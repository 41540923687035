module PositiveTS {
  export module Service {
    export module Cashier {

            export async function getAll(): Promise<Array<Storage.Entity.Employee>>{
                try{
                    if(posUtils.isBlank(session.pos)) return [];
                    let employeesStorage = new PositiveTS.Storage.Entity.Employee();
                    let employees = await employeesStorage.fetchByStoreAndIsCashier(session.pos.storeID);
                    return employees;
                }catch(err){
                    console.log(err);
                    Promise.reject(err);
                }
            }
            export async function setCurrent(employeeID:string,employeeName:string):Promise<void>{
                try{
                    session.pos.employeeID = employeeID;
                    session.pos.employeeName = employeeName;
                    await  session.pos.persist();
                    await PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);			
                    return await PositiveTS.Storage.Entity.Sale.updateCashierToOpenSale(employeeID, employeeName);
                }catch(err){
                    console.log(err);
                    return Promise.reject(err);
                }
            }
            export async function removeCurrent():Promise<void>{
                try{
                    session.pos.employeeID = '-1';
                    session.pos.employeeName = null;
                    await  session.pos.persist();
                    return await PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);		
                }catch(err){
                    console.log(err);
                    return Promise.reject(err);
                }
            }
            export async function setExistGeneralEmployeeByDefault():Promise<Storage.Entity.Employee>{
                try{
                    let employees = await getAll();
                    let generalEmployee = employees.find(e=> e.magneticCard == Shared.Constants.Employee.GENERIC_EMPLOYEE)
                    if(!posUtils.isBlank(generalEmployee)){
                        await setCurrent(generalEmployee.employeeID,generalEmployee.name);
                    }
                    return generalEmployee;
                }catch(err){
                    console.log(err);
                    return null;
                }
            }
            export function setGeneralEmployeeByDefault():{employeeID:string,employeeName:string}{
                    session.pos.employeeID = Shared.Constants.Employee.GENERIC_EMPLOYEE;
                    session.pos.employeeName = i18next.t("pos.generalEmployee");
                    return {employeeID:session.pos.employeeID,employeeName:session.pos.employeeName}     
            }
        }
    }
}