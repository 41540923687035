module PositiveTS {
    export module Service {
        export module CustomerSaleItemLimit {
            const URL = "/items/get_num_of_item_purchases_in_range";
            const TYPES = {
                1: 'day',
                2: 'week',
                3: 'month',
            }

            export async function checkIfNeedToAlertForCustomerItems() {
                if (posUtils.isBlank(posVC.sale.jsondata)) {
                    return false;
                }

                let jd = JSON.parse(posVC.sale.jsondata);

                if (posUtils.isBlank(jd.customer)) {
                    return false;
                }

                if (!Pinia.globalStore.isOnline) {
                    return false;
                }

                let checkedItemByCode = {};

                for (let saleItem of posVC.saleItems) {

                    if (checkedItemByCode[saleItem.itemCode]) {
                        continue;
                    }
                    
                    if (isLimitConfiguredOnItem(saleItem.item)) {
                        let result = await getNumOfItemPurchasesInRange(jd.customer, saleItem.item);

                        if (posUtils.isBlank(result)) {
                            continue;
                        }

                        let customerName = `${jd.customer.s_first_name} ${jd.customer.s_last_name}`
                        let currentItemCount = sumItemQuantity(posVC.saleItems, saleItem.item);

                        if ((result.count + currentItemCount) > saleItem.item.salesLimitTimeAmount) {
                            let message = '';
                            let timespan = i18next.t(`timespans.${TYPES[saleItem.item.salesLimitTimeType]}`);
                            let transData = {
                                customer: customerName,
                                item: saleItem.item.description,
                                timespan: timespan,
                                allowedQty: saleItem.item.salesLimitTimeAmount
                            }

                            if (result.count == 0) {
                                message = i18next.t('customerSaleItemLimit.wantsToByMoreThenAllowedQty', {
                                    ...transData,
                                    currentQty: currentItemCount,
                                });
                            } else {
                                if (saleItem.item.salesLimitTimeAmount == 1) {
                                    message = i18next.t('customerSaleItemLimit.boughtMoreThenOnce', {
                                        ...transData,
                                        day: moment(result.date).format('D/M/Y'),
                                        hour: moment(result.date).format('H:m'),
                                    });
                                } else {
    
                                    message = i18next.t('customerSaleItemLimit.boughtMoreThenQty', {
                                        ...transData,
                                        boughtQty: result.count,
                                    });
                                }
                            }


                            let response = await app.promiseShowAlert({
                                header: i18next.t("payAttention"),
                                content: message,
                                continueButtonText: i18next.t("continue")
                            })
                
                            if(response == "cancel"){
                                return true;
                            }

                            checkedItemByCode[saleItem.item.code] = true;
                        }
                    }
                }

                return false;
            }

            function isLimitConfiguredOnItem(item) {
                return posUtils.isDefined(item.salesLimitTimeType) && posUtils.isDefined(item.salesLimitTimeAmount); 
            }

            async function getNumOfItemPurchasesInRange(customer, item) {
                try {
                    app.showLoadingMessage(i18next.t('customerSaleItemLimit.checkingCustomerItems'))

                    let startDate = moment().startOf(TYPES[item.salesLimitTimeType]);
                    let endDate = moment().endOf(TYPES[item.salesLimitTimeType]);

                    let response = await PositiveTS.Service.FetchReq.jsonReq(URL, 'post', {
                        customer_id: customer.db_id,
                        item_code: item.code,
                        start_date: startDate,
                        end_date: endDate});
          
                    if (!(response.result && response.result.success)) {
                        Service.Logger.error(response.result);
                        throw new Error('CustomerSaleItemLimit - Server returned success false');
                    }

                    app.hideLoadingMessage();
                    
                    return response.result;
                  } catch(err) {
                    Service.Logger.error(err);
                    app.hideLoadingMessage();
                    return;          
                  }

            }

            function sumItemQuantity(saleItems: Array<Storage.Entity.SaleItem>, item: Storage.Entity.Item) {
                let sum = 0;

                for (let currentSaleItem of saleItems) {
                    if (currentSaleItem.itemCode == item.code) {
                        sum += currentSaleItem.quantity;
                    }
                }

                return sum;
            }
        }
    }
}
