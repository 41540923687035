module PositiveTS {
export module Promotions {
export module Templates {
export class BuyXGetDiscountOnY extends TemplateAbstract {
  constructor(initParameters) {
    super(initParameters)
  }

  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (var promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      // Get the promotion
      var promotion = this.parameters.promotionsByCode[promotionCode];

      // Check that this promotion is from template 2
      if (promotion.template !== '2') {
        // -- Not from template 2, skip!
        continue;
      }

      // -- This promotion is from template 2
      this.applyPromotion(promotion);
    }
  };

  promoBuyLikeGet(code) {
    var sortByBarcode = function(item1, item2) {
        if ( item1.attr < item2.attr )
          return -1;
        if ( item1.attr > item2.attr )
          return 1;
        return 0;
      }
    var buyArr = this.parameters.promotionsBuy[code].slice();
    var getArr = this.parameters.promotionsGet[code].slice();
    if (buyArr.length != getArr.length) {
      return false;
    }
    buyArr.sort(sortByBarcode);
    getArr.sort(sortByBarcode);
    for (var i=0; i < buyArr.length; i++) {
      if (buyArr[i].barcode !== getArr[i].barcode) {
        return false;
      }
    }
    return true;

  };

  //TODO: Too damn long
  applyPromotion(promotion:Storage.Entity.Promotion) {
    // -----------------------------------------------
    // | Minimum quantity and amount check
    // -----------------------------------------------
    if (!this.promotionHasMinimumQuantityAndAmount(promotion, this.parameters.promotionsBuy[promotion.code])) {
      return;
    }

    // -----------------------------------------------
    // | Is promotion in get side also check
    // -----------------------------------------------
    if (!(promotion.code in this.parameters.promotionsGet)) {
      return;
    }

    // -----------------------------------------------
    // | Flatten sale items and sort them by unitPrice
    // -----------------------------------------------
    var flattenedSaleItemsBySide = {
      'buy': this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code]),
      'get': this.flattenSaleItemsByQuantity(this.parameters.promotionsGet[promotion.code])
    };

    flattenedSaleItemsBySide.buy.sort(this.sortByUnitPriceFromExpensiveToCheapest);
    flattenedSaleItemsBySide.get.sort(this.sortByUnitPriceFromExpensiveToCheapest);

    //workaround to preserve forward compatibility with new sorting method of promotions
    flattenedSaleItemsBySide.get = flattenedSaleItemsBySide.get.map(item => { item.quantity = item.realQuantity; return item; })
    flattenedSaleItemsBySide.buy = flattenedSaleItemsBySide.buy.map(item => { item.quantity = item.realQuantity; return item; })

    var buyEqGet = this.promoBuyLikeGet(promotion.code);

    if (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT && !buyEqGet) {
      flattenedSaleItemsBySide.buy.reverse();
      flattenedSaleItemsBySide.get.reverse();
    }


    // -----------------------------------------------
    // | Apply promotion for sale items
    // -----------------------------------------------
    // Initialize an object to save the discount per sale item
    var discountAmountForSaleItem = {};
    var itemToPromote;

    var totals = this.countTotalQuantityAndAmountOfSaleItems(this.parameters.promotionsBuy[promotion.code]);

    // if it is percent calculation then apply to all the items the discount
    // var maxItemByMinimumBuyAmount = totals.totalQuantity;

    var allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
    var allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
    var promotionApplied = false;

    var maxItemByMinimumBuyAmount = Math.floor(totals.totalPrice / Number(promotion.minimumBuyAmount));
    if (!allowMultipleTimesPerSale) {
      maxItemByMinimumBuyAmount = Math.min(maxItemByMinimumBuyAmount,1);
    }

    var counter = 1;
    var promoGroups = {};


    // Start to peer item from the get side to items in the buy side
    var isFixPromotion = (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX);
    if ((promotion.discountType !== PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT) ||
        (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT && !buyEqGet ))
    {
    while (flattenedSaleItemsBySide['buy'].length >= promotion.minimumBuyQuantity && flattenedSaleItemsBySide['get'].length > 0 ) {
      if (!allowMultipleTimesPerSale && promotionApplied) {
        break;
      }
      var result = this.getItemToPromote(promotion, flattenedSaleItemsBySide, maxItemByMinimumBuyAmount);
      if (result.itemToPromote === null) {
        break;
      }
      flattenedSaleItemsBySide = result.flattenedSaleItemsBySide;
      itemToPromote = result.itemToPromote;
      if (!promoGroups[itemToPromote.id]) {
        promoGroups[itemToPromote.id] = {
          itemsCounter: {},
          promotion: promotion,
          discountAmountForGroup: 0,
          totalPriceForItemsBeforeDiscount: 0
        }
      }

      // Initialize entry in discountAmountForSaleItem
      if (!(itemToPromote.id in discountAmountForSaleItem)) {
        discountAmountForSaleItem[itemToPromote.id] = 0;
      }
      promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(itemToPromote,promoGroups[itemToPromote.id].itemsCounter);
      promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += itemToPromote.unitPrice;

      if (counter > maxItemByMinimumBuyAmount)
      {
        continue;
      }

      // Give the promotion on the item
      var discountValueAmount = 0;
      var discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL;
      switch (promotion.discountType) {
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
          discountValueAmount = Number(promotion.discountValue*maxItemByMinimumBuyAmount);
          discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT;
          //add the items that were in this promotion into the items counter
          for (var j=0; j<result.amountPromotionDiscountedItems.length; j++) {
            promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(result.amountPromotionDiscountedItems[j],promoGroups[itemToPromote.id].itemsCounter);
            promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += result.amountPromotionDiscountedItems[j].unitPrice;
          }
        break;
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
          discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT;
          var otherItemsPrice = 0;
          for (var j=0; j < result.fixedPromotionDiscountedItems.length; j++ ) {
            otherItemsPrice += parseFloat(result.fixedPromotionDiscountedItems[j].unitPrice.toFixed(2));
            promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(result.fixedPromotionDiscountedItems[j],promoGroups[itemToPromote.id].itemsCounter);
            promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += result.fixedPromotionDiscountedItems[j].unitPrice;
          }

          discountValueAmount = (itemToPromote.unitPrice+otherItemsPrice) - Number(promotion.discountValue);
          discountValueAmount = parseFloat(discountValueAmount.toFixed(2)); //convert to float with only 2 decimals
        break;
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
          discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT;
          for (var j=0; j<result.percentPromotionDiscountedItems.length; j++) {
            promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(result.percentPromotionDiscountedItems[j],promoGroups[itemToPromote.id].itemsCounter);
            promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += result.percentPromotionDiscountedItems[j].unitPrice;
          }
          discountValueAmount += itemToPromote.unitPrice * Number(promotion.discountValue) / 100;
        break;
        default:
          console.error('Unknown discount type... (' + promotion.discountType + ')');
        break;
      }

      discountAmountForSaleItem[itemToPromote.id] = discountAmountForSaleItem[itemToPromote.id] + discountValueAmount;
      promoGroups[itemToPromote.id].discountAmountForGroup = discountAmountForSaleItem[itemToPromote.id];
      promotionApplied = true;

      counter++;
    }
    }
    if (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT && buyEqGet) {
      //Discount type == Percent - rewrite by Shai - assume that promobuy is like promoget
      var discountValueAmount = 0;

      discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT;
      let minBuyQuantity = promotion.minimumBuyQuantity;
      for (var i = 0; i <= flattenedSaleItemsBySide['buy'].length -minBuyQuantity ; i += minBuyQuantity) {

        if (!allowMultipleTimesPerSale && promotionApplied) {
          break;
        }

        itemToPromote = flattenedSaleItemsBySide['buy'][i+minBuyQuantity-1];
        if (!promoGroups[itemToPromote.id]) {
          promoGroups[itemToPromote.id] = {
            itemsCounter: {},
            promotion: promotion,
            discountAmountForGroup: 0,
            totalPriceForItemsBeforeDiscount: 0
          }
        }

        //add the promotion code to followed other items affilicated with this promotion
        for (var j=i; j < i+minBuyQuantity - 1; j++) {
          promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(flattenedSaleItemsBySide['buy'][j],promoGroups[itemToPromote.id].itemsCounter);
          promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += flattenedSaleItemsBySide['buy'][j].unitPrice;
        }

        // Initialize entry in discountAmountForSaleItem
        if (!(itemToPromote.id in discountAmountForSaleItem)) {
          discountAmountForSaleItem[itemToPromote.id] = 0;
        }
        promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(itemToPromote,promoGroups[itemToPromote.id].itemsCounter);
        promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += itemToPromote.unitPrice;

        //Apply the discount
        discountValueAmount = itemToPromote.unitPrice * promotion.discountValue / 100;

        //for calculations in a later stage
        discountAmountForSaleItem[itemToPromote.id] += discountValueAmount;
        promoGroups[itemToPromote.id].discountAmountForGroup += discountValueAmount;
        promotionApplied = true;
      }
    }

    // Go over the discount amount per sale item and apply it

    for (var saleItemID in discountAmountForSaleItem) {
      var key = this._generateUniqueKey({id: saleItemID}, promotion.code, 'get');

      // Update data...
      var saleItemTotalAmount = this.parameters.saleItemsPotentialPromotions[key].unitPrice * this.parameters.saleItemsPotentialPromotions[key].quantity;
      this.parameters.saleItemsPotentialPromotions[key].discountAbsoluteValue = session.fixedNumber(discountAmountForSaleItem[saleItemID]);
      this.parameters.saleItemsPotentialPromotions[key].discountPrecentValue = session.fixedNumber(discountAmountForSaleItem[saleItemID] / saleItemTotalAmount * 100);
      this.parameters.saleItemsPotentialPromotions[key].discountType = discountType;
      this.parameters.saleItemsPotentialPromotions[key].isPromotionGiven = true;

      promoGroups[saleItemID].item = this.parameters.saleItemsPotentialPromotions[key];
    }
    for (let key in promoGroups) {
      this.parameters.promoGroups.push(promoGroups[key]);
    }
  }

  removeItemFromArrayById(itemToRemove, array) {
    var _length = array.length;
    for (var i = 0; i < _length; i++) {
      if ( array[i] && array[i].id === itemToRemove.id ) {
        array.splice(i, 1);
        break;
      }
    }
    return array;
  }

  getItemToPromote(promotion:Storage.Entity.Promotion, flattenedSaleItemsBySide, maxItemByMinimumBuyAmount):any {

      var totalQuantity = flattenedSaleItemsBySide['get'].length;

      // Take the most expensive item from the get side
      var flattendGetBackup = jQuery.extend({}, flattenedSaleItemsBySide['get']);
      var itemToPromote= flattenedSaleItemsBySide['get'].shift();

      var fixedPromotionDiscountedItems = [];
      var amountPromotionDiscountedItems = [];
      var percentPromotionDiscountedItems = [];



      if (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX ) { //Discount_type = FIX
          for (var i = 0; i < promotion.minimumBuyQuantity-1; i++) {
            fixedPromotionDiscountedItems.push(flattenedSaleItemsBySide['get'][i]);
          }

          // Remove it from the buy side (if it is there)
          flattenedSaleItemsBySide['buy'] = this.removeItemFromArrayById(itemToPromote, flattenedSaleItemsBySide['buy']);

          for (var i = 0; i < promotion.minimumBuyQuantity-1; i++) {
              flattenedSaleItemsBySide['get'] = this.removeItemFromArrayById(flattenedSaleItemsBySide['buy'].shift(), flattenedSaleItemsBySide['get']);
          }

          if (flattenedSaleItemsBySide['get'].length == 0 )
          {
              flattenedSaleItemsBySide['get'] = flattendGetBackup;
          }
      }
      if (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT ) { //DiscountType == AMOUNT
          // Delete the 'promotion.minimumBuyQuantity' most expensive items from the buy side and remove them from the get side (if they are there)
          if (maxItemByMinimumBuyAmount > 0) {
            var minBuyAmount = Number(promotion.minimumBuyAmount);
            var itemsAmount = itemToPromote.unitPrice;

            flattenedSaleItemsBySide['buy'] = this.removeItemFromArrayById(itemToPromote, flattenedSaleItemsBySide['buy']);
            //TODO: remove the items according the the minimumBuyAmount and store them in the return value!
            while (itemsAmount < minBuyAmount*maxItemByMinimumBuyAmount) {
              var nextItem = flattenedSaleItemsBySide['buy'].shift();
              if (typeof(nextItem) === 'undefined') {
                //if we got here it means that there are not enough items to apply the promotion again
                //this means - no item to promote and exit.
                return {
                  itemToPromote: null
                }
              }
              amountPromotionDiscountedItems.push(nextItem);
              itemsAmount += nextItem.unitPrice;
              flattenedSaleItemsBySide['get'] = this.removeItemFromArrayById(nextItem, flattenedSaleItemsBySide['get']);
            }
          }
      }
      if (promotion.discountType === PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT) {
        if (maxItemByMinimumBuyAmount > 0) {
          var minBuyAmount = Number(promotion.minimumBuyAmount);
          var itemsAmount = itemToPromote.unitPrice; //- itemToPromote.unitPrice * Number(promotion.discountValue) /100;
          var itemsQuantity = 1;

          flattenedSaleItemsBySide['buy'] = this.removeItemFromArrayById(itemToPromote, flattenedSaleItemsBySide['buy']);
          //TODO: remove the items according the the minimumBuyAmount and store them in the return value!
          while (itemsAmount < minBuyAmount*maxItemByMinimumBuyAmount && itemsQuantity < Number(promotion.minimumBuyQuantity)) {
            var nextItem = flattenedSaleItemsBySide['buy'].shift();
            if (typeof(nextItem) === 'undefined') {
              //if we got here it means that there are not enough items to apply the promotion again
              //this means - no item to promote and exit.
              return {
                itemToPromote: null
              }
            }
            percentPromotionDiscountedItems.push(nextItem);
            itemsAmount += nextItem.unitPrice;
            itemsQuantity += 1;
            flattenedSaleItemsBySide['get'] = this.removeItemFromArrayById(nextItem, flattenedSaleItemsBySide['get']);
          }
        }
      }

      return {
          flattenedSaleItemsBySide:   flattenedSaleItemsBySide,
          itemToPromote:              itemToPromote,
          fixedPromotionDiscountedItems: fixedPromotionDiscountedItems,
          amountPromotionDiscountedItems: amountPromotionDiscountedItems,
          percentPromotionDiscountedItems: percentPromotionDiscountedItems
      };
  }

}
}}}
