module PositiveTS {
  export module Components {
    export module TableAttributesDialog {

      export function getComponent() {
        return {
          template: JST.tableAttributesDialog(),
          props: {
            newTable: {
              type: Boolean,
              default: false,
            }
          },
          methods: {
            async open(dalpak = {}) {
              this.dalpakToEdit = _.cloneDeep({data: {}, ...dalpak});
              await this.$refs.dialog.open();
            },
            validateAndSave() {
              let errors = this.validateDalpak();

              if (posUtils.isBlank(errors)) {
                this.updateEditModeDalpak(this.dalpakToEdit);
                return true;
              }

              app.showAlertDialog({
                header: i18next.t('error'),
                content: errors.join('\n'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              });

              return false;
            },
            validateDalpak() {
              let errors = [];

              if (posUtils.isBlank(this.dalpakToEdit.name)) {
                errors.push(this.$t('tableAttributesDialog.tableNameEmpty'))
              } else {
                if (this.dalpaksInEditMode.some(d => d.name.trim() == this.dalpakToEdit.name.trim() && d.id != this.dalpakToEdit.id)) {
                  errors.push(this.$t('tableAttributesDialog.tableNameAlreadyExists'))
                }
              }

              let fixedDiscount = this.dalpakToEdit.data.fixedDiscount;

              if (!posUtils.isBlank(fixedDiscount)) {
                if (!posUtils.isNumeric(fixedDiscount) || fixedDiscount < 0 || fixedDiscount > 100) {
                  errors.push(this.$t('tableAttributesDialog.tableDiscountNotValid'))
                }
              }

              if (this.hasDalpakAreas && posUtils.isBlank(this.dalpakToEdit.area)) {
                errors.push(this.$t('tableAttributesDialog.dalpakAreaMandetory'))
              }

              return errors;
            },
            focusOnNextStepOrFinish(currentStep = null) {
              let steps = ['tableName', 'tableDiscount'];

              if (this.hasDalpakAreas) {
                steps.push('dalpakArea')
              }

              let currentStepIndex = steps.indexOf(currentStep);
              let currentComponentToFocus = null;

              if (currentStepIndex == -1 && steps.length > 0) {
                currentComponentToFocus = steps[0];
              } else {
                currentComponentToFocus = steps[currentStepIndex + 1];
              }

              if (currentComponentToFocus && this.$refs[currentComponentToFocus]) {
                this.$refs[currentComponentToFocus].focus();
              } else {
                this.$refs.dialog.buttonClicked(this.saveButton);
              }
            },
          },
          computed: {
            saveButton() {
                return {
                  text: this.$t('addAction'),
                  class: 'tables-green',
                  beforeCloseAction: () => this.validateAndSave(),
                }
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {hasDalpakAreas,dalpakAreasInEditModeWithoutDeleted,dalpaksInEditMode} = Pinia.storeToRefs(dalpaksStore);
            const {updateEditModeDalpak} = dalpaksStore

            return {hasDalpakAreas,dalpakAreasInEditModeWithoutDeleted,dalpaksInEditMode,updateEditModeDalpak}

            
          },
          data() {
            return {
              dalpakToEdit: null,
            }
          }
        }
      }
    }
  }
}