module PositiveTS {
  export module Service {
     export module Check {
      export class ClassDictTransaction {

        protected _ERN_ManufacturerId = "PTV";
        protected _ERN_ManufacturerVersion = "002";

        protected _getClientRequestId() {
          return session.pos.tenantID + session.pos.companyID + session.pos.storeID + "11111";
        };

        protected _getShortUnique() {
          var d = new Date().getTime().toString();
          var str = PositiveTS.Service.StrUtils.rpad(session.pos.storeID + session.pos.posNumber.toString(), 6, "0") + d.substring(d.length - 6, d.length);

          return PositiveTS.Service.StrUtils.lpad(str, 12);
        };

        protected _getErnMId() {
          var ernId = jsonConfig.getVal(jsonConfig.KEYS.ernMId); // for test possible to use "100276"
          if (!ernId) { throw new Error("No jsonConfig.KEYS.ernMId defined"); }

          return ernId;

        };

        protected _translateIlDateToErnDate(ildate) {
          return moment(ildate,"DD/M/YY").format("DDMMYYYY")
        };
      }
}}}
