module PositiveTS {
  export module Components {
    export module CreateDalpakAreaDialog {

      export const route = '/create-dalpak-area';
      type CompType = any;


      export function getComponent() {
        return {
          template: JST.createDalpakAreaDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            close() {
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
            async createAndFinish() {
              let isCreatedSuccessfully = await Service.Dalpak.createNewDalpakArea({name: this.name, color: this.color, sortOrder: this.sortOrder}, this.dalpaksToTransfer);

              if (isCreatedSuccessfully) {
                this.close();
              }
            }
          },
          data() {
            return {
              name: null,
              color: null,
              sortOrder: null,
              area: null,
              dalpaksToTransfer: [],
              isFirstStoreArea: true
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {allDalpakAreas,allDalpaks} = Pinia.storeToRefs(dalpaksStore);
            return {allDalpakAreas,dalpaks:allDalpaks}
          },
          computed: {
            dalpakOptions() {
              return this.dalpaks.filter(dalpak => !this.dalpaksToTransfer.includes(dalpak) && dalpak.area !== Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES)
                .map(dalpak => {
                  return dalpak;
                });
            },
          },
          mounted() {
            this.isFirstStoreArea = this.allDalpakAreas && this.allDalpakAreas.length == 0

            if(this.isFirstStoreArea) {
              this.dalpaksToTransfer = this.dalpaks.filter(dalpak => dalpak.area !== Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES)
            }
          },
          beforeRouteEnter: function(to, from, next) {
            next(vm => {
              let self = vm as CompType;
              self.sortOrder = to.params.sortOrder || 0;
            });
          }
        }
      }
    }
  }
}
