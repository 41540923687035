module PositiveTS {
    export module Service {
    export module GoodiDiscountItemService {

        export async function addDiscount(discountAmount:number):Promise<void>{
            if (discountAmount == 0){
                return;     
            }
            
            let saleitem = await getdDiscountSaleitem();
            saleitem.unitPrice = -discountAmount;  
            posVC.saleItems.push(saleitem);

            let itemsToUpdate = new Map<number,Storage.Entity.SaleItem>()
            itemsToUpdate.set(saleitem.rowNumber,saleitem)

            await posVC.saleUpdated();
        }

        async function getdDiscountSaleitem():Promise<Storage.Entity.SaleItem>{
            let itemCode = jsonConfig.getVal(jsonConfig.KEYS.goodiCardDiscountItemCode)
            let itemandcode = await PositiveTS.Storage.Entity.Item.searchByCode(itemCode);
            let item = itemandcode[0].item;
            if (!item){
                throw new Error(`Bad POS configuration, no itemCode ${itemCode}`)
            }

            let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(item, item.code);
            saleItem.saleID = posVC.sale.id;
            saleItem.rowNumber = posVC.getRowNumber();
            saleItem.quantity = 1;
            
            return saleItem; 
          }
    }
}
}