module PositiveTS {
  export module Dialogs {
    export interface InputWithCVVResult {
      cardNumber: string
      cvv: string
    }
    export class InputDialogWithCVV extends InputDialog {
      constructor() {
        super();
      }

      open(options: InputDialogOptions): Promise<InputWithCVVResult> {
        return new Promise<InputWithCVVResult>((resolve, reject) => {
          options = this.initDialog(options);

          $('#input-dialog-continue').click((key) => {

            $('#input-dialog-error-msg').hide();
            let cardNumber = $('#input-dialog-field').val();
            let cvv = $('#input-dialog-field-cvv').val();

            if (!options.inputValidator(cardNumber) && !posUtils.isNullOrUndefinedOrEmptyString(options.emptyErrorMessage)) {
              $('#input-dialog-error-msg').fadeIn('slow').delay(5000).fadeOut('slow');
              return;
            }
            this.close();
            resolve({
              cardNumber: cardNumber,
              cvv: cvv
            })

          })

          $('#input-dialog-cancel').click(() => {
            this.close();
            reject("User canceled");
          });

          this.ui.dialog('open');
        })
      }

      close() {
        super.close();
        $('#input-dialog-field-cvv').keyboard().getkeyboard().destroy();
        $('#input-dialog-field-cvv').hide();
      }

      protected initDialog(options) {
        $('#input-dialog-field').val('');
        $('#input-dialog-field-cvv').val('');
        $('#input-dialog-field-cvv').show();
        if (!Modernizr.touch) {
          $('#input-dialog-field-cvv').keyboard({
            layout: 'only-numbers',
            usePreview: false,
            autoAccept: true,
            css        : { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" },
            position: {
              of: $('#footer'),
              my: 'center top',
              at: 'center bottom'
            }
          }).addTyping();
          return super.initDialog(options);
        }

      }
    }
  }
}
