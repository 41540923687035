module PositiveTS {
	export module Dialogs {
		export class AdvancedItemSearchDialog {
			private callback:Function;
			private autoselectUI:AdvancedItemSearchAutoselectUI
			private uiInputs:any;
			private ctrl:any;
			private semaphorePaintResultTable;
			private bal:AdvancedItemSearchBAL;
			STILL_TYPING_MILISSECOND = 500;
			private static _instance:AdvancedItemSearchDialog;


			
			public static async getInstance(callback?:Function) {
				if (this._instance == null) {
					this._instance = new AdvancedItemSearchDialog(callback);
				}
				await this._instance.init();
				return this._instance;
			}

			private constructor(callback?:Function) {
				this.callback = callback;
			}

			private async init() {
				(<any>window).AdvancedItemSearchDialogUI.dialog('open');
				$('#ui-id-1.ui-dialog-title').text(i18next.t("advanceItemSearch.title"));
				$('.line-itemCodeContains').text(i18next.t("advanceItemSearch.itemCodeContains"));
				$('.line-itemDescriptionContains').text(i18next.t("advanceItemSearch.itemDescriptionContains"));
				$('.line-brand').text(i18next.t("advanceItemSearch.brand"));
				$('.line-age').text(i18next.t("advanceItemSearch.age"));
				$('.line-department').text(i18next.t("advanceItemSearch.department"));
				$('.line-under').text(i18next.t("advanceItemSearch.under"));
				$('.line-subcategory').text(i18next.t("advanceItemSearch.subcategory"));
				$('.line-sub').text(i18next.t("advanceItemSearch.under"));
				$('.line-priceFrom').text(i18next.t("advanceItemSearch.priceFrom"));
				$('.line-priceTo').text(i18next.t("advanceItemSearch.priceTo"));
				$('#AdvancedItemSearch-back .ui-button-text').text(i18next.t("back"));

				this.autoselectUI = new AdvancedItemSearchAutoselectUI(this);
				this.initInputs();
				this.bal = new AdvancedItemSearchBAL();
				this.ctrl = {};
				let allPromises = [
					this.populateSelectionCategoriesFromDb(this.bal.ITEM_CATEGORY_ID.BRAND_GROUP, "categoryBrandGroup"),
					this.populateSelectionCategoriesFromDb(this.bal.ITEM_CATEGORY_ID.AGE_GROUP, "categoryAgeGroup"),
					this.populateSelectionCategoriesFromDb(this.bal.ITEM_CATEGORY_ID.MAIN_CAT, "categoryMainClassGroup"),
					this.populateSelectionCategoriesFromDb(this.bal.ITEM_CATEGORY_ID.SUB_MAIN_CAT, "categoryMainSubClassGroup"),
					this.populateSelectionCategoriesFromDb(this.bal.ITEM_CATEGORY_ID.SECOND_CAT, "categorySecondaryClassGroup"),
					this.populateSelectionCategoriesFromDb(this.bal.ITEM_CATEGORY_ID.SUB_SECOND_CAT, "categorySecondarySubClassGroup"),
				];
				await Promise.all(allPromises)
				this.load();
			}


			private initInputs() {
				this.uiInputs = {
					'#AdvancedItemSearch-ItemCode' : {
						type : 'Input',
						val : "",
						enter_id : 0,
						enter_next : 1,
						keypress_refresh: true,
						onChange: (selector) => { this.onChangeField(selector) }
					},
					'#AdvancedItemSearch-ItemDescription' : {
						type : 'Input',
						val : "",
						enter_id : 1,
						enter_next : 2,
						keypress_refresh: true,
						onChange:  (selector) => { this.onChangeField(selector) }
					},
					'#AdvancedItemSearch-FromPrice' : {
						type : 'Input',
						val : "",
						enter_id : 2,
						enter_next : 3,
						keypress_refresh: true,
						onChange:(selector) => { this.onChangeField(selector) },
						itemTableKey: "priceZarhan",
						itemTableOperand: ">="
					},
					 '#AdvancedItemSearch-ToPrice' : {
						type : 'Input',
						val : "",
						enter_id : 3,
						enter_next : 4,
						keypress_refresh: true,
						onChange: (selector) => { this.onChangeField(selector) },
						itemTableKey: "priceZarhan",
						itemTableOperand: "<="
					},
					'#AdvancedItemSearch-ItemBrandId' : {type: 'Input', val: "", enter_id: 4, enter_next: 5,
								isAutoComplete: true, internalVarName: "ItemBrandId", internalDataArrayName: "categoryBrandGroup",
								itemTableKey: "brandID"},
					'#AdvancedItemSearch-ItemAgeId' : {type: 'Input', val: "", enter_id: 5, enter_next: 6,
								isAutoComplete: true, internalVarName: "ItemAgeId", internalDataArrayName: "categoryAgeGroup",
								itemTableKey: "ageGroupID"},
					'#AdvancedItemSearch-ItemMainClassId' : {type: 'Input', val: "", enter_id: 6, enter_next: 7,
								isAutoComplete: true, internalVarName: "ItemMainClassId", internalDataArrayName: "categoryMainClassGroup",
								itemTableKey: "itemMainClassID"},
					'#AdvancedItemSearch-ItemMainSubClassId' : {type: 'Input', val: "", enter_id: 7, enter_next: 0,
								isAutoComplete: true, internalVarName: "MainSubClassId", internalDataArrayName: "categoryMainSubClassGroup",
								itemTableKey: "itemMainSubClassID" },
	
					'#AdvancedItemSearch-ItemSecondaryClassId' : {type: 'Input', val: "", enter_id: 6, enter_next: 7,
								isAutoComplete: true, internalVarName: "ItemSecondaryClassId", internalDataArrayName: "categorySecondaryClassGroup",
								itemTableKey: "itemSecondaryClassID"},
					'#AdvancedItemSearch-ItemSecondarySubClassId' : {type: 'Input', val: "", enter_id: 7, enter_next: 0,
								isAutoComplete: true, internalVarName: "SecondarySubClassId", internalDataArrayName: "categorySecondarySubClassGroup",
								itemTableKey: "itemSecondarySubClassID" },
				};
	
			}

			private onChangeField(selectorField?) {
				$(selectorField).unbind("change");
				$(selectorField).change(() => {
					this.bal.paging.pageNumber = 1;
		
					this.ctrl.setVal();
					this.autoselectUI.saveSelectionValuesOnChange();
		
					this.paintResultTable();
				});
			};


			private refreshResultTableToPageNumber(toPageNumber) {
				this.bal.paging.pageNumber = toPageNumber;
				this.paintResultTable();
			};
	
			private bindOnChange() {
				for (var key in this.uiInputs){
					if (this.uiInputs[key].onChange){
						this.uiInputs[key].onChange(key);
					}
				}
			};
	

	
			private open() {
				// Show dialog div
				$(document).unbind('keypress');
				let self = this;

				let onPageUpClick = function() {
					if (self.bal.paging.pageNumber > 1) {
						self.bal.paging.pageNumber = self.bal.paging.pageNumber - 1;
						self.paintResultTable();
					}
				};
			
				let onPageDownClick = function() {
					if (self.bal.paging.couldBeMorePages) {
						self.bal.paging.pageNumber = self.bal.paging.pageNumber + 1;
						self.paintResultTable();
					}
				};

				let onTableRowClick = function() {
					var itemcode = $(this).attr("data-itemcode");
					if (self.callback) {
						self.closeInternal(onTableRowClick,onPageUpClick,onPageDownClick,itemcode);
					} else {
						posVC.addItemByBarcode(itemcode);
						self.closeInternal(onTableRowClick,onPageUpClick,onPageDownClick);
					}
			
				};
				this.bindOnChange();
				this.autoselectUI.bindAllUiSelection();
				$("#advanced-item-search-dialog").on("click","#AdvancedItemSearch-resultTable tr", onTableRowClick);
				$("#advanced-item-search-dialog").on("click","#AdvancedItemSearch-pageUp", onPageUpClick);
				$("#advanced-item-search-dialog").on("click","#AdvancedItemSearch-pageDown", onPageDownClick);
		
				$("#AdvancedItemSearch-back").unbind("click");
				$("#AdvancedItemSearch-back").bind("click", () => {
					this.closeInternal(onTableRowClick,onPageUpClick,onPageDownClick);
				});
		
		
			};
	
			private closeInternal(onTableRowClick,onPageUpClick,onPageDownClick,callbackParameter?) {
				$("#advanced-item-search-dialog").off("click","#AdvancedItemSearch-resultTable tr", onTableRowClick);
				$("#advanced-item-search-dialog").off("click","#AdvancedItemSearch-pageUp", onPageUpClick);
				$("#advanced-item-search-dialog").off("click","#AdvancedItemSearch-pageDown", onPageDownClick);
				(<any>window).AdvancedItemSearchDialogUI.dialog('close');
				if (this.callback) {return this.callback(callbackParameter);}
		
				posVC.initializeKeyboard();
		
				if ((<any>window).AdvancedItemSearchDialogUI.data("backtoFlightCard")){
					(<any>window).AdvancedItemSearchDialogUI.data("backtoFlightCard",false);
					(<any>window).flightcardDg.open();
				}
			};
		
			private chnageTrigger() {
				$("#AdvancedItemSearch-ItemCode").trigger("change");
			};
	
			private bindChangeTriggerToKey(key) {
				$( key ).unbind("keyup");
				$( key ).keyup(() => {
				this.chnageTrigger();
				});
			};
		
			private bindKeyEnterToChangeEvent() {
				for(var key in this.uiInputs)
					{
						if (this.uiInputs[key].keypress_refresh) {
							this.bindChangeTriggerToKey(key);
						}
					}
			};
	
	
			private async paintResultTableTimeoutFunction() {
			
				let foundBarcode = await this.bal.AddItemBarcodeToArrayIfExists(this.ctrl)
				
				if (foundBarcode) {
					$("#AdvancedItemSearch-ItemCode").val('');
				}
				let result = await this.bal.searchByCtrlFields(this.ctrl);
				
				this.bal.paging.storeCouldBeMorePages(result.length);
		
				let dictUnderscore = {rows: result,
										pageNumber: this.bal.paging.pageNumber};
				let template = $("#AdvancedItemSearch-resultTemplate").html();
				let processed = _.template(template)(dictUnderscore);
				$("#AdvancedItemSearch-resultContainer").html(processed);
				
			};
	
			private paintResultTable() {
				window.clearTimeout(this.semaphorePaintResultTable);
				this.semaphorePaintResultTable = setTimeout(() => { this.paintResultTableTimeoutFunction() }, this.STILL_TYPING_MILISSECOND);
			};
		
			private guiFixes() {
				$("#advanced-item-search-dialog").css("top","5px");
			};
		
		
			private async populateSelectionCategoriesFromDb(barandGroupId, internalTag) {
				let result = await this.bal.getCategoryValuesByCategoryId(barandGroupId)
				this[internalTag] = result;
			};

			load() {
				$("#AdvancedItemSearch-resultContainer").html('');
				this.open();
				this.ctrl = UI.moduleSetUiDictionary(this.uiInputs);
				this.ctrl.enter_binding();
				this.bindKeyEnterToChangeEvent();
				this.autoselectUI.bindAllUiSelectionDelKey();
				this.guiFixes();
				this.ctrl.setDefaultIfEnterIdZero();
			};
	
		}

		class AdvancedItemSearchBAL {
			public ITEM_CATEGORY_ID = {
				MAIN_CAT:1, //Also known as Brand
				SUB_MAIN_CAT: 2,
				SECOND_CAT: 3,
				SUB_SECOND_CAT: 4,
				AGE_GROUP: 5,
				BRAND_GROUP: 6,
				TYPE_GROUP: 7
			};
		
		  /*
		   Example:
		   {
			itemCode: "777",
			itemDescription:"TestBarcode",
			itemPrice:"33"ת
			itemBarcode: "12342134"
			
		  }
		   */
		  BarcodeItems = [];
		  private _wasmdb:typeof Service.WasmDB
		  public paging:AdvancedItemSearchPagingBAL;
		  
		  
		  
		
		  constructor() {
			this._wasmdb = PositiveTS.Service.WasmDB;
			this.paging = new AdvancedItemSearchPagingBAL();
		  };
		  
		  
		  getSqlCategoryValuesByCategoryId(itemCategoryId){
			var SQL_TEMPLATE = 
			  "SELECT typeIdentifier, name from itemCategory \
			  WHERE categoryid = '<!!=itemCategoryId !!>'";
			var sql = _.template(SQL_TEMPLATE)({"itemCategoryId":itemCategoryId});
			return sql;
		  };
		  
		  
		 getAddiotionalConditionTemplate(ctrl, selectorCtrl, sqlTemplate, templateFieldName, additionalCondition) {
			  var queryParams = {};
			  var itemId = ctrl.dictUi[selectorCtrl].val; 
			  if (ctrl.dictUi[selectorCtrl].isAutoComplete){
				itemId = ctrl.dictUi[selectorCtrl].selected; 
			  }
			  
			  if (itemId) {
				additionalCondition += sqlTemplate;
			  }
			  queryParams[templateFieldName] = itemId;
			  
			  return _.template(additionalCondition)(queryParams);
		  };
		  
		  getAdditionalCondition(ctrl){
			var additionalCondition = "";
		
			for (var key in ctrl.dictUi){
			  if (ctrl.dictUi.hasOwnProperty(key)){
				var row = ctrl.dictUi[key];
				var itemTableOperand = "=";
				if (row.itemTableOperand) {
				  itemTableOperand = row.itemTableOperand;
				}
				
				if (ctrl.dictUi.hasOwnProperty(key) && row.itemTableKey) {
					  additionalCondition = this.getAddiotionalConditionTemplate(ctrl,
																	key,
																	"AND " + 
																	  row.itemTableKey + 
																	  " " + itemTableOperand + 
																	  " <!!=varItem !!> ",
																	"varItem",
																	additionalCondition);
				}
			  }
			}
		
			return additionalCondition; 
		
		  };
		  
		  getItemByBarcodeSQL(ctrl) {
			let SQL_TEMPLATE = 
			  "select item.code,item.description, item.priceZarhan, barcode from itembarcode \
			   inner join item on itembarcode.code = item.code  \
			   where itembarcode.barcode='<!!=itemCode !!>' ";
			   
			let itemCode = ctrl.dictUi["#AdvancedItemSearch-ItemCode"].val.toUpperCase();
			let queryParams:any = {};
			queryParams.itemCode = itemCode;
			return _.template(SQL_TEMPLATE)(queryParams);
		  };
		  
		  isBarcodeNotExists(code){
			for(var i=0; i<this.BarcodeItems.length; i++){
			  if (this.BarcodeItems[i].itemCode == code) {
				return false;
			  }
			}
			return true;
		  };
		  
		  addBarcodeItemToArray(row){
			this.BarcodeItems.push(
			  {
				itemCode: row.code,
				itemDescription: row.description,
				itemPrice: row.priceZarhan,
				itemBarcode: row.barcode
			  }
			);
		  };
		  
		  /*
		   * Return true if barcode found
		   */
		  public async AddItemBarcodeToArrayIfExists(ctrl){
			let result = await this._wasmdb.promiseSql( this.getItemByBarcodeSQL (ctrl) )
			
			let barcodeReturned = (result.length === 1);
			let barcodeNotExists = false;
			if (barcodeReturned) {
			barcodeNotExists = this.isBarcodeNotExists(result[0].code);
			}
			
			if(barcodeNotExists) {
			this.addBarcodeItemToArray(result[0]);
			}
			
			return barcodeNotExists;
			
		  };
		  
		  getItemSqlString(ctrl){
			var SQL_TEMPLATE = 
			  "SELECT * FROM item\
			   WHERE upper(code) LIKE '%<!!=itemCode !!>%'  \
					 AND upper(description) LIKE '%<!!=itemDescription !!>%'   \
					 AND cannotBeSoldSeparately = 0  \
					 <!!=additionalCondition !!> \
			   ORDER BY code ASC \
			   LIMIT " + this.paging.getSqlLimit();
			 
			var itemCode = ctrl.dictUi["#AdvancedItemSearch-ItemCode"].val.toUpperCase();
			var itemDescription = ctrl.dictUi["#AdvancedItemSearch-ItemDescription"].val.toUpperCase();
				
			
			var queryParams:any = {};
		
		
		
			var additionalConditionOut = this.getAdditionalCondition(ctrl);
			
			queryParams.itemCode = itemCode;
			queryParams.itemDescription = itemDescription;
			queryParams.additionalCondition = additionalConditionOut;
		   
			return _.template(SQL_TEMPLATE)(queryParams);
		  };
		  
		  completeResultSet(result) {
			var _return = [];
			for (let i=0;i<this.BarcodeItems.length; i++ ){
			  _return.push({
				itemCode: this.BarcodeItems[i].itemCode,
				itemDescription: this.BarcodeItems[i].itemDescription,
				itemPrice: this.BarcodeItems[i].itemPrice,
				itemBarcode: this.BarcodeItems[i].itemBarcode     
			  });
			}
			
			for (let i = 0 ; i<result.length;i++){
			  var row = result[i];
			  _return.push({
				itemCode: row.code,
				itemDescription: row.description,
				itemPrice: row.priceZarhan,
			  }); 
			}
			return _return;
		  };
		  
		  
		  
		  parseItemResult(result) {
			var completeRows = this.completeResultSet( result );
			var _return_ary = [];
			
			for (var i = this.paging.getFromRowNumber() ; i<completeRows.length;i++){
			  var row = completeRows[i];
			  _return_ary.push({
				itemCode: row.itemCode,
				itemDescription: row.itemDescription,
				itemPrice: row.itemPrice,
				itemBarcode: row.itemBarcode
			  }); 
			}
			return _return_ary;
		  };
		  
		  parseCategoryValuesResult(result) {
			var _return_ary = [];
			for (var i = 0 ; i<result.length;i++){
			  var row = result[i];
			  _return_ary.push({
				valu: row.typeIdentifier,
				label: row.name
			  }); 
			}
			return _return_ary;
		  };
		  
		  public async getCategoryValuesByCategoryId(itemCategoryId) {
			let result = await this._wasmdb.promiseSql( this.getSqlCategoryValuesByCategoryId (itemCategoryId) )
			return this.parseCategoryValuesResult(result);
		  };
		  
		  public searchByCtrlFields(ctrl) {
			return this._wasmdb.promiseSql( this.getItemSqlString (ctrl) )
			.then((result) => {
			  return this.parseItemResult(result);
			});
		  };
		}

		class  AdvancedItemSearchPagingBAL { 
			public pageSize = 6;
			public pageNumber = 1;
			public couldBeMorePages = false;
		
			public resetPageNumber() {
		  		this.pageNumber = 1;
			};
		
			public getSqlLimit() {
		  		return this.pageNumber * this.pageSize;
			};
		
			public getFromRowNumber() {
		  		return (this.pageNumber - 1) * this.pageSize;
			};
		
			public storeCouldBeMorePages(returnLen){
				this.couldBeMorePages = (returnLen >= this.pageSize && this.pageNumber >= 1); 
			};
		
		}
	}
}

declare var AdvancedItemSearchDialog:PositiveTS.Dialogs.AdvancedItemSearchDialog;
$(function() {
	
	(<any>window).AdvancedItemSearchDialogUI = $( "#advanced-item-search-dialog" ).dialog({
			autoOpen: false,
			modal: true,
			dialogClass: 'positive-dialog',
			width: '90%',
			height: window.innerHeight -20,
			maxHeight: 700,
			resizable: false,
			closeOnEscape: false,
			draggable: false,
		});
});
