module PositiveTS {
  export module Service {
    export class MultiCurr extends PositiveShared.MultiCurrencyService {

        static instance:MultiCurr = null;

        static getInstance(): MultiCurr {
          if (!MultiCurr.instance) {
            MultiCurr.instance = new MultiCurr();
          }

          return MultiCurr.instance;
        }

        isMultiCurr():boolean {          
          return jsonConfig.getVal(jsonConfig.KEYS.isMultiCurr) || jsonConfig.getVal(jsonConfig.KEYS.isDutyFree);
        }

        creditInOriginalCashCurrencyRelatively(): boolean {
          return jsonConfig.getVal(jsonConfig.KEYS.creditInOriginalCashCurrencyRelatively);
        }
        
        getCurrencyName (code: string): string {
          if (i18next.te(`currenciesNames.${code}`)){
            return i18next.t(`currenciesNames.${code}`)
          }
          return code
        }

        getCurrenciesEnabled (): Array<string> {
          return jsonConfig.getVal(jsonConfig.KEYS.currenciesEnabled)
        }

        getPosCurrenciesEnabled (): Array<string>{
          const baseCurrencyPos = this.getPosCurrency()
          let currenciesEnabled = this.getCurrenciesEnabled()
          
          if (currenciesEnabled && !currenciesEnabled.includes(baseCurrencyPos)) {
            currenciesEnabled.unshift(baseCurrencyPos)
          }

          if (currenciesEnabled == null) {
            currenciesEnabled = []
          }

          return currenciesEnabled
        }

        invoicePrintSlipCashParsing (aThis, payment, paymentTypes, amount) {

          const posCurrency = this.getPosCurrency()
          
          if (this.isMultiCurr()){
          
            if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CASH || payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE) {
              // On debit invoice we have payment.data with paid currency details
              var cash_in_curr_ary = JSON.parse(payment.data);
              if (Array.isArray( cash_in_curr_ary ) ) {
                for (var i=0;i < cash_in_curr_ary.length; i++){
                  aThis.printFloatingLineWithPrice(
                    paymentTypes[payment.method].displayName +
                    " " +
                    cash_in_curr_ary[i].paid_currency_symble +
                    " " +
                    session.fixedNumber(cash_in_curr_ary[i].paid_currency_amount),
                    session.fixedNumber(cash_in_curr_ary[i].lead_currency_amount)
                  );
                }
              }
              //pay in pos currency
              else {
                const amountPay = session.fixedNumber(amount)
                aThis.printFloatingLineWithPrice(   
                  `${paymentTypes[payment.method].displayName} ${posCurrency} ${amountPay}`,
                  amountPay    
                );
              }
        
              return true;
            } 
            
            if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER) {
              
              aThis.printFloatingLineWithPrice(
                `${paymentTypes[payment.method].displayName} ${posCurrency}`,
                session.fixedNumber(amount)
              );
        
              return true;
            } 

          }
  
          return false;	
        }
        
        checkIfNeedTranslateAmountAndReturn (amount: number): number {

          const paymentCurrencyCode = this.getPaymentCurrencyCode()

          if (paymentCurrencyCode){     
            return this.translateAmount(amount, paymentCurrencyCode) 
          }

          return amount
        }

        translateAmount (amount: number, translateFromCurrency: string): number {
          amount = Number(amount)
          const currencyRate = posPaymentVC.Currencies[translateFromCurrency]
          
          return Number(amount / currencyRate)
        }

        translateAmountAndCreateRowSalePaymentData (amount, translateFromCurrency, paidCurrencyRate = null): [number, any] {

          const paid_currency_rate = paidCurrencyRate ? paidCurrencyRate : posPaymentVC.Currencies[translateFromCurrency]
          const lead_currency_amount = (amount / paid_currency_rate)
          const row = {
            lead_currency_amount: lead_currency_amount, 
            lead_currency_symble: this.getPosCurrency(),
            lead_currency_rate: 1,  
            paid_currency_symble: translateFromCurrency,
            paid_currency_amount: amount,				
            paid_currency_rate: paid_currency_rate,
            paid_currency_last_update_rate: posPaymentVC.LastUpdateCurrencies,
            usd_to_ils_rate: posPaymentVC.Currencies["ILS"]
          }
  
          return [lead_currency_amount, row] 
              
        }

        multiplyCurrencyData(currencyData, ratio) {
          currencyData.lead_currency_amount = currencyData.lead_currency_amount * ratio;
          currencyData.paid_currency_amount = currencyData.paid_currency_amount * ratio;
          
          return currencyData;
        }
  
        addRowSalePaymentData (salePaymentData, row) {
  
          let arrayPaymentData = JSON.parse(salePaymentData); 
  
          if (!arrayPaymentData || !Array.isArray(arrayPaymentData)) {
            arrayPaymentData = []
          }
  
          if (session.pos.hasFlights) {

            let rowCurrencyIndex = null;
            
            for (let currenciesIndex in arrayPaymentData) {
              if (arrayPaymentData[currenciesIndex].paid_currency_symble == row.paid_currency_symble) {
                rowCurrencyIndex = currenciesIndex;
                break;
              }
            }

            if (rowCurrencyIndex) {
              arrayPaymentData[rowCurrencyIndex].lead_currency_amount += row.lead_currency_amount;
              arrayPaymentData[rowCurrencyIndex].paid_currency_amount += row.paid_currency_amount;
            } else {
              arrayPaymentData.push(row)
            }
          } else {
            arrayPaymentData.push(row)
          }
  
          return JSON.stringify(arrayPaymentData)
        }

        getCurrencyCodeEMV (paymentToCredit): string{
          if (!this.isMultiCurr() || !paymentToCredit.creditCurrency) {
            return "376"
          }
          return PositiveShared.MultiCurrencyService.currenciesEMV[paymentToCredit.creditCurrency]
        }

        getCurrencySymbolEMV(paymentToCredit): string {
          if (!this.isMultiCurr()) {
            return 'ILS';
          }
          
          let currencySymbolByEmvCode = PositiveShared.MultiCurrencyService.inverterdCurrenciesEmv;

          return paymentToCredit.creditCurrency || currencySymbolByEmvCode[paymentToCredit.Currency];

        }

        getPaymentCurrencyCreditRequestEMV () {
          let paymentCurrencyCode = this.getPaymentCurrencyCode()

          if (!paymentCurrencyCode){
            paymentCurrencyCode = this.getPosCurrency()
          }
          
          if (!PositiveShared.MultiCurrencyService.currenciesEMV[paymentCurrencyCode]){
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t('selectCurrencyPayment.unsupportedCurrency', 
                {currency: i18next.t(`currenciesNames.${paymentCurrencyCode}`)}),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            },
            null,null)

            throw Error('Curreny payment not support')
          }

          return PositiveShared.MultiCurrencyService.currenciesEMV[paymentCurrencyCode]
        }

        getI18nextCurrency(currency: string) {
          return i18next.t(`currencies.${currency}`);
        }

        getPosCurrency (): string {
          return posUtils.isBlank(session.pos.baseCurrency) ? 'ILS' : session.pos.baseCurrency;
        }

        get i18nextPosCurrency(): string {
          return this.getI18nextCurrency(this.getPosCurrency());
        }

        getPosCurrencySign() {
          return this.getCurrencySign(this.getPosCurrency());
        }

        addCurrencySignIfNeeded(price): string {
          if (!jsonConfig.getVal(jsonConfig.KEYS.showCurrencySign)) {
            return price;
          }

          return this.addCurrencySign(price);
        }

        addCurrencySign(price, withoutSpace = false): string {
          let sign = this.getPosCurrencySign();

          if (PositiveShared.MultiCurrencyService.LTR_CURRENCY_SIGNS[this.getPosCurrency()] || withoutSpace) {
            return `${sign}${price}`
          } else {
            return `${price} ${sign}`
          }
        }


        getCurrencyNameFromEMVCode (code: string): string {
          let currenciesEMVInverted = PositiveShared.MultiCurrencyService.inverterdCurrenciesEmv
          if(i18next.te('currenciesNames.' + currenciesEMVInverted[Number(code)])){
            return i18next.t('currenciesNames.' + currenciesEMVInverted[Number(code)])
          }
          else{
            return code;
          }
        }

        getCurrencyNameFromCreditData(data) {
          if (session.pos.isEmv && !data.isGateway) {
            return this.getCurrencyNameFromEMVCode(data.Currency)
          }else if (data.isGateway) {
            return this.getCurrencyName(data.Currency)
          }
          else {
            return data.creditCurrency;
          }
        }

        getCurrrencyPaymentDataBaseCurrencyAmount(currencyPaymentData) {

          if (!posUtils.isBlank(currencyPaymentData.creditLeadCurrencyAmount)) {
            return currencyPaymentData.creditLeadCurrencyAmount;
          }

          let currency = this.getCurrencySymbolEMV(currencyPaymentData);

          return this.translateAmount(currencyPaymentData.Amount / 100, currency);
   
        }

        checkIfNeedConversionAmountAndReturn (amount: number): number {
          const paymentCurrencyCode = this.getPaymentCurrencyCode()

          if (paymentCurrencyCode){       
            return this.conversionAmount(amount, paymentCurrencyCode) 
          }
          return amount
        }

        conversionAmount (amount: number, currencyConversionCode: string): number {
          const currencyRate = posPaymentVC.Currencies[currencyConversionCode]
          return amount * currencyRate
        }

        getPaymentCurrencyCode (): string {
          return Pinia.globalStore.paymentCurrency
        }

        getObjectDataCreditCard (amount) {
          const posCurrency =  this.getPosCurrency()
          let data = {
            creditCurrency: posCurrency,
            currencyRate: 1,
            creditLeadCurrencyAmount: amount
          }
          const paymentCurrencyCode = this.getPaymentCurrencyCode()
          const currencyPaymentIsNotPosCurrency = paymentCurrencyCode && paymentCurrencyCode != posCurrency

          if (currencyPaymentIsNotPosCurrency){
            data.creditCurrency = paymentCurrencyCode
            data.currencyRate = posPaymentVC.Currencies[paymentCurrencyCode] || 1
            data['creditCurrencyFullNumber'] = (Number(amount) * data.currencyRate)
          }

          return data 
        }
    }
}}
