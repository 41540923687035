module PositiveTS {
	export module Storage {
		export module Entity {
			export class PositiveCustomer extends IDBEntity {
				
				
				apartment_number: string
				birthday_promotion_used_at: string
				block_from_mailing_list: boolean
				block_from_sms_list: boolean
				blocked: boolean
				city: string
				current_points: number
				customer_club_id: number
				customer_group_id: number
				customer_number: string
				customer_tz: string
				date_of_birth: string
				discount_percent: number
				email: string
				first_name: string
				gender: string
				join_date: string
				join_promotion_used_at: string
				last_name: string
				new_customer: boolean
				phone_number: string
				street_address:string
				tenant_id: number
				total_purchases: number
				valid_until: string
				zip_code: string
				promotion_types: string
				timestamp: number
				search:any
				
				constructor() {
					let meta = {
						name: 'PositiveCustomer',
						fields: {
							apartment_number: "TEXT",
							birthday_promotion_used_at: "TEXT",
							block_from_mailing_list: "BOOL", 
							block_from_sms_list: "BOOL", 
							blocked: "BOOL", 
							city: "TEXT",
							current_points: "FLOAT",
							customer_club_id: "INT",
							customer_group_id: "INT",
							customer_number: "TEXT",
							customer_tz: "TEXT",
							date_of_birth: "TEXT",
							discount_percent: "FLOAT",
							email:  "TEXT",
							first_name: "TEXT",
							gender:  "TEXT", 
							join_date: "TEXT",
							join_promotion_used_at: "TEXT",
							last_name: "TEXT",
							new_customer: "BOOL",
							phone_number: "TEXT",
							street_address: "TEXT",
							tenant_id: "INT",
							total_purchases: "FLOAT",
							valid_until: "TEXT",
							zip_code: "TEXT",
							promotion_types: "TEXT",
						}
					}
					super(meta)
				}
				
				static search(searchInput):any {
					let reqStr = `/positive_customer_club?club_name=positive&search=${searchInput}`
					
					if ( jsonConfig.getVal(jsonConfig.KEYS.doNotSeachInternalCustomerClubByName) ){
						reqStr = `/positive_customer_club?club_name=positive&do_not_search_text=true&search=${searchInput}`
					}

					if (PositiveTS.Reachability.isOnline) {
						
						return PositiveTS.Service.FetchReq.jsonReq(reqStr,"get")
					}
					
					if(session.pos.onlineOnlyMemberClub){
						let _result = {i_return_code:"2", customers:[],s_return_msg:i18next.t("positiveCustClub.companyHasOnlineOnlySearch")};
						return Promise.resolve({result: _result})
					}
					
					if(jsonConfig.getVal(jsonConfig.KEYS.hasOnlyOnlineCustomerClub)){
						return Promise.resolve({
							result: {i_return_code:"2", customers:[], s_return_msg:i18next.t("positiveCustClub.posHasOnlineOnlySearch")}
						})
					}
					

					let trimedSearchInput = searchInput.trim()

					let fuzzySearchInput = [trimedSearchInput]

					let searchInputStartsWithZero = trimedSearchInput[0] == "0"

					if(searchInputStartsWithZero) {
						fuzzySearchInput.push(trimedSearchInput.substring(1))
					}

					return appDB.positiveCustomer.where('search').startsWithAnyOfIgnoreCase(fuzzySearchInput).limit(50).toArray()
					.then( rows => {
						const notFuond = "4"
						const success = "1";
						let _result = {i_return_code:notFuond, customers:[]};
						if ( rows.length > 0) {
							let custs = []
							for (let i=0; i< rows.length; i++) {
								rows[i].promotion_types = JSON.parse(rows[i].promotion_types)
								custs.push(rows[i])
							}
							_result = {i_return_code:success, customers:custs};
						}
						return {result: _result}
					});
					
					
				}
				
			}}}}
