module PositiveTS {
    export module Components {
    export module DTSCustomerClub {
    
      const _dialogSelector = "#dts-customer-club";
      const _vueComponentName = 'dts-customer-club';
      
      export function create() {
        let dtsCustomerClub = {
            template: JST.dtsCustomerClub(),
            methods: {
              open: open,
              close: close,
              async findCustomer() {
                let dtsService = new Service.Dts();
                app.showLoadingMessage(i18next.t('dtsCustomerClub.lookingForCustomer'))
                try{
                  let res = await dtsService.getBalance(this.card, this.pinCode,this.inputMethod);
                  if(res.Result.ResultCode == 0){
                    this.items = []
                    for(let item of res.Items){
                      item.qtyToUse = 0
                      this.items.push(item)
                    }
                    this.customer = res.Customer;
                    this.customerName = `${i18next.t("dtsCustomerClub.customer")}: ${res.Customer.MemberId} ${res.Customer.FirstName} ${res.Customer.LastName} ${i18next.t("dtsCustomerClub.fromClub")}: ${res.Customer.OrganizationName}` 
                  }
                  else{
                    this.card = null;
                    this.pinCode = "0000";
                    app.showAlert({
                      header: i18next.t("error"),
                      content: res.Result.ResultFriendlyMessage,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                  }
                }
                catch(error){
                  console.error(error);
                  this.card = null;
                  this.pinCode = "0000";
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('dtsCustomerClub.error'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }


                app.hideLoadingMessage();
  
              },
              async pay() {
                  app.showLoadingMessage(i18next.t('dtsCustomerClub.chargingCard'))
                  await posVC.restartSale()
                  for(let item of this.items){
                    for(let i=0;i<item.qtyToUse;i++)
                      if(session.allItems.get(item.FullBarCode)){
                        await posVC.addItemByBarcodeOrByCode(item.FullBarCode, true, true)
                      }
                      else{
                        app.hideLoadingMessage();
                        app.showAlert({
                          header: i18next.t("error"),
                          content: i18next.t("dtsCustomerClub.itemNotFoundInPos",{code: item.FullBarCode}),
                          continueButtonText: i18next.t("ok"),
                          hideCancelButton: true,
                        })
                        await posVC.restartSale();
    
                        return;
                      }
                  }

                  if(posVC.saleItems.length == 0){
                    app.hideLoadingMessage();
                    app.showAlert({
                      header: i18next.t("error"),
                      content: i18next.t("dtsCustomerClub.noItemsInSale"),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })

                    return;
                  }

                  posPaymentVC.updateAmountsIndicators()
                  await Service.VoucherPayment.payBySmartVoucherType(Storage.Entity.Voucher.SMART_VOUCHER_DTS,posVC.getTotalAmount(),this.card,undefined,{customer: this.customer,items: this.items});
                                   
                  if(posVC.salePayments.length > 0){ //Voucher payment was succssfull
                    this.cancel();

                  }
                  else{
                    await posVC.restartSale()
                  }

              },
              async swipeCard(){
                app.showLoadingMessage(i18next.t('dtsCustomerClub.swipeCard'));
                let cardNumber = await Service.EMV.swipe();
                if(cardNumber){
                  this.card = cardNumber;
                  await this.findCustomer();
                }
                app.hideLoadingMessage();
              },
              chooseItem(selectedItem){
                selectedItem.qtyToUse = selectedItem.Quantity
              },
              cancelItem(item){
                item.qtyToUse = 0;
              },
              cancel() {
                this.items = [];
                this.card = "";
                close();
              }
            },
            computed: {
              mobileLayout(){
                return Pinia.globalStore.mobileLayout
              },
            },
            data: _initData,
        }
        VueApp.component(_vueComponentName,dtsCustomerClub)
      }
    
      function open(){
        posPaymentVC.init()
        
        $(function() {
          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: Pinia.globalStore.mobileLayout ? '100vw' : '90vw',
            height: Pinia.globalStore.mobileLayout ? window.outerHeight : 670,
            resizable: false,
            closeOnEscape: false,
            draggable: false,
          });
          $(_dialogSelector).dialog('open');
        });
    
    
        $(document).unbind('keypress');

        if(jsonConfig.getVal(jsonConfig.KEYS.nirDavidScanEntranceBarcode)) {
          Pinia.globalStore.setShowNirDavidEntranceDialog(false);
        }
      }
    
      function _initData(){
        return {
          items: [],
          card: null,
          pinCode: "0000",
          saleCustomerPoints: null,
          customerName: "",
          customer: null,
          promotionApplied: false,
          inputMethod: 4, 
          totalAmount: 0,
          allowOtherPayments: false,
          lastKeypressTimestamp: 0
        };
      }
    
      function close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);

        if(jsonConfig.getVal(jsonConfig.KEYS.nirDavidScanEntranceBarcode)) {
          Pinia.globalStore.setShowNirDavidEntranceDialog(true);
        }
      }
    }}}