module PositiveTS {

export module Components {

  export module SelectCurrencyPayment {

    const dialogSelector = 'select-currency-payment'

    function initData() {
      let data: {
        dialogElem: any,
        currencies: Array<string>
      } = {
        dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
        currencies: []
      }
      return data
    }

    function cancel () {
      PositiveTS.Service.Currencies.updatePosPaymentCurrency(null);
      this.close()
    }

    function close () {
      if (this.dialogElem == null) {
        this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
      }

      this.dialogElem.close()   
    }

    function open () {
      if (this.dialogElem == null) {
        this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
      }

      if(jsonConfig.getVal(jsonConfig.KEYS.allowedCurrenciesPerPaymentMethod)){
        this.$nextTick(() => {
          this.setCurrencies()
        })
      }

      this.dialogElem.showModal() 
    }

    function setCurrencyAndClose(code: string) {
      PositiveTS.Service.Currencies.updatePosPaymentCurrency(code);
      this.close()
    }

    function setCurrencies () {
      this.currencies = Service.MultiCurr.getInstance().getPosCurrenciesEnabled()
      if(jsonConfig.getVal(jsonConfig.KEYS.allowedCurrenciesPerPaymentMethod)){
        let currenciesAfterFilter = []
        let allowedCurrenciesPerPaymentMethod = jsonConfig.getVal(jsonConfig.KEYS.allowedCurrenciesPerPaymentMethod)
        for(let currency of this.currencies){
          if(!allowedCurrenciesPerPaymentMethod[currency] ||
            allowedCurrenciesPerPaymentMethod[currency][String(posPaymentVC.currentPaymentMethod.method)]){
            currenciesAfterFilter.push(currency);
          }
        }
        this.currencies = currenciesAfterFilter
      }
    }

    function getCurrencyName (code: string): string {
      return PositiveTS.Service.MultiCurr.getInstance().getCurrencyName(code)
    }

    export function create() {

      let component = {

        name: 'SelectCurrencyPayment',

        template: JST.selectCurrencyPayment(),

        methods: {
          open,
          close,
          setCurrencyAndClose, 
          setCurrencies,
          cancel,
          getCurrencyName
        },

        data: initData,
        setup(){

          const globalStore = Pinia.useGlobalStore();
          const {currency,paymentCurrency} = Pinia.storeToRefs(globalStore);
          const globalStoreProps = {posCurrency:currency,paymentCurrency} 

          return {...globalStoreProps}
        },

        mounted () {
          if(!jsonConfig.getVal(jsonConfig.KEYS.allowedCurrenciesPerPaymentMethod)){
            this.$nextTick(() => {
              this.setCurrencies()
            })
          }
        }
      
      }

      VueApp.component('select-currency-payment',component)

      }

    }
  }
}
