<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useGlobalStore } from '@/Store/globalStore';
const globalStore = useGlobalStore();
const welcomeScreen = session.pos.welcomeScreenObj;
const dialogElement = ref(null);
const classes = ref(['self-service-bg-image']);
if (!jsonConfig.getVal(jsonConfig.KEYS.isCancelOpacity)) {
    classes.value.push('with-opacity')
}

onMounted(() => {
    open();
});

const close = () => {
    globalStore.deactivateScreenSaver = false;
    dialogElement.value.close();
}

const open = () => {
    globalStore.deactivateScreenSaver = true;
    dialogElement.value.showModal();
    setTimeout(() => {
        close();
    }, welcomeScreen.timeout * 1000);
}

const isValidImage = (image: string) => {
    return image && image !== 'null' && image !== '/missing.png';
}

defineExpose({
    open
})
</script>

<template>
    <dialog class="flex flex-col h-100vh w-100vw justify-center items-center text-center" :class="classes"
        ref="dialogElement" @click="close">

        <div class="absolute top-5 right-5">
            <font-awesome-icon :icon="['fal', 'circle-xmark']" class="text-5xl" />
        </div>

        <h1 class="text-7xl h-20vh">{{ welcomeScreen.title }}</h1>

        <p class="text-5xl h-30vh">
            {{ welcomeScreen.content }}
        </p>
        <img :src="welcomeScreen.second_image" v-if="isValidImage(welcomeScreen.second_image)" 
        alt="welcomeScreen.second_image" class="my-16 h-10vh object-cover" />

        <img :src="welcomeScreen.image" v-if="isValidImage(welcomeScreen.image)" 
        alt="welcomeScreen.image" class="h-20vh object-cover" />

    </dialog>
</template>