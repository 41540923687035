module PositiveTS {
export module Components {
export module Mixins {
  export var modalsMixin = {
    methods: {

      showGenericErrorDialog: function(){
        app.showAlertDialog({
          header: i18next.t('error'),
          content: i18next.t('generalError'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      },

      showGenericError: function () {
        app.showAlert({
          header: i18next.t('error'),
          content: i18next.t('generalError'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      },

      showErrorDialog: function(errStr) {
        app.showAlertDialog({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      },

      showError: function(errStr:string) {
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      },

      promiseShowError: function(errStr) {
        return app.promiseShowAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      },

      showAlertGenericDialog: function(message, okCallback = null, cancelCallback = null, hideCancelButton = true,
        continueButtonText =  i18next.t("ok"), cancelButtonText = i18next.t("cancel")) {
      app.showAlertDialog({
        header: i18next.t('error'),
        content: message,
        continueButtonText:continueButtonText,
        cancelButtonText: cancelButtonText,
        hideCancelButton: hideCancelButton
      }, okCallback, cancelCallback);
     },

      showAlertGeneric: function(message, okCallback = null, cancelCallback = null, hideCancelButton = true,
          continueButtonText =  i18next.t("ok"), cancelButtonText = i18next.t("cancel")) {
        app.showAlert({
          header: i18next.t('error'),
          content: message,
          continueButtonText:continueButtonText,
          cancelButtonText: cancelButtonText,
          hideCancelButton: hideCancelButton
        }, okCallback, cancelCallback);
      },

      showAlertWithTitleGeneric: function(message, title = i18next.t('error'), okCallback = null, cancelCallback = null, hideCancelButton = true,
        continueButtonText =  i18next.t("ok"), cancelButtonText = i18next.t("cancel")) {
        app.showAlert({
          header: title,
          content: message,
          continueButtonText:continueButtonText,
          cancelButtonText: cancelButtonText,
          hideCancelButton: hideCancelButton
        }, okCallback, cancelCallback);
      },

      promiseShowAlertGenericDialog: function(message, hideCancelButton = true,
        continueButtonText =  i18next.t("ok"), cancelButtonText = i18next.t("cancel"), 
        header = i18next.t('error')) {
        return app.showAlertDialog({
          header: header,
          content: message,
          continueButtonText:continueButtonText,
          cancelButtonText: cancelButtonText,
          hideCancelButton: hideCancelButton,
        }, null, null);
      },

      promiseShowAlertGeneric: function(message, hideCancelButton = true, header = i18next.t('error'),
        continueButtonText =  i18next.t("ok"), cancelButtonText = i18next.t("cancel")) {
        return app.promiseShowAlert({
          header: header,
          content: message,
          continueButtonText:continueButtonText,
          cancelButtonText: cancelButtonText,
          hideCancelButton: hideCancelButton
        }, null, null);
      }

    }
  }

}}}
