module PositiveTS {
export module Storage {
export module Entity {
export class Brand extends WasmEntity {

	constructor() {
		let meta = {
			name: 'Brand',
			fields: {
				brandID: "TEXT",
			  	name: "TEXT"
			},
			unique: ['brandID']
		};
		super(meta)
	}
}}}}
