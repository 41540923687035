module PositiveTS { 
  export module Printing {
  export module HtmlInvoice{
    export class ObjCustomerClub {
      hasCustomer: boolean = false;
      customerString: string;
      clubString: string;
      customerNameId: string;
      storeName: string;
      customerAddress: string;
      customerPhone: string;
      customerCP: string;

      constructor(sale, saleItems, salePayments) {
        var svcCustomerClub = new PositiveTS.Service.CustomerClub(sale, salePayments, saleItems);
        var dictCustomer;
        if (svcCustomerClub.isCurrentCustomerExists()) {
          dictCustomer = svcCustomerClub.getCurrentSelectedCustomer();
          this.hasCustomer = true;
        } else {
          return;
        }

        var fullName = posUtils.null2empty(dictCustomer.s_first_name) + " " + posUtils.null2empty(dictCustomer.s_last_name);
        if (posUtils.null2empty(dictCustomer.s_first_name) === posUtils.null2empty(dictCustomer.s_last_name)) {
          fullName = posUtils.null2empty(dictCustomer.s_first_name);
        }

        this.customerString =  `${fullName}  ( ${dictCustomer.s_id_number} ) <br /> `;
        this.customerNameId =  `${fullName}`;

        if (dictCustomer.address){
          this.customerString = this.customerString  +  dictCustomer.address + "<br /> ";
          this.customerAddress = dictCustomer.address;
        }

        if (dictCustomer.s_phone_number_1){
          this.customerString = this.customerString  +  dictCustomer.s_phone_number_1 + "<br /> "
          this.customerPhone = dictCustomer.s_phone_number_1;
        }

        if (dictCustomer.s_id_number){
          this.customerCP = dictCustomer.s_id_number;
        }

        
        this.clubString = PositiveTS.Service.CustomerClub.CUSTOMER_CLUBS[dictCustomer.clubName.toLowerCase()].description;

      }
    }

  }}}
