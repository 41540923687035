module PositiveTS {
  export module Components {
    export module RestaurantItemActions {
      export function getComponent() {
        let component = {
          template: JST.RestaurantItemActions(),
          props: {
            item: {
              type: Storage.Entity.SaleItem,
              required: false,
              default: null,
            }
          },
          mixins: [PositiveTS.Components.Mixins.saleItemMixin],
          components: {
            PositiveIcon: PositiveIcon.getComponent(),
          },
          computed: {
            allowMoveDalpak(){
              if(this.hasItemProp){
                this.canMoveDalpak(this.item)
              }
              return true;
            },
            hasItemProp(){
              return this.item !== null;
            },
            computedItem() {
              if(this.item === null){
                if(this.selectedCount > 0){
                  return this.selectedItems[0];
                }else return {};
              }
              return this.item;
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {getCurrentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);
            
            const posStore = Pinia.usePosStore();
            const {expandedView,selectModeActive,selectedCount,selectedItems} = Pinia.storeToRefs(posStore);
            const  posStoreProps = {expandedView,selectModeActive,selectedCount,selectedItems}

            const {
              selectItem,
              incrementQuantity,
              decrementQuantity,
              setManualQuantitiy,
              moveDalpak,
              editItemWWithPrepInstructions,
              selectDiscountHandler,
              confirmMultipleSaleItemsDelete,
              duplicateItem,
              editItemWithGroups
            } = posStore;
            const posStoreActions = {
              selectItem,
              incrementQuantity,
              decrementQuantity,
              setManualQuantitiy,
              moveDalpak,
              editItemWWithPrepInstructions,
              selectDiscountHandler,
              confirmMultipleSaleItemsDelete,
              duplicateItem,
              editItemWithGroups
            }

            const globalStore = Pinia.useGlobalStore();
            const {
              canMoveDalpak,
              canIncrementQuantity,
              canDecrementQuantity,
              canChangeQuantity,
              canEditItem,
              canDeleteItem,
              isSelfServiceTa,
              canMakeItemDiscount
            } = Pinia.storeToRefs(globalStore);

            const globalStoreProps = {
              canMoveDalpak,
              canIncrementQuantity,
              canDecrementQuantity,
              canChangeQuantity,
              canEditItem,
              canDeleteItem,
              isSelfServiceTa,
              canMakeItemDiscount
            } 

            const {saleItemSelected} = globalStore

            return {...globalStoreProps,...posStoreActions,...posStoreProps,getCurrentSelectedDalpak,saleItemSelected}
          },
          methods: {
            async printBon(skipReprintSilently = false) {
              await PositiveTS.Components.Pos.printBon(skipReprintSilently)
              return;
            },
            async selectItemAndEdit(saleItem = null) {
              try{
                if (saleItem) {
                  this.saleItemSelected(saleItem);
                }
                if(saleItem.hasPreparationInstructions){
                  await this.editItemWWithPrepInstructions()
                }
                if(saleItem.hasGroups){
                  await this.editItemWithGroups();
                }
              }catch(err){
                console.error(err);
              }

            },
            confirmSaleDelete() {
              if(this.selectedCount > 1){
                this.confirmMultipleSaleItemsDelete();
              }else{
                let saleItem = this.computedItem;
              this.saleItemSelected(saleItem);
              posVC.confirmSaleItemDelete();
              }
            },
            selectDiscountHandlerItem() {
              if(this.selectedCount < 1){
                this.selectItem(this.computedItem);
                this.saleItemSelected(this.computedItem);
              }
              this.selectDiscountHandler();
            },
            moveDalpakHandlerItem() {
              if(this.selectedCount < 1){
                this.selectItem(this.computedItem);
                this.saleItemSelected(this.computedItem);
              }
              this.moveDalpak();

            },
            deleteDiscountFromSaleItem: (saleItem, disablePromotions) => posVC.deleteDiscountFromSaleItem(saleItem, disablePromotions),
          },
          data() {
            return {
              allowMultipleSaleItemsSelection: Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)),
              ignorePrintingOfOnlySelectedSaleItems: false,
              allowQuickBonPrinting: jsonConfig.getVal(jsonConfig.KEYS.allowQuickBonPrinting),
              allowBonPrinting :jsonConfig.getVal(jsonConfig.KEYS.allowBonPrinting),
            };
          },
        }
        return component;
      }
    }
  }
}
