module PositiveTS {
    export module Service {
        interface ZReportForServerReqeust {
            z_reports: {
                [name: string]: { z_report: any }
            }
        }
        export module ZReportsSynchronizer {

            // Creates the Request Body
            function createZReporstPostJson(zReport: PositiveTS.Storage.Entity.ZReport): ZReportForServerReqeust {
                let currentJsonReport = zReport.toSyncObject();
                return {
                    'z_reports': {
                        [zReport.id]: { 'z_report': currentJsonReport },
                    }
                }
            }

            function setZReportAsFailed(zReport: PositiveTS.Storage.Entity.ZReport, errorMessage: string) {
                zReport.syncStatus = PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_FAILED;
                zReport.syncLastMessage = errorMessage;
                zReport.syncLastMessageTimestamp = PositiveTS.DateUtils.fullFormat();
                return zReport;
            }

            function setZReportAsSuccess(zReport: PositiveTS.Storage.Entity.ZReport, confirmationNumber: string) {
                zReport.syncStatus = PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_SYNCED_SUCCESFULLY;
                zReport.syncLastMessage = 'Sale was synced successfully!';
                zReport.confirmationNumber = confirmationNumber;
                zReport.syncLastMessageTimestamp = PositiveTS.DateUtils.fullFormat();
                return zReport;
            }

            export async function SendZReportToServer(zReport: PositiveTS.Storage.Entity.ZReport) {
                if (!PositiveTS.Reachability.isOnline) {
                    throw new Error("offlineError");
                }

                let req = createZReporstPostJson(zReport);
                let res: any = {};
                try {
                    res = await PositiveTS.Service.ZReport.sendReports(req);
                } catch (error) {
                    console.error(error);
                    res['error'] = error.message ? error.message : error;
                }


                if ('error' in res) {
                    setZReportAsFailed(zReport, 'Sync failed due to missing properties from result object.');
                    throw new ErrorWithData("syncToRemoteServerError", zReport);
                } else {
                    let resultForZReport = res[zReport.id];
                    if (resultForZReport.result) {
                        zReport = await PositiveTS.Helper.ReportsHelper.convertServerReportToZReport(resultForZReport.z_report, zReport);
                        setZReportAsSuccess(zReport, resultForZReport.confirmation);
                    } else {
                        setZReportAsFailed(zReport, resultForZReport.message);
                        throw new ErrorWithData("syncToRemoteServerError", zReport);
                    }
                }

                return zReport;
            }
        }
    }
}