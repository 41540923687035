
module PositiveTS {  
    export module Service {    
        export module TheftDetection {
        interface Rule {
            fromSaleAmount: number,
            toSaleAmount: number,
            salesCount: number
        }

            export async function checkIfSaleNeedToBeApproved(){
                    const counter:number = +localStorage.getItem('theftSalesCounter')
                    const rules: Array<Rule> = jsonConfig.getVal(jsonConfig.KEYS.selfServiceTheftDetectionRules)
                    const totalAmount = posVC.getTotalAmount()
                    const activateManagerApproval = rules.some(rule => (totalAmount >= rule.fromSaleAmount && totalAmount <= rule.toSaleAmount) &&  counter % rule.salesCount  === 0)

                    if(activateManagerApproval && !Pinia.globalStore.isSaleApproved){
                        return true
                    } else{
                        return false
                    }

            }

        }

    }
}