module PositiveTS {
  export module Storage {
  export module Entity {
  //represents an entity that is persisted in IndexedDB (and not webSQL)
  export class IDBEntity extends Entity { 

    constructor(meta) {
      super(meta);
    }

    async all(param?) {
      let result = await appDB[this.meta.name].toArray();
      return result.map(item => this.importFromObject(item));
    }


  
    defaultVal(field) {

      // console.warn(`assigning default value to a missing field in entity ${this.meta.name}. Field is ${field}`);
      switch (this.meta.fields[field]) {
        case "TEXT":
          return "";
        case "BOOL":
          return false;
        case "INT":
        case "FLOAT":
          return 0;
        case "JSON":
          return "{}";
        default:
          return undefined;
      }
    }

    static async updateCaches() {
      if (session.pos.isRoshemet) {
        let promises = [];
        promises.push(appDB.departments.orderBy('[sortOrder+name]').toArray())
        promises.push(appDB.localItems.toCollection().sortBy('description'))
        let results = await Promise.all(promises);
        session.allDepartments = results[0];
        let allItems = results[1];
        allItems.forEach(item => {
          session.allItems.set(item.code,item);
          if (!posUtils.isNullOrUndefinedOrEmptyString(item.barcode)) {
            session.allItemBarcodes.set(item.barcode,item);
          }
        })
      }
    }


    importFromObject(importFrom) {

      for (let field in this.meta.fields) {
        if (!(field in importFrom)) {
          this[field] = this.defaultVal(field)
        }
        else {
          this[field] = importFrom[field];
        }
      }

      // If there is an ID, mark the entity as not new
      if ('id' in importFrom) {
        this.id = importFrom.id;
        this.timestamp = importFrom.timestamp;
        this._new = false;
      }
      return this;
	  };

    async persist(tx?) {
      return;
    }

    async createTable() {
      //we do nothing here as tables are created in sharedDB via Dexie;
      return;
    }

    //convert from webSQL to indexedDB table
    //usage example is: PositiveTS.Storage.Entity.IDBEntity.convertToIDB(PositiveTS.Storage.Entity.Sequence,"sequences")
    static async convertToIDB(data:any[], tableName:string):Promise<any> {
      if (data.length > 0) {
        await appDB[tableName].bulkPut(data)
      }
      await appDB.convertedTables.put(new ConvertedTable(tableName,data.length))
      return true;
    }
  }

  export class ConvertedTable {
    tableName:string
    id:number
    timestamp:number
    convertedRecords:number
    constructor(name:string, numberOfConvertedRecords) {
      this.tableName = name;
      this.timestamp = (new Date()).getTime();
      this.convertedRecords = numberOfConvertedRecords;
    }
  }
}}}
