
import { defineStore } from 'pinia';
const FIFTEEN_SECONDS = 15000
const state = {
    isOnline: true,
    pingURL: "https://www.google.com/",
};
const getters = {
    getOnlineStatus: (state) =>{
        return state.isOnline;
    },
};

const actions = {
    setOnlineStatus(payload) {
        this.isOnline = payload;
    },
    debouncePingToURL: _.debounce(function () {
        this.pingToURL();
    }, FIFTEEN_SECONDS),

    async startConnectivityCheck() {
        this.pingToURL();
        this.debouncePingToURL();
    },
    async pingToURL() {
        try {
            let result = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponse(this.pingURL, "GET");
            if (result?.status === 200) {
                this.setOnlineStatus(true);
            } else if (result?.status === -1) {
                this.setOnlineStatus(false);
            }
            this.debouncePingToURL();
        } catch (err) {
            this.debouncePingToURL();
            this.setOnlineStatus(false);
        }
    },
};


const connectivityStore = defineStore('connectivity', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default connectivityStore;
