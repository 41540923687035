module PositiveTS {
export module Components {
export module ChangePasswordDialog {

  const _dialogSelector = "#positive-change-password-dialog"
  const _vueComponentName = 'change-password-dialog';

  export function create() {
    let changePasswordDialog = {
        template: JST.changePasswordDialog(),
        methods: {
          open: open,
          close: close,
          
          async changePassword() {
            this.newPasswords.employee_id = session.pos.employeeID;

            let success = (await PositiveTS.Service.FetchReq.jsonReq('/employee_password', 'put', this.newPasswords)).result;
            if(success) {
              Pinia.globalStore.cashierHasToChangePassword(false);
              close();
            } else {
              this.password = "";
              this.confirmation = "";
              app.showAlert({
                header: i18next.t('error'),
                content: "נא לבחור סיסמה אחרת ולנסות שוב",
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, function() { }, null);
            }
          }
        },
        data: _initData,
        
        setup(){
          const globalStore = Pinia.useGlobalStore();
          const {isOnline,cashierHasToChangePassword} = Pinia.storeToRefs(globalStore);
          const globalStoreProps = {stateIsOnline:isOnline,cashierHasToChangePassword} 

          return {...globalStoreProps}
        },
        watch: {
          cashierHasToChangePassword(showChangePassword:boolean) {
            if(showChangePassword) this.open();
          }
        },
    }
    VueApp.component(_vueComponentName,changePasswordDialog)
  }

  function open(){
    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: 'positive-dialog',
        width: '60%',
        height: 350,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });
  }

  function _initData(){
    return {
      newPasswords: {
        password: "",
        confirmation: "",
        employee_id: null
      }
    };
  }

  function close() {
    this.password = "";
    this.confirmation = "";
    $(_dialogSelector).dialog('close');
  }
}}}
