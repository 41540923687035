module PositiveTS {
export module Service {
export module CreditDal {

  export function promiseTransmitCreditDictionary(dictToPost) {
    var _isCreditTransaction = true;
    var _xmlToPost = _getXmlTransmitTransaction( dictToPost, _isCreditTransaction );

    app.showLoadingMessage('מזכה כרטיסי אשראי. אנא המתן...');
    return serviceShva.promiseAddon(_xmlToPost)
    .then(function (response) {
      app.hideLoadingMessage();
      return serviceShva.parseShvaOutString(response);
    });
  }

  export function promiseTransmitDebitDictionary(dictToPost) {

    dictToPost = dictToPost;

    var _xmlToPost = _getXmlTransmitTransaction( dictToPost );


    return serviceShva.promiseAddon(_xmlToPost)
    .then(function (response) {
      var _return = serviceShva.parseShvaOutString(response);
      _return.phone_number = dictToPost.CreditCard.phone_number;
      return _return ;
    });

  }
  export function promiseTransmitJ2Dictionary( dictToPost ):Promise<any> {

    var _xmlToPost = _getXmlTransmitTransaction( dictToPost, undefined, undefined, true );
    return new Promise((resolve,reject) => {
      serviceShva.promiseAddon(_xmlToPost)
      .then((response) => {
        if (response) {
          resolve(serviceShva.parseShvaOutString(response));
        }
        else {
          reject(new Error("Got undefined from shva addon - is shva addon installed correctly??"));
        }
      });
    })

  }


   export function promiseTransmitConfirmDictionary( dictToPost ){

     var _xmlToPost = _getXmlTransmitTransaction( dictToPost, undefined, true );

     return serviceShva.promiseAddon(_xmlToPost)
     .then(function (response) {
        return serviceShva.parseShvaOutString(response);
      });
   }

   /*
   Description:
     dictdata:
        {"credit_card"=>{
          "amount"=>"100.33",
          "card_number"=>"6671",
          "payment_type"=>"1",
          "cvv"=>"787123",
          "payments_count"=>"3",
          "valid_until"=>"01/15",
          "phone_number"=>"0398123"}
          }
     iscredit:
        [undefined/false | true]
   */
   function _getXmlTransmitTransaction(dictData, isCredit?, isConfirm?, isJ2? ) {
      var parsedDictionary = _getDictionaryXmlTransmitTransaction(dictData, isCredit, isConfirm, isJ2 );

      var ShvaInString = serviceShva.getShvaInString(parsedDictionary);
     return ShvaInString;
   }

   /*
  	Description:
  		Name of this function is misleading.
  		It just return a Dictionary, for Shva factory to parse the shva_in command
  	*/
  	function _getDictionaryXmlTransmitTransaction(dictData, isCredit, isConfirm, isJ2 ) {

  	  let dictCredit:any = dictData.CreditCard;
  		// Confirmation number used by isCredit Only
  		var action_type = _ACTION_TYPE.debit;


  		if (isCredit) {
  			action_type = _ACTION_TYPE.credit;
  		}

  		if ( isConfirm ) {
  			action_type = _ACTION_TYPE.confirm;
  		}

  		if ( isJ2 ) {
  			action_type = _ACTION_TYPE.J2;
  		}

  		var amount_formated = _getCreditAmountFormat(dictCredit.amount);
  		var real_reader = _isCardReader(dictCredit.card_number);
  		var card_number = dictCredit.card_number;
  		var valid_until = {};
  		var cvv = "0";

  		var payments_count = dictCredit.payments_count;
  		// Card_data is card number of card readers
  		var card_data = "0";

  		var cardReaderType = _CARD_READER_TYPE["disconnected"];
  		var phone_number = dictCredit.phone_number;
  		var validationMonth = "0";
  		var validationYear = "0";

  		// if manually types card
  		// set creditcard number with trailing zero
  		// and format expire date
  		if (real_reader) {
  			 card_data = card_number;
  			 cardReaderType = _CARD_READER_TYPE["connected"];
  			 card_number = "0";
  			 phone_number = "000000000";
  		} else
  		 {
  			 card_data = "0";
  			 var _return = _formatCardNumberAndValidUntil(dictCredit.card_number ,
  											dictCredit.valid_until ) ;
  			 card_number = _return.card_number;
  			 validationMonth = _return.validationMonth;
  			 validationYear = _return.validationYear;
  			 // Credit transaction should have '0' cvv
  			 if ( ! isCredit ) {
  						 cvv = dictCredit.cvv;
  			 }
  		}
  		// Calculated payments ammount
  		var dictFirstPaymentAndFixedAmount = _formatFirstPaymentAndFixedAmount( amount_formated, payments_count );
  		var first_payment = dictFirstPaymentAndFixedAmount.first_payment;
  		var fixed_amount = dictFirstPaymentAndFixedAmount.fixed_amount;
  		// Used to determine Production/Or test
  		var vendorName = 'sak' + dictData.store.credit_card_vendor;

  		// construct the xml to send
  		var dictCreditXMLPro:any = {};
  		dictCreditXMLPro.cvvNumber = cvv;
  		dictCreditXMLPro.confNumber = dictCredit.confNumber;
      dictCreditXMLPro.tz = dictCredit.tz;
  		dictCreditXMLPro.vendorName = vendorName;
  		dictCreditXMLPro.purchaseType = dictCredit.payment_type;
  		dictCreditXMLPro.cardNumber = card_number;
  		dictCreditXMLPro.validationMonth = validationMonth;
  		dictCreditXMLPro.validationYear = validationYear;
  		dictCreditXMLPro.totalPayment = amount_formated;
  		dictCreditXMLPro.paymentsNumber = payments_count;
  		dictCreditXMLPro.firstPayment = first_payment;
  		dictCreditXMLPro.fixedAmmount = fixed_amount;
  		dictCreditXMLPro.actionType = action_type;
  		dictCreditXMLPro.cardReader = cardReaderType;
  		dictCreditXMLPro.readerData = card_data;
  		dictCreditXMLPro.phone =phone_number;
  		dictCreditXMLPro.creditCurrency = dictCredit.creditCurrency;
  		dictCreditXMLPro.creditLeadCurrencyAmount = dictCredit.creditLeadCurrencyAmount;
  		return dictCreditXMLPro;
   }

   /*
   Description:
      Add remain to first payment type
      if payments_count > 1
   Usage:
      amount should already formatted with iiidd AND NOT iii.dd
   */
  export function _formatFirstPaymentAndFixedAmount(amount_formated, payments_count) {
    var _return:any = {};
    var first_payment = amount_formated;
    var _payments_count = parseInt(payments_count);
    var fixed_amount = 0;
    if ( _payments_count > 1 ) {
     first_payment = amount_formated % _payments_count + parseInt( String(Number(amount_formated) / _payments_count) ) ;
     fixed_amount = (amount_formated - first_payment) / ( _payments_count - 1);
    }
    _return.first_payment = _lpad(first_payment , 8);
    _return.fixed_amount = _lpad(fixed_amount , 8);
    return _return;
  }

  /*
  Description:
     return credit card formated {card_number: <formated card number>,
              valid_until: <formated valid_until}
  */
  function _formatCardNumberAndValidUntil( card_number, valid_until){

    var _return:any = {};
    // card number lpad
    _return.card_number = _lpad( card_number , 16);
    // valid until to from 01/14 to 0114
    var _dict_valid_until = valid_until.split("/");
    _return.validationMonth = _lpad( parseInt(_dict_valid_until[0]) ,2);
    _return.validationYear = _lpad( parseInt(_dict_valid_until[1]) ,2);
    return _return;
  }

  /*
		Description:
			 return true, if scanned by card reader
	*/
 	function _isCardReader(card_number){
		if ( card_number.indexOf('=') < 0 ) {
			 return false;
		} else {
			 return true;
		}
 	}
 	/*
 	Description:
		credit2000 excpect format of number 123.456 as 000012346
 	*/
 	export function _getCreditAmountFormat(n):string {
		return _lpad(Math.round(n * 100),8);
 	}
 	/*
 	Description:
 	Usage:
		_getErrorDescriptionByCode('000');

		would return "successfull transaction"
 	*/
 	export function _getErrorDescriptionByCode(code) {
		return _ERROR_CODE[ _lpad(code, 3) ];
  }
  /*
  Description:
		Private lpad_ number
  Usage:
		lpad_ (12,3);
	*/
  function _lpad (n, width, z = '0') {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  export const _CARD_READER_TYPE = {
    "connected": 1,
    "disconnected": 2
  }

  export const _ACTION_TYPE =
     {
        "debit": 4,
        "confirm": 5,
        "credit": 7,
        "J2": 9
     }

  // private constant
  export const _ERROR_CODE =
     {
        "000": "עיסקה תקינה",
        "001": "חסום החרם כרטיס ",
        "002": "גנוב החרם כרטיס ",
        "003": "התקשר לחברת האשראי ",
        "004": "סירוב ",
        "005": "מזויף החרם כרטיס ",
        "006": "חובה להתקשר לחברת האשראי",
        "008": "תקלה בבניית מפתח גישה לקובץ חסומים",
        "009": "לא הצליח להתקשר",
        "010": 'תוכנית הופסקה עפ"י הוראת המפעיל',
        "019": "רשומה בקובץ קלט לא תקינה",
        "020": "קובץ קלט לא קיים",
        "021": "קובץ חסומים לא מעודכן",
        "022": "אחד מקבצי פרמטרים או ווקטורים לא קיים",
        "023": "קובץ תאריכים לא קיים",
        "024": "קובץ אתחול לא קיים",
        "025": "הפרש בימים בקליטת חסומים גדול מדי",
        "026": "הפרש דורות בקליטת חסומים גדול מידי",
        "027": "כאשר לא הוכנס פס מגנטי כולו הגדר עיסקה כעיסקה טלפונית",
        "028": "מספר מסוף מרכזי לא הוכנס למסוף המוגדר לעבודה כרב ספק",
        "029": "מספר מוטב לא הוכנס למסוף המוגדר לעבודה כרב מוטב",
        "030": "מסוף שאינו מעודכן כרב ספק/רב מוטב והוקלד מס' ספק/מס' מוטב",
        "031": "מסוף מעודכן כרב ספק והוקלד גם מס' מוטב",
        "032": "תנועות ישנות בצע שידור או בקשה לאישור עבור כל עיסקה",
        "033": "כרטיס לא תקין",
        "034": "כרטיס לא רשאי לבצע במסוף זה או אין אישור לעיסקה כזאת",
        "035": "כרטיס לא רשאי לבצע עיסקה עם סוג אשראי זה",
        "036": "פג תוקף",
        "037": "שגיאה בתשלומים",
        "038": "לא ניתן לבצע עיסקה מעל תקרה לכרטיס לאשראי חיוב מיידי",
        "039": "סיפרת בקורת לא תקינה",
        "040": "סוף שמוגדר כרב מוטב הוקלד מס' ספק",
        "041": "סכום מעל תיקרה- בעיה הגדרת נתונים",
        "042": "כרטיס חסום בספק",
        "044": "לא ראשאי לבקש אישור ב5",
        "045": "מסוף לא רשאי לבקש אישור ביוזמת קמעונאי",
        "046": "מסוף חייב לבקש אישור",
        "047": "חייב להקליד מספר סודי",
        "051": "מספר רכב לא תקין",
        "052": "מד מרחק לא הוקלד",
        "053": "עיסקה לא מתאים",
        "058": "לא ניתן לזכות כרטיס זה",
        "059": "לא הוקלדו מספר תעודת הזהות וה- CVV2 .",
        "060": "צרוף 01S לא נמצא בהתחלת נתוני קלט בזיכרון",
        "061": "מספר כרטיס לא נמצא או נמצא פעמיים",
        "062": "סוג עיסקה לא תקין",
        "063": "קוד עיסקה לא תקין ",
        "064": "סוג אשראי לא תקין",
        "065": "מטבע לא תקין ",
        "066": "קיים תשלום ראשון ו/או תשלום קבוע לסוג אשראי שונה מתשלומים",
        "067": "יים מספר תשלומים לסוג אשראי שאינו דורש זה",
        "068": "לא ניתן להצמיד לדולר או למדד לסוג אשראי שונה",
        "069": "אורך הפס המגנטי קצר מידי",
        "071": "מספר שגוי",
        "072": "מספר סודי לא הוקלד",
        "073": "מספר סודי שגוי",
        "074": "מספר סודי שגוי - ניסיון אחרון",
        "099": "TR0N לא מצליח לקרוא/ לכתוב/ לפתוח קובץ",
        "100": "לא קיים מכשיר להקשת מספר סודי",
        "101": "אין אישור מחברת אשראי לעבודה",
        "107": "סכום העיסקה גדול מידי - חלק במספר העיסקאות",
        "108": "למסוף אין אישור לבצע עסקאות מאולצות",
        "109": "למסוף אין אישור לכרטיס עם קוד השרות 587",
        "110": "למסוף אין אישור לכרטיס חיוב מיידי",
        "111": "למסוף אין אישור לעיסקה בתשלומים",
        "112": "למסוף אין אישור לעיסקה טלפון/ חתימה בלבד בתשלומים",
        "113": "למסוף אין אישור לעיסקה טלפונית",
        "114": 'למסוף אין אישור לעיסקה "חתימה בלבד"',
        "115": "למסוף אין אישור לעיסקה בדולרים",
        "116": "למסוף אין אישור לעסקת מועדון",
        "117": "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים",
        "118": "למסוף אין אישור לאשראי ישראקרדיט",
        "119": "למסוף אין אישור לאשראי אמקס קרדיט",
        "120": "למסוף אין אישור להצמדה לדולר",
        "121": "למסוף אין אישור להצמדה למדד",
        "122": 'למסוף אין אישור להצמדה למדד לכרטיסי חו"ל',
        "123": "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים לסוגאשראי זה",
        "124": "למסוף אין אישור לאשראי ישרא 36",
        "125": "למסוף איו אישור לאשראי אמקס 36",
        "126": "למסוף אין אישור לקוד מועדון זה",
        "127": "למסוף אין אישור לעסקת חיוב מיידי פרט לכרטיסי חיוב מיידי",
        "128": "למסוף אין אישור לקבל כרטיסי ויזה אשר מתחילים ב - 3",
        "129": "למסוף אין אישור לבצע עסקת זכות מעל תקרה",
        "130": "כרטיס לא רשאי לבצע עסקת מועדון",
        "131": "כרטיס לא רשאי לבצע עסקת כוכבים/נקודות/מיילים",
        "132": "כרטיס לא רשאי לבצע עסקאות בדולרים )רגילות או טלפוניות(",
        "133": "כרטיס לא תקף על פי רשימת כרטיסים",
        "134": 'כרטיס לא תקין עפ"י הגדרת המערכת',
        "135": 'כרטיס לא רשאי לבצע עסקאות דולריות עפ"י הגדרת המערכת',
        "136": 'כרטיסים אשר אינה רשאית לבצע עסקאות עפ"י הגדרת המערכת',
        "137": 'קידומת הכרטיס )7 ספרות( לא תקפה עפ"י הגדרת המערכת',
        "138": "כרטיס לא רשאי לבצע עסקאות בתשלומים",
        "139": "מספר תשלומים גדול מידי על פי רשימת כרטיסים",
        "140": "כרטיסי ויזה ודיינרס לא רשאים לבצע עסקאות מועדון",
        "141": 'סידרת כרטיסים לא תקפה עפ"י הגדרת המערכת',
        "142": 'קוד שרות לא תקף עפ"י הגדרת המערכת',
        "143": 'קידומת הכרטיס )2 ספרות( לא תקפה עפ"י הגדרת המערכת',
        "144": 'קוד שרות לא תקף עפ"י הגדרת המערכת',
        "145": 'קוד שרות לא תקף עפ"י הגדרת המערכת',
        "146": "לכרטיס חיוב מיידי אסור לבצע עסקת זכות",
        "147": 'כרטיס לא רשאי לבצע עיסקאות בתשלומים עפ"י',
        "148": "כרטיס לא רשאי לבצע עסקאות טלפוניות וחתימה בלבד",
        "149": "כרטיס אינו רשאי לבצע עיסקאות טלפוניות",
        "150": "אשראי לא מאושר לכרטיסי חיוב מיידי",
        "151": 'אשראי לא מאושר לכרטיסי חו"ל',
        "152": "קוד מועדון לא תקין",
        "153": "כרטיס לא רשאי לבצע עסקאות אשראי גמיש )עדיף +30/(",
        "154": 'כרטיס לא רשאי לבצע עסקאות חיוב מיידי עפ"י הגדרת',
        "155": "סכום לתשלום בעסקת קרדיט קטן מידי",
        "156": "מספר תשלומים לעסקת קרדיט לא תקין",
        "157": "תקרה 0 לסוג כרטיס זה בעיסקה עם אשראי רגיל או קרדיט",
        "158": "תקרה 0 לסוג כרטיס זה בעיסקה עם אשראי חיוב מיידי",
        "159": "תקרה 0 לסוג כרטיס זה בעסקת חיוב מיידי בדולרים",
        "160": "תקרה 0 לסוג כרטיס זה בעיסקה טלפונית",
        "161": "תקרה 0 לסוג כרטיס זה בעסקת זכות",
        "162": "תקרה 0 לסוג כרטיס זה בעסקת תשלומים",
        "163": 'כרטיס אמריקן אקספרס אשר הונפק בחו"ל לא רשאי',
        "164": "כרטיסי921 רשאי לבצע עסקאות רק באשראי רגיל",
        "165": "סכום בכוכבים/נקודות/מיילים גדול מסכום העיסקה",
        "166": "כרטיס מועדון לא בתחום של המסוף",
        "167": "לא ניתן לבצע עסקת כוכבים/נקודות/מיילים בדולרים",
        "168": "לא ניתן לבצע עיסקה בדולרים עם סוג אשראי זה",
        "169": "לא ניתן לבצע עסקת זכות עם אשראי שונה מהרגיל",
        "170": "סכום הנחה בכוכבים/נקודות/מיילים גדול מהמותר",
        "171": "לא ניתן לבצע עיסקה מאולצת לכרטיס/אשראי חיוב מיידי",
        "172": "לא ניתן לבטל עיסקה קודמת )עיסקת זכות או מספר",
        "173": "עיסקה כפולה",
        "174": "למסוף אין אישור להצמדה למדד לאשראי זה",
        "175": "למסוף אין אישור להצמדה לדולר לאשראי זה",
        "176": 'כרטיס אינו תקף עפ"י הגדרת ה מערכת',
        "177": 'בתחנות דלק לא ניתן לבצע "שרות עצמי" אלא "שרות עצמי',
        "178": "אסור לבצע עיסקת זכות בכוכבים/נקודות/מיילים",
        "179": "אסור לבצע עיסקת זכות בדולר בכרטיס תייר",
        "200": "שגיאה יישומית",
        "999": "טבלאות לא מעודכנות",
        "998": "8NPUT-תקלה בספריית הנתונים",
        "997": "OUTPUT-תקלה בספריית הנתונים",
        "996": "רכיבי תוכנה חסרים",
        "993": "אין תקשורת לשרת - זמני",
        "992": "בעיה בקו תקשורת",
        "990": "תקלה במחשב שרת-להפסיק שידור"
     }

}}}
