module PositiveTS {
    export module Service {
        export module PluxeeConvertService {

            const _TenbisPayment = 221;
            const _CreditCardPayment = 222;
            const _HakafaPayment = 223;
            const _TenbisCurrentPayment = 4;
            const _PluxeeServiceLogger = "pluxee order" 
            

            export async function convertOrderToSale(order) {
                let orderNumber = order.ID;
                let customerNumber = Service.PluxeeService.customer_number;
                let pluexeeCustomer = await Hakafa.getHakafaCustomerByCustomerNumber(customerNumber);
                if (!pluexeeCustomer) { // For backwards compatibility
                    customerNumber = "לקוח סיבוס";
                    pluexeeCustomer = await Hakafa.getHakafaCustomerByCustomerNumber(customerNumber);    
                }                
                if (!pluexeeCustomer) {
                    // Its blows the logs with too many messages
                    // PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({ request: "No pluxee/cibus hakafa customer found:", result: "" }),orderNumber,_PluxeeServiceLogger,Delivery.FailedDeliveryType.HakafaCustomer)
                    throw new Error("No cibus/pluxee hakafa customer found");
                }

                let sale = await Sale.getNewOrder(orderNumber, order.TotalSum, Storage.Entity.Sequence.TYPE_SHIPMENT_INV);


                let deliveryMethod = order.selfCollect
                let deliveryStatus = Storage.Entity.Sale.OPEN_DELIVERY;
                let deliveryTypeStr = Service.Delivery.DeliveryType.externalCibus;


                if (deliveryMethod == 1) {
                    deliveryTypeStr = Service.Delivery.DeliveryType.externalCibusTA;
                    deliveryStatus = Storage.Entity.Sale.TA_PAID;
                }

                let jsondata = JSON.parse(sale.jsondata)
                jsondata.cibusTotalAmount = sale.totalAmount
                jsondata["promotions"] = [];
                jsondata["delivery"] = {
                    deliveryType: deliveryTypeStr,
                    status: deliveryStatus,
                    ordererName: order.userName + ' - חברה: ' + order.cmpName,
                    ordererPhone: order.userPhone,
                    ordererCallerRemarks: "",
                    ordererDeliveryRemarks: order.notes,
                    ordererDeliveryCutlery: "",
                    "deliveryAddress": {
                        "address": {
                            "name": order.address.street || i18next.t('delivery.unspecified'),
                            "value": 0,
                            "cityID": 0
                        },
                        "apartment": "",
                        "house_number": order.address.num || i18next.t('delivery.unspecified'),
                        "house_entrance": "",
                        "house_floor": "",

                        "city": {
                            "name": order.address.city || i18next.t('delivery.unspecified'),
                            "value": 0,
                            "cityID": 0
                        }
                    },
                    "orderTime": order.creationTime,
                    "CibusRestID": order.RestID,
                    "CibusRestName": order.RestName,
                    "address": order.deliveryAddress,
                    "deliveryTime": moment(order.toTime).format("HH:mm")
                };

                jsondata["customer"] = {
                    clubName: "HAKAFA",
                    s_id_number: pluexeeCustomer.tz,
                    s_first_name: pluexeeCustomer.first_name,
                    s_last_name: pluexeeCustomer.last_name,
                    dt_birth_date: null,
                    s_phone_number_1: pluexeeCustomer.phone,
                    customer_group_id: null,
                    amount: 0,
                    merakez: "",
                    db_id: pluexeeCustomer.id,
                    is_offline: false,
                    is_price_alut: false,
                    printer_type: 0,
                    discount_percent: 0,
                    customer_number: customerNumber,
                    obligo: null,
                    address: null,
                    is_tamash_customer: true,
                    is_cibus_tenbis: true
                };

                sale.orderTime = order.creationTime,
                    sale.jsondata = JSON.stringify(jsondata);


                let orderItems = order.requestedElements;
                let items = [], totalQuantity = 0, totalSumItems = 0;

                orderItems.forEach(function (orderItem, index) {
                    let currentItem = session.allItems.get(String(orderItem.dishMakat || orderItem.dishId))

                    if (!Boolean(currentItem)) {
                        currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                    }

                    if (Boolean(currentItem)) {
                        let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });
                        saleItem.quantity = orderItem.dishQuantity;
                        saleItem.barcode = saleItem.itemCode;
                        saleItem.saleID = sale.id;
                        saleItem.unitPrice = orderItem.dishPrice;
                        saleItem.originalUnitPrice = orderItem.dishPrice;
                        saleItem.hasPreparationInstructions = false;
                        saleItem.rowNumber = index + 1;
                        totalQuantity += orderItem.dishQuantity;
                        totalSumItems += orderItem.dishPrice * orderItem.dishQuantity;
                        saleItem.children = [];
                        saleItem.priceNetoAfterDiscounts = orderItem.dishPrice * orderItem.dishQuantity;

                        if (currentItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)) {
                            saleItem.itemDescription = String(orderItem.dishName).trim();
                        }

                        items.push(saleItem);

                        orderItem?.sideDishGroups?.forEach(function (group) {
                            group.sideDishes?.forEach(function (sideDish, subIndex) {
                                let addItem = session.allItems.get(String(sideDish.sideDishMakat || sideDish.sideDishId));
                                if (!Boolean(addItem)) {
                                    addItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                                }
                                if (Boolean(addItem)) {
                                    let add = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(addItem, { size: 'null', color: 'null', barcode: null });
                                    add.rowNumber = 20001 + subIndex;
                                    add.unitPrice = sideDish.sideDishPrice;
                                    add.quantity = 1;
                                    totalSumItems += sideDish.sideDishPrice * orderItem.dishQuantity;
                                    add.originalUnitPrice = sideDish.sideDishPrice;
                                    add.barcode = add.itemCode;
                                    add.priceNetoAfterDiscounts = sideDish.sideDishPrice * orderItem.dishQuantity
                                    add.level = 1;

                                    if (addItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)) {
                                        add.itemDescription = String(sideDish.sideDishName).trim();
                                    }

                                    add.parentItemId = items[index].item.id;
                                    add.parentRowNumber = 1;
                                    add.itemGroupId = -1;
                                    add.parent = items[index];

                                    items[index].children.push(add);
                                    items[index].unitPrice += add.unitPrice * add.quantity;
                                    items[index].priceNetoAfterDiscounts += add.priceNetoAfterDiscounts * items[index].quantity

                                } else {
                                    PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({ request: "No Item cibus found: ", result: `${String(sideDish.sideDishMakat || sideDish.sideDishId)}` }),orderNumber,_PluxeeServiceLogger,Delivery.FailedDeliveryType.InterfaceItemMissing,true)
                                    items.push({ "item_desc": "NOT_FOUND" });
                                }
                            })
                        })
                    }
                })

                let itemsConsists = validateItemsConsist(items);

                if (itemsConsists && items.length > 0) {

                    let deliveryItem = new Storage.Entity.Item();
                    switch (deliveryMethod) {
                        case 0:
                            deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));
                            deliveryItem.priceZarhan = order.delivery_cost
                            break;

                        case 1:
                            deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode));
                            deliveryItem.priceZarhan = 0;
                            break;
                    }


                    if (deliveryItem) {
                        let deliveryItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(deliveryItem, { size: 'null', color: 'null', barcode: null });
                        deliveryItm.rowNumber = 0;
                        deliveryItm.priceNetoAfterDiscounts = deliveryItem.priceZarhan
                        items.push(deliveryItm);
                        sale.items = items;
                        sale.totalQuantity = String(totalQuantity);
                        sale.totalAmount = totalSumItems;
                        sale.totalVatableAmount = session.store.containVat ? 0 : totalSumItems;
                        sale.payments = [];

                        let currentPayment = createDeliveryPayment(order, sale); // element.PaymentMethod
                        sale.payments.push(currentPayment);
                        let sequence = await Storage.Entity.Sequence.getSequenceForInvType(sale.invoiceType);
                        sequence.sequence++;
                        sale.invoiceSequence = sequence.sequence;
                        
                        await appDB.sequences.put(sequence)
                        return sale;
                    } else {
                        PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({ request: "No delivery item in pos", result: `OrderId:${sale.orderNumber}` }),orderNumber,_PluxeeServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing,true)
                        sale.items = [];
                        return sale;
                    }
                } else {
                    PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({ request: "Order item/add not found.", result: `OrderId:${sale.orderNumber}` }),orderNumber,_PluxeeServiceLogger,Delivery.FailedDeliveryType.MissingItems,true)
                    sale.items = [];
                    return sale;
                }


            }

        }
    }



    function createDeliveryPayment(order, sale) {
        let currentPayment = new Storage.Entity.SalePayment();
        currentPayment.saleID = sale.id;
        currentPayment.method = METHOD_HAKAFA;
        currentPayment.amount = order.price;
        let voucherData = [
            {
                "amount": order.price,
                "barCode": "",
                "creditType": "פלאקסי",
                "voucher_type_id": "998",
                "smartVoucherType": Service.SmartVoucher.PLUXEE_HANDLER,
                "valuTypeId": null,
                "praxellManpik": null,
                "mutipassInit": null,
                "isTamashCustomer": true
            }
        ]
        currentPayment.data = JSON.stringify(voucherData)
        return currentPayment;
    }

    function validateItemsConsist(items) {
        let missingItems = items.filter(function (item) { return (item.item_desc == "NOT_FOUND") }).length;
        return (missingItems == 0)
    }
}
