module PositiveTS {
  export module Components {
    export module ExternalOrdersDifferences {
      export function create() {
        let externalOrdersDifferences = {
          template: JST.externalOrdersDifferences(),
          methods: {
            async openIfNeededAndReturnIfToContinue() {
              const saleData = JSON.parse(posVC.sale.jsondata)
              if(!saleData.externalOrderItems) {
                return true;
              }
              this.missingSaleItems = []

              const orderSaleItems = this.unifySimilarSaleItems(saleData.externalOrderItems);
              const saleSaleItems  = this.unifySimilarSaleItems(this.posSaleItems);

              orderSaleItems.forEach((orderItem:Storage.Entity.SaleItem) => {
                let itemInSale = saleSaleItems.find((saleItem: Storage.Entity.SaleItem) => orderItem.itemCode == saleItem.itemCode
                  && orderItem.color == saleItem.color && orderItem.size == saleItem.size);
                if(posUtils.isBlank(itemInSale)) {
                  this.differences.push({saleItem: orderItem, reason: null})
                }
                else if(!itemInSale.hasWeight && itemInSale.quantity < orderItem.quantity ) {
                  orderItem.quantity -= itemInSale.quantity;
                  this.differences.push({saleItem: orderItem, reason: null})
                }
              });
              
              if(this.differences.length > 0) {
                this.showDifferenceResolveModal();
                return await new Promise(resolve => {
                  this.windowResolve = resolve;
                })
              }
              return true;
            },
            async showDifferenceResolveModal() {
              this.$el.showModal();              
            },
            close() {
              this.$el.close();
              this.differences = [];
            },
            cancel() {
              this.close()
              this.windowResolve(false);
            },
            async accept() {
              let saleData = JSON.parse(posVC.sale.jsondata)
              delete saleData.externalOrderItems
              saleData.orderDifferences = this.differences.map((item) => {
                return {
                  description: item.saleItem.itemDescription,
                  code: item.saleItem.barcode || item.saleItem.itemCode,
                  quantity: item.saleItem.quantity,
                  reason: item.reason
                }
              })
              posVC.sale.jsondata = JSON.stringify(saleData);
              await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)

              this.close();
              this.windowResolve(true);
            },
            unifySimilarSaleItems(saleItems:Array<Storage.Entity.SaleItem>) : Array<Storage.Entity.SaleItem> {
              let uniqueSaleItems:Array<Storage.Entity.SaleItem> = [];

              saleItems.forEach((orderItem:Storage.Entity.SaleItem) => {
                const existingSaleItem = uniqueSaleItems.findIndex((item:Storage.Entity.SaleItem) => item.itemCode == orderItem.itemCode 
                  && item.color == orderItem.color && item.size == orderItem.size )
                if(existingSaleItem > -1) {
                  uniqueSaleItems[existingSaleItem].quantity += orderItem.quantity;
                } else {
                  uniqueSaleItems.push(orderItem)
                }
              });
              return uniqueSaleItems;
            },
            setItemAlternate(index) {
              this.differences[index].reason = Service.Delivery.DifferenceReason.Alter;
            },
            setItemMissing(index) {
              this.differences[index].reason = Service.Delivery.DifferenceReason.Missing;
            },
            showAlternate(item) {
              return posUtils.isBlank(item.reason) || item.reason == Service.Delivery.DifferenceReason.Alter;
            },
            showMissing(item) {
              return posUtils.isBlank(item.reason) || item.reason == Service.Delivery.DifferenceReason.Missing;
            },
            getMarked(index) {
              let text = "";
              if(this.differences[index].reason == Service.Delivery.DifferenceReason.Alter) {
                text = i18next.t("externalOrdersDifferences.alterior");
              }
              if(this.differences[index].reason == Service.Delivery.DifferenceReason.Missing) {
                text = i18next.t("externalOrdersDifferences.missing");
              }
              return text;
            }
          },
          setup(){
            const globalStore = Pinia.useGlobalStore();
            const {saleItems} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {posSaleItems:saleItems} 

            return {...globalStoreProps}
          },
          data() {
            return {
              windowResolve: null,
              missingSaleItems: null,
              differences: []
            }
          },
        }
        VueApp.component('external-orders-differences', externalOrdersDifferences)

      }
    }
  }
}
