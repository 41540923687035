module PositiveTS {
  export module Service {
     export module Check {

      export class ClassDictTran {
        private _ERN_TRANSACTION_TYPE = {
          CASH: "01",
          POSTPHONED: "02",
          PAYMENTS: "03",
        };


        protected _ERN_ManufacturerId = "PTV";
        protected _ERN_ManufacturerVersion = "002";

        protected _getClientRequestId() {
          return session.pos.tenantID + session.pos.companyID + session.pos.storeID + "11111";
        };

        protected _getShortUnique() {
          var d = new Date().getTime().toString();
          var str = PositiveTS.Service.StrUtils.rpad(session.pos.storeID + session.pos.posNumber.toString(), 6, "0") + d.substring(d.length - 6, d.length);

          return PositiveTS.Service.StrUtils.lpad(str, 12);
        };

        protected _getErnMId() {
          var ernId = jsonConfig.getVal(jsonConfig.KEYS.ernMId); // for test possible to use "100276"
          if (!ernId) { throw new Error("No jsonConfig.KEYS.ernMId defined"); }

          return ernId;

        };

        protected _translateIlDateToErnDate(ildate) {
          return moment(ildate,"DD/M/YY").format("DDMMYYYY")
        };

        ClientRequestId: any;
        ClientRequestCode: any;
        ErnMId: any;
        ManufacturerId: any;
        ManufacturerVersion: any;
        RequestUniqueId: any;
        BankNumber: any;
        BranchNumber: any;
        CheckType: any;
        SecureDigit: any;
        AccountNumber: any;
        ServiceId: any;
        TransactionType: any;
        TotalSum: any;
        VoiceAuthorizationNumber: any;
        NumberOfPayments: any;
        CustId: any;
        GuarantorId: any;
        PhoneNumber: any;
        PaymentNumber: any;
        CheckNumber: any;
        DueDate: any;
        CheckSum: any;
        Swiped: any;
        J5Type: any;
        CarNumber: any;
        Payments: any;
        payments: any;


        constructor(fieldsData) {
          /* //Working Example
           var paramBankNumber = 11;
           var paramBranchNumber = "188";
           var paramAccountNumber = 13218;
           var paramTotalSum = "000005000";
           var paramVoiceAuthorizationNumber = "00000000000";
           var paramNumberOfPayments = "01";
           var paramCheckNumber = "13145";
           var paramDueDate = "29092014";
           var paramCheckSum = "000005000";
           var paramPayments = "";
           */
          var paramBankNumber = PositiveTS.Service.StrUtils.lpad(fieldsData.bank, 2);
          var paramBranchNumber = PositiveTS.Service.StrUtils.lpad(fieldsData.branch, 3);
          var paramAccountNumber = PositiveTS.Service.StrUtils.lpad(fieldsData.account, 10);
          var paramTotalSum = PositiveTS.Service.StrUtils.lpad(posUtils.toInt(fieldsData.amount, 2), 9);
          var paramVoiceAuthorizationNumber = PositiveTS.Service.StrUtils.lpad(fieldsData.manualApproval, 11);
          var paramNumberOfPayments = PositiveTS.Service.StrUtils.lpad(fieldsData.paymentCount, 2);
          var paramCheckNumber = PositiveTS.Service.StrUtils.lpad(fieldsData.number, 9);

          var paramDueDate = this._translateIlDateToErnDate(fieldsData.date);
          var paramCheckSum = PositiveTS.Service.StrUtils.lpad(posUtils.toInt(fieldsData.amount, 2), 9);
          var paramPayments = "";
          var paramCustId = PositiveTS.Service.StrUtils.lpad(fieldsData.customerId,9); // for testibg use "200583532"
          var paramGuarantorId = PositiveTS.Service.StrUtils.lpad(fieldsData.guarantorId,9);

          this.ClientRequestId = this._getClientRequestId();
          this.ClientRequestCode = this._getClientRequestId();
          this.ErnMId = this._getErnMId();
          this.ManufacturerId = this._ERN_ManufacturerId;
          this.ManufacturerVersion = this._ERN_ManufacturerVersion;
          this.RequestUniqueId = this._getShortUnique();
          this.BankNumber = paramBankNumber;
          this.BranchNumber = paramBranchNumber;
          this.CheckType = 0;
          this.SecureDigit = 0;
          this.AccountNumber = paramAccountNumber;
          this.ServiceId = 3;
          this.TransactionType = this._getTransactionType(parseInt(paramNumberOfPayments), posUtils.parseIlDate(fieldsData.date));
          this.TotalSum = paramTotalSum;
          this.VoiceAuthorizationNumber = paramVoiceAuthorizationNumber;
          this.NumberOfPayments = paramNumberOfPayments;
          this.CustId = paramCustId;
          this.GuarantorId = paramGuarantorId;
          this.PhoneNumber = "000000000";
          this.PaymentNumber = "01";
          this.CheckNumber = paramCheckNumber;
          this.DueDate = paramDueDate;
          this.CheckSum = paramCheckSum;
          this.Swiped = 0;
          this.J5Type = 0;
          this.CarNumber = "0";
          this.Payments = paramPayments;
        }

        private _getTransactionType(numberOfPayments:number, firstPaymentDate:Date) {
          var now = new Date();
          if (numberOfPayments === 1) {
            if ((new Service.DateDiff(firstPaymentDate, now)).diffDays >= 0) {
              return this._ERN_TRANSACTION_TYPE.CASH;
            } else {
              return this._ERN_TRANSACTION_TYPE.POSTPHONED;
            }
          } else {
            return this._ERN_TRANSACTION_TYPE.PAYMENTS;
          }
        };

      }
}}}
