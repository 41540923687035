module PositiveTS {
export module Components {
export module ValuCardReport {
  const _dialogSelector = "#positive-valu-card-report-dialog"
  const _vueComponentName = 'valu-card-report';

  class ClassTransaction {
    trxTime: string;
    trxAmnt: string;
    trxAction: string;
    balance: string

    private _heActions: { [id: number]: any; }  = {}

    constructor(row:any){
      this._heActions[PositiveTS.Service.ValuCardActionTypes.LOAD_CARD] = "טעינת כרטיס";
      this._heActions[PositiveTS.Service.ValuCardActionTypes.CANCEL_LOAD_CARD] = "ביטול טעינה";
      this._heActions[PositiveTS.Service.ValuCardActionTypes.PAY_WITH_CARD] = "תשלום";
      this._heActions[PositiveTS.Service.ValuCardActionTypes.CANCEL_PAY_WITH_CARD] = "ביטול תשלום";
      this._heActions[PositiveTS.Service.ValuCardActionTypes.RESET_BALANCE] = i18next.t('valuCardReport.actions.resetBalance');

      this.trxTime = moment(new Date(row.transaction_time)).format ( "DD/MM/YYYY hh:mm")
      this.trxAmnt = session.fixedNumber(row.amount)
      this.trxAction = this._heActions[row.action_type];
      this.balance = posUtils.isPresent(row.balance) ? session.fixedNumber(row.balance) : ""
    }
  }

  export function create() {
    let vueObj = {
        template: JST.valuCardReport(),
        methods: {
          async open (currentBalance: string, valuCardNumber: string) :Promise<any> {
            try{
              $(document).unbind('keypress')

              $(function() {
                $(_dialogSelector).dialog({
                  autoOpen: true,
                  modal: true,
                  dialogClass: 'positive-dialog',
                  width: '80%',
                  height: 550,
                  resizable: false,
                  closeOnEscape: false,
                  draggable: false,
                });
              $(_dialogSelector).dialog('open')
              })

              this.blurInputsOnElement($(_dialogSelector)[0])
              this._clearForm()
              this.currentBalance = currentBalance
              this.valuCardNumber = valuCardNumber
              await this.fetchData()
            }catch (error){
              console.error(error)
              app.hideLoadingMessage();
              app.showAlert({
                header: i18next.t('checkBalance'),
                content: error.message,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            }
          },

          async fetchData () {
            try{
              app.showLoadingMessage(i18next.t("voucherPaymentBarcodeCheckBalanceLoadingMsg"))
              this.trxs = []
              let v = new PositiveTS.Service.Valu()
              let response = await v.getTransactions(this.valuCardNumber, this.fromDate, this.toDate)
            
              if (!response.success){
                throw new Error(response.error)
              }
              
              for (var i=0;i<response.transactions.length;i++ ){
                this.trxs.push(new ClassTransaction(response.transactions[i]))
              }
              app.hideLoadingMessage()
            }catch(error){
              console.error(error)
              app.hideLoadingMessage()
              app.showAlert({
                header: i18next.t('checkBalance'),
                content: error.message,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            }
          },

          close: _close,
          print: _print,
          _clearForm: _clearForm,
        },
        data: _initData

    }

    VueApp.component(_vueComponentName,vueObj)

  }


  function _print(){
    Printing.Reports.printValuCardTransactions(this.valuCardNumber , this.currentBalance, this.trxs);
    this.close();
  }


  function _clearForm(){
    this.currentBalance = "";
    this.valuCardNumber = "";
    this.trxs = [];
    this.fromDate = moment(new Date()).startOf("month").toDate()
    this.toDate = moment(new Date()).endOf("month").toDate()
  }

  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      currentBalance: "",
      valuCardNumber: "",
      trxs: [],
      fromDate: moment(new Date()).startOf("month").toDate(),
      toDate : moment(new Date()).endOf("month").toDate(),
      dateFormat: "dd/MM/yyyy",
    };
  }

  function _close() {
    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
  }


}}}
