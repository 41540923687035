module PositiveTS {
export module Service {
export module EmailSend {

    export async function sendReport(type:number, recipients:Array<Storage.Entity.Employee>, params:any) {
        let url;
        switch(type) {
            case Shared.Constants.EmailReportType.Z_REPORT:
                url = "/z_reports/send_email"
                break;
            case Shared.Constants.EmailReportType.SALES_REPORT:
                url ="/sales/send_email"
                break;
        }
        let emails = recipients.map((employee) => { return employee.email });
        let response = await PositiveTS.Service.FetchReq.jsonReq(url, 'post', {...params, emails: emails});
        return response;
    }

}}}
