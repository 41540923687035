module PositiveTS {
export module Service {
export module Performa{

  export async function printPerforma(sale:Storage.Entity.Sale, saleItems:Array<Storage.Entity.SaleItem>,
      salePayments: Array<Storage.Entity.SalePayment>, loadingMessage = null) {
      let askToPrintWithoutItemPrices = jsonConfig.getVal(jsonConfig.KEYS.askToPrintPerformaWithoutItemPrices)
      let printPerformaWithoutPrices = false
  
      if(askToPrintWithoutItemPrices) {
        if (!posUtils.isBlank(loadingMessage)) {
          app.hideLoadingMessage();
        }

        let alertResult = await app.promiseShowAlert({
          content: i18next.t('printing.performa.askToPrintWithoutItemPricesMessage'),
          continueButtonText: i18next.t('printing.performa.yes'),
          cancelButtonText: i18next.t('printing.performa.no'),
        })

        printPerformaWithoutPrices = alertResult == 'cancel'

        if (!posUtils.isBlank(loadingMessage)) {
          app.showLoadingMessage(loadingMessage);
        }
      }


      let cloneSale = _.cloneDeep(sale)
      let cloneSaleItems = saleItems.map(item => _.cloneDeep(item))
      let cloneSalePayments = salePayments.map(payment => _.cloneDeep(payment))

      var calcuateSaleTotals = Helper.SaleHelper.calcuateSaleTotals(cloneSale, cloneSaleItems, cloneSalePayments)
      cloneSale.totalAmount = calcuateSaleTotals.totalPaid;
      cloneSale.createdAt = new Date();
      cloneSale.totalQuantity = calcuateSaleTotals.totalQuantity;
      cloneSale.totalDiscount = calcuateSaleTotals.totalSaleDiscount;
      cloneSale.totalVatableAmount = calcuateSaleTotals.totalVatableAmount;


      return Printing.Invoice.printInvoiceFactory(cloneSale, cloneSaleItems, cloneSalePayments, undefined,
        undefined, undefined,{isPerforma: true, printPerformaWithoutPrices: printPerformaWithoutPrices});        
      }


}}}
