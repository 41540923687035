module PositiveTS {
export module Storage {
export module Entity {
export class SpecialPromotion extends WasmEntity {

  constructor() {
		let meta = {
      name: 'SpecialPromotion',
      fields: {
        itemCode: "TEXT",
        discPerc: "TEXT",
        itemCodeGet: "TEXT",
        discPercGet: "TEXT",
        fromDate: "TEXT",
        toDate: "TEXT",
        promotionNo: "TEXT",
        storeList: "TEXT",
        sizeBuy: "TEXT",
        sizeGet: "TEXT",
        colorBuy: "TEXT",
        colorGet: "TEXT",
      }
    }
		super(meta)
	}
}}}}
