import { defineStore } from 'pinia';

const state = {
    selectedPaymentMethod: null,
    currentComponent: null,
    creditCardPaymentsData: null,
    isAfterPaymentPage: false,
    extraData: {},
}
const getters = {
    getExtraData(state) {
        return state.extraData;
    }
}

const actions = {
    setSelectedPaymentMethod(payload) {
        this.selectedPaymentMethod = payload;
    },
    setCurrentStep(payload) {
        this.currentComponent = payload;
    },
    setCreditCardPaymentsData(payload) {
        this.creditCardPaymentsData = payload
    },
    setIsAfterPaymentPage(payload) {
        this.isAfterPaymentPage = payload
    },
    setExtraData(payload) {
        this.extraData = payload
    },
    async setFlightLegs(context, legs) {
        await appDB.flights.update(context.state.flight.id, { legs: PositiveTS.Shared.DB.checkIfNeedCloneProxy(legs) });
        await Pinia.flightsStore.refreshStateFlight()
    },

}


const selfServicePaymentStore = defineStore('selfServicePayment', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default selfServicePaymentStore;

