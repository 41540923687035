module PositiveTS {
  export module Components {
    export module TableItem {

      export function getComponent() {
        return {
          template: JST.tableItem(),
          components: { positiveIcon: PositiveIcon.getComponent(), tableAttributesDialog: TableAttributesDialog.getComponent() },
          props: {
            dalpak: Object,
            boxView: {
              type: Boolean,
              default: false,
            },
            editBoxView: {
              type: Boolean,
              default: false,
            },
          },
          methods: {
            async selectDalpak() {
              try {
                await Service.Dalpak.selectDalpakAndGoToPos(this.dalpak);
              } catch (err) {
                console.error(err);
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: err.message,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
              }
            },

            toggleSelection() {
              this.isSelected = !this.isSelected;
            },
            async printInvoice() {
              await Service.Dalpak.printDalpakInvoice(this.dalpak);
            },
            openDialog() {
              if (this.dalpak.sale) {
                this.showDialog = true;
              }
            },
            closeDialog() {
              this.showDialog = false;
            },
            numberWithCommas(num) {
              return PositiveShared.Utils.numberWithCommas(num);
            },
            async openTableAttributesDialog() {
              this.stopDragging = true;
              await this.$refs.tableAttributesDialog.open(this.dalpak);
              this.stopDragging = false;
            },
            async promptAndDeleteDalpak() {
              if (this.showChangeArea) {
                return;
              }

              if (this.dalpaksById[this.dalpak.id]) {
                if (!posUtils.isBlank(this.dalpaksById[this.dalpak.id].sale)) {
                  return app.showAlertDialog({
                    header: i18next.t('payAttention'),
                    content: i18next.t('selectDalpaksDialog.cantDeleteTableWithSale'),
                    continueButtonText: i18next.t('ok'),
                    hideCancelButton: true,  
                  })
                }

                if (!posUtils.isBlank(this.dalpaksById[this.dalpak.id].lockedBy)) {
                  return app.showAlertDialog({
                    header: i18next.t('payAttention'),
                    content: i18next.t('dalpaks.dalpakIsLocked', {lockedBy: this.dalpaksById[this.dalpak.id].lockedBy, dalpak: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) ? i18next.t('table') : i18next.t('counter')}),
                    continueButtonText: i18next.t('ok'),
                    hideCancelButton: true,  
                  })
                  
                }
              }


              let result = await app.showAlertDialog({
                header: i18next.t('payAttention'),
                content: i18next.t('selectDalpaksDialog.deleteTablePrompt'),
                continueButtonText: i18next.t('ok'),
                cancelButtonText: i18next.t('cancel'),
                hideCancelButton: false,
                noHandleEnterEscape: true,
              })

              if (result != "cancel") {
                this.deleteEditModeDalpak(this.dalpak);
              }
            },
            async openChangeArea() {
              this.showChangeArea = true;
              await this.$nextTick();
              this.$refs.selectAreaPopup.scrollIntoView();
            },
            closeChangeArea() {
              this.showChangeArea = false;
            },
            moveToAreaAndCloseDialog(area) {
              this.dalpak.area = area.railsId;
              this.updateEditModeDalpak(this.dalpak);
              this.closeChangeArea();
            },
            moveDalpakSale() {
              if (this.showDialog) {
                this.closeDialog();
              }

              if (this.mobileLayout) {
                VueServices.Router.goto(MoveTableComponent.route, { dalpakId: this. dalpak.id });
              } else {
                return this.$emit('moveTable');
              }
            },
            changeWaiterSale(){
              if (this.showDialog) {
                this.closeDialog();
              }
              if (this.mobileLayout) {
                VueServices.Router.goto(changeWaiterComponent.route, { dalpakId: this.dalpak.id, activeEmployee: this.dalpak.sale.salespersonEmployeeID });
              } else {
                return this.$emit('changeWaiter');
              }
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {dalpaksById,dalpakAreasInEditModeWithoutDeleted,hasDalpakAreasInEditMode} = Pinia.storeToRefs(dalpaksStore);

            const {deleteEditModeDalpak,updateEditModeDalpak} = dalpaksStore
            const dalpaksActions = {deleteEditModeDalpak,updateEditModeDalpak}

            
            return {...dalpaksActions,dalpaksById,dalpakAreasInEditModeWithoutDeleted,hasDalpakAreasInEditMode}
          },
          computed: {
            dalpakColor() {
              return Service.Dalpak.DALPAK_COLORS_BY_STATUS[this.dalpakStatus];
            },
            dalpakStatusIcon() {
              return Service.Dalpak.DALPAK_ICONS_BY_STATUS[this.dalpakStatus];
            },
            dalpakStatus() {
              return Service.Dalpak.getDalpakStatus(this.dalpak);
            },
            dalpakSaleCustomer() {
              let dalpakCustomerName = Service.Dalpak.getDalpakSaleCustomer(this.dalpak);
              if (this.mobileLayout ){
                dalpakCustomerName = _.truncate(dalpakCustomerName, {length: 9})
              }
              return dalpakCustomerName;
            },
            avgPerDiner() {
              return Math.round(this.dalpakTotals.avgPerDiner);
            },
            dalpakTotals() {
              return Service.Dalpak.getDalpakTotals(this.dalpak);
            },
            dalpakLastChangedMinutes() {
              return Service.Dalpak.getDalpakLastChangedMinutes(this.dalpak) || '0';
            },
            dalpakLastChangedText() {
              if (this.dalpak.sale) {
                return moment(this.dalpak.sale.openDate).format("HH:mm");
              }

              return '-';
            },
            availableAreasToMoveTo() {
              return this.dalpakAreasInEditModeWithoutDeleted.filter(area => String(area.railsId) != String(this.dalpak.area));
            },
            popupStyle() {
              let positionProp = 'left';
              
              if (this.$el.offsetLeft > (this.$el.parentElement.offsetWidth / 2)) {
                positionProp = 'right';
              }

              return {
                [positionProp]: '-3px'
              }
            },

            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },

          },
          data() {
            return {
              isSelected: false,
              showDialog: false,
              showChangeArea: false,
              stopDragging: false,
            }
          }
        }
      }
    }
  }
}