module PositiveTS {
//TODO: this whole service is a pile of bull crap that needs to be re-written
  export module Service {
    export module Sva30 {

      function _parseAmountFromTranRow(currentPaymentSvaString) {
        var amount = parseFloat(currentPaymentSvaString.substr(35, 8)) / 100;
        var transavtionType = currentPaymentSvaString.substr(60, 2);
        var REGULAR_DEBIT = "01";

        if (transavtionType === REGULAR_DEBIT) {
          return amount;
        } else {
          return amount * -1;
        }

      };


      var _getShvaPaymentUid = function(data, byRefDictReturn) {
        var _return = [];
        var row_data = JSON.parse(data);

        var currentPaymentSvaString;
        for (var i = 0; i < row_data.length; i++) {
          currentPaymentSvaString = JSON.parse(JSON.parse(data)[i].all_data).result;
          byRefDictReturn.allPaymentSvaStrings.push(currentPaymentSvaString);
          _return.push(
            currentPaymentSvaString.substr(125, 14)
          );

          byRefDictReturn.totalPaymentsAmount += _parseAmountFromTranRow(currentPaymentSvaString);
          byRefDictReturn.totalPaymentsObjectAmount += JSON.parse(data)[i].amount;
        }


        return _return;
      };




      function _getCreditZPayments(dataOfCreditCardPayments) {
        var byRefDictReturn = {
          totalPaymentsAmount: 0,
          allPaymentSvaStrings: [],
          totalPaymentsObjectAmount: 0
        }


        var _return = [];

        for (let data of dataOfCreditCardPayments) {
          var _returnShvaPaymentsUid = _getShvaPaymentUid(data, byRefDictReturn);

          for (var i = 0; i < _returnShvaPaymentsUid.length; i++) {
            try {
              _return.push(_returnShvaPaymentsUid[i]);
            } catch (err) {
              throw "Could not parse sale payment data, is it Shva INTR timestamp response valid?";
            }
          }
        }
        return ({
          paymentAry: _return,
          localCheckSum: byRefDictReturn.totalPaymentsAmount,
          totalPaymentsObjectAmount: byRefDictReturn.totalPaymentsObjectAmount,
          allPaymentSvaStrings: byRefDictReturn.allPaymentSvaStrings,
        });


      };


      function _promiseGetPaymentsOfCLEANTRAN(zNumber, dataOfCreditCardPayments) {

        var addonPostDict: any = {};
        var result = _getCreditZPayments(dataOfCreditCardPayments)
        addonPostDict.payments = result.paymentAry;
        addonPostDict.localCheckSum = result.localCheckSum;
        addonPostDict.totalPaymentsObjectAmount = result.totalPaymentsObjectAmount;
        addonPostDict.allPaymentSvaStrings = result.allPaymentSvaStrings;
        addonPostDict.maxZ = zNumber;
        // avoid addon fail on empty payments
        if (addonPostDict.payments.length === 0) {
          addonPostDict.payments = [];
        }
        return addonPostDict;

      };



      function _parseResponseTransmitCLEANTRAN(response, addonPostDict) {
        if (response.request.status == "200") {
          var result = response.request.result;

          if (result.startsWith("ERROR")) {
            throw Error(result);
          }
        }
      }


      async function _promiseGetSqlResultOfSalePaymentDataOfMethodOne() {
        let allPayments:Storage.Entity.SalePayment[] = [].concat.apply([],(await appDB.sales.toArray()).map(sale=> sale.payments))
        return  allPayments.filter(pay => pay.method == Storage.Entity.SalePayment.METHOD_CREDIT).map(pay => pay.data);
      }



      export function _getFutureZNumber(){
        var sequence = new PositiveTS.Storage.Entity.Sequence();
        return sequence.findByTenantAndCompanyAndStoreAndPosAndType(undefined,
            session.pos.tenantID,
            session.pos.companyID,
            session.pos.storeID,
            session.pos.physicalID,
            PositiveTS.Storage.Entity.Sequence.TYPE_Z_REPORT)
          .then(result => {

            var sequence = result;

            // If there is no sequence for this type, create it
            if (sequence == null) {
              throw new Error('credit sale sequence not found for the pos, call to system administrator.');
            }

            return sequence.sequence + 1;
        })
      }


      function _promiseV30CleanAndTransmitCurrentPayments(zNumber) {
        return _promiseGetSqlResultOfSalePaymentDataOfMethodOne()
          .then(sqlResultOfSalePaymentDataOfMethodOne => {
            return _promiseGetPaymentsOfCLEANTRAN(zNumber, sqlResultOfSalePaymentDataOfMethodOne) //return object with last Z number, and Z payments
          })
          .then(function(addonPostDict) {
            return serviceShva.promiseV30_CLEANTRAN(addonPostDict)
          })
          .then(() => {
            return serviceShva.promiseV30_TRANS95();
          });

      }

      export function promiseV30CleanAndTransmitCurrentPayments() {
        return _getFutureZNumber()
        .then( futureZNumber =>{

          return _promiseV30CleanAndTransmitCurrentPayments(futureZNumber)
          .then( ()=> {
            return PositiveTS.Storage.Entity.SvaTransmitLog.createEntriesForNonExistingShvaTransmits();
          });
          
        })
      }

    }
  }
}




