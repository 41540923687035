module PositiveTS {
    export module Service {
        export module Translation {
            const translationFieldMapper = {
                /** If you want to add a new model, do it like this
                 * ModelType: { "targetField": "SourceValue" }
                 */
                "SaleItem": {
                    itemDescription: "name",
                },
                "Item": {
                    name: "name",
                    details: "details",
                    description: 'name',
                    shortDetails: "short_details",
                },
                "Menu": {
                    name: "name"
                },
                "ItemGroup": {
                    name: "name"
                },
                "ItemGroupItem": {
                    itemDesc: "name"
                },
                "Upsale": {
                    textToShow: "text_to_show",
                },
                "PreparationInstruction": {
                    name: "name"
                },
            }
            
            function shouldTranslate(): boolean {
                return Boolean(Pinia.languageManagerStore.multiLangEnabled);
            }
            
            /**
             * 
             * @param model 
             * @param modelType 
             * @param lang 
             * @param modelTransSource 
             * @returns 
             */
            type ModelType = "SaleItem" | "Menu" | "Item" | "ItemGroup" | "ItemGroupItem" |"Upsale" |"PreparationInstruction";
            export function translateModel(model: any, modelType: ModelType, lang: string, modelTransSource?: string) {
                if (shouldTranslate() === false) {
                    return model;
                }
                let translations: TranslationObj = posUtils.jsonParseWasmCol(model.translations || modelTransSource);
                if (!translations || posUtils.isBlankLikeRails(translations[lang])) {
                    console.warn("No translations found for this model : ", model);
                    return model
                }
                let modelTranslationMapper = translationFieldMapper[modelType];
                let fields = Object.keys(modelTranslationMapper);
                for (const target of fields) {
                    let source = modelTranslationMapper[target]
                    model[target] = translations[lang][source];
                }
                return model;
            }

            /**
             * A shortcut function to translate's a sale's items name and description. 
             * Recommanded for use this for printing and display UI
             * @param saleItems The sale items to translate
             * @returns return the same sale items but with translated fields according to the current lang
             */
            export function formatSaleItems(args: TranslationFormatSaleItemsArgs): PositiveTS.Storage.Entity.SaleItem[] {
                let saleItems = args.saleItems;
                if (shouldTranslate() === false) {
                    return saleItems;
                }
                let currentLang: string = args.lang;
                let saleItemsDup = _.cloneDeep(saleItems);
                saleItems = saleItemsDup.map(saleItem => {
                    let si = PositiveTS.Service.Translation.translateModel(saleItem, 'SaleItem', currentLang, saleItem.item.translations);
                    if (si.children) {
                        si.children = si.children.map(csi => PositiveTS.Service.Translation.translateModel(csi, 'SaleItem', currentLang, csi.item.translations));
                    }
                    return si;
                });
                return saleItems;
            }
        }
    }
}