module PositiveTS {
  export module Components {
  export module SelectableTable {
  
    const _vueComponentName = 'selectable-table';


    export function getComponent() {
      let selectableTable = {
          template: JST.selectableTable(),
          props:{
            columns: {
              type: Array,
              default: []
            },
            rows: {
              type: Array,
              default: []
            },
            greyTheme: {
              type: Boolean,
              default: false
            }
          },
          methods: {
            select(index) {
              this.selectedIndex = index
              this.$emit('selected', this.rows[index]);
            }
          },
          data() {
            return {
              selectedIndex: null
            };
          },
      }
      VueApp.component(_vueComponentName,selectableTable);
      return selectableTable;
    }
}}}
