module PositiveTS {
   export module Service {
      export module WebsocketSync {
         export class BasicMessageSyncModule extends BaseSyncModule {

            constructor() {
               super();
            }

            public setWebsocketEvents(websocket) {
               websocket.on('message', message => {
                  console.log(message)
               });
            }

            async sendMessage(message: any): Promise<any> {
               let result = await this.getSocket().validateConnectionAndDo(async (): Promise<any> => {
                  return await this.getSocket().makeActionAndWaitForResult('sendMessage', message);                  
               });

               if (!result.success && !result.errorMessage) {
                  result.errorMessage = i18next.t('dalpaks.errorConnectingToDalpakServer');
               }

               return result;
            }


            public setInitialConnectionDataAndConnect() {
               // Action not needed here
               return;
            }

            public async waitForInitialData() {
               // Action not needed here
               return;
            }            
         }
      }
   }
}
