module PositiveTS {
  export module Service {
    export module ReportZByDate {

      const url = '/ZReportsByRange?';

      export function getReport(fromDate:Date, toDate, byFirstSale = false){
        return FetchReq.jsonReq(url + jQuery.param( {fromDate:fromDate, toDate: toDate, by_first_sale: byFirstSale} ), 'get')
        .then( (response) => {
           if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
           return response.result;
         });
      }

    }
  }
}
