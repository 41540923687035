import i18next from "i18next";
export default abstract class CreditCardBaseOutput {

  public TerminalId: string;
  public Command: string;
  public ResultCode: string;
  public Status: string;
  public Mti: string;
  public CardName: string
  public CurrencyName: string
  public Currency: string
  public DateTime: string
  public Amount: string
  public FirstPayment: string
  public ParameterJ: string
  public creditCurrency: string
  public currencyRate: number
  public creditLeadCurrencyAmount: number
  public creditCurrencyFullNumber: number //is for big float number for calculations
  public Uid: string
  public CreditTerms: string
  abstract get getPanEntryMode(): string;
  public AuthCodeSolek: string
  public NoPayment: string
  public CardType: string
  get amount() { return Number(this.Amount) / 100; }
  set amount(value: number) { this.Amount = String(value * 100) }
  get valid_until() { return ""; }
  get phone_number() { return ""; }
  get transactionDate() { return `${this.Uid.substr(4, 2)}/${this.Uid.substr(2, 2)}/${this.Uid.substr(0, 2)} ${this.Uid.substr(6, 2)}:${this.Uid.substr(8, 2)}` }
  get payments_count() {
    let num = Number(this.NoPayment)
    return isNaN(num) ? 1 : (this.CreditTerms == "6" ? num : num+1);
  }

  
  get ceditTermsHebrew() {
    switch (this.CreditTerms) {
      case "1":
        return i18next.t("emv.creditTerms.regular")
      case "2":
        switch (this.CardType) { //TODO-EMV: determine card type (appdx 9.2) from CardType 3 digit code
          case "093":
          case "030":
            return i18next.t("emv.creditTerms.isracredit")
          case "000":
          case "001":
          case "002":
            return i18next.t("emv.creditTerms.adifPlus30")
          default:
            return i18next.t("emv.creditTerms.credit")
        }
      case "3":
        return i18next.t("emv.creditTerms.immediateCharge")
      case "6":
        return i18next.t("emv.creditTerms.credit")
      case "8":
        return i18next.t("emv.creditTerms.payments")
      default:
        return ""
    }
    
  }
  get panEntryModeHebrew() {
    let pem = (this.getPanEntryMode == "" || this.getPanEntryMode == null) ? -1 : Number(this.getPanEntryMode)
    switch (pem) {
      case 0:
        return i18next.t("emv.panEntries.magnetic")
      case 4:
        return "CTLMSR"
      case 5:
        return "CTLEMV"
      case 6:
      case 7:
        return i18next.t("emv.panEntries.cellular")
      case 50:
      case 51:
        return i18next.t("emv.panEntries.manual")
      default: //TODO-EMV: not sure about this....
        return i18next.t("emv.panEntries.kaf")
    }
  }


}