module PositiveTS {
  export module Dialogs {
    export module GenericSelect {
      interface _rowItf{
        code:string
        name:string
      }

      export function open(itemList:_rowItf[], params={tableViewCaption:"בחר", numberOfRows: 6}):Promise<_rowItf>{
            
            OptionSelectUI.dialog('open');
            return _initTable(itemList, params);
      }

      function _initTable(itemList:_rowItf[], params):Promise<_rowItf>{

        $('#OptionSelect-dialog-cancel').hide();
        $('#OptionSelect-dialog-continue').hide();

        var tableView = $('#OptionSelect-dialog-tableview').tableview();
        tableView.setNumberOfVisibleRows(params.numberOfRows);
        tableView.empty();
        $("#OptionSelect-dialog .ui-tableview-header").html(params.tableViewCaption)

        for (var i=0;i<itemList.length; i++){
          var theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
                      itemList[i].name
          ]));
          theRow.data('data', itemList[i]);
        }

        return new Promise( (resolve) => {
          $("#OptionSelect-dialog-tableview .ui-tableview-row-cell").on('click',function(m){
            var selected =  $(m.target).closest('tr').data().data;
            OptionSelectUI.dialog('close');
            $("#OptionSelect-dialog .ui-tableview-header").html("בחר")
            resolve(selected);
          });
        });




      }

    }
  }
}