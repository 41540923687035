module PositiveTS {
  export module Components {
      export module PosSalesPerson {
        const dialogSelector = "pos-sales-person"
  

        interface CompData {
          initialized:boolean,
          searchInput:string,
          loading:boolean,
          rejectFunc:any,
          resolveFunc:any,
          saleItemsCopy:Array<Storage.Entity.SaleItem>,
          dialogElem:HTMLDialogElement,
          employees:any,
          selectForAllSale:boolean,
          showBackButton:boolean,
          selectSalesPersonWithoutContinueBtn:boolean
        }

        function initData():CompData {
          return {
            initialized: false,
            searchInput: "",
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            saleItemsCopy: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            employees: {},
            selectForAllSale: false,
            showBackButton: false,
            selectSalesPersonWithoutContinueBtn: jsonConfig.getVal(jsonConfig.KEYS.selectSalesPersonWithoutContinueBtn)
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.posSalesPerson(),
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
              },
              
             close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }

                  this.supposedToBeOpen = false;
                  this.dialogElem.close();
                }
                this.cleanData()
              },

              open(saleItems:Array<Storage.Entity.SaleItem>, selectForAllSale:boolean = false, showBackButton = false) {
          
                return new Promise(async (resolve,reject) => {
      
                  this.showBackButton = showBackButton;
                  this.initialized = true;
                  this.selectForAllSale = selectForAllSale;
      
                  if (saleItems == null && !selectForAllSale) {
                    return resolve();
                  }

                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
            
                  
                  let hasEmployees = await this.showEmployeeGrid(saleItems)
                  
                  // $(document).unbind('keypress');
        
                  if (hasEmployees) {
                    if (this.dialogElem == null) {
                      this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                    }

                    this.supposedToBeOpen = true;
                    this.dialogElem.showModal();
                  }
                  else {
                    this.resolveFunc();
                    this.close();
                  }
                  
                })
              },
              
              async showEmployeeGrid(saleItems:Array<Storage.Entity.SaleItem>) {
       
                if (!this.selectForAllSale) {
                  this.saleItemsCopy = _.cloneDeep(saleItems)
                }
            
                try {
                  let employees = await Storage.Entity.Employee.fetchByStoreOrByShift(session.pos.storeID)
                  
                  let tablematrix = $('#pos-sales-person-tablematrix').tablematrix();
                  tablematrix.empty();
                  this.employees = {};
            
                  if (employees.length == 0) {
                    await app.showAlertDialog({
                      header: i18next.t('error'),
                      content: i18next.t('noEmployeesInShift'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    })
                    return false;
                  }
            
                  // Iterate over the results
                  for (let employee of employees) {
            
                    // Add a cell
                    var cellId = tablematrix.addCell(employee.name);
                    this.employees[cellId] = employee;
                  }
                  return true;
            
                }
                catch(e) {
                  console.error('Fetching employees failed. Please contact support.');
                  await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: 'Fetching employees failed. Please contact support.',
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                };
            
              },

              async confirmAndClose() {
                  
                let cellId = $('#pos-sales-person-tablematrix').tablematrix().getSelectedCell();
            
                // If there are no selected rows, finish
                if (!(cellId) || cellId.length == 0) {
                  if (_.isEmpty(this.employees)) {
                    this.resolveFunc()
                    this.close();
                    return;  
                  }
                  else {
                    return;
                  }
                }
                
                if (this.selectForAllSale) {
                  await posVC.updateSalesPersonForSale(this.employees[cellId]);
                } else {
                  await posVC.updateSalesPersonForItem(this.saleItemsCopy, this.employees[cellId]);
                }
                  
                this.resolveFunc()
                this.close();
                
              },
              validateClose() {
                if (this.supposedToBeOpen) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  this.dialogElem.showModal();
                }
              },
              back() {
                this.resolveFunc();
                this.close();
              },
            },
            data: initData,
            computed: {
              totalAmount(){
                  return session.fixedNumber(Pinia.globalStore.totalAmount);
              }

            }
          }
          
          VueApp.component('pos-sales-person-dialog',component);
        }
      }
    }
  }
  