/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"LockScreen", arguments: []})

*/
module PositiveTS {
export module Components {
export module CashierStatement {
  const _dialogSelector = "#positive-cashier-statement-dialog"
  const _vueComponentName = 'cashier-statement';

  type CompType = any;

  export function create() {
    let vueObj = {
        template: JST.cashierStatement(),
        methods: {
          async open() {
            let self:CompType = this;
            self.elem = <HTMLDialogElement>window.document.getElementById('positive-cashier-statement-dialog')
            self.elem.show();
            if (session.isAndroid) {
              document.getElementById("cashier-statement-cash-200").blur();
              Array.from(document.getElementsByClassName('money-input')).forEach(elem => elem.setAttribute("data-keyboardoffset","20"))
            }
            self.xReportCash =  session.fixedFloat( await self.getCashInPos())
          
          },
          close() {
            let self:CompType = this;
            self.bills = (_initData()).bills;
            if (session.isAndroid) {
              Array.from(document.getElementsByClassName('money-input')).forEach(elem => elem.removeAttribute("data-keyboardoffset"));
            }
            self.elem.close();
          },
          openDrawer() {
            printer.openDrawer();
          },
          isToShowX() {
            return jsonConfig.getVal(jsonConfig.KEYS.hideCashAmountOnDeclarations);
          },
          cancel() {
            let self:CompType = this;
            if(Service.CashierStatement.forceCashDeclarationsAtEndOfDay() && parseInt(self.totalCashStatement()) > 0
                && self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_END_DAY) {
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('cashierStatement.closeConfirmation'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: false
              },() => { self.close() }, null);
            } else {
              self.close();
            }
          },
          totalCashStatement() {
            let self:CompType = this;
            let total = 0;
            self.bills.forEach(element => {
              total += element.value * element.count;
            });
            return total.toFixed(2);
          },
          async acceptStatement() {
            let self:CompType = this;
            if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS
              || self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS) {
                self.cashManagementConfirmBox();
                return;
            }

            if(jsonConfig.getVal(jsonConfig.KEYS.requireEmployeeLoginOnCashStatements)) {
Pinia.componentsStore.openComponent( {componentName:"loginDialog", args: [true]});
            } else {
              await self.generateStatement({id: session.pos.employeeID, name: session.pos.employeeName})
            }
          },
          async generateStatement(employee) {
            let self:CompType = this;
            let newStatement = new Storage.Entity.CashStatementLog;
            newStatement.employee_id = employee.id;
            newStatement.type = self.cashierStatementType;
            newStatement.amount = Number(self.totalCashStatement());
            

            if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS ){
              Printing.Reports.printCashManagementStatement(
                self.cashierStatementType,
                session.pos.companyName,
                session.pos.storeName,
                session.pos.deviceID,
                employee.name,
                self.bills,
                i18next.t("cashManagement.removeButton"),
                i18next.t("cashManagement.cashToSafe"));
            } else if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS) {
              Printing.Reports.printCashManagementStatement(
                self.cashierStatementType,
                session.pos.companyName,
                session.pos.storeName,
                session.pos.deviceID,
                employee.name,
                self.bills,
                i18next.t("cashManagement.addButton"),
                i18next.t("cashManagement.addedCash"));
            } else {
              Printing.Reports.printCashierStatement(
                self.cashierStatementType,
                session.pos.companyName,
                session.pos.storeName,
                session.pos.deviceID,
                employee.name,
                self.bills
              );
            }
            
            if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_START_DAY) {
              Service.CashierStatement.setLastEnterCashierStatement(self.totalCashStatement());
              if (Service.CashierStatement.forceCashDeclarationsAtBeginningOfDay()) {
                app.PosIconClick=true;
                pNavigator.pushPage('pos', i18next.t('homepage.pos'), null, null);
              }
            } 
            else if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS) {
              Service.CashierStatement.setLastEnterCashierStatement(parseFloat(self.totalCashStatement()) + Service.CashierStatement.getLastEnterCashierStatementNumber());
            }
            else if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS) {
              Service.CashierStatement.addCashSumWithdrawnToSafe(self.totalCashStatement());
            }
            else if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_END_DAY) {
              let realZCash = await self.getCashInPos();
              let difference = realZCash - Number(self.totalCashStatement());
              let allowedDifference = jsonConfig.getVal(jsonConfig.KEYS.allowedDeclarationToXDifference);

              if(difference > allowedDifference || difference < allowedDifference * -1) {
                let zDifferenceDialog = await app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: i18next.t('cashierStatement.zDifferenceProblem'),
                  continueButtonText: i18next.t("cashierStatement.zDifferenceProblemOk"),
                  cancelButtonText: i18next.t("cashierStatement.zDifferenceProblemCancel"),
                  hideCancelButton: false
                });
                if(zDifferenceDialog == "cancel") {
                  return ;
                }
              }
              Service.CashierStatement.setCashierZStatement(self.totalCashStatement());
              if(difference < 0) {
                difference *= -1;
              }
              newStatement.difference = difference;
              newStatement.z_amount = realZCash
              newStatement.opening_amount = Number(Service.CashierStatement.getLastEnterCashierStatementNumber());
              newStatement.cash_in_safe = Number(Service.CashierStatement.getCashSumWithdrawnToSafe());
              newStatement.syncStatus = Shared.Constants.CashierStatement.SYNC_STATUS_AWAITING_Z;

              pNavigator.pushPage('zreport', i18next.t('homepage.zreport'), '/homepage', null);
              zReportVC.createNewZReportMain();
              
            }

            appDB.cashStatementLogs.put(newStatement);

            self.close();
          },
          async cashManagementConfirmBox() {
            let self:CompType = this;
            if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS) {
              app.showAlert({
                header: i18next.t('cashManagement.areYouSure'),
                content: i18next.t('cashManagement.removeCashApprove', {sum: self.totalCashStatement(), date: moment().format('DD/MM/YYYY'), time: moment().format("HH:m:s")}),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: false
              },async () => { 
                if(jsonConfig.getVal(jsonConfig.KEYS.requireEmployeeLoginOnCashStatements)) {
Pinia.componentsStore.openComponent( {componentName:"loginDialog", args: [true]}); 
                } else {
                  await self.generateStatement({id: session.pos.employeeID, name: session.pos.employeeName});
                }}, null);
            }
            else if(self.cashierStatementType == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS) {
              app.showAlert({
                header: i18next.t('cashManagement.areYouSure'),
                content: i18next.t('cashManagement.addedCashApprove', {sum: self.totalCashStatement(), date: moment().format('DD/MM/YYYY'), time: moment().format("HH:m:s")}),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: false
              },async () => { 
                if(jsonConfig.getVal(jsonConfig.KEYS.requireEmployeeLoginOnCashStatements)) {
Pinia.componentsStore.openComponent( {componentName:"loginDialog", args: [true]}); 
                } else {
                  await self.generateStatement({id: session.pos.employeeID, name: session.pos.employeeName});
                } }, null);
            }
            return false;
          },
          async getCashInPos() {
            return Service.Withdrawal.cashInPos();
          },
          replaceDotWithDash(str:string) {
            return str.replace(".", "-");
          }
        },
        data: _initData,
        computed: {
          cashierStatementType(){
            return Pinia.globalStore.cashierStatementType
          },
          title () {
            return i18next.te(`cashierStatement.statementTitle.${this.cashierStatementType}`) ? this.$t(`cashierStatement.statementTitle.${this.cashierStatementType}`) : ''
          },
        },
        mounted() {
          let self = this;
          emitter.on('user-logged', async employee => { await self.generateStatement(employee); });
        }
    }
    VueApp.component(_vueComponentName,vueObj)
    return vueObj;
  }

  
  

  function _initData(){
    return {
      imgs: {
        drawer: `${(<any>window).images_path}pos/open_drawer.png`,
        clear: `${(<any>window).images_path}clear_big.png`
      },
      bills: createBills(),
      hideCashAmountOnDeclarations: jsonConfig.getVal(jsonConfig.KEYS.hideCashAmountOnDeclarations),
      xReportCash: 0,
      statingEmployee: null,
      dialogTitle: "",
      elem: null
    };
  }

  function createBills() {
    let bills = new Array();
    
    bills.push({
      value: 200,
      count: 0,
      height: '200',
      width: '100',
      img: `${(<any>window).images_path}pos-payment/cash200.png`
    });
    bills.push({
      value: 100,
      count: 0,
      height: '200',
      width: '100',
      img: `${(<any>window).images_path}pos-payment/cash100.jpg`
    });
    bills.push({
      value: 50,
      count: 0,
      height: '200',
      width: '100',
      img: `${(<any>window).images_path}pos-payment/cash50.png`
    });
    bills.push({
      value: 20,
      count: 0,
      height: '200',
      width: '100',
      img: `${(<any>window).images_path}pos-payment/cash20.jpg`
    });
    bills.push({
      value: 10,
      count: 0,
      height: '60',
      width: '60',
      img: `${(<any>window).images_path}pos-payment/cash10.png`
    });
    bills.push({
      value: 5,
      count: 0,
      height: '60',
      width: '60',
      img: `${(<any>window).images_path}pos-payment/cash5.png`
    });
    bills.push({
      value: 2,
      count: 0,
      height: '60',
      width: '60',
      img: `${(<any>window).images_path}pos-payment/cash2.png`
    });
    bills.push({
      value: 1,
      count: 0,
      height: '60',
      width: '60',
      img: `${(<any>window).images_path}pos-payment/cash1.png`
    });
    bills.push({
      value: 0.5,
      count: 0,
      height: '60',
      width: '60',
      img: `${(<any>window).images_path}pos-payment/cash05.png`
    });
    bills.push({
      value: 0.1,
      count: 0,
      height: '60',
      width: '60',
      img: `${(<any>window).images_path}pos-payment/cash010.jpg`
    });

    return bills;
  }
}}}
