module PositiveTS {
  export module Service {
    export module EmployeeTimeReport {

      /*
      {
          employee_id: employeeID,
          month:       month,
          year:        year
          dataType: undefined/json
      }
      */
      export function getReport(timeReportObject, getInHtmlFormat = true): Promise<any> {
        if (timeReportObject === null || timeReportObject === undefined) {
          return Promise.reject(new Error('timeReportObject undefined'));
        }
        if (!timeReportObject["employee_id"]) {
          delete timeReportObject["employee_id"];
        }

        return FetchReq.textReq('/employee_time_tracks?' + $.param(timeReportObject), 'get', undefined, getInHtmlFormat)
          .then((response) => {
            if (!response.response.ok && !response.result) { throw new Error("SERVER_INTERNAL_ERROR"); }
            return response.result;
          });
      }

      export function getEmployeeWithUnclosedTimeSheet(): Promise<string[]> {
        return getReport({ month: (new Date()).getMonth() + 1, year: (new Date()).getFullYear() }, false)
          .then(response => {
            return _parsedOutput(JSON.parse(response));
          })
          .then(empIds => {

            var employeeNames = [];

            for (var i = 0; i < empIds.length; i++) {
              _addEmpname(empIds[i].toString(), employeeNames);
            }

            return employeeNames;
          })
      }

      function _addEmpname(cardNumber: string, byrefAray: string[]): any {
        var strSql = `select * from employee where employeeID = '${cardNumber}'`;
        byrefAray.push(PositiveTS.Service.WasmDB.execAsObject(strSql)[0].name)
      }

      function _parsedOutput(obj): number[] {
        var empList: number[] = []
        for (var key in obj) {
          let itm = obj[key];
          let lastStampIsExit = itm.tracks[itm.tracks.length - 1].mode === 1;
          if (lastStampIsExit) {
            empList.push(itm.employee.employee_id)
          }
        }
        return empList;
      }

    }
  }
}
