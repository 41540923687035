module PositiveTS {
  export module Components {
    export module SimplyClubCustomerClub {
      const dialogSelector = 'simplyclub-customer-club';

      export function create() {

        let simplyClubCustomerClub = {
          mixins: [Mixins.oneClickMixin, Mixins.scrollableMixin],
          components: { scrollable: PositiveTS.Components.Scrollable.getComponent() },
          template: JST.simplyClubCustomerClub(),
          methods: {
            open(customer: PositiveTS.Service.SimplyClubAPI.MemberDetails = null) {
              if (customer) {
                this.previousCardNumber = customer.CardNumber;
                this.previousPhoneNumber = PositiveTS.Service.SimplyClubService.getPhoneNumberFromCustomer(customer);
                let memberByFieldId = _.keyBy(customer.MemberFields, 'FieldId');
                this.customer = {
                  firstName: customer.FirstName,
                  lastName: customer.LastName,
                  card_field: customer.CardNumber,
                  mobile: this.previousPhoneNumber,
                  email: memberByFieldId['email'] ? memberByFieldId['email'].FieldValue : '',
                  birthDate: memberByFieldId['birthday'] ? moment(memberByFieldId['birthday'].FieldValue, 'DD/MM/YYYY').toDate() : null,
                  aniversaryDate: memberByFieldId['wedding_day'] ? moment(memberByFieldId['wedding_day'].FieldValue, 'DD/MM/YYYY').toDate() : null,
                  if_send_sms: memberByFieldId['if_send_sms'] ? memberByFieldId['if_send_sms'].FieldValue.toLowerCase() === 'true' : false,
                  if_send_email: memberByFieldId['if_send_email'] ? memberByFieldId['if_send_email'].FieldValue.toLowerCase() === 'true' : false,
                  current_points: customer.TotalCashBack ? session.fixedNumber(customer.TotalCashBack) : 0
                };
                this.setMode('edit');
              } else {
                this.setMode('search');
              }
              this.$el.showModal();
              $(document).unbind('keypress');
              return new Promise(resolve => {
                this.resolveFunc = resolve
              });
            },
            close() {
              this.searchInput = "";
              this.showSearchResults = false;
              this.customers = [];
              this.customer = {};
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();
            },
            async swipeCard(): Promise<void> {
              app.showLoadingMessageDialog(i18next.t("mizdamenOrHakafaDialog.swipeCustomerCard"));
              const swipeCardInput = await Service.EMV.swipe();
              this.searchInput = swipeCardInput || "";
              if (this.searchInput) {
                await this.searchCustomer()
              }
              app.hideLoadingMessageDialog();
            },
            async searchCustomer() {
              if (this.searchInput.trim() == "") {
                return;
              }
              this.loading = true;
              this.showLoading(i18next.t('simplyClubCustClub.searchingCustomerPleaseWait'));
              try {
                let membersRes = await PositiveTS.Service.SimplyClubService.searchMemebers(this.searchInput);
                this.customers = membersRes.Members;
                this.noCustomersFound = membersRes.Members.length <= 0;
                this.loading = false
                this.showSearchResults = true;
                if (membersRes.sentJoinSMS) {
                  this.showAlert(i18next.t("simplyClubCustClub.memberNotFoundSentJoinSms"));
                }
              } catch (err) {
                console.log(err)
                this.showError(err.message);
                this.loading = false;
              }
              finally {
                app.hideLoadingMessageDialog();
              }
            },
            async sendSmsToAddCustomer() {
              this.showLoading(i18next.t('simplyClubCustClub.sending'));
              try {
                let res = await PositiveTS.Service.SimplyClubService.sendSmsToJoin(this.customer.customerNumber);
                if (res.ResultCode === 0) {
                  this.showAlert(i18next.t("simplyClubCustClub.sentCheckSms"));
                } else {
                  this.showError(res.ErrorMessage || i18next.t("error"));
                }
              } catch (err) {
                console.log(err)
                this.showError(err.message);
                this.loading = false;
              }
              finally {
                app.hideLoadingMessageDialog();
              }
            },
            async addNewCustomer() {
              let validResult = this.validate(this.customer);
              if (validResult.valid == false) {
                let msg = "";
                for (const err of validResult.errorMessages) {
                  msg += err + '\n';
                }
                this.showError(msg);
                return;
              }
              this.showLoading(i18next.t('loading'));
              try {
                let res = await PositiveTS.Service.SimplyClubService.addMember({
                  first_name: this.customer.firstName, last_name: this.customer.lastName,
                  card_field: this.customer.card_field,
                  cell_number: this.customer.mobile, email: this.customer.email,
                  birthday: this.customer.birthDate ? moment(this.customer.birthDate, "DD/MM/YYYY").toDate() : null,
                  wedding_day: this.customer.aniversaryDate ? moment(this.customer.aniversaryDate, "DD/MM/YYYY").toDate() : null,
                  if_send_email: this.customer.if_send_email,
                  if_send_sms: this.customer.if_send_sms,
                });
                if (res.ErrorCode === 0) {
                  this.showAlert(i18next.t("simplyClubCustClub.addedCustomerSuccessfully"));
                  this.selectCustomer(res["Member"]);
                } else {
                  this.showError(res.ErrorMessage || i18next.t("error"));
                }
              } catch (err) {
                console.log(err)
                this.showError(err.message);
                this.loading = false;
              }
              finally {
                app.hideLoadingMessageDialog();
              }
            },
            async updateCustomer() {
              let validResult = this.validate(this.customer);
              if (validResult.valid == false) {
                let msg = "";
                for (const err of validResult.errorMessages) {
                  msg += err + '\n';
                }
                this.showError(msg);
                return;
              }
              this.showLoading(i18next.t('loading'));
              try {
                if (this.customer.card_field != this.previousCardNumber && this.customer.card_field) {
                  let res = await PositiveTS.Service.SimplyClubService.updateMemberCardNumber(this.previousCardNumber, this.customer.card_field);
                  if (res.ErrorCode !== 0) {
                    this.showError(res.ErrorMessage || i18next.t("error"));
                    return;
                  }
                }
                let res = await PositiveTS.Service.SimplyClubService.updateMember(this.previousPhoneNumber, {
                  first_name: this.customer.firstName, last_name: this.customer.lastName,
                  card_field: null,
                  cell_number: this.customer.mobile, email: this.customer.email,
                  birthday: this.customer.birthDate ? moment(this.customer.birthDate, "DD/MM/YYYY").toDate() : null,
                  wedding_day: this.customer.aniversaryDate ? moment(this.customer.aniversaryDate, "DD/MM/YYYY").toDate() : null,
                  if_send_email: this.customer.if_send_email,
                  if_send_sms: this.customer.if_send_sms,
                });
                if (res.ErrorCode !== 0) {
                  this.showError(res.ErrorMessage || i18next.t("error"));
                }
                this.showAlert(i18next.t("simplyClubCustClub.updatedCustomerSuccessfully"));
                this.selectCustomer(res["Member"]);
              } catch (err) {
                console.log(err)
                this.showError(err.message);
                this.loading = false;
              }
              finally {
                app.hideLoadingMessageDialog();
              }
            },
            validate(customer) {
              let result = { valid: true, errorMessages: [] };
              if (!customer.firstName || !customer.firstName.trim()) {
                result.errorMessages.push(i18next.t('simplyClubCustClub.errField.firstNameRequired'));
              }
              if (!customer.lastName || !customer.lastName.trim()) {
                result.errorMessages.push(i18next.t('simplyClubCustClub.errField.lastNameRequired'));
              }
              if (!customer.mobile || !customer.mobile.trim()) {
                result.errorMessages.push(i18next.t('simplyClubCustClub.errField.mobileRequired'));
              }
              result.valid = result.errorMessages.length <= 0;
              return result;
            },
            async selectCustomer(customerObj) {
              try {
                this.showLoading(i18next.t('loading'));
                let customerDetailsRes = await PositiveTS.Service.SimplyClubService.getCustomerDetails(customerObj.CardNumber);
                if (customerDetailsRes.ErrorCode !== 0) {
                  console.error(customerDetailsRes);
                  this.showError(customerDetailsRes.ErrorMessage || i18next.t('unknownErrorHasOccured'));
                  return;
                }
                await PositiveTS.Service.SimplyClubService.setCustomerOnSale(customerDetailsRes.Member, posVC.sale);
                this.close();
                let br = "<br>";
                let cus = customerDetailsRes.Member;
                let exportMomentDate = moment(cus.ExpDate);
                let memberTextulInfo = cus.FirstName + " " + cus.LastName + br;
                memberTextulInfo += i18next.t("simplyClubCustClub.idCard") + " : " + cus.CardNumber + br;
                memberTextulInfo += i18next.t("simplyClubCustClub.expireDate") + " : " + exportMomentDate.format('DD/MM/YYYY') + br;
                memberTextulInfo += i18next.t("simplyClubCustClub.lastSaleDate") + " : " + moment(cus.LastSaleDate).format('DD/MM/YYYY HH:mm') + br;
                memberTextulInfo += i18next.t("simplyClubCustClub.balance") + " : " + cus.TotalCashBack + br + br;
                memberTextulInfo += "<h2>" + i18next.t("simplyClubCustClub.benefits") + "</h2>";
                memberTextulInfo += "<h3>" + i18next.t("simplyClubCustClub.points") + "</h3>";
                cus.PossibleCashBackBenefits.forEach(bene => memberTextulInfo += ` - ${bene.PromoId}: ${bene.Description} ${bene.Amount}` + br);
                memberTextulInfo += "<h3>" + i18next.t("simplyClubCustClub.discounts") + "</h3>";
                cus.PossibleDiscountBenefits.forEach(bene => memberTextulInfo += ` - ${bene.PromoId}: ${bene.Description} ${bene.Amount}` + br);
                memberTextulInfo += "<h3>" + i18next.t("simplyClubCustClub.accumulating") + "</h3>";
                cus.AllAccums.forEach(bene => memberTextulInfo += ` - ${bene.AccID}: ${bene.BenefitName} ${bene.BenefitValue}` + br);
                await app.promiseShowAlert({
                  header: i18next.t("simplyClubBenefits.title"),
                  content: '<div style="text-align: right;">' + memberTextulInfo + '</div>',
                  continueButtonText: i18next.t("continue"),
                  hideCancelButton: true,
                });
                if (this.mode === "search" && exportMomentDate.toDate() < new Date()) {
                  let response = await app.promiseShowAlert({
                    header: i18next.t("payAttention"),
                    content: i18next.t("simplyClubCustClub.memberExpiredDoYouWantToRenew"),
                    continueButtonText: i18next.t("ok"),
                  });
                  if (response == "continue") {
                    let code = jsonConfig.getVal(jsonConfig.KEYS.simplyClubRenewItemCode);
                    await posVC.addItemByBarcodeOrByCode(code, true);
                  }
                }
                else if (this.mode === "new") {
                  let code = jsonConfig.getVal(jsonConfig.KEYS.simplyClubJoinItemCode);
                  await posVC.addItemByBarcodeOrByCode(code, true);
                }
                posVC.loadSale();
              } catch (err) {
                console.log(err)
                this.showError(err.message);
                this.loading = false;
              }
              finally {
                app.hideLoadingMessageDialog();
              }

            },
            setMode(mode) {
              this.mode = mode;
              switch (mode) {
                case "search":
                  this.searchCustomerMode = true;
                  this.addNewCustomerMode = false;
                  this.customerSelected = false;
                  break;
                case "new":
                  this.searchCustomerMode = false;
                  this.addNewCustomerMode = true;
                  PositiveTS.VueInstance.$nextTick(() => {
                    this.$refs.customerNumber.focus();
                  })
                  break;
                case 'edit': {
                  this.searchCustomerMode = false
                  this.addNewCustomerMode = false;
                  this.customerSelected = true;
                  break;
                }
                default:
                  //do noting
                  break;
              }
            },
            selectInput(field) {
              this.$refs[field].focus();
            },
            showError(errStr) {
              app.showErrorAlertDialog(errStr);
            },
            showAlert(msg: string, header = i18next.t("note")) {
              app.showAlertDialog({
                header: i18next.t("note"), continueButtonText: i18next.t("ok"),
                content: msg,
                hideCancelButton: true, noHandleEnterEscape: true,
              });
            },
            showLoading(msg: string) {
              app.showLoadingMessageDialog(msg);
            },
          },
          data() {
            return {
              initialized: true,
              searchInput: "",
              loading: false,
              mode: 'search',
              customerSelected: false,
              showSearchResults: false,
              searchCustomerMode: true,
              noCustomersFound: false,
              addNewCustomerMode: false,
              customer: {},
              customers: [],
              /** Used to update cusotmer */
              previousPhoneNumber: null,
              previousCardNumber: null,
              arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
              resolveFunc: null,
            }
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {fullClub,mobileLayout,allowSetNewCustomerDiscountPercent} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {fullClub,mobileLayout,allowSetNewCustomerDiscountPercent}

            return {...globalStoreProps}
          },
        }

        VueApp.component(dialogSelector, simplyClubCustomerClub)

      }
    }
  }
}
