module PositiveTS {
  export module Components {
    export module SelectSplitPaymentAmountDialog {
      const dialogSelector = "select-split-payment-amount-dialog"

      function initData() {
        return {
          amount: '',
          fields: initFields(),
          shouldGoToPaymentScreen: false,
        }
      }

      function initFields() {
        let fields = {
          'amount': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'amount'
          }
        }
        return fields;
      }

      export function create() {

        let component = {
          template: JST.selectSplitPaymentAmountDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            open(shouldGoToPaymentScreen = false) {
              this.cleanData();
              $(document).unbind('keypress');
              if (posUtils.isDefined(this.splitPaymentData)) {
                this.amount = this.splitPaymentCurrentQuantity;
              } else {
                this.amount = posVC.sale.diners ? posVC.sale.diners.toString() : '';
              }

              this.shouldGoToPaymentScreen = shouldGoToPaymentScreen;
      
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;

                this.$nextTick(() => {
                  this.$refs.amount.focus();
                })
              })
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            async confirmAndClose() {
              let amount = parseInt(this.amount);

              if (isNaN(amount) || amount <= 0 || amount > this.reminderToPay()) {
                return this.showError(i18next.t("splitPaymentAmountDialog.invalidAmount"));
              }

              if (this.shouldGoToPaymentScreen) {
                await PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.openPaymentScreen();
              } else {
                
              }

              this.initSplitPayment(amount);
              this.resolveFunc(amount);
              return this.close();
            },
            clearAndClose() {
              this.clearSplitPaymentData();
              this.resolveFunc(null);
              return this.close();
            },
            async exitOnEscape() {
              this.resolveFunc(null);
              return this.close();
            },
            reminderToPay() {
              return posVC.getTotalAmount();
            },
            onKeyTapped(key) {
              this.keyPadHanlder(key, this.fields["amount"], this, 3);
            },
            clearTapped() {
              this.amount = 0;
            },
            showError(errStr) {
              let aThis = this;
              app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                () => {
                  this.$refs.amount.focus();
                }, null)
            },
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {splitPaymentCurrentQuantity,splitPaymentData,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {splitPaymentCurrentQuantity,splitPaymentData,mobileLayout} 

            
            const {clearSplitPaymentData,initSplitPayment} = globalStore
            const globalStoreActions = {clearSplitPaymentData,initSplitPayment}
            return {...globalStoreProps,...globalStoreActions}
          },
          data: initData,
        }

        VueApp.component('select-split-payment-amount-dialog', component)

      }
    }
  }
}
