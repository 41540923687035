module PositiveTS {
   export module Service {
      export module WebsocketSync {
         export interface PrimaryPosOfflineManager {
            moveToOnline();
            onConnectionEstablished();
            onConnectionCompleted();
            syncDataFromPrimary(): Promise<boolean>;
         }

         export type SyncResult = {
            success: boolean,
            errorMessage: string,
            data: any,
            errorCode: number,
         }
         export abstract class BaseSyncModule {
            
            initialConnectionData: any
            websocket: any
            serverErrorCodeToClientTranslation: any
            
            constructor(protected primaryPosPrimaryPosOfflineManager: PrimaryPosOfflineManager = null) {
               this.serverErrorCodeToClientTranslation = {
                  '-1': 'syncServer.errors.serverError',
                  0: 'dalpaks.errorConnectingToDalpakServer',
                  1: 'dalpaks.dalpakIsLocked',
                  2: 'dalpaks.mainPosConnectionProblem',
               }
            }

            public abstract setWebsocketEvents(websocket);
            public getPrimaryPosOfflineManager() {
               return this.primaryPosPrimaryPosOfflineManager;
            }
            public hasPrimaryPosOfflineManager() {
               return posUtils.isDefined(this.getPrimaryPosOfflineManager());
            }

            public async waitForInitialData() {
               await this.initialConnectionData.isDataReceivedPromise.promise;
            }

            setInitialConnectionDataAndConnect() {
               let temp;

               this.initialConnectionData = {
                  isDataReceivedPromise: {
                     promise: new Promise((resolve, reject) => temp = { resolve, reject }),
                     isReceived: false,
                  },
               }
               
               this.initialConnectionData.isDataReceivedPromise = { ...this.initialConnectionData.isDataReceivedPromise, ...temp };
               let initFuncReject = this.initialConnectionData.reject;
               this.initialConnectionData.reject = (err = null) => {
                  initFuncReject(err);
                  temp.reject(err);

                  if (this.initialConnectionData.isDataReceivedPromise.timeout) {
                     clearTimeout(this.initialConnectionData.isDataReceivedPromise.timeout);
                  }
               }

               this.initialConnectionData.isDataReceivedPromise.timeout = setTimeout(() => {
                  if (!this.initialConnectionData.isDataReceivedPromise.isReceived) {
                     this.initialConnectionData.isDataReceivedPromise.reject();
                     throw 'TIMEOUT';
                  }
               }, SyncServerClient.REQUEST_TIMEOUT);


               return this.initialConnectionData.promise;
            }


            public initialDataReceived() {
               if (!this.initialConnectionData.isDataReceivedPromise.isReceived) {

                  this.initialConnectionData.isDataReceivedPromise.isReceived = true;

                  if (this.initialConnectionData.isDataReceivedPromise.timeout) {
                     clearTimeout(this.initialConnectionData.isDataReceivedPromise.timeout);
                  }
                  this.initialConnectionData.isDataReceivedPromise.resolve();

                  this.onConnectionEstablished();
               }
            }

            protected onConnectionEstablished() {
               if (this.hasPrimaryPosOfflineManager()) {
                  this.getPrimaryPosOfflineManager().onConnectionEstablished();
               }
            }

            public getSocket() {
               return SyncServerClient.getInstance();
            }

            protected async handleAction(actionName, data = null) {
               let res = await this.getSocket().validateConnectionAndDo(async () => {
                  return await this.getSocket().makeActionAndWaitForResult(actionName, data);
               })

               return this.convertFromServerResponseToSyncResult(res);
            }

            protected convertFromServerResponseToSyncResult(result: any): SyncResult {
               return {
                  success: result.success,
                  data: result.data,
                  errorMessage: i18next.t(this.serverErrorCodeToClientTranslation[result.errorCode] || 'generalError'),
                  errorCode: result.errorCode
               }
            }

            public genericSuccessResult(data:any = null): SyncResult {
               return {
                  success: true,
                  data: data || true,
                  errorMessage: null,
                  errorCode: null
               }
            }

            public genericErrorResult(errorMessageTransKey = null, data:any = null): SyncResult {
               return {
                  success: false,
                  data: data || true,
                  errorMessage: i18next.t(errorMessageTransKey || 'generalError'),
                  errorCode: -2,
               }
            }
         }
      }
   }
}