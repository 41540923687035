module PositiveTS {

    export module Components {

        export module AddOrEditPromotion {

            export module Shared{

                export const methods = {
                    
                    templateIsKneBe () {
                        return this.template == 14 
                    },
        
                    templateIsHaZolMe () {
                        return this.template == 15
                    },
    
                    templateIsKneKabel () {
                        return this.template == 12
                    },

                    validate(skipValidateItems: boolean = false) {

                        let errors :Array<string> = []
        
                        if (posUtils.isBlank(this.name)) {
                            errors.push(i18next.t("promotionManage.nameCannotBeEmpty"))
                        }
        
                        let isBlankTemplate = posUtils.isBlank(this.template)
        
                        if (isBlankTemplate) {
                            errors.push(i18next.t("promotionManage.templateCannotBeEmpty"))
                        }
        
                        if (posUtils.isBlank(this.priority)) {
                            errors.push(i18next.t("promotionManage.priorityCannotBeEmpty"))
                        }
                        
                        if (0 > moment(this.toDate).diff(this.fromDate, 'minute')){
                            errors.push(i18next.t("promotionManage.toDateCannotBeLittleFromDate"))
                        }

                        //validate template
                        if (!isBlankTemplate){
                            
                            //KneBe && HaZolMe
                            let templateIsKneBe = this.templateIsKneBe()
                            let templateIsHaZolMe = this.templateIsHaZolMe()
        
                            if (templateIsKneBe || templateIsHaZolMe){

                                if (!skipValidateItems && this.promotionItemsBuy.length == 0){
                                    errors.push(i18next.t("promotionManage.itemsCannotBeEmpty"))
                                }
                            
                                let isBlankMinimumBuyQuantity = posUtils.isBlank(this.minimumBuyQuantity)
            
                                if (isBlankMinimumBuyQuantity) {
                                    errors.push(i18next.t("promotionManage.kneCannotBeEmpty")) 
                                }
            
                                if (!isBlankMinimumBuyQuantity && templateIsKneBe && 0 >= this.minimumBuyQuantity) {
                                    errors.push(i18next.t("promotionManage.kneNeedToBeBiggerThenZero"))
                                }
            
                                if (!isBlankMinimumBuyQuantity && templateIsHaZolMe && 1 >= this.minimumBuyQuantity) {
                                    errors.push(i18next.t("promotionManage.minimumBuyQuantityMinimum2"))
                                }
            
                            }
            
                            if (this.templateIsKneKabel()){
        
                                let isBlankQuantitySal2 = posUtils.isBlank(this.quantitySal2)
            
                                let isBlankValueBuyOptionSelected = posUtils.isBlank(this.valueBuyOptionSelected)
                
                                if (isBlankValueBuyOptionSelected) {
                                    errors.push(i18next.t("promotionManage.kneCannotBeEmpty"))
                                }
                
                                if (!isBlankValueBuyOptionSelected && 0 >= this.valueBuyOptionSelected) {
                                    errors.push(i18next.t("promotionManage.kneNeedToBeBiggerThenZero"))
                                }
                
                                if (isBlankQuantitySal2) {
                                    errors.push(i18next.t("promotionManage.kabelCannotBeEmpty"))
                                }
                
                                if (!isBlankQuantitySal2 && 0 >= this.quantitySal2) {
                                    errors.push(i18next.t("promotionManage.kabelNeedToBeBiggerThenZero"))
                                }
                                
                                if (!skipValidateItems && this.promotionItemsBuy.length == 0){
                                    errors.push(i18next.t("promotionManage.itemsBuyCannotBeEmpty"))
                                }

                                if (!skipValidateItems && this.promotionItemsGet.length == 0){
                                    errors.push(i18next.t("promotionManage.itemsGetCannotBeEmpty"))
                                }
        
                            }
        
                            let isBlankDiscountValue = posUtils.isBlank(this.discountValue)
        
                            if (isBlankDiscountValue) {
                            errors.push(i18next.t("promotionManage.discountValueCannotBeEmpty"))
                            }
            
                            if (!isBlankDiscountValue && 0 >= this.discountValue) {
                            errors.push(i18next.t("promotionManage.discountValueNeedToBeBiggerThenZero"))
                            }
        
                        }
                        
                        return {valid: errors.length ? false : true, errors};
        
                    },

                    async addItemsToPromotion (type: 'buy' | 'get') {
                
                        let validate = this.validate(true)
                        
                        if (!validate.valid) {
                          app.hideLoadingMessage()
                          this.showAlertGeneric(validate.errors.join('<br>'))
                          return 
                        }
        
                        VueServices.Router.goto(AddItemsToPromotion.route, {type})
                    
                    }

                }

                export const computed = {
                    hasErrorOnSavePromotion () {
                        return Pinia.promotionsManageStore.getErrorOnSavePromotion             
                    },

                    buyOptionSelected: {
                        get () {
                            return Pinia.promotionsManageStore.getBuyOptionSelected
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_BUY_OPTION_SELECTED(value)
                        }
                    },
                    
                    valueBuyOptionSelected: {
                        get () {
                            return Pinia.promotionsManageStore.getValueBuyOptionSelected
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_BUY_VALUE_BUY_OPTION_SELECTED(value)
                        }
                    },

                    templates () {
                        return Pinia.promotionsManageStore.getTemplates
                    }, 

                    discountTypes () {
                        return Pinia.promotionsManageStore.getDiscountTypes          
                    },

                    templateName () {
                
                        let temp = this.templates.find(t => t.id ==  this.template)
        
                        return typeof temp == 'object' ? temp.name : ''
    
                    }, 

                    isNewPromotion () {
                        return Pinia.promotionsManageStore.getIsNewPromotion   
                    },
        
                    promotionItemsBuy  () {
                        return Pinia.promotionsManageStore.getItemsBuy  
                    },
        
                    promotionItemsGet () {
                        return Pinia.promotionsManageStore.getItemsGet
                    },
    
                    promotion () {
                        return Pinia.promotionsManageStore.getPromotion
                    },
    
                    name: {
                        get () {
                            return this.promotion.name
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'name',  value})
                        }
                    },
    
                    template: {
                        get () {
                            return this.promotion.template
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'template',  value})
                        }
                    },
    
                    minimumBuyQuantity: {
                        get () {
                            return this.promotion.minimumBuyQuantity
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'minimumBuyQuantity',  value})
                        }
                    },
    
                    discountValue: {
                        get () {
                            return this.promotion.discountValue
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'discountValue',  value})
                        }
                    },
    
                    discountType: {
                        get () {
                            return this.promotion.discountType
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'discountType',  value})
                        }
                    },
    
                    quantitySal2: {
                        get () {
                            return this.promotion.quantitySal2
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'quantitySal2',  value})
                        }
                    },
    
                    priority: {
                        get () {
                            return this.promotion.priority
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'priority',  value})
                        }
                    },
                    
                    fromDate: {
                        get () {
                            return typeof this.promotion.fromDate == 'string' ? moment(this.promotion.fromDate, 'DD/MM/YYYY').toDate() : this.promotion.fromDate
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'fromDate',  value})
                        }
                    },
    
                    toDate: {
                        get () {
                            return typeof this.promotion.toDate == 'string' ? moment(this.promotion.toDate, 'DD/MM/YYYY').toDate() : this.promotion.toDate
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'toDate',  value})
                        }
                    },
    
                    isActive: {
                        get () {
                            return this.promotion.isActive
                        },
                        set (value) {
                            Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'isActive',  value})
                        }
                    }

                  }
            } 
        
        }
        
    }

}