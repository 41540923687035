module PositiveTS {
  export module Components {
    
      export module AddOrEditItem {
  
        export const route = "/add-or-edit-item";

        interface ComponentData {
          item:Storage.Entity.Item,
          departments:Array<Storage.Entity.Department>,
          selectedDepartment:Storage.Entity.Department,
          isNew:boolean,
          originalBarcode:string,
          origInv: number,
          invUpdated:boolean,
          bonPrinters: Array<Storage.Entity.LogicalPrinter>,
          selectedBonPrinters: Storage.Entity.LogicalPrinter
        }
        
        export function getComponent() {

          return {
            template: JST.addOrEditItem(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            methods: {
              ...Mixins.modalsMixin.methods,
              async barcodeExists(barcode:string):Promise<boolean> {
                if (posUtils.isNullOrUndefinedOrEmptyString(barcode)) {
                  return false;
                }
                let items = await appDB.localItems.where('barcode').equals(barcode).toArray()
                return (items.length > 0 && items[0].code !== this.item.code);
              },
      
      
              async validate() {
                if (posUtils.isNullOrUndefinedOrEmptyString(this.item.description)) {
                  return {valid: false, error: i18next.t("itemManage.nameCannotBeEmpty")}
                }
      
                // if (this.selectedDepartment == null || this.selectedDepartment.id == null) {
                //   return {valid: false, error: i18next.t("itemManage.departmentMustBeSelected")};
                // }
      
                if ((await this.barcodeExists(this.item.barcode))) {
                  return {valid: false, error: i18next.t("itemManage.barcodeAlreadyExists")};
                }

                if (!posUtils.isNullOrUndefinedOrEmptyString(this.item.barcode) && session.allItems.get(this.item.barcode) != null) {
                  return {valid: false, error: i18next.t("itemManage.barcodeAlreadyExists")};
                }

                if (this.item.priceZarhan != 0 && this.item.isAllowNameChange) {
                  return {valid: false, error: i18next.t("itemManage.mustBePrice0ToChangeName")};
                }
      
                return {valid: true, error: null};
              },
      
              async save() {
                try {
                  app.showLoadingMessage(i18next.t("itemManage.savingItem"));
                  let result = await this.validate();
                  if (!result.valid) {
                    app.hideLoadingMessage();
                    this.showAlertGeneric(result.error);
                    return;
                  }
      
                  if (this.isNew) {
                    let localItems = await appDB.localItems.toArray();
                    let lastItemCode = 10000;
                    for(let item of localItems) {
                      //check if item.code is a number to handle edge cases such as https://pcrs.atlassian.net/browse/TCK-935
                      if (item.code.match(/^[0-9]+$/)) {
                        if(Number(item.code) > lastItemCode){
                          lastItemCode = Number(item.code);
                        }
                      }
                    }

                    lastItemCode++;
                    while (session.allItemBarcodes.get(String(lastItemCode)) != null) {
                      lastItemCode++;
                    }
                    
                    this.item.code = String(lastItemCode);
                    this.item.logicalOrGroupPrinters = "[]";
                    this.item.discounts = "1&2&3&4&5&6&7&8&9&10";
                    //we use space to avoid entity orm setter that makes empty string to null
                    this.item.promoBuy = " "
                    this.item.promoGet = " "
                    this.item.promoSal3 = " "
                    this.item.promoSal4 = " "
                    this.item.allowZeroPrice = true;
                  }
      
                  if (!this.invUpdated) {
                    this.item.invDelta = 0;
                  }
                  this.item.timestamp = new Date().getTime();
                  if (this.selectedDepartment) {
                    this.item.departmentId = this.selectedDepartment.id;
                    this.item.serverDepartmentId = this.selectedDepartment.serverID;
                  }
                  else {
                    this.item.departmentId = -1;
                    this.item.serverDepartmentId = -1;
                  }

                  if(this.selectedBonPrinters){
                    let selectedBonPrintersArr = []
                    for (let currentPrinter of this.selectedBonPrinters) {
                      selectedBonPrintersArr.push(`'${currentPrinter.logicalOrGroupPrinterId}'`)
                    }
                    this.item.logicalOrGroupPrinters = `[${selectedBonPrintersArr.toString()}]`
                  }
                  else{
                    this.item.logicalOrGroupPrinters = "[]"
                  }
                  this.item.hasInventory = !this.item.isAllowNameChange;
                  this.item.description = this.item.description.trim();
                  
                  //we only update the sync status on a clone of the item - to prevent confusion with the allItems cache
                  let tempItem = _.cloneDeep(this.item);
                  tempItem.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  if (tempItem._data) {
                    tempItem._data.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  }
                  let res = await Storage.Entity.Item.saveToDB(tempItem, true);
                  // update the menus when a new item is added or updated
                  Pinia.posMenu.setMenusLoaded(false);
                  Pinia.posMenu.loadMenusIfNeeded();
                  if (this.originalBarcode == this.item.barcode) {
                    if (!posUtils.isNullOrUndefinedOrEmptyString(this.originalBarcode)) {
                      session.allItemBarcodes.set(this.item.barcode,<any>this.item);
                    }
                  }
                  else { //this.item.barcode != this.originalBarcode
                    if (!posUtils.isNullOrUndefinedOrEmptyString(this.originalBarcode)) {
                      session.allItemBarcodes.delete(this.originalBarcode);
                    }
                    if (!posUtils.isNullOrUndefinedOrEmptyString(this.item.barcode)) {
                      session.allItemBarcodes.set(this.item.barcode,<any>this.item);
                    }
                  }
                  app.hideLoadingMessage();
                  VueServices.Router.goto(ItemManage.route);
                }
                catch (e) {
                  app.hideLoadingMessage();
                  console.error(e);
                  this.showAlertGeneric(i18next.t("itemManage.cannotSaveItem"));
                }
              },

              async updateInventory() {
                let dialogParameters = {
                  header: i18next.t('itemManage.currentInventory'),
                  description: i18next.t('itemManage.updateInventoryMessage'),
                  inputPlaceHolder: i18next.t('itemManage.updateInventoryPlaceholder'),
                  showCancelButton: true,
                  emptyErrorMessage: i18next.t('itemManage.pleaseEnterAValue'),
                }
                let result = await inputDg.open(dialogParameters);
                this.invUpdated = true;
                this.item.invDelta += Number(result) - this.item.currentInventory;
                this.item.currentInventory = Number(result);
              },

              async addToInventory() {
                let dialogParameters = {
                  header: i18next.t('itemManage.currentInventory'),
                  description: i18next.t('itemManage.addToInventoryMessage'),
                  inputPlaceHolder: i18next.t('itemManage.addToInventoryPlaceholder'),
                  showCancelButton: true,
                  emptyErrorMessage: i18next.t('itemManage.pleaseEnterAValue'),
                }
                let result = await inputDg.open(dialogParameters);
                this.invUpdated = true;
                this.item.invDelta += Number(result);
                this.item.currentInventory += Number(result);
              },
              cancel(param:string) {
                console.log(param);
                this.item.currentInventory = this.origInv;
                VueServices.Router.goto(ItemManage.route);
              },
      
              selectField(field) {
                (<any>this.$refs[field]).focus();
              },
              blurField(field) {
                (<any>this.$refs[field]).blur();
              }
            },
            data: function() {
              let dataVal: ComponentData = {
                item: new Storage.Entity.Item(),
                departments: [],
                selectedDepartment: null,
                isNew: true,
                originalBarcode: null,
                origInv: 0,
                invUpdated: false,
                bonPrinters: [],
                selectedBonPrinters: null
              }
              return dataVal;
            },
            beforeRouteEnter: async function(to, from, next) {
              app.showLoadingMessage(i18next.t('loading'));
              let item = new Storage.Entity.Item();
              let isNew = true;
              if (to.params.code != null) {
                isNew = false;
                item = await appDB.localItems.where('code').equals(to.params.code).first();
                item = PositiveTS.Storage.Entity.Item.convertToVueObject(item);
              }
              let departments = await appDB.departments.orderBy('[sortOrder+name]').toArray();
              let logicalBonPrinters = await Storage.Entity.LogicalPrinter.getCache()
              next(vm => {
                let self = <ComponentData>vm;
                self.item = item;
                self.originalBarcode = item.barcode;
                self.departments = departments;
                self.bonPrinters = logicalBonPrinters;
                self.isNew = isNew;
                self.origInv = item.currentInventory;
                if (self.item.departmentId != 0) {
                  self.selectedDepartment = self.departments.filter(dep => dep.id == self.item.departmentId)[0]
                }
                if (self.item.logicalOrGroupPrinters && self.item.logicalOrGroupPrinters != "[]") {
                  let selectedBonPrintersArr:any = []
                  let itemBonPrinters = self.item.logicalOrGroupPrinters.replace('[','').replace(']','').split(",")
                  for (let bonPrinter of self.bonPrinters) {
                    if(itemBonPrinters.includes(`'${bonPrinter.logicalOrGroupPrinterId}'`)){
                      selectedBonPrintersArr.push(bonPrinter)
                    }
                  }
                  self.selectedBonPrinters = selectedBonPrintersArr

                }
                app.hideLoadingMessage();
              });
            },
          }
        }
        
      }
    }
  }
  