module PositiveTS {
export module Service {
export module ChargeableCardSelectDialog {
    var _itemList = []

    export function open(itemList:any[], callback:Function) {

        if (itemList.length === 1) {
            callback(itemList[0].code);
        }
        else {
            _itemList = itemList;
            _initChargeableCardTable();

            $('#ChargeableCard-dialog-continue').click(function () {
              var selectedOption = _getSelectedChargeableCard();

              if ( _getSelectedChargeableCard() !== undefined ) {
                _close();
                callback( _itemList[selectedOption].code );
              }

            });

            $('#ChargeableCard-dialog-cancel').click(function () {
                _close();
                throw new Error("Action Canceled");
            });

            $('#dialog-overlay2').show();
            $('#ChargeableCard-dialog').show();
        }

    }


    // --------------------------------------------------------------------
    // Logic Related Functions
    // --------------------------------------------------------------------
    function _getSelectedChargeableCard () {
        var tableView = $('#ChargeableCard-dialog-tableview').tableview();
        return tableView.getSelectedRow().data('data');
    }

    function _initChargeableCardTable() {
        var tableView = $('#ChargeableCard-dialog-tableview').tableview();

        // Empty the table so we can be sure we are displaying only the latest results
        tableView.empty();

        var itemList = _itemList;
        for (var i = 0; i < itemList.length; i++) {
            // Add a row with the z report data
            var theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
                itemList[i].description
            ]));
            theRow.data('data', i);
        }
    }

    function _close() {
        $('#ChargeableCard-dialog-cancel').unbind('click');
        $('#ChargeableCard-dialog-continue').unbind('click');
        $('#ChargeableCard-dialog').hide();
        $('#dialog-overlay2').hide();
    }

}}}



