module PositiveTS {
export module Storage {
export module Entity {
export class DuplicateBarcode extends WasmEntity {

	constructor() {
		let meta = {
			name: 'DuplicateBarcode',
			fields: {
				code: "TEXT",
				barcode: "TEXT"
			}
		};
		super(meta)
	}

	openDuplicateDialogIfNeeded (code):Promise<any> {
		let aThis = this;
		return new Promise((resolve,reject) => {
			aThis.getDuplicateCodes(code)
			.then(codes => {
				if (codes.length < 2) {
					if (codes.length === 0) {
						codes = [code]
					}
					PositiveTS.Service.WasmDB.promiseSql(`select * from item where code='${codes[0]}'`)
					.then(result => {
						if (result.length === 0) {
							resolve(null)
							return;
						}

						let entityItem = new PositiveTS.Storage.Entity.Item();
						entityItem.importFromObject(result[0]);
						resolve(entityItem)
					})

				}
				else {
					Pinia.componentsStore.openComponent( {componentName:"duplicateBarcodeDialog", args: [codes]})
					.then((item) => {
						resolve(item === null ? app.userCancelledGlobalMessage : item)
					})
					.catch((err) => {
						console.debug(app.userCancelledGlobalMessage)
						reject(err)
					})
				}
			})
		})

	};

	getDuplicateCodes (code):Promise<Array<any>> {
		return new Promise((resolve,reject) => {

			let promise1 = PositiveTS.Service.WasmDB.promiseSql(`select code from DuplicateBarcode where barcode='${code}'`)
			let promise2 = PositiveTS.Service.WasmDB.promiseSql(`select distinct code from itembarcode where code='${code}' or barcode='${code}'`)
			Promise.all([promise1,promise2])
			.then(results => {
				let itemCodes = [];

				for (let i = 0; i < results[0].length; i++) {
					itemCodes.push(results[0][i].code)
				}

				for (let i = 0; i < results[1].length; i++) {
					itemCodes.push(results[1][i].code)
				}
				resolve(_.uniq(itemCodes))

			})
		})

	}
}}}}
