module PositiveTS {
  export module Components {
    export module SimplyClubBenefitsDialog {
      const dialogSelector = 'simplyclub-benefits-dialog';

      export function create() {

        let simplyClubBenefitsDialog = {
          mixins: [Mixins.oneClickMixin, Mixins.scrollableMixin],
          components: { scrollable: PositiveTS.Components.Scrollable.getComponent() },
          template: JST.simplyClubBenefitsDialog(),
          data() {
            return {
              sale: null,
              saleItems: [],
              loading: false,
              ready: false,
              resolveFunc: null,
              resolveResult: {
                isToContinue: true,
              },
              selectedBenefits: [],
              autoBenefits: [],
              subTotal: null
            }
          },
          computed: {
            customer() {
              return PositiveTS.Service.SimplyClubService.getCustomerOnSale(this.sale);
            },
            originalSaleTotal() {
              let total = 0;
              if (!this.subTotal) return total;
              this.saleItems.forEach((saleItem: PositiveTS.Storage.Entity.SaleItem) => total += saleItem.priceNetoAfterDiscounts);
              return total.toFixed(2);;
            },
            overallBenefitsUsed() {
              let total = 0;
              if (!this.subTotal) return total;
              for (const bene of this.subTotal.CashBackDiscounts) {
                if (typeof (bene.userField) === 'boolean') {
                  total += bene.userField ? (bene.Amount * bene.ItemQty) : 0;
                } else {
                  total += bene.userField * bene.ItemQty;
                }
              }
              for (const bene of this.subTotal.TotalDiscounts) {
                total += bene.userField ? bene.Amount : 0;
              }
              return total.toFixed(2);;
            },
            totalOverall() {
              return (this.originalSaleTotal - this.overallBenefitsUsed).toFixed(2);;
            }
          },
          methods: {
            open(sale: PositiveTS.Storage.Entity.Sale, saleItems: PositiveTS.Storage.Entity.SaleItem[]) {
              this.sale = sale;
              this.saleItems = saleItems;
              this.$el.showModal();
              $(document).unbind('keypress');
              this.ready = true;
              this.subTotal = PositiveTS.Service.SimplyClubService.getSubTotalOnSale(this.sale);
              return new Promise(resolve => { this.resolveFunc = resolve });
            },
            close() {
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();
              this.ready = false;
              this.resolveFunc(this.resolveResult);
            },
            async continueWithoutBenefits() {
              for (const bene of this.subTotal.CashBackDiscounts) {
                bene.userField = typeof (bene.userField) === "boolean" ? false : 0;
              }
              for (const bene of this.subTotal.TotalDiscounts) {
                bene.userField = false;
              }
              await this.validateAndUpdateSale();
            },
            cancel() {
              this.resolveResult.isToContinue = false;
              this.close();
            },
            selectInput(field) {
              this.$refs[field].focus();
            },
            showError(errStr) {
              app.showErrorAlertDialog(errStr);
            },
            showAlert(msg: string, header = i18next.t("note")) {
              app.showAlertDialog({
                header: i18next.t("note"), continueButtonText: i18next.t("ok"),
                content: msg,
                hideCancelButton: true, noHandleEnterEscape: true,
              });
            },
            showLoading(msg: string) {
              app.showLoadingMessageDialog(msg);
            },
            isDiscount(benefit: PositiveTS.Service.SimplyClubAPI.BaseBenefit): boolean {
              return benefit.ObjectType == PositiveTS.Service.SimplyClubAPI.BenefitObjectType.DISCOUNT;
            },
            isCashBack(benefit: PositiveTS.Service.SimplyClubAPI.BaseBenefit): boolean {
              return benefit.ObjectType == PositiveTS.Service.SimplyClubAPI.BenefitObjectType.CASHBACK;
            },
            overallCalc(benefit: PositiveTS.Service.SimplyClubAPI.BaseBenefit): number {
              if (typeof (benefit.userField) === "boolean") {
                return benefit.userField ? benefit.Amount : 0;
              } else {
                return benefit.userField;
              }
            },
            beneChanged(bene) {
              bene.userField = Math.max(0, bene.userField);
              bene.userField = Math.min(bene.userField, bene.Amount);
              bene.userField = posUtils.ensureDecimalPointsOn2DigitsOnly(bene.userField);
            },
            async validateAndUpdateSale() {
              if (this.totalOverall < 0) {
                this.showAlert(i18next.t('simplyClubBenefits.benefitsPointsCannotBeAbovePrice'));
                return;
              }
              this.showLoading(i18next.t('loading'));
              try {
                let res = await PositiveTS.Service.SimplyClubService.validateAndSetSaleForTranEnd(this.subTotal, this.sale);
                if (res.ErrorCode !== 0) {
                  this.showError(res.ErrorMessage || i18next.t("error"));
                  return;
                } // Else... 
                this.resolveResult.isToContinue = true;
                this.close();
              } catch (err) {
                console.log(err)
                this.showError(err.message);
                this.loading = false;
              }
              finally {
                app.hideLoadingMessageDialog();
              }

            },
          },
        }

        VueApp.component(dialogSelector, simplyClubBenefitsDialog)

      }
    }
  }
}
