module PositiveTS {
    export module Components {
        export module SelfServiceCreditPaymentMethod {
            export function getComponent() {
              return {
                template: JST.selfServiceCreditPaymentMethod(),
                mixins: [Mixins.wizardItemMixin],
                setup(){
                  const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
                  const {setCurrentStep,setCreditCardPaymentsData} = selfServicePaymentStore;
                  return {setCreditCardPaymentsData,setCurrentStep}
                },
                methods: {
                  goToPayment(){
                    this.setCurrentStep(this.pageToGo)
                  },

                  async openKeyPad(paymentType:string){
                    let numberOfPayments = await Pinia.componentsStore.openComponent( {componentName:"keyPadPaymentsDialog", args: []})
                      if(numberOfPayments.approved){
                        this.setCreditCardPaymentsData({
                          numberOfPayments: numberOfPayments.payments,
                          paymentType: paymentType
                        }) 
                        this.setCurrentStep(this.pageToGo)
                      }else {
                        this.goBack()
                      }
                 
                  }
                },
                data:function(){
                  return{
                    paymentsNumber: 1,
                    paymentType: 1,
                    pageToGo: session.pos.isEmv ? "SelfServiceEmv" : "SelfServiceAshrait"
                  }
                },
              }
            }
    }
  }
}