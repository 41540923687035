module PositiveTS {
export module Storage {
export module Entity {
export class Migvan extends WasmEntity {

	color: string;
	name: string;
	pictureUrl: string;
	pictureBase64: string;

	constructor() {
		let meta = {
			name: 'Migvans',
			fields: {
				color: "TEXT",
				name: "TEXT",
				pictureUrl: "TEXT",
				pictureBase64: "TEXT",
			},
		}
		super(meta)
	}
}}}}
