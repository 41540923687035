module PositiveTS {
    export module Storage {
        export module Entity {
            export class VendingMachine extends WasmEntity {

                constructor() {
                    let meta = {
                        name: 'VendingMachine',
                        fields: {
                            machineNumber: "INT",
                            nickname: "TEXT",
                            companyId: "INT",
                            storeId: "INT",
                            posId: "INT",
                            posDeviceId: "TEXT",
                            port: "TEXT"
                        },
                        unique: []
                    }
                    super(meta)
                }


            }
        }
    }
}
