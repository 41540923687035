module PositiveTS {
    export module Components {
    export module SwipeCardDialog {
    
      const _vueComponentName = 'swipe-card-dialog';
    
      export function create() {
        let swipeCardDialog = {
            template: JST.swipeCardDialog(),
            methods: {    
                getSwipedCard() {
                    return new Promise((resolve, reject) => {
                        this.promiseResolve = resolve;
                        this.promiseReject = reject;
                        this.open();
                    })
                },
                cancel() {
                    this.promiseResolve(null);
                    this.close();
                },
                returnCard() {
                    if(posUtils.isBlank(this.cardNumberText)) {
                        app.showAlertDialog({
                            header: i18next.t('error'),
                            content: i18next.t('swipeCardDialog.noCardError'),
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true
                        });

                        return;
                    }

                    this.promiseResolve(this.cardNumberText);
                    this.close()
                },
                async swipeCard() {
                    app.showLoadingMessage(i18next.t("swipeCardDialog.swipe"));
                    let cardNumber = await Service.EMV.swipe(this.amount);
                    if(posUtils.isDefined(cardNumber)){
                      this.cardNumberText = cardNumber;
                      this.returnCard();
                    }
                    app.hideLoadingMessage();
                },
                open(){
                    this.$el.showModal();
                    $(document).unbind('keypress');
                },
                close() {
                    this.$el.close();
                    $(document).unbind('keypress');
                    $(document).keypress(posVC.onKeyPress);
                    this.cardNumberText = null;
                },
                posUseSwipe() {
                    return (jsonConfig.getVal( jsonConfig.KEYS.usePinPadSwipe )) && (session.pos.isEmv)
                }
            },
            data() {
              return {
                promiseResolve: null,
                promiseReject: null,
                cardNumberText: null,
              }
            },
            computed: {
            }
        }
    
        VueApp.component(_vueComponentName,swipeCardDialog)
      }
    }}}
    
