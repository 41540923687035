module PositiveTS {
  export module Service {
    export module Brill {
      export function isBrillClub(clubName: string): boolean {
        return clubName == 'bril';
      }

      export function getCustomerGroupKey(customer):string {
        return String(customer.class_code) + '_' + (posUtils.isBlank(customer.migvan) ? '0' : String(customer.migvan));
      }

      export function saleHasCustomerGroupPromotion(saleJsondata, customerKey): boolean {
        let customerGroup = session.customerGroupsByName[customerKey];

        if (posUtils.isBlank(customerGroup)) {
          return false;
        }

        let salePromotionsData = saleJsondata.promotions;


        let promotionCodes =  posUtils.isBlank(salePromotionsData) ? [] : _.uniq(salePromotionsData.map(p => p.promotionCode));

        if (!posUtils.isBlank(saleJsondata.salePromotion) && !posUtils.isBlank(saleJsondata.salePromotion.code)) {
          promotionCodes.push(saleJsondata.salePromotion.code);
        }

        if (promotionCodes.length == 0) {
          return false;
        }

        for (let currentPromotion of session.allPromotions) {
          if (promotionCodes.includes(currentPromotion.code) && currentPromotion.customerGroupId == customerGroup.code) {
            return true;
          }
        }

        return false;
      }
    }
  }
}
