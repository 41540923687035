module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div v-if="initialzied && isInner && isTableViewMobile" :class="dialogClass" :style="{ width: dialogWidth }"\n	class="p2-pro:h-auto p2-pro:max-h-unset p2-pro:max-w-unset">\n	<template v-if="isTableViewMobile">\n		' +
((__t = ( JST.itemWithGroupsDialogDalpakTableViewMobile() )) == null ? '' : __t) +
'\n	</template>\n</div>\n<dialog v-else-if="initialzied" id="item-with-groups-dialog-wrapper" :class="dialogClass" :style="{ width: dialogWidth }"\n	class="p2-pro:h-auto p2-pro:max-h-unset p2-pro:max-w-unset">\n	<template v-if="isTableViewMobile">\n		' +
((__t = ( JST.itemWithGroupsDialogDalpakTableViewMobile() )) == null ? '' : __t) +
'\n	</template>\n\n	<template v-else-if="showSelfServiceAndDalpakTableView">\n		' +
((__t = ( JST.itemWithGroupsDialogSelfService() )) == null ? '' : __t) +
'\n	</template>\n\n	<template v-else>\n		' +
((__t = ( JST.itemWithGroupsDialogRegular() )) == null ? '' : __t) +
'\n	</template>\n</dialog>\n';

}
return __p
}