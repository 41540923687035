module PositiveTS {
    export module Service {
        export module Addresses {

            export async  function getCities() { 
                let cities = [];
                if( Pinia.globalStore.cities.length == 0){
                    let SQL = "select * from cities";
                    let result = await PositiveTS.Service.WasmDB.promiseSql(SQL);

                    for(let row of result){
                        cities.push({
                            name: row.city_name,
                            value: row.city_id,
                            cityID: row.city_id
                        })
                    }
                    Pinia.globalStore.setCities(cities)
                }
                else{
                    cities = Pinia.globalStore.cities
                }

                return cities;
            }

            export async  function getStreets() {
                let streets = [];

                if( Pinia.globalStore.streets.length == 0){
                    let SQL = "select * from streets";
                    let result = await PositiveTS.Service.WasmDB.promiseSql(SQL);
    
                    for(let row of result){
                        streets.push({
                            name: row.street_name,
                            value: row.street_id,
                            cityID: row.city_id
                        })
                    }
                    Pinia.globalStore.setStreets(streets)
                }
                else{
                    streets = Pinia.globalStore.streets
                }


                return streets;
            }
        }

    }
}
