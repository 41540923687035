//hakafaSpecialItemValidation
//
module PositiveTS {
export module Service {
export module Hakafa {
export module HakafaSpecialItemValidation {
  const HANDLER = "HAKAFA";

  export function getHakafaSpecialItem(specialItems):SpecialItem {
    return specialItems.filter((item) => {return item.hndlr == HANDLER;})[0]
  }

  export function isItemValid(saleItem:any,specialItems:Array<SpecialItem>, itemAmount?: number) {
    var _ret = {valid:true, msg:""};
    let saleItems = posVC.saleItems;
    let foundItemArr;
    let counter = 0
    itemAmount = itemAmount|| saleItem.unitPrice;

    let isItemSpecial = (specialItems.filter( (specialItem) => {
      return (saleItem.itemCode === specialItem.code && specialItem.hndlr == HANDLER)
    }).length > 0);


    if (isItemSpecial) {
      if ((saleItems.length === 1 && saleItems[0].id !== saleItem.id) || saleItems.length > 1) {
        _ret.valid = false;
        _ret.msg = "HAKAFA_ITEM_MOST_BE_ONLY_ITEM"
        return _ret;
      }

      if ( !PositiveTS.Service.Hakafa.hasCustomer()){
        _ret.valid = false;
        _ret.msg = "HAKAFA_ITEM_WITH_NO_CUSTOMER"
        return _ret;
      }

      // if ( itemAmount >  _maxPriceAllowed() ) {
      //   _ret.valid = false;
      //   _ret.msg = "HAKAFA_PRICE_GT_DEBIT_AMNT"
      //   return _ret;
      // }

      if (saleItem.quantity !== 1) {
        _ret.valid = false;
        _ret.msg = "QTY_GT_ONE"
        return _ret;
      }
    }
    else {
      let hakafaSpecialItem = getHakafaSpecialItem(specialItems);
      if (hakafaSpecialItem) {
        let result = saleItems.filter((item) => {return item.itemCode == hakafaSpecialItem.code;})
        if (result.length > 0) {
          _ret.valid = false;
          _ret.msg = "HAKAFA_ITEM_MOST_BE_ONLY_ITEM"
          return _ret;
        }
      }
    }

    return _ret;
  }

  function _maxPriceAllowed(){
    return Number(PositiveTS.Service.Hakafa.getJsondata()["HakafaSetCustomer-amount"]) * - 1;
  }

}}}}
