/*
Example:
Service.CheckTypes.check([
  {type:'int', param: 12, errorMsg: "shalom", minLen: 3},
  ]);

checkTypes([
  {type:'int', param: actionType},
  {type:'number', param: amount},
  {type:'int', param: timestamp},
  ]);
*/

module PositiveTS {
  export module Service {
  export module CheckTypes {

    export function exceptionCheck(ary){
      for (let i=0; i<ary.length; i++ ){
        checkItem(ary[i]);
      }
    };
  
    export function check(ary){
      let _return= {isValid:true, message:""};
      try {
          exceptionCheck(ary);
      }
      catch(err) {
          _return.message = err.message;
          _return.isValid = false;
      }
      return _return;
    };
  
    function checkItem(item){
      let nullable = item.nullable;
      let param = item.param;
  
      if (item.len) {
        item.minLen = item.len;
        item.maxLen = item.len;
      }
  
      if (!nullable && typeof(param) !== "undefined") {
        validateHasEnum(item, param);
        validateLen(item, param);
      }
  
  
  
      switch (item.type) {
        case "string":
          tString(item, param, nullable);
          break;
        case "int":
          tInt(item, param, nullable);
          break;
        case "number":
          tNumber(item, param, nullable);
          break;
        case "array":
          tArray(item, param, nullable);
          break;
        case "function":
          tFunction(item, param, nullable);
          break;
        case "object":
          tObject(item, param, item.name,nullable);
          break;
        case "boolean":
          tBoolean(item, param ,nullable);
          break;
        default:
          throwError(item, "Could not validate " + item.type);
      }
    };
  
    function throwError(item, defaultString) {
      if (item.errorMsg) {
        throw new Error(item.errorMsg);
      } else {
        throw new Error(defaultString);
      }
    };
  
    function validateHasEnum(item, param) {
      if (item.hasEnum) {
        let itemInEnum = Object.keys(item.hasEnum).map(function(key){
              return item.hasEnum[key];
          }).indexOf(param);
  
        if (itemInEnum === -1) {
          throwError(item, "Valu must exists in Enum");
        }
      }
    };
  
    function validateLen(item, param) {
      if (item.minLen) {
        if (param.toString().length < item.minLen) {
          throwError(item, "Valu len too small");
        }
      }
  
      if (item.maxLen) {
        if (param.toString().length > item.maxLen) {
          throwError(item, "Valu len too big");
        }
      }
  
    };
  
    function tString(item, str, nullable){
      if (nullable && !str) {
        return str;
      }
  
      if ( typeof(str) !== "string"){
        throwError(item, "String expected");
      }
  
      return str;
    };
  
    function tBoolean(item, param, nullable){
      if (nullable && !param) {
        return param;
      }
  
      if ( typeof(param) !== "boolean"){
        throwError(item, "Boolean expected");
      }
  
      return param;
    };
  
    function tInt(item, number, nullable){
      if (nullable && !number) {
        return number;
      }
  
      if (number !== parseInt(number)) {
        throwError(item, "Integer expected");
      }
  
      return number;
    };
  
    function tNumber(item, number, nullable) {
      if (nullable && !number) {
        return number;
      }
  
      if (typeof number !== 'number' || !isFinite(number)) {
        throwError(item, "Number expected");
      }
  
      return number;
    };
  
    function tFunction(item, fc, nullable) {
      if (nullable && !fc) {
        return fc;
      }
  
      if (typeof fc !== 'function') {
        throwError(item, "Function expected");
      }
  
      return fc;
    };
  
    function tArray(item, array, nullable) {
      if (nullable && !array) {
        return array;
      }
  
      if ( !Array.isArray(array)){
        throwError(item, "Array expected");
      }
  
      return array;
    };
  
    function tObject(item, obj, name, nullable) {
      if (nullable && !obj) {
        return obj;
      }
  
      // get name from entity if not standart js obj
      let objName = obj.constructor.name;
      if (!objName && obj.meta) {
        objName = obj.meta.name;
      }
  
      if ( typeof(obj) === "object"){
        if (name && objName !== name ) {
          throwError(item, name + " object expected");
        }
      } else {
        throwError(item, "Generic object expected");
      }
  
      return obj;
    };
  }
}}

