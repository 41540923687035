module PositiveTS {
  export module UI {
    export class AutoComplete {
      public selectedItem = {};
      private dataArray;
      private selectorField;
      private onChange:Function;
      constructor(parameters) {
        this.dataArray = parameters.dataArray;
        this.selectorField = parameters.selectorField;
        this.onChange = parameters.onChange;
        this.init();
      }
      // dictionary of [{value:"asdf", label:"safd"}]
      
      
      private init() {
        $(this.selectorField).autocomplete({
          minLength: 0,
          source: this.dataArray,
          focus: function( event, ui ) {
            $( this.selectorField ).val( ui.item.label );
            return false;
          },
          select: function( event, ui ) {
            this.selectedItem = ui.item;
            this.onChange();
            return false;
          },
          change: function( event, ui ) {
            if ( !ui.item ) {
                        
            var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()) + "$", "i" ), valid = false;
          
            for (var i=0;i < this.dataArray.length; i++){
              if (this.dataArray[i].label.match(matcher)) {
                  this.selected = valid = true;
                  this.selectedItem = this.dataArray[i];
                  this.onChange();
                  return false;
              }
            }
          
              if (!valid) {
                // remove invalid value, as it didn't match anything
                $(this).val("");
                $(this).data("autocomplete").term = "";
                this.selectedItem = undefined;
                this.onChange();
                return false;
              }
            }
          }//END CHANGE
        })
        .data( "ui-autocomplete" )._renderItem = function( ul, item ) {
          return $( "<li>" )
            .append( "<a>" + item.label + "<br>" + item.desc + "</a>" )
            .appendTo( ul );
        };
        $( this.selectorField ).data("autocomplete")._resizeMenu = function () {
                var ul = this.menu.element;
                ul.outerWidth(this.element.outerWidth());
        }
      };

    }
  }
}
