module PositiveTS {
export module Service {
  export function getItemQuestion(saleItem){
    let validIds =  _validApprovalIds()
    var dialogParameters = _getDialogParameters(saleItem, validIds);
    return inputDg.open(dialogParameters)
  }


  function _getDialogParameters(saleItem, validIds){
    return {
      header: 'אישור מוכר',
      description: `המידה שנקלטה היא ${saleItem.size}. בדוק שהשמאלית והימינית תואמות והזן מספר מוכר לאישור.`,
      inputPlaceHolder: 'אנא הזן קוד עובד או כרטיס',
      showCancelButton: true,
      emptyErrorMessage: 'חובה להזין קוד עובד',
      inputValidator:  (value) => {
        if ( validIds.indexOf(value) === -1) {
          inputDg.optionsIn.emptyErrorMessage = 'מספר/קוד מוכר לא קיים';
          return false;
        }

        return true;
      }
    };
  }




  function _validApprovalIds(){
    return PositiveTS.Service.WasmDB.execAsObject("select employeeid from employee union select magneticcard from employee").map(e => e.employeeID);
  }
}}
