module PositiveTS {
export module Storage {
export module Entity {
export class SaleItem extends IDBEntity {
	quantity: number;
	rowNumber: number;
	unitPrice: number;
	originalUnitPrice:number;
	barcode: string;
	itemCode: string;
	itemDescription: string;
	saleID: string;
	addTimestamp;
	salespersonEmployeeID;
	salespersonEmployeeName: string;
	parentSaleInvoiceSequence: number;
	discountApprovedByEmployeeID: number;
	discountID:string;
	discountPercent;
	discountAmount;
	discountType:string;
	discountName:string;
	size: string;
	color: string;
	isPickup;
	bonPrintedAt: string;
	invoiceSequence: number;
	priceListId: number;
	noVat: boolean;
	alwaysHasVat: boolean;
	hasWeight: boolean;
	isAddition: boolean;
	isAbstract: boolean;
	hasGroups: boolean;
	hasChanges: boolean;
	hasUpdated: boolean;
	itemBeforeChanges: SaleItem | {};
	isPresent: boolean;
	originalItemId: string;
	buyPromotionCode;
	getPromotionCode;
	originalQuantity;
	parentItemId:number;
	parentRowNumber:number;
	itemGroupId:number;
	noDiscount:boolean;
	hasPreparationInstructions:boolean
	selectedPreparationInstructions:string
	selectedPreparationInstructionsTranslations:string
	priceBeforeCompensation:number
	priceBeforeChangedToMin:number
	hasCompensation:boolean
	level:number; //the depth of the item (0 - root, 1 - child, 2 - grandchild (tosefet of ben))
	children: Array<SaleItem>
	realQuantity:number // used to help with promotion calculations... not persisted to DB
	salePercentAddition:number;
	reduceQuantity:number;
	selectedPackagesById:string;
	promotions:string;
	multipassDiscount:number;
	kasparDiscount:number;
	priceNetoAfterDiscounts:number;
	isDeposit:boolean;
	packageDiscount:number;
	quantityInUnits:boolean;
	timeItemVersion:number;
	startTimestamp:number;
	endTimestamp:number;
	priceAlut:number;
	elalTicketNumber:String;
	elalTicketType:String;
	elalRecId:String;
	elalServiceCD:String;
	elalPNR:String;
	isSplitSalePayment:boolean;
	disablePromotions: boolean;
	private itemRef:Item

	_parent:SaleItem|null;

	get item() {
		if (this.itemRef == null && session.allItems.get(this.itemCode)) {
			this.itemRef = (new Item()).importFromObject(session.allItems.get(this.itemCode));
		}
		return this.itemRef;
	}

	get quantityUnit() {
		if(this.item.hasWeight && !this.quantityInUnits) {
			return i18next.t('itemUnits.kilo');
		}
		return i18next.t('itemUnits.unit');
	}

	set item(value) {
		this.itemRef = (value instanceof Item) ? value : (new Item()).importFromObject(value);
	}

	get isPaidAddition():boolean {
		return this.itemGroupId == -1;
	}

	get isPNRItem(): boolean {
		return Boolean(this.elalRecId && this.elalPNR);
	}

	exportToObject() {
		let obj: any = super.exportToObject();
		if (posUtils.isPresent(this.children)) {
			obj.children = this.children.map(child => child.exportToObject());
		}
		return obj;
	}
	importFromObject(importFrom) {
		super.importFromObject(importFrom);
		if (posUtils.isPresent(importFrom.children)) {
			this.children = importFrom.children.map(child => {
				let childItem = new SaleItem();
				return childItem.importFromObject(child);
			});
		}
		return this;
	}

	constructor() {
		let meta = {
			name: 'SaleItem',
			internal: true,
			fields: {
				saleID: "TEXT",
				rowNumber: "INT",
				itemCode: "TEXT",
				itemDescription: "TEXT",
				size: "TEXT",
				color: "TEXT",
				elalTicketNumber:"TEXT",
				elalTicketType:"TEXT",
				elalRecId:"TEXT",
				elalServiceCD:"TEXT",
				elalPNR:"TEXT",
				quantity: "FLOAT",
				unitPrice: "FLOAT",
				isPresent: "BOOL",
				isPickup: "BOOL",
				bonPrintedAt: "TEXT",
				addTimestamp: "TEXT",
				discountID: "TEXT",
				discountName: "TEXT",
				discountPercent: "TEXT",
				discountAmount: "TEXT",
				discountApprovedByEmployeeID: "TEXT",
				discountType: "TEXT",
				salespersonEmployeeID: "TEXT",
				salespersonEmployeeName: "TEXT",
				// item: "JSON",
				buyPromotionCode: "TEXT",
				getPromotionCode: "TEXT",
				originalUnitPrice: "FLOAT",
				invoiceSequence: "INT",
				barcode: "TEXT",
				originalItemId: "TEXT",
				priceListId: "INT",
				noVat: "BOOL",
				alwaysHasVat: "BOOL",
				hasWeight: "BOOL",
				hasChanges: "BOOL",
				hasUpdated: "BOOL",
				itemBeforeChanges: "JSON",
				isAbstract: "BOOL",
				isAddition: "BOOL",
				parentItemId: "INT",
				parentRowNumber: "INT",
				itemGroupId: "INT",
				level: "INT",
				hasGroups: "BOOL",
				noDiscount: "BOOL",
				salePercentAddition: "FLOAT",
				hasPreparationInstructions: "BOOL",
				selectedPreparationInstructions: "TEXT",
				selectedPreparationInstructionsTranslations: "TEXT",
				reduceQuantity: "INT",
				selectedPackagesById: "TEXT",
				priceBeforeCompensation:"FLOAT",
				priceBeforeChangedToMin: "FLOAT",
				hasCompensation:"BOOL",
				promotions: "TEXT",
				multipassDiscount:"FLOAT",
				kasparDiscount:"FLOAT",
				priceNetoAfterDiscounts:"FLOAT",
				isDeposit: "BOOL",
				quantityInUnits: "BOOL",
				timeItemVersion: "INT",
				startTimestamp: "INT",
				endTimestamp: "INT",
				priceAlut:"FLOAT",
				isSplitSalePayment:"BOOL",
				disablePromotions:"BOOL"
			},
			// unique: ['saleID','itemCode','size','color'],
			money: ['unitPrice', 'discountAmount', 'originalUnitPrice']
		}
		super(meta)
	}

	static DiscountType			= {
		NULL: null,
		PERCENT: '1',
		AMOUNT: '2',
		BARCODE_AMOUNT: '3',
		BARCODE_PCT: '4'
	};

	static import(item) {
		let itemModel = new SaleItem();
		return itemModel.importFromObject(item);
	}

	similarItemUnsigned  (otherSaleItem:SaleItem) {
		var isSimilar = true;
		isSimilar = isSimilar && (this.itemCode == otherSaleItem.itemCode);
		isSimilar = isSimilar && (this.size == otherSaleItem.size);
		isSimilar = isSimilar && (this.color == otherSaleItem.color);
		isSimilar = isSimilar && (this.bonPrintedAt == otherSaleItem.bonPrintedAt);

		isSimilar = isSimilar && (this.rowNumber == otherSaleItem.rowNumber)

		return isSimilar;
	};

	similarItem  (otherSaleItem, isLastItem) {
		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.separateAllLinesAlways))) {
			return false;
		}

		if ( otherSaleItem.item.isPunchCard ) {return false;}

		let isSimilar = true;
		let srcHasWeight = this.hasWeight;
		let otherHasWeight = otherSaleItem.hasWeight;
		isSimilar = isSimilar && (this.itemCode == otherSaleItem.itemCode);
		isSimilar = isSimilar && (this.size == otherSaleItem.size);
		isSimilar = isSimilar && (this.color == otherSaleItem.color);
		isSimilar = isSimilar && (this.isPickup == otherSaleItem.isPickup);
		isSimilar = isSimilar && (this.bonPrintedAt == otherSaleItem.bonPrintedAt);
		// other and current quantity have bouth negative or positive  sign
		isSimilar = isSimilar && ( (this.quantity * otherSaleItem.quantity) > 0 );
		isSimilar = isSimilar && (!srcHasWeight && !otherHasWeight || jsonConfig.getVal(jsonConfig.KEYS.dontSeparateWeightLines));
		// Is it newlly added item with no price
		let originalUnitPriceNotZero = (otherSaleItem.originalUnitPrice !== 0);
		let isNotAlreadyAddedWithDifferentId = otherSaleItem.originalUnitPrice === 0 && otherSaleItem.id !== this.id;
		let forceNotSimilar = session.pos.separateItemLinesOn && otherSaleItem.id !== this.id && !isLastItem;
		isSimilar = isSimilar && originalUnitPriceNotZero && !isNotAlreadyAddedWithDifferentId && !forceNotSimilar;
		isSimilar = isSimilar && otherSaleItem.selectedPreparationInstructions == this.selectedPreparationInstructions;

		if(session.pos.isRoshemet){
			let notGenericItem = (otherSaleItem.itemCode != Shared.Constants.Item.GENERIC_ITEM);
			isSimilar = isSimilar && notGenericItem;
		}

		return isSimilar;
	};

	similar  (otherSaleItem) {
		let isSimilar = true;
		let srcHasWeight = this.hasWeight;
		let otherHasWeight = otherSaleItem.hasWeight;
		isSimilar = isSimilar && (!srcHasWeight && !otherHasWeight || jsonConfig.getVal(jsonConfig.KEYS.dontSeparateWeightLines)) ;
		isSimilar = isSimilar && (this.itemCode == otherSaleItem.itemCode);
		isSimilar = isSimilar && (this.size == otherSaleItem.size);
		isSimilar = isSimilar && (this.color == otherSaleItem.color);
		isSimilar = isSimilar && (this.isPickup == otherSaleItem.isPickup);
		isSimilar = isSimilar && (this.bonPrintedAt == otherSaleItem.bonPrintedAt);
		isSimilar = isSimilar && (this.discountID == otherSaleItem.discountID);
		isSimilar = isSimilar && (this.discountPercent == otherSaleItem.discountPercent);
		isSimilar = isSimilar && ((this.discountAmount == otherSaleItem.discountAmount) || (this.discountAmount == -1 * otherSaleItem.discountAmount));
		isSimilar = isSimilar && (this.discountType == otherSaleItem.discountType);

		return isSimilar;
	};

	importFromItemAndBarcode (item:Item, itemBarcode) {
		this.saleID = '';
		this.rowNumber = -1;
		this.invoiceSequence = -1;
		this.itemCode = item.code;
		this.itemDescription = item.description;
		this.size = itemBarcode.size != 'null' ? itemBarcode.size : '' ;
		this.color = itemBarcode.color != 'null' ? itemBarcode.color : '';
		this.barcode = itemBarcode.barcode != 'null' ? itemBarcode.barcode : item.code;
		this.quantity = 1;
		if (session.store.containVat && session.store.tenantID == "9") {
			this.unitPrice = item.priceEilat;
			this.originalUnitPrice = item.priceEilat;
		} else {
			this.unitPrice = item.priceZarhan;
			this.originalUnitPrice = item.priceZarhan;
		}
		this.isPresent = false;
		this.isPickup = false;
		this.bonPrintedAt = "";
		this.addTimestamp = PositiveTS.DateUtils.fullFormat();
		this.discountID = '-1';
		this.discountName = '';
		this.discountPercent = -1;
		this.discountAmount = -1;
		this.discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL;
		this.discountApprovedByEmployeeID = -1;
		this.salespersonEmployeeID = -1;
		this.parentItemId = -1;
		this.parentRowNumber = -1;
		this.salespersonEmployeeName = '';
		this.hasWeight = item.hasWeight;
		this.isAbstract = item.isAbstract;
		this.isAddition = item.isAddition;
		this.hasGroups = item.hasGroups;
		this.noDiscount = item.noDiscount;
		this.hasPreparationInstructions = item.hasPreparationInstructions;
		this.noVat = Boolean(item.noVat);
		this.alwaysHasVat = Boolean(item.alwaysHasVat);
		this.priceAlut = item.priceAlut;
		this.item = item; //the JSON setter will stringify the object...

		return this;
	};

	async persist(tx? , ignoreValidations?) {
		return;
	}


	clone(cloneWithChildren = false) {
		let itemClone = super.clone()
		let item = this.item;

		if (this.item) {
			itemClone.item = item;
		}

		if (cloneWithChildren && this.children) {
			itemClone.children = this.children;
		}

		if (this.id != null) {
			itemClone.id = this.id;
			itemClone.timestamp = this.timestamp;
			itemClone._new = false;
		}
		return itemClone;
	}

	persistWithChildren(removePromotionsFromChild = false) {
		let counter = 1;
		this.unitPrice = this.originalUnitPrice;
		if (this.children == null || this.children.length == 0) {
			return;
		}

		for (let childItem of this.children) {
			childItem.saleID = this.saleID;
			childItem.rowNumber = (this.rowNumber+1)*10000 + counter;
			childItem.parentRowNumber = this.rowNumber;
			if (removePromotionsFromChild){
				childItem.promotions = null;
			}
			if (childItem.promotions == null){
				childItem.priceNetoAfterDiscounts = childItem.quantity * childItem.unitPrice;
			}
			this.unitPrice += childItem.priceNetoAfterDiscounts;

			if (childItem.children) {
				let innerCounter = 1;
				for (let granChildItem of childItem.children) {
					granChildItem.saleID = this.saleID;
					granChildItem.rowNumber = childItem.rowNumber + (1000*counter) + innerCounter - counter;
					granChildItem.parentRowNumber = childItem.rowNumber;
					if(this.item.disableItemGroupEditing) {
						this.unitPrice += granChildItem.quantity*granChildItem.unitPrice;
					} else {
						this.unitPrice += granChildItem.unitPrice;
					}
					innerCounter += 1;
				}
			}
			counter += 1;
		}
	}

	static flattenItems(saleItems:Array<SaleItem>) {
		let childItems = saleItems.filter(item => (item.children != null && item.children.length > 0))
			.map(item => item.children)
			.reduce((a,b) => a.concat(b),[])
		let grandChildren = childItems.filter(item => (item.children != null && item.children.length > 0)).map(item => item.children)
		if (grandChildren.length > 0) {
			childItems = childItems.concat(grandChildren.reduce((a,b) => a.concat(b),[]))
		}
		return saleItems.concat(childItems)
	}

	static setPackagePrices(packageItem:SaleItem) {
		if(packageItem.children && packageItem.children.length > 0) {
			//if the item is being changed and not bought or returned.
			if(!posUtils.isBlank(packageItem.parentSaleInvoiceSequence)) {
				return packageItem;
			}
			if(!packageItem.children[0].isDeposit && packageItem.children[0].item.disableItemGroupEditing) {
				let childrenOriginalPrice = packageItem.children.reduce((total, child) => total + child.item.priceZarhan * child.quantity, 0);
				let packagePrice = packageItem.originalUnitPrice;
				let discountAmount = childrenOriginalPrice - packagePrice;
				packageItem.packageDiscount = discountAmount/childrenOriginalPrice;
				let promotionsDiscountAmount = 0
				let promotionsDiscountPercent = 0;
				if(packageItem.priceNetoAfterDiscounts > 0) {
					promotionsDiscountAmount = (packageItem.unitPrice*Math.abs(packageItem.quantity)) - Math.abs(packageItem.priceNetoAfterDiscounts);
					promotionsDiscountPercent = promotionsDiscountAmount/(packageItem.unitPrice*Math.abs(packageItem.quantity))
				} else if (packageItem.priceNetoAfterDiscounts == 0) {
					promotionsDiscountAmount = packageItem.unitPrice*Math.abs(packageItem.quantity);
					promotionsDiscountPercent = 1;
				}

				packageItem.children.forEach((childItem, index) => {
					if(packageItem.quantity > 0) {
						let itemPriceForQuantity = childItem.originalUnitPrice * childItem.quantity;
						let relativeItemPriceInPackage = itemPriceForQuantity - (packageItem.packageDiscount*itemPriceForQuantity)
						packageItem.children[index].priceNetoAfterDiscounts = relativeItemPriceInPackage - (promotionsDiscountPercent*relativeItemPriceInPackage);
						packageItem.children[index].priceNetoAfterDiscounts = _.round(packageItem.children[index].priceNetoAfterDiscounts, 6);
					} else {
						let partOfTotalPrice = ((childItem.originalUnitPrice * childItem.quantity)/childrenOriginalPrice);
						packageItem.children[index].priceNetoAfterDiscounts = partOfTotalPrice * packageItem.priceNetoAfterDiscounts;
					}

					if(childItem.children && childItem.children[0].isDeposit) {
						childItem.children[0].priceNetoAfterDiscounts = this.getDepositPriceNetoAfterDiscounts(packageItem, childItem.children[0]);
						if(packageItem.quantity > 0) {
							childItem.priceNetoAfterDiscounts = _.round(childItem.priceNetoAfterDiscounts + childItem.children[0].priceNetoAfterDiscounts, 6);
						}
					}
				})
			}
			else if(packageItem.children[0].isDeposit && packageItem.children.length == 1) {
				packageItem.children[0].priceNetoAfterDiscounts = this.getDepositPriceNetoAfterDiscounts(packageItem, packageItem.children[0]);
			}
		}

		return packageItem;
	}

	static setPriceNetoAfterDiscountsForOldPromotionsItems(sale: Sale, saleItems: SaleItem[]): SaleItem[] {
		// This function is for *current sale ONLY*, if someone need to use it on past sale he will need to change this function and recheck it.
		let items = _.cloneDeep(saleItems)

		if(!session.pos.useNewPromotions) {
			let saleItemDiscountPct = session.fixedFloat(sale.discountPercent) < 0 ? 0 : (session.fixedFloat(sale.discountPercent) / 100)
			for (const item of items) {
				let itemDiscount = 0
				let itemTotalPrice = item.quantity * item.unitPrice

				if ((!posUtils.isBlank(item.getPromotionCode) || !posUtils.isBlank(item.buyPromotionCode)) && Number(item.discountAmount) > 0) {
					itemDiscount = Number(item.discountAmount)

					item.priceNetoAfterDiscounts = itemTotalPrice - itemDiscount
				} else {
					item.priceNetoAfterDiscounts  =  itemTotalPrice - (itemTotalPrice * saleItemDiscountPct)
				}
			}
		}

		return items
	}

	static getDepositPriceNetoAfterDiscounts(rootItem:SaleItem, depositItem:SaleItem) {
		let promotionsDiscountPercent = 0;
		let promotionsDiscountAmount = 0;
		let priceNetoBeforeDiscounts = depositItem.unitPrice*depositItem.quantity;
		//if the root item has promotions
		if(rootItem.priceNetoAfterDiscounts > 0 && rootItem.priceNetoAfterDiscounts != rootItem.unitPrice*rootItem.quantity) {
			promotionsDiscountAmount = (rootItem.unitPrice*rootItem.quantity) - rootItem.priceNetoAfterDiscounts;
			promotionsDiscountPercent = promotionsDiscountAmount/(rootItem.unitPrice*rootItem.quantity)
		}
		//if the root item is beign returned and has a mininum price
		if(rootItem.quantity < 0 && rootItem.item.minUnitPrice == rootItem.unitPrice) {
			let originalRootItemPrice = rootItem.item.priceZarhan + (depositItem.quantity * depositItem.unitPrice)
			promotionsDiscountAmount = originalRootItemPrice - rootItem.item.minUnitPrice;
			promotionsDiscountPercent = (promotionsDiscountAmount/rootItem.item.priceZarhan)
		}

		if (rootItem.quantity < 0 && priceNetoBeforeDiscounts > 0) {
			priceNetoBeforeDiscounts *= -1;
		}

		if (rootItem.priceNetoAfterDiscounts == 0) {
			promotionsDiscountPercent = 1;
		}

		return _.round(priceNetoBeforeDiscounts - (priceNetoBeforeDiscounts*promotionsDiscountPercent), 6)
	}


	static hashStringItemChildren(saleItems: Array<SaleItem>) {
		let itemsHashObj = {}

		saleItems.forEach(item => {
			let hashString = []

			if(item.children && item.children.length > 0) {
				hashString =  item.children.map(i => {
					return  `${i.itemCode}$$${i.hasPreparationInstructions ? i.selectedPreparationInstructions : null}###${i.children && i.children.length > 0 ? this.hashStringItemGrandchild(i.children) : null }`
				})
			}

			itemsHashObj[item.rowNumber] = hashString
		})

		return itemsHashObj
	}

	static hashStringItemGrandchild(childrenItems: Array<SaleItem>) {
		let stringToReturn = ''
		childrenItems.forEach((child,index) => {
		  stringToReturn += `${child.itemCode}$$${child.hasPreparationInstructions ? child.selectedPreparationInstructions : null}${(index + 1) == childrenItems.length ? '' : '&&&'}`
		})

		return stringToReturn
	}


	set parent(parentItem:SaleItem) {
		this._parent = parentItem;
	}

	get parent() {
		if (this._parent != null) {
			return this._parent;
		}
		else {
			if (this.saleID == null || this.parentItemId == null || this.parentItemId <= 0 || this.level == null || this.level == 0) {
				return null;
			}
			if (posVC.saleItems && posVC.saleItems.length > 0) { //TODO: this is a code smell... I need to find a better way to do this and not access posVC...
				this._parent = SaleItem.flattenItems(posVC.saleItems).filter(item => item.rowNumber == this.parentRowNumber)[0]
			}
			return this._parent;
		}
	}

	get isChild():boolean {
		return (this.level != null && this.level > 0)
	}

	get displayQuantity() {
		let priceToDisplay = this.unitPrice;
		let quantityToDisplay = this.quantity;
		if (this.children != null && this.children.length != 0 && this.level == 0 && !Pinia.globalStore.simpleSelfService) {
			priceToDisplay = this.reducePriceOfChildren(priceToDisplay);
		}
		else if (this.isChild && this.parent != null && this.level == 1) {
			quantityToDisplay *= this.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.parent.parent != null && this.level == 2  && this.parent.item.disableItemGroupEditing) {
			quantityToDisplay = this.quantity * this.parent.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.level == 1 && !this.hasWeight) {
			quantityToDisplay = this.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.parent.parent != null && this.level == 2  && !this.hasWeight) {
			quantityToDisplay = this.parent.parent.quantity;
		}
		if (Math.abs( quantityToDisplay ) !==  1) {
			return `${session.fixedFloat(quantityToDisplay, 3)}X${session.fixedNumber(priceToDisplay)}`;
		}
		return String(quantityToDisplay)
	}

	get displayExternalOrderQuantity() {
		return `${this.getExternalOrderQuantity()} ${this.quantityUnit}`;
	}

	get displayExternalOrderAmount() {
		let quantity = this.getExternalOrderQuantity();

		if(this.item.hasWeight && this.quantityInUnits) {
			if(!posUtils.isBlank(this.item.priceZarhan2) && this.item.priceZarhan2 > 0) {
				return session.fixedNumber(this.item.priceZarhan2 * quantity);
			} else {
				return '-'
			}
		} else {
			return session.fixedNumber(quantity * this.unitPrice);
		}
	}

	private getExternalOrderQuantity() {
		let quantity = this.quantity;
		if(!this.item.hasWeight) {
			switch(this.level) {
				case 1:
					quantity *= this.parent.quantity;
					break;
				case 2:
					quantity *= this.parent.parent.quantity
					break;
			}
		}
		return quantity
	}

	get actualQuantity() { //quantity of child item is taken from parent
		let realQuantity = this.quantity

		if(this.isChild && this.parent != null && this.level == 1) {
			realQuantity *= this.parent.actualQuantity;
		}
		else if(this.isChild && this.parent != null && this.parent.parent != null && this.level == 2 && this.parent.item.disableItemGroupEditing) {
			realQuantity = this.quantity * this.parent.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.level == 1 && !this.hasWeight) {
			realQuantity = this.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.parent.parent != null && this.level == 2  && !this.hasWeight) {
			realQuantity = this.parent.parent.quantity;
		}
		return realQuantity;
	}

	get displayTotal() {
		let result = this.displayTotalNumber;

		if (session.pos.hasFlights) {
			result = result + ' ' + Service.MultiCurr.getInstance().getPosCurrencySign();
		}

		return result;
	}


	get displayTotalNumber() {
		let priceToDisplay = this.unitPrice;
		let quantityToDisplay = this.quantity;
		if (this.children != null && this.children.length != 0 && this.level == 0 && !Pinia.globalStore.simpleSelfService) {
			priceToDisplay = this.reducePriceOfChildren(priceToDisplay)
		}
		else if(this.isChild && this.parent != null && this.level == 1) {
			quantityToDisplay *= this.parent.quantity;
		}
		else if(this.isChild && this.parent != null && this.parent.parent != null && this.level == 2 && this.parent.item.disableItemGroupEditing) {
			quantityToDisplay = this.quantity * this.parent.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.level == 1 && !this.hasWeight) {
			quantityToDisplay = this.parent.quantity;
		}
		else if (this.isChild && this.parent != null && this.parent.parent != null && this.level == 2 && !this.hasWeight) {
			quantityToDisplay = this.parent.parent.quantity;
		}

		let result = session.fixedNumber(quantityToDisplay * priceToDisplay);
		
		return result;
	}


	get displayColorSize() {
		if (this.color == null && this.size == null) {
			return "";
		}
		return `${this.color || ""}/${this.size || ""}`;
	}

	get hasDiscount() {
		return this.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL;
	}

	get displayDiscountValue() {
		let floatDiscountValue = saleItemHelper.getSaleItemDiscountAmount(this);
		// Calculate the discount value
		let discountValue = session.fixedNumber(-floatDiscountValue );
		let priceAfterDiscount =  session.fixedNumber(this.unitPrice * this.quantity -floatDiscountValue);
		return {priceAfterDiscount: priceAfterDiscount, discountValue: discountValue};
	}

	get displayPrepInstructionsStr() {
		if (this.hasPreparationInstructions) {
			let prepInstArr = JSON.parse(this.selectedPreparationInstructions)
			if (!prepInstArr || prepInstArr.length == 0) {
				return null;
			}
			return `${prepInstArr.join(", ")}`
		}
		else {
			return null;
		}
	}

	get avoidFromAccumulatingClubPoints(): boolean {
		return this.item.avoidFromAccumulatingClubPoints;
	}

	private reducePriceOfChildren(originalPrice) {
		for (let child of this.children) {
				originalPrice -= child.quantity*child.unitPrice;
			if (child.children) {
				for (let granChildItem of child.children) {
					if(this.item.disableItemGroupEditing) {
						originalPrice -= granChildItem.quantity*granChildItem.unitPrice;
					} else {
						originalPrice -= granChildItem.unitPrice;
					}
				}
			}
		}
		return originalPrice;
	}



	get isDelivery():boolean{
		return jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && [jsonConfig.getVal(jsonConfig.KEYS.taItemCode),
            jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)].indexOf(this.itemCode) > -1;
	}

}}}}
