module PositiveTS {
export module Storage {
export module Entity {
export class Coupon extends WasmEntity {
	settingsID:string
	tenant_id:number
	company_id:number
	remark:string
	store_list:string
	used_at:string
	expire_at:string
	amount_threshold:number
	operation_code:string
	mandatory_voucher:string
	resume_voucher:string

	constructor() {
		let meta = {
			name: 'Coupon',
			fields: {
				settingsID: "TEXT",
				tenant_id: "INT",
				company_id: "INT",
				remark: "TEXT" ,
				store_list: "TEXT" ,
				used_at: "TEXT" ,
				expire_at: "TEXT" ,
				amount_threshold: "FLOAT",
				operation_code: "TEXT",
				mandatory_voucher: "TEXT",
				resume_voucher: "TEXT",
			},
			unique: ['settingsID']
		};
		super(meta)
	}

	static dbCache:Array<Coupon> = null
	
	// getCache() {
	// 	return super.getCache<Coupon>("dbCache");
	// }


}}}}
