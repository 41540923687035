module PositiveTS {
    export module Service {
    export module MizdamenOrHakafaSerive {
        export class HakafaCustomerDetails {
            clubName = "HAKAFA";
            s_id_number = "";
            s_first_name = "";
            s_last_name = "";
            dt_birth_date = "";
            s_phone_number_1 = "";
            amount = 0;
            merakez = "";
            db_id = undefined
            merakez_id = undefined;
            customer_number:string
            obligo:number
            customer_group_id:number
            address:string
            mode = 0;
            is_tamash_customer = false;
    
          }
    
          export class ServerHakafaCustomer {
            id:number
            first_name:string
            last_name:string
            tz:string
            store:any
            amount:number
            birth_date:string
            phone:string
            name:string
            hakafaMerakezCustomer:any
            merakez:string
            obligo:number
            is_monthly_obligo:boolean
            address:string
            customer_number:string
            customer_group_id:number
            mode = 0
            is_tamash_customer = false;
          }


          export function convertFromPosStructure(uglyCustObj:HakafaCustomerDetails) {
            let cust = new ServerHakafaCustomer()
            cust.tz = uglyCustObj.s_last_name
            cust.customer_number = uglyCustObj.customer_number;
            cust.first_name = uglyCustObj.s_first_name;
            cust.last_name = uglyCustObj.s_last_name;
            cust.birth_date = uglyCustObj.dt_birth_date;
            cust.phone = uglyCustObj.s_phone_number_1;
            cust.amount = uglyCustObj.amount;
            cust.id = uglyCustObj.db_id;
            cust.merakez = uglyCustObj.merakez;
            cust.obligo = uglyCustObj.obligo;
            cust.address = uglyCustObj.address;
            cust.is_tamash_customer = uglyCustObj.is_tamash_customer;
    
            return cust;
  
          }

          export function getCustomerFromSaleIfExists() {
            let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
            let customer = customerClubService.getCurrentSelectedCustomer()
            if (customer) {
              return convertFromPosStructure(customer);
            }

            return null;
          }

          export function openDialogIfNeeded() {
            if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.mizdamenOrHakafaMode))) {
              if (PositiveTS.Helper.SaleHelper.getJsonData(posVC.sale).isCustomerMizdamen && Pinia.globalStore.saleItems.length != 0) {
                return Pinia.globalStore.setCustomerMizdamen()
              }
              
              if (Service.Hakafa.hasCustomer()) {
                return Pinia.globalStore.setCustomerHakafa(PositiveTS.Helper.SaleHelper.getJsonData(posVC.sale).customer)
              }

              if (Pinia.globalStore.simpleSelfService) {
                Pinia.componentsStore.openComponent( {componentName:"mizdamenOrHakafaSelfServiceDialog"});
              } else {
                Pinia.componentsStore.openComponent( {componentName:"mizdamenOrHakafaDialog"});
              }
            }
          }

          export function cleanDataIfNeeded() {
            if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.mizdamenOrHakafaMode))) {
              if (Pinia.globalStore.simpleSelfService) {
                VueInstance.$refs.mizdamenOrHakafaSelfServiceDialog.cleanData();
              } else {
                VueInstance.$refs.mizdamenOrHakafaDialog.cleanData();
              }
            }
          }

          export async function selectOccasionalCustomer() {
            let jd = PositiveTS.Helper.SaleHelper.getJsonData(posVC.sale)
            jd.isCustomerMizdamen = true
            PositiveTS.Helper.SaleHelper.setJsonData(posVC.sale,jd)
            await Service.FullSale.saveCurrentSale()
            Pinia.globalStore.setCustomerMizdamen();
          }

          export async function searchCustomer(cardNumber) {
            let customerSearch = await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/${cardNumber.trim()}?by_customer_number=true`,"get");
            
            if (customerSearch.result.status == 1) {
              if (customerSearch.result.hakafa_customer.is_locked) {
                return {
                  success: false,
                  error: i18next.t('mizdamenOrHakafaDialog.cannotSaleCustomerIsLocked'),
                  status: customerSearch.result.status
                }
              }

              return {success: true, customer: customerSearch.result.hakafa_customer};
            }

            let errorMessage = i18next.t('generalError');

            if (customerSearch.result.status == 4) {
              errorMessage = i18next.t('customerNotFound');
            } else if (customerSearch.result.status == 2) {
              errorMessage = customerSearch.result.message;
            }

            return {
              success: false,
              error: errorMessage,
              status: customerSearch.result.status
            }
          }

          export async function selectCustomer(customer, isPriorityApi = false) {
            let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
            let jd = PositiveTS.Helper.SaleHelper.getJsonData(posVC.sale)
            delete jd.isCustomerMizdamen;
            PositiveTS.Helper.SaleHelper.setJsonData(posVC.sale,jd)
            await Service.FullSale.saveCurrentSale()
            let customerInPosStructure = await Service.Hakafa.convertToPosStructure(customer, isPriorityApi)
            await customerClubService.setCurrentSelectedCustomer(customerInPosStructure);
            await posVC.loadSale();
            Pinia.globalStore.setCustomerHakafa(PositiveTS.Helper.SaleHelper.getJsonData(posVC.sale).customer)    
          }
    }
}
}