module PositiveTS {
export module Storage {
export module Entity {
export class CustomerClub extends WasmEntity {

	constructor() {
		let meta = {
			name: 'CustomerClub',
			fields: {
				clubName: "TEXT",
				displayName: "TEXT",
				ClubMemberSpecialItemHndlr: "TEXT",
				customerUsePointsRatio: "FLOAT",
				minPointsForUse: "INT", //how many points does a customer need to have in order to be able to use points
				gainPointsFactor: "FLOAT", //how many points are gained per shekel purchase
	      fullClub: "BOOL", //is the club in basic mode or full mode (with points and so on...)
				customerFactorPctThresholdToSubstruct: "FLOAT", //if customer gets a discount of abouve X percent - he gaines less points - this is the percent threshold
				customerFactorToSubstruct: "FLAOT", //if customer reaches discount threshhold - multiply this value with the gain points factor
			},
		};
		super(meta)
	}
}}}}
