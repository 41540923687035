module PositiveTS {
  export module Components {
    export module HakafaTab {      
      
      export const route = "/hakafa-tab";

      export function getComponent() {
        return {
          template: JST.hakafaTab(),
          methods: {
          },
          components: {
            HakafaCustomerDialog: Components.HakafaCustomerDialog.getComponent()
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {standaloneMode,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {standaloneMode,mobileLayout}
  
            return {...globalStoreProps}
          },
          computed: {
            isDalpakimOnline(){
              return jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline)
            },
            mobilePhoneRepairModule(){
              return jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule);
            },
          },
          mounted(){
            Pinia.globalStore.setPosTabMode(true)
          },
          beforeRouteLeave (to,from,next){
            Pinia.globalStore.setPosTabMode(false)
            next()
          },
          data() {
            return {
              componentToDisplay: "HakafaCustomerDialog"
            }
          }
        }
      }
    }
  }
}
