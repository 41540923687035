module PositiveTS {

  export module Components {

      export module AddItemsToPromotion {
        
        export const route = "/add-items-to-promotion";
        interface ComponentData {
          promotion: object,
          items: Array<object>,
          filteredItems: Array<object>
          page: number,
          itemsPerPage: number,
          totalItems: number,
          itemSearch: string,
          departmentsMap: Map<number,Storage.Entity.Department>,
          departments:Array<Storage.Entity.Department>,
          type: 'get' | 'buy'
        }
        
        export function getComponent() {

          return {

            template: JST.AddItemsToPromotion(),

            async beforeRouteEnter(to, from, next){

              let departments = await appDB.departments.toArray();
              let departmentsMap = new Map()
              for (let dep of departments) {
                departmentsMap.set(dep.id,dep);
              }

              next(vm => {
                let self = vm as ComponentData
                self.departments = departments
                self.departmentsMap = departmentsMap
              })

            },

            async created () {

              if (this.$route.params.type){
                this.type =  this.$route.params.type
                this.items = await appDB.localItems.toCollection().sortBy('description')
                this.filterItems()     
              
              }
              
            },

            computed :{

              promotionItemsBuy () {
                return  Pinia.promotionsManageStore.getItemsBuy
              },

              promotionItemsGet () {
                return Pinia.promotionsManageStore.getItemsGet
              },

              promotionId () {
                return Pinia.promotionsManageStore.promotion.id
              },

              promotionName () {
                return Pinia.promotionsManageStore.promotion.name
              },

              promotionCode () {
                return Pinia.promotionsManageStore.promotion.code
              }

            },

            beforeRouteLeave (to, from, next) {

              // destroy the promotion in state if not go to add-or-edit-promotion
              if (to.path != '/add-or-edit-promotion'){
                Pinia.promotionsManageStore.RESET_STATE()
              }

              next()

            },

            methods: {

              ...Mixins.modalsMixin.methods,

              depName(item:Storage.Entity.Item) {

                if (item.departmentId > 0) {
                  return this.departmentsMap.get(item.departmentId).name;
                }
                else {
                  return i18next.t("itemManage.withoutDepartment");
                }
              },

              async filterItems(initPage = true) {

                if (initPage) {
                  this.page = 0;
                }
                //let isActive = (this.btns.filter( row =>{return row.isSelected})[0].id == "active")

                this.filteredItems = _.sortBy(this.items.filter(item => 
                  
                    //Boolean(item.locked) == !isActive && 
                    (item.description.includes(this.itemSearch) || (item.barcode && item.barcode.includes(this.itemSearch))) 
                  ),'code')
                
                this.totalItems = this.filteredItems.length;
                this.filteredItems = this.filteredItems.slice(this.page*this.itemsPerPage,this.page*this.itemsPerPage+this.itemsPerPage)
              },

              nextPage() {
                if (((this.page+1)*this.itemsPerPage) < this.totalItems) {
                  this.page += 1;
                  this.filterItems(false);
                }
              },

              prevPage() {
                if (this.page >= 1) {
                  this.page -= 1;
                  this.filterItems(false);
                }
              },
              
              itemHasThisPromotion (item) {
         
                let items = this.type == 'buy' ?  this.promotionItemsBuy : this.promotionItemsGet

                let indexOfItem = items.findIndex(i => i.id == item.id || i.code == item.code)

                return indexOfItem >= 0

              },

              itemCanBeAddToPromotion (item) {
                return !item.hasWeight && item.priceZarhan > 0 
              },

              removeItemFromPromotion (item) {
                Pinia.promotionsManageStore.addItemToDeleteFromPromotion({item, type: this.type})
              },

              async addItemToPromotion (item) {
     
                if (this.itemCanBeAddToPromotion(item)){
                  
                  Pinia.promotionsManageStore.addItemToAddPromotion({item, type: this.type})

                  return

                }

                //error alert
                app.hideLoadingMessage()
                this.showAlertGeneric(i18next.t("promotionManage.cannotAddItemHasWeightOrZeroPrice"))                

              },

              async refreshItemInSession (item){

                let oldItem = session.allItems.get(item.code)

                session.allItems.set(item.code, Object.assign(oldItem, item))

              },

              back () {
                VueServices.Router.goto(AddOrEditPromotion.route, {id: this.promotionId})
              } 

            },

            data() {
              
              let dataVal: ComponentData = {             
                promotion: {},
                items: [],
                filteredItems: [],
                page: 0,
                itemsPerPage: 25,
                totalItems: 0,
                itemSearch: '',
                departmentsMap: new Map(),
                departments:[],
                type: 'buy'
              }
              return dataVal;
            }
        
          }

        }
        
    }
  }
}