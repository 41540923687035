
module PositiveTS {
export module Application {
export module Controllers {
export var instances:any = {};
export class AbstractViewControllerTS {
	init (options?) {
		
	}
	/**
	 * This function is called whenever the controller is at the top of the navigation stack again
	 * It means that the page of the controller is the page displayed to the user
	 * 
	 * Bind any event listeners here
	 * 
	 * @param {Object} [options] Can be used to pass some configuration to the controller
	 */
	resume (options?) {
		
	}
	/**
	 * This function is called when the page of the controller is no longer at the top of the navigation stack
	 *
	 * Use this function to unbind and undo everything that was done in resume
	 */
	stop () {
		
	}
	/**
	 * This function is called when the controller is removed from the navigation stack
	 */
	destroy () {
		
	}
}}}}
