module PositiveTS {
  export module Components {
  export module UpsaleDialog {
  
    const _vueComponentName = 'upsale-dialog';

    export function create() {
      let upsaleDialog = {
          template: JST.upsaleDialog(),
          components: {PosMenuButton: PosMenuButton.getComponent()},
          methods: {
            open(upsale) {
              this.upsale = upsale;
              return new Promise(res => {
                this.resolve = res;
                this.$el.showModal();
              });
            },
            close() {
              this.resolve();
              this.$el.close();
              this.upsale = null;
              this.resolve = null;
            },
            async onItemClicked(upsaleItem) {
              if (this.upsale.singleItemDisplay) {
                return;
              }

              await this.addItemAndClose(upsaleItem);
            },
            async addItemAndClose(upsaleItem) {
              if (upsaleItem.item.hasGroups || upsaleItem.item.hasPreparationInstructions) {
                this.$el.close();
              }

              await this.addItem(upsaleItem);
              this.close();
            },
            getPrice(itemUpsale) {
              if (posUtils.isBlank(itemUpsale.price)) {
                return itemUpsale.item.priceZarhan;
              }

              return itemUpsale.price;
            },
            getItemImage(itemImage) {
              if (itemImage.item && itemImage.item.hasPicture) {
                return `/catalog-images/items/${session.pos.tenantID}/${session.pos.companyID}/${itemImage.item.code}`
              }

              return this.defaultImages && this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM];
            },
            async addItem(itemUpsale) {
              let price = this.getPrice(itemUpsale);
              let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(itemUpsale.item, {});
              saleItem.saleID = posVC.sale.id;
              saleItem.rowNumber = posVC.getRowNumber();
              saleItem.unitPrice = Number(price);
              await posVC.persistNewSaleItem(saleItem, true, false, price);
              await posPaymentVC.updateAmountsIndicators(false);
      
            },
          },
          computed: {
            title() {
              if (!posUtils.isBlank(this.upsale.textToShow)) {
                return this.upsale.textToShow;
              }

              if (!this.upsale.singleItemDisplay) {
                return this.$t('upsaleDialog.wouldYouLikeItems');
              }

              let item = this.upsale.itemUpsales[0].item
              return this.$t('upsaleDialog.wouldYouLikeAItem', {item: item.description});
            },
            currencySign() {
              if (jsonConfig.getVal(jsonConfig.KEYS.displayPricesOnButtons)) {
                return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
              }

              return '';
            },
            itemsClass() {
              if (this.upsale.itemUpsales.length < 7) {
                return `number-of-items-${this.upsale.itemUpsales.length}`;
              }

              return null;
            },
            defaultImages(){
              return Pinia.globalStore.defaultImages;
            },
            },
            data() {
              return {
                resolve: null,
                upsale: null,
              };
            },
        }

        VueApp.component(_vueComponentName,upsaleDialog);
        return upsaleDialog;
      }
    }}}
