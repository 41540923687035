
window.JST = {};

function baseName(str)
{
   var base = new String(str).substring(str.lastIndexOf('/') + 1); 
    if(base.lastIndexOf(".") != -1)       
        base = base.substring(0, base.lastIndexOf("."));
   return base;
}

function requireAll(r) { 
    r.keys().filter(k => {return k != "./sw.js" && 
                                !k.startsWith("./Workers/") })
            .forEach(r); 
}

function requireAllJST(r) {
    r.keys().forEach(function(k) { JST[baseName(k)] = r(k)})
}

window.Modernizr = {
    touch: false
};

function replaceCashierToPurser(obj){
    for (let i in obj) {
      if(typeof obj[i] == "object") {
        replaceCashierToPurser(obj[i]);
      }
      else if(typeof obj[i] == 'string') {
            obj[i] = obj[i].replace(/Cashier/g, 'Purser');
            obj[i] = obj[i].replace(/cashier/g, 'purser');
      }  
    }    
  }

import '../../../stylesheets/positive-simple.scss';
import '../../../stylesheets/tailwind.css';
import '../../../stylesheets/external.scss';
requireAll(require.context('../../../images',true,/\.(png|jpg|jpeg|gif|svg)$/)) //|woff|woff2|ttf|eot

import _ from 'lodash';
import 'script-loader!jquery';
import 'script-loader!jquery-migrate';
import eruda from 'eruda';
import erudaCode from 'eruda-code';
import sanitizeHtml from 'sanitize-html';
window.erudaCode = erudaCode;
window.eruda = eruda;
window.sanitizeHtml = sanitizeHtml;

window.VueApp = null;
import {createApp, defineComponent, isProxy} from 'vue'
window.createApp = createApp
window.defineComponent = defineComponent
window.isProxy = isProxy
// window.defineStore = defineStore

import {createRouter, createMemoryHistory, createWebHistory} from 'vue-router'
window.createRouter = createRouter
window.createMemoryHistory = createMemoryHistory
window.createWebHistory = createWebHistory
import VueSelect from 'vue-select';

window.VueSelect = VueSelect;
const moment = require('moment');
window.moment = moment;
import Dexie from 'dexie';
window.Dexie = Dexie;

import {translation as he} from './Service/I18n/he'
import {translation as en} from './Service/I18n/en'
import { createI18n } from 'vue-i18n'
let strDictionary = JSON.stringify(en);
let en_flight = JSON.parse(strDictionary);
replaceCashierToPurser(en_flight);
window.createI18n = createI18n
const i18n = createI18n({
    allowComposition: true, // to use both legacy and composition api
    locale: 'he',
    fallbackLocale: 'he',
    messages: {
        en,
        en_flight,
        he,
    }
})

window.i18n = i18n
window.i18next = i18n.global
import Datepicker from 'vue3-datepicker'
window.Datepicker = Datepicker
const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1 && window.Android != null;
let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
const chromeVersion = raw ? parseInt(raw[2], 10) : 0;
if (!isAndroid || chromeVersion >= 103) {
    const VueDraggable = require("vuedraggable");
    window.VueDraggable = VueDraggable;
}
const Emitter = require('tiny-emitter');
window.emitter = new Emitter()

import VueMask from 'v-mask'
window.VueMask = VueMask;
import initSqlJs from "sql.js";
window.initSqlJs = initSqlJs;
import Keyboard from "simple-keyboard";
window.Keyboard = Keyboard
import layout from "simple-keyboard-layouts/build/layouts/hebrew";
window.KeyboardLayout = layout
import * as JsBarcode from 'jsbarcode'
window.JsBarcode = JsBarcode;
import QrcodeVue from 'qrcode.vue'
window.QrcodeVue = QrcodeVue;
import QRCode from 'qrcode'
window.QRCode = QRCode ;
import * as PositiveShared from '../../../../positive-shared';
window.PositiveShared = PositiveShared;

import * as PositShared from '../../../../posit-js-shared';
window.PositShared = PositShared;

import * as Posimod from './modular/index';
window.Posimod = Posimod;

import { datadogLogs } from '@datadog/browser-logs';
window.datadogLogs = datadogLogs;
import { datadogRum } from '@datadog/browser-rum'
window.datadogRum = datadogRum;

import Vue3TouchEvents from "vue3-touch-events";
window.Vue3TouchEvents = Vue3TouchEvents;

window.notificationsAudio = {
    incomingDelivery: new Audio('/notifications_audio/delivery_incoming.ogg'),
}

import faIcons from './FontAwesome';

import { dom, library } from '@fortawesome/fontawesome-svg-core'
library.add.apply(library, faIcons);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
window.FontAwesomeIcon = FontAwesomeIcon;

import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
window.Vue3Lottie = Vue3Lottie;


datadogLogs.init({
    clientToken: 'pubc2392faeaf563def3177166c61a44ee1',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,    
});


import io from 'socket.io-client';
window.io = io;

import onScan from 'onscan.js'
window.onScan = onScan

import 'script-loader!./SharedWithWorkers/SharedDBVersions.js';
//import '../../loadVersion';
import '../../main.js';
import 'script-loader!./Libs/vorlon.production.js';
import 'script-loader!../../Libraries/jquery.url.js';
import 'script-loader!./Libs/jquery.keyboard.js';
import 'script-loader!./Libs/keyboard-layouts/hebrew.js';
import 'script-loader!../../Libraries/jshashtable-2.1.js';
import 'script-loader!../../Libraries/jquery.numberformatter-1.2.3.min.js';
import 'script-loader!../../Libraries/jquery.basic-slider.min.js';
import 'script-loader!../../Libraries/jquery-typing.2.0.min.js';
import 'script-loader!../../Libraries/jquery.soap.js';
import 'script-loader!../../Libraries/fileSaver.js';

import './Session.ts'
import './VueServices.ts'

// import '../../Libraries/jquery.url.js'
import 'script-loader!../../Libraries/async.js';
import 'script-loader!../../Libraries/html5sql.js';
import 'script-loader!../../Libraries/log4javascript_uncompressed.js'


requireAllJST(require.context('./', true, /\.ejs$/));
requireAll(require.context('./', true, /\.js$/));

requireAll(require.context('./SharedWithWorkers', true, /(^.?|\.[^d]|[^.]d|[^.][^d])\.ts$/));


import './Storage/Entity.ts';
import './Storage/IDBEntity.ts';
import './Storage/WasmEntity.ts';
requireAll(require.context('./Components/', true, /(^.?|\.[^d]|[^.]d|[^.][^d])\.ts$/));

requireAll(require.context('./', true, /(^.?|\.[^d]|[^.]d|[^.][^d])\.ts$/));


import * as Pinia from  '../src/Store/Pinia';
window.Pinia = Pinia
import * as Vue from  '../src/Vue';
window.Vue = Vue



//require(`file-loader?name=[name].[ext]!string-replace-loader?search=/assets/images&replace=/dist/assets/images&flags=g!../dist/index.html`);

