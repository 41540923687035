module PositiveTS {
    export module Storage {
    export module Entity {
    export class ElalReservation extends IDBEntity {
        recId: string
        pnr: string
        status: number
        docNumber:string
        docType: string
    
    
        static SYNC_STATUS_WAITING_TO_BE_SENT_TO_ELAL = 1;
        static SYNC_STATUS_WAITING_TO_BE_SENT_TO_SERVER = 2;
    
        constructor() {
            let meta = {
                name: 'ElalDocNumber',
                internal: true,
                fields: {
                    recId: "TEXT",
                    pnr: "TEXT",
                    docNumber: "TEXT",
                    docType: "TEXT",
                    status: "INT"
                },  
            }
            super(meta)
        }
    
        
    
        static import(elalReservation):ElalReservation {
            let model = new ElalReservation();
            model.importFromObject(elalReservation);
            return model;
        }
    
        async persist (tx = null, ignoreValidations = false) {
            
            return;
        };
        
    
        async isCurrentTimestampValid() {
            let ts = new Date().getTime()+3600*1000*24;
            let cnt = await appDB.elalDocNumbers.where('status').above(0).filter(sale => sale.timestamp > ts).count()
          return cnt == 0;
        };

        static async addOrUpdate(reservation) {
            await appDB.elalDocNumbers.put(reservation);
        }
    
        static async fetchByStatuses  (statuses:number[]) {
            let elalDocNumbers = await appDB.elalDocNumbers.where('status').anyOf(statuses).toArray()
            return elalDocNumbers;
        };

        static async fetchByPnr (pnrs:string[]) {
            let elalDocNumbers = await appDB.elalDocNumbers.where('pnr').anyOf(pnrs).toArray()
            return elalDocNumbers;
        } 
    }}}}
    