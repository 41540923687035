module PositiveTS {
  export module Components {
    export module FlightsSelectAuthorizedEmployees {

      export function getComponent() {
        return {
          template: JST.flightsSelectAuthorizedEmployees(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight} = Pinia.storeToRefs(flightsStore);
            return {currentFlight}
          },
          computed: {
            availableEmployees() {
              return this.allEmployees.filter(employee => (!(this.stepData.authorizesEmployees || []).includes(employee)) && employee.employeeID != this.wizardData.loggedInEmployee.employeeID)
              .map(employee => {
                employee.nameAndMagneticCard = `${employee.name} - ${employee.magneticCard}`;

                return employee;
              });
            }
          },
          data() {
            return {
              allEmployees: [],
            }
          },
          created() {
            app.showLoadingMessage(i18next.t("loading"));
            PositiveTS.Storage.Entity.Employee.fetchByStore(session.pos.storeID).then((result) => {
              this.allEmployees = result;
              app.hideLoadingMessage();
            })
            .catch(err => {
              app.hideLoadingMessage();
              console.error(err);
              this.showGenericError();
              this.goBack();
            })
          }
        }
      }
    }
  }
}