module PositiveTS {
  export module Components {
    export module VueDialog {
      let vueObj = {
        template: JST.VueDialog(),
        components: {
          
        },
        data() {
          return {
            componentToDisplay: "",
            title: "",
            showDialog: false,
            dialogElem: null,
            initParams:{},
            initialized: false
          }
        },
        methods: {
          _initjQueryDialogIfNeeded(options) {
            if (!this.initialized) {              
                this.dialogElem = $("#vue-dialog").dialog(options);
                this.initialized = true
            }
          },
          titleUpdated(target){
            this.title = target;
            (document.getElementById("vue-dialog").previousSibling as HTMLElement).querySelector(".ui-dialog-title").innerHTML = target

          },
          async open(componentToDisplay,initParams = {},options={
            modal: true,
            dialogClass: 'positive-dialog',
            resizable: false,
            closeOnEscape: false,
            draggable: false,
            width: '100vw',
            height: window.innerHeight,
          }) {
            this.componentToDisplay = componentToDisplay
            this.initParams = initParams
            this.showDialog = true
            this._initjQueryDialogIfNeeded(options)
            this.dialogElem.dialog('option', options)
            this.dialogElem.dialog('open')
            $(document).unbind('keypress');
          },
          close(){
            $("#vue-dialog").dialog('close');
            $(document).keypress(posVC.onKeyPress);
            this.initialized = false
            this.showDialog = false;
          }
        }
      }

      export function create() {
        VueApp.component("vue-dialog", vueObj)
      }
    }
  }
}
