module PositiveTS {
	export module Application {
		export module Controllers {
			export class ZreportViewController extends AbstractViewControllerTS {
				currentStep = 1
				wizard =null
				doPrint=false;
				doUpdateDataOnBack = false;
				stepMap = {
					main: 1,
					acceptNew: 2,
					restore: 3,
					result: 4
				}
				emvZreport:Storage.Entity.ZReport = null
				cachedZReports = []
				cachedZReport:Storage.Entity.ZReport = null
				ignoreClick = false
				newZCreated = false;
				// --------------------------------------------------------------------
				// Controller Life-Cycle
				// --------------------------------------------------------------------
				init (options) {
					var aThis = zReportVC;

					// Initialize the table views
					aThis.initTableViews();

					// Initialize the slideshow plugin which is used to mimic the wizard style flow
					aThis.initWizard();
				}
				initTableViews() {
					var aThis = zReportVC;

					// Initialize the table view
					var restoreTableView = $('#zreport-restore-tableview').tableview();
					restoreTableView.setNumberOfVisibleRows(11);

					var resultDetailsTable = $('#zreport-result-details-tableview').tableview({scollable: false});
					resultDetailsTable.setNumberOfVisibleRows(2);

					// Init the xreport table view
					var resultPaymentsTable = $('#zreport-result-payments-tableview').tableview({scollable: false});
					resultPaymentsTable.setNumberOfVisibleRows(6);

					var resultVouchersTable = $('#zreport-result-vouchers-tableview').tableview();
					if(Pinia.globalStore.mobileLayout) {
						resultVouchersTable = $('#zreport-result-vouchers-tableview').tableview({scollable: false});
					}
					resultVouchersTable.setNumberOfVisibleRows(4);

					// Set the table view header
					restoreTableView.setHeader(`
						<div class='ui-grid-b'>
							<div class='ui-block-a' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.zNumber')}</div></div>
							<div class='ui-block-b' style='width: 20%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.reportDate')}</div></div>
							<div class='ui-block-c' style='width: 13%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.totalInvoices')}</div></div>
							<div class='ui-block-d' style='width: 15%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.invoicesCount')}</div></div>
							<div class='ui-block-e' style='width: 15%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.totalCash')}</div></div>
							<div class='ui-block-f' style='width: 15%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.totalCredit')}</div></div>
							<div class='ui-block-f' style='width: 12%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.totalVat')}</div></div>
						</div>`);

					resultDetailsTable.setHeader(`
						<div class='ui-grid-b'>
						<div class='ui-block-a' style='width: 11%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.zNumber')}</div></div>
						<div class='ui-block-b' style='width: 17%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.producingTime')}</div></div>
						<div class='ui-block-c' style='width: 13%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.invoicesCount')}</div></div>
						<div class='ui-block-d' style='width: 15%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.totalInvoices')}</div></div>
						<div class='ui-block-e' style='width: 15%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.totalVat')}</div></div>
						<div class='ui-block-f' style='width: 12%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.status')}</div></div>
						<div class='ui-block-f' style='width: 17%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.statusDate')}</div></div>
						</div><!-- /grid-b -->`);

					resultPaymentsTable.setHeader(`
						<div class='ui-grid-b'>
						<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.paymentType')}</div></div>
						<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.inPosAmount')}</div></div>
						</div><!-- /grid-b -->`);

					resultVouchersTable.setHeader(`
						<div class='ui-grid-b'>
						<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.voucherType')}</div></div>
						<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>${i18next.t('zxReport.inPosSum')}</div></div>
						</div><!-- /grid-b -->`);
				}
				initWizard() {
					var aThis = zReportVC;

					// Initialize the slideshow plugin which is used to mimic the wizard style flow
					if (posUtils.isNullOrUndefined(aThis.wizard)) {
						aThis.wizard = $('#zreport [data-role="content"]').bjqs({
							'width'			: '100%',
							'height'		: '100%',
							'showMarkers'	: false,
							'showControls'	: false,
							'centerMarkers' : false,
							'automatic'		: false,
							'useCaptions'	: false,
							'keyboardNav'	: false
						});
						$('#zreport [data-role="content"]').css({
							'width': ''
						});
					}
				}

	 resume (options?) {
	 	var aThis = zReportVC;

	 	aThis.beforeResume();

	 	switch (aThis.currentStep) {
			 case aThis.stepMap.main:
	 		aThis.resume_main();
	 		break;
			 case aThis.stepMap.acceptNew:
	 		aThis.resumeAcceptNew();
	 		break;
			 case aThis.stepMap.restore:
	 		aThis.resumeRestore();
	 		break;
			 case aThis.stepMap.result:
	 		aThis.resumeResult();
	 		break;
	 	}

	 	aThis.afterResume();
	 }

	 changeStep(step) {
	 	var aThis = zReportVC;

	 	if (aThis.currentStep + 1 == step) {
	 		aThis.wizard.bjqsGo('forward', false);
	 	} else if (aThis.currentStep - 1 == step) {
	 		aThis.wizard.bjqsGo('backward', false);
	 	} else {
	 		aThis.wizard.bjqsGo(null, step);
	 	}

	 	aThis.currentStep = step;

	 	aThis.resume();
	 }

	 beforeResume() {
	 	var aThis = zReportVC;

	 	$('#zreport .dialog-buttons-bottom').hide();

	 	// Disable all buttons
	 	$('#zreport .ui-button').unbind('click');
	 	$('#zreport').css('max-height', '32em');
	 	$('#zreport').css('width', '50%');
	 }

	afterResume() {
		let aThis = zReportVC;

		if (aThis.currentStep == 4) {
			$('#zreport').css({top: 0, left: 0, width: '100vw', height: '100vh', maxHeight: '100vh' });
		}
		else if (aThis.currentStep == 3) {
			$('#zreport').css({top: 0, left: 0, width: '100vw', height: '100vh', maxHeight: '100vh' });
		}
		else {
			$(window).trigger('resize');
		}
	}

	resume_main() {
	 	var aThis = zReportVC;

	 	$('#zreport').css('max-height', '150px');

		 $('#zreport-main-restore').unbind('click')
	 	$('#zreport-main-restore').click(function() {
	 		aThis.changeStep(aThis.stepMap.restore);
	 	});

		 $('#zreport-main-new').unbind('click');
	 	$('#zreport-main-new').click(async function() {

			if(jsonConfig.getVal(jsonConfig.KEYS.prohibitZWhenSalesOnHold)) {
				if(await PositiveTS.Service.HoldSale.hasSalesOnHold()) {
					app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('zReport.salesOnHoldError'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					  });
				}
				return ;
			}

			if(Service.CashierStatement.forceCashDeclarationsAtEndOfDay()) {
				app.showAlert({
				  header: i18next.t('error'),
				  content: i18next.t("homepage.cashierStatementZError"),
				  continueButtonText: i18next.t("homepage.doCashierStatement"),
				  hideCancelButton: false
				}, (res) => {
					if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()){
Pinia.componentsStore.openComponent( {componentName:"CashierStatementMultiCurrencies", args: [Shared.Constants.CashierStatement.TYPE_END_DAY]});
					}else{
						Pinia.globalStore.setCashierStatementType('Z');
Pinia.componentsStore.openComponent( {componentName:"CashierStatement", args: []});
					}
				}, null);
				return ;
			  }
			  else {
				aThis.changeStep(aThis.stepMap.acceptNew);
			  }
		 });
	 }

	 resumeAcceptNew() {
	 	var aThis = zReportVC;

	 	$('#zreport').css('max-height', '200px');

		 $('#zreport-new-back').unbind('click');
	 	$('#zreport-new-back').click(function() {
	 		aThis.changeStep(aThis.stepMap.main);
	 	});

		$('#zreport-new-continue').unbind('click');

		if (session.pos.hasFlights && Pinia.flightsStore.flight) {
			$('#zreport-new-continue').click(Service.CloseFlight.CloseFlightAndZReport);
		} else {
			$('#zreport-new-continue').click(() => aThis.createNewZReportMain());
		}
	 }

	 resumeRestore() {
	 	var aThis = zReportVC;

	 	$('#zreport').css('max-height', '700px');
	 	$('#zreport').css('width', '95%');

	 	aThis.loadOldZreportsToView();

	 	$('#zreport-restore-buttons-bottom').show();

		 $('#zreport-restore-back').unbind('click');
	 	$('#zreport-restore-back').click(function() {
			 aThis.changeStep(aThis.stepMap.main);
			 pNavigator.back();
	 	});

		 $('#zreport-restore-show').unbind('click');
	 	$('#zreport-restore-show').click(async function() {
	 		var zReport = await aThis.getSelectedZreportFromCache();

	 		if (posUtils.isNullOrUndefined(zReport)) {
	 			// not selected
	 			return;
	 		}

	 		aThis.cachedZReport = zReport;

	 		aThis.changeStep(aThis.stepMap.result);
	 	});
	 }
	 resumeResult() {
		 var aThis = zReportVC;

		 if(session.pos.hasFlights || jsonConfig.getVal(jsonConfig.KEYS.cancelPrintZReport)) {
			 aThis.doPrint = false;
		 }

	 	$('#zreport').css('max-height', '700px');
	 	$('#zreport').css('width', '95%');

	 	$('#zreport-results-buttons-bottom').show();

		aThis.showZReport(aThis.cachedZReport);

		if(Service.CashierStatement.forceCashDeclarationsAtEndOfDay()) {
			Storage.Entity.CashStatementLog.setLastZToSend().then(() => {});
		} else {
			let newStatement = new Storage.Entity.CashStatementLog;
            newStatement.employee_id = session.pos.employeeID;
			newStatement.type = Shared.Constants.CashierStatement.TYPE_END_DAY;
			newStatement.amount = 0;
			appDB.cashStatementLogs.put(newStatement);
		}

		$('#zreport-results-back').unbind('click');
	 	$('#zreport-results-back').click(function() {
	 		aThis.changeStep(aThis.stepMap.main);
			if (aThis.doUpdateDataOnBack){
				statsVC.triggerUpdateDbUsingUi();
				aThis.doUpdateDataOnBack = false;
				}
	 	});
		 $('#zreport-results-print').unbind('click');
	 	$('#zreport-results-print').click(aThis.print);

		if (aThis.doPrint){
			aThis.doPrint = false;
			aThis.print()
		}

		if (aThis.newZCreated) {
			Pinia.globalStore.zReportCompleted(aThis.cachedZReport);
			this.newZCreated = false;
		}

	}

	async print() {

		let aThis = zReportVC;


		let vouchers = await aThis.getAllVouchers();

		let paymentsToPrint = {};
		let cachedPayments = JSON.parse(aThis.cachedZReport.payments)
		let totalWithdrawal = 0;
		for(let key in cachedPayments) {
			let keyNum = Number(key)
			if(keyNum != PositiveTS.Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL) {
				paymentsToPrint[keyNum] = cachedPayments[keyNum]
			} else {
				totalWithdrawal = cachedPayments[keyNum].amount
			}
		}



		if(aThis.cachedZReport.emvTransmitReport){
			let emvTransmitRep = JSON.parse(aThis.cachedZReport.emvTransmitReport)
			if(emvTransmitRep.SessionNumber && emvTransmitRep.Date && emvTransmitRep.DepositReport && (emvTransmitRep.DepositReport.length == 0 || emvTransmitRep.DepositReport[0].length == 0)){
				let res = await Service.EMV.getDepositReport(emvTransmitRep.SessionNumber,emvTransmitRep.Date);
				emvTransmitRep.DepositReport[0] = res;
				aThis.cachedZReport.emvTransmitReport = JSON.stringify(emvTransmitRep);
			}
		}

		if(aThis.cachedZReport.withdrawnCash > 0) {
			aThis.cachedZReport.withdrawnCash = (aThis.cachedZReport.withdrawnCash * -1)
		}
			let args: Printing.Reports.PrintZXReportArgs = {
				company_name: session.pos.companyName,
	 			store_name: session.pos.storeName,
	 			pos_id: session.pos.deviceID,
	 			cashier_name: session.pos.employeeName,
	 			payments: paymentsToPrint,
	 			vouchers: vouchers,
	 			date: aThis.cachedZReport.createdAt,
	 			zNumber: aThis.cachedZReport.zNumber,
	 			vat: aThis.cachedZReport.totalVat,
	 			isPrimary: aThis.cachedZReport.isPrimary,
	 			primaryZReportNumber: aThis.cachedZReport.primaryZReportNumber,
	 			debitSales: aThis.cachedZReport.debitSales,
	 			creditSales: aThis.cachedZReport.creditSales,
				zReport: aThis.cachedZReport,
				deliveriesSum: aThis.cachedZReport.deliveriesSum,
				deliveriesCount: aThis.cachedZReport.deliveriesCount,
				canceledBon: aThis.cachedZReport.canceledBonCount,
				withdrawalTotal: aThis.cachedZReport.withdrawnCash,
				cashToSafe: aThis.cachedZReport.cashMovedToSafe,
				enterStatement: aThis.cachedZReport.enterStatement,
				zStatement: aThis.cachedZReport.zStatement,
				withdrawnCount: aThis.cachedZReport.withdrawnCount,
				tipsAmount: aThis.cachedZReport.tipsCount,
				type: 'Z',
				tenbisCibusAmount: null,
				currenciesSummary: JSON.parse(aThis.cachedZReport.currenciesSummary),
			}

			if(!Service.ZReportAll.isRemoteAllZActive) // if the Z report was requested from a remote pos
				Printing.Reports.ZXReports.printZReport(args);
		}

	async getAllVouchers() {

	 	let aThis = zReportVC;
	 	let voucherModel = new PositiveTS.Storage.Entity.Voucher();
	 	let vouchers = JSON.parse(aThis.cachedZReport.vouchers);
		let allVouchers:Storage.Entity.Voucher[] = await voucherModel.all();

		let relevantVouchers =  allVouchers.filter(voucher => Object.keys(vouchers).indexOf(voucher.typeID) > -1 )
		relevantVouchers = _.sortBy(relevantVouchers,'typeID')

		let result = []
		for (let voucher of relevantVouchers) {
			result.push({voucher: voucher, amount: vouchers[voucher.typeID]})
	 	}

		return result;
	 		}

	async getSelectedZreportFromCache () {
	 	let aThis = zReportVC;
	 	try {
	 		// Get the selected row
	 		let theRow = $('#zreport-restore-tableview').tableview().getSelectedRow();

	 		// If there are no selected rows, return null
	 		if (theRow.length === 0) {
	 			return null;
	 		}

	 		// There are selected rows. Take the ID of the first one
	 		let rowID = $(theRow[0]).attr('id');

			 var zReport = aThis.cachedZReports[rowID];
			 await zReportVC.getPrimaryCategoryStats(zReport);
			 if (jsonConfig.getVal(jsonConfig.KEYS.printItemDetailsInZReport)){
				await zReportVC.getItemDetails(zReport)
			 }
			 return zReport;
	 	} catch (e) {
	 		console.error(e);
	 		return null;
	 	}
	}

	async loadOldZreportsToView() {
	 	var aThis = zReportVC;

	 	// Initialize the table view
	 	var restoreTableView = $('#zreport-restore-tableview').tableview();

	 	// empty z reports table
		restoreTableView.empty();

		if (Pinia.globalStore.isOnline) {

			try {
				app.showLoadingMessage(i18next.t("loadingData"));
				let zReports = await Helper.ReportsHelper.loadZReportsFromServer();
				zReports;

				aThis.cachedZReports = [];
				let mobileLayout = Pinia.globalStore.mobileLayout;


				for (let z of zReports) {
					let payments = JSON.parse(z.payments)
					let newRow = null;

					if (mobileLayout) {
						newRow = restoreTableView.addRow(posUtils.sanitizeTableViewRow([
							[i18next.t('zxReport.reportDate') + ": " + z.createdAt.split(" ").join(" "), 'report-date-cell'],
							i18next.t('zxReport.reportNumber') + ": " + (z.isPrimary ? z.primaryZReportNumber : z.zNumber),
							i18next.t('zxReport.totalInvoices') + ": " + z.totalSales,
							i18next.t('zxReport.total') + ": " + session.fixedNumber(z.totalPayments),
							]));
					} else {
						newRow = restoreTableView.addRow([
							z.isPrimary ? z.primaryZReportNumber : z.zNumber,
							z.createdAt.split(" ").join(" "),
							z.totalSales,
							session.fixedNumber(z.totalPayments),
							session.fixedNumber(payments[Storage.Entity.SalePayment.METHOD_CASH].amount),
							session.fixedNumber(payments[Storage.Entity.SalePayment.METHOD_CREDIT].amount),
							session.fixedNumber(z.totalVat)
							]);
					}

					aThis.cachedZReports[newRow.attr('id')] = z;
				}
				app.hideLoadingMessage();
			}
			catch(e) {
				// --- Failed to fetch entity
				PositiveTS.Service.Filelog.log('loadOldZreportsToView', JSON.stringify(e), false, 'error');
				console.error(e);
				app.hideLoadingMessage();
				// Tell the user
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('loadingZreportdataError'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
				Service.Logger.error(e);
			};
		}else{
				app.hideLoadingMessage();
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('offlineZreportMessage'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
		}
	}

	async validateBeforeZ() {
		let aThis = zReportVC;

		if (!Pinia.globalStore.isOnline) {
			throw new Error('offlineError');
		}
		let employeesSignOut = !session.pos.hasRemoteZ || (session.pos.hasRemoteZ &&  session.pos.isAutoEmployeeSignOutRemoteZ)
		if(employeesSignOut){
			await PositiveTS.Service.AutoCloseEodTimeReport.getReport()
        }

		if (session.pos.isEmv) {
			let connected = await Service.EMV.isPinPadConnected()
			if (!connected) {
				let creditCardPaymentsExisits = await this.isCreditCardPaymentsExists();
				if(creditCardPaymentsExisits){
					throw new Error(i18next.t('emv.PINPAD_NOT_CONNECTED'));
				}
			}
		}



		let isValidResponse = await PositiveTS.Helper.ReportsHelper.isZreportPreRequesitsValid();
		if (isValidResponse.valid) {
			app.showLoadingMessage(i18next.t("localBackupOfSales"), null, false);
			await Storage.Entity.Sale.cleanSaleOnHold()
		} else {
			throw new Error(isValidResponse.error);
		}

	}

	async transmitCreditCard() {

		if(Service.Gateway.isEnabled()){
			zReportVC.emvZreport = null;
			zReportVC.emvZreport = Helper.ReportsHelper.createNewZReportObjectFromSession();
			app.showLoadingMessage(i18next.t('creditCardPayment.gateway.transmitTxs'))
			await Service.Gateway.compareAndTransmit(zReportVC.emvZreport)
		}
		else if (session.pos.isEmv ){
			let connected = await Service.EMV.isPinPadConnected()
			if (!connected) {
				let creditCardPaymentsExisits = await this.isCreditCardPaymentsExists();
				if(creditCardPaymentsExisits){
					throw new Error(i18next.t('emv.PINPAD_NOT_CONNECTED'));
				}
				else{
					return;
				}
			}
			zReportVC.emvZreport = null;
			zReportVC.emvZreport = Helper.ReportsHelper.createNewZReportObjectFromSession();
			await Storage.Entity.EmvTransmitLog.getLastReportAndCreateRecordIfneeded()
			app.showLoadingMessage("מתכונן לשידור")
			await Service.EMV.compareToTranAndTransmit(zReportVC.emvZreport)
		}
	}

	async getPrimaryCategoryStats(zReport:PositiveTS.Storage.Entity.ZReport) {
		let response = await Service.FetchReq.jsonReq('/z_primary_categories_stats','post', zReport)
		zReport.primaryCategoryStats = JSON.stringify(response.result);
	}

	async getItemDetails(zReport:PositiveTS.Storage.Entity.ZReport) {
		let response = await Service.FetchReq.jsonReq('/z_reports/z_report_item_details','post', zReport)
		zReport.itemDetails = JSON.stringify(response.result)
	}

	async getGrandTotal(zReport:PositiveTS.Storage.Entity.ZReport) {
		let result = 0;
		if(jsonConfig.getVal(jsonConfig.KEYS.printGrandTotal) == true){
			let response = await Service.FetchReq.jsonReq('/z_grand_total','post', zReport)
			result = response.result;
		}
		zReport.grandTotal = result;
	}

	async createZReportAndFinish(forceWithoutPinpad = !session.pos.isEmv):Promise<{zReport: Storage.Entity.ZReport, sequence: Storage.Entity.Sequence}> {
		let {zReport,sequence} = await Helper.ReportsHelper.createNewZReport(zReportVC.emvZreport, forceWithoutPinpad); //if z report is master the return will be null...
		console.log(zReport)
		console.log(sequence)

		// close and finish
		// if Primary POS, createPrimaryZReport() and then return zReport
		if (serviceZMasterSlave.isZMasterSlave && serviceZMasterSlave.role == serviceZMasterSlave.ROLES.master) { //master Z
			if (zReport) {
				app.showLoadingMessage(i18next.t("sendZReportToServer"), null, false);

				await PositiveTS.Service.ZReportsSynchronizer.SendZReportToServer(zReport);
				let saleIds = await appDB.sales.where('syncStatus').equals(PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY).primaryKeys();
        		await appDB.sales.bulkDelete(saleIds);
				await appDB.sequences.put(sequence);
				app.hideLoadingMessage();
			}
			zReport = await Service.PrimaryZReport.createPrimaryZ()
		}
		else if (! serviceZMasterSlave.isZMasterSlave && !session.pos.isEmv) { //regular Z

			let svaTransmitReport = session.isAndroid ? null : await Storage.Entity.SvaTransmitLog.getEntriesForNonExistingShvaTransmits()
			await zReportVC.getPrimaryCategoryStats(zReport);
			await zReportVC.getGrandTotal(zReport);
			await zReport.updateEmvTransmitLog(zReport,svaTransmitReport)
		}
		else if (! serviceZMasterSlave.isZMasterSlave &&  session.pos.isEmv) { //emv Z
			zReport.syncStatus = PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_WAITING_TO_BE_SENT;

			if (!forceWithoutPinpad) {
				await Storage.Entity.EmvTransmitLog.getLastReportAndCreateRecordIfneeded()
				await Storage.Entity.EmvTransmitLog.attachNotAttachedReports(zReport)
			}

			await zReportVC.getPrimaryCategoryStats(zReport);
			await zReportVC.getGrandTotal(zReport);
			await zReport.updateEmvTransmitLog(zReport)
		}

		return {zReport, sequence};
	}

	async isCreditCardPaymentsExists(){
		let sales = await appDB.sales.toArray();
		for(let sale of sales){
			for(let payment of sale.payments){
				if(payment.method == Storage.Entity.SalePayment.METHOD_CREDIT){
					return true;
				}
			}
		}

		return false;
	}

	async CheckForOpenDeliveries(){
		if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
			if (!Service.Dalpak.isPrimaryPosOrSinglePos()) {
				return;
			}
		}

		let openDeliveryOrders = [];
		let cashOutside = await PositiveTS.Service.Delivery.deliveryCashRightNow();
		let openDeliveryOrdersWarning = i18next.t('zReport.openDeliveryOrdersWarning');

		try {
			openDeliveryOrders = await PositiveTS.Service.Delivery.getAllOrders();
		} catch(err) {
			Service.Logger.error(err);
			throw new Error('failedToGetOrders')
		}

			let partialOrders = openDeliveryOrders.filter(order => PositiveTS.Service.Delivery.isPartiallyFilledOrder(order));


			if (partialOrders.length > 0) {
				throw new Error("partialOrdersExists");
			}

			openDeliveryOrders = openDeliveryOrders.filter(order => order.status == PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY
			|| order.status == PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH);

			if(cashOutside > 0){
				openDeliveryOrdersWarning += "\n" + i18next.t('zReport.payAttention')+" " + cashOutside + " " + i18next.t('zReport.currentlyInDeliveryCash');
			}


			if(openDeliveryOrders.length > 0){
				await app.promiseShowAlert({
					header: i18next.t('zReport.openDeliveryOrders'),
					content: openDeliveryOrdersWarning,
					continueButtonText: i18next.t('zReport.closeOrders'),
					cancelButtonText: i18next.t('zReport.cancel'),
					hideCancelButton: false,
					noHandleEnterEscape: true,
				}).then(async response =>{
					if (response ==="cancel"){
						throw new Error("userCanceled");
					}
					else{
						await PositiveTS.Service.Delivery.closeAllOrders();
					}
				})
			}


			if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)){
				let res = await Service.DalpakSharedOrders.deleteAllOrders()

				if (!res.success) {
					throw new Error("failedToDeleteOrders");
				}
			}
	}

    async createNewZReportMain(forceWithoutPinpad = !session.pos.isEmv) { //here the Z Report creation process starts and finishes
		let aThis = zReportVC;
		// Backup payments for use by shva
		if (aThis.ignoreClick) {
			return false;
		}
		aThis.ignoreClick = true;
		Pinia.globalStore.setEMVHideLoader(true);
		try {
			app.showLoadingMessage(i18next.t("compareLocalSalesWithServer"));
			let localSalesMatchWithServerSales = await Service.CheckPosPropriety.syncLocalSalesWithServer()
			app.hideLoadingMessage();

			if(!localSalesMatchWithServerSales.success) {
				throw new Error("zReportLocalAndServerSalesDoesNotMatch")
			}

			if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
				await zReportVC.CheckForOpenDeliveries();
			}

			await zReportVC.validateBeforeZ();

			if (!forceWithoutPinpad || Service.Gateway.isEnabled()) {
				await zReportVC.transmitCreditCard()
			}

			// Backup Sales
			await Helper.ReportsHelper.backupSales();

			// Create the ZReport
			app.showLoadingMessage(i18next.t("creatingZReport"), null, false);
			let {zReport, sequence} = await zReportVC.createZReportAndFinish(forceWithoutPinpad);

			app.hideLoadingMessage();

			// Send ZReport to Server
			app.showLoadingMessage(i18next.t("sendZReportToServer"), null, false);
			zReport = await PositiveTS.Service.ZReportsSynchronizer.SendZReportToServer(zReport);
			app.hideLoadingMessage();

			aThis.ignoreClick = false;
			// if (zReport == null) { //TODO: not sure if this condition can even happen
			// 	aThis.changeStep(aThis.stepMap.main);
			// } else {
			aThis.cachedZReport = zReport;
			await posVC.resetOrderNumber();

			if (! (serviceZMasterSlave.isZMasterSlave && serviceZMasterSlave.role == serviceZMasterSlave.ROLES.slave)){
			aThis.doPrint = true;
			}
			if(Service.ZReportAll.isRemoteAllZActive || session.pos.isVendingMachinePos){
				aThis.doPrint = false;
			}

			// remove saved cancelled transaction uid-xfield
			localStorage.removeItem("cancelRequests")

			let saleIds = await appDB.sales.where('syncStatus').equals(PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY).primaryKeys();
        	await appDB.sales.bulkDelete(saleIds);
			if (!serviceZMasterSlave.isZMasterSlave || serviceZMasterSlave.role != serviceZMasterSlave.ROLES.master) {
				await appDB.sequences.put(sequence);
			}

			aThis.newZCreated = true;
			aThis.doUpdateDataOnBack = true;
			if(!session.pos.isVendingMachinePos){ // dont go to the z report screen if its a vending machine
				aThis.changeStep(aThis.stepMap.result);
			}
			// }

			if(PositiveTS.Service.ZReportAll.isRemoteAllZActive){
				socketManager.channelStore.trigger('z_report_info', {
					from: session.pos.deviceID,
					status: Service.ZReportAll.STATUS.FINISHED,
					message: 'דוח Z הופק בהצלחה',
					report_num: zReport.zNumber
				});
			}

			return true;
		}
		catch(e) {
			app.hideLoadingMessage();
			aThis.ignoreClick = false;
			if(Service.CashierStatement.forceCashDeclarationsAtEndOfDay()) {
				await Storage.Entity.CashStatementLog.undoLastZ();
			}
			console.error(e);
			console.error(e.stack);
			Service.Logger.error(e);
			switch (e.message) {
				case "offlineError":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportCreatingErrorOffline"))
				break;
				case app.userCancelledGlobalMessage:
				pNavigator.back();
				//do nothing...
				break;
				case "userCanceled":
				pNavigator.back();
				//do nothing...
				break;
				case i18next.t('emv.PINPAD_NOT_CONNECTED'):
				case i18next.t('emv.TRANSMIT_ERROR'):
				case i18next.t('emv.USER_CANCEL'):
				case i18next.t('emv.ERROR_WHILE_DOING_TRANSMIT'):
					app.showAlert({
						header: i18next.t('error'),
						content: e.message,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
					break;
			 case "transmitFailedError":
				 PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportTransmitFailed"));
					break;
				case "noSalesError":
				 PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportCreatingErrorNoSales"));
					break;
			case "syncToRemoteServerError":
				 PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportSyncToRemoteServerError"));
			     	break;
			 case "offlineSalesExist":
				 PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportCreatingErrorOfflineSales"));
				 break;
			 case "salesOnHoldExist":
				 PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportCreatingErrorOnHoldSales"));
				 break;
			 case "dalpaksAreOpen":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportCreatingErrorOpenedDalpak"));
				break;
			case "cantGetDalpaks":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("dalpaks.errorLoadingDalpaks"));
				break;
			case "failedToDeleteOrders":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("dalpakOrders.failedToDeleteOrders"));
				break;
			case "failedToGetOrders":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("delivery.failedToGetOrders"));
				break;
			case "noneTransmitedSecondaryPoss":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("noneTransmitedSecondaryPoss")+`\n${e.data.join(", ")}` + i18next.t("needToDoZreportInThosePoss"));
				break;
			case "partialOrdersExists":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("delivery.partialOrdersExists"));
				break;
			case "zReportLocalAndServerSalesDoesNotMatch":
				PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportLocalAndServerSalesDoesNotMatch"));
				break;

				default:
					if (e.message){
						app.showAlert({
							header: i18next.t('error'),
							content: `${i18next.t(`zReportFailedError`)}\n${e.message}`,
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true
						}, null, null);
					} else {
						PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportFailedError"));
					}
				break;
			}

			if (session.pos.hasFlights) {
				pNavigator.back();
			}
		}
		finally{
			Pinia.globalStore.setEMVHideLoader(false);
			if(session.pos.isVendingMachinePos){
				app.hideAlertDialog();
				console.log("vending machine pos - no need to show alert");
				Pinia.globalStore.setIsLoadingMessageDisabled(false)
			}
		}

		return false;
	}

	 showZReport(zReport) {
		PositiveTS.VueInstance.$refs.zReport.setZReport(zReport);


		if (zReport.tenbisTotalAmount){
			$('#zreport-tenbis-sum').text(session.fixedNumber(zReport.tenbisTotalAmount))
			$('#zreport-tenbis-row').show();
		} else {
			$('#zreport-tenbis-row').hide();
		}

		if (zReport.mishlohaTotalAmount){
			$('#zreport-mishloha-sum').text(session.fixedNumber(zReport.mishlohaTotalAmount))
			$('#zreport-mishloha-row').show();
		} else {
			$('#zreport-mishloha-row').hide();
		}

		 if (zReport.cibusTotalAmount){
				$('#zreport-pluxee-sum').text(session.fixedNumber(zReport.cibusTotalAmount))
				$('#zreport-pluxee-row').show();
		 } else {
			 $('#zreport-pluxee-row').hide();
		 }

		 if (zReport.goodiTotalAmount){
			$('#zreport-goodi-sum').text(session.fixedNumber(zReport.goodiTotalAmount))
			$('#zreport-goodi-row').show();
		} else {
			$('#zreport-goodi-row').hide();
		}

		 if (zReport.valuTotalAmount){
			$('#zreport-valu-sum').text(session.fixedNumber(zReport.valuTotalAmount))
			$('#zreport-valu-row').show();
		} else {
			$('#zreport-valu-row').hide();
		}

		if (zReport.dtsTotalAmount){
			$('#zreport-dts-sum').text(session.fixedNumber(zReport.dtsTotalAmount))
			$('#zreport-dts-row').show();
		} else {
			$('#zreport-dts-row').hide();
		}

		if (zReport.yaadTotalAmount){
			$('#zreport-yaad-sum').text(session.fixedNumber(zReport.yaadTotalAmount))
			$('#zreport-yaad-row').show();
		} else {
			$('#zreport-yaad-row').hide();
		}

		 if (zReport.totalHakafaTamash){
			 $('#zreport-total-hakafa-tamash-sum').text(session.fixedNumber(zReport.totalHakafaTamash))
			 $('#zreport-total-hakafa-tamash-row').show();
		 } else {
			 $('#zreport-total-hakafa-tamash-row').hide();
		 }

		if (zReport.paiditTotalAmount){
			$('#zreport-paidit-sum').text(session.fixedNumber(zReport.paiditTotalAmount))
			$('#zreport-paidit-row').show();
		} else {
			$('#zreport-paidit-row').hide();
		}

	 	var resultDetailsTable = $('#zreport-result-details-tableview').tableview();
	 	resultDetailsTable.empty();


	 	var zreportTable = $('#zreport-result-payments-tableview').tableview();
		var zreportVouchersTable = $('#zreport-result-vouchers-tableview').tableview();
		if(Pinia.globalStore.mobileLayout) {
			zreportVouchersTable = $('#zreport-result-vouchers-tableview').tableview({scollable: false});
		}

	 	$('#zreport-result-payments-tableview .ui-tableview-container').css('height', '26em');
		$('#zreport').css('max-height', '700px');
		 $('#zreport').css('width', '95vw');

	 	// $('#zreport-result-vouchers-tableview .ui-tableview-container').css('height', '7.6em');

	 	zreportTable.empty();
	 	zreportVouchersTable.empty();

	 	var payments = JSON.parse(zReport.payments);
	 	var vouchers = JSON.parse(zReport.vouchers);
	 	var voucherModel = new PositiveTS.Storage.Entity.Voucher();


	 	for (var method in payments) {
	 		if(payments[method].isInXZ == 1) {
				if(!session.pos.isRoshemet){
	 			zreportTable.addRow([
	 				payments[method].displayName,
	 				session.fixedNumber(payments[method].amount)
					 ]);
				}
				else{
					if(payments[method].amount > 0){
						zreportTable.addRow([
							payments[method].displayName,
							session.fixedNumber(payments[method].amount)
							]);
					}
				}

	 		}
	 	}

	 	var EnclosingVchrFor = function (type) {
			let results = voucherModel.fetchByTypeID(type)
			let name = results[0].name;
			if (["תן ביס", "פלאקסי", "goodi", "DTS", "יעד שריג"].indexOf(name) === -1) {
			  zreportVouchersTable.addRow(posUtils.sanitizeTableViewRow([
				  name,
				  session.fixedNumber(vouchers[type])
			  ]));
			}
	 	};

	 	for (var type in vouchers) {
	 		EnclosingVchrFor(type);
	 	}

	 	resultDetailsTable.addRow([
	 		zReport.isPrimary ? zReport.primaryZReportNumber : zReport.zNumber,
	 		zReport.createdAt,
	 		zReport.totalSales,
	 		session.fixedNumber(zReport.totalPayments),
	 		session.fixedNumber(zReport.totalVat),
	 		zReport.getZreportStatusText(),
	 		zReport.syncLastMessageTimestamp
	 		]);
	 }
	}}}}
declare var zReportVC:PositiveTS.Application.Controllers.ZreportViewController
zReportVC = new PositiveTS.Application.Controllers.ZreportViewController()
PositiveTS.Application.Controllers.instances.ZreportViewController = zReportVC
