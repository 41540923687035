/// <reference path="../dts/references.d.ts" />
module PositiveTS {
  export module Shared {
    export module DB {
      export function positiveDBVersions(db:Dexie) {
        (<any>self).positiveDBVersions(db);
      }

      export function putAddon(db:Dexie) {
        let origPut = db.Table.prototype.put;
        db.Table.prototype.put = function(object:any, key?):Promise<any> {
          if (object.id && object._data) { //object instanceof PositiveTS.Storage.Entity.Entity
            return origPut.call(this,Object.assign({id:object.id, timestamp: object.timestamp},object._data),key);
          }
          else {
            return origPut.call(this,checkIfNeedCloneProxy(object),key);
          } 
        }
      }

      export function bulkPutAddon(db:Dexie) {
        let origBulkPut = db.Table.prototype.bulkPut;
        db.Table.prototype.bulkPut = function(objects:any, keys?, options?):Promise<any> {
          let cloneObjects = objects.map(object => checkIfNeedCloneProxy(object))
          return origBulkPut.call(this, cloneObjects, keys, options) 
        }
      }

      export function checkIfNeedCloneProxy (value) {
        try{
          if (window.isProxy(value)){
            return _.cloneDeep(value)
          }else{
            // check if has property proxy
            if (typeof value == 'object'){
              for (let property in value){
                let v = value[property]
                if (window.isProxy(v)){
                  value[property] = _.cloneDeep(v)
                }
  
                if (Array.isArray(v) && v.length > 0){
                  if (v.some(item => window.isProxy(item))) {
                    value[property] = _.cloneDeep(v)
                  }
                }
              }
            }

            return value
          }
        }catch(error){
          if (error && error.name && error.name != 'ReferenceError'){
            console.error(error)
          }
          return value
        }
      }
    }
  }
}
