module PositiveTS {
   export module Components {
     export module MultiButtonDialog {
       const dialogSelector = "multi-button-dialog"
 
       export function create() {
 
         let component = {
           template: JST.multiButtonDialog(),
           methods: {
             open(buttons, text, uid = null, title = i18next.t('message')) {
               $(document).unbind('keypress');
               this.buttons = buttons;
               this.text = text;
               this.title = title;
               this.uid = uid;
 
               return new Promise((resolve, reject) => {
                 this.resolveFunc = resolve;
                 this.rejectFunc = reject;
 
                 this.$nextTick(() => {
                   this.$el.showModal();
                 })
               })
             },
             close(result, uid = null) {
               if (uid && this.uid != uid) {
                   return;
               }

               this.resolveFunc(result);
               this.$el.close();
               $(document).keypress(posVC.onKeyPress);
               this.uid = null;
             },
           },
           data: () => ({
             buttons: null,
             title: null,
             text: null, 
             uid: null,
           }),
         }
 
         VueApp.component('multi-button-dialog', component)
       }
     }
   }
 } 