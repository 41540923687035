import { fixedNumber } from "../../Utils";

export default class StaticObj {
    SessionNumber:string;
    Date:string;
    FileNumber:string;
    FileOpenDate:string;
    DebitNumberOfRecords:number;
    DebitTotal:number;
    CreditNumberOfRecords:number;
    CreditTotal:number;
    DepositReport:Array<string>;
    SolekList:Array<Solek>
  
    get DebitTotalForPrint() {
      return fixedNumber(this.DebitTotal/100.0,2)
    }
  
    get CreditTotalForPrint() {
      return fixedNumber(this.CreditTotal/100.0,2)
    }
  }
  
  export class Solek {
    Id:string
    CurrencySumsList:Array<Currency>
  
    get HebrewName() {
      switch(Number(this.Id)) {
        case 1:
          return "ישראכרט"
        case 2:
          return "כ.א.ל"
        case 6:
          return "לאומי קארד"
        default:
          return ""
      }
    }
  }
  
  export class Currency {
    Code:string
    DebitNumRecs:number
    DebitSums:number
    CreditNumRecs:number
    CreditSums:number
  
    get DebitSumsForPrint() {
      return fixedNumber(this.DebitSums/100.0,2)
    }
  
    get CreditSumsForPrint() {
      return fixedNumber(this.CreditSums/100.0,2)
    }
  
    get HebrewName() {
      switch (this.Code) { //TODO: add full currency table (low priority)
        case "376":
          return "שקל"
        case "840":
          return "דולר"
        case "978":
          return "יורו"
        default:
          return ""
      }
    }
  }