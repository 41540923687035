module PositiveTS {
  export module Components {
    export module MidragPayments {
      function initData() {
        return {
          rejectFunc: null,
          resolveFunc: null,
          allowedNumOfPayments: null,
          numOfPayments: [],
          selectedNumOfPayments: null
        }
      }
      export function create() {

        let component = {
          template: JST.midragPayments(),
          computed: {
            selectedPaymentBG() {
              return payment => {
                if (this.isOsherAd) {
                  return 'bg-osherad-lightblue'
                }
                if (payment == this.selectedNumOfPayments) {
                  return '!bg-primary-active'
                }
                return ''
              }
            },
            isOsherAd() {
              return Service.OsherAd.isEnabled();
            },
          },

          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
              this.initialized = true;
            },
            selectNumOfPayments(value) {
              this.selectedNumOfPayments = value;
              if (this.isOsherAd) {
                this.close();
              }
            },
            createNumOfPaymentsArr(max) {
              this.numOfPayments = Array.apply(null, { length: max + 1 }).map(Number.call, Number).slice(1);
            },
            close(isCancel = false) {
              let numOfPayments = null;
              if (!isCancel) {
                numOfPayments = this.selectedNumOfPayments;
              }
              this.resolveFunc(numOfPayments);
              this.$el.close();
              this.cleanData();
            },
            open(allowedMaxNumOfPayments) {
              this.allowedMaxNumOfPayments = allowedMaxNumOfPayments
              if (allowedMaxNumOfPayments) {
                this.createNumOfPaymentsArr(allowedMaxNumOfPayments)
              }
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            }
          },
          data: initData,
        }

        VueApp.component('midrag-payments', component)
      }
    }
  }
}
