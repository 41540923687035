module PositiveTS {
  export module Service {
    export class PositiveDeliveryAPIService extends BaseDeliveryAPIService {

      protected apiData;

      constructor(protected apiConfig) {
        super(new PositiveDeliveryConvertService());

        if (!this.apiConfig) {
          throw new Error('Error initializing api data - data is missing');
        }

        let apiData = Storage.Entity.PositiveDeliveryApi.getById(apiConfig.positiveApiId);

        if (!apiData) {
          throw new Error('Positive API data not found - id ' + apiConfig.positiveApiId);
        }

        this.apiData = {
          ...apiConfig,
          ...apiData,
        };

        (this.convertService as PositiveDeliveryConvertService).apiData = this.apiData;
      }

      public serviceName() {
        return this.apiData.name;
      }

      protected login(): Promise<void> {
        return null;
      }

      protected getTokenLocalStorageKey() {
        return null;
      }

      protected getLocalToken() {
        return this.apiData.token;
      }

      protected async getOpenOrders(): Promise<any[]> {
        try{
          let result = await this.makeHttpRequest(
            this.getUrl('GetOpenOrders'),
            'GET'
          )
          if(result.status != 200){
            throw new Error('error in request to ' + this.serviceName() + ' - ' + result.status + ' - ' + result.body);
          }
          PositiveTS.Service.Delivery.clearDevlieryErrorsByOrderNumberAndType(null, Delivery.FailedDeliveryType.HttpError)
          return posUtils.jsonParse(result.body) || [];
        }catch(e){
          PositiveTS.Service.Delivery.failedDeliveriesLogger(e,null,this.serviceName(),Delivery.FailedDeliveryType.HttpError, false)
          PositiveTS.Service.Logger.error(e);
          return null;
        }

      }

      protected getUrl(endpoint) {
        return this.apiData.url + '/' + endpoint + '/' + this.apiData.restId;
      }

      public async setOrderStatus(order: any, status: any, data: any) {
        return await this.makeHttpRequest(
          this.getUrl('SetOrderStatus'),
          'POST',
          { orderId: order.orderId, status, data }
        )
      }
    }
  }
}