module PositiveTS {
export module Components {
export module ReportEmployeeTime {
  const _dialogSelector = "#report-employee-time-dialog"
  const _vueComponentName = 'report-employee-time';
  const _template = JST.reportEmployeeTime();

  export function create() {
    let vueObj = {
        template: _template,
        methods: {
          open: _open,
          close: _close,
          runReport: _runReport,
        },
        data: _initData

    }
    VueApp.component(_vueComponentName,vueObj)

  }




  function _open(currentBalance: string, valuCardNumber: string){
    $(document).unbind('keypress');
    if (!$(_dialogSelector).hasClass('ui-dialog-content')) {
      $(function() {
        let mobileLayout = Pinia.globalStore.mobileLayout;

        $(_dialogSelector).dialog({
          autoOpen: true,
          modal: true,
          dialogClass: 'positive-dialog',
          width: mobileLayout ? '95%' : '80%',
          height: mobileLayout ? 'auto' : 230,
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });
      });

      $(this.$refs.reportByDateFromDate).datepicker({
			     dateFormat: 'dd/mm/yy'
         });

     $(this.$refs.reportByDateToDate).datepicker({
              dateFormat: 'dd/mm/yy',
              maxDate: new Date()
            });

     $(this.$refs.reportByDateFromDate).focus();
    }


    //this._toDate.setHours(23, 59, 59, 999);
    $(_dialogSelector).dialog('open');


  }


  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      fromDate: moment().format("DD/MM/YYYY"),
      toDate: moment().format("DD/MM/YYYY"),

    };
  }

  function _close() {
    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
  }

  function _runReport(){

    if (!PositiveTS.Reachability.isOnline) {
      return app.promiseShowAlert({
        header: "",
        content: i18next.t('unifiedEmployeesReportDialog.offLine'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true,
        noHandleEnterEscape: true,
      })
    }

    var strFromDate = $(this.$refs.reportByDateFromDate).val();
    var strToDate = $(this.$refs.reportByDateToDate).val();
    var fromDate = moment(strFromDate, "DD/MM/YYYY");
    var toDate = moment(strToDate, "DD/MM/YYYY");
    var diff = fromDate.diff(toDate, "days");
    if (Math.abs(diff) > 365) {
      return app.promiseShowAlert({
        header: "",
        content: i18next.t('unifiedEmployeesReportDialog.badDateRange'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true,
        noHandleEnterEscape: true,
      })
    }

    if (diff > 0){
      return app.promiseShowAlert({
        header: "",
        content: i18next.t('unifiedEmployeesReportDialog.toLargerThenFrom'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true,
        noHandleEnterEscape: true,
      })
    }

    // app show message
    app.showLoadingMessage(i18next.t('unifiedEmployeesReportDialog.inProccess'));
    return PositiveTS.Service.ReportEmployeeTime.getReport( fromDate.toDate(),toDate.toDate() )
    .then ((result) => {
      app.hideLoadingMessage();
      if (!Object.keys(result).length) {
        return app.promiseShowAlert({
          header: "",
          content: i18next.t('unifiedEmployeesReportDialog.noData'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
          noHandleEnterEscape: true,
        })
      } else {
        let isPicturePrinting = printer.isPicturesPrinter()
        let printReportInOldFormat = jsonConfig.getVal(jsonConfig.KEYS.printEmployeeTimeReportInOldFormat)

        if (printReportInOldFormat && !isPicturePrinting) {
          return PositiveTS.Printing.ReportEmployeeTime.EmployeeTimeReportOld(result, strFromDate, strToDate);
        } else {
          return PositiveTS.Printing.ReportEmployeeTime.EmployeeTimeReportNew(result, strFromDate, strToDate);
        }
      }
    })
    .then( ()=>{
      this.close();
    })
    .catch((e)=>{
      console.error(e.message);
      app.hideLoadingMessage();
      return app.promiseShowAlert({
        header: "",
        content:  i18next.t('unifiedEmployeesReportDialog.systemError'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true,
        noHandleEnterEscape: true,
      })

    })


  }

}}}
