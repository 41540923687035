module PositiveTS {
export module Promotions {
	export class Engine {
		public allPromotionsCodes
		private saleItemsPotentialPromotions // Holds all the sale items and their potential promotions
		private salePromotion // Hold the promotion that relevant to sale (no saleItem)
		private salePromotionDiscountPercent // Hold the promotion discount percent for promotion that relevant to sale (no saleItem)
		private promotionsBuy // Holds all the promotions from buy type
		private promotionsGet // Holds all the promotions from get type
		private saleItemBuyPromotions // Holds all buy promotions by sale item
		private promotionsByCode // Access all promotions by their code
		private noItemsTemplates
		private manualPromotions
		private pormotionGroups
		private customer

		constructor(private hasCustomer = false,private ignorePromotions = false, private customerPromotionTypes = [],
			private customerGroupId = null, customer = null) {

			this.saleItemsPotentialPromotions = {};
			this.salePromotion = null;
			this.salePromotionDiscountPercent = 0;
			this.promotionsBuy = {};
			this.promotionsGet = {};
			this.allPromotionsCodes = [];
			this.saleItemBuyPromotions = {};
			this.promotionsByCode = {};
			this.noItemsTemplates = ['4'];
			this.manualPromotions = [];
			this.pormotionGroups = [];
			this.customer = customer;
		}

		public calculatePromotionsForSale(sale, saleItems) {
			var aThis = this;
			

			var returnObj = {
				salePromotion: null,
				salePromotionDiscountPercent: 0,
				saleItemsPotentialPromotions: [],
				promotionItems: []
			};

			if (aThis.ignorePromotions) {
				return returnObj;
			}

			let updatedSaleItems = this.getRelevantItems(saleItems);

			// Preprocess sale items
			this.preprocessSaleItems(updatedSaleItems);

			// get all avilable promotions
			try {

				let promotions = this.loadAvilablePromotions()			

				if (Helper.SaleHelper.doesSaleHasDiscount(posVC.sale)) { //if there is a discount - don't apply sale promotions
					promotions = promotions.filter(promo => promo.template != "4")
				}
				if (promotions.length === 0) {
					// -- No, there are no promotions
					return returnObj;
				}
				this.findBestPromotions(returnObj, promotions, sale, updatedSaleItems);
				returnObj.promotionItems.forEach((item) =>  { delete item.promotion; });
				return returnObj;
			
			} catch(error) {
				// --- Fetching promotions failed
				console.error(error.message);
				console.error(error.stack);
				throw (this.saleItemsPotentialPromotions); //TODO ANDROID: not sure about this
			}
		}

		public getAvilableManualPromotionsForSale(sale, saleItems) {

			//If there is hanahat heshbon we don't need to pay attention to item promotions
			if (sale.discountPercent > 0 && posUtils.isNullOrUndefinedOrEmptyString(sale.promotionCode)) {
				return [];
			}

			// // Preprocess sale items to get all promotion codes...
			this.preprocessSaleItems(saleItems);

			// Fetch all manual promotions
			return Storage.Entity.Promotion.fetcManualByStoreAndCodes(session.pos.storeID, this.hasCustomer, 
				this.allPromotionsCodes, this.customerPromotionTypes, this.customerGroupId);
		};

		private getRelevantItems(saleItems) {
			let relevantPromotionCodes = new Set(session.allPromotions.filter(promo => {
				if (promo.isClubMembersOnly && !this.hasCustomer) {
					return false;
				}
				if (promo.customerGroupId != null && promo.customerGroupId != this.customerGroupId) {
					return false;
				}
				return true;
			}).map(promo => promo.code))
		
				
			let relevantItems = saleItems.filter((saleItem) => {
				 let notIgnored = posVC.ignoredItemsForPromotions.indexOf(saleItem.rowNumber) === -1 && 
				 									parseFloat(saleItem.discountID) <= 0 && saleItem.noDiscount == false;
				 let promos = `${saleItem.item.promoBuy}&${saleItem.item.promoGet}`.split("&"); 
				 let hasRelevant = promos.filter(x=>relevantPromotionCodes.has(x)).length > 0;
				 return notIgnored && hasRelevant;
			});

			let updatedSaleItems = [] //get a clone of the sale items and work on it.
			for (let saleItem of relevantItems) {
				updatedSaleItems.push(_.cloneDeep(saleItem)) //$.extend(true,{},saleItem)
			}

			return updatedSaleItems;
		}

		private loadAvilablePromotions() {
			var aThis = this;

			// Fetch all automatic promotions
			let promotions = Storage.Entity.Promotion.fetchAutomaticByStoreAndCodes(session.pos.storeID, this.hasCustomer, this.allPromotionsCodes, 
				this.customerPromotionTypes, this.customerGroupId, this.customer)
				if(this.customer && !this.customer.is_valid && ['positive', 'bril'].includes(this.customer.clubName))	{
				promotions = promotions.filter(promo => !promo.isClubMembersOnly);
			}
			return promotions.concat(aThis.manualPromotions);
		}

		private addPromoGroups(promoObj, promotions, sale, updatedSaleItems, promoGroups) {
			//remove promotions that don't have allow multiple times same sale
			for (var i=0; i< promoGroups.length; i++) {
				var selectedPromoItem = promoGroups[i];
				var selectedPromotion = selectedPromoItem.promotion;
					if (!Boolean(selectedPromotion.allowMultipleTimesSameSale)) {
						promotions = promotions.filter((promotion) => {
							return promotion.code !== selectedPromotion.code;
					});
				}

				promoObj.promotionItems = promoObj.promotionItems.concat(selectedPromoItem);

				//re-run promotion calculation without the items of the first promotion

				var saleItemsMinusPromotion = [];

				_.forEach(selectedPromoItem.itemsCounter,(itemCounterValue,itemCounterKey) => {

					var field = session.pos.separateItemLinesOn ? 'rowNumber' : 'barcode'
					for(var key in updatedSaleItems) {

						if (updatedSaleItems[key][field] == itemCounterKey) {
							updatedSaleItems[key].quantity -= itemCounterValue;
						}
					}
				});

				for(var key in updatedSaleItems) {
					if (updatedSaleItems[key].quantity > 0) {
						saleItemsMinusPromotion.push(updatedSaleItems[key]);
					}
				}

				//recursion - calculate promotions again on all the remaining items
				this.saleItemsPotentialPromotions = {};
				this.promotionsBuy = {};
				this.promotionsGet = {};
				this.saleItemBuyPromotions = {};

			}
			this.preprocessSaleItems(saleItemsMinusPromotion);
			return this.findBestPromotions(promoObj,promotions,sale,saleItemsMinusPromotion);
		}

		private findBestPromotions(promoObj, promotions, sale, updatedSaleItems) {

			this.processPromotionsForSaleItems(promotions, sale, updatedSaleItems);

			promoObj.salePromotion = this.salePromotion;
			promoObj.salePromotionDiscountPercent = 0;

			promoObj.pormotionGroups = this.pormotionGroups;
			_.map(promoObj.pormotionGroups,(group) => {
				group.promotionCode = group.promotion.code;
				group.clubMemberPromotionType = group.promotion.clubMemberPromotionType;
				return group;
			});

			promoObj.pormotionGroups.forEach((group) => {
				group.discountPercentForGroup = group.discountAmountForGroup/group.totalPriceForItemsBeforeDiscount;
				group.hasBarcode = false;
				if (Boolean(group.promotion.hasBarcode)) {
					group.promotionBarcode = group.promotion.promotionBarcode;
					group.hasBarcode = true;
				}
			});
			var groupsByDiscountOrder = _.sortBy(promoObj.pormotionGroups,'discountAmountForGroup').reverse();
			var groupsByDiscountPercentOrder = _.sortBy(promoObj.pormotionGroups,'discountPercentForGroup').reverse();

			if (groupsByDiscountOrder.length === 0 || groupsByDiscountOrder[0].discountAmountForGroup === 0) { //stop condition of the recursion - no more discounts on remaining items
				return promoObj;
			}
			else {
				if (groupsByDiscountPercentOrder[0].promotion.template == "6") {
					return this.addPromoGroups(promoObj, promotions, sale, updatedSaleItems, groupsByDiscountPercentOrder.filter((grp) => {
						return grp.promotion.template === "6";
					}));
				}
				else {
					return this.addPromoGroups(promoObj, promotions, sale, updatedSaleItems, [groupsByDiscountPercentOrder[0]]);
				}
			}
		}

		private processPromotionsForSaleItems(promotions, sale, saleItems) {
			// -- Yes, there are promotions, continue...
			// Filter only the active promotions and rearrange the array
			this.removeInactivePromotions(promotions);
			this.updatePromotionData(promotions);
			this.pormotionGroups = [];

			// Build parameters object
			var parameters = {
				saleItemsPotentialPromotions:	this.saleItemsPotentialPromotions,
				promotionsBuy:								this.promotionsBuy,
				promotionsGet:								this.promotionsGet,
				allPromotionsCodes:						this.allPromotionsCodes,
				saleItemBuyPromotions:				this.saleItemBuyPromotions,
				promotionsByCode:							this.promotionsByCode,
				sale:													sale,
				saleItems:										saleItems,
				salePromotion: 								this.salePromotion,
				salePromotionDiscountPercent: this.salePromotionDiscountPercent,
				promoGroups: []
			};

			// Define templates priority
			// The highest template is the one defined first
			var templateQueue = [
				PositiveTS.Promotions.Templates.BuyXGetDiscountOnX,
				PositiveTS.Promotions.Templates.BuyXGetDiscountOnY,
				PositiveTS.Promotions.Templates.BuyXGetVariedItemDiscount,
				PositiveTS.Promotions.Templates.BuyAboveXGetSaleDiscount,
				PositiveTS.Promotions.Templates.BuyXGetAmountDiscountOnY,
				PositiveTS.Promotions.Templates.PercentDiscountOnAllWhenBuyingAboveNItems,
				PositiveTS.Promotions.Templates.BuyAboveXWithItemGetSaleDiscount,
				PositiveTS.Promotions.Templates.KneBe,
				PositiveTS.Promotions.Templates.KneBeWithWeight,
				PositiveTS.Promotions.Templates.KneKabel,
				PositiveTS.Promotions.Templates.HaZolMe,
				PositiveTS.Promotions.Templates.BrillCustomerClubBirthday
			];

			for (var i = 0; i < templateQueue.length; i++) {
				var templateInstance = new templateQueue[i](parameters);
				templateInstance.calculatePromotions();
				parameters = templateInstance.parameters;
			}

			this.salePromotion = parameters.salePromotion;
			this.salePromotionDiscountPercent = parameters.salePromotionDiscountPercent;
			this.saleItemsPotentialPromotions = parameters.saleItemsPotentialPromotions;
			this.pormotionGroups = parameters.promoGroups;
		};

		private removeInactivePromotions(promotions) {
			this.promotionsByCode = {};
			//iterate from end to start because we use splice here!
			for (var i = promotions.length - 1; i >= 0; i--) {
				if (!Engine.isPromotionActive(promotions[i])) {
					// --- START: For Table -----------------------------------------------
					// Remove from table...
					var keys = Object.keys(this.saleItemsPotentialPromotions);
					for (var loop = 0; loop < keys.length; loop++) {
						var key = keys[loop];
						if (this.saleItemsPotentialPromotions[key].promotionCode === promotions[i].code) {
							delete this.saleItemsPotentialPromotions[key];
						}
					}
					// --- END: For Table -----------------------------------------------

					// Promotion is inactive, remove it from the promotions array
					promotions.splice(i, 1);
					continue;
				}

				// Promotion is active so add it to the promotionsByCode object
				this.promotionsByCode[promotions[i].code] = promotions[i];
			}
		};

		private updatePromotionData(promotions) {
			for (var i = 0; i < promotions.length; i++) {
				var promotion = promotions[i];
				for (var key in this.saleItemsPotentialPromotions) {
					if (this.saleItemsPotentialPromotions[key].promotionCode === promotion.code) {
						// Update data...
						this.saleItemsPotentialPromotions[key].promotionName = promotion.name;
						this.saleItemsPotentialPromotions[key].discountType = 'Item';
					}
				}
			}
		}

		// --------------------------------------------------------------------
		// Utilities
		// --------------------------------------------------------------------
		private _generateUniqueKey(saleItem, promotionCode, buyGet) {
			return (saleItem.id + '&' + promotionCode + '&' + buyGet);
		}

		/*
			Check if promotion is active by 2 checks:
				1. isActive
				2. Inside the date range fromDate - toDate
		*/
		static isPromotionActive(promotion) {
			// --- Is Promotion Active?
			if (!promotion.isActive) {
				return false;
			}

			// --- Is Promotion in Date Range?
			// Parse the dates
			var fromDateTimestamp = 0;
			var parts;
			if (promotion.fromDate !== '' && promotion.fromDate !== 'null' && promotion.fromDate !== null) {
				parts = promotion.fromDate.match(/(\d+)/g);
				fromDateTimestamp = (new Date(parts[2], parts[1] - 1, parts[0])).getTime();
			}

			var toDateExist = false;
			var toDateTimestamp = 0;
			if (promotion.toDate !== '' && promotion.toDate !== 'null' && promotion.toDate !== null) {
				parts = promotion.toDate.match(/(\d+)/g);
				toDateTimestamp = (new Date(parts[2], parts[1] - 1, parts[0])).getTime() + 24 * 60 * 60 * 1000;
				toDateExist = true;
			}

			// Check...
			var currentTimestamp = PositiveTS.DateUtils.timestamp();
			if (currentTimestamp < fromDateTimestamp || (currentTimestamp > toDateTimestamp && toDateExist)) {
				return false;
			}

			// --- All Checks Passed!
			return true;
		}

		private calcuateSaleTotalAmount(sale, saleItems) {
			var totalAmount = 0;

			for (var i = 0; i < saleItems.length; i++) {
				var saleItem = saleItems[i];

				totalAmount += this.calcuateSaleItemTotalAmount(saleItem);
			}

			if (this.salePromotionDiscountPercent > 0) {
				totalAmount *= (100 - this.salePromotionDiscountPercent) / 100;
			}

			return totalAmount;
		};

		private calcuateSaleItemTotalAmount(saleItem) {
			var saleItemCopy = new PositiveTS.Storage.Entity.SaleItem();
			saleItemCopy.importFromObject(saleItem.exportToObject());

			for (var i in this.saleItemsPotentialPromotions) {
				var currentPromoObj = this.saleItemsPotentialPromotions[i];

				if (currentPromoObj.isPromotionGiven && currentPromoObj.itemCode == saleItemCopy.itemCode) {
					saleItemHelper.addPromotionToSaleItem(saleItemCopy, currentPromoObj);
					break;
				}
			}

			return saleItemHelper.getSaleItemTotalPrice(saleItemCopy);
		}

		public filterChoosedManualPromotions(sale, saleItems) {
			var allPromotions = this.manualPromotions;

			// all promotions that connected to items
			var itemsPromotions = $.map(allPromotions, (promotion, i) => {
				if (($.inArray(promotion.template, this.noItemsTemplates) === -1)) {
					return promotion;
				}
			});

			// all promotions that not connected to items
			var noItemsPromotions = $.map(allPromotions, (promotion, i) => {
				if ($.inArray(promotion.template, this.noItemsTemplates) > -1) {
					return promotion;
				}
			});

			// all promotions that not connected to items and not allowed with other promotions
			var noItemsAndNotAllowedWithOtherPromotions = $.map(noItemsPromotions, (promotion, i) => {
				if (promotion.allowWithOtherPromotions !== 1) {
					return promotion;
				}
			});

			// if ther is no promotions that not in noItemsAndNotAllowedWithOtherPromotions do not filter promotions
			if (noItemsAndNotAllowedWithOtherPromotions.length < 1) {
				return allPromotions;
			}

			// try 3 variations of promotions combination and take the best for the customer
			// 1. use all manual promotions
			// 2. use only items manual promotions
			// 3. use only no items (sale) manual promotions
			var variationsResults = {};

			// define variation 1
			let variation1 = new PositiveTS.Promotions.Engine(this.hasCustomer);
			variation1.setManualPromotions(allPromotions);

			// define variation 2
			let variation2 = new PositiveTS.Promotions.Engine(this.hasCustomer);
			variation2.setManualPromotions(itemsPromotions);

			// define variation 3
			let variation3 = new PositiveTS.Promotions.Engine(this.hasCustomer);
			variation3.setManualPromotions(noItemsPromotions);

			// calc variation 1
			let promotionObject = variation1.calculatePromotionsForSale(sale, saleItems)
			variationsResults[1] = variation1.calcuateSaleTotalAmount(sale, saleItems);
			
			// calc variation 2
			promotionObject = variation2.calculatePromotionsForSale(sale, saleItems)	
			variationsResults[2] = variation2.calcuateSaleTotalAmount(sale, saleItems);
				
			
			promotionObject = variation3.calculatePromotionsForSale(sale, saleItems)
			variationsResults[3] = variation3.calcuateSaleTotalAmount(sale, saleItems);
			
			// Now we have all the promotions variations total amount results in variationsResults
			// look for the best variation index

			var minTotalAmountIndex = 1;
			for (var i = 2; i <= 3; i++) {
				if (variationsResults[i] > variationsResults[minTotalAmountIndex]) {
					minTotalAmountIndex = i;
				}
			}
	
			// return the best variation promotions
			switch (minTotalAmountIndex) {
				case 1:
					return allPromotions;
				case 2:
					return itemsPromotions;
				case 3:
					return noItemsPromotions;
			}
		};

		public setManualPromotions(promotions) {
			this.manualPromotions = promotions;
		};

		public hasMoreManualPromotionToChose(sale, saleItems, allowWithOtherPromotions) {
			var aThis = this;

			let promotions = aThis.getAvilableManualPromotionsForSale(sale, saleItems)
			
			if (!allowWithOtherPromotions) {
				return !aThis.isAllPromotionsExistInManualPromotions(promotions);
			}
			else {
				var promotionsAllowedWithOther = promotions.filter((promo) => { return Boolean(promo._data.allowWithOtherPromotions)});
				return !aThis.isAllPromotionsExistInManualPromotions(promotionsAllowedWithOther);
			}
			
		}

		private isAllPromotionsExistInManualPromotions(promotions) {
			var found = false;

			for (var i = 0; i < promotions.length; i++) {
				for (var j = 0; j < this.manualPromotions.length; j++) {
					if (promotions[i].code == this.manualPromotions[j].code) {
						found = true;
						break;
					}
				}

				if (!found) {
					return false;
				}

				found = false;
			}

			return true;
		}





		private preprocessSaleItems(saleItemsToProcess) {
			saleItemsToProcess = this.filterSaleItemsWithDiscount(saleItemsToProcess);

			for (var i = 0; i < saleItemsToProcess.length; i++) {
				// Get the sale item
				var saleItem = saleItemsToProcess[i];

				// Extract the promotions from the item of the sale item
				var extractedPromotions = this.extractPromotionsFromItem(saleItem.item);
				var promoBuy = extractedPromotions.promoBuy;
				var promoGet = extractedPromotions.promoGet;

				this.saleItemBuyPromotions[saleItem.id] = promoBuy;

				this.addPromotionsToAllPromotionsCodesArray(promoBuy);
				this.addPromotionsToAllPromotionsCodesArray(promoGet);

				this.addSaleItemToPromotionsBuyArray(saleItem, promoBuy);
				this.addSaleItemToPromotionsGetArray(saleItem, promoGet);

				this.addPotentialPromotionsForSaleItem(saleItem, promoBuy, promoGet);
			}
		};

		private filterSaleItemsWithDiscount(saleItems) {
			// Ignore saleItems that have item discount not from promotions
			var i = saleItems.length - 1;
			while (i > 0) {
				if (saleItems[i].discountType !== PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL && saleItems[i].getPromotionCode === -1) {
					saleItems.splice(i, 1);
				}
				i = i - 1;
			}

			return saleItems;
		}

		private extractPromotionsFromItem(item:Storage.Entity.Item) {

			function onlyUnique(value, index, self) {
	    	return self.indexOf(value) === index;
			}

			// Extract the promotions
			var promoBuy = [];
			if (item.promoBuy != null) {
				promoBuy = item.promoBuy.split('&');
			}

			var promoGet = [];
			if (item.promoGet != null) {
				promoGet = item.promoGet.split('&');
			}

			return {
				'promoBuy': promoBuy.filter(onlyUnique),
				'promoGet': promoGet.filter(onlyUnique)
			};
		}

		private addPromotionsToAllPromotionsCodesArray(promotionCodes) {
			$.each(promotionCodes,(index, promotionCode) => {
				if (this.allPromotionsCodes.indexOf(promotionCode) === -1) {
					this.allPromotionsCodes[this.allPromotionsCodes.length] = promotionCode;
				}
			});
		}

		private addSaleItemToPromotionsBuyArray(saleItem, promoBuy) {
			for (var j = 0; j < promoBuy.length; j++) {
				var currentPromoBuy = promoBuy[j];
				if (!(currentPromoBuy in this.promotionsBuy)) {
					this.promotionsBuy[currentPromoBuy] = [];
				}
				this.promotionsBuy[currentPromoBuy][this.promotionsBuy[currentPromoBuy].length] = saleItem;
			}
		}

		private addSaleItemToPromotionsGetArray(saleItem, promoGet) {
			for (var j = 0; j < promoGet.length; j++) {
				var currentPromoGet = promoGet[j];
				if (!(currentPromoGet in this.promotionsGet)) {
					this.promotionsGet[currentPromoGet] = [];
				}
				this.promotionsGet[currentPromoGet][this.promotionsGet[currentPromoGet].length] = saleItem;
			}
		}

		private addPotentialPromotionsForSaleItem(saleItem, promoBuy, promoGet) {
			// --- START: For Table -----------------------------------------------
			// Add the item to the promotions arrays
			for (var j = 0; j < promoBuy.length; j++) {
				this.saleItemsPotentialPromotions[this._generateUniqueKey(saleItem, promoBuy[j], 'buy')] = {
					saleItemID: saleItem.id,
					rowNumber: saleItem.rowNumber,
					itemCode: saleItem.itemCode,
					promotionCode: promoBuy[j],
					buyGet: 'buy',
					promotionName: 'inactive',
					quantity: saleItem.quantity,
					unitPrice: saleItem.unitPrice,
					isCheapest: false,
					priceForAllItemsInPromotion: 0,
					quantityForAllItemsInPromotion: 0,
					discountType: null,
					discountAbsoluteValue: 0,
					discountPrecentValue: 0,
					isPromotionGiven: false,
					alreadyUsedForFixed: false
				};
			}

			// Add the item to the promotions arrays
			for (j = 0; j < promoGet.length; j++) {
				this.saleItemsPotentialPromotions[this._generateUniqueKey(saleItem, promoGet[j], 'get')] = {
					saleItemID: saleItem.id,
					rowNumber: saleItem.rowNumber,
					itemCode: saleItem.itemCode,
					promotionCode: promoGet[j],
					buyGet: 'get',
					promotionName: 'inactive',
					quantity: saleItem.quantity,
					unitPrice: saleItem.unitPrice,
					isCheapest: false,
					priceForAllItemsInPromotion: 0,
					quantityForAllItemsInPromotion: 0,
					discountType: null,
					discountAbsoluteValue: 0,
					discountPrecentValue: 0,
					isPromotionGiven: false,
					alreadyUsedForFixed: false
				};
			}
			// --- END: For Table -----------------------------------------------
		}


}}}
