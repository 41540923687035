module PositiveTS {
  export module Components {
    export module SelfSelectCustomer {

      export function getComponent() {
        return {
          template: JST.selfSelectCustomer(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          components: {selfServicePage: SelfServicePage.getComponent()},
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {setCurrentStep} = selfServicePaymentStore;

            const {selectedPaymentMethod} = Pinia.storeToRefs(selfServicePaymentStore);
            return {setCurrentStep,selectedPaymentMethod}
            
          },
          methods: {
            continueToPayment(){
              if(this.customerName){
                Service.DedicatedTo.setSaleJsonData(this.customerName)
                if(jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowCustomerNameAfterPaymentMethod)){
                  this.setCurrentStep(this.selectedPaymentMethod)
                }else{
                  this.setCurrentStep("SelfSelectPaymentMethod")
                }
                return
              }
            },
            selectMethod(methodName:string) {

              this.setCurrentStep(methodName);
              // this.goToNextStep();
            },

            setInput(event) {
              this.$refs.SimpleKeyboardInstance.setNewInput(event.currentTarget)
            },
            updateVal(text) {
              let continueBtn = document.getElementsByClassName("hg-button-continue")[0];
              if(text.trim()){
                continueBtn.classList.add("continue-enabled");
              }
              else{
                continueBtn.classList.remove("continue-enabled");
              }
              this.customerName = text
            }
           
          },
          data: function() {
            return {
              customerName: "",
              layout: {  default: [
                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                "/ ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 \\",
                "\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3",
                "\u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . {shift}",
                "{continue} {space}"
              ],
              shift: [
                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                "Q W E R T Y U I O P { } {caps-fill}",
                'A S D F G H J K L : "',
                "Z X C V B N M < > ? {shift}",
                "{continue} {space}"
              ],
              caps: [
                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                "q w e r t y u i o p { } {caps}",
                'a s d f g h j k l : "',
                "z x c v b b m < > ? {shift}",
                "{continue} {space}"
              ]
            },
              display: {
                '{continue}': i18next.t("pos.continueToPayment"),
                '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
                '{shift}': '<i class="far fa-globe"></i>',
                '{caps}': '<i class="fa-regular fa-up"></i>',
                '{caps-fill}': '<i class="fa-solid fa-up"></i>'
              }
            }
          },
          mounted() {
            Vue.watch(() => Pinia.languageManagerStore.currentLang, (newValue, oldValue) => {
              this.display ={'{continue}': i18next.t("pos.continueToPayment"),
              '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
              '{shift}': '<i class="far fa-globe"></i>',
              '{caps}': '<i class="fa-regular fa-up"></i>',
              '{caps-fill}': '<i class="fa-solid fa-up"></i>'}
              this.$refs.SimpleKeyboardInstance.setDisplay(this.display)
          });
            this.$refs.SimpleKeyboardInstance.setLayout(this.layout)
            this.$refs.SimpleKeyboardInstance.setDisplay(this.display)
            this.$refs.SimpleKeyboardInstance.setCustomFunction(this.continueToPayment)
            this.$refs.SimpleKeyboardInstance.setNewInput(this.$refs.key1)

            // let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
            // this.leftAmount = totals.totalAmount - totals.totalPaid;
          },


        }
      }
    }
  }
}