module PositiveTS {
  export module Components {
    export module SelectDinersNumberDialog {
      const dialogSelector = "select-diners-number-dialog"

      function initData() {
        return {
          amount: '',
          restaurantNewPOS: jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS),
          fields: initFields()
        }
      }

      function initFields() {
        let fields = {
          'amount': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'amount'
          }
        }
        return fields;
      }

      export function create() {

        let component = {
          template: JST.selectDinersNumberDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            open(data:any = {}) {
              this.cleanData();
              $(document).unbind('keypress');
              this.amount = posVC.sale.diners ? posVC.sale.diners.toString() : '';
      
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;

                this.$nextTick(() => {
                  this.$refs.amount.focus();
                })
              })
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            cancel() {
              this.resolveFunc(null);
              return this.close();
            },
            async confirmAndClose() {
              if (posUtils.isBlank(this.amount)) {
                this.amount = 1;
              }

              if (isNaN(this.amount) || this.amount < 1) {
                return this.showError(i18next.t("selectDinersNumberDialog.invalidNumberOfDiners"));
              }

              this.amount = parseInt(this.amount);

              if (this.amount > 9) {
                let result = await app.showAlertDialog({
                  header:  i18next.t('payAttention'),
                  content: i18next.t("selectDinersNumberDialog.aboveNineDines"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: false,
                });

                
                if(result == "cancel"){
                  return;
                }
              }

              await this.setSaleDiners();
              this.resolveFunc(this.amount);
              return this.close();
            },
            async exitOnEscape() {
              this.amount = 1;
              await this.setSaleDiners();
              this.resolveFunc(this.amount);
              return this.close();
            },
            async setSaleDiners() {
              posVC.sale.diners = this.amount;
              await posVC.saleUpdated();
            },
            onKeyTapped(key) {
              this.keyPadHanlder(key, this.fields["amount"], this, 3);
            },
            clearTapped() {
              this.amount = 0;
            },
            showError(errStr) {
              let aThis = this;
              app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                () => {
                  this.$refs.amount.focus();
                }, null)
            }
          },
          data: initData,
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }
        }

        VueApp.component('select-diners-number-dialog', component)

      }
    }
  }
}
