module PositiveTS {
    export module Components {
        export module VerticalScroll {
            export function getComponent() {
                let component = {
                    template: JST.VerticalScroll(),
                    mixins: [Mixins.scrollableMixin, Mixins.itemButtonMenuMixin],
                    components: { scrollable: Scrollable.getComponent() },
                    data() {
                        return {
                            /** @type {Array<Types.RestaurantMenuButton>} } */
                            currentMenusAndItemsToDisplay: [],
                            /** @type {Array<Types.RestaurantMenuButton>} } */
                            initialMenusToDisplay: [],
                            initalMenuItems: [],
                            backMenu: null,
                            lastSubMenuSelected: null,
                            isSubMenuTab: false,
                            level: 0,
                        };
                    },
                    async created() {
                        this.initialMenusToDisplay = await this.LoadMenu();
                        this.currentMenusAndItemsToDisplay = this.initialMenusToDisplay;
                    },
                    async mounted() {
                        this.initialMenusToDisplay = await this.LoadMenu();
                        this.currentMenusAndItemsToDisplay = this.initialMenusToDisplay;
                    },
                    setup(){
                        const posStore = Pinia.usePosStore();
                        const {activeMenuName} = Pinia.storeToRefs(posStore);

                        const globalStore = Pinia.useGlobalStore();
                        const {defaultImages,effectiveMigvanId,usePictures} = Pinia.storeToRefs(globalStore);
                        const globalStoreProps = {defaultImages,effectiveMigvanId,usePictures}

                        return {...globalStoreProps,activeMenuName}
                    },
                    watch:{
                        async effectiveMigvanId(newVal,oldVal){
                                if(newVal != oldVal ){
                                    this.initialMenusToDisplay = await  this.LoadMenu();
                                    this.currentMenusAndItemsToDisplay = this.initialMenusToDisplay;
                                }
                            }
                    },
                    methods: {

                        pictureUrl(item: Types.MenuButton) {
                            let image = this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_MENU];
                            if (item.pictureUrl) {
                                image = item.pictureUrl;
                            }
                            return image;
                        },
                        subMenuTabClicked(subMenu: Types.RestaurantMenuButton, k) {
                            if (subMenu.subMenus?.length > 0) {
                                this.level++;
                                switch (this.level) {
                                    case 1:
                                        this.backMenu = this.initialMenusToDisplay;
                                        this.lastSubMenuSelected = k;
                                        break;
                                    case 2:
                                        this.backMenu = this.initialMenusToDisplay[this.lastSubMenuSelected];
                                        break;
                                    case 3:
                                        this.backMenu = this.initialMenusToDisplay[this.lastSubMenuSelected].subMenus.find(menu => menu.menuName == subMenu.menuName);
                                        break;
                                }

                                this.isSubMenuTab = true;
                                this.currentMenusAndItemsToDisplay = subMenu.subMenus;

                                Pinia.posStore.setDefaultMenu(subMenu.items);
                                Pinia.posStore.setDefaultMenuName(subMenu.menuName);
                            }
                            Pinia.posStore.setActiveMenu({ items: subMenu.items, name: subMenu.menuName });
                        },
                        backToMenuTabClicked() {
                            this.level--;
                            if (this.level == 0) { // back to inital level
                                this.isSubMenuTab = false;
                                this.lastSubMenuSelected = null;
                                this.currentMenusAndItemsToDisplay = this.initialMenusToDisplay;
                                Pinia.posStore.setDefaultMenu(this.initalMenuItems);
                                Pinia.posStore.setDefaultMenuName('');
                                Pinia.posStore.setActiveMenu( {
                                    items: this.initalMenuItems,
                                    name: ''
                                });
                            } else { // back to parent menu level
                                this.currentMenusAndItemsToDisplay = this.backMenu.subMenus;
                                Pinia.posStore.setActiveMenu( {
                                    items: this.backMenu.items,
                                    name: this.backMenu.menuName
                                });
                            }


                        },

                        async LoadMenu() {
                            let orderByDesc = (a, b) => (posUtils.isBlank(b) === false && a < b ? 1 : -1);
                            let orderByAsc = (a, b) => (posUtils.isBlank(b) === false && a < b ? -1 : 1);
                            let arr: Array<Types.RestaurantMenuButton> = [];
                            let topLvlItemsAndMenus: Array<Types.MenuButton> = await this.getTopLevelMenuItems(this.isRoshemet);
                            this.initalMenuItems = topLvlItemsAndMenus.filter(btn => btn.isItem);
                            Pinia.posStore.setDefaultMenu(this.initalMenuItems);
                            Pinia.posStore.setDefaultMenuName('');
                            let menus = topLvlItemsAndMenus.filter(btn => !btn.isItem && btn.isPrimaryMenuItem).sort(orderByDesc);
                            for (const menu of menus) {
                                let menuItemsAndSubMenus: Array<Types.MenuButton> = await this.getPrimaryMenuItems(menu);
                                let menuItems = menuItemsAndSubMenus.filter(btn => btn.isItem).sort(orderByAsc);
                                let restaurantMenu: Types.RestaurantMenuButton = {
                                    menuName: menu.name,
                                    pictureUrl: menu.pictureUrl,
                                    items: menuItems
                                };
                                let subMenus = menuItemsAndSubMenus.filter(btn => !btn.isItem && btn.isSecondaryMenuItem).sort(orderByAsc);
                                restaurantMenu.subMenus = [];
                                for (const subMenu of subMenus) {
                                    let menuItemsAndSubMenus: Array<Types.MenuButton> = await this.getSecondaryMenuItems(subMenu);
                                    let subMenuItems = menuItemsAndSubMenus.filter(btn => btn.isItem).sort(orderByAsc);
                                    let restaurantSubMenu: Types.RestaurantMenuButton = {
                                        menuName: subMenu.name,
                                        pictureUrl: subMenu.pictureUrl,
                                        items: subMenuItems,
                                        subMenus: []
                                    };
                                    let thirdSubMenus = menuItemsAndSubMenus.filter(btn => !btn.isItem && btn.isThirdMenuItem).sort(orderByAsc);
                                    restaurantMenu.subMenus.push(restaurantSubMenu);
                                    for (const subSubMenu of thirdSubMenus) {
                                        let thirdMenuItems: Array<Types.MenuButton> = await this.getThirdMenuItems(subSubMenu);
                                        thirdMenuItems = thirdMenuItems.filter(btn => btn.isItem).sort(orderByAsc);
                                        let thirdMenu: Types.RestaurantMenuButton = {
                                            menuName: subSubMenu.name,
                                            pictureUrl: subSubMenu.pictureUrl,
                                            items: thirdMenuItems
                                        };
                                        restaurantSubMenu.subMenus.push(thirdMenu);
                                    }
                                }
                                arr.unshift(restaurantMenu);

                            }
                            return arr;
                        },
                    }
                }
                return component;
            }
        }
    }
}