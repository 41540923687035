module PositiveTS {
	export module Storage {
		export module Entity {
			export class PriceList extends WasmEntity {

				constructor() {
					let meta = {
						name: 'PriceList',
						fields: {
							code: "INT",
							name: "TEXT",
							isCustomerClub: "BOOL",
							noDiscount: "BOOL",
							isHakafa: "BOOL",
							companyId: "INT",
							customerGroupId: "INT",
							customerId: "INT",
							hakafaCustomerId: "INT",
							stores: "TEXT",
						},
						unique: ['code']
					}
					super(meta)
				}

				
			}
		}
	}
}
