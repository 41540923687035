 module PositiveTS {
  export module Service {
    export module Check {

      export class CheckRecord {
        amount: any;
        bank: any;
        branch: any;
        account: any;
        number: any;
        date: any;
        paymentCount: any;
        manualApproval: any;
        ern: any;
        timeStamp: any;
        aryCheckNumber: any;

        constructor(fieldsData) {
          this.amount = fieldsData.amount;
          this.bank = fieldsData.bank;
          this.branch = fieldsData.branch;
          this.account = fieldsData.account;
          this.number = fieldsData.number;
          this.date = fieldsData.date;
          this.paymentCount = fieldsData.paymentCount;
          this.manualApproval = fieldsData.manualApproval;
          this.ern = fieldsData.ern;
          this.timeStamp = fieldsData.timeStamp;
          this.aryCheckNumber = fieldsData.aryCheckNumber;
        }
      }

      export class CheckPayment {
        private _CHECK_DAY_VALID_UNTIL = 10;
        private _DEFALUT_MAX_ONE_CHECK_DAYS = 30;
        private _ERN_CONFIRMATION_NUMBER_LEN = 11;

        doErnTransactionIfRequired(fieldsData): Promise<any> {
          if (serviceCheckErn.isErnSupported()) {
            if (serviceCheckErn.isTooMuchBadCallRequestsAndNoConfirmationNumber(fieldsData)) {
              return Promise.reject(new Error(i18next.t("checkErnTooManyRejectsMustConfirmationNumber")));
            } else {
              return serviceCheckErn.actionTran(fieldsData);
            }


            //.then if error allow ignore
          } else {
            return Promise.resolve();
          }
        };

        addPaymentToDataObject(dataObj, fieldsData): void {
          var paymentCount = parseInt(fieldsData.paymentCount);

          fieldsData.timeStamp = (new Date()).getTime();

          if (paymentCount === 1) {
            dataObj.push(fieldsData);
            return;
          } else {
            this._getSplitedToPaymentsFieldsData(fieldsData, dataObj);
          }
          return;
        };

        isCheckConfirmationNumberValid(confirmationNumber:string):boolean {
          if (!serviceCheckErn.isErnSupported()) {return true;}
          if (!confirmationNumber) {return true;}

          var isLenAndNumber: boolean = Service.CheckTypes.check([
            { type:'int', param: Number(confirmationNumber),  len: this._ERN_CONFIRMATION_NUMBER_LEN},
          ]).isValid;
          if (!isLenAndNumber) {return false;}

          // ern confirmation is <10 dig number><1 char control dig>
          const CONFIRMATION_CONTENT_LEN:number = this._ERN_CONFIRMATION_NUMBER_LEN - 1;

          var constrolCar = confirmationNumber.substr(CONFIRMATION_CONTENT_LEN);
          var strToValidate = confirmationNumber.substr(0,CONFIRMATION_CONTENT_LEN);

          var _allDigSum:number = this._getAllDigSum(strToValidate);
          if (_allDigSum%10 !== parseInt(constrolCar)) {return false;}

          return true;
        }

        private _getAllDigSum(strToValidate:string):number{
          var sum=0;
          for (var i=0;i<strToValidate.length;i++){
            sum+=parseInt(strToValidate[i]);
          }
          return sum;
        }

        isCheckDateValid(dt: Date, paymentCount: number): { isValid: boolean, msg: string } {
          var _return = {
            isValid: true,
            msg: ""
          };
          var blDidNotPassThe10OfMonth = (this._getLatestCheckDateAllowed().getTime() - dt.getTime() >= 0);
          var isParameterMaxCheckDaysValid = this._isParameterMaxCheckDaysValid(dt);

          if (!isParameterMaxCheckDaysValid) {
            _return = {
              isValid: false,
              msg: i18next.t('checkPaymentDateMaxMandatoryError', {COUNT: session.pos.parameterMaxCheckDays})
            };
          }


          if (paymentCount > 1 && !blDidNotPassThe10OfMonth) {
            _return = {
              isValid: false,
              msg: i18next.t("checkPaymentDateMaxMandatoryTo10Error")
            };
          }

          var diffDays = new Service.DateDiff(new Date(), dt).diffDays;

          if (paymentCount > 1 &&
            diffDays > 1) {
            _return = {
              isValid: false,
              msg: i18next.t("checkManyPaymentsMustBeTodayOrTommorow")
            };
          }
 
          if (diffDays < -90) {
            _return = {
              isValid: false,
              msg: i18next.t("checkDateUntillThreeMonthOld")
            };
          }

          var maxOneCheckDays = session.pos.maxOneCheckDays || this._DEFALUT_MAX_ONE_CHECK_DAYS;
          if (paymentCount === 1 && diffDays > maxOneCheckDays) {
            _return = {
              isValid: false,
              msg: i18next.t("checkDateSingleCheckError")
            };
          }

          return _return;
        };

        private _isCheckValidationRequired(): boolean {
          return serviceCheckErn.isErnSupported();
        };

        private _getLatestCheckDateAllowed(): Date {
          var _returnDate = new Date();
          if (_returnDate.getDate() >= this._CHECK_DAY_VALID_UNTIL) {
            _returnDate.setMonth(_returnDate.getMonth() + 1);
          }
          _returnDate.setDate(this._CHECK_DAY_VALID_UNTIL);

          return _returnDate;
        };

        private _isParameterMaxCheckDaysValid(dt): boolean {
          var parameterMaxCheckDays = session.pos.parameterMaxCheckDays;
          var today = new Date();
          if ((new Service.DateDiff(today, dt)).diffDays > parameterMaxCheckDays) {
            return false;
          } else {
            return true;
          }
        };

        private _addFirstPayment(originalFieldData, firstPaymentAmount, byRefReturn): void {
          var objFirstPayment = new CheckRecord(originalFieldData);
          objFirstPayment.paymentCount = 1;
          objFirstPayment.amount = firstPaymentAmount;

          if (originalFieldData.aryCheckNumber) {
            objFirstPayment.number = originalFieldData.aryCheckNumber[0];
          }

          byRefReturn.push(objFirstPayment);
        };

        /*
         * Description:
         *  Increment date in format of dd/mm/yy by one month
         */
        private _addMonthToIlFormattedDate(strDt): string {
          var dt = posUtils.parseIlDate(strDt);
          dt.setMonth(dt.getMonth() + 1);
          return Service.StrUtils.dateToDdmmyySlashed(dt);
        };

        private _addOtherPayments(originalFieldData, eachOtherPaymentAmount, totalOtherPaymnetCount, byRefReturn): void {
          var objNewPayment;
          var previousPayment;
          for (var i = 0; i < totalOtherPaymnetCount; i++) {
            previousPayment = byRefReturn[i];
            objNewPayment = new CheckRecord(originalFieldData);
            objNewPayment.paymentCount = 1;
            objNewPayment.amount = eachOtherPaymentAmount;

            if (originalFieldData.aryCheckNumber) {
              objNewPayment.number = PositiveTS.Service.StrUtils.lpad(originalFieldData.aryCheckNumber[i + 1], 9);
            } else {
              objNewPayment.number = PositiveTS.Service.StrUtils.lpad(parseInt(previousPayment.number) + 1, 9);
            }

            objNewPayment.date = this._addMonthToIlFormattedDate(previousPayment.date);
            byRefReturn.push(objNewPayment);
          }

        };

        /*
         * Calculate first check object, and each other check objec
         * add array to ByRef dataObj
         */
        private _getSplitedToPaymentsFieldsData(fieldsData, dataObj): void {
          var originalFieldData = new CheckRecord(fieldsData);
          var totalAmount = session.fixedFloat(originalFieldData.amount);
          var totalPaymentCount = parseInt(originalFieldData.paymentCount);
          var totalOtherPaymnetCount = totalPaymentCount - 1;

          var totalAmountPerPayment: any = totalAmount / totalPaymentCount

          var eachOtherPaymentAmount = session.fixedFloat(parseInt(totalAmountPerPayment));
          // floating point arithmetics
          var toIntTotalAmount = posUtils.toInt(totalAmount);
          var toIntEachOtherPaymentAmount = posUtils.toInt(eachOtherPaymentAmount);
          var firstPaymentAmount = posUtils.fromInt(toIntTotalAmount - toIntEachOtherPaymentAmount * totalOtherPaymnetCount);

          this._addFirstPayment(originalFieldData, firstPaymentAmount, dataObj);
          this._addOtherPayments(originalFieldData, eachOtherPaymentAmount, totalOtherPaymnetCount, dataObj);


        };



      }
    }
  }
}

declare var _checkPayment: PositiveTS.Service.Check.CheckPayment;
_checkPayment = new PositiveTS.Service.Check.CheckPayment();
