<script setup lang="ts">
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { globalStore } from '@/Store/Pinia';
import PositKeyboard from '@m/components/shared/keyboard.vue';
import GenericKeyboardDialog from '@m/components/shared/genericKeyboardDialog.vue';
import { ManagerApproval } from '@m/service';
import { GenericKeyboardDialogExtraButton } from '@m/types';
import { isNullOrUndefined } from '@shared/src/Utils';

const { t } = useI18n();


const el = ref(null);
const permissions = ref([]);
const rawInput = ref('');
const errorMessage = ref('');
const resolve = ref(null);
const reject = ref(null);
const isAndroid = Boolean(session.isAndroid);
const isRestaurantNewPOS = Boolean(PositiveTS.Service.Dalpak.isRestaurantNewPOS())

const identificationManagerByFingerPrintOnly = computed(() => jsonConfig.getVal(jsonConfig.KEYS.identificationManagerByFingerPrintOnly));
const isManagerApprovalBySms = computed(() => jsonConfig.getVal(jsonConfig.KEYS.isManagerApproveActionBySMS));
const label = computed(() => identificationManagerByFingerPrintOnly.value ? t('detailedManagerPromptOnlyFingerPrint') : t('detailedManagerPrompt'));
const inputPlaceHolder = computed(() => isManagerApprovalBySms.value ? t('managerApprovalBySmsPlaceholder') : t('managerApprovalPlaceholder'))
const showFingerPrintButton = computed(() => !globalStore.isRoshemet);
const showManagerApprovalSms = computed(() => !globalStore.isRoshemet && !identificationManagerByFingerPrintOnly.value && isManagerApprovalBySms.value);
const showOkButton = computed(() => !identificationManagerByFingerPrintOnly.value)


const resetData = () => {
    permissions.value = [];
    rawInput.value = '';
    errorMessage.value = '';
    resolve.value = null;
    reject.value = null;
}
const close = (result = null) => {
    el.value.close();
    if(result){
        resolve.value(result);
    }else{
        reject.value(null);
    }
    
    resetData();
}
const cancel = () => close(null)
const open = async (permissionsArg?: string[]) => {
    resetData();
    el.value.open();
    return new Promise((res,rej) => {
        permissions.value = permissionsArg;
        resolve.value = res;
        reject.value =rej
    });
}
const fingerprint = async () => {
    try {
        let verifiedFingerPrint = await ManagerApproval.byFingerprint(permissions.value);
        if (verifiedFingerPrint) {
            close(verifiedFingerPrint)
        }

    } catch (err) {
        console.log(err);
    }
};
const swipe = async () => {
    try {
        let cardNumber = await ManagerApproval.swipeCard();
        if (posUtils.isBlank(cardNumber)) {
            return;
        }
        rawInput.value = cardNumber;
        await validateApprovalAndClose();

    } catch (err) {
        console.log(err);
        app.showErrorAlertDialog(t('errorOccurred'));
    }
};
const requestSmsCode = async () => {
    try {
        await ManagerApproval.requestSmsCodes(permissions.value);
    } catch (err) {
        console.log(err);
        app.showErrorAlertDialog(t('errorOccurred'));
    }
};
const validateApprovalAndClose = async () => {
    if (ManagerApproval.isSmsCodesSent) {
        let smsCodeValue = rawInput.value;
        let managerApprovedTheAction = ManagerApproval.verifySmsCode(smsCodeValue);
        if (!posUtils.isBlank(managerApprovedTheAction)) {
            try {
                let employee = new PositiveTS.Storage.Entity.Employee();
                let employees = await employee.fetchByStoreAndEmployeeID(managerApprovedTheAction.store_id, managerApprovedTheAction.employee_id)
                ManagerApproval.resetSmsCodesData();
                return close(employees[0]);
            } catch (err) {
                console.log(err);
            }
        } else {
            app.showAlertDialog(t('managerApprovalGenericError'));
        }
    }

    let magneticCard = rawInput.value;
    if (posUtils.isBlank(magneticCard)) {
        return;
    }

    try {
        errorMessage.value = '';
        app.showLoadingMessage(t('loading'));
        if (!isManagerApprovalBySms.value) {
            let employee = new PositiveTS.Storage.Entity.Employee();
            let employees = await employee.fetchByStoreAndMagneticCardAndPermissions(session.pos.storeID, magneticCard, permissions.value);
            if (posUtils.isPresent(employees)) {
                return close(employees[0]);
            } else {
                errorMessage.value = i18next.t("managerApprovalGenericError");
            }
        } else {
            errorMessage.value = i18next.t("managerApprovalGenericError");
        }
    } catch (error) {
        console.log(error);
        app.showErrorAlertDialog(t('errorOccurred'));
    } finally {
        app.hideLoadingMessage();
    }
};

const extraButtons = computed((): Array<GenericKeyboardDialogExtraButton> => {
    return [
        {
            showButton: showFingerPrintButton.value,
            label: t('ett.fingerPrint'),
            icon: 'fa-light fa-fingerprint',
            action: fingerprint,
        },
        {
            showButton: showManagerApprovalSms.value,
            label: isRestaurantNewPOS ? t('ett.toManager') : t('ett.managerApprovalSms'),
            icon: 'fa-light fa-message-sms',
            action: requestSmsCode,
        }
    ];
});

defineExpose({
    open,
    close
})
</script>

<template>
    <GenericKeyboardDialog ref="el" :cancel-button-label="t('cancel')" :cancel-button-action="cancel"
        :show-ok-button="showOkButton" :ok-button-label="t('ok')" :ok-button-action="validateApprovalAndClose"
        :extra-buttons="extraButtons">
        <template v-slot:title>
            <div class="title">{{ t("managerApproval") }}</div>
        </template>
        <template v-slot:content>
            <div class="content">
                <div class="text-center text-lg">
                    {{ label }}
                </div>
                <div class="form-row flex gap-x-4">
                    <input :placeholder="inputPlaceHolder" type="password" autocomplete="off" v-model.trim="rawInput" ref="swipeCardRef" class="p2-pro:!text-base p2-lite:!text-base" />
                    <font-awesome-icon icon="fad fa-scanner-keyboard" class="h-9"
                        @click.stop="swipe"></font-awesome-icon>
                </div>
                <div class="text-center text-lg text-danger-default" v-if="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
        </template>
        <template v-slot:keyboard>
            <div v-if="!isAndroid" class="left-1/2 transform -translate-x-1/2 absolute bottom-0">
                <PositKeyboard v-model="rawInput" />
            </div>
        </template>
    </GenericKeyboardDialog>
</template>
