/// <reference path="../../dts/references.d.ts" />
/// <reference path="../Modules/salePromotionsService.ts" />
/// <reference path="../../Helper/SaleItem.ts" />
/// <reference path="../../Service/pluxee/pluxeeVoucher.ts"/>
class VoucherTypeDialog {
  private ui: any;
  private initialized: boolean = false;
  private itemsVoucherMap: { [key:string]:number };
  private itemsVoucherMapWithoutPromoOrDiscount: { [key:string]:number };
  private _voucherRowUnfiltered: any[] = [];
  private _quickInputSelector = "#voucher-type-input";
  private _usePromise;
  private resolvePromise: ((value?: any) => void) | null = null;

  constructor() {
    if (!this.initialized) {
      $(() => {
          var self = this;
          this.ui = $("#voucher-type").dialog({
              autoOpen: false,
              modal: true,
              dialogClass: 'positive-dialog',
              title: i18next.t('voucher.title'),
              width: '90%',
              resizable: false,
              closeOnEscape: false,
              draggable: false,
              create: function(event, ui) { self.init(true); }
          });
          this.initialized = true;
        });
    }
  }

  close(selectedVoucher = null) {
    this.ui.dialog('close')
    //$(document).unbind('keydown', posUtils.ignoreEvent);
    $('#voucher-type-continue').unbind('click');
    if (this._usePromise && this.resolvePromise) {
      if (selectedVoucher != null) {
        this.resolvePromise(selectedVoucher);
      } else {
        this.resolvePromise(null);
      }
    } else {
      posPaymentVC.open();
    }
    
  }

  async openWithPromise(vouchers):Promise<any>{
    this._usePromise = true;

    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve;  
      this.open(vouchers);
    });
  }

  async open(vouchers = null) {
    this.ui.dialog('open');
    if (session.pos.parameterSpecialItemBlockVouchers &&
       PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems,posVC.Specialitems) && !jsonConfig.getVal(jsonConfig.KEYS.allowGiftCardPaymentWithVoucher)) {
       app.showAlert({
        header: i18next.t('error'),
        content: i18next.t('specialitemAndVoucherNotAllowed'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      },  () => {
        // Go back
        this.close()
      }, null);
    }

    try {
      await this.init();
      // $(document).bind('keydown',posUtils.ignoreEvent);
      if(vouchers){
        this._voucherRowUnfiltered = vouchers;
      } else {
        // Fetch all the available voucher types
        let voucher = new PositiveTS.Storage.Entity.Voucher();
        let returnedArray = await voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID)
      
        this._voucherRowUnfiltered = returnedArray;
      }
      this.getVoucherList(this._voucherRowUnfiltered)
    
    } catch(e) {
      PositiveTS.Service.Logger.error(e)
      // --- Fetching vouchers failed
      // Tell the user
      app.showAlert({
        header: i18next.t('error'),
        content: 'Fetching voucher tyeps failed. Please contact support.',
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      }, null, null);
      console.error('Fetching voucher tyeps failed. Please contact support.');
    };

    // Bind the continue button to its listener
    $('#voucher-type-continue').click(() => { this.continue() });
  }

  private async init(firstInit = false) {
    $("#ui-id-7.ui-dialog-title").text(i18next.t('voucher.title'));
    $("#voucher-type-second-title").text(i18next.t('voucher.selectVoucherType'));
    $("#voucher-type-back .ui-button-text").text(i18next.t('back')); 
    $("#voucher-type-continue .ui-button-text").html(i18next.t('continue'));
    $('#voucher-type-tablematrix').attr("data-header",i18next.t('voucher.selectVoucherType'));
    $('#voucher-type-tablematrix').attr("data-empty-message",i18next.t('voucher.dontExist'));
    $('#voucher-type-tablematrix .ui-tablematrix-header').text(i18next.t('voucher.selectVoucherType'));
    $('#voucher-type-tablematrix .ui-tablematrix .ui-tablematrix-row-empty td').text(i18next.t('voucher.dontExist'));

    let tableView = $('#voucher-type-tablematrix').tablematrix();
    tableView.empty()
    if (!firstInit) {
      await this.createItemsVoucherMap()
    }
    this._bindFilterChange();
  }

  private _bindFilterChange(){
    new PositiveTS.UI.UiInput(this._quickInputSelector);
    $(this._quickInputSelector).val('');
    $(this._quickInputSelector).unbind("keyup focus");
    $(this._quickInputSelector).bind("keyup focus", () => {
      var filterString: string = $(this._quickInputSelector).val().toString();
      this._filterCurrentTable(filterString);
    });
  }

  private _filterCurrentTable(filter: string) {
    var vouchers = []
    for (let i = 0; i < this._voucherRowUnfiltered.length; i++) {
      let voucher = this._voucherRowUnfiltered[i];
      let searchIn = voucher.typeID + voucher.name;
      if (searchIn.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
        vouchers.push(voucher)
      }
    }
    this.getVoucherList(vouchers)
  }

  private async createItemsVoucherMap() {
    this.itemsVoucherMap = {};
    let vouchers = [];
    if (session.pos.isRoshemet) {
      let dbVouchers = await PositiveTS.Storage.Entity.Voucher.getCache();
      vouchers = dbVouchers.map(a => a.typeID);
    }
    this.itemsVoucherMapWithoutPromoOrDiscount = {};
    for (let saleItem of posVC.saleItems) {
      if (!session.pos.isRoshemet) {
        vouchers = saleItem.item.vouchers.split("&");
      }
      for (let voucher of vouchers) {
        if (!this.itemsVoucherMap[voucher]) {
          this.itemsVoucherMap[voucher] = 0;
          this.itemsVoucherMapWithoutPromoOrDiscount[voucher] = 0;
        }
         //calculate price after promotions
        this.itemsVoucherMap[voucher] += saleItem.quantity * salePromotions.calcuateItemPriceAfterPromotions(saleItem, posVC.sale, posVC.saleItems);
        if (!saleItemHelper.doesItemContainDiscountOrPromotion(saleItem)) {
          this.itemsVoucherMapWithoutPromoOrDiscount[voucher] += saleItem.quantity * saleItem.unitPrice;
        }
      }
    }
  }

  private getRelevantItemsAmount(voucherTypeId:string, allowKefel:boolean):number {
    var map;
    if (allowKefel) {
      map = this.itemsVoucherMap;
    }
    else {
      map = this.itemsVoucherMapWithoutPromoOrDiscount;
    }
    if (typeof (map[voucherTypeId]) !== 'undefined') {
      return map[voucherTypeId];
    }
    else {
      return 0
    }
  }

  private inDateRange(currentVoucher):boolean {
    var now = new Date();

    // filter by dates
    if (!posUtils.isNullOrUndefinedOrEmptyString(currentVoucher.fromDate)) {
      try {
        var fromDate = new Date(currentVoucher.fromDate);

        if (fromDate > now) {
          return false
        }
      } catch (err) {
        // Do Nothing, if date not valid it like not exist
      }
    }
    if (!posUtils.isNullOrUndefinedOrEmptyString(currentVoucher.toDate)) {
      try {
        var toDate = new Date(currentVoucher.toDate);
        toDate.setHours(23, 59, 59, 999);

        if (toDate < now) {
          return false
        }
      } catch (err) {
        // Do Nothing, if date not valid it like not exist
      }
    }
    return true
  }

  private getVoucherList(vouchers) {
    // Get the table view for later use
    var tableView = $('#voucher-type-tablematrix').tablematrix();

    // Empty the table so we can be sure we are displaying only the latest results
    tableView.empty();

    // Check that there are payment types in the results

    if (vouchers.length === 0) {
      return
    }

    var selectedRowId = "";

    var isSaleSpecialItem = PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems,posVC.Specialitems);
    
    var amountPaidByVoucherTypeId = PositiveTS.Service.VoucherPayment.getAmountPaidByVoucherTypeId();

    let isBeengoSupported = Pinia.globalStore.isBeengoSupported;
    for (let i = 0; i < vouchers.length; i++) {

      var currentVoucher = vouchers[i];
      var relevantItemsAmount = posVC.getTotalAmount();

      if (!currentVoucher.isActive){continue;}

      if (PositiveTS.Service.PluxeeService.Voucher.isPluxeeCibusVoucherAndUserNotAuthorized(currentVoucher)){continue;}

      if ( currentVoucher.typeID === PositiveTS.Service.VALU_CARD_VOUCHER_TYPE_ID) {
        continue;
      }

      const vouchersToSkip = [ 
        "תן ביס",
        "סיבוס", "פלאקסי",
        "goodi", 
        "banx",
        "יעד שריג",
        "InterService",
        PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH
      ]

      if (vouchersToSkip.includes(currentVoucher.name)) {
        continue;
      }

      if (currentVoucher.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT) {
        continue;
      }

      if ((jsonConfig.getVal(jsonConfig.KEYS.mishlohaVoucherNumber) && currentVoucher.typeID == jsonConfig.getVal(jsonConfig.KEYS.mishlohaVoucherNumber)) ||
          (jsonConfig.getVal(jsonConfig.KEYS.mishlohaCreditCardVoucherNumber) && currentVoucher.typeID == jsonConfig.getVal(jsonConfig.KEYS.mishlohaCreditCardVoucherNumber))) {
          continue;
      }

      if (currentVoucher.hideFromVoucherScreen) {
        continue;
      }

      if (PositiveTS.Service.Hakafa.getVoucherID() == currentVoucher.typeID && !PositiveTS.Service.Hakafa.hasCustomer()) {
        continue;
      }

      if (PositiveTS.Service.CustomerClub.CustomerClubVoucherType == currentVoucher.typeID) {
        continue;
      }

      if (PositiveTS.Service.Hakafa.getVoucherID() == currentVoucher.typeID && Pinia.globalStore.hakafaDisabled) {
        continue;
      }

      if (PositiveTS.Service.Hakafa.getVoucherID() == currentVoucher.typeID &&Pinia.globalStore.hakafaCustomer && Pinia.globalStore.hakafaCustomer.cannot_pay_by_hakafa) {
        continue;
      }

      if (PositiveTS.Service.PaiditConvertService.PaiditExternalCreditCardVoucherCode == currentVoucher.typeID) {
        continue;
      }

      if (PositiveTS.Service.PaiditConvertService.PaiditExternalCashMachineVoucherCode == currentVoucher.typeID) {
        continue;
      }

      if (PositiveTS.Service.PaiditConvertService.PaiditVoucherCode == currentVoucher.typeID) {
        continue;
      }

      if (jsonConfig.getVal(jsonConfig.KEYS.beengoVoucherId) == currentVoucher.typeID  &&
          !isBeengoSupported) {
            continue;
      }


      if (!Boolean(currentVoucher.allowKefel)) {
        let saleHelper = PositiveTS.Helper.SaleHelper;
        if (saleHelper.doesSaleHasPromotion(posVC.sale) || saleHelper.doesSaleHasDiscount(posVC.sale)) {
          continue
        }
      }

      // filter by minimum amount
      if (!posUtils.isNullOrUndefinedOrEmptyString(currentVoucher.minimumAmount)) {
        relevantItemsAmount = this.getRelevantItemsAmount(currentVoucher.typeID, Boolean(currentVoucher.allowKefel));
        if (Number(currentVoucher.minimumAmount) > relevantItemsAmount) {
          continue
        }
      }

      if (!posUtils.isNullOrUndefinedOrEmptyString(currentVoucher.maxPercent)) {
          let amountToPay = amountPaidByVoucherTypeId[currentVoucher.typeID] || 0
          let voucherValue = 0.1 //minimum value that can be paid with voucher
          if (!posUtils.isNullOrUndefinedOrEmptyString(currentVoucher.value)) {
              voucherValue = Number(currentVoucher.value)
          }

          if (Boolean(currentVoucher.isDiscountVoucher)) {
            relevantItemsAmount += amountToPay
          }

          amountToPay += voucherValue
          if (relevantItemsAmount > 0) {
            if (((amountToPay / relevantItemsAmount)*100) > Number(currentVoucher.maxPercent)) {
              continue
            }
          }
      }

      if (!this.inDateRange(currentVoucher)) {
        continue
      }
      //TODO: removed and kept code for refence - we will need to add a parameter for this in the future
      // if (isSaleSpecialItem && vouchers[i].isDiscountVoucher) {
      //   continue
      // }

      let blockVouchersCodes = PositiveTS.Service.BlockItemPaymentMethod.getBlockVouchersCodes(posVC.saleItems)
      if (blockVouchersCodes.includes(Number(currentVoucher.typeID))){
        continue
      }


      // Add a row with the payment type name
      var id = this.uiAddRow(vouchers[i], tableView)

      if (selectedRowId == "") {
          selectedRowId = id;
      }
    }

    //Select the first row
    tableView.selectRow(selectedRowId);
  }

  private uiAddRow(voucher, tableView) {
    var imgIcon = '';
    if (voucher.isDiscountVoucher) {
      imgIcon = '<img src="' + images_path + 'pos/gift.png" style="padding-right: 6px;" />';
    }


    var theRowId = tableView.addCell(voucher.typeID + " - " + posUtils.sanitizeHtml(voucher.name) + imgIcon)

    $("#" + theRowId).data('id', voucher);
    $("#" + theRowId).click(() => { this.continue() });

    return theRowId;
  }

  private async continue() {

    // Get the selected row
    var theRowId = $('#voucher-type-tablematrix').tablematrix().getSelectedCell();

    // If there are no selected rows, finish
    if (theRowId.length == 0) {
      return;
    }

    //Get the user selection
    var userSelection = $("#" + theRowId).text();
    const voucher = await PositiveTS.Service.VoucherPayment.setSelectedVoucherEx($("#" + theRowId).data('id').typeID);

    this.close(voucher)
  }
};

declare var voucherTypeDialog: VoucherTypeDialog;
voucherTypeDialog = new VoucherTypeDialog();
