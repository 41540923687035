module PositiveTS {
export module Dialogs {
export class EmployeeChangePasswordDialog {

    constructor() { }
    private init() { }
    open() { 
        Pinia.componentsStore.openComponent( {componentName:"changePasswordDialog", args: []});
    }
    close() { }

}}}
declare var changePasswordDialog:PositiveTS.Dialogs.EmployeeChangePasswordDialog;
changePasswordDialog = new PositiveTS.Dialogs.EmployeeChangePasswordDialog();