module PositiveTS {
export module Service {

export class SaleProxy {
  
  static LOCAL_TYPE = 1;
  static REMOTE_DATA_TYPE = 2;

  proxyType:number
  saleID:string
  remote_sale:any
  remote_all_level_sale:any
  store_id:string
  pos_device_id:string
  invoice_number:number
  invoice_type:number

  constructor(data) {
    this.proxyType = data.type;
    this.saleID = data.saleID;
    this.remote_sale = data.remote_sale;
    this.remote_all_level_sale = data.remote_all_level_sale;
    this.store_id = data.store_id;
    this.pos_device_id = data.pos_device_id;
    this.invoice_number = data.invoice_number;
    this.invoice_type = data.invoice_type;
  };
  
  
  
  
  public async load() {
    
    
    switch (this.proxyType) {
      case SaleProxy.LOCAL_TYPE:
        let saleModel = new PositiveTS.Storage.Entity.Sale();
        let sales = await saleModel.fetchSaleItemAndPaymentsBySaleIDs([this.saleID])
        try {
            return sales[0];
        } catch (err) {
          console.error(err);
          return null;
        }
  
      case SaleProxy.REMOTE_DATA_TYPE:
        if (this.remote_sale == null) {
          return null;
        }
  
        try {
          return Service.Sale.ToStorageTypeSale(this.remote_sale);
        } catch (err) {
          console.error(err);
          return null;
        }
    };
  };
  
  async loadAllLevels():Promise<any> {
    
    let aThis = this;
    if(session.pos.hasFlights && this.proxyType == SaleProxy.REMOTE_DATA_TYPE) {
      let sale = await appDB.sales.where('invoiceSequence').equals(this.invoice_number).first();
      if(sale.syncStatus != 2 || !PositiveTS.Reachability.isOnline) {
        this.proxyType = SaleProxy.LOCAL_TYPE;
        this.saleID = sale.id;
      }
    }
    
    switch (this.proxyType) {
      case SaleProxy.LOCAL_TYPE:
        let model = new PositiveTS.Storage.Entity.Sale();
        let result = await model.fetchSaleItemAndPaymentsAndChildrensBySaleIDs([this.saleID])
        return [result[this.saleID]];
  
      case SaleProxy.REMOTE_DATA_TYPE:
  
        if (this.remote_all_level_sale == null) {
          
          let result = await Service.Sale.getSaleFromServer(this.store_id, 
                                                            this.pos_device_id, 
                                                            this.invoice_number, this.invoice_type);
          
          aThis.remote_all_level_sale = Service.Sale.ToStorageTypeSale(result);
          return [aThis.remote_all_level_sale];
        }

        break;
    };
  };
  

}}}