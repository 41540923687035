module PositiveTS {
   export module Service {

    interface WoltFailedDelivery {
      url: string;
      error: string;
    }
     export class WoltDeliveryAPIService extends BaseDeliveryAPIService {

      protected apiData;

      constructor(protected apiConfig) {
        super(new WoltDeliveryConvertService());
        apiConfig.timeRunFetchJob = 30000
        this.apiData = apiConfig
      }

      public serviceName() {
        return 'Wolt'
      }

      protected login(): Promise<void> {
        throw new Error("Method not implemented.")
      }

      protected getTokenLocalStorageKey () {
        throw new Error("Method not implemented.")
      }

      protected getLocalToken () {
        return this.apiData.woltApiToken
      }

      public async getOpenOrders(): Promise<any[]> {

        if(WoltDeliveryAPIService.lastWoltOrder < WoltDeliveryAPIService.lastPull){
          return [];
        }
        try {
          let res = await FetchReq.jsonReq(`/wolt_orders?venue_id=${this.apiData.woltApiVenueId}`, 'GET');

          if(res.result.length == 0)
            return [];

          const now = moment().valueOf();
          let result = [];
          let failedDeliveries:WoltFailedDelivery[] = [];

          for(let invoice of res.result) {
              let response = await this.makeHttpRequest(invoice.url, 'GET', null,  {"accept": "application/json", "Content-Type": "application/json", 'WOLT-API-KEY' : invoice.api_key});
              if(response.status === 200 && response.body){
                Filelog.log("wolt-orders", JSON.stringify({'request': {'url': invoice.url, 'venue_id': invoice.venue_id}, result: response}))
                if (response){
                  result.push(posUtils.jsonParse(response.body));
                }
              }else{
                failedDeliveries.push({url: invoice.url, error: response.body});
              }
          }
          this.setFailedDeliveries(failedDeliveries);
          this.setLastPull(now);

          return result;
        } catch (error){
            PositiveTS.Service.Delivery.failedDeliveriesLogger(error,null,"wolt-orders",Delivery.FailedDeliveryType.InvalidData)
            PositiveTS.Service.Logger.error(error);
        }
      }

      public async setOrderStatus(order: any, status: any, data: any) {
        return FetchReq.jsonReq(`/wolt_orders/${order.id}`, 'PUT');
      }

      private setLastPull (lastPull) {
        localStorage.setItem('WoltDeliveryAPIService.lastPull', lastPull)
      }

      private setFailedDeliveries (failedDeliveries: WoltFailedDelivery[]) {
        if(failedDeliveries.length > 0){
          for(let failedDelivery of failedDeliveries){
            let orderId = failedDelivery.url?.split("/")?.pop() || null;
            PositiveTS.Service.Delivery.failedDeliveriesLogger(failedDelivery.error,orderId,"wolt-orders",Delivery.FailedDeliveryType.HttpError, false);
            PositiveTS.Service.Logger.error(failedDelivery.error + " - " + orderId);
          }
      }
      }

      static get lastPull () {
        return parseInt(localStorage.getItem('WoltDeliveryAPIService.lastPull'))|| moment().add(-5, 'minutes').valueOf()
      }

      static get lastWoltOrder () {
        return parseInt(localStorage.getItem('lastWoltOrder')) || moment().valueOf()
      }
     }
   }
}

