module PositiveTS {
export module Service {
export module PunchCard {

    const punchCardVoucherId = "666";
    const action_pay_card = "pay_card";
    const action_load_card = "load_card"
    const json_data_punch_card_key ="punch_card_arr"

    export function isPunchCardSale(sale: PositiveTS.Storage.Entity.Sale){
        
        return Boolean( JSON.parse(sale.jsondata)[json_data_punch_card_key] );
    }

    export function removeLoadPunchCardEntryFromSale(sale: PositiveTS.Storage.Entity.Sale, saleItem:Storage.Entity.SaleItem):void{
        if (!isPunchCardSale(sale)){return;};
         
        let jsonData = JSON.parse( sale.jsondata );
        let ary:_punchCardDetails[] = jsonData[json_data_punch_card_key] || []
        ary = ary.filter( row =>{ return row.row_number != saleItem.rowNumber})
        if (ary.length === 0){
            delete jsonData[json_data_punch_card_key];
        } else {
            jsonData[json_data_punch_card_key] = ary;
        }

        sale.jsondata = JSON.stringify(jsonData);
    }

    export function payPunchCard(){
        $(document).unbind('keypress');

        Pinia.globalStore.setPunchCardMode(true);
        return Pinia.componentsStore.openComponent( {componentName:"punchCardInput", args: []})
        .then( (cardNumber:string) =>{
          return _payPunchCard(cardNumber,posVC.sale)
        })
        .catch((e) => {
                posVC.initializeKeyboard();
          app.hideLoadingMessage()
          if (e) {
            if (e.message === app.userCancelledGlobalMessage ||
                e === app.userCancelledGlobalMessage ){
            
                return app.showManagerApprovalDialog()
                    .then(function (managerEmployee) {
                        Pinia.globalStore.setPunchCardMode(false);
                    })
                    .catch(function (error) {
                        reopenPayPunchCardIfRequired()
                    })
              
            }
            app.promiseShowAlert({
              header: i18next.t('error'),
              content: e.message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
            .then(()=>{
                reopenPayPunchCardIfRequired();
            })
            console.error(e);
          }
        });
      }
    
    export function getPunchCardItemById(id:number, fromDate, toDate):Promise<any>{
        let url = `/punch_card_items/${id}/?from_date=${encodeURIComponent(fromDate)}&to_date=${encodeURIComponent(toDate)}`
        
        return FetchReq.jsonReq(url, "GET", undefined)
      }

    function _getPriceZarhanByPunchItemId(punchCardItemId:number):Promise<number>{
        return Service.WasmDB.promiseSql(`select pricezarhan from item where id=${punchCardItemId}`)
        .then(result =>{
            return result[0].priceZarhan
        })
    }

    export function loadCardPunchCard(sale: PositiveTS.Storage.Entity.Sale, saleItem:Storage.Entity.SaleItem) {

        return _promptForChargeCard()
        .then( (cardNumber) =>{
            return _postChargePunchCard( cardNumber, saleItem.item)
        } ) 
        .then( (punchCardDetails:_punchCardDetails)=>{
            saleItem.itemDescription = `${saleItem.itemDescription}-${punchCardDetails.card_number.substr(-4)}`
            saleItem.itemDescription += " " + 
                                    i18next.t('nEntriesAdded', { n: punchCardDetails.punch_quantity.toString() })
            punchCardDetails.row_number = saleItem.rowNumber;
            if (saleItem.item.punchCardPriceByQuantity){
                //set priceZarhan
                return _getPriceZarhanByPunchItemId(saleItem.item.punchCardItemId)
                .then( (priceZarhan:number) =>{
                    saleItem.unitPrice=priceZarhan * punchCardDetails.punch_quantity;
                    return punchCardDetails;
                })
                
            }
            return punchCardDetails;

                
            
        })
        .then( (punchCardDetails:_punchCardDetails)=>{
                _setSaleJsonData( sale , punchCardDetails )
        })
        .catch((e:any) => {
            app.hideLoadingMessage()
            if (e) {
              if (e.message !== app.userCancelledGlobalMessage && 
                e !== app.userCancelledGlobalMessage){
                app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: e.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
              }

              console.error(e);
            }
            throw e
          });

    }

    export function isPayPunchCardSale(sale: PositiveTS.Storage.Entity.Sale):boolean{
        let punchCardDetails:_punchCardDetails[] = getPunchCardSaleJonData(sale);
        if (punchCardDetails && 
            punchCardDetails.filter( row=>{return row.action === action_pay_card}).length > 0){
            return true;
        } else {
            return false;
        }

    }

    export function getPunchCardSaleJonData(sale: PositiveTS.Storage.Entity.Sale):_punchCardDetails[]{
        return JSON.parse( sale.jsondata )[json_data_punch_card_key];
    }

    export function reopenPayPunchCardIfRequired() {
        if ( localStorage.getItem("is_punch_card_mode") && jsonConfig.getVal(jsonConfig.KEYS.isPunchCardSelfService) ){
            return payPunchCard();    
        }
        else {
            Pinia.globalStore.setPunchCardMode(false);
        }
    }

    export function showBalance(cardNumber:string):Promise<boolean>{
        return _getBalance( cardNumber )
        .then( response => {


            app.hideLoadingMessage();
            if (response.success) {
              return Pinia.componentsStore.openComponent( {componentName:"punchCardListBalance", args: [ response.response.punch_card_items, cardNumber]})
              .then( ()=>{
                return response.success;
              });
            } else {
                return app.promiseShowAlert({
                    header: "",
                    content: response.response,
                    hideCancelButton: true,
                    noHandleEnterEscape: true,
              }).then( ()=>{
                return response.success;
              });
            }

        })

    }

    function _getBalance( cardNumber:string):Promise< {success:boolean, response: any}>{
        return _punchCardFetchGet(cardNumber)
        .then(response => {
            if (!response.success){  throw new Error(response.description) ; }

            return {success:true, response:response}    
        })
        .catch( (e:Error)=>{
            return {success:false, response: e.message}
        })
    }

    function _punchCardFetchGet(cardNumber:string):Promise< {
                                        success: boolean,
                                        description: string,
                                        punch_card_id: number,
                                        punch_card_items:[{"current_balance":number,
                                                        "item_code": string,
                                                        "item_name": string,
                                                        "item_id":number,
                                                        "punch_card_id":number,
                                                        "punch_card_item_id":number,
                                                        "punch_item_code":number,
                                                        "punch_item_id":number,
                                                        "punch_item_name":string
                                                    }]
                                    }>
    {
        return _punchCardFetch(cardNumber, "GET")
        .then( response =>{
            if (!response.response.ok) {  throw new Error(i18next.t("error")) ; }
            return response.result;
        })
    }    

    async function _payPunchCard(cardNumber:string, sale: PositiveTS.Storage.Entity.Sale) {
        
        let availablePunchCards = await _punchCardFetchGet(cardNumber);
        console.log(availablePunchCards)

        if ( availablePunchCards.success == false){
            throw new Error(availablePunchCards.description);
        }


        let itemId:number = -1;        
        let currentBalance = 0;
        if (availablePunchCards.punch_card_items.length === 1){
            itemId = availablePunchCards.punch_card_items[0].item_id  
            currentBalance =  availablePunchCards.punch_card_items[0].current_balance           
        } else if (availablePunchCards.punch_card_items.length > 1) {
            let itemIds = availablePunchCards.punch_card_items.map( row=> {
                return {code:row.item_id.toString(), 
                        name: `${row.punch_item_name} - ${row.current_balance} ניקובים`
                    };
            });
            let selectedItem = await Pinia.componentsStore.openComponent( {componentName:"punchCardGenericSelect", args: [itemIds]});
            itemId = parseInt(selectedItem.code)
            currentBalance = availablePunchCards.punch_card_items.filter( row=>{return row.item_id === itemId})[0].current_balance;
        } else {
            throw new Error("no punch card item assigned, system error") 
        }

        if (currentBalance === 0) {
            throw new Error("אין יתרה בכרטיסיה");
        }

        app.showLoadingMessage("רושם כניסה")
        let punchCardDetails:_punchCardDetails = await _postPayPunchCard( cardNumber, itemId)
        app.hideLoadingMessage();

        let saleItem = await _addItemByCode(punchCardDetails.punch_item_code)
              
        punchCardDetails.row_number = saleItem.rowNumber;
        punchCardDetails.card_number = cardNumber;
        _setSaleJsonData( sale , punchCardDetails )

        await _createPayWithPunchCardAndClose(saleItem);
        
        
    }

    async function _addItemByCode(code:string){
        let iObj = new PositiveTS.Storage.Entity.Item();
        let itemsAndBarcodes = await iObj.searchByBarcode(code)
       
        var item = itemsAndBarcodes[0].item;
        var itemBarcode = itemsAndBarcodes[0].itemBarcode;
        // Create new sale item entity
        var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
        saleItem.saleID = posVC.sale.id;
        saleItem.rowNumber = posVC.getRowNumber();
        
        
        await posVC.persistNewSaleItem(saleItem);
        return saleItem;
    }

    function _promptForChargeCard(){
        return inputDg.open(
            {
             header: 'טען כרטיסיה',
             description: 'אנא העבר כרטיסיה',
             inputPlaceHolder: 'אנא העבר כרטיסיה',
             showCancelButton: true,
             emptyErrorMessage: 'חובה להזין מספר כרטיס',
             isShowKeyboard: false,
             inputValidator:  (value) => {
               if (value) {
                 return true;
               }
       
               return false;
             }
          })

    }

    async function _createPayWithPunchCardAndClose(saleItem: Storage.Entity.SaleItem):Promise<any>{
        var focusField = 'amount';
        app.showLoadingMessage("רושם כניסה")
        let payment = new PositiveTS.Storage.Entity.SalePayment();
        payment.method = Storage.Entity.SalePayment.METHOD_VOUCHER;
        payment.amount = saleItem.unitPrice;
        payment.saleID = saleItem.saleID;
        payment.data = "[]";
        
        //TODO: need to verify that this logic is correct - we calculate the totals before 
        //      we add the payment to the salePayments array
        let calcuateSaleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments)
        posVC.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE;
        posVC.sale.totalAmount = calcuateSaleTotals.totalAmount;
        //TODO: update INV
        posVC.sale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
        posVC.sale.createdAt = PositiveTS.DateUtils.fullFormat();
        posVC.sale.totalQuantity = String(calcuateSaleTotals.totalQuantity);
        posVC.sale.totalDiscount = calcuateSaleTotals.totalSaleDiscount;
        posVC.sale.totalVatableAmount = session.store.containVat ? 0 : calcuateSaleTotals.totalVatableAmount;

        posVC.salePayments.push(payment);

        await PositiveTS.Service.FullSale.posVCSale.IncrementSeqAndPersist()
        //clone the sale so we can print it after we close the real sale
        let fullSale = PositiveTS.Service.FullSale.posVCSale.clone();
        fullSale.salePayments.push(payment);
        
        posVC.cleanSale(); 
        app.hideLoadingMessage()
        await Printing.Invoice.printInvoice( fullSale.sale, fullSale.saleItems, fullSale.salePayments, true,[])  
        if(jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)){
            await Printing.Invoice.printInvoice( fullSale.sale, fullSale.saleItems, fullSale.salePayments, false,[])  
        }
        await Service.LogicalPrinterBonPrint.printBons(fullSale);
        await posVC.loadSale()
        posVC.afterSaleLoaded()
            
        PositiveTS.Service.PunchCard.reopenPayPunchCardIfRequired();
      }


    function _setSaleJsonData( sale: PositiveTS.Storage.Entity.Sale , punchCardDetails:_punchCardDetails ):void {
        let jsonData = JSON.parse( sale.jsondata );
        jsonData[json_data_punch_card_key] = jsonData[json_data_punch_card_key] || []
        jsonData[json_data_punch_card_key].push(punchCardDetails);
        sale.jsondata = JSON.stringify(jsonData);
    }

    interface _punchCardDetails {
        item_code: string
        punch_card_id: number
        punch_quantity: number
        item_id?: number
        action: string
        success: boolean
        current_balance: number
        punch_item_code: string
        punch_card_item_id: number
        row_number: number
        card_number:string
        transaction_id?: number
    }

    function _punchCardFetch(cardNumber:string, method: string, reqObj?:{item_id:number}):any {
        return FetchReq.jsonReq(`/punch_card/${cardNumber}`, method, reqObj)
    }

    function _postPayPunchCard( cardNumber:string, itemId: number):Promise<_punchCardDetails>{
        return _punchCardFetch(cardNumber, "PUT",{item_id: itemId})
        .then(response => {
            if (!response.response.ok) {  throw new Error("שגיאת מערכת") ; }
            if (!response.result.success){  throw new Error(response.result.description) ; }

          return {
            success: response.result.success,
            item_code: response.result.item_code,
            item_id: response.result.item_id,
            punch_card_id: response.result.punch_card_id,
            punch_quantity: response.result.punch_quantity,
            current_balance: response.result.current_balance,
            punch_item_code: response.result.punch_item_code,
            punch_card_item_id: response.result.punch_card_item_id,
            transaction_id: response.result.transaction_id,
            action: action_pay_card
          }         
        });
    }


    function _postChargePunchCard( cardNumber:string, item:PositiveTS.Storage.Entity.Item):Promise<_punchCardDetails>{
        return _punchCardFetchGet(cardNumber)
        .then(response => {
          
          if (!response.success){  throw new Error(response.description) ; }

          let result:_punchCardDetails = {
            item_code: item.code,
            punch_card_id: response.punch_card_id,
            punch_quantity: item.punchQuantity || 0,
            item_id: parseInt(item.id),
            action: action_load_card,
            success: response.success,
            current_balance: undefined,
            punch_item_code: undefined,
            punch_card_item_id: undefined,
            row_number: undefined,
            card_number: cardNumber
        };
          
          
          if (result.punch_quantity === 0){
                return inputDg.open({
                    header: 'כרטיסיה',
                    description: 'הזן כמות ניקובים לטעינה',
                    inputPlaceHolder: 'אנא הזן כמות ניקובים לטעינה',
                    showCancelButton: true,
                    emptyErrorMessage: 'חובה להזין כמות ניקובים לטעינה',
                    inputValidator:  (value) => {
                        if (!session.isNumber(value) || Number(value) < 0) {
                            inputDg.optionsIn.emptyErrorMessage = 'חובה להזין ניקובים לטעינה';
                            return false;
                        }
                        return true;
                    }
                })
                .then( (amount:string) => {
                    result.punch_quantity = parseInt(amount);
                    return result;
                })
          }
          return result;

        });
    }

}}}
