module PositiveTS {
  export module Service {
    export module Filelog {
      var url = '/filelogs';
      var withMailUrl = '/filelogs/log_and_mail';

      export async function log(log: string, data: string, withMail = false, type: 'info'|'error'|'debug'|'warn' = 'info'): Promise<boolean> {
        try {

          datadogLogs.logger[type](data,{service:log, pos_device_id: session?.pos?.deviceID}); 

          if (withMail || session?.pos?.isCaveret) {
            let logUrl = withMail ? withMailUrl : url;
            data = `${moment().toISOString(true)}:${data}`
            let response = await FetchReq.jsonReq(logUrl, 'post', {
              log: log,
              data: data
            })
  

            if (response.response.ok && response.result.success) {
              if (withMail && !response.result.mail_success) {
                Logger.error(`${log} - Failed to send mail `)
                console.error(`${log} - ${data}`)
                return false
              }

              return true
            }
            else {
              Logger.error(`${log} - Failed to log transaction`)
              console.error(`${log} - ${data}`)
              return false
            }
          }

          return true;
        }
        catch{
          Logger.error(`${log} - Failed to log transaction`)
          console.error(`${log} - ${data}`)
        }
      }

      export async function logAndMail(logName: string, data: string): Promise<boolean> {
        return await log(logName, data, true);
      }

    }
  }
}