module PositiveTS {
export module Service {
export module ItemInventory {
  export function getColorSize(itmCode:string){
    var _sql = `select * from (select distinct size, color from itembarcode where code = '${itmCode}')`

    return PositiveTS.Service.WasmDB.promiseSql(_sql)
    .then( (message)=>{
      var msg = [];
      for (var i=0;i< message.length;i++){
        msg.push({color:message[i].color, size: message[i].size });
      }

      return msg;
    })
  }

  export function getInventory(itmCode:string, color:string, size: string){
    if (!color) {color = " ";}
    if (!size) {size = " ";}
    
    return FetchReq.jsonReq(`/item_inventory/${encodeURIComponent(itmCode)}?color=${encodeURIComponent(color)}&size=${encodeURIComponent(size)}`,'get')
    .then((response) => {
      if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
     if (!response.result.success) {
       throw new Error(response.result.msg)
     }
     return response.result.msg;
    });
  }

  export async function getPriorityInventoryItem (itemCode: string) {
    const storeId = session.pos.storeID
    const endPointUrl = `/LOGPART/?$filter=STATDES eq 'פעיל' and SHOWINWEB eq 'Y' and PARTNAME eq '${itemCode}'&$expand=PARTBALANCE_SUBFORM`
    let data = []
    let errors = []
    
    try{
      const res = await PositiveTS.Service.PriorityService.request('GET', endPointUrl)
      if (!_.isEmpty(res.data)){
        let row = res.data[0]
        if (!_.isEmpty(row['PARTBALANCE_SUBFORM'])){
          const stores = await PositiveTS.Storage.Entity.Store.getCache();
          const findStoreName = (storeID) => {
            let store = stores.find((s) => s.storeID == storeID)
            return store && store.storeName ? store.storeName : storeID
          }
          row['PARTBALANCE_SUBFORM'].map((w) => {
            if (w['WARHSNAME'] == storeId){
              data.unshift({
                storeId: w['WARHSNAME'],
                storeName: findStoreName(w['WARHSNAME']),
                storeQty: w['TBALANCE']
              })
            }else{
              data.push({
                storeId: w['WARHSNAME'],
                storeName: findStoreName(w['WARHSNAME']),
                storeQty: w['TBALANCE']
              })
            }
          })
        }else{
          errors.push(i18next.t('itemInventoryDialog.itemInventoryNotFoundDataFromPriority'))
        }
      }else{
        errors.push(i18next.t('itemInventoryDialog.itemInventoryNotFoundDataFromPriority'))
      }
    }catch(e){
      console.error(e)
      errors.push(i18next.t('itemInventoryDialog.itemInventoryGlobalError'))
    }finally{
      return {data, errors}
    }
  }
}}}
