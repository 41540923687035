module PositiveTS {
export module Storage {
export module Entity {
export class ItemGroupItem extends WasmEntity {
	public itemGroupId:number;
	public itemCode:string;
	public itemDesc:string;
	public itemId:number;
	public priceZarhan:number;
	public hasPicture:boolean;
	public color:string;

	constructor() {
		let meta = {
			name: 'ItemGroupItem',
			fields: {
			  itemGroupId: "INT",
				itemId: "INT",
				itemCode: "TEXT",
				itemDesc: "TEXT",
				priceZarhan: "FLOAT",
				sortOrder: "INT",
				hasPicture: "BOOL",
				color: "TEXT",
			},
    	money: ['priceZarhan']
		}
		super(meta)
	};

	static updateItemGroupItemPricesIfNoVatStore(db: any) {
		if(!session.pos || !session.systemSettings || !session.pos.isCaveret) {
			return null;
		}
		if(!session.store || !session.store.containVat) {
			return null;
		}

		let vat = ((Number(session.systemSettings.vat)/100)+1);
		return Promise.resolve(db.exec(`UPDATE ItemGroupItem SET priceZarhan = round(priceZarhan / ${vat}, 2) WHERE priceZarhan > 0`));
	}

}}}} 
