module PositiveTS {
  export module Components {
    export module Directives {
      export module Keyboard {
        export function createDirective() {
          VueApp.directive('keyboard', {
            unmounted(el) {
              // Added to delete the element because vue eats shits when adding element not in template
              // (this is why its not recommended, somthing with the compile is messed up)
              // we should move to an awesome keyboard gruper showed me called SimpleKeyboard
              // this jQuery shit will kill us :*
              // console.debug("unbind keyboard!!!!");
              //***** לשון הרע לא מדבר אליי ***********
              if ($(el).getkeyboard()) {
                $(el).getkeyboard().destroy();
              }
              let hasIcon = $('li#' + el.id + '-keyboard-icon.positive-keyboard-icon')
              if (hasIcon.length) {
                hasIcon.remove()
              }
            },
            mounted: function (el, binding, vNode) {

              if (!$(el)[0].id) {
                console.error("id is missing on keyboard input element \n"
                  + (<HTMLElement>$(el)[0].parentNode).outerHTML + $(el)[0].outerHTML);
                return;
              }
              if ((navigator.userAgent.toLowerCase().indexOf('android') > -1 && typeof (Android) !== "undefined")) {
                return; //do nothing if we are using android
                //TODO: maybe we'll decide later to keep this icon and show the native keyboard programmatically.
              }

              let keyboardIconTemplate = `<li id="${el.id}-keyboard-icon" class="positive-keyboard-icon default-height fad fa-keyboard"></li>`;
              let fontSizeConfig = $(el).data("keyboard-icon-font-size") || null;

              if (fontSizeConfig != null) {
                keyboardIconTemplate = `<li id="${el.id}-keyboard-icon" style="font-size: ${fontSizeConfig};" class="positive-keyboard-icon fad fa-keyboard"></li>`;
              }
              else {
                let offsetHeight = el.offsetHeight;
                if (offsetHeight > 0) {
                  keyboardIconTemplate = `<li id="${el.id}-keyboard-icon" style="font-size: ${offsetHeight}px;" class="positive-keyboard-icon fad fa-keyboard"></li>`;
                }
              }



              var clearIconTemplate = `<img id="${el.id}-clear-icon" class="clear-icon" src="${(<any>window).images_path}clear_big.png" />`;
              let inputEl: any = $(el);
              let keyboardHolder = inputEl;

              if (inputEl.data("keyboard-holder")) {
                keyboardHolder = inputEl.closest(inputEl.data("keyboard-holder"))
              }

              let hasBeenOpenedForFirstTime = false;
              if (inputEl.find('#' + inputEl.id + '-keyboard-icon').length === 0) {
                if (inputEl.data("autoopen") == null) {
                  $(keyboardIconTemplate).insertAfter(keyboardHolder)
                }
                if (inputEl.data("clear") != null) {
                  $(clearIconTemplate).insertAfter(keyboardHolder)
                }
              }

              if (inputEl.getkeyboard() === undefined) {
                var layout;
                if (inputEl.data("layout") != null) {
                  if (inputEl.data("layout") == "text") {
                    layout = "hebrew-qwerty-custom"//"hebrew-qwerty-custom";
                  } else if (inputEl.data("layout") == "numbers") {
                    layout = "only-numbers";
                  }
                } else {
                  layout = "hebrew-qwerty-custom"//"hebrew-qwerty-custom";
                }



                let keyboardOptions = {
                  layout: layout,
                  usePreview: false,
                  autoAccept: true,
                  openOn: 'keyboard-icon-click',
                  position: {
                    of: inputEl.data("open-on-top") ? $('#header') : $('#footer'),
                    my: 'center top',
                    at: 'center bottom',
                  },
                  validate: function (keyboard, value, isClosing) {
                    return true;
                  },
                  initialized: function (e, keyboard, el) { },
                  beforeVisible: function (e, keyboard, el) { },
                  visible: function (e, keyboard, el) { },
                  beforeInsert: function (e, keyboard, el, textToAdd) {
                    return textToAdd;
                  },
                  beforeClose: function (e, keyboard, el, accepted) {
                    // console.debug('beforeClose')
                  },
                  accepted: function (e, keyboard, el) {
                    console.debug('accepted')
                  },
                  canceled: function (e, keyboard, el) {
                    // console.debug('canceled')
                  },
                  restricted: function (e, keyboard, el) {
                    // console.debug('restricted')
                  },
                  hidden: function (e, keyboard, el) {
                    // console.debug('hidden')
                  },
                  change: function (e, keyboard, el) {
                    var event = new Event('input', { bubbles: false })
                    inputEl[0].dispatchEvent(event);
                  }
                }

                if (layout == 'only-numbers') {
                  keyboardOptions['css'] = { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" }
                }
                inputEl.keyboard(keyboardOptions)//.addTyping();
              }

              if (inputEl.data("autoopen") != null) {
                inputEl.click(() => {
                  inputEl.getkeyboard().reveal();
                  $(inputEl).select();
                  return false;
                });
              }

              $(`#${el.id}-clear-icon`).click(() => {
                $(inputEl).val('');
              })

              // Open the keyboard by clicking on the keyboard icon only
              $(`#${el.id}-keyboard-icon`).click(() => {
                inputEl.getkeyboard().reveal();
                return false;
              });

            }
          })
        }
      }
    }
  }
}