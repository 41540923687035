module PositiveTS {
  export module Service {
    export module VoucherPayment {

      export async function deleteVoucherPaymentAndPersist(salePayment, voucherData) {
        try {
          app.showLoadingMessage(i18next.t("posPayment.cancelPay"));
          let eraseFromList = true;
          let voucherPayment = salePayment
          let vouchers = JSON.parse(voucherPayment.data);
          let voucherToEraseIndex = vouchers.findIndex((voucher) => _.isEqual(voucher, voucherData))
          if(voucherToEraseIndex == -1) {
            return { success: false, error: i18next.t('posPayment.voucherNotFound') };
          }

          if (Storage.Entity.Voucher.isPickupOrderVoucherType(voucherData.voucherType)) {
            return { success: false, error: i18next.t('posPayment.cantDeleteOrderVoucher') };
          }

          let smartVoucher = Service.SmartVoucherFactory.createVoucher(voucherData.smartVoucherType);
          if(smartVoucher) {
              let result = await smartVoucher.cancelPayment(voucherData, true);
              if(!result.success) {
                  eraseFromList = false;
                  return result;
              }
          }
          if(eraseFromList) {
              if (voucherPayment.amount >= voucherData.amount) {
                if(!smartVoucher || salePayment.method !== Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT){
                  voucherPayment.amount -= voucherData.amount;
                }    
              }
              vouchers.splice(voucherToEraseIndex, 1);
              voucherPayment.data = JSON.stringify(vouchers);
              if (vouchers.length > 0) {
                await posVC.persistSalePayment(voucherPayment);
              }
              else {
                await posVC.deleteSalePayment(voucherPayment);
                posPaymentVC.updateAmountsIndicators();
              }
              if(smartVoucher){
                await smartVoucher.afterCancelPayment(voucherData, true)
              }
              return { success: true }
          }
        } catch(err) {
          return { success: false, error: err.message}
        } finally {
          app.hideLoadingMessage();
        }
      }

      export async function validateVoucherPayment(voucher, amount, barcode, currentVouchers) {

        if(!voucher){
          return { success: false, error: i18next.t("voucherPaymentVoucherMandatoryError")  };
        }

        if (PositiveTS.Service.icMega.checkIfCurrentSaleIsIcMegaItem() 
          && voucher.smartVoucherType != PositiveTS.Service.SmartVoucher.ICMEGA_HANDLER){
            return {success: false, error: i18next.t(`icMega.canPaymentOnlyByIcMegaVoucher`)}
        }

        let maxAmountAllowed = 500000;
        var isAmountRequiredAndAmountIsZero = posUtils.isBlank(voucher.value) && !Number(amount);
        if (isAmountRequiredAndAmountIsZero) {
          return { success: false, error: i18next.t("voucherPaymentAmountMandatoryError") };
        }

        let maxSaleAmount = Number(jsonConfig.getVal(jsonConfig.KEYS.maxSaleAmount));
        if (maxSaleAmount > 0 && Number(amount) > maxSaleAmount) {
          return { success: false, error: i18next.t('maxSaleAmountIs', {AMOUNT: String(maxSaleAmount)}) };
        }

        if (posUtils.isBlank(voucher.value) && amount > maxAmountAllowed) {
          return { uccess: false, error: `לא ניתן להוסיף שובר בסכום הגדול מ-${maxAmountAllowed}` };
        }

        let result = Service.Hakafa.validateHakafaPayment(voucher, Number(amount), posVC.sale, posVC.saleItems, posVC.salePayments)
        if (!result.valid) {
          return { success: false, error: result.message };
        }

        var isAmountMandatoryString = (!Number(amount) || Number(amount) > 9999);
        if (voucher.smartVoucherType && isAmountMandatoryString) {
          return { success: false, error: i18next.t("voucherPaymentAmountMandatoryError") };
        }

        if(voucher.hasBarcode && (posUtils.isBlank(barcode) || String(barcode) == "0")) {
          return { success: false, error: i18next.t("voucherPaymentBarcodeEmptyMandatoryError") };
        }

        if (voucher.quantityInInventory != "" && voucher.quantityInInventory != "null" && voucher.quantityInInventory != null) {
          if(currentVouchers.filter((v) => v.voucher_type_id == voucher.typeID).length > voucher.quantityInInventory) {
            return { success: false, error: i18next.t("voucherPaymentQuantityInInventoryMandatoryError", {TYPE: voucher.name, MAXIMUM_COUNT: voucher.quantityInInventory}) };
          }
        }

        if (voucher.minimumAmount != "" && voucher.minimumAmount != "null" && voucher.minimumAmount != null && voucher.minimumAmount > 0) {
          let total = currentVouchers.reduce((total, v) => {
            if(v.voucher_type_id == voucher.typeID) {
              total.count++
              if(voucher.isDiscountVoucher) {
                total.sum  +=  v.amount;
              }
            }

            return total;
          }, {count: 0,  sum: 0});
          if (Math.floor((posPaymentVC.totalAmount + total.sum) / Number(voucher.minimumAmount)) <= total.count) {
            return {
              success: false,
              error: i18next.t("voucherPaymentMinimunAmountMandatoryError", {TYPE: voucher.name, MINIMUM_AMOUNT: voucher.minimumAmount})
            }
          }
        }

        if (!posUtils.isNullOrUndefinedOrEmptyString(voucher.maxPercent) && voucher.maxPercent != 'null') {
          var voucherAmount = currentVouchers.reduce((total,  v)  =>  {
            if(v.voucher_type_id == voucher.typeID)  {
              total += parseFloat(v.amount);
            }

            return total;
          }, 0) ;
          voucherAmount += parseFloat(amount);

          var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
          var saleTotalExcludeDiscounts = posPaymentVC.totalAmount + saleTotals.totalDiscount;


          if (voucherAmount / saleTotalExcludeDiscounts * 100 > parseFloat(voucher.maxPercent)) {
              return {
                  success: false,
                  error: i18next.t("voucherPaymentMaxDiscountPrecetageMandatoryError", {TYPE: voucher.name, PRECETAGE: voucher.maxPercent})
              };
          }
        }

        // If voucher has barcode, validate it
        if (!posUtils.isBlank(barcode)) {
          // Do not allow using barcode when offline
          if (!PositiveTS.Reachability.isOnline) {
            if (!(PositiveTS.Service.SmartVoucher.isSmartVoucher(voucher.smartVoucherType) &&
                  PositiveTS.Service.SmartVoucher.worksInOffline(voucher.smartVoucherType))) {
                    return { success: false, error: i18next.t("voucherPaymentBarcodeOfflineMandatoryError") };
            }
          }

          // If praxell, allow same barcode again and do not validate in server
          if (PositiveTS.Service.SmartVoucher.isSmartVoucher(voucher.smartVoucherType)) {
              return { success: true };
          }

          // Check if barcode allready used localy
          for (let i = 0; i < currentVouchers.length; i++) {
              if (currentVouchers[i].voucher_type_id == voucher.typeID && currentVouchers[i].barCode == barcode) {
                  return { success: false, error: i18next.t("voucherPaymentBarcodeUsedLocallyMandatoryError") };
              }          
          }

          app.showLoadingMessage(i18next.t("voucherPaymentBarcodeOnlineValidationLoadingMsg"));

          // Check barcode at server
          // Check barcode at server
          let res = await Service.Voucher.getValidatedVoucherBarcode(
            Pinia.globalStore.selectedVoucherType.typeID, barcode, session.pos.tenantID, session.pos.companyID);

          app.hideLoadingMessage();

          if (res == null) {
            return { success: false, error: 'Unknown' };
          }

          if (!res.success) {
            var error = null;
            if (res.error_code == 1) {
              error = i18next.t("voucherPaymentBarcodeNotExistMandatoryError");
            } else if (res.error_code == 2) {
              error = i18next.t("voucherPaymentBarcodeUsedMandatoryError");
            } else {
              error = 'Unknown';
            }
            return { success: false, error: error };
          }
          return  { success: true };
      } else {
          return  { success: true };
      }
    }

      export function addDummyHakafaCustomerIfCibusOrTenbis(
        sale: Storage.Entity.Sale,
        salePayments: Storage.Entity.SalePayment[]
      ): void {
        if(session.pos.isCaveret) {
          return ;
        }
        let tenbisCibusAmnt = Storage.Entity.SalePayment.getTenbisPluxeeAmount(salePayments);
        let cibusTotalAmount = undefined;
        let tenbisTotalAmount = undefined;
        let goodiTotalAmount = undefined;
        let dtsTotalAmount = undefined;
        let banxTotalAmount = undefined;
        let yaadTotalAmount = undefined;
        let mishlohaTotalAmount = undefined;

        if (tenbisCibusAmnt.tenbisTotalAmount != 0 || tenbisCibusAmnt.cibusTotalAmount != 0 || tenbisCibusAmnt.goodiTotalAmount != 0 || tenbisCibusAmnt.dtsTotalAmount != 0 || tenbisCibusAmnt.yaadTotalAmount != 0) {

          let firstName = ""
          if (tenbisCibusAmnt.cibusTotalAmount != 0) {
            firstName += "פלאקסי";
            cibusTotalAmount = tenbisCibusAmnt.cibusTotalAmount;
          }
          if (tenbisCibusAmnt.tenbisTotalAmount != 0) {
            if (firstName.length > 0) {
              firstName += "+";
            }
            firstName += "תן ביס"
            tenbisTotalAmount = tenbisCibusAmnt.tenbisTotalAmount;
          }

          if (tenbisCibusAmnt.mishlohaTotalAmount != 0) {
            if (firstName.length > 0) {
              firstName += "+";
            }
            firstName += "משלוחה"
            mishlohaTotalAmount = tenbisCibusAmnt.mishlohaTotalAmount;
          }
          if (tenbisCibusAmnt.goodiTotalAmount != 0) {
            if (firstName.length > 0) {
              firstName += "+";
            }
            firstName += "גודי"
            goodiTotalAmount = tenbisCibusAmnt.goodiTotalAmount;
          }

          if (tenbisCibusAmnt.dtsTotalAmount != 0) {
            if (firstName.length > 0) {
              firstName += "+";
            }
            firstName += "DTS"
            dtsTotalAmount = tenbisCibusAmnt.dtsTotalAmount;
          }

          if (tenbisCibusAmnt.banxTotalAmount != 0) {
            if (firstName.length > 0) {
              firstName += "+";
            }
            firstName += "banx"
            banxTotalAmount = tenbisCibusAmnt.banxTotalAmount;
          }

          if (tenbisCibusAmnt.yaadTotalAmount != 0) {
            if (firstName.length > 0) {
              firstName += "+";
            }
            firstName += "יעד"
            yaadTotalAmount = tenbisCibusAmnt.yaadTotalAmount;
          }

          

          addDummyHakafaCustomer(sale, firstName, cibusTotalAmount, tenbisTotalAmount, undefined, goodiTotalAmount, dtsTotalAmount, banxTotalAmount,yaadTotalAmount,mishlohaTotalAmount)
        }
      }


      export function addDummyHakafaCustomer(
        sale: Storage.Entity.Sale,
        firstName: string,
        cibusTotalAmount: number,
        tenbisTotalAmount: number,
        valuTamashTotalAmount: number,
        goodiTotalAmount: number,
        dtsTotalAmount: number,
        banxTotalAmount: number,
        yaadTotalAmount: number,
        mishlohaTotalAmount: number,
      ): void {
        let jsonData = JSON.parse(sale.jsondata) || {};
        jsonData.cibusTotalAmount = cibusTotalAmount;
        jsonData.tenbisTotalAmount = tenbisTotalAmount;
        jsonData.valuTamashTotalAmount = valuTamashTotalAmount;
        jsonData.goodiTotalAmount = goodiTotalAmount;
        jsonData.dtsTotalAmount = dtsTotalAmount;
        jsonData.banxTotalAmount = banxTotalAmount;
        jsonData.yaadTotalAmount = yaadTotalAmount;
        jsonData.mishlohaTotalAmount = mishlohaTotalAmount;

        jsonData.customer = {
          "clubName": "HAKAFA",
          "s_id_number": 111111,
          "s_first_name": firstName,
          "s_last_name": session.store.storeName,
          "dt_birth_date": null,
          "s_phone_number_1": "0",
          "customer_group_id": null,
          "amount": 0,
          "merakez": "",
          "db_id": 900, //TODO: I don't like this.. why do we have to put a value here?
          "is_price_alut": false,
          "printer_type": 0,
          "discount_percent": 0,
          "customer_number": null,
          "obligo": 0,
          "address": "",
          "is_tamash_customer": true,
          "is_cibus_tenbis": true
        }
        sale.jsondata = JSON.stringify(jsonData);

      }

      export function fixSplitSaleCustomers(sale: Storage.Entity.Sale, salePayments: Storage.Entity.SalePayment[]) {
        let hasCibusTenBis = false;
        let hasHakafaPayment = false;

        let voucherPayment = salePayments.filter(payment => payment.method == Storage.Entity.SalePayment.METHOD_VOUCHER)[0]

        if (voucherPayment == null) {
          return;
        }

        let data = JSON.parse(voucherPayment.data)
        data.forEach(voucherData => {
          if (voucherData.voucher_type_id == Service.Hakafa.VOUCHER_TYPE_ID) {
            if (voucherData.isTamashCustomer) {
              hasHakafaPayment = true
            }
          }
          if (voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE ||
            voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS ||
            voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS ||
            voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS ||
            voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT ||
            voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH ||
            (voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI && voucherData.isTaxInvoice == false)) {
            hasCibusTenBis = true;
          }
        })

        if (hasCibusTenBis && hasHakafaPayment) {
          let jd = JSON.parse(sale.jsondata)
          jd.additionalCustomer = jd.customer;
          sale.jsondata = JSON.stringify(jd)
          VoucherPayment.addDummyHakafaCustomerIfCibusOrTenbis(sale, salePayments);
        }
        else {
          VoucherPayment.addDummyHakafaCustomerIfCibusOrTenbis(sale, salePayments);
        }
      }


      export function areAnyTenbisOrCibusPaymentsExists() {
        var areExists = false;
        return getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE)
          .then((result) => {
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }
            
            return areExists ? result : getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS)
          })
          .then((result) => {
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }

            return getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS)
          })
          .then((result) => {
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }

            return getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT)
          })
          .then((result) => {
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }

            return getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH)
          })
          .then((result) => {
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }

            return getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT)
          })
          .then((result) => {
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }
            if (session.pos.isCaveret) {
              new Promise(function (resolve, reject) {
                resolve(areExists);
              });
            }
            else {
              return getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI)
            }
          })
          .then((result) => {
            if (session.pos.isCaveret) {
              return result;
            }
            var total = result.reduce((a, b) => { return a + b.amount }, 0)
            if (total) {
              areExists = true
            }
            return areExists;
          })
      }

      export async function removeSmartVoucherPayment(voucherToRemove) {
        var voucherData = JSON.parse(voucherToRemove.salePayment.data);
        let voucherPayments = PositiveTS.Service.VoucherPayment.getVoucherPayments();
        let voucherPayment = voucherPayments.find((payment) => payment.id == voucherToRemove.salePayment.id)
        let deleteStatus = await deleteVoucherPaymentAndPersist(voucherPayment, voucherData[voucherToRemove.idx])

        if (deleteStatus.success) {
          return true;
        } else{
          app.showAlert({
            header: i18next.t('error'),
            content: deleteStatus.error,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          });
        }

      }

      export function getVoucherPaymentsByType(smartVoucherType) {
        var voucher = new PositiveTS.Storage.Entity.Voucher();
        return voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID)
          .then((result) => {
            for (let voucher of result) {
              if (voucher.smartVoucherType == smartVoucherType) {
                return voucher;
              }
            }
            return undefined;
          })
          .then((voucherToAdd) => {
            if (!voucherToAdd) { return undefined }

            var currentMethodType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER
            if (voucherToAdd.isDiscountVoucher == 1) {
              currentMethodType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;
            }

            var salePayment = null;
            for (var i = 0; i < posVC.salePayments.length; i++) {
              // Get the payment
              var aSalePayment = posVC.salePayments[i];

              // Check that the sale payment has the current method
              if (aSalePayment.method == currentMethodType) {
                salePayment = aSalePayment;
                break;
              }
            }
            return salePayment;
          })
          .then((salePayment) => {

            var _return = []
            if (!salePayment) { return _return; }
            var data = JSON.parse(salePayment.data);
            for (let i = 0; i < data.length; i++) {
              let row = data[i];
              if (row.smartVoucherType === smartVoucherType) {
                let dataToPush = {
                  idx: i,
                  salePayment: salePayment,
                  row: row,
                  amount: row.amount,
                  cardNumber: row.barCode,
                  paymentsTxt: "",
                  quantity: row.quantity
                }
                if (dataToPush.quantity == null){
                  delete dataToPush.quantity 
                }
                _return.push(dataToPush)
              }
            }
            return _return;
          })
      }

      export function getVoucherPayments() {
        return posVC.salePayments.filter(payment => {
          return payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER ||
            payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT
        });
      }

      export function getAmountPaidByVoucherTypeId(): { [voucherTypeId: string]: number } {
        let result: { [voucherTypeId: string]: number } = {}
        let payments = getVoucherPayments();
        for (let payment of payments) {
          try {
            let pd = JSON.parse(payment.data)
            for (let data of pd) {
              if (data.voucher_type_id) {
                result[data.voucher_type_id] = result[data.voucher_type_id] || 0
                result[data.voucher_type_id] += Number(data.amount)
              }
            }
          }
          catch (err) {
            //do nothing
          }
        }
        return result;
      }


      export async function saveVoucher(salePayment, voucherData) {
        //Create dataObject array
        var dataObj = [];

        if (voucherData.voucher_type_id === PositiveTS.Service.VALU_CARD_VOUCHER_TYPE_ID && voucherData.isTamashCustomer) {
          PositiveTS.Service.VoucherPayment.addDummyHakafaCustomer(posVC.sale, "מועדון פנימי", undefined, undefined, voucherData.amount, undefined, undefined, undefined,undefined, undefined)
        }

        //init the old dataObject
        if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1) {
          dataObj = JSON.parse(salePayment.data);
        }

        dataObj.push(voucherData);

        //Set the salePayment with the data object
        salePayment.data = JSON.stringify(dataObj);

        var voucherPayments = [];
        voucherPayments.push(salePayment);

        var otherVoucherPaymentType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;

        if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
          otherVoucherPaymentType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;
        }

        for (var i = 0; i < posVC.salePayments.length; i++) {

          // Get the payment
          var currentSalePayment = posVC.salePayments[i];

          // Check that this sale payment method is as the given method
          if (currentSalePayment.method == otherVoucherPaymentType) {
            voucherPayments.push(currentSalePayment);
            break;
          }
        }

        // Set the amount of the sale payment
        if (posUtils.isBlank(voucherData['amount']) || salePayment.method === 6) {
          salePayment.amount = 0;
        } else {
          salePayment.amount += voucherData['amount'];
        }

        // Persist the sale payment
        await posVC.persistSalePayment(salePayment);

      }


      export async function handleSmartVoucher(amount, cvv, companyId, voucherData, salePayment, additionalData?): Promise<any> {
        let aThis = posPaymentVC;
        if (amount > aThis.totalAmount) {
          app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('voucherPaymentPraxellAmountExceeds'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          });
          return;
        }
        try {
          let voucher = Service.SmartVoucherFactory.createVoucher(Pinia.globalStore.selectedVoucherType.smartVoucherType);
          app.showLoadingMessage(i18next.t("voucherPaymentDebitPraxellLoadingMsg"));
          let voucherPaymentResult = await voucher.pay(voucherData, amount, cvv, companyId, additionalData)
          app.hideLoadingMessage();
          if (Service.SmartVoucher.isActionResponse(voucherPaymentResult)) {
            //response that is an ActionResponse
            if(voucherPaymentResult.success){
              await saveVoucher(salePayment, voucherPaymentResult.voucher);
            }
            
            return voucherPaymentResult;
          }
          else {
            //simple voucher data
            await saveVoucher(salePayment, voucherData);
          }
        }
        catch (error) {
          app.hideLoadingMessage()
          app.hideLoadingMessageDialog()
          console.error(error)
          app.showAlert({
            header: i18next.t('error'),
            content: typeof (error) == "string" ? error : error.message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          });
        }
      }

      export async function payBySmartVoucherType(smartVoucherType, amount, barcode, companyId?, additionalData?) {
        await setSelectedVoucher(smartVoucherType)
        let salePayment = getPaymentBasedOnSelectedVoucher();
        return payWithVoucher(salePayment, amount, barcode, companyId, additionalData);
      }

      async function payWithVoucher(salePayment, amount, barCode, companyId, additionalData?) {
        let aThis = posPaymentVC;
        let cvv = ""

        aThis.inTheMiddleOfPayment = true;

        const salePaymentData = JSON.parse(salePayment.data)
        const smartVoucher = Pinia.globalStore.selectedVoucherType
        const voucherUsedOnSale = Array.isArray(salePaymentData) ? salePaymentData.filter((pa) => pa.voucher_type_id == smartVoucher.typeID).length : 0
        if (smartVoucher.numberOfUseOnSale == voucherUsedOnSale){
          app.showAlert({
            header: i18next.t('error'),
            content:  i18next.t('posPayment.voucherOverNumberCanUseOnSale', {name: smartVoucher.name, number: smartVoucher.numberOfUseOnSale}),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          })
          return
        }

        let voucherData = {
          'amount': amount,
          'creditType': Pinia.globalStore.selectedVoucherType.name,
          'barCode': barCode,
          'voucher_type_id': Pinia.globalStore.selectedVoucherType.typeID,
          'smartVoucherType': false,
          'valuTypeId': Pinia.globalStore.selectedVoucherType.valuTypeId,
          'praxellManpik': Pinia.globalStore.selectedVoucherType.praxellManpik,
          'mutipassInit': Pinia.globalStore.selectedVoucherType.mutipassInit,
          'response': undefined,
          'isTamashCustomer': Service.Hakafa.isCurrentCustomerTamash(Pinia.globalStore.selectedVoucherType)
        };



        if (PositiveTS.Service.SmartVoucher.isSmartVoucher(Pinia.globalStore.selectedVoucherType.smartVoucherType)) {
          let smartVoucherResponse = await handleSmartVoucher(amount, cvv, companyId, voucherData, salePayment, additionalData)
          aThis.inTheMiddleOfPayment = false;
          return smartVoucherResponse
        }
        else {
          await saveVoucher(salePayment, voucherData)
          aThis.inTheMiddleOfPayment = false;
        }
      }


      function setSelectedVoucher(smartVoucherType) {
        var voucher = new PositiveTS.Storage.Entity.Voucher();
        return voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID)
          .then((result) => {
            for (let voucher of result) {
              if (voucher.smartVoucherType == smartVoucherType) {
                return voucher;
              }
            }
            throw new Error(i18next.t("smartVoucherNotFound", {name: smartVoucherType}));
          })
          .then((voucherToAdd) => {

            Pinia.globalStore.setSelectedVoucherType( voucherToAdd);
          })
      }

      function getPaymentBasedOnSelectedVoucher() {
        var currentMethodType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER
        if (Pinia.globalStore.selectedVoucherType.isDiscountVoucher == 1) {
          currentMethodType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;
        }

        var salePayment = null;
        for (var i = 0; i < posVC.salePayments.length; i++) {
          // Get the payment
          var aSalePayment = posVC.salePayments[i];

          // Check that the sale payment has the current method
          if (aSalePayment.method == currentMethodType) {
            salePayment = aSalePayment;
            break;
          }
        }

        // If no sale payment was found for the current payment method, create new one
        if (salePayment == null) {
          salePayment = new PositiveTS.Storage.Entity.SalePayment();
          salePayment.saleID = posVC.sale.id;
          salePayment.method = currentMethodType;
        }
        return salePayment;
      }

      //external payments methods, without using posPaymentVC

      function getVoucherSalePayment() {
        let currentMethodType = Storage.Entity.SalePayment.METHOD_VOUCHER
        let salePayment = null;
        for (let i = 0; i < posVC.salePayments.length; i++) {
          var aSalePayment = posVC.salePayments[i];
          if (aSalePayment.method == currentMethodType) {
            salePayment = aSalePayment;
            break;
          }
        }

        if (salePayment == null) {
          salePayment = new Storage.Entity.SalePayment();
          salePayment.saleID = posVC.sale.id;
          salePayment.method = currentMethodType;
        }

        return salePayment;
      }

      async function handleSmartVoucherEx(amount, cvv, companyId, voucherData, salePayment, smartVoucher, additionalData?): Promise<ActionResponse> {

        let voucherDataAsActionResponse: ActionResponse = {
          success: false,
          error: "",
        }

        let voucher = Service.SmartVoucherFactory.createVoucher(smartVoucher.smartVoucherType);

        try {
          let voucherPaymentResult = await voucher.pay(voucherData, amount, cvv, companyId, additionalData)
          app.hideLoadingMessage();
          if (Service.SmartVoucher.isActionResponse(voucherPaymentResult)) {
            //response that is an ActionResponse
            if (voucherPaymentResult.success) {
              await saveVoucherEx(salePayment, voucherPaymentResult.voucher);
            }

            voucherDataAsActionResponse = voucherPaymentResult;
          }
          else {
            //simple voucher 
            voucherDataAsActionResponse.voucher = voucherData
            voucherDataAsActionResponse.success = true
            await saveVoucherEx(salePayment, voucherData);
          }
        }
        catch (error) {
          voucherDataAsActionResponse.success = false
          voucherDataAsActionResponse.error = posUtils.isString(error) ? error : error.message
        }
        finally {
          return voucherDataAsActionResponse
        }
      }


      async function saveVoucherEx(salePayment, voucherData) {
        let saveVoucherResult: ActionResponse = {
          success: false,
          error: null
        };

        var dataObj = [];

        if (voucherData.voucher_type_id === PositiveTS.Service.VALU_CARD_VOUCHER_TYPE_ID && voucherData.isTamashCustomer) {
          Service.VoucherPayment.addDummyHakafaCustomer(posVC.sale, "מועדון פנימי", undefined, undefined, voucherData.amount, undefined, undefined, undefined,undefined, undefined)
        }

        if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1) {
          dataObj = JSON.parse(salePayment.data);
        }

        dataObj.push(voucherData);
        salePayment.data = JSON.stringify(dataObj);

        var voucherPayments = [];
        voucherPayments.push(salePayment);

        var otherVoucherPaymentType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;

          if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
            otherVoucherPaymentType = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;
          }

        for (var i = 0; i < posVC.salePayments.length; i++) {

          // Get the payment
          var currentSalePayment = posVC.salePayments[i];

          // Check that this sale payment method is as the given method
          if (currentSalePayment.method == otherVoucherPaymentType) {
            voucherPayments.push(currentSalePayment);
            break;
          }
        }

        if (voucherData['amount'] == null || voucherData['amount'] == '' || salePayment.method === Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
          salePayment.amount = 0;
        } else {
          salePayment.amount += voucherData['amount'];
        }
        
        posVC.persistSalePayment(salePayment);

        saveVoucherResult.success = true;
        return saveVoucherResult;
      }


      export async function payWithVoucherEx(salePayment, amount, barCode, companyId, smartVoucher, additionalData?, cvv = "") :Promise<ActionResponse> {
        const salePaymentData = JSON.parse(salePayment.data)
        const voucherUsedOnSale = Array.isArray(salePaymentData) ? salePaymentData.filter((pa) => pa.voucher_type_id == smartVoucher.typeID).length : 0
        if (smartVoucher.numberOfUseOnSale == voucherUsedOnSale){
          return {success: false, error: i18next.t('posPayment.voucherOverNumberCanUseOnSale', {name: smartVoucher.name, number: smartVoucher.numberOfUseOnSale})}
        }
        let voucherData = {
          'amount': amount,
          'creditType': smartVoucher.name,
          'barCode': barCode,
          'voucher_type_id': smartVoucher.typeID,
          'smartVoucherType': false,
          'valuTypeId': smartVoucher.valuTypeId,
          'praxellManpik': smartVoucher.praxellManpik,
          'mutipassInit': smartVoucher.mutipassInit,
          'response': undefined,
          'isTamashCustomer': Service.Hakafa.isCurrentCustomerTamash(smartVoucher)
        };

        let paymentResult: ActionResponse;

        if (Service.SmartVoucher.isSmartVoucher(smartVoucher.smartVoucherType)) {
          paymentResult = await handleSmartVoucherEx(amount, cvv, companyId, voucherData, salePayment, smartVoucher, additionalData)
        }
        else {
          paymentResult = await saveVoucherEx(salePayment, voucherData);
        }

        return paymentResult;
      }

      export async function setSelectedVoucherEx(smartVoucherTypeOrID) {
        let voucherEntity = new Storage.Entity.Voucher();
        let allowedVoucherTypesResult = await voucherEntity.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID)

        for (let voucher of allowedVoucherTypesResult) {
          if (voucher.smartVoucherType == smartVoucherTypeOrID) {
            Pinia.globalStore.setSelectedVoucherType(voucher);
            return voucher;
          }
        }

        for (let voucher of allowedVoucherTypesResult) {
          if (voucher.typeID == smartVoucherTypeOrID) {
            Pinia.globalStore.setSelectedVoucherType(voucher);
            return voucher;
          }
        }

        throw new Error(`No voucher ${smartVoucherTypeOrID} defined`);
      }

      export async function payBySmartVoucherTypeEx(smartVoucherType, amount, barcode, companyId?, additionalData?) {
        {
          let salePayment = getVoucherSalePayment();
          let selectedVoucher = await setSelectedVoucherEx(smartVoucherType);
          return await payWithVoucherEx(salePayment, amount, barcode, companyId, selectedVoucher, additionalData)
        }


      }
    }
  }
}
