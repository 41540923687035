module PositiveTS {
export module Storage {
export module Entity {
export class Iteminfo extends WasmEntity {

  constructor() {
    let meta = {
      name: 'Iteminfo',
      fields: {
          code: "TEXT",
          description: "TEXT",
          isVatLiable: "BOOL",
          isDiscountRestricted: "BOOL",
          maxPctDiscountRestricted: "FLOAT",
          jsonData: "TEXT"
      }
    }
    super(meta)
  }

}}}}
