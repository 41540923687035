module PositiveTS {
	export class SalesQueue {
		static totalItemsInQueue = 0;
		static ONE_HOUR = 1000 * 60 * 60;

	static async launchQueueExt() {
			try{
				await SalesQueue.launchQueue()
			}
			catch(error){
				var msg = "";
				if (error && error.message) {
					msg = error.message;
				}
				if (typeof(error) === "string") {
					msg = error;
				}
				PositiveTS.QueueWorker.Messaging.postNotification(PositiveTS.Shared.Constants.Messaging.errorLogMessages, `Did not send sales to server. Reason: ${msg}`)
			}
			finally{
				setTimeout(() => { SalesQueue.launchQueueExt(); }, 5000);
			}
		}

		static async launchQueue() {

			let offlineSalesCount = await Shared.SaleSync.countAndNotifyOfflineSales(workerDB);
			if(offlineSalesCount == 0 || !PositiveTS.QueueWorker.Utils.isOnline){
				return;
			}

			return await SalesQueue.tryToSendSalesToServer();
		}

		static async tryToSendSalesToServer(){
			let syncStatusesNeedToBeSent = [Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT];

			let addFaildSyncStatusToSend = () =>{syncStatusesNeedToBeSent.push(Shared.Constants.Sale.SYNC_STATUS_FAILED)}

			await SalesQueue.checkAndTriggerFailedSalesSync(addFaildSyncStatusToSend)

			let sales = await PositiveTS.QueueWorker.Utils.fetchBySyncStatuses(syncStatusesNeedToBeSent);
			if(sales.length == 0){
				return;
			}
			let saleIDs = sales.map(sale => sale.id);			
			// Fetch all the items and payments for all the sales
			let result = await PositiveTS.QueueWorker.Utils.fetchSaleItemAndPaymentsBySaleIDs(saleIDs)
			
			let sendResult = await SalesQueue.convertSalesToJsonAndSendToServer(result,sales);
			return sendResult;
		}

		static async checkAndTriggerFailedSalesSync(callback:Function){
			try{

				let lastTimeSaleSyncFailed = await workerDB.keyValueStore.where('key').equals(Shared.Constants.SaleSync.lastTimeSaleSyncFailed).first()
				if(lastTimeSaleSyncFailed?.value){ 
					let nextTimeToSyncErrSales = lastTimeSaleSyncFailed.value + SalesQueue.ONE_HOUR 
					let now = new Date().getTime() 
					if(now > nextTimeToSyncErrSales){
						callback()
						workerDB.keyValueStore.put({key:Shared.Constants.SaleSync.lastTimeSaleSyncFailed,value: new Date().getTime()})
						console.log(`Triggered failed sales sync at ${new Date().toLocaleString()}`)
					}
				}else{
					callback()
					workerDB.keyValueStore.put({key:Shared.Constants.SaleSync.lastTimeSaleSyncFailed,value: new Date().getTime()})
					console.log(`Triggered failed sales sync at ${new Date().toLocaleString()}`)
				}
			}catch(err){
				console.error(err)
			}
		}

		static convertSalesToJsonAndSendToServer(salesData, sales) { 
			let promises = [];

			let results = salesData.map(saleData => SalesQueue.ConvertSaleToServerTypeJson(saleData))
	
			let salesObject = { sales: {} };
	
			results.forEach(saleData => {
				salesObject.sales[saleData.sale.id] = saleData;
			})
	
			promises.push(SalesQueue.sendSalesToServer(salesObject,sales));

			return Promise.all(promises);
		}

		static async sendSalesToServer(salesObject, sales) { //async
	
	
			console.debug('Sales to sync: ');
			console.debug(salesObject.sales);
	
			// Convert the salesJSONObject to JSON
			var salesJSON = JSON.stringify(salesObject);
	
			// Send the sales to the remote server
			var url = Shared.Constants.remoteRoot + 'sales';
			
			let results;
			let promises = [];


			try {
				results = await PositiveTS.QueueWorker.FetchReq.jsonReq(url,"POST",PositiveTS.QueueWorker.Utils.token,{ data: salesJSON })
			} catch(error) {
				await SalesQueue.saveFailures(sales,{error: error.message})
				console.error(error);
				throw new Error(error);
			}
			// --- If there was an error, the remote server will return an error object
			if ('error' in results.result) {
				await SalesQueue.saveFailures(sales,results)
				console.error(results.result.error);
				throw new Error(results.result.error);
			}
			else {
				// --- Sync completed. Go over the results object from the server and check which sale synced successfully
				// The results object is an array where the keys are the indexes from the salesJSONObject.sales array
				// and the values are booleans indicating whether or not the sale was saved
				// Go over the sales, one by one, and update its sales sync status
				for (let sale of sales) {

					// Check that the result object has all the needed properties
					if (!(sale.id in results.result) || !('result' in results.result[sale.id]) || !('message' in results.result[sale.id])) {
						sale.syncStatus = Shared.Constants.Sale.SYNC_STATUS_FAILED;
						sale.syncLastMessage = 'Sync failed due to missing properties from result object.';
						sale.syncError = 'UNKNOWN';
					} else {
						// Get the result for this sale ID
						var resultForSale = results.result[sale.id];

						// Check whether the sale was added successfully
						var wasAddedSuccessfully = resultForSale['result'];
						if (wasAddedSuccessfully) {
							sale.syncStatus = Shared.Constants.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY;
							sale.syncLastMessage = 'Sale was synced successfully!';
							PositiveTS.SalesQueue.sendToRemoveXFields(sale.payments)
						} else {
							sale.syncStatus = Shared.Constants.Sale.SYNC_STATUS_FAILED;
							sale.syncLastMessage = 'Sync failed due to remote server error. (' + resultForSale['message'] + ')';
							sale.syncError = resultForSale['message'];
						}
					}
					sale.syncLastMessageTimestamp = PositiveTS.DateUtils.fullFormat();
					promises.push(workerDB.sales.put(sale));
				}
				await Promise.all(promises);
				return;
			}
		}
		static saveFailures(sales, results) {
			var promises = [];
			// Mark all sales sync status as failed
			for (let sale of sales) {
				
				sale.syncStatus = Shared.Constants.Sale.SYNC_STATUS_FAILED;
				sale.syncLastMessage = 'Sync failed due to remote server error. (' + results.error + ')';
				sale.syncLastMessageTimestamp = PositiveTS.DateUtils.fullFormat();
				sale.syncError = results.error;
				promises.push(workerDB.sales.put(sale));
			}
			return Promise.all(promises);
		}

		static ConvertSaleToServerTypeJson(saleAndItemsAndPayments) {

			let sale = saleAndItemsAndPayments;
			let saleItems = saleAndItemsAndPayments.items;
			let salePayments = saleAndItemsAndPayments.payments;
	
			// Add the sale structure to the JSON object
			let saleJSONObject = {sale: {}, items: [], payments: []};
	
			// Save all item codes and later add them to the sale object
			let item_codes = [];
	
			let paymentsWithSale = [];
	
			// Add sale items to JSON object
			for (let saleItem of saleItems) {
				if (item_codes.indexOf(saleItem.itemCode) == -1) {
					item_codes.push(saleItem.itemCode);
				}

				saleJSONObject.items.push({
					id                : saleItem.id,
					row_number				: saleItem.rowNumber,
					barcode           : saleItem.barcode,
					item_code				: saleItem.itemCode,
					item_desc				: saleItem.itemDescription,
					color					: saleItem.color,
					size					: saleItem.size,
					quantity				: saleItem.quantity,
					unit_price				: saleItem.unitPrice,
					original_unit_price		: saleItem.originalUnitPrice,
					is_present				: saleItem.isPresent,
					is_pickup				: saleItem.isPickup,
					discount_id				: saleItem.discountID,
					discount_name 		: saleItem.discountName,
					discount_percent		: saleItem.discountPercent,
					discount_amount		: saleItem.discountAmount,
					discount_type		: saleItem.discountType,
					buy_promotion_code : saleItem.buyPromotionCode,
					get_promotion_code : saleItem.getPromotionCode,
					discount_approver_emp_id: saleItem.discountApprovedByEmployeeID,
					salesperson_employee_id : saleItem.salespersonEmployeeID == '-1' ? sale.cashierEmployeeID : saleItem.salespersonEmployeeID,
					salesperson_employee_name: saleItem.salespersonEmployeeName || sale.cashierEmployeeName,
					originalItemId:            saleItem.originalItemId,
					price_list_id:            saleItem.priceListId,
					no_vat:                   saleItem.noVat,
					always_has_vat:                   saleItem.alwaysHasVat,
					price_alut:                   saleItem.priceAlut,
					has_weight:               saleItem.hasWeight,
					is_abstract:              saleItem.isAbstract,
					is_addition:              saleItem.isAddition,
					has_groups:               saleItem.hasGroups,
					level:                    saleItem.level,
					parent_item_id:           saleItem.parentItemId,
					parent_row_number:        saleItem.parentRowNumber,
					no_discount:							saleItem.noDiscount,
					has_preparation_instructions: saleItem.hasPreparationInstructions,
					selected_preparation_instructions: saleItem.selectedPreparationInstructions,
					salePercentAddition:      saleItem.salePercentAddition,
					promotions:	saleItem.promotions,
					multipassDiscount:	saleItem.multipassDiscount,
					kasparDiscount:	saleItem.kasparDiscount,
					priceNetoAfterDiscounts: saleItem.priceNetoAfterDiscounts,
					isDeposit:				  saleItem.isDeposit,
					hasCompensation: saleItem.hasCompensation,
					time_item_version: saleItem.timeItemVersion,
					start_timestamp: saleItem.startTimestamp,
					elal_ticket_number: saleItem.elalTicketNumber,
					elal_ticket_type: saleItem.elalTicketType,
					elal_pnr: saleItem.elalPNR,
					elal_rec_id: saleItem.elalRecId,
					elal_service_cd: saleItem.elalServiceCD,
					end_timestamp: saleItem.endTimestamp,
				});
			}
	
			// Add sale payments to JSON object
			for (let salePayment of salePayments) {
	
				saleJSONObject.payments.push({
					method	: salePayment.method,
					amount	: salePayment.amount,
					data	: salePayment.data
				});
	
				paymentsWithSale.push({sale: sale, payment: salePayment});
			}
	
			// Add sale header to JSON object
			saleJSONObject.sale = {
				id 												: sale.id,
				invoice_number						: sale.invoiceSequence,
				invoice_type 							: sale.invoiceType,
				tenant_id									: sale.tenantID,
				company_id								: sale.companyID,
				store_id									: sale.storeID,
				store_name								: sale.storeName,
				jsondata								  : JSON.stringify(Object.assign({useNewSalesqueue: true},JSON.parse(sale.jsondata))),
				store_address							: sale.storeAddress,
				store_free_text						: sale.storeFreeText,
				store_registration_num		: sale.storeRegistrationNum,
				store_phone								: sale.storePhone,
				physical_pos_id						: sale.posPhysicalID,
				pos_id										: sale.posDeviceID,
				pos_number								: sale.posNumber,
				total_quantity						: sale.totalQuantity,
				total_amount							: sale.totalAmount,
				total_discount 						: sale.totalDiscount,
				cashier_id								: sale.cashierEmployeeID,
				cashier_name							: sale.cashierEmployeeName,
				timestamp									: sale.createdAt,
				formatted_timestamp					: sale.formatted_timestamp,
				discount_id								: sale.discountID,
				discount_name 						: sale.discountName,
				discount_type							: sale.discountType,
				discount_percent					: sale.discountPercent,
				sale_discount_amount			: sale.saleDiscountAmount,
				sale_discount_allowed_with_other_promotions	: sale.saleDiscountAllowedWithOtherPromotions,
				club_member_promotion_type : sale.clubMemberPromotionType,
				total_vatable_amount      : sale.totalVatableAmount,
				discount_approver_emp_id	: sale.discountApprovedByEmployeeID,
				promotion_code	          : sale.promotionCode,
				cust_seq_Id								: sale.customerSeqID,
				cust_id 									: sale.customerID,
				cust_phone 								: sale.customerPhone,
				cust_name									: sale.customerName,
				salesperson_employe_id 		: sale.salespersonEmployeeID,
				salesperson_employee_name : sale.salespersonEmployeeName,
				is_delivery					: sale.isDelivery,
				order_time					: sale.orderTime,
				vat 											: sale.vat,
				orderNumber               : sale.orderNumber,
				delivery_status               : sale.deliveryStatus,
				items_ids		: ';' + item_codes.join(';') + ';',
				atmTransactionId			: sale.atmTransactionId,
				is_withdrawal			: sale.isWithdrawal ? true : false,
				roundAmount: sale.roundAmount,
				flightLegId: sale.flightLegId,
				dalpak_id: sale.dalpakId,
				diners: sale.diners,
				amount_without_split: sale.amountWithoutSplit,
				future_order_id: sale.futureOrderId || null,
				tip_amount: sale.tipAmount,
				sale_remarks: sale.saleRemarks
			};
	
	
			if (sale.invoiceType == Shared.Constants.Sequence.TYPE_CREDIT_INVOICE ||
				sale.invoiceType == Shared.Constants.Sequence.TYPE_TAX_CREDIT_INV ||
				sale.invoiceType == Shared.Constants.Sequence.TYPE_CREDIT_SHIPMENT_INV ||
				sale.invoiceType == Shared.Constants.Sequence.TYPE_RECEIPT && sale.totalAmount < 0 ||
				sale.parentSaleInvoiceSequence && SalesQueue.isReplacementSale(sale)) {
				let parentSaleInfo = SalesQueue.getParentSaleInfoForCreditSale(sale) 
					
				Object.assign(saleJSONObject.sale, parentSaleInfo);
			} 

			return saleJSONObject;
		}

		static getParentSaleInfoForCreditSale(sale) {
			let parentInfo = {
				parent_invoice_company_id: sale.parentSaleCompanyID,
				parent_invoice_store_id : sale.parentSaleStoreID,
				parent_invoice_pos_device_id : sale.parentSalePosDeviceID,
				parent_invoice_pos_number : sale.parentSalePosNumber,
				parent_invoice_number : sale.parentSaleInvoiceSequence,
				parent_invoice_type : sale.parentSaleInvoiceType,
				parent_sale_delivery : sale.parentSaleDelivery
			};
	
			return parentInfo;
		}

		static isReplacementSale(sale) {
			if (sale.invoiceType == Shared.Constants.Sequence.TYPE_DEBIT_INVOICE && typeof (sale.jsondata) !== 'undefined' && sale.jsondata !== null && String(sale.jsondata).trim() != '') {

			  let jsondata = JSON.parse(sale.jsondata);
 
			  return jsondata['replacementSaleItems'] !== null && typeof (jsondata['replacementSaleItems']) !== 'undefined';
			}
 
			return false;
		 }

		 static sendToRemoveXFields(salePayments){
			try{
				let xFieldsToRemove = []
				for (let payment of salePayments) {
					if (payment.method == 1) {
						let data = JSON.parse(payment.data)
						for (let payementData of data) {
							if (payementData) {
								if (payementData.Xfield) {
									xFieldsToRemove.push(payementData.Xfield)
								}
							}
						}
					}
				}
				PositiveTS.QueueWorker.Messaging.postNotification("removeXField", xFieldsToRemove)
		 	} catch(err) {
				console.error(err)
			}
		}		
	}
}
