module PositiveTS {
export module Service {
export module CaveretFiles {
    const  dir = "c:\\Caveret\\temp\\"

    export async function createItemsFile() {
        try {
            let items = (await PositiveTS.Service.WasmDB.promiseSql("SELECT code, priceZarhan, promoBuy FROM Item"));
            let promotionsRows = (await PositiveTS.Service.WasmDB.promiseSql("SELECT code, discountValue, fromDate, toDate FROM PROMOTION WHERE template = -1"));
            promotionsRows = promotionsRows.filter((promotion:any) =>
                            moment().isBetween(moment(promotion.fromDate, "DD-MM-YYYY"), moment(promotion.toDate, "DD-MM-YYYY")));

            //map promotions into hash
            let promotions = {};
            promotionsRows.forEach((promotion:any) => {
                promotions[promotion.code] = promotion;
            });

            let fileItems = items.map((item:any) => {
                let it = [item.code, item.priceZarhan];
                let itemPromos = item.promoBuy.split("&");
                let itemHasPromo = false;
                itemPromos.forEach(promoCode => {
                    if(promotions[promoCode]) {
                        it = [...it, promotions[promoCode].discountValue, promotions[promoCode].fromDate, promotions[promoCode].toDate];
                        itemHasPromo = true;
                    }
                });
                if(!itemHasPromo) it = [...it, "","",""]  
                return it.join(',');
            });
            
            let fileStr = `${session.pos.storeID},${session.pos.deviceID},${fileItems.length},${moment().format("DD/MM/YYYY HH:mm:ss.SSS")}\n`;
            fileStr += fileItems.join('\n');
            await FileUpload.deleteFile(`${dir}items.txt`);
            await FileUpload.genericFileSave(`${dir}items.txt`, btoa(unescape(encodeURIComponent(fileStr))));
            return true;

        } catch (err) {
            console.error(err);
            return false;
        }
    }

    export async function eraseNotInUsePosFlag() {
        try {
            await FileUpload.deleteFile(`${dir}notInUse.txt`);
        } catch (err) {
            console.error(err);
        }
    }

    export async function createNotInUsePosFlag() {
        try {
            await FileUpload.genericFileSave(`${dir}notInUse.txt`, btoa(unescape(encodeURIComponent("1"))));
        } catch (err) { 
            console.error(err); 
        }
   }

   export async function createVersionFile() {
       try {
        await FileUpload.deleteFile(`${dir}positiveVersion.txt`);
        let fileContent = `${PositiveTS.Version.appVersion} ${PositiveTS.Version.appDate},${session.pos.deviceID},${session.pos.emvTerminalNumber},${session.pos.posNumber}`
        await FileUpload.genericFileSave(`${dir}positiveVersion.txt`, btoa(unescape(encodeURIComponent(fileContent))));

        } catch (err) { 
            console.error(err); 
        }
   }
}}}
