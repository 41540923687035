module PositiveTS {
export module Components {
export module ItemInventoryItm {
  const _dialogSelector = "#positive-item-inventory-itm-dialog"
  const _vueComponentName = 'item-inventory-itm';
  const _template = JST.ItemInventoryItm();

  export function create() {
    let vueObj = {
        template: _template,
        methods: {
          open: _open,
          close: _close,
          openAttrSelect: _openAttrSelect,
          _resetForm: _resetForm,
          isRoshemet: isRoshemet,
          hasFlights: () => session.pos.hasFlights
        },
        data: _initData

    }

    VueApp.component(_vueComponentName,vueObj)

  }

  function isRoshemet(){
    return session.pos.isRoshemet;
  }


  function _open(itm:any, showChangeSizeBtn = true, reBindKeypress = true){
    this.showChangeSizeBtn = showChangeSizeBtn;
    this.reBindKeypress = reBindKeypress;
    $(document).unbind('keypress');
    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: 'positive-dialog',
        width: '80%',
        height: 400,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });
    this._resetForm(itm);
  }

  async function _resetForm(itm:any): Promise<any>{
    this.origItem = itm;
    this.itemCode = itm.itemCode;
    this.itemName = itm.itemDescription;
    this.itemColor = itm.color
    this.itemSize = itm.size;
    this.invs = []
    this.itemHasAttr = false 
    this.colorSizeAvail = []

    app.showLoadingMessage();
    if (session.pos.hasFlights) {


      let barsetItems = Pinia.flightsStore.currentBarsetItemsByItemCode[itm.itemCode];

      let totalQty = 0;

      if (barsetItems && barsetItems.length > 0) {

        totalQty = Pinia.flightsStore.getItemInventoryByCode(itm.itemCode);
        
        for (let saleItem of posVC.saleItems) {
          if (saleItem.itemCode == itm.itemCode) {
            totalQty -= saleItem.quantity;
          }
        }

      } else {
        let sales = await appDB.sales.toArray();

        for (let sale of sales) {
          for (let saleItem of sale.items) {
            if (saleItem.itemCode == itm.itemCode) {
              totalQty -= saleItem.quantity;
            }
          }
        }
      }

      this.invs = [{
        storeQty: totalQty
      }];

      app.hideLoadingMessage();

    } else {    
      if(session.pos.isRoshemet){
        let qty = 0;
        if(itm.item){
          qty = session.allItems.get(itm.itemCode).currentInventory;
        }
        else if(itm.currentInventory){
          qty = itm.currentInventory;
        }
        
        this.invs = [{
          storeQty: qty
        }];
        app.hideLoadingMessage();
      }
      else{
        let isEmptyData = false
        try{
          if (PositiveTS.Service.PriorityService.isPriorityCompany() && !PositiveTS.Service.PriorityService.isManagingInventoryInBo()){
            //not support color size
            //this.colorSizeAvail need to be array of objects {color: '', size: ''}
            //this.itemHasAttr need to be true if colorSizeAvail has more then one color size objects
            let response = await PositiveTS.Service.ItemInventory.getPriorityInventoryItem(this.itemCode)
            if (response.errors.length){
              app.showAlert({
                header: i18next.t('itemInventoryDialog.itemInventoryError'),
                content: response.errors.join("\n"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
              return
            }
            isEmptyData = _.isEmpty(response.data)
            this.invs = response.data
            return
          }

          const colorSizeResponse = await PositiveTS.Service.ItemInventory.getColorSize(this.itemCode)
          this.colorSizeAvail = colorSizeResponse;
          this.itemHasAttr = (colorSizeResponse.length > 1)
          const inventoryResponse = await PositiveTS.Service.ItemInventory.getInventory(this.itemCode,  this.itemColor,this.itemSize)
          this.invs = inventoryResponse;
          isEmptyData = _.isEmpty(inventoryResponse)
          
        }catch(e){
          console.error(e)
          app.showAlert({
            header: i18next.t('itemInventoryDialog.itemInventory'),
            content: i18next.t('itemInventoryDialog.itemInventoryGlobalError'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          })
        }finally{
          app.hideLoadingMessage()
          if (isEmptyData){
            app.showAlert({
              header: i18next.t('itemInventoryDialog.itemInventory'),
              content: i18next.t('itemInventoryDialog.itemInventoryNotFoundInventory'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
          }
        }
      }
    }
  }

  function _openAttrSelect(){
    this.close();
    var that = this;
    pNavigator.pushPage('pos-item-chooser', i18next.t('pageTitle.posItemChooser'), '/pos/pos-item-stock', {initParams: {
      callbackBeforeBack: 'ItemInventoryItm',
      saleItemObject: that.origItem
    }});
  }

  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      itemCode: "",
      itemName: "",
      itemColor: "",
      itemSize: "",
      origItem:undefined,
      colorSizeAvail: [],
      invs: [],
      itemHasAttr: true,
      showChangeSizeBtn: true,
      reBindKeypress: true,
    };
  }

  function _close() {
    $(_dialogSelector).dialog('close');
    if (this.reBindKeypress) {
      $(document).unbind('keypress');
      $(document).keypress(posVC.onKeyPress);
    }
  }


}}}
