module PositiveTS {
export module Components {
    export module QuicksaleDialog {
      const dialogSelector = "#quicksale-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function _initjQueryDialogIfNeeded() {
        let aThis = this;
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '45.45%',
              height: 550,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
              position: {
                my: 'left',
                at: 'right',
                of: '#pos'
              }
            });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          quicksale: 0,
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          fields: initFields()

        }
      }

      function initFields() {
        let fields = {
          'quicksale': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'quicksale'
          }
        }
        return fields;
      }

      function close() {
        if (this.initialized) {
          $(dialogSelector).dialog('close');
        }
        this.cleanData()
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      function open() {
        
        return new Promise((resolve,reject) => {
          Pinia.globalStore.setReopenQuicksaleOnNewSale(true);
          
          _initjQueryDialogIfNeeded.call(this)
          this.quicksale = 0;
          $(document).unbind('keypress');

          $(dialogSelector).dialog('open');
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
        })
      }




      function confirmAndClose() {
        if (Number(this.quicksale) === 0) {
          return;
        }
        this.resolveFunc({approved:true, quicksale: Number(this.quicksale)})
        this.close();
        
      }

      function cancelTapped() {
        Pinia.globalStore.setReopenQuicksaleOnNewSale(false);
        this.resolveFunc({approved: false, quicksale: null})
        this.close();
      }


      function opemPaymentScreen(payAll:boolean){ ; //TODO: refactor this once we convert the voucher screen to Vue component
        this.close();
        Pinia.globalStore.paymentScreenOpen(false)
        posVC.goToPaymentScreen()
        .then(async () => {
          PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod("cash")
          if (payAll){ 
            let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
            PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView.amount = totals.totalAmount-totals.totalPaid;
            await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView.verifyAndPay();
          }
        })        
      }





      function onKeyTapped(key) {
        this.keyPadHanlder(key,this.fields["quicksale"],this,3);
      }



      export function create() {

        let component = {
          template: JST.quicksaleDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData: cleanData,
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            addItem() {
              let quicksale = this.quicksale;
              this.quicksale = 0

              if (Number(quicksale) === 0) {
                return;
              }

              Service.Quicksale.addItem(quicksale);
              PositiveTS.VueInstance.$nextTick( ()=> {
                // DOM updated
                (<HTMLInputElement>this.$refs.quicksale).focus();
                (<HTMLInputElement>this.$refs.quicksale).select();
              })
              
            },
            cancelTapped: cancelTapped,
            onKeyTapped: onKeyTapped,
            opemPaymentScreen: opemPaymentScreen,       
            selectMe(e:FocusEvent) {
              (<HTMLInputElement>e.target).select();
            } 
          },
          data: initData,
          computed:{
            totalAmount(){
              return session.fixedNumber(Pinia.globalStore.totalAmount)
            }
          }
        }

        VueApp.component('quicksale-dialog',component)

      }
    }
  }
}
