import { defineStore } from 'pinia';
const state = {
    lastElalSaleSequence: localStorage.getItem('lastElalSaleSequence'),
    offlinePNR: localStorage.getItem('offlinePNR')
}

const getters = {
    isOn: (state) => {
        return session.pos.isElal && state.lastElalSaleSequence && state.lastElalSaleSequence > 0;
    },
    getLastElalSaleSequence: (state)  => {
        return state.lastElalSaleSequence;
    },
    getOfflinePNR: (state) => {
        return state.offlinePNR;
    },
    // getOfflinePNR: (state) => (payload) => {
    //     return state.offlinePNR;
    // },
    isSalesWithPNR: () => {
        let isSalesValid = true;
        try {
            posVC.saleItems.forEach(saleItem => {
                if (!saleItem.elalTicketNumber && !saleItem.elalRecId && !saleItem.elalPNR) {
                    isSalesValid = false
                }
            })
            return isSalesValid;
        } catch (err) {
            console.error(err)
            return isSalesValid;
        }
    },
}
const actions = {
    setLastElalSaleSequence(payload){
        localStorage.setItem('lastElalSaleSequence', payload);
        this.lastElalSaleSequence = payload;
    },
    setOfflinePNR(payload) {
        localStorage.setItem('offlinePNR', payload);
        this.offlinePNR = payload;
    },
    async createNewElalSaleSequence() {
        try {
            let lastElalSaleSequence = this.getLastElalSaleSequence;
            if (lastElalSaleSequence && lastElalSaleSequence > -1) {
                let originalMatches = String(lastElalSaleSequence).match(/\d/);
                const originalFirstDigit = Number(originalMatches[0]);

                lastElalSaleSequence++;
                let newMatches = String(lastElalSaleSequence).match(/\d/)
                let newFirstDigit = Number(newMatches[0]);

                if (newFirstDigit != originalFirstDigit) {
                    lastElalSaleSequence = (originalFirstDigit * 100000);
                }
                this.setLastElalSaleSequence(lastElalSaleSequence)
                let jd = JSON.parse(posVC.sale.jsondata);
                jd.elalSaleSequenceNumber = lastElalSaleSequence;
                posVC.sale.jsondata = JSON.stringify(jd);
                await PositiveTS.Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
                await posVC.saleUpdated()
            }
        } catch (err) {
            PositiveTS.Service.Logger.error(err)
        }
    },
    async updateSaleItemsPNRdata() {
        try {
            let result = await Pinia.componentsStore.openComponent({componentName: 'elalSaleWithoutPNR'})  

            if (!result) {
                throw new Error(i18next.t('elal.errors.saleWithoutPNR'))
            }

            let salesWithPNRdata = result;
            posVC.saleItems.forEach(saleItem => {
                let currentSale = salesWithPNRdata.find(pnrSale => pnrSale.id == saleItem.id);
                saleItem.elalTicketNumber = currentSale.ticketNumber;
                saleItem.elalTicketType = currentSale.selectedTicketType;
                saleItem.elalPNR = currentSale.elalPNR;
            })
            await PositiveTS.Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
            await posVC.saleUpdated()
            return true;
        } catch (err) {
            console.error(err)
            return Promise.reject(err);
        }
    },
    async addPNRItemsDialog() {
        try {
            let pnrDetails: PositiveTS.Types.ElalGetPNRResponse = undefined;
            let pnrItemsToAdd: PositiveTS.Types.ElalUpdatePNRResponse = undefined;
            pnrDetails = await Pinia.componentsStore.openComponent( {
                componentName: "enterPNRCodeDialog",
                args: []
            });
            if (pnrDetails?.root?.PNR == "cancel") { // close button gives "cancel" as PNR
                if (jsonConfig.getVal(jsonConfig.KEYS.isElalPNRHomeBtnRequiresApproval)) {
                    try {
                        await app.showManagerApprovalDialog();
                        pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null)
                        return;
                    } catch (e) {
                        return await this.addPNRItemsDialog()
                    }
                } else {
                    pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null)
                    return;
                }
            }
            if (posUtils.isBlank(pnrDetails?.root?.PNR) || posUtils.isBlankLikeRails(pnrDetails.root.reservations)) {
                let res = await app.promiseShowAlert({
                    header: i18next.t("pos.payAttention"),
                    content: i18next.t("elal.errors.pnrNotFound"),
                    hideCancelButton: false
                });
                if (res == "continue") { // Go offline
                    return;
                } else {
                    return await this.addPNRItemsDialog()
                }
            }
            pnrItemsToAdd = await Pinia.componentsStore.openComponent( {
                componentName: "selectPNRItemsDialog",
                args: [pnrDetails]
            });
            if (posUtils.isPresent(pnrItemsToAdd?.SuccessReservations)) {
                for (const reservation of pnrItemsToAdd.SuccessReservations) {
                    let resToAdd = pnrDetails.root.reservations.find(res => reservation.ID == res.RecId);
                    if (!resToAdd) {
                        continue;
                    }
                    let itemName = resToAdd.ServiceDescription || resToAdd.Name;
                    let saleItem = await saleItemHelper.createNewSaleItemFromGenernicItem(itemName, parseFloat(resToAdd.FormOfPayment), false);
                    if (posUtils.isBlank(resToAdd.DOCNumber)) {
                        PositiveTS.Storage.Entity.ElalReservation.addOrUpdate({
                            pnr: reservation.PNR,
                            recId: resToAdd.RecId,
                            docType: resToAdd.TypeDoc,
                            status: PositiveTS.Storage.Entity.ElalReservation.SYNC_STATUS_WAITING_TO_BE_SENT_TO_ELAL,
                        });
                    }
                    saleItem.elalTicketNumber = resToAdd.DOCNumber;
                    saleItem.elalTicketType = resToAdd.TypeDoc;
                    saleItem.elalRecId = resToAdd.RecId;
                    saleItem.elalServiceCD = resToAdd.TypeDoc == "EMD" ? resToAdd.ServiceCD : "";
                    saleItem.elalPNR = reservation.PNR;
                    await posVC.persistNewSaleItem(saleItem, true);
                }

                if (posUtils.isPresent(pnrItemsToAdd.ErrorReservations) && pnrItemsToAdd.ErrorReservations[0]) {
                    let msg = '';
                    for (const reservation of pnrItemsToAdd.ErrorReservations) {
                        let blockedRes = pnrDetails.root.reservations.find(res => reservation.ID == res.RecId);
                        if (!blockedRes) {
                            continue;
                        }
                        let itemName = blockedRes.ServiceDescription || blockedRes.Name;
                        msg += ` - (${blockedRes.RecId}) ${itemName} <br/>`
                    }
                    await app.promiseShowAlert({
                        header: `${i18next.t("pos.payAttention")} - ${i18next.t("elal.errors.pnrItemsCouldNotBeAdded")}`,
                        content: msg,
                        hideCancelButton: false
                    });
                }
            } else {
                let res = await app.promiseShowAlert({
                    header: i18next.t("pos.payAttention"),
                    content: i18next.t("elal.errors.pnrItemsCouldNotBeAdded"),
                    hideCancelButton: false,
                });
                if (res == "continue") { // Go offline
                    return;
                } else {
                    return await this.addPNRItemsDialog()
                }
            }
        } catch (error) {
            console.error(error);
            await app.promiseShowAlert({
                header: i18next.t("pos.payAttention"),
                content: i18next.t("elal.errors.errorOnAddingPNRItems"),
                hideCancelButton: true
            });
            this.state.alreadyOpenAddPNRItemsDialog = true;
        }
    },
    async cancelPNRItems() {
        let pnrDataArr: PositiveTS.Types.ElalUpdatePNRReservationReq[] = [];
        if (posVC?.saleItems?.length === 0) {
            return;
        }
        for (const saleItem of posVC.saleItems) {
            if (posUtils.isPresent(saleItem.elalPNR) && posUtils.isPresent(saleItem.elalRecId)) {
                pnrDataArr.push({
                    PNR: saleItem.elalPNR,
                    RecId: saleItem.elalRecId,
                    KupaStatus: "cancel"
                })
            }
        }
        if (pnrDataArr.length > 0) {
            let response = await PositiveTS.Service.ElalApiService.UpdatePNR(pnrDataArr);
            if (response?.Status != 'OK' || (response?.ErrorReservations?.length > 0 && response?.ErrorReservations[0] !== null)) {
                console.error('An error occurred while trying to cancel PNR items :: ', response)
            }
        }
    },
    async validateElalActiveDirectory() {
        try {
            let addonResult = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({}, "elal_ad")
            let groups = addonResult.request.result;
            let username = groups[0]?.split('LDAP Username: ')[1] || "";

            if (!groups.some(group => group.includes("T3-Cash-Registers"))) {
                let content = i18next.t('elal.errors.errorNotInGroupContent', {
                    username
                });
                Pinia.componentsStore.openComponent( {
                    componentName: "ElalPermissionDialog",
                    args: [
                        content
                    ]
                })
            }
        } catch (e) {
            Pinia.componentsStore.openComponent( {
                componentName: "ElalPermissionDialog",
                args: [
                    i18next.t('elal.errors.errorLoadingADGroups')
                ]
            })

        }
    }
}

const elalStore = defineStore('elal', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default elalStore;