module PositiveTS {
  export module Dialogs {
    export module Ontrust {
      export module Customer {

        var dialogTemplate = `
      <div id="SimpleItemSelect-dialog"
        style="display:none;"
        title="בחר">
        <div data-role="content" style="text-align: right; top: 3em;">
        <table>
          <tr>
            <td>
              שם לקוח:
            </td>
            <td>
              <div class="field-container" id="ontrust-dialog-customer-name-container">
                <input type="text" id="ontrust-dialog-customer-name">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              טלפון:
            </td>
            <td>
              <div class="field-container" id="ontrust-dialog-customer-phone-container">
                <input type="text" id="ontrust-dialog-customer-phone">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              הערות:
            </td>
            <td>
              <div class="field-container" id="ontrust-dialog-customer-remarks-container">
                <input type="text" id="ontrust-dialog-customer-remarks">
              </div>
            </td>
          </tr>

        </table>

        <div data-role="footer">
          <div class="dialog-buttons-bottom">
            <div id="SimpleItemSelect-dialog-continue" data-role="button" data-background-color="dark-green">המשך</div>
            <div id="SimpleItemSelect-dialog-cancel" data-role="button" data-background-color="red">ביטול</div>

          </div>
        </div>
      </div>
      `;


        export function getOntrustCustomerDetails(): void {
          var c = new OntrustCustomerDialogClass();
          c.open();
        }

        class OntrustCustomerDialogClass {
          private _uiDialog: JQuery;
          private _uiJquery: JQuery;

          constructor() {
            $(() => {
              this._uiJquery = $(dialogTemplate);
              this._uiDialog = this._uiJquery.dialog({
                autoOpen: false,
                modal: true,
                dialogClass: 'positive-dialog',
                width: '50%',
                resizable: false,
                closeOnEscape: false,
                draggable: false,
                open: function(event, ui) { },
                close: function(event, ui) { }
              });

              this._uiInit();
            });
          }

          open() {
            this._uiDialog.dialog('open');
            // Unbind whatever
          }

          private _uiInit(): void {
            $(document).unbind('keypress');

            var allButtons: any = this._uiJquery.find('[data-role="button"]');
            var allInput: any = this._uiJquery.find('.field-container');

            allButtons.uibutton();
            allInput.inputField();

            this._$("#ontrust-dialog-customer-name").val("");
            this._$("#ontrust-dialog-customer-phone").val("");
            this._$("#ontrust-dialog-customer-remarks").val("");

            this._$("#SimpleItemSelect-dialog-continue").unbind("click");
            this._$("#SimpleItemSelect-dialog-continue").bind("click", () => {
              this._continue();
            });

            this._$("#SimpleItemSelect-dialog-cancel").unbind("click");
            this._$("#SimpleItemSelect-dialog-cancel").bind("click", () => {
              this._close();
            });

          }

          private _$(selector:string):JQuery{
            return $(this._uiJquery).find(selector);
          }

          private _continue(): Promise<any> {
            var name = this._$("#ontrust-dialog-customer-name").val();
            var phone = this._$("#ontrust-dialog-customer-phone").val();
            var remarks = this._$("#ontrust-dialog-customer-remarks").val();

            var customer = new PositiveTS.Service.Ontrust.Customer.CustomerObj(name, phone, remarks);
            if (customer.isValid) {
              this._close();
              return customer.persistToSale();
            } else {
              return this._uiShouldCorrectDataOrRemoveCustomer();
            }
          }

          private _uiShouldCorrectDataOrRemoveCustomer():Promise<any>{
            return app.promiseShowAlert({
              header: "",
              content: i18next.t('okToRemoveCustomerCancelToCorrect'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: false
            })
            .then((response)=>{
              if (response === "continue") {
                this._close();
                return PositiveTS.Service.Ontrust.Customer.removeOntrustCustomer();
              } else if (response === "cancel"){
                // Do nothing let user reenter correct data.
              }
            });
          }

          private _close(): void {
            this._uiDialog.dialog('close');
            posVC.initializeKeyboard();
            // Unbind whatever
          }


        }




      }
    }
  }
}
