module PositiveTS {
export module Storage {
export module Entity {
export class EmvTransmitLog extends IDBEntity {

  expedtedZNumber:string
  tranFileNumber:string
  attachedToZNumber:string
  isAttached:boolean
  sessionNumber:string //session number to get report from Shva
  depositReport:string
  depositDate:string
  createdAt:string
  updatedAt:string
  
  constructor() {
    let meta = {
      name: 'EmvTransmitLog',
      internal: true,
      fields: {
        expedtedZNumber: "TEXT",
        tranFileNumber: "TEXT",
        attachedToZNumber: "TEXT",
        isAttached: "BOOL",
        sessionNumber: "TEXT",
        depositReport: "TEXT",
        depositDate: "TEXT",
        createdAt: "TEXT",
        updatedAt: "TEXT"
      }
    }
    super(meta)
  }

  static async attachNotAttachedReports(zReport) {
    
    let transReport = JSON.parse(zReport.emvTransmitReport)
    if (transReport == null) {
      transReport = {};
    }
    transReport.DepositReport = [];
    let emvSessionNumbers = [];
    let result = await appDB.emvTransmitLogs.where('isAttached').anyOf([0]).toArray()
    
    for (let etl of result) {
      let depReport = JSON.parse(posUtils.isNullOrUndefinedOrEmptyString(etl.depositReport) ? "{}" : etl.depositReport)
      transReport.DepositReport.push(depReport)
      emvSessionNumbers.push(etl.sessionNumber)
    }
    zReport.emvTransmitReport = JSON.stringify(transReport)
    zReport.emvSessionNumbers = JSON.stringify(emvSessionNumbers)
    return zReport;
  }

  static async getLastReportAndCreateRecordIfneeded() { //should run at the beginning of Z process before transmit!
    
    if(jsonConfig.getVal(jsonConfig.KEYS.usePinpadInSwitchMode) || Service.Gateway.isEnabled()){return}

    let result = await Service.EMV.getTotal()
    
    if (result.ResultCode == "0") {
      if (result.StaticObj && !posUtils.isNullOrUndefinedOrEmptyString(result.StaticObj.SessionNumber)) {
        let dbResult = await appDB.emvTransmitLogs.where('sessionNumber').equals(result.StaticObj.SessionNumber).toArray();
        if (dbResult.length == 0) {
          let recordToAdd = new EmvTransmitLog();
          recordToAdd.isAttached = false;
          recordToAdd.tranFileNumber = result.StaticObj.FileNumber
          recordToAdd.sessionNumber = result.StaticObj.SessionNumber
          recordToAdd.depositDate = result.StaticObj.Date
          let date = new Date();
          let strDate = moment(date).toString();
          recordToAdd.createdAt = strDate;
          recordToAdd.updatedAt = strDate;
          recordToAdd.timestamp = date.getTime();
          await appDB.emvTransmitLogs.put(recordToAdd); //TODO: need to check if the first put is really needed...
          let depositReport = await Service.EMV.getDepositReport(result.StaticObj.SessionNumber, result.StaticObj.Date)
            
          recordToAdd.depositReport = JSON.stringify(depositReport)
          await appDB.emvTransmitLogs.put(recordToAdd); 
        } 
      }
    }
    else {
      console.error("problem in getting last total file...")
      console.error(result)
      return
    }
    
    
  }

}}}}
