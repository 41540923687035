module PositiveTS {
export module Components {
export module CashManagementReport {

  const _dialogSelector = "#positive-cash-management-report"
  const _vueComponentName = 'cash-management-report';

  export function create() {
    let cashManagementReport = {
        template: JST.cashManagementReport(),
        methods: {
          close: close,
          async open(isStoreReport){

            this.enterStatement = Number(Service.CashierStatement.getLastEnterCashierStatementNumber());
            this.totalCashPayments = await this.getTotalCashPayments();
            this.totalWithdrawals = ( await Service.Withdrawal.getWithdrawalsXZ()).sum;
            this.totalInSafe = Number(Service.CashierStatement.getCashSumWithdrawnToSafe()) * -1;
            this.total = await Service.Withdrawal.cashInPos();
            let isMobileLayout = this.mobileLayout

            $(function() {
              $(_dialogSelector).dialog({
                autoOpen: true,
                modal: true,
                dialogClass: 'positive-dialog',
                width: isMobileLayout ? '95%' : '60%',
                height: 400,
                resizable: false,
                closeOnEscape: false,
                draggable: false,
              });
              $(_dialogSelector).dialog('open');
            });

            if(isStoreReport) {
              app.showAlert({
                header: i18next.t('cashManagement.printer.title'),
                content:i18next.t('cashManagement.warning'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
            }
          },
          fixNegativeNumber(number) {
            if(number < 0) return `${session.fixedNumber(number*-1)} -`;
            return session.fixedNumber(number);
          },
          async getTotalCashPayments() {
            let report =  await xReportVC.calculateReport(true);

            return report.payments[Storage.Entity.SalePayment.METHOD_CASH].amount;
          }
        },
        computed:{
          mobileLayout(){
            return Pinia.globalStore.mobileLayout
          }
        },
        data: _initData
    }

    VueApp.component(_vueComponentName,cashManagementReport)
  }

  function close() {
    $(_dialogSelector).dialog('close');
  }

  function _initData () {
    return {
      enterStatement: 0,
      totalCashPayments: 0,
      totalWithdrawals: 0,
      totalInSafe: 0,
      total: 0,
    }
  }

}}}
