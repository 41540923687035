module PositiveTS {
  export module Service {
     export module Check {

      export class ServiceCheckErn {

        protected _ERN_ManufacturerId = "PTV";
        protected _ERN_ManufacturerVersion = "002";

        protected _getClientRequestId() {
          return session.pos.tenantID + session.pos.companyID + session.pos.storeID + "11111";
        };

        protected _getShortUnique() {
          var d = new Date().getTime().toString();
          var str = PositiveTS.Service.StrUtils.rpad(session.pos.storeID + session.pos.posNumber.toString(), 6, "0") + d.substring(d.length - 6, d.length);

          return PositiveTS.Service.StrUtils.lpad(str, 12);
        };

        protected _getErnMId() {
          var ernId = jsonConfig.getVal(jsonConfig.KEYS.ernMId); // for test possible to use "100276"
          if (!ernId) { throw new Error("No jsonConfig.KEYS.ernMId defined"); }

          return ernId;

        };

        protected _translateIlDateToErnDate(ildate) {
          return moment(ildate,"DD/M/YY").format("DDMMYYYY")
        };
      

        private _SERVICE_URL = 'https://www.eranit.co.il/erntranswsxml/service.asmx';
        private _SOAP_ACTION_FILE_UPDATE = "ProcessFileUpdateXml";
        private _SOAP_ACTION_TRAN = "ProcessTransactionXml";
        private _SOAP_ACTION_CANCEL_TRANSACTION = "CancelTransactionXml";
        private _SOAP_TEMPLATE_SELECTOR_FILE_UPDATE = "#ern-ws-soapData-ProcessFileUpdateXml";
        private _SOAP_TEMPLATE_SELECTOR_TRAN = "#ern-ws-soapData-ProcessTransactionXml";
        private _SOAP_TEMPLATE_SELECTOR_CANCEL_TRANSACTION = "#ern-ws-soapData-ProcessCancelTransactionXML";
        private _SOAP_SECURITY_PASSWORD = "5500000000000";
        private _ADDON_SOAP_ERN = "SOAP_ERN";
        private _ADDON_HTTP_ROUTER = "http_router";
        // Store the ERN limit values, As fetched from ern
        private _LOCAL_STORAGE_ERN = "ERN";

        // The following parameters are constant
        // the ERN_ManufacturerVersion, is short double on ERN so it is not as usefull

        private _counterErnCall = { acct: "", count: 0 };
        private _MAX_ERN_CALL = 2;



        private _ERN_REPONSE = {
          AUTH: "AUTH",
          CALL: "CALL",
          DECL: "DECL"
        };

        private _I18L = {
          OPEN_COMMUNICATION: "פותח תקשורת מול חברת ERN, אנא המתן",
        };

        ERN_FORCED_CONTINUE = "ERN_FORCED_CONTINUE";

        constructor() {
        }

        isTooMuchBadCallRequestsAndNoConfirmationNumber(fieldsData): boolean {
          var dictSoapData = new ClassDictTran(fieldsData);

          var intVoiceAuthorizationNumber = parseInt(dictSoapData.VoiceAuthorizationNumber);

          if (this._counterErnCall.acct !== dictSoapData.AccountNumber) {
            this._counterErnCall.acct = dictSoapData.AccountNumber;
            this._resetTooMuchBadCallRequests();
          }

          if (this._counterErnCall.count >= this._MAX_ERN_CALL) {
            if (intVoiceAuthorizationNumber) {
              this._resetTooMuchBadCallRequests();
              return false;
            } else {
              this._resetTooMuchBadCallRequests();
              return true;
            }

          } else {
            return false;
          }
        };

        getErnFileUploadStorage() {
          return JSON.parse(localStorage.getItem(this._LOCAL_STORAGE_ERN));
        };

        actionFileUpload() {
          if (!this.isErnSupported()) {
            return Promise.resolve();
          }

          var soapData = this._getSoapActionFileUpdateData();

          return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
            data: soapData,
            action: this._ADDON_SOAP_ERN
          }, this._ADDON_HTTP_ROUTER, this._I18L.OPEN_COMMUNICATION)
            .then((response) => {
            var strData = this._extractDataStringFromSoapActionFileUpdateResponse(response);
            var dictData = this._praseFileUpdateData(strData);
            this._setErnFileUploadStorage(dictData);
          });
        };

        actionTran(fieldsData) {
          return this._getSoapActionTranData(fieldsData)
            .then((soapData) => {
            return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
              data: soapData,
              action: this._ADDON_SOAP_ERN
            }, this._ADDON_HTTP_ROUTER, this._I18L.OPEN_COMMUNICATION);
          })
            .then((response) => {
            return this._extractDataStringFromSoapActionTranResponse(response);
          });
        };

        cancelTransactionIfRequired(checkPayment, removeAt):Promise<any> {
          if (removeAt && this.isErnSupported()) {

            var ernString = JSON.parse(checkPayment.data)[removeAt - 1].ern;
            if (ernString && ernString !== serviceCheckErn.ERN_FORCED_CONTINUE) {

              var authorizationNumber = ernString;
              return this.actionCancelTransaction(authorizationNumber);
            }
          }
          return Promise.resolve();
        };

        isErnSupported() {
          return jsonConfig.getVal(jsonConfig.KEYS.isErn);
        };

        actionCancelTransaction(authorizationNumber) {
          var soapData = this._getSoapActionCancelTransaction(authorizationNumber);
          return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
            data: soapData,
            action: this._ADDON_SOAP_ERN
          }, this._ADDON_HTTP_ROUTER, this._I18L.OPEN_COMMUNICATION).then((response) => {
            // Parse response
            return this._extractDataStringFromSoapActionTranResponse(response);
          });
        };

        private _resetTooMuchBadCallRequests() {
          this._counterErnCall.count = 0;
        };





        private _extractDataStringFromSoapActionTranResponse(response) {
          var domDoc = $.parseXML(response.request.result);
          var msg = $(domDoc).find("Message").text();
          var authorizationNumber = $(domDoc).find("AuthorizationNumber").text();
          var responseCode = $(domDoc).find("ResponseCode").text();

          if (responseCode === this._ERN_REPONSE.CALL) {
            this._counterErnCall.count++;
          }
          else {
            this._resetTooMuchBadCallRequests();
          }

          if (responseCode === this._ERN_REPONSE.AUTH) {
            return authorizationNumber;
          } else {
            throw new Error(msg);
          }
        };

        private _getSoapActionCancelTransaction(authorizationNumber) {
          var soapUrl = this._SERVICE_URL;
          var soapAction = this._SOAP_ACTION_CANCEL_TRANSACTION;

          var templateSoapData = $(this._SOAP_TEMPLATE_SELECTOR_CANCEL_TRANSACTION).html();

          var dictSoapData = new ClassDictCancelTransaction(authorizationNumber);

          var strSoapData = _.template(templateSoapData)(dictSoapData);

          var soapActionParams: SoapActionParams = new SoapActionParams(strSoapData, this._SOAP_SECURITY_PASSWORD);


          return {
            soapUrl: soapUrl,
            soapAction: soapAction,
            soapActionParams: JSON.stringify(soapActionParams)
          };
        };



        private _getSoapActionTranPaymentData(fieldsData) {
          var tmpDataObj = [];
          var svc = new PositiveTS.Service.Check.CheckPayment();
          svc.addPaymentToDataObject(tmpDataObj, fieldsData);

          var currentRow;
          for (var i = 0; i < tmpDataObj.length; i++) {
            currentRow = tmpDataObj[i];
            currentRow.dueDate = this._translateIlDateToErnDate(currentRow.date);

            currentRow.paymentNumber = PositiveTS.Service.StrUtils.lpad(i + 1, 2);
            currentRow.checkNumber = currentRow.number;
            currentRow.checkSum = PositiveTS.Service.StrUtils.lpad(posUtils.toInt(currentRow.amount, 2), 9);

          }
          return tmpDataObj;
        };

        private _getSoapActionTranData(fieldsData) {
          var dictSoapData = this._getSoapActionTranDataPrepairObject(fieldsData);
          return this._getSoapActionTranDataConfirmCheckPaymentNumbers(dictSoapData, fieldsData)
            .then(() => {
            return this._getSoapActionTranDataTransactionXmlDict(dictSoapData);
          });
        };

        private _getSoapActionTranDataConfirmCheckPaymentNumbers(dictSoapData:ClassDictTran, byRefFieldsData) {
          var numberOfPayments = parseInt(dictSoapData.NumberOfPayments);

          if (numberOfPayments === 1) { return Promise.resolve(); }



          return new PositiveTS.Service.Check.CheckErnConfirmCheckNumbers().inputBoxConfirmAndSetCheckNumbers(dictSoapData, byRefFieldsData);

        };







        private _getSoapActionTranDataTransactionXmlDict(dictSoapData:ClassDictTran) {
          var soapUrl = this._SERVICE_URL;
          var soapAction = this._SOAP_ACTION_TRAN;

          var templateSoapData = $(this._SOAP_TEMPLATE_SELECTOR_TRAN).html();
          var strSoapData = _.template(templateSoapData)(dictSoapData);

          var soapActionParams: SoapActionParams = new SoapActionParams(strSoapData, this._SOAP_SECURITY_PASSWORD);


          return {
            soapUrl: soapUrl,
            soapAction: soapAction,
            soapActionParams: JSON.stringify(soapActionParams)
          };
        };

        private _getSoapActionTranDataPrepairObject(fieldsData) {

          var dictSoapData = new ClassDictTran(fieldsData);


          var payments = this._getSoapActionTranPaymentData(fieldsData);
          dictSoapData.CheckSum = payments[0].checkSum
          dictSoapData.payments = payments.slice(1)

          return dictSoapData;
        };







        private _getSoapActionFileUpdateData() {
          var soapUrl = this._SERVICE_URL;
          var soapAction = this._SOAP_ACTION_FILE_UPDATE;

          var templateSoapData = $(this._SOAP_TEMPLATE_SELECTOR_FILE_UPDATE).html();
          var dictSoapData = new ClassDictFileUpdateSoap();

          var strSoapData = _.template(templateSoapData)(dictSoapData);

          var soapActionParams: SoapActionParams = new SoapActionParams(strSoapData, this._SOAP_SECURITY_PASSWORD);


          return {
            soapUrl: soapUrl,
            soapAction: soapAction,
            soapActionParams: JSON.stringify(soapActionParams)
          };
        };

        private _extractDataStringFromSoapActionFileUpdateResponse(response) {
          var domDoc = $.parseXML(response.request.result);
          var msg = $(domDoc).find("Message").text();
          if ($(domDoc).find("ResponseCode").text() === this._ERN_REPONSE.AUTH) {
            return msg;
          } else {
            throw new Error("ERN Param update error" + msg);
          }
        };

        private _praseFileUpdateData(strData) {
          return {
            ennVendorNumber: strData.substring(0, 6),
            entAuthVector: strData.substring(6, 15),
            isPaymentsAllowed: strData.substring(15, 16),
            isPostphonedAllowed: strData.substring(16, 17),
            vectoreIdVerification: strData.substring(17, 26),
            vectorePhoneNumberVerification: strData.substring(26, 35),
            vectoreCarVerification: strData.substring(35, 44),
            maxPaymentsAllowed: strData.substring(44, 46),
            maxPostphonedMonths: strData.substring(46, 48),
            maxOfflineCeal: strData.substring(48, 54),
            isAllowedJ5: strData.substring(54, 55),
            maxPostphoneDays: strData.substring(55, 57),
            isMustArevSign: strData.substring(57, 58),
            isAutoCheckComplete: strData.substring(58, 59),
            maxDaysAllowCancelation: strData.substring(59, 61)
          };
        };

        private _setErnFileUploadStorage(dict) {
          localStorage.setItem(this._LOCAL_STORAGE_ERN, JSON.stringify(dict));
        };


      }
    }
  }
}
declare var serviceCheckErn:PositiveTS.Service.Check.ServiceCheckErn;