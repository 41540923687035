module PositiveTS {
  export module Components {
      export module UsbDevices {
  
        const dialogSelector = "usb-devices"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            devices: [],
            devicesTimeout: null
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.usbDevices(),
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }

                clearTimeout(this.devicesTimeout)
                this.initialized = true;
              },

              cancel() {                
                this.resolveFunc()
                this.close();
              },
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  this.dialogElem.close();
                }
                this.cleanData()
              },
              askPermission(vendorID,productID){
                Android.askPermission(vendorID,productID);
              },
              async listDevices(){
                let devicesString = Android.listUSBDevices();
                    devicesString = devicesString.split("\n");
                    this.devices = [];
                    for(let i = 0; i < devicesString.length - 1; i++){
                      let deviceString = devicesString[i].split("|");
                      this.devices.push({
                        vendorID: deviceString[0],
                        productID: deviceString[1],
                        name: deviceString[2],
                        permission: deviceString[3] == "true"
                      })
                    }

                this.devicesTimeout = setTimeout(() => {this.listDevices()}, 2000);
              },
              open() {
                if(Android.listUSBDevices != undefined){
                    this.listDevices();
                }
                else{
                  app.showAlert({
                    header: i18next.t('error'),
                    content:i18next.t('usbDevices.noFunctionFOund'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
          
                }
                return new Promise((resolve,reject) => {
      
                  
                  this.initialized = true;
        
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  this.dialogElem.showModal();
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
            computed: {
            }
          }
  
          VueApp.component('usb-devices-dialog',component)
        }
      }
    }
  }
  