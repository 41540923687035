module PositiveTS {
    export module Components {
        export module MenuSideBar {
            declare type CompType = InstanceType<ReturnType<typeof create>>;

            export function create () {
                const component = {
                    template: JST.MenuSideBar(),

                    data (){
                        let data = {
                            opened: false,
                            overlayBackground: document.getElementById("loading-message-overlay"),
                            restaurantNewPOS: jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS),
                            initialTime: PositiveTS.DateUtils.longFormat(),
                            deviceID: session.pos.deviceID,
                            storeName: session.pos.storeName,
                            employeeName: session.pos.employeeName,
                            appVersion: PositiveTS.Version.appVersion,
                            appDate: PositiveTS.Version.appDate,
                            primaryList: null,
                            showTogglePromotions: jsonConfig.getVal(jsonConfig.KEYS.showTogglePromotionsBtn),
                            ignorePromotions: Boolean(posVC.ignorePromotions),
                            hideDiscounts: jsonConfig.getVal(jsonConfig.KEYS.hideDiscounts),
                            hasDiners: jsonConfig.getVal(jsonConfig.KEYS.hasDiners),
                            hasFlights: session.pos.hasFlights,
                            isDelivery: jsonConfig.getVal(jsonConfig.KEYS.isDelivery),
                            isDutyFree: Service.DutyFree.isDutyFree(),
                            isSittingOrTA: Service.SittingTA.isEnabled(),
                            isRoshemet: session.pos.isRoshemet,
                            isDalpakimOnline:jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline),
                            allowSplitSale:Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment))
                        }

                        return data;
                    },

                    computed: {                       
                        dalpakName () {
                            let name = null;
                            if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && Pinia.dalpaksStore.currentSelectedDalpak){
                                name = `${jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) ? i18next.t('table') : i18next.t('pos.dalpak')} - `;
                                name += `${Pinia.dalpaksStore.currentSelectedDalpak.name}`;
                            }

                            return name;
                        },

                        buttons () {
                            let iconClass = "color-primary active";
                            let buttons: Types.PositiveButtonProps[] = [
                                {
                                  if:true,
                                  text: i18next.t('pos.posSearchInvoices'),
                                  iconClass,
                                  icon: 'fa-thin fa-magnifying-glass-dollar',
                                  onClick: (index) => {
                                        this.close();
                                        pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
                                    },  
                                },
                                {
                                  if:!this.isDalpakimOnline,
                                  text: i18next.t('pos.posPastInvoices'),
                                  iconClass,
                                  icon: 'fa-thin fa-file-invoice-dollar',
                                  onClick: (index) => {
                                    this.close();
                                    pNavigator.pushPage('pos-past-invoices', i18next.t('pageTitle.posPastInvoices'), null, null);
                                  },
                                },
                                {
                                  if:true,
                                  text: i18next.t("pos.printInvoice"),
                                  iconClass,
                                  icon: 'fa-thin fa-print',
                                  onClick: (index) => {
                                    this.close();
                                    PositiveTS.VueInstance.$refs.pos.printPerforma();
                                  },
                                },
                                {
                                  if:true,
                                  text: i18next.t('pos.withoutPrintInvoice'),
                                  iconClass,
                                  icon: 'fa-thin fa-circle-xmark',
                                  onClick: (index) => {
                                    this.close();
                                    PositiveTS.VueInstance.$refs.pos.dalpaksSaveExit(true);
                                  },
                                },
                            ];

                            return buttons.filter(button => button.if);
                        },

                        togglePromotionsText () {
                            const self = this as CompType;
                            return self.ignorePromotions ? i18next.t("pos.togglePromotionsOn") : i18next.t("pos.togglePromotionsOff");
                        },

                        moreOptionsList  () {
                            const self = this as CompType;
                            let moreOptionsList = {
                                onClick: (index) => {
                                    self.setPrimaryListByIndex(index)
                                },
                                text: i18next.t("pos.saleActions"),
                                subList: []
                            };

                            if (!self.isRoshemet && !self.isDutyFree && (!self.hasFlights)) {
                                if (!this.isDeliverySale) {
                                    moreOptionsList.subList.push({
                                        onClick: (index) => {
                                            self.close();
                                            posVC.selectCustomerClubForSale();  
                                        },
                                        text: i18next.t("pos.clubMember"),
                                    });
                                }

                                moreOptionsList.subList.push({
                                    onClick: (index) => {
                                        self.close();
                                        posVC.balanceCheck();  
                                    },
                                    text: i18next.t("pos.balanceInquiry"),
                                });
                            }

                            if (self.showTogglePromotions) {
                                moreOptionsList.subList.push({
                                    onClick: (index) => {
                                        self.close();
                                        PositiveTS.Helper.SaleHelper.togglePromotions(!self.ignorePromotions);
                                        self.ignorePromotions = posVC.ignorePromotions;                                        
                                        self.renderActionLists[index].text = self.togglePromotionsText;
                                    },
                                    text: self.togglePromotionsText,
                                });
                            }

                            if(self.allowSplitSale){
                                moreOptionsList.subList.push({
                                    onClick: async () => { 
                                        self.close();
                                        await PositiveTS.VueInstance.$refs.pos.openSplitSaleScreen();
                                    },
                                    text: i18next.t('posPaymentButtons.splitSalePayment'),
                                });
                            }

                            moreOptionsList.subList.push(...[
                                {
                                    onClick: (index) => {
                                        self.close();
                                        Pinia.componentsStore.openComponent( {componentName:"calculatorDialog"});  
                                    },
                                    text: i18next.t("accountActions.calculator"),
                                },
                                {
                                    onClick: (index) => {
                                    self.close();
                                    printer.lineFeed();
                                    },
                                    text: i18next.t("accountActions.feedPaper"),
                                },
                                {
                                    onClick: (index) => {
                                        self.close();
                                        posVC.confirmSaleRestart();
                                    },
                                    text: i18next.t("accountActions.restartSale"),
                                }
                            ]);
                            if(self.isSittingOrTA){
                                moreOptionsList.subList.push({
                                    onClick: (index) => {
                                        self.close();
                                        Pinia.componentsStore.openComponent( 
                                        {componentName:"SittingOrTADialog", args: [true]});
                                    },
                                    text: i18next.t("accountActions.saleSittingOrTA"),
                                });
                            }


                            return moreOptionsList;
                        },

                        deliveriesList () {
                            const self = this as CompType;
                            let deliveriesList = {
                                onClick: (index) => {
                                    self.setPrimaryListByIndex(index)
                                },
                                text: i18next.t("delivery.showDeliveries"),
                                badge: this.openedOrdersCount,
                                subList: [
                                    {
                                        onClick: (index) => {
                                            self.close();
                                            PositiveTS.VueInstance.$refs.pos.openOpenDeliveries(); 
                                        },
                                        text: i18next.t("delivery.showDeliveries"),
                                    },
                                    {
                                        onClick: (index) => {
                                            self.close();
                                            Pinia.componentsStore.openComponent( {componentName:"externalOrdersList"});
                                        },
                                        text: i18next.t("menuSideBar.internetDeliveries"),
                                    },
                                    {
                                        onClick: (index) => {
                                            self.close();
                                            PositiveTS.VueInstance.$refs.pos.closeDeliveryOrdersDialog(); 
                                        },
                                        text: i18next.t("pos.closeDeliveries"),
                                    },
                                    {
                                        onClick: (index) => {
                                            self.close();
                                            PositiveTS.VueInstance.$refs.pos.openCouriersAssignmentDialog();
                                        },
                                        text: i18next.t("pos.assignCouriers"),
                                    },
                                ]
                            };

                            return deliveriesList;
                        },

                        actionLists () {
                            const self = this as CompType;
                            let actionLists =  [];

                            if (self.isDelivery){
                                actionLists.push(self.deliveriesList);
                            }

                            actionLists.push({
                                onClick: (index) => {
                                    self.close();
                                    Pinia.componentsStore.openComponent( {componentName:"fullSearch"});
                                },
                                text: i18next.t("pos.search"),
                            });

                            if (!self.hasFlights) {
                                actionLists.push({
                                    onClick: (index) => {
                                        self.close();
                                        this.restaurantNewPOS ?
                                        Pinia.componentsStore.openComponent( {componentName:"dedicatedToDialog"})
                                        :
                                        Service.DedicatedTo.openSetDedicatedTo();
                                    },
                                    text: i18next.t("pos.dedicatedTo"),
                                });
                            }

                            if (self.hasDiners) {
                                actionLists.push({
                                    onClick: (index) => {
                                        self.close();
                                        Pinia.componentsStore.openComponent( {componentName:"selectDinersNumberDialog"});
                                    },
                                    text: i18next.t("selectDinersNumberDialog.title"),
                                });
                            }

                            if (!self.hideDiscounts) {
                                actionLists.push({
                                    onClick: (index) => {
                                        self.close();
                                        posVC.selectDiscountForSale();
                                    },
                                    text: i18next.t("pos.saleDiscount"),
                                });
                            }

                            if (self.isHasTips) {
                                actionLists.push({
                                    onClick: (index) => {
                                        self.close();
                                        PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.openTipPaymentScreen();
                                    },
                                    text: i18next.t("posPaymentButtons.tip"),
                                });
                            }

                            actionLists.push(self.moreOptionsList);

                            return actionLists;
                        },

                        renderActionLists () {
                            const self = this as CompType;
                            let actionLists =  self.actionLists;

                            if (self.primaryList){
                                actionLists = self.primaryList.subList;
                            }

                            return actionLists;
                        },
                        
                        offlineSaleCountText () {
                            let offlineSaleCount = this.offlineSaleCount == 0 ? i18next.t('none') : this.offlineSaleCount;
                            return `${offlineSaleCount} ${i18next.t("offlineSales")}`;
                        },

                        isHasTips () {
                            return Service.Tip.isHasTips();
                        },
                        offlineSaleCount(){
                            return Pinia.globalStore.offlineSaleCount == 0 ? i18next.t('none') :  Pinia.globalStore.offlineSaleCount
                        },
                    },
                    setup(){
                        const dalpaksStore = Pinia.useDalpaksStore();
                        const {openedOrdersCount} = Pinia.storeToRefs(dalpaksStore);

                        const globalStore = Pinia.useGlobalStore();
                        const {
                            isDeliverySale,
                            storeLogoUrl,
                            emvPosNumber,
                            isOnline,
                            cashierName,
                            mobileLayout,
                            supportNumber
                        } = Pinia.storeToRefs(globalStore);

                        const globalStoreProps = {
                            isDeliverySale,
                            storeLogoUrl,
                            emvPosNumber,
                            stateIsOnline:isOnline,
                            cashierName,
                            mobileLayout,
                            supportNumber
                        } 

                        return {...globalStoreProps,openedOrdersCount}
                    },

                    methods: {
                        open () {
                            const self = this as CompType;
                            self.opened = true;
                        },

                        close () {
                            const self = this as CompType;
                            self.opened = false;
                        },

                        setPrimaryListByIndex (index: string) {
                            const self = this as CompType;
                            self.primaryList = self.renderActionLists[index];
                        }
                    }
                };

                VueApp.component('menu-side-bar',component);
                return defineComponent(component);
            }
        }
    }
}
