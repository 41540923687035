module PositiveTS {
export module Storage {
export module Entity {
export class Dalpak extends IDBEntity implements Service.DalpakInfra.Dalpak {

	color: string;
	name: string;
	sortOrder: number;
	lockedBy: string;
	isPrinted: boolean;
	sale: any;
	area: any;
	railsId: number;
	data: string;

	constructor() {
		let meta = {
			name: 'Dalpaks',
			fields: {
				color: "TEXT",
				name: "TEXT",
				sortOrder: "INT",
				lockedBy: "TEXT",
				isPrinted: "BOOL",
				sale: "TEXT",
				area: "TEXT",
				railsId: "INT",
				data: "TEXT",
			},
		}
		super(meta)
	}
}}}}
