<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { globalStore } from "@/Store/Pinia";

const state = reactive({
    isOpen: false,
    loading: false,
    isRestaurantNewPOS: PositiveTS.Service.Dalpak.isRestaurantNewPOS(),
});

const dialogElement = ref(null);


function open() {
    $(document).unbind('keypress');
    if (!dialogElement.value.open) {
        dialogElement.value.showModal();
    }
    state.isOpen = true;
}

function close() {
    dialogElement.value.close();
    state.isOpen = false;
    $(document).unbind("keypress");
    $(document).keypress(posVC.onKeyPress);
}

defineExpose({ open, close });
</script>

<template>
    <dialog ref="dialogElement" class="w-90vw p2:w-[55vw]" v-bind:class="{
        'new-restaurant-dialog': state.isRestaurantNewPOS,
        'simple-self-service': globalStore.simpleSelfService,
        'self-service pop-in': globalStore.onSelfServicePaymentDialog
    }" :class="{ 'positive-dialog': !globalStore.simpleSelfService }">
        <div class="dialog-container h-auto w-[55vw] max-p2:w-full max-p2:left-0 bg-white" v-if="state.isOpen">
            <div class="title bg-primary-default text-center text-white py-2">
                <slot name="title"></slot>
            </div>
            <slot name="content"></slot>

        </div>

    </dialog>
</template>


<style lang="scss"></style>
