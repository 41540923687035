module PositiveTS {
  export module Components {
    export module RestaurantOrderSummary {
      export function getComponent() {
        let component = {
          template: JST.RestaurantOrderSummary(),
          data() {
            return {
              hasNewPromotions: session.pos.useNewPromotions,
              dedicatedTo: "",
            };
          },
          async updated() { 
            this.open();
          },
          components: {
            SingleItem: PositiveTS.Components.RestaurantSingleItem.getComponent(),
            RestaurantItemActions: PositiveTS.Components.RestaurantItemActions.getComponent(),
            PromoGroupItem: PositiveTS.Components.RestaurantPromoGroupItem.getComponent(),
            PositiveIcon: PositiveIcon.getComponent(),
          },
          computed: {
            saleItemsTableViewMessage(){
              return Pinia.globalStore.mobileLayout ? i18next.t("emptyTableViewMessageMobile") : Pinia.globalStore.saleItemsTableViewMessage
            },
            getCurrentCustomer() {
              return this.dedicatedTo !=
                this.posCustomerNameData?.displayName && this.dedicatedTo != ""
                ? this.dedicatedTo
                : this.posCustomerNameData?.displayName;
            },
            getSaleDiscounts() {
              let result = this.promoGroups.filter((pg: Promotions.PromoGroup) => {
                  return pg.isSaleDiscount && pg.discountAmountForGroup;
              })
              return result;
          },
            currencySign() {
              return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
            },
            scaleOSD(){
              return jsonConfig.getVal(jsonConfig.KEYS.scaleCom) == Service.Scale.S2Scale;
            },

            restaurantItemMenu(): Types.PositiveButtonProps {
              return {
                text: i18next.t('pos.dalpakPage.restaurantItemMenu'),
                border: false,
                classes: 'restaurant-btn',
                icon: 'fa-light fa-clipboard-list-check',
                onClick: () => {
                  PositiveTS.VueInstance.$refs.pos.showOrderSummary = false;
                }
              }
            },

            goToPaymentBtn(): Types.PositiveButtonProps {
              return {
                text: i18next.t('pos.dalpakPage.goToPayment'),
                border: false, classes: 'restaurant-btn',
                icon: 'fa-light fa-money-bill-1',
                onClick: PositiveTS.VueInstance.$refs.pos.openPaymentScreenSingleButton,
              };
            },
            displaygoToPaymentBtn() {
              return this.totalQuantity > 0;
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const posStore = Pinia.usePosStore();

            const {getCurrentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);
            const {expandedView,selectModeActive,selectedCount} = Pinia.storeToRefs(posStore);

            
            const globalStore = Pinia.useGlobalStore();
            const {
              canChangeQuantity,
              saleItems,
              saleTotal,
              mobileLayout,
              simpleSelfService,
              sale,
              selectedSaleItem,
              multipleSelectedSaleItems,
              saleItemsTableViewMessage,
              portraitMode,
              isRoshemet,
              promoGroups,
              selfServiceSuperMarket,
              rtlMode,
              effectiveMigvanId,
              totalQuantity,
              posCustomerNameData,
            } = Pinia.storeToRefs(globalStore);


            const globalStoreProps = {
              saleItemsTableViewMessage,
              canChangeQuantity,
              posCustomerNameData,
              totalQuantity,
              effectiveMigvanId,
              rtlMode,
              selfServiceSuperMarket,
              promoGroups,
              isRoshemet,
              portraitMode,
              noSaleItems:(saleItems.value.length == 0),
              multipleSelectedSaleItems,
              selectedSaleItem,
              sale,
              simpleSelfService,
              saleItems,
              saleTotal,
              mobileLayout,
              reverseSaleItems:(saleItems.value.slice().reverse())
            } 

            const {leaveEditMode,setManualQuantitiy} = posStore

            return {...globalStoreProps,getCurrentSelectedDalpak,expandedView,selectModeActive,selectedCount,leaveEditMode,setManualQuantitiy}
        },
          methods: {
            async updateItemQuantity(data){
              if(this.canChangeQuantity(data.item)){
                  await this.setManualQuantitiy({selectedItem:data.item, quantityToUpdate: data.quantityToUpdate})
              }
          },
            toggleExpanedView() {
              Pinia.posStore.toggleExpandedView();
            },
            open() {
              Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.RestaurantOrderSummary);
              PositiveTS.VueInstance.$refs.pos.showOrderSummary = true;
              this.dedicatedTo = PositiveTS.Service.DedicatedTo.getCurrentDedicatedTo(posVC.sale);
            },

          }
        }
        return component;
      }
    }
  }
}