module PositiveTS {
  export module Components {
    export module FlightsChangeTeam {

      export function getComponent() {
        return {
          template: JST.flightsChangeTeam(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.barsetMixin],
          methods: {
            answer(isTeamChange) {
              this.stepData = { isTeamChange };
              this.goToNextStep();
            }
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {isLastLeg} = Pinia.storeToRefs(flightsStore);
            return {isLastLeg}
          },
        }
      }
    }
  }
}