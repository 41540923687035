module PositiveTS {
export module Promotions {
export module Templates {
export class BuyAboveXWithItemGetSaleDiscount extends BuyAboveXGetSaleDiscount {
  constructor(initParameters) {
    super(initParameters)
  }

  calculatePromotions() {
      // Go over the promotions to decide what to do next
      for (var promotionCode in this.parameters.promotionsByCode) {

          var promotion = this.parameters.promotionsByCode[promotionCode];

          // Check that this promotion is from template 1
          if (promotion.template !== '7') {
              continue;
          }

          this.applyPromotion(promotion);
      }
  }

  applyPromotion(promotion) {
      var parameters = this.parameters;

      if (parameters.saleItems.filter((item) => { return (item.itemCode === "123890000")}).length === 0) {
        return;
      }

      super.applyPromotion.call(this,promotion);
  }
}
}}}
