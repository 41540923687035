module PositiveTS {
  export module Service {
    export module AddItem {
      const CREATE_URL = "/items";
      const GENERIC_ITEM_CODE = "1000";

      export async function createItem(item) {
        if (session.pos.isRoshemet){
          return createItemRoshemet(item)
        }

        if (!Pinia.globalStore.isOnline) {
          app.showAlertDialog({
            header: i18next.t('error'),
            content: i18next.t('mustBeOnline'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          });
          return;
        }

        if (!isValidItem(item)) {
          return;
        }

        if (!getGenericItem()) {
          app.showAlertDialog({
            header: i18next.t('error'),
            content: i18next.t('addItemDialog.noGenericItemFound', { item_code: GENERIC_ITEM_CODE }),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          });
          return;
        }

        app.showLoadingMessage(i18next.t('addItemDialog.addingItemPleaseWait'))

        let response = await createItemAtServer(item);

        if (posUtils.isBlank(response)) {
          app.hideLoadingMessage();
          return;
        }

        let itemFromServer = response.item;
        let barcode = response.barcode;

        let localItem = getLocalItem(item, itemFromServer);

        await saveItemToLocalDb(localItem, barcode);
        
        app.hideLoadingMessage();

        return localItem;
      }

      export async function createItemAtServer(item) {
        try {
          let response = await PositiveTS.Service.FetchReq.jsonReq(CREATE_URL, 'post', { item });

          if (!(response.result && response.result.success)) {

            if (response.result.errors) {

              let errors = Object.values(response.result.errors);

              if (errors.length > 0 && (errors[0] as any).length > 0) {
                showError(errors[0][0]);
                return;

              } else {
                throw new Error(response.result);
              }

            } else {
              throw new Error(response.result);
            }

          }

          return response.result;
        } catch (err) {
          showError(i18next.t('requestFailed'));
          return;
        }
      }

      function getLocalItem(item, serverItem) {
        let genericItem = getGenericItem();
        let propsToAdd = {
          id: serverItem.id,
          code: serverItem.code,
          description: serverItem.name,
          priceZarhan: item.priceZarhan,
          primaryCategoryId: serverItem.primary_category_id || 0,
          noVat: serverItem.no_vat ? 1 : 0,
          hasWeight: serverItem.has_weight ? 1 : 0,
        };

        return { ...genericItem, ...propsToAdd };
      }

      function getGenericItem() {
        return session.allItems.get(GENERIC_ITEM_CODE);
      }

      async function saveItemToLocalDb(item, barcode) {
        let coderev = item.code.split('').reverse().join('');
        let barcoderev = barcode.split('').reverse().join('');

        let query = `INSERT INTO Item (${Object.keys(item).join(',')})
                     VALUES (${Object.values(item).map(val => posUtils.isNumeric(val) ? val : '"' + val + '"').join(',')});`;
        query += `INSERT INTO itemBarcode (barcode, code, barcoderev, coderev, timestamp) VALUES ("${barcode}", "${item.code}", "${barcoderev}", "${coderev}", ${Date.now()});`
        query += `INSERT INTO itemSearch (barcode, code, color, size, description, priceZarhan, barcoderev, coderev)
                      SELECT itembarcode.barcode,itembarcode.code,color,size,item.description,item.priceZarhan, itembarcode.barcoderev, itembarcode.coderev
                      FROM itembarcode LEFT OUTER JOIN item ON item.code = itembarcode.code WHERE item.code = ${item.code};`;

        await Service.WasmDB.execAndSave(query);
      }

      export function isValidItem(item) {
        if (posUtils.isBlank(item.priceZarhan) || posUtils.isBlank(item.code) || posUtils.isBlank(item.name)) {
          showError(i18next.t("addItemDialog.fillAllFields"));
          return false;
        }


        if (!posUtils.isNumeric(item.priceZarhan) || item.priceZarhan < 0) {
          showError(i18next.t("addItemDialog.insertValidPrice"));
          return false;
        }

        if (item.name.includes('"')) {
          showError(i18next.t("addItemDialog.invalidNameChar"));
          return false;
        }

        return true;
      }

      export function showError(errorText) {
        return app.showAlertDialog({
          header: i18next.t('error'),
          content: errorText,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        })
      }

      export async function createItemRoshemet(item) {
        if (!isValidItem(item)) {
          return;
        }
        
        if (!getGenericItem()) {
          app.showAlertDialog({
            header: i18next.t('error'),
            content: i18next.t('addItemDialog.noGenericItemFound', { item_code: GENERIC_ITEM_CODE }),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          });
          return;
        }

        app.showLoadingMessage(i18next.t('addItemDialog.addingItemPleaseWait'))
        let localItem: Storage.Entity.Item = null
        let tempItem = new PositiveTS.Storage.Entity.Item()
        let localItems = await appDB.localItems.toArray()
        let lastItemCode = 10000

        for(let item of localItems){
          if(Number(item.code) > lastItemCode){
            lastItemCode = Number(item.code);
          }
        }

        lastItemCode++;
        while (session.allItemBarcodes.get(String(lastItemCode)) != null) {
          lastItemCode++;
        }
        
        tempItem.code = String(lastItemCode)
        tempItem.description = item.name.trim()
        tempItem.priceZarhan = session.fixedFloat(item.priceZarhan)
        tempItem.barcode = item.code
        tempItem.departmentId = -1;
        tempItem.serverDepartmentId = -1;
        tempItem.timestamp = new Date().getTime();
        tempItem.logicalOrGroupPrinters = "[]";
        tempItem.discounts = "1&2&3&4&5&6&7&8&9&10";
        tempItem.promoBuy = " "
        tempItem.promoGet = " "
        tempItem.promoSal3 = " "
        tempItem.promoSal4 = " "
        tempItem.allowZeroPrice = true;
        tempItem.invDelta = 0
        tempItem.noVat = item.noVat ? true : false;
        tempItem.hasWeight = item.hasWeight ? true: false;
        
        tempItem.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
        if (tempItem._data) {
          tempItem._data.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
        }

        let itemId = await appDB.localItems.put(tempItem)
        localItem = Storage.Entity.Item.import(await appDB.localItems.get(itemId))
        session.allItems.set(localItem.code, localItem)

        if (!session.allItemBarcodes.get(localItem.barcode)){
          session.allItemBarcodes.set(localItem.barcode, <any>localItem);
        }
        
        app.hideLoadingMessage()
        return localItem
      }
    }
  }
}
