module PositiveTS {
  export module Service {
    export module ScanService {
      export async function scanQrCode(options = {}): Promise<string|null> {
        if (session.isAndroid && Pinia.globalStore.mobileLayout && !jsonConfig.getVal(jsonConfig.KEYS.forceQRScanWithUsbScanner)) {
          app.showLoadingMessage(i18next.t("flightsOnlineLoginDialog.pleaseScanQrCode"));

          let data = Android.scanQrCode();

          app.hideLoadingMessage();

          return data;
        } else {
          let { success, barcode } = await Pinia.componentsStore.openComponent( {componentName:"scanBarcodeDialog", args: [null, null, options]});
          return success ? barcode : null;
        }
      }
    }
  }
}
