module PositiveTS {
   export module Service {
      export module PrimaryPosPrint {

         const PRINT_JOB_TYPES = {
            BON: 0,
            MAIN_PRINTER: 1,
         }

         let syncModule: WebsocketSync.PrimaryPosPrinterSyncModule = null;

         export function init() {
            syncModule = new WebsocketSync.PrimaryPosPrinterSyncModule(primaryPosPrint);
            WebsocketSync.SyncServerClient.getInstance().addModule(syncModule);
         }

         export function isActive() {
            return  isPrintBonAtPrimaryPosActice() || isPrintRegularAtPrimaryPosActive() || jsonConfig.getVal(jsonConfig.KEYS.isPrimaryPosPrinter);
         }

         export function isPrintBonAtPrimaryPosActice() {
            return jsonConfig.getVal(jsonConfig.KEYS.dalpkaimPrintOnlyInMainPos);
         }

         export function isPrintRegularAtPrimaryPosActive() {
            return jsonConfig.getVal(jsonConfig.KEYS.printWithPrimaryPos);
         }


         async function primaryPosPrint(data: any[]) {
            if (Service.WebsocketSync.SyncServerClient.isPrimaryPos()) {
               let jobType = data.shift();
               switch (jobType) {
                  case PRINT_JOB_TYPES.BON: 
                     //There are 4 fixed variables
                     //The rest is extraData
                     let [sale, saleItems, salePayments, bonType] = data;
                     let extraData = _.drop(data, 4)

                     saleItems = saleItems.map(si => {
                        let objectSaleItem = new Storage.Entity.SaleItem();
                        objectSaleItem.importFromObject(si);
                        return objectSaleItem;
                     })
      
                     saleItems = saleItemHelper.unflattenSaleItems(saleItems);
                     return await Service.LogicalPrinterBonPrint.sendBonToLogicalPrintersByBonType(sale, saleItems, salePayments, bonType, extraData)
                     break;
                  case PRINT_JOB_TYPES.MAIN_PRINTER:
                     let printingData = data.shift();
                     return await printer.jzebra.printData(printingData);
                     break;

                  default:
                     Service.Logger.error(`Printer job type not found. job type: ${jobType}`);
                     Service.Logger.error(_.toString(data));
                     break;
               }
               
            }
         }

         export async function sendBonToPrimaryPosPrinter(sale, saleItems, salePayments, extraData = []) {
            let fulleSale = Service.FullSale.getFullSaleFormat(sale, saleItems, salePayments);
            let items = fulleSale.items;
            let payments = fulleSale.payments;

            delete fulleSale.items;
            delete fulleSale.payments;

            return await syncModule.sendPrintJobToPrimaryPos([PRINT_JOB_TYPES.BON, fulleSale, items, payments, ...extraData]);
         }

         export async function sendPrintJobToPrimaryPosPrinter(data) {
            return await syncModule.sendPrintJobToPrimaryPos([PRINT_JOB_TYPES.MAIN_PRINTER, data]);
         }
      }
   }
}