module PositiveTS {
export module Components {
    export module MizdamenOrHakafaSelfServiceDialog {

      const STEPS = {
        SELECT_CUSTOMER: 0, 
        CARD_OR_INPUT: 1,
        SWIPE_CARD: 2,
        KEYPAD_INPUT: 3,
      }

      export function create() {

        let component = {
            template: JST.mizdamenOrHakafaSelfServiceDialog(),
            components: {
              selfServicePage: SelfServicePage.getComponent(),
              positiveIcon: PositiveIcon.getComponent(),
              selfServiceKeypad: SelfServiceKeypad.getComponent(),
            },
            methods: {
              open() {
                this.cleanData();
                this.$el.show();
              },
              async close() {
                this.$el.close();
              },
              async selectOccasionalCustomer() {
                await Service.MizdamenOrHakafaSerive.selectOccasionalCustomer();
                this.close();
              },
              goBack() {
                if (this.step == STEPS.KEYPAD_INPUT) {
                  this.setStep(STEPS.CARD_OR_INPUT);
                } else if (this.step == STEPS.CARD_OR_INPUT) {
                  this.setStep(STEPS.SELECT_CUSTOMER);
                }
              },
              setStep(step) {
                this.step = step;

                if (this.step == STEPS.SWIPE_CARD) {
                  this.swipeCustomerCard();
                }
              },
              async searchAndSetCustomer(cardNumber) {
                this.customerCard = cardNumber;
                app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.searchingCustomer'));
                
                try {
                  let result = await Service.MizdamenOrHakafaSerive.searchCustomer(this.customerCard);
        
                  app.hideLoadingMessageDialog();
        
                  if (result.success) {
                    await Service.MizdamenOrHakafaSerive.selectCustomer(result.customer);
                    this.close();
                  } else {
                    await app.showAlertDialog({
                      header: i18next.t('error'),
                      content: result.error,
                      hideCancelButton: true
                    });
                    this.setStep(STEPS.CARD_OR_INPUT);
                  }
                } catch (err) {
                  app.hideLoadingMessageDialog();
                  Service.Logger.error(err);
                  await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('generalError'),
                    hideCancelButton: true
                  });
                  this.setStep(STEPS.CARD_OR_INPUT);
                }
              },
              async swipeCustomerCard() {
                try {
                  let customerCard = await Service.EMV.swipe();

                  if (posUtils.isBlank(customerCard)) {
                    this.setStep(STEPS.CARD_OR_INPUT)
                    return;
                  }

                  this.searchAndSetCustomer(customerCard);
                } catch (err) {
                  Service.Logger.error(err);
                  await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('generalError'),
                    hideCancelButton: true
                  });
                  this.setStep(STEPS.KEYPAD_INPUT)
                }
              },
              cleanData() {
                this.setStep(STEPS.SELECT_CUSTOMER);
                this.customerCard = '';
              },
            },
            computed: {
              titles() {
                if (this.step == STEPS.CARD_OR_INPUT) {
                  return {
                    title: this.$t('mizdamenOrHakafaDialog.existingCustomer'),
                    subtitle: this.$t('mizdamenOrHakafaDialog.happyToSeeYou'),
                  }
                }

                if (this.step == STEPS.SWIPE_CARD || this.step == STEPS.KEYPAD_INPUT) {
                  return {
                    title: this.$t('mizdamenOrHakafaDialog.existingCustomer'),
                    subtitle: this.step == STEPS.SWIPE_CARD ? this.$t('mizdamenOrHakafaDialog.pleaseSwipeCard') : this.$t('selfService.insertCardNumber'),
                  }
                }

                return {
                  title: this.$t('mizdamenOrHakafaDialog.welcome'),
                  subtitle: this.$t('mizdamenOrHakafaDialog.selectCustomerType')
                }
              },
              showGoBack() {
                return [STEPS.CARD_OR_INPUT, STEPS.KEYPAD_INPUT].includes(this.step);
              },
              displayLanguageSelector() {
                return this.step != STEPS.SWIPE_CARD;
              },
            },
            data() {
              return {
                customerCard: '',
                step: STEPS.SELECT_CUSTOMER,
                STEPS,
              }
            },

        }

        VueApp.component('mizdamen-or-hakafa-self-service-dialog',component)

      }
    }
  }
}
