module PositiveTS {
  export module Service {
    export module ShekuloTovBooks {

      interface FurtherData {
        userIdentifiers: Array<String>,
        posIdentifier: String,
        dealSum: Number,
        numOfBooksSold: Number
      }

      export async function getBookInfo(barcode: string): Promise<any> {  

        let url = `https://api.rebooks.cc/posEndpoints/getBookDetailsByCopyBarcode?copyBarcode=${barcode}`
        let _data = {
          "body": "", "type": "GET",
          "url": url,
        };

        let data = JSON.stringify(_data);

        app.showLoadingMessageDialog(i18next.t(`shekuloTovBooks.importingBookInfo`));

        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "external_service"
        )

        app.hideLoadingMessageDialog();

        let result = JSON.parse(response.request.result)
        result.error = null

        if (result.status !== 200) {
          result.error = i18next.t(`shekuloTovBooks.${result.status}`)
        }

        return result

      }

      export async function sendBooksBarcodes(barcodes: Array<string>, furtherSaleData: string): Promise<any> {
        let url = `https://api.rebooks.cc/posEndpoints/getSoldCopiesArray?soldCopiesString=${barcodes}&furtherData=${furtherSaleData}`
        let _data = {
          "body": "", "type": "POST",
          "url": url,
        };

        let data = JSON.stringify(_data);

        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "external_service"
        )

        let result = JSON.parse(response.request.result)
        result.error = null


        if (result.status !== 200){
          result.error = i18next.t(`shekuloTovBooks.${result.status}`)
        }

        return result

      }

      export function checkBarcodesLength():boolean {
        if (Pinia.globalStore.shekuloTovBookBarcodes.length > 0) {
          return true
        }
          return false
      }

      export async function prepareBarcodesAndSend() {
        const {salespersonEmployeeID} = posVC.sale
        let soldBooksBarcodes = Pinia.globalStore.shekuloTovBookBarcodes.map(barcode => {return barcode.barcode})
        const posIdentifier = `${session.store.storeID}${session.pos.posNumber >= 10 ? `${session.pos.posNumber}` : `0${session.pos.posNumber}`}`
        
        let furtherSaleData: FurtherData = {
          userIdentifiers: [salespersonEmployeeID],
          posIdentifier: posIdentifier,
          dealSum: posVC.getTotalAmount(),
          numOfBooksSold: soldBooksBarcodes.length
        }
        let result = await sendBooksBarcodes(soldBooksBarcodes, JSON.stringify(furtherSaleData))

        return result
      }

      export async function checkForSpecialItemsAndFetch(saleItem : Storage.Entity.SaleItem): Promise<any> {
        let isSpecialItem = posVC.Specialitems.filter(si => {return si.code === saleItem.barcode})

          if(isSpecialItem.length > 0 && isSpecialItem[0].hndlr === "SHEKULO_TOV_BOOK"){
            let result = await Pinia.componentsStore.openComponent( {componentName:"scanBarcodeDialog", args: []})

            if(!result.success && posUtils.isNullOrUndefinedOrEmptyString(result.barcode)){
              return {userCancelled: true, error: null}
            }

            let bookInfo = await getBookInfo(result.barcode)

            if (bookInfo.data && bookInfo.data.numOfCopies && bookInfo.data.numOfCopies < 1) {
              return {userCancelled: false, error: i18next.t(`shekuloTovBooks.outOfStock`)}
            }

            if(bookInfo.error !== null) {
              return {userCancelled: false, error: bookInfo.error}
            }

            saleItem.itemDescription = bookInfo.data.bookName
            Pinia.globalStore.handleShekuloTovBarcodes({type: "insert", item: {barcode: result.barcode, rowNumber: saleItem.rowNumber}})

            return { userCancelled: false, error: null }
          }

          return { userCancelled: false, error: null }
      }

    }
  }
}