module PositiveTS {
    export module Service {
        export module Gateway {
            enum FINANCIAL_STATUS {
                AUTHORIZED,
                REJECTED,
                CAPTURED,
                TRANSMITTED,
                PENDING,
                CANCELLED
            }


            export function isEnabled() {
                return Boolean(jsonConfig.getVal(jsonConfig.KEYS.isGatewayPayment))
            }

            export function terminalEmvWithChecksum(){
                let emvNum = session.pos.emvTerminalNumber
                if(session.pos.emvTerminalNumber?.length != 7){
                    throw new Error("The terminal Number length must be 7")
                }
                let checksum = 
                    ((2 * Number(emvNum[0])) + 
                    (3* Number(emvNum[1])) + 
                    (4* Number(emvNum[2])) + 
                    (5* Number(emvNum[3]))  + 
                    (6 * Number(emvNum[4])) + 
                    (8 * Number(emvNum[5])) +
                    (9 * Number(emvNum[6])) +
                    (10 * 0) +
                    (11 * 1)) % 7
                return `${emvNum}01${checksum}`;
            }

            export function getGatewayEmvPosNum() {
                let gatewayEmvPosNum = jsonConfig.getVal(jsonConfig.KEYS.gatewayEmvPosNum)
                if (!gatewayEmvPosNum) {
                    throw new Error(i18next.t('creditCardPayment.gateway.errors.slaveTerminalNumberNotSet'))
                }
                return `${gatewayEmvPosNum}`.padStart(3, '0'); ;
            }



            export async function buildInquireByTerminalIntInXml(termnialNumber,fromDate, toDate, pageNumber) {
                try {
                    let shiftId1 = getShiftId1();
                    let shiftId2 = getShiftId2();
                    return `<ashrait><request><version>2000</version><command>inquireTransactions</command><inquireTransactions>
                            <terminalNumber>${termnialNumber}</terminalNumber>
                            <shiftId1>${shiftId1}</shiftId1>
                            <shiftId2>${shiftId2}</shiftId2>
                            <pageNumber>${pageNumber}</pageNumber>
                            <fromShiftTxnDate>${fromDate}</fromShiftTxnDate>
                            <toShiftTxnDate>${toDate}</toShiftTxnDate>
                            <financialStatus>${FINANCIAL_STATUS[2]}</financialStatus>
                            <resultType>Paging</resultType></inquireTransactions></request></ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export function getShiftId1(){    
                return session.pos.deviceID;
            }

            export function getShiftId2() {
                return session.pos.posNumber ? session.pos.posNumber : 1;
            }

            export async function getShiftId3(){
                try{
                    let currentZNumberRow = await Service.ZReport.getLastPosZnumberRow();
                    let nextZNumber = currentZNumberRow.sequence + 1;
                    let shiftId3 = nextZNumber;
                    return shiftId3;
                }catch(err){
                    console.error(err)
                    return Promise.reject(err)
                }

            }

            export async function buildCreditPaymentIntInXml(creditCardDetails ){
                try{
                    let user,cardExpiration,cardId,currency,total,slaveTerminalNumber,shiftTxnDate;
                    user = creditCardDetails.User;
                    cardExpiration = creditCardDetails.CardExpiration;
                    slaveTerminalNumber = creditCardDetails.SlaveTerminalNumber;
                    cardId = creditCardDetails.CardId;
                    currency = creditCardDetails.Currency;
                    total = creditCardDetails.Amount;
                    let terminalNumber = creditCardDetails.TerminalId;
                    shiftTxnDate = moment().format('YYYY-MM-DD HH:mm:ss')

                    let shiftId1 = getShiftId1();
                    let shiftId2 = getShiftId2();
                    let shiftId3 = await getShiftId3();

                    return `<ashrait>
                    <request>
                    <version>2000</version>
                    <language>ENG</language>
                    <dateTime/><requestid/>
                    <command>doDeal</command><doDeal>
                    <user>${user}</user>
                    <terminalNumber>${terminalNumber}</terminalNumber>
                    <cardExpiration>${cardExpiration}</cardExpiration>
                    <slaveTerminalNumber>${slaveTerminalNumber}</slaveTerminalNumber>
                    <cardId>${cardId}</cardId>
                    <shiftId1>${shiftId1}</shiftId1>
                    <shiftId2>${shiftId2}</shiftId2>
                    <shiftId3>${shiftId3}</shiftId3>
                    <transactionType>Credit</transactionType>
                    <shiftTxnDate>${shiftTxnDate}</shiftTxnDate>
                    <creditType>RegularCredit</creditType>
                    <currency>${currency}</currency>
                    <transactionCode>Phone</transactionCode>
                    <total>${total}</total>
                    <validation>autoComm</validation>
                    </doDeal>
                    </request>
                    </ashrait>`
                }catch(err){
                    console.error(err);
                    return Promise.reject(err);
                }
            }

            export async function buildTransmitReportInXml(termnialNumber,transmitId) {
                try {
                    return `<ashrait>
                                <request>
                                <version>2000</version>
                                <command>transmitInquire</command>
                                <transmitInquire>
                                    <terminalNumber>${termnialNumber}</terminalNumber>
                                    <transmitId>${transmitId}</transmitId>
                                    <returnSlip>1</returnSlip>
                                </transmitInquire>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export async function buildTransmitsReportsByDatesInXml(termnialNumber,shiftStartDate) {
                try {
                    let shiftId1 = getShiftId1();
                    let shiftEndDate = moment().format('YYYY-MM-DD HH:mm:ss')
                    return `<ashrait>
                                <request>
                                <version>2000</version>
                                <command>transmitInquire</command>
                                <transmitInquire>
                                    <terminalNumber>${termnialNumber}</terminalNumber>
                                    <fromTransmitDateTime>${shiftStartDate}</fromTransmitDateTime>
                                    <toTransmitDateTime>${shiftEndDate}</toTransmitDateTime>
                                    <returnSlip>1</returnSlip>
                                </transmitInquire>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            function appendTransmitToZReport(zReport: Storage.Entity.ZReport,transmitReports) {  
                try{
                    let allTransmitsReports=[];
                    transmitReports.forEach(transmits =>{
                        transmits.forEach(tr => {
                            let transmitData = tr?.transmitStatistics?.ref;
                            let shfitId1 = getShiftId1();
                            if(transmitData.countTransmitted == 0 || transmitData["shiftId1"] != shfitId1){
                                return;
                            }
                            if(transmitData){
                                let stringedSlip = transmitData.slip?.row.map(slipRowData => {
                                    return slipRowData["#cdata"] ?? '';
                                })
                                tr["DepositReport"] = [stringedSlip]
                                tr["shvaReferanceNo"] = transmitData?.shvaReferanceNo
                                tr["currencyTotal"] = transmitData?.currencyTotal
                                tr["fileNumber"] = transmitData?.fileNumber
                                delete tr.transmitStatistics?.ref?.slip
                            }
                            allTransmitsReports.push(tr)
                        })
                    })
                    zReport.gatewayTransmitReport = JSON.stringify(allTransmitsReports)  
                }catch(err){
                    Logger.error(err)
                    throw err;
                }
            }

            export async function buildRefundTxByUidIntInXml(uid,TerminalId) {
                try {
                    return `<ashrait>
                    <request>
                    <command>refundDeal</command>
                    <requesteId/>
                    <dateTime/>
                    <version>2000</version>
                    <language>HEB</language>
                    <refundDeal>
                    <terminalNumber>${TerminalId}</terminalNumber>
                    <ashraitEmvData>
                    <orgUid>${uid}</orgUid>
                    </ashraitEmvData>
                    </refundDeal>
                    </request>
                    </ashrait>`
                            
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export async function buildPartialRefundTxByTranIdIntInXml(terminalNumber,tranId,amount) {
                try {
                    return `<ashrait>
                    <request>
                    <command>refundDeal</command>
                    <dateTime/>
                    <version>2000</version>
                    <language>HEB</language>
                    <refundDeal>
                        <terminalNumber>${terminalNumber}</terminalNumber>
                        <tranId>${tranId}</tranId>
                        <total>${amount}</total>
                    </refundDeal>
                    </request>
                   </ashrait>`
                            
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export async function buildInquireTxByUser(user,terminalNumber){
                try {
                    return `<ashrait>
                                <request>
                                    <requestId></requestId>
                                    <version>2000</version>
                                    <language>HEB</language>
                                    <dateTime></dateTime>
                                    <command>inquireTransactions</command>
                                    <inquireTransactions>
                                        <financialStatus>${FINANCIAL_STATUS[2]}</financialStatus>
                                        <user>${user}</user>
                                        <terminalNumber>${terminalNumber}</terminalNumber>
                                    </inquireTransactions>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }
            
            export async function buildRefundTxIntInXml(tranId,TerminalId) {
                try {
                    return `<ashrait>
                            <request>
                            <command>refundDeal</command>
                            <dateTime/>
                            <version>2000</version>
                            <language>Eng</language>
                            <refundDeal>
                            <terminalNumber>${TerminalId}</terminalNumber>
                            <tranId>${tranId}</tranId>
                            </refundDeal>
                            </request>
                            </ashrait>`
                            
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export async function buildTransmitImmediateIntInXml(terminalNumber,fromDate,toDate) {
                try {
                    let shiftId1 = getShiftId1();
                    let shiftId2 = getShiftId2();
                    return `<ashrait>
                                <request>
                                    <version>2000</version>
                                    <language>ENG</language>
                                    <dateTime/>
                                    <command>transmitTerminal</command>
                                    <transmitTerminal>
                                    <terminalNumber>${terminalNumber}</terminalNumber>
                                    <transmitType>settlements</transmitType>
                                    <shiftId1>${shiftId1}</shiftId1>
                                    <shiftId2>${shiftId2}</shiftId2>
                                    <shiftStartDate>${fromDate}</shiftStartDate>
                                    <shiftEndDate>${toDate}</shiftEndDate>
                                    <responseType>immediate</responseType>
                                    </transmitTerminal>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export async function sendRequestToCreditGuard(intIn) {
                try {
                    const CREDIT_GUARD_URL = session.pos.creditGuardUrl;
                    let crad = await getCradentials();
                    let requestXML = intIn;
                    let headers = { "Content-Type": "application/x-www-form-urlencoded" }
                    let url_params = new URLSearchParams();
                    let timeout = 120000;
                    url_params.append("user", crad.username);
                    url_params.append("password", crad.password);
                    url_params.append("int_in", requestXML);
                    let body = url_params.toString()
                    datadogLogs.logger.info(requestXML);
                    let response = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponseAndValidate(CREDIT_GUARD_URL, 'POST', body, headers, null, timeout, true)
                    datadogLogs.logger.info(JSON.stringify(response));
                    let convertedResponse = await convertedXmlResponse(response)
                    return convertedResponse;

                } catch (err) {
                    Logger.error(err);
                    return Promise.reject(err)
                }
            }

            function convertedXmlResponse(res) {
                try {
                    let parsedBody = PositiveShared.Utils.xml2json(res.body)
                    let response = parsedBody?.ashrait?.response;
                    if (response && (response.result == "000" || response.result == "314"  ||response.result == "310" )) {
                        return response;
                    } else if (response && response.message) {
                        throw new Error(`${response.result}: ${response.message}`)
                    }
                    else {
                        throw new Error(i18next.t('paymentPage.errors.fetchDataError'))
                    }

                } catch (err) {
                    console.error(err)
                    return Promise.reject(err)
                }
            } 

            export async function inquireTxsByTerminal(terminalNumber,fromDate, toDate) {
                try {
                    let allTxs = {};
                    let pageNumber = 1;
                    let totalPages = 1;
                    while (pageNumber <= totalPages) {

                        let int_in = await buildInquireByTerminalIntInXml(terminalNumber,fromDate, toDate, pageNumber);
                        let response = await sendRequestToCreditGuard(int_in);
                        if (response?.inquireTransactions) {
                            totalPages = response.inquireTransactions.totals.pagesAmount;
                            let transactions = response.inquireTransactions.transactions?.transaction ?? []
                            if(transactions && !(Array.isArray(transactions))){
                                transactions = [transactions]
                            }
                            for (let index = 0; index < transactions.length; index++) {
                                let transaction = { Xfield: "", uid: "", mti: "", tranId: "",TerminalId:""};
                                const inquireTx = transactions[index];
                                if ((inquireTx.validation["@code"] == "4" || inquireTx.validation["@code"] == "9") && inquireTx.status == "000" && inquireTx.ashraitEmvData.mti == PositiveShared.MtiType.COMMIT) {
                                    transaction["Xfield"] = inquireTx.user;
                                    transaction["uid"] = inquireTx.ashraitEmvData.uid;
                                    transaction["mti"] = inquireTx.ashraitEmvData.mti;
                                    transaction["tranId"] = inquireTx.tranId;
                                    transaction["TerminalId"] = inquireTx.terminalNumber;
                                    allTxs[inquireTx.ashraitEmvData.uid] = transaction
                                }
                            }
                        } else {
                            throw new Error(i18next.t('creditCardPayment.gateway.errors.gatewayZInquireRequest'))
                        }
                        pageNumber++;
                    }
                    return  allTxs;
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }

            export async function refundTxPromiseByTranID(tranID,TerminalId){
                    let int_in = await buildRefundTxIntInXml(tranID,TerminalId);
                    return  await sendRequestToCreditGuard(int_in);
            }


            export async function compareAndTransmit(zReport: Storage.Entity.ZReport) {
                try {
                    if (session.pos.isEmv) {
                        let creditCardPaymentsExisits = await zReportVC.isCreditCardPaymentsExists();
                        let connected = await Service.EMV.isPinPadConnected()
                        if (creditCardPaymentsExisits && connected) {
                            try {
                                await Service.EMV.transmitAndDeleteTranIfNeeded()
                            } catch (err) {
                                Logger.error(err)
                            }
                        }
                    }

                    let allTxsInGateway = {};
                    let terminals = [terminalEmvWithChecksum()]
                    let firstSale = await Helper.SaleHelper.getFirstSale();
                    let fromShiftTxnDate =  moment(firstSale.createdAt).subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    let toShiftTxnDate = moment().format('YYYY-MM-DD HH:mm:ss')
                    terminals = await retrieveAllTerminalsUsedOnSales(terminals)
                    for(let i=0;i<terminals.length;i++){
                        let inquireTxsByTerminalResponse =  await Service.Gateway.inquireTxsByTerminal(terminals[i],fromShiftTxnDate, toShiftTxnDate)
                        allTxsInGateway = {...allTxsInGateway,...inquireTxsByTerminalResponse}
                    }
                    
                    let txsToRefund = await compareGatewayToPosTxsToRefund(_.clone(allTxsInGateway));
                    if(Object.keys(txsToRefund).length > 0){
                        await refundGatewayTxs(txsToRefund)
                    }
                    
                    app.showLoadingMessage(i18next.t('creditCardPayment.gateway.transmitTxs'))
                    await PositiveShared.Utils.sleep(30000);
                    await transmitTerminalsTxs(terminals,fromShiftTxnDate,toShiftTxnDate)
                    app.showLoadingMessage(i18next.t('creditCardPayment.gateway.creatingTransmitReport'))
                    let transmitReports = await fetchTransmitsReportsByDates(terminals,fromShiftTxnDate)
                    appendTransmitToZReport(zReport,transmitReports)
                } catch (err) {
                    Logger.error(err)
                    return Promise.reject(err)
                }
            }

            export async function transmitTerminalsTxs(terminals,fromShiftTxnDate,toShiftTxnDate){
                try{
                    for(let i=0;i<terminals.length;i++){
                            await transmitTxs(terminals[i],fromShiftTxnDate, toShiftTxnDate);
                    }
                }catch(err){
                    Logger.error(err)
                    return Promise.reject(err)
                }
            }

            export async function fetchTransmitsReportsByDates(terminals,fromShiftTxnDate){
                try{    
                    let transmitReports = []
                    for(let i=0;i<terminals.length;i++){
                        let transmitReport =  await fetchTransmitReport(terminals[i],null,0,fromShiftTxnDate);
                        transmitReports.push(transmitReport)
                    }
                    return transmitReports;
                }catch(err){
                    Logger.error(err)
                    return Promise.reject(err)
                }
            }

            export async function createRefundPaymentGatewayOutput(response,creditCardData){
                try{

                    let responseDeal = response.doDeal ? response.doDeal : response.refundDeal;

                    let creditPaymentGatewayOutput = new PositiveShared.PaymentGatewayOutput();
                    Object.assign(creditPaymentGatewayOutput,{...creditCardData});

                    creditPaymentGatewayOutput.Command = "refundDeal"
                    creditPaymentGatewayOutput.DateTime = response.dateTime
                    creditPaymentGatewayOutput.TranID = response.tranId
                    creditPaymentGatewayOutput.OriginalPaymentTranId = creditCardData.TranID;
                    
                    if(response.result == "014"){
                        let inquireTxIntIn = await Service.Gateway.buildInquireTxByUser(creditCardData.User,creditCardData.TerminalId);
                        let inquireTxResponse = await PositiveTS.Service.Gateway.sendRequestToCreditGuard(inquireTxIntIn);
                        let transaction = inquireTxResponse?.inquireTransactions.transactions?.transaction;
                        if(transaction){
                            responseDeal = transaction;
                        }
                    }
                    creditPaymentGatewayOutput.Mti = responseDeal.ashraitEmvData.mti
                    creditPaymentGatewayOutput.IdFlag = responseDeal.ashraitEmvData.idFlag ?? ''
                    creditPaymentGatewayOutput.Uid = responseDeal.ashraitEmvData.uid 
                    creditPaymentGatewayOutput.CvvFlag = responseDeal.ashraitEmvData.cvvFlag ?? ''
                    creditPaymentGatewayOutput.TransactionType = responseDeal.transactionType["@code"]
                    return creditPaymentGatewayOutput
                }catch(err){
                    console.error(err);
                    return Promise.reject(err);
                }  
            }

            async function fetchTransmitReport(terminalNumber,transmitId=null,retry=0,fromShiftTxnDate){
                try{
                    await PositiveShared.Utils.sleep(3000);
                    let int_in
                    if(transmitId){
                        int_in = await buildTransmitReportInXml(terminalNumber,transmitId)
                        
                    }else{
                        int_in = await buildTransmitsReportsByDatesInXml(terminalNumber,fromShiftTxnDate)
                    }
                    
                    let transmitReportResult = await sendRequestToCreditGuard(int_in);
                    let transmitResult = transmitReportResult?.transmitInquire?.transmit ? transmitReportResult?.transmitInquire?.transmit : transmitReportResult?.transmitInquire;
                    let isTransmitValid = true;
                    if(!transmitResult[0]){
                        transmitResult = [transmitResult]
                    }
                    for(let i=0;i<transmitResult.length;i++){
                        if(!transmitResult[i].transmitStatus || transmitResult[i].transmitStatus != "completed"){
                            isTransmitValid = false
                        }
                    }
                    if(!isTransmitValid &&retry <=20){
                        retry++;
                        return await fetchTransmitReport(terminalNumber,transmitId,retry,fromShiftTxnDate)
                    }else if(isTransmitValid){
                        return transmitResult
                    }else{
                        throw new Error(i18next.t('creditCardPayment.gateway.errors.transmitReportNotValid'))
                    }
                }catch(err){
                    Logger.error(err);
                    if(retry <=20){
                        retry++;
                        return await fetchTransmitReport(terminalNumber,transmitId,retry,fromShiftTxnDate)
                    }else{
                        Logger.error(err);
                        return Promise.reject(err)
                    }
                }
            }

            async function transmitTxs(terminalNumber,fromDate,toDate){ 
                try{
                    let int_in = await buildTransmitImmediateIntInXml(terminalNumber,fromDate,toDate)
                    let transmitResult = await sendRequestToCreditGuard(int_in);
                    let transmitId = transmitResult?.transmitTerminal?.transmitId
                    if(transmitResult.result == "000" && transmitId){
                        return transmitId
                    }
                    else{
                        throw new Error(i18next.t('creditCardPayment.gateway.errors.trasmitFaild'))
                    }
                }catch(err){
                    Logger.error(err)
                    return Promise.reject(err);
                }
            }

            async function refundGatewayTxs(txsToRefund,retry = 0){
                try{
                    let tranIDs = [];
                    for (const key in txsToRefund) {
                        tranIDs.push({tranId: txsToRefund[key].tranId,TerminalId:txsToRefund[key].TerminalId})
                    }
                    let isRejected = false
                    let promises = tranIDs.map(tran => refundTxPromiseByTranID(tran.tranId,tran.TerminalId));
                    let refundDealsResponses = await Promise.allSettled(promises);
                    refundDealsResponses.forEach(refund => {
                        if(refund.status == "rejected" && refund.reason.message != "The transaction was already cancelled"){
                                isRejected = true;
                        }
                        else if(refund.status == "fulfilled"){
                            let dealStatus =  refund.value.refundDeal?.status == "314" || refund.value.refundDeal?.status == "000" || refund.value.refundDeal?.status == "310";
                            if(!dealStatus){
                                isRejected = true;
                            }
                        }
                    })
                    if(retry<3 && isRejected){
                        retry++;
                        return await refundGatewayTxs(txsToRefund,retry)
                    }else if(isRejected){
                        return Promise.reject("There have Txs that not cancelled successfully")
                    }
                }catch(err){
                    console.error(err)
                    return  Promise.reject(err)
                }
            }

            async function compareGatewayToPosTxsToRefund(GatewayTxs) {
                try {
                    let sales = await appDB.sales.where('invoiceSequence').above(0).toArray();
                    for (let sale of sales) {
                        for (let payment of sale.payments) {
                            if (payment.method == Storage.Entity.SalePayment.METHOD_CREDIT) {
                                let allCreditCardData = JSON.parse(payment.data)
                                if(allCreditCardData){
                                    for (let i = 0; i < allCreditCardData.length; i++) {
                                        if(allCreditCardData[i].isGateway || allCreditCardData[i].isEmv){
                                            let creditCardPaymentType = allCreditCardData[i].isGateway ? 'Gateway' : 'EMV';
                                            let paymentData = Object.assign(Service.CreditCardPayment.createOutputByType(creditCardPaymentType),allCreditCardData[i])
                                            let checkedTx = GatewayTxs[paymentData.Uid]
                                            if(checkedTx && checkedTx.mti == paymentData.Mti && checkedTx.Xfield == paymentData.Xfield && checkedTx.uid == paymentData.Uid ){
                                                delete GatewayTxs[paymentData.Uid]
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return GatewayTxs;
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err);
                }
            }

            async function retrieveAllTerminalsUsedOnSales(terminals){
                try {
                    let sales = await appDB.sales.where('invoiceSequence').above(0).toArray();
                    for (let sale of sales) {
                        for (let payment of sale.payments) {
                            if (payment.method == Storage.Entity.SalePayment.METHOD_CREDIT) {
                                let allCreditCardData = JSON.parse(payment.data)
                                if(allCreditCardData){
                                    for (let i = 0; i < allCreditCardData.length; i++) {
                                        if(allCreditCardData[i].isGateway){
                                            let creditCardPaymentType = allCreditCardData[i].isGateway ? 'Gateway' : 'EMV';
                                            let paymentData = Object.assign(Service.CreditCardPayment.createOutputByType(creditCardPaymentType),allCreditCardData[i])
                                            if (!terminals.includes(paymentData.TerminalId)) {
                                                terminals.push(paymentData.TerminalId);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return terminals;
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err);
                }
            }

            function getCradentials() {
                let username = session.pos.creditguardAuthUsername;
                let password = session.pos.creditguardAuthPassword;
                try {
                    if (!username || !password) {
                        return Promise.reject(i18next.t('paymentPage.errors.pleaseCheckSettings'))
                    }
                } catch (err) {
                    return Promise.reject(err)
                }
                return { username, password }
            }
        }
    }
}
