module PositiveTS {
  export module Components {
      export module PeriodReportDialog {
  
        const dialogSelector = "period-report-dialog"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            dateFormat: "dd/MM/yyyy",
            startDate: moment(new Date()).startOf("month").toDate(),
            endDate : moment(new Date()).endOf("month").toDate(),
            selectedMonth: null,
            printMonth: null,
            employees: [],
            isEmployeeReport: false,
            isMonthReport: false,
            selectedEmployee: null,
            deviceId: "",
            mode: 0,
            currentTitle: '',
            keyboardImg: `${(<any>window).images_path}/keyboard.jpg`,
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.periodReportDialog(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              async runReport () {
                try {
                  let reportPayments:any = [];
                  let employeeId = ( this.selectedEmployee!=null ? this.selectedEmployee.employeeID : 0 )
                  let employeeName = '';

                  if(employeeId>0){
                    employeeName = this.selectedEmployee.name;
                    if(employeeName == i18next.t("pos.generalEmployee")){
                      employeeId = Shared.Constants.Employee.GENERIC_EMPLOYEE;
                    }
                  }

                  if(employeeId==0 && this.mode==2){
                    app.showAlert({
                      header: i18next.t('בחירת עובד'),
                      content: i18next.t('נא לבחור עובד'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    }, null, null);
                    this.close();                   
                    return;  
                  }
                          
                  if(this.isMonthReport && this.selectedMonth==null){
                    app.showAlert({
                      header: i18next.t('בחירת חודש'),
                      content: i18next.t('נא לבחור חודש'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    }, null, null);
                    this.close();                   
                    return; 
                  }
                  
                  this.loading = true;
                  app.showLoadingMessage("מפיק דוח"); 
                  const detailedReport = localStorage.getItem("detailedReports") == "true"
                  const reportsBy = localStorage.getItem("reportsBy")
                  const reportBy = reportsBy || 'departments'

                  let data = await Service.PeriodReport.getReport(this.startDate, this.endDate, this.deviceId, employeeId, employeeName,  this.selectedMonth, detailedReport, reportBy)
                  this.loading = false;
                  let payments = await this.generatePayments(data.meta.payments) 
                  reportPayments = payments.payments;

                  // for check old print change new_report to false on Service.PeriodReport.getReport is top a few lines
                  // await Printing.periodReportOld(data, reportPayments, 
                  //   moment(this.startDate).format("DD/MM/YYYY") , moment(this.endDate).format("DD/MM/YYYY"), employeeId, employeeName, 
                  //       this.currentTitle, this.mode, this.selectedMonth); 

                  await Printing.periodReport(data, reportPayments, 
                    moment(this.startDate).format("DD/MM/YYYY"), moment(this.endDate).format("DD/MM/YYYY"), employeeId, employeeName, 
                    this.currentTitle, this.mode, this.selectedMonth, detailedReport, reportBy) 
                  this.loading = false;
                  app.hideLoadingMessage();
                  this.close();
                  
                }catch(error){
                  console.error("error in printing period report dialog:"); 
                  console.error(error);
                  this.close();
                  app.showErrorAlertDialog()
                }
              },
              async generatePayments(payments:PositiveTS.Storage.Entity.SalePayment[]){
                return await Helper.ReportsHelper.calculateZReport(payments);
              },
              cancel() {                
                this.resolveFunc()
                this.close();
              },
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }

                  this.resolveFunc();                  
                  this.dialogElem.close();
                }
                this.cleanData()
              },
              async open(mode) {
                this.deviceId = session.pos.deviceID;
                this.mode = mode;

                switch (mode) {
                  case 1:
                      this.currentTitle = 'דוח תקופתי';
                      break;
                  case 2:
                      this.currentTitle = 'דוח לעובד';         
                      this.isEmployeeReport = true;
                      break;
                  case 3:
                      this.currentTitle = 'דוח חודשי';
                      this.isMonthReport = true;
                      break;                      
                } 


                this.employees = await appDB.employees.filter(emp => emp.isLocked == false).toArray();
                   
                return new Promise((resolve,reject) => {
                  
                  this.initialized = true;

                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  this.dialogElem.showModal();
                  
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              },
              sendReportByEmail() {
                Pinia.componentsStore.openComponent( {
                  componentName: 'emailDialog', 
                  args: [
                    Shared.Constants.EmailReportType.SALES_REPORT, 
                    {by_from_date: this.startDate, by_to_date: this.endDate}
                  ]});
              }
            },
            data: initData,
          }
  
          VueApp.component('period-report-dialog',component)
        }
      }
    }
  }
