module PositiveTS {
  export module Components {
    export module ElalSaleWithoutPNR {

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }
      function initData() {
        return {
          _initialized: false,
          ticketTypes:['TKT','EMD'],
          errorMessage:null,
          saleItems:[{
            id:'',
            code:'',
            price:'',
            currency:'',
            ticketNumber:'',
            selectedTicketType:'EMD',
            isTiketTypeOpen:false,
            description:'',
            isValid:true
          }],
        }
      }
      export function create() {
        let component = {
          template: JST.elalSaleWithoutPNR(),
          components: {
            positiveIcon: PositiveIcon.getComponent(),
          },
          setup(){
            const elalStore = Pinia.useElalStore();
            const {getOfflinePNR} = Pinia.storeToRefs(elalStore);
            return {getOfflinePNR}
          },
          methods: {
            cleanData: cleanData,
            open() {
              this.cleanData();
              $(document).unbind("keypress");
              this.saleItems = this.convertSaleItems();
              this.closeDropdownOutiseClick();
              this.resetValidation();
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              });
            },
            close(isAbort =false) {
              if(isAbort){
                PositiveTS.Components.Cash.clearCashAmount();
                this.$el.close();
                return;
              }
              let isPnrValid = this.isPNRValid();
              if(!isPnrValid){
                this.errorMessage = i18next.t('elal.errors.ticketNumberLength')
                return;
              }
              
              this.resolveFunc(this.saleItems);
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            isPNRValid() {
              let isPNRValid = true;
              this.resetValidation();
              this.saleItems.forEach(saleItem => {
                if(!saleItem.ticketNumber || saleItem.ticketNumber.toString().length > 20){
                  isPNRValid = false
                  saleItem.isValid = false
                }
              })
              return isPNRValid;
            },
            resetValidation(){
              this.saleItems.forEach(saleItem => {
                saleItem.isValid = true;
              })
              this.errorMessage = null
            },
            convertSaleItems(){
              return posVC.saleItems.map(saleItem => {
                let convertedSaleItem = {
                  id:saleItem.id,
                  code:saleItem.itemCode,
                  price:saleItem.priceNetoAfterDiscounts,
                  currency:session.pos.baseCurrency,
                  ticketNumber:saleItem.elalTicketNumber ?? '',
                  selectedTicketType:saleItem.elalTicketType??'EMD',
                  isTiketTypeOpen:false,
                  description:saleItem.itemDescription,
                  elalPNR: this.getOfflinePNR
                }
                return convertedSaleItem;
              })
            },
            updateTicketType(saleItem,ticketType){
              saleItem.selectedTicketType = ticketType;
            },
            cancel(event,isAbort) {
              event.preventDefault()
              event.stopImmediatePropagation()
              return this.close(isAbort);
            },
            closeDropdownOutiseClick(){
              document.addEventListener('click', ()=>{
                this.saleItems.forEach(saleItem => {
                  saleItem.isTiketTypeOpen = false
                })
              })
            },
            cancelTapped() {
              this.resolveFunc(null);
              return this.close();
            },
            async confirmAndClose() {
              return this.close();
            },
          },
          data: initData,
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },
        };
        VueApp.component("elal-sale-without-pnr", component);
      }
    }
  }
}
