module PositiveTS {
  export module Service {
    export module Localization {
      export function init(lang = null) {
        if (lang == null) {
          lang = getLanguage();
        }
        
        changeLng(lang)
        moment.locale(lang);
        document.body.setAttribute("lang",lang);
        localStorage.setItem("posLang",lang);
        updateContent();
        let isRtlMode = lang == 'he' || lang == "ar" || lang.substr(0,3) == "ar-";
        Pinia.globalStore.setRtlMode(isRtlMode)
        
        function updateContent () {
          document.getElementById('login-title').innerHTML = i18next.t('loginTitle');
          document.getElementById('login-username-label').innerHTML = i18next.t('username');
          document.getElementById('login-password-label').innerHTML = i18next.t('password');
          document.getElementById('login-form-button-login').innerHTML = i18next.t('login');
          document.getElementById('OptionSelect-dialog-cancel').innerHTML = i18next.t('cancel')
          document.getElementById('OptionSelect-dialog-continue').innerHTML = i18next.t('continue')
        }
        
        function changeLng (lng) {
          i18next.locale = lng
        }
      }

      export function getLanguage () {
        return jsonConfig.getVal(jsonConfig.KEYS.lang) || 'he'
      }
    }
  }
}
