import { defineStore } from 'pinia';
const state = {
    currentLang: 'he',
    defaultLang: 'he',
}

const getters = {
    langsEnabled: () => {
        let posLangs: string[] = jsonConfig.getVal(jsonConfig.KEYS.langsEnabled);
        let companyLangs: string[] = session.pos.companySupportedLanguagesArr;
        return posLangs.filter(l => companyLangs.includes(l));
    },
    isRTL: (state): boolean => {
        const rtlLanguages = ['ar', 'fa', 'ur', 'he'];
        return rtlLanguages.includes(state.currentLang);
    },
    isLTR: (state): boolean =>{
        return !getters.isRTL(state);
    },
    currentDisplayedLanguageName: (state) => {
        return i18next.t(`languages.${state.currentLang}`);
    },
    multiLangEnabled: () => {
        return session.pos.companyWorksWithMultiLanguage && jsonConfig.getVal(jsonConfig.KEYS.selfServiceMultiLang) && getters.langsEnabled().length > 1;
    },
}
const actions = {
    setLang(lang) {
        if (this.langsEnabled.includes(lang) == false) {
            throw Error(`Language ${lang} is not enabled in this POS`)
        }
        this.currentLang = lang;
        PositiveTS.Service.Localization.init(lang);
    },
    changeLanguage(langCode: string) {
        this.setLang(langCode)
        // Update Items
        // Update Menus
        // Update current Sale's Items
    }
}

const initStore =()=>{
    Pinia.languageManagerStore.currentLang = jsonConfig.getVal(jsonConfig.KEYS.lang)
    Pinia.languageManagerStore.defaultLang = jsonConfig.getVal(jsonConfig.KEYS.lang)
}

const useLanguageManagerStore = defineStore('languageManager', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export {
    useLanguageManagerStore,
    initStore
};