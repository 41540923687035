module PositiveTS {
    
    export module Components {
        
        export module PickupFormDialog {

            const dialogSelector = "pickup-form-dialog"

            interface validateObject {
                valid: Boolean,
                errors: Array<String>
            }

            function nextInput (ref: string): void {
                this.$refs[ref].focus()
            }

            function validate (): validateObject {
    
                let errors = []

                const isBlankId = posUtils.isBlank(this.pickup.id)
                if (isBlankId){
                    errors.push(i18next.t('validate.required', {input: i18next.t('pickupFormDialog.id')}))
                }
                if (!isBlankId && !PositiveTS.Service.DutyFree.isPassportIdValid(this.pickup.id)){
                    errors.push(i18next.t('flightCardDialog.idIsNotValid'))  
                }
                
                const isBlankPhone = posUtils.isBlank(this.pickup.phone)
                if (isBlankPhone){
                    errors.push(i18next.t('validate.required', {input: i18next.t('pickupFormDialog.phone')}))
                }
                if (!isBlankPhone && !posUtils.isValidIsraelPhone(this.pickup.phone)){
                    errors.push(i18next.t('pickupFormDialog.phoneIsNotValid'))
                }else{
                    this.pickup.phone = this.pickup.phone.replace('-','')
                }

                const isBlankReturnDt = posUtils.isBlank(this.pickup.ReturnDt)
                if (isBlankReturnDt){
                    errors.push(i18next.t('validate.required', {input: i18next.t('pickupFormDialog.returnDate')}))
                }

                if (!isBlankReturnDt){
                    const validReturnDt = Service.Pickup.isReturnDateValid(this.pickup.ReturnDt)

                    if (!validReturnDt.valid){
                        errors.push(validReturnDt.message)
                    }
                }

                const isBlankFlight = posUtils.isBlank(this.pickup.flight)
                if (isBlankFlight){
                    errors.push(i18next.t('validate.required', {input: i18next.t('pickupFormDialog.flight')}))
                }
            
                return {valid: errors.length ? false : true, errors}
            }

            function showErrors (errors: Array<string>): void {
                this.showAlertGenericDialog(errors.join("\n"))
                console.log('errors', errors)
            }

            function enterConfirm (e) {
                e.stopPropagation()
                e.preventDefault()
                this.confirm()
            }

            function confirm (): void {
       
                this.pickup.flight = this.pickup.flight.toUpperCase().substring(0, 50);

                const validate = this.validate()
                
                if (validate.valid){
                    Service.Pickup.setPickupDataOnSale(this.pickup)
                    this.resolveFunc()
                    this.close()
                }else{
                    this.showErrors(validate.errors)
                }
                
            }

            function cancel (): void {
                this.rejectFunc()
                this.close()
            }

            function close (): void {
                this.dialogElem.close()
                $(document).unbind('keypress')
                $(document).keypress(posVC.onKeyPress)
            }

            function open (): Promise<null> {

                if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                }

                this.fetchPickupFromSale()
                this.dialogElem.showModal()

                this.$nextTick(() => {
                    $(document).unbind('keypress')
                })
                
                return new Promise((resolve,reject) => {
                    this.resolveFunc = resolve
                    this.rejectFunc = reject
                })

            }

            function fetchPickupFromSale () {
                const pickup = PositiveTS.Service.Pickup.getPickupOnSale()
                if (pickup){
                    this.pickup = pickup
                    return 
                }
                let jsondata = JSON.parse(posVC.sale.jsondata)
                let flightCustomerId = jsondata.flight ? jsondata.flight.id : "";

                this.pickup = {ReturnDt: '',flight: '',id: flightCustomerId,phone: '',DuetyFreeBarcode: '', packaging: 1, notes: ''}
            }

            function initData (){
                const data: {
                    dialogElem: any,
                    resolveFunc: Promise<null>,
                    rejectFunc: Promise<null>,
                    pickup: Service.Pickup.pickup
                } = {
                    dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
                    resolveFunc: null,
                    rejectFunc: null,
                    pickup: {ReturnDt: '',flight: '',id: "",phone: '',DuetyFreeBarcode: '', packaging: 1, notes: ''}
                }
                return data
            }

            function resetData () {
                Object.assign(this.$data, this.$options.data.call(this));
            }

            export function create() {
  
                let component = {
                  template: JST.PickupFormDialog(),
                  methods: {
                    showAlertGenericDialog: Mixins.modalsMixin.methods.showAlertGenericDialog,
                    cancel,    
                    open,
                    close,
                    confirm,
                    validate,
                    showErrors,
                    nextInput,
                    resetData,
                    fetchPickupFromSale,
                    enterConfirm
                  },
                  data: initData,
                  watch: {
                    'pickup.packaging' (newValue) {
                        
                        if (0 >= newValue){
                            this.pickup.packaging = 1
                            return
                        }
                        
                        this.pickup.packaging = newValue.toString().match(/^-?\d+(?:)?/)[0]
                    },
                  }
                }

                VueApp.component('pickup-form-dialog',component)
            }

        }

    }

}
