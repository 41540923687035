module PositiveTS {
  export module Components {
    export module HakafaCustomerDialog {
      export const componentName = "HakafaCustomerDialog"
      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }

        this.getCustomerGroups()
      }



      function convertFromPosStructure(uglyCustObj: Service.Hakafa.HakafaDialogCustomerDetails) {
        let cust = new Service.Hakafa.HakafaDialogServerHakafaCustomer()
        cust.tz = uglyCustObj.s_last_name
        cust.customer_number = uglyCustObj.customer_number;
        cust.first_name = uglyCustObj.s_first_name;
        cust.last_name = uglyCustObj.s_last_name;
        cust.birth_date = uglyCustObj.dt_birth_date;
        cust.phone = uglyCustObj.s_phone_number_1;
        cust.customer_group_id = uglyCustObj.customer_group_id;
        cust.amount = uglyCustObj.amount;
        cust.id = uglyCustObj.db_id;
        cust.merakez = uglyCustObj.merakez;
        cust.obligo = uglyCustObj.obligo;
        cust.address = uglyCustObj.address;
        cust.is_price_alut = uglyCustObj.is_price_alut
        cust.is_tamash_customer = uglyCustObj.is_tamash_customer;
        cust.printer_type = uglyCustObj.printer_type;
        cust.discount_percent = uglyCustObj.discount_percent;
        cust.comments = uglyCustObj.comments

        this.customer = cust;

      }

      function showCustomerReport(customer: PositiveTS.Storage.Entity.HakafaCustomer) {
Pinia.componentsStore.openComponent( {componentName:"hakafaReportDialogNew", args: [customer.customer_number]})
      }

      function showPriorityCustomerSales(customer) {
Pinia.componentsStore.openComponent( {componentName:"priorityHakafaReportDialog", args: [customer]})
      }

      async function showHakafaMerakezInvoicesDialog(customer) {
        if (this.$refs.searchTextInput) {
          this.$refs.searchTextInput.blur();
        }
        else if (this.$refs.customerNumber) {
          this.$refs.customerNumber.blur();
        }
        let res = await Pinia.componentsStore.openComponent( {componentName:"hakafaMerakezInvoicesDialog", args: [customer]})
        if(res.needToRefreshCustomer){
          app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.searchingCustomer'))
          await this.searchCustomer()
          app.hideLoadingMessageDialog()
        }
      }

      async function selectCustomer(customer) {
        try {
          this.customer = customer;
          let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
          const posCustomer = await Service.Hakafa.convertToPosStructure.call(this, customer)
          await customerClubService.setCurrentSelectedCustomer(posCustomer)
          await posVC.saleUpdated()
          this.customerSelected = true;
        } catch (e) {
          console.error(e)
          this.showGenericError()
        } finally {
          this.close();
        }
      }

      function showCustomerData(customer) {
        this.customer = _.cloneDeep(customer)
        if(customer.parent_hakafa_customer_id) {
          this.parentHakafaCustomer = this.parentHakafaCustomersOptions.find((phc) => phc.id == customer.parent_hakafa_customer_id)
        } else {
          this.parentHakafaCustomer = null
        }
        this.customerSelected = true;
        this.setMode('show')
      }

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $("#hakafa-customer-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: Pinia.globalStore.mobileLayout ? window.innerWidth : '100vw',
              height: Pinia.globalStore.mobileLayout ? window.innerHeight : '100vh',
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }

      async function changePage(pagesToAdd: number) {
        if (this.filters.page === 1 && pagesToAdd === -1) {
          return
        }
        this.filters.page += pagesToAdd
      }

      function initData() {
        return {
          initialized: this.initialized,
          customerSelected: false,
          showSearchResults: false,
          searchCustomerMode: true,
          noCustomersFound: false,
          addNewCustomerMode: false,
          openedFromHomePage: false,
          showCustomerMode: false,
          editCustomerMode: false,
          isPriorityApi: PositiveTS.Service.PriorityService.isPriorityCompany(),
          canCreateHakafaCustomer: jsonConfig.getVal(jsonConfig.KEYS.canCreateHakafaCustomer),
          canManageHakafaCustomer: jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos),
          showGoToCustomerCommentsButton: jsonConfig.getVal(jsonConfig.KEYS.showGoToCustomerCommentsButton),
          customer: {},
          customers: [],
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          parentHakafaCustomer: null,
          parentHakafaCustomers: [],
          isImportParentHakafaCustomers: false,
          customerGroupsOptions: [],
          filters: {
            is_locked: false,
            by_customer_group: null,
            page: 1,
            per: 25,
            search: ""
          },
          totals: "",
          isAndroid: session.isAndroid,
        }
      }

      export function close() {
        this.isImportParentHakafaCustomers = false
        this['parentHakafaCustomer'] = null
        if(this.posTabMode){
          pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
        }
        else{
          VueInstance.$refs.vueDialog.close()
        }
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
        
      }

      async function searchCustomer() {
        let params = _.cloneDeep(this.filters)

        if (this.isPriorityApi) {
          return this.searchCustomersPriority();
        }

        if (params["by_customer_group"]) {
          params["by_customer_group"] = params["by_customer_group"].id
        }

        if(params["is_locked"] == null){
          delete params["is_locked"]
        }

        if(!this.canManageHakafaCustomer) {
          delete params["per"]
          delete params["page"]
        }

        try {
          let search = await PositiveTS.Storage.Entity.HakafaCustomer.search(params)

          switch (search.result.status) {
            case 1:
              this.noCustomersFound = false
              this.showCustomer(search.result);
              break;
            case 4:
              this.noCustomersFound = true
              break;
            case 2:
              this.showError(search.result.message);
              break;
            default:
              this.showGenericError()
          }

          if(this.canManageHakafaCustomer) {
            this.totals = search.result.meta.total
          }
        }
        catch (err) {
          console.log(err)
        }
        finally {
          console.debug(this.filters.search);
        }
      }

      async function swipeCard(): Promise<void> {
        app.showLoadingMessageDialog(i18next.t("mizdamenOrHakafaDialog.swipeCustomerCard"));
        try{
          const swipeCardInput = await Service.EMV.swipe(0,true);
          this.filters.search = swipeCardInput || ""; 
          this.searchCustomer()
        } catch (e) {
          await app.showAlertDialog({
            header: i18next.t('error'),
            content: e.message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          })
        }

        app.hideLoadingMessageDialog();
      }

      async function swipeCardNewCustomer() :Promise<void>{
        app.showLoadingMessageDialog(i18next.t("mizdamenOrHakafaDialog.swipeCustomerCard"));
        const swipeCardInput = await Service.EMV.swipe();
       
        this.customer.customer_number = swipeCardInput || "";
        app.hideLoadingMessageDialog();
      }

      async function saveCustomer() {
        try {
          if (!Pinia.globalStore.isOnline) {
            this.showError(i18next.t('hakafaCustomerDialog.errorCannotEditCustomerWhenPosOffline'))
            return
          }

          this.customer.parent_hakafa_customer_id = this.parentHakafaCustomer ? this.parentHakafaCustomer.id : null
          let saveCustomerFromShowMode = this.customerSelected && this.customer.hasOwnProperty("is_offline") && this.customer.hasOwnProperty("mode")

          if(saveCustomerFromShowMode) {
            delete this.customer.is_offline
            delete this.customer.mode
          }


          app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.savingCustomer'))
          let req = await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/${this.customer.id}`, "put", { hakafaCustomer: this.customer })
          if (req.result.success) {
            await app.showAlertDialog({
              content: req.result.message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            })
            let updatedCustomer = req.result.hakafa_customer
            delete updatedCustomer.created_at
            delete updatedCustomer.updated_at




            let customerIndex = this.customers.findIndex(cust => cust.id === this.customer.id)
            
            this.customers[customerIndex] = updatedCustomer

            if(saveCustomerFromShowMode) {
              this.close()
              this.selectCustomer(this.customer)
            } else {
              this.setMode('search')
            }
          } else {
            console.error(req.result.message)
            this.showError(req.result.message)
          }
        }
        catch (err) {
          console.error(err)
          this.showError(err)
        }
        finally {
          app.hideLoadingMessageDialog()
        }
      }

      async function searchCustomersPriority() {
        try {
          this.showSearchResults = false
          this.noCustomersFound = false
          this.customers = []
          let result = await PositiveTS.Service.PriorityService.searchCustomersPriority(this.filters.search.trim(), true)         
          if (!result.success || result.error){
            this.showError(result.error)
            return
          }

          if (result.customers.length > 0) {
            this.showCustomer({customers: this.parseCustomersPriority(result.customers)});
            this.noCustomersFound = false;
          } else {
            this.noCustomersFound = true;
          }

        } catch (e) {
          console.error(e)
          this.showError(i18next.t('hakafaCustomerDialog.errorRequestCustomersPriority'))
        }
      }

      function parseCustomersPriority(customers: Array<object>) {
        let result = [];
        const priorityUserData = PositiveTS.Service.PriorityService.getPriorityUserData()
        const isTamashCustomer = Boolean(priorityUserData?.send_hakafa_customer_sale_as_tamash)

        if (Array.isArray(customers)) {
          customers.map((customer) => {
            let cus_object = {
              address: `${customer['ADDRESS'] || ''} ${customer['ADDRESS2'] || ''} ${customer['ADDRESS3'] || ''}`,
              amount: customer['CUST'],
              tz: customer['SPEC2'],
              customer_number: customer['CUSTNAME'],
              email: customer['EMAIL'],
              name: customer['CUSTDES'],
              phone: customer['PHONE'],
              first_name: customer['CUSTDES'],
              is_tamash_customer: isTamashCustomer
            }

            result.push(cus_object);
          })
        }

        return result;
      }

      function showCustomer(result: any) {

        this.customers = []
        this.showSearchResults = true;
        this.customers = result.customers.map((c) => { c.amount =  c.amount ? session.fixedNumber(c.amount) : 0; return c });
      }

      function checkIfCustomerExists() {
        if (!Pinia.globalStore.isOnline) {
          this.showError(i18next.t('hakafaCustomerDialog.errorCannotAddNewCustomerWhenPosOffline'))
          return
        }

        return new Promise(async (resolve, reject) => {
          app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.checkIfCustomerExists'));
          try {
            let res = await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/${this.customer.tz}?by_customer_number_and_taz=true&customer_number=${this.customer.customer_number || null}`, "get")

            switch (res.result.status) {
              case 1:
                resolve(true)
                break;
              case 4:
                resolve(false)
                break;
              default:
                this.showGenericError()
            }
          } catch (e) {
            console.error(e)
            this.showGenericError()
          } finally {
            app.hideLoadingMessageDialog()
          }
        })
      }

      function validate(): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
          let errors = []
          
          if(this.customer.customer_number.trim()[0] == "-" ){          
            errors.push({ field: "customerNumber", error: i18next.t('hakafaCustomerDialog.errors.negativeCustomerNumber') });
          }
          if(this.customer.customer_number.trim().length > PositiveTS.Service.CustomerClub.CUSTOMER_TEXT_INPUT_LEGTH ) { 
            errors.push({field:"customerNumber", error:i18next.t("positiveCustClub.toLongTextInput")}) 
          }     
          if (!this.customer.tz || String(this.customer.tz || '') === "") {
            errors.push({ field: "taz", error: i18next.t('hakafaCustomerDialog.errors.tazRequired') })
          }
          if (this.customer.tz && String(this.customer.tz || '').length > 9) {
            errors.push({ field: "taz", error: i18next.t('hakafaCustomerDialog.errors.tazLength') })
          }
          if (!this.customer.first_name || this.customer.first_name.trim() === "") {
            errors.push({ field: "firstName", error: i18next.t('hakafaCustomerDialog.errors.firstNameRequired') })
          }
          if (!this.customer.phone || String(this.customer.phone|| '') === "") {
            errors.push({ field: "phoneNumber", error: i18next.t('hakafaCustomerDialog.errors.phoneNumberRequired') })
          }

          if (this.customer.is_parent_hakafa_customer && this.customer.parent_hakafa_customer_id) {
            errors.push({ field: "isParentHakafaCustomer", error: i18next.t('hakafaCustomerDialog.errorCannotSetParentCustomerToParentCustomer') })
          }

          if (!posUtils.isBlank(this.customer.discount_percent) && isNaN(this.customer.discount_percent)) {
            errors.push({ field: "discountPercent", error: i18next.t('hakafaCustomerDialog.errors.discountPercent') })
          }

          if (!posUtils.isBlank(this.customer.obligo) && isNaN(this.customer.obligo)) {
            errors.push({ field: "obligo", error: i18next.t('hakafaCustomerDialog.errors.obligo') })
          }

          if (errors.length === 0) {
            checkIfCustomerExists.call(this)
              .then(custExists => {
                if (custExists) {
                  errors.push({ field: "customerNumber", error: i18next.t('hakafaCustomerDialog.errors.customerNumberExists') })
                }
                resolve(errors)
              })
          }
          else {
            resolve(errors);
          }

        })
      }

      async function addNewCustomer(sendToServerDirectly: boolean = false): Promise<void> {
        let self = this;
        if (self.customer.tz) {
          self.customer.tz = String(self.customer.tz || '');
        }
        if (self.customer.customer_number) {
          self.customer.customer_number = self.customer.customer_number.trim();
        }
        
        self.customer.parent_hakafa_customer_id = self.parentHakafaCustomer ? self.parentHakafaCustomer.id : null
        let validationErrors = await validate.call(self)

        if (validationErrors.length > 0) {
          let errStr = "";
          validationErrors.forEach((el) => { errStr += `${el.error}<br>` })

          app.showAlert({
            header: i18next.t('error'),
            content: errStr,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          },
            () => {
              self.$refs[validationErrors[0].field].focus();
            }, null)

          return
        }

        if (sendToServerDirectly) {
          app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.addingCustomerMessage'))
          let req = await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/`, "POST", { hakafaCustomer: self.customer })
          if (req.response.ok) {
            await app.showAlertDialog({
              content: req.result.message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            })

            app.hideLoadingMessageDialog()
            this.searchCustomer()
            this.setMode('search')

          } else {
            console.error(req.result.message)
            app.hideLoadingMessageDialog()
            self.showError(req.result.message)
          }
        } else {
          self.customer.mode = 1
          self.selectCustomer(self.customer)
        }
      }

      function customerHistory(customer) {
        if (!customer || !customer.id) {
          return;
        }

        posSearchInvoicesVC.searchObject = { hakafa_customer_id: customer.id };
        posSearchInvoicesVC.showingResults = true;
        posSearchInvoicesVC.formRendered = true;
        this.close();

        pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
      }

      function showGenericError() {
        app.showAlertDialog({
          header: i18next.t('error'),
          content: i18next.t('hakafaCustomerDialog.errors.generalError'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
          null, null)
      }

      function showError(errStr) {
        app.showAlertDialog({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
          null, null)
      }

      function setMode(mode) {
        if (mode == "search") {
          this.searchCustomerMode = true
          this.addNewCustomerMode = false
          this.showCustomerMode = false
          this.editCustomerMode = false

          this.customer = {}
          this.customerSelected = false
        }

        if (mode == "new") {
          if (!Pinia.globalStore.isOnline) {
            this.showError(i18next.t('hakafaCustomerDialog.errorCannotAddNewCustomerWhenPosOffline'))
            return
          }
          this.searchCustomerMode = false
          this.addNewCustomerMode = true
          this.showCustomerMode = false
          this.editCustomerMode = false
          if (!this.isImportParentHakafaCustomers) {
            this.importParentHakafaCustomers()
          }
        }

        if (mode == "show") {
          this.searchCustomerMode = false
          this.addNewCustomerMode = false
          this.showCustomerMode = true
          this.editCustomerMode = this.canManageHakafaCustomer ? true : false
          if (!this.isImportParentHakafaCustomers) {
            this.importParentHakafaCustomers()
          }
        }
      }

      function selectInput(field) {
        this.$refs[field].focus();
      }

      function returnToSearch() {
        this.customer = {}
        this.customerSelected = false
        this.setMode('search')
      }

      async function getCustomerGroups() {
        if (!this.isRoshemet) {
          let req = await PositiveTS.Service.FetchReq.jsonReq("/customer_groups", "get")

          this.customerGroupsOptions = req.result.customer_groups

        }
      }

      export function getComponent() {

        let vueObj = {
          template: JST.hakafaCustomerDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            cleanData: cleanData,
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            close: close,
            searchCustomer: searchCustomer,
            swipeCard: swipeCard,
            showCustomerReport: showCustomerReport,
            showCustomer: showCustomer,
            showCustomerData: showCustomerData,
            returnToSearch: returnToSearch,
            showError: showError,
            showGenericError: showGenericError,
            checkIfCustomerExists: checkIfCustomerExists,
            addNewCustomer: addNewCustomer,
            selectCustomer: selectCustomer,
            saveCustomer: saveCustomer,
            getCustomerGroups: getCustomerGroups,
            setMode: setMode,
            selectInput: selectInput,
            customerHistory: customerHistory,
            searchCustomersPriority: searchCustomersPriority,
            swipeCardNewCustomer:swipeCardNewCustomer,
            parseCustomersPriority: parseCustomersPriority,
            showPriorityCustomerSales: showPriorityCustomerSales,
            showHakafaMerakezInvoicesDialog: showHakafaMerakezInvoicesDialog,
            changePage: changePage,
            async importParentHakafaCustomers() {
              try {
                app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.messageImportParentHakafaCustomers'))
                let res = await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/get_parent_hakafa_customers`, "get")
                const customers = res && res.result && res.result.customers ? res.result.customers : []
                this.parentHakafaCustomers = customers
                this.isImportParentHakafaCustomers = true
              } catch (e) {
                console.error(e)
                this.showError(i18next.t('hakafaCustomerDialog.errorImportParentHakafaCustomers'))
                this.parentHakafaCustomers = []
              } finally {
                app.hideLoadingMessageDialog()
              }
            },
            scrollToCustomerComments() {
              let elementId = 'hakafaComments'
              let commentsEl = document.getElementById(elementId)
              if(commentsEl) {
                commentsEl.focus()
                commentsEl.scrollIntoView();
              } else {
                console.error(`${elementId}, element was not found`)
              }
            },
          },
          props: ['initParams'],
          computed: {
            formattedPhoneNumber: {
              get() {
                return this.customer?.phone ? this.customer?.phone : "";
              },
              set(value) {
                  if(!value || value == ''){
                    this.customer.phone = ''
                  }else{
                    this.customer.phone = value.replace(/\D/g, '');
                  }
                
              }
            },
            parentHakafaCustomersOptions() {
              return this.parentHakafaCustomers.map((phc) => {
                return { id: phc.id, name: `${phc.first_name ? phc.first_name : ''} ${phc.last_name ? phc.last_name : ''}` }
              })
            },
            search() {
              return this.filters.search
            },
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {posTabMode,isRoshemet,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {posTabMode,isRoshemet,mobileLayout}
  
            return {...globalStoreProps}
          },
          data: initData,

          mounted() {
            this.getCustomerGroups()
          },
          async created() {
            this.$emit("updateTitle",i18next.t('hakafaCustomerDialog.title'))
            this.searchDebounce = _.debounce(this.searchCustomer, 300);
            if (!Pinia.globalStore.isOnline && this.canManageHakafaCustomer) {
              this.showError(i18next.t('hakafaCustomerDialog.offlineError'))
              return
            }
            app.showLoadingMessageDialog(i18next.t('hakafaCustomerDialog.searchingCustomer'))
    
            if (this.canManageHakafaCustomer){
              await this.searchCustomer()
            }

            await this.importParentHakafaCustomers()

            app.hideLoadingMessageDialog()
    
            this.openedFromHomePage = this.initParams?.openedFromHomePage
            let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
            let customer = customerClubService.getCurrentSelectedCustomer()
            if (customer) {
              convertFromPosStructure.call(this, customer)
              this.customerSelected = true
            }
            else {
              this.customer = { is_tamash_customer: true }
            }
            
            if (this.$refs.searchTextInput) {
              this.$refs.searchTextInput.focus();
            }
            else if (this.$refs.customerNumber) {
              this.$refs.customerNumber.focus();
            }
          },
          watch: {
            filters: {
              handler() {
                if (!this.isPriorityApi){
                  this.searchDebounce()
                }
              },
              deep: true
            }
          }
        }
        // VueApp.component("HakafaCustomerDialog", vueObj)
        return vueObj;
      }
    }
  }
}
