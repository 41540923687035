module PositiveTS {
  export module Components {
    export module selfServiceLoadCard {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreen(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.selfServicePaymentMixin("selfService.loadCard")],
          data() {
            return {
              selectedPaymentMethodType: Mixins.INPUT_METHODS.SWIPE_CARD,
            }
          }
        }
      }
    }
  }
}