module PositiveTS {
export module Components {
export module WithdrawalRefundDialog {
  

  const _dialogSelector = "#positive-withdrawal-refund-dialog"
  const _vueComponentName = 'withdrawal-refund-dialog';

  export function create() {
    let withdrawalRefundDialog = {
        template: JST.withdrawalRefundDialog(),
        methods: {
          close: close,
          open(sale){
              let saleJsonData = JSON.parse(sale.jsondata);
              this.surcharge = sale.items[0].unitPrice * 100;
              this.amount = saleJsonData.withdrawn
              this.fullTx = saleJsonData.FullTx;
              this.requestId = saleJsonData.requestId;
              this.sale = sale;
              this.originalPayment = {
                surcharge: this.surcharge,
                amount: this.amount,
                fullTx: this.fullTx,
                requestId: this.requestId,
                sale: this.sale
              }
            $(function() {
              $(_dialogSelector).dialog({
                autoOpen: true,
                modal: true,
                dialogClass: 'positive-dialog',
                width: '60%',
                height: 360,
                resizable: false,
                closeOnEscape: false,
                draggable: false,
              });
              $(_dialogSelector).dialog('open');
            });
          },
          async refund() {
            try{
              let response = await PositiveTS.Service.EMV.getInstance().creditPaymentInternal(this.fullTx,false)
              if(response.success) {
                let refundedSale = await Service.Withdrawal.createRefundSale(this.originalPayment,response);
                await Printing.Invoice.printCashWithdrawalInvoice(refundedSale, 
                  this.originalPayment.sale.items[0], 
                  this.originalPayment.surcharge, 
                  response.result.TerminalId, 
                  refundedSale.atmTransactionId, 
                  true,
                  this.originalPayment.amount);
  
                  await app.promiseShowAlert({
                    header: i18next.t("successfullyCompleted"),
                    content: i18next.t("withdrawal.withdrawalRefundSuccess"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                });
  
                this.close();
                app.hideLoadingMessage();
              }
              else{
                app.hideLoadingMessage();
                app.showAlert({
                  header: i18next.t('error'),
                  content: response.error,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              }
            }catch(err){
              app.hideLoadingMessage();
              console.error(err);
              app.showAlert({
                header: i18next.t('error'),
                content: err,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
            }
          }
        },
        data: _initData
    }

    VueApp.component(_vueComponentName,withdrawalRefundDialog)
  }

  function close() {
    $(_dialogSelector).dialog('close');
  }

  function _initData () {
    return {
      requestId: null,
      amount: null,
      surcharge: null,
      sale: null
    }
  }

}}}
