
enum TranType {
    CHARGE = 1,
    UNLOAD = 2,
    FORCED_CHARGE = 3,
    CASHBACK = 6,
    CASH = 7,
    RECURRING = 11, // הוראת קבע
    CHECK_BALANCE = 30,
    CREDIT = 53,
    LOAD  = 55,
}

export default TranType;