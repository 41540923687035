module PositiveTS {
export module Promotions {
export module Templates {
export class KneBeWithWeight extends TemplateAbstract {
  private idToQty
  private promoGroups = {}
  private allItems = {}
  private minBuyQuantity
  private minBuyAmount
  private allowMultipleTimesPerSale
  private promotionApplied;
  private templates;
  private counterPromotionItemsQuantityDivided = {}

  constructor(initParameters) {
    super(initParameters)
    this.templates = ['16']
    this.promotionApplied = false;
    this.promoGroups = {};
    this.idToQty = {};
  }

  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (let promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      var promotion = this.parameters.promotionsByCode[promotionCode];

      if (this.templates.indexOf(promotion.template) < 0 ) {
        continue;
      }
      this.run(promotion)
    }
  }

  run(promotion:Storage.Entity.Promotion) {
    this.promoGroups = {};
    if (!this.valid(promotion)) {
      return;
    }
   
    //don't mix between items
    let usedBarcodes = []
    for (let item of this.parameters.promotionsBuy[promotion.code]) {
      let currentBarcode = item.barcode;
      if (usedBarcodes.indexOf(currentBarcode) >= 0) {
        continue;
      }
      // In this case, promotion actually raises the base price for 1 KILO
      if (item.unitPrice < promotion.discountValue && promotion.discountType === "Fix") {
        continue;
      }
      if (!item.hasWeight) {
        continue;
      }
      usedBarcodes.push(item.barcode);
      let buyItems = this.initData(promotion, item.barcode);
      while (this.performRun(buyItems, promotion)) {}
    }
   
    this.addToPromoGroups()
  }

  private initData(promotion, barcodeFilter = null) {
    this.allItems = {};
    this.minBuyQuantity = Number(promotion.minimumBuyQuantity);
    this.minBuyAmount = Number(promotion.minimumBuyAmount);
    this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
    this.allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);

    let flattenedSaleItemsBySide = {
      'buy': this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code],false),
      'get': this.flattenSaleItemsByQuantity(this.parameters.promotionsGet[promotion.code],false)
    };

    let items = this.parameters.promotionsBuy[promotion.code].concat(this.parameters.promotionsGet[promotion.code])
    for (let item of items) {
      if (item) {
        this.allItems[item.id] = item;
      }
    }


    let buyItems = flattenedSaleItemsBySide.buy.sort(this.sortByUnitPriceFromExpensiveToCheapest);
    if (barcodeFilter) {
      buyItems = buyItems.filter(item => {return item.barcode == barcodeFilter})
    }

    buyItems.forEach((item) => { this.idToQty[item.id] = item.hasWeight ? item.quantity : item.realQuantity } )
    return buyItems;
  }

  private includeItem(item, itemsIncludedInRun, hasWeight = false) {
    let quantity = this.getItemQuantity(item)
    this.idToQty[item.id] -= quantity
    itemsIncludedInRun[item.id] = itemsIncludedInRun[item.id] || 0
    itemsIncludedInRun[item.id] += quantity
  }

  private removeItem(item, itemsIncludedInRun, hasWeight = false) {
    let quantity = this.getItemQuantity(item)
    this.idToQty[item.id] += quantity
    itemsIncludedInRun[item.id] -= quantity
  }

  //validate if promotion can be applied
  private valid(promotion) {
    if (Number(promotion.minimumBuyQuantity) < 0) {
      console.error('quantity is smaller than 0 - not applying promotion')
      return false;
    }
    if (!this.parameters.promotionsBuy[promotion.code]) {
      return false;
    }

    return true;
  }

  private performRun(buyItems:Array<Storage.Entity.SaleItem>, promotion:Storage.Entity.Promotion) {
    let [totalPriceForRun, totalQuantityForRun, itemsIncludedInRun] = [0,0,{}];

    for (let item of buyItems) {
      if (promotion.maxQuantityToGiveCustomer > 0 && this.counterPromotionItemsQuantityDivided[promotion.code] >= promotion.maxQuantityToGiveCustomer){
        break
      }

      if (!this.allowMultipleTimesPerSale && this.promotionApplied) {
        break;
      }
      if (this.idToQty[item.id] <= 0) {
        continue;
      }

      let quantity = this.getItemQuantity(item)
      totalPriceForRun += item.unitPrice * quantity
      totalQuantityForRun += quantity
      if (!this.counterPromotionItemsQuantityDivided[promotion.code]){
        this.counterPromotionItemsQuantityDivided[promotion.code] = 0
      }
      this.counterPromotionItemsQuantityDivided[promotion.code] += quantity
      this.includeItem(item,itemsIncludedInRun,item.hasWeight)

      if (totalPriceForRun >= this.minBuyAmount && totalQuantityForRun >= this.minBuyQuantity) {
        //find the first relevant get item and apply the promotion on it...
        let itemToPromote = null;
        for (let i=0; i<buyItems.length; i++) {
          if (itemsIncludedInRun[buyItems[i].id] && itemsIncludedInRun[buyItems[i].id] > 0) {
            itemToPromote = buyItems[i];
            break;
          }
        }

        if (itemToPromote) {
          this.addPromoGroup(itemToPromote,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun, promotion)
          return true;
        }
        else {
          return false
        }
      }
    }
    return false;

  }

  //remove items from the itemsIncludedInRun list that the promotion can still be applied without them
  private removeRedundantItems(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun) {
    if (totalQuantityForRun == this.minBuyQuantity) {
      return;
    }
    for (let id in itemsIncludedInRun) {
      for (let i=0; i< itemsIncludedInRun[id]; i++) {
        let hasWeight = Boolean(this.allItems[id].hasWeight)
        if (totalPriceForRun-this.allItems[id].unitPrice*(hasWeight ? this.allItems[id].quantity : 1)  >= this.minBuyAmount &&
            (totalQuantityForRun-(hasWeight ? this.allItems[id].quantity : 1) >= this.minBuyQuantity)) {

          totalQuantityForRun -= (hasWeight ? this.allItems[id].quantity :  1);
          totalPriceForRun -= this.allItems[id].unitPrice * (hasWeight ? this.allItems[id].quantity :  1);
          this.removeItem(this.allItems[id],itemsIncludedInRun,hasWeight);
        }
      }
    }
  }

  private addPromoGroup(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun, promotion) {

    if (!this.promoGroups[itemToPromote.id]) {
      this.promoGroups[itemToPromote.id] = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
    }
    // console.debug('before')
    // console.debug(itemsIncludedInRun)
    this.removeRedundantItems(itemToPromote,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun)
    // console.debug('after')
    // console.debug(itemsIncludedInRun)

    let promoGroup = this.promoGroups[itemToPromote.id];

    let discountValue = this.getDiscountValue(itemToPromote, totalPriceForRun, totalQuantityForRun, promotion);

    this.promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += this.getTotalPriceForItemsInRun(itemsIncludedInRun)
    this.promoGroups[itemToPromote.id].discountAmountForGroup += discountValue;


    for (let id in itemsIncludedInRun) {
      for (let i=0; i< itemsIncludedInRun[id]; i++) {
        let item = this.allItems[id]
        let promoGroup = this.promoGroups[itemToPromote.id]
        this.addToItemsCounter(item, promoGroup.itemsCounter, item.quantity)
        this.addToRowValueCounter(item, promoGroup.rowValueCounter, item.quantity)
      }
    }

    let item = {
      discountAbsoluteValue: session.fixedNumber(promoGroup.discountAmountForGroup),
      discountPrecentValue: session.fixedNumber(promoGroup.discountAmountForGroup/(itemToPromote.unitPrice * itemToPromote.realQuantity)*100),
      discountType: promotion.discountType == 'Percent' ? PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT : PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT,
      isPromotionGiven: true,
      saleItemID: itemToPromote.id,
      promotionCode: promotion.code,
      promotionName: promotion.name,
      buyGet: 'buy'
    }

    promoGroup.item = item;
    this.promotionApplied = true
  }

  private getTotalPriceForItemsInRun(itemsIncludedInRun) {
    let totalPrice = 0
    for (let id in itemsIncludedInRun) {
      totalPrice+= this.allItems[id].unitPrice * itemsIncludedInRun[id];
    }
    return totalPrice;
  }

  private getDiscountValue(item, totalPriceForRun, totalQuantityForRun, promotion:Storage.Entity.Promotion) {
    let qty = (promotion.maxQuantityToGiveCustomer > 0 ? 
      Math.min(promotion.maxQuantityToGiveCustomer,totalQuantityForRun) : totalQuantityForRun)
    switch (promotion.discountType) {
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
        return qty * promotion.discountValue;
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
        return item.unitPrice * qty - promotion.discountValue * qty
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
        return item.unitPrice * qty * promotion.discountValue/100.0;
    }
  }


  private addToPromoGroups() {
    for (let key in this.promoGroups) {
      this.parameters.promoGroups.push(this.promoGroups[key]);
    }
  }

  private getItemQuantity(item) {
    let quantity = 1
    if (item.hasWeight){
      if(session.pos.useNewPromotions){
        quantity = item.quantity
      }else{
        quantity = item.realQuantity
      }
    }

    return quantity
  }

}}}}
