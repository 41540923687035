module PositiveTS {
export module Service {
    export module FutureOrder {
        const baseUrl = '/future_orders'

        export interface FutureOrder {
            id: number,
            customer_id: number,
            company_id: number,
            store_id: number,
            status: number,
            closed_at: string, 
            notes: string,
            sequence: number,
            opened_at: string,
            orderable: any,
            customer: any,
        }

        export const futureOrder: FutureOrder = {
            id: null,
            customer_id: null,
            company_id: null,
            store_id: null,
            status: null,
            closed_at: null,
            notes: null,
            sequence: null,
            opened_at: null,
            orderable: null,
            customer: null,
        }

        declare const orderableTypes: 'MobilePhoneRepair'

        export const statuses = {
            open: 1,
            completed: 2,
            cancel: 3,
        }

        export const statusArray: Array<{name: string, id: number}> = [
            {name: 'open', id: 1},
            {name: 'completed', id: 2},
            {name: 'cancel', id: 3},
        ]

        const futureOrderFilter = {page: 1, per: 1, search: ''}

        export async function create (type: typeof orderableTypes, futureOrder: FutureOrder) {
            let result = {success: false, data: null, errors: []}

            try {
                let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(`${baseUrl}/?type=${type}`, 'post', futureOrder)
                result.success = res.success
                result.data = res.data
            }catch(error){
                console.error('Error on PositiveTS.Service.FutureOrder.create', error)
                result.errors = error.errors
            }

            return result
        }

        export async function update (id: number, type: typeof orderableTypes, futureOrder: FutureOrder) {
            let result = {success: false, data: null, errors: []}

            try {
                let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(`${baseUrl}/${id}/?type=${type}`, 'put', futureOrder)
                result.success = res.success
                result.data = res.data
            }catch(error){
                console.error('Error on PositiveTS.Service.FutureOrder.update', error)
                result.errors = error.errors
            }

            return result
        }
        
        export async function find (type, id) {
            let result = {success: false, data: null, errors: []}

            try {
                let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(`${baseUrl}/${id}/?type=${type}`, 'get')
                result.success = res.success
                result.data = res.data
            }catch(error){
                console.error('Error on PositiveTS.Service.FutureOrder.find', error)
                result.errors = error.errors
            }

            return result
        }

        export async function all (type: typeof orderableTypes, filter = futureOrderFilter) {
            let result = {success: false, data: null, errors: []}

            try {
                let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(`${baseUrl}/?type=${type}&${posUtils.objectToUrlQueryParams(filter)}`, 'get')
                result.success = res.success
                result.data = res.data
            }catch(error){
                console.error('Error on PositiveTS.Service.FutureOrder.all', error)
                result.errors = error.errors
            }

            return result
        }
    }
}
}

