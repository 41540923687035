module PositiveTS {
    export module Service {
        export module CreditCardPayment {
            
            export async function savePayment(result: {salePayment:Storage.Entity.SalePayment, cardDetails:any}, amount:number ,paymentCurrency?,amountFullNumber?) {
                let salePayment = result.salePayment;
                let cardDetails = result.cardDetails;
      
                //Create dataObject array
                var dataObj = [];
      
                //init the old dataObject
                if (salePayment.data != null && JSON.stringify(salePayment.data).toLowerCase().indexOf('amount') != -1) {
                  dataObj = JSON.parse(salePayment.data);
                }
      
                // isMultiCurr payment must be translated
                if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
                  let McCreditAmount = paymentCurrency ? amountFullNumber : amount;
                  Object.assign(cardDetails, PositiveTS.Service.MultiCurr.getInstance().getObjectDataCreditCard(McCreditAmount));
                  salePayment.amount = session.fixedFloat(_.sumBy(dataObj.concat([cardDetails]),'creditLeadCurrencyAmount'));
                  
                } else {
                  salePayment.amount += Number(amount);
                }
                
                dataObj.push(cardDetails);
                salePayment.data = JSON.stringify(dataObj);
      
                // Persist the payment
                let inserted = false
                for (let i=0; i < posVC.salePayments.length; i++) {
                  if (posVC.salePayments[i].method == salePayment.method) {
                    posVC.salePayments[i] = salePayment;
                    inserted = true;
                    break;
                  }
                }
      
                if (!inserted) {
                  posVC.salePayments.push(salePayment)
                }
      
                await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
                return salePayment;
              }

            export function getSalePayment():Storage.Entity.SalePayment {
                let salePayment = posVC.salePayments.filter(sp => {
                  return (sp.method == Storage.Entity.SalePayment.METHOD_CREDIT)
                })[0]
      
      
                if (!salePayment) {
                  salePayment = new PositiveTS.Storage.Entity.SalePayment();
                  salePayment.saleID = posVC.sale.id;
                  salePayment.amount = 0;
                  salePayment.method = Storage.Entity.SalePayment.METHOD_CREDIT;
                }
      
                return salePayment;
            }

            export function sumPaymentsDataAmountInBaseCurrency(data: Array<any>) {
              return _.sumBy(data || [], (paymentData => Service.MultiCurr.getInstance().getCurrrencyPaymentDataBaseCurrencyAmount(paymentData)));
            }
            export function createOutputByType(type){
              if(type == 'Gateway'){
                return new PositiveShared.PaymentGatewayOutput()
              }
              return new PositiveShared.EmvOutput()
            }
        }
    }
}