module PositiveTS {
/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"LockScreen", arguments: []})

*/
  export module Components {
export module LockScreen {
  const _dialogSelector = "#positive-lock-screen-dialog"
  const _vueComponentName = 'lock-screen';

  export function create() {
    let vueObj = {
        template: JST.lockScreen(),
        methods: {
          open: open,
          close: close,
          doSearchEmployee: doSearchEmployee,
          _clearForm: _clearForm,
          fingerPrint: fingerPrint
        },
        data: _initData

    }

    VueApp.component(_vueComponentName,vueObj)

  }


  function doSearchEmployee(){
    var employee = new PositiveTS.Storage.Entity.Employee();
    var ary = []
    ary.push( employee.fetchByStoreAndEmployeeID(session.pos.storeID, this.searchEmployee) );
    ary.push( employee.fetchByStoreAndMagneticCard(session.pos.storeID, this.searchEmployee) );

    return Promise.all(ary)
    .then((items) => {
      let emp;
      if (items[0].length > 0) {emp = items[0][0]}
      if (items[1].length > 0) {emp = items[1][0]}
      if (
        emp &&
        !( emp.isManagerDiscountConfirm || emp.isManagerMinusConfirm || emp.isCashier )
      ){
        this.status = i18next.t('lockScreenDialog.statuses.employeeNotCashierOrManager');
        this._clearForm();
      } else if (items[0].length > 0 || items[1].length > 0) {
        // -- Employee card is invalid
        this.close();
      } else {
      this.status = i18next.t('lockScreenDialog.statuses.employeeNotFound');
      this._clearForm();
      }
    })

  }

  function open(currentBalance: string, valuCardNumber: string){
    $(document).unbind('keypress');
    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: 'positive-dialog',
        width: '80%',
        height: 300,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });

    this._clearForm();
    this.status = i18next.t('lockScreenDialog.statuses.idle');
    Pinia.globalStore.setIsScreenLocked(true);
  }


  function _clearForm(){

    this.searchEmployee = "";
    this.$refs.searchEmployee.focus();
  }

  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      searchEmployee: "",
      status: ""
    };
  }
  
  function fingerPrint(){
    return PositiveTS.Service.Fingerprint.verify()
      .then((response) => {
        this.close();
    }).catch((error) => {
      this.status = error.message
      this._clearForm();
    })
  }

  function close() {
    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
    Pinia.globalStore.setIsScreenLocked(false);
  }


}}}
