module PositiveTS {
    export module Service {
    export class BlockItemPaymentMethod {
        static hasBlockItemPaymentMethods = false
        static paymentMethods = {}
        static smartVouchers = ['tenbis', 'cibus', 'banx', 'goodi', 'yaad', 'valu']
        static allBlockItemPaymentMethods = []

        static initialize () {
            BlockItemPaymentMethod.allBlockItemPaymentMethods = session.allPromotions.filter(promo => promo.template == '1000')
            BlockItemPaymentMethod.hasBlockItemPaymentMethods = !!BlockItemPaymentMethod.allBlockItemPaymentMethods.length

            BlockItemPaymentMethod.paymentMethods = {
                cash: PositiveTS.Storage.Entity.SalePayment.METHOD_CASH,
                credit: PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT,
                check: PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK,
                creditVoucher: PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER,
                voucher: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
            }
        }

        static getBlockVouchersCodes (saleItems) {
            let vouchersCodes = []

            if (BlockItemPaymentMethod.hasBlockItemPaymentMethods){
                saleItems.forEach((saleItem) => {
                    const promoBuy = saleItem.item.promoBuy.split('&')
                    promoBuy.forEach((pb) => {
                        const blockItemPaymentMethod = BlockItemPaymentMethod.getActiveBlockItemPaymentMethod(pb)
                        if (blockItemPaymentMethod){
                            blockItemPaymentMethod.paymentMethodsArray.forEach((pm) => {
                                if (pm.method == 'voucher'){
                                    vouchersCodes.push(pm.type_code)
                                }
                            })
                        }
                    })
                })
            }

            return vouchersCodes
        }

        static getPaymentMethodNameByMethodNumber (methodNumber: number) {
            let ob = BlockItemPaymentMethod.paymentMethods
            return Object.keys(ob).find(key => ob[key] == methodNumber)
        }

        static hasBlockPaymentOnSale (saleItems, salePayments, alertOnError = false) {
            let hasBlock = false
            if (BlockItemPaymentMethod.hasBlockItemPaymentMethods){
                for (let salePayment of salePayments){
                    if (salePayment.amount == 0){
                        continue
                    }
                    let method = BlockItemPaymentMethod.getPaymentMethodNameByMethodNumber(salePayment.method)
                    let paymentMethod = method
                    let typeCode = null 
                    if (method == 'voucher' && !posUtils.isBlank(salePayment.data)){
                        let salePaymentData = JSON.parse(salePayment.data)
                        if (salePaymentData.length == 0){
                            continue
                        }
                        let salePaymentDataFirst = salePaymentData[0]
                        if (salePaymentDataFirst.smartVoucherType){
                            paymentMethod = 'voucher'
                            typeCode = salePaymentDataFirst.smartVoucherType
                        }else{
                            typeCode = salePaymentDataFirst.voucher_type_id
                        }
                    }
                    let res = BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(saleItems, paymentMethod, typeCode)
                    if (res['hasBlock']){
                        hasBlock = true
                        let paymentName = BlockItemPaymentMethod.getMethodName(method, typeCode)
                        let message = `${i18next.t('blockItemPaymentMethod.errorBlockPaymentOnSale', {paymentName: paymentName, itemDescription: res['blockItmes'][0]})}`
                        
                        if (alertOnError){
                            BlockItemPaymentMethod.showAlert(message)
                        }
                        break
                    }
                }
            }
            
            return hasBlock
        }

        static getActiveBlockItemPaymentMethod (code) {
            let blockItemPaymentMethod = BlockItemPaymentMethod.getBlockItemPaymentMethodByCode(code)
            if (blockItemPaymentMethod && Promotions.NewPromotionsEngine.isPromotionActive(blockItemPaymentMethod)){
                return blockItemPaymentMethod
            }
            
            return null
        }

        static getBlockItemPaymentMethodByCode (code) {
            return BlockItemPaymentMethod.allBlockItemPaymentMethods.find((p) => p.code == code)
        }

        static hasBlockPaymentOnSaleItems (saleItems, paymentMethod, typeCode = null, alertOnError = false): boolean|{hasBlock:boolean,blockItmes: Array<string>,message: string} {
            let result = {hasBlock: false, blockItmes: [], message: ''}
            if (BlockItemPaymentMethod.hasBlockItemPaymentMethods){
                for (let saleItem of saleItems){
                    const promoBuy = saleItem.item.promoBuy.split('&')
                    for (let pb of promoBuy){
                        const blockItemPaymentMethod = BlockItemPaymentMethod.getActiveBlockItemPaymentMethod(pb)

                        if (blockItemPaymentMethod){
                            let paymentMethodObject = blockItemPaymentMethod.paymentMethodsArray.find((pm) => {
                                if (typeCode){
                                    return pm.method == paymentMethod && (pm.type_code == typeCode || 
                                        (typeof pm.smart_voucher_type == 'string' ? String(typeCode).toLocaleLowerCase() == pm.smart_voucher_type.toLocaleLowerCase() : false))
                                }else{
                                    return pm.method == paymentMethod
                                }
                            })

                            if (paymentMethodObject){
                                result.hasBlock = true;
                                let existedItem = result.blockItmes.find(e => e== saleItem.itemDescription);
                                if(!existedItem) result.blockItmes.push(saleItem.itemDescription);
                                break
                            }	
                        }
                    }
                    
                }
                
                if (result.hasBlock){
                    let paymentName = BlockItemPaymentMethod.getMethodName(paymentMethod, typeCode)
                    result.message = `${i18next.t(`blockItemPaymentMethod.${result.blockItmes.length == 1 ? 'item' : 'items'}`)} ${result.blockItmes.slice(0,3).join()}${result.blockItmes.length > 3 ? '...' : ''} ${i18next.t(`blockItemPaymentMethod.${result.blockItmes.length == 1 ? 'isBlocked' : 'areBlocked'}`)} ${i18next.t(`blockItemPaymentMethod.blockedToItems`)} <ul class="blocked-items"><li>${paymentName}</li></ul> `
                    if (alertOnError){
                        BlockItemPaymentMethod.showAlert(result.message)
                    }
                }
            }

            return alertOnError ? result.hasBlock : result
        }

        static getMethodName (paymentMethod, typeCode) {
            let paymentName = ''
            if (typeCode){
                if (typeCode == 999){
                    paymentName = i18next.t(`blockItemPaymentMethod.hakafa`)
                }
                let typeCodeString = String(typeCode).toLocaleLowerCase()
                if (!paymentName && BlockItemPaymentMethod.smartVouchers.includes(typeCodeString)){
                    paymentName = i18next.t(`blockItemPaymentMethod.smartVouchers.${typeCodeString}`)
                }

                if (!paymentName){
                    let voucherClass = new PositiveTS.Storage.Entity.Voucher()
                    let voucher = voucherClass.fetchByTypeID(typeCode)
                    paymentName = Array.isArray(voucher) && voucher.length ? i18next.t(`blockItemPaymentMethod.voucher`, {voucherName: voucher[0].name }) : ''
                }
            }else{
                paymentName = i18next.t(`blockItemPaymentMethod.${paymentMethod}`)
            }

            return paymentName
        }

        static showAlert (message) {
            app.showAlert({
                header: i18next.t(`blockItemPaymentMethod.errorTitle`),
                content: message,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
            }) 
        } 
    }
}
}
