module PositiveTS {
  class TableViewQuickRow {
    rowDescription: string;
    rowData: any;
    
    constructor(rowDescription, rowData){
      this.rowDescription = rowDescription;
      this.rowData = rowData;
    }
  }

  export class TableViewQuick {

    tableview: any;
    private _originalTableRows: TableViewQuickRow[] = [];
    private _quickInputSelector: string;

    constructor(thisElement, options, quickInputSelector?: string) {
      this.tableview = (PositiveTS.UI.TableView(thisElement, options));
      if (quickInputSelector) {
        this._quickInputSelector = quickInputSelector;
        this._initQuickInput();
        this._bindOnChange();        
      }

    }

    addRow(cellValues, selected) {
      var _return = this.tableview.addRow(cellValues, selected);
      return _return;
    };

    empty(){
      $(this._quickInputSelector).val("");
      return this.tableview.empty();
    }



    setNumberOfVisibleRows(numberOfRows, options){
      return this.tableview.setNumberOfVisibleRows(numberOfRows, options);
    }

    getSelectedRow(){
      return this.tableview.getSelectedRow();
    }

    selectRow(rowID, allowMultiRowSelection) {
      this.tableview.selectRow( rowID, allowMultiRowSelection );
    }

    quickCurrentTable() {
      var that = this;
      that._originalTableRows = [];
      var allRows = that.tableview.getAllRows();
      $.each(allRows, function(id, row) {
        that._originalTableRows.push(new TableViewQuickRow($(row).find("td").html(), $(row).data()));
      });
    }

    private _filterCurrentTable(filter: string) {
      this.tableview.empty();
      var currentRow: TableViewQuickRow;
      var tbvRow;
      for (var i = 0; i < this._originalTableRows.length; i++) {
        currentRow = this._originalTableRows[i];
        if (currentRow.rowDescription.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          tbvRow = this.tableview.addRow([
            currentRow.rowDescription
          ]);
          this._addRowData(tbvRow, currentRow.rowData);
        }
      }
    }

    private _addRowData(tbvRow, data) {
      var keys = Object.keys(data);
      var key: string;

      for (var i = 0; i < keys.length; i++) {
        key = keys[i];
        tbvRow.data(keys[i], data[key]);
      }
    }

    _initQuickInput(): void {
      new UI.UiInput(this._quickInputSelector);
    }

    private _bindOnChange(): void {
      var selectorField = this._quickInputSelector;
      var that = this;

      $(selectorField).unbind("keyup focus");
      $(selectorField).bind("keyup focus", () => {
        var filterString: string = $(selectorField).val().toString();
        this._filterCurrentTable(filterString);
      });
    }

  }
}

var tableViewQuicks: { [id: string]: PositiveTS.TableViewQuick; } = {}
// Add it to jQuery prototype
jQuery.fn.tableViewQuick = function(options, quickInputSelector?: string) {
  if (this.find(' > .ui-tableview-container').length > 0) {
    return tableViewQuicks[this.selector];
  }
  tableViewQuicks[this.selector] = (new PositiveTS.TableViewQuick(this, options, quickInputSelector)); 
  return tableViewQuicks[ this.selector ];
};