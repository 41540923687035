module PositiveTS {
export module Storage {
export module Entity {
export class Sequence extends IDBEntity {



	constructor() {
		let meta = {
			name: 'Sequence',
			internal: true,
			fields: {
				tenantID: "TEXT",
				companyID: "TEXT",
				storeID: "TEXT",
				posPhysicalID: "TEXT",
				type: "INT",
				sequence: "INT"
			},
			unique: ['tenantID','companyID','storeID','posPhysicalID','type']
		};
		super(meta)
	}

	public tenantID;
	public companyID;
	public storeID;
	public posPhysicalID;
	public type;
	public sequence;

	static TYPE_DEBIT_INVOICE		= 1;
	static TYPE_CREDIT_INVOICE	= 2;
	static TYPE_CREDIT_VOUCHER	= 3;
	static TYPE_Z_REPORT			= 4;
	static TYPE_RECEIPT			= 5;
	static TYPE_Z_MASTER_REPORT			= 6;
	static TYPE_TAX_INV = 7;
	static TYPE_TAX_CREDIT_INV = 8;
	static TYPE_SHIPMENT_INV = 9;
	static TYPE_CREDIT_SHIPMENT_INV = 10;
	static TYPE_PROFORMA = 20;
	static TYPE_PUNCH_CARD_INVOICE = 21;
	static TYPE_CASH_WITHDRAWAL_INVOICE = 30;
	static TYPE_CASH_WITHDRAWAL_REFUND_INVOICE = 31;
	

	static isDebitMethod(invoiceType:number) {
		switch (invoiceType) {
			case Sequence.TYPE_DEBIT_INVOICE:
			case Sequence.TYPE_TAX_INV:
			case Sequence.TYPE_RECEIPT:
			case Sequence.TYPE_SHIPMENT_INV:
				return true;
			case Sequence.TYPE_CREDIT_INVOICE:
			case Sequence.TYPE_TAX_CREDIT_INV:
			case Sequence.TYPE_CREDIT_SHIPMENT_INV:
				return false;
			default:
				return false;
		}
	}
	
	/** Checks if it's a credit refund method.
	 * Doesn't include TYPE_CREDIT_VOUCHER (i.e. "שובר זיכוי") which isn't a traditional refund */
	static isCreditRefundMethod(invoiceType:number) {
		switch (invoiceType) {
			case Sequence.TYPE_DEBIT_INVOICE:
			case Sequence.TYPE_TAX_INV:
			case Sequence.TYPE_RECEIPT:
			case Sequence.TYPE_SHIPMENT_INV:
				return false;
			case Sequence.TYPE_CREDIT_INVOICE:
			case Sequence.TYPE_TAX_CREDIT_INV:
			case Sequence.TYPE_CREDIT_SHIPMENT_INV:
				return true;
			default:
				return false;
		}
	}

	static async getSequenceForInvType(invoiceType:number):Promise<Sequence> {
		return (await (new Storage.Entity.Sequence()).findByTenantAndCompanyAndStoreAndPosAndType(null,
			session.pos.tenantID, session.pos.companyID, session.pos.storeID, 
			session.pos.physicalID, invoiceType))
	}

	async findByTenantAndCompanyAndStoreAndPosAndType (tx, tenantID, companyID, storeID, posPhysicalID, type:number):Promise<Sequence> {
		let aThis = this;

		let result = (await appDB.sequences.where('type').equals(type).toArray())[0];
		if (result == null) {
			throw new Error('sequence not found');
		}
		return aThis.importFromObject(result)
	};
}}}}
