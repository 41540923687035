module PositiveTS {
	export module Application {
	export module Controllers {
	export class LoginViewController extends AbstractViewControllerTS {
		init(options) {
		}
		resume(options) {
			var aThis = loginVC;
			
			// If there is already a session, go to homepage
			if (session.isLoggedIn) {
				// Replace this page with the homepage and exit
				pNavigator.replacePage('homepage', i18next.t('pageTitle.homepage'), null, null);
				
				// We are done
				return;
			}
			
			
			var loginUsername = jsonConfig.getVal(jsonConfig.KEYS.loginUsername);
			var loginPassword = jsonConfig.getVal(jsonConfig.KEYS.loginPassword);
			
			// Empty the fields in case there is something there from last login 
			$('#login-form-username').val(loginUsername);
			$('#login-form-password').val(loginPassword);
			$('#login-form-username').keydown(function(e){
				if(e.keyCode === 9) {
					$('#login-form-password').trigger( "focus" )
				}
			  });
			$('#login-form-password').keydown(function(e){
				if(e.keyCode === 13) {
					$('#login-form-button-login').trigger('click');
				}
				if(e.keyCode === 9) {
					$('#login-form-username').trigger( "focus" )
				}
			});
			
			// Login button click listener
			$('#login-form-button-login').click(aThis.login);
			$('#login-change-tenant-confirm').click(aThis.changeTenant)

			var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : "development";
			document.getElementById("login-current-tenant").innerHTML = subdomain
			if(!loginVC.isAndroid()){
				document.getElementById("change-tenant-btn").style.display = "none"
			}

			// If this is not a touch device, enable keyboard
			if (!Modernizr.touch){
				// Initialize a keyboard for the username field
				$('#login-form-username').keyboard({
					layout     : "hebrew-qwerty-custom",//'hebrew-qwerty-custom',
					usePreview : false,
					autoAccept : true,
					  position   : {
						   of : $('#footer'),
						   my : 'center top',
						   at : 'center bottom'
					  }
				});
				
				// Initialize a keyboard for the password field
				$('#login-form-password').keyboard({
					layout     : "hebrew-qwerty-custom",//'hebrew-qwerty-custom',
					usePreview : false,
					autoAccept : true,
					  position   : {
						   of : $('#footer'),
						   my : 'center top',
						   at : 'center bottom'
					  }
				});
			}
		}
		stop() {
			// Unbind the listeners from the resume function
			$('#login-form-button-login').unbind();
			$('#login-change-tenant-confirm').unbind();
		}
		destroy() {
		}
		// --------------------------------------------------------------------
		// Listeners
		// --------------------------------------------------------------------
		changeTenant(){
			let newTenantName = (<HTMLInputElement>document.getElementById("login-custom-tenant")).value
			let newTenantAddress = "https://" + newTenantName + ".valu.co.il/versions/stable/"
			
			if(newTenantName == ""){
				(<HTMLDialogElement>document.getElementById("login-change-tenant-dialog")).close()
				return
			}

			if(loginVC.isAndroid()){
				let positiveDir = "/positive"
				Android.WriteFile(positiveDir,"ip.txt",newTenantAddress)
			}
			
			window.location.href = newTenantAddress
		}

		isAndroid(){
			return navigator.userAgent.toLowerCase().indexOf('android') > -1 && (<any>window).Android != null;
		}

		async login() {
			// Get the user entered values
			var username = $('#login-form-username').val();
			var password = $('#login-form-password').val();
			
			// Display a loading message while login
			app.showLoadingMessage(i18next.t("loggingIn") + "...");
			
			try {
				await session.login(username, password)
				// Hide loading message
				app.hideLoadingMessage();
				localStorage.setItem("shouldReloadData", "true");
				// Go to homepage			
				pNavigator.replacePage('homepage', i18next.t('pageTitle.homepage'), null,null);
			}
			catch(e) {
				// Hide the loading message
				app.hideLoadingMessage();
				let msg = typeof(e) === "string" ? e : e.message
				// Alert the user about the error
				app.showAlert({
					header: i18next.t('error'),
					content: msg,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
			};
		}
	}
}}}

declare var loginVC:PositiveTS.Application.Controllers.LoginViewController;
loginVC = new PositiveTS.Application.Controllers.LoginViewController();
PositiveTS.Application.Controllers.instances.LoginViewController = loginVC;
