module PositiveTS {

    export module Components {

        export module AddOrEditPromotion {

            export module HaZolMe{

                export const route = "ha-zol-me";
                  
                export function getComponent() {
        
                  return {

                      template: JST.HaZolMe(),

                      created () {

                        if (2 > this.minimumBuyQuantity){
                          Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'minimumBuyQuantity',  value: 2})
                        }

                      },

                      methods: {
                        ...Components.Mixins.modalsMixin.methods,
                        ...Shared.methods
                      },

                      computed: {
                        ...Shared.computed
                      }
                  }
      
                }

            }
          
         
        }
    }

}
