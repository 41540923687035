module PositiveTS {
export module Application {
export module Controllers {
export class StatsViewController extends AbstractViewControllerTS {
	reloadJob:Service.LoadRemoteDataJob = null
	// --------------------------------------------------------------------
	// Controller Life-Cycle
	// --------------------------------------------------------------------
	init() {
	}
	resume (options?) {
		var aThis = statsVC;

		// Bind the reload button to the reload data function
		aThis.reloadDataState();

		// Observe for changes in reachability and update the reload button state accordingly
		PositiveTS.NotificationCenter.addObserver(aThis.reloadDataState, PositiveTS.Reachability.PositiveReachabilityStateChangedNotification);

		// Update the statistic
		// On page first load
		aThis.updateStatistic();

	}
	stop () {
	}
	destroy () {
	}
	triggerUpdateDbUsingUi() {
		pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null);
		pNavigator.pushPage('stats', i18next.t('pageTitle.sync'), '/homepage', null);
		$("#stats-button-reload-data").trigger("click");
	}
		/* This function calls state. and state calls statisticsPerEntity*/
	statistics() {

		let lastUpdate = Pinia.globalStore.lastCatalogUpdate;
		let lastUpdateStr = i18next.t("updateCatalog.catalogEmpty")


		if (lastUpdate != null) {
			 let mom = moment(new Date(lastUpdate));
			 mom.locale(jsonConfig.getVal(jsonConfig.KEYS.lang) || 'he');
			 lastUpdateStr = mom.format("LLLL");
		}

		// Extract all the other statistics from localStorage
		return {
			state: window.localStorage.getItem(storage.LocalStorageStateKey),
			message: window.localStorage.getItem(storage.LocalStorageStateMessageKey),
			version: '-',
			lastUpdate: lastUpdateStr
		};
	}
	updateStatistic() { //TODO: refactor - too long and probably not needed

		// // Show some message while we are loading the statistics
		// app.showLoadingMessage(i18next.t("loadingStatistics"));

		// // Fetch storage statistics
		let statistics = statsVC.statistics()
		// If the storage state is initial, report the last update as never
		if (statistics.state == storage.StatusInitial) {
			// Set the last storage update to never
			$('#stats-storage-last-update').text(i18next.t("never"));
		} else {
			// For every other state, set the last storage update
			$('#stats-storage-last-update').text(statistics.lastUpdate);
		}

		// If state is not OK, display a message
		if (statistics.state != storage.StatusOK && statistics.state != storage.StatusInitial) {
			// Convert the state to a readable text
			var stateText;
			switch (statistics.state) {
				case storage.StatusInitial:
					stateText = i18next.t("entityStatusEmpty");
					break;
				case storage.StatusOK:
					stateText = i18next.t("entityStatusValid");
					break;
				case storage.StatusPartial:
					stateText = i18next.t("entityStatusPartial");
					break;
				case storage.StatusInvalid:
					stateText = i18next.t("entityStatusInvalid");
					break;
				default:
					stateText = i18next.t("entityStatusInvalid");
					break;
			}

			// Set the state
			$('#stats-storage-state').text(stateText);

			// Set the state message
			$('#stats-storage-state-message').text(statistics.message);

			// Show the 'not OK' message and scroll to it
			$('#stats-storage-state-not-ok').show();
		} else {
			// Ensure the 'not OK' message is hidden
			$('#stats-storage-state-not-ok').hide();
		}

		app.hideLoadingMessage();

	}
	// --------------------------------------------------------------------
	// Listeners
	// --------------------------------------------------------------------
	reloadDataState() {
		var aThis = statsVC;

		// Unbind the reload button listener
		$('#stats-button-reload-data').unbind('click');
		$('#stats-button-reload-back').unbind('click');

		$('#stats-button-reload-back').click(() => {
			pNavigator.back()
			if (PositiveTS.Service.CheckPosPropriety.isCannotWorkOnPos()){
Pinia.componentsStore.openComponent( {componentName:"checkPosProprietyDialog", args: []})
			}
		})

		// Are we online?
		if (PositiveTS.Reachability.isOnline) {
			// --- We are online
			// Set a click listener for the reload button
			$('#stats-button-reload-data').click(function(){
				if(jsonConfig.getVal(jsonConfig.KEYS.passwordProtected) && !posUtils.isNullOrUndefinedOrEmptyString(session.pos.employeeID)) {
					Pinia.globalStore.setAvoidNextLogin(true);
				}
				aThis.reloadAllData(false);
			});
			if(session.pos.isRoshemet){
				$('#stats-button-full-reload-data').click(function(){
					aThis.reloadAllData(true);
				});
			}
			else{
				$('#stats-button-full-reload-data').hide();
			}

			// Change its style to active
			(<HTMLInputElement>document.getElementById('stats-button-reload-data')).disabled = false;
		} else {
			// --- We are offline
			// Change the reload button style to inactive
			(<HTMLInputElement>document.getElementById('stats-button-reload-data')).disabled = true;
		}
	}
	async reloadAllData(fullUpdate = false) {
		var aThis = statsVC;

		// If the reload data job is already running, abort!
		if (aThis.reloadJob != null) {
			console.debug('Job already running!');
			return;
		}
		if (!PositiveTS.Reachability.isOnline) {
			await promiseShowAlert({
				header: i18next.t('updateCatalog.title'),
				content: i18next.t("updateCatalog.offlineError"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true,
			  });
			return;
		}

		app.showLoadingMessage(i18next.t("updateCatalog.preperingCatalogLoad"), null, false);
		try {
			await jsonConfig.updateLocalStorageFromAjax();
		} catch (error) {
			console.error(error);
			app.hideLoadingMessage();

			if(!posUtils.isBlank(error.message)) {
				await promiseShowAlert({
					header: i18next.t('updateCatalog.title'),
					content: i18next.t(`updateCatalog.${error.message}`),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				  });

			} else if(error.request.status == 0) {
				await promiseShowAlert({
					header: i18next.t('updateCatalog.title'),
					content: i18next.t('updateCatalog.offlineError'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				  });
			} else {
				await promiseShowAlert({
					header: i18next.t('updateCatalog.title'),
					content: i18next.t('updateCatalog.generealError'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				  });
			}
			return;
		}
		await aThis.pollForCatalogOnDemandIfNeeded();

		try {
			await PositiveTS.Service.PluxeeService.Soap.listCompanies();
		} catch (err) {
			console.error(err.message);
		}

      	// Create new relod data job
      	aThis.reloadJob = new Service.LoadRemoteDataJob();

      	// Register this view controller as an observer for the reload data job
      	PositiveTS.NotificationCenter.addObserver(aThis.jobCompleteObserver, Service.LoadRemoteDataJob.JobCompletedNotification);
      	PositiveTS.NotificationCenter.addObserver(aThis.jobFailedObserver,   Service.LoadRemoteDataJob.JobFailedNotification);
      	PositiveTS.NotificationCenter.addObserver(aThis.jobProgressObserver, Service.LoadRemoteDataJob.JobProgressNotification);

      	// Show loading message
      	app.showLoadingMessage(i18next.t('loadingData'), 0, false);
      	aThis.reloadJob.run(fullUpdate);
	}

	getCatalogOnDemand() {
    return Service.FetchReq.jsonReq(`/catalog/get_catalog_on_demand?pos_device_id=${session.pos.deviceID}`,"get")
    .then(ret => {
      switch (ret.result.status) {
        case 1: //up to date catalog available
        	return true
        case 2: //catalog running - poll again in a few seconds
          return false
        case 3: //catalog started running - poll again in a few seconds
          return false
        default:
          throw "invalid return status from catalog on demand"
      }
    })
	}

	pollForCatalogOnDemandIfNeeded() {
		return new Promise((resolve,reject) => {
			if (!session.pos.onDemandCatalog) {
				return resolve()
			}
			app.showLoadingMessage(i18next.t("updateCatalog.generatingCatalog"), null, false);
			this.getCatalogOnDemand()
			.then(upToDateCatalogAvailable => {
				if (upToDateCatalogAvailable) {
					return resolve()
				}
				else {
					let intervalId = setInterval(() => {
						this.getCatalogOnDemand()
						.then(upToDateCatalogAvailable => {
							if (upToDateCatalogAvailable) {
								clearInterval(intervalId)
								resolve()
							}
						})
						.catch(err => {
        			console.log(err)
        			app.hideLoadingMessage()
        			clearInterval(intervalId)
        			reject(err)
			      })

					},2000)
				}
			})
			.catch(err => {
        console.log(err)
        app.hideLoadingMessage()
      })
		})
	}
	// --------------------------------------------------------------------
	// Observers
	// --------------------------------------------------------------------
	async storageObserver (isSuccess:boolean) {
		let aThis = statsVC;
		// Update the statistic
		let completeOK = false;
		let lastUpdate = Pinia.globalStore.lastCatalogUpdate;
		if (session.pos.isRoshemet && lastUpdate == null && isSuccess) {
			app.showLoadingMessage(i18next.t('convertingServerInfo'))
			try {
				let dbRecord = await appDB.dataDumps.where('name').equals(PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB).first();
				let buffer = await dbRecord.data.arrayBuffer();
				let data = new Uint8Array(buffer);
				let db = new (window as any).SQL.Database(data);

				let emps = Service.WasmDB.execAsObjectWithDB('select * from employee', db).map(emp => Storage.Entity.Employee.import(emp))
				let items = Service.WasmDB.execAsObjectWithDB('select * from item', db).map(item => Storage.Entity.Item.import(item))
				let inventoriesReq = await PositiveTS.Service.FetchReq.textReq("/pos_items/current_items_inventory",'get', undefined);
				let inventories = JSON.parse(inventoriesReq.result).store_items;
				let inventoriesMap = new Map(inventories.map(i => [i.item_id, i.current_inventory]));

				let itemBarcodes:Array<any> = Service.WasmDB.execAsObjectWithDB('select * from itembarcode', db);
				itemBarcodes = itemBarcodes.filter(ib => ib.code != ib.barcode);

				let promotions = Service.WasmDB.execAsObjectWithDB('select * from promotion', db)
									.map(promotion => Storage.Entity.Promotion.import(promotion))

				let departments:Array<any> = Service.WasmDB.execAsObjectWithDB('select * from primaryCategory where id > 0', db);
				let promises = [];
				await appDB.transaction("rw",appDB.employees,appDB.localItems,appDB.departments,appDB.promotions,async () => {
					promises.push(appDB.departments.bulkPut(departments.map(dep => {
						let newDep = new Storage.Entity.Department(dep.name);
						newDep.sortOrder = dep.sortOrder ? dep.sortOrder : 0;
						newDep.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY;
						newDep.serverID = dep.id;
						newDep.timestamp = (new Date()).getTime();
						return newDep;
					})));
					promises.push(appDB.localItems.bulkPut(items.map(item => {
						item.departmentId = -1; //TODO: fixme
						item.serverDepartmentId = item.primaryCategoryId;
						item.currentInventory = Number(inventoriesMap.get(item.id)) || 0;
						let itemBarcode = itemBarcodes.filter(ib => ib.code == item.code)[0];
						if (itemBarcode != null) {
							item.barcode = itemBarcode.barcode;
						}
						item.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY;
						return item;
					})));
					promises.push(appDB.employees.bulkPut(emps.map(emp => {
						emp.serverID = Number(emp.employeeID);
						emp.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY;
						emp.isLocked = false;
						return emp;
					})));

					promises.push(appDB.promotions.bulkPut(promotions.map(promotion => {
						promotion.serverID = Number(promotion.code)
						promotion.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY
						promotion.timestamp = (new Date()).getTime();
						return promotion
					})))

				await Promise.all(promises);
					let allDeps = await appDB.departments.toArray();
					await appDB.employees.toCollection().modify((value, ref) => {delete ref.value.meta; delete ref.value._data;});
					await appDB.promotions.toCollection().modify((value, ref) => {
						delete ref.value.meta;
						delete ref.value._data;
						delete ref.value._new;
						value.dependantCodes = []
					});

					return await appDB.localItems.toCollection().modify((value, ref) => {
						delete ref.value.meta; delete ref.value._data;
						if (value.serverDepartmentId > 0) {
							let dep = allDeps.filter(dep => dep.serverID == value.serverDepartmentId)[0];
							if (dep != null) {
								ref.value.departmentId = dep.id;
							}
						}
					});
				});
				app.hideLoadingMessage();
				completeOK = true;
			}
			catch (err) {
				app.hideLoadingMessage();
				completeOK = false;
				app.showAlertDialog({
          header: i18next.t('error'),
          content: i18next.t("conversionFailed"),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
				}, null, null);
				console.error(err);
				console.error(err.stack);
			}

		} else if (isSuccess) {
			if (session.pos.isCaveret) {
				app.showLoadingMessage(i18next.t("updateCatalog.generatingItemListFile"))
				await PositiveTS.Service.CaveretFiles.createItemsFile();
				app.hideLoadingMessage();
			}
			await Service.FetchReq.jsonReq(`/successfull_local_update_db`, "post", { devicePhysicalId: session.pos.physicalID })
			completeOK = true;
			aThis.updateStatistic();
			if (session.pos.isVendingMachinePos) {
				try {
					console.info("attempting to fetch vending machine's inventory");
					app.showLoadingMessage(i18next.t('vendingMachine.inventory.loading'));
					await Pinia.vendingMachine.getCurrentInventoryFromServer();
					app.showLoadingMessage(i18next.t('convertingServerInfo'));

				} catch (err) {
					app.hideLoadingMessage();
					PositiveTS.Service.Filelog.log(err.message || "Failed on catalog conversion", err, false, 'error');
					console.error(err);
					let msgForUser = err?.data?.msgForUser || i18next.t('vendingMachine.inventory.errorOnLoad');
					msgForUser = i18next.t('vendingMachine.inventory.catalogUpdatedBut') + msgForUser
					await app.promiseShowAlert({ header: i18next.t('error'), content: msgForUser, hideCancelButton: true });
				}
			}
		}

		if (completeOK) {
			isCatalogUpdated.saveLocalStorageCatalogUpdateDate();
			aThis.updateStatistic();
			app.sendMessageToServiceWorker({type: 'catalogFinishedSuccessfully'})
			session.pos.isRestartRequired = true;
			// Notify update complete
			app.showAlertDialog({
				header: i18next.t('pageTitle.sync'),
				content:i18next.t('syncComplete'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
				}, function () {
					posUtils.refreshPos();
				}, null);
		}
	}

	jobCompleteObserver(jobResults) { // --- Job completed successfully
		var aThis = statsVC;

		// Check that the notification is from our job
		if (!('identifier' in jobResults) || jobResults.identifier != Service.LoadRemoteDataJob.identifier) {
			// Not our job, ignore!
			return;
		}

		aThis.reloadJob = null;

		// After the job completed, the data should be stored locally, so show a relevant message
		app.showLoadingMessage(i18next.t("storingDataLocally"),null,false);
	}
	jobFailedObserver(jobResults) {
		var aThis = statsVC;

		// Check that the notification is from our job
		if (!('identifier' in jobResults) || jobResults.identifier != Service.LoadRemoteDataJob.identifier) {
			// Not our job, ignore!
			return;
		}

		// --- The job failed
		// Hide the loading message
		app.hideLoadingMessage();

		// Nullify the job pointer
		aThis.reloadJob = null;

		// Show the error message to the user
		$('#stats-storage-state-message').text(jobResults.error.message);

		// Show the 'not OK' message and scroll to it
		$('#stats-storage-state-not-ok').show();
		statsVC.handleFlightsErrorIfNeeded();
	}
	jobProgressObserver(jobResults) {

		// Check that the notification is from our job
		if (!('identifier' in jobResults) || jobResults.identifier != Service.LoadRemoteDataJob.identifier) {
			// Not our job, ignore!
			return;
		}

		// Update the progress message
		app.showLoadingMessage(jobResults.message, jobResults.progressValue);
	}

	async handleFlightsErrorIfNeeded() {

		if (session.pos.hasFlights && Pinia.flightsStore.isFlightLoadedNow  && Pinia.flightsStore.currentFlight) {
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('updateCatalog.flightsCatalogError'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);

			// Disconnecy Flight if there is an error in the catalog
			PositiveTS.Service.FetchReq.jsonReq('/flights/detach_pos', 'post', { flight_id: Pinia.flightsStore.currentFlight.id, pos_device_id: session.pos.deviceID }).catch(err => console.log(err))
			Pinia.flightsStore.disconnectFlight();
		}
	}
}}}}
declare var statsVC:PositiveTS.Application.Controllers.StatsViewController;
statsVC = new PositiveTS.Application.Controllers.StatsViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.StatsViewController = statsVC;
