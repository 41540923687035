module PositiveTS {
  export module Service {

    interface ItfRetGetBudget {
      success: boolean;
      balance:string;
      error:string;
      status_code:string;
      companyID:string
    }

    interface ItfAddTransaction {
      success: boolean;
      balance:string;
      error:string;
      transaction_id: string;
      status_code:string;
      approved_sum:string;
      company_id: string;
      items_amount_discount: string;
      total_discount: string;
      result_message: string;
    }

    interface ItfDeleteTransaction {
      success: boolean;
      error:string;
      transaction_id: string;
      status_code:string;
    }

    interface ItfSetupTransaction {
      success: boolean;
      error:string;
      pos_parameters: string;
      status_code:string;
    }

    export module MultipassCompaniesList{
      let companiesLimit = null

      export async function getCompaniesLimit(): Promise<Array<string>> {
        if(!companiesLimit){
          let queryResult = await PositiveTS.Service.WasmDB.promiseSql('select multipassCompanyIdLimit from Voucher WHERE smartVoucherType = "Multipass" AND multipassCompanyIdLimit != ""')
          let result = [];
          for (let row of queryResult) {
            let multipassCompanyIdLimit = row['multipassCompanyIdLimit'] ? row['multipassCompanyIdLimit'].trim() : null;
            if (posUtils.isPresent(multipassCompanyIdLimit)) {
              let splitted = multipassCompanyIdLimit.split('&').filter(str => posUtils.isPresent(str));
              result = result.concat(splitted);
            }
          }
          companiesLimit = result;
        }
        return companiesLimit;
      }
    }

    export class Multipass extends SmartVoucher {
      hasCVV = true
      async getBalance(cardNumber: string, cvv: string = ""): Promise<GetBalanceResponse> {

        try{
          let url = `/multipass/${cardNumber}?device_id=${posVC.sale.posDeviceID}&cvv=${cvv}`
          let headers = { 'Authorization': `Token token=${session.pos.access_token}` }
          let response = await fetch(url, { headers: headers });
          let result:ItfRetGetBudget = await response.json();
          this.log({ method: 'getBalance', requsetData: { url, headers }})          

          let companiesLimit = await MultipassCompaniesList.getCompaniesLimit()
          companiesLimit = companiesLimit.map(companyId => `${companyId}`)
          let companyID = `${result.companyID}`
          if(companiesLimit.length > 0 && result.success){
            if(!companiesLimit.includes(companyID)){
              this.log({  message: `${companyID} is not allow of multipass's (WASM) comapny list`, companiesLimit }, "error")
              return {
                success: false,
                device_id: session.pos.deviceID,
                balance: Number(result.balance) / 100,
                error: i18next.t("multipass.companyNotAllowed")
              }
            }
          }

          return {
            success: result.success,
            device_id: session.pos.deviceID,
            balance: Number(result.balance) / 100,
            error: result.error
          }
        }
        catch(error) {
          this.log({ method: 'getBalance', message: "An error occured while get mutlipass balance, check next log error", error: error }, 'error')
          return  {success: false,
                  balance: 0,
                  error: "שגיאה כללית אנא פנה לתמיכה"
            };
        }
      }


      async loadBudget(cardNumber: string, amount: number){

        var posTranId = moment().format("YYYY-MM-DDTHH:mm:ss");

        let response = await fetch(`/multipass`, {
          method: 'post',
          headers: {
            'Authorization': `Token token=${session.pos.access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            number: cardNumber,
            device_id: posVC.sale.posDeviceID,
            action_type: 'load_budget',
            pos_tran_id: posTranId,
            amount: amount
            })
          });
          let result = await response.json()

          return result;

      }


      async pay(byrefVoucherData: any, amount: number, cvv?: string): Promise<any> {
        var sCvv = cvv|| "";
        var posTranId = new Date().getTime().toString();
          let companiesLimit = await MultipassCompaniesList.getCompaniesLimit();
          if(companiesLimit.length > 0){
            let balanceResult = await this.getBalance(byrefVoucherData.barCode,cvv);
            if(!balanceResult.success){
              throw new Error(balanceResult.error)
            }

            let multipassResponse = await fetch(`/multipass/${byrefVoucherData.barCode}?device_id=${posVC.sale.posDeviceID}&cvv=${cvv}`, {
              headers: { 'Authorization': `Token token=${session.pos.access_token}` }});
  
            let multipassResult = await multipassResponse.json()
  
            let companyID = `${multipassResult.companyID}`
  
            let voucherQuery = await PositiveTS.Service.WasmDB.promiseSql(`select * from Voucher WHERE smartVoucherType = "Multipass" AND typeID = ${byrefVoucherData.voucher_type_id}`)
  
            if(voucherQuery.length > 0) {
              let voucher = voucherQuery[0]
              let voucherCompanyIdsLimit = voucher.multipassCompanyIdLimit.trim();
              let voucherCompanyIdsLimitSplitted: string[] = voucherCompanyIdsLimit.split('&').filter(str => posUtils.isPresent(str));
              if(!voucherCompanyIdsLimitSplitted.includes(companyID)) {
                this.log({  message: `${companyID} is not allow of multipass's (WASM) comapny list`, companiesLimit }, "error")
                throw new Error(i18next.t("multipass.companyNotAllowed"))
              }
  
            } 
          
          }

          let multipasslItemsObj = PositiveTS.Service.MultipassItems.getMultipasslItems(posVC.saleItems);
          let body = {
            number: byrefVoucherData.barCode,
            cvv: sCvv,
            device_id: posVC.sale.posDeviceID,
            action_type: 'withdraw',
            pos_tran_id: posTranId,
            total_amount: this._formatAmount(amount),
            multipass_tran_type: multipasslItemsObj.multipassTranType,
            cat_items_list: multipasslItemsObj.catItemsList,
          }
        
          let response = await fetch(`/multipass`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })

          let result:ItfAddTransaction = await response.json()
          this.log({ method: 'pay', request: body, response: result })

            if (result.success){
              byrefVoucherData.response = JSON.stringify(result);
              byrefVoucherData.amount = session.fixedFloat( Number( result.approved_sum ) / 100 );

              byrefVoucherData.items_amount_discount = session.fixedFloat( Number( result.items_amount_discount ) / 100 );
              result.total_discount = result.total_discount || "0";
              byrefVoucherData.total_discount = session.fixedFloat( result.total_discount );

              byrefVoucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS;
              byrefVoucherData.actionType = SmartVoucherActionTypes.WITHDRAW
              await  PositiveTS.Service.MultipassDiscountItemService.addDiscount(byrefVoucherData.items_amount_discount + byrefVoucherData.total_discount )
              return byrefVoucherData
            } else {
              this.log({ method: 'pay', message: "An error occured while doing mutlipass pay, check next log error", error: result.error }, 'error')
              throw new Error(result.error)
            }
      }

      // VoucherToCancel is byref
      // on error catch and return success: false
      cancelPayment(byrefVoucherToCancel): Promise<ActionResponse> {
        var voucherToCancel = JSON.parse(byrefVoucherToCancel.response)
        var mltpTransactionId = voucherToCancel.transaction_id;

        return fetch(`/multipass`, {
                  method: 'post',
                  headers: {
                    'Authorization': `Token token=${session.pos.access_token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    transaction_id: mltpTransactionId,
                    device_id: posVC.sale.posDeviceID,
                    action_type: 'cancel_withdraw',
                  })
        }).then((response) => {
          let totalDiscount = parseFloat(voucherToCancel.total_discount ) + parseFloat(voucherToCancel.items_amount_discount )/100;

          if (PositiveTS.Service.MultipassDiscountItemService.isMultipassDiscountItemExists(posVC.saleItems)){
            return PositiveTS.Service.MultipassDiscountItemService.addDiscount( (totalDiscount) * -1 )
            .then( ()=>{
              return response.json();           
            })
          } else {
            return response.json();
          }

          

        })
        .then( (result:ItfDeleteTransaction )=>{
          return Promise.resolve({
            success: result.success,
            error: result.error
          });
        })
        .catch((e) => {
          console.error(e)
          return Promise.resolve({ success: false, error: "שגיאה כללית אנא פנה לתמיכה" });
        });

      }

      async setup(): Promise<ActionResponse> {
        try{
          let headers = {
            'Authorization': `Token token=${session.pos.access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          };
          let body = {
            device_id: session.pos.deviceID,
            action_type: 'setup',
          }
          let response = await fetch(`/multipass`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(body)});

            let result:ItfSetupTransaction = await response.json();
            this.log({ method: 'setup', body, headers, action: 'setup' })
            return {
              success: result.success,
              error: result.error,
              pos_parameters: result.pos_parameters,
            }

        }
        catch(error){
          this.log({ method: 'setup', message: "An error occured while doing mutlipass setup, check next log error", error: error }, 'error')
          return { success: false, error: "שגיאה כללית אנא פנה לתמיכה" };
        }
      }


      // This is UI input box returns card number scan
      getCardNumber() {
        return new Promise<GetCardNumberResponse>((resolve, reject) => {
          return this._getInputCardScan()
            .then(cardNumber => {
            resolve({
              cardNumber: cardNumber,
              options: {}
            })
          })
        })
      }

      // Not relevant for multipass
      loadCard(cardNumber: string, amount: number, options?: any): Promise<any> {
        return Promise.resolve({ success: false, error: "שגיאה כללית אנא פנה לתמיכה" });
      }

      // Not relevant for multipass
      cancelLoad(byrefPaymentToCancel): Promise<any> {
        return Promise.resolve({ success: false, error: "שגיאה כללית אנא פנה לתמיכה" });
      }

      private _formatAmount(amount:number):string{
        return Math.trunc( Math.abs(amount) * 100 ).toString();
      }

      private _getInputCardScan() {
        let options = new PositiveTS.Dialogs.InputDialogOptions()
        options.header = 'הזן כרטיס לטעינה'
        options.description = 'סרוק כרטיס לטעינה'
        options.inputPlaceHolder = 'סרוק כרטיס לטעינה'
        options.showCancelButton = false
        options.emptyErrorMessage = 'סרוק כרטיס לטעינה'
        options.inputValidator = function() {
          return true;
        }
        return inputDg.open(options)
      };

      log(data: object | string, type: 'info'|'error'|'debug'|'warn' = 'info') {
        return Service.Filelog.log('mutlipass', typeof(data) === 'string' ? data : JSON.stringify(data), false, type);
      }
    }
  }
}
