module PositiveTS {
  export module Components {
    export module FlightsOfflineLogin {

      export function getComponent() {
        return {
          template: JST.flightsOfflineLogin(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          methods: {
            async login() {
              if (!this.stepData.username) {
                return;
              }

              let employee = new PositiveTS.Storage.Entity.Employee();
              let result:PositiveTS.Storage.Entity.Employee[];
              
              // If the team was't changed
              if (this.isPreviousClosedLegTeam) {
                result = this.lastClosedLeg.authorizes_employees.filter(employee => employee.magneticCard == this.stepData.username);
              } else {
                result = await employee.fetchByStoreAndMagneticCardAndCanConfirmManagerDiscount(session.pos.storeID, this.stepData.username);
              }

              if (!result.length) {
                await this.promiseShowError(i18next.t("flightsWizard.offlineLogin.userNotFound"));
                this.focusOnUsername();
                return;
              }
              
              this.stepData['loggedInEmployee'] = result[0]

              this.goToNextStep();
            },
            focusOnUsername() {
              this.$refs.username.focus();
            }
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight,lastClosedLeg,isPreviousClosedLegTeam} = Pinia.storeToRefs(flightsStore);
            return {currentFlight,lastClosedLeg,isPreviousClosedLegTeam}
          },
          mounted() {
            this.focusOnUsername();
          }
        }
      }
    }
  }
}