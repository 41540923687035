module PositiveTS {
    export module Components {
        export module SelfServicePluxee {    
            export module SelfServicePluxeeSelectCompanyDialog {  
                const _vueComponentName = 'self-service-pluxee-select-company-dialog';
          
                export function create() {
                  let selfServicePluxeeSelectCompanyDialog = {
                    template: JST.selfServicePluxeeSelectCompanyDialog(),
                    mixins: [Mixins.oneClickMixin],
                    methods: {
                      open() {
                        if (this.$el.hasAttribute('open')) {
                          return;
                        }

                        this.companies = PositiveTS.Service.PluxeeService.Voucher.listOfCompanies()
                        this.$el.showModal();

                        return new Promise(resolve => {
                            this.resolveFunc = resolve
                        })
                      },

                      close () {
                        this.$el.close();
                      },

                      selectCompanyAndClose (company) {
                        let res = {selected : true, company: company}
                        this.close()
                        this.resolveFunc(res)
                      },

                      back () {
                        let res = {selected : false, company: null}
                        this.close()
                        this.resolveFunc(res)
                      },
                    },

                    data() {
                      return {
                        resolveFunc: null,
                        companies: [],
                        search: '',
                      };
                    },
                    computed: {
                        pluxeeCompanies () {
                            let companies = []

                            if (posUtils.isPresent(this.companies)){
                                if (posUtils.isPresent(this.search)){
                                    companies = this.companies.filter(company => {
                                        return (_.startsWith(company.companyCode, this.search) || _.endsWith(company.companyCode, this.search)) || (_.startsWith(company.companyName, this.search) || _.endsWith(company.companyName, this.search))
                                    })
                                }else{
                                    companies = this.companies
                                }
                            }

                            return companies
                        }
                    },
                  }
          
                  VueApp.component(_vueComponentName, selfServicePluxeeSelectCompanyDialog)
                }
            }
        }
    }
  }


