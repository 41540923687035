import { defineStore } from 'pinia';
import { computed, reactive, watch } from 'vue';
import { useGlobalStore } from './globalStore';


interface VendingMachineState {
    port: SerialPort;
    reader: any;
    responseBuffer: number[],
    hexadecimalBuffer: string[],
    commandSender: any;
    commandQueue: VendingMachine.VendingMachineCommand[],
    lastStatusResponse: string;
    lastTempCheckResponse: number;
    COMMAND_INTERVAL: number;
    statusMapper: object
    successCodes: object
    errorCodes: object
    alertKeys: { [key: string]: { key: string, alert: number } }
    doorOpenCodes: { [key: string]: { key: string, alert: number } }
    isDoorOpen: boolean
    isHavingError: boolean
    errorType: string
    logsEnabled: boolean
    queueDisabled: boolean
    tempCheckCounter: number
}

const vendingMachineStore = defineStore('vendingMachine', () => {
    const globalStore = useGlobalStore();

    const state: VendingMachineState = reactive({
        port: undefined,
        reader: undefined,
        responseBuffer: [],
        hexadecimalBuffer: [],
        commandSender: undefined,
        commandQueue: [],
        lastStatusResponse: '',
        lastTempCheckResponse: 0,
        COMMAND_INTERVAL: 1000,

        statusMapper: {
            '00': 'POS ERROR',
            '30': 'READY (NOTHING TO REPORT)',
            '31': 'IN PROCESS - BUSY',
            '32': 'INVALID TRAY REQUESTED',
            '33': 'INVALID CHANNEL REQUESTED',
            '34': 'EMPTY CHANNEL',
            '35': 'FAULT IN ELEVATOR MOTOR (POSSIBLE JAM)',
            '37': 'FAULT IN ANY OF THE CABINET\'S PHOTOTRANSISTORS',
            '38': 'NO CHANNEL DETECTED',
            '39': 'DETECTING PRODUCT',
            '41': 'FAULT IN 485 BUS',
            '42': 'PRODUCT UNDER THE ELEVATOR ALARM',
            '43': 'ERROR WHEN ELEVATOR APROACHING TO A POSITION',
            '44': 'FAULT IN KEYBOARD',
            '45': 'EEPROM WRITING ERROR',
            '46': 'FAULT COMMUNICATING WITH TEMPERATURE CONTROL',
            '47': 'THERMOMETER DISCONNECTED',
            '48': 'THERMOMETER PROGRAMMING LOST',
            '49': 'THERMOMETER FAULTY',
            '4a': 'CHANNELS POWER COMSUMPTION DETECTOR FAULTY',
            '4b': 'ELEVATOR DOES NOT FIND CHANNEL/TRAY',
            '4c': 'ELEVATOR DOES NOT FIND DELIVERY PRODUCT POSITION',
            '4d': 'INTERIOR OF THE ELEVATOR BLOCKED',
            '4e': 'ERROR IN TESTER OF PRODUCT DETECTOR',
            '4f': 'WAITING FOR PRODUCT TO BE REMOVED',
            '50': 'PRODUCT IS EXPIRED BECAUSE TEMPERATURE',
            '59': 'PRODUCT IS EXPIRED (NSF ALARM 1)',
            '5a': 'PRODUCT IS EXPIRED (NSF ALARM 2)',
            '61': 'PRODUCT IS EXPIRED (NSF ALARM 3)',
            '62': 'PRODUCT IS EXPIRED (NSF ALARM 4)',
            '63': 'PRODUCT IS EXPIRED (NSF ALARM 5)',
            '64': 'PRODUCT DETECTOR DID NOT CHANGE DURING ITS VERIFICATION TEST',
            '81': 'DOOR OPEN' // when testing the code I get before the random gibbrish is 81 every time the door is opened
        },

        successCodes: {
            '30': 'READY (NOTHING TO REPORT)',
            '41': 'FAULT IN 485 BUS',
            '4f': 'WAITING FOR PRODUCT TO BE REMOVED',
        },

        errorCodes: {
            '00': 'POS ERROR',
            '31': 'IN PROCESS - BUSY',
            '32': 'INVALID TRAY REQUESTED',
            '33': 'INVALID CHANNEL REQUESTED',
            '34': 'EMPTY CHANNEL',
            '35': 'FAULT IN ELEVATOR MOTOR (POSSIBLE JAM)',
            '37': 'FAULT IN ANY OF THE CABINET\'S PHOTOTRANSISTORS',
            '38': 'NO CHANNEL DETECTED',
            '39': 'DETECTING PRODUCT',
            '42': 'PRODUCT UNDER THE ELEVATOR ALARM',
            '43': 'ERROR WHEN ELEVATOR APROACHING TO A POSITION',
            '44': 'FAULT IN KEYBOARD',
            '45': 'EEPROM WRITING ERROR',
            '46': 'FAULT COMMUNICATING WITH TEMPERATURE CONTROL',
            '47': 'THERMOMETER DISCONNECTED',
            '48': 'THERMOMETER PROGRAMMING LOST',
            '49': 'THERMOMETER FAULTY',
            '4a': 'CHANNELS POWER COMSUMPTION DETECTOR FAULTY',
            '4b': 'ELEVATOR DOES NOT FIND CHANNEL/TRAY',
            '4c': 'ELEVATOR DOES NOT FIND DELIVERY PRODUCT POSITION',
            '4d': 'INTERIOR OF THE ELEVATOR BLOCKED',
            '4e': 'ERROR IN TESTER OF PRODUCT DETECTOR',
            '4f': 'WAITING FOR PRODUCT TO BE REMOVED',
            '50': 'PRODUCT IS EXPIRED BECAUSE TEMPERATURE',
            '59': 'PRODUCT IS EXPIRED (NSF ALARM 1)',
            '5a': 'PRODUCT IS EXPIRED (NSF ALARM 2)',
            '61': 'PRODUCT IS EXPIRED (NSF ALARM 3)',
            '62': 'PRODUCT IS EXPIRED (NSF ALARM 4)',
            '63': 'PRODUCT IS EXPIRED (NSF ALARM 5)',
            '64': 'PRODUCT DETECTOR DID NOT CHANGE DURING ITS VERIFICATION TEST',
            '81': 'DOOR OPEN' // when testing the code I get before the random gibbrish is 81 every time the door is opened
        },

        alertKeys: {},
        doorOpenCodes: {},

        isDoorOpen: false,
        isHavingError: false,
        errorType: localStorage.getItem('vendingMachineErrorType') || "",
        logsEnabled: localStorage.getItem('enableVendingMachineLogs') == "true",
        queueDisabled: false,
        tempCheckCounter: 0,
    })

    watch(() => state.errorType, (newVal, oldVal) => {
        if (newVal != oldVal) {
            localStorage.setItem('vendingMachineErrorType', newVal);
        }
    })
    const statusName = computed(() => {
        return state.statusMapper[state.lastStatusResponse]
    });
    const railsId = computed(() => {
        return session.pos.vendingMachineRailsId;
    });

    const logger = (...args: any[]) => {
        if (state.logsEnabled) {
            console.log("VendingMachine: ", ...args)
        }
    }
    const toggleLogs = (value: boolean) => {
        state.logsEnabled = value;
        localStorage.setItem('enableVendingMachineLogs', value.toString());
    }

    async function requestPort() {
        try {
            const port = await navigator.serial.requestPort();
            logger('Port opened, initializing machine...');
            await openPort(port);
            logger('Machine initialized, checking status...');
        } catch (e) {
            console.error("Error in request port", e)
        }
    }

    async function init() {
        try {
            logger('Attempting to initialize machine...');
            const ports = await navigator.serial.getPorts();
            let port = null;
            if (ports.length == 0) {
                port = await navigator.serial.requestPort();
            } else {
                port = ports[0];
            }
            await openPort(port);
            setAlerts();
            globalStore.setIsVendingMachinePos(true)
        } catch (e) {
            PositiveTS.Service.Logger.error("Error initializing machine:" + e);
        }
    }



    async function openPort(port) {
        try {
            state.port = port;
            await state.port.open({ baudRate: 9600, dataBits: 8, parity: 'none', stopBits: 1 });
            logger('Machine initialized.');
            startQueue();

        } catch (error) {
            PositiveTS.Service.Logger.error('Error initializing port:' + error);
        }
    }

    async function startQueue() {
        try {
            state.commandSender = setInterval(async () => {
                if (state.queueDisabled) {
                    return;
                }
                if (state.tempCheckCounter > 30) {
                    state.commandQueue.push({
                        command: VendingMachine.Commands.TEMP_CHECK,
                        params: {}
                    });
                    state.tempCheckCounter = 0;
                } else {
                    state.tempCheckCounter++;
                }
                if (state.commandQueue.length == 0) {
                    status();
                    return;
                }
                let command = state.commandQueue.shift();
                switch (command.command) {
                    case VendingMachine.Commands.STATUS:
                        status();
                        break;
                    case VendingMachine.Commands.VEND:
                        vend(command.params as VendingMachine.VendParams);
                        break;
                    case VendingMachine.Commands.RESET:
                        reset();
                        break;
                    case VendingMachine.Commands.TEMP_CHECK:
                        tempCheck(true);
                        break;
                }
            }, state.COMMAND_INTERVAL);
        } catch (error) {
            PositiveTS.Service.Logger.error('Error initializing STATUS:' + error);
        }
    }

    function openQueue() {
        logger("Opening queue...");
        PositiveShared.Utils.sleep(state.COMMAND_INTERVAL);
        state.queueDisabled = false;
    }

    async function closeQueue() {
        logger("Closing queue...");
        state.queueDisabled = true;
    }


    function getMachineAddress() {
        // TODO: Get machine address from settings
        return 0x81;
    }

    async function readResponseOfCommand(expectedResponseLength) {
        logger("Reading response of command...");
        state.responseBuffer = [];
        state.hexadecimalBuffer = [];
        while (state.port.readable) {
            try {
                state.reader = state.port.readable?.getReader();
            } catch (e) {
                break;
            }
            while (true) {
                try {
                    let { value, done } = await state.reader.read();
                    if (value) {
                        state.responseBuffer.push(...value);
                        logger("Response buffer:",
                            Array.prototype.map.call(new Uint8Array(state.responseBuffer),
                                x => ('00' + x.toString(16).toUpperCase()).slice(-2)).join(' ')
                        );

                        // Check if the packet size is at least the expected length
                        if (state.responseBuffer.length >= expectedResponseLength) {
                            let hexadecimal = Array.from(state.responseBuffer, byte => ('00' + byte.toString(16)).slice(-2)).join(' ');
                            state.hexadecimalBuffer.push(hexadecimal);
                            // We have a full packet
                            // Clear response buffer for the next packet
                            let fullPacket = state.hexadecimalBuffer;
                            state.responseBuffer = [];
                            state.hexadecimalBuffer = [];
                            return fullPacket;
                        }
                    }
                    if (done) {
                        logger("Done reading from port");
                        return;
                    }
                } catch (error) {
                    PositiveTS.Service.Logger.error('Error reading from port:' + error);
                    break;
                } finally {
                    if (state.reader) {
                        state.reader.releaseLock();
                        state.reader = null; // Reset reader state
                    }
                }
            }
        }
    }

    async function sendCommandAndReadResponse(command, expectedResponseLength = 1) {
        await sendCommand(command);
        return await readResponseOfCommand(expectedResponseLength);
    }

    function checksum(command, start, count) {
        let total = 0;
        for (let i = start; i < start + count; i++) {
            total += command[i];
        }

        let checksum1 = (total & 0x0F) | 0xF0; // Four least significant bits + four high bits set to 1
        let checksum2 = (total & 0xF0) | 0x0F; // Four most significant bits + four low bits set to 1


        return [checksum1, checksum2];
    }

    function commandBuilder(machineAddress, commandByte: string, param1, param2): Uint8Array {
        let command = new Uint8Array([0x02, 0x30, 0x30, machineAddress, commandByte.charCodeAt(0), param1, param2, 0xFF, 0xFF, 0x03]);

        let [checksum1, checksum2] = checksum(command, 0, 7);
        command[7] = checksum1;
        command[8] = checksum2;

        return command;
    }

    async function sendCommand(command) {
        try {
            const writer = state.port.writable.getWriter();
            await writer.write(command);
            writer.releaseLock();
            const output = Array.prototype.map.call(new Uint8Array(command), x => ('00' + x.toString(16).toUpperCase()).slice(-2)).join(' ');
            logger('Sent command:', output);
        } catch (e) {
            console.error("Error in sendCommand", e)
        }
    }

    function handleResponse(response: string[]) {
        // reponse is a string array of hexadecimals (e.g. ['30', '31']) coming from the machine,
        // usuallly the length of it should be 2 but when the door is opened we get a lot of random gibbrish and the length could be bigger
        if (!response) {
            logger("Got an empty response from the machine, could be due to door being closed or machine boot")
            return;
        }
        let responseCodesArray = response[0].split(" ");
        let responseCode = responseCodesArray[1] || "00";
        // we continue only when we get a real response code
        if (responseCodesArray.length != 2 && responseCode != "81") {
            return;
        }

        handleRealStatusResponse(responseCode);
        state.lastStatusResponse = responseCode;
    }

    function handleRealStatusResponse(responseCode: string) {
        if (!state.successCodes[responseCode]) { // if we get an error code
            if (state.alertKeys[responseCode] && state.errorType != responseCode) {
                logActivityAndLockPosIfNeeded(state.alertKeys[responseCode], responseCode);
            }

            if (state.doorOpenCodes[responseCode] && state.errorType != responseCode) {
                if (state.isDoorOpen) {
                    clearMachineErrors();
                    globalStore.unLockSelfServicePos("vendingDoorOpen")
                    return;
                }
                state.isDoorOpen = true;
                logActivityAndLockPosIfNeeded(state.doorOpenCodes[responseCode], responseCode);
                return;
            }

        } else { // if we get a success code
            logger("Got a success code", responseCode, state.lastStatusResponse, state.errorType)
            if (state.errorType != "99") {
                if (globalStore.isSelfServicePosLoked) { // if the pos is locked and the error is not due to temperature
                    const logReason = state.alertKeys[state.errorType] || state.doorOpenCodes[state.errorType]
                    logger("Unlocking self service pos", state.errorType)
                    globalStore.unLockSelfServicePos(logReason?.key || null)
                }
                clearMachineErrors();
            }
            return;
        }
    }

    async function reset() {
        let machineAddress = getMachineAddress();
        let command = commandBuilder(machineAddress, 'R', 0xFF, 0xFF);
        let response = await sendCommandAndReadResponse(command, 1);
        return response;
    }

    async function vend(params: VendingMachine.VendParams) {
        let machineAddress = getMachineAddress();
        let tray = 0x80 + params.tray + 10 // 10 is the offset for the tray number
        let channel = 0x80 + params.belt;
        let command = commandBuilder(machineAddress, 'V', tray, channel);
        let response = await sendCommandAndReadResponse(command, 1);
        return response;
    }

    async function status(handle = true) {
        let machineAddress = getMachineAddress();
        let command = commandBuilder(machineAddress, 'S', 0xFF, 0xFF);
        let response = await sendCommandAndReadResponse(command, 2); // Here we expect a response of 2 bytes for the status command
        if (handle) {
            handleResponse(response); // Here we handle the response
        }
        return response;
    }

    async function tempCheck(report = false) {
        if(state.isDoorOpen){
            // We cant communicate with the machine when the door is open
            return;
        }
        let response = await sendCommandAndBypassQueue('C', 0x74, 0xFF, 8);
        if (!response) {
            console.error("Got an empty response from the machine:", response)
            return state.lastTempCheckResponse;
        }
        let tempResponse = response[0].split(" ");
        // Convert each hexadecimal value to a character
        let str = tempResponse.map(hex => String.fromCharCode(parseInt(hex, 16))).join('');
        str = str.replace(/[^0-9+\-.C]/g, '');

        // This pattern is designed to capture a number that may be prefixed by a '+' or '-' sign and may include a decimal point
        const tempPattern = /(?:0C)?([+-]?\d+(\.\d+)?)/;
        const matches = str.match(tempPattern);
        let extractedTemp;
        if (matches && matches.length > 1) {
            extractedTemp = parseFloat(matches[1]);
        } else {
            // Default or error value if no valid temperature is found
            extractedTemp = -99;
        }
        state.lastTempCheckResponse = extractedTemp;
        logger("Temp Check was ran with result: ", str, state.lastTempCheckResponse)
        if (report && state.lastTempCheckResponse != -99) {
            let maxTemp = parseFloat(session.pos.suspiciousActivityAlertsDataObj[PositiveTS.Shared.Constants.SuspiciousActions.VENDING_TEMP_ABOVE_X]?.vending_temp_above_x || 99);
            if (state.lastTempCheckResponse > maxTemp && maxTemp != 99) {
                if(state.errorType != "99"){
                    logActivityAndLockPosIfNeeded(state.alertKeys["99"], "99");
                    state.errorType = "99";
                }
            }
        }

        return state.lastTempCheckResponse;
    }

    async function genericCommand(commandByte: string, param1: number, param2: number, expectedResponseLength: number = 1) {
        let machineAddress = getMachineAddress();
        let command = commandBuilder(machineAddress, commandByte, param1, param2);
        let response = await sendCommandAndReadResponse(command, expectedResponseLength);
        return response;
    }

    async function sendCommandAndBypassQueue(commandByte: string, param1: number, param2: number, expectedResponseLength: number = 1) {
        // Close the Queue and wait the interval time to prevent any other commands from being sent
        await closeQueue();
        await PositiveShared.Utils.sleep(state.COMMAND_INTERVAL);
        let machineAddress = getMachineAddress();
        let command = commandBuilder(machineAddress, commandByte, param1, param2);
        let response = await sendCommandAndReadResponse(command, expectedResponseLength);
        openQueue();
        return response;
    }

    function setAlerts() {
        state.alertKeys = {
            "59": { key: "productExpiredDueToTemp", alert: PositiveTS.Shared.Constants.SuspiciousActions.PRODUCT_EXPIRED_DUE_TO_TEMP },
            "5a": { key: "productExpiredDueToTemp", alert: PositiveTS.Shared.Constants.SuspiciousActions.PRODUCT_EXPIRED_DUE_TO_TEMP },
            "61": { key: "productExpiredDueToTemp", alert: PositiveTS.Shared.Constants.SuspiciousActions.PRODUCT_EXPIRED_DUE_TO_TEMP },
            "62": { key: "productExpiredDueToTemp", alert: PositiveTS.Shared.Constants.SuspiciousActions.PRODUCT_EXPIRED_DUE_TO_TEMP },
            "63": { key: "productExpiredDueToTemp", alert: PositiveTS.Shared.Constants.SuspiciousActions.PRODUCT_EXPIRED_DUE_TO_TEMP },
            "35": { key: "vendingStuck", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_STUCK },
            "37": { key: "vendingStuck", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_STUCK },
            "38": { key: "vendingStuck", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_STUCK },
            "42": { key: "vendingStuck", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_STUCK },
            "43": { key: "vendingStuck", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_STUCK },
            "4a": { key: "powerDetectorFaulty", alert: PositiveTS.Shared.Constants.SuspiciousActions.POWER_DETECTOR_FAULTY },
            "4b": { key: "elevatorMinorFault", alert: PositiveTS.Shared.Constants.SuspiciousActions.ELEVATOR_MINOR_FAULT },
            "4c": { key: "elevatorMinorFault", alert: PositiveTS.Shared.Constants.SuspiciousActions.ELEVATOR_MINOR_FAULT },
            "4d": { key: "elevatorMajorFault", alert: PositiveTS.Shared.Constants.SuspiciousActions.ELEVATOR_MAJOR_FAULT },
            "4e": { key: "elevatorMajorFault", alert: PositiveTS.Shared.Constants.SuspiciousActions.ELEVATOR_MAJOR_FAULT },
            "64": { key: "elevatorMajorFault", alert: PositiveTS.Shared.Constants.SuspiciousActions.ELEVATOR_MAJOR_FAULT },
            "46": { key: "tempControllerFaulty", alert: PositiveTS.Shared.Constants.SuspiciousActions.TEMP_CONTROLLER_FAULTY },
            "47": { key: "tempControllerFaulty", alert: PositiveTS.Shared.Constants.SuspiciousActions.TEMP_CONTROLLER_FAULTY },
            "48": { key: "tempControllerFaulty", alert: PositiveTS.Shared.Constants.SuspiciousActions.TEMP_CONTROLLER_FAULTY },
            "49": { key: "tempControllerFaulty", alert: PositiveTS.Shared.Constants.SuspiciousActions.TEMP_CONTROLLER_FAULTY },
            "99": { key: "vendingTempAboveX", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_TEMP_ABOVE_X },
        }

        state.doorOpenCodes = {
            "81": { key: "vendingDoorOpen", alert: PositiveTS.Shared.Constants.SuspiciousActions.VENDING_DOOR_OPEN },
        }
    }

    function logActivityAndLockPosIfNeeded(action: { key: string, alert: number }, responseCode: string) {
        const messageKey = `posAlertLog.${action.key}`
        let activityMessage;
        if (responseCode === "99") { // Custom message for temperature alert
            let maxTemp = parseInt(session.pos.suspiciousActivityAlertsDataObj[PositiveTS.Shared.Constants.SuspiciousActions.VENDING_TEMP_ABOVE_X]?.vending_temp_above_x || 99);
            activityMessage = i18next.t(messageKey,
                { deviceID: session.pos.deviceID, storeName: session.pos.storeName, date: moment().format('DD/MM/YYYY HH:mm'), temp: maxTemp })
        } else {
            activityMessage = i18next.t(messageKey,
                { deviceID: session.pos.deviceID, storeName: session.pos.storeName, date: moment().format('DD/MM/YYYY HH:mm') })
        }

        PositiveTS.Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(
            action.alert, null, null, null, activityMessage);
        if (session.pos.suspiciousActivityAlertsDataObj[action.alert]) {
            let alertData = session.pos.suspiciousActivityAlertsDataObj[action.alert];
            if (alertData.locking_machine) {
                globalStore.lockSelfServicePos(action.key)
            }
        }
        state.isHavingError = true;
        state.errorType = responseCode;
    }


    async function machineItems(): Promise<PositiveTS.Storage.Entity.VendingMachineItem[]> {
        let _sql = `select * FROM VendingMachineItem WHERE posDeviceId = "${session.pos.deviceID}"`;
        let res: PositiveTS.Storage.Entity.VendingMachineItem[] = await PositiveTS.Service.WasmDB.promiseSql(_sql)
        return res;
    }

    async function getCurrentInventoryFromServer() {
        if (globalStore.offlineSaleCount > 0) {
            throw new PositiveTS.ErrorWithData("Can't update inventory when Pos has unsynced sales", { msgForUser: i18next.t('vendingMachine.inventory.cantLoadWhenOfflineSales') });
        }
        let res = await Posimod.Service.FetchReq.jsonReq(`/vending_machines/${railsId.value}/current_inventory`, 'GET');
        if (!res.response.ok) {
            PositiveTS.Service.Filelog.log("VendingMachine.getCurrentInventoryFromServer - got a bad response", JSON.stringify(res), false, 'error');
            throw new PositiveTS.ErrorWithData("Failed to fetch inventory from server", { responseFromServer: res });
        }
        let machineInventory: PositiveTS.Types.VendingMachineItemInventoryResponse = res.result;
        let vendingMachineItems: PositiveTS.Storage.Entity.VendingMachineItem[] = await (new PositiveTS.Storage.Entity.VendingMachineItem()).all()
        let itemCodesToAddToOutOfStock = [];
        let itemCodesToRemoveFromOutOfStock = [];
        let itemInventoryHashedResponse: { [key: string]: PositiveTS.Types.VendingMachineItemInventoryResponseItem } = {};
        itemInventoryHashedResponse = _.keyBy(machineInventory.items, 'item_code');
        await appDB.transaction("rw", appDB.vendingMachineItemInventory, async () => {
            if (vendingMachineItems.length <= 0) { // vendingMachineItems not loaded yet, set with the inventory we got from response
                await appDB.vendingMachineItemInventory.bulkAdd(machineInventory.items.map(i => {
                    let itenInv = new PositiveTS.Storage.Entity.VendingMachineItemInventory();
                    itenInv.itemCode = i.item_code;
                    itenInv.currentInventory = Number(i.current_inventory);
                    itenInv.vendingMachineItemId = Number(i.vending_machine_item_id);
                    itenInv.isOutOfStock = itenInv.currentInventory <= 0;
                    return itenInv;
                }))
            } else {
                for (const item of vendingMachineItems) {
                    let itemInventoryResp = itemInventoryHashedResponse[item.itemCode];
                    let currentInventory: number = posUtils.isPresent(itemInventoryResp) ? Number(itemInventoryResp.current_inventory) : 0;
                    let vendingMachineItemId = posUtils.isPresent(itemInventoryResp) ? Number(itemInventoryResp.vending_machine_item_id) : -1;
                    let itemInventory = await appDB.vendingMachineItemInventory.where('vendingMachineItemId').equals(vendingMachineItemId).first();
                    if (posUtils.isBlankLikeRails(itemInventory)) {
                        itemInventory = new PositiveTS.Storage.Entity.VendingMachineItemInventory();
                        itemInventory.vendingMachineItemId = Number(item.railsId);
                        itemInventory.currentInventory = currentInventory;
                        itemInventory.itemCode = item.itemCode;
                    }
                    itemInventory.currentInventory = currentInventory;
                    itemInventory.isOutOfStock = currentInventory <= 0 ? true : false;
                    await appDB.vendingMachineItemInventory.put(itemInventory);
                    itemInventory.isOutOfStock ? itemCodesToAddToOutOfStock.push(itemInventory.itemCode) : itemCodesToRemoveFromOutOfStock.push(itemInventory.itemCode);
                }
            }

        });
        let currentItemCodes: string[] = globalStore.vendingMachineOutOfStockItemCodes || [];
        currentItemCodes = currentItemCodes.filter(code => itemCodesToRemoveFromOutOfStock.includes(code) == false);
        let args: PositiveTS.Types.VendingMachineOutOfStockItemCodesArgs = { itemCodes: _.union(currentItemCodes, itemCodesToAddToOutOfStock), action: "set" };
        globalStore.setVendingMachineOutOfStockItemCodesFactor(args);
    }

    async function closeSale() {
        let isVending = true;
        let items = await machineItems()
        let matchingItems: PositiveTS.Storage.Entity.VendingMachineItem[] = items.filter(obj2 =>
            posVC.saleItems.some(obj1 => obj1.itemCode === obj2.itemCode)
        );
        let matchingItemsIds = matchingItems.map(i => i.railsId);
        let matchingItemsInvetory = await appDB.vendingMachineItemInventory.where('vendingMachineItemId').anyOf(matchingItemsIds).toArray();
        let itemsOutOfStock = matchingItemsInvetory.filter(inv => inv.isOutOfStock || inv.currentInventory <= 0)
        if (posUtils.isPresent(itemsOutOfStock)) {
            addItemsAsOutOfStock(itemsOutOfStock.map(itemInv => itemInv.itemCode))
            app.showErrorAlertDialog(i18next.t('vendingMachine.inventory.onCloseSaleOutOfStockError'));
            return false;
        }

        if (matchingItems.length > 0) {
            app.showLoadingMessage(i18next.t("vendingMachine.closingSale"));
            for (const item of matchingItems) {
                state.commandQueue.push({
                    command: VendingMachine.Commands.VEND,
                    params: { tray: item.tray, belt: item.belt }
                });
                isVending = true;
            }
        } else {
            isVending = false;
        }

        while (state.lastStatusResponse != "4f" && isVending) {
            if (state.errorCodes[state.lastStatusResponse] && state.lastStatusResponse.length > 0) {
                // If we have an error code and the response is not empty
                app.hideLoadingMessage();

                // 17-12-2023 ועד הודעה חדשה
                // The Vending machine can only pull 1 items per sale.
                // Thus, if we get the last response as 34, then it means the item is out of stock
                if (state.lastStatusResponse === "34") {
                    let itemInv = matchingItemsInvetory[0];
                    itemInv.isOutOfStock = true;
                    await appDB.vendingMachineItemInventory.put(itemInv);
                    let args: PositiveTS.Types.VendingMachineOutOfStockItemCodesArgs = { itemCodes: [itemInv.itemCode], action: "add" };
                    globalStore.setVendingMachineOutOfStockItemCodesFactor(args);
                }

                return false; // We cancel the sale on error
            }
            await PositiveShared.Utils.sleep(1000);
        }

        app.hideLoadingMessage();
        let dialogOpened = false;
        if (state.lastStatusResponse == "4f") {
            while (state.lastStatusResponse == "4f") {
                if (!dialogOpened) {
                    app.showAlertDialog({
                        header: i18next.t('vendingMachine.success'),
                        content: i18next.t("vendingMachine.codes." + state.lastStatusResponse) || state.statusMapper[state.lastStatusResponse],
                        hideCancelButton: true,
                        hideContinueButton: true,
                    })
                    dialogOpened = true;
                }
                await PositiveShared.Utils.sleep(1000);
            }
        }
        if (dialogOpened) {
            PositiveTS.VueInstance.$refs.alertDialog.close();
        }
        // Every item passed successfully, so update inventory
        for (const inv of matchingItemsInvetory) {
            await decrementItemInventory(inv);
        }

        return true;
    }

    async function decrementItemInventory(inventory: PositiveTS.Storage.Entity.VendingMachineItemInventory) {
        inventory.currentInventory = inventory.currentInventory - 1;
        if (inventory.currentInventory < 0) {
            let msg = `item code ${inventory.itemCode} got got minus inventory ${inventory.currentInventory}`
            PositiveTS.Service.Filelog.log('VendingMachineInventory', msg, false, 'warn')
        }
        inventory.isOutOfStock = inventory.currentInventory <= 0;
        await appDB.vendingMachineItemInventory.put(inventory);
        if (inventory.isOutOfStock) {
            let args: PositiveTS.Types.VendingMachineOutOfStockItemCodesArgs = { itemCodes: [inventory.itemCode], action: "add" };
            globalStore.setVendingMachineOutOfStockItemCodesFactor(args);
        }
    }

    function addItemsAsOutOfStock(itemCodes: string[]) {
        let args: PositiveTS.Types.VendingMachineOutOfStockItemCodesArgs = { action: 'add', itemCodes: itemCodes };
        globalStore.setVendingMachineOutOfStockItemCodesFactor(args);
    }

    function clearMachineErrors() {
        state.isDoorOpen = false;
        state.isHavingError = false;
        state.errorType = "";
    }

    return {
        state,
        statusName,
        requestPort,
        getCurrentInventoryFromServer,
        logger,
        toggleLogs,
        init,
        openPort,
        startQueue,
        sendCommandAndReadResponse,
        checksum,
        commandBuilder,
        sendCommand,
        handleResponse,
        logActivityAndLockPosIfNeeded,
        handleRealStatusResponse,
        clearMachineErrors,
        reset,
        vend,
        status,
        tempCheck,
        genericCommand,
        machineItems,
        closeSale,
        decrementItemInventory,
        addItemsAsOutOfStock,
        closeQueue,
        sendCommandAndBypassQueue,
    }
});


export default vendingMachineStore;

export namespace VendingMachine {
    export enum Commands {
        STATUS = 'status',
        VEND = 'vend',
        RESET = 'reset',
        TEMP_CHECK = 'temp_check'
    }
    export interface VendingMachineCommand {
        command: Commands;
        params: Object;
    }
    export interface VendParams {
        tray: number;
        belt: number;
    }
}
