module PositiveTS {
    export module Components {
        export module selfService {
            export module LangSelect {
                export function create() {
                    let component = {
                        template: JST.LangSelect(),
                        data() {
                            return {
                                displayLangSelector: false,
                            };
                        },
                        props: {
                            fontColor: {
                                required: false,
                                default: jsonConfig.getVal(jsonConfig.KEYS.selfServiceColorPickerForHeadlines)
                            }
                        },
                        setup(){
                            const languageManagerStore = Pinia.useLanguageManagerStore();
                            const {currentLang,currentDisplayedLanguageName,langsEnabled,multiLangEnabled} = Pinia.storeToRefs(languageManagerStore);

                            const {changeLanguage} = languageManagerStore
                            return {currentLang,currentDisplayedLanguageName,langsEnabled,multiLangEnabled,changeLanguage}
                        },
                        computed: {
                            languageOptions() {
                                let allLangs = [
                                    { key: 'en', label: 'English' },
                                    { key: 'he', label: 'עברית' },
                                    { key: 'ar', label: 'عربيه' },
                                    { key: 'ru', label: 'Русский' },
                                ];
                                let langCodes: string[] = this.langsEnabled;
                                return allLangs.filter(lang => langCodes.includes(lang.key));
                            },
                            useSelfServiceColorPickerForHeadlines() {
                                return this.fontColor == jsonConfig.getVal(jsonConfig.KEYS.selfServiceColorPickerForHeadlines);
                            },
                            styleColor() {
                                let cssStyle = {};
                                if (this.useSelfServiceColorPickerForHeadlines) {
                                    cssStyle['color'] = this.fontColor;
                                }
                                return cssStyle;
                            },
                            switchToLanguageName(){
                                return this.currentLang == 'en' ? 'עברית' : 'English';
                            }
                            
                        },
                        methods: {
                            changeLang(code) {
                                this.changeLanguage(code);
                                this.toggleLanguageSelection(false);
                            },
                            isLangEnabled(code: string): boolean {
                                return this.currentLang == code;
                            },
                            toggleLanguageSelection(val = null) {
                                if(val === null) {
                                    this.displayLangSelector = !this.displayLangSelector;
                                } else {
                                    this.displayLangSelector = val;
                                }
                                
                            },
                        },
                    }
                    VueApp.component('lang-select', component)
                }
            }
        }
    }
}

