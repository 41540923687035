module PositiveTS {
    export module Components {
      export module Mixins {
        export let oneClickMixin = {
            data() {
                return {
                    functionRunning: false
                }
            },
            methods: {
                async oneTimeRun(functionToRun, ...args) {
                    if(this.functionRunning) {
                        console.log(functionToRun);
                        Service.Logger.error("prevented double click");
                        return ;
                    }
                    this.functionRunning = true;
                    try {
                        console.log(`oneClick Running on function ${functionToRun.name}`)
                        console.log(functionToRun);
                        await functionToRun(...args);
                    }
                    catch(err) {
                        console.log(`oneClick Failed on function ${functionToRun.name}`)
                        if (!posUtils.isNullOrUndefined(err)) {
                            console.error(err);
                        }
                    }
                    finally {
                        console.log(`oneClick Finished function ${functionToRun.name}`)
                        this.functionRunning = false;   
                    }
                }
          },
        }
}}}
  
