module PositiveTS {
export module Components {
export module WithdrawalReportDialog {

  const _vueComponentName = 'withdrawal-report-dialog';

  export function create() {
    let withdrawalReportDialog = {
        template: JST.withdrawalReportDialog(),
        methods: {
          open(){
             this.$el.showModal();
          },
          close() {
            this.detailedReport = false
            this.$el.close();
          },
          async print() {
            try {
              app.showLoadingMessageDialog(i18next.t("withdrawal.gettingReport"));
              if(localStorage.getItem("detailedReports") === "true") {
                this.detailedReport  = true;
              }
              let response = await PositiveTS.Service.FetchReq.jsonReq('/sales/withdrawals_summary', 'post', {startDate: this.startDate, endDate: this.endDate, detailedReport: this.detailedReport});
              Printing.Reports.printWithdrawalReport(response.result, moment(this.startDate), moment(this.endDate));
              app.hideLoadingMessageDialog()
            } catch(err) {
              console.error(err);
              app.hideLoadingMessageDialog()
              this.close();
              await app.promiseShowAlert({
                header: i18next.t("error"),
                content: i18next.t("withdrawal.reportError"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            }
            
          },
        },
        data() {
          return {
            dateFormat: "dd/MM/yyyy",
            keyboardImg: `${(<any>window).images_path}/keyboard.jpg`,
            startDate: moment(new Date()).startOf("month").toDate(),
            endDate : moment(new Date()).endOf("month").toDate(),
            detailedReport: false,
            isRoshemet: session.pos.isRoshemet
          }
        }
    }

    VueApp.component(_vueComponentName,withdrawalReportDialog)
  }
}}}
