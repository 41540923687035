module PositiveTS {
  export module Components {
    export module FlightsDisconnectDialog {

      const _dialogSelector = "#positive-flights-disconnect-dialog";
      const _vueComponentName = 'flights-disconnect-dialog';

      export function create() {
        let flightsDisconnectDialog = {
          template: JST.flightsDisconnectDialog(),
          methods: {
            async open() {
              app.showLoadingMessage(i18next.t("flightsDisconnectDialog.checkingData"));

              await this.checkDisconnectAvailable();

              app.hideLoadingMessage();

              this.$nextTick(() => {
                this.$el.show();
              })
            },
            close() {
              this.$el.close();
            },
            async checkDisconnectAvailable() {
              this.posHaveSales = (await appDB.sales.count()) > 1;

              let currentSale = await appDB.sales.where('invoiceSequence').equals(-1).first();

              if (currentSale && currentSale.items && currentSale.items.length) {
                this.posHaveSales = true;
              }
            },
            async disconnectFlightClick() {
              app.showLoadingMessage(i18next.t("flightsDisconnectDialog.disconnecting"));

              let request;

              try {
                request = await PositiveTS.Service.FetchReq.jsonReq('/flights/detach_pos', 'post', { flight_id: Pinia.flightsStore.flight.id, pos_device_id: session.pos.deviceID })
              } catch (err) {
                app.hideLoadingMessage();
                await this.showLoginError(i18next.t('generalError'));
                console.error(err);
                return;
              }

              app.hideLoadingMessage();

              if (!(request.response && request.response.ok && request.result)) {
                await this.showError(request.result && request.result.error ? request.result.error : i18next.t('generalError'));
                return;
              }

              await this.disconnectFlight();
              this.close();
            },
            showError(errorText) {
              return app.promiseShowAlert({
                header: i18next.t('error'),
                content: errorText,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {disconnectFlight} = flightsStore
            return {disconnectFlight}
          },
          data() {
            return {
              posHaveSales: false,
            };
          },
          computed: {
            stateIsOnline(){
              return Pinia.globalStore.isOnline
            },
            canDisconnect() {
              return (!this.posHaveSales) && this.stateIsOnline;
            }
          },
        }
        VueApp.component(_vueComponentName, flightsDisconnectDialog)
      }
    }
  }
}