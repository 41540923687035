module PositiveTS {
export module Storage {
export module Entity {
export class KeyValueStore extends IDBEntity {

	key: string;
	value: any;

	constructor(key: string, value: any = null) {
		let meta = {
			name: 'KeyValueStore',
			fields: {
				key: "TEXT",
				value: "any",
			},
		}
		super(meta)
		this.key = key;
		this.value = value;
	}
}}}}
