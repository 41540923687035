
module PositiveTS {
   export module Service {
      export abstract class BaseOrderConvertService {
         abstract getOrderNumber(order);
         abstract getOrderTotal(order);
         abstract getSalePayments(order, sale);
         abstract getOrderJsonData(order, sale);
         abstract getOrderSaleItems(order, sale);
         abstract getCustomerNumber();

         getSaleInvoiceType(sale) {
            return Storage.Entity.Sequence.TYPE_SHIPMENT_INV;
         }

         async convertOrderToSale(order) {
            try {
               let orderNumber = this.getOrderNumber(order);

               let sale = await Sale.getNewOrder(orderNumber, this.getOrderTotal(order));

               sale.payments = this.getSalePayments(sale, order);

               sale.invoiceType = this.getSaleInvoiceType(sale);

               let jsondata = await this.getOrderJsonData(order, sale);

               sale.deliveryStatus = jsondata.delivery.status;
               sale.orderTime = String(jsondata.delivery.orderTime)

               sale.jsondata = JSON.stringify(jsondata);

               sale.items = this.getOrderSaleItems(order, sale);

               let itemsWihtoutTip = sale.items;

               if (Service.Tip.isHasTips()) {
                  itemsWihtoutTip = itemsWihtoutTip.filter(i => i.itemCode != session.pos.tipItemCode)
               }

               sale.totalQuantity = _.sumBy(itemsWihtoutTip, 'quantity');

               let sequence = await Storage.Entity.Sequence.getSequenceForInvType(sale.invoiceType);
               sequence.sequence++;

               sale.invoiceSequence = sequence.sequence;
               await appDB.sequences.put(sequence);

               return sale;
            } catch (e) {
               PositiveTS.Service.Delivery.failedDeliveriesLogger(e, order.order_number, 'base-order-converter', Delivery.FailedDeliveryType.InvalidData);
               PositiveTS.Service.Logger.error('Error in Converting order from base order convert service');
               PositiveTS.Service.Logger.error(e);
               PositiveTS.Service.Logger.error(order);
            }
         }
      }
   }
}