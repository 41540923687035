module PositiveTS {
export module Promotions {
export module Templates {
export class BuyXGetVariedItemDiscount extends TemplateAbstract {
  constructor(initParameters) {
    super(initParameters)
  }

	calculatePromotions() {
			// Go over the promotions to decide what to do next
			for (var promotionCode in this.parameters.promotionsBuy) {
					// Check that the promotion is in the promotion by code object
					if (!(promotionCode in this.parameters.promotionsByCode)) {
							continue;
					}

					// Get the promotion
					var promotion = this.parameters.promotionsByCode[promotionCode];

					// Check that this promotion is from template 1
					if (promotion.template !== '1') {
							// -- Not from template 1, skip!
							continue;
					}

					// -- This promotion is from template 1
					this.applyPromotion(promotion);
			}
	};

	applyPromotion(promotion) {
		// Extract the discount precents to an array
		var discountPrecents = this.extractDiscountPrecentsFromPromotion(promotion);

		// -----------------------------------------------
		// | Minimum quantity and amount check
		// -----------------------------------------------
		if (!this.promotionHasMinimumQuantityAndAmount(promotion, this.parameters.promotionsBuy[promotion.code])) {
				return;
		}

		// -----------------------------------------------
		// | Flatten sale items and sort them by unitPrice
		// -----------------------------------------------
		var flattenedSaleItems = this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code]);
		flattenedSaleItems.sort(this.sortByUnitPriceFromExpensiveToCheapest);

		// -----------------------------------------------
		// | Apply promotion for sale items
		// -----------------------------------------------
		// Initialize an object to save the discount per sale item
		var discountAmountForSaleItem = {};
		var promoGroups = {};
		var itemToPromote;
		var promoGroupCreated = false;
		var promoGroupId;

		// Apply the promotion for each unit
		var discountPrecentIndex = 0;
		var saleItemsGotCurrentDiscountPrecent = 0;
		for (var i = 0; i < flattenedSaleItems.length; i++) {

			if ((i%discountPrecents.length) === 0) {
				itemToPromote = flattenedSaleItems[i];
				if (!(itemToPromote.id in discountAmountForSaleItem)) {
					discountAmountForSaleItem[itemToPromote.id] = 0;
				}
				if (!promoGroupCreated) {
					if (!promoGroups[itemToPromote.id]) {
						promoGroups[itemToPromote.id] = {
							itemsCounter: {},
							promotion: promotion,
							discountAmountForGroup: 0,
							totalPriceForItemsBeforeDiscount: 0
						}
					}
					promoGroupId = itemToPromote.id;
					promoGroupCreated = true;
				}
			}

			var saleItem = flattenedSaleItems[i];
			var discountValueAmount = saleItem.unitPrice * discountPrecents[discountPrecentIndex] / 100;


			discountAmountForSaleItem[promoGroupId] += Number(discountValueAmount);
			promoGroups[promoGroupId].itemsCounter = this.addToItemsCounter(saleItem,promoGroups[promoGroupId].itemsCounter);
			promoGroups[promoGroupId].totalPriceForItemsBeforeDiscount += saleItem.unitPrice;

			saleItemsGotCurrentDiscountPrecent = saleItemsGotCurrentDiscountPrecent + 1;

			// Change discount precent per 'promotion.minimumBuyQuantity' items
			if (saleItemsGotCurrentDiscountPrecent >= promotion.minimumBuyQuantity) {
					// Advance the discount precent index by 1... Ensure it doesn't overflow!
					discountPrecentIndex = (discountPrecentIndex + 1) % discountPrecents.length;
			}
		}

		// Go over the discount amount per sale item and apply it

		for (var saleItemID in discountAmountForSaleItem) {
			if (discountAmountForSaleItem[saleItemID] > 0) {
				var key = this._generateUniqueKey({id: saleItemID}, promotion.code, 'buy');

				// Update data...
				var saleItemTotalAmount = this.parameters.saleItemsPotentialPromotions[key].unitPrice * this.parameters.saleItemsPotentialPromotions[key].quantity;
				this.parameters.saleItemsPotentialPromotions[key].discountAbsoluteValue = session.fixedNumber(discountAmountForSaleItem[saleItemID]);
				this.parameters.saleItemsPotentialPromotions[key].discountPrecentValue = session.fixedNumber(discountAmountForSaleItem[saleItemID] / saleItemTotalAmount * 100);
				this.parameters.saleItemsPotentialPromotions[key].discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT;
				this.parameters.saleItemsPotentialPromotions[key].isPromotionGiven = true;

				promoGroups[saleItemID].discountAmountForGroup = parseFloat(discountAmountForSaleItem[saleItemID]);
				promoGroups[saleItemID].item = this.parameters.saleItemsPotentialPromotions[key];
			}
		}

		for (let key in promoGroups) {
			if (promoGroups[key].discountAmountForGroup > 0) {
				this.parameters.promoGroups.push(promoGroups[key]);
			}
		}
	};

	extractDiscountPrecentsFromPromotion(promotion) {
			var discountPercents = [];
			var lastZeroIndex = 3;
			for (var i = 1; i <= 4; i++) {
					var number = parseInt(promotion['discountPercent' + i], 10);
					if (!isNaN(number)) {
							discountPercents[discountPercents.length] = number;
					}
			}

			if (discountPercents.length === 0) {
					discountPercents[discountPercents.length] = parseInt(promotion.discountValue, 10);
			}

			for (var i=discountPercents.length-1; i>0 ; i--) {
					if (discountPercents[i] > 0) {
							lastZeroIndex = i+1;
							break;
					}
					else {
							lastZeroIndex = i;
					}
			}
			discountPercents.splice(lastZeroIndex,discountPercents.length-lastZeroIndex);

			return discountPercents;
	};
}
}}}
