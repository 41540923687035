module PositiveTS {
export module Storage {
export module Entity {
export class VoucherStore extends WasmEntity {

	constructor() {
		let meta = {
			name: 'VoucherStore',
			fields: {
				voucherTypeID: "TEXT",
			  	storeID: "TEXT"
			}
		}
		super(meta);
	}
}}}}
