module PositiveTS {
  export module Components {
    export module PositiveDeliveryCustomerClub {

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }


      function convertToPosStructure(customer) {
        let uglyCustObj = {}

        uglyCustObj["s_id_number"] = customer.customer_tz
        uglyCustObj["s_member_no"] = customer.customer_tz
        uglyCustObj["customer_group_id"] = customer.customer_group_id
        uglyCustObj["customer_group_name"] = customer.customer_group_name
        uglyCustObj["s_first_name"] = customer.first_name
        uglyCustObj["s_last_name"] = customer.last_name
        uglyCustObj["email"] = customer.email
        uglyCustObj["msg_for_screen"] = "" //TODO
        uglyCustObj["msg_for_slip"] = "" //TODO
        uglyCustObj["cust_points"] = customer.current_points
        uglyCustObj["is_valid"] = (moment(new Date(customer.valid_until)).diff(moment(new Date()),'days') >= 0)
        uglyCustObj["secondary_phone"] = customer.secondary_phone
        uglyCustObj["remarks"] = customer.remarks
        uglyCustObj["is_delivery_customer_only"] = customer.is_delivery_customer_only
        if(customer.hakafa_customer){
          customer.enable_hakafa_payment = true;
          uglyCustObj["enable_hakafa_payment"] = true;
          uglyCustObj["is_tamash_customer"] = customer.hakafa_customer.is_tamash_customer;
        }
        else{
          uglyCustObj["enable_hakafa_payment"] = customer.enable_hakafa_payment
          uglyCustObj["is_tamash_customer"] = true;
        }
        uglyCustObj["customer_addresses"] = customer.customer_addresses
        uglyCustObj["compensation"] = customer.compensation
        uglyCustObj["compensation_reason"] = customer.compensation_reason
        

        let pointsForUse = customer.current_points < jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse) ? 0 : customer.current_points //TODO - parameter at club level
        let multiplyPointsVal = jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication)
        if (pointsForUse > 0 && multiplyPointsVal != null && multiplyPointsVal > 0) {
          pointsForUse = pointsForUse - pointsForUse % multiplyPointsVal;
        }
        uglyCustObj["cust_points_for_use"] = pointsForUse
        uglyCustObj["i_point_value"] = 1 //TODO - parameter at club level
        uglyCustObj["i_club_id"] = customer.customer_club_id
        uglyCustObj["dt_birth_date"] = customer.date_of_birth
        uglyCustObj["address"] = `${customer.street_address || ""} ${customer.apartment_number || ""} ${customer.city || ""}`
        uglyCustObj["s_phone_number_1"] = customer.phone_number
        uglyCustObj["db_id"] = customer.id
        uglyCustObj["zip_code"] = customer.zip_code
        uglyCustObj["val_per_points"] = uglyCustObj["i_point_value"] * uglyCustObj["cust_points_for_use"]
        uglyCustObj["clubName"] = 'positive'
        uglyCustObj["discount_percent"] = customer.discount_percent

        //TODO: get promotion types from server. maybe use a serializer?
        uglyCustObj["promotion_types"] = customer.promotion_types
        //uglyCustObj["promotion_types"] = CustomerClub.get_promotion_types(customer)

        return uglyCustObj;
      }

      function convertFromPosStructure(uglyCustObj) {
        this.customer = {}
        this.customer.customer_tz = uglyCustObj["s_id_number"]
        this.customer.customer_number = uglyCustObj["s_member_no"]
        this.customer.customer_group_id = uglyCustObj["customer_group_id"]
        this.customer.customer_group_name = uglyCustObj["customer_group_name"]
        this.customer.discount_percent = uglyCustObj["discount_percent"]
        this.customer.street_address = uglyCustObj["address"]
        this.customer.first_name = uglyCustObj["s_first_name"]
        this.customer.last_name = uglyCustObj["s_last_name"]
        this.customer.id = uglyCustObj["db_id"]
        this.customer.customer_addresses = uglyCustObj["customer_addresses"]
        this.customer.email = uglyCustObj["email"]
        // uglyCustObj["msg_for_screen"] = "" //TODO
        // uglyCustObj["msg_for_slip"] = "" //TODO
        this.customer.current_points = uglyCustObj["cust_points"]
        this.customer.secondary_phone = uglyCustObj["secondary_phone"]
        this.customer.remarks = uglyCustObj["remarks"]
        this.customer.is_delivery_customer_only = uglyCustObj["is_delivery_customer_only"]
        this.customer.enable_hakafa_payment = uglyCustObj["enable_hakafa_payment"] 
        this.customer.is_tamash_customer = this.customer.enable_hakafa_payment 
        this.customer.compensation = uglyCustObj["compensation"]
        this.customer.compensation_reason = uglyCustObj["compensation_reason"] 

        this.customer.customer_club_id = uglyCustObj["i_club_id"]
        this.customer.date_of_birth = uglyCustObj["dt_birth_date"]
        this.customer.phone_number = uglyCustObj["s_phone_number_1"]
        this.customer.zip_code = uglyCustObj["zip_code"]

      }

      function fixAddressesIfNeeded(address){
        if(address){
          if(!address.city.name){
            address.city = {
              name: address.city,
              value: address.city_id,
              cityID: address.city_id
            }

            if(!address.address.name){
              address.address = {
                name: address.address,
                value: address.street_id,
                cityID: address.city_id
              }
            }
          }
        }
      }

      async function assignCustomerIfWeGotHereFromOfflineSearch(customer){
        if(!this.serverSearch && customer.id){
          if (!PositiveTS.Reachability.isOnline){
            this.showError(i18next.t("positiveCustClub.errorCannotSelectCustomerForDeliveryOnOffline"))
            return
          }
          app.showLoadingMessage("מאתר פרטי לקוח")
          let serverCustomer = await Service.FetchReq.jsonReq(`/delivery_customer_club/${customer.id}`,"get")
          customer = serverCustomer.result

          if(this.cities.length == 0){
            this.cities = await Service.Addresses.getCities(); 
           }
  
            if(this.streets.length == 0){
              this.streets = await  Service.Addresses.getStreets(); 
            }
            //avoid fetching customer once again on second customer assignment
            this.serverSearch = true

          app.hideLoadingMessage();
        }

        return customer;
      }

      async function selectCustomer(customer) {
        try{
          customer = await this.assignCustomerIfWeGotHereFromOfflineSearch(customer);
          this.customer = customer;

          let selectedAddress = customer.customer_addresses.filter(address => {return address.is_selected})[0];
          let defaultAddress = customer.customer_addresses.filter(address => {return address.is_default})[0];

          fixAddressesIfNeeded(selectedAddress);
          fixAddressesIfNeeded(defaultAddress); 
          
          
          Service.Delivery.setDeliveryCustomerAddress(selectedAddress || defaultAddress);
          this.shortCustomerAddress = Service.Delivery.getShortDeleveryCustomerAddress(posVC.sale.jsondata);
          
          let result = (new PositiveTS.Storage.Entity.CustomerGroup()).allWithoutPromise()
          if (customer.customer_group_id) {
            customer.customer_group_name = result.filter(row => { return row.code == customer.customer_group_id })[0].name;
          }
          let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
          await customerClubService.setCurrentSelectedCustomer(convertToPosStructure.call(this, customer))
          await posVC.loadSale()

          if((this.customer.enable_hakafa_payment) || (this.customer.hakafa_customer)){
            let jd = JSON.parse(posVC.sale.jsondata);
            jd.useNewCustomerClubMethod = true;
            posVC.sale.jsondata = JSON.stringify(jd);

            Pinia.globalStore.setHakafaDisabled(false);
          }
          this.customerSelected = true;
          this.customers = []
          this.showSearchResults = false;
          this.searchInput = "";
          this.$nextTick(()=> {
            $(document).unbind('keypress');
          });
          
          if(customer.customer_addresses.length == 0 && this.locationFieldsAreRequired) {
            return;
          }
          if (this.isCustomerClubMode) {
            this.close(false, true)
            try {
              await Pinia.componentsStore.openComponent( { componentName: "positiveDeliveryOrderDetail", args: [this.isCustomerClubMode, this.customer, this.notForDelivery] })
              this.resolveFunc(this.customer)
            } catch (error) {
              console.error(error)
              this.rejectFunc({})
            }
          } else {
            this.close(false, this.isCustomerClubMode);
            await Pinia.componentsStore.openComponent( { componentName: "positiveDeliveryOrderDetail", args: [this.isCustomerClubMode, this.customer, this.notForDelivery] });
          }
        }catch(error){
          console.error(error)
        }
      }

      function _initjQueryDialogIfNeeded() {

        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $("#positive-delivery-customer-club-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '100%',
              height: 680,
              position: 'top',
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          customerSelected: false,
          showSearchResults: false,
          searchCustomerMode: true,
          noCustomersFound: false,
          addNewCustomerMode: false,
          dontCancelOrder: false,
          notForDelivery: false,
          lastSearchID: null,
          serverSearch: false,
          shortCustomerAddress: "",
          customer: {},
          customerAddresses: getNewAddresses(),
          customers: [],
          cities:[],
          streets: [],
          filterdStreets:[],
          isCustomerClubMode: false,
          createCustomerOnline: false,
        }
      }

      async function close(cancel?, justClose = false) {
        if(cancel){
          if((this.dontCancelOrder == false) || (!this.dontCancelOrder)){
            await posVC.restartSale();
            this.rejectFunc({})
          }
        }
        $("#positive-delivery-customer-club-dialog").dialog('close');
        if (!justClose){
          this.resolveFunc({})
        }
      }

      function convertToAddressesObjects(addresses){
        let newAddresses = [];

        let selectedAddress = addresses.filter(row => { return row.is_selected })[0];

        if(!selectedAddress){
          let defaultAddress = addresses.filter(row => { return row.is_default })[0];
          if(defaultAddress){
            defaultAddress.is_selected = true;
          }
          else{
            addresses[0].is_selected = true;
          }
        }

        for(let address of addresses){
          let addressToPush = Object.assign({},address);
          if(address.city_id){
            addressToPush.city = {
              name: address.city.name || address.city,
              value: address.city_id,
              cityID: address.city_id
            }
          }

          if(address.street_id){
            addressToPush.address = {
              name: address.address.name || address.address,
              value: address.street_id,
              cityID: address.city_id
            }
          }

          newAddresses.push(addressToPush);
        }


        return newAddresses;
      }

      function open(dontCancelOrder= false, isCustomerClubMode = false, serverSearch = false, createCustomerOnline = false, keepPreviousPromise = false, notForDelivery = false) {
        return new Promise(async (resolve, reject) => {
          if (!keepPreviousPromise){
            this.resolveFunc = resolve
            this.rejectFunc = reject
          }
          
          this.dontCancelOrder = dontCancelOrder;
          this.customerAddresses = this.getNewAddresses()

          

          let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
          let customer = customerClubService.getCurrentSelectedCustomer()
          if (customer) {
            convertFromPosStructure.call(this, customer)

            if((customer.customer_addresses) && (customer.customer_addresses.length > 0)){
              this.customerAddresses = convertToAddressesObjects(customer.customer_addresses);
            }

            this.customerSelected = true;
          }
          else {
            this.customerSelected = false;
            this.cleanData();
          }
          this.notForDelivery = notForDelivery;
          this.isCustomerClubMode = isCustomerClubMode
          if (typeof serverSearch == 'boolean'){
            this.serverSearch = serverSearch
          }
          this.createCustomerOnline = createCustomerOnline

          app.showLoadingMessage(i18next.t('delivery.loadingStreets'));
          

          this._initjQueryDialogIfNeeded()


          $(document).unbind('keypress');

          $("#positive-delivery-customer-club-dialog").dialog('open');

          if (posUtils.isBlank(this.cities)) {
            await this.loadAddresses();
          }
          
          PositiveTS.VueInstance.$nextTick(()=> {
            app.hideLoadingMessage();
            if (this.$refs.searchInputText) {
              this.$refs.searchInputText.focus();        
            }

          })

        })
      }


      function filterStreets(selectedAddress, forceFilter = false){
        if(selectedAddress.city){
          let foundStreets = this.streets.filter(street => street.cityID == selectedAddress.city.cityID);          

          //Hack so vue won't go to infinte loop
          if(selectedAddress.city != selectedAddress.prevSelectedCity || forceFilter){
            if(selectedAddress.prevSelectedCity){
              selectedAddress.address = null;
            }
            selectedAddress.filterdStreets = foundStreets;
            selectedAddress.prevSelectedCity = selectedAddress.city;
          }
        }
      }

      function validateAllAddresses():Promise<Array<any>>{
        return new Promise(async (resolve) =>{
          let errorsPromises = [];
          if (!this.locationFieldsAreRequired) {
            resolve(errorsPromises);
            return;
          }
          let blankAddress = 0;
          for(let address of this.customerAddresses){
            if((address.city) && (address.address)){
              let errors = await validateAddress(address);
              if(errors.length > 0){
                errorsPromises.push(errors);
              }
            }
            else{
              blankAddress++;
            }    
          }

          if(blankAddress == this.customerAddresses.length){
            errorsPromises.push({ field: "customerAddress.address", error: "חובה להזין לפחות כתובת אחת" })
          }
          resolve(errorsPromises);
        });
        
      }


      function validateAddress(address):Promise<Array<any>>{
        return new Promise((resolve, reject) => {
          let errors = [];
          if (!this.locationFieldsAreRequired) {
            resolve(errors);
            return;
          }

          if (!address.city) {
            errors.push({ field: "customerAddress.city", error: "חובה להזין עיר" })
          }
          if (!address.address) {
            errors.push({ field: "customerAddress.address", error: "חובה להזין רחוב" })
          }
          if (!address.house_number || address.house_number.trim() === "") {
            errors.push({ field: "customerAddress.house_number", error: "חובה להזין מספר בית" })
          }

          resolve(errors);
        })
      }


      async function addNewAddress(){  

        let errors = await validateAddress(this.customerAddresses[this.customerAddresses.length-1]);

        if(errors.length > 0){

          let errMsg = "";
          for(let error of errors){
            errMsg+=error.error + "<br>";
          }

          app.showAlert({
            header:  i18next.t('error'),
            content: errMsg,
            continueButtonText: i18next.t('ok'),
            hideCancelButton: true,
          })
        }
        else{
          let isDefault = false;
          let isSelected = false;
  
          if(this.customerAddresses.length == 0){
            isDefault = true;
            isSelected = true;
          }
  
          this.customerAddresses.push({is_default: isDefault,is_saved: false,is_selected:isSelected});
          let height = $(".addresses-cards").get(0).scrollHeight;
          $(".addresses-cards").animate({scrollTop: height});
        }
      }

      async function selectDeliveryAddress(choosenAddress){
        
        let errors = await validateAddress(choosenAddress);

        if(errors.length > 0){

          let errMsg = "";
          for(let error of errors){
            errMsg+=error.error + "<br/>";
          }

          app.showAlert({
            header:  i18next.t('error'),
            content: errMsg,
            continueButtonText: i18next.t('ok'),
            hideCancelButton: true,
          })
        }
        else{
          for(let address of this.customerAddresses){
            address["is_selected"] = false
          }
  
          Service.Delivery.setDeliveryCustomerAddress(choosenAddress);
          this.shortCustomerAddress = Service.Delivery.getShortDeleveryCustomerAddress(posVC.sale.jsondata);
          choosenAddress["is_selected"] = true
        }
      }

      function removeAddress(choosenAddress){
        if((choosenAddress.is_default == false) && (choosenAddress.is_selected == false)){
          var index = this.customerAddresses.findIndex(x=> x == choosenAddress);
          this.customerAddresses.splice(index,1);
        }
        else
        {
          app.showAlert({
            header:  i18next.t('error'),
            content: choosenAddress.is_default? i18next.t('delivery.cantDeleteDefaultAddress') : i18next.t('delivery.cantDeleteSelectedAddress'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          })
        }
      }

      function saveCurrentAddress(choosenAddress){
        
      }
 

      function saveAddress(choosenAddress): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
          let errors = [];
          

          if (!choosenAddress.city) {
            errors.push({ field: "customerAddress.city", error: "חובה להזין עיר" })
          }
          if (!choosenAddress.address) {
            errors.push({ field: "customerAddress.address", error: "חובה להזין רחוב" })
          }
          if (!choosenAddress.house_number || choosenAddress.house_number.trim() === "") {
            errors.push({ field: "customerAddress.house_number", error: "חובה להזין מספר בית" })
          }


          if(errors.length === 0)
          {
            choosenAddress.is_saved = true;
          }

          resolve(errors);
        })
      }

      function setDefault(choosenAddress)
      {
        for(let address of this.customerAddresses){
          address["is_default"] = false
        }
        choosenAddress["is_default"] = true
      }

      function searchCustomerKeyPressed(){
        this.lastSearchID = new Date().getTime()

        if(this.serverSearch == false){
          this.searchCustomer(this.lastSearchID);
        }
      }

      async function searchCustomer(lastSearchID) {
        if (this.searchInput.trim() == "") {
          this.showSearchResults = false;
          return;
        }

        let fuzzySearchInput = this.searchInput.trim()

        const notFuond = "4"
        const success = "1";

        if(this.serverSearch == false){

	        

            let customers = await appDB.positiveCustomer.where('search').startsWithIgnoreCase(fuzzySearchInput).limit(50).toArray()
            let _result = {i_return_code:notFuond, customers:[]};

            if (lastSearchID != this.lastSearchID) {
              return;
            }

            if(customers.length > 0){
              this.noCustomersFound = false
              customers = _.uniqBy(customers, 'customer_number')
              _result = {i_return_code:success, customers:customers};
                this.showCustomer(_result);
            }
            else{
              this.noCustomersFound = true
            }
        }
        else{
          app.showLoadingMessage('מחפש לקוח - אנא המתן');
          let is_taz = posUtils.validateTz(this.searchInput)

          PositiveTS.Service.FetchReq.jsonReq(`/delivery_customer_club?club_name=positive&search=${this.searchInput}&taz=${is_taz}`,"get")
          .then(async ret =>  {

            if(this.cities.length == 0){
              this.cities = await Service.Addresses.getCities(); 
             }
    
              if(this.streets.length == 0){
                this.streets = await  Service.Addresses.getStreets(); 
              }
              
            switch (ret.result.i_return_code) {
              case "1":
                this.noCustomersFound = false
                 this.showCustomer(ret.result);
                break;
              case "4":
                this.noCustomersFound = true
                break;
              case "2":
                this.showError(ret.result.s_return_msg);
                break;
              default:
                this.showGenericError()
            }
            this.loading = false
            app.hideLoadingMessage()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            app.hideLoadingMessage()
          })
        }
      }

      function showCustomer(result: any) {

        this.customers = []
        this.showSearchResults = true;
        this.customers = result.customers;
        this.customers.forEach(cust => {
          cust.expired = cust.valid_until ? (moment(cust.valid_until).toDate() < new Date()) : false
        })       
      }


      function validate(): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
          let errors = []
          if (!this.customer.first_name || this.customer.first_name.trim() === "") {
            errors.push({ field: "firstName", error: "חובה להזין שם פרטי" })
          }
          if (!this.customer.last_name || this.customer.last_name.trim() === "") {
            errors.push({ field: "lastName", error: "חובה להזין שם משפחה" })
          }
          if (!this.customer.phone_number || this.customer.phone_number.trim() === "") {
            errors.push({ field: "phoneNumber", error: "חובה להזין טלפון" })
          }
          if (!posUtils.isValidIsraelPhone(this.customer.phone_number.trim()) && !posUtils.isValidIsraelMobilePhone(this.customer.phone_number.trim())) {
            errors.push({ field: "phoneNumber", error: "מספר טלפון לא תקין" })
          }
          if (this.customer.discount_percent && isNaN(this.customer.discount_percent)) {
            errors.push({ field: "discountPercent", error: "אחוז הנחה ללקוח חייב להיות ערך מיספרי או ריק" })
          }

          if (!posUtils.isBlank(this.customer.customer_tz) && String(this.customer.customer_tz).trim().length > 9) {
            errors.push({ field: "taz", error: i18next.t('hakafaCustomerDialog.errors.tazLength') })
          }

          resolve(errors);
        })
      }

      async function updateDetails(){
        let customerErrors = await validate.call(this)
        if (customerErrors.length > 0) {
          let self = this
          let errStr = ""

          customerErrors.forEach((el) => errStr += `${el.error}<br>`)

          app.showAlert({
            header: i18next.t("error"),
            content: errStr,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          },
          () => {
            self.$refs[customerErrors[0].field].focus();
          }, null)

          return
        }

        let errors = await validateAllAddresses.call(this);
        if(errors.length > 0){
          app.showAlert({
            header:  i18next.t('error'),
            content: "קיימות כתובות לא תיקניות, נא לוודא עיר, רחוב ומס' בית",
            continueButtonText: i18next.t('ok'),
            hideCancelButton: true,
          })
        }
        else{
          this.setCustomerAddresses();
          this.selectCustomer(this.customer);
        }     
      }

      async function addNewCustomer() {
        let self = this;
        validate.call(this)
          .then(async errors => {
            if (errors.length > 0) {
              let errStr = "";
              errors.forEach((el) => { errStr += `${el.error}<br>` })

              app.showAlert({
                header: i18next.t("error"),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                () => {
                  self.$refs[errors[0].field].focus();
                }, null)

            }
            else{
              let customerAlreadyExists = false;
              app.showLoadingMessage('אנא המתן');
              let customerSearch = await Storage.Entity.PositiveCustomer.search(this.customer.phone_number);
              if(customerSearch.result.i_return_code == 1){
                for(let customer of customerSearch.result.customers){
                  if((customer.tz || "") == (this.customer.customer_tz || "") || (customer.customer_number || "") == (this.customer.customer_tz || "")){
                    app.hideLoadingMessage()
                    customerAlreadyExists = true;
                    app.showAlert({
                        header:  i18next.t('error'),
                        content: i18next.t('delivery.tryingToAddExistsingCustomer'),
                        continueButtonText: i18next.t('ok'),
                        hideCancelButton: true,
                      })

                      return;
                    }
                  }
              }
              else if(customerSearch.result.i_return_code == 2){
                app.hideLoadingMessage()
                    app.showAlert({
                        header:  i18next.t('error'),
                        content: customerSearch.result.s_return_msg,
                        continueButtonText: i18next.t('ok'),
                        hideCancelButton: true,
                      })

                      return;
              }
              
              app.hideLoadingMessage();

              let errors = await validateAllAddresses.call(this);
              if(errors.length > 0){
                app.showAlert({
                  header:  i18next.t('error'),
                  content: "קיימות כתובות לא תיקניות, נא לוודא עיר, רחוב ומס' בית",
                  continueButtonText: i18next.t('ok'),
                  hideCancelButton: true,
                })
              }
              else{
                let hakafaCustomerAlreadyExists = false;
                if(this.customer.enable_hakafa_payment){
                  if(posUtils.isNullOrUndefinedOrEmptyString(this.customer.customer_tz)){
                    return app.promiseShowAlert({
                      header:  i18next.t('error'),
                      content: "חובה להזין תעודת זהות עבור לקוח המשלם בהקפה",
                      continueButtonText: i18next.t('ok'),
                      hideCancelButton: true,
                    })
                  }
                  app.showLoadingMessage('אנא המתן');
                  let ret = await PositiveTS.Storage.Entity.HakafaCustomer.search({search: this.customer.customer_tz});
                  if(ret.result.status == 1)
                  {
                    for(let customer of ret.result.customers){
                      if(customer.tz == this.customer.customer_tz || customer.customer_number == this.customer.customer_tz){
                        hakafaCustomerAlreadyExists = true;
                        app.hideLoadingMessage()
                        app.showAlert({
                            header:  i18next.t('error'),
                            content: i18next.t('delivery.tryingToAddExistsingCustomer'),
                            continueButtonText: i18next.t('ok'),
                            hideCancelButton: true,
                          })
                        }
                      }
                    }
                }
                 
                 if (this.createCustomerOnline){
                  if (!Pinia.globalStore.isOnline){
                    this.showError(i18next.t("positiveCustClub.errorCannotCreateCustomerOnOffline"))
                    return
                  }

                  app.showLoadingMessage(i18next.t("positiveCustClub.createCustomer"))
                  let res = await PositiveTS.Service.FetchReq.jsonReq('/positive_customer_club?club_name=positive&create_from_pos=true', 'post', {customer: this.customer})
                  if (res.result && Array.isArray(res.result.errors)){
                    this.showError(res.result.errors.join(`<br>`))
                    return
                  }

                  this.customer.id = res.result.customer.id
                } else {
                  this.customer.mode = 1;
                }

                app.hideLoadingMessage()
                if(!hakafaCustomerAlreadyExists){
                  this.customer.customer_addresses = [];
                  this.customer.is_delivery_customer_only = true;
                  this.setCustomerAddresses();
                  this.selectCustomer(this.customer);
                }
              }

            }
          });

          
      }
    
      function setCustomerAddresses(){
        this.customer.customer_addresses = [];

        for(let address of this.customerAddresses){
          let addressToPush = Object.assign({},address);

          //Fix for manual input so data will be consistent in db

          if(address.city){
            if(!address.city.name){
              addressToPush.city = {
                name: address.city,
                value: -1
              }
            }
            if(!address.city.value){
              addressToPush.city.value = -1;
            }
            if(!address.address.name){
              addressToPush.address = {
                name: address.address,
                value: -1
              }
            }

            delete addressToPush["filterdStreets"];
            delete addressToPush["prevSelectedCity"];
            this.customer.customer_addresses.push(addressToPush);
          }
        }
      }

      function customerHistory(customer) {
        this.customer = customer;

        if (customer.customer_addresses != null) {
          var fileredDefaultCustomerAddress = customer.customer_addresses.filter(row => { return row.is_default });
          if (fileredDefaultCustomerAddress.length > 0) {
            PositiveTS.Service.Delivery.setDeliveryCustomerAddress(fileredDefaultCustomerAddress[0])
            this.shortCustomerAddress = Service.Delivery.getShortDeleveryCustomerAddress(posVC.sale.jsondata);
            fileredDefaultCustomerAddress[0].is_selected = true;
          }
        }

        (new PositiveTS.Storage.Entity.CustomerGroup()).all().
          then(result => {
            if (customer.customer_group_id) {
              customer.customer_group_name = result.filter(row => { return row.code == customer.customer_group_id })[0].name;
            }

            let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
            customerClubService.setCurrentSelectedCustomer(convertToPosStructure.call(this, customer))
          })
          .then(() => {
            return posVC.loadSale()
              .then(() => {
                this.customerSelected = true;
                this.customers = []
                this.showSearchResults = false;
                this.searchInput = "";
                this.serverSearch = false;
        
                PositiveTS.Components.PositiveDeliveryPreviousOrders.openPreviousOrders(parseInt(customer.id), this.isCustomerClubMode).then(()=>{
                  this.selectCustomer(this.customer)
                  if(!posUtils.isNullOrUndefinedOrEmptyString(this.shortCustomerAddress)){
                    this.close(false, this.isCustomerClubMode);
Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryOrderDetail", args: [this.isCustomerClubMode, this.customer, this.notForDelivery]});
                  }
                })
              })
          })
      }

      function showError(errStr) {
        app.showAlert({
          header: i18next.t("error"),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
          null, null)
      }

      async function loadAddresses() {
        if(this.cities.length == 0) {
          this.cities = await Service.Addresses.getCities(); 
        }

        if (this.streets.length == 0) {
          this.streets = await  Service.Addresses.getStreets(); 
        }
      }

      async function setMode(mode) {
        switch (mode) {
          case "search":
            this.searchCustomerMode = true
            this.addNewCustomerMode = false
            PositiveTS.VueInstance.$nextTick(()=> {
              if (this.$refs.searchInputText) {
                this.$refs.searchInputText.focus();       
              } 
            })
            break;
          case "new":
            this.searchCustomerMode = false;
            this.addNewCustomerMode = true;
            if(!posUtils.isBlank(this.searchInput)){
              this.customer.phone_number = this.searchInput
            }         
            if (this.cities.length == 0 || this.streets.length == 0) {
              app.showLoadingMessage("טוען ערים ורחובות...");
              await PositiveTS.VueInstance.$nextTick();
            }
            
            await this.loadAddresses();
            PositiveTS.VueInstance.$nextTick(()=> {
              app.hideLoadingMessage();
              this.$refs.phoneNumber.focus();        
            })
            if(!posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.deliveryDefaultCity))) {
              //all of this component needs to be rewritten. I am ashamed to have contributed to this.
              let defaultAddress = _.cloneDeep(this.customerAddresses[0]);
              this.filterStreets(defaultAddress, true);
              this.customerAddresses[0] = defaultAddress
            }
            break;
          default:
            //do noting
            break;
        }
      }

      function selectInput(field) {
        this.$refs[field].focus();
      }

      function customerGroupNameFocus() {
        return PositiveTS.Dialogs.CustomerGroupSelect.selectCustomerGroup().
          then(selected => {
            this.$refs["customerGroupName"].value = selected.name;
            this.customer.customer_group_name = selected.name;
            this.customer.customer_group_id = selected.code;
            if (this.allowSetNewCustomerDiscountPercent) {
              this.$refs["discountPercent"].focus();
            } else {
              this.$refs["phoneNumber"].focus();
            }

          })
      }

      function getNewAddresses() {
        let defaultCity = {
          is_default:  true,
          is_selected: true,
        };

        if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && !posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.deliveryDefaultCity))) {
          let cityFromDB = null;

          
          if (PositiveTS.Service.WasmDB.tableExists('cities')) {
            cityFromDB = PositiveTS.Service.WasmDB.execAsObject(
              `SELECT * FROM cities WHERE city_name LIKE '${jsonConfig.getVal(jsonConfig.KEYS.deliveryDefaultCity)}'`)[0]  
          }
          
          if(!posUtils.isBlank(cityFromDB)) {
            defaultCity['city'] = {
              cityID: cityFromDB.city_id,
              value: cityFromDB.city_id,
              name: cityFromDB.city_name,

            }
            
          } else {
            defaultCity['city'] = {
              name: jsonConfig.getVal(jsonConfig.KEYS.deliveryDefaultCity)
            }
          }

        }

        return  [defaultCity];
      }

      export function create() {
        let positiveDeliveryCustomerClub = {
          template: JST.positiveDeliveryCustomerClub(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            close: close,
            cleanData: cleanData,
            searchCustomer: searchCustomer,
            showCustomer: showCustomer,
            showError: showError,
            addNewCustomer: addNewCustomer,
            selectCustomer: selectCustomer,
            setMode: setMode,
            loadAddresses: loadAddresses,
            selectInput: selectInput,
            addNewAddress: addNewAddress,
            removeAddress: removeAddress,
            saveCurrentAddress : saveCurrentAddress ,
            setDefault: setDefault,
            assignCustomerIfWeGotHereFromOfflineSearch: assignCustomerIfWeGotHereFromOfflineSearch,
            saveAddress: saveAddress,
            selectDeliveryAddress: selectDeliveryAddress,
            customerHistory: customerHistory,
            customerGroupNameFocus: customerGroupNameFocus,
            updateDetails: updateDetails,
            filterStreets: filterStreets,
            setCustomerAddresses: setCustomerAddresses,
            searchCustomerKeyPressed: searchCustomerKeyPressed,
            getNewAddresses:getNewAddresses,
            checkIfEmpty: function(event){
              var elem = event.target
              if(elem.value.length == 0){
                elem.classList.add("required-input-border")
              }
              else{
                elem.classList.remove("required-input-border")
              }
            },
            
          },

          data: initData,
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {
              fullClub,
              allowSetNewCustomerDiscountPercent,
              mobileLayout,
              addressCityAndApartmentFieldsRequired,
              mobilePhoneRepairModule
            } = Pinia.storeToRefs(globalStore);

            const globalStoreProps = {
              fullClub,
              allowSetNewCustomerDiscountPercent,
              mobileLayout,
              addressCityAndApartmentFieldsRequired,
              mobilePhoneRepairModule
            }

            return {...globalStoreProps}
          },
          computed: {
            locationFieldsAreRequired() {
              return Boolean(!this.notForDelivery || !this.mobilePhoneRepairModule || this.addressCityAndApartmentFieldsRequired)
            },
          }
        }

        VueApp.component('positive-delivery-customer-club', positiveDeliveryCustomerClub)

      }
    }
  }
}
