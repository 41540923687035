module PositiveTS {
export module Components {
export module ZReport {

  const _dialogSelector = "#zreport"
  const _vueComponentName = 'z-report';

  export function create() {
    let zReport = {
        template: JST.zReport(),
        
        methods: {
          sendEmail() {
            Pinia.componentsStore.openComponent( {
              componentName: 'emailDialog', 
              args: [
                Shared.Constants.EmailReportType.Z_REPORT, 
                { fromDate: zReportVC.cachedZReport.createdAt, 
                  toDate: zReportVC.cachedZReport.createdAt, pos: session.pos.deviceID }
              ]
            });
          },
          printTransmitReport() {
            if(Service.Gateway.isEnabled()){
              Printing.Reports.ZXReports.printFullGatewayEmvReport(zReportVC.cachedZReport);
            }else{
              Printing.Reports.ZXReports.printFullEmvReport(zReportVC.cachedZReport);
            }
            
          },
          setZReport(report) {
            this.zReport = report;
          },
          getDeliveryApiTotalsParsed() {
            return (this.zReport && !posUtils.isBlank(this.zReport.deliveryApiTotals)) ? JSON.parse(this.zReport.deliveryApiTotals) : null;
          }
        },
        data() {
          return { 
            zReport: null,
            vouchersByTypeId: Storage.Entity.Voucher.getAllByTypeId(),
          }
        },
        computed: {
          isRoshemet() {
            return session.pos.isRoshemet;
          },
          isEmv() {
            return session.pos.isEmv;
          },
          hasFlights: () => session.pos.hasFlights,
          printOnlyEMVReportSessionNumbersInZ: () => jsonConfig.getVal(jsonConfig.KEYS.printOnlyEMVReportSessionNumbersInZ),
        }
    }

    VueApp.component(_vueComponentName,zReport)
  }
}}}
