module PositiveTS {
	export module Storage {
		export module Entity {
			export class SyncError extends WasmEntity {

				error: string;
				title: string;
				description: string;
				
				constructor() {
					let meta = {
						name: 'syncErrors',
						fields: {
							error: "TEXT",
							title: "TEXT",
							description: "TEXT",
						},
					}
					super(meta)
				}


				static getTitleByCode(id:number):string {
					const result = this.execAndConvertResult(`select * from syncErrors where id = ${id}`)[0];
					if(posUtils.isPresent(result?.id)) {
						return result.title
					}
					return null;
				}

				static getDescriptionByCode(id:number):string {
					const result = this.execAndConvertResult(`select * from syncErrors where id = ${id}`)[0];
					if(posUtils.isPresent(result?.id)) {
						return result.description
					}
					return null;
				}

				static getCodeByError(error:string):number {
					let result = this.execAndConvertResult(`select * from syncErrors where error LIKE '${error}'`)[0];
					if(posUtils.isBlank(result?.id)) {
						result = this.execAndConvertResult(`select * from syncErrors where error LIKE 'UKNOWN'`)[0];
					}
					if(posUtils.isPresent(result?.id)) {
						return result.id
					}
					return null;
				}

				static getTitleByError(error:string):string {
					const code = SyncError.getCodeByError(error)
					return SyncError.getTitleByCode(code);
				}

				static getDescriptionByError(error:string):string {
					const code = SyncError.getCodeByError(error)
					return SyncError.getDescriptionByCode(code);
				}
			}
		}
	}
}
