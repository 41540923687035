module PositiveTS {
export module Storage {
export module Entity {
export class SvaTransmitLog extends IDBEntity {
        
  tranFileNumber:string
  confirmationNumber:string
  attachedToZNumber:string
  createdAt:string
  updatedAt:string
  shvaReportString: string
  
  constructor() {
    let meta = {
      name: 'SvaTransmitLog',
      internal: true,
      fields: {
        tranFileNumber: "TEXT",
        confirmationNumber: "TEXT",
        attachedToZNumber: "TEXT",
        shvaReportString: "TEXT",
        createdAt: "TEXT",
        updatedAt: "TEXT"
      }
    }
    super(meta)
  }
  
  private static _getTranFileNumber(svaReportString) {
    return svaReportString.substring(22,24); 
  }
  
  private static _getConfirmationNumber(svaReportString) {
    return svaReportString.substring(14,22);
  }
  
  
  private _setFields(svaReportString){
    this.confirmationNumber = SvaTransmitLog._getConfirmationNumber(svaReportString);
    this.tranFileNumber = SvaTransmitLog._getTranFileNumber(svaReportString);
    this.shvaReportString = svaReportString;
    this.createdAt = PositiveTS.DateUtils.fullFormat();
    this.updatedAt = PositiveTS.DateUtils.fullFormat();
    this.timestamp = (new Date()).getTime();
  }
  
  private static async _isCurrentShvaReportNotExists(svaReportString):Promise<boolean> {

    let timeStampFrom = moment().add(-90,"days").toDate().getTime();

    let count = await appDB.svaTransmitLogs.where('confirmationNumber')
                      .equals(SvaTransmitLog._getConfirmationNumber(svaReportString))
                      .and(stl => stl.timestamp > timeStampFrom).count()
    
    console.debug(count);
    return count == 0;
    
  }

  private static async _isTableEmpty():Promise<boolean> {
    return (await appDB.svaTransmitLogs.count()) == 0;
  }
  
  static async createEntriesForNonExistingShvaTransmits(totalString = null):Promise<boolean> {
    
    let svaReportString = totalString != null ? totalString : await serviceShva.getTotalFile();

    if (svaReportString == null) {
      return;
    }
    
    let svaTransLogNeedsToBeCreated = await SvaTransmitLog._isCurrentShvaReportNotExists(svaReportString)
    
    if (svaTransLogNeedsToBeCreated) {
      let stl = new SvaTransmitLog()
      stl._setFields(svaReportString);
      //don't attach the first record
      let isTableEmpty = await SvaTransmitLog._isTableEmpty()
      if (isTableEmpty) {
        stl.attachedToZNumber = '-1';
      }
      await appDB.svaTransmitLogs.put(stl);
      return true
    }
    else {
      return false;
    }
    
  }
  
  static async getEntriesForNonExistingShvaTransmits():Promise<string> {
    await this.createEntriesForNonExistingShvaTransmits()
    
    let result = await appDB.svaTransmitLogs.where('attachedToZNumber').equals('').toArray()
    
    return JSON.stringify(
      result.map(stl => { return {  confirmationNumber: stl.confirmationNumber,  shvaReportString: stl.shvaReportString } })
    )
      
  }
    

          
}}}}