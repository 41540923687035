module PositiveTS {
  export module Components {
    export module SelfServiceCash {
      export function getComponent() {
        return {
          template: JST.selfServiceCash(),
          mixins: [
            Mixins.wizardItemMixin,
            Mixins.modalsMixin,
            Mixins.selfServicePaymentMixin("selfService.cash", true, { type: Mixins.ICON_TYPES.FONT_AWESOME, name: 'fa fa-coins' },
              undefined, { paymentInProcessTitle: () => i18next.t('selfService.cashPayment.creatingOrder') })],
          data() {
            return {
              failed: false,
              paymentInProcess: true,
              orderNumber: 0,
              closeByUserFlag: false,
              fontColor: jsonConfig.getVal(jsonConfig.KEYS.selfServiceColorPickerForHeadlines),
            };
          },
          computed: {
            fontColorStyle() {
              return { 'color': this.fontColor };
            },
          },
          methods: {
            async paymentAction() {
              this.resetData();
              if (!Pinia.globalStore.isOnline) {
                this.failed = true;
                app.showErrorAlertDialog(i18next.t("selfService.cashPayment.offlineError"));
                this.goBack();
                return;
              }
              try {
                this.paymentInProcess = true;
                let newOrder = await PositiveTS.Service.SelfServiceSaleOrders.createNewOrder(posVC.sale, posVC.saleItems);
                posVC.sale.orderNumber = newOrder.order_num;
                let printingOptions: PositiveTS.Types.PrintInvoiceFactoryOptions = { dontPrintInvoiceNumber: true, explicityPrintOrderNumber: true }
                await PositiveTS.Printing.Invoice.printInvoiceFactory(Pinia.globalStore.sale, Pinia.globalStore.saleItemsFormatted, [], true, undefined, undefined, printingOptions);
                this.orderNumber = newOrder.order_num;
                this.paymentInProcess = false;
                this.closeByUserFlag = false;
                await PositiveShared.Utils.sleep(15000);
                if (this.closeByUserFlag) {
                  return;
                }
                await this.close();
              } catch (error) {
                this.failed = true;
                console.error(error);
                app.showErrorAlertDialog(i18next.t("generalError"));
                PositiveTS.Service.Filelog.log("An error hash occured while creating order in self service cash payment: ", JSON.stringify(error), false, 'error');
                this.goBack();
              }
            },
            async releasePageNow() {
              this.closeByUserFlag = true;
              await this.close();
            },
            async close() {
              await posVC.restartSale();
              this.closePaymentPage();
            },
            resetData() {
              this.failed = false;
              this.paymentInProcess = true;
              this.orderNumber = 0;
              this.closeByUserFlag = false;
            }
          },
        };
      }
    }
  }
}
