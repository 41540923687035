module PositiveTS {
  export module Components {
    export module selfServiceHakafaCustomerPaymentMethod {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreen(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin,
          Mixins.selfServicePaymentMixin("selfService.hakafaCustomer", true, { type: Mixins.ICON_TYPES.POSITIVE_ICON, name: 'customerHakafaCard' })],
          created() {
            this.paymentInProcess = this.hasCustomerOnSale(posVC.sale);
          },
          methods: {
            async paymentAction() {
              try {
                let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                const hasCustomerOnSale = this.hasCustomerOnSale(posVC.sale);
                if (hasCustomerOnSale == false) {
                  let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
                  await this.searchHakafaCustomerAndPutOnSale(amount);
                }

                // pay just if has customer on sale for case that customer not found
                // if hasCustomerOnSale is true on the first time not need check again 
                if (hasCustomerOnSale || this.hasCustomerOnSale(posVC.sale) == true) {
                  await this.pay();
                }
              } catch (error) {
                console.error(error);
                this.paymentInProcess = false;
                await this.showError(i18next.t("generalError"));
                this.setCurrentStep("SelfSelectPaymentMethod");
              }
            },
            async pay() {
              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
              let customer: Service.Hakafa.HakafaDialogCustomerDetails = JSON.parse(posVC.sale.jsondata)
              let leftToPay = (customer.obligo ? customer.obligo : 0) + (customer.amount ? customer.amount : 0);
              if (customer.obligo != null && leftToPay - amount <= 0) {
                this.paymentInProcess = false;
                await this.showError(i18next.t('directHakafa.moreThanObligo'));
              } else {
                let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(Service.Hakafa.getVoucherID(), amount, customer.customer_number);
                if (response.success) {
                  this.goToNextStep();
                } else {
                  this.paymentInProcess = false;
                  console.error(response.error);
                  this.showError(response.error);
                  this.setCurrentStep("SelfSelectPaymentMethod");
                }
              }
            },
            async searchHakafaCustomerAndPutOnSale(amount) {
              this.paymentInProcess = false;
              let args = { goWithSelectedPaymentMethodType: this.selectedPaymentMethodType != Mixins.INPUT_METHODS.SWIPE_CARD };
              let searchInput = await this.getPaymentCardNumber(amount, args);
              this.paymentInProcess = true;
              let customer = await PositiveTS.Storage.Entity.HakafaCustomer.getCustomerByPhoneOrCustomerNumber(searchInput);
              if (customer) {
                customer.amount = session.fixedNumber(customer.amount);
                let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
                const posCustomer = await Service.Hakafa.convertToPosStructure(customer);
                await customerClubService.setCurrentSelectedCustomer(posCustomer);
                await posVC.saleUpdated();
              } else {
                await this.showError(i18next.t('clubMemberSelfServiceDialog.hakafaNotFound'));
                if (this.selectedPaymentMethodType == Mixins.INPUT_METHODS.SWIPE_CARD) {
                  this.setCurrentStep("SelfSelectPaymentMethod");
                } else {
                  await this.searchHakafaCustomerAndPutOnSale(amount);
                }
              }
            },
            async showError(content, header = i18next.t('error')) {
              app.hideLoadingMessageDialog();
              await app.showAlertDialog({ header, content, continueButtonText: i18next.t("ok"), hideCancelButton: true });
            },

            hasCustomerOnSale (sale) {
              return PositiveTS.Service.Hakafa.hasCustomer(sale);
            },
          },
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {getExtraData} = Pinia.storeToRefs(selfServicePaymentStore);
            return {getExtraData}
          },
        }
      }
    }
  }
}