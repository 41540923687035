module PositiveTS {
  export module Components {
    export module SelfServiceSafeCash {

      export function getComponent() {
        return {
          template: JST.selfServiceSafeCash(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          components: {positiveIcon: PositiveIcon.getComponent(), SelfServicePage: SelfServicePage.getComponent(), QrcodeVue},
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {setCurrentStep} = selfServicePaymentStore;
            return {setCurrentStep}
          },
          methods: {
            cancelAndGoBack() {
              this.safeCashPayment.cancelRequest(this.safeCashPayment)
              this.goToSelectPaymentMethodScreen();
            },
            cancelCodePayment(){
              this.showQR = true;
              this.safeCashPaymentByCode.cancelRequest(this.safeCashPaymentByCode)
            },
            goToSelectPaymentMethodScreen() {
              this.setCurrentStep("SelfSelectPaymentMethod");
            },

            async codePayment (el) {
              el.target.disabled = true;
              this.code = null;
              try{
                this.safeCashPaymentByCode = new Service.SafeCashPayment(this.leftAmount, 'CODE');
    
                app.showLoadingMessage(i18next.t('safeCash.createRequestForCodePayment'));
                let responsePaymentCode = await this.safeCashPaymentByCode.requestCreateCode();
                app.hideLoadingMessage();
    
                if (responsePaymentCode.success){                      
                  this.showQR = false;               
                  this.code = responsePaymentCode.code;
                  let resultPayment = await this.safeCashPaymentByCode.request(responsePaymentCode.transactionId);
                  if (resultPayment.success){
                    if (!resultPayment.isCanceled){
                      app.showLoadingMessage(i18next.t('safeCash.proccessingPayment'))
                      const additionalData: PositiveTS.Service.SafeCashPaymentAdditionalData = {
                        requestPaymentData: this.safeCashPaymentByCode.requestPaymentData,
                        responsePaymentData: resultPayment.response,
                      }
                      
                      let res = await PositiveTS.Service.VoucherPayment.payBySmartVoucherTypeEx(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH, resultPayment.response.amount, null, null, additionalData)
                      
                      if(res.success){
                        this.goToNextStep();
                      }else{
                        console.error(res.error)
                        throw new Error(res.error);
                      }
                    }
                  }else {                   
                    Service.Logger.error('safeCash request Response Error');
                    Service.Logger.error(resultPayment.error);
                    throw resultPayment.error;
                  }                  
                }else {
                  app.showErrorAlert(responsePaymentCode.error);
                }
    
                el.target.disabled = false;        
                this.showQR = true;
              }catch(e){               
                el.target.disabled = false;          
                this.showQR = true;

                if (e !== 'cancelRequest'){
                  console.error(e);
                  app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: e.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                }
              }
            },
          },
          data() {
            return {
              qrCode: null,
              code: null,
              safeCashPayment: null,
              showQR: true,
              leftAmount: null,
              safeCashPaymentByCode:null
            }
          },
          async mounted() {
            try {
              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              this.leftAmount = totals.totalAmount - totals.totalPaid;
  
              app.showLoadingMessageDialog(i18next.t('safeCash.createRequestForCodePayment'));
              this.safeCashPayment = new PositiveTS.Service.SafeCashPayment(this.leftAmount, 'QR', true)
              this.safeCashPayment.setRequestPaymentData()
              let QRResponse = await this.safeCashPayment.requestCreateQR(this.safeCashPayment.requestPaymentData)


              let transactionId = null;

              if (QRResponse.success){
                this.qrCode = QRResponse?.data?.qrCodeAsString;
                transactionId = QRResponse?.data?.transactionId;
                app.hideLoadingMessageDialog()
              } else {
                Service.Logger.error(QRResponse.error)
                Service.Logger.error('safeCash requestCreateQR Response Error')
                throw new Error('safeCash requestCreateQR Response Error');
              }

              let result = await this.safeCashPayment.request(transactionId)
              if (result.success){
                if (!result.isCanceled){
                  app.showLoadingMessage(i18next.t('safeCash.proccessingPayment'))
                  const additionalData: PositiveTS.Service.SafeCashPaymentAdditionalData = {
                    requestPaymentData: this.safeCashPayment.requestPaymentData,
                    responsePaymentData: result.response,
                  }
                  
                  let res = await PositiveTS.Service.VoucherPayment.payBySmartVoucherTypeEx(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH, result.response.amount, null, null, additionalData)
                  
                  if(res.success){
                    this.goToNextStep();
                  }else{
                    console.error(res.error)
                    throw new Error(res.error);
                  }
                }
              }else{
                Service.Logger.error('safeCash request Response Error');
                Service.Logger.error(result.error);
                throw result.error;
              }


            } catch (error) {
              console.error(error)
              app.hideLoadingMessageDialog()
              if(typeof error == 'string'){
                app.showErrorAlertDialog(error)
              }else{
                app.showErrorAlertDialog(i18next.t('safeCash.globalError'))
              }
              this.goToSelectPaymentMethodScreen();
            }            
          },
        }
      }
    }
  }
}