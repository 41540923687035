module PositiveTS {
	export module PromotionsQueue {

        export async function launchQueueExt() {
            try {
                await launchQueue();
            } catch(error) {
                let msg = "";
                if (error && error.message) {
                    msg = error.message;
                }
                if (typeof(error) === "string") {
                    msg = error;
                }
                console.error(`Did not send promotions to server. Reason: ${msg}`);
            }

            // Schedule the next queue launch
            setTimeout(() => { PromotionsQueue.launchQueueExt(); }, 5000);			
        }

	    async function launchQueue() {
			
			let items = await workerDB.promotions.where('syncStatus').anyOf(
				[Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT, Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED]).toArray()
            
			if (items.length == 0) {
				return;
			}

			if (!PositiveTS.QueueWorker.Utils.isOnline) { 
				console.debug('Aborting (offline)');
				return;
			}
			
			await sendItemsToServer(items);
			return;
		}

		async function sendItemsToServer(items) {
			
			console.debug('Items to sync: ');
			console.debug(items);
	
			// Send the sales to the remote server
			let url = Shared.Constants.remoteRoot + Shared.Constants.PromotionsRemote;
			
            let results = await PositiveTS.QueueWorker.FetchReq.jsonReq(url,"POST",PositiveTS.QueueWorker.Utils.token,{ data: JSON.stringify(items) })
            console.debug(results);
            // return
			let promises = [];
			// --- If there was an error, the remote server will return an error object
			if ('error' in results.result) {
				await saveFailures(items)
				console.error(results.result.error);
				throw new Error(results.result.error);
			}
			else {
				for (let itemResult of results.result) {

					if (itemResult.success) {
						
						promises.push(workerDB.promotions.where('id').equals(itemResult.pos_promotion_id)
						.modify({syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY, serverID: itemResult.id}))
					
					}
					else {
						//error
						promises.push(workerDB.promotions.where('id').equals(itemResult.pos_promotion_id)
						.modify({syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED}))
					}
				}

				await Promise.all(promises);
				return;
			}
		}

		async function saveFailures(items) {
			
			// Mark all sales sync status as failed
			await workerDB.promotions.where('id').anyOf(items.map(item => item.id))
			.modify({ syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED })
			return;
		}

	}
}
