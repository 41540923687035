module PositiveTS {
export module Components {
export module XReport {
    
    const _dialogSelector = "#xreport"
    const _vueComponentName = 'x-report';
    
    export function create() {
        let xReport = {
            template: JST.xReport(),
            
            methods: {
                setXReport(report) {
                    this.xReport = report;
                },
                openMuliCurrencyCashDetails(){
                    Pinia.componentsStore.openComponent(
                    {componentName:"multiCurrencyCashDetailsDialog", args: [this.xReport]});
                }
            },
            computed:{
                isMultiCurr(){
                    return PositiveTS.Service.MultiCurr.getInstance().isMultiCurr();
                }
                
            },
            data() {
                return {
                    xReport: null,
                    vouchersByTypeId: Storage.Entity.Voucher.getAllByTypeId(),
                }
            }
        }
        VueApp.component(_vueComponentName,xReport);
    }
}}}