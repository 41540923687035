module PositiveTS {
export module Storage {
export module Entity {
export class ItemBarcode extends WasmEntity {

	code:string
	barcode:string
	color:string
	size:string
	
	constructor() {
		let meta = {
			name: 'ItemBarcode',
			fields: {
				code: "TEXT",
			  size: "TEXT",
				color: "TEXT",
				barcode: "TEXT"
			}
		}
		super(meta)
	}

	defaultVal(field) {

		// console.warn(`assigning default value to a missing field in entity ${this.meta.name}. Field is ${field}`);
		switch (this.meta.fields[field]) {
			case "TEXT":
				return "";
			case "BOOL":
				return false;
			case "INT":
			case "FLOAT":
				return 0;
			case "JSON":
				return "{}";
			default:
				return undefined;
		}
	}

	importFromObject(importFrom) {
		if (session && session.pos && session.pos.isRoshemet) {
			for (let field in this.meta.fields) {
				if (!(field in importFrom)) {
					this[field] = this.defaultVal(field)
				}
				else {
					this[field] = importFrom[field];
				}
			}

			// If there is an ID, mark the entity as not new
			if ('id' in importFrom) {
				this.id = importFrom.id;
				this.timestamp = importFrom.timestamp;
				this._new = false;
			}
			return this;
		}
		else {
			return super.importFromObject(importFrom);
		}
	};

	async fetchByCodeWithSizeColor(code) {
		
		let aThis = this;

		if (code) {
			code = code.trim();
		}

		let result = await Service.WasmDB.promiseSql(`select * from ${aThis.meta.name} where code='${code}' and (size != "" OR color != "")`)
		let items:ItemBarcode[] = [];
		for (let i = 0; i < result.length; i++) {
			let entity = new ItemBarcode();
			entity.importFromObject(result[i]);
			items[i] = entity;
		}
		return items;

	};
}}}}
