module PositiveTS {
	export module Promotions {
		class Result {
			constructor(success,userCanceled:boolean,error? :string,promotionBarcode? :string) {
				this.success = success
				this.error = error
				this.userCanceled = userCanceled
				this.promotionBarcode = promotionBarcode
			}
			userCanceled = false
			success:boolean
			error:string
			promotionBarcode = ""

		}
		export class PromotionWithBarcode {
			isBarcodePromotion(promotion:any):boolean {
				return promotion.hasBarcode;
			}

			async process(code:string):Promise<Result> {
				try {
					let options = new PositiveTS.Dialogs.InputDialogOptions();
					options.header =  i18next.t("pos.insertBarcode")
					let selectedValue = await inputDg.open(options);
					app.showLoadingMessage('בודק ברקוד')
					try {
						let response = await fetch(`/promotion_barcodes/${code}?promotion_barcode=${selectedValue}`,{
							headers: {'Authorization': `Token token=${session.pos.access_token}`}
						})	
						let json = await response.json()
						app.hideLoadingMessage()
						return (new Result(json.success,false,json.error,selectedValue));
					}
					catch(e) {
						app.hideLoadingMessage()
						console.error(e)
						return (new Result(false,false,"שגיאה כללית, אנא נסה שנית מאוחר יותר"))
					}
				}
				catch(e) {
					//do nothing - user canceled
					return (new Result(false,true))
				}
				
			}
		}
	}
}
