module PositiveTS {
export module Components {
export module Mixins {
  export var scrollableMixin = {
    data: () => {
      return {
        scrollUpImg: `fas fa-angle-up`,
        scrollDownImg: `fas fa-angle-down`
      }
    },
    methods: {
      scroll: function(direction, scrollSelector, offset = 100) {
        this.scrollByElement(direction, document.querySelector(scrollSelector), offset);
      },
      scrollByElement(direction, element, offset = 100) {
        switch (direction) {
          case 'up':
            element.scrollTop -= offset //TODO: maybe animate?
          break;
          case 'down':
            element.scrollTop += offset
          break;
        }
      }
    }
  }
}}}
