module PositiveTS {
export module Service {
export module PluxeeService {
export module Voucher {

  export function isPluxeeCibusVoucher(currentVoucher){
    return currentVoucher && [PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE, PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS].includes(currentVoucher.smartVoucherType);
  }

  export function isAuthorizedCibus():boolean{
    return Boolean(jsonConfig.getVal(jsonConfig.KEYS.cibusPosId));
  }

  export function isPluxeeCibusVoucherAndUserNotAuthorized(currentVoucher){
    return isPluxeeCibusVoucher(currentVoucher) && !isAuthorizedCibus();
  }


  export function listOfCompanies(): Array<{companyCode: string, companyName: string}> {
    return JSON.parse(localStorage.getItem(PositiveTS.Service.PluxeeService.Soap.companiesLocalStorageKey))
  }


}}}}
