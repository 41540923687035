module PositiveTS {
    export module Service {
        export module SimplyClubAPI {
            export enum SearchFieldType {
                CELL_PHONE = 7,
                CARD_FIELD = 17,
                PHONE = 6,
                PERSONAL_ID = 20,
                FIRST_NAME = 0,
                LAST_NAME = 1,
                /** Used for search Member details only (i.e. not used for search list result) */
                SYS_ID = 19,
                /** Used for search Member details only (i.e. not used for search list result) */
                EMAIL = 5,
                /** Search all cols via ONE! */
                SEARCH_ALL = 100,
            }
        }
    }
}