module PositiveTS {
    export module Service {
        export class BanxPayment extends SmartVoucher {
            static API_PREFIX = 'https://srv.banx.co.il/api';
            static STATUS_PAYMENT = {approved: 1, declined: 2, refund: 3, canceled: 4, completed: 5};
            
            public sale;
            public amount: string;
            private resolvePayment;
            private rejectPayment;
            private requestPaymanetData;
            private timerId: number;
            private hasCancelRequest: boolean;

            constructor (sale, amount) {
                super();
                this.sale = sale;
                this.amount = amount;
                this.resolvePayment = undefined;
                this.rejectPayment = undefined;
                this.timerId = null;
                this.requestPaymanetData = undefined;
                this.hasCancelRequest = false;
            }

            pollingStatusPayment () {
                clearTimeout(this.timerId)
                this.timerId = window.setTimeout(async () =>  {await this.handlePollingStatusPayment()}, 1000);
            }

            static async fetchToken () {
                try{
                    const url = `/auth/token?username=${session.pos.banxUserName}&password=${session.pos.banxUserPass}`;
                    const res = await BanxPayment.apiRequest(url);
                    if (res.status == 200){
                        session.banxToken = res.data;
                        return;
                    }
                    console.error('Error fetch banx token', res.data);
                }catch(e){
                    console.error('Error fetch banx token', e);
                }
            }
            
            static getToken () {
                return session.banxToken;
            }

            static async apiRequest (url, method = 'GET', data = {}) {
            url = `${BanxPayment.API_PREFIX}${url}`;
            let requestData: any = {
                method: method, 
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', // if we have problem with Android this could be the problem
                    'token': BanxPayment.getToken()
                }
              }

            const methodsWithoutBody = ['GET', 'HEAD'];
            if (!methodsWithoutBody.includes(method) && !_.isEmpty(data)) {
                requestData.body = JSON.stringify(data);
            }

            let response: any = await fetch(url, requestData);
            if(response.headers.get("content-type") && response.headers.get("content-type").includes('application/json')){
                response.data = await response.json();
            }else{
                response.data = await response.text();
            }
            
            return response;
           }

            async handlePollingStatusPayment () {
                const url = `/wallet/validateOrder?orderId=${this.requestPaymanetData.orderId}`;
                
                try{
                    const response = await BanxPayment.apiRequest(url);
                    if (response.status == 401){
                        //todo refresh the QR check it on pos
                        await Service.QRService.hideQR()
                        this.rejectPayment({error: i18next.t('banxPayment.errorApiToken')});
                        return;
                    }

                    if (response.status == 200 && !_.isEmpty(response.data)){
                        const data = response.data;
                        const STATUS_PAYMENT = BanxPayment.STATUS_PAYMENT;

                        await Service.QRService.hideQR()

                        if (data.status == STATUS_PAYMENT.approved){
                            this.resolvePayment(data); 
                            return;
                        }
                        
                        if (data.status == STATUS_PAYMENT.declined){
                            this.rejectPayment({error: i18next.t('banxPayment.customerDeclinedPayment')});
                            return;
                        }

                        if ([STATUS_PAYMENT.refund, STATUS_PAYMENT.canceled, STATUS_PAYMENT.completed].includes(data.status)){
                            this.rejectPayment({error: i18next.t('banxPayment.globalError')});
                            return;
                        }

                    }else{
                        if (this.timerId != null){
                            this.pollingStatusPayment();
                        }
                    }

                    console.debug('handlePollingStatusPayment response:', response);
                }catch(e){
                    await Service.QRService.hideQR()
                    console.error('Error handlePollingStatusPayment' + e);
                    this.rejectPayment({error: i18next.t('banxPayment.globalError')});
                }
            }


            request (type: 'QR' | 'SMS' = 'QR'): Promise<any> {
                const promise = new Promise((resolve, reject) => {
                    this.resolvePayment = resolve;
                    this.rejectPayment = reject;
                });

                if (type === 'QR' || type === 'SMS'){
                    this.prepareRequestPaymentData();

                    if (type === 'QR'){
                        QRService.displayQR(this.stringifyRequestPaymentData())
                    }

                    if (!this.hasCancelRequest){
                        this.pollingStatusPayment();
                    }
                    return promise;
                }  
                
                return Promise.reject(i18next.t('banxPayment.globalError'));
            }

            cancelRequest (banxPayment) {
                clearTimeout(banxPayment.timerId);
                banxPayment.timerId = null;
                banxPayment.hasCancelRequest = true;
                banxPayment.rejectPayment('cancelRequest');
                app.hideLoadingMessageWithCancel();
            }

            async commitPayment (orderId) {
                const res = await BanxPayment.apiRequest('/wallet/pay', 'POST', {orderId});
                try{
                    if (res.status == 200){
                        return Promise.resolve();
                    }
                    
                    return Promise.reject(i18next.t('banxPayment.responseErrorBanx'));
                }catch(e){
                    console.error('Error commitPayment' + e);
                    return Promise.reject(i18next.t('banxPayment.globalError'));
                }
            }

            async pay (voucherData:any, amount: number, cvv?: string, companyId?: any,additionalData?: any): Promise<any> {
                let resolveReject = {resolve: null, reject: null};
                const promise = new Promise((resolve, reject) => {
                    resolveReject.resolve = resolve;
                    resolveReject.reject = reject;
                });

                //additionalData is object of BanxPayment
                const orderId = additionalData.requestPaymanetData.orderId;
                try{
                    await additionalData.commitPayment(orderId);
                    voucherData.allowPartialReturn = true;
                    voucherData.actionType = SmartVoucherActionTypes.WITHDRAW;
                    voucherData.barCode = orderId;
                    voucherData.amount = Number(amount);
                    voucherData.orderId = orderId;
                    voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT;
                    voucherData.data = JSON.stringify(additionalData.requestPaymanetData);
                    resolveReject.resolve(voucherData);
                }catch(e){
                    resolveReject.reject(new Error(e));
                }

                return promise;
            }

            async cancelPayment(data: any, doRemove?:boolean):Promise<any>{
                if (doRemove){
                    return this.apiCancelPayment(data.orderId)
                }else{
                    return this.apiRefundPayment(data.orderId, data.amount)
                }
            }

            async apiCancelPayment (orderId) {
                const res = await BanxPayment.apiRequest('/wallet/cancelPayment', 'POST', {orderId});
                try{
                    if (res.status == 200){
                        return Promise.resolve({success: true});
                    }
                    
                    return Promise.reject({success: false});
                }catch(e){
                    console.error('Error apiCancelPayment' + e);
                    return Promise.reject({success: false});
                }
            }

            async apiRefundPayment (orderId, amount) {
                amount = Math.abs(amount);
                const res = await BanxPayment.apiRequest('/wallet/refund', 'POST', {orderId, amount});

                try{
                    if (res.status == 200){
                        return Promise.resolve({success: true});
                    }
                    
                    return Promise.resolve({success: false});
                }catch(e){
                    console.error('Error apiCancelPayment' + e);
                    return Promise.resolve({success: false});
                }
            }

            cancelLoad(paymentToCancel: any): Promise<any> {
                throw new Error("Method not implemented.");
            }

            loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
                throw new Error("Method not implemented.");
            }

            getCardNumber(): Promise<GetCardNumberResponse> {
                throw new Error("Method not implemented.");
            }

            getBalance (cardNumber: string, cvv?:string): Promise<GetBalanceResponse> {
                throw new Error("Method not implemented.");
            }

            private prepareRequestPaymentData () {
                this.requestPaymanetData = {
                  storeId: String(this.sale.storeID),
                  orderId: this.sale.id + '-' +  moment().unix(),
                  amount: String(this.amount)
                };
                console.log('requestPaymanetData', this.requestPaymanetData);
                console.log('stringifyRequestPaymentData QR string', this.stringifyRequestPaymentData())
            }

            private stringifyRequestPaymentData (){
                const paymanetData = this.requestPaymanetData;
                return `${paymanetData.storeId}$${paymanetData.orderId}$${paymanetData.amount}`;
            }
            
        }
    }
}