module PositiveTS {
    export module Application {
    export module Controllers {
    export class EmployeeTimeReportViewController extends AbstractViewControllerTS {
        // UGLY! Remove this when solving the resume issue in dialogs!!!
        fromEmployeeSelect = false;
        // -- END of UGLY
        selectedEmployee = null;

        init(options) {
        }
        resume(options) {
            // Initialize employee card field
            $('#employee-time-report-employee-card-field-container').inputField({
                keyboardOn:   false,
                disclosureOn: true
            });
            $('#employee-time-report-employee-card-field-container').inputField('disclosureListener', employeeTimeReportVC.openEmployeeSelectDialog);
            $('#employee-time-report-employee-card-field-container').inputField('change', employeeTimeReportVC.updateEmployeeOnClear);
            if (!employeeTimeReportVC.fromEmployeeSelect) {
                $('#employee-time-report-employee-card-field-container').inputField('setValue', '');
            }
    
            // Initalize month picker and set it to the previous month
            $('#employee-time-report-month-container').monthPicker();
            if (!employeeTimeReportVC.fromEmployeeSelect) {
                $('#employee-time-report-month-container').monthPicker('setDefault');
                // $('#employee-time-report-month-container').monthPicker('step', -1);
            }
    
            $('#employee-time-report-continue').click(employeeTimeReportVC.continueClicked);
        }
        stop() {
            // Unbind the listeners from the resume function
            $('#employee-time-report-continue').unbind('click');
            $('#employee-time-report-employee-card-field-container').inputField('unbind');
        }
        destroy() {
        }
        // --------------------------------------------------------------------
        // View Related Functions
        // --------------------------------------------------------------------
        updateEmployeeOnClear(event) {
            if ($('#employee-time-report-employee-card-field-container').inputField('getValue') === '') {
                employeeTimeReportVC.selectedEmployee = null;
            }
        }
        openEmployeeSelectDialog(event) {
            return employeeSelectVC.openRelativeTo('/homepage/employee-time-report')
            .then(function (employee:any) {
                employeeTimeReportVC.selectedEmployee = employee;
                $('#employee-time-report-employee-card-field-container').inputField('setValue', employee.name);
            });
        }
        displayErrorMessage(errorMessage) {
            app.showAlert({
                header: i18next.t('error'),
                content: errorMessage,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            }, function () {
            }, null);
        }
        continueClicked() {
            var month = $('#employee-time-report-month-container').monthPicker('getMonth');
            var year  = $('#employee-time-report-month-container').monthPicker('getYear');
    
            // Generate report for the supplied parameters
            employeeTimeReportVC.generateReport(employeeTimeReportVC.selectedEmployee, month, year);
        }
        closeScreen() {
            pNavigator.back();
        }
        // --------------------------------------------------------------------
        // Logic Related Functions
        // --------------------------------------------------------------------
        async generateReport(employee, month, year) {
            let employeeID: any;
            if (employee !== null) {
                employeeID = employee.employeeID;
            }
    
            try {
                const htmlResponse = await PositiveTS.Service.EmployeeTimeReport.getReport({
                    employee_id: employeeID,
                    month: month,
                    year: year
                });
                let dialogElem = document.getElementById("employee-time-report-dialog");
                let dialogElemContent = document.getElementById("employee-time-report-dialog-content");
                dialogElemContent.innerHTML = htmlResponse;
                let printBtnElement = <HTMLElement>document.getElementById("emp-time-track-print-btn")
                
                if(printBtnElement && session.isAndroid){
                    printBtnElement.hidden = true;
                }
                
                (<HTMLDialogElement>dialogElem).showModal();
                
            } catch (error) {
                console.error(error);
                employeeTimeReportVC.displayErrorMessage(error.responseText);
            }
        }
    }
}}}
declare var employeeTimeReportVC:PositiveTS.Application.Controllers.EmployeeTimeReportViewController;
employeeTimeReportVC = new PositiveTS.Application.Controllers.EmployeeTimeReportViewController();
PositiveTS.Application.Controllers.instances.EmployeeTimeReportViewController = employeeTimeReportVC
