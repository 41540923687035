(function( $ ){
	// Define some methods that can be used on buttons
	var methods = {
		// Initializes a button
		init: function (options) {
			return this.each(function () {
				let $this = $(this);

				// Read options from element
				var elementOptions:any = {};
				if ($this.attr('data-background-color') != undefined) {
					elementOptions.backgroundColor = $this.attr('data-background-color');
				}

				if ($this.attr('data-icon-url') != undefined) {
					elementOptions.iconURL = $this.attr('data-icon-url');
				}

				if ($this.attr('data-reflection') != undefined) {
					elementOptions.reflection = ($this.attr('data-reflection') === 'true');
				}

				if ($this.attr('data-shine') != undefined) {
					elementOptions.shine = ($this.attr('data-shine') === 'true');
				}

				if ($this.text().replace(/ /g,'').trim() != '') {
					elementOptions.text = $this.html();
					$this.text('');
				}
				var opts = $.extend(elementOptions, options);

				// Default options...
				var defaults = {
					backgroundColor: 'blue',
					iconURL: '',
					onClick: null,
					reflection: false,
					shine: false,
					text: '',
					defaultButtonStyle:true,
					textOpt:'1'
				};
				opts = $.extend(defaults, opts);

				// Save the options on the element so they can be retrieved later
				$this.attr('data-background-color', opts.backgroundColor);
				$this.attr('data-icon-url', opts.iconURL);
				$this.attr('data-reflection', opts.reflection);
				$this.attr('data-shine', opts.shine);

				// If there is no ID yet, set an unique ID for this button
				var id;
				if ($this.attr('id') == undefined) {
					$this.attr('id', 'ui-button-' + Math.floor(Math.random()*1000));
				}
				id = $this.attr('id');

				// Add classes
				$this.addClass('ui-button');

				// On click listener
				if (opts.onClick != null) {
					$this.click(opts.onClick);
				}

				// Button reflection
				if (opts.reflection) {
					var reflectionDiv = $('<div></div>');
					reflectionDiv.addClass('ui-button-reflection');
					$this.append(reflectionDiv);
				}

				// Button style
				var styleDiv = $('<div></div>');
				styleDiv.addClass('ui-button-style').addClass('ui-button-style-' + opts.backgroundColor + '-background');
				$this.append(styleDiv);

				// Button shine
				if (opts.shine) {
					var shineDiv = $('<div></div>');
					shineDiv.addClass('ui-button-shine');
					styleDiv.append(shineDiv);
				}

				// Button icon
				if (opts.iconURL != '') {
					var iconDiv = $('<div></div>');
					iconDiv.addClass('ui-button-icon');
					iconDiv.append($('<img>').attr('src', opts.iconURL));
					$this.append(iconDiv);
				}

				// Button text
				var textDiv = $('<div></div>');
				textDiv.addClass('ui-button-text');
				textDiv.html(opts.text);
				if (opts.iconURL == '') {
					textDiv.css({'line-height': '2.5em'});
					//textDiv.css({'margin-right': '0.7em'});
				}
				$this.append(textDiv);
			});
		},
		// Makes a button inactive
		inactive: function () {
			let $this = $(this);

			// Get the button background color
			var backgroundColor = $this.attr('data-background-color');

			// Check that the background color is defined
			if (backgroundColor == undefined) {
				// It is not defined, exit!
				return;
			}

			// Remove the button background
			$this.find(' > .ui-button-style').removeClass('ui-button-style-' + backgroundColor + '-background');

			// Set the button style to inactive
			$this.find(' > .ui-button-style').addClass('ui-button-style-inactive-background');
		},
		// Makes a button active
		active: function () {
			let $this = $(this);

			// Get the button background color
			var backgroundColor = $this.attr('data-background-color');

			// Check that the background color is defined
			if (backgroundColor == undefined) {
				// It is not defined, exit!
				return;
			}

			// Remove the inactive button background
			$this.find(' > .ui-button-style').removeClass('ui-button-style-inactive-background');

			// Set the button style to inactive
			$this.find(' > .ui-button-style').addClass('ui-button-style-' + backgroundColor + '-background');
		}
	};

	let btnFunc = function (method) {
		// Method calling logic
		if (methods[method]) {
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof method === 'object' || !method) {
			return methods.init.apply( this, arguments );
		} else {
			$.error('Method ' +  method + ' does not exist on jQuery.uibutton');
		}
	};

	// Add it to jQuery prototype
	$.fn.uibutton = btnFunc;

})( jQuery );
