module PositiveTS {
export module Service {
export module Addon {

  const _AddonId = "cmocchjblapfodknpnamoiebikflhobo";
  export function isAddonInstalled():Promise<boolean> {

    return new Promise((resolve,reject) => {
    let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    let isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1 && (<any>window).Android != null;

    if (isChrome === true && !isAndroid) {
      try { 
        chrome.runtime.sendMessage(_AddonId, { message: "version", useChannel: (session.addonFilesVersion >= 2) },
        function (reply) {
            if (reply && reply.valuAddon === true) {
                resolve(true);
            } else {
                resolve(false);
            }
          });
      }
      catch (err) {
        resolve(false);
      }
    } else {
      resolve(false);
    }
    })

  }

  export function sendNativeMessageToExtension(data,addonMethod, splashMsg?):Promise<any> {

    return new Promise((resolve,reject) => {

    if (splashMsg) {
      let showRefreshTimeout = !(addonMethod === 'emv' || addonMethod === 'emv_atm' || addonMethod === 'pinpad_addon')
      app.showLoadingMessage(splashMsg,null,showRefreshTimeout);
    }

    	isAddonInstalled()
    	.then((isAddonInstalled) => {
        if (isAddonInstalled) {
          
          let noChannelAddons = ["file_upload","external_service","scale","http_router"];
          if (!Boolean(jsonConfig.getVal(jsonConfig.KEYS.printWithChannel))) {
            noChannelAddons.push("print20")
          }

          chrome.runtime.sendMessage(_AddonId, {
              message: data,
              method: addonMethod,
              useChannel: (session.addonFilesVersion >= 2 && noChannelAddons.indexOf(addonMethod) == -1)
            },
            function (reply) {
                if (splashMsg) {
                  app.hideLoadingMessage();
                }


                
                if(addonMethod && (addonMethod == "http_router" || addonMethod == "external_service") ){
                  if(typeof(reply.request.result) == "string" && reply.request.result.substring(0, 5) == "ERROR"){
                      console.error("Network error, response from addon was: " + JSON.stringify(reply));
                      return reject( i18next.t('addon.networkError'))
                  }
                }
                
                //console.dir(reply);
  				        resolve(reply);
            });

        } else {
          //this whole file only applys to windows anyway but
          //there are lots of places in the pos that instead of calling
          //GenereicAddon function calling this function directly
          //so instead of chaging everything we check here if it's android or windows
          if(session.isAndroid == false){
            app.hideLoadingMessage();
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t('addonNotInstalledCorrectly'),
              continueButtonText: i18next.t('installAddon'),
            },
            () => {
              const addonUrl = 'https://chrome.google.com/webstore/detail/valu-pos/cmocchjblapfodknpnamoiebikflhobo'
              let win = window.open(addonUrl, '_blank')
              win.focus()
              resolve()
            },
            () => {
              resolve()
            })
          }
        }
      }
    );
    })
  }

  //only used for printing because of legacy reasons - for all other purposes - use sendNativeMessageToExtension
  export function sendMessageToExtension(data,addonMethod):Promise<any> {

    return new Promise((resolve,reject) => {
      var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
      let isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

      if (isChrome === true && !isAndroid) {
        chrome.runtime.sendMessage(_AddonId, {
            message: data,
            method: addonMethod,
            useChannel: (session.addonFilesVersion >= 2 && Boolean(jsonConfig.getVal(jsonConfig.KEYS.printWithChannel)))
          },
          function (reply) {
            if (reply) {
              if (reply.valuAddon === true) {
                  resolve(true);
              }
              else {
                resolve()
              }
            } 
            else {
                resolve(false);
            }
          });
      } else {
         resolve(false);
      }
    })

  }

}}}
