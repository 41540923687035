//** PositiveSimple.Storage.Entity.SalePayment.METHOD_* */
enum SalePaymentMethod {
    CHANGE = 0,
    CREDIT = 1,
    CASH = 2,
    VOUCHER = 3,
    CREDIT_VOUCHER = 4,
    CHECK = 5,
    VOUCHER_DISCOUNT = 6,
    CREDIT_VOUCHER_CHANGE = 7,
    // Payment type used for Parxell Receipt
    PRAXELL = 8,
    // Payment type used for Valu Receitpt
    VALU = 9,
    GPP = 10,
    MULTIPASS = 11,
    VALUE_CARD = 12,
    MULTIPASS_POLICE = 13,
    OTOT = 14,
    GOODI = 17,
    DTS = 18,
    YAAD = 19,
    CASH_WITHDRAWAL = 30,
    CASH_WITHDRAWAL_REFUND = 31,
}

export default SalePaymentMethod;