module PositiveTS {
export module Service {
export module Tip {
    export function isHasTips() {
        return jsonConfig.getVal(jsonConfig.KEYS.showTip) && !posUtils.isBlank(getTipsItem());
    }

    export function getTipsItem() {
        return session.pos.tipItemCode && session.allItems.get(session.pos.tipItemCode);
    }

    
    export function isTipItemCode(code) {
        return session.pos.tipItemCode && code == session.pos.tipItemCode;
    }

    export function isTipItem(item) {
        if (!isHasTips()) {
            return false;
        }

        return isTipItemCode(item.code);
    }
    
    export async function addTipToSale(amount: number) {
        if (amount <= 0) {
            return;
        }

        let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(getTipsItem(), {});
        saleItem.saleID = posVC.sale.id;
        saleItem.rowNumber = posVC.getRowNumber();
        saleItem.unitPrice = Number(amount);
        await posVC.persistNewSaleItem(saleItem, false, true);
        await posPaymentVC.updateAmountsIndicators(false);
    }

    export function totalSaleItemsTips(saleItems: any[]): number {
        return totalSaleItemsTipsCountAndAmount(saleItems).amount;
    }


    export function totalSaleItemsTipsCountAndAmount(saleItems: any[]): any {
        let totalAmount = 0;
        let totalCount = 0;

        for (let saleItem of saleItems) {
            if (isTipItemCode(saleItem.itemCode)) {
                totalAmount += (saleItem.unitPrice * saleItem.quantity);
                totalCount += saleItem.quantity;
            }
        }

        return {amount: totalAmount, count: totalCount};
    }

    export function totalSalesTipsCountAndAmount(sales: any[]): any {
        return totalSaleItemsTipsCountAndAmount(_.flatten(sales.map(sale => sale.items)));
    }
}}}
