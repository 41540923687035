module PositiveTS {
export module Storage {
export module Entity {
export class ItemPriceList extends WasmEntity {

	constructor() {
		let meta = {
			name: 'ItemPriceList',
			fields: {
				itemCode: "INT" ,
				price: "FLOAT" ,
				priceListCode: "INT"
			},
			unique: []
		}
		super(meta)
	}

	getPricesByItemCodeAndPriceListCodes (itemCode: string, priceListCodes: string[]):Promise<PositiveTS.Service.PriceList.PriceAndListCode[]> {
		var _sql = `select price, priceListCode from itempriceList where itemCode='${itemCode}' and priceListCode in (${priceListCodes.join(",")})`;
		return PositiveTS.Service.WasmDB.promiseSql(_sql)
		.then( (result) =>{
			var _return:PositiveTS.Service.PriceList.PriceAndListCode[] = [];
			for (var i=0;i<result.length;i++){
				_return.push( {price:result[i].price, priceListCode: result[i].priceListCode} );
			}
			return _return;
		})
	}

}}}}
