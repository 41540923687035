/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"LockScreen", arguments: []})

*/
module PositiveTS {
export module Components {
export module LogoutDialog {
  const _dialogSelector = "#positive-logout-dialog"
  const _vueComponentName = 'logout-dialog';

  export function create() {
    let vueObj = {
        template: JST.logoutDialog(),
        methods: {
          open: open,
          close: close,
          doLogout: doLogout,
          _clearForm: _clearForm,
        },
        data: _initData,
    }

    VueApp.component(_vueComponentName,vueObj)

  }


  function open(currentBalance: string, valuCardNumber: string){
    let isMobile = Pinia.globalStore.mobileLayout
    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: isMobile ? 'positive-dialog logout-dialog-mobile' : 'positive-dialog',
        width: '80%',
        height: jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) ? 350 : 300,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });

    this._clearForm();

  }

  async function doLogout(){
    if (this.password !== "ValU"){
      this.status = i18next.t("logoutScreen.INVALID_PASSWORD");
      return;
    }
    let shouldStopRecording = session.shouldRecordPos();

    let canDoLogout = await checkIfCanDoLogout()
    if (canDoLogout.approved == false){
      let errorMessage = canDoLogout.errors.join("\n\n")
      const wrappedErrorMessage = `<div class="max-h-64 overflow-y-scroll">${errorMessage}</div>`
      app.showErrorAlert(wrappedErrorMessage)
      return
    }

    app.showLoadingMessage(i18next.t("loggingOut") + "...");
    return _logout(this.isRemoveId)
    .then(() => {
      if (shouldStopRecording) {
        console.info("stop recording pos");
        datadogRum.stopSession();
      }      
    }).then(()=>{     
     console.info("Refresh to login page");
     location.reload(true);
    })
    .catch((e)=>{
      console.error(e);
      app.hideLoadingMessage();

      return app.promiseShowAlert({
              header: i18next.t('error'),
              content: i18next.t('logoutFailed'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
      .then( ()=>{
        // this to ensure that even when id removed on server and client could not be aware
        // it would be forced to login to validate his local phisical id
        this.close();
        return _logout(false)
      });
    })
  }

  async function checkIfCanDoLogout (): Promise<{approved: boolean, errors: Array<string>}> {
    let result = {approved: true, errors: []}

    if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
      if (posVC.sale && posUtils.isDefined(Service.Dalpak.getDalpakBySale(posVC.sale))) {
        result.errors.push(i18next.t('logoutActiveDalpak'))
      }
    }else {
      let offlineSales = await appDB.sales.filter(sale => {
          return sale.syncStatus != PositiveTS.Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY && 
            sale.invoiceSequence != PositiveTS.Storage.Entity.Sale.NULL_INVOICE_SEQUENCE
        })
        .toArray();

      if(offlineSales.length > 0){
        result.errors.push(i18next.t('logoutOfflineSale'))
      }
    }

    const getLocalItemsNotSync = await PositiveTS.Service.CheckPosPropriety.getItemNotSyncToServer('localItems')
    if (getLocalItemsNotSync.length > 0){
      const failedItems = getLocalItemsNotSync.map((item,index) => `${i18next.t("numberItem")} ${index + 1} - ${i18next.t("addItemDialog.itemCode")}:${item.barcode? item.barcode : ""}`).join('\n')
      result.errors.push(i18next.t('logoutItemsNotSync', { failedItems: failedItems,failedItemsLength: getLocalItemsNotSync.length}));
    }

    const getLocalEmployeesNotSync = await PositiveTS.Service.CheckPosPropriety.getItemNotSyncToServer('employees')
    if (getLocalEmployeesNotSync.length > 0) {
      const failedEmployees = getLocalEmployeesNotSync.map(employee => `${i18next.t("employeeManageEdit.employeeName")}: ${employee.name}}`).join('\n')
      result.errors.push(i18next.t('logoutEmployeesNotSync', { failedEmployees: failedEmployees,failedEmployeesLength: getLocalEmployeesNotSync.length}));
    }

    result.approved = result.errors.length == 0
    return result
  }

  function _clearForm(){

    this.password = "";
    this.status = i18next.t("logoutScreen.STATUS_IDLE");
    this.isRemoveIdDisabled = !PositiveTS.Reachability.isOnline;
    this.isRemoveId = false;
    this.$refs.password.focus();
  }

  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      password: "",
      status: "",
      isRemoveIdDisabled: !PositiveTS.Reachability.isOnline,
      isRemoveId: false,
      currentUser: session.pos.deviceID,
      isSelfServiceSuperMarketPos: jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) && jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos) ? true : false
    };
  }



  function close() {
    $(_dialogSelector).dialog('close');
    
    if (PositiveTS.Service.CheckPosPropriety.isCannotWorkOnPos()){
Pinia.componentsStore.openComponent( {componentName:"checkPosProprietyDialog", args: []}) 
    }
  }


  async function _logout(isClearPhisicalId:boolean){
    await storage.clearPhysicalId(isClearPhisicalId)
    await storage.deleteAll();

  }


}}}
