module PositiveTS {
	export module Helper {
		export module SalesPerson {

			/**
			* updateSalesPersonForItemInDB
			* Updates the sales person for the given saleItem in the DB if needed
			* Sync/Async: Sync
			*/
			export function updateSalesPersonForItemInDB(saleItem) {
				if (posUtils.isNullOrUndefined(saleItem)) {
					return saleItem;
				}
				if (posUtils.isNullOrUndefined(posVC.sale)) {
					return saleItem;
				}
				
				if (!posUtils.isDefined(posVC.sale.salespersonEmployeeName)) {
					return saleItem;
				}
				
				if (
					(posUtils.isNullOrUndefinedOrEmptyString(saleItem.salespersonEmployeeName)) &&
					(!posUtils.isNullOrUndefinedOrEmptyString(posVC.sale.salespersonEmployeeName))
				) {
					
					// Update the current sales person in the sale item (if exist)
					saleItem.salespersonEmployeeID = posVC.sale.salespersonEmployeeID;
					saleItem.salespersonEmployeeName = posVC.sale.salespersonEmployeeName;

					if (saleItem.children) {
						for (let child of saleItem.children) {
							child.salespersonEmployeeID = posVC.sale.salespersonEmployeeID
							child.salespersonEmployeeName = posVC.sale.salespersonEmployeeName;
							if (child.children) {
								for (let gc of child.children) {
									gc.salespersonEmployeeID = posVC.sale.salespersonEmployeeID
									gc.salespersonEmployeeName = posVC.sale.salespersonEmployeeName;
								}
							}
						}
					}
				}
				
				return saleItem;
			};
			
			/**
			* deleteSalesPersonFromSaleIfNeeded
			* Sync/Async: Sync
			*/
			export function deleteSalesPersonFromSaleIfNeeded() {
				
				if (posVC.saleItems.length === 0){
					
					if (posVC.sale.salespersonEmployeeName !== "")
					{
						// Update the sales person  in the sale
						posVC.sale.salespersonEmployeeID = "";
						posVC.sale.salespersonEmployeeName = "";
						
						//Update the sale window
						$('#pos-sale-info-item-info-sales-person > span').text(posVC.sale.salespersonEmployeeName);
						return true //means that we need to persist the sale
						
					}
				}
				
				return false;
			};
			
		}
	}
}
