module PositiveTS {
  export module Components {
    export module PosPayment {
      export module PaymentPage {
        export function create() {
          let paymentPage = {
            template: JST.PaymentPage(),
            mixins: [keyPadMixin, Mixins.paymentViewMixin, Mixins.oneClickMixin],
            components: {
              KeypadBtn: KeypadBtn.getComponent(),
              positiveIcon: PositiveIcon.getComponent(),
            },
            methods: {
              async makeCancelPaymentAction(activePayment){
                try{
                  app.showLoadingMessage(i18next.t('creditCardPayment.cancelPayment'))
                  let response = await Service.Gateway.refundTxPromiseByTranID(activePayment["TranID"],activePayment["TerminalId"]);
                  if(!(response.refundDeal?.status == "000" || response.result == "314")){
                    let errorMessage = i18next.t('creditCardPayment.gateway.errors.cannotCancelPayment') +'\n'
                    errorMessage+= response.userMessage;
                    app.showAlert({
                      header: i18next.t('error'),
                      content: errorMessage,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    });
                    return
                  }
                  let creditPayment = posVC.salePayments.find(sp => {return sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT})
                  let paymentDataIndexToDelete = -1;
                  let paymentToDelete;
                  if (creditPayment) {
                    let data = JSON.parse(creditPayment.data);
                    for(let i=0; i<data.length ;i++){
                      if(data[i].isGateway && data[i]["User"] == activePayment["User"]){
                        paymentToDelete = data[i];
                        paymentDataIndexToDelete = i;
                      }
                    }
                    if (paymentToDelete) {
                      data.splice(paymentDataIndexToDelete,1)
                      if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
                        let creditLeadCurrencyAmount = parseFloat(paymentToDelete.creditLeadCurrencyAmount);
                        creditPayment.amount -= creditLeadCurrencyAmount;
                      } else {
                        creditPayment.amount -= (paymentToDelete.Amount /100);
                      }
                      creditPayment.data = JSON.stringify(data);
                      // Persist the sale payment
                      await Service.FullSale.saveCurrentSale()
                      this.removeSplittedPaymentIfNeeded();
                      await this.refreshView()
                      await posPaymentVC.updateAmountsIndicators()
                      app.hideLoadingMessage()
                    }
                  }
                }catch(err){
                  let errorMessage = i18next.t('creditCardPayment.gateway.errors.generalMessage')
                  app.showAlert({
                    header: i18next.t('error'),
                    content: errorMessage,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                  Service.Logger.error(err)
                  app.hideLoadingMessage()
                }
              },
              async cancelPayment(activePayment){
                  app.showAlert({
                    header: i18next.t('confirmSalePaymentItemDeleteHeader'),
                    content: i18next.t('confirmSalePaymentItemDelete'),
                    continueButtonText: i18next.t('remove'),
                    cancelButtonText: i18next.t('cancel')
                  }, ()=>this.makeCancelPaymentAction(activePayment),null);
              },
              formatCardMask(cardMask) {
                const rightPart = cardMask.slice(0, 6);
                const leftPart = cardMask.slice(-4); 
                const replacement = '******'; 
                return `${leftPart}${replacement}${rightPart}`;
              },
              updateAmount(event) {
                let value = event.target.value;
                let match = value.match(/^\d*(\.\d{0,2})?$/);
                if (match){
                  let parts = match[0].split("");
                  if(parts[0] == '.'){
                      match[0] = `0${match[0]}`
                  }
                }
                this.amount = match ? match[0] : this.amount;
                this.$nextTick(() => {
                  event.target.value = this.amount;
                });
              },
              async refreshView() {
                this.updateScreenAmount();
                await PositiveTS.VueInstance.$nextTick()
              },
              updateScreenAmount(){
                this.addSplittedPaymentIfNeeded();
                let amountLeft;
                if (this.isSplitPaymentActive()) {
                  this.setSplitPaymentAmount();
                } else {
                  let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                  amountLeft = totals.totalAmount - totals.totalPaid;
                  if (this.amount != amountLeft) {
                    this.amount = session.fixedFloat(Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(amountLeft), 2).toString()
                  }

                }
              },
              activePayments(){
                return Service.PaymentPage.activePayments();
              },
              async initialPaymentPageRequest() {
                try {
                  
                  let validation = this.validatePayment()
                  if (!validation.isValid) {
                    app.showAlert({
                      header: i18next.t('error'),
                      content: validation.errorMessage,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    });
                    return
                  }
                  app.showLoadingMessage(i18next.t('paymentPage.loading'))
                  let Xfield = String((new Date()).getTime());
                  let int_in = await Service.PaymentPage.buildInitRequestIntInXml(this.amount,this.paymentCurrency,Xfield);
                  let initResponse = await Service.Gateway.sendRequestToCreditGuard(int_in);
                  this.isCheckPaymentStatusPending = false;
                  if (initResponse.doDeal && initResponse.doDeal.mpiHostedPageUrl) {
                    const currentDateTime = moment();
                    const formattedDateTime = currentDateTime.format('YYYY-MM-DD HH:mm:ss');
                    
                    this.currentIframeUrl = initResponse.doDeal.mpiHostedPageUrl + `&createdAt=${formattedDateTime}&user=${Xfield}`;
                    Service.PaymentPage.setLasGatewayPaymentTxUrl(this.currentIframeUrl)
                    this.runCheckPaymentStatus();
                    app.hideLoadingMessage()
                  }
                } catch (err) {
                  app.hideLoadingMessage()
                  this.clearPayment()
                  Service.Logger.error(err);
                  app.showAlert({
                    header: i18next.t('error'),
                    content: err.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                }
              },
              async checkPaymentStatus(){
                try{
                  if(this.isCheckPaymentStatusPending){
                    return;
                  }
                  if(Service.PaymentPage.isTxExpired() || !Service.PaymentPage.getLasGatewayPaymentTxUrl()){
                    this.clearPayment();
                    return
                  }
                  if(this.inTxProgress || this.isOffline){
                    this.runCheckPaymentStatus()
                    return;
                  }

                  let isInquireCheck = this.retryJ2InquireCheck %10 ==0;
                  this.retryJ2InquireCheck++;

                  let j2Result = {isValid:false,response:null,err:null}
                  if(isInquireCheck){
                    j2Result = await this.makeJ2Request()
                  }
                  if(!this.inTxProgress && j2Result.isValid && isInquireCheck){
                    let j2ResultRow = j2Result.response?.inquireTransactions?.row
                    
                    if(j2ResultRow && Number(j2ResultRow.statusCode) != 0){
                      if(j2ResultRow && Number(j2ResultRow.statusCode) == 5){
                        this.currentIframeUrl = Service.PaymentPage.getCancelURL();
                      }else{
                        this.currentIframeUrl = `${Service.PaymentPage.getFailURL()}?errorText=${j2ResultRow.cgGatewayResponseText}&errorCode=${j2ResultRow.cgGatewayResponseCode}`;
                      }
                      this.clearPayment();
                      return
                    }
                    this.currentIframeUrl = Service.PaymentPage.getSuccessURL();
                    await this.completeLastGatewayPaymentTx();
                    this.runCheckPaymentStatus()
                    return 
                  }
                  if(!this.currentIframeUrl){
                    this.currentIframeUrl = Service.PaymentPage.getLasGatewayPaymentTxUrl();
                  }
                  this.iFrameListener = document.getElementById('payement_page_iframe') as HTMLIFrameElement;
                  if(this.iFrameListener && !this.inTxProgress){
                    this.iFrameListener.addEventListener('load', this.loadIframeListenerFunction);
                  }
                  this.runCheckPaymentStatus();
                }catch(err){
                  this.runCheckPaymentStatus()
                  console.error(err)
                }
              },
              validatePayment(): { isValid: boolean, errorMessage: string } {
                let validation = { isValid: true, errorMessage: '' };
                if (this.amount <= 0) {
                  validation.isValid = false
                  validation.errorMessage = i18next.t('paymentPage.errors.amountMustBeGreaterThanZero')
                  return validation;
                }
                let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);
                if (this.amount > amountLeft) {
                  validation.isValid = false
                  validation.errorMessage = i18next.t('paymentPage.errors.generalPaymentLeftAmountMandatory')
                  return validation;
                }
                if (Service.MultiCurr.getInstance().isMultiCurr() && 
                  this.amount > session.fixedFloat(Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(posVC.getTotalLeftToPay()), 2)){
                  validation.isValid = false
                  validation.errorMessage = i18next.t('paymentPage.errors.generalPaymentLeftAmountMandatory')
                }
                return validation;

              },
              clearPayment(isCloseSale =false){
                
                if(isCloseSale){
                  this.showPaymentPage = false
                }
                this.isCheckPaymentStatusPending = true;
                
                setTimeout(() => {
                  this.currentIframeUrl = '';
                  this.inTxProgress = false;
                  Service.PaymentPage.removeLastGatewayPaymentTxUrl();
                  this.showPaymentPage = true
                  this.iFrameListener.removeEventListener('load', this.loadIframeListenerFunction);
                }, 5000)
              },
              loadIframeListenerFunction() {
                let redirectedURL = this.iFrameListener.contentWindow.location.href;
                if (redirectedURL.includes('payment_page/cancel') || redirectedURL.includes('payment_page/fail')) {
                  this.clearPayment()
                }
                if (redirectedURL.includes('payment_page/success')) {
                  this.iFrameListener.removeEventListener('load', this.loadIframeListenerFunction);
                  this.completeLastGatewayPaymentTx()
                }
              },
              geAmountFullNumber(amount,currency){
                return (currency && this.isMultiCurrency) ? Service.MultiCurr.getInstance().translateAmount(amount, currency) : Number(this.amount);
              },
              async makeJ2Request(): Promise<{isValid:boolean,response:any,err:any}>{
                let result = {isValid:false,response:null,err:null}
                try{
                  let lastTx = Service.PaymentPage.getTxIdFromLasGatewayPaymentUrl()
                  let int_in = await Service.PaymentPage.buildInquireIntInXml(lastTx);
                  let j2Response = await Service.Gateway.sendRequestToCreditGuard(int_in);
                  result.isValid = true;
                  result.response = j2Response;
                  return result
                }catch(err){
                  result.isValid = false;
                  result.err = err;
                }
                return result;
              },
              async completeLastGatewayPaymentTx(){
                try{
                  if(this.inTxProgress){
                    return
                  }
                  this.inTxProgress = true;
                  let lastTx = Service.PaymentPage.getTxIdFromLasGatewayPaymentUrl();
                  let int_in = await Service.PaymentPage.buildInquireIntInXml(lastTx);
                  let j2Response = await Service.Gateway.sendRequestToCreditGuard(int_in);

                  if(j2Response.inquireTransactions){
                    
                    let j9Response;
                    try{
                      let int_in = await Service.PaymentPage.buildJ9IntInXml(j2Response.inquireTransactions);
                      j9Response = await Service.Gateway.sendRequestToCreditGuard(int_in);
                    }catch(err){
                      console.error(err);
                      this.clearPayment();
                      throw new Error(err);
                    }
                    j2Response = await Service.Gateway.sendRequestToCreditGuard(int_in);
                    if(!j9Response || !j2Response){
                      this.inTxProgress = false
                      return;
                    }
                    let paymentGatewayOutput = await Service.PaymentPage.convertToGatewayOutput(j2Response.inquireTransactions,j9Response);
                    let creditCardSalePayment = Service.CreditCardPayment.getSalePayment();
                    let amountFullNumber = this.geAmountFullNumber(paymentGatewayOutput.amount,paymentGatewayOutput.Currency)
                    await Service.CreditCardPayment.savePayment({salePayment: creditCardSalePayment, cardDetails:paymentGatewayOutput},paymentGatewayOutput.amount,paymentGatewayOutput.Currency,amountFullNumber);

                    this.updateScreenAmount()
                    Service.PaymentPage.removeLastGatewayPaymentTxUrl();
                    Service.FullSale.saveCurrentSale()
                    await posPaymentVC.updateAmountsIndicators()
                    await posVC.saleUpdated()
                    await PositiveTS.VueInstance.$nextTick()
                    let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                    let amountLeft = totals.totalAmount - totals.totalPaid;
                    let isCloseSale = amountLeft == 0? true : false;
                    this.inTxProgress = false;
                    this.clearPayment(isCloseSale)
                  }

                }catch(err){
                  this.showPaymentPage = true;
                  this.inTxProgress = false;
                  Service.Logger.error(err);
                  app.showAlert({
                    header: i18next.t('error'),
                    content: err.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                }
              }
            },
            watch:{
              paymentCurrency () {
                if(this.isCurrentScreenPaymentPage){
                  let amount = Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(posVC.getTotalLeftToPay());
                  this.amount = session.fixedFloat(amount, 2);
                }
              },
              isPaymentPageActive(newVal){
                if(newVal && !this.isCurrentScreenPaymentPage){
                  this.isCurrentScreenPaymentPage =  true;
                  if (Service.PaymentPage.getLasGatewayPaymentTxUrl()) {
                    this.checkPaymentStatus()
                    this.runCheckPaymentStatus();
                  } else {
                    this.currentIframeUrl = null
                    Service.PaymentPage.removeLastGatewayPaymentTxUrl();
                  }
                }
              }
            },
            setup(){
              const posStore = Pinia.usePosStore();
              const {isPaymentPageActive} = Pinia.storeToRefs(posStore);
              return {isPaymentPageActive}
            },
            computed:{
              paymentCurrency(){
                return Pinia.globalStore.paymentCurrency || Pinia.globalStore.currency
              },
              isMultiCurrency() {
                return Service.MultiCurr.getInstance().isMultiCurr();
              },
              isOffline(){
                return !Pinia.globalStore.isOnline;
              }
            },
            mounted() {
              this.showPaymentPage = true;
              this.runCheckPaymentStatus = _.debounce(this.checkPaymentStatus, 5000);
            },
            data() {
              return {
                amount: "",
                showPaymentPage:true,
                currentIframeUrl: "",
                runCheckPaymentStatus: null,
                iFrameListener: null,
                inTxProgress:false,
                isCurrentScreenPaymentPage:false,
                retryJ2InquireCheck:0,
                isCheckPaymentStatusPending:false
              }
            }
          }
          VueApp.component('payment-page', paymentPage);
        }
      }
    }
  }
}