module PositiveTS {
export module Storage {
export module Entity {
export class DiscountStore extends WasmEntity {

	constructor() {
		let meta = {
			name: 'DiscountStore',
			fields: {
				discountID: "TEXT",
			  	storeID: "TEXT"
			}
		};
		super(meta)
	}
}}}}
