module PositiveTS {
  export module Dialogs {
    export module CustomerGroupSelect {

      export function selectCustomerGroup():Promise<any>{

        return (new PositiveTS.Storage.Entity.CustomerGroup()).all()
        .then( (listOfCustomerGroups)=>{
            OptionSelectUI.dialog('open');
            return _initTable(listOfCustomerGroups);
        })
      }

      function _initTable(itemList:any[]):Promise<string>{

        $('#OptionSelect-dialog-cancel').hide();
        $('#OptionSelect-dialog-continue').hide();

        var tableView = $('#OptionSelect-dialog-tableview').tableview();
        tableView.setNumberOfVisibleRows(6);

        tableView.empty();

        let theRow = tableView.addRow([
                    "ללא"
        ]);
        theRow.data('data', {code:null, name: ""});

        for (let i=0;i<itemList.length; i++){
          let theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
                      itemList[i].name
          ]));
          theRow.data('data', itemList[i]);
        }

        return new Promise( (resolve) => {
          $("#OptionSelect-dialog-tableview .ui-tableview-row-cell").on('click',function(m){
            var selected =  $(m.target).closest('tr').data().data;
            OptionSelectUI.dialog('close');
            resolve(selected);
          });
        });




      }

    }
  }
}
