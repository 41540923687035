import { defineStore } from 'pinia';
const state = {
    components: {}
}

const getters = {
    isOpen: (state) => (componentName: string) => {
        if (state.components[componentName] === undefined) {
            state.components[componentName] = false;
        }
        return state.components[componentName];
    }
}

const actions = {
    allowComponent(componentName: string) {
        this.components[componentName] = true;
    },
    closeComponent( componentName: string){
        state.components[componentName] = false;
    },
    async openComponent({ componentName, args = [] }) {

        this.allowComponent(componentName)
        await PositiveTS.VueInstance.$nextTick()
        if(!PositiveTS.VueInstance.$refs[componentName]){
            return Promise.reject(i18next.t('components.componentNotExist', { componentName: componentName }))
        }
        return PositiveTS.VueInstance.$refs[componentName].open.apply(PositiveTS.VueInstance.$refs[componentName], args)
    }
}


const componentsStore = defineStore('components', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default componentsStore;
