module PositiveTS {
    export module Service {
        export module Sandi {
            export module SandiService {

                function headers() {
                    let username = session.pos.sandiBasicAuthUsername;
                    const password = session.pos.sandiBasicAuthPassword;
                    let base64EcodedAuth = window.btoa(`${username}:${password}`);
                    return { "Authorization": `Basic ${base64EcodedAuth}`, "accept": "application/json", "Content-Type": "application/json" };
                }

                function baseUrl() { return session.pos.sandiUrl; }

                /** Creates a delivery to be sent by Sandi.
                 * 
                 * Note! This method sets sale's property "jsondata" with 2 properties ("isSandiDelivery" & "sandiOrderId") under property delivery
                 * It is recommended you save that data in that sale
                 */
                export async function CreateDelivery(sale: PositiveTS.Storage.Entity.Sale): Promise<CreateDeliveryRes> {
                    let jsonData = JSON.parse(sale.jsondata);
                    let deliveryData = jsonData.delivery;
                    let address = deliveryData.deliveryAddress;
                    let comments: string = deliveryData.ordererCallerRemarks;
                    if (deliveryData.ordererDeliveryRemarks) {
                        if (posUtils.isBlank(comments)) {
                            comments = deliveryData.ordererDeliveryRemarks;
                        } else {
                            comments += " | " + deliveryData.ordererDeliveryRemarks;
                        }

                    }
                    let req: CreateDeliveryReq = {
                        client_phone: deliveryData.ordererPhone,
                        client_apartment: address.apartment,
                        client_floor: address.house_floor,
                        client_city: address.city.name,
                        client_street: address.address.name,
                        client_building: address.house_number,
                        client_name: deliveryData.ordererName,
                        client_comment: comments,
                        rest_username: jsonConfig.getVal(jsonConfig.KEYS.sandiManUsername),
                    };
                    const res = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponse(baseUrl() + "delivery/createDelivery", "POST", req, headers(), "Sandi API Create Delivery");
                    let resBody: CreateDeliveryRes = res.getJsonBody();
                    if (!res.success || resBody?.msg?.toLowerCase() != "Create order successfully".toLowerCase()) {
                        throw new ErrorWithData("Sandi Service ::: Could not successfully request a delivery order", res);
                    }
                    jsonData.delivery.isSandiDelivery = true;
                    jsonData.delivery.sandiOrderId = resBody.data.order_id;
                    sale.jsondata = JSON.stringify(jsonData);
                    return resBody;
                }

                export async function CancelDelivery(sale: PositiveTS.Storage.Entity.Sale): Promise<{ msg: string, data: any }> {
                    let req: CancelDeliveryReq = null;
                    let jsonData = JSON.parse(sale.jsondata);

                    if (jsonData.delivery.sandiOrderId) {
                        req = { cancel_status: 4, order_id: jsonData.delivery.sandiOrderId };
                    } else {
                        console.warn("SandiAPI ::: CancelDelivery ::: Using `sale.id` as the order's identifier, recommand storing sandi's own order_id instead");
                        throw new Error("sandiOrderId not found");
                    }
                    const res = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponse(baseUrl() + "delivery/cancelDelivery", "POST", req, headers(), "Sandi API Cancel Delivery");
                    let resBody: { msg: string, data: any } = res.getJsonBody();
                    if (!res.success || resBody?.msg?.toLowerCase() != "order has been canceled successfully".toLowerCase()) {
                        throw new ErrorWithData("Sandi Service ::: Could not successfully request to cancel delivery order", res);
                    }
                    return resBody;
                }
            }
        }
    }
}