module PositiveTS {
  export module Components {
    export module SelfServiceHakafa {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreenNoPinpad(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin,Mixins.oneClickMixin, Mixins.selfServicePaymentNoPinpadMixin('selfService.hakafa', Mixins.IMAGES_TYPES.CONTACTLESS)],
          methods: {
            async pay() {
              this.paymentInProcess = true;
              let hakafaCustomerResult = await Service.HakafaCardPayment.searchHakafaCustomer(this.cardNumber);
              let hakafaCustomer = hakafaCustomerResult.result.hakafa_customer;

              if (hakafaCustomer.id != 0) {
                await Service.HakafaCardPayment.selectCustomer(hakafaCustomer);

                let itemsToUpdate = posVC.updatePromotions();
                await posVC.saleUpdated(itemsToUpdate);

                let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);

                let leftToPay = (hakafaCustomerResult.result.meta.obligo ? hakafaCustomerResult.result.meta.obligo : 0) + (hakafaCustomerResult.result.meta.amount ? hakafaCustomerResult.result.meta.amount : 0);

                if (hakafaCustomerResult.result.meta.obligo != null && leftToPay - amount <= 0) {
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('directHakafa.moreThanObligo'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                } else {
                  let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(Service.Hakafa.getVoucherID(), amount, this.cardNumber);
                  if (response.success) {
                    this.goToNextStep();
                  }
                  else {
                    console.error(response.error)
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: response.error,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                    this.setCurrentStep("SelfSelectPaymentMethod")
                  }
                }
              }
              else {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('directHakafa.notExists'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
                this.setCurrentStep("SelfSelectPaymentMethod")
              }

              this.paymentInProcess = false;
            }
          },
        }
      }
    }
  }
}