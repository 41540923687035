module PositiveTS {
   export module Service {
      export module WebsocketSync {
         export class SyncServerClient {
            static DEV_TEST_URLS = {
               'localhost': ['ws://localhost:8626'],
               'lvh.me': ['ws://localhost:8626'],
               // '192.168.1.162': ['ws://192.168.1.162:8626'], // PUT YOUR LOCAL IP TO ACCESS THE SYNC SERVER THROUGH WIFI (NEEDED FOR ACCESSING FROM OTHER DEVICES, LIKE P2PRO)
               'staging.valu.co.il': ['wss://sync-staging.valu.co.il'],
               'staging.pcrs.co.il': ['wss://sync-staging.valu.co.il'],
            } 

            static ERROR_CODES = {
               NO_CONNECTION_TO_WEBSOCKET: 0
            }

            static REQUEST_TIMEOUT = 30 * 1000;
            static ATTEMPTS_TO_FAIL = 3;
            static MINIMUM_TIME_TO_WAIT = 5 * 1000;
            static CONNECTION_STATUSES = {
               DISCONNECTED: 0,
               WAITING_FOR_AUTH: 1,
               CONNECTED: 2
            }

            static instance = null;


            protected baseUrls: string[];
            protected websocket;
            protected waitingTimestamp;
            private connectionStatus = SyncServerClient.CONNECTION_STATUSES.DISCONNECTED;
            protected initialConnectionData = null;
            private keepReconnect = true;
            private isTryingToConnect = false;
            private isConnectionFailed = false;
            protected websocketPath: string = 'dalpaks_socket'
            protected modules: BaseSyncModule[] = [];
            protected isPrimaryPos: boolean;

            constructor() {
               // GET THE CURRECT SYNC-SERVER URL ACCORDING TO THE ENVIROMENT
               this.isPrimaryPos = SyncServerClient.isPrimaryPos();
               let hostname = (new URL(window.location.href)).hostname;
               if (SyncServerClient.DEV_TEST_URLS[hostname]) {
                  this.baseUrls = SyncServerClient.DEV_TEST_URLS[hostname];
               } else {
                  this.baseUrls = jsonConfig.getVal(jsonConfig.KEYS.syncServerUrls).map(url => `wss://${url}`);
               }

               Pinia.globalStore.setsyncServerOnlineState(SyncServerClient.CONNECTION_STATUSES.DISCONNECTED);
               this.waitingTimestamp = 0;
            }

            public addModule(module: BaseSyncModule) {
               this.modules.push(module);
            }


            protected async start() {
               if (this.isTryingToConnect) {
                  return;
               }

               this.isTryingToConnect = true;

               while (this.keepReconnect && this.getConnectionStatus() != SyncServerClient.CONNECTION_STATUSES.CONNECTED) {
                  try {
                     await this.waitMinimumTime();
                     await this.setInitialConnectionDataAndConnect();
                     await this.waitForConnectionToFinish(); 

                     this.isConnectionFailed = false;

                     for (let module of this.modules) {
                        if (module.hasPrimaryPosOfflineManager()) {
                           await module.getPrimaryPosOfflineManager().onConnectionCompleted();
                        }
                     }
                  } catch(err) {
                     this.isConnectionFailed = true;
                     Logger.error(`ERROR CONNECTING DALPAKS. ${err}, IsPrimaryPos: ${this.isPrimaryPos}, IsRailsServerConnected: ${Pinia.globalStore.isOnline}`);
                  };
               }

               this.isTryingToConnect = false;
            }

            protected async setInitialConnectionDataAndConnect() {
               let temp;

               this.initialConnectionData = {
                  isFinished: false,
                  promise: new Promise(async (resolve, reject) => {
                     try {
                        temp = { resolve, reject }
                        await this.connectToWebsocket();
                        await this.afterAuthValidations();
                        this.initialConnectionData.isFinished = true;
                        this.setConnectionStatus(SyncServerClient.CONNECTION_STATUSES.CONNECTED);
                        resolve();
                     } catch (err) {
                        this.terminateAndRestartConnection(err);
                        reject(err);
                     }
                  }),
                  attempts: 0,
               };

               this.initialConnectionData = { ...this.initialConnectionData, ...temp };

               for (let module of this.modules) {
                  await module.setInitialConnectionDataAndConnect();
               }
            }

            private clearInitialConnectionData(err = null) {
               if (this.initialConnectionData && !this.initialConnectionData.isFinished) {
                  this.initialConnectionData.reject(err);
               }

               this.initialConnectionData = null;
            }

            protected setConnectionStatus(newConnectionStatus: number) {
               this.connectionStatus = newConnectionStatus;
               Pinia.globalStore.setsyncServerOnlineState(this.connectionStatus);
            }

            public getConnectionStatus(): number {
               return this.connectionStatus;
            }


            public async waitForConnectionToFinish() {
               if (!this.initialConnectionData) {
                  return Promise.reject('No connection');
               }

               if (!this.initialConnectionData.isFinished) {
                  return await this.initialConnectionData.promise;
               }

               return Promise.resolve();
            }

            protected async handleSyncBeforeReady() {
               let success = true;
               try {
                  for (let module of this.modules) {
                     if (module.hasPrimaryPosOfflineManager()) {
                        success = success && (await module.getPrimaryPosOfflineManager().syncDataFromPrimary());
                     }
                  }

                  if (success) {
                     let res = await this.makeActionAndWaitForResult('primaryReady');
                     success = res.success;
                  }

               } catch (err) {
                  success = false;
               }

               return success;
            }

            protected async moveToOnline() {
               try {
                  for (let module of this.modules) {
                     if (module.hasPrimaryPosOfflineManager()) {
                        module.getPrimaryPosOfflineManager().moveToOnline();
                     }
                  }
               } catch (err) {
                  Service.Logger.error(err);
               }
            }

            protected async makeSureAllDataRecieved() {
               for (let module of this.modules) {
                  await module.waitForInitialData();
               }
            }

            protected async afterAuthValidations() {
               if (this.isPrimaryPos) {
                  let isSuccess = await this.handleSyncBeforeReady();                  

                  if (!isSuccess) {
                     throw new Error("ERR_SYNC_PRIMARY_POS");
                  }

                  await this.makeSureAllDataRecieved();
                  await this.moveToOnline();
               } else {
                  await this.makeSureAllDataRecieved();
               }
            }

            protected async waitMinimumTime() {
               if (Date.now() - SyncServerClient.MINIMUM_TIME_TO_WAIT < this.waitingTimestamp) {
                  await new Promise(resolve => {
                     setTimeout(resolve, this.waitingTimestamp - (Date.now() - SyncServerClient.MINIMUM_TIME_TO_WAIT))});
               }

               this.waitingTimestamp = Date.now();
            }


            public makeActionAndWaitForResult(actionName: string, actionData = null): Promise<any> {
               return new Promise((resolve, reject) => {

                  let isResolved = false;

                  let timeoutHandler = setTimeout(() => {
                     if (!isResolved) {
                        reject('SOCKET ACTION TIMEOUT');
                     }
                  }, SyncServerClient.REQUEST_TIMEOUT);


                  this.websocket.emit(actionName, actionData, (result) => {
                     clearTimeout(timeoutHandler);
                     resolve(result);
                  });
               });
            }

            protected async connectToWebsocket(): Promise<any> {
               let errorToReturn = null;

               for (let i = 0; i < SyncServerClient.ATTEMPTS_TO_FAIL; i++) {
                  for (let url of this.baseUrls) {

                     try {
                        this.websocket = io(url, {
                           path: '/' + this.websocketPath,
                           transports: ['websocket'],
                           query: {
                              version: '2.0',
                              timestamp: Date.now(),
                           },
                           reconnection: false,
                        });
   
   
                        this.setWebsocketEvents();
                        return await this.authenticate();
                     } catch (err) {
                        errorToReturn = err;
                        this.terminate();
                        console.error("connection attempt failed: " + err);
                     }
                  }
               }
               
               throw errorToReturn;
            };

            protected getLoginData() {
               return { token: session.pos.access_token, isPrimaryPos: this.isPrimaryPos };
            }

            protected authenticate(): Promise<any> {
               return new Promise((resolve, reject) => {
                  try {

                     this.websocket.on('connect_error', () => {
                        return reject(`connection error(could not open socket with sync server)`)
                     });

                     this.websocket.on('connect', async () => {

                        this.setConnectionStatus(SyncServerClient.CONNECTION_STATUSES.WAITING_FOR_AUTH);

                        let result;
                        try {
                           result = await this.makeActionAndWaitForResult('auth', this.getLoginData())
                        } catch (err) {
                           return reject(err);
                        }

                        if (!result.success) {
                           let errorText = '';
                           
                           switch (result.errorCode) {
                              case 0:
                                 errorText = '(Server Error)';
                                 break;
                              case 2:
                                 errorText = '(Main Pos Disconnected)';
                                 break;
                           }
                           
                           return reject(new Error(`can't authenticate socket. Error code: ${result.errorCode} ${errorText}`));
                        }

                        return resolve(result);
                     });


                     this.websocket.on('disconnect', () => this.terminateAndRestartConnection());
                  } catch (err) {
                     return reject(err);
                  }
               });
            }

            public async terminateAndRestartConnection(err = null) {
               this.terminate(err);
               this.clearInitialConnectionData(err);
               this.start();
            }

            public async terminate(err = null) {
               if (!this.websocket) {
                  return;
               }

               this.websocket.removeListener('disconnect');
               this.websocket.disconnect();
               this.websocket = null;
               this.setConnectionStatus(SyncServerClient.CONNECTION_STATUSES.DISCONNECTED);
            }



            public async validateConnectionAndDo(action: () => Promise<any>): Promise<any> {
               if (!this.websocket) {
                  await this.start();
               }

               if (this.isConnectionFailed) {
                  return { success: false, errorCode: SyncServerClient.ERROR_CODES.NO_CONNECTION_TO_WEBSOCKET };
               }

               if (this.getConnectionStatus() != SyncServerClient.CONNECTION_STATUSES.CONNECTED) {
                  if (this.initialConnectionData && !this.initialConnectionData.isFinished && this.initialConnectionData.attempts < SyncServerClient.ATTEMPTS_TO_FAIL) {
                     try {
                        await this.waitForConnectionToFinish();
                     } catch (err) {
                        return { success: false, errorCode: SyncServerClient.ERROR_CODES.NO_CONNECTION_TO_WEBSOCKET };
                     }
                  } else {
                     return { success: false, errorCode: SyncServerClient.ERROR_CODES.NO_CONNECTION_TO_WEBSOCKET };
                  }
               }

               return await action();
            }

            protected setWebsocketEvents() {
               for (let module of this.modules) {
                  module.setWebsocketEvents(this.websocket);
               }
            }

            public getModules(): BaseSyncModule[] {
               return this.modules;
            }

            public static getInstance(): SyncServerClient {
               if (!this.instance) {
                  this.instance = new SyncServerClient();
               }

               return this.instance;
            }

            public static startInstanceIfNeeded() {
               if (this.instance && this.instance.modules.length > 0) {
                  this.instance.start();
               }
            }

            public static isPrimaryPos() {
               return jsonConfig.getVal(jsonConfig.KEYS.is_z_master_slave) && jsonConfig.getVal(jsonConfig.KEYS.z_master_slave_primary_pos_device_id) == session.pos.deviceID;
            }
         }
      }
   }
}