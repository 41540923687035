module PositiveTS {
  export module Components {
    export module SelfServiceOtot {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreenNoPinpad(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.oneClickMixin, Mixins.selfServicePaymentNoPinpadMixin('selfService.ototBracelet', Mixins.IMAGES_TYPES.SCAN_TAG, true)],
          mounted(){
            $(document).unbind('keypress');
          },
          methods: {
            async pay() {

              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
  
              let cardNumber = this.cardNumber;
  
              if (posUtils.isNullOrUndefinedOrEmptyString(cardNumber)) {
                return this.setCurrentStep("SelfSelectPaymentMethod");
              }
  
              try {
  
                let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(Storage.Entity.Voucher.SMART_VOUCHER_OTOT, amount, cardNumber, null, { saleItems: posVC.saleItems });
  
                if (posUtils.isBlank(response)) {
                  throw new Error(i18next.t('generalError'));
                }
  
                if (!posUtils.isBlank(response) && !response.success) {
                  throw new Error(response.error);
                }
  
                this.goToNextStep({}, true);
  
              } catch (err) {
                this.paymentInProcess = false
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: err instanceof Error ? err.message : err,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
                this.setCurrentStep("SelfSelectPaymentMethod");
              }
            }
          },
          computed: {
            options() {
              return {
                title: i18next.t('otot.pleaseScanBracelet'),
                charging: i18next.t('otot.chargingBracelet'),
              }
            }
          },
          data() {
            return {
              cardNumber: null,
              contactless: true
            }
          },
        }
      }
    }
  }
}
