module PositiveTS {
    export module Service {
        export module SimplyClubAPI {

            /** Used for SubTotal */
            export interface BaseTransaction {
                TranMember?: TranMember;
                TranItems?: TranItem[];
                TranNumber?: string;
                TranDate?: string;
                /** Assign field with value SysId in customer */
                SellerId?: string;
                OperatorId?: string;
                TranType?: number;
            }

            /** Used for TranEnd & TranEndCheck */
             export interface Transaction extends BaseTransaction {
                /** כולל רשימת מבצעים ושימוש נקודות. */
                TranDiscounts?: TranDiscount[];
                /** OBSOLETE, USELESS FIELD */
                CashbackDifferent?: number;
                /** OBSOLETE, USELESS FIELD */
                TranTenders?: TranTender[];
                /** OBSOLETE, USELESS FIELD */
                AdditionalInfo?: AdditionalInfo3[];
            }

            export interface TranMember {
                MemberType?: number;
                EnterType?: number;
                ClubId?: number;
                CardNumber?: string;
            }

            export interface TranItem {
                TrxID?: number;
                Code?: string;
                Price?: string;
                GrossPrice?: number;
                Amount?: number;
                Quantity?: number;
                UOM?: number;
                Department?: string;
                SubDept1?: string;
                SubDept2?: string;
                SubDept3?: string;
                Vendor?: string;
                Manufacture?: string;
                Cost?: number;
                SysDiscount?: number;
                ItemDescription?: string;
                ItemBarcode?: string;
                SuggestedPromoId?: any;
                PromoCode?: string;
                InputFlags?: number | InputFlags;
                PLUClassificationCode1?: string;
                PLUClassificationCode2?: string;
                PLUClassificationCode3?: string;
                CategoryCode?: string;
                AdditionalInfo?: AdditionalInfo[];
            }

            export interface TranDiscount {
                PromoId: number;
                Amount: number;
                /** 
                * - 0 - הנחה
                * - 1 - CASHBACK נקודות מימוש
                */
                DiscountType: number;
                /** Set 0 */
                POSAmount?: number;
                /** Set 1 */
                Quantity?: number;
                /** Set "" */
                ReferenceCode?: string;
            }

            export interface TranTender {
                TenderType?: number;
                TenderValue?: number;
                Reference?: string;
                AdditionalInfo?: AdditionalInfo[];
            }

            export interface AdditionalInfo3 {
                FieldId?: string;
                FieldValue?: string;
            }
            export enum InputFlags{
                NONE = 0,
                /** יש מבצע על הפריט */
                PROMONTED = 1,
                /** יש הנחה על הפריט */
                DISCOUNTED = 2,
                /** יש הנחה + מבצע על הפריט */
                PROMONTED_AND_DISCOUNTED = 3,
            }

            
        }
    }
}