module PositiveTS {
  export module Service {
    export module ExceptionsReport {
      export function getFromServer(exceptionsReportObject):Promise<string> {
        return new Promise((resolve,reject) => {
          if (posUtils.isBlank(exceptionsReportObject)) {
            throw new Error('ExceptionsReportObject undefined');
          }
        
          $.ajax({
            type: "GET",
            url: Shared.Constants.remoteRoot + 'exceptions_report/',
            data: exceptionsReportObject,
            dataType: 'html',
            cache: false,
            success: function (returnedData) {
              resolve(returnedData as string);
            }
          }).error(function(err) {
            reject(err);
          });
        })
      
    
      };      
    }
  }
}
