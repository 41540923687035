module PositiveTS {
export module Components {
export module MultipassPoliceBalance {

  const _dialogSelector = "positive-multipass-police-balance";
  const _vueComponentName = 'multipass-police-balance';

  export function create() {
    let loginDialog = {
        template: JST.multipassPoliceBalance(),
        methods: {
          async open(cardTrack){
            app.showLoadingMessage(i18next.t('multipassPolice.searching'));
            let multipass = new Service.MultipassPolice();
            let result = <any>await multipass.getBalance(cardTrack)
            if(result.ResultId && result.ResultId == "0") {
              this.points = new Array();
              result.PointsBudgetList.Point.forEach(point => { 
                this.points.push({
                  amount: Number(point.Amount) / 100,
                  type: point.Type,
                  preText: session.pos.isCaveret && result.CompanyId == "1184" ? i18next.t('multipassPolice.pointTypes.stars') : ""
                })});
              this.element = <HTMLDialogElement>window.document.getElementById(_dialogSelector);
              this.element.showModal();
            }
          },
          close: close,
        },
        data: _initData,
    }
    VueApp.component(_vueComponentName,loginDialog)
  }



  function _initData(){
    return {
      points: null,
      element: null
    };
  }

  function close() {
    this.element.close();
  }
}}}
