module PositiveTS {
    export module Components {
      export const enum PosViewState {
        Buttons = 1,
        Rows,
        Actions,
        PastInvoices,
        SearchInvoices,
      }
      export module MobilePosNavigation {
  
        export function create() {
          let component = {
            template: JST.mobilePosNavigation(),
            methods: {
              openPastInvoices() {
                posPastInvoicesVC.currentPage = 0;
                Pinia.globalStore.setMobilePosNavState(PosViewState.PastInvoices);
                pNavigator.pushPage('pos-past-invoices', i18next.t('pageTitle.posPastInvoices'), null, null);
              },
              showButtons() {
                Pinia.globalStore.setMobilePosNavState(PosViewState.Buttons);
                pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
              },
              showItemRows() {
                Pinia.globalStore.setMobilePosNavState(PosViewState.Rows);
                pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
              },
              showSaleActions() {
                Pinia.globalStore.setMobilePosNavState(PosViewState.Actions);
                pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
              },
              openSearchInvoices() {
                Pinia.globalStore.setMobilePosNavState(PosViewState.SearchInvoices);
                pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
              },
              
            },
            data()  {
                return {
                    itemMenu: Boolean(jsonConfig.getVal(jsonConfig.KEYS.useSmartMenu)),
                    isDalpakimOnline: Boolean(jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline)),
                    // rowsOrButtonsState: PosViewState.Buttons,
                }
            },
            computed:{
              isAndroid(){
                return session.isAndroid;
              }
            },
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {standaloneMode,simpleSelfService,mobilePosNavState,mobileLayout,isOnline} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {isOnline,standaloneMode,mobileLayout,simpleSelfService,rowsOrButtonsState:mobilePosNavState} 
              
              return {...globalStoreProps}
            },


            mounted: async function() {
            }
          }
  
          VueApp.component('mobile-pos-navigation',component)
        }
      }
    }
  }
  