module PositiveTS {
   export module Service {
      export class DigitradeService extends ECommerceAPIService {
         static localStorageTokenKey = "DIGITRADE_ECOMMERCE_TOKEN"
         static apiName = "Digitrade"
         
         static get baseUrl () {
            return jsonConfig && !posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.digitradeBaseUrl)) ? 
            jsonConfig.getVal(jsonConfig.KEYS.digitradeBaseUrl) : "https://statos.co/app/api_user.php"
         }

         static getRequestId() {
            return String(session.pos.tenantID) + String(session.pos.companyID) + String(Date.now());
         }

         private static headers() {
            return JSON.stringify({
            })
         }

         protected static async makeLoginRequest(): Promise<ECommerceResponse> {
            const _data = {
               "body": this.convertObjectToFormUrlEncodedFormat({
                  action: "signIn",
                  userName: jsonConfig.getVal(jsonConfig.KEYS.digitradeOrdersAPIUser),
                  userPass: jsonConfig.getVal(jsonConfig.KEYS.digitradeOrdersAPIPassword),
                  resId: jsonConfig.getVal(jsonConfig.KEYS.digitradeOrdersResID),
                  requestID: this.getRequestId()
               }),
               "url": this.baseUrl,
               "type": "POST",
               "headers": this.headers()
            };

            const data = JSON.stringify(_data)

            const response = await GenericAddon.sendNativeMessageToExtension(
               { data: data },
               "external_service"
            )

            Service.Filelog.log("digitrade",
                    JSON.stringify({request:response.request.body.data, result:response.request.result}))

            let eCommerceResponse: ECommerceResponse = {
               success: false
            }

            const result = JSON.parse(response.request.result);

            if (result && result.LogInResponse) {
               if (result.LogInResponse.Success && result.LogInResponse.data && result.LogInResponse.data.Token) {
                  const token = result.LogInResponse.data.Token;

                  eCommerceResponse.success = true
                  eCommerceResponse.data = token

                  this.saveToken(eCommerceResponse);
               }
            }

            return eCommerceResponse
         }

         protected static async makeFetchOrdersRequest(): Promise<ECommerceResponse> {
            let eCommerceResponse: ECommerceResponse = {
               success: false
            }

            let token = await this.getToken();
            if(!token) {
               return eCommerceResponse;
            }
            const _data = {
               "body": this.convertObjectToFormUrlEncodedFormat({
                  action: "GetTodaysOrders",
                  token: token,
                  status: "Pending",
                  requestID: this.getRequestId(),
               }),
               "url": this.baseUrl,
               "type": "POST",
               "headers": this.headers()
            };

            const data = JSON.stringify(_data)

      

            const response = await GenericAddon.sendNativeMessageToExtension(
               { data: data },
               "external_service"
            )

            Service.Filelog.log("digitrade",
                    JSON.stringify({request:response.request.body.data, result:response.request.result}))

            //No orders found, for some reason digitrade returns empty json response
            if(response.request.result == "{}" || response.request.result == ""){
               eCommerceResponse.success = true
               return eCommerceResponse
            }

            const result = JSON.parse(response.request.result);

            if (result && result.GetPendingOrdersResponse) {
               if (result.GetPendingOrdersResponse.Success) {
                  eCommerceResponse.success = true
                  eCommerceResponse.data = result.GetPendingOrdersResponse.data
               }
               else {
                  eCommerceResponse.success = false
                  eCommerceResponse.data = result.GetPendingOrdersResponse.Error
               }

            }

            return eCommerceResponse

         }

         private static async makePayment(sale, items,saleTotals) {
            try {
               let token = await this.getToken();
               let saleData = JSON.parse(sale.jsondata);
               let  saleDetails =  {
                  orderId: Number(saleData.delivery.externalOrderId),
                  FinalOrderPrice: Number(saleTotals.totalAmount),
                  Products: items.filter((item) => item.itemCode != jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)).map((item) => { return {
                     CatalogNumber: String(item.itemCode),
                     NewQuantity: String(item.quantity),
                     FinalRowTotal: session.fixedNumber(item.quantity * item.unitPrice),
                     Type: Number(!item.hasWeight)
                  } })
               }
               const data = {
                  "body": this.convertObjectToFormUrlEncodedFormat({
                     action: "UpdateOrder",
                     token: token,
                     OrdDet: JSON.stringify(saleDetails),
                     requestID: this.getRequestId(),
                  }),
                  "url": this.baseUrl,
                  "type": "POST",
                  "headers": this.headers()
               };

               const response = await GenericAddon.sendNativeMessageToExtension(
                  { data: JSON.stringify(data) },
                  "external_service"
               )

               Service.Filelog.log("digitrade",
                    JSON.stringify({request:response.request.body.data, result:response.request.result}))
               
               const result = JSON.parse(response.request.result);
               if(result.UpdateOrderStatusResponse && result.UpdateOrderStatusResponse.Success) {
                  return true;
               } else {
                  console.error("DIGITADE BAD RESPONSE");
                  console.error(result);
                  return false;
               }
            } catch (error) {
               console.error(error);
               return false;
            }
         }

         public static async finalizeSale(sale, items) {
            let saleTotals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);

            const paymentSuccess = await DigitradeService.makePayment(sale,items,saleTotals);
            const saleJsondata = JSON.parse(sale.jsondata);
            if(paymentSuccess) {
               //update external order on server
               await PositiveTS.Service.FetchReq.jsonReq(`/external_orders/${saleJsondata.delivery.remoteDBId}`, 
                 'put', {status: Service.Delivery.ExternalOrderStatus.ReadyForShipment});
     
               //close sal
               const payment = await Service.ECommerceAPIService.getPaymentForJ5Pay(sale, saleTotals.totalAmount);
               posVC.salePayments.push(payment)
               await Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
               await PositiveTS.Service.CloseSale.closeSale();
               await posVC.createNewSale()
               } else {
                  throw Error("Digitrade PaymentError");
               }
         }

         protected static async updateOrderStatus(orderId, newStatus): Promise<ECommerceResponse> {
            let token = await this.getToken();

            const _data = {
               "body": this.convertObjectToFormUrlEncodedFormat({
                  action: "ChangeStandardOrderStatus",
                  token: token,
                  orderId: orderId,
                  newStatus: newStatus,
                  requestID: this.getRequestId(),
               }),
               "url": this.baseUrl,
               "type": "POST",
               "headers": this.headers()
            };

            const data = JSON.stringify(_data)

            let eCommerceResponse: ECommerceResponse = {
               success: false
            }

            const response = await GenericAddon.sendNativeMessageToExtension(
               { data: data },
               "external_service"
            )

            Service.Filelog.log("digitrade",
                    JSON.stringify({request:response.request.body.data, result:response.request.result}))

            const result = JSON.parse(response.request.result);

            if (result && result.ChangeStandardOrderStatusResponse) {
               if (!result.ChangeStandardOrderStatusResponse.Error || result.ChangeStandardOrderStatusResponse.Error == false) {
                  eCommerceResponse.success = true
               }
               else {
                  eCommerceResponse.success = false
                  eCommerceResponse.data = result.GetPendingOrdersResponse.Error
               }

            }

            return eCommerceResponse
         }

         protected static async getVoucherForPayment(possibleVouchers:Array<Storage.Entity.Voucher>, sale:Storage.Entity.Sale, order:Storage.Entity.ExternalOrder)
         : Promise<Storage.Entity.Voucher> {
            return possibleVouchers.find(voucher => voucher.typeID == jsonConfig.getVal(jsonConfig.KEYS.digitradeVoucherNumber))
         }

         private static async fetchSingleOrder(orderId) {
            let token = await this.getToken();

            const _data = {
               "body": this.convertObjectToFormUrlEncodedFormat({
                  action: "GetSingleOrder",
                  token: token,
                  orderId: orderId,
                  requestID: this.getRequestId(),
               }),
               "url": this.baseUrl,
               "type": "POST",
               "headers": this.headers()
            };

            const data = JSON.stringify(_data)

            let eCommerceResponse: ECommerceResponse = {
               success: false
            }

            const response = await GenericAddon.sendNativeMessageToExtension(
               { data: data },
               "external_service"
            )

            Service.Filelog.log("digitrade",
                    JSON.stringify({request:response.request.body.data, result:response.request.result}))

            const result = JSON.parse(response.request.result);

            if (result && result.GetSingleOrderResponse) {
               eCommerceResponse.success = true
               eCommerceResponse.data = result.GetSingleOrderResponse.data
            }
            return eCommerceResponse
         }


         protected static async convertApiOrderToPosExternalOrder(result: any): Promise<Array<PositiveTS.Storage.Entity.ExternalOrder>> {
            let externalOrdersArray = [];
            
            if(!result){
               return externalOrdersArray
            }

            ordersLoop: for (let apiOrder of result.OrderLineElement) {


               let convertSuccessfull = true

               let singleOrder = await this.fetchSingleOrder(apiOrder.OrderID)

               if (!singleOrder.success) {
                  console.error(`Error fetching single order, e-commerce server:${this.apiName}\n data: ${singleOrder.data}`)
                  continue
               }

               let singleOrderData = singleOrder.data.Order
               let singleOrderAddress = singleOrderData.address

               let externalOrder = new Storage.Entity.ExternalOrder()
               externalOrder.orderNum = posVC._getNewOrderNumber()
               externalOrder.totalAmount = apiOrder.TotalSum
               externalOrder.deliveryType = this.convertDeliveryTypeFromDigitrade(apiOrder.DeliveryMethod)
               externalOrder.orderOrigin = Service.Delivery.ExternalOrderOrigin.Digitrade
               externalOrder.orderType = Service.Delivery.ExternalOrderType.SaleItemsFormat
               externalOrder.externalOrderId = apiOrder.OrderID
               externalOrder.date = moment(apiOrder.OrderSubmittedTime,"DD-MM-YYYY | HH:mm:ss").toDate().toString()
               externalOrder.status = Service.Delivery.ExternalOrderStatus.New
               externalOrder.paymentToken = singleOrderData.CardVoucher
               externalOrder.remarks = singleOrderData.OrderRemarks
               externalOrder.customerName = singleOrderData.user.name
               externalOrder.phone = singleOrderAddress.Cellular
               externalOrder.apartmentNumber = singleOrderAddress.ApartementNumber
               externalOrder.city = singleOrderAddress.CityName
               externalOrder.entrance = singleOrderAddress.Enterance
               externalOrder.floor = Number(singleOrderAddress.Floor) || 0
               externalOrder.houseNumber = Number(singleOrderAddress.HouseNumber) || 0
               externalOrder.street = singleOrderAddress.StreetName
               externalOrder.syncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT
               externalOrder.paid = !DigitradeService.isJ5Payment();
               externalOrder.saleItems = []

               let items = singleOrderData.dishList.DishToSubmitDTO
               let itemsCount = 0;
               for (let orderItem of items) {
                  let currentItem = session.allItems.get(orderItem.CatalogId)

                  if (Boolean(currentItem)) {
                     let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });
                     saleItem.quantity = orderItem.Quantity;
                     saleItem.unitPrice = orderItem.TotalPrice / orderItem.Quantity;
                     saleItem.originalUnitPrice = orderItem.DishPrice;
                     saleItem.rowNumber = itemsCount++;
                     saleItem.priceNetoAfterDiscounts = orderItem.TotalPrice
                     if (orderItem.DishNotes != "" && orderItem.DishNotes != null && !_.isEmpty(orderItem.DishNotes)) {
                        saleItem.hasPreparationInstructions = true;
                        saleItem.selectedPreparationInstructions = JSON.stringify([orderItem.DishNotes]);
                     }
                     if(currentItem.hasWeight && orderItem.ProductType == 1) {
                        saleItem.quantityInUnits = true;
                     }

                     externalOrder.saleItems.push(saleItem)
                  }
                  else {
                     console.error(`Error adding sale item, e-commerce server:${this.apiName}\n Item missing ID: ${orderItem.CatalogId}`)
                     continue ordersLoop
                  }

               }
               if(singleOrderData.BillingInfo && singleOrderData.BillingInfo.BillingLines){
                  const billingLines = singleOrderData.BillingInfo.BillingLines.BillingLine
                  for (let index = 0; index < billingLines.length; index++) {
                     const billingLine = billingLines[index];
                     if(billingLine.Type == "DeliveryCharge"){
                        let deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));   
                        deliveryItem.priceZarhan = Number(billingLine.Amount)

                        let deliveryItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(deliveryItem, {size:'null',color:'null',barcode:null});
                        deliveryItm.priceNetoAfterDiscounts = deliveryItem.priceZarhan
                        externalOrder.saleItems.push(deliveryItm)
                     }
                     
                  }
               }

               externalOrdersArray.push(externalOrder)

               return externalOrdersArray;
            }
         }

         static convertDeliveryTypeFromDigitrade(deliveryType) {
            //TODO DIGITRADE - add more types once we support it
            let mapObj = {
               Delivery: Service.Delivery.DeliveryType.delivery,
               PickUp: Service.Delivery.DeliveryType.ta
            }

            return mapObj[deliveryType];
         }
      }
   }
}
