module PositiveTS {
    export module Service {
        export module AndroidNativeMessage {
            export module Scale {
                export function sendNativeMessage(jsonData){
                    return new Promise((resolve, reject) => {
                        setTimeout(function(){
                            let jsonDataParsed = JSON.parse(jsonData.data);
                            if(jsonDataParsed.cmd == "W"){
                                if(jsonDataParsed.com == Service.Scale.S2Scale){
                                    let net = Android.getBuiltInScaleNet();
                                    if(net != -1){
                                        let resultJSON = {
                                            request:{
                                                result: String(net/1000)
                                            }
                                        }
                                        resolve(resultJSON);
                                    }
                                    else{
                                        let resultJSON = {
                                            request:{
                                                result: "ERROR: Scale not stable or connected"
                                            }
                                        }
                                        resolve(resultJSON);
                                    }
                                }else{
                                    Android.ReadFromSerial();
                                    Android.WriteToSerial("w");
                                    let resultJSON = {
                                        request:{
                                            result: Android.ReadFromSerial()
                                        }
                                    }
                                    if(posUtils.isNullOrUndefinedOrEmptyString(resultJSON.request.result)){
                                        resultJSON.request.result = "ERROR: Android can't read serial";
                                    }
                                    
                                    resolve(resultJSON); 
                                    }
                                }
                                else if(jsonDataParsed.cmd == "z"){
                                    if(jsonDataParsed.com == Service.Scale.S2Scale){
                                        Android.ZeroScale();
                                    }
                                };
                        },0);
                    });
                }
            }
        }
    }
}