module PositiveTS {
  export module Components {
  export module OfflineSalesDialog {
  
    const _vueComponentName = 'offline-sales-dialog';

    export function create() {
      let offlineSalesDialog = {
          template: JST.offlineSalesDialog(),
          components: {
            SelectableTable: SelectableTable.getComponent()
          },
          methods: {

            open() {
              app.showLoadingMessage(i18next.t("loading"))
              this.getOfflineSales();
              this.$el.showModal();
              app.hideLoadingMessage();
            },
            close() {
              this.$el.close();
            },
            async getOfflineSales() {
              let sales = await appDB.sales.where('syncStatus').equals(3).toArray()
              this.rows = sales.map((sale) => ({invoiceSequence: sale.invoiceSequence, error:sale.syncError, errorTitle: Storage.Entity.SyncError.getTitleByError(sale.syncError)}))
              this.selectedError = this.rows[0];
            },
          },
          data() {
            return {
              columns: [
                { key:"invoiceSequence", title: i18next.t("offlineSalesDialog.invoiceSequenceTitle") },
                { key:"errorTitle", title: i18next.t("offlineSalesDialog.errorTitle")}
              ],
              rows: [],
              selectedError: null
            };
          },
          computed: {
            selectedErrorTitle() {
              if(this.selectedError?.error) {
                return Storage.Entity.SyncError.getTitleByError(this.selectedError?.error)
              }
              return ""
            },
            selectedErrorDescription() {
              if(this.selectedError?.error) {
                return Storage.Entity.SyncError.getDescriptionByError(this.selectedError.error);
              }
              return ""
            },
            physicalId() {
              return session.pos.physicalID;
            },
            
          }
      }
      VueApp.component(_vueComponentName,offlineSalesDialog);
      return offlineSalesDialog;
    }
  }}}
