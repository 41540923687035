module PositiveTS {
  export module Components {
    export module SelfServicePayment {

      
      const _vueComponentName = 'self-service-payment-dialog';

      export function create() {
        let selfServicePaymentDialog = {
          template: JST.selfServicePayment(),
          mixins: [Mixins.modalsMixin, Mixins.oneClickMixin],
          components: {
            SelfServiceEmv: SelfServiceEmv.getComponent(),
            SelfServiceAshrait: SelfServiceAshrait.getComponent(),
            SelfServiceTenbis: SelfServiceTenbis.getComponent(),
            SelfServiceCibus: SelfServicePluxee.getComponent(),
            SelfServiceGoodi: SelfServiceGoodi.getComponent(),
            SelfSelectPaymentMethod: jsonConfig.getVal(jsonConfig.KEYS.isAlignSelfService) ? SelfServiceAlignScreen.getComponent() : SelfSelectPaymentMethod.getComponent(),
            SelfSelectCustomer: SelfSelectCustomer.getComponent(),
            SelfServiceChargeableCard: SelfServiceChargeableCard.getComponent(),
            SelfServiceHakafa: SelfServiceHakafa.getComponent(),
            SelfServiceCustomerClubPaymentMethod: selfServiceHakafaCustomerPaymentMethod.getComponent(),
            SelfServiceTheftDetectApproval: SelfServiceTheftDetectApproval.getComponent(),
            SelfServiceAfterSaleScreen: SelfServiceAfterSaleScreen.getComponent(),
            SelfServiceCreditPaymentMethod: SelfServiceCreditPaymentMethod.getComponent(),
            SelfServiceHoger: SelfServiceHoger.getComponent(),
            SelfServiceGpp: SelfServiceGpp.getComponent(),
            SelfServiceCaveret: SelfServiceCaveret.getComponent(),
            selfServiceLoadCard: selfServiceLoadCard.getComponent(),
            SelfServiceOtot: SelfServiceOtot.getComponent(),
            SelfServiceSafeCash: SelfServiceSafeCash.getComponent(),
            SelfServiceCash: SelfServiceCash.getComponent(),
          },
          methods: {
            async setNextStep(data: object = {}, updatePaymentInProcess = false) {
              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              if (totals.totalPaid >= totals.totalAmount) {
                  if(jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableAfterSaleScreen)){
                    this.closeWithAfterSaleScreen()
                  }else{
                    await this.applyAndCloseWizard();
                    if (updatePaymentInProcess){
                      this.paymentInProcess = false
                    }
                  }
              }
              else {
                this.setCurrentStep('SelfSelectPaymentMethod')
              }
            },
            async open() {
              $(document).unbind('keypress');
              if(Service.Otot.isOtotActive()){
                Pinia.globalStore.setOtotSaleBracelet({
                  bracelet: null,
                  status: Service.Otot.BRACELET_STATUS.INVALID
                })
                let resultBraceletToLoad = await Service.Otot.getNeededBraceletsForSale();
                if(resultBraceletToLoad.status === Service.Otot.BRACELET_STATUS.INVALID){
                  this.close();
                  return;
                } else if(resultBraceletToLoad.status === Service.Otot.BRACELET_STATUS.VALID){
                  try{
                    let bracelet = resultBraceletToLoad.bracelet;
                    let bracletTocheck = null
                    if(typeof bracelet === "string"){
                      bracletTocheck = bracelet;
                    }
                    if(typeof bracelet === "object"){
                      const keys = Object.keys(bracelet);
                      bracletTocheck = bracelet[keys[0]];
                      if(Array.isArray(bracletTocheck)){
                        bracletTocheck = bracletTocheck[0];
                      }
                    }
                    await Service.Otot.getFullBalance(bracletTocheck);
                  } catch (error){
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: error,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                    this.close();
                    return;
                  }
                }
              }

              if(jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos) && jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableTheftDetection)){                
                let checkIfNeedManagerApprove = await PositiveTS.Service.TheftDetection.checkIfSaleNeedToBeApproved();
                  if(checkIfNeedManagerApprove){
                    this.setCurrentStep('SelfServiceTheftDetectApproval')
                  }else{
                    this.setCurrentStep('SelfSelectPaymentMethod')
                  }
              } 
              else if(jsonConfig.getVal(jsonConfig.KEYS.alwaysGetDedicatedTo) && !jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowCustomerNameAfterPaymentMethod)){
                  this.setCurrentStep('SelfSelectCustomer')
                }
                else{
                  this.setCurrentStep('SelfSelectPaymentMethod')
                }

              if (this.showPosHeader){
                this.$el.show();
              }else{
                this.$el.showModal();
              }
              
              Pinia.globalStore.setOnSelfServicePaymentDialog(true)

            },
            close() {
              this.setCurrentStep(null)
              this.$el.close();
              Pinia.globalStore.setOnSelfServicePaymentDialog(false)
              if(this.isSelfServiceSuperMarket) {
                document.getElementById('pos-search-field').focus()
              }
            },
            async goBack() {
              if((this.currentComponent == "SelfSelectPaymentMethod") && 
              (jsonConfig.getVal(jsonConfig.KEYS.alwaysGetDedicatedTo) && 
              ! jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowCustomerNameAfterPaymentMethod))){
                this.setCurrentStep('SelfSelectCustomer')
              }
              else if(this.currentComponent == "SelfSelectCustomer" && jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowCustomerNameAfterPaymentMethod)){
                this.setCurrentStep('SelfSelectPaymentMethod')
              }
              else if((this.currentComponent == "SelfSelectPaymentMethod") || (this.currentComponent == "SelfSelectCustomer")) {
                if (this.selfServiceQuickSale) {
                  await posVC.restartSale();
                }
                this.close()
              }
              else if(this.currentComponent == "SelfServiceTheftDetectApproval") {
                this.close()
              }
              else if(this.currentComponent == "SelfServiceCreditPaymentMethod") {
                this.setCurrentStep('SelfSelectPaymentMethod')
              }
              else if(this.currentComponent == "SelfServiceCaveret") {
                this.setCurrentStep('SelfSelectPaymentMethod')
              }
              else if(this.currentComponent == "SelfServiceGpp") {
                this.setCurrentStep('SelfSelectPaymentMethod')
              }
            },
            async closeSale() {
              if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceSittingOrTaScreen) && this.isSelfServiceTa) {
                await Service.Delivery.setDeliveryType(Service.Delivery.DeliveryType.ta);
              }
              closeSaleDg.setDataAndVerify();
              let isSetUpSelfServiceAlignPrintingSettings: boolean = jsonConfig.getVal(jsonConfig.KEYS.isAlignSelfService) || jsonConfig.getVal(jsonConfig.KEYS.selfServiceAlignPrintingSettings);
              if (isSetUpSelfServiceAlignPrintingSettings && posVC.salePayments[0].method == 1) { // only for Align and credit card payment
                let saleData = {...JSON.parse(posVC.sale.jsondata), printReceipt: await Pinia.componentsStore.openComponent( {componentName:"printReceiptDialog", args: []})};
                posVC.sale.jsondata = JSON.stringify(saleData);
              }
		          await Service.CloseSale.closeSale();
              Pinia.globalStore.setPosState("pos")
              posVC.stop();
              posVC.init();
              posVC.resume();
            },
            async applyAndCloseWizard() {
              await this.closeSale();
              this.clearWizard();
              this.close();
            },
            async closeWithAfterSaleScreen(){
              this.setCurrentStep('SelfServiceAfterSaleScreen');
              await this.closeSale();
              let afterSaleScreenDuration: number = jsonConfig.getVal(jsonConfig.KEYS.selfServiceAfterSaleDuration);
              if (afterSaleScreenDuration <= 0) afterSaleScreenDuration = 0.5;
              await new Promise((res) => { setTimeout(res, afterSaleScreenDuration * 1000) });
              this.clearWizard();
              this.close();
            },
            clearWizard() {
              this.step = 0;
              this.wizardStepsData = [];
              this.setIsAfterPaymentPage(false);
            },
            getDefaultWizardData() {
              return {selectedLeg: this.nextUnopenedLeg};
            },
            toggleLanguageSelection() {
              this.displayLangSelector = !this.displayLangSelector;
            },
          },
          computed: {
            displayLanguageSelector(): boolean {
              return ['SelfSelectPaymentMethod', 'SelfSelectCustomer'].includes(this.currentComponent);
            },
            isAlignSelfService () {
              return jsonConfig.getVal(jsonConfig.KEYS.isAlignSelfService)
            },

            showOnTop () {
              return this.isAlignSelfService && this.currentComponent != 'SelfSelectPaymentMethod' 
            },

            showPosHeader () {
              return this.isAlignSelfService && this.currentComponent == 'SelfSelectPaymentMethod' 
            },          
          },
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {selectedPaymentMethod,currentComponent} = Pinia.storeToRefs(selfServicePaymentStore);

            const {setCurrentStep,setIsAfterPaymentPage} = selfServicePaymentStore;

            const globalStore = Pinia.useGlobalStore();
            const {isSelfServiceTa,selfServiceQuickSale,selfServiceSuperMarket} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {isSelfServiceTa,selfServiceQuickSale,isSelfServiceSuperMarket:selfServiceSuperMarket} 

            return {...globalStoreProps,selectedPaymentMethod,currentComponent,setCurrentStep,setIsAfterPaymentPage}
          },
          data() {
            return {
              step: 0,
              wizardStepsData: [],
              displayLangSelector: false,
            };
          },
          watch: {
            // step() {
            //   if (this.step < 0) {
            //     this.step = 0;
            //     this.close();
            //   }

            //   if (this.step >= this.currentStepsComponets.length) {
            //     this.finishWizard();
            //   }
            // }
          },
        }
        VueApp.component(_vueComponentName, selfServicePaymentDialog)
      }
    }
  }
}
