module PositiveTS {
    export module Service {
        export module AndroidAddon {

            export async function sendNativeMessageToExtension(jsonData, addonMethod?, splashMsg?): Promise<any> {
                if (splashMsg) {
                    app.showLoadingMessage(splashMsg);
                }
                let response;


                switch(addonMethod) {
                    case "scale":
                        response = await AndroidNativeMessage.Scale.sendNativeMessage(jsonData)
                        break;
                    case "external_service":
                        response = await AndroidNativeMessage.ExternalService.sendNativeMessage(jsonData)
                        break;
                    case "http_service":
                        response = await AndroidNativeMessage.NativeHttpService.sendNativeMessage(jsonData)
                        break;
                    case "emv":
                        response = await AndroidNativeMessage.EMV.sendNativeMessage(jsonData)
                        break;
                    case "emv_atm":
                        response = await AndroidNativeMessage.EMV.sendNativeMessage(jsonData, "53", "02")
                        break;
                    default:
                        response = ""
                  }


                if (splashMsg) {
                    app.hideLoadingMessage();
                }

                return response
            }

            export function fixKeyboardPanning() {
                $(document).on("focus", "input, textarea", function () {
                    if (!$(this).data('nosoftkeyboard')) { //for elements with naitive android keyboard
                        let domRect = $(this)[0].getBoundingClientRect();
                        let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                        let offset = 0;
                        if ($(this).data('keyboardoffset')) {
                            offset = $(this).data('keyboardoffset');
                        }

                        if (!$(this).data('disableselectall')) {
                            $(this).select();
                        }
                        Android.setFocusInputPosition(domRect.bottom, domRect.top, h, offset);

                    }
                    else { //for elements with on screen keypad
                        $(this).attr('readonly', 'true');
                    }
                });
            }
        }
    }
}