module PositiveTS {
export module Helper {
export module SaleHelper {

	export function doesSaleHasDiscount (sale:Storage.Entity.Sale):boolean {
		
		if (sale.useNewPromotions) {
			try {
				let jd = JSON.parse(sale.jsondata);
				let salePromotionsAndDiscounts = jd.promotions;
				if (posUtils.isBlank(salePromotionsAndDiscounts)) {
					return false;
				}
				let hasSaleDiscountPromotion = salePromotionsAndDiscounts.findIndex(pr => pr.isDiscount && pr.isSaleDiscount) != -1;
				return hasSaleDiscountPromotion;
			}
			catch(e) {
				console.error(e);
				return false;
			}
		}
		else {
			return !PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(sale) && !Number.isNaN(Number(sale.discountPercent)) && Number(sale.discountPercent) > 0;
		}
	}

	export function doesSaleHasPromotion (sale:Storage.Entity.Sale):boolean {
		if (sale.useNewPromotions) {
			try {
				let jd = JSON.parse(sale.jsondata);
				let salePromotionsAndDiscounts = jd.promotions;
				if (posUtils.isBlank(salePromotionsAndDiscounts)) {
					return false;
				}
				let activePromotions = session.allPromotions.filter(p => salePromotionsAndDiscounts.map(pr => pr.promotionCode).includes(p.code))
				let hasSalePromotion = activePromotions.findIndex(ap => Promotions.NewPromotionsEngine.isSalePromotion(ap)) != -1;
				return hasSalePromotion;
			}
			catch(e) {
				console.error(e);
				return false;
			}
		}
		else {
			return !posUtils.isNullOrUndefinedOrEmptyString(sale.promotionCode);
		}
	}

	export function getJsonData(sale) {
		var jsondata = JSON.parse(sale.jsondata);
		if (!jsondata) {
			return {};
		}
		return jsondata;
	}

	export function setJsonData(sale,jsondata) {
		sale.jsondata = JSON.stringify(jsondata);
	}

	export function initPromotionGroupJsonData(sale) {
		let aThis = PositiveTS.Helper.SaleHelper;
		var jsondata = aThis.getJsonData(sale);
		jsondata.promotions = [];
		aThis.setJsonData(sale,jsondata);
	}

	export function addPromotionGroupToJsonData(sale, promotionGroup) {
		let aThis = PositiveTS.Helper.SaleHelper;
		var jsondata = aThis.getJsonData(sale);
		jsondata.promotions.push(promotionGroup);
		aThis.setJsonData(sale,jsondata);
	}

	export function removeSalePromotionFromSale (sale) {
		if (PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(sale)) {
			sale.discountPercent = '-1';
			sale.saleDiscountAmount = 0;
			sale.discountName = '';
			sale.promotionCode = '';
			sale.saleDiscountAllowedWithOtherPromotions = false;
			sale.clubMemberPromotionType = undefined;
			var jd = JSON.parse(sale.jsondata);
			delete jd.salePromotion;
			sale.jsondata = JSON.stringify(jd);
		}
	}

  export function findPaymentIdByMethod (salePayments, method) {
    // Try to find a current change payment type
    var payment = null;

    for (var i = 0; i < salePayments.length; i++) {
      // Get the payment
      var salePayment = salePayments[i];

      if (salePayment.method === method) {
        payment = i;
      }
    }

    return payment;
  }

	export function findPaymentByMethod (salePayments:Storage.Entity.SalePayment[], method:number):Storage.Entity.SalePayment {
		// Try to find a current change payment type
		let payment = null;

		for (let i = 0; i < salePayments.length; i++) {
			
			if (salePayments[i].method === method) {
				payment = salePayments[i];
				break;
			}
		}

		return payment;
	}

	// Sync
	export function getTotalPaidByMethod (salePayments, method) {
		var paid = 0;
		for (var i = 0; i < salePayments.length; i++) {
			// Get the payment
			var salePayment = salePayments[i];

			if (salePayment.method === method) {
				paid += salePayment.amount;
			}
		}

		return paid;
	}

	export function calcuateChange (totalAmount, totalPaid, paidCash, round?) {

		if (session.pos.hasFlights) {
			let totalPaidMC = Storage.Entity.SalePayment.sumPaymentsData(posVC.salePayments)
			let change = session.fixedFloat(totalAmount - totalPaidMC)
			if (Math.abs(totalAmount - totalPaidMC) < 0.01) { //if the diff is smaller than one cent - treat it as no change
				change = 0;
			}
			return {
				cash: change,
				creditVoucher: 0,
				creditCard: 0,
				totalChange: change,
				roundedCash: 0,
			}
		}
		// Calculate the change amount
		let changeAmount = 0;
		if(round) {
			changeAmount = totalAmount - (totalPaid + round);
		} else {
			changeAmount = totalAmount - totalPaid;
		}

		var changeInChash = 0;
		var changeInCreditVoucher = 0;

		// calcuate change in cash and change in creditVoucher
		if (changeAmount < 0) {
			if (paidCash >= -1 * changeAmount) {
				changeInChash = changeAmount;
			} else {
				changeInChash = -1 * paidCash;

				changeInCreditVoucher = changeAmount - changeInChash;

				// if ther is no change in cash and changeInCreditVoucher is less then 10 nis (admin parameter)
				// return the change in cash
				if (changeInChash == 0 && (-1 * changeInCreditVoucher) <= session.pos.parameterMinimumChangeInCreditVoucher) {
					changeInChash = changeInCreditVoucher;
					changeInCreditVoucher = 0;
				}
			}
		}

		let roundChange = session.pos.isILSBasedCurrency ? session.fixedFloat(session.fixedFloat(changeInChash, 2) - session.fixedFloat(changeInChash, 1), 2) : 0;
		if(roundChange != 0) {
			posVC.sale.roundAmount = roundChange;
			changeInChash = session.fixedFloat(changeInChash, 1);
		}
		return {
			cash: changeInChash,
			creditVoucher: changeInCreditVoucher,
			creditCard: 0,
			totalChange: changeAmount,
			roundedCash: roundChange,
		};		
	}

	export async function closeDebitSaleWithSequence (sale:Storage.Entity.Sale, saleItems, salePayments) {

		// if it is receipt then overide default sequence type
		var entitySequence = PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
		if (PositiveTS.Service.PunchCard.isPayPunchCardSale(sale)){
			entitySequence = PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE
		}
		// if punch card 

		if ( PositiveTS.Service.SmartVoucher.isLoadCardSale(saleItems,posVC.Specialitems) ) {
			entitySequence = PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT;
			sale.totalVatableAmount = 0
		}

		let specialItem = PositiveTS.Service.SmartVoucher.getSpecialItemOrNull(posVC.saleItems,posVC.Specialitems);
		let hakafaVoucher = await  PositiveTS.Service.Hakafa.getHakafaVoucher()
		
		if (hakafaVoucher && !Boolean(hakafaVoucher.isDiscountVoucher)) {
			if ( specialItem && specialItem.hndlr === PositiveTS.Service.Hakafa.CLUB_IDENTIFIER  ){
				entitySequence = PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT;
				sale.totalVatableAmount = 0
			}
		}

		if(jsonConfig.getVal(jsonConfig.KEYS.useCreditInvoiceOnMinusSale) && Pinia.globalStore.totalAmount < 0) {
			entitySequence = PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE
		}
			
		if(Service.MultipassLoadBudget.posHasMultipassLoad() && Service.MultipassLoadBudget.saleItemsContainLoadBudget(saleItems)) {
			entitySequence = PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT;
		}
			
		let sequence = await PositiveTS.Storage.Entity.Sequence.getSequenceForInvType(entitySequence)
				
		sequence.sequence = sequence.sequence + 1;

		sale.createdAt = PositiveTS.DateUtils.fullFormat(); //TODO: why is this here and not in close sale??

				// Close the sale
		let result = await sale.closeSale(sequence, saleItems, salePayments);
		return result;

	}

	export function doesSaleContainAllCustomerDetails (sale) {
		return (!posUtils.isNullOrUndefinedOrEmptyString(posVC.sale.customerID))
			&&
			(!posUtils.isNullOrUndefinedOrEmptyString(posVC.sale.customerName))
			&&
			(!posUtils.isNullOrUndefinedOrEmptyString(posVC.sale.customerPhone));
	}

	export async function loadSalesWithoutZReportFromServerToLocal () {
		// load sales without z report from the server and then save them
		let sales = await Service.Sale.GetSalesWithoutZReport(session.pos.deviceID)
		let promises = [];

		localStorage.setItem("failedXFields",JSON.stringify(new Array()));

		sales.forEach(sale => {
			sale.posPhysicalID = session.pos.physicalID;
			sale.zNumber = Storage.Entity.Sale.NULL_Z_REPORT;
			promises.push(Service.FullSale.persist(sale,sale.items,sale.payments,true));
		})
		await Promise.all(promises);
		return;
		
	}
	export function exportArrayToObjects (entities) {
		if (entities === null || entities === undefined) {
			return [];
		}

		var objects = [];
		for (var i = 0; i < entities.length; i++) {
			objects.push(entities[i].exportToObject());
		}
		return objects;
	}
	export function calculateVat (amount:number, vat:number) {
		amount = session.fixedFloat(amount);
		return amount - (amount / ((vat / 100) + 1));
	}
	// saleTotals = { totalAmount, totalDiscount, totalQuantity, totalPaid }
	export function validateSaleTotals (saleTotals, isFinalSaleCredit = false) {
		var returnObj = {
			valid: false,
			message: 'not finished yet'
		};

		var minToPay = session.fixedFloat(saleTotals.totalAmount - 0.1);
		var maxToPay = session.fixedFloat(saleTotals.totalAmount + 0.1);

		if (saleTotals.totalAbsoluteQuantity <= 0 && saleTotals.tipAmount == 0) {
			returnObj.message = 'closeSaleNoItems';
		} else if (isFinalSaleCredit) {
			returnObj.valid = true;
			return returnObj;
		} else if (saleTotals.totalPaid >= minToPay && saleTotals.totalPaid <= maxToPay) {
			returnObj.valid = true;
		} else if (saleTotals.totalPaid <= 0 && saleTotals.totalPaid >= minToPay && saleTotals.totalPaid <= maxToPay) {
			returnObj.valid = true;
		} else if (saleTotals.totalAmount > saleTotals.totalPaid) {
			returnObj.message = 'closeSaleMissingPayments';
		} else {
			returnObj.message = 'closeSaleMissingItems';
		}

		// if quantity is undefined or zero
		// returnObj.valid = false;
		if ( isNaN( saleTotals.totalQuantity )) {
			returnObj.valid = false;
			returnObj.message = "closeSaleNoTotalQuantity";
		}

		return returnObj;
	}

	export function saleTotalAmountWithNoSaleDiscount (saleItems:Array<PositiveTS.Storage.Entity.SaleItem>) {
		// Initialize sale total
		var total = 0;

		// Go over each sale item
		for (let saleItem of saleItems) {
			if (saleItem.noDiscount) {
				continue;
			}
			// Calculate the item price
			let itemPrice = saleItem.quantity * saleItem.unitPrice;

			itemPrice = itemPrice - saleItemHelper.getSaleItemDiscountAmount(saleItem)

			// Add it to the total
			total = total + itemPrice;
		};

		return session.fixedFloat(total);
	}

	export function applySalePromotionOnSale (sale, saleItems, promotion):boolean {
		let aThis = PositiveTS.Helper.SaleHelper;
		var totalSaleDiscount = 0;

		var minimumBuyAmount = parseFloat(promotion.minimumBuyAmount);
		var minimumBuyQuantity = parseFloat(promotion.minimumBuyQuantity);
		var discountValue = Number(promotion.discountValue || 0);
		var allowedWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);

		var ciService = Service.CreditInvoiceUtils;
		var saleItemsWithPromotion = saleItems.filter(function(saleItem) {
			var item = saleItem.item;
			return (item.promoBuy != null && (item.promoBuy.split("&").indexOf(promotion.code) > -1));
		});

		var results;
		switch (Number(promotion.template)) {
			case 4:
				results = ciService.calculateTotalsWithAndWithoutPromotionsAndDiscounts(sale,saleItemsWithPromotion);
				break;
			case 7:
				results = ciService.calculateTotalsWithAndWithoutPromotionsAndDiscounts(sale,saleItems);
				break;
			default:
				results = ciService.calculateTotalsWithAndWithoutPromotionsAndDiscounts(sale,saleItemsWithPromotion);
		}

		var totalAmount = results.totalAmount;
		var totalQuantity = results.totalQuantity;
		var totalAmountWithoutItemsWithDiscount = results.totalAmountWithoutItemsWithDiscount;
		var totalQuantityWithoutItemsWithDiscount = results.totalQuantityWithoutItemsWithDiscount;

		var totalAmountForSaleDiscountCalc = totalAmount;

		if (!allowedWithOtherPromotions) {
			totalAmountForSaleDiscountCalc = totalAmountWithoutItemsWithDiscount;
			totalQuantity = totalQuantityWithoutItemsWithDiscount;
		}


		var allowedMultipleTimesSameSale = Boolean(promotion.allowMultipleTimesSameSale);

		if (totalAmountForSaleDiscountCalc >= minimumBuyAmount && totalQuantity >= minimumBuyQuantity) {
			sale.discountName = promotion.name;
			sale.promotionCode = promotion.code;


			if (allowedMultipleTimesSameSale) {
				if (minimumBuyAmount > 0) {
					totalSaleDiscount = Math.floor(totalAmountForSaleDiscountCalc/minimumBuyAmount)*discountValue;
				}
				else {
					totalSaleDiscount = discountValue;
				}
			}
			else {
				totalSaleDiscount = discountValue;
				const template = Number(promotion.template)
				if (template === 10) {
					totalSaleDiscount = Math.min(140,totalAmount*0.2)
				}
				if ((template === 4) && promotion.discountType == PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT) {
					totalSaleDiscount = totalAmountForSaleDiscountCalc*Number(discountValue)/100.0
				}
			}
			sale.saleDiscountAllowedWithOtherPromotions = allowedWithOtherPromotions;
			sale.saleDiscountAmount = totalSaleDiscount;
			sale.discountPercent = session.fixedFloat(totalSaleDiscount/totalAmount * 100);
			if (promotion.clubMemberPromotionType) {
				sale.clubMemberPromotionType = promotion.clubMemberPromotionType;
			}
			var jd = JSON.parse(sale.jsondata);
			jd.salePromotion = {
				code: promotion.code,
				barcodesInPromotion: saleItemsWithPromotion.map(function(item) { return item.barcode } )
			}
			if (Boolean(promotion.hasBarcode) && promotion.promotionBarcode) {
				jd.salePromotion.hasBarcode = true;
				jd.salePromotion.promotionBarcode = promotion.promotionBarcode;
			}
			sale.jsondata = JSON.stringify(jd); 
			return true;
		}
		else {
			aThis.removeSalePromotionFromSale(sale);
			return false;
		}

	}

	export function customerPaidEnough(saleTotals):boolean {
		let condition
		if (session.pos.hasFlights) {
			condition = session.fixedFloat(saleTotals.totalAmount, 2) > (session.fixedFloat(saleTotals.totalPaid, 2)+0.01);
		}
		else {
			condition = session.fixedFloat(saleTotals.totalAmount, 2) > session.fixedFloat(saleTotals.totalPaid + saleTotals.totalRound, 2);
		}
		return condition;
	}

	// Sync
	export function calcuateSaleTotals (sale:Storage.Entity.Sale, saleItems:Array<Storage.Entity.SaleItem>, salePayments) {
		var aThis = PositiveTS.Helper.SaleHelper;
		var totalAmount													= 0;
		var totalQuantity												= 0;
		var totalAbsoluteQuantity								= 0;
		var totalDiscount												= 0;
		var totalPaid														= 0;
		var totalAmountWithoutItemsWithDiscount = 0;
		var totalSaleDiscount 									= 0;
		var totalVatableAmount									= 0;
		let totalItemsThatCantGetDiscount					= 0;
		let totalVatableAmountThatCantGetDiscount = 0;
		let totalVatableAmountWithoutItemsWithDiscount = 0; //this is soooo.... bad
		let itemPrice:number;
		let discountVoucher, currVoucher, discountPercent;
		let isCreditInvoice = sale.invoiceType === PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE
		let tipAmount = 0;
		let totalAmountOfItems = 0

		for (let i = 0; i < saleItems.length; i++) {
			let saleItem = saleItems[i]
			if ( Boolean(saleItems[i].salePercentAddition) ){
        		continue;
      		}

			if (isCreditInvoice &&
				posCIVC.itemHasPromotions(saleItems[i],sale,saleItems)) {
				itemPrice = posCIVC.calcuateItemPriceAfterDiscounts(saleItems[i], sale);

				totalAmount += itemPrice * saleItems[i].quantity;
				
				if(session.store.containVat == false){
					if(Boolean(saleItems[i].noVat) == false){
						totalVatableAmount += itemPrice * saleItems[i].quantity;
					}
				}
				else{		//Eilat store
					if(Boolean(saleItems[i].alwaysHasVat) == true){
						totalVatableAmount += itemPrice * saleItems[i].quantity;
					}
				}
			}
			else {
				itemPrice = saleItem.unitPrice * saleItem.quantity
				let saleItemChildrenAndGrandchildren = [];
				if (saleItem.hasGroups) {
					const childrenAndGrandchildren = saleItemHelper.extractSaleItemChildrenAndGrandchildren(saleItem);
					saleItemChildrenAndGrandchildren = [...childrenAndGrandchildren.children, ...childrenAndGrandchildren.grandchildren];
				}

				if (saleItem.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL || saleItem.promotions != null ||
					saleItemChildrenAndGrandchildren.some(si => si.promotions != null)) {
					let itemDiscount;
					if (sale.useNewPromotions) {
						itemDiscount = itemPrice - saleItems[i].priceNetoAfterDiscounts;
					}
					else {
						itemDiscount = saleItemHelper.getSaleItemDiscountAmount(saleItems[i]);
					}
					totalDiscount += itemDiscount;
					itemPrice -= itemDiscount;
					totalAmount += itemPrice;
					if(session.store.containVat == false){
						if(Boolean(saleItems[i].noVat) == false){
							totalVatableAmount += itemPrice;
						}
					}
					else{		//Eilat store
						if(Boolean(saleItems[i].alwaysHasVat) == true){
							totalVatableAmount += itemPrice;
						}
					}
				} else {
					if (saleItems[i].noDiscount) {
						totalItemsThatCantGetDiscount += itemPrice;
					}
					totalAmountWithoutItemsWithDiscount += itemPrice;
					if(session.store.containVat == false){
						if (!Boolean(saleItems[i].noVat)) {
							totalVatableAmountWithoutItemsWithDiscount += itemPrice;
							if (saleItems[i].noDiscount) {
								totalVatableAmountThatCantGetDiscount += itemPrice
							}
						}
						if(Boolean(saleItems[i].noVat) == false){
							totalVatableAmount += itemPrice;
						}
					}
					else{		//Eilat store

						if(Boolean(saleItems[i].alwaysHasVat) == true){
							totalVatableAmount += itemPrice;
							
							totalVatableAmountWithoutItemsWithDiscount += itemPrice;
							if (saleItems[i].noDiscount) {
								totalVatableAmountThatCantGetDiscount += itemPrice
							}
							
						}
					}
				}
			}

			const isTipItemCode = Service.Tip.isTipItemCode(saleItem.itemCode)
			if (isTipItemCode) {
				tipAmount += itemPrice;
			} else {
				totalQuantity += saleItems[i].quantity;
				totalAbsoluteQuantity += Math.abs(saleItems[i].quantity);
			}

			if (saleItem.quantity > 0 && !isTipItemCode) {
				if (saleItem.hasWeight) {
					totalAmountOfItems += 1;
				} else {
					totalAmountOfItems += saleItem.quantity;
				}
          	}

			if (Array.isArray(saleItems[i].children)) {
				let hasChildWihtoutVat = saleItems[i].children.findIndex(child => Boolean(child.noVat)) >= 0
				if (hasChildWihtoutVat){
					saleItems[i].children.forEach(child => {
						if (child.noVat){
							let childUnitPriceForTax = session.pos.isCaveret ? child.item.priceZarhan : child.unitPrice;
							let childItemPrice = childUnitPriceForTax * child.quantity							
							totalVatableAmount -= isCreditInvoice ? childItemPrice * -1 : childItemPrice
						}
					})
				}
			}
		}

		totalAmount += totalAmountWithoutItemsWithDiscount;
		//השורה הזאת נמחקה - זאת עובדה.
		//...איתה הכל נשבר, בלעדיה הכל סבבה - מדוע היא הייתה שם? מה היה תפקידה? זאת ילדי... כבר לעולם לא נדע
		// totalVatableAmount += totalVatableAmountWithoutItemsWithDiscount;


		var discountVoucherPayment = null;
		for (var i = 0; i < salePayments.length; i++) {
			var payment = salePayments[i];

			if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
				discountVoucherPayment = payment;
			} else if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE) {
				totalPaid -= payment.amount;
			} else {
				totalPaid += payment.amount;
			}
		}

		if (PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(sale)) {
			discountPercent = 1;
			if (totalAmount != 0) {
				discountPercent = sale.saleDiscountAmount/totalAmount;
			}

			sale.saleDiscountAmount = session.fixedFloat(sale.saleDiscountAmount);

			totalDiscount += sale.saleDiscountAmount;
			totalAmount -= sale.saleDiscountAmount;
			totalVatableAmount = totalVatableAmount*(1-discountPercent);
		}
		else if (PositiveTS.Helper.SaleHelper.doesSaleHasDiscount(sale)
					&& !session.pos.useNewPromotions) { //this if irrelevant in the new promotion system because sale.discountPercent does nothing in it.
				if (sale.saleDiscountAllowedWithOtherPromotions) {
					totalSaleDiscount = session.fixedFloat((totalAmount-totalItemsThatCantGetDiscount) * Number(sale.discountPercent) / 100);
					totalVatableAmount -= (totalVatableAmount - totalVatableAmountThatCantGetDiscount) * (Number(sale.discountPercent)/100);
				}
				else {
					totalSaleDiscount = session.fixedFloat((totalAmountWithoutItemsWithDiscount-totalItemsThatCantGetDiscount) *
							Number(sale.discountPercent) / 100);
					totalVatableAmount -= (totalVatableAmountWithoutItemsWithDiscount - totalVatableAmountThatCantGetDiscount) * (Number(sale.discountPercent)/100);
				}
				sale.saleDiscountAmount = totalSaleDiscount;
				totalDiscount += totalSaleDiscount;
				totalAmount -= totalSaleDiscount;

		}

		if (discountVoucherPayment != null) {
			let totalDiscountVouchers = 0;
			discountVoucher = JSON.parse(discountVoucherPayment.data);
			for (var i = 0; i < discountVoucher.length; i++) {
				currVoucher = discountVoucher[i];

				totalDiscount += currVoucher.amount;
				totalDiscountVouchers += currVoucher.amount;
			}

			let voucherDiscountRatio = 1;
			if (totalAmount != 0) {
				voucherDiscountRatio = totalDiscountVouchers/totalAmount;
			}
			if (session.fixedFloat(totalDiscountVouchers) == session.fixedFloat(totalAmount)) {
				totalVatableAmount = 0;
			}
			totalAmount -= totalDiscountVouchers;
			totalVatableAmount = totalVatableAmount*(1-voucherDiscountRatio);

		}


		let salePercentAdditionItems = saleItems.filter((row)=>{return Boolean(row.salePercentAddition);})
		if ( salePercentAdditionItems.length === 1 ) {
			salePercentAdditionItems[0].unitPrice = (salePercentAdditionItems[0].salePercentAddition / 100) * totalAmount
			salePercentAdditionItems[0].priceNetoAfterDiscounts = salePercentAdditionItems[0].unitPrice
			totalAmount += salePercentAdditionItems[0].unitPrice;
			totalVatableAmount += salePercentAdditionItems[0].unitPrice;
		}

		if (sale.invoiceType == Storage.Entity.Sequence.TYPE_RECEIPT) {
			totalVatableAmount = 0;
		}

		return {
			totalAmount: session.fixedFloat(totalAmount),
			totalVatableAmount: session.fixedFloat(totalVatableAmount),
			totalDiscount: session.fixedFloat(totalDiscount),
			totalQuantity: totalQuantity,
			totalAbsoluteQuantity: totalAbsoluteQuantity,
			totalPaid: session.fixedFloat(totalPaid),
			totalSaleDiscount: session.fixedFloat(sale.saleDiscountAmount),
			totalRound: sale.roundAmount,
			tipAmount: session.fixedFloat(tipAmount),
			totalAmountOfItems: totalAmountOfItems,
		};
	}

	export async function createCreditVoucherPayment (amountToCredit):Promise<Storage.Entity.SalePayment> {
		
		// save the credit sale
		let sequence = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_CREDIT_VOUCHER)
			
		if (sequence == null) {		
			throw new Error('credit sale sequence not found for the pos, call to system administrator.');
		}

		sequence.sequence = sequence.sequence + 1;
		await appDB.sequences.put(sequence)
				
		let creditVoucherNumber = session.pos.companyID + session.pos.storeID + session.pos.posNumber + sequence.sequence;

		let creditVoucherPayment = new PositiveTS.Storage.Entity.SalePayment();
		creditVoucherPayment.amount = -amountToCredit;
		creditVoucherPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER;
		creditVoucherPayment.data = JSON.stringify([{
			"amount": session.fixedFloat(amountToCredit),
			"creditVoucherSequence" : sequence.sequence,
			"promotionNumber":  creditVoucherNumber,
			"creditVoucherType": PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_CREDIT
		}]);

		return creditVoucherPayment;
	}

	export function getCreditedItemsAndPaymentsByFullSale (fullSale) {
		var fullSaleCopy = new PositiveTS.Storage.Entity.Sale();
		fullSaleCopy.importFromObject(fullSale.exportToObject());
		fullSaleCopy = $.extend(fullSaleCopy, fullSale);

		var saleItems = [];
		// create saleItems copy
		for (var i in (<any>fullSaleCopy).items) {
			var saleItemCopy = new PositiveTS.Storage.Entity.SaleItem();
			saleItemCopy.importFromObject((<any>fullSaleCopy).items[i].exportToObject());
			saleItemCopy.originalQuantity = saleItemCopy.quantity;
			saleItemCopy.originalItemId = (<any>fullSaleCopy).items[i].originalItemId; //TODO: not sure about this! was parentItemId for some reason!
			saleItems.push(saleItemCopy);
		}

		// PositiveTS.Helper.SaleHelper.devidPromotionsDiscountByItemsForCredit(saleItems);

		var salePayments = [];
		// create salePayments copy
		for (var i in (<any>fullSaleCopy).payments) {
			var salePaymentCopy = new PositiveTS.Storage.Entity.SalePayment();
			salePaymentCopy.importFromObject((<any>fullSaleCopy).payments[i].exportToObject());
			salePayments.push(salePaymentCopy);
		}

		if ((<any>fullSaleCopy).childrens === null || (<any>fullSaleCopy).childrens === undefined) {
			return { itemsToCredit: saleItems, paymentsToCredit: salePayments };
		}

		for (var childrens_index = 0; childrens_index < (<any>fullSaleCopy).childrens.length; childrens_index++) {
			var childSale = (<any>fullSaleCopy).childrens[childrens_index];

			PositiveTS.Service.CreditInvoice.reduceChildItemsFromOriginalItems(childSale, saleItems)
			PositiveTS.Service.CreditInvoice.reduceChildPaymentsFromOriginalPayments(childSale, salePayments)

		}

		return { itemsToCredit: saleItems, paymentsToCredit: salePayments };
	}

	function isFinalSaleCredit(fullSaleToCredit, creditedItems:Array<Storage.Entity.SaleItem>):boolean {
		let originalQuantities = {}
		fullSaleToCredit.items.forEach(item => originalQuantities[item.rowNumber] = item.quantity)
		if (fullSaleToCredit.childrens) {
			for (let childSale of fullSaleToCredit.childrens) {
				childSale.items.forEach(item => originalQuantities[item.rowNumber] += item.quantity)
			}
		}
		creditedItems.forEach(item => originalQuantities[item.rowNumber] += item.quantity)

		for (let rowNumber in originalQuantities) {
			let rowNumberNum = Number(rowNumber)
			if (originalQuantities[rowNumberNum] != 0) {
				return false;
			}
		}


		return true;
	}

	export function initCreditSale(parentSale:Storage.Entity.Sale, entitySequenceType:number) {
		let creditSale = new PositiveTS.Storage.Entity.Sale();

		creditSale.parentSaleCompanyID					= parentSale.companyID;
		creditSale.parentSaleStoreID						= parentSale.storeID;
		creditSale.parentSalePosDeviceID				= parentSale.posDeviceID;
		creditSale.parentSalePosNumber					= parentSale.posNumber;
		creditSale.parentSaleInvoiceSequence		= String(parentSale.invoiceSequence);
		creditSale.parentSaleInvoiceType				= String(parentSale.invoiceType);
		creditSale.parentSaleDelivery				= JSON.stringify(JSON.parse(parentSale.jsondata).delivery);
		creditSale.tenantID 										= session.pos.tenantID;
		creditSale.companyID 										= session.pos.companyID;
		creditSale.storeID 											= session.store.storeID;
		creditSale.storeName 										= session.store.storeName;
		creditSale.storeAddress 								= session.store.address;
		creditSale.storeFreeText 								= session.store.freeText;
		creditSale.storeRegistrationNum 				= session.store.registrationNum;
		creditSale.storePhone 									= session.store.phone;
		creditSale.posPhysicalID 								= session.pos.physicalID;
		creditSale.posDeviceID 									= session.pos.deviceID;
		creditSale.posNumber	 									= String(session.pos.posNumber);
		creditSale.invoiceSequence 							= -1;
		creditSale.invoiceType 									= entitySequenceType;
		creditSale.zNumber 											= PositiveTS.Storage.Entity.Sale.NULL_Z_REPORT;
		creditSale.cashierEmployeeID 						= session.pos.employeeID;
		creditSale.cashierEmployeeName 					= session.pos.employeeName;
		creditSale.createdAt 										= PositiveTS.DateUtils.fullFormat();
		creditSale.discountID 									= '-1';//parentSale.discountID;
		creditSale.discountName 								= null;//parentSale.discountName;
		creditSale.discountPercent 							= '0';//parentSale.discountPercent;
		creditSale.discountApprovedByEmployeeID	= null;//parentSale.discountApprovedByEmployeeID;
		creditSale.promotionCode								= null;//parentSale.promotionCode;
		creditSale.customerSeqID 								= parentSale.customerSeqID;
		creditSale.customerID 									= parentSale.customerID;
		creditSale.customerPhone 								= parentSale.customerPhone;
		creditSale.customerName 								= parentSale.customerName;
		creditSale.syncStatus 									= PositiveTS.Storage.Entity.Sale.SYNC_STATUS_NEW;
		creditSale.syncLastMessage 							= '';
		creditSale.syncLastMessageTimestamp 		= PositiveTS.DateUtils.fullFormat();
		creditSale.salespersonEmployeeID 				= parentSale.salespersonEmployeeID;
		creditSale.salespersonEmployeeName 			= parentSale.salespersonEmployeeName;
		creditSale.vat 													= parentSale.vat;
		creditSale.isInHold = false;
		creditSale.initJsondata();

		return creditSale;
	}

	export async function creditSale (fullSaleToCredit, 
		creditedItems:Storage.Entity.SaleItem[], creditedPayments:Storage.Entity.SalePayment[], 
		entitySequenceType = Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {


		let parentSale = fullSaleToCredit;

		// Create new sale of credit type from the sale
		let creditSale = initCreditSale(parentSale,entitySequenceType);

		// save the credit sale
		let sequence = await PositiveTS.Storage.Entity.Sequence.getSequenceForInvType(entitySequenceType);

		Service.VoucherPayment.fixSplitSaleCustomers(creditSale, creditedPayments)

		creditedItems.forEach(item => item.saleID = creditSale.id)
		creditedPayments.forEach(pay => pay.saleID = creditSale.id)
				
					// Advance the sequence by 1
		sequence.sequence = sequence.sequence + 1;

		let savedCreditSale = await creditSale.closeSale(sequence, creditedItems, creditedPayments, isFinalSaleCredit(fullSaleToCredit,creditedItems), false)

		if (PositiveTS.Service.SimplyClubService.isTranEndResExistsOnSale(parentSale)) {
			let res = await PositiveTS.Service.SimplyClubService.saleRefund(parentSale, savedCreditSale.invoiceSequence.toString());
			PositiveTS.Service.SimplyClubService.setTranEndResOnSale(res, savedCreditSale, false);
		}
		
		savedCreditSale.items = creditedItems;
		savedCreditSale.payments = creditedPayments;

		return savedCreditSale;
	
	}
	export async function getFirstSale(){
		return await appDB.sales.orderBy('timestamp').first();
	}

	export function returnCreditCard (creditCard, callback, errCallback) {
		
		var creditType = 0;

		if (posUtils.isNullOrUndefined(creditCard.payment_type)) {
			creditType = creditCard.payments_count > 1 ? 2 : 1;
		} else {
			creditType = creditCard.payment_type;
		}

		PositiveTS.Service.CreditCard.creditCreditCardPayment(
			creditCard.token,
			creditCard.amount,
			creditType,
			"", //creditCard.confirmation_number
			creditCard.payments_count,
			creditCard.valid_until,
			creditCard.phone_number,
			creditCard.tz,
			creditCard.creditCurrency,
			function (returnedArray) {

				if (returnedArray[0].success === true) {
					var retData = returnedArray[0];
					// override credit all_data ofold with new request
					if (retData.all_data) {
						creditCard.old_all_data = creditCard.all_data;
						creditCard.all_data = retData.all_data;
					}
					callback(null, creditCard);
				} else {
					// this function might be called by custom async.all
					// so erors returned using callback
					if (errCallback) {
						errCallback(returnedArray[0].error_message, creditCard);
					}
					else {
						callback(true, returnedArray[0].error_message);
					}
				}
			},
			function (err) {
				if (errCallback) {
					errCallback(err, creditCard);
				} else {
					callback("ERROR:" + err, creditCard)
				}
			}
		);
	}

	export function promiseReturnVoucher(voucher) {
		return new Promise((resolve,reject) => {
			returnVoucher(voucher,(err,voucher) => {
				if (err != null) {
					reject(err);
				}
				else {
					resolve(voucher);
				}
			})
		});
	}
	
	export function togglePromotions (ignorePromotions: boolean){
        posVC.ignorePromotions = ignorePromotions;
        posVC.sale.items = posVC.saleItems;
		posVC.sale.payments = posVC.salePayments;
		return posVC.loadSale();
	}

	function returnVoucher (voucher, callback) {
		if (voucher.barCode == null || voucher.barCode == '') {
			callback(null, voucher);
			return;
		}

		if (PositiveTS.Reachability.isOnline) {
			if (PositiveTS.Service.SmartVoucher.isSmartVoucher( voucher['smartVoucherType'] ) ) {
				return PositiveTS.Service.SmartVoucherFactory.createVoucher(voucher['smartVoucherType']).cancelPayment(voucher)
				.then((response) => {
					if(response.response){
						voucher.barCode = response.response.transactionId;
						voucher.transactionId = response.response.transactionId;
					}
					callback(response.success ? null : response.error,voucher)
				})
				.catch((err) => {
					callback(err,voucher)
				})
			}
			else {
				Service.Voucher.UnuseVoucherBarcode(voucher.voucher_type_id, voucher.barCode)
				.then(function() {
					callback(null, voucher);
				})
				.catch(function(err) {
					callback(err, voucher);
				})					
			}
		} else {
			callback(i18next.t('cancelSaleVoucherBarcodeOfflineError'), voucher);
		}
	}

}}}
