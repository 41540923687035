module PositiveTS {
export module Application {
export module Controllers {
export class PosDiscountViewController extends AbstractViewControllerTS {
	currentStep = 1
	wizard = null
	discounts = {}
	isManager = false
	selectedDiscount:Storage.Entity.Discount
	selectedDiscountPrecent = -1
	discountOwnerType = "-1"
	inSpecialDiscounts = false
	entities:Array<Storage.Entity.Sale | Storage.Entity.SaleItem> = null
	discountType = 1
	discountValue = 0
	discountAmountOf = 0
	saleDiscountAmount:number

	//user defined type guard. @see https://www.typescriptlang.org/docs/handbook/advanced-types.html
	private isSaleItem(entity: Storage.Entity.SaleItem | Storage.Entity.Sale): entity is Storage.Entity.SaleItem {
		return (<Storage.Entity.SaleItem>entity).unitPrice !== undefined;
	}


	init(options) {
		let aThis = posDiscountVC;

		// Initialize the table views
		aThis.initTableViews();


		// Initialize the slideshow plugin which is used to mimic the wizard style flow
		aThis.initWizard();

		// Initialize the keypad
		var keypad = $('#pos-discount-keypad').keypad({format: {format:"##0", locale:"il"}, isPercentage: true});

		$('#pos-discount-manager-card').keyboard({
			layout     : 'only-numbers',
			usePreview : false,
			autoAccept : true,
			css        : { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" },
			position   : {
				of : $('#footer'),
				my : 'center top',
				at : 'center bottom'
			}
		})//.addTyping();

		// Bind the clear button to a function
		$('#pos-discount-precent-clear').click(function () {
			$('#pos-discount-keypad').keypad().setValue(0);
		});
	}
	setDiscountType(newDiscountType:number) {
		let aThis = posDiscountVC;

		var currentDiscountValue = $('#pos-discount-keypad').keypad().getValue();
		var newDiscountValue = currentDiscountValue;
		this.discountValue = Number(currentDiscountValue);

		if (newDiscountType == Number(PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT)) {
			$('#pos-discount-keypad').keypad().setIsPercentage(true);
			$('#pos-discount-keypad').keypad().setFormat({format:"##0.##", locale:"il"});

			if (aThis.discountType != newDiscountType) {
				newDiscountValue = ((aThis.discountAmountOf - currentDiscountValue) * 100 / aThis.discountAmountOf);
			}
		} else {
			$('#pos-discount-keypad').keypad().setIsPercentage(false);
			$('#pos-discount-keypad').keypad().setFormat({format:"#,##0.##", locale:"il"});

			if (aThis.discountType != newDiscountType) {
				newDiscountValue = aThis.discountAmountOf - (currentDiscountValue / 100 * aThis.discountAmountOf);
			}
		}

		$('#pos-discount-keypad').keypad().setValue(newDiscountValue);

		aThis.discountType = newDiscountType;
	}
	initDiscountTypeSelector() {
		var aThis = posDiscountVC

		$('#pos-discount-type-chooser').positiveRadioSelect({
			changed: aThis.setDiscountType
		});
		$('#pos-discount-type-chooser').positiveRadioSelect('select', Number(Storage.Entity.SaleItem.DiscountType.PERCENT));
	}
	initTableViews() {
		var aThis = posDiscountVC;

		// Initialize the table view
		var discountsTableView = $('#pos-discount-tableview').tableview();

		// Initialize the table view
		var specialDiscountsTableView = $('#pos-special-discount-tableview').tableview();

		// Set the number of visible rows
		discountsTableView.setNumberOfVisibleRows(5);

		// Set the table view header
		discountsTableView.setHeader(`
			<div class='ui-grid-b'>
				<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.discountName")}</div></div>
				<div class='ui-block-b' style='width: 25%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.approvedDiscount")}</div></div>
				<div class='ui-block-c' style='width: 25%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.managerDiscount")}</div></div>
			</div><!-- /grid-b -->`);

		specialDiscountsTableView.setHeader(`
			<div class='ui-grid-b'>
				<div class='ui-block-a' style='width: 20%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.confirmedDate")}</div></div>
				<div class='ui-block-b' style='width: 20%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.allowerName")}</div></div>
				<div class='ui-block-c' style='width: 20%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.customerName")}</div></div>
				<div class='ui-block-d' style='width: 20%'><div class='ui-tableview-header-cell'>% ${i18next.t("discount")}</div></div>
				<div class='ui-block-e' style='width: 20%'><div class='ui-tableview-header-cell'>${i18next.t("itemDiscount.comment")}</div></div>
			</div><!-- /grid-b -->`);
	}
	initWizard() {
		var aThis = posDiscountVC;

		// Initialize the slideshow plugin which is used to mimic the wizard style flow
		if (aThis.wizard === null) {
			aThis.wizard = $('#pos-discount [data-role="content"]').bjqs({
				'width'			: '100%',
				'height'		: '100%',
				'showMarkers'	: false,
				'showControls'	: false,
				'centerMarkers' : false,
				'automatic'		: false,
				'useCaptions'	: false,
				'keyboardNav'	: false
			});
			$('#pos-discount [data-role="content"]').css({
				'width': ''
			});
			$('#pos-discount-precent-select').css({
				'margin': '1em 1em'
			});
		}
	}
	setDefaultDiscountOwnerType(options) {
		var aThis = posDiscountVC;

		// The default discount type is sale discount
		aThis.discountOwnerType = PositiveTS.Storage.Entity.Discount.TYPE_SALE;
		if ('discountType' in options) {
			aThis.discountOwnerType = options.discountType;
		}
	}
	isValidDiscountOwnerType(discountOwnerType) {
		// Check that the discount type is valid
		if (discountOwnerType != PositiveTS.Storage.Entity.Discount.TYPE_SALE && discountOwnerType != PositiveTS.Storage.Entity.Discount.TYPE_ITEM) {
			// We are done!
			return false;
		}

		return true;
	}
	updateDiscountsOnTableView(discounts) {
		let aThis = posDiscountVC;

		// Get the table view
		let tableView = $('#pos-discount-tableview').tableview();

		// Empty the table
		tableView.empty();

		// Empty the local discounts array
		aThis.discounts = {};

		let svcCustomerClub = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
		let isCustomerExists = svcCustomerClub.isCurrentCustomerExists() 

		// Iterate over the discounts
		for (let i = 0; i < discounts.length; i++) {
			// Get the current discount
			let discount = discounts[i];
			if (discount.isClubMemberOnly && !isCustomerExists){continue;}

			// Get the approved discount precent
			var approved = discount.approvedPrecent;
			if (isNaN(parseInt(approved, 10))) {
				// In case the approved discount is an empty string, default to 0
				approved = '0';
			}

			// Get the manager discount precent
			var manager = discount.managerPrecent;
			if (isNaN(parseInt(manager, 10))) {
				// In case the manager discount is an empty string, default to 0
				manager = '0';
			}

			// Add it to the table view
			var theRow = tableView.addRow([
				posUtils.sanitizeHtml(discount.name),
				approved + '%',
				manager + '%'
			]);
			aThis.discounts[theRow.attr('id')] = discount;
		}
	}
	resumeGUI() {
		let aThis = posDiscountVC;
		
		aThis.initDiscountTypeSelector();

		// --- UI Setup ----------------------------------------------------------------------
		// Set the dialog header by the discount type
		var dialogHeader = i18next.t("posDiscount");
		switch (aThis.discountOwnerType) {
			case PositiveTS.Storage.Entity.Discount.TYPE_ITEM:
				dialogHeader = i18next.t("posDiscountTypeItem");
				break;
			case PositiveTS.Storage.Entity.Discount.TYPE_SALE:
				dialogHeader = i18next.t("posDiscountTypeSale");
				break;
			default:
				break;
		}
		$('#pos-discount > [data-role="header"]').text(dialogHeader);

		// If in special discounts exit to first step
		if (aThis.inSpecialDiscounts) {
			aThis.backFromSpecialDiscount();
		}

		// If the wizard is not on its first step, bring it there
		if (aThis.currentStep != 1) {
			aThis.wizard.bjqsGo(null, 1);
		}
		aThis.currentStep = 1;

    $('#pos-discount-ItemSelecSpecial').hide();

		if (aThis.discountOwnerType == PositiveTS.Storage.Entity.Discount.TYPE_ITEM) {
			$('#pos-discount-special').hide();
		} else {
			$('#pos-discount-special').show();
		}

		// Bind the continue button to its listener
		$('#pos-discount-continue').click(posDiscountVC.continue);
		$('#pos-discount-ItemSelecSpecial').click(posDiscountVC.continue);

		// Allow clicking on the special discounts only while online
		var observer = function () {
			if (PositiveTS.Reachability.isOnline) {
				$('#pos-discount-special').unbind();
				$('#pos-discount-special').click(posDiscountVC.goToSpecialDiscount);
				$('#pos-discount-special > .ui-button-style').addClass('ui-button-style-yellow-background').removeClass('ui-button-style-gray-solid-background');
			} else {
				$('#pos-discount-special').unbind();
				$('#pos-discount-special > .ui-button-style').addClass('ui-button-style-gray-solid-background').removeClass('ui-button-style-yellow-background');
			}
		};
		PositiveTS.NotificationCenter.removeObserver(observer);
		PositiveTS.NotificationCenter.addObserver(observer, PositiveTS.Reachability.PositiveReachabilityStateChangedNotification);
		observer();


		// Bind the back button to its listener
		$('#pos-discount-back').click(posDiscountVC.back);

	}

	bindKeyboard() {
			$(document).unbind('keypress');
			let aThis = posDiscountVC;

			// Redirect every keystroke outside the pos-discount-precent field into it
			$(document).keypress(function (event) {
				// If the keypress is in the field, ignore
				if (event.target.id == 'pos-discount-precent') {
					return;
				}
	
				// Prevent default
				event.preventDefault();
	
				// Decide what to do based on the current step
				if (aThis.currentStep == 2) {
					// Forward the event to the keypad...
					$('#pos-discount-keypad').keypad().insertCharWithCode(event.which);
				} else if (aThis.currentStep == 3) {
					// Forward the event to the input field for the manager card
					let oldvalue = $('#pos-discount-manager-card').val();
					$('#pos-discount-manager-card').attr('value', oldvalue + String.fromCharCode(event.which));
				}
			});
	}

	/**
	 * @param {Object} options Configuration object
	 * @param {String} options.discountType The discount type as defined in PositiveTS.Storage.Entity.Discount.TYPE_*
	 */

	resume(options?) {
		let aThis = posDiscountVC;
		
		aThis.saleDiscountAmount = aThis.getSaleDiscountAmount();

		// The default discount type is sale discount
		aThis.setDefaultDiscountOwnerType(options);

		// Check that the discount type is valid
		if (!aThis.isValidDiscountOwnerType(aThis.discountOwnerType)) {
			// Invalid discount type, so go back!
			pNavigator.back();

			// We are done!
			return;
		}

		// Nullify the selected discount
		aThis.selectedDiscount = null;

		// reusme the gui
		aThis.resumeGUI();

		// Load the entity based on the discount type
		 
		posDiscountVC.entities = [posVC.sale];

		if (posDiscountVC.discountOwnerType == PositiveTS.Storage.Entity.Discount.TYPE_ITEM) {
			if(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)) {
				let sortedItemsByHighestPrice = Pinia.globalStore.multipleSelectedSaleItems.sort(function(a, b) {
					return (b.unitPrice * b.quantity) -  (a.unitPrice * a.quantity) ;
				});
				posDiscountVC.entities = posVC.fetchArrayPosVCSaleItems(sortedItemsByHighestPrice)
			} else {
				posDiscountVC.entities = [posVC.saleItemForSelectedRow()]
			}

			if (posDiscountVC.entities == null) {
				console.error('Row is not associated with any sale items');
				pNavigator.back();
				return;
			}
		}


		for (const entity of posDiscountVC.entities) {
			// --- Entity fetched successfully
			// If the discount type is item, get the Item from the SaleItem
			let item:Storage.Entity.Item = null;
			if (session.pos.useNewPromotions) {
				if (aThis.isSaleItem(entity)) {
					posDiscountVC.discountAmountOf = entity.unitPrice*entity.quantity;
					item = entity.item;
				} else {
					let totalAmount = posVC.getTotalAmount();
					posDiscountVC.discountAmountOf = totalAmount + posDiscountVC.saleDiscountAmount;
				}
			}
			else {
				if (posDiscountVC.discountOwnerType == PositiveTS.Storage.Entity.Discount.TYPE_ITEM) {
					posDiscountVC.discountAmountOf = (<Storage.Entity.SaleItem>entity).unitPrice;
					item = (<Storage.Entity.SaleItem>entity).item;
				} else {
					posDiscountVC.discountAmountOf = PositiveTS.Helper.SaleHelper.saleTotalAmountWithNoSaleDiscount(posVC.saleItems);
				}
			}
	
			
			// Fetch the relevant discounts
			var discount = new PositiveTS.Storage.Entity.Discount();
			return discount.fetchByStoreAndType(session.pos.storeID, posDiscountVC.discountOwnerType, item)
			.then(function (discounts) {
				// --- Discounts fetched successfully
				// Are there any discounts?
				if (discounts.length === 0) {
					console.error('No discounts');
					throw new Error(i18next.t("noDiscounts"));
				}
	
				// update the discounts in the table view
				aThis.updateDiscountsOnTableView(discounts);
			})
			.catch(function (error) {
				console.error(error);
				posDiscountVC.displayErrorMessage(error.message);
			});
		}
	}
	stop() {
		// Unbind the listeners from the resume function
		$(document).unbind('keypress');
		$('#pos-discount-continue').unbind('click');
		$('#pos-discount-back').unbind('click');

		$('#pos-discount-special').unbind('click');
		$('#pos-discount-ItemSelecSpecial').unbind('click');

		$('#pos-discount-type-chooser').positiveRadioSelect('destroy');
	}
	destroy() {
		// Unbind the listeners from the init function
		$('#pos-discount-precent-clear').unbind();
	}
	displayErrorMessage(errorMessage) {
			app.showAlert({
					header: i18next.t('error'),
					content: errorMessage,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
			},
			function () {
				pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
			}, null);
	}


	async addDiscountNewStructure(employeeId, selectedDiscount:Storage.Entity.Discount, selectedDiscountPrecent:number, 
		entityArray:Array<Storage.Entity.SaleItem | Storage.Entity.Sale>, discountType:number, discountValue:number, sale = posVC.sale) {
		let discountPg:Promotions.PromoGroup = {
			promotionCode: selectedDiscount.discountID,
			promoName: selectedDiscount.name,
			discountPercentForGroup: selectedDiscountPrecent,
			discountApprovedByEmployeeID: employeeId,
			discountType: discountType == 1 ? Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT : Storage.Entity.Promotion.DISCOUNT_TYPE_FIX,
			discountAmountForGroup: discountValue,
		}
		let discountRemoveCondition = (pr:Promotions.PromoGroup) => true;

		for (const entity of entityArray) {
					// Decide what to do based on the discount type
		if (posDiscountVC.isSaleItem(entity)) {
			discountPg.discountRowNumber = entity.rowNumber;
			discountPg.rowToShowOn = entity.rowNumber;
			discountRemoveCondition = (pr:Promotions.PromoGroup) => pr.discountRowNumber != discountPg.discountRowNumber
		}
		else {
			discountRemoveCondition = (pr:Promotions.PromoGroup) => pr.discountRowNumber != null;
			discountPg.isSaleDiscount = true;
		}

		//remove previous item/sale discount if we have one and apply the new one
		let jd = JSON.parse(sale.jsondata)

		if (jd.discounts != null) {
			jd.discounts = jd.discounts.filter(discountRemoveCondition);
		}
		else {
			jd.discounts = [];
		}
		jd.discounts.push(discountPg);
		sale.jsondata = JSON.stringify(jd);
		}

		if (posVC.sale && !posUtils.isBlank(posVC.sale.id) && sale.id == posVC.sale.id) { //make sure that we don't just apply the discount on posVC.sale
			let updatedSaleItems = posVC.updatePromotions()
			await posVC.saleUpdated(updatedSaleItems);
		} else {
			return sale;
		}
	}

	async addDiscountForInternetSale(discountAmount:number, saleTotalAmount:number, sale:Storage.Entity.Sale) {
		let internetDiscount = await Storage.Entity.Discount.fetchByDiscountId(jsonConfig.getVal(jsonConfig.KEYS.internetOrderDiscountCode));
		let discountPercent = (discountAmount/saleTotalAmount) * 100;

		if (session.pos.useNewPromotions) {
			sale = await posDiscountVC.addDiscountNewStructure(session.pos.employeeID,internetDiscount,
				discountPercent, [sale], Number(Storage.Entity.SaleItem.DiscountType.PERCENT), 
				discountAmount, sale);
		} 
		sale.discountID = internetDiscount.discountID;
		sale.discountName = internetDiscount.name;
		sale.discountPercent = String(session.fixedFloat(discountPercent, 2));
		sale.discountApprovedByEmployeeID = session.pos.employeeID;
		sale.saleDiscountAmount = discountAmount;
		sale.discountType = "1";
		sale.promotionCode = '';
		sale.saleDiscountAllowedWithOtherPromotions = false;

		let totals = Helper.SaleHelper.calcuateSaleTotals(sale, sale.items, []);
		sale.totalVatableAmount -= totals.totalVatableAmount - discountAmount;
		sale.totalAmount -= discountAmount;
		
		return sale;
	}

	addDiscount(employeeID?) {
		if (employeeID == null) {
			employeeID = -1;
		}

		// Determine the employee ID
		if (posDiscountVC.isManager && !isNaN(parseInt(employeeID, 10))) {
			employeeID = parseInt(employeeID, 10);
		}

		if (session.pos.useNewPromotions) {
			return posDiscountVC.addDiscountNewStructure(employeeID,posDiscountVC.selectedDiscount,
				posDiscountVC.selectedDiscountPrecent, posDiscountVC.entities, posDiscountVC.discountType, 
				posDiscountVC.discountValue);
		}

		if (posDiscountVC.selectedDiscountPrecent === 0) {
			pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
			return;
		}

		// Set the discount for the entity
		for (const entity of posDiscountVC.entities) {
			entity.discountID = posDiscountVC.selectedDiscount.discountID;
			entity.discountName = posDiscountVC.selectedDiscount.name;
			entity.discountPercent = posDiscountVC.selectedDiscountPrecent;
			entity.discountApprovedByEmployeeID = employeeID;
	
			// Decide what to do based on the discount type
			switch (posDiscountVC.discountOwnerType) {
				case PositiveTS.Storage.Entity.Discount.TYPE_ITEM:
					(<Storage.Entity.SaleItem>entity).getPromotionCode = -1;
					(<Storage.Entity.SaleItem>entity).buyPromotionCode = -1;
					(<Storage.Entity.SaleItem>entity).discountAmount = -1;
	
					// if (posDiscountVC.selectedDiscount.typeID == PositiveTS.Storage.Entity.SaleItem.DiscountType.BARCODE_AMOUNT){
					// 	(<any>posDiscountVC.entity).doNotExtractDiscAmntFromPct = true;
					// 	(<any>posDiscountVC.entity).minimumAmount = posDiscountVC.selectedDiscount.minimumAmount;
					// }
	
					entity.discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT;
	
					posVC.persistSaleItem((<Storage.Entity.SaleItem>entity));
	
				case PositiveTS.Storage.Entity.Discount.TYPE_SALE:
					(<Storage.Entity.Sale>entity).discountType = "1";
					(<Storage.Entity.Sale>entity).promotionCode = '';
					(<Storage.Entity.Sale>entity).saleDiscountAllowedWithOtherPromotions = posDiscountVC.selectedDiscount.allowDuplicatePromotion;
					posVC.saleUpdated();
			}
		}


	}
	// --------------------------------------------------------------------
	// Listeners
	// --------------------------------------------------------------------
	step_1_continue() {
		// --- Step 2 initialization ----------------------------------------------------------------------
		// Get the selected discount
		let discount = posDiscountVC.getSelectedDiscount();

		// If there is no selected discount, we will get null
		if (discount === null) {
			// There is no selected discount, we are done!
			return;
		}

		// Set the selected discount
		posDiscountVC.selectedDiscount = discount;


		// Set the details of the selected discount
		$('#pos-discount-selected-discount-name').text(discount.name);
		if (isNaN(parseInt(<any>discount.approvedPrecent, 10))) {
			$('#pos-discount-selected-discount-approved').text('0%');
		} else {
			$('#pos-discount-selected-discount-approved').text(discount.approvedPrecent + '%');
		}
		if (isNaN(parseInt(<any>discount.managerPrecent, 10))) {
			$('#pos-discount-selected-discount-manager').text('0%');
		} else {
			$('#pos-discount-selected-discount-manager').text(discount.managerPrecent + '%');
		}

		// Show the 2nd step
		posDiscountVC.wizard.bjqsGo('forward', false);
		posDiscountVC.currentStep = 2;

		// Get the approved precent as INT
		var approved = parseInt(<any>discount.approvedPrecent, 10);
		if (isNaN(approved)) {
			approved = 0;
		}

		// Set the default value for the value of discount precent to the approved precent
		var discountToPut = (discount.approvedPrecent < 100) ? discount.approvedPrecent : 0;

		if (session.pos.hasFlights && discount.discountID == Storage.Entity.FlightsDiscountCodes.complimentary) {
			discountToPut = 100;
		}
		
		$('#pos-discount-keypad').keypad().setValue(discountToPut);

		// Select the default so it can be easily overwritten
		$('#pos-discount-precent').select();

		if(session.pos.hasFlights && (discount.discountID ==  Storage.Entity.FlightsDiscountCodes.complimentary || discount.discountID == Storage.Entity.FlightsDiscountCodes.crew )){
			$(document).unbind('keypress');
			(<HTMLInputElement>document.getElementById("pos-discount-precent")).disabled = true;
			document.getElementById("pos-discount-keypad").style.display = "none";
		}
		else if(session.pos.hasFlights){
			posDiscountVC.bindKeyboard();
			(<HTMLInputElement>document.getElementById("pos-discount-precent")).disabled = false;
			document.getElementById("pos-discount-keypad").style.display = "block";
		}

		// Hide the error message
		$('#pos-discount-precent-errorbarcodeDiscount').hide();

		//posDiscountVC.barcodeDiscount.posDiscountVC_step_1_continue();
		$("#pos-discount-manager-card").attr("placeholder", i18next.t("managerApprovalPlaceholder"));
		$("#pos-discount-manager-title").text(i18next.t("managerApprovalTitle"));
		$("#pos-discount-manager-header").text(i18next.t("itemDiscount.step3Title"));
	}
	async step_2_continue() {
		// --- Step 3 initialization ----------------------------------------------------------------------
		// Get the selected discount precent
		var selectedDiscount = $('#pos-discount-keypad').keypad().getValue();

		// If it is not a number, it means that the user entered invalid text
		if (isNaN(selectedDiscount)) {
			// We are done!
			return;
		}

		var selectedDiscountPrecent = selectedDiscount;

		if (posDiscountVC.discountType == Number(PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT)) {
			selectedDiscountPrecent = ((posDiscountVC.discountAmountOf - selectedDiscount) * 100 / posDiscountVC.discountAmountOf);
		}

		// Get the manager precent
		var managerDiscountInt = parseFloat(<any>posDiscountVC.selectedDiscount.managerPrecent);

		// Check that the selected precent is not above the manager precent
		if (!isNaN(managerDiscountInt) && selectedDiscountPrecent > managerDiscountInt) {
			// It is above the manager discount, than highlight the precent field
			$('#pos-discount-precent').effect("highlight", {}, 3000);

			// Set an error message
			$('#pos-discount-precent-error').text(i18next.t("discountPrecentHigherThenAllowed"));

			// Show the error message
			$('#pos-discount-precent-error').show('slow');
      		return;
		}

		// Everything is ok, so set the selected discount precent
		posDiscountVC.selectedDiscountPrecent = selectedDiscountPrecent;
		if (session.pos.useNewPromotions) {
			posDiscountVC.discountValue = selectedDiscount;
		}

		// Get the approved precent as INT
		var approved = parseFloat(<any>posDiscountVC.selectedDiscount.approvedPrecent);

		// If the selected discount precent is below the approved precent, skip the manager card step
		if (!isNaN(approved) && posDiscountVC.selectedDiscountPrecent <= approved) {
			// Set that we don't need manager approval
			posDiscountVC.isManager = false;
			// We are currently in step 3, the step in which a manager card is required. Since we don't need a manager card, we just call continue to skip it.
			posDiscountVC.addDiscount()
			pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
			// We are done!
			return;
		}

		// We do need manager approval
		posDiscountVC.isManager = true;
		let managerApproveAction = await PositiveTS.Service.GlobalUI.showManagerApprovalDialog([PositiveTS.Storage.Entity.Employee.APPROVES_DISCOUNT]); 
		if(managerApproveAction){
			let suspiciousAction;
			switch (posDiscountVC.discountOwnerType) {
				case PositiveTS.Storage.Entity.Discount.TYPE_ITEM:
					suspiciousAction = Shared.Constants.SuspiciousActions.SALE_ITEM_DISCOUNT;
					break;
				case PositiveTS.Storage.Entity.Discount.TYPE_SALE:
					suspiciousAction = Shared.Constants.SuspiciousActions.SALE_DISCOUNT;
					break;
				default:
					break;
			}
			let discount = posDiscountVC.selectedDiscount._data;
			let target_name =  `${discount.name} של ${selectedDiscountPrecent}%`;
			Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(suspiciousAction, session.pos.employeeID,managerApproveAction.employeeID, posDiscountVC.discountAmountOf, target_name);
			posDiscountVC.addDiscount(managerApproveAction.employeeID)
			pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
		}
	}

	async specialDiscountContinue() {
		let specialDiscountsTableView = $('#pos-special-discount-tableview').tableview();
	  
		let selectedDiscountRow = specialDiscountsTableView.getSelectedRow();
		if (selectedDiscountRow === null || selectedDiscountRow.length < 1) {
		  return false;
		}
	  
		let selectedDiscount = selectedDiscountRow.data('discount');
		if (selectedDiscount === null) {
		  return false;
		}

		if (session.pos.useNewPromotions) {
			posDiscountVC.discountValue = selectedDiscount;
		}
	  
		// Show loading message while we are loading data from remote server
		app.showLoadingMessage(i18next.t("specialDiscountUse"));
	  
		try {
		  // Update special discount if the sale using spacial discount
		  await PositiveTS.Service.SpecialDiscounts.useSpecialDiscount(selectedDiscount.id, posVC.sale.invoiceSequence);
		  
		
		if (session.pos.useNewPromotions) {
			let discounts = await Storage.Entity.Discount.fetchByStoreAndType(session.pos.storeID, Storage.Entity.Discount.TYPE_SALE, null)
			if (discounts[0] == null) {
				throw new Error(i18next.t('itemDiscount.specialDiscountError'));
			}
			posDiscountVC.selectedDiscount = selectedDiscount;
			posDiscountVC.selectedDiscount.name = selectedDiscount.remark;
			posDiscountVC.selectedDiscount.approvedPrecent = selectedDiscount.discount_percent;
			posDiscountVC.selectedDiscount.discountID = discounts[0].discountID;
			posDiscountVC.selectedDiscountPrecent = selectedDiscount.discount_percent;
			posDiscountVC.discountValue = selectedDiscount.discount_percent;
			posVC.sale.discountType = PositiveTS.Storage.Entity.Discount.SPECIAL_DISCOUNT_TYPE;
			posVC.sale.discountID = selectedDiscount.id;
			posVC.sale.discountPercent = "-1";
			posVC.sale.discountName = selectedDiscount.remark;
		  	posDiscountVC.addDiscount()
		}
		else {
		  posVC.sale.discountID = selectedDiscount.id;
		  posVC.sale.discountName = selectedDiscount.remark;
		  posVC.sale.discountPercent = selectedDiscount.discount_percent;
		  posVC.sale.discountType = PositiveTS.Storage.Entity.Discount.SPECIAL_DISCOUNT_TYPE;
	  
		  posVC.saleUpdated();
		}
	  
		  pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
		} catch (error) {
		  posDiscountVC.displayErrorMessage(error.message);
		} finally {
		  app.hideLoadingMessage();
		}
	  }

	isInvalidComplimentarySale() {
		if (session.pos.hasFlights && posDiscountVC.getSelectedDiscount() &&
			posDiscountVC.getSelectedDiscount().discountID == Storage.Entity.FlightsDiscountCodes.complimentary) {
			let isAllItemsComplimentary = true;

			for (let saleItem of posVC.saleItems) {
				if (!(session.allItems.get(saleItem.itemCode) && session.allItems.get(saleItem.itemCode).isComplimentary)) {
					isAllItemsComplimentary = false;
					break;
				}
			}

			if (!isAllItemsComplimentary) {
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('complimentarySale.notAllComplimentaryItems'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
				return true;
			}
		}

		return false;
	}

	continue() {
		var aThis = posDiscountVC;

		if (aThis.inSpecialDiscounts) {
			return aThis.specialDiscountContinue();
		}

		if (aThis.isInvalidComplimentarySale()) {
			return;
		}


		var stepsContinue = [];
		stepsContinue[1] = aThis.step_1_continue;
		stepsContinue[2] = aThis.step_2_continue;

		// Decide what to do based on the current step
		if (aThis.currentStep >=1 && aThis.currentStep <= 2) {
			stepsContinue[aThis.currentStep]();
		}
	}
	back() {
		var aThis = posDiscountVC;

		// Special behavior when in special discounts
		if (aThis.inSpecialDiscounts) {
			aThis.backFromSpecialDiscount();
			return;
		}


		// Decide what to do based on the current step
		if (aThis.currentStep == 1) {
			// Back from the first step means back in the navigation stack
			pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
		} else if (aThis.currentStep >= 2) {
			// Back from second step on, means back in the wizard
			aThis.wizard.bjqsGo('backward', false);

			// Update the current step
			aThis.currentStep = aThis.currentStep - 1;

			//posDiscountVC.barcodeDiscount.posDiscountVC_back();


		}
	}
	getSelectedDiscount():Storage.Entity.Discount {
		var aThis = posDiscountVC;

		// Get the selected row
		var theRow = $('#pos-discount-tableview').tableview().getSelectedRow();

		// If there are no selected rows, return null
		if (theRow.length === 0) {
			return null;
		}

		// There are selected rows. Take the ID of the first one
		var rowID = $(theRow[0]).attr('id');

		// Return the entity from the local discounts array
		return aThis.discounts[rowID];
	}
	goToSpecialDiscount() {
		let aThis = posDiscountVC;

		// Show loading message while we are loading data from remote server
		app.showLoadingMessage(i18next.t("loadingData"));

		PositiveTS.Service.SpecialDiscounts.getDiscounts(function(discounts) {

			if (discounts === null) {
				app.hideLoadingMessage();
				// Tell the user
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('specialDiscountsNotFound'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
				return;
			}

			$('#pos-discount-special').hide();


			aThis.inSpecialDiscounts = true;

			aThis.wizard.bjqsGo(null, 4);

			var tableview = $('#pos-special-discount-tableview').tableview();

			tableview.empty();

			for (var i = 0; i < discounts.length; i++) {
				var discount = discounts[i];

				var currentRow = tableview.addRow(posUtils.sanitizeTableViewRow([
					PositiveTS.DateUtils.fullFormat(discount.created_at),
					discount.approver_name,
					discount.customer_name,
					discount.discount_percent + '%',
					discount.remark
				]));

				currentRow.data('discount', discount);
			}

			app.hideLoadingMessage();
		});
	}
	backFromSpecialDiscount() {
		let aThis = posDiscountVC;

		$('#pos-discount-special').show();

		aThis.inSpecialDiscounts = false;

		aThis.wizard.bjqsGo(null, aThis.currentStep);
	}

	removeDiscountFromItem(saleItem:Storage.Entity.SaleItem, disablePromotions = false) {
		if (!session.pos.useNewPromotions) {
			return;
		}
		let jd = JSON.parse(posVC.sale.jsondata)

		if(jd.discounts != null && !disablePromotions) {
			jd.discounts = jd.discounts.filter((dg:Promotions.PromoGroup) => dg.rowToShowOn != saleItem.rowNumber);
		}

		if(disablePromotions) {
			saleItem.disablePromotions = true

			if(jd.promotions != null) {
				jd.promotions = jd.promotions.filter((pg: Promotions.PromoGroup) => (pg.rowToShowOn != saleItem.rowNumber) ||  (pg.rowToShowOn == saleItem.rowNumber && pg.isDiscount))
			}
		}
		
		posVC.sale.jsondata = JSON.stringify(jd);

		let updatedSaleItems = posVC.updatePromotions()
		posVC.saleUpdated(updatedSaleItems);
	}

	getSaleDiscountAmount():number {
		if (!session.pos.useNewPromotions) {
			return 0;
		}
		let jd = JSON.parse(posVC.sale.jsondata)

		if (jd.promotions != null) {
			let sd = jd.promotions.filter((dg:Promotions.PromoGroup) => dg.isSaleDiscount)[0];
			return sd == null ? 0 : sd.discountAmountForGroup;
		}
		else {
			return 0;
		}
	}

	removeSaleDiscount() {
		if (!session.pos.useNewPromotions) {
			return;
		}
		let jd = JSON.parse(posVC.sale.jsondata)

		if (jd.discounts != null) {
			jd.discounts = jd.discounts.filter((dg:Promotions.PromoGroup) => dg.rowToShowOn != null);
		}
		
		posVC.sale.jsondata = JSON.stringify(jd);

		let updatedSaleItems = posVC.updatePromotions()
		posVC.saleUpdated(updatedSaleItems);

	}
}}}}
declare var posDiscountVC:PositiveTS.Application.Controllers.PosDiscountViewController;
posDiscountVC = new PositiveTS.Application.Controllers.PosDiscountViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.PosDiscountViewController = posDiscountVC;
