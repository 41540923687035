module PositiveTS {

    export module Components {

        export module AddOrEditPromotion {

            export module KneBe{

                export const route = "kne-be";
                  
                export function getComponent() {
        
                  return {

                      template: JST.KneBe(),

                      methods: {
                        ...Components.Mixins.modalsMixin.methods,
                        ...Shared.methods
                      },

                      computed: {
                        ...Shared.computed
                      }
                  }
      
                }

            }
          
         
        }
    }

}
