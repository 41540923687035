module PositiveTS {
export module Promotions {
export module Templates {
export class KneKabel extends TemplateAbstract {
  private idToQty
  private promoGroups = {}
  private allItems = {}
  private minBuyQuantity
  private minBuyAmount
  private allowMultipleTimesPerSale
  private promotionApplied;
  private maxQuantityToGiveCustomer;

  constructor(initParameters) {
    super(initParameters)

    this.promotionApplied = false;
    this.idToQty = {};
  }
  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (let promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      var promotion = this.parameters.promotionsByCode[promotionCode];

      // Check that this promotion is from template 12
      if (promotion.template !== '12') {
        continue;
      }

      this.run(promotion)
    }
  }

  run(promotion:Storage.Entity.Promotion) {
    this.promoGroups = {};
    if (!this.valid(promotion)) {
      return;
    }

    let {buyItems, getItems} = this.initData(promotion);
    while (this.performRun(buyItems,getItems,promotion)) {}

    this.addToPromoGroups()
  }

  private includeItem(item:Storage.Entity.SaleItem, itemsIncludedInRun, isGet = false) {
    this.idToQty[item.id] -= this.getQuantity(item,isGet)
    itemsIncludedInRun[item.id] = itemsIncludedInRun[item.id] || 0
    itemsIncludedInRun[item.id] += this.getQuantity(item,isGet)
  }

  private removeItem(item, itemsIncludedInRun) { //we always call this method with buy items..
    this.idToQty[item.id] += this.getQuantity(item, false) 
    itemsIncludedInRun[item.id] -= this.getQuantity(item, false)
  }

  private getQuantity(item:Storage.Entity.SaleItem, isGet = false, timesToApplyPromotion = Infinity):number {
    let qty = item.hasWeight ? Math.min(item.quantity,1) : 1
    if (isGet && item.hasWeight && this.maxQuantityToGiveCustomer && this.maxQuantityToGiveCustomer > 0) {
      qty = Math.min(qty,this.maxQuantityToGiveCustomer,timesToApplyPromotion)
    }
    return qty    
  }

  // private getActualTimesThatPromotionCanBeApplied(item,timesToApplyPromotion) {
  //   let tmpQuant = item.quantity
  //   if (!item.hasWeight) {
  //     return Math.min(timesToApplyPromotion,item.quantity)
  //   }
  //   else {
  //     return this.getQuantity(item,true,timesToApplyPromotion)
  //   }
    
  // }

  private getDiscountValue(item:Storage.Entity.SaleItem,promotion:Storage.Entity.Promotion, timesToApplyPromotion:number) {

    let timesThatPromotionCanBeApplied = this.getQuantity(item,true,timesToApplyPromotion)

    switch (promotion.discountType) {

      case Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
        return promotion.discountValue*timesThatPromotionCanBeApplied;
      case Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
        return Math.max(0,item.unitPrice*timesThatPromotionCanBeApplied-promotion.discountValue)
      case Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
        return item.unitPrice*timesThatPromotionCanBeApplied*promotion.discountValue/100.0;
    }
  }

  //validate if promotion can be applied
  private valid(promotion:Storage.Entity.Promotion) {
    if (Number(promotion.minimumBuyQuantity) <= 0) {
      console.error('quantity is smaller or equal to 0 - not applying promotion')
      return false;
    }
    if (!this.parameters.promotionsGet[promotion.code] || !this.parameters.promotionsBuy[promotion.code]) {
      return false;
    }
    let totalsForBuy = this.countTotalQuantityAndAmountOfSaleItems(this.parameters.promotionsBuy[promotion.code]);
    let totalsForGet = this.countTotalQuantityAndAmountOfSaleItems(this.parameters.promotionsGet[promotion.code]);

    let getItemsHaveWeight = this.parameters.promotionsGet[promotion.code].filter(item => item.hasWeight).length > 0
    if ((!getItemsHaveWeight && totalsForGet.totalQuantity < 1) || (totalsForGet.totalQuantity <= 0)) {
      console.warn('promotion does not have enough on the get side - exiting');
      return false;
    }

    return true;
  }

  private initData(promotion) {
    this.minBuyQuantity = Number(promotion.minimumBuyQuantity)
    this.minBuyAmount = Number(promotion.minimumBuyAmount)
    this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale)
    this.allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions)
    this.maxQuantityToGiveCustomer = promotion.maxQuantityToGiveCustomer || 0;

    let flattenedSaleItemsBySide = {
      'buy': this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code]),
      'get': this.flattenSaleItemsByQuantity(this.parameters.promotionsGet[promotion.code])
    };

    let items = this.parameters.promotionsBuy[promotion.code].concat(this.parameters.promotionsGet[promotion.code])
    for (let item of items) {
      if (item) {
        this.allItems[item.id] = item;
      }
    }


    let getItems = flattenedSaleItemsBySide.get.sort(this.sortByUnitPriceFromExpensiveToCheapest);
    let buyItems = flattenedSaleItemsBySide.buy.sort((a,b) => {
      //sort buy items from cheapest to expensive - put the get items at the end of the array from most expensive to cheapest
      let itemAinGet = Boolean(getItems.filter((item) => { return item.id == a.id})[0]);
      let itemBinGet = Boolean(getItems.filter((item) => { return item.id == b.id})[0]);

      if (itemAinGet && !itemBinGet) {
        return 1;
      }
      if (itemBinGet && !itemAinGet) {
        return -1;
      }
      let aQuant = (a.hasWeight ? a.quantity : 1)
      let bQuant = (b.hasWeight ? b.quantity : 1)

      if (itemAinGet && itemBinGet) { //TODO: not sure about this... maybe getitems at end should also be from cheapest to most expensive....
        return bQuant*b.unitPrice - aQuant*a.unitPrice;
      }
      else {
        return aQuant*a.unitPrice - bQuant*b.unitPrice;
      }
    });



    buyItems.forEach((item) => { 
      this.idToQty[item.id] = this.idToQty[item.id] || 0
      this.idToQty[item.id] += item.quantity 
    })
    getItems.forEach((item) => { 
      this.idToQty[item.id] = this.idToQty[item.id] || 0
      if (buyItems.filter(bi => bi.id === item.id).length === 0) {
        this.idToQty[item.id] += item.quantity 
      }
    })
    return {buyItems: buyItems, getItems: getItems}
  }

  private updateRemainingItems(getItems) {
    let idToQtyCopy = Object.assign({},this.idToQty)
    let remainingItems = []

    for (let item of getItems) {
      if (item.quantity <= idToQtyCopy[item.id]) {
        idToQtyCopy[item.id] -= item.quantity
        remainingItems.push(item)
      }
    }
    return remainingItems;
  }

  private reduceQuantityOfPromotedItemFromGet(getItems,itemToPromote,quantityToReduce) {
    let updatedGetItems = []
    for (let item of getItems) {
      if (item.id == itemToPromote.id && quantityToReduce > 0) {
        let qtyToReduce = Math.min(item.quantity,quantityToReduce)
        if (qtyToReduce < item.quantity) {
          //if we got here it means that from now on quantToReduce will be 0
          let itemCopy = item.clone()
          itemCopy.quantity -= qtyToReduce
          updatedGetItems.push(itemCopy);
        }
        quantityToReduce -= qtyToReduce
      }
      else {
        updatedGetItems.push(item)
      } 
    }
    return updatedGetItems;
  }

  //get the maximum amount of times that promotion can be applied without actually applying it...
  private performDryRun(buyItems:Array<Storage.Entity.SaleItem>, getItems:Array<Storage.Entity.SaleItem>, promotion:Storage.Entity.Promotion) {
    let [totalPriceForRun, totalQuantityForRun, itemsIncludedInRun] = [0,0,{}];
    let maxDiscountValue = -Infinity
    let maxTimesToApplyPromotion = -Infinity
    let timesToDiscountValueMap = {}

    for (let item of buyItems) {

      if (!this.allowMultipleTimesPerSale && this.promotionApplied) {
        break;
      }
      if (this.idToQty[item.id] <= 0) {
        continue;
      }
      
      let qty = this.getQuantity(item)
      totalPriceForRun += qty*item.unitPrice
      totalQuantityForRun += qty

      this.includeItem(item,itemsIncludedInRun)

      if (totalPriceForRun >= this.minBuyAmount && totalQuantityForRun >= this.minBuyQuantity) {
        //find the first relevant get item and apply the promotion on it...
        let itemToPromote:Storage.Entity.SaleItem;

        let remainingGetItems = this.updateRemainingItems(getItems)
        
        let timesToApplyPromotion = Math.floor(Math.min(totalPriceForRun/this.minBuyAmount,totalQuantityForRun/this.minBuyQuantity))
        let timesToApplyPromotionByMaxQty = timesToApplyPromotion;
        if (this.maxQuantityToGiveCustomer > 0 && this.maxQuantityToGiveCustomer < 1) {
          timesToApplyPromotionByMaxQty = this.maxQuantityToGiveCustomer*timesToApplyPromotion
        }
        else if (this.maxQuantityToGiveCustomer > 0 && !this.allowMultipleTimesPerSale) {
          timesToApplyPromotionByMaxQty = Math.min(timesToApplyPromotion,this.maxQuantityToGiveCustomer)
        }

        timesToDiscountValueMap[timesToApplyPromotion] = timesToDiscountValueMap[timesToApplyPromotion] || 0


        if (remainingGetItems.length > 0) {
          itemToPromote = remainingGetItems.sort((a,b) => {
            return this.getDiscountValue(b,promotion,timesToApplyPromotionByMaxQty)-this.getDiscountValue(a,promotion,timesToApplyPromotionByMaxQty) 
          })[0]
          
          timesToDiscountValueMap[timesToApplyPromotion] = 
            Math.max(this.getDiscountValue(itemToPromote,promotion,timesToApplyPromotionByMaxQty),timesToDiscountValueMap[timesToApplyPromotion])


          
          if (timesToApplyPromotion > maxTimesToApplyPromotion) {

            let tmpRemainingGet = remainingGetItems
            let totalQuantityOfItemsToPromote = this.getQuantity(itemToPromote,true,timesToApplyPromotionByMaxQty)
            let currentQunatityToPromote = totalQuantityOfItemsToPromote
            let nextItemToPromote = itemToPromote;
            
            while (totalQuantityOfItemsToPromote < timesToApplyPromotionByMaxQty && tmpRemainingGet.length > 0) {

              tmpRemainingGet = this.reduceQuantityOfPromotedItemFromGet(tmpRemainingGet,nextItemToPromote,currentQunatityToPromote)

              let actualTimesToPromote = timesToApplyPromotionByMaxQty-totalQuantityOfItemsToPromote

              nextItemToPromote = tmpRemainingGet.sort((a,b) => {
                return this.getDiscountValue(b,promotion,actualTimesToPromote)-this.getDiscountValue(a,promotion,actualTimesToPromote) 
              })[0]

              if (nextItemToPromote) {
                timesToDiscountValueMap[timesToApplyPromotion] += this.getDiscountValue(nextItemToPromote,promotion,actualTimesToPromote)
                currentQunatityToPromote = this.getQuantity(nextItemToPromote,true,actualTimesToPromote)
                totalQuantityOfItemsToPromote += currentQunatityToPromote
                maxTimesToApplyPromotion = Math.max(maxTimesToApplyPromotion,timesToApplyPromotion)
              }

            }
          }
          maxTimesToApplyPromotion = Math.max(timesToApplyPromotion,maxTimesToApplyPromotion)
          if (!this.allowMultipleTimesPerSale) {
            maxTimesToApplyPromotion = Math.min(this.maxQuantityToGiveCustomer,maxTimesToApplyPromotion)
          }

        }
      }
      
    }


    //reset quantities back...
    for (let itemId in itemsIncludedInRun) { 
      this.idToQty[itemId] += itemsIncludedInRun[itemId]
      itemsIncludedInRun[itemId] = 0
    }

    return timesToDiscountValueMap
  }

  private performRun(buyItems:Array<Storage.Entity.SaleItem>, getItems:Array<Storage.Entity.SaleItem>, promotion:Storage.Entity.Promotion) {
    let [totalPriceForRun, totalQuantityForRun, itemsIncludedInRun] = [0,0,{}];
    let maxDiscountValue = -Infinity
    let maxTimesToApplyPromotion = -Infinity
    let timesToDiscountValueMap = this.performDryRun(buyItems,getItems,promotion)
    

    for (let key in timesToDiscountValueMap) { maxDiscountValue = Math.max(timesToDiscountValueMap[key],maxDiscountValue) }
    for (let key in timesToDiscountValueMap) {
      if (timesToDiscountValueMap[key] == maxDiscountValue) {
        maxTimesToApplyPromotion = Number(key);
        break;
      }
    }

     if (!this.allowMultipleTimesPerSale) {
      maxTimesToApplyPromotion = Math.min(this.maxQuantityToGiveCustomer,maxTimesToApplyPromotion)
     }


    if (maxDiscountValue == 0) {
      return false;
    }

    
    for (let item of buyItems) {

      if (!this.allowMultipleTimesPerSale && this.promotionApplied) {
        break;
      }
      if (this.idToQty[item.id] <= 0) {
        continue;
      }
      
      let qty = this.getQuantity(item)
      totalPriceForRun += qty*item.unitPrice
      totalQuantityForRun += qty

      this.includeItem(item,itemsIncludedInRun)

      if (totalPriceForRun >= this.minBuyAmount && totalQuantityForRun >= this.minBuyQuantity) {
        //find the first relevant get item and apply the promotion on it...
        let itemToPromote = null;
        let remainingGetItems = this.updateRemainingItems(getItems)
        
        let timesToApplyPromotion = Math.floor(Math.min(totalPriceForRun/this.minBuyAmount,totalQuantityForRun/this.minBuyQuantity))
        let timesToApplyPromotionByMaxQty = timesToApplyPromotion;
        if (this.maxQuantityToGiveCustomer > 0 && this.maxQuantityToGiveCustomer < 1) {
          timesToApplyPromotionByMaxQty = this.maxQuantityToGiveCustomer*timesToApplyPromotion
        }
        else if (this.maxQuantityToGiveCustomer > 0 && !this.allowMultipleTimesPerSale) {
          timesToApplyPromotionByMaxQty = Math.min(timesToApplyPromotion,this.maxQuantityToGiveCustomer)
        }

        if (remainingGetItems.length > 0) {
          itemToPromote = remainingGetItems.sort((a,b) => {
            return this.getDiscountValue(b,promotion,timesToApplyPromotionByMaxQty)-this.getDiscountValue(a,promotion,timesToApplyPromotionByMaxQty) 
          })[0]


          if (timesToApplyPromotion >= maxTimesToApplyPromotion) {

            timesToApplyPromotion = Math.min(maxTimesToApplyPromotion,timesToApplyPromotion) //for when allow multiple times same sale is false

            this.addPromoGroup(itemToPromote,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun, promotion, timesToApplyPromotionByMaxQty, timesToApplyPromotion)

            let tmpRemainingGet = remainingGetItems//= remainingGetItems.map(item => item.clone())
            let totalQuantityOfItemsToPromote = this.getQuantity(itemToPromote,true,timesToApplyPromotionByMaxQty)
            let currentQunatityToPromote = totalQuantityOfItemsToPromote
            let nextItemToPromote = itemToPromote;

            while (totalQuantityOfItemsToPromote < timesToApplyPromotionByMaxQty && tmpRemainingGet.length > 0) {

              tmpRemainingGet = this.reduceQuantityOfPromotedItemFromGet(tmpRemainingGet,nextItemToPromote,currentQunatityToPromote)

              let actualTimesToPromote = timesToApplyPromotionByMaxQty-totalQuantityOfItemsToPromote
              nextItemToPromote = tmpRemainingGet.sort((a,b) => {
                return this.getDiscountValue(b,promotion,actualTimesToPromote)-this.getDiscountValue(a,promotion,actualTimesToPromote)  
              })[0]

              if (nextItemToPromote) {
                
                this.addAdditionalItemToExistingPromoGroup(itemToPromote, nextItemToPromote, itemsIncludedInRun, promotion, actualTimesToPromote)

                currentQunatityToPromote = this.getQuantity(nextItemToPromote,true,actualTimesToPromote)
                totalQuantityOfItemsToPromote += currentQunatityToPromote
              }

            }
            return true;
          }
            
        }
        else {
          return false
        }
      }
      
    }
    return false;

  }

  private addAdditionalItemToExistingPromoGroup(itemToPromote, nextItemToPromote, itemsIncludedInRun, promotion, actualTimesToPromote) {
    let promoGroup = this.promoGroups[itemToPromote.id];
    this.includeItem(nextItemToPromote,itemsIncludedInRun,true);

    promoGroup.discountAmountForGroup += this.getDiscountValue(nextItemToPromote,promotion,actualTimesToPromote)
    promoGroup.totalPriceForItemsBeforeDiscount = this.getTotalPriceForItemsInRun(itemsIncludedInRun)

    this.addToItemsCounter(this.allItems[nextItemToPromote.id],promoGroup.itemsCounter,itemsIncludedInRun[nextItemToPromote.id]);
    let item = {
      discountAbsoluteValue: session.fixedNumber(promoGroup.discountAmountForGroup),
      discountPrecentValue: session.fixedNumber(promoGroup.discountAmountForGroup/(itemToPromote.unitPrice * itemToPromote.realQuantity)*100),
      discountType: promotion.discountType == 'Percent' ? PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT : PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT,
      isPromotionGiven: true,
      saleItemID: itemToPromote.id,
      promotionCode: promotion.code,
      promotionName: promotion.name,
      buyGet: 'get'
    }

    promoGroup.item = item;
  }

  //remove items from the itemsIncludedInRun list that the promotion can still be applied without them
  private removeRedundantItems(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun, timesToApplyPromotion) {
    if (totalQuantityForRun == this.minBuyQuantity) {
      return;      
    }
    let [minQty,minAmount] = [this.minBuyQuantity*timesToApplyPromotion,this.minBuyAmount*timesToApplyPromotion]
    for (let id in itemsIncludedInRun) {
      for (let i=0; i< itemsIncludedInRun[id]; i++) {
        let qty = this.getQuantity(this.allItems[id])
        if (totalPriceForRun-qty*this.allItems[id].unitPrice >= minAmount && totalQuantityForRun-qty >= minQty) {
          totalQuantityForRun -= qty;
          totalPriceForRun -= qty*this.allItems[id].unitPrice;
          this.removeItem(this.allItems[id],itemsIncludedInRun);
        }
      }
    }
  }

  private addPromoGroup(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun, promotion, timesToApplyPromotion, promotionTimesMultiplier) {

    if (!this.promoGroups[itemToPromote.id]) {
      this.promoGroups[itemToPromote.id] = {
        itemsCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
    }
    // console.debug('before')
    // console.debug(itemsIncludedInRun)
    this.removeRedundantItems(itemToPromote,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun,promotionTimesMultiplier)
    // console.debug('after')
    // console.debug(itemsIncludedInRun)
    this.includeItem(itemToPromote,itemsIncludedInRun,true);
    let promoGroup = this.promoGroups[itemToPromote.id];

    this.promoGroups[itemToPromote.id].discountAmountForGroup += this.getDiscountValue(itemToPromote,promotion,timesToApplyPromotion)
    this.promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += this.getTotalPriceForItemsInRun(itemsIncludedInRun)

    for (let id in itemsIncludedInRun) {
      this.addToItemsCounter(this.allItems[id],this.promoGroups[itemToPromote.id].itemsCounter,itemsIncludedInRun[id]);
    }

    let item = {
      discountAbsoluteValue: session.fixedNumber(promoGroup.discountAmountForGroup),
      discountPrecentValue: session.fixedNumber(promoGroup.discountAmountForGroup/(itemToPromote.unitPrice * itemToPromote.realQuantity)*100),
      discountType: promotion.discountType == 'Percent' ? PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT : PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT,
      isPromotionGiven: true,
      saleItemID: itemToPromote.id,
      promotionCode: promotion.code,
      promotionName: promotion.name,
      buyGet: 'get'
    }

    promoGroup.item = item;
    this.promotionApplied = true
  }

  private getTotalPriceForItemsInRun(itemsIncludedInRun) {
    let totalPrice = 0
    for (let id in itemsIncludedInRun) {
      totalPrice+= this.allItems[id].unitPrice * itemsIncludedInRun[id];
    }
    return totalPrice;
  }

  private addToPromoGroups() {
    for (let key in this.promoGroups) {
      this.parameters.promoGroups.push(this.promoGroups[key]);
    }
  }

}
}}}
