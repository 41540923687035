module PositiveTS {
export module Service {
export module ValueCardDiscountItemService {

  export function addDiscount(discountAmount:number, doNotAddPrice = false):Promise<void>{
    if (discountAmount === 0 && !doNotAddPrice){
        return Promise.resolve();       
    }

    return _getdDiscountSaleitem().
    then( (saleitem)=>{
      
      saleitem.unitPrice -= discountAmount;  
      saleitem.priceNetoAfterDiscounts = saleitem.unitPrice; 
      
      if (saleitem.unitPrice === 0) {
        return posVC.deleteSaleItem(saleitem)
      } else {
        let itemsToUpdate = new Map<number,Storage.Entity.SaleItem>()
        itemsToUpdate.set(saleitem.rowNumber,saleitem)
        return posVC.saleUpdated( itemsToUpdate);   
      }    
    })

      
  }

  export async function clearPreviousDiscountIfExists(saleItems:Storage.Entity.SaleItem[]):Promise<any>{
    if(!isValueDiscountItemExists(saleItems) ) {return Promise.resolve()}
    let saleItem = await _getdDiscountSaleitem();
    await posVC.deleteSaleItem(saleItem)
    return;
  }

  export function isValueDiscountItemExists(saleItems:Storage.Entity.SaleItem[]){
    var itemCode = jsonConfig.getVal(jsonConfig.KEYS.valueCardDiscountItemCode)
    for (let saleItem of saleItems){
      if (saleItem.itemCode === itemCode) {
        return true
      }
    }
    return false;
  }

  function _getdDiscountSaleitem():Promise<PositiveTS.Storage.Entity.SaleItem>{
    var itemCode = jsonConfig.getVal(jsonConfig.KEYS.valueCardDiscountItemCode)
    for (let i=0; i< posVC.saleItems.length;i++){
      if (posVC.saleItems[i].itemCode === itemCode) {
        return Promise.resolve(posVC.saleItems[i])
      }
    }

    var item = new PositiveTS.Storage.Entity.Item();
    return item.searchByBarcode(itemCode)
    .then( (itemsAndBarcodes) => {

      if (itemsAndBarcodes.length !== 1){
        throw new Error(`Bad POS configuration, no itemCode ${itemCode}`)
      }

      // Pick the first item and get the item and its barcode
      var item:PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
      var itemBarcode = itemsAndBarcodes[0].itemBarcode;

      // Create new sale item entity
      var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
      saleItem.saleID = posVC.sale.id;
      saleItem.rowNumber = posVC.getRowNumber();
      saleItem.quantity = 1;
      return posVC.persistNewSaleItem(saleItem)
      .then( ()=>{
        return saleItem;
      });

    });
  }

}}}