module PositiveTS {
    export module Service {
        export module SittingTA {
            export let isTA: boolean = false;

            export function isEnabled() {
                return jsonConfig.getVal(jsonConfig.KEYS.enableSittingOrTA)
                    || jsonConfig.getVal(jsonConfig.KEYS.selfServiceSittingOrTaScreen);
            }

            export function isTASale() {
                return isTA;
            }

            export function isTASittingSelected() {
                if (posVC.sale) {
                    let jsonData = JSON.parse(posVC.sale?.jsondata);
                    if (jsonData.hasOwnProperty('isTA')) {
                        return true;
                    }
                }
                return false;
            }

            export async function setIsTA(isTA: boolean) {
                if (posVC.sale) {
                    this.isTA = isTA;
                    Pinia.posStore.setIsSaleTA(isTA);
                    let jsonData = JSON.parse(posVC.sale?.jsondata);
                    jsonData.isTA = isTA;
                    posVC.sale.jsondata = JSON.stringify(jsonData);
					await posVC.saleUpdated();
                }
            }

            export async function setTAItemInSale(){
                try{
                    let taItemCode = jsonConfig.getVal(jsonConfig.KEYS.taItemCode);
                    let result = await Service.AllItems.exactSearch(taItemCode);
                    if(result.length > 0){
                        posVC.addItemByBarcodeOrByCode(taItemCode,true)
                    }
                }catch(err){
                    console.error(err)
                }
                
            }
        }
    }
}