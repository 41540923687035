
export function useKeyPad() {
    // Your methods converted into standalone functions

    function getDecimalPrecision() {
        let decimalPrecision = 2;

        if (posUtils.isDefined(session.pos) && posUtils.isDefined(session.pos.parameterDecimalPrecision)) {
            decimalPrecision = session.pos.parameterDecimalPrecision;
        }
        return decimalPrecision;
    }

    function keyPadHandler(key, field, refValue, userDecimalPrecision = undefined) {
        let decimalPrecision = userDecimalPrecision ? userDecimalPrecision : this.getDecimalPercision()

        if (field) {
            let allowDecimal = field.allowDecimal && decimalPrecision != 0;
            if (field.inEditMode) {
                let modelStr = String(refValue.value)
                switch (key) {
                    case '.':
                        if (allowDecimal) {
                            field.hasDecimal = true
                        }
                        break;
                    case '-':
                        if (modelStr[0] != "-") {
                            refValue.value = "-" + modelStr;
                        }
                        break;
                    case 'bksp':
                        if (allowDecimal && field.hasDecimal && modelStr.indexOf('.') >= 0) {
                            if (modelStr.indexOf('.') === (modelStr.length - 2)) {
                                modelStr = modelStr.slice(0, -1);
                                field.hasDecimal = false
                            }
                            else {
                                modelStr = modelStr.slice(0, -1);
                                field.hasDecimal = modelStr[modelStr.length - 1] != '0'
                            }
                        }
                        else {
                            modelStr = modelStr.slice(0, -1);
                            if (field.hasDecimal && modelStr.indexOf('.') === -1) {
                                field.hasDecimal = false
                            }
                        }

                        if (modelStr == "-") {
                            modelStr = "0";
                        }

                        if (field.type == 'number') {
                            refValue.value = Number(modelStr)
                        }
                        else {
                            refValue.value = modelStr
                        }
                        break;
                    default:
                        if (allowDecimal && (field.hasDecimal || modelStr.indexOf('.') >= 0)) {
                            if (modelStr.indexOf('.') === -1) {
                                modelStr += '.'
                            }
                            else {
                                if (modelStr.indexOf('.') === (modelStr.length - decimalPrecision - 1)) {
                                    modelStr = modelStr.slice(0, -1);
                                }
                            }
                        }

                        modelStr += key
                        // not break the bank
                        if (field.type == 'number' && Number(modelStr) > 9999999999999999) {
                            return
                        }

                        if (modelStr[0] == "-" && modelStr[1] == "0" && field.type == 'number' && key != '0') {
                            refValue.value = Number(modelStr);
                        }
                        else if (modelStr[0] == '0' && field.type == 'number' && key != '0') {
                            refValue.value = Number(modelStr);
                        }
                        // else if((modelStr[0] != '0' || key != '0') && (((modelStr[0] != '-') || (modelStr[1] != '0')) || key != '0')){
                        //   refValue.value = modelStr
                        // }
                        else {
                            refValue.value = modelStr
                        }

                        if (field.type == 'number' && !posUtils.isBlank(field.max)) {
                            refValue.value = Math.min(Number(modelStr), Number(field.max))
                        }
                        if (field.type == 'number' && !posUtils.isBlank(field.min)) {
                            refValue.value = Math.max(Number(modelStr), Number(field.min))
                        }
                }

            }
            else {
                //set it in edit mode and change value to be the key
                field.inEditMode = true
                if (field.type == 'number') {
                    refValue.value = Number(key)
                }
                else {
                    refValue.value = key
                }
            }
        }
    }

    // Return the functions for use in components
    return {
        getDecimalPrecision,
        keyPadHandler
    };
}