module PositiveTS {
export module Promotions {
export module Templates {
export class HaZolMe extends TemplateAbstract {
  private idToQty
  private promoGroups = {}
  private allItems = {}
  private minBuyQuantity
  private minBuyAmount
  private allowMultipleTimesPerSale
  private promotionApplied;
  private maxQuantityToGiveCustomer:number
  private countByRows:boolean
  private templates;

  constructor(initParameters) {
    super(initParameters)
    this.templates = ['15']
    this.promotionApplied = false;
    this.promoGroups = {};
    this.idToQty = {};
  }

  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (let promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      var promotion = this.parameters.promotionsByCode[promotionCode];

      if (this.templates.indexOf(promotion.template) < 0 ) {
        continue;
      }
      
      this.run(promotion)
    }
  }

  run(promotion:Storage.Entity.Promotion) {
    this.promoGroups = {};
    if (!this.valid(promotion)) {
      return;
    }
    if (promotion.template == '13') {
      //don't mix between items
      let usedBarcodes = []
      for (let item of this.parameters.promotionsBuy[promotion.code]) {
        let currentBarcode = item.barcode;
        if (usedBarcodes.indexOf(currentBarcode) >= 0) {
          continue;
        }
        usedBarcodes.push(item.barcode);
        let buyItems = this.initData(promotion, item.barcode);
        while (this.performRun(buyItems, promotion)) {}
      }
    }
    else {
      let buyItems = this.initData(promotion);
      while (this.performRun(buyItems, promotion)) {}
    }

    this.addToPromoGroups()
  }

  private initData(promotion, barcodeFilter = null) {
    this.allItems = {};
    this.minBuyQuantity = Number(promotion.minimumBuyQuantity);
    this.minBuyAmount = Number(promotion.minimumBuyAmount);
    this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
    this.allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
    this.countByRows = promotion.countByRows

    this.maxQuantityToGiveCustomer = (promotion.maxQuantityToGiveCustomer == null || promotion.maxQuantityToGiveCustomer <= 0) ? 
      Infinity : promotion.maxQuantityToGiveCustomer;


    let flattenedItems
    if (this.countByRows) {
      flattenedItems = this.parameters.promotionsBuy[promotion.code]
    }
    else {
      flattenedItems = this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code], false)
    }
    

    let items = this.parameters.promotionsBuy[promotion.code].concat(this.parameters.promotionsGet[promotion.code])
    for (let item of items) {
      if (item) {
        this.allItems[item.id] = item;
      }
    }


    let buyItems = flattenedItems.sort(this.sortByUnitPriceFromExpensiveToCheapest);
    if (barcodeFilter) {
      buyItems = buyItems.filter(item => {return item.barcode == barcodeFilter})
    }

    buyItems.forEach((item) => { 
      this.idToQty[item.id] = this.idToQty[item.id] || 0;
      this.idToQty[item.id] += item.quantity 
    })

    return buyItems;
  }

  private includeItem(item, itemsIncludedInRun, hasWeight = false) {
    this.idToQty[item.id] -= (hasWeight ? item.quantity :  1)
    itemsIncludedInRun[item.id] = itemsIncludedInRun[item.id] || 0
    itemsIncludedInRun[item.id] += (hasWeight ? item.quantity :  1)
  }

  private removeItem(item, itemsIncludedInRun, hasWeight = false) {
    
    this.idToQty[item.id] += (hasWeight ? item.quantity :  1)
    itemsIncludedInRun[item.id] -= (hasWeight ? item.quantity :  1)
  }

  //validate if promotion can be applied
  private valid(promotion) {
    if (Number(promotion.minimumBuyQuantity) <= 0) {
      console.error('quantity is smaller or equal to 0 - not applying promotion')
      return false;
    }
    if (!this.parameters.promotionsBuy[promotion.code]) {
      return false;
    }

    return true;
  }

  private performRun(buyItems, promotion) {
    let [totalPriceForRun, totalQuantityForRun, itemsIncludedInRun] = [0,0,{}];

    for (let item of buyItems) {

      if (!this.allowMultipleTimesPerSale && this.promotionApplied) {
        break;
      }
      if (this.idToQty[item.id] <= 0) {
        continue;
      }
      totalPriceForRun += item.unitPrice * (item.hasWeight ? item.quantity : 1)
      if (this.countByRows) {
        totalQuantityForRun += 1
      }
      else {
        totalQuantityForRun += (item.hasWeight ? item.quantity : 1)
      }
      this.includeItem(item,itemsIncludedInRun,item.hasWeight)

      if (totalPriceForRun >= this.minBuyAmount && totalQuantityForRun >= this.minBuyQuantity) {
        //find the first relevant get item and apply the promotion on it...
        let itemToPromote = null;
        for (let i=0; i<buyItems.length; i++) {
          if (itemsIncludedInRun[buyItems[i].id] && itemsIncludedInRun[buyItems[i].id] > 0) {
            itemToPromote = buyItems[i];
            break;
          }
        }

        if (itemToPromote) {
          this.addPromoGroup(itemToPromote,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun, promotion)
          return true;
        }
        else {
          return false
        }
      }
    }
    return false;

  }

  //remove items from the itemsIncludedInRun list that the promotion can still be applied without them
  private removeRedundantItems(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun) {
    if (totalQuantityForRun == this.minBuyQuantity) {
      return;
    }
    for (let id in itemsIncludedInRun) {
      for (let i=0; i< itemsIncludedInRun[id]; i++) {
        let hasWeight = Boolean(this.allItems[id].hasWeight)
        let qty = (hasWeight ? this.allItems[id].quantity : 1)
        if (this.countByRows) {
          qty = 1
        }
        if (totalPriceForRun-this.allItems[id].unitPrice*(hasWeight ? this.allItems[id].quantity : 1)  >= this.minBuyAmount &&
            (totalQuantityForRun-qty >= this.minBuyQuantity)) {

          totalQuantityForRun -= qty;
          totalPriceForRun -= this.allItems[id].unitPrice * (hasWeight ? this.allItems[id].quantity : 1);
          this.removeItem(this.allItems[id],itemsIncludedInRun,hasWeight);
        }
      }
    }
  }

  private addPromoGroup(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun, promotion) {

    if (!this.promoGroups[itemToPromote.id]) {
      this.promoGroups[itemToPromote.id] = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
    }
    
    this.removeRedundantItems(itemToPromote,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun)

    let minPriceForRun = 0;
    let isOneRowDiscount = Object.keys(itemsIncludedInRun).filter(key => itemsIncludedInRun[key] > 0).length === 1
    for (let key in itemsIncludedInRun) {
      let qty = isOneRowDiscount ? itemsIncludedInRun[key]/2.0 : itemsIncludedInRun[key]
      if (minPriceForRun == 0) {
        minPriceForRun = this.allItems[key].unitPrice*(Math.min(qty,this.maxQuantityToGiveCustomer))
      }
      else {
        minPriceForRun = Math.min(minPriceForRun,this.allItems[key].unitPrice*(Math.min(qty,this.maxQuantityToGiveCustomer)))
      }

    }
    let promoGroup = this.promoGroups[itemToPromote.id];

    let discountValue = this.getDiscountValue(itemToPromote, totalPriceForRun, totalQuantityForRun, promotion, minPriceForRun);

    this.promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += this.getTotalPriceForItemsInRun(itemsIncludedInRun)
    this.promoGroups[itemToPromote.id].discountAmountForGroup += discountValue;


    for (let id in itemsIncludedInRun) {
      for (let i=0; i< itemsIncludedInRun[id]; i++) {
        this.addToItemsCounter(this.allItems[id],this.promoGroups[itemToPromote.id].itemsCounter);
        this.addToRowValueCounter(this.allItems[id],this.promoGroups[itemToPromote.id].rowValueCounter);
      }
    }

    let item = {
      discountAbsoluteValue: session.fixedNumber(promoGroup.discountAmountForGroup),
      discountPrecentValue: session.fixedNumber(promoGroup.discountAmountForGroup/(itemToPromote.unitPrice * itemToPromote.quantity)*100),
      discountType: promotion.discountType == 'Percent' ? PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT : PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT,
      isPromotionGiven: true,
      saleItemID: itemToPromote.id,
      promotionCode: promotion.code,
      promotionName: promotion.name,
      buyGet: 'buy'
    }

    promoGroup.item = item;
    this.promotionApplied = true
  }

  private getTotalPriceForItemsInRun(itemsIncludedInRun) {
    let totalPrice = 0
    for (let id in itemsIncludedInRun) {
      totalPrice+= this.allItems[id].unitPrice * itemsIncludedInRun[id];
    }
    return totalPrice;
  }

  private getDiscountValue(item, totalPriceForRun, totalQuantityForRun, promotion:Storage.Entity.Promotion, minPriceForRun) {
    switch (promotion.discountType) {
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
        return Math.max(0,Number(promotion.discountValue));
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:

        return Math.max(0,minPriceForRun-Number(promotion.discountValue))
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
        return minPriceForRun*Number(promotion.discountValue)/100.0;
    }
  }


  private addToPromoGroups() {
    for (let key in this.promoGroups) {
      this.parameters.promoGroups.push(this.promoGroups[key]);
    }
  }

}
}}}
