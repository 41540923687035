module PositiveTS {
export module Storage {
export module Entity {
export class ItemTimeRate extends WasmEntity {


	companyId: number
	itemId: number
	itemCode: string
	minutesAmount: number
	rate: number
	order: number
	chargeFullTime: boolean
	version: number

	constructor() {
		let meta = {
			name: 'ItemTimeRate',
			fields: {
				companyId: "INT",
				itemId: "INT",
				itemCode: "TEXT",
				minutesAmount: "INT",
				rate: "FLOAT",
				order: "INT",
				chargeFullTime: "BOOL",
				createdAt: "TEXT",
				version: "INT",
			}
		};
		super(meta)
	}

	static getTimeRatesWithDefault(itemCode: string, version = null) {
		if (version == null) {
			version = this.getLastVersion(itemCode);
		}

		let result = this.execAndConvertResult(`select * from ItemTimeRate where itemCode = $itemCode AND version = $version`, {$itemCode: itemCode, $version: version});

		if (result && result.length > 0) {

			result = _.sortBy(result, 'order');
			let defaultResult = result.pop();

			return {
				default: defaultResult,
				orderedRates: result
			}
		}

		return null;
	}

	static getLastVersion(itemCode: string): number {
		let res = Service.WasmDB.execAsObject(`select max(version) as max from ItemTimeRate where itemCode = '${itemCode}'`)

		return res.length > 0 ? res[0].max : 0;
	}
}}}}
