module PositiveTS {
export module Service {
export module DedicatedTo {

  export function openSetDedicatedTo () {
		if (jsonConfig.getVal(jsonConfig.KEYS.alwaysGetDedicatedTo)) {
			Service.DedicatedTo.setDedicatedTo('שם לקוח', 'יש להזין שם לקוח להזמנה', 'שם לקוח')
		}
		else {
			PositiveTS.Service.DedicatedTo.setDedicatedTo();
		}
	}

  export async function setDedicatedTo(title = null, description = null, inputPlaceHolder = null,askForPhone?,allowEmptyName = true, 
                                       emptyErrorMessage = null, allowLongName = false, tooLongErrorMessage = null) {

    title = title || i18next.t('pos.dedicatedTo');
    description = description || i18next.t('pos.dedicatedTo');
    inputPlaceHolder = inputPlaceHolder || i18next.t('pos.dedicatedTo');
    
    let dedicatedPhone;

    let dedicatedToName = await inputDg.open({
                                  header: title,
                                  description: description,
                                  dialogPosition: {my: 'center top+150', at: 'top'},
                                  inputPlaceHolder: inputPlaceHolder,
                                  strDefault: getCurrentDedicatedTo(posVC.sale),
                                  showCancelButton: true,
                                  keyboardLayout: "hebrew-qwerty-custom",//'hebrew-qwerty-custom',
                                  emptyErrorMessage: emptyErrorMessage || i18next.t('pos.nameRequired'),
                                  tooLongErrorMessage: tooLongErrorMessage || i18next.t('pos.nameTooLong',{maxChars: "255"}),
                                  inputValidator: function(value) {
                                    if(allowEmptyName){
                                      return true;
                                    }else{
                                      return !posUtils.isNullOrUndefinedOrEmptyString(value);
                                    }
                                  },
                                  inputLengthValidator: (value) => allowLongName || value && value.length > 255 ? true : false
                                })

    let currentDedicatedPhone = getCurrentDedicatedPhone();
    if ((askForPhone) || (currentDedicatedPhone != null)){
      dedicatedPhone = await inputDg.open({
        header: "מספר טלפון",
        description: "מספר טלפון",
        inputPlaceHolder: "מספר טלפון",
        strDefault: currentDedicatedPhone,
        dialogPosition: {my: 'center top+150', at: 'top'},
        showCancelButton: true,
        keyboardLayout: "hebrew-qwerty-custom",//'hebrew-qwerty-custom',
        emptyErrorMessage: 'חובה להזין טלפון',
        inputValidator: function(value) {
            return !posUtils.isNullOrUndefinedOrEmptyString(value);
        }    
      })

    }

    setSaleJsonData(dedicatedToName);

    if (askForPhone || currentDedicatedPhone != null) {
      setSaleJsonDataOrdererPhone(dedicatedPhone);
    }


    FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);

    posVC.setDedicatedTo();
  }

  export function printDedicatedToIfRequired(sale, zebra, objPrint, printPrefix = "לכבוד:",printerBuffer?) {    
    let jsondata =  JSON.parse(sale.jsondata);

    if (jsondata.dedicatedTo) {
      zebra.append(objPrint.allowComponents.init,printerBuffer);
      zebra.append(objPrint.allowComponents.bigFont,printerBuffer);
      zebra.append(objPrint.allowComponents.centerAlignment,printerBuffer);
      objPrint.printMultyLine(printPrefix + ' ' + jsondata.dedicatedTo,20,printerBuffer);
      objPrint.printLine(" ",printerBuffer);
      zebra.append(objPrint.allowComponents.init,printerBuffer);
    }
  
  };


  export function getCurrentDedicatedTo(sale:PositiveTS.Storage.Entity.Sale):string {
    if(!sale) return "";
    if(!sale.jsondata){ 
      return "";
    }
    let jsonData = JSON.parse(sale.jsondata);

    if(!jsonData.dedicatedTo){
      return "";
    }
    return jsonData.dedicatedTo;
  }

  export function setSaleJsonDataOrdererPhone(dedicatedPhone) {
    if(!dedicatedPhone){
      return
    }

    if(!posVC.sale.jsondata) {
      posVC.sale.jsondata = JSON.stringify({});
    }

    var jsonData = JSON.parse(posVC.sale.jsondata);
    if(jsonData.delivery){
      jsonData.delivery.ordererPhone = dedicatedPhone;
    }
    jsonData.dedicatedPhone = dedicatedPhone;
    posVC.sale.jsondata = JSON.stringify(jsonData);
  }

  function getCurrentDedicatedPhone(){
    var jsonData = JSON.parse(posVC.sale.jsondata);
    return jsonData.dedicatedPhone;
  }

  export function setSaleJsonData(dedicatedToName) {

    if(!posVC.sale.jsondata) {
      posVC.sale.jsondata = JSON.stringify({});
    }

    var jsonData = JSON.parse(posVC.sale.jsondata);
    jsonData.dedicatedTo = dedicatedToName;
    if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && posVC.sale.isDelivery){
      if (posUtils.isBlank(jsonData.delivery)) {
        jsonData.delivery = {};
      }
      jsonData.delivery.ordererName = dedicatedToName;
    }
    posVC.sale.jsondata = JSON.stringify(jsonData);
  }
}}}




  

  


