module PositiveTS {
  export module Service {
    export class Pinpad extends PositiveShared.Pinpad {

      static instance: Pinpad;

      static getInstance(): Pinpad {
        if (!Pinpad.instance) {
          Pinpad.instance = new Pinpad();
        }

        return Pinpad.instance;
      }

      constructor() {
        super(Pinpad.getPinpadConfig(),
          Pinpad.getAddonstatic(),
          Pinpad.getLoadingMessagesAndDialogManager(),
          Pinpad.getLogger());
      }

      static getPinpadConfig(): PositiveShared.PinpadConfig {
        return {
          terminalNumber: session.pos.emvTerminalNumber,
          atmTerminalNumber: jsonConfig.getVal(jsonConfig.KEYS.atmTerminalNumber),
          emvTimeout: Pinia.globalStore.emvTimeout,
          posNumber: jsonConfig.getVal(jsonConfig.KEYS.emvPosNumber) || session.pos.posNumber,
          moneyOnlyRefund: jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund),
          shouldCancelOnCredit: () => !(session.pos.hasFlights && Pinia.globalStore.isOnline),
          isAndroid: session.isAndroid,
          isNewVersionAddonFiles: session.addonFilesVersion >= 2.83,
          skipVerificationCode: session.pos.hasFlights,
          pinpadCommunicationErrors: jsonConfig.getVal(jsonConfig.KEYS.pinpadCommunicationErrors),
          keepAliveEmvMinutesInterval: jsonConfig.getVal(jsonConfig.KEYS.keepAliveEmvMinutesInterval),
          keepAliveEmv: jsonConfig.getVal(jsonConfig.KEYS.keepAliveEmv),
          usePinpadInSwitchMode: jsonConfig.getVal(jsonConfig.KEYS.usePinpadInSwitchMode),
          preventDuplicateTransactionWithXFields: session.pos.isEmv && jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment),
          isHttpConnection: !posUtils.isBlank(Service.EMV.getIpAddress()),
          isGateway: Service.Gateway.isEnabled(),
          numberOfRetriesInNullResult: session.pos.isEmv && jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment) ? 3 : 0,
          numberOfSecondsToSleep: 15,
        }
      }

      static getAddonstatic(): PositiveShared.IAddon {
        let emvIpAddress = Service.EMV.getIpAddress()
        return {
          async sendDataToPinpad(request, type) {
            if (!posUtils.isBlank(emvIpAddress)) {
              //PCL header calculation - see Appendix B in Caspit docs
              let requestData = (Pinpad.getPinpadConfig().isNewVersionAddonFiles && !Pinpad.getPinpadConfig().isAndroid) ?
                request.xml : request.rawXml
              let encoder = new TextEncoder();
              let isoBytes = encoder.encode(requestData);
              let dataLengthInHex = (isoBytes.length + 16).toString(16).padStart(4, '0').toUpperCase(); //LLLL
              let target = 52; // 52 - Smart Retail App 
              let source = 2; //  02 - ECR - Electronic Cash Register
              let header = `^PTL!00#${dataLengthInHex}${target}${source}`;
              return await HTTPService.makeHttpRequestWithFullResponseAndValidate(
                "http://" + emvIpAddress,
                "POST",
                header + requestData,
                { "Content-Type": "text/html; charset=windows-1255" },
                null,
                120000,
                true
              )
            }
            return await GenericAddon.sendNativeMessageToExtension(request, type);
          }
        }
      }

      static getLoadingMessagesAndDialogManager(): PositiveShared.ILoadingMessageAndDialogManager {
        return {
          showLoadingMessage(message: string) { return app.showLoadingMessage(message) },
          hideLoadingMessage() { return app.hideLoadingMessage() },
          showAlert(message: string, title: string) {
            return app.promiseShowAlert({
              header: title,
              content: message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            })
          },
          async showAlertWithAnswer(message: string, title: string, continueButtonText: string = i18next.t('ok'), cancelButtonText: string = i18next.t('cancel'), yellowCancel: boolean = false): Promise<PositiveShared.DialogAnswer> {
            let result = await app.promiseShowAlert({
              header: title,
              content: message,
              yellowCancel: yellowCancel,
              continueButtonText: continueButtonText,
              cancelButtonText: cancelButtonText
            });

            return result == "continue" ? PositiveShared.DialogAnswer.OK : PositiveShared.DialogAnswer.CANCEL;
          }
        }
      }


      static getLogger(): PositiveShared.ILogger {
        return {
          error(err: any) {
            console.error(err);
            Service.Logger.error(err);
          },
          dir(data: any) {
            console.dir(data);
          },
          log(data: any) {
            console.log(data);
          },
          info(data: any) {
            datadogLogs.logger.info(data);
          }
        }
      }

    }
  }
}
