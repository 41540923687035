module PositiveTS {
export module Service {
export module SOAPRequestGenerator {
    export interface SOAPAttribute {
        name: string,
        value: string
    }
    export interface SOAPParam {
        name: string,
        attributes?: Array<SOAPAttribute>,
        value?: string,
    }
    export interface SOAPFunction extends SOAPParam {
        params: Array<SOAPParam>
    }
    export interface SOAPHeader {
        "Content-Type": string,
        "SOAPAction"?: string
    }

    export function createRequest(envelopeAttributes:Array<SOAPAttribute>, soapFunction:SOAPFunction):string {
        let request = `<?xml version="1.0" encoding="utf-8"?>
        <soap:Envelope ${attributes2String(envelopeAttributes)}>
        \t<soap:Body>
        ${function2String(soapFunction)}
        \t</soap:Body>
        </soap:Envelope>`;
        return request
    }

    export async function sendRequest(soapFunction:SOAPFunction, envelopeAttributes:Array<SOAPAttribute>, 
                                        soapUrl:string, logFile:string, method:string = "POST", soapHeader:SOAPHeader = getDefaultHeader()) {
        let request = createRequest(envelopeAttributes, soapFunction);
        
        var packet = {
            body: request,
            type: method,
            url: soapUrl,
            headers: JSON.stringify(soapHeader)
        }
        let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({data: JSON.stringify(packet)}, "external_service");
        PositiveTS.Service.Filelog.log(logFile, JSON.stringify({request: packet.body, response: response.request.result}))
        let jsonResponse = posUtils.xml2json(response.request.result);
        if(jsonResponse["soap:Envelope"]){
            return jsonResponse["soap:Envelope"]["soap:Body"];
        }
        else{
            return false;
        }
        
        
    }

    function attributes2String(attributes:Array<SOAPAttribute>):string {
        if(posUtils.isNullOrUndefinedOrEmptyString(attributes)) return "";
        let str = "";
        attributes.forEach(attribute => {
            str += ` ${attribute.name}="${attribute.value}"`;
        });
        return str;
    }

    function params2String(params:Array<SOAPParam>):string {
        let str = "";
        params.forEach(param => {
            str += `\t\t\t<${param.name}${attributes2String(param.attributes)}>${param.value}</${param.name}>\n`
        })
        return str;
    }

    function function2String(soapFunction:SOAPFunction):string {
        let str = `\t\t<${soapFunction.name}${attributes2String(soapFunction.attributes)}>
        ${params2String(soapFunction.params)}
        \t\t</${soapFunction.name}>\n`
        return str;
    }

    function getDefaultHeader():SOAPHeader {
        return <SOAPHeader> {
            "Content-Type": "text/xml; charset=utf-8"
        };
    }
}}}