module PositiveTS {
export module Service {
export module TaxInv {
  export const hasLinkedReceiptJsondataKey = "hasLinkedReceipt";
  export const linkedReceiptSeq = "linkedReceiptSeq";
  export const linkedReceiptAmnt = "linkedReceiptAmnt";
  export const taxInvSeqTag = "taxInvSeq"

  export async function createTaxInvIfRequired(fullSale:FullSale):Promise<FullSale>{
    if (!isCurrentSaleTaxInv(fullSale)) {
      throw new Error("not TaxInv Invoice");
    }
    let validation = _validateSaleComplete(fullSale)
    if (!validation.valid) {
      throw new Error( validation.message );
    }

    let fullSaleBackup = fullSale.clone()
    try {
      let updatedFullSale = createDocument(fullSale)
      
      await updatedFullSale.IncrementSeqAndPersist()
      let saleToPrint = updatedFullSale.clone();

      await Service.CloseSale.afterSaleClosedActions(updatedFullSale);

      await printDocuments(saleToPrint);
      
      return updatedFullSale;
    }
    catch(e) {
      restore(fullSaleBackup);
      throw e;
    }

  }

  function _validateSaleComplete(fullSale:FullSale):any{
    var stateTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(fullSale.sale, fullSale.saleItems, fullSale.salePayments);
    return PositiveTS.Helper.SaleHelper.validateSaleTotals(stateTotals);
  }

  export function receiptTextIfRequired(sale):string{
    var _return = "";
    if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV &&
        JSON.parse(sale.jsondata)[linkedReceiptSeq]
      ){
      return `שולם ${JSON.parse(sale.jsondata)[linkedReceiptAmnt]} שח בחשבונית ${JSON.parse(sale.jsondata)[linkedReceiptSeq]}`
    }
    return _return;
  }

  export function isCurrentSaleTaxInv(fullSale:FullSale): boolean {
    if (!PositiveTS.Service.Hakafa.hasCustomer()){ return false; }
    if (PositiveTS.Service.Hakafa.hasCustomer() && JSON.parse(fullSale.sale.jsondata).customer.is_tamash_customer) {return false;}

    let voucherSalePayment = PositiveTS.Helper.SaleHelper.findPaymentByMethod(fullSale.salePayments, Storage.Entity.SalePayment.METHOD_VOUCHER);

    if (!voucherSalePayment) { return false; }

    var dataObj = JSON.parse(voucherSalePayment.data);
    for (var i = 0; i < dataObj.length; i++) {
      if (dataObj[i].voucher_type_id === PositiveTS.Service.Hakafa.getVoucherID()) {
        return true;
      }
    }
    return false;
  }

  export function createDocument(fullSale:FullSale):FullSale {
    
    var calcuateSaleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(fullSale.sale, fullSale.saleItems, fullSale.salePayments)
    fullSale.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV;
    fullSale.sale.totalAmount = calcuateSaleTotals.totalAmount;
    fullSale.sale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
    fullSale.sale.createdAt = PositiveTS.DateUtils.fullFormat();
    fullSale.sale.totalQuantity = String(calcuateSaleTotals.totalQuantity);
    fullSale.sale.totalDiscount = calcuateSaleTotals.totalSaleDiscount;
    fullSale.sale.totalVatableAmount = session.store.containVat ? 0 : calcuateSaleTotals.totalVatableAmount;

    if ( fullSale.sale.totalAmount < 0 ) {
      if ( JSON.parse(fullSale.sale.jsondata).customer.is_tamash_customer)
      {
        fullSale.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV;
        fullSale.sale.parentSaleInvoiceSequence = null;
      } else {
        fullSale.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV;
        fullSale.sale.parentSaleInvoiceSequence = null;
      }
    }

    return fullSale;
  }

  function restore(fullSale:FullSale):void {
    posVC.sale = fullSale.sale;
    posVC.saleItems = fullSale.saleItems;
    posVC.salePayments = fullSale.salePayments;
  }

  function printDocuments(fullSale):Promise<void>{
    const doNotPrintOriginalInvoice = jsonConfig.getVal(jsonConfig.KEYS.doNotPrintOriginalInvoice)
    if (doNotPrintOriginalInvoice) {
      return;
    }
    return Printing.Invoice.printInvoice( fullSale.sale, fullSale.saleItems, fullSale.salePayments, true,[])
    .then( ()=>{
      if (jsonConfig.getVal(jsonConfig.KEYS.hakafaTaxInvPrintCopyWithOriginal) || jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)){
        return Printing.Invoice.printInvoice( fullSale.sale, fullSale.saleItems, fullSale.salePayments, false,[])
      }
    })    
  }

  function createNewEmptyTaxInv(mainSale:FullSale, taxInvSequence: Storage.Entity.Sequence,
                                mainSequence:Storage.Entity.Sequence):FullSale {

    let sale = posVC.initNewSaleFields();
    
    let mainJd = JSON.parse(mainSale.sale.jsondata)
    mainJd.isSplitSale = true;
    mainJd.splitSaleNumber = taxInvSequence.sequence+1;

    let jsondata:any = {};
    jsondata.customer = mainJd.customer;
    jsondata.isSplitSale = true;
    jsondata.splitSaleNumber = mainSequence.sequence+1;

    if(mainJd.delivery){
      jsondata.splitSaleDelivery = mainJd.delivery;
      jsondata.delivery = mainJd.delivery;
    }


    sale.jsondata = JSON.stringify( jsondata );
    mainSale.sale.jsondata = JSON.stringify(mainJd);

    return new FullSale(sale,[],[])
  }

  async function fixSplitSaleItems(mainSale:FullSale,taxInvSale:FullSale, taxInvSequence: Storage.Entity.Sequence,
                                   mainSequence:Storage.Entity.Sequence) {
    let itemCode = Shared.Constants.Item.GENERIC_ITEM;

    let itemsAndBarcodes = await  PositiveTS.Storage.Entity.Item.searchByCode(Shared.Constants.Item.GENERIC_ITEM)
    
    if (itemsAndBarcodes.length !== 1) {
      throw new Error(`Bad POS configuration, no itemCode ${itemCode}`)
    }

    // Pick the first item and get the item and its barcode
    let item:PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
    item.noDiscount = true //prevent a bug that this item fucks up the total amount in case of sale discount.
    let itemBarcode = {};

    // Create new sale item entity
    let tamashSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
    tamashSaleItem.saleID = taxInvSale.sale.id;
    tamashSaleItem.rowNumber = 0;
    tamashSaleItem.quantity = 1;
    tamashSaleItem.unitPrice = taxInvSale.salePayments[0].amount;
    tamashSaleItem.priceNetoAfterDiscounts  =   tamashSaleItem.unitPrice;
    tamashSaleItem.originalUnitPrice = tamashSaleItem.unitPrice;
    tamashSaleItem.itemDescription = `${i18next.t('shipmentInv.splitPaymentForInvoice')}   ${mainSequence.sequence+1}`

    taxInvSale.saleItems.push(tamashSaleItem)

    let mainSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
    mainSaleItem.saleID = mainSale.sale.id;
    mainSaleItem.rowNumber = posVC.getRowNumber();
    mainSaleItem.quantity = 1;
    mainSaleItem.unitPrice = -taxInvSale.salePayments[0].amount;
    mainSaleItem.originalUnitPrice = -mainSaleItem.unitPrice
    tamashSaleItem.priceNetoAfterDiscounts  =   -mainSaleItem.unitPrice

    mainSaleItem.itemDescription = `${i18next.t('shipmentInv.minusBecauseSplitTaxPayment')}  ${taxInvSequence.sequence+1}`

    mainSale.saleItems.push(mainSaleItem)

  }

  function fixSplitSalePayments(mainSale:FullSale, taxInvSale:FullSale) {
    let mainSaleVoucherPayment = mainSale.salePayments.filter(payment => payment.method == Storage.Entity.SalePayment.METHOD_VOUCHER)[0]

    if (mainSaleVoucherPayment == null) {
      throw new Error("שגיאה קריטית - חסרים תשלומים לחשבונית מס - יש לפנות לתמיכה")
    }

    
    let taxInvSalePayment = new PositiveTS.Storage.Entity.SalePayment();
    taxInvSalePayment.saleID = taxInvSale.sale.id;
    taxInvSalePayment.amount = 0;
    taxInvSalePayment.method = Storage.Entity.SalePayment.METHOD_VOUCHER;
    let tamashPaymentData = []
    let hasHakafaPayment = false


    let data = JSON.parse(mainSaleVoucherPayment.data)
    data = data.filter(voucherData => {
      if (voucherData.voucher_type_id == Service.Hakafa.VOUCHER_TYPE_ID) {
        if (!voucherData.isTamashCustomer) {
          mainSaleVoucherPayment.amount -= voucherData.amount
          taxInvSalePayment.amount += voucherData.amount
          tamashPaymentData.push(voucherData)
          hasHakafaPayment = true
          return false;
        }
      }

      return true;
    })

    taxInvSalePayment.data = JSON.stringify(tamashPaymentData)
    mainSaleVoucherPayment.data = JSON.stringify(data)
    taxInvSale.salePayments.push(taxInvSalePayment)
  }

  export async function createTaxInvForSplit(mainSale:FullSale, taxInvSequence:Storage.Entity.Sequence, 
                                            mainSeq:Storage.Entity.Sequence):Promise<FullSale> {
    
	  const splitInfo = PositiveTS.Service.CloseSale._getSplitSaleInfo(mainSale.sale, mainSale.saleItems, mainSale.salePayments)
    const mainSaleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(mainSale.sale, mainSale.saleItems, mainSale.salePayments)
    let taxInvSale = createNewEmptyTaxInv(mainSale, taxInvSequence, mainSeq)
    fixSplitSalePayments(mainSale, taxInvSale);
    await fixSplitSaleItems(mainSale,taxInvSale,taxInvSequence,mainSeq)

    taxInvSale.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV;
    taxInvSale.sale.totalAmount = taxInvSale.salePayments[0].amount
    if (mainSaleTotals.totalVatableAmount == mainSaleTotals.totalAmount) {
      taxInvSale.sale.totalVatableAmount = taxInvSale.sale.totalAmount
    } else {
      taxInvSale.sale.totalVatableAmount = mainSaleTotals.totalVatableAmount * splitInfo.taxInvPct
    }
    taxInvSale.sale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
    taxInvSale.sale.createdAt = PositiveTS.DateUtils.fullFormat();
    taxInvSale.sale.totalQuantity = "1";
    taxInvSale.sale.totalDiscount = 0

    let mainJd = JSON.parse(mainSale.sale.jsondata);
    mainJd.splitSaleAmount = taxInvSale.sale.totalAmount;
    mainSale.sale.jsondata = JSON.stringify(mainJd);
    if (mainSaleTotals.totalVatableAmount == mainSaleTotals.totalAmount) {
      mainSale.sale.totalVatableAmount = mainSale.sale.totalAmount
    } else {
      mainSale.sale.totalVatableAmount = mainSaleTotals.totalVatableAmount * splitInfo.standardInvPct
    }
    
    return taxInvSale;
  }



}}}
