module PositiveTS {
  export module QueueWorker {

      export class FetchReq {

        static req(reqUrl: string, reqType: string, token:string , reqObj?: any): Promise<Response> {
          return fetch(reqUrl, new FetchObj(reqType, token, reqObj));
        }

        static textReq(reqUrl: string, reqType: string, token:string , reqObj?: any, noAcceptHeader?:boolean): Promise<{response:Response,result:string}> {
          var _response:Response;
          var objFetch = new FetchObj(reqType, token, reqObj, noAcceptHeader);

          return fetch(reqUrl, objFetch)
            .then((response) => {
            _response = response;
            return response.text();
          })
          .then((str)=>{
            return {response:_response, result:str};
          });
        }

        static async jsonReq(reqUrl: string, reqType: string, token:string, reqObj?: any): Promise<{response:Response,result:any}> {
          var _response:Response;
          let response = await fetch(reqUrl, new FetchObj(reqType, token, reqObj))
          _response = response;
          let obj = await response.json();
          PositiveTS.QueueWorker.Messaging.postNotification("WorkerXHR request",reqObj);
          PositiveTS.QueueWorker.Messaging.postNotification("WorkerXHR response",obj);
          return { response: _response, result: obj};
        }

      }

      class FetchObj implements RequestInit {
        method: string;
        body: string;
        token:string;

        get headers() {
          return {
            'Authorization': `Token token=${this.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }

        constructor(method: string, token:string, body?: any, noAcceptHeader?:boolean) {
          this.method = method;
          this.token = token;
          if (noAcceptHeader){
            this.headers['Accept'] = '';
          }

          if (body) {
            this.body = JSON.stringify(body);
          }
        }
      } 


  }
}
