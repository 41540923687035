module PositiveTS {
/* globals Positive, posVC, Promise */

/*
* Description:
*  2 path exists,
*      First:
*          sale item has SpecialPromotion 'itemCode' with all date ranges and other validation ok,
*           'itemCodeGet' is Empty
* ```` Second:
*          sale item has 'itemCodeGet' -> with all date ranges and other validation ok
*          sale also has an item with 'itemCode'
*/

  export module Service {
    export module SpecialPromotion {
      
      function getAllSpecialPromotions() {
        
        return session.allSpecialPromotions;
        
      };
      
      function getAllSaleItemsCodes(){
        var _return = [];
        for (var i=0;i < posVC.saleItems.length; i++){
          _return.push( posVC.saleItems[i].itemCode );
        }
        return _return;
      };
      
      function getAllSaleItemsCodesAndQty(){
        var _return = {};
        for (var i=0;i < posVC.saleItems.length; i++){
          _return[posVC.saleItems[i].itemCode] = posVC.saleItems[i].quantity;
        }
        return _return;
      };
      
      function specialPromotionRowEffective( specialPromotionRow, objItem, isGetEffective) {
        if (isGetEffective) {
          var saleItemCodes =  getAllSaleItemsCodes();
          
          var isGetItemCodeValid = objItem.itemCode === specialPromotionRow.itemCodeGet;
          var isItemCodeOfGetItemCodeValid = saleItemCodes.indexOf( specialPromotionRow.itemCode ) !== -1;
          if ( !(isGetItemCodeValid && isItemCodeOfGetItemCodeValid )) {return false;}
        } else {
          if  ( objItem.itemCode !== specialPromotionRow.itemCode || specialPromotionRow.itemCodeGet) {return false;}
        }
        
        if (objItem.originalUnitPrice !== objItem.unitPrice) {return false;}
        
        var fromDate = posUtils.parseIlDate( specialPromotionRow.fromDate);
        var toDate = posUtils.parseIlDate( specialPromotionRow.toDate);
        if (fromDate.toString() === "Invalid Date" ||
        toDate.toString() === "Invalid Date") {
          return false;
        }
        
        if ( moment().diff(fromDate, 'days') < 0 ||
        moment().diff(toDate, 'days') > 0 ) {return false;}
        
        if (specialPromotionRow.storeList &&
          specialPromotionRow.storeList.split("&").indexOf(session.pos.storeID) === -1) {
            return false;
          }
          
          return true;
          
          
        };
        
        function getPromoArrIfUndefined(specialPromotionsAry) {
          if (posUtils.isNullOrUndefined(specialPromotionsAry)) {
            return getAllSpecialPromotions()
          }
          else {
            return specialPromotionsAry;
          }
        };
        
        function getFirstEffectiveSpecialPromotionForObjItem(objItem, specialPromotionsAry?){
          var _return = {
            isGetEffective: false,
            specialPromotionRow: undefined
          };
          
          
          var specialPromotionsAry = getPromoArrIfUndefined(specialPromotionsAry)
          
          for (var i=0; i<specialPromotionsAry.length; i++ ) {
            var specialPromotionRow = specialPromotionsAry[i];
            
            if (specialPromotionRowEffective( specialPromotionRow, objItem, false)) {
              _return.isGetEffective = false;
              _return.specialPromotionRow = specialPromotionRow;
              return _return;
            }
            
            if (specialPromotionRowEffective( specialPromotionRow, objItem, true)){
              _return.isGetEffective = true;
              _return.specialPromotionRow = specialPromotionRow;
              return _return;
            }
            
          }
          return undefined;
          
          
        };

        
        function addPromotionToItemAmendPct(objItem, objSpecialPromotion, isGetEffective){
          let objDiscount = session.allDiscounts.filter(dis => dis.discountID == objSpecialPromotion.promotionNo)[0]
          
          if (objDiscount == null) {
            throw new Error("Promotion defind however no discount exists for promotionNo " +
            "select * from discount where discountID = " + objSpecialPromotion.promotionNo);
          }
          
          var allSaleItemsCodesAndQty = getAllSaleItemsCodesAndQty();
          
          objItem.getPromotionCode = -1;
          objItem.buyPromotionCode = -1;
          
          objItem.discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT;
          
          objItem.discountID = objSpecialPromotion.promotionNo;
          objItem.discountName = objDiscount.name;
          
          var discountPercent;
          
          // It is required to explain the if you have 5 items of 'GET' and 9 items of 'none GET'
          // with 50% discount,
          // you are entiltled for only (5/9) *  50%
          // (up to the maximum ratio of (9/9) * 50% )
          if (isGetEffective){
            discountPercent = parseFloat( objSpecialPromotion.discPercGet);
            var discountPctFactor = allSaleItemsCodesAndQty[objSpecialPromotion.itemCode] /
            allSaleItemsCodesAndQty[objSpecialPromotion.itemCodeGet];
            if (discountPctFactor > 1) { discountPctFactor = 1;}
            discountPercent = parseFloat( session.fixedNumber( discountPercent * discountPctFactor ) );
            
          } else {
            discountPercent = objSpecialPromotion.discPerc;
          }
          objItem.discountPercent = discountPercent.toString();
          objItem.discountAmount = objItem.quantity * objItem.unitPrice * parseFloat(discountPercent) / 100;
          
          
          objItem.discountApprovedByEmployeeID = -1;
          
        };
        
        function setSpecialPromotionForObjItem(objItem, specialArray){
          var objSpecialPromotionResultDict = getFirstEffectiveSpecialPromotionForObjItem(objItem, specialArray)
          
          if (objSpecialPromotionResultDict){
            return addPromotionToItemAmendPct(objItem,
              objSpecialPromotionResultDict.specialPromotionRow,
              objSpecialPromotionResultDict.isGetEffective);
            }
          };
          
          function removeSpecialPromotionForSaleItem(saleItem){
            var objSpecialPromotionResultDict = getFirstEffectiveSpecialPromotionForObjItem(saleItem)
            
            if (objSpecialPromotionResultDict) {
              saleItem.discountID = -1;
              saleItem.discountName = "";
              saleItem.discountPercent = -1;
              saleItem.discountAmount = -1;
              saleItem.discountType = null;
              saleItem.discountApprovedByEmployeeID = -1;
              
              posVC.doNotAutoReappendPromotionAry.push(saleItem.item.id);
              
              return;
            }
          };
          
          function removeSpecialPromotionFromItems() {
            
            for (var i=0;i< posVC.saleItems.length; i++ ) {
              var saleItem = posVC.saleItems[i];
              removeSpecialPromotionForSaleItem(saleItem)
            }
            
          }
          
          export function setSpecialPromotionsForSaleItems() {
            
            var specialArray = getAllSpecialPromotions()
            
            if (posVC.ignorePromotions) {
              return removeSpecialPromotionFromItems()
              
            }
            
            for (var i=0;i< posVC.saleItems.length; i++ ) {
              if (posVC.doNotAutoReappendPromotionAry.indexOf( posVC.saleItems[i].item.id ) === -1 ) {
                setSpecialPromotionForObjItem( posVC.saleItems[i],specialArray );
              }
            }
            
          };
          
        }}}