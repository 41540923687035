module PositiveTS {
    export module Application {
    export module Controllers {
    export class EmployeeStoreConnectionViewController extends AbstractViewControllerTS {
        init(options) {
            let employeesTable = $('#employee-store-connection-tableview').tableview();
            employeesTable.setNumberOfVisibleRows(7);
    
                    if (session.pos.parameterIsAutomaticEmployeeStoreConnection) {
                        $('#employee-store-connection-add-employee').hide();
                    } else {
                        $('#employee-store-connection-add-employee').show();
                    }
    
        }

        resume(options) {
            this.loadEmployees();
    
            $('#employee-store-connection-add-employee').unbind('click');
            $('#employee-store-connection-add-employee').click(this.addEmployeeToStore);
    
            $('#employee-store-connection-add-fingerprint').unbind('click');
            $('#employee-store-connection-add-fingerprint').click(PositiveTS.Service.Fingerprint.FingerprintUi.enroll);
        }

        stop() {
            $('#employee-store-connection-add-employee').unbind('click');
            $('#employee-store-connection-add-fingerprint').unbind('click');
        }

        destroy() {
        }
        // --------------------------------------------------------------------
        // View Related Functions
        // --------------------------------------------------------------------
    
        async loadEmployees() {
            let employeesTable = $('#employee-store-connection-tableview').tableview();
            employeesTable.empty();
    
            let employees = await Storage.Entity.Employee.fetchByStore(session.pos.storeID)
            
            let htmlLine;

            for (let i = 0; i < employees.length; i++) {
                htmlLine = '<a class="employee-table-delete"><img src="' + (<any>window).images_path + 'close.png" /></a>' + employees[i].name;
                if (session.pos.parameterIsAutomaticEmployeeStoreConnection) {
                    htmlLine = employees[i].name
                }
                let row = employeesTable.addRow([htmlLine]);
                row.data('employee', employees[i]);
            }

            $('#employee-store-connection-tableview a.employee-table-delete').click(async function () {
                let employeeRow = $(this).parent().parent();
                let employee = employeeRow.data('employee');

                let employeeStore = new PositiveTS.Storage.Entity.EmployeeStore();
                await employeeStore.deleteEmployeeStore(employee.employeeID, session.pos.storeID)
                
                employeesTable.removeRow(employeeRow.attr('id'));
            });
            
        }
    
        async saveEmployeeToStore(employee) {
            return await Storage.Entity.EmployeeStore.addEmployeeStore(employee.employeeID, session.pos.storeID);
        }
    
        async addEmployeeToStore() {
            let res1 = await Dialogs.EmployeeSelectorDialog.open()
            await employeeStoreConnectionVC.saveEmployeeToStore(res1)
            await employeeStoreConnectionVC.loadEmployees();
        }
    }
}}}


declare var employeeStoreConnectionVC:PositiveTS.Application.Controllers.EmployeeStoreConnectionViewController;
employeeStoreConnectionVC = new PositiveTS.Application.Controllers.EmployeeStoreConnectionViewController();
PositiveTS.Application.Controllers.instances.EmployeeStoreConnectionViewController = employeeStoreConnectionVC;
