module PositiveTS {
	export module Application {
	export module Controllers {
	export class PosItemStockViewController extends AbstractViewControllerTS {
		model = null
		saleItem = null
		// --------------------------------------------------------------------
		// Controller Life-Cycle
		// --------------------------------------------------------------------
		init(options) {
			let aThis = posItemStockVC;
	
			// Initialize tableview
			let tableView = $('#pos-item-stock-tableview').tableview();
	
			// Set the number of visible rows
			tableView.setNumberOfVisibleRows(5);
	
			// Set the table header
			tableView.setHeader("\
				<div class='ui-grid-a'>\
					<div class='ui-block-a' style='width: 16%'><div class='ui-tableview-header-cell'>מספר חנות</div></div>\
					<div class='ui-block-b' style='width: 42%'><div class='ui-tableview-header-cell'>שם חנות</div></div>\
					<div class='ui-block-c' style='width: 42%'><div class='ui-tableview-header-cell'>מלאי</div></div>\
				</div><!-- /grid-c -->");
	
			$('#pos-item-stock-back-button').click(aThis.back);
		}
		changeSaleItem(saleItem) {
			posItemStockVC.saleItem = saleItem;
		}
		/**
		 * @param {Object} options Configuration object
		 * @param {String} options.saleItemID The sale item ID to check stock for
		 */
		resume(options) {
			let aThis = posItemStockVC;
	
			// Check that there is saleItemID in options
			if (!('saleItem' in options)) {
				aThis.back();
				return;
			}
	
			if (aThis.saleItem == null) {
				aThis.saleItem = options.saleItem;
			}
	
			let saleItem = aThis.saleItem;
	
			$('#pos-item-stock-change-button').click(function () {
				// Go to the discount dialog
				pNavigator.pushPage('pos-item-chooser', i18next.t('pageTitle.posItemChooser'), '/pos/pos-item-stock', {initParams: {
					callbackBeforeBack: 'posItemStockVC.changeSaleItem',
					saleItemObject: saleItem
				}});
			});
	
			let size = aThis.saleItem.size||"";
			let color = aThis.saleItem.color||"";
			//saleItem.size
			$('#pos-item-stock-info').text(size + " " + color);
			// Show loading message while we are loading data from remote server
			app.showLoadingMessage(i18next.t("loadingData"));
			// ajax
	
			return PositiveTS.Service.ItemStock.getItemStock(saleItem.barcode)
			.then(function(rows){
				app.hideLoadingMessage();
				let tableView = $('#pos-item-stock-tableview').tableview();
				tableView.empty();
				let currentRow;
				for (let i=0;i<rows.length;i++){
					currentRow = rows[i];
					tableView.addRow(posUtils.sanitizeTableViewRow([currentRow.STORE_ID, currentRow.STORE_NAME, currentRow.STOCK_QTY]));
				}
	
				// get current store level
				$('#pos-item-stock-current-info').text('מלאי בחנות הנוכחית: ' +
					PositiveTS.Service.ItemStock.getCurrentStoreQty(rows) );
			})
			.catch(function(){
				app.hideLoadingMessage();
				app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('remoteDataLoadFailed'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					  }, function () {
						// Go back!
						aThis.back();
					  }, null);
			});
	
	
	
	
		}
		back() {
			let aThis = posItemStockVC;
			aThis.saleItem = null;
	
			pNavigator.back();
		}
		stop() {
			$('#pos-item-stock-back-button').unbind('click');
			$('#pos-item-stock-change-button').unbind('click');
		}
		destroy() {
		}
	}
}}}

declare var posItemStockVC:PositiveTS.Application.Controllers.PosItemStockViewController;
posItemStockVC = new PositiveTS.Application.Controllers.PosItemStockViewController();
PositiveTS.Application.Controllers.instances.PosItemStockViewController = posItemStockVC
