module PositiveTS {
  export module Storage {
    export module Entity {
      export class SecondaryCategory extends IDBEntity {

        name: string
        number: number
        primary_category_id: number

        constructor() {
          let meta = {
            name: 'SecondaryCategory',
            fields: {
              name: "TEXT",
              number: "INT",
              primary_category_id: "INT",
            }
          };
          super(meta)
        }

        static async getAll(convertToEntity = false): Promise<Array<SecondaryCategory|any>> {
          let result = await appDB.secondaryCategory.toArray();
          return convertToEntity ? SecondaryCategory.fromArrayToObjects(result) : result;
        }

        static async getAllEntity(): Promise<Array<SecondaryCategory|any>> {
          return this.getAll(true);
        }

        static async getByIds(ids: Array<string>, sortBy: string = 'id', convertToEntity: boolean = false):Promise<Array<SecondaryCategory|any>> {
          let result = await appDB.secondaryCategory.where('id').anyOf(ids).sortBy(sortBy);
          return convertToEntity ? SecondaryCategory.fromArrayToObjects(result) : result;
        }
        
        static fromArrayToObjects(array: Array<any>): Array<SecondaryCategory> {
          return array.map(entity => (new SecondaryCategory().importFromObject(entity)));       
        }
      }
    }
  }
}
