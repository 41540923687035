module PositiveTS {
    export module Components {
    export module CheckCardBalance {
      const dialogSelector = "positive-check-card-balance-dialog"    
    
      export function create() {

        let component = {
          template: JST.checkCardBalance(),
          mixins: [keyPadMixin],
          methods: {
            open(){
              return new Promise((resolve, reject) => {
                $(document).unbind('keypress');
                this.initialized = true;
                this.dialogElem.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
                
                this.cardscan = "";
      
                this.$refs.paymentTypes.btns = this.btns;
              })    
            },
            close() {
              if (this.initialized) {
                this.dialogElem.close();
              }

              $(document).unbind('keypress');
              $(document).keypress(posVC.onKeyPress);
            },
            doConfirm(){
              if (this.cardscan){
                this.resolveFunc({cardscan:this.cardscan, cardType: this.btns.filter( row =>{return row.isSelected})[0].id });
                this.close();
              }    
            },
            doReject(){ 
              this.close();
              this.rejectFunc(new Error(app.userCancelledGlobalMessage));
            }, 
            async swipeCard() {
              try {                
                app.showLoadingMessageDialog(i18next.t('swipeCard'));
                this.cardscan = await Service.EMV.swipe(0, true);              
                app.hideLoadingMessageDialog();
              }
              catch(e) {
                app.hideLoadingMessageDialog();
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: e.message,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
              }
            },
            clearMagneticCardField() {
              this.cardscan = "";
          },
          },
          data () {
            return {
              dialogElem: document.getElementById(dialogSelector),
              rejectFunc: null,
              resolveFunc: null,
              intitialized: false,
              btns: [
                new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.chargableCard'),"chargableCard",true),
                new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.chargableCreditCard'),"chargableCreditCard",false),
                new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.punchCard'),"punchCard",false),
                new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.paraxel'),"praxell",false),
                new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.valueCard'),"valueCard",false),
                new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.multipass'),"multipass",false),
              ].concat(Service.MultipassPolice.posHasMultipassPolice() ? [new PositiveTS.Components.PositiveRadio.Btn(i18next.t('checkCardBalanceDialog.multipassPoints'),Shared.Constants.MultipassPolicePoints.CLUB_CODE,false)] : []),
              cardscan: "",
              restaurantNewPOS: jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS),
              sunmiInternalEMVPayment: jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment),
            };
          },
          mounted () {
            this.dialogElem = document.getElementById(dialogSelector);
          },
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            }
            }
        }

        VueApp.component('positive-check-card-balance-dialog', component);
      }   
    
    
    
    
    
    
    }}}
