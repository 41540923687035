module PositiveTS {
    export module Components {
      export module ScanBarcodeDialog {
        const dialogSelector = "scan-barcode-dialog"
        const barcodeInputSelector = 'barcode-input'
  
        function cleanData() {
          let result = initData();
          for (let prop in result) {
            this[prop] = result[prop];
          }
        }
  
        function initData() {
          return {
            initialized: this.initialized,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: document.getElementById(dialogSelector),
            barcode: "",
            showKeyboard: false,
            inputEl: document.getElementById(barcodeInputSelector),
            cancelButtonText: i18next.t("cancel"),
            reactiveSubTitle: "",
            options: {},

          }
        }
  
        function close() {
          if (this.initialized) {
            if (this.dialogElem == null) {
              this.dialogElem = document.getElementById(dialogSelector)
            }
            if (this.inputEl == null) {
              this.inputEl = document.getElementById(barcodeInputSelector)
            }
            
            this.dialogElem.close();
            $(document).keypress(posVC.onKeyPress);
            this.inputEl.blur()
          }
          this.cleanData()
  
 
        }
  
        function open(cancelButtonText?, reactiveSubTitle?, options = {}) {
          this.options = options || {};
          return new Promise(async (resolve, reject) => {
            this.initialized = true;
  
            if (this.dialogElem == null) {
              this.dialogElem = document.getElementById(dialogSelector)
            }
            if (this.inputEl == null) {
              this.inputEl = document.getElementById(barcodeInputSelector)
            }

            this.resolveFunc = resolve;
            this.rejectFunc = reject;
            if (cancelButtonText) {
              this.cancelButtonText = cancelButtonText
            }
            if(reactiveSubTitle){
              this.reactiveSubTitle = reactiveSubTitle
            }

            if (this.options.svg) {
              await this.$nextTick();
            }

            this.dialogElem.showModal();
            $(document).unbind('keypress');

            //we need it because of the jQuery.autoSuggest search result. when clicking a result item the focus shifts to somewhere else.
            //NEED REMOVE THIS IF WE EVER CHANGE THE jquery.autosuggest behavior.
            setTimeout(() => { 
              this.inputEl.focus()
            }, 100)
          })
        }
  
        function confirmAndClose() {
  
          if (posUtils.isBlank(this.barcode)) {
            this.showError(this.optionsWithDefaults.emptyValueMessage);
            this.barcode = ""
            return;
          }
  
          this.resolveFunc({ success: true, barcode: this.barcode });
          this.close();
          return;
        }
  
        function cancelAndClose() {
          this.resolveFunc({ success: false, barcode: "" });
          this.close();
        }

        async function showError(errStr) {
            await app.showAlertDialog({
              header: i18next.t('error'),
              content: errStr,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            });

            this.inputEl.focus();
          }

        function updateVal(value) {
          this.barcode = value
        }


        function toggleKeyboard() {
          this.showKeyboard = !this.showKeyboard
          this.inputEl.focus()
        }
  
        export function create() {
  
          let component = {
            template: JST.scanBarcodeDialog(),
            components: {positiveIcon: PositiveIcon.getComponent()},
            methods: {
              cleanData: cleanData,
              open: open,
              close: close,
              confirmAndClose: confirmAndClose,
              cancelAndClose: cancelAndClose,
              showError: showError,
              updateVal: updateVal,
              toggleKeyboard: toggleKeyboard,
              focus(event) {
                event.target.focus();
              },
            },
            computed: {
              simpleSelfService(){
                return Pinia.globalStore.simpleSelfService;
              },
              optionsWithDefaults() {
                let defaults = {
                  emptyValueMessage: i18next.t(`scanBarcodeDialog.barcodeIsRequired`),
                  title: i18next.t('scanBarcodeDialog.barcodeTypeOrScan'),
                  barcodeName: i18next.t('scanBarcodeDialog.barcode'),
                  reverseActions: false,
                  noKeyboard: false,
                };

                return Object.assign(defaults, this.options || {});
              } 
            },
            data: initData,
          }
  
          VueApp.component(dialogSelector, component)
        }
      }
    }
  }
  