module PositiveTS {
    export module Service {

        export interface SafeCashPaymentAdditionalData{
            requestPaymentData: requestPaymentData,
            responsePaymentData: responsePaymentData,
        }

        interface requestPaymentData {
            businessId: string,
            posId: string,
            amount: number,
            externalId: string,
        }

        interface responsePaymentData {
            transactionId: string,
            amount: number
        }

        interface resolvePaymentObject {
            success: boolean, 
            error: string, 
            isCanceled: boolean, 
            response?: any,
            ignoreErrorMessage?:boolean
        }
        export class SafeCashPayment extends SmartVoucher {
            

            static API_PREFIX = posUtils.isDevelopment() || posUtils.isStaging() ? 'https://stage-api.safecashapps.com/api/external/v1' : 'https://prod-api.safecashapps.com/api/external/v1';
            static TRANSACTION_STATUS = {pending: 0, success: 1, canceled: 2, rejected: 3, requested: 7, limited: -1};
            static TOKEN_KEY = 'safeCashPaymentToken'
            
            public amount: number;
            private resolvePayment;
            public requestPaymentData: requestPaymentData
            private hasCancelRequest: boolean;
            private paymentTypeBy: 'QR' | 'CODE'
            private lastTransactionId: string
            private isSelfService: boolean
            private QRInterval: any

            constructor (amount, paymentTypeBy: 'QR' | 'CODE', isSelfService = false) {
                super();
                this.amount = amount;
                this.resolvePayment = undefined;
                this.requestPaymentData = undefined;
                this.hasCancelRequest = false;
                this.paymentTypeBy = paymentTypeBy
                this.lastTransactionId = null
                this.isSelfService = isSelfService
            }

            pollingStatusPayment = _.debounce(this.handlePollingStatusPayment, 1000)
            pollingRefundPayment = _.debounce(this.handlePollingRefundPayment, 1000)
            
            static async fetchToken (): Promise<{success: boolean, error: string, token: string}> {
                try{
                    var result = {success: false, error: null, token: null}
                    var errorMessage = i18next.t('safeCash.errorApiToken')
                    const userName = SafeCashPayment.getUserName()
                    const password = SafeCashPayment.getPassword()
                    const businessId = SafeCashPayment.getBusinessId()
                    const url = `/Login`
                    const res = await SafeCashPayment.apiRequest(url, {userName, password, businessId}, 'POST', false)

                    if (res.response.status == 200){
                        result.token = res?.data?.token
                        result.success = true
                        SafeCashPayment.setToken(result.token)
                        return Promise.resolve(result)
                    }
                    
                    console.error('Error fetch SafeCash token', res.data)
                    if (res?.data?.description){
                        errorMessage += `\n${i18next.t('safeCash.errorMessage')}: ${res.data.description}`
                    }
                    result.error = errorMessage
                    return Promise.resolve(result) 
                }catch (error) {
                    console.error('Error fetch SafeCash token', error)
                    result.error = errorMessage
                    return Promise.resolve(result)
                }
            }
            
            static setToken (token) {
                return localStorage.setItem(SafeCashPayment.TOKEN_KEY, token)
            }

            static getToken () {
                return localStorage.getItem(SafeCashPayment.TOKEN_KEY)
            }

            static getUserName () {
                return jsonConfig.getVal(jsonConfig.KEYS.safeCashUserName)
            }

            static getPassword () {
                return jsonConfig.getVal(jsonConfig.KEYS.safeCashPassword)
            }

            static getBusinessId () {
                return session.store.registrationNum
            }

            static async apiRequest (url, data = {}, method = 'POST', checkIfHasToken = true, writeToLog = true): 
                Promise<{success: boolean, response: Response, data: any, error: string}> {
                let result = {success: true, response: null, data: null, error: null}
                let fullUrl = `${SafeCashPayment.API_PREFIX}${url}`;
                let token = SafeCashPayment.getToken()
                if (token == null && checkIfHasToken){
                    const resToken = await SafeCashPayment.fetchToken()
                    if (resToken.success){
                        token = resToken.token
                    }else{
                        result.success = false
                        result.error = resToken.error
                        return result
                    }
                }

                let requestData: any = {
                    method: method, 
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',  // if we have problem with Android this could be the problem
                        'Authorization': `Bearer ${token}`
                    }
                }

                const methodsWithoutBody = ['GET', 'HEAD'];
                if (!methodsWithoutBody.includes(method) && !_.isEmpty(data)) {
                    requestData.body = JSON.stringify(data);
                }

                result.response = await fetch(fullUrl, requestData);
                
                //fetch new token if has token on localStorage
                if (checkIfHasToken && result.response.status == 401){
                    const resToken = await SafeCashPayment.fetchToken()
                    if (resToken.success){
                        return SafeCashPayment.apiRequest(url, data, method, checkIfHasToken, writeToLog)
                    }else{
                        result.success = false
                        result.error = resToken.error
                        return result
                    }
                }

                if(result.response.headers.get("content-type") && result.response.headers.get("content-type").includes('application/json')){
                    result.data = await result.response.json();
                    if (result.response?.data?.data){
                        result.data = result.response.data.data
                    }
                }else{
                    result.response.data = await result.response.text();
                }

                if (!result.response.ok){
                    result.success = false
                    result.error = result?.data?.description || i18next.t('safeCash.globalError')
                    return result
                }

                if (writeToLog){
                    PositiveTS.Service.Filelog.log("safeCash", JSON.stringify({requestData, url}))
                    PositiveTS.Service.Filelog.log("safeCash", JSON.stringify(result.response.data))
                }

                return result
            }

            async handlePollingStatusPayment (transactionId) {
                const url = `/request/StatusByTransactionId`;
                let businessId = SafeCashPayment.getBusinessId()
                let requestData = {
                    businessId: businessId,
                    transactionId: transactionId,
                }

                if (this.hasCancelRequest){
                    return
                }

                try{
                    const result = await SafeCashPayment.apiRequest(url, requestData, 'POST', true, false)
         
                    // some error here return 
                    if (!result.success){
                        this.resolvePayment({success: false, error: result.error}); 
                    }

                    if (result.success && result.response.status == 200 && !_.isEmpty(result.data)){
                        const data = result.data;
                        const TRANSACTION_STATUS = SafeCashPayment.TRANSACTION_STATUS;

                        if (data.transactionStatus == TRANSACTION_STATUS.success) {
                            this.resolvePayment({success: true, error: null, response: data}); 
                            this.hideQR();
                            return;
                        }
                        
                        if (data.transactionStatus == TRANSACTION_STATUS.rejected) {
                            this.resolvePayment({success: false, error: i18next.t('safeCash.customerDeclinedPayment')});
                            this.hideQR();
                            return;
                        }

                        if (data.transactionStatus == TRANSACTION_STATUS.canceled) {
                            this.resolvePayment({success: false, error: i18next.t('safeCash.customerCancelledPayment')});
                            this.hideQR();
                            return;
                        }

                        this.pollingStatusPayment(transactionId)
                    }
                    console.debug('handlePollingStatusPayment response:', result);
                }catch(e){
                    await this.hideQR();
                    console.error('Error handlePollingStatusPayment' + e);
                    this.resolvePayment({success: false, error: i18next.t('safeCash.globalError')});
                }
            }

            validatePaymentData():{isValid:boolean,message:string}{
                if(posUtils.isBlank(this.amount) || this.amount<=0){
                    return {isValid:false,message: i18next.t('safeCash.validation.insertValidAmount')};
                }
                return {isValid:true,message:'success'};
            }

            async request (transactionId?: string): Promise<resolvePaymentObject> {
                let validationResult = this.validatePaymentData()
                if(!validationResult.isValid){
                    return Promise.resolve({success: false, error: validationResult.message, isCanceled: false})
                }
                if (this.paymentTypeBy === 'QR' || this.paymentTypeBy === 'CODE'){
                    const promise: Promise<{success: boolean, error: string, isCanceled: boolean}> = new Promise<resolvePaymentObject>((resolve) => {
                        this.resolvePayment = resolve;
                    });
                    this.setRequestPaymentData()

                    // if is self service just run the polling the request create on selfSelectPaymentMethod safeCashPay
                    if (this.paymentTypeBy === 'QR' && !this.isSelfService){
                        try {
                            let QRRequest = await this.requestCreateQR(this.requestPaymentData)
                            if (QRRequest.success) {
                                if (jsonConfig.getVal(jsonConfig.KEYS.qrDisplay) === "PINPAD") {
                                    Pinia.globalStore.setEMVDisableClick(false);
                                    Pinia.globalStore.setEMVisAsync(true);
                                    let isPending = false;
                                    this.QRInterval = setInterval(() => {
                                        if (!isPending) {
                                            Pinia.globalStore.setEMVDisableClick(false);
                                            Pinia.globalStore.setEMVisAsync(true);
                                            isPending = true;
                                            PositiveTS.Service.QRService.displayQR(QRRequest?.data?.qrCodeAsString, 7)
                                            .then((res) => {
                                                isPending = false;
                                                if (this.hasCancelRequest) {
                                                    this.hideQR();
                                                }
                                            })
                                        }
                                    }, 100);
                                } else {
                                    QRService.displayQR(QRRequest?.data?.qrCodeAsString).then(res => {
                                        this.cancelRequest(this)
                                    })
                                }
                                transactionId = QRRequest?.data?.transactionId
                            }else{
                                this.resolvePayment({success: false, error: QRRequest.error})
                                this.hasCancelRequest = true
                                await this.hideQR();
                            }
                        } catch (error){
                            console.error(error)
                            this.resolvePayment({success: false, error: i18next.t('safeCash.globalError')})
                            this.hasCancelRequest = true
                            await this.hideQR();
                        }
                    }
                    //paymentTypeBy CODE create the actions on requestCreateCode and call this function with transactionId for pollingStatusPayment 

                    this.lastTransactionId = transactionId

                    if (!this.hasCancelRequest){
                        this.pollingStatusPayment(transactionId)
                    }
                    return promise;
                }
                
                return Promise.resolve({success: false, error: i18next.t('safeCash.globalError'), isCanceled: false})
            }

            async requestCreateQR (requestData) {
                return await PositiveTS.Service.SafeCashPayment.apiRequest('/request/create', requestData)
            }

            async requestCreateCode (): Promise<{code: string, transactionId: string, success: boolean, error: string}> {
                let validationResult = this.validatePaymentData()
                if(!validationResult.isValid){
                    return Promise.resolve({code: '', transactionId: null,success:false, error: validationResult.message})
                }
                let result = {code: null, transactionId: null, success: false, error: null}

                //create transaction
                this.setRequestPaymentData()
                const resTransaction = await this.requestCreateQR(this.requestPaymentData)
                if (!resTransaction.success){
                    result.error = resTransaction.error
                    return result
                }

                //create short code for pay
                const transactionId = resTransaction?.data?.transactionId
                const requestDataCode = {
                    businessId: SafeCashPayment.getBusinessId(),
                    transactionId
                }
                let res = await PositiveTS.Service.SafeCashPayment.apiRequest('/transactions/generate/code', requestDataCode)
                if (res.success){
                    result.code = res.data
                    result.transactionId = transactionId
                    result.success = true
                }else{
                    result.error = res.error 
                }

                return result
            }

            async hideQR() {
                if (this.isSelfService) {
                    return;
                }
                clearInterval(this.QRInterval);
                this.hasCancelRequest = true;
                
                return await Service.QRService.hideQR();
            }

            async cancelRequest (safeCashPayment) {
                try {
                    clearInterval(this.QRInterval);
                    var result = {success: false, error: null, isCanceled: false}
                    app.showLoadingMessage(i18next.t('safeCash.cancelTransaction'))
                    safeCashPayment.hasCancelRequest = true
                    if (safeCashPayment.paymentTypeBy == 'QR'){
                        await this.hideQR();
                    }

                    let cancelResult = await safeCashPayment.apiCancelTransaction()
                    if (cancelResult.success){
                        result.success = true
                        result.isCanceled = true
                    }else{
                        result.error = cancelResult.error
                    }

                    if(safeCashPayment.paymentTypeBy == 'QR'){
                        await this.hideQR();
                    }
                    
                    safeCashPayment.resolvePayment(result)
                    app.hideLoadingMessage()
                }catch (error){
                    console.error('Error apiCancelTransaction', error)
                    app.hideLoadingMessage()
                    result.success = false
                    result.error = i18next.t('safeCash.errorCancelTransaction')
                    safeCashPayment.resolvePayment(result)
                }
            }

            async pay (voucherData:any, amount: number, cvv?: string, companyId?: any,additionalData?: PositiveTS.Service.SafeCashPaymentAdditionalData ): Promise<any> {
                let resolveReject = {resolve: null, reject: null};
                const promise = new Promise((resolve, reject) => {
                    resolveReject.resolve = resolve;
                    resolveReject.reject = reject;
                });

                const transactionId = additionalData?.responsePaymentData?.transactionId
                try{
                    voucherData.allowPartialReturn = true;
                    voucherData.actionType = SmartVoucherActionTypes.WITHDRAW;
                    voucherData.barCode = transactionId;
                    voucherData.amount = Number(amount);
                    voucherData.transactionId = transactionId;
                    voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH;
                    voucherData.data = JSON.stringify(additionalData)
                    resolveReject.resolve(voucherData);
                }catch(e){
                    resolveReject.reject(new Error(e));
                }

                return promise;
            }

            async cancelPayment(data: responsePaymentData, doRemove?:boolean):Promise<any>{
                return this.apiRefundPayment(data)
            }

            async apiCancelTransaction () {
                clearInterval(this.QRInterval);
                this.hideQR();
                return await SafeCashPayment.apiRequest('/request/cancel', {businessId: SafeCashPayment.getBusinessId(), transactionId: this.lastTransactionId})

            }

            async handlePollingRefundPayment(reqData) {
                try{ 
                    const result = await SafeCashPayment.apiRequest(`/request/StatusForPOSByTransactionId`, reqData, 'POST', true, false)

                    if(result.response.status == 200 && result.data.transactionStatus == 0) {
                        this.pollingRefundPayment(reqData);   
                    }
                    else if (result.response.status == 200 && result.data.transactionStatus == 1){
                        this.resolvePayment({success: true,response:result.data});
                    }
                    else {                       
                        this.resolvePayment({success: false, error: `${i18next.t('safeCash.errorMessage')}: ${result.data.description}`}); 
                    }
                }catch(e){
                    console.error('Error apiRefundPayment' + e);
                    this.resolvePayment({success: false, error: `${i18next.t('safeCash.errorMessage')}: ${e}`});
                }
            }

            async apiRefundPayment (data: responsePaymentData) {
                const requestData = {
                    businessId: SafeCashPayment.getBusinessId(),
                    posId: session.pos.deviceID,
                    transactionId: data.transactionId,
                    amount:  Math.abs(data.amount),
                    externalId: `${session.pos.deviceID}-${moment().unix()}`
                }

                try{

                    const res = await SafeCashPayment.apiRequest('/transactions/refund', requestData);
                    let reqData = {
                        businessId: SafeCashPayment.getBusinessId(),
                        transactionId: res.data.transactionId,
                    } 
                    if (res.response.status == 200) {  
                        const promise: Promise<{success: boolean, error: string, isCanceled: boolean,response? :any}> = new Promise<resolvePaymentObject>((resolve) => {
                            this.resolvePayment = resolve;
                        });
                        this.pollingRefundPayment(reqData);   
                        return promise; 
                          
                    }
                    else {
                        return Promise.resolve({success: false, error: `${i18next.t('safeCash.errorMessage')}: ${res.data.description}`});
                    }
                    
                }catch(e){
                    console.error('Error apiRefundPayment' + e);
                    return Promise.resolve({success: false, error: `${i18next.t('safeCash.errorMessage')}: ${e}`});
                }
            }

            cancelLoad(paymentToCancel: any): Promise<any> {
                throw new Error("Method not implemented.");
            }

            loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
                throw new Error("Method not implemented.");
            }

            getCardNumber(): Promise<GetCardNumberResponse> {
                throw new Error("Method not implemented.");
            }

            getBalance (cardNumber: string, cvv?:string): Promise<GetBalanceResponse> {
                throw new Error("Method not implemented.");
            }

            public setRequestPaymentData () {
                let businessId = SafeCashPayment.getBusinessId()

                this.requestPaymentData = {
                    businessId: businessId,
                    posId: session.pos.deviceID,
                    amount: this.amount,
                    externalId: `${session.pos.deviceID}-${moment().unix()}`
                }

                return this.requestPaymentData
            }
        }
    }
}
