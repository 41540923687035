module PositiveTS { export module Dialogs { export module Hakafa {
  export function validate(enumMode:EnumMode, dialogVals:Fields):{isValid:boolean, msg:string}{
    var _return:{isValid:boolean, msg:string};
    if (enumMode === EnumMode.search) {
      _return = _validateSearch(dialogVals);
    } else {
      _return = _validateInsert(dialogVals);
    }
    return _return;
  }

  function _validateSearch(dialogVals:Fields):{isValid:boolean, msg:string}{
    var isValid = true;
    var msg = "";

    return {isValid:isValid, msg: msg};
  }

  function _validateInsert(dialogVals:Fields):{isValid:boolean, msg:string}{
    var isValid = true;
    var msg = "";

    var birthDate = dialogVals["HakafaSetCustomer-dt_birth_date"];

    if (birthDate && !posUtils.validateBirthDateString( birthDate )){
      isValid = false;
      msg = "BIRTHDAY_FORMAT_NOT_IN_DDMMYYYY"
    }

    if (!_allValuesArePopulated(dialogVals)) {
      isValid = false;
      msg = "ALL_FIELDS_ARE_MANDATORY_MISSING_VALS"
    }

    return {isValid:isValid, msg:msg};
  }

  function _allValuesArePopulated(dialogVals:Fields):boolean{
    const ignoreField = [ "HakafaSetCustomer-CustomerSearch","HakafaSetCustomer-amount", "HakafaSetCustomer-dt_birth_date",
      "HakafaSetCustomer-merakez","HakafaSetCustomer-merakez_id"] ;

    for (var key in dialogVals){
      if (ignoreField.indexOf(key) === -1 && !dialogVals[key]) {
        return false;
      }
    }
    return true;
  }

}}}
