module PositiveTS {
export module Storage {
export module Entity {
export class Voucher extends WasmEntity {

	typeID:string
	name:string
	value:string
	isDiscountVoucher:boolean
	hideFromVoucherScreen: boolean

	constructor() {
		let meta = {
			name: 'Voucher',
			fields: {
				typeID: "TEXT",
				name: "TEXT",
				value: "TEXT",
				typeStatusID: "TEXT",
				realDiscountPrecetage: "TEXT",
				minimumAmount: "TEXT",
				fromDate: "TEXT",
				toDate: "TEXT",
				isActive: "BOOL",
				isDiscountVoucher: "BOOL",
				quantityInInventory: "TEXT",
				slipText: "TEXT",
				maxPercent: "TEXT",
				allowCreditVoucher: "BOOL",
				hasBarcode: "BOOL",
				smartVoucherType: "TEXT",
				valuTypeId: "INT",
				isChargeable: "BOOL",
				allowKefel: "BOOL",
				praxellManpik:  "TEXT",
				mutipassInit: "TEXT",
				multipassCompanyIdLimit: "TEXT",
				numberOfUseOnSale: "INT",
				hideFromVoucherScreen: "BOOL"
			}
		}
		super(meta);
	}

	static SMART_VOUCHER_PRAXELL = "Praxell";
	static SMART_VOUCHER_VALU = "Valu";
	static SMART_VOUCHER_GPP = "GPP";
	static SMART_VOUCHER_PLUXEE = "Pluxee";
	static SMART_VOUCHER_CIBUS = "Cibus"; // Used for backward compatibility for pluxee
  	static SMART_VOUCHER_VALUE_CARD = "VALUE_CARD";
	static SMART_VOUCHER_TENBIS = "Tenbis";
	static SMART_VOUCHER_CAVERETPAYMENT = "CaveretPayment";
	static SMART_VOUCHER_BEENGO = "BEENGO";
	static SMART_VOUCHER_MULTIPASS = "Multipass";
	static SMART_VOUCHER_GOODI = "goodi"
	static SMART_VOUCHER_DTS = "DTS_CLUB"
	static SMART_VOUCHER_BANXPAYMENT = "banx";
	static SMART_VOUCHER_SAFECASH = "KashCash";
	static SMART_VOUCHER_YAADPAYMENT = "yaad";
	static SMART_VOUCHER_MULTIPASS_POLICE = "MultipassPolice";
	static SMART_VOUCHER_INTERSERVICE = "InterService";
	static SMART_VOUCHER_OTOT = "OTOT";
	static VOUCHER_MISHLOHA = "Mishloha";
	static DYNAMIC_DELIVERY_API = "dynamicDeliveryApi";
	static SMART_VOUCHER_ICMEGA = "ICMega";

	static allByTypeId = null


	static dbCache:Array<Voucher> = null

	static getAllByTypeId() {
		if (!this.allByTypeId) {
			this.allByTypeId = _.keyBy((new Storage.Entity.Voucher()).allWithoutPromise(), 'typeID');
		}

		return this.allByTypeId;
	}

	static getVoucherNameByTypeId(typeId) {
		let voucher = this.getAllByTypeId()[typeId];
		return voucher ? voucher.name : '-';
	}

	getCache() {
		return super.getCache<Voucher>("dbCache");
	}

	static async getCache():Promise<Array<Voucher>> {
		let res = await (new Voucher()).getCache()
		return res;
	}

	static isPickupOrderVoucherType(voucherType) {
		return [
			Voucher.VOUCHER_MISHLOHA,
			Voucher.DYNAMIC_DELIVERY_API,
		].includes(voucherType);
	}

	fetcByStoreAndBySmartVoucherType(storeID, smartVoucherType: string): any[] {
		let voucherStoreEntityName = (new PositiveTS.Storage.Entity.VoucherStore()).meta.name;
		let sql = `SELECT v.* FROM ${this.meta.name} AS v
					  WHERE ( EXISTS (SELECT 1 FROM ${voucherStoreEntityName} WHERE storeID = ${storeID} AND voucherTypeID = v.typeID) 
					  OR NOT EXISTS (SELECT 1 FROM ${voucherStoreEntityName} WHERE voucherTypeID = v.typeID))
					  AND v.smartVoucherType = '${smartVoucherType}'`
		let result = this.execAndConvertResult(sql);		
		return result.filter(voucher => this.isCurrentItemDateEffective(voucher));		
	}

fetchByTypeID (typeID) {
	let query = `SELECT * FROM ${this.meta.name}
					 WHERE typeID = ${typeID}`;


	return this.execAndConvertResult(query);
};

isCurrentItemDateEffective (  currentVoucher ){
  var reMonthIn3Dig = new RegExp("[a-z,A-Z]");

  var today = new Date();
  if (currentVoucher.fromDate ) {
        var fromDate;
        if (reMonthIn3Dig.test( currentVoucher.fromDate ) ) {
          fromDate =  new Date( currentVoucher.fromDate);
        } else {
          fromDate =  posUtils.parseIlDate( currentVoucher.fromDate);
        }

        if (  (new Service.DateDiff(today, fromDate)).diffDays > 0 ){
          return false;
        }
  }

  if (currentVoucher.fromDate ) {
        var toDate;
        if (reMonthIn3Dig.test( currentVoucher.toDate ) ) {
          fromDate =  new Date( currentVoucher.toDate);
        } else {
          fromDate =  posUtils.parseIlDate( currentVoucher.toDate);
        }

        if (  (new Service.DateDiff(today, toDate)).diffDays < 0 ){
          return false;
        }
  }

  return true;
}

getVoucherIdsFromItemsInSale (){
    var voucherIds = [];
    for (var i = 0; i < posVC.saleItems.length; i++) {
      var saleItem = posVC.saleItems[i];

      if (saleItem.item != null) {
        var item = saleItem.item;
				if (item.vouchers) {
        	voucherIds = voucherIds.concat(item.vouchers.split('&'));
				}
      }
    };

    voucherIds = $.unique(voucherIds);
    return voucherIds;
};

promiseFetchByStoreAndAllowedTypeIds(storeID) {
	return Promise.resolve(this.fetchByStoreAndAllowedTypeIds(storeID));
};

fetchByStoreAndAllowedTypeIds(storeID) {
	var aThis = this;

	let voucherStoreEntityName = (new PositiveTS.Storage.Entity.VoucherStore()).meta.name;

	let sql = `SELECT v.* FROM ${aThis.meta.name} AS v
				  WHERE ( EXISTS (SELECT 1 FROM ${voucherStoreEntityName} WHERE storeID = ${storeID} AND voucherTypeID = v.typeID) 
				  OR NOT EXISTS (SELECT 1 FROM ${voucherStoreEntityName} WHERE voucherTypeID = v.typeID))`


	// Get the VoucherStore table name
	let result = this.execAndConvertResult(sql);
	
	return result.filter(voucher => aThis.isCurrentItemDateEffective(voucher));
}
}}}}