module PositiveTS {
    
    export module Components {
        
        export module FlightSaleWithPassDialog {

            interface validateObject {
                valid: Boolean,
                errors: Array<String>
            }


            function validate (): validateObject {

                let errors = [];

                if(this.flightNumber == null || this.name == null || this.date == null || this.flightNumber.trim().length == 0  || this.name.trim().length == 0) {
                    errors.push(i18next.t('flightSaleWithPassDialog.fillAllFields'));
                } 
                else if(isNaN(this.flightNumber) || this.flightNumber > 999999) {
                    errors.push(i18next.t('flightSaleWithPassDialog.wrongFlightNumber'));
                }                

                return {valid: errors.length ? false : true, errors}
            }

            function showErrors (errors: Array<string>): void {
                this.showAlertGenericDialog(errors.join("\n"))
                console.log('errors', errors)
            }

            function confirm (): void {
                
                const validate = this.validate()

                if (validate.valid){
                    let tmpData = JSON.parse(posVC.sale.jsondata);
                    tmpData.flightPassData ={
                        name: this.name,
                        date: this.date,
                        flightNumber: this.flightNumber                        
                    };
                    posVC.sale.jsondata = JSON.stringify(tmpData);

                    this.close();
                    this.resolveFunc();
                    this.resetData();
                }else{
                    this.showErrors(validate.errors)
                }
                
            }

            function cancel () {
                this.close()
                if (this.rejectFunc){
                    this.rejectFunc()
                }
                this.resetData()
            }

            function close (closeJustTheDialog: boolean = false): void {
                if (!closeJustTheDialog){
                    $(document).unbind('keypress');
                    document.removeEventListener('keydown', disabledEscape, true);
                    $(document).keypress(posVC.onKeyPress);
                }
                
                if (this.dialogElem == null) {
                    this.setDialogElement();              
                }
                
                this.dialogElem.close();   
            }

            async function open () {
                if (this.dialogElem == null) {
                    this.setDialogElement();               
                }
                
                let today = new Date();
                let yesteday = new Date();
                yesteday.setDate(yesteday.getDate() - 1);
                let tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.allowedDates = [yesteday.toDateString(),today.toDateString(),tomorrow.toDateString()];

                this.dialogElem.showModal();

                this.$nextTick(() => {
                    $(document).unbind('keypress');
                    document.addEventListener('keydown', disabledEscape, true);
                })
                    
                return new Promise((resolve,reject) => {
                    this.resolveFunc = resolve;
                    this.rejectFunc = reject;
                })

            }

            function disabledEscape (e) {
                if (e.key == 'Escape') {
                    e.preventDefault();
                }
            }

            function initData (){
                const data: {
                    barcode: any,
                    name: any,
                    flightNumber: any,
                    date: any,
                    allowedDates: any,
                    isScanPass: boolean,
                    dialogElem: any,
                    resolveFunc: Promise<null>,
                    rejectFunc: Promise<null>,
                } = {
                    barcode: null,
                    name: null,
                    flightNumber: null,
                    date: null,
                    allowedDates: [],
                    isScanPass: true,
                    dialogElem: null,                  
                    resolveFunc: null,
                    rejectFunc: null,
                }
                return data
            }

            function resetData () {
                Object.assign(this.$data, this.$options.data.call(this));
            }

            function updateDataFromPassBarcode() {
                function getDayOfYear(dateStr) {
                    let date = new Date(dateStr);
                    const timestamp1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
                    const timestamp2 = Date.UTC(date.getFullYear(), 0, 0);                  
                    const differenceInMilliseconds = timestamp1 - timestamp2;                  
                    const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;                  
                    return differenceInDays;
                  }

                if(this.barcode.length < 47) {
                    this.showAlertGenericDialog(i18next.t('flightSaleWithPassDialog.wrongBarcode'));
                    this.barcode = null;
                    return;
                }

                this.name = this.barcode.slice(2,22);
                this.flightNumber = this.barcode.slice(39,44);
         
                let tmpDate = this.barcode.slice(44,47);
                let checkDate = this.allowedDates.findIndex( date => tmpDate == getDayOfYear(date));
                
                if(checkDate == -1) {
                    this.showAlertGenericDialog(i18next.t('flightSaleWithPassDialog.wrongDate'));
                    this.barcode = null;
                    this.name = null;
                    this.flightNumber = null;
                    return; 
                }
                else {
                    this.date = this.allowedDates[checkDate];
                }
            }

            function setDialogElement () {            
                this.dialogElem = (<HTMLDialogElement>document.getElementById('flight-sale-with-pass-dialog'))
            }

            function scanForP2PRO() {
                app.showLoadingMessage(i18next.t("flightsOnlineLoginDialog.pleaseScanQrCode"));
                let data = Android.scanQrCode();      
                app.hideLoadingMessage();
                
                this.barcode = data;
                this.updateDataFromPassBarcode();
            }

            export function create() {
  
                let component = {
                  template: JST.flightSaleWithPassDialog(),
                  mixins: [Mixins.modalsMixin],
                  methods: {
                    open,
                    close,
                    confirm,
                    validate,
                    showErrors,
                    resetData,
                    setDialogElement,
                    updateDataFromPassBarcode,
                    cancel,
                    scanForP2PRO,
                  },
                  data: initData,
                  computed: { 
                    isAndroid() {
                        return session.isAndroid;
                    },
                    mobileLayout(){
                        return Pinia.globalStore.mobileLayout
                      },
                  },
                  mounted () {
                      this.setDialogElement()
                  }
                }

                VueApp.component('flight-sale-with-pass-dialog',component)
            }

        }

    }

}