module PositiveTS {
  export module Components {
    export module FlightsPrintBarsetCountReport {

      export function getComponent() {
        return {
          template: JST.flightsPrintBarsetCountReport(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.barsetMixin],
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight} = Pinia.storeToRefs(flightsStore);
            return {currentFlight}
          },
          methods: {
            print() {
              app.showLoadingMessage(i18next.t("loading"));
              this.getCurrentBarset().then(async barset => {
                try {
                let currentEmployee = this.wizardData.loggedInEmployee;

                // if Closed leg wizard
                if (! currentEmployee) {
                  currentEmployee = (await Storage.Entity.Employee.fetchByEmployeeID(session.pos.employeeID));
                }


                Printing.FlightReports.printFlightInventoryReport(session.store.storeName,
                                                            this.currentFlight,
                                                            this.wizardData.selectedLeg,
                                                            this.getBarsetItemsMapped(),
                                                            currentEmployee);
				        app.hideLoadingMessage();
                this.goToNextStep();
                } catch(err) {
                  app.hideLoadingMessage();
                  console.error(err);
                  this.showGenericError();
                }
              })
            }
          },
        }
      }
    }
  }
}