import { defineStore } from 'pinia';


function sortLegs(leg1, leg2) {
    return leg1.leg_number - leg2.leg_number;
}

const state = {
    isFlightLoadedNow: false,
    flight: null,
    leg: null,
    saleData: {},
    barset: null,
}

const getters = {
    currentFlight:(state) => {
        return state.flight;
    },
    currentLeg:(state) => {
        return state.leg;
    },
    nextUnopenedLeg:(state) => {
        return state.flight && state.flight.legs.filter(leg => !leg.is_opened).sort(sortLegs)[0] || null;
    },
    lastClosedLeg:(state) => {
        let closedLegs = state.flight && state.flight.legs.filter(leg => leg.is_closed).sort(sortLegs) || null;
        return closedLegs && closedLegs.length && closedLegs[closedLegs.length - 1] || null;
    },
    isPreviousClosedLegTeam: (state) => {
        return getters.lastClosedLeg(state) && (!getters.lastClosedLeg(state).is_team_changed);
    },
    isLastLeg: (state) => {
        return !getters.nextUnopenedLeg(state);
    },
    currentTeamLegs: (state) => {
        if (!(getters.currentFlight(state) && getters.currentLeg(state))) {
            return [];
        }

        let orderedLegs = state.flight.legs.sort(sortLegs);

        let currentFlightLegs = [];
        for (let leg of orderedLegs) {
            if (leg.is_team_changed) {
                currentFlightLegs = [];
            } else {
                currentFlightLegs.push(leg);
            }

            if (leg.id == getters.currentLeg(state).id) {
                return currentFlightLegs;
            }
        }

        return [];
    },
    getPaymentData:(state) => {
        return (method) => {
            return state.saleData && state.saleData.payments && state.saleData.payments[method] || {};
        }
    },
    currenctBarset:(state) => {
        return state.barset;
    },
    currentBarsetItems:(state) => {
        return ((state.barset && state.barset.barset_items) || []).filter(barsetItem => session.allItems.get(barsetItem.code));
    },
    currentBarsetItemsById: (state) => {
        return _.keyBy(getters.currentBarsetItems(state), 'id');
    },
    currentBarsetItemsByItemCode: (state) => {
        return _.groupBy(getters.currentBarsetItems(state), 'code');
    },
    currentBarsetItemsAsItems: (state) => {
        return getters.currentBarsetItems(state).map(barsetItem => session.allItems.get(barsetItem.code));
    },
    currentBarsetItemsCodes: (state) => {
        return getters.currentBarsetItems(state).map(barsetItem => barsetItem.code);
    },
    currentBarsetItemsCodesAsHash(state) {
        let hash = {};

        for (let barsetItem of getters.currentBarsetItems(state)) {
            hash[barsetItem.code] = true;
        }
        return hash;
    },
    totalSalesAmount:(state) => {
        return session.fixedFloat(state.saleData.totalSalesAmount || 0, 2);
    },
    getFlightLegById: (state) => {
        return legId => state.flight && state.flight.legs.filter(leg => leg.id == legId)[0] || null;
    },
    getItemInventoryByCode: (state) => {
        return (itemCode) => {
            let barsetItems = getters.currentBarsetItemsByItemCode(state)[itemCode];

            if (barsetItems && barsetItems.length > 0) {
                return barsetItems.reduce((total, barsetItem) => total + getters.getItemInventoryByBarsetItemId(state)(barsetItem.id), 0);
            }

            return 0;
        }
    },
    getItemInventoryByBarsetItemId: (state) => {
        return (barsetItemId) => {
            if (state.saleData && state.saleData.barsetItemsInventory) {
                return parseInt(state.saleData.barsetItemsInventory[barsetItemId] || 0);
            }

            return 0;
        }
    },
}

const actions = {
    setFlightState(data) {
        let [flight, leg, barset] = data;

        this.flight = flight;
        this.leg = leg;
        this.barset = barset;
    },
    setSaleData(data) {
        this.saleData = data;
    },
    setFlightLoaded(payload) {
        this.isFlightLoadedNow = payload;
    },
    async setFlightLegs(legs) {
        await appDB.flights.update(this.flight.id, { legs: PositiveTS.Shared.DB.checkIfNeedCloneProxy(legs) });
        await this.refreshStateFlight();
    },
    async refreshStateFlight() {
        app.showLoadingMessage();
        let flight = await appDB.flights.toCollection().first();
        let leg = null;

        if (flight) {
            let openedLegs = flight.legs.filter(leg => leg.is_opened && (!leg.is_closed));

            leg = openedLegs.length ? openedLegs[0] : null;
        }

        let barset = await appDB.barsets.toCollection().first();

        await this.setFlightState([flight, leg, barset]);
        await this.updateSessionMenuItems();
        await this.calculateSalesData();
        app.hideLoadingMessage();
    },
    async clearFlightsData() {
        await appDB.airports.clear();
        await appDB.barsets.clear();
        await appDB.flights.clear();

        if (session.pos.hasFlights) {
            await appDB.currencies.clear();
        }
    },
    async setFlightsData(flightData) {
        await this.clearFlightsData();
        await appDB.airports.bulkPut(_.cloneDeep(flightData.airports));
        await appDB.barsets.bulkPut(_.cloneDeep([flightData.barset]));
        await appDB.flights.bulkPut(_.cloneDeep([flightData.flight]));
        await appDB.currencies.bulkPut(_.cloneDeep(flightData.flight.currencies));
        await PositiveTS.Service.Currencies.fetchExternalCurrenciesFromDB();
        this.setFlightLoaded(true);
        await this.refreshStateFlight();
    },
    async disconnectFlight() {
        await this.clearFlightsData()
        await this.refreshStateFlight()
    },
    async openLeg(wizardData) {
        if (!this.flight) {
            throw new Error("Flight not found");
        }

        let newLeg = wizardData.selectedLeg;

        if (this.isPreviousClosedLegTeam) {
            newLeg.authorizes_employees = this.lastClosedLeg.authorizes_employees;
            newLeg.main_employee = this.lastClosedLeg.main_employee;

        } else {
            newLeg.authorizes_employees = (wizardData.authorizesEmployees || []).concat([wizardData.loggedInEmployee]);
            newLeg.main_employee = wizardData.loggedInEmployee.employeeID;
        }
        newLeg.is_opened = true;

        newLeg.start_count = wizardData.itemsCount;


        let legs = this.flight.legs;

        let legFound = false;

        for (let legIndex in legs) {
            if (legs[legIndex].id == newLeg.id) {
                legs[legIndex] = newLeg;
                legFound = true;
            }
        }

        if (!legFound) {
            throw new Error("Leg not found");
        }

        session.pos.employeeID = wizardData.loggedInEmployee.employeeID;
        session.pos.employeeName = wizardData.loggedInEmployee.name;
        $('.header-cashier').text(wizardData.loggedInEmployee.name);
        session.pos.persist();
        await PositiveTS.Storage.Entity.Sale.updateCashierToOpenSale(session.pos.employeeID, session.pos.employeeName);

        await this.setFlightLegs(legs);
        await this.refreshStateFlight();
    },
    async closeLeg(wizardData) {
        if (!this.flight) {
            throw new Error("Flight not found");
        }

        if (!this.leg) {
            throw new Error("Current Leg not found");
        }

        // need to be on first before changing leg
        if (wizardData.currencies) {
            // Cashier statement
            let currencies = wizardData.currencies.filter(cur => parseFloat(cur.amount));
            let totalAmount = currencies.reduce((accumulator, currency) => accumulator + currency.amount, 0);
            await PositiveTS.Service.CashierStatement.generateMultiCurrencyStatement(currencies, totalAmount, PositiveTS.Shared.Constants.CashierStatement.TYPE_START_DAY);
        }

        let currentLeg = wizardData.selectedLeg;
        currentLeg.number_of_passengers = parseInt(wizardData.numberOfPassengers);
        currentLeg.is_closed = true;
        currentLeg.is_team_changed = wizardData.isTeamChange;

        currentLeg.end_count = wizardData.itemsCount;

        let legs = this.flight.legs;

        let legFound = false;

        for (let legIndex in legs) {
            if (legs[legIndex].id == currentLeg.id) {
                legs[legIndex] = currentLeg;
                legFound = true;
            }
        }

        if (!legFound) {
            throw new Error("Leg not found");
        }

        await this.setFlightLegs(legs);
        await this.refreshStateFlight();
    },
    async finishFlight() {
        await this.clearFlightsData();
        await this.refreshStateFlight();
    },
    async openIsrairFlight(wizardData) {
        let flight: any = {
            id: 1,
            code: wizardData.flightCode,
            take_off_time: new Date(),
            legs: [{
                authorizes_employees: wizardData.authorizesEmployees,
                is_opened: true,
            }]
        };


        let barsetItems = [];

        Array.from(session.allItems.values()).forEach((item) => {
            barsetItems.push({
                item_id: item.id,
                code: item.code,
                flight_start_count: wizardData.itemsCount[item.code] || 0
            });
        });

        let barset: any = { barset_items: barsetItems, id: 1 };


        session.pos.employeeID = null;
        session.pos.employeeName = null;
        $('.header-cashier').text('');
        session.pos.persist();
        await appDB.flights.bulkPut(_.cloneDeep([flight]));
        await appDB.barsets.bulkPut(_.cloneDeep([barset]));
        await this.refreshStateFlight();
    },
    async closeIsrairFlight(wizardData) {
        if (!this.flight) {
            throw new Error("Flight not found");
        }

        let barset = await appDB.barsets.toCollection().first();

        if (!barset) {
            throw new Error("Barset not found");
        }

        for (let itemIndex in (barset.barset_items || [])) {
            barset.barset_items[itemIndex].flight_end_count = wizardData.itemsCount[barset.barset_items[itemIndex].code] || 0;
        }

        await appDB.barsets.update(barset.id, PositiveTS.Shared.DB.checkIfNeedCloneProxy(barset));
        await appDB.flights.update(this.flight.id, { is_closed: true });
        await this.refreshStateFlight();
    },
    async setFlightSales(sales) {
        if (!this.flight) {
            throw new Error("Flight not found");
        }

        await appDB.flights.update(this.flight.id, { sales: PositiveTS.Shared.DB.checkIfNeedCloneProxy(sales.map(sale => sale.invoiceSequence)) });
        await this.refreshStateFlight();
    },
    async calculateSalesData() {
        let currentLegsIds = this.currentTeamLegs.map(leg => leg.id);
        let legsSales = (await appDB.sales.where('invoiceSequence').above(-1).toArray()).filter(sale => currentLegsIds.includes(sale.flightLegId));
        let salesPayments = PositiveTS.Service.MultiCurrencyPayments.getPaymentsBySalesAndCurrencies(legsSales);

        let lastCount = {};

        if (this.currentTeamLegs && this.currentTeamLegs[0] && this.currentTeamLegs[0].start_count) {
            // If middle(leg opened - in pos)/end of leg(close leg wizard)
            lastCount = this.currentTeamLegs[0].start_count;
        } else {
            if (!this.lastClosedLeg) {
                // If start of the flight(opening first leg, open leg wizard)
                for (let barsetItem of this.currentBarsetItems) {
                    lastCount[barsetItem.id] = barsetItem.packed_count;
                }
            } else {
                // if start of leg and has previous legs(open leg wizard)
                lastCount = this.lastClosedLeg.end_count;
            }
        }

        let barsetItemsInventory = PositiveTS.Storage.Entity.Barset.calculateCurrentInventory(lastCount, legsSales);

        this.setSaleData({ ...this.saleData, barsetItemsInventory, payments: salesPayments, totalSalesAmount: _.sumBy(legsSales, sale => sale.totalAmount) });
    },
    async updateSessionMenuItems() {
        session.allMenuItems = (await (new PositiveTS.Storage.Entity.ItemMenuItem().onlyFlightsBarsetItem()));
    },
}

const flightsStore = defineStore('flights', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default flightsStore;