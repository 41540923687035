module PositiveTS {
    export module Components {
        export module Mixins {
            export let itemImageMixin = {
                data() {
                    return {
                    }
                },
                computed: {
                    computedItemRef(){
                        if(this.item?.itemRef){
                            return this.item.itemRef;
                        }else{
                            return this.item;
                        }
                    },
                    srcImg() {
                        let item = this.computedItemRef;
                        let image = this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM];
                        if (item.pictureUrl) {
                            image = item.pictureUrl;
                        } else if (item.hasPicture) {
                            image = `/catalog-images/items/${session.pos.tenantID}/${session.pos.companyID}/${item.code}`
                        }
                        return image;
                    },
                    hasImg(): boolean {
                        let item = this.computedItemRef;
                        return Boolean(item.hasPicture);
                    },
                    defaultImages(){
                        return Pinia.globalStore.defaultImages
                    }
                },
            }
        }
    }
}

