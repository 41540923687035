module PositiveTS {
export module Service {


  export class FullSale {
    sale:Storage.Entity.Sale;
    saleItems:Storage.Entity.SaleItem[];
    salePayments:Storage.Entity.SalePayment[];
    constructor(sale:Storage.Entity.Sale, saleItems:Storage.Entity.SaleItem[], salePayments:Storage.Entity.SalePayment[]) {
      this.sale = sale;
      this.saleItems = saleItems;
      this.salePayments = salePayments;
    }

    async persist() {
      return FullSale.persist(this.sale,this.saleItems,this.salePayments);
    }

    static saveCurrentSale() {
      return FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
    }

    static async updateInventory(sale:Storage.Entity.Sale, saleItems:Storage.Entity.SaleItem[]) {
      
      if (session && session.pos && session.pos.isRoshemet) {
        let codes = saleItems.map(si => si.item.code);
        let dbItems = await appDB.localItems.where('code').anyOf(codes).toArray();
        for (let saleItem of saleItems) {
          let item = session.allItems.get(saleItem.itemCode)
          let currInv = Number(item.currentInventory)
          if (currInv == null) {
            currInv = 0;
          }
          currInv -= saleItem.quantity;
          item.currentInventory = currInv;
          let dbItem = dbItems.filter(item => item.code == saleItem.item.code)[0]
          dbItem.currentInventory = currInv;
        
        }
        return await appDB.localItems.bulkPut(dbItems);
      }
      else {
        return;
      }
      
    }

    static  getFullSaleFormat(sale:Storage.Entity.Sale, saleItems:Storage.Entity.SaleItem[], salePayments:Storage.Entity.SalePayment[],useSaleDate = false){
      let dbSale = Object.assign(sale._data,{id: sale.id});
      let isEilatCustomerInSaleInNonEilatStore = localStorage.getItem("eilatCustomerInSaleInNonEilatStore") == "true"
      
      for (let saleItem of saleItems) {
        let counter = 1;

        if(isEilatCustomerInSaleInNonEilatStore && !saleItem.alwaysHasVat) {
          saleItem.noVat = true
        }

        if (saleItem.children) {
          saleItem.invoiceSequence = sale.invoiceSequence;
          for (let childItem of saleItem.children) {
            childItem.saleID = saleItem.saleID;
            childItem.invoiceSequence = sale.invoiceSequence;
            childItem.rowNumber = (saleItem.rowNumber+1)*10000 + counter;
            childItem.parentRowNumber = saleItem.rowNumber;
            if(isEilatCustomerInSaleInNonEilatStore && !childItem.alwaysHasVat) {
              childItem.noVat = true
            }
            // saleItem.unitPrice += childItem.hasWeight ? childItem.quantity*childItem.unitPrice : childItem.unitPrice;
            if (childItem.children) {
              let innerCounter = 1;
              for (let granChildItem of childItem.children) {
                granChildItem.saleID = saleItem.saleID;
                granChildItem.invoiceSequence = sale.invoiceSequence;
                granChildItem.rowNumber = childItem.rowNumber + (1000*counter) + innerCounter - counter;
                granChildItem.parentRowNumber = childItem.rowNumber;
                if(isEilatCustomerInSaleInNonEilatStore && !granChildItem.alwaysHasVat) {
                  granChildItem.noVat = true
                }
                // saleItem.unitPrice += granChildItem.hasWeight ? granChildItem.quantity*granChildItem.unitPrice : granChildItem.unitPrice;
                innerCounter += 1;
              }
            }
            counter += 1;
          }
        }
      }
      for (let salePayment of salePayments) {
        salePayment.saleID = sale.id;
        salePayment.invoiceSequence = sale.invoiceSequence;
      }
      let dbItems = Storage.Entity.SaleItem.flattenItems(saleItems).map(item => item._data)
      if (useSaleDate) { //if we got the sales from the server (on login) we persist them with their original time.
        dbSale.createdAt = new Date(sale.createdAt);
      }
      else {
        dbSale.createdAt = new Date();
      }
      dbSale.formatted_timestamp = moment(new Date()).format("DD/MM/YYYY HH:mm:ss.SS");
      dbSale.timestamp = dbSale.createdAt.getTime();
      sale.createdAt = dbSale.createdAt;
      sale.timestamp = dbSale.timestamp;
      dbSale = Object.assign(dbSale,{items: dbItems,payments: salePayments.map(pay => pay._data)});
      return dbSale
    }

    static async persist(sale:Storage.Entity.Sale, saleItems:Storage.Entity.SaleItem[], salePayments:Storage.Entity.SalePayment[],useSaleDate = false):Promise<any> {
      let formattedFullSale = FullSale.getFullSaleFormat(sale,saleItems,salePayments,useSaleDate)
      await appDB.sales.put(formattedFullSale);
      return formattedFullSale;
    }

    static async persistAndReturnID(sale:Storage.Entity.Sale, saleItems:Storage.Entity.SaleItem[], salePayments:Storage.Entity.SalePayment[],useSaleDate = false) {
      let formattedFullSale = FullSale.getFullSaleFormat(sale,saleItems,salePayments,useSaleDate)
      return await appDB.sales.put(formattedFullSale);
    }

    get cloneSale():Storage.Entity.Sale {
      let cloneSale = new PositiveTS.Storage.Entity.Sale();
      cloneSale.importFromObject(this.sale.exportToObject());
      return cloneSale;
    }

    get cloneSalePayments():Storage.Entity.SalePayment[] {
      let cloned = []
      for (let salePayment of this.salePayments) {
        let salePaymentCopy = new PositiveTS.Storage.Entity.SalePayment();
        salePaymentCopy.importFromObject(salePayment.exportToObject());
        cloned.push(salePaymentCopy);
      };

      return cloned;
    }

    get cloneSaleItems():Storage.Entity.SaleItem[] {
      let cloned = []
      for (let saleItem of this.saleItems) {
        cloned.push(_.cloneDeep(saleItem)); 
      };
      return cloned;
    }

    clone():FullSale {
      return new FullSale(this.cloneSale, this.cloneSaleItems, this.cloneSalePayments)
    }

    getIncrementAndPersistPromises(sequence:Storage.Entity.Sequence):Promise<any>[] {
      let promises = []
      sequence.sequence++;
      
      this.sale.invoiceSequence = sequence.sequence;
      promises.push(appDB.sequences.put(sequence)); 
      promises.push(this.persist())

      return promises;
    }

    async IncrementSeqAndPersist():Promise<any>{
      return appDB.transaction("rw",appDB.sales,appDB.sequences,async () => {
        let seqObj = await Storage.Entity.Sequence.getSequenceForInvType(this.sale.invoiceType);
        
        await Promise.all(this.getIncrementAndPersistPromises(seqObj))  
      })

    }

    static get posVCSale() {
      return new FullSale(posVC.sale, posVC.saleItems, posVC.salePayments);
    }

  }

}}
