module PositiveTS {
    export module Service {
        export module MenuAndItemGroup {
            function currentBarsetItemsAsItemsWithNotInventoryItem(): PositiveTS.Storage.Entity.Item[] {
                return Pinia.posMenu.currentBarsetItemsAsItemsWithNotInventoryItem;
            }
            function getCurrentMigvanId(): number {
                return Pinia.posMenu.currentMigvanId;
            }
            function isFlightsGroupsMenu() {
                return Boolean(Pinia.posMenu.isFlightsGroupsMenu);
            }
            function getPosInfo() {
                let isRoshemet = Boolean(Pinia.globalStore.isRoshemet);
                let simpleSelfService = Boolean(Pinia.globalStore.simpleSelfService);
                let shouldShowOtotPrintBracelet = Boolean(Pinia.globalStore.shouldShowOtotPrintBracelet);
                let showOtotRFIDBraceletActivation = Boolean(Pinia.globalStore.showOtotRFIDBraceletActivation);
                let multiLangEnabled = Boolean(Pinia.languageManagerStore.multiLangEnabled);
                return { multiLangEnabled, simpleSelfService, isRoshemet, shouldShowOtotPrintBracelet, showOtotRFIDBraceletActivation }
            }
            /** for flights (JR) */
            export async function getFlightsTopLevelMenuItems() {
                let allItems = currentBarsetItemsAsItemsWithNotInventoryItem();
                let itemsForMenu = [];
                let categoriesIds = [];
                for (const item of allItems) {
                    if (posUtils.isBlank(item['itemMainClassID'])) { 
                        itemsForMenu.push(PositiveTS.Storage.Entity.Item.fromItemsToMenuItem(item, 1))
                    } else {
                        categoriesIds.push(it['itemMainClassID'])
                    }
                }
                let categories = await Storage.Entity.PrimaryCategory.getByIds(categoriesIds, 'name');
                let menuButtons = convertCategoriesToMenuItems(categories, 1);
                return menuButtons.concat(itemsForMenu);
            }
            /** for flights (JR) */
            export async function getFlightsPrimaryMenuItems(button: PositiveTS.Types.MenuButton) {
                let allLevelItems: PositiveTS.Storage.Entity.Item[] = currentBarsetItemsAsItemsWithNotInventoryItem().filter(i => i['itemMainClassID'] == button.id);
                let itemsForMenu = [];
                let categoriesIds = [];
                for (const item of allLevelItems) {
                    if (posUtils.isBlank(item['itemMainSubClassID'])) {
                        itemsForMenu.push(PositiveTS.Storage.Entity.Item.fromItemsToMenuItem(item, 2))
                    } else {
                        categoriesIds.push(item['itemMainSubClassID'])
                    }
                }
                let categories = await Storage.Entity.SecondaryCategory.getByIds(categoriesIds, 'name');
                let menuButtons = convertCategoriesToMenuItems(categories, 2);
                return menuButtons.concat(itemsForMenu);
            }
            /** for flights (JR) */
            export function getFlightsSecondaryMenuItems(button: PositiveTS.Types.MenuButton): PositiveTS.Types.MenuButton[] {
                let allLevelItems: PositiveTS.Storage.Entity.Item[] = currentBarsetItemsAsItemsWithNotInventoryItem().filter(item => item['itemMainSubClassID'] == button.id);
                return allLevelItems.map(item => PositiveTS.Storage.Entity.Item.fromItemsToMenuItem(item, 3));
            }
            /** Get Item's childern (i.e. Item Group items) */
            export async function getGroupsAndItemsByItemId(args: PositiveTS.Types.ItemGroupItemsArgs): Promise<Storage.Entity.ItemGroup[]> {
                let { multiLangEnabled } = getPosInfo();
                let itemsAndroups = await Storage.Entity.ItemGroup.getGroupsAndItemsByItemId(args.ItemId);
                if (multiLangEnabled) {
                    itemsAndroups = itemsAndroups.map(ig => {
                        ig = PositiveTS.Service.Translation.translateModel(ig, "ItemGroup", args.lang);
                        ig.itemGroupItems = ig.itemGroupItems.map(igi => {
                            let trans = PositiveTS.Storage.Entity.Item.getItemTranslations(igi.itemCode);
                            return PositiveTS.Service.Translation.translateModel(igi, "ItemGroupItem", args.lang, trans);
                        });
                        return ig;
                    });
                }
                return itemsAndroups;
            }
            /**
             * Returns primary menu buttons and main menu items
             * @param lang Conditional parameter. Set this when "multiLangEnabled" in POS is true
             * @returns A tree like object from primary menus down to third menu level
             */
            export async function LoadMenuTree(lang?: string): Promise<PositiveTS.Types.MenuButton[]> {
                let arr: Array<PositiveTS.Types.MenuButton> = [];
                let topLvlItemsAndMenus: Array<PositiveTS.Types.MenuButton> = await PositiveTS.Service.MenuAndItemGroup.loadTopLevelMenusAndItems(lang);
                for (const menu of topLvlItemsAndMenus) {
                    if (menu.isItem || menu.isNotAMenu) {
                        arr.push(menu);
                        continue;
                    }
                    let args = { button: menu, lang: lang };
                    let menuItemsAndSubMenus: Array<PositiveTS.Types.MenuButton> = await PositiveTS.Service.MenuAndItemGroup.getPrimaryMenuItems(args);
                    for (const subMenu of menuItemsAndSubMenus) {
                        if (subMenu.isItem) {
                            continue;
                        }
                        let subArgs = { button: subMenu, lang: lang };
                        let menuItemsAndSubMenus: Array<PositiveTS.Types.MenuButton> = await PositiveTS.Service.MenuAndItemGroup.getSecondaryMenuItems(subArgs);
                        for (const subSubMenu of menuItemsAndSubMenus) {
                            if (subSubMenu.isItem) {
                                continue;
                            }
                            let subSubArgs = { button: subSubMenu, lang: lang };
                            let thirdMenuItems: Array<PositiveTS.Types.MenuButton> = await PositiveTS.Service.MenuAndItemGroup.getThirdMenuItems(subSubArgs);
                            subSubMenu.items = thirdMenuItems;
                        }
                        subMenu.items = menuItemsAndSubMenus;
                    }
                    menu.items = menuItemsAndSubMenus;
                    arr.push(menu);
                }
                return arr;
            }
            /**
             * Returns primary menu buttons and main menu items
             * @param lang Conditional parameter. Set this when "multiLangEnabled" in POS is true
             * @returns 
             */
            export async function loadTopLevelMenusAndItems(lang?: string): Promise<PositiveTS.Types.MenuButton[]> {
                let { multiLangEnabled } = getPosInfo();
                let currentMigvanId = getCurrentMigvanId()
                let { simpleSelfService, isRoshemet, showOtotRFIDBraceletActivation, shouldShowOtotPrintBracelet } = getPosInfo();
                let primaryMenus: PositiveTS.Types.MenuButton[] = [];
                if (isFlightsGroupsMenu()) {
                    return await PositiveTS.Service.MenuAndItemGroup.getFlightsTopLevelMenuItems();
                }
                if (isRoshemet) {
                    primaryMenus = session.allDepartments.map(dep => { return { name: dep.name, isPrimaryMenuItem: true, level: 0, id: String(dep.id) } });
                    let noCatButtons = Array.from(session.allItems.values())
                        .filter(item => (item.departmentId == -1 || item.departmentId == null || item.showInMainMenu == true) && !item.locked && !item.hideFromMenu)
                        .sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
                        .sort((a, b) => (a.menuItemSortOrder > b.menuItemSortOrder) ? 1 : ((b.menuItemSortOrder > a.menuItemSortOrder) ? -1 : 0))
                        .map(item => <PositiveTS.Types.MenuButton>{ name: item.description, isItem: true, level: 0, id: item.code, code: item.code, price: item.priceZarhan })
                    primaryMenus.push(...noCatButtons);
                } else {
                    let primaryMenuItemInstance = new PositiveTS.Storage.Entity.PrimaryMenuItem();
                    let primaryMenuItems = await primaryMenuItemInstance.getAll(currentMigvanId);
                    let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(0, 'primaryMenuItemId', 1, currentMigvanId || 0)
                    primaryMenuItems = primaryMenuItems.concat(items);
                    if (multiLangEnabled) {
                        primaryMenuItems = primaryMenuItems.map(menu => PositiveTS.Service.Translation.translateModel(menu, menu.isItem ? "Item" : "Menu", lang));
                    }
                    primaryMenus = primaryMenuItems;
                }

                if (simpleSelfService && shouldShowOtotPrintBracelet) {
                    let getCardButton: PositiveTS.Types.MenuButton = {
                        name: i18next.t("pos.collectOtotTickets"), isPrimaryMenuItem: true,
                        level: 0, onClickedFunc: PositiveTS.Service.Otot.printEntranceTagFromExistingQrCode, details: null, hasPicture: false,
                        isShowDetails: false, pictureUrl: null, price: null, order: 1, isNotAMenu: true
                    };
                    primaryMenus.unshift(getCardButton);
                    if (simpleSelfService && showOtotRFIDBraceletActivation) {
                        let activateBraceletButton: PositiveTS.Types.MenuButton = {
                            name: i18next.t("pos.activateRfidBracelet"), isPrimaryMenuItem: true,
                            level: 0, onClickedFunc: PositiveTS.Service.Otot.activateRfidBraceletViaQrCode, details: null, hasPicture: false,
                            isShowDetails: false, pictureUrl: null, price: null, order: 1, isNotAMenu: true
                        };
                        primaryMenus.unshift(activateBraceletButton);
                    }
                }

                return primaryMenus;
            }
            export async function getPrimaryMenuItems(args: PositiveTS.Types.LoadMenuItemsArgs): Promise<PositiveTS.Types.MenuButton[]> {
                let button = args.button;
                let { multiLangEnabled } = getPosInfo();
                let { isRoshemet } = getPosInfo();
                if (isFlightsGroupsMenu()) {
                    return await PositiveTS.Service.MenuAndItemGroup.getFlightsPrimaryMenuItems(button);
                }
                if (isRoshemet) {
                    return Array.from(session.allItems.values())
                        .filter(item => item.departmentId == Number(button.id) && !item.locked && !item.hideFromMenu)
                        .map(item => { return { level: 1, isItem: true, name: item.description, code: item.code, id: item.code, order: item.menuItemSortOrder, price: item.priceZarhan }; })
                        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
                } else {
                    let menuButtons = await (new PositiveTS.Storage.Entity.SecondaryMenuItem()).getAll(button.id)
                    let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(button.id, 'primaryMenuItemId', 2, getCurrentMigvanId())
                    menuButtons = menuButtons.concat(items);
                    if (multiLangEnabled) {
                        menuButtons = menuButtons.map(menu => PositiveTS.Service.Translation.translateModel(menu, menu.isItem ? "Item" : "Menu", args.lang));
                    }
                    return menuButtons;
                }
            }
            export async function getSecondaryMenuItems(args: PositiveTS.Types.LoadMenuItemsArgs): Promise<PositiveTS.Types.MenuButton[]> {
                let button = args.button;
                let { multiLangEnabled } = getPosInfo();
                let menuButtons: PositiveTS.Types.MenuButton[] = [];
                if (isFlightsGroupsMenu()) {
                    menuButtons = PositiveTS.Service.MenuAndItemGroup.getFlightsSecondaryMenuItems(button);
                } else {
                    menuButtons = await (new PositiveTS.Storage.Entity.ThirdMenuItem()).getAll(button.id)
                    let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(button.id, 'secondaryMenuItemId', 3, getCurrentMigvanId())
                    menuButtons = menuButtons.concat(items);
                    if (multiLangEnabled) {
                        menuButtons = menuButtons.map(menu => PositiveTS.Service.Translation.translateModel(menu, menu.isItem ? "Item" : "Menu", args.lang));
                    }
                }
                return menuButtons;
            }
            export async function getThirdMenuItems(args: PositiveTS.Types.LoadMenuItemsArgs): Promise<PositiveTS.Types.MenuButton[]> {
                let button = args.button
                let { multiLangEnabled } = getPosInfo();
                let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(button.id, 'thirdMenuItemId', 4, getCurrentMigvanId() || 0);
                if (multiLangEnabled) {
                    items = items.map(menu => PositiveTS.Service.Translation.translateModel(menu, menu.isItem ? "Item" : "Menu", args.lang));
                }
                return items;
            }
            function convertCategoriesToMenuItems(categories: Array<PositiveTS.Types.CategoryEntity>, level: number): PositiveTS.Types.MenuButton[] {
                let itemsByName = _.keyBy(Array.from(session.allItems.values()), 'description');
                return categories.map(category => ({
                  level: level, isItem: false, itemId: 0, sortOrder: 0, name: category.name,
                  code: category.name, color: null, id: category.id, hasPicture: itemsByName[category.name] && itemsByName[category.name].hasPicture, isPrimaryMenuItem: level == 1,
                  isSecondaryMenuItem: level == 2, isThirdMenuItem: level == 3
                }));
              }
        }
    }
}