module PositiveTS {
  export module Components {
  export module LoginDialog {
  
    const _dialogSelector = "#positive-login-dialog"
    const _passwordDialogSelector = "#positive-login-password"
    const _vueComponentName = 'login-dialog';
  
    export function create() {
      let loginDialog = {
          template: JST.loginDialog(),
          methods: {
            open: open,
            close: close,
            openPasswordDialog: openPasswordDialog,
            closePasswordDialog: closePasswordDialog,
  
            async login() {
              app.showLoadingMessage(i18next.t("loginDialog.loggingIn"));
              let employee = await this.verifyPasswordAndGetUser();
              app.hideLoadingMessage();
  
              if(employee == false) {
                await this.showLoginError(i18next.t('loginDialog.userOrPassNotFoud'));
                Pinia.globalStore.isSlowConnection(false);
                this.password = "";
                return ;
              }
              if(this.requireManager && !Boolean(employee.isManagerDiscountConfirm)) {
                await this.showLoginError(i18next.t('loginDialog.notManager'));
                Pinia.globalStore.isSlowConnection(false);
                this.password = "";
                return ;
              }
        
              if(this.stateIsOnline && !this.slowConnection) { 
                this.saveEmployeePassword(this.taz, this.password, this.magnetic_card);
              }
  
              if(this.sendLoginToCashierStatement) {
                this.sendLoginDetailsToCashierStatement(employee.employeeID, employee.name);
              }
              else if(this.managerApprovePromise != null) {
                $(document).keypress(posVC.onKeyPress);
                this.managerApprovePromise.resolve(employee);
              } else {
                this.sessionEmployeeId = employee.employeeID;
                Pinia.globalStore.login({changePass: employee.changePass || false, employeeID: employee.employeeID, employeeName: employee.name});
              }
  
              this.numOfAttempts = 0;
              this.password = "";
              this.taz = null;
              this.magnetic_card = null;
              this.manual_password = null;
    
              close();
              closePasswordDialog();
            },
            async swipeCard() {
              app.showLoadingMessage(i18next.t("loginDialog.waitingPinPad"));
              let cardNum = await Service.EMV.swipe();
              app.hideLoadingMessage();
              
              if(posUtils.isNullOrUndefinedOrEmptyString(cardNum)) {
                return ;
              } 
              if(cardNum == 'error') {
                await this.showLoginError(i18next.t('loginDialog.pinpadError'));
                return ;              
              } 
              this.taz = parseInt(cardNum.substr(4, 9));
              this.magnetic_card = parseInt(cardNum.substr(13, 8));
              
              app.showLoadingMessage(i18next.t("loginDialog.loggingIn"));
              let foundEmployeeInStore = await this.isEmployeeInStore();
              app.hideLoadingMessage();
  
              if(foundEmployeeInStore) {              
                openPasswordDialog();
              } else {
                await this.showLoginError(i18next.t('loginDialog.noPermission'))
                this.taz = null;
                this.magnetic_card = null;
                Pinia.globalStore.isSlowConnection(false);
              }
            },
            logout() {
              session.pos.employeeID = null;
              session.pos.employeeName = null;
              Pinia.globalStore.cashierLogout();
              this.sessionEmployeeId = null;
              $('.header-cashier').text("");
              session.pos.persist();
              this.forceLogin = true;
              open(false, true);
            },
            sendLoginDetailsToCashierStatement(employeeID, employeeName) {
              emitter.emit('user-logged', {id: employeeID, name: employeeName});
            },
            updateCatalog() {
              Pinia.globalStore.setAvoidNextLogin(false);
              statsVC.reloadAllData(false);
            },
            async isEmployeeInStore() {
              if(this.stateIsOnline) {
                let employeeFound = await PositiveTS.Service.CaveretEmployeeApproval.isEmployeeInStore(this.taz, this.magnetic_card)
                if(employeeFound !== null) {
                  return employeeFound;
                }
              } 
              
              if(!this.stateIsOnline || this.slowConnection){
                if(this.employeesSavedPasswords) {
                  let matchingEmployees = this.employeesSavedPasswords.filter(emp => { return emp.taz == this.taz });
                  return matchingEmployees.length > 0;
                }
              }
              return false;
            },
            async verifyPasswordAndGetUser() {
              let response;
  
              if(this.stateIsOnline && !this.slowConnection) {
                response = await PositiveTS.Service.CaveretEmployeeApproval.verifyPassword(this.taz, this.magnetic_card, this.password)
                if(response) {
                  return { 
                    employeeID: response.employee.id,
                    name: response.employee.name,
                    isManagerDiscountConfirm: response.employee.isManager,
                    changePass: response.success == "change"
                  }
                }
              }
  
              if(!this.stateIsOnline || posUtils.isBlank(response)) {
                if(this.employeesSavedPasswords) {
                  let localEmployee = this.employeesSavedPasswords.find(emp => { return emp.taz == this.taz && emp.password == Service.Sha256.sha256.hash(this.password) });
                  if(localEmployee) {
                    return  await Storage.Entity.Employee.fetchByTaz(this.taz);
                  }
                }
              }
  
              return false;
            }, 
            async showLoginError(errorText) {
              this.numOfAttempts++;
              await app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: errorText,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
                if(this.numOfAttempts > 4) {
                  app.showLoadingMessage(i18next.t('loginDialog.blocked'));
                  setTimeout(function() { app.hideLoadingMessage() }, 30000);
                }
            },
            saveEmployeePassword(id: number, password: string, cardNum) {
              if(!localStorage.hasOwnProperty("employeesSavedPasswords")) {
                let employees = new Array({ taz: id, password: Service.Sha256.sha256.hash(password), cardNum: cardNum});
                localStorage.setItem("employeesSavedPasswords", JSON.stringify(employees)); 
                return ;
              }
  
              let employees = _.cloneDeep(this.employeesSavedPasswords)
              let currentEmployeeIndex = employees.findIndex(emp => { return emp.taz == id });
              if(currentEmployeeIndex > -1) {
                employees[currentEmployeeIndex].password = Service.Sha256.sha256.hash(password);
                employees[currentEmployeeIndex].cardNum = cardNum;
              } else {
                employees.push({taz: id, password: Service.Sha256.sha256.hash(password), cardNum: cardNum});
              }
              localStorage.setItem("employeesSavedPasswords", JSON.stringify(employees));
            }
          },
          data: _initData,
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {
              slowConnection,
              isOnline,
              passwordProtected,
              avoidNexLogin
            } = Pinia.storeToRefs(globalStore);

            const globalStoreProps = {
              slowConnection,
              stateIsOnline:isOnline,
              statePasswordProtected:passwordProtected,
              avoidNextLogin:avoidNexLogin
            } 

            return {...globalStoreProps}
          },
          computed:  {
          employeesSavedPasswords() {
            return JSON.parse(localStorage.getItem("employeesSavedPasswords")) || null;
          }
        },
          mounted: function() {
            this.$nextTick(function () {
              if(session.pos.passwordProtected) {
                open(false, true);
              }
            });
          }
      }
      
      VueApp.component(_vueComponentName, loginDialog)
    }
  
    function open(sendLoginToCashierStatement = false, forceLogin = false, requireManager = false, managerApprovePromise = null){
      this.sendLoginToCashierStatement = sendLoginToCashierStatement;
      this.forceLogin = forceLogin;
      this.managerApprovePromise = managerApprovePromise;
      this.requireManager = requireManager;
      this.isModalOpen = true;
      let dialogHeight = 265
      let isSelfServiceSuperMarket = Pinia.globalStore.selfServiceSuperMarket

      if(forceLogin && !isSelfServiceSuperMarket){
        dialogHeight = 150
      }
  
      //if this login should be avoided and its not a manager approval or cashier statement, don't show it.
      if(this.avoidNextLogin && !sendLoginToCashierStatement && !requireManager && managerApprovePromise == null) {
        Pinia.globalStore.setAvoidNextLogin(false); 
        return ;
      }
      $(function() {        
        $(_dialogSelector).dialog({
          autoOpen: true,
          modal: true,
          dialogClass: 'positive-dialog',
          width: '60%',
          height: dialogHeight ,
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });
        $(_dialogSelector).dialog('open');
      });
    }
  
    function openPasswordDialog() {
      this.isModalOpen = true;
      let isselfServiceSuperMarket =Pinia.globalStore.selfServiceSuperMarket

      $(function() {        
        $(_passwordDialogSelector).dialog({
          autoOpen: true,
          modal: true,
          dialogClass: 'positive-dialog',
          width: '60%',
          height: isselfServiceSuperMarket ? 210 : 150,
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });
      });
      $(_passwordDialogSelector).dialog('open');
      $(document).unbind('keypress');
    }
  
    function _initData(){
      return {
        manualLogin: false,
        numOfAttempts: 0,
        isLocked: false,
        sendLoginToCashierStatement: false,
        sessionEmployeeId: session.pos.employeeID,
        forceLogin: true,
        password: "",
        taz: null,
        manual_password: null,
        magnetic_card: null,
        managerApprovePromise: null,
        requireManager: false,
        isModalOpen: false,
      };
    }
  
    function close() {
      if(this.managerApprovePromise != null) {
        this.managerApprovePromise.reject();
      }
      Pinia.globalStore.isSlowConnection(false);
      $(_dialogSelector).dialog('close');
      this.isModalOpen = false;
    }
  
    function closePasswordDialog() {
      this.password = "";
      Pinia.globalStore.isSlowConnection(false);
      $(_passwordDialogSelector).dialog('close');
      this.isModalOpen = false;
      document.getElementById('pos-search-field').focus()
    }
  }}}
