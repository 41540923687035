module PositiveTS {
  export module Service {
    export module AutoCloseEodTimeReport {
      const url = '/EmployeeTimeTracks/emp_today_with_no_exit';

      export async function getReport() {
        try{
          app.showLoadingMessage(i18next.t('zReport.checkForActiveEmployees'));
          let listOfEmployees = await fetchTodayEmpNoExit();
          app.hideLoadingMessage();
          if (listOfEmployees.length > 0) {
            let isAutoEmployeeSignOutRemoteZ = session.pos.hasRemoteZ && session.pos.isAutoEmployeeSignOutRemoteZ;
            let selectedEmp = isAutoEmployeeSignOutRemoteZ ? listOfEmployees : await selectNoExitEmployees(listOfEmployees)
            let empTimeStamps = [];
            for (let i = 0; i < selectedEmp.length; i++) {
              empTimeStamps.push(
                PositiveTS.Service.EmployeeTimeTrack.EmployeeTimeTrackEntry.magneticCardEntry(
                  PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT,
                  selectedEmp[i].magnetic_card
                )
              )
            }
            return Promise.all(empTimeStamps);
          }
        }catch(err){
          errorHandler(err);
        }
      }

      async function fetchTodayEmpNoExit() {
        try{
          let res = await FetchReq.jsonReq(url, 'get');
          if(!res.response.ok){
            console.error("error in " + url);
            return [];
          }
          return res.result;
        }catch(err){
          Logger.error(err)
          return [];
        }
      }

      async function selectNoExitEmployees(listOfEmployees){
        try{
          let selectedEmp = await empNoExitDg.open(listOfEmployees)
          return selectedEmp;
        }catch(err){
          errorHandler(err)
        }
      }

      async function errorHandler(err){
        app.hideLoadingMessage();
        if (err.message == app.userCancelledGlobalMessage) {
            throw new Error(app.userCancelledGlobalMessage); 
        }
        Logger.error(err.message)
      }
    }
  }
}
