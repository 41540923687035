module PositiveTS { 
    export module Printing {

        export async function printHtmlOpenFormat(response){
            await _printHtmlOpenFormat54(response);
            await _printHtmlOpenFormat26(response);
        }

        async function _printHtmlOpenFormat54 (response) {
            await HtmlFactory.popupPrintWithPromise(
                _.template(JST.htmlOpenFormat54())({
                    companyRegNum: session.store.registrationNum,
                    companyName: session.pos.companyName,
                    pathLocal: response.pathLocal,
                    fromDate: moment(response.fromDate ).format("DD/MM/YYYY"),
                    toDate: moment(response.toDate ).format("DD/MM/YYYY"),
                    nowDate: moment().format("DD/MM/YYYY"),
                    nowTime: moment().format("HH:mm"),
                    report54: response.response.result.html_report_54,
                })
            );
        }

        async function _printHtmlOpenFormat26 (response) {
            await HtmlFactory.popupPrintWithPromise(
                _.template(JST.htmlOpenFormat26())({
                    companyRegNum: session.store.registrationNum,
                    companyName: session.pos.companyName,
                    pathLocal: response.pathLocal,
                    fromDate: moment(response.fromDate ).format("DD/MM/YYYY"),
                    toDate: moment(response.toDate ).format("DD/MM/YYYY"),
                    nowDate: moment().format("DD/MM/YYYY"),
                    nowTime: moment().format("HH:mm"),
                    report26: response.response.result.html_report_26,
                })
            );
        }
    }
}
