module PositiveTS {
    export module Storage {
        export module Entity {
            export class VendingMachineItem extends WasmEntity {

                companyId: number
                storeId: number
                posId: number
                posDeviceId: string
                vendingMachineId: number
                itemId: number
                itemCode: string
                belt: number
                tray: number
                railsId: number

                constructor() {
                    let meta = {
                        name: 'VendingMachineItem',
                        fields: {
                            companyId: "INT",
                            storeId: "INT",
                            posId: "INT",
                            posDeviceId: "TEXT",
                            vendingMachineId: "INT",
                            itemId: "INT",
                            itemCode: "TEXT",
                            belt: "INT",
                            tray: "INT",
                            railsId: "INT"
                        },
                        unique: []
                    }
                    super(meta)
                }


            }
        }
    }
}
