module PositiveTS {
  export module Application {
  export module Controllers {
    export class PagesViewController extends AbstractViewControllerTS {
      resume(options?) {
        if (options == null) {
          console.warn('no route passed!')
          return;
        }
        VueServices.Router.goto(options.route,options, options?.extraData || null);
      }

      stop() {
        VueServices.Router.goto('/');
      }
    }}
  }
}
PositiveTS.Application.Controllers.instances.PagesViewController = new PositiveTS.Application.Controllers.PagesViewController();