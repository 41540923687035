module PositiveTS {
export module Service {
export class PosPayment {

	/** The total amount to pay */
	totalAmount = 0
	/** The paid amount */
	paidAmount = 0
	/** The amount left to pay */
	leftAmount = 0
	/** The current amount that was insert by the user*/
	currAmount = 0
	/** The current payment method (an object with 'method' set to the payment method from PositiveTS.Storage.Entity.SalePayment.METHOD_*, and 'id' set to the payment method section ID) */
	currentPaymentMethod:any = {}
	/** Should be set to true when this controller is launched from pos VC */
	fromPos = false
	/** A list of payment methods and their section IDs in the HTML code */
	paymentMethodsSectionIDs:any = {}

	inTheMiddleOfPayment = false

	moduleMultiCurrencyCash:any
	moduleMultiCurrencyCredit:any
	PaymentCreditSelectedCurrency:any
	PaymentSelectedCurrency:any

	Currencies:any
	LastUpdateCurrencies:any

	init() {
		var aThis = posPaymentVC;

		// Populate the section IDs for payment methods
		aThis.paymentMethodsSectionIDs[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT] 		= '#pos-payment-credit';
		aThis.paymentMethodsSectionIDs[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH] 		= '#pos-payment-cash';
		aThis.paymentMethodsSectionIDs[PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER]		= '#pos-payment-voucher';
		aThis.paymentMethodsSectionIDs[PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT]		= '#pos-payment-voucher';
		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCreditVoucher))) {
		aThis.paymentMethodsSectionIDs[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER] 	= '#pos-payment-promotion';
		}
		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCheck))) {
		aThis.paymentMethodsSectionIDs[PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK] 		= '#pos-payment-check';
		}

			}


	open() {
		let aThis = posPaymentVC;

		if (posVC.sale === null) {
			throw new Error('sale is empty');
		}

		Pinia.globalStore.setPosState("payment")
		posVC.stop();

		aThis.init();

		//TODO (SR): We probably don't need this logic anymore
		// Determine whether or not to show the close sale dialog based on the controller that called this VC
		var dontShowCloseSaleDialog = true;
		if (aThis.fromPos) {
			dontShowCloseSaleDialog = false;
		}
		aThis.fromPos = false;

		$(document.body).unbind('keyup');


		aThis.updateAmountsIndicators(dontShowCloseSaleDialog);

		if (Service.CustomerDisplay.isSunmiScreen()) {
			Service.CustomerDisplay.sendToScreenShowTotalToPay(true)
		}

		if (session.pos.hasFlights && Storage.Entity.Discount.isCrewDiscountSale(posVC.saleItems)) {

			PositiveTS.Service.DedicatedTo.setDedicatedTo(i18next.t('flightsCrewDiscountEmployee.title'),
				i18next.t('flightsCrewDiscountEmployee.body'),
				i18next.t('flightsCrewDiscountEmployee.placeholder'),
				false, false)
				.catch(err => {
					this.close();
				});
		}
		PositiveTS.VueInstance.$refs.posPaymentDialog.open();
	}
	close() {
		var aThis = posPaymentVC;

		// Unbind the listeners from the resume function
		$(document.body).unbind('keyup');
		$('.pos-payment-cash-money-img').unbind('click');

		// Remove observers
		PositiveTS.NotificationCenter.removeObserver(aThis.updateAmountsIndicators);

		//simulate the dispacher sequence (maybe just call pushpage???)
		Pinia.globalStore.setPosState("pos")
		posVC.stop();
		posVC.init();
		posVC.resume();

		if (Service.CustomerDisplay.isSunmiScreen()){
			Service.CustomerDisplay.sendToScreenShowTotalToPay(false)
		}
	}
	openCloseSaleDialog (leftAmount) {
		var aThis = posPaymentVC;
		const isMultiCurr = PositiveTS.Service.MultiCurr.getInstance().isMultiCurr();

		if(isMultiCurr && jsonConfig.getVal(jsonConfig.KEYS.chooseChangeCurrency) && leftAmount != 0) { 
			// isMultiCurr and chooseChangeCurrency is true with change
			Pinia.componentsStore.openComponent(
			{componentName:"chooseChangeCurrencyDialog", args: []}).then((res) => {
				if(res.success){
					posVC.createChangeInCashPaymentMultiCurrency(res.paidCurrencies);
					closeSaleDg.open();
					closeSaleDg.continue();
				}else{
					PositiveTS.Components.Cash.clearCashAmount();
				}
			})
		}else{
			if (aThis.totalAmount > 0 && Boolean(jsonConfig.getVal(jsonConfig.KEYS.alwaysShowCloseSaleDialog)) && !session.pos.hasFlights) {
				CloseSaleDialogUI.dialog('open'); //fuck the dispacher! we open a normal dialog like human beings - yay!!! :-)
			} else if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.alwaysShowCloseSaleDialog)) 
			&& session.pos.hasFlights &&  isMultiCurr && leftAmount != 0) {
				Pinia.componentsStore.openComponent(
				{componentName:"multiCurrencyChangeCloseSaleDialog", args: []});
			} else {
				closeSaleDg.open();
				closeSaleDg.continue();
			}
		}
	}

	getOrCreatePayment(paymentMethod) {
		// Find a sale payment entity with the same method
		var salePayment = null;
		let salePayments = posVC.salePayments;
		for (var i = 0; i < salePayments.length; i++) {
			// Get the payment
			var aSalePayment = salePayments[i];

			// Check that the sale payment has the current method
			if (aSalePayment.method == paymentMethod) {
				salePayment = aSalePayment;
				break;
			}
		}

		// If no sale payment was found for the current payment method, create new one
		if (salePayment == null) {
			salePayment = new PositiveTS.Storage.Entity.SalePayment();
			salePayment.saleID = posVC.sale.id;
			salePayment.method = paymentMethod;
		}

		return salePayment;
	}

	// --------------------------------------------------------------------
	// Total, Paid and Left Amounts
	// --------------------------------------------------------------------
	async updateAmountsIndicators (dontShowCloseSaleDialog = false, round?) { //TODO: all this shit should move to VueX
		var aThis = posPaymentVC;
		// Hide all badges

		$('.pos-payment-header-badge').css('visibility', 'hidden');

		// Define the paid amount variable
		var paidAmount = 0;
		let additionalForPayAndPaidAmountForView = 0;

		var voucherAmount = 0;

		// Go over all the sale payments and update the badges
		for (var i = 0; i < posVC.salePayments.length; i++) {
			// Get the payment
			var salePayment = posVC.salePayments[i];

			var saleMathod = salePayment.method;

			// Ignore change
			if (saleMathod == PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE) {
				continue;
			}

			// Add this payment to the paid amount
			paidAmount = paidAmount + salePayment.amount;


			if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
				saleMathod = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
				voucherAmount += salePayment.amount;
			}

			if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
				voucherAmount += salePayment.amount;
			}

			// Update the payment badge
			var paymentBadgeID = null;
			if (!(saleMathod in aThis.paymentMethodsSectionIDs)) {
				// Unknown payment type, exit!
				console.error('Unknown payment method ' + salePayment.method);
				return;
			}
			paymentBadgeID = aThis.paymentMethodsSectionIDs[saleMathod] + '-header > .pos-payment-header-badge';
				
			let paymentAmount = salePayment.amount;
			// if its caveret and it has a goodi voucher, don't add it to the amount
			if(session.pos.isCaveret) {
				if(salePayment.method == Storage.Entity.SalePayment.METHOD_VOUCHER) {
					let vouchersObjs = JSON.parse(salePayment.data);
					vouchersObjs.forEach(voucher => {
						if(voucher.smartVoucherType == Storage.Entity.Voucher.SMART_VOUCHER_GOODI) {
							paymentAmount -= voucher.amount
						}
					});
				}
			}
			if(saleMathod == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT){
				let creditCardSales = JSON.parse(salePayment.data);
				creditCardSales.forEach(creditPayment => {
					if(creditPayment.isGateway){
						paymentAmount -=(creditPayment["Amount"] /100);
					}
				})
			}

			if(salePayment.method == Storage.Entity.SalePayment.METHOD_VOUCHER) {
				let vouchersObjs = JSON.parse(salePayment.data);
				const reductionVouchers = [
					Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT,
					Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT,
				]

				vouchersObjs.forEach(voucher => {
					if(reductionVouchers.includes(voucher.smartVoucherType)) {
						paymentAmount -= voucher.amount
					}
				});
			}

			if(salePayment.method == Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
				let vouchersObjs = JSON.parse(salePayment.data);
				vouchersObjs.forEach(voucher => {
					if(String(voucher.voucher_type_id) == String(CustomerClubConstants.VOUCHER_TYPE_ID)) {
						paymentAmount += voucher.amount
						additionalForPayAndPaidAmountForView += voucher.amount
					}
					if(String(voucher.voucher_type_id) == String(SimplyClubService.VOUCHER_TYPE_ID)) {
						paymentAmount += voucher.amount
						additionalForPayAndPaidAmountForView += voucher.amount
					}
				});
			}

			// If the amount for this payment method is greater than zero, display its badge
			if (paymentAmount > 0) {	
				let badgeText = session.fixedNumber(paymentAmount);

				if (session.pos.hasFlights) {
					badgeText = badgeText + ' ' + MultiCurr.getInstance().getPosCurrencySign();
				}

				$(paymentBadgeID).text(badgeText);
				$(paymentBadgeID).css('visibility', 'visible');
			}

		}

		aThis.updateTenbisCibus();

		// Get the total amount to pay
		var totalAmount = posVC.getTotalAmount();

		// Fix bug of negative payment amount
		// if negative payment ammount found, delete the
		// PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER cousing it
		if ( paidAmount < 0 && totalAmount > 0 ) {
			for (var i = 0; i < posVC.salePayments.length; i++) {
				if ( posVC.salePayments[i].method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER ) {
					paidAmount = paidAmount - posVC.salePayments[i].amount;
					posVC.deleteSalePayment( posVC.salePayments[i] );
					await posVC.saleUpdated();
				}
			}
		}

		// Calculate the amount left to pay
		if(round) {
				paidAmount += round;
		}
		
		var leftAmount = totalAmount - paidAmount;
		leftAmount = session.fixedFloat(leftAmount);

		paidAmount = session.fixedFloat(paidAmount);

		var stotalAmount = (session.fixedFloat(totalAmount + additionalForPayAndPaidAmountForView)).toString();
		var spaidAmount = (session.fixedFloat(paidAmount + additionalForPayAndPaidAmountForView)).toString();

		// Display the 3 amounts
		$('#pos-payment-math-total').text(stotalAmount);
		$('#pos-payment-math-paid').text(spaidAmount);
		PositiveTS.VueInstance.$refs.posPaymentDialog.paymentLeft = leftAmount;

		if (aThis.totalAmount == totalAmount && aThis.paidAmount == paidAmount && aThis.leftAmount == leftAmount) {
			dontShowCloseSaleDialog = true;
		}

		// And save them locally
		aThis.totalAmount = totalAmount;
		aThis.paidAmount  = paidAmount;
		aThis.leftAmount  = leftAmount;

		//And values to store 
		Pinia.globalStore.setLeftAmount(leftAmount);

		if (aThis.leftAmount <= 0) {
			dontShowCloseSaleDialog = false;
		}

		if (session.pos.hasFlights) {
			let totalPayments = Storage.Entity.SalePayment.sumPaymentsData(posVC.salePayments);
			if (Math.abs(aThis.totalAmount - totalPayments) < 0.01) {
				aThis.leftAmount = 0;
				if (dontShowCloseSaleDialog !== true) {
					aThis.openCloseSaleDialog(aThis.leftAmount);
				}
				return;
			}
		}

		if (Pinia.globalStore.openPaymentsForTip) {
			Pinia.globalStore.setOpenPaymentsForTip(false)
			return;
		}

		// If there is no money left to pay, close sale
		if (aThis.leftAmount <= 0 && dontShowCloseSaleDialog !== true) {
			aThis.openCloseSaleDialog(aThis.leftAmount);
		}
	}

	updateTenbisCibus() {
		//very very ugly workaround vuejs bug detaching from dom elemnts
		if (Pinia.globalStore.isTenbisOrCibus) {
			let cibusID = '#pos-payment-cibus-header .pos-payment-header-badge-show';
			let tenbisID = '#pos-payment-tenbis-header .pos-payment-header-badge-show';
			let goodiID = '#pos-payment-goodi-header .pos-payment-header-badge-show';
			let banxID = '#pos-payment-banx-header .pos-payment-header-badge-show';
			let safeCashID = '#pos-payment-safecash-header .pos-payment-header-badge-show';
			let yaadID = '#pos-payment-yaad-header .pos-payment-header-badge-show';
			let interServiceID = '#pos-payment-interService-header .pos-payment-header-badge-show'

			let cibusTotal = Pinia.globalStore.cibusTenbis.cibusTotalAmount;
			let tenbisTotal = Pinia.globalStore.cibusTenbis.tenbisTotalAmount;
			let goodiTotal = Pinia.globalStore.cibusTenbis.goodiTotalAmount;
			let banxTotal = Pinia.globalStore.cibusTenbis.banxTotalAmount;
			let safeCashTotal = Pinia.globalStore.cibusTenbis.safeCashTotalAmount;
			let yaadTotal = Pinia.globalStore.cibusTenbis.yaadTotalAmount;
			let interServiceTotal = Pinia.globalStore.cibusTenbis.interServiceTotalAmount;

			if (tenbisTotal > 0) {
				$(tenbisID).text(session.fixedNumber(tenbisTotal));
				$(tenbisID).css('visibility', 'visible');
			}
			else {
				$(tenbisID).css('visibility', 'hidden');	
			}
			if (cibusTotal > 0) {
				$(cibusID).text(session.fixedNumber(cibusTotal));
				$(cibusID).css('visibility', 'visible');
			}
			else {
				$(cibusID).css('visibility', 'hidden');	
			}
			if (goodiTotal > 0) {
				$(goodiID).text(session.fixedNumber(goodiTotal));
				$(goodiID).css('visibility', 'visible');
			}
			else {
				$(goodiID).css('visibility', 'hidden');	
			}

			if (banxTotal > 0) {
				$(banxID).text(session.fixedNumber(banxTotal));
				$(banxID).css('visibility', 'visible');
			}
			else {
				$(banxID).css('visibility', 'hidden');	
			}

			if (safeCashTotal > 0) {
				$(safeCashID).text(session.fixedNumber(safeCashTotal));
				$(safeCashID).css('visibility', 'visible');
			}
			else {
				$(safeCashID).css('visibility', 'hidden');	
			}

			if (yaadTotal > 0) {
				$(yaadID).text(session.fixedNumber(yaadTotal));
				$(yaadID).css('visibility', 'visible');
			}
			else {
				$(yaadID).css('visibility', 'hidden');	
			}

			if (interServiceTotal > 0) {
				$(interServiceID).text(session.fixedNumber(interServiceTotal));
				$(interServiceID).css('visibility', 'visible');
			}
			else {
				$(interServiceID).css('visibility', 'hidden');	
			}
			
		}
	}
	
}}}

//Shortend for POS view controller
declare var posPaymentVC:PositiveTS.Service.PosPayment;
posPaymentVC = new PositiveTS.Service.PosPayment();
