module PositiveTS {
  export module Components {
    export module DummyComponent {
      export function getComponent() {
        return {
          template: JST.dummyComp(),
          data() {
            return { }
          }
        }
      }
    }
  }
}