module PositiveTS {
export module Storage {
export module Entity {
export class Specialitem extends WasmEntity {

	constructor() {
		let meta = {
			name: 'Specialitem',
			fields: {
				settingsID: "TEXT",
				tenant_id: "INT",
				company_id: "INT",
				code: "TEXT" ,
				hndlr: "TEXT",
				discountAllowed: "BOOL",
			},
			unique: ['settingsID']
		}
		super(meta)
	}
}}}}
