module PositiveTS {
    export module Service {
        export module MishlohaConvertService {
            const _MishlohaServiceLogger ="mishloha orders"

            const deliveryTypes = {
                0: "Delivery",
                1: "TakeAway"
            }

            const PAYMENT_TYPE = {
                CASH: 0,
                REGULAR: 9,
                DIRECT_CREDIT_CARD: 10,
                TENBIS: 3,
                CIBUS: 2
            }

            function isDelivery(deliveryMethod: string): boolean {
                return deliveryMethod === "Delivery"
            }

            export async function checkMishlohaCustomer(): Promise<boolean> {
                let mishlohaCustomer = await Service.Hakafa.getHakafaCustomerByCustomerNumber(Service.MishlohaService.customer_number);
                if (!mishlohaCustomer) {
                    
                    PositiveTS.Service.Filelog.log("mishloha orders", JSON.stringify({ request: "No mishloha hakafa customer found:", result: "" }));
                    
                    return false;
                }

                return true
            }

            export async function convertOrderToSale(order): Promise<FullSale> {
                let orderNumber = order.shareToken,
                    deliveryMethod = deliveryTypes[order.deliveryType],
                    deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalMishloha,
                    deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;

                let orderMishlohaID = null;

                let error ="";

                if (!posUtils.isBlank(orderNumber)) {
                    let splitted = orderNumber.split('/'); // order.shareToken format: 'mishlohaOrderId/CustomerOrderId'

                    if (splitted.length == 2) {
                        orderNumber = splitted[1];
                        orderMishlohaID = splitted[0];
                    }
                } 

                if (deliveryMethod == "TakeAway") {
                    deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalMishlohaTA;
                    deliveryStatus = PositiveTS.Storage.Entity.Sale.TA_PAID;
                }

                let mishlohaCustomer = await Service.Hakafa.getHakafaCustomerByCustomerNumber(Service.MishlohaService.customer_number);
                if (!mishlohaCustomer){
                    let error = "Mishloha Hakafa customer missing";
                    Delivery.failedDeliveriesLogger(JSON.stringify({ request: error, result: "" }),orderNumber,_MishlohaServiceLogger,Delivery.FailedDeliveryType.HakafaCustomer,orderMishlohaID);
                    throw new Error(error);
                }

                let isDeliveryOrder = isDelivery(deliveryMethod)

                let sale = await Sale.getNewOrder(orderNumber, order.TotalSum);
                sale.payments = []

                let isPickup = false;
                if(posUtils.isBlank(order.payments) || order.payments.length == 0){
                    error = "There are no payments on the order "
                    PositiveTS.Service.Delivery.failedDeliveriesLogger(error,orderNumber,_MishlohaServiceLogger,Delivery.FailedDeliveryType.MissingPayments,false,orderMishlohaID);
                }
                if(posUtils.isBlank(order.items) || order.items.length == 0){
                    error = "There are no items on the order "
                    PositiveTS.Service.Delivery.failedDeliveriesLogger(error,orderNumber,_MishlohaServiceLogger,Delivery.FailedDeliveryType.MissingItems,false,orderMishlohaID);
                }
                if(error != ""){
                    return;
                }


                order.payments?.forEach(pay => 
                    {
                        let isPaymentAdded = addPaymentToSale(pay, sale, deliveryMethod == "TakeAway")

                        if (!isPaymentAdded) {
                            isPickup = true;
                            deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
                        }
                    });
                

                let jsondata = JSON.parse(sale.jsondata)
                jsondata["promotions"] = [];
                jsondata["delivery"] = {
                    deliveryType: deliveryTypeStr,
                    status: deliveryStatus,
                    isPickup: isPickup,
                    ordererName: `${order.contact.firstName} ${order.contact.lastName}`,
                    ordererPhone: order.contact.phone,
                    ordererCallerRemarks: order.comment,
                    ordererDeliveryRemarks: isDeliveryOrder && order.address ? order.address.comment : null,
                    ordererDeliveryCutlery: isDeliveryOrder ? order.takeoutSets : "",
                    orderHasBeenPaid: !isPickup && _.isEmpty(order.payments?.find(op=> op.type === PAYMENT_TYPE.CASH)),
                    "deliveryAddress": {
                        "address": {
                            "name": isDeliveryOrder ? order.address.street : "",
                            "value": 0,
                            "cityID": 0
                        },
                        "apartment": isDeliveryOrder ? order.address.apt : "",
                        "house_number": isDeliveryOrder ? order.address.number : "",
                        "house_entrance": isDeliveryOrder ? order.address.entrance : "",
                        "house_floor": isDeliveryOrder ? order.address.floor : "",
                        "city": {
                            "name": isDeliveryOrder ? order.address.city : "",
                            "value": 0,
                            "cityID": 0
                        },
                    },
                    "orderTime": new Date(order.created),
                    "MishlohaRestID": "",
                    "MishlohaRestName": "",
                    "address": "",
                    "mishlohaId": orderMishlohaID,
                };

                jsondata["customer"] = {
                    clubName: "HAKAFA",
                    s_id_number: mishlohaCustomer.tz,
                    s_first_name: mishlohaCustomer.first_name,
                    s_last_name: mishlohaCustomer.last_name,
                    dt_birth_date: null,
                    s_phone_number_1: mishlohaCustomer.phone,
                    customer_group_id: null,
                    amount: 0,
                    merakez: "",
                    db_id: mishlohaCustomer.id,
                    is_offline: false,
                    is_price_alut: false,
                    printer_type: 0,
                    discount_percent: 0,
                    customer_number: "לקוח משלוחה",
                    obligo: null,
                    address: null,
                    is_tamash_customer: false,
                    is_cibus_tenbis: true
                }

                sale.orderTime = String(new Date(order.created))
                sale.jsondata = JSON.stringify(jsondata);
                sale.deliveryStatus = deliveryStatus;

                let orderItems = order.items
                let items = [], totalQuantity = 0;

                orderItems.forEach(function (orderItem, index) {

                    let currentItem = session.allItems.get(String(orderItem.id))

                    if (!Boolean(currentItem)) {
                        currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                    }


                    let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });
                    saleItem.quantity = orderItem.count;
                    saleItem.barcode = saleItem.itemCode;
                    saleItem.saleID = sale.id;
                    saleItem.unitPrice = convertAgorotToShkalim(orderItem.price)
                    saleItem.originalUnitPrice = convertAgorotToShkalim(orderItem.price);
                    saleItem.hasPreparationInstructions = false;
                    saleItem.rowNumber = index + 1;
                    totalQuantity += orderItem.count;
                    saleItem.invoiceSequence = sale.invoiceSequence
                    saleItem.children = [];
                    saleItem.priceNetoAfterDiscounts = convertAgorotToShkalim(orderItem.price)

                    if (currentItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)) {
                        saleItem.itemDescription = String(orderItem.desc).trim();
                    }
                    
                    if (!posUtils.isNullOrUndefinedOrEmptyString(orderItem.comment)) {
                        saleItem.hasPreparationInstructions = true;
                        let comments = String(orderItem.comment).replace(/\r\n|\n|\r/g, '\r').split('\r').filter(prepIns => !posUtils.isBlank(prepIns))
                        saleItem.selectedPreparationInstructions = JSON.stringify(comments);
                    }

                    items.push(saleItem);

                    orderItem?.variations?.forEach((choice) => {

                        choice.items.forEach((varItem, varIndex) => {

                            let addItem = session.allItems.get(String(varItem.id));

                            if (!Boolean(addItem)) {
                                addItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                            }

                            let add = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(addItem, { size: 'null', color: 'null', barcode: null });
                            add.rowNumber = 20001 + varIndex;
                            add.unitPrice = convertAgorotToShkalim(varItem.price);
                            add.quantity = 1;
                            add.originalUnitPrice = convertAgorotToShkalim(varItem.price);
                            add.barcode = add.itemCode;
                            add.priceNetoAfterDiscounts = convertAgorotToShkalim(varItem.price * varItem.count);
                            add.level = 1;

                            if (addItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)) {
                                add.itemDescription = String(varItem.desc).trim();
                            }

                            add.parentItemId = items[index].item.id;
                            add.parentRowNumber = 1;
                            add.itemGroupId = -1;
                            add.parent = items[index];

                            items[index].unitPrice += add.priceNetoAfterDiscounts
                            items[index].priceNetoAfterDiscounts += add.priceNetoAfterDiscounts * items[index].quantity
                            items[index].children.push(add);

                        })
                    })

                    if (orderItem.variations.length > 0) {
                        items[index].hasGroups = 1;
                    }
                })

                let saleDiscount = order.charges.find(c => { return c.type === 0 })

                if (saleDiscount) {
                    let discountItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                    discountItem.description = i18next.t("mishloha.discount")
                    discountItem.priceZarhan = -(convertAgorotToShkalim(saleDiscount.amount))

                    let discountItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(discountItem, { size: 'null', color: 'null', barcode: null });
                    discountItm.rowNumber = items.length + 1
                    discountItm.saleID = sale.id
                    discountItm.priceNetoAfterDiscounts =  discountItem.priceZarhan
                    items.push(discountItm);
                }

                let deliveryItem = new Storage.Entity.Item();

                switch (deliveryMethod) {
                    case "Delivery":
                        let deliveryCharge = order.charges.find(c => { return c.type === 1 })
                        let deliveryPrice = 0
                        if(deliveryCharge) {
                            deliveryPrice = convertAgorotToShkalim(deliveryCharge.amount)
                        }
                        deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));
                        deliveryItem.priceZarhan = deliveryPrice
                        break;

                    case "TakeAway":
                        deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode));
                        deliveryItem.priceZarhan = 0;
                        break;
                }

                let deliveryItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(deliveryItem, { size: 'null', color: 'null', barcode: null });
                deliveryItm.rowNumber = 0;
                deliveryItm.saleID = sale.id
                deliveryItm.priceNetoAfterDiscounts = deliveryItem.priceZarhan
                items.push(deliveryItm);

                sale.totalQuantity = String(items.length);

                return new FullSale(sale, items, sale.payments)
            }



            function convertAgorotToShkalim(amount) {
                return (amount / 100)
            }

            function addPaymentToSale(pay, sale: Storage.Entity.Sale, isTA = false) {
                let vouchers = [
                    PAYMENT_TYPE.CIBUS,
                    PAYMENT_TYPE.TENBIS,
                    PAYMENT_TYPE.DIRECT_CREDIT_CARD,
                    PAYMENT_TYPE.REGULAR
                ]
                
                if(vouchers.includes(pay.type)) {
                    let paymentIndex = sale.payments.findIndex((salePayment) => salePayment.method == 3)
                    if(paymentIndex == -1) {
                        sale.payments.push(new Storage.Entity.SalePayment());
                        paymentIndex = sale.payments.length - 1
                        sale.payments[paymentIndex].method = Storage.Entity.SalePayment.METHOD_VOUCHER
                        sale.payments[paymentIndex].saleID = sale.id
                    }

                    sale.payments[paymentIndex].amount += convertAgorotToShkalim(pay.amount);
                    let paymentData = JSON.parse(sale.payments[paymentIndex].data);                        
                    if(posUtils.isBlank(paymentData)) {
                        paymentData = [];
                    }

                    let voucherData =
                    {
                        amount: convertAgorotToShkalim(pay.amount),
                        barCode: "",
                        creditType: i18next.t("mishloha.paymentName"),
                        voucher_type_id: jsonConfig.getVal(jsonConfig.KEYS.mishlohaVoucherNumber),
                        voucherType: PositiveTS.Storage.Entity.Voucher.VOUCHER_MISHLOHA,
                        smartVoucherType: null,
                        valuTypeId: null,
                        praxellManpik: null,
                        mutipassInit: null,
                        isTamashCustomer: true,
                        isDebitInvoice: false
                    }

                    if(pay.type == PAYMENT_TYPE.DIRECT_CREDIT_CARD) {
                        voucherData.voucher_type_id = jsonConfig.getVal(jsonConfig.KEYS.mishlohaCreditCardVoucherNumber);
                        voucherData.creditType = i18next.t("mishloha.creditCardPaymentName");
                        voucherData.voucherType =  null;
                        voucherData.isDebitInvoice = true 

                    }
                
                    paymentData.push(voucherData);
                    sale.payments[paymentIndex].data = JSON.stringify(paymentData);
                    
                } else {
                    if (isTA) {
                        return false; // Pickup order
                    }

                    let cashPayment = new Storage.Entity.SalePayment();
                    cashPayment.method = Storage.Entity.SalePayment.METHOD_CASH;
                    cashPayment.amount = convertAgorotToShkalim(pay.amount);
                    cashPayment.saleID = sale.id;
                    cashPayment.data = JSON.stringify([{isDebitInvoice: true}]);
                    sale.payments.push(cashPayment);    
                }

                return true;
            }


            export async function checkSplitPaymentAndSplitIfNeeded(fullSale: FullSale): Promise<void> {
                let splitInfo = Service.CloseSale._getSplitSaleInfo(fullSale.sale, fullSale.saleItems, fullSale.salePayments)

                if (splitInfo.saleSplitRequired && splitInfo.tamashPct > 0) {
                    let regularSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)
                    let tamashSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_SHIPMENT_INV)
                    //regular inv is the main invoice
                    //create a new tamash invoice for the split
                    let tamashSale = await Service.ShipmentInv.createShipmentInvForSplit(fullSale, tamashSeq, regularSeq)
                    await Service.CloseSale.persistChanges(fullSale, tamashSale, tamashSeq)
                    await Printing.Invoice.printInvoice(tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, true)
                    if (jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)) {
                        await Printing.Invoice.printInvoice(tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, false)
                    }
                }

                return
            }

            export async function setSaleInvoiceTypeAndSequence(fullSale: FullSale): Promise<void> {
                let paymentData = fullSale.salePayments.find(sPayment => sPayment.amount !== 0)

                if(!posUtils.isBlank(paymentData)) {
                    paymentData = JSON.parse(paymentData.data)[0]
                } else {
                    paymentData = JSON.parse(fullSale.salePayments[0].data)[0]
                }

                if(paymentData.isDebitInvoice == true) {
                    fullSale.sale.invoiceType = Storage.Entity.Sequence.TYPE_DEBIT_INVOICE 
                } else {
                    fullSale.sale.invoiceType = Storage.Entity.Sequence.TYPE_SHIPMENT_INV
                }

                let sequence = await Storage.Entity.Sequence.getSequenceForInvType(fullSale.sale.invoiceType);
                sequence.sequence++;
                fullSale.sale.invoiceSequence = sequence.sequence;

                await appDB.sequences.put(sequence)
            }


            export function setSaleTotals(fullSale: FullSale): void {
                let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(fullSale.sale, fullSale.saleItems, fullSale.salePayments)
                let totalVatableAmount = 0
                let isEilatStore = session.store.containVat

                    for (const item of fullSale.saleItems) {
                        if(isEilatStore && !item.alwaysHasVat) {
                            continue
                        }

                        totalVatableAmount += item.unitPrice
    
                    }

                fullSale.sale.totalAmount = saleTotals.totalAmount
                fullSale.sale.totalVatableAmount = session.store.containVat ? 0 : totalVatableAmount
            }

        }
    }
}