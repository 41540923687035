module PositiveTS {
    export module Components {
        export module PositiveDatepicker {
            
            export function create() {
                const translations = PositiveTS.Components.PositiveDatepickerTranslations.translations

                const component = {
                    emits: ['update:modelValue'],
                    template: JST.positiveDatepicker(),
                    components: {
                        Datepicker: window.Datepicker,
                    },
                    props: {
                        disabled: {
                            type: Boolean,
                            default: false
                        },

                        format: {
                            type: String,
                            default: "dd/MM/yyyy"
                        },

                        placeholder: {
                            type: String,
                            default: null
                        },
                        
                        textInput: {
                            type: Boolean,
                            default: false
                        },

                        lang: {
                            type: String,
                            default: null
                        },

                        modelValue: {
                        },

                        startingView: {
                            type: String,//"time" | "day" | "month" | "year"
                            default: 'day'
                        },

                        minimumView: {
                            type: String, //"time" | "day" | "month" | "year"
                            default: 'day'
                        },
                    },
                    
                    computed: {
                        date: {
                            get (){
                                return this.modelValue
                            },
                            set(value) {
                                this.$emit('update:modelValue', value)
                            }
                        }
                    },

                    data () {
                        return {
                          locale: translations[this.lang || PositiveTS.Service.Localization.getLanguage()],
                          weekStartsOn: 0,
                        }
                    },
                }

                VueApp.component('positive-datepicker',component)
            }
        }
    }
}
