module PositiveTS {
  export module Components {
      export module addonFilesUpdateDialog {
        export function create() {
  
          let component = {
            template: JST.addonFilesUpdateDialog(),
            methods: {
              changeVersion(version){
                this.selectedVersion = version.index;
              },
              cancel() {  
                this.close();
              },
              
              confirmAndClose() {
                this.resolveFunc({"versionToUpdate":this.versions[this.selectedVersion].ver})
                this.close();
              },
              close() {
                this.$el.close();
              },
              open(versions: Array<any>,currentVersion:string) {
                return new Promise((resolve,reject) => {

                  this.versions= versions;
                  this.currentVersion = currentVersion;
                  this.selectedVersion = this.versions.findIndex(e => e.ver == currentVersion);
                  if(this.selectedVersion <0) this.selectedVersion =0;

                  this.$el.showModal();
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              },
            },
            data(){
              return{
                rejectFunc: null,
                resolveFunc: null,
                selectedVersion: 1,
                versions:[],
                currentVersion:null,
                resolveOnCancel: false,
              }
            },
            computed: {
              
              allVersions(){
                return this.versions;
              }
            }
          }
  
          VueApp.component('addon-files-update-dialog-component',component)
        }
      }
    }
  }
  