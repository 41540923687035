module PositiveTS {
   export module Service {
      export class AndroidService {
         static asyncActionPromises = {};

         static async makeActionAsync(action, ...params) {
            if (Android.runFunctionAsync == undefined) {
               throw new Error('Make async function not supported on this android version.');
            }

            let uid = storage.createUUID();

            let temp;

            let promise = new Promise((resolve, reject) => {
               temp = {resolve, reject}
            });

            this.asyncActionPromises[uid] = {promise, ...temp};

            Android.runFunctionAsync(action, uid, JSON.stringify(params))

            let res;

            try {
               res = await this.asyncActionPromises[uid].promise;
            } finally {
               delete this.asyncActionPromises[uid];
            }

            return res;
         }

         static async makeActionAsyncIfSupported(action, ...params) {
            if (Android.runFunctionAsync == undefined) {
               return Android[action](...params);
            } else {
               return await this.makeActionAsync(action, ...params)
            }
         }
         

         static handleResult(uid, success, data) {
            if (this.asyncActionPromises[uid]) {
               if (success) {
                  this.asyncActionPromises[uid].resolve(data);
               } else {
                  this.asyncActionPromises[uid].reject(data);
               }
           } else {
               console.error(`Android http request with id ${uid} not found. data: ${data}`); // If gets to here, its a bug that needed a fix
           }
         }
      }
   }
}