module PositiveTS {
export module Components {
    export module SuperFriendsCustomerClub {

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function convertToPosStructure(customer) {
        let  uglyCustObj = {}

        uglyCustObj["s_id_number"] = customer.customer_tz
        uglyCustObj["s_member_no"] = customer.customer_number
        uglyCustObj["customer_group_id"] = customer.customer_group_id
        uglyCustObj["customer_group_name"] = customer.customer_group_name
        uglyCustObj["s_first_name"] = customer.first_name
        uglyCustObj["s_last_name"] = customer.last_name
        uglyCustObj["email"] = customer.email
        uglyCustObj["msg_for_screen"] = "" //TODO
        uglyCustObj["msg_for_slip"] = "" //TODO
        uglyCustObj["cust_points"] = customer.current_points

        let pointsForUse = customer.current_points < jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse) ? 0 : customer.current_points //TODO - parameter at club level
        let multiplyPointsVal = jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication)
        if (pointsForUse > 0 && multiplyPointsVal != null && multiplyPointsVal>0) {
          pointsForUse = pointsForUse-pointsForUse%multiplyPointsVal;
        }
        uglyCustObj["cust_points_for_use"] = pointsForUse
        uglyCustObj["i_point_value"] = 1 //TODO - parameter at club level
        uglyCustObj["i_club_id"] = customer.customer_club_id
        uglyCustObj["dt_birth_date"] = customer.date_of_birth
        uglyCustObj["address"] = `${customer.street_address || ""} ${customer.apartment_number || ""} ${customer.city || ""}`
        uglyCustObj["s_phone_number_1"] = customer.phone_number
        uglyCustObj["db_id"] = customer.id
        uglyCustObj["zip_code"] = customer.zip_code
        uglyCustObj["val_per_points"] = uglyCustObj["i_point_value"]*uglyCustObj["cust_points_for_use"]
        uglyCustObj["clubName"] = 'positive'
        uglyCustObj["discount_percent"] = customer.discount_percent

        //TODO: get promotion types from server. maybe use a serializer?
        uglyCustObj["promotion_types"] = customer.promotion_types
        //uglyCustObj["promotion_types"] = CustomerClub.get_promotion_types(customer)

        uglyCustObj["block_from_mailing_list"] = customer.block_from_mailing_list
        
        return uglyCustObj;
      }

      function convertFromPosStructure(uglyCustObj) {
        this.customer = this.convertFromPosStructureToCustomerObject(uglyCustObj)
      }

      function convertFromPosStructureToCustomerObject (uglyCustObj){

        let customer :any = {}
        customer.customer_tz = uglyCustObj["s_id_number"]
        customer.customer_number = uglyCustObj["s_member_no"]
        customer.first_name = uglyCustObj["s_first_name"]
        customer.last_name = uglyCustObj["s_last_name"]
        customer.id = uglyCustObj["db_id"]
        customer.email = uglyCustObj["email"]
        customer.current_points = uglyCustObj["cust_points"]
        customer.customer_club_id = uglyCustObj["i_club_id"]
        customer.date_of_birth = uglyCustObj["dt_birth_date"]
        customer.phone_number = uglyCustObj["s_phone_number_1"]
        customer.msg_for_screen = uglyCustObj["msg_for_screen"]
        customer.cust_points_for_use = Number(uglyCustObj["cust_points_for_use"])
        customer.msg_for_slip = uglyCustObj["msg_for_slip"] || ""
        customer.class_code = uglyCustObj["class_code"] || ""
        customer.migvan = uglyCustObj["migvan"] || ""
        customer.expired = moment() > moment(new Date(uglyCustObj["valid_until"]))

        return customer

      }

      async function selectCustomer(customer, addItemByBarcode = '') {

        customer.dt_birth_date = customer.dt_birth_date.substr(6,2) + "/" +
          customer.dt_birth_date.substr(4,2) + "/" +
          customer.dt_birth_date.substr(0,4) 
        customer.current_points = customer.cust_points
        customer.is_valid = (moment(new Date(customer.valid_until)).diff(moment(new Date()),'days') >= 0);

        this.customer = customer

        let customerClubService = new Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
        await customerClubService.setCurrentSelectedCustomer(customer) //convertToPosStructure.call(this,customer)
        
        await posVC.loadSale()
          
        this.customerSelected = true

        if (addItemByBarcode){
          posVC.addItemByBarcode(addItemByBarcode)
        }
        
        this.close()
          
      }

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $("#super-friends-customer-club-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '95%',
              height: 670,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: '',
          searchInputByCellular: '',
          loading: false,
          customerSelected: false,
          showSearchResults: false,
          searchCustomerMode: true,
          noCustomersFound: false,
          addNewCustomerMode: false,
          showPositiveAddCustomer: !jsonConfig.getVal(jsonConfig.KEYS.hidePositiveAddCustomer),
          customer: {},
          customers: [],
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,

        }
      }

      function close() {
        $("#super-friends-customer-club-dialog").dialog('close');
        $(document).keypress(posVC.onKeyPress);
        this.cleanData()
      }

      function open() {
        let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
        let customer = customerClubService.getCurrentSelectedCustomer()
        if (customer) {
          convertFromPosStructure.call(this,customer)
          this.customerSelected = true
        }
        else {
          this.customerSelected = false;
          this.customer = {block_from_mailing_list: false}
        }

        this._initjQueryDialogIfNeeded()

        $(document).unbind('keypress');

        $("#super-friends-customer-club-dialog").dialog('open');
        if (this.$refs.searchInputText) {
          this.$refs.searchInputText.focus();
        }
        else if (this.$refs.taz) {
          this.$refs.taz.focus();
        }

      }

      function searchCustomer () {
        this.search('findCustomer', this.searchInput)
        this.searchInputByCellular = ''
      }

      function searchCustomerByCellular () {
        this.search('findCustomerByCellular', this.searchInputByCellular)
        this.searchInput = ''
      }

      function search (find_by, value) {

        value = value.trim()

        if (!value) {
          return;
        }
        this.noCustomersFound = false
        this.showSearchResults = false
        this.customers = []     
        this.loading = true
        app.showLoadingMessage(i18next.t("positiveCustClub.findingCustomer"))
        let data : {
          find_by: string,
          value: string
        } = {
          find_by,
          value
        }

        let queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

        Service.FetchReq.jsonReq(`/brilinterface?${queryString}`, 'get')
          .then((res) => {
            
            if (res.response.ok){

              let result = res.result
              if (result.is_request_valid && result.customers.length){
                this.showCustomers(result)
              }else{
                this.noCustomersFound = true
                if (find_by == 'findCustomer' && this.searchInput.substr(0,4) == '6767'){
                  app.showAlert({
                    header: 'הודעה',
                    content:  'לא נמצא לקוח האם להקים לקוח חדש?',
                    continueButtonText: i18next.t("ok")
                  },
                  () => {
                    this.searchCustomerMode = false
                    this.addNewCustomerMode = true
                    this.customer.customer_number = this.searchInput
                    this.searchInput = ''        
                  })
                }
                
              }
              this.loading = false
              app.hideLoadingMessage()

            }else{
              this.showGenericError()
              this.loading = false
              app.hideLoadingMessage()
            }
            
          }).catch(() => {
            this.showGenericError()
            this.loading = false
            app.hideLoadingMessage()
          })

      }

      function showCustomers(result:any) {
        this.customers = []
        let customers = result.customers
        customers.map((customer) => Object.assign(customer, this.convertFromPosStructureToCustomerObject(customer)))    
        this.customers = customers
        this.showSearchResults = true
      }

      function validate():Promise<Array<any>> {
        return new Promise((resolve,reject) => {
          let errors = []
          
          const hasEmptyTZ = !this.customer.customer_tz || this.customer.customer_tz.trim() === ""
          if (hasEmptyTZ) {
            errors.push({field:"taz", error:i18next.t("positiveCustClub.mustEnterTZ")})
          }
          if (!hasEmptyTZ && !posUtils.validateTz(this.customer.customer_tz)) {
            errors.push({field:"taz", error:i18next.t("positiveCustClub.TZIsNotValid")})
          }
          if (!this.customer.first_name || this.customer.first_name.trim() === "") {
            errors.push({field:"firstName", error:i18next.t("positiveCustClub.mustEnterFirstName")})
          }
          if (!this.customer.last_name || this.customer.last_name.trim() === "") {
            errors.push({field:"lastName", error:i18next.t("positiveCustClub.mustEnterLastName")})
          }
          if (!this.customer.phone_number || String(this.customer.phone_number || '') === "") {
            errors.push({field:"phoneNumber", error:i18next.t("positiveCustClub.mustEnterPhone")})
          }
          if (!this.customer.s_form_number || this.customer.s_form_number.trim() === "") {
            errors.push({field:"formNumber", error:i18next.t("superFriendsCustClub.mustEnterFormNumber")})
          }
          if(this.customer.discount_percent && isNaN(this.customer.discount_percent)) {
            errors.push({field:"discountPercent", error:i18next.t("positiveCustClub.discountPercentMustBeNumericOrEmpty")})
          }
          

          resolve(errors);

        })
      }

      function addNewCustomer() {
        let self = this;
        validate.call(this)
        .then(errors => {
          if (errors.length > 0) {
            let errStr = "";
            errors.forEach((el) => {errStr += `${el.error}<br>`})

            app.showAlert({
              header: i18next.t('error'),
              content: errStr,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            },
            () => {
              self.$refs[errors[0].field].focus();
            },null)

          }
          else {

            //call to server check if is ok request 
            this.loading = true
            app.showLoadingMessage(i18next.t("positiveCustClub.findingCustomer"));
            //session.pos.storeID
            let jsonData = `{"action":"findByIdAndPhone","id":"${this.customer.customer_tz}","phone":"${this.customer.phone_number}","store_id":"${810}","club_name":"bril"}`;
            Service.Ajax.promiseJqAjax('/brilinterface', {data: jsonData}, 'post')
              .then((res) => {
                let result = JSON.parse(res.request.result)
                if (result.is_request_valid && result.customers && result.customers.length){
                  app.showAlert({
                    header: 'הודעה',
                    content: `נמצא לקוח קיים ${result.customers[0].s_first_name + ' ' + result.customers[0].s_last_name}` +
                    '<br>האם להוסיף למכירה?',
                    continueButtonText: i18next.t("ok"),
                  },
                  () => {
                    this.selectCustomerAndCheckIfNewCustomer(result.customers[0])
                  }) 
                  this.loading = false
                  app.hideLoadingMessage()
                  return 
                }
                 
                if (result.i_return_code == 4){

                  self.customer.dt_birth_date = moment(self.customer.date_of_birth, 'DD/MM/YYYY').format( "YYYYMMDD")

                  self.customer.clubName = "bril"          
                  self.customer.s_first_name = self.customer.first_name
                  self.customer.s_last_name = self.customer.last_name
                  self.customer.s_id_number = self.customer.customer_tz
                  self.customer.s_member_no = self.customer.customer_tz
                  self.customer.s_phone_number_1 = self.customer.phone_number
                  self.customer.is_new_customer = true
                  
                    // profiles:null
                    // promotion_types:Array[0]
                    // self.customer.cust_points = "0"
                    // "cust_points_for_use": "0",
                    // "dt_birth_date": "1988-02-02T00:00:00+00:00",
                    // "i_club_id": "7",
                    // "i_point_value": "0",
                    // "i_return_code": "1",
                    // "is_request_valid": true,
                    // "is_new_customer": true,
                    // "msg_for_screen": "",
                    // "msg_for_slip": "",
                    // "s_first_name": "פוזיטיב",
                    // "s_id_number": "200453694",
                    // "s_last_name": "שלום",
                    // "s_member_no": "99483816",
                    // "s_phone_number_1": "0545555333",
                    // val_per_points = 1
                   
                  self.selectCustomer(self.customer, '181')
                  //add item new member to pos 
                  this.loading = false
                  app.hideLoadingMessage()
                  return
                }        

                this.showError(result.s_return_msg)
                this.loading = false
                app.hideLoadingMessage()
                
              })
              .catch(() => {
                this.loading = false
                app.hideLoadingMessage()

              })
          }
        })
      }

      function customerHistory(customer) {
        if (!customer || !customer.id) {
          return;
        }

        posSearchInvoicesVC.searchObject = {customer_id: customer.id};
        posSearchInvoicesVC.showingResults = true;
        posSearchInvoicesVC.formRendered = true;
        this.close();

        pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content:  i18next.t('error'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function setMode(mode) {
        switch (mode) {
          case "search":
            this.searchCustomerMode = true
            this.addNewCustomerMode = false
            this.$nextTick(() => {
              this.selectInput('searchInputText')
            }) 
            break;
          case "new":
            this.searchCustomerMode = false
            this.addNewCustomerMode = true
            this.$nextTick(() => {
              this.selectInput('taz')
            })  
            break;
          default:
            //do noting
            break;
        }
      }

      function selectInput(field) {
        this.$refs[field].focus();
      }

      export function create() {

        let superFriendsCustomerClub = {
            template: JST.superFriendsCustomerClub(),
            methods: {
              cleanData: cleanData,
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              close: close,
              searchCustomer: searchCustomer,
              searchCustomerByCellular: searchCustomerByCellular,
              search: search,
              showCustomers: showCustomers,
              showError: showError,
              showGenericError: showGenericError,
              addNewCustomer: addNewCustomer,
              selectCustomer: selectCustomer,
              setMode: setMode,
              selectInput: selectInput,
              customerHistory: customerHistory,
              convertFromPosStructureToCustomerObject: convertFromPosStructureToCustomerObject,
              async selectCustomerAndCheckIfNewCustomer(customer) {
                let itemToAdd = '';

                if (session.pos.brillConfig) {
                  let brillConfig = JSON.parse(session.pos.brillConfig);

                  if (brillConfig.registered_customer_clubs && brillConfig.registered_customer_clubs.length > 0  && 
                      (!brillConfig.registered_customer_clubs.map(c => String(c || '')).includes(customer.class_code))) {

                        // Validate new customer
                        if (posUtils.isBlank(customer.s_first_name) ||
                        posUtils.isBlank(customer.s_last_name) ||
                        posUtils.isBlank(customer.s_id_number) || 
                        posUtils.isBlank(customer.s_phone_number_1) || 
                        posUtils.isBlank(customer.date_of_birth) || 
                        customer.date_of_birth == "00000000") {
                          app.showAlertDialog({
                              header: i18next.t('error'),
                              content: i18next.t('superFriendsCustClub.incompleteCustomerData'),
                              continueButtonText: i18next.t("ok"),
                              hideCancelButton: true,
                          });
                          return;
                        }

                        itemToAdd = '181';

                        this.close();
                      }
                }

                this.selectCustomer(customer, itemToAdd);
              },
              putCustomerInSale(customer) {
                this.selectCustomerAndCheckIfNewCustomer(customer);
              },
              getCustomerClubName(customer) {
                if (customer.class_code && i18next.te('superFriendsCustClub.clubsNames.' + customer.class_code)) {
                  return i18next.t('superFriendsCustClub.clubsNames.' + customer.class_code);
                } 

                return i18next.t('superFriendsCustClub.clubsNames.other');
              },
              getClubNumberAndAssignment(customer) {
                let result = "";

                if (!posUtils.isBlank(customer.class_code)) {
                  result = customer.class_code + " - ";
                }

                result += this.getCustomerClubName(customer);

                return result;
              } 
            },
            data: initData,

            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {fullClub,allowSetNewCustomerDiscountPercent} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {fullClub,allowSetNewCustomerDiscountPercent}

              return {...globalStoreProps}
            },
            computed:  {
              formattedPhoneNumber: {
                get() {
                  return this.customer?.phone_number ? this.customer?.phone_number : "";
                },
                set(value) {
                  if(!value || value == ''){
                    this.customer.phone_number = ''
                  }else{
                    this.customer.phone_number = value.replace(/\D/g, '');
                  }
                  
                }
              },
              currentCustomerTitle() {
                let title = i18next.t('superFriendsCustClub.title')
                
                if (this.customer && this.customer.customer_tz) {
                  title = this.getCustomerClubName(this.customer);
                }

                // Because JQuery :'(
                  if (this.$el) {
                    $(this.$el.parentElement).find('.ui-dialog-title').text(title);
                  }
                  
                return title;
              }, 
          },
        }

        VueApp.component('super-friends-customer-club',superFriendsCustomerClub)

      }
    }
}
}
