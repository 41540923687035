module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="table-item" :class="{\'box-view\': boxView || editBoxView, \'no-drag\': stopDragging || showChangeArea, \'popup\': showChangeArea}">\n	<template v-if="mobileLayout">\n		' +
((__t = ( JST.tableItemRowMobile() )) == null ? '' : __t) +
'\n	</template>\n	<template v-else>\n		<template v-if="editBoxView">\n			' +
((__t = ( JST.tableItemEditBox() )) == null ? '' : __t) +
'\n		</template>\n		<template v-else-if="boxView">\n			' +
((__t = ( JST.tableItemBox() )) == null ? '' : __t) +
'\n		</template>\n		<template v-else>\n			' +
((__t = ( JST.tableItemRow() )) == null ? '' : __t) +
'\n		</template>\n\n		' +
((__t = ( JST.tableItemDialog() )) == null ? '' : __t) +
'\n		\n		<table-attributes-dialog ref="tableAttributesDialog" v-if="editBoxView"></table-attributes-dialog>\n	</template>\n</div>\n';

}
return __p
}