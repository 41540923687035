module PositiveTS {
  export module Service {
    export class SocketActions {
      intervalInMs:number
      intervalHndlr:any
      CHANNEL_ACTIONS:any

      constructor() {
        this.intervalInMs = 15000
        this.CHANNEL_ACTIONS = {
          UPDATEDB: {name: "UPDATEDB" ,func: this.refreshLocalDbAction},
          UPDATEADDONFILES: {name: "UPDATEADDONFILES" ,func: this.addonFilesUpdate},
          RELOAD: {name: "RELOAD" ,func: this.reload},
          ECHO: {name: "ECHO" ,func: this.echoAction},
        }
      }
        
      
      
      intervalActions() {
        let versionToSend = String(session.addonFilesVersion);
        if (session.isAndroid) {
          versionToSend = `A${Android.getVersion()}`;
        }

        let regexRes = posUtils.isDefined(navigator.userAgent) ? (/Chrome\/(.*)\s/).exec(navigator.userAgent) : null;
        let webviewVersion = posUtils.isDefined(regexRes) ? regexRes[1] : null;

        let object_to_send = {
          tenantID: session.pos.tenantID,
          tenantName: session.pos.tenantName,
          companyID: session.pos.companyID,
          storeID: session.pos.storeID,
          posNumber: session.pos.posNumber,
          deviceID: session.pos.deviceID,
          physicalID: session.pos.physicalID,
          offLineSales: Pinia.globalStore.offlineSaleCount,
          appVersion: PositiveTS.Version.appVersion,
          vat: session.systemSettings && session.systemSettings.vat,
          addonVersion: versionToSend,
          webviewVersion: posUtils.isDefined(webviewVersion) ? webviewVersion : '',
        };
    
        socketManager.triggerAction('onlinecheck',object_to_send)
        .then((response:any) => {
          if (localStorage.getItem("employee_time_tracks_last_update") !== response.employee_time_tracks_last_update) {
              return AutoEmployeeStore.runService()
              .then( ()=>{
                  localStorage.setItem("employee_time_tracks_last_update", response.employee_time_tracks_last_update);
              });
          }
          if(response.is_remote_support_enabled){
            let hour = 1000 *60 *60;
            if(!PositiveTS.VueInstance.isVorlonActivated){
              PositiveTS.VueInstance.startVorlon()
            }
            
            setTimeout(()=>{
              if(PositiveTS.VueInstance.isVorlonActivated){
                PositiveTS.VueInstance.stopVorlon()
              }
            },hour)
          }

          localStorage.setItem("serverCatalogDate",response.catalog_last_update);
          localStorage.setItem("serverCatalogisCritical",response.catalog_is_critical)
          isCatalogUpdated.setIsLocalCatalogUpdatedStatus()

          localStorage.setItem("lastWoltOrder", response.new_wolt_orders);            

          Service.Sms.setReminderQuota(response.sms_quota || 0);
        })
      };

      clearInterval() {
        clearInterval(this.intervalHndlr);
      }
      
      startInterval() {
        clearInterval(this.intervalHndlr);
        this.intervalHndlr = setInterval(this.intervalActions ,this.intervalInMs);
      };
      
      /*
      Usage
        FROM SERVER:
          a = {:action => "ECHO",:param=>"shalom olam"}
          WebsocketRails[:fishman_2_58_CEL05801].trigger :new, a
            OR
          WebsocketRails[:fishman_2_58].trigger :new, a
            OR
          WebsocketRails[:fishman_2].trigger :new, a
        
        FROM CLIENT:
          socketManager.admin_listerOnChannel(fishman_2_58_CEL05801, 
            function(msg){console.log(msg);})
          socketManager.admin_send(action: "ECHO",param: "shalom olam"});
      */
      echoAction(params) {
        console.log(params);
      };
      
      /*
        Usage:
        a = {:action => "RELOAD"}
        WebsocketRails[:fishman_2_58_CEL05801].trigger :new, a
      */
      reload() {
        location.reload(true);
      }
      
      /*
      Usage:
        a = {:action => "UPDATEDB"}
        WebsocketRails[:fishman_2_58_CEL05801].trigger :new, a
      */
      refreshLocalDbAction() {
        statsVC.triggerUpdateDbUsingUi();    
        return;
      };
      
        /*
      Usage:
        a = {:action => "UPDATEADDONFILES"}
        WebsocketRails[:fishman_2_58_CEL05801].trigger :new, a
      */
      addonFilesUpdate() {
        PositiveTS.Service.FileUpload.addonFilesUpdate();    
        return;
      };
      
      runChannelAction(message) {
        var action  = message.action;
        var param = message.param;
        return this.CHANNEL_ACTIONS[action].func(param);    
      };
    
    }
  }
}

declare var socketActions: PositiveTS.Service.SocketActions;
 
