module PositiveTS {
	export module Storage {
		export module Entity {
			export class PositiveDeliveryApi extends WasmEntity {

				name: string;
				url: string;
				token: string;
				invoiceTypeConfiguration: string;

				constructor() {
					let meta = {
						name: 'positiveDeliveryApis',
						fields: {
							name: "TEXT",
							url: "TEXT",
							token: "TEXT",
							invoiceTypeConfiguration: "TEXT",
						},
					}
					super(meta)
				}


				static getById(id) {
					return this.execAndConvertResult(`select * from positiveDeliveryApis where id = ${id}`)[0];
				}
			}
		}
	}
}
