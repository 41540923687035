module PositiveTS {
  export module Components {
    export module FlightsPrintFlightLegSumReport {

      export function getComponent() {
        return {
          template: JST.flightsPrintFlightLegSumReport(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight,currentTeamLegs} = Pinia.storeToRefs(flightsStore);
            return {currentFlight,currentTeamLegs}
          },
          methods: {
            async print() {
              app.showLoadingMessage(i18next.t("loading"));
              try {
                let currentEmployee = this.wizardData.loggedInEmployee;

                await Service.FlightReport.printLegSumReport(currentEmployee);
                
                app.hideLoadingMessage();
              } catch (err) {
                app.hideLoadingMessage();
                console.error(err);
                this.showGenericError();
              }
            },
          },
          mounted() {
            if (!this.stepData.isPrinted) {
              this.stepData = { isPrinted: true };
              this.print();
            }
          }
        }
      }
    }
  }
}