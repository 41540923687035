module PositiveTS {
    export module Components {
        export module HorizontalSelect {
            export function getComponent() {
                let component = {
                    template: JST.HorizontalSelect(),
                    components: {
                        'container': HorizontalScrollContainer.getComponent(),
                    },
                    mixins: [],
                    props: {
                        tabs: {
                            /** @type {Array<PositiveTS.Types.HorizonalSelectTab} } */
                            type: Array,
                            default: [],
                        },
                        noScrollBar: {
                            type: Boolean,
                            default: true,
                        },
                    },
                    data() {
                        return {
                            selectedTabIndex: -1,
                        }
                    },
                    computed: {
                        dynamicClasses() {
                            let cssClasses = {
                                'no-scroll-bar': this.noScrollBar,
                            };
                            return cssClasses;
                        },
                    },
                    methods: {
                        smallText(txt: string): boolean {
                            return txt.length > 10;
                        },
                        tabClicked($event: Event, index: number) {
                            this.selectedTabIndex = index;
                            let args: PositiveTS.Types.HorizonalTabClickedArgs = { index: index, event: $event };
                            this.$emit('tabClick', args);
                        },
                        setSelectedManually(index: number) {
                            this.selectedTabIndex = index;
                        }
                    }
                }
                return component;
            }
        }
    }
}

