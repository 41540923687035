module PositiveTS {
    export module Service {
        export module CaveretEmployeeApproval {

            export async function isEmployeeInStore(taz, magneticCard) {
                let response = await PositiveTS.Service.FetchReq.jsonReqWithTimeout('/is_employee_store', 'post', 10000, { taz: taz, magnetic_card: magneticCard });
                if (response === null) {
                    Pinia.globalStore.isSlowConnection(true);
                    return null;
                }
                return response.result;
            };

            export async function verifyPassword(taz, magneticCard, password) {
                let response = await PositiveTS.Service.FetchReq.jsonReqWithTimeout('/authenticate_employee', 'post', 10000, { taz: taz, magnetic_card: magneticCard, password: password });
                if (response === null) {
                    Pinia.globalStore.isSlowConnection(true);
                    return null;
                }
                return response.result;
            }
        }
    }
}