module PositiveTS {
export module Promotions {
export module Templates {
export class KneBeWithWeightNew extends TemplateAbstract {
  private idToQty
  private promoGroups = {}
  private allItems = {}
  private itemByCode = {}
  private minBuyQuantity
  private minBuyAmount
  private allowMultipleTimesPerSale
  private promotionApplied;
  private templates;
  private buyItems;

  constructor(initParameters) {
    super(initParameters)
    // 16 - exact amount, don't mix items
    // 17 - exact amount, mixed items
    // 160 - minumum amount and up, don't mix items
    // 170 - minumum amount and up, mix items
    this.templates = ['16', '17', '160', '170'] 
    this.promotionApplied = false;
    this.promoGroups = {};
    this.idToQty = {};
  }

  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (let promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      var promotion = this.parameters.promotionsByCode[promotionCode];

      if (this.templates.indexOf(promotion.template) < 0 ) {
        continue;
      }
      this.run(promotion)
    }
  }

  run(promotion:Storage.Entity.Promotion) {
    this.promoGroups = {};
    if (!this.valid(promotion)) {
      return;
    }
   
    if (promotion.template == '16' || promotion.template == '160') {
      //don't mix between items
      let usedBarcodes = []
      for (let item of this.parameters.promotionsBuy[promotion.code]) {
        let currentBarcode = item.barcode;
        if (usedBarcodes.indexOf(currentBarcode) >= 0) {
          continue;
        }
        // In this case, promotion actually raises the base price for 1 KILO
        if (item.unitPrice < promotion.discountValue && promotion.discountType === "Fix") {
          continue;
        }
        if (!item.hasWeight) {
          continue;
        }
        usedBarcodes.push(item.barcode);
        this.buyItems = this.initData(promotion, item.barcode);
        this.performRun(promotion);
      }
    } else {
      this.buyItems = this.initData(promotion);
      this.performRun(promotion);
    }
   
    this.addToPromoGroups()
  }

  private initData(promotion, barcodeFilter = null) {
    this.allItems = {};
    this.minBuyQuantity = Number(promotion.minimumBuyQuantity);
    this.minBuyAmount = Number(promotion.minimumBuyAmount);
    this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
    this.allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);

    let items = this.parameters.promotionsBuy[promotion.code].concat(this.parameters.promotionsGet[promotion.code])
    for (let item of items) {
      if (item) {
        this.allItems[item.id] = item;
      }
    }

    let flattenedSaleItemsBySide = _.cloneDeep(this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code],false));
    let buyItems = flattenedSaleItemsBySide.sort(this.sortByUnitPriceFromCheapestToExpensive);
    if (barcodeFilter) {
      buyItems = buyItems.filter(item => {return item.barcode == barcodeFilter})
    }

    return buyItems;
  }


  //validate if promotion can be applied
  private valid(promotion) {
    if (Number(promotion.minimumBuyQuantity) < 0) {
      console.error('quantity is smaller than 0 - not applying promotion')
      return false;
    }
    if (!this.parameters.promotionsBuy[promotion.code]) {
      return false;
    }

    return true;
  }

  private performRun(promotion:Storage.Entity.Promotion) {
    let [totalPriceForRun, totalQuantityForRun, itemsIncludedInRun, totalQuantity] = [0,0,{},0];
    let addPromoGroupAtTheEnd = false;
    let currentItem = null;

    while (this.buyItems.length > 0) {
      if (!this.allowMultipleTimesPerSale && this.promotionApplied) {
        break;
      }

       currentItem = this.buyItems.shift();
       currentItem.quantity = session.fixedFloat(currentItem.quantity, 3)// fix item qty, somtimes its get boken

       if (!this.itemByCode[currentItem.code]) {
        this.itemByCode[currentItem.code] = currentItem;
       }

      let currentItemQuantity = this.getItemQuantity(currentItem)
      let currentItemPrice = session.fixedFloat(currentItem.unitPrice * currentItemQuantity, 2);

      if (promotion.maxQuantityToGiveCustomer > 0){
        if (totalQuantity >= promotion.maxQuantityToGiveCustomer) {
          break;
        }

        if (session.fixedFloat(totalQuantity + currentItemQuantity, 2) > promotion.maxQuantityToGiveCustomer) {
          // braek items to fit maximum qty
          let splittedItems = saleItemHelper.splitSaleItemByQty(currentItem, session.fixedFloat(promotion.maxQuantityToGiveCustomer - totalQuantity, 3));

          currentItem = splittedItems[0];
          this.buyItems.unshift(splittedItems[1])
  
          currentItemQuantity = this.getItemQuantity(currentItem)
          currentItemPrice = session.fixedFloat(currentItem.unitPrice * currentItemQuantity, 2);
        }
      }


       // Exact quantity promotion
      if (promotion.template == '16' || promotion.template == '17') {
        if (session.fixedFloat(totalQuantityForRun + currentItemQuantity, 3) > this.minBuyQuantity) {
          // break items to fit exact
          let splittedItems = saleItemHelper.splitSaleItemByQty(currentItem, session.fixedFloat(this.minBuyQuantity - totalQuantityForRun, 3));

          currentItem = splittedItems[0];
          this.buyItems.unshift(splittedItems[1])

          currentItemQuantity = this.getItemQuantity(currentItem)
          currentItemPrice = session.fixedFloat(currentItem.unitPrice * currentItemQuantity, 2);
        }
      }

      totalPriceForRun = session.fixedFloat(totalPriceForRun + currentItemPrice, 2);
      totalQuantityForRun = session.fixedFloat(totalQuantityForRun + currentItemQuantity, 3);
      totalQuantity = session.fixedFloat(totalQuantity + currentItemQuantity, 3);

      itemsIncludedInRun[currentItem.id] = (itemsIncludedInRun[currentItem.id] || 0) + currentItemQuantity;

      if (totalQuantityForRun >= this.minBuyQuantity) {
          // Exact quantity promotion
          if (promotion.template == '16' || promotion.template == '17') {
          this.addPromoGroup(this.itemByCode[currentItem.code],itemsIncludedInRun,totalPriceForRun,totalQuantityForRun, promotion)

          totalPriceForRun = 0;
          totalQuantityForRun = 0;
          itemsIncludedInRun = {};
        } else {
          addPromoGroupAtTheEnd = true;
        }
      }

    }

    if (addPromoGroupAtTheEnd) {
      this.addPromoGroup(currentItem,itemsIncludedInRun,totalPriceForRun,totalQuantityForRun, promotion)
    }
  }

  private addPromoGroup(itemToPromote, itemsIncludedInRun, totalPriceForRun, totalQuantityForRun, promotion) {

    if (!this.promoGroups[itemToPromote.id]) {
      this.promoGroups[itemToPromote.id] = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
    }

    let promoGroup = this.promoGroups[itemToPromote.id];

    let discountValue = this.getDiscountValue(itemToPromote, totalPriceForRun, totalQuantityForRun, promotion);

    this.promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount = session.fixedFloat(this.promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount + this.getTotalPriceForItemsInRun(itemsIncludedInRun), 2)
    this.promoGroups[itemToPromote.id].discountAmountForGroup = session.fixedFloat(this.promoGroups[itemToPromote.id].discountAmountForGroup + discountValue, 2);


    for (let id in itemsIncludedInRun) {
      let item = this.allItems[id]
      let promoGroup = this.promoGroups[itemToPromote.id]
      this.addToItemsCounter(item, promoGroup.itemsCounter, itemsIncludedInRun[id])
      this.addToRowValueCounter(item, promoGroup.rowValueCounter, itemsIncludedInRun[id])
    }

    let item = {
      discountAbsoluteValue: promoGroup.discountAmountForGroup,
      discountPrecentValue: session.fixedFloat(promoGroup.discountAmountForGroup/(itemToPromote.unitPrice * itemToPromote.realQuantity)*100, 2),
      discountType: promotion.discountType == 'Percent' ? PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT : PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT,
      isPromotionGiven: true,
      saleItemID: itemToPromote.id,
      promotionCode: promotion.code,
      promotionName: promotion.name,
      buyGet: 'buy'
    }

    promoGroup.item = item;
    this.promotionApplied = true
  }

  private getTotalPriceForItemsInRun(itemsIncludedInRun) {
    let totalPrice = 0
    for (let id in itemsIncludedInRun) {
      totalPrice+= this.allItems[id].unitPrice * itemsIncludedInRun[id];
    }
    return totalPrice;
  }

  private getDiscountValue(item, totalPriceForRun, totalQuantityForRun, promotion:Storage.Entity.Promotion) {
    let qty = (promotion.maxQuantityToGiveCustomer > 0 ? 
      Math.min(promotion.maxQuantityToGiveCustomer,totalQuantityForRun) : totalQuantityForRun)
    switch (promotion.discountType) {
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
        return qty * promotion.discountValue;
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
        return totalPriceForRun - promotion.discountValue * qty
      case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
        return totalPriceForRun * promotion.discountValue/100.0;
    }
  }

  private addToPromoGroups() {
    this.parameters.promoGroups = this.parameters.promoGroups.concat(Object.values(this.promoGroups))
  }

  private getItemQuantity(item) {
    let quantity = 1
    if (item.hasWeight){
      if(session.pos.useNewPromotions){
        quantity = item.quantity
      }else{
        quantity = item.realQuantity
      }
    }

    return quantity
  }

}}}}
