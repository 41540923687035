module PositiveTS {
  export module Components {
    export module EnterPNRCodeDialog {
      const dialogSelector = 'enter-pnr-code-dialog';
      export function create() {
        let enterPNRCodeDialog = {
          template: JST.EnterPNRCodeDialog(),
          data() {
            return {
              resolveFunc: null,
              pnrValue: '',
            };
          },
          computed: {
            elalSequence() {
              return i18next.t('elal.PNRDialog.elalSequence', { seq: this.getLastElalSaleSequence });
            },
            getLastElalSaleSequence(){
              return Pinia.elalStore.getLastElalSaleSequence
            }
          },
          methods: {
            async submit() {
              app.showLoadingMessage();
              if (posUtils.isBlank(this.pnrValue)) {
                app.hideLoadingMessage();
                return;
              }
              try {
                let res = await Service.ElalApiService.GetPNR(this.pnrValue);

                if (posUtils.isBlank(res?.root?.PNR) || posUtils.isBlankLikeRails(res.root.reservations)) {
                 Pinia.elalStore.setOfflinePNR(this.pnrValue);
                }
                app.hideLoadingMessage();
                this.close(res);  
              } catch (error) {
                app.hideLoadingMessage();
                console.error(error);
                this.close(error);
              }
            },
            open() {
              this.$el.showModal();
              $(document).unbind('keypress');
              this.pnrValue = '';
              return new Promise(resolve => { this.resolveFunc = resolve });
            },
            cancel(event) {
              event.preventDefault()
              event.stopImmediatePropagation()
              return this.close();
            },
            close(resolveResult) {
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();
              this.resolveFunc(resolveResult);
            },
          },
        }
        VueApp.component(dialogSelector, enterPNRCodeDialog)
      }
    }
  }
}
