module PositiveTS {
    export module Components {
        export module RestaurantSingleItem {
            export function getComponent() {
                let component = {
                    template: JST.RestaurantSingleItem(),
                    props: {
                        item: {
                            type: Storage.Entity.SaleItem,
                        },
                        defaultExpanded: {
                            type: Boolean,
                            default: jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOSExpandedDefault)
                        },
                    },
                    mixins: [PositiveTS.Components.Mixins.saleItemMixin, PositiveTS.Components.Mixins.itemImageMixin],
                    components: {
                        'restaurant-item-actions': PositiveTS.Components.RestaurantItemActions.getComponent(),
                        'positive-icon': PositiveIcon.getComponent(),

                    },
                    data() {
                        return {
                            isItemExpanded: this.defaultExpanded,
                            justEnteredSelectMode: false,
                            allowQuickBonPrinting: jsonConfig.getVal(jsonConfig.KEYS.allowQuickBonPrinting),
                            allowBonPrinting :jsonConfig.getVal(jsonConfig.KEYS.allowBonPrinting),
                        };
                    },
                    watch: {
                        expandedView() {
                            this.isItemExpanded = this.expandedView;
                        }
                    },
                    computed: {
                        isSelected() {
                            // get the selected sale item from the store
                            let selectedSaleItem = Pinia.posStore.selectedItems;

                            // check if the current item is selected
                            return selectedSaleItem.some(saleItem => saleItem.id === this.item.id)
                        },
                        isExpanded() {
                            return this.isItemExpanded && this.canBeExpanded;
                        },
                        currencySign() {
                            return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                        },
                        canBeExpanded(){
                            const getterNames = [
                                'canChangeQuantity',
                                'canEditItem',
                                'canDeleteItem',
                                'canMoveDalpak',
                                'canMakeItemDiscount'
                            ];
                            if(this.allowBonPrinting && this.allowQuickBonPrinting && !this.item){
                                return true
                            }
                            const result = getterNames.some(getterName => this[getterName](this.item));                   
                            return result;
                        },
                    },
                    setup(){
                        const posStore = Pinia.usePosStore();
                        const {expandedView,selectModeActive} = Pinia.storeToRefs(posStore);
                        const  posStoreProps = {expandedView,selectModeActive}
            
                        const {enterEditMode,selectItem,setManualQuantitiy} = posStore;
                        const posStoreActions = {enterEditMode,selectItem,setManualQuantitiy}

                        const globalStore = Pinia.useGlobalStore();

                        const {
                            usePictures,
                            allowedItemActions,
                            canIncrementQuantity,
                            canDecrementQuantity,
                            canChangeQuantity,
                            canEditItem,
                            canDeleteItem,
                            canMoveDalpak,
                            canMakeItemDiscount
                        } = Pinia.storeToRefs(globalStore);

                        const globalStoreProps = {
                            usePictures,
                            allowedItemActions,
                            canIncrementQuantity,
                            canDecrementQuantity,
                            canChangeQuantity,
                            canEditItem,
                            canDeleteItem,
                            canMoveDalpak,
                            canMakeItemDiscount
                        }
            
                        return {...posStoreActions,...posStoreProps,...globalStoreProps}
                    },
                    methods: {
                        childrenPrice(saleItem: Storage.Entity.SaleItem) {
                            let childrenPrice: number = 0;
                            if (saleItem.children?.length > 0) {
                                saleItem.children.forEach((child) => {
                                    childrenPrice += parseFloat(child.displayTotal);
                                    if (child.children?.length > 0) {
                                        child.children.forEach((grandChild) => {
                                            childrenPrice += parseFloat(grandChild.displayTotal);
                                        });
                                    }
                                });
                            }
                            return childrenPrice;
                        },
                        async updateItemQuantity(){
                            try{
                                if(!this.item.bonPrintedAt && this.canChangeQuantity(this.item)){
                                    let quantityToUpdate = await Pinia.componentsStore.openComponent( {componentName:"selectItemQuantityDialog", args: [this.item.quantity]});  
                                    if(quantityToUpdate){
                                        this.$emit('updateItemQuantity',{item:this.item,quantityToUpdate})
                                    }
                                }
                            }catch(err){
                                console.error(err);
                            }
                        },
                        getChildrenGroup(saleItem: Storage.Entity.SaleItem) {
                            return Storage.Entity.ItemGroup.getGroupNameByItemGroupID(saleItem.itemGroupId)
                        },
                        toggleExpand() {
                            this.isItemExpanded = !this.isItemExpanded;
                        },
                        expandOrSelect(item = null) {
                            if (this.justEnteredSelectMode) {
                                return;
                            }
                            if (this.selectModeActive) {
                                this.selectItem(this.item);
                            } else {
                                this.isItemExpanded = !this.isItemExpanded;
                            }
                        },
                        longPress() {
                            this.justEnteredSelectMode = true
                            this.selectItem(this.item);
                            if (posUtils.isTouchDevice()) {
                                setTimeout(() => this.justEnteredSelectMode = false, 250)
                            } else {
                                this.$nextTick(() => {
                                    this.justEnteredSelectMode = false
                                })
                            }
                        }


                    }
                }
                return component;
            }
        }
    }
}