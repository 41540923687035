module PositiveTS {
  export module Components {
    export module FlightsIsrairStockCount {

      export function getComponent() {
        return {
          template: JST.flightsIsrairStockCount(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          methods: {
            calculatedQty(item) {
              let calculated = ((this.itemsStartCountByCode[item.code] || 0) - (this.itemsSoldByCode[item.code] || 0));
              return calculated < 0 ? 0 : calculated;
            },
            finishCount() {
              if (this.verifyData()) {
                this.goToNextStep();
              } else {
                this.showError(i18next.t('flightsWizard.barsetCount.validationFailed'));
              }
            },
            isValidCountNumber(value) {
              return (!isNaN(parseInt(value)) && value == parseInt(value)) && parseInt(value) >= 0;
            },
            verifyData() {
              this.showErrors = true;

              if (!this.stepData.itemsCount) {
                return false;
              }
              if (_.sum(Object.values(this.stepData.itemsCount)) <= 0) {
                return false;
              }

              for (let itemId in (this.stepData.itemsCount)) {
                if (!this.isValidCountNumber(this.stepData.itemsCount[itemId])) {
                  return false;
                }
              }

              return true;
            },
            nextInput(event) {
              let nextInputIndex = this.$refs.countInput.indexOf(event.target) + 1;

              if (this.$refs.countInput[nextInputIndex]) {
                this.$refs.countInput[nextInputIndex].focus();
                return;
              }

              this.finishCount();
            },
            tenantID() {
              return session.pos.tenantID;
            },
            companyID() {
              return session.pos.companyID;
            },
            getItems() {
              return Array.from(session.allItems.values()).filter(item => item.hasInventory);
            },
            async setItemsCalculatedAndStartCount() {
              let allSales = await appDB.sales.where('invoiceSequence').above(-1).toArray();

              let calculatedByCode = {};

              for (let sale of allSales) {
                for (let saleItem of sale.items) {
                  calculatedByCode[saleItem.itemCode] = (calculatedByCode[saleItem.itemCode] || 0) + saleItem.quantity;
                }
              }

              this.itemsSoldByCode = calculatedByCode;

              let barset = await appDB.barsets.toCollection().first();
              
              let startCountByCode = {};

              for (let barsetItem of (barset.barset_items || [])) {
                startCountByCode[barsetItem.code] = (startCountByCode[barsetItem.code] || 0) + barsetItem.flight_start_count;
              }

              this.itemsStartCountByCode = startCountByCode;

              this.stepData = {
                ...this.stepData,
                itemsStartCountByCode: this.itemsStartCountByCode,
                itemsSoldByCode: this.itemsSoldByCode,
              }
            },
            handleIconClick(item) {
              this.stepData.itemsCount[item.code] = this.calculatedQty(item);
            },
            getIconVisibilityStyle(item) {
              return {
                visibility: this.calculatedQty(item) == (this.stepData.itemsCount[item.code] || 0) ? 'hidden' : 'visible'
              };
            },  
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {usePictures,rtlMode} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {usePictures,rtlMode}

            return {...globalStoreProps}
          },
          computed: {
            isStartCount() {
              return this.wizardData.openFlight;
            },
          },
          data() {
            return {
              isCounting: false,
              showErrors: false,
              itemsSoldByCode: {},
              itemsStartCountByCode: {},
            }
          },
          mounted() {
            if (!(this.stepData && this.stepData.itemsCount)) {
              this.stepData = { itemsCount: {} };
            }

            if (!this.isStartCount) {
              app.showLoadingMessage(i18next.t("loading"));
              this.setItemsCalculatedAndStartCount().then(() => {
                app.hideLoadingMessage();
              });
            }
          }
        }
      }
    }
  }
}