
import _ from 'loadsh';

const currenciesEMV = {
  ILS: '376',
  USD: '840',
  EUR: '978'
}
export default class MultiCurrencyService {
    
    static CURRENCY_SIGNS = {
        ILS: "₪",
        USD: "$",
        EUR: "€",
        GBP: "£",
        AUD: "$",
        CAD: "$",
        CHF: "CHF",
        DKK: "kr",
        EGP: "£",
        JOD: "JOD",
        JPY: "¥",
        LBP: "£",
        NOK: "kr",
        SEK: "kr",
        ZAR: "R"
      }

      static LTR_CURRENCY_SIGNS = {
        'USD': true,
        'GBP': true,
      }

      static currenciesEMV = currenciesEMV;
      static inverterdCurrenciesEmv = _.invert(currenciesEMV);

      static getCurrencyFromEmvCode(emvCurrencyCode) {
        return MultiCurrencyService.inverterdCurrenciesEmv[emvCurrencyCode];
      }
    

    getPaymentCurrencyCreditRequestEMV(): string {
        return MultiCurrencyService.currenciesEMV.ILS;
    }

    isMultiCurr(): boolean {
        return false;
    }

    getCurrencyCodeEMV(paymentToCredit): string {
        if (!this.isMultiCurr() || !paymentToCredit.creditCurrency) {
            return MultiCurrencyService.currenciesEMV.ILS;
        }
        
        return MultiCurrencyService.currenciesEMV[paymentToCredit.creditCurrency]
    }

    getCurrencySign(currencycode) {
      return MultiCurrencyService.CURRENCY_SIGNS[currencycode] || '';
    }
}