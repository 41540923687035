module PositiveTS {
    export module Service {
        export module SelfServiceSaleOrders {
            function prefixUrl() {
                return `/self_service_sale_orders/`
            }

            function getNewOrderNumber() {
                return posVC._getNewOrderNumber();
            }

            export async function createNewOrder(sale: PositiveTS.Storage.Entity.Sale, items: PositiveTS.Storage.Entity.SaleItem[]): Promise<PositiveTS.Types.SelfServiceSaleOrdersOrder> {
                try {
                    let orderNumber = sale.orderNumber? sale.orderNumber: getNewOrderNumber();
                    let jsondata = posUtils.jsonParse(sale.jsondata);
                    jsondata.SelfServiceSaleOrder = { orderNumber: orderNumber, posDeviceId: session.pos.deviceID };
                    sale._new = true;
                    sale.jsondata = JSON.stringify(jsondata);
                    let saleData = sale.exportToObject();
                    let itemsData = items.map(item => {
                        delete item.saleID;
                        item._new = true;
                        return item.exportToObject()
                    });
                    let req: any = { data: { items: itemsData, sale: saleData }, order_num: orderNumber };
                    if (sale.customerName || jsondata?.dedicatedTo) {
                        req.customer_name = sale.customerName || jsondata?.dedicatedTo;
                    }
                    let res = await PositiveTS.Service.FetchReq.jsonReq(prefixUrl(), 'POST', { order: req });
                    if (!res.response.ok) {
                        PositiveTS.Service.Filelog.log("SelfServiceSaleOrders", JSON.stringify({ action: "createNewOrder", response: res}), false, 'error');
                        throw new ErrorWithData("Got a bad response from server", res);
                    }
                    let { id, customer_name, order_num, created_at, order_time } = res.result.order
                    return { data: { sale: sale, items: items }, order_num, customer_name, created_at, order_time, id };
                } catch (error) {
                    PositiveTS.Service.Filelog.log("SelfServiceSaleOrders", JSON.stringify({ action: "createNewOrder", error: error}), false, 'error');
                    throw new ErrorWithData("Got a general error", error);
                }
            }
            export async function getOrders(freeSearch: string, pageNum = 1): Promise<PositiveTS.Types.SelfServiceSaleOrders> {
                try {
                    let url = `${prefixUrl()}?page=${pageNum}&per_page=4`;
                    if (posUtils.isPresent(freeSearch)) {
                        url += `&free_search=${freeSearch}`
                    }
                    let res = await PositiveTS.Service.FetchReq.jsonReq(url, 'GET');
                    if (!res.response.ok) {
                        PositiveTS.Service.Filelog.log("SelfServiceSaleOrders", JSON.stringify({ action: "getOrders", response: res }), false, 'error');
                        throw new ErrorWithData("Got a bad response from server", res);
                    }
                    let formattedOrders = res.result.items.map((order): PositiveTS.Types.SelfServiceSaleOrdersOrder => {
                        let sale = new PositiveTS.Storage.Entity.Sale().importFromObject(order.data.sale);
                        let saleItems: PositiveTS.Storage.Entity.SaleItem[] = order.data.items.map(si => {
                            let saleItem = new PositiveTS.Storage.Entity.SaleItem();
                            return saleItem.importFromObject(si)
                        }
                        );
                        let { customer_name, id, order_time, created_at, order_num } = order;
                        return { data: { sale, items: saleItems }, order_num, customer_name, created_at, order_time, id };
                    })
                    return { items: formattedOrders, meta: res.result.meta };
                } catch (error) {
                    PositiveTS.Service.Filelog.log("SelfServiceSaleOrders", JSON.stringify({ action: "getOrders", error: error }), false, 'error');
                    throw new ErrorWithData("Got a general error", error);
                }
            }
            export async function loadOrderAsSale(order: PositiveTS.Types.SelfServiceSaleOrdersOrder): Promise<Boolean> {
                if (posVC.salePayments.length > 0) {
                    throw new Error("hold current sale prerequesists failed");
                }
                if (posVC.sale) {
                    await appDB.sales.delete(posVC.sale.id);
                }
                let jsondata = posUtils.jsonParse(order.data.sale.jsondata);
                jsondata.SelfServiceSaleOrder.id = order.id; // Add Id for later usage
                order.data.sale.jsondata = JSON.stringify(jsondata);
                order.data.sale.posDeviceID = session.pos.deviceID;
                order.data.sale.posNumber = session.pos.posNumber.toString();
                order.data.sale.posPhysicalID = session.pos.physicalID;
                for (const saleItem of order.data.items) {
                    saleItem.saleID = order.data.sale.id;
                    if (posUtils.isPresent(saleItem.children)) {
                        for (const child of saleItem.children) {
                            child.saleID = order.data.sale.id;
                            if (posUtils.isPresent(child.children)) {
                                for (const grandChild of child.children) {
                                    grandChild.saleID = order.data.sale.id;
                                    if (posUtils.isPresent(grandChild.children)) {
                                        for (const greatGrandChild of grandChild.children) {
                                            greatGrandChild.saleID = order.data.sale.id;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                let saleId = await FullSale.persistAndReturnID(order.data.sale, order.data.items, [], false);
                let propsToUpdate: any = { invoiceSequence: -1 };
                await appDB.sales.update(saleId, PositiveTS.Shared.DB.checkIfNeedCloneProxy(propsToUpdate))
                await posVC.resume();
                return true;
            }
        }
    }
}