module PositiveTS {
export module Service {
export module HakafaCardPayment {

  export async function generateDirectHakafaPayment(title = i18next.t('directHakafa.title'), description = i18next.t('directHakafa.description'), inputPlaceHolder = i18next.t('directHakafa.placeHolder')) {

    let totalAmount = posVC.getTotalAmount();
    if (totalAmount == 0) return;
    
    let customerCard = await inputDg.open({
                header: title,
                description: description,
                dialogPosition: {my: 'center top+150', at: 'top'},
                inputPlaceHolder: inputPlaceHolder,
                strDefault: '',
                showCancelButton: true,
                isShowKeyboard: false,
                keyboardLayout: "hebrew-qwerty-custom",
                emptyErrorMessage: i18next.t('directHakafa.cardRequired'),
                inputValidator: function(value) {
                    return !posUtils.isNullOrUndefinedOrEmptyString(value);
                },   
    }) 

    try {
      app.showLoadingMessage(i18next.t('directHakafa.customerSearch')); 
      
      let data = await searchHakafaCustomer(customerCard)

      app.hideLoadingMessage();    

      let foundCustomer = data.result.hakafa_customer;
      if(foundCustomer.id == 0){
        await app.promiseShowAlert({header: i18next.t('directHakafa.error'),content: i18next.t('directHakafa.notExists'),hideCancelButton: true});
        return;
      }
      else { 
        selectCustomer(foundCustomer);
        if(totalAmount<0){
          await returnHakafa();
          return; 
        }
        let leftToPay =  (data.result.meta.obligo ? data.result.meta.obligo : 0) + (data.result.meta.amount ? data.result.meta.amount : 0);
        await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);

        if(data.result.meta.obligo != null && leftToPay-totalAmount <= 0) { 
          let msg = i18next.t('directHakafa.moreThanObligo');
          await app.promiseShowAlert({header: i18next.t('directHakafa.error'),content: msg,hideCancelButton: true});
          return;
        } else{
          await payAll();
          return;
        }
      }
    }
    catch(e) {
      app.hideLoadingMessage();
      console.error(e);
      await app.promiseShowAlert({header: i18next.t('directHakafa.error'), content: i18next.t('directHakafa.generalError'), hideCancelButton:true});
    }
  }


    export async function searchHakafaCustomer(searchInput) : Promise<any> {
      if (PositiveTS.Reachability.isOnline) {
        return PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/search_by_card/?search=${searchInput}`,"post")
      } 
       
      let sqlExact = `select * from HakafaCustomer where customer_number = '${searchInput}' and is_locked = false`,
        data = await PositiveTS.Service.WasmDB.promiseSql(sqlExact);

        if(data.length == 1){
          return {result: {hakafa_customer: data[0], meta:{amount: Number(data[0].amount), obligo: Number(data[0].obligo)}  }};
        }else if(data.length > 1){
          return {result: {hakafa_customer: 0}};
        }
        return {result: {hakafa_customer: null}};
    }
    
    export async function selectCustomer(customer) {
      let customerClubService = new CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
      let hakafaCustomerInPosStruct = await Service.Hakafa.convertToPosStructure.call(this, customer)
      await customerClubService.setCurrentSelectedCustomer(hakafaCustomerInPosStruct)
      await posVC.saleUpdated()
    }

    async function payAll() { //TODO: all this shit needs to move to an action that gets the voucher, creates the payment if applicable, and pays with it
      let updatedSaleItemRows = posVC.updatePromotions();
      await PositiveTS.Components.PosPaymentButtons.openVoucherId(PositiveTS.Service.Hakafa.getVoucherID() , 'amount');
     
      posVC.saleUpdated(updatedSaleItemRows);	
      await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
      await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.putAllAmountToPayInAmountField();
      await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.verifyAndPay();
    }

    async function returnHakafa(){
      await PositiveTS.Components.PosPaymentButtons.returnHakafa();
    }
   
  
}}}




  

  


