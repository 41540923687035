module PositiveTS {
export module Components {
export module FullSearch {

  const componentSelector = "#full-search"
  const I18n = {
    ERROR_IN_SEARCH: i18next.t("errorOccurred")
  }

  function _initjQueryDialogIfNeeded() {
    var that = this;
    if (!this._initialized) {
      //TODO: find a more elegant way to open a dialog or create a vue modal...
        $(componentSelector).dialog({
          autoOpen: true,
          modal: true,
          dialogClass: 'positive-dialog',
          width: Pinia.globalStore.mobileLayout ? '100vw' : '90%',
          height:  Pinia.globalStore.mobileLayout ? $(window).height() : 600,
          position: Pinia.globalStore.mobileLayout ? { my: "top", at: "top", of: window} : { my: "center", at: "center", of: window },
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });

      this._initialized = true
    }





    this.$refs.itemCode.focus();

  }


  function cleanData() {
    let result = initData();
    for (let prop in result) {
      this[prop] = result[prop];
    }
  }


  function close() {
    $(componentSelector).dialog('close');
    this.cleanData()
    this.removeListeners();
    $(document).unbind('keypress');
    $(document).keypress(posVC.onKeyPress);
  }

  function open() {
    this._initjQueryDialogIfNeeded();
    $(document).unbind('keypress');
    $(componentSelector).dialog('open');
    this.addListeners();
    // this.rows = [];
    this.$refs.itemCode.focus();
  }

  


  
  function initData(){
    return {
      _initialized: false,
      itemCode: "",
      lastItemCode: "",
      rows: [],
      selectedRowIndex: 0,
      fullSearchElem: null,
      restaurantNewPOS: jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS),
    };
  }


  

  function showInv(row:itemList){
    Pinia.componentsStore.openComponent( {
      componentName: 'itemInventoryItm', 
      args: [{
      itemCode:row.code, itemDescription: row.description, 
      color: row.color, size:row.size,currentInventory:row.currentInventory }, false, false]});
  }


   interface itemList {
      code: string;
      description: string;
      priceZarhan: number;
      promoBuy: string;
      barcode: string;
      size: string;
      color:string;
      supplierName: string;
      currentInventory: number;
      promotions?: Array<{code: string, description: string}>
    }

    const _maxFuzzySearchRows = 12;

  function moveResult(direction:string) {
    if (!this.rows || this.rows.length < 2) {
      return;
    }
    if (this.fullSearchElem == null) {
      this.fullSearchElem = document.getElementById('full-search-search-results');
      
    }
    switch(direction) {
      // case "ArrowDown":
        
      //   if (this.selectedRowIndex < this.rows.length-1) {
      //     this.selectedRowIndex += 1;
      //   }
      //   // this.fullSearchElem.scrollTop = 37*(this.selectedRowIndex+1);
      //   if (this.selectedRowIndex >= 11) {
      //     (<any>document.getElementById(`full-search-result-${this.selectedRowIndex}`)).scrollIntoView(false)
      //   }
      //   break;
      // case "ArrowUp":

      //   if (this.selectedRowIndex > 0) {
      //     this.selectedRowIndex -= 1;
      //   }
      //   (<any>document.getElementById(`full-search-result-${this.selectedRowIndex}`)).scrollIntoView(false)
      //     // if (this.selectedRowIndex >= 11) {
      //       // document.getElementById(`full-search-result-${this.selectedRowIndex}`).scrollIntoView()
            
      //     // }
        
      //   break;
      case "ArrowDown":
      case "ArrowUp":
        this.fullSearchElem.focus();
        break;
      default:
        break;
    }
  }


  function keyDownListener(event) {
    let aThis = PositiveTS.VueInstance.$refs.fullSearch
    aThis.moveResult(event.key)
  }

  function addListeners() {
    document.addEventListener('keydown',keyDownListener)
  }

  function removeListeners() {
    document.removeEventListener('keydown',keyDownListener)
  }

  function addItemToSale(index) {
    let aThis = PositiveTS.VueInstance.$refs.fullSearch

    if (aThis.rows && aThis.rows[index] ) {
      posVC.addItemByBarcodeOrByCode(aThis.rows[index].barcode || aThis.rows[index].code,true)
      aThis.close();
    }
  }


  export function create() {

    let component = {
        template: JST.fullSearch(),
        mixins: [Mixins.modalsMixin],
        methods: {
          _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
          open: open,
          close: close,
          async searchItem () {
            try{
              if (this.itemCode.length < 2 || this.itemCode == this.lastItemCode) {
                return
              }

              // search by code or barcode
              app.showLoadingMessage()
              this.selectedRowIndex = -1;
              this.rows = [];
              if (this.fullSearchElem == null) {
                this.fullSearchElem = document.getElementById('full-search-search-results');
                this.fullSearchElem.scrollTop = 0;
              }
              
              let result = await this.getItemSearch(this.itemCode)
              this.rows = result;
              this.$refs.itemCode.focus();
              this.lastItemCode = this.itemCode;
              app.hideLoadingMessage()
            }catch(e){
              console.error(e)
              app.hideLoadingMessage()
              this.showAlertGeneric(I18n.ERROR_IN_SEARCH);
            }
          },

          async getItemSearch (search:string) {
            try{
              let result = await Service.AllItems.exactSearch(search);
              if (result.length > 0) {
                return result;
              }
              
              result = await this.fuzzySearch(search);
              return result
            }catch(e){
              console.error(e)
            }
          },
      
          async fuzzySearch (search):Promise<itemList[]>{
            let _return: itemList[] = [];

            if (session.pos.isRoshemet) {
              let items = await appDB.localItems.filter(item => item.code.includes(search) || 
                (item.barcode != null && item.barcode.includes(search)) || item.description.includes(search)).toArray()
              for (let i = 0; i < items.length; i++) { 
                let item = items[i]

                let row: itemList = {
                  code: item.code,
                  description: item.description,
                  priceZarhan: item.priceZarhan,
                  promoBuy: item.promoBuy,
                  barcode: item.barcode,
                  size: "",
                  color: "",
                  supplierName: "",
                  currentInventory: item.currentInventory
                }

                if (this.isShowPromotionsOnSearchItemPos){
                  row.promotions = await this.promotionsByPromotionString(item.promoBuy)
                }

                _return.push(row)
              }
            }
            else {
              let sql = `
              select item.code, item.description, item.priceZarhan, item.promoBuy,
                    itembarcode.barcode, itembarcode.size, itembarcode.color, item.supplierName
              from item left join itembarcode on item.code = itembarcode.code
              where
                (item.cannotBeSoldSeparately IS NULL OR item.cannotBeSoldSeparately = 0) AND
                (upper(item.code) like '${search}%' or upper(item.code) like '%${search}' or
                upper(itembarcode.barcode) like '${search}%' or upper(itembarcode.barcode) like '%${search}' or
                upper(item.description) like '%${search}%') 
              order by item.description limit 300`;
              
              let result = await PositiveTS.Service.WasmDB.promiseSql(sql)
              
              for (let i=0; i< result.length; i++) {
                let item = result[i]                  
                let row: itemList = {
                  code: item.code,
                  description: item.description,
                  priceZarhan: item.priceZarhan,
                  promoBuy: item.promoBuy,
                  barcode: item.barcode,
                  size: item.size,
                  color: item.color,
                  supplierName: item.supplierName,
                  currentInventory: 0
                }
                if (this.isShowPromotionsOnSearchItemPos){
                  row.promotions = await this.promotionsByPromotionString(item.promoBuy)
                }
                _return.push(row)
              }
            }

            return _return
          },

          async promotionsByPromotionString (promotionString:string):Promise<{code:number, description:string}[]> {
            let promotionCodes = promotionString.split("&")
            let _return = [];

            session.allPromotions.forEach(promotion => {
              if (promotionCodes.includes(promotion.code)){
                _return.push({
                  code: promotion.code,
                  description: promotion.name,
                })
              }
            })

            return _return;      
          },

          async showPromo (row:itemList) {
            let result = await this.promotionsByPromotionString(row.promoBuy)
Pinia.componentsStore.openComponent( {componentName:"itemPromoList", args: [result, row]})
          }, 

          showInv: showInv,
          moveResult: moveResult,
          addListeners: addListeners,
          removeListeners: removeListeners,
          addItemToSale: addItemToSale,
          cleanData: cleanData,
        },
        setup(){

          const globalStore = Pinia.useGlobalStore();
          const {portraitMode,isRoshemet,mobileLayout} = Pinia.storeToRefs(globalStore);
          const globalStoreProps = {portraitMode,isRoshemet,mobileLayout}

          return {...globalStoreProps}
        },
        computed: {
          isShowPromotionsOnSearchItemPos () {
            return jsonConfig.getVal(jsonConfig.KEYS.showPromotionsOnSearchItemPos)
          },
        },
        data: initData,

    }

    VueApp.component('full-search',component)

  }


}}}
