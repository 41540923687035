module PositiveTS {
export module Promotions {
export module Templates {
export class BuyXGetDiscountOnX extends TemplateAbstract {
  constructor(initParameters) {
    super(initParameters)
  }

  calculatePromotions() {
      // Go over all the sale items and apply all the promotions from template 3 to them
      for (var i = 0; i < this.parameters.saleItems.length; i++) {
        this.findBestPromotionForSaleItem(this.parameters.saleItems[i]);
      }
  }

  findBestPromotionForSaleItem(saleItem:Storage.Entity.SaleItem) {
    // Ignore sale items that have item discount not from promotions
    if (saleItem.discountType !== Storage.Entity.SaleItem.DiscountType.NULL && saleItem.getPromotionCode === -1) {
        return;
    }

    // Get all the buy promotions for this sale item
    var promoBuy = this.parameters.saleItemBuyPromotions[saleItem.id];

    // Determine, from all the template 3 promotions available (if available), what is the best one for this item
    var bestPromotionCode = -1;
    var bestDiscountType = -1;
    var bestDiscountValuePrecent = 0;
    var bestDiscountValueAmount = 0;
    for (var j = 0; j < promoBuy.length; j++) {
        if (posUtils.isDefined(this.parameters.promotionsByCode[promoBuy[j]]) && this.parameters.promotionsByCode[promoBuy[j]].template === '3') {
            // Get the promotion
            var currentPromotion = this.parameters.promotionsByCode[promoBuy[j]];

            // -- This promotion is from template 3
            var result = this.calculatePromotionForSaleItem(currentPromotion, saleItem);

            // If this promotion gives the best value, take it!
            if (result.discountValueAmount > bestDiscountValueAmount || result.discountValuePrecent > bestDiscountValuePrecent) {
                bestPromotionCode = currentPromotion.code;
                bestDiscountType = result.discountType;
                bestDiscountValueAmount = result.discountValueAmount;
                bestDiscountValuePrecent = result.discountValuePrecent;
            }
        }
    }

    // If a promotion was found for this sale item, then apply it to the item
    if (bestPromotionCode !== -1) {

        var key = this._generateUniqueKey(saleItem, bestPromotionCode, 'buy');
        this.parameters.saleItemsPotentialPromotions[key].discountAbsoluteValue     = parseFloat(session.fixedNumber(bestDiscountValueAmount));
        this.parameters.saleItemsPotentialPromotions[key].discountPrecentValue      = session.fixedNumber(bestDiscountValuePrecent);
        this.parameters.saleItemsPotentialPromotions[key].discountType              = bestDiscountType;
        this.parameters.saleItemsPotentialPromotions[key].isPromotionGiven          = true;
        //create the promoGroup for the single item
        var itemsCounter = this.addToItemsCounter(saleItem,{})
        var promoGroup = {
            item: this.parameters.saleItemsPotentialPromotions[key],
            itemsCounter: itemsCounter,
            promotion: this.parameters.promotionsByCode[bestPromotionCode],
            discountAmountForGroup: this.parameters.saleItemsPotentialPromotions[key].discountAbsoluteValue,
            totalPriceForItemsBeforeDiscount: saleItem.quantity * saleItem.unitPrice
        };
        this.parameters.promoGroups.push(promoGroup);
    }
  }

  calculatePromotionForSaleItem(promotion:Storage.Entity.Promotion, saleItem:Storage.Entity.SaleItem):any {
    // -- This promotion is from template 3
    // Calculate the sale item price
    var saleItemPrice = saleItem.quantity * saleItem.unitPrice;

    // Update data for this sale item + promotion combo
    var key = this._generateUniqueKey(saleItem, promotion.code, 'buy');
    this.parameters.saleItemsPotentialPromotions[key].quantityForAllItemsInPromotion = saleItem.quantity;
    this.parameters.saleItemsPotentialPromotions[key].priceForAllItemsInPromotion = saleItemPrice;

    // Check that we have the minimum required amount and quantity
    if (saleItemPrice < Number(promotion.minimumBuyAmount) || saleItem.quantity < Number(promotion.minimumBuyQuantity)) {
        // - Item's price is lower than minimum buy amount or quantity is not enough, skip!
        return {};
    }

    // Calculate the number of times to give the promotion
    var timesToGivePromotionBuyQuantity = Math.floor(saleItem.quantity / promotion.minimumBuyQuantity);

    var timesToGivePromotionBuyAmount = Math.floor(saleItemPrice / promotion.minimumBuyAmount);

    var timesToGivePromotion = timesToGivePromotionBuyQuantity;

    if (timesToGivePromotionBuyAmount < timesToGivePromotion) {
        timesToGivePromotion = timesToGivePromotionBuyAmount;
    }

    // Calculate the discount value in precent
    var discountValuePrecent = 0;
    var discountValueAmount = 0;
    var discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL;
    switch (promotion.discountType) {
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
            // If sale item's price is lower than the discount value, ignore this promotion
            if (saleItemPrice < timesToGivePromotion * promotion.discountValue) {
                // -- Sale item's price is lower than the discount value
                return {};
            }

            // -- Sale item's price is higher or equal to the discount value
            // Calculate the discount value
            discountValueAmount = timesToGivePromotion * promotion.discountValue;
            discountValuePrecent = discountValueAmount / saleItemPrice * 100;
            discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT;
            break;
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
            var amountAfterDiscount = (saleItem.quantity * saleItem.unitPrice) - (timesToGivePromotion * (promotion.discountValue * saleItem.unitPrice / 100));

            // Calculate the discount value
            discountValueAmount = saleItemPrice - amountAfterDiscount;
            discountValuePrecent = discountValueAmount / saleItemPrice * 100;
            discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT;
            break;
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
            var discountValue = Number(saleItem.unitPrice)*Number(promotion.minimumBuyQuantity) - Number(promotion.discountValue);
            // If sale item's price is lower than the discount value, ignore this promotion
            if (saleItemPrice < timesToGivePromotion * discountValue) {
                // -- Sale item's price is lower than the discount value
                return {};
            }

            // -- Sale item's price is higher or equal to the discount value
            // Calculate the discount value
            discountValueAmount = timesToGivePromotion * discountValue;
            discountValuePrecent = discountValueAmount / saleItemPrice * 100;
            discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT;
            break;
        default:
            console.log('Unknown discount type... (' + promotion.discountType + ') template #3');
            break;
    }

    return {
        discountValueAmount:    discountValueAmount,
        discountValuePrecent:   discountValuePrecent,
        discountType:           discountType
    }
  };

}
}}}
