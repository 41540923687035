module PositiveTS {
    export module Printing {
        export module FlightReports {
            const sumsWidth = [10, 10];

            export function printFlightSumLine(flight, leg) {
                let aThis = printer;

                aThis.printTableRow([i18next.t('flightsInventoryReport.journeyKey'), `${flight ? flight.id : ''}`], sumsWidth);
                aThis.printTableRow([i18next.t('flightsInventoryReport.legNumber'), `${leg ? leg.leg_number + 1 : ''}`], sumsWidth);
                aThis.printTableRow([i18next.t('flightsInventoryReport.flightNumber'), `${leg ? leg.airline_symbol + leg.flight_number : ''}`], sumsWidth);
                aThis.printTableRow([i18next.t('flightsInventoryReport.date'), `${moment(leg ? leg.take_off_time : (flight ? flight.take_off_time : new Date())).format('DD/MM/YY')}`], sumsWidth);
                aThis.printTableRow([i18next.t('flightsInventoryReport.origin'), `${leg ? leg.origin_airport.symbol : ''}`], sumsWidth);
                aThis.printTableRow([i18next.t('flightsInventoryReport.destination'), `${leg ? leg.destination_airport.symbol : ''}`], sumsWidth);
            }

            export function printFlightUserSumLine(employee) {
                let aThis = printer;


                aThis.printTableRow([i18next.t('flightsInventoryReport.user'), `${employee && employee.name || ''}`], sumsWidth);
                aThis.printTableRow([i18next.t('flightsInventoryReport.printed'), `${moment().format('HH:mm DD/MM/YY')}`], sumsWidth);
            }

            export function printFlightInventoryReport(storeName, flight, leg, barsetItems, employee) {
                let aThis = printer;
                let zebra = aThis.jzebra;
                let tableWidths = [
                    aThis.lineLength - (aThis.narrowNumCol * 3),
                    aThis.narrowNumCol,
                    aThis.narrowNumCol,
                    aThis.narrowNumCol,
                ];

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                aThis.printLine(storeName);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bold);
                aThis.printLine(i18next.t('flightsInventoryReport.title'));
                aThis.printLine(i18next.t('flightsInventoryReport.' + (leg.is_opened ? 'endCount' : 'startCount')));
                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);

                for (let stockUnitType in barsetItems) {
                    for (let stockUnitNumber in barsetItems[stockUnitType]) {
                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);


                        for (let drawerNumber in barsetItems[stockUnitType][stockUnitNumber]) {
                            // Print

                            let stockUnit = i18next.t(`barset.stockUnitTypes.${stockUnitType}`) + ` ${parseInt(stockUnitNumber) < 9 ? '0' : ''}${parseInt(stockUnitNumber) + 1}`;
                            let drawer = i18next.t('barset.drawer') + ` ${parseInt(drawerNumber) < 9 ? '0' : ''}${parseInt(drawerNumber) + 1}`;

                            zebra.append(aThis.allowComponents.init);
                            zebra.append(aThis.allowComponents.bold);

                            aThis.printLine(`${stockUnit} - ${drawer}`);

                            aThis.printTableEnglishRow([i18next.t('flightsInventoryReport.tableHeaders.product'),
                            i18next.t('flightsInventoryReport.tableHeaders.packed'),
                            i18next.t('flightsInventoryReport.tableHeaders.counted'),
                            i18next.t('flightsInventoryReport.tableHeaders.diff')], tableWidths);
                            zebra.append(aThis.allowComponents.init);

                            for (let itemNumber in barsetItems[stockUnitType][stockUnitNumber][drawerNumber]) {
                                let item = barsetItems[stockUnitType][stockUnitNumber][drawerNumber][itemNumber];

                                aThis.printTableEnglishRow([
                                    String(item.description).trim(),
                                    String(item.calculated),
                                    String(item.counted),
                                    String(item.counted - item.calculated)
                                ], tableWidths);
                            }

                            aThis.printHr();
                            zebra.append(printer.allowComponents.newLine);
                        }

                    }
                }

                printFlightSumLine(flight, leg);
                printFlightUserSumLine(employee);

                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);
                aThis.printLineCut();
                aThis.print();
                zebra.append(aThis.allowComponents.init);
            }

            function printFlightLegsItemsAndPayments(data) {
                let aThis = printer;
                let zebra = aThis.jzebra;

                let tablesTitles = [i18next.t('flightsCloseLegReport.flightsCloseLegReportTable.name'), i18next.t('flightsCloseLegReport.flightsCloseLegReportTable.sum'), i18next.t('flightsCloseLegReport.flightsCloseLegReportTable.count')];
                let currencyTableTitles = [i18next.t('flightsCloseLegReport.flightsCloseLegReportCurrenciesTable.type'), i18next.t('flightsCloseLegReport.flightsCloseLegReportCurrenciesTable.currency'), i18next.t('flightsCloseLegReport.flightsCloseLegReportCurrenciesTable.sum'), i18next.t('flightsCloseLegReport.flightsCloseLegReportCurrenciesTable.creditCount')];
                let tableWidth = [aThis.firstDefaultColWidth - 1, aThis.secondDefaultColWidth, aThis.thirdDefaultColWidth + 1];
                let currencyTableWidth = [aThis.firstDefaultColWidth4Columns - 3, aThis.secondDefaultColWidth4Columns, aThis.thirdDefaultColWidth4Columns + 3, aThis.fourthDefaultColWidth4Columns];
                let paymentsDisplayNameByMethod = {};

                // TOTAL ITEMS
                zebra.append(aThis.allowComponents.init);
                aThis.printLine(" ");
                zebra.append(aThis.allowComponents.bold);
                zebra.append(aThis.allowComponents.smallLineSpace);
                aThis.printHeader(i18next.t('flightsCloseLegReport.itemSales'));
                aThis.printHeader(aThis.getTableRow(tablesTitles, tableWidth));

                zebra.append(aThis.allowComponents.init);
                for (let item of data.itemsSales) {
                    if (item.total != 0) {
                        let values = [String(item.desc), String(session.fixedNumber(item.sum, 2)), String(item.amount)];
                        if (!printer.isRTL) {
                            aThis.printTableEnglishRow(values, tableWidth);
                        } else {
                            aThis.printTableRow(values, tableWidth);
                        }
                    }
                }

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.bold);
                printer.printHr();

                aThis.printTableRow([i18next.t('flightsCloseLegReport.totalItemsSum'), session.fixedNumber(data.totalSalesSum), String(data.totalSalesAmount)], tableWidth)
                // END TOTAL ITEMS

                // TOTAL PAYMENTS
                let totalPaymentSum = 0, totalPaymentAmount = 0;

                let payments = {};

                for (let method in data.payments) {
                    paymentsDisplayNameByMethod[method] = data.payments[method].displayName;
                    if (data.payments[method].isInXZ && data.payments[method].amount) {                  // && ! PositiveTS.Service.SmartVoucher.isPaymentmethodSpecialitem(method) ) {
                        payments[method] = data.payments[method];
                    }
                }

                if (Object.keys(payments).length) {
                    zebra.append(printer.allowComponents.newLine);
                    zebra.append(printer.allowComponents.newLine);

                    aThis.printHeader(i18next.t('flightsCloseLegReport.totalPayments'));
                    aThis.printHeader(aThis.getTableRow(tablesTitles, tableWidth))

                    for (let method in payments) {
                        totalPaymentSum += payments[method].amount;
                        totalPaymentAmount += payments[method].count || 0;

                        let amount = payments[method].amount;
                        amount = amount || 0;

                        let values = [String(payments[method].displayName),
                        String(session.fixedNumber(amount)),
                        String(payments[method].count || 0)]
                        if (amount != 0) {
                            aThis.printTableRow(values, tableWidth)
                        }

                    };


                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.bold);
                    printer.printHr();

                    aThis.printTableRow([i18next.t('flightsCloseLegReport.totalPaymentsSum'), session.fixedNumber(totalPaymentSum), String(totalPaymentAmount)], tableWidth)
                    // END TOTAL PAYMENTS

                    // CURRENCIES PAYMENTS
                    if (Object.keys(data.paymentsByCurrencies).length) {
                        zebra.append(printer.allowComponents.newLine);
                        zebra.append(printer.allowComponents.newLine);

                        aThis.printHeader(i18next.t('flightsCloseLegReport.currencyPayments'));
                        aThis.printHeader(aThis.getTableRow(currencyTableTitles, currencyTableWidth))

                        for (let method in data.paymentsByCurrencies) {
                            for (let currency in data.paymentsByCurrencies[method].currencies) {
                                let values = [
                                    String(paymentsDisplayNameByMethod[method]),
                                    String(currency),
                                    String(session.fixedNumber(data.paymentsByCurrencies[method].currencies[currency].amount || 0, 2)),
                                    String(data.paymentsByCurrencies[method].currencies[currency].creditCount || 0),
                                ];

                                aThis.printTableRow(values, currencyTableWidth);
                            }

                        };

                        printer.printHr();
                        zebra.append(printer.allowComponents.newLine);
                    }
                    // END CURRENCIES PAYMENTS
                }
            }

            export function printFlightLegsSumReport(data, storeName, flight, employee) {
                let aThis = printer;
                let zebra = aThis.jzebra;

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                aThis.printLine(storeName);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bold);
                aThis.printLine(i18next.t('flightsCloseLegReport.title'));
                zebra.append(printer.allowComponents.newLine);

                let legs = data['-1'].legs;

                for (let leg of legs) {
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(printer.allowComponents.newLine);

                    printFlightSumLine(flight, leg);
                }

                for (let legNumber of Object.keys(data).sort()) {

                    if (legs.length == 1 && parseInt(legNumber) == -1) {
                        continue;
                    }

                    let currentData = data[legNumber]

                    zebra.append(printer.allowComponents.newLine);
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.centerAlignment);
                    zebra.append(aThis.allowComponents.bigFont);

                    if (legs.length > 1){
                        aThis.printLine(parseInt(legNumber) == -1 ? i18next.t('flightsCloseLegReport.allLegs') : i18next.t('flightsCloseLegReport.legNumber') + ' ' + (parseInt(legNumber) + 1));
                    }
                    
                    aThis.printLine(i18next.t('flightsCloseLegReport.saleTypes.all') + ' ' + i18next.t('flightsCloseLegReport.sales'));

                    printFlightLegsItemsAndPayments(currentData);


                    for (let saleType in currentData.bySaleType) {
                        zebra.append(printer.allowComponents.newLine);
                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.centerAlignment);
                        zebra.append(aThis.allowComponents.bigFont);
                        aThis.printLine(i18next.t('flightsCloseLegReport.saleTypes.' + saleType.toString()) + ' ' + i18next.t('flightsCloseLegReport.sales'));

                        printFlightLegsItemsAndPayments(currentData.bySaleType[saleType]);
                    }


                }

                zebra.append(aThis.allowComponents.init);
                zebra.append(printer.allowComponents.newLine);
                printFlightUserSumLine(employee);

                zebra.append(printer.allowComponents.newLine);
                aThis.printLineCut();
                aThis.print();
                zebra.append(aThis.allowComponents.init);
            }

            export function printFlightCashierStatement(storeName, flight, leg, currencies, employee, isFinal, baseCurrency, totals, times = 1) {
                let aThis = printer;
                let zebra = aThis.jzebra;

                for (let i = 0; i < times; i++) {
                    printFlightCashierStatemnetData(storeName, flight, leg, currencies, employee, isFinal, baseCurrency, totals);
                }

                aThis.print();
                zebra.append(aThis.allowComponents.init);

            }

            function printFlightCashierStatemnetData(storeName, flight, leg, currencies, employee, isFinal, baseCurrency, totals) {
                let aThis = printer;
                let zebra = aThis.jzebra;
                let columnWidth = parseInt(((aThis.lineLength - (aThis.narrowNumCol + 1)) / 3).toString());
                let tableWidths = [
                    aThis.narrowNumCol + 1,
                    columnWidth,
                    columnWidth,
                    columnWidth,
                ];

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                aThis.printLine(storeName);

                let calculatedAmount = totals['calculated'];
                let currencyAmount = totals['currencyAmount'];
                let diffAmount = totals['diffAmount'];
                let creditAppendants = totals['creditAppendants'];
                let creditPaymetsSum = totals['creditPaymetsSum'];

                let status = i18next.t('flightsWizard.cashierStatement.equal');

                if (diffAmount > 0) {
                    status = i18next.t('flightsWizard.cashierStatement.overpay');
                }
                if (diffAmount < 0) {
                    status = i18next.t('flightsWizard.cashierStatement.underpay');
                }


                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bold);
                aThis.printLine(i18next.t('flightsCashierStatementReport.title'));
                aThis.printLine(i18next.t(isFinal ? 'flightsCashierStatementReport.approvedReport' : 'flightsCashierStatementReport.notApprovedReport'));
                aThis.printLine(i18next.t(status));
                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.bold);


                // Print
                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.bold);


                aThis.printTableEnglishRow([i18next.t('flightsCashierStatementReport.tableHeaders.currency'),
                i18next.t('flightsCashierStatementReport.tableHeaders.expected'),
                i18next.t('flightsCashierStatementReport.tableHeaders.actual'),
                i18next.t('flightsCashierStatementReport.tableHeaders.diff')], tableWidths);
                zebra.append(aThis.allowComponents.init);

                for (let currency of currencies) {
                    if ((currency.calculated || 0) == 0 && (currency.currencyAmount || 0) == 0 && (currency.currencyAmount || 0) == 0) {
                        continue;
                    }

                    aThis.printTableEnglishRow([
                        String(currency.code).trim(),
                        String(parseFloat(currency.calculated || 0).toFixed(2)),
                        String(parseFloat(currency.currencyAmount || 0).toFixed(2)),
                        (parseFloat(currency.currencyAmount || 0) - parseFloat(currency.calculated || 0)).toFixed(2)
                    ], tableWidths);
                }

                aThis.printHr();

                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);

                aThis.printTableRow([i18next.t('flightsWizard.cashierStatement.ccReceipts'), creditAppendants], sumsWidth);
                aThis.printTableRow([i18next.t('flightsWizard.cashierStatement.creditPayments',  { currency: baseCurrency }), session.fixedNumber(creditPaymetsSum, 2)], sumsWidth);
                aThis.printTableRow([i18next.t('flightsWizard.cashierStatement.expInCurr', { currency: baseCurrency }), session.fixedNumber(calculatedAmount, 2)], sumsWidth);
                aThis.printTableRow([i18next.t('flightsWizard.cashierStatement.actInCurr', { currency: baseCurrency }), session.fixedNumber(currencyAmount, 2)], sumsWidth);
                aThis.printTableRow([i18next.t('flightsWizard.cashierStatement.diffInCurr', { currency: baseCurrency }), session.fixedNumber(diffAmount, 2)], sumsWidth);
                aThis.printTableRow(['', status], sumsWidth);
                zebra.append(printer.allowComponents.newLine);

                printFlightSumLine(flight, leg);
                printFlightUserSumLine(employee);

                zebra.append(printer.allowComponents.newLine);
                aThis.printLineCut();
            }

            function printPaymentLines(payment) {
                let aThis = printer;
                let widths = [6, 4, 8, 1, 9];
                let data = [];
                let classes = ['left pad-left', null, null, null, 'left'];

                const posCurrency = Service.MultiCurr.getInstance().getPosCurrency();

                var cash_in_curr_ary = JSON.parse(payment.data);
                if (Array.isArray(cash_in_curr_ary) && cash_in_curr_ary.length) {

                    for (var i = 0; i < cash_in_curr_ary.length; i++) {
                        let currencyData = Service.MultiCurrencyPayments.convertCurrencyPaymentToStandard(cash_in_curr_ary[i], payment.method);

                        if (currencyData.paid_currency_symble == posCurrency) {
                            const amountPay = session.fixedNumber(currencyData.paid_currency_amount);

                            data = [amountPay, posCurrency, '', '=', `${amountPay} ${posCurrency}`]
                        } else {
                            data = [session.fixedNumber(currencyData.paid_currency_amount),
                            currencyData.paid_currency_symble,
                            `(${((posPaymentVC.Currencies[currencyData.paid_currency_symble] || 1)).toFixed(4)})`,
                                '=',
                            session.fixedNumber(currencyData.lead_currency_amount) + ' ' + posCurrency]
                        }

                        aThis.printTableRow(data, widths, classes);
                    }
                }
                //pay in pos currency
                else {
                    const amountPay = session.fixedNumber(payment.amount)

                    data = [amountPay, posCurrency, '', '=', `${amountPay} ${posCurrency}`]
                    aThis.printTableRow(data, widths, classes);
                }
            }

            export function printInvoicePayments(payments, paymentTypes, isCreditSale?) {
                let aThis = printer;
                var zebra = aThis.jzebra;

                let paymentByMethod = _.groupBy(payments, payment => payment.method == Storage.Entity.SalePayment.METHOD_CHANGE ? Storage.Entity.SalePayment.METHOD_CASH : payment.method);

                for (let paymentMethod in paymentByMethod) {
                    if (!(paymentByMethod[paymentMethod] && paymentByMethod[paymentMethod].length)) {
                        continue;
                    }

                    aThis.printHeader(`${i18next.t('printing.payments')} - ${paymentTypes[paymentMethod].displayName}`);
                    aThis.printHr();

                    zebra.append(aThis.allowComponents.init);

                    for (let payment of paymentByMethod[paymentMethod]) {
                        if (payment.method == Storage.Entity.SalePayment.METHOD_CHANGE) {

                            zebra.append(aThis.allowComponents.newLine);
                            zebra.append(aThis.allowComponents.init);
                            zebra.append(aThis.allowComponents.bold);
                            zebra.append(aThis.allowComponents.bigFont);
                            zebra.append(paymentTypes[payment.method].displayName);
                            zebra.append(aThis.allowComponents.init);
                        }

                        printPaymentLines(payment);
                    }

                    zebra.append(aThis.allowComponents.newLine);
                }
            }

            export function printIsrairFlightInventoryReport(storeName, flightCode, items, isStratCount, countDataByCode, itemsStartCountByCode = null, itemsSoldByCode = null) {
                let aThis = printer;
                let zebra = aThis.jzebra;
                let tableWidths = isStratCount ? [4, 1] : [3, 1, 1, 1, 1];

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                aThis.printLine(storeName);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bold);
                aThis.printLine(i18next.t('flightsInventoryReport.title'));
                aThis.printLine(isStratCount ? i18next.t('flightsInventoryReport.startCount') : i18next.t('flightsInventoryReport.endCount'));
                aThis.printLine(i18next.t('flight') + " " + flightCode);
                aThis.printLine(moment(new Date()).format("DD/MM/YYYY HH:mm:ss"));
                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.bold);


                let titles = isStratCount ?
                    [
                        i18next.t('flightsInventoryReport.tableHeaders.product'),
                        i18next.t('flightsInventoryReport.tableHeaders.countedLong'),
                    ] : [
                        i18next.t('flightsInventoryReport.tableHeaders.product'),
                        i18next.t('flightsInventoryReport.tableHeaders.start'),
                        i18next.t('flightsInventoryReport.tableHeaders.sold'),
                        i18next.t('flightsInventoryReport.tableHeaders.countedLong'),
                        i18next.t('flightsInventoryReport.tableHeaders.diffLong'),
                    ]

                aThis.printTableRow(titles, tableWidths);

                zebra.append(aThis.allowComponents.init);

                for (let item of items) {

                    // if empty don't print
                    if (isStratCount) {
                        if (parseInt(countDataByCode[item.code] || 0) == 0) {
                            continue;
                        }
                    } else {
                        if (parseInt(itemsStartCountByCode[item.code] || 0) == 0 && parseInt(itemsSoldByCode[item.code] || 0) == 0 && parseInt(countDataByCode[item.code] || 0) == 0) {
                            continue;
                        }
                    }

                    let data = isStratCount ? [
                        String(item.description),
                        String(parseInt(countDataByCode[item.code] || 0))
                    ] : [
                            String(item.description),
                            String(parseInt(itemsStartCountByCode[item.code] || 0)),
                            String(parseInt(itemsSoldByCode[item.code] || 0)),
                            String(parseInt(countDataByCode[item.code] || 0)),
                            String(parseInt(String((countDataByCode[item.code] || 0) - ((itemsStartCountByCode[item.code] || 0) - (itemsSoldByCode[item.code] || 0)))))
                        ];

                    aThis.printTableRow(data, tableWidths);
                }

                zebra.append(printer.allowComponents.newLine);
                aThis.printLineCut();
                aThis.print();
                zebra.append(aThis.allowComponents.init);
            }

            export function printCurrenciesRatesReport(currencies, storeName, flight, leg, employee) {
                let aThis = printer;
                let zebra = aThis.jzebra;

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                aThis.printLine(storeName);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bold);
                aThis.printLine(i18next.t('flightsCurrenciesReport.title'));
                zebra.append(printer.allowComponents.newLine);
                zebra.append(aThis.allowComponents.init);


                printFlightSumLine(flight, leg);
                printFlightUserSumLine(employee);

                zebra.append(aThis.allowComponents.init);
                zebra.append(printer.allowComponents.newLine);
                zebra.append(printer.allowComponents.newLine);

                aThis.printFloatingLine(i18next.t('flightsCurrenciesReport.conversion'), i18next.t('flightsCurrenciesReport.rate'))

                let widths = [2,2,6,5,4,3];
                let data = [];
                let classes =  [null, null, null, null, 'left pad-left', null];

                for (let currencyCode in currencies) {
                    if (currencyCode == Service.MultiCurr.getInstance().getPosCurrency()) {
                        continue;
                    }

                    data = [String(currencies[Service.MultiCurr.getInstance().getPosCurrency()]), 'X', Service.MultiCurr.getInstance().getPosCurrency(), '=', session.fixedFloat(currencies[currencyCode], 4).toFixed(4), currencyCode];
                    aThis.printTableRow(data, widths, classes);
                }



                zebra.append(aThis.allowComponents.init);
                zebra.append(printer.allowComponents.newLine);

                aThis.printFloatingLine(i18next.t('flightsCurrenciesReport.conversion'), i18next.t('flightsCurrenciesReport.rate'))

                for (let currencyCode in currencies) {
                    if (currencyCode == Service.MultiCurr.getInstance().getPosCurrency()) {
                        continue;
                    }

                    data = ['1', 'X', currencyCode, '=', session.fixedFloat(Service.MultiCurr.getInstance().translateAmount(1, currencyCode), 4).toFixed(4), Service.MultiCurr.getInstance().getPosCurrency()];
                    aThis.printTableRow(data, widths, classes);
                }




                zebra.append(printer.allowComponents.newLine);
                aThis.printLineCut();
                aThis.print();
                zebra.append(aThis.allowComponents.init);
            }


        }
    }
}
