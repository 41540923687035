module PositiveTS {
  export module QueueWorker {
    export module Utils {

      export var isOnline = true;
      export var token:string

      export function initQueues(token:string, dbVersion:number, isOnline:boolean) {
        Utils.isOnline = isOnline;
        workerDB = new QueueWorker.WorkerDB(dbVersion);
        Utils.token = token;
        PositiveTS.SalesQueue.launchQueueExt();
        PositiveTS.ItemsQueue.launchQueueExt();
        PositiveTS.EmployeesQueue.launchQueueExt();
        PositiveTS.EmployeeHoursQueue.launchQueueExt();
        PositiveTS.DepartmentsQueue.launchQueueExt();
        PositiveTS.DeliveryQueue.launchQueueExt();
        PositiveTS.SuspiciousActivityLogWorker.launchQueueExt();
        PositiveTS.CashStatementQueue.launchQueueExt();
        PositiveTS.PromotionsQueue.launchQueueExt();
        PositiveTS.ExternalOrdersQueueWorker.launchQueueExt();
        PositiveTS.ElalDocNumberQueue.launchQueueExt();
      }
  
      export async function fetchSaleItemAndPaymentsBySaleIDs(saleIDs:string[]):Promise<any[]> {
        let sales = await workerDB.sales.where('id').anyOf(saleIDs).toArray()
        //we sort the result by timestamp mainly for the use of the sales queue
        return sales.sort((a,b) => a.timestamp - b.timestamp);
      };

      export async function fetchBySyncStatuses(syncStatuses, limit = 20) {
        let sales = await workerDB.sales
          .where('syncStatus')
          .anyOf(syncStatuses)
          .sortBy('timestamp');
        
        return sales.slice(0, limit);
      };
    }
  }
}
