module PositiveTS {
  export module Components {
      export module PromotionManage {
  
        export const route = "/promotion-manage";
        
        export function getComponent() {
          
          return {
            template: JST.PromotionManage(),
            computed: {
              mobileLayout(){
                return Pinia.globalStore.mobileLayout
              },
            },
            data () {
              return {
                search: '',
                items: [],
                filteredItems: [],
                itemsPerPage: 25,
                page: 0,
                totalItems: 0
              }
            },

             created (){
              this.setItems() 

              const templates = [
                {id: 14, name: i18next.t("promotionManage.templateNameKneBe")},
                {id: 15, name: i18next.t("promotionManage.templateNameHaZolMe")},
                {id: 12, name: i18next.t("promotionManage.templateNameKneKabel")}
              ]

              const discountTypes = [
                  {name: i18next.t("promotionManage.discountTypeAmount"), type: 'Amount'},
                  {name: i18next.t("promotionManage.discountTypePercent"), type: 'Percent'},
                  {name: i18next.t("promotionManage.discountTypeFix"), type: 'Fix'}
              ]

              Pinia.promotionsManageStore.SET_TEMPLATES(templates)
              Pinia.promotionsManageStore.SET_DISCOUNT_TYPES(discountTypes)

              this.searchDebounce = _.debounce(this.filterItems, 100)

            },

            watch : {

              search () {
                this.searchDebounce()
              }
              
            },

            methods:{

              async setItems (){
                this.items = await appDB.promotions.toArray()
                this.filterItems()
              },

              async filterItems(initPage = true) {

                if (initPage) {
                  this.page = 0;
                }
                //let isActive = (this.btns.filter( row =>{return row.isSelected})[0].id == "active")
                this.filteredItems = _.sortBy(this.items.filter(item => 
                  
                    //Boolean(item.locked) == !isActive && 
                    (item.name.includes(this.search) || (item.code && item.code.includes(this.search))) 
                  ),(pr) => Number(pr.code))
                
                this.totalItems = this.filteredItems.length;
                this.filteredItems = this.filteredItems.slice(this.page*this.itemsPerPage,this.page*this.itemsPerPage+this.itemsPerPage)
              },

              nextPage() {
                if (((this.page+1)*this.itemsPerPage) < this.totalItems) {
                  this.page += 1;
                  this.filterItems(false);
                }
              },

              prevPage() {
                if (this.page >= 1) {
                  this.page -= 1;
                  this.filterItems(false);
                }
              },

              getTemplateNameById (id){

                let name = ''

                switch (id){

                  case '14':
                    name = i18next.t('promotionManage.templateNameKneBe')
                  break;

                  case '15':
                    name = i18next.t('promotionManage.templateNameHaZolMe')
                  break;

                  case '12':
                    name = i18next.t('promotionManage.templateNameKneKabel')
                  break;

                }

                return name
                
              },

              editItem (item){
                VueServices.Router.goto(AddOrEditPromotion.route, {id: item.id})
              },

              back () {
                VueServices.Router.goto(ItemManage.route)
              },

              addNewPromotion () {
                VueServices.Router.goto(AddOrEditPromotion.route)
              }

            }
          }
          
        }
        
      }
    }
  }
  