module PositiveTS {
  export module Service {
    export module LogicalPrinterBonPrint {

      interface BON_TYPES_INTERFACE {
        REGULAR: 0,
        MOVE_ITEMS: 1,
      }

      export const BON_TYPES: BON_TYPES_INTERFACE = {
        REGULAR: 0,
        MOVE_ITEMS: 1,
      }

      export function bonPrinterMapDialog() {
        return Pinia.componentsStore.openComponent( { componentName: "bonPrinterMapDialog", args: [] })
          .then(response => {

            let commands = [];

            if (response.approved) {
              for (let row of response.modifiedRows) {
                commands.push(`update LogicalPrinter set physicalName='${row.physicalName}', originalPhysicalName='${row.originalPhysicalName}' where id=${row.id}`)
              }

              let obj = new PositiveTS.Storage.Entity.LogicalPrinter()
              obj.clearCache();
            }

            if (commands.length) {
              return PositiveTS.Service.WasmDB.execAndSave(commands.join(';'));
            }
          });
      }

      export function updateSaleItemsBonPrinted(fullSale) {
        for (let index = 0; index < fullSale.saleItems.length; index++) {
          let item = fullSale.saleItems[index];
          if ((item.bonPrintedAt == null || item.hasChanges) && (item.item.logicalOrGroupPrinters != "[]" == true)) {
            item.bonPrintedAt = moment().format("HH:mm");

            if (item.children != null) {
              for (let index = 0; index < item.children.length; index++) {
                let child = item.children[index];
                child.bonPrintedAt = moment().format("HH:mm");

                if (child.children != null) {
                  for (let cindex = 0; cindex < child.children.length; cindex++) {
                    const childchild = child.children[cindex];
                    childchild.bonPrintedAt = moment().format("HH:mm");
                  }
                }
              }
            }
          }
        }
      }

      export function getParentItemIfChildIsSelected(saleItem): Entity.SaleItem {
        if (saleItem.isChild && saleItem.level == 1 && saleItem.parentRowNumber > 0) {
          return Pinia.globalStore.saleItems.find(item => item.rowNumber == saleItem.parentRowNumber)
        } else {
          return saleItem
        }
      }


      export async function printBons(fullSale: FullSale, reprint = false, ignorePrintingOfOnlySelectedSaleItems = true,moveDalpakPrint = false): Promise<any> {
        let bonItems
        let printOnlySelectedItems = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection) && Pinia.globalStore.multipleSelectedSaleItems.length > 0 && !ignorePrintingOfOnlySelectedSaleItems
        let selectedItemsToPrint = []

        if (printOnlySelectedItems) {
          bonItems = []

          for (const saleItem of Pinia.globalStore.multipleSelectedSaleItems) {
            let itemToPrint = getParentItemIfChildIsSelected(saleItem)
            selectedItemsToPrint.push(_.cloneDeep(itemToPrint))
          }

          selectedItemsToPrint = _.uniqBy(selectedItemsToPrint, 'rowNumber')

          bonItems = selectedItemsToPrint.filter(item => item.bonPrintedAt == null).map(item => _.cloneDeep(item)) //clone deep
        } else {
          bonItems = fullSale.saleItems.filter(item => item.bonPrintedAt == null).map(item => _.cloneDeep(item)) //clone deep
        }

        let isUpdateBon = fullSale.sale["hasUpdatedGroupItems"]
        if (reprint || isUpdateBon) {
          let saleItems = printOnlySelectedItems ? selectedItemsToPrint : fullSale.saleItems
          bonItems = saleItems.map(item => _.cloneDeep(item))
        }

        return sendBonToLogicalPrinters(fullSale.sale, bonItems, fullSale.salePayments, false, reprint, moveDalpakPrint)
          .catch((e) => {
            Service.Logger.error(e);
            app.showAlert({
              header: i18next.t('error'),
              content: `שגיאה בעת הדפסת בונים\n השגיאה היא: ${e.message}`,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            }, null, null);
          })
      }

      export async function saleHasBonItems(sale: Storage.Entity.Sale): Promise<Boolean> {
        let saleItems = sale.items
        let bufferToItemsMap = await getItemsInPrintFormation(saleItems)
        for (let [buffer, bufferItems] of bufferToItemsMap.entries()) {
          if (bufferItems.length > 0) {
            return true
          }
        }

        return false;
      }

      //before call this function check order extraData
      export async function sendBonToLogicalPrintersByBonType(sale, saleItems, salePayments, bonType: BON_TYPES_INTERFACE['REGULAR'] | BON_TYPES_INTERFACE['MOVE_ITEMS'], extraData = []) {
        if (Service.PrimaryPosPrint.isPrintBonAtPrimaryPosActice() && !Service.WebsocketSync.SyncServerClient.isPrimaryPos()) {
          let result = await Service.PrimaryPosPrint.sendBonToPrimaryPosPrinter(sale, saleItems, salePayments, [bonType, ...extraData]);

          if (!result.success) {
            throw new Error(result.errorMessage);
          }

          return;
        }

        //before call this function check order extraData
        let printFunc = async (sale, saleItems, printerBuffer, extraData = []) => {
          let printerLogicalName = ''
          let isOrderCancel, isReprint, isUpdate, movedFromDalpakName, toDalpakName = null

          switch (bonType) {
            case BON_TYPES.REGULAR:
              [isOrderCancel, isReprint, printerLogicalName, isUpdate] = extraData
              return await PositiveTS.Printing.Invoice.printBon(sale, saleItems, printerBuffer, isOrderCancel, isReprint, printerLogicalName, isUpdate)
            case BON_TYPES.MOVE_ITEMS:
              [printerLogicalName, movedFromDalpakName, toDalpakName] = extraData
              return await PositiveTS.Printing.Invoice.printUpdateBonAfterMoveItemsDalpak(sale, saleItems, printerBuffer, printerLogicalName, movedFromDalpakName, toDalpakName)
          }
        }

        if (!jsonConfig.getVal(jsonConfig.KEYS.isLogicalPrinter)) {
          return;
        }

        //define var values from extraData by bon type
        if (bonType == BON_TYPES.REGULAR) {
          var [isOrderCancel, isReprint] = extraData
        }

        if (bonType == BON_TYPES.MOVE_ITEMS) {
          var [movedFromDalpakName, toDalpakName] = extraData
        }

        let aThis = printer;
        let zebra = aThis.jzebra;

        if (jsonConfig.getVal(jsonConfig.KEYS.unflattenSaleItemsOnBon)) {
          saleItems = saleItemHelper.unflattenSaleItems(saleItems)
        }
        let bufferToItemsMap = await getItemsInPrintFormation(saleItems)
        let promises = []
        for (let [buffer, bufferItems] of bufferToItemsMap.entries()) {
          const logicalPrinter = await PositiveTS.Storage.Entity.LogicalPrinter.getPrinterByPhysicalName(buffer)

          if (bufferItems.length > 0) {
            zebra.resetPrintBuffer(buffer);

            let itemsToPrintInSameBon = [];
            let extraData = []

            //For now Just need for regular type if need more add it here
            let needLoopBufferItems = bonType == BON_TYPES.REGULAR

            if (needLoopBufferItems) {
              for (let bufferItem of bufferItems) {
                let isItemWithChanges = bufferItem["hasChanges"]

                if (isItemWithChanges) {
                  extraData = [isOrderCancel, isReprint, logicalPrinter.logicalName, true]
                  printFunc(sale, [bufferItem], buffer, extraData)
                  continue
                }

                if (jsonConfig.getVal(jsonConfig.KEYS.printBonItemsOnSeperateBons) && !sale.movedFromDalpak) {
                  extraData = [isOrderCancel, isReprint, logicalPrinter.logicalName]
                  printFunc(sale, [bufferItem], buffer, extraData)
                  continue
                }

                //if not add the bufferItem
                itemsToPrintInSameBon.push(bufferItem)
              }
            } else {
              itemsToPrintInSameBon = bufferItems
            }

            if (itemsToPrintInSameBon.length > 0) {
              if (bonType == BON_TYPES.REGULAR) {
                extraData = [isOrderCancel, isReprint, logicalPrinter.logicalName]
              }

              if (bonType == BON_TYPES.MOVE_ITEMS) {
                extraData = [logicalPrinter.logicalName, movedFromDalpakName, toDalpakName]
              }

              printFunc(sale, itemsToPrintInSameBon, buffer, extraData)
            }

            promises.push(printer.jzebra.print(buffer, buffer, false))
          }
        }

        await Promise.all(promises)
      }

      export async function sendBonToLogicalPrinters(sale, saleItems, salePayments, isOrderCancel = false, isReprint = false ,moveDalpakPrint = false): Promise<any> {
        // we want update the sale if is new sale
        let isPickupSale = Service.Delivery.isTaOrder(JSON.parse(sale.jsondata).delivery?.deliveryType)
        const needToResetSaleAndItemsUpdated = isOrderCancel == false 
          && sale.invoiceSequence == PositiveTS.Storage.Entity.Sale.NULL_INVOICE_SEQUENCE
          && !moveDalpakPrint && !isPickupSale;

        if (Service.PrimaryPosPrint.isPrintBonAtPrimaryPosActice() && !Service.WebsocketSync.SyncServerClient.isPrimaryPos()) {
          let result = await Service.PrimaryPosPrint.sendBonToPrimaryPosPrinter(sale, saleItems, salePayments, [BON_TYPES.REGULAR, isOrderCancel, isReprint]);

          if (!result.success) {
            throw new Error(result.errorMessage);
          }
          
          if (needToResetSaleAndItemsUpdated){
            await resetSaleAndItemsUpdatedFields(sale, saleItems, salePayments)
          }
          return;
        }

        if (!jsonConfig.getVal(jsonConfig.KEYS.isLogicalPrinter)) {
          return;
        }

        var aThis = printer;
        var zebra = aThis.jzebra;

        if (jsonConfig.getVal(jsonConfig.KEYS.unflattenSaleItemsOnBon)) {
          saleItems = saleItemHelper.unflattenSaleItems(saleItems)
        }
        let bufferToItemsMap = await getItemsInPrintFormation(saleItems)
        let promises = []
        for (let [buffer, bufferItems] of bufferToItemsMap.entries()) {
          const logicalPrinter = await PositiveTS.Storage.Entity.LogicalPrinter.getPrinterByPhysicalName(buffer)

          if (bufferItems.length > 0) {
            zebra.resetPrintBuffer(buffer);

            let itemsToPrintInSameBon = [];

            for (let bufferItem of bufferItems) {
              let isItemWithChanges = bufferItem["hasChanges"]

              if (isItemWithChanges) {
                Printing.Invoice.printBon(sale, [bufferItem], buffer, isOrderCancel, isReprint, logicalPrinter.logicalName, true);
              }
              else if (jsonConfig.getVal(jsonConfig.KEYS.printBonItemsOnSeperateBons) && !sale.movedFromDalpak) {
                Printing.Invoice.printBon(sale, [bufferItem], buffer, isOrderCancel, isReprint, logicalPrinter.logicalName);
              }
              else {
                itemsToPrintInSameBon.push(bufferItem)
              }

            }

            if (itemsToPrintInSameBon.length > 0) {
              Printing.Invoice.printBon(sale, itemsToPrintInSameBon, buffer, isOrderCancel, isReprint, logicalPrinter.logicalName);
            }

            promises.push(printer.jzebra.print(buffer, buffer, false))
          }
        }

        await Promise.all(promises)
        if (needToResetSaleAndItemsUpdated){
          await resetSaleAndItemsUpdatedFields(sale, saleItems, salePayments);
        }
      }

      export async function getItemsInPrintFormation(saleItems: Array<Storage.Entity.SaleItem>): Promise<Map<string, Storage.Entity.SaleItem[]>> {

        let allLogicalPrinters = await Storage.Entity.LogicalPrinter.getCache()
        let uniqNamesSet = new Set(allLogicalPrinters.filter(lp => lp.storeDisallowed(session.pos.storeID)).map(lp => lp.physicalName))
        let printerToItemsMap = new Map<string, Storage.Entity.SaleItem[]>()
        uniqNamesSet.forEach((val, index, set) => printerToItemsMap.set(val, []))
        let saleWithUpdatedGroupItems = posVC.sale?.["hasUpdatedGroupItems"]

        for (let [buffer, items] of printerToItemsMap.entries()) {
          for (let saleItem of saleItems) {

            if (saleWithUpdatedGroupItems && !saleItem["hasChanges"] && !posUtils.isBlank(saleItem.bonPrintedAt)) {
              continue
            }

            let itemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(saleItem, allLogicalPrinters)
            if (itemPrinters.indexOf(buffer) > -1) { //push item and all of it's children that match
              printerToItemsMap.set(buffer, AddToBufferWithChildren(saleItem, buffer, items, allLogicalPrinters))
            }
            else { //iterate over children and push if needed
              if (saleItem.children) {
                for (let childItem of saleItem.children) {
                  let itemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(childItem, allLogicalPrinters)
                  if (itemPrinters.indexOf(buffer) > -1) {
                    printerToItemsMap.set(buffer, AddToBufferWithChildren(childItem, buffer, items, allLogicalPrinters))
                  }
                  else { //iterate over grand children and push if needed
                    if (childItem.children) {
                      for (let grandChildItem of childItem.children) {
                        let itemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(grandChildItem, allLogicalPrinters)
                        if (itemPrinters.indexOf(buffer) > -1) {
                          printerToItemsMap.set(buffer, AddToBufferWithChildren(grandChildItem, buffer, items, allLogicalPrinters))
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return printerToItemsMap;
      }

      function AddToBufferWithChildren(saleItem: Storage.Entity.SaleItem,
        buffer: string, bufferItems: Array<Storage.Entity.SaleItem>, allLogicalPrinters: Array<Storage.Entity.LogicalPrinter>) {
        let itemClone = _.cloneDeep(saleItem);
        let saleHasUpdatedGroupItems = posVC?.sale?.["hasUpdatedGroupItems"]
        let itemHasChanges = itemClone["hasChanges"]

        if (itemClone.children) {
          itemClone.children = itemClone.children.filter(childItem => {
            let childItemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(childItem, allLogicalPrinters)
            return childItemPrinters.indexOf(buffer) > -1
          })
          for (let childItem of itemClone.children) {
            if (childItem.children) {
              childItem.children = childItem.children.filter(grandChildItem => {
                let grandChildItemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(grandChildItem, allLogicalPrinters)
                return grandChildItemPrinters.indexOf(buffer) > -1
              })
            }
          }
        }

        if (saleHasUpdatedGroupItems && itemClone.children && itemHasChanges) {
          let hasAnyChildrenWithUpdates = itemClone.children.some(child => child["hasUpdated"])
          let itemBeforeChanges = itemClone.itemBeforeChanges
          if (typeof saleItem.itemBeforeChanges == 'string') {
            itemBeforeChanges = JSON.parse(saleItem.itemBeforeChanges)
          }
          itemClone.itemBeforeChanges.children = itemBeforeChanges.children.filter(childItem => {
            let childItemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(childItem, allLogicalPrinters)
            return childItemPrinters.includes(buffer)
          })

          if (!hasAnyChildrenWithUpdates && itemBeforeChanges.children.length == 0) {
            return []
          }
        }

        bufferItems.push(itemClone)
        return bufferItems;
      }


      async function resetSaleAndItemsUpdatedFields(sale, saleItems, salePayments){
        sale["hasUpdatedGroupItems"] = false;
        saleItems = saleItems.map(si => {
          si["hasChanges"] = false;
          si["hasUpdated"] = false;
          si.itemBeforeChanges = "{}"
          if(si.children){
            si.children = si.children.map(child => {
              child.itemBeforeChanges = "{}"
              child["hasChanges"] = false;
              child["hasUpdated"] = false;
              return child;
            })
          }
          return si;
        })

        sale = await Service.FullSale.persist(sale, saleItems, salePayments);
      }



    }
  }
}
