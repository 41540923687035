module PositiveTS {
  export module Service {
    export module CurrentInventoryReport {
      export async function print() {
        
        try {
          let aThis = printer;
          let zebra = printer.jzebra;
          let totalInv = 0;
          let company = session.company;
          let store = session.store;
          let widths = [17,14,6];
          let depTitles = [i18next.t("currInvReport.itemName"),i18next.t("currInvReport.barcode"),i18next.t("currInvReport.currInv")];
          let values = [];
          app.showLoadingMessage(i18next.t("currInvReport.generatingReport"));
          aThis.printLogo();
          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.centerAlignment);
          
          let company_name = company.companyName;
          aThis.printLine(company_name);
          
          let store_name = store.storeName;
          aThis.printLine(store_name);
          
          if (!posUtils.isNullOrUndefinedOrEmptyString(store.registrationNum)) {
            aThis.printText(aThis.chetPeiAinMem() + `:`);
            if(session.pos.printerType != Printing.PRINTER_TYPE.sunmi){
              aThis.printText(`\t`);
            }
            aThis.printLine(store.registrationNum);
          }

          
          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.centerAlignment);
          zebra.append(aThis.allowComponents.bigFont);
          
          aThis.printLine(i18next.t("currInvReport.title"));
          

          zebra.append(aThis.allowComponents.newLine);

          let allItems = await appDB.localItems.toArray()
          let allDepartments = await appDB.departments.orderBy('[sortOrder+name]').toArray();

          
          for (let dep of allDepartments) {
            zebra.append(aThis.allowComponents.init);
            zebra.append(aThis.allowComponents.rightAlignment);
            let depInv = 0;
            let depItems = _.sortBy(allItems.filter(item => item.departmentId == dep.id),'description');
            if (depItems.length == 0) {
              continue;
            }
            else {
              aThis.printHeader(`${i18next.t("currInvReport.department")} ${dep.name}`);
              aThis.printHeader(aThis.getTableRow(depTitles,widths));
              for (let item of depItems) {
                values = [item.description,item.barcode || "", QuantityToPrint(item)]
                totalInv += item.currentInventory;
                depInv += item.currentInventory;
                aThis.printTableRow(values,widths);
              }
              aThis.printFloatingLine('',`-`.repeat(printer.lineLength));
              zebra.append(aThis.allowComponents.init);
              zebra.append(aThis.allowComponents.bold);
              aThis.printTableRow([`${i18next.t("currInvReport.totalDep")} ${session.fixedNumber(depInv)}`],[printer.lineLength]);
              zebra.append(aThis.allowComponents.newLine);
            }
          }

          let noDepItems = _.sortBy(allItems.filter(item => item.departmentId == -1),'description');
          if (noDepItems.length > 0) {
            let noDepInv = 0;
            aThis.printHeader(i18next.t("currInvReport.withoutDepartment"));
            aThis.printHeader(aThis.getTableRow(depTitles,widths));
            for (let item of noDepItems) {
              values = [item.description,item.barcode || "", QuantityToPrint(item)]
              noDepInv += item.currentInventory;
              totalInv += item.currentInventory;
              aThis.printTableRow(values,widths);           
            }
            aThis.printFloatingLine('',`-`.repeat(printer.lineLength));
            zebra.append(aThis.allowComponents.init);
            zebra.append(aThis.allowComponents.bold);
            aThis.printTableRow([`${i18next.t("currInvReport.totalDep")} ${session.fixedNumber(noDepInv)}`],[printer.lineLength]);
          }

          
                  
          
          zebra.append(aThis.allowComponents.newLine);
          aThis.printHeader(`${i18next.t("currInvReport.totalAll")} ${session.fixedNumber(totalInv)}`);
          printer.printLineCut();
          
          zebra.append(aThis.allowComponents.init);
          
          await zebra.print();
          app.hideLoadingMessage();
        
        }
        catch(e) {
          console.error(e);
          app.hideLoadingMessage();
          let ans = await app.promiseShowAlert({
            header:  i18next.t('error'),
            content: i18next.t("currInvReport.error"),
            continueButtonText: i18next.t("ok"),
          });
        }
      }

      function QuantityToPrint(item) {
        if (item.hasWeight && item.currentInventory < 1000){
         return session.fixedNumber(item.currentInventory)
        }
        else{
          return String(Math.floor(item.currentInventory))
          }
      }
    }
  }
}
