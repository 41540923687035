module PositiveTS {
export module Storage {
export module Entity {
export class ThirdMenuItem extends WasmEntity {


	constructor() {
		let meta = {
			name: 'ThirdMenuItem',
			fields: {
				name: "TEXT",
			 	button_index: "INT",
	      		secondary_menu_item_id: "TEXT",
				color: "TEXT",
				migvanId: "INT",
				pictureUrl: "TEXT",
				translations: "TEXT",
			}
		}
		super(meta)
	}


	async getAll(secondaryMenuItemId): Promise<Array<PositiveTS.Types.MenuButton>> {
		let cols = await PositiveTS.Service.WasmDB.getExistingTableColumns('ThirdMenuItem', ['id', 'name', 'color', 'disallowedStores', 'pictureUrl', 'translations']);
		let result = await Service.WasmDB.promiseSql(`SELECT ${cols.join(', ')} FROM ThirdMenuItem where secondary_menu_item_id=${secondaryMenuItemId} 
			ORDER by button_index asc, name asc` );
		var resArr = []
		for (var i = 0; i < result.length; i++) {
			if (result[i].disallowedStores &&
				JSON.parse(result[i].disallowedStores).indexOf(parseInt(session.store.storeID)) > -1) { continue; }
			if (session.pos.hasFlights && ThirdMenuItem.getNumOfItemsInMenuTree(result[i].id) == 0) {
				continue;
			}
			resArr.push(Object.assign({ level: 1, isItem: false, isThirdMenuItem: true }, result[i]));
		}
		return resArr;
	}

	static getCategoryMenuItems(categoryId:number):ItemMenuItem[] {
		return session.allMenuItems.filter(menuItem => menuItem.thirdMenuItemId == categoryId);
	}

	static getNumOfItemsInMenuTree(categoryId: number): number {
		return this.getCategoryMenuItems(categoryId).length;
	}
}}}}
