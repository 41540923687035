module PositiveTS {
export module Components {
    export module TableView {

        function initData() {
          return {
            giftImg: `${(<any>window).images_path}pos/gift.png`,
            pickupImg: `${(<any>window).images_path}pos/plane12.png`,
            deleteImg: `${(<any>window).images_path}delete-new.png`,
            scrollSelector: '#pos-items-table-view-container .table-view',
            allowMultipleSaleItemsSelection: jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection),
            allowToDisablePromotionsOnItem: jsonConfig.getVal(jsonConfig.KEYS.allowToDisablePromotionsOnItem),
            selfServiceSittingOrTaScreen: jsonConfig.getVal(jsonConfig.KEYS.selfServiceSittingOrTaScreen)
          }
        }


        function selectRow(saleItem) {
          this.selectSaleItem(saleItem)
        }



        export function create() {
            var component = {
                template: JST.tableView(),
                mixins: [Mixins.scrollableMixin],
                name: 'PosItemsTableView',
                props: {
                  uniqueId: {
                    type: String,
                    default: 'n',
                  },
                },
                methods: {
                  processPreparationInstructions(item) {
                    if (this.multiLangEnabled && item.selectedPreparationInstructionsTranslations) {
                      try {
                        const parsedTranslations = JSON.parse(item.selectedPreparationInstructionsTranslations);
                        if (Array.isArray(parsedTranslations)) {
                          return parsedTranslations.map(ins => ins.text[this.currentLang] || ins.text["original"]);
                        }
                      } catch (e) {
                        console.error("Error parsing selectedPreparationInstructionsTranslations", e);
                      }
                    } else if (item.selectedPreparationInstructions) {
                      try {
                        const parsedInstructions = JSON.parse(item.selectedPreparationInstructions);
                        if (Array.isArray(parsedInstructions)) {
                          return parsedInstructions;
                        }
                      } catch (e) {
                        console.error("Error parsing selectedPreparationInstructions", e);
                      }
                    }
                    return [];
                  },
                  getTime(timestamp) {
                    return moment(timestamp).format('HH:mm');
                  },
                  selectRow: selectRow,
                  selectItemAndEditItemWithGroups(saleItem = null) {
                    if (saleItem) {
                      this.saleItemSelected(saleItem);
                    }

                    this.editItemWithGroups();
                  },
                  confirmSaleItemDelete(saleItem) {
                    this.saleItemSelected(saleItem);
                    posVC.confirmSaleItemDelete();
                  },
                  allowDecrement(saleItem) {
                    return !this.canEditItem(saleItem) && saleItem.quantity > 1 && this.canDecrementQuantity(saleItem)
                  },
                  deleteDiscountFromSaleItem: (saleItem, disablePromotions) => posVC.deleteDiscountFromSaleItem(saleItem, disablePromotions),
                  removeCompensation: (saleItem) => posVC.removeCompensationOnItem(saleItem),
                  confirmSaleDiscountDelete: () => posVC.confirmSaleDiscountDelete(),
                  fixedFloat: session.fixedFloat,
                  promotionsForRow(saleItem:Storage.Entity.SaleItem) {
                    let totalWithDiscounts = Number(saleItem.displayTotalNumber);
                    let promoGroups = this.promoGroups.filter((pg:Promotions.PromoGroup) => {
                      return !pg.isSaleDiscount && pg.rowToShowOn == saleItem.rowNumber && pg.discountAmountForGroup != 0;
                    })

                    return promoGroups.map((pg:Promotions.PromoGroup, index) => {
                      totalWithDiscounts -= pg.discountAmountForGroup;
                      return {
                        ...pg,
                        totalToDisplay: this.getTotalAmountString(totalWithDiscounts),
                        isNotLast: index < (promoGroups.length - 1)
                      }
                    });

                  },
                  getTotalAmountString(total:number): string {
                    return `${session.fixedNumber(Math.abs(total))}${total < 0 ? '-' : ''}`;
                  },
                  itemHasRegularPromotions(saleItem) {
                    let itemPromotions = saleItem.promotions.split(";")

                    return itemPromotions.some((promotion) => !promotion.includes("D"))
                  },
                  getSaleDiscounts() {
                    let result = this.promoGroups.filter((pg:Promotions.PromoGroup) => {
                      return pg.isSaleDiscount && pg.discountAmountForGroup;
                    })
                    return result;
                  },
                  openSittingTaDialog() {
                    Pinia.componentsStore.openComponent( {componentName:"selfServiceSittingTaDialog", args: []});
                  },
                  incrementOrDuplicateItem(saleItem){
                    if(saleItem.hasPreparationInstructions || saleItem.hasGroups) {
                      this.duplicateItem(saleItem);
                    } else{
                      this.incrementQuantity(saleItem);
                    }
                  },
                },
                setup(){
                  
                  Vue.watch(() => Pinia.globalStore.saleUpdatedTriggered, (newValue, oldValue) => {
                      setTimeout(() => {
                        let elem = document.querySelector('.table-row.selected')
                        if (elem != null) {
                          (<any>elem).scrollIntoView(false)
                        }
                      },0);
                  });

                  const posStore = Pinia.usePosStore();
                  const {isSaleTA,selfServiceCartTagId} = Pinia.storeToRefs(posStore);
                  const  posStoreProps = {isSaleTA,selfServiceCartTagId}
      
                  const {incrementQuantity,decrementQuantity,editItemWithGroups,duplicateItem} = posStore;
                  const posStoreActions = {incrementQuantity,decrementQuantity,editItemWithGroups,duplicateItem}

                  const globalStore = Pinia.useGlobalStore();
                  const {
                    selfServiceQuickSale,
                    canIncrementQuantity,
                    canDecrementQuantity,
                    canEditItem,
                    canDeleteItem,
                    saleItemsFormatted,
                    saleItems,
                    simpleSelfService,
                    sale,
                    multipleSelectedSaleItems,
                    selectedSaleItem,
                    mobileLayout,
                    saleItemsTableViewMessage,
                    portraitMode,
                    isRoshemet,
                    promoGroups,
                    selfServiceSuperMarket,   
                    rtlMode,               
                    posCustomerNameData,
                  } = Pinia.storeToRefs(globalStore);
                  const globalStoreProps = {
                    saleItemsTableViewMessage,
                    mobileLayout,
                    posCustomerNameData,
                    rtlMode,
                    selfServiceSuperMarket,
                    promoGroups,
                    isRoshemet,
                    portraitMode,
                    multipleSelectedSaleItems,
                    selectedSaleItem,
                    sale,
                    simpleSelfService,
                    saleItems,
                    selfServiceQuickSale,
                    canIncrementQuantity,
                    canDecrementQuantity,
                    canEditItem,
                    canDeleteItem,
                    saleItemsFormatted}

                    
                    const {saleItemSelected,selectSaleItem} = globalStore
                    const globalStoreActions = {saleItemSelected,selectSaleItem}

                  return {...globalStoreActions,...posStoreActions,...posStoreProps,...globalStoreProps}
                },
                data: initData,
                computed: {
                  noSaleItems(){
                    return Pinia.globalStore.saleItems?.length == 0
                  },
                  saleItemsTableViewMessage(){
                    return Pinia.globalStore.mobileLayout ? i18next.t("emptyTableViewMessageMobile") : Pinia.globalStore.saleItemsTableViewMessage
                  },
                  currencySign() {
                    return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                  },
                  hasNewPromotions() {
                    return session.pos.useNewPromotions;
                  },
                  multiLangEnabled(){
                    return Pinia.languageManagerStore.multiLangEnabled
                  },
                  currentLang(){
                    return Pinia.languageManagerStore.currentLang
                  },

                  saleDiscountExists() {
                    let self:any = this;
                    if (self.hasNewPromotions) {
                      return (this.getSaleDiscounts().length > 0);
                    }
                    else {
                      return (self.sale.hasDiscount || self.sale.hasPromotion)
                    }
                  },
                  scaleOSD(){
                    return jsonConfig.getVal(jsonConfig.KEYS.scaleCom) == Service.Scale.S2Scale;
                  },

                  reverseSaleItems() {
                    return this.saleItemsFormatted.slice().reverse()
                  },
                },

                mounted: function() {

                }

            }

            VueApp.component('tableview',component)
        }
    }
  }
}
