module PositiveTS {

    export module Components {

        export module AddOrEditPromotion {

            export module KneKabel{

                export const route = "kne-kabel";
                  
                export function getComponent() {
        
                  return {
                      
                      template: JST.KneKabel(),

                      methods: {
                        ...Components.Mixins.modalsMixin.methods,
                        ...Shared.methods
                      },

                      computed: {

                        buyOptions () {
                          return  Pinia.promotionsManageStore.getBuyOptions 
                        },

                        ...Shared.computed
                      },

                      created () {

                        const buyOptions = [
                          {name: i18next.t("promotionManage.buyOptionAmount"), type: 'amount'},
                          {name: i18next.t("promotionManage.buyOptionQuantity"), type: 'quantity'}
                        ]

                        Pinia.promotionsManageStore.SET_BUY_OPTIONS(buyOptions)

                        if (0 >= this.quantitySal2){
                          Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'quantitySal2',  value: 1})
                        }
                        
                      }

                  }
      
                }

            }
          
         
        }
    }

}
