module PositiveTS {
    
    export module Components {
        
        export module FlightCardDialog {

            const dialogSelector = 'flight-card-dialog'
            const approvalDialogSelector = 'flight-card-approval-dialog'

            interface validateObject {
                valid: Boolean,
                errors: Array<String>
            }

            interface flight {
                id: string,
                barcode: string,
                origin: string,
                reason: string,
                approval: string
            }

            function nextInput (ref: string): void {
                this.$refs[ref].focus()
            }

            function validate (): validateObject {
    
                let errors = []
   
                if (!this.isMultiFlightsMode && posUtils.isBlank(this.flight.origin)){
                    errors.push(i18next.t('flightCardDialog.originNotSelect'))
                }

                if (posUtils.isBlank(this.flight.barcode)){
                    errors.push(i18next.t('validate.required', {input: i18next.t('flightCardDialog.barcode')}))
                }
                const isBlankFlightId = posUtils.isBlank(this.flight.id)
                if (isBlankFlightId){
                    errors.push(i18next.t('validate.required', {input: i18next.t('flightCardDialog.id')}))
                }

                if (!isBlankFlightId && !PositiveTS.Service.DutyFree.isPassportIdValid(this.flight.id)){
                    errors.push(i18next.t('flightCardDialog.idIsNotValid'))  
                }
        
                if (this.isMultiFlightsMode && errors.length == 0 && this.checkIfBarcodeOrIdAlreadyUsed(this.flight)){
                    errors.push(i18next.t('flightCardDialog.barcodeOrIdAlreadyUsed'))
                }

                return {valid: errors.length ? false : true, errors}
            }

            function checkIfBarcodeOrIdAlreadyUsed (flight: flight){
                let _return = false
                const flights = this.getFlightsOnSale()
                for (let i = 0; i < flights.length; i++) {
                    let flightSale = flights[i]
                    if (flight.id == flightSale.id || flight.barcode == flightSale.barcode) {
                        _return = true
                        break;
                    }
                }

                return _return
            }

            function showErrors (errors: Array<string>): void {
                this.showAlertGenericDialog(errors.join("\n"))
                console.log('errors', errors)
            }

            function  confirm (): void {
                
                const validate = this.validate()

                if (validate.valid){
                    const isFlightBarcodeValid = Service.DutyFree.isFlightBarcodeValid(this.flight.barcode)
                
                    if (!isFlightBarcodeValid){
                        this.openApprovalDialog()
                        return 
                    } 
                    
                    this.saveFlightOnSale();
                    if (!this.isMultiFlightsMode || this.isCompletedMultiFlights()){   
                        this.close()
                        if (this.resolveFunc){
                            this.resolveFunc()
                        }
                        this.resetData()
                    }
                }else{
                    this.showErrors(validate.errors)
                }
                
            }

            function getFlightsOnSale () {
                const jsondata = JSON.parse(posVC.sale.jsondata)
                return Array.isArray(jsondata.flights) ? jsondata.flights : [] 
            }

            function isCompletedMultiFlights () {
                if (this.isMultiFlightsMode){
                    return this.flightCardsNumberRequired == 0
                }
                return false  
            }

            function cancel () {
                this.close()
                if (this.rejectFunc){
                    this.rejectFunc()
                }
                this.resetData()
            }

            function backToHome (): void {  
                pNavigator.pushPage('homepage', i18next.t("pageTitle.homepage"), null, null)
                this.close()
                this.resetData()
            }

            function close (closeJustTheDialog: boolean = false): void {
                if (!closeJustTheDialog){
                    $(document).unbind('keypress')
                    document.removeEventListener('keydown', disabledEscape, true)
                    $(document).keypress(posVC.onKeyPress)
                }
                
                if (this.dialogElem == null) {
                    this.setDialogElement()               
                }
                
                this.dialogElem.close()   
            }

            function open (flightCardsNumber: number = null) {

                if (this.dialogElem == null) {
                    this.setDialogElement()               
                }

                this.dialogElem.showModal()

                this.$nextTick(() => {
                    $(document).unbind('keypress')
                    document.addEventListener('keydown', disabledEscape, true)
                })

                if (flightCardsNumber && flightCardsNumber > 0){
                    this.isMultiFlightsMode = true
                    this.flightCardsHasFilled = this.getFlightsOnSale().length 
                    this.flightCardsNumberRequired = flightCardsNumber
                    
                    return new Promise((resolve,reject) => {
                        this.resolveFunc = resolve
                        this.rejectFunc = reject
                    })
                }

            }

            function disabledEscape (e) {
                if (e.key == 'Escape') {
                    e.preventDefault();
                }
            }

            function initData (){
                const data: {
                    dialogElem: any,
                    approvalDialogElem: any,
                    flight: flight,
                    reasons: Array<object>,
                    isMultiFlightsMode: boolean,
                    flightCardsHasFilled: number,
                    flightCardsNumberRequired: number,
                    resolveFunc: Promise<null>,
                    rejectFunc: Promise<null>,
                    imgDrawer: string
                } = {
                    dialogElem: null,
                    approvalDialogElem: null,
                    flight: {id: '', barcode: '', origin: '', reason: '', approval: ''},
                    reasons: [
                        {code: "1", description: "צוות אויר"},
                        {code: "2", description: "לא מצליח לקרוא את הברקוד"},
                        {code: "3", description: "ברקוד פגום"},
                        {code: "4", description: "לא קיים ברקוד – טיסה במעבר"},
                        {code: "5", description: "שירות לקוחות"},  
                        {code: "6", description: "חשבונית ידנית"} 
                    ],
                    isMultiFlightsMode: false,
                    flightCardsHasFilled: 0,
                    flightCardsNumberRequired: 0,
                    resolveFunc: null,
                    rejectFunc: null,
                    imgDrawer: `${(<any>window).images_path}pos/open_drawer.png`
                }
                return data
            }

            function resetData () {
                Object.assign(this.$data, this.$options.data.call(this));
            }

            function setOriginFlight (origin: string){
                this.flight.origin =  origin
                this.nextInput('flightId')
            } 

            function isTourist () {
                return this.flight.origin == 'T' 
            }

            function isIsraeli () {
                return this.flight.origin == 'I' 
            }

            function openDrawer () {
                printer.openDrawer()
            }

            function openCurrencyConversionCalculator () {
Pinia.componentsStore.openComponent( {componentName:"currencyConversionCalculator", args: [true]})
            }

            function openItemSearch () {
                this.close(true)
                PositiveTS.Dialogs.AdvancedItemSearchDialog.getInstance(() => this.open())
            }

            function setReasonAndClose (code: string) {
                this.flight.reason = code
                this.closeApprovalDialog()
                this.close()
                this.approvalManagerDialog()       
            }

            function closeApprovalDialog () {
                this.approvalDialogElem.close()
            }

            function openApprovalDialog () {
                if (this.approvalDialogElem == null) {
                    this.setDialogElement(true)
                }

                this.approvalDialogElem.showModal()
            }

            function saveFlightOnSale () {
                let jsondata = JSON.parse(posVC.sale.jsondata)
                if (!jsondata.hasOwnProperty('flight')){
                    jsondata.flight = this.flight
                }
                if (!jsondata.hasOwnProperty('flights')){
                    jsondata.flights = []
                }
                jsondata.flights.push(this.flight)
                posVC.sale.jsondata = JSON.stringify(jsondata)
                Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
                if (this.isMultiFlightsMode){
                    this.flightCardsNumberRequired -= 1
                }
            }

            function approvalManagerDialog () {
                app.showManagerApprovalDialog()
                    .then((managerEmployee) => {
                        this.flight.approval = managerEmployee.employeeID
                        this.saveFlightOnSale();
                        const isCompletedMultiFlights = this.isCompletedMultiFlights()
                        if (this.isMultiFlightsMode && !isCompletedMultiFlights){
                            this.flight = {id: '', barcode: '', origin: '', reason: '', approval: ''}
                            this.open(this.flightCardsNumberRequired)        
                            return 
                        }
                        if (!this.isMultiFlightsMode || isCompletedMultiFlights){   
                            if (this.resolveFunc){
                                this.resolveFunc()
                            }
                            this.resetData()
                        }
                    })
                    .catch(() => {
                        const flightCardsNumberRequired = this.isMultiFlightsMode ? this.flightCardsNumberRequired : null
                        this.open(flightCardsNumberRequired)                
                    })
            }

            function setDialogElement (approvalDialog: boolean = false) {

                if (approvalDialog){
                    this.approvalDialogElem = (<HTMLDialogElement>document.getElementById(approvalDialogSelector))
                    return 
                }

                this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
            }

            export function create() {
  
                let component = {
                  template: JST.FlightCardDialog(),
                  methods: {
                    ...Mixins.modalsMixin.methods,
                    backToHome,    
                    open,
                    close,
                    confirm,
                    validate,
                    showErrors,
                    nextInput,
                    resetData,
                    setOriginFlight,
                    openDrawer,
                    openCurrencyConversionCalculator,
                    openItemSearch,
                    setReasonAndClose,
                    openApprovalDialog,
                    closeApprovalDialog,
                    saveFlightOnSale,
                    approvalManagerDialog,
                    setDialogElement,
                    cancel,
                    isCompletedMultiFlights,
                    getFlightsOnSale,
                    checkIfBarcodeOrIdAlreadyUsed,
                  },
                  data: initData,
                  computed: {
                    isTourist,
                    isIsraeli
                  },  
                  mounted () {
                      this.setDialogElement()
                  }
                }

                VueApp.component('flight-card-dialog',component)
            }

        }

    }

}