module PositiveTS {
    export module Components {
    export module EmployeeHours {
      export const route = '/employee-hours';

     

      type CompType = any;


      export interface RowUi {
        enterRow?:PositiveTS.Storage.Entity.EmployeeTimeTrack
        exitRow?:PositiveTS.Storage.Entity.EmployeeTimeTrack
      }

      interface CompData {
        timeReportRows:any[],
        selectedDate:Date,
        employeeObj:Storage.Entity.Employee,
        totalHours:string,
        name:string,
        hasAfterThisMonth:boolean,  
      }
    
      export function getComponent() {

        return {
            template: JST.employeeHours(),
            methods: {
              printReport(){
                Printing.Reports.printEmployeeTimeReport(this.name,this.totalHours,moment(this.selectedDate).format("MM/YYYY"),this.timeReportRows);
              },
              async openEditHoursDialog(row:RowUi, index:number){
        
                await Pinia.componentsStore.openComponent( {componentName:"employeeEditHoursDialog", args: [row, this.employeeObj.employeeID, this.employeeObj.serverID]});
                await this.formatReport();
              },

              async addEntry() {
                await  Pinia.componentsStore.openComponent( {
                  componentName: 'employeeEditHoursDialog', 
                  args: [{}, 
                    this.employeeObj.employeeID, 
                    this.employeeObj.serverID, 
                    Storage.Entity.EmployeeTimeTrack.MODE_ENTER]
                  });
                await this.formatReport();
              },

              async addExit() {
                await await  Pinia.componentsStore.openComponent( {
                  componentName: 'employeeEditHoursDialog', 
                  args: [
                    {}, 
                    this.employeeObj.employeeID, 
                    this.employeeObj.serverID, 
                    Storage.Entity.EmployeeTimeTrack.MODE_EXIT
                  ]});
                await this.formatReport();
              },

              async addBoth() {
                await await  Pinia.componentsStore.openComponent( {
                  componentName: 'employeeEditHoursDialog', 
                  args: [{}, this.employeeObj.employeeID, this.employeeObj.serverID]});
                await this.formatReport();
              },
        
              getRowDDMMYYYY(row:RowUi){
                let currentDateObj = row.enterRow || row.exitRow
                return moment(currentDateObj.timestamp).format("DD-MM-YY")
              },
        
              getRowDay(row:RowUi){
                let currentDateObj = row.enterRow || row.exitRow
                return moment(currentDateObj.timestamp).format("ddd")
              },
        
              getRowEnterTime(row:RowUi){
                let _ret = ""
                if (row.enterRow) {
                  _ret = moment(row.enterRow.timestamp).format("HH:mm")
                }
                
                return _ret;     
              },
        
              getRowExitTime(row:RowUi){
                let _ret = ""
                if (row.exitRow) {
                  _ret = moment(row.exitRow.timestamp).format("HH:mm")
                }
                
                return _ret;     
              },      
        
              
              getRowIsManualUpdate(row:RowUi, withNo = false) {                
                let isManualUpdate = ((row.exitRow && row.exitRow.isManualUpdate) || (row.enterRow && row.enterRow.isManualUpdate))
                return isManualUpdate ? i18next.t('yes'): (withNo ? i18next.t('no') : "");
              }, 

              async deleteHours(row:RowUi,index) {
                let ans = await app.promiseShowAlert({
                  header:  i18next.t('employeeHours.title'),
                  content: i18next.t("employeeHours.deleteConfirm"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: false,
                });
                
                if(ans == "cancel"){
                  return;
                }
                else{
                    //TODO: ask for manager card
                    //we don't really delete them as the delete needs to be sent to the sever!
                    if (row.enterRow) {
                      row.enterRow.markedForDelete = true;
                      row.enterRow.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                      await appDB.employeeTimeTracks.put(row.enterRow)
                    }
                    if (row.exitRow) {
                      row.exitRow.markedForDelete = true;
                      row.exitRow.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                      await appDB.employeeTimeTracks.put(row.exitRow);
                    }
                    await this.formatReport();
                }

              },
        
        
              getRowTotalHours(row:RowUi){
                let _ret = ""
                if (row.enterRow && row.exitRow) {
                  _ret = session.fixedNumber(  
                    (moment( new Date(row.exitRow.timestamp) ).diff(moment( new Date( row.enterRow.timestamp) ), 'minutes')/60) ,2 
                  )
                }
                return _ret;     
              },       
        
              async previousMonth(){
                this.selectedDate = moment(this.selectedDate).add(-1, 'M').toDate()
                await this.formatReport()
              },
        
              async nextMonth(){
                this.selectedDate = moment(this.selectedDate).add(1, 'M').toDate()
                await this.formatReport()
              },
        
              async formatReport() {
                let timeReportRows:RowUi[] = []; 
        
                let employeeObj:PositiveTS.Storage.Entity.Employee=this.employeeObj
                let employeePunchedAry = (await appDB.employeeTimeTracks.where('employeeID').equals(employeeObj.employeeID)
                                                .sortBy('timestamp')).filter(emp => !emp.markedForDelete);
        
                let selectedDate = this.selectedDate
                this.hasAfterThisMonth = moment( selectedDate ).endOf("month").toDate().getTime() < 
                                          moment().endOf("month").toDate().getTime();
        
                employeePunchedAry = employeePunchedAry
                    .filter( (row)=>{
                      return row.timestamp > moment( selectedDate ).startOf("month").toDate().getTime() &&
                      row.timestamp < moment( selectedDate ).endOf("month").toDate().getTime()
                    })
                 
                while (employeePunchedAry.length > 0) {
                  let currentTimeTrack = employeePunchedAry.splice(0,1)[0]
                  let nextEntry = employeePunchedAry[0] 
                  let nextEntryModeSameAsCurrent = nextEntry && currentTimeTrack.mode === nextEntry.mode
                  let nextEntryMoreThen20Hr = nextEntry && 
                        moment( currentTimeTrack.timestamp ).diff(moment( new Date( nextEntry.timestamp) ), 'hours') >= 20
        
                  let isNotValidToJoinNextRecord = employeePunchedAry.length === 0 || 
                                nextEntryModeSameAsCurrent || 
                                currentTimeTrack.mode === PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT ||
                                nextEntryMoreThen20Hr
                  
                  if ( isNotValidToJoinNextRecord ){
                    if (currentTimeTrack.mode === PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT) {
                      timeReportRows.push( {enterRow: undefined, exitRow: currentTimeTrack})
                    } else {
                      timeReportRows.push( {enterRow: currentTimeTrack, exitRow: undefined})
                    }
                  } else {
                    let exitRow = employeePunchedAry.splice(0,1)[0]
                    timeReportRows.push( {enterRow: currentTimeTrack, exitRow: exitRow})
                  }
                }
                this.timeReportRows = timeReportRows;
        
                let totalHours = 0
                for(let row of this.timeReportRows ) {
                  let rowTotal = this.getRowTotalHours(row);
                  if(rowTotal){
                    totalHours = totalHours + parseFloat(rowTotal)
                  }
                }
                this.totalHours = session.fixedNumber(totalHours,2);
                 
              },
        
              doConfirm(){
                this.close();
              },
            
              doReject(){
                this.close();
              },
            
              close() {
                VueServices.Router.goto(EmployeeManage.route);
              }
            },
            computed: {
              mobileLayout(){
                return Pinia.globalStore.mobileLayout
              },
            },
            data: initData,
            beforeRouteEnter: async function(to, from, next) {
              app.showLoadingMessage(i18next.t('loading'));
              let employee:Storage.Entity.Employee
              if (to.params.id != null) {
                employee = await appDB.employees.get(to.params.id);
              }
              else {
                return;
              }
              
              next(async (vm) => {
                let self = <CompType>vm;
                
                self.selectedDate = new Date();
                self.employeeObj = employee; 
                self.name = employee.name;
                await self.formatReport();
                app.hideLoadingMessage();
              });
            },
    
    
        }
    
      }
    

      

      function initData():CompData {
        return {
          timeReportRows: [],
          selectedDate: new Date(),
          employeeObj: null,
          totalHours: "0.00",
          name: "",
          hasAfterThisMonth: false,
        };
      }
         
}}}
