module PositiveTS {
    export module Service {
    export module PaiditConvertService {

        const _PaiditServiceLogger = "paidit order";
        export const PaiditExternalCreditCardVoucherCode = "995"
        export const PaiditExternalCashMachineVoucherCode = "994"
        export const PaiditVoucherCode = "996"

        function validOrderNumber(orderNumber) {
            return !Number.isInteger(orderNumber);
        }
      
        export async function convertOrderToSale(order) {
            if(validOrderNumber(order.OrderID)){
               PositiveTS.Service.Delivery.failedDeliveriesLogger("Paidit : invalid order number",order.OrderID,_PaiditServiceLogger, Delivery.FailedDeliveryType.InvalidOrderNumber)
               return;
            }
            
            let orderNumber = Number(order.OrderID),
                deliveryMethod = order.DeliveryMethod, deliveryTypeStr='', deliveryStatus=0;
        
            switch (deliveryMethod) {
                case "Delivery":
                deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalPaiditDelivery,
                deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;    
                break;
              
                case "Pickup":
                deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalPaiditTA;    
                deliveryStatus = PositiveTS.Storage.Entity.Sale.TA_PAID;
                break;

                case "Sitting":
                deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalPaiditSitting;    
                deliveryStatus = PositiveTS.Storage.Entity.Sale.TA_PAID;
                break;

                default :
                PositiveTS.Service.Delivery.failedDeliveriesLogger("Paidit : unregognized Delivery method",order.OrderID,_PaiditServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing)
                return;
            }

            let sale = await Sale.getNewOrder(orderNumber, order.TotalSum);
            let orderData = await PositiveTS.Service.PaiditService.fetchSingleOrder(orderNumber);
            if(!orderData?.Data?.Order?.BillingInfo?.PaymentInfo){
                PositiveTS.Service.Delivery.failedDeliveriesLogger("Paidit : Faild to get single order data", order.OrderID,_PaiditServiceLogger,Delivery.FailedDeliveryType.InvalidData)
               return;
            }

            if (orderData?.Data?.Order?.BillingInfo?.PaymentInfo?.length == 0){ //payment at pos sale
                await Service.PaiditService.updateOrderStatus(orderData.Data.Order.OrderId, 'inprocess');
            }
            if(!validateOrder(orderNumber, orderData)){
                sale.items = []
                return sale;
            }

            let paymentType = orderData.Data.Order.BillingInfo.PaymentInfo[0].PaymentType;
            let currentCustomer = null;
            if(paymentType != "credit" && paymentType != "cashMachine"){
                currentCustomer = await getCustomer(paymentType);
                if(!currentCustomer){
                    PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"No hakafa customer found:", result: paymentType}),orderNumber,_PaiditServiceLogger,Delivery.FailedDeliveryType.HakafaCustomer)
                    throw new Error("No hakafa customer found:" + paymentType);
                } 
            }
            
            let CouponId = '';
            if(orderData.Data.Order.BillingInfo.BillingLines.length > 0){
                CouponId =  orderData.Data.Order.BillingInfo.BillingLines[0].CouponId;
            }

            let streetName = '', apartementNumber='', houseNumber='', enterance='', floor='', cityName = '';
            if(deliveryMethod == "Delivery"){
                streetName = orderData.Data.Order.address.StreetName
                apartementNumber = orderData.Data.Order.address.ApartementNumber
                houseNumber = orderData.Data.Order.address.HouseNumber
                enterance = orderData.Data.Order.address.Enterance
                floor = orderData.Data.Order.address.Floor
                cityName = orderData.Data.Order.address.CityName
            }
           
            let jsondata = JSON.parse(sale.jsondata)
            sale.customerName = order.CustomerName || '';
            sale.customerPhone = orderData.Data.Order.CustomerPhoneNumber || '';
            jsondata["promotions"] = [];
            jsondata["delivery"] = {
            deliveryType: deliveryTypeStr, 
            status: deliveryStatus,
            ordererName: order.CustomerName,
            ordererPhone: orderData.Data.Order.CustomerPhoneNumber,
            ordererCallerRemarks: orderData.Data.Order.address.Remarks,
            ordererDeliveryRemarks: orderData.Data.Order.OrderRemarks,
            //ordererDeliveryCutlery: orderData.Data.Order.Header.userDoNotWantCutlery,
            "deliveryAddress": {
                "address": {
               "name": streetName,
                "value": 0,
                "cityID": 0
                },
                "apartment": apartementNumber,
                "house_number": houseNumber,
                "house_entrance": enterance,
                "house_floor": floor,
                "city": {
                "name": cityName,
                "value": 0, 
                "cityID": 0
                },
            },
            "orderTime": new Date(),
            "PaiditRestID": order.ResID,
            "PaiditRestName": order.ResName,
            // "PaiditExternalPaymentInfo": JSON.stringify(orderData.Data.Order.BillingInfo.PaymentInfo),
            "PaiditCouponId": CouponId,
            "address": "",
            };
            if(paymentType != "credit" && paymentType != "cashMachine") {
                jsondata["customer"] = {
                clubName: "HAKAFA",
                s_id_number: currentCustomer.tz,
                s_first_name: currentCustomer.first_name,
                s_last_name: currentCustomer.last_name,
                dt_birth_date: null,
                s_phone_number_1: currentCustomer.phone,
                customer_group_id: null,
                amount: 0,
                merakez: "",
                db_id: currentCustomer.id,
                is_offline: false,
                is_price_alut: false,
                printer_type: 0,
                discount_percent: 0,
                customer_number: currentCustomer.customer_number,
                obligo: null,
                address: null,
                is_tamash_customer: true,
                is_cibus_tenbis: true
                }
            }
            sale.orderTime = jsondata["delivery"]["orderTime"];
            sale.jsondata = JSON.stringify(jsondata);

            let orderItems = orderData.Data.Order.dishList;

            let items = [], totalQuantity = 0, totalSumItems = 0; 
            orderItems.forEach(function (orderItem, index) {
               
                let currentItem = session.allItems.get(orderItem.CatalogId)
                let instructions = []; 

                if(!Boolean(currentItem)){
                    currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                }

                if(Boolean(currentItem)){
                    let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, {size:'null',color:'null',barcode:null});
                    saleItem.quantity = orderItem.Quantity;
                    saleItem.barcode = saleItem.itemCode;
                    saleItem.saleID = sale.id;
                    saleItem.itemDescription = String(orderItem.DishName).trim();
                    saleItem.unitPrice = orderItem.TotalPrice/orderItem.Quantity; 
                    saleItem.originalUnitPrice = orderItem.DishPrice; 
                    saleItem.hasPreparationInstructions = false;
                    saleItem.rowNumber = index + 1; 
                    totalQuantity += orderItem.Quantity;
                    totalSumItems += orderItem.DishPrice*orderItem.Quantity; 
                    saleItem.children = [];
                    saleItem.priceNetoAfterDiscounts = orderItem.TotalPrice

                    if(currentItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)){
                        saleItem.itemDescription = String(orderItem.DishName).trim();
                    }

                    if(orderItem.DishNotes != "" && orderItem.DishNotes != null && !_.isEmpty(orderItem.DishNotes)){
                        saleItem.hasPreparationInstructions = true;                        
                        saleItem.selectedPreparationInstructions = JSON.stringify([orderItem.DishNotes]);
                    }

                    items.push(saleItem);

                    orderItem?.Choices?.forEach(function (choice) {
                        let itemSubChoices = choice.SubsChosen || [];
                        itemSubChoices.forEach(function (subChoice, subIndex) {
                            let choicePrefix = String(subChoice.CatalogId).substr(0,3);

                            if(choicePrefix == "Ins"){
                                instructions.push(subChoice.Name);
                            }else{

                                let catalogId = extracCatalogId(String(subChoice.CatalogId));
                                    
                                let addItem = session.allItems.get(catalogId);
                                if(!Boolean(addItem)){
                                    addItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                                }
                                if(Boolean(addItem)){
                                    let add = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(addItem, {size:'null',color:'null',barcode:null});
                                    add.rowNumber = 20001 + subIndex; 
                                    add.unitPrice = subChoice.Price; 
                                    add.itemDescription = String(subChoice.Name).trim();
                                    add.quantity = 1;
                                    totalSumItems += subChoice.Price*orderItem.Quantity;
                                    add.originalUnitPrice = subChoice.Price; 
                                    add.barcode = add.itemCode; 
                                    add.priceNetoAfterDiscounts = subChoice.Price*orderItem.Quantity;
                                    add.level = 1;                                       

                                    if(addItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)){
                                        add.itemDescription = String(subChoice.Name).trim();
                                    }

                                    add.parentItemId = items[index].item.id;
                                    add.parentRowNumber = 1;                                        
                                    add.itemGroupId = -1;
                                    add.parent = items[index];
                                    items[index].children.push(add);

                                }else{
                                    PositiveTS.Service.Filelog.log("paidit orders",   JSON.stringify({request:"No api generic item found: ", result:`${subChoice.CatalogId}`}));
                                    items.push({"item_desc": "NOT_FOUND"});
                                } 
                            }
                        
                        })
                    })

                    if(orderItem.Choices.length > 0){
                        if(!_.isEmpty(orderItem.Choices[0])){
                            items[index].hasGroups = 1;
                        }
                    }

                    if(instructions.length > 0){
                        if(items[index].hasPreparationInstructions){
                            let prevInstructions = JSON.parse(items[index].selectedPreparationInstructions) || [];
                            instructions.forEach(function (instructionItem) {
                                prevInstructions.push(instructionItem);  
                            })
                            items[index].selectedPreparationInstructions = JSON.stringify(prevInstructions);
                        }else{
                            items[index].hasPreparationInstructions = true;                        
                            items[index].selectedPreparationInstructions = JSON.stringify(instructions);
                        }
                    }
                }else{
                    PositiveTS.Service.Filelog.log("paidit orders",   JSON.stringify({request:"No Order item found: ", result:`Code:${orderItem.CatalogId} OrderId:${sale.orderNumber}`}));
                    items.push({"item_desc": "NOT_FOUND"});
                }
            });

            let itemsConsists = validateItemsConsist(items);

            if(itemsConsists && items.length > 0){

              let deliveryItem = new Storage.Entity.Item();
              switch (deliveryMethod) {
                  case "Delivery":
                  deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));   
                  deliveryItem.priceZarhan = orderData.Data.Order.restaurant.DeliveryPrice || 0;                  
                  break;
                
                  case "Pickup":
                  deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode));    
                  deliveryItem.priceZarhan = 0;
                  break;

                  case "Sitting":
                  deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.sittingItemCode));    
                  deliveryItem.priceZarhan = 0;
                  break;
              }

              if(Number(orderData.Data.Order.restaurant.DiscountCouponPercent)>0){
                let apiDiscountItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiDiscountItemCode));
                if(!(apiDiscountItem)){
                    PositiveTS.Service.Delivery.failedDeliveriesLogger("Paidit : No tenbis api generic discount item!", order.OrderID,_PaiditServiceLogger,Delivery.FailedDeliveryType.InterfaceItemMissing)
                    throw new Error("No tenbis api generic discount item!");
                }
                let discountItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(apiDiscountItem, {size:'null',color:'null',barcode:null});
                discountItem.rowNumber = items.length + 1;
                discountItem.unitPrice =  (totalSumItems + deliveryItem.priceZarhan - order.TotalSum)*(-1);
                discountItem.priceNetoAfterDiscounts =  discountItem.unitPrice
                discountItem.quantity = 1;
                items.push(discountItem);
              }   

              if(deliveryItem){
                let deliveryItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(deliveryItem, {size:'null',color:'null',barcode:null});
                deliveryItm.rowNumber = 0;
                deliveryItm.priceNetoAfterDiscounts = deliveryItem.priceZarhan
                items.push(deliveryItm);

                sale.items = items;
                sale.totalQuantity = String(totalQuantity);

                let orderPayment = orderData.Data.Order.BillingInfo.PaymentInfo[0]; 
                sale.payments = [];

                let currentPayment = createDeliveryPayment(order, sale, orderPayment); 
                sale.payments.push(currentPayment);

                let sequence = await Storage.Entity.Sequence.getSequenceForInvType(sale.invoiceType);
                sequence.sequence++;

                sale.invoiceSequence = sequence.sequence;
               
                
                 await appDB.sequences.put(sequence) 

                return sale;
            }else{
                PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"No delivery/ta/sitting item in pos", result:`OrderId:${sale.orderNumber}`}),orderNumber,_PaiditServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing,true)
                sale.items = [];
                return sale;
            }
            }else{
                PositiveTS.Service.Filelog.log("paidit orders",   JSON.stringify({request:"Order item/add not found.", result:`OrderId:${sale.orderNumber}`}));
                sale.items = [];
                return sale;
            }

            
        }

        function extracCatalogId(catalogIdStr){
            let pos = catalogIdStr.indexOf("_");
            if(pos == -1){
                return catalogIdStr;
            }else{
                return catalogIdStr.substring(pos+1);
            }
        }

        function createDeliveryPayment(order, sale, orderPayment){
            let currentPayment = new Storage.Entity.SalePayment(); 
            let voucherData = null;

            currentPayment.saleID = sale.id;
            currentPayment.amount = order.TotalSum;

            switch (orderPayment.PaymentType) {
                case "cashMachine":
                    voucherData = [
                        {
                            "amount": order.TotalSum,
                            "barCode": orderPayment.PaymentId,
                            "voucher_type_id": PaiditExternalCashMachineVoucherCode,
                            "smartVoucherType": "CashMachine",
                            "creditType": "CashMachine",
                            "valuTypeId": null,
                            "praxellManpik": null,
                            "mutipassInit": null,
                            "isTamashCustomer": false
                        }
                    ]
                    currentPayment.data = JSON.stringify(voucherData)
                    sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
                    currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
                    break;
                case "credit":
                    voucherData = [
                        {
                        "amount": order.TotalSum,
                        "barCode": orderPayment.PaymentId,
                        "creditType": "ExternalCredit",
                        "voucher_type_id": PaiditExternalCreditCardVoucherCode,
                        "smartVoucherType": "ExternalCredit",
                        "valuTypeId": null,
                        "isTamashCustomer": false,
                        "card_type": 7,
                        "phone_number": "",
                        "card_number": "",
                        "data": JSON.stringify([{
                            "amount": order.TotalSum,
                            "payments_count": "0",
                            "payment_type": "1",
                            "phone_number": "",
                            "return_code": "000",
                            "card_number": "000",
                            "card_type": "1",
                            "valid_until": "",
                            "confirmation_number": "0000000",
                            "token": "5100491900050948",
                            "creditCurrency": "ILS",
                            "tz": "",
                            "success": true,
                            "first_payment": "00001000",
                            "fixed_amount": "00000000",
                            "all_data": {
                                "request": "",
                                "result": ""
                              }
                        }])
                        }
                    ]
                    currentPayment.data = JSON.stringify(voucherData)
                    sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
                    currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
                    break;
              
                case "tenbis":
                    voucherData = [
                        {
                        "amount": order.TotalSum,
                        "barCode": orderPayment.PaymentId,
                        "creditType": "Tenbis",
                        "voucher_type_id": "997",
                        "smartVoucherType": "Tenbis",
                        "valuTypeId": null,
                        "praxellManpik": null,
                        "mutipassInit": null,
                        "isTamashCustomer": true
                        }
                    ]
                    currentPayment.data = JSON.stringify(voucherData);
                    sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV;                    
                    currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
                    break;

                case "cibus":
                    voucherData = [
                        {
                        "amount": order.TotalSum,
                        "barCode": orderPayment.PaymentId,
                        "creditType": "Cibus",
                        "voucher_type_id": "998",
                        "smartVoucherType": "Cibus",
                        "valuTypeId": null,
                        "praxellManpik": null,
                        "mutipassInit": null,
                        "isTamashCustomer": true
                        }
                    ]
                    currentPayment.data = JSON.stringify(voucherData);
                    sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV;
                    currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
                    break;

                case "paidit":
                    currentPayment.method = PositiveTS.METHOD_HAKAFA;
                    voucherData = [
                        {
                        "amount": order.TotalSum,
                        "barCode": orderPayment.PaymentId,
                        "creditType": "Paidit",
                        "voucher_type_id": "996",
                        "smartVoucherType": "Paidit",
                        "valuTypeId": null,
                        "praxellManpik": null,
                        "mutipassInit": null,
                        "isTamashCustomer": true
                        }
                    ]
                    currentPayment.data = JSON.stringify(voucherData)
                    sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
                    currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
                    break;

            }
            
            return currentPayment;
        }

        function validateItemsConsist(items){
            let missingItems = items.filter(function(item) { return (item.item_desc == "NOT_FOUND") }).length;
            return (missingItems==0)
        }

          async function getCustomer(customer){
            let customer_number = "";
            switch (customer) {
                case "tenbis":
                customer_number = "לקוח תן ביס";
                break;

                case "cibus":
                case "pluxee":
                customer_number = "לקוח פלאקסי";
                break;

                case "paidit":
                customer_number = "לקוח פיידאיט"
                break;
            }
            return await Hakafa.getHakafaCustomerByCustomerNumber(customer_number)
          }

         
          function validateOrder(orderNumber, orderData){
            if(!orderData || orderNumber == 0 || orderData==undefined){
                PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"Fetch Single Order Error or OrderNumber is zero:", result:""}),orderNumber,_PaiditServiceLogger,Delivery.FailedDeliveryType.InvalidOrderNumber)
                return false;
            }
            if(orderData.Data.Order.BillingInfo.PaymentInfo.length == 0){
                //payment at pos sale
                return false
            }
            return true;
          }


    }}}    
