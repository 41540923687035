module PositiveTS {
  export module Storage {
  export module Entity {
  //represents an entity that is persisted in Wasm DB
  export class WasmEntity extends Entity { 

    constructor(meta) {
      super(meta);
    }

    // ====================
    // OVERLOADS FUNCTIONS
    // ====================

    async persist(tx?) {
      return;
    }

    async createTable() {
      let sql = this.getCreateTableSql();
      PositiveTS.Service.WasmDB.execAsObject(sql);
    }

    findByIdNew(id): Promise<any> {
      let aThis = this;
      return new Promise((resolve,reject) => {
        Service.WasmDB.promiseSql(`select * from ${this.meta.name} where id = '${id}'`)
        .then((result:any) => {
          if (result.length > 1) {
            reject('more than one row found for uniq id')
          }
          if (result.length == 0) {
            resolve(null)
          }
          else {
            aThis.importFromObject(result[0]);
            resolve(aThis)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    }

    findByIdWithoutPromise(id): Promise<any> {
      return this.execAndConvertResult(`select * from ${this.meta.name} where id = '${id}'`)[0];
    }

    findByKeyValue(key, value): Promise<any> {
      let aThis = this;
      return new Promise(async (resolve,reject) => {
        if (typeof value == 'string'){
          value = `'${value}'`
        }
        let sql = `select * from ${this.meta.name} where ${key} = ${value}`
        try{
          let result: any = await Service.WasmDB.promiseSql(sql)
          if (result.length == 0) {
            resolve(null)
          }
          else {
            aThis.importFromObject(result[0]);
            resolve(aThis)
          }
        }catch(error){
          reject(error)
        }
      })
    }

    all() {
      let query = 'SELECT * FROM ' + this.meta.name;

      return Promise.resolve(this.execAndConvertResult(query));
    }

    allWithoutPromise (){
      let query = 'SELECT * FROM ' + this.meta.name;
      return this.execAndConvertResult(query);
    }
    
    getCache<T extends Entity>(fieldName:string):Promise<T[]> {
      return new Promise((resolve,reject) => {
        if ((this.constructor)[fieldName] != null) {
          resolve((this.constructor)[fieldName])
        }
        else {
          Service.WasmDB.promiseSql(`select * from ${this.meta.name}`)
          .then((result:any) => {
            let cache = []
            for (let i = 0; i < result.length; i++) {
              let entity = new (<any>this).constructor();
              entity.importFromObject(result[i]);
              cache.push(entity)
            }
            (this.constructor)[fieldName] = cache
            return resolve(cache)
          })
        }
      })
    }

    // ====================
    // ADDITIONAL FUNCTIONS
    // ====================

    static execAndConvertResult(query, bindValues?:any) {
      let result = Service.WasmDB.execAsObject(query, bindValues);

      return this.convertResultsToEntity(result);  
    }

    execAndConvertResult(query) {
      return (this.constructor as typeof WasmEntity).execAndConvertResult(query);
    }
  }
}}}
