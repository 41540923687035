module PositiveTS {
  export module Dialogs {
    export interface InputDialogOptionsInterface {
      header:string
      strDefault?:string
      description:string
      inputPlaceHolder:string
      emptyErrorMessage?:string
      tooLongErrorMessage?:string
      showCancelButton:boolean
      isShowKeyboard?:boolean
      showContinue2Button?:boolean
      textContinue2Button?:string
      resultInDictFormat?:boolean
      dialogPosition?:any
      keyboardLayout?:string
      inputValidator?:(value:any) => boolean
      inputLengthValidator?:(value:any) => boolean
      keyboardOffset?:number,
      inputType?:string,
      showSwipeCard?:boolean,
      isPassword?:boolean
    }
    export class InputDialogOptions implements InputDialogOptionsInterface {
      header:string = ""
      strDefault:string = ""
      description:string = ""
      inputPlaceHolder:string = ""
      emptyErrorMessage:string = ""
      tooLongErrorMessage:string = ""
      showCancelButton:boolean = true
      isShowKeyboard:boolean = true
      showContinue2Button:boolean = false
      textContinue2Button:string = ""
      resultInDictFormat:boolean = false
      keyboardLayout:string = undefined
      inputValidator:(value:any) => boolean
      inputLengthValidator:(value:any) => boolean
      inputType = "text"
      showSwipeCard:boolean = false
      isPassword = false
    }
    export class InputDialog {
      protected ui:any
      private initialized = false
      optionsIn:InputDialogOptions

      constructor() { 
          if (this.initialized) {
            return
          }
          this.initialize();
      }

      initialize() {
        this.ui = $( "#input-dialog" ).dialog({
          autoOpen: false,
          modal: true,
          dialogClass: 'positive-dialog',
          width: (()=>{return Pinia.globalStore.mobileLayout}) ? '90%' : '50%',
          resizable: false,
          closeOnEscape: false,
          draggable: false,
          close: function(event,ui) {
            $('#input-dialog-cancel').unbind('click');
            $('#input-dialog-continue').unbind('click');
            $('#input-dialog-2ndContinue').unbind('click');
            $('#input-dialog-swipe-card').unbind('click');
            $('#input-dialog-field').unbind('keypress');
          }
        })
        this.initialized = true
      }

      close() {
        this.ui.dialog('close');
        $('#input-dialog-field').keyboard().getkeyboard().destroy();
      }

      open(options:InputDialogOptionsInterface):Promise<any> {
        if(this.ui.length == 0){
          this.initialize();
        }
        return new Promise((resolve,reject) => {
          // apply the options for te dialog
          this.optionsIn = this.initDialog(options);
          if (this.optionsIn.strDefault) {
            $('#input-dialog-field').val(this.optionsIn.strDefault);
          } else {
            $('#input-dialog-field').val('');
          }

          if(options.showSwipeCard == true){ //TODO RONEN: this should be on only if a parameter is passed in input dialog options
            $('#input-dialog-swipe-card').show();
          }

          if (options.isPassword == true) {
            $('#input-dialog-field').css("-webkit-text-security","disc");
          }
          else {
            $('#input-dialog-field').css("-webkit-text-security","");
          }
          
          $('#input-dialog-field').on('keypress',function(e) {
            if(e.which == 13) {
              $('#input-dialog-continue').click();
            }
          })

          $('#input-dialog-continue, #input-dialog-2ndContinue').click((key) => {
            $('#input-dialog-error-msg').hide();
            var selectedValue = $('#input-dialog-field').val();

            if (!this.optionsIn.inputValidator(selectedValue) && !posUtils.isNullOrUndefinedOrEmptyString(this.optionsIn.emptyErrorMessage)) {
              $('#input-dialog-error-msg').text(this.optionsIn.emptyErrorMessage);
              $('#input-dialog-error-msg').fadeIn('slow').delay(5000).fadeOut('slow');
              return;
            }

            if (this.optionsIn.inputLengthValidator(selectedValue) ) {
              $('#input-dialog-error-msg').text(this.optionsIn.tooLongErrorMessage);
              $('#input-dialog-error-msg').fadeIn('slow').delay(5000).fadeOut('slow');
              return;
            }
            this.close();
            if (this.optionsIn.resultInDictFormat){
              resolve({key: $(key.currentTarget).attr("id"), val:selectedValue});
            } else {
              resolve(selectedValue);
            }
          });

          $('#input-dialog-swipe-card').click((key) => {
            this.swipeCard();
          });

          $('#input-dialog-cancel').click(() => {
            this.close();
            reject(app.userCancelledGlobalMessage);
          });

          this.ui.dialog('open');

        })

      }

      async swipeCard(){
        app.showLoadingMessage("העבר כרטיס");
          let cardNumber = await Service.EMV.swipe();
          if(cardNumber){
            $('#input-dialog-field').val(cardNumber);
            $('#input-dialog-continue').click();
          }
        app.hideLoadingMessage();
      }

      protected initDialog(options) {
        // default settings
        var settings = {
          header: "",
          description: "",
          inputPlaceHolder: "",
          emptyErrorMessage: "",
          tooLongErrorMessage: "",
          showCancelButton: true,
          isShowKeyboard: true,
          inputType: "text",
          inputValidator: function (value) {
            return !posUtils.isNullOrUndefinedOrEmptyString(value);
          },
          inputLengthValidator: (value) => value && value.length > 255 ? true : false
        };

        if (posUtils.isNullOrUndefinedOrEmptyString(options)) {
          options = {};
        }

        options = $.extend(settings, options);

        // set dialog header
        this.ui.dialog("option", "title", options.header);

        if (options.dialogPosition) {
          this.ui.dialog("option", "position", options.dialogPosition);          
        }
        else {
          this.ui.dialog("option","position",{my:'center', at: 'center', of: window})
        }

        this.ui.dialog("option","width",Pinia.globalStore.mobileLayout ? '90%' : '50%')

        // set dialog description
        $('#input-dialog-description').html(options.description);

        // set dialog field place holder text
        $('#input-dialog-field').attr('placeholder', options.inputPlaceHolder);
        if (options.inputType === "password"){
          $("#input-dialog-field").css("-webkit-text-security", "disc");
        } else {
          $("#input-dialog-field").css("-webkit-text-security", "");
        }

        $('#input-dialog-field').prop('type',options.inputType);
        if (options.keyboardOffset == null) {
          $('#input-dialog-field').data('keyboardoffset',65);
        }
        else {
          if (options.keyboardOffset != 0) {
            $('#input-dialog-field').data('keyboardoffset',options.keyboardOffset);
          }
        }
        
        // If this is not a touch device, enable keyboard
        let keyboardLayout = options.keyboardLayout || 'only-numbers';

        let keyboardOptions = {
            layout     : keyboardLayout,
            usePreview : false,
            autoAccept : true,
            position   : {
              of : $('#footer'),
              my : 'center top',
              at : 'center bottom'
            },
            validate: function (keyboard, value, isClosing) { 
              return true; 
            },
            initialized: function(e, keyboard, el) {},
            beforeVisible: function(e, keyboard, el) {},
            visible: function(e, keyboard, el) {},
            beforeInsert: function(e, keyboard, el, textToAdd) {
              return textToAdd; 
            },
            beforeClose: function(e, keyboard, el, accepted) {
            },
            accepted: function(e, keyboard, el) {
            },
            canceled: function(e, keyboard, el) {
            },
            restricted: function(e, keyboard, el) {
            },
            hidden: function(e, keyboard, el) {
            },
            change: function(e,keyboard,el){
            }
          }
        if (keyboardLayout == 'only-numbers') {
          keyboardOptions['css'] = { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" }
          $('#input-dialog-field').attr('type','number');
        }
        else {
          $('#input-dialog-field').attr('type','text');
        }

        if (!Modernizr.touch && options.isShowKeyboard){
          $('#input-dialog-field').keyboard(keyboardOptions)//.addTyping();
        } else {
          let k =$('#input-dialog-field').getkeyboard()
          if (k && k.removeKeyboard){
            k.removeKeyboard();
          }
        }

        // set dialog error message
        $('#input-dialog-error-msg').text(options.emptyErrorMessage);
        $('#input-dialog-error-msg').hide();

        if (options.showCancelButton === true) {
          $('#input-dialog-cancel').show();
        } else {
          $('#input-dialog-cancel').hide();
        }

        $('#input-dialog-2ndContinue .ui-button-text').text(i18next.t('moveNext'))
        if (options.showContinue2Button === true) {
          $('#input-dialog-2ndContinue').show();
          $('#input-dialog-2ndContinue .ui-button-text').text(options.textContinue2Button);
        } else {
          $('#input-dialog-2ndContinue').hide();
        }

        $('#input-dialog-continue .ui-button-text').text(i18next.t('moveNext'));
        $('#input-dialog-cancel .ui-button-text').text(i18next.t('cancel'))


        // set focus on the input field
        $('#input-dialog-field').focus();

        return options;
      }
    }
  }
}

declare var inputDg:PositiveTS.Dialogs.InputDialog;
inputDg = new PositiveTS.Dialogs.InputDialog();
