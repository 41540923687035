module PositiveTS {
  export module Components {
    export module CustomerPointsDialog {

      const _vueComponentName = 'customer-points-dialog';

      export function create() {
        let customerPointsDialog = {
          template: JST.customerPointsDialog(),
          methods: {
            async init() {
              this.blPoints = new Service.CustomerPoints();
              this.maxAllowedAmounts = this.blPoints.getMaxAllowedAmounts();
              this.previousSelectedAmounts = this.blPoints.getPrevousSelectedAmounts();        
              this.customer = this.blPoints.helper.getCurrentSelectedCustomer();
              this.pointsToUse = this.maxAllowedAmounts.points;
            },
            async open() {
              await this.init();
              this.$el.show();

              let aThis = this;

              this.$nextTick(() => this.$refs.pointsToUse.focus())
              $(document).unbind('keypress');

              return new Promise(resolve => {
                aThis.resolve = resolve;
              });
            },
            close(continueToPaymentScreen) {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress); 

              if (this.resolve) {
                this.resolve(continueToPaymentScreen);
              }
            },
            async finish() {
              if (!this.isValidPointsAmount) {
                return;
              }

              await this.blPoints.updateCustomerPointsPaymentData(this.totalMoneyFromPoints, this.pointsToUse);

              this.close(true);
            },
          },
          data() {
            return {
              resolve: null,
              blPoints: null,
              maxAllowedAmounts: 0,
              previousSelectedAmounts: null,
              customer: null,
              pointsToUse: 0,
            };
          },
          computed: {
            totalMoneyFromPoints() {
              return this.pointsToUse * this.maxAllowedAmounts.ratio;
            },
            isValidPointsAmount() {
              if (this.pointsToUse > this.maxAllowedAmounts.points || this.pointsToUse < 0) {
                return false;
              }

              if (this.totalMoneyFromPoints > this.maxAllowedAmounts.amount || this.totalMoneyFromPoints < 0) {
                return false;
              }

              if (this.customerClubMinPointsToUse && this.customerClubMinPointsToUse > 0 && this.pointsToUse < this.customerClubMinPointsToUse) {
                return false;
              } 

              if (this.pointsForUseInMultiplication && this.pointsForUseInMultiplication > 0 && this.pointsToUse % this.pointsForUseInMultiplication != 0) {
                return false;
              }

              return true;
            },
            customerClubMinPointsToUse() {
              return jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse)
            },
            pointsForUseInMultiplication() {
              return jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication)
            },
            stateIsOnline(){
              return Pinia.globalStore.isOnline
            },
          },
        }
        VueApp.component(_vueComponentName, customerPointsDialog)
      }
    }
  }
}
