module PositiveTS {
  export module Storage {
  export module Entity {
  export class PrimaryMenuItem extends WasmEntity {
  
    migvanId:number
    disallowedStores:string
    itemId: number
    name:string
    button_index: number
    hasPicture:boolean
    pictureUrl: string
    translations: string

    get itemCode() { //kombina for pictures in categories
      return this.name;
    }

    constructor() {
      let meta = {
          name: 'PrimaryMenuItem',
          fields: {
            name: "TEXT",
            button_index: "INT",
            color: "TEXT",
            migvanId: "INT",
            disallowedStores: "TEXT",
            itemId: "INT",
            hasPicture: "BOOL",
            pictureUrl: "TEXT",
            translations: "TEXT",
          }
        };
      super(meta)
    }
    
    static dbCache:Array<PrimaryMenuItem> = null
    
    getCache() {
      return super.getCache<PrimaryMenuItem>("dbCache");
    }
  
  
    async getAll(migvanId:number): Promise<PositiveTS.Types.MenuButton[]> {
      let instance = new PrimaryMenuItem();
      let allPmi = await instance.getCache();
      let storeId = parseInt(session.store.storeID);
      let relevantPmi = allPmi.filter(pmi => { 
        let disAllowedStores: Number[] = posUtils.jsonParse(pmi.disallowedStores);
        return pmi.migvanId == migvanId && (disAllowedStores == null || disAllowedStores.every(id => id != storeId)); 
      });
      if (session.pos.hasFlights) {
        let availablePmi: any[] = relevantPmi;
        
        relevantPmi = [];

        for (let pmi of availablePmi) {
          if ((await PrimaryMenuItem.getNumOfItemsInMenuTree(pmi.id)) > 0) {
            relevantPmi.push(pmi)
          }
        }
      }
      relevantPmi = relevantPmi.map(pmi => Object.assign({code: pmi.itemCode, level:1, isItem: false, isPrimaryMenuItem: true},pmi)).sort((a,b) => {
        if (a.button_index == b.button_index) {
          return a.name < b.name ? -1 : 1;
        } else {
          if (a.button_index == null) {
            a.button_index = Number.POSITIVE_INFINITY
          }
          if (b.button_index == null) {
            b.button_index = Number.POSITIVE_INFINITY
          }
          return a.button_index < b.button_index ? -1 : 1;
        }
      });
      return relevantPmi;
    }

    static getCategoryMenuItems(categoryId:number):ItemMenuItem[] {
      return session.allMenuItems.filter(menuItem => menuItem.primaryMenuItemId == categoryId);
    }
  
    static async getNumOfItemsInMenuTree(categoryId: number): Promise<number> {
      let total = this.getCategoryMenuItems(categoryId).length;
  
  
      let subcategories = await (new SecondaryMenuItem()).getAll(categoryId);
  
      for (let subcategory of subcategories) {
        total += await SecondaryMenuItem.getNumOfItemsInMenuTree(subcategory.id);
      }
  
      return total;
    }
  }}}}
  