module PositiveTS {
  export module Components {
    export module SelfServiceTheftDetectApproval {
      export function getComponent() {
        return {
          template: JST.selfServiceTheftDetectApproval(),
          mixins: [Mixins.wizardItemMixin],
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {setCurrentStep} = selfServicePaymentStore;
            return {setCurrentStep}
          },
          methods: {
            async swipeCard() {
              app.showLoadingMessageDialog(i18next.t('caveretPayment.swipeCard'));

              this.cardNumber = await Service.EMV.swipe();
              if (session.pos.isCaveret) {
                await this.validateManagerCaveret()
              } else {
                await this.validateManager()
              }
              app.hideLoadingMessageDialog();

            },
            
            async validateManagerCaveret() {
              if (posUtils.isNullOrUndefinedOrEmptyString(this.cardNumber)) {
                await app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('caveretPasswordDialog.pleaseSwipeCard'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
                return
              }
              try {
                let taz = parseInt(this.cardNumber.substr(4, 9))
                let cardNumber = parseInt(this.cardNumber.substr(13, 8));
                let employee = await PositiveTS.Service.CaveretEmployeeApproval.isEmployeeInStore(taz, cardNumber)

                if (employee) {
                  PositiveTS.VueInstance.$refs.caveretPasswordDialog.magneticCard = cardNumber
                  PositiveTS.VueInstance.$refs.caveretPasswordDialog.taz = taz

                  let password = await Pinia.componentsStore.openComponent( {componentName:"caveretPasswordDialog", args: []})
                  Pinia.globalStore.setSaleApproval(true);
                  this.setCurrentStep("SelfSelectPaymentMethod")
                } else {
                  await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPasswordDialog.noPermission'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }


              } catch (e) {
                this.showError = true

                setTimeout(() => {
                  this.showError = false
                }, 3000)
              }
            },

            async validateManager() {
              const employee = new PositiveTS.Storage.Entity.Employee();
              const permissions = [Storage.Entity.Employee.IS_MANAGEER_MINUS_CONFIRM]
              const employees = await employee.fetchByStoreAndMagneticCardAndPermissions(session.pos.storeID, this.cardNumber, permissions)
              if (employees.length === 0) {
                this.showError = true
                return
              }

              let jsonData = JSON.parse(posVC.sale.jsondata);
              jsonData.selfServiceSupermarketSale = employees[0].magneticCard;
              posVC.sale.jsondata = JSON.stringify(jsonData);

              Pinia.globalStore.setSaleApproval(true);

              this.setCurrentStep("SelfSelectPaymentMethod")
            },

            async toggleKeyboard() {
              this.showKeyboard = !this.showKeyboard

              if (this.showKeyboard) {
                await this.$nextTick();
                
                this.$refs.simpleKeyboardInstance.setNewInput(this.$refs.cardNumberInput)
                this.$refs.simpleKeyboardInstance.setLayout(this.selfServicelayout)
                this.$refs.simpleKeyboardInstance.setDisplay(this.display)
                this.$refs.simpleKeyboardInstance.setCustomFunction(this.validateFunc)
              }
            },

            updateVal(value) {
              this.cardNumber = value
            },
            cleanData() {
              this.cardNumber = "",
                this.taz = "",
                this.showKeyboard = false,
                this.showError = false

            }
          },

          data: function () {
            return {
              showError: false,
              errorMessage: i18next.t("givenCardIsNotmanagerCard"),
              cardNumber: "",
              taz: "",
              resolveFunc: null,
              rejectFunc: null,
              showKeyboard: false,
              posWithPinPad: (jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv) ? true : false,
              isCaveretPos: session.pos.isCaveret,
              validateFunc: null,
              isPasswordProtected: jsonConfig.getVal(jsonConfig.KEYS.passwordProtected),
              selfServicelayout: {  default: [
                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                "/ ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 \\",
                "\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3",
                "\u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . {shift}",
                "{continue} {space}"
              ],
              shift: [
                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                "Q W E R T Y U I O P { } |",
                'A S D F G H J K L : "',
                "Z X C V B N M < > ? {shift}",
                "{continue} {space}"
              ]},
              display: {
                '{continue}': i18next.t('continue'),
                '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
                '{shift}': '<i class="far fa-globe"></i>'
              }
            }
          },
          mounted() {
            this.validateFunc = this.isCaveretPos ? this.validateManagerCaveret : this.validateManager
            if (!this.posWithPinPad) {
              this.$refs.cardNumberInput.focus()
            }
          }
        }
      }
    }
  }
}