module PositiveTS {
export module Storage {
export module Entity {
export class LogicalPrinter extends WasmEntity {

	logicalOrGroupPrinterId:string
	disallowedStores:string
	logicalName:string
	physicalName:string
	originalPhysicalName:string
	ipAddress:string
	isPicturePrint: boolean

	constructor() {
		let meta = {
			name: 'LogicalPrinter',
			fields: {
				logicalOrGroupPrinterId: "TEXT",
			  disallowedStores: "TEXT",
			  logicalName: "TEXT",
			  physicalName: "TEXT",
				originalPhysicalName: "TEXT",
				ipAddress: "TEXT",
				isPicturePrint: "BOOL"
			}
		}
		super(meta)
	}

	static dbCache:Array<LogicalPrinter> = null

	// getCache() {
	// 	return super.getCache<LogicalPrinter>("dbCache");
	// }

	static getPrintersForItem(saleItem:SaleItem, allPrinters:Array<LogicalPrinter>) {
		let item = saleItem.item;
		if (item == null) {
			item = session.allItems.get(saleItem.itemCode)
		}

		let itemLogicalPrinterIds =  posUtils.jsonParseWasmCol(item.logicalOrGroupPrinters);
		let saleWithUpdatedGroupItems = posVC.sale?.["hasUpdatedGroupItems"]
		let printerNames = []

		for (const lp of allPrinters) {
			let disallowedStores = JSON.parse(lp.disallowedStores)
			let isFatherItem = saleItem.children && saleItem.children.length > 0

			if(isFatherItem && !itemLogicalPrinterIds.includes(lp.logicalOrGroupPrinterId) && !disallowedStores.includes(Number(session.pos.storeID))){
				let hasChildAsBonItem = saleItem.children.some(child => child.item.logicalOrGroupPrinters != "[]")

				if (hasChildAsBonItem) {
					let hasDescendantsWithThisPrinter = false

					for (const child of saleItem.children) {
						let childLogicalPrinterIds = JSON.parse(child.item.logicalOrGroupPrinters.replace(/\'/g, '"') )
						hasDescendantsWithThisPrinter = childLogicalPrinterIds.includes(lp.logicalOrGroupPrinterId)
						
						if(hasDescendantsWithThisPrinter) {
							break
						} else {
							let hasGrandChildAsBonItem = child.children && child.children.some(grandChild => grandChild.item.logicalOrGroupPrinters != "[]")

							if(hasGrandChildAsBonItem) {
								for (const grandChild of child.children) {
									let grandChildLogicalPrinterIds = JSON.parse(grandChild.item.logicalOrGroupPrinters.replace(/\'/g, '"') )
									hasDescendantsWithThisPrinter = grandChildLogicalPrinterIds.includes(lp.logicalOrGroupPrinterId)
									if(hasDescendantsWithThisPrinter) {
										break
									}
								}
							}
						}
					}

					if(hasDescendantsWithThisPrinter) {
						printerNames.push(lp.physicalName)
						continue
					}
				}
			}

			if (itemLogicalPrinterIds.includes(lp.logicalOrGroupPrinterId) && !disallowedStores.includes(Number(session.pos.storeID))) {
				printerNames.push(lp.physicalName)
			}
		}

		return printerNames
	}

	storeDisallowed(storeID:string) {
		return JSON.parse(this.disallowedStores).indexOf(parseInt(storeID)) === -1
	} 

	static async getCache():Promise<Array<LogicalPrinter>> {
		if(session.pos.isRoshemet && session.isAndroid){
			const builtInPrinter = new LogicalPrinter();
			builtInPrinter.logicalOrGroupPrinterId = "0_1"
			builtInPrinter.physicalName = "SUNMI",
			builtInPrinter.logicalName = i18next.t('printing.internalSunmiPrinter')
			builtInPrinter.disallowedStores = "[]"

			const wifiPrinter = new LogicalPrinter();
			wifiPrinter.logicalOrGroupPrinterId = "0_2"
			wifiPrinter.physicalName = "WIFI",
			wifiPrinter.logicalName = i18next.t('printing.wifiPrinter')
			wifiPrinter.disallowedStores = "[]"

			
			return [builtInPrinter,wifiPrinter]
		}

		let res = await (new LogicalPrinter()).getCache<LogicalPrinter>("dbCache")
		return (<LogicalPrinter[]> res)
	}

	clearCache() {
		return super.clearCache<LogicalPrinter>("dbCache");
	}


	getPrinterIpAddress() {
		if (this.isOldWifiPrinter()) {
			return PositiveTS.VueInstance.wifiPrinterIp;
		}

		return this.ipAddress;
	}

	// For legacy reasons, this is used to work like this
	isOldWifiPrinter() {
		return this.physicalName == 'WIFI' && PositiveTS.VueInstance.useWifiPrinter;
	}

	isWifiPrinter() {
		return !this.isSunmiPrinter() && !posUtils.isBlank(this.getPrinterIpAddress());
	}

	isSunmiPrinter() {
		return this.physicalName == "SUNMI";
	}

	static isAndroidWifiPrinter(printerPhysicalName: string) {
		if (!session.isAndroid) {
			return false;
		}

		let printer = LogicalPrinter.getPrinterByPhysicalName(printerPhysicalName);

		if (posUtils.isBlank(printer)) {
			return false;
		}

		return printer.isWifiPrinter();
	}

	static getPrinterByPhysicalName(physicalName): LogicalPrinter {
		return this.execAndConvertResult(`select * from LogicalPrinter where physicalName = '${physicalName}'`)[0];
	}

}

}}}
