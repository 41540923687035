module PositiveTS {
  export module Service {

    class OrderClass {
      price:string;
      orderId:string;
      constructor(xml:string){
        this.orderId = $(xml).find("orderID").text();
        this.price = $(xml).find("price").text()
      }

      get json():string{
        return JSON.stringify(this);
      }
    }


    export class Pluxee extends SmartVoucher {
      hasCVV = false

      async getBalance(cardNumber: string, cvv?: string, companyId?: string): Promise<GetBalanceResponse> {
        let response = await PositiveTS.Service.PluxeeService.Soap.getBudgetBalance(companyId, cardNumber);
        if (!response) {
          throw new Error("received response was empty");
        }
        let jQueryRes = $(response);
        let errId = jQueryRes.find("GetBudgetBalanceResult").text();
        if (errId !== "0") {
          return { error: i18next.t(`pluxeeView.err.${errId}`), balance: 0, success: false,rawResponse: response };
        }
        let budgetBalanceStr = jQueryRes.find("budgetBalance").text();
        let budgetBalance = parseFloat(budgetBalanceStr);
        return { balance: budgetBalance, success: true,rawResponse: response, error: '' };
      }


      async pay(voucherData: any, amount: number, cvv?: string, companyId?: string): Promise<any> {
        let cardNumber = voucherData.barCode;
        if (posUtils.isNullOrUndefinedOrEmptyString(cardNumber)) {
          throw new Error(i18next.t("cardNumberCantBeEmpty"));
        }
        try {
          const response = await PositiveTS.Service.PluxeeService.Soap.addOrder(companyId, cardNumber, amount);
          const errId = $(response).find("AddOrderResult").text();
          if (errId !== "0") {
            throw new Error(i18next.t(`pluxeeView.err.${errId}`) +
              '. קוד שגיאה ' +
              "- " +
              errId);
          }
          const order = new OrderClass(response);
          const smartVoucherType = PositiveTS.Service.Pluxee.getSmartVoucherType();
          voucherData.barCode = cardNumber;
          voucherData.actionType = SmartVoucherActionTypes.WITHDRAW;
          voucherData.allowPartialReturn = false;
          voucherData.amount = Number(order.price);
          voucherData.orderId = order.orderId;
          voucherData.companyId = companyId || "";
          voucherData.smartVoucherType = smartVoucherType;
          return voucherData;
        } catch (error) {
          throw error;
        }
      }

      cancelPayment(voucherToCancel: any): Promise<ActionResponse> {
        var oderId:string = voucherToCancel.orderId;
        return PositiveTS.Service.PluxeeService.Soap.cancelOrder(oderId)
        .then((response)=>{
            var errId = $(response).find("CancelOrderResult").text();
            if (errId !== "0"){
              throw new Error(`Err: ${errId} ${i18next.t(`pluxeeView.err.${errId}`)}`)
            }
            return ({
              success: true,
              error: ""
            });

        });

      }

      cancelLoad(paymentToCancel): Promise<any> {
        return Promise.reject("Not implemented");
      }

      getCardNumber(): Promise<any> {
        return Promise.reject("NOT IMPLEMENTED");
      }

      loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
        var _return:any = Promise.reject("Not implemented");
        return _return;
      }

      
      /**
       * For Backward compatibility
       * @returns Name of the smart voucher type
       */
      static getSmartVoucherType() {
        var voucher = new PositiveTS.Storage.Entity.Voucher()
        let result = voucher.fetcByStoreAndBySmartVoucherType(session.pos.storeID, PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE)
        if (result.length > 0) {
          return PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE;
        } else { // Pos didn't update to pluxee, so he probably still has cibus
          return PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS;
        }
      }

    }
  }
}
