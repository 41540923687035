module PositiveTS {
  export module Service {
    export module FlightReport {

      export const SALE_TYPES = {
        1: 'Retail',
        2: 'Crew',
        3: 'Complimentary'
      }

      async function getSalesData(sales: Storage.Entity.Sale[]) {
        let baseData = {
          itemsSales: {},
          totalSalesSum: 0,
          totalSalesAmount: 0,
          bySaleType: {}
        }

        let payments = Storage.Entity.SalePayment.fromSalesArray(sales);
        let paymentsByCurrencies =  MultiCurrencyPayments.getPaymentsBySalesAndCurrencies(sales);
        let data = {...baseData, ...(await Helper.ReportsHelper.calculateZReport(payments)), paymentsByCurrencies};

        for (let sale of sales) {
          for (let itemSale of sale.items) {
            data.itemsSales[itemSale.itemCode] = data.itemsSales[itemSale.itemCode] || {
              desc: itemSale.itemDescription,
              sum: 0,
              amount: 0,
              paymentsByCurrencies: null,
            };


            data.itemsSales[itemSale.itemCode].sum += itemSale.priceNetoAfterDiscounts;
            data.itemsSales[itemSale.itemCode].amount += itemSale.quantity;


            data.totalSalesSum += itemSale.priceNetoAfterDiscounts;
            data.totalSalesAmount += itemSale.quantity;
          }
        }

        data.itemsSales = Object.values(data.itemsSales);

        return data;
      }

      function splitBySalesType(sales: Storage.Entity.Sale[]) {
        let byType = {};

        let salesByInvoiceSequence = _.keyBy(sales, currSale => currSale.invoiceSequence);

        for (let sale of sales) {
          let baseSale = sale;

          if (sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE && sale.parentSaleInvoiceSequence && salesByInvoiceSequence[sale.parentSaleInvoiceSequence]) {
            baseSale = salesByInvoiceSequence[sale.parentSaleInvoiceSequence];
          }

          let saleType = Storage.Entity.Discount.isComplimentaryDiscountSale(baseSale.items) ? 
            3 : (Storage.Entity.Discount.isCrewDiscountSale(baseSale.items) ? 2 : 1);

          byType[saleType] = byType[saleType] || [];
          byType[saleType].push(sale);
        }


        return byType;
      }
      

      export async function getLegsData(legs: any[]){
        let legsData;

        let sales = await appDB.sales.where('invoiceSequence').above(-1).toArray();
        let legsIds = legs.map(leg => leg.id)
        sales = sales.filter(sale => legsIds.includes(sale.flightLegId));

        legsData = await getSalesData(sales);

        let salesByType = splitBySalesType(sales);

        legsData.bySaleType = {};

        if (Object.keys(salesByType).length > 1) { 
          for (let type in salesByType) {
            legsData.bySaleType[type] = await getSalesData(salesByType[type]);
          }
        }

        /*
        מידע שיש להוסיף:
        ה. תקבולים לפי סוג אמצעי תשלום ובחלוקה למטבעות
        ח. פירוט מכירות ותקבולים בדומה לס"ק ב'-ו' לפי סוג מכירה 
        */

        return legsData;
      }

      export async function getReportData(legs: any[]){
        let allData = {
          '-1':  {...(await Service.FlightReport.getLegsData(legs)), legs }
        };

        for (let leg of legs) {
          allData[leg.leg_number] = await Service.FlightReport.getLegsData([leg]);
        }

        return allData;
      }

      export async function printLegSumReport(currentEmployee = null) {
        // if Closed leg wizard
        if (!currentEmployee && hasSessionPosEmployee()) {
          currentEmployee = (await Storage.Entity.Employee.fetchByEmployeeID(session.pos.employeeID));
        }
        
        let data = await Service.FlightReport.getReportData(Pinia.flightsStore.currentTeamLegs);

        Printing.FlightReports.printFlightLegsSumReport(data,
          session.store.storeName,
          Pinia.flightsStore.currentFlight,
          currentEmployee);
      }

      export async function printCurrenciesRatesReport() {

        let currentEmployee = null;

        if (hasSessionPosEmployee()) {
          currentEmployee = (await Storage.Entity.Employee.fetchByEmployeeID(session.pos.employeeID));
        }

        let currenciesToPrint = {};
        let allowedCurrencies = Service.MultiCurr.getInstance().getPosCurrenciesEnabled();

        for (let currenyName of allowedCurrencies) {
          if (posPaymentVC.Currencies[currenyName]) {
            currenciesToPrint[currenyName] = posPaymentVC.Currencies[currenyName];
          }
        }        

        Printing.FlightReports.printCurrenciesRatesReport(currenciesToPrint,
          session.store.storeName,
          Pinia.flightsStore.currentFlight,
          Pinia.flightsStore.currentLeg,
          currentEmployee);
      }

      function hasSessionPosEmployee() {
        return session.pos.employeeID !== '-1' && !posUtils.isBlank(session.pos.employeeID);
      }
    }
  }
}
