module PositiveTS {
export module Storage {
export module Entity {
export class PreparationInstruction extends WasmEntity {

  instructionType:number
  name:string
  isDefault:boolean
  color:string
  sortOrder:number
  translations: string
  
  constructor() {
    let meta = {
      name: 'PreparationInstruction',
      fields: {
        instructionType: "INT" ,
        name: "TEXT" ,
        isDefault: "BOOL" ,
        color: "TEXT",
        sortOrder: "INT",
        translations: "TEXT",
      }
    }
    super(meta)
  }

  static getInstructionsForSaleItem(saleItem:SaleItem):Array<PreparationInstruction> {
      if (!saleItem || !saleItem.item) {
        return [];
      }
      
      let itemId = saleItem.item.id;

      let sql = `select * from PreparationInstruction where 
      (id in (select preparationInstructionId from ItemPreparationInstruction where itemId=${itemId}) OR isDefault=1) 
      order by instructionType desc,sortOrder asc,name asc`;

      return this.execAndConvertResult(sql);
    }   
  }

}}}
