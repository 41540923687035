module PositiveTS {

    export module Components {

        export module ZReportAllProgressDialog {
            const dialogSelector = 'z-report-all-progress-dialog'

            interface ZReportAllProgressDialogOptions {
                channels: PositiveTS.Service.ZAllReportChannel[],
            }

            export function create() {

                let component = {
                    name: 'z-report-all-progress-dialog',
                    template: JST.ZReportAllProgressDialog(),
                    methods: {
                        open(channels: PositiveTS.Service.ZAllReportChannel[]) {
                            this.channels = channels
                            this.updates = []
                            this.$el.showModal()
                            PositiveShared.Utils.sleep(10000).then(() => {
                                this.channels.forEach((channel: PositiveTS.Service.ZAllReportChannel) => {
                                    if (channel.status === Service.ZReportAll.STATUS.WAITING_FOR_START) {
                                        channel.status = Service.ZReportAll.STATUS.NO_RESPONSE;
                                    }
                                });
                            });

                            socketManager.channelStore.bind('z_report_info', (data) => {
                                this.update(data);
                            });

                            // Keep Alive Check
                            this.keepAlive = setInterval(() => {
                                this.channels.forEach((channel: PositiveTS.Service.ZAllReportChannel) => {
                                    if (channel.status === Service.ZReportAll.STATUS.STARTED) {
                                        // check if channel last alive timestamp is more than keepAliveInterval
                                        let now = new Date().getTime();
                                        let diff = (now - channel.last_alive) / 1000;
                                        if (diff > Service.ZReportAll.keepAliveInterval) {
                                            channel.status = Service.ZReportAll.STATUS.NO_RESPONSE;
                                        }
                                    }
                                });
                                this.finish() // Check if all channels are finished
                            }, Service.ZReportAll.keepAliveInterval * 1000);

                            this.$nextTick(() => {
                                $(document).unbind('keypress')
                                document.addEventListener('keydown', posUtils.disabledEscape, true)
                            })

                            return new Promise((resolve, reject) => {
                                this.resolveFunc = resolve
                                this.rejectFunc = reject
                            })
                        },

                        update(data) {
                            if (data.from != session.pos.deviceID) {
                                // Update the channel status
                                this.channels.forEach((channel: PositiveTS.Service.ZAllReportChannel) => {
                                    if (channel.pos_id === data.from) {
                                        if (data.status === Service.ZReportAll.STATUS.STARTED)
                                            channel.last_alive = moment.now();
                                        channel.status = data.status;
                                        if (data.message)
                                            channel.message = data.message;
                                        if (data.report_num)
                                            channel.report_num = data.report_num;
                                    }
                                });

                                this.finish()

                            }
                        },
                        finish() {
                            if (this.remainingReports === 0 && this.successfulReports > 0) {
                                clearInterval(this.keepAlive);
                                if(jsonConfig.getVal(jsonConfig.KEYS.cancelPrintZReport)){
                                    return;
                                }
                                PositiveTS.Service.ZReportAll.printFinished(this.channels);
                                this.channels.forEach((channel: PositiveTS.Service.ZAllReportChannel) => {
                                    if (channel.status === Service.ZReportAll.STATUS.FINISHED) {
                                        channel.status = Service.ZReportAll.STATUS.PRINTED;
                                    }
                                });
                            }
                        },

                        close(): void {
                            this.$el.close()
                        },

                        cancel() {
                            this.close()
                            if (this.rejectFunc) {
                                this.rejectFunc()
                            }
                        },

                        confirm() {
                            this.close()
                        },

                    },
                    data() {
                        const data: {
                            dialogElem: any,
                            title: string,
                            channels: PositiveTS.Service.ZAllReportChannel[],
                            resolveFunc: any,
                            rejectFunc: any,
                            keepAlive: any,
                        } = {
                            dialogElem: null,
                            title: i18next.t('ZReportAllProgressDialog.title'),
                            channels: [],
                            resolveFunc: null,
                            rejectFunc: null,
                            keepAlive: null,
                        }
                        return data
                    },
                    computed: {
                        remainingReports(): number {
                            return this.channels.filter((channel) => {
                                return channel.status === Service.ZReportAll.STATUS.WAITING_FOR_START
                                    || channel.status === Service.ZReportAll.STATUS.STARTED
                            }).length;
                        },
                        successfulReports(): number {
                            return this.channels.filter((channel) => {
                                return channel.status === Service.ZReportAll.STATUS.FINISHED;
                            }).length;
                        },
                        failedReports(): number {
                            return this.channels.filter((channel) => {
                                return channel.status === Service.ZReportAll.STATUS.ERROR;
                            }).length;
                        },
                        offlineReports(): number {
                            return this.channels.filter((channel) => {
                                return channel.status === Service.ZReportAll.STATUS.NO_RESPONSE;
                            }).length;
                        },
                        printedReports(): number {
                            return this.channels.filter((channel) => {
                                return channel.status === Service.ZReportAll.STATUS.PRINTED;
                            }).length;
                        }

                    },
                }

                VueApp.component('z-report-all-progress-dialog', component)
            }

        }

    }

}

