module PositiveTS {
  export module Promotions {
  export module Templates {
  export class HaZolMeNew extends TemplateAbstract {
    
    private minBuyQuantity:number
    private minBuyAmount:number
    private allowMultipleTimesPerSale:boolean
    private countByRows:boolean
    
    private allItems:Array<Storage.Entity.SaleItem>
    private buyItems:Array<Storage.Entity.SaleItem>
  
    constructor(initParameters) {
      super(initParameters)
      this.allItems = initParameters.updatedSaleItems;
    }
  
    calculatePromotions() {
      //yalla azov oti me shtoyot
    }
  
    run(promotion:Storage.Entity.Promotion) {
      
      this.buyItems = [];
      this.minBuyQuantity = Number(promotion.minimumBuyQuantity);
      this.minBuyAmount = Number(promotion.minimumBuyAmount);
      this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
      this.countByRows = Boolean(promotion.countByRows)
      let allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
      
      if (this.minBuyQuantity <= 1) {
        console.warn('min buy quantity must be greater than 1 for ha zol me promotion')
        return;
      }

      if (!allowWithOtherPromotions) {
        this.allItems = this.allItems.filter(saleItem => !((<any>saleItem).hasPromotion))
      }
      
      for (let si of this.allItems) {
        if (si.item.promoBuy.split("&").includes(promotion.code)) {
          if (this.countByRows){
            let r = 0
            if(!this.buyItems.find((bi) => bi.rowNumber == si.rowNumber)){
              let siClone = _.cloneDeep(si)
              siClone.quantity = 1
              this.buyItems.push(siClone)
            }
          }else{
            this.buyItems.push(si)
          }
        }
      }
  

      this.buyItems = this.buyItems.sort((a,b) => this.fromExpensiveToCheap.call(this, a, b))
      
      //build largest get group in multiple of getQty - build group by order accoring to condition:
      //if the buy condition is quantity - get the cheapest items first. don't re-run with expensive first.
      let [buyGroup,getGroup] = this.buildGroups()    
      
      
      //if group size is smallest and can't apply - finish.
      while (buyGroup != null) {
      
        this.parameters.promoGroups = this.parameters.promoGroups.concat(this.createPromoGroups(buyGroup,getGroup,promotion));
        this.buyItems.splice(0,this.minBuyQuantity);
        [buyGroup,getGroup] = this.buildGroups()
        
      }
        
      
     

    }

    private static fromCheapToExpensive(a:Storage.Entity.SaleItem,b:Storage.Entity.SaleItem)  {
      if (a.unitPrice != b.unitPrice) {
        return a.unitPrice - b.unitPrice;
      }

      return b.rowNumber - a.rowNumber;
    }

    fromExpensiveToCheap(a:Storage.Entity.SaleItem,b:Storage.Entity.SaleItem)  {
      let aUnitPrice = a.unitPrice * (this.countByRows ? a.realQuantity : a.quantity)
      let bUnitPrice = b.unitPrice * (this.countByRows ? b.realQuantity : b.quantity)
      if (aUnitPrice != bUnitPrice) {
        return bUnitPrice - aUnitPrice;
      }

      return b.rowNumber - a.rowNumber;
    }

    private buildGroups() {
      let getGroup:Array<Storage.Entity.SaleItem> = []
      let buyGroup:Array<Storage.Entity.SaleItem> = []
      let tempBuyQty = this.minBuyQuantity;
     
      if (this.buyItems.length < this.minBuyQuantity) {
        return [null,null];
      }

      for (let saleItem of this.buyItems) {
        if (tempBuyQty>1) {
          buyGroup.push(saleItem);
          tempBuyQty--;
        }
        else {
          getGroup.push(saleItem);
          tempBuyQty = this.minBuyQuantity
          break;
        }
        
      }


      return [buyGroup,getGroup];
       
    }
  
  
    private createPromoGroups(buyGroup,getGroup, promotion) {
  
      let promoGroups = []      
      
      promoGroups.push(this.createPromoGroup(promotion,buyGroup,true));
      promoGroups.push(this.createPromoGroup(promotion,getGroup,false));

      return promoGroups;
    
    }

    private createPromoGroup(promotion,tempArr,isBuyGroup) {
      let promoGroup = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
      promoGroup.totalPriceForItemsBeforeDiscount += _.sumBy(tempArr, (t) => {
        if (t.hasWeight){
          return t.unitPrice * (this.countByRows ? t.realQuantity : t.quantity)
        }

        return t.unitPrice
      })

      if (isBuyGroup) {
        promoGroup.discountAmountForGroup = 0;  
      }
      else {
        promoGroup.discountAmountForGroup = this.getDiscountValue(promoGroup.totalPriceForItemsBeforeDiscount, promotion);
      }
      tempArr.forEach(item => {
        this.addToItemsCounter(item,promoGroup.itemsCounter);
        this.addToRowValueCounter(item,promoGroup.rowValueCounter);
      });
      return promoGroup;
    }

  
    private getDiscountValue(totalPriceForRun, promotion:Storage.Entity.Promotion) {
      switch (promotion.discountType) {
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
          return Number(promotion.discountValue);
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
          return Math.max(0,totalPriceForRun-Number(promotion.discountValue))
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
          return totalPriceForRun*Number(promotion.discountValue)/100.0;
      }
    }
  
  
  }
  }}}
  