module PositiveTS {
export module Storage {
export module Entity {
export class Flight extends IDBEntity {

	legs: any;
	pos_ids: string;
	status: number;
	take_off_time: number;
	number: number;
	airline_symbol: string;
	code: string;

	constructor() {
		let meta = {
			name: 'Flight',
			fields: {
				legs: "JSON",
				pos_ids: "JSON",
				status: "INT",
				take_off_time: "INT",
				number: "INT",
				airline_symbol: "TEXT",
				code: "TEXT",
			},
		}
		super(meta)
	}
}}}}
