module PositiveTS {
    export module Service {
        interface icmegaOmCheckResponse {
            statusCode: number,
            message: string,
            errCode: number,
            title: string,
            ticket_type: number,
            q: string,
            org_id: string,
        }

        interface icMegaItem {
            item_code: string, 
            origin_id: string,
        }

        // origin_id
        // 92- מועדון טוב
        // 96- מגה לאן
        // 93- קרנות השוטרים
        // 91 - חבר

        export class icMega extends SmartVoucher{
            static API_PREFIX = 'https://sapak.icmega.co.il/api/v2/kod'

            hasCVV = false

            static get sapakId () {
                return jsonConfig.getVal(jsonConfig.KEYS.icMegaSapakId)
            }

            static getIcMegaItems (): Array<icMegaItem> {
                return jsonConfig.getVal(jsonConfig.KEYS.icMegaItems)
            }

            static getIcMegaItemCodes () {
                return this.getIcMegaItems().map(item => item.item_code)
            }

            static hasIcMegaItems (): boolean { 
                return Boolean(icMega.getIcMegaItems().length)
            }

            static isIcMegaItem (saleItem: Storage.Entity.SaleItem) {
                const icMegaItemCodes = icMega.getIcMegaItemCodes()
                return icMegaItemCodes.includes(saleItem.itemCode)
            }

            static checkIfSaleIsPaymentByIcMega (sale) {
                if (sale.payments?.length > 0){
                    let paymentData = JSON.parse(sale.payments[0].data)
                    if (Array.isArray(paymentData)){
                        let paymentByIcMega = paymentData.findIndex(pd => pd.smartVoucherType == PositiveTS.Service.SmartVoucher.ICMEGA_HANDLER)
                        return paymentByIcMega >= 0
                    }
                }

                return false
            }

            static checkIfCurrentSaleIsIcMegaItem () {
                let saleItem = posVC.saleItems.find((si) => icMega.isIcMegaItem(si))
                return Boolean(saleItem)
            }

            static checkIfCanSaleItemBePersisted (saleItem: Storage.Entity.SaleItem) {
                let result = true
                let isIcMegaItem = icMega.isIcMegaItem(saleItem)

                if (PositiveTS.Service.icMega.checkIfCurrentSaleIsIcMegaItem()){
                    if (isIcMegaItem){
                        if (posVC.saleItems[0].itemCode != saleItem.itemCode) {
                            result = false
                            app.showAlert({
                                header: i18next.t('icMega.cannotAddItem'),
                                content: i18next.t('icMega.cannotAddDifferentcICMegaItemCodes'),
                                hideCancelButton: true
                            })
                        }
                    }else{
                        result = false
                        app.showAlert({
                            header: i18next.t('icMega.cannotAddItem'),
                            content: i18next.t('icMega.onlyIcMegaItemsCanAdd'),
                            hideCancelButton: true
                        })
                    }
                }else{
                    if (isIcMegaItem){
                        let findIndexSaleItemNotIcMega = posVC.saleItems.findIndex(si => !icMega.isIcMegaItem(si))
    
                        if (findIndexSaleItemNotIcMega >= 0) {
                            result = false
                            app.showAlert({
                                header: i18next.t('icMega.cannotAddItem'),
                                content: i18next.t('icMega.cannotAddIcMegaItemWithOtherItem'),
                                hideCancelButton: true
                            })
                        }
                    }
                }
                
                return result
            }

            static checkIfCurrentSaleIsIcMegaItemAndNotAllowMethod (method, alertOnError = false) {
                let result = false
                let allowMethod = 'icmega'

                if (icMega.checkIfCurrentSaleIsIcMegaItem()){
                    result = method != allowMethod
                    if (result && alertOnError){
                        app.showAlert({
                            header: i18next.t(`message`),
                            content: i18next.t(`icMega.canPaymentOnlyByIcMegaVoucher`),
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true,
                        })
                    }
                }

                return result
            }

            static checkIfCurrentSaleCanPayByIcMegaMethod (alertOnError = true) {
                let result = true

                if (!icMega.checkIfCurrentSaleIsIcMegaItem()){
                    result = false
                    if (alertOnError){
                        app.showAlert({
                            header: i18next.t(`message`),
                            content: i18next.t(`icMega.cannotPaymentByIcMegaNotFoundIcMegaItemOnSale`),
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true,
                        })
                    }
                }

                return result
            }

            static validateResponseIfCanPay (response: icmegaOmCheckResponse, saleItem, quantity) {
                let result = {valid: false, errors: []}

                if(response.statusCode != 200){
                    if (response.message == "not found"){
                        result.errors.push(i18next.t('icMega.icMegaServerErrorNotFound'))  
                    }else{
                        result.errors.push(response.message || i18next.t('icMega.generalIcMegaServerError'))
                    }
                }

                if (result.errors.length == 0){
                    let icMegaItem: icMegaItem = icMega.getIcMegaItems().find(item => item.item_code == saleItem.itemCode)
                    if (response.org_id != icMegaItem.origin_id) {
                        result.errors.push(i18next.t('icMega.cannotPaySaleItemWithBarcode'))
                    }

                    if(Number(response.q) <= 0){
                        result.errors.push(i18next.t('icMega.cannotPayNoAvailableQuantity')) 
                    }

                    if(quantity > Number(response.q)) {
                        result.errors.push(i18next.t('icMega.cannotPayQuantityBiggerThanAvailableQuantity', {quantity: response.q}))
                    }

                    if(Number(response.q) > quantity) {
                        result.errors.push(i18next.t('icMega.cannotPayLessThanAvailableQuantity', {quantity: response.q}))
                    }
                }
                
                result.valid = result.errors.length == 0

                return result
            }

            static async checkOrUseIcMegaBarcode (barcode, type: 'check'|'use' = 'check') {                  
                let url = `${icMega.API_PREFIX}?om=${type}&sapak_id=${icMega.sapakId}&barcode=${barcode}`;
                let httpMethod = "POST";
                let headers = { 'Content-Type': 'application/json', 'accept': 'application/json' };
                let data: any = {
                    body: "",
                    type: httpMethod,
                    url: url,
                    headers: JSON.stringify(headers),
                }
                data = JSON.stringify(data)
                let response = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponseAndValidate(url, httpMethod, "", headers, `ICMega API ${type}`);
                let res: any = { request: { result: response.body }};
                return res; 
            }

            static async useBarcodesOnSalePayment (salePayment: Storage.Entity.SalePayment): Promise<any> {
                let result = {success: false, error: '', voucher: {}}

                try {
                    let salePaymentData = JSON.parse(salePayment.data) 

                    for (let row of salePaymentData){
                        let res = await icMega.checkOrUseIcMegaBarcode(row.barCode, 'use')               
                        let response = JSON.parse(res.request.result);
                        result.success = true
                        if (response.statusCode != 200){
                            throw new Error(response.message)
                        }
                    }         
                } catch (error) {
                    console.error(error)
                    result.success = false
                    result.error = error.message || i18next.t('generalError')
                }
                
                return result
            }
            
            static getVoucher (): PositiveTS.Storage.Entity.Voucher {
                return PositiveTS.Storage.Entity.Voucher.execAndConvertResult(`SELECT * FROM Voucher WHERE smartVoucherType = '${PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA}'`)[0]
            }
            
            //not really have cancel the pay create on close sale on function useBarcodesOnSale
            async pay (voucherData:any, amount: number, cvv?: string, companyId?: any,additionalData?: any): Promise<any> {
                throw new Error("Method not implemented.")
            }

            //not really have cancel the pay create on close sale
            async cancelPayment(data: any, doRemove?:boolean):Promise<any>{
                return Promise.resolve({success: true})
            }

            //the api of icmeage is only for use cannot do cancel
            cancelLoad(paymentToCancel: any): Promise<any> {
                throw new Error("Method not implemented.");
            }

            loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
                throw new Error("Method not implemented.");
            }

            getCardNumber(): Promise<GetCardNumberResponse> {
                throw new Error("Method not implemented.");
            }

            getBalance (cardNumber: string, cvv?:string): Promise<GetBalanceResponse> {
                throw new Error("Method not implemented.");
            }
        }
    }
}
