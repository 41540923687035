module PositiveTS {
  export module Components {
    export module DedicatedToDialog {
      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }
      function initData() {
        return {
          _initialized: false,
          askForPhone:false,
          dedicated:{
            toPhone:{
              placeHolder:i18next.t("pos.dedicated.toPhone.placeHolder"),
              text:"",
              type:"number",
              iconName:"mobile",
              titleName:i18next.t("pos.dedicated.toPhone.titleName"),
              errorMessage:""
            },
            toName:{
              placeHolder:i18next.t("pos.dedicated.toName.placeHolder"),
              text:"",
              type:"text",
              iconName:"user",
              titleName:i18next.t("pos.dedicated.toName.titleName"),
              errorMessage:""
            }
          },
          currentStep:"toName",
        };
      }
      export function create() {
        let component = {
          template: JST.dedicatedToDialog(),
          mixins: [keyPadMixin],
          components: {
            selfServiceKeypad: SelfServiceKeypad.getComponent(),
            positiveIcon: PositiveIcon.getComponent(),
          },
          methods: {
            cleanData: cleanData,
            open(askForPhone) {
              this.cleanData();
              this.askForPhone = askForPhone;
              $(document).unbind("keypress");
              this.dedicated.toName.text = PositiveTS.Service.DedicatedTo.getCurrentDedicatedTo(
                posVC.sale
              );
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              });
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            cancel() {
              this.resolveFunc(null);
              return this.close();
            },
            cancelTapped() {
              this.resolveFunc(null);
              return this.close();
            },
            async confirmAndClose() {
              let isValid = this.isValidateFields();
              if(!isValid){
                return;
              }

              if(this.askForPhone && this.currentStep == "toName"){
                this.currentStep = "toPhone";
                return;
              }
              this.setSaleJsonData();
              return this.close();
            },
            setSaleJsonData(){
              PositiveTS.Service.DedicatedTo.setSaleJsonData(this.dedicated.toName.text);
              if (this.askForPhone) {
                PositiveTS.Service.DedicatedTo.setSaleJsonDataOrdererPhone(this.dedicated.toPhone.text);
              }
              PositiveTS.Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
              posVC.setDedicatedTo();
              this.resolveFunc(true);
            },
            updateVal(text: string): void {
              this.dedicatedTo.text = text;
            },
            isValidateFields(){
                let isValid = true;
                this.dedicated.toPhone.errorMessage = "";
                this.dedicated.toName.errorMessage ="";
                if(this.currentStep == "toName"){
                  let isNameEmpty = posUtils.isNullOrUndefinedOrEmptyString(this.dedicated.toName.text);
                  if(isNameEmpty){
                    this.dedicated.toName.errorMessage = i18next.t("pos.dedicated.errors.emptyString")
                    isValid = false;
                  }
                }
                else{
                  let isPhoneNumberEmpty = posUtils.isNullOrUndefinedOrEmptyString(this.dedicated.toPhone.text);
                  if(isPhoneNumberEmpty){
                    this.dedicated.toPhone.errorMessage = i18next.t("pos.dedicated.errors.emptyString")
                    isValid = false
                    return isValid;
                  }
                  let isPhoneNumberValid = posUtils.isValidIsraelPhone(this.dedicated.toPhone.text)
                  if(!isPhoneNumberValid){
                    this.dedicated.toPhone.errorMessage = i18next.t("pos.dedicated.errors.notValidPhoneNumber")
                    isValid = false
                    return isValid;
                  }
                }
                return isValid;
            }
          },
          data: initData,
          computed: {
            dedicatedTo(){
              if(this.currentStep == "toName"){
                return this.dedicated.toName
              }
              return this.dedicated.toPhone
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },
        };

        VueApp.component("dedicated-to-dialog", component);
      }
    }
  }
}
