
module PositiveTS {
    export module Service {
      export module MigvanItem {
          export async function updateItemsByMigvan(db){
              if (session.pos.hasStoreMigvans) {
                await updateItemsByMigvanStores(db);
              } else {
                await updateItemsByMigvanPrices(db);
              }
          }

          async function updateItemsByMigvanStores(db) {
            console.time("migvanItemsUpdate");
            app.showLoadingMessage(i18next.t('updatingMigvanItems'))


              let itemCodesToDelete = [];

              let itemsToCheck = Service.WasmDB.execAsObjectWithDB(`SELECT code,itemsMigvans FROM Item WHERE itemsMigvans != ''`, db);

              if (itemsToCheck.length > 0) {
                  for (let item of itemsToCheck) {
                      let allowedStores = item.itemsMigvans.split('&')

                      if (!allowedStores.includes(session.pos.storeID)) {
                        itemCodesToDelete.push(item.code);
                      }
                  }
              }

              if (itemCodesToDelete.length > 0) {
                let sqlCommands = [
                    `DELETE FROM Item WHERE code IN (${itemCodesToDelete.join(',')})`,
                    `DELETE FROM ItemBarcode WHERE code IN (${itemCodesToDelete.join(',')})`,
                    `DELETE FROM DuplicateBarcode WHERE code IN (${itemCodesToDelete.join(',')})`
                ];
                
                let sqlQuery = `BEGIN; ${sqlCommands.join(';')}; END;`

                db.exec(sqlQuery, db);
              }

              app.hideLoadingMessage();
              console.timeEnd("migvanItemsUpdate");

          }

          async function updateItemsByMigvanPrices(db) {
            console.time("migvanItemsUpdate");
            app.hideLoadingMessage();
            if(session.pos.migvanItemsId){
            app.showLoadingMessage(i18next.t('updatingMigvanPrices'))
            
                let rows = Service.WasmDB.execAsObjectWithDB(`SELECT id,code,itemsMigvans,priceZarhan FROM Item WHERE itemsMigvans LIKE '%&${session.pos.migvanItemsId}=%'`, db);
                let migvanItems = [];
                let migvanItemsCodes = [];
                let itemPriceUpdatesSql = [];

                for(let row of rows){
                    let id = (<any>row).id;
                    migvanItems.push(id);
                    migvanItemsCodes.push('"' + (<any>row).code + '"');
                    let itemsMigvans = (<any>row).itemsMigvans;
                    let indexof = itemsMigvans.indexOf(`&${session.pos.migvanItemsId}`)
                    let codeAndPrice = itemsMigvans.substr(indexof + 1,itemsMigvans.indexOf("&",indexof + 1) - indexof - 1)
                    let price = codeAndPrice.substr(codeAndPrice.indexOf("=") + 1)
                    if(!posUtils.isNullOrUndefinedOrEmptyString(price) && Number(price) != (<any>row).priceZarhan){
                        itemPriceUpdatesSql.push(`UPDATE Item SET priceZarhan = ${price} WHERE id = ${id}`);
                    }

                }
                migvanItemsCodes.push('"' + Shared.Constants.Item.GENERIC_ITEM + '"')

                let sqlCommands = [
                    `DELETE FROM Item WHERE id NOT IN (${migvanItems.join()}) AND code != '${Shared.Constants.Item.GENERIC_ITEM}'`,
                    `DELETE FROM ItemBarcode WHERE code NOT IN (${migvanItemsCodes.join()})`,
                    `DELETE FROM DuplicateBarcode WHERE code NOT IN (${migvanItemsCodes.join()})`
                ];
                
                sqlCommands = sqlCommands.concat(itemPriceUpdatesSql);
                let sqlQuery = `BEGIN; ${sqlCommands.join(';')}; END;`

                console.log(sqlQuery);

                db.exec(sqlQuery, db);


                app.hideLoadingMessage();
                console.timeEnd("migvanItemsUpdate");

            }
          }
      }
    }
}