module PositiveTS {
    export module Components {
    export module PositiveDeliveryCustomerAddress {
      const _dialogSelector = "#positive-delivery-customer-address-dialog"
      const _vueComponentName = 'positive-delivery-customer-address';
      const _i18Heb = {
        strNew: "חדש"
      }
    
      export function create() {
        let vueObj = {
            template: JST.positiveDeliveryCustomerAddress(),
            methods: {
              open: _open,
              close: _close,
              doConfirm: _doConfirm,
              doReject: _doReject,
              setActiveRow: _setActiveRow,
            },
            data: _initData,
    
    
        }
    
        VueApp.component(_vueComponentName,vueObj)
    
      }
    
    
      function _setActiveRow(idxStr){
        var ary = []
        for (let i=0;i<this.customerAddresses.length;i++){
            
            if (idxStr === this.customerAddresses[i].idxStr){
              this.selectedRow = this.customerAddresses[i]
              this.selectedRow.isUpdated = true
              this.customerAddresses[i].isSelected = true;
            } else {
              this.customerAddresses[i].isSelected = false;
            }
            ary.push(this.customerAddresses[i])     
        }
        this.customerAddresses =ary;
      }

      function _open(customer_id:number){    
        $(document).unbind('keypress');
        $(function() {
          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: '80%',
            height: 700,
            resizable: false,
            closeOnEscape: false,
            draggable: false,
          });
          $(_dialogSelector).dialog('open');
        });
    
    
        return new Promise((resolve,reject) => {
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.cardscan = "";
          this.customerId = customer_id;



          
          return PositiveTS.Service.Delivery.getDeliveryCustomerAddresses(this.customerId)
          .then( result=>{
            this.customerAddresses = result 

            for (let i=0;i<this.customerAddresses.length;i++){
              this.customerAddresses[i].idxStr = i.toString();
              if (this.customerAddresses[i].is_default){
                this.customerAddresses[i].isSelected = true
                this.selectedRow = this.customerAddresses[i]
              }
            }  

            this.customerAddresses.push({
              idxStr: _i18Heb.strNew
            })    

            if(this.customerAddresses.filter( (row)=>{return row.is_default;}).length === 0){
              this.customerAddresses[0].isSelected = true
              this.selectedRow = this.customerAddresses[0]
            }

          })
          .catch(e=>{
            this.rejectFunc(e);
          })
        })
    
      }
    
    
      function _initData(){
        return {
          _dialogSelector: _dialogSelector,
          rejectFunc: null,
          resolveFunc: null,
          cardscan: "",
          customerId:null,
          customerAddresses: [],
          selectedRow: {},


          value: { name: 'Vue.js', language: 'JavaScript' },
          options: [
            { name: 'Vue.js', language: 'JavaScript' },
            { name: 'Rails', language: 'Ruby' },
            { name: 'Sinatra', language: 'Ruby' },
            { name: 'Laravel', language: 'PHP' },
            { name: 'Phoenix', language: 'Elixir' }
          ]
    
        };
      }
    
      function _doConfirm(){
          this.close();
          var rowsUpdated = this.customerAddresses.filter( (row)=>{return row.isUpdated });
          if (rowsUpdated.length > 0 && this.customerId){
            app.showLoadingMessage("מעדכן כתובות בשרת");
            PositiveTS.Service.FetchReq.jsonReq(`/customer_addresses/${this.customerId}`,"post",{data:rowsUpdated})
            .then( ()=>{
              app.hideLoadingMessage()
              this.resolveFunc(this.selectedRow);
            })
            .catch((e)=>{
              this.rejectFunc(e);
            })
          } else {
            this.resolveFunc(this.selectedRow);
          }

          
      }
    
      function _doReject(){
        this.close();
        this.rejectFunc(new Error(app.userCancelledGlobalMessage));
      }
    
      function _close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }
    
    
    }}}