module PositiveTS {
export module Components {
    export module ValuecardCustomerClub {

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }





      function selectCustomer(customerObj) {

        var vcc = new PositiveTS.Service.ValueCardCustomerClubSelect(posVC.sale);
        return vcc.setCustomerOnSale(customerObj)
        .then(()=>{
          this.close();
          posVC.loadSale()
        })

      }

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $("#valuecard-customer-club-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '90%',
              height: 660,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          customerSelected: false,
          showSearchResults: false,
          searchCustomerMode: true,
          noCustomersFound: false,
          addNewCustomerMode: false,
          customer: {},
          customers: [],
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          showSwipe: jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe) && (session.pos.isEmv),
        }
      }

      function close() {
        $("#valuecard-customer-club-dialog").dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }

      function open() {
        if (!PositiveTS.Service.ValueCardService.valuPosParamsSet()) {
          this.showError(i18next.t('valueCardCustClub.apiKeysAreMissing'));
          return;
        }
        var vcc = new PositiveTS.Service.ValueCardCustomerClubSelect(posVC.sale);
        this.customerSelected = vcc.isCustomerExistsOnSale();

        this.searchInput = "";
        this.customers = [];
        this.showSearchResults = false;

        this.customer = {}
        if (this.customerSelected) {
          this.customer = vcc.getCustomerOnSale();
          this.customer.message = this.customer.message.trim();
        }

        this._initjQueryDialogIfNeeded()

        $(document).unbind('keypress');

        $("#valuecard-customer-club-dialog").dialog('open');
        if (this.$refs.searchInputText) {
          this.$refs.searchInputText.focus();
        }
        else if (this.$refs.customerNumber) {
          this.$refs.customerNumber.focus();
        }

      }

      async function searchCustomer() {
        if (this.searchInput.trim() == "") {
          return;
        }
        this.loading=true;
        app.showLoadingMessage(i18next.t('valueCardCustClub.searchingCustomerPleaseWait'));
        try {
          let customer = await PositiveTS.Service.ValueCardV5Service.getCardInformationEx(this.searchInput);
          this.loading = false;
          let valuecardResponse;
          app.hideLoadingMessage()
          if (jsonConfig.getVal(jsonConfig.KEYS.assetsScreenConfirmation)) {
            valuecardResponse = await Pinia.componentsStore.openComponent( { componentName: "valueCardAssetsScreen", args: [customer] });
          } else {
            valuecardResponse = 'confirm'
          }

          if (valuecardResponse == 'confirm') {
            var vcc = new PositiveTS.Service.ValueCardCustomerClubSelect(posVC.sale);
            await vcc.setCustomerOnSale(customer);
            this.close();
            await posVC.loadSale();
          }
        } catch (err) {
          app.hideLoadingMessage()
          this.loading = false
          await this.showError(i18next.t('clubMemberSelfServiceDialog.memberNotFound'))
          return;
        }
      }

      function _validate():{field:string, error:string}[] {

          let errors:{field:string, error:string}[] = []

          let requiredFields:{fldName:string, hebName:string}[] = [
            // it can be default -1 //{fldName:"customerNumber", hebName:"מספר לקוח/כרטיס"},
            {fldName:"firstName", hebName:"שם פרטי"},
            {fldName:"lastName", hebName:"שם משפחה"},
            //{fldName:"gender", hebName:"מין"},
            {fldName:"mobile", hebName:"נייד"},
            //{fldName:"email", hebName:"Email"},
            //{fldName:"birthDate", hebName:"תאריך לידה (שנה בארבע ספרות)"},
            //{fldName:"aniversaryDate", hebName:"תאריך יום נישואין (שנה בארבע ספרות)"},
            //{fldName:"address", hebName:"כתובת"},          
            //{fldName:"zipcode", hebName:"מיקוד"},
            //{fldName:"remarks", hebName:"הערות"},
          ]

          if (this.customer["customerNumber"] && !(this.customer["customerNumber"].trim() === "")){
            return errors;
          }

          for (var item of requiredFields) {
            let itemText = this.customer[item.fldName]
            if (!itemText || itemText.trim() === "") {
              errors.push({field: item.fldName, error:`חובה להזין ${item.hebName} במידה ולא הוזן מספר כרטיס`})
            }
          }


          return errors;

      }

      function addNewCustomer() {
        let self = this;
        let errors = this._validate();

        if (errors.length > 0) {
          let errStr = "";
          errors.forEach((el) => {errStr += `${el.error}<br>`})

          app.showAlert({
            header: i18next.t('error'),
            content: errStr,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          },
          () => {
            self.$refs[errors[0].field].focus();
          },null)

        }
        else {
          let customerNumber = this.customer.customerNumber || "-1";
          PositiveTS.Service.ValueCardService.getRegisterClubMemberExQuery(
            customerNumber,
            this.customer.firstName,
            this.customer.lastName,
            this.customer.gender === "זכר",
            this.customer.birthDate ? moment(this.customer.birthDate,"DD/MM/YYYY").toDate() : null,
            this.customer.aniversaryDate ? moment(this.customer.aniversaryDate,"DD/MM/YYYY").toDate() : null,
            this.customer.mobile,
            this.customer.email,
            this.customer.address,
            this.customer.zipcode,
            this.customer.remarks)
          .then( (response)=>{
            if (customerNumber == "-1"){
              customerNumber = this.customer.mobile;
            } 
            return PositiveTS.Service.ValueCardService.getCardInformation(customerNumber)
          })
          .then ( (customerObj:Service.ValueCardService.CardInformationItf)=>{
            var vcc = new PositiveTS.Service.ValueCardCustomerClubSelect(posVC.sale);
            return vcc.setCustomerOnSale(customerObj);
          })
          .then(()=>{
            this.close();
          })
          .catch( (e:Error)=>{
            return app.promiseShowAlert(
             {
              header: i18next.t('error'),
              content: e.message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            })
            .then(()=>{
              throw e;  
            })
            
          })

        }

      }


      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function setMode(mode) {
        switch (mode) {
          case "search":
            this.searchCustomerMode = true
            this.addNewCustomerMode = false
            break;
          case "new":
            this.searchCustomerMode = false
            this.addNewCustomerMode = true
            PositiveTS.VueInstance.$nextTick( ()=> {
              this.$refs.customerNumber.focus();
            })
            
            break;
          default:
            //do noting
            break;
        }
      }

      function selectInput(field) {
        this.$refs[field].focus();
      }

      function genderFocus() {
        return PositiveTS.Dialogs.GenericSelect.open([{code:"זכר", name: "זכר"},{code:"נקבה", name: "נקבה"}]).
        then( selected =>{
          this.$refs["gender"].value = selected.code;
          this.customer.gender = selected.name;
          this.$refs["mobile"].focus();
        })
      }

      export function create() {

        let valuecardCustomerClub = {
            template: JST.valuecardCustomerClub(),
            methods: {
              cleanData: cleanData,
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              close: close,
              searchCustomer: searchCustomer,
              showError: showError,
              showGenericError: showGenericError,
              addNewCustomer: addNewCustomer,
              selectCustomer: selectCustomer,
              setMode: setMode,
              selectInput: selectInput,
              genderFocus: genderFocus,
              _validate: _validate,
              async swipeCard(): Promise<void> {
                if (this.showSwipe) {
                  const swipeCardInput = await this.swipeCardAndGetData();
                  if (this.$refs.searchInputText) {
                    this.searchInput = swipeCardInput || "";
                    if (this.searchInput) {
                      await this.searchCustomer()
                    }
                  } else if (this.$refs.customerNumber) {
                      this.customer.customer_number = swipeCardInput || "";
                  }
                }
              },
              async swipeCardAndGetData(){ 
                app.showLoadingMessageDialog(i18next.t("valueCardCustClub.swipeCard"));
                const swipeCardInput = await Service.EMV.swipe(); 
                app.hideLoadingMessageDialog();
                return swipeCardInput;
              }
            },
            data: initData,
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {fullClub,allowSetNewCustomerDiscountPercent,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {fullClub,allowSetNewCustomerDiscountPercent,mobileLayout}

              return {...globalStoreProps}
            },

        }

        VueApp.component('valuecard-customer-club',valuecardCustomerClub)

      }
    }
}
}
