module PositiveTS {
  export module Components {
    export module DalpakAreaActionsDialog {

      export const route = '/dalpak-area-actions';

      type CompType = any;

      export function getComponent() {
        return {
          template: JST.dalpakAreaActionsDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            focusOn(ref) {
              this.$refs[ref]?.focus()
            },
            close() {
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
            async getDalpakAreaToTransferDalpaksTo(): Promise<void> {
              if (this.allDalpakAreas.length < 3) {
                let otherDalpkArea = this.allDalpakAreas.filter(da => da.railsId != this.dalpakArea.railsId)
                if (otherDalpkArea.length > 0) {
                  this.replacedDalpakAreaId = otherDalpkArea[0].railsId
                } else {
                  this.replacedDalpakAreaId = Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT
                }
              } else {
                this.replacedDalpakAreaId = await this.$refs.selectDalpakAreaModal.open()
              }
            },
            async deleteDalpakArea() {
              let result = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);
              if (result) {
                let dalpakAreaDalpaks = this.dalpaksByArea[this.dalpakArea.railsId]
                let dalpakAreaHasDalpaks = !posUtils.isBlank(dalpakAreaDalpaks)
                if (dalpakAreaHasDalpaks) {
                  await this.getDalpakAreaToTransferDalpaksTo()
                }

                this.dalpakArea.replacedDalpakAreaId = this.replacedDalpakAreaId

                let isSuccess = await Service.Dalpak.deleteDalpakArea(this.dalpakArea, this.replacedDalpakAreaId, dalpakAreaDalpaks)

                if (isSuccess) {
                  this.dalpakArea = null
                  Service.Dalpak.clearCurrentSelectedDalpakAreaFromLocalStorage()
                  this.close();
                }
              }
            },
            async update() {
              await this.transferSelectedDalpaksToThisArea()
              let isSuccess = await Service.Dalpak.updateDalpakArea(this.dalpakArea);

              if (isSuccess) {
                this.dalpakArea = null
                this.close();
              }
            },
            async transferSelectedDalpaksToThisArea() {
              if (this.dalpaksToTransfer?.length > 0) {
                return await Service.Dalpak.dalpakUpdateArea(this.dalpaksToTransfer, this.dalpakArea.railsId)
              }

              return true
            },
            async scrollToElement(element) {
              await this.$nextTick();
              element.scrollIntoView(true);
            },
            async releaseDalpakAreaActionsLock() {
              let employee = await app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_EDIT_DALPAKS]);

                if(employee?.isManagerDiscountConfirm || employee?.canEditDalpaks) {
                  this.lockDalpakAreaActions = false
                } else {
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('dalpakAreaActionsDialog.editDalpakAreaPermissionError'),
                    hideCancelButton: true
                  })
                }
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {dalpaksByArea,allDalpakAreas,allDalpaks} = Pinia.storeToRefs(dalpaksStore);

            const globalStore = Pinia.useGlobalStore();
            const {routerExtraData} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {routerExtraData} 

            return {...globalStoreProps,dalpaksByArea,allDalpakAreas,dalpaks:allDalpaks}
          },
          computed: {
            dalpakOptions() {
              return this.dalpaks.filter(dalpak => !this.dalpaksToTransfer.includes(dalpak) && (parseInt(dalpak.area) !== this.dalpakArea.railsId) && dalpak.area !==  Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES)
                .map(dalpak => {
                  return dalpak;
                });
            },
            isAndroid() {
              return session.isAndroid;
            },
          },
          data() {
            return {
              dalpakArea: null,
              replacedDalpakAreaId: null,
              dalpaksToTransfer: [],
              lockDalpakAreaActions: false
            }
          },
          beforeRouteEnter: function (to, from, next) {
            next(vm => {
              let self = vm as CompType;
              self.dalpakArea = _.cloneDeep(vm.routerExtraData.dalpakArea)
              self.lockDalpakAreaActions = jsonConfig.getVal(jsonConfig.KEYS.isDalpakEditPermissionRequired)
            });
          }
        }
      }
    }
  }
}
