module PositiveTS {
export module Components {
    export module CreditCardNoPinPad {
        //clears the component completely - called after sale is closed (cleanSale)
        function cleanData() {
          let result = initData();
          for (let prop in result) {
            this[prop] = result[prop];
          }
        }

        //clears the data after a single payment but when there might be still amount to pay
        function clearOldData() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          let result = initData()
          for (let prop in result) {
            if (prop === 'creditPayments' || prop === "amount") {
              continue;
            }
            aThis[prop] = result[prop];
          }

        }

        function clearTapped() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          aThis.clearOldData()
          aThis.selectField('cardNumberText')
        }

        function initFields() {
          let fields = {
            'amount': {
              selected: false,
              allowDecimal: true,
              inEditMode: false,
              type: 'number',
              disabled: false
            },
            'cardNumberText': {
              selected: false,
              inEditMode: false,
              type: 'string',
              allowDecimal: false,
              disabled: false
            },
            'payments': {
              allowDecimal: false,
              selected: false,
              inEditMode: false,
              type: 'number',
              disabled: true
            },
            'phone': {
              selected: false,
              allowDecimal: false,
              inEditMode: false,
              type: 'string',
              disabled: false
            },
            'cvv': {
              selected: false,
              allowDecimal: false,
              inEditMode: false,
              type: 'string',
              disabled: false
            },
            'expDate': {
              selected: false,
              allowDecimal: false,
              inEditMode: false,
              type: 'string',
              disabled: false
            },
            'tz': {
              selected: false,
              allowDecimal: false,
              inEditMode: false,
              tyoe: 'number',
              disabled: false
            }
          }
          for (let field in fields) {
            fields[field].model = field;
          }
          return fields
        }

        function disableFields() {
          for (let field in this.fields) {
            this.fields[field].disabled = true
          }
        }

        function enableFields(originalFieldsState) {
          for (let field in this.fields) {
            this.fields[field].disabled = originalFieldsState[field].disabled;
          }
        }

        function initData() {
          let types = []

          for (let ccpt in session.creditCardPaymentTypes) {
            let ccptType = session.creditCardPaymentTypes[ccpt]
            ccptType.selected = false
            if (ccptType.ccTypeID == "1") {
              ccptType.selected = true
            }
            types.push(ccptType);
          }
          return {
            creditCardPaymentTypes: types,
            paymentsTypeSelected: false,
            cardNumber: "",
            cardNumberText: "",
            cvv: "",
            expDateText: "",
            payments: 1,
            amount: 0,
            phone: "",
            tz: "",
            manualConfirmationNumber: null,
            fields: initFields(),
            clearImg: `${(<any>window).images_path}/close.png`,
            creditPayments: []
          }
        }

        function selectCreditType(selectedType) {
          for (let ccpt of this.creditCardPaymentTypes) {
            ccpt.selected = false;

            if (ccpt.ccTypeID == selectedType.ccTypeID) {
              ccpt.selected = true
              this.paymentsTypeSelected = Boolean(ccpt.allowPayments)
              if (!this.paymentsTypeSelected) {
                this.payments = 1
                this.fields['payments'].disabled = true
                this.selectField('cardNumberText')
              }
              else {
                this.fields['payments'].disabled = false
                setTimeout(() => {
                  this.selectField('payments')
                  this.$refs['payments'].select();
                },0)

              }
            }
          }
        }

        function selectField(fieldName) {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          let refField = null;
          //not liking this code so much... but want to make sure that number will be rounded
          aThis.amount = session.fixedFloat(aThis.amount);
          for (let field in aThis.fields) {
            aThis.fields[field].selected = false
            aThis.fields[field].inEditMode = true
            aThis.fields[field].hasDecimal = false
            if (field == fieldName) {
              aThis.fields[field].selected = true
              refField = field
            }
          }
          if (refField != null) {
            this.$refs[refField].focus();
          }
        }

        function onKeyTapped(key) {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad

          let field = getSelectedField();
          aThis.keyPadHanlder(key,field,aThis);
        }

        function getSelectedField() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          for (let fieldName in aThis.fields) {
            if (aThis.fields[fieldName].selected) {
              return aThis.fields[fieldName]
            }
          }
          return undefined;
        }

        function getPaymentToRender() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          aThis.creditPayments = []
          let creditPayment = posVC.salePayments.filter(sp => {return sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT})[0]
          if (creditPayment) {
            try {
              let data = JSON.parse(creditPayment.data)
              for (let paymentData of data) {
                //Build the display of the payments field in the table
                let creditPaymentType = session.creditCardPaymentTypes[Number(paymentData.payment_type)];
                let displayPayments = '';

                if (creditPaymentType.allowPayments){
                  displayPayments = paymentData.payments_count + " תש'"
                }else{
                  displayPayments = creditPaymentType.ccTypeName;
                }
                aThis.creditPayments.push({
                  amount: session.fixedFloat(paymentData.amount),
                  cardNumber: `${paymentData.card_number.substr(-4,paymentData.card_number.length)}****`,
                  paymentsTxt: displayPayments
                })
              }
            }
            catch (e) {
              console.error(e);
              aThis.creditPayments = []
            }
          }

        }

        function onPaymentDeleted(creditPayment, paymentToDelete, data, index) {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          data.splice(index,1)

          if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() && paymentToDelete.creditCurrency == 'ILS') {
            let creditLeadCurrencyAmount = parseFloat(paymentToDelete.creditLeadCurrencyAmount);
            creditPayment.amount -= creditLeadCurrencyAmount;
          } else {
            creditPayment.amount -= paymentToDelete.amount;
          }

          creditPayment.data = JSON.stringify(data);
          // Persist the sale payment
          Service.FullSale.saveCurrentSale()
          .then(async () => {
            aThis.removeSplittedPaymentIfNeeded();
              await refreshView()
              posPaymentVC.updateAmountsIndicators()
              app.hideLoadingMessage()
          })
        }

        function deleteCreditPayment(index) {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          console.debug(index);
          app.showAlert({
            header: i18next.t('confirmSalePaymentItemDeleteHeader'),
            content: i18next.t('confirmSalePaymentItemDelete'),
            continueButtonText: i18next.t('remove'),
            cancelButtonText: i18next.t('cancel')
          }, function () {

            let creditPayment = posVC.salePayments.filter(sp => {return sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT})[0]
            if (creditPayment) {
              let data = JSON.parse(creditPayment.data);
              let paymentToDelete = data[index];
              if (paymentToDelete) {
                if (paymentToDelete.card_number.substring(0,2) == '72') {
                  console.warn('ניסיון למחוק תשלום של כרטיס נטען - לא ניתן לעשות זאת');
                  return;
                }
                let orignialFieldsState = JSON.parse(JSON.stringify(aThis.fields));
                aThis.disableFields()

                app.showLoadingMessage(i18next.t('cancelChargePleaseWait'));
                PositiveTS.Helper.SaleHelper.returnCreditCard(paymentToDelete,
                  function (returnedArray) {
                    aThis.enableFields(orignialFieldsState);
                    onPaymentDeleted(creditPayment, paymentToDelete, data, index)
                }, function (err) {
                  aThis.enableFields(orignialFieldsState);
                  app.hideLoadingMessage();
                  console.log(err);
                  showAlertGeneric(err)

                });
              }
            }
          },null);

        }

        async function refreshView() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad

          aThis.setCalculatedAmount();
          aThis.selectField('cardNumberText')

          getPaymentToRender()
          await PositiveTS.VueInstance.$nextTick()
        }

        function verifyAmount() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad

          return new Promise((resolve,reject) => {
            let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
            let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);

            if (aThis.amount <= 0) {
              showAlertGeneric(i18next.t('newCreditCard.AMOUNT_MUST_BE_BIGGER_THAN_ZERO'),reject)
            }
            else if (aThis.amount > amountLeft) {
              showAlertGeneric(i18next.t('generalPaymentLeftAmountMandatoryError'),reject)
            }
            else {
              resolve()
            }
          })

        }

        function verifyPayments() {
          return new Promise((resolve,reject) => {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
            if (aThis.paymentsTypeSelected) {
              let creditTypeID = aThis.creditCardPaymentTypes.filter((ccpt) => {return ccpt.selected === true})[0].ccTypeID
              let numberOfPaymentsAuthorizedForAmount =
                paymentLimit.creditCardNumberOfPaymentsAuthorizedForAmount( aThis.amount, creditTypeID !== 3 );
              if (aThis.payments == 0) {
                showAlertGeneric(i18next.t('creditCardPaymentPaymentsMandatoryError'),reject)
              } else if (aThis.payments > session.pos.parameterMaxCreditCardPayments) {
                showAlertGeneric(
                  i18next.t('creditCardPaymentPaymentsMaxMandatoryError', {COUNT: session.pos.parameterMaxCreditCardPayments}),
                  reject)
              } else if (aThis.payments > numberOfPaymentsAuthorizedForAmount) {
                showAlertGeneric(i18next.t('creditCardPaymentPaymentsMaxForAmountError', {COUNT:
                	paymentLimit.lastQueryOfCreditCardNumberOfPaymentsAuthorizedForAmount}),reject)
              } else if(aThis.amount < jsonConfig.getVal(jsonConfig.KEYS.minAmountForPayments)) {
                app.showAlert({
                  header: i18next.t('error'),
                  content: i18next.t('newCreditCard.minAmountForPaymentsNotMet', {amount: jsonConfig.getVal(jsonConfig.KEYS.minAmountForPayments)}),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, () => { reject() }, null);
              }
              else {
                resolve()
              }
            }
            else {
              resolve()
            }
          })
        }

        function verify() {
          return verifyAmount()
          .then(verifyExpDate)
          .then(verifyPayments)
        }

        function cardChanged() {
          if (this.cardNumberText.indexOf('*') !== -1) { //card was already swiped and we just pressed enter...
            return this.verifyAndPay();
          }
          if (this.cardNumberText.indexOf('=') !== -1) { //swiped

            this.cardNumber = this.cardNumberText;
            let formattedValue = ''
            if (this.cardNumberText.length > 8) {
              formattedValue += this.cardNumberText.substring(0,8);

              for (let i = 7; i < this.cardNumberText.length; i++) {
                formattedValue += '*';
              }
            }
            this.cardNumberText = formattedValue
            return this.verifyAndPay();
          }
          else { //manual
            this.cardNumber = this.cardNumberText;
            this.selectField('expDate')
          }
        }

        function verifyAndPay(response) {
          return new Promise( (resolve,reject) => {
              if (this.cardNumberText.indexOf('*') === -1) {
                this.cardNumber = this.cardNumberText;
              }
              return verify()
              .then(() => {
                return createPaymentAndPay()
              })
              .then(() => {
                clearOldData();
                resolve();
              }).catch(() => {
                reject();
              })

          })

        }

        function verifyExpDate() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          let error = false
          return new Promise((resolve,reject) => {
            if (aThis.swiped) {
              return resolve()
            }
            if (aThis.expDate === "" || !aThis.expDate) {
              showAlertGeneric(i18next.t('newCreditCard.EXP_DATE_MISSING'),reject)
              error = true
            }
            let expDate = moment(aThis.expDate, 'MM/YY').endOf('month')
            if (expDate <= moment(new Date())) {
              showAlertGeneric(i18next.t('creditCardPaymentExpDateOverMandatoryError'),reject)
              error = true
            }

            if (!error) {
              resolve()
            }
          })
        }

        function createPaymentAndPay() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          let orignialFieldsState = JSON.parse(JSON.stringify(aThis.fields));
          aThis.disableFields()
          app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
          return new Promise((resolve,reject) => {
            PositiveTS.Service.CreditCard.promiseGetEffectiveBalance(aThis.cardNumber,aThis.amount)
            .then(function( rAmount ){
              //if card is chargable - update the amount to the max available amount
              aThis.amount = rAmount
            })
            .then(() => {
              return aThis.pay()
            })
            .then(() => {
              aThis.enableFields(orignialFieldsState)
              resolve()
            })
            .catch(err => {
              app.hideLoadingMessage()
              aThis.enableFields(orignialFieldsState)
              if (err) {
                console.error(err);
                if (err.message !== app.userCancelledGlobalMessage) {
                    showAlertGeneric(err || i18next.t('creditCardPaymentError'),reject)
                }
              }
              else {
                reject();
              }
            });
          })

        }

        function getSalePayment() {
          let salePayment = posVC.salePayments.filter(sp => {
            return (sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT)
          })[0]


          if (!salePayment) {
            salePayment = new PositiveTS.Storage.Entity.SalePayment();
            salePayment.saleID = posVC.sale.id;
            salePayment.amount = 0;
            salePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT;
          }

          return salePayment;
        }

        function updateViewAndGlobalState() {


          refreshView()
          posPaymentVC.updateAmountsIndicators()
        }

        async function savePaymentAndRenderView(result) {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          let salePayment = result.salePayment;
          let cardDetails = result.cardDetails;

          //Create dataObject array
          var dataObj = [];

          //init the old dataObject
          if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1) {
            dataObj = JSON.parse(salePayment.data);
          }

          //workaround for a rare bug in gili's credit card infrastructure
          //this code can be removed once creditDal/shva/creditService have been re-written
          if (cardDetails.amount == null || Number.isNaN(cardDetails.amount)) {
            cardDetails.amount = aThis.amount;
          }

          dataObj.push(cardDetails);
          salePayment.data = JSON.stringify(dataObj);

          // Duetyfree Shekel payment must be translated into USD
          if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() && cardDetails.creditCurrency == 'ILS') {
            var OrgCreditAmount = aThis.amount;
            var McCreditAmount = aThis.moduleMultiCurrencyCredit.getAmountTranslatedIfRequired( OrgCreditAmount);
            salePayment.amount += McCreditAmount;
          } else {
            salePayment.amount = Number(salePayment.amount);
            salePayment.amount += Number(aThis.amount);
          }

          let inserted = false
          for (let i=0; i < posVC.salePayments.length; i++) {
            if (posVC.salePayments[i].method == salePayment.method) {
              posVC.salePayments[i] = salePayment;
              inserted = true;
              break;
            }
          }

          if (!inserted) {
            posVC.salePayments.push(salePayment)
          }
          await Service.FullSale.saveCurrentSale();
          aThis.addSplittedPaymentIfNeeded();

          return updateViewAndGlobalState();
        }

        function askForVerificationCode() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad
          return new Promise((resolve,reject) => {
            app.hideLoadingMessage()
            let dg = new PositiveTS.Dialogs.InputDialog()
            let options = new PositiveTS.Dialogs.InputDialogOptions()
            options.header = 'נדרש אישור מחברת האשראי'
            options.description = 'נא הקלד מספר אישור שהתקבל מחברת האשראי או לחץ על ביטול'
            options.showCancelButton = true
            dg.open(options)
            .then(result => {
              aThis.manualConfirmationNumber = result
              resolve()
            })
            .catch(err => {reject('פעולה בוטלה')})
          })

        }

        function getCardDetails() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad

          var creditCurrency = undefined;
          var creditLeadCurrencyAmount = undefined;
          // if MC supported get posPaymentVC.PaymentCreditSelectedCurrency.getVal()
          if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
            creditCurrency = posPaymentVC.PaymentCreditSelectedCurrency.getVal();
            creditLeadCurrencyAmount = posPaymentVC.moduleMultiCurrencyCredit.getAmountTranslatedIfRequired(aThis.amount);
          }

          let cardDetails = {
            'amount'    : aThis.amount,
            'cardNumber'   : aThis.cardNumber,
            'creditType' : aThis.creditCardPaymentTypes.filter((ccpt) => {return ccpt.selected === true})[0].ccTypeID,
            'experDate' : aThis.expDate || "",
            'phoneNumber': aThis.phone || "",
            'ccv' : aThis.cvv || "",
            'cardPayments'   : String(aThis.payments),
            'confNumber' : aThis.manualConfirmationNumber || "",
            'creditCurrency': creditCurrency,
            'creditLeadCurrencyAmount': creditLeadCurrencyAmount,
            'tz': aThis.tz || ""
          }

          return cardDetails;

        }

        function pay() {
          let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad

          return new Promise((resolve,reject) => {

            app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
            //create the cardDetails object and call debit credit card...
            debitCreditCard(getSalePayment(), getCardDetails())
            .catch(result => { //TODO: this should not be catch... it is a normal response - not an execption
              //if code is 003 - ask for confirmation number
              if (result && result.return_code && result.return_code === "003" && !jsonConfig.getVal(jsonConfig.KEYS.doNotAskForConfirmationNumber)) {
                return askForVerificationCode()
                .then(() => {
                  app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
                  return debitCreditCard(getSalePayment(), getCardDetails())
                })
              }
              else if (result && result.return_code && (result.return_code == "025" || result.return_code == "032")) {
                return Service.CreditCard.updateSvaIfErr025AndNoTranExists(result)
                .then(() => {
                  return debitCreditCard(getSalePayment(), getCardDetails());
                })
              }
              else {
                throw result.error_message
              }
            })
            .then((result) => {
              return savePaymentAndRenderView(result)
            })
            .then(() => {
              app.hideLoadingMessage();
              resolve()
            })
            .catch(error => {
              app.hideLoadingMessage();
              if (error != app.userCancelledGlobalMessage) {
                console.error(error)
                showAlertGeneric(error,reject)
              }
              else {
                reject();
              }
            });
          })


        }

        function debitCreditCard(salePayment, cardDetails) {

          return new Promise((resolve,reject) => {
            Service.CreditCard.payWithCreditCard(cardDetails.amount,cardDetails.cardNumber,cardDetails.creditType,cardDetails.ccv,
              cardDetails.cardPayments,cardDetails.experDate,cardDetails.phoneNumber,cardDetails.creditCurrency,cardDetails.confNumber, cardDetails.tz)
            .then(function (result) {
                if (result.success === true) {
                  resolve({ salePayment: salePayment, cardDetails: result });
                } else {
                  reject(result);
                }
            })
            .catch((error) => {
              reject(error)
            })
          });

        }

        function showAlertGeneric(message, okCallback = null, cancelCallback = null) {
          app.showAlert({
            header: i18next.t('error'),
            content: message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          }, 
          () => {
            if(!posUtils.isNullOrUndefined(okCallback)) {
              okCallback();
            }}, cancelCallback);
        }

        export function create() {
            var creditCardComponent = {
                template: JST.creditCardNoPinPad(),
                mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
                methods: {
                    cleanData: cleanData,
                    selectCreditType: selectCreditType,
                    refreshView: refreshView,
                    disableFields: disableFields,
                    enableFields: enableFields,
                    verifyAndPay: verifyAndPay,
                    pay: pay,
                    selectField: selectField,
                    cardChanged: cardChanged,
                    clearOldData: clearOldData,
                    clearTapped: clearTapped,
                    createPaymentAndPay: createPaymentAndPay,
                    onKeyTapped: onKeyTapped,
                    deleteCreditPayment: deleteCreditPayment,
                },
                data: initData,
                computed: {
                  'expDate': {
                    set: function(newValue) {
                      //force jquery.mask plugin to work and then get the value into the model
                      $(this.$refs.expDate).val(newValue);
                      $(this.$refs.expDate).trigger(jQuery.Event("keyup.mask"))
                      this.expDateText = $(this.$refs.expDate).val();
                    },
                    get: function() {
                      return this.expDateText
                    }
                  },
                  'swiped': function() {
                    return (this.cardNumber.indexOf('=') !== -1)
                  }
                }

            }

            VueApp.component('credit-card-view-no-pin-pad',creditCardComponent)
        }
    }
  }
}
