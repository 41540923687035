module PositiveTS {
  export module Components {
    export module OpenDeliveryOrdersDialog {

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
          $("#open-delivery-dialog").dialog(this.dialogOptions);
          this.initialized = true
        }
      }


      function close() {
        this.selectedStatus = null;
        this.selectedCourier = null;
        this.selectedType = null;
        this.tableFilter = null;

        $("#open-delivery-dialog").dialog('close');
        $(document).keypress(posVC.onKeyPress);

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          this.deactivateDalpaksOrderGetters();
        }
      }

      function open() {
        this._initjQueryDialogIfNeeded()
        $(document).unbind('keypress');
        $("#open-delivery-dialog").dialog('open');

        PositiveTS.Service.Delivery.getCouriers().then(couriers => {
          this.couriers = [];
          for (let index = 0; index < couriers.length; index++) {
            const courier = couriers[index];
            this.couriers.push(courier.name);

          }
        })

        if (this.mobileLayout) {
          this.showDeliveredOrders = false;
          this.showOnlyTa = false;
        }

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          this.activateDalpaksOrderGetters();
        }

        this.loadOrders();


        if (this.isDeliveriesTablet) {
          if (!this.updateIntervalFunction) {
            this.updateIntervalFunction = setInterval(this.loadOrders.bind(this), 5000);
          }
        } else {
          PositiveTS.VueInstance.$nextTick(() => {
            if (this.$refs.tableFilter && !session.isAndroid) {
              this.$refs.tableFilter.focus();
            }
          })
        }

      }

      function _initData() {

        let isPresto = jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet);

        return {
          openOrders: [],
          tableFilter: null,
          orgopenOrders: [],
          selectedCourier: null,
          selectedStatus: null,
          selectedType: null,
          couriers: [],
          statuses: [{ label: i18next.t('delivery.openDelivery'), value: PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY },
          { label: i18next.t('delivery.closedDelivery'), value: PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY },
          { label: i18next.t('delivery.assignedDelivery'), value: PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH },
          { label: i18next.t('delivery.taPaid'), value: PositiveTS.Storage.Entity.Sale.TA_PAID },
          { label: i18next.t('delivery.delivered'), value: PositiveTS.Storage.Entity.Sale.DELIVERED }],

          types: [i18next.t('delivery.delivery'),
          i18next.t('delivery.TA'),
          i18next.t('delivery.ExternalTenbisDelivery'),
          i18next.t('delivery.ExternalTenbisTA'),
          i18next.t('delivery.externalPaiditDelivery'),
          isPresto ? i18next.t('delivery.externalPrestoTA') : i18next.t('delivery.externalPaiditTA'),
          isPresto ? i18next.t('delivery.externalPrestoSitting') : i18next.t('delivery.externalPaiditSitting'),
          i18next.t('delivery.externalMishloha'),
          i18next.t('delivery.externalMishlohaTA')
          ],
          showDeliveredOrders: null,
          showOnlyTa: null,
          updateIntervalFunction: null
        };
      }

      function showOrder(saleParam) {
        Pinia.componentsStore.openComponent({ componentName: "positiveDeliveryShowOrder", args: [_.cloneDeep(saleParam), _.cloneDeep(saleParam.items), false] })
      }


      async function filterTable() {
        await PositiveTS.VueInstance.$nextTick()
        this.openOrders = this.getRowsAfterFilter(this.orgopenOrders);
      }

      function getRowsAfterFilter(rowsToFilter) {
        if (rowsToFilter != null) {
          return rowsToFilter.filter(this.filterLogic);
        }

        return [];
      }

      function createdAtStr(createdAt) {
        if (!createdAt) {
          return "";
        }

        let date = moment(createdAt)

        let format = "DD/MM HH:mm"
        if (jsonConfig.getVal(jsonConfig.KEYS.useAmericanTimeAndDateFormat)) {
          format = "MM/DD hh:mm A"
        }

        return `${date.format(format)}`
      }

      async function releaseOrder(order) {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          if (posUtils.isDefined(Pinia.dalpaksStore.currentSelectedDalpak)) {
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t('dalpaks.cantReleaseOrderInDalpak'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            });
            return;
          }

          let openedSuccessfully = await Service.Dalpak.selectDalpakAndGoToPos(Service.Dalpak.getDalpakBySale(order.sale));
          if (openedSuccessfully) {
            this.close();
          }
          return;
        }

        try {
          let result = await Service.HoldSale.releaseSaleOnHold(order.sale.id)
          if (result) {
            this.close();
            posVC.resume();
          }
        }
        catch (e) {
          console.error(e);
        }
      }

      function printSale(sale) {
        let saleToPrint = _.cloneDeep(sale);
        saleToPrint.items = saleItemHelper.unflattenSaleItems(saleToPrint.items)
        Printing.Invoice.printInvoice(saleToPrint, saleToPrint.items, saleToPrint.payments, false);
      }

      async function deliverOrder(order) {
        Service.Delivery.deliverOrder(order);

        if (this.mobileLayout) {
          this.loadOrders();
        }
      }

      export function create() {

        let openDeliveryOrdersDialog = {
          template: JST.openDeliveryOrdersDialog(),
          components: { positiveVSelect: PositiveVueSelect.getComponent() },
          methods: {
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            close: close,
            open: open,
            createdAtStr: createdAtStr,
            filterTable: filterTable,
            showOrder: showOrder,
            releaseOrder: releaseOrder,
            printSale: printSale,
            deliverOrder: deliverOrder,
            getRowsAfterFilter: getRowsAfterFilter,
            filterLogic(row) {
              let rowString = row.address +
                row.customerName +
                row.orderNumber.toString();

              if (this.tableFilter && rowString.indexOf(this.tableFilter) == -1) {
                return false;
              }

              if (this.selectedCourier && row.courierName.indexOf(this.selectedCourier) == -1) {
                return false;
              }

              if (this.selectedType && row.type.indexOf(this.selectedType) == -1) {
                return false;
              }

              if (this.selectedStatus && this.selectedStatus.value != row.status) {
                return false;
              }

              if (posUtils.isDefined(this.showDeliveredOrders)) {
                let isDelivered = this.isDelivered(row.sale);

                if (this.showDeliveredOrders != isDelivered) {
                  return false;
                }
              }

              if (posUtils.isDefined(this.showOnlyTa)) {
                if (this.showOnlyTa != row.isTa) {
                  return false;
                }
              }

              return true;
            },
            isDelivered(sale) {
              return Service.Delivery.isDelivered(sale);
            },
            isDeliveryCancelled(sale) {
              return Service.Delivery.isDeliveryCancelled(sale);
            },
            loadOrders() {

              if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                app.showLoadingMessageDialog(i18next.t('delivery.loadingDeliveries'));
                Service.Dalpak.isDalpaksDataValid().then(result => {
                  app.hideLoadingMessageDialog();

                  if (result.success) {
                    PositiveTS.VueInstance.$nextTick(() => {
                      if (this.$refs.tableFilter && !session.isAndroid) {
                        this.$refs.tableFilter.focus();
                      }
                    })
                  } else {
                    app.promiseShowAlert({
                      header: i18next.t('error'),
                      content: result.errorMessage,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    });
                    this.close();
                  }
                })

                return;
              }

              PositiveTS.Service.Delivery.getAllOrders()
                .then((openOrders => {
                  this.orgopenOrders = openOrders;
                  this.openOrders = openOrders;

                  if (this.mobileLayout) {
                    this.filterTable();
                  }
                }))
            },
            toggleShowDeliveredOrders() {
              this.showOnlyTa = false;
              this.showDeliveredOrders = !this.showDeliveredOrders;
              if (!jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                this.filterTable();
              }
            },
            canCancel(sale) {
              if (posUtils.isBlank(sale.jsondata)) {
                return false;
              }
              let jsonData = JSON.parse(sale.jsondata);
              return jsonData.delivery.isSandiDelivery && !Service.Delivery.isDeliveryCancelled(sale);
              // Modify this if you need to for other cases
            },
            async cancelDeliverOrder(order) { // Modify this if you need to for other cases
              try {
                await app.showLoadingMessage(i18next.t("delivery.cancellingOrder"));
                let res = await PositiveTS.Service.Sandi.SandiService.CancelDelivery(order.sale);
                console.info("Sandi Response ::: ", res);
                await Service.Delivery.cancelDeliverOrder(order);
                if (this.mobileLayout) {
                  this.loadOrders();
                }
              } catch (error) {
                app.hideLoadingMessage();
                console.error("Sandi Cancel Order error ::: ", error);
                app.showErrorAlert(i18next.t("delivery.sandiCancelOrderError"))
              }
              finally {
                app.hideLoadingMessage();
              }
            },
            async exitToHompage() {
              let result = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);

              if (result) {
                this.close();
                pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null);
              }
            },
            getTitle() {
              let title = i18next.t('pos.allDeliveries');

              if (this.mobileLayout) {
                if (this.showDeliveredOrders) {
                  title = i18next.t('delivery.showDeliveredOrders');
                } else {
                  title = i18next.t('delivery.showOpenOrders');
                }

                // Because JQuery :'(
                if (this.$el) {
                  $(this.$el.parentElement).find('.ui-dialog-title').text(title);
                }
              }

              return title;
            },
            faliledDeliveryClickHandle() {
              let content = '';
              this.failedDeliveriesOrders.forEach(failedOrder => content += this.failedDeliveryMessage(failedOrder))
              app.showAlertDialog({
                header: i18next.t('error'),
                content: content,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              });
            },
            failedDeliveryMessage(failedOrder) {
              if (failedOrder.orderNumber == null) {
                return failedOrder.errorMessage + '\n';
              }
              return i18next.t('externalDeliveryOrders.failedDeliveriesMessage', { orderNumber: failedOrder.orderNumber, interface: failedOrder.interfaceType }) + '\n';
            },
            formatPrice(price) {
              return Service.MultiCurr.getInstance().addCurrencySignIfNeeded(session.fixedNumber(price));
            },
            canReleaseOrder(order) {
              if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                let dalpak = Service.Dalpak.getDalpakBySale(order.sale);

                if (posUtils.isDefined(dalpak)) {
                  return Service.Delivery.isPartiallyFilledOrder(order);
                }
              }

              return order.sale.isInHold;
            },
            getOrderTotalAmount(order) {
              return Service.Delivery.getOrderTotalAmount(order);
            },
            getOrderCustomerDetailsHtml(order) {

              let dedicatedTo = Service.DedicatedTo.getCurrentDedicatedTo(order.sale);

              let name = dedicatedTo;

              let externalDeliveriesCustomerNames = ['לקוח תן ביס', 'לקוח סיבוס', "לקוח פלאקסי", 'לקוח משלוחה']


              if (posUtils.isBlank(dedicatedTo)) {
                name = order.ordererName;
              }

              let lineData = [name];

              if (order.customerName != name && !externalDeliveriesCustomerNames.includes(order.customerName)) {
                lineData.push(order.customerName)
              }

              lineData.push(order.phoneNumber)

              return lineData.join('<br />');
            },
            getTaDeliveryShort(order) {
              return order.isTa ? i18next.t('delivery.TA') : i18next.t('delivery.delivery')
            },
            setShowOnlyTa(val) {
              this.showOnlyTa = val;

              if (!jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                this.filterTable();
              }
            },
          },
          setup() {
            const dalpaksStore = Pinia.useDalpaksStore();
            const { dalpaksOrders } = Pinia.storeToRefs(dalpaksStore);

            const { activateDalpaksOrderGetters, deactivateDalpaksOrderGetters } = dalpaksStore
            const dalpaksActions = { activateDalpaksOrderGetters, deactivateDalpaksOrderGetters }

            const globalStore = Pinia.useGlobalStore();
            const { portraitMode, mobileLayout, failedDeliveriesOrders } = Pinia.storeToRefs(globalStore);
            const globalStoreProps = { portraitMode, mobileLayout, failedDeliveriesOrders }

            return { ...globalStoreProps, ...dalpaksActions, dalpaksOrders }
          },
          computed: {
            dialogOptions() {
              return {
                autoOpen: true,
                modal: true,
                dialogClass: 'positive-dialog',
                width: '100%',
                position: 'top',
                resizable: false,
                closeOnEscape: false,
                draggable: false,
              };
            },
            isDeliveriesTablet: () => jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet),
            openOrdersToRender() {
              let orders = this.openOrders;

              if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                orders = this.getRowsAfterFilter(this.dalpaksOrders)
              }

              return _.sortBy(orders, order => (new Date(order.createdAt)).getTime() * -1);;
            },
            deliveryBadges() {
              if (!this.mobileLayout || this.showDeliveredOrders) {
                return;
              }

              let allOrders = jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) ? this.dalpaksOrders : this.orgopenOrders;
              let data = {
                ta: 0,
                deliveries: 0,
              }

              if (!allOrders) {
                return data;
              }

              for (let order of allOrders) {
                if (Service.Delivery.isFurtherActionNeeded(order.sale)) {
                  data[order.isTa ? 'ta' : 'deliveries']++;
                }
              }

              return data;
            },
          },
          data: _initData

        }

        VueApp.component('open-delivery-dialog', openDeliveryOrdersDialog)

      }
    }
  }
}
