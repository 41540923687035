module PositiveTS {
  export module Components {
    export module TableSearchDialog {

      export const route = "/dalpak-search";

      export function getComponent() {
        return {
          template: JST.tableSearchDialog(),
          components: {tableItem: TableItem.getComponent()},
          methods: {
            async loadDalpaks() {
              this.isLoading = true;

              this.$nextTick(async () => {
                await Service.Dalpak.loadAllDalpaks();
                this.isLoading = false;
              });
            },
            async loadDalpakAreas() {
              this.isLoading = true;

              await Service.Dalpak.loadAllDalpakAreas();
              this.isLoading = false
            },
            getDalpakOpenedHour(dalpak) {
              let sale = this.getDalpakSale(dalpak);

              if (!sale) {
                return '';
              }

              return moment(sale.openDate).format('HH:mm');
            },
            getDalpakSaleCustomer(dalpak) {
              return Service.Dalpak.getDalpakSaleCustomer(dalpak);
            },
            dalpakFilterFunction(dalpak) {
              if (dalpak.name.toLowerCase().includes(this.search.toLowerCase())) {
                return true;
              }

              if (dalpak.sale) {
                if (dalpak.sale.salespersonEmployeeName && dalpak.sale.salespersonEmployeeName.toLowerCase().includes(this.search.toLowerCase())) {
                  return true;
                }

                if (Service.Dalpak.getDalpakSaleCustomer(dalpak) && Service.Dalpak.getDalpakSaleCustomer(dalpak).toLowerCase().includes(this.search.toLowerCase())) {
                  return true;
                }
              }

              return false;
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {dalpaksByArea,openedOrdersCount,allDalpaks,allDalpakAreas} = Pinia.storeToRefs(dalpaksStore);
            return {dalpaksByArea,openedOrdersCount,dalpaks:allDalpaks,dalpakAreas:allDalpakAreas}
          },
          computed: {
            filteredDalpaks() {
              if (this.isLoading) {
                return [];
              }

              let dalpaks = [];

              for (let dalpakArea of this.dalpakAreas) {
                dalpaks = dalpaks.concat(this.dalpaksByArea[dalpakArea.railsId.toString()] || []);
              }

              if (this.search) {
                dalpaks = dalpaks.filter(this.dalpakFilterFunction);
              }

              return _.sortBy(dalpaks, 'sortOrder');
            },
          },
          data() {
            return {
              isLoading: false,
              dalpakRefreshInterval: null,
              search: '',
            }
          },
          async mounted() {
            await this.loadDalpakAreas();
            await this.loadDalpaks();
            this.dalpakRefreshInterval = setInterval(() => { this.loadDalpakAreas(), this.loadDalpaks() }, 1000 * 60 * 3);
            await this.$nextTick();
            this.$refs.input.focus();
          },
          beforeUnmount() {
            clearInterval(this.dalpakRefreshInterval)
          },
          beforeRouteEnter: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(i18next.t('searchDalpakDialog.title'))
            Pinia.dalpaksStore.setHideHamburgerMenu(true)
            Pinia.dalpaksStore.setGoBackAction(() => VueServices.Router.goto(SelectDalpakDialog.route))
            next();
          },
          beforeRouteLeave: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(null)
            Pinia.dalpaksStore.setHideHamburgerMenu(true)
            Pinia.dalpaksStore.setGoBackAction(null)
            next();
          }
        }
      }
    }
  }
}
