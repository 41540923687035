module PositiveTS {
    export module Service {
    export module AllItems {
        interface itemList {
            code: string;
            description: string;
            priceZarhan: number;
            promoBuy: string;
            barcode: string;
            size: string;
            color:string;
            supplierName: string;
            currentInventory: number;
            promotions?: Array<{code: string, description: string}>
          }
    
        export function get(code:string) {
            return Service.WasmDB.execAsObject(`select * from item where code = $code LIMIT 1`, {$code: code})[0]
        }

        export function set(code:string,Item:Storage.Entity.Item) {
            //do nothing - this method is just here for compatibility with the original Map object
            return;
        }

        export function values():Array<Storage.Entity.Item> { //this method should not really be run
            return Service.WasmDB.execAsObject('select * from item').map(item => Storage.Entity.Item.import(item));
        }

        export function getItemBarcodeByBarcode(barcode:string) {
            if (session.pos.isRoshemet) {
                let item = session.allItemBarcodes.get(barcode)
                if (item) {
                    return item;
                }
                return session.allItems.get(barcode);
            }
            else {
                return Service.WasmDB.execAsObject(`select item.*,color,size,barcode from itembarcode 
                    inner join item on item.code = itembarcode.code where barcode="${barcode}"`)[0];
            }
        }

        export async function exactSearch (search:string):Promise<itemList[]>{
            
            let _return:itemList[] = [];

            if(session.pos.isRoshemet){
              let items = await appDB.localItems.where('code').equals(search).or('barcode').equals('search').toArray();
              for (let i=0; i < items.length; i++) { 
                let item = items[i]

                let row: itemList = {
                  code: item.code,
                  description: item.description,
                  priceZarhan: item.priceZarhan,
                  promoBuy: item.promoBuy,
                  barcode: item.barcode,
                  size: "",
                  color: "",
                  supplierName: "",
                  currentInventory: item.currentInventory
                }

                if (this.isShowPromotionsOnSearchItemPos){
                  row.promotions = await this.promotionsByPromotionString(item.promoBuy)
                }

                _return.push(row)
              }

            }else {
              let sql = `
                select item.code, item.description, item.priceZarhan, item.promoBuy,
                  itembarcode.barcode, itembarcode.size, itembarcode.color, item.supplierName
                from itembarcode 
                left join item on item.code = itembarcode.code
                where (itembarcode.code = '${search}' or itembarcode.barcode = '${search}') AND 
                      (item.cannotBeSoldSeparately IS NULL OR item.cannotBeSoldSeparately = 0) 
              `;
            
              let result = await PositiveTS.Service.WasmDB.promiseSql(sql)

              for (let i=0; i<result.length; i++){
                let item = result[i]
                                      
                let row: itemList = {
                  code: item.code,
                  description: item.description,
                  priceZarhan: item.priceZarhan,
                  promoBuy: item.promoBuy,
                  barcode: item.barcode,
                  size: item.size,
                  color: item.color,
                  supplierName: item.supplierName,
                  currentInventory: 0
                }

                if (this.isShowPromotionsOnSearchItemPos){
                  row.promotions = await this.promotionsByPromotionString(item.promoBuy)
                }

                _return.push(row)
              }
            }
            return _return
          }
        
    }}}
    