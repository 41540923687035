module PositiveTS {
export module Service {
export module MSR {
    function parseJsonResponse(response, requestId) {
        let responseObj = JSON.parse(response.request.result.jsonResponseStr)
        let statusCode = response.request.result.statusCode
        if (responseObj.ResponseGetData && responseObj.ResponseGetData.RequestId != requestId) {
            return {success: false, message: i18next.t(`newCreditCard.msr.noConnection`), data: null};
        }
        if(statusCode != 0) {
            return checkErrors(statusCode, responseObj);
        }
        return {success: true, message: null, data: responseObj};
    }

    export async function swipeCard(amount:number, requestId?:string) {
        if(posUtils.isBlank(requestId)) {
            requestId = String(new Date().getTime())
        }
        return await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
            portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
            inputType: "4",
            amount: String(amount * 100),
            requestId: requestId
        }, "card_reader")
    }
    export async function swipeTrack2(amount:number) {
        let requestId = String(new Date().getTime())
        try {
            let response = await swipeCard(amount, requestId)
            let result = parseJsonResponse(response, requestId);
            if(result.success) {
                return result.data.ResponseGetData.Track2;
            }   else {
                app.showAlert({
                    header: i18next.t('error'),
                    content: result.message,
                    continueButtonText: i18next.t('ok'),
                    hideCancelButton: true
                }, null, null);
            }
        } catch(error) {
            console.error(error);
            return null;
        }
    }

    function checkErrors(statusCode, responseObj) {
          switch (statusCode) {
            case 10000:
                return {success: false, message: i18next.t('newCreditCard.RETVAL_TIMEOUT'), data: null};
            case 10001:
            case 10002:
                return {success: false, message: i18next.t('newCreditCard.RETVAL_ERROR_OPENING_SERIAL_PORT'), data: null};
            case 10003:
                if(responseObj.ResponseGetData.ResultCode && i18next.te(`newCreditCard.msr.${responseObj.ResponseGetData.ResultCode}`)) {
                    return {success: false, message: i18next.t(`newCreditCard.msr.${responseObj.ResponseGetData.ResultCode}`), data: null};
                }
                return {success: false, message: i18next.t(`newCreditCard.msr.generalError`), data: null};
            default:
                console.error(responseObj)
                return {success: false, message: i18next.t('newCreditCard.GENERAL_ERROR'), data: null};
          }
      }

}}}