module PositiveTS {
  export module Service {
    export module CustomerDisplay {
      export const sunmiPort = "SUNMI"
      export const sunmiScreenPort = "SUNMISCREEN"
      let displayLen = 24;
      let displayLenSUNMI = 16;
      const bc = new BroadcastChannel('customer-diplay')

      export function isSunmiScreen() {
        return jsonConfig.getVal(jsonConfig.KEYS.isCustomerDisplay) &&
          jsonConfig.getVal(jsonConfig.KEYS.customerDisplayCom).toUpperCase() == sunmiScreenPort
      }

      export function sendToScreenShowTotalToPay(shouldShow: boolean) {
        bc.postMessage({ type: 'show-total-to-pay', shouldShow })
      }

      export function sendParamsToScreen() {

        if (isSunmiScreen()) {

          let welcomeText = jsonConfig.getVal(jsonConfig.KEYS.customerScreenWelcomeText)
          welcomeText = typeof welcomeText == 'string' ? welcomeText.trim() : null

          let goodbyeText = jsonConfig.getVal(jsonConfig.KEYS.customerScreenGoodbyeText)
          goodbyeText = typeof goodbyeText == 'string' ? goodbyeText.trim() : null

          const baseCurrencyPos = Service.MultiCurr.getInstance().getPosCurrency()

          const decimalPrecision = posUtils.isDefined(session.pos.parameterDecimalPrecision) ? session.pos.parameterDecimalPrecision : 1
          let params = {
            welcomeText: welcomeText ? welcomeText : null,
            goodbyeText: goodbyeText ? goodbyeText : null,
            decimalPrecision: decimalPrecision,
            customerDisplayWithImgs: jsonConfig.getVal(jsonConfig.KEYS.customerDisplayWithImgs) || false,
            customerDisplayWithCurrenciesScreen: jsonConfig.getVal(jsonConfig.KEYS.customerDisplayWithCurrenciesScreen),
            idleModeImageUrls: session?.pos?.customerDisplayIdleModeImgUrlArr || [],
            idleModeImageSwapTimeout: jsonConfig.getVal(jsonConfig.KEYS.customerDisplayOnSaleImagesTimeout) || 10,
            isCustomerDisplayNewDesign: jsonConfig.getVal(jsonConfig.KEYS.isCustomerDisplayNewDesign),
            onSaleImageUrl: session?.pos?.customerDisplayOnSaleImgUrl || '',
            lang: jsonConfig.getVal(jsonConfig.KEYS.lang) || 'he',
            baseCurrencyPos,
            currencies: posPaymentVC.Currencies,
            LastUpdateCurrencies: posPaymentVC.LastUpdateCurrencies,
            expectedCurrencies: Service.MultiCurr.getInstance().getPosCurrenciesEnabled()
          }

          bc.postMessage({ type: 'set-params', params })

        }

      }

      export function updateCurrenciesRate() {
        if (jsonConfig.getVal(jsonConfig.KEYS.customerDisplayWithCurrenciesScreen)) {
          const currencies = {
            currencies: posPaymentVC.Currencies,
            LastUpdateCurrencies: posPaymentVC.LastUpdateCurrencies,
            expectedCurrencies: Service.MultiCurr.getInstance().getPosCurrenciesEnabled()
          }
          bc.postMessage({ type: 'currencies-update', currencies })
        }
      }

      export function updatePosPaymentCurrency(code) {
        bc.postMessage({ type: 'selcted-currency', code })
      }

      export function updateScreenSaleClose() {
        bc.postMessage({ type: 'close-sale' })
      }

      export function sendSaleToScreen() {

        const items = prepareSaleItemsData()
        const saleDiscounts = prepareSaleDiscountsData()

        const cart = {
          items,
          totalAmount: posVC.getTotalAmount(),
          totalQuantity: posVC.getTotalQuantity(),
          saleDiscounts,
        }

        bc.postMessage({ type: 'cart-updated', cart })

      }

      export function sendQRCode(QRCode) {
        bc.postMessage({ type: 'show-qr', QRCode })
      }

      export function hideQRCode() {
        bc.postMessage({ type: 'hide-qr' })
      }

      function prepareSaleDiscountsData() {
        let saleDiscounts = []
        const sale = posVC.sale

        if (session.pos.useNewPromotions) {

          let jd = JSON.parse(sale.jsondata)

          if (jd.promotions) {
            for (let promotion of jd.promotions) {
              if (promotion.isSaleDiscount) {
                let saleDiscount = {
                  amount: promotion.discountAmountForGroup,
                  name: promotion.promoName
                }

                saleDiscounts.push(saleDiscount)
              }
            }
          }
        }

        if (sale.saleDiscountAmount) {
          let saleDiscount = {
            amount: sale.saleDiscountAmount,
            name: sale.discountName
          }

          saleDiscounts.push(saleDiscount)
        }

        return saleDiscounts
      }

      function prepareSaleItemsData() {

        let promotionsArray = []
        let items = [];
        for (let saleItem of posVC.saleItems) {
          items.push(_.cloneDeep(saleItem))
        }

        if (items.length) {

          if (session.pos.useNewPromotions) {

            let jd = JSON.parse(posVC.sale.jsondata)


            if (jd.promotions) {
              jd.promotions.forEach((promotion, index) => {
                if (!promotion.isSaleDiscount) {
                  if (promotionsArray[promotion.rowToShowOn]) {
                    promotionsArray[promotion.rowToShowOn].push({
                      amount: promotion.discountAmountForGroup,
                      name: promotion.promoName,
                      isDiscount: promotion.isDiscount,
                      isNotLast: index < (jd.promotions.length - 1)
                    })
                  } else {
                    promotionsArray[promotion.rowToShowOn] = [{
                      amount: promotion.discountAmountForGroup,
                      name: promotion.promoName,
                      isDiscount: promotion.isDiscount,
                      isNotLast: index < (jd.promotions.length - 1)
                    }];
                  }
                }
              });

            }
          } else {

            items.forEach((i) => {
              if (i.discountAmount != '-1') {
                promotionsArray[i.rowNumber] = [{
                  amount: Number(i.discountAmount),
                  name: i.discountName,
                  isDiscount: i.discountType == '1' ? true : false
                }];
              } else {
                delete i['promotions']
              }
            })

          }

          //add promotions to items
          if (promotionsArray.length) {
            promotionsArray.forEach((promotions, showOnRow) => {
              items.forEach(i => {
                if (i.rowNumber == showOnRow) {
                  i.promotions = JSON.stringify(promotions)
                }
                if(i.children){
                  i.children.forEach(c => {
                    if (c.rowNumber == showOnRow) {
                      try {
                        let itemPromotions =  JSON.parse(i.promotions);
                        itemPromotions = i.promotions.concat(promotions)
                        i.promotions = JSON.stringify(itemPromotions);
                      }catch(e){
                        i.promotions = JSON.stringify(promotions)
                      }
                    }
                  })
                }
              })
            })
          }

        }

        return items
      }

      function _isSunmi() {
        let isAndroidCustomerDisplay = jsonConfig.getVal(jsonConfig.KEYS.customerDisplayCom) === sunmiPort && session.isAndroid;
        if (isAndroidCustomerDisplay == true) {
          displayLen = displayLenSUNMI;
        }
        return _isEnabled() && isAndroidCustomerDisplay;
      }

      export function print(line1: string, line2: string) {
        if (!_isEnabled()) { return Promise.resolve(); }
        if (_isSunmi()) {
          if (SUNMITwoLinesAvailable()) {
            Android.sendLCDDoubleString(line1, line2);
          }
          else {
            Android.sendLCDString(line1)
          }
          return Promise.resolve();
        }

        const codePage = "iso-8859-8";
        const com = jsonConfig.getVal(jsonConfig.KEYS.customerDisplayCom);

        var sLine1 = PositiveTS.Service.StrUtils.lpad(printer.supportHebrew(line1), displayLen, " ")
        var sLine2 = PositiveTS.Service.StrUtils.lpad(printer.supportHebrew(line2), displayLen, " ")

        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          {
            "com": com,
            "codepage": codePage,
            "lines": String.fromCharCode(12) + //Clear
              String.fromCharCode(27) + String.fromCharCode(116) + String.fromCharCode(15) +
              String.fromCharCode(12) + String.fromCharCode(17)   //Set codepage
              + sLine1 + "\r" + sLine2
          }
          ,
          "customer_display"
        ).then((x) => {
          return x.request.result;
        }).catch((e: Error) => {
          console.error("Customer display addon might not be installed.");
          console.error(e.message);
        });
      }

      function _isEnabled() {
        return jsonConfig.getVal(jsonConfig.KEYS.isCustomerDisplay) && !isSunmiScreen();
      }

      function SUNMITwoLinesAvailable() {
        return Android.sendLCDDoubleString != undefined && navigator.userAgent.toLowerCase().indexOf('t1mini') == -1;
      }

      export function setTextWelcome() {
        var line1 = "ברוכים";
        var line2 = "הבאים";
        if (_isSunmi() && !SUNMITwoLinesAvailable()) {
          line1 = "שלום"
          line2 = ""
        }
        return print(line1, line2);
      }

      export function setTextFarewell() {
        var line1 = "תודה";
        var line2 = "ולהתראות";
        if (_isSunmi() && !SUNMITwoLinesAvailable()) {
          line1 = "להתראות"
          line2 = ""
        }
        return print(line1, line2)
      }

      export function setTextTotalSum(smount: string) {
        var line1 = "לתשלום";
        var line2 = smount
        if (_isSunmi() && !SUNMITwoLinesAvailable()) {
          line1 = smount
          line2 = ""
        }
        return print(line1, smount)
      }

      export function setItemDetails(saleItem: Storage.Entity.SaleItem) {
        if (!_isEnabled()) { return Promise.resolve(); }
        try {
          posVC.recalculateDiscountForItem(saleItem);

          var ttlAmount
          if (Number(saleItem.discountAmount) > 0) {
            ttlAmount = session.fixedNumber(saleItem.quantity * saleItem.unitPrice - Number(saleItem.discountAmount))
          } else {
            ttlAmount = session.fixedNumber(saleItem.quantity * saleItem.unitPrice)
          }

          var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);

          var displayTotal = session.fixedNumber(saleTotals.totalAmount);

          var ttlAmountLen = ttlAmount.length
          var itemDescription = saleItem.itemDescription

          itemDescription = itemDescription.substring(0, displayLen - ttlAmountLen)
          itemDescription = PositiveTS.Service.StrUtils.rpad(itemDescription.substring(0, displayLen - ttlAmountLen), displayLen - ttlAmountLen, " ");

          let line1 = itemDescription + ttlAmount
          let line2 = 'סה"כ: ' + displayTotal

          if (_isSunmi() && !SUNMITwoLinesAvailable()) {
            line1 = displayTotal
            line2 = ""
          }
          return print(line1, line2);
        }
        catch (err) {
          console.error(err.message);
          return Promise.resolve();
        }




      }



    }
  }
}
