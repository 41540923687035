module PositiveTS {
export module Application {
export module Controllers {
  enum stepMap {
    items = 1,
    payments = 2,
    finish = 3
  }
export class PosCreditInvoiceViewController extends AbstractViewControllerTS {  

  currentStep:number
  wizard:any
  options:any
  saleProxyOptions:any
  canceledParxellDetails:any

  //TODO: gili's whole credit praxell implementaion is a mess! needs a re-write...
  messageCanceledParxellError:string 
  isCanceledParxellError:boolean
  isCancelPraxle:boolean

  backTo:string
  originalSaleItemsAndPayments:any
  itemsAllowedToCredit:any
  paymentsAllowedToCredit:any
  itemsIdAmount:any
  itemsIdSaleItem:any
  paymentsToCredit: { [key: string]: PositiveTS.Storage.Entity.SalePayment }
  rowIdSaleItemId:any
  childrenItems:Array<any>
  roundAmount:number

  constructor() {
    super();
    this.currentStep = 1
    this.wizard = null
    this.originalSaleItemsAndPayments = null
    this.itemsIdAmount = {}
    this.itemsIdSaleItem = {}
    this.paymentsToCredit = {}
    this.rowIdSaleItemId = {}
    this.saleProxyOptions = {}
    this.itemsAllowedToCredit = null

    this.options = {}

    this.backTo = 'pos-past-invoices'

  }

  isFullSaleRefundOnly () {
    let result = jsonConfig.getVal(jsonConfig.KEYS.fullSaleRefundOnly)

    if (Array.isArray(this.originalSaleItemsAndPayments?.items) && this.originalSaleItemsAndPayments.items.some(item => item.itemCode == Service.ValueCardService.getValueDiscountItemCode())){
      result = true
    }

    return result
  }
  
  initWizard() {
    var aThis = posCIVC;

    // Initialize the slideshow plugin which is used to mimic the wizard style flow
    if (aThis.wizard == null) {
      aThis.wizard = $('#pos-credit-invoice #credit-invoice-steps-container').bjqs({
        'width'     : '100%',
        'height'    : 'calc(100vh - 250px)',
        'showMarkers'   : false,
        'showControls'  : false,
        'centerMarkers' : false,
        'automatic'   : false,
        'useCaptions' : false,
        'keyboardNav' : false
      });
      $('#pos-credit-invoice #credit-invoice-steps-container').css({
          'width': ''
      });
    }
  }

  initTables() {
    var aThis = posCIVC;

    // Initialize the tableview
    var itemsTableView    = $('#pos-credit-invoice-items-tableview').tableview({freshInstance:true});
    var paymentsTableView = $('#pos-credit-invoice-payments-tableview').tableview({freshInstance:true});

    // Set the table view header
    itemsTableView.setHeader(`
      <div class='ui-grid-c'>
        <div class='ui-block-a' style='width: 24%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.itemCode")}</div></div>
        <div class='ui-block-b' style='width: 20%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.description")}</div></div>
        <div class='ui-block-c' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.quantity")}</div></div>
        <div class='ui-block-d' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.beforeDiscount")}</div></div>
        <div class='ui-block-e' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.amountToCredit")}</div></div>
        <div class='ui-block-f' style='width: 17%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.quantityToCredit")}</div></div>
        <div class='ui-block-f' style='width: 9%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.total")}</div></div>
      </div>`);

    // Set the table view header
    paymentsTableView.setHeader(`
      <div class='ui-grid-c'>
        <div class='ui-block-a' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.paymentType")}</div></div>
        <div class='ui-block-b' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.amount")}</div></div>
        <div class='ui-block-c' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.amountToReturn")}</div></div>
        <div class='ui-block-d' style='width: 20%'><div class='ui-tableview-header-cell'></div></div>
        <div class='ui-block-e' style='width: 10%'><div class='ui-tableview-header-cell'>${i18next.t("posCreditInvoice.details")}</div></div>
      </div>`);

    // Set the number of visible rows
    let numRows = 5;
    if (Pinia.globalStore.portraitMode) {
      numRows = 8
    }
    itemsTableView.setNumberOfVisibleRows(numRows);
    paymentsTableView.setNumberOfVisibleRows(numRows);

    // Set a listener for row select
    itemsTableView.attachSelectRowListener('posRowSelectListener', function (theRow) {
      var itemId = aThis.rowIdSaleItemId[theRow.attr('id')];
      var selectedSaleItem = aThis.itemsIdSaleItem[itemId];

      for (var i in aThis.originalSaleItemsAndPayments.items) {
        var currentItem = aThis.originalSaleItemsAndPayments.items[i];
        if (selectedSaleItem.similar(currentItem)) {
          var creditedPastAmount = currentItem.quantity - selectedSaleItem.quantity;
          if (creditedPastAmount > 0) {
            $('#pos-credit-invoice-items-history').text(i18next.t('creditSaleItemCreditedAmountMsg', {AMOUNT: creditedPastAmount}));
          }else {
            $('#pos-credit-invoice-items-history').text(' ');
          }
        }
      }
    });
  }

  forceMoneyReturnOnly(paymentData = null) {
    var aThis = posCIVC;

    if (!paymentData) {
      paymentData = $('.return-status').data('payment-object') || {}
    }

    return aThis.originalSaleItemsAndPayments.invoiceType == Storage.Entity.Sequence.TYPE_SHIPMENT_INV || paymentData.refundMoneyOnly;
  }

  forceCreditVoucherOnly(paymentData = null) {
    
    if (!paymentData) {
      paymentData = $('.return-status').data('payment-object') || {};
    }

    return paymentData.refundInCreditVoucherOnly;
  }

  isContainAutoReturnVouchers (callback) {
    var aThis = posCIVC;

    var isVoucherAutoReturn = function (voucherTypeId, callback) {
      aThis.isVoucherAutoReturn(voucherTypeId, function (isAutoReturning) {
        if (isAutoReturning) {
          callback('conitain auto returning voucher', true);
        } else {
          callback(null, false);
        }
      });
    };

    var potentialAutoVouchersTypeID = [];
    for (var i = 0; i < aThis.paymentsAllowedToCredit.length; i++) {
      var currentPayment = aThis.paymentsAllowedToCredit[i];
      if (currentPayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER || currentPayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
        var vouchers = JSON.parse(currentPayment.data);
        for (var j = 0; j < vouchers.length; j++) {
          var voucherData = vouchers[j];
          var index = potentialAutoVouchersTypeID.indexOf(voucherData.voucher_type_id);
          if (index == -1) {
            potentialAutoVouchersTypeID.push(voucherData.voucher_type_id);
          }
        }
      }
    }

    if (potentialAutoVouchersTypeID.length > 0) {
      async.mapSeries(potentialAutoVouchersTypeID, isVoucherAutoReturn, function (err, results) {
        callback(err != null);
      });
    } else {
      callback(false);
    }
  }
  getItemsTotalQuantity () {
    var aThis = posCIVC;

    var saleItems = aThis.itemsAllowedToCredit;
    var totalQuantity = 0;

    for (var i = 0; i < saleItems.length; i++) {
      totalQuantity += saleItems[i].quantity;
    }

    return totalQuantity;
  }
  getItemsToCreditQuantity () {
    var aThis = posCIVC;

    var totalQuantity = 0;

    for (var itemID in aThis.itemsIdAmount) {
      totalQuantity += aThis.itemsIdAmount[itemID];
    }

    return totalQuantity;
  }
  doesAllItemSelected () {
    var aThis = posCIVC;

    var totalQuantity = aThis.getItemsTotalQuantity();

    var quantityToCredit = aThis.getItemsToCreditQuantity();

    return totalQuantity == quantityToCredit;
  }
  isVoucherAutoReturn (voucherTypeID, callback) {
    callback = callback.bind(this);

    var voucherModel = new PositiveTS.Storage.Entity.Voucher();
    let results = voucherModel.fetchByTypeID(voucherTypeID);

    if (! results[0]) {
      throw new Error("Could not find voucherTypeID " + voucherTypeID);
    }

    callback(results[0].allowCreditVoucher != 1);
  }
  // --------------------------------------------------------------------
  // Controller Life-Cycle
  // --------------------------------------------------------------------
  init (options) {
    var aThis = posCIVC;

    $('#pos-credit-invoice-replace').unbind('click');
    $('#pos-credit-invoice-replace').click(function () {
      PositiveTS.Service.CreditInvoiceReplaceItem.isValid().then((isValid) => {
        if (isValid.status ) {
          Service.CreditInvoiceReplaceItem.addSelectedItemsToInvoice()
          posCIVC.back();
          $(".pos-tab-sale").trigger("click");
          
          // do return the item
        } else {
          //Show alert to the user
          app.showAlert({
            header: i18next.t('error'),
            content: isValid.err,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          }, null, null);
        }

      })
    });

    if(jsonConfig.getVal(jsonConfig.KEYS.allowItemsChangeFromCreditInvoice) == false){
      $('#pos-credit-invoice-replace').hide();
    }

    $('#pos-credit-invoice-select-all').unbind('click');
    $('#pos-credit-invoice-select-all').click(aThis.selectAllContinue);
    $('#pos-credit-invoice-select-all').show();

    $('#pos-credit-invoice-back').click(this.back);

    $('#pos-credit-invoice-credit').unbind('click');
    $('#pos-credit-invoice-credit').click(function () {
      
      let ItemsAmountToCredit = aThis.calcuateItemsAmountToCredit()
      const isSaleWithValueCardDiscountItem = aThis.originalSaleItemsAndPayments.items.some(item => item.itemCode == PositiveTS.Service.ValueCardService.getValueDiscountItemCode())

      if (ItemsAmountToCredit < 0 || (ItemsAmountToCredit == 0 && isSaleWithValueCardDiscountItem)) {
        aThis.isContainAutoReturnVouchers(function (containAutoReturningVouchers) {
          var doesAllItemSelected = aThis.doesAllItemSelected();
          if (!containAutoReturningVouchers || (containAutoReturningVouchers && doesAllItemSelected)) {
            aThis.changeStep(aThis.currentStep + 1);
          } else {
            //Show alert to the user
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t('creditSaleMustSelectAllItemsWhereContainAutoCreditVouchersError'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            }, null, null);
          }
        });
      } else if (ItemsAmountToCredit == 0) {
        //Show alert to the user
        app.showAlert({
          header: i18next.t('error'),
          content: i18next.t('creditSaleMustSelectItemsToCreditError'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        }, null, null);
      }
      else if(ItemsAmountToCredit > 0){
        app.showAlert({
          header: i18next.t('error'),
          content: i18next.t('creditSaleMustbeNegative'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        }, null, null);
      }
    });

    this.initTables();

    this.initWizard();
  }
  selectAllContinue(){
    var pcivc = posCIVC  

    let creditSaleItemsExistInPos:boolean = pcivc.itemsAllowedToCredit.every(item => {
      return session.allItems.get(item.itemCode)
    })

      if(creditSaleItemsExistInPos){
            pcivc.selectAll();
            $('#pos-credit-invoice-select-all').hide();
        
            $('#pos-credit-invoice-credit').trigger("click");

        } else {
          app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('posCreditInvoice.cannotCreditLockedItem'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true});
        }
    } 

  selectAll() {
    var pcivc = posCIVC
    var id;
    var qty;
    for (var i=0;i<pcivc.itemsAllowedToCredit.length;i++){
      id=pcivc.itemsAllowedToCredit[i].id;
      qty=pcivc.itemsAllowedToCredit[i].quantity;
      pcivc.itemsIdAmount[id] = qty;
    }
  }
  //This method will set the table view header
  setTableHeader(saleData) {

    //If sale data exist
    if (saleData) {

      //Split the string
      var saleDataArr = saleData.split(';');

      //Invoice value
      var invoiceSequence = saleDataArr[0];
      var createdAt = saleDataArr[1];
      var cashierEmployeeName =  saleDataArr[2];

      $('.header-invoice-number').text(invoiceSequence);
      $('.header-invoice-date').text(posUtils.getDateStr(createdAt));
      $('.header-invoice-cashier').text(cashierEmployeeName);
    }
  }
  resumeItems() {
    var aThis = posCIVC;

    aThis.itemsIdAmount = {};
    aThis.itemsIdSaleItem = {};
    aThis.rowIdSaleItemId = {};

    $('#pos-credit-invoice-items-history').text(' ');


    var saleItems = aThis.itemsAllowedToCredit;

    aThis.refreshAmountsToCredit();

    // Initialize the tableview
    var tableView = $('#pos-credit-invoice-items-tableview').tableview();

    // Empty the table so we can be sure we are displaying only the latest results
    tableView.empty();

    var selectedRow = false;
     // Update total payments
     $('#pos-credit-invoice-sale-info-total').text(session.fixedNumber(0));

    for(var i=0;i<saleItems.length;i++){
      if (saleItems[i].quantity !== 0 || saleItems[i].itemCode === jsonConfig.getVal(jsonConfig.KEYS.beengoDiscountSaleItemCode) ) {
        aThis.addSaleItemRow(saleItems[i], tableView);
      }
    }

    if(aThis.isFullSaleRefundOnly()) {
      aThis.selectAll();
      $('#pos-credit-invoice-select-all').hide();
      aThis.updateTotalItemsIndicator();
    }
  }
  addSaleItemRow(saleItem, tableView) {
    var aThis = posCIVC;
    // Format the color/size for the table
    var colorSize = [];
    if (saleItem.color != '') {
      colorSize[0] = saleItem.color;
    }
    if (saleItem.size != '') {
      colorSize[colorSize.length] = saleItem.size;
    }

    var itemRealPrice = aThis.calcuateItemPriceAfterDiscounts(saleItem);

    // Format the total amount for this item
    var total = saleItem.quantity * itemRealPrice;
    var totalBeforeDiscount = saleItem.quantity * saleItem.originalUnitPrice;

    // Format the quantity for the table
    var quantityText = saleItem.quantity;
    if (saleItem.quantity > 1) {
      quantityText = quantityText + ' X ' + session.fixedNumber(itemRealPrice);
    }

    aThis.itemsIdAmount[saleItem.id] = 0;
    aThis.itemsIdSaleItem[saleItem.id] = saleItem;



    var itemDescription = saleItem.itemDescription;
    if (saleItem.isPickup){
      itemDescription += '<img class="pos-tableview-description-cell-pickup-img-show" src="' +
                     (<any>window).images_path + 'pos/plane12.png" class="pos-pickup-icon" />';
    }

    let totalVal = '<div style="direction:ltr;" name="' + saleItem.id + '" class="number pos-credit-invoice-item-count">' + session.fixedNumber(0) + '</div>';

    let rowArray: Array<Array<string> | string>;
    if(aThis.isFullSaleRefundOnly()) {
      rowArray = [
        [saleItem.itemCode,'code'],
        [itemDescription + " " + colorSize.join(' '),'desc'],
        ['<div style="direction: ltr;text-align: right;">' + quantityText + '</div>','qty-text'],
        ['<b>' + session.fixedNumber(totalBeforeDiscount) + '</b>'],
        ['<b>' + session.fixedNumber(total) + '</b>'],
        [saleItem.quantity],
        Pinia.globalStore.mobileLayout ? [session.fixedNumber(total),'total-to-credit',i18next.t('posCreditInvoice.toCredit')] : session.fixedNumber(total)
      ]
    }
    else {
      rowArray = [
        [saleItem.itemCode,'code'],
        [itemDescription + " " + colorSize.join(' '),'desc'],
        ['<div style="direction: ltr;text-align: right;">' + quantityText + '</div>','qty-text'],
        ['<b>' + session.fixedNumber(totalBeforeDiscount) + '</b>'],
        ['<b>' + session.fixedNumber(total) + '</b>'],
        [saleItem.quantity > 0 && itemRealPrice > 0  ? '<input type="text" name="' + saleItem.id + '" class="pos-credit-invoice-item-count-chooser" />' : '','chooser'],
        Pinia.globalStore.mobileLayout ? [totalVal,'total-to-credit',i18next.t('posCreditInvoice.toCredit')] : totalVal
      ]
    }
    var row = tableView.addRow(posUtils.sanitizeTableViewRow(rowArray), false);
    aThis.rowIdSaleItemId[row.attr('id')] = saleItem.id;
    let maxQty = saleItem.quantity;

    if(posUtils.isNullOrUndefined(session.allItems.get(saleItem.itemCode))){
      maxQty = 0;
    }

    if(total < 0 && !Service.SplitSalePayment.isPaymentItem(aThis.originalSaleItemsAndPayments, saleItem)){
      aThis.numberPickerChanged(saleItem.id,saleItem.quantity,maxQty)
    }


    $('#pos-credit-invoice-items-tableview').find('.pos-credit-invoice-item-count-chooser[name="' + saleItem.id + '"]').numberPicker({
      min: 0,
      max: maxQty,
      changed: function (newVal) {
        aThis.numberPickerChanged($(this).attr('name'),newVal,maxQty)
      }
    });
  }

  numberPickerChanged(itemId,newVal,maxQty){
    var aThis = posCIVC;

    if(maxQty == 0){
      app.showAlert({
        header: i18next.t('error'),
        content: i18next.t('posCreditInvoice.cannotCreditLockedItem'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true});
    }

    
    var saleItem = aThis.itemsIdSaleItem[itemId];

    aThis.itemsIdAmount[itemId] = newVal;

    var itemRealPrice = aThis.calcuateItemPriceAfterDiscounts(saleItem);

    var amount = itemRealPrice * newVal;

    $('.pos-credit-invoice-item-count[name="' + itemId + '"]').text(session.fixedNumber(-amount));

    aThis.updateTotalItemsIndicator()
  }

  updateTotalItemsIndicator() {
    var aThis = posCIVC;
    var totalAmountToCredit = 0;
    let allowToReplaceItemsInReplacementSale = jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)
    for (var currentItemId in aThis.itemsIdAmount) {
      if(allowToReplaceItemsInReplacementSale && Service.CreditInvoice.isReplacementSale(aThis.originalSaleItemsAndPayments) && aThis.itemsIdSaleItem[currentItemId].quantity < 0) {
        continue
      }
      totalAmountToCredit += aThis.itemsIdAmount[currentItemId] * aThis.calcuateItemPriceAfterDiscounts(aThis.itemsIdSaleItem[currentItemId]);
   }
    // Update total payments
    $('#pos-credit-invoice-sale-info-total').text(session.fixedNumber(-totalAmountToCredit));
  }
  resumePayments() {
    var aThis = posCIVC;

    aThis.paymentsToCredit = {};

    aThis.refreshAmountsToCredit();

    if (aThis.forceMoneyReturnOnly()) {
      $('#pos-credit-invoice-select-all').hide();
    }

    // Initialize the tableview
    var tableView = $('#pos-credit-invoice-payments-tableview').tableview();

    // Empty the table so we can be sure we are displaying only the latest results
    tableView.empty();

    var salePayments = aThis.paymentsAllowedToCredit;

    var changeSalePayment = null;

    for(var i=0;i<salePayments.length;i++){

      switch(salePayments[i].method){

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
          aThis.addCreditCardRows(salePayments[i],tableView);
          break;

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
          aThis.addCashRow(salePayments[i],tableView);
          break;

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
          changeSalePayment = salePayments[i];
          break;

        case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
        case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
          aThis.addVoucherRows(salePayments[i],tableView);
          break;

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER:
          aThis.addPromotionRows(salePayments[i],tableView);
          break;

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
          aThis.addCheckRows(salePayments[i],tableView);
          break;
      }
    }

    aThis.refreshAmountsToCredit();

    if (changeSalePayment != null) {
      aThis.addChangeRow(changeSalePayment,tableView);
    }

    $('.return-status').booleanStateChanger({ //TODO: this shit needs to be rewritten!!! data should not be persisted on the f$!#@!@ dom!!!
      defaultValue:  false,
      trueClass: 'pos-credit-invoice-state-changer-true',
      falseClass: 'pos-credit-invoice-state-changer-false',
      trueText: `${i18next.t("posCreditInvoice.cancelReturn")}`,
      falseText:`${i18next.t("posCreditInvoice.returnPayment")}`,
      changed: function (newValue) {

        if (newValue) {
          var creditPayment = aThis.creditPayment($(this).data('payment-method'), $(this).data('payment-object'));
          if (typeof creditPayment == "string") {
            app.showAlert({
              header: i18next.t('error'),
              content: creditPayment,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            }, null, null);
            return false;
          }

          var paymentObject = $(this).data('payment-object');
          if(paymentObject && (paymentObject.isEmv ||paymentObject.isGateway) ){
            let creditCardPaymentType = paymentObject.isGateway ? 'Gateway' : 'EMV';
            paymentObject.__proto__ = (<any> Service.CreditCardPayment.createOutputByType(creditCardPaymentType)).__proto__;
          }
          var amount = 0;
          if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()){
            amount = paymentObject === null || paymentObject === undefined || paymentObject.currencyAmount ===  undefined ? creditPayment.amount : -paymentObject.currencyAmount;
          }else{
            amount = paymentObject === null || paymentObject === undefined ? creditPayment.amount : -paymentObject.amount;
          }
          let amountToCreditStr = Pinia.globalStore.mobileLayout ? `${i18next.t("posCreditInvoice.amountToReturn")} ${session.fixedNumber(amount)}` : session.fixedNumber(amount);
          $(this).parents('tr').find('.amount-to-credit').text(amountToCreditStr);
        } else {
          aThis.unCreditPayment($(this).data('payment-method'), $(this).data('payment-object'));
          let amountToCreditStr = Pinia.globalStore.mobileLayout ? `${i18next.t("posCreditInvoice.amountToReturn")} 0.00` : '0.00';
          $(this).parents('tr').find('.amount-to-credit').text(amountToCreditStr);
        }

        aThis.refreshAmountsToCredit();
        return true;
      }
    });



    if (!aThis.forceCreditVoucherOnly()) {
      if(aThis.options.lastSale || jsonConfig.getVal(jsonConfig.KEYS.returnMoneyAsDefault) || aThis.forceMoneyReturnOnly()){
        $(".pos-credit-invoice-state-changer-true:visible").click();
      }
    }
  }
  calcuateItemsAmountToCredit () {
    var aThis = posCIVC;

    var itemsAmountToCredit = 0;
    for (var currentItemId in aThis.itemsIdAmount) {
      if (aThis.itemsIdSaleItem[currentItemId]){
      itemsAmountToCredit -= aThis.itemsIdAmount[currentItemId] * aThis.calcuateItemPriceAfterDiscounts(aThis.itemsIdSaleItem[currentItemId]);
      }
     
    }

    return session.fixedFloat(itemsAmountToCredit);
  }

  calcuateReturnInCreditVoucherAmount () {

    var aThis = posCIVC;
    var itemsAmountToCredit = aThis.calcuateItemsAmountToCredit();


    for (var method in aThis.paymentsToCredit) {
      itemsAmountToCredit -= aThis.paymentsToCredit[method].amount;
    }
    if (Math.abs(itemsAmountToCredit) < 0.1) {
      itemsAmountToCredit = 0
    }

    return session.fixedFloat(itemsAmountToCredit);
  }
  refreshAmountsToCredit () {
    var aThis = posCIVC;

    var itemsAmountToCredit = aThis.calcuateItemsAmountToCredit();

    var withCreditVoucher = aThis.calcuateReturnInCreditVoucherAmount();

    var withPayments = 0;
    for (var method in aThis.paymentsToCredit) {
      withPayments += aThis.paymentsToCredit[method].amount;
    }

    $('#pos-credit-invoice-payments-items-to-credit-amount').text(session.fixedNumber(itemsAmountToCredit));
    $('#pos-credit-invoice-payments-with-credit-voucher-amount').text(session.fixedNumber(withCreditVoucher));
    $('#pos-credit-invoice-payments-with-payment-amount').text(session.fixedNumber(withPayments));
  }
  unCreditPayment (method, paymentData) {
    var aThis = posCIVC;

    switch(method){

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
        if (paymentData && (paymentData.isEmv || paymentData.isGateway)) {
          let creditCardPaymentType = paymentData.isGateway ? 'Gateway' : 'EMV';
          paymentData.__proto__ = (<any> Service.CreditCardPayment.createOutputByType(creditCardPaymentType)).__proto__;
        }
        return aThis.unCreditPaymentData(method, paymentData);


      case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
        return aThis.unCreditCash();


      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
        return aThis.unCreditPaymentData(method, paymentData);


      case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
        return aThis.unCreditPaymentData(method, paymentData);

    }
  }
  paymentDefaultReturn (method, paymentData, callback) {
    var aThis = posCIVC;

    switch(method){
      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
        let voucherModel = new PositiveTS.Storage.Entity.Voucher();
        let results = voucherModel.fetchByTypeID(paymentData.voucher_type_id)
        callback(results[0].allowCreditVoucher);
        return;

      default:
        callback(false);
        break;
    }
  }
  creditPayment (method, paymentData) {
    var aThis = posCIVC;

    switch(method){

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
        return aThis.creditCreditCard(paymentData);

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
        return aThis.creditCash();

      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
        return aThis.creditPaymentData(method, paymentData);

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
        return aThis.creditPaymentData(method, paymentData);
    }
  }
  unCreditPaymentData (method, paymentData) {
    var aThis = posCIVC;

    var currentPayment = aThis.paymentsToCredit[method];

    if (currentPayment === null || currentPayment === undefined) {
      return;
    }

    var currentPaymentData = JSON.parse(currentPayment.data);

    var indexToDelete = null;
    var amountToUncredit = 0;
    for (var i = 0; i < currentPaymentData.length; i++) {
      if (JSON.stringify(currentPaymentData[i]) == JSON.stringify(paymentData)) {
        indexToDelete = i;
        let amount = paymentData.amount || 0;
        if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() && paymentData.currencyRate){
          amount = session.fixedNumber(amount / paymentData.currencyRate);
        }
        amountToUncredit = amount;  
      }
    }

    currentPaymentData.splice(indexToDelete, 1);

    currentPayment.data = JSON.stringify(currentPaymentData);
    currentPayment.amount += Math.abs(Number(amountToUncredit));
  }
  creditCreditCard (paymentData) {
    var aThis = posCIVC;
    if(paymentData && (paymentData.isEmv || paymentData.isGateway)){
      let creditCardPaymentType = paymentData.isGateway ? 'Gateway' : 'EMV';
      paymentData.__proto__ = (<any> Service.CreditCardPayment.createOutputByType(creditCardPaymentType)).__proto__;
    }
    var maxToReturn = -aThis.calcuateReturnInCreditVoucherAmount();

    if (maxToReturn == 0) {
      return i18next.t("creditSaleNotEnoughCreditBackError");
    }

    var currentPayment = aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT];

    if (currentPayment === undefined) {
      currentPayment = new PositiveTS.Storage.Entity.SalePayment();
      currentPayment.amount = 0;
      currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT;
      currentPayment.data = "[]";
    }
    const isMultiCurr = PositiveTS.Service.MultiCurr.getInstance().isMultiCurr();
    var amountToCredit = isMultiCurr ? (paymentData.amount / paymentData.currencyRate) : paymentData.amount;
  
    if (amountToCredit > maxToReturn && !(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund) && aThis.isFullSaleRefundOnly())) {
      amountToCredit = maxToReturn;
      paymentData.amount = maxToReturn;
    }

    if (isMultiCurr) {
      paymentData.currencyAmount = amountToCredit * paymentData.currencyRate;
      paymentData.creditCurrencyFullNumber = amountToCredit * paymentData.currencyRate;
      paymentData.creditLeadCurrencyAmount = amountToCredit;
    }

    let currentPaymentData = JSON.parse(currentPayment.data);

    if (isMultiCurr && session.pos.hasFlights) {
      currentPayment.amount = Service.CreditCardPayment.sumPaymentsDataAmountInBaseCurrency(currentPaymentData.concat([paymentData])) *-1
    } else {
      currentPayment.amount -= Number(amountToCredit);
    }


    if (!paymentData.isEmv) {
    paymentData = $.extend(paymentData,
         PositiveTS.Service.CreditDal._formatFirstPaymentAndFixedAmount(
           PositiveTS.Service.CreditDal._getCreditAmountFormat(paymentData.amount),
         paymentData.payments_count)
    )
    }

    currentPaymentData.push(paymentData);
    currentPayment.data = JSON.stringify(currentPaymentData);

    aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT] = currentPayment;

    return currentPayment;
  }
  creditPaymentData (method, paymentData) {
    var aThis = posCIVC;

    var maxToReturn = -aThis.calcuateReturnInCreditVoucherAmount();
    var currentPayment = aThis.paymentsToCredit[method];
    var creditAmount = Math.min(maxToReturn,Math.abs(Number(paymentData.amount)));

    if ( (!paymentData.allowPartialReturn && paymentData.voucher_type_id !== PositiveTS.Service.Hakafa.getVoucherID()) &&
          paymentData.amount > (maxToReturn + 0.2)
        ) {
      return i18next.t("creditSaleNotEnoughCreditBackError");
    }

    if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund) && aThis.isFullSaleRefundOnly()) {
      creditAmount =  Math.abs(Number(paymentData.amount));
    }


    if (currentPayment === undefined) {
      currentPayment = new PositiveTS.Storage.Entity.SalePayment();
      currentPayment.amount = 0;
      currentPayment.method = method;
      currentPayment.data = "[]";
    }

    currentPayment.amount -= creditAmount;
    var currentPaymentData = JSON.parse(currentPayment.data);
    paymentData.amount = creditAmount * -1;
    currentPaymentData.push(paymentData);
    currentPayment.data = JSON.stringify(currentPaymentData);

    aThis.paymentsToCredit[method] = currentPayment;

    return currentPayment;
  }
  unCreditCash () {
    var aThis = posCIVC;

    delete aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH];
  }
  creditCash () {
    var aThis = posCIVC;

    var maxToReturn = -aThis.calcuateReturnInCreditVoucherAmount();

    if (maxToReturn == 0) {
      return i18next.t("creditSaleNotEnoughCreditBackError");
    }

    var salePayments = aThis.paymentsAllowedToCredit;

    var cashPayment = null;
    var changePayment = null;


    // find cash and change payments
    for(var i=0;i<salePayments.length;i++){

      switch(salePayments[i].method){

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
          cashPayment = salePayments[i];
          break;

        case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
          changePayment = salePayments[i];
          break;
      }
    }

    var amountToCredit = parseFloat(cashPayment.amount);

    if (changePayment != null) {
      amountToCredit -= parseFloat(changePayment.amount);
    }

    if (amountToCredit > maxToReturn) {
      amountToCredit = maxToReturn;
    }

    if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund) && aThis.isFullSaleRefundOnly()) {
      amountToCredit = parseFloat(cashPayment.amount) - parseFloat(changePayment ? changePayment.amount : 0);
    }

    
    var creditCashSalePayment = new PositiveTS.Storage.Entity.SalePayment();
    creditCashSalePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CASH;
    creditCashSalePayment.amount = -amountToCredit;
    let multiCurrService = Service.MultiCurr.getInstance();
    let isMultiCurr = multiCurrService.isMultiCurr();
    if (session.pos.hasFlights && isMultiCurr && jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund) && aThis.isFullSaleRefundOnly()) {


      let allPayments = [];
      let cashPaymentData = [];
      let changePaymentData = [];

      if (cashPayment && (!posUtils.isBlank(cashPayment.data)) && JSON.parse(cashPayment.data).length > 0) {
        cashPaymentData = JSON.parse(cashPayment.data);
      }

      if (changePayment && (!posUtils.isBlank(changePayment.data)) && JSON.parse(changePayment.data).length > 0) {
        changePaymentData = JSON.parse(changePayment.data);
      }

      for (let paymentData of cashPaymentData) {
        let paymentDataClone = _.clone(paymentData);
        paymentDataClone = Service.MultiCurr.getInstance().multiplyCurrencyData(paymentDataClone, -1);
        allPayments.push(paymentDataClone);
      }

      allPayments = allPayments.concat(changePaymentData.map(payment => _.clone(payment)));

      creditCashSalePayment.data = JSON.stringify(allPayments);
    } else if (isMultiCurr && multiCurrService.creditInOriginalCashCurrencyRelatively() && aThis.wasSalePaidWithCashPaymentOnly()) {
      let cashPaymentData: PositiveTS.Types.PaymentData[] = [];
      let changePaymentData: {[key: string]: PositiveTS.Types.PaymentData } = {};
      if (cashPayment && (posUtils.isPresent(JSON.parse(cashPayment.data)))) {
        cashPaymentData = JSON.parse(cashPayment.data);
      }
      if (changePayment && (posUtils.isPresent(JSON.parse(changePayment.data)))) {
        let changePaymentDataArr: PositiveTS.Types.PaymentData[] = JSON.parse(changePayment.data);
        changePaymentData = _.keyBy(changePaymentDataArr, 'paid_currency_symble')
      }
      let amountToCreditInPosCurrency = amountToCredit;
      let totalAmount = aThis.originalSaleItemsAndPayments.totalAmount;
      let allPayments = cashPaymentData.map(paymentData => {
        let theRelativePercentOfRefundAmount = amountToCreditInPosCurrency / totalAmount;
        let currencyCode = paymentData.paid_currency_symble;
        let currencyChange = changePaymentData[currencyCode]?.paid_currency_amount || 0;
        let amountRefund = -1 * ((paymentData.paid_currency_amount - currencyChange) * theRelativePercentOfRefundAmount);
        let currRate = paymentData.paid_currency_rate;
        let [leadCurrencyAmount, creditPaymentData] = multiCurrService.translateAmountAndCreateRowSalePaymentData(amountRefund, currencyCode, currRate);
        return creditPaymentData;
      });
      creditCashSalePayment.data = JSON.stringify(allPayments);
    }

    aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH] = creditCashSalePayment;

    return creditCashSalePayment;
  }
  
  isOriginalDateOfPaymentTodayAndSamePos(saleSoldAt, posDeviceID){
    let paymentTime = new Date(saleSoldAt);
    let today = new Date();
    let diffDays = (new Service.DateDiff(paymentTime, today)).diffDays;
    return ( diffDays <= 1 && session.pos.deviceID === posDeviceID ) ;
  }

  isPaymentReturnAble(salePayment) {
    let aThis = posCIVC;
    return aThis.isPaymentReturnAbleEx(salePayment, aThis.originalSaleItemsAndPayments.createdAt, aThis.originalSaleItemsAndPayments.posDeviceID)
  }

  isPaymentReturnAbleEx(salePayment, createdAt, posDeviceID) {
    
    let aThis = posCIVC;
    

    let paymentTime = new Date( aThis.convertDate( createdAt.split(" ")[0] ) );
    if (String(paymentTime) == "Invalid Date") {
      paymentTime = new Date(createdAt);
    }
    var today = new Date();
    var diffDays = (new Service.DateDiff(paymentTime, today)).diffDays;

    switch(salePayment.method){

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
        return true;

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:


      if ( aThis.isOriginalDateOfPaymentTodayAndSamePos(createdAt, posDeviceID) && jsonConfig.getVal(jsonConfig.KEYS.MaxDaysForCashReturn) !== 0 ) {
        return true;
      } else {
        var isInMaxDaysForCashReturn = ( diffDays < jsonConfig.getVal(jsonConfig.KEYS.MaxDaysForCashReturn))
        return isInMaxDaysForCashReturn;
      }

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
        return false;

      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
      case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
        return false;

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER:
        return false;

      case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
        return true;
    }
  }
  convertDate ( date )
  {
    var pattern=/(.*?)\/(.*?)\/(.*?)$/;
    var result = date.replace(pattern,function(match,day,month,year){
        var x = month+"/"+day+"/"+year;
        return x;
    });
    return result;
  }
  isVoucherReturnAble (salePayment, voucherData) {
    var aThis = posCIVC;
    if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
      if (voucherData && voucherData.voucher_type_id == PositiveTS.Service.Hakafa.getVoucherID()) {
        return true;
      }
    }

    return true;
  }
  wasSalePaidWithCashPaymentOnly(): boolean {
    let aThis = this;
    let cashPaymentMethods = [PositShared.SalePaymentMethod.CASH, PositShared.SalePaymentMethod.CHANGE];
    return aThis.originalSaleItemsAndPayments.payments.every(payment => cashPaymentMethods.includes(payment.method));
  }
  showMultiCurrDetails(payment) {

    let rows = [];
    let data = JSON.parse(payment.data);

    for (let currPayment of data) {
      rows.push(`${session.fixedFloat(currPayment.paid_currency_amount, 2)} ${currPayment.paid_currency_symble}`)
    }

    app.showAlert({
      header: i18next.t('posCreditInvoice.details'),
      content: rows.join('\n'),
      continueButtonText: i18next.t("ok"),
      hideCancelButton: true
    }, null, null);
  }
  addChangeRow(salePayment, tableview) {
    var aThis = posCIVC;

    let changeDetailsButton = session.pos.hasFlights && (!posUtils.isBlank(salePayment.data)) && JSON.parse(salePayment.data).length > 0 ? '<button id="credit-details-button" class="positive-simple light-blue shadow">' + i18next.t("posCreditInvoice.details") + '</button>' : '';

    var posMethodType = i18next.t("posCreditInvoice.change");
    tableview.addRow(posUtils.sanitizeTableViewRow([
      [posMethodType,"method",""],
      [session.fixedNumber(salePayment.amount) + (session.pos.hasFlights ? ' ' + PositiveTS.Service.MultiCurr.getInstance().getPosCurrency() : ''),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
      ["0.00",'amount-to-credit',i18next.t("posCreditInvoice.amountToReturn") + ": "],
      [(aThis.isPaymentReturnAble(salePayment) ? '<div class="return-status" data-payment-method="' + salePayment.method + '"></div>' : '') + changeDetailsButton,'return-status-td',''],
      ['','empty-cell',''],
      ['','empty-cell',''],
      ['','empty-cell',''],
      ['','empty-cell',''],
      ['','empty-cell',''],
      ['','empty-cell',''],
     ]), false);

    if(session.pos.hasFlights){           
      $('#credit-details-button').click(() => aThis.showMultiCurrDetails(salePayment));
    }
  }
  addCashRow(salePayment, tableview) {
    var aThis = posCIVC;

    var posMethodType = i18next.t("posCreditInvoice.cash");
    let rowArray;
    if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund)) {
      let cashDetailsButton = session.pos.hasFlights && (!posUtils.isBlank(salePayment.data)) && JSON.parse(salePayment.data).length > 0 ? '<button id="cash-details-button" class="positive-simple light-blue shadow">' + i18next.t("posCreditInvoice.details") + '</button>' : '';
      rowArray = [
        [posMethodType,"method",""],
        [session.fixedNumber(salePayment.amount) +
          (session.pos.hasFlights ? ' ' + PositiveTS.Service.MultiCurr.getInstance().getPosCurrency() : '') +
          cashDetailsButton,'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell','']]
    } else {
      rowArray = [
        [posMethodType,"method",""],
        [session.fixedNumber(salePayment.amount),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
        ["0.00",'amount-to-credit',i18next.t("posCreditInvoice.amountToReturn") + ": "],
        [aThis.isPaymentReturnAble(salePayment) ? '<div class="return-status" data-payment-method="' + salePayment.method + '"></div>' : '','return-status-td',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell',''],
        ['','empty-cell','']]
    }

    tableview.addRow(posUtils.sanitizeTableViewRow(rowArray), false);

     if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund)){      
	    aThis.creditPayment(salePayment.method,null);
     }

    if (session.pos.hasFlights) {
      $('#cash-details-button').click(() => aThis.showMultiCurrDetails(salePayment));
    }

  }
  addRegularCreditCardRow(paymentData, tableView, salePayment) {
    var aThis = posCIVC;

    var posMethodType = i18next.t("posCreditInvoice.credit");
    var amount = paymentData.amount;
    var cardNumber  = paymentData.isEmv ? `${paymentData.cardNumberForPrint}****` : paymentData.card_number.substring(paymentData.card_number.length - 6, paymentData.card_number.length);
    let creditTypeText:string = ""

    if (posUtils.isNullOrUndefined(paymentData.payment_type)) {
      creditTypeText = paymentData.payments_count > 1 ? ' ' + i18next.t("posCreditInvoice.payments") : ' ' + i18next.t("posCreditInvoice.regular");
      } else {
      creditTypeText = session.creditCardPaymentTypes[paymentData.payment_type].ccTypeName;
      }

    var cardType =  creditTypeText;
    var experDate   = `${i18next.t("posCreditInvoice.validity")}:<br>`+paymentData.valid_until;
    var phoneNumber = `${i18next.t("posCreditInvoice.phoneNumber")}:<br>`+paymentData.phone_number;
    var cardPayments = `${i18next.t("posCreditInvoice.payments")}:<br>`+paymentData.payments_count;
    var confNumber = `${i18next.t("posCreditInvoice.confirmationNumber")}:<br>`+paymentData.confirmation_number;

      let rowArray;
      if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund)) {

        if (session.pos.hasFlights) {
          rowArray = [[posMethodType,'method',''],
          ['','total-amount',i18next.t("posCreditInvoice.amount") + ": "],
          [session.fixedNumber(amount) + ' ' + paymentData.creditCurrency,'',''],
          [session.fixedNumber(paymentData.creditLeadCurrencyAmount) + ' ' + PositiveTS.Service.MultiCurr.getInstance().getPosCurrency(),'',''],
          [cardNumber,'card-number',''],
          [cardType,'card-type',''],
          [cardPayments,'card-payments',''],
          [Pinia.globalStore.mobileLayout ? "" : experDate,'card-exper',''],
          [Pinia.globalStore.mobileLayout ? "" : phoneNumber,'card-phone-number',''],
          [confNumber,'card-conf-num','']]
        } else {
          rowArray = [[posMethodType,'method',''],
          [session.fixedNumber(amount),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
          ['','empty-cell',''],
          ['','empty-cell',''],
          [cardNumber,'card-number',''],
          [cardType,'card-type',''],
          [cardPayments,'card-payments',''],
          [Pinia.globalStore.mobileLayout ? "" : experDate,'card-exper',''],
          [Pinia.globalStore.mobileLayout ? "" : phoneNumber,'card-phone-number',''],
          [confNumber,'card-conf-num','']]
        }


      } else {
        rowArray = [
          [posMethodType,'method',''],
          [session.fixedNumber(amount),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
          ["0.00",'amount-to-credit',i18next.t("posCreditInvoice.amountToReturn") + ": "],
          [aThis.isPaymentReturnAble(salePayment) ? "<div class='return-status' data-payment-method='" + salePayment.method + "' data-payment-object='" + JSON.stringify(paymentData) + "'></div>" : '','return-status-td',''],
          [cardNumber,'card-number',''],
          [cardType,'card-type',''],
          [cardPayments,'card-payments',''],
          [Pinia.globalStore.mobileLayout ? "" : experDate,'card-exper',''],
          [Pinia.globalStore.mobileLayout ? "" : phoneNumber,'card-phone-number',''],
          [confNumber,'card-conf-num','']
        ]
      }
      
      tableView.addRow(posUtils.sanitizeTableViewRow(rowArray), false);

      if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund)){
        aThis.creditPayment(salePayment.method,paymentData)
      }
  }
  addCreditCardRows(salePayment,tableView){
    var aThis = posCIVC;


    var dataObj = JSON.parse(salePayment.data);
    dataObj.sort((a, b) => {
      if (b.isGateway) {
        return 1;
      } else if (a.isGateway) {
        return -1;
      } else if (b.isEMV) {
        return 1; 
      } else if (a.isEMV) {
        return -1;
      } else {
        return 0;
      }
    });
    for(var i=0;i<dataObj.length;i++){
      let paymentData = dataObj[i];
			if (paymentData && (paymentData.isEmv || paymentData.isGateway)) {
        delete paymentData.amount;
        paymentData.__proto__ = paymentData.isEmv ?  PositiveShared.EmvOutput.prototype : PositiveShared.PaymentGatewayOutput.prototype
            //These attributes casues trouble when parsing them into html data attribute
            //Once again, it shouldnt be in the DOM
            delete paymentData.CardName;
            delete paymentData.TerminalName;
            delete paymentData.ReceiptMerchant;
            delete paymentData.ReceiptCustomer;
      }
      aThis.addRegularCreditCardRow(paymentData,tableView,salePayment)
    }
  }
  addVoucherRows(salePayment,tableView){
    var aThis = posCIVC;

    var posMethodType = i18next.t("posCreditInvoice.voucher");
    var dataObj = JSON.parse(salePayment.data);

    for(var i=0;i<dataObj.length;i++){
      var amount = dataObj[i].amount;
      var creditType = dataObj[i].creditType;
      var barCode = dataObj[i].barCode;
      if(Pinia.globalStore.mobileLayout){

      }
      var rowID = tableView.addRow(posUtils.sanitizeTableViewRow([
        [posMethodType,'method',''],
        [session.fixedNumber(amount),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
        ["0.00",'amount-to-credit',i18next.t("posCreditInvoice.amountToReturn") + ": "],
        [aThis.isVoucherReturnAble(salePayment, dataObj[i]) ? "<div class='return-status' data-payment-method='" + salePayment.method + "' data-payment-object='" + JSON.stringify(dataObj[i]) + "' " + ((aThis.forceMoneyReturnOnly(dataObj[i]) || aThis.forceCreditVoucherOnly(dataObj[i])) ? "style='visibility: hidden;'" : '') + "></div>" : '','return-status-td' ,''],
         [`${i18next.t("posCreditInvoice.creditType")}:<br>` +creditType,'credit-type',''],
         [barCode != "" ? `${i18next.t("posCreditInvoice.barcode")}:<br>` + barCode : '','barcode',''],
         ['','empty-cell',''],
         ['','empty-cell',''],
         ['','empty-cell',''],
         ['','empty-cell',''],
      ]), false);

      if ( salePayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT  ){
        aThis.forceReturnRowPayment(rowID, salePayment);
        continue;
      }
      aThis.isVoucherAutoReturn.apply(rowID, [dataObj[i].voucher_type_id, function (autoReturn) {
        if (autoReturn) {
          var returnStatus = $(this).find('.return-status');
          if (returnStatus.length > 0) {
            aThis.forceReturnRowPayment(this, salePayment);
          }
        }
      }]);
    }
  }
  forceReturnRowPayment(rowSelector, salePayment){
      var aThis = posCIVC;
      var returnStatus = $(rowSelector).find('.return-status');
        if (returnStatus.length > 0) {
          var paymentMethod = returnStatus.data('payment-method');
          var paymentDataObject = returnStatus.data('payment-object');

          returnStatus.hide();
          if (PositiveTS.Service.SmartVoucher.isSmartVoucher( paymentDataObject.smartVoucherType) &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BEENGO && 
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS && 
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE &&
            paymentDataObject.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALUE_CARD) {
            return;
          }
          var paymentData = aThis.creditPayment(salePayment.method, paymentDataObject);
          let amountToCreditStr: string;
          if (typeof(paymentData) === 'string') {
            amountToCreditStr = i18next.t('errorOccurred') + ': ' + paymentData;
          } else {
            amountToCreditStr = Pinia.globalStore.mobileLayout ? `${i18next.t("posCreditInvoice.amountToReturn")} ${session.fixedNumber(paymentData.amount)}` : session.fixedNumber(paymentData.amount);
          }          
          $(this).find('.amount-to-credit').text(amountToCreditStr);
          aThis.refreshAmountsToCredit();
      }
  }
  addPromotionRows(salePayment,tableView){
    var aThis = posCIVC;

    var posMethodType = i18next.t("posCreditInvoice.creditVoucher");
    var dataObj = JSON.parse(salePayment.data);

    for(var i=0;i<dataObj.length;i++){
      var amount = dataObj[i].amount;
      var promotionNumber = dataObj[i].promotionNumber;
      var creditVoucherType = dataObj[i].creditVoucherType;

      if (creditVoucherType == PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_CREDIT) {
        posMethodType = i18next.t("posCreditInvoice.creditVoucherChange");
      } else {
        posMethodType = i18next.t("posCreditInvoice.creditVoucherPayment");
      }

      if (posUtils.getMoment(aThis.originalSaleItemsAndPayments.createdAt).diff(moment(new Date()), 'days') <= -1) {
        promotionNumber = i18next.t("posCreditInvoice.notForDisplay");
      }
      tableView.addRow(posUtils.sanitizeTableViewRow([
                        [posMethodType,'method',''],
                        [session.fixedNumber(amount),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
                        ['','empty-cell',''],
                        [aThis.isPaymentReturnAble(salePayment) ? "<div class='return-status' data-payment-method='" + salePayment.method + "' data-payment-object='" + JSON.stringify(dataObj[i]) + "'></div>" : '','return-status-td',''],
                        `${i18next.t("posCreditInvoice.voucherNumber")}:<br>` +promotionNumber,
                        ['','empty-cell',''],
                        ['','empty-cell',''],
                        ['','empty-cell',''],
                        ['','empty-cell',''],
                        ['','empty-cell',''],
                        ]), false);
    } 
  }
  addCheckRows(salePayment,tableView){
    var aThis = posCIVC;

    var posMethodType = i18next.t("posCreditInvoice.check");
    var dataObj = JSON.parse(salePayment.data);

    for(var i=0;i<dataObj.length;i++){

      var amount = dataObj[i].amount;
      var accountData = dataObj[i].bank + ' ' + dataObj[i].branch + ' ' + dataObj[i].account;
      var checkNumber = dataObj[i].number;
      var expireDate =  dataObj[i].date;

      tableView.addRow(posUtils.sanitizeTableViewRow([
                        [posMethodType,'method',''],
                        [session.fixedNumber(amount),'total-amount',i18next.t("posCreditInvoice.amount") + ": "],
                        ["0.00",'amount-to-credit',i18next.t("posCreditInvoice.amountToReturn") + ": "],
                        [aThis.isPaymentReturnAble(salePayment) ? "<div class='return-status' data-payment-method='" + salePayment.method + "' data-payment-object='" + JSON.stringify(dataObj[i]) + "'></div>" : '','return-status-td',''],
                        [`${i18next.t("posCreditInvoice.bank")}:<br>` + dataObj[i].bank,'check-bank',''],
                        [`${i18next.t("posCreditInvoice.branch")}:<br>` + dataObj[i].branch,'check-branch',''],
                        [`${i18next.t("posCreditInvoice.account")}:<br>` + dataObj[i].account,'check-account',''],
                        [`${i18next.t("posCreditInvoice.check")}:<br>`+checkNumber,'check-number',''],
                        [`${i18next.t("posCreditInvoice.expiryDate")}:<br>`+expireDate,'check-exper',''],
                        ['','empty-cell',''],
                      ]), false);
    }
  }
  itemHasPromotions(saleItem,sale,saleItems) {
    var aThis = posCIVC;
    if (aThis.originalSaleItemsAndPayments !== null) {
      return salePromotions.itemHasPromotions(saleItem,aThis.originalSaleItemsAndPayments,aThis.originalSaleItemsAndPayments.items);
    }
    else {
      return salePromotions.itemHasPromotions(saleItem,sale,saleItems)
    }
  }
  calcuateItemPriceAfterDiscounts (saleItem:Storage.Entity.SaleItem,currentSale = null) {
    let aThis = posCIVC;

    let sale = currentSale || aThis.originalSaleItemsAndPayments;

    if (JSON.parse(sale.jsondata).useNewPromotions) {
      if(saleItem.originalQuantity != null){
        return saleItem.priceNetoAfterDiscounts / saleItem.originalQuantity
      }

      return saleItem.priceNetoAfterDiscounts / saleItem.quantity
    }
    
    let service = PositiveTS.Service.CreditInvoiceUtils;
    return service.calculateItemPriceAfterAllDiscounts(sale, saleItem);
  }
  createItemListToCredit() {
    var aThis = posCIVC;
    var creditInvoiceUtils = PositiveTS.Service.CreditInvoiceUtils;
    var creditedItems = [];

    for (var currentItemId in aThis.itemsIdAmount) {
      if (aThis.itemsIdAmount[currentItemId] != 0) {
        creditedItems.push(
          creditInvoiceUtils.createCreditSaleItem(aThis.itemsIdSaleItem[currentItemId],
            aThis.itemsIdAmount[currentItemId],
            aThis.originalSaleItemsAndPayments)
        );
      }
    }

    return creditedItems;
  }

  createCreditVoucherPaymentExt():Promise<Storage.Entity.SalePayment> {
    var aThis = posCIVC;

    return new Promise((resolve,reject) => {


      let amountToCreditWithCreditVoucher = -aThis.calcuateReturnInCreditVoucherAmount();

      if (amountToCreditWithCreditVoucher == 0) {
        resolve(null)
        return;
      }

      let doCreate = function() {
        Helper.SaleHelper.createCreditVoucherPayment(session.fixedNumber(amountToCreditWithCreditVoucher))
        .then(result => {
          resolve(result)
        })
        .catch(err => {
          reject(err)
        });
      };

      if (posUtils.isNullOrUndefinedOrEmptyString(aThis.originalSaleItemsAndPayments.customerName)
      || (posUtils.isNullOrUndefinedOrEmptyString(aThis.originalSaleItemsAndPayments.customerPhone))) {
        app.showCustomerDetailsFill(function (name, phone, customerID) {
          aThis.originalSaleItemsAndPayments.customerName = name;
          aThis.originalSaleItemsAndPayments.customerPhone = phone;
          aThis.originalSaleItemsAndPayments.customerID = customerID;

          doCreate();
        }, function () {
          reject(app.userCancelledGlobalMessage);
        });
      } else {
        doCreate();
      }

    })

    
  }

  cancelPraxellCharge(){
      var aThis = posCIVC;
      // Place holder contain recharge detais incase
      // Praxell canceled, however creditcard failed to be canceled
      this.canceledParxellDetails = undefined;

      for (var i = 0; i < aThis.paymentsAllowedToCredit.length; i++) {
            var currentPayment = aThis.paymentsAllowedToCredit[i];
            if (currentPayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_PRAXELL) {
              this.canceledParxellDetails = JSON.parse(currentPayment.data)[0];
            }
          }


      // if it is praxell charge, compare balance
      if ( this.canceledParxellDetails  ){
        return Promise.resolve(true);
      } else {
        // no parxell for this sale,
        // do nothing
        return Promise.resolve(false);
      }


      // do we have praxell payment
      // Is card balance, the same as in the Sale.data?
      // Cancel transaction, set flag of
      //    this.canceledParxellDetails = { amount: xx
      //    ,IsCanceledParxellAmount: true
      //    , canceledParxellCard: xxxx}



  }

  async creditCreditCards () {
    var aThis = posCIVC;

    try{
      let creditCardSalePayment = aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT]
      if (creditCardSalePayment == null) {
        return Promise.resolve();
      }
      let creditCards = JSON.parse(creditCardSalePayment.data);
      if (session.pos.isEmv || Service.Gateway.isEnabled()) {
        if (!creditCards || creditCards.length == 0) {
          return Promise.resolve()
        }
        let results = []
        let ccPromise:any
        if(creditCards[0].isEmv){
          ccPromise= PositiveTS.Service.EMV.creditPayment(creditCards[0])
        }else if(creditCards[0].isGateway){
          let int_in = await Service.Gateway.buildRefundTxByUidIntInXml(creditCards[0].Uid,creditCards[0].TerminalId)
          ccPromise = PositiveTS.Service.Gateway.sendRequestToCreditGuard(int_in)
          app.showLoadingMessage(i18next.t("creditCardPayment.refundPayment"));
        }
        for (let i=1; i < creditCards.length; i++) {
          let response;
          try{
            response = await ccPromise;
          }catch(err){
            if(creditCards[i-1].isGateway){
              let errorMessage = err.message ?err.message : err;
              throw new Error(`${i18next.t("creditCardPayment.gateway.errors.cannotCancelPayment")} ${errorMessage}`)
            }
            throw new Error(err)
          }
          
          if(creditCards[i-1].isGateway){
            try{
              if(response.result == "000" || response.result == "314"){
                let creditPaymentGatewayOutput =  await Service.Gateway.createRefundPaymentGatewayOutput(response,creditCards[i-1]);
                results.push(creditPaymentGatewayOutput);
                if(creditCards[i].isGateway){
                  let int_in = await Service.Gateway.buildRefundTxByUidIntInXml(creditCards[i].Uid,creditCards[i].TerminalId)
                  ccPromise =  PositiveTS.Service.Gateway.sendRequestToCreditGuard(int_in);
                }else{
                  app.hideLoadingMessage();
                  ccPromise =  PositiveTS.Service.EMV.creditPayment(creditCards[i])
                }
                continue;
              }
              else{
                console.error(response)
                throw new Error(response.error);
              }
            }catch(err){
              Service.Logger.error(err);
              throw new Error(i18next.t("creditCardPayment.gateway.errors.generalMessage"))
            }
          }
          if (response.success == false) {
            throw new Error(response.error);
          }
          else {
            response.result["OriginalPaymentRequestId"] = response.originalPayment.RequestId
            if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
              Object.assign(response.result, {
                creditCurrency: response.originalPayment.creditCurrency,
                currencyRate: response.originalPayment.currencyRate,
                creditLeadCurrencyAmount: response.originalPayment.creditLeadCurrencyAmount,
                creditCurrencyFullNumber: response.originalPayment.creditCurrencyFullNumber,
                currencyAmount: response.originalPayment.currencyAmount
              })
            }
            results.push(response.result);
            ccPromise = PositiveTS.Service.EMV.creditPayment(creditCards[i])
          }
        }
        let response;
        try{
          response = await ccPromise;
        }catch(err){
          if(creditCards[creditCards.length-1].isGateway){
            let errorMessage = err.message ?err.message : err;
            throw new Error(`${i18next.t("creditCardPayment.gateway.errors.cannotCancelPayment")} ${errorMessage}`)
          }
          throw new Error(err)
        }
        app.hideLoadingMessage();
                      if(creditCards[creditCards.length-1].isGateway){
              try{
                if(response.result == "000"  || response.result == "314"){
                  let creditPaymentGatewayOutput = await Service.Gateway.createRefundPaymentGatewayOutput(response,creditCards[creditCards.length-1]);
                  results.push(creditPaymentGatewayOutput);
                  creditCardSalePayment.data = JSON.stringify(results);
                  return Promise.resolve()
                }else{
                  console.error(response)
                  throw new Error(response.error);
                }
              }catch(err){
                Service.Logger.error(err);
                throw new Error(i18next.t("creditCardPayment.gateway.errors.generalMessage"))
              }
                      }
          if (response.success == false) {
            throw new Error(response.error);
          }
          else {
            response.result["OriginalPaymentRequestId"] = response.originalPayment.RequestId

            if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {         
              Object.assign(response.result, {
                creditCurrency: response.originalPayment.creditCurrency,
                currencyRate: response.originalPayment.currencyRate,
                creditLeadCurrencyAmount: response.originalPayment.creditLeadCurrencyAmount,
                creditCurrencyFullNumber: response.originalPayment.creditCurrencyFullNumber,
                currencyAmount: response.originalPayment.currencyAmount
              })
            }
            results.push(response.result)
            creditCardSalePayment.data = JSON.stringify(results);
            return Promise.resolve()
          }
      }else{
        async.mapSeries(creditCards, PositiveTS.Helper.SaleHelper.returnCreditCard, function(err, usedCreditCards) {
          // store current creditcard for
          // obtail payment.all_data to persist the new credir sale

          if (err != null) {
                //TODO: 29-12-2016 - ask gili what the heck praxell is doing here and if this can be removed....
                if (aThis.canceledParxellDetails && aThis.canceledParxellDetails.isCanceled) {

                      aPrxlAdon.promiseAddValueCard(aThis.canceledParxellDetails.card_number,aThis.canceledParxellDetails.debit_amount,"1000")
                      .then(function() {
                    Promise.reject( err );
                      });
                }
                else {
                if (usedCreditCards && typeof(usedCreditCards.filter) === "function")  {
                    Promise.reject(usedCreditCards.filter(function(uc) { return !uc.success })[0] || err);
                }
                else {
                    throw new Error(err);
              }
              }
          } else {
                aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT].data = JSON.stringify(usedCreditCards);
                Promise.resolve();
          }
        });
      }

    }catch(err){
      let errorMessage = err.message ?err.message : err;
      Service.Logger.error(err)
      app.hideLoadingMessage();
      return Promise.reject(errorMessage)
    }
  }

  creditSmartVoucher(voucherPayment) {

    return new Promise(function (resolve,reject) {
      var voucherPaymentData = JSON.parse(voucherPayment.data);
      var promises = [];

      for (var j = 0; j < voucherPaymentData.length; j++) {
        if ( PositiveTS.Service.SmartVoucher.isSmartVoucher( voucherPaymentData[j]['smartVoucherType'] ) ) {
          // vouchers.push(voucherPaymentData[j]);
          promises.push(
            new Promise(function(innerResolve,innerReject) {
              var index = j;
              PositiveTS.Helper.SaleHelper.promiseReturnVoucher(voucherPaymentData[j])
              .then(function(voucher) {
                voucherPaymentData[index] = voucher;
                innerResolve();
              })
              .catch(function(err) {
                innerReject(err)
              })
            })
          )
        }
      }

      Promise.all(promises)
      .then(function(results) {
        voucherPayment.data = JSON.stringify(voucherPaymentData);
        resolve();
      })
      .catch(function(err) {
        reject(err);
      })
    });
  }
  creditVouchers () {
    var aThis = posCIVC;

    var vouchersPayments = $.map(aThis.paymentsToCredit, function (value, key) {
      if (value.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER || value.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
        return value;
      } else {
        return null;
      }
    });

    var promises = [];

    return new Promise( function(resolve,reject) {
      app.showLoadingMessage(i18next.t("posCreditInvoice.creditVouchers"));
      for (var i = 0; i < vouchersPayments.length; i++) {
        promises.push(aThis.creditSmartVoucher(vouchersPayments[i]))
      }

      Promise.all(promises)
      .then(function() {
        app.hideLoadingMessage();
        resolve();
      })
      .catch(function(err) {
        app.hideLoadingMessage();
        reject(err);
      })
    })

  }
  _isCreditPaymentCanceledSuccessfully(cancelStatus) {

      var aThis = posCIVC;
      if (cancelStatus.success) {
            aThis.canceledParxellDetails.isCanceled = true;

          } else {
            aThis.isCanceledParxellError = true;
            aThis.messageCanceledParxellError = cancelStatus.message;
          }
          return true;
  }
  _isCreditPaymentHasValidBalance(returnedBalanced):any { //TODO: this method does not always return a value!
    var aThis = posCIVC;
      if (returnedBalanced.success == true &&
        returnedBalanced.amount == aThis.canceledParxellDetails.card_balance)
        {
          return  aPrxlAdon.promiseCancelDebitCard(
                    aThis.canceledParxellDetails.card_number,
                    aThis.canceledParxellDetails.transaction_id,"1000").then(aThis._isCreditPaymentCanceledSuccessfully);
        } else {
          aThis.isCanceledParxellError = true;
          if (returnedBalanced.success) {
            aThis.messageCanceledParxellError = "Card balance "
                            + returnedBalanced.amount
                            + " expected "
                            + aThis.canceledParxellDetails.card_balance;
            return true;
          } else {
            aThis.messageCanceledParxellError = returnedBalanced.message;
          }
        }
        // else, log the error
    }
  
  createPaymentListToCredit():Promise<Storage.Entity.SalePayment[]> {
    var aThis = posCIVC;

    return new Promise((resolve,reject) => {
      var creditVoucherPayment = aThis.createCreditVoucherPaymentExt()
      .then(creditVoucherPayment => {
          
        if (creditVoucherPayment != null) {
          const posCurrency = Service.MultiCurr.getInstance().getPosCurrency();    
          let stringContent = i18next.t('creditSaleAllowCreditVoucher', {AMOUNT: session.fixedNumber(-creditVoucherPayment.amount), CURRENCY:  i18next.t(`currenciesNames.${posCurrency}`)});

          app.promiseShowAlert({
            header: i18next.t('creditVoucher'),
            content: stringContent,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: false,
            cancelButtonText: i18next.t("posCreditInvoice.return"),
            blueCancel: false
          })
          .then((btnResult) => {
            console.log(btnResult);
            if(btnResult == "cancel"){
              reject(i18next.t("posCreditInvoice.actionCanceled"));
            }
            //--
            var result:Storage.Entity.SalePayment[] = $.map(aThis.paymentsToCredit, function (value, key) { return value; });
            result.push(creditVoucherPayment);
  
            // intercom var for praxell
            aThis.isCanceledParxellError = undefined;
            // credit credit cards and vouchers
            
            PositiveTS.Service.CheckCreditInvoice.promiseCreditChecks()
            .then(function(){
              return aThis.cancelPraxellCharge();
            })
            .then(function(isCancelPraxle){
  
              if (isCancelPraxle) {
                var aThis = posCIVC;
                aThis.isCancelPraxle = true;
                return aPrxlAdon.promiseGetCardBalance( aThis.canceledParxellDetails.card_number )
                .then(aThis._isCreditPaymentHasValidBalance);
              } // end if
            })
            .then(function(){
              if (aThis.isCanceledParxellError) {
                throw (aThis.messageCanceledParxellError);
  
              }
              return aThis.creditCreditCards();
            })
            .then(function () {
  
              return aThis.creditVouchers();
            }).then(() => {
              resolve(result);
            }).catch((err) => {
              reject(err);
            });
            //---
  
          })
          .catch((err) => {
            reject(i18next.t("posCreditInvoice.actionCanceled"));
          });
        } 
        else {
  
          //--------
          var result = $.map(aThis.paymentsToCredit, function (value, key) { return value; });
  
          // intercom var for praxell
          aThis.isCanceledParxellError = undefined;
          // credit credit cards and vouchers
          PositiveTS.Service.CheckCreditInvoice.promiseCreditChecks()
          .then(function(){
            return aThis.cancelPraxellCharge();
          })
          .then(function(isCancelPraxle){

            if (isCancelPraxle) {
              var aThis = posCIVC;
              aThis.isCancelPraxle = true;
              return aPrxlAdon.promiseGetCardBalance( aThis.canceledParxellDetails.card_number )
              .then(aThis._isCreditPaymentHasValidBalance);
            } // end if
          })
          .then(function(){
            if (aThis.isCanceledParxellError) {
              throw (aThis.messageCanceledParxellError);

            }

            return aThis.creditCreditCards();
          })
          .then(function () {
            return aThis.creditVouchers();
          }).then(function () {
            resolve(result);
          }).catch((err) => {
            reject(err);
          });
  
        }
      }).catch(err => {
        reject(err);
      });
    })
    
  }


	preFinishValidation(){
		if ( PositiveTS.Service.CheckCreditInvoice.isValid() ){
			return Promise.resolve();
		} else {
			return Promise.reject(i18next.t("checkPaymentsMustSelectAllPayments"));
		}
	}

  creditReceiptSmartVoucherOrContinue(smartVoucher,currentPayment,creditedPayments) {

    return new Promise(function(resolve,reject) {
    if (smartVoucher) {
        app.showLoadingMessage(i18next.t("smartVoucherCancelingLoadCard"))
        smartVoucher.cancelLoad(JSON.parse(currentPayment.data))
        .then(function(payment) {
          app.hideLoadingMessage();
          creditedPayments.push(payment);
          resolve();
        })
        .catch(function(err) {
          app.hideLoadingMessage();
          reject(err);
        });
    }
    else {
        resolve()
    }
    })

	}

  async finish() {
    var aThis = posCIVC;
    var saleHelper = PositiveTS.Helper.SaleHelper;
    let multiCurrService = Service.MultiCurr.getInstance();

    try {
      await aThis.preFinishValidation()
        
      let saleObj = aThis.originalSaleItemsAndPayments;
      let saleItems = aThis.createItemListToCredit();

      let saleData  = JSON.parse(saleObj.jsondata);
      if(saleData.delivery && saleData.delivery.orderOrigin == Service.Delivery.ExternalOrderOrigin.CashCow && !saleData.delivery.isPhoneOrder ) {
        await Service.CashCowService.cancelPayment(saleObj);
      }

      await PositiveTS.Service.ValueCardService.creditValuePointsIfExists(saleObj, saleItems)
        
      let creditedPayments = await aThis.createPaymentListToCredit();
      if (multiCurrService.isMultiCurr() && multiCurrService.creditInOriginalCashCurrencyRelatively() && aThis.wasSalePaidWithCashPaymentOnly()) {
        let creditCashSalePayment = aThis.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH];
        if (creditCashSalePayment && creditCashSalePayment.data) {
          let args: Types.ConfirmCashCreditPaymentDialogArgs = { paymentData: JSON.parse(creditCashSalePayment.data) };
          await Pinia.componentsStore.openComponent( { componentName: "confirmCashCreditPaymentDialog", args: [args] });
        }
      }

      let creditedItems = aThis.createItemListToCredit();

      let totalItems = session.fixedFloat(creditedItems.reduce((total, item) => total + (item.unitPrice * item.quantity), 0),2);
      let totalRefundPayments = session.fixedFloat(creditedPayments.reduce((total, payment) => total + payment.amount, 0), 2);
      let totalRound = 0;

      if (session.pos.isILSBasedCurrency) {
        creditedPayments.forEach((payment) => {
          if(payment.method == Storage.Entity.SalePayment.METHOD_CASH) {
            let roundAmount = session.fixedFloat(session.fixedFloat(payment.amount,2) - session.fixedFloat(payment.amount,1), 2);
            payment.amount = session.fixedFloat(payment.amount,1);
            totalRound += roundAmount;
          }
        })
      }


      if(!posUtils.isBlank(saleObj.roundAmount)) {
      //if all the original sale is credited and it was rounded down
        if(totalRound == 0 && session.fixedFloat(totalItems + saleObj.roundAmount) == totalRefundPayments) {
          totalRound = session.fixedFloat(saleObj.roundAmount, 2) * -1;
        }
      }

      let entitySequenceType = PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE;
      let smartVoucher,currentPayment;

      if (aThis.saleProxyOptions.invoice_type == 5) {
        entitySequenceType = PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT;
        for (var i = 0; i < aThis.paymentsAllowedToCredit.length; i++) {

          currentPayment = aThis.paymentsAllowedToCredit[i];
          smartVoucher = PositiveTS.Service.SmartVoucher.getSmartVoucherFromLoadPaymentMehtod(currentPayment.method);
          if (smartVoucher) {
            break;
          }
        }
      } // endif aThis.saleProxyOptions.invoice_type == 5)

      if (aThis.saleProxyOptions.invoice_type == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV 
          && creditedPayments[0].method ==  PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
        entitySequenceType = PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV;
      }

      if (aThis.saleProxyOptions.invoice_type == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV 
          && creditedPayments[0].method ==  PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
        entitySequenceType = PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV;
      }

      await aThis.creditReceiptSmartVoucherOrContinue(smartVoucher,currentPayment,creditedPayments)
        
      if (aThis.childrenItems.length > 0){
        let parentRowNumbers = creditedItems.map(i => i.rowNumber);
        let childrenItemsToCredit = [];
        let childRowNumbers = [];
        aThis.childrenItems.forEach(item => {
          if (parentRowNumbers.includes(item.parentRowNumber)){
            item.promotions = null;
            childrenItemsToCredit.push(item);
            childRowNumbers.push(item.rowNumber);
          }
        });
        
        let granchildrenItemsToCredit = [];
        aThis.childrenItems.forEach(item => {
          if (childRowNumbers.includes(item.parentRowNumber)){
            item.promotions = null;
            granchildrenItemsToCredit.push(item);
          }
        });
        
        creditedItems = saleItemHelper.unflattenSaleItems([...creditedItems, ...childrenItemsToCredit, ...granchildrenItemsToCredit])
      }
      
      let creditedSale = await saleHelper.creditSale(aThis.originalSaleItemsAndPayments, creditedItems, creditedPayments, entitySequenceType)

      try {
        await Service.Sms.addToSaleSmsPhoneNumberIfNeeded(creditedSale);
      } catch(err) {
        console.error(err)
      }

      creditedSale.roundAmount = session.pos.isILSBasedCurrency ? session.fixedFloat(totalRound, 2) : 0;
            
      let svcClubCustomer = new PositiveTS.Service.CustomerClub(creditedSale, creditedPayments, creditedItems);
      await svcClubCustomer.copyClientDataFromSale(aThis.originalSaleItemsAndPayments)
      
      await svcClubCustomer.postUpdateCustomerSale(true,aThis.originalSaleItemsAndPayments);
      
      
      let orgJsonData = JSON.parse(aThis.originalSaleItemsAndPayments.jsondata)
      let creditSaleJsondata = JSON.parse( creditedSale.jsondata )

      if (orgJsonData.value_card_customer) {
        creditSaleJsondata.value_card_customer = orgJsonData.value_card_customer
        creditSaleJsondata.value_card_benefits_trx = orgJsonData.value_card_benefits_trx
        creditedSale.jsondata = JSON.stringify(creditSaleJsondata);
      }

      if (session.pos.isCaveret) {
        for (let creditItem of creditedItems) {
          for (let childItem of (creditItem.children || [])) {
            childItem.saleID = creditedSale.id;
            childItem.priceNetoAfterDiscounts *= -1;
            childItem._data.priceNetoAfterDiscounts *= -1;
  
            for (let granChildItem of (childItem.children || [])) {
              granChildItem.saleID = creditedSale.id;
              granChildItem.priceNetoAfterDiscounts *= -1;  
              granChildItem._data.priceNetoAfterDiscounts *= -1;  
            }
          }
        }
      }


      PositiveTS.Service.valuCardSetSaleTrxKey(creditedSale, false)

      creditedSale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
      creditedSale.syncLastMessage			= 'Sale added to queue successfully';
      creditedSale.syncLastMessageTimestamp	= PositiveTS.DateUtils.fullFormat();

      await Service.FullSale.persist(creditedSale,creditedItems,creditedPayments);

      Service.EMV.getInstance().updateXFieldSequencesAfterSuccessfullTransactionIfNeeded();

      await Service.HoldSale.cancelBonItemsIfRequired(creditedItems,true);

      if(!posUtils.isNullOrUndefinedOrEmptyString(creditedSale.parentSaleDelivery)){
        await Service.Delivery.creditDelivery(creditedSale);
      }

      let printInvoice = true
      let allowAndSendSmsInvoiceAtSaleClose = jsonConfig.getVal(jsonConfig.KEYS.sendSmsAtSaleClose) && jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice)
      if (allowAndSendSmsInvoiceAtSaleClose) {
        let jsonData = JSON.parse(posVC.sale.jsondata)
        
        if(!posUtils.isBlank(jsonData.printSmsInvoice)) {
          printInvoice = jsonData.printSmsInvoice
        }
      }
      
      if (printInvoice){
        Printing.Invoice.printInvoice(creditedSale, saleItemHelper.unflattenSaleItems(creditedItems), creditedPayments, true);
      }
      
      if (session.pos.hasFlights) {
        Pinia.flightsStore.calculateSalesData();
      }
      
      if (PositiveTS.Service.DutyFree.isDutyFree()){
        PositiveTS.Service.DutyFree.clearFlightCardFromSale()
        pNavigator.pushPage('pos', i18next.t("pageTitle.pos"), null, null);
      }else{
        pNavigator.replacePage(aThis.backTo, i18next.t('pageTitle.posPastInvoices'), null, null);
      }     
      
    } catch(err) {
      app.hideLoadingMessage();
      console.error(err);
      app.showAlert({
        header: i18next.t('error'),
        content: err,
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      }, null, null);

      aThis.currentStep = stepMap.payments;
    };
  }

  resume (options?) {
    var aThis = posCIVC;

    var resumeStep = function () {
      switch (aThis.currentStep) {
        case stepMap.items:
          aThis.resumeItems();

          // For receipt
          // resumeItems select all items
          if (aThis.saleProxyOptions.invoice_type == 5) {
               var newVal = 1;

               var itemId = Object.keys(aThis.itemsIdSaleItem)[0];
               var saleItem = aThis.itemsIdSaleItem[itemId];

               aThis.itemsIdAmount[itemId] = newVal;

               var itemRealPrice = aThis.calcuateItemPriceAfterDiscounts(saleItem);

               var amount = itemRealPrice * newVal;

               //$('.pos-credit-invoice-item-count[name="' + itemId + '"]').text(session.fixedNumber(-amount));

               var totalAmountToCredit = 0;
               for (var currentItemId in aThis.itemsIdAmount) {
                 totalAmountToCredit += aThis.itemsIdAmount[currentItemId] * aThis.calcuateItemPriceAfterDiscounts(aThis.itemsIdSaleItem[currentItemId]);
               }

               // Update total payments
               //$('#pos-credit-invoice-sale-info-total').text(session.fixedNumber(-totalAmountToCredit));

            //aThis.changeStep(2);
          }
          // End for receipt

          break;
        case stepMap.payments:
          aThis.resumePayments();
          break;
        case stepMap.finish:
          aThis.finish();
          break;
      }
    };

    if (options !== undefined) {
      aThis.options = options;

      aThis.saleProxyOptions = options.saleProxyOptions;

      aThis.backTo = options.backTo;

      if (aThis.currentStep != 1) {
        aThis.changeStep(1);
      }

      var saleProxy = new PositiveTS.Service.SaleProxy(aThis.saleProxyOptions);

      // Fetch all items and payments for the sale
      app.showLoadingMessage(i18next.t("posCreditInvoice.loadingSale"));
      saleProxy.loadAllLevels()
      .then(result => {
        app.hideLoadingMessage();
        if (result.length < 1) {
          // Tell the user
          app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('loadingItemFromStorageFailed'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          }, null, null);
          aThis.originalSaleItemsAndPayments = null;
          return;
        }
        aThis.childrenItems = result[0].items.filter(item => item.level > 0)
        

        result[0].items = result[0].items.filter(item => { return (item.level == null || item.level === 0) });
        var itemsAndSalesToCredit = PositiveTS.Helper.SaleHelper.getCreditedItemsAndPaymentsByFullSale(result[0]);

        aThis.itemsAllowedToCredit = itemsAndSalesToCredit.itemsToCredit;
        aThis.paymentsAllowedToCredit = itemsAndSalesToCredit.paymentsToCredit;


        // --- Sale items and payments fetched successfully
        // Get the items and payments for the current sale ID
        aThis.originalSaleItemsAndPayments = result[0];
        var isCustomerExists = false;
        var customerName = ""
        var svcCustomerClub = new PositiveTS.Service.CustomerClub(aThis.originalSaleItemsAndPayments,aThis.originalSaleItemsAndPayments.payments,aThis.originalSaleItemsAndPayments.items);
        let allowToReplaceItemsInReplacementSale= jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)
        let isReplacementSale = Service.CreditInvoice.isReplacementSale(aThis.originalSaleItemsAndPayments)
        if ( svcCustomerClub.isCurrentCustomerExists( aThis.originalSaleItemsAndPayments) ){
          isCustomerExists = true;
          customerName = svcCustomerClub.getCustomerShortDisplayName( aThis.originalSaleItemsAndPayments );
        }

      if (jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) {
          if (Service.SplitSalePayment.isSplittedSale(aThis.originalSaleItemsAndPayments)) {
            $('#pos-credit-invoice-credit').hide();
            $('#pos-credit-invoice-select-all').hide();
            $('#pos-credit-invoice-replace').text(i18next.t("posCreditInvoice.refund"));
          } else {
            $('#pos-credit-invoice-credit').show();
            $('#pos-credit-invoice-select-all').show();
            $('#pos-credit-invoice-replace').text(i18next.t("posCreditInvoice.replacement"));
          }
        }

        if (allowToReplaceItemsInReplacementSale &&  isReplacementSale ) {
          $('#pos-credit-invoice-credit').hide();

          let isCreditSale = aThis.saleProxyOptions.invoice_type == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE

          if(isCreditSale) {
            let saleItems = aThis.itemsAllowedToCredit;
            let itemsNotAllowdToCredit = 0

            for (const saleItem of saleItems) {
              let itemRealPrice = aThis.calcuateItemPriceAfterDiscounts(saleItem);

              if(!(saleItem.quantity > 0 && itemRealPrice > 0)) {
                itemsNotAllowdToCredit ++
              }
            }
            
            if(itemsNotAllowdToCredit == saleItems.length){
              $('#pos-credit-invoice-select-all').hide();
              $('#pos-credit-invoice-replace').hide();
            }

          }
        }

        if (aThis.forceMoneyReturnOnly()) {
          $('#pos-credit-invoice-replace').hide();
        }

        if (isCustomerExists) {
            $(".past-invoices-display-items-customer-name").text(`${i18next.t("posCreditInvoice.customer")}: ${customerName}`);
            $(".past-invoices-display-items-customer-name").show();
          } else {
            $(".past-invoices-display-items-customer-name").hide();
          }

        //Set the table view header
        aThis.setTableHeader(aThis.originalSaleItemsAndPayments.invoiceSequence + ";" + aThis.originalSaleItemsAndPayments.createdAt + ";" + aThis.originalSaleItemsAndPayments.cashierEmployeeName);

          resumeStep();

      }, function (e) {
        // --- Failed to fetch sale items and payments
        console.error(e.message);

        // Tell the user
        app.showAlert({
          header: i18next.t('error'),
          content: i18next.t('loadingItemFromStorageFailed'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        }, null, null);
      });
    } else {
      resumeStep();
    }
  }
  changeStep(step) {
    var aThis = posCIVC;
    if(jsonConfig.getVal(jsonConfig.KEYS.allowItemsChangeFromCreditInvoice) == false){
      $('#pos-credit-invoice-replace').hide();
    }
    
    if (step != stepMap.finish) {
      if (aThis.currentStep + 1 == step) {
        aThis.wizard.bjqsGo('forward', false);
      } else if (aThis.currentStep - 1 == step) {
        aThis.wizard.bjqsGo('backward', false);
      } else {
        aThis.wizard.bjqsGo(null, step);
      }
    }

    aThis.currentStep = step;

    aThis.resume();
  }
  creditInvoice() {
    var aThis = posCIVC;

    // TODO credit the invoice
  }
  stop () {
    $('#pos-credit-invoice-back').unbind('click');
    $('#pos-credit-invoice-credit').unbind('click');
  }
  back(){
    var aThis = posCIVC;
    $('#pos-credit-invoice-select-all').show();
    $('#pos-credit-invoice-replace').show();

    // if credit receipt back should navigate back
    if (aThis.currentStep > 1 && !(aThis.saleProxyOptions.invoice_type == 5) ) {
      aThis.changeStep(aThis.currentStep - 1);
    } else {
      pNavigator.replacePage(aThis.backTo, i18next.t('pageTitle.posPastInvoices'), null, null);
    }
  }
  // --------------------------------------------------------------------
  // Listeners
  // --------------------------------------------------------------------
  continue () {

  }
}}}}
declare var posCIVC:PositiveTS.Application.Controllers.PosCreditInvoiceViewController;
posCIVC = new PositiveTS.Application.Controllers.PosCreditInvoiceViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.PosCreditInvoiceViewController = posCIVC;

