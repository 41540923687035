module PositiveTS {
    export module Components {
        export module SelfServiceAfterSaleScreen {
            export function getComponent() {
              return {
                template: JST.selfServiceAfterSaleScreen(),
                mixins: [Mixins.wizardItemMixin],
                setup(){
                  const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
                  const {setCurrentStep} = selfServicePaymentStore;
                  return {setCurrentStep}
                },
                data:function(){
                  return{
                    total: Pinia.globalStore.totalAmount
                  }
                },
                computed: {
                  isSuperMarketPos() {
                    return jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos);
                  },
                  title() {
                    let posTitle = jsonConfig.getVal(jsonConfig.KEYS.selfServiceAfterSaleTitle);
                    if(posUtils.isBlank(posTitle) === false) {
                      return posTitle;
                    } else if(this.isSuperMarketPos) {
                      return i18next.t("selfServiceAfterSaleScreen.thanksForBuying");
                    } else {
                      return i18next.t("selfServiceAfterSaleScreen.genericTitle");
                    }
                  },
                  subtitle() {
                    let posSubTitle = jsonConfig.getVal(jsonConfig.KEYS.selfServiceAfterSaleSubTitle);
                    if(posUtils.isBlank(posSubTitle) === false) {
                      return posSubTitle;
                    } else if(this.isSuperMarketPos) {
                      return i18next.t("selfServiceAfterSaleScreen.dontForgetReceiptAndItems");
                    } else {
                      return '';
                    }
                  }
                }
              }
            }
    }
  }
}