module PositiveTS {
export module Components {
export module PromotionsReport {

  const _vueComponentName = 'promotions-report';

  export function create() {
    let promotionsReport = {
        template: JST.promotionsReport(),
        methods: {
          async open() {
            this.promotions = await Service.PromotionsReport.getData();
            this.$el.showModal();
          },
          close() {
            this.$el.close();
          },
          print() {
            Printing.Reports.printPromotionsReport(this.promotions);
          }
        },
        data() {
          return { 
            promotions: []
          }
        },
        computed: {
          
        }
    }

    VueApp.component(_vueComponentName,promotionsReport)
  }
}}}
