module PositiveTS {
  export module Service {
    
    
    export class SocketManager {
      dispatcher:WebSocketRails.Dispatcher
      channelPos:WebSocketRails.Channel
      channelStore:WebSocketRails.Channel
      channelCompany:WebSocketRails.Channel
      isIntervalRuning:boolean
      intervalHndlr:number
      
      constructor() {
        this.startNewDispatcher();
      }
      
      
      triggerAction(action, object_to_send) {
        return new Promise((resolve, reject) => {
          
          let success = (response) => {
            resolve(response);
          };
          
          let failure = (response) => {
            reject(response);
          };
          
          
          if (! this.isOnline()) {
            return reject("NOT CONNECTED");
          }
          
          this.dispatcher.trigger(action, object_to_send, success, failure);
        });
        
      };
      
      startDispatcherIfNotConnected() {
        if (!this.isOnline()) {
         // this.dispatcher.reconnect();
         this.startNewDispatcher();
        }
      };
      
      
      // admin_send(object_to_send) {
      //   this.admin_channel.trigger('new', object_to_send);
      // };
      
      // admin_listerOnChannel(channelName, returnFunction) {
      //   this.admin_channel = this.dispatcher.subscribe(channelName);
      //   this.admin_channel.bind('new', returnFunction);
      // };
      
      isOnline() {
        return (this.dispatcher.state === "connected");
      };
      
      
      private returnChannelResponse(param) {
        var object_to_send = {action: socketActions.CHANNEL_ACTIONS.ECHO.name ,
          param:param
        };
        this.channelPos.trigger('new', object_to_send);
      };
      
      private bindChannel(bindChannel:WebSocketRails.Channel) {
        bindChannel.unbind('new');
        bindChannel.bind('new', (message) => {
          console.log("message received: " + JSON.stringify(message));
          
          var result = socketActions.runChannelAction(message);
          // Do not echo the echo
          if (message.action !== socketActions.CHANNEL_ACTIONS.ECHO.name) {
            this.returnChannelResponse(result);
          }
          
        });
      };
      
      isMessageForPos(target) {
        if (typeof(target) === "undefined" || target == null) {
          return false;
        }
        var targetArray = target.split("_");
        if (targetArray.length === 0) {
          return false;
        }
        var result = true;
        var posArray = [session.pos.tenantName,
          session.pos.companyID,
          session.pos.storeID,
          session.pos.deviceID];
          for (var i=0; i < targetArray.length; i++) {
            if (targetArray[i] !== posArray[i]) {
              result = false;
            }
          }
          
          return result;
        };
        
        bindEvent() {
          this.dispatcher.unbind('run_action');
          this.dispatcher.bind('run_action',(message) => {
            if (this.isMessageForPos(message.target)) {
              socketActions.runChannelAction(message);
            }
          });
          
          let errorOrClosedHandler = (message) => {
            // Statechange inside semaphore since
            // when disconected state frequentlly updated from 'connecring' to 'disconnected' and bouth means the same
            if (this.isIntervalRuning == false || this.isIntervalRuning == null) {
              this.isIntervalRuning = true; //prevent duplicate entries here that can be caused by both error and closed events one after the other
              this.dispatcher.disconnect();
              PositiveTS.Reachability.stateChanged();
              socketActions.clearInterval();
              this.startInterval();
            }
          }
          
          this.dispatcher.unbind('connection_closed');
          this.dispatcher.bind('connection_closed', (message) => errorOrClosedHandler(message));
          this.dispatcher.unbind('connection_error');
          this.dispatcher.bind('connection_error', (message) => errorOrClosedHandler(message));
          
          this.dispatcher.on_open = (data) => {
            console.log('Connection has been established: ', data);
            // You can trigger new server events inside this callback if you wish.
            
            this.recreateChannelOnConnected();
            PositiveTS.Reachability.stateChanged();
            socketActions.startInterval();
            this.stopInterval();
            
          }
          
        };
        
        
        private recreateChannelOnConnected() {
          let posChannelName =  session.pos.tenantName + "_" + session.pos.companyID + "_" + session.pos.storeID + "_" +
                                session.pos.deviceID;
          this.dispatcher.unsubscribe(posChannelName);
          this.channelPos = this.dispatcher.subscribe(posChannelName);
          
          var storeChannelName = session.pos.tenantName + "_" + session.pos.companyID + "_" + session.pos.storeID;
          this.dispatcher.unsubscribe(storeChannelName);
          this.channelStore = this.dispatcher.subscribe(storeChannelName);
          
          var companyChannelName = session.pos.tenantName + "_" + session.pos.companyID;
          this.dispatcher.unsubscribe(companyChannelName);
          this.channelCompany = this.dispatcher.subscribe(companyChannelName);
          
          this.bindChannel(this.channelPos);
          this.bindChannel(this.channelStore);
          this.bindChannel(this.channelCompany);

          // Add all Z report channel subscribe
          this.dispatcher.subscribe(posChannelName).bind('z_report_all' , (message:any) => {
            PositiveTS.Service.ZReportAll.remoteZ();
          });
        };

      
        
        private startNewDispatcher () {
          if (this.dispatcher) {
            this.dispatcher.markForDestruction();
          }

          var socket_address = window.document.location.host + '/websocket';
          
          this.dispatcher = new WebSocketRails.Dispatcher(socket_address);
          this.bindEvent();
        };
        
        
        
        
        
        startInterval() {
          this.isIntervalRuning = true;
          this.intervalHndlr = window.setInterval(() => { this.startDispatcherIfNotConnected() },5000);
        };
        
        stopInterval() {
          this.isIntervalRuning = false;
          clearInterval(this.intervalHndlr);
        };

        static generateChannelName(): string {
          return String(Date.now()) + String(Math.floor(1000000 * Math.random()))
        }
        static getHostAddress() {
          return window.document.location.host + '/websocket/';
        }

        static async runJob(requestData): Promise<any> {
            let dispatcher: WebSocketRails.Dispatcher, resolveFunc: Function, rejectFunc: Function
        
            return new Promise(async (resolve, reject) => {
              let channelName: string = SocketManager.generateChannelName();
        
              try {
                dispatcher = new WebSocketRails.Dispatcher( SocketManager.getHostAddress());
                let channel = dispatcher.subscribe(channelName)
        
                channel.unbind('newJobResult');
                channel.bind('newJobResult', (socketResult) => {
                  dispatcher.unsubscribe(channelName);
        
                  if (socketResult.success) {
                    resolveFunc({ data: socketResult.data })
                  } else {
                    rejectFunc({ data: socketResult })
                  }
                });
        
        
                resolveFunc = resolve
                rejectFunc = reject
        
                dispatcher.on_open = function (data) {
                  console.log(`Connection has been established: channel: ${channelName} `, data)
                }
        
                if (!requestData.headers) {
                  requestData.headers = {};
                }
        
                requestData.headers['run-as-job'] = true;
                requestData.headers['channel-name'] = channelName;
                requestData.responseType = 'json';
        
                let promiseRespone = await PositiveTS.Service.FetchReq.jsonRequestPromise(requestData.url, requestData.method, requestData.data, false, requestData.headers)
                if (promiseRespone.success) {
                  let response = promiseRespone.response
                  let data = promiseRespone.data
                  if (!(response.status == 200 && data)) {
                    rejectFunc(response)
                    dispatcher.unsubscribe(channelName)
                  }
                } else {
                  rejectFunc(promiseRespone)
                  dispatcher.unsubscribe(channelName)

              }
        
              } catch (e) {
                rejectFunc(e)
                dispatcher.unsubscribe(channelName)
              }
          })
        }  
         static async makeRequestAndWaitForSocketResult(requestData): Promise<any> {
            let result = await this.runJob({ ...requestData })
            let file_download_url = `/files/get_job_result?filename=${result.data}`
            return  PositiveTS.Service.FetchReq.jsonReq(file_download_url,"GET")
          }
        }

      }} 
      
      declare var socketManager:PositiveTS.Service.SocketManager
      