module PositiveTS {
export module Storage {
export module Entity {
export class SalePayment extends IDBEntity {
	invoiceSequence:number
	method:number
	amount:number
	data:string
	invoiceType:number
	saleID;
	countForPriZ:number //not persisted - just used for primary z report calculations
	isDebitInvoice: boolean //not persisted - just used for MishlohaConvertService in #setSaleInvoiceTypeAndSequence

	constructor() {
		let meta = {
			name: 'SalePayment',
			internal: true,
			fields: {
				saleID: "TEXT",
				method: "INT",
				amount: "FLOAT",
				data: "JSON",
				invoiceSequence: "INT"
			},
			unique: ['saleID','method'],
			money: ['amount']
		};
		super(meta);
	}

	static CREDIT_VOUCHER_TYPE_DEBIT 	= 1;
	static CREDIT_VOUCHER_TYPE_CREDIT 	= 2;

	static METHOD_CHANGE = PositShared.SalePaymentMethod.CHANGE;

	static METHOD_CREDIT = PositShared.SalePaymentMethod.CREDIT;
	static METHOD_CASH = PositShared.SalePaymentMethod.CASH;
	static METHOD_VOUCHER = PositShared.SalePaymentMethod.VOUCHER;
	static METHOD_CREDIT_VOUCHER = PositShared.SalePaymentMethod.CREDIT_VOUCHER;
	static METHOD_CHECK = PositShared.SalePaymentMethod.CHECK;
	static METHOD_VOUCHER_DISCOUNT = PositShared.SalePaymentMethod.VOUCHER_DISCOUNT;
	static METHOD_CREDIT_VOUCHER_CHANGE = PositShared.SalePaymentMethod.CREDIT_VOUCHER_CHANGE;

	// Payment type used for Parxell Receipt
	static METHOD_PRAXELL = PositShared.SalePaymentMethod.PRAXELL;
	// Payment type used for Valu Receitpt
	static METHOD_VALU = PositShared.SalePaymentMethod.VALU;
	static METHOD_GPP = PositShared.SalePaymentMethod.GPP;
	static METHOD_MULTIPASS = PositShared.SalePaymentMethod.MULTIPASS;
	static METHOD_VALUE_CARD = PositShared.SalePaymentMethod.VALUE_CARD;
	static METHOD_MULTIPASS_POLICE = PositShared.SalePaymentMethod.MULTIPASS_POLICE;
	static METHOD_OTOT = PositShared.SalePaymentMethod.OTOT;
	static METHOD_GOODI = PositShared.SalePaymentMethod.GOODI;
	static METHOD_DTS = PositShared.SalePaymentMethod.DTS;
	static METHOD_YAAD = PositShared.SalePaymentMethod.YAAD;
	static METHOD_CASH_WITHDRAWAL = PositShared.SalePaymentMethod.CASH_WITHDRAWAL;
	static METHOD_CASH_WITHDRAWAL_REFUND = PositShared.SalePaymentMethod.CASH_WITHDRAWAL_REFUND;

	static PAYMENTS_WITH_DATA = [1,3,5,6];

	static CreditCardTypes = [
		{ id: 1, name: 'ישראכרט/מסטרקארד' },
		{ id: 2, name: 'ויזה' },
		{ id: 3, name: 'דיינרס' },
		{ id: 4, name: 'אמריקן אקספרס' },
		{ id: 5, name: 'יורוכארט' },
		{ id: 6, name: 'לאומי כארד' },
		{ id: 7, name: 'אשראי ידני' },
		{ id: 0, name: 'אשראי תייר'}
	];

	static import(payment) {
		let model = new SalePayment();
		return model.importFromObject(payment);
	}

	async fetchByZNumber(zNumber, ignoreDeliveries = false):Promise<Array<SalePayment>> {
		let sales = await appDB.sales.where('invoiceSequence').above(0).filter(sale => sale.zNumber == zNumber).toArray();
		if(ignoreDeliveries){
			sales = sales.filter(sale => {
				if (Boolean(sale.isDelivery)){
					let saleJsondata = JSON.parse(sale.jsondata)
					if (saleJsondata.delivery && saleJsondata.delivery.deliveryType != PositiveTS.Service.Delivery.DeliveryType.ta){
						return false
					}
				}

				return true
			})
		}
		return SalePayment.fromSalesArray(sales);
	};
	
	static fromSalesArray(sales: Sale[]) {
		let salePayments = [];
		sales.forEach(sale => salePayments = salePayments.concat(
			sale.payments.map(pay => {
				let payEnt = SalePayment.import(pay);
				payEnt.invoiceType = sale.invoiceType;
				return payEnt;
			})
		))
		
		return salePayments;
	}


	static sumPaymentsData(salePayments:Array<SalePayment>) {
		let total = 0;
		if (session.pos.hasFlights) {
			for (let pay of salePayments) {
				let data;
				switch (pay.method) {
					case 0:
						data = JSON.parse(pay.data);
						total -= _.sumBy(data,'lead_currency_amount');
						break;
					case 1:
						data = JSON.parse(pay.data);
						total += _.sumBy(data,'creditLeadCurrencyAmount');
						break;
					case 2:
						data = JSON.parse(pay.data);
						total += _.sumBy(data,'lead_currency_amount');
						break;
					default:
						total += pay.amount;
						break;
				}
			}
		}
		return total;
	}

	async persist (tx?:any, ignoreValidations = false):Promise<any> {
		return;
	};


	static  getTenbisPluxeeAmount(salePayments){
		var tenbisTotalAmount = 0
		var cibusTotalAmount = 0
		var valuTotalAmount = 0
		var goodiTotalAmount = 0;
		var dtsTotalAmount = 0;
		var banxTotalAmount = 0;
		var safeCashTotalAmount = 0;
		var yaadTotalAmount = 0;
		var caveretPaymentTotalAmount = 0;
		var multipassPoliceTotalAmount = 0;
		var tamashTotalAmount = 0
		var totalHakafaTamash = 0;
		var totalHakafa = 0
		var interServiceTotalAmount = 0
		var mishlohaTotalAmount = 0
		let deliveryApiTotals = {}

		var tamashCount = 0
		var hakafaCount = 0
		var cibusCount = 0
		var tenbisCount = 0
		var goodiCount = 0
		var dtsCount = 0
		var valuCount = 0
		var banxCount = 0
		var safeCashCount = 0
		var yaadCount = 0
		var caveretPaymentCount = 0
		var multipassPoliceCount = 0
		var interServiceCount = 0
		var mishlohaCount = 0
		var paiditTotalAmount = 0
		var paiditCount = 0

		//unique by saleID
		var dictTamashCount = {}
		var dicthHakafaCount = {}
		var dictCibusCount = {}
		var dictTenbisCount = {}
		var dictValuCount = {}
		var dictGoodiCount = {}
		var dictDtsCount = {}
		var dictBanxCount = {}
		var dictSafeCashCount = {}
		var dictYaadCount = {}
		var dictCaveretPaymentCount = {}
		var dictMultipassPoliceCount = {}
		var dictInterServiceCount = {}
		var dictMishlohaCount = {}
		var dictPaiditCount = {}


		for (let salePayment of salePayments) {

			console.log(salePayment.invoiceType)

			let isTamash = ([PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV,
			PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV ].indexOf( salePayment.invoiceType ) > -1)
			if (isTamash){
				dictTamashCount[salePayment.saleID] = "Y"
			}
			if  ( [3,6].indexOf( salePayment.method) > -1 ){
				let dataAry = JSON.parse(salePayment.data);
				for (let i=0;i<dataAry.length;i++){
					let dataItm = dataAry[i];
					if (dataItm.smartVoucherType || dataItm.voucher_type_id) {
						if (PositiveTS.Service.PluxeeService.Voucher.isPluxeeCibusVoucher(dataItm)) {
							dictCibusCount[salePayment.saleID] = "Y"
							cibusTotalAmount = cibusTotalAmount + dataItm.amount
						} else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS) {
							dictTenbisCount[salePayment.saleID] = "Y"
							tenbisTotalAmount = tenbisTotalAmount + dataItm.amount
						} else if (dataItm.voucher_type_id == Service.VALU_CARD_VOUCHER_TYPE_ID) {
							dictValuCount[salePayment.saleID] = "Y"
							valuTotalAmount = valuTotalAmount + dataItm.amount
						} 
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI) {
							dictGoodiCount[salePayment.saleID] = "Y"
							goodiTotalAmount = goodiTotalAmount + dataItm.amount
						}	
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS) {
							dictDtsCount[salePayment.saleID] = "Y"
							dtsTotalAmount = dtsTotalAmount + dataItm.amount
						}
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT) {
							dictBanxCount[salePayment.saleID] = "Y"
							banxTotalAmount = banxTotalAmount + dataItm.amount
						}
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH) {
							dictSafeCashCount[salePayment.saleID] = "Y"
							safeCashTotalAmount = safeCashTotalAmount + dataItm.amount
						}	
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT) {
							dictYaadCount[salePayment.saleID] = "Y"
							yaadTotalAmount = yaadTotalAmount + dataItm.amount
						}
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_INTERSERVICE) {
							dictInterServiceCount[salePayment.saleID] = "Y"
							interServiceTotalAmount = interServiceTotalAmount + dataItm.amount
						}
						else if (dataItm.voucherType == PositiveTS.Storage.Entity.Voucher.VOUCHER_MISHLOHA) {
							dictMishlohaCount[salePayment.saleID] = "Y"
							mishlohaTotalAmount = mishlohaTotalAmount + dataItm.amount
						}	
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE) {
							dictMultipassPoliceCount[salePayment.saleID] = "Y"
							multipassPoliceTotalAmount = multipassPoliceTotalAmount + dataItm.amount
						}	
						else if (dataItm.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT) {
							dictCaveretPaymentCount[salePayment.saleID] = "Y"
							caveretPaymentTotalAmount = caveretPaymentTotalAmount + dataItm.amount
						}
						else if (dataItm.voucher_type_id == Service.Hakafa.VOUCHER_TYPE_ID) {
							totalHakafa = totalHakafa + dataItm.amount;
							dicthHakafaCount[salePayment.saleID] = "Y"
							if ( isTamash ){
								totalHakafaTamash = totalHakafaTamash + dataItm.amount;
							}
						}
						else if (dataItm.voucher_type_id == PositiveTS.Service.PaiditConvertService.PaiditVoucherCode) {
							dictPaiditCount[salePayment.saleID] = "Y"
							paiditTotalAmount += dataItm.amount
						}
						else if (dataItm.voucherType == PositiveTS.Storage.Entity.Voucher.DYNAMIC_DELIVERY_API) {
							if (!deliveryApiTotals[dataItm.voucher_type_id]) {
								deliveryApiTotals[dataItm.voucher_type_id] = {amount: 0, count: 0,  totalTamashAmount: 0, tamashCount: 0, totalTamashByPaymentDataAmount: 0}
							}

							deliveryApiTotals[dataItm.voucher_type_id].amount += dataItm.amount;
							deliveryApiTotals[dataItm.voucher_type_id].count++;
							
							if (isTamash){
								deliveryApiTotals[dataItm.voucher_type_id].totalTamashAmount += dataItm.amount
								deliveryApiTotals[dataItm.voucher_type_id].tamashCount++;
							}

							if (dataItm.isTamashCustomer) {
								deliveryApiTotals[dataItm.voucher_type_id].totalTamashByPaymentDataAmount += dataItm.amount
							}
						}
					}
				}
			}
		}

		tamashCount = Object.keys(dictTamashCount).length;
		hakafaCount = Object.keys(dicthHakafaCount).length;
		cibusCount = Object.keys(dictCibusCount).length;
		tenbisCount = Object.keys(dictTenbisCount).length;
		goodiCount = Object.keys(dictGoodiCount).length;
		valuCount = Object.keys(dictValuCount).length;
		dtsCount = Object.keys(dictDtsCount).length;
		banxCount = Object.keys(dictBanxCount).length;
		safeCashCount = Object.keys(dictSafeCashCount).length;
		yaadCount = Object.keys(dictYaadCount).length;
		interServiceCount = Object.keys(dictInterServiceCount).length;
		multipassPoliceCount = Object.keys(dictMultipassPoliceCount).length;
		caveretPaymentCount = Object.keys(dictCaveretPaymentCount).length;
		mishlohaCount = Object.keys(dictMishlohaCount).length;
		paiditCount = Object.keys(dictPaiditCount).length;

		tamashTotalAmount = salePayments.filter( (row)=> {
               return [PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV,
               PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV ].indexOf( row.invoiceType ) > -1}).
                                                        reduce( (sum,value)=>{return sum+value.amount;},0);

		return {tenbisTotalAmount: tenbisTotalAmount, cibusTotalAmount: cibusTotalAmount, valuTotalAmount: valuTotalAmount, interServiceTotalAmount: interServiceTotalAmount, mishlohaTotalAmount: mishlohaTotalAmount,
						goodiTotalAmount: goodiTotalAmount,dtsTotalAmount:dtsTotalAmount, banxTotalAmount: banxTotalAmount, safeCashTotalAmount: safeCashTotalAmount,
						multipassPoliceTotalAmount:multipassPoliceTotalAmount, caveretPaymentTotalAmount: caveretPaymentTotalAmount, yaadTotalAmount: yaadTotalAmount, tamashTotalAmount: tamashTotalAmount,tamashCount: tamashCount,
						totalHakafaTamash: totalHakafaTamash, totalHakafa: totalHakafa, hakafaCount: hakafaCount, 
						cibusCount: cibusCount, tenbisCount: tenbisCount,goodiCount: goodiCount,dtsCount: dtsCount, valuCount: valuCount, banxCount: banxCount,
						safeCashCount: safeCashCount, yaadCount: yaadCount,caveretPaymentCount:caveretPaymentCount,multipassPoliceCount:multipassPoliceCount, interServiceCount:interServiceCount, mishlohaCount:mishlohaCount,
						paiditTotalAmount: paiditTotalAmount,
						paiditCount: paiditCount, deliveryApiTotals: deliveryApiTotals}
	}

	static isEmvNegativePayment(paymentData: PositiveTS.Types.PaymentData) {
		return paymentData.TranType?.toString() == "53" || paymentData.Mti?.toString() == "400"
	}

}





}}}
