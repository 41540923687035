module PositiveTS {
export module Service {
export module MultipassLoadBudget {
    const HANDLER = "LOAD_MULTIPASS";
    let loadBudgetExists = undefined;
    let loadBudgetItemCode = undefined;

    export async function loadCardBudget() {
        try {
            app.showLoadingMessage(i18next.t("multipass.loadingCard"));
            let loadData =  Pinia.globalStore.multipassPendingLoadBudget;
            let result = await (new Service.Multipass()).loadBudget(loadData.card, loadData.amount);
            if(result.success) {
                Pinia.globalStore.cleanMultipassPendingLoadBudget();
            } else {
                result.error = `${i18next.t("multipass.multipassError")}\n${result.error}\n${i18next.t("multipass.multipassErrorCode")} ${result.error_code}`
            }
            
            return result;
        } catch(err) {
            console.error(err);
            return {
                success: false,
                error: i18next.t('multipass.errorLoading')
            }
        } finally {
            app.hideLoadingMessage();
        }
    }

    export function checkItemValid(saleItemToAdd:Storage.Entity.SaleItem, saleItemsInSale:Array<Storage.Entity.SaleItem>) {
        if(!posHasMultipassLoad()) {
            return { valid: true }
        }

        if(getLoadBudgetItemCode() == saleItemToAdd.itemCode && saleItemsInSale.length > 0) {
            return {
                valid: false,
                msg: i18next.t('multipass.emptySaleValidation')
            }
        }
        else if(saleItemsContainLoadBudget(saleItemsInSale)) {
            return {
                valid: false,
                msg: i18next.t('multipass.additionalItemsValidation')
            }
        } else if(getLoadBudgetItemCode() == saleItemToAdd.itemCode && saleItemToAdd.quantity !== 1) {
            return {
                valid: false,
                msg: i18next.t('multipass.additionalQuantityValidation')
            }
        }

        return { valid: true }
    }

    export async function saveCardToLoadInSale(saleItem:Storage.Entity.SaleItem) {
        let cardNumber = await PositiveTS.VueInstance.$refs.swipeCardDialog.getSwipedCard();
        if(posUtils.isBlank(cardNumber)) {
            
           return { success: false, error: i18next.t('multipass.noCardSwiped')} 
        }
        app.showLoadingMessage(i18next.t('multipass.checkingCard'));
        let balanceResponse = await (new Service.Multipass()).getBalance(cardNumber)
        app.hideLoadingMessage();
        if(!balanceResponse.success) {
            return { success: false, error: balanceResponse.error};
        }
        Pinia.globalStore.setMultipassPendingLoadBudget({card: cardNumber, amount: saleItem.unitPrice*100});
        return { success: true, error: null}

    }

    export function saleItemsContainLoadBudget(saleItems:Array<Storage.Entity.SaleItem>) {
        let loadBudgetItem = saleItems.find((item:Storage.Entity.SaleItem) => item.itemCode == getLoadBudgetItemCode())
        return posUtils.isDefined(loadBudgetItem);
    }

    export function posHasMultipassLoad() {
        if(loadBudgetExists === undefined) {
            let specialItem = posVC.Specialitems.find((item) => item.hndlr == HANDLER );
            loadBudgetExists = posUtils.isDefined(specialItem);
        }
        return loadBudgetExists;
    }

    export function getLoadBudgetItemCode() {
        if(loadBudgetItemCode === undefined) {
            let specialItem = posVC.Specialitems.find((item) => item.hndlr == HANDLER );
            if(specialItem && specialItem.code) {
                loadBudgetItemCode = specialItem.code;
            }
        }
        return loadBudgetItemCode;
    }

}}}