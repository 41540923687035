module PositiveTS {
  export module Components {
    export module SplitSalePaymentDialog {
      const dialogSelector = "split-sale-payment-dialog"

      function initData() {
        return {
          amount: 0,
          dedicatedTo: '',
          focusElement: '',
          fields: initFields(),
          reminderToPay: 0,
          allowMultipleSaleItemsSelection: jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection),
          selectedItemsAmount: 0
        }
      }

      function initFields() {
        let fields = {
          'amount': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'amount'
          }
        }
        return fields;
      }

      export function create() {

        let component = {
          template: JST.splitSalePaymentDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            open(data:any = {}) {
              app.showLoadingMessage()
              this.cleanData();
              this.setReminderToPay()
              $(document).unbind('keypress');

              if (posUtils.isDefined(data)) {
                if (posUtils.isDefined(data.amount)) {
                  this.amount = data.amount;
                }
                if (posUtils.isDefined(data.dedicatedTo)) {
                  this.dedicatedTo = data.dedicatedTo;
                }
              }

              if (this.allowMultipleSaleItemsSelection) {
                let selectedItems = posVC.fetchArrayPosVCSaleItems(Pinia.globalStore.multipleSelectedSaleItems)

                if(selectedItems && selectedItems.length > 0) {
                  this.selectedItemsAmount = _.sumBy(selectedItems, (item) => { return item.priceNetoAfterDiscounts })  //The multiple selection feature only works with new promotions companies, so in this case suming by priceNetoAfterDiscounts is enough.
                  this.amount = this.selectedItemsAmount
                } else {
                  this.amount = 0
                }
              }
      
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;

                this.$nextTick(() => {
                  app.hideLoadingMessage()
                  this.$refs.amount.focus();
                })
              })
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            confirmAndClose() {
              if (isNaN(this.amount) || this.amount <= 0 || this.amount > this.reminderToPay) {
                return this.showError(i18next.t("splitSalePaymentDialog.invalidAmount"));
              }

              this.resolveFunc({amount: this.amount, dedicatedTo: this.dedicatedTo})
              return this.close();
            },
            cancel() {
              this.resolveFunc()
              this.close();
            },
            onKeyTapped(key) {
              this.keyPadHanlder(key, this.fields["amount"], this, 3);
            },
            clearTapped(stayOpen?) {
              this.amount = 0;

              if(!stayOpen){
                this.cancel();
              }
            },
            showError(errStr) {
              let aThis = this;
              app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                () => {
                  this.$refs.amount.focus();
                }, null)
            },
            setReminderToPay() {
              this.reminderToPay = posVC.getTotalLeftToPay()
            },
            focus(element) {
              this.focusElement = element;
            },
            updateVal(val) {
              this.dedicatedTo = val;
            }
          },
          data: initData,
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {sale,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {sale,mobileLayout}

            return {...globalStoreProps}
          },
        }

        VueApp.component('split-sale-payment-dialog', component)

      }
    }
  }
}
