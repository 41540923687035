module PositiveTS {
  export module Components {
    export module keyPadPaymentsDialog {
      const dialogSelector = "keypad-payments-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          rejectFunc: null,
          resolveFunc: null,
          payments: 2,
          originalPayments: 1,
          dialogElem: document.getElementById(dialogSelector),
          fields: initFields(),
        }
      }

      function initFields() {
        let fields = {
          'payments': {
            selected: true,
            allowDecimal: false,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'payments'
          }
        }
        return fields;
      }

      function close() {
        if (this.initialized) {
          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.close();
        }
        this.cleanData()
      }

      function open() {
        return new Promise((resolve, reject) => {
          this.initialized = true;

          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.showModal();
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
        })
      }

      function showError(errStr) {
        app.showAlertDialog({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        })
      }

      function confirmAndClose() {
        let payments = Number(this.payments);

        if (payments <= 1) {
          this.showError(i18next.t("pos.paymentsMinimalQuantityIs2"));
          this.payments = 2
          return;
        }

        this.resolveFunc({ approved: true, payments: payments });
        this.close();
        return;
      }

      function cancelTappedAndClose() {
        this.resolveFunc({ approved: false, payments: this.originalPayments });
        this.close();
      }

      function onKeyTapped(key) {
        this.keyPadHanlder(key, this.fields["payments"], this, 3);
      }

      export function create() {

        let component = {
          template: JST.keyPadPaymentsDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData: cleanData,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            onKeyTapped: onKeyTapped,
            cancelTappedAndClose: cancelTappedAndClose,
            showError: showError,
          },
          data: initData,
        }

        VueApp.component('keypad-payments-dialog', component)

      }
    }
  }
}
