module PositiveTS {
    export module Service {
        export module SpecialDiscounts {
			export function getDiscounts(callback) {
		
				$.getJSON(
					Shared.Constants.remoteRoot + 'one_time_discounts',
					{

					},
					callback
				).error(function() {
					callback(null);
				});
			};

			export function useSpecialDiscount(discountID, invoiceSequence) {
				
				return new Promise((resolve,reject) => {
					$.ajax({
						type: "PUT",
						url: Shared.Constants.remoteRoot + 'one_time_discounts/' + discountID,
						data: {
							invoice: invoiceSequence
						},
						dataType: 'json',
						success: function(results) {
							if (results !== null && results.success === true) {
								resolve();
							} else {
								reject(new Error(i18next.t("specialDiscountUseError")));
							}
						}
					}).error(function(err) {
						reject(new Error("לא ניתן לטעון מידע"));
					});
	
				})
				
				
			}
		}
	}
}