module PositiveTS {
  export module VueServices {
    export class Initializer {
        static async init() {
          VueApp = VueServices.Initializer.initMainInstance()
          VueServices.Initializer.initComponents()
          PositiveTS.Components.Directives.createDirectives()
          const vueInstance = VueApp.mount("#vue-wrapper")
          PositiveTS.VueInstance = vueInstance
          PositiveTS.Service.Sale.initLiveQuerySaleChangesSubscribes()
          return vueInstance
        }

        private static registerComponents(app, components) {
          Object.keys(components).forEach(key => {
            app.component(key, components[key]);
          });
        }

        private static initMainInstance() {
          const vueApp = createApp({
            data () { //we define props here for use in the root index-simple-orig rendering
              const data = {
              cardReader: Boolean(session.pos.usePinPad),
              isVorlonActivated: false,
              mizdamenOrHakafaMode: Boolean(jsonConfig.getVal(jsonConfig.KEYS.mizdamenOrHakafaMode)),
              askForClubMembershipeMode: Boolean(jsonConfig.getVal(jsonConfig.KEYS.askForClubMembershipeMode)),
              isHaveToChooseClubMembership: Boolean(jsonConfig.getVal(jsonConfig.KEYS.isHaveToChooseClubMembership)),
              supportStyle: {color: '#FFF', fontWeight:'bold', fontSize: '25px'},
              offlineSales: this.$t("offlineSales"),
              useWifiPrinter: window.localStorage.getItem("useWifiPrinter") == "true",
              wifiPrinterIp: window.localStorage.getItem("wifiPrinterIp"),
              emvIpAddress: window.localStorage.getItem("emvIpAddress"),
              useRoshemetBigLayout: window.localStorage.getItem("useRoshemetBigLayout") == "true",
              dbgDevicePixelRatio: window.devicePixelRatio,
              dbgOuterHeight: window.outerHeight,
              dbgOuterWidth: window.outerWidth,
              vorlonBtnText: this.$t('params.vorlonSupportStart'),
              isMultiCurr: Service.MultiCurr.getInstance().isMultiCurr(),
              isDutyFree: Service.DutyFree.isDutyFree(),
              isShipsPickup: jsonConfig.getVal(jsonConfig.KEYS.isPickupEnabledShips),
              hasFlights: session && session.pos && session.pos.hasFlights,
              isCaveret: session && session.pos && session.pos.isCaveret,
              isIsrairPos: jsonConfig.getVal(jsonConfig.KEYS.isIsrairPos),
              caspitTenants: session.pos.tenantID == "14" || session.pos.tenantID == "17" || window.location.hostname.includes("localhost"),
              sunmiInternalEMVPayment: jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment),
              TabletDefaultOrientation: jsonConfig.getVal(jsonConfig.KEYS.TabletDefaultOrientation),
              screenOrientaion: localStorage.getItem("screenOrientaion") || jsonConfig.getVal(jsonConfig.KEYS.TabletDefaultOrientation),
              created: false,
              lastElalSaleSequenceFromSale: null,
              displayLangSelector: false,
              }
              return data
            },

            methods: {
              async resetLastTimeSaleSyncFailed(){
                const keyValueItem = new PositiveTS.Storage.Entity.KeyValueStore(Shared.Constants.SaleSync.lastTimeSaleSyncFailed, null);
                await appDB.keyValueStore.put(keyValueItem);
              },
              toggleLanguageSelection() {
                this.displayLangSelector = !this.displayLangSelector;
              },
              setOrientation: function() {
                if(session.isAndroid && posUtils.allowOrientationChange() && this.allowOrientationChange){
                  localStorage.setItem("screenOrientaion", orientation.toString())
                  Android.setOrientation(parseInt(String(orientation)))
                }
              },
              logout: () => {
                Pinia.componentsStore.openComponent( {componentName:"logoutDialog", args: []});
              },
              saveRoshemetLayout() {
                window.localStorage.setItem("useRoshemetBigLayout", PositiveTS.VueInstance.useRoshemetBigLayout)
                Pinia.globalStore.setRoshemetBigLayout(PositiveTS.VueInstance.useRoshemetBigLayout);
              },
              saveWifiSettings: () => {
                if (PositiveTS.VueInstance.useWifiPrinter) {
                  window.localStorage.setItem("useWifiPrinter", PositiveTS.VueInstance.useWifiPrinter)
                  window.localStorage.setItem("wifiPrinterIp", PositiveTS.VueInstance.wifiPrinterIp)
                } else {
                  window.localStorage.removeItem("useWifiPrinter");
                  window.localStorage.removeItem("wifiPrinterIp");
                }

                alert("wifi settings saved")
              },
              saveEmvIpAddress: () =>{
                window.localStorage.setItem("emvIpAddress", PositiveTS.VueInstance.emvIpAddress);
                app.showAlert({
                  header:i18next.t("params.updateEmvIpAddressHeader"),
                  content: `${i18next.t("params.updateEmvIpAddress")}`,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                },function () {
                  // Update settings screen status to ok
                  return window.location.reload();
                }, null);

              },
              scale600: () => {
                document.querySelector("meta[name=viewport]").setAttribute('content',
                'width=device-width, initial-scale='+(window.outerWidth/600)+', maximum-scale='+(window.outerWidth/600)+', user-scalable=no');
              },
              scale1: () => {
                document.querySelector("meta[name=viewport]").setAttribute('content',
                'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
              },
              async estimatedQuota() {
                let newNav:any = window.navigator
                if (newNav.storage && newNav.storage.estimate) {
                  const estimation = await newNav.storage.estimate();
                  console.log(`Storage estimation:`);
                  console.log(estimation)
                  let percent = ((estimation.usage/estimation.quota) * 100).toPrecision(2)
                  app.showAlert({
                    header:i18next.t("storagemanager.usage"),
                    content: `${i18next.t("storagemanager.usage")}: ${percent}%`,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                } else {
                  console.error("StorageManager not found");
                }
              },
              openOfflineSalesDialog() {
Pinia.componentsStore.openComponent( {componentName:"offlineSalesDialog", args: []});
              },
              showOpenSourceCredits(){
                app.showAlert({
                  header:i18next.t("params.credits"),
                  content: `POS Credit Card Chip animation by @vanesamelonio/LottieFiles \n
                  Success animation by @geraksembayu/LottieFiles`,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              },
              activateVorlon(){
                if(!this.isVorlonActivated){
                  this.startVorlon();
                }else{
                  this.stopVorlon();
                }
              },
              startVorlon() {
                window.eruda.init()
                window.eruda.add(window.erudaCode);
                if (VORLON && VORLON.Production) {
                  if ((<any>window).vorlonProd == null) {
                    (<any>window).vorlonProd = new VORLON.Production("https://demo.valu.co.il:41337", "positive-support");
                    (<any>window).vorlonProd.setIdentity(session.pos.deviceID);
                    (<any>window).vorlonProd.deactivate();
                    (<any>window).vorlonProd.activate();
                    this.vorlonBtnText = i18next.t('params.vorlonSupportStop');
                  }
                }
                this.isVorlonActivated = true;
              },
              stopVorlon(){
                if ((<any>window.eruda)._isInit) {
                  window.eruda.remove(window.erudaCode);
                  window.eruda.destroy()
                }
                if (VORLON && VORLON.Production) {
                  if ( !( (<any>window).vorlonProd == null)) {
                    if ((<any>window).vorlonProd.isActivated) {
                      (<any>window).vorlonProd.deactivate(true);
                    }
                  }
                }
                this.isVorlonActivated = false;
              },
              setPCLType(type){
                if(Android.SetPCLMode(type)){
                  if(type == "SERIAL"){
                    Android.DeleteFile("firstboot.txt");
                  }
                    app.showAlert({
                      header:"שינוי סוג תקשורת",
                      content: "סוג התקשורת שונה בהצלחה, יש להפעיל את האפליקציה מחדש",
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    }, function(){
                      Android.KillActivity();
                    }, null);
                } else{
                  app.showAlert({
                    header:"שינוי סוג תקשורת",
                    content: "שינוי סוג התקשורת נכשל",
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                }
              },
              lunchTeamViewer(){
                  Android.LunchTeamViewer();
              },
              async updatePinPad() {
                await Service.EMV.updatePinPad();
              },
              async sendEMVLogsToCaspitFTP() {
                await Service.EMV.sendEMVLogsToCaspitFTP();
              },
              async callCaspitTechServer() {
                await Service.EMV.callCaspitTechServer();
              },
              async terminalCheck() {
                await Service.EMV.terminalCheck();
              },
              async transmit(){
                let transmitAndDeleteResult = await Service.EMV.transmitAndDeleteTranIfNeeded()
                let transmitResult = transmitAndDeleteResult[0]
                if(transmitResult.ResultCode == "0"){
                  await this.printLastTransmit();
                }
                else{
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('emv.TRANSMIT_ERROR'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }
              },
              async printLastTransmit(){
                let emvTotal = await Service.EMV.getTotal();
                if (emvTotal.StaticObj && !posUtils.isNullOrUndefinedOrEmptyString(emvTotal.StaticObj.SessionNumber)) {
                  let deposit = await Service.EMV.getDepositReport(emvTotal.StaticObj.SessionNumber,emvTotal.StaticObj.Date);
                  await Service.EMV.printTransmitReportStandalone(deposit);
                }

              },
              async closeFlightWithutPinpadTransmit() {
                let paramsButtonPassword = session.pos.getPassword('z_without_transmit');

                let closeFlightFunction = () => Service.CloseFlight.CloseFlightAndZReport(true);

                if(paramsButtonPassword){
                  let res = await Pinia.componentsStore.openComponent( {componentName:"techCardDialogComponent", args: [paramsButtonPassword]})

                    if(res.approved){
                      closeFlightFunction();
                    } else {
                        app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('errorTechnicalCard'),
                        hideCancelButton: true
                      });
                    }
                  } else {
                    closeFlightFunction();
                  }
              },
              startPCL(){
                Android.StartPCL();
              },
              stopPCL(){
                Android.StopPCL();
              },
              listUsbDevices(){
Pinia.componentsStore.openComponent( {componentName:"usbDevicesDialog", args: []});
              },
              clearAllVersionsCache(){
                app.showAlert({
                  header: i18next.t('storagemanager.clearStorage'),
                  content: i18next.t('storagemanager.makeSureOnlineBeforeClearStorage'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: false,
                },async function(){
                  await posUtils.clearAllOldPosVersionsCache()
                });
              },
              deleteFirstBoot(){
                if(Android.DeleteFile === undefined){
                  app.showAlert({
                    header: "אתחול תקשורת לפינפאד",
                    content: "פעולה לא נתמכת - יש לעדכן גירסת אפליקציה",
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });

                  return;
                }

                let res = Android.DeleteFile("firstboot.txt");
                if(res){
                  app.showAlert({
                    header: "אתחול תקשורת לפינפאד",
                    content: "הקובץ firstboot.txt נמחק בהצלחה",
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  },function(){
                    Android.KillActivity();
                  })
                }else{
                  app.showAlert({
                    header: "אתחול תקשורת לפינפאד",
                    content: "שגיאה במחיקת הקובץ firstboot.txt",
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }
              },
              isEmv: () => { return session.pos.isEmv },
              clearMinusOneSales: () => posUtils.clearMinusOneSales(),
              sendBadTranLog: () => posUtils.sendBadTranLog(),
              isMultiCurrency: () => Service.MultiCurr.getInstance().isMultiCurr(),
              canSendInvoiceSms: () => jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice),
              isDalpakimOnline: () => jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline),
              mobilePhoneRepairModule: () => jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule),
              canManageHakafaCustomer: () => jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos),
              posUsesBonsPrinting: () => jsonConfig.getVal(jsonConfig.KEYS.allowBonPrinting),
              isOtot: () => Service.Otot.isOtotActive(),
              shouldGoToDalpakScreen() {
                if (!jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  return false;
                }

                if (this.currentPage != 'pos') {
                  return false;
                }

                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline)) {
                  if (Service.WebsocketSync.SyncServerClient.isPrimaryPos()) {
                    return true;
                  }

                  if (this.syncServerOnlineState == Service.WebsocketSync.SyncServerClient.CONNECTION_STATUSES.CONNECTED) {
                    return true;
                  }

                  return false;
                }

                return true;
               },
              openSendSmsInvoiceDialog: () => {
                let sale = posSearchInvoicesVC.getSelectedSale();
                let storageSale = null;
                if (sale) {
                  storageSale = PositiveTS.Service.Sale.ToStorageTypeSale(sale);
                }

                PositiveTS.Service.Sms.openPhoneDialogAndSendInvoiceSms(storageSale);
              },
              async printOtotBracelet() {
                let sale = posSearchInvoicesVC.getSelectedSale();
                let storageSale = null;
                if (sale) {
                  storageSale = PositiveTS.Service.Sale.ToStorageTypeSale(sale);
                }

                if (storageSale) {
                  await Service.Otot.printTagsFromSaleWithManangerApproval(storageSale);
                }
              },
              async printBon() {

                let sale = posSearchInvoicesVC.getSelectedSale();
                let storageSale = null;

                if (sale) {
                  storageSale = PositiveTS.Service.Sale.ToStorageTypeSale(sale);
                } else {
                  return;
                }

                app.showManagerApprovalDialog().then(async ()=>{
                  if(await Service.LogicalPrinterBonPrint.saleHasBonItems(storageSale)){
                    let saleJsonData = posUtils.isBlank(storageSale.jsondata) ? {} : JSON.parse(storageSale.jsondata);
                    Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(storageSale, saleItemHelper.unflattenSaleItems(storageSale.items, true, saleJsonData.useNewPromotions),storageSale.payments,null,true)
                  }
                  else{
                    app.showAlert({
                      header: i18next.t('error'),
                      content: i18next.t('posPastInvoices.noBonsInSale'),
                      hideCancelButton: true
                    },null,null);
                  }
                })
              },
              goToHomepage() {
                if(Pinia.globalStore.posState != 'pos') {
                  return;
                }

                if (this.dalpakTablesView && this.goBackAction) {
                  this.goBackAction();
                  return;
                }

                if (this.shouldGoToDalpakScreen()) {
                  if(PositiveTS.VueInstance.$refs.pos.showOrderSummary) {
                    PositiveTS.VueInstance.$refs.pos.showOrderSummary = false
                  }
                  PositiveTS.VueInstance.$refs.pos.dalpaksSaveExit();
                  return;
                }

                if(posVC?.sale?.["hasUpdatedGroupItems"]) {
                  PositiveTS.Components.Pos.printBon()
                }

								posVC.RedirectIfAuthorized(jsonConfig.getVal(jsonConfig.KEYS.isHomeScreenAuthorizationRequired)
                  ,() => {
                    if (jsonConfig.getVal(jsonConfig.KEYS.isAlignSelfService)) {
                      PositiveTS.VueInstance.$refs.selfServicePaymentDialog.close()
                    }
                    if(PositiveTS.Service.OsherAd.isEnabled()){
                      PositiveTS.VueInstance.$refs.OsherAdComponent.close()
                    }
                    pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null)
                  });
                PositiveTS.Service.DutyFree.clearFlightCardFromSale();
              },
              goToPos () {
                if(Pinia.globalStore.posState == 'pos') {
                  pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null)
                }
                Pinia.globalStore.setMobilePosNavState(PositiveTS.Components.PosViewState.Buttons);
                pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null)
              },
              fixedNumber (value) {
                if (value == null){
                  return ''
                }
                return session.fixedNumber(value)
              },
              isStaging() {
                return posUtils.isStaging();
              }
            },
            watch:{
              currentSale(newVal){
                if(newVal){
                  const currentSale = Object.assign({}, newVal);
                  this.lastElalSaleSequenceFromSale = null;
                  if(!posUtils.isBlank(currentSale)){
                    let saleJsonData = JSON.parse(currentSale.jsondata);
                    this.lastElalSaleSequenceFromSale = saleJsonData.elalSaleSequenceNumber
                    if(!this.lastElalSaleSequenceFromSale){
                      this.lastElalSaleSequenceFromSale = null;
                    }
                  }
                }
              },
              currentPage(newVal){
                if(newVal !='pos'){
                  this.lastElalSaleSequenceFromSale = null;
                }
              }
            },
            computed: {
              hypLogo() {
                return `/versions/${PositiveTS.Version.appVersion}/assets/images/logos/hyp.png`
              },
              positLogo() {
                return `/versions/${PositiveTS.Version.appVersion}/assets/images/logos/posit.png`
              },
              lastElalSequence(){
                if(this.isElal && this.lastElalSaleSequenceFromSale > -1){
                  return this.lastElalSaleSequenceFromSale;
                }
                return -1
              },
              isElal(){
                return Pinia.elalStore.isOn
              },
              identificationManagerByFingerPrintOnly(){
                return jsonConfig.getVal(jsonConfig.KEYS.identificationManagerByFingerPrintOnly)
              },
              isManagerApprovalBySms(){
                return jsonConfig.getVal(jsonConfig.KEYS.isManagerApproveActionBySMS)
              },
              dalpakTablesView() {
                return jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView);
              },
              restaurantNewPOS() {
                return jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS);
              },
              hasRemoteZ() {
                return session.pos.hasRemoteZ
              },
              isAndroid() {
                return session.isAndroid;
              },
              isDeliveryPos(){
                return jsonConfig.getVal(jsonConfig.KEYS.isDelivery);
              },
              simpleSelfService() {
                return jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService);
              },
              hasCashWithdrawal() {
                return Service.Withdrawal.posHasCashWithdrawals();
              },
              allowOrientationChange() {
                // feature disabled for now so it returns false
                return false;
                return posUtils.allowOrientationChange();
              },
              deviceSerialNumber(){
                if(session.isAndroid && Android.getDeviceSerialNumber != undefined){
                  return Android.getDeviceSerialNumber()
                }
                else{
                  return "N/A"
                }
              },
              inPaymentScreen(){
                return Pinia.globalStore.posState == "payment";
              },
              ...Pinia.mapStores(Pinia.useComponentsStore)
            },
            setup(){
              const dalpaksStore = Pinia.useDalpaksStore();
              const {dalpaksPageTitle,hideHamburgerMenu,goBackAction} = Pinia.storeToRefs(dalpaksStore);

              const flightsStore = Pinia.useFlightsStore();
              const {currentLeg,isLastLeg,currentFlight} = Pinia.storeToRefs(flightsStore);

              const languageManager = Pinia.useLanguageManagerStore();
              const {currentDisplayedLanguageName} = Pinia.storeToRefs(languageManager);

              const offlineSaleCount = Vue.computed(() => {
                return Pinia.globalStore.offlineSaleCount == 0 ? i18next.t('none') : Pinia.globalStore.offlineSaleCount;
              });
              const globalStore = Pinia.useGlobalStore();
              const {sale,
                syncServerOnlineState,
                selfServiceSuperMarket,
                portraitMode,
                isRoshemet,
                mobileLayout,
                simpleSelfService,
                itemWithDialogXL,
                supportNumber,
                currentPage} = Pinia.storeToRefs(globalStore);
                
              const globalStoreProps = {
                currentSale:sale,
                syncServerOnlineState,
                selfServiceSuperMarket,
                portraitMode,
                isRoshemet,
                mobileLayout,
                simpleSelfService,
                itemWithDialogXL,
                supportNumber,
                currentPage}

              return { ...globalStoreProps, dalpaksPageTitle, hideHamburgerMenu, goBackAction, currentLeg, isLastLeg, currentFlight, currentDisplayedLanguageName, offlineSaleCount }
            },
            created() {
              Pinia.initStore();
              this.setOrientation();
              this.created = true;
            },

            mounted () {
              vueApp.config.globalProperties.$refs = this.$refs
            },
          })
          
          //vue app uses here
          const router = Router.getRouter()
          vueApp.use(router)
          vueApp.use(Pinia.instance)
          vueApp.use(i18n)
          vueApp.use((<any>window).VueMask)
          vueApp.use((<any>window).Vue3TouchEvents)
          vueApp.component('font-awesome-icon', (<any>window).FontAwesomeIcon)
          this.registerComponents(vueApp, Posimod.Components);

          vueApp.use((<any>window).Vue3Lottie,{ name: 'LottieAnimation' })
          //set global properties
          vueApp.config.globalProperties.fixedNumber = function (value) {
            if (value == null){
              return ''
            }
            return session.fixedNumber(value)
          }

          vueApp.config.globalProperties.momentFormat = function (date, format) {
            return moment(date).format(format)
          }

          vueApp.config.globalProperties.cutStrLimit = function (str, length = 30) {
            let cutNotes = ''
            const regex = new RegExp(`.{1,${length}}`, 'g')
            if (typeof str == 'string') {
              let notes = str.match(regex)
              if (notes){
                if (notes.length == 1){
                  cutNotes = notes[0]
                }

                if (notes.length > 1){
                  cutNotes = `${notes[0]}...`
                }
              }
            }

            return cutNotes
          }

          vueApp.config.globalProperties.to_f = function (number) {
            return session.fixedFloat(number, 2)
          }

          vueApp.config.globalProperties.lodash = _


          vueApp.config.globalProperties.blurInputsOnElement = function (element) {
            let inputs = element.querySelectorAll('input')
            if (inputs.length > 0){
              for (let input of inputs){
                input?.blur()
              }
            }
          }

          return vueApp
        }

        private static initComponents() {
          VueApp.component('v-select', VueSelect)
          VueApp.component(Components.HakafaCustomerDialog.componentName, Components.HakafaCustomerDialog.getComponent())
          PositiveTS.Components.HeaderComponent.create();
          Components.SimpleKeyboard.create();
          Components.PositiveButton.create();
          Components.PositiveButtons.create();
          Components.MenuSideBar.create();
          Components.HomePage.create()
          Components.ItemButtonMenu.create()
          Components.PositiveDatepicker.create()
          Components.TableView.create()
          Components.KeyPad.create()
          Components.MobilePosNavigation.create()
          Components.Pos.create()
          Pages.PosPastInvoices.create()
          Pages.PosSearchInvoices.create()
          Components.CreditCard.create()
          Components.Cash.create()
          Components.WeightDialog.create()
          Components.QtyDialog.create()
          Components.SplitSalePaymentDialog.create()
          Components.SelectDinersNumberDialog.create()
          Components.selectItemQuantityDialog.create()
          Components.QrDialog.create()
          Components.SelectSplitPaymentAmountDialog.create()
          Components.AddItemDialog.create()
          Components.keyPadPaymentsDialog.create()
          Components.ScanBarcodeDialog.create()
          Components.TextInputDialog.create()
          Components.HakafaInvoicesDialog.create()
          Components.QuicksaleDialog.create()
          Components.ValuecardPointsDialog.create()
          Components.SimplyClubBenefitsDialog.create();
          Components.CreditCardNoPinPad.create()
          Components.PosPayment.PluxeeView.create();
          Components.PosPayment.TenbisView.create();
          Components.PosPayment.OtotView.create();
          Components.PosPayment.InterServiceView.create();
          Components.PosPayment.CheckView.create();
          Components.PosPayment.GoodiView.create();
          Components.PosPayment.BanxView.create();
          Components.PosPayment.YaadView.create();
          Components.PosPayment.PaymentPage.create();
          Components.PosPayment.TipView.create();
          Components.PosPayment.create()
          Components.MizdamenOrHakafaDialog.create()
          Components.MizdamenOrHakafaSelfServiceDialog.create()
          Components.ClubMemberSelfServiceDialog.create()
          Components.valueCardAssetsScreen.create()
          Components.PosPaymentButtons.create()
          Components.PositiveCustomerClub.create()
          Components.ValuecardCustomerClub.create()
          Components.SimplyClubCustomerClub.create()
          Components.PriorityHakafaReportDialog.create()
          Components.hakafaMerakezInvoicesDialog.create()
          Components.PriorityImportOrderDialog.create()
          Components.SalesPersonDialog.create()
          Components.DuplicateBarcodeDialog.create()
          Components.CreditCreditCardDialog.create()
          Components.ItemWithGroupsDialog.create()
          Components.PreparationInstructionsDialog.create()
          Components.PositiveRadio.create()
          Components.PosSalesPerson.create()
          Components.EmployeeEditHours.create()
          Components.UsbDevices.create();
          Components.AlertDialog.create();
          Components.TimedAlertDialog.create();
          Components.ElalPermissionDialog.create();
          Components.MidragPayments.create();
          Components.LoadingMessage.create();
          Components.LoadingMessageWithCancel.create();
          Components.EmployeeReportMonthDialog.create()
          Components.ValuCardBalance.create()
          Components.CheckCardBalance.create()
          Components.PunchCardInput.create();
          Components.PunchCardListBalance.create();
          Components.PunchCardTransactionsReport.create();
          Components.PunchCardGenericSelect.create();
          Components.ValuCardReport.create()
          Components.ItemInventoryItm.create()
          Components.ReportZByDate.create()
          Components.GenericItemDescription.create()
          Components.ReportEmployeeTime.create()
          Components.LockScreen.create();
          Components.LoginDialog.create();
          Components.SelectDalpakFromListDialog.create();
          Components.CaveretPasswordDialog.create();
          Components.MultiButtonDialog.create();
          Components.FlightsOnlineLoginDialog.create();
          Components.FlightsDisconnectDialog.create();
          Components.FlightsOpenLegWizardDialog.create();
          Components.FlightsCloseLegWizardDialog.create();
          Components.ChangePasswordDialog.create();
          Components.AccountActionsMenu.create();
          Components.ElalSaleWithoutPNR.create();
          Components.ReleaseSaleDialog.create();
          Components.ItemSheilta.create();
          Components.FullSearch.create();
          Components.DedicatedToDialog.create();
          Components.ItemPromoList.create();
          Components.LogoutDialog.create();
          Components.BonPrinterMapDialog.create();
          Components.EmployeeInStore.create();
          Components.PackageDialog.create();
          Components.CalculatorDialog.create();
          Components.PositiveDeliveryTableView.create();
          Components.PositiveDeliveryOrderDetail.create();
          Components.CouriersAssignmentDialog.create();
          Components.PositiveDeliveryPreviousOrders.create();
          Components.PositiveDeliveryShowOrder.create();
          Components.PositiveDeliveryCustomerAddress.create();
          Components.PositiveDeliveryCustomerCompensation.create();
          Components.PositiveDeliveryCustomerClub.create();
          Components.OpenDeliveryOrdersDialog.create();
          Components.CloseDeliveryOrdersDialog.create();
          Components.PeriodReportDialog.create();
          Components.vouchersReportDialog.create();
          Components.periodReportSettingsDialog.create();
          Components.TechCardDialog.create();
          Components.WithdrawalDialog.create();
          Components.CaveretPaymentCustomerPicker.create();
          Components.SelfServiceCaveretSelectClubDialog.create();
          Components.DTSCustomerClub.create();
          Components.CaveretPaymentSaleDialog.create();
          Components.WithdrawalRefundDialog.create();
          Components.CashManagementReport.create();
          Components.MobileInfo.create();
          Components.CashierStatement.create();
          Components.CashManagement.create();
          Components.SuperFriendsCustomerClub.create();
          Components.SelectCurrencyPayment.create();
          Components.CurrencyConversionCalculator.create();
          Components.PickupFormDialog.create();
          Components.PickupShipsFormDialog.create();
          Components.FlightCardDialog.create();
          Components.EnterPNRCodeDialog.create();
          Components.chooseChangeCurrencyDialog.create();
          Components.multiCurrencyCashDetailsDialog.create();
          Components.currenciesRatesDialog.create();
          Components.SelectPNRItemsDialog.create();
          Components.FlightSaleWithPassDialog.create();
          Components.ZReport.create();
          Components.CashierStatementMultiCurrencies.create();
          Components.VouchersWithoutInterfacesReportDialog.create();
          Components.XReport.create();
          Components.OfflineSalesDialog.create();
          Components.EmailDialog.create();
          Components.WithdrawalReportDialog.create();
          Components.SwipeCardDialog.create();
          Components.SelfServiceScreenSaver.create();
          Components.HakafaReportDialogNew.create();
          Components.TimeClockDialog.create()
          Components.CreditSelectModal.create();
          Components.reportsDialog.create();
          Components.SelfServicePayment.create();
          Components.SelfServiceTotalBeforePayDialog.create();
          Components.SelfServiceGenericPrepInstructionsDialog.create();
          Components.UpsaleDialog.create();
          Components.selfServiceMigvanSelect.create();
          Components.selfServiceSittingTaDialog.create();
          Components.MultiCurrencyChangeCloseSaleDialog.create();
          Components.FlightsIsrairOpenDialog.create();
          Components.FlightsIsrairCloseDialog.create();
          Components.CustomerSalesReportDialog.create();
          Components.OneClickButton.create();
          Components.PosPayment.VoucherView.create();
          Components.PosPayment.CreditVoucherView.create();
          Components.PromotionsReport.create();
          Components.VueDialog.create();
          Components.PricelistCustomerPicker.create();
          Components.CustomerPointsDialog.create();
          Components.ExternalOrdersList.create();
          Components.SendInvoiceSmsDialog.create();
          Components.OutOfStockItemsDialog.create();
          Components.FinishTimeItemDialog.create();
          Components.ExternalOrdersDifferences.create();
          Components.SelectDalpakAreaModal.create();
          Components.showCustomerSalesReportDialog.create();
          Components.MultipassPoliceBalance.create();
          Components.MultipassPoliceCustomerPicker.create();
          Components.MultipassPoliceChoosePoints.create();
          Components.checkPosProprietyDialog.create();
          Components.AdditionalNotesDialog.create();
          Components.PosPayment.ICMegaView.create();
          Components.PriorityCustomerClub.create();
          Components.PriorityCustomerInvoiceDetailsDialog.create();
          Components.SelfServicePluxee.SelfServicePluxeeSelectCompanyDialog.create();
          Components.PriorityCustomerReportDialog.create();
          Components.PositiveInput.create();
          Components.PositiveGenericDialog.create();
          Components.PrintReceiptDialog.create();
          Components.ZReportAllProgressDialog.create();
          Components.addonFilesUpdateDialog.create();
          Components.SittingOrTADialog.create();
          Components.SelfServiceKeypadModal.create();
          Components.OsherAdComponent.create();
          Components.selfService.LangSelect.create();
          Components.MultiCurr.ConfirmCashCreditPaymentDialog.create();
          Components.CompleteOrderWithPaymentDialog.create();
        }
    }
  }
}
