module PositiveTS {
	export module Printing {
        export module Invoice{
            export function printItemListBon(sale: PositiveTS.Storage.Entity.Sale, saleItems) {
				let aThis = printer
				let zebra = aThis.jzebra;

				let salejd;
				let customerName;

				if(sale.isDelivery) {
					salejd = JSON.parse(sale.jsondata);
					if (!posUtils.isBlank(salejd) && !posUtils.isBlank(salejd.delivery)) {
						customerName = salejd.delivery.ordererName				  
					}
				}
				let bonLineSpace = jsonConfig.getVal(jsonConfig.KEYS.bonLineSpace);
				let bonFontHuge = jsonConfig.getVal(jsonConfig.KEYS.bonFontHuge);

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);

				aThis.printLine(" ");
				aThis.printFloatingLine(i18next.t('printing.printingDateAndHour') + ":", posUtils.getDateStr(sale.createdAt), undefined);
				aThis.printLine(" ");

				aThis.printFloatingLine(`${i18next.t('printing.salespersonName')}:`, sale.cashierEmployeeName, undefined);
				aThis.printLine(` `);

				if(!posUtils.isBlank(customerName)){
					customerName = customerName.replace(',','');
					aThis.printFloatingLine(`${i18next.t('printing.customer')}:`, customerName, undefined);
					aThis.printLine(` `);
				}


				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.hugeFont);
				zebra.append(aThis.allowComponents.centerAlignment);

				let isTa = (saleItems.filter(item => item.itemCode == jsonConfig.getVal(jsonConfig.KEYS.taItemCode)).length > 0)
				let isSitting = (saleItems.filter(item => item.itemCode == jsonConfig.getVal(jsonConfig.KEYS.sittingItemCode)).length > 0)

				let grandCaptionString = i18next.t('printing.order') + ':', deliveryText = '';
				if (isTa) {
					deliveryText = i18next.t('printing.itemsAttachmentForTA');
				}
				else if (isSitting) {
					deliveryText = i18next.t('printing.itemsAttachmentForSitting');
				} else {
					deliveryText = i18next.t('printing.itemsAttachmentForDelivery');
				}

				aThis.printFloatingLine(`${deliveryText}`, '', undefined);
				aThis.printFloatingLine(`${grandCaptionString} ${sale.orderNumber}`, '', undefined);
				zebra.append(aThis.allowComponents.init);

				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				aThis.printHr();
				zebra.append(aThis.allowComponents.bigFont);

				aThis.printFloatingLine(i18next.t('printing.itemCode'), i18next.t('printing.quantity'), 21);
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				aThis.printHr();
				zebra.append(aThis.allowComponents.hugeFont);

				for (let saleItem of saleItems) {

					if (saleItem.itemCode == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiDiscountItemCode) ||
						saleItem.itemCode == jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode) ||
						saleItem.itemCode == jsonConfig.getVal(jsonConfig.KEYS.taItemCode)) {
						continue;
					}

					let itemDescription = saleItem.itemDescription;
					let nameOnBon = saleItem.item.nameOnBon
					if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
						itemDescription = nameOnBon
					}
					if (bonLineSpace) {
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
						zebra.append(aThis.allowComponents.hugeFont);
						zebra.append(aThis.allowComponents.hugeLineSpacing);
					}
					aThis.printFloatingLine(itemDescription, saleItem.actualQuantity.toString(), 21);
					if (!jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
						zebra.append(aThis.allowComponents.bigFont);
						zebra.append("\n");
						zebra.append(aThis.allowComponents.hugeFont);
						(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();
						aThis.printMultyLine(nameOnBon, 24)
					}

					if (saleItem.hasPreparationInstructions) {
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
						let prepInstArr = JSON.parse(saleItem.selectedPreparationInstructions)
						if (prepInstArr) {
							for (let prepInst of prepInstArr) {
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.rightAlignment);
								zebra.append(aThis.allowComponents.bigFont);
								(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();
								
								if (printer.isHTMLBasedPrinting()) {
									zebra.append(`>> ${prepInst}`);
								} else {
									aThis.printMultyLine(`>> ${prepInst}`, aThis.lineLengthBigFont);
								}
								
								zebra.append(`\n`);
							}
						}
					}
					if (saleItem.children && saleItem.children.length > 0) {
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
						for (let child of saleItem.children) {
							(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();
							if (bonFontHuge == true && !child.isPaidAddition) {
								zebra.append(aThis.allowComponents.hugeFont);
								zebra.append(aThis.allowComponents.hugeLineSpacing);
							} else {
								zebra.append(aThis.allowComponents.bigFont);
							}
							aThis.printFloatingLine(child.itemDescription, child.actualQuantity.toString(), 21);
							if (child.hasPreparationInstructions) {
								let prepInstArr = JSON.parse(child.selectedPreparationInstructions)
								if (prepInstArr) {
									for (let prepInst of prepInstArr) {
										zebra.append(aThis.allowComponents.rightAlignment);
										zebra.append(aThis.allowComponents.bigFont);
										let text = aThis.supportHebrew(prepInst);
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();
										
										if (printer.isHTMLBasedPrinting()) {
											zebra.append(`>> ${text}`);
										} else {
											aThis.printMultyLine(`>> ${prepInst}`, aThis.lineLengthBigFont);
										}

										zebra.append(`\n`);
										zebra.append(aThis.allowComponents.init);
										zebra.append(aThis.allowComponents.rightAlignment);
										zebra.append(aThis.allowComponents.bigFont);
									}
								}
							}
							if (child.children && child.children.length > 0) {
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.rightAlignment);
								for (let grandChild of child.children) {
									(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();
									zebra.append(aThis.allowComponents.bigFont);
									aThis.printFloatingLine(` ` + grandChild.itemDescription.substr(0, 17), grandChild.actualQuantity.toString(),
										21);
									if (grandChild.hasPreparationInstructions) {
										let prepInstArr = JSON.parse(grandChild.selectedPreparationInstructions)
										if (prepInstArr) {
											for (let prepInst of prepInstArr) {
												zebra.append(aThis.allowComponents.rightAlignment);
												zebra.append(aThis.allowComponents.bigFont);
												let text = aThis.supportHebrew(prepInst);
												(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();

												if (printer.isHTMLBasedPrinting()) {
													zebra.append(`>> ${text}`);
												} else {
													aThis.printMultyLine(`>> ${prepInst}`, aThis.lineLengthBigFont);
												}		
																						zebra.append(`\n`);
												zebra.append(aThis.allowComponents.init);
												zebra.append(aThis.allowComponents.rightAlignment);
												zebra.append(aThis.allowComponents.bigFont);
											}
										}
									}
								}
							}
						}
					}
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.hugeFont);
					zebra.append(aThis.allowComponents.rightAlignment);
					(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing) : _.noop();
				}

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				aThis.printHr();
				zebra.append(aThis.allowComponents.bigFont);

				aThis.printLine(` `);
				aThis.printLine(` `);

				if(sale.isDelivery && !posUtils.isBlank(salejd)) {
					if(!posUtils.isBlank(salejd.delivery)) {
						
						if (!posUtils.isBlank(salejd.delivery.deliveryTime)) {
							aThis.printFloatingLine(`${i18next.t('delivery.deliveryTimeTitle')}: ${salejd.delivery.deliveryTime}`, '', undefined);
							aThis.printLine(` `);
						}

						if (!posUtils.isBlank(salejd.delivery.ordererCallerRemarks)) {
							aThis.printMultyLine(salejd.delivery.ordererCallerRemarks, aThis.lineLengthBigFont);
							aThis.printLine(` `);
						}

						if (!posUtils.isBlank(salejd.delivery.ordererDeliveryRemarks)) {
							aThis.printMultyLine(salejd.delivery.ordererDeliveryRemarks, aThis.lineLengthBigFont);
							aThis.printLine(` `);
						}
						aThis.printLine(` `);
					}
				}

				printer.printLineCut();
				printer.print();

            }
            export function printCheck(objCheckRecord: PositiveTS.Service.Check.CheckRecord) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				var company_name = session.pos.checkCompanyName;
				var textAmnt = Service.HeNum.getCurrencyInHebrew(objCheckRecord.amount)

				aThis.printLine(company_name);
				aThis.printLine(objCheckRecord.date);
				aThis.printLine(objCheckRecord.amount.toString());
				aThis.printLine(textAmnt);


				zebra.append(aThis.allowComponents.init);
				zebra.print();
            }
            export async function printInvoice(sale, saleItems, salePayments, isSource, addiotionalText?):Promise<any> {
				
				if (isSource && jsonConfig.getVal(jsonConfig.KEYS.printWithPrimaryPos)) {
					app.hideLoadingMessage();
					let result = await app.promiseShowAlert({
						header: i18next.t(`message`),
						content: i18next.t(`shouldPrintInvoiceAtPrimaryPos`),
						continueButtonText: i18next.t(`ok`),
						yellowCancel: true
					});

					if (result != `continue`) {
						return;
					}
				}

				var hakafaPrinterType = 0
				var saleJsonData = JSON.parse(sale.jsondata);
				if (saleJsonData.customer && saleJsonData.customer.printer_type) {
					hakafaPrinterType = saleJsonData.customer.printer_type;
				}

				if (session.pos.printerType === `A4` || hakafaPrinterType === 1) {
					return await printInvoiceA4(sale, saleItems, salePayments, isSource, addiotionalText);
				}



				if (hakafaPrinterType === 2 || jsonConfig.getVal(jsonConfig.KEYS.askPrinterTypeForInvoice)) {
					app.hideLoadingMessage();
					let response = await app.promiseShowAlert({
						header: i18next.t(`shouldPrintA4Title`),
						content: i18next.t(`shouldPrintA4Msg`),
						continueButtonText: i18next.t(`a4Printer`),
						cancelButtonText: i18next.t(`posPrinter`),
						yellowCancel: true
					})
					
					if (response === `continue`) {
						return await printInvoiceA4(sale, saleItems, salePayments, isSource, addiotionalText);
					} else {
						return await printInvoiceRaw(sale, saleItems, salePayments, isSource, addiotionalText);
					}

				}
				return await printInvoiceRaw(sale, saleItems, salePayments, isSource, addiotionalText);


			}
			
			export function printMerakezInvoice(merakez_invoice) {
				let aThis = printer;
				let zebra = aThis.jzebra;
				let isPicturePrinter = aThis.isPicturesPrinter()
				const isSunmiPrinter = printer.isSunmi()
				let saleData = {
					storeAddress: merakez_invoice.store_address,
					storePhone: merakez_invoice.store_phone,
					storeRegistrationNum: merakez_invoice.chetpei,
					createdAt: merakez_invoice.created_at,
					salespersonEmployeeName: session.pos.employeeName
				}

				let companyData = {
					companyName: merakez_invoice.company_name
				}

				let merakezetLines = merakez_invoice.merakez_invoice_lines

				let classes = ['','lang-en pad-left','lang-en pad-left']

				zebra.append(printer.allowComponents.init);
				printSlipHeader(saleData, companyData)

				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment)
				printer.printLine(`${i18next.t("printing.merakezetInvoiceDate")} :  ${posUtils.getDateStr(merakez_invoice.invoice_date)}`);

				zebra.append(printer.allowComponents.newLine)
				zebra.append(printer.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				aThis.printLine(merakez_invoice.is_copy === false ? `*${i18next.t("printing.original")}*` : `*${i18next.t("printing.copy")}*`);
				aThis.printMultyLine(`${i18next.t("printing.merakezInvoiceNum")} ${merakez_invoice.invoice_number}`);

				zebra.append(printer.allowComponents.newLine)
				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment);
				aThis.printHr();
				aThis.printLine(`${i18next.t("printing.customer")}: ${merakez_invoice.dedicated_to_text}`)

				aThis.printLine(`${i18next.t("printing.hakafaCustomerBalance")}:  ` + session.fixedNumber(merakez_invoice.hakafa_customer.amount));
				aThis.printHr();

				zebra.append(aThis.allowComponents.init);

				let totalText = i18next.t('printing.totalForPayment');
				let widths = [printer.lineLength / 3.33, printer.lineLength / 2.5, printer.lineLength / 3.33]

				let firstCol = i18next.t('printing.documentNumber');
				let isRtl = printer.isHebrewText(firstCol)

				if (!isSunmiPrinter) {
					zebra.append(aThis.allowComponents.rightAlignment);
				}
				if (merakezetLines.length > 0) {
					zebra.append(printer.allowComponents.newLine);
					zebra.append(printer.allowComponents.init);
					
					if(isPicturePrinter){
						zebra.append(aThis.allowComponents.bold);
						aThis.printTableRow([firstCol,i18next.t('printing.saleDate'), totalText], widths)
					}else {
						if (aThis.isNarrowPrinting()) {
							aThis.printHeader(aThis.getTableRow([i18next.t('printing.documentNumber'), i18next.t('printing.saleDate'), totalText], getItemsNarrowWidthts()), isRtl);
						} else {
							aThis.printHeader(aThis.getTableRow([firstCol,i18next.t('printing.saleDate'), totalText], widths), isRtl);
						}
					}

					
					zebra.append(printer.allowComponents.init);
					if (!isSunmiPrinter) {
						zebra.append(aThis.allowComponents.rightAlignment);
					}
					for (const line of merakezetLines) {
						let dataRow = [String(line.sale.invoice_number), moment(line.sale.sold_at).format("DD/MM/YY HH:MM"), session.fixedNumber(line.amount)]

						if (isSunmiPrinter){
							if (isRtl) {
								aThis.printTableRow(dataRow, widths)
							} else {
								aThis.printTableEnglishRow(dataRow.reverse(), widths.reverse())
							}
						}else{
							if (isPicturePrinter) {
								aThis.printTableRow(dataRow, widths, classes)
							} else {
								aThis.printTableEnglishRow(dataRow.reverse(), widths.reverse())
							}
						}
					}

					aThis.printHr();
				}

				zebra.append(printer.allowComponents.newLine)
				zebra.append(printer.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bigFont);

				let totalForPaymentText = `${i18next.t("printing.totalForPayment")}:   ` + printer.fixNegSunmi(Base.numberWithCommas(session.fixedNumber(merakez_invoice.total_amount, 2, true)));

				if (totalForPaymentText.length < printer.lineLengthBigFont) {
					aThis.printMultyLine(totalForPaymentText, printer.lineLengthBigFont);
				}
				else {
					aThis.printLine(`${i18next.t("printing.totalForPayment")}:`);
					aThis.printLine(merakez_invoice.total_amount);
				}

				zebra.append(printer.allowComponents.newLine)
				zebra.append(printer.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);

				let totalOwsVat: any = merakez_invoice.current_vat ? merakez_invoice.total_vat / (merakez_invoice.current_vat / 100) : 0;
				totalOwsVat = session.fixedNumber(totalOwsVat)
				let totalAmountNonVat = merakez_invoice.total_amount - merakez_invoice.total_vat  - totalOwsVat;
				
				if (Math.round(totalAmountNonVat * 100) > 0) {
					aThis.printFloatingLineWithPrice(`${i18next.t("printing.totalFreeOfVat")}`, Base.numberWithCommas(session.fixedNumber(Math.abs(totalAmountNonVat), 2, true)))
				}

				aThis.printFloatingLineWithPrice(`${i18next.t("printing.totalOwsVat")}`, totalOwsVat);
				aThis.printFloatingLineWithPrice(`${i18next.t("printing.vat")} ${merakez_invoice.current_vat}%`, session.fixedNumber(merakez_invoice.total_vat))
			
				if (merakez_invoice.tax_confirmation_number) {
					let length = merakez_invoice.tax_confirmation_number.length;
					let shortConfirmationNumber = merakez_invoice.tax_confirmation_number.substring(length - 9, length);
					aThis.printHr();
					aThis.printLine(` `);
					aThis.printFloatingLine(i18next.t('printing.taxAuthorityConfirmationNumber') + ':', shortConfirmationNumber);
					aThis.printFloatingLine(merakez_invoice.tax_confirmation_number);
					aThis.printLine(` `);
				}


				printer.printLineCut();

				zebra.print();
			}

			export function printBankDeposit(bankDeposit) {
				let aThis = printer;
				let zebra = aThis.jzebra;
				let isPicturePrinter = aThis.isPicturesPrinter()
				let saleData = {
					storeAddress: session.store.address,
					storePhone: session.store.phone,
					storeRegistrationNum: session.store.registrationNum,
					createdAt: bankDeposit.depositTime,
					salespersonEmployeeName: bankDeposit.depositEmployee.name
				}

				let companyData = {
					companyName: session.pos.companyName
				}



				zebra.append(printer.allowComponents.init);
				printSlipHeader(saleData, companyData)

				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment)
				
				printer.printFloatingLine(`${i18next.t("printing.bankDeposit.employeeName")} :`,`${bankDeposit.depositEmployee.name}`);
				printer.printFloatingLine(`${i18next.t("printing.bankDeposit.bank")} :`,`${bankDeposit.bankName}`);
				printer.printFloatingLine(`${i18next.t("printing.bankDeposit.branchNumber")} :`,`${bankDeposit.branchNumber}`);
				printer.jzebra.append(printer.allowComponents.newLine);
				printer.printFloatingLine(`${i18next.t("printing.bankDeposit.totalCashZdsSelected")} :`,`${session.fixedNumber(bankDeposit.totalCashZdsSelected)}`);
				printer.printFloatingLine(`${i18next.t("printing.bankDeposit.totalCashDeclared")} :`,`${session.fixedNumber(bankDeposit.totalCashDeclared)}`);
				printer.printFloatingLine(`${i18next.t("printing.bankDeposit.cashDiff")} :`,`${Math.abs(Number(session.fixedNumber(bankDeposit.totalCashZdsSelected-bankDeposit.totalCashDeclared)))}`);
				printer.jzebra.append(printer.allowComponents.newLine);
				printer.printLine(`${i18next.t("printing.bankDeposit.comments")}:`);
				printer.printMultyLine(`${bankDeposit.comments}`);


				printer.jzebra.append(printer.allowComponents.newLine);
				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment)
				let zreportListTableSizes = [Math.floor((0.20*printer.lineLength)),Math.floor((0.20*printer.lineLength)),Math.floor((0.20*printer.lineLength)),Math.floor((0.20*printer.lineLength))];
				let regularSizes = [6,11,11,11];    
				if (printer.isPicturesPrinter()){
					regularSizes = [7,10,11,11]
				}
				printer.printHeader(printer.getTableRow([
					i18next.t("printing.bankDeposit.zNumber"),
					i18next.t("printing.bankDeposit.createdAt"),
					i18next.t("printing.bankDeposit.totalAmount"),
					i18next.t("printing.bankDeposit.totalZCashAmount")
				],regularSizes));

				bankDeposit.zReports.forEach((zReport) => {
					let row = [String(zReport.zNumber), moment(zReport.originalCreatedAt).format("DD/MM/YY"), String(zReport.totalAmount), String(zReport.totalZCashAmount)]
					if(session.pos.isRoshemet){
						printer.printTableRow(row, regularSizes);
					} else if(printer.isSunmi()) {
						printer.printTableRow(row, zreportListTableSizes);
					} else if(printer.isA4()) {
						printer.printTableRow(row, regularSizes);
					} else {
						if (printer.isPicturesPrinter()){
							printer.printTableRow(row, regularSizes);
						}else{
							printer.printTableEnglishRow(row.reverse(), regularSizes.slice().reverse());
						}
					}
				})

				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment);
				printer.jzebra.append(printer.allowComponents.newLine);
				printer.jzebra.append(printer.allowComponents.newLine);
				printer.jzebra.append(printer.allowComponents.newLine);
				printer.printLine(`${i18next.t("printing.signature")}: ______________________`);
				printer.jzebra.append(printer.allowComponents.newLine);
			
				printer.printLineCut();

				zebra.print();
			}

            export function printReplacementSlip(sale, saleGiftItems, company, isSource, itemQuantity?) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				//Reset the printer
				zebra.append(aThis.allowComponents.init);

				printSlipHeader(sale, company);

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);

				aThis.printLine(isSource === true ? `*${i18next.t("printing.original")}*` : `*${i18next.t("printing.copy")}*`);
				if (printer.isHTMLBasedPrinting()) {
					aThis.printLine(`${i18next.t("printing.returnNoteNo")}: ${sale.invoiceSequence}`);
				} else {
					if (printer.isSunmi()) {
						aThis.printText(`${i18next.t("printing.returnNoteNo")}: `);
						aThis.printLine(sale.invoiceSequence);
					} else {
						aThis.printText(sale.invoiceSequence);
						aThis.printLine(`${i18next.t("printing.returnNoteNo")}: `);
					}
				}


				aThis.printLine(` `);
				zebra.append(aThis.allowComponents.init);

				let widths = [7, 22, 7];
				if (session.pos.isRoshemet) {
					aThis.printHeader(aThis.getTableRow([i18next.t('printing.itemCode'), i18next.t('printing.name'), " "], widths));
				}
				else {
					printItemsHeader(false);
				}

				// print items
				for (var i = 0; i < saleGiftItems.length; i++) {
					printer.addRTLChar();
					printReplacementItemGeneric(saleGiftItems[i], itemQuantity, sale, widths);
				}

				aThis.printHr();

				zebra.append(aThis.allowComponents.rightAlignment);

				aThis.printLine(` `);

				var suffixLines = [];
				if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterDebitInvoiceSuffix)) {
					suffixLines = session.pos.parameterDebitInvoiceSuffix.split(`\n`);
				}

				for (var i = 0; i < suffixLines.length; i++) {
					aThis.printMultyLine(suffixLines[i]);
				};

				printer.printLineCut();

				zebra.append(aThis.allowComponents.init);
			}
            export function printItemsWithPrintSufix(company, sale, saleItems) {
				for (let i = 0; i < saleItems.length; i++) {
					if (saleItems[i].item.printSufix ||
						PositiveTS.Service.PunchCard.isPayPunchCardSale(sale)) {
						for (let z = 0; z < saleItems[i].quantity; z++) {
							printItemWithPrintSufix(company, sale, saleItems[i]);
						}
					}
				}
			}

			export async function printCashWithdrawalFailure(sale:any , atmId:string, terminalId:string, terminalTranId:string, error:string) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				var company = session.company;

				zebra.append(aThis.allowComponents.init);
				printSlipHeader(sale, company);
				aThis.printFloatingLine(i18next.t('withdrawal.terminalNo'), terminalId + ' ');
				aThis.printFloatingLine(i18next.t('withdrawal.tranNo'), terminalTranId + ' ');
				aThis.printLine(` `);
				aThis.printHr();
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				aThis.printLine(i18next.t('withdrawal.withdrawalError'));
				zebra.append(aThis.allowComponents.init);
				aThis.printHr();
				zebra.append(aThis.allowComponents.centerAlignment);
				aThis.printLine(error);
				zebra.append(aThis.allowComponents.init);
				aThis.printHr();
				zebra.append(aThis.allowComponents.centerAlignment)
				aThis.printLine(` `);
				printer.printLineCut();

				zebra.print();
			}

            export async function printCashWithdrawalInvoice(sale, saleItem, surcharge, terminalId, seqTxId, isRefund = false, withdrawal?, isSource = true) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				zebra.append(aThis.allowComponents.init);
				printer.openDrawer();

				
				await printWithdrawalBody(sale, saleItem, surcharge, terminalId, seqTxId, isRefund,withdrawal);
				await printWithdrawalInvoice(sale, isRefund, isSource);
				
				zebra.append(aThis.allowComponents.centerAlignment)
				aThis.printLine(` `);
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				aThis.printLine(i18next.t('withdrawal.customerCopy'));
				aThis.printLine(` `);
				printer.printLineCut();

				await printWithdrawalBody(sale, saleItem, surcharge, terminalId, seqTxId, isRefund,withdrawal);
				zebra.append(aThis.allowComponents.centerAlignment)
				aThis.printLine(i18next.t('withdrawal.signature'));
				aThis.printLine(` `);
				aThis.printLine(` `);
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				zebra.append(aThis.allowComponents.bigFont);
				let cashStr = isRefund ? i18next.t('withdrawal.cash') : i18next.t('withdrawal.cash');
				let cashReturnAmount = withdrawal;
				aThis.printLine(cashStr + ': ' + session.fixedNumber(cashReturnAmount));
				aThis.printLine('  ');
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				aThis.printLine(i18next.t('withdrawal.cashierCopy'));
				aThis.printLine(` `);
				printer.printLineCut();

				zebra.print();
			}

			export async function printWithdrawalBody(sale, saleItem, surcharge, terminalId, seqTxId, isRefund,withdrawal) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				let minusSign = isRefund ? '- ' : '';
				let surchargeAmount, withdrawalAmount;
				
				surchargeAmount = saleItem.unitPrice;
				
				withdrawalAmount = withdrawal;
				let totalPrice = withdrawalAmount + surchargeAmount;
				var company = session.company;

				zebra.append(aThis.allowComponents.init);
				printSlipHeader(sale, company);
				aThis.printFloatingLine(i18next.t('withdrawal.idNo'), seqTxId + ' ');
				aThis.printLine(` `);
				aThis.printHr();
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				let titleText = isRefund ? i18next.t('withdrawal.refundTitle') : i18next.t('withdrawal.withdrawalTitle');
				aThis.printLine(titleText);
				zebra.append(aThis.allowComponents.init);
				aThis.printHr();
				aThis.printFloatingLine(`${i18next.t('printing.withdrawalSum')}:`, minusSign + session.fixedNumber(withdrawalAmount));
				aThis.printFloatingLine(`${saleItem.itemDescription}:`, minusSign + session.fixedNumber(surchargeAmount));
				aThis.printHr();
				aThis.printFloatingLine(i18next.t('withdrawal.chargeTotal'), minusSign + session.fixedNumber(totalPrice));
				aThis.printLine(` `);

			}

			export async function printWithdrawalInvoice(sale, isRefund, isSource) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				let invoiceName;

				let calcuatedVat = PositiveTS.Helper.SaleHelper.calculateVat(sale.totalVatableAmount, sale.vat);

				aThis.printLine(` `);
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				let minusSign = "";
				if(isRefund) {
					invoiceName = i18next.t('withdrawal.creditInv')
					minusSign = "-"
				} else {
					invoiceName = i18next.t('withdrawal.debitInv')
				}

				aThis.printLine(`*${isSource ? i18next.t(`printing.original`) : i18next.t(`printing.copy`)}*`);
				aThis.printHr();
				aThis.printLine(`${invoiceName} ${sale.invoiceSequence}`);
				zebra.append(aThis.allowComponents.init);
				aThis.printHr();
				aThis.printFloatingLineWithPrice(`${i18next.t("printing.totalIncVat")}`, `${minusSign}${Base.numberWithCommas(session.fixedNumber(Math.abs(sale.totalVatableAmount), 2, true))}`);
				aThis.printLine(` `);
				aThis.printFloatingLineWithPrice(`${i18next.t("printing.totalOwsVat")}`, `${minusSign}${Base.numberWithCommas(session.fixedNumber(Math.abs(sale.totalVatableAmount) - Math.abs(calcuatedVat), 2, true))}`);
				aThis.printFloatingLineWithPrice(`${i18next.t("printing.vat")} ` + sale.vat + `%`, `${minusSign}${Base.numberWithCommas(session.fixedNumber(Math.abs(calcuatedVat), 2, true))}`);
				aThis.printHr();




			}
			export function printSlipHeader(sale, company) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				var storeName = session.store.storeName || session.pos.storeName;
				if (sale.storeName !== null && sale.storeName !== undefined && sale.storeName !== `undefined` && sale.storeName != `null` && sale.storeName != ``) {
					storeName = sale.storeName;
				}

				storeName = storeName.replace(`״`, `"`);

				var posNumber = session.pos.deviceID; // session.pos.posNumber;
				if (sale.posNumber !== null && sale.posNumber !== undefined && sale.posNumber !== `undefined` && sale.posNumber != `null` && sale.posNumber != ``) {
					posNumber = sale.posDeviceID; //sale.posNumber;
				}

				aThis.printLogo();

				//Reset the printer
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.bigFont);
				zebra.append(aThis.allowComponents.centerAlignment);

				var companyNameArray = company.companyName.split(`$$$`);

				for (var i = 0; i < companyNameArray.length; i++) {
					aThis.printMultyLine(companyNameArray[i], printer.lineLengthBigFont);
				};

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);

				aThis.printMultyLine(storeName);

				if (company != null) {
					// todo: change to store address and not company adress
					// aThis.printLine(company.street + ` ` +  company.buildingNo + ` ` + company.city);
					if (!posUtils.isNullOrUndefinedOrEmptyString(sale.storeAddress)) {
						aThis.printLine(sale.storeAddress);
					}

					let isRTL = aThis.isRTL
					if (isRTL && printer.isSunmi()) { isRTL = !isRTL }

					if (!posUtils.isNullOrUndefinedOrEmptyString(sale.storePhone)) {
						if (aThis.isHTMLBasedPrinting()) {
							aThis.printLine(i18next.t("printing.phone") + `: ` + sale.storePhone);
						} else {
							if (isRTL) {
								aThis.printText(sale.storePhone);

								aThis.printText(`\t`);
								aThis.printLine(`${i18next.t("printing.phone")}:`);
							}
							else {
								aThis.printText(`${i18next.t("printing.phone")}:`);
								if (printer.isSunmi() == false) {
									aThis.printText(`\t`);
								}
								aThis.printLine(sale.storePhone);
							}
						}
					}

					if (!posUtils.isNullOrUndefinedOrEmptyString(sale.storeRegistrationNum)) {
						if (aThis.isHTMLBasedPrinting()) {
							aThis.printLine(aThis.chetPeiAinMem() + `: ` + sale.storeRegistrationNum);
						} else {

							if (isRTL) {
								aThis.printText(sale.storeRegistrationNum);
								aThis.printText(`\t`);
								aThis.printLine(aThis.chetPeiAinMem() + `:`);
							}
							else {
								aThis.printText(aThis.chetPeiAinMem() + `:`);
								if (printer.isSunmi() == false) {
									aThis.printText(`\t`);
								}
								aThis.printLine(sale.storeRegistrationNum);
							}
						}
					}

					if (!posUtils.isNullOrUndefinedOrEmptyString(sale.storeFreeText)) {

						var freeTextArray = sale.storeFreeText.split(`$$$`);

						for (var i = 0; i < freeTextArray.length; i++) {
							aThis.printMultyLine(freeTextArray[i]);
						};
					}
				}

				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);

				let isSelfServiceSupermarketPos = jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos)
				let salePerson = isSelfServiceSupermarketPos ? i18next.t("selfServiceSupermarket.salePerson") : (sale.salespersonEmployeeName == null) ? sale.cashierEmployeeName : sale.salespersonEmployeeName;
				let dateToPrint;
				if (String(sale.createdAt).match(/[a-z]/i)) {	//Date contains letters = new date format
					dateToPrint = moment(new Date(sale.createdAt)).format("DD/MM/YYYY HH:mm");
				}
				else {
					dateToPrint = moment(sale.createdAt, "DD/MM/YYYYY HH:mm").format("DD/MM/YYYY HH:mm");
				}

				if (session.pos.hasFlights) {
					aThis.printTableRow([i18next.t('printing.POS') + ':', posNumber + ' '], [10, 10]);
					let saleLeg = sale.flightLegId ? Pinia.flightsStore.getFlightLegById(sale.flightLegId) : Pinia.flightsStore.currentLeg;
					Printing.FlightReports.printFlightSumLine(Pinia.flightsStore.currentFlight, saleLeg);
					Printing.FlightReports.printFlightUserSumLine({name: salePerson});
				} else {
					aThis.printFloatingLine(i18next.t('printing.date') + ':', dateToPrint);
					aThis.printFloatingLine(i18next.t('printing.POS') + ':', posNumber + ' ');

					if(posUtils.isPresent(sale.jsondata) && JSON.parse(sale.jsondata).delivery?.courierName) {
						aThis.printFloatingLine(i18next.t('printing.courier') + ':', JSON.parse(sale.jsondata).delivery?.courierName);
					}
				}

				if (sale.customerName != null && sale.customerName != "") {
					aThis.printLine(" ");
					aThis.printFloatingLine(`${i18next.t('printing.customer')}:`,  sale.customerName.replace(/[^A-Za-zא-ת0-9/_-\s]+/g, ``));
				}

				if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
					let dalpak = Service.Dalpak.getDalpakFromRailsId(sale.dalpakId);

					if (posUtils.isDefined(dalpak)) {
						aThis.printFloatingLine(i18next.t('printing.counter') + ':', dalpak.name);
					}
				}

				if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners) && posUtils.isDefined(sale.diners) && sale.diners > 0) {
					aThis.printFloatingLine(i18next.t('printing.dinersNumber') + ':', sale.diners.toString());
				}
				if(!jsonConfig.getVal(jsonConfig.KEYS.doNotPrintCahsierOnInvoice)){
					if(jsonConfig.getVal(jsonConfig.KEYS.printSalesPersonInBigFont)) {
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
						zebra.append(aThis.allowComponents.bigFont);
	
						let salesPersonlineLength = aThis.isPicturesPrinter() ? null : aThis.lineLengthBigFont
						aThis.printFloatingLine(i18next.t('printing.salesperson') + ':', salePerson, salesPersonlineLength);
					
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
					} else {
						aThis.printFloatingLine(i18next.t('printing.salesperson') + ':', salePerson);
					}
				}


				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.init);

				if (!posUtils.isNullOrUndefinedOrEmptyString(company) && !posUtils.isNullOrUndefinedOrEmptyString(company.webSite)) {
					zebra.append(aThis.allowComponents.centerAlignment);

					aThis.printLine(`כתובתנו באינטרנט:`);
					aThis.printLine(company.webSite);
					aThis.printLine(` `);
				}


			}

			// support only Picture Printer
			// if need old way need to develop it 
			export function printUpdateBonAfterMoveItemsDalpak (sale: PositiveTS.Storage.Entity.Sale, saleItems, printerBuffer, printerLogicalName = '', movedFromDalpakName: string, toDalpakName: string) {
				let aThis = printer
				let zebra = aThis.jzebra;
				let oldPrinterType = session.pos.printerType;
				let salejd = JSON.parse(sale.jsondata)
				let customerName = ''
				let customerPhone = ''

				let customerNameWithBigFont = jsonConfig.getVal(jsonConfig.KEYS.printBonsWithBigFontCustomerName)

				if (Storage.Entity.LogicalPrinter.isAndroidWifiPrinter(printerBuffer)){
					session.pos.printerType = jsonConfig.getVal(jsonConfig.KEYS.wifiPrinterType);
					printer.jzebra._setPrinterType()
				}

				for (let i = 0; i < jsonConfig.getVal(jsonConfig.KEYS.timesToPrintBon); i++){
					let bonLineSpace = jsonConfig.getVal(jsonConfig.KEYS.bonLineSpace);
					let bonFontHuge = jsonConfig.getVal(jsonConfig.KEYS.bonFontHuge);

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);


					if (!posUtils.isBlank(printerLogicalName)){
						aThis.printFloatingLine(`${i18next.t('printing.printer')}:`, printerLogicalName, undefined, printerBuffer)
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					let grandCaptionString = `${i18next.t('printing.orderNumber')}:`
					aThis.printFloatingLine(grandCaptionString, sale.orderNumber.toString(), undefined, printerBuffer);

					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					aThis.printFloatingLine(i18next.t('printing.printingDateAndHour') + ":", posUtils.getDateStr(sale.movedFromDalpak ? (new Date()).toString() : sale.createdAt), undefined, printerBuffer);
					zebra.append(aThis.allowComponents.newLine, printerBuffer)

					aThis.printFloatingLine(`${i18next.t('delivery.cashier')}:`, sale.cashierEmployeeName, undefined, printerBuffer);
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					
					if (!posUtils.isBlank(sale.salespersonEmployeeName)){
						aThis.printFloatingLine(`${i18next.t('printing.salesperson')}:`, sale.salespersonEmployeeName, undefined, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					if (jsonConfig.getVal(jsonConfig.KEYS.postName) != "") {
						aThis.printFloatingLine('עמדה:', jsonConfig.getVal(jsonConfig.KEYS.postName), undefined, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					let orderedName = '';

					if (sale.isDelivery && Service.Delivery.isExternalDeliveryFromJsonData(salejd)) {
						if (posUtils.isDefined(salejd.delivery.ordererName)) {
							orderedName = salejd.delivery.ordererName;
						}
					}

					if (!posUtils.isNullOrUndefinedOrEmptyString(orderedName)) {
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.bigFont, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						aThis.printMultyLine(`${i18next.t("printing.orderer")}: ` + orderedName, 24, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
					zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
					aThis.printTableRow([i18next.t("printing.moveItemsDalpak")], [aThis.lineLengthBigFont], ['text-4 center'], printerBuffer)
					
					if (posUtils.isDefined(movedFromDalpakName)) {
						aThis.printTableRow([`${i18next.t('printing.fromCounter')}: ${movedFromDalpakName}`], [aThis.lineLengthBigFont], ['text-3 center'], printerBuffer)
						aThis.printTableRow([`${i18next.t('printing.toCounter')}: ${toDalpakName}`], [aThis.lineLengthBigFont], ['text-3 center'], printerBuffer)
					}

					aThis.printTableRow([`${grandCaptionString} ${sale.orderNumber}`], [aThis.lineLengthBigFont], ['text-3 center'], printerBuffer)
					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);

					let tableItemsWidths = [8, 2]
	
					for (let saleItem of saleItems) {
						aThis.printHr(printerBuffer)
						let itemDescription = saleItem.itemDescription
						let nameOnBon = saleItem.item.nameOnBon
						if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
							itemDescription = nameOnBon
						}

						if (bonLineSpace) {
							zebra.append(aThis.allowComponents.init, printerBuffer);
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
							zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
							zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
						}

							zebra.append(aThis.allowComponents.init, printerBuffer)
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer)
							aThis.printTableRow([itemDescription, saleItem.actualQuantity.toString()], tableItemsWidths, ['text-2 white-space-reset', 'text-2 center'], printerBuffer)
							aThis.printHr(printerBuffer)
	
							if (!jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
								zebra.append(aThis.allowComponents.bigFont, printerBuffer);
								zebra.append(aThis.allowComponents.newLine, printerBuffer)
								zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
								(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
								aThis.printMultyLine(nameOnBon, 24, printerBuffer)
							}
	
							if (saleItem.hasPreparationInstructions) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								let prepInstArr = JSON.parse(saleItem.selectedPreparationInstructions)
								let widths = [6]
								let data = []
								let classes =  ['right pad-right x5 text-1 white-space-reset']
								
								if (prepInstArr) {
									for (let prepInst of prepInstArr) {
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
										data = [prepInst]
										aThis.printTableRow(data, widths, classes, printerBuffer)
									}
	
									zebra.append(aThis.allowComponents.init, printerBuffer)
								}
							}

						if (saleItem.children && saleItem.children.length > 0) {
							zebra.append(aThis.allowComponents.init, printerBuffer);
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
							let classes =  ['right pad-right x5 text-1 white-space-reset']

							for (let child of saleItem.children) {
								let itemDescription = child.itemDescription
								let nameOnBon = child.item.nameOnBon
								if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
									itemDescription = nameOnBon
								}

								(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
								if (bonFontHuge == true && !child.isPaidAddition) {
									zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
									zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
								}

								aThis.printTableRow([itemDescription, child.actualQuantity.toString()], tableItemsWidths, classes, printerBuffer)

								if (child.hasPreparationInstructions) {
									let prepInstArr = JSON.parse(child.selectedPreparationInstructions)
									if (prepInstArr) {
										for (let prepInst of prepInstArr) {
											zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
											zebra.append(aThis.allowComponents.bigFont, printerBuffer);
											(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
											zebra.append(`	${prepInst}`, printerBuffer);
											zebra.append(aThis.allowComponents.newLine, printerBuffer)
											zebra.append(aThis.allowComponents.init, printerBuffer);
											zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
											zebra.append(aThis.allowComponents.bigFont, printerBuffer);
										}
									}
								}

								if (child.children && child.children.length > 0) {
									zebra.append(aThis.allowComponents.init, printerBuffer);
									zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
									for (let grandChild of child.children) {
										let itemDescription = grandChild.itemDescription
										let nameOnBon = grandChild.item.nameOnBon
										if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
											itemDescription = nameOnBon
										}
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
										zebra.append(aThis.allowComponents.bigFont, printerBuffer);
										aThis.printFloatingLine(` ` + itemDescription, grandChild.actualQuantity.toString(),
											21, printerBuffer);
										if (grandChild.hasPreparationInstructions) {
											let prepInstArr = JSON.parse(grandChild.selectedPreparationInstructions)
											if (prepInstArr) {
												for (let prepInst of prepInstArr) {
													zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
													zebra.append(aThis.allowComponents.bigFont, printerBuffer);
													(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
													zebra.append(`	${prepInst}`, printerBuffer);
													zebra.append(aThis.allowComponents.newLine, printerBuffer)
													zebra.append(aThis.allowComponents.init, printerBuffer);
													zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
													zebra.append(aThis.allowComponents.bigFont, printerBuffer);
												}
											}
										}
									}
								}
							}
						}
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
						zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
						(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
						
					}

					zebra.append(aThis.allowComponents.init, printerBuffer)
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer)
					zebra.append(aThis.allowComponents.bigFont, printerBuffer)

					if (posUtils.isBlank(customerName) && salejd && !posUtils.isBlank(salejd.dedicatedTo)){
						customerName = salejd.dedicatedTo
					}

					if (posUtils.isBlank(customerName) && !posUtils.isBlank(sale.customerName)){
						customerName = sale.customerName
					}

					if (posUtils.isBlank(customerPhone) && !posUtils.isBlank(sale.customerPhone)){
						customerPhone = sale.customerPhone
					}

					if (posUtils.isBlank(customerName) && salejd && salejd.customer){
						customerName = `${salejd.customer.s_first_name ? salejd.customer.s_first_name : ''}${salejd.customer.s_last_name ? ` ${salejd.customer.s_last_name}` : ''}`
					}
					
					if (posUtils.isBlank(customerPhone) && salejd && salejd.customer && !posUtils.isBlank(salejd.customer.s_phone_number_1)){
						customerPhone = salejd.customer.s_phone_number_1
					}

					if (!posUtils.isBlank(customerName)) {
						aThis.printHr(printerBuffer)
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
						zebra.append(aThis.allowComponents.init, printerBuffer);

						if(customerNameWithBigFont) {
							zebra.append(aThis.allowComponents.bigAndBoldFont, printerBuffer);
						}

						let customerPhoneStr = ''
						if (!posUtils.isBlank(customerPhone)) {
							customerPhoneStr = `${i18next.t("printing.phone")}: ${customerPhone}`
						}
						aThis.printFloatingLine(`${i18next.t("printing.customer")}: ${customerName.replace(/[^A-Za-zא-ת0-9_-\s]+/g, ``)}`, customerPhoneStr, undefined, printerBuffer)
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
						zebra.append(aThis.allowComponents.init, printerBuffer);
					}

					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					printer.printLineCut(printerBuffer)
					printer.jzebra.print(printerBuffer)
				}

				if (Storage.Entity.LogicalPrinter.isAndroidWifiPrinter(printerBuffer)){
					session.pos.printerType = oldPrinterType
					printer.jzebra._setPrinterType()
				}
			}

			export function printBon(sale: PositiveTS.Storage.Entity.Sale, saleItems, printerBuffer, isOrderCancel = false, isReprint = false, printerLogicalName = '', isUpdate = false) {
				if (printer.isPicturesPrinter(printerBuffer)){
					printPictureBon(sale, saleItems, printerBuffer, isOrderCancel, isReprint, printerLogicalName, isUpdate)
					return
				}
				let aThis = printer
				let zebra = aThis.jzebra;
				let oldPrinterType = session.pos.printerType;
				let salejd = null
				let customerNameWithBigFont = jsonConfig.getVal(jsonConfig.KEYS.printBonsWithBigFontCustomerName)

				if (sale.isDelivery) {
					salejd = JSON.parse(sale.jsondata);
				}


				if(Storage.Entity.LogicalPrinter.isAndroidWifiPrinter(printerBuffer)){
					session.pos.printerType = jsonConfig.getVal(jsonConfig.KEYS.wifiPrinterType);
					printer.jzebra._setPrinterType()
				}

				for (let i = 0; i < jsonConfig.getVal(jsonConfig.KEYS.timesToPrintBon); i++) {
					let bonLineSpace = jsonConfig.getVal(jsonConfig.KEYS.bonLineSpace);
					let bonFontHuge = jsonConfig.getVal(jsonConfig.KEYS.bonFontHuge);

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);

					if (sale.invoiceSequence > 0) {
						aThis.printFloatingLine(`הזמנה לחשבונית מספר:`, getSaleNumberFormated(sale).toString(), undefined, printerBuffer);
					}
					else {
						let grandCaptionString = i18next.t('printing.numberOrder') + ":"
						if (isOrderCancel) {
							grandCaptionString = i18next.t('printing.cancelOrderNumber') + ":"
						}
						aThis.printFloatingLine(grandCaptionString, sale.orderNumber.toString(), undefined, printerBuffer);
					}
					aThis.printLine(" ", printerBuffer);
					aThis.printFloatingLine(i18next.t('printing.printingDateAndHour') + ":", posUtils.getDateStr(sale.movedFromDalpak ? (new Date()).toString() : sale.createdAt), undefined, printerBuffer);
					aThis.printLine(" ", printerBuffer);

					aThis.printFloatingLine(`${i18next.t('delivery.cashier')}:`, sale.cashierEmployeeName, undefined, printerBuffer);
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					
					if (!posUtils.isBlank(sale.salespersonEmployeeName)){
						aThis.printFloatingLine(`${i18next.t('printing.salesperson')}:`, sale.salespersonEmployeeName, undefined, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}
					
					if (sale.customerName != null && sale.customerName != ``) {
						zebra.append(aThis.allowComponents.init, printerBuffer);
						if(customerNameWithBigFont) {
							zebra.append(aThis.allowComponents.bigFont, printerBuffer);
							zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						} else {
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
						}

						let lineLength = customerNameWithBigFont ? printer.lineLengthBigFont : printer.lineLength
						aThis.printMultyLine(`${i18next.t("printing.customer")}: ${sale.customerName.replace(/[^A-Za-zא-ת0-9_-\s]+/g, ``)}`, lineLength, printerBuffer);
						zebra.append(aThis.allowComponents.init, printerBuffer);
						aThis.printLine(` `, printerBuffer);
					}

					if (sale.customerPhone != null && sale.customerPhone != ``) {
						aThis.printFloatingLine(`${i18next.t("printing.phone")}:`, sale.customerPhone, undefined, printerBuffer);
						aThis.printLine(` `, printerBuffer);
					}

					if (jsonConfig.getVal(jsonConfig.KEYS.postName) != "") {
						aThis.printFloatingLine('עמדה:', jsonConfig.getVal(jsonConfig.KEYS.postName), undefined, printerBuffer);
						aThis.printLine(" ", printerBuffer);
					}

					if (Service.SittingTA.isTASale() && Service.SittingTA.isEnabled()) {
						aThis.printMultyLine(`***TA***`,  aThis.lineLengthBigFont);
					}

					let orderedName = '';

					if (sale.isDelivery && Service.Delivery.isExternalDeliveryFromJsonData(salejd)) {
						if (posUtils.isDefined(salejd.delivery.ordererName)) {
							orderedName = salejd.delivery.ordererName;
						}
					}

					if (!posUtils.isNullOrUndefinedOrEmptyString(orderedName)) {
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.bigFont, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						aThis.printMultyLine(`${i18next.t("printing.orderer")}: ` + orderedName, 24, printerBuffer);
						aThis.printLine(` `, printerBuffer);
					}

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
					zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);

					let grandCaptionString = i18next.t('printing.order') + ':'
					if (isOrderCancel) {
						grandCaptionString = i18next.t('printing.orderCancel') + ':';
					}

					if (isReprint && !sale.movedFromDalpak) {
						aThis.printFloatingLine(`הדפסה חוזרת`, '', undefined, printerBuffer);
					}

					if (sale.movedFromDalpak) {
						if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
							aThis.printFloatingLine(i18next.t("printing.dalpakMovement_new_rest"), '', undefined, printerBuffer);
						}else{
							aThis.printFloatingLine(i18next.t("printing.dalpakMovement"), '', undefined, printerBuffer);
						}
					}

					let deliveryText = "";
					if (sale.isDelivery) {
						let translatedText = Service.Delivery.getDeliveryTypeText(salejd.delivery.deliveryType);

						if (posUtils.isBlank(translatedText)) {
						deliveryText = "***משלוח***";


						if (Service.Delivery.isTaOrder(salejd.delivery.deliveryType)) {
							deliveryText = "***TA***"
						}
						if (salejd.delivery.deliveryType == Service.Delivery.DeliveryType.externalPaiditSitting) {
							deliveryText = "***SITTING***"
						}
						} else {
							deliveryText = `***${translatedText}***`
					}


						aThis.printMultyLine(`${deliveryText}`,  aThis.lineLengthBigFont, printerBuffer);
					}

					if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && sale.dalpakId) {
						let dalpak = null;

						if (Pinia.dalpaksStore.allDalpaks) {
							dalpak = Pinia.dalpaksStore.allDalpaks.filter(d => d.railsId == sale.dalpakId)[0];
						}

						if (posUtils.isDefined(dalpak)) {
							if (sale.movedFromDalpak) {
								if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
									aThis.printFloatingLine(`${i18next.t('printing.fromCounter_new_rest')}: ${sale.movedFromDalpak}`, '', undefined, printerBuffer);
									aThis.printFloatingLine(`${i18next.t('printing.toCounter_new_rest')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}else{
									aThis.printFloatingLine(`${i18next.t('printing.fromCounter')}: ${sale.movedFromDalpak}`, '', undefined, printerBuffer);
									aThis.printFloatingLine(`${i18next.t('printing.toCounter')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}
							} else {
								if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
									aThis.printFloatingLine(`${i18next.t('printing.counter_new_rest')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}else{
									aThis.printFloatingLine(`${i18next.t('printing.counter')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}
							}
							if(!jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
							}
						}
					}
					if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						zebra.append(aThis.allowComponents.superHugeFont, printerBuffer);
						aThis.printFloatingLine(`${grandCaptionString} ${sale.orderNumber}`, '', undefined, printerBuffer);
						aThis.printLine(` `, printerBuffer);
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
					}else{
						aThis.printFloatingLine(`${grandCaptionString} ${sale.orderNumber}`, '', undefined, printerBuffer);
					}
					
					zebra.append(aThis.allowComponents.init, printerBuffer);

					aThis.printLine(` `, printerBuffer);

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
					aThis.printHr(printerBuffer);
					zebra.append(aThis.allowComponents.bigFont, printerBuffer);


					if (!sale.movedFromDalpak) {

						aThis.printFloatingLine(i18next.t('printing.itemCode'),i18next.t('printing.quantity'), 21, printerBuffer);
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
						aThis.printHr(printerBuffer);
						zebra.append(aThis.allowComponents.hugeFont, printerBuffer);

						for (let saleItem of saleItems) {
							let itemDescription = saleItem.itemDescription.substr(0, 17)
							let nameOnBon = saleItem.item.nameOnBon
							if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
								itemDescription = nameOnBon
							}
							if (bonLineSpace) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
								zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
								zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
							}
							aThis.printFloatingLine(itemDescription, saleItem.actualQuantity.toString(), 21, printerBuffer);
							if (!jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
								zebra.append(aThis.allowComponents.bigFont, printerBuffer);
								zebra.append("\n", printerBuffer);
								zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
								(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
								aThis.printMultyLine(nameOnBon, 24, printerBuffer)
							}

							//let origItem = posVC.saleItems.filter(item => item.rowNumber == saleItem.rowNumber)[0]
							if (saleItem.hasPreparationInstructions) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
								let prepInstArr = JSON.parse(saleItem.selectedPreparationInstructions)
								if (prepInstArr) {
									for (let prepInst of prepInstArr) {
										zebra.append(aThis.allowComponents.init, printerBuffer);
										zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
										zebra.append(aThis.allowComponents.bigFont, printerBuffer);
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();

										if (printer.isHTMLBasedPrinting(printerBuffer)) {
											zebra.append(`>> ${prepInst}`, printerBuffer);
										} else {
											aThis.printMultyLine(`>> ${prepInst}`, aThis.lineLengthBigFont,printerBuffer);
										}

										zebra.append(`\n`, printerBuffer);
									}
								}
							}
							if (saleItem.children && saleItem.children.length > 0) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
								for (let child of saleItem.children) {
									let itemDescription = child.itemDescription.substr(0, 17)
									let nameOnBon = child.item.nameOnBon
									if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
										itemDescription = nameOnBon
									}

									(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
									if (bonFontHuge == true && !child.isPaidAddition) {
										zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
										zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
									} else {
										zebra.append(aThis.allowComponents.bigFont, printerBuffer);
									}

									aThis.printFloatingLine(itemDescription, child.actualQuantity.toString(),
										21, printerBuffer);
									if (child.hasPreparationInstructions) {
										let prepInstArr = JSON.parse(child.selectedPreparationInstructions)
										if (prepInstArr) {
											for (let prepInst of prepInstArr) {
												zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
												zebra.append(aThis.allowComponents.bigFont, printerBuffer);
												(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();

												if (printer.isHTMLBasedPrinting(printerBuffer)) {
													zebra.append(`>> ${prepInst}`, printerBuffer);
												} else {
													aThis.printMultyLine(`>> ${prepInst}`, aThis.lineLengthBigFont,printerBuffer);
												}

												zebra.append(`\n`, printerBuffer);
												zebra.append(aThis.allowComponents.init, printerBuffer);
												zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
												zebra.append(aThis.allowComponents.bigFont, printerBuffer);
											}
										}
									}
									if (child.children && child.children.length > 0) {
										zebra.append(aThis.allowComponents.init, printerBuffer);
										zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
										for (let grandChild of child.children) {
											let itemDescription = grandChild.itemDescription.substr(0, 17)
											let nameOnBon = grandChild.item.nameOnBon
											if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
												itemDescription = nameOnBon
											}
											(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
											zebra.append(aThis.allowComponents.bigFont, printerBuffer);
											aThis.printFloatingLine(` ` + itemDescription, grandChild.actualQuantity.toString(),
												21, printerBuffer);
											if (grandChild.hasPreparationInstructions) {
												let prepInstArr = JSON.parse(grandChild.selectedPreparationInstructions)
												if (prepInstArr) {
													for (let prepInst of prepInstArr) {
														zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
														zebra.append(aThis.allowComponents.bigFont, printerBuffer);
														(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();

														if (printer.isHTMLBasedPrinting(printerBuffer)) {
															zebra.append(`>> ${prepInst}`, printerBuffer);
														} else {
															aThis.printMultyLine(`>> ${prepInst}`, aThis.lineLengthBigFont,printerBuffer);
														}

														zebra.append(`\n`, printerBuffer);
														zebra.append(aThis.allowComponents.init, printerBuffer);
														zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
														zebra.append(aThis.allowComponents.bigFont, printerBuffer);
													}
												}
											}
										}
									}
								}
							}
							zebra.append(aThis.allowComponents.init, printerBuffer);
							zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
							(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
						}
					}

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
					aThis.printHr(printerBuffer);
					zebra.append(aThis.allowComponents.bigFont, printerBuffer);

					aThis.printLine(` `, printerBuffer);
					aThis.printLine(` `, printerBuffer);

					if(sale.isDelivery) {
						let salejd = JSON.parse(sale.jsondata);
						if(!posUtils.isBlank(salejd.delivery)) {
							
							if (!posUtils.isBlank(salejd.delivery.deliveryTime)) {
								aThis.printFloatingLine(`${i18next.t('delivery.deliveryTimeTitle')}: ${salejd.delivery.deliveryTime}`, '', undefined, printerBuffer);
								aThis.printLine(` `, printerBuffer);
							}

							let ordererCallerRemarks = ""; 

							if (!posUtils.isBlank(salejd.delivery.ordererCallerRemarks)) {
								aThis.printMultyLine(salejd.delivery.ordererCallerRemarks, printer.lineLengthBigFont, printerBuffer);
								aThis.printLine(` `, printerBuffer);
								ordererCallerRemarks = salejd.delivery.ordererCallerRemarks;
							}

							if (!posUtils.isBlank(salejd.delivery.ordererDeliveryRemarks) && ordererCallerRemarks != salejd.delivery.ordererDeliveryRemarks) {
								aThis.printMultyLine(salejd.delivery.ordererDeliveryRemarks, printer.lineLengthBigFont, printerBuffer);
								aThis.printLine(` `, printerBuffer);
							}
							aThis.printLine(` `, printerBuffer);
						}
					}

					printer.printLineCut(printerBuffer);

			}

				if(Storage.Entity.LogicalPrinter.isAndroidWifiPrinter(printerBuffer)){
					session.pos.printerType = oldPrinterType
					printer.jzebra._setPrinterType()
				}

			}

			export function printPictureBon (sale: PositiveTS.Storage.Entity.Sale, saleItems, printerBuffer, isOrderCancel = false, isReprint = false, printerLogicalName = '', isUpdateBon = false) {
				let aThis = printer
				let zebra = aThis.jzebra;
				let oldPrinterType = session.pos.printerType;
				let salejd = JSON.parse(sale.jsondata)
				let customerName = ''
				let customerPhone = ''
				isUpdateBon = isUpdateBon && !isOrderCancel
				isReprint = isReprint && !isUpdateBon

				let customerNameWithBigFont = jsonConfig.getVal(jsonConfig.KEYS.printBonsWithBigFontCustomerName)

				if (Storage.Entity.LogicalPrinter.isAndroidWifiPrinter(printerBuffer)){
					session.pos.printerType = jsonConfig.getVal(jsonConfig.KEYS.wifiPrinterType);
					printer.jzebra._setPrinterType()
				}

				for (let i = 0; i < jsonConfig.getVal(jsonConfig.KEYS.timesToPrintBon); i++){
					let bonLineSpace = jsonConfig.getVal(jsonConfig.KEYS.bonLineSpace);
					let bonFontHuge = jsonConfig.getVal(jsonConfig.KEYS.bonFontHuge);

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);


					if (!posUtils.isBlank(printerLogicalName)){
						aThis.printFloatingLine(`${i18next.t('printing.printer')}:`, printerLogicalName, undefined, printerBuffer)
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					if (sale.invoiceSequence > 0) {
						aThis.printFloatingLine(`הזמנה לחשבונית מספר:`, getSaleNumberFormated(sale).toString(), undefined, printerBuffer);
					}else {
						let grandCaptionString = i18next.t('printing.numberOrder') + ":"
						if (isOrderCancel) {
							grandCaptionString = i18next.t('printing.cancelOrderNumber') + ":"
						}
						if(isUpdateBon) {
							grandCaptionString = i18next.t('printing.updateOrderNumber') + ":"
						}
						aThis.printFloatingLine(grandCaptionString, sale.orderNumber.toString(), undefined, printerBuffer);
					}

					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					aThis.printFloatingLine(i18next.t('printing.printingDateAndHour') + ":", posUtils.getDateStr(sale.movedFromDalpak ? (new Date()).toString() : sale.createdAt), undefined, printerBuffer);
					zebra.append(aThis.allowComponents.newLine, printerBuffer)

					aThis.printFloatingLine(`${i18next.t('delivery.cashier')}:`, sale.cashierEmployeeName, undefined, printerBuffer);
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					
					if (!posUtils.isBlank(sale.salespersonEmployeeName)){
						aThis.printFloatingLine(`${i18next.t('printing.salesperson')}:`, sale.salespersonEmployeeName, undefined, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					if (jsonConfig.getVal(jsonConfig.KEYS.postName) != "") {
						aThis.printFloatingLine('עמדה:', jsonConfig.getVal(jsonConfig.KEYS.postName), undefined, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					let orderedName = '';

					if (sale.isDelivery && Service.Delivery.isExternalDeliveryFromJsonData(salejd)) {
						if (posUtils.isDefined(salejd.delivery.ordererName)) {
							orderedName = salejd.delivery.ordererName;
						}
					}

					if (!posUtils.isNullOrUndefinedOrEmptyString(orderedName)) {
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.bigFont, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						aThis.printMultyLine(`${i18next.t("printing.orderer")}: ` + orderedName, 24, printerBuffer);
						zebra.append(aThis.allowComponents.newLine, printerBuffer)
					}

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
					zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);

					let grandCaptionString = i18next.t('printing.order') + ':'
					if (isOrderCancel) {
						grandCaptionString = i18next.t('printing.orderCancel') + ':';
					}

					if (isReprint && !sale.movedFromDalpak) {
						aThis.printFloatingLine(`הדפסה חוזרת`, '', undefined, printerBuffer);
					}

					if (sale.movedFromDalpak) {
						if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
							aThis.printFloatingLine(i18next.t("printing.dalpakMovement_new_rest"), '', undefined, printerBuffer);
						}else{
							aThis.printFloatingLine(i18next.t("printing.dalpakMovement"), '', undefined, printerBuffer);
						}
					}

					if (Service.SittingTA.isTASale() && Service.SittingTA.isEnabled()) {
						aThis.printMultyLine(`***TA***`,  aThis.lineLengthBigFont, printerBuffer);
					}


					let deliveryText = "";
					if (sale.isDelivery) {
						let translatedText = Service.Delivery.getDeliveryTypeText(salejd.delivery.deliveryType);

						if (posUtils.isBlank(translatedText)) {
						deliveryText = "***משלוח***";


						if (Service.Delivery.isTaOrder(salejd.delivery.deliveryType)) {
							deliveryText = "***TA***"
						}
						if (salejd.delivery.deliveryType == Service.Delivery.DeliveryType.externalPaiditSitting) {
							deliveryText = "***SITTING***"
						}
						} else {
							deliveryText = `***${translatedText}***`
					}


						aThis.printMultyLine(`${deliveryText}`,  aThis.lineLengthBigFont, printerBuffer);
					}

					if(isUpdateBon) {
						aThis.printTableRow(['***עדכון הזמנה***'], [aThis.lineLengthBigFont], ['text-1 center'], printerBuffer)
					}

					if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && sale.dalpakId) {
						let dalpak = null;

						if (Pinia.dalpaksStore.allDalpaks) {
							dalpak = Pinia.dalpaksStore.allDalpaks.filter(d => d.railsId == sale.dalpakId)[0];
						}

						if (posUtils.isDefined(dalpak)) {
							if (sale.movedFromDalpak) {
								if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
									aThis.printFloatingLine(`${i18next.t('printing.fromCounter_new_rest')}: ${sale.movedFromDalpak}`, '', undefined, printerBuffer);
									aThis.printFloatingLine(`${i18next.t('printing.toCounter_new_rest')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}else{
								aThis.printFloatingLine(`${i18next.t('printing.fromCounter')}: ${sale.movedFromDalpak}`, '', undefined, printerBuffer);
								aThis.printFloatingLine(`${i18next.t('printing.toCounter')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}

							} else {
								if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
									aThis.printFloatingLine(`${i18next.t('printing.counter_new_rest')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}else{
								aThis.printFloatingLine(`${i18next.t('printing.counter')}: ${dalpak.name}`, '', undefined, printerBuffer);
								}
							}
							if(!jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
							}
						}
					} 

					if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						zebra.append(aThis.allowComponents.superHugeFont, printerBuffer);
						aThis.printFloatingLine(`${grandCaptionString} ${sale.orderNumber}`, '', undefined, printerBuffer);
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
					}else{
						aThis.printFloatingLine(`${grandCaptionString} ${sale.orderNumber}`, '', undefined, printerBuffer);
					}
					
					if(isUpdateBon && jsonConfig.getVal(jsonConfig.KEYS.printUpdateBonsWithForItemInfo)) {						
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.hugeFont, printerBuffer);						
						zebra.append(aThis.allowComponents.centerAlignment, printerBuffer);
						aThis.printFloatingLine(`**שינוי למנה: ${saleItems[0].itemDescription}**`, '', undefined, printerBuffer);
					}

					zebra.append(aThis.allowComponents.init, printerBuffer);

					zebra.append(aThis.allowComponents.newLine, printerBuffer)

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);

					let tableItemsWidths = [8, 2]

					if (!sale.movedFromDalpak) {
						if(isUpdateBon) {
							printUpdateItemsBon(saleItems, printerBuffer)
						} 
						else {
	
							for (let saleItem of saleItems) {
								aThis.printHr(printerBuffer)
								let itemDescription = saleItem.itemDescription
								let nameOnBon = saleItem.item.nameOnBon
								if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
									itemDescription = nameOnBon
								}
		
								if (bonLineSpace) {
									zebra.append(aThis.allowComponents.init, printerBuffer);
									zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
									zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
									zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
								}
		
									zebra.append(aThis.allowComponents.init, printerBuffer)
									zebra.append(aThis.allowComponents.rightAlignment, printerBuffer)
									aThis.printTableRow([itemDescription, saleItem.actualQuantity.toString()], tableItemsWidths, ['text-4 white-space-reset', 'text-4 center'], printerBuffer)
									aThis.printHr(printerBuffer)
			
									if (!jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
										zebra.append(aThis.allowComponents.init, printerBuffer);
										zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
										zebra.append(aThis.allowComponents.bigFont, printerBuffer);
										zebra.append(aThis.allowComponents.newLine, printerBuffer)
										zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
										aThis.printMultyLine(nameOnBon, 24, printerBuffer)
									}
			
									if (saleItem.hasPreparationInstructions) {
										zebra.append(aThis.allowComponents.init, printerBuffer);
										let prepInstArr = JSON.parse(saleItem.selectedPreparationInstructions)
										let widths = [6]
										let data = []
										let classes =  ['right pad-right x5 text-3 white-space-reset']
										
										if (prepInstArr) {
											for (let prepInst of prepInstArr) {
												(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
												data = [prepInst]
												aThis.printTableRow(data, widths, classes, printerBuffer)
											}
			
											zebra.append(aThis.allowComponents.init, printerBuffer)
										}
									}
		
								if (saleItem.children && saleItem.children.length > 0) {
									zebra.append(aThis.allowComponents.init, printerBuffer);
									zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
									let classes =  ['right pad-right x5 text-3 white-space-reset']
		
									for (let child of saleItem.children) {
										let itemDescription = child.itemDescription
										let nameOnBon = child.item.nameOnBon
										if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
											itemDescription = nameOnBon
										}
		
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
										if (bonFontHuge == true && !child.isPaidAddition) {
											zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
											zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
										}
		
										aThis.printTableRow([itemDescription, child.actualQuantity.toString()], tableItemsWidths, classes, printerBuffer)
		
										if (child.hasPreparationInstructions) {
											let prepInstArr = JSON.parse(child.selectedPreparationInstructions)
											if (prepInstArr) {
												for (let prepInst of prepInstArr) {
													zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
													zebra.append(aThis.allowComponents.bigFont, printerBuffer);
													(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
													zebra.append(`	${prepInst}`, printerBuffer);
													zebra.append(aThis.allowComponents.newLine, printerBuffer)
													zebra.append(aThis.allowComponents.init, printerBuffer);
													zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
													zebra.append(aThis.allowComponents.bigFont, printerBuffer);
												}
											}
										}
		
										if (child.children && child.children.length > 0) {
											zebra.append(aThis.allowComponents.init, printerBuffer);
											zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
											for (let grandChild of child.children) {
												let itemDescription = grandChild.itemDescription
												let nameOnBon = grandChild.item.nameOnBon
												if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
													itemDescription = nameOnBon
												}
												(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
												zebra.append(aThis.allowComponents.bigFont, printerBuffer);
												aThis.printFloatingLine(` ` + itemDescription, grandChild.actualQuantity.toString(),
													21, printerBuffer);
												if (grandChild.hasPreparationInstructions) {
													let prepInstArr = JSON.parse(grandChild.selectedPreparationInstructions)
													if (prepInstArr) {
														for (let prepInst of prepInstArr) {
															zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
															zebra.append(aThis.allowComponents.bigFont, printerBuffer);
															(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
															zebra.append(`	${prepInst}`, printerBuffer);
															zebra.append(aThis.allowComponents.newLine, printerBuffer)
															zebra.append(aThis.allowComponents.init, printerBuffer);
															zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
															zebra.append(aThis.allowComponents.bigFont, printerBuffer);
														}
													}
												}
											}
										}
									}
								}
								zebra.append(aThis.allowComponents.init, printerBuffer);
								zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
								zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
								(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
								
							}
						}

						zebra.append(aThis.allowComponents.init, printerBuffer)
						zebra.append(aThis.allowComponents.rightAlignment, printerBuffer)
						zebra.append(aThis.allowComponents.bigFont, printerBuffer)

						if (salejd && salejd.delivery){
							customerName = salejd.delivery.ordererName
							customerPhone = salejd.delivery.ordererPhone
						}

						if (posUtils.isBlank(customerName) && salejd && !posUtils.isBlank(salejd.dedicatedTo)){
							customerName = salejd.dedicatedTo
						}

						if (posUtils.isBlank(customerName) && !posUtils.isBlank(sale.customerName)){
							customerName = sale.customerName
						}

						if (posUtils.isBlank(customerPhone) && !posUtils.isBlank(sale.customerPhone)){
							customerPhone = sale.customerPhone
						}

						if (posUtils.isBlank(customerName) && salejd && salejd.customer){
							customerName = `${salejd.customer.s_first_name ? salejd.customer.s_first_name : ''}${salejd.customer.s_last_name ? ` ${salejd.customer.s_last_name}` : ''}`
						}
						
						if (posUtils.isBlank(customerPhone) && salejd && salejd.customer && !posUtils.isBlank(salejd.customer.s_phone_number_1)){
							customerPhone = salejd.customer.s_phone_number_1
						}

						if (!posUtils.isBlank(customerName)) {
							aThis.printHr(printerBuffer)
							zebra.append(aThis.allowComponents.newLine, printerBuffer)
							zebra.append(aThis.allowComponents.init, printerBuffer);

							if(customerNameWithBigFont) {
								zebra.append(aThis.allowComponents.bigAndBoldFont, printerBuffer);
							}

							let customerPhoneStr = ''
							if (!posUtils.isBlank(customerPhone)) {
								customerPhoneStr = `${i18next.t("printing.phone")}: ${customerPhone}`
							}
							aThis.printFloatingLine(`${i18next.t("printing.customer")}: ${customerName.replace(/[^A-Za-zא-ת0-9_-\s]+/g, ``)}`, customerPhoneStr, undefined, printerBuffer)
							zebra.append(aThis.allowComponents.newLine, printerBuffer)
							zebra.append(aThis.allowComponents.init, printerBuffer);
						}

						if(jsonConfig.getVal(jsonConfig.KEYS.printDeliveryInformationOnBon) && sale.isDelivery) {
							if(!posUtils.isBlank(salejd.delivery)) {
								if (posUtils.isBlank(customerPhone) && posUtils.isBlank(customerName)){
									aThis.printHr(printerBuffer)
									zebra.append(aThis.allowComponents.newLine, printerBuffer)
								}
								
								if (!posUtils.isBlank(salejd.delivery.deliveryTime)) {
									aThis.printFloatingLine(`${i18next.t('delivery.deliveryTimeTitle')}: ${salejd.delivery.deliveryTime}`, '', undefined, printerBuffer);
									zebra.append(aThis.allowComponents.newLine, printerBuffer)
								}

								if (!posUtils.isBlank(salejd.delivery.deliveryAddress)) {
									let deliveryAddress = salejd.delivery.deliveryAddress
									
									if (!posUtils.isBlank(deliveryAddress.city)) {
										aThis.printFloatingLine(`${i18next.t('delivery.city')}: ${deliveryAddress.city.name}`, '', undefined, printerBuffer)
									}

									if (!posUtils.isBlank(deliveryAddress.address)) {
										aThis.printFloatingLine(`${i18next.t('delivery.address')}: ${deliveryAddress.address.name}${posUtils.isBlank(deliveryAddress.house_number) ? '' : ` ${deliveryAddress.house_number}`}`, '', undefined, printerBuffer)
									}

									if (!posUtils.isBlank(deliveryAddress.apartment)) {
										aThis.printFloatingLine(`${i18next.t('delivery.apartment')}: ${deliveryAddress.apartment}`, '', undefined, printerBuffer)
									}
									
									if (!posUtils.isBlank(deliveryAddress.house_entrance)) {
										aThis.printFloatingLine(`${i18next.t('delivery.entrance')}: ${deliveryAddress.house_entrance}`, '', undefined, printerBuffer)
									}

									if (!posUtils.isBlank(deliveryAddress.house_floor)) {
										aThis.printFloatingLine(`${i18next.t('delivery.floor')}: ${deliveryAddress.house_floor}`, '', undefined, printerBuffer)
									}

									zebra.append(aThis.allowComponents.newLine, printerBuffer)
								}

								let ordererCallerRemarks = ""
								if (!posUtils.isBlank(salejd.delivery.ordererCallerRemarks)) {
									aThis.printMultyLine(salejd.delivery.ordererCallerRemarks, printer.lineLengthBigFont, printerBuffer);
									zebra.append(aThis.allowComponents.newLine, printerBuffer)
									ordererCallerRemarks = salejd.delivery.ordererCallerRemarks;
								}

								if (!posUtils.isBlank(salejd.delivery.ordererDeliveryRemarks) && ordererCallerRemarks != salejd.delivery.ordererDeliveryRemarks) {
									aThis.printMultyLine(salejd.delivery.ordererDeliveryRemarks, printer.lineLengthBigFont, printerBuffer);
									zebra.append(aThis.allowComponents.newLine, printerBuffer)
								}
							}
						}
					}

					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
					printer.printLineCut(printerBuffer)
					printer.jzebra.print(printerBuffer)
				}

				if (Storage.Entity.LogicalPrinter.isAndroidWifiPrinter(printerBuffer)){
					session.pos.printerType = oldPrinterType
					printer.jzebra._setPrinterType()
				}
			}

			function printUpdateItemsBon(saleItems, printerBuffer) {
				let aThis = printer
				let zebra = aThis.jzebra;
				let bonLineSpace = jsonConfig.getVal(jsonConfig.KEYS.bonLineSpace);
				let bonFontHuge = jsonConfig.getVal(jsonConfig.KEYS.bonFontHuge);
				let tableItemsWidths = [8,2]
				let printForItemTextAndOriginalItem = jsonConfig.getVal(jsonConfig.KEYS.printUpdateBonsWithForItemInfo)

				for (let saleItem of saleItems) {
					if(!saleItem.hasChanges) {
						continue
					}

					aThis.printHr(printerBuffer)
					let itemDescription = saleItem.itemDescription
					let nameOnBon = saleItem.item.nameOnBon
					if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
						itemDescription = nameOnBon
					}

					if (bonLineSpace) {
						zebra.append(aThis.allowComponents.init, printerBuffer);
						zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
						zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
						zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
					}

					if(saleItem.itemBeforeChanges) {
						let itemBeforeChanges = saleItem.itemBeforeChanges || []
						if(typeof saleItem.itemBeforeChanges == 'string') {
							itemBeforeChanges = JSON.parse(saleItem.itemBeforeChanges)
						}
						printItemTextWithStrikethrough(itemBeforeChanges, printerBuffer, tableItemsWidths, bonLineSpace)
						let addedChildrenItems = saleItem.children.filter((saleItem) => saleItem.hasUpdated)

						for (const addedItem of addedChildrenItems) {
							aThis.printTableRow([addedItem.itemDescription, addedItem.actualQuantity.toString()], tableItemsWidths, ['text-4 white-space-reset', 'text-4 center'], printerBuffer)
							if (addedItem.hasPreparationInstructions) {
								zebra.append(aThis.allowComponents.init, printerBuffer);
								let prepInstArr = JSON.parse(addedItem.selectedPreparationInstructions)
								let widths = [6]
								let data = []
								let classes =  ['right pad-right x5 text-3 white-space-reset']
								
								if (prepInstArr) {
									for (let prepInst of prepInstArr) {
										(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
										data = [prepInst]
										aThis.printTableRow(data, widths, classes, printerBuffer)
									}
	
									zebra.append(aThis.allowComponents.init, printerBuffer)
								}
							}

							if(addedItem.children && addedItem.children.length > 0) {
								for (let grandChild of addedItem.children) {
									let itemDescription = grandChild.itemDescription
									let nameOnBon = grandChild.item.nameOnBon
									if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
										itemDescription = nameOnBon
									}
									(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
									zebra.append(aThis.allowComponents.bigFont, printerBuffer);
									aThis.printFloatingLine(` ` + itemDescription, grandChild.actualQuantity.toString(),
										21, printerBuffer);
									if (grandChild.hasPreparationInstructions) {
										let prepInstArr = JSON.parse(grandChild.selectedPreparationInstructions)
										if (prepInstArr) {
											for (let prepInst of prepInstArr) {
												zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
												(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
												aThis.printTableRow([prepInst], tableItemsWidths, ['right pad-right x5 text-1 white-space-reset'], printerBuffer)
												zebra.append(aThis.allowComponents.newLine, printerBuffer)
												zebra.append(aThis.allowComponents.init, printerBuffer);
												zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
											}
										}
									}
								}
							}
						}
					}

					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
					(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
				}
			}

			function printItemTextWithStrikethrough(saleItem, printerBuffer, tableWidths, bonLineSpace) {
				let aThis = printer
				let zebra = aThis.jzebra;
				let bonFontHuge = jsonConfig.getVal(jsonConfig.KEYS.bonFontHuge);

				zebra.append(aThis.allowComponents.init, printerBuffer)
				if (saleItem.hasPreparationInstructions) {
					let prepInstArr = JSON.parse(saleItem.selectedPreparationInstructions)
					if (prepInstArr) {
						for (let prepInst of prepInstArr) {
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
							zebra.append(aThis.allowComponents.bigFont, printerBuffer);
							(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
							aThis.printTableRow([prepInst], tableWidths, ['right pad-right x5 text-1 white-space-reset'], printerBuffer)
							zebra.append(aThis.allowComponents.newLine, printerBuffer)
							zebra.append(aThis.allowComponents.init, printerBuffer);
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
							zebra.append(aThis.allowComponents.bigFont, printerBuffer);
						}
					}
				}

				if (saleItem.children && saleItem.children.length > 0) {
					zebra.append(aThis.allowComponents.init, printerBuffer);
					zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
					for (let child of saleItem.children) {
						child = Storage.Entity.SaleItem.import(child);
						let itemDescription = child.itemDescription.strike()
						let nameOnBon = child.item?.nameOnBon?.strike()
						if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
							itemDescription = nameOnBon
						}

						(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
						if (bonFontHuge == true && !child.isPaidAddition) {
							zebra.append(aThis.allowComponents.hugeFont, printerBuffer);
							zebra.append(aThis.allowComponents.hugeLineSpacing, printerBuffer);
						}

						aThis.printTableRow([itemDescription, child.actualQuantity.toString().strike()], tableWidths, ['text-4 white-space-reset', 'text-4 center'], printerBuffer)

						if (child.hasPreparationInstructions) {
							let prepInstArr = JSON.parse(child.selectedPreparationInstructions)
							if (prepInstArr) {
								for (let prepInst of prepInstArr) {
									zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
									zebra.append(aThis.allowComponents.bigFont, printerBuffer);
									(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
									aThis.printTableRow([prepInst.strike()], tableWidths, ['right pad-right x5 text-1 white-space-reset'], printerBuffer)
									zebra.append(aThis.allowComponents.newLine, printerBuffer)
									zebra.append(aThis.allowComponents.init, printerBuffer);
									zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
									zebra.append(aThis.allowComponents.bigFont, printerBuffer);
								}
							}
						}

						if (child.children && child.children.length > 0) {
							zebra.append(aThis.allowComponents.init, printerBuffer);
							zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
							for (let grandChild of child.children) {
								let itemDescription = grandChild.itemDescription.strike()
								let nameOnBon = grandChild.item.nameOnBon?.strike()
								if (jsonConfig.getVal(jsonConfig.KEYS.isNameOnBonPrecede) && nameOnBon) {
									itemDescription = nameOnBon
								}
								(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
								zebra.append(aThis.allowComponents.bigFont, printerBuffer);
								aThis.printFloatingLine(` ` + itemDescription, grandChild.actualQuantity.toString().strike(),
									21, printerBuffer);
								if (grandChild.hasPreparationInstructions) {
									let prepInstArr = JSON.parse(grandChild.selectedPreparationInstructions)
									if (prepInstArr) {
										for (let prepInst of prepInstArr) {
											zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
											zebra.append(aThis.allowComponents.bigFont, printerBuffer);
											(bonLineSpace == true) ? zebra.append(aThis.allowComponents.lineSpacing, printerBuffer) : _.noop();
											aThis.printTableRow([prepInst.strike()], tableWidths, ['right pad-right x5 text-1 white-space-reset'], printerBuffer)
											zebra.append(aThis.allowComponents.newLine, printerBuffer)
											zebra.append(aThis.allowComponents.init, printerBuffer);
											zebra.append(aThis.allowComponents.rightAlignment, printerBuffer);
											zebra.append(aThis.allowComponents.bigFont, printerBuffer);
										}
									}
								}
							}
						}
					}
					zebra.append(aThis.allowComponents.newLine, printerBuffer)
				}

			}

			function getItemsNarrowWidthts() {
				return printer.isHTMLBasedPrinting() ? [15,7,8] : [14, 7, 8];
			}

            //TODO: TDL!!!
			export function printInvoiceFactory(sale, saleItems: Array<PositiveTS.Storage.Entity.SaleItem>, salePayments, isSource, printCalculatedVars,
				addiotionalText, dictOpetions?: PositiveTS.Types.PrintInvoiceFactoryOptions) {
				return new Promise(async (resolve, reject) => {
					var isPerforma = false;
					let printPerformaWithoutPrices = false
					var doNotPrintAmounts = (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV &&
						jsonConfig.getVal(jsonConfig.KEYS.hakafaShipmentInvPrintWithNoPrice))

					if (dictOpetions) {
						isPerforma = dictOpetions.isPerforma;
						printPerformaWithoutPrices = dictOpetions.printPerformaWithoutPrices

						if (isPerforma && printPerformaWithoutPrices == true) {
							doNotPrintAmounts = true
						}
					}

					if (printer.jzebra === null) {
						reject(`zerbra not defined`)
						return false
					}

					let aThis = printer;
					var zebra = aThis.jzebra;

					var vat = sale.vat;

					var _str = Service.StrUtils;

					if ([PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE,
					PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV,
					PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV,
					PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE].indexOf(sale.invoiceType) > -1 &&
						session.pos.shouldPrintBon && !isPerforma) {
						if(jsonConfig.getVal(jsonConfig.KEYS.printBonItemsOnSeperateBons) && !sale.movedFromDalpak){
							for(let saleItem of saleItems){
								printBon(sale, [saleItem], null);
							}
						}
						else{
							printBon(sale, saleItems, null);
						}
					}


					var itemsCopyWithDevidPromotions: Array<PositiveTS.Storage.Entity.SaleItem> = saleItems.map(item => item.clone())

					itemsCopyWithDevidPromotions = saleItemHelper.flattenSaleItemsByQuantity(itemsCopyWithDevidPromotions);

					// PositiveTS.Helper.SaleHelper.devidPromotionsDiscountByItemsForCredit(itemsCopyWithDevidPromotions);

					//Check if pickup and print pickup slip
					let pickups = [];
					if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
						for (var i = 0; i < saleItems.length; i++) {
							var currentSaleItem = saleItems[i];

							// the item is present
							if (currentSaleItem.isPickup) {
								pickups.push(currentSaleItem);
							}
						};
					}

					// check for gifts and print replacement slip
					var gifts = [];
					if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
						for (var i = 0; i < itemsCopyWithDevidPromotions.length; i++) {
							var currentSaleItem = itemsCopyWithDevidPromotions[i];

							// the item is present
							if (currentSaleItem.isPresent) {
								gifts.push(currentSaleItem);
							}
						};
					}

					
					if (isSource) {
						let needFireOpenDrawerIfRequired = true
						if (sale.isDelivery){
							needFireOpenDrawerIfRequired = jsonConfig.getVal(jsonConfig.KEYS.openDrawerOnReceiveDelivery)
						}

						if (needFireOpenDrawerIfRequired){
							await printer.openDrawerIfRequired(salePayments);
						}
					}
					

					try {
						var companyModel = new PositiveTS.Storage.Entity.Company();
						let company = await companyModel.promiseFetchByTenantIDAndCompanyID(sale.tenantID, sale.companyID)

						if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE) {
							printItemsWithPrintSufix(company, sale, saleItems);
							zebra.print();
							resolve();
							return;
						}

						let paymentTypeModel = new PositiveTS.Storage.Entity.PaymentType();
						let paymentsTypes = await paymentTypeModel.getCalcuatedPaymentTypesObject()
							

						let posNumber = session.pos.posNumber;
						if (sale.posNumber !== null && sale.posNumber !== undefined && sale.posNumber !== `undefined` && sale.posNumber != `null` && sale.posNumber != ``) {
							posNumber = sale.posNumber;
						}

						printSlipHeader(sale, company);

						let dedicatedToPrefix = (jsonConfig.getVal(jsonConfig.KEYS.alwaysGetDedicatedTo)) ? `${i18next.t("printing.customer")}:` : `${i18next.t("printing.for")}:`
						PositiveTS.Service.DedicatedTo.printDedicatedToIfRequired(sale, zebra, aThis, dedicatedToPrefix);

						zebra.append(aThis.allowComponents.init);

						zebra.append(aThis.allowComponents.centerAlignment);
						zebra.append(aThis.allowComponents.bold);

						let originalCopyTransKey = isSource === true ? 'original' : 'copy';

						aThis.printLine('*' + i18next.t(`printing.${originalCopyTransKey}`) + '*');

						if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && sale.dalpakId) {
							let dalpak = null;
	
							if (Pinia.dalpaksStore.allDalpaks) {
								dalpak = Pinia.dalpaksStore.allDalpaks.filter(d => d.railsId == sale.dalpakId)[0];
							}
	
							if (posUtils.isDefined(dalpak)) {
								zebra.append(aThis.allowComponents.bigAndBoldFont);
								if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
									aThis.printTextWithPrintLineText(`${i18next.t('printing.counter_new_rest')}: ${dalpak.name}`, '');
								}else{
									aThis.printTextWithPrintLineText(`${i18next.t('printing.counter')}: ${dalpak.name}`, '');
								}
								aThis.jzebra.append(printer.allowComponents.newLine);
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.centerAlignment);
								zebra.append(aThis.allowComponents.bold);
							}
						}
						if (!dictOpetions?.dontPrintInvoiceNumber) {
							if (isPerforma) {
								aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t('printing.saleMidBill') + ': ')
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
	
								if (session.getParameterInvoiceType() == 3) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t(`printing.invReceipt`) + ': ')
								} else if (session.getParameterInvoiceType() == 2) {
									if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
										aThis.jzebra.append(aThis.allowComponents.bigAndBoldFont);
									}
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.taxInvReceipt`) + ': ')
									if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
										aThis.jzebra.append(aThis.allowComponents.init);
										aThis.jzebra.append(aThis.allowComponents.centerAlignment);
										aThis.jzebra.append(aThis.allowComponents.bold);
									}
								} else {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.invoice`) + ': ')
								}
	
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
								if (session.getParameterInvoiceType() == 3) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t(`printing.cancelInvReceipt`) + ': ')
								} else if (session.getParameterInvoiceType() == 2) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t('printing.taxCreditReceipt') + ': ')
	
								} else {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.creditInvoice`) + ': ')
								}
	
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV) {
								if (session.getParameterInvoiceType() == 3) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t(`printing.invReceipt`) + ': ')
	
								} else if (session.getParameterInvoiceType() == 2) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.taxInv`) + ': ')	
								} else {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t('printing.invoice') + ': ')
								}
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV) {
								if (session.getParameterInvoiceType() == 3) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t(`printing.cancelInvReceipt`) + ': ')
	
								} else if (session.getParameterInvoiceType() == 2) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t('printing.taxCreditInvoice') + ': ')
								} else {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t('printing.invoice') + ': ')
								}
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {
								if (session.getParameterInvoiceType() == 3) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t(`printing.invReceipt`) + ': ')
								} else if (session.getParameterInvoiceType() == 2) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.deliveryNote`) + ': ')
								} else {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.deliveryNote`) + ': ')
								}
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) {
								if (session.getParameterInvoiceType() == 3) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
										i18next.t(`printing.cancelInvReceipt`) + ': ')
								} else if (session.getParameterInvoiceType() == 2) {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.returnNote`) + ': ')
								} else {
									aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									i18next.t(`printing.returnNote`) + ': ')
								}
							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT) {
								aThis.printTextWithPrintLineText(getSaleNumberFormated(sale, isPerforma),
									`${i18next.t("printing.receipt")}:`
								)
							}

						}
						
						
						
						if (Service.SittingTA.isTASale() && Service.SittingTA.isEnabled()) {
							aThis.printMultyLine(`***TA***`,  aThis.lineLengthBigFont);
						}

						if(sale.isDelivery || Service.Otot.isOtotActive() || dictOpetions?.explicityPrintOrderNumber) {
							
							if (Service.Otot.isOtotActive()) {
								aThis.jzebra.append(printer.allowComponents.newLine);
								zebra.append(aThis.allowComponents.hugeFont);
							}

							printer.printLine(`${i18next.t(`printing.orderNumber`)}: ${sale.orderNumber}`)

							let saleJsonData = JSON.parse(sale.jsondata);
							if(saleJsonData.ototTransactionId){
								aThis.printLine(` `);
								aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + saleJsonData.ototTransactionId);
								aThis.printLine(` `);
							}

							if (Service.Otot.isOtotActive()) {
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.centerAlignment);
								zebra.append(aThis.allowComponents.bold);
							}
						}

						aThis.printLine(` `);


						printClubMemberDescriptionIfExists(sale, saleItems, salePayments);

						zebra.append(aThis.allowComponents.init);

						let totalPrice = 0;
						let totalQuantity = 0;
						let totalDiscount = 0;
						let vatable_total_amount = 0;

						let widths = [aThis.itemDescriptionLength, 7, 8, 7];

						if (session.pos.printerType == PRINTER_TYPE.sunmik1) {
							widths = [aThis.itemDescriptionLength - 2, 7, 8, 9];
						}

						if (printer.isHTMLBasedPrinting()) {
							widths = [aThis.itemDescriptionLength - 4, 9, 7, 10]
						}

						let totalText = i18next.t('printing.totalForPayment');

						if (session.pos.hasFlights) {
							totalText = totalText + ' ' + Service.MultiCurr.getInstance().getPosCurrencySign();
						}

						if (!printer.isSunmi()) {
							zebra.append(aThis.allowComponents.rightAlignment);
						}
						if (saleItems.length > 0) {
							if (jsonConfig.getVal(jsonConfig.KEYS.isNewPrinting) || session.pos.isRoshemet) {
								let firstCol = i18next.t('printing.name');
								if (jsonConfig.getVal(jsonConfig.KEYS.printItemCode)) {
									firstCol = i18next.t('printing.itemNameCode');
								}
								if (printer.isNarrowPrinting()) {
									aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'), i18next.t('printing.quantity'), totalText], getItemsNarrowWidthts()), true);
								} else {

									if(isPerforma && printPerformaWithoutPrices) {
										widths = [aThis.maxDescriptionLength ,15]
										aThis.printHeader(aThis.getTableRow([firstCol, i18next.t('printing.quantity')], widths), true);
									} else {
										aThis.printHeader(aThis.getTableRow([firstCol, i18next.t('printing.price'), i18next.t('printing.quantity'), totalText], widths), true);
									}

								
								}
							}
							else {
								printItemsHeader(!doNotPrintAmounts);
							}
						}
						let discountsTable = {};
						if (session.pos.useNewPromotions) {
							let jd = JSON.parse(sale.jsondata);
							if (jd.promotions) {
								for (let promotion of jd.promotions) {
									if (discountsTable[promotion.rowToShowOn]) {
										discountsTable[promotion.rowToShowOn].push({
											amount: promotion.discountAmountForGroup,
											name: promotion.promoName,
											code: promotion.promotionCode,
											promotionAsDiscount: promotion.promotionAsDiscount
										})
									}
									else {
										discountsTable[promotion.rowToShowOn] = [{
											amount: promotion.discountAmountForGroup,
											name: promotion.promoName,
											code: promotion.promotionCode,
											promotionAsDiscount: promotion.promotionAsDiscount
										}];

									}
								}
							}
						}

						let saleItemsToPrint = saleItems;

						if (Service.Tip.isHasTips()) {
							let tipSaleItems = [];
							saleItemsToPrint = [];

							for (var i = 0; i < saleItems.length; i++) {
								if (Service.Tip.isTipItemCode(saleItems[i].itemCode)) {
									tipSaleItems.push(saleItems[i])
								} else {
									saleItemsToPrint.push(saleItems[i]);
								}
							}

							if (tipSaleItems.length > 0) {
								let tipItemToPrint = {...tipSaleItems[0]};

								let tipCalc = Service.Tip.totalSaleItemsTipsCountAndAmount(tipSaleItems);

								tipItemToPrint.quantity = tipCalc.count;
								tipItemToPrint.unitPrice = tipCalc.amount;
								tipItemToPrint = (new Storage.Entity.SaleItem()).importFromObject(tipItemToPrint);

								saleItemsToPrint.push(tipItemToPrint);
							}
						}



						let childSign = '<-';
						let grandChildSign = '<---';

						if ((!Pinia.globalStore.rtlMode) || (Pinia.globalStore.rtlMode && printer.isHTMLBasedPrinting())) {
							childSign = '->';
						   grandChildSign = '--->';
						}

						for (var i = 0; i < saleItemsToPrint.length; i++) {
							if (Service.Tip.isHasTips() && Service.Tip.isTipItemCode(saleItemsToPrint[i].itemCode)) {
								aThis.printHr();
							}

							zebra.append(String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(8));
							if (jsonConfig.getVal(jsonConfig.KEYS.isNewPrinting) || session.pos.isRoshemet) {
								let discounts = discountsTable[saleItemsToPrint[i].rowNumber] || [];
								printInvoiceItemTableRow(saleItemsToPrint[i], widths, { print_price: !doNotPrintAmounts, discounts: discounts, isPerforma:isPerforma });

								if (saleItemsToPrint[i].children) {
									for (let child of saleItemsToPrint[i].children) {
										child.itemDescription = childSign + child.itemDescription;
										let discounts = discountsTable[child.rowNumber] || [];
										printInvoiceItemTableRow(child, widths, { print_price: (!doNotPrintAmounts && !saleItemsToPrint[i].item.hideChildrenPrices), discounts: discounts, isPerforma:isPerforma });
										if (child.children) {
											for (let grandchild of child.children) {
												grandchild.itemDescription = grandChildSign + grandchild.itemDescription;
												let discounts = discountsTable[grandchild.rowNumber] || [];
												printInvoiceItemTableRow(grandchild, widths, { print_price: (!doNotPrintAmounts && !saleItemsToPrint[i].item.hideChildrenPrices), discounts: discounts, isPerforma:isPerforma });
											}
										}
									}
								}
							}
							else {
								printInvoiceItem(saleItemsToPrint[i], { print_price: !doNotPrintAmounts, isPerforma:isPerforma  });
							}
						}
						sale['items'] = saleItems
						let calcuateSaleTotals = Helper.SaleHelper.calcuateSaleTotals(sale, saleItems, salePayments);
						totalPrice = calcuateSaleTotals.totalAmount;
						let roundAmount = calcuateSaleTotals.totalRound;
						let isCreditSale = sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE || sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV || sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV

						vatable_total_amount = calcuateSaleTotals.totalVatableAmount;
						totalDiscount = calcuateSaleTotals.totalDiscount;
						totalQuantity = calcuateSaleTotals.totalQuantity;

						let changePayment = null;
						let discountVoucherPayment = null;
						let creditVoucherPayment = null;
						let creditCardPayment = null;
						let caveretPayment = null;
						let cashPayment = null;
						let invoicePayments: Storage.Entity.SalePayment[] = [];
						
						appendToInvoiceTotalAmountOfItems(calcuateSaleTotals, widths)

						if(session.pos.isElal){
							let elalTicketType = "";
							let saleType = {
								emd: false,
								tkt: false,
							};
							sale.items.forEach(item => {
								if(item.elalTicketType == "EMD") {
									saleType.emd = true;
								} 
								if(item.elalTicketType == "TKT") {
									saleType.tkt = true;
								}
							})
							if(saleType.emd && saleType.tkt) {
								
								elalTicketType = i18next.t("posPastInvoices.elalTicketType.both");
							} else if(saleType.emd) {

								elalTicketType = i18next.t("posPastInvoices.elalTicketType.emd");
							} else if(saleType.tkt) {
								elalTicketType = i18next.t("posPastInvoices.elalTicketType.tkt");
							}

							if(elalTicketType != "") {
								aThis.printLine(` `);
								zebra.append(printer.allowComponents.rightAlignment);
								aThis.printLine(`${i18next.t("posPastInvoices.elalTicketType.type")}: ${elalTicketType}`);
							}
						}
						
						if (!doNotPrintAmounts) {
							for (let i = 0; i < salePayments.length; i++) {
								let payment = salePayments[i];

								switch (payment.method) {
									case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
										changePayment = payment;
										break;
									case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER:
										creditVoucherPayment = payment;
										invoicePayments.push(payment);
										break;
									case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
										discountVoucherPayment = payment;
										invoicePayments.push(payment);
										break;
									case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
										creditCardPayment = payment;
										invoicePayments.push(payment);
										break;
									case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
										cashPayment = payment;
										invoicePayments.push(payment);
										break;
									case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
										invoicePayments.push(payment);
										break;
									case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
										invoicePayments.push(payment);
										if (payment.data) {
											let paymentData = JSON.parse(payment.data)
											if (paymentData[0] && paymentData[0].smartVoucherType == Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT) {
												caveretPayment = payment;
											}
										}
										break;
								}
							};

							if (discountVoucherPayment != null) {
								try {
									var discountVoucherData = JSON.parse(discountVoucherPayment.data);

									if (discountVoucherData == null || discountVoucherData.length < 1) {
										discountVoucherPayment = null;
									}
								} catch (err) {
									discountVoucherPayment = null;
								}
							}

							if (sale.discountPercent > 0 || discountVoucherPayment != null) {
								zebra.append(aThis.allowComponents.rightAlignment);
								aThis.printFloatingLineWithPrice(totalQuantity + ` ${i18next.t("printing.items")}      ${i18next.t("printing.total")} ${i18next.t("currentCurrency")}`, session.fixedNumber(totalPrice + totalDiscount));

								if (sale.discountPercent > 0) {
									let saleDiscount = sale.saleDiscountAmount;
									let priceText = session.fixedNumber(parseFloat(saleDiscount) * -1);
									let precentText = session.fixedFloat(sale.discountPercent) + `%`;

									let discountSpaces = aThis.itemPriceLength - priceText.length;
									if (sale.discountName === `` || typeof (sale.discountName) === `undefined` || sale.discountName === null) {
										sale.discountName = i18next.t("printing.discount");
									}

									let textSpaces = aThis.lineLength - aThis.itemPriceLength - sale.discountName.length - precentText.length - 2;

									if ((aThis.isRTL) && (!printer.isSunmi())) {
										aThis.printChar(` `, discountSpaces);
										aThis.printText(priceText);
										aThis.printText(`  `);
										aThis.printText(precentText);
										aThis.printChar(` `, textSpaces);
										aThis.printLine(sale.discountName);
									}
									else {
										aThis.printText(sale.discountName);
										aThis.printChar(` `, textSpaces);
										aThis.printChar(` `, discountSpaces);
										aThis.printText(precentText);
										aThis.printText(`  `);
										if (session.pos.printerType == PRINTER_TYPE.sunmi) {
											priceText = priceText.substring(1) + "-";
										}
										aThis.printLine(priceText);
									}
								}

								if (discountVoucherPayment != null) {
									let discountVoucher = JSON.parse(discountVoucherPayment.data);
									for (let i = 0; i < discountVoucher.length; i++) {
										let currVoucher = discountVoucher[i];
										aThis.printFloatingLineWithPrice(currVoucher.creditType, session.fixedNumber(parseFloat(currVoucher.amount) * -1));
									};
								}
								zebra.append(aThis.allowComponents.init);
							}

							aThis.printLine(` `);

							zebra.append(aThis.allowComponents.centerAlignment);
							zebra.append(aThis.allowComponents.bigFont);

							if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE ||
								sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV ||
								sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {
								let totalForPaymentText

								if(JSON.parse(sale.jsondata).delivery && Service.Delivery.isExternalDelivery(sale) && JSON.parse(sale.jsondata).delivery.orderHasBeenPaid) {
									totalForPaymentText = `${i18next.t("printing.totalPaid")}:   `
								}
								else {
									totalForPaymentText = `${i18next.t("printing.totalForPayment")}:   `
								}

								totalForPaymentText = totalForPaymentText + printer.fixNegSunmi(Base.numberWithCommas(session.fixedNumber(totalPrice, 2, true)));

								if (session.pos.hasFlights) {
									totalForPaymentText = totalForPaymentText + ' ' + Service.MultiCurr.getInstance().getPosCurrencySign();
								}

								if (aThis.isHTMLBasedPrinting()) {
									zebra.append(aThis.allowComponents.init);
									zebra.append(aThis.allowComponents.bigFont);
									zebra.append(aThis.allowComponents.centerAlignment);
					
									aThis.printLine(totalForPaymentText);

								} else if (totalForPaymentText.length < printer.lineLengthBigFont) {
									aThis.printMultyLine(totalForPaymentText, printer.lineLengthBigFont);
								}
								else {
									aThis.printLine(`${i18next.t("printing.totalForPayment")}:`);
									aThis.printLine(printer.fixNegSunmi(Base.numberWithCommas(session.fixedNumber(totalPrice, 2, true))));
								}

								if (Service.SplitSalePayment.isBaseSplitSale(sale)) {
									let totalOriginalAmount = printer.fixNegSunmi(Base.numberWithCommas(session.fixedNumber(totalPrice + Service.SplitSalePayment.calculateBaseSaleSplittedPayments(sale, saleItems), 2, true)));

									zebra.append(aThis.allowComponents.init);
									zebra.append(aThis.allowComponents.centerAlignment);

									let totalWithoutSplitForPaymentText = `${i18next.t("printing.originalTotalForPayment")}: ` + totalOriginalAmount;

									if (!aThis.isHTMLBasedPrinting() && totalWithoutSplitForPaymentText.length < printer.lineLength) {
										aThis.printMultyLine(totalWithoutSplitForPaymentText, printer.lineLength);
									} else {
										aThis.printLine(totalWithoutSplitForPaymentText);
									}
								}

							} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE ||
								sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV ||
								sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) {
								let mutliCurr = PositiveTS.Service.MultiCurr.getInstance();
								
								if (mutliCurr.isMultiCurr()) {
									if (mutliCurr.creditInOriginalCashCurrencyRelatively() && invoicePayments.length == 1 && invoicePayments[0].method == PositShared.SalePaymentMethod.CASH) {
										let paymentData: Types.PaymentData[] = JSON.parse(invoicePayments[0].data);
										if (paymentData.length == 1) {
											let cash = paymentData[0]
											let currSign = mutliCurr.getCurrencySign(cash.paid_currency_symble);
											let currAmount = Math.abs(cash.paid_currency_amount);
											aThis.printLine(`${i18next.t('printing.totalForRefund')}:   ` + Base.numberWithCommas(session.fixedNumber(currAmount, 2, true)) + currSign);
										}
									} else {
										aThis.printLine(`${i18next.t('printing.totalForRefund')}:   ` + Base.numberWithCommas(session.fixedNumber(-totalPrice, 2, true)) + mutliCurr.getPosCurrencySign());
									}
								} else {
									aThis.printLine(`${i18next.t('printing.totalForRefund')}:   ` + Base.numberWithCommas(session.fixedNumber(-totalPrice, 2, true)));
								}

							}

							zebra.append(aThis.allowComponents.init);
							zebra.append(aThis.allowComponents.rightAlignment);

							if (sale.invoiceType != PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT) {
								aThis.printLine(` `);

								var calcuatedVat = 0;


								if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE ||
									sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV
									|| sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {
									calcuatedVat = PositiveTS.Helper.SaleHelper.calculateVat(
										sale.totalVatableAmount != null ? sale.totalVatableAmount : vatable_total_amount, vat);

								} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE ||
									sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV
									|| sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) {
									calcuatedVat = PositiveTS.Helper.SaleHelper.calculateVat(
										sale.totalVatableAmount != null ? -sale.totalVatableAmount : -vatable_total_amount, vat);
								}

								let totalAmountNonVat = sale.totalAmount - sale.totalVatableAmount
								if (
									sale.totalVatableAmount != sale.totalAmount && sale.totalVatableAmount != null &&
									((totalAmountNonVat > 0.1) || (totalAmountNonVat < -0.1) || session.pos.hasFlights) &&
									!isPerforma
									) {

									aThis.printFloatingLineWithPrice(`${i18next.t("printing.totalFreeOfVat")}`, Base.numberWithCommas(session.fixedNumber(Math.abs(totalAmountNonVat), 2, true)))
								}

								if (!Service.DutyFree.isDutyFree()) {
									const dontPrintVatRowsIfIsZero = jsonConfig.getVal(jsonConfig.KEYS.dontPrintVatRowsIfIsZero)
									if (!dontPrintVatRowsIfIsZero || (dontPrintVatRowsIfIsZero && calcuatedVat > 0)){
										aThis.printFloatingLineWithPrice(`${i18next.t("printing.totalOwsVat")}`, Base.numberWithCommas(session.fixedNumber(sale.totalVatableAmount != null ? Math.abs(sale.totalVatableAmount) - Math.abs(calcuatedVat) : vatable_total_amount - calcuatedVat, 2, true)));
										aThis.printFloatingLineWithPrice(`${i18next.t("printing.vat")} ` + vat + `%`, Base.numberWithCommas(session.fixedNumber(Math.abs(calcuatedVat), 2, true)));
									}
								}

								aThis.printLine(` `);
							}

						}


						if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
							if (session.getParameterInvoiceType() == 2) {
								aThis.jzebra.append(printer.allowComponents.rightAlignment);
								aThis.printLine(`${i18next.t('printing.refundMethod')}: `);
							}
						}

						if (!isNaN(roundAmount) && roundAmount != 0 && session.pos.isCaveret) {
							aThis.printFloatingLineWithPrice('עיגול חשבון:', session.fixedNumber(roundAmount * -1, 2));
							roundAmount = null;
						}

						if (session.pos.hasFlights) {
							FlightReports.printInvoicePayments(invoicePayments.concat(changePayment && changePayment.amount ? [changePayment] : []), paymentsTypes, isCreditSale);
							changePayment = null;
						} else {
							for (var i = 0; i < invoicePayments.length; i++) {
								printInvoicePayment(invoicePayments[i], paymentsTypes, isCreditSale);
							}
						}

						
						if (!isNaN(roundAmount) && roundAmount != 0 && !session.pos.isCaveret) {
							aThis.printFloatingLineWithPrice('עיגול אגורות:', session.fixedNumber(roundAmount * -1, 2));
							roundAmount = null;
						}

						aThis.printLine(` `);
						if (changePayment != null) {
							printInvoicePayment(changePayment, paymentsTypes);
						}

						
						if ((!sale.isDelivery) && (!sale.parentSaleDelivery)) {
							printClubMemberFooter(sale, saleItems, salePayments);
						}
						else if (sale.isDelivery) {
							printDeliveryInformation(sale);
						}
						printGppAndValueCard(sale, saleItems, salePayments);
						printValu(sale, saleItems, salePayments);

						if (totalDiscount > 0) {
							aThis.printLine(` `);
							let savedText = session.fixedNumber(totalDiscount, 2);

							if (session.pos.hasFlights) {
								savedText = savedText + ' ' + Service.MultiCurr.getInstance().getPosCurrency();
							}

							aThis.printFloatingLineWithPrice(i18next.t("printing.inThisSaleYouSaved"), savedText);

							if (session.pos.hasFlights) {
								aThis.printLine(` `);
							}
						}

						if ((!session.pos.hasFlights) || creditCardPayment != null) {
							aThis.printHr();
						}

						if (creditCardPayment != null) {
							var creditCardData = JSON.parse(creditCardPayment.data);
							for (var i = 0; i < creditCardData.length; i++) {
								if (creditCardData[i].isEmv) {
									creditCardData[i] = Object.assign(new PositiveShared.EmvOutput(), creditCardData[i]);
								}else if(creditCardData[i].isGateway){
									creditCardData[i] = Object.assign(new PositiveShared.PaymentGatewayOutput(), creditCardData[i]);
								}
								if ((session.pos.isEmv && creditCardData[i].isEmv) || creditCardData[i].isGateway) {
									printCreditCardDetailsEmv(creditCardData[i], sale);
								}
								else {
									printCreditCardDetails(creditCardData[i], sale);
								}

								aThis.printHr();
								aThis.printLine(` `);
							};
						}	
						invoicePayments.forEach(function (payment) {
							if(payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER){
								var vouchers = JSON.parse(payment.data);
							for (var i = 0; i < vouchers.length; i++) {
								if (vouchers[i].smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE) {
								printDetailedMultipassPointsUsed(vouchers[i]);
								}	
							};
							}
						})

						let salejd = JSON.parse(sale.jsondata)
						if (salejd.tax_authority) {
							let length = salejd.tax_authority.confirmation_number.length;
							let shortConfirmationNumber = salejd.tax_authority.confirmation_number.substring(length - 9, length);
							aThis.printLine(` `);
							aThis.printFloatingLine(i18next.t('printing.taxAuthorityConfirmationNumber') + ':', shortConfirmationNumber);
							aThis.printFloatingLine(salejd.tax_authority.confirmation_number);
							aThis.printLine(` `);
						}
		
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);

						aThis.printLine(` `);

						if(!posUtils.isBlank(sale.saleRemarks)){
							zebra.append(aThis.allowComponents.init);
							zebra.append(aThis.allowComponents.rightAlignment);
							zebra.append(aThis.allowComponents.bold);

							let remarkLines = sale.saleRemarks.split('\n')

							for (const remarkLine of remarkLines) {
								
								printer.printLine(remarkLine)
							}

						}

						if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS) && isPerforma) {
							zebra.append(aThis.allowComponents.centerAlignment);
							zebra.append(aThis.allowComponents.bigFont);
							aThis.printLine("**" + i18next.t("printing.notPaid") + "**");
						}

						let suffixLines = addiotionalText || [];

						if (sale.invoiceType == Storage.Entity.Sequence.TYPE_DEBIT_INVOICE || sale.invoiceType == Storage.Entity.Sequence.TYPE_SHIPMENT_INV || sale.invoiceType == Storage.Entity.Sequence.TYPE_TAX_INV ) {
							if(isPerforma) {
								if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterPreformaSuffix)) {
									suffixLines = _str.splitByNewLine(session.pos.parameterPreformaSuffix);
								} else {
									if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterDebitInvoiceSuffix)) {
										suffixLines = _str.splitByNewLine(session.pos.parameterDebitInvoiceSuffix);
									}
								}
							} else {
								if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterDebitInvoiceSuffix)) {
									suffixLines = _str.splitByNewLine(session.pos.parameterDebitInvoiceSuffix);
								}
							}
						} else if (sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE || sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV || sale.invoiceType == Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV) {
							if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterCreditInvoiceSuffix)) {
								suffixLines = _str.splitByNewLine(session.pos.parameterCreditInvoiceSuffix);
							}
						} else if (sale.invoiceType == Storage.Entity.Sequence.TYPE_RECEIPT) {
							if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterPositiveRcptSuffix)) {
								let specialItemHndlr = PositiveTS.Service.SmartVoucher.getSpecialItemOrNull(saleItems, posVC.Specialitems)
								if (specialItemHndlr && specialItemHndlr.hndlr === "Valu") {
									suffixLines = _str.splitByNewLine(session.pos.parameterPositiveRcptSuffix);
								}
							}
						}

						for (let i = 0; i < suffixLines.length; i++) {
							aThis.printMultyLine(suffixLines[i]);
						};
						if (caveretPayment) {
							let data = JSON.parse(caveretPayment.data)[0];
							if (data.PrintComments) {
								aThis.jzebra.append(printer.allowComponents.newLine);
								aThis.printLine(data.PrintComments);
							}
						}

						printer.printLineCut();

						if (caveretPayment) {
							printCaveretSlip(sale, company, caveretPayment);
						}



					if ((sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE ||
							PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) && isSource) {
							printItemsWithPrintSufix(company, sale, saleItems);
						}

						//new PositiveTS.Printing.CashRefoundSlip(sale, company, salePayments).run();
						new PositiveTS.Printing.CheckErnSlip(sale, company, salePayments).run();
						// new PositiveTS.Printing.MultipassSlip(sale, company, salePayments).run();
						// new PositiveTS.Printing.CibusSlip(sale, company, salePayments).run();
						// new PositiveTS.Printing.TenbisSlip(sale, company, salePayments).run();

						if (JSON.parse(sale.jsondata).Beengo) {
							printSlipHeader(sale, company);
							aThis.printLine(i18next.t("beengo.beengoPrintMessage"));
							aThis.printLine(JSON.parse(sale.jsondata).Beengo.posTransactionId);
							printer.printLineCut();
						}


						printCreditInvoiceVoucherCredited(company, sale, salePayments);

						zebra.append(aThis.allowComponents.init);

						if (creditCardPayment != null && (!Boolean(session.pos.storeSignature))
							&& !Pinia.globalStore.standaloneMode
							&& !jsonConfig.getVal(jsonConfig.KEYS.dontPrintMerchantCreditCardSlip)) {
							let creditCardData = JSON.parse(creditCardPayment.data);
							for (let i = 0; i < creditCardData.length; i++) {
								if (creditCardData[i].isGateway || (creditCardData[i].isEmv && Service.EMV.isFullEmv(creditCardData[i].PanEntryMode))) {
									continue;
								}

								printSlipHeader(sale, company);
								if (creditCardData[i].isEmv) {
									creditCardData[i] = Object.assign(new PositiveShared.EmvOutput(), creditCardData[i]);
								}
								printCreditCardMerchantSlip(sale, creditCardData[i]);

								printer.printLineCut();

								zebra.append(aThis.allowComponents.init);
							};
						}

						if (cashPayment != null && cashPayment.amount < 0 &&
							(sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE ||
								sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)) {
							printSlipHeader(sale, company);
							printCashReturnSignature(sale, cashPayment.amount);

							printer.printLineCut();

							zebra.append(aThis.allowComponents.init);
						}

						if (creditVoucherPayment != null) {
							let creditVoucherData = JSON.parse(creditVoucherPayment.data);
							for (let i = 0; i < creditVoucherData.length; i++) {
								if (creditVoucherData[i].creditVoucherType == PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_CREDIT) {

									// if source: print source and copy
									// if copy: print only copy

									if (isSource) {
										printSlipHeader(sale, company);
										printCreditVoucher(sale, company, totalQuantity, creditVoucherData[i], isSource, printCalculatedVars);

										printer.printLineCut();

										zebra.append(aThis.allowComponents.init);
									}

									if (!jsonConfig.getVal(jsonConfig.KEYS.isPrintCreditVoucherOriginOnly)) {
										printSlipHeader(sale, company);
										printCreditVoucher(sale, company, totalQuantity, creditVoucherData[i], false, printCalculatedVars);

										printer.printLineCut();

										zebra.append(aThis.allowComponents.init);
									}


								}
							};
						}

						if (pickups.length > 0) {

							const copiesOfSlip = PositiveTS.Service.Pickup.getPickupPackagingNumberFromJsonData(JSON.parse(sale.jsondata)) + 1

							for (let i = 0; i < copiesOfSlip; i++) {
								printPickupSlip(sale, saleItems, salePayments, isSource, pickups, company, zebra, posNumber);
							}

						}

						if (gifts.length > 1) {

							// ask for count of replacement slip
							app.hideLoadingMessage();
							app.showAlert({
								content: `האם להדפיס פתק החלפה אחד או לכל פריט?`,
								continueButtonText: `לכל פריט`,
								cancelButtonText: `פתק אחד`,
								blueCancel: true,
							}, async function () {
								// print one slip per gift
								for (let i = 0; i < gifts.length; i++) {
									printReplacementSlip(sale, [gifts[i]], company, isSource, 1);
								};


								// last async action before print
								await printCouponSlip(totalPrice, salePayments, sale, company, isSource)

								await zebra.print()			
								resolve()

							}, async function () {
								// print all gift as one slip
								printReplacementSlip(sale, gifts, company, isSource);
								// last async action before print
								await printCouponSlip(totalPrice, salePayments, sale, company, isSource)

								await zebra.print();	
								resolve();
							});
						} else if (gifts.length > 0) {
							// print the only one gift
							printReplacementSlip(sale, gifts, company, isSource);
							// last async action before print
							await printCouponSlip(totalPrice, salePayments, sale, company, isSource)

							await zebra.print()			
							resolve()
										
						} else {
							// last async action before print
							await printCouponSlip(totalPrice, salePayments, sale, company, isSource)	

							await zebra.print()
							resolve()
						}
									
							
					}
					catch(err) { 
						reject(err);
						Service.Logger.error(err);
					};

				})


			}

			export function appendToInvoiceTotalAmountOfItems (calcuateSaleTotals, widths) {
				let totalAmountOfItems = session.fixedNumber(calcuateSaleTotals.totalAmountOfItems).replace(".00", '');
				let zebra = printer.jzebra;
				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment);
				printer.printHr();

				let totalQuantityText = `${i18next.t("closeSaleDialog.totalQuantity")}: ${totalAmountOfItems}`
				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.bigFont);
				zebra.append(printer.allowComponents.rightAlignment);

				if (printer.isHTMLBasedPrinting()) {
					let widthTotalQuantity = [(widths[0] + widths[1]), widths[2], widths[3]]  
					printer.printTableRow([i18next.t("closeSaleDialog.totalQuantity"), String(totalAmountOfItems)], widthTotalQuantity);
					printer.printLine(printer.allowComponents.newLine)

				} else if (totalQuantityText.length < printer.lineLengthBigFont) {
					printer.printMultyLine(totalQuantityText, printer.lineLengthBigFont);
				}else {
					printer.printLine(`${i18next.t("closeSaleDialog.totalQuantity")}:`);
					printer.printLine(String(totalAmountOfItems));
				}		
			}

			export function getSaleNumberFormated(sale, isPerforma?) {
				// Reviously posNumber + `-` + sale.invoiceType + `-` + sale.invoiceSequence
				if (isPerforma) {
					return sale.orderNumber
				}
				else {
					return sale.invoiceSequence
				}
			}
			function printItemWithPrintSufix(company, sale, saleItem: Storage.Entity.SaleItem) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				//Reset the printer
				zebra.append(aThis.allowComponents.init);

				printSlipHeader(sale, company);

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);

				aThis.printTextWithPrintLineText(sale.invoiceSequence,
					`${i18next.t("printing.voucherForItem")}: `)

				aThis.printLine(` `);

				// zebra.append(aThis.allowComponents.init);

				// zebra.append(aThis.allowComponents.centerAlignment);

				// var totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(sale, saleGiftItems, []);

				// aThis.printLine((session.fixedFloat(totals.totalAmount) * 100).toString() + `456`);
				// aThis.printLine(" ");

				zebra.append(aThis.allowComponents.init);




				if (!PositiveTS.Service.PunchCard.isPayPunchCardSale(sale)) {
					if (jsonConfig.getVal(jsonConfig.KEYS.isNewPrinting)) {
						let widths = [printer.itemDescriptionLength, printer.lineLength - printer.itemDescriptionLength];
						aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'), i18next.t('printing.quantity')], widths));
						aThis.printTableRow([String(saleItem.itemDescription), "1"], widths);
					}
					else {
						printItemsHeader(false);
						printInvoiceItem(saleItem, { print_price: false });
					}
				}

				zebra.append(aThis.allowComponents.rightAlignment);

				zebra.append(aThis.allowComponents.newLine);
				aThis.printHr();


				aThis.printLine(` `);

				var suffixLines = [];
				if (!posUtils.isNullOrUndefinedOrEmptyString(saleItem.item.printSufix)) {
					suffixLines = saleItem.item.printSufix.split(`\n`);

				}

				if (PositiveTS.Service.PunchCard.isPayPunchCardSale(sale)) {
					zebra.append(aThis.allowComponents.bigFont);
				}

				for (let i = 0; i < suffixLines.length; i++) {
					if (jsonConfig.getVal(jsonConfig.KEYS.useQRCode) && (suffixLines[i] == "[QR1]" || suffixLines[i] == "[QR2]")) {
						let qrData = sale.companyID + "|" + sale.storeID + "|" + sale.posDeviceID +
							"|" + sale.invoiceSequence + "|" + saleItem.itemCode;
						let qrDataWithHash = Service.QRService.generateQRHash(qrData) + "|" + qrData;
						printer.printQR(qrDataWithHash);
						Service.QRService.sendQRToGateControl(qrDataWithHash, suffixLines[i].substr(3, 1));
					} else {
						aThis.printLine(suffixLines[i]);
					}
				};


				aThis.printLine(` `);
				if (PositiveTS.Service.PunchCard.isPayPunchCardSale(sale)) {
					for (let row of PositiveTS.Service.PunchCard.getPunchCardSaleJonData(sale)) {
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.centerAlignment);
						zebra.append(aThis.allowComponents.bold);
						aThis.printLine(`נותרו עוד ${row.current_balance} כניסות בכרטיס זה`);
					}

				}


				if (PositiveTS.Service.PunchCard.isPayPunchCardSale(sale)) {
					if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterPayPunchCardSuffix)) {
						let punchSuffixLines = session.pos.parameterPayPunchCardSuffix.split("\n")
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);
						aThis.printLine(` `);
						for (let i = 0; i < punchSuffixLines.length; i++) {
							aThis.printMultyLine(punchSuffixLines[i]);
						};
					}
				}

				printer.printLineCut();

				zebra.append(aThis.allowComponents.init);
			}
			function printCreditVoucher(sale, company, itemsCount, creditVoucher, isSource, printCalculatedVars) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				if (itemsCount < 0) {
					itemsCount = -itemsCount;
				}

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);

				aThis.printLine(isSource === true ? `*${i18next.t("printing.original")}*` : `*${i18next.t("printing.copy")}*`);
				aThis.printMultyLine(`${i18next.t("printing.creditVoucher")}: ${creditVoucher.promotionNumber}`);

				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.init);

				aThis.printHr();

				if (!posUtils.isNullOrUndefinedOrEmptyString(sale.customerName)) {
					aThis.printFloatingLine(`${i18next.t("printing.customer")}:`, sale.customerName.replace(/[^A-Za-zא-ת0-9_-\s]+/g, ``));
				}

				if (posUtils.isNullOrUndefinedOrEmptyString(sale.customerID) || sale.customerID == `-1`) {
					aThis.printFloatingLine(`${i18next.t("printing.ID")}:`, `0`);
				} else {
					aThis.printFloatingLine(`${i18next.t("printing.ID")}:`, sale.customerID.toString());
				}

				aThis.printFloatingLine(`${i18next.t("printing.phone")}:`, posUtils.isNullOrUndefinedOrEmptyString(sale.customerPhone) ? ` ` : sale.customerPhone.toString());

				// zebra.append(aThis.allowComponents.init);
				aThis.printHr();

				aThis.printFloatingLine(`${i18next.t("printing.producedInInvoice")}:`, String(getSaleNumberFormated(sale)));

				var companyNameArray = company.companyName.split(`$$$`);

				aThis.printFloatingLine(`${i18next.t("printing.company")}:`, companyNameArray[0]);

				// printer.is the same as
				//   if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE)
				if (printCalculatedVars.parentSaleStoreName) {
					aThis.printFloatingLine(`${i18next.t("printing.branch")}:`, printCalculatedVars.parentSaleStoreName);
				} else {
					aThis.printFloatingLine(`${i18next.t("printing.branch")}:`, sale.storeName);
				}


				try {
					let createdAt = moment(new Date(sale.createdAt));
					aThis.printFloatingLine(`${i18next.t("printing.year")}:`, createdAt.year().toString());
				} catch (e) { }

				if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
					aThis.printFloatingLine(`${i18next.t("printing.producedForInvoice")}:`, sale.parentSalePosNumber + `-` + sale.parentSaleInvoiceType + `-` + sale.parentSaleInvoiceSequence);
				} else {
					aThis.printFloatingLine(`${i18next.t("printing.producedForInvoice")}:`, `---`);
				}

				aThis.printHr();
				aThis.printLine(` `);


				zebra.append(aThis.allowComponents.init);

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bigFont);

				if (PositiveTS.Service.MultiCurr.getInstance().getPosCurrency() == 'USD') {
					aThis.printLine(`${i18next.t("printing.creditAmount")}:   ` + session.fixedNumber(creditVoucher.amount) + `$`);
				} else {
					aThis.printLine(`${i18next.t("printing.creditAmount")}:   ` + session.fixedNumber(creditVoucher.amount));
				}



				aThis.printLine(` `);
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);
				if (jsonConfig.getVal(jsonConfig.KEYS.isPrintCreditVoucherexpiration)) {
					aThis.printLine(`${i18next.t("printing.voucherExpiration")}`);
				}


				zebra.append(aThis.allowComponents.init);

				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.centerAlignment);

				var suffixLines = [];
				if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterCreditVoucherSuffix)) {
					suffixLines = session.pos.parameterCreditVoucherSuffix.split(`\n`);
				}
				else {
					suffixLines.push(`${i18next.t("printing.limitingConditionsForVoucher")}.`);
				}

				for (var i = 0; i < suffixLines.length; i++) {
					aThis.printMultyLine(suffixLines[i]);
				};

				aThis.printLine(` `);
				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.init);

				zebra.append(aThis.allowComponents.centerAlignment);

				if (session.pos.tenantID != `4`) {
					aThis.printBarcode(creditVoucher.promotionNumber, `t2`);

					zebra.append(aThis.allowComponents.init);
					aThis.printLine(` `);
					aThis.printLine(` `);
					aThis.printLine(` `);
					zebra.append(aThis.allowComponents.init);
				}
			}
			function printCashReturnSignature(sale, amount) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				var storeName = session.pos.storeName;
				if (sale.storeName !== null && sale.storeName !== undefined && sale.storeName !== `undefined` && sale.storeName != `null` && sale.storeName != ``) {
					storeName = sale.storeName;
				}

				var posNumber = session.pos.posNumber;
				if (sale.posNumber !== null && sale.posNumber !== undefined && sale.posNumber !== `undefined` && sale.posNumber != `null` && sale.posNumber != ``) {
					posNumber = sale.posNumber;
				}

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);


				if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t(`printing.cancelInvReceipt`) + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.taxCreditInvoice') + ': ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'חשבון עסקה זכות: ')
					}
				}

				aThis.printLine(` `);
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);

				aThis.printLine(`${i18next.t("cash")}`);
				aThis.printHr();

				zebra.append(aThis.allowComponents.rightAlignment);

				aThis.printFloatingLine(`${i18next.t("amount")}`, Base.numberWithCommas(printer.fixNegSunmi(session.fixedNumber(amount))), 30);

				aThis.printLine(` `);

				aThis.printLine(`${i18next.t("printing.customerName")}: ___________________`);

				aThis.printLine(` `);

				aThis.printLine(`${i18next.t("printing.phone")}: ______________________`);

				aThis.printLine(` `);
				aThis.printLine(`${i18next.t("printing.signature")}: ________________`);


			}
			function printCreditCardMerchantSlip(sale, creditCard) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				if (creditCard.isAxium) {
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.centerAlignment);
					zebra.append(creditCard.ticket);
					return;
				}

				var storeName = session.pos.storeName;
				if (sale.storeName !== null && sale.storeName !== undefined && sale.storeName !== `undefined` && sale.storeName != `null` && sale.storeName != ``) {
					storeName = sale.storeName;
				}

				var posNumber = session.pos.posNumber;
				if (sale.posNumber !== null && sale.posNumber !== undefined && sale.posNumber !== `undefined` && sale.posNumber != `null` && sale.posNumber != ``) {
					posNumber = sale.posNumber;
				}

				printCreditCardDetailsForMerchant(creditCard, sale)
			}
			function printCreditCardDetailsForMerchant(creditCard, sale) {
				let aThis = printer
				let zebra = printer.jzebra;

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);

				if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.InvReceiptAppended') + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.taxInvReceiptAppended') + ': ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לחשבון עסקה: ')
					}

				} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.cancelattionInvAppended') + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.taxCreditReceiptAppended') + ': ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לחשבון עסקה זכות: ')
					}
				} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.InvReceiptAppended') + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לחשבונית מס: ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לחשבון עסקה: ')
					}
				} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.cancelattionInvAppended') + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.taxCreditReceiptAppended') + ': ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לחשבון עסקה: ')
					}
				} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.InvReceiptAppended') + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לתעודת משלוח: ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לתעודת משלוח: ')
					}
				} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) {
					if (session.getParameterInvoiceType() == 3) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							i18next.t('printing.cancelattionInvAppended') + ': ')
					} else if (session.getParameterInvoiceType() == 2) {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לתעודת החזרה: ')
					} else {
						aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
							'ספח לתעודת החזרה: ')
					}
				}

				aThis.printLine(` `);

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);

				aThis.printLine(i18next.t("printing.credit"));
				aThis.printHr();
				if ((session.pos.isEmv && creditCard.isEmv) || creditCard.isGateway) {
					printCreditCardDetailsEmv(creditCard, sale);
				}
				else {
					printCreditCardDetails(creditCard, sale);
				}


				var phone_number = String(creditCard.phone_number || '').trim();

				printer.printLine(`${i18next.t("printing.signature")}: ________________`);
				printer.printLine(` `);
				if (phone_number) {
					printer.printLine(`${i18next.t("printing.phone")}:      ` + phone_number);
				} else {
					printer.printLine(`${i18next.t("printing.phone")}:      ________________`);
				}
			}

			function adjustForHtmlIfNeeded(text: string) {
				if (printer.isHTMLBasedPrinting()) {
					return text.split(':').reverse().join(': ');
				}

				return text;
			}

			function printCreditCardDetailsEmv(creditCard, sale) {
				if (jsonConfig.getVal(jsonConfig.KEYS.shortEmvSlip)) {
					printShortCreditCardDetailsEmvPayGateway(creditCard, sale);
					return;
				}
				if (printer.jzebra === null) {
					return false;
				}
				let creditCardData
				if(creditCard.isGateway){
					creditCardData = (<PositiveShared.PaymentGatewayOutput>creditCard);
				}else{
					creditCardData = (<PositiveShared.EmvOutput>creditCard);
				}
				

				let aThis = printer;
				var zebra = aThis.jzebra;

				let doublePropertyWidth = [12, aThis.lineLength - 34, 11, 11]
				let triplePropertyWidth =  [5, Math.floor((aThis.lineLength - 15)/3), 5, Math.floor((aThis.lineLength - 15)/3), 5, Math.ceil((aThis.lineLength - 15)/3)]

				zebra.append(aThis.allowComponents.rightAlignment);
				if(creditCardData.isEmv){
					aThis.printMultyLine(`${i18next.t('printing.terminalName')}: ${printer.supportHebrew(creditCardData.TerminalName,true)}`)
				}
				aThis.printMultyLine(`${i18next.t('printing.terminalNumber')}: ${creditCardData.TerminalId}`);
				aThis.printMultyLine(`${i18next.t('printing.buisnessNumberAtCreditCompany')}: ${creditCardData.ComRetailerNum}`)
				let uid = creditCardData.Uid
				aThis.printMultyLine(`${i18next.t('printing.transactionDateTime')}: ${uid.substr(4, 2)}/${uid.substr(2, 2)}/${uid.substr(0, 2)} ${uid.substr(6, 2)}:${uid.substr(8, 2)}`)

				if (printer.isHebrewText(creditCardData.CardName || '')) {
					aThis.printMultyLine(`${i18next.t('printing.cardName')}: ${(creditCardData.CardName || '').split(``).reverse().join(``)}`)
				} else {
					aThis.printMultyLine(`${i18next.t('printing.cardName')}: ${creditCardData.CardName || ''}`)
				}

				aThis.printMultyLine(`${i18next.t('printing.cardNumber')}: ${creditCardData.Pan.substr(Math.max(creditCardData.Pan.length - 4, 0), 4)}`);
				aThis.printMultyLine(`${i18next.t('printing.voucherNumber')}: ${creditCardData.FileNo}${creditCardData.TermNo}${creditCardData.TermSeq}`)
				aThis.printMultyLine(adjustForHtmlIfNeeded(`${creditCardData.Uid} :UID`))
				if (creditCardData.Rrn) {
					aThis.printMultyLine(adjustForHtmlIfNeeded(`${creditCardData.Rrn} :RRN`))
				}

				if (creditCardData.ATC || creditCardData.CardSeqNumber) {
					aThis.printTableRow([`${i18next.t('printing.atc')}:`, `${creditCardData.ATC || ""}`, `${i18next.t('printing.cardSeqNumber')}:`, `${creditCardData.CardSeqNumber || ""}`], doublePropertyWidth)
				}

				if (creditCardData.TSI || creditCardData.ARC || creditCardData.TVR){
					aThis.printTableRow(["TSI:", creditCardData.TSI || "", "ARC:", creditCardData.ARC || "", "TVR:", creditCardData.TVR || ""], triplePropertyWidth)
				}
				
				aThis.printMultyLine(`${i18next.t('printing.transactionType')}: ${creditCardData.tranTypeHebrew}`)
				if (!posUtils.isNullOrUndefinedOrEmptyString(creditCardData.AuthManpikNo)) {
					aThis.printMultyLine(`${i18next.t('printing.approvalNumber')}: ${creditCardData.AuthManpikNo}`)
				}
				aThis.printMultyLine(`${i18next.t('printing.approvalAuthority')}: ${creditCardData.authCodeManpikHebrew}`)
				aThis.printMultyLine(`${i18next.t('printing.executionMethod')}: ${creditCardData.panEntryModeHebrew}`)
				if (!posUtils.isNullOrUndefinedOrEmptyString(creditCardData.AID)) {
					aThis.printMultyLine(`${i18next.t('printing.applicationIdInChip')}: ${creditCardData.AID}`)
				}
				aThis.printMultyLine(`${i18next.t('printing.creditType')}: ${creditCardData.ceditTermsHebrew}`)

				// let cardType = PositiveTS.Storage.Entity.SalePayment.CreditCardTypes.filter(type => String(type.id) == creditCardData.Solek)[0]

				// aThis.printMultyLine(`סוג כרטיס`, cardType.name, 30);

				//TODO: get credit card type...
				// if (creditCard.all_data) {
				// 	var creditType = shvaService.getShvaPaymentTypeOfficialNameFromRequestString(JSON.parse(creditCard.all_data).request);
				// 	aThis.printMultyLine(`${i18next.t('printing.creditType')}`, creditType, 30);
				// }

				var currency_hebrew = Service.MultiCurr.getInstance().getCurrencyNameFromCreditData(creditCard)

				if (parseFloat(sale.totalAmount) > 0) {
					aThis.printMultyLine(`${i18next.t('printing.sum')}: ${session.fixedNumber(creditCard.amount, 2)}`);
				} else {
					aThis.printMultyLine(`${i18next.t('printing.sum')}: ${(session.fixedNumber(creditCard.amount * -1, 2))}`);
				}

				aThis.printMultyLine(`${i18next.t('printing.currency')}: ${currency_hebrew}`)

				if (creditCardData.payments_count > 1) {
					if (creditCardData.FirstPayment) {
						aThis.printMultyLine(`${i18next.t('printing.firstPayment')}: ${session.fixedNumber(Number(creditCardData.FirstPayment) / 100, 2)}`);
					}
					if (creditCardData.NoPayment) {
						aThis.printMultyLine(`${i18next.t('printing.paymentsCount')} ${creditCardData.CreditTerms == `6` ? `` :  i18next.t('printing.additionalPayments')}:` + creditCardData.NoPayment);
					}
					if (creditCardData.NotFirstPayment) {
						aThis.printMultyLine(`${i18next.t('printing.everyOtherPayment')}: ${session.fixedNumber(Number(creditCardData.NotFirstPayment) / 100, 2)}`);
					}
				}

				if (creditCardData.VerifiedByPIN == `1`) {
					aThis.printLine(`Verified by PIN`);
				}





				aThis.printLine(` `);
			}
			function printShortCreditCardDetailsEmvPayGateway(creditCard, sale) {
				if (printer.jzebra === null) {
					return false;
				}
				let creditCardData
				if(creditCard.isGateway){
					creditCardData = (<PositiveShared.PaymentGatewayOutput>creditCard);
				}else{
					creditCardData = (<PositiveShared.EmvOutput>creditCard);
				}
				let aThis = printer;
				var zebra = aThis.jzebra;
				zebra.append(aThis.allowComponents.init);
				let singlePropertyWidth = [12, aThis.lineLength - 12];
				let doublePropertyWidth = [12, aThis.lineLength - 31, 11, 8]
				let triplePropertyWidth =  [5, Math.floor((aThis.lineLength - 15)/3), 5, Math.floor((aThis.lineLength - 15)/3), 5, Math.ceil((aThis.lineLength - 15)/3)]



				zebra.append(aThis.allowComponents.rightAlignment);
				if (creditCardData.isEmv && aThis.isHebrewText(creditCardData.TerminalName)) {
					aThis.printTableRow([`${i18next.t('printing.terminalName')}:`, `${creditCardData.TerminalName.split(``).reverse().join(``)}`], singlePropertyWidth);
				}
				else if(creditCardData.TerminalName){
					aThis.printTableRow([`${i18next.t('printing.terminalName')}:`, `${creditCardData.TerminalName}`], singlePropertyWidth)
				}

				if (printer.isHebrewText(creditCardData.CardName || '')) {
					aThis.printTableRow([`${i18next.t('printing.cardName')}:`, `${(creditCardData.CardName || '').trim().split(``).reverse().join(``)}`], singlePropertyWidth);
				} else {
					aThis.printTableRow([`${i18next.t('printing.cardName')}:`, `${(creditCardData.CardName || '').trim()}`], singlePropertyWidth);
				}

				aThis.printTableEnglishRow([`${creditCardData.Uid}`, `:UID`], singlePropertyWidth.slice().reverse())
				if (creditCardData.Rrn) {
					aThis.printTableEnglishRow([`${creditCardData.Rrn.trim()}`, `:RRN`], singlePropertyWidth.slice().reverse())
				}

				if (creditCardData.ATC || creditCardData.CardSeqNumber) {
					aThis.printTableRow([`${i18next.t('printing.atc')}:`, `${creditCardData.ATC || ""}`, `${i18next.t('printing.cardSeqNumber')}:`, `${creditCardData.CardSeqNumber || ""}`], doublePropertyWidth)
				}
				
				if (creditCardData.TSI || creditCardData.ARC || creditCardData.TVR){
					aThis.printTableRow(["TSI:", creditCardData.TSI || "", "ARC:", creditCardData.ARC || "", "TVR:", creditCardData.TVR || ""], triplePropertyWidth)
				}

				let uid = creditCardData.Uid
				aThis.printTableRow([`תאריך ושעה:`, `${uid.substr(4, 2)}/${uid.substr(2, 2)}/${uid.substr(0, 2)} ${uid.substr(6, 2)}:${uid.substr(8, 2)}`], singlePropertyWidth)


				if (printer.isHebrewText(creditCardData.CardName || '')) {
					aThis.printTableRow([`${i18next.t('printing.cardName')}:`, `${(creditCardData.CardName || '').trim().split(``).reverse().join(``)}`], singlePropertyWidth);
				} else {
					aThis.printTableRow([`${i18next.t('printing.cardName')}:`, `${(creditCardData.CardName || '').trim()}`], singlePropertyWidth);
				}


				if (!posUtils.isNullOrUndefinedOrEmptyString(creditCardData.AID)) {
					aThis.printTableRow([`${i18next.t('printing.applicationIdInChip')}:`, `${creditCardData.AID}`], singlePropertyWidth)
				}

				if (!posUtils.isNullOrUndefinedOrEmptyString(creditCardData.AuthManpikNo)) {
					aThis.printTableRow([`${i18next.t('printing.approvalNumber')}:`, `${creditCardData.AuthManpikNo.trim()}`], singlePropertyWidth)
				}

				// var currency_hebrew = `ש"ח`;
				// if (creditCard.creditCurrency == `USD`) {
				// 	currency_hebrew = `דולר`;
				// }

				var currency_hebrew = i18next.t(`currencies.${creditCard.creditCurrency || PositiveTS.Storage.Entity.CurrencieCodes.ILS}`);


				if (parseFloat(sale.totalAmount) > 0) {
					aThis.printTableRow([`${i18next.t('printing.sum')}:`, `${session.fixedNumber(creditCard.amount, 2)} ${currency_hebrew}`], singlePropertyWidth);
				} else {
					aThis.printTableRow([`${i18next.t('printing.sum')}:`, `${(session.fixedNumber(creditCard.amount * -1, 2))}  ${currency_hebrew}`], singlePropertyWidth);
				}
				aThis.printTableRow([`${i18next.t('printing.businessNumber')}:`, `${creditCardData.ComRetailerNum}`, `${i18next.t('printing.terminalNumber')}:`, `${creditCardData.TerminalId}`], doublePropertyWidth)
				aThis.printTableRow([`${i18next.t('printing.cardNumber')}:`, `${creditCardData.Pan.substr(Math.max(creditCardData.Pan.length - 4, 0), 4)}`, `שובר:`, `${creditCardData.FileNo}${creditCardData.TermNo}${creditCardData.TermSeq}`], doublePropertyWidth);
				aThis.printTableRow([`${i18next.t('printing.transactionType')}:`, `${creditCardData.tranTypeHebrew}`, `${i18next.t('printing.creditType')}:`, `${creditCardData.ceditTermsHebrew}`], doublePropertyWidth)
				aThis.printTableRow([`${i18next.t('printing.approvalAuthority')}:`, `${creditCardData.authCodeManpikHebrew}`, `${i18next.t('printing.executionMethod')}:`, `${creditCardData.panEntryModeHebrew}`], doublePropertyWidth)


				if (creditCardData.payments_count > 1) {
					if (creditCardData.FirstPayment) {
						aThis.printTableRow([`${i18next.t('printing.firstPayment')}:`, `${session.fixedNumber(Number(creditCardData.FirstPayment) / 100, 2)}`], singlePropertyWidth);
					}
					if (creditCardData.NoPayment) {
						aThis.printTableRow([`${i18next.t('printing.paymentsCount')}`, `${creditCardData.CreditTerms == `6` ? `` : ` נוספים`}:` + creditCardData.NoPayment], singlePropertyWidth);
					}
					if (creditCardData.NotFirstPayment) {
						aThis.printTableRow([`${i18next.t('printing.everyOtherPayment')}:`, `${session.fixedNumber(Number(creditCardData.NotFirstPayment) / 100, 2)}`], singlePropertyWidth);
					}
				}

				if (creditCardData.VerifiedByPIN == `1`) {
					aThis.printLine(`Verified by PIN`);
				}
				aThis.printLine(` `);
			}
			function printCreditCardDetails(creditCard, sale) {
				if (printer.jzebra === null) {
					return false;
				}


				let aThis = printer;
				var zebra = aThis.jzebra;

				if (creditCard.isAxium) {
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.centerAlignment);
					zebra.append(creditCard.ticket);
					return;
				}



				zebra.append(aThis.allowComponents.rightAlignment);

				aThis.printFloatingLine(`מס כרטיס`, creditCard.card_number, 30);

				var cardType = $.grep(PositiveTS.Storage.Entity.SalePayment.CreditCardTypes, function (type) {
					return type.id == creditCard.card_type
				});


				aThis.printFloatingLine(`סוג כרטיס`, cardType[0].name, 30);

				if (creditCard.all_data) {
					var creditType = serviceShva.getShvaPaymentTypeOfficialNameFromRequestString(JSON.parse(creditCard.all_data).request);
					aThis.printFloatingLine(`${i18next.t('printing.creditType')}`, creditType, 30);
				}

				if (creditCard.payments_count > 1) {
					aThis.printFloatingLine(`מס תשלומים`, creditCard.payments_count.toString(), 30);
				}

				aThis.printFloatingLine(`תוקף`, creditCard.valid_until, 30);


				aThis.printFloatingLine(`שובר/אישור`, creditCard.confirmation_number, 30);

				//creditCard.creditCurrency
				var currency_hebrew = ' ' + Service.MultiCurr.getInstance().getCurrencyNameFromCreditData(creditCard)

				if (parseFloat(sale.totalAmount) > 0) {
					aThis.printFloatingLine(`${i18next.t('printing.sum')}`, session.fixedNumber(creditCard.amount) +
						currency_hebrew
						, 30);
				} else {
					aThis.printFloatingLine(`${i18next.t('printing.sum')}`, (session.fixedNumber(creditCard.amount * -1)) +
						currency_hebrew
						, 30);
				}
				if (creditType == `תשלומים` && Number(creditCard.payments_count) > 1) {
					if (!isNaN(Number(creditCard.first_payment)) && !isNaN(Number(creditCard.payments_count)) && !isNaN(Number(creditCard.fixed_amount))) {
						aThis.printFloatingLine(`סכום תשלום ראשון`, session.fixedNumber(Number(creditCard.first_payment) / 100, 2) + currency_hebrew, 30)
						aThis.printFloatingLine(`ועוד ${Number(creditCard.payments_count) - 1} תשלומים של`,
							session.fixedNumber(Number(creditCard.fixed_amount) / 100, 2) + currency_hebrew, 30)
					}
				}
				aThis.printLine(` `);
			}
			function printPickupSlip(sale, saleItems, salePayments, isSource, pickups, company, zebra, posNumber) {
				let aThis = printer;
				printSlipHeader(sale, company);

				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);

				aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
					`${i18next.t('pickupFormDialog.pickup')}: `)

				aThis.printLine(" ");


				let _picup_details = JSON.parse(sale.jsondata);

				if (Service.DutyFree.isPickupEnabled()) {
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.centerAlignment);
	
					// PRINT OF BARCODE
					aThis.printBarcode(sale.invoiceSequence, `t2`);

				}

				zebra.append(aThis.allowComponents.init);
				aThis.printLine(` `);


				zebra.append(aThis.allowComponents.init);
				printItemsHeader(false);
				let totalPrice = 0;

				for (var i = 0; i < pickups.length; i++) {
					printInvoiceItem(pickups[i], { print_price: true });
					if (i + 1 < saleItems.length) {
						aThis.printLine(" ");
					}

					let itemPrice = pickups[i].unitPrice * pickups[i].quantity;
					if (pickups[i].discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
						let itemDiscount = saleItemHelper.getSaleItemDiscountAmount(pickups[i]);
						itemPrice -= itemDiscount;
					}
					totalPrice += itemPrice;

				}

				aThis.printLine(i18next.t('pickupFormDialog.totalPickup') + " : " + session.fixedNumber(totalPrice));

				aThis.printHr();
				zebra.append(aThis.allowComponents.rightAlignment);

				if (Service.DutyFree.isPickupEnabled()) {
					aThis.printLine(i18next.t('pickupFormDialog.passportDetails') + ":");
					for (var i = 0; i < _picup_details.flights.length; i++) {
						aThis.printLine(_picup_details.flights[i].id);
					}
					zebra.append(aThis.allowComponents.init);
					aThis.printHr();
	
					zebra.append(aThis.allowComponents.init);
					aThis.printLine(" ");
	
					if (!_.isEmpty(_picup_details.pickup)) {
						aThis.printFloatingLine(`${i18next.t("pickupFormDialog.passport")}:`, _picup_details.pickup.id);
						aThis.printFloatingLine(`${i18next.t("printing.phone")}:`, _picup_details.pickup.phone);
						aThis.printFloatingLine(`${i18next.t("pickupFormDialog.returnDate")}:`, _picup_details.pickup.ReturnDt);
						aThis.printFloatingLine(`${i18next.t("pickupFormDialog.flight")}:`, _picup_details.pickup.flight);
						aThis.printFloatingLine(`${i18next.t("pickupFormDialog.packaging")}:`,
							String(PositiveTS.Service.Pickup.getPickupPackagingNumberFromJsonData(_picup_details)));
	
						if (typeof _picup_details.pickup.notes == 'string' && _picup_details.pickup.notes.trim()) {
							zebra.append(aThis.allowComponents.rightAlignment);
							zebra.append(aThis.allowComponents.bold);
							aThis.printLine(`${i18next.t("pickupFormDialog.notes")}:`);
							aThis.printLine(_picup_details.pickup.notes);
							zebra.append(aThis.allowComponents.init);
						}
					}
				} else {
					aThis.printLine(" ");
					aThis.printFloatingLine(`${i18next.t("pickupFormDialog.passengerName")}:`, _picup_details.pickup.passengerName);
					aThis.printFloatingLine(`${i18next.t("pickupFormDialog.roomNumber")}:`, _picup_details.pickup.roomNumber);
				}


				aThis.printLine(` `);
				aThis.printLine(` `);



				aThis.printLine(`${i18next.t("printing.customerName")}:    ________________`);
				aThis.printLine(` `);
				aThis.printLine(`${i18next.t("printing.phone")}:      ________________`);
				aThis.printLine(` `);
				aThis.printLine(i18next.t('pickupFormDialog.declaringSignature'));
				aThis.printLine(i18next.t('pickupFormDialog.acceptingItems') + ':');
				aThis.printLine(` `);
				aThis.printLine(`${i18next.t('printing.signature')}:     _________________`);


				if (Service.DutyFree.isPickupEnabled()) {
					aThis.printHr();

					//aThis.printLine(sale.invoiceSequence);
					aThis.printBarcode(sale.invoiceSequence, `t2`);
					zebra.append(aThis.allowComponents.init);
					aThis.printLine(` `);
	
					aThis.printLine(` `);
	
					if (isSource === true) {
						// PRINT OF BARCODE
						aThis.printBarcode(_picup_details.pickup.DuetyFreeBarcode, `t2`);
						aThis.printBarcode(_picup_details.pickup.DuetyFreeBarcode2, `t2`);
						zebra.append(aThis.allowComponents.init);
					}	
				}

				aThis.printLine(` `);

				printer.printLineCut();
				zebra.append(aThis.allowComponents.init);
			}
			function printClubMemberDescriptionIfExists(sale, saleItems, salePayments) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				var svcCustomerClub = new PositiveTS.Service.CustomerClub(sale, salePayments, saleItems);
				var dictCustomer: any = {};

				if (svcCustomerClub.isCurrentCustomerExists()) {
					dictCustomer = svcCustomerClub.getCurrentSelectedCustomer();
				} else {
					return;
				}
				if (dictCustomer.is_cibus_tenbis) { return; }

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				aThis.printHr();

				const saleHasCustomerHakafa = PositiveTS.Service.Hakafa.hasCustomer(sale);

				if (saleHasCustomerHakafa && PositiveTS.Service.Hakafa.needPrintHakafaCustomerNumber()) {
					aThis.printLine(`${i18next.t("printing.customerNumber")}: ${posUtils.null2empty(dictCustomer.customer_number)}`)
				}

				let customerDetailsToPrint  = posUtils.null2empty(dictCustomer.s_member_no) + ` ` + 
							   posUtils.null2empty(dictCustomer.s_first_name) + ` ` + 
							   posUtils.null2empty(dictCustomer.s_last_name);


				if (customerDetailsToPrint .trim().length > 0) {
					aThis.printLine(`${i18next.t("printing.customer")}: ` + customerDetailsToPrint );
				}


				if (PositiveTS.Service.CustomerClub.CUSTOMER_CLUBS[dictCustomer.clubName]) {
					aThis.printLine(`מועדון: ` +
						PositiveTS.Service.CustomerClub.CUSTOMER_CLUBS[dictCustomer.clubName].description);
				}

				if (dictCustomer.address && dictCustomer.address.trim()) {
					aThis.printLine(`כתובת: ` +
						dictCustomer.address);
				}

				if (dictCustomer.s_phone_number_1 && String(dictCustomer.s_phone_number_1 || '').trim()) {
					aThis.printLine(`${i18next.t("printing.phone")}: ` +
						dictCustomer.s_phone_number_1);
				}

				if (saleHasCustomerHakafa) {
					const invoiceTypesCredit = [
							Storage.Entity.Sequence.TYPE_CREDIT_INVOICE,
							Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV,
							Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV
					]
					aThis.printLine(`${i18next.t("printing.customerIdNumber")}: ` + dictCustomer.s_id_number);

					if (!jsonConfig.getVal(jsonConfig.KEYS.hideCustomerBalance) && !invoiceTypesCredit.includes(sale.invoiceType) &&
						 !posUtils.isBlank(PositiveTS.Service.Hakafa.getSaleCustomerAmount(sale))) {
						aThis.printLine(`יתרתך הנוכחית:  ` + session.fixedNumber(
							PositiveTS.Service.Hakafa.expectedCustomerBalance(sale, posVC.Specialitems, saleItems, salePayments)
						));
					}
				}

				aThis.printHr();
			}
			function printDeliveryInformation(sale) {
				const saleData = JSON.parse(sale.jsondata);
				let deliveryInfo = saleData.delivery;
				let orderDifferences = saleData.orderDifferences;

				let aThis = printer;
				var zebra = aThis.jzebra;

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				if(!posUtils.isBlank(orderDifferences) && orderDifferences.length > 0) {
					zebra.append(aThis.allowComponents.bigFont);
					aThis.printHr();
					aThis.printLine(`${i18next.t('externalOrdersDifferences.title')}`);
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.rightAlignment);

					let widths = [printer.lineLength - 11, 5, 6];
					printer.printHeader(printer.getTableRow([i18next.t("externalOrdersDifferences.item"), i18next.t("externalOrdersDifferences.quantity"), ""], widths));

					orderDifferences.forEach(item => {
						const reason = item.reason == Service.Delivery.DifferenceReason.Alter ? i18next.t("externalOrdersDifferences.alterior") :i18next.t("externalOrdersDifferences.missing");
						printer.printTableRow([item.description, String(item.quantity), reason], widths);
					});
				}

				aThis.printHr();
				
				if (deliveryInfo.ordererName) {
					zebra.append(aThis.allowComponents.bigFont);
					aThis.printMultyLine(deliveryInfo.ordererName, 24);
				}
				
				zebra.append(aThis.allowComponents.newLine);
				if (!posUtils.isBlank(saleData.delivery.deliveryTime)) {
					aThis.printLine(`${i18next.t('delivery.deliveryTimeTitle')}: ${saleData.delivery.deliveryTime}`);
					zebra.append(aThis.allowComponents.newLine);
				}
				if (!posUtils.isBlank(deliveryInfo.ordererCallerRemarks)) {
					aThis.printMultyLine(deliveryInfo.ordererCallerRemarks, aThis.lineLengthBigFont);
					zebra.append(aThis.allowComponents.newLine);
				}

				if (!posUtils.isBlank(deliveryInfo.ordererDeliveryRemarks)) {
					aThis.printMultyLine(deliveryInfo.ordererDeliveryRemarks, aThis.lineLengthBigFont);
					zebra.append(aThis.allowComponents.newLine);
				}
				zebra.append(aThis.allowComponents.newLine);

				if (deliveryInfo.deliveryAddress && (deliveryInfo.deliveryType == Service.Delivery.DeliveryType.externalDelivery
					|| deliveryInfo.deliveryType == Service.Delivery.DeliveryType.delivery ||
					deliveryInfo.deliveryType == Service.Delivery.DeliveryType.externalPaiditDelivery
					|| deliveryInfo.deliveryType == Service.Delivery.DeliveryType.externalWolt
					|| deliveryInfo.deliveryType == Service.Delivery.DeliveryType.externalCibus || deliveryInfo.deliveryType == Service.Delivery.DeliveryType.externalMishloha)) {

					let address = deliveryInfo.deliveryAddress.address;
					if (deliveryInfo.deliveryAddress.address.name) {
						address = deliveryInfo.deliveryAddress.address.name;
					}



					let city = deliveryInfo.deliveryAddress.city;

					if (deliveryInfo.deliveryAddress.city.name) {
						city = deliveryInfo.deliveryAddress.city.name;
					}

					let houseNumber = ""
					if(!posUtils.isBlank(deliveryInfo.deliveryAddress.house_number)) {
						houseNumber = String(deliveryInfo.deliveryAddress.house_number);
					}

					aThis.printMultyLine(`${i18next.t('delivery.address')}: ${address} ${houseNumber ? houseNumber : ''}`, printer.lineLengthBigFont);

					if (deliveryInfo.deliveryAddress.city && (typeof deliveryInfo.deliveryAddress.city === 'string' || !posUtils.isBlank(deliveryInfo.deliveryAddress.city.name))) {
						aThis.printLine(`${i18next.t('delivery.city')}: ${city}`);
					}

					if (deliveryInfo.deliveryAddress.house_floor) {
						aThis.printLine(`${i18next.t('delivery.floor')}: ${deliveryInfo.deliveryAddress.house_floor}`);
					}
					if (deliveryInfo.deliveryAddress.apartment) {
						aThis.printLine(`${i18next.t('delivery.apartment')}: ${deliveryInfo.deliveryAddress.apartment}`);
					}
					if (deliveryInfo.deliveryAddress.house_entrance) {
						aThis.printLine(`${i18next.t('delivery.entrance')}: ${deliveryInfo.deliveryAddress.house_entrance}`);
					}

					zebra.append(aThis.allowComponents.newLine);

					if (deliveryInfo.deliveryAddress.name) {
						aThis.printMultyLine(`${i18next.t('delivery.nameOfPlace')}: ${deliveryInfo.deliveryAddress.name}`, printer.lineLengthBigFont);
					}
				}

				if (!posUtils.isBlank(deliveryInfo.ordererPhone)){
					zebra.append(aThis.allowComponents.newLine);
					aThis.printLine( `${i18next.t('delivery.phoneShort')}: ${deliveryInfo.ordererPhone}`);
				}
				


				if (deliveryInfo.ordererDeliveryRemarks) {
					zebra.append(aThis.allowComponents.newLine);
					aThis.printMultyLine(deliveryInfo.ordererDeliveryRemarks, printer.lineLengthBigFont);
				}

				if (deliveryInfo.ordererDeliveryCutlery) {
					zebra.append(aThis.allowComponents.newLine);
					aThis.printMultyLine(i18next.t(`printing.cutlery`), printer.lineLengthBigFont);
				}

				zebra.append(aThis.allowComponents.init);
			}
			function printClubMemberFooter(sale, saleItems, salePayments) {

				let aThis = printer;
				var zebra = aThis.jzebra;
				var svcCustomerClub = new PositiveTS.Service.CustomerClub(sale, salePayments, saleItems);
				var dictCustomer: any = {};
				if (svcCustomerClub.isCurrentCustomerExists()) {
					dictCustomer = svcCustomerClub.getCurrentSelectedCustomer();
				} else {
					return;
				}
				if (!PositiveTS.Service.CustomerClub.CUSTOMER_CLUBS[dictCustomer.clubName]) {
					return;
				}

				if (dictCustomer.clubName == "valuecard") {
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.rightAlignment);
					aThis.printHr();

					zebra.append(aThis.allowComponents.centerAlignment);
					zebra.append(aThis.allowComponents.bold);
					aThis.printLine(" ");
					aThis.printLine("*** " + i18next.t('clubMembershipCard')
						+ " ***");

					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.rightAlignment);

					let jsondata = JSON.parse(posVC.sale.jsondata)
					if (jsondata.value_card_customer && jsondata.value_card_customer.printMessage) {
						let ary_remark = jsondata.value_card_customer.printMessage.split("\n")
						for (let iary_remark = 0; iary_remark < ary_remark.length; iary_remark++) {
							aThis.printLine(ary_remark[iary_remark]);
						}
					}


					return;
				}

				if (dictCustomer.clubName == "simplyclub") {
					if (!PositiveTS.Service.SimplyClubService.isTranEndResExistsOnSale(posVC.sale)) {
						return;
					}
					let data = PositiveTS.Service.SimplyClubService.getTranEndResOnSale(posVC.sale);
					if (!data["Vouchers"]) {
						return;
					}
					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.rightAlignment);
					aThis.printHr();

					zebra.append(aThis.allowComponents.centerAlignment);
					zebra.append(aThis.allowComponents.bold);
					aThis.printLine(" ");
					aThis.printLine("*** " + i18next.t('clubMembershipCard')
						+ " ***");

					zebra.append(aThis.allowComponents.init);
					zebra.append(aThis.allowComponents.rightAlignment);
					for (const voucher of data["Vouchers"]) {
						if (!voucher["VoucherText"]) continue;
						for (const voucherText of voucher["VoucherText"]) {
							aThis.printLine(voucherText);
						}
					}
					return;
				}

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				aThis.printLine("מועדון: " +
					PositiveTS.Service.CustomerClub.CUSTOMER_CLUBS[dictCustomer.clubName].description);


				if (jsonConfig.getVal(jsonConfig.KEYS.fullClub)) {

					let customerDetailsToPrint = posUtils.null2empty(dictCustomer.s_member_no) + ` ` + 
											 posUtils.null2empty(dictCustomer.s_first_name) + ` ` + 
											 posUtils.null2empty(dictCustomer.s_last_name);

					if (customerDetailsToPrint.trim().length > 0) {
						aThis.printLine(`${i18next.t('printing.cardNumber')}: ` + customerDetailsToPrint);
					} else if (!posUtils.isBlank(dictCustomer.s_phone_number_1)) {
						aThis.printLine(`${i18next.t('printing.phone')}: ` + dictCustomer.s_phone_number_1);
					}

					if (session.company.tenantID != '9') {

						aThis.printLine(`במכירה זו צברת: ` +
							session.fixedNumber(dictCustomer.points_accumulated_in_sale || 0)
						);
						aThis.printLine(`במכירה זו ממשת: ` +
							session.fixedNumber(svcCustomerClub.getUsedPoints() || 0)
						);

						var newPointBalance = parseFloat(session.fixedNumber(dictCustomer.points_accumulated_in_sale || 0)) -
							parseFloat(session.fixedNumber(dictCustomer.points_used_in_sale || 0)) +
							parseFloat(session.fixedNumber(dictCustomer.cust_points || 0));

						aThis.printLine(`יתרת הנקודות: ` + session.fixedNumber(newPointBalance) || 0);
						if (dictCustomer.confirmation_number) {
							aThis.printLine(`${i18next.t('printing.approvalNumber')}: ` + dictCustomer.confirmation_number);
						} else {
							aThis.printLine(`יתרתך תתעדכן תוך 24 שעות`);
						}
					} else {
						aThis.printLine(`יתרתך תתעדכן תוך 24 שעות`);
					}

					aThis.printLine(`)`
						+ `ניתן למימוש בהתאם לתקנון`
						+ `(`);

					if (dictCustomer.msg_for_slip) {
						aThis.printMultyLine(dictCustomer.msg_for_slip);
					}

				}


				zebra.append(aThis.allowComponents.init);
			}
			function printValu(sale, saleItems, salePayments) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				var currentPayment, currentData;
				for (let currentPayment of salePayments) {

					if (currentPayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT ||
						currentPayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
						let data = JSON.parse(currentPayment.data);
						for (let currentData of data) {
							if (currentData.smartVoucherType === `Valu`) {

								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.rightAlignment);
								aThis.printHr();
								aThis.printLine(`יתרתך בכרטיס מספר ` +
									JSON.parse(currentData.response).card.number +
									` היא: ` +
									JSON.parse(currentData.response).card.current_balance);
							}
						}
					}
				}
			}
			function printGppAndValueCard(sale, saleItems, salePayments) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				var currentPayment, currentData;
				var barCodeStr = function (barcode) {
					var split;
					if (!barcode) {
						return ``;
					}
					split = barcode.split(`=`);
					barcode = split[0];
					if (barcode.length <= 11) {
						return barcode.substring(0, barcode.length - 3);
					}
					else {
						let resStr = ``;
						var reduceCvvLength = (split.length > 1) ? 4 : 3;
						for (var i = 0; i < (barcode.length - reduceCvvLength - 4); i++) {
							resStr += `*`;
						}
						resStr += barcode.substring(barcode.length - 4 - reduceCvvLength, barcode.length - reduceCvvLength);
						return resStr;
					}
				}

				for (var i = 0; i < salePayments.length; i++) {

					currentPayment = salePayments[i];
					if (currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
						let data = JSON.parse(currentPayment.data);
						for (var j = 0; j < data.length; j++) {
							currentData = data[j];
							if (currentData.smartVoucherType === `GPP`) {
								zebra.append(aThis.allowComponents.centerAlignment);
								zebra.append(aThis.allowComponents.bold);
								aThis.printLine(` `);
								aThis.printLine(`*** ` + (currentData.actionType === `withdraw` ? `קבלת תשלום` : `ביטול מימוש`) + ` ***`);
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.rightAlignment);
								aThis.printLine(`${i18next.t('printing.cardNumber')}: ` + barCodeStr(currentData.barCode));
								aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + currentData.actionReference);
								aThis.printLine(`${i18next.t('smartVoucher.actionSum')}: ` + currentData.amount.toFixed(2));
								aThis.printLine(`${i18next.t('smartVoucher.currentBalance')}: ` + String(currentData.balance));
								aThis.printLine(" ");
							}

							if (currentData.smartVoucherType === "Multipass" &&
								sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
								let resultMessage = JSON.parse(currentData.response).result_message;
								if (resultMessage) {
									zebra.append(aThis.allowComponents.centerAlignment);
									zebra.append(aThis.allowComponents.bold);
									aThis.printLine(" ");
									aThis.printLine("*** " + (currentData.actionType === "withdraw" ? "קבלת תשלום" : "ביטול מימוש") + " ***");
									zebra.append(aThis.allowComponents.init);
									zebra.append(aThis.allowComponents.rightAlignment);

									for (let lstr of resultMessage.split("<br>")) {
										aThis.printLine(lstr);
									}
								}
							}
						}
					}


					if (currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
						let data = JSON.parse(currentPayment.data);
						for (var j = 0; j < data.length; j++) {
							currentData = data[j];
							if (currentData.smartVoucherType === `VALUE_CARD`) {
								zebra.append(aThis.allowComponents.centerAlignment);
								zebra.append(aThis.allowComponents.bold);
								aThis.printLine(` `);
								aThis.printLine(`*** ` + (currentData.actionType === `withdraw` ? `קבלת תשלום` : `ביטול מימוש`) + ` ***`);
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.rightAlignment);
								aThis.printLine(`${i18next.t('printing.cardNumber')}: ` + barCodeStr(currentData.barCode));
								aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + currentData.actionReference);
								aThis.printLine(`${i18next.t('smartVoucher.actionSum')}: ` + currentData.amount.toFixed(2));

								if (currentData.amount > 0) {
									let ary_remark = currentData.printMessage.split(`\n`)
									for (let iary_remark = 0; iary_remark < ary_remark.length; iary_remark++) {
										aThis.printLine(ary_remark[iary_remark]);
									}
								}


							}
						}
					}



					if (currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_GPP) {
						let data = JSON.parse(currentPayment.data);
						for (var j = 0; j < data.length; j++) {
							currentData = data[j];
							zebra.append(aThis.allowComponents.centerAlignment);
							zebra.append(aThis.allowComponents.bold);
							aThis.printLine(` `);
							aThis.printLine(`*** ` + (currentData.actionType === Service.SmartVoucherActionTypes.DEPOSIT ? i18next.t('smartVoucher.deposit') : i18next.t('smartVoucher.cancelDeposit')) + ` ***`);
							zebra.append(aThis.allowComponents.init);
							zebra.append(aThis.allowComponents.rightAlignment);
							aThis.printLine(`${i18next.t('printing.cardNumber')}: ` + barCodeStr(currentData.barCode));
							aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + currentData.actionReference);
							aThis.printLine(`${i18next.t('smartVoucher.actionSum')}: ` + String(currentData.amount));
							aThis.printLine(`${i18next.t('smartVoucher.currentBalance')}: ` + String(currentData.balance));
							aThis.printLine(` `);

						}
					}


					if (currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VALUE_CARD) {
						let data = JSON.parse(currentPayment.data);
						for (var j = 0; j < data.length; j++) {
							currentData = data[j];
							zebra.append(aThis.allowComponents.centerAlignment);
							zebra.append(aThis.allowComponents.bold);
							aThis.printLine(` `);
							aThis.printLine(`*** ` + (currentData.actionType === Service.SmartVoucherActionTypes.DEPOSIT ? i18next.t('smartVoucher.deposit') : i18next.t('smartVoucher.cancelDeposit')) + ` ***`);
							zebra.append(aThis.allowComponents.init);
							zebra.append(aThis.allowComponents.rightAlignment);
							aThis.printLine(`${i18next.t('printing.cardNumber')}: ` + barCodeStr(currentData.barCode));
							aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + currentData.actionReference);
							aThis.printLine(`${i18next.t('smartVoucher.actionSum')}: ` + String(currentData.amount));
							let ary_remark = currentData.printMessage.split(`\n`)							
							for (let iary_remark = 0; iary_remark < ary_remark.length; iary_remark++) {
								aThis.printLine(posUtils.escapeStringForHTML(ary_remark[iary_remark]));
							}



						}
					}
					let saleJsonData = JSON.parse(sale.jsondata);
					if(saleJsonData.ototTransactionId){
						aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + saleJsonData.ototTransactionId);
					}
					if ((currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT) ||
						(currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER)) {
						let data = JSON.parse(currentPayment.data);
						for (var j = 0; j < data.length; j++) {
							if(data[j].smartVoucherType === 'OTOT' || (currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT)){
								currentData = data[j];
								zebra.append(aThis.allowComponents.centerAlignment);
								zebra.append(aThis.allowComponents.bold);
								aThis.printLine(` `);
								if(currentPayment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT){
									aThis.printLine(`*** ` + (currentData.actionType === Service.SmartVoucherActionTypes.DEPOSIT ? i18next.t('smartVoucher.deposit') : i18next.t('smartVoucher.cancelDeposit')) + ` ***`);
								}
								zebra.append(aThis.allowComponents.init);
								zebra.append(aThis.allowComponents.rightAlignment);
								// aThis.printLine(`${i18next.t('printing.cardNumber')}: ` + currentData.barCode); // Dont know what should be printeed
								aThis.printLine(`${i18next.t('smartVoucher.transactionNumber')}: ` + currentData.actionReference);
								aThis.printLine(`${i18next.t('smartVoucher.actionSum')}: ` + String(currentData.amount));
								aThis.printLine(`${i18next.t('smartVoucher.currentBalance')}: ` + String(currentData.balance));
							}
						}
					}


				}

			}
			function printInvoiceA4(sale, saleItems, salePayments, isSource, addiotionalText) {

				if (session.isAndroid || jsonConfig.getVal(jsonConfig.KEYS.printWithPrimaryPos)) {
					return app.promiseShowAlert({
						header: i18next.t('error'),
						content: i18next.t('printing.a4printNotSupported', {at: session.isAndroid ? i18next.t('android') : i18next.t('primaryPosPrinter')}),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true,
						noHandleEnterEscape: true,
					  });
				}

				var jqTemplateString = JST.htmlInvoice();
				var d = new PositiveTS.Printing.HtmlInvoice.HtmlInvoice()
				return d.print(sale, saleItems, salePayments, isSource, jqTemplateString)
			}
			function printInvoiceRaw(sale, saleItems, salePayments, isSource, addiotionalText) {
				let aThis = printer;
				var printCalculatedVars: any = {};
				if (sale.parentSaleStoreID) {
					return PositiveTS.Storage.Entity.Store.getStoreName(sale.tenantID, sale.parentSaleCompanyID, sale.parentSaleStoreID)
						.then(function (parentSaleStoreName) {
							printCalculatedVars.parentSaleStoreName = parentSaleStoreName;
							return printInvoiceFactory(sale, saleItems, salePayments, isSource, printCalculatedVars, addiotionalText);
						})
				} else {
					return printInvoiceFactory(sale, saleItems, salePayments, isSource, printCalculatedVars, addiotionalText);
				}
			}
			function getAryCreditInvoiceVoucherCredited(sale, salePayments) {
				var _return = [];
				if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
					var arySaleCrediPaymentMethodVoucher;
					for (var i = 0; i < salePayments.length; i++) {
						if (salePayments[i].method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
							arySaleCrediPaymentMethodVoucher = JSON.parse(salePayments[i].data);
							for (var j = 0; j < arySaleCrediPaymentMethodVoucher.length; j++) {
								_return.push(arySaleCrediPaymentMethodVoucher[j]);
							}
						}
					}
				}
				return _return;
			}
			function printCreditInvoiceVoucherCredited(company, sale, salePayments) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				var aryCreditInvoiceVoucherCredited = getAryCreditInvoiceVoucherCredited(sale, salePayments);
				if (aryCreditInvoiceVoucherCredited.length === 0) {
					return;
				}
				var row;

				zebra.append(aThis.allowComponents.init);
				printSlipHeader(sale, company);
				zebra.append(aThis.allowComponents.centerAlignment);
				zebra.append(aThis.allowComponents.bold);

				aThis.printTextWithPrintLineText(getSaleNumberFormated(sale),
					`תעודת זיכוי מספר: `)


				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);
				aThis.printHr();
				aThis.printLine(`לקוח יקר להלן מספרי השובר/ים,`);
				aThis.printLine(`העומד/ים לרשותך לקניה הבא`);
				for (var i = 0; i < aryCreditInvoiceVoucherCredited.length; i++) {
					var row = aryCreditInvoiceVoucherCredited[i];
					aThis.printFloatingLine(`קוד התו`, row.voucher_type_id);
					aThis.printFloatingLine(`תאור התו`, row.creditType);
					aThis.printFloatingLineWithPrice(`${i18next.t('printing.sum')}`, Math.abs(row.amount));
					if (row.barCode) {
						aThis.printFloatingLine(`ברקוד`, row.barCode);
						aThis.printBarcode(row.barCode, aThis.printerType, false)
					}
				}

				aThis.printLine(`יש לשמור על מספר/ים שובר/ים`);
				aThis.printLine(`אלו לקניה הבאה.`);
				aThis.printHr();
				aThis.printLineCut();

			}
			function printCaveretSlip(sale, company, payment) {
				let allPayments = JSON.parse(payment.data);
				for (let i = 0; i < allPayments.length; i++) {
					if (posUtils.isNullOrUndefinedOrEmptyString(allPayments[i].smartVoucherType) ||
						allPayments[i].smartVoucherType != Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT) {
						continue;
					}
					let data = JSON.parse(payment.data)[i];
					let voucherData = JSON.parse(data.data);
					let docType = "תעודת משלוח";
					if (voucherData.DocType == 1) {
						docType = "חשבונית מס קבלה";
					}

					if (sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
						docType = docType + " זכות"
					}
					printSlipHeader(sale, company);
					printer.jzebra.append(printer.allowComponents.init);
					printer.jzebra.append(printer.allowComponents.centerAlignment);
					printer.jzebra.append(printer.allowComponents.bold);
					printer.printTextWithPrintLineText(getSaleNumberFormated(sale),
						` ספח חתימה - תשלום כוורת `)
					printer.jzebra.append(printer.allowComponents.init);
					printer.jzebra.append(printer.allowComponents.rightAlignment);
					printer.jzebra.append(printer.allowComponents.newLine);
					let svcCustomerClub = new PositiveTS.Service.CustomerClub(sale, sale.saleItems, sale.salePayments);
					let dictCustomer: any = {};
					if (svcCustomerClub.isCurrentCustomerExists()) {
						dictCustomer = svcCustomerClub.getCurrentSelectedCustomer();
						printer.printLine(`${i18next.t("printing.customer")}: ` + posUtils.null2empty(dictCustomer.s_member_no) + " " + posUtils.null2empty(dictCustomer.s_first_name) + " " + posUtils.null2empty(dictCustomer.s_last_name));
					}
					printer.printLine(`מספר אישור כוורת: ${data.confirmationNum} )${docType}(`);
					printer.jzebra.append(printer.allowComponents.newLine);
					printer.printHeader(printer.getTableRow(["קוד תקציב", "סכום חיוב"], [printer.itemCodeLength, printer.lineLength - printer.itemCodeLength]));
					let budgetsSum = 0;
					for (let budget of data.budgetsUsed) {
						let amountToCharge = Number(budget.AmountToCharge)
						if (sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
							amountToCharge = amountToCharge * -1
							//this is a fix to partiel credit, we don't support budgets mangment in partiel credit
							//as cavert doesn't support it and they confirmd that only sales with
							//one budget will be allowd for partiel credit
							//so this is small-not-so-pretty priting fix
							if(data.amount < 0 && amountToCharge < 0){
								amountToCharge = data.amount
							}
						}
						
						budgetsSum += amountToCharge
						printer.printTableEnglishRow([session.fixedNumber(amountToCharge), String(budget.BudgetID)], [printer.itemCodeLength, printer.lineLength - printer.itemCodeLength].reverse());
					}
					//see above comment
					if(sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE && budgetsSum != data.amount){
						budgetsSum = data.amount
					}
					printer.printHr();
					printer.printTableRow([`סה"כ`, session.fixedNumber(budgetsSum)], [printer.itemCodeLength, printer.lineLength - printer.itemCodeLength]);


					printer.jzebra.append(printer.allowComponents.rightAlignment);
					printer.jzebra.append(printer.allowComponents.newLine);
					printer.printLine(`${i18next.t("printing.phone")}: ______________________`);
					printer.jzebra.append(printer.allowComponents.newLine);
					printer.printLine(`${i18next.t("printing.signature")}: ______________________`);
					printer.printLineCut();
					printer.jzebra.append(printer.allowComponents.init);
				}

			}
			function printCouponSlip(totalPrice, salePayments, sale, company, isSource): Promise<any> {
				if(!isSource) { 
					return
				}

				// Hardware setup
				if (printer.jzebra === null) {
					return Promise.reject(``);
				}
				let aThis = printer;
				var zebra = aThis.jzebra;
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.rightAlignment);

				var couponBarcode = JSON.parse(sale.jsondata)['voucherBarcode'] || Service.Coupon.generateCouponNumber()

				return PositiveTS.Service.Coupon.getEffectiveCouponsForPrinting(salePayments, totalPrice)
					.then((data) => {
						if (data.length === 0) { return; }

						zebra.append(aThis.allowComponents.init);
						printSlipHeader(sale, company);
						zebra.append(aThis.allowComponents.centerAlignment);
						zebra.append(aThis.allowComponents.bold);
						aThis.printText(sale.invoiceSequence.toString());
						aThis.printLine(`קופון מס: `);
						aThis.printLine(` `);
						zebra.append(aThis.allowComponents.init);
						zebra.append(aThis.allowComponents.rightAlignment);

						// for each item to print
						for (var i = 0; i < data.length; i++) {
							// Split Lines and print each line using printline
							var ary_remark = data[i].remark.split(`\n`);
							for (var iary_remark = 0; iary_remark < ary_remark.length; iary_remark++) {
								aThis.printLine(ary_remark[iary_remark]);
							}
							aThis.printLine(" ");
							aThis.printLine("קוד קניה : " + couponBarcode[i]);
							aThis.printLine(" ");

							aThis.printBarcode(couponBarcode[i], `t2`);
							printer.printLineCut();
						}
					});

            }
			function printInvoicePayment(payment, paymentTypes, isCreditSale?) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				var amount = payment.amount;
				if (amount < 0) {
					amount = -amount;
				}

				switch (payment.method) {
					case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
					case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
					case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
					case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER:

						// If multi currency
						// Print each line
						if (PositiveTS.Service.MultiCurr.getInstance().invoicePrintSlipCashParsing(aThis, payment, paymentTypes, amount)) {
							break;
						}

					case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
						aThis.printFloatingLineWithPrice(paymentTypes[payment.method].displayName, Base.numberWithCommas(session.fixedNumber(amount, 2, true)));
						break;
					case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
						break;
					case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
						var vouchers = JSON.parse(payment.data);
						for (var i = 0; i < vouchers.length; i++) {
							let creditType: string = vouchers[i].creditType;
							creditType = creditType == "סיבוס" ? "פלאקסי" : creditType;
							if (isCreditSale) {
								aThis.printFloatingLineWithPrice(creditType, session.fixedNumber(vouchers[i].amount * -1, 2, true));
							} else {
								aThis.printFloatingLineWithPrice(creditType, session.fixedNumber(vouchers[i].amount, 2, true));
							}
							let smartVoucherType = vouchers[i].smartVoucherType;
							if(smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH){
								aThis.printText(vouchers[i].transactionId)
								aThis.printLine(``)
							}
							if (session.pos.isCaveret && PositiveTS.Service.PluxeeService.Voucher.isPluxeeCibusVoucher(vouchers[i])) {
								aThis.printFloatingLine(i18next.t('printing.approvalNumber') , vouchers[i].orderId);
							}
						};
						break;
				}
			}

			function printDetailedMultipassPointsUsed(voucherData) {
				let aThis = printer;
				let walletsData = JSON.parse(voucherData.data);

				if (posUtils.isBlank(walletsData?.points)) {
					return;
				}
				let pointsUsed = {}
				for (const point of walletsData.points) {
					if (!pointsUsed[point.Type]) {
						pointsUsed[point.Type] = 0
					}
					pointsUsed[point.Type] += Number(point.Amount) / 100;
				}
				let pointsBudget = []
				let allPoints ={
					usedBudget: 0,
					currentlyBudget: 0,
					fullBudget: 0
				}
				walletsData.pointsBudgetList.forEach(budget => {
					if(pointsUsed.hasOwnProperty(budget.Type)){

					const pointBudgetData = {
						currentlyBudget: Number(budget.Amount) /100,
						usedBudget: pointsUsed[budget.Type],
						budgetType: budget.Type,
						fullBudget: (Number(budget.Amount) /100) + (pointsUsed[budget.Type])
					} 
					allPoints.usedBudget += pointsUsed[budget.Type]
					allPoints.currentlyBudget += Number(budget.Amount) /100
					allPoints.fullBudget += (Number(budget.Amount) /100) + (pointsUsed[budget.Type])
					pointsBudget.push(pointBudgetData)
					}
				});

				let zebra = aThis.jzebra;

				aThis.printLine(`\n`)
				let widths = [12, 11, 10, 11];
				
				zebra.append(printer.allowComponents.centerAlignment);
				zebra.append(printer.allowComponents.bold);
				aThis.printLine(i18next.t('multipassPolice.pointsDetails'))
				aThis.printLine(`\n`)

				aThis.printTableRow([i18next.t('multipassPolice.pointsType'), 
					i18next.t('multipassPolice.startSalePointsAmount'), 
					i18next.t('multipassPolice.amountUsedInSale'), 
					i18next.t('multipassPolice.endSalePointsAmount')], widths
				);
				aThis.printHr();
				pointsBudget.forEach(budget => {
					aThis.printTableRow([i18next.t('multipassPolice.pointTypes.' + budget.budgetType), session.fixedNumber(budget.fullBudget, 2, true), session.fixedNumber(budget.usedBudget, 2, true), session.fixedNumber(budget.currentlyBudget, 2, true)], widths);
				});
				aThis.printHr();
				aThis.printTableRow([i18next.t('multipassPolice.totalPoints'), session.fixedNumber(allPoints.fullBudget, 2, true), session.fixedNumber(allPoints.usedBudget, 2, true), session.fixedNumber(allPoints.currentlyBudget, 2, true)], widths);
				aThis.printLine(`\n`)
				aThis.printFloatingLine(i18next.t('multipassPolice.cardNo'), walletsData.cardNumber);
				aThis.printFloatingLine(i18next.t('multipassPolice.approvmentNumber'), walletsData.transactionId);
				aThis.printLine(`\n`)
			}

			function getQuantityText(item, htmlPrinting = false) {

				if (htmlPrinting) {
					if (item.hasWeight) {
						if (Math.abs(item.quantity) < 1 && Math.abs(item.quantity) > 0) {
							// if(printer.isSunmi()){
							// 	return item.quantity  * 1000 + " X גרם ";
							// }
							return `X ${item.quantity * 1000} ${i18next.t('weights.g')}`;

						} else {
							// if(printer.isSunmi()){
							// 	return  ` ` + "קג`" + ` X ` + item.quantity;
							// }
							return `X ${item.quantity} ${i18next.t('weights.kg')}`;
						}
	
					} else {
						return ` X ${item.quantity}`;
					}
				}

				if (item.hasWeight) {
					if (Math.abs(item.quantity) < 1 && Math.abs(item.quantity) > 0) {
						// if(printer.isSunmi()){
						// 	return item.quantity  * 1000 + " X גרם ";
						// }
						return item.quantity * 1000 + ` ` + i18next.t('weights.g') + ` X `;
					} else {
						// if(printer.isSunmi()){
						// 	return  ` ` + "קג`" + ` X ` + item.quantity;
						// }
						return item.quantity + ` ` + i18next.t('weights.kg') + ` X `;
					}

				} else {
					return item.quantity + ` X `;
				}

			}
			function printReplacementItemGeneric(item, itemQuantity, sale, widths) {
				if (session.pos.isRoshemet) {
					printReplacementItemNew(item, itemQuantity, sale, widths);
				}
				else {
					printReplacementItem(item, itemQuantity, sale);
				}
			}
			function printReplacementItemNew(item, itemQuantity, sale, widths) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				var description = item.itemDescription;
				var code = item.itemCode;
				var price = item.unitPrice * item.quantity;
				let priceCode = (session.fixedFloat(session.fixedFloat(price) * 100)).toString() + `456`;
				if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL || parseInt(sale.totalDiscount) > 0) {
					var discountPrice = saleItemHelper.getSaleItemDiscountAmount(item, itemQuantity);
					//aThis.printText(`:` + (session.fixedFloat(session.fixedFloat(price - discountPrice) * 100)).toString() + `456`);
					if (discountPrice > 0) {
						priceCode = ((price - discountPrice) * 100) + `456`;
					}
					else {
						// printer.Used to print the total percentage of discount on invoice
						// however printer.was also incorrect, since it did not consider `no invoice discount`
						// (sale.discountPercent === `-1` ) and did not show the price but the precentage
						//aThis.printText(`:` + (((1 - parseFloat(sale.discountPercent / 100))) * 100) + `456`);

						// price should be calculated as after Hanachat Cheshbon
						// ** Hanachat Cheshbon, applayed ONLY if no Item Discount Exists Already **
						if (parseInt(sale.discountPercent) > 0) {
							price = price * ((100 - sale.discountPercent) / 100);
						}


						// Istead just show item price with no discount
						priceCode = (session.fixedFloat(session.fixedFloat(price) * 100)).toString() + `456`;
					}

				}

				printer.printTableRow([code, description, priceCode], widths);

			}
			function printReplacementItem(item, itemQuantity, sale) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (aThis.isHTMLBasedPrinting()) {
					let data = [item.itemCode, item.itemDescription];
					let width = [1,3];


					if (item.quantity > 1) {
						data[1] = data[1]  + ' ' + getQuantityText(item, true);
					}

					aThis.printTableRow(data, width);

				} else {

					var description = item.itemDescription;
					var descriptionRegEx = new RegExp(".{1," + (aThis.itemDescriptionLength - 1) + "}", "g");
					description = description.match(descriptionRegEx);

					var code = item.itemCode;
					if (code.length > aThis.itemCodeLength - 1) {
						code = code.substring(0, aThis.itemCodeLength - 1);
					}

					var linesCount = 1;
					if (item.quantity > 1 || description.length > 1) {
						++linesCount;
					}

					var itemDescriptionSpaces = aThis.itemDescriptionLength - description[0].length;
					var itemCodeSpaces = aThis.itemCodeLength - code.length;

					aThis.printChar(` `, aThis.itemPriceLength);

					aThis.printChar(` `, itemDescriptionSpaces);
					aThis.printText(description[0]);

					aThis.printChar(` `, itemCodeSpaces);
					aThis.printLine(code);

					if (linesCount > 1) {
						if (description.length > 1) {
							aThis.printChar(` `, aThis.itemPriceLength + 1);
							aThis.printText(description[1]);
							aThis.printChar(` `, aThis.itemDescriptionLength - description[1].length - 1);
						} else {
							aThis.printChar(` `, aThis.itemPriceLength + aThis.itemDescriptionLength);
						}

						if (item.quantity !== 1 && itemQuantity !== 1) {
							var quantityText = getQuantityText(item);
							aThis.printChar(` `, aThis.itemCodeLength - quantityText.length);
							aThis.printText(quantityText);
						}

						zebra.append(`\n`);
					}

				}

				var price = item.unitPrice * item.quantity;


				aThis.printText((session.fixedFloat(session.fixedFloat(price) * 100)).toString() + `456`);


				if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL || parseInt(sale.totalDiscount) > 0) {
					var discountPrice = saleItemHelper.getSaleItemDiscountAmount(item, itemQuantity);
					//aThis.printText(`:` + (session.fixedFloat(session.fixedFloat(price - discountPrice) * 100)).toString() + `456`);
					if (discountPrice > 0) {
						aThis.printText(`:` + ((price - discountPrice) * 100) + `456`);
					}
					else {
						// printer.Used to print the total percentage of discount on invoice
						// however printer.was also incorrect, since it did not consider `no invoice discount`
						// (sale.discountPercent === `-1` ) and did not show the price but the precentage
						//aThis.printText(`:` + (((1 - parseFloat(sale.discountPercent / 100))) * 100) + `456`);

						// price should be calculated as after Hanachat Cheshbon
						// ** Hanachat Cheshbon, applayed ONLY if no Item Discount Exists Already **
						if (parseInt(sale.discountPercent) > 0) {
							price = price * ((100 - sale.discountPercent) / 100);
						}


						// Istead just show item price with no discount
						aThis.printText(`:` + (session.fixedFloat(session.fixedFloat(price) * 100)).toString() + `456`);
					}

				}

				zebra.append(`\n`);
			}
			function t1miniInvoicePrintingShananigns(tableRow, widths) {
				let description = tableRow[0]
				let price = tableRow[1]
				let qty = tableRow[2]
				let priceToPay = tableRow[3]

				if (printer.isHebrewText(description) || printer.isFirstCharDigit(description)) {
					if (priceToPay.includes('-')) {
						priceToPay = priceToPay.replace("-", "")
						priceToPay = "-" + priceToPay;
					}
					printer.printTableRow([description, price, qty, priceToPay], widths);
				}
				else {
					qty = qty.replace("קג'", "kg");
					qty = qty.replace("ג'", "g");

					if (qty.includes('-')) {
						qty = qty.replace("-", "")
						qty = "-" + qty;
					}

					printer.printTableEnglishRow([description, price, qty, priceToPay].reverse(), widths.reverse());
					widths.reverse();
				}
			}
			function printInvoiceItemTableRowNarrow(item, description, price, qty, priceToPay, itemBarcode) {
				let widths = getItemsNarrowWidthts();
				let printItemMultiline = jsonConfig.getVal(jsonConfig.KEYS.printItemMultiline);
				let printClass = printItemMultiline ? ['multiline-items'] : [];

				if (printer.isHTMLBasedPrinting()) {
					printer.printTableRow([description, qty, priceToPay], widths, printClass);
				} else {
										
					if(!printItemMultiline ) {
						description = description.substr(0, printer.maxDescriptionLength);
					}					

					if (printer.isHebrewText(description)) {
						if (!item.hasWeight) {
							printer.printTableRow([description], [printer.lineLength], printClass);
							printer.printTableEnglishRow([priceToPay, qty, " "], widths.reverse());
						}
						else {
							printer.printTableRow([description], [printer.lineLength], printClass);
							printer.printTableRow([" ", qty, priceToPay], widths);

						}
					} else if (!Base.hebrew.test(description) && Base.english.test(description)) {
						printer.printTableEnglishRow([description], [printer.lineLength], printClass);
						printer.printTableEnglishRow([priceToPay, qty, " "], widths.reverse());
					}
					else {
						printer.printTableRow([description], [printer.lineLength], printClass);
						printer.printTableEnglishRow([priceToPay, qty, " "], widths.reverse());
					}
				}
			}
			function printInvoiceItemTableRow(item, widths, options) {
				printer.jzebra.append(printer.allowComponents.rightAlignment);
				let price = item.level == 0 && item.children ? session.fixedNumber(item.reducePriceOfChildren(item.unitPrice)) : session.fixedNumber(item.unitPrice);
				let qty = item.quantity % 1 === 0 ? String(item.actualQuantity) : session.fixedNumber(item.quantity);
				let description = String(item.itemDescription).trim();
				let priceToPay = item.level == 0 && item.children ? session.fixedNumber(item.reducePriceOfChildren(item.unitPrice) * item.actualQuantity) : session.fixedNumber(item.unitPrice * item.actualQuantity);
				let printItemCode = jsonConfig.getVal(jsonConfig.KEYS.printItemCode);
				let printItemMultiline = jsonConfig.getVal(jsonConfig.KEYS.printItemMultiline);
				let printItemCodeAlways = jsonConfig.getVal(jsonConfig.KEYS.printItemCodeAlways);
				let itemBarcode = item.barcode || item.itemCode;
				let printClass = printItemMultiline ? ['multiline-items'] : [];
				let isRTL: boolean = Pinia.languageManagerStore.isRTL;
				let isLTR: boolean = Pinia.languageManagerStore.isLTR;

				//don't move it from here 
				if (item.hasWeight) {
					if (Math.abs(item.quantity) < 1 && Math.abs(item.quantity) > 0) {
						if ((item.quantity < 0) && (printer.isSunmi())) {
							qty = String(item.quantity * 1000).substr(1) + "- " + i18next.t('printing.gram');
						}
						else {
							qty = String(item.quantity * 1000) + " " + i18next.t('printing.gram');
						}
					}
					else {
						if ((item.quantity < 0) && (printer.isSunmi())) {
							qty = String(item.quantity).substr(1) + "- " + i18next.t('printing.kilogram');
						}
						else {
							qty = String(item.quantity) + " " + i18next.t('printing.kilogram');
						}
					}
				}

				if (Service.Tip.isHasTips() && Service.Tip.isTipItemCode(item.itemCode)) {
					priceToPay = price;
					price = '';
				}

				let valuesToPrint = [description, price, qty, priceToPay]

				if(options && !options.print_price) {
					qty = ''
					price = ''
					priceToPay = ''
				}

				if(options && !options.print_price && options.isPerforma) {
					qty = item.quantity % 1 === 0 ? String(item.actualQuantity) : session.fixedNumber(item.quantity);
					valuesToPrint = [description, qty]
				}

				if (printItemCodeAlways) {
					itemBarcode = item.itemCode;
				}
				
				if(session.pos.isElal && !posUtils.isNullOrUndefinedOrEmptyString(item.elalServiceCD)){
					itemBarcode = item.elalServiceCD
				}

				if ((item.color || item.size) && (printItemCode) && (!printer.isHTMLBasedPrinting())) {
					if (description.length > printer.maxDescriptionLength - printer.itemColorSizeLength) {
						description = description.substr(0, printer.maxDescriptionLength - printer.itemColorSizeLength)
					}
				}

				if (item.color) {
					description += " " + item.color;
				}

				if (item.size) {
					description += " " + item.size;
				}

				if (widths[0] <= item.itemDescription.length && (!printItemCode) && (!printer.isHTMLBasedPrinting())) {
					description = description.substr(0, widths[0] - 2);
				}
				
				if (printer.isNarrowPrinting()) {
					printInvoiceItemTableRowNarrow(item, description, price, qty, priceToPay, itemBarcode)
				}
				else {
					if (!printItemCode) {
						switch (session.pos.printerType) {
							case PRINTER_TYPE.sunmit1:
								handleT1SeriesPrinting(valuesToPrint, widths);
								break;
							case PRINTER_TYPE.sunmi:
							case PRINTER_TYPE.sunmik1:
								handleT2SeriesPrinting(valuesToPrint, widths, printClass);
								break;
							default:
								handleNormalPrinting(item, printer, description, valuesToPrint, widths, printClass);
						}
					} else {
						handleItemCodePrinting(printer, description, printItemMultiline, itemBarcode, isRTL, isLTR, item, valuesToPrint, widths, printClass);
					}
				}

				if (item.hasCompensation) {
					printer.printTableRow(["פיצוי", "", "", ""], widths);
				}

				if (!posUtils.isBlank(item.timeItemVersion)) {
					let timeItemData = Service.TimeItem.getTotalPriceData(item, item.timeItemVersion);

					if (timeItemData) {
						for (let detail of timeItemData.details) {
							let timeWidths = [];
	
							if (printer.isNarrowPrinting()) {
								let narrowWidths = getItemsNarrowWidthts();
								timeWidths = [1, narrowWidths[0] - 1, narrowWidths[1] + narrowWidths[2]];
							} else {
								timeWidths = [1, widths[0] + widths[1] - 1, widths[2] + widths[3]]
							}

							let rateTransData = {
								rate: detail.rate,
								rateTime: detail.rateTime,
								currency: i18next.t(`currencies.${Pinia.globalStore.currency}`)
							}

							printer.printTableRow(['', '-- ' + i18next.t('finishTimeItemDialog.rateText', rateTransData), String(session.fixedFloat(detail.timeUnitsUsed * detail.rateTime, 2)) + ' ' + i18next.t('finishTimeItemDialog.minutes')], timeWidths);		
						}
					}
				}

				if (session.pos.useNewPromotions) {
					if (options.discounts) {
						for (let discount of options.discounts) {
							let discountWidth = widths[0] + widths[1] + widths[2];
							printer.printTableRow([((discount.code && discount.code[0] == 'D') || discount.promotionAsDiscount ? i18next.t("printing.discount") : i18next.t("printing.promotion")) + ": " + discount.name, session.fixedNumber(-1 * Number(discount.amount))], [discountWidth, widths[3]]);
						}
					}
				}
				else {
					if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
						var discountPrice = saleItemHelper.getSaleItemDiscountAmount(item);
						let discountPriceText = session.fixedNumber(-discountPrice);

						var discountNameText = item.discountName;
						if (posUtils.isNullOrUndefined(discountNameText)) {
							discountNameText = ``;
						}

						if (printer.isNarrowPrinting()) {
							printer.printTableRow([discountNameText, item.discountPercent + "%", discountPriceText], getItemsNarrowWidthts());	
						} else {
							let discountWidths = [...widths];
							discountWidths[0] += (discountWidths[1] - 1);
							discountWidths[1] = 1;
							printer.printTableRow([discountNameText, "", item.discountPercent + "%", discountPriceText], discountWidths);	
						}
					}
				}
				printer.jzebra.append(printer.allowComponents.init);

			}

			function handleT1SeriesPrinting(valuesToPrint: string[], widths: number[]) {
				t1miniInvoicePrintingShananigns(valuesToPrint, widths);
			}
			
			function handleT2SeriesPrinting(valuesToPrint: string[], widths: number[], printClass: string[]) {
				printer.printTableRow(valuesToPrint, widths, printClass);
			}
			
			function handleNormalPrinting(item, printer: Base, description: string, valuesToPrint: string[], widths: number[], printClass: string[]) {
				if (item.hasWeight || printer.isHebrewText(description) || item.isChild || printer.isHTMLBasedPrinting()) {
					printer.printTableRow(valuesToPrint, widths, printClass);
				} else {
					printer.printTableEnglishRow(valuesToPrint.reverse(), widths.reverse(), printClass);
					widths.reverse(); // Reset widths array to original order
				}
			}
			
			function handleItemCodePrinting(printer: Base, description: string, printItemMultiline, itemBarcode, isRTL: boolean, isLTR: boolean, item: Storage.Entity.Item, valuesToPrint: string[], widths: number[], printClass: string[]) {
				if (!printItemMultiline) {
					description = description.substr(0, printer.maxDescriptionLength);
				}
				valuesToPrint[0] = itemBarcode;
			
				if (printer.isHTMLBasedPrinting()) {
					printer.printTableRow(valuesToPrint, widths);
					printer.printTableRow([description], [printer.lineLength], printClass);
				} else {
					if (isRTL) {
						if (item.hasWeight) {
							printer.printTableRow(valuesToPrint, widths);
						} else {
							printer.printTableEnglishRow(valuesToPrint.reverse(), widths.reverse());
							widths.reverse(); // Reset widths array to original order
						}
					} else if (isLTR) {
						printer.printTableEnglishRow(valuesToPrint, widths);
					}
			
					if (printer.isHebrewText(description)) {
						printer.printTableRow([description], [printer.lineLength], printClass);
					} else {
						printer.printTableEnglishRow([description], [printer.lineLength], printClass);
					}
				}
			}

			export function printOrderList(order:Storage.Entity.ExternalOrder) {
				let zebra = printer.jzebra;
				let company = session.company;
				let widths = [printer.lineLength - 10, 10];
				order = Object.assign((new Storage.Entity.ExternalOrder), order);

				let companyNameArray = company.companyName.split(`$$$`);
				var storeName = session.pos.storeName.replace(`״`, `"`);

				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.bigFont);
				zebra.append(printer.allowComponents.centerAlignment);
				for (var i = 0; i < companyNameArray.length; i++) {
					printer.printMultyLine(companyNameArray[i], printer.lineLengthBigFont);
				};
				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.centerAlignment);
				printer.printMultyLine(storeName);
				printer.printLine(` `);

				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.bigFont);
				zebra.append(printer.allowComponents.rightAlignment);
				printer.printFloatingLine(` ${i18next.t("externalOrdersList.orderNum")}:`, `${order.orderNum}`, printer.lineLengthBigFont);
				printer.printFloatingLine(` ${i18next.t("externalOrdersList.customer")}:`,  `${order.customerName}`, printer.lineLengthBigFont);
				printer.printLine(` `);

				zebra.append(printer.allowComponents.init);
				zebra.append(printer.allowComponents.rightAlignment);
				printer.printHeader(printer.getTableRow([`${i18next.t("externalOrdersDifferences.item")}/${i18next.t("externalOrdersDifferences.barcode")}`, i18next.t("externalOrdersDifferences.quantity")], widths));
				for(let saleItem of order.saleItems) {
					printer.printTableEnglishRow([saleItem.barcode || saleItem.itemCode], widths);	
					saleItem = Object.assign((new Storage.Entity.SaleItem), saleItem);
					printer.printTableRow([saleItem.itemDescription,  `${saleItem.quantity} ${saleItem.quantityUnit}`], widths);					
				}
				
				printer.printLine(` `);
				if(order.missingItems.length > 0) {
					zebra.append(printer.allowComponents.init);
					zebra.append(printer.allowComponents.rightAlignment);
					printer.printHeader(printer.getTableRow([i18next.t("externalOrdersDifferences.missingItemsWarning")], [printer.lineLength]));
					order.missingItems.forEach((itemName) => {
						printer.printTableRow([itemName], [printer.lineLength]);					
					})
				}

				printer.printLineCut();
				zebra.print();
			}

			function printInvoiceItem(item, options?) {
				var print_price = true;
				if (options && !options.print_price) {
					print_price = false;
				}
				let aThis = printer;
				var zebra = aThis.jzebra;

				var colorSize = [];
				if (item.color) {
					colorSize[0] = item.color;
				}
				if (item.size) {
					colorSize[colorSize.length] = item.size;
				}

				var description = item.itemDescription;

				if (item.hasCompensation) {
					description += " " + i18next.t('delivery.compensation')
				}

				if (colorSize.length) {
					description += ` ` + colorSize.join(` `);
				}

				var descriptionRegEx = new RegExp(".{1," + (aThis.itemDescriptionLength - 1) + "}", "g");
				description = description.match(descriptionRegEx);

				var code = item.itemCode;
				if (code.length > aThis.itemCodeLength - 1) {
					code = code.substring(0, aThis.itemCodeLength - 1);
				}

				var linesCount = 1;
				if (item.quantity !== 1 || description.length > 1) {
					++linesCount;

					if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
						++linesCount;
					}
				} else if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
					++linesCount;
				}

				var price = item.unitPrice * item.quantity;

				var itemPriceSpaces = aThis.itemPriceLength - session.fixedNumber(price).length;
				var itemDescriptionSpaces = aThis.itemDescriptionLength - description[0].length;
				var itemCodeSpaces = aThis.itemCodeLength - code.length;
				if (!print_price) {
					itemPriceSpaces = aThis.itemPriceLength;
				}

				let isRTL = aThis.isRTL
				if (printer.isSunmi() && isRTL) {
					let descToCheck = description[0];
					if ((description.length > 1) && (linesCount == 2)) {
						descToCheck = description[1];
					}
					if (aThis.isHebrewText(descToCheck)) {
						isRTL = !isRTL
					}
					else {
						itemDescriptionSpaces += itemPriceSpaces;
						itemPriceSpaces = 0;
					}

				}

				if (isRTL) {
					aThis.printChar(` `, itemPriceSpaces);
					if (print_price) {
						aThis.printText(session.fixedNumber(price));
					}

					aThis.printChar(` `, itemDescriptionSpaces);
					aThis.printText(description[0]);

					aThis.printChar(` `, itemCodeSpaces);
					aThis.printLine(code);
				}
				else {

					aThis.printText(code);
					aThis.printChar(` `, itemCodeSpaces);

					aThis.printText(description[0]);
					aThis.printChar(` `, itemDescriptionSpaces);

					aThis.printChar(` `, itemPriceSpaces);
					if (print_price) {
						let price_str = session.fixedNumber(price);

						if (price < 0) {
							if (printer.isSunmi()) {
								price_str = price_str.substring(1) + "-";
							}
						}
						aThis.printLine(price_str);
					}
				}



				if (linesCount == 2) {

					if (description.length > 1) {
						if (isRTL) {
							aThis.printChar(` `, aThis.itemPriceLength + 1);
							aThis.printText(description[1]);
							aThis.printChar(` `, aThis.itemDescriptionLength - description[1].length - 1);
						}
						else {
							aThis.printChar(` `, aThis.itemDescriptionLength - description[1].length - 1);
							aThis.printText(description[1]);
							aThis.printChar(` `, aThis.itemPriceLength + 1);
						}
					} else if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
						var discountPrice = saleItemHelper.getSaleItemDiscountAmount(item);
						let discountPriceText = session.fixedNumber(-discountPrice);
						if (printer.isSunmi()) {
							discountPriceText = discountPriceText.substr(1) + "-";
						}

						var discountNameText = item.discountName;
						if (posUtils.isNullOrUndefined(discountNameText)) {
							discountNameText = ``;
						}
						if (discountNameText.length > aThis.itemDiscountLength - 1) {
							discountNameText = discountNameText.substring(0, aThis.itemDiscountLength - 1);
						}

						if ((isRTL) && ((session.pos.printerType != PRINTER_TYPE.sunmi))) {
							aThis.printChar(` `, aThis.itemPriceLength - discountPriceText.length);
							aThis.printText(discountPriceText);

							aThis.printChar(` `, aThis.itemDiscountLength - discountNameText.length);
							aThis.printText(discountNameText);
						}
						else {
							aThis.printChar(` `, 4);
							aThis.printText(discountNameText);
							aThis.printChar(` `, aThis.itemDiscountLength - discountNameText.length);

							aThis.printChar(` `, aThis.itemPriceLength - discountPriceText.length);
							aThis.printText(discountPriceText);
						}
					} else {
						aThis.printChar(` `, aThis.itemPriceLength + aThis.itemDescriptionLength);
					}

					if (item.quantity !== 1) {
						var quantityText = getQuantityText(item) + session.fixedNumber(item.unitPrice);
						aThis.printChar(` `, aThis.itemCodeLength - quantityText.length);
						aThis.printText(quantityText);
					}

					zebra.append(`\n`);
				} else if (linesCount == 3) {
					if (description.length > 1) {
						aThis.printChar(` `, aThis.itemPriceLength + 1);
						aThis.printText(description[1]);
						aThis.printChar(` `, aThis.itemDescriptionLength - description[1].length - 1);
					}

					if (item.quantity !== 1) {
						if (description.length <= 1) {
							aThis.printChar(` `, aThis.itemDescriptionLength + aThis.itemPriceLength);
						}
						var quantityText = getQuantityText(item) + session.fixedNumber(item.unitPrice);
						aThis.printChar(` `, aThis.itemCodeLength - quantityText.length);
						aThis.printText(quantityText);
					}

					zebra.append(`\n`);

					if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
						var discountPrice = saleItemHelper.getSaleItemDiscountAmount(item);

						let discountPriceText = session.fixedNumber(-discountPrice);
						aThis.printChar(` `, aThis.itemPriceLength - discountPriceText.length);
						aThis.printText(discountPriceText);

						var discountNameText = item.discountName;
						if (discountNameText === null) {
							discountNameText = ``;
						}
						if (discountNameText.length > aThis.itemDiscountLength - 1) {
							discountNameText = discountNameText.substring(0, aThis.itemDiscountLength - 1);
						}

						aThis.printChar(` `, aThis.itemDiscountLength - discountNameText.length);
						aThis.printText(discountNameText);

						zebra.append(`\n`);
					}
				}
			}
			function printItemsHeader(printPrice?) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				var price = i18next.t("printing.amount");
				var description = i18next.t("printing.name");
				var code = i18next.t("printing.itemCode");

				if (printPrice === false) {
					price = ` `;
				}

				var itemPriceSpaces = aThis.itemPriceLength - price.length;
				var itemDescriptionSpaces = aThis.itemDescriptionLength - description.length;
				var itemCodeSpaces = aThis.itemCodeLength - code.length;

				zebra.append(aThis.allowComponents.underline);

				let isRTL = aThis.isRTL
				if (printer.isSunmi()) {
					isRTL = !isRTL
				}

				if (aThis.isHTMLBasedPrinting()) {

					let widths = [1, 3];
					let data = [code, description];

					if (printPrice !== false) {
						widths.push(1);
						data.push(price);
					}

					aThis.printTableRow(data, widths);

				} else {

					if (isRTL) {
						aThis.printChar(` `, itemPriceSpaces);
						aThis.printText(price);

						aThis.printChar(` `, itemDescriptionSpaces);
						aThis.printText(description);

						aThis.printChar(` `, itemCodeSpaces);
						aThis.printLine(code);
					}
					else {

						aThis.printText(code);
						aThis.printChar(` `, itemCodeSpaces);


						aThis.printText(description);
						aThis.printChar(` `, itemDescriptionSpaces);

						aThis.printChar(` `, itemPriceSpaces);
						aThis.printLine(price);
					}

				}

				zebra.append(aThis.allowComponents.init);
			}
        }
    }
}
