module PositiveTS {
export module Application {
export module Controllers {
export class XreportViewController extends AbstractViewControllerTS {

	init(options?) {
		console.debug('init xpreort')
		var aThis = xReportVC;

		// Init the xreport table view
		var xreportTable = $('#xreport-tableview').tableview({scollable: false, freshInstance:true});

		let xreportVouchersTable = $('#xreport-vouchers-tableview').tableview({freshInstance:true});
		if(Pinia.globalStore.mobileLayout) {
			xreportVouchersTable = $('#xreport-vouchers-tableview').tableview({scollable: false, freshInstance:true});
		}

		// Set the table view header
		xreportTable.setHeader("\
			<div class='ui-grid-b'>\
			<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.paymentType') + "</div></div>\
			<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.inPosAmount') + "</div></div>\
			</div><!-- /grid-b -->");

		xreportVouchersTable.setHeader("\
			<div class='ui-grid-b'>\
			<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.voucherType') + "</div></div>\
			<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.inPosSum') + "</div></div>\
			</div><!-- /grid-b -->");

		xreportTable.setNumberOfVisibleRows(10);
		xreportVouchersTable.setNumberOfVisibleRows(5);
	}

	resume(options?) {
		console.debug('resume xpreort')
		var aThis = xReportVC;

		if (session.pos.employeeID == "-1") {
			// --- No, there is no cashier in session
			console.error('Cannot work in x-report without cashier');

			// Tell the user
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('mustSelectCashier'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, function () {
				// Go back
				pNavigator.back();
			}, null);

			return;
		}

		// Show loading message while we are loading data from remote server
		app.showLoadingMessage(i18next.t("loadingData"));



		var xreportTable = $('#xreport-tableview').tableview({scollable: false, freshInstance:true});
		let xreportVouchersTable = $('#xreport-vouchers-tableview').tableview({freshInstance:true});

		if(Pinia.globalStore.mobileLayout) {
			xreportVouchersTable = $('#xreport-vouchers-tableview').tableview({scollable: false, freshInstance:true});
		}

		xreportTable.setHeader("\
		<div class='ui-grid-b'>\
		<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.paymentType') + "</div></div>\
		<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.inPosAmount') + "</div></div>\
		</div><!-- /grid-b -->");

		xreportVouchersTable.setHeader("\
		<div class='ui-grid-b'>\
		<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.voucherType') + "</div></div>\
		<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.inPosSum') + "</div></div>\
		</div><!-- /grid-b -->");

		xreportVouchersTable.setNumberOfVisibleRows(5);

		xreportTable.empty();
		xreportVouchersTable.empty();

		aThis.calculateReport()
		.then(async (report) => {
			report = Object.assign(report, { tenbisCibusAmount: Storage.Entity.SalePayment.getTenbisPluxeeAmount(report.salePayments) })
			var bonTotals = PositiveTS.Service.HoldSale.getBonTotalSumAndQuantityCanceled()
			report = Object.assign(report, { 
				canceledBonCount:bonTotals.totalQuantity,
				canceledBonSum: bonTotals.totalPrice
			 })


			 if (jsonConfig.getVal(jsonConfig.KEYS.isDelivery) || Service.Tip.isHasTips()) {
				let sales = null;
				
				try {
					if (report.isStoreXReport) {
						let salesResult = await PositiveTS.Service.FetchReq.jsonReq(`/x_reports/sales_for_store/`,"get"); 
						sales = salesResult.result.sales.map(serverSale => Service.Sale.ToStorageTypeSale(serverSale))
					} else {
						sales = await Service.Delivery.getAllPosSalesIncludingDalpakim();
					}
				} catch(err) {
					// Tell the user
					app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('zxReport.failedLoadingSales'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, function () {
						// Go back
						pNavigator.back();
					}, null);
					Service.Logger.error(err);
					return;
				}


				if (jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
					let deliveriesTotals = await Service.Delivery.calculateX(report.isStoreXReport, sales);
					report = Object.assign(report, { 
						deliveriesCount:deliveriesTotals.count,
						deliveriesSum: deliveriesTotals.sum,
						cashOutside:  deliveriesTotals.cashOutside,
						canceledBon: deliveriesTotals.canceledBons,
						totalDeliveriesCash: deliveriesTotals.totalDeliveriesCash
					})	
				}
	
				if (Service.Tip.isHasTips()) {
					let tipsAmount = Service.Tip.totalSalesTipsCountAndAmount(sales).amount;
					(report as any).tipsAmount = tipsAmount;
				}
			 }

			 if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
				let dalpaks = await Service.Dalpak.getAllDalpaksWithOpenedSales()
				
				let dalpakTotals = {
					totalDiners: 0,
					totalOpenTables: 0,
					OpenTablesSum: 0
				}

				for (const dalpak of dalpaks) {
					const {diners, items, payments} = dalpak.sale
					dalpakTotals.totalDiners += diners;
					dalpakTotals.totalOpenTables = dalpaks.length;
					dalpakTotals.OpenTablesSum += Helper.SaleHelper.calcuateSaleTotals(dalpak.sale, items, payments).totalAmount;
				}

				report = Object.assign(report, { 
					dalpakTotals
				})						
			}
			if(Service.MultiCurr.getInstance().isMultiCurr() && report.isStoreXReport){
				let res = await PositiveTS.Service.FetchReq.jsonReq(`/x_reports/cashier_statements_for_store/`,"get"); 
				report = Object.assign(report, {
					storeCashierStatments: res.result.store_cashier_statments
				})
			}
			
			let cashToSafe = Storage.Entity.CashStatementLog.getCashSumWithdrawnToSafe();
			report = Object.assign(report, {
				cashWithdrawnToSafe: cashToSafe
			})


			aThis.showReport(report);
			$('#xreport-print').click(() => aThis.print(report));

			app.hideLoadingMessage();
		});


		}

	stop() {
		$('#xreport-print').unbind('click');
	}

	showReport(xReport) {
		PositiveTS.VueInstance.$refs.xReport.setXReport(xReport);
			console.debug('show xpreort')
			var aThis = xReportVC;

		let payments = xReport.payments;
		console.debug('xreport')
		console.debug(xReport.payments)
		console.debug(xReport.vouchers)

		let vouchers = xReport.vouchers;

		var xreportTable = $('#xreport-tableview').tableview({scollable: false, freshInstance:true});
		xreportTable.setHeader("\
		<div class='ui-grid-b'>\
		<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.paymentType') + "</div></div>\
		<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.inPosAmount') + "</div></div>\
		</div><!-- /grid-b -->");

			xreportTable.setNumberOfVisibleRows(10);

			// This would ne refactored as parameter to TableView.js
			// $('#xreport-tableview .ui-tableview-container').css('height', '22em');
			// $('#xreport-vouchers-tableview .ui-tableview-container').css('height', '7.6em');

			xreportTable.empty();

			//cash withdrawals are not real payments and shouldnt be shown as such
			delete payments[31];
			delete payments[30];

			var totalPayments = 0;

			let lastEnterCashierStatementNumber = Service.CashierStatement.getLastEnterCashierStatementNumber();

			for (let method in payments) {
				if(Number(method) == PositiveTS.Storage.Entity.SalePayment.METHOD_CASH) {
					if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && !xReport.isStoreXReport) {
						payments[method].displayName = i18next.t('zReport.cashPaymentsDisplayDalpakim') ;

					} else {
						payments[method].displayName = i18next.t('zReport.cashPaymentsDisplay') ;
					}
				}

				if (payments[method].amount === null || payments[method].amount === undefined) {
					payments[method].amount = 0;
				}

				if (payments[method].isInXZ == 1) {
					// if method is not praxell
					var dAmount = payments[method].amount;

					if(Number(method) != PositiveTS.Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL) {
						totalPayments += dAmount;
					}

					
					if (jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && !jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
						if(parseInt(method) == PositiveTS.Storage.Entity.SalePayment.METHOD_CASH){
							dAmount = dAmount - xReport.cashOutside;
						}
					}
  			  	if(dAmount != 0) {
	  				xreportTable.addRow([payments[method].displayName, session.fixedNumber(dAmount)]);
          		}
					
					if (jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
						if (parseInt(method) === PositiveTS.Storage.Entity.SalePayment.METHOD_CASH) {
							let text =  jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) ? i18next.t('cashOutsideDalpakim') : i18next.t('cashOutside');
							xreportTable.addRow([text, session.fixedNumber(xReport.cashOutside)]);
						}
					}
				}
			}

			if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
				xreportTable.addRow([i18next.t("totalDeliveriesCash"), session.fixedNumber( xReport.totalDeliveriesCash)]);
			}

			xreportTable.addRow([i18next.t("cashManagement.enterStatement"),  session.fixedFloat(lastEnterCashierStatementNumber)]);
			

			if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
				$('#xreport-dalpaks-total-diners').text(session.fixedNumber(xReport.dalpakTotals.totalDiners))
				$('#xreport-dalpaks-total-open-tables').text(session.fixedNumber(xReport.dalpakTotals.totalOpenTables))
				$('#xreport-dalpaks-open-tables-sum').text(session.fixedNumber(xReport.dalpakTotals.OpenTablesSum))
			} else{
				$('#xreport-dalpaks-table').hide();
			}

			$('#xreport-cash-details').unbind('click');
			$('#xreport-cash-details').click(() => {
Pinia.componentsStore.openComponent( {componentName:"cashManagementReport", args: [xReport.isStoreXReport]});
			})		

			let cashOutside = 0;
			if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
				$('#xreport-deliveries-sum').text(session.fixedNumber(xReport.deliveriesSum))
				$('#xreport-deliveries-count').text(xReport.deliveriesCount)
				$('#xreport-deliveries-cash-outside').text(session.fixedNumber(xReport.cashOutside))
				$('#xreport-deliveries-canceled-bons').text(xReport.canceledBon)
				cashOutside = xReport.cashOutside;
			}
			else{
				$('#xreport-sum-left').hide();
			}

			if (Service.Tip.isHasTips()) {
				$('#xreport-total-tips-sum').text(xReport.tipsAmount);
			} else {
				$('#xreport-tips-sum-left').hide();
			}

			if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)){
				$('#xreport-sum').text(session.fixedNumber(totalPayments));
			}
			else{
				$('#xreport-sum').text(session.fixedNumber(totalPayments - cashOutside));
			}

			PositiveTS.Service.Withdrawal.cashInPos().then(cash => $("#xreport-total-cash").text(session.fixedNumber(cash)));


		if (xReport.tenbisCibusAmount.tenbisTotalAmount){
			$('#xreport-tenbis-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.tenbisTotalAmount))
				 $('#xreport-tenbis-row').show();
		} else {
			$('#xreport-tenbis-row').hide();
		}

		if (xReport.tenbisCibusAmount.mishlohaTotalAmount){
			$('#xreport-mishloha-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.mishlohaTotalAmount))
				 $('#xreport-mishloha-row').show();
		} else {
			$('#xreport-mishloha-row').hide();
		}

		if (xReport.tenbisCibusAmount.goodiTotalAmount){
			$('#xreport-goodi-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.goodiTotalAmount))
					$('#xreport-goodi-row').show();
					} else {
						$('#xreport-goodi-row').hide();
					}

		if (xReport.tenbisCibusAmount.valuTotalAmount){
		$('#xreport-valu-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.valuTotalAmount))
				$('#xreport-valu-row').show();
			} else {
				$('#xreport-valu-row').hide();
			}

		if (xReport.tenbisCibusAmount.cibusTotalAmount){
			$('#xreport-pluxee-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.cibusTotalAmount))
					$('#xreport-pluxee-row').show();
			 } else {
				 $('#xreport-pluxee-row').hide();
			 }

		if (xReport.tenbisCibusAmount.yaadTotalAmount){
			$('#xreport-yaad-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.yaadTotalAmount))
					$('#xreport-yaad-row').show();
				 } else {
					$('#xreport-yaad-row').hide();
				 }
		
		if (xReport.tenbisCibusAmount.dtsTotalAmount){
			$('#xreport-dts-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.dtsTotalAmount))
					$('#xreport-dts-row').show();
				} else {
					$('#xreport-dts-row').hide();
				}

		if (xReport.tenbisCibusAmount.paiditTotalAmount){
			$('#xreport-paidit-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.paiditTotalAmount))
			$('#xreport-paidit-row').show()
		} else {
			$('#xreport-paidit-row').hide()
		}

		if (xReport.tenbisCibusAmount.totalHakafaTamash){
			$('#xreport-total-hakafa-tamash-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.totalHakafaTamash))
				 $('#xreport-total-hakafa-tamash-row').show();
			 } else {
				 $('#xreport-total-hakafa-tamash-row').hide();
			 }
		if((!session.pos.isRoshemet) || (xReport.tenbisCibusAmount.tamashTotalAmount > 0)){
			$('#xreport-tamash-sum-label').show();
			$('#xreport-tamash-sum').show();
			$('#xreport-tamash-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.tamashTotalAmount));
		}
		else{
			$('#xreport-tamash-sum-label').hide();
			$('#xreport-tamash-sum').hide();
		}
		if((!session.pos.isRoshemet) || (xReport.tenbisCibusAmount.totalHakafa - xReport.tenbisCibusAmount.totalHakafaTamash > 0)){
			$('#xreport-hakafa-tax-inv-sum-label').show();
			$('#xreport-hakafa-tax-inv-sum').show();
		  	$('#xreport-hakafa-tax-inv-sum').text(session.fixedNumber(xReport.tenbisCibusAmount.totalHakafa - xReport.tenbisCibusAmount.totalHakafaTamash));
    	}
		else{
			$('#xreport-hakafa-tax-inv-sum-label').hide();
			$('#xreport-hakafa-tax-inv-sum').hide();
		}

		$('#xreport-withdrawn-safe-sum').text( Service.CashierStatement.getCashSumWithdrawnToSafe()  );

		aThis.showVouchersList(vouchers);

						}

	async showVouchersList(vouchers) {
		let xreportVouchersTable = $('#xreport-vouchers-tableview').tableview({freshInstance:true});

		if(Pinia.globalStore.mobileLayout) {
			xreportVouchersTable = $('#xreport-vouchers-tableview').tableview({scollable: false, freshInstance:true});
		}

		xreportVouchersTable.setHeader("\
		<div class='ui-grid-b'>\
		<div class='ui-block-a' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.voucherType') + "</div></div>\
		<div class='ui-block-b' style='width: 50%'><div class='ui-tableview-header-cell'>" + i18next.t('zxReport.inPosSum') + "</div></div>\
		</div><!-- /grid-b -->");
		xreportVouchersTable.setNumberOfVisibleRows(5);
		xreportVouchersTable.empty();
		let voucherModel = new PositiveTS.Storage.Entity.Voucher();
		let allVouchers:Storage.Entity.Voucher[] = await voucherModel.all();
		let voucherMap = new Map<string,Storage.Entity.Voucher>();
		for (let voucher of allVouchers) {
			voucherMap.set(voucher.typeID,voucher)
						}

			for (let type in vouchers) {
			//EnclosingVchrFor(type);
			let voucher = voucherMap.get(type)
			if (["תן ביס", "פלאקסי", "goodi", "DTS", "יעד שריג"].indexOf(voucher.name) === -1) {
				xreportVouchersTable.addRow([voucher.name, session.fixedNumber(vouchers[type])]);
			}
		}
			}

	async fetchSalesAndCalculate(forCurrentPosOnly) {
			// Fetch all salePayemnts that not have z report yet
		try {
			app.hideLoadingMessage()
			let isStoreXReport = await Service.XReport.isStoreXReport(forCurrentPosOnly);
			let payments:Storage.Entity.SalePayment[] = await Service.XReport.fetchSalePayments(forCurrentPosOnly);
			$("#xreport-header").text(isStoreXReport ? i18next.t("reports.xReport") : i18next.t("printing.xReportForPos") + " " + session.pos.deviceID);
			app.hideLoadingMessage();
			let result = await Helper.ReportsHelper.calculateZReport(payments, false);
			result.isStoreXReport = isStoreXReport;
			return result;
		}
		catch(err) {
				console.error(err.stack);
				throw new Error("XreportViewController.calculateReport Error");
		};
		}

	async calculateReport(forCurrentPosOnly?:any) {
		let aThis = xReportVC;

		let report = await aThis.fetchSalesAndCalculate(forCurrentPosOnly);
		return report;
		}

	async getAllVouchers(xReport) {
		let aThis = xReportVC;
		let voucherModel = new PositiveTS.Storage.Entity.Voucher();

		let vouchers = xReport.vouchers;
		let allVouchers:Storage.Entity.Voucher[] = await voucherModel.all();

		let relevantVouchers =  allVouchers.filter(voucher => Object.keys(vouchers).indexOf(voucher.typeID) > -1 )
		relevantVouchers = _.sortBy(relevantVouchers,'typeID')

		let result = []
		for (let voucher of relevantVouchers) {
			result.push({voucher: voucher, amount: vouchers[voucher.typeID]})
			}

		return result;
				}

	async print(xReport) {
		let aThis = xReportVC;

		let vouchers = await aThis.getAllVouchers(xReport)

		let paymentsToPrint = {};
		let totalWithdrawal = (await PositiveTS.Service.Withdrawal.getWithdrawalsXZ()).sum;
		for(let key in xReport.payments) {
			let keyNum = Number(key)
			if(keyNum != PositiveTS.Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL) {
				paymentsToPrint[keyNum] = xReport.payments[keyNum]
			}
		}

		let enterStatement = Service.CashierStatement.getLastEnterCashierStatementNumber();
		if(isNaN(enterStatement)) enterStatement = 0;
		let args: Printing.Reports.PrintZXReportArgs = {
			company_name: session.pos.companyName, store_name: session.pos.storeName, pos_id: session.pos.deviceID,
			cashier_name: session.pos.employeeName, payments: paymentsToPrint, vouchers: vouchers, tenbisCibusAmount: xReport.tenbisCibusAmount,
			deliveriesSum: xReport.deliveriesSum, deliveriesCount: xReport.deliveriesCount, cashOutside: xReport.cashOutside, canceledBon: xReport.canceledBon,
			withdrawalTotal: totalWithdrawal, cashToSafe: Service.CashierStatement.getCashSumWithdrawnToSafe(), enterStatement: enterStatement,
			tipsAmount: xReport.tipsAmount, dalpakTotals: xReport.dalpakTotals, type: 'X', date: PositiveTS.DateUtils.longFormat(),
			currenciesSummary: xReport.currenciesSummary,
		};
		Printing.Reports.ZXReports.printXReport(args);	
				}

		}}}}
declare var xReportVC:PositiveTS.Application.Controllers.XreportViewController;
xReportVC = new PositiveTS.Application.Controllers.XreportViewController()
PositiveTS.Application.Controllers.instances.XreportViewController = xReportVC;
