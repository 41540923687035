module PositiveTS {
export module Components {
    export module CloseDeliveryOrdersDialog {

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $("#close-delivery-orders-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '100%',
              height: 550,
              position: 'top',
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }



      function close() {
        this.isOpen = false;
        $("#close-delivery-orders-dialog").dialog('close');
        $(document).keypress(posVC.onKeyPress); 

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          this.deactivateDalpaksOrderGetters();
        }
      }

      function open() {
        this.isOpen = true;
        this._initjQueryDialogIfNeeded()
        $(document).unbind('keypress');
        $("#close-delivery-orders-dialog").dialog('open');
        PositiveTS.Service.Delivery.getCouriers().then(couriers =>{
          this.couriers = couriers;
          this.selectedCourierID = this.couriers[0].employeeID;
          this.selectedCourier = this.couriers[0].name;
        })

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          this.activateDalpaksOrderGetters();
        }

        this.loadOrders();
      }

      function _initData(){
        return {openOrders: [],
                tableFilter: "",
                selectedCourier: "",
                selectedCourierID: null,
                selectedStatus: "",
                selectedOrders: [],
                selectedType: "",
                couriers: [],
                statuses: [PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY
                  ,PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY
                  ,PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH],
                isOpen: false};
      }

      function showOrder(saleParam){
Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryShowOrder", args: [_.cloneDeep(saleParam), _.cloneDeep(saleParam.items), false ]})
      }
      
      function getCashFromSale(sale){
        return Service.Delivery.getCashFromSale(sale);
      }

      function getPaymentsButCashFromSale(sale){
        return Service.Delivery.getPaymentsButCashFromSale(sale);
      }

      async function closeOrder(order){
        await Service.Delivery.closeOrder(order)

        if (!jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          let orderIndex = this.openOrders.indexOf(order);

          if (orderIndex > -1) {
            this.openOrders.splice(orderIndex, 1);
          }          
        }
      }

      function closeAllOrdersForCourier(){
        let ordersToClose = this.currentCourierOrders;
        if(ordersToClose.length > 0){
          return app.promiseShowAlert({
            header: i18next.t("delivery.closeAll"),
            content: i18next.t("delivery.shouldCloseAllOrdersForCourier"),
            continueButtonText: i18next.t("delivery.closeAll"),
            continueButtonAttribute: 'data-approve-button',
            cancelButtonText: i18next.t("cancel")
          })
          .then(async response =>{
            if (response === "continue") {
          
              for (let order of ordersToClose) {
                  await this.closeOrder(order);                
                }
              }
            }
          )
      }

      }
      

      function createdAtStr(createdAt) {
        if (!createdAt) { 
          return "";
        }

        let date = moment(createdAt)
        return `${date.format("DD/MM HH:mm")}`
      }

      function selectCourier(courier){
        this.selectedCourier = courier.name;
        this.selectedCourierID = courier.employeeID;
      }

      export function create() {

        let closeDeliveryOrdersDialog = {
            template: JST.closeDeliveryOrdersDialog(),
            methods: {
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              close: close,
              open: open,
              createdAtStr: createdAtStr,
              showOrder: showOrder,
              selectCourier: selectCourier,
              closeOrder: closeOrder,
              closeAllOrdersForCourier,
              getCashFromSale: getCashFromSale,
              getPaymentsButCashFromSale: getPaymentsButCashFromSale,
              isToConsiderOrder(order) {
                return order.status != PositiveTS.Storage.Entity.Sale.DELIVERED;
              },
              isCurrentCourierOrder(order) {
                return order.courierID == this.selectedCourierID;
              },
              isCashOutsideOrder(order) {
                return order.status == PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH;
              },
              loadOrders() {


                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  app.showLoadingMessageDialog(i18next.t('delivery.loadingDeliveries'));
                  Service.Dalpak.isDalpaksDataValid().then(result => {
                    app.hideLoadingMessageDialog();

                    if (!result.success) {
                      app.promiseShowAlert({
                        header: i18next.t('error'),
                        content: result.errorMessage,
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                      });
                      this.close();
                    }
                  })

                  return;
                }

                PositiveTS.Service.Delivery.getAllOrders()
                .then( (openOrders => {
                  this.openOrders = openOrders.filter(this.isToConsiderOrder)
                }));
              },
              getOrderTotalAmount(order) {
                return Service.Delivery.getOrderTotalAmount(order);
              },
            },
            setup(){
              const dalpaksStore = Pinia.useDalpaksStore();
              const {dalpaksOrders} = Pinia.storeToRefs(dalpaksStore);

              const {activateDalpaksOrderGetters,deactivateDalpaksOrderGetters} = dalpaksStore
              const dalpaksActions = {activateDalpaksOrderGetters,deactivateDalpaksOrderGetters}

              const globalStore = Pinia.useGlobalStore();
              const {portraitMode,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {portraitMode,mobileLayout}

              return {...globalStoreProps,...dalpaksActions,dalpaksOrders}
            },
            computed: {
              ordersToHandle() {
                if (!this.isOpen) {
                  return [];
                }

                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  return this.dalpaksOrders.filter(order => this.isToConsiderOrder(order));
                }
  
                return this.openOrders;
              },
              currentCourierOrders() {
                return this.ordersToHandle.filter(this.isCurrentCourierOrder);
              },
              ordersStats() {
                let result = {
                  cashToReceive: 0,
                  totalCashToReceive: 0,
                  totalOtherPayments: 0,
                };

                for (let order of this.ordersToHandle) {
                  if (this.isCashOutsideOrder(order)) {
                    result.totalCashToReceive += this.getCashFromSale(order.sale);
                    result.totalOtherPayments += this.getPaymentsButCashFromSale(order.sale);

                    if (this.isCurrentCourierOrder(order)) {
                      result.cashToReceive += this.getCashFromSale(order.sale);
                    }
                  }
                }

                return result;
              },
            },
            data: _initData

        }

        VueApp.component('close-delivery-orders-dialog',closeDeliveryOrdersDialog)

      }
    }
}
}
