module PositiveTS {
    export module Service {

        const ITEMS_TYPES = {
            PURCHASE: "com.otot.common.data.dataObjects.smartClub.accounting.products.PurchasedItemDataCaspit",
            TICKET_PURCHASE: "com.otot.common.data.dataObjects.smartClub.accounting.products.PurchasedLimitedEntries",
            RFID_TICKET_PURCHASE: 'com.otot.common.data.dataObjects.smartClub.accounting.products.PurchasedVisitorWithUID'
        }

        const LOGGER_NAME = "OTOT";

        export class Otot extends SmartVoucher {

            static OTOT_PURCHASE_ENTRANCE_CARD_MODES = {
                ALWAYS_PRINT: 0,
                ALWATS_LOAD: 1,
                ASK_USER: 2,
            }


            static SCAN_DIALOG_TYPES = {
                printTag: 1,
                payment: 2,
                loadTag: 3,
                activateBracelet: 4
            }
            static BRACELET_STATUS = {
                VALID: 1,
                INVALID: 2,
                NEW: 3,
                BRACELET_NOT_NEEDED: 4
            }

            static async getFullBalance(cardNumber: string, cvv?: string): Promise<any> {
                let result = await Otot.makeHttpRequestAndHandleErrors('getBalance?tagNumber=' + cardNumber, 'GET')

                return {
                    currentAmount: session.fixedFloat(result.currentAmount / 100, 2),
                    entriesLeft: result.entriesLeft,
                    loyaltyPointsLeft: result.loyaltyPointsLeft,
                };
            }

            static getUniqeSaleItemKey(saleItem){
                return `${saleItem.rowNumber}#$#${saleItem.itemCode}`
            }

            async getBalance(cardNumber: string, cvv?: string): Promise<GetBalanceResponse> {
                let result = await Otot.getFullBalance(cardNumber, cvv);

                return {
                    balance: result.currentAmount,
                    success: true,
                } as GetBalanceResponse;
            }
            async pay(voucherData: any, amount: number, cvv?: string, companyId?: any, additionalData?: any): Promise<any> {
                let data = {
                    tagNumber: voucherData.barCode,
                    amountUsed: Math.round(amount * 100),
                    items: Otot.convertItemsToApiStructure(additionalData.saleItems, ITEMS_TYPES.PURCHASE),
                }

                let result = await Otot.makeHttpRequestAndHandleErrors('onPurchaseByCredits', 'POST', data);

                voucherData = {...voucherData,
                    actionType: SmartVoucherActionTypes.WITHDRAW,
                    amount: amount,
                    actionReference: result.transactionId,
                    barCode: voucherData.barCode,
                    balance: session.fixedFloat(result.currentAmount / 100, 2),
                    allowPartialReturn: false,
                    smartVoucherType: PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT,
                };

                return {
                    success: true,
                    error: null,
                    rawResponse: result,
                    voucher: voucherData
                }
            }
            async cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
                let data = {
                    tagNumber: parseInt(voucherToCancel.barCode),
                    amount: Math.abs(Math.round(voucherToCancel.amount * 100)),
                    originalTransactionId: voucherToCancel.actionReference
                }

                await Otot.makeHttpRequestAndHandleErrors('onRefundByCredits', 'POST', data);

                return {
                    success: true,
                    error: null,
                }
            }
            async cancelLoad(paymentToCancel: any): Promise<any> {
                paymentToCancel = paymentToCancel[0];

                let data = {
                    tagNumber: parseInt(paymentToCancel.barCode),
                    amount: Math.round(paymentToCancel.amount * 100),
                    originalTransactionId: paymentToCancel.actionReference
                }

                let result = await Otot.makeHttpRequestAndHandleErrors('onCancelCreditPointsPurchase', 'POST', data);


                let currentPayment = new PositiveTS.Storage.Entity.SalePayment();
                currentPayment.amount = 0
                currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT

                let paymentJsondata = {
                    actionType: SmartVoucherActionTypes.CANCEL_DEPOSIT,
                    amount: -Math.abs(paymentToCancel.amount),
                    actionReference: result.transactionId,
                    barCode: paymentToCancel.barCode,
                    balance: session.fixedFloat(result.currentAmount / 100, 2),
                };
                currentPayment.data = JSON.stringify([paymentJsondata]);

                return currentPayment;
            }
            async loadCard(cardNumber: string, amount: number, options?: any): Promise<SmartVoucherPaymentData> {
                let data = {
                    tagNumber: parseInt(cardNumber),
                    creditPointsToLoad: Math.round(amount * 100),
                }
                
                let result = await Otot.makeHttpRequestAndHandleErrors('onCreditPointsPurchased', 'POST', data);

                let paymentJsondata = {
                    actionType: SmartVoucherActionTypes.DEPOSIT,
                    amount: amount,
                    actionReference: result.transactionId,
                    barCode: cardNumber,
                    balance: session.fixedFloat(result.currentAmount / 100, 2),
                };

                return {
                    amount: 0,
                    data: JSON.stringify([paymentJsondata]),
                    method: PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT,
                };
            }
            async getCardNumber(): Promise<GetCardNumberResponse> {
                let cardNumber;
                if(Pinia.globalStore.ototSaleBracelet.status != Service.Otot.BRACELET_STATUS.NEW){
                    cardNumber = Pinia.globalStore.ototSaleBracelet.bracelet;
                }else{
                    cardNumber = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.loadTag);
                }

                if (!posUtils.isBlank(cardNumber)) {
                    return {
                        cardNumber: cardNumber,
                        options: null,
                    }
                }

                throw new Error(i18next.t('scanBarcodeDialog.qrcodeIsRequired'));
            }
            
            static async getCardByEntanceCard(cardNumber: string): Promise<any> {
                return await this.makeHttpRequestAndHandleErrors('getTagNumberByEntranceCard?entranceCardNumber=' + cardNumber, 'GET');
            }

            static async getAllTagNumbersCardByEntanceCard(cardNumber: string, reprint: boolean = false): Promise<any> {
                let endpoint = (reprint ?  'revalidateAllTagNumbersByEntranceCard' : 'getAllTagNumbersByEntranceCard') + '?entranceCardNumber=' + cardNumber
                return await this.makeHttpRequestAndHandleErrors(endpoint, 'GET');
            }
            
            static async onPurchaseByLoyalty(data: {tagNumber: string, amountUsed: number}): Promise<any> {
                return await this.makeHttpRequestAndHandleErrors('onPurchaseByLoyalty', 'POST', data);
            }

            static async buyEntranceCard(saleItems: Storage.Entity.SaleItem[], bracelets: string | Types.OtotEntranceSaleItemBracelets = null) {
                let endPoint = '/v2/onNewPurchaseFromPos';
                if (!posUtils.isBlank(bracelets) && bracelets != 'new') {
                    // bracelets could be equal to "new" if the user wants to create a new bracelet
                    if (this.isRfidMode()) {
                        endPoint = 'onNewPurchaseFromPosWithUID';
                        return await this.makeHttpRequestAndHandleErrors(endPoint, 'POST', { productList: this.convertItemsToApiStructure(saleItems, ITEMS_TYPES.RFID_TICKET_PURCHASE, <Types.OtotEntranceSaleItemBracelets>bracelets ) });
                    } else {
                        endPoint = 'onAddProductsFromPosToExistingUID?uid=' + bracelets;
                        return await this.makeHttpRequestAndHandleErrors(endPoint, 'POST', { productList: this.convertItemsToApiStructure(saleItems, ITEMS_TYPES.TICKET_PURCHASE) });
                    }
                } 
                // return response with headers
                return await this.makeHttpRequestAndHandleErrors(endPoint, 'POST', { productList: this.convertItemsToApiStructure(saleItems, ITEMS_TYPES.TICKET_PURCHASE) }, true);
            }

            static async cancelEntranceCard(cardNumber: string) {
                // return await this.makeHttpRequestAndHandleErrors('onNewPurchaseFromPos', 'POST', cardNumber);
            }

            static async makeHttpRequestAndHandleErrors(endpoint: string, type: string, body = null, returnHeaders = false) {
                let result = await Service.HTTPService.makeHttpRequestWithFullResponseAndValidate(this.getBaseUrl() + '/caspit/' + endpoint, type, body, await this.getHeaders(), LOGGER_NAME);
                if (!result.success && result.status == 401) {
                    // remove the token in order to get a new one at getHeaders()
                    this.removeLocalTokenData();
                    result = await Service.HTTPService.makeHttpRequestWithFullResponseAndValidate(this.getBaseUrl() + '/caspit/' + endpoint, type, body, await this.getHeaders(), LOGGER_NAME);
                }

                if (!result.success) {
                    let errMsg = i18next.t('otot.generalError');

                    if (result.status == 400 || result.status == 500) {
                        try {
                            errMsg = result.getJsonBody().error;
                        } catch(err) {
                            Service.Logger.error("OTOT ERROR MESSAGE INVALID FORMAT");
                            console.error(result);
                        }
                    }

                    throw new Error(errMsg);
                }
                if(returnHeaders){
                    return {body: result.getJsonBody(), headers: result.headers};
                }
                return result.getJsonBody();
            }


            static async getToken(): Promise<string> {
                let tokenData = this.getLocalTokenData();

                if (posUtils.isBlank(tokenData)) {
                    await this.login();
                }

                tokenData = this.getLocalTokenData();

                return tokenData.token;
            }

            static getLocalTokenData(): any {
                let tokenData = localStorage.getItem('ototToken');

                if (posUtils.isBlank(tokenData)) {
                    return null;
                }

                return JSON.parse(tokenData);
            }

            static removeLocalTokenData(): any {
                localStorage.removeItem('ototToken');
            }

            static async login(): Promise<void> {
                let fingerprint = jsonConfig.getVal(jsonConfig.KEYS.ototFingerprint);

                if (posUtils.isBlank(fingerprint)) {
                    throw new Error(i18next.t('otot.validationError'));
                }

                let result = await Service.HTTPService.makeHttpRequestWithFullResponseAndValidate(`${this.getBaseUrl()}/authorizedUsersManagement/loginWithDeviceId?systemId=${this.getSystemId()}&fingerPrint=${fingerprint}`, 'GET',
                null,  { "accept": "application/json", "Content-Type": "application/json", "Accept-Language": jsonConfig.getVal(jsonConfig.KEYS.lang)}, LOGGER_NAME);

                if (!result.success) {
                    console.error(result);
                    throw new Error(i18next.t('otot.validationError'));
                }

                localStorage.setItem("ototToken", JSON.stringify({ at: new Date().toJSON(), ...result.getJsonBody() }))
            }

            static async getHeaders(): Promise<any> {
                let token = await this.getToken();

                return { "accept": "application/json", "Content-Type": "application/json", "Accept-Language": jsonConfig.getVal(jsonConfig.KEYS.lang), token: JSON.stringify({token}) };
            }

            static isOtotActive() {
                return !posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.ototFingerprint));
            }

            static isOtotPurchaseTagItem(saleItem: Storage.Entity.SaleItem) {
                let ototItemCodes = jsonConfig.getVal(jsonConfig.KEYS.ototPurchaseTagItemCode);
                const isBlankOtotItemCodes = posUtils.isBlank(ototItemCodes)

                if (!isBlankOtotItemCodes){
                    ototItemCodes = ototItemCodes.split(',')
                }

                return !isBlankOtotItemCodes && ototItemCodes.includes(saleItem.itemCode);
            }

            static isRfidMode() {
                return Boolean(jsonConfig.getVal(jsonConfig.KEYS.supportOtotRFID));
            }

            static isItemValid(saleItem: Storage.Entity.SaleItem, saleItems: Storage.Entity.SaleItem[]) {
                if (this.isOtotActive()) {
                    if (this.isOtotPurchaseTagItem(saleItem)) {
                        return saleItems.every(si => this.isOtotPurchaseTagItem(si));
                    } else {
                        return saleItems.every(si => !this.isOtotPurchaseTagItem(si));
                    }
                } 

                return true;
            }

            static getBalancesTextArrayFromResponse(response) {
                let formattedEntries = "";
                const responseEntriesLeft = response?.entriesLeft || "";
                responseEntriesLeft.split("\n").forEach(entry => {
                  if (entry !== "" && entry !== null) {
                    let [key, value] = entry.split(":");
                    formattedEntries += `<span class="font-bold">${key}${value ? ":</span> " + value:"</span>"}\n`;
                  }
                });

                return [
                    `<div class="flex justify-center"><div class="flex flex-col justify-end items-start">`,
                    `<div>${formattedEntries}</div>`,
                    `<div><span class="font-bold">${i18next.t('otot.balances.currentAmount')}:</span> ${response.currentAmount}</div>`,
                    `<div><span class="font-bold">${i18next.t('otot.balances.loyaltyPointsLeft')}:</span> ${response.loyaltyPointsLeft}</div>`,
                    "</div></div>"
                ];
            }

            static async printTag(braceletNumber: string, braceletName: string) {
                let qrCodeBase64 = await QRCode.toDataURL(braceletNumber);
                const ototPrintTagOnPrinterPos = jsonConfig.getVal(jsonConfig.KEYS.ototPrintTagOnPrinterPos);
                let template = JST.ototBracelet({printHorizontal: ototPrintTagOnPrinterPos});

                let htmlDataToPrint = _.template(template)({qrcode: qrCodeBase64, name: braceletName})

                if (ototPrintTagOnPrinterPos){
                    await printer.jzebra.printPicture(htmlDataToPrint, null, printer.jzebra.getDefaultPrinter());
                }else{
                    let base64img = await PositiveShared.PicturesPrinting.convertHtmlToBase64Img(htmlDataToPrint, session.pos.printerType, false, 2, jsonConfig.getVal(jsonConfig.KEYS.zplPrinterLabelSize));
                    if (!base64img) {
                        Service.Logger.error("There was an error converting the bracelet into base64 image");
                        return;
                    }

                    let fixedBase64img = base64img.substr(base64img.indexOf(',') + 1);
                    await printer.jzebra.printOnZebraPrinter(fixedBase64img, true);
                }
            }

            static async printTagsFromSale(sale) {
                let data = JSON.parse(sale.jsondata) 
                let numberOfBraceletPrinted = 0;

                if (posUtils.isDefined(data.ototTagsPurchased)) {
                    for (let ototTagPurchased of data.ototTagsPurchased) {
                        await Service.Otot.printTag(ototTagPurchased.uid, ototTagPurchased.productName);
                        numberOfBraceletPrinted++;
                    }
                }

                return numberOfBraceletPrinted;
            }

            static async printTagsFromSaleWithManangerApproval(sale) {
                try {
                    if (await app.showManagerApprovalDialogAndGetEmployee()) {
                        let result = await Service.Otot.printTagsFromSale(sale);
    
                        if (!result) { 
                          app.showAlert({
                            header: i18next.t("payAttention"),
                            content: i18next.t("otot.noBraceletOnSale"),
                            continueButtonText: i18next.t('ok'),
                            hideCancelButton: true
                          }, null, null);                        
                        }
                    }
                } catch (err) {
                    Service.Logger.error(err);
                }
            }

            static async checkBalanceAndShowMessage(cardNumber: string) {
                app.showLoadingMessage(i18next.t('checkingBalance'))
                
                try {
                  let response = await Service.Otot.getFullBalance(cardNumber);
                  app.hideLoadingMessage();

                  app.showAlert({
                    header: i18next.t('balanceCheck.title'),
                    content: Service.Otot.getBalancesTextArrayFromResponse(response).join('\n'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                } catch (err) {
                  Logger.error(err);
                  app.hideLoadingMessage();
                  app.showAlert({
                    header: i18next.t('error'),
                    content: err.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                }
            }

            static async purchaseByGamePoints() {
                let title = i18next.t("pos.purchaseByGamePoints")
                let placeHolder = i18next.t("otot.pointsAmount")
                let requiredMessage = i18next.t("otot.purchaseByGamePointsAmountRequiredMessage")
                const validateFunc = (aThis) => {
                    let result = true
                    if (posUtils.isBlank(aThis.inputValue)) {
                        aThis.showError(requiredMessage)
                        result = false
                    }else{
                        if (Number(aThis.inputValue) == 0) {
                            aThis.showError(i18next.t("otot.purchaseByGamePointsAmountNeedToBeGreaterThanZero"))
                            result = false
                        }    
                    }

                    return result
                }

                let usePointsAmount = await Pinia.componentsStore.openComponent( {componentName:"textInputDialog", args: [title, placeHolder, requiredMessage, Components.TextInputDialog.keyboardTypes.keyPad, 'number', '', validateFunc]});

                if (usePointsAmount.success && usePointsAmount.result){
                    let amountUsed = Number(usePointsAmount.result)
                    let cardNumber = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.printTag);
      
                    if (cardNumber) {
                      app.showLoadingMessage(i18next.t('pos.purchaseByGamePoints'));
                      let data = {tagNumber: cardNumber, amountUsed: amountUsed, items: []}

                      try {
                        let result = await Service.Otot.onPurchaseByLoyalty(data)
                        PositiveTS.Printing.Reports.printOtotAfterPurchaseByGamePoints(result.userMsg, result.originalAmount, result.amountUsed, result.amountLeft)
                        app.hideLoadingMessage();
                      } catch(err) {
                        Logger.error(err);
                        app.hideLoadingMessage();
                        app.showAlertDialog({
                          header: i18next.t('error'),
                          content: err.message || err,
                          continueButtonText: i18next.t("ok"),
                          hideCancelButton: true,
                        })
                      }
                    }
                }

               
            }

            static async reprintEntranceTagFromExistingQrCode(){
                await app.promiseShowAlert({
                    header: i18next.t('payAttention'),
                    content: i18next.t(`otot.reprintWarning`),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });
                  await Service.Otot.printEntranceTagFromExistingQrCode(true);
            }

            static async printEntranceTagFromExistingQrCode(reprint = false) {
                let cardsNumber = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.printTag);

                if (cardsNumber) {
                  app.showLoadingMessage(i18next.t('otot.printingTag'));

                  try {
                      let cards = cardsNumber.replace(/ף/g, ';').split(';').filter(card => !posUtils.isBlank(card.trim()))

                      for (let card of cards) {
                        app.showLoadingMessage(i18next.t('otot.printingTag'));
                        let result = await Service.Otot.getAllTagNumbersCardByEntanceCard(card, reprint);
                        let resultMessage = null

                        for (let row of result) {
                            if (!resultMessage && row.resultMessage){
                                resultMessage = row.resultMessage
                            }

                            await Service.Otot.printTag(row.tagNumber, row.fullName || '');
                        }

                        if (resultMessage) {
                            app.hideLoadingMessage();
                            await app.promiseShowAlert({
                                header: i18next.t('payAttention'),
                                content: resultMessage,
                                continueButtonText: i18next.t("ok"),
                                hideCancelButton: true,
                                noHandleEnterEscape: true,
                            })
                        }
                      }

                     app.hideLoadingMessage();
                  } catch(err) {
                    Logger.error(err);
                    app.hideLoadingMessage();
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: err.message || err,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                  }
                }
            }

            static async activateRfidBraceletViaQrCode() {
                try {
                    $(document).unbind('keypress');
                    let qrCode = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.printTag);
                    if (!qrCode) {
                        await app.promiseShowAlert({ 
                            header: i18next.t("otot.scanRFIDBraceletToActivate"),
                            content: i18next.t("actionCancelled"),
                            hideCancelButton: true, });
                        return;
                    }
                    app.showLoadingMessage(i18next.t('pleaseWait'));
                    qrCode = qrCode.replace(';','');
                    let getDetailsEndpoint = `getDetailsByEntranceCard?entranceCardNumber=${qrCode}`;
                    let tagDetails = await this.makeHttpRequestAndHandleErrors(getDetailsEndpoint, 'GET');
                    app.hideLoadingMessage();
                    let bracelet: string = null;
                    if (tagDetails?.productName) {
                        let ticketName = tagDetails.productName;
                        if (tagDetails.personalData && (tagDetails.personalData.firstName || tagDetails.personalData.lastName)) {
                            let data = tagDetails.personalData;
                            ticketName = i18next.t('otot.activateBraceletFor', { name: `${data.firstName} ${data.firstName}`, productName: tagDetails.productName });
                        }
                        bracelet = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.loadTag, { ticketName: ticketName });
                    } else {
                        bracelet = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.activateBracelet);
                    }
                    if (!bracelet) {
                        await app.promiseShowAlert({ 
                            header: i18next.t("otot.scanRFIDBraceletToActivate"),
                            content: i18next.t("actionCancelled"),
                            hideCancelButton: true, });
                        return;
                    }
                    app.showLoadingMessage(i18next.t('otot.activatingRfidBracelet'));
                    let endpoint = `onActivateUIDForUser?entranceCardNumber=${qrCode}&uid=${bracelet}`;
                    let response = await this.makeHttpRequestAndHandleErrors(endpoint, 'GET');
                    console.log("otot RESPONSE ::: ", tagDetails);
                    app.hideLoadingMessage();
                    // If no error was thrown, then the bracelet was activated sucessfully
                    await app.promiseShowAlert({ header: i18next.t("otot.scanRFIDBraceletToActivate"), content: i18next.t("otot.braceletActivated"), hideCancelButton: true });
                    return tagDetails;
                } catch (error) {
                    app.hideLoadingMessage();
                    console.error("Error during attempt to activate RFID Bracelet", error);
                    await app.promiseShowAlert({ header: i18next.t("errorOccurred"), content: error, hideCancelButton: true });
                } finally {
                    $(document).keypress(posVC.onKeyPress);
                }
            }

            static async openScanBraceletDialog(type = null, args?: Types.ScanBraceletDialogAdditionalArgs) {
                let options: any = {
                    emptyValueMessage: i18next.t(`scanBarcodeDialog.qrcodeIsRequired`),
                    title: i18next.t('otot.scanQrBracelet'),
                    barcodeName: i18next.t('scanBarcodeDialog.qrcode'),
                    reverseActions: true,
                    noKeyboard: Pinia.globalStore.mobileLayout || jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService),
                    svg: 'SCANBRACELET',
                }

                if (type == Otot.SCAN_DIALOG_TYPES.printTag) {
                    options.title = i18next.t('otot.scanTicketQr')
                    options.svg = 'SCANQRPHONE'
                }

                if (type == Otot.SCAN_DIALOG_TYPES.loadTag) {
                    if (args && args.ticketName) {
                        options.title = i18next.t('otot.scanRFIDBraceletToLoadTicket', { ticketName: args.ticketName });
                        options.barcodeName = i18next.t('scanBarcodeDialog.braceletId');
                    } else {
                        options.title = i18next.t('otot.scanQrBraceletForLoad')
                    }
                }

                if (type == Otot.SCAN_DIALOG_TYPES.activateBracelet) {
                    options.title = i18next.t('otot.scanRFIDBraceletToActivate');
                    options.barcodeName = i18next.t('scanBarcodeDialog.braceletId');
                }


                return await ScanService.scanQrCode(options);
            }

            static async getNeededBraceletsForSale() : Promise<Types.OtotBracelet> {
                let hasOtotItems = posVC.saleItems.some(item => item.itemCode == 'OTOT' || Service.Otot.isOtotPurchaseTagItem(item));
                if (!Service.Otot.isOtotActive() || !hasOtotItems) {
					return {
                        bracelet: "",
                        status: Otot.BRACELET_STATUS.BRACELET_NOT_NEEDED
                    };
				}
                if (!posUtils.isBlank(Pinia.globalStore.ototSaleBracelet.bracelet)) {
                    return Pinia.globalStore.ototSaleBracelet        
                } else {
                    let braceletToLoad: string | Types.OtotEntranceSaleItemBracelets = null;
                    let smartVoucher = PositiveTS.Service.SmartVoucher.getSmartVoucherObjectToLoadIfApplicable(posVC.saleItems,posVC.Specialitems);
                    if(smartVoucher){
                        braceletToLoad = await Service.Otot.openScanBraceletDialog(Otot.SCAN_DIALOG_TYPES.loadTag);
                    } else {
                        if (Service.Otot.isRfidMode()) {
                            braceletToLoad = {};
                            let braceletIndexCount = {};
                            let saleItemGroups = _.groupBy(saleItemHelper.unflattenSaleItems(posVC.saleItems), 'itemCode');
                            for (const itemCode in saleItemGroups) {
                                let saleItems: Storage.Entity.SaleItem[] = saleItemGroups[itemCode];
                                if (saleItems && !Service.Otot.isOtotPurchaseTagItem(saleItems[0])) {
                                    continue;
                                }
                                let totalCards: number = _.sumBy(saleItems, 'quantity');
                                let itemIndex = 1;
                                for (const saleItem of saleItems) {
                                    const uniqueSaleItemKey = Otot.getUniqeSaleItemKey(saleItem); 
                                    braceletToLoad[uniqueSaleItemKey] = []
                                    for (let i = 0; i < saleItem.quantity; i++) {
                                        let ticketName = `${saleItem.itemDescription} ${i18next.t('xOutOfY', { index: itemIndex, length: totalCards })}`
                                        let bracelet = await Service.Otot.openScanBraceletDialog(Service.Otot.SCAN_DIALOG_TYPES.loadTag, { ticketName: ticketName });
                                        if (posUtils.isBlank(bracelet)) {
                                            throw new Error(i18next.t('otot.tagNotScanned'),);
                                        }
                                        braceletToLoad[uniqueSaleItemKey].push(bracelet);
                                        itemIndex++;
                                    }
                                }
                            }
                        } else {
                            let ototPurchaseTagMode = jsonConfig.getVal(jsonConfig.KEYS.ototPurchaseTagMode);
                            let showScanBraceletMode = ototPurchaseTagMode == Service.Otot.OTOT_PURCHASE_ENTRANCE_CARD_MODES.ALWATS_LOAD;
                            if (ototPurchaseTagMode == Service.Otot.OTOT_PURCHASE_ENTRANCE_CARD_MODES.ASK_USER) {
                                let dialogResponse = await app.showAlertDialog({
                                    header: i18next.t('payAttention'),
                                    content: i18next.t('otot.printOrLoadBrancelet'),
                                    continueButtonText: i18next.t('otot.loadExistingBracelet'),
                                    cancelButtonText: i18next.t('otot.printNewBracelet'),
                                    hideCancelButton: false,
                                    noHandleEnterEscape: true,
                                })
        
                                showScanBraceletMode = dialogResponse != 'cancel';
                            }
        
                            if (showScanBraceletMode) {
                                braceletToLoad = await Service.Otot.openScanBraceletDialog(Service.Otot.SCAN_DIALOG_TYPES.loadTag);
                                if (posUtils.isBlank(braceletToLoad)) {
                                    throw new Error(i18next.t('otot.tagNotScanned'),);
                                }
                            }else{
                                const ototBracelet = {
                                    bracelet: null,
                                    status: Otot.BRACELET_STATUS.NEW
                                }
                                Pinia.globalStore.setOtotSaleBracelet(ototBracelet)
                                return ototBracelet;
                            }
                        }
                    }
                    const ototBracelet = {
                        bracelet: braceletToLoad,
                        status: Otot.BRACELET_STATUS.VALID
                    }

                    if(!posUtils.isBlank(braceletToLoad)){
                        Pinia.globalStore.setOtotSaleBracelet(ototBracelet)
                    } else {
                        return {
                            bracelet: null,
                            status: Otot.BRACELET_STATUS.INVALID
                        }
                    }
                    return ototBracelet
                }
            }

            private static convertItemsToApiStructure(saleItems: Storage.Entity.SaleItem[], itemType, bracelets: Types.OtotEntranceSaleItemBracelets = null) {
                return saleItemHelper.unflattenSaleItems(saleItems).map(saleItem => {
                    const uniqueSaleItemKey = Otot.getUniqeSaleItemKey(saleItem);  
                    if (bracelets && bracelets[uniqueSaleItemKey] && bracelets[uniqueSaleItemKey].length > 0) {
                        return this.convertItemToApiStructure(saleItem, itemType, bracelets[uniqueSaleItemKey])
                    } else {
                        return this.convertItemToApiStructure(saleItem, itemType);
                    }

                });
            }

            private static convertItemToApiStructure(saleItem: Storage.Entity.SaleItem, itemType, braceletUIDs: string[] = null) {
                let itemData:any = {
                    name: saleItem.item.description,
                    productId: 0,
                    pricePerUnit: Math.round(saleItem.unitPrice  * 100),
                    pricePaid: Math.round(saleItem.priceNetoAfterDiscounts * 100),
                    unitsAmount: saleItem.quantity,
                    type: itemType,
                } 

                if (itemType == ITEMS_TYPES.PURCHASE) {
                    itemData.subItems = saleItem.children && saleItem.children.length > 0 ? saleItem.children.map(childItem => this.convertItemToApiStructure(childItem, itemType)) : [];
                    itemData.makat = saleItem.itemCode;
                }

                if ([ITEMS_TYPES.TICKET_PURCHASE, ITEMS_TYPES.RFID_TICKET_PURCHASE].includes(itemType)) {
                    itemData.entriesAmount = 4;
                    itemData.productId = Number(saleItem.itemCode);
                    if (braceletUIDs) itemData.uidList = braceletUIDs;
                }

                return itemData;
            }

            private static getBaseUrl() {
                return session.pos.ototDefaultUrl;
            }
            private static getSystemId() {
                return session.pos.systemId;
            }
        }
    }
}


