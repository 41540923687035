module PositiveTS {
   export module Service {
      export module DalpakInfra {
         export type Dalpak = {
            id: string
            color: string
            name: string
            sortOrder: number
            lockedBy: string
            isPrinted: boolean
            sale: any
            area: any
            railsId: number,
            syncWithoutId?: boolean
         }

         export type DalpakArea = {
            id: string
            color: string
            name: string
            sortOrder: number
            railsId: number,
         }

         export type InfraResult<ResultType> = {
            success: boolean
            errorMessage: string|null
            data: ResultType
         }

         export const DALPAK_SPECIAL_AREAS = {
            DEFAULT: '0',
            DELIVERIES: '-1',
            DELETED: '-2',
         }

         export const ATTRIBUTES_ALLOWED_IN_BULK_UPDATE = ['name', 'sortOrder', 'area', 'data', 'railsId']

         export abstract class DalpakEngine {
            abstract  getDalpak(dalpakId: string): Promise<InfraResult<Dalpak>>;
            abstract  getDalpakArea(dalpakAreaId: string): Promise<InfraResult<DalpakArea>>;
            abstract  getAndLock(dalpakId: string): Promise<InfraResult<Dalpak>>;
            abstract  getAll(): Promise<InfraResult<Dalpak[]>>;
            abstract  getAllDalpakAreas(): Promise<InfraResult<DalpakArea[]>>
            abstract  saveDalpakAttributesAndUnlock(dalpak: Dalpak): Promise<InfraResult<Dalpak>>;
            abstract  saveDalpakAreaAttributes(dalpakArea: DalpakArea): Promise<InfraResult<DalpakArea>>;
            abstract  markSaleAsPrinted(dalpak: Dalpak): Promise<InfraResult<Dalpak>>;
            abstract  saveSale(dalpak: Dalpak, unlock: boolean): Promise<InfraResult<Dalpak>>;
            abstract  deleteDalpak(dalpak: Dalpak): Promise<InfraResult<boolean>>;
            abstract  deleteDalpakArea(dalpakArea: DalpakArea): Promise<InfraResult<boolean>>;
            abstract  unlock(dalpak: Dalpak): Promise<InfraResult<boolean>>;
            abstract  bulkUpdateDalpaksAndAreasAttributes(data): Promise<InfraResult<boolean>>;
            abstract  bulkPut(data: Dalpak[]): Promise<InfraResult<boolean>>;
            abstract  bulkPutDalpakAreas(data: DalpakArea[]): Promise<InfraResult<boolean>>;
            abstract  isCurrentDataValid(): Promise<InfraResult<boolean>>
            abstract  moveDalpaksToArea(dalpaks: Dalpak[], newAreaRailsId: string): Promise<InfraResult<boolean>>

            async saveSaleAndUnlock(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.saveSale(dalpak, true);
            }


            isDataAlwaysUpToDate(): boolean {
               return false;
            }
            
            async syncWith(target: DalpakEngine): Promise<InfraResult<any>> {
               try {
                  let result = await this.getAll();

                  if (!result.success) {

                     result.data = null;
                     return result;
                  }

                  return target.bulkPut(result.data);

               } catch(err) {
                  console.error(err);
                  Service.Logger.error(err);

                  return {
                     success: false,
                     data: null,
                     errorMessage: i18next.t('generalError'),
                  }
               }
            }

            public isLocked(dalpak) {
               return dalpak.lockedBy && dalpak.lockedBy != session.pos.deviceID
            }

            protected syncFailedDeliveriesIfNeeded() {
               if (jsonConfig.getVal(jsonConfig.KEYS.isDelivery)) {
                  Service.DalpakSharedOrders.syncFailedSales();
               }
            }
         }
      }
   }
}