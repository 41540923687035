module PositiveTS {

  export module Components {

    export module CashierStatementMultiCurrencies {
      const _dialogSelectorCashierStatementMultiCurrencies = 'positive-cashier-statement-multi-currencies-dialog';
      const _dialogSelectorCashierStatementCurrency = 'cashier-statement-currency';
      const _vueComponentName = 'cashier-statement-multi-currencies';

      export function create() {
        let vueObj = {
            template: JST.CashierStatementMultiCurrencies(),
            components: {
              positTable: PositiveTS.Components.PositTable.getComponent(),
            },
            methods: {
              open: open,
              close: close,
              setCurrencies,
              getCurrencyName,
              openCashierStatementCurrency,
              closeCashierStatementCurrency,
              approveCashierStatementCurrency,
              selectInput,
              isNewRow,
              openDrawer,
              cancel,
              acceptStatement,
              generateStatement,
              getCashInPos,
              fixedNumberAndToLocalString(num) {
                return session.fixedNumberAndToLocalString(num);
              },
              async getCashCurreniesSummary() {
                let multiCurr = Service.MultiCurr.getInstance()
                let payments = await Service.XReport.fetchSalePayments(true);
                let currenciesCalculator = new PositShared.CalcCurrenciesSummary(multiCurr.getPosCurrency(), i18next);
                let LastEnterCashierStatementCurrencies = PositiveTS.Service.CashierStatement.getLastEnterCashierStatementNumberCurrencies()
                let CashCurrenciesInSafe = PositiveTS.Service.CashierStatement.getCashCurrenciesSumWithdrawnToSafe();
                let cashCurrInPos = {};
                let income = currenciesCalculator.calcCash(payments);
                for (const currCode of multiCurr.getCurrenciesEnabled()) {
                  cashCurrInPos[currCode] = {};
                  cashCurrInPos[currCode].total = (income[currCode]?.total || 0) + (LastEnterCashierStatementCurrencies[currCode] || 0);
                  cashCurrInPos[currCode].total -= (CashCurrenciesInSafe[currCode] || 0);
                }
                return cashCurrInPos;
              },
              getCashForCurrency(currencyCode: string) {
                let cashCurrencyInPos = this.currentCashInPos[currencyCode]?.total || 0;
                return cashCurrencyInPos;
              },
              getCashGapBetweenStateAndCalced(currency: Types.CashierStatementMultiCurr) {
                let income = this.getCashForCurrency(currency.code);
                return currency.currencyAmount - income;
              },
              setCashierZStatement
            },

            data: _initData,
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {showMultiCurrCoinGap,currency,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {showCoinsGap:showMultiCurrCoinGap,posCurrency:currency,isMobile:mobileLayout} 

              return {...globalStoreProps}
            },
            computed: {
              sumTotalCurrencyBills,
              totalCashStatement,
              title () {
                return i18next.te(`cashierStatement.statementTitle.${this.type}`) ? this.$t(`cashierStatement.statementTitle.${this.type}`) : ''
              },
              cashierStatementCurrencySelected () {
                let currency = ''
                if (this.currencySelected?.code){
                  currency = this.getCurrencyName(this.currencySelected.code)
                }
                return i18next.t("cashierStatementMultiCurrencies.selectedCurrency", {currency})
              },
              colDefs(): PositiveTS.Types.PositTableHeader[] {
                let multiCurr = PositiveTS.Service.MultiCurr.getInstance();
                let posCurrency = getCurrencyName(this.posCurrency);
                let cols: PositiveTS.Types.PositTableHeader[] = [{ field: "code", type: 'action', title: i18next.t("cashierStatementMultiCurrencies.currency"),
                computedField: (c) => this.isMobile ? multiCurr.getCurrencySign(c.code) : getCurrencyName(c.code), action: "state", btnClass: 'pos:w-32 p2-pro:w-14 p2-pro:h-14',
                class: ' w-36  ' }];
                if (!this.showCoinsGap) {
                  cols.push({ field: "currencyAmount", type: 'number', title: i18next.t("cashierStatementMultiCurrencies.currencyAmount") });

                  cols.push({ field: "amount", type: 'number', title: i18next.t("cashierStatementMultiCurrencies.currencyPosAmount", { currency: posCurrency }) })

                } else {
                  cols.push({ field: "currencyAmount", type: 'number', title: i18next.t("cashierStatementMultiCurrencies.statedCurrency"), class: ' w-28 '  });
                  
                  cols.push({ field: "amount", type: 'number', title: i18next.t("cashierStatementMultiCurrencies.statedCurrencyInCurrencyPosAmount", { currency: posCurrency }), 
                  class: ' w-28 ' });
                  
                  cols.push({ field: "code", type: 'number', title: i18next.t("cashierStatementMultiCurrencies.calcedCurrency"),
                  computedField: (row) => this.getCashForCurrency(row.code), class: ' w-28 ' });
                  
                  cols.push({ field: "code", type: 'number', title: i18next.t("cashierStatementMultiCurrencies.statedAndCalcedGap"),
                  computedField: (row) => this.getCashGapBetweenStateAndCalced(row), class: ' w-28 ' });
                }
                cols.forEach(c => { c.class = (c.class || '') + " text-center" });
                return cols;
              },
              currencyBillsColDefs(): PositiveTS.Types.PositTableHeader[] {
                return [
                  { field: 'amount', type: 'string', title: i18next.t('cashierStatementMultiCurrencies.amount'), class: 'text-center' },
                  { field: 'count', type: 'slot', title: i18next.t('cashierStatementMultiCurrencies.quantity'), class: 'w-5/12' },
                  { field: 'total', title: i18next.t('cashierStatementMultiCurrencies.total'), 
                    computedField: (bill) => this.fixedNumberAndToLocalString(bill.amount * bill.count), class: 'text-center w-5/12' },
                ];
              },
            },

            mounted() {
              emitter.on('user-logged', async employee => { await this.generateStatement(employee); });
              
              this.$nextTick(() => {
                this.setCurrencies()
                this.elem = (<HTMLDialogElement>document.getElementById(_dialogSelectorCashierStatementMultiCurrencies))
                this.elemCashierStatementCurrency = (<HTMLDialogElement>document.getElementById(_dialogSelectorCashierStatementCurrency))
              })   
            }
        }
        VueApp.component(_vueComponentName, vueObj)
      }

      function openDrawer() {
        printer.openDrawer()
      }

      function cancel() {
        this.close()
      }

      function totalCashStatement() {

        let total = 0;

        this.currencies.forEach((c) => {
          if (c.currencyAmount > 0){
            total += Number(c.amount)
          }
        })

        return session.fixedFloat(total, 2)
      }

      async function acceptStatement() {
        
        let currencies = []
        let totalAmount = 0

        this.currencies.forEach((c: Types.CashierStatementMultiCurr) => {
          if (c.currencyAmount > 0){
            totalAmount += Number(c.amount)
            let curr  = _.clone(c);
            if (this.showCoinsGap) {
              curr.cashInPos = this.getCashForCurrency(c.code);
              curr.statedAndCalcedGap = this.getCashGapBetweenStateAndCalced(c);
            }
            currencies.push(curr)
          }
        })

        if (currencies.length){
          PositiveTS.Printing.Reports.printCashierStatementMultiCurrencies(
            this.type,
            session.pos.companyName,
            session.pos.storeName,
            session.pos.deviceID,
            session.pos.employeeName,	
            currencies,
            this.posCurrency
          )

          this.generateStatement(currencies, totalAmount)
        }
      }

      async function generateStatement(currencies, totalAmount) {
        let newStatement = await Service.CashierStatement.generateMultiCurrencyStatement(currencies, totalAmount, this.type);

        if (newStatement instanceof PositiveTS.Storage.Entity.CashStatementLog){
          this.close()
        }
      }

      async function setCashierZStatement (totalAmount, newStatement) {
        
        let realZCash = await this.getCashInPos();
        let difference = realZCash - totalAmount;
        let allowedDifference = jsonConfig.getVal(jsonConfig.KEYS.allowedDeclarationToXDifference);
        if (difference > allowedDifference || difference < allowedDifference * -1) {
            let zDifferenceDialog = await app.promiseShowAlert({
                header: i18next.t('error'),
                content: i18next.t('cashierStatement.zDifferenceProblem'),
                continueButtonText: i18next.t("cashierStatement.zDifferenceProblemOk"),
                cancelButtonText: i18next.t("cashierStatement.zDifferenceProblemCancel"),
                hideCancelButton: false
            });
            if (zDifferenceDialog == "cancel") {
                return false;
            }
        }
        Service.CashierStatement.setCashierZStatement(totalAmount);
        if (difference < 0) {
            difference *= -1;
        }
        newStatement.difference = difference;
        newStatement.z_amount = realZCash;
        newStatement.opening_amount = Number(Service.CashierStatement.getLastEnterCashierStatementNumber());
        newStatement.cash_in_safe = Number(Service.CashierStatement.getCashSumWithdrawnToSafe());
        newStatement.syncStatus = PositiveTS.Shared.Constants.CashierStatement.SYNC_STATUS_AWAITING_Z;
        pNavigator.pushPage('zreport', i18next.t('homepage.zreport'), '/homepage', null);
        zReportVC.createNewZReportMain();

        return newStatement;
      }

      async function getCashInPos() {
        return Service.Withdrawal.cashInPos();
      }
      
      function isNewRow (index){
        return isInt((index / this.maxCurrenciesInRow))
      }

      function isInt(n){
        return Number(n) === n && n % 1 === 0;
      }

      function approveCashierStatementCurrency () {

        this.currencies.map((c) => {
          if (c.code == this.currencySelected.code){
            c.amount = PositiveTS.Service.MultiCurr.getInstance().translateAmount(this.sumTotalCurrencyBills, this.currencySelected.code)
            c.currencyAmount = this.sumTotalCurrencyBills
            c.currencyBills = _.cloneDeep(this.currencySelected.currencyBills)
          }
        })

        this.currencySelected = null
        this.closeCashierStatementCurrency()
      }

      function closeCashierStatementCurrency () {
        this.elemCashierStatementCurrency.close();
        this.elem.show();
        $(document).keypress(posVC.onKeyPress);
      }

      function openCashierStatementCurrency (currency) {
        if (this.elemCashierStatementCurrency == null) {
          this.elemCashierStatementCurrency = (<HTMLDialogElement>document.getElementById(_dialogSelectorCashierStatementCurrency))
        }
        this.close(true);
        this.currencySelected = _.cloneDeep(currency);
        this.elemCashierStatementCurrency.show();
        $(document).unbind('keypress');
      }

      function getCurrencyName (code) {
        return PositiveTS.Service.MultiCurr.getInstance().getCurrencyName(code)
      }

      function setCurrencies () {
        let currencies = [];
        const currencyBills = [
          {amount: 0.1, count: 0},
          {amount: 0.5, count: 0},
          {amount: 1, count: 0},
          {amount: 2, count: 0},
          {amount: 5, count: 0},
          {amount: 10, count: 0},
          {amount: 20, count: 0},
          {amount: 50, count: 0},
          {amount: 100, count: 0},
          {amount: 200, count: 0},
          {amount: 500, count: 0}
        ];

        PositiveTS.Service.MultiCurr.getInstance().getPosCurrenciesEnabled().forEach((c) => {
          currencies.push({code: c, amount: 0.0, currencyAmount: 0.0, currencyBills})
        })
        if (window.innerWidth <= 1024){
          this.maxCurrenciesInRow = 5
          setStyleVar('--cashier-statement-multi-currencies-columns', `7rem repeat(${this.maxCurrenciesInRow}, 1fr)`)
        }
        this.currencies = currencies
        this.totalRows = Math.ceil(currencies.length / this.maxCurrenciesInRow) || 1
        setStyleVar('--cashier-statement-multi-currencies-rows', `repeat(${this.totalRows}, 1fr)`)
      }

      function setStyleVar(key, value){
        const root = document.documentElement
        root.style.setProperty(key, value)
      }

      async function open (type: string) {

        this.type = type

        if (this.elem == null) {
          this.elem = (<HTMLDialogElement>document.getElementById(_dialogSelectorCashierStatementMultiCurrencies))
        }
        
        this.elem.show();

        if (session.isAndroid) {
          document.getElementById("cashier-statement-cash-200").blur();
          Array.from(document.getElementsByClassName('money-input')).forEach(elem => elem.setAttribute("data-keyboardoffset","20"))
        }
        this.xReportCash = await this.getCashInPos()
        this.currentCashInPos = await this.getCashCurreniesSummary();
      }

      function _initData(){
        let data: {
          imgs: Object,
          bills: Array<any>,
          xReportCash: number,
          statingEmployee: any,
          dialogTitle: string,
          elem: any,
          elemCashierStatementCurrency: any,
          currencies: Array<Types.CashierStatementMultiCurr>,
          currentCashInPos: object,
          currencySelected: object,
          type: string,
          totalRows: number,
          maxCurrenciesInRow: number,
          hideCashAmountOnDeclarations: boolean
        } = 
        {
          imgs: {
            drawer: `${(<any>window).images_path}pos/open_drawer.png`,
            clear: `${(<any>window).images_path}clear_big.png`
          },
          bills: [],
          xReportCash: 0,
          statingEmployee: null,
          dialogTitle: "",
          elem: null,
          elemCashierStatementCurrency: null,
          currencies: [],
          currencySelected: null,
          currentCashInPos: {},
          type: '',
          totalRows: 0,
          maxCurrenciesInRow: 7,
          hideCashAmountOnDeclarations: jsonConfig.getVal(jsonConfig.KEYS.hideCashAmountOnDeclarations)
        }

        return data
      }

      function selectInput(e) {
        e.target.select()
      }

      function sumTotalCurrencyBills () {
        let sumTotal = 0
        
        if (this.currencySelected){
          this.currencySelected.currencyBills.forEach((b) => {
            sumTotal += (Number(b.amount) * Number(b.count))
          })
        }
        return sumTotal
      }

      function close (withoutReset = false) {
        
        if (session.isAndroid) {
          Array.from(document.getElementsByClassName('money-input')).forEach(elem => elem.removeAttribute("data-keyboardoffset"));
        }
        this.elem.close();

        if (!withoutReset){
          const propertiesToSave = {
            elem: this.elem, 
            elemCashierStatementCurrency: this.elemCashierStatementCurrency
          }
          let data = Object.assign(_initData(), propertiesToSave)
          Object.assign(this.$data, data)

          this.setCurrencies()
        }
      }
    }
  }
}
