module PositiveTS {
  export module Components {
    export module CreateDalpakDialog {

      export const route = '/create-dalpak';
      type CompType = any;


      export function getComponent() {
        return {
          template: JST.createDalpakDialog(),
          methods: {
            close() {
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
            async createAndFinish() {
              if(this.allDalpakAreas && this.allDalpakAreas.length > 0 && this.area == null) {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('createDalpakDialog.areaError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);

                return
              }

              let isCreatedSuccessfully = await Service.Dalpak.createNewDalpak({name: this.name, color: this.color, sortOrder: this.sortOrder, area: this.area, data: this.data});

              if (isCreatedSuccessfully) {
                this.close();
              }
            }
          },
          data() {
            return {
              name: null,
              color: null,
              sortOrder: null,
              area: null,
              data: {},
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {allDalpakAreas} = Pinia.storeToRefs(dalpaksStore);
            return {allDalpakAreas}
          },
          mounted() {
            if(this.allDalpakAreas && this.allDalpakAreas.length == 1) {
              this.area = this.allDalpakAreas[0].railsId
            }
          },
          beforeRouteEnter: function(to, from, next) {
            next(vm => {
              let self = vm as CompType;
              self.sortOrder = to.params.sortOrder || 0;
            });
          }
        }
      }
    }
  }
}
