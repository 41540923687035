module PositiveTS {
   export module Service {
      export module DalpakInfra {

         export class DalpakLocalEngine extends DalpakEngine {
            constructor(private withLocks = false) {
               super();
            }


            async getDalpak(dalpakId: string): Promise<InfraResult<Dalpak>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => await table.get(dalpakId));
            }

            async getDalpakArea(dalpakAreaId: string): Promise<InfraResult<DalpakArea>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.DalpakArea, string>) => await table.get(dalpakAreaId), null, 'dalpakAreas');
            }

            async getAndLock(dalpakId: string): Promise<InfraResult<Dalpak>> {
               if (this.withLocks) {
                  let dalpak = await appDB.dalpaks.get(dalpakId);

                  if (this.isLocked(dalpak)) {
                     return {
                        success: false,
                        data: dalpak,
                        errorMessage: i18next.t('dalpaks.dalpakIsLocked', {lockedBy: dalpak.lockedBy, dalpak: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) ? i18next.t('table') : i18next.t('counter')}),
                     }
                  }

                  return this.handleDbCommand(async table => {
                     await table.update(dalpak.id, {lockedBy: session.pos.deviceID});
                  }, dalpakId);
               } else {
                  return this.getDalpak(dalpakId);
               }

            }
            async getAll(): Promise<InfraResult<Dalpak[]>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => await table.toArray());
            }

            async getAllDalpakAreas(): Promise<InfraResult<DalpakArea[]>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.DalpakArea, string>) => await table.toArray(), null, 'dalpakAreas');
            }

            async saveDalpakAttributesAndUnlock(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => {
                  let dalpakData:any = {};

                  if (posUtils.isBlank(dalpak.id)) {
                     dalpakData = {...dalpak};
                     dalpakData.id = storage.createUUID();
                  } else {
                     let dbDalpak = await table.get(dalpak.id);
                     dalpakData = {...dbDalpak, ...(_.pick(dalpak, ['name', 'color', 'sortOrder', 'area', 'data']))};
                  }

                  let newDalpak:any = { ...dalpakData, lockedBy: null };

                  await table.put(newDalpak);
                  
                  return newDalpak;
               });
            }

            async saveDalpakAreaAttributes(dalpakArea: DalpakArea): Promise<InfraResult<DalpakArea>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.DalpakArea, string>) => {
                  let dalpakAreaData:any = {};

                  if (posUtils.isBlank(dalpakArea.id)) {
                     dalpakAreaData = {...dalpakArea};
                     dalpakAreaData.id = storage.createUUID();
                  } else {
                     let dbDalpakArea = await table.get(dalpakArea.id);
                     dalpakAreaData = {...dbDalpakArea, ...(_.pick(dalpakArea, ['name', 'color', 'sortOrder']))};
                  }

                  let newDalpakArea:any = { ...dalpakAreaData};

                  await table.put(newDalpakArea);
                  
                  return newDalpakArea;
               }, null, 'dalpakAreas');
            }

            async markSaleAsPrinted(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => await table.update(dalpak.id, { isPrinted: true }), dalpak.id);
            }
            async saveSale(dalpak: Dalpak, unlock: boolean): Promise<InfraResult<Dalpak>> {
               let isPrinted = dalpak.isPrinted && posUtils.isDefined(dalpak.sale);

               let dataToUpdate:any = {
                  sale: dalpak.sale,
                  isPrinted: isPrinted,
               };

               if (unlock) {
                  dataToUpdate.lockedBy = null;
               }

               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => await table.update(dalpak.id, PositiveTS.Shared.DB.checkIfNeedCloneProxy(dataToUpdate)), dalpak.id);
            }

            async deleteDalpak(dalpak: Dalpak): Promise<InfraResult<boolean>> {
               let res = await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => await table.delete(dalpak.id));
               res.data = res.success;
               return res;
            }

            async deleteDalpakArea(dalpakArea: DalpakArea): Promise<InfraResult<boolean>> {
               let res = await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.DalpakArea, string>) => await table.delete(dalpakArea.id), null, 'dalpakAreas');
               res.data = res.success;
               return res;
            }

            async unlock(dalpak: Dalpak): Promise<InfraResult<boolean>> {
               if (this.withLocks) {
                  return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => await table.update(dalpak.id, { lockedBy: null }), dalpak.id);
               } else {
                  return {
                     success: true,
                     data: true,
                     errorMessage: null,
                  };
               }
            }


            private async bulkUpdateDalpaksAttribute(dalpaks) {
               let dalpaksToDestroy = [];
               let dalpaksToCreate = [];
               let dalpaksToUpdate = [];

               for (let dalpak of dalpaks) {
                  if (dalpak[Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.DELETE]) {
                     dalpaksToDestroy.push(dalpak);
                  } else {
                     let dalpakAttributesToUpdate = _.pick(dalpak, ATTRIBUTES_ALLOWED_IN_BULK_UPDATE)
                     dalpakAttributesToUpdate.id = dalpak.id;

                     if (dalpak[Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW]) {
                        dalpaksToCreate.push(dalpakAttributesToUpdate);
                     } else {
                        dalpaksToUpdate.push(dalpakAttributesToUpdate);
                     }
               
                  }
               }

               return await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => {

                  if (dalpaksToDestroy.length > 0) {
                     await table.where('id').anyOf(dalpaksToDestroy.map(d => d.id)).delete();
                  }

                  if (dalpaksToCreate.length > 0) {
                     await table.bulkAdd(dalpaksToCreate);
                  }

                  if (dalpaksToUpdate.length > 0) {
                     for (let dalpak of dalpaksToUpdate) {
                        await table.update(dalpak.id, dalpak)
                     }
                  }

                  return true;
               });

            }

            private async bulkUpdateAreasAttribute(dalpakAreas) {
               let newDalpakAreas = [];
               
               for (let dalpakArea of dalpakAreas) {
                  if (!dalpakArea[Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.DELETE]) {
                     delete dalpakArea[Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW];
                     newDalpakAreas.push(dalpakArea);
                  }
               }

               return await this.bulkPutDalpakAreas(newDalpakAreas);
            }

            async bulkUpdateDalpaksAndAreasAttributes(data: any): Promise<InfraResult<boolean>> {
               let {dalpaks, dalpakAreas} = data;

               let result = await this.bulkUpdateAreasAttribute(dalpakAreas);

               if (!result.success) {
                  return result;
               }

               return await this.bulkUpdateDalpaksAttribute(dalpaks);
            }

            async bulkPut(data: Dalpak[]): Promise<InfraResult<boolean>> {
               let res = await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => {
                  await table.clear();
                  return await table.bulkPut(data as any);
               });

               res.data = res.success;
               return res;
            }

            async bulkPutDalpakAreas(data: DalpakArea[]): Promise<InfraResult<boolean>> {
               let res = await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.DalpakArea, string>) => {
                  await table.clear();                  
                  return await table.bulkPut(data as any);
               }, null, 'dalpakAreas');

               res.data = res.success;
               return res;
            }


            async isCurrentDataValid(): Promise<InfraResult<boolean>> {
               return {
                  success: true,
                  data: true,
                  errorMessage: null,
               };
            }

            async moveDalpaksToArea(dalpaks, newDalpakAreaRailsId) {
               let dalpakIds = dalpaks.map(d => {return d.id})
               let res = await this.handleDbCommand(async (table: Dexie.Table<Storage.Entity.Dalpak, string>) => {                
                  return await table.where('id').anyOf(dalpakIds).modify({area: `${newDalpakAreaRailsId}`})
               }, null, 'dalpaks');

               res.data = res.success;
               return res;
            }

            async handleDbCommand(commnandFunction: Function, idForResult: string = null, table = 'dalpaks'): Promise<InfraResult<any>> {
               try {
                  let result = await commnandFunction(appDB[table]);

                  if (posUtils.isDefined(idForResult)) {
                     result = await appDB[table].get(idForResult);
                  }

                  return {
                     success: true,
                     data: result,
                     errorMessage: null,
                  }
               } catch (err) {
                  console.error(err);
                  Service.Logger.error(err);

                  return {
                     success: false,
                     data: null,
                     errorMessage: i18next.t('generalError'),
                  }
               }
            }
         }
      }
   }
}