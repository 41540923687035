module PositiveTS {
export module Application {
export module Controllers {
export class InitViewController extends AbstractViewControllerTS {
	init (options) {

		if(session.isAndroid)
			PositiveTS.Service.AndroidAddon.fixKeyboardPanning();
			
		$('#init').hide();
	
	}
	resume (options) {
	}
	stop () {
	}
}}}}
PositiveTS.Application.Controllers.instances.InitViewController = new PositiveTS.Application.Controllers.InitViewController();
