module PositiveTS {
    export module Service {
        export module SimplyClubAPI {

            export async function addMember(addMemberReq: AddMemberReq): Promise<BaseResponse> {
                let req = prepareAddMemeberReq(addMemberReq);
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/MemberAdd", "POST", req, headers(), logName("SimplyClub-AddMember"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function updateMember(updateMemberReq: UpdateMemberReq): Promise<BaseResponse> {
                let req = prepareUpdateMemeberReq(updateMemberReq);
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/MemberUpdate", "POST", req, headers(), logName("SimplyClub-UpdateMember"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function switchMemberRecognition(updateMemberCardNumReq: updateMemberCardNumReq): Promise<BaseResponse> {
                updateMemberCardNumReq.auth = initAuth();
                let req = updateMemberCardNumReq;
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/MemberSwitchRecognition", "POST", req, headers(), logName("SimplyClub-UpdateMemberCardNumber"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function searchMemberNew(searchParams: SearchMembersParams): Promise<SearchNewResponse> {
                let req = prepareSearchMembersParams(searchParams);
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/MemberSearchNew", "POST", req, headers(), logName("SimplyClub-SearchMemberNew"));
                let resResult = <SearchNewResponse>res.getJsonBody();
                resResult.sentJoinSMS = !posUtils.isBlank(resResult.AdditionalInfo) && 
                    resResult.AdditionalInfo.some(field => field.FieldId === "sendJoinSMS" && field.FieldValue.includes("true"));
                return resResult;
            }
            export async function searchMembers(searchParams: SearchMembersParams): Promise<SearchResponse> {
                let req = prepareSearchMembersParams(searchParams);
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/MemberSearch", "POST", req, headers(), logName("SimplyClub-SearchMember"));
                return <SearchResponse>res.getJsonBody();
            }
            export async function sendSmsToJoin(smsToJoinReq: SmsToJoinReq): Promise<BaseResponse> {
                let param: SearchParam = {
                    Field: SearchFieldType.CELL_PHONE,
                    Value: smsToJoinReq.phoneNumber,
                }
                smsToJoinReq['searchParams'] = [param]
                delete smsToJoinReq.phoneNumber;
                if (!smsToJoinReq.auth) {
                    smsToJoinReq.auth = initAuth();
                }
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/SendJoinSMS", "POST", smsToJoinReq, headers(), logName("SimplyClub-JoinViaSmsSend"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function getMemberDetails(searchParams: SearchSingleMemberParams): Promise<CustomerDetailsResponse> {
                searchParams['fieldId'] = SearchFieldType.CARD_FIELD;
                searchParams['fieldValue'] = searchParams.card;
                delete searchParams.card;
                if (!searchParams.auth) {
                    searchParams.auth = initAuth();
                }
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Member/MemberGetDetails", "POST", searchParams, headers(), logName("SimplyClub-GetDetailsMember"));
                return <CustomerDetailsResponse>res.getJsonBody();
            }

            export async function subTotal(tran: SubTotalTranReq): Promise<SubTotalResponse> {
                tran.auth = initAuth();
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Transaction/SubTotal", "POST", tran, headers(), logName("SimplyClub-SubTotal"));
                return <SubTotalResponse>res.getJsonBody();
            }
            export async function checkIfCanEndTran(tran: Tran): Promise<BaseResponse> {
                tran.auth = initAuth();
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Transaction/EndTranCheck", "POST", tran, headers(), logName("SimplyClub-CheckTran"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function endTran(tran: Tran): Promise<BaseResponse> {
                tran.auth = initAuth();
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Transaction/EndTran", "POST", tran, headers(), logName("SimplyClub-EndTran"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function cancelTran(tran: CancelTran): Promise<BaseResponse> {
                tran.auth = initAuth();
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Transaction/TranCancel2", "POST", tran, headers(), logName("SimplyClub-CancelTran"));
                return <BaseResponse>res.getJsonBody();
            }
            export async function refundTran(tran: RefundTran): Promise<BaseResponse> {
                tran.auth = initAuth()
                const res = await PositiveTS.Service.HTTPService.
                    makeHttpRequestWithFullResponse(baseUrl() + "Transaction/TranRefund", "POST", tran, headers(), logName("SimplyClub-RefundTran"));
                return <BaseResponse>res.getJsonBody();
            }
            function prepareSearchMembersParams(searchParams: SearchMembersParams): SearchMembersParams {
                searchParams.searchParams = [];
                if (searchParams.searchParamProps.all) {
                    searchParams.searchParams.push({ Field: SearchFieldType.SEARCH_ALL, Value: searchParams.searchParamProps.all });
                } else {
                    if (searchParams.searchParamProps.cardField) {
                        searchParams.searchParams.push({ Field: SearchFieldType.CARD_FIELD, Value: searchParams.searchParamProps.cardField });
                    }
                    if (searchParams.searchParamProps.cellPhone) {
                        searchParams.searchParams.push({ Field: SearchFieldType.CELL_PHONE, Value: searchParams.searchParamProps.cellPhone });
                    }
                    if (searchParams.searchParamProps.phone) {
                        searchParams.searchParams.push({ Field: SearchFieldType.PHONE, Value: searchParams.searchParamProps.phone });
                    }
                    if (searchParams.searchParamProps.personalId) {
                        searchParams.searchParams.push({ Field: SearchFieldType.PERSONAL_ID, Value: searchParams.searchParamProps.personalId });
                    }
                    if (searchParams.searchParamProps.firstName) {
                        searchParams.searchParams.push({ Field: SearchFieldType.FIRST_NAME, Value: searchParams.searchParamProps.firstName });
                    }
                    if (searchParams.searchParamProps.lastName) {
                        searchParams.searchParams.push({ Field: SearchFieldType.LAST_NAME, Value: searchParams.searchParamProps.lastName });
                    }
                }
                delete searchParams.searchParamProps;
                searchParams.searchParams.forEach(param => param.Operator = 0);
                if (!searchParams.auth) {
                    searchParams.auth = initAuth();
                }
                return searchParams;
            }
            function prepareAddMemeberReq(addMemberReq: AddMemberReq): AddMemberReq {
                addMemberReq.memberInfo.MemberFields = [];
                for (const key of Object.keys(addMemberReq.memberInfo.MemberFieldsProps)) {
                    const val = addMemberReq.memberInfo.MemberFieldsProps[key];
                    addMemberReq.memberInfo.MemberFields.push({ FieldId: key, FieldValue: val })
                }
                delete addMemberReq.memberInfo.MemberFieldsProps;
                if (!addMemberReq.auth) {
                    addMemberReq.auth = initAuth();
                }
                return addMemberReq;
            }
            function prepareUpdateMemeberReq(updateMemberReq: UpdateMemberReq): UpdateMemberReq {
                updateMemberReq.newMemberInfo.MemberFields = []
                for (const key of Object.keys(updateMemberReq.newMemberInfo.MemberFieldsProps)) {
                    const val = updateMemberReq.newMemberInfo.MemberFieldsProps[key];
                    updateMemberReq.newMemberInfo.MemberFields.push({ FieldId: key, FieldValue: val })
                }
                // DOES THE ENUM TURN TO STRING OR A NUMBER WHEN USING TO STRING?? In Typescript
                updateMemberReq.fieldId = updateMemberReq.fieldId.toString();
                delete updateMemberReq.newMemberInfo.MemberFieldsProps;
                if (!updateMemberReq.auth) {
                    updateMemberReq.auth = initAuth();
                }
                return updateMemberReq;
            }
            function initAuth(): Auth {
                return {
                    AuthType: 1,
                    Account: {
                        User: jsonConfig.getVal(jsonConfig.KEYS.simplyClubUser),
                        Password: jsonConfig.getVal(jsonConfig.KEYS.simplyClubPassword),
                    },
                    Terminal: {
                        AccountId: jsonConfig.getVal(jsonConfig.KEYS.simplyClubAccountId),
                        TerminalType: 0,
                    }
                };
            }
            function baseUrl() {
                let url = session.pos.simplyClubUrl;
                if (url.endsWith("/") === false) url += "/"
                return url;
            }
            function headers() { return { "accept": "application/json", "Content-Type": "application/json" }; }
            /** Adds a log name with a prefix of the Pos's device ID, i.e. "Pos's name" */
            function logName(name: string) {
                return session?.pos?.deviceID + "-" + name;
            }
        }
    }
}