module PositiveTS {
  export module Promotions {
  export module Templates {
  export class KneKabelNew extends TemplateAbstract {
    
    private minBuyQuantity:number
    private minBuyAmount:number
    private allowMultipleTimesPerSale:boolean
    
    private getQty:number;
    private allItems:Array<Storage.Entity.SaleItem>
    private buyItems:Array<Storage.Entity.SaleItem>
    private getItems:Array<Storage.Entity.SaleItem>
    private getPotentialSalePromotions:boolean
  
    constructor(initParameters) {
      super(initParameters)
      this.allItems = initParameters.updatedSaleItems;
      this.getPotentialSalePromotions = initParameters.getPotentialSalePromotions;
    }
  
    calculatePromotions() {
      //yalla azov oti me shtoyot
    }
  
    run(promotion:Storage.Entity.Promotion) {
      
      this.buyItems = [];
      this.getItems = [];
      this.minBuyQuantity = Number(promotion.minimumBuyQuantity);
      this.minBuyAmount = Number(promotion.minimumBuyAmount);
      this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
      let allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
      this.getQty = promotion.quantitySal2;
      


      if (!allowWithOtherPromotions) {
        this.allItems = this.allItems.filter(saleItem => !((<any>saleItem).hasPromotion))
      }
      
      for (let si of this.allItems) {
        if (si.item.promoBuy.split("&").includes(promotion.code)) {
          this.buyItems.push(si);
        }

        if (si.item.promoGet.split("&").includes(promotion.code)) {
          this.getItems.push(si);
        }
      }
  

      if (this.minBuyAmount == 1) { //condition is quantity
        //build largest get group in multiple of getQty - build group by order accoring to condition:
        //if the buy condition is quantity - get the cheapest items first. don't re-run with expensive first.
        let [buyGroup,getGroup] = this.buildGroups(KneKabelNew.fromCheapToExpensive)    
        
        if (this.getPotentialSalePromotions) {
          if (getGroup.length == 0) {
            if (buyGroup.length >= this.minBuyQuantity) {
              this.parameters.promoGroups = this.createPromoGroups(buyGroup,getGroup,promotion);
            }
          }
        }
        else {
          //if group size is smallest and can't apply - finish.
          while (getGroup.length > 0) {
          
            //test if we can apply the promotion in the right multiple
            if (buyGroup.length >= Math.floor(this.minBuyQuantity*(getGroup.length/this.getQty))) {
              //if yes - build the promo groups (lots of get groups and one big buy group) and finish
              while ((buyGroup.length) > Math.floor(this.minBuyQuantity*(getGroup.length/this.getQty))) {
                buyGroup.splice(-1,1);
              }
              this.parameters.promoGroups = this.createPromoGroups(buyGroup,getGroup,promotion);
              return;
            }
            else {
              //if no - reduce the group size from the end and test again.
              for (let i=0; i<this.getQty; i++) {
                getGroup.splice(-1,1)
              }
            }
          }
        }
      }
      else {
        //if the buy condition is amount  - get the expensive items first, and re-run with cheap items first.
        //return the best result
        
        let promoGroupsExpensiveToCheap = this.getBestPromoGroupForAmount(KneKabelNew.fromExpensiveToCheap,promotion)
        let promoGroupsCheapToExpensive = this.getBestPromoGroupForAmount(KneKabelNew.fromCheapToExpensive,promotion)
        
        if (_.sumBy(promoGroupsExpensiveToCheap,'discountAmountForGroup') >= _.sumBy(promoGroupsCheapToExpensive,'discountAmountForGroup')) {
          this.parameters.promoGroups = promoGroupsExpensiveToCheap;
        }
        else {
          this.parameters.promoGroups = promoGroupsCheapToExpensive;
        }
        
      }

    }

    private getBestPromoGroupForAmount(sortFunc:(a: Storage.Entity.SaleItem, b: Storage.Entity.SaleItem) => number,
                                       promotion:Storage.Entity.Promotion) {
      let [buyGroup,getGroup] = this.buildGroups(sortFunc)   
      let promoGroups = [] 
      if (this.getPotentialSalePromotions) {
        if (getGroup.length == 0) {
          let totalBuyGroup = _.sumBy(buyGroup,'unitPrice')
          if (totalBuyGroup >= this.minBuyAmount) {
            promoGroups = this.createPromoGroups(buyGroup,getGroup,promotion);
          }
        }
      }
      else {
        while (getGroup.length > 0) {
          let totalBuyGroup = _.sumBy(buyGroup,'unitPrice')
          //test if we can apply the promotion in the right multiple
          if (totalBuyGroup >= Math.floor(this.minBuyAmount*(getGroup.length/this.getQty))) {
            //if yes - build the promo groups (lots of get groups and one big buy group) and finish
            promoGroups = this.createPromoGroups(buyGroup,getGroup,promotion);
            break;
          }
          else {
            //if no - reduce the group size from the end and test again.
            for (let i=0; i<this.getQty; i++) {
              buyGroup = buyGroup.concat(getGroup.splice(-1,1))
            }
          }
        
        }
      }

      return promoGroups;

    }

    private static fromCheapToExpensive(a:Storage.Entity.SaleItem,b:Storage.Entity.SaleItem)  {
      if (a.unitPrice != b.unitPrice) {
        return a.unitPrice - b.unitPrice;
      }

      return b.rowNumber - a.rowNumber;
    }

    private static fromExpensiveToCheap(a:Storage.Entity.SaleItem,b:Storage.Entity.SaleItem)  {
      if (a.unitPrice != b.unitPrice) {
        return b.unitPrice - a.unitPrice;
      }

      return b.rowNumber - a.rowNumber;
    }

    private buildGroups(sortFunc: (a: Storage.Entity.SaleItem, b: Storage.Entity.SaleItem) => number) {
      let getGroup:Array<Storage.Entity.SaleItem> = []
      let buyGroup:Array<Storage.Entity.SaleItem> = []
      let tempArr = []
      let tempGetQty = this.getQty;
      
      this.getItems = this.getItems.sort(sortFunc)
      for (let saleItem of this.getItems) {
        tempArr.push(saleItem)
        if (tempGetQty > 1) {
          tempGetQty--;
        }
        else {
          getGroup = getGroup.concat(tempArr);
          tempArr = [];
          tempGetQty = this.getQty;
          if (!this.allowMultipleTimesPerSale) {
            break;
          }
        }
      }

      for (let saleItem of this.buyItems) {
        if (getGroup.findIndex(si => si.id == saleItem.id) == -1) {
          buyGroup.push(saleItem);
        }
      }
      return [buyGroup,getGroup];
       
    }
  
  
    private createPromoGroups(buyGroup,getGroup, promotion) {
  
      let promoGroups = []
      let buyPromoGroup = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
  
      buyPromoGroup.totalPriceForItemsBeforeDiscount += _.sumBy(buyGroup,'unitPrice')
      buyPromoGroup.discountAmountForGroup = 0;
  
      
      buyGroup.forEach(item => {
        this.addToItemsCounter(item,buyPromoGroup.itemsCounter)
        this.addToRowValueCounter(item,buyPromoGroup.rowValueCounter)
      });
      
      
      promoGroups.push(buyPromoGroup);

      let tempGetQty = this.getQty;
      let tempArr = []
      
  

      for (let saleItem of getGroup) {
        tempArr.push(saleItem);
        if (tempGetQty > 1) {
          tempGetQty--;
        }
        else {
          promoGroups.push(this.createGetGroup(promotion,tempArr));
          tempArr = [];
          tempGetQty = this.getQty;
        }
      }
      if(jsonConfig.getVal(jsonConfig.KEYS.discountOnlyGetGroupOnPromotions)) {
        return promoGroups;
      }
      else {
        let groupsTotalAmount = 0; 
        let groupsTotalDiscounts = 0;
        promoGroups.forEach((group) => {
          groupsTotalAmount += group.totalPriceForItemsBeforeDiscount;
          groupsTotalDiscounts += group.discountAmountForGroup;
        });
        promoGroups.forEach((group) => {
          let groupPrencentageOfTotal = group.totalPriceForItemsBeforeDiscount / groupsTotalAmount;
          group.discountAmountForGroup = groupsTotalDiscounts * groupPrencentageOfTotal;
        });
        return promoGroups;
      }
    }

    private createGetGroup(promotion,tempArr) {
      let getPromoGroup = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
      getPromoGroup.totalPriceForItemsBeforeDiscount += _.sumBy(tempArr,'unitPrice');
      getPromoGroup.discountAmountForGroup = this.getDiscountValue(getPromoGroup.totalPriceForItemsBeforeDiscount, promotion);
      tempArr.forEach(item => {
        this.addToItemsCounter(item,getPromoGroup.itemsCounter);
        this.addToRowValueCounter(item,getPromoGroup.rowValueCounter);
      });
      return getPromoGroup;
    }

  
    private getDiscountValue(totalPriceForRun, promotion:Storage.Entity.Promotion) {
      switch (promotion.discountType) {
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
          return Number(promotion.discountValue);
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
          return Math.max(0,totalPriceForRun-Number(promotion.discountValue))
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
          return totalPriceForRun*Number(promotion.discountValue)/100.0;
      }
    }
  
  
  }
  }}}
  
