module PositiveTS {
	export module Application {
	export module Controllers {
	export class PosSearchInvoicesFormViewController extends AbstractViewControllerTS {
	// --------------------------------------------------------------------
	// Controller Life-Cycle
	// --------------------------------------------------------------------
	init(options) {
		var aThis = posSearchInvoicesFormVC;

		$( "#pos-search-invoices-form-fromDate" ).datepicker({
			dateFormat: 'dd/mm/yy',
			changeMonth: true,
			onClose: function( selectedDate ) {
				$( "#pos-search-invoices-form-toDate" ).datepicker( "option", "minDate", selectedDate );
			}
		});
		$( "#pos-search-invoices-form-toDate" ).datepicker({
			dateFormat: 'dd/mm/yy',
			changeMonth: true,
			onClose: function( selectedDate ) {
				$( "#pos-search-invoices-form-store-id" ).datepicker( "option", "maxDate", selectedDate );
			}
		});

		var btns = [];
		btns.push(new Components.PositiveRadio.Btn(i18next.t("all"),"0",true));
		btns.push(new Components.PositiveRadio.Btn(i18next.t("cash"),"2",false));
		btns.push(new Components.PositiveRadio.Btn(i18next.t("credit"),"1",false));
		btns.push(new Components.PositiveRadio.Btn(i18next.t("vouchers"),"3,6,7,8,9",false));
		btns.push(new Components.PositiveRadio.Btn(i18next.t("creditVoucher"),"4",false));
		btns.push(new Components.PositiveRadio.Btn(i18next.t("check"),"5",false));
		PositiveTS.VueInstance.$refs.posSearchInvoicesFormInvoiceType.btns = btns;
					
		if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
			var deliveryTypesBtns = [];
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("all"),"0",true));
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("delivery.delivery"),Service.Delivery.DeliveryType.delivery,false));
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("delivery.TA"),Service.Delivery.DeliveryType.ta,false));
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("delivery.externalTenbisBtn"),String([Service.Delivery.DeliveryType.externalDelivery,Service.Delivery.DeliveryType.externalTA]),false));
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("delivery.externalPaiditBtn"),String([Service.Delivery.DeliveryType.externalPaiditDelivery,Service.Delivery.DeliveryType.externalPaiditSitting,Service.Delivery.DeliveryType.externalPaiditTA]),false));
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("delivery.externalPluxeeBtn"),String([Service.Delivery.DeliveryType.externalCibus,Service.Delivery.DeliveryType.externalCibusTA]),false));
			deliveryTypesBtns.push(new Components.PositiveRadio.Btn(i18next.t("delivery.externalWoltBtn"),String([Service.Delivery.DeliveryType.externalWolt,Service.Delivery.DeliveryType.externalWoltTA]),false));

			PositiveTS.VueInstance.$refs.posSearchInvoicesFormDeliveryType.btns = deliveryTypesBtns;
		}

		if(session.pos.isRoshemet){
			$("#pos-search-invoices-form-store-container").hide();
			$("#pos-search-invoices-form-store-id-container").hide();
			$("#pos-search-invoices-customer-phone").hide();
			$("#pos-search-invoices-item-code").hide();
		}

		aThis.initalizeActions();
		aThis.defaultValues();
	}

	defaultValues() {
		var store_id = $('#pos-search-invoices-form-store-id').val();
		if (store_id === null || store_id === undefined || store_id == "") {
			// $('#pos-search-invoices-form-store').val(session.pos.storeName);
			$('#pos-search-invoices-form-store-id').val(session.pos.storeID);

			// call focus out event to update store name
			$('#pos-search-invoices-form-store-id').trigger('focusout');
		}

		var today = new Date();
		var yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);

		var todayDate = new PositiveTS.DateUtils(today);
		var yesterdayDate = new PositiveTS.DateUtils(yesterday);

		$('#pos-search-invoices-form-fromDate').val(yesterdayDate.shortFormat());
		$('#pos-search-invoices-form-toDate').val(todayDate.shortFormat());

		let disable_stores = jsonConfig.getVal( jsonConfig.KEYS.posSearchInvoicesDisableStores );
		if(disable_stores){
			$('#pos-search-invoices-form-store').prop('disabled', true);
			$('#pos-search-invoices-form-store-id').prop('disabled', true);
		}


	}
	resume(options?) {
		var aThis = posSearchInvoicesFormVC;
	}

	stop() {
		var aThis = posSearchInvoicesFormVC;

		aThis.unInitalizeActions();
	}
	back() {
		posSearchInvoicesVC.formRendered = true;
		// Go back!
		pNavigator.back();
	}
	createSearchObjectFromDom() {
		var store_id = $('#pos-search-invoices-form-store-id').val();

    var dictValidation = {isValid: true,
                        consoleError: "",
                        UserMsg: ""};

		if (store_id === null || store_id === undefined) {
		  dictValidation.isValid = false;
		  dictValidation.consoleError = 'unable to search invoices without store';
		  dictValidation.UserMsg = i18next.t("searchInvoicesStoreSelectError");
		}

    var CreditCard4Dig  = $('#pos-search-invoices-form-CreditCard4Dig').val();
    if (CreditCard4Dig &&
         (
           isNaN( CreditCard4Dig ) ||
           CreditCard4Dig.toString().length !== 4
         )
      ){
        dictValidation.isValid = false;
        dictValidation.consoleError = '4 digit value for credit card is invalid';
        dictValidation.UserMsg = i18next.t("searchInvoices4digCcError");
     }

    if ( !dictValidation.isValid ) {
                 // --- Failed to fetch sale items and payments
       console.error( dictValidation.consoleError );

        // Tell the user
        app.showAlert({
          header: i18next.t('error'),
          content: dictValidation.UserMsg,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        }, null, null);

        return null;
    }

		let from_date:any = $('#pos-search-invoices-form-fromDate').datepicker("getDate");
		let to_date:any = $('#pos-search-invoices-form-toDate').datepicker("getDate");

		let from_amount = $('#pos-search-invoices-form-fromAmount').val();
		let to_amount 	= $('#pos-search-invoices-form-toAmount').val();

		let pickupPhone = $('#pos-search-invoices-form-pickupPhone').val();
		let pickupId = $('#pos-search-invoices-form-pickupId').val();

		if (from_date != "" && from_date != null) {
			from_date.setHours(0,0,0,0);
			from_date.setHours(from_date.getHours() - from_date.getTimezoneOffset() / 60); // fixed for the toJSON timestamp
		}
		if (to_date != "" && to_date != null) {
			to_date.setHours(23,59,59,999);
			to_date.setHours(to_date.getHours() - to_date.getTimezoneOffset() / 60) // fixed for the toJSON timestamp
		}
		let invoice_number 	= $('#pos-search-invoices-form-invoiceNo').val();
		let customer_name 	= $('#pos-search-invoices-form-customerName').val();
		let customer_phone 	= $('#pos-search-invoices-form-customerPhone').val();
		let items 	= $('#pos-search-invoices-form-items').val();	
		let dedicated_to = (<HTMLInputElement>document.getElementById("pos-search-invoices-form-dedicatedTo")).value


		var searchObject = {
			tenant_id: session.pos.tenantID,
			company_id: session.pos.companyID,
			store_id: store_id,
			delivery_pos: jsonConfig.getVal(jsonConfig.KEYS.isDelivery)
		};

		if (invoice_number != "") {
			$.extend(searchObject, {
				invoice_number: invoice_number != "" ? invoice_number : undefined
			});
		} else {
			$.extend(searchObject, {
				from_date: from_date != "" && from_date != null ? from_date.toJSON() : undefined,
				to_date: to_date != "" && to_date != null ? to_date.toJSON() : undefined,
				min_amount: from_amount != "" ? from_amount : undefined,
				max_amount: to_amount != "" ? to_amount : undefined,
				customer_name: customer_name != "" ? customer_name : undefined,
				customer_phone: customer_phone != "" ? customer_phone : undefined,
				item_id: items != "" ? items : undefined,
				CreditCard4Dig: CreditCard4Dig != "" ? CreditCard4Dig : undefined,
				pickup_phone: pickupPhone != "" ? pickupPhone : undefined,
				pickup_id: pickupId != "" ? pickupId : undefined,
				by_payment_method: PositiveTS.VueInstance.$refs.posSearchInvoicesFormInvoiceType.getSelected().id,
				dedicated_to: dedicated_to != "" ? dedicated_to : undefined
			});

			if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
				let order_number = $('#pos-search-invoices-form-orderNumber').val();
				let street = $('#pos-search-invoices-form-street').val();
				let city = $('#pos-search-invoices-form-city').val();
				let deliveryType = PositiveTS.VueInstance.$refs.posSearchInvoicesFormDeliveryType.getSelected().id

				$.extend(searchObject,{
					by_delivery_type: deliveryType  != "0" ?  deliveryType: undefined,
					order_number: order_number != "" ? order_number: undefined,
					street: street != "" ? street: undefined,
					city: city != "" ? city: undefined
				})
			}
		}

		return searchObject;
	}
	continue() {
		var aThis = posSearchInvoicesFormVC;

		var isValidStore = $('#pos-search-invoices-form-store-id').data('valid-id');
		if (!isValidStore) {
			// Tell the user
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('searchInvoicesStoreNotFoundError', {STORE_ID: $('#pos-search-invoices-form-store-id').val()}),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
			return;
		}

		let from_date:any = $('#pos-search-invoices-form-fromDate').datepicker("getDate");
		let to_date = $('#pos-search-invoices-form-toDate').datepicker("getDate");
		var isDateRangeValid = (moment(to_date).diff(from_date,"month") < 3);
		if (!isDateRangeValid) {
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('searchInvoiceDateRangeNotValid'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
			return;
		}

		var searchObject = aThis.createSearchObjectFromDom();

		if (searchObject === null || searchObject === undefined) {
			return;
		}

		posSearchInvoicesVC.searchObject = searchObject;
		posSearchInvoicesVC.showingResults = true;
		posSearchInvoicesVC.formRendered = true;

		// Go back!
		pNavigator.back();
	}
	storeIdChanged() {
		var aThis = posSearchInvoicesFormVC;

		var store_id = $('#pos-search-invoices-form-store-id').val();

		var storeModel = new PositiveTS.Storage.Entity.Store();

		try {
			let stores = storeModel.fetchByTenantIDAndCompanyIDAndStoreID(session.pos.tenantID, session.pos.companyID, store_id);

			if (stores === null || stores === undefined || stores.length < 1) {
				aThis.selectStore(store_id, 'store not found', false);
				return;
			}

			let store = stores[0];
			aThis.selectStore(store.storeID, store.storeName);

		} catch (err) {
			aThis.selectStore(store_id, 'store not found', false);
		}
	}
	initalizeActions() {
		$('#pos-search-invoices-form-back').click(posSearchInvoicesFormVC.back);
		$('#pos-search-invoices-form-continue').click(posSearchInvoicesFormVC.continue);

		$('#pos-search-invoices-form-store-id').focusout(posSearchInvoicesFormVC.storeIdChanged);

		this.initInputTabbing("pos-search-invoices-form-store-id","pos-search-invoices-form-store");
		this.initInputTabbing("pos-search-invoices-form-fromAmount","pos-search-invoices-form-toAmount");
		this.initInputTabbing("pos-search-invoices-form-toAmount","pos-search-invoices-form-invoiceNo");
		this.initInputTabbing("pos-search-invoices-form-invoiceNo","pos-search-invoices-form-customerName");
		this.initInputTabbing("pos-search-invoices-form-customerName","pos-search-invoices-form-customerPhone");
		this.initInputTabbing("pos-search-invoices-form-customerPhone","pos-search-invoices-form-items");
		this.initInputTabbing("pos-search-invoices-form-items","pos-search-invoices-form-CreditCard4Dig");
		this.initInputTabbing("pos-search-invoices-form-CreditCard4Dig","pos-search-invoices-form-continue");
		
	}
	selectInput(input){
		$("#" + input).click();
	}
	initInputTabbing(source,dest){
		$('#' + source).on('keyup', function (e) {
			console.log(e.keyCode);
			if (e.keyCode == 13) {
				$("#" + dest).focus();
				$("#" + dest).click();
			}
		});
	}
	unInitalizeActions() {
		$('#pos-search-invoices-form-back').unbind('click');
		$('#pos-search-invoices-form-continue').unbind('click');
		$('#pos-search-invoices-form-store-id').unbind('focusout');

		$('#pos-search-invoices-form-store-id').unbind('keyup');
		$('#pos-search-invoices-form-fromAmount').unbind('keyup');
		$('#pos-search-invoices-form-toAmount').unbind('keyup');
		$('#pos-search-invoices-form-invoiceNo').unbind('keyup');
		$('#pos-search-invoices-form-customerName').unbind('keyup');
		$('#pos-search-invoices-form-customerPhone').unbind('keyup');
		$('#pos-search-invoices-form-items').unbind('keyup');
		$('#pos-search-invoices-form-CreditCard4Dig').unbind('keyup');
		
	}
	selectStore(id, name, isValid?) {
		if (isValid !== false) {
			isValid = true;
		}

		$('#pos-search-invoices-form-store').val(name);
		$('#pos-search-invoices-form-store-id').val(id);
		$('#pos-search-invoices-form-store-id').data('valid-id', isValid);

	}
}
}}};

declare var posSearchInvoicesFormVC:PositiveTS.Application.Controllers.PosSearchInvoicesFormViewController;
posSearchInvoicesFormVC = new PositiveTS.Application.Controllers.PosSearchInvoicesFormViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.PosSearchInvoicesFormViewController = posSearchInvoicesFormVC;
