/// <reference path="../Modules/promotionWithBarcode" />
class EmpNoExitDialog {
    private ui:any;
    private empsNoExitTable:any;
    constructor() {
        //init the jquery dialog
        $(() => {
            this.ui = $( "#emp-no-exit-dialog" ).dialog({
                autoOpen: false,
                modal: true,
                dialogClass: 'positive-dialog',
                width: '50%',
                height: 'auto',
                resizable: false,
                closeOnEscape: false,
                draggable: false
            });  
        });    
    }
	close() {
		$('#emp-no-exit-dialog-cancel').unbind('click');
        $('#emp-no-exit-dialog-continue').unbind('click');
        this.ui.dialog('close');
	}

	open(promotions) {
		var deferred = Promise.defer();


        this.empsNoExitTable = $('#emp-no-exit-dialog-tableview').tableview({
            isMultiSelection: true
        });

        this.empsNoExitTable.setNumberOfVisibleRows(7);

        this.empsNoExitTable.empty();

        $('#emp-no-exit-dialog-continue').click(function () {

            empNoExitDg.close();

            var selectedEmps = empNoExitDg.getSelectedEmps();

            deferred.resolve(selectedEmps);
        });

        $('#emp-no-exit-dialog-cancel').click(() => {
            this.close();
            deferred.reject(new Error(app.userCancelledGlobalMessage));
        });

        this.ui.dialog('open');

        empNoExitDg.loadPromotionsToView(promotions);

        return deferred.promise;
	}

    private getSelectedEmps() {
        var promotionsTable = $('#emp-no-exit-dialog-tableview').tableview();

        var selectedRow = promotionsTable.getSelectedRow();

		if (selectedRow.length === 0) {
			return [];
		} else {
            var selectedEmps = [];
            for (var i = 0; i < selectedRow.length; i++) {
                var row = selectedRow[i];
                selectedEmps.push($(row).data('emp'));
            };
			return selectedEmps;
		}
    }

    private loadPromotionsToView(emps) {
        var promotionsTable = $('#emp-no-exit-dialog-tableview').tableview();
        promotionsTable.empty();

        for (var i = 0; i < emps.length; i++) {
            var row = promotionsTable.addRow(posUtils.sanitizeTableViewRow([emps[i].name]));

            row.data('emp', emps[i]);
        };
    }

}

declare var empNoExitDg:EmpNoExitDialog;
empNoExitDg = new EmpNoExitDialog();
