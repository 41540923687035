module PositiveTS {
	
	export module Service {

		export module DutyFree {

			export function isDutyFree (): boolean { 
				return jsonConfig.getVal(jsonConfig.KEYS.isDutyFree)
			}


			export function isPickupEnabled (): boolean { 
				return jsonConfig.getVal(jsonConfig.KEYS.isDutyFree) && jsonConfig.getVal(jsonConfig.KEYS.isPickupEnabledDutyFree)
			}

			export function getMaxPriceForPickupItem(): number | null {
				return Number(jsonConfig.getVal(jsonConfig.KEYS.maxPriceForDutyFreePickupItem));
			}
			
			export function clearFlightCardFromSale () {

				if (!isDutyFree()){
					return 
				}

				let jsondata = JSON.parse(posVC.sale.jsondata)
				delete jsondata['flight']
				delete jsondata['flights']
                posVC.sale.jsondata = JSON.stringify(jsondata)
                Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
			}

			export function checkIfNeedOpenFlightCardDialog () {
				if (isDutyFree() && !hasFlightOnSale()){
					openFlightCardDialog()
				}
			}

			export function openFlightCardDialog (): void {
Pinia.componentsStore.openComponent( {componentName:"flightCardDialog", args: []})
			}
      
			class FlightBarcodeValidator {

				barcode: string
				valid: boolean
				error: string
				flight_date: any
				now: any

				constructor (barcode: string) {
					this.init(barcode)
				}
	
				parse_number_of_days_from_year_start () {
					let d1 = this.barcode.substr(44,3)
					let d2 = this.barcode.substr(44,2)
			
					if (posUtils.isNumeric(d1)){
						return parseInt(d1)
					}
			
					if (posUtils.isNumeric(d2)){
						return parseInt(d2);
					}
			
					this.valid = false
					this.error = "NO_VALID_FLIGHT_DATE"
					return false
				}
			
				calc_fligh_date (days_from_year_start) {
					return moment()
						.startOf('year')
						.add(days_from_year_start - 1, 'days')
				}
			
				today_is_to_long_before_the_flight_date (dt) {
					//true if today date old then two days from flight date
					return this.now.isBefore(dt.clone().add(-1,'days'),'date')
				}
			
				today_greater_from_flight_date (dt) {
					//true if today date greater then flight date 
					return this.now.isAfter(dt, 'date')
			  	}
			
				init (barcode: string): void {
					this.valid = true
					this.error = ""
					this.barcode = barcode.trim()
					this.now = moment()

					if (!this.barcode){
						this.error = "empty_barcode"
						this.valid = false
						return 
					}
				
					let days_from_year_start = this.parse_number_of_days_from_year_start();
					if (!days_from_year_start) {
						this.error = "could_not_parse_days"
						this.valid = false
						return
					}
			
					let flight_date = this.calc_fligh_date(days_from_year_start);
					this.flight_date = flight_date
					
					if (this.today_is_to_long_before_the_flight_date(flight_date)) {
						this.error = "today is to long before the flight date"
						this.valid = false
						return
					}

					if (this.today_greater_from_flight_date(flight_date)) {
						this.error = "today_greater_from_flight_date"
						this.valid = false
						return
					}	

				}
				
			}

			export function isFlightBarcodeValid (barcode: string): boolean {
				let validator = new FlightBarcodeValidator(barcode)
				console.log('isFlightBarcodeValid error:',validator.error)
				return validator.valid
			}
			
			export function isPassportIdValid (passportId: string): boolean {
				passportId = passportId.trim()
				return passportId.length > 4 && passportId.length <= 50
			}

			export function getNumberFlightsOnSale () {
				let flightsNumber = 0
				const jsondata = JSON.parse(posVC.sale.jsondata)
				if (Array.isArray(jsondata.flights)){
					flightsNumber = jsondata.flights.length
				}
				return flightsNumber
			}

			function hasFlightOnSale (): boolean {
				const jsondata = JSON.parse(posVC.sale.jsondata)	
				return !_.isEmpty(jsondata.flight)
			}

			export function togglePickup (saleItem: Storage.Entity.SaleItem, isPickup: boolean = null): void {
				let toggle: boolean = Boolean(isPickup === null ? !saleItem.isPickup : isPickup);
				saleItem.isPickup = toggle;
				posVC.cleanDuplicateItemRowsDueToPickupManipulation(saleItem);
				posVC.persistSaleItem(saleItem) // Persist the sale item
			}

		}
		
	}

}


