module PositiveTS {
  export module Components {
    export module QrDialog {
      const dialogSelector = "qr-dialog"

      function initData() {
        return {
          textToDisplay: ""
        }
      }

      export function create() {

        let component = {
          template: JST.qrDialog(),
          mixins: [keyPadMixin],
          components: {
            QrcodeVue
          },
          methods: {
            open(text) {
              this.textToDisplay = text
              $(document).unbind('keypress');      
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            cancel() {
              this.resolveFunc(null);
              return this.close();
            },
            async confirmAndClose() {
              this.resolveFunc();
              return this.close();
            },
            async exitOnEscape() {
              this.resolveFunc();
              return this.close();
            }
          },
          data: initData,
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }
        }

        VueApp.component('qr-dialog', component)

      }
    }
  }
}
