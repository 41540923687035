module PositiveTS {
  export module Components {
    export module ClubMemberSelfServiceDialog {

      const STEPS = {
        SELECT_CUSTOMER: 0,
        KEYPAD_INPUT: 1,
      }

      export function create() {

        let component = {
          template: JST.ClubMemberSelfServiceDialog(),
          components: {
            selfServicePage: SelfServicePage.getComponent(),
            positiveIcon: PositiveIcon.getComponent(),
            selfServiceKeypad: SelfServiceKeypad.getComponent(),
          },
          data() {
            return {
              customerCard: '',
              step: STEPS.SELECT_CUSTOMER,
              STEPS,
              club: 'positive',
              loading: false,
              resolveFunc: null,
              rejectFunc: null,
            }
          },
          methods: {
            open(club: string = 'positive', step = STEPS.SELECT_CUSTOMER) {
              return new Promise((resolve,reject) => {      
                this.cleanData();
                this.club = club;
                this.step = step;
                this.$el.show();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            },
            close(resolveResult?: Types.ClubMemberSelfServiceDialogResult) {
              this.resolveFunc(resolveResult);
              this.$el.close();
            },
            goBack() {
              this.setStep(STEPS.SELECT_CUSTOMER);
            },
            setStep(step) {
              this.step = step;
            },
            occasionalCustomerSelected() {
              let resolveResult: Types.ClubMemberSelfServiceDialogResult = { occasionalCustomer: true };
              this.close(resolveResult);
            },
            async searchAndSetCustomer(searchInput) {
              try {
                if (!posUtils.isValidIsraelMobilePhone(searchInput) || searchInput.trim() == "") {
                  await this.showError(i18next.t("positiveCustClub.phoneInvalid"))
                  return;
                }
                this.loading = true
                switch (this.club) {
                  case 'positive':
                    await this.searchPositiveCustomer(searchInput);
                    break;
                  case 'valuecard':
                    await this.searchValueCardCustomer(searchInput);
                    break;
                  case 'hakafa': 
                    await this.searchHakafaCustomer(searchInput);
                    break;
                }
              } catch (err) {
                Service.Logger.error(err);
                await this.showError(i18next.t('generalError'))
              }
              this.loading = false
            },

            async searchValueCardCustomer(phoneNumber) {
              try {
                let customer = await PositiveTS.Service.ValueCardV5Service.getCardInformationEx(phoneNumber);
                this.loading = false;
                let valuecardResponse;
                if (jsonConfig.getVal(jsonConfig.KEYS.assetsScreenConfirmation)) {
                  valuecardResponse = await Pinia.componentsStore.openComponent( { componentName: "valueCardAssetsScreen", args: [customer] });
                } else {
                  valuecardResponse = 'confirm'
                }

                if (valuecardResponse == 'confirm') {
                  var vcc = new PositiveTS.Service.ValueCardCustomerClubSelect(posVC.sale);
                  return vcc.setCustomerOnSale(customer)
                    .then(() => {
                      this.close();
                      posVC.loadSale()
                    })
                }
              } catch (err) {
                this.loading = false
                await this.showError(i18next.t('clubMemberSelfServiceDialog.memberNotFound'))
                return;
              }
            },

            async searchPositiveCustomer(phoneNumber) {
              let res = await PositiveTS.Storage.Entity.PositiveCustomer.search(phoneNumber);
              this.loading = false
              switch (res.result.i_return_code) {
                case "1":
                  let customer = res.result.customers[0];
                  await Service.CustomerClub.renewMemberClub(customer)
                  await Service.CustomerClub.putCustomerInSale(customer)
                  this.close()
                  break;
                case "4":
                  await this.showError(this.$t('clubMemberSelfServiceDialog.areYouNotMemberClub'));
                  this.close();
                  break;
                case "2":
                  await this.showError(res.result.s_return_msg);
                  break;
                default:
                  await this.showError(i18next.t('error'));
              }
            },
            async searchHakafaCustomer(searchInput) {
              let customer = await PositiveTS.Storage.Entity.HakafaCustomer.getCustomerByPhoneOrCustomerNumber(searchInput)
              if (customer) {
                customer.amount = session.fixedNumber(customer.amount);
                let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
                const posCustomer = await Service.Hakafa.convertToPosStructure(customer);
                await customerClubService.setCurrentSelectedCustomer(posCustomer);
                await posVC.saleUpdated();
                this.close()
              } else {
                await this.showError(i18next.t('clubMemberSelfServiceDialog.memberNotFound'));
              }
            },
            async showError(content, header = i18next.t('error')) {
              app.hideLoadingMessageDialog();
              await app.showAlertDialog({
                header,
                content,
                hideCancelButton: true
              });

            },
            cleanData() {
              this.setStep(STEPS.SELECT_CUSTOMER);
              this.customerCard = '';
            }
          },
          computed: {
            titles() {
              if (this.step == STEPS.KEYPAD_INPUT) {
                let placeHolder = this.$t('clubMemberSelfServiceDialog.phone');
                let subtitle = this.$t('clubMemberSelfServiceDialog.pleaseTypePhone');
                if (this.club == 'hakafa') {
                  placeHolder = this.$t('clubMemberSelfServiceDialog.hakafaIdenfifierPlaceHolder');
                  subtitle = this.$t('clubMemberSelfServiceDialog.pleaseTypeHakafaIdentifier');
                }
                return {
                  title: this.$t('clubMemberSelfServiceDialog.memberClub'),
                  subtitle: subtitle,
                  placeHolder: placeHolder,
                }
              }

              return {
                title: this.$t('clubMemberSelfServiceDialog.welcome'),
                subtitle: this.$t('clubMemberSelfServiceDialog.isMember')
              }
            },
            showGoBack() {
              return [STEPS.KEYPAD_INPUT].includes(this.step);
            },
            
          },


        }
        VueApp.component('club-member-self-service-dialog', component)
      }
    }
  }
}
