module PositiveTS {
  export module Service {


      const url = '/openformat/create_with_job';
      const polling_url = '/openformat/check_status_and_get_data';

      export module OpenformatResult {

        export async function getBkmvdata () {
          try {
            app.showLoadingMessage(i18next.t('generatingReportWait'));
            const url = "/openformat_result/bkmvdata";
            const fileName = "bkmvdata.txt";

            if (session.isAndroid){
              let response = await PositiveTS.Service.FetchReq.textReq(url,'get', undefined);
              let filename = Android.SaveFile(response.result, fileName);
              return filename;
            } else{
              await PositiveTS.Service.FetchReq.fileRequestAndDownload(url, fileName);
            }
          }catch (error){
            console.error(error);
            app.showErrorAlertDialog();
          }finally{
            app.hideLoadingMessage();
          }
        }

        export async function getIni () {
          try {
            app.showLoadingMessage(i18next.t('generatingReportWait'));
            const url = "/openformat_result/ini";
            const fileName = "ini.txt";

            if (session.isAndroid){
              let response = await PositiveTS.Service.FetchReq.textReq(url,'get', undefined);
              let filename = Android.SaveFile(response.result, fileName);
              return filename;
            } else{
              await PositiveTS.Service.FetchReq.fileRequestAndDownload(url, fileName);    
            }
          }catch (error){
            console.error(error);
            app.showErrorAlertDialog();
          }finally{
            app.hideLoadingMessage();
          }
        }
      }


      export class ServiceOpenformatAjax {
        fromDate:Date;
        toDate:Date;
        companyId: string;
        filePath: string;
        static staticIniFilePath: string;
        static staticBkmvdataFilePath: string;

        companyRegNum: string;

        constructor(fromDate:Date, toDate:Date) {
          this.fromDate = fromDate;
          this.toDate = toDate;
          this.companyId = session.pos.companyID;
          this.companyRegNum = session.store.registrationNum;
          this.filePath = this._getPathToSave();
        }
        
        pollingFunction(jobId, timeout): Promise<any> {
          return new Promise((resolve, reject) => {
            app.showLoadingMessage(i18next.t('generatingReportWait'));

            setTimeout(async () => {
              try {
                let result = await FetchReq.jsonReq(polling_url,'post', {job_id: jobId});
                return resolve(result)
              } catch (err) {
                return reject(err);
              }
            }, timeout);
          });
        }

        async startPollingFuntionAndGetResult(jobId, timeout): Promise<any>{
          let response = await this.pollingFunction(jobId, 0);

          while(!response.result.is_finished) {
            response  = await this.pollingFunction(jobId, timeout);

            if (!response.result.success) {
              throw new Error(i18next.t('generalError'));
            }
          }

          return response;
        }


        async runReport(): Promise<any> {
              let params = {
                fromDate: moment(this.fromDate).format(),
                toDate: moment(this.toDate.toJSON()).format(),
                companyId: this.companyId,
                filePath: this.filePath,
                companyRegNum: this.companyRegNum
              };

              let response = await FetchReq.jsonReq(url,'post', params);
    
              if (!response.response.ok) { throw new Error(i18next.t('generalError')); }
              if (!response.result.success) {
                throw new Error(response.result.msg)
              }

              let jobId = response.result.job_id;

              let jobResponse = await this.startPollingFuntionAndGetResult(jobId, 3000);
              
              return {
                  pathLocal: this.filePath,
                  response: jobResponse,
                  fromDate: this.fromDate,
                  toDate: this.toDate,
                };  
        }

        private _getPathToSave() {
          var d = new Date();
          var month = PositiveTS.Service.StrUtils.lpad( d.getMonth() ,2);
          var dd = PositiveTS.Service.StrUtils.lpad( d.getDate(), 2);
          var hh = PositiveTS.Service.StrUtils.lpad( d.getHours(), 2);
          var mm = PositiveTS.Service.StrUtils.lpad( d.getMinutes(), 2);
          return `C:\\OPENFRMT\\${this.companyRegNum}.${d.getFullYear().toString().substring(2)}\\${month}${dd}${hh}${mm}`
        };

      }
    }
  }
