import FetchReq from "./fetchReqService";

export module ManagerApproval {
    const GENERATE_SMS_CODES_URL = '/employees/send_manager_sms_codes';
    export let isSmsCodesSent = false;
    let smsCodesData = [];
    export async function requestSmsCodes(permissions: string[]) {
        try {
            app.showLoadingMessageDialog(i18next.t('managerApprovalBySmsLoading'));
            let permission = '';
            if (!posUtils.isBlank(permissions) && posUtils.isArray(permissions)) {
                permission = permissions[0];
            }
            const data = {
                employeeRequestName: session.pos.employeeName,
                requestedAction: permission
            }
            let response = await FetchReq.jsonReq(GENERATE_SMS_CODES_URL, 'post', { ...data });

            if (response.result.success == true) {
                saveSmsCodesData(response.result);
                app.hideLoadingMessageDialog();
            } else {
                app.hideLoadingMessageDialog();
                if (response.result.message) {
                    showError(response.result.message);
                } else {
                    showError(i18next.t("managerApprovalBySmsError"));
                }
            }
        } catch (err) {
            PositiveTS.Service.Filelog.log(`ManagerApproval:::requestSmsCodes`, JSON.parse(JSON.stringify(err)), false, 'error');
            showError(i18next.t("managerApprovalBySmsError"));
            app.hideLoadingMessageDialog();
        }
    }
    function saveSmsCodesData(savedHashedCodes) {
        try {
            isSmsCodesSent = true;
            smsCodesData = savedHashedCodes.data;
        } catch (err) {
            PositiveTS.Service.Filelog.log(`ManagerApproval:::saveSmsCodesData`, JSON.parse(JSON.stringify(err)), false, 'error');
        }
    }
    export function resetSmsCodesData() {
        isSmsCodesSent = false;
        smsCodesData = [];
    }
    export function verifySmsCode(code: string) {
        try {
            let managersData = smsCodesData;
            if (posUtils.isBlank(managersData)) {
                return null;
            }
            const hashedClientCode = PositiveTS.Service.Sha256.sha256.hash(code);
            let manager = managersData.find(manager => manager.hashed_sms_code == hashedClientCode);
            if (!posUtils.isBlank(manager)) {
                return manager;
            }
        } catch (error) {
            PositiveTS.Service.Filelog.log(`ManagerApproval:::verifySmsCode`, JSON.parse(JSON.stringify(error)), false, 'error');
            return null;
        }
    }
    function showError(errorText: string) {
        return app.showAlertDialog({
            header: i18next.t('error'),
            content: errorText,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
        })
    }
    export async function byFingerprint(permissions: string[]) {
        try {
            let verifyedUser = await PositiveTS.Service.Fingerprint.verify();
            let userMagneticCard = await PositiveTS.Service.WasmDB.promiseSql("select magneticCard from Employee where employeeID = " + verifyedUser);
            let result = await PositiveTS.Service.GlobalUI.handleManagerMagneticCard(userMagneticCard[0].magneticCard, permissions);
            return result;
        } catch (error) {
            PositiveTS.Service.Filelog.log(`ManagerApproval:::byFingerprint`, JSON.parse(JSON.stringify(error)), false, 'error');
            showError(error.message);
        }
    }
    export async function swipeCard() {
        try {
            app.showLoadingMessageDialog(i18next.t('ett.swipeManagerCard'));
            let cardNumber = await PositiveTS.Service.EMV.swipe();
            return cardNumber;
        } catch (error) {
            PositiveTS.Service.Filelog.log(`ManagerApproval:::swipeCard`, JSON.parse(JSON.stringify(error)), false, 'error');
            showError(i18next.t('errorOccurred'));
        } finally {
            app.hideLoadingMessageDialog();
        }
    }
    export function isOpenDrawerPrinting() {
        return jsonConfig.getVal(jsonConfig.KEYS.isOpenDrawerManagerApproval);
    }
    export async function createOpenDrawerLogSuspiciousActivity() {
        try {
            let result = await PositiveTS.Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(PositiveTS.Shared.Constants.SuspiciousActions.OPEN_DRAWER, session.pos.employeeID);
            return result;
        } catch (error) {
            PositiveTS.Service.Filelog.log(`ManagerApproval:::createOpenDrawerLogSuspiciousActivity`, JSON.parse(JSON.stringify(error)), false, 'error');
        }
    }
    export function isReducingQuantity() {
        return jsonConfig.getVal(jsonConfig.KEYS.isReducingQuantityManagerApproval);
    }
    export function isNegativeChangingQuantity() {
        return jsonConfig.getVal(jsonConfig.KEYS.isNegativeChangingQuantityManagerApproval);
    }
}