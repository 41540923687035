module PositiveTS {
  export module Components {
    export module Scrollable {
      export function getComponent() {
        return {
          template: JST.scrollable(),
          components: { positiveIcon: PositiveIcon.getComponent() },
          props: {
            amountToScroll: {
              type: Number,
              default: 500,
            },
            thinStyle: {
              type: Boolean,
              default: false,
            },
            forSelfService: {
              type: Boolean,
              default: false,
            },
            showScrollButtonsDefault: {
              type: Boolean,
              default: false,
            },
          },
          methods: {
            doScroll(direction) {
              this.$el.children[1].scrollBy({
                top: this.amountToScroll * direction,
                behavior: 'smooth'
              })
            },
          },
          data() {
            return {
              showScrollButtons: this.showScrollButtonsDefault,
              lastKnownChildren: 0,
            }
          },
          updated() {
            let scrollableNode = this.$slots.default && this.$slots.default()[0];
            if (scrollableNode) {
              let slotElement = this.$el.querySelector(`${scrollableNode.props.class.split(' ').map(c => '.' + c).join('')}`)
              if (slotElement && slotElement.clientWidth && slotElement.clientHeight && this.lastKnownChildren != slotElement.children.length) {
                this.lastKnownChildren = slotElement.children.length;
                if (this.forSelfService) {
                  this.showScrollButtons = slotElement.scrollTop != (slotElement.scrollHeight - slotElement.offsetHeight)
                } else {
                  this.showScrollButtons = slotElement.scrollHeight > slotElement.clientHeight
                }
              }
            }
          }
        }
      }
    }
  }
}