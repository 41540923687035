<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { globalStore } from "@/Store/Pinia";
import { GenericKeyboardDialogExtraButton } from '@m/types';
import { isNullOrUndefined } from '@shared/src/Utils';
const cleanDataObject = {
    loading: false,
    isRestaurantNewPOS: PositiveTS.Service.Dalpak.isRestaurantNewPOS(),

}
const initialState = {
    ...cleanDataObject,
};
const state = reactive(initialState);
const props = defineProps({
    okButtonAction: { type: Function, required: false, default: () => { } },
    extraButtonAction: { type: Function, required: false, default: () => { } },
    cancelButtonAction: { type: Function, required: false, default: () => { } },
    okButtonLabel: { type: String, default: "", required: false },
    extraButtonLabel: { type: String, default: "", required: false },
    cancelButtonLabel: { type: String, default: "", required: false },
    showOkButton: { type: Boolean, default: true, required: false },
    showExtraButton: { type: Boolean, default: false, required: false },
    showCancelButton: { type: Boolean, default: true, required: false },
    extraButtonIcon: { type: String, default: null, required: false },
    extraButtons: { type: Array<GenericKeyboardDialogExtraButton>, default: [], required: false },
});
function okFunction() {
    props.okButtonAction();
}

function cancelFunction() {
    props.cancelButtonAction();
}

function extraFunction() {
    props.extraButtonAction();
}

const dialogElement = ref(null);
const isOpen = ref(false);

function cleanData() {
    for (const [key, value] of Object.entries(cleanDataObject)) {
        state[key] = value;
    }
}

function open() {
    isOpen.value = true;
    cleanData()
    $(document).unbind('keypress');
    if (!dialogElement.value.open) {
        dialogElement.value.showModal();
    }
}

function close() {
    isOpen.value = false;
    dialogElement.value.close();
    cleanData();
    $(document).unbind("keypress");
    $(document).keypress(posVC.onKeyPress);
}

const getBtnClasses = computed(() => {
    const classes = [];
    if (globalStore.onSelfServicePaymentDialog) {
        classes.push("positive-simple", "self-service-btn");
    } else {
        classes.push("positive-simple-button");
    }
    classes.push("p2-pro:!min-w-21 p2-lite:!min-w-21");
    return classes;
});

const extraButtonIcon = computed(() => {
    if (posUtils.isPresent(props.extraButtonIcon)) { 
        return props.extraButtonIcon
    } else if (globalStore.simpleSelfService) {
        return "fa-regular fa-pen-circle";
    } else if (state.isRestaurantNewPOS) {
        return "fa-light fa-pen-circle";
    } else {
        return "";
    }
});

const extraButtonsToShow = computed(() => {
    let btns = props.extraButtons.filter((x) => isNullOrUndefined(x.showButton) ? true : x.showButton);
    return btns;
});

const moreThan2Buttons = computed(() => {
    let btns = [props.showOkButton, props.showExtraButton, props.showCancelButton];
    btns = btns.concat(extraButtonsToShow.value.map(_ => true));
    return btns.filter((x) => x).length > 2;
});

const moreThan3Buttons = computed(() => {
    let btns = [props.showOkButton, props.showExtraButton, props.showCancelButton];
    btns = btns.concat(extraButtonsToShow.value.map(_ => true));
    return btns.filter((x) => x).length > 3;
});

defineExpose({ open, close });
</script>

<template>
    <dialog ref="dialogElement" class="dialog-styles w-90vw p2:w-[55vw] relative shadow-none"
        id="generic-keyboard-dialog" v-bind:class="{
            'is-open': isOpen, 'new-restaurant-dialog': state.isRestaurantNewPOS,
            'simple-self-service': globalStore.simpleSelfService,
            'self-service pop-in': globalStore.onSelfServicePaymentDialog,
            'regular-pos !w-screen !h-screen m-0 !bg-transparent': !globalStore.simpleSelfService && !state.isRestaurantNewPOS && !globalStore.onSelfServicePaymentDialog
        }" :class="{ 'positive-dialog': !globalStore.simpleSelfService }">
        <div class="dialog-container absolute top-4/10 pos:top-3/10 left-1/4 h-auto w-[55vw] max-p2:w-full max-p2:left-0 bg-white">

            <slot name="title"></slot>

            <slot name="content"></slot>


            <div data-role="footer" class="self:!mt-0">
                <div class="dialog-buttons-bottom" :class="{ 'p2-pro:gap-0': moreThan2Buttons, 'gap-20': !moreThan3Buttons, 'gap-4': moreThan3Buttons }">
                    <div v-if="showOkButton" :class="getBtnClasses" class="red btn" @click="cancelFunction">
                        <font-awesome-icon icon="fa-regular fa-circle-xmark" class="icon"
                            v-if="globalStore.simpleSelfService"></font-awesome-icon>
                        <font-awesome-icon icon="fa-light fa-circle-xmark" class="icon"
                            v-if="state.isRestaurantNewPOS"></font-awesome-icon>
                        <div class="self:text-xl">{{ cancelButtonLabel }}</div>
                    </div>
                    <div v-if="showExtraButton" :class="getBtnClasses" class="yellow btn" @click="extraFunction">
                        <font-awesome-icon :icon="extraButtonIcon" class="icon"
                            v-if="globalStore.simpleSelfService"></font-awesome-icon>
                        <font-awesome-icon :icon="extraButtonIcon" class="icon"
                            v-else-if="state.isRestaurantNewPOS"></font-awesome-icon>
                        <div class="self:text-xl">{{ extraButtonLabel }}</div>
                    </div>
                    <template v-for="extraBtn of extraButtonsToShow">
                        <div :class="getBtnClasses" class="yellow btn" @click="extraBtn.action">
                            <font-awesome-icon :icon="extraBtn.icon" class="icon"
                                v-if="globalStore.simpleSelfService"></font-awesome-icon>
                            <font-awesome-icon :icon="extraBtn.icon" class="icon"
                                v-else-if="state.isRestaurantNewPOS"></font-awesome-icon>
                            <div class="self:text-xl">{{ extraBtn.label }}</div>
                        </div>
                    </template>
                    <div v-if="showCancelButton" :class="getBtnClasses" class="green btn" @click="okFunction">
                        <font-awesome-icon icon="fa-regular fa-circle-check" class="icon"
                            v-if="globalStore.simpleSelfService"></font-awesome-icon>
                        <font-awesome-icon icon="fa-light fa-circle-check" class="icon"
                            v-else-if="state.isRestaurantNewPOS"></font-awesome-icon>
                        <div class="self:text-xl">{{ okButtonLabel }}</div>
                    </div>
                </div>
            </div>
        </div>
        <slot name="keyboard"></slot>

    </dialog>
</template>


<style lang="scss">
.regular-pos {

    max-width: initial;
    max-height: initial;
}


dialog.self-service::backdrop {
    background: rgb(0 0 0 / 50%) !important;
    backdrop-filter: blur(3px);
}

#generic-keyboard-dialog {
    &.self-service {
        width: 70vw;
        min-height: 15vh;

        .self-service-btn {
            line-height: unset;
        }

        .content {
            display: flex;
            flex-direction: column;
        }

        [data-role="footer"] {
            margin-top: 5vh;
        }

        font-family: "Assistant";
        font-size: 30px;
        border-radius: 6px;
    }

    &.simple-self-service,
    &.new-restaurant-dialog {
        font-size: 1.5em;
        width: 100vw;
        height: 100vh;
        margin: 0;
        max-width: initial;
        max-height: initial;

        .dialog-container {
            font-family: "Assistant";
            font-size: 20px;
        }

        .title {
            background: white !important;
            color: black;
            padding: 1rem 0;
            font-size: 2rem;
            display: none;
        }

        .content {
            font-size: 20px;
            margin-top: 0;
            padding-bottom: 40px;
            padding-top: 40px;
        }
    }

    &.simple-self-service {
        background: rgb(0 0 0 / 50%) !important;
        backdrop-filter: blur(3px);

        .dialog-container {
            border-radius: 10px;
        }
    }

    &.new-restaurant-dialog {
        box-shadow: var(--dialog-or-modal-shadow);
        background: transparent !important;
    }

    .content {
        margin-top: 10px;
        text-align: center;
        white-space: pre-line;

        label {
            font-weight: bold;
        }

        input {
            border: 1px solid #ccc;
            font-size: 20px;
            padding: 4px 7px;
            width: 90%;

            &:focus {
                outline-color: var(--primary-default);
            }
        }
    }
}

.food-self-service,
.new-restaurant {
    #generic-keyboard-dialog {
        .dialog-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: "Assistant";
            font-size: 20px;

            .dialog-buttons-bottom {
                justify-content: center;
                margin-bottom: 0;

                .btn {
                    background-color: transparent;
                    font-size: 14px;
                    font-weight: 600;
                    box-shadow: none;
                    color: gray;
                    display: flex;
                    flex-direction: column;

                    & .icon {
                        font-size: 40px;
                    }

                    &.green {
                        .icon {
                            color: var(--success-default);
                        }
                    }

                    &.yellow {
                        .icon {
                            color: var(--additional-default);
                        }
                    }

                    &.red {
                        .icon {
                            color: var(--danger-default);
                        }
                    }
                }
            }
        }
    }
}

.new-restaurant {
    #generic-keyboard-dialog {
        .dialog-container {
            border: 1px solid #e5e5e5;
            width: 55vw;
        }
    }
}

.food-self-service {
    #generic-keyboard-dialog {
        .dialog-container {
            width: 55vw;
            border-radius: 10px;
            border: 2px solid var(--primary-active);
        }
    }
}

@media (max-width: 600px) {
    #generic-keyboard-dialog {
        &.new-restaurant-dialog {
            .dialog-container {
                .dialog-buttons-bottom {
                    margin-bottom: 1.5rem !important;
                }
            }
        }
    }

    .new-restaurant {
        #generic-keyboard-dialog {
            .dialog-container {
                width: 90vw;
            }
        }

        #positive-alert {
            .dialog-buttons-bottom {
                margin-bottom: 1.5rem !important;
            }
        }
    }
}

@media (min-width: 1024px) {
    #generic-keyboard-dialog {
        &.self-service {
            .self-service-btn {
                height: 45px;
            }
        }
    }
}
</style>