module PositiveTS {

    export module Service {

        export module SuperFriendsClub {

            export function checkIfCanAddItemClub (itemCode) :any {
                
                let error = ''

                if (['181','183'].includes(itemCode)){

                    const saleJsonData = JSON.parse(posVC.sale.jsondata)
                    const saleItemsBarcodes = posVC.saleItems.map(i => i.barcode)
                    //error if customer club not found in sale
                    //else check if the item is new member item or renew member item
                    if (!saleJsonData.customer){
                        error = i18next.t("superFriendsCustClub.cannotAddItemClubCustomerNotFound") 
                    }else{

                        let customer = saleJsonData.customer

                        if (itemCode == '181'){

                            if (saleItemsBarcodes.includes('181')){
                                error = i18next.t("superFriendsCustClub.cannotAddItemClubNewMemberMoreThanOne") 
                            }

                            let isRegisteredCustomerClub = true;
                            if (session.pos.brillConfig) {
                                let brillConfig = JSON.parse(session.pos.brillConfig);

                                if (!posUtils.isBlank(brillConfig.registered_customer_clubs)) {
                                    isRegisteredCustomerClub = brillConfig.registered_customer_clubs.map(r => String(r)).includes(String(customer.class_code))
                                }
                            }

                            if (!customer.is_new_customer && isRegisteredCustomerClub){
                                error = i18next.t("superFriendsCustClub.cannotAddItemClubNewMemberOnExistsMember")
                            }

                        }

                        if (itemCode == '183') {
                            if (customer.is_new_customer){
                                error = i18next.t("superFriendsCustClub.cannotAddItemClubRenewMemberOnNewMember")
                            }

                            if (saleItemsBarcodes.includes('183')){
                                error = i18next.t("superFriendsCustClub.cannotAddItemClubRenewMemberMoreThanOne")
                            }

                            if(session.pos.brillConfig) {
                                let brillConfig = JSON.parse(session.pos.brillConfig);
                                let blockedFromRenewingCustomerClubs: Array<any> = brillConfig.forbidden_clubs_from_renew;
                                if (blockedFromRenewingCustomerClubs.some(c => c.toString() === customer.class_code.toString())) {
                                    error = i18next.t("superFriendsCustClub.cannotAddItemClubRenewMemberOnBlockCustomerClub")
                                }
                            }                            
                        }
        
                    }
     
                }

                return error ? {error} : true
            }

        }

    }

}