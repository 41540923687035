module PositiveTS {
	export module Application {
	export module Controllers {
	export class PosPastInvoicesDisplayPaymentsViewController extends AbstractViewControllerTS {
		creditTypeText = ""

		saleProxyOptions:any = {}
	
		saleItemsAndPayments = null
	
		backTo = 'pos-past-invoices'
	
		// --------------------------------------------------------------------
		// Controller Life-Cycle
		// --------------------------------------------------------------------
		init(options) {
			let aThis = posPastInvoicesDisplayPaymentsVC
			$('#pos-past-invoices-display-payments-back').click(aThis.back);
	
			// Initialize the tableview
			let tableView = $('#pos-past-invoices-payments-tableview').tableview();
	
			// Set the number of visible rows
			tableView.setNumberOfVisibleRows(5);
	
		}
		//This method will set the table view header
		setTableHeader(saleData) {
	
			//If sale data exist
			if (saleData) {
	
				//Split the string
				let saleDataArr = saleData.split(';');
	
				//Invoice value
				let invoiceSequence = saleDataArr[0];
				let createdAt = saleDataArr[1];
				let cashierEmployeeName =  saleDataArr[2];
	
				$('.header-invoice-number').text(invoiceSequence);
				$('.header-invoice-date').text(posUtils.getDateStr(createdAt));
				$('.header-invoice-cashier').text(cashierEmployeeName);
			}
		}
		resumeFromPast(options) {
			let aThis = posPastInvoicesDisplayPaymentsVC;
	
			let saleProxy = new PositiveTS.Service.SaleProxy(aThis.saleProxyOptions);
	
			aThis.saleItemsAndPayments = null;
	
			// Fetch all items and payments for the sale
			saleProxy.load()
			.then(returnedSale => {
				if (returnedSale === null || returnedSale === undefined) {
					// Tell the user
					app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('loadingItemFromStorageFailed'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
	
					return;
				}
	
				// --- Sale items and payments fetched successfully
				// Get the items and payments for the current sale ID
				aThis.saleItemsAndPayments = returnedSale;
	
		  let isCustomerExists = false;
		  let customerName = ""
		  let svcCustomerClub = new PositiveTS.Service.CustomerClub(aThis.saleItemsAndPayments, aThis.saleItemsAndPayments.payments, aThis.saleItemsAndPayments.items);
	
		  if (svcCustomerClub.isCurrentCustomerExists( aThis.saleItemsAndPayments )){
			isCustomerExists = true;
			customerName = svcCustomerClub.getCustomerShortDisplayName(aThis.saleItemsAndPayments);
		  }
	
		  if (aThis.saleItemsAndPayments.customerName != null && aThis.saleItemsAndPayments.customerName != "") {
			isCustomerExists = true;
			customerName = aThis.saleItemsAndPayments.customerName;
		  }
		  	let el = $(".past-invoices-display-items-customer-name")
			if (isCustomerExists) {
				el.text(`${i18next.t("posPastInvoicesDisplayItems.customer")}: ${customerName}`);
				el.show();
			} else {
				el.hide();
			}
	
				$("#pos-past-sale-info-receipt-info").text( PositiveTS.Service.TaxInv.receiptTextIfRequired(aThis.saleItemsAndPayments) );
	
				//Set the table view header
				aThis.setTableHeader(aThis.saleItemsAndPayments.invoiceSequence + ";" + aThis.saleItemsAndPayments.createdAt + ";" + aThis.saleItemsAndPayments.cashierEmployeeName);
	
				//Get the sale payments
				let salePayments = aThis.saleItemsAndPayments.payments;
	
				// Initialize the tableview
				let tableView = $('#pos-past-invoices-payments-tableview').tableview();
	
				// Empty the table so we can be sure we are displaying only the latest results
				tableView.empty();
	
				let changeSalePayment = null;
	
				for(let i=0;i<salePayments.length;i++){
	
					switch(salePayments[i].method){
	
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
							aThis.addCreditCardRows(salePayments[i],tableView);
							break;
	
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
							aThis.addCashRow(salePayments[i],tableView);
							break;
	
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
							changeSalePayment = salePayments[i];
							break;
	
						case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
						case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
							aThis.addVoucherRows(salePayments[i],tableView);
							break;
	
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER:
							aThis.addPromotionRows(salePayments[i],tableView);
							break;
	
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
							aThis.addCheckRows(salePayments[i],tableView);
							break;
					}
				}
	
				if (changeSalePayment != null) {
					aThis.addChangeRow(changeSalePayment,tableView);
				}
	
			})
			.catch(e => {
				// --- Failed to fetch sale items and payments
				console.error(e.message);
	
				// Tell the user
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('loadingItemFromStorageFailed'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
			});
		}
		resume(options) {
			let aThis = posPastInvoicesDisplayPaymentsVC;
	
			aThis.saleProxyOptions = options.saleProxyOptions;
	
			if (aThis.saleProxyOptions.type == PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE) {
				aThis.backTo = 'pos-search-invoices';
			} else {
				aThis.backTo = 'pos-past-invoices';
			}
	
			aThis.resumeFromPast(options);
		}
		addChangeRow(salePayment, tableview) {
			let aThis = posPastInvoicesDisplayPaymentsVC;
	
			let posMethodType = i18next.t("posCreditInvoice.change");
	
			if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
				let paymentData = JSON.parse(salePayment.data);
				if (paymentData && paymentData.length && paymentData.length > 0){
					for (let payment of paymentData) {
						tableview.addRow([
							posMethodType,
							session.fixedFloat(payment.lead_currency_amount,2),
							payment.paid_currency_symble,
							payment.paid_currency_amount,
								'',
								'',
								'',
								''
						], false);
					}
				}else{
					tableview.addRow([
						posMethodType,
						session.fixedNumber(salePayment.amount),
						PositiveTS.Service.MultiCurr.getInstance().getPosCurrency(),
						session.fixedNumber(salePayment.amount),
							'',
							'',
							'',
							''
					], false);
				}		
	
			} else {
			  tableview.addRow([
				  posMethodType,
				  session.fixedNumber(salePayment.amount),
					  '',
					  '',
					  '',
					  '',
					  '',
					  ''
				  ], false);
			}
		}
		addCashRow(salePayment, tableview) {
			let aThis = posPastInvoicesDisplayPaymentsVC;
			let posMethodType = i18next.t("posCreditInvoice.cash");
			
			if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
				let paymentData = JSON.parse(salePayment.data);
				if (paymentData && paymentData.length && paymentData.length > 0){
					for (let payment of paymentData) {
						tableview.addRow([
							posMethodType,
							session.fixedFloat(payment.lead_currency_amount,2),
							payment.paid_currency_symble,
							payment.paid_currency_amount,
								'',
								'',
								'',
								''
						], false);
					}
				}else{
					tableview.addRow([
						posMethodType,
						session.fixedNumber(salePayment.amount),
						PositiveTS.Service.MultiCurr.getInstance().getPosCurrency(),
						session.fixedNumber(salePayment.amount),
							'',
							'',
							'',
							''
					], false);
				}		
	
			} else {
			  tableview.addRow([
				  posMethodType,
				  session.fixedNumber(salePayment.amount),
					  '',
					  '',
					  '',
					  '',
					  '',
					  ''
				  ], false);
			}
	
	
	
		}
	
		addRegularCreditCardRow(paymentData, tableView) {
			let aThis = posPastInvoicesDisplayPaymentsVC;
			let posMethodType = i18next.t("posCreditInvoice.credit");;
			let amount = paymentData.amount;
			let cardNumber  = paymentData.card_number;
	
			if (posUtils.isNullOrUndefined(paymentData.payment_type)) {
				aThis.creditTypeText = paymentData.payments_count > 1 ? i18next.t("posCreditInvoice.payments") : i18next.t("posCreditInvoice.regular");
			} else {
				aThis.creditTypeText = session.creditCardPaymentTypes[paymentData.payment_type].ccTypeName;
			}
	
			let cardType =  aThis.creditTypeText;
			let experDate   = `${i18next.t("posCreditInvoice.validity")}:<br>`+(paymentData.valid_until || '');
			let phoneNumber = `${i18next.t("posCreditInvoice.phone")}:<br>`+(paymentData.phone_number || '');
			let ccv  = ""; // NOT ALLOWED TO STORE CCV
			let cardPayments = `${i18next.t("posCreditInvoice.payments")}:<br>`+(paymentData.payments_count || '');
			let confNumber = `${i18next.t("posCreditInvoice.confirmationNumber")}:<br>`+(paymentData.confirmation_number  || '');
	
			amount = session.fixedNumber(Number(amount));
			if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ){
				amount = PositiveTS.Service.MultiCurr.getInstance().getCurrencySymbolEMV(paymentData) + " " + amount;
			}
	
			tableView.addRow([
				posMethodType,
				amount,
				cardNumber,
				cardType,
				cardPayments,
				experDate,
				phoneNumber,
				confNumber
			], false);
		}
		addCreditCardRows(salePayment,tableView){
			let aThis = posPastInvoicesDisplayPaymentsVC;
	
	
			let dataObj = JSON.parse(salePayment.data);
			for(let i=0; i < dataObj.length; i++) {
				let paymentData = dataObj[i];
				if (paymentData.isEmv || paymentData.isGateway) {
					let creditCardPaymentType = paymentData.isGateway ? 'Gateway' : 'EMV';
					paymentData = Object.assign(Service.CreditCardPayment.createOutputByType(creditCardPaymentType),paymentData)
				}
				aThis.addRegularCreditCardRow(paymentData,tableView)
			}
		}
		addVoucherRows(salePayment,tableView){
			let posMethodType = i18next.t("posCreditInvoice.voucher");
			let dataObj = JSON.parse(salePayment.data);
	
			for(let i=0; i<dataObj.length; i++) {
				let amount = dataObj[i].amount;
				let creditType = dataObj[i].creditType;
				if (creditType == "סיבוס") {
					creditType = "פלאקסי"
				}
				let barCode = dataObj[i].barCode;
				tableView.addRow([
									posMethodType,
									session.fixedNumber(amount),
									 `${i18next.t("posCreditInvoice.creditType")}:<br>` + posUtils.sanitizeHtml(creditType),
									 barCode != "" ? `${i18next.t("posCreditInvoice.barcode")}:<br>` + barCode : '',
									 '',
									 '',
									 '',
									 ''
								  ], false);
			}
		}
		addPromotionRows(salePayment,tableView){
			let aThis = posPastInvoicesDisplayPaymentsVC;
	
			let posMethodType = i18next.t("posCreditInvoice.creditVoucher");
			let dataObj = JSON.parse(salePayment.data);
	
			for(let i=0;i<dataObj.length;i++){
				let amount = dataObj[i].amount;
				let promotionNumber = dataObj[i].promotionNumber;
				let creditVoucherType = dataObj[i].creditVoucherType;
	
				if (creditVoucherType == PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_CREDIT) {
					posMethodType = i18next.t("posCreditInvoice.creditVoucherChange");
				} else {
					posMethodType = i18next.t("posCreditInvoice.creditVoucherPayment");
				}
	
		  if (posUtils.getMoment(aThis.saleItemsAndPayments.createdAt).diff(moment(new Date()), 'days') <= -1) {
			promotionNumber = i18next.t("posCreditInvoice.notForDisplay");
		  }
	
				tableView.addRow([
								  posMethodType,
								  session.fixedNumber(amount),
								  `${i18next.t("posCreditInvoice.voucherNumber")}:<br>` + promotionNumber,
								  '',
								  '',
								  '',
								  '',
								  ''
								  ], false);
			}
		}
		addCheckRows(salePayment,tableView){
			let posMethodType = i18next.t("posCreditInvoice.check");
			let dataObj = JSON.parse(salePayment.data);
	
			for(let i=0;i<dataObj.length;i++){
	
				let amount = dataObj[i].amount;
				let accountData = dataObj[i].bank + ' ' + dataObj[i].branch + ' ' + dataObj[i].account;
				let checkNumber = dataObj[i].number;
				let expireDate =  dataObj[i].date;
	
				tableView.addRow([
								   posMethodType,
								   session.fixedNumber(amount),
									`${i18next.t("posCreditInvoice.bank")}:<br>` + dataObj[i].bank,
									`${i18next.t("posCreditInvoice.branch")}:<br>` + dataObj[i].branch,
									`${i18next.t("posCreditInvoice.account")}:<br>` + dataObj[i].account,
								   `${i18next.t("posCreditInvoice.check")}:<br>`+checkNumber,
								   `${i18next.t("posCreditInvoice.expiryDate")}:<br>`+expireDate,
								   ''
								  ], false);
			}
		}
		stop() {
			$('#pos-past-invoices-display-payments-back').unbind('click');
		}
		back() {
			let aThis = posPastInvoicesDisplayPaymentsVC;

			if(Pinia.globalStore.mobileLayout == true){
				if (aThis.backTo == 'pos-search-invoices') {
					posSearchInvoicesVC.displaySaleItems();
				} else {
					posPastInvoicesVC.displaySaleItems()
				}
			}
			else{
				pNavigator.replacePage(aThis.backTo, i18next.t('pageTitle.posPastInvoices'), null, null);
			}
		}
		// --------------------------------------------------------------------
		// Listeners
		// --------------------------------------------------------------------
		continue() {
	
		}
	}
}}}
declare var posPastInvoicesDisplayPaymentsVC:PositiveTS.Application.Controllers.PosPastInvoicesDisplayPaymentsViewController;
posPastInvoicesDisplayPaymentsVC = new PositiveTS.Application.Controllers.PosPastInvoicesDisplayPaymentsViewController();
PositiveTS.Application.Controllers.instances.PosPastInvoicesDisplayPaymentsViewController = posPastInvoicesDisplayPaymentsVC;

