module PositiveTS {
  export module Components {
    export module SelfServiceEmv {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreen(),
          components: {PositiveLottie: PositiveLottie.getComponent()},
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.selfServicePaymentMixin('selfService.creditCard', false, null, Mixins.IMAGES_TYPES.EMV)],
          computed: {
						osherad_logo(): string {
							return this.autoStore.config.head_logo;
						},
            isOsherAd(){
              return Service.OsherAd.isEnabled();
            },
            autoStore(){
							return Pinia.posStore.autoStore
						},
          },
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {setCurrentStep,setSelectedPaymentMethod,setIsAfterPaymentPage,setExtraData} = selfServicePaymentStore;

            const {getExtraData,creditCardPaymentsData} = Pinia.storeToRefs(selfServicePaymentStore);



            return {setCurrentStep,setSelectedPaymentMethod,setIsAfterPaymentPage,setExtraData,getExtraData,creditCardPaymentsData}
          },
          methods: {
            async paymentAction() {
                let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
                let paymentsData = this.creditCardPaymentsData
                let paymentType =  session.creditCardPaymentTypes[paymentsData == null ? 1 : paymentsData.paymentType]
                let numberOfPayments= paymentsData == null ? 1  : paymentsData.numberOfPayments;


                if(this.isMidragPaymentsEnabled()){
                  let installmentResult = await this.makeInstallmentPayment(amount,paymentType,numberOfPayments)
                  if(installmentResult != 'regular'){
                    return;
                  }
                }
                let response = await Service.EMV.payWithCreditCard(amount, Service.EMV.PINPAD_DEAL, paymentType, numberOfPayments, null, "1")
                if (response.success) {
                  await Service.CreditCardPayment.savePayment({salePayment: Service.CreditCardPayment.getSalePayment(),cardDetails:response.result },amount);
                  await Service.FullSale.saveCurrentSale()
                  this.goToNextStep();
                }
                else{
                  if(response.result && Number(response.result.AshStatus) != 705){ //Skip error showing for 705 (user cancelled)
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: response.error,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                    
                  }
                  this.setCurrentStep("SelfSelectPaymentMethod")
                  if(PositiveTS.VueInstance.$refs.midragPayments){
                    PositiveTS.VueInstance.$refs.midragPayments?.close()
                  }
                  if(Service.OsherAd.isEnabled()){
                    PositiveTS.VueInstance.$refs.selfServicePaymentDialog.clearWizard()
                    PositiveTS.VueInstance.$refs.selfServicePaymentDialog.close()
                  }
                }
              },
              async makeInstallmentPayment(amount,paymentType,numberOfPayments){
                try{
                  this.setExtraData(null)
                  const creditCardDetailsResult = await Service.EMV.getCardDetails(amount,paymentType)
                  if(creditCardDetailsResult.success){
                    let availableInstallments = Service.EMV.checkCardAvailableNumOfPayments(creditCardDetailsResult);
                    let availableInstallmentsMaxNumOfPayments = availableInstallments.availableMaxNumOfPayments
                    let allowedMaxNumOfPayments = this.allowedNumOfPaymentsByMidrag(availableInstallmentsMaxNumOfPayments,amount);
                    let selectedNumOfPayments = await  Pinia.componentsStore.openComponent( {componentName: 'midragPayments', args: [allowedMaxNumOfPayments]});
                    if(!selectedNumOfPayments){
                      app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('selfServicePaymentMethod.errorMessages.noPaymentSelected'),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true,
                      })
                      this.setCurrentStep("SelfSelectPaymentMethod")
                      return null;
                    }
                    numberOfPayments = selectedNumOfPayments
                    paymentType = session.creditCardPaymentTypes[2];
                    if(numberOfPayments == 1){
                      paymentType = session.creditCardPaymentTypes[1];
                    }
                    let extraRequestTagData = await Service.EMV.extractCreditCardExtraTagData(creditCardDetailsResult);
                    let response = await Service.EMV.payWithCreditCard(amount, Service.EMV.PINPAD_DEAL, paymentType, numberOfPayments, null, "1",null,null,"4",extraRequestTagData)
                    if (response.success) {
                      await Service.CreditCardPayment.savePayment({salePayment: Service.CreditCardPayment.getSalePayment(),cardDetails:response.result },amount);
                      await Service.FullSale.saveCurrentSale()
                      this.goToNextStep();
                    }
                    else{
                      this.EMVErrorHandler(creditCardDetailsResult);
                    }
                  }else{
                    this.EMVErrorHandler(creditCardDetailsResult);
                  }
                }catch(err){
                  PositiveTS.VueInstance.$refs.midragPayments?.close()
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('error'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                  this.setCurrentStep("SelfSelectPaymentMethod")
                  return null;
                }
              },
            EMVErrorHandler(response){
              if(response.result && Number(response.result.AshStatus) != 705){ //Skip error showing for 705 (user cancelled)
                PositiveTS.VueInstance.$refs.midragPayments?.close()
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: response.error,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
              }
              this.setCurrentStep("SelfSelectPaymentMethod")
              if(Service.OsherAd.isEnabled()){
                PositiveTS.VueInstance.$refs.selfServicePaymentDialog.clearWizard()
                PositiveTS.VueInstance.$refs.selfServicePaymentDialog.close()
              }
              return null;
            },
            midragPayments(){
              let midragPayments = JSON.parse(session.pos.midragPayments)
              return midragPayments;
            },
            isMidragPaymentsEnabled(){
                let midragPayments = JSON.parse(session.pos.midragPayments)
                let isSelfServiceMidragPayments = Boolean(jsonConfig.getVal(jsonConfig.KEYS.selfServiceMidragPayments))
                return isSelfServiceMidragPayments && midragPayments.length > 0 && this.getExtraData?.isMidragPayments
            },
            allowedNumOfPaymentsByMidrag(allowedNumOfPaymentsByMidrag,amount){
              let defaultNumOfPayments = 1
              try{
                let midragPayments = this.midragPayments()
                let foundedMidrag =  midragPayments.find(midrag => amount >= midrag.fromAmount && amount <= midrag.toAmount);
                if(!foundedMidrag){
                  return defaultNumOfPayments
                }
                allowedNumOfPaymentsByMidrag = Math.min(allowedNumOfPaymentsByMidrag,foundedMidrag.numOfPayments);
                return allowedNumOfPaymentsByMidrag;
              }catch(err){
                console.error(err)
                return defaultNumOfPayments
              }
            },
          },
          data() {
            return {
              selectedPaymentMethodType: Mixins.INPUT_METHODS.SWIPE_CARD,
            }
          },

        }
      }
    }
  }
}
