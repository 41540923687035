module PositiveTS {
  export module Promotions {

    interface PromotionsResult {
      salePromotion: any,
      salePromotionDiscountPercent: number,    
      promotionGroups: Array<PromoGroup>
    }

    export interface PromoGroup {
      itemsCounter?: Map<string,number> | {},
      rowValueCounter?: Map<string,number> | {},
      promotion?: Storage.Entity.Promotion,
      discountAmountForGroup: number,
      discountPercentForGroup: number,
      hasBarcode? : boolean,
      promotionBarcode?: any,
      promotionCode: string,
      discountType: string,
      priority?: number,
      rowToShowOn?: number,
      clubMemberPromotionType?: number,
      item?:any, 
      promoName:string,
      totalPriceForItemsBeforeDiscount?: number,
      //discount fields - we implement now discounts as promotion groups
      isDiscount?: boolean,
      promotionAsDiscount?: boolean, 
      discount?: Storage.Entity.Discount,
      discountRowNumber?: number,
      discountApprovedByEmployeeID?: number,
      isSaleDiscount?: boolean,
      parentRowNumber?: number,
    }
    export class NewPromotionsEngine {
      public allPromotionsCodes:Array<string>
      private salePromotion // Hold the promotion that relevant to sale (no saleItem)
      private salePromotionDiscountPercent // Hold the promotion discount percent for promotion that relevant to sale (no saleItem)
      private promotionsBuy // Holds all the promotions from buy type
      private promotionsGet // Holds all the promotions from get type
      private saleItemBuyPromotions // Holds all buy promotions by sale item
      private manualPromotions
      private customer
      private hasSomeItemGroupsPromotion: boolean;
  
      constructor(private hasCustomer = false,private ignorePromotions = false, private customerPromotionTypes = [],
        private customerGroupId = null, customer = null) {
  
        this.salePromotion = null;
        this.salePromotionDiscountPercent = 0;
        this.promotionsBuy = {};
        this.promotionsGet = {};
        this.allPromotionsCodes = [];
        this.saleItemBuyPromotions = {};
        this.manualPromotions = [];
        this.customer = customer
        this.hasSomeItemGroupsPromotion = NewPromotionsEngine.checkIfHasSomeItemGroupsPromotion(this.customer);
      }
      
      public concatPromotionsResult (baseResult: PromotionsResult, resultToConcat: PromotionsResult) {
        if (resultToConcat){
          baseResult.promotionGroups.push(...resultToConcat.promotionGroups);
        }
      }

      public calculateItemGroupsPromotionsForSale (sale:Storage.Entity.Sale, saleItems:Array<Storage.Entity.SaleItem>, getPotentialSalePromotions = false, 
        externalPromotionCodes:Array<string> = []): PromotionsResult {
        let returnObj: PromotionsResult = {
          salePromotion: null,
          salePromotionDiscountPercent: 0,
          promotionGroups: [],
        };

        saleItems.forEach(saleItem => {
          if (saleItem.hasGroups) {
            //need to be flatten if parent has more then one quantity
            let parentSaleItems = saleItemHelper.flattenSaleItemsByQuantity([saleItem], true, true);

            parentSaleItems.forEach(si => {
              const childrenAndGrandchildren = saleItemHelper.extractSaleItemChildrenAndGrandchildren(si);
      
              if (childrenAndGrandchildren.children.length > 0){
                let result = this.calculatePromotionsForSale(sale, childrenAndGrandchildren.children, getPotentialSalePromotions, externalPromotionCodes, false);
                this.concatPromotionsResult(returnObj, result);
              }

              //we not suppot promotions on grandchildren items
            });
          }
        });

        //update unitprice of parent saleItem if children has discount
        if (returnObj.promotionGroups.length > 0){
          let saleItemsToPersist = this.applyPromotionsOnItems(returnObj, sale, _.cloneDeep(saleItems), false);
          saleItems.forEach(si => {
            if (si.hasGroups){
              let siUpdated = saleItemsToPersist.get(si.rowNumber);
              if (siUpdated){
                const childrenAndGrandchildren = saleItemHelper.extractSaleItemChildrenAndGrandchildren(siUpdated);
                si.unitPrice = si.originalUnitPrice + (_.sumBy([...childrenAndGrandchildren.children, ...childrenAndGrandchildren.grandchildren], 'priceNetoAfterDiscounts') / si.quantity);
              }
            }
          });
        }

        return returnObj;
      }

      public calculatePromotionsForSale(sale:Storage.Entity.Sale, saleItems:Array<Storage.Entity.SaleItem>, getPotentialSalePromotions = false, 
        externalPromotionCodes:Array<string> = [], needToRunOnItemGroups = true) {
        let aThis = this;
        
  
        let returnObj:PromotionsResult = {
          salePromotion: null,
          salePromotionDiscountPercent: 0,
          promotionGroups: []
        };
  
        if (aThis.ignorePromotions) {
          return returnObj;
        }
        
        let updatedSaleItems = this.getRelevantItems(saleItems, sale, (this.hasSomeItemGroupsPromotion && needToRunOnItemGroups));

        if (this.hasSomeItemGroupsPromotion && needToRunOnItemGroups){
          let resultCalculateChildren = this.calculateItemGroupsPromotionsForSale(sale, updatedSaleItems, getPotentialSalePromotions, externalPromotionCodes);
          this.concatPromotionsResult(returnObj, resultCalculateChildren);
        }
        //וואי וואי וואי איזה האק אחושרמוטה למערכת מבצעים כדי שתמשיך לעבוד בלי שנצטרך לשכתב את התבניות
        //חובה יהיה לשכתב את התמיכה בתבניות של משקלים
        updatedSaleItems = saleItemHelper.flattenSaleItemsByQuantity(updatedSaleItems);

        updatedSaleItems.forEach(si => {
          si.id = storage.createUUID(); 
          
          if (si.hasWeight){
            si.realQuantity = Math.min(si.quantity, 1);
          }else{
            si.realQuantity = 1
          }
          
          si.meta.money = si.meta.money.filter(field => field != 'unitPrice'); //I don't want the ORM to round the unitPrice while I run on the items.
        })
  
        // Preprocess sale items
        this.preprocessSaleItems(updatedSaleItems);
  
        // get all avilable promotions
        try {
  
          let allPromotions = this.loadAvailablePromotions(externalPromotionCodes, !needToRunOnItemGroups)

          // if is run on saleItems children need to filter promotions is include the item group id
          if (this.hasSomeItemGroupsPromotion && !needToRunOnItemGroups && saleItems.length > 0){
            allPromotions = allPromotions.filter(promotion => promotion.itemGroupIdsArray.includes(saleItems[0].itemGroupId))
          }

          // if (promotions.length === 0) {
          //   return returnObj;
          // }

 
          
          //1. apply row discounts with no duplicae
          //2. apply row promotions (not sale promotions)
          //3. apply row discounts with duplicate
          let promotions = allPromotions.filter(pr => {
            return !(NewPromotionsEngine.isDiscountPromotion(pr) && pr.discountGroup.isSaleDiscount) &&
                  !(NewPromotionsEngine.isSalePromotion(pr) || NewPromotionsEngine.isMessagePromotion(pr))
          })
          updatedSaleItems = this.findBestPromotions(returnObj, promotions, sale, updatedSaleItems);
          //4. apply sale discounts without duplicate
          //5. apply sale discounts with duplicate
          promotions = allPromotions.filter(pr => {
            return (NewPromotionsEngine.isDiscountPromotion(pr) && pr.discountGroup.isSaleDiscount)
          })
          updatedSaleItems = this.findBestPromotions(returnObj, promotions, sale, updatedSaleItems);
          //6. apply wrapper promotions
          updatedSaleItems.forEach(si => si.hasPromotion = false) //wrapper promotions ignore duplicate
          promotions = allPromotions.filter(pr => {
            return (NewPromotionsEngine.isSalePromotion(pr) || NewPromotionsEngine.isMessagePromotion(pr))
          })
          this.findBestPromotions(returnObj, promotions, sale, updatedSaleItems,getPotentialSalePromotions);
     
          return returnObj; 
        } catch(error) {
          // --- Fetching promotions failed
          console.error(error.message);
          console.error(error.stack);
        }
      }
  
      public getAvilableManualPromotionsForSale(saleItems:Array<Storage.Entity.SaleItem>) {
  
        // // Preprocess sale items to get all promotion codes...
        this.preprocessSaleItems(saleItems);
  
        // Fetch all manual promotions
        return Storage.Entity.Promotion.fetcManualByStoreAndCodes(session.pos.storeID, this.hasCustomer, 
          this.allPromotionsCodes, this.customerPromotionTypes, this.customerGroupId);
      };

      public getSaleItemByRowNumberIncludeChildrenGrandchildren (rowNumber: number, saleItems: Array<Storage.Entity.SaleItem>, runOnGrandchildren = false){
        let saleItem = null;
        let parentSaleItem = null;
        outerLoop:
        for (let si of saleItems){
          if (si.rowNumber == rowNumber){
            saleItem = si;
            break;
          }

          if (si.children && si.children.length > 0) {
						for (let child of si.children) {
              if (child.rowNumber == rowNumber){
                saleItem = child;
                parentSaleItem = si;
                break outerLoop;
              }

              if (runOnGrandchildren && child.children && child.children.length > 0) {
                for (let grandChild of child.children) {
                  if (grandChild.rowNumber == rowNumber){
                    saleItem = grandChild;
                    parentSaleItem = si;
                    break outerLoop;
                  }
                }
              }
            }
          }
        }

        return {saleItem: saleItem, parentSaleItem: parentSaleItem};
      }

      public applyPromotionsOnItems(promotionObject:PromotionsResult, sale:Storage.Entity.Sale, saleItems:Array<Storage.Entity.SaleItem>, cleanupPromotionsGroups = true) {
        let saleItemsToPersist = new Map<number,Storage.Entity.SaleItem>();

        //remove promotions from items
        for (let saleItem of saleItems) {
          if (!posUtils.isBlank(saleItem.promotions)) {
            saleItemsToPersist.set(saleItem.rowNumber,saleItem);
          }
          saleItem.promotions = null;
          saleItem.priceNetoAfterDiscounts = _.round(saleItem.unitPrice*saleItem.quantity, 6);
          if (this.hasSomeItemGroupsPromotion){
            const childrenAndGrandchildren = saleItemHelper.extractSaleItemChildrenAndGrandchildren(saleItem);
            // remove promotions also from children and grandchildren
            [...childrenAndGrandchildren.children, ...childrenAndGrandchildren.grandchildren].forEach(child => {
              child.promotions = null;
              child.priceNetoAfterDiscounts = _.round(child.unitPrice * child.quantity * saleItem.quantity, 6);
            });
          }
        }        

        //unite the promotion groups if they have the same itemsCounter and promotionCode
        let indicesToItemsCounter = {}
        for (let i=0; i<promotionObject.promotionGroups.length; i++) {
          let pg = promotionObject.promotionGroups[i];
          if (pg.discountAmountForGroup == 0) {
            continue;
          }
          // new internet customer will not get join promotion on first sale
          if (pg.clubMemberPromotionType == 3 && this.customer != undefined && (this.customer.total_purchases == null || this.customer.total_purchases == 0)) {
            continue;
          }
          let arr = indicesToItemsCounter[JSON.stringify({ic:pg.itemsCounter,code:pg.promotionCode})]
          if (arr == null) {
            arr = [i]
          }
          else {
            arr.push(i)
          }
          indicesToItemsCounter[JSON.stringify({ic:pg.itemsCounter,code:pg.promotionCode})] = arr;
        }

        let unitedPromotionGroups:Array<PromoGroup> = promotionObject.promotionGroups.filter(pg => pg.discountAmountForGroup == 0)
        
        for(let index in indicesToItemsCounter) {
          let unitedPg = promotionObject.promotionGroups[indicesToItemsCounter[index][0]] //קוד קשוח להבנה...
          let maxKey = -1;
          for (let key in unitedPg.itemsCounter) {
            maxKey = Math.max(maxKey,Number(key))
          }
          unitedPg.promoName = unitedPg.promotion.name;
          unitedPg.rowToShowOn = maxKey
          
          for (let i=1; i<indicesToItemsCounter[index].length; i++) {
            let pg =  promotionObject.promotionGroups[indicesToItemsCounter[index][i]]
            unitedPg.discountAmountForGroup += pg.discountAmountForGroup;
            unitedPg.totalPriceForItemsBeforeDiscount += pg.totalPriceForItemsBeforeDiscount;
            for (let key in pg.itemsCounter) {
              unitedPg.itemsCounter[key] += pg.itemsCounter[key]
              unitedPg.rowValueCounter[key] += pg.rowValueCounter[key]
            }
            
          }
          unitedPromotionGroups.push(unitedPg);
        }
        
        // console.debug(unitedPromotionGroups);
        // console.debug(indicesToItemsCounter);
        

        promotionObject.promotionGroups = unitedPromotionGroups.sort((a,b) => {
          return NewPromotionsEngine.promotionSortFunc(a.promotion,b.promotion);
        })

        // console.debug(promotionObject.promotionGroups);


        //apply the promotion on the items
        for (let pg of promotionObject.promotionGroups) {
          
          for (let key in pg.itemsCounter) {
            let saleItem = null;
            let parentSaleItem = null;
            if (this.hasSomeItemGroupsPromotion){
              let res = this.getSaleItemByRowNumberIncludeChildrenGrandchildren(Number(key), saleItems);
              saleItem = res.saleItem;
              parentSaleItem = res.parentSaleItem;
            }else{
              saleItem = posVC.saleItems.find(si => si.rowNumber == Number(key));
            }
            
            let discountAmountForPG = pg.discountPercentForGroup*pg.rowValueCounter[key];
            if (saleItem.promotions == null) { //this shit was done because empty string of text field sets to null
              saleItem.promotions = `${pg.promotionCode}=${_.round(discountAmountForPG,6)};`
            }
            else {    
              // in order to fix the sale item promotions string length, we convert a string like this
              // 489684=0;489684=0;489684=4;489684=0;489684=0;489684=4;
              // to this 
              // 489684=8              
              let currentItemPromotions = Storage.Entity.Promotion.convertPromotionStringToObject(saleItem.promotions);

              if (posUtils.isBlank(currentItemPromotions[pg.promotionCode])) {
                currentItemPromotions[pg.promotionCode] = 0;
              } else {
                currentItemPromotions[pg.promotionCode] = parseFloat(currentItemPromotions[pg.promotionCode]);
              }

              currentItemPromotions[pg.promotionCode] += _.round(discountAmountForPG,6);

              saleItem.promotions = Storage.Entity.Promotion.convertPromotionObjectToString(currentItemPromotions);
            }
            if (this.hasSomeItemGroupsPromotion && parentSaleItem){
              parentSaleItem.priceNetoAfterDiscounts -= discountAmountForPG;
              saleItem.priceNetoAfterDiscounts -= discountAmountForPG;
              pg.parentRowNumber = parentSaleItem.rowNumber;
              //return the saleItem parent to persist
              saleItem = parentSaleItem;
            }else{
              saleItem.priceNetoAfterDiscounts -= discountAmountForPG
            }

            saleItemsToPersist.set(saleItem.rowNumber,saleItem);
          }
        }


        //cleanup the promotionsGroups
        if (cleanupPromotionsGroups){
          promotionObject.promotionGroups.forEach(pg => {
            delete pg.item;
            delete pg.promotion;
          })
        }

        //update the jsondata of the sale
        for (let si of saleItemsToPersist.values()) {
          si.priceNetoAfterDiscounts = _.round(si.priceNetoAfterDiscounts,6)
        }

        return saleItemsToPersist;
      }
  
      private getRelevantItems(saleItems: Storage.Entity.SaleItem[],sale: Storage.Entity.Sale, runOnSaleItemChildren = false) {
        let relevantPromotionCodes = new Set(session.allPromotions.filter(promo => {
          if (promo.isClubMembersOnly && !this.hasCustomer) {
            return false;
          }

          if (promo.isClubMembersOnly && promo.customerGroupId == null && this.customer.clubName == Service.Hakafa.CLUB_IDENTIFIER) {
            return false;
          }

          if (promo.customerGroupId != null && promo.customerGroupId != this.customerGroupId) {
            return false;
          }
          return true;
        }).map(promo => promo.code))
      

        let jd = JSON.parse(sale.jsondata);
        let saleHasDiscount = false;
        let rowsToKeep = []
        if (jd.discounts && jd.discounts.length > 0) {
          for (let discount of jd.discounts) {
            if (discount.isSaleDiscount) {
              saleHasDiscount = true
            }
            else {
              rowsToKeep.push(discount.rowToShowOn)
            }
          }
        }

          
        let relevantItems = saleItems.filter((saleItem) => {
           let notIgnored = posVC.ignoredItemsForPromotions.indexOf(saleItem.rowNumber) === -1;
            let promosStr = `${saleItem.item.promoBuy}&${saleItem.item.promoGet}&${saleItem.item.promoSal3}&${saleItem.item.promoSal4}`;
            if (runOnSaleItemChildren){
              const children = saleItemHelper.extractSaleItemChildrenAndGrandchildren(saleItem);
              children.children.forEach(child => {
                promosStr += `&${child.item.promoBuy}&${child.item.promoGet}&${child.item.promoSal3}&${child.item.promoSal4}`;
              });
            }
            let promos = _.uniq(promosStr.split("&"));
            let hasRelevant = promos.filter(x=>relevantPromotionCodes.has(x)).length > 0;
           return (saleItem.noDiscount == false && ((notIgnored && hasRelevant) || rowsToKeep.includes(saleItem.rowNumber))) && !saleItem.disablePromotions;
        });

        
        if (saleHasDiscount) {
          relevantItems = saleItems.filter(si => !si.noDiscount);
        }
  
        let updatedSaleItems = [] //get a clone of the sale items and work on it.
        for (let saleItem of relevantItems) {
          updatedSaleItems.push(_.cloneDeep(saleItem)); //$.extend(true,{},saleItem)
        }
  
        return updatedSaleItems;
      }

      private getDiscountPromotions() {

        let jd = JSON.parse(posVC.sale.jsondata)
        if (jd.discounts == null) {
          return [];
        }
        let discountPromotions = []
        for (let discountPg of (<Array<PromoGroup>>jd.discounts)) {
          let promo = new Storage.Entity.Promotion();
          let discount = session.allDiscounts.filter(d => d.discountID == discountPg.promotionCode)[0]
          if (discount == null && discountPg.promotionCode == Service.CustomerClub.CUSTOMER_DISCOUNT_ID) {
            discount = Service.CustomerClub.createCustomerDiscount();
          }
          promo.code = `D${discountPg.promotionCode}`;
          promo.name = discountPg.promoName;
          promo.template = "0"
          promo.priority = discount.allowDuplicatePromotion ? 1000000 : -1000000;
          promo.discountValue = 
            discountPg.discountType == Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT ? discountPg.discountPercentForGroup : discountPg.discountAmountForGroup;
          promo.fromDate = moment().subtract(1,'year').format("DD/MM/YYYY")
          promo.toDate = moment().add(1,'year').format("DD/MM/YYYY")
          promo.isActive = true;
          promo.discountType = discountPg.discountType;
          promo.allowWithOtherPromotions = discount.allowDuplicatePromotion;
          promo.discountGroup = discountPg;
          discountPromotions.push(promo);
        }

        
        return discountPromotions;
      }

      static checkIfHasSomeItemGroupsPromotion (customer?) {
        return session.allPromotions.some(pr => NewPromotionsEngine.isItemGroupsPromotion(pr) && NewPromotionsEngine.isPromotionActive(pr, customer));
      }

      private loadAvailablePromotions(externalPromotionCodes, itemGroupsPromotionsOnly = false) {
        let aThis = this;
        // Fetch all automatic promotions
        let promotions = Storage.Entity.Promotion.fetchAutomaticByStoreAndCodes(session.pos.storeID, 
          this.hasCustomer, this.allPromotionsCodes, 
          this.customerPromotionTypes, this.customerGroupId, externalPromotionCodes)
        
        let allPromos = promotions.concat(aThis.manualPromotions).concat(this.getDiscountPromotions());
        allPromos = allPromos.filter(pr => {
          return NewPromotionsEngine.isPromotionActive(pr, this.customer) && 
          (itemGroupsPromotionsOnly ? NewPromotionsEngine.isItemGroupsPromotion(pr) : !NewPromotionsEngine.isItemGroupsPromotion(pr));
        });
        return allPromos;
      }
  
      static promotionSortFunc(a:Storage.Entity.Promotion,b:Storage.Entity.Promotion) {

        if (!NewPromotionsEngine.isDiscountPromotion(a) && !NewPromotionsEngine.isDiscountPromotion(b)) {
          if (NewPromotionsEngine.isSalePromotion(a) != NewPromotionsEngine.isSalePromotion(b)) { //put sale promotions at the end
            return Number(NewPromotionsEngine.isSalePromotion(a))-Number(NewPromotionsEngine.isSalePromotion(b))
          }
        }

        if (NewPromotionsEngine.isDiscountPromotion(a) && NewPromotionsEngine.isDiscountPromotion(b)) {
          if (a.discountGroup.isSaleDiscount != b.discountGroup.isSaleDiscount) {
            //put saleDiscounts after regular discounts
            return Number(Boolean(a.discountGroup.isSaleDiscount)) - Number(Boolean(b.discountGroup.isSaleDiscount))
          }
        }

        if (a.priority != b.priority) {
          return a.priority - b.priority;
        }

        if (a.discountType != b.discountType) {
          return a.discountTypeForSort - b.discountTypeForSort;
        }

        return (Number(a.code) - Number(b.code));
      }

      static isMessagePromotion(promotion) {
        switch (promotion.template) {
          case '22':
          case '30':
            return true;
          default:
            return false;
        }
      }

      static isSalePromotion(promotion) {
        switch (promotion.template) {
          case '21':
          case '22':
            return true;
          default:
            return false;
        }
      }

      static isDiscountPromotion(promotion:Storage.Entity.Promotion) {
        return promotion.discountGroup != null;
      }
     
  
      private findBestPromotions(promoObj:PromotionsResult, promotions:Array<Storage.Entity.Promotion>, 
          sale, updatedSaleItems, getPotentialSalePromotions = false) {
  
        //sort promotions by 1. priority, 2. discountType (fixed price is last), 4. promotionCode (to be deterministic)
        let promos = promotions.sort(NewPromotionsEngine.promotionSortFunc)

        let allReducedItems = [];
        let reducedItems = []

        let relevantItems = updatedSaleItems;
        promos.forEach((promo:Storage.Entity.Promotion) => {
        let parameters = this.processPromotionsForSaleItems(promotions,relevantItems);
          parameters.getPotentialSalePromotions = getPotentialSalePromotions;
          if (getPotentialSalePromotions) {
            let currGroups = Pinia.globalStore.promoGroups;
            if (currGroups.filter(pg => promo.dependantCodes.indexOf(pg.promotionCode) > -1).length > 0) {
              return;
            }
          }
          let templateInstance = NewPromotionsEngine.promotionFactory(promo.template,parameters);

          //we run the promotion only if no promotion it depends on has been activated before
          if (promoObj.promotionGroups.filter(pg => promo.dependantCodes.indexOf(pg.promotionCode) > -1).length == 0) {
        
            templateInstance.run(promo);
            parameters = templateInstance.parameters;
            let promotionGroups:Array<PromoGroup> = parameters.promoGroups;

            promotionGroups.forEach(this.addGroupMissingProperties);

            [relevantItems,reducedItems] = this.addPromoGroups(promoObj, promotions, sale, relevantItems, promotionGroups.filter(pg => pg.promotion.code == promo.code));
            allReducedItems = allReducedItems.concat(reducedItems)
          }
        })


        return allReducedItems.concat(relevantItems)
        // if (salePromotionsRun) {
        //   return;
        // }
        // else {
        //   this.findBestPromotions(promoObj,promotions,sale,allItemsForSalePromotions,true)
        // }

      }

      private addGroupMissingProperties(group:PromoGroup) {
        group.promotionCode = group.promotion.code;
        group.priority = Number(group.promotion.priority) == 0 ? Number.POSITIVE_INFINITY : group.promotion.priority
        group.discountType = group.promotion.discountType;
        group.clubMemberPromotionType = group.promotion.clubMemberPromotionType;
        group.discountPercentForGroup = group.totalPriceForItemsBeforeDiscount == 0 ? 0 :group.discountAmountForGroup/group.totalPriceForItemsBeforeDiscount;
        group.hasBarcode = false;
        group.isDiscount = Boolean(group.isDiscount);
        group.promotionAsDiscount = Boolean(group.promotion.promotionAsDiscount);
        if (Boolean(group.promotion.hasBarcode)) {
          group.promotionBarcode = group.promotion.promotionBarcode;
          group.hasBarcode = true;
        }
      }


      private addPromoGroups(promoObj:PromotionsResult, promotions, sale, updatedSaleItems, promoGroups:Array<PromoGroup>) {
        //remove promotions that don't have allow multiple times same sale
        let saleItemsMinusPromotion = [];
        let reducedItems = []
        let usedIndexes = [];
        for (let pg of promoGroups) {
         
          
          promoObj.promotionGroups = promoObj.promotionGroups.concat(pg);
 
          _.forEach(pg.itemsCounter,(itemCounterValue:number,itemCounterKey:string) => {
            let remaining = itemCounterValue;
            for(let key in updatedSaleItems) {
              if (usedIndexes.includes(key)) {
                continue;
              }
              if (updatedSaleItems[key]['rowNumber'] == itemCounterKey) {
                if (!pg.promotion.allowWithOtherPromotions) {
                  if (remaining > 0) {
                    updatedSaleItems[key].quantity -= Math.min(updatedSaleItems[key].quantity,1);
                    updatedSaleItems[key].hasPromotion = true;
                    let percent = pg.totalPriceForItemsBeforeDiscount == 0 ? 0 : pg.discountAmountForGroup/pg.totalPriceForItemsBeforeDiscount;
                    updatedSaleItems[key].unitPrice *= (1-percent); //for sale promotions
                    usedIndexes.push(key);
                    remaining--;
                  }
                }
                else {
                  if (remaining > 0) {
                    let percent = pg.totalPriceForItemsBeforeDiscount == 0 ? 0 : pg.discountAmountForGroup/pg.totalPriceForItemsBeforeDiscount;
                    updatedSaleItems[key].unitPrice *= (1-percent);
                    updatedSaleItems[key].hasPromotion = true;
                    usedIndexes.push(key);
                    remaining--;
                  }
                }
              }
            }
          });

          //TODO: not sure if we need this
          this.promotionsBuy = {};
          this.promotionsGet = {};
          this.saleItemBuyPromotions = {};
          //TODO: end of TODO

        }

        if (promoGroups[0] && !promoGroups[0].promotion.allowWithOtherPromotions) {
          for(let key in updatedSaleItems) {
            if (updatedSaleItems[key].quantity > 0) {
              saleItemsMinusPromotion.push(updatedSaleItems[key]);
            }
            else {
              reducedItems.push(updatedSaleItems[key])
            }
          }
        }
        else {
          saleItemsMinusPromotion = updatedSaleItems;
        }

        this.preprocessSaleItems(saleItemsMinusPromotion);
        return [saleItemsMinusPromotion,reducedItems];
        //return this.findBestPromotions(promoObj,promotions,sale,saleItemsMinusPromotion);
		  }

      private static promotionFactory(template:string, parameters) {
        switch (template) {
          case '0':
            return new Templates.discountPromotion(parameters)
          case '12':
            return new Templates.KneKabelNew(parameters)
          case '-1':
          case '13':
          case '14':
            return new Templates.KneBe(parameters)
          case '15':
            return new Templates.HaZolMeNew(parameters)
          case '16':
          case '17':
          case '160':
          case '170':
            return new Templates.KneBeWithWeightNew(parameters)
          case '18':
            return new Templates.Kombina(parameters)
          case '19':
            return new Templates.Medorag(parameters)
          case '21':
            return new Templates.HeshbonDiscount(parameters)
          case '22':
            return new Templates.KneKabelNew(parameters)
          case '30':
            return new Templates.Dirbun(parameters)
        }
      }
  
      private processPromotionsForSaleItems(promotions:Array<Storage.Entity.Promotion>,updatedSaleItems:Array<Storage.Entity.SaleItem>) {
        
        promotions = promotions.filter(promo => NewPromotionsEngine.isPromotionActive(promo))
        let promotionsByCode = {};
        promotions.forEach(promo => promotionsByCode[promo.code] = promo);
        updatedSaleItems = this.filterSaleItemsWithDiscount(updatedSaleItems);
        let saleItemsThatCanGetPromotions = updatedSaleItems
  
        if(jsonConfig.getVal(jsonConfig.KEYS.allowToDisablePromotionsOnItem)) {
          saleItemsThatCanGetPromotions = updatedSaleItems.filter(si =>  !si.disablePromotions);
        }
        // Build parameters object
        let parameters = {
          // saleItemsPotentialPromotions:	this.saleItemsPotentialPromotions,
          promotionsBuy:								this.promotionsBuy, //we use this
          promotionsGet:								this.promotionsGet, //we use this
          allPromotionsCodes:						this.allPromotionsCodes,
          saleItemBuyPromotions:				this.saleItemBuyPromotions,
          promotionsByCode:							promotionsByCode,
          updatedSaleItems:             saleItemsThatCanGetPromotions,
          allItemsForDiscountPromo:     updatedSaleItems,
          // sale:													sale,
          // saleItems:										saleItems,
          salePromotion: 								this.salePromotion,
          salePromotionDiscountPercent: this.salePromotionDiscountPercent,
          getPotentialSalePromotions: false,
          promoGroups: [] //we use this
        };

        
        return parameters;
      };
  
      static happyHourIsActiveForSaleItem(promotion:Storage.Entity.Promotion, saleItem:Storage.Entity.SaleItem) {
        let isHhActive = true
        let startHour = promotion.happyHourStartingHour == null ? "00" : String(promotion.happyHourStartingHour).padStart(2,"0");
        let startMinute = promotion.happyHourStartingMinutes == null ? "00" : String(promotion.happyHourStartingMinutes).padStart(2,"0");
        let endHour = promotion.happyHourEndingHour == null ? "23" : String(promotion.happyHourEndingHour).padStart(2,"0");
        let endMinute = promotion.happyHourEndingMinutes == null ? "59" : String(promotion.happyHourEndingMinutes).padStart(2,"0");
        let serverHhDays = promotion['happyHourDays']
        isHhActive = serverHhDays && serverHhDays.split(',').includes(moment().locale('en').format('ddd'))
        let format = 'HH:mm:ss'
        let formatDateTime = 'DD/MM/YYYY HH:mm:ss';
        let saleItemAddedAt = moment(saleItem.addTimestamp, formatDateTime).format(format);
        let beforeTime = moment(`${startHour}:${startMinute}:00`, format)
        let afterTime = moment(`${endHour}:${endMinute}:00`, format)
        isHhActive = isHhActive && moment(saleItemAddedAt,format).isBetween(beforeTime, afterTime)
        return isHhActive
      }


      static isItemGroupsPromotion (promotion:Storage.Entity.Promotion) {
        return posUtils.isPresent(promotion.itemGroupIds);
      }

      static isPromotionActive(promotion:Storage.Entity.Promotion, customer?) {
        if(customer && !customer.is_valid && promotion.isClubMembersOnly && ['positive', 'bril'].includes(customer.clubName)){
            return false;
        }
        if (!promotion.isActive) {
          return false;
        }
        if (promotion.isClubMembersOnly && promotion.customerGroupId == null && customer?.clubName == Service.Hakafa.CLUB_IDENTIFIER) {
            return false;
        }
        
        let startHour = promotion.start_hour == null ? "00" : String(promotion.start_hour).padStart(2,"0");
        let startMinute = promotion.start_minute == null ? "00" : String(promotion.start_minute).padStart(2,"0");
        let endHour = promotion.end_hour == null ? "23" : String(promotion.end_hour).padStart(2,"0");
        let endMinute = promotion.start_minute == null ? "59" : String(promotion.end_minute).padStart(2,"0");

        let fromDate = moment(`${promotion.fromDate} ${startHour}:${startMinute}:00`,"DD/MM/YYYY HH:mm:ss")
        let toDate = moment(`${promotion.toDate}  ${endHour}:${endMinute}:59`,"DD/MM/YYYY HH:mm:ss")

        return moment().isBetween(fromDate,toDate);
        
      }
  
      public setManualPromotions(promotions) {
        this.manualPromotions = promotions;
      };
  
      public hasMoreManualPromotionToChose(saleItems) {
        let aThis = this;
  
        let promotions = aThis.getAvilableManualPromotionsForSale(saleItems)
        
        return (promotions.length > 0)
        
      }

  
      private preprocessSaleItems(saleItemsToProcess) {
        saleItemsToProcess = this.filterSaleItemsWithDiscount(saleItemsToProcess);

        this.promotionsBuy = {};
        this.promotionsGet = {};
        this.saleItemBuyPromotions = {};
  
        for (var i = 0; i < saleItemsToProcess.length; i++) {
          // Get the sale item
          var saleItem = saleItemsToProcess[i];
  
          // Extract the promotions from the item of the sale item
          var extractedPromotions = this.extractPromotionsFromItem(saleItem);
          var promoBuy = extractedPromotions.promoBuy;
          var promoGet = extractedPromotions.promoGet;
  
          this.saleItemBuyPromotions[saleItem.id] = promoBuy;
  
          this.addPromotionsToAllPromotionsCodesArray(promoBuy);
          this.addPromotionsToAllPromotionsCodesArray(promoGet);
  
          this.addSaleItemToPromotionsBuyArray(saleItem, promoBuy);
          this.addSaleItemToPromotionsGetArray(saleItem, promoGet);
  
          // this.addPotentialPromotionsForSaleItem(saleItem, promoBuy, promoGet);
        }
      };
  
      private filterSaleItemsWithDiscount(saleItems) {
        // Ignore saleItems that have item discount not from promotions and discount does not allow duplicate
        return saleItems.filter((si:Storage.Entity.SaleItem) => {
          return (si.discountType == Storage.Entity.SaleItem.DiscountType.NULL || (si.discountType !== Storage.Entity.SaleItem.DiscountType.NULL && 
            session.allDiscounts.filter(d => d.discountID == si.discountID)[0].allowDuplicatePromotion == true))
        });
      }

  
      private extractPromotionsFromItem(saleItem: Storage.Entity.SaleItem) {
        let item: Storage.Entity.Item = saleItem.item;
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
  
        // Extract the promotions
        let promoBuy: string[] = [];
        if (item.promoBuy != null) {
          promoBuy = item.promoBuy.split('&');
        }
  
        let promoGet: string[] = [];
        if (item.promoGet != null) {
          promoGet = item.promoGet.split('&');
        }
        
        for (const promo of session.allPromotions) { // Filter out Happy Hour promos on SaleItems that cannot get the promo
          if (promoBuy.includes(promo.code) == false && promoGet.includes(promo.code) == false) {
            continue;
          }
          if (promo.isHappyHour == false || !NewPromotionsEngine.isPromotionActive(promo)) {
            continue;
          }
          if (!NewPromotionsEngine.happyHourIsActiveForSaleItem(promo, saleItem)) {
            if (promoBuy.includes(promo.code)) {
              _.remove(promoBuy, promoCode => promoCode == promo.code)
            }
            if (promoGet.includes(promo.code)) {
              _.remove(promoGet, promoCode => promoCode == promo.code)
            }
          }
        }
        // Filter out happy hour promo that should not be on SaleItem 
  
        return {
          'promoBuy': promoBuy.filter(onlyUnique),
          'promoGet': promoGet.filter(onlyUnique)
        };
      }
  
      private addPromotionsToAllPromotionsCodesArray(promotionCodes) {
        for (let promotionCode of promotionCodes) {
          if (this.allPromotionsCodes.indexOf(promotionCode) === -1) {
            this.allPromotionsCodes[this.allPromotionsCodes.length] = promotionCode;
          }
        }
      }
  
      private addSaleItemToPromotionsBuyArray(saleItem, promoBuy) {
        
        for (let currentPromoBuy of promoBuy) {
          //let currentPromoBuy = promoBuy[j];
          if (!(currentPromoBuy in this.promotionsBuy)) {
            this.promotionsBuy[currentPromoBuy] = [];
          }

          if(!saleItem.disablePromotions) {
            this.promotionsBuy[currentPromoBuy][this.promotionsBuy[currentPromoBuy].length] = saleItem;
          }

        }
      }
  
      private addSaleItemToPromotionsGetArray(saleItem, promoGet) { //TODO: apply promo buy logic on the get side as well
        for (var j = 0; j < promoGet.length; j++) {
          var currentPromoGet = promoGet[j];
          if (!(currentPromoGet in this.promotionsGet)) {
            this.promotionsGet[currentPromoGet] = [];
          }

          if(!saleItem.disablePromotions) {
            this.promotionsGet[currentPromoGet][this.promotionsGet[currentPromoGet].length] = saleItem;
          }
         
        }
      }
  
  }}}
  
