module PositiveTS {
  export module Components {
    export module SelfSelectPaymentMethod {

      export function getComponent() {
        return {
          template: JST.selfSelectPaymentMethod(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          components: {
            PositiveIcon: PositiveIcon.getComponent(),
            SelfServicePage: SelfServicePage.getComponent(),
            PositiveLottie: PositiveLottie.getComponent()
          },
          methods: {
            logoIconNameByLang(logoName){
              let currentLang = Service.Localization.getLanguage();
              if (logoName == 'tenbis' && currentLang == 'en') {
                return 'tenbisEN';
              } else if (logoName == 'tenbis') {
                return 'tenbis';
              }
            },
            async selectMethod(methodName: string) {
              let pageToGoTo = methodName;
              let extraData = {}
              if (methodName == "SelfServiceCash") {
                if (this.availablePaymentMethods.length > 1) {
                  let msg = `${i18next.t('payAttention')}: ${i18next.t('selfService.cashPayment.note')}`;
                  let response: string = await app.showAlertDialog({ content: msg });
                  if (response == "cancel") {
                    return;
                  }
                }            
              }
              if (methodName == "creditCard"){
                if (this.leftAmount >= jsonConfig.getVal(jsonConfig.KEYS.minAmountForPayments) && jsonConfig.getVal(jsonConfig.KEYS.selfServiceSupermarketAllowPayments)){
                  this.setCurrentStep("SelfServiceCreditPaymentMethod");
                  return
                }
                if (session.pos.isEmv){
                  pageToGoTo = "SelfServiceEmv"
                }
                else{
                  pageToGoTo = "SelfServiceAshrait"
                }
              }
              if(methodName == 'creditCardMidragPayments'){
                if (session.pos.isEmv){
                  pageToGoTo = "SelfServiceEmv"
                  extraData = {isMidragPayments:true};
                }
              }

              let saleJsonData:any = {};

              if (!posUtils.isBlank(posVC.sale.jsondata)) {
                saleJsonData = JSON.parse(posVC.sale.jsondata);
              }

              if (methodName == this.PAYMENT_METHODS.selfServiceCibus && jsonConfig.getVal(jsonConfig.KEYS.showCibusCompanySelectOnSelfServie)){
                let companySelected = await Pinia.componentsStore.openComponent( {componentName:"selfServicePluxeeSelectCompanyDialog", args: []})

                if (!companySelected.selected){
                  return
                }

                extraData = {cibusCompanyId: companySelected.company.companyCode}
              }

              if(methodName == this.PAYMENT_METHODS.selfServiceCaveret) {
                let clubSelected = await Pinia.componentsStore.openComponent( {componentName:"selfServiceCaveretSelectClubDialog", args: []});
                if (!clubSelected.selected){
                  return;
                }
                extraData = { caveretClubCode: clubSelected.club.code }
              }

              let isHakafaPayment = ["SelfServiceHakafa", this.PAYMENT_METHODS.selfServiceHakafaCustomer].includes(methodName);
              if(jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowCustomerNameAfterPaymentMethod) && !isHakafaPayment
                && !PositiveTS.Service.SmartVoucher.getSmartVoucherObjectToLoadIfApplicable(posVC.saleItems,posVC.Specialitems)
                && posUtils.isBlank(saleJsonData.dedicatedTo)) {
                this.setSelectedPaymentMethod(pageToGoTo)
                this.setCurrentStep("SelfSelectCustomer");
              }
              else{
                this.setCurrentStep(pageToGoTo);
              }

              this.setExtraData(extraData)
            },
          },
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {setCurrentStep,setSelectedPaymentMethod,setIsAfterPaymentPage,setExtraData} = selfServicePaymentStore;

            const {isAfterPaymentPage} = Pinia.storeToRefs(selfServicePaymentStore);

            const globalStore = Pinia.useGlobalStore();
            const {isSaleGiftCard} = Pinia.storeToRefs(globalStore);

            return {isSaleGiftCard,setCurrentStep,setSelectedPaymentMethod,setIsAfterPaymentPage,setExtraData,isAfterPaymentPage}
          },
          computed: {
            isMidragPaymentsEnabled(){
              let midragPayments = JSON.parse(session.pos.midragPayments);
              let isOnlyOnePayment = false;
              let maxMidragAmount =0;
              midragPayments.forEach(midrag => {
                if(midrag.numOfPayments == 1 && this.leftAmount >= midrag.fromAmount && this.leftAmount <= midrag.toAmount){
                  isOnlyOnePayment =true
                }
                if(midrag.toAmount > maxMidragAmount){
                  maxMidragAmount = midrag.toAmount;
                }
              })
              if(this.leftAmount > maxMidragAmount){
                isOnlyOnePayment = true;
              }
              let isSelfServiceMidragPayments = Boolean(jsonConfig.getVal(jsonConfig.KEYS.selfServiceMidragPayments))
              return isSelfServiceMidragPayments && midragPayments.length > 0 && !isOnlyOnePayment
            },
            isHakafaCustomerPaymentEnabled() {
              let membershipsEnabled = jsonConfig.getVal(jsonConfig.KEYS.memberOfClubs);
              return membershipsEnabled.includes("hakafa");
            },
            numberOfEnabledPaymentMehtods() {
              return this.availablePaymentMethods.length;
            },
            availablePaymentMethods() {
              let availableMethods = [];

              if (Service.Otot.isOtotActive() && PositiveTS.Service.SmartVoucher.getSmartVoucherObjectToLoadIfApplicable(posVC.saleItems,posVC.Specialitems)) {
                return [this.PAYMENT_METHODS.creditCard]
              }

              if (this.isMidragPaymentsEnabled) {
                availableMethods.push(this.PAYMENT_METHODS.creditCardMidragPayments);
              }
              if (this.showCibus) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceCibus);
              }
              if (this.showTenbis) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceTenbis);
              }
              if (this.showCredit) {
                availableMethods.push(this.PAYMENT_METHODS.creditCard);
              }
              if (this.showValuCardPay) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceChargeableCard);
              }
              if (this.showDirectHakafaPayment) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceHakafa);
              }
              if (this.isHakafaCustomerPaymentEnabled) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceHakafaCustomer);
              }
              if (this.showGoodi) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceGoodi);
              }
              if (this.showHoger) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceHoger);
              }
              if (this.showGpp) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceGpp);
              }
              if (this.showOtot) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceOtot);
              }
              if (this.showCaveret) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceCaveret);
              }
              if (this.showCash) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceCash);
              }
              if (this.showSafeCash) {
                availableMethods.push(this.PAYMENT_METHODS.selfServiceSafeCash);
              }

              return availableMethods;
            },
            leftToPayText() {
              return `${this.$t('selfServicePaymentMethod.leftToPay')}: ${this.fixedNumber(this.leftAmount)} ${this.currencySign}`
            },
            isOsherAd(){
              return Service.OsherAd.isEnabled();
            },
          },
          data: function() {
            return {
              leftAmount: 0,
              showCash: Boolean(jsonConfig.getVal(jsonConfig.KEYS.payCashInSelfService)),
              showCibus: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCibus)),
              showTenbis: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showTenbis)),
              showCredit: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCredit)),
              showValuCardPay: Boolean(jsonConfig.getVal(jsonConfig.KEYS.isShowSimplePosValuCardPay)),
              showCaveret: Boolean(jsonConfig.getVal(jsonConfig.KEYS.isCaveretPayment)),
              showDirectHakafaPayment: Boolean(jsonConfig.getVal(jsonConfig.KEYS.directHakafaPayment)),
              currencySign: Service.MultiCurr.getInstance().getPosCurrencySign(),
              showGoodi: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showGoodi)),
              showSafeCash: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentSafeCash)),
              showHoger: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showHoger)),
              showGpp: false,
              showOtot: Service.Otot.isOtotActive() && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showOtotPayment)),
              PAYMENT_METHODS: {
                creditCard: 'creditCard',
                creditCardMidragPayments:"creditCardMidragPayments",
                selfServiceHakafa: 'SelfServiceHakafa',
                selfServiceHakafaCustomer: 'SelfServiceCustomerClubPaymentMethod',
                selfServiceChargeableCard: 'SelfServiceChargeableCard',
                selfServiceOtot: 'SelfServiceOtot',
                selfServiceTenbis: 'SelfServiceTenbis',
                selfServiceCibus: 'SelfServiceCibus',
                selfServiceGoodi: 'SelfServiceGoodi',
                selfServiceCaveret: 'SelfServiceCaveret',
                selfServiceHoger: 'SelfServiceHoger',
                selfServiceGpp: 'SelfServiceGpp',
                selfServiceSafeCash: 'SelfServiceSafeCash',
                selfServiceCash: 'SelfServiceCash',
              }
            }
          },
          async mounted() {
            let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
            this.leftAmount = totals.totalAmount - totals.totalPaid;

            let gppVoucher = await PositiveTS.Service.WasmDB.promiseSql("SELECT * FROM VoucherStore JOIN Voucher ON Voucher.typeID = VoucherStore.voucherTypeID WHERE Voucher.smartVoucherType = 'GPP' AND VoucherStore.storeID = " + session.pos.storeID)
            if (gppVoucher.length > 0) {
              this.showGpp = true
            }

            if (this.availablePaymentMethods.length == 1) {
              if (this.isAfterPaymentPage) {
                this.setIsAfterPaymentPage(false);
                this.goBack();
              } else {
                this.setIsAfterPaymentPage(true);
                this.selectMethod(this.availablePaymentMethods[0]);
              }
            }
          }
        }
      }
    }
  }
}
