
module PositiveTS {
	export module Components {
		export module OsherAdError {
			export function getComponent() {
				return {
					template: JST.OsherAdError(),
					components: {
						PositiveLottie: PositiveLottie.getComponent(),
					},
					props: {
						type: {
							type: String,
							default: 'error',
						}
					},
					data() {
						return {
						}
					},
					methods: {
						goToHomepage() {
							PositiveTS.VueInstance.goToHomepage();
						},
					},
					computed:{
						autoStore(){
							return Pinia.posStore.autoStore
						},
						errors(): string[] {
							return this.autoStore?.config?.errors || [];
						},
						error(): string {
							return this.autoStore?.error || "";
						}
					}
				}
			}
		}
	}
}
