



module PositiveTS {
/*
Parxell Balance validation for:
16200346301606=19629377975239387028

START Parxell Request:
https://www.praxellgw.com/data=113001_9377975239387028_0000_0000000000000000_00000_151_019_0000001_0001_00000000_00000000_NIS_01102014_182128_01_XX


Response:
data=083501_1620034630160601_00000_151_019_0000001_00008740_00008740_01102014_182128_000_


*/
  export module Service {
    export class PraxellAddon {
      private _clsStr:typeof Service.StrUtils;
      private ajax:any
      private _structLastTrx = {req: undefined, response: undefined }
      private _URL_PREFIX:string
      constructor() {
        this.ajax = PositiveTS.Service.Ajax;
        this._clsStr = PositiveTS.Service.StrUtils;
        this._URL_PREFIX = 'https://www.praxellgw.com/data=';
        if ( this._getLocalStorage() ) {
          this._structLastTrx = this._getLocalStorage();
        }
      }

      /*
      * Unfinished transaction is when Praxell succeded
      * but something else whent wrong (like creditcard, or persist of record)
      */
      hasUnfinishedTrx() {
        var _return = false;
        if (this._structLastTrx &&
          this._structLastTrx.response &&
          this._structLastTrx.response.success == true) {
            _return = true;
          }
          return _return;
        };

      undoLastTrxBackup(){
        // use received transaction id to undo action
        return aPrxlAdon.promiseCancelDebitCard(aPrxlAdon._structLastTrx.response.cardNumber,
          aPrxlAdon._structLastTrx.response.transaction_id, 'invoiceNumber')
          .then(function(response){
            if (response.success || response.message.split(':')[0] === '015') { //if code is 15 it means that cancel was already done in the past
              aPrxlAdon.resetLastTrxBackup();
              return true;
            }
          });
      };

      /*
      Description:
      Cancel debitcard payment
      Notice,  the invoice number is not really used (it is override later on by '000000')
      */
      promiseCancelDebitCard(cardNumber, transaction_id, invoiceNumber){
        var that = this;

        var companyId = session.pos.companyID;
        var store_id = session.pos.storeID;
        var posDisplayNumber = aPrxlAdon._PRAXELL_POS_NUMBER;

        var  message = {
          op_code: '003',
          card_id: that._clsStr.lpadRight(cardNumber, 16),
          transaction_id: that._clsStr.lpadRight(transaction_id, 16),
          order_id: that._clsStr.lpadRight(invoiceNumber, 5),
          network_id: that._clsStr.lpadRight(aPrxlAdon._getPraxellNetworkId(), 3),
          store_id: that._clsStr.lpadRight(store_id, 3),
          terminal_id: that._clsStr.lpadRight(posDisplayNumber, 7)
        };


        var getMessage = aPrxlAdon._composeSendMessage( message );

        var _data={"body":"","type":"GET","url":getMessage};
        var data = JSON.stringify(_data)
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                      {data: data, action: "HTTP_ROUTER" },
                      "http_router"
        ).then(function (response) {
          return (aPrxlAdon._parseResponse(response) );
        })
        .catch(function (response) {
          return (aPrxlAdon._parseResponse(response) );
        })
        .then(function(response){
          return aPrxlAdon._parseCancelCardDebitResponse(response );
        })
      }

      private addLeadingZeros(str:string,zeroCount:number):string {
        let zeroStr = "";
        for (let i=0; i<zeroCount; i++) {
          zeroStr += "0"
        }
        return (zeroStr+str);
      }

      promiseAddValueCard(cardNumber, amount, invoiceNumber ):Promise<any> {
        var that = this;
        that.resetLastTrxBackup();

        var companyId = session.pos.companyID;
        var store_id = session.pos.storeID;
        var posDisplayNumber = aPrxlAdon._PRAXELL_POS_NUMBER;
        let strAmount = String(Math.round(Number(amount)*100));

        var  message = {
          op_code: '005',
          card_id: that._clsStr.lpadRight(cardNumber, 16),
          order_id: that._clsStr.lpadRight(invoiceNumber, 5),
          network_id: that._clsStr.lpadRight(aPrxlAdon._getPraxellNetworkId(), 3),
          store_id: that._clsStr.lpadRight(store_id, 3),
          terminal_id: that._clsStr.lpadRight(posDisplayNumber, 7),
          amount1: this.addLeadingZeros( strAmount, 8-strAmount.length)
        };


        var getMessage = aPrxlAdon._composeSendMessage( message );
        that.setLastTrxBackupReq(getMessage);


        var _data={"body":"","type":"GET","url":getMessage};
        var data = JSON.stringify(_data)
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                      {data: data, action: "HTTP_ROUTER" },
                      "http_router"
        ).then(function (response) {
          return (aPrxlAdon._parseResponse(response) );
        })
        .then(function(response){
          // Parsing response for add value
          // is the same for debit
          var parsed = aPrxlAdon._parseCardDebitResponse(response );
          aPrxlAdon.setLastTrxBackupResponse(parsed);
          return parsed;
        })
      }

      promiseDebitCard(cardNumber, amount, invoiceNumber ){
        var that = this;
        aPrxlAdon.resetLastTrxBackup();


        var companyId = session.pos.companyID;
        var store_id = session.pos.storeID;
        var posDisplayNumber = aPrxlAdon._PRAXELL_POS_NUMBER;
        let strAmount = String(Math.round(Number(amount)*100));

        var  message = {
          op_code: '002',
          card_id: that._clsStr.lpadRight(cardNumber, 16),
          order_id: that._clsStr.lpadRight(invoiceNumber, 5),
          network_id: that._clsStr.lpadRight(aPrxlAdon._getPraxellNetworkId(), 3),
          store_id: that._clsStr.lpadRight(store_id, 3),
          terminal_id: that._clsStr.lpadRight(posDisplayNumber, 7),
          amount1: this.addLeadingZeros( strAmount, 8-strAmount.length)
        };



        var getMessage = aPrxlAdon._composeSendMessage( message );
        aPrxlAdon.setLastTrxBackupReq(getMessage);

        var _data={"body":"","type":"GET","url":getMessage};
        var data = JSON.stringify(_data)
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                      {data: data, action: "HTTP_ROUTER" },
                      "http_router"
        ).then(function (response) {
          return (aPrxlAdon._parseResponse(response) );
        })
        .then(function(response){
          var parsed = aPrxlAdon._parseCardDebitResponse(response );
          aPrxlAdon.setLastTrxBackupResponse(parsed);
          return parsed;
        } );

      }

      resetLastTrxBackup() {
        this._structLastTrx = {req: undefined, response: undefined };
        this._setLocalStorage();
      }

      promiseGetCardBalance( cardNumber ){

        var that = this;

        var companyId = session.pos.companyID;
        var store_id = session.pos.storeID;
        var posDisplayNumber = aPrxlAdon._PRAXELL_POS_NUMBER;
        var  message = {
          op_code: '001',
          card_id: that._clsStr.lpadRight(cardNumber, 16),
          network_id: that._clsStr.lpadRight(aPrxlAdon._getPraxellNetworkId(), 3),
          store_id: that._clsStr.lpadRight(store_id, 3),
          terminal_id: that._clsStr.lpadRight(posDisplayNumber, 7)
        };

        var getMessage = aPrxlAdon._composeSendMessage( message );

        var _data={"body":"","type":"GET","url":getMessage};
        var data = JSON.stringify(_data)
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                      {data: data, action: "HTTP_ROUTER" },
                      "http_router"
        ).then(function (response) {
          return (aPrxlAdon._parseResponse(response) );
        })
        .catch(function (response) {
          return (aPrxlAdon._parseResponse(response) );
        })
        .then(function(response){
          return aPrxlAdon._parseCardBalanceResponse(response );
        })
      }

      _NETWORK_ID_BY_COMPANY = {
        1: 151,
        2: 127,
        3: 127
      }

      _PRAXELL_POS_NUMBER = 1;

      _ERROR_MESSAGES = {
        '001': 'חשבון לא קיים',
        '002': 'כרטיס חסום',
        '003': 'יתרתך בכרטיס נמוכה מהמבוקש השלם את התשלום באמצעי תשלום אחר',
        '004': 'מטבע שגוי',
        '005': 'חשבון לא קיים',
        '006': 'קוד רשת/חנות/קופה אינו מוגדר בפרקסל',
        '007': 'מסר שגוי',
        '008': 'מסר שגוי',
        '009': 'קוד אישור שגוי',
        '010': 'הכרטיס לא פעיל',
        '011': 'קוד אישי שגוי',
        '012': 'קוד אישי שגוי',
        '013': 'קוד אישי שגוי',
        '014': 'הכרטיס כבר פעיל',
        '015': 'תנועה כבר מבוטלת',
        '016': 'תנועה לא קיימת',
        '018': 'תקלת תקשורת',
        '019': 'כרטיס פג תוקף',
        '020': 'לא ניתן לבטל תנועה',
        '021': 'סכום עסקה שגוי',
        '022': 'תנועה לא קיימת',
        '023': 'לא ניתן לבצע פעולה בכרטיס',
        '025': 'קוד רשת לא פעיל',
        '026': 'יתרת הכרטיס נמוכה מהמבוקש',
        '028': 'קוד אישי שגוי',
        '030': 'סכום עסקה שגוי',
        '031': 'תאריך הפעלה שגוי',
        '032': 'קוד אישי שגוי',
        '038': 'יתרתך בכרטיס נמוכה מהמבוקש',
        '039': 'תנועה כבר קיימת',
        '041': 'לא ניתן לבצע פעולה בכרטיס',
        '043': 'לא ניתן לבטל תנועה',
        '044': 'תקלת תקשורת',
        '045': 'לא ניתן להפעיל את הכרטיס – תוקף קצר',
        '046': 'ערך הפעלה מבוקש אינו מתאים לערך הכרטיס במערכת',
        '047': 'התאריך / השעה בקופה אינה תקינה',
        '503': 'שגיאה'
      };

      //PositiveTS.Service.ParxellAddon._URL_PREFIX = "https://www.praxellgw.com/data=";
      //_URL_PREFIX = 'for example http://fishman.lvh.me:3000/parxellrelay/';

      // Static vars
      _shadowComposedSendMessage:any = {};
      /*
      Parxel factories
      */
      private _createDateByPraxelFormat() {
        var d = new Date();
        return  this._clsStr.lpad( d.getDate(), 2 ) +
        this._clsStr.lpad( d.getMonth() + 1, 2 ) +
        d.getFullYear();
      }

      private _createTimeByPraxelFormat() {
        var d = new Date();
        return  this._clsStr.lpad( d.getHours(), 2 ) +
        this._clsStr.lpad( d.getMinutes(), 2 ) +
        this._clsStr.lpad( d.getSeconds(), 2 );
      }

      private _getParxelUrlFromDict( message ){
        var urlGet  = '';
        var listData = [];
        for (var key in message) {
          listData.push( message[key] );
        }

        var sDataString = listData.join('_');

        urlGet += aPrxlAdon._URL_PREFIX + sDataString.length + sDataString;

        return urlGet;
      };

      /*
      Description:
      Get Paxell long number "001234" and convert to float '12.34'
      */
      private _getParxelNumberToFloat(prxlInt){
        return Number( parseInt( prxlInt ) / 100 )
      };

      private _composeSendMessage(message){
        // Save current parameters since
        // card_id for example is required to be handled by other paarmeters
        aPrxlAdon._shadowComposedSendMessage = message;

        var parametersTemplate = {
          op_code: '001',
          card_id: '0000000000000000',
          user_password: '0000',
          transaction_id: '0000000000000000',
          order_id: '00000', //For some reasone this parameter were not used
          network_id: '003', //would be overide by dict merge
          store_id: '001', //would be overide by dict merge
          terminal_id: '1234567', //would be overide by dict merge
          ruleId: '0001',
          amount1: '00000000',
          amount2: '00000000',
          currency: 'NIS',
          date: aPrxlAdon._createDateByPraxelFormat(),
          time: aPrxlAdon._createTimeByPraxelFormat(),
          method_of_insert: '01',
          suffix: 'XX'
        };
        var parametersMerged =  $.extend(parametersTemplate , message);

        return aPrxlAdon._getParxelUrlFromDict( parametersMerged );
      };

      private _parseResponse( response ) {

        var _return = {
          success: true,
          response: {},
          message: ''
        };


        var message = response.request.result;

        PositiveTS.Service.Filelog.log("praxell",JSON.stringify([JSON.parse(response.request.body.data).url,message]));
        // if no message exists, it is infrastructure error
        if (message.startsWith("ERROR:") || !message) {
          _return.success = false;
          _return.message = message;
          return _return;
        }



        var messageLength = parseInt( message.substr(5, 3) );
        var messageData = message.substr(8, messageLength - 2);
        var dataAsArray = messageData.split('_');

        var returnDict = {
          op_code: dataAsArray[0],
          transaction_id: dataAsArray[1],
          order_id: dataAsArray[2],
          network_id: dataAsArray[3],
          store_id: dataAsArray[4],
          terminal_id: dataAsArray[5],
          amount1: dataAsArray[6],
          amount2: dataAsArray[7],
          date: dataAsArray[8],
          time: dataAsArray[9],
          error: dataAsArray[10]
        };

        _return.response = returnDict;
        return _return;
      };

      private _parseCancelCardDebitResponse(response){
        var responseDict = response.response;


        var _return = {
          success: true,
          card_balance: 0.00,
          cancel_amount: 0.00,
          transaction_id: 0,
          cardNumber:  aPrxlAdon._shadowComposedSendMessage.card_id, //Static var
          message: ''
        };
        _return.success = response.success;


        // if not Infrastructure successfull
        if (!response.success){
          _return.message = response.message;
          return _return;
        }

        // If not logiclly successfull
        if (responseDict['op_code'] == '502') {
          _return.card_balance = aPrxlAdon._getParxelNumberToFloat( responseDict['amount1'] );
          _return.cancel_amount = aPrxlAdon._getParxelNumberToFloat( responseDict['amount2'] );
          _return.transaction_id = responseDict['transaction_id'];
        }
        else
        {
          aPrxlAdon._formatReturnErrorMsg(_return, responseDict);

        }

        return _return;
      };

      private _parseCardDebitResponse(response){
        var responseDict = response.response;


        var _return = {
          success: true,
          card_balance: 0.00,
          debit_amount: 0.00,
          transaction_id: 0,
          card_number:  aPrxlAdon._shadowComposedSendMessage.card_id, //Static var
          message: ''
        };
        _return.success = response.success;


        // if not Infrastructure successfull
        if (!response.success){
          _return.message = response.message;
          return _return;
        }

        // If not logiclly successfull
        if (responseDict['op_code'] == '502' || responseDict['error'] == '003') {
          _return.card_balance = aPrxlAdon._getParxelNumberToFloat( responseDict['amount1'] );
          _return.debit_amount = aPrxlAdon._getParxelNumberToFloat( responseDict['amount2'] );
          _return.transaction_id = responseDict['transaction_id'];
        }
        else
        {
          aPrxlAdon._formatReturnErrorMsg(_return, responseDict);

        }

        return _return;
      };

      /*
      Description:
      Receive byref _return
      */
      private _formatReturnErrorMsg(_return, responseDict){
        _return.success = false;
        var _message;
        // If no hebrew translation to error message,
        // return default of 503 description
        if (aPrxlAdon._ERROR_MESSAGES[responseDict['error']]) {
          _message = aPrxlAdon._ERROR_MESSAGES[responseDict['error']];
        } else {
          _message = aPrxlAdon._ERROR_MESSAGES['503'];
        }



        _return.message = responseDict['error'] + ':' + _message;
      };

      /*
      * Used to reverse unknown status transactions
      */
      private _getLocalStorage(){
        return JSON.parse(  localStorage.getItem('_structLastTrx') );
      }

      private _setLocalStorage(){
        localStorage.setItem('_structLastTrx', JSON.stringify(  this._structLastTrx ));
      }

      private setLastTrxBackupReq(req) {
        this._structLastTrx = {req: req, response: undefined };
        this._setLocalStorage();
      }
      private setLastTrxBackupResponse(response) {
        this._structLastTrx.response = response;
        this._setLocalStorage();
      }

      private _getPraxellNetworkId(){
        var companyId = session.pos.companyID;
        if (session.pos.praxellCode) {
          return session.pos.praxellCode
        } else {
          return aPrxlAdon._NETWORK_ID_BY_COMPANY[companyId];
        }
      }

      /*
      Description:
      get the generic response parsing output dictionary
      and return status and ammount
      */
      private _parseCardBalanceResponse(response){
        var responseDict = response.response;


        var _return = {
          success: true,
          amount: 0.00,
          balance: 0.00,
          message: '',
          responseDict: responseDict
        };
        _return.success = response.success;


        // if not successfull return error
        if (!response.success){
          _return.message = response.message;
          return _return;
        }


        if (responseDict['op_code'] == '501') {
          _return.amount = aPrxlAdon._getParxelNumberToFloat( responseDict['amount1'] );
          _return.balance = aPrxlAdon._getParxelNumberToFloat( responseDict['amount1'] );
        }
        else
        {
          aPrxlAdon._formatReturnErrorMsg(_return, responseDict);
        }

        return _return;
      }
      /*
      Unit test
      */
      private test(){
        this._NETWORK_ID_BY_COMPANY[1] = 200;
        let sInputCard =  '19623482123042440875';
        aPrxlAdon.promiseGetCardBalance( sInputCard ).then(function(i){console.log(i);});

        // Debit with 0.1 Shekel, invoice number 1000
        //aPrxlAdon.promiseDebitCard( sInputCard, 0.1,"1000" ).then(function(i){console.log(i);}) .done;

        // Cancel payment: card_id, trx, invoiceNumber
        // aPrxlAdon.promiseCancelDebitCard( sInputCard, "0000290907600511","1000" ).then(function(i){console.log(i);}) .done;

        // Add 1 Shekel to the card
        //aPrxlAdon.promiseAddValueCard( sInputCard, 1,"1000" ).then(function(i){console.log(i);}) .done;

      }
    }
  }
}

declare var aPrxlAdon:PositiveTS.Service.PraxellAddon
// aPrxlAdon = new PositiveTS.Service.PraxellAddon();
