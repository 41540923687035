module PositiveTS {
  export module Components {
    export module TimedAlertDialog {

      export const okResult = "continue";
      export const cancelResult = "cancel"

      function initData() {
        return {
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          header: "",
          content: "",
          hideCancelButton: false,
          hideContinueButton: false,
          continueButtonText: "",
          cancelButtonText: "",
          cancelCallback: null,
          continueCallback: null,
          timeToEnable: 0,
          timeLeft: 0,
        }
      }

      export function create() {

        let component = {
          template: JST.TimedAlertDialog(),
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
              this.initialized = true;
            },
            cancel() {
              if (this.cancelCallback != null) {
                this.cancelCallback();
              }
              this.resolveFunc(cancelResult);
              this.close();
            },

            async confirmAndClose() {
              if (this.continueCallback != null) {
                this.continueCallback();
              }
              this.resolveFunc(okResult);
              this.close();
            },

            close() {
              this.$el.close();
              this.cleanData();
            },

            countDown(){
              this.timeLeft--;
              if (this.timeLeft > 0) {
                setTimeout(this.countDown, 1000);
              }
            },

            open(options, continueCallback, cancelCallback) {
              this.header = options.header || i18next.t('message');
              this.content = options.content || i18next.t('content');
              this.hideCancelButton = options.hideCancelButton || false;
              this.hideContinueButton = options.hideContinueButton || false;
              this.continueButtonText = options.continueButtonText || i18next.t('ok');
              this.cancelButtonText = options.cancelButtonText || i18next.t('cancel');
              this.continueCallback = continueCallback || null;
              this.cancelCallback = cancelCallback || null;
              this.imageUrl = options.imageUrl || null;
              this.imageHeight = options.imageHeight || null;
              this.timeToEnable = options.timeToEnable || 0;
              this.timeLeft = this.timeToEnable;
              return new Promise((resolve, reject) => {
                if (!this.$el.open) {
                  this.$el.showModal();
                  if(this.timeToEnable > 0){
                      this.countDown();
                  }
                }
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            }
            
          },
          data: initData,
        }

        VueApp.component('timed-alert-dialog', component)
      }
    }
  }
}
