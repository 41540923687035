module PositiveTS {
    export module Components {
      export module PositiveIcon {
  
        export function getComponent() {
          return {
            template: `<component :is="{template:iconTemplate}" :class="this.class ?? ''"/>`,
            props: {
                name: {
                    type: String,
                },
                class: {
                    type: String,
                    default: ''
                }
            },
            computed: {
                iconTemplate() {
                    // Pleas notice to end the icon file with the 'Icon' suffix, after the name tou use in the props
                    try{
                        return JST[this.name + 'Icon']();
                    }catch(e){
                        console.error('Icon not found: ' + this.name);
                        return '';
                    }
                }
            }
          }
        }
      }
    }
  } 
  