
module PositiveTS {
  export module Service {
    export class _returnHash {
      amount: number;
      payments_count: string;
      payment_type: string;
      phone_number: string;
      card_number: string;
      card_type: string;
      valid_until: string;
      confirmation_number: string;
      token: string;
      creditCurrency: string;
      usdRate: string;
      creditLeadCurrencyAmount: string;
      success: boolean;
      all_data: string;
      BalanceOut: string;
      isChargableCard: boolean;
      error_message: string;
      return_code: string;
      tz: string;
    };

    export class Shva {
      private _clsAddon = PositiveTS.Service.Addon;

      private _SHVA_PRYMENT_TYPES_OFFICIAL_NAME = {
        "1": "אשראי רגיל",
        "2": "קרדיט/ עדיף+31",
        "3": "חיוב מיידי",
        "4": "אשראי מועדון",
        "5": "סופר קרדיט",
        "6": "קרדיט בתשלומים קבועים",
        "8": "תשלומים",
        "9": "מועדון בתשלומים",
      };

      private TRANS95_ERRORS = {
        "1": "לא הצליח להתחבר",
        "2": "ניתוק בפרמטרים",
        "3": "ניתוק בתנועות",
        "4": "ניתוק בחסומים",
        "9": "ניתוק בעדכון תוכנה", 
        "0": "הצלחה"}

      private _SPLASH_MSG = 'משדר תנועות אשראי, עם סיום הפעולה יש לסגור ידנית את חלון ״דוח שידור״.';


      async tranExistsAndNotEmpty():Promise<boolean> {
        let tran = null
        if (session.isAndroid) {
          return false;
        }
        try {
          tran = atob(await PositiveTS.Service.FileUpload.readFileBase64('c:/sva/TRAN'))
        } 
        catch(e) {
          if (!e.message) {
            throw e;
          }
          let isfileNotFoundError = (e.message.trim() == "ERROR:Could not find file 'c:\\sva\\TRAN'." || "ERROR:לא היתה אפשרות לאתר את הקובץ 'c:\\sva\\TRAN'.")
          if (!isfileNotFoundError) {
            console.error(e)
            throw e
          }
        }
        return !posUtils.isNullOrUndefinedOrEmptyString(tran);        
      }

      async getTotalFile():Promise<string> {
        let total = null
        try {
          total = atob(await PositiveTS.Service.FileUpload.readFileBase64('c:/sva/TOTAL'))
        }
        catch(e) {
          console.error('could not get total file')
          console.error(e)
        }
        return total;
      }

      promiseV30_GET_TOTAL() {
          var that = this;

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionV30_GET_TOTAL(),
              "sva"
          ).then((x) => {
              return (x.request.result);
          });
      };


      promiseV30_TRANS95() {
          var that = this;

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionV30_TRANS95(),
              "sva"
          ).then((response) => {
            if (parseInt(response.request.result) !== 0){
              if (this.TRANS95_ERRORS[ response.request.result ]){
                throw new Error(this.TRANS95_ERRORS[ response.request.result ])
              }

              throw new Error(response.request.result)
            }
             return (response);
          });
      };


      promiseIS_TRAN_EXISTS() {
          var that = this;

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionIS_TRAN_EXISTS(),
              "sva"
          ).then((x) => {
              return (x.request.result === "true");
          });
      };

      /*
       Description
       Usage:
         Data here is Max z number
       */
      promiseTRANS(data) {
          if (session.isShva30){
            if (data.isForced) {
              return this.promiseV30_BACKUP_FORCETRANS(data)
              .then( ()=>{
                return this.promiseV30_TRANS95();
              })              
            } else {
              return this.promiseV30_CLEANTRAN(data)
              .then( ()=>{
                return this.promiseV30_TRANS95();
              })              
            }
          }

          var that = this;
          app.showLoadingMessage(that._SPLASH_MSG);

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionTRANS(data),
              "sva"
          )
          .then(function(response){
            app.hideLoadingMessage();
            return response;
          });
      };

      promiseCLEANTRAN(data) {
          var that = this;

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionCLEANTRAN(data),
              "sva"
          );
      };

      promiseV30_CLEANTRAN(data) {
          var that = this;

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionV30_CLEANTRAN(data),
              "sva"
          )
          .then( response=>{
            if (response.request.status == "200") {
                var result = response.request.result;

                if (result.startsWith("ERROR")) {
                  throw Error(result);
                } else {
                  return result;
                }
              }
          });
      };

      promiseV30_BACKUP_FORCETRANS(data) {
          var that = this;

          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionV30_BACKUP_FORCETRANS(data),
              "sva"
          );
      };

      /*
       Description
       Usage:
         Data here is Max z number
       */
      promiseTRANS_OUT(data) {
          var that = this;
          return that._clsAddon.sendNativeMessageToExtension(
              that._getDictActionTRANS_OUT(data),
              "sva"
          );
      }

      promiseAddon(data) {
          // Wait for addon to complete
          var that = this;
          return that._clsAddon.sendNativeMessageToExtension(
            serviceShva._getDictActionINTR(data),
              "sva"
          );

      }

      getShvaPaymentTypeOfficialNameFromRequestString(shvaRequestString) {
        var id = shvaRequestString.substr(shvaRequestString.indexOf('D'))[3];
        return this._SHVA_PRYMENT_TYPES_OFFICIAL_NAME[id];
      };

      getBodyOfRequest(response) {
        var _request;
        var isNativeMessaging = response.request.body.data;
        if (isNativeMessaging) {
          _request = response.request.body.data;

        } else {
          _request = unescape(response.request.body.substr(5));
        }
        return _request;
      };



      parseShvaOutString(response) {
        

        var _return_hash = new _returnHash();
        var _result = response.request.result;
        var that = this;

        var _request = that.getBodyOfRequest(response);


        var _returnCode = _result.substr(0, 3);

        var _hasBalanceOut = false;
        if (parseInt(_result.substr(3, 1)) > 0) {
          _hasBalanceOut = true;
        }

        var amountFromRequest: number = parseFloat(Service.StrUtils.extractAmountfromRequest(_request) );
        _return_hash.amount = amountFromRequest / 100;
        //_return_hash.amount = parseFloat( _result.substr(35,8) / 100);

        var _payments_count = parseInt(_result.substr(93, 2));
        var payments_is_supported = (_request.indexOf("H") > 0);
        if (_payments_count === 0 && payments_is_supported) {
          _payments_count = 1;
        }

        _return_hash.payments_count = _payments_count.toString();

        // decode payment type by J
        //_payment_type = _request.substr(_request.indexOf("D") + 1 ,2);
        var _payment_type: string = _request.substr(_request.indexOf("D") + 3, 1);
        if (_payment_type != "1" && payments_is_supported) {
          _return_hash.payment_type = "2";
          if (_payment_type == "8") {
            _return_hash.payments_count = (_payments_count + 1).toString();
          }
          else {
            _return_hash.payments_count = _return_hash.payments_count.toString();//(_payments_count + 1).toString();
          }
        } else {
          _return_hash.payment_type = "1";
        }




        // No phone number fiels in Shva
        _return_hash.phone_number = "";
        _return_hash.return_code = _returnCode;

        if (_returnCode === '000') {
          var _card_number = _result.substr(4, 19);
          _return_hash.card_number = Service.StrUtils.hashCC(_card_number);
          _return_hash.card_type = _result.substr(24, 1);
          // MM/YY
          _return_hash.valid_until = _result.substr(29, 2) +
            "/" +
            _result.substr(31, 2);
          _return_hash.confirmation_number = _result.substr(70, 7);

          // we store full token and not just   _s.cleanCC( _card_number );
          _return_hash.token = Service.StrUtils.extractCCfromRequest(_request);
          _return_hash.creditCurrency = Service.StrUtils.extractMcCreditCurrency(_request);

          _return_hash.tz = Service.StrUtils.extractYFromRequest(_request);


          // => (amount, fromSymble, toSymble) {
          var creditLeadCurrencyAmount;
          // if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
          //   creditLeadCurrencyAmount =
          //     posPaymentVC.moduleMultiCurrencyCredit.dictTranslateFromCurrToCurr(_return_hash.amount,
          //       _return_hash.creditCurrency,
          //       "USD");

          //   creditLeadCurrencyAmount = session.fixedNumber(creditLeadCurrencyAmount);
          //   _return_hash.usdRate = posPaymentVC.Currencies.USD;
          // }
          _return_hash.creditLeadCurrencyAmount = creditLeadCurrencyAmount;

          _return_hash.success = true;
          _return_hash.all_data = JSON.stringify({ request: _request, result: _result });

          if (_hasBalanceOut) {
            _return_hash.BalanceOut = _result.substr(43, 8);
          }

          if (_result.substr(118, 1) == 4) {
            _return_hash.isChargableCard = true;
          }

        } else {
          _return_hash.card_number = _request.substring(1, _request.indexOf("C"));
          var _expdate = "";
          var _IdxExpdate = _request.indexOf("T");
          if (_IdxExpdate > 0) {
            _expdate = _request.substr(_IdxExpdate, 2) +
              "/" +
              _request.substr(_IdxExpdate + 2, 2);
          }
          _return_hash.valid_until = _expdate;

          var _error_message = "";
          _error_message = PositiveTS.Service.CreditDal._getErrorDescriptionByCode(parseInt(_returnCode));
          if (!_error_message) {
            _error_message = _returnCode;
          }
          _return_hash.error_message = _error_message;

          if (response.request.status !== 200) {
            _return_hash.error_message += ' ' + response.request.status;
          }

          if (response.request.err) {
            _return_hash.error_message += ' ' + response.request.err.code + ' ' + response.request.err.message;
          }
        }

        return _return_hash;

      };

      /*
         * Description:
         *   Returns the shva in string
         *   based on _getDictionaryXmlTransmitTransaction(..)
         *  First parameter id CreditCardCredit200 context
         */
      getShvaInString(d) {
        var svaString = "";
        var isCardReader = false;
        // If card reader it is A*
        // if manual number it is B*
        if (d.cardReader == 1) {
          isCardReader = true;
          svaString += "A" + d.readerData;
        } else {
          svaString += "B" + d.cardNumber;
        }

        svaString += "C" + d.totalPayment;

        // Decode payment type from credit 200 to shva
        var _purchaseType = "0";
        switch (parseInt(d.purchaseType)) {
          case 1:
            _purchaseType = "1";
            break;
          case 2:
            _purchaseType = "8";
            break;
          case 3:
            _purchaseType = "6";  //Credit with payments
            break;
          case 4:
            _purchaseType = "2";
            break;
          case 7:
            _purchaseType = "2";
            break;

        }

        var _D = "";
        if (d.actionType == PositiveTS.Service.CreditDal._ACTION_TYPE.debit ||
          d.actionType == PositiveTS.Service.CreditDal._ACTION_TYPE.confirm) {
          _D = "D01";
        }

        if (d.actionType == PositiveTS.Service.CreditDal._ACTION_TYPE.credit) {
          _D = "D51";
          // For some reason transaction always saved as regullar
          if (d.paymentsNumber > 1) {
            _purchaseType = "8";
          }

        }

        // notice not D52 'Action type cancel'

        // credit/debit/cancel
        svaString += _D;
        // Purchase type 01 regular 02 payment
        svaString += _purchaseType;

        // Consts
        // always assume Shekel currency
        if (d.creditCurrency) {
          if (d.creditCurrency == "ILS") {
            svaString += "1";
          } else if (d.creditCurrency == "USD") {
            svaString += "2";
          }
        } else {
          svaString += "1";
        }

        // Cardscan= Regular, manual = signiture type
        if (d.cardReader == 1) {
          svaString += "00";
        } else {
          svaString += "50";
        }



        // Do we have a confirmation number
        if (d.confNumber) {
          // with out E?, since no support for "00000" confiramtion number is required
          svaString += "E?" + d.confNumber;
        } else if (d.actionType == PositiveTS.Service.CreditDal._ACTION_TYPE.credit) {
          //no E?0 at all for credit
          //it is in remark for now
          //svaString += "E?0";
        }

        var first_payment = "";
        var fixed_amount = "";


        if (d.paymentsNumber > 1) {
          var dictFirstPaymentAndFixedAmount =
            PositiveTS.Service.CreditDal._formatFirstPaymentAndFixedAmount(d.totalPayment, d.paymentsNumber);
          first_payment = dictFirstPaymentAndFixedAmount.first_payment;
          fixed_amount = dictFirstPaymentAndFixedAmount.fixed_amount;


          // "F","G","H" required only if paymentsNumber > 1
          var _payment_count = 0;
          if (_purchaseType == "6") {
            _payment_count = parseInt(d.paymentsNumber);
          } else {
            svaString += "F" + first_payment;
            svaString += "G" + fixed_amount;
            // C=F + H * G ==>in shvae   ...  H = H -1
            _payment_count = parseInt(d.paymentsNumber) - 1;
          }


          svaString += "H" + _payment_count;
        }


        // Confirm will return card balance
        // while test [J2] returns if cgargable card
        if (d.actionType == PositiveTS.Service.CreditDal._ACTION_TYPE.confirm) {
          svaString += "J5";
        }

        if (d.actionType == PositiveTS.Service.CreditDal._ACTION_TYPE.J2) {
          svaString += "J2";
        }

        // Expirery date MMYY
        if (Number( d.validationMonth ) !== 0) {
          svaString += "T" + d.validationMonth + d.validationYear;
        }

        // "U" cvv
        if (Number( d.cvvNumber ) !== 0) {
          svaString += "U" + d.cvvNumber;
        }


        if (!isCardReader && d.tz) {
          svaString += "Y" + d.tz;
        }
        // User defined field Unique timestamp
        svaString += "X" + (new Date()).getTime();

        return svaString;
      };



      private _getDictActionINTR(data) {
        return { data: data, action: "INTR" };
      };

      private _getDictActionTRANS(data) {
        var action = "TRANS";
        if (data.isForced) {
          action = "FORCETRANS";
        }
        return { data: data.maxZ, payments: data.payments.join("\n"), action: action };
      };

      private _getDictActionCLEANTRAN(data) {
        return { data: data.maxZ, payments: data.payments.join("\n"), action: "CLEANTRAN" };
      };

      private _getDictActionV30_CLEANTRAN(data) {
        return { data: data.maxZ, payments: data.payments.join("\n"), action: "V30_CLEANTRAN" };
      };

      private _getDictActionV30_BACKUP_FORCETRANS(data) {
        return { data: data.maxZ, payments: data.payments.join("\n"), action: "V30_BACKUP_FORCETRANS" };
      };

      private _getDictActionTRANS_OUT(data) {
        return { data: data, action: "TRANS_OUT" };
      };


      private _getDictActionIS_TRAN_EXISTS() {
        return { data: "", action: "IS_TRAN_EXISTS" };
      };

      private _getDictActionV30_GET_TOTAL() {
        return { data: "", action: "V30_GET_TOTAL" };
      };

      private _getDictActionV30_TRANS95() {
        return { data: "", action: "V30_TRANS95" };
      };


      private _getDictActionIS_LOCK_EXISTS() {
        return { data: "", action: "IS_LOCK_EXISTS ERRROR" };
      };



      private _getDictActionFORCE_TRANS() {
        return { data: "", action: "FORCE_TRANS" };
      };



    }
  }
}
declare var serviceShva:PositiveTS.Service.Shva;
serviceShva = new PositiveTS.Service.Shva();

