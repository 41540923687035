/**
 * @example
 * var successCallback = function (returnedArray) {
 *   var firstExecutionResult = returnedArray[0];
 *   var secondExecutionResult = returnedArray[0];
 *   // ...
 * };
 * 
 * var errorCallback = function (e) {
 *   var message = e.message;
 *   // ...
 * };
 * 
 * var progressCallback = function (message, progressValue) {
 *   // ...
 * };
 */
//מי שאני תופס אותו משתמש בחרא הזה אני יורה בו - אני לא צוחק
module PositiveTS {
	export function Callback(totalCallCount, successCallback, errorCallback, progressCallback?) {
		// Retains the current call count
		var callCount = 0;
		
		// Retains all the objects returned from the successWithObject calls
		var returnedArray = new Array();
		
		// When false, indicates that an error has occured. Used as a locking mechanism so future calls to successWithObject and progress won't do anything.
		var isOk = true;
	
		// Called on call success
		this.successWithObject = function (aObject) {
			// If there was an error, don't continue...
			if (!isOk) {
				return;
			}
			
			// Add the object to the returned array
			returnedArray[callCount] = aObject;
			
			// Increase the call count
			callCount = callCount + 1;
			
			
			// If call count reached the total call count, call the success callback
			if (callCount == totalCallCount) {
				if (typeof successCallback == 'function') {
					successCallback(returnedArray);
				}
			}
		};
		
		// Called in case the was an error during a call
		this.error = function (error) {
			// Lock future calls to successWithObject and progress
			isOk = false;
			
			// Call the error callback
			if (typeof errorCallback == 'function') {
				return errorCallback(error);
			}
		};
		
		// Called to track progress of a job, for example. A message can be supplied to describe the current progress.
		this.progress = function (message, progressValue) {
			// If there was an error, don't continue...
			if (!isOk) {
				return;
			}
			
			// Call the progress callback
			if (typeof progressCallback == 'function') {
				progressCallback(message, progressValue);
			}
		};
	};
}
