module PositiveTS {
export module Storage {
export module Entity {
  /** The ZReport should a tableless model in POS Frontend.
   * Since we don't store any ZReport in IndexedDB anymore...
   */
export class ZReport extends IDBEntity {
  zNumber:number
  tenantID:string
  companyID:string
  storeID:string
  posPhysicalID:string
  posDeviceID:string
  createdAt:string
  totalSales:number
  creditSales:number
  debitSales:number
  totalPayments:number
  totalVat:number
  isPrimary:boolean
  primaryZReportNumber:number
  slaveZReports:any
  invoicesList:any
  shipmentAndTaxInvs:any
  vouchers:any
  payments:any
  currenciesSummary: any;
  syncStatus:number
  syncLastMessage:string
  syncLastMessageTimestamp:string
  confirmationNumber:string
  averageSale:number
  averageItemPrice:number
  salesAbove5k:number
  totalItems:number
  averageQtyPerCustomer:number
  totalCreditQty:number
  totalCreditAmount:number
  totalReturnQty:number
  totalReturnAmount:number
  totalDiscountsAmount:number
  creditCardSummary:string
  emvTransmitReport:string
  gatewayTransmitReport:string
  emvCreditCardReport:string
  emvPaymentsNotTransmitted:string
  XFields: string
  emvTranFileNumber:string
  emvSessionNumbers:string
  tenbisTotalAmount:number
  mishlohaTotalAmount:number
  mishlohaCashTotalAmount:number
  mishlohaCashCount:number
  cibusTotalAmount:number
  goodiTotalAmount:number
  dtsTotalAmount:number
  yaadTotalAmount:number
  tamashTotalAmount:number
  totalHakafaTamash: number
  totalHakafa: number
  tamashCount: number
  hakafaCount: number
  cibusCount: number
  tenbisCount: number
  mishlohaCount: number
  goodiCount: number
  dtsCount: number
  yaadCount:number
  svaTransmitReport:string
  totalHakafaDebtPayments: number
  primaryCategoryStats: string
  totalTaxInvHakafaPayments: number
  totalReceipts:number
  valuTotalAmount: number
  valuCount: number
  withdrawalInvs:any


  canceledBonCount:number
  canceledBonSum:number

  deliveriesCount:number
  deliveriesSum:number
  withdrawnCash:number
  tipsCount:number
  tipsAmount:number
  withdrawnCount:number
  cashMovedToSafe:number
  enterStatement:number
  roundedAmount: number
  roundedCount: number
  zStatement: number
  grandTotal: number
  withoutTransmit:boolean
  externalOrdersAmount:number
  totalSittingDiners: number
  totalDeliveriesDiners: number
  totalSittingDinersSalesAmount: number
  totalDeliveriesDinersSalesAmount: number
  itemDetails: string
  paiditTotalAmount: number
  paiditCount: number
  deliveryApiTotals: any
  salesDiscountReport: string

  constructor() {
    let meta = {
      name: 'ZReport',
      internal: true,
      fields: {
        zNumber: "INT",
        tenantID: "TEXT",
        companyID: "TEXT",
        storeID: "TEXT",
        posPhysicalID: "TEXT",
        posDeviceID: "TEXT",
        createdAt: "TEXT",
        totalSales: "INT",
        creditSales: "INT",
        debitSales: "INT",
        totalPayments: "FLOAT",
        totalVat: "FLOAT",
        syncStatus: "INT",
        syncLastMessage: "TEXT",
        syncLastMessageTimestamp: "TEXT",
        confirmationNumber: "TEXT",
        invoicesList: "JSON",
        payments: "JSON",
        currenciesSummary: "JSON",
        vouchers: "JSON",
        isPrimary: "BOOL",
        primaryZReportNumber: "INT",
        slaveZReports: "JSON",
        averageSale: "FLOAT",
        averageItemPrice: "FLOAT",
        salesAbove5k: "INT",
        totalItems: "FLOAT",
        averageQtyPerCustomer: "FLOAT",
        totalCreditQty: "FLOAT",
        totalCreditAmount: "FLOAT",
        totalReturnQty: "FLOAT",
        totalReturnAmount: "FLOAT",
        totalDiscountsAmount: "FLOAT",
        creditCardSummary: "TEXT",
        emvTransmitReport: "TEXT",
        gatewayTransmitReport: "TEXT",
        svaTransmitReport: "TEXT",
        emvCreditCardReport: "TEXT",
        emvPaymentsNotTransmitted: "TEXT",
        XFields: "TEXT",
        emvTranFileNumber: "TEXT",
        emvSessionNumbers: "TEXT",
        tenbisTotalAmount: "FLOAT",
        mishlohaTotalAmount: "FLOAT",
        mishlohaCashTotalAmount:"FLOAT",
        mishlohaCashCount:"INT",
        cibusTotalAmount: "FLOAT",
        goodiTotalAmount: "FLOAT",
        dtsTotalAmount: "FLOAT",
        yaadTotalAmount: "FLOAT",
        tamashTotalAmount: "FLOAT",
        valuTotalAmount: "FLOAT",
        valuCount: "FLOAT",
        totalHakafaTamash: "FLOAT",
        totalHakafa: "FLOAT",
        tamashCount: "INT",
        hakafaCount: "INT",
        cibusCount: "INT",
        tenbisCount: "INT",
        mishlohaCount: "INT",
        goodiCount: "INT",
        dtsCount: "INT",
        yaadCount: "INT",
        totalHakafaDebtPayments: "FLOAT",
        primaryCategoryStats: "TEXT",
        totalTaxInvHakafaPayments: "FLOAT",
        shipmentAndTaxInvs: "TEXT",
        totalReceipts: "FLOAT",
        canceledBonCount: "INT",
        canceledBonSum: "FLOAT",
        deliveriesCount: "INT",
        deliveriesSum: "FLOAT",
        withdrawnCash: "FLOAT",
        tipsCount: "INT",
        tipsAmount: "FLOAT",
        cashMovedToSafe: "FLOAT",
        enterStatement: "FLOAT",
        zStatement: "FLOAT",
        roundedAmount: "FLOAT",
        roundedCount: "INT",
        grandTotal: "FLOAT",
        withdrawnCount: "INT",
        withdrawalInvs: "TEXT",
        withoutTransmit: "BOOL",
        externalOrdersAmount: "FLOAT",
        totalSittingDiners: "INT",
        totalDeliveriesDiners: "INT",
        totalSittingDinersSalesAmount: "FLOAT",
        totalDeliveriesDinersSalesAmount: "FLOAT",
        paiditTotalAmount: "FLOAT",
        paiditCount: "FLOAT",
        deliveryApiTotals: "JSON",
        salesDiscountReport: "TEXT",
      },
      unique: ['tenantID','companyID','storeID','posPhysicalID','zNumber'],
      money: ['totalPayments']
    }
    super(meta);
  }


  static SYNC_STATUS_NEW = -1;
  static SYNC_STATUS_WAITING_TO_BE_SENT = 1;
  static SYNC_STATUS_SYNCED_SUCCESFULLY = 2;
  static SYNC_STATUS_FAILED = 3;

  static ERP_SYNC_STATUS_NEW       = 1;
  static ERP_SYNC_STATUS_TRANSMITTED   = 2;
  static ERP_SYNC_STATUS_ERROR       = 3;

  toSyncObject () {
    var zReport = this;

    if(posUtils.isBlank(zReport.withdrawalInvs)) {
      zReport.withdrawalInvs = "[]";
    }

    let syncObject:any = {
      zNumber: zReport.zNumber,
      tenant_id: zReport.tenantID,
      company_id: zReport.companyID,
      store_id: zReport.storeID,
      physical_pos_id: zReport.posPhysicalID,
      pos_device_id: zReport.posDeviceID,
      created_at: zReport.createdAt,
      total_sales: zReport.totalSales,
      credit_sales: zReport.creditSales,
      debit_sales: zReport.debitSales,
      average_sale: zReport.averageSale,
      average_item_price: zReport.averageItemPrice,
      sales_above_5k: zReport.salesAbove5k,
      total_hakafa_debt_payments: zReport.totalHakafaDebtPayments,
      total_receipts: zReport.totalReceipts,
      primary_categories_stats: zReport.primaryCategoryStats,
      total_credit_qty: zReport.totalCreditQty,
      total_credit_amount: zReport.totalCreditAmount,
      total_return_qty: zReport.totalReturnQty,
      total_return_amount: zReport.totalReturnAmount,
      total_discount_amount: zReport.totalDiscountsAmount,
      credit_card_summary: zReport.creditCardSummary,
      total_items: zReport.totalItems,
      average_qty_per_customer: zReport.averageQtyPerCustomer,
      total_payments: zReport.totalPayments,
      total_vat: zReport.totalVat,
      is_primary: zReport.isPrimary,
      primary_z_report_number: zReport.primaryZReportNumber,
      slave_z_reports: zReport.slaveZReports,
      invoices_list: JSON.parse(zReport.invoicesList),
      shipment_and_tax_invs: JSON.parse(zReport.shipmentAndTaxInvs),
      withdrawalInvs: JSON.parse(zReport.withdrawalInvs),
      currencies_summary: zReport.currenciesSummary,
      payments: zReport.paymentsAsSyncObject(),
      vouchers: zReport.vouchersAsSyncObject(),
      emvTransmitReport: zReport.emvTransmitReport ? JSON.parse(zReport.emvTransmitReport) : {},
      gatewayTransmitReport: zReport.gatewayTransmitReport ? JSON.parse(zReport.gatewayTransmitReport) : {},
      svaTransmitReport: zReport.svaTransmitReport ? JSON.parse(zReport.svaTransmitReport) : {},
      emvCreditCardReport: zReport.emvCreditCardReport ? JSON.parse(zReport.emvCreditCardReport) : {},
      emvPaymentsNotTransmitted: zReport.emvPaymentsNotTransmitted,
      XFields: zReport.XFields,
      emvTranFileNumber: zReport.emvTranFileNumber,
      emvSessionNumbers: zReport.emvSessionNumbers,
      tenbisTotalAmount: zReport.tenbisTotalAmount,
      mishlohaTotalAmount: zReport.mishlohaTotalAmount,
      mishlohaCashTotalAmount: zReport.mishlohaCashTotalAmount,
      mishlohaCashCount: zReport.mishlohaCashCount,
      cibusTotalAmount: zReport.cibusTotalAmount,
      goodiTotalAmount: zReport.goodiTotalAmount,
      dtsTotalAmount: zReport.dtsTotalAmount,
      yaadTotalAmount: zReport.yaadTotalAmount,
      tamashTotalAmount: zReport.tamashTotalAmount,
      totalHakafaTamash: zReport.totalHakafaTamash,
      totalHakafa: zReport.totalHakafa,
      tamashCount: zReport.tamashCount,
      hakafaCount: zReport.hakafaCount,
      cibusCount: zReport.cibusCount,
      valuTotalAmount: zReport.valuTotalAmount,
      valuCount: zReport.valuCount,
      tenbisCount: zReport.tenbisCount,
      mishlohaCount: zReport.mishlohaCount,
      goodiCount: zReport.goodiCount,
      dtsCount: zReport.dtsCount,
      yaadCount: zReport.yaadCount,
      canceledBonCount: zReport.canceledBonCount,
      canceledBonSum: zReport.canceledBonSum,
      deliveriesCount: zReport.deliveriesCount,
      deliveriesSum:   zReport.deliveriesSum,
      withdrawnCash: zReport.withdrawnCash,
      tipsCount: zReport.tipsCount,
      tipsAmount: zReport.tipsAmount,
      withdrawnCount: zReport.withdrawnCount,
      cashMovedToSafe:zReport.cashMovedToSafe,
      enterStatement: zReport.enterStatement,
      zStatement: zReport.zStatement,
      roundedAmount: zReport.roundedAmount,
      roundedCount: zReport.roundedCount,
      grandTotal: zReport.grandTotal,
      withoutTransmit: zReport.withoutTransmit,
      externalOrdersAmount:zReport.externalOrdersAmount,
      totalSittingDiners: zReport.totalSittingDiners,
      totalDeliveriesDiners: zReport.totalDeliveriesDiners,
      totalSittingDinersSalesAmount: zReport.totalSittingDinersSalesAmount,
      totalDeliveriesDinersSalesAmount: zReport.totalDeliveriesDinersSalesAmount,
      paiditTotalAmount: zReport.paiditTotalAmount,
      paiditCount: zReport.paiditCount,
      deliveryApiTotals: zReport.deliveryApiTotals ? JSON.parse(zReport.deliveryApiTotals) : {},
      salesDiscountReport: zReport.salesDiscountReport,
    };

    return syncObject;
  };

  vouchersAsSyncObject () {
    var zReportVoucers = JSON.parse(this.vouchers);

    var vouchersList = [];
    for (var voucher_type_id in zReportVoucers) {
      vouchersList.push({
        voucher_type_id: voucher_type_id,
        amount: zReportVoucers[voucher_type_id]
      });
    };

    return vouchersList;
  };

  paymentsAsSyncObject () { //TODO: this conversion to a different object structure is reduandant and just plain stupid
    var zReportPayments = JSON.parse(this.payments);

    var paymentsList = [];
    for (var i in zReportPayments) {
      if (zReportPayments[i].isInXZ && zReportPayments[i].amount != 0) {
        paymentsList.push({
          payment_type_id: zReportPayments[i].seq,
          amount: zReportPayments[i].amount,
          count:  zReportPayments[i].count,
        });
      }
    };

    return paymentsList;
  };

  getZreportStatusText () {
    switch (this.syncStatus) {
      case PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT:
        return i18next.t("waitingToBeSent");
      case PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY:
        return i18next.t("successfullySent");
      case PositiveTS.Storage.Entity.Sale.SYNC_STATUS_FAILED:
        return i18next.t("syncFailed");
      default:
        return i18next.t("unknown");
    }
  };

  static import(z):ZReport {
    let zModel = new ZReport();
    return zModel.importFromObject(z);
  }

  async closePrimaryReportAndTransmit() {
    let aThis = this;

    try {
      // get new z report sequence number
      let sequence = await Sequence.getSequenceForInvType(Sequence.TYPE_Z_REPORT)
      
      // If there is no sequence for this type, create it
      if (sequence == null) {
        throw new Error('ZReport sequence not found for this POS. חובה לפנות לתמיכה.');
      }

      let primaryZNumberSeq = await Sequence.getSequenceForInvType(Sequence.TYPE_Z_MASTER_REPORT)
      if (primaryZNumberSeq == null) {
        throw new Error('PrimaryZReport sequence not found for this POS. חובה לפנות לתמיכה.');
      }

      // Advance the sequence by 1
      let seq = sequence.sequence;
      aThis.zNumber = seq+1;
      sequence.sequence = seq+1;
      primaryZNumberSeq.sequence = primaryZNumberSeq.sequence + 1;
      aThis.primaryZReportNumber  = primaryZNumberSeq.sequence;

      let shvaReportString = "";
      let closedZ = await aThis.closePrimaryReport(aThis,sequence,shvaReportString, primaryZNumberSeq);
      return closedZ;
    }
    catch(error) {
      console.error(error);
      if (error && error.message == "transmitFailedError") {
        throw error;
      }
      throw new Error('unable to close z report');
    }

  };

  closePrimaryReport(zReport:ZReport, sequence:Sequence, shvaReportString:string, primaryZNumber:Sequence) {

    return new Promise((resolve,reject) => {
      appDB.transaction("rw",appDB.svaTransmitLogs,appDB.sequences,async () => {

        let promises = [];
        promises.push(appDB.sequences.put(sequence))
        promises.push(appDB.sequences.put(primaryZNumber))

        zReport.svaTransmitReport = shvaReportString;

        await Promise.all(promises)
      })
      .then(() => { //transaction committed
          resolve(zReport)
        })
      .catch(error => { //transaction failed!
        console.error(error);
        reject(error)
      })
    })
  }

  updateEmvTransmitLog(zReport:ZReport,shvaReportString = null) {

    return new Promise((resolve,reject) => {
      appDB.transaction("rw",appDB.svaTransmitLogs,appDB.emvTransmitLogs,async () => {

          PositiveTS.Service.HoldSale.resetBonTotalSumAndQuantityCanceled();

          let promises = [];

          if (shvaReportString != null) {
          promises.push(appDB.svaTransmitLogs.where('attachedToZNumber').equals('').modify({attachedToZNumber: zReport.zNumber}))
            zReport.svaTransmitReport = shvaReportString;
          }

          if (session.pos.isEmv && (!zReport.withoutTransmit)) {
            //mark transmit logs as attached...
            let sessionNumbers = JSON.parse(zReport.emvSessionNumbers)
            if (sessionNumbers.length > 0) {
            promises.push(appDB.emvTransmitLogs.where('sessionNumber').anyOf(sessionNumbers).modify({isAttached: 1,attachedToZNumber: zReport.zNumber}))
            }
          }
        await Promise.all(promises)
      })
      .then(() => { //transaction committed
            resolve(zReport)
          })
      .catch(error => { //transaction failed!
        console.error(error);
        reject(error)
      })
    })
  }

  async updateSequenceAndCloseSecondaryReportIfNeeded(sales, isSlaveReport):Promise<{zReport:ZReport, sequence:Sequence}> {
    var aThis = this;

    // get new z report sequence number
    let sequence = await Sequence.getSequenceForInvType(Sequence.TYPE_Z_REPORT);

    // If there is no sequence for this type, create it
    if (sequence == null) {
      throw new Error('Z sequence not found for the pos, call to system administrator.');
    }

    aThis.zNumber = sequence.sequence = sequence.sequence + 1;

    try {

      if (isSlaveReport) {
        if (session.pos.isEmv) {
          aThis.syncStatus = PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_WAITING_TO_BE_SENT;
          await PositiveTS.Storage.Entity.EmvTransmitLog.getLastReportAndCreateRecordIfneeded();
          await PositiveTS.Storage.Entity.EmvTransmitLog.attachNotAttachedReports(aThis);
        }
        
        await aThis.updateEmvTransmitLog(aThis)

        // if the Z report was requested from a remote pos
        if(!Service.ZReportAll.isRemoteAllZActive && !jsonConfig.getVal(jsonConfig.KEYS.cancelPrintZReport)){
          if(Service.Gateway.isEnabled()){
            Printing.Reports.ZXReports.printGatewayTransmitReportAlone(aThis)
          }else if(session.pos.isEmv){
            Printing.Reports.ZXReports.printEmvTransmitReportAlone(aThis);
          }
        }
      }
      
      return {zReport:aThis,sequence: sequence};
    }
    catch(error) {
      console.error(error);
      if (error && error.stack) {
        console.error(error.stack);
      }
      throw new Error('unable to close z report');
    }

  };


  getSittingDinersAvg():number {
    return this.totalSittingDiners == 0 ? 0 : this.totalSittingDinersSalesAmount / this.totalSittingDiners;
  }

  getDeliveriesDinersAvg():number {
    return this.totalDeliveriesDiners == 0 ? 0 : this.totalDeliveriesDinersSalesAmount / this.totalDeliveriesDiners;
  }

  getDeliveriesAvg():number {
    return this.deliveriesCount == 0 ? 0 : this.totalDeliveriesDinersSalesAmount / this.deliveriesCount;
  }

}}}}
