
module PositiveTS {
    export module Components {
      export module PositiveLottie {
  
        export function getComponent() {
          return {
            template: `<lottie-animation  :animationData="lottieTemplate" :loop="this.loop" :autoPlay="this.autoPlay"/>`,
            props: {
                name: {
                    type: String,
                },
                class: {
                    type: String,
                    default: ''
                },
                loop: {
                    type: Boolean,
                    default: true
                },
                autoPlay: {
                    type: Boolean,
                    default: true
                },
            },
            computed: {
                lottieTemplate() {
                    // Pleas notice to end the lottie file with the 'Lottie' suffix, after the name tou use in the props
                    try{
                        return JSON.parse(JST[this.name + 'Lottie']());
                    }catch(e){
                        console.error('Lottie not found: ' + this.name);
                        return '';
                    }
                }
            },
          }
        }
      }
    }
  } 