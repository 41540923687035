module PositiveTS {
  export module Printing {
    export module ReportEmployeeTime {

      interface employeeReport {
        entries: Service.ReportEmployeeTime.entryRowObject[],
        empId: string,
        empName: string
      }

      export function EmployeeTimeReportNew(objReports, fromDateString, toDateString) {
        let aryReport: employeeReport[] = [];

        for (let employeeId in objReports) {
          let rowsArray = Service.ReportEmployeeTime.createEmployeeReport(objReports[employeeId].tracks)

          aryReport.push({
            entries: rowsArray,
            empId: employeeId.toString(),
            empName: objReports[employeeId].employee.name
          })
        }

        printReportHeaders(fromDateString, toDateString)

        let aThis = printer;
        let zebra = printer.jzebra;
        let widths = [10, 5, 15, 7, 7]

        for (const empReport of aryReport) {
          let report = empReport
          let empHours = Service.ReportEmployeeTime.getTotalEmployeeHours(report.entries)
          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.centerAlignment);
          aThis.printHr();
          zebra.append(printer.allowComponents.newLine)
          aThis.printLine(`${report.empId} ${report.empName}`)
          zebra.append(printer.allowComponents.newLine)

          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.centerAlignment);
          aThis.printTableRow([i18next.t('reportEmployeeTime.date'), i18next.t('reportEmployeeTime.day'), i18next.t('reportEmployeeTime.hoursRange'), i18next.t('reportEmployeeTime.hours'), i18next.t('reportEmployeeTime.breakTime')], widths)
          aThis.printHr();

          zebra.append(aThis.allowComponents.init);

          for (const row of report.entries) {
            let { date, entryDay, fromDateHour, toDateHour, formatedBreakTime, totalHoursWithBreakTime } = row

            aThis.printTableRow([date, entryDay, `${fromDateHour} - ${toDateHour}`, String(totalHoursWithBreakTime), formatedBreakTime], widths)
          }

          aThis.printHr();
          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.centerAlignment);
          aThis.printLine(`${i18next.t('reportEmployeeTime.totalEmployeeHours')}: ${empHours.toFixed(2)}`);
        }

        printer.printLineCut();
        zebra.append(aThis.allowComponents.init);
        zebra.print();
      }

      export function EmployeeTimeReportOld(objReports, fromDateString, toDateString) {
        let aryReport: employeeReport[] = [];


        for (let employeeId in objReports) {
          let rowsArray = Service.ReportEmployeeTime.createEmployeeReport(objReports[employeeId].tracks)

          aryReport.push({
            entries: rowsArray,
            empId: employeeId.toString(),
            empName: objReports[employeeId].employee.name
          })
        }

        printReportHeaders(fromDateString, toDateString)

        let aThis = printer;
        let zebra = printer.jzebra;

        for (const empReport of aryReport) {
          let report = empReport
          let empHours = Service.ReportEmployeeTime.getTotalEmployeeHours(report.entries)

          zebra.append(aThis.allowComponents.init);
          aThis.printLineForTopBox();
          aThis.printFloatingInBoxLine(report.empId, report.empName)
          aThis.printFloatingInBoxLine("      ", "    ")
          zebra.append(aThis.allowComponents.init);

          aThis.printFloatingInBoxLine(`${i18next.t('reportEmployeeTime.date')}   ${i18next.t('reportEmployeeTime.day')}   ${i18next.t('reportEmployeeTime.hoursRange')}`, `${i18next.t('reportEmployeeTime.hours')}    ${i18next.t('reportEmployeeTime.breakTime')}`, 28);
          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.underline);

          for (const row of report.entries) {
            let { date, entryDay, fromDateHour, toDateHour, formatedBreakTime, totalHoursWithBreakTime } = row

            if (totalHoursWithBreakTime == i18next.t('reportEmployeeTime.noEntry')) {
              if (fromDateHour == i18next.t('reportEmployeeTime.noEntry')) {
                fromDateHour = `${fromDateHour}  `
              }
              aThis.printFloatingInBoxLine(`${date}  ${entryDay}  ${fromDateHour} - ${toDateHour}`, `${totalHoursWithBreakTime}     ${formatedBreakTime}`, printer.lineLength - 20);
            }
            else {
              aThis.printFloatingInBoxLine(`${date}  ${entryDay}  ${fromDateHour} - ${toDateHour}`, `${formatedBreakTime}    ${totalHoursWithBreakTime}`, printer.lineLength - 20);

            }
          }
          aThis.printFloatingInBoxLine("      ", "    ");
          aThis.printFloatingInBoxLine(i18next.t('reportEmployeeTime.totalEmployeeHours'), empHours.toFixed(2));
          aThis.printLineForMiddleBox();
        }
        printer.printLineCut();
        zebra.append(aThis.allowComponents.init);
        zebra.print();

      }

      function printReportHeaders(fromDateString: string, toDateString: string) {
        let aThis = printer;
        let zebra = printer.jzebra;
        let company = session.company;
        let store = session.store;
        let company_name = company.companyName;
        let store_name = store.storeName;

        aThis.printLogo();
        zebra.append(aThis.allowComponents.init);

        zebra.append(aThis.allowComponents.centerAlignment);


        aThis.printLine(company_name);
        let companyNameArray = company.companyName.split("$$$");

        for (const companyName of companyNameArray) {
          aThis.printLine(companyName)
        }

        aThis.printLine(store_name);

        if (!posUtils.isBlank(store.address)) {
          aThis.printLine(store.address);
        }

        if (!posUtils.isBlank(store.phone)) {

          if (aThis.isHTMLBasedPrinting()) {
            aThis.printLine(`${i18next.t('phone')}: ${store.phone}`);
          } else {
            aThis.printText(store.phone);

            aThis.printText('\t');
            aThis.printLine(i18next.t('phone') + ':');
          }
        }

        if (!posUtils.isBlank(store.registrationNum)) {
          if (aThis.isHTMLBasedPrinting()) {
            aThis.printLine(`${aThis.chetPeiAinMem()}: ${store.registrationNum}`);
          } else {
            aThis.printText(store.registrationNum);
            aThis.printText('\t');
            aThis.printLine(aThis.chetPeiAinMem() + ':');
          }
        }

        if (!posUtils.isBlank(store.freeText)) {
          var freeTextArray = store.freeText.split("$$$");

          for (const text of freeTextArray) {
            aThis.printLine(text)
          }
        }

        aThis.printLine(" ");
        zebra.append(aThis.allowComponents.init);
        aThis.printFloatingLine(`${i18next.t('reportEmployeeTime.fromDate')}:`, fromDateString);
        aThis.printFloatingLine(`${i18next.t('reportEmployeeTime.toDate')}:`, toDateString);
        aThis.printFloatingLine(`${i18next.t('reportEmployeeTime.pos')}:`, session.pos.deviceID);
        aThis.printFloatingLine(`${i18next.t('reportEmployeeTime.cashier')}:`, session.pos.employeeName);

        zebra.append(aThis.allowComponents.init);
        aThis.printLine(" ");
        zebra.append(aThis.allowComponents.underline);
        zebra.append(aThis.allowComponents.centerAlignment);
        aThis.printLine(i18next.t('reportEmployeeTime.title'));
        aThis.printLine(" ");
      }
    }
  }
}
