module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div dir="rtl">\n\n    <head>\n      <meta charset="UTF-8">\n    </head>\n    <style>\n      body{\n        font-family: Arial, Helvetica, sans-serif;\n      }\n\n      .container {\n          height: 230mm;\n          display: flex;\n          justify-content: center;\n          align-items: center;\n          flex-direction: column;\n          text-align: center;\n          width: 100%;\n      }\n\n      .container div{\n        height: 50%;\n      }\n\n      .qrcode {\n        display: flex;\n        flex-direction: column-reverse;\n      }\n\n      .name-container {\n        overflow: hidden; \n        height: 87mm;\n        text-align: right;\n      }\n      .name {\n        transform: translateX(48mm) rotate(90deg);\n        transform-origin: top left;\n        height: 87mm;\n        width: 87mm;\n        font-size: 7mm;\n        white-space: nowrap;\n        text-align: center;\n      }\n\n      img {\n          width: 23mm;\n      }\n\n      .horizontal{\n        flex-direction: row-reverse;\n        height: 100%;\n        min-height: 45mm;\n      } \n      \n      .horizontal .name{\n        transform: unset;\n        transform-origin: unset;\n        font-size: 8mm;\n        text-align: unset;\n        width: 100%;\n        white-space: unset;\n      }\n\n      .horizontal img{\n        width: 40mm;\n      }\n    </style>\n    \n\n        <div class="' +
((__t = ( printHorizontal ? 'container horizontal' : 'container' )) == null ? '' : __t) +
'">\n            <div class="qrcode">\n              <img src="<!!= qrcode !!>" />\n            </div>\n            <div class="name-container">\n                <div class="name"><!!= name !!></div>\n            </div>\n        </div>\n</div>\n';

}
return __p
}