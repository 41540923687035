module PositiveTS {
    export module Components {
        export module RestaurantDesktopLayout {
            export function getComponent() {
                let component = {
                    template: JST.RestaurantDesktopLayout(),
                    components: {
                        'resturant-items-display': PositiveTS.Components.RestaurantItemsDisplay.getComponent(),
                        'restaurant-desktop-order-summary': PositiveTS.Components.RestaurantDesktopOrderSummary.getComponent(),
                        'vertical-scroll': PositiveTS.Components.VerticalScroll.getComponent(),
                    },
                    methods: {
                        open() {
                            Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.RestaurantItemMenu);
                        },
                        openFullSearch() {
                            Pinia.componentsStore.openComponent( { componentName: "fullSearch", args: [] });
                        },
                        penDinersDialog() {
                            Pinia.componentsStore.openComponent( { componentName: "selectDinersNumberDialog", args: [] });
                        },

                        calculator() {
                            Pinia.componentsStore.openComponent( { componentName: "calculatorDialog", args: [] });
                        },

                        openTipPaymentScreen() {
                            if (Service.Tip.isHasTips()) {
                                Pinia.globalStore.setOpenPaymentsForTip(true)
                                PositiveTS.Components.PosPaymentButtons.openPaymentScreen('tip');
                            }
                        }
                    },
                    data(): { buttons: Types.PositiveButtonProps[],paymentButtons:Types.PositiveButtonProps[] } {
                        return {
                            buttons: [
                                {
                                    text: i18next.t('restaurant.bottomActions.search'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-magnifying-glass',
                                    onClick: () => { this.openFullSearch() },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.for'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-question-circle',
                                    onClick: () => {
                                        Pinia.componentsStore.openComponent( {componentName:"dedicatedToDialog"})
                                    },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.numberOfDiners'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-people-group',
                                    onClick: () => { this.penDinersDialog() },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.findCustomer'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-hand-holding-heart',
                                    onClick: () => {
                                        posVC.selectCustomerClubForSale();
                                    },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.noPrint'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-xmark-circle',
                                    onClick: () => { PositiveTS.VueInstance.$refs.pos.dalpaksSaveExit(true); },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.discount'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-tag',
                                    onClick: () => {
                                        posVC.selectDiscountForSale();
                                    },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.tip'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-coins',
                                    if: PositiveTS.Service.Tip.isHasTips(),
                                    onClick: () => { this.openTipPaymentScreen() },
                                },
                                {
                                    text: i18next.t('restaurant.bottomActions.calculator'),
                                    border: false,
                                    classes: 'desktop-restaurant-btn',
                                    icon: 'fa-light fa-calculator',
                                    onClick: () => { this.calculator() },
                                },
                            ],
                            paymentButtons:[
                                {
                                    text: i18next.t('posPaymentButtons.splitSalePayment'),
                                    border: false,
                                    icon: 'fa-light fa-money-bills',
                                    if: Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showSplitSalePaymentButton)),
                                    onClick: async () => { await PositiveTS.VueInstance.$refs.pos.openSplitSaleScreen();},
                                },
                                {
                                    text: i18next.t('posPaymentButtons.splitPaymentAmount'),
                                    border: false,
                                    icon: 'fa-light fa-money-bill-wave',
                                    if: Boolean(jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually)),
                                    onClick: async () => { PositiveTS.VueInstance.$refs.pos.openSplitPaymentScreen();},
                                },
                                {
                                    text: i18next.t('pos.dalpakPage.goToPayment'),
                                    border: false,
                                    icon: 'fa-light fa-money-bill-1',
                                    onClick: () => { PositiveTS.VueInstance.$refs.pos.openPaymentScreenSingleButton() },
                                },
                            ]
                        }
                    },
                    setup(){
                        const posStore = Pinia.usePosStore();
                        const {activeMenu,activeMenuName} = Pinia.storeToRefs(posStore);

                        const globalStore = Pinia.useGlobalStore();
                        const {saleTotal,isOnline,usePictures} = Pinia.storeToRefs(globalStore);
                        const globalStoreProps = {saleTotal,stateIsOnline:isOnline,usePictures} 
          
                        return {...globalStoreProps,activeMenu,activeMenuName}
                    },
                    computed: {
                        bottomActionButtons: function () {
                            let buttons = this.buttons.filter(button => button.if !== false);
                            return buttons;
                        },
                        goToPaymentBtns: function () {
                            let active = this.saleTotal > 0 ? 'active' : '';
                            let paymentButtons = this.paymentButtons.map(button => ({
                                ...button,
                                classes:`desktop-restaurant-btn ${active}`
                            })).filter(button => button.if !== false);
                            return paymentButtons;
                        },
                        showPos(){
                            return Pinia.globalStore.posState == "pos";
                        },
                        
                    },
                }
                return component;
            }
        }
    }
}
