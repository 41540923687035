/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
WebsocketRails JavaScript Client

Setting up the dispatcher:
  var dispatcher = new WebSocketRails('localhost:3000/websocket');
  dispatcher.on_open = function() {
    // trigger a server event immediately after opening connection
    dispatcher.trigger('new_user',{user_name: 'guest'});
  })

Triggering a new event on the server
  dispatcherer.trigger('event_name',object_to_be_serialized_to_json);

Listening for new events from the server
  dispatcher.bind('event_name', function(data) {
    console.log(data.user_name);
  });
*/
module WebSocketRails {
  export class Dispatcher {
    url:string
    use_websockets:any
    callbacks:any
    channels:any
    queue:any
    state:string
    on_open:any
    _conn:AbstractConnection
    destroyAfterConnection: boolean = false

    constructor(url, use_websockets = true) {
      this.url = url;
      this.use_websockets = use_websockets;
      this.callbacks = {};
      this.channels  = {};
      this.queue     = {};

      this.connect();
    }

    connect() {
      this.state = 'connecting';

      if (!this.supports_websockets() || !this.use_websockets) {
        this._conn = new HttpConnection(this.url, this);
      } else {
        this._conn = new WebSocketConnection(this.url, this);
      }

      return this._conn.new_message = this.new_message;
    }

    disconnect() {
      if (this._conn) {
        this._conn.close();
        delete this._conn._conn;
        delete this._conn;
      }

      return this.state     = 'disconnected';
    }

    // Reconnects the whole connection, 
    // keeping the messages queue and its' connected channels.
    // 
    // After successfull connection, this will:
    // - reconnect to all channels, that were active while disconnecting
    // - resend all events from which we haven't received any response yet
    reconnect() {
      const old_connection_id = this._conn != null ? this._conn.connection_id : undefined;

      this.disconnect();
      this.connect();

      // Resend all unfinished events from the previous connection.
      for (let id in this.queue) {
        const event = this.queue[id];
        if ((event.connection_id === old_connection_id) && !event.is_result()) {
          this.trigger_event(event);
        }
      }

      this.reconnect_channels();
    }

    new_message(data) {
      return (() => {
        const result = [];
        for (let socket_message of Array.from(data)) {
          const event = new Event( socket_message );
          if (event.is_result()) {
            if (this.queue[event.id] != null) {
              this.queue[event.id].run_callbacks(event.success, event.data);
            }
            delete this.queue[event.id];
          } else if (event.is_channel()) {
            this.dispatch_channel(event);
          } else if (event.is_ping()) {
            this.pong();
          } else {
            this.dispatch(event);
          }

          if ((this.state === 'connecting') && (event.name === 'client_connected')) {
            result.push(this.connection_established(event.data));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    }

    connection_established(data) {
      if (this.destroyAfterConnection) {
        this.disconnect();
        return;
      }

      this.state         = 'connected';
      this._conn.setConnectionId(data.connection_id);
      this._conn.flush_queue();
      if (this.on_open != null) {
        return this.on_open(data);
      }
    }

    unbind(event_name) {
      return this.callbacks[event_name] = [];
    }

    bind(event_name, callback) {
      if (this.callbacks[event_name] == null) { this.callbacks[event_name] = []; }
      return this.callbacks[event_name].push(callback);
    }

    trigger(event_name, data, success_callback, failure_callback) {
      const event = new Event( [event_name, data, (this._conn != null ? this._conn.connection_id : undefined)], success_callback, failure_callback );
      return this.trigger_event(event);
    }

    trigger_event(event) {
      if (this.queue[event.id] == null) { this.queue[event.id] = event; } // Prevent replacing an event that has callbacks stored
      if (this._conn) { 
        this._conn.trigger(event); 
      }
      return event;
    }

    dispatch(event) {
      if (this.callbacks[event.name] == null) { return; }
      return Array.from(this.callbacks[event.name]).map((callback:any) => callback(event.data));
    }

    subscribe(channel_name, success_callback?, failure_callback?):Channel {
      if (this.channels[channel_name] == null) {
        const channel = new Channel(channel_name, this, false, success_callback, failure_callback);
        this.channels[channel_name] = channel;
        return channel;
      } else {
        return this.channels[channel_name];
      }
    }

    subscribe_private(channel_name, success_callback?, failure_callback?) {
      if (this.channels[channel_name] == null) {
        const channel = new Channel(channel_name, this, true, success_callback, failure_callback);
        this.channels[channel_name] = channel;
        return channel;
      } else {
        return this.channels[channel_name];
      }
    }

    unsubscribe(channel_name) {
      if (this.channels[channel_name] == null) { return; }
      this.channels[channel_name].destroy();
      return delete this.channels[channel_name];
    }

    dispatch_channel(event) {
      if (this.channels[event.channel] == null) { return; }
      return this.channels[event.channel].dispatch(event.name, event.data);
    }

    supports_websockets() {
      return ((typeof(WebSocket) === "function") || (typeof(WebSocket) === "object"));
    }

    pong() {
      const pong = new Event( ['websocket_rails.pong', {}, (this._conn != null ? this._conn.connection_id : undefined)] );
      return this._conn.trigger(pong);
    }

    connection_stale() {
      return this.state !== 'connected';
    }

    markForDestruction() {
      this.destroyAfterConnection = true;
    }

    // Destroy and resubscribe to all existing @channels.
    reconnect_channels() {
      let result = [];
      for (let name in this.channels) {
        let channel = this.channels[name];
        const callbacks = channel._callbacks;
        channel.destroy();
        delete this.channels[name];

        channel = channel.is_private ? this.subscribe_private(name) : this.subscribe(name);
        channel._callbacks = callbacks;
        result.push(channel);
      }
      return result;
    }
  };
}

