module PositiveTS {
   export module Service {
      export module WebsocketSync {

         export interface DalpakSyncClientListener {
            dalpakCreated(dalpak: DalpakInfra.Dalpak);
            dalpakUpdated(dalpak: DalpakInfra.Dalpak);
            dalpakDeleted(dalpak: DalpakInfra.Dalpak);
            setDalpaks(dalpaks: DalpakInfra.Dalpak[]);
            setDalpakAreas(dalpakAreas: DalpakInfra.DalpakArea[])
            onConnectionEstablished();
            dalpaksAreasChanged(dalpaks: DalpakInfra.Dalpak[])
         };

         export class DalpakSyncModule extends BaseSyncModule {
            dataNeededToRecieve = {dalpaks: false, dalpakAreas:false};
   

            constructor(private dalpaksListener: DalpakSyncClientListener, primaryPosPrimaryPosOfflineManager: PrimaryPosOfflineManager = null) {
               super(primaryPosPrimaryPosOfflineManager);
            }

            public setWebsocketEvents(websocket) {
               websocket.on('dalpaks', this.dalpaksRecieved.bind(this));
               websocket.on('dalpakAreas', this.dalpakAreasRecieved.bind(this));

               websocket.on('dalpaksAreasChanged', this.dalpaksListener.dalpaksAreasChanged.bind(this.dalpaksListener))
               websocket.on('dalpakCreated', this.dalpaksListener.dalpakCreated.bind(this.dalpaksListener));
               websocket.on('dalpakDeleted', this.dalpaksListener.dalpakDeleted.bind(this.dalpaksListener));
               websocket.on('dalpakUpdated', this.dalpaksListener.dalpakUpdated.bind(this.dalpaksListener));
               websocket.on('setDalpakAreas', this.dalpaksListener.setDalpakAreas.bind(this.dalpaksListener));
            }

            private dalpaksRecieved(dalpaks) {
               this.dalpaksListener.setDalpaks(dalpaks);
               this.dataReceived('dalpaks');
            }

            private dalpakAreasRecieved(dalpakAreas) {
               this.dalpaksListener.setDalpakAreas(dalpakAreas);
               this.dataReceived('dalpakAreas');

            }

            private dataReceived(dataType) {
               this.dataNeededToRecieve[dataType] = true;

               if (this.dataNeededToRecieve.dalpaks && this.dataNeededToRecieve.dalpakAreas) {
                  this.initialDataReceived();
               }
            }

            

            protected onConnectionEstablished() {
               super.onConnectionEstablished();
               this.dalpaksListener.onConnectionEstablished();
            }
            
         }
      }
   }
}