module PositiveTS {
    export module Components {
        export module CollapseSelect {
            export function getComponent() {
                let component = {
                    template: JST.CollapseSelect(),
                    mixins: [],
                    props: {
                        name: {
                            type: String,
                            required: true,
                        },
                        /** If false, then content will be hidden via v-show */
                        useRender: {
                            type: Boolean,
                            default: true,
                        },
                    },
                    data() {
                        return {
                            isOpen: false
                        }
                    },
                    computed: {
                        collapseIcon() {
                            return 'fa-solid fa-' + (this.isOpen ? 'chevron-up' : 'chevron-down');
                        }
                    },
                    methods: {
                        toggle(toggleTo: boolean = null) {
                            this.isOpen = typeof(toggleTo) === "boolean" ? toggleTo : !this.isOpen;
                            this.$emit(this.isOpen ? 'open' : 'close', null);
                        }
                    }
                }
                return component;
            }
        }
    }
}

