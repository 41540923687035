module PositiveTS {
  export module Components {
    export module SelectDalpakFromListDialog {
      const _vueComponentName = 'select-dalpak-from-list-dialog';

      export function create() {
        let SelectDalpakFromListDialog = {
          template: JST.selectDalpakFromListDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            async open() {
              app.showLoadingMessage('loading');
              $(document).unbind('keypress');
              this.dalpak = null;
              this.area = null;

              try {
                this.allDalpaks = await Service.Dalpak.loadAllDalpaks();
                this.allAreas = await Service.Dalpak.loadAllDalpakAreas();  
              } catch(err) {
                Service.Logger.error(err);
                app.hideLoadingMessage();
                this.close();
                return;
              }

              return new Promise((resolve, reject) => {
                this.resolve = resolve;

                app.hideLoadingMessage();

                this.$el.show();
                this.isOpen = true;
              });
            },
            cancel() {
              this.resolve(null);
              this.close();
            },
            close() {
              $(document).keypress(posVC.onKeyPress);
              this.isOpen = false;
              this.$el.close();
            },
            async finish() {
              this.resolve(this.dalpak);
              this.close();  
            },
            clearSelectedDalpak() {
              this.dalpak = null;
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {dalpaksByArea,allDalpakAreas,currentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);
            return {dalpaksByArea,dalpakAreas:allDalpakAreas,currentSelectedDalpak}
          },
          computed: {
            avialableDalpaks() {
              let dalpaks = [];

              if (this.hasAreas) {
                if (this.dalpakArea){
                  dalpaks = this.dalpaksByArea[this.dalpakArea.railsId] || [];
                }
              } else {
                dalpaks = this.dalpaksByArea[Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT] || [];
              }

              
              return dalpaks.filter(d => d.id != this.currentSelectedDalpak.id);
            },
            hasAreas() {
              return this.dalpakAreas && this.dalpakAreas.length > 0;
            },
          },
          data() {
            return {
              isRestaurantNewPOS: Service.Dalpak.isRestaurantNewPOS(),
              dalpak: null,
              dalpakArea: null,
              isOpen: false,
            }
          }
        }
        VueApp.component(_vueComponentName, SelectDalpakFromListDialog)
      }
    }
  }
}
