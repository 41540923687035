module PositiveTS {
  export module AutoSuggest {
    
    export var activate = false;
    export var results = []
    export var startTime;
    export var resultsAreUpToDate;
    export var clearQueryPosSearchTimeout;
    export var inputFromScanner = false;
    export var resultsHolderSelector = '#as-results-pos-search-field';
    export var resultsUlSelector = 'ul.as-list';
    var scrollFuncListener = function(e) { posAS.activate = true };
    
    export function retrieveComplete(itemsAndBarcodes:Array<{item: PositiveTS.Storage.Entity.Item, 
      itemBarcode:PositiveTS.Storage.Entity.ItemBarcode}>):Array<any> | Boolean {
        
        // Flag that the results are up-to-date
        posAS.resultsAreUpToDate = true;
        var searchQuery = $('#pos-search-field').val();
        
        if (searchQuery === '') {
          posAS.results = [];
          return false;
        }
        
        var data = [];
        
        for (var i = 0; i < itemsAndBarcodes.length; i++) {
          // Get the item and its barcode
          let item = itemsAndBarcodes[i].item;
          let ib = itemsAndBarcodes[i].itemBarcode;
          let displayTextTemplate = "";
          
          if (Pinia.globalStore.isRoshemet == true) {
            displayTextTemplate = `
            <div id="ref">
            <div style="display: flex; justify-content: space-between;">
            
            <div>${item.code} </div>
            <div style="display: flex; justify-content: space-between; width: 70%;">
            <div>${item.description}</div>
            
            <div>${item.priceZarhan}</div>
            </div>
            </div>
            </div>`;
            
            if(Pinia.globalStore.isRoshemet == true){
              displayTextTemplate = displayTextTemplate.replace(`<div>${item.code} </div>`,``);
            }
          }
          else {
            displayTextTemplate = `
            <div id="ref">
            <div style="display: flex; justify-content: space-between;">
            
            
            <div>
            <div style="display: inline-block; width: 200px;">${item.code} </div>
            
            <div style="display: inline-block;"><strong>ברקוד:</strong> ${ib ? ib.barcode : item.code} 
              ${(ib && (ib.color || ib.size)) ? ib.color +'/' + ib.size : ''}</div>
            </div>
            <div style="display: flex; justify-content: space-between; width: 50%;">
            <div >${posUtils.sanitizeHtml(item.description)}</div>
            
            <div>${item.priceZarhan}</div>
            </div>
            </div>
            </div>`;
          }
          
          
          // Add it to the list of suggestions
          data[data.length] = {
            id              : item.id, //TODO: need to check if I need to add the id col to the the virtual table
            item            : item,
            itemBarcode     : ib,
            displayText     : displayTextTemplate,//$(displayTextTemplate).html(),
            selectedValue   : '',
            barcode: item.code,
            value           : item.code + '&' + item.description
          };
        }

        posAS.results = data;
        
        return data;
    }
    export function resultsComplete() {
        // Activate the first result
        if(!jsonConfig.getVal(jsonConfig.KEYS.exactSearchAndBarcodeScan)) {
        $('#as-results-pos-search-field > .as-list > li:first').addClass('active');
        }
        //keep the results open if we are scrolling
        let resultsList = document.getElementsByClassName("as-list")[0];
        if (resultsList != null) {
          
          resultsList.removeEventListener('scroll',scrollFuncListener)
          resultsList.addEventListener('scroll', scrollFuncListener)            
        }
    }
    
    export function resultClick(data) {
      let kb = $('#pos-search-field').getkeyboard()
      if (kb.isVisible()) {
        kb.close();
      }
      if ((<any>window).Android) {
        let elem = document.getElementById('pos-search-field');
        elem.blur();
      }
      posVC.selectSizeColorForSaleItem(data.attributes.item, data.attributes.itemBarcode, !session.pos.isRoshemet);
    }
    /**
     * This callback is called immediately after a result was added
     * @param {Object} elem The element that was added
     */
    export function selectionAdded(elem) {
      var aThis = this;
      // Close the selection by invoking autoSuggest's callback
      $(elem).find(' > .as-close').click();
      
      // Remove active
      $('#as-results-pos-search-field > .as-list > .active').removeClass('active');
    }
    export function stop() {
      $('#pos-search-field').unbind('keyup', posAS.searchFieldChangeListener);
    }
    /**
     * Initialize autoSuggest
     */
    export function init(posVC) {
      var aThis = this;
      aThis.posVC = posVC;
      posAS.startTime = 0;
      // aThis.activate = aThis.inputFromScanner; //Boolean(session.pos.noBarcodeMode);
      
      // Flag when the results for the last key down were retrieved by autoSuggest or not
      posAS.resultsAreUpToDate = false;
      
      // Build the internal url for searching storage
      var storageSearchURL = PositiveTS.internalProtocol + 'storage/searchCatalogForItems';

      posAS.clearQueryPosSearchTimeout = _.debounce(() => {
        posAS.clearSearchField();
        PositiveTS.Components.Pos.updateLastScannedItem({});
      },20000);
      
      // Setup autoSuggest
      $('#pos-search-basic').autoSuggest(storageSearchURL, {
        // Enables you to specify your own custom ID that will be appended to the top level AutoSuggest UL element's ID name
        asHtmlID: 'pos-search-field',
        // Minimum number of characters that must be entered into the AutoSuggest input field before the search begins
        minChars: 2,
        // Make the search case sensitive when set to true
        matchCase: false,
        // Limits the number of selections that are allowed to be made to the number specified (false by default)
        selectionLimit: 1,
        // Text to display when the AutoSuggest input field is empty
        startText: '',
        // Text to display when their are no search results
        emptyText: i18next.t('autoSuggestEmptyText'),
        // Number of milliseconds to delay after a keydown on the AutoSuggest input field and before search is started (400 by default)
        keyDelay:200,
        // The name of the param that will hold the search string value in the AJAX request
        queryParam: 'q',
        // If set to true this option will never allow the 'return' key to submit the form that AutoSuggest is a part of
        neverSubmit: true,
        // Option to choose whether or not to highlight the matched text in each result item
        resultsHighlight:true,
        // Custom function that is run after the ajax request has completed. The data object MUST be returned if this is used
        retrieveComplete: aThis.retrieveComplete,
        // Custom function that is run when the suggestion results dropdown list is made visible. Will run after every search query.
        resultsComplete: aThis.resultsComplete,
        // Custom function that is run when a search result item is clicked. The data from the item that is clicked is passed into this callback function.
        resultClick: aThis.resultClick,
        // Custom function that is run when a selection is made by choosing one from the Results dropdown. The selection item is passed into this callback function
        selectionAdded: aThis.selectionAdded,
        // Name of object property to use as the display text for each chosen item.
        selectedItemProp: 'displayText',
        // Name of object property to use as the value for each chosen item. This value will be stored into the hidden input field.
        selectedValuesProp: 'selectedValue',
        // Comma separated list of object property names. The values in these objects properties will be used as the text to perform the search on.
        searchObjProps: 'value',
        // limits the search to 7 results
        beforeRetrieve: function (string) {
          posAS.startTime = PositiveTS.DateUtils.timestamp();
          return string;
        }
      });
      if(!session.isAndroid){
        // Move the foucs to the search field
        $('#pos-search-field').trigger('focus');
      }
      // Add the search icon to the search field
      if (($('#pos-search-field-search-icon').length === 0) && !jsonConfig.getVal(jsonConfig.KEYS.hideSearchBtn) && (!session.pos.isRoshemet)) {
        //$('#as-original-pos-search-field').prepend('<img id="pos-search-field-search-icon" height="23" src="' + (<any>window).images_path + 'pos/new-search.png" />');
        if(jsonConfig.getVal(jsonConfig.KEYS.itemQueryOnlyPos)){
          if($("#pos-search-field-barcode-icon").length == 0){
            $('#as-original-pos-search-field').prepend('<i id="pos-search-field-barcode-icon" class="fas fa-barcode-read" style="font-size:30px; margin-top:3px; color:black"></i>');
          }
        }else{
        $('#as-original-pos-search-field').prepend('<i id="pos-search-field-search-icon" class="fas fa-search" style="font-size:20px; margin-top:3px; color:black"></i>');
        }
        $("#pos-search-field-search-icon").unbind("click");
        $("#pos-search-field-search-icon").click(function() {
          PositiveTS.Dialogs.AdvancedItemSearchDialog.getInstance();
        });
      }
      
      if(session.pos.isRoshemet){
        $("#pos-search-field").attr("placeholder",i18next.t("pos.searchPlaceholderRoshemet"))
      }
      
      // Add a 'clear field' icon
      if ($('#pos-search-field-clear').length === 0) {
        $('#as-original-pos-search-field').append('<li id="pos-search-field-clear" height="23" class="fas fa-times-circle"></li>');
      }
      
      if ((navigator.userAgent.toLowerCase().indexOf('android') > -1 && typeof(Android) !== "undefined") &&
      (session.pos.isRoshemet)) {
        $('#pos-search-field-keyboard-icon').remove();
        $('#pos-search-field').css('width', '100%');
        
      }
      
      // Attach a click listener to the clear field icon
      $('#pos-search-field-clear').click(aThis.clearSearchField);
      
      // Listen for keypress events on the search field
      $('#pos-search-field').on('keyup', posAS.searchFieldChangeListener);
    }
    export function clearSearchField() {
      // Clear the search field
      $('#pos-search-field').val('');
      
      // Send backspace event to autoSuggest
      var e = jQuery.Event('keyup');
      e.which = 8;
      e.keyCode = 8;
      $('#pos-search-field').trigger(e);
      
      // Remove active
      $('#as-results-pos-search-field > .as-list > .active').removeClass('active');
      
      // Prevent executing more listeners
      return false;
    }
    export function searchFieldChangeListener(event) {
      // console.log('search change listener')
      // console.dir(event)
      if (session.isAndroid) {
        posAS.inputFromScanner = false
      }

      if (posAS.inputFromScanner) {
        //clear the search results
        let res = $(posAS.resultsUlSelector).html("")
        if (res[0]) {
          $(posAS.resultsHolderSelector).html(res[0].outerHTML).hide();
        }
        else {
          $(posAS.resultsHolderSelector).html("").hide();
        }
        posAS.activate = false;
        // posAS.clearSearchField();
      }
      else {
        posAS.activate = true;
        
      }
      
      // console.log(posAS.activate)
      // Is the return key was pressed?
      if (event.which != 13) {
        // --- No, the return key was not pressed
        // Flag that the results are not up-to-date
        posAS.resultsAreUpToDate = false;
        // We are done!
        return;
      }
      
      // If the results are up-to-date, let autoSuggest take care of this event
      if (posAS.resultsAreUpToDate && !jsonConfig.getVal(jsonConfig.KEYS.exactSearchAndBarcodeScan)) {
        return;
      }
      
      // Prevent default
      event.preventDefault();
      
      // --- The return key was pressed
      // Get the search query
      var searchQuery = $('#pos-search-field').val();
      
      // If the query is empty, abort
      if (searchQuery === '' || searchQuery == i18next.t("autoSuggestStartText")) {
        return;
      }
      
      // Clear the search field to abort any searches by autoSuggest

      if(jsonConfig.getVal(jsonConfig.KEYS.itemQueryOnlyPos)){
        let item = new PositiveTS.Storage.Entity.Item();
        return item.searchByBarcode(searchQuery).then(resItem => {        
          PositiveTS.Components.Pos.updateLastScannedItem(resItem[0] ? resItem[0].item : {});
          document.getElementById("pos-search-field").blur();
          clearTimeout(posAS.clearQueryPosSearchTimeout);
          posAS.clearQueryPosSearchTimeout();
        })
        
      }

      posAS.clearSearchField();
      
      if(jsonConfig.getVal(jsonConfig.KEYS.exactSearchAndBarcodeScan)) {
        $('.as-results').hide();
        posVC.addItemByBarcodeOrByCode(searchQuery, true)
        .catch(function(e){
          $(document).unbind('keypress',posVC.onKeyPress);
          $('#pos-search-field').attr('disabled',"true");
          //don't send to Logrocket/OpenReplay item not found errors
          if (e && e.message && e.message.startsWith(i18next.t("NO_ITEM_OR_BARCODE_FOUND"))) {
            console.warn(e);
          }
          else {
            PositiveTS.Service.Logger.error(e);
          }
          return app.promiseShowAlert({
            header: "",
            content: e.message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
            noHandleEnterEscape: true,
          })
          .then(function() {
            $('#pos-search-field').removeAttr('disabled');
            $(document).keypress(posVC.onKeyPress);
          })
        });
      }
      else if (jsonConfig.getVal(jsonConfig.KEYS.searchByCode) || posAS.inputFromScanner){
        posVC.addItemByBarcodeOrByCode(searchQuery,posAS.inputFromScanner)
        .catch(function(e){
          $(document).unbind('keypress',posVC.onKeyPress);
          $('#pos-search-field').attr('disabled',"true")
          return app.promiseShowAlert({
            header: "",
            content: e.message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
            noHandleEnterEscape: true,
          })
          .then(function() {
            $('#pos-search-field').removeAttr('disabled');
            $(document).keypress(posVC.onKeyPress);
          })
        });
      } else {
        posVC.addItemByBarcode(searchQuery);
      }
      
    }
  }
}


declare var posAS:typeof PositiveTS.AutoSuggest;
(<any>window).posAS = PositiveTS.AutoSuggest;
