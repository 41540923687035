module PositiveTS {
  export module Components {
    export module PosPayment {
      export module OtotView {


        export function create() {
          var c = {
            template: JST.OtotView(),
            mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
            methods: {
              selectField(fieldName) {
                let refField = null;
                //not liking this code so much... but want to make sure that number will be rounded
                this.amount = session.fixedFloat(this.amount);
                for (let field in this.fields) {
                  this.fields[field].selected = false
                  this.fields[field].inEditMode = true
                  this.fields[field].hasDecimal = false
                  if (field == fieldName) {
                    this.fields[field].selected = true
                    refField = field
                  }
                }
                if (refField != null) {
                  this.$refs[refField].focus();
                }
              },
              onKeyTapped(key) {
                let field = this.getSelectedField();
                this.keyPadHanlder(key, field, this);
              },

              getSelectedField() {
                for (let fieldName in this.fields) {
                  if (this.fields[fieldName].selected) {
                    return this.fields[fieldName]
                  }
                }
                return undefined;
              },
              clearTapped() {
                this.clearOldData()
                this.selectField('cardNumberText')
              },
              clearOldData() {
                let result = initData()
                for (let prop in result) {
                  if (prop == "amount") {
                    continue;
                  }
                  this[prop] = result[prop];
                }
              },
              async refreshView() {

                this.setCalculatedAmount();

                let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT)
                this.creditPayments = result;
                this.clearTapped()
                await PositiveTS.VueInstance.$nextTick()

                if (this.braceletNumberEmpty()) {
                  this.scanQr();
                }
              },
              async verifyAndPay() {
                try {
                  this.verify()
                  let payBySmartVoucherResult: Service.ActionResponse = await Service.VoucherPayment.payBySmartVoucherType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT, this.amount, this.cardNumberText, undefined, { saleItems: posVC.saleItems })

                  if (!posUtils.isBlank(payBySmartVoucherResult) && !payBySmartVoucherResult.success) {
                    app.promiseShowAlert({
                      header: i18next.t('error'),
                      content: payBySmartVoucherResult.error,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    })
                  }
                }
                catch (error) {
                  app.hideLoadingMessage();
                  app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: error.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }
              },
              verify() {
                if (this.braceletNumberEmpty()) {
                  throw new Error(i18next.t("scanBarcodeDialog.qrcodeIsRequired"))
                }

                if (!posUtils.isBlank(posVC.salePayments)) {
                  throw new Error(i18next.t("otot.cantPayWithOtherPayments"))
                }
              },
              useSwipe() {
                return (jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv)
              },
              braceletNumberEmpty() {
                return posUtils.isBlank(this.cardNumberText)
              },
              async checkBalance() {
                await Service.Otot.checkBalanceAndShowMessage(this.cardNumberText);
              },
              async scanQr() {
                let cardNumber = await Service.Otot.openScanBraceletDialog();

                if (cardNumber) {
                  this.cardNumberText = String(cardNumber);
                }
              }
            },
            data: initData,
            computed: {
              isMobile(){
                return Pinia.globalStore.mobileLayout
              },
            }
          }

          VueApp.component('otot-view', c)
        }





        function initFields() {
          let fields = {

            'amount': {
              selected: false,
              allowDecimal: true,
              inEditMode: false,
              type: 'number',
              disabled: false
            },
            'cardNumberText': {
              selected: false,
              inEditMode: false,
              type: 'string',
              allowDecimal: false,
              disabled: false
            },

          }
          for (let field in fields) {
            fields[field].model = field;
          }
          return fields
        }


        function initData() {
          return {
            cardNumberText: "",
            fields: initFields(),
            amount: 0,
            creditPayments: []
          }
        }
      }
    }
  }
}
