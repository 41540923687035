// This object is used as a shortened for several date functions
module PositiveTS {
	export class DateUtils {
		localDate:Date
		constructor(time?) {
			if (time == null) {
				this.localDate = new Date();
			} else {
				this.localDate = new Date(time);
			}
		}

		/* Date Parts */
	getMonthDay() {
		return ('0' + this.localDate.getDate()).slice(-2);
	};
	
	getMonth () {
		return ('0' + (this.localDate.getMonth() + 1)).slice(-2);
	};
	
	getFullYear () {
		return this.localDate.getFullYear();
	};
	
	getHours () {
		return ('0' + this.localDate.getHours()).slice(-2);
	};
	
	getMinutes () {
		return ('0' + this.localDate.getMinutes()).slice(-2);
	};
	
	getSeconds () {
		return ('0' + this.localDate.getSeconds()).slice(-2);
	};
	
	timestamp () {
		return this.localDate.getTime();
	};
	
	/* Date Formats */
	fullFormat () {
		return this.getMonthDay() + '/' + this.getMonth() + '/' + this.getFullYear() + ' ' + this.getHours() + ':' + this.getMinutes() + ':' + this.getSeconds();
	};
	
	longFormat() {
		return this.getMonthDay() + '/' + this.getMonth() + '/' + this.getFullYear() + ' ' + this.getHours() + ':' + this.getMinutes();
	};
	
	shortFormat() {
		return this.getMonthDay() + '/' + this.getMonth() + '/' + this.getFullYear();
	};

	static fullFormat(time?) {
		return (new PositiveTS.DateUtils(time)).fullFormat();
	};
	
	static longFormat(time?) {
		return (new PositiveTS.DateUtils(time)).longFormat();
	};
	
	static shortFormat(time?) {
		return (new PositiveTS.DateUtils(time)).shortFormat();
	};
	
	static timestamp(time?) {
		return (new PositiveTS.DateUtils(time)).timestamp();
	};
	
	static toDateFormat (value) {	
		var dt = value;
		var da = dt.split('/');
		for (var a = 0; a < da.length; a++) {if (da[a] != +da[a]) da[a] = da[a].substr(0,da[a].length-1);}
		if (da[1] > 12) {da[2] = da[1].substr(da[1].length-1,1);da[1] = '0'+da[1].substr(0,da[1].length-1);}
		if (da[2] > 9999) da[1] = da[2].substr(0,da[2].length-1);
		dt = da.join('/');
		if (dt.length == 2) dt += '/';
		return dt;
	};
}}

