module PositiveTS {
export module Service {
export module ShipmentInv {

  export async function createShipmentInvIfRequired(fullSale:FullSale):Promise<FullSale> {
    if (!isCurrentSaleShipmentInv(fullSale)) {
      throw new Error("not Shipment Invoice"); //TOOD: I don't think this validation is needed
    }
    let validation = _validateSaleComplete(fullSale)
    if (!validation.valid) {
      throw new Error( i18next.t(validation.message) )
    }

    let fullSaleBackup = fullSale.clone()
    try {
      let updatedFullSale = createDocument(fullSale)
      
      await updatedFullSale.IncrementSeqAndPersist()
      let saleToPrint = updatedFullSale.clone();

      await Service.CloseSale.afterSaleClosedActions(updatedFullSale);

      await printDocuments(saleToPrint);
      
      return updatedFullSale;
    }
    catch(e) {
      restore(fullSaleBackup);
      throw e;
    }

  }

  function createNewEmptyTamash(mainSale:FullSale, tamashSequence: Storage.Entity.Sequence,
    mainSequence:Storage.Entity.Sequence):FullSale {
    let sale = posVC.initNewSaleFields();
    
    
    let mainJd = JSON.parse(mainSale.sale.jsondata)
    mainJd.isSplitSale = true;
    mainJd.splitSaleNumber = tamashSequence.sequence+1;

    let jsondata:any = {};
    jsondata.customer = mainJd.customer;
    jsondata.isSplitSale = true;
    jsondata.splitSaleNumber = mainSequence.sequence+1;

    if(mainJd.delivery){
      jsondata.splitSaleDelivery = mainJd.delivery;
      jsondata.delivery = mainJd.delivery;

    }


    sale.jsondata = JSON.stringify( jsondata );
    mainSale.sale.jsondata = JSON.stringify(mainJd);

    return new FullSale(sale,[],[])
  }

  async function fixSplitSaleItems(mainSale:FullSale,tamashSale:FullSale, tamashSequence: Storage.Entity.Sequence,
                                   mainSequence:Storage.Entity.Sequence) {
    let itemCode = Shared.Constants.Item.GENERIC_ITEM

    let itemsAndBarcodes = await  PositiveTS.Storage.Entity.Item.searchByCode(Shared.Constants.Item.GENERIC_ITEM)
    
    if (itemsAndBarcodes.length !== 1) {
      throw new Error(`Bad POS configuration, no itemCode ${itemCode}`)
    }

    // Pick the first item and get the item and its barcode
    let item:PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
    item.noDiscount = true //prevent a bug that this item fucks up the total amount in case of sale discount.
    let itemBarcode = {};

    // Create new sale item entity
    let tamashSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
    tamashSaleItem.saleID = tamashSale.sale.id;
    tamashSaleItem.rowNumber = 0;
    tamashSaleItem.quantity = 1;
    tamashSaleItem.unitPrice = tamashSale.salePayments[0].amount;
    tamashSaleItem.priceNetoAfterDiscounts  =   tamashSaleItem.unitPrice;
    tamashSaleItem.originalUnitPrice = tamashSaleItem.unitPrice;
    tamashSaleItem.itemDescription = `${i18next.t('shipmentInv.splitPaymentForInvoice')}   ${mainSequence.sequence+1}`

    tamashSale.saleItems.push(tamashSaleItem)

    let mainSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
    mainSaleItem.saleID = mainSale.sale.id;
    mainSaleItem.rowNumber = posVC.getRowNumber();
    mainSaleItem.quantity = 1;
    mainSaleItem.unitPrice = -tamashSale.salePayments[0].amount;
    mainSaleItem.originalUnitPrice = mainSaleItem.unitPrice 
    mainSaleItem.priceNetoAfterDiscounts  =   mainSaleItem.unitPrice;

    mainSaleItem.itemDescription = `${i18next.t('shipmentInv.minusBecauseSplitShipmentPayment')} ${tamashSequence.sequence+1}`

    mainSale.saleItems.push(mainSaleItem)

  }

  function fixSplitSaleCustomers(mainSale:FullSale, tamashSale:FullSale, hasHakafaPayment:boolean, hasCibusTenBis:boolean) {
    if (hasCibusTenBis && hasHakafaPayment) {
      let jd = JSON.parse(mainSale.sale.jsondata) 
      let tamashJd = JSON.parse(tamashSale.sale.jsondata);
      tamashJd.additionalCustomer = jd.customer;
      tamashSale.sale.jsondata = JSON.stringify(tamashJd)
      VoucherPayment.addDummyHakafaCustomerIfCibusOrTenbis(tamashSale.sale, tamashSale.salePayments);
    }
    else if (hasHakafaPayment) {
      let jd = JSON.parse(mainSale.sale.jsondata) 
      let tamashJd = JSON.parse(tamashSale.sale.jsondata);
      tamashJd.customer = jd.customer;
      tamashSale.sale.jsondata = JSON.stringify(tamashJd)
    }
    else {
      VoucherPayment.addDummyHakafaCustomerIfCibusOrTenbis(tamashSale.sale, tamashSale.salePayments);      
    }
  }

  function fixSplitSalePayments(mainSale:FullSale, tamashSale:FullSale) {
    let mainSaleVoucherPayment = mainSale.salePayments.filter(payment => payment.method == Storage.Entity.SalePayment.METHOD_VOUCHER)[0]

    if (mainSaleVoucherPayment == null) {
      throw new Error("שגיאה קריטית - חסרים תשלומים לתעודת משלוח - יש לפנות לתמיכה")
    }

    
    let tamashSalePayment = new PositiveTS.Storage.Entity.SalePayment();
    tamashSalePayment.saleID = tamashSale.sale.id;
    tamashSalePayment.amount = 0;
    tamashSalePayment.method = Storage.Entity.SalePayment.METHOD_VOUCHER;
    let tamashPaymentData = []
    let hasHakafaPayment = false
    let hasCibusTenBis = false


    let data = JSON.parse(mainSaleVoucherPayment.data)
    data = data.filter(voucherData => {
      if (voucherData.voucher_type_id == Service.Hakafa.VOUCHER_TYPE_ID) {
        if (voucherData.isTamashCustomer) {
          mainSaleVoucherPayment.amount -= voucherData.amount
          tamashSalePayment.amount += voucherData.amount
          tamashPaymentData.push(voucherData)
          hasHakafaPayment = true
          return false;
        }
      }
      if (voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE ||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS ||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS||
          (voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI && voucherData.isTaxInvoice == false) ||
          voucherData.voucherType == PositiveTS.Storage.Entity.Voucher.VOUCHER_MISHLOHA || voucherData.voucherType == PositiveTS.Storage.Entity.Voucher.DYNAMIC_DELIVERY_API
        ) {
        mainSaleVoucherPayment.amount -= voucherData.amount
        tamashSalePayment.amount += voucherData.amount
        tamashPaymentData.push(voucherData)
        hasCibusTenBis = true
        return false;
      }

      return true;
    })

    tamashSalePayment.data = JSON.stringify(tamashPaymentData)
    mainSaleVoucherPayment.data = JSON.stringify(data)
    tamashSale.salePayments.push(tamashSalePayment)
    fixSplitSaleCustomers(mainSale,tamashSale,hasHakafaPayment,hasCibusTenBis)

  }

  export async function createShipmentInvForSplit(mainSale:FullSale, 
                          tamashSequence:Storage.Entity.Sequence, mainSeq:Storage.Entity.Sequence):Promise<FullSale> {
    
    const splitInfo = PositiveTS.Service.CloseSale._getSplitSaleInfo(mainSale.sale, mainSale.saleItems, mainSale.salePayments)
    const mainSaleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(mainSale.sale, mainSale.saleItems, mainSale.salePayments)
    let tamashSale = createNewEmptyTamash(mainSale,tamashSequence,mainSeq)
      fixSplitSalePayments(mainSale, tamashSale);
    await fixSplitSaleItems(mainSale,tamashSale,tamashSequence,mainSeq)

    tamashSale.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV;
    tamashSale.sale.totalAmount = tamashSale.salePayments[0].amount
    if (!session.store.containVat) {
      if (mainSaleTotals.totalVatableAmount == mainSaleTotals.totalAmount) {
        tamashSale.sale.totalVatableAmount = tamashSale.sale.totalAmount
      } else {
        tamashSale.sale.totalVatableAmount = mainSaleTotals.totalVatableAmount * splitInfo.tamashPct
      }
    }


    tamashSale.sale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
    tamashSale.sale.createdAt = PositiveTS.DateUtils.fullFormat();
    tamashSale.sale.totalQuantity = "1";
    tamashSale.sale.totalDiscount = 0

    let mainJd = JSON.parse(mainSale.sale.jsondata);
    mainJd.splitSaleAmount = tamashSale.sale.totalAmount;
    mainSale.sale.jsondata = JSON.stringify(mainJd);
    if (!session.store.containVat) {
      if (mainSaleTotals.totalVatableAmount == mainSaleTotals.totalAmount) {
        mainSale.sale.totalVatableAmount = mainSale.sale.totalAmount
      } else {
        mainSale.sale.totalVatableAmount = mainSaleTotals.totalVatableAmount * splitInfo.standardInvPct
      }
    }

    if (session.store.containVat) {
      tamashSale.sale.totalVatableAmount = 0;
      mainSale.sale.totalVatableAmount = 0;
    }
  
    return tamashSale;
  }


  export function isCurrentSaleShipmentInv(fullSale): boolean {

    let voucherSalePayment = Helper.SaleHelper.findPaymentByMethod(fullSale.salePayments, Storage.Entity.SalePayment.METHOD_VOUCHER);

    if (!voucherSalePayment) {
      return false; 
    }

    let vouchers = JSON.parse(voucherSalePayment.data);
    for (let voucherData of vouchers) {
      if ( [PositiveTS.Service.Hakafa.VOUCHER_TYPE_ID,
            VALU_CARD_VOUCHER_TYPE_ID].indexOf( voucherData.voucher_type_id ) > -1  && voucherData.isTamashCustomer) {
        return true;
			}
      
      if (voucherData.smartVoucherType == Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT && voucherData.isTamashCustomer){
        return true;
      }

      if (voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE ||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS ||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS||
          (voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI && voucherData.isTaxInvoice == false) ||
          voucherData.smartVoucherType == PositiveTS.Storage.Entity.Voucher.VOUCHER_MISHLOHA) {
        return true;
      }
    }
    return false;
  }

  export function createDocument(fullSale:FullSale):FullSale {
    
    if (fullSale.salePayments.length === 0) {
      return fullSale;
    }

    let calcuatedSaleTotals = Helper.SaleHelper.calcuateSaleTotals(fullSale.sale, fullSale.saleItems, fullSale.salePayments)
    fullSale.sale.invoiceType = Storage.Entity.Sequence.TYPE_SHIPMENT_INV;
    fullSale.sale.totalAmount = calcuatedSaleTotals.totalPaid
    fullSale.sale.syncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
    fullSale.sale.createdAt = PositiveTS.DateUtils.fullFormat();
    fullSale.sale.totalQuantity = String(calcuatedSaleTotals.totalQuantity);
    fullSale.sale.totalDiscount = calcuatedSaleTotals.totalSaleDiscount;
    fullSale.sale.totalVatableAmount = calcuatedSaleTotals.totalVatableAmount;

    if ( fullSale.sale.totalAmount < 0 ){
      if ( JSON.parse(fullSale.sale.jsondata).customer.is_tamash_customer)
      {
        fullSale.sale.invoiceType = Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV;
        fullSale.sale.parentSaleInvoiceSequence = null;
      } else {
        fullSale.sale.invoiceType = Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV;
        fullSale.sale.parentSaleInvoiceSequence = null;
      }
    }

    return fullSale;
  }

  
  async function printDocuments(fullSale: FullSale): Promise<void> {
    const doNotPrintOriginalInvoice = jsonConfig.getVal(jsonConfig.KEYS.doNotPrintOriginalInvoice)
    if (doNotPrintOriginalInvoice) {
      return;
    }
    if (Pinia.languageManagerStore.multiLangEnabled) {
      let currentLang = Pinia.languageManagerStore.currentLang;
      fullSale.saleItems = PositiveTS.Service.Translation.formatSaleItems({ saleItems: fullSale.saleItems, lang: currentLang })
    }
    await Printing.Invoice.printInvoice(fullSale.sale, fullSale.saleItems, fullSale.salePayments, true)
    if (jsonConfig.getVal(jsonConfig.KEYS.hakafaShipmentInvPrintCopyWithOriginal) || jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)) {
      return Printing.Invoice.printInvoice(fullSale.sale, fullSale.saleItems, fullSale.salePayments, false)
    }
  }

  function _validateSaleComplete(fullSale):any{
    var stateTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(fullSale.sale, fullSale.saleItems, fullSale.salePayments);
    return PositiveTS.Helper.SaleHelper.validateSaleTotals(stateTotals);
  }

  function restore(fullSale:FullSale):void {
    posVC.sale = fullSale.sale;
    posVC.saleItems = fullSale.saleItems;
    posVC.salePayments = fullSale.salePayments;
  }

}}}
