module PositiveTS {
	export module Service {
		export module Sale {

			export function SearchSales(searchObject):Promise<Array<any>> {

				return new Promise((resolve,reject) => {
					$.ajax({
						type: "GET",
						url: Shared.Constants.remoteRoot + 'sales',
						data: searchObject,
						dataType: 'json',
						success: function (returnedData) {
							resolve(returnedData);
						}
					}).error(function (err) {
						reject(err);
					});
				})


			};

			export function getSaleFromServer(store_id, pos_device_id, invoice_number, invoice_type) {

				return new Promise((resolve, reject) => {
					$.ajax({
						type: "GET",
						url: Shared.Constants.remoteRoot + 'sales/' + invoice_number,
						data: {
							store_id: store_id,
							pos_device_id: pos_device_id,
							invoice_type: invoice_type
						},
						dataType: 'json',
						success: function (returnedData) {
							resolve(returnedData);
						}
					}).error(function (err) {
						reject(err);
					});
				})

			};

			export async function GetSalesWithoutZReport(posDeviceId, date = null) {

				if (posDeviceId == null) {
					return false;
				}
				let from_date = date == null ? `` : `&from_date=${date}` ;
				let response = await FetchReq.jsonReq(`${Shared.Constants.remoteRoot}sales/sales_without_z?pos_device_id=${posDeviceId}` + from_date, 'GET')
				return response.result.map(sale => Service.Sale.ToStorageTypeSale(sale))
			};

			export function getNewOrder(orderNumber,orderTotal,invoiceType?){
					var newSale = new Storage.Entity.Sale();
					newSale.tenantID = session.pos.tenantID;
					newSale.companyID = session.pos.companyID;
					newSale.storeID = session.pos.storeID;
					newSale.storeName =  session.store.storeName;
					newSale.storeAddress =  session.store.address;
					newSale.storeFreeText = session.store.freeText;
					newSale.storeRegistrationNum =  session.store.registrationNum;
					newSale.storePhone =  session.store.phone;
					newSale.posPhysicalID = session.pos.physicalID;
					newSale.posDeviceID = session.pos.deviceID;
					newSale.posNumber = String(session.pos.posNumber);
					newSale.invoiceSequence = Storage.Entity.Sale.NULL_INVOICE_SEQUENCE; 
					newSale.invoiceType = invoiceType;
					newSale.cashierEmployeeID = session.pos.employeeID;
					newSale.cashierEmployeeName = session.pos.employeeName;
					newSale.createdAt = DateUtils.fullFormat();
					newSale.soldAt = DateUtils.fullFormat();
					newSale.discountID = '-1';
					newSale.discountName = '';
					newSale.discountPercent = '-1';
					newSale.discountApprovedByEmployeeID = '-1';
					newSale.customerSeqID = '-1';
					newSale.customerID = '-1';
					newSale.customerPhone = '';
					newSale.customerName = '';
					newSale.syncStatus = Storage.Entity.Sale.SYNC_STATUS_NEW;
					newSale.syncLastMessage = '';
					newSale.syncLastMessageTimestamp = DateUtils.fullFormat();
					newSale.salespersonEmployeeID = '';
					newSale.salespersonEmployeeName = '';
					newSale.zNumber = Storage.Entity.Sale.NULL_Z_REPORT;
					newSale.salespersonEmployeeID = Shared.Constants.Employee.GENERIC_EMPLOYEE;
					newSale.isDelivery = true;
					newSale.totalVatableAmount = session.store.containVat ? 0 : orderTotal;
				
					let jsondata:any = {}
					  
					jsondata.separateItemLines = Boolean(session.pos.separateItemLinesOn);
					jsondata.useNewPromotions = Boolean(session.pos.useNewPromotions);
					newSale.jsondata = JSON.stringify( jsondata );
					newSale.vat = session.systemSettings.vat;
					newSale.isInHold = false;
			  
					newSale.isDelivery = true;
					newSale.orderNumber = orderNumber;  
					newSale.totalAmount = orderTotal;
			  
					return newSale;
			}

			export function ToStorageTypeSale(sale) {
				var saleData = JSON.parse(sale.data);

				var entity = new PositiveTS.Storage.Entity.Sale();

				entity.tenantID = saleData.sale.tenant_id;
				entity.companyID = saleData.sale.company_id;
				entity.storeID = saleData.sale.store_id;
				entity.storeName = saleData.sale.store_name;
				entity.storeAddress = saleData.sale.store_address;
				entity.orderNumber = saleData.sale.orderNumber;
				entity.isDelivery = saleData.sale.is_delivery;
				entity.deliveryStatus = saleData.sale.delivery_status;
				entity.orderTime = saleData.sale.order_time;
				entity.storeFreeText = saleData.sale.store_free_text;
				entity.storeRegistrationNum = saleData.sale.store_registration_num;
				entity.storePhone = saleData.sale.store_phone;
				entity.posPhysicalID = saleData.sale.physical_pos_id;
				entity.posDeviceID = saleData.sale.pos_id;
				entity.posNumber = saleData.sale.pos_number;
				entity.invoiceSequence = saleData.sale.invoice_number;
				entity.invoiceType = saleData.sale.invoice_type;
				entity.diners = saleData.sale.diners

				entity.parentSaleCompanyID = saleData.sale.parent_invoice_company_id;
				entity.parentSaleStoreID = saleData.sale.parent_invoice_store_id;
				entity.parentSalePosDeviceID = saleData.sale.parent_invoice_pos_device_id;
				entity.parentSalePosNumber = saleData.sale.parent_invoice_pos_number;
				entity.parentSaleInvoiceSequence = saleData.sale.parent_invoice_number;
				entity.parentSaleInvoiceType = saleData.sale.parent_invoice_type;
				entity.parentSaleDelivery = saleData.sale.parent_sale_delivery;

				entity.totalAmount = saleData.sale.total_amount;
				entity.totalVatableAmount = saleData.sale.total_vatable_amount != null ? saleData.sale.total_vatable_amount : saleData.sale.total_amount;
				entity.totalQuantity = saleData.sale.total_quantity;
				entity.totalDiscount = saleData.sale.total_discount;

				entity.cashierEmployeeID = saleData.sale.cashier_id;
				entity.cashierEmployeeName = saleData.sale.cashier_name;
				entity.createdAt = saleData.sale.timestamp;
				entity.discountID = saleData.sale.discount_id;
				entity.discountName = saleData.sale.discount_name;
				entity.discountPercent = saleData.sale.discount_percent;
				entity.discountApprovedByEmployeeID = saleData.sale.discount_approver_emp_id;
				entity.promotionCode = saleData.sale.promotion_code;
				entity.saleDiscountAmount = saleData.sale.sale_discount_amount || 0;
				entity.saleDiscountAllowedWithOtherPromotions = saleData.sale.sale_discount_allowed_with_other_promotions;
				entity.discountType = saleData.sale.discount_type;
				entity.customerSeqID = saleData.sale.cust_seq_Id;
				entity.customerID = saleData.sale.cust_id;
				entity.customerPhone = saleData.sale.cust_phone;
				entity.customerName = saleData.sale.cust_name;
				entity.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY;
				entity.syncLastMessage = "";
				entity.syncLastMessageTimestamp = "";
				entity.salespersonEmployeeID = saleData.sale.salesperson_employe_id;
				entity.salespersonEmployeeName = saleData.sale.salesperson_employee_name;
				entity.vat = saleData.sale.vat;
				entity.jsondata = sale.jsondata;
				entity.forceAllowCredit = sale.force_allow_credit;
				entity.soldAt = sale.sold_at;
				entity.isInHold = false;
				entity.roundAmount = saleData.sale.roundAmount;
				entity.dalpakId = saleData.sale.dalpak_id;
				entity.saleRemarks = saleData.sale.sale_remarks;



				(<any>entity).items = [];

				for (var i = 0; i < saleData.items.length; i++) {
					var saleItem = saleData.items[i];

					var entityItem = new PositiveTS.Storage.Entity.SaleItem();

					entityItem.saleID = entity.id;
					entityItem.rowNumber = saleItem.row_number;
					entityItem.barcode = saleItem.barcode || saleItem.item_code;
					entityItem.itemCode = saleItem.item_code;
					entityItem.originalItemId = saleItem.id;
					entityItem.itemDescription = saleItem.item_desc;
					entityItem.size = saleItem.size;
					entityItem.color = saleItem.color;
					entityItem.quantity = saleItem.quantity;
					entityItem.unitPrice = saleItem.unit_price;
					entityItem.originalUnitPrice = saleItem.original_unit_price;
					entityItem.isPresent = saleItem.is_present;
					entityItem.isPickup = saleItem.is_pickup;
					entityItem.discountID = saleItem.discount_id;
					entityItem.discountName = saleItem.discount_name;
					entityItem.discountPercent = saleItem.discount_percent;
					entityItem.discountAmount = saleItem.discount_amount;
					entityItem.discountType = saleItem.discount_type;
					entityItem.buyPromotionCode = saleItem.buy_promotion_code;
					entityItem.getPromotionCode = saleItem.get_promotion_code;
					entityItem.discountApprovedByEmployeeID = saleItem.discount_approver_emp_id;
					entityItem.salespersonEmployeeID = saleItem.salesperson_employee_id;
					entityItem.salespersonEmployeeName = saleItem.salesperson_employee_name;
					//entityItem.item 													= JSON.stringify(saleItem);
					entityItem.originalItemId = (saleData.sale.invoice_type == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) ? saleItem.originalItemId : saleItem.id;
					entityItem.priceListId = saleItem.price_list_id;
					entityItem.noVat = saleItem.no_vat;
					entityItem.alwaysHasVat = saleItem.always_has_vat;
					entityItem.priceAlut = saleItem.price_alut;
					entityItem.hasWeight = saleItem.has_weight;
					entityItem.isAbstract = saleItem.is_abstract;
					entityItem.isAddition = saleItem.is_addition;
					entityItem.isDeposit = saleItem.isDeposit;
					entityItem.hasGroups = saleItem.has_groups || false;
					entityItem.noDiscount = saleItem.no_discount || false;
					entityItem.hasPreparationInstructions = saleItem.has_preparation_instructions || false;
					entityItem.selectedPreparationInstructions = saleItem.selected_preparation_instructions || "[]";
					entityItem.level = saleItem.level || 0;
					entityItem.parentItemId = saleItem.parent_item_id || -1;
					entityItem.parentRowNumber = saleItem.parent_row_number == null ? -1 : saleItem.parent_row_number;
					entityItem.salePercentAddition = saleItem.salePercentAddition;
					entityItem.bonPrintedAt = saleItem.bonPrintedAt;
					entityItem.priceNetoAfterDiscounts = saleItem.priceNetoAfterDiscounts;
					entityItem.promotions = saleItem.promotions;
					entityItem.multipassDiscount = saleItem.multipassDiscount || 0;
					entityItem.kasparDiscount = saleItem.kasparDiscount || 0;
					entityItem.timeItemVersion = saleItem.time_item_version;
					entityItem.elalTicketNumber = saleItem.elalTicketNumber;
					entityItem.elalTicketType = saleItem.elalTicketType;
					entityItem.elalRecId = saleItem.elalRecId;
					entityItem.elalServiceCD = saleItem.elalServiceCD;
					entityItem.elalPNR = saleItem.elalPNR;
					entityItem.startTimestamp = saleItem.start_timestamp;
					entityItem.endTimestamp = saleItem.end_timestamp;

					(<any>entity).items.push(entityItem);
				};

				(<any>entity).payments = [];

				for (var i = 0; i < saleData.payments.length; i++) {
					var paymentItem = saleData.payments[i];

					var entityPayment = new PositiveTS.Storage.Entity.SalePayment();

					entityPayment.saleID = entity.id;
					entityPayment.method = paymentItem.method;
					entityPayment.amount = paymentItem.amount;
					entityPayment.data = paymentItem.data;

					(<any>entity).payments.push(entityPayment);
				};

				if (sale.sale_credits !== null && sale.sale_credits !== undefined && sale.sale_credits.length > 0) {
					(<any>entity).childrens = [];
					for (var i = 0; i < sale.sale_credits.length; i++) {
						(<any>entity).childrens.push(Service.Sale.ToStorageTypeSale(sale.sale_credits[i]));
					};
				}

				return entity;
			}

			export async function deleteOldSales() {
				let filterFunction = sale => sale.timestamp < moment().subtract(parseInt(jsonConfig.getVal(jsonConfig.KEYS.hoursToSaveSales)), 'h').toDate().getTime() &&
													  sale.syncStatus == PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY;

				await appDB.sales.filter(filterFunction).delete();
			}

			export function initLiveQuerySaleChangesSubscribes() {
                initSubscribeTotalOfflineSales();
            }

            export function initSubscribeTotalOfflineSales() {

                const monitorTotalOfflineSales = () => Dexie.liveQuery(() =>
                    appDB.keyValueStore.where('key').equals(PositiveTS.Shared.Constants.SaleSync.totalOfflineSales).first()
                );

                monitorTotalOfflineSales().subscribe(totalOfflinesSales => {
                    if (totalOfflinesSales) {
                        if (totalOfflinesSales.value >= 0) {
                            Pinia.globalStore.OfflineSalesChanged(totalOfflinesSales.value)
                        }
                    }
                });
            }
		}
	}
}


