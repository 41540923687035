module PositiveTS {
  export module Components {
    export module FinishTimeItemDialog {
      const _vueComponentName = 'finish-time-item-dialog';

      export function create() {
        let FinishTimeItemDialog = {
          template: JST.finishTimeItemDialog(),
          methods: {
            open(saleItem: Storage.Entity.SaleItem) {
              $(document).unbind('keypress');
              this.saleItem = saleItem;
              this.isEditMode = false;

              return new Promise(res => {
                this.resolve = res;
                this.$el.showModal();
              })
            },
            close(returnSaleItem = false) {
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();

              let returnValue = null;

              if (returnSaleItem) {
                returnValue = this.saleItem;
              }

              this.resolve(returnValue);
              this.resolve = null;
              this.saleItem = null;
            },
            showError(errorText) {
              return app.showAlertDialog({
                header: i18next.t('error'),
                content: errorText,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            },
            getDateTimeInFormat(timestamp, format) {
              return moment(timestamp).format(format);
            },
            setDate(saleItemAttribute, value) {
              let newVal = moment(value);

              this.saleItem[saleItemAttribute] = moment(this.saleItem[saleItemAttribute]).set({
                year: newVal.get('year'),
                month: newVal.get('month'),
                date: newVal.get('date'),
              }).valueOf()
            },
            setTime(saleItemAttribute, timeAttribute, value) {
              this.saleItem[saleItemAttribute] = moment(this.saleItem[saleItemAttribute]).set(timeAttribute, value).valueOf()
            },
            toFixed(number, percision) {
              return session.fixedFloat(number, percision);
            },
            async edit() {
              this.$el.close();

              try {
                await app.showManagerApprovalDialog();
                this.isEditMode = true;
              } catch (err) {

              }

              this.$el.showModal();
            },
            getRateTranslateData(detail) {
              return {
								rate: detail.rate,
								rateTime: detail.rateTime,
								currency: i18next.t(`currencies.${this.currency}`)
							}
            }
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {mobileLayout,currency} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {mobileLayout,currency}

            return {...globalStoreProps}
          },
          computed: {
            startDate: {
              get() {
                return new Date(this.saleItem.startTimestamp);
              },
              set(val) {
                this.setDate('startTimestamp', val)
              }
            },
            startHour: {
              get() {
                return this.getDateTimeInFormat(this.saleItem.startTimestamp, 'HH')
              },
              set(val) {
                this.setTime('startTimestamp', 'hour', val);
              }
            },
            startMinute: {
              get() {
                return this.getDateTimeInFormat(this.saleItem.startTimestamp, 'mm')
              },
              set(val) {
                this.setTime('startTimestamp', 'minute', val);
              }
            },
            endDate: {
              get() {
                return new Date(this.saleItem.endTimestamp);
              },
              set(val) {
                this.setDate('endTimestamp', val)
              }
            },
            endHour: {
              get() {
                return this.getDateTimeInFormat(this.saleItem.endTimestamp, 'HH')
              },
              set(val) {
                this.setTime('endTimestamp', 'hour', val);
              }
            },
            endMinute: {
              get() {
                return this.getDateTimeInFormat(this.saleItem.endTimestamp, 'mm')
              },
              set(val) {
                this.setTime('endTimestamp', 'minute', val);
              }
            },
            totalTime() {
              let timeInMinutes = Service.TimeItem.totalMinutes(this.saleItem);

              return Math.floor(timeInMinutes / 60) + ':' + String(timeInMinutes % 60).padStart(2, '0');
            },
            totalPriceData() {
              return Service.TimeItem.getTotalPriceData(this.saleItem);
            },
          },
          data() {
            return {
              resolve: null,
              saleItem: null,
              isEditMode: false,
              dateFormat: 'dd/MM/yyyy',
              minutesRange: Array(60).fill(0).map((x,i)=> String(i).padStart(2,"0")),
              hoursRange: Array(24).fill(0).map((x,i)=> String(i).padStart(2,"0")),  
            }
          },
        }
        VueApp.component(_vueComponentName, FinishTimeItemDialog)
      }
    }
  }
}
