module PositiveTS {
export module Components {
export module GenericItemDescription {
  const _dialogSelector = "#generic-item-description-dialog"

  export function create() {
    let vueObj = {
        template: JST.genericItemDescription(),
        methods: {
          open: _open,
          close: _close,
          cancel: _cancel,
          validate: _validate,
          selectField: _selectField,
        },
        data: _initData,
        computed:{isValid: _validate}

    }
    VueApp.component('generic-item-description',vueObj)
  }

  function _selectField(fieldName) {
    this.$refs[fieldName].focus();
    this.$refs[fieldName].select();
  }

  function _open(saleItem, callback, callbackReject, showCancel = true) {
    this.itemPrice = saleItem.unitPrice;
    this.itemDescription = saleItem.itemDescription;
    this.showCancel = showCancel
    setTimeout(() => {
      this.$refs.genericItemDescriptionItemPrice.select();
    },0)
    this._callback = callback;
    this._callbackReject = callbackReject;
    this._allowZeroPrice = saleItem.item.allowZeroPrice;

    $(document).unbind('keypress');
    if (!$(_dialogSelector).hasClass('ui-dialog-content')) {
      $(function() {
        $(_dialogSelector).dialog({
          autoOpen: true,
          modal: true,
          dialogClass: 'positive-dialog',
          width: '80%',
          height: 270,
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });
      });

      $(this.$refs.genericItemDescriptionItemPrice).focus();
    }


    this.validate();
    $(_dialogSelector).dialog('open');


  }


  function _initData() {
    return {
      _dialogSelector: _dialogSelector,
      itemPrice: 0,
      itemDescription: "",
      _saleItem: undefined,
      _callback: function(){},
      _callbackReject: function(){},
      _allowZeroPrice: false,
      errorMessage: "",
      showCancel: true,
    };
  }


  function _cancel() {
    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
    this._callbackReject(app.userCancelledGlobalMessage);
  }

  function _close() {
    if (!this.validate()){return;}

    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
    this._callback({itemPrice:this.itemPrice, itemDescription: this.itemDescription});
  }


  function _validate() {
    if (!session.isNumber(this.itemPrice) || Number(this.itemPrice) < 0) {
      this.errorMessage  = 'חובה להזין מחיר';
      return false;
    }

    if (Number(this.itemPrice) === 0 && !this._allowZeroPrice) {
      this.errorMessage  = 'חובה להזין מחיר גדול מ-אפס';
      return false;
    }



    if (!this.itemDescription) {
      this.errorMessage = 'חובה להזין תאור פריט';
      return false;
    }

    if (this.itemDescription.length > 254) {
      this.errorMessage = 'תאור הפריט ארוך מידי';
      return false;
    }
    this.errorMessage = "";
    return true;
  }

}}}
