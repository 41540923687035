module PositiveTS {
   export module Service {
      export module WebsocketSync {
         export class OutOfStockSyncModule extends BaseSyncModule {
            outOfStockData = null;
            constructor() {
               super();
               this.outOfStockData =null;
            }

            public setWebsocketEvents(websocket) {
               websocket.on('outOfStockData', this.outOfStockDataRecieve.bind(this));
            }

            private async outOfStockDataRecieve(outOfStockData) {
               if(posUtils.isArray(outOfStockData)){
                  this.outOfStockData = outOfStockData[0];
                  PositiveTS.Service.OutOfStock.reLoadOutOfStockItemsCodes()
               } 
            }

            async sendOutOfStockUpdatedData(data: any): Promise<any> {
               let result = await this.getSocket().validateConnectionAndDo(async (): Promise<any> => {
                  return await this.getSocket().makeActionAndWaitForResult('updateOutOfStock', data);                  
               });
               console.log(result);
               if (!result.success && !result.errorMessage) {
                  result.errorMessage = i18next.t('dalpaks.errorConnectingToDalpakServer');
               }

               return result;
            }
            public setInitialConnectionDataAndConnect() {
               // Action not needed here
               return;
            }

            public async waitForInitialData() {
               // Action not needed here
               return;
            }            
         }
      }
   }
}
