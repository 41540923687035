module PositiveTS {
  export module Service {
    export module TimeItem {
      export async function openTimeItemDialog(saleItem: Storage.Entity.SaleItem, finishTimeItem = true) {
        let saleItemToChange = saleItem.clone();

        if (finishTimeItem) {
          saleItemToChange.endTimestamp = getNowWithoutSeconds();

          if (!posUtils.isBlank(saleItem.item.messageOnTimeEnd)) {
            await app.promiseShowAlert({
              header: i18next.t('payAttention'),
              content: saleItem.item.messageOnTimeEnd,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            })
          }
        }

        let updatedSaleItem = await Pinia.componentsStore.openComponent( {componentName:"finishTimeItemDialog", args: [saleItemToChange]});

        if (updatedSaleItem) {
          let priceData = getTotalPriceData(updatedSaleItem);
          updatedSaleItem.unitPrice = priceData.price;
          posVC.persistSaleItem(updatedSaleItem);
          Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(false);
        }
      }

      export function totalMinutes(saleItem: Storage.Entity.SaleItem) {
        let startDate = saleItem.startTimestamp;
        let endDate = saleItem.endTimestamp || getNowWithoutSeconds();

        return Math.floor((endDate - startDate) / (1000 * 60));
      }

      export function getNowWithoutSeconds() {
        let now = Date.now();
        return now - (now % (1000 * 60));

      }

      export function getTotalPriceData(saleItem: Storage.Entity.SaleItem, version = null) {
        let totalPrice = 0;
        let time = totalMinutes(saleItem); 
        let itemTimeRates = Storage.Entity.ItemTimeRate.getTimeRatesWithDefault(saleItem.itemCode, version);

        if (itemTimeRates == null) {
          return null;
        }

        let details = [];

        for (let i = 0; i < itemTimeRates.orderedRates.length && time > 0; i++) {
          let currentTimeItemRate: Storage.Entity.ItemTimeRate = itemTimeRates.orderedRates[i];

          let rateToPay = currentTimeItemRate.rate;
          let timeUnits = 1;

          if (time < currentTimeItemRate.minutesAmount && !currentTimeItemRate.chargeFullTime) {
            rateToPay = (time / currentTimeItemRate.minutesAmount) * currentTimeItemRate.rate;
            timeUnits = time / currentTimeItemRate.minutesAmount;
          }

          totalPrice += rateToPay;
          time -= currentTimeItemRate.minutesAmount;
          details.push({timeUnitsUsed: timeUnits, rate: currentTimeItemRate.rate, price: rateToPay, rateTime: currentTimeItemRate.minutesAmount});
        }

        if (time > 0) {
          let defaultTimeItemRate: Storage.Entity.ItemTimeRate = itemTimeRates.default;


          let remainigTimeUnits = time / defaultTimeItemRate.minutesAmount;
          let remainingTime = time;

          if (defaultTimeItemRate.chargeFullTime) {
            remainigTimeUnits = Math.ceil(remainigTimeUnits);
            remainingTime = remainigTimeUnits * defaultTimeItemRate.minutesAmount;
          }

          totalPrice += remainigTimeUnits * defaultTimeItemRate.rate;
          details.push({timeUnitsUsed: remainigTimeUnits, rate: defaultTimeItemRate.rate, price: remainigTimeUnits * defaultTimeItemRate.rate, rateTime: defaultTimeItemRate.minutesAmount});
        }

        return { price: session.fixedFloat(totalPrice), details: details};
      }

      export function isSaleHasOpenTimeItems(saleItems): boolean {
        for (let saleItem of saleItems) {
          if (saleItem.timeItemVersion && (posUtils.isBlank(saleItem.endTimestamp) || saleItem.endTimestamp == 0)) {
            return true;
          }
        }

        return false;
      }
    }
  }
}
