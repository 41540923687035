module PositiveTS {
export module Components {
    export module PriorityCustomerInvoiceDetailsDialog {
      
      const dialogSelector = "priority-customer-invoice-details-dialog"
      
      function initData() {
        return {
          dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
          ainvoice: {},
          tinvoice: {},
          isEinvoice: false,
        }
      }

      
      export function create() {

        let priorityCustomerInvoiceDetailsDialog = {
            template: JST.priorityCustomerInvoiceDetailsDialog(),
            methods: {
              close() {
                if (this.dialogElem == null) {
                  this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                }
        
                this.dialogElem.close()
Pinia.componentsStore.openComponent( {componentName:"priorityCustomerReportDialog", args: [null, false]})
                this.cleanData()
              },
        
              async open(ainvoice) {
                try{
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  
                  this.dialogElem.show()
                  app.showLoadingMessage()
                  this.isEinvoice = ainvoice.hasOwnProperty('EINVOICEITEMS_SUBFORM')
                  
                  if (!this.isEinvoice){
                    const params = {
                      '$filter': `BOOKNUM eq '${ainvoice.BOOKNUM}'`,
                      '$expand': 'TPAYMENT_SUBFORM,TPAYMENT2_SUBFORM',
                    }
                    let result = await PositiveTS.Service.PriorityService.getTinvoices(params)
                    if (!result.success || result.error){
                      app.showErrorAlertDialog(result.error)
                      app.hideLoadingMessage()
                      return
                    }
  
                    if (result.tinvoices.length === 0){
                      app.showErrorAlertDialog(i18next.t('priorityCustomerInvoiceDetailsDialog.notFoundTinvoice'))
                      app.hideLoadingMessage()
                      return
                    }
                    this.tinvoice = result.tinvoices[0]
                  }
                  
                  this.ainvoice = ainvoice
                  
                  this.$nextTick(() => {
                    $(document).unbind('keypress')
                  })
                  app.hideLoadingMessage()  
                }catch(e){
                  console.error(e)
                  this.showGenericError()
                  app.hideLoadingMessage()
                }
              },
        
              showGenericError () {
                app.showAlert({
                  header: i18next.t('error'),
                  content: i18next.t('generalError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                },
                null,null)
              },
              
              cleanData () {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
              },

              addBasePriceOfItemToItems (items){
                if (Array.isArray(items)){
                  items.forEach((item) => {
                    // skip general item 
                    if (!["000","1000"].includes(item.PARTNAME)){
                      let localItem = session.allItems.get(item.PARTNAME)
                      if (localItem){
                        item.basePrice = localItem.priceZarhan
                      }
                    }
                  })
                }
              },
            },

            computed: {
              getInvoiceItems () {
                let items = []
                if (this.ainvoice.hasOwnProperty('EINVOICEITEMS_SUBFORM')){
                  items = this.ainvoice.EINVOICEITEMS_SUBFORM
                }

                if (this.ainvoice.hasOwnProperty('AINVOICEITEMS_SUBFORM')){
                  items = this.ainvoice.AINVOICEITEMS_SUBFORM
                }

                this.addBasePriceOfItemToItems(items)
                return items
              },

              invoiceNumber () {
                const BOOKNUM = posUtils.toString(this.ainvoice.BOOKNUM)
                const DETAILS = posUtils.toString(this.ainvoice.DETAILS)
                //should not be together but for case add space between them
                let invoiceNumber = `${BOOKNUM}${BOOKNUM && DETAILS ? ' ' + DETAILS: DETAILS}`
                return invoiceNumber
              }
            },

            data: initData

        }

        VueApp.component('priority-customer-invoice-details-dialog', priorityCustomerInvoiceDetailsDialog)
      }
    }
  }
}

