module PositiveTS {
export module Components {
    export module AccountActionsMenu {

      const componentSelector = "#account-actions-menu"

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
          
            this.theDialog = $(componentSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: Pinia.globalStore.mobileLayout ? '90vw' : '60vw',
              height: Pinia.globalStore.mobileLayout ? window.outerHeight*0.755 : 350,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          
          this.initialized = true
        }
      }



      function close() {
        $(componentSelector).dialog('close');
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      function calculator(){
        this.close();
Pinia.componentsStore.openComponent( {componentName:"calculatorDialog", args: []});  
      }


      function open() {
        this._initjQueryDialogIfNeeded();
        $(document).unbind('keypress');
        this.theDialog.dialog("option",{
          width: Pinia.globalStore.mobileLayout ? '90vw' : '90vw',
          height: Pinia.globalStore.mobileLayout ? window.outerHeight*0.755 : 450, 
          position: Pinia.globalStore.mobileLayout ? [18, 100] : [50, 150]
        }).dialog('open');
        // $(componentSelector)

        this.bonsAvailable = bonItemsCount() > 0;
        let saleItem = posVC.saleItemForSelectedRow();

        if (saleItem) {
          let itemPrinters = JSON.parse( saleItem.item.logicalOrGroupPrinters.replace(/\'/g, '"') )
          let noBonPrinters = (itemPrinters == null || itemPrinters.length == 0)
          this.isShowGeneralPrepAction = !noBonPrinters;
        }
        else {
          this.isShowGeneralPrepAction = false;
        }


        this.holdSaleAvailable = Service.HoldSale.isHoldSaleAvailable();
        return PositiveTS.Service.HoldSale.isReleaseSaleAvailable()
        .then( isReleaseSaleAvailable =>{
          this.releaseSaleAvailable  = isReleaseSaleAvailable;
        })
      }


      async function holdCurrentSale(){
        app.blockUIAndShowMessage(i18next.t("pos.holdingSale"),false);
        await Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.HOLD_SALE, posVC.sale.cashierEmployeeID, null, posVC.getTotalAmount());
        await PositiveTS.Service.HoldSale.holdCurrentSale();
        this.close();
        app.resumeUIAndHideMessage(false);
      }

      function openReleaseSaleDialog(){
        this.close();
Pinia.componentsStore.openComponent( {componentName:"releaseSaleDialog", args: []});
      }

      function printPerforma(){
        this.close();

        return PositiveTS.Service.Performa.printPerforma(posVC.sale, posVC.saleItems, posVC.salePayments)
        .then(() => {
          if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
            Service.Dalpak.dalpakPrinted(this.currentSelectedDalpak);
          }
          
          Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.PERFORMA_PRINTED, posVC.sale.cashierEmployeeID, null, posVC.getTotalAmount()).then(() => {});
          if (!session.pos.onlyPrintBonAfterPayment) {
            let bonItems = Storage.Entity.SaleItem.flattenItems(posVC.saleItems).map(item => $.extend(true,{},item)) //clone deep
            return PositiveTS.Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(posVC.sale, bonItems, posVC.salePayments)
          }
        })
      }

      function _initData(){
        return {
          holdSaleAvailable: false,
          releaseSaleAvailable: false,
          isShowGeneralPrepAction: false,
          bonsAvailable: false,
          ignorePromotions: posVC.ignorePromotions,
          showTogglePromotionsBtn: jsonConfig.getVal(jsonConfig.KEYS.showTogglePromotionsBtn),
          isMultiCurr: PositiveTS.Service.MultiCurr.getInstance().isMultiCurr(),
          isDutyFree: PositiveTS.Service.DutyFree.isDutyFree(),
          doNotPrintOriginalInvoice: jsonConfig.getVal(jsonConfig.KEYS.doNotPrintOriginalInvoice),
          createItemsFromPos: jsonConfig.getVal(jsonConfig.KEYS.createItemsFromPos) && !session.pos.isRoshemet,
          isPriorityApi: PositiveTS.Service.PriorityService.isPriorityCompany(),
          canManageHakafaCustomer: jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos),
        };
      }

      function printLastSaleCopy (){
        PositiveTS.VueInstance.$refs.pos.printLastSaleCopy()
      }

      function openPrepAction(){
        this.close();
        let saleItem = posVC.saleItemForSelectedRow();
        saleItem.hasPreparationInstructions = true
        return posVC.editItemWithPreparationInstructions()
      }

      async function printBon(){
        await PositiveTS.Components.Pos.printBon();
        this.close();
      }

      async function printBonAndHoldSale(){
        await Components.Pos.printBonAndHoldSale();
        this.close();
      }

      function bonItemsCount(){
        let bonItemsCount = PositiveTS.Storage.Entity.SaleItem.flattenItems(posVC.saleItems).filter(item => item.item.logicalOrGroupPrinters != "[]").length;

        return bonItemsCount;
      }

      async function ExitDalpakWithoutPrintingBons(){
        await PositiveTS.VueInstance.$refs.pos.dalpaksSaveExit(true);
        close()
      }

      let shouldFeed = true;
      function feedPaper(){
        printer.lineFeed();
      }

      function togglePromotions(){
        this.ignorePromotions = !this.ignorePromotions;
        PositiveTS.Helper.SaleHelper.togglePromotions(this.ignorePromotions);
      }

      function openCurrencyConversionCalculator(){
        Pinia.componentsStore.openComponent( {componentName:"currencyConversionCalculator", args: []})
        this.close()
      }
      function openCurrenciesRates(){
        Pinia.componentsStore.openComponent({componentName:"currenciesRatesDialog", args: []})
        this.close()
      }

      export function create() {

        let component = {
            template: JST.accountActionsMenu(),
            methods: {
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              holdCurrentSale: holdCurrentSale,
              openReleaseSaleDialog: openReleaseSaleDialog,
              printPerforma: printPerforma,
              close: close,
              printBon: printBon,
              printBonAndHoldSale: printBonAndHoldSale,
              itemSheiltaOpen: ()=>{ Pinia.componentsStore.openComponent( {componentName:"itemSheilta"}); },
              async openOrderCompletion() {
                this.close()
                await Pinia.componentsStore.openComponent( {componentName:"completeOrderWithPaymentDialog"});                
              },
              openPrepAction: openPrepAction,
              calculator: calculator,
              feedPaper: feedPaper,
              togglePromotions: togglePromotions,
              openCurrencyConversionCalculator: openCurrencyConversionCalculator,
              openCurrenciesRates: openCurrenciesRates,
              printLastSaleCopy: printLastSaleCopy,
              ExitDalpakWithoutPrintingBons: ExitDalpakWithoutPrintingBons,
              zeroScale: () => {
                Service.Scale.zeroScale();
              },
              scaleOSD: () => {return jsonConfig.getVal(jsonConfig.KEYS.scaleCom) == Service.Scale.S2Scale},
              isCaveret() { return session.pos.isCaveret },
              async splitSalePayment() {
                this.close();
                await Service.SplitSalePayment.startSplitSaleProcess();
              },
              async createItem() {
                this.close();
                let item = await Pinia.componentsStore.openComponent( {componentName:"addItemDialog", args: []});

                if (posUtils.isDefined(item)) {
                  return posVC.selectSizeColorForSaleItem(item);
                } else {
                  this.open();
                }
              },
              
              importOrderFromPriority (){
                this.close()
Pinia.componentsStore.openComponent( {componentName:"priorityImportOrderDialog", args: []})
              },
              openDinersDialog() {
Pinia.componentsStore.openComponent( {componentName:"selectDinersNumberDialog", args: []});
              },

              openCustomerClub () {
                this.close()
                posVC.openCustomerDialog('positive')
              },

              openHakafaDialog () {
                this.close()
                posVC.openCustomerDialog('hakafa')
              },

              openNewMobilePhoneRepair () {
                this.close()
                pNavigator.pushPage('pages', i18next.t('mobilePhoneRepair.title'), null, null)
                PositiveTS.VueServices.Router.goto(PositiveTS.Components.FeatureOrder.MobilePhoneRepair.name)
              },

              openMobilePhoneRepairsIndex () {
                this.close()
                pNavigator.pushPage('pages', i18next.t('indexMobilePhoneRepairs.title'), null, null)
                PositiveTS.VueServices.Router.goto(PositiveTS.Components.FeatureOrder.IndexMobilePhoneRepairs.name)
              },

              openMobilePhoneRepairsSettings () {
                this.close()
                pNavigator.pushPage('pages', i18next.t('mobilePhoneRepairSettings.title'), null, null)
                PositiveTS.VueServices.Router.goto(PositiveTS.Components.FeatureOrder.MobilePhoneRepairSettings.name)
              },
              async sendSmsToCustomer() {
                let title = i18next.t("sendSmsToCustomerDialog.title")
                let placeHolder = i18next.t("sendSmsToCustomerDialog.placeHolder")
                let requiredMessage = i18next.t("sendSmsToCustomerDialog.requiredMessage")
                let invalidPhoneMessage = i18next.t("sendSmsToCustomerDialog.invalidPhone")
                let smsMessage = jsonConfig.getVal(jsonConfig.KEYS.textToSendToCustomerInSms)

                let res = await Pinia.componentsStore.openComponent( {componentName:"textInputDialog", args: [title, placeHolder, requiredMessage, Components.TextInputDialog.keyboardTypes.simpleKeyboard]});
                let phoneNumber = res.result.replace(/\D+/g, '');

                  if(res.success && (posUtils.isBlank(phoneNumber) ||  Number(phoneNumber) > 9999999999)){
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: invalidPhoneMessage,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                    return
                  }

                  if(!res.success){
                    return
                  }

                await PositiveTS.Service.Sms.sendGenericSms(phoneNumber, smsMessage)
              },
              async printOtotTag(reprint = false) {
                this.close();
                await Service.Otot.printEntranceTagFromExistingQrCode(reprint);
              },

              async reprintOtotTag() {
                this.close();
                await Service.Otot.reprintEntranceTagFromExistingQrCode();
              },

              async purchaseByGamePoints() {
                this.close();
                await Service.Otot.purchaseByGamePoints();
              },
              
              openDrawer() {
                printer.openDrawerWithLog();
              },
              async setSaleRemarks() {
                let title = i18next.t("saleRemarksDialog.title")
                let placeHolderText = i18next.t("saleRemarksDialog.placeHolder")
                let saleRemarks = this.getSaleRemarks
                let keyboardType = Components.TextInputDialog.keyboardTypes.simpleKeyboard

                let res = await Pinia.componentsStore.openComponent( {componentName:"textInputDialog", args: [title, placeHolderText, null, keyboardType, 'textarea', saleRemarks]});

                if (res.success) {
                  if(res.result.length < 256){  
                    await this.addSaleRemarks(res.result)
                  } else {
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: i18next.t('saleRemarksDialog.toLongText'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                  }
                }

              },
              openCustomerClubSelect () {
                this.close()
                posVC.selectCustomerClubForSale()
              },
            },
            data: _initData,
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const dalpaksStore = Pinia.useDalpaksStore();

              const {currentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);
              
              const {getSaleRemarks,mobileLayout,saleItems,isDeliverySale} = Pinia.storeToRefs(globalStore);

              const globalStoreProps = {
                getSaleRemarks,
                saleItems,
                isDeliverySale,
                isMobile:mobileLayout,
              } 

              const {addSaleRemarks} = globalStore
              return {...globalStoreProps,currentSelectedDalpak,addSaleRemarks}
            },
            computed: {
              isElal(){
                return session.pos.isElal;
              },
              paymentForOrderCompletion(){
                return jsonConfig.getVal(jsonConfig.KEYS.paymentForOrderCompletion);
              },
              noSaleItems(){
                return Pinia.globalStore.saleItems?.length == 0
              },
              isExternalOrderSale(){
                if(Pinia.globalStore.isDeliverySale) {
                  let saleData = JSON.parse(posVC.sale.jsondata);
                  if(!posUtils.isBlank(saleData.delivery) && saleData.delivery.isExternalOrder) {
                    return true;
                  }
                }
                return false;
              },
              hasCustomerClubs(){
                return jsonConfig.getVal(jsonConfig.KEYS.memberOfClubs)?.length > 0 || false;
              },
              allowSplitSalePayment: () => Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)),
              hasOptions() { return (this.holdSaleAvailable || this.releaseSaleAvailable) },
              isEmptySale() { return Pinia.globalStore.saleItems.length == 0 },
              canPrintPerforma (){
                return !jsonConfig.getVal(jsonConfig.KEYS.disablePrintPerforma)
              },
              isDalpakim: () => jsonConfig.getVal(jsonConfig.KEYS.isDalpakim),
              showSendSmsButton: () => jsonConfig.getVal(jsonConfig.KEYS.showSendSmsButton),
              isOtot: () => Service.Otot.isOtotActive(),
              printBonOnDalpakExit: () => jsonConfig.getVal(jsonConfig.KEYS.printBonOnDalpakExit),
              hasDiners: () => jsonConfig.getVal(jsonConfig.KEYS.hasDiners),
              hasMobilePhoneRepairModule: () => jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule),
              isAndroidExernalCashDrawer: () => jsonConfig.getVal(jsonConfig.KEYS.isAndroidExernalCashDrawer),
              showOtotReprintQrCode: () => jsonConfig.getVal(jsonConfig.KEYS.showOtotReprintQrCode),
              allowSaleRemarks: () => jsonConfig.getVal(jsonConfig.KEYS.allowSaleRemarks),
              hasFlights(){
                return session.pos.hasFlights;
              },
              isRoshemet(){
                return session.pos.isRoshemet;
              },
            },

            mounted () {
              $('#pos-sale-actions-balance').off( "click");
			        $('#pos-sale-actions-balance').click(posVC.balanceCheck);
            },
        }

        VueApp.component('account-actions-menu',component)

      }
    }
}
}
