module PositiveTS { 
  export module Printing {
    export class MultipassSlip extends AFactory {
        private _I18N = {
          FOR_DOCUMENT: "מולטיפס עבור מסמך:",

        };



        constructor(sale: any, company: any, salePayments: any) {
            super(sale, company, salePayments);
        }

        run(): void{
          var voucherPayments = PositiveTS.Helper.SaleHelper.findPaymentByMethod(this._salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER);
          if (!voucherPayments) {return;}

          var dataOfPayments = JSON.parse(voucherPayments.data);
          for (var i=0; i<dataOfPayments.length;i++){
            this._printIfMultipass(dataOfPayments[i]);
          }

        }

        private _printIfMultipass(currentPayment):void{
          if ( currentPayment.smartVoucherType !== PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS){return;}
          var chargedCredited = "חוייב";
          if (this._sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
            chargedCredited = "זוכה";
          }

          var data = JSON.parse(currentPayment.response);

          var aThis = this._aThis;
          var zebra = this._zebra;
          var sale = this._sale;


          this.printHeaderForSlipType(this._I18N.FOR_DOCUMENT);

          zebra.append(aThis.allowComponents.init);
          aThis.printFloatingLineWithPrice(chargedCredited,
            session.fixedNumber( currentPayment.amount));

          if (this._sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
            aThis.printFloatingLineWithPrice("אישור",
                session.fixedNumber( data.transaction_id));
          } else {
            aThis.printFloatingLineWithPrice("עבור אישור",
                session.fixedNumber( data.transaction_id));
          }

          if (this._sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
            aThis.printFloatingLineWithPrice("יתרה",
                    session.fixedNumber( parseInt(data.balance) / 100));
          }

          this.lineFeed();
          printer.printLineCut();

        }

        private _isCashReturnInvoice(): boolean {
            var sale = this._sale;
            return (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE &&
                this._totalPayed() > 0);

        }

        private _totalPayed():number{
          return PositiveTS.Helper.SaleHelper.getTotalPaidByMethod(this._salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_CASH);
        }


    }
  }
}
