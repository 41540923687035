module PositiveTS {
  export module Components {
    export module DeleteAreaDialog {

      export function getComponent() {
        return {
          template: JST.deleteAreaDialog(),
          methods: {
            async open(dalpakAreaToDelete) {
              this.dalpakAreaToDelete = dalpakAreaToDelete;
              this.areaToMoveTo = this.availableAreasToMoveTo[0];
              return await this.$refs.dialog.open();
            },
            async saveChanges() {
              this.deleteEditModeDalpakArea({areaToDelete: this.dalpakAreaToDelete, dalpaksTargetAreaRailsId: this.areaToMoveTo.railsId});
              return true;
            },
          },
          computed: {
            continueButton() {
                return {
                  text: this.$t('continue'),
                  class: 'tables-green',
                  beforeCloseAction: () => this.saveChanges(),
                  value: true,
                }
            },
            availableAreasToMoveTo() {
              return this.dalpakAreasInEditModeWithoutDeleted.filter(area => String(area.railsId) != String(this.dalpakAreaToDelete.railsId));
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {hasDalpakAreas,dalpakAreasInEditModeWithoutDeleted,dalpaksInEditMode} = Pinia.storeToRefs(dalpaksStore);
            const {deleteEditModeDalpakArea} = dalpaksStore
            return {hasDalpakAreas,dalpakAreasInEditModeWithoutDeleted,dalpaksInEditMode,deleteEditModeDalpakArea}
          },
          data() {
            return {
              dalpakAreaToDelete: null,
              areaToMoveTo: null,
            }
          }
        }
      }
    }
  }
}