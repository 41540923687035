
module PositiveTS {
export module Application {
export module Controllers {
export class PosViewController extends AbstractViewControllerTS {

	sale:Storage.Entity.Sale = null;
	saleItems:Array<Storage.Entity.SaleItem> = []
	salePayments:Array<Storage.Entity.SalePayment> = []
	lastKeypressTimestamp:number = 0
	maxRowNumber = 0

	/** Posted when a change is made to the sale payments (like adding/updating/removing payment) */
	SalePaymentsChangedNotification:string = 'PositiveApplicationControllersPosViewControllerSalePaymentsChangedNotification'


	/** The sales person helper instance */
	salesPersonHelper = null

	customerDialogShowed:boolean =  false

	//for special promotions
	doNotAutoReappendPromotionAry = []

	/** The HTML used for the 2nd cell of every row (e.g, item description, discount name) */
	rowDescriptionCellHTML:string =  `<span class="pos-tableview-description-cell-description"></span>
			<img class="pos-tableview-description-cell-sale-img" src="${(<any>window).images_path}pos/sale.png" />
			<img class="pos-tableview-description-cell-present-img" src="${(<any>window).images_path}pos/gift.png" class="pos-present-icon" />
			<img class="pos-tableview-description-cell-pickup-img" src="${(<any>window).images_path}pos/plane12.png" class="pos-pickup-icon" />`


	rowDescriptionPromotionCellHTML:string = `<span class="pos-tableview-description-cell-description"></span>
			<img class="pos-tableview-description-cell-sale-img" src="${(<any>window).images_path}pos/promotion.png" />
			<img class="pos-tableview-description-cell-present-img" src="${(<any>window).images_path}pos/gift.png" class="pos-present-icon" />
			<img class="pos-tableview-description-cell-pickup-img" src="${(<any>window).images_path}pos/plane12.png" class="pos-pickup-icon" />`


	containSaleInHold:boolean = false
	warningMsgbox:boolean
	ignorePromotions:boolean
	flightcardUi:any
	Specialitems:Array<any>
	initPosPayment:boolean
	Paymentlimits:any

	allowedManualPromotions = []

	ignoredItemsForPromotions = []

	externalPromotions = [];

	isSaleCashWithdrawal = false;

	posInitialized = false


	// --------------------------------------------------------------------
	// Controller Life-Cycle
	// --------------------------------------------------------------------
	init(options?) {
		if(jsonConfig.getVal(jsonConfig.KEYS.enableSittingOrTA)){
			Pinia.componentsStore.openComponent(
				{componentName:"SittingOrTADialog", args: []});
		}
	}
	resume(options?) {
		console.debug('resume POSVC')
		if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && !Pinia.dalpaksStore.currentSelectedDalpak){
			if (!Service.SplitSalePayment.shouldRemainInPosScreenInDalpakim()) {
				Service.Dalpak.openDalpakScreen();
				return Promise.resolve();
			}
		}

		if (Service.MultipleMigvans.isActive()) {
			if ((posVC.saleItems.length > 0 || posVC.salePayments.length > 0) && !posUtils.isBlank(localStorage.getItem('lastKnownMigvan'))) {
				Pinia.globalStore.migvanUpdated(parseInt(localStorage.getItem('lastKnownMigvan')));
			} else {
				Pinia.componentsStore.openComponent( {componentName:"selfServiceMigvanSelectDialog", args: []});
			}
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceSittingOrTaScreen)) {
			if(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)){
				Pinia.componentsStore.openComponent(
				{componentName:"selfServiceSittingTaDialog", args: []});
			}
		}


		if (jsonConfig.getVal(jsonConfig.KEYS.isAlignSelfService)) {
			if (PositiveTS.VueInstance.$refs?.selfServicePaymentDialog?.$el?.open === true){
				PositiveTS.VueInstance.$refs.selfServicePaymentDialog.close()
			}

			PositiveTS.VueInstance.$nextTick(() => Pinia.componentsStore.openComponent( {componentName:"selfServicePaymentDialog"}));
		}

		if (PositiveTS.Service.OsherAd.isEnabled()) {
			if (PositiveTS.VueInstance.$refs?.OsherAdComponent?.$el?.open === true){
				PositiveTS.VueInstance.$refs.OsherAdComponent.close()
			}
				Pinia.componentsStore.openComponent( {componentName:"OsherAdComponent", args: []})
		}

		return new Promise((resolve,reject) => {
			if(this.posInitialized == false){
				if (PositiveTS.VueInstance.$refs.pos.$refs.itemButtonMenu){
					PositiveTS.VueInstance.$refs.pos.$refs.itemButtonMenu.loadDefaultMenu()
				}
				this.posInitialized = true;
			}
			posAS.init(posVC); //initialize auto suggest
			posVC.initializeKeyboard();
			if (Pinia.globalStore.simpleSelfService || jsonConfig.getVal(jsonConfig.KEYS.itemQueryOnlyPos) || jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet)) {
				homepageVC.init()
			}
			if(jsonConfig.getVal(jsonConfig.KEYS.itemQueryOnlyPos)){
				return resolve();
			}


			Service.InactivityService.init();


			// $('[data-role="tableview-header"] #left-of-search-container .pos-close-button').click(posVC.confirmSaleRestart);

			// Load sale from storage
			posVC.loadSale()
			.then(() => {
				return posVC.afterSaleLoaded()
			})
			.then( ()=>{
				PositiveTS.VueInstance.$refs.pos.open();
				if (PositiveTS.VueInstance.$refs.pos.$refs.itemButtonMenu != null && (session.pos.isRoshemet || posVC.sale.isDelivery) ) {
					PositiveTS.VueInstance.$refs.pos.$refs.itemButtonMenu.backToTop()
				}

				return PositiveTS.Service.VoucherPayment.areAnyTenbisOrCibusPaymentsExists().
				then( (isTenbisOrCibus) => {
					if (PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments).totalPaid &&
						!jsonConfig.getVal(jsonConfig.KEYS.allowInvoiceSplit)) {
						if (isTenbisOrCibus) {
							PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hideTenbisCibus = false;
							PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hideAllButTenbisCibus = true;
						} else {
							PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hideTenbisCibus = true;
							PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hideAllButTenbisCibus = false;
						}
					} else {
						PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hideTenbisCibus = false;
						PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hideAllButTenbisCibus = false;
					}

				})
			})
			.then(async () => {
				posVC.initalizeItemActions();
				posVC.initalizeSaleActions();

				// TODO: 	why this function call at the end?
				// 			if thier is no cahier, this function will call pNavigator.back
				posVC.warningMsgbox = false;
				posVC.checkIfCashierAssigned();
				if (posVC.warningMsgbox) return resolve();
				//session.pos.isRestartRequired = true;
				posVC.checkIfKupaRequiresRestart();
				if (posVC.warningMsgbox) return resolve();

				if(Service.PaymentPage.isEnabled() && Service.PaymentPage.getLasGatewayPaymentTxUrl()){
					return await Service.PaymentPage.goToPosPaymentPageIfNeeded();
				}

				// ensure shva configured properlly
				if (app.PosIconClick) {
					app.PosIconClick = false;
					if (session.pos.isEmv) {
						await Service.EMV.setAdapativeEMVPosNumberIfNeeded()
						await Service.EMV.checkIfRecoveryNeeded();
					}
				} else if(session.pos.isEmv && jsonConfig.getVal(jsonConfig.KEYS.standaloneMode)) {
					await Service.EMV.checkIfRecoveryNeeded();
				}

				if (posVC.warningMsgbox) return resolve();

				PositiveTS.Service.PunchCard.reopenPayPunchCardIfRequired();
				if(jsonConfig.getVal(jsonConfig.KEYS.scaleCom) == Service.Scale.S2Scale){
					Android.startReceivingWeight();
				}

				if(jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet)){
					PositiveTS.VueInstance.$refs.pos.openOpenDeliveries()
					return resolve();
				}

				if(session.isAndroid && localStorage.getItem("androidCustomerDisplayScreenDisabled") == null
					&& jsonConfig.getVal(jsonConfig.KEYS.customerDisplayCom) != Service.CustomerDisplay.sunmiScreenPort){
					if(Android.disableCustomerDisplay != undefined){
						Android.disableCustomerDisplay()
						localStorage.setItem("androidCustomerDisplayScreenDisabled","disabled")
					}
				}

				if(Service.SplitSalePayment.isSplitPaymentSale(posVC.sale)) {
					return posVC.goToPaymentScreen().then(() => {
						PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod();
						resolve();
					});
				} else if (Pinia.globalStore.splittedSalePaymentDialogInfo && !jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)) {
				  Service.SplitSalePayment.startSplitSaleProcess();
				}

				this.checkForIncompleteDelivery().then(() => {
					Service.Dalpak.setSaleExtraDalpaksDataIfNeeded(Pinia.dalpaksStore.currentSelectedDalpak).then(() => {

						if (jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually) && posUtils.isDefined(Pinia.globalStore.splitPaymentData) && Pinia.globalStore.splitPaymentCurrentQuantity > 1) {
							Pinia.componentsStore.openComponent( {componentName:"selectSplitPaymentAmountDialog", args: [true]});
						}

						resolve();
					})
				})
			})
		})

	}
	stop() {
		// Unbind the listeners from the resume function
		$('[data-role="tableview-header"] > .pos-close-button').unbind('click');

		$('#pos-sale-actions-discount').unbind('click');
		$('#pos-sale-actions-customer-club').unbind('click');
		$('#pos-search-field-clear').unbind('click');
		$('#pos-search-field-keyboard-icon').unbind('click');
		$('.ui-keyboard-input').unbind('beforeClose');
		$(document).unbind('keypress');
		$('#customer-club-delete-button').unbind('click');
		posAS.stop();

	  $('#pos-sale-dedicatedTo').unbind('click');
	  $('#pos-sale-dedicatedTo').bind('click',() => {
		jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS) ?
          Pinia.componentsStore.openComponent( {componentName:"dedicatedToDialog"})
          :
          Service.DedicatedTo.openSetDedicatedTo();
	  });

	  posVC.ignorePromotionsUI();

	}

	destroy() {
	}
	ignorePromotionsUI() {
		  $('#pos-sale-promotions-active .ui-button-style').removeClass('ui-button-style-gray-background');
		  $('#pos-sale-promotions-active .ui-button-style').removeClass('ui-button-style-azure-background');

		  if (posVC.ignorePromotions) {
		    $('#pos-sale-promotions-active .ui-button-style').addClass('ui-button-style-gray-background')
		  } else {
		    $('#pos-sale-promotions-active .ui-button-style').addClass('ui-button-style-azure-background')
		}
	}

	/**
	 * Initialize the on-screen keyboard
	 */
	initializeKeyboard() {

		if ((navigator.userAgent.toLowerCase().indexOf('android') > -1 && typeof(Android) !== "undefined")
		&& (session.pos.isRoshemet)) {
			$(document).off('keypress');
			$(document).on('keypress',posVC.onKeyPress);
			if(posVC.isDeviceWithoutSearchBar() || jsonConfig.getVal(jsonConfig.KEYS.useOnScanLibrary)){
				if(!onScan.isAttachedTo(document)){
					onScan.attachTo(document,{
						ignoreIfFocusOn: "input"
					});
					document.addEventListener<any>('scan',<any>posVC.noSearchInputScan)
				}
				onScan.setOptions(document, {
					minLength: 2,
					keyCodeMapper (oEvent) {
						if (oEvent.which == '189') {
							if (oEvent.shiftKey){
								return '_'
							}
							return '-'
						}

						return onScan.decodeKeyEvent(oEvent)
					},
				});
			}
			return;
		  }

		// Initialize keyboard
		$('#pos-search-field').keyboard(app.defaultKeyboardConfig)//.addTyping();


		let inputEl:any = $('#pos-search-field')
		let inputFunc = (a,el,b) => {
			inputEl.trigger('keydown');
		}

		$('.ui-keyboard-input').on('accepted', (e, keyboard) => {
			// posAS.activate = Boolean(session.pos.noBarcodeMode) || false;
			let searchString = $('#pos-search-field').val();
			if (posAS.results && posAS.results[0] && posAS.results[0].barcode == searchString) {
				$('#as-result-item-0').click();
			}
			return true;
		});

		$('.ui-keyboard-input').on('beforeClose', (e, keyboard) => {
			// posAS.activate = Boolean(session.pos.noBarcodeMode) || false;
			return true;
		});

		// Open the keyboard by clicking on the keyboard icon only
		$('#pos-search-field-keyboard-icon').off('click');
		$('#pos-search-field-keyboard-icon').on('click',() => {
			posAS.activate = Boolean(session.pos.noBarcodeMode) || true;
			$('#pos-search-field').trigger('keyboard-icon-click');
			$('#pos-search-field').trigger('keydown');
			$(inputEl).off("keyboardChange ", inputFunc)
			$(inputEl).on("keyboardChange", inputFunc)
			return false;
		});


		// Redirect every keystroke outside the pos-search-field into it
		$(document).off('keypress');
		$(document).on('keypress',posVC.onKeyPress);
		if(posVC.isDeviceWithoutSearchBar() || jsonConfig.getVal(jsonConfig.KEYS.useOnScanLibrary)){
			if(!onScan.isAttachedTo(document)){
				onScan.attachTo(document,{
					ignoreIfFocusOn: "input"
				});
				document.addEventListener<any>('scan',<any>posVC.noSearchInputScan)
			}
			onScan.setOptions(document, {
				minLength: 2,
				keyCodeMapper (oEvent) {
					if (oEvent.which == '189') {
						if (oEvent.shiftKey){
							return '_'
						}
						return '-'
					}

					return onScan.decodeKeyEvent(oEvent)
				},
			});
		}
	}

	noSearchInputScan(sScancode, iQuantity){
		if((!posVC.isDeviceWithoutSearchBar() || Pinia.globalStore.posState != "pos") && !posVC.canUseOnScan()){
			return;
		}

		if(Pinia.globalStore.mobilePosNavState == Components.PosViewState.Buttons ||
			Pinia.globalStore.mobilePosNavState == Components.PosViewState.Rows ||
			(jsonConfig.getVal(jsonConfig.KEYS.useOnScanLibrary) && !posVC.isDeviceWithoutSearchBar()))
			{
				posVC.addItemByBarcodeOrByCode(sScancode.detail.scanCode, true)
				.catch(function(e){
				  //don't send to Logrocket/OpenReplay item not found errors
				  if (e && e.message && e.message.startsWith(i18next.t("NO_ITEM_OR_BARCODE_FOUND"))) {
					console.warn(e);
				  }
				  else {
					PositiveTS.Service.Logger.error(e);
				  }
				  return app.promiseShowAlert({
					header: "",
					content: e.message,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
					noHandleEnterEscape: true,
				  })
				});
			}
	}

	canUseOnScan() {
		return jsonConfig.getVal(jsonConfig.KEYS.useOnScanLibrary) &&
		Pinia.globalStore.posState == "pos" && Pinia.globalStore.currentPage == "pos";
	}

	isDeviceWithoutSearchBar(){
		let devicesWithoutSearchBar = ['p2_lite','p2_pro','v1s']
		let found = false
		for(let i=0;i<devicesWithoutSearchBar.length;i++){
			if(navigator.userAgent.toLowerCase().indexOf(devicesWithoutSearchBar[i]) > -1){
				found = true
			}
		}
		return found;
	}

	onKeyPress (event) {
		if(posVC.isDeviceWithoutSearchBar() || jsonConfig.getVal(jsonConfig.KEYS.useOnScanLibrary)){
			return;
		}
		let isAndroid = (session.isAndroid && (<any>window).Android);
		let relevantFieldSelected = (event.target.id == 'pos-search-field' ||
					event.target.id == 'prompt-manager-approval-manager-card' ||
					event.target.id == 'input-dialog-field');
		// console.log('posVC keypress')
		// console.log(event.timeStamp)
		posAS.inputFromScanner = (event.timeStamp - posVC.lastKeypressTimestamp) < 30

		if (!posAS.inputFromScanner && event.target.id == 'pos-search-field' && !isAndroid) {
			(<any>document.querySelector('#pos-search-field')).blur();
			(<any>document.querySelector('#pos-search-field')).focus();
		}
		posVC.lastKeypressTimestamp = event.timeStamp

		// If the keypress is on the autoSuggest, ignore
		// TODO: this is a bad solution, need to fix
		//
		if (relevantFieldSelected) {
			return;
		}

		// Prevent default
		event.preventDefault();

		if (isAndroid) {
			if(event.which == 13)
			{
				var e = jQuery.Event('keyup');
				e.which = 27;
				e.keyCode = 27;
				$('#pos-search-field').trigger(e);

				var e = $.Event( "keyup", { which: 13, keyCode: 13 } );
				$('#pos-search-field').trigger(e);
			}
			else
			{
				$("#pos-search-field").val($('#pos-search-field').val() + String.fromCharCode(event.which))
			}
		}
		else {
			// Forward the event to the autoSuggest box...
			if(document.querySelector('#pos-search-field')){
				(<any>document.querySelector('#pos-search-field')).focus();
				(<any>document.querySelector('#pos-search-field')).value = String.fromCharCode(event.which);
			}

		}

	}

	RedirectIfAuthorized (authorizationRequired, funcRedirect,permissions?) {
    if (authorizationRequired) {
	       	app.showManagerApprovalDialog(permissions)
	        .then(function (managerEmployee) {
				console.log(managerEmployee.employeeID);
				funcRedirect();
	        })
	        .catch(function (error) {
	            console.log('DID NOT CHOOSE APPROVAL!!');
	        });
	    } else {
	      funcRedirect();
	    }
  }

	/**
	 * Initialize the item actions buttons
	 */
	initalizeItemActions () {
		$('#pos-sale-actions-balance').off( "click");
		$('#pos-sale-actions-balance').click(posVC.balanceCheck);

		$('#pos-sale-actions-account').off( "click");
		$('#pos-sale-actions-account').click(() => {
			Pinia.componentsStore.openComponent( {componentName: 'accountActionsMenu'})
		})

	}
	changePriceClicked() {
		let isGeneralItem = false
		let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)

		if(allowMultipleSaleItemsSelection) {
			isGeneralItem = Pinia.globalStore.multipleSelectedSaleItems.some(item => !item?.item?.allowZeroPrice && item?.item?.priceZarhan == 0)
		} else{
			isGeneralItem = !posVC.saleItemForSelectedRow()?.item?.allowZeroPrice &&  posVC.saleItemForSelectedRow()?.item?.priceZarhan == 0
		}

		var isRequireManagerApproval = (session.pos.parameterSelectAllowChangeItemPrice === 2); //MANAGER_APPROVAL_AUTHORIZATIONS.PROMPT

		if(isGeneralItem && (!allowMultipleSaleItemsSelection || (allowMultipleSaleItemsSelection && Pinia.globalStore.multipleSelectedSaleItems.length == 1))) {
			isRequireManagerApproval = jsonConfig.getVal(jsonConfig.KEYS.managerApprovalForGeneralItemPriceChange)
		}

		posVC.RedirectIfAuthorized(isRequireManagerApproval, posVC.changeUnitPriceForSaleItem,[Storage.Entity.Employee.CAN_CHANGE_PRICE]);
	}
	/**
	 * Initialize the sale actions buttons
	 */
	initalizeSaleActions () {

		// Set click listener for the sale discount button
		$('#pos-sale-actions-discount').click(posVC.selectDiscountForSale);

		$('#pos-sale-actions-customer-club').click(posVC.selectCustomerClubForSale);

		$('#customer-club-delete-button').click(posVC.confirmCustomerDelete);

		$('#pos-currency-conversion-calculator').click(() => posVC.openCurrencyConversionCalculator);
	}
	checkIfCashierAssigned () {
		// Is there a cashier in the session?
		if (session.pos.employeeID == "-1" || posUtils.isNullOrUndefined(session.pos.employeeID)) {
			const isRosemet = session.pos.isRoshemet;
			const isDeliveriesTablet = jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet);
			const standaloneMode = jsonConfig.getVal(jsonConfig.KEYS.standaloneMode)
			const selfServiceSuperMarket =Pinia.globalStore.selfServiceSuperMarket;

			if(isRosemet || isDeliveriesTablet || selfServiceSuperMarket || standaloneMode){
				let generalCashier = Service.Cashier.setGeneralEmployeeByDefault()
				posVC.sale.cashierEmployeeID = generalCashier.employeeID;
				posVC.sale.cashierEmployeeName = generalCashier.employeeName;
				Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
				$('.header-cashier').text(i18next.t("pos.generalEmployee"));
				return session.pos.persist();
			}

			// --- No, there is no cashier in session
			console.warn('Cannot work in POS without cashier');
			posVC.warningMsgbox = true;

			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("mustSelectCashier"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, function () {
				// Go back
				pNavigator.back();
			}, null);
		}
	}

	/*
	 Description:
	 	Ensure user restart brouser after data update, so data is saved locally and pos.ini
	 	is run with all updated new tables information
	 */
	checkIfKupaRequiresRestart () {
    // Is there a cashier in the session?
    if (session.pos.isRestartRequired) {
      // Must restart after data update
      console.warn('Must Restart After Data Update');
	  posVC.warningMsgbox = true;
      // Tell the user
      app.showAlert({
        header: i18next.t("error"),
        content: i18next.t("mustRestartAfterDataUpdate"),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      }, function () {
        // Go back
        pNavigator.back();
      }, null);
    }
  }

	balanceCheck () {
		PositiveTS.Service.CheckBalance.openCheckBalance()
	}

	_getNewOrderNumber(){
		var currentSequence = parseInt( localStorage.getItem("orderNumber")) || session.pos.posNumber * 1000 ;
		currentSequence++;
		localStorage.setItem("orderNumber", currentSequence.toString());
		return currentSequence;
	}

	async resetOrderNumber() {
		localStorage.removeItem("orderNumber");
		let orderNumber = posVC._getNewOrderNumber()
		await appDB.sales.where('invoiceSequence').equals(-1).modify({orderNumber: orderNumber});
	}

	initNewSaleFields(setNewOrderNumber = true) {
		var newSale = new PositiveTS.Storage.Entity.Sale();
		newSale.tenantID = session.pos.tenantID;
		newSale.companyID = session.pos.companyID;
		newSale.storeID = session.pos.storeID;
		newSale.storeName =  session.store.storeName;
		newSale.storeAddress =  session.store.address;
		newSale.storeFreeText = session.store.freeText;
		newSale.storeRegistrationNum =  session.store.registrationNum;
		newSale.storePhone =  session.store.phone;
		newSale.posPhysicalID = session.pos.physicalID;
		newSale.posDeviceID = session.pos.deviceID;
		newSale.posNumber = String(session.pos.posNumber);
		newSale.invoiceSequence = PositiveTS.Storage.Entity.Sale.NULL_INVOICE_SEQUENCE;
		newSale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
		newSale.cashierEmployeeID = session.pos.employeeID;
		newSale.cashierEmployeeName = session.pos.employeeName;
		newSale.createdAt = PositiveTS.DateUtils.fullFormat();
		newSale.discountID = '-1';
		newSale.discountName = '';
		newSale.discountPercent = '-1';
		newSale.discountApprovedByEmployeeID = '-1';
		newSale.customerSeqID = '-1';
		newSale.customerID = '-1';
		newSale.customerPhone = '';
		newSale.customerName = '';
		newSale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_NEW;
		newSale.syncLastMessage = '';
		newSale.syncLastMessageTimestamp = PositiveTS.DateUtils.fullFormat();
		newSale.salespersonEmployeeID = '';
		newSale.salespersonEmployeeName = '';
		newSale.zNumber = PositiveTS.Storage.Entity.Sale.NULL_Z_REPORT;
		newSale.orderNumber = setNewOrderNumber ? posVC._getNewOrderNumber() : null

		if((session.pos.isRoshemet) && (session.pos.parameterRequireSalesperson != PositiveTS.Storage.Entity.Pos.REQUIRE_MANDATORY)){
			newSale.salespersonEmployeeID = Shared.Constants.Employee.GENERIC_EMPLOYEE;
			newSale.salespersonEmployeeName = i18next.t("pos.generalEmployee");
		}

		newSale.initJsondata();
		newSale.vat = session.systemSettings.vat;
		newSale.isInHold = false;

		if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
			newSale.openDate = new Date();

			if (Pinia.dalpaksStore.currentSelectedDalpak) {
				newSale.dalpakId = Pinia.dalpaksStore.currentSelectedDalpak.railsId;
			}
		}

		return newSale;
	}
	async createNewSale() {
		posVC.customerDialogShowed = false;
		
		try {
		// There are no sales the given invoice sequence, so create one
		var newSale = posVC.initNewSaleFields();

			// newSale.persist(null)

			// Set the sale and its items and payments locally
			posVC.sale = newSale;
			posVC.saleItems = [];
			posVC.salePayments = [];

			// Call relevant hooks
			await posVC.saleUpdated();

			posVC.sale.isDelivery = false;
			Pinia.globalStore.setCustomerCompensation(false);
			Pinia.globalStore.setIsDeliverySale(false);
			Service.Delivery.changeMigvanIfNeeded();
			if(Pinia.elalStore.isOn){
				await Pinia.elalStore.createNewElalSaleSequence();
			}

		} catch(e) {
			// --- Failed to fetch sale items and payments
			console.error(e.stack);
			Service.Logger.error(e);
			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("cannotCreateNewSale"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
			throw e;
		};

	}
	deleteDiscountFromSaleItem(saleItemToRemoveDiscountFrom, disableItemPromotions = false) { //TODO: move to action/mutation
    let saleItem:PositiveTS.Storage.Entity.SaleItem = posVC.saleItems.filter(item => item.rowNumber == saleItemToRemoveDiscountFrom.rowNumber)[0]

    // If no sale item was found, abort!
    if (saleItem == null) {
        console.error('Row is not associated with any sale items, or there is no discount row selected');
        return;
    }

    // Ask the user
	let alertContent = disableItemPromotions ? i18next.t("posRemovePromotionsFromItem") : i18next.t("posRemoveDiscountFromItemBody")
    app.showAlert({
        header: i18next.t("posRemoveDiscountFromItemTitle"),
        content: alertContent,
        continueButtonText: i18next.t("remove"),
        cancelButtonText: i18next.t("cancel")
    }, function () {
		// Remove discount from sale item
		if (session.pos.useNewPromotions) {
			posDiscountVC.removeDiscountFromItem(saleItem, disableItemPromotions)
		}
		else {
			if (parseFloat(saleItem.discountID) > 0) {
				saleItem.discountID = "-1";
				saleItem.discountName = "";
				saleItem.discountPercent = -1;
				saleItem.discountAmount = -1;
				saleItem.discountType = null;
				saleItem.discountApprovedByEmployeeID = -1;


				posVC.doNotAutoReappendPromotionAry = posVC.doNotAutoReappendPromotionAry || [];
				posVC.doNotAutoReappendPromotionAry.push(saleItem.item.id);
				//remove the saleItem from the ignored promotions list if it is there
				var index = posVC.ignoredItemsForPromotions.indexOf(saleItem.rowNumber);
				if (index > -1) {
						posVC.ignoredItemsForPromotions.splice(index, 1);
				}

				return posVC.persistSaleItem(saleItem)
			}
			else {
				posVC.ignoredItemsForPromotions.push(saleItem.rowNumber);
				posVC.afterSaleItemPersisted(saleItem) //call relevant hooks
			}
		}
    }, null);
  }

	async fetchSale(currentSale) {

		try {

			// Set the sale and its items and payments locally
			let saleModel = new Storage.Entity.Sale();
			let notExistedItems = []
			currentSale.items = currentSale.items.map(item => {
				let itemModel = new Storage.Entity.SaleItem();
				itemModel.importFromObject(item);
				if(session.allItems.get(itemModel.itemCode)){
					itemModel.item = session.allItems.get(itemModel.itemCode)
					return itemModel;
				}else{
					if (session.pos.isCaveret && itemModel.itemCode == '1000' && itemModel.itemDescription == i18next.t("multipassPolice.discountName")) {
						// Special case when caveret's sale has a discount from Multipass-points service, ignore this one only
					} else {
						notExistedItems.push(itemModel.itemDescription)
					}
				}
			});

			if(notExistedItems.length > 0){
				let missingItemsToShowInMessage = ""
				notExistedItems.forEach(item=>{
					missingItemsToShowInMessage += `${item} \n `
				})
				throw new Error (`${i18next.t("pos.ItemNotFound")}:\n ${missingItemsToShowInMessage} \n ${i18next.t("pos.contactSupport")}`)
			}
			currentSale.payments = currentSale.payments.map(payment => {
				let paymentModel = new Storage.Entity.SalePayment();
				paymentModel.importFromObject(payment);
				return paymentModel;
			});
			let childItems:Array<PositiveTS.Storage.Entity.SaleItem> = currentSale.items.filter((item) =>  { return (item.level >= 1) })
			saleModel.importFromObject(currentSale);
			posVC.sale = saleModel;
			posVC.saleItems = currentSale.items.filter((item) => { return (item.level == null || item.level == 0)})

			posVC.salePayments = currentSale.payments;

			let jd = JSON.parse(posVC.sale.jsondata);
			let isElalOn = Pinia.elalStore.isOn;
			if( (isElalOn && !jd.elalSaleSequenceNumber)||(isElalOn && (jd.elalSaleSequenceNumber != Pinia.elalStore.getLastElalSaleSequence ))){
				await Pinia.elalStore.createNewElalSaleSequence();
			}
			if(!isElalOn && jd.elalSaleSequenceNumber){
				delete jd.elalSaleSequenceNumber;
				posVC.sale.jsondata = JSON.stringify(jd);
			}
			childItems.forEach((childItem) => {
				let parentItem = currentSale.items.filter((item) => {return item.rowNumber === childItem.parentRowNumber})[0]
				if (parentItem != null) {
					parentItem.children = parentItem.children || [];
					parentItem.children.push(childItem);
				}
				else {
					console.error('child item without parent found!!!');
				}
			})

		  // Clean payment of type METHOD_CHANGE
		  // Their shouldnt be any changed saved in cash
		  // for some reason bug exists that sometime it happenes
		  let salePayments = posVC.salePayments;
		  for (let i = 0; i < salePayments.length; i++) {
		    if (salePayments[i].method == PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE) {
		    	posVC.deleteSalePayment( salePayments[i] );
		    }
		  }

			let itemsToUpdate = posVC.updatePromotions();

			// If sale does not have a cashier, set it
			if (posVC.sale.cashierEmployeeID === '-1') {
				//TODO (SR): this should not happen ever - we need to throw an exception here if it does
				//           sale should not be persisted to DB if it has no cashier
				posVC.sale.cashierEmployeeID = session.pos.employeeID;
				posVC.sale.cashierEmployeeName = session.pos.employeeName;
			}
			await posVC.saleUpdated(itemsToUpdate);
			Service.Delivery.changeMigvanIfNeeded();

			return posVC.sale;

		} catch(e) {
			// --- Failed to fetch sale items and payments
			console.error(e.stack);

			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: e.message || i18next.t("loadingItemFromStorageFailed"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
		}
	}

	async checkForIncompleteDelivery(){
		let deliveryItem = posVC.saleItems.filter(item => item.isDelivery == true)[0];
		if(deliveryItem && posVC.sale.isDelivery == false){
			 let response = await app.promiseShowAlert({
				header: i18next.t("error"),
				content: i18next.t("delivery.incompleteDelivery"),
				continueButtonText: i18next.t("ok")
			})

			if(response == "continue"){
				await Service.Delivery.promptDeliveryDialogs(posVC.sale,deliveryItem);
			}
			else{
				await posVC.restartSale();
			}
		}
	}



	async loadSale (blockUI = true) {

		try {
			if(jsonConfig.getVal(jsonConfig.KEYS.nirDavidScanEntranceBarcode) && Pinia.globalStore.posState == "pos" && Pinia.globalStore.showNirDavidEntranceDialog) {
                await Service.NirDavidService.checkIfSaleIsEntrance()
			}
			if(blockUI){
				app.blockUIAndShowMessage(i18next.t("pos.loadingSale"));
			}
			let currentSale:any = await appDB.sales.where({invoiceSequence:-1}).first();
			let isAfterSplitSale = false;

			if (jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment) && posUtils.isBlank(currentSale)) {
				currentSale = await Service.SplitSalePayment.getSplittedSaleIfExist();
				isAfterSplitSale = posUtils.isDefined(currentSale) && posUtils.isDefined(Pinia.globalStore.splittedSalePaymentInfo);

				if (currentSale) {
					await appDB.sales.update(currentSale.id,{invoiceSequence: -1,isInHold:false})
					currentSale = await appDB.sales.get(currentSale.id);
				}
			}

			// Are there any sales?
			if (posUtils.isDefined(currentSale)) {
				await posVC.fetchSale(currentSale)

				if (isAfterSplitSale) {
					await Service.SplitSalePayment.afterSplittedSaleClosedActions();
				}

					if ( Pinia.globalStore.isReopenQuicksaleOnNewSale ) {
						Pinia.componentsStore.openComponent( {componentName:"quicksaleDialog", args: []});
					}
			}
			else {
				await posVC.createNewSale();
				

				if ( Pinia.globalStore.isReopenQuicksaleOnNewSale ) {
					Pinia.componentsStore.openComponent( {componentName:"quicksaleDialog", args: []});
				}

				if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && Pinia.dalpaksStore.currentSelectedDalpak) {
					await Service.Dalpak.setDalpakDiscountIfNeeded(Pinia.dalpaksStore.currentSelectedDalpak);
				}
			}
			let isPickupSale = Service.Delivery.isTaOrder(JSON.parse(posVC.sale.jsondata).delivery?.deliveryType)
			Pinia.globalStore.setPickupSale(isPickupSale);
		} catch(e) {
			// --- Failed to fetch sale
			if(blockUI){
				app.resumeUIAndHideMessage();
			}
			console.error(e.stack);

			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedToFetchSale"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
			throw e;

		}
		finally {
			if(blockUI){
				app.resumeUIAndHideMessage();
			}
		}
	}
	async afterSaleLoaded () {
		if (posVC.warningMsgbox == true) return;
		Service.DutyFree.checkIfNeedOpenFlightCardDialog();

		if (Boolean(session.pos.usePinPad)) {
			if (PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView) {
				PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView.getMissingSignaturesIfNeeded();
			}
		}

		Service.MizdamenOrHakafaSerive.openDialogIfNeeded();

		if (posVC.saleItems.length === 0 && Pinia.elalStore.isOn) {
			await Pinia.elalStore.addPNRItemsDialog();
		}

		if(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) && session.addonFilesVersion >= 2.87){
			try{
				let addonResult =  await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({}, "printer_status");
				let printerStatus = JSON.parse(addonResult?.request?.result);
				if(printerStatus?.isError && printerStatus.code == 2){
					let errors = printerStatus?.output?.split(', ')?.map(error => i18next.t(`selfService.printerErrorCodes.${error}`)) ?? []
					await app.showAlertDialog({
						header: i18next.t("error"),
						content: i18next.t("selfService.printerError", {errors: errors.join('\n ')}),
						hideContinueButton: true,
					})
					Pinia.globalStore.lockSelfServicePos()
				}
			}catch(error){
				console.error('Cannot check printer status. ' + error.message);
				Service.Logger.error(error)
			}
		}

		if(posVC.saleItems.length > 0 && Service.Withdrawal.itemIsCashWithdrawal(posVC.saleItems[0])) {
			posVC.isSaleCashWithdrawal = true;
			Pinia.globalStore.setWithdrawalSale(true);
		}
		if (posVC.sale.totalAmount >= 0) {
			PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.setPaymentButtonsNumber();
		}
		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.askForClubMembershipeMode)) && Pinia.globalStore.isCurrentSaleEmpty) {
			let availableClubs = jsonConfig.getVal(jsonConfig.KEYS.memberOfClubs);
			let selectedClub = availableClubs.find(club => club !== 'hakafa')
			posVC.openCustomerDialog(selectedClub ?? 'positive');
		}
	}
	setCustomer (customer) {
		if (customer != null) {
			posVC.sale.customerSeqID = customer.sequenceID;
			posVC.sale.customerID = customer.customerID;
			posVC.sale.customerPhone = customer.phone;
			posVC.sale.customerName = customer.name;
			if (customer.discountPrecent > 0) {
				posVC.sale.discountID = '-1';
				posVC.sale.promotionCode = '';
				posVC.sale.discountName = i18next.t("customerClub");
				posVC.sale.discountPercent = customer.discountPrecent;
				posVC.sale.discountType = '-1';
			}
		} else {
			posVC.sale.customerSeqID = '-1';
			posVC.sale.customerID = '-1';
			posVC.sale.customerPhone = '';
			posVC.sale.customerName = '';

			if (posVC.sale.discountID == '-1') {
				posVC.sale.discountPercent = '-1';
			}

			posVC.removeCustomerOnlyManualPromotions();

		}


		return posVC.saleUpdated();
	}

	updateSalesPersonForItem (saleItems, employee) { //TODO: move to action/mutation
		if (posUtils.isNullOrUndefined(employee)) {
			return Promise.reject('employee is undefined');
		}

		for (const saleItem of saleItems) {
			let realItem = posVC.saleItems.filter(item => item.rowNumber == saleItem.rowNumber)[0]
			if (realItem == null) {
				return Promise.reject('saleItem for salesperson update was not found');
			}

			posVC.sale.salespersonEmployeeID = employee.employeeID;
			posVC.sale.salespersonEmployeeName = employee.name;

			realItem.salespersonEmployeeID = employee.employeeID;
			realItem.salespersonEmployeeName = employee.name;

			if (realItem.children) {
				for (let child of realItem.children) {
					child.salespersonEmployeeID = employee.employeeID;
					child.salespersonEmployeeName = employee.name;
					if (child.children) {
						for (let gc of child.children) {
							gc.salespersonEmployeeID = employee.employeeID;
							gc.salespersonEmployeeName = employee.name;
						}
					}
				}
			}


			let itemsToUpdate = posVC.fillEmptySalespersons(employee);
			itemsToUpdate.set(realItem.rowNumber, realItem)
			posVC.saleUpdated(itemsToUpdate);
		}

		posVC.loadSale();
	}

	updateSalesPersonForSale(employee) {
		if (posUtils.isNullOrUndefined(employee)) {
			return Promise.reject('employee is undefined');
		}

		posVC.sale.salespersonEmployeeID = employee.employeeID;
		posVC.sale.salespersonEmployeeName = employee.name;

		let itemsToUpdate = posVC.fillEmptySalespersons(employee);
		posVC.saleUpdated(itemsToUpdate);
	}

  fillEmptySalespersons (employee) { //TODO IDB: no need to persist this here - just update the data in mem
		let itemsWithNoSalesperson = posVC.saleItems.filter(item => item.salespersonEmployeeID == "-1");

		if(session.pos.isRoshemet){
			itemsWithNoSalesperson = posVC.saleItems;
		}
		let itemsToUpdate = new Map<number,Storage.Entity.SaleItem>()
		if (itemsWithNoSalesperson.length == 0) {
			return itemsToUpdate;
			}

		for (let saleItem of itemsWithNoSalesperson) {
				saleItem.salespersonEmployeeID = employee.employeeID;
				saleItem.salespersonEmployeeName = employee.name;
			itemsToUpdate.set(saleItem.rowNumber,saleItem)
		}
		return itemsToUpdate;

			}

	recalculateDiscountForItem(saleItem:Storage.Entity.SaleItem) { //TODO: move to action/mutation
		// calcuate discount amount for items that has percent discount and not from promotion
		if (saleItem.discountType == PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT && saleItem.getPromotionCode == -1) {
			saleItem.discountAmount = Number(saleItem.quantity) * Number(saleItem.unitPrice) * Number(saleItem.discountPercent) / 100;
		}
	}
	persistSaleItem(saleItem:Storage.Entity.SaleItem) {  //TODO: move to action/mutation
		saleItem.quantity = parseFloat(session.fixedNumber(saleItem.quantity ,3));
		// recalculate the discount (in case we change the quantity or change the item price...)
		if (!saleItem.isChild) {
			posVC.recalculateDiscountForItem(saleItem);

			var itemIndex = posVC.saleItems.findIndex(function(item) { return (item.id == saleItem.id) });
			if (itemIndex < 0) {
				console.error('saleItem does not exist on pos item list!')
				return Promise.reject('saleItem does not exist on pos item list!');
			}
			else {
				posVC.saleItems[itemIndex] = saleItem;
			}
		}

		posVC.afterSaleItemPersisted(saleItem)
			if (!saleItem.isChild) {
				 PositiveTS.Service.CustomerDisplay.setItemDetails(saleItem);
			}

	}
	getIncrementQuantity (saleItem) {
		var incrementQuantity = 1;
		 if (saleItem.hasWeight) {
			  return PositiveTS.Service.Scale.getW()
      	.then(weight => {
        	incrementQuantity =  weight;
					if (saleItem._new && saleItem.quantity === 1){
						saleItem.quantity = weight;
					}
        	return  incrementQuantity;
      	})
				.catch(err => {
					 throw err;
      	})
		 } else {
			 	return  Promise.resolve(incrementQuantity);
		 }

	}

	canSaleItemBePersisted(saleItem:Storage.Entity.SaleItem, isFromTipPaymentScreen:boolean = false):string|boolean {
		// START TESTING REJECT ITEM]
		if(PositiveTS.Service.ForceDailyZ.hasToPerformZ())	{
			return i18next.t("zReport.forceZReport");
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && posUtils.isDefined(Pinia.dalpaksStore.currentSelectedDalpak) &&
			  Pinia.dalpaksStore.currentSelectedDalpak.area == Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES &&
			  (!Service.Delivery.isPickupSale(posVC.sale) && !Service.SplitSalePayment.isSplittedSale(posVC.sale))) {
				app.showAlert({
					header: i18next.t("error"),
					content: i18next.t("dalpaks.cantAddItemToDeliveryDalpak"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				},
				null, null);
				return i18next.t("dalpaks.cantAddItemToDeliveryDalpak");
		}

		if (!jsonConfig.getVal(jsonConfig.KEYS.allowGiftCardWithCustomerClub) && PositiveTS.Service.SmartVoucher.isSpecialItemCodeGiftCard(saleItem.barcode)
				&& PositiveTS.Service.CustomerClub.hasCutomerClubOnSale(posVC.sale)){
				app.showAlert({
					header: i18next.t("error"),
					content: i18next.t("failedAddSpecialItemGiftCardCustomerClubExistsOnSale"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				},
				null, null);
				return i18next.t("failedAddSpecialItemGiftCardCustomerClubExistsOnSale");
		}

		if (!PositiveTS.Service.SmartVoucher.isItemValid(saleItem,posVC.saleItems,posVC.Specialitems) ||
			!PositiveTS.Service.Delivery.isItemValid(saleItem,posVC.saleItems) ||
			!PositiveTS.Service.Otot.isItemValid(saleItem, posVC.saleItems)
				) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedSpecialitemExists"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return i18next.t("failedSpecialitemExists");
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.blockDeliveryOnSaleWithItems) && saleItem.isDelivery && posVC.saleItems.length > 0) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedDeliveryItemOnNotEmptySale"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return i18next.t("failedDeliveryItemOnNotEmptySale");
		}


		if ( saleItem.item.isSalePercentAddition &&
				 PositiveTS.Service.SalePercentAddition.isPreviousSalePercentAdditionItemFound(posVC.saleItems) ) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedSalePercentAdditionExists"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return i18next.t("failedSSalePercentAdditionExists");
		}


		var hakafaValidation = PositiveTS.Service.Hakafa.HakafaSpecialItemValidation.isItemValid(saleItem,posVC.Specialitems);
		if (!hakafaValidation.valid){
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t(`hakafa.${hakafaValidation.msg}`),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return i18next.t(`hakafa.${hakafaValidation.msg}`);
		}

		var withdrawalValidation = Service.Withdrawal.isItemValidInSale(saleItem);
		if(!withdrawalValidation.valid) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t(withdrawalValidation.msg),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return withdrawalValidation.msg;
		}

		let withdrawalSaleBrokenValidation = Service.Withdrawal.isSaleAnUnclosedWithdrawalSale(saleItem);
		if(!withdrawalSaleBrokenValidation.valid) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t(withdrawalSaleBrokenValidation.msg),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return withdrawalSaleBrokenValidation.msg;
		}

		let multipassLoadBudgetValidation = Service.MultipassLoadBudget.checkItemValid(saleItem, posVC.saleItems);
		if(!multipassLoadBudgetValidation.valid) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t(multipassLoadBudgetValidation.msg),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return multipassLoadBudgetValidation.msg;
		}

		if (!saleItem.isPNRItem && posVC.saleItems.some(si => si.isPNRItem)) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("elal.errors.cannotAddItemsPNRToSale"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			});
			return i18next.t("elal.errors.cannotAddItemsPNRToSale");
		}


		if (saleItem.itemCode === jsonConfig.getVal(jsonConfig.KEYS.beengoDiscountSaleItemCode)){
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("beengo.itemReservedForBeengo"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			function () {},
			null);

			return false;
		}

		if (Service.Tip.isTipItem(saleItem.item) && !isFromTipPaymentScreen){
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("cantAddTipItem"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			});

			return false;
		}


		let itemTotal = saleItem.quantity * saleItem.unitPrice
		let totalAmount = posVC.getTotalAmount()
		if ((totalAmount + itemTotal) < 0 && posVC.salePayments.length > 0) {
			app.showAlert({
				content: i18next.t("cannotMoveToMinusWhenSaleHasPayments"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			})
			return false
		}

		if (session.pos.hasFlights && posVC.isCurrentSaleComplimentaryDiscountSale() &&
				session.allItems.get(saleItem.itemCode) && (!session.allItems.get(saleItem.itemCode).isComplimentary)) {

				app.showAlert({
					header: i18next.t('complimentarySale.cantAdd'),
					content: i18next.t('complimentarySale.onlyComplimentaryItems'),
					hideCancelButton: true
				});

				return i18next.t('complimentarySale.onlyComplimentaryItems');
		}


		if (!PositiveTS.Service.icMega.checkIfCanSaleItemBePersisted(saleItem)){
			return false
		}

		return true;
	}
	editItemWithPreparationInstructions():Promise<any> {

		let saleItem:Storage.Entity.SaleItem

		if(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)) {
			saleItem = posVC.fetchPosVCSaleItem(Pinia.globalStore.multipleSelectedSaleItems[0])
		} else {
			saleItem = posVC.saleItemForSelectedRow()
		}

		if (saleItem == null) {
			console.error('Row is not associated with any sale items');
			return Promise.resolve();
		}
		if (saleItem.level == 2) { //this should never happen but just in case
			console.error('Cannot edit grand child item');
			return Promise.resolve();
		}

		return new Promise((resolve,reject) => {
			let itemCopy:Storage.Entity.SaleItem = _.cloneDeep(saleItem)//$.extend(true,{},saleItem);
			if (saleItem.level == 1) {
				let parentItem = saleItem.parent;
				if (!parentItem) {
					return reject('No Parent found for item')
				}

				let index = parentItem.children.findIndex(item => item.rowNumber == saleItem.rowNumber)


Pinia.componentsStore.openComponent( {componentName:"prepInstructionsDialog", args: [saleItem]})
				.then((rootItem:PositiveTS.Storage.Entity.SaleItem) => {
					if (rootItem === null) {
						saleItem = itemCopy; //if the user cancelled - revert back to the original item
						parentItem.children[index] = itemCopy;
						return resolve();
					}
					else {
						// itemCopy.deleteWithChildren()
						// .then(() => {
							parentItem.children[index] = rootItem;
							Storage.Entity.SaleItem.flattenItems([rootItem]).forEach(item => item._new = true)
						parentItem.persistWithChildren()
						posVC.afterSaleItemPersisted(parentItem)
								resolve();
					}
				})
				.catch(error => {
					console.error(error);
					reject(error);
				})
			}
			else {
Pinia.componentsStore.openComponent( {componentName:"prepInstructionsDialog", args: [saleItem]})
				.then((rootItem:PositiveTS.Storage.Entity.SaleItem) => {

					if (rootItem === null) {
						saleItem = itemCopy; //if the user cancelled - revert back to the original item
						return resolve();
					}
					// itemCopy.deleteWithChildren()
					// .then(() => {
						PositiveTS.Storage.Entity.SaleItem.flattenItems([rootItem]).forEach(item => item._new = true)
						rootItem.persistWithChildren()
					// })

						posVC.afterSaleItemPersisted(rootItem)
							resolve();

				})
				.catch(error => {
					console.error(error);
					reject(error);
				})
			}

		})
	}

	_getUpsaleMessageIfRequired(saleItem:Storage.Entity.SaleItem){
		if ( saleItem.item.upsaleMessage ) {
			return app.promiseShowAlert({
						header: "",
						content: saleItem.item.upsaleMessage,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					})
					.then( ()=>{
						return true;
					})
		}
		return Promise.resolve(true);
	}

	checkQuestionDialog(saleItem) {
		return posVC._getUpsaleMessageIfRequired(saleItem)
		.then(()=>{
			if (saleItem.item.checkQuestion) {
				return PositiveTS.Service.getItemQuestion(saleItem)
					.then(result =>{
						if (result === "cancel") {return false;}
						return true;
					})
			}
			return Promise.resolve(true);
		})

	}
	addPrepInstructionsIfNeeded(saleItem:Storage.Entity.SaleItem):Promise<{item:Storage.Entity.SaleItem, userCancelled:Boolean }> {
		let result = {item: saleItem, userCancelled: false}

		return new Promise((resolve,reject) => {
			if (Boolean(saleItem.item.autoOpenPreparationInstructions)) {
Pinia.componentsStore.openComponent( {componentName:"prepInstructionsDialog", args: [saleItem]})
				.then((item:Storage.Entity.SaleItem) => {
					if (item) {
						let resultItem = item.clone();
						if (item.children && item.children.length > 0) {
							resultItem.children = []
							item.children.forEach(child => resultItem.children.push(child.clone()))
						}
						result.item = resultItem
					}
					else {
						result.item =saleItem.clone()
						result.userCancelled = true
					}

					resolve(result)
				})
			}
			else {
				resolve(result)
			}
		})
	}

	async checkForMigvanChange(saleItem){
		let isThereNewMigvan = false;
		if(saleItem.itemCode == jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)){
			if(session.pos.migvanDeliveryId){
				isThereNewMigvan = true
			}
		}

		if(saleItem.itemCode == jsonConfig.getVal(jsonConfig.KEYS.taItemCode)){
			if(session.pos.migvanTaId){
				isThereNewMigvan = true
			}
		}


		if((posVC.saleItems.length > 0) && (isThereNewMigvan == true)){
			let response = await app.promiseShowAlert({
				header: i18next.t("confirmSaleItemDeleteHeader"),
				content: i18next.t("pos.itemsWillBeRemovedMigvanUpdate"),
				continueButtonText: i18next.t("remove"),
				cancelButtonText: i18next.t("cancel")
			});

			if(response == "continue"){


				let saleItemsCopy = posVC.saleItems.slice(0);

				for(let curSaleItem of saleItemsCopy){
					//The commented code exists in case we want to keep items that are actually
					//in the new migvan

					// let migvanItem = session.allMenuItems.filter(item => item.itemCode == curSaleItem.itemCode
					// 	&& item.migvanId == Pinia.globalStore.effectiveMigvanId)

					// if(migvanItem.length == 0){
						posVC.deleteSaleItem(curSaleItem);
					// }
				}
			}
			else{
				return false;
			}
		}

		return true;
	}

	async persistNewSaleItem (saleItem:Storage.Entity.SaleItem, ignorePriceLists = false, isFromTipPaymentScreen = false, forceUnitPrice: number = null):Promise<any> { //TODO: move to action/mutation

		if (saleItem == null) {
			return;
		}

		if (PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSale([saleItem], posVC.salePayments, true)) {
			return
		}

		if((jsonConfig.getVal(jsonConfig.KEYS.flightPass)) && !posVC.sale.jsondata.includes('flightPassData')) {
			try {
				await PositiveTS.VueInstance.$refs.flightSaleWithPassDialog.open();
			}
			catch(err) {
				return;
			}
		}

		let result = posVC.canSaleItemBePersisted(saleItem, isFromTipPaymentScreen)
		if (result !== true) {
			throw new Error(String(result));
		}

		if (saleItem.item.isPunchCard){
			await PositiveTS.Service.PunchCard.loadCardPunchCard(posVC.sale, saleItem);
		}

		if(saleItem.item.availableFrom > 0){
			if(moment.unix(saleItem.item.availableFrom).isAfter(new Date())){
				app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.itemNotAvailableYet'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
				});
				return;
			}
		}

		if(saleItem.item.availableUntil > 0){
			if(moment.unix(saleItem.item.availableUntil).isBefore(new Date())){
				app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.itemNotAvailableAnymore'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
				});
				return;
			}
		}

		if (saleItem.isDelivery){
			let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);

			if(svcCustomer.isCurrentCustomerExists()){
                app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('delivery.customerExists'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                });

                return;
			}

			let result = await posVC.checkForMigvanChange(saleItem);

			if(!result){
				return;
			}
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.shekuloTovBooksService)){
				try{
					let result = await Service.ShekuloTovBooks.checkForSpecialItemsAndFetch(saleItem)

					if (result.userCancelled){
						return
					}

					if(result.error !== null){
						app.showAlertDialog({
							header: i18next.t('error'),
							content: result.error,
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true,
						})
						return
					}

				} catch (e){
					app.hideLoadingMessageDialog();
					app.showAlertDialog({
						header: i18next.t('error'),
						content: i18next.t(`shekuloTovBooks.genericCommunicationError`),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true,
					})

					return
				}
		}
		//change dev tenantID
		if(session.company.tenantID == '9'){

			let result = Service.SuperFriendsClub.checkIfCanAddItemClub(saleItem.item.code)

			if (result.error){
				app.showAlert({
					header: i18next.t('error'),
					content: result.error,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				});
				return
			}

		}

		if (saleItem.item.isTimeItem) {
			saleItem.timeItemVersion = Storage.Entity.ItemTimeRate.getLastVersion(saleItem.item.code);
			saleItem.startTimestamp = Service.TimeItem.getNowWithoutSeconds();

			Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(true);
		}

		if (!posUtils.isBlank(saleItem.item.message)) {
			await app.promiseShowAlert({
				header: i18next.t('payAttention'),
				content: saleItem.item.message,
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true,
			})
		}

		if (typeof forceUnitPrice === 'number'){
			saleItem.unitPrice = forceUnitPrice
			saleItem.originalUnitPrice = forceUnitPrice
		}
		
		if (PositiveTS.Service.MultipassPolice.isEnable() && saleItem.itemDescription != i18next.t('multipassPolice.discountName')) {
			let cancelledPayment = await PositiveTS.Service.MultipassPolice.alertUserAboutPaymentBackIfNeeded();
			if (cancelledPayment === false) {
				return;
			}
		}

		try {

			if (saleItem.hasGroups && !saleItem.item.disableItemGroupEditing) {
				let itemWithInstructions = await posVC.addPrepInstructionsIfNeeded(saleItem)

				if(itemWithInstructions.userCancelled) {
					return;
				}

				let rootItem = await Pinia.componentsStore.openComponent({componentName:"itemWithGroupsDialog", args: [itemWithInstructions.item,0]})
				await Helper.SalesPerson.updateSalesPersonForItemInDB(rootItem);

				if (rootItem == null) {
					return;
						}

				rootItem.persistWithChildren();
				posVC.saleItems.push(rootItem);
				await posVC.parameterRequireSalespersonHandler(rootItem);
				await posVC.afterSaleItemPersisted(rootItem)
				return;
			}
			else if(saleItem.hasGroups && saleItem.item.disableItemGroupEditing) {
				let itemWithInstructions = await posVC.addPrepInstructionsIfNeeded(saleItem)
				if(itemWithInstructions.userCancelled) {
					return;
				}

				let rootItem = await Pinia.componentsStore.openComponent( {componentName:"itemWithGroupsDialog", args: [itemWithInstructions.item,0]})
				await Helper.SalesPerson.updateSalesPersonForItemInDB(rootItem);

				let indexInArray = -1;
				for (let i = 0; i < posVC.saleItems.length; i++) {
					if ( posVC.saleItems[i].quantity > 0 )
					{
						if (posVC.saleItems[i].id == saleItem.id ||
							posVC.saleItems[i].similarItem(saleItem, (i===(posVC.saleItems.length-1))) ) {
								rootItem = posVC.saleItems[i];
							indexInArray = i;
							break;
						}
					}
				}

				if(indexInArray > -1) {
					rootItem.quantity = Number((rootItem.quantity + 1).toFixed(3));
				}
				rootItem.persistWithChildren();
				if(indexInArray > -1) {
					posVC.saleItems[indexInArray] = rootItem
				} else {
					posVC.saleItems.push(rootItem);
				}
				await posVC.parameterRequireSalespersonHandler(rootItem);
				await posVC.afterSaleItemPersisted(rootItem)
				return;

			}
			else {
				if (saleItem.hasPreparationInstructions) {
					let itemWithInstructions = await posVC.addPrepInstructionsIfNeeded(saleItem)
					if(itemWithInstructions.userCancelled) {
						return;
					}
					saleItem = itemWithInstructions.item
					await Helper.SalesPerson.updateSalesPersonForItemInDB(saleItem);
					if (saleItem.children && saleItem.children.length > 0) {
						saleItem.persistWithChildren()
						posVC.saleItems.push(saleItem);
						await posVC.parameterRequireSalespersonHandler(saleItem)
						await posVC.afterSaleItemPersisted(saleItem)

						return;
					}
				}
				if (saleItem.isDelivery){
					if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery) != true){
						app.showAlert({
							header: i18next.t('error'),
							content: i18next.t('delivery.notDeliveryPos'),
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true
						}, null, null);
						return;
					} else {
						let saleData = JSON.parse(posVC.sale.jsondata);
						if(posUtils.isBlank(saleData.delivery) || (!posUtils.isBlank(saleData.delivery) && posUtils.isBlank(saleData.delivery.isExternalOrder))) {
							await PositiveTS.Service.Delivery.promptDeliveryDialogs(posVC.sale, saleItem);
						}
					}
				}
				let isPickupSale = Service.Delivery.isTaOrder(JSON.parse(posVC.sale.jsondata).delivery?.deliveryType)
				Pinia.globalStore.setPickupSale(isPickupSale);

				await posVC.persistNewSaleItemWithoutGroups(saleItem, ignorePriceLists, forceUnitPrice)
			}

			if(Service.MultipassLoadBudget.posHasMultipassLoad() && Service.MultipassLoadBudget.getLoadBudgetItemCode() == saleItem.itemCode) {
				let multipassCardResponse = await Service.MultipassLoadBudget.saveCardToLoadInSale(saleItem);
				if(!multipassCardResponse.success) {
					app.showAlert({
						header: i18next.t('error'),
						content: multipassCardResponse.error,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, () => {
						posVC.confirmSaleRestart(true);
					});
					return;
				}
			}

		}
		catch(error) {
			console.error(error);
			throw error
		}
	}

	async persistNewSaleItemWithoutGroups (saleItem:Storage.Entity.SaleItem, ignorePriceLists = false, forceUnitPrice = null) {
		try {
			let indexInArray = -1;
			// Check whether this sale item is the same as one from the sale

			for (let i = 0; i < posVC.saleItems.length; i++) {

				//check if the item is already returned

				if (posVC.saleItems[i].quantity > 0){
					if (posVC.saleItems[i].id == saleItem.id ||
						posVC.saleItems[i].similarItem(saleItem, (i===(posVC.saleItems.length-1))) ) {
						// Replace the sale item with that from the local array
						saleItem = posVC.saleItems[i];

						//saleItem.quantity = saleItem.quantity + incrementQuantity;
						indexInArray = i;
						break;
					}
				}
			}

			let canContinue = await posVC.checkQuestionDialog(saleItem)

			if (!canContinue){
				throw new Error(app.userCancelledGlobalMessage);
			}

			if (!ignorePriceLists && !forceUnitPrice) {
				let priceAmntObj = await PositiveTS.Service.PriceList.getEffectivePriceForCurrentSale(posVC.sale, posVC.salePayments, posVC.saleItems, saleItem) //not for parit ben (for now)

				if ((!saleItem.item.punchCardPriceByQuantity) && (!Service.Tip.isTipItem(saleItem.item))){
					saleItem.unitPrice = priceAmntObj.minimumPrice;
					saleItem.noDiscount = saleItem.noDiscount || priceAmntObj.minimumPriceListNoDiscount;
				}
				saleItem.priceListId = priceAmntObj.minimumPriceListCode;
			}

			if (saleItem.item.isSalePercentAddition){
				if (saleItem.unitPrice){
					saleItem.salePercentAddition = saleItem.unitPrice;
				} else {
					let dialogParameters = posVC.getDialogParametersSalePctAddition(saleItem)
					let inputDialogPrice = await inputDg.open(dialogParameters)
					saleItem.unitPrice = parseFloat(inputDialogPrice);
					saleItem.salePercentAddition = parseFloat(inputDialogPrice);
					saleItem.noDiscount = true;
				}
			}

			let incrementQuantity = await posVC.getIncrementQuantity(saleItem)

			if ( indexInArray > -1) {
				saleItem.quantity = Number((saleItem.quantity + incrementQuantity).toFixed(3));
			}

			posVC.recalculateDiscountForItem(saleItem); //sync - not for parit ben

			if((session.pos.parameterRequireSalesperson == PositiveTS.Storage.Entity.Pos.REQUIRE_MANDATORY) || (session.pos.isRoshemet)) { //sync - for parit ben also
				// update sale item sales person
				Helper.SalesPerson.updateSalesPersonForItemInDB(saleItem);
			}

			let hakafaSpecialItem = Service.Hakafa.HakafaSpecialItemValidation.getHakafaSpecialItem(posVC.Specialitems);

			if(session.pos.isRoshemet){

				if(saleItem.hasPreparationInstructions == true){
					let inputDgParams =  {
						header: i18next.t('prepInstructions.dialogHeader'),
						description: i18next.t('prepInstructions.dialogDescription') + ":",
						inputPlaceHolder: i18next.t('prepInstructions.dialogDescription'),
						showCancelButton: false,
						emptyErrorMessage: i18next.t('prepInstructions.dialogEmptyError'),
						keyboardLayout: 'hebrew-qwerty-custom',
						inputValidator: function(text){
							return true
						}
						};
						let prepRes: string = await inputDg.open(inputDgParams);
						if(!posUtils.isNullOrUndefinedOrEmptyString(prepRes)){
						saleItem.selectedPreparationInstructions = JSON.stringify([prepRes]);
						}
				}
					let localItem =  session.allItems.get(saleItem.barcode);
					if(!localItem){
						localItem =  session.allItems.get(saleItem.itemCode);
					}
					const clubItems  = [
						jsonConfig.getVal(jsonConfig.KEYS.valueCardDiscountItemCode),
						jsonConfig.getVal(jsonConfig.KEYS.goodiCardDiscountItemCode),
						jsonConfig.getVal(jsonConfig.KEYS.multipassDiscountItemCode),
						jsonConfig.getVal(jsonConfig.KEYS.tenbisApiDiscountItemCode),
						jsonConfig.getVal(jsonConfig.KEYS.simplyClubJoinItemCode),
						jsonConfig.getVal(jsonConfig.KEYS.simplyClubRenewItemCode),
					]

					if (((Number(saleItem.unitPrice) === 0) || (Number.isNaN(Number(saleItem.unitPrice)))) && saleItem.barcode !== hakafaSpecialItem.code
					&& !clubItems.includes(localItem.code)) { //not for parit ben or club items
						let itemPrice = await posVC.changePriceDialog(saleItem)
						saleItem.unitPrice = Number(itemPrice);
					}
			}

			if (saleItem.item.managerApprove) {
				await app.showManagerApprovalDialog()
			}

			if (indexInArray === -1) {
				indexInArray = posVC.saleItems.length;
			}

			// Add/update the sale item in local saleItems array
			posVC.saleItems[indexInArray] = saleItem;

			if(saleItem.hasWeight && jsonConfig.getVal(jsonConfig.KEYS.autoPopSelectItemPackage)){
				let allPackages = await PositiveTS.Storage.Entity.ItemPackage.getAllPackages(posVC.sale.companyID)
				let selected = await Pinia.componentsStore.openComponent( {componentName:"packageDialog", args: [allPackages, saleItem]})

				if (selected.approved) {
					saleItem.quantity = saleItem.quantity - (selected.weight/1000);
					saleItem.quantity = parseFloat(session.fixedNumber(saleItem.quantity, 3));

					if(selected.weight > 0){
						saleItem.itemDescription = saleItem.itemDescription + " הופחת " + String(selected.weight) + " גרם"
					}
					saleItem.reduceQuantity = selected.weight;
					saleItem.selectedPackagesById = JSON.stringify(selected.selectedPackagesById);
				}
			}

			if (Number.isInteger(forceUnitPrice)){
				saleItem.unitPrice = forceUnitPrice
				saleItem.originalUnitPrice = forceUnitPrice
			}

			// ## Put here if you want the forceUnitPrice not affctive
			if (!session.pos.isRoshemet || (session.pos.isRoshemet && jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos))) {
				let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
				if(hakafaSpecialItem && hakafaSpecialItem.code == saleItem.itemCode && ((jsonConfig.getVal(jsonConfig.KEYS.forceInvoiceClosingOnHakafa) && !svcCustomer.getCurrentSelectedCustomer().is_tamash_customer) || Service.PriorityService.payHakafaCustomerInvoicesFromPriority())){
					let price = await Pinia.componentsStore.openComponent( {componentName:"hakafaInvoicesDialog", args: []});
					saleItem.unitPrice = price;
				}
				else{
					//recalculate discount for the case when adding new items to the last line and that line already has discount
					if ((!saleItem.item.allowZeroPrice || (saleItem.item.isAllowNameChange && saleItem.item.allowZeroPrice))&& ((Number(saleItem.unitPrice) === 0) || (Number.isNaN(Number(saleItem.unitPrice))))) { //not for parit ben
						let itemPrice = await posVC.changePriceDialog(saleItem)
						saleItem.unitPrice = Number(itemPrice)
					}
				}
			}

			await PositiveTS.Service.CustomerDisplay.setItemDetails(saleItem)
			await posVC.parameterRequireSalespersonHandler(saleItem)
			posVC.afterSaleItemPersisted(saleItem)
			console.debug("Item Selected", saleItem)
		} catch (e) {
			if(e == app.userCancelledGlobalMessage) {
				this.deleteSaleItem(saleItem);
			}
			else {
				await app.promiseShowAlert({
					header: i18next.t("error"),
					content: i18next.t("failedToPersistSaleItem"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
					}, null, null);

				throw e;
			}
		}
	}

	afterSaleItemPersisted(saleItem:Storage.Entity.SaleItem) {  //TODO: move to action/mutation
		if (!Boolean(session.pos.noBarcodeMode)) {
			posAS.activate = false;
			// posAS.exactMatch = true;
		}

		let updatedSaleItemRows = posVC.updatePromotions()
		updatedSaleItemRows = updatedSaleItemRows.set(saleItem.rowNumber, saleItem)
		if(!saleItem.item?.allowZeroPrice && saleItem.item?.priceZarhan == 0) {
			let specialItems  = posVC.Specialitems
			let isSpecialItem = specialItems && specialItems.length > 0 ? specialItems.filter(si => si.code == saleItem.itemCode).length > 0 : false

			if(!isSpecialItem) {
				Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.GENERAL_ITEM, session.pos.employeeID,null, saleItem.unitPrice, saleItem.itemDescription).then(() => { });
			}
		}
		posVC.saleUpdated(updatedSaleItemRows).then(() => {
			if(jsonConfig.getVal(jsonConfig.KEYS.showLastItemAddedInPos)) { // Scroll to the bottom of the item list
				let itemsListSectionTag = document.getElementById("pos-unique-id");
				if (itemsListSectionTag) {
					itemsListSectionTag.scrollTop = itemsListSectionTag.scrollHeight;
				}
			}
		});	// sync
	}
	async persistSalePayment (salePayment:Storage.Entity.SalePayment, round?, callUpdateAmountIndicators = true) {  //TODO: move to action/mutation


		try {
			// Check whether the sale payment is already in the local salePayments array
			let indexInArray = posVC.salePayments.findIndex(payment=> payment.method == salePayment.method)
			// i  want to remove the VUE proxy object
			let currentSalePayment =  Storage.Entity.SalePayment.import(salePayment);
			if (indexInArray === -1) {
				posVC.salePayments.push(currentSalePayment);
			}else {
				posVC.salePayments[indexInArray] = currentSalePayment;
			}
			


			//persist the whole sale, wait for it to finish and only then update the UI!
			// await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
			await posVC.saleUpdated(); //async

			if (callUpdateAmountIndicators) {
				posPaymentVC.updateAmountsIndicators(false, round); //async!!!
			}

		}
		catch(e) {

			// --- Failed to fetch sale
			console.error(e.stack);



			// Tell the user
			app.showAlertDialog({
				header: i18next.t("error"),
				content: i18next.t("failedToPersistSalePayment"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);

			throw e;

		};

	}
	removeHakafaCLosedInvoices(saleItem){
		let hakafaSpecialItem = Service.Hakafa.HakafaSpecialItemValidation.getHakafaSpecialItem(posVC.Specialitems);
		if(hakafaSpecialItem && hakafaSpecialItem.code == saleItem.itemCode){
			let jd = JSON.parse(posVC.sale.jsondata);
			if(jd.hakafaClosedInvoices){
				delete jd.hakafaClosedInvoices;
				posVC.sale.jsondata = JSON.stringify(jd);
			}
		}
	}
	deleteSaleItem (saleItem:PositiveTS.Storage.Entity.SaleItem) {
		// Get the index of the sale item in the local array
		let indexInArray = posVC.saleItems.findIndex( item => item.rowNumber == saleItem.rowNumber)

		// If the sale item is not in array, than abort
		if (indexInArray == -1) {
			console.error('Cannot delete sale item which is not in the current sale.');
			return Promise.resolve(); //not really sure how rejecting the promise would help us here...
		}

		try {
		posVC.removeHakafaCLosedInvoices(saleItem);
		PositiveTS.Service.PunchCard.removeLoadPunchCardEntryFromSale(posVC.sale, saleItem)

		if(jsonConfig.getVal(jsonConfig.KEYS.shekuloTovBooksService) && Service.ShekuloTovBooks.checkBarcodesLength()) {
			Pinia.globalStore.handleShekuloTovBarcodes({type: 'remove', rowNumber: saleItem.rowNumber})
		}

		if (saleItem.timeItemVersion) {
			Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(false)
		}

		let lastPrintedItemsHashStrings = JSON.parse(posVC.sale.jsondata)["lastPrintedItemsHashStrings"]

		if(lastPrintedItemsHashStrings && lastPrintedItemsHashStrings[saleItem.rowNumber]) {

			delete lastPrintedItemsHashStrings[saleItem.rowNumber]
			let jsonData = JSON.parse(posVC.sale.jsondata)
			jsonData["lastPrintedItemsHashStrings"] = lastPrintedItemsHashStrings

			posVC.sale.jsondata = JSON.stringify(jsonData)
		}

		// Remove the item from local array
			let deletedRow = posVC.saleItems.splice(indexInArray, 1);


		// Make a copy of the entity since after delete its properites will change
		var saleItemCopy = new PositiveTS.Storage.Entity.SaleItem();
		saleItemCopy.importFromObject(saleItem.exportToObject());

		// Delete the sale item
			// return saleItem.deleteWithChildren()
			// .then(function () {

			Helper.SalesPerson.deleteSalesPersonFromSaleIfNeeded()
			let updatedItems = posVC.updatePromotions()// update promotions
			posVC.saleUpdated(updatedItems, deletedRow[0])
		} catch(e) {
			// --- Failed to delete sale item
			console.error(e.stack);

			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedToDeleteSaleItem"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
		};
	}
	async deleteSalePayment (salePayment) {
		// Get the index of the sale payment in the local array
		var indexInArray = $.inArray(salePayment, posVC.salePayments);

		// If the sale payment is not in array, than abort
		if (indexInArray == -1) {
			console.error('Cannot delete sale payment which is not in the current sale.');
			return;
		}
		try {
		// Make a copy of the entity since after delete its properites will change
			let salePaymentCopy = new PositiveTS.Storage.Entity.SalePayment();
			salePaymentCopy.importFromObject(salePayment.exportToObject());

			// Remove the payment from local array
			posVC.salePayments.splice(indexInArray, 1);
			await posVC.saleUpdated();

		} catch(e) {
			// --- Failed to delete sale payment
			console.error(e.stack);

			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedToDeleteSalePayment"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);
		}
	}
	createChangeInCashePayment (amount) {
		if (amount < 0) {
			var changeSalePayment = PositiveTS.Helper.SaleHelper.findPaymentByMethod(
				posVC.salePayments,
				PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE
			);

			if (changeSalePayment == null) {
				changeSalePayment = new PositiveTS.Storage.Entity.SalePayment();
				changeSalePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE;
				changeSalePayment.saleID = posVC.sale.id;

				posVC.salePayments.push(changeSalePayment);
			}

			changeSalePayment.amount = -amount;
		}
	}

	createChangeInCashPaymentMultiCurrency(paidCurrencies){
		var changeSalePayment = PositiveTS.Helper.SaleHelper.findPaymentByMethod(
			posVC.salePayments,
			PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE
		);
		let currencyData = [];

		if (changeSalePayment == null) {
			changeSalePayment = new PositiveTS.Storage.Entity.SalePayment();
			changeSalePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE;
			changeSalePayment.saleID = posVC.sale.id;
		}

		for(let currency in paidCurrencies){
			const paidCurrencyAmount = paidCurrencies[currency]
			let multiCurrPayment = Service.MultiCurr.getInstance().translateAmountAndCreateRowSalePaymentData(paidCurrencyAmount, currency);
			const baseCurrAmount = multiCurrPayment[0];
            const multiCurrRow = multiCurrPayment[1];
			if (paidCurrencyAmount > 0) {
				changeSalePayment.amount += baseCurrAmount;
				currencyData.push(multiCurrRow);
			}
		}
		changeSalePayment.data = JSON.stringify(currencyData)
		posVC.salePayments.push(changeSalePayment);
	}

	createChangeInCreditVoucherPayment (amount) {
		// create change in credit voucher
		return new Promise((resolve,reject) => {
			if (amount < 0) {
				var creditVoucherSalePayment = PositiveTS.Helper.SaleHelper.findPaymentByMethod(
					posVC.salePayments,
					PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER
				);

				if (creditVoucherSalePayment == null) {
					creditVoucherSalePayment = new PositiveTS.Storage.Entity.SalePayment();
					creditVoucherSalePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER;
					creditVoucherSalePayment.saleID = posVC.sale.id;
					creditVoucherSalePayment.amount = 0;
					creditVoucherSalePayment.data = JSON.stringify([]);

					posVC.salePayments.push(creditVoucherSalePayment);
				}

				return PositiveTS.Helper.SaleHelper.createCreditVoucherPayment(-amount)
				.then((newCreditPayment) => {

					creditVoucherSalePayment.amount += newCreditPayment.amount;
					var newPaymentData = JSON.parse(newCreditPayment.data);
					var oldPaymentData = JSON.parse(creditVoucherSalePayment.data);
					oldPaymentData.push(newPaymentData[0]);
					creditVoucherSalePayment.data = JSON.stringify(oldPaymentData);
					resolve()
				});
			} else {
				resolve();
			}
		})
	}

	createChangeInCreditCardPayment (amount, creditCard) {
		return new Promise((resolve,reject) => {
			// create change in credit voucher
			if (amount < 0) {
				if (posUtils.isNullOrUndefinedOrEmptyString(creditCard)) {
					return reject('error in createChangeInCreditCardPayment, creditCard is not defined');
				}

				let creditCardSalePayment = PositiveTS.Helper.SaleHelper.findPaymentByMethod(
					posVC.salePayments,
					PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT
				);

				if (creditCardSalePayment == null) {
					creditCardSalePayment = new PositiveTS.Storage.Entity.SalePayment();
					creditCardSalePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT;
					creditCardSalePayment.saleID = posVC.sale.id;
					creditCardSalePayment.amount = 0;
					creditCardSalePayment.data = JSON.stringify([]);

					posVC.salePayments.push(creditCardSalePayment);
				}



				creditCardSalePayment.amount += amount;
				var oldPaymentData = JSON.parse(creditCardSalePayment.data);
				oldPaymentData.push(creditCard);
				creditCardSalePayment.data = JSON.stringify(oldPaymentData);

				Service.FullSale.saveCurrentSale()
				.then(resolve)
			} else {
				return resolve();
			}
		})

	}

	async printSuffix () {
		let sale = posVC.sale.clone();

		let saleItems = posVC.saleItems.map(item => item.clone());

		var companyModel = new PositiveTS.Storage.Entity.Company();
		let company = await companyModel.promiseFetchByTenantIDAndCompanyID(sale.tenantID, sale.companyID)

		Printing.Invoice.printItemsWithPrintSufix(company, sale, saleItems);
		printer.jzebra.print();
		return;
	}

	async printInvoice () {
		let saleCopy = posVC.sale.clone();

		let saleItemsCopy: Storage.Entity.SaleItem[] = [];
		if (Pinia.languageManagerStore.multiLangEnabled) {
			let translatedSaleItems = Pinia.globalStore.saleItemsFormatted;
			saleItemsCopy = Storage.Entity.SaleItem.flattenItems(translatedSaleItems).map(item => item.clone());
		} else {
			saleItemsCopy = Storage.Entity.SaleItem.flattenItems(posVC.saleItems).map(item => item.clone());
		}		

		let salePaymentsCopy = posVC.salePayments.map(pay => pay.clone());

		await Printing.Invoice.printInvoice(saleCopy, saleItemHelper.unflattenSaleItems(saleItemsCopy), salePaymentsCopy, true)

		if(jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)){
			Printing.Invoice.printInvoice(saleCopy, saleItemHelper.unflattenSaleItems(saleItemsCopy), salePaymentsCopy, false)
		}
	}

	cleanSale (emptyTableViewMessage = null, stayOnCurrentDalapk  = false) {
		let currentSale = posVC.sale;

		posVC.maxRowNumber = 0;
		posVC.sale = null;
		if (Boolean(session.pos.usePinPad)) {
			if (PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView) {
				PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView.cleanData()
			}
		}
		else {
			PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad.cleanData()
		}

		Service.MizdamenOrHakafaSerive.cleanDataIfNeeded();

		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.isCaveretPayment))) {
			PositiveTS.VueInstance.$refs.caveretPaymentCustomerPicker.cleanData()
		}
		PositiveTS.VueInstance.$refs.positiveCustomerClub.cleanData()
		posVC.saleItems = [];
		posVC.salePayments = [];
		posVC.ignorePromotions = false;
		posVC.ignoredItemsForPromotions = [];
		posVC.allowedManualPromotions = [];
		posVC.externalPromotions = [];
		posVC.doNotAutoReappendPromotionAry = [];
		$('#pos-sale-info-item-info-sales-person > span').text(i18next.t("none"));
		Pinia.globalStore.cleanSale(emptyTableViewMessage)
		PositiveTS.Service.CustomerDisplay.setTextFarewell();
		setTimeout(function(){PositiveTS.Service.CustomerDisplay.setTextWelcome()},7000)

		if(localStorage.getItem("eilatCustomerInSaleInNonEilatStore") == "true"){
			session.store.containVat = false
			localStorage.removeItem("eilatCustomerInSaleInNonEilatStore")
		}

		if(localStorage.getItem("notEilatCustomerInSaleInEilatStore") == "true"){
			session.store.containVat = true
			localStorage.removeItem("notEilatCustomerInSaleInEilatStore")
		}

		if (PositiveTS.Service.DutyFree.isDutyFree()){
			PositiveTS.Service.Pickup.resetPickupAfterCleanSale()
		}
		if (PositiveTS.VueInstance.$refs.pos.$refs.itemButtonMenu){
			PositiveTS.VueInstance.$refs.pos.$refs.itemButtonMenu.loadDefaultMenu()
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
			let doDalpakClear = !stayOnCurrentDalapk;

			if (jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) {
				if (currentSale && Service.SplitSalePayment.isSplitPaymentSale(currentSale)) {
					doDalpakClear = false;
				}
			}

			if (doDalpakClear) {
				PositiveTS.Service.Dalpak.setCurrentSelectedDalpakAndSaveInLocalStorage(null);
			}
		}
	}

	async closeCurrentSale (change, creditCard, withoutGoBack = false):Promise<void> {

		PositiveTS.Service.valuCardSetSaleTrxKey(posVC.sale)

		if (jsonConfig.getVal(jsonConfig.KEYS.openDrawerOnSaleClosed)) {
			await printer.openDrawerIfRequired(posVC.salePayments)
		}

		// .then(() => {
			// calcuate sale totals
		var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);

		// If there is an amount left to pay, alert and exit!
		if (Helper.SaleHelper.customerPaidEnough(saleTotals)) {
			// Tell the user
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("incompletePayment"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			}, null, null);

			// We are done!
			throw new Error(i18next.t("incompletePayment"));
		}


		posVC.createChangeInCashePayment(change.cash)
		await posVC.createChangeInCreditVoucherPayment(change.creditVoucher)
		// Credit card change
		await posVC.createChangeInCreditCardPayment(change.creditCard, creditCard);


		(new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems).postUpdateCustomerSale())

		await Service.Coupon.setSaleJsondata(posVC.sale, posVC.salePayments, saleTotals.totalAmount);

		try {
			await Service.Sms.addToSaleSmsPhoneNumberIfNeeded(posVC.sale);
		} catch(err) {
			console.error(err)
		}

		if (PositiveTS.Service.MobilePhoneRepair.hasMobilePhoneRepairModule()){
			PositiveTS.Service.MobilePhoneRepair.deleteMobilePhoneRepairDataFromSaleJsonData(posVC.sale)
		}

		await	Helper.SaleHelper.closeDebitSaleWithSequence(posVC.sale, posVC.saleItems, posVC.salePayments);

		Service.EMV.getInstance().updateXFieldSequencesAfterSuccessfullTransactionIfNeeded();

		let doNotPrintOriginalInvoice = jsonConfig.getVal(jsonConfig.KEYS.doNotPrintOriginalInvoice);

		let saleData = JSON.parse(posVC.sale.jsondata);
		if(saleData.printReceipt != undefined) {
			doNotPrintOriginalInvoice = !saleData.printReceipt;
		}

		const doNotPrintOriginalInvoiceIfCash = jsonConfig.getVal(jsonConfig.KEYS.doNotPrintOriginalInvoiceIfCash)

		if (doNotPrintOriginalInvoice && jsonConfig.getVal(jsonConfig.KEYS.standaloneMode) &&
					jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)){
			//print only Items With PrintSufix in self service mode
			await posVC.printSuffix();
		}

		let dontPrintIfCash = (doNotPrintOriginalInvoiceIfCash &&
			(posVC.salePayments.length == posVC.salePayments.filter(pay => pay.method == 0 || pay.method == 2).length))

		let printInvoice = !dontPrintIfCash && !doNotPrintOriginalInvoice
		let allowAndSendSmsInvoiceAtSaleClose = jsonConfig.getVal(jsonConfig.KEYS.sendSmsAtSaleClose) && jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice)
		if (allowAndSendSmsInvoiceAtSaleClose) {
			let jsonData = JSON.parse(posVC.sale.jsondata)

			if(!posUtils.isBlank(jsonData.printSmsInvoice)) {
				printInvoice = jsonData.printSmsInvoice
			}
		}

		if(session.pos.hasInternetStore && posVC.sale.isDelivery){
			let data = JSON.parse(posVC.sale.jsondata)
			if(data.delivery && data.delivery.isExternalOrder && (data.delivery.j5 || data.delivery.isPhoneOrder)) {
				try {
					let paymentData =  JSON.parse(data.delivery.paymentData);
					if(!posUtils.isBlank(paymentData.Email)) {
						//update cashcow order
						const resCashCow = await PositiveTS.Service.CashCowService.updateOrder({
							order_id: data.delivery.externalOrderId,
							email_address: paymentData.Email,
							order_status_type: 4,
							total_price: posVC.sale.totalAmount,
							invoice_number: posVC.sale.invoiceSequence,
						})
					}
				} catch (error) {
					console.error(error);
				}
			}
		}

		if (Service.Otot.isOtotActive() && !Service.Otot.isRfidMode()  && Service.Otot.isOtotPurchaseTagItem(posVC.saleItems[0])) {
			await Service.Otot.printTagsFromSale(posVC.sale);
		}

		const printBonsBefore = Boolean(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) || 
								Boolean(jsonConfig.getVal(jsonConfig.KEYS.isAlignSelfService)) ||
								Boolean(jsonConfig.getVal(jsonConfig.KEYS.selfServiceAlignPrintingSettings));
		if(printBonsBefore) {
			await Service.LogicalPrinterBonPrint.printBons(Service.FullSale.posVCSale);
		}

		let shouldPromptInvoiceTransmit = session.pos.taxAuthorityInvoiceAllocationAmount > 0 
		&& (saleTotals.totalAmount > session.pos.taxAuthorityInvoiceAllocationAmount && saleTotals.totalVatableAmount > 0);
		// We prompt the transmit option only when the sale is above the threshold and there is at least one vatable item
		// Else we just print the invoice if needed like before
		if(shouldPromptInvoiceTransmit){
			app.hideLoadingMessage();
			let sale = posVC.sale;
			sale.items = posVC.saleItems;
			sale.payments = posVC.salePayments;
			await Pinia.componentsStore.openComponent( { componentName: "transmitTaxInvoiceDialog", args: [sale, false] })
		}else{
			if (printInvoice) {
				await  posVC.printInvoice()
			}
		}

		if(((doNotPrintOriginalInvoice || doNotPrintOriginalInvoiceIfCash)
			&& jsonConfig.getVal(jsonConfig.KEYS.openDrawerWhenNotPrintingOriginalInvoice)) || (allowAndSendSmsInvoiceAtSaleClose && jsonConfig.getVal(jsonConfig.KEYS.openDrawerOnCashSalesWithSmsInvoice))) {
				printer.openDrawerIfRequired(posVC.salePayments);
		}

		let emptyMessage;
		if (change.cash < 0) {
			let currencyLabel = Service.MultiCurr.getInstance().i18nextPosCurrency;
			if (jsonConfig.getVal(jsonConfig.KEYS.showChangePopup)) {
				app.showAlertDialog({
					header: i18next.t('message'),
					content: i18next.t("pos.changeForCustomer", {change: -change.cash.toFixed(1), currencySign : currencyLabel}),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				  }, null, null);
			} else {
				emptyMessage = i18next.t("pos.changeForCustomer", {change: -change.cash.toFixed(1), currencySign : currencyLabel});
			}
		}

		await Service.CloseSale.afterSaleClosedActions(Service.FullSale.posVCSale, emptyMessage, !printBonsBefore);

		if (Service.CustomerDisplay.isSunmiScreen()){
			Service.CustomerDisplay.updateScreenSaleClose()
		}

	}

	addExternalPromotion(code){
		for(let i=0;i<posVC.externalPromotions.length; i++){
			if(posVC.externalPromotions[i] == code){
				return;
			}
		}
		posVC.externalPromotions.push(code);
	}

	async getPotentialSalePromotionMessages():Promise<boolean> {
		let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()

		let promotionsEngine:Promotions.NewPromotionsEngine = new Promotions.NewPromotionsEngine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId,customer);

		// Add Manual selected promotions to the engine
		promotionsEngine.setManualPromotions(posVC.allowedManualPromotions);
		if (hasCust){
			Service.SpecialPromotion.setSpecialPromotionsForSaleItems() //TODO: check what this does
		}


		let promotionObject = promotionsEngine.calculatePromotionsForSale(posVC.sale, posVC.saleItems, true,posVC.externalPromotions);

		promotionObject.promotionGroups = promotionObject.promotionGroups.filter(pg => Promotions.NewPromotionsEngine.isMessagePromotion(pg.promotion));
		if (promotionObject.promotionGroups.length > 0) {
			let messages = _.uniqBy(promotionObject.promotionGroups,'promotion.code')
											.map((pg:Promotions.PromoGroup) =>
											`<li>${posUtils.isBlank(pg.promotion.message) ? pg.promotion.name : pg.promotion.message}</li>`);
			let unitedMessage = i18next.t("pos.potentialPromotionsExist") + '\n\n<ul class="potential-promotions">' + messages.join("") + '</ul>';
			let res = await app.promiseShowAlert({
				header: i18next.t("pos.potentialPromotionsExistHeader"),
				content: unitedMessage,
				continueButtonText: i18next.t("pos.continueToPayment"),
				cancelButtonText: i18next.t("pos.backToSale"),
				hideCancelButton: false,
			})
			return (res == "continue")
		}
		else {
			return true;
		}
	}


	//Sync - Update sale promotions
	updatePromotions ():Map<number,Storage.Entity.SaleItem> {
		if (session.pos.useNewPromotions) {
			return this.updatePromotionsNewEngine();
		}
		else {
			return this.updatePromotionsOld();
		}

	}

	private updatePromotionsNewEngine():Map<number,Storage.Entity.SaleItem> {
		// remove sale promotion before calcuating promotions for sale and items
		//Helper.SaleHelper.removeSalePromotionFromSale(posVC.sale); //tODO: re-implement

		if (posVC.saleItems.length < 1) {
			return new Map<number,Storage.Entity.SaleItem>();
		}

		// create instance for promotion engine
		let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()

		let promotionsEngine:Promotions.NewPromotionsEngine = new Promotions.NewPromotionsEngine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId,customer);

		// Add Manual selected promotions to the engine
		promotionsEngine.setManualPromotions(posVC.allowedManualPromotions);
		if (hasCust){
			Service.SpecialPromotion.setSpecialPromotionsForSaleItems() //TODO: check what this does
		}


		let promotionObject = promotionsEngine.calculatePromotionsForSale(posVC.sale, posVC.saleItems, false, posVC.externalPromotions);


		//second phase. apply promotion on sale items - return the sale items for update.
		let saleItemsToPersist = promotionsEngine.applyPromotionsOnItems(promotionObject, posVC.sale, posVC.saleItems);

		let jd = JSON.parse(posVC.sale.jsondata)
		jd.promotions = promotionObject.promotionGroups;
		posVC.sale.jsondata = JSON.stringify(jd);

		Pinia.globalStore.setPromotions(jd.promotions);
		// if (promotionObjects.salePromotion != null) {
		// 	Helper.SaleHelper.applySalePromotionOnSale(posVC.sale, posVC.saleItems, promotionObjects.salePromotion);
		// }

		return (saleItemsToPersist);
	}

	private updatePromotionsOld():Map<number,Storage.Entity.SaleItem> {
		// remove sale promotion before calcuating promotions for sale and items
		Helper.SaleHelper.removeSalePromotionFromSale(posVC.sale);

		if (posVC.saleItems.length < 1) {
			return new Map<number,Storage.Entity.SaleItem>();
		}

		// create instance for promotion engine
		let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()

		let promotionsEngine = new Promotions.Engine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId, customer);


		// Add Manual selected promotions to the engine
		promotionsEngine.setManualPromotions(posVC.allowedManualPromotions);
		if (hasCust){
			Service.SpecialPromotion.setSpecialPromotionsForSaleItems()
		}


		let promotionObjects = promotionsEngine.calculatePromotionsForSale(posVC.sale, posVC.saleItems);
		let saleItemsToPersist = new Map<number,Storage.Entity.SaleItem>();
			PositiveTS.Helper.SaleHelper.initPromotionGroupJsonData(posVC.sale);
			for (let saleItem of posVC.saleItems) {
				var saleItemInserted = false;

				//remove all existing promotions from saleItems
				if (saleItemHelper.doesItemContainDiscountOrPromotion(saleItem)) {
					saleItemHelper.removePromotionFromSaleItem(saleItem); //sync
					saleItemsToPersist.set(saleItem.rowNumber, saleItem);
					saleItemInserted = true;
				}

				var bestDiscountForItem = 0;

				for (let i=0; i< promotionObjects.promotionItems.length; i++) {
					let promotionGroup = promotionObjects.promotionItems[i];
					let promoItem = promotionGroup.item;
					promoItem.discountAbsoluteValue = parseFloat(promoItem.discountAbsoluteValue);

					if (saleItem.id == promoItem.saleItemID && promoItem.isPromotionGiven === true &&
						(promoItem.discountAbsoluteValue > bestDiscountForItem )) {
						bestDiscountForItem = promoItem.discountAbsoluteValue;

						saleItemHelper.addPromotionToSaleItem(saleItem, promoItem);
						PositiveTS.Helper.SaleHelper.addPromotionGroupToJsonData(posVC.sale,promotionGroup);
						if (!saleItemInserted) {
							saleItemsToPersist.set(saleItem.rowNumber,saleItem);
						}
					}
				}
			};

			if (promotionObjects.salePromotion != null) {
				Helper.SaleHelper.applySalePromotionOnSale(posVC.sale, posVC.saleItems, promotionObjects.salePromotion);
			}


		return (saleItemsToPersist);
	}

	getCustomerParams() {
		var svcCustomerClub = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
		let hasCust = ((posVC.sale.customerSeqID != '-1') || svcCustomerClub.isCurrentCustomerExists());
		let clubCust = svcCustomerClub.getCurrentSelectedCustomer()
		let isCustomerClubCust = hasCust && (clubCust == null || clubCust != null && (clubCust["clubName"] !== "HAKAFA" || session.pos.useNewPromotions));
		let promotionTypes = []
		if (hasCust && jsonConfig.getVal(jsonConfig.KEYS.fullClub)) {
			promotionTypes = clubCust.promotion_types || [];
		}
		let customerGroupId = null;
		if (clubCust) {
			customerGroupId = clubCust.customer_group_id

			if (Service.Brill.isBrillClub(clubCust.clubName) && !customerGroupId) {
				let customerGroupKey = Service.Brill.getCustomerGroupKey(clubCust);

				if (session.customerGroupsByName[customerGroupKey]) {
					customerGroupId = session.customerGroupsByName[customerGroupKey].code;
				}
			}
		}
		return [isCustomerClubCust,promotionTypes,customerGroupId,clubCust]

	}
	hasCustomer () {
		var svcCustomerClub = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
		let hasCust = ((posVC.sale.customerSeqID != '-1') || svcCustomerClub.isCurrentCustomerExists());
		let clubCust = svcCustomerClub.getCurrentSelectedCustomer()
		return hasCust && (clubCust == null || clubCust != null && clubCust["clubName"] !== "HAKAFA");
	}

	customerPromotionTypes() {
		let res = [];
		var svcCustomerClub = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
		if (svcCustomerClub.isCurrentCustomerExists()) {
			res = svcCustomerClub.getCurrentSelectedCustomer().promotion_types || [];
		}
		if (!jsonConfig.getVal(jsonConfig.KEYS.fullClub)) {
			res = [];
		}
		return res;
	}
	// --------------------------------------------------------------------
	// Manage table view
	// --------------------------------------------------------------------
	//async - but we don't wait for this function's persist sale to finish on purpose because of performance reasons
	saleUpdated (updatedOrAddedRows = new Map<number,Storage.Entity.SaleItem>(), deletedRow = null):Promise<any> {
		// Sale updated so post relevant notification
		// posVC.updateSaleTotal(); //TODO: this is a logic method not view method
		if(Pinia.globalStore.selfServiceSuperMarket){
			document.getElementById('pos-search-field').focus()
		}
		Pinia.globalStore.setSaleApproval(false);
		updatedOrAddedRows.forEach((saleItem, rowNumber) => {
			Storage.Entity.SaleItem.setPackagePrices(saleItem);
		})

		Pinia.globalStore.saleUpdated({items: posVC.saleItems, sale: posVC.sale, payments: posVC.salePayments,
			updatedOrAddedRows: updatedOrAddedRows, deletedRow: deletedRow, saleTotal: posVC.getTotalAmount() })

		return new Promise((resolve,reject) => {
			Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
			.then(() => {
				posVC.setCustomerState()

				if (Service.CustomerDisplay.isSunmiScreen()){
					Service.CustomerDisplay.sendSaleToScreen()
				}

				resolve()
			})

		})
	}

	setCustomerState() { //TODO: move this whole logic to Vuex

		let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
		let customerDisplayName = undefined;
		let isCustomerExists = false;

		if(!posUtils.isNullOrUndefinedOrEmptyString(JSON.parse(posVC.sale.jsondata).dedicatedTo)){
			customerDisplayName = JSON.parse(posVC.sale.jsondata).dedicatedTo;
			isCustomerExists = true;
		}

		if (svcCustomer.isCurrentCustomerExists() ) {
			customerDisplayName = svcCustomer.getCustomerShortDisplayName();
			isCustomerExists = true;
		}

		if (posVC.sale.customerSeqID != '-1') {
		  isCustomerExists = true;
		  customerDisplayName = posVC.sale.customerName;
		}

		let isPositiveCustomerInSale = svcCustomer.isCurrentCustomerExists(null,"positive")

		Pinia.globalStore.setHakafaDisabled(!PositiveTS.Service.Hakafa.showHakafaBtn());
		Pinia.globalStore.setPositiveCustomerInSale(isPositiveCustomerInSale)

		if (isCustomerExists) {

			let amountStr = null;
			let amountType = null;
			let deliveryText = null;
			let phoneNumber = null;
			let cust = svcCustomer.getCurrentSelectedCustomer() || {}


			Pinia.globalStore.setCustomerCompensation((cust.compensation != null) && (cust.compensation != ""));

			if (cust.amount != null) {
				amountType = "יתרה: "
				amountStr = session.fixedNumber(cust.amount);
			}
			if (cust.cust_points_for_use != null && Pinia.globalStore.fullClub) {
				amountType = i18next.t('positiveCustClub.points') + ": "

				amountStr = session.fixedNumber(cust.cust_points);
			}

			if (cust.TotalCashBack != null && cust.clubName == PositiveTS.Service.SimplyClubService.clubName) {
				amountType = i18next.t('positiveCustClub.points') + ": "

				amountStr = session.fixedNumber(cust.TotalCashBack);
			}

			if (cust.selectedBudget != null) {
				amountType = "תקציב: "
				amountStr = session.fixedNumber(cust.selectedBudget);
			}
			if((posVC.sale.isDelivery) && (JSON.parse(posVC.sale.jsondata).delivery.ordererPhone || "")){
				deliveryText = "" + (JSON.parse(posVC.sale.jsondata).delivery.ordererPhone || "");
			}

			if((cust.is_delivery_customer_only != null ) && (cust.is_delivery_customer_only)){
				amountType = null;
				amountStr = null
			}

			if(JSON.parse(posVC.sale.jsondata).dedicatedPhone){
				phoneNumber = JSON.parse(posVC.sale.jsondata).dedicatedPhone;
			}

			let customerDataDeliveryText = (deliveryText == phoneNumber ? "" : deliveryText);
			let customerData = {
				displayName: customerDisplayName.substring(0,50),
				amountType,
				amountStr,
				customerDataDeliveryText,
				phoneNumber
			};


			Pinia.globalStore.setPosCustomerNameData(customerData);
		} else {
			Pinia.globalStore.setPosCustomerNameData(null);
		}

		posVC.sale.customerName = customerDisplayName;
	}

	setLastPrintedBonGroupItemsHashStrings(){
        let lastPrintedItemsHashStrings = Storage.Entity.SaleItem.hashStringItemChildren(posVC.saleItems)

        let jsonData = JSON.parse(posVC.sale.jsondata)

        jsonData = {...jsonData, lastPrintedItemsHashStrings}
        posVC.sale.jsondata = JSON.stringify(jsonData)
    }

	setDedicatedTo(){
		let dedicatedTo = JSON.parse(posVC.sale.jsondata).dedicatedTo;
		let phoneNumber = null;

		if(JSON.parse(posVC.sale.jsondata).dedicatedPhone){
			phoneNumber = JSON.parse(posVC.sale.jsondata).dedicatedPhone;
		}

		Pinia.globalStore.setPickupSale(true);

		let customerData = {
			displayName: dedicatedTo.substring(0,32),
			phoneNumber,
		};

		Pinia.globalStore.setPosCustomerNameData(customerData);
		posVC.sale.customerName = dedicatedTo;
	}

	/**
	* saleItemForSelectedRow
	* returns the saleItem (Entity) for the given table row
	* Sync/Async: Sync
	*/
	saleItemForSelectedRow ():Storage.Entity.SaleItem {
		if (Pinia.globalStore.selectedSaleItem == null) {
			return null;
		}
		let selectedRowNumber = Pinia.globalStore.selectedSaleItem.rowNumber;
		if (Pinia.globalStore.selectedSaleItem.isChild) {
			return posVC.saleItems.filter(item => (item.children != null && item.children.length > 0))
				.map(item => item.children)
				.reduce((a,b) => a.concat(b))
				.filter(item => item.rowNumber == selectedRowNumber)[0]
		}
		else {
			return posVC.saleItems.filter(item => item.rowNumber == selectedRowNumber)[0]
		}
	}

	fetchPosVCSaleItem(saleItem):Storage.Entity.SaleItem {
		let selectedRowNumber = saleItem.rowNumber

		if (saleItem.isChild) {
			return posVC.saleItems.filter(item => (item.children != null && item.children.length > 0))
				.map(item => item.children)
				.reduce((a,b) => a.concat(b))
				.filter(item => item.rowNumber == selectedRowNumber)[0]
		}
		else {
			return posVC.saleItems.find(item => item.rowNumber == selectedRowNumber)
		}
	}

	fetchArrayPosVCSaleItems(saleItems): Array<Storage.Entity.SaleItem> {
		let items = []

		for (const saleItem of saleItems) {
			items.push(posVC.fetchPosVCSaleItem(saleItem))
		}

		return items
	}
	// --------------------------------------------------------------------
	// Listeners
	// --------------------------------------------------------------------
	async _deleteSaleItem(saleItemToDelete){
		let items = [];
		items.push(saleItemToDelete)
		await PositiveTS.Service.HoldSale.cancelBonItemsIfRequired(items)

		var index = posVC.ignoredItemsForPromotions.indexOf(saleItemToDelete.rowNumber);
		if (index > -1) {
			posVC.ignoredItemsForPromotions.splice(index,1);
		}

		if(Service.Withdrawal.itemIsCashWithdrawal(saleItemToDelete)) {
			posVC.isSaleCashWithdrawal = false;
			Pinia.globalStore.setWithdrawalSale(false);
		}

		// Delete the sale item
		return await posVC.deleteSaleItem(saleItemToDelete);
	}

	confirmSaleItemDelete (saleItem = null, dontAskForConfirmation = false) {
		// Search for this row ID in the local array
		var saleItemToDelete = saleItem || posVC.saleItemForSelectedRow();

		// If no sale item was found, abort!
		if (saleItemToDelete == null) {
			console.log('Row is not associated with any sale items');
			return;
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.cannotAllowDeleteItemsWhenPaymentMethodIsActive) && posVC.salePayments?.length > 0){
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedToDeleteSaleBeacuseActivePayments"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			  }, null, null);
			   return;
		}
		// check if the sale is going to restart
		if (posVC.saleItems.length < 2 && posVC.salePayments[0] && posVC.salePayments[0].amount > 0)
		{
			app.showAlert({
			  header: i18next.t("error"),
			  content: i18next.t("failedToDeleteSalePaymentPending"),
			  continueButtonText: i18next.t("ok"),
			  hideCancelButton: true
			}, null, null);
		 	return;
		}
		if (saleItemToDelete.isPNRItem) {
			app.showAlert({
			  header: i18next.t("error"),
			  content: i18next.t("elal.errors.cannotDeletePNRItem"),
			  continueButtonText: i18next.t("ok"),
			  hideCancelButton: true
			}, null, null);
		 	return;
		}

		if (this.checkIfCurrentSaleIsPaymentOfMobilePhoneRepair()){
			app.showAlert({
			header: i18next.t("error"),
			content: i18next.t('mobilePhoneRepair.alertCannotDeleteItemMobilePhoneRepairFromPaymentSale'),
			continueButtonText: i18next.t("ok"),
			hideCancelButton: true
			}, null, null)
			return
		}

		if(Service.MultipassLoadBudget.getLoadBudgetItemCode() == saleItemToDelete.itemCode) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t('multipass.deleteItemValidation'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
				}, null, null)
				return
		}

		// Ask the user
		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.askForConfirmationDeleteItem)) && !dontAskForConfirmation) {
			return app.promiseShowAlert({
				header: i18next.t("confirmSaleItemDeleteHeader"),
				content: i18next.t("confirmSaleItemDelete"),
				continueButtonText: i18next.t("remove"),
				continueButtonAttribute: 'data-approve-button',
				cancelButtonText: i18next.t("cancel")
			})
			.then( response =>{
				if (response === "continue") {
					if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.isDeleteItemManagerApproval))) {
						return app.showManagerApprovalDialog()
						.then( (manager)=>{
							Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_ROW, posVC.sale.cashierEmployeeID, manager.employeeID, session.fixedFloat(saleItemToDelete.unitPrice * saleItemToDelete.quantity)).then(() => {});
							return posVC._deleteSaleItem(saleItemToDelete);
						})
					}
					else {
						Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_ROW, posVC.sale.cashierEmployeeID, null, session.fixedFloat(saleItemToDelete.unitPrice * saleItemToDelete.quantity)).then(() => {});
						return posVC._deleteSaleItem(saleItemToDelete);
					}
				}
			})
		}
		else {
			if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.isDeleteItemManagerApproval))) {
				return app.showManagerApprovalDialog()
				.then( (manager)=>{
					Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_ROW, posVC.sale.cashierEmployeeID,  manager.employeeID, session.fixedFloat(saleItemToDelete.unitPrice * saleItemToDelete.quantity)).then(() => {});
					return posVC._deleteSaleItem(saleItemToDelete);
				})
			}
			else {
				Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_ROW, posVC.sale.cashierEmployeeID, null, session.fixedFloat(saleItemToDelete.unitPrice * saleItemToDelete.quantity)).then(() => {});
				return posVC._deleteSaleItem(saleItemToDelete);
			}
		}
	}
	async restartSale(blockUI = true) {
		try {
			if(blockUI){
				app.blockUIAndShowMessage(i18next.t("pos.loadingSale"));
			}
			let stayOnDalpak = false;

			if(jsonConfig.getVal(jsonConfig.KEYS.shekuloTovBooksService) && Service.ShekuloTovBooks.checkBarcodesLength()) {
				Pinia.globalStore.handleShekuloTovBarcodes({type: "clear"})
			}

			if(Pinia.globalStore.multipassPendingLoadBudgetSale) {
				Pinia.globalStore.cleanMultipassPendingLoadBudget()
			}
			if (Pinia.elalStore.isOn) {
				await Pinia.elalStore.cancelPNRItems() ;
			}
			if(Service.Otot.isOtotActive()){
				Pinia.globalStore.setOtotSaleBracelet({
					bracelet: null,
					status: Service.Otot.BRACELET_STATUS.NEW
				})
			}
			if (PositiveTS.Service.SimplyClubService.isSubTotalExistsOnSale(posVC.sale)) {
				PositiveTS.Service.SimplyClubService.cancelTranOnSale(posVC.sale);
			}
			if (PositiveTS.Service.MultipassPolice.policePointsHaveBeenPaid()) {
				await PositiveTS.Service.MultipassPolice.abortPayments();				
			}

			await PositiveTS.Service.HoldSale.cancelBonItemsIfRequired(posVC.saleItems, false)

			if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)){

				if (Service.Modbus.isActive() && posVC.saleItems.filter(si => si.timeItemVersion && !si.endTimestamp).length > 0) {
					Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(false);
				}

				let result = await Service.Dalpak.clearDalpakSale(Pinia.dalpaksStore.currentSelectedDalpak);

				if (!result.success){
					stayOnDalpak = true;
					app.showAlertDialog({
						header: i18next.t('error'),
						content: result.errorMessage || i18next.t('dalpaks.restartSaleFailed'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					 });
				}
			}

			if (Service.SplitSalePayment.isSplitPaymentSale(posVC.sale)) {
				Pinia.globalStore.setSplittedSalePaymentInfo(null);
			}

			await appDB.sales.delete(posVC.sale.id);
			posVC.cleanSale(null, stayOnDalpak);

			if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && !stayOnDalpak){
				posVC.isSaleCashWithdrawal = false;
				Pinia.globalStore.setWithdrawalSale(false);
				Service.Dalpak.openDalpakScreen();
			} else {
				await posVC.loadSale(blockUI)
				posVC.afterSaleLoaded()
				posVC.isSaleCashWithdrawal = false;
				Pinia.globalStore.setWithdrawalSale(false);


				if (Service.MultipleMigvans.isActive()) {
					Pinia.componentsStore.openComponent(
					{componentName:"selfServiceMigvanSelectDialog", args: []});
				}

				if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceSittingOrTaScreen)) {
					if(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)){
						Pinia.componentsStore.openComponent(
						{componentName:"selfServiceSittingTaDialog", args: []});
					}else{
						Pinia.componentsStore.openComponent(
						{componentName:"SittingOrTADialog", args: []});
					}
				}
			}
		}
		finally {
			if(blockUI){
				if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
					app.resumeUIAndHideMessage(false);
				} else {
					app.resumeUIAndHideMessage();
				}
			}
		}

		return true;
	}
	confirmSaleRestart (skipMessageConfirmation = false) {
		var blockDelete = false;

		if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) &&
			 jsonConfig.getVal(jsonConfig.KEYS.isDelivery) &&
			 posUtils.isDefined(Pinia.dalpaksStore.currentSelectedDalpak) &&
			 Pinia.dalpaksStore.currentSelectedDalpak.area == Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES &&
			 Service.Delivery.isPickupSale(posVC.sale)) {
				app.showAlert({
					header: i18next.t("error"),
					content: i18next.t("failedToDeletePickupSale"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
					}, null, null);
				 return;
		}

		// Check if there is a payment already debited but not completed the sale.
		for ( var i = 0; i < posVC.salePayments.length; i++ )
		{

			if (posVC.salePayments[i].method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
					var data = JSON.parse(posVC.salePayments[i].data);
					if (data[0] && data[0].amount) {
						blockDelete = true;
					}
			}

			if ( posVC.salePayments[i].amount != 0 || blockDelete)
			{
				app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedToDeleteSalePaymentPending"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
				}, null, null);
		 		return;
			}
		}


		if (Service.SplitSalePayment.isBaseSplitSale(posVC.sale)) {
			app.promiseShowAlert({
				header: i18next.t("error"),
				content: i18next.t("splitSalePayment.cantRestartSale"),
				continueButtonText: i18next.t("ok"),
				cancelButtonText: i18next.t("cancel")
				}).then(response => {
						if (response === "continue") {
							posVC.removeAllRemoveableItems();
						}
					});
		 	return;
		}



		if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.askForConfirmationDeleteSale)) && !skipMessageConfirmation) {

			return app.promiseShowAlert({
				header: i18next.t("confirmSaleRestartHeader"),
				content: i18next.t("confirmSaleRestart"),
				continueButtonText: i18next.t("remove"),
				cancelButtonText: i18next.t("cancel")
			})
			.then( response =>{
				if (response === "continue") {
					if(posVC.saleItems.length == 0){
						return posVC.restartSale();
					}
					else if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.isDeleteInvoiceManagerApproval))) {
						return app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_CANCEL_SALE])
						.then( (manager)=>{
							Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_SALE, posVC.sale.cashierEmployeeID, manager.employeeID, posVC.getTotalAmount()).then(() => {});
							return posVC.restartSale();
						})
					} else {
						Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_SALE, posVC.sale.cashierEmployeeID, null, posVC.getTotalAmount()).then(() => {});
						return posVC.restartSale();
					}
				}
			})

		}
		else {
			if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.isDeleteInvoiceManagerApproval )) && !skipMessageConfirmation ) {
				return app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_CANCEL_SALE])
				.then( (manager)=>{
					Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_SALE, posVC.sale.cashierEmployeeID, manager.employeeID, posVC.getTotalAmount()).then(() => {});
					return posVC.restartSale();

				})

			} else {
				Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.ERASE_SALE, posVC.sale.cashierEmployeeID, null, posVC.getTotalAmount()).then(() => {});
				return posVC.restartSale();
			}
		}
	}
	deleteCustomerFactory() {
	    // if golf customer
      var svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
      if (svcCustomer.isCurrentCustomerExists() ) {
        return svcCustomer.clearCustomer();
      }

      // Delete the customer
      posVC.setCustomer(null)
      let updatedSaleItems = posVC.updatePromotions()

        // for (var i = 0; i < updatedSaleItems.length; i++) {
          // var currentSaleItem = updatedSaleItems[i];
          // Call relevant hooks
          // posVC.saleItemUpdated(currentSaleItem, false);
          // posVC.itemDiscountsHelper.saleItemUpdated(currentSaleItem);
        // }

      return posVC.saleUpdated(updatedSaleItems);
	}
	confirmCustomerDelete() {
		// Ask the user
		app.showAlert({
			header: i18next.t("confirmSaleCustomerDeleteHeader"),
			content: i18next.t("confirmSaleCustomerDelete"),
			continueButtonText: i18next.t("remove"),
			cancelButtonText: i18next.t("cancel")
		}, function () {
      posVC.deleteCustomerFactory();
		}, null);
	}

	removeCompensationOnItem(saleItemToRemoveCompensationFrom){
		let saleItem:PositiveTS.Storage.Entity.SaleItem = posVC.saleItems.filter(item => item.rowNumber == saleItemToRemoveCompensationFrom.rowNumber)[0]
        saleItem.hasCompensation = false;
		saleItem.unitPrice = saleItem.priceBeforeCompensation;
		var jsonData = JSON.parse(posVC.sale.jsondata);
		jsonData.compensationUsed = false;
		posVC.sale.jsondata = JSON.stringify(jsonData);
        posVC.persistSaleItem(saleItem);
	  }


	confirmSaleDiscountDelete () {  //TODO: move to action/mutation
		// Ask the user
		app.showAlert({
			header: i18next.t("removeSaleDiscountTitle"),
			content: i18next.t("removeSaleDiscountMessage"),
			continueButtonText: i18next.t("remove"),
			cancelButtonText: i18next.t("cancel")
		}, function () {
			if (session.pos.useNewPromotions) {
				if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
					posVC.sale.dalpakDiscountRemoved = true;
				}

				posDiscountVC.removeSaleDiscount()
			}
			else {
				// Delete the sale discount
				var sale = posVC.sale;
				sale.discountID = '-1';
				sale.discountName = '';
				sale.saleDiscountAmount = 0;
				sale.discountPercent = '-1';
				sale.discountApprovedByEmployeeID = '-1';

				//TODO: this is probably not needed as it seems like this refers to promotion....
				// if the discount is manual promotion remove it
				if (!posUtils.isNullOrUndefinedOrEmptyString(sale.promotionCode)) {
					posVC.removeFromManualPromotionsIfExist(sale.promotionCode);
					sale.promotionCode = '';
				}


				let result = posVC.updatePromotions()
				posVC.saleUpdated(result)
			}

		}, null);
	}

	toggleAllPickup() {
		var promises = []
		if (posVC.saleItems.length) {
            var setPickupStatusTo = !posVC.saleItems[0].isPickup;
            for (var i=0; i<posVC.saleItems.length; i++ ) {
                  posVC.saleItems[i].isPickup = setPickupStatusTo;
                  posVC.cleanDuplicateItemRowsDueToPickupManipulation(posVC.saleItems[i]);
                  promises.push(posVC.persistSaleItem( posVC.saleItems[i] ));
            }
      	}
		return Promise.all(promises);
	}
	cleanDuplicateItemRowsDueToPickupManipulation(origItem){

		for (var i=0; i<posVC.saleItems.length; i++){
			var isDuplicateRow =
					posVC.saleItems[i].itemCode === origItem.itemCode &&
					posVC.saleItems[i].isPickup === origItem.isPickup &&
					posVC.saleItems[i].rowNumber !== origItem.rowNumber;

			if (isDuplicateRow) {
				origItem.quantity += posVC.saleItems[i].quantity;
				posVC.deleteSaleItem( posVC.saleItems[i] );
			}

		}

	}
	async togglePresent () {
		if (PositiveTS.Service.DutyFree.isDutyFree()) {
			return
		}

		let saleItems

		if(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)) {
			saleItems = posVC.fetchArrayPosVCSaleItems(Pinia.globalStore.multipleSelectedSaleItems)
		} else {
			saleItems = [posVC.saleItemForSelectedRow()]
		}

		for (const saleItem of saleItems) {
			// Search for this row ID in the local array
			// var saleItem = posVC.saleItemForSelectedRow();

			// If no sale item was found, abort!
			if (saleItem == null) {
				console.error('Row is not associated with any sale items');
				return;
			}

			// Toggle the present state
			saleItem.isPresent = !saleItem.isPresent;

			// Persist the sale item
			await posVC.persistSaleItem(saleItem)//.then(posVC.selectNextRowOfSaleItemSelected);
		}

	}
	checkStockForSaleItem () {
		// Search for this row ID in the local array
		let saleItem
		let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)

		if(allowMultipleSaleItemsSelection && Pinia.globalStore.multipleSelectedSaleItems.length != 1) {
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('pos.selectOnlyOneItemToCheckStock'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			});

			return
		}

		if(allowMultipleSaleItemsSelection){
			saleItem = posVC.fetchPosVCSaleItem(Pinia.globalStore.multipleSelectedSaleItems[0])
		}else {
			saleItem = posVC.saleItemForSelectedRow();
		}

		// If no sale item was found, abort!
		if (saleItem == null) {
			console.error('Row is not associated with any sale items');
			return;
		}

		if (session.pos.backofficeIsControl && !session.pos.hasFlights){
			// Go to the item stock dialog
			pNavigator.pushPage('pos-item-stock', i18next.t("pageTitle.posItemStock"), '/pos', {initParams: {
				saleItem: saleItem
			}});
		} else {
Pinia.componentsStore.openComponent( {componentName:"itemInventoryItm", args: [saleItem]});
		}

	}

	getRowNumber() {
		posVC.maxRowNumber++;
		var rowNumber = -1;
		for (var i=0; i< posVC.saleItems.length;  i++) {
			rowNumber = Math.max(posVC.saleItems[i].rowNumber, rowNumber)
		}
		posVC.maxRowNumber = Math.max(posVC.maxRowNumber,rowNumber+1);
		return posVC.maxRowNumber;
	}

	async selectSizeColorForSaleItem (item:Storage.Entity.Item, itemBarcode = null, getItemFromDB = false, quantity = null) {

		if(itemBarcode == null && item == null) return;

		// path to prevent open size and color dialog if no need to chose size and color
		let sizesColors:Array<{size:string, color:string, barcode:string}>
		if (itemBarcode == null) {
			sizesColors = await (new PositiveTS.Storage.Entity.ItemBarcode()).fetchByCodeWithSizeColor(item.code)
		}
		else {
			sizesColors = [{size: itemBarcode.size, color: itemBarcode.color, barcode: itemBarcode.barcode}]
		}

		if (sizesColors.length === 0) {
			sizesColors.push({size:'null',color:'null',barcode: item.code}); //simulate an empty item barcode item
		}

		//create the saleItem
		let itemObj = item;
		if (getItemFromDB) {
			itemObj = Service.AllItems.get(item.code);
		}
		let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(itemObj, sizesColors[0]);
		saleItem.saleID = posVC.sale.id;
		saleItem.rowNumber = posVC.getRowNumber();
		if(quantity != null){
			saleItem.quantity = quantity;
		}

		// Only 1 size and color combination?
		if (sizesColors.length === 1) {

			// Set the size and color for the item to the only size and color combination
			saleItem.color = sizesColors[0].color;
			saleItem.size = sizesColors[0].size;
			saleItem.barcode = sizesColors[0].barcode;

			// Persist the item
			return posVC.persistNewSaleItem(saleItem);


		}

		// Go to the item chooser dialog
		pNavigator.pushPage('pos-item-chooser', i18next.t("pageTitle.posItemChooser"), '/pos', {initParams: {
			callbackBeforeBack: 'posVC.persistNewSaleItem',
			saleItemObject: saleItem.exportToObject()
		}});

	}
	selectDiscountForSaleItem () {
		// Go to the discount dialog
		pNavigator.pushPage('pos-discount', i18next.t("pageTitle.posDiscount"), '/pos', {initParams: {
			discountType: PositiveTS.Storage.Entity.Discount.TYPE_ITEM,
		}});
	}
	getDialogParametersChangePrice(saleItem){
		return {
			header: i18next.t('enterPriceTitle'),
			description: i18next.t('enterPrice'),
			inputPlaceHolder: i18next.t('enterPrice'),
			showCancelButton: true,
			emptyErrorMessage: 'חובה להזין מחיר גדול מ-אפס',
			inputValidator:  (value) => {
				if (!session.isNumber(value) || Number(value) < 0) {
					inputDg.optionsIn.emptyErrorMessage = 'חובה להזין מחיר';
					return false;
				}


				if (Number(value) === 0 && !saleItem.item.allowZeroPrice) {
					inputDg.optionsIn.emptyErrorMessage = 'חובה להזין מחיר גדול מ-אפס';
					return false;
				}

				var hakafaValidation = PositiveTS.Service.Hakafa.HakafaSpecialItemValidation.isItemValid(saleItem,posVC.Specialitems, Number(value) );
				if (!hakafaValidation.valid) {
					inputDg.optionsIn.emptyErrorMessage = i18next.t(`hakafa.${hakafaValidation.msg}`);
					return false;
				}
				return true;
			}
		};
	}

	getDialogParametersSalePctAddition(saleItem){
		return {
			header: 'הזן אחוז',
			description: 'הקלד את אחוז התוספת המבוקש על החשבון',
			inputPlaceHolder: 'הקלד את אחוז התוספת המבוקש על החשבון',
			showCancelButton: true,
			emptyErrorMessage: 'חובה להזין אחוז גדול מ-אפס',
			inputValidator:  (value) => {
				if (!session.isNumber(value) || Number(value) < -100 || Number(value) > 100) {
					inputDg.optionsIn.emptyErrorMessage = 'חובה להזין אחוז בין 100 למינוס 100';
					return false;
				}

				return true;
			}
		};
	}


	async changePriceDialog(saleItem:Storage.Entity.SaleItem){
		// isAllowNamingChange from item
		let itm = saleItem.item
		let result:any;
		let showCancel = this.checkIfShowCancelOnChangePriceDialog(saleItem)

		if (itm.isAllowNameChange && itm.priceZarhan === 0) {
			return new Promise((resolve, reject)=>{
				  Pinia.componentsStore.openComponent( {
					componentName:'genericItemDescription',
			      	args: [saleItem,
			                (x)=>{resolve(x)},
			                (x)=>{reject(x)},
			                showCancel]})
			    })
			.then((response:any) =>{
				saleItem.itemDescription = response.itemDescription;
				return response.itemPrice;
			});
		} else {
			if(!Service.Withdrawal.itemIsCashWithdrawal(saleItem)) {
				let priceDialogTitle = "priceDialog.desc";


				let smartVocher = PositiveTS.Service.SmartVoucher.getSmartVoucherObjectToLoadIfApplicable([saleItem],posVC.Specialitems);
				if (smartVocher) {
					if (smartVocher instanceof PositiveTS.Service.Otot) {
						priceDialogTitle = "otot.priceForLoad"
					} else {
						priceDialogTitle = "priceDialog.priceForLoad"
					}
				}

				result = await Pinia.componentsStore.openComponent( {componentName:"priceDialog", args: [saleItem.unitPrice,saleItem,saleItem.item.allowZeroPrice, priceDialogTitle, null, showCancel]});
			} else {
				result = await Pinia.componentsStore.openComponent( {
													componentName:'priceDialog',
													args: [
														saleItem.unitPrice,
														saleItem,
														saleItem.item.allowZeroPrice,
														"withdrawal.pickAmount",
														Service.Withdrawal.validateWithdrawalAmount
													]});
				this.isSaleCashWithdrawal = true;
				Pinia.globalStore.setWithdrawalSale(true);
			}
			return result.price;

		}
	}
	async changeUnitPriceForSaleItem () {
		let saleItems

		if(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)) {
			saleItems = posVC.fetchArrayPosVCSaleItems(Pinia.globalStore.multipleSelectedSaleItems)
		} else {
			saleItems = [posVC.saleItemForSelectedRow()]
		}

		for (const saleItem of saleItems) {
			if (saleItem == null) {
				console.error('Row is not associated with any sale items');
				console.error(JSON.stringify(saleItem))
				return;
			}

			if(Service.MultipassLoadBudget.posHasMultipassLoad() && Service.MultipassLoadBudget.getLoadBudgetItemCode() == saleItem.itemCode) {
				app.showAlert({
					header: i18next.t("error"),
					content: i18next.t("multipass.changePriceValidation"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				},
				null, null);
				return;
			}
			if(saleItem.isPNRItem) {
				app.showAlert({
					header: i18next.t("error"),
					content: i18next.t("elal.errors.cannotChangePricePNRItem"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				},
				null, null);
				return;
			}

			let customer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.sale.payments, posVC.sale.items).getCurrentSelectedCustomer()
			let hakafaSpecialItem = PositiveTS.Service.Hakafa.HakafaSpecialItemValidation.getHakafaSpecialItem(posVC.Specialitems);

			let result = null;

			if(hakafaSpecialItem && saleItem.barcode == hakafaSpecialItem.code && !customer?.is_tamash_customer){
				result = await Pinia.componentsStore.openComponent( {componentName:"hakafaInvoicesDialog", args: []});
			} else {
				result = await Pinia.componentsStore.openComponent( {componentName:"priceDialog", args: [saleItem.unitPrice,saleItem,saleItem.item.allowZeroPrice]})
				result = result.price
			}


			saleItem.unitPrice = result;

			let target_name =  `פריט "${saleItem.itemDescription}" שונה ממחיר ${saleItem.originalUnitPrice} למחיר ${saleItem.unitPrice}`
			Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.PRICE_CHANGED, session.pos.employeeID,null, saleItem.unitPrice, target_name).then(() => { });

			posVC.persistSaleItem(saleItem);
		}


	}
	selectDiscountForSale () {

		if (Pinia.globalStore.isSaleGiftCard){
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("cannotAddDiscountOnSpecialItemGiftCard"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			null, null);
			return;
		}
		// Go to the discount dialog
		pNavigator.pushPage('pos-discount', i18next.t("pageTitle.posDiscount"), '/pos', {initParams: {
			discountType: PositiveTS.Storage.Entity.Discount.TYPE_SALE
		}});
	}

	openCustomerDialog(customerType){
		if (customerType != 'hakafa' && Pinia.globalStore.isSaleGiftCard) {
			app.showAlert({
				header: i18next.t("error"),
				content: i18next.t("failedOpenCustomerClubSpecialItemGiftCardExists"),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			},
			null, null);
			return;
		}
	 switch(customerType) {
		case 'positive':
			if (Pinia.globalStore.isDeliverySale) {
				Pinia.componentsStore.openComponent( {
					componentName: "positiveDeliveryCustomerClub",
					args: []
				});
			} else if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
				Pinia.componentsStore.openComponent( {
					componentName: "clubMemberSelfServiceDialog",
				});
			} else {
				Pinia.componentsStore.openComponent( {
					componentName: "positiveCustomerClub",
					args: []
				});
			}
			break;
		case 'hakafa':
			if (jsonConfig.getVal(jsonConfig.KEYS.mizdamenOrHakafaMode) && jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
				Pinia.componentsStore.openComponent( { componentName: "clubMemberSelfServiceDialog", args: ['hakafa'] });
			} else {
				Pinia.componentsStore.openComponent( {componentName:"vueDialog", args: [Components.HakafaCustomerDialog.componentName]})
			}
			break;
		case PositiveTS.Service.ValueCardService.clubName:
			// Pinia.componentsStore.openComponent( {componentName:"valuecardCustomerClub", arguments: []});
			if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
				Pinia.componentsStore.openComponent( {
					componentName: "clubMemberSelfServiceDialog",
					args: [
						PositiveTS.Service.ValueCardService.clubName
					]
				});
			}else{
				Pinia.componentsStore.openComponent( {componentName:"valuecardCustomerClub", args: []});
			}
			break;
		case PositiveTS.Service.SimplyClubService.clubName:
			let customer = null;
			if (PositiveTS.Service.SimplyClubService.isCustomerExistsOnSale(posVC.sale)){
				customer = PositiveTS.Service.SimplyClubService.getCustomerOnSale(posVC.sale)
			}
			Pinia.componentsStore.openComponent( {componentName:"simplyclubCustomerClub", args: [customer]});
			break;
		case 'bril':
			Pinia.componentsStore.openComponent( {componentName:"superFriendsCustomerClub", args: []});
			break;
		case 'dts':
			if(posVC.saleItems.length == 0){
			Pinia.componentsStore.openComponent( {componentName:"dtsCustomerClub", args: []})
			}
			else{
				app.showAlert({
					header: i18next.t("error"),
					content: i18next.t("dtsCustomerClub.itemsExistsInSale"),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				  }, null,null);
			}
			break;
		case Shared.Constants.MultipassPolicePoints.CLUB_CODE:
		case Shared.Constants.MultipassPolicePoints.CLUB_CODE.toLowerCase():
			Pinia.componentsStore.openComponent( {componentName:"multipassPoliceCustomerPicker", args: []});
			break;
		case 'priority':
			Pinia.componentsStore.openComponent( {componentName:"priorityCustomerClub", args: []});
			break;
    	default:
        throw new Error('No customer club ' + customerType);
		}
	}
	selectCustomerClubForSale() {
		var custSvc = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
		var externalClub = custSvc.getExternalClubDialogName()
		if ( externalClub){
				posVC.openCustomerDialog( externalClub );
			return;
	  }


    var memberOfClubs = jsonConfig.getVal(jsonConfig.KEYS.memberOfClubs);
    if (memberOfClubs.length === 0){
      return;
    }
    else if (memberOfClubs.length === 1) {
      posVC.openCustomerDialog(memberOfClubs[0] );
    } else {
      Dialogs.CustomerClubSelectDialog.open(function(clubId){posVC.openCustomerDialog(clubId);})
    }
	}

	removeFromManualPromotionsIfExist (promotionCode) {
		posVC.allowedManualPromotions = $.grep(posVC.allowedManualPromotions, function (currentPromotion) {
			return (<any>currentPromotion).code != promotionCode;
		});
	}
	checkIfNeedToOpenManualPromotionSelector () {

		if (posVC.ignorePromotions) {
			return Promise.resolve(false);
		}
		if (session.pos.useNewPromotions) {
			let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()
			let promotionsEngine = new Promotions.NewPromotionsEngine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId,customer);
			return promotionsEngine.hasMoreManualPromotionToChose(posVC.saleItems);
		}
		else {
			let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()
			let promotionsEngine = new Promotions.Engine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId, customer);
			//promotionsEngine.setManualPromotions(posVC.allowedManualPromotions);

			var saleItemsWithNoDiscounts = [];
			for (var i = 0; i < posVC.saleItems.length; i++) {
			if (!saleItemHelper.doesItemContainDiscountOrPromotion(posVC.saleItems[i])) {
					saleItemsWithNoDiscounts.push(posVC.saleItems[i]);
				}
			}

			let result = promotionsEngine.hasMoreManualPromotionToChose(posVC.sale, saleItemsWithNoDiscounts, false)
			if (result) {
				return result;
			}
			else {
				return promotionsEngine.hasMoreManualPromotionToChose(posVC.sale, posVC.saleItems, true);
			}
		}
	}

	async openPaymentScreen () {
		PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalAmount()))

		let openIt = () => {
			// Flag that we are calling the payment screen from the pos VC
			posPaymentVC.fromPos = true;



			posVC.initPosPayment = true;
			// Go to the payment dialog
			//pNavigator.pushPage('pos-payment', i18next.t("pageTitle.posPayment"), pNavigator.currentURL, null);
			posPaymentVC.open();

		}

		try {
			await PositiveTS.Service.ValuecardPointsDialog.openDialogIfRequired();
			let dialogResult = await PositiveTS.Service.SimplyClubService.openDialogIfRequired(posVC.sale, posVC.saleItems);
			if (dialogResult.isToContinue === false) {
				return;
			}

			if (session.pos.brillConfig) {
				let jsondata = JSON.parse(posVC.sale.jsondata);

				if (jsondata.customer && Service.Brill.isBrillClub(jsondata.customer.clubName)) {
				  let brillConfig = JSON.parse(session.pos.brillConfig);

				  if (brillConfig.promotion_limits) {
					let customerKey = Service.Brill.getCustomerGroupKey(jsondata.customer);
					let customerPromotionsLimit = brillConfig.promotion_limits[customerKey];

					if (customerPromotionsLimit && Service.Brill.saleHasCustomerGroupPromotion(jsondata, customerKey)) {
					  let saleTotalAmount = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments).totalAmount;
					  if (((jsondata.customer.total_purchase || 0) + saleTotalAmount) > customerPromotionsLimit) {

						app.showAlert({
						  header: i18next.t("payAttention"),
						  content: i18next.t('superFriendsCustClub.customerPromotionAmountExeeded', {
							promotionsLimit: customerPromotionsLimit, totalPurchases: jsondata.customer.total_purchase || 0, allowedAmount: session.fixedFloat(customerPromotionsLimit - (jsondata.customer.total_purchase || 0))
						  }),
						  continueButtonText: i18next.t("back"),
						  hideCancelButton: true,
						});
						return;
					  }

					}
				  }
				}
			  }
			  if (PositiveTS.Reachability.isOnline && new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems).shouldOpenCustomerPointsDialog()) {
				let isToContinue = await Pinia.componentsStore.openComponent( {componentName:"customerPointsDialog", args: []});
				if (!isToContinue) {
					return;
					}
				}

			if (session.pos.useNewPromotions) {
				let res = await posVC.getPotentialSalePromotionMessages();
				if (res) {
					openIt();
					}
				}
				else {
					openIt()
				}

			} catch(e){
				if (e.message === "User canceled"){return;}
				console.error(e);
				app.hideLoadingMessage();
				return app.promiseShowAlert({
					header: i18next.t("error"),
					content: e.message,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				})
			}

	}

	getAllAvilableManualPromotions () {
		let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()
		var promotionsEngine = new Promotions.Engine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId);

		return promotionsEngine.getAvilableManualPromotionsForSale(posVC.sale, posVC.saleItems);
	}

	getCodesArrayFromPromotions (promotions) {
		return $.map(promotions, function (currentPromotion) {
			return currentPromotion.code;
		});
	}

	removeCustomerOnlyManualPromotions () {
		posVC.allowedManualPromotions = $.grep(posVC.allowedManualPromotions, function (promotion) {
			return (<any>promotion).isClubMembersOnly == 0;
		});
	}

	getAllAllowedManualPromotionsCodes () {
		return posVC.getCodesArrayFromPromotions(posVC.allowedManualPromotions);
	}

	isSamePromotionsAsAllowedManualPromotions (promotions) {
		var allowedManualPromotionsCodes = posVC.getAllAllowedManualPromotionsCodes();
		var promotionsCodes = posVC.getCodesArrayFromPromotions(promotions);

		return posUtils.isSameArrays(allowedManualPromotionsCodes, promotionsCodes);
	}
	/*
	 * Description:
	 * 	Bug in slow workstations, might cause lines to be created without
	 *  SalesPerson,
	 *  This function would populate all missing salesPersons with default last found
	 * Usage:
	 * 	Run just before click "Payment" in  goToPaymentScreen
	 */
	ensureAllLinesHasSalespersonEmployee() {
	    var aryPopulateSalesPersonEmp = [];
	    var sDefaultsalespersonEmployeeID = '';
	    var sDefaultsalespersonEmployeeName = '';

	    // prepair for list of items to populate
	    for (var i = 0; i < posVC.saleItems.length; i++) {
	      // Get the sale item
	      var saleItem = posVC.saleItems[i];



	      // Check whether this is the sale item
	      if ( posUtils.isNullOrUndefined( saleItem.salespersonEmployeeID ) ) {
	        aryPopulateSalesPersonEmp.push(i);
	      } else {
	        sDefaultsalespersonEmployeeID = saleItem.salespersonEmployeeID;
	        sDefaultsalespersonEmployeeName = saleItem.salespersonEmployeeName;
	      }
	    } // end for


	    // If already sales persone found for one of the rows
	    if (sDefaultsalespersonEmployeeID) {
	      // populate missing sales person, with the last one found in lines
	      for (var i = 0; i < aryPopulateSalesPersonEmp.length; i++) {
	           // Get the sale item
	           var iIndex = aryPopulateSalesPersonEmp[i];
	          var saleItem = posVC.saleItems[iIndex];
	          saleItem.salespersonEmployeeID = sDefaultsalespersonEmployeeID;
	          saleItem.salespersonEmployeeName = sDefaultsalespersonEmployeeName;
	        }

	    }
	}

	blockItemInMinusWithMultipleSalespersons() {
		if (Boolean(session.pos.parameterBlockItemInMinusWithMultipleSalespersons)) {
			let hasItemsInMinus = (posVC.saleItems.filter(function(item) {return (item.quantity < 0);}).length > 0);
			let hasMultipleSalesPersons = (_.uniq(posVC.saleItems.map(function(item) { return item.salespersonEmployeeID; })).length > 1);
			if (hasItemsInMinus && hasMultipleSalesPersons) {
				app.showAlert({
          header: i18next.t("error"),
          content: i18next.t("itemInMinusShouldBeOnlyOneSalesPerson"),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        }, null,null);
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	}

	async checkIfCustomerNeededInSale() {
		if(this.getTotalAmount() >= 5000) {
			let warningText = session.pos.isRoshemet ? i18next.t("pos.bigSaleLawWarningRoshemet") : i18next.t("pos.bigSaleLawWarning")
			let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
			let customerExists = false
			if(session.pos.isRoshemet){
				if(!posUtils.isNullOrUndefinedOrEmptyString(Service.DedicatedTo.getCurrentDedicatedTo(posVC.sale))){
					customerExists = true
				}
			}else{
				if(svcCustomer.isCurrentCustomerExists()){
					customerExists = true
				}
			}
			if(!customerExists) {
				if(jsonConfig.getVal(jsonConfig.KEYS.forceCustomerOnBigSales)) {
					await app.promiseShowAlert({
						header: i18next.t("pos.payAttention"),
						content: warningText,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true});
					return true;
				} else {
					let res = await app.promiseShowAlert({
						header: i18next.t("pos.payAttention"),
						content: warningText,
						continueButtonText: i18next.t("pos.backToPos"),
						hideCancelButton: false});
					if(res == "continue") {
						return true;
					} else {
						return false;
					}
				}
			}
		}
		return false;
	}

	async goToPaymentScreen (forceSkipDedicatedTo = false) {
		let saleNeedsCustomer = await this.checkIfCustomerNeededInSale()
		if(saleNeedsCustomer) {
			return ;
		}

		if (PositiveTS.Service.MobilePhoneRepair.hasMobilePhoneRepairModule()) {
			PositiveTS.Service.MobilePhoneRepair.goToPaymentScreen(posVC.sale)
			return
		}


		if (posVC.blockItemInMinusWithMultipleSalespersons()) {
			return;
		}

		// Twik bug of empty sales persones
		posVC.ensureAllLinesHasSalespersonEmployee();

		// Before go to payment screen we check about manual promotions
		if (!forceSkipDedicatedTo && jsonConfig.getVal(jsonConfig.KEYS.alwaysGetDedicatedTo) &&
				posUtils.isNullOrUndefinedOrEmptyString(Service.DedicatedTo.getCurrentDedicatedTo(posVC.sale))) {
			let svcCustomerClub = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
			if (!svcCustomerClub.isCurrentCustomerExists()) {
				await Service.DedicatedTo.setDedicatedTo('שם לקוח', 'יש להזין שם לקוח להזמנה', 'שם לקוח')
			}
		}

		const isPickupEnabled = Service.Pickup.isPickupEnabled()
		let needToChoseManualPromotions = await posVC.checkIfNeedToOpenManualPromotionSelector()
		if (needToChoseManualPromotions) {
			let openPaymentScreenFunction = isPickupEnabled ? Service.Pickup.goToPaymentScreen : posVC.openPaymentScreen
			return posVC.openManualPromotionsDialogAndGotoPaymentScreen(openPaymentScreenFunction)
		} else {
			if (isPickupEnabled){
				return Service.Pickup.goToPaymentScreen()
			}else{
				return posVC.openPaymentScreen()
			}
		}

	}

	async openManualPromotionsDialogAndGotoPaymentScreen(openPaymentScreenFunction = posVC.openPaymentScreen) {
		try {
			let promotions = posVC.getAllAvilableManualPromotions()

			let selectedPromotions = await promotionsSelectorDg.open(promotions, posVC.getAllAllowedManualPromotionsCodes())

			if (session.pos.useNewPromotions) {
				posVC.allowedManualPromotions = selectedPromotions;
			}
			else {
				if (posVC.isSamePromotionsAsAllowedManualPromotions(selectedPromotions)) {
					return '';
				}

				// remove sale promotion before calcuating best promotions for sale and items
				PositiveTS.Helper.SaleHelper.removeSalePromotionFromSale(posVC.sale);

				// create instance for promotion engine
				let [hasCust,promoTypes,custGroupId,customer] = posVC.getCustomerParams()
				var promotionsEngine = new Promotions.Engine(hasCust,posVC.ignorePromotions,promoTypes,custGroupId);

				// Add Manual selected promotions to the engine
				promotionsEngine.setManualPromotions(selectedPromotions);

				let bestManualPromotionsVariation = await promotionsEngine.filterChoosedManualPromotions(posVC.sale, posVC.saleItems)

				posVC.allowedManualPromotions = bestManualPromotionsVariation;

			}

			let updatedSaleItems = posVC.updatePromotions();

			posVC.saleUpdated(updatedSaleItems);

			app.showAlert({
				header:  i18next.t("pos.promotions"),
				content: i18next.t("pos.successfullyUpdated"),
				continueButtonText:  i18next.t("pos.continueToPay"),
				continueButtonAttribute: 'data-approve-button',
				cancelButtonText: i18next.t("pos.backToInvoice"),
				hideCancelButton: false
			}, function () {
					return openPaymentScreenFunction();
			}, null);
		}
		catch(err) {
			console.error(err);
			return openPaymentScreenFunction();
		}

	}
	async parameterRequireSalespersonHandler (saleItem = null, selectForAllSale = false, showBackButton = false) {
		if(Service.SmartVoucher.isSpecialItemCode(saleItem?.itemCode)) {
			selectForAllSale = true;
		}

		switch (session.pos.parameterRequireSalesperson) {
			case PositiveTS.Storage.Entity.Pos.REQUIRE_MANDATORY:

				$('#pos-item-actions-sales-person').removeAttr('disabled');

				if (posUtils.isNullOrUndefinedOrEmptyString(posVC.sale.salespersonEmployeeName)){

				  await posVC.selectSalesPersonForSaleItem([saleItem], selectForAllSale, showBackButton);

				  return true;
				}

				break;

			case PositiveTS.Storage.Entity.Pos.REQUIRE_OPTIONAL:
				$('#pos-item-actions-sales-person').removeAttr('disabled');
				break;

			case PositiveTS.Storage.Entity.Pos.REQUIRE_DISABLED:
				$('#pos-item-actions-sales-person').attr('disabled', 'disabled');
				break;

			default:
				$('#pos-item-actions-sales-person').removeAttr('disabled');
				break;
		}
		return false;
	}

	selectSalesPersonForSaleItemClicked () {
		if (session.pos.parameterRequireSalesperson == PositiveTS.Storage.Entity.Pos.REQUIRE_DISABLED) {
			return;
		}

		let saleItems

		if(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)) {
			saleItems = posVC.fetchArrayPosVCSaleItems(Pinia.globalStore.multipleSelectedSaleItems)
		} else {
			saleItems = [posVC.saleItemForSelectedRow()];
		}

		return posVC.selectSalesPersonForSaleItem(saleItems);
	}
	async selectSalesPersonForSaleItem (saleItems, selectForAllSale = false, showBackButton = false) {

		// If no sale item was found, abort!

		if (posUtils.isNullOrUndefined(saleItems) && !selectForAllSale) {
			console.error('Row is not associated with any sale items');
			return;
		}

		if (jsonConfig.getVal(jsonConfig.KEYS.selectSalesPersonByCode)){
			await Pinia.componentsStore.openComponent( {componentName:"salesPersonDialog", args: [saleItems, selectForAllSale, showBackButton]});
		}else{
			// Go to the sales-person dialog
			// The file name is posSalesPerson.ts
			await Pinia.componentsStore.openComponent( {componentName:"posSalesPersonDialog", args: [saleItems, selectForAllSale, showBackButton]});
		}

	}
	// --------------------------------------------------------------------
	// Search Field Related
	// --------------------------------------------------------------------
	addItemByBarcode (barcode) { //async

		// Initialize an Item
		var item = new PositiveTS.Storage.Entity.Item();

		// Perform the search
		return item.searchByBarcode(barcode)
		.then(function (itemsAndBarcodes) {
			// console.dir(itemsAndBarcodes);
			// Are there any items in the result?
			if (itemsAndBarcodes.length === 0) {
				//TODO: add weight server code here!!!

				// There are no item, maybe the query was not a barcode, so let autoSuggest take care of it
				$('#pos-search-field').val(barcode);

				// We are done!
				return Promise.resolve();
			}

			// Pick the first item and get the item and its barcode
			var item = itemsAndBarcodes[0].item;
			var itemBarcode = itemsAndBarcodes[0].itemBarcode;

			// Create new sale item entity
			var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
			saleItem.saleID = posVC.sale.id;
			saleItem.rowNumber = posVC.getRowNumber();

			// Add it to sale
			return posVC.persistNewSaleItem(saleItem);
		});
	}

	async addItemByBarcodeOrByCode(searchQuery, isExact, dontPerformDuplicateSearch = false, silent = false) {
		try {
			if (Boolean(session.store.allowDuplicateBarcodes) && dontPerformDuplicateSearch === false) {
				let dupBarcode = new PositiveTS.Storage.Entity.DuplicateBarcode();
				const item = await dupBarcode.openDuplicateDialogIfNeeded(searchQuery);
				if (item === null) {
					let itemFound = await PositiveTS.Service.Scale.tryToGetFromScaleServerAndAddItem(searchQuery);
					if (!itemFound) {
						if(!silent){
							posVC.errorBeep(50, 'square', 200);
						}

						if (jsonConfig.getVal(jsonConfig.KEYS.createItemsFromPos)) {

							let answer = await app.promiseShowAlert({
								content: i18next.t('addItemDialog.itemNotFoundCreate', { code: searchQuery }),
								continueButtonText: i18next.t("ok"),
								cancelButtonText: i18next.t("cancel")
							});

							if (answer == 'continue') {
								let item = await Pinia.componentsStore.openComponent( { componentName: "addItemDialog", args: [searchQuery] });

								if (posUtils.isDefined(item)) {
									return await posVC.selectSizeColorForSaleItem(item);
								}
							} else {
								return;
							}
						} else {
							return Promise.reject(new Error(i18next.t("NO_ITEM_OR_BARCODE_FOUND") + searchQuery));
						}
					}
				}

				if (item == app.userCancelledGlobalMessage) {
					return; //do nothing
				}
				return await posVC.selectSizeColorForSaleItem(item);
			}

			const itemModel = new PositiveTS.Storage.Entity.Item();
			const itemsAndBarcodes = await itemModel.searchByBarcode(searchQuery);
			// Are there any items in the result?
			if (itemsAndBarcodes.length === 0) {
				let items;
				if (Boolean(isExact)) {
					items = await PositiveTS.Storage.Entity.Item.searchByCode(searchQuery);
				}
				else {
					items = await itemModel.search(searchQuery);
				}

				if (items.length !== 1) {
					for (var i = 0; i < items.length; i++) {
						if (items[i].item.code === searchQuery) {
							return await posVC.selectSizeColorForSaleItem(items[i].item, null, true);
						}
					}

					const itemWeightFound = await PositiveTS.Service.Scale.tryToGetFromScaleServerAndAddItem(searchQuery);
					if (!itemWeightFound) {
						$(document).unbind('keypress');
						posVC.errorBeep(50, 'square', 200);

						if (jsonConfig.getVal(jsonConfig.KEYS.createItemsFromPos)) {
							let answer = await app.promiseShowAlert({
								content: i18next.t('addItemDialog.itemNotFoundCreate', { code: searchQuery }),
								continueButtonText: i18next.t("ok"),
								cancelButtonText: i18next.t("cancel")
							});

							try {
								if (answer == 'continue') {
									let item = await Pinia.componentsStore.openComponent( { componentName: "addItemDialog", args: [searchQuery] });
									if (posUtils.isDefined(item)) {
										return await posVC.selectSizeColorForSaleItem(item);
									}
								} else {
									return;
								}
							} finally {
								$(document).on('keypress', posVC.onKeyPress);//todo check if need it
							}

						} else {
							return Promise.reject(new Error(i18next.t("NO_ITEM_OR_BARCODE_FOUND") + searchQuery));
						}
					}
				} else {
					return await posVC.selectSizeColorForSaleItem(items[0].item, null, true);
				}
			}
			else {
				// Bitch ass nigga
				// Pick the first item and get the item and its barcode
				let item: PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
				var itemBarcode = itemsAndBarcodes[0].itemBarcode;

				// Create new sale item entity
				var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
				saleItem.saleID = posVC.sale.id;
				saleItem.rowNumber = posVC.getRowNumber();
				return await posVC.persistNewSaleItem(saleItem);
			}
		}catch(error){
			console.error(error);
		}
	}

	errorBeep(freq = 50, type = 'square', speed = 200, noteLength = 0.5, repeat = 3) {


		let context = new AudioContext();
		let t = context.currentTime

		for (var i = 0; i < repeat; i++) {
			let o = context.createOscillator();
			// 1 second divided by number of beats per second times number of beats (length of a note)
			let playlength = 1/(speed/60) * noteLength;
			o.type = type as OscillatorType;
			o.frequency.value = freq;//arrNotes[i].frq;
			o.start(t);
			o.stop(t + playlength);
			t += playlength;
			o.connect(context.destination);
		}

	  }
	// --------------------------------------------------------------------
	// Sale Total
	// --------------------------------------------------------------------
	//sync
	// updateSaleTotal () {
	// 	// Set the sale total
	// 	let total = session.fixedFloat(posVC.getTotalAmount());
	// 	PositiveTS.VueStore.commit('saleTotalUpdated',total);

	// 	// $('#pos-sale-info-quantity').html(posVC.getTotalFormatedQuantity());
	// }
	getTotalAmount ():number {
		var totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
		return session.fixedFloat(totals.totalAmount);
	}
	getHtmlFormatedQty(totalQty, totalPickupQty) {
		return totalQty +' /<span style=\'color: blue\'> ' +
			totalPickupQty +
			'</span>';
	}
	getTotalFormatedQuantity():any {
		if ( PositiveTS.Service.DutyFree.isDutyFree() ) {
			var totalQty = posVC.getTotalQuantity();
			var totalPickupQty = saleItemHelper.getSaleItemsTotalQuantity( posVC.saleItems , true);
			return posVC.getHtmlFormatedQty(totalQty, totalPickupQty);
		} else {
			return posVC.getTotalQuantity();
		}
	}
	getTotalQuantity () {
		return saleItemHelper.getSaleItemsTotalQuantity( posVC.saleItems );
	}
	getTotalLeftToPay(){
		var totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
		return session.fixedFloat(totals.totalAmount - totals.totalPaid);
	}

	openCurrencyConversionCalculator () {
Pinia.componentsStore.openComponent( {componentName:"currencyConversionCalculator", args: []})
	}

	isCurrentSaleComplimentaryDiscountSale() {
		if (!this.sale) {
			return false;
		}

		let data = JSON.parse(this.sale.jsondata);
		return data.useNewPromotions && data.discounts &&
		data.discounts.filter(discount => discount.promotionCode == Storage.Entity.FlightsDiscountCodes.complimentary).length;
	}

	async removeAllRemoveableItems() {
		let saleItemsToDelete = posVC.saleItems.filter(saleItem => Pinia.globalStore.canDeleteItem(saleItem));
		await PositiveTS.Service.HoldSale.cancelBonItemsIfRequired(saleItemsToDelete);

		for (let saleItem of saleItemsToDelete) {
			saleItem.bonPrintedAt = null;
			posVC.confirmSaleItemDelete(saleItem, true);
		}
	}

	checkIfShowCancelOnChangePriceDialog (saleItem: Storage.Entity.SaleItem) {
		let showCancel = true
		if (this.checkIfCurrentSaleIsPaymentOfMobilePhoneRepair(saleItem)){
			showCancel = false
		}

		return showCancel
	}

	checkIfCurrentSaleIsPaymentOfMobilePhoneRepair (saleItem: Storage.Entity.SaleItem = null) {
		const mobilePhoneRepairItemCode =  jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairItemCode)
		if (!saleItem){
			saleItem = posVC.saleItems.find((item) => item.itemCode == mobilePhoneRepairItemCode)
		}

		return posVC.sale.futureOrderId && saleItem && saleItem.itemCode == mobilePhoneRepairItemCode
	}
}}}}

declare var posVC:PositiveTS.Application.Controllers.PosViewController;
posVC = new PositiveTS.Application.Controllers.PosViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.PosViewController = posVC;
