module PositiveTS {
    export module Application {
    export module Controllers {
    export class CashierViewController extends AbstractViewControllerTS {

		cashiers = {};
		// --------------------------------------------------------------------
		// Controller Life-Cycle
		// --------------------------------------------------------------------
		init(options) {
			// Initialize the table view
			var tableView = $('#cashier-tableview').tableview();
			tableView.setNumberOfVisibleRows(5);
			tableView.empty();
		}
		resumeActions(options) {
			var aThis = cashierVC;
			
			// Bind the continue button to its listener
			$('#cashier-continue').click(aThis.continue);
			$('#cashier-exit').click(aThis.exitCashier);
		}
	
		async resume(options) {
			let aThis = cashierVC;
			try{
				if (session.pos == null) {
					pNavigator.back();
					console.error('No POS defined in session, cannot pick cashier');
					return;
				}
				
				
				let employees = await Service.Cashier.getAll();

				var tableView = $('#cashier-tableview').tableview();
	
				// Empty the table so we can be sure we are displaying only the latest results
				tableView.empty();
	
				// Also empty the local cashiers objects
				aThis.cashiers = {};
	
				// Check that there are cashiers in the results
				if (employees.length === 0) {
					// The table is already empty. We are done!
					return;
				}
	
				// If has flight, get only allowed crew cashiers
				if ((session.pos.hasFlights || jsonConfig.getVal(jsonConfig.KEYS.isIsrairPos)) &&
				
				Pinia.flightsStore.leg && 
				Pinia.flightsStore.leg.authorizes_employees) {
						var allowedEmployeeIds = Pinia.flightsStore.leg.authorizes_employees.map(employee => parseInt(employee.employeeID));
						employees = employees.filter(employee => allowedEmployeeIds.includes(parseInt(employee.employeeID)));
				}
	
				// Iterate over the results
				for (var i = 0; i < employees.length; i++) {
					// Add a row with the cashier name
					var theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
						employees[i].name
					]));
					aThis.cashiers[theRow.attr('id')] = employees[i];
				}
				
			}catch(err){
				app.showAlert({
					header: i18next.t('error'),
					content: 'Fetching cashiers failed. Please contact support.',
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
				console.error('Fetching cashiers failed. Please contact support.');
				PositiveTS.Service.Logger.error(err);
			}
			aThis.resumeActions(options);
			
		}
		stop() {
			// Unbind the listeners from the resume function
			$('#cashier-continue').unbind('click');
			$('#cashier-exit').unbind('click');
		}
	
		getSelectedCashier() {
			var aThis = cashierVC;
	
			// Get the selected row
			var theRow = $('#cashier-tableview').tableview().getSelectedRow();
	
			// If there are no selected rows, finish
			if (theRow.length === 0) {
				return null;
			}
	
			// Get the employee entity from the local cashiers object
			return aThis.cashiers[theRow.attr('id')];
		}
	
		doesContainPosInSession() {
			// Check that there is a POS in session
			if (session.pos == null) {
				// Tell the user
				app.showAlert({
					header: i18next.t('error'),
					content: 'No POS defined in session, cannot pick cashier.',
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
				console.error('No POS defined in session, cannot pick cashier');
	
				// Exit!
				return false;
			} else {
				return true;
			}
		}
	
		// --------------------------------------------------------------------
		// Listeners
		// --------------------------------------------------------------------
		async exitCashier() {
			try{
				let aThis = cashierVC;
				// Check that there is a POS in session
				if (!aThis.doesContainPosInSession()) {
					return;
				}

				await Service.Cashier.removeCurrent();
				pNavigator.back();
			}catch(err){
				app.showAlert({
					header: i18next.t('error'),
					content: 'Failed persisting POS.',
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, function () {
					// Go back
					pNavigator.back();
				}, null);
				console.error('Failed persisting POS');
			}
		}
	
		async continue() {
			try{
				let aThis = cashierVC;
	
				// Get selected employee from the view
				var employee = aThis.getSelectedCashier();
		
				if (employee === null) {
					return;
				}
		
				// Check that there is a POS in session
				if (!aThis.doesContainPosInSession()) {
					return;
				}
				await Service.Cashier.setCurrent(employee.employeeID,employee.name)
				pNavigator.back();
			}catch(err){
				app.showAlert({
					header: i18next.t('error'),
					content: 'Failed persisting POS.',
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, function () {
					// Go back
					pNavigator.back();
				}, null);
				console.error('Failed persisting POS');
			}
			

		}
	}
}}}

declare var cashierVC:PositiveTS.Application.Controllers.CashierViewController;
cashierVC = new PositiveTS.Application.Controllers.CashierViewController();
PositiveTS.Application.Controllers.instances.CashierViewController = cashierVC;
