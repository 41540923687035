module PositiveTS {
export module Service {
  
export module TaxInvCredit {
  interface validate_object {
    valid: boolean, 
    errors: Array<string>
  }

  // Should show "credit invtax enabled"
  export function isTaxInvCreditable (sale, itemsAndSalesToCredit): validate_object {
    let result = {valid: false, errors: []};

    if (_isTaxInvDocument(sale)) {
      if (itemsAndSalesToCredit.itemsToCredit.length <= 0) {
        result.errors.push(i18next.t('isCreditable.canNotCreditSaleNoItemsAvailableToCredit'));
      }

      if (hasLinkedReceipt(sale)) {
        result.errors.push(i18next.t('isCreditable.canNotCreditSaleTaxInvoiceLinkedToReceipt'));
      }

      result.valid = result.errors.length === 0;
    }

    return result;
  }

  export function creditTaxInvItem(aThis){
      aThis.creditSaleItem();
  }

  function _isTaxInvDocument(sale):boolean{
    return sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV;
  }

  export function hasLinkedReceipt(sale):boolean{
    return Boolean( JSON.parse(sale.jsondata)[PositiveTS.Service.TaxInv.hasLinkedReceiptJsondataKey] );
  }

}}}
