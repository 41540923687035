module PositiveTS {
	export module Application {
	export module Controllers {
	export class PosPastInvoicesDisplayItemsViewController extends AbstractViewControllerTS {

	// Array of instances of SaleItem for the current sale
	saleItems = []

	rowIdSaleItem = {}

	// Map of - RowId vs item description
	rowIdItemDesc = {}

	// Map of - RowId vs item quantity stock
	rowIdQuantityStock = {}

	// Map of - RowId vs salesPerson
	rowIdSalesPerson = {}

	saleProxyOptions:any = {}

	sale:Storage.Entity.Sale;

	backTo:string = 'pos-past-invoices'

	// --------------------------------------------------------------------
	// Controller Life-Cycle
	// --------------------------------------------------------------------
	init(options) {
		let aThis = posPastInvoicesDisplayItemsVC;

		$('#pos-past-invoices-display-items-back').click(aThis.back);

		$('#pos-past-invoices-print-item-button').click(this.printReplacementSlip);

		

		// Initialize the tableview
		let tableView = $('#pos-past-invoices-items-tableview').tableview();

		if(Pinia.globalStore.mobileLayout){
			tableView.setNumberOfVisibleRows(9);
		}
		else{
			tableView.setNumberOfVisibleRows(5);
		}
		// Set the number of visible rows

		// Set a listener for row select
		tableView.attachSelectRowListener('posRowSelectListener', function (theRow) {
			if ($(this).data('itemCode') !== null && $(this).data('itemCode') !== undefined) {
				let rowsToSelect = $('#pos-past-invoices-items-tableview tr').filter(function() {
				  return $(this).data('itemCode') == theRow.data('itemCode');
				});

				$('#pos-past-invoices-items-tableview tr').removeClass('selected');
				rowsToSelect.addClass('selected');

				// Call relevant hooks
				aThis.updateSaleItemInformation();
			}
		});
	}
	updateSaleItemInformation(){
		let aThis = posPastInvoicesDisplayItemsVC;

		//Get the table view
		let tableView = $('#pos-past-invoices-items-tableview').tableview();

		//Get the selected row
		let selectedRow = tableView.getSelectedRow()[0];
		let selectedRowId = selectedRow.id;

		//Get the sale information
		let saleItem = aThis.rowIdSaleItem[selectedRowId];
		let desc = aThis.rowIdItemDesc[selectedRowId];
		let salesPerson = aThis.rowIdSalesPerson[selectedRowId];

		//Update the sale information
		$('#pos-past-sale-info-item-info-description').text(desc);
		$('#pos-past-sale-info-item-info-sales-person > span').text(salesPerson);

		if (saleItem !== null && saleItem !== undefined) {
			// if the cashier changed the unit price for this item we want to show to original unit price
			if (posUtils.isDefined(saleItem.originalUnitPrice) &&  saleItem.originalUnitPrice != saleItem.unitPrice) {
				$('#pos-past-sale-info-item-info-original-price span').text(session.fixedNumber(saleItem.originalUnitPrice));
				$('#pos-past-sale-info-item-info-original-price').show();
			} else {
				$('#pos-past-sale-info-item-info-original-price').hide();
			}

		} else {
			$('#pos-past-invoices-display-items #pos-sale-info-item-picture img').attr('src', (<any>window).images_path + 'noimage.jpg');
		}

	}
	setTableHeader(saleData) {
		//If sale data exist
		if (saleData){

			//Split the string
			let saleDataArr = saleData.split(';');

			//Invoice value
			let invoiceSequence = saleDataArr[0];
			let createdAt = saleDataArr[1];
			let cashierEmployeeName =  saleDataArr[2];

			$('.header-invoice-number').text(invoiceSequence);
			$('.header-invoice-date').text(posUtils.getDateStr(createdAt));
			$('.header-invoice-cashier').text(cashierEmployeeName);
		}
	}

	resumeFromPast(options) {
		let aThis = posPastInvoicesDisplayItemsVC;

		let saleProxy = new PositiveTS.Service.SaleProxy(aThis.saleProxyOptions);

		// Fetch all items and payments for the sale
		saleProxy.load()
		.then(returnedSale => {
			if (returnedSale === null || returnedSale === undefined) {
				
				// Tell the user
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('loadingItemFromStorageFailed'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);

				return;
			}

			// --- Sale items and payments fetched successfully
			// Get the items and payments for the current sale ID
			let saleItemsAndPayments = returnedSale;
			aThis.sale = saleItemsAndPayments;

      let isCustomerExists = false;
      let customerName = ""
      let svcCustomerClub = new PositiveTS.Service.CustomerClub(saleItemsAndPayments, saleItemsAndPayments.payments, saleItemsAndPayments.items);

      if (svcCustomerClub.isCurrentCustomerExists(aThis.sale)){
        isCustomerExists = true;
        customerName = svcCustomerClub.getCustomerShortDisplayName(aThis.sale);
      }



			if (saleItemsAndPayments.customerName != null && saleItemsAndPayments.customerName != "") {
			  isCustomerExists = true;
			  customerName = saleItemsAndPayments.customerName;
			}
		let el = $(".past-invoices-display-items-customer-name")
		if (isCustomerExists) {
			el.text(`${i18next.t("posPastInvoicesDisplayItems.customer")}: ${customerName}`);
			el.show();
		} else {
			el.hide();
		}

			//Set the table view header
			aThis.setTableHeader(saleItemsAndPayments.invoiceSequence + ";" + saleItemsAndPayments.createdAt + ";" + saleItemsAndPayments.cashierEmployeeName);

			//Get the sale items
			let saleItemsWithoutChild = {};
			let childSaleItems = {};
			// add children and grandchildren to saleItem
			saleItemsAndPayments.items.forEach(item => {
				//next if is grandchildren
				if (item.level > 1){
					return;
				}

				if (item.isChild){
					if (!Array.isArray(childSaleItems[item.parentRowNumber])){
						childSaleItems[item.parentRowNumber] = [];
					}
					const grandchildren = saleItemsAndPayments.items.filter(i => i.isChild && i.parentRowNumber == item.rowNumber);
					if (grandchildren.length > 0){
						item.children = grandchildren;
						item.hasGroups = true;
					}
					childSaleItems[item.parentRowNumber].push(item);
				}else{
					saleItemsWithoutChild[item.rowNumber] = item;
				}
			});

			for (let parentRowNumber in childSaleItems){
				saleItemsWithoutChild[parentRowNumber].children = childSaleItems[parentRowNumber];
				saleItemsWithoutChild[parentRowNumber].hasGroups = true;
			}

			aThis.saleItems = Object.values(saleItemsWithoutChild);
			// Get the table view for later use
			let tableView = $('#pos-past-invoices-items-tableview').tableview();

			// Empty the table so we can be sure we are displaying only the latest results
			tableView.empty();

			//Empty Map
			aThis.rowIdSaleItem = {};
			aThis.rowIdItemDesc = {};
			aThis.rowIdQuantityStock = {};
			aThis.rowIdSalesPerson = {};


			let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(aThis.sale, aThis.saleItems, saleItemsAndPayments.payments);
	  		let sale_promotions;
			let totalPrice = 0;

			let selectedRow = false;
			let jd = JSON.parse(aThis.sale.jsondata);
			const useNewPromotions = jd.useNewPromotions;
			if (useNewPromotions){
				 sale_promotions = jd && Array.isArray(jd.promotions) ? jd.promotions : [];
			}
			for(let i=0;i<aThis.saleItems.length;i++){

				// Format the color/size for the table
				let colorSize = [];
				if (aThis.saleItems[i].color != '') {
					colorSize[0] = aThis.saleItems[i].color;
				}
				if (aThis.saleItems[i].size != '') {
					colorSize[colorSize.length] = aThis.saleItems[i].size;
				}

				// Format the quantity for the table
				let quantityText = aThis.saleItems[i].quantity;
				if (aThis.saleItems[i].quantity > 1) {
					quantityText = quantityText + ' X ' + session.fixedNumber(aThis.saleItems[i].unitPrice)
				}

				// Format the total amount for this item
				let total = aThis.saleItems[i].quantity * aThis.saleItems[i].unitPrice;

				let itemDescription = aThis.saleItems[i].itemDescription;
				if (aThis.saleItems[i].isPickup){
					itemDescription += '<img class="pos-tableview-description-cell-pickup-img-show" src="' +
				                 (<any>window).images_path + 'pos/plane12.png" class="pos-pickup-icon" />';
				}

				let colorSizeExists = colorSize[0] != null && colorSize[1] != null;
				//Add a row to table
				let row = tableView.addRow(posUtils.sanitizeTableViewRow([
				                 [aThis.saleItems[i].itemCode,'code',''],
				                 [itemDescription,'item-desc',''],
				                 [colorSizeExists ? colorSize.join('/') : '',colorSizeExists ? 'color-size' : 'empty-cell'],
				                 [quantityText,'qty',''],
				                 [session.fixedNumber(total),'total','']
				                 ]), false);

				row.data('itemCode', aThis.saleItems[i].itemCode);

				aThis.rowIdSaleItem[row.attr('id')] = aThis.saleItems[i];


				// Their are discount TYPE=AMOUNT with 0 amount
				// hide it from the user
				if(useNewPromotions){
					sale_promotions.map((p) => {
						let rowNumber = aThis.saleItems[i].rowNumber;
						if (p.rowToShowOn == rowNumber || p.parentRowNumber == rowNumber){
							let discountRow = tableView.addRow(posUtils.sanitizeTableViewRow([
								'',
								posVC.rowDescriptionCellHTML,
								'',
								session.fixedFloat(p.discountPercentForGroup, 2) + '%',
								session.fixedNumber(p.discountAmountForGroup * -1)
								]), false
							);
	
							discountRow.data('itemCode', aThis.saleItems[i].itemCode);
	
							// Add a custom class for the discount row
							discountRow.addClass('pos-item-type-discount');
	
							// Set the discount name
							$(discountRow).find('.pos-tableview-description-cell-description').text(p.promoName);
	
							// Show the sale icon
							$(discountRow).find('.pos-tableview-description-cell-sale-img').show();
						}
					})
					
				}else{

					let discountValue = -saleItemHelper.getSaleItemDiscountAmount(aThis.saleItems[i]);
					if (aThis.saleItems[i].discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL &&
						discountValue != 0) {
							
						let discountRow = tableView.addRow(posUtils.sanitizeTableViewRow([
							'',
							posVC.rowDescriptionCellHTML,
							'',
							session.fixedFloat(aThis.saleItems[i].discountPercent,2) + '%',
							session.fixedNumber(discountValue)
							]), false
						);

						discountRow.data('itemCode', aThis.saleItems[i].itemCode);

						// Add a custom class for the discount row
						discountRow.addClass('pos-item-type-discount');

						// Set the discount name
						$(discountRow).find('.pos-tableview-description-cell-description').text(aThis.saleItems[i].discountName);

						// Show the sale icon
						$(discountRow).find('.pos-tableview-description-cell-sale-img').show();
					}
				}

				//Get the row id
				let rowId = row.attr('id');

				//Add to the maps
				aThis.rowIdSaleItem[rowId] = aThis.saleItems[i];
				aThis.rowIdItemDesc[rowId] = aThis.saleItems[i].itemDescription;
				aThis.rowIdQuantityStock[rowId] = quantityText;
				aThis.rowIdSalesPerson[rowId] = aThis.saleItems[i].salespersonEmployeeName;

				//Select the first row
				if (!selectedRow){
					tableView.selectRow(rowId);
					selectedRow = true;
					aThis.updateSaleItemInformation();
				}
			}

			if (Number(saleItemsAndPayments.discountPercent) > 0) {

				let theFooter = tableView.setFooter([
					saleItemsAndPayments.discountID,
					posVC.rowDescriptionCellHTML,
					'',
					session.fixedFloat(saleItemsAndPayments.discountPercent,2) + '%',
					session.fixedNumber(saleTotals.totalSaleDiscount)
				]);

				// Set the discount name
				$(theFooter).find(' .pos-tableview-description-cell-description').text(saleItemsAndPayments.discountName);

				// Show the sale icon
				$(theFooter).find(' .pos-tableview-description-cell-sale-img').show();
			}

			//Update the sale total
			aThis.updateSaleTotal(saleTotals.totalAmount);
		});
	}

	resume(options) {
		let aThis = posPastInvoicesDisplayItemsVC;

		aThis.saleProxyOptions = options.saleProxyOptions;

		if (aThis.saleProxyOptions.type == PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE) {
			aThis.backTo = 'pos-search-invoices';
		} else {
			aThis.backTo = 'pos-past-invoices';
		}

		aThis.resumeFromPast(options);
	}
	stop() {
		$('#pos-past-invoices-display-items-back').unbind('click');
		$('#pos-past-invoices-print-item-button').unbind('click');
		$('#pos-past-invoices-show-payments').unbind('click')
	}
	back() {
		let aThis = posPastInvoicesDisplayItemsVC;

		pNavigator.replacePage(aThis.backTo, i18next.t('pageTitle.posPastInvoices'), null, null);
	}
	updateSaleTotal(total) {
		let aThis = posPastInvoicesDisplayItemsVC;

		if (total == null || total == undefined || total == "" || total == 0) {
			total = aThis.calculateSaleTotal(); // Get the sale total
		}

		// Format the number
		total = session.fixedNumber(total);

		// Set the sale total
		$('#pos-past-invoice-sale-info-total').text(total);
	}
	calculateSaleTotal() {
		let aThis = posPastInvoicesDisplayItemsVC;

		// Initialize sale total
		let total = 0;

		// Go over each sale item
		$.each(aThis.saleItems, function (index, saleItem) {
			// Calculate the item price
			let unitPrice = saleItem.unitPrice !== undefined ? saleItem.unitPrice : saleItem.unit_price;
			let itemPrice = saleItem.quantity * unitPrice;

			// Add it to the total
			total = total + itemPrice;
		});

		return total;
	}
	getSlectedItem() {
		let aThis = posPastInvoicesDisplayItemsVC;

		let tableView = $('#pos-past-invoices-items-tableview').tableview();

		return aThis.rowIdSaleItem[tableView.getSelectedRow().attr('id')];
	}
	// --------------------------------------------------------------------
	// Listeners
	// --------------------------------------------------------------------
	printReplacementSlip() {
		let aThis = posPastInvoicesDisplayItemsVC;

		let selectedSaleItem = aThis.getSlectedItem();

		let companyModel = new PositiveTS.Storage.Entity.Company();
		let company = companyModel.fetchByTenantIDAndCompanyID(aThis.sale.tenantID, aThis.sale.companyID)[0];

		let itemsCopy = aThis.saleItems.map((si:PositiveTS.Storage.Entity.SaleItem) => { return si.clone()});


		// PositiveTS.Helper.SaleHelper.devidPromotionsDiscountByItemsForCredit(itemsCopy);

		let selectedItemCopy = null;
		for (let i = 0; i < itemsCopy.length; i++) {
		  // item is a property contains all the Item-Object properties serialized [color/size etc...]
		  // without the randomly generated fields like WebDb - id
			if ( itemsCopy[i].item == selectedSaleItem.item ) {
				selectedItemCopy = itemsCopy[i];
			}
		};

		console.log(aThis.sale.totalDiscount);

		PositiveTS.Printing.Invoice.printReplacementSlip(aThis.sale, [selectedItemCopy], company, false);

		printer.print();

	}
}}}}

declare var posPastInvoicesDisplayItemsVC:PositiveTS.Application.Controllers.PosPastInvoicesDisplayItemsViewController;
posPastInvoicesDisplayItemsVC = new PositiveTS.Application.Controllers.PosPastInvoicesDisplayItemsViewController();
PositiveTS.Application.Controllers.instances.PosPastInvoicesDisplayItemsViewController = posPastInvoicesDisplayItemsVC;