// +--------------------------------------------------------
// | Entity for Employee Time Tracking
// +--------------------------------------------------------
// | tenantID
// | companyID
// | employeeID
// | mode
// |	MODE_ENTER = 1
// |	MODE_EXIT = 2
// | recordTimestamp
// | storeID
// | posPhysicalID
// | posDeviceID
// | syncStatus
// |	SYNC_STATUS_NEW = -1
// |	SYNC_STATUS_WAITING_TO_BE_SENT = 1 (ברירת מחדל)
// |	SYNC_STATUS_SYNCED_SUCCESFULLY = 2
// |	SYNC_STATUS_FAILED = 3
// | syncLastMessage
// | syncLastMessageTimestamp
// +--------------------------------------------------------
module PositiveTS {
export module Storage {
export module Entity {
export class EmployeeTimeTrack extends IDBEntity {

	tenantID:string
	companyID:string
	employeeID:string
	mode:number
	recordTimestamp:string
	storeID:string
	posPhysicalID:string
	posDeviceID:string
	syncStatus:number
	syncLastMessage:string
	syncLastMessageTimestamp:string

	//roshemet fields
	serverID:number
	serverEmployeeID:number
	isManualUpdate:boolean
	markedForDelete: boolean
	//end of roshemet fields
	


	constructor() {
		let meta = {
			name: 'employeeTimeTrack',
			internal: true,
			fields: {
				tenantID: "TEXT",
				companyID: "TEXT",
				employeeID: "TEXT",
				mode: "INT",
				recordTimestamp: "TEXT",
				storeID: "TEXT",
				posPhysicalID: "TEXT",
				posDeviceID: "TEXT",
				syncStatus: "INT",
				syncLastMessage: "TEXT",
				syncLastMessageTimestamp: "TEXT"
			},
			unique: ['tenantID','companyID','employeeID','mode','recordTimestamp']
		}
		if (session && session.pos && session.pos.isRoshemet) {
			meta.fields = Object.assign({
				serverID: "INT",
				serverEmployeeID: "INT",
				isManualUpdate: "BOOL",
				markedForDelete: "BOOL",
			},meta.fields)
		}
		
		super(meta)
	}


	static MODE_ENTER = 1;
	static MODE_EXIT = 2;

	static SYNC_STATUS_WAITING_TO_BE_SENT = 1;
	static SYNC_STATUS_SYNCED_SUCCESFULLY = 2;
	static SYNC_STATUS_FAILED = 3;

}}}}
