module PositiveTS {
    export module Promotions {
    export module Templates {
    export class discountPromotion extends TemplateAbstract { //this template applies a reuglar discount (item or sale)
      
      
      private allItems:Array<Storage.Entity.SaleItem>
      
      constructor(initParameters) {
        super(initParameters)
        this.allItems = initParameters.allItemsForDiscountPromo;
      }
    
      calculatePromotions() {
        //yalla azov oti me shtoyot
      }
      
    
      run(promotion:Storage.Entity.Promotion) {
        
        let buyItems = [];
        let allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
        let isSaleDiscount = false;
  
        if (!allowWithOtherPromotions) {
          this.allItems = this.allItems.filter(saleItem => !((<any>saleItem).hasPromotion))
        }

        if (promotion.discountGroup.rowToShowOn != null) {
            //this is a row discount
            buyItems = this.allItems.filter(saleItem => saleItem.rowNumber == promotion.discountGroup.rowToShowOn)
        }
        else {
            //this is a sale discount
            buyItems = this.allItems;
            isSaleDiscount = true;
        }

        let pg = this.createPromoGroup(promotion,buyItems,allowWithOtherPromotions);
        pg.isSaleDiscount = isSaleDiscount;
        this.parameters.promoGroups = [pg]
      }
  
      private createPromoGroup(promotion,tempArr,allowWithOtherPromotions):any {
        let getPromoGroup = {
          itemsCounter: {},
          rowValueCounter: {},
          promotion: promotion,
          isDiscount: true,
          discountAmountForGroup: 0,
          totalPriceForItemsBeforeDiscount: 0
        }

        getPromoGroup.totalPriceForItemsBeforeDiscount += _.sumBy(tempArr,(item) => (allowWithOtherPromotions ? item.realQuantity : item.quantity)*item.unitPrice);
        getPromoGroup.discountAmountForGroup = this.getDiscountValue(getPromoGroup.totalPriceForItemsBeforeDiscount, promotion);
        tempArr.forEach(item => {
          this.addToItemsCounter(item,getPromoGroup.itemsCounter, (allowWithOtherPromotions ? item.realQuantity : item.quantity));
          this.addToRowValueCounter(item,getPromoGroup.rowValueCounter, (allowWithOtherPromotions ? item.realQuantity : item.quantity));
        });
        return getPromoGroup;
      }
  
    
      private getDiscountValue(totalPriceForRun:number, promotion:Storage.Entity.Promotion) {
        switch (promotion.discountType) {
          case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
            return Math.min(Number(promotion.discountValue),totalPriceForRun);
          case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
            return Math.max(0, //in fixed discount - make sure that the discount amount does not exceed the allowed discount percent
                            Math.min(totalPriceForRun*Number(promotion.discountGroup.discountPercentForGroup)/100.0,
                            totalPriceForRun-Number(promotion.discountValue))
                           )
          case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
            return totalPriceForRun*Number(promotion.discountValue)/100.0;
        }
      }
    
    
    }
}}}
    