module PositiveTS {
export module Components {
    export module PositiveDeliveryTableView {

        function initData() {
          return {
            giftImg: `${(<any>window).images_path}pos/gift.png`,
            pickupImg: `${(<any>window).images_path}pos/plane12.png`,
            deleteImg: `${(<any>window).images_path}delete-new.png`,
            scrollSelector: '#positive-delivery-table-view-container .positive-delivery-table-view',

            sale: {},
            saleItems: [],
            noSaleItems: false,
            saleItemsPositiveDeliveryTableViewMessage: "",
          }
        }




        export function create() {
            var component = {
                template: JST.positiveDeliveryTableView(),
                mixins: [Mixins.scrollableMixin],
                name: 'PosItemsPositiveDeliveryTableView',
                methods: {
                  // selectRow: selectRow,
                  deleteDiscountFromSaleItem: (saleItem) => posVC.deleteDiscountFromSaleItem(saleItem),
                  confirmSaleDiscountDelete: () => posVC.confirmSaleDiscountDelete(),
                  fixedFloat: session.fixedFloat,
                  formatPrice(price) {
                    return Service.MultiCurr.getInstance().addCurrencySignIfNeeded(session.fixedNumber(price));
                  },
                  promotionsForRow(rowNumber:number) {
                    let result = this.promoGroups.filter((pg:Promotions.PromoGroup) => {
                      return !pg.isSaleDiscount && pg.rowToShowOn == rowNumber && pg.discountAmountForGroup != 0;
                    })
                    return result;
                  },
                  getSaleDiscounts() {
                    let result = this.promoGroups.filter((pg:Promotions.PromoGroup) => {
                      return pg.isSaleDiscount && pg.discountAmountForGroup;
                    })
                    return result;
                  },
                },
                data: initData,
                computed: {
                  selectedSaleItem(){
                    return Pinia.globalStore.selectedSaleItem
                  },
                  promoGroups() {
                    if (this.sale && this.sale.jsondata) {
                      let jd = JSON.parse(this.sale.jsondata)

                      return jd.promotions || [];
                    }

                    return [];
                  },
                  saleDiscountExists() {
                    let self:any = this;
                    if (self.hasNewPromotions) {
                      return (this.getSaleDiscounts().length > 0);
                    }
                    else {
                      return (self.sale.hasDiscount || self.sale.hasPromotion)
                    }
                  },
                  isExternalorder() {
                    if(session.pos.hasInternetStore && !posUtils.isBlank(this.sale)) {                      
                        let saleData = JSON.parse(this.sale.jsondata);
                        if(saleData && saleData.delivery && saleData.delivery.isExternalOrder) {
                          return true;
                        }
                    }
                    return false;
                  },
                  unflattenSaleItemsToShow() { 
                    let items = this.saleItems;

                    return saleItemHelper.unflattenSaleItems(items);
                  },
                  hasNewPromotions() {
                    return session.pos.useNewPromotions;
                  },
                  mobileLayout(){
                    return Pinia.globalStore.mobileLayout
                  },
                },
                mounted: function() {
                  Vue.watch(() => Pinia.globalStore.saleUpdatedTriggered, (newValue, oldValue) => {
                    setTimeout(() => {
                      let elem = document.querySelector('.table-row.selected')
                      if (elem != null) {
                        (<any>elem).scrollIntoView(false)
                      }
                    },0);
                });
                }

            }

            VueApp.component('positive-delivery-tableview',component)
        }
    }
  }
}
