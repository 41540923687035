module PositiveTS {
  export module Components {
    export module CellularTab {      
      
      export const route = "/cellular-tab";

      export function getComponent() {
        return {
          template: JST.cellularTab(),
          methods: {
          },
          components: {
            IndexMobilePhoneRepairs: FeatureOrder.IndexMobilePhoneRepairs.getComponent(),
            MobilePhoneRepair: FeatureOrder.MobilePhoneRepair.getComponent()

          },
          computed: {
            componentToDisplay () {
              return this.$route?.params?.innerComponent || ''
            },
            canManageHakafaCustomer(){
              return jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos);
            },
            isDalpakimOnline(){
              return jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline);
            },
            
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {standaloneMode,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {standaloneMode,mobileLayout}

            return {...globalStoreProps}
          },
          mounted(){
            Pinia.globalStore.setPosTabMode(true)
          },
          unmounted(){
            Pinia.globalStore.setPosTabMode(false)
          },
          data() {
            return {
              title: ""
            }
          }
        }
      }
    }
  }
}
