import { createPinia,storeToRefs,mapStores } from 'pinia';
import useElalStore from "./elalStore"
import useDalpaksStore from "./dalpaksStore"
import useComponentsStore from "./componentsStore"
import usePromotionsManageStore from "./promotionsManageStore"
import useConnectivityStore from "./connectivityStore"
import useFlightsStore from "./flightsStore"
import {useLanguageManagerStore, initStore as initLanguageManagerStore} from "./languageManagerStore"
import useSelfServicePaymentStore from "./selfServicePaymentStore"
import usePosStore from "./posStore"
import usePosMenu from "./PosMenuStore"
import useVendingMachineStore from "./vendingMachineStore"
import {useGlobalStore,initStore as initGlobalStore} from "./globalStore"

const instance = createPinia();
const elalStore = useElalStore(instance);
const dalpaksStore = useDalpaksStore(instance);
const componentsStore = useComponentsStore(instance);
const promotionsManageStore = usePromotionsManageStore(instance);
const connectivityStore = useConnectivityStore(instance);
const flightsStore = useFlightsStore(instance);
const languageManagerStore = useLanguageManagerStore(instance);
const selfServicePaymentStore = useSelfServicePaymentStore(instance);
const posStore = usePosStore(instance);
const posMenu = usePosMenu(instance);
const vendingMachine = useVendingMachineStore(instance);
const globalStore = useGlobalStore(instance);

const initStore = () =>{
    initLanguageManagerStore()
    initGlobalStore()
}

export{
    instance,
    mapStores,
    storeToRefs,
    initStore,

    connectivityStore,
    useConnectivityStore,

    promotionsManageStore,
    usePromotionsManageStore,

    elalStore,
    useElalStore,

    componentsStore,
    useComponentsStore,

    dalpaksStore,
    useDalpaksStore,

    flightsStore,
    useFlightsStore,

    languageManagerStore,
    useLanguageManagerStore,

    selfServicePaymentStore,
    useSelfServicePaymentStore,

    posStore,
    usePosStore,

    posMenu,
    usePosMenu,

    vendingMachine,
    useVendingMachineStore,

    globalStore,
    useGlobalStore

}
