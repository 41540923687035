module PositiveTS {
  export module Promotions {
  export module Templates {
  export class HeshbonDiscount extends TemplateAbstract {
    
    private minBuyQuantity:number
    private minBuyAmount:number
    private allowMultipleTimesPerSale:boolean
    private getPotentialSalePromotions:boolean
    
    private allItems:Array<Storage.Entity.SaleItem>
    
    constructor(initParameters) {
      super(initParameters)
      this.allItems = initParameters.updatedSaleItems;
      this.getPotentialSalePromotions = initParameters.getPotentialSalePromotions;
    }
  
    calculatePromotions() {
      //yalla azov oti me shtoyot
    }
  
    run(promotion:Storage.Entity.Promotion) {
      
      let buyItems = [];
      this.minBuyQuantity = Number(promotion.minimumBuyQuantity);
      this.minBuyAmount = Number(promotion.minimumBuyAmount);
      this.allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
      let allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);


      if (this.getPotentialSalePromotions) { //we don't want to show messages for this kind of promotion (yet)
        return;
      }

      if (!allowWithOtherPromotions) {
        this.allItems = this.allItems.filter(saleItem => !((<any>saleItem).hasPromotion))
      }
      
      for (let si of this.allItems) {
        if (si.item.promoBuy.split("&").includes(promotion.code)) {
          buyItems.push(si);
        }
      }

      let buyItemsTotal = _.sumBy(buyItems,'unitPrice');
      let buyItemsQty = buyItems.length;
      let totalTimesToApply = 0;
  

      if (this.minBuyAmount == 1) { //condition is quantity
        //build largest get group in multiple of getQty - build group by order accoring to condition:
        totalTimesToApply = Math.floor(buyItemsQty/this.minBuyQuantity);
      }
      else {
        //if the buy condition is amount  - get the expensive items first, and re-run with cheap items first.
        totalTimesToApply = Math.floor(buyItemsTotal/this.minBuyAmount);
      }

      if (totalTimesToApply > 0) {
        if (!this.allowMultipleTimesPerSale) {
          totalTimesToApply = 1;
        }
        this.parameters.promoGroups = [this.createPromoGroup(promotion,buyItems,totalTimesToApply)]
      }
       

    }


    private createPromoGroup(promotion,tempArr,timesToApply:number) {
      let getPromoGroup = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
      getPromoGroup.totalPriceForItemsBeforeDiscount += _.sumBy(tempArr,'unitPrice');
      getPromoGroup.discountAmountForGroup = this.getDiscountValue(getPromoGroup.totalPriceForItemsBeforeDiscount, promotion, timesToApply);
      tempArr.forEach(item => {
        this.addToItemsCounter(item,getPromoGroup.itemsCounter);
        this.addToRowValueCounter(item,getPromoGroup.rowValueCounter);
      });
      return getPromoGroup;
    }

  
    private getDiscountValue(totalPriceForRun:number, promotion:Storage.Entity.Promotion, timesToApply:number) {
      switch (promotion.discountType) {
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
          return Math.min(timesToApply*Number(promotion.discountValue),totalPriceForRun);
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
          return Math.max(0,totalPriceForRun-Number(promotion.discountValue))
        case PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
          return totalPriceForRun*Number(promotion.discountValue)/100.0;
      }
    }
  
  
  }
  }}}
  