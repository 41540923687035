module PositiveTS {
    export module Service {
        export module AndroidNativeMessage {
            export module EMV {
                export function sendNativeMessage(jsonData, from = "52", to = "02") {
                    return new Promise((resolve, reject) => {
                        setTimeout(async function () {
                            let XmlData = jsonData.rawXml
                            let androidIntentTimeout = jsonData.androidIntentTimeout || 0
                            let isAsync = Pinia.globalStore.EMVisAsync;
                            let disableClick = Pinia.globalStore.EMVDisableClick;

                            let response = "";
                            if (jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment)) {
                                if(Pinia.globalStore.EMVHideLoader && Android.getVersion() >= "1.72"){ 
                                    // APK Version 1.72 is required to hide the loader
                                    response = Android.SendXMLToInternalPinpad(XmlData, androidIntentTimeout, true)
                                }else{
                                    response = Android.SendXMLToInternalPinpad(XmlData, androidIntentTimeout)
                                }
                            }
                            else {
                                //52 = sending to "Smart Retail App"
                                //02 = sending from "ECR - Electronic Cash Register"
                                if (isAsync) {
                                    response = await PositiveTS.Service.AndroidService.makeActionAsync('SendXMLToPinPad', XmlData, from, to, disableClick);
                                    Pinia.globalStore.setEMVisAsync(false);
                                } else {
                                    response = Android.SendXMLToPinPad(XmlData, from, to);

                                }
                            }

                            console.log("EMV Response: " + response);
                            resolve(response);

                        }, 0);
                    })

                }
            }
        }
    }
}
