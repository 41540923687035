module PositiveTS {
export module Components {
    export module KeyPad {
      export function create() {
          var comp = {
              props: {
                keyTapped: {
                  'type': Function,
                  'required': true
                },
                confirmTapped: {
                  'type': Function,
                  'required': false
                },
                customTapped: {
                  type: Function,
                  required: false
                },
                generalItemTapped: {
                  'type': Function,
                  'required': false
                },
                timesTapped: {
                  'type': Function,
                  required: false
                },
                payAllTapped: {
                  'type': Function,
                  'required': false,
                },
                showConfirmButton: {
                  'type': Boolean,
                  'required': false
                },
                showApproveAndPayAll: {
                  'type': Boolean,
                  'required': false
                },
                showCustomButton: {
                  type: Boolean,
                  required: false,
                  default: false
                },
                customButtonText: {
                  type: String,
                  required: false,
                },
                posMainKeypad: {
                  'type': Boolean,
                  'required': false,
                },
                showClearButton: {
                  type: Boolean,
                  required: false
                },
                clearTapped: {
                  type: Function,
                  required: false
                },
                clearText: {
                  type: String,
                  required: false
                },
                confirmText: {
                  type: String,
                  required: false,
                  default: i18next.t('ok')
                },
                allowNeg: {
                  type: Boolean,
                  required: false
                },
                dialogLayout: {
                  type: Boolean,
                  required: false
                },
                hidePrecisionPoint: {
                  type: Boolean,
                  required: false,
                  default: false,
                }
              },
              template: JST.newKeypad(),
              methods: {
              },
              computed: {
                keysOrder() {
                  let keys = [
                    [1,2,3],
                    [4,5,6],
                    [7,8,9]
                  ];

                  if (this.rtlMode) {
                    keys = keys.map(keysLine => keysLine.reverse())
                  }

                  return keys;
                },
                rtlMode(){
                  return Pinia.globalStore.rtlMode
                },
              }
          }

          VueApp.component('keypad',comp)
      }
    }
  }
}
