module PositiveTS {
  export module Service {

    export class Tenbis extends SmartVoucher {
      hasCVV = false

      getBalance(cardNumber: string, cvv: string): Promise<GetBalanceResponse> {
        return;
      }


      async pay(voucherData: any, amount: number, cvv?: string, companyId?: string): Promise<ActionResponse> {
        let cardNumber = voucherData.barCode;
        if (posUtils.isNullOrUndefinedOrEmptyString(cardNumber)) {
          return {
            success: false,
            error: i18next.t("cardNumberCantBeEmpty"),
          }
        }

        let response = await Service.TenbisService.addOrder(cardNumber, amount);

        if (response.Success) {
          var happyHoutDiscounts = response.Data.Transactions.filter((row) => { return row.IsHappyHourTransaction }).reduce((a, b) => { return parseFloat(b.TransSum) + a }, 0);

          voucherData.barCode = cardNumber
          voucherData.actionType = SmartVoucherActionTypes.WITHDRAW
          voucherData.allowPartialReturn = false
          voucherData.amount = Number(response.Data.TransactionSum)
          voucherData.orderId = response.Data.TransactionID
          voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS
          voucherData.data = JSON.stringify(response.data);
          voucherData.happyHoutDiscounts = happyHoutDiscounts
        }

        let errorMsg = "";
        if (response.Errors.length > 0) {
          errorMsg = response.Errors[0].ErrorDesc
        }

        let responseObj: ActionResponse = {
          success: response.Success,
          error: errorMsg,
          rawResponse: response,
          voucher: voucherData
        }
        return responseObj;
      }

      cancelPayment(voucherToCancel: any): Promise<ActionResponse> {
        var amount = Math.abs(voucherToCancel.amount)
        return PositiveTS.Service.TenbisService.cancelOrder(voucherToCancel.barCode, amount).
          then((response) => {
            return ({
              success: true,
              error: ""
            });
          }).catch((e: Error) => {
            return ({
              success: false,
              error: e.message
            });
          })

      }

      cancelLoad(paymentToCancel): Promise<any> {
        return Promise.reject("Not implemented");
      }

      getCardNumber(): Promise<any> {
        return Promise.reject("NOT IMPLEMENTED");
      }

      loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
        var _return: any = Promise.reject("Not implemented");
        return _return;
      }

    }
  }
}
