module PositiveTS {
export module Components {
export module Mixins {
  export function selfServicePaymentMixin(methodNameI18nextKey, supportsKeypad = false, iconData = null, 
    imageType = IMAGES_TYPES.SWIPE_CARD, otherOptions?: PositiveTS.Types.SelfServicePaymentMixinOptions) {
    return {
      components: { 
        selfServiceKeypad: PositiveTS.Components.SelfServiceKeypad.getComponent(),
        positiveIcon: PositiveTS.Components.PositiveIcon.getComponent(),
        selfServicePage: PositiveTS.Components.SelfServicePage.getComponent(),
      },
      data() {
        return {
          paymentInProcess: false,
          methodName: methodNameI18nextKey,
          iconData: iconData,
          isSelfServicePos: Pinia.globalStore.selfServiceSuperMarket,
          imageType: imageType,
          cardNumber: '',
          resolve: null,
          paymentInProcessTitle: otherOptions?.paymentInProcessTitle,
          selectedPaymentMethodType: null,
          supportsKeypad,
          INPUT_METHODS,
          IMAGES_TYPES,
          selectedPaymentMethodTypeResolve: null,
          keypadInputResolve: null,
          ICON_TYPES,
        }
      },
      methods: {
        
        async getPaymentCardNumber(amount, otherArgs = { goWithSelectedPaymentMethodType: false} ) {
          if (!this.supportsKeypad) {
            this.selectedPaymentMethodType = INPUT_METHODS.SWIPE_CARD;
             return await Service.EMV.swipe(amount); 
          }
          let selectedMethodType = otherArgs?.goWithSelectedPaymentMethodType && this.selectedPaymentMethodType ? this.selectedPaymentMethodType : await this.getSelectedPaymentMethodType();
          if (selectedMethodType == INPUT_METHODS.KEYPAD) {
            return new Promise((res, rej) => {
              this.keypadInputResolve = res;
            });
          } else {
            return await Service.EMV.swipe(amount);
          }
        },
        setSelectedPaymentMethodType(selectedPaymentMethodType) {
          this.selectedPaymentMethodType = selectedPaymentMethodType;
          this.selectedPaymentMethodTypeResolve(this.selectedPaymentMethodType);
        },
        getSelectedPaymentMethodType(): Promise<any> {
          return new Promise((res, rej) => {
            this.selectedPaymentMethodTypeResolve = res;
          });
        },
        resolvePaymentCardNumberPromise(withCardNumber) {
          this.resolve(withCardNumber ? this.cardNumber : null);
        },
        keypadSubmitted(cardNumber) {
          this.keypadInputResolve(cardNumber);
        }, 
        goBack(){
          this.setCurrentStep('SelfSelectPaymentMethod')
        },
        setCurrentStep(arg){
          Pinia.selfServicePaymentStore.setCurrentStep(arg)
        }
      },
      computed: {
        paymentMethodName() {
          return this.methodName ? i18next.t(this.methodName) : '';
        },
        titlesToShow() {
          if (this.paymentInProcess) {
            let title = i18next.t('selfService.chargingCard')
            if (posUtils.isPresent(this.paymentInProcessTitle)) {
              title = typeof(this.paymentInProcessTitle) == 'string' ? this.paymentInProcessTitle : this.paymentInProcessTitle();
            }
            return {title: title + '\n' + i18next.t('selfService.pleaseWait'), subtitle: this.paymentMethodName}
          }
          if (this.supportsKeypad && !this.selectedPaymentMethodType) {
            return {title: i18next.t('selfService.paymentX', {payment: this.paymentMethodName}), subtitle: i18next.t('selfService.howDoYouPay')}
          }

          if (this.supportsKeypad && this.selectedPaymentMethodType == INPUT_METHODS.KEYPAD) {
            return {title: i18next.t('selfService.paymentX', {payment: this.paymentMethodName}), subtitle: i18next.t('selfService.insertCardNumber')}
          }

          return {
            title: this.paymentMethodName == i18next.t('selfService.loadCard') ? i18next.t('selfService.pleaseSwipeCardNoPayment') : i18next.t('selfService.paymentX', {payment: this.paymentMethodName}),
            subtitle: i18next.t('selfService.pleaseSwipeCard'),
          }
        }
      },
      mounted() {
        // need to implements this in sons
        if (this.paymentAction) {
          this.paymentAction();
        }
      },
    }
  }

  export function selfServicePaymentNoPinpadMixin(methodName, imageType, updatePaymentInProcess = false) {
    return {
      components: {
        positiveIcon: PositiveTS.Components.PositiveIcon.getComponent(),
        selfServicePage: PositiveTS.Components.SelfServicePage.getComponent(),
      },
      data() {
        return {
          paymentInProcess: false,
          methodName: methodName,
          cardNumber:"",
          imageType: imageType,
          updatePaymentInProcess: updatePaymentInProcess,
          IMAGES_TYPES
        }
      },
      methods: {
        preventBlur() {
          if (this.$refs.cardNumber) {
            this.$refs.cardNumber.focus();
          }
        },
        setCurrentStep(arg){
          Pinia.selfServicePaymentStore.setCurrentStep(arg)
        },
        goBack(){
          this.setCurrentStep('SelfSelectPaymentMethod')
        },
        async cardChanged() {
          if (this.paymentInProcess){
            return
          }

          if (this.updatePaymentInProcess){
            this.paymentInProcess = true
          }
          console.trace('selfServicePaymentMixin cardChanged pay', new Date)
          await this.pay();
        },
      },
      mounted() {
        this.$refs.cardNumber.focus();
      },
      computed: {
        titlesToShow() {
          if (this.paymentInProcess) {
            return {title: this.options.charging + '\n' + i18next.t('selfService.pleaseWait'), subtitle: this.paymentMethodName}
          }

          return {
            title: i18next.t('selfService.paymentX', {payment: this.paymentMethodName}),
            subtitle: this.options.title,
          }
        },
        options() {
          return {
            title: i18next.t('selfService.pleaseSwipeCard'),
            charging: i18next.t('selfService.chargingCard'),
          }
        },
        paymentMethodName() {
          return this.methodName ? i18next.t(this.methodName) : '';
        },
      }
    }
  }


  export const IMAGES_TYPES = {
    SWIPE_CARD: 0,
    CONTACTLESS: 1,
    SCAN_TAG: 2,
    EMV: 3,
  }

  export const INPUT_METHODS = {
    KEYPAD: 1,
    SWIPE_CARD: 2,
  }

  export const ICON_TYPES = {
    FONT_AWESOME: 0,
    POSITIVE_ICON: 1,
  }

}}}
