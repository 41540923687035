module PositiveTS {
  export module Components {
    export module FlightsIsrairCloseDialog {

      const _dialogSelector = "#positive-flights-israir-open-dialog";
      const _vueComponentName = 'flights-israir-close-dialog';

      export function create() {
        let flightOnlineloginDialog = {
          template: JST.flightsIsrairCloseDialog(),
          mixins: [Mixins.modalsMixin],
          components: {
            FlightsIsrairStockCount: FlightsIsrairStockCount.getComponent(),
            FlightsIsrairPrintStockCountReport: FlightsIsrairPrintStockCountReport.getComponent(),
          },
          methods: {
            open() {              
              this.step = 0;
              this.$el.show();
            },
            close() {
              this.$el.close();
            },
            goNext() {
              this.step++;
            },
            goBack() {
              this.step--;
            },
            finishWizard() {
              this.showAlertWithTitleGeneric(i18next.t('flightsWizard.israirFinishFlight'),
                i18next.t('flightsWizard.finishTitle'),
                this.applyAndCloseWizard,
                this.goBack,
                false,
                i18next.t('flightsWizard.approve'),
                i18next.t('flightsWizard.goBack'));
            },
            async applyAndCloseWizard() {
              app.showLoadingMessage(i18next.t("loading"));

              try {
                await this.closeIsrairFlight(this.allWizardData);
                app.hideLoadingMessage();
              } catch (err) {
                console.error(err);
                app.hideLoadingMessage();
                this.showGenericError();
                this.goBack();
                return;
              }

              this.clearWizard();
              this.close();
            },
            clearWizard() {
              this.step = -1;
              this.wizardStepsData = [];
            },
            getDefaultWizardData() {
              return { openFlight: false };
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {closeIsrairFlight} = flightsStore;

            const globalStore = Pinia.useGlobalStore();
            const {isOnline} = Pinia.storeToRefs(globalStore);


            return {closeIsrairFlight,isOnline}
          },
          computed: {
            allWizardData() {
              return this.wizardStepsData.reduce((allData, currentData) => Object.assign(allData, currentData), this.getDefaultWizardData());
            },
            currentStepsComponets() {
              return [
                'FlightsIsrairStockCount',
                'FlightsIsrairPrintStockCountReport',
              ]
            },
          },
          data() {
            return {
              step: -1,
              wizardStepsData: [],
            };
          },
          watch: {
            step() {
              if (this.step < 0) {
                this.step = -1;
                this.close();
              }

              if (this.step >= this.currentStepsComponets.length) {
                this.finishWizard();
              }
            }
          },
        }
        VueApp.component(_vueComponentName, flightOnlineloginDialog)
      }
    }
  }
}