module PositiveTS {
  export module Service {
    export module Ontrust {
      export module Customer {

        const _ontrustJsondataCustomerKey = "OntrustCustomer";

        export class CustomerObj{
          name:string = "";
          phone:string = "";
          remarks:string = "";
          constructor (name:string, phone:string, remarks:string){
            this.name = name;
            this.phone = phone;
            this.remarks = remarks;
          }

          get isValid():boolean{
            return (!!this.name && !!this.phone && !!this.remarks )
          }

          persistToSale():Promise<any>{
            this._setSaleJsondata();

            return PositiveTS.Service.Ontrust.Payment.setPayment()
            .then(()=>{
                return posVC.sale.persist();
            });

          }

          private _setSaleJsondata():void{
            var jsondata = {};
            if (posVC.sale.jsondata){
              jsondata = JSON.parse(posVC.sale.jsondata);
            }
            jsondata[_ontrustJsondataCustomerKey] = this;
            posVC.sale.jsondata = JSON.stringify(jsondata);
          }
        }




        export function removeOntrustCustomer():Promise<any>{
          // remove from sale.jsondata
          // remove payment with voucher ontrust
          return Promise.resolve();
        }




      }
    }
  }
}
