
module PositiveTS {
    export module Components {
        export module PositiveButton {
            declare type CompType = InstanceType<ReturnType<typeof create>>;

            export function getDefaultProps() {
                let prop: {
                    text?: string,
                    icon?: string,
                    iconClass?: string,
                    textClass?: string,
                    classes?: string,
                    border?: boolean,
                    id?: string,
                } = {
                    text: '',
                    icon: '',
                    iconClass: '',
                    textClass: '',
                    classes: '',
                    border: true,
                    id: null,
                }

                return prop;
            }

            export function create() {
                const defaultProps = getDefaultProps()

                const component = {
                    template: JST.PositiveButton(),

                    props: {
                        text: {
                            type: String,
                            default: defaultProps.text,
                        },

                        icon: {
                            type: String,
                            default: defaultProps.icon,
                        },

                        iconClass: {
                            type: String,
                            default: defaultProps.iconClass,
                        },

                        textClass: {
                            type: String,
                            default: defaultProps.textClass,
                        },

                        classes: {
                            type: String,
                            default: defaultProps.classes,
                        },

                        border: {
                            type: Boolean,
                            default: defaultProps.border,
                        },

                        id: {
                            type: String,
                            default: defaultProps.id,
                        },
                    },
                    components: {
                        'positive-icon': PositiveIcon.getComponent(),

                    },
                    computed: {
                        hasIcon() {
                            return posUtils.isPresent(this.icon);
                        },
                        isFaIcon() {
                            return this.icon && this.icon.includes('fa-');
                        },

                        componentClasses() {
                            let classes = [this.classes];

                            if (this.mobileLayout) {
                                classes.push('mobile')
                            }

                            if (this.border) {
                                classes.push('border')
                            }

                            return classes
                        },

                        mobileLayout(){
                            return Pinia.globalStore.mobileLayout
                          },
                    },
                }

                VueApp.component('positive-button', component);
                return defineComponent(component);
            }
        }
    }
}
