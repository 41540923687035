module PositiveTS {
/*
Usage:
  PositiveTS.VueInstance.$refs.punchCardTransactionsReport}.open("10.50","123123")
*/

  export module Components {
    export module PunchCardTransactionsReport {
      const _dialogSelector = "#positive-punch-card-transactions-report-dialog"
      const _vueComponentName = 'punch-card-transactions-report';
    
      export function create() {
        let vueObj = {
            template: JST.punchCardTransactionsReport(),
            methods: {
              getDate: getDate,
              close: _close,
              doConfirm: _doConfirm,
              doReject: _doReject,
              doPrint: _doPrint,

              open (punchCardItemId, itemName, cardNumber) {
                $(document).unbind('keypress');
                $(function() {
                  $(_dialogSelector).dialog({
                    autoOpen: true,
                    modal: true,
                    dialogClass: 'positive-dialog',
                    width: '80%',
                    height: 600,
                    resizable: false,
                    closeOnEscape: false,
                    draggable: false,
                  });
                  $(_dialogSelector).dialog('open');
                });

                this.blurInputsOnElement($(_dialogSelector)[0])
                this.punchCardItemId = punchCardItemId
                this.itemName = itemName
                this.cardNumber = cardNumber
                this.fetchData()
        
                return new Promise((resolve,reject) => {
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              },

              async fetchData () {
                try{
                  app.showLoadingMessage()
                  let result = await PositiveTS.Service.PunchCard.getPunchCardItemById(this.punchCardItemId, this.fromDate, this.toDate)
                  result = result.result
                  result.itemName = this.itemName
                  result.cardNumber = this.cardNumber
                  this.result = result
                  this.punchCardTransactions = result.punch_card_transactions
                  app.hideLoadingMessage()
                } catch (error) {
                  app.hideLoadingMessage()
                  console.error(error)
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('generalError'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }
              },
            },
            data: _initData,
    
    
        }
    
        VueApp.component(_vueComponentName,vueObj)
    
      }
    
      function getDate(pct) {
        return moment(new Date(pct.created_at)).format("DD/MM/YYYY HH:mm:ss");
      }
    
      function _initData(){
        return {
          _dialogSelector: _dialogSelector,
          rejectFunc: null,
          resolveFunc: null,
          punchCardTransactions: null,
          result: null,
          fromDate: moment(new Date()).startOf("month").toDate(),
          toDate: moment(new Date()).endOf("month").toDate(),
          dateFormat: "dd/MM/yyyy",
          punchCardItemId: null,
          itemName: null,
          cardNumber: null,
        };
      }
    
      function _doConfirm(){
          this.close();
          this.resolveFunc();
      }

      function _doPrint(){
        Printing.Reports.printPunchCardTransactions(this.result)
        this.close();
        this.resolveFunc();
  
    }      
    
      function _doReject(){
        this.close();
        this.rejectFunc(new Error(app.userCancelledGlobalMessage));
      }
    
      function _close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }
    
    
    }}}
    