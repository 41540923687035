// use: $("input field selector").autoselect();
(function( $ ){

  var methods = {
    init : function( options ) {
      return this.each(function(){
        var inited = $(this).data('cleanable-inited');
        if (!inited) {
          $(this).data('cleanable-inited', true);
          if (options.cleanSelector !== null && options.cleanSelector !== undefined) {
            let $el = $(this);

            $(options.cleanSelector).data('cleanable-button-related-input', $el);
            $(options.cleanSelector).click(function() {
              $(options.cleanSelector).data('cleanable-button-related-input').val('');
              $(options.cleanSelector).css("visibility", "hidden");
            });

            $el.focus(function() {
              $(options.cleanSelector).css("visibility", "visible");
            }).blur(function() {
              if ($(options.cleanSelector).length > 0 && !$(options.cleanSelector).is(':hover')) {
                $(options.cleanSelector).css("visibility", "hidden");
              }
            });
          }
        }
      });
    }
  };

  $.fn.cleanable = function( method ) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on rubysoft.cleanAble' );
    }    

  };

})( jQuery );