module PositiveTS {
  export module Components {
      export module VouchersWithoutInterfacesReportDialog {
  
        const dialogSelector = "vouchers-without-interfaces-report-dialog"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            keyboardImg: `${(<any>window).images_path}/keyboard.jpg`,
            availableVouchers: [],
            selectedVoucher: null,
            dateFormat: "dd/MM/yyyy",
            startDate: moment(new Date()).startOf("month").toDate(),
            endDate : moment(new Date()).endOf("month").toDate(),
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.VouchersWithoutInterfacesReportDialog(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            computed: {
              canPrint () {
                return Boolean(this.selectedVoucher)
              }
            },
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              cancel() {                
                this.close();
              },
              async printReport(summeryOnly){

                if(moment(this.startDate).diff(moment(this.endDate),"years") != 0){
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('vouchersWithoutInterfacesReport.periodTooLong'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  },null,null)

                  return;
                }

                app.showLoadingMessageDialog(i18next.t('vouchersWithoutInterfacesReport.generatingReport'));
                
                try {
                  let report_print = {
                    lines: await this.getReport(),
                    startDate: this.startDate,
                    endDate: this.endDate
                  }
                  console.log('Voucher without interfaces report:', report_print)
                  const customerName = this.selectedVoucher.name;
                  Printing.Reports.printRoshemetVoucherWithoutInterfacesReport(report_print,session.pos.companyName,session.pos.storeName,customerName,summeryOnly);        
                }
                catch(e) {
                  console.error(e);
                }
                finally {
                  app.hideLoadingMessageDialog();
                }
              },

              async getReport () {
                let report = [];
                let fromDate = this.startDate.toJSON();
                let toDate = this.endDate.toJSON();
                let type_code = this.selectedVoucher.typeID;

                let res = await PositiveTS.Service.FetchReq.jsonReq(`/vouchers/roshemet_vouchers_report/?type_code=${type_code}&fromDate=${fromDate}&toDate=${toDate}`, 
                'get')
                
                if (res.result && res.result.report){
                  report = res.result.report;
                }

                return report;
              },

              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  this.dialogElem.close();
                }
                this.cleanData()
              },
              async open () {
                  
                this.initialized = true;

                if (this.dialogElem == null) {
                  this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                }
                app.showLoadingMessage(i18next.t('vouchersWithoutInterfacesReport.loadingVouchers'))
                this.availableVouchers  =  await this.getVouchers()
                app.hideLoadingMessage();
                if(this.availableVouchers.length > 0){
                  this.selectedVoucher = this.availableVouchers[0];
                }
                this.dialogElem.showModal();
                this.blurInputsOnElement(this.dialogElem)
              },

              async getVouchers () {
                let vouchers = []
                let sql = `SELECT * FROM Voucher WHERE typeID != 999 AND smartVoucherType = ''`
                await PositiveTS.Service.WasmDB.promiseSql(sql)
                  .then((res) => {
                    if (res){
                      vouchers = res
                    }
                  })
                  .catch((e) => console.error(e))

                return vouchers;
              }
            },
            data: initData,
          }
          
          VueApp.component('vouchers-without-interfaces-report-dialog',component)
        }
      }
    }
  }
