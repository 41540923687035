module PositiveTS {
    export module Components {
        export module Mixins {
            type MenuButton = PositiveTS.Types.MenuButton;
            /** This mixin is a straight copy of component of "itemButtonMenu"
             */
            export let itemButtonMenuMixin = {
                methods: {
                    openVoucherId(voucherId, focusField = 'barcode') { //TODO: refactor this once we convert the voucher screen to Vue component
                        return posVC.goToPaymentScreen()
                            .then(function () {
                                PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('voucher')
                                var voucher = new PositiveTS.Storage.Entity.Voucher();
                                return voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);
                            })
                            .then(function (result) {
                                for (var i = 0; i < result.length; i++) {
                                    if (result[i].typeID === voucherId) {
                                        return result[i];
                                    }
                                }
                                throw new Error("No smart voucher Valu defined");
                            })
                            .then(async function (voucherToAdd) {
                                Pinia.globalStore.setSelectedVoucherType(voucherToAdd);
                                await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.putAllAmountToPayInAmountField();
                            });
                    },

                    menuItemClicked(button: MenuButton) {
                        let self: any = this;

                        if (!button.id && !button.code) {
                            return;
                        }

                        if (button.isPrimaryMenuItem) {
                            self.setLoadingState()
                            self.getPrimaryMenuItems(button)
                                .then(result => {
                                    self.currentLevel = 1
                                    self.loading = false
                                    self.buttons = result;
                                })
                        }
                        if (button.isSecondaryMenuItem) {
                            self.setLoadingState()
                            self.getSecondaryMenuItems(button)
                        }
                        if (button.isThirdMenuItem) {
                            self.setLoadingState()
                            self.getThirdMenuItems(button)
                        }
                        if (button.isItem) {
                            self.addItemToSale(button)
                        }
                    },

                    async getTopLevelMenuItems(isRoshemetParam = null): Promise<Array<MenuButton>> {
                        let self: any = this;
                        self.setLoadingState()

                        if (this.isFlightsGroupsMenu) {
                            return await self.getFlightsTopLevelMenuItems();
                        }

                        let menuButtons: Array<any> = [];
                        let isRoshemet = isRoshemetParam != null ? isRoshemetParam : this.isRoshemet
                        if (!isRoshemet) {
                            let primaryMenuItems = await (new PositiveTS.Storage.Entity.PrimaryMenuItem()).getAll(this.effectiveMigvanId || 0);

                            if (this.selfServiceScrollMenu) {
                                if (this.primaryMenuButtons.length == 0) {
                                    this.primaryMenuButtons = primaryMenuItems;
                                }
                            } else {
                                menuButtons = primaryMenuItems;
                            }

                            let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(0, 'primaryMenuItemId', 1, this.effectiveMigvanId || 0)
                            menuButtons.push(...items);
                        }
                        else {
                            menuButtons = session.allDepartments.map(dep => <MenuButton>{
                                name: dep.name, isPrimaryMenuItem: true, level: 0, id: String(dep.id)
                            })
                            let noCatButtons = Array.from(session.allItems.values())
                                .filter(item => (item.departmentId == -1 || item.departmentId == null || item.showInMainMenu == true) && !item.locked && !item.hideFromMenu)
                                .sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
                                .sort((a, b) => (a.menuItemSortOrder > b.menuItemSortOrder) ? 1 : ((b.menuItemSortOrder > a.menuItemSortOrder) ? -1 : 0))
                                .map(item => <MenuButton>{ name: item.description, isItem: true, level: 0, id: item.code, code: item.code, price: item.priceZarhan })
                            menuButtons.push(...noCatButtons);
                        }
                        if (this.simpleSelfService && this.shouldShowOtotPrintBracelet) {
                            let getCardButton: MenuButton = {
                                name: i18next.t("pos.collectOtotTickets"), isPrimaryMenuItem: true,
                                level: 0, onClickedFunc: this.printOtotTicket, details: null, hasPicture: false,
                                isShowDetails: false, pictureUrl: null, price: null, order: 1
                            };
                            menuButtons.unshift(getCardButton);
                            if (this.simpleSelfService && this.showOtotRFIDBraceletActivation) {
                                let activateBraceletButton:MenuButton = { name: i18next.t("pos.activateRfidBracelet"), isPrimaryMenuItem: true, 
                                  level: 0, onClickedFunc: this.scanOtotQrToActivateRFIDBracelet, details : null, hasPicture: false,
                                  isShowDetails: false, pictureUrl: null, price: null, order: 1
                                };
                                menuButtons.unshift(activateBraceletButton);
                            }
                        }


                        if (menuButtons.length > 0) {
                            this.hasDefaultMenuItems = true;
                        }

                        return menuButtons;

                    },

                    fromCategoriesToMenuItem(categories, level) { //for flights (JR)
                        let itemsByName = _.keyBy(Array.from(session.allItems.values()), 'description');

                        return categories.map(category => ({
                            level: level, isItem: false, itemId: 0, sortOrder: 0, name: category.name,
                            code: category.name, color: null, id: category.id, hasPicture: itemsByName[category.name] && itemsByName[category.name].hasPicture, isPrimaryMenuItem: level == 1,
                            isSecondaryMenuItem: level == 2, isThirdMenuItem: level == 3
                        }));
                    },

                    async getFlightsTopLevelMenuItems() { //for flights (JR)
                        let allItems = this.currentBarsetItemsAsItemsWithNotInventoryItem;
                        let itemsForMenu = PositiveTS.Storage.Entity.Item.fromItemsToMenuItem(allItems.filter(item => posUtils.isBlank(item.itemMainClassID)), 1);
                        let categoriesIds = allItems.filter(item => !posUtils.isBlank(item.itemMainClassID)).map(it => parseInt(it.itemMainClassID));
                        let categories = Storage.Entity.PrimaryCategory.getByIds(categoriesIds, 'name');
                        let menuButtons = this.fromCategoriesToMenuItem(categories, 1);
                        return menuButtons.concat(itemsForMenu);
                    },

                    async getPrimaryMenuItems(button: MenuButton) {
                        let self: any = this;
                        self.parentButton = button;
                        self.setLoadingState()

                        if (this.isFlightsGroupsMenu) {
                            return await self.getFlightsPrimaryMenuItems(button);
                        } else {
                            if (this.isRoshemet) {
                                return Array.from(session.allItems.values()).filter(item => item.departmentId == Number(button.id) && !item.locked && !item.hideFromMenu)
                                    .map(item => <MenuButton>{
                                        level: 1, isItem: true, name: item.description,
                                        code: item.code, id: item.code, order: item.menuItemSortOrder, price: item.priceZarhan
                                    }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                    .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

                            }
                            else {
                                let menuButtons = await (new PositiveTS.Storage.Entity.SecondaryMenuItem()).getAll(button.id)
                                let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(button.id, 'primaryMenuItemId', 2, this.effectiveMigvanId || 0)
                                return menuButtons.concat(items);
                            }
                        }
                    },

                    async getFlightsPrimaryMenuItems(button: MenuButton) {
                        let allLevelItems = this.currentBarsetItemsAsItemsWithNotInventoryItem.filter(item => item.itemMainClassID == button.id);
                        let itemsForMenu = PositiveTS.Storage.Entity.Item.fromItemsToMenuItem(allLevelItems.filter(item => posUtils.isBlank(item.itemMainSubClassID)), 2);
                        let categoriesIds = allLevelItems.filter(item => !posUtils.isBlank(item.itemMainSubClassID)).map(it => parseInt(it.itemMainSubClassID));
                        let categories = await Storage.Entity.SecondaryCategory.getByIds(categoriesIds, 'name');
                        let menuButtons = this.fromCategoriesToMenuItem(categories, 2);

                        return menuButtons.concat(itemsForMenu);

                    },

                    async getSecondaryMenuItems(button: MenuButton) {
                        let self: any = this;
                        self.subButton = button;
                        self.setLoadingState()
                        let menuButtons;

                        if (this.isFlightsGroupsMenu) {
                            menuButtons = await self.getFlightsSecondaryMenuItems(button);
                        } else {
                            menuButtons = await (new PositiveTS.Storage.Entity.ThirdMenuItem()).getAll(button.id)

                            let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(button.id, 'secondaryMenuItemId', 3, this.effectiveMigvanId || 0)
                            menuButtons = menuButtons.concat(items);
                        }

                        self.currentLevel = 2;
                        self.loading = false;
                        self.buttons = menuButtons;
                        return menuButtons;
                    },


                    async getFlightsSecondaryMenuItems(button: MenuButton) { //for flights (JR)
                        let allLevelItems = this.currentBarsetItemsAsItemsWithNotInventoryItem.filter(item => item.itemMainSubClassID == button.id);

                        return PositiveTS.Storage.Entity.Item.fromItemsToMenuItem(allLevelItems, 3);
                    },

                    async getThirdMenuItems(button: MenuButton) {
                        let self: any = this;
                        self.subThirdButton = button;
                        self.setLoadingState()

                        let items = await Storage.Entity.ItemMenuItem.getItemsForMenu(button.id, 'thirdMenuItemId', 4, this.effectiveMigvanId || 0)

                        self.currentLevel = 3;
                        self.loading = false;
                        self.buttons = items;
                        return items;
                    },

                    async addItemToSale(button: MenuButton) {
                        let self: any = this;
                        if (!button.isItem || !button.code) {
                            return;
                        }

                        
                        if (jsonConfig.getVal(jsonConfig.KEYS.allowOutOfStockItems)) {
                            if (this.outOfStockItemCodesByCode[button.code]) {
                                let message = jsonConfig.getVal(jsonConfig.KEYS.outOfStockItemMessage) || i18next.t('outOfStockItemsDialog.outOfStockMessage')
                                app.showAlertDialog({
                                    header: i18next.t('error'),
                                    content: message,
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                }, null, null);
                                return;
                            }
                        }

                        if (this.isRoshemet) {
                            let item = session.allItems.get(button.code); //await appDB.localItems.where('code').equals(button.code).first();
                            let itemBarcode = { size: 'null', color: 'null', barcode: item.code };
                            let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
                            saleItem.saleID = posVC.sale.id;
                            saleItem.rowNumber = posVC.getRowNumber();
                            saleItem.quantity = PositiveTS.VueInstance.$refs.pos.times;
                            PositiveTS.VueInstance.$refs.pos.times = 1;
                            let changeUnitPrice = null
                            const canFastChangeItemPriceByKeypad = (jsonConfig.getVal(jsonConfig.KEYS.fastChangeItemPriceByKeypad) && PositiveTS.VueInstance.$refs.pos.amount != 0);
                            if (canFastChangeItemPriceByKeypad) {
                                if (Service.Withdrawal.itemIsCashWithdrawal(saleItem)) {
                                    app.showAlert({
                                        header: i18next.t('error'),
                                        content: i18next.t('withdrawal.roshemetFastBtnsError'),
                                        continueButtonText: i18next.t("ok"),
                                        hideCancelButton: true
                                    }, null, null);
                                    return;
                                }
                                const pos_ref = PositiveTS.VueInstance.$refs.pos;
                                changeUnitPrice = pos_ref.amount;
                                pos_ref.amount = 0;
                                pos_ref.times = 1;
                                pos_ref.fields['amount'].hasDecimal = false;
                            }
                            // Add it to sale
                            await posVC.persistNewSaleItem(saleItem, false, false, changeUnitPrice);

                        }
                        else {
                            await posVC.addItemByBarcodeOrByCode(button.code, true, true)
                        }
                        if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)) {
                            // if new restaurant POS, scroll to bottom of order items
                            if(document.querySelector(".order-items")){
                                document.querySelector(".order-items").scrollTop = document.querySelector(".order-items").scrollHeight
                            }
                        }

                        if (jsonConfig.getVal(jsonConfig.KEYS.returnToMainMenuAfterSelectItemFromMenu) && self.currentLevel != 0) {
                            self.backToTop()
                        }
                    },

                    setLoadingState() {
                        let self: any = this;
                        self.buttons = [<MenuButton>{ name: "טוען...", id: undefined }]
                        self.loading = true;
                    },

                    async loadDefaultMenu() {
                        let self: any = this;
                        if (!self.selfServiceScrollMenu) {
                            self.backToTop();
                            return;
                        }
                        let items = await self.getTopLevelMenuItems();
                        if (items.length > 0) {
                            self.backToTop();
                            return;
                        }

                        self.setLoadingState();
                        let menuButtons: Array<MenuButton> = await (new PositiveTS.Storage.Entity.PrimaryMenuItem()).getAll(this.effectiveMigvanId || 0);

                        let button = menuButtons.find((btn) => posUtils.isPresent(btn.id) && btn.id == session.pos.defaultMenuId);
                        if (button) {
                            let result = await self.getPrimaryMenuItems(button);
                            self.currentLevel = 1;
                            self.loading = false;
                            self.buttons = result;
                        }
                        else {
                            self.backToTop();
                            return;
                        }

                    },

                    backToTop() {
                        let self: any = this;
                        self.setLoadingState()
                        self.getTopLevelMenuItems()
                            .then(result => {
                                self.currentLevel = 0;
                                self.loading = false;
                                self.buttons = result;
                            })
                        if(document.querySelector("#pos-menu-buttons")){
                            document.querySelector("#pos-menu-buttons").scrollTop = 0
                        }
                    },
                    openPaymentScreen(method) {
                        if (posVC.saleItems.length == 0) {
                            app.showAlert({
                                header: "לא ניתן לשלם",
                                content: "לא נבחרו פריטים",
                                hideCancelButton: true
                            })
                            return;
                        }
                        posVC.goToPaymentScreen()
                            .then(() => {
                                PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod(method)
                            })

                    },

                    payWithValuCard() {
                        if (posVC.saleItems.length == 0) {
                            app.showAlert({
                                header: "לא ניתן לשלם",
                                content: "לא נבחרו פריטים",
                                hideCancelButton: true
                            })
                            return;
                        }
                        var simplePosValuCardTypeID = jsonConfig.getVal(jsonConfig.KEYS.simplePosValuCardTypeID);
                        this.openVoucherId(simplePosValuCardTypeID)
                    },
                    qtyForButton(button: MenuButton) {
                        if (button.code) {
                            let val = this.codeToQtyMap.get(button.code);
                            return val ? val : 0;
                        }
                        else {
                            return 0;
                        }
                    },

                    payWithHakafa() {
                        if (Service.Hakafa.showHakafaBtn() && Service.Hakafa.hasCustomer()) {
                            PositiveTS.Components.PosPaymentButtons.openVoucherId(PositiveTS.Service.Hakafa.getVoucherID(), 'amount');
                        } else {
                            app.promiseShowAlert({
                                header: i18next.t('error'),
                                content: i18next.t('itIsNotPossibleToPayHakafaWithMizdamen'),
                                continueButtonText: i18next.t('ok'),
                                hideCancelButton: true
                            })

                        }
                    },
                    companyID() {
                        return session.company.companyID;
                    },
                    tenantID() {
                        return session.company.tenantID;
                    },
                    async printOtotTicket() {
                        await Service.Otot.printEntranceTagFromExistingQrCode();
                    },
                    async scanOtotQrToActivateRFIDBracelet() {
                        await Service.Otot.activateRfidBraceletViaQrCode();
                    },
                },
                computed: {
                    simpleSelfService(){
                        return Pinia.globalStore.simpleSelfService
                    },
                    isRoshemet(){
                        return Pinia.globalStore.isRoshemet
                    },
                    isSpecialItemCode(){
                        return Pinia.globalStore.isSpecialItemCode
                    },
                    saleItems(){
                        return Pinia.globalStore.saleItems
                    },
                    codeToQtyMap(){
                        return Pinia.globalStore.codeToQtyMap
                    },
                    effectiveMigvanId(){
                        return Pinia.globalStore.effectiveMigvanId
                    },
                    outOfStockItemCodesByCode(){
                        return Pinia.globalStore.outOfStockItemCodesByCode
                    },
                    selfServiceSuperMarket(){
                        return Pinia.globalStore.selfServiceSuperMarket
                    },
                    isFlightsGroupsMenu(){
                        return Pinia.posMenu.isFlightsGroupsMenu
                    },
                    currentBarsetItemsAsItems(){
                        return Pinia.flightsStore.currentBarsetItemsAsItems;
                    },
                    isChargeGiftCard(){
                        return Pinia.globalStore.saleItems.length > 0 && Pinia.globalStore.saleItems[0].itemCode == "999991"
                    },
                    noSaleItems(){
                        return Pinia.globalStore.saleItems?.length == 0
                    },
                    showCredit(){
                        return Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCredit))
                    },
                    usePictures(){
                        return Boolean(jsonConfig.getVal(jsonConfig.KEYS.usePictures))
                    },
                    shouldShowOtotPrintBracelet() {
                        return Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotPrintBraceletButton);
                    },
                    showOtotRFIDBraceletActivation() {
                        return Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotRFIDBraceletActivation);
                    },
                    currentBarsetItemsAsItemsWithNotInventoryItem() {
                        let itemsById = _.keyBy(this.currentBarsetItemsAsItems, 'id');

                        for (let item of session.allItems.values()) {
                            if (!item.hasInventory) {
                                itemsById[item.id] = item;
                            }
                        }

                        return Object.values(itemsById);
                    },
                    alignItemsMenuToCenter() {
                        return jsonConfig.getVal(jsonConfig.KEYS.alignItemsMenuToCenter);
                    },
                    alignContentToStart() {
                        return this.selfServiceScrollMenu && this.buttons.length >= 9
                    },
                    itemButtonsMenuSelfServiceScrollCSSClasses() {
                        return {
                            'with-top-buttons': this.currentLevel > 0,
                            'has-pictures': this.usePictures,
                            'align-to-center': this.alignItemsMenuToCenter,
                            'need-to-scroll': this.needToScrol,
                            'align-content-to-start': this.alignContentToStart,
                        };
                    },
                    isAndroid() {
                        return session.isAndroid;
                    },
                    needToScrol() {
                        if ((screen.height < 1441 && this.buttons.length > 9) || this.buttons.length > 15)
                            return true;
                        else
                            return false;
                    },
                    showBackButtonInScrollMode() {
                        return this.currentLevel > 1 || (this.currentLevel == 1 && this.hasDefaultMenuItems);
                    },
                    currentMenuTree() {
                        let currentMenuTreeButtons = [];

                        if (this.currentLevel >= 1) {
                            currentMenuTreeButtons.push(this.parentButton);
                        }
                        if (this.currentLevel >= 2) {
                            currentMenuTreeButtons.push(this.subButton);
                        }
                        if (this.currentLevel >= 3) {
                            currentMenuTreeButtons.push(this.subThirdButton);
                        }

                        return currentMenuTreeButtons.filter(button => button.id);
                    },
                    beforeLastMenuButton() {
                        return this.currentMenuTree[this.currentMenuTree.length - 2];
                    }
                },
                data(): PositiveTS.Types.ItemButtonMenuCompData {
                    return {
                        currentLevel: 0,
                        initialzied: true,
                        loading: true,
                        arrowImg: `${(<any>window).images_path}pos/arrow.png`,
                        addingInProgress: false,
                        parentButton: { name: "", id: undefined },
                        subButton: { id: undefined, name: "", color: undefined },
                        subThirdButton: { id: undefined, name: "", color: undefined },
                        buttons: [{ name: "טוען...", id: undefined, color: undefined }],
                        primaryMenuButtons: [],
                        isShowSelfServiceHakafa: jsonConfig.getVal(jsonConfig.KEYS.isShowSelfServiceHakafa),
                        isShowSelfServicePayWithValuCard: jsonConfig.getVal(jsonConfig.KEYS.isShowSelfServicePayWithValuCard),
                        selfServiceHakafaBtnDescription: jsonConfig.getVal(jsonConfig.KEYS.selfServiceHakafaBtnDescription),
                        showPaymentButtonsInStandAlone: jsonConfig.getVal(jsonConfig.KEYS.showPaymentButtonsInStandAlone),
                        selfServiceScrollMenu: jsonConfig.getVal(jsonConfig.KEYS.selfServiceScrollMenu),
                        scrollSelector: ".item-button-menu #pos-menu-buttons",
                        hasDefaultMenuItems: false,
                    }
                },
            }
        }
    }
}