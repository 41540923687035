module PositiveTS {
  export class Session {
    SessionChangedNotification = 'PositiveSessionSessionChangedNotification'
    isLoggedIn = false
    pos:Storage.Entity.Pos = null
    store:Storage.Entity.Store
    gateToken: string
    bgKeeper: Service.BackgroundKeeper;
    isAndroid:boolean
    addonFilesVersion:number = -1
    isShva30: boolean = false;
    company:Storage.Entity.Company
    allItemBarcodes:Map<string,Storage.Entity.ItemBarcodeWithItem> = new Map<string,Storage.Entity.ItemBarcodeWithItem>()
    allItems:(Map<string,Storage.Entity.Item> | typeof Service.AllItems) = new Map<string,Storage.Entity.Item>()
    allPromotions:Array<Storage.Entity.Promotion>
    allPromotionStores:Array<Storage.Entity.PromotionStore>
    allSpecialPromotions:Array<Storage.Entity.SpecialPromotion>
    allDiscounts:Array<Storage.Entity.Discount>
    allMenuItems:Array<Storage.Entity.ItemMenuItem>
    allDepartments:Array<Storage.Entity.Department>
    systemSettings = null
    creditCardPaymentTypes = null
    vueInstance;
    worker:Worker;
    dictionaryStrings: { [index: string]: string }
    banxToken: string;
    mobilePhoneRepairSettings: typeof Service.MobilePhoneRepair.settings
    customerGroupsByName: any

    constructor() {
      this.dictionaryStrings = this.getDict();
    }
    init() {
      window.localStorage.removeItem('isOnline'); // remove the isOnline flag and let the socketManager determine our current online state
      var aThis = this;
      return new Promise((resolve,reject) => {
      // Fetch all available POSes
        let pos = new PositiveTS.Storage.Entity.Pos();
        pos.all(null)
        .then(results => {
          if (results.length === 0) {
          aThis.isLoggedIn = false;
          aThis.pos = null;
          // Post the relevant notification
          PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);
            reject();
        } else {
          // Set the POS to the first one
            aThis.pos = results[0];
          aThis.isLoggedIn = aThis.pos.isLoggedIn;

          aThis.loadSessionDataFromStorage(true)
          .then(() => {
            if(session.isAndroid){
              if(Android.getVersion() >= "2.0"){
                Android.setPosId(session.pos.deviceID);
              }
            }
            if(session.isAndroid && Android.StartPinpadConnection !== undefined && !jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment)){
              setTimeout(function(){
                app.showLoadingMessage(i18next.t('restartingPinpadConnection'));
                let result = Android.StartPinpadConnection();
                if(!result){
                  console.error("Pinpad initialization failed");
                }
                app.hideLoadingMessage();
              },0)
              app.hideLoadingMessage();
            }

            Service.Delivery.startDeliveryDBHook();

            if(!localStorage.getItem("XFields")){
              let XFieldsObj = {
                unknownXFields: [],
                failedXFields: [],
                successfullXFields: [],
              }

              localStorage.setItem("XFields",JSON.stringify(XFieldsObj))
            }

            Service.EMV.getInstance().initXFieldSequencesIfNeeded();

            if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
              PositiveTS.Service.Dalpak.init();
            }

            if (Service.PrimaryPosPrint.isActive()) {
              Service.PrimaryPosPrint.init();
            }

            if(jsonConfig.getVal(jsonConfig.KEYS.isCibusDeliveryApiActive)){
              Service.PluxeeService.init();
            }

            if (jsonConfig.getVal(jsonConfig.KEYS.allowOutOfStockItems)) {
              Service.OutOfStock.init();
              Pinia.globalStore.loadOutOfStockItemsCodes();
            }

            if(jsonConfig.getVal(jsonConfig.KEYS.multipassPolicePosId)){
              (new PositiveTS.Service.MultipassPolice()).setup()
            }

            if(jsonConfig.getVal(jsonConfig.KEYS.passwordProtected) && !jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
              if(PositiveTS?.VueInstance?.$refs?.loginDialog) {
                Pinia.componentsStore.openComponent( {componentName:"loginDialog", args: [false,true]});
              }
            }

            if(jsonConfig.getVal(jsonConfig.KEYS.forceZEveryDay)) {
              PositiveTS.Service.ForceDailyZ.startCheckingIfZHasBeenDone();
            }

            if(jsonConfig.getVal(jsonConfig.KEYS.preventUseOfGoogleTranslate)) {
              let htmlTag = document.getElementsByTagName("html")[0]
              htmlTag.setAttribute('translate', 'no')

              console.log('prevent use of Google Translate');
            }

            if (!PositiveTS.Reachability.isOnline) {
              // Post the relevant notification
              PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);
                return resolve();
            }

            Service.WebsocketSync.SyncServerClient.startInstanceIfNeeded();

            // Check POS status at FlexRT
            let url = Shared.Constants.remoteRoot + 'posstatus/{posDeviceID}';
            url = url.replace('{posDeviceID}', session.pos.deviceID);
            $.post(url, {},function(results) {
              if (!results.status) {
                // --- POS is disabled ------------------------------------------
                // Delete the pos (which has the affect of logging-out)
                Storage.Entity.Pos.deletePosTables()
                .then(function() {
                  // Update the POS
                  aThis.pos = null;
                  aThis.isLoggedIn = false;

                  // Post the relevant notification
                  PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);

                  // Delete also all the data in the database
                  storage.deleteAll();

                  // Alert the user
                  app.showAlert({
                      header: i18next.t('relogin'),
                      content: i18next.t('posDisabledPleaseLogin'),
                      continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, function() {
                    // Go to the login page
                      pNavigator.pushPage('login', i18next.t('pageTitle.login'), null, null);
                  }, null);
                })
                .catch(function(error) {
                  console.error('POS is disabled by remote, but deleting it locally failed', error);
                });
              } else {
                  resolve();
              }
            }, 'json')
            .fail(function() {
              // --- Checking POS status failed
              // Tell the user
              app.showAlert({
                  header: i18next.t('error'),
                content: i18next.t('posOfflineMessage'),
                  continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
              console.error('Error connecting to server to check POS status');
                resolve();
            });

            if( localStorage.getItem("posLang") == 'en' && (session.pos.hasFlights || jsonConfig.getVal(jsonConfig.KEYS.isIsrairPos))) {
              localStorage.setItem("posLang", 'en_flight');
              i18next.locale = 'en_flight';
            }

            PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);
          })

        }
        // Post the relevant notification
        })
        .catch(error => {
        // --- Fetching POSed from db failed
        // Alert the user
        app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('cannotFetchPosFromDBReload'),
            continueButtonText: i18next.t('refresh'),
            cancelButtonText: i18next.t("ok")
        }, function() {
          // Continue callback
          posUtils.refreshPos();
        }, null);
        console.error('Could not fetch all available POSes from db', error);
      });

      })
    }

    async login(username, password) {

      if(!username || !password) {
        app.showAlert({
          header: i18next.t('error'),
        content: i18next.t('usernamePasswordDoesntExists'),
          continueButtonText: i18next.t("ok"),
        hideCancelButton: true
        }, null, null);
        return false
      }

      await appDB.clearAllTables();
      localStorage.clear();

      return new Promise((resolve,reject) => {
        let aThis = session;


        // Prepare data for send
        var data = { username: username, password: password, devicePhysicalId: null };

        var _json_config_physicalID = jsonConfig.getVal(jsonConfig.KEYS.physicalID);
        // If this POS is already registered (not its first login), than add the POS physical ID to the data too
        if (aThis.pos !== null) {
          data.devicePhysicalId = aThis.pos.physicalID;
        } else if (_json_config_physicalID) {
          data.devicePhysicalId = _json_config_physicalID;
        }



        var url = Shared.Constants.remoteRoot + 'login';
        $.post(url, data, function(results) {
          if (results && results.success) {
            PositiveTS.Service.CheckPosPropriety.setStatus(PositiveTS.Service.CheckPosPropriety.STATUSES.WAITING)
          }
          aThis.persistHomeControllerResult(username, password, results, true)
          .then(() => {
            resolve();
          });
        }, 'json')
          .fail(function (err) {
            console.error('An HTTP error has occured while login', err);
            let msg = err.status == 429 ? err.responseText : (i18next.t("loginFailed") + ' ' + i18next.t("mustBeOnline") + ' (HTTP error)');
            reject(msg);
          });
      })

    }

    async logout() {
      let aThis = session;

      // Update the POS
      aThis.pos.isLoggedIn = false;
      aThis.pos.loginUsername = '';
      aThis.pos.loginPassword = '';
      try {
        await aThis.pos.persist();
        // Update the login state
        aThis.isLoggedIn = false;

        // Call the success callback


        // Post the relevant notification
        PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);
      }
      catch(error) {
        // Call the error callback
        console.error('Cannot persist pos. ' + error.message);
        Service.Logger.error(error)
        throw new Error(i18next.t("logoutFailed") + ' (pos persist failed)');
      };
    }

    getParameterInvoiceType() {
      if(jsonConfig.getVal(jsonConfig.KEYS.overrideParameterInvoiceType)) {
        return jsonConfig.getVal(jsonConfig.KEYS.overrideParameterInvoiceType);
      }
      return session.pos.parameterInvoiceType;
    }

    async convertTables(shouldConvertTables) {
      if (!shouldConvertTables) {
        return;
      }

      if (!session.pos.parameterIsAutomaticEmployeeStoreConnection && posUtils.isBlank(localStorage.getItem("readOnlyDbDataLoadedAndWebDBRemoved"))) {
        let db = Service.WebDB.getDbIfSupported();

        if (db && await Service.WebDB.tableExists('EmployeeStore', db)) {
          // update employee store
          let employeeIds = Array.from(<any>(await PositiveTS.Service.WebDB.promiseSql(`select * from EmployeeStore`, db)).rows).map(row => (row as any).employeeID);
          Storage.Entity.EmployeeStore.saveFromArray(employeeIds);
        }
      }
    }

    async cacheItems(shouldCacheItems) {
      if (!shouldCacheItems) {
        return;
      }
      console.time("cacheItems")
      let promises = []
      if (session.pos.isRoshemet) {
        promises.push(appDB.departments.orderBy('[sortOrder+name]').toArray())
        promises.push(appDB.localItems.toCollection().sortBy('description'))
      }
      else {
        promises.push(Promise.resolve())
        promises.push(Promise.resolve())
      }
      if(!posUtils.isNullOrUndefinedOrEmptyString(session.store)) {

        if (session.pos.isRoshemet){
          promises.push(appDB.promotions.toCollection().sortBy('code'))
          promises.push(Promise.resolve({rows: new Set()})); //empty promotions stores
        }else{
          let cond = ''
          if (session.pos.useNewPromotions) {
            cond = ` inner join promotionStore on promotion.code = promotionStore.promotionID and promotionStore.storeID=${session.store.storeID}`;
          }
          promises.push(Service.WasmDB.promiseSql(`select * from promotion ${cond}`))

          if (session.pos.useNewPromotions) {
            promises.push(Promise.resolve({rows: new Set()}));
          }
          else {
            promises.push(Service.WasmDB.promiseSql(`select * from promotionstore ${cond}`))
          }
        }

      }
      promises.push(new Storage.Entity.SpecialPromotion().all())
      promises.push(new Storage.Entity.Discount().all())
      promises.push(new Storage.Entity.ItemMenuItem().all())

      let results = await Promise.all(promises);

      if (session.pos.isRoshemet) {
        session.allItems = new Map();
        session.allDepartments = results[0];
        let allItems = results[1];
        allItems.forEach(item => {
          session.allItems.set(item.code,item);
          if (!posUtils.isNullOrUndefinedOrEmptyString(item.barcode)) {
            session.allItemBarcodes.set(item.barcode,item);
          }
        })
      }
      else {
        let allItemBarcodes = [];
        session.allItems = Service.AllItems;
      }

      if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && jsonConfig.getVal(jsonConfig.KEYS.isPrimaryDeliveryPos) && (jsonConfig.getVal(jsonConfig.KEYS.isTenbisDeliveryApiActive)
      || jsonConfig.getVal(jsonConfig.KEYS.isPaiditDeliveryApiActive) || jsonConfig.getVal(jsonConfig.KEYS.isCibusDeliveryApiActive) || jsonConfig.getVal(jsonConfig.KEYS.isMishlohaOrdersApiActive)))
      {
       session.preFlightCheckExternalDeliveryItems()
      }

      if (session.pos.isRoshemet){
        session.allPromotions = results[2]
        session.allPromotions.forEach(p => p.dependantCodes = [])
      }
      else{
        let allPromotionRows = results[2];

        if(!posUtils.isNullOrUndefinedOrEmptyString(allPromotionRows)) {
          session.allPromotions = allPromotionRows;
        }
        else {
          session.allPromotions = [];
        }
      }


      if (!session.pos.isRoshemet) {
        if(!posUtils.isNullOrUndefinedOrEmptyString(results[3])) {
          session.allPromotionStores = results[3];
        }
        else {
          session.allPromotions = [];
        }
      }

      //filter from allPromotions the promotions that are not active in the current store.

      session.allPromotions = session.allPromotions.map(promo => {
        let promotion = new Storage.Entity.Promotion();
        promotion.importFromObject(promo);
        return promotion;
      })

      PositiveTS.Service.BlockItemPaymentMethod.initialize()
      session.allPromotions = session.allPromotions.filter(promo => {
        //session.store can be null on first session load before login
        if (!Boolean(promo.isActive) || session.store == null || promo.template == '1000') {
          return false;
        }
        if (!session.pos.useNewPromotions && !session.pos.isRoshemet) {
          let relevantPS = session.allPromotionStores.filter(ps => ps.promotionID == promo.code)
          return relevantPS.length == 0 || relevantPS.filter(ps => ps.storeID == String(session.store.storeID)).length > 0
        }
        else {
          return true;
        }
      })
      session.allSpecialPromotions = results[4];
      session.allDiscounts = results[5];
      session.allMenuItems = results[6];
      console.timeEnd("cacheItems")
    }

    preFlightCheckExternalDeliveryItems() :void {
      let tenbisApiGenericItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem))
      let deliveryItemCode = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode))
      let taItemCode = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode))
      let tenbisApiDiscountItem = true
      if(jsonConfig.getVal(jsonConfig.KEYS.isTenbisDeliveryApiActive) || jsonConfig.getVal(jsonConfig.KEYS.isPaiditDeliveryApiActive)) {
        if(posUtils.isBlank(session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiDiscountItemCode)))) {
          tenbisApiDiscountItem = false
        }
      }

      if (!tenbisApiGenericItem || !deliveryItemCode || !taItemCode || !tenbisApiDiscountItem) {
        let errorList: string = i18next.t('externalDeliveryOrders.notItemsError') + "\n";
        if (!taItemCode) {
          errorList += " - "
          errorList += i18next.t('externalDeliveryOrders.missingItems.pickup', { itemCode: jsonConfig.getVal(jsonConfig.KEYS.taItemCode) });
          errorList += "\n"
        }
        if (!deliveryItemCode) {
          errorList += " - "
          errorList += i18next.t('externalDeliveryOrders.missingItems.delivery', { itemCode: jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode) });
          errorList += "\n"
        }
        if (!deliveryItemCode) {
          errorList += " - "
          errorList += i18next.t('externalDeliveryOrders.missingItems.generalItem',{ itemCode: jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem) });
          errorList += "\n"
        }
        if (!tenbisApiDiscountItem) {
          errorList += " - "
          errorList += i18next.t('externalDeliveryOrders.missingItems.tenbisApiDiscountItem',{ itemCode: jsonConfig.getVal(jsonConfig.KEYS.tenbisApiDiscountItemCode) });
          errorList += "\n"
        }
        app.showAlert({
          header: i18next.t('error') + ' - ' + i18next.t('externalDeliveryOrders.notItemsErrorTitle'),
          content: errorList,
          continueButtonText:  i18next.t('ok'),
          hideCancelButton: true
      });
        return
      }

      Pinia.globalStore.activateExternalDeliveryOrders()

      return
    }

    loadSessionDataFromStorage(isReload = false) {
      app.showLoadingMessage(i18next.t("cachingItems"))
      var aThis = session;
      session.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1 && (<any>window).Android != null;

      return new Promise((resolve,reject) => {
        aThis.updateWebLogo()
        aThis.loadSystemSettings()
        .then(aThis.loadCompany)
        .then(aThis.loadStore)
        .then(aThis.loadCreditCardTypes)
        .then(aThis.loadCustomerGroups)
        .then(PositiveTS.Service.SmartVoucher.populateSpecialitems)
        .then(PositiveTS.Service.CustomerDisplay.sendParamsToScreen)
        .then(async () => {

          session.bgKeeper = new Service.BackgroundKeeper();
          session.bgKeeper.start();


          printer = new Printing.Base();


          (<any>window).aPrxlAdon = new PositiveTS.Service.PraxellAddon();
          (<any>window).isCatalogUpdated = new PositiveTS.Service.IsCatalogUpdated();

          printer.jzebra = Printing.getPrintObject();

          PositiveTS.Service.AutoEmployeeStore.runService();

          (<any>window).socketManager = new PositiveTS.Service.SocketManager();
          (<any>window).socketActions = new PositiveTS.Service.SocketActions();

          (<any>window).appDB = new PositiveTS.Service.PositiveDB();
          await (<any>window).appDB.init();
          (<any>window).serviceZMasterSlave = new PositiveTS.Service.ZMasterSlave();
          (<any>window).serviceCheckErn = new PositiveTS.Service.Check.ServiceCheckErn();


          // it is not essential, to get ERN parameters since
          // all parameters are limit at the Kupa level
          //// serviceCheckErn.actionFileUpload();



          paymentLimit.promiseCashTable()
          .then(()=>{return aThis.updateOldDepartmentsIfNeeded()})
          .then(() => { return aThis.cacheItems(isReload) })
          .then(() => { return aThis.convertTables(isReload) })
          .then(Service.FileUpload.getLocalManifestFile)
          .then((response) => {

            console.log(response);
            if (response != null) {
              try {
              aThis.addonFilesVersion = Number(response?.ver);
            }
              catch(e) {
                console.error(e);
                aThis.addonFilesVersion = -1
                aThis.isShva30 = false;
              }
            }
          })
          .then( ()=>{
            PositiveTS.Service.CustomerDisplay.setTextWelcome();
          })
          .then(async () => {
            if(jsonConfig.getVal(jsonConfig.KEYS.useQRCode) == true){
              session.gateToken = await Service.QRService.gateControlLogin();
            }
          })
          .then(() => {
            PositiveTS.VueServices.Initializer.init()
          .then((vueInstance) => {

            if (session && session.store){
              //Multi currencies
              let hasFlights = session && session.pos && session.pos.hasFlights;

              if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()){
                if (hasFlights) {
                  PositiveTS.Service.Currencies.fetchExternalCurrenciesFromDB();
                } else {
                  PositiveTS.Service.Currencies.fetchCurrencies().then(async isUpdated => {
                    try {
                      if (!isUpdated) {
                        await PositiveTS.Service.Currencies.showCurrenciesNotUpdatedMessage();
                      }
                    } catch(err) {
                      Service.Logger.error(err);
                    }

                    PositiveTS.Service.Currencies.fetchCurrenciesInterval()
                  })
                }
              }
              if(Pinia.elalStore.isOn){
                PositiveTS.Service.ElalApiService.UpdateEktAndSendToServer().then(res => {});
                PositiveTS.Service.ElalApiService.fetchElalReservationsInterval()
              }
              if (hasFlights || jsonConfig.getVal(jsonConfig.KEYS.isIsrairPos)) {
              Pinia.flightsStore.refreshStateFlight()
              }

              this.initBackgroundImageIfNeeded();
              const simpleSelfservice = Boolean(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService));
              if(simpleSelfservice){
                document.documentElement.style.setProperty('--self-service-generic-color',jsonConfig.getVal(jsonConfig.KEYS.selfServiceColorPickerForHeadlines));
              }

              Pinia.globalStore.setBeengoState(session.store);
              Pinia.globalStore.setCaveretPaymentState(Boolean(jsonConfig.getVal(jsonConfig.KEYS.isCaveretPayment)));
              Pinia.globalStore.setSimpleSelfService(simpleSelfservice)
              Pinia.globalStore.setItemWithDialogXL(Boolean(jsonConfig.getVal(jsonConfig.KEYS.itemWithDialogXL)))
              Pinia.globalStore.setDarkThemeMode(Boolean(jsonConfig.getVal(jsonConfig.KEYS.hasDarkTheme)))
              Pinia.globalStore.setRoshemetBigLayout(((<any>window).localStorage.getItem("useRoshemetBigLayout") == "true"));
              Pinia.globalStore.migvanUpdated(session.pos.migvanId || 0);
              const mq = window.matchMedia("(max-width: 801px) and (orientation: portrait)");
              const mqMobile = window.matchMedia("(max-width: 600px) and (orientation: portrait)");
              Pinia.globalStore.portraitModeChange(mq.matches); //set the initial state
              Pinia.globalStore.mobileLayoutChange(mqMobile.matches)
              Pinia.globalStore.setRoshemet(session.pos.isRoshemet);
              Pinia.globalStore.setPasswordProtected(jsonConfig.getVal(jsonConfig.KEYS.passwordProtected));
              Pinia.globalStore.setCurrency(Service.MultiCurr.getInstance().getPosCurrency())
              Pinia.globalStore.setSupportNumber(this.getSupportNumber());
              Pinia.globalStore.setDefaultImages(posUtils.isBlank(session.pos.defaultImages) ? {} : JSON.parse(session.pos.defaultImages));
              if(posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.emvPosNumber))) {
                Pinia.globalStore.setEmvPosNumber(String(session.pos.posNumber));
              }

              if(session && (session.addonFilesVersion >= 2.83 || session.isAndroid)) {
                Pinia.globalStore.setEmvTimeout(jsonConfig.getVal(jsonConfig.KEYS.emvTimeout))
                Pinia.globalStore.setAtmTimeout(jsonConfig.getVal(jsonConfig.KEYS.atmTimeout));
              }

              if ((<any>window).datadogLogs && session.pos) {
                datadogLogs.setGlobalContextProperty('service',session.pos.deviceID);
                let version = localStorage.getItem("Positive.VersionPath").split("/")[2];
                if (!posUtils.isBlank(version)) {
                  datadogLogs.setGlobalContextProperty('version',version)
                }
              };

              if ((<any>window).datadogRum && this.shouldRecordPos()) {
                console.info("Pos recording is enabled");
                this.startRecordingPos();
              } else { // Doing this in case change param of recording
                datadogRum.stopSession();
              }

              mq.addListener((mq) => {
                Pinia.globalStore.portraitModeChange(mq.matches); //update it on state change
              })
              mqMobile.addListener((mq) => {
                Pinia.globalStore.mobileLayoutChange(mq.matches); //update it on state change
              })

              if (jsonConfig.getVal(jsonConfig.KEYS.showPaymentBanx)){
                Service.BanxPayment.fetchToken();
              }

              PositiveShared.PicturesPrinting.init(session.pos.printerType);

              if (jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule)){
                PositiveTS.Service.MobilePhoneRepair.fetchSettings()
              }
            }

            PositiveTS.Service.DailyZReport.setInterval()

            if(jsonConfig.getVal(jsonConfig.KEYS.hasOnlyOnlineCustomerClub)) {
              appDB.positiveCustomer.clear()
            }

            if(PositiveTS.Service.OsherAd.isEnabled()){
              PositiveTS.Service.OsherAd.getInstance().init();
            }

            return PositiveTS.Service.EMV.getInstance().initializePinpadAddon();
          }).then(() => {

            Pinia.posStore.setIsPOSReady(true);

            resolve();
          })
        })

        })


      })


    }
    async updateOldDepartmentsIfNeeded(){
      try{
        if(session.pos.isRoshemet && !localStorage.getItem('isOldDepartmentsCompatible')){
            const allDepartments = await appDB.departments.toArray();
            if(allDepartments.length > 0){
              for (const department of allDepartments) {
                if (posUtils.isNullOrUndefinedOrEmptyString(department.sortOrder)) {
                  department.sortOrder = 0;
                  department.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  await appDB.departments.put(department);
                }
              }
              localStorage.setItem("isOldDepartmentsCompatible",'true')
            }
        }
      }catch(e){
        return Promise.reject(e)
      }
    }

    isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    fixedFloatTrunc(num, pow?) {
      if (!pow) {
        pow = 1;

        if (posUtils.isDefined(session.pos) && posUtils.isDefined(session.pos.parameterDecimalPrecision)) {
          pow = session.pos.parameterDecimalPrecision;
        }
      }
      return Math.round(Number(num) *  Math.pow(10, pow)) / Math.pow(10, pow);
    }
    fixedFloat(num: number | string, decPrec?:number): number {
      return Number(session.fixedNumber(num,decPrec));
    }
    fixedNumber(numberParam: string | number, decPrec?:number, cosmeticOnly = false): string {
      // Make sure the number is indeed a number
      let num = parseFloat(String(numberParam));
      if (isNaN(num)) {
        console.trace('Error fixedNumber arguments:', [...arguments])
        throw new TypeError(`Value: ${numberParam} cannot be converted to float`);
      }


      let decimalPrecision = 2;
      let posDecimalPrecision = null;

      // Check if there is an active session
      // if not use default value for percision, default value = 2
      if (posUtils.isDefined(session.pos) && posUtils.isDefined(session.pos.parameterDecimalPrecision)) {
        decimalPrecision = session.pos.parameterDecimalPrecision;
        posDecimalPrecision = session.pos.parameterDecimalPrecision;
      }

      if (decPrec) {
        decimalPrecision = decPrec;
      }
      // Make sure that sign does not affect the rounding (-1.45 is rounded to -1.4 while 1.45 is rounded to 1.5)
      // So we ignore the sign and add it back later. this way -1.45 is rounded to -1.5 and 1.45 is rounded to 1.5
      let sign = num < 0 ? -1 : 1;
      // Format number according to POS parameters
      if (posDecimalPrecision != decimalPrecision && posDecimalPrecision != null && cosmeticOnly) {
        return (Number((Math.abs(num)).toFixed(posDecimalPrecision))*sign).toFixed(decimalPrecision);
      }
      else {
        return (Number((Math.abs(num)).toFixed(decimalPrecision))*sign).toFixed(decimalPrecision);
      }
    }
    fixedNumberAndToLocalString(numberParam: string | number, decPrec?:number, cosmeticOnly = false) {
      let fixedNum = this.fixedNumber(numberParam, decPrec, cosmeticOnly);
      return Number(fixedNum).toLocaleString();
    }
    isValidDate(value, sepVal, dayIdx, monthIdx, yearIdx, twoDigitsYear): any {
      try {
        //Change the below values to determine which format of date you wish to check. It is set to dd/mm/yyyy by default.
        var DayIndex = dayIdx !== undefined ? dayIdx : 0;
        var MonthIndex = monthIdx !== undefined ? monthIdx : 0;
        var YearIndex = yearIdx !== undefined ? yearIdx : 0;

        value = value.replace(/-/g, "/").replace(/\./g, "/");
        var SplitValue = value.split(sepVal || "/");

        if (twoDigitsYear === true) {
          SplitValue[YearIndex] = "20" + SplitValue[YearIndex];
        }

        var Day = null;
        var Month = null;
        var Year = null;

        var OK = true;
        if (!(SplitValue[DayIndex].length == 1 || SplitValue[DayIndex].length == 2)) {
          OK = false;
        }
        if (OK && !(SplitValue[MonthIndex].length == 1 || SplitValue[MonthIndex].length == 2)) {
          OK = false;
        }
        if (OK && SplitValue[YearIndex].length != 4) {
          OK = false;
        }
        if (OK) {
          Day = parseInt(SplitValue[DayIndex], 10);
          Month = parseInt(SplitValue[MonthIndex], 10);
          Year = parseInt(SplitValue[YearIndex], 10);

          if (OK = (Year > 1900)) {
            if (OK = (Month <= 12 && Month > 0)) {

              var LeapYear = (((Year % 4) == 0) && ((Year % 100) != 0) || ((Year % 400) == 0));

              if (OK = Day > 0) {
                if (Month == 2) {
                  OK = LeapYear ? Day <= 29 : Day <= 28;
                }
                else {
                  if ((Month == 4) || (Month == 6) || (Month == 9) || (Month == 11)) {
                    OK = Day <= 30;
                  }
                  else {
                    OK = Day <= 31;
                  }
                }
              }
            }
          }
        }
        if (OK) {
          var retDate = new Date();
          retDate.setFullYear(Year, Month - 1, Day);
          return retDate;
        } else {
          return false;
        }
      }
      catch (e) {
        return false;
      }
    }
    private parseResultsError(results) {
      // Extract the message from the error object
      var message = i18next.t("unknownErrorHasOccured");
      if ('message' in results.error) {
        message = results.error.message;
        if (results.error.message == 'Username/password combination doesn\'t exists') {
          message = i18next.t("usernamePasswordDoesntExists");
        } else if (results.error.message == 'No available POS for this user') {
            message = i18next.t("noAvailablePOSforUser");
        }
      }

      app.hideLoadingMessage()
      app.showAlert({
        header: i18next.t('error'),
      content: message,
        continueButtonText: i18next.t("ok"),
      hideCancelButton: true
    }, null, null);
      console.error(message);
      throw new Error(message);

    }
    async persistHomeControllerResult(username, password, results, isLogin?) {
      let aThis = this;

      // --- If there was an error, the remote server will return an error object
      if ('error' in results) {
        this.parseResultsError(results)
        return;
      }
      // --- Login was successful, persist the data from the remote server
      // Check that the data from the remote server has all the needed data



      // Persist the POS data
      var isNewPos = false;
      if (this.pos == null) {
        isNewPos = true;
        this.pos = new PositiveTS.Storage.Entity.Pos();
      }

      this.setSessionPostObjWithReturnObj(this.pos, results);


      // Store credit_card_vendor for offline transaction
      localStorage.setItem("credit_card_vendor", results.credit_card_vendor);
      localStorage.setItem("lastElalSaleSequence", results.lastElalSaleSequence);
      if (isLogin) {
        this.pos.employeeID = "-1";
        this.pos.employeeName = '';
      }
      this.pos.isLoggedIn = true;
      this.pos.loginUsername = username;
      this.pos.loginPassword = password;
      jsonConfig.updateLocalStorageFromResults(results, username, password);

      let posPersistCallback = async function() {

        await aThis.pos.persist();
          // Update the login state
        aThis.isLoggedIn = aThis.pos.isLoggedIn;

        let promise:Promise<any> = isLogin ? aThis.loadSessionDataFromStorage() : Promise.resolve();

        await promise;
          // Post the relevant notification
        PositiveTS.NotificationCenter.postNotification(session.SessionChangedNotification);
        return;
      };

      // If this is a new POS, insert an initial sequence from remote server and then persist POS
      try {
        await aThis.persistAllSequence(results)

        if (isNewPos) {
          await PositiveTS.Helper.SaleHelper.loadSalesWithoutZReportFromServerToLocal()
          await Service.EmployeeTimeTrack.getTimeTracksForRoshemetLogin()
          await posPersistCallback();
        } else {
          await posPersistCallback();
        }
      }
      catch(error) {
        console.error('Cannot persist sequence. ' + error.message);
        throw new Error(i18next.t("loginFailed") + ' (receipt sequence persist failed)');
      };



    }
    startRecordingPos() {
      try {
        let env = 'production';
        if (posUtils.isDevelopment()) {
          env = 'development';
        } else if (posUtils.isStaging()) {
          env = 'staging';
        }
        let posVersion = window.location.pathname.split('/')[2];
        datadogRum.init({
          applicationId: 'f780694c-2246-4a22-a35e-59a73889f524',
          clientToken: 'pubc334af9d7bde3742110e76977067f9ca',
          // `site` refers to the Datadog site parameter of your organization
          // see https://docs.datadoghq.com/getting_started/site/
          site: 'datadoghq.com',
          // Note: Yes,  service name must not have Upper case because DataDog returns a warning that init will fail if it does
          service: 'PosRecordings',
          env: env,
          version: posVersion,
          // Specify a version number to identify the deployed version of your application in Datadog
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
        // This will allow  Devs to easily search both by Terminal Number and Device ID (i.e. "Pos name")
        datadogRum.setUser({ name: session.pos.deviceID });
        console.info('Recording started successfully');
      } catch (error) {
        console.error('Error starting recording', error);
      }
    }
    shouldRecordPos() {
      if (window.location.href.includes("shkl.co.il") || posUtils.isDevelopment()) {
        return false;
      }

      let shouldRecord = typeof (jsonConfig) != 'undefined' && jsonConfig.getVal(jsonConfig.KEYS.forceLogRocket);

      if (!shouldRecord && localStorage.getItem('shouldRecord') == 'true') {
        let isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1 && window.Android != null;

        if (isAndroid) {
          shouldRecord = (window.navigator && window.navigator.connection && ["ethernet", "wifi"].indexOf(window.navigator.connection.type) > -1);
        } else {
          shouldRecord = true;
        }
      }
      return shouldRecord;
    }
    private persistAllSequence(results) {
      var populateSeq = new PositiveTS.Storage.Entity.Sequence();
      return appDB.sequences.toArray()
      .then( (seqs)=> {
        let seqsByType = _.keyBy(seqs, 'type');
        var seqTypes = seqs.map( (itm) =>{return itm.type});
        var aThis = session;
        var promiseValAry = [];
        var promiseAry = [];

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE,
            val: results.debit_invoice_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE,
            val: results.credit_invoice_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_VOUCHER) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_VOUCHER,
            val: results.credit_voucher_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_Z_MASTER_REPORT) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_Z_MASTER_REPORT,
            val: results.z_master_report_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_Z_REPORT) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_Z_REPORT,
            val: results.z_report_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT,
            val: results.receipt_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV,
            val: results.tax_inv_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV,
            val: results.tax_credit_inv_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV,
            val: results.shipment_inv_sequence
          });
        } else {
          if (seqsByType[PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV].sequence < 10000) {
            let seqToUpdate = seqsByType[PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV];
            seqToUpdate.sequence = (6100000 + (results.pos_number - 1) * 10000000) + seqsByType[PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV].sequence
            promiseAry.push(appDB.sequences.put(seqToUpdate))
          }
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV,
            val: results.credit_shipment_inv_sequence
          });
        } else {
          if (seqsByType[PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV].sequence < 10000) {
            let seqToUpdate = seqsByType[PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV];
            seqToUpdate.sequence = (7100000 + (results.pos_number - 1) * 10000000) + seqsByType[PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV].sequence
            promiseAry.push(appDB.sequences.put(seqToUpdate))
          }
        }


        if (seqTypes.indexOf( PositiveTS.Storage.Entity.Sequence.TYPE_PROFORMA ) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_PROFORMA,
            val: 111111
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE,
            val: results.punch_card_inv_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_INVOICE) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_INVOICE,
            val: results.cash_withdrawal_invoice_sequence
          });
        }

        if (seqTypes.indexOf(PositiveTS.Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_REFUND_INVOICE) === -1){
          promiseValAry.push({
            key: PositiveTS.Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_REFUND_INVOICE,
            val: results.cash_withdrawal_refund_invoice_sequence
          });
        }

        for (var i = 0; i < promiseValAry.length; i++) {
          promiseAry.push(aThis.persistSequence(results,
            promiseValAry[i].key,
            promiseValAry[i].val))
        }

        return Promise.all(promiseAry);


      })


    }
    private persistSequence(results, seqType, seqSequence) {
      var populateSeq = new PositiveTS.Storage.Entity.Sequence();
      populateSeq.tenantID = results.tenant_id;
      populateSeq.companyID = results.company_id;
      populateSeq.storeID = results.store_id;
      populateSeq.posPhysicalID = results.pos_physical_id;

      populateSeq.type = seqType;
      populateSeq.sequence = seqSequence;

      return appDB.sequences.put(populateSeq);
    }


    private async updateWebLogo() {
      var aThis = this;
      let imgSrc = '';

      let tenantsWithGlobalLogo = ['14', '17'];

      if (tenantsWithGlobalLogo.includes(aThis.pos.tenantID)) { //logo for all companies in caspit...
        imgSrc = `/versions/${PositiveTS.Version.appVersion}/assets/images/logos/hyp.png`;
      }
      else {
        imgSrc = Shared.Constants.remoteRoot + 'logo/{TENANT_ID}/{COMPANY_ID}/logo';
        imgSrc = imgSrc.replace('{TENANT_ID}', aThis.pos.tenantID).replace('{COMPANY_ID}', aThis.pos.companyID);
      }
      try {
        await posUtils.urlExists(imgSrc)
        let image = document.getElementById('header-store-logo');
        image.setAttribute('src', imgSrc)
        Pinia.globalStore.setStoreLogoUrl(imgSrc);
      }
      catch(err) {
        //do nothing just stay with generic image as the url does not exist
        let image = document.getElementById('header-store-logo') as HTMLImageElement;
        Pinia.globalStore.setStoreLogoUrl(image.src);
      }
    }
    private loadSystemSettings() {
      var aThis = session;

      var systemSettings = new PositiveTS.Storage.Entity.SystemSettings();

      return systemSettings.all()
      .then(result => {
        try {
          aThis.systemSettings = result[0];
        } catch (err) {
          console.error(err.message);
        }
      });
    }
    private loadCompany() {
      var aThis = session;

      var companyModel = new PositiveTS.Storage.Entity.Company();

      return companyModel.all()
      .then(result => {
        try {
          aThis.company = result[0];
        } catch (err) {
          console.error(err.message);
        }
      });
    }
    private loadStore() {
      var aThis = session;

      var storeModel = new PositiveTS.Storage.Entity.Store();
      return new Promise((resolve,reject) => {
        try {
          let stores = storeModel.fetchByTenantIDAndCompanyIDAndStoreID(aThis.pos.tenantID, aThis.pos.companyID, aThis.pos.storeID)
          aThis.store = stores[0];
          resolve();
        } catch(err) {
          console.error(err.message);
          resolve();
        }
      })

    }


    private async loadCustomerGroups() {
      if (session.pos && session.pos.brillConfig) {
        let result = await (new PositiveTS.Storage.Entity.CustomerGroup()).all();
        session.customerGroupsByName = _.keyBy(result, 'name')
      }

      return;
    }

    private loadCreditCardTypes() {
      var aThis = session;

      var creditCardPaymentTypesModel = new Storage.Entity.CreditCardPaymentTypes();

      return creditCardPaymentTypesModel.all()
      .then(function(creditCardPaymentTypes) {

        aThis.creditCardPaymentTypes = {};

        $.each(creditCardPaymentTypes, function(key, creditCardPaymentType) {
          aThis.creditCardPaymentTypes[creditCardPaymentType.ccTypeID] = creditCardPaymentType;
          aThis.creditCardPaymentTypes[creditCardPaymentType.ccTypeID].ccTypeName = i18next.t(`creditCardPaymentTypes.${creditCardPaymentType.ccTypeID}`);
        });
        if (aThis.pos.isEmv) {
          aThis.creditCardPaymentTypes["8"] = {
            ccTypeID: "8", emvTypeCode: 3, allowPayments: false, ccTypeName: i18next.t("creditCardPaymentTypes.8")
          }
        }
      });
    }
    private setSessionPostObjWithReturnObj(objSessionPos: any, results): void {
      let resultArray: string[] = Object.keys(results);


      for (let key in this.dictionaryStrings) {
        if (this.dictionaryStrings.hasOwnProperty(key)) {
          let resultKey: string = this.dictionaryStrings[key];
          if (resultArray.indexOf(resultKey) === -1) {
            throw new Error("Missing pos results parameter " + key)
          }
          objSessionPos[key] = results[resultKey];
        }
      }

      return;
    }

    private async initBackgroundImageIfNeeded() {
			if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) && !posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.selfServiceBackgroundImage))) {
				let bgImage = await appDB.screenSavers.get(jsonConfig.getVal(jsonConfig.KEYS.selfServiceBackgroundImage));

				if (bgImage) {
					let styleElm = document.body.appendChild(document.createElement("style"));

          // if we dont fix the base 64, it wont work. according to github, this is the solution
          let fixedBase64 = bgImage.base64.replace(/(\r\n|\n|\r)/gm, "");

					styleElm.innerHTML = ".self-service-bg-image:before {background-image: url('" + fixedBase64 + "'); }";
					document.body.classList.add('self-service-bg-image');

          if(!jsonConfig.getVal(jsonConfig.KEYS.isCancelOpacity)) {
            styleElm.innerHTML += " .with-opacity";
            document.body.classList.add('with-opacity');
          }

          Pinia.globalStore.setSelfServiceBackgroundImageActivated(true);
				}
			}
		}

    private getSupportNumber() {
      if(session && session.pos) {
        if(session.pos.supportPhone) {
          return `${i18next.t("support")}: ${session.pos.supportPhone}`;
        }
        if((session.pos.tenantID == "14") || (session.pos.tenantID == "17")) {
          return `${i18next.t("support")}: 1-700-07-1000`;
        }
        if(session.pos.isCaveret) {
          return `${i18next.t("support")}: 1700-071-055`;
        }
      }
      return `${i18next.t("support")}: 03-6006262`;
    }

    private getDict(): { [index: string]: string } {
      var _return: { [index: string]: string } = {};
      _return["tenantID"] = "tenant_id";
      _return["tenantName"] = "tenant_name";
      _return["companyID"] = "company_id";
      _return["companyName"] = "company_name";
      _return["checkCompanyName"] = "check_company_name";
      _return["storeID"] = "store_id";
      _return["storeName"] = "store_name";
      _return["physicalID"] = "pos_physical_id";
      _return["deviceID"] = "pos_device_id";
      _return["posNumber"] = "pos_number";
      _return["access_token"] = "access_token";
      _return["paramsetID"] = "posparamset_id";
      _return["parameterDecimalPrecision"] = "decimal_precision";
      _return["parameterRequireSalesperson"] = "require_salesperson";
      _return["parameterRequireCustomer"] = "require_customer";
      _return["parameterPreformaSuffix"] = "preforma_suffix";
      _return["parameterDebitInvoiceSuffix"] = "debit_invoice_suffix";
      _return["parameterCreditInvoiceSuffix"] = "credit_invoice_suffix";
      _return["parameterInvoiceType"] = "invoice_type";
      _return["parameterEnforceInventory"] = "enforce_inventory";
      _return["parameterEnforceInventoryLevel"] = "enforce_inventoryLevel";
      _return["parameterMaxCheckDays"] = "max_check_days";
      _return["parameterMaxCreditVoucherDays"] = "max_credit_voucher_days";
      _return["parameterMaxCreditCardPayments"] = "max_credit_card_payments";
      _return["parameterAllowTypingCreditCard"] = "allow_typing_credit_card";
      _return["parameterMaxChangeInCash"] = "max_change_in_cash";
      _return["parameterAllowNegativeSale"] = "allow_negative_sale";
      _return["parameterAllowNegativeQty"] = "allow_negative_qty";
      _return["parameterPrintLogo"] = "print_logo";
      _return["parameterNoCheckDetails"]			= 'no_check_details';
      _return["parameterMinimumChangeInCreditVoucher"] = "minimum_change_in_credit_voucher";
      _return["parameterEmployeeMagneticCardLength"] = "employee_magnetic_card_length";
      _return["parameterIsAutomaticEmployeeStoreConnection"] = "is_automatic_employee_store_connection";
      _return["parameterAllowOfflineCreditCard"] = "allow_offline_credit_card";
      _return["parameterOfflineCreditCardLimit"] = "offline_creditcard_limit";
      _return["parameterAllowChangeItemPrice"] = "allow_change_item_price";
      _return["parameterSelectAllowChangeItemPrice"] = "select_allow_change_item_price";
      _return["parameterHideCreditCardConfirmationNumber"] = "hide_credit_card_confirmation_number";
      _return["parameterSpecialItemBlockVouchers"] = "special_item_block_vouchers";
      _return["parameterSpecialItemBlockCreditVouchers"] = "special_item_block_credit_vouchers";
      _return["parameterBlockItemInMinusWithMultipleSalespersons"] = "block_item_in_minus_with_multiple_salespersons";
      _return["printerType"] = "printer_type";
      _return["jsonConfig"] = "json_config";
      _return["parameterManagerApprovalChangeItemPrice"] = "parameterManagerApprovalChangeItemPrice";
      _return["parameterManagerApprovalCreditInvoice"] = "parameterManagerApprovalCreditInvoice";
      _return["parameterManagerApprovalZXReport"] = "parameterManagerApprovalZXReport";
      _return["parameterManagerApprovalReplacementSlip"] = "parameterManagerApprovalReplacementSlip";
      _return["praxellCode"] = "praxellCode";
      _return["maxInvoiceReturnDays"] = "maxInvoiceReturnDays";
      _return["maxOneCheckDays"] = "maxOneCheckDays";
      _return["ontrustVoucherId"] = "ontrustVoucherId";
      _return["usePinPad"] = "usePinPad";
      _return["separateItemLines"] = "separateItemLines";
      _return["noBarcodeMode"] = "no_barcode_mode";
      _return["storeSignature"] = "store_signature";
      _return["backofficeIsControl"] = "backofficeIsControl";
      _return["isTranbUpload"] = "isTranbUpload";
      _return["emvTerminalNumber"] = "emvTerminalNumber";
      _return["isShowCombinedXReport"] = "isShowCombinedXReport";
      _return["onDemandCatalog"] = "on_demand_catalog";
      _return["shouldPrintBon"] = "shouldPrintBon";
      _return["openDrawerCode"] = "openDrawerCode";
      _return["isManagerApprovalReports"] = "isManagerApprovalReports";
      _return["onlyPrintBonAfterPayment"] = "onlyPrintBonAfterPayment";
      _return["tenbisUserName"] = "tenbisUserName";
      _return["tenbisUserPass"] = "tenbisUserPass";
      _return["tenbisResId"] = "tenbisResId";
      _return["banxUserName"] = "banxUserName";
      _return["banxUserPass"] = "banxUserPass";
      _return["interServiceUserName"] = "interServiceUserName";
      _return["interServicePass"] = "interServicePass";
      _return["interServiceBranchCode"] = "interServiceBranchCode";
      _return["paiditResId"] = "paiditResId";
      _return["migvanId"] = "migvanId";
      _return["valuePosId"] = "valuePosId";
      _return["valuePosPassword"] = "valuePosPassword";
      _return["useNewPromotions"] = "use_advanced_promotions_system"
      _return["valueCashiersPassword"] = "valueCashiersPassword";
      _return["isRoshemet"] = "is_roshemet";
      _return["useMinPriceForNegItem"] = "useMinPriceForNegItem";
      _return["isGoodiTaxInvoice"] = "isGoodiTaxInvoice";
      _return["isCaveret"] = "isCaveret";
      _return["companyWorksWithMultiLanguage"] = "companyWorksWithMultiLanguage";
      _return["companySupportedLanguages"] = "companySupportedLanguages";
      _return["caveretCibusCompanies"] = "caveretCibusCompanies";
      _return["storeSelfServiceOpeningHours"] = "storeSelfServiceOpeningHours";
      _return["midragPayments"] = "midragPayments";
      _return["askForMemberClubRenew"] = "askForMemberClubRenew";
      _return["isElal"] = "isElal";
      _return["systemId"] = "systemId";
      _return["migvanDeliveryId"] = "migvanDeliveryId";
      _return["migvanTaId"] = "migvanTaId";
      _return["migvanItemsId"] = "migvanItemsId";
      _return["baseCurrency"] = "baseCurrency";
      _return["usePictures"] = "usePictures";
      _return["supportPhone"] = "supportPhone";
      _return["hasFlights"] = "hasFlights";
      _return["companyUsePriorityApi"] = "companyUsePriorityApi";
      _return["priorityUserData"] = "priorityUserData";
      _return["passwords"] = "passwords";
      _return["yaadSarigPassp"] = "yaad_sarig_passp"
      _return["hasInternetStore"] = "hasInternetStore";
      _return["singleStorePos"] = "singleStorePos";
      _return["tipItemCode"] = "tipItemCode";
      _return["mishlohaToken"] = "mishloha_token";
      _return["sandiBasicAuthUsername"] = "sandi_basic_auth_username";
      _return["sandiBasicAuthPassword"] = "sandi_basic_auth_password";
      _return["creditguardAuthUsername"] = "creditguard_username";
      _return["creditguardAuthPassword"] = "creditguard_password";
      _return["sandiUrl"] = "sandi_url";
      _return["elalUrlGetPNR"] = "elal_url_get_pnr";
      _return["elalUrlUpdateTMI"] = "elal_url_update_tmi";
      _return["elalUrlGetEKT"] = "elal_url_get_ekt";
      _return["creditGuardUrl"] = "credit_guard_url";
      _return["ototDefaultUrl"] = "otot_default_url";
      _return["simplyClubUrl"] = "simply_club_url";
      _return["worksWithCustomerSaleItemLimit"] = "works_with_customer_sale_item_limit";
      _return["nirDavidCredentials"] = "nir_david_credentials";
      _return["customerClubNumberRequired"] = "customerClubNumberRequired";
      _return["gainUponOnRenewMemberSale"] = "gainUponOnRenewMemberSale";
      _return["onlineOnlyMemberClub"] = "onlineOnlyMemberClub";
      _return["hasStoreMigvans"] = "hasStoreMigvans";
      _return["brillConfig"] = "brillConfig";
      _return["companyUsesSaleDataJson"] = "companyUsesSaleDataJson";
      _return["hasUpsales"] = "hasUpsales";
      _return["companyAllowGainCustomerClubPointsPaymentVouchers"] = "companyAllowGainCustomerClubPointsPaymentVouchers";
      _return["defaultImages"] = "defaultImages";
      _return["defaultMenuId"] = "defaultMenuId";
      _return['hasRemoteZ'] = 'has_remote_z';
      _return['isAutoEmployeeSignOutRemoteZ'] = 'is_auto_employee_sign_out_remote_z';
      _return['isAddTextDigitalInvoice'] = 'is_add_text_digital_invoice';
      _return['customerDisplayOnSaleImgUrl'] = 'customer_display_on_sale_img_url';
      _return['customerDisplayIdleModeImgUrls'] = 'customer_display_idle_mode_img_urls';
      _return['isAutoStore'] = 'is_auto_store';
      _return['autoStoreConfig'] = 'auto_store_config';
      _return['useVendingMachines'] = 'use_vending_machines';
      _return['isVendingMachinePos'] = 'is_vending_machine_pos';
      _return['vendingMachineRailsId'] = 'vending_machine_rails_id';
      _return['taxAuthorityInvoiceAllocationAmount'] = 'tax_authority_invoice_allocation_amount';
      _return['welcomeScreen'] = 'welcome_screen';
      _return['suspiciousActivityAlertsData'] = 'suspicious_activity_alerts_data';
      return _return;
    }
  }
}

declare var session: PositiveTS.Session;
session = new PositiveTS.Session();
