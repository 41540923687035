module PositiveTS {
	export module Printing {
		export enum printerTypes {
			epson = 1,
			ibm = 2
		}

		const WINDOWS_LOGO_CHARS = String.fromCharCode(28) + String.fromCharCode(112) + String.fromCharCode(1) + String.fromCharCode(0);
		const ANDROID_LOGO_CHARS = String.fromCharCode(127) + String.fromCharCode(112) + String.fromCharCode(1) + String.fromCharCode(0);

		const wrap = (s, w) => s.replace(
			new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n'
		);
		export class Base {
			static english = /[aA-zZ]/;
			static hebrew = /[\u0590-\u05FF]/;
			PrinterChangedNotification: 'PrinterChangedNotification'
			jzebra: PositiveTS.Printing.Print20
			lineLength = 48
			lineLengthBigFont = 24
			itemCodeLength = 14
			itemDescriptionLength = 25
			itemColorSizeLength = 10
			itemPriceLength = 8
			itemDiscountLength = 30
			waitingTime = 0 // in milli-seconds
			firstDefaultColWidth = 23;
			secondDefaultColWidth = 13;
			thirdDefaultColWidth = 6;

            firstDefaultColWidth4Columns = 16;
            secondDefaultColWidth4Columns = 10;
            thirdDefaultColWidth4Columns = 10;
            fourthDefaultColWidth4Columns = 6;

			maxDescriptionLength = 33;
			narrowNumCol = 3;
			zReportTableWidths = [23, 13]
			printerType = printerTypes.epson
			static numberWithCommas = (x) => {
				var parts = x.toString().split(".");
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

				return parts.join(".");
			}
			windowsLogoChars = WINDOWS_LOGO_CHARS;
			androidLogoChars = ANDROID_LOGO_CHARS;
			allowComponents = {
				lineSpacing: String.fromCharCode(27) + String.fromCharCode(51) + String.fromCharCode(77),
				hugeLineSpacing: String.fromCharCode(27) + String.fromCharCode(51) + String.fromCharCode(127),
				GS: String.fromCharCode(29),
				barcode: String.fromCharCode(29) + `k`,
				rightAlignment: String.fromCharCode(27) + String.fromCharCode(97) + String.fromCharCode(2),
				centerAlignment: String.fromCharCode(27) + String.fromCharCode(97) + String.fromCharCode(1),
				leftAlignment: String.fromCharCode(27) + String.fromCharCode(97) + String.fromCharCode(0),
				underline: String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(128),
				formFeed: String.fromCharCode(12),
				lineFeed: String.fromCharCode(10),
				linesFeed: String.fromCharCode(27) + String.fromCharCode(100) + String.fromCharCode(10),
				reverseFeed: String.fromCharCode(27) + String.fromCharCode(74),
				newLine: `\n`,
				CR: `\r`,
				init: String.fromCharCode(27) + String.fromCharCode(64),
				bold: Base.boldEscpCode(printerTypes.epson),
				regular: String.fromCharCode(27) + String.fromCharCode(70),
				condensed: String.fromCharCode(15),
				noCondensed: String.fromCharCode(18),
				bigFont: Base.bigFontEscpCode(printerTypes.epson),
				bigFontSunmi: Base.bigFontEscpCode(printerTypes.ibm),
				bigAndBoldFont: String.fromCharCode(27) + `!` + String.fromCharCode(255),
				noBigFont: String.fromCharCode(27) + `w0` + String.fromCharCode(27) + `W0`,
				vSpacing6LPI: String.fromCharCode(27) + String.fromCharCode(50),
				vSpacing8LPI: String.fromCharCode(27) + String.fromCharCode(48),
				//cutLine				: String.fromCharCode(27) + String.fromCharCode(105),
				defaultCutLine: null,
				cutLine: String.fromCharCode(27) + String.fromCharCode(105),
				picturePrinterCutLine: '<!-- CUT LINE -->',
				/* Box drawing component */
				drawLine: String.fromCharCode(196),
				drawDLine: String.fromCharCode(205),
				topLeft: String.fromCharCode(169),
				topRight: String.fromCharCode(215),
				vLine: String.fromCharCode(33),
				vIntersectR: String.fromCharCode(180),
				vIntersectL: String.fromCharCode(195),
				vIntersectTC: String.fromCharCode(194),
				intersect: String.fromCharCode(197),
				hugeFont: String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(48),
				superHugeFont: String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(48),
				smallLineSpace: ``,
				vIntersectBC: String.fromCharCode(193),
				bottomLeft: String.fromCharCode(192),
				bottomRight: String.fromCharCode(217),
				logo: WINDOWS_LOGO_CHARS,
				RTLChar: String.fromCharCode(parseInt("200F", 16)),
				LTRChar: String.fromCharCode(parseInt("200E", 16))
			}

			static getLineLength() {
				return 42;
			}

			static boldEscpCode(printerType) {
				if (printerType === printerTypes.epson) {
					return String.fromCharCode(27) + String.fromCharCode(69) + String.fromCharCode(27);
				}

				if (printerType === printerTypes.ibm) {
					return String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(10);
				}

				return String.fromCharCode(27) + String.fromCharCode(69) + String.fromCharCode(27);
			}

			static bigFontEscpCode(printerType) {
				if (printerType === printerTypes.epson) {
					return String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(100);
				}

				if (printerType === printerTypes.ibm) {
					// on ibm set bigfont to bold
					return String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(32)
					//return String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(33);
				}

				return String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(100);
			}

			addRTLChar() {
				if (session.pos.printerType === PRINTER_TYPE.rongta) {
					return;
				}

				if (navigator.userAgent.toLowerCase().indexOf('t2') != -1 || navigator.userAgent.toLowerCase().indexOf('t1') != -1 ||
					navigator.userAgent.toLowerCase().indexOf('v1s') != -1 || navigator.userAgent.toLowerCase().indexOf('p2_pro') != -1) {
					printer.jzebra.append(printer.allowComponents.RTLChar);
				}
			}

			addLTRChar() {
				if (session.pos.printerType === PRINTER_TYPE.rongta) {
					return;
				}

				if (navigator.userAgent.toLowerCase().indexOf('t2') != -1 || navigator.userAgent.toLowerCase().indexOf('t1') != -1 ||
					navigator.userAgent.toLowerCase().indexOf('v1s') != -1 || navigator.userAgent.toLowerCase().indexOf('p2_pro') != -1) {
					printer.jzebra.append(printer.allowComponents.LTRChar);
				}
			}

			printQR(qr) {

				let aThis = printer;
				var zebra = aThis.jzebra;

				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.centerAlignment);


				// The dot size of the QR code
				var dots = '\x05';

				// Some proprietary size calculation
				var size1 = String.fromCharCode(qr.length + 3);
				var size0 = '\x00'; // Always 0 unless qr > 256 characters

				var data = [
					// <!-- BEGIN QR DATA -->
					'\x1D' + '\x28' + '\x6B' + '\x04' + '\x00' + '\x31' + '\x41' + '\x32' + '\x00',    // <Function 165> select the model (model 2 is widely supported)
					'\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x43' + dots,               // <Function 167> set the size of the module
					'\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x45' + '\x30',             // <Function 169> select level of error correction (48,49,50,51) printer-dependent
					'\x1D' + '\x28' + '\x6B' + size1 + size0 + '\x31' + '\x50' + '\x30' + qr,          // <Function 080> send your data (testing 123) to the image storage area in the printer
					'\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x51' + '\x30',              // <Function 081> print the symbol data in the symbol storage area
					'\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x52' + '\x30',              // <Function 082> Transmit the size information of the symbol data in the symbol storage area
					// <!-- END QR DATA -->

					//   qr + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A'               // more line feeds and text to see if we messed up the QR Code syntax      
				];
				data.forEach(dat => printer.jzebra.append(dat))
				zebra.print();
				zebra.append(aThis.allowComponents.init);

			}
			chr(i) {
				return String.fromCharCode(i);
			}
			reverse(s) {
				return s.split(``).reverse().join(``);
			}
			lineFeed() {
				let aThis = printer;
				var zebra = aThis.jzebra;

				zebra.append(aThis.allowComponents.init, null, true);

				zebra.append(aThis.allowComponents.linesFeed, null, true);

				zebra.append(aThis.allowComponents.init, null, true);

				zebra.printWithoutPicturesPrinting();
			}
			fixNegSunmi(number) {
				let modifiedNumber = number;
				if (printer.isSunmi() && !printer.isHTMLBasedPrinting()) {
					if (number.charAt(0) === "-") {
						modifiedNumber = modifiedNumber.substr(1);
						modifiedNumber = modifiedNumber + "-";
					}
				}

				return modifiedNumber;
			}
			isSunmi() {
				if (session.pos.printerType === PRINTER_TYPE.sunmi) {
					return true;
				}
				if (session.pos.printerType === PRINTER_TYPE.sunmit1) {
					return true;
				}
				if (session.pos.printerType === PRINTER_TYPE.sunmiv1) {
					return true;
				}
				if (session.pos.printerType === PRINTER_TYPE.sunmik1) {
					return true;
				}

				return false;
			}

			isNarrowPrinting() {
				if (session.pos.printerType == PRINTER_TYPE.sunmiv1) {
					return true;
				}
			}

			isA4() {
				return session.pos.printerType === PRINTER_TYPE.A4
			}

			isPicturesPrinter(printerBuffer = null) {
				if (posUtils.isDefined(printerBuffer)) {
					let printer = Storage.Entity.LogicalPrinter.getPrinterByPhysicalName(printerBuffer);

					return posUtils.isDefined(printer) && printer.isPicturePrint;
				}
				
				return jsonConfig.getVal(jsonConfig.KEYS.isPicturesPrinter);
			}

			isHTMLBasedPrinting(printBuffer = null) {
				return this.isA4() || this.isPicturesPrinter(printBuffer);
			}
			
			print() {
				if (printer.jzebra === null) {
					return Promise.reject(false);
				}

				return printer.jzebra.print();
			}

			printTableRow(values: String[], widths: number[], classes: string[] = [], printerBuffer = null) {
				let str = printer.getTableRow(values, widths, classes, printerBuffer);
				if (printer.isSunmi()) {
					str = printer.magicCharizeMe(String(str))
					if (str.slice(-1) == printer.allowComponents.RTLChar) {
						str = str.substr(0, str.length - 1)
					}
				}
				printer.printFloatingLine(str, "", null, printerBuffer)
			}

			printTableEnglishRow(values: String[], widths: number[], classes: string[] = []) {
				printer.printFloatingLine(printer.getTableEnglishRow(values, widths, classes), "");
			}

			getTableRow(values: String[], widths: number[], classes: string[] = [], printerBuffer = null): String {
				if (printer.isHTMLBasedPrinting(printerBuffer)) {
					return PositiveTS.Printing.HtmlPrinting.tableRow(values, widths, classes)
				}

				let row = ``;
				for (let i = 0; i < values.length; i++) {
					let width = widths[i];
					let val = values[i];


					if (printer.isSunmi()) {
						if (Number(val)) {
							let num = Number(val);
							if (num < 0) {
								val = val.substring(1);
								val = val + "-";
							}
						}
					}


					let padding = ""
					if (session.pos.printerType == PRINTER_TYPE.sunmi) {
						val = val.replace(printer.allowComponents.RTLChar, "");
					}
					if (width > val.length) {
						padding = ' '.repeat(width - val.length);
					}
					else {
						padding = " "
						width = width - 1;
					}
					row += val.substr(0, width) + padding;
				}

				return row;
			}

			magicCharizeMe(str: string): string {
				if (posUtils.isBlank(str)) {
					return "";
				}
				let words = str.split(" ");
				let result = ""

				for (let i = 0; i < words.length; i++) {
					let word = words[i];
					if (!Base.hebrew.test(word) && word != "") {
						result += word + printer.allowComponents.RTLChar + (i == words.length - 1 ? "" : " ")
					}
					else {
						result += word + (i == words.length - 1 ? "" : " ")
					}
				}
				return result;
			}

			getTableEnglishRow(values: String[], widths: number[], classes: string[] = []): String {
				if (printer.isHTMLBasedPrinting()) {
					return PositiveTS.Printing.HtmlPrinting.tableRow(values, widths, classes)
				}
				
				let row = ``;
				for (let i = 0; i < values.length; i++) {
					let width = widths[i];
					let val = values[i];

					if (printer.isSunmi() && session.pos.printerType != PRINTER_TYPE.sunmit1) {
						if (Number(val)) {
							let num = Number(val);
							if (num < 0) {
								val = val.substring(1);
								val = val + "-";
							}
						}
					}


					let padding = ""
					if (session.pos.printerType == PRINTER_TYPE.sunmi) {
						val = val.replace(printer.allowComponents.RTLChar, "");
					}
					if (width > val.length) {
						padding = ' '.repeat(width - val.length);
					}
					else {
						padding = " ";
						width = width - 1;
					}
					if(printer.isRTL) {
						row += padding + val.substr(0, width);
					}
					else {
						row += val.substr(0, width) + padding;
					}
				}
				return row;
			}

			printHeader(value, rightAlignment?) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (printer.isHTMLBasedPrinting()) {
					zebra.append(
						PositiveTS.Printing.HtmlPrinting.header(value)
					);
					return;
				}

				let row = ``;
				zebra.append(aThis.allowComponents.init);
				zebra.append(aThis.allowComponents.bold);
				zebra.append(aThis.allowComponents.smallLineSpace);

				if (rightAlignment == true) {	//a fix to make sure less than actual print width looks ok but didn't have time to fix everything else besids invoice printing so its a parameter
					zebra.append(aThis.allowComponents.rightAlignment);
				}


				aThis.printFloatingLine(value, ``);

				if (aThis.isHTMLBasedPrinting()) {
					aThis.printHr();
				} else {
					if (printer.isSunmi() && aThis.isRTL) {
						aThis.printFloatingLine('', `-`.repeat(value.length));
					} else {
						aThis.printFloatingLine(`-`.repeat(value.length));
					}
				}

				zebra.append(aThis.allowComponents.init);
			}

			chetPeiAinMem() {
				if (session.store.isOsekMurshe) {
					return i18next.t("printing.taxIdNum")
				} else {
					if (session.store.isOsekPatur) {
						return i18next.t("printing.noTaxNum")
					}
					else if (session.store.isAmotaReshoma) {
						return i18next.t("printing.amota")
					}
					else {
						return i18next.t("printing.companyNum")
					}
				}
			}

			printTextWithPrintLineText(txt, printLineTxt) {
				let aThis = printer;
				if (printer.isHTMLBasedPrinting()) {
					aThis.printLine(`${printLineTxt} ${txt}`);
					return;
				}
				if (printer.isSunmi()) {
					aThis.printMultyLine(printLineTxt + txt)
				} else {
					aThis.printText(txt);
					aThis.printLine(printLineTxt)
				}

			}

			pad(num, size) {
				var s = num + "";
				while (s.length < size) s = "0" + s;
				return s;
			}

			get isRTL() {
				return Pinia.globalStore.rtlMode;
			}

			printFloatingLineWithPrice(text, price) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				var priceText = price;

				var priceSpaces = aThis.itemPriceLength - priceText.length;
				var textSpaces = aThis.lineLength - aThis.itemPriceLength - text.length - 1;

				if (aThis.isHTMLBasedPrinting()) {
					this.printFloatingLine(text, Base.numberWithCommas(priceText));
					return;
				}

				var isRTL = aThis.isRTL
				if (printer.isSunmi() && isRTL) {
					isRTL = !isRTL
					if (priceSpaces < 0) {
						textSpaces = textSpaces + priceSpaces - 1;
					}
				}
				if (isRTL) {
					aThis.printChar(` `, priceSpaces);
					aThis.printText(Base.numberWithCommas(priceText));
				}
				else {
					aThis.printText(text);
					aThis.printChar(` `, textSpaces);
				}


				if (isRTL) {
					aThis.printChar(` `, textSpaces);
					aThis.printLine(text);
				}
				else {
					aThis.printChar(` `, priceSpaces);
					aThis.printLine(Base.numberWithCommas(priceText));
				}




			}

			printChar(chr, count) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				for (var i = 0; i < count; i++) {
					zebra.append(chr);
				};
			}

			printFloatingInBoxLine(rightText, leftText, charConstant?) {
				let aThis = printer;
				var zebra = aThis.jzebra;
				var spaces;

				if (printer.isHTMLBasedPrinting()) {
					zebra.append(PositiveTS.Printing.HtmlPrinting.getBoxLine(rightText, leftText));
					return;
				}

				if (charConstant) {
					rightText = rightText.substring(0, charConstant);
					spaces = charConstant - rightText.length;
				}
				else { //default
					rightText = rightText.substring(0, 22);
					spaces = 22 - rightText.length;
				}

				if (printer.isSunmi()) {
					if (charConstant) {
						leftText = leftText.substring(0, charConstant);
						spaces = charConstant - leftText.length;
					}
					else { //default
						leftText = leftText.substring(0, 13);
						spaces = 13 - leftText.length;
					}
				}

				var charsCount = 2 + rightText.length + spaces + leftText.length;

				zebra.append(`|`);

				for (var i = 0; i < aThis.lineLength - charsCount - 5; i++) {
					zebra.append(` `);
				};

				if (printer.isSunmi()) {
					aThis.printText(rightText);
				}
				else {
					aThis.printText(leftText);
				}

				for (var i = 0; i < spaces; i++) {
					zebra.append(` `);
				};

				if (printer.isSunmi()) {
					aThis.printText(leftText);
				}
				else {
					aThis.printText(rightText);
				}


				zebra.append(`    |\n`);
			}

			getTextCenteredBySpace(text, lineLength) {
				var countOfSpaces = 0;
				countOfSpaces = lineLength - text.length;
				var leftSpaces = parseInt(String(countOfSpaces / 2));
				var rightSpaces = countOfSpaces - leftSpaces;



				var finalText = ``;

				for (var i = 0; i < leftSpaces; i++) {
					finalText += ` `;
				};

				finalText += text;

				for (var i = 0; i < rightSpaces; i++) {
					finalText += ` `;
				};


				return finalText;
			}

			printHr(printerBuffer?, additionalLength = 0) {
				let zebra = printer.jzebra;

				if (printer.isHTMLBasedPrinting(printerBuffer)) {
					zebra.append('<div class="hr-line"></div>', printerBuffer);
					return;
				}

				printer.lineLength += additionalLength

				for (var i = 0; i < printer.lineLength; i++) {
					zebra.append(`-`, printerBuffer);
				};

				zebra.append(`\n`, printerBuffer);
				printer.lineLength -= additionalLength;
			}

			printBoxCenteredLine(text) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (printer.isHTMLBasedPrinting()) {
					zebra.append(PositiveTS.Printing.HtmlPrinting.getBoxTitle(text));
					return;
				}

				zebra.append(`|`);
				zebra.append(
					aThis.getTextCenteredBySpace(aThis.supportHebrew(text), printer.lineLength - 3)
				);
				zebra.append(`|\n`);
			}

			printLineForTopBox() {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (printer.isHTMLBasedPrinting()) {
					return;
				}

				for (var i = 0; i < aThis.lineLength - 1; i++) {
					zebra.append(`_`);
				};

				zebra.append(`\n|`);

				for (var i = 0; i < aThis.lineLength - 3; i++) {
					zebra.append(` `);
				};

				zebra.append(`|\n`);
			}

			printLineForMiddleBox() {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (printer.isHTMLBasedPrinting()) {
					return;
				}

				zebra.append(`|`);

				for (var i = 0; i < aThis.lineLength - 3; i++) {
					zebra.append(`-`);
				};

				zebra.append(`|\n`);
			}

			printLineForBottomBox(lineLength) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (lineLength === null || lineLength === undefined) {
					lineLength = aThis.lineLength;
				}

				for (var i = 0; i < lineLength - 1; i++) {
					zebra.append(`-`);
				};

				//zebra.append(`\n`);
			}

			printFloatingLine(rightText, leftText = ``, lineLength = null, printerBuffer?, ignoreSUNMI = false) {
				let aThis = printer;
				let isRTL = aThis.isRTL;

				if (posUtils.isNullOrUndefined(leftText)) { leftText = "" }

				if (printer.isHTMLBasedPrinting(printerBuffer)) {

					if (rightText.match(/^[-]{2,}$/)) {
						return this.printHr();
					}

					printer.jzebra.append(
						PositiveTS.Printing.HtmlPrinting.floatingLine(rightText, leftText),
						printerBuffer
					)
					return;
				}

				if ((printer.isSunmi()) && (ignoreSUNMI == false) && session.pos.printerType == PRINTER_TYPE.sunmi || session.pos.printerType == PRINTER_TYPE.sunmiv1) {
					let r = rightText;
					rightText = leftText;
					leftText = r;

					if (Number(leftText)) {
						let num = Number(leftText);
						if (num < 0) {
							leftText = leftText.substring(1);
							leftText = leftText + "-";

						}
					}

					if (Number(rightText)) {
						let num = Number(rightText);
						if (num < 0) {
							rightText = rightText.substring(1);
							rightText = rightText + "-";
						}
					}
				}





				if (lineLength === null || lineLength === undefined) {
					lineLength = aThis.lineLength;
				}

				if (isRTL) {
					rightText = aThis.supportHebrew(rightText);
					leftText = aThis.supportHebrew(leftText);
				}
				else {
					let tmp = aThis.supportHebrew(rightText);
					rightText = aThis.supportHebrew(leftText);
					leftText = tmp;
				}

				var spacingCount = lineLength - rightText.length - leftText.length;

				var finalText = leftText;

				for (var i = 0; i < spacingCount; i++) {
					finalText += ` `;
				};

				finalText += rightText;

				printer.jzebra.append(finalText, printerBuffer);
				printer.jzebra.append(`\n`, printerBuffer);
			}

			printLogo(forceRongta = false) {
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (printer.jzebra === null) {
					return false;
				}

				if (printer.isSunmi() || (printer.isPicturesPrinter() && !forceRongta)) {
					printer.addRTLChar();
					zebra.append("[PRINTLOGO]");
					printer.addRTLChar();
					zebra.append(printer.allowComponents.newLine);
					return;
				}


				if (!session.pos.parameterPrintLogo) {
					return;
				}

				zebra.append(aThis.allowComponents.init,null, forceRongta);
				zebra.append(aThis.allowComponents.newLine,null, forceRongta);
				zebra.append(aThis.allowComponents.centerAlignment,null, forceRongta);
				zebra.append(aThis.allowComponents.logo,null, forceRongta);
				zebra.append(aThis.allowComponents.newLine,null, forceRongta);
				zebra.append(aThis.allowComponents.init,null, forceRongta);

			}

			printLineCut(printerBuffer?) {
				let aThis = printer;

				if (printer.jzebra === null) {
					return false;
				}

				if (printer.isHTMLBasedPrinting(printerBuffer)) {
					if (this.isPicturesPrinter(printerBuffer)) {
						printer.jzebra.append(aThis.allowComponents.init, printerBuffer);
						printer.jzebra.append(aThis.allowComponents.cutLine, printerBuffer);
					}

					return;
				}

				try {
					for (var i = 0; i < 5; i++) {
						printer.jzebra.append(aThis.allowComponents.newLine, printerBuffer);
					};

					// Cut paper
					printer.jzebra.append(aThis.allowComponents.cutLine, printerBuffer);


					return true;
				} catch (ex) {
					return false;
				}
			}

			printText(text) {
				let aThis = printer;

				if (printer.jzebra === null) {
					return false;
				}

				if (printer.isHTMLBasedPrinting()) {
					printer.jzebra.append(text);
					return true;
				}

				try {
					text = aThis.supportHebrew(text);

					printer.jzebra.append(text);
					return true;
				} catch (ex) {
					return false;
				}
			}

			printMultyLine(text, maxLineSize = printer.lineLength, printerBuffer?) {
				let aThis = printer;

				if (printer.isHTMLBasedPrinting(printerBuffer)) {
					printer.jzebra.append(PositiveTS.Printing.HtmlPrinting.line(text), printerBuffer);
					return;
				}

				if (maxLineSize > aThis.lineLength) {
					maxLineSize = aThis.lineLength;
				}
				let wrappedText = wrap(text, maxLineSize).split("\n");
				for (let index = 0; index < wrappedText.length; index++) {
					aThis.printLine(wrappedText[index], printerBuffer);
				}
			}

			printLine(text, printerBuffer?) {
				let aThis = printer;

				if (printer.isHTMLBasedPrinting(printerBuffer)) {
					let htmlLine = PositiveTS.Printing.HtmlPrinting.line(text);

					if (htmlLine.length) {
						printer.jzebra.append(
							htmlLine,
							printerBuffer
						);
					}

					return true;
				}

				if (printer.jzebra === null) {
					return false;
				}

				try {
					text = aThis.supportHebrew(text);
					printer.jzebra.append(text + `\n`, printerBuffer);
					return true;
				} catch (ex) {
					return false;
				}
			}

			supportHebrew(text, ignoreSUNMI?,isCombinedHebrewEnglish = false) {
				let aThis = printer;
				if ((printer.isSunmi()) && (!ignoreSUNMI)) { return text; }

				try {
					// if contains hebrew
					if (/[א-ת]/.test(text)) {
						text = text.replace(new RegExp(`[^א-ת ]*`, `g`), function (match) {
							return match.split(``).reverse().join(``);
						});

						text = text.split(``).reverse().join(``);
					}

					//fixes reversed english word in mixed sentence
					let english = /[aA-zZ]/;
					let hebrew = /[\u0590-\u05FF]/;

					if (hebrew.test(text) && english.test(text) && !printer.isSunmi()) {
						let startEngRev = -1;
						let endEngRev = -1;
						if(isCombinedHebrewEnglish){
							text = this.combineEnglishAndHebrewText(text).split(" ");
						}else{
							text = text.split(" ");
						}
						for (let i = 0; i < text.length; i++) {
							if (english.test(text[i]) && (i == 0 || !english.test(text[i - 1]))) {
								startEngRev = i;
							}
							if (english.test(text[i]) && (i == text.length - 1 || !english.test(text[i + 1]))) {
								endEngRev = i;
							}

							if (startEngRev > -1 && endEngRev > -1) {
								let newEngArr = [];
								for (let j = endEngRev; j > startEngRev - 1; j--) {
									newEngArr.push(text[j]);
								}
								for (let j = startEngRev; j < endEngRev + 1; j++) {
									text[j] = newEngArr[j - startEngRev];
								}
							}
						}

						text = text.join(` `);
					}

					return text;
				} catch (ex) {
					return text;
				}
			}

			combineEnglishAndHebrewText(text) {
				try {
					let englishPattern = /[a-zA-Z]+/g;
			
					// Extract English segments
					let englishSegments = text.match(englishPattern) || [];
					let englishText = englishSegments.join(' ');

					let textWithoutEnglish = text.replace(englishPattern, '').trim();

					return textWithoutEnglish + ' ' + englishText;
				} catch (ex) {
					return text;
				}
			}
			
			

			isHebrewText(text) {
				let result = false
				let hebrewChars = new RegExp("[\u0590-\u05FF]")
				let arabicChars = new RegExp("[\u0600-\u06FF]")

				if (hebrewChars.test(text) || arabicChars.test(text)) {
					result = true
				}

				return result
			}

			isFirstCharDigit(text) {
				let c = text.charAt(0)
				return '0123456789'.includes(c)
			}
			/**
			 * This function adds Hebrew support for text. It is responsible for: (1+2 are RTL support)
			 * 1. Reverse string
			 * 2. Right aligning the text
			 * 3. Making sure Hebrew chars are printed properly
			 */

			addHebrewSupportToText(text) {
				// 1. Reverse string
				text = (`` + text).split(``).reverse().join(``);
				text = text.replace(new RegExp(`[a-z0-9]*`, `g`), (match) => {
					return match.split(``).reverse().join(``);
				});

				// 2. Right aligning the text
				var lineLength = 42;
				var textLength = text.length;
				if (textLength > lineLength) {
					text = text.substr(0, lineLength);
				} else {
					text = Array(lineLength - textLength).join(` `) + text;
				}

				// 3. Making sure Hebrew chars are printed properly
				var alef = 1488;
				var taf = 1514
				for (var i = alef; i <= taf; i++) {
					text = text.replace(new RegExp(String.fromCharCode(i), `g`), String.fromCharCode(i - 1360));
				}

				return text;
			}

			_openDrawer() {
				return new Promise((resolve, reject) => {
					if (printer.isA4() || jsonConfig.getVal(jsonConfig.KEYS.printWithPrimaryPos)) {
						return resolve(false);
					}

					if ((navigator.userAgent.toLowerCase().indexOf('android') > -1 && typeof (Android) !== "undefined")) {
						if (jsonConfig.getVal(jsonConfig.KEYS.isAndroidExernalCashDrawer)) {
							Android.OpenExternalDrawer();
						} else if (session.pos.hasAnyPrinterSet) {
							Android.OpenDrawer();
						}
						resolve(true)
					}
					else {
						if (printer.jzebra === null) {
							return resolve(false)
						}

						printer.jzebra.append(String.fromCharCode(27) + "\x70" + "\x30" + String.fromCharCode(25) + String.fromCharCode(25) + "\r", null, true);
						printer.jzebra.printWithoutPicturesPrinting()
							.then(() => resolve(true))
					}
				})


			}

			openDrawer() {
				if (printer.isA4()) {
					return;
				}
				if(jsonConfig.getVal(jsonConfig.KEYS.isOpenDrawerManagerApproval)) {
					return PositiveTS.Application.showManagerApprovalDialog()
							.then((result) => {
								 Posimod.Service.ManagerApproval.createOpenDrawerLogSuspiciousActivity();
								return printer._openDrawer();
							});
				}
				else if (session.pos.openDrawerCode && session.pos.openDrawerCode != "") {
					var inppBox = new PositiveTS.Dialogs.InputDialog()
					var dialogOptions = new PositiveTS.Dialogs.InputDialogOptions();
					dialogOptions.strDefault = ``;
					dialogOptions.isPassword = true;
					dialogOptions.description = `קוד לפתיחת מגירה`;
					dialogOptions.showCancelButton = true
					dialogOptions.emptyErrorMessage = `חובה להקליד קוד תקין לפתיחת מגירה`;
					dialogOptions.inputValidator = (value) => {
						return value == session.pos.openDrawerCode;
					}
					return inppBox.open(dialogOptions)
						.then((result) => {
							Posimod.Service.ManagerApproval.createOpenDrawerLogSuspiciousActivity();
							return printer._openDrawer()
						});
				} else {			
					return printer._openDrawer()
				}
			}

			openDrawerWithLog() {
					if(!Posimod.Service.ManagerApproval.isOpenDrawerPrinting() && !(session.pos.openDrawerCode && session.pos.openDrawerCode != "")){
						Posimod.Service.ManagerApproval.createOpenDrawerLogSuspiciousActivity();
					}
					printer.openDrawer();
			}

			openDrawerIfRequired(salePayments: PositiveTS.Storage.Entity.SalePayment[]): Promise<any> {
				let isOpenDrawer = false;
				for (let p of salePayments) {
					if (PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER == p.method || PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT == p.method) {
						let data = JSON.parse(p.data)

						for (let row of data) {
							if (row.voucher_type_id == PositiveTS.Service.Hakafa.VOUCHER_TYPE_ID ||
								row.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS ||
								row.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE ||
								row.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS ||
								row.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU) {
								continue;
							}
							isOpenDrawer = true;
						}
					} else if (p.method !== PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT || (!jsonConfig.getVal(jsonConfig.KEYS.shouldNotOpenDrawerWithCreditCard))) {
						isOpenDrawer = true;
					}
				}
				if (isOpenDrawer) {
					return printer._openDrawer()
				} else {
					return Promise.resolve()
				}

			}

			printBarcode(barcodeNumber, printerType, printBarcodeNumberAfterTheBarcode = true) {
				barcodeNumber = barcodeNumber.toString();
				let aThis = printer;
				var zebra = aThis.jzebra;

				if (aThis.isHTMLBasedPrinting()) {
					if (aThis.isPicturesPrinter()) {
						let canvas = document.createElement("canvas");
						JsBarcode(canvas, barcodeNumber);
						zebra.append(`<div class="center"><img src="${canvas.toDataURL("image/png")}" height=${canvas.height}></div>`, null, false);
					}

					return;
				}

				let dict: any = {};
				dict["t1"] = {};
				dict["t1"].prefix =
					String.fromCharCode(27) +
					`a2` +
					String.fromCharCode(27) +
					`b` +
					String.fromCharCode(6) +
					String.fromCharCode(4) +
					String.fromCharCode(1) +
					`5`;
				dict["t1"].surfix = String.fromCharCode(18);

				dict["t2"] = {};
				dict["t2"].prefix =
					String.fromCharCode(27) +
					`a2` +
					String.fromCharCode(29) +
					String.fromCharCode(104) +
					String.fromCharCode(60) +
					String.fromCharCode(29) +
					String.fromCharCode(119) +
					String.fromCharCode(2) +
					String.fromCharCode(29) +
					String.fromCharCode(107) +
					String.fromCharCode(73) +
					String.fromCharCode(20) +
					String.fromCharCode(123) +
					String.fromCharCode(65);

				dict["t2"].surfix =
					String.fromCharCode(13);

				if (printerType) {

					if (session.pos.printerType == PRINTER_TYPE.ibm4610) {
						zebra.append(aThis.allowComponents.barcode + `\x07` + barcodeNumber + `\x00`);
					}
					else if (printer.isSunmi()) {
						printer.jzebra.append(

							String.fromCharCode(29) + String.fromCharCode(104) + String.fromCharCode(64) +

							String.fromCharCode(29) + "k" + String.fromCharCode(73) + String.fromCharCode(barcodeNumber.length + 2) +

							String.fromCharCode(123) +

							String.fromCharCode(66) + barcodeNumber
						)
					} else {
						printer.jzebra.append(
							String.fromCharCode(27) + String.fromCharCode(64) +

							String.fromCharCode(29) + String.fromCharCode(71) + String.fromCharCode(1) +

							String.fromCharCode(29) + String.fromCharCode(102) + String.fromCharCode(1) +

							String.fromCharCode(29) + String.fromCharCode(104) + String.fromCharCode(64) +

							String.fromCharCode(29) + "k" + String.fromCharCode(73) + String.fromCharCode(barcodeNumber.length + 2) +

							String.fromCharCode(123) +

							String.fromCharCode(66) +

							barcodeNumber);

					}





					printer.jzebra.append("\n");



					if(printBarcodeNumberAfterTheBarcode) {
						aThis.printLine(barcodeNumber);
					}

					aThis.printLine(` `);

					aThis.printLine(` `);

				} else {

					aThis.printBarcodeEpson(barcodeNumber);

				}


			}

			printBarcodeEpson(barcodeNumber) {
				if (printer.jzebra === null) {
					return false;
				}

				let aThis = printer;
				var zebra = aThis.jzebra;

				if (aThis.isHTMLBasedPrinting()) {
					return false;
				}

				zebra.append(String.fromCharCode(29) + `w` + 40);
				zebra.append(aThis.allowComponents.barcode + barcodeNumber + `\x02`);
				//zebra.append(String.fromCharCode(93) + `k` + barcodeNumber + `\x02`);
				aThis.printLine(` `);
			}
		}
	}
}

declare var printer: PositiveTS.Printing.Base;
printer = new PositiveTS.Printing.Base();
