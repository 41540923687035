module PositiveTS {
  export module Components {
    export module multiCurrencyCashDetailsDialog {
      const dialogSelector = 'multi-currency-cash-details-dialog';
      export function create() {
        let multiCurrencyCashDetailsDialog = {
          template: JST.multiCurrencyCashDetailsDialog(),
          data() {
            return {
              currencies: {},
              cashCurrenciesAmounts: {},
              resolveFunc: null,
              xReport:null,
              isStoreXReport:false
            };
          },
          computed: {
            posCurrencySign() {
              return Service.MultiCurr.getInstance().getPosCurrencySign();
            },
          },
          methods: {
            cancel() {
              return this.close();
            },

            async calculateCashCurrenciesAmounts(){
              let multiCurr = Service.MultiCurr.getInstance()
              let currenciesCalculator = new PositShared.CalcCurrenciesSummary(Service.MultiCurr.getInstance().getPosCurrency(), i18next);
              let calulatedCurr = currenciesCalculator.calcCash(this.xReport.salePayments)
              for (const currCode of multiCurr.getCurrenciesEnabled()) {
                this.currencies[currCode].amount = (calulatedCurr[currCode]?.total || 0);
              }
            },
            async calculatePosCashStatements(){
              let lastEndDayStatement = await PositiveTS.Storage.Entity.CashStatementLog.fetchLastOfType(PositiveTS.Shared.Constants.CashierStatement.TYPE_END_DAY);
              let cashStatements
              if(lastEndDayStatement){
                cashStatements = await appDB.cashStatementLogs.where('statement_time').above(lastEndDayStatement.statement_time).toArray()
              }else{
                cashStatements = await appDB.cashStatementLogs.toArray()
              }
              this.calculateCashStatements(cashStatements)

            },
            calculateCashStatements(cashStatements){
              for(let cashStatementIndex =0;cashStatementIndex <cashStatements.length;cashStatementIndex++){
                let addOperations = [Shared.Constants.CashierStatement.TYPE_START_DAY,Shared.Constants.CashierStatement.TYPE_ADD_TO_POS]
                let subOperations = [Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS]
                let statementData = JSON.parse(cashStatements[cashStatementIndex].data)
                if(statementData?.currencies){
                  statementData.currencies.forEach((currencyStatement) =>{
                    let statementType = cashStatements[cashStatementIndex].type || cashStatements[cashStatementIndex].statement_type
                    if(addOperations.indexOf(statementType) > -1){
                      this.currencies[currencyStatement["code"]]["amount"]+= Number(currencyStatement["currencyAmount"] || 0)
                    }else if(subOperations.indexOf(statementType) > -1){
                      this.currencies[currencyStatement["code"]]["amount"]-= Number(currencyStatement["currencyAmount"] || 0)
                    }
                  })
                }
              }
            },
            calculateStoreCashStatements(){
              let storeCashierStatments = this.xReport?.storeCashierStatments;
              if(storeCashierStatments){
                this.calculateCashStatements(storeCashierStatments)
              }
              
            },
            initCurrencies() {
              let currencies = PositiveTS.Service.MultiCurr.getInstance().getPosCurrenciesEnabled();
              currencies.map((currency) => {
                const name = PositiveTS.Service.MultiCurr.getInstance().getCurrencyName(currency);
                const code = currency;
                const symbol = PositiveTS.Service.MultiCurr.getInstance().getCurrencySign(currency);
                this.currencies[currency] = { name, symbol, code,amount:0 };
              });
            },
            async open(xReport) {
              this.xReport = xReport;
              this.isStoreXReport = this.xReport.isStoreXReport;
              this.initCurrencies();
              await this.calculateCashCurrenciesAmounts();
              if(this.xReport.isStoreXReport){
                await this.calculateStoreCashStatements();
              }else{
                await this.calculatePosCashStatements();
              }
              
              this.$el.showModal();
              return new Promise(resolve => { this.resolveFunc = resolve });
            },
            close(resolveResult) {
              this.$el.close();
              this.resolveFunc(resolveResult);
            },
          },
        }
        VueApp.component(dialogSelector, multiCurrencyCashDetailsDialog)
      }
    }
  }
}
