module PositiveTS {
 export module Service {
   export module Check {

     export class CheckErnConfirmCheckNumbers{

       inputBoxConfirmAndSetCheckNumbers(dictSoapData:ClassDictTran, byRefFieldsData) {

         var checkNumbers = this._extractCheckNumbersFromDictSoapData( dictSoapData );


         return DialogErnSetCheckNumber.promiseReturnCheckNumbersArray(checkNumbers, dictSoapData)
         .then( (aryCheckNumbers) => {
             this._applayCheckNumbersFromArray(dictSoapData, aryCheckNumbers);
             byRefFieldsData.aryCheckNumber = aryCheckNumbers;
           });

       };

       private _applayCheckNumbersFromArray(dictSoapData:ClassDictTran, checkNumbers:string[]){
           dictSoapData.CheckNumber = checkNumbers[0];


           for (var i=1; i<checkNumbers.length; i++){
             dictSoapData.payments[i - 1].checkNumber = checkNumbers[i];
           }


       };

       private _extractCheckNumbersFromDictSoapData( dictSoapData:ClassDictTran ){
         var _return = [];

         _return.push( dictSoapData.CheckNumber.toString() );

         for (var i=0; i<dictSoapData.payments.length; i++){
           _return.push( dictSoapData.payments[i].checkNumber.toString() );
         }

         return _return;
       };


     }

}}}
