module PositiveTS {
  export module Service {

    export class FullHttpResult {
      constructor(
        public body: string,
        public headers: any,
        public status: number,
        public success: boolean
      ) { }

      getJsonBody() {
        if (posUtils.isBlank(this.body)) {
          return {};
        }

        return JSON.parse(this.body);
      }
    }


    export module HTTPService {
      export async function makeHttpRequest(url: string, type: string, body: any = null, headers = { "accept": "application/json" }, timeout = 120000): Promise<any> {
        let data = {
          "body": body ? JSON.stringify(body) : "",
          "type": type,
          "url": url,
          "headers": JSON.stringify(headers),
          "timeout": timeout,
        };

        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify(data) },
          "external_service"
        )

        return posUtils.isBlank(response.request.result) ? null : JSON.parse(response.request.result);
      }

      /** makeHttpRequest - returns only the requested data parsed, throws error when the status is not 200 (like 422, 500)
       * 
       * makeHttpRequestWithFullResponse - returns the request data, headers, status no matter what the status are.      
       * 
       * the data is located at the body prop and he is unparsed, for cases where the server returns 500 error with a message instead of json.
       * 
       * Also support PUT, PATCH, DELETE requests. */
      export async function makeHttpRequestWithFullResponse(url: string, type: string, body: any = null, headers = { "accept": "application/json", "Content-Type": "application/json" }, logname:string = null, timeout = 120000, keepRawBody = false): Promise<FullHttpResult> {
        let bodyToSend = "";

        if (body) {
          if (keepRawBody) {
            bodyToSend = body;
          } else {
            bodyToSend = JSON.stringify(body);
          }
        }

        let data = {
          "body": bodyToSend,
          "type": type,
          "url": url,
          "headers": JSON.stringify(headers),
          "timeout": timeout,
        };


        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify(data) },
          "http_service"
        )

        if (posUtils.isBlank(response) || posUtils.isBlank(response.request.result)) {
          Service.Logger.error("Addon error on FullHttpReuquest. addon response: " + (response || 'null') + ", request data: " + JSON.stringify(data));
          return new FullHttpResult("", {}, -2, false); // -1 is addon request error (like url not found or exception), -2 is addon version error, -3 is addon general error
        }

        let resultData = JSON.parse(response.request.result);

        if (logname) {
          Service.Filelog.log(logname, JSON.stringify({resultData, deviceId: session.pos.deviceID, requsetData: data}));
        }

        return new FullHttpResult(resultData.body, resultData.headers, resultData.status, resultData.success)
      }

      export async function makeHttpRequestWithFullResponseAndValidate(url: string, type: string, body: any = null, headers:any = { "accept": "application/json", "Content-Type": "application/json" }, logname:string = null, timeout = 120000, keepRawBody = false): Promise<FullHttpResult> {
        let result = await makeHttpRequestWithFullResponse(url, type, body, headers, logname, timeout, keepRawBody);

        if (!result.success) {
          if (result.status == -1) {
            throw new Error(i18next.t('addon.networkError'))
          }

          if (result.status == -2) {
            throw new Error(i18next.t('addon.versionIncorrect'))
          }

          if (result.status == -3) {
            Logger.error("Http addon error");
            console.error(result);
            throw new Error(i18next.t('generalError'))
          }
        }

        return result;
      }
    }
  }
}
