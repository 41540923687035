module PositiveTS {
export module Components {
export module reportsDialog {

    const _dialogSelector = "#report"
    const _vueComponentName = 'reports-dialog';
    
    export function create() {
        let reportsDialog = {
            template: JST.reportsDialog(),
            methods: {
              hasFlights() {
                return session.pos.hasFlights;
              },
              async printLegSumReport() {
                await app.showLoadingMessageAsync(i18next.t("loading"));

                await Service.FlightReport.printLegSumReport();

                app.hideLoadingMessage();
              },
              async printCurrenciesReport() {
                await app.showLoadingMessageAsync(i18next.t("loading"));

                await Service.FlightReport.printCurrenciesRatesReport();

                app.hideLoadingMessage();
              }
            },
            data() {
                return {

                }
            },
            computed: {
              posCustomersReportByHakafaCustomers(){
                return jsonConfig.getVal(jsonConfig.KEYS.posCustomersReportByHakafaCustomers);
              },
              isRoshemet(){
                return Pinia.globalStore.isRoshemet
              },
            hasExceptionsReport() {
                if(!session) {
                  return false;
                }
                return Number(session.pos.tenantID) < 10
              },
              hasCashWithdrawal() {
                return Service.Withdrawal.posHasCashWithdrawals();
              }
            }
        }
        VueApp.component(_vueComponentName,reportsDialog);
    }
}}} 
