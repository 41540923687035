module PositiveTS {
export module Storage {
export module Entity {
export class ItemMenuItem extends WasmEntity {

	static DEFAULT_IMAGES_TAGS = {
      SELF_SERVICE_ITEM: 'self-service-default-item',
      SELF_SERVICE_MENU: 'self-service-default-menu',
      SELF_SERVICE_CHILD_ITEM: 'self-service-default-child-item',	}


	private itemRef:Item
	private parsedDisallowedStores:Array<number>
	itemCode:string
	migvanId:number
	storeId:number
	color:string
	primaryMenuItemId:number
	secondaryMenuItemId:number
	thirdMenuItemId:number
	sortOrder:number
	disallowedStores:string
	level:number //not persisted
	hasPicture: boolean
	price:number


	constructor() {
		let meta = {
			name: 'ItemMenuItem',
			fields: {
				itemCode: "TEXT",
				primaryMenuItemId: "INT",
				secondaryMenuItemId: "INT",
				thirdMenuItemId:  "INT",
				disallowedStores: "TEXT",
				sortOrder: "INT",
				color: "TEXT",
				storeId: "INT",
				migvanId: "INT",
				hasPicture: "BOOL",
				price: "FLOAT"
			}
		}
		super(meta)
	}

	
	get item() {
		if (this.itemRef == null) {
			this.itemRef = session.allItems.get(this.itemCode);
		}
		return this.itemRef;
	}

	set item(value) {
		this.itemRef = value;
	}

	get notAllowedStores() {
		if (this.parsedDisallowedStores == null) {
			this.parsedDisallowedStores = JSON.parse(this.disallowedStores);
		}
		return this.parsedDisallowedStores;
	}

	static async getItemsForMenu (id: string | number, field, level, migvanId):Promise<Array<PositiveTS.Types.MenuButton>> {

		console.time("getMenuItems")
		let storeID = parseInt(session.store.storeID);
		
		let res = session.allMenuItems.filter(imi => imi[field] == id && imi.migvanId == migvanId)
		.filter(imi => 
			(imi.notAllowedStores == null || imi.notAllowedStores.indexOf(storeID) == -1) && 
			imi.item != null && 
			(imi.itemRef && imi.itemRef.cannotBeSoldSeparately == false))
		.map(imi => { 
			return {
				level: level, isItem: true, itemId: imi.item.id, sortOrder: imi.sortOrder, name: imi.item.description, 
				code: imi.itemCode, color: imi.color, id: imi.itemCode, hasPicture: imi.hasPicture, price: imi.item.priceZarhan,
				details: imi.item.details,shortDetails:imi.item.shortDetails, isShowDetails: false, translations: imi.itemRef.translations
			}
		})

		console.timeEnd("getMenuItems")
		return _.sortBy(res,'sortOrder','name')			
	};

	async onlyFlightsBarsetItem() {
		const all = await this.all();
		const barsetItemsCodesHash = Pinia.flightsStore.currentBarsetItemsCodesAsHash;
	
		return all.filter(menuItem => {
			const itemCode = menuItem.itemCode;
			const item = session.allItems.get(itemCode);
			return (!item.hasInventory) || barsetItemsCodesHash[itemCode];
		});
	}

}}}}
