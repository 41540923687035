<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useVendingMachineStore from "@/Store/vendingMachineStore"
const vendingMachine = useVendingMachineStore()
const { t } = useI18n()
const dialog = ref(null)

const open = () => {
    dialog.value.showModal();
}
const close = () => {
    dialog.value.close();
}
const requestPort = async () => {
    try {
        await vendingMachine.requestPort();
    } catch (e) {
        console.error("request port error", e)
    }
}
const updateVendingMachineInventory = async () => {
    let confirmRes = await app.showAlertDialog({
        header: t("areYouSure"),
        content: t('vendingMachine.inventory.areYouSureYouWantToReload'),
        continueButtonText: t("ok"),
        hideCancelButton: false,
    });
    if (confirmRes == "cancel") {
        return;
    }
    try {
        app.showLoadingMessageDialog(t('vendingMachine.inventory.loading'))
        await vendingMachine.getCurrentInventoryFromServer();
        app.hideLoadingMessageDialog()
        await app.showAlertDialog({
            header: t("successfullyCompleted"), content: t('vendingMachine.inventory.success'),
            continueButtonText: t("ok"), hideCancelButton: true
        });
    } catch (error) {
        PositiveTS.Service.Filelog.log(error.message, JSON.stringify(error), false, 'error');
        console.error(error);
        app.hideLoadingMessageDialog();
        await app.showAlertDialog({
            header: t("errorOccurred"), continueButtonText: t("ok"), hideCancelButton: true,
            content: error?.data?.msgForUser ? error.data.msgForUser : t('vendingMachine.inventory.errorOnLoad')
        });
    }
}
const resetMachine = async () => {
    try {
        await vendingMachine.reset();
        app.showAlertDialog({
            content: t('vendingMachine.resetingMachine'),
            continueButtonText: t("ok"),
            hideCancelButton: true,
        });
    } catch (e) {
        console.error("reset machine error", e)
    }
}

const checkStatus = async () => {
    try {
        await vendingMachine.status();
        app.showAlertDialog({
            content: vendingMachine.state.lastStatusResponse + ": " + vendingMachine.statusName,
            continueButtonText: t("ok"),
            hideCancelButton: true,
        });
    } catch (e) {
        console.error("check status error", e)
    }
}

const toggleLogger = async () => {
    try {
        vendingMachine.toggleLogs(!vendingMachine.state.logsEnabled);
    } catch (e) {
        console.error("toggle logger error", e)
    }
}

const tempCheck = async () => {
    try {
        app.showLoadingMessageDialog(t('vendingMachine.queringMachine'))
        let temp = await vendingMachine.tempCheck();
        app.hideLoadingMessageDialog();
        if (temp == -99) {
            app.showAlertDialog({
                content: t('vendingMachine.tempCheckError'),
                continueButtonText: t("ok"),
                hideCancelButton: true,
            });
            return;
        }
        app.showAlertDialog({
            content: t('vendingMachine.tempIs') + temp + "°C",
            continueButtonText: t("ok"),
            hideCancelButton: true,
        });
    } catch (e) {
        console.error("temp check error", e)
    }
}

const toggleLogsLabel = computed(() => {
    return vendingMachine.state.logsEnabled ? t('vendingMachine.disableLogs') : t('vendingMachine.enableLogs');
})

defineExpose({
    open
})
</script>
<template>
    <dialog id="vending-manage-machine" ref="dialog" class="p-0 w-90vw p2:w-[55vw]">
        <div class="p-0">
            <div class="title w-full bg-primary-default text-center text-white py-2">{{ t('vendingMachine.manageMachine') }}
            </div>
            <div class="p-4 flex flex-col items-center justify-center">
                <div class="grid grid-cols-3 gap-3 w-full 
                    *:bg-gray-300 *:rounded-md *:py-8 *:px-4 *:text-black *:text-xl *:text-center *:cursor-pointer">
                    <button @click.stop="resetMachine()">{{ t('vendingMachine.resetMachine') }}</button>
                    <button @click.stop="requestPort()">{{ t('vendingMachine.requestPort') }}</button>
                    <button @click.stop="updateVendingMachineInventory()">{{ t('vendingMachine.inventoryUpdate') }}</button>
                    <button @click.stop="checkStatus()">{{ t('vendingMachine.checkStatus') }}</button>
                    <button @click.stop="toggleLogger()">{{ toggleLogsLabel }}</button>
                    <button @click.stop="tempCheck()">{{ t('vendingMachine.tempCheck') }}</button>
                </div>
                <div class="py-4">
                    <button class="positive-simple red shadow" @click="close">{{ t('close') }}</button>
                </div>
            </div>
        </div>
    </dialog>
</template>