module PositiveTS {
	export module Service {
		export module CreditVoucher {
			export function  GetCreditVoucher(voucherId, callback) {

				if (voucherId == null) {
					return;
				}
			
				$.getJSON(Shared.Constants.remoteRoot + 'credit_vouchers/' + voucherId,{}, callback)
				.error(function() {
					callback(null);
				});
			};
			

		}
	}
}

