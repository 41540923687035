module PositiveTS {
export module Components {
    export module QtyDialog {
      const dialogSelector = "qty-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          qty: 0,
          hasWeight: false,
          originalQty: 0,
          allowZero: false,
          allowNeg: false,
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          dialogElem: document.getElementById(dialogSelector),
          fields: initFields()

        }
      }

      function initFields() {
        let fields = {
          'qty': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'qty'
          }
        }
        return fields;
      }

      function close() {
        if (this.initialized) {
          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.close();
        }
        this.cleanData()
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      function open(originalQty,allowZero = false,allowNeg = false, hasWeight = false) {
        return new Promise((resolve,reject) => {
          this.initialized = true;

          this.allowZero = allowZero;
          this.allowNeg = allowNeg;
          this.hasWeight = hasWeight;
          this.originalQty = originalQty;
          
          $(document).unbind('keypress');

          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.showModal();
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
        })
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        let aThis = this;
        app.showAlertDialog({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.$refs.qty.focus();
        },null)
      }

      function confirmAndClose() {
        
        let qty = Number(this.qty);

        if(!isNaN(qty) && (qty !== 0 || this.allowZero) && (Math.abs(qty) < 10000 || this.selfService)) {
          if(this.selfService && qty<0){
            this.showError(i18next.t("pos.changeQuantityMustBePositiveError"));
            return;
          }
          const canChangeQuantityWithDecimalFraction = jsonConfig.getVal(jsonConfig.KEYS.canChangeQuantityWithDecimalFraction)
          if (!canChangeQuantityWithDecimalFraction && !this.hasWeight && Math.round(qty) != qty) {
            this.showError(i18next.t("qtyDialog.cannotApplyDecimalQtyToRegularItem"));
            return;
          }
          this.resolveFunc({approved:true, qty: Number(this.qty)})
          this.close();
        }
        else{
          let errorMessage = this.selfService ? i18next.t("pos.changeQuantityMustBePositiveError") : i18next.t("pos.changeQuantityError");
          this.showError(errorMessage);
        }
        return;
      }

      function cancelTapped() {
        this.resolveFunc({approved: false, qty: this.originalQty})
        
      }

      function clearTapped(stayOpen?){
        this.qty = 0;
        if(!stayOpen){
          this.close();
        }
      }

        function onKeyTapped(key) {
          this.keyPadHanlder(key,this.fields["qty"],this,3);
        }

      export function create() {

        let component = {
          template: JST.qtyDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData: cleanData,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            cancelTapped: cancelTapped,
            onKeyTapped: onKeyTapped,
            clearTapped: clearTapped,
            showError: showError,
            showGenericError: showGenericError,            
          },
          data: initData,
          computed: {
            saleHasItems() {
              return Pinia.globalStore.saleItems.length != 0;
            },
            selfService(){
              return Pinia.globalStore.simpleSelfService;
            },
          }
        }

        VueApp.component('qty-dialog',component)

      }
    }
  }
}
