module PositiveTS {
    export module Service {
    interface CaveretPaymentResponse extends GetBalanceResponse {
        Budgets:Array<any>
        ReplyCode:Number
        ScreenCommentsBody:String
        ScreenCommentsHeader:String
        BudgetTotal:Number
        CustomerCode:String
        CustomerName:String
        AllowOtherPayments:boolean
    }

    export module CaveretPaymentObjects{
        export let baseObj = {
            IDToken: "0",
            CompanyID: 0,
            TerminalID: 0,
            Numartor: 0,
            DataType: 1,
            Payload: "",
            PinCode: "0000",
            OTP: "",
            CustomerCode: "",
            RequestType: 0,
            CaveretPaymentType: 0
          }

        export let saleDoc = {
            BudgetsToCharge:[],
            DocDiscount: 0,
            DocTotal: 0,
            InvoiceNum: "",
            IsCustomerVatFree: false,
            PaymentLines: [],
            PosID: "",
            SaleDate: "",
            SaleLines: [],
            StoreID: "",
            TerminalOTC: ""
        }
        export let transactionCancelObj = {
            OriginalConfirmationCode: 0,
            OriginalDocTotal: 0,
            OriginalStoreID: "",
            OriginalTerminalID: "",
            OriginalSaleDate: "",
            TerminalOTC: "",
            Amount: 0

        }
    }
      export class CaveretPayment extends SmartVoucher {
          caveretBaseUrl = "https://broker.shkl.co.il/Publish/CaveretTickerService/CaveretTickerService.svc/"
          headers = JSON.stringify({
            "Content-Type": "application/json; charset=utf-8"
          })          
         
          updateCustomer(){
            CaveretPaymentObjects.baseObj.CustomerCode = "";
            let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
            let currentCustomer = customerClubService.getCurrentSelectedCustomer();
            if(currentCustomer){
                CaveretPaymentObjects.baseObj.CustomerCode = currentCustomer.s_member_no;
            }
          }

          async getBalance(cardNumber: string, cvv?: string, inputMethod?:number,clubCode?:number): Promise<CaveretPaymentResponse> {
            this.updateCustomer();

              CaveretPaymentObjects.baseObj.CompanyID = Number(session.pos.companyID);
              CaveretPaymentObjects.baseObj.TerminalID = Number(session.pos.storeID) * 100 + session.pos.posNumber;
              CaveretPaymentObjects.baseObj.Payload = cardNumber;
              CaveretPaymentObjects.baseObj.RequestType = 1;
              CaveretPaymentObjects.baseObj.PinCode = cvv;
              CaveretPaymentObjects.baseObj.DataType = inputMethod;
              
              if(clubCode){
                CaveretPaymentObjects.baseObj.CaveretPaymentType = clubCode
              }

              let url = this.caveretBaseUrl + "PreSale";
              this.updateSaleDoc(false);
              
              var data = JSON.stringify({
                "body": JSON.stringify(CaveretPaymentObjects.saleDoc).replace("\\",""),
                "type": "POST",
                "url": url,
                "headers": this.headers
            });
      
            return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                { data: data },
                "external_service"
            ).then((x) => {
                PositiveTS.Service.Filelog.log("caveretpayment",
                JSON.stringify({request:x.request.body.data, result:x.request.result}))

                return JSON.parse(x.request.result);
            })
          }
          async pay(voucherData: any, amount: number, cvv?: string,companyID?:number,additionalData?): Promise<any> {
            this.updateCustomer();
            app.showLoadingMessageDialog(i18next.t("caveretPayment.chargingCard"));
            let url = this.caveretBaseUrl + "Pay";
            CaveretPaymentObjects.baseObj.RequestType = 2;
            CaveretPaymentObjects.baseObj.PinCode = additionalData.pincode;

            if(additionalData.clubCode){
                CaveretPaymentObjects.baseObj.CaveretPaymentType = additionalData.clubCode;
            }
            
            this.updateSaleDoc();
            CaveretPaymentObjects.saleDoc.BudgetsToCharge = [];
            
            for(let budget of additionalData.budgets){
                    CaveretPaymentObjects.saleDoc.BudgetsToCharge.push({
                        AmountToCharge: budget.used,
                        BudgetID: budget.BudgetID
                    })
            }

            let payment = new PositiveTS.Storage.Entity.SalePayment();
            payment.method = Storage.Entity.SalePayment.METHOD_VOUCHER;
            payment.amount = amount;
            payment.saleID = posVC.sale.id;
            CaveretPaymentObjects.saleDoc.PaymentLines = [];
            for(let paymentToSend of posVC.salePayments){

                let paymentType = paymentToSend.method;
                let paymentDesc = i18next.t(`paymentTypes.${paymentToSend.method}`)
                let numOfPayments = 1;

                if(!posUtils.isNullOrUndefinedOrEmptyString(JSON.parse(paymentToSend._data.data))){
                    let jdata = JSON.parse(paymentToSend._data.data)[0];
                    if(jdata.voucher_type_id){
                        paymentType = Number(jdata.voucher_type_id)
                        paymentDesc = jdata.creditType;
                    }
                    if(jdata.payments_count){
                        numOfPayments = Number(jdata.payments_count) ;
                    }
                }
                CaveretPaymentObjects.saleDoc.PaymentLines.push({
                    PaymentMethood: paymentToSend.method,
                    PaymentType: paymentType,
                    PaymentDesc: paymentDesc,
                    PaymentAmount: paymentToSend.amount,
                    NumOfPayments: numOfPayments
                }) 
            }

            var data = JSON.stringify({
              "body": JSON.stringify(CaveretPaymentObjects.saleDoc).replace("\\",""),
              "type": "POST",
              "url": url,
              "headers": this.headers
            });
        
            let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                { data: data },
                "external_service"
            );

            PositiveTS.Service.Filelog.log("caveretpayment",
            JSON.stringify({request:response.request.body.data, result:response.request.result}))

            app.hideLoadingMessageDialog();

            let result = JSON.parse(response.request.result);
            if(result.ReplyCode == 1){
                let dataToReturn = await this.addPayment(response.request.result,payment,voucherData);
                if(!jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos))
                  {await app.showAlertDialog({
                      header:  result.ScreenCommentsHeader,
                      content: result.ScreenCommentsBody,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                  })}

                return dataToReturn;
            }
            else{
                throw result.ScreenCommentsBody
            }
          }
          updateSaleDoc(includeSaleLines = true){
            Object.assign(CaveretPaymentObjects.saleDoc,CaveretPaymentObjects.baseObj);
            CaveretPaymentObjects.saleDoc.DocTotal = posVC.getTotalAmount();
            CaveretPaymentObjects.saleDoc.DocDiscount = Number(posVC.sale.discountPercent);
            CaveretPaymentObjects.saleDoc.InvoiceNum = "0";
            CaveretPaymentObjects.saleDoc.IsCustomerVatFree = false; //TODO: CAVERET
            CaveretPaymentObjects.saleDoc.PosID = session.pos.deviceID;
            CaveretPaymentObjects.saleDoc.SaleDate = moment(posVC.sale.createdAt).format("DD/MM/YYYY HH:mm:ss.SSS");
            CaveretPaymentObjects.saleDoc.StoreID = session.pos.storeID;
            CaveretPaymentObjects.saleDoc.TerminalOTC = Sha256.sha256.hash(`${CaveretPaymentObjects.baseObj.CustomerCode}|${CaveretPaymentObjects.saleDoc.DocTotal}|${CaveretPaymentObjects.saleDoc.SaleDate}`);
            
            
            CaveretPaymentObjects.saleDoc.SaleLines = [];
            let flattenItems = Storage.Entity.SaleItem.flattenItems(posVC.saleItems);
            if(includeSaleLines){
            for(let saleItem of flattenItems){
                let parentItemCode = "";
                if(saleItem.parent){
                    parentItemCode = saleItem.parent.itemCode;
                }

                let isPackageItem = false;

                if(Boolean(saleItem.item.disableItemGroupEditing) && saleItem.children && saleItem.children[0].item.isDeposit){
                    isPackageItem = true;
                }

                CaveretPaymentObjects.saleDoc.SaleLines.push({
                    AdditionalData1: "",
                    AdditionalData2: "",
                    AdditionalData3: "",
                    AdditionalData4: "",
                    Barcode: saleItem.barcode,
                    CatalogUnitPrice: Number(saleItem.originalUnitPrice.toFixed(6)),
                    Color: saleItem.color || "",
                    Discounts: [ ],
                    IsAdditionalItem: saleItem.isChild, 
                    IsNoVatItem: saleItem.noVat,
                    IsPackageItem: isPackageItem, 
                    ItemCode: saleItem.itemCode,
                    ItemDesc: saleItem.itemDescription,
                    LineNum: saleItem.rowNumber,
                    LineTotal: Number((saleItem.unitPrice * saleItem.quantity).toFixed(6)), 
                    ParentItemCode: parentItemCode,
                    Quantity: saleItem.quantity,
                    Size: saleItem.size || "",
                    UnitPrice: Number(saleItem.unitPrice.toFixed(6))
                })
            }
            }
          }
          async addPayment(data:any,payment: Storage.Entity.SalePayment,voucherData){

            let voucher = new PositiveTS.Storage.Entity.Voucher();
            let result = await voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);
            for (let voucher of result){
              if (voucher.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT){
                Pinia.globalStore.setSelectedVoucherType(voucher);
              }
            }
            data = JSON.parse(data);
            voucherData.data = JSON.stringify(data);
            voucherData.smartVoucherType = Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT;
            voucherData.allowPartialReturn = true;
            voucherData.amount = payment.amount;
            voucherData.creditType = Pinia.globalStore.selectedVoucherType.name;
            voucherData.barCode = CaveretPaymentObjects.baseObj.Payload;
            voucherData.customerCode = CaveretPaymentObjects.baseObj.CustomerCode;
            voucherData.voucher_type_id = Pinia.globalStore.selectedVoucherType.typeID;
            voucherData.confirmationNum =  data.ConfirmationNum;
            voucherData.storeID = session.store.storeID;
            voucherData.deviceID = session.pos.deviceID;
            voucherData.saleDate = CaveretPaymentObjects.saleDoc.SaleDate;
            voucherData.AdditionalData = data.AdditionalData;
            voucherData.budgetsUsed = CaveretPaymentObjects.saleDoc.BudgetsToCharge;
            voucherData.PrintComments = data.PrintComments;
            
            if(data.DocType != 1){
                voucherData.isTamashCustomer = true;
            }

            return voucherData;
          }
          cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
              let url = this.caveretBaseUrl + "cancelTransaction";
              let voucherToCancelData = JSON.parse(voucherToCancel.data);
              let confirmationNum = voucherToCancelData.ConfirmationNum;
              CaveretPaymentObjects.baseObj.CompanyID = Number(session.pos.companyID);
              CaveretPaymentObjects.baseObj.TerminalID = Number(session.pos.storeID) * 100 + session.pos.posNumber;
              CaveretPaymentObjects.baseObj.Payload = voucherToCancel.barCode;
              CaveretPaymentObjects.baseObj.RequestType = 3;
              CaveretPaymentObjects.baseObj.CustomerCode = voucherToCancel.customerCode;
              CaveretPaymentObjects.baseObj.DataType = 4;

              const budgets = voucherToCancel.budgetsUsed
              let totalBudgetUsed = 0
              for(let i=0; i<budgets.length;i++){
                totalBudgetUsed += budgets[i].AmountToCharge
              }

              Object.assign(CaveretPaymentObjects.transactionCancelObj,CaveretPaymentObjects.baseObj);
              CaveretPaymentObjects.transactionCancelObj.OriginalConfirmationCode = confirmationNum;
              CaveretPaymentObjects.transactionCancelObj.OriginalDocTotal = totalBudgetUsed
              CaveretPaymentObjects.transactionCancelObj.Amount = voucherToCancel.amount * -1
              CaveretPaymentObjects.transactionCancelObj.OriginalStoreID = voucherToCancel.storeID;
              CaveretPaymentObjects.transactionCancelObj.OriginalTerminalID = voucherToCancel.deviceID;
              CaveretPaymentObjects.transactionCancelObj.OriginalSaleDate = voucherToCancel.saleDate;
              CaveretPaymentObjects.transactionCancelObj.TerminalOTC = Sha256.sha256.hash(`${CaveretPaymentObjects.baseObj.CustomerCode}|${voucherToCancel.amount}|${voucherToCancel.saleDate}`);

              
              var data = JSON.stringify({
                "body": JSON.stringify(CaveretPaymentObjects.transactionCancelObj).replace("\\",""),
                "type": "POST",
                "url": url,
                "headers": this.headers
              });
          
              return Service.GenericAddon.sendNativeMessageToExtension(
                  { data: data },
                  "external_service"
              ).then(function(res){
                  if(!res){
                    return {
                        success: false,
                        error: "Error while crediting Caveret payment"
                    }
                  }
                  else{
                    PositiveTS.Service.Filelog.log("caveretpayment",
                    JSON.stringify({request:res.request.body.data, result:res.request.result}))

                    let resJson = JSON.parse(res.request.result)
                    if(resJson.ReplyCode == 1){
                        voucherToCancel.confirmationNum = resJson.ConfirmationNum;
                        voucherToCancel.PrintComments = resJson.PrintComments;
                        return {
                            success: true,
                            error: ""
                        }
                      }else{
                        return {
                            success: false,
                            error: JSON.parse(res.request.result).ScreenCommentsBody
                        }
                      }
                  }
            });
          }
          cancelLoad(paymentToCancel: any): Promise<any> {
              throw new Error("Method not implemented.");
          }
          loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
              throw new Error("Method not implemented.");
          }
          getCardNumber(): Promise<GetCardNumberResponse> {
              throw new Error("Method not implemented.");
          }

      }
    }
}
