export default {
    error: 'שגיאה',
    generalError: "שגיאה כללית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
    success: 'הצלחה',
    gatewayPayment:{
      transactionTypes:{
        blockedCard:"כרטיס חסום",
        regularDebit:"חובה רגילה",
        approvedDebit:"חובה מאושרת",
        forced:"עסקה מאולצת",
        cashback:"cashback",
        cash:"מזומן",
        recurringDebit:"הוראת קבע",
        balanceEnquiry:"בירור יתרה",
        regularCredit:"עסקת זיכוי",
        cancel:"ביטול",
        approvedCredit:"זכות מאושרת",
        load:"טעינה",
        reversal:"שחרור אובליגו",
        discharge:"עסקת פריקה",
        retrieveCardNumber:"איתור מספר כרטיס",
      },
      authcCodeCreditCompany:{
        issuer: "מנפיק",
        shva: 'שב"א בשירות STIP',
        card: "כרטיס חכם",
        voiceCall: "מענה קולי",
      },
    },
    emv: {
        performingPinpadConfiguration: "קורא הגדרות מהמסופון",
        queryTransaction: "מאתר עסקה בפינפאד",
        filteringFaildTran: "מוודא התאמה של עסקאות לפני שידור",
        payAttention: "שים לב",
        PINPAD_NOT_CONNECTED: "החיבור לקורא הכרטיסים אינו תקין. יש לוודא כי קורא הכרטיסים מחובר לקופה ודולק ולנסות שוב.\n במידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
        PINPAD_NOT_CONNECTED_ANDROID: "החיבור לקורא הכרטיסים אינו תקין. יש לוודא כי קורא הכרטיסים מחובר לקופה ודולק ולנסות שוב. \n\nמומלץ לסגור את אפליקציית הקופה ולהיכנס מחדש - כפי שמודגם בתמונה מטה.\n\n במידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
        PINPAD_NOT_CONNECTED_IP: "החיבור לקורא הכרטיסים אינו תקין. יש לוודא כי המסוף דולק ומחובר לרשת. במידה וזה ממשיך, יש לסגור את האפליקצית תשלום ולפתוח אותה מחדש ולנסות שוב.\n במידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
        TRANSMIT_ERROR: "שגיאה בעת שידור תנועות אשראי. לא ניתן להמשיך.\nחובה לפנות לתמיכה.",
        USER_CANCEL: 'הפעולה בוטלה ע"י המשתמש',
        CANNOT_CREDIT_IN_RECOVERY_MODE: 'לא ניתן לזכות כרטיס אשראי במצב עבודה ללא אשראי.\nהחיבור לקורא הכרטיסים אינו תקין או קיים קובץ תנועות ישן על המסופון שטרם שודר',
        OLD_TRAN_FILE_PRESENT: "קיים קובץ תנועות ישן על המסופון שטרם שודר. חובה לבצע התאוששות.\nלחץ על אישור בכדי לנסות לשדר תנועות שוב ולבצע התאוששות.\nמומלץ לפנות לתמיכה.",
        emptyTrack2: "בעיה בקריאת הכרטיס.",
        errorNum: "קוד שגיאה: {{error}}",
        creditPaymentsExistsButStillGot10019: "שים לב, לא היו עסקאות במסופון לשליחה למתג למרות שקיימות עסקאות אשראי",
        clickOKToCallShva: "לחץ אישור לקליטת נתונים חדשים משבא",
        cardReaderConnectivity: "בודק קישוריות לקורא הכרטיסים",
        EMV_IN_PROGRESS: "מבצע חיוב",
        EMV_CANCEL_IN_PROGRESS: "מבטל עסקה",
        EMV_RETURN_IN_PROGRESS: "מבצע החזר",
        EMV_CREDIT_IN_PROGRESS: "מזכה כרטיס יש להעביר כרטיס בקורא הכרטיסים",
        GENERAL_ERROR: "שגיאה כללית - יש לוודא כי המסופון מחובר לקופה ולנסות שוב. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
        REQUEST_AND_OUTPUT_MISMATCH: "חוסר התאמה בין בקשה לתשובה שהתקבלה - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה.",
        EMV_CREATING_Z_REPORT: "מייצר דוח Z",
        EMV_PERFORMING_TRANSMIT: "מבצע שידור",
        EMV_PERFORMING_TRANSMIT_TO_SWITCH: "מבצע שידור למתג",
        DELETING_TRAN_FILE: "מוחק קובץ TRAN מהמסופון",
        CALLING_SHVA_WITHOUT_TRANSMITTING_TRAN: "קולט נתונים משבא",
        ERROR_WHILE_DELETING_TRAN: "שגיאה בעת מחיקת קובץ TRAN",
        GETTING_TRANS_REPORT: "מקבל דוח שידור משבא",
        CREDIT_HEADER: "זיכוי אשראי",
        CREDIT_DIALOG_CONTENT: "בחר העברת כרטיס או זיכוי ידני",
        CREDIT_DIALOG_CONTINUE_BUTTON_TEXT: "העבר כרטיס",
        CREDIT_DIALOG_CANCEL_BUTTON_TEXT: "הקלדה ידנית",
        PREPARING_TRANSMISSION: "מכין נתונים לשידור",
        CHECKING_PINPAD_CONNECTION: "מוודא חיבור לקורא כרטיסים",
        UPDATING_PINPAD: "מעדכן גירסת תוכנה של המסופון",
        SENDING_LOGS_TO_FTP: "שולח לוגים ל FTP",
        CALLING_CASPIT_TECH_SERVER: "מתחבר לשרת טכנאים של כספיט",
        CHECKING_TERMINAL_CONNECTION: "בודק תקינות מסופון ",
        connectionOk: 'סטטוס תקשורת תקין',
        ERROR_WHILE_DOING_TRANSMIT: "שגיאה בעת שידור עסקאות.\n אנא וודא כי המסוף מחובר לקופה וכי יש חיבור לאינטרנט.\nחובה לפנות לתמיכה בכדי לבצע התאוששות.",
        pinpadConfigurationError: "שגיאה בקבלת הגדרות מסופון",
        blockListFileOutdatedUpdatingIt: 'קובץ חסומים לא מעודכן. מעדכן, אנא המתן...',
        cancelingPayment: "מזכה אשראי",
        checkingForSaleInPinpad: "מבצע בדיקה אם קיים חיוב עבור הכרטיס אשראי לאחר אתחול",
        responseIsNotComplete: "התשובה שחזרה מהמסופון לא מלאה. החיוב בוטל, נא לנסות שוב.",
        responseIsNotCompleteTitle: 'חיוב בוטל',
        badResponseWasntCancelled: 'הייתה שגיאה בחיוב, נא ליצור קשר עם חברת האשראי על מנת לבטל אותו',
        badResponseWasntCancelledTitle: 'שגיאה בחיוב',
        cancelPaymentNotAvailable: "לא ניתן לבטל את תשלום האשראי בשלב זה. יש להשלים את המכירה ואז לבטל את המכירה דרך חשבוניות קודמות",
        checkingAfterEmptyResult: "התקבלה תשובה ריקה, מבצע בדיקה נוספת...",
        emptyResponse: "התקבלה תשובה ריקה",
        creditTerms: {
          regular: "רגיל",
          isracredit: "ישראקרדיט",
          adifPlus30: "עדיף/+30",
          credit: "קרדיט",
          immediateCharge: "חיוב מיידי",
          payments: "תשלומים"
        },
        panEntries: {
          magnetic: "מגנטי",
          cellular: "סלולרי",
          manual: "ידני",
          kaf: "כ"
        },
        authCodesManpik: {
          card: "כרטיס",
          company: "חברה",
          shva: 'שב"א',
          manual: "ידני"
        },
        tranTypes: {
          informative: "אינפורמטיבי",
          debit: "חובה",
          dischrage: "פריקה",
          enforced: "מאולצת",
          cash: "מזומן",
          standingOrder: "הוראת קבע",
          checkBalance: "בירור יתרה",
          credit: "זכות",
          charge: "טעינה"
        },
        cardDenied: "סירוב",
        errors: {
          "10000": "לא התקבלה תשובה",
          "10003": "מספר מסוף שגוי",
          "10022": "מספר קופה שגוי (ECR)",
          "10036": "XML_MISSING_TERMINAL_ID_TAG",
          "10041": "אין תקשורת למסופון",
          "10042": "XML_MISSING_REQUEST_ID_TAG",
          "10043": "CASPIT_WINDOWS_SERVICE_NOT_RESPONDING",
          "10048": "CHECK_OTHER_STATUSES",
          "10050": "DOUBLE_TRANSACTION",
          "10053": "המסופון החזיר תשובה ריקה",
          "10054": "הגדרה שגויה של אשראית",
          "10100": "INVALID_PAN_ENTRY_MODE",
          "10101": "INVALID_MTI",
          "10102": "INVALID_X_FIELD",
          "10501": "הכרטיס לא הועבר בזמן",
          "11000": "לא ניתן להשתמש בכרטיס אשראי בפעולה זו",
          "11001": "הועבר כרטיס לא תואם",
        },
        pinpadErrorTitle: "בעיה בחיבור למסוף אשראי",
        statusCodes: {
          '0000': `מאושר`,
          '1001': `העבר\הכנס\הצג כרטיס`,
          '1002': `העבר\הצג כרטיס`,
          '1003': `העבר\הכנס כרטיס`,
          '1004': `הכנס כרטיס לקורא חכם`,
          '1005': `הצג כרטיס`,
          '1006': `נא הוצא כרטיס`,
          '1007': `נא הוצא כרטיס`,
          '1008': `העבר שנית`,
          '1009': `השתמש בקורא פס מגנטי`,
          '1010': `המסוף לא רשאי לבצע עיסקאות בכרטיס`,
          '1011': `אנא המתן השאר כרטיס`,
          '1012': `כרטיס לא תקין`,
          '1013': `כרטיס לא נתמך`,
          '1014': `קיימות עסקאות ישנות. בצע שידור`,
          '1015': `קובץ חסום לא מעודכן. בצע שידור`,
          '1016': `כרטיס לא מורשה לסוג אשראי`,
          '1017': `אין הרשאה לסוג מטבע`,
          '1018': `מספר כרטיס שגוי`,
          '1019': `הכרטיס לא מורשה במסוף`,
          '1020': `כרטיס לא מורשה לסוג העסקה`,
          '1021': `הכרטיס מחוץ לתחום`,
          '1022': `קוד מועדון לא בתחום`,
          '1023': `מנפיק לא קיים`,
          '1024': `מותג לא קיים`,
          '1025': `מספר רכב לא תקין`,
          '1026': `כרטיס לא בתוקף`,
          '1027': `נא הקלד נתונים נוספים`,
          '1028': `הקש 3 ספרות אחרונות בגב הכרטיס`,
          '1029': `הקש 4 ספרות מודפסות בכרטיס`,
          '1030': `נא הקש ת.ז. כולל ספרת ביקורת`,
          '1031': `ת.ז. שגויה`,
          '1032': `ת.ז. שגויה נסה שנית`,
          '1033':  `נא הקש כתובת לקוח`,
          '1034':  `לאלץ או להתקשר`,
          '1035':  `הקשה F1 לבקשת אישור`,
          '1036':  `התקשר לחברת אשראי`,
          '1037':  `מאושר, הוצא כרטיס`,
          '1038':  `העסקה לא אושרה`,
          '1039':  `דחייה. תקלה בתקשורת`,
          '1040':  `דחה עסקה cvv2 או ת.ז. שגוי`,
          '1041':  `דחייה. כרטיס לא משוייך לרשת`,
          '1042':  `דחה עסקה. cavv/ucaf שגוי`,
          '1043':  `דחה עסקה. כתובת שגויה`,
          '1044':  `כרטיס גנוב. החרם`,
          '1045':  `כרטיס חסום`,
          '1046':  `כרטיס מזוייף. החרם`,
          '1047':  `דחה בקשה. קוד יתרה שגוי`,
          '1048':  `דחה עסקה. חוסר בנק'/כוכבים/מיילים/הטבה אחרת`,
          '1049':  `ממתין לאישור. השאר כרטיס`,
          '1050':  `העסקה אושרה. הוצא כרטיס`,
          '1051':  `נא הקלד מס' אישור קולי שהתקבל מחברת האשראי`,
          '1052':  `מהדורת תוכנה שגויה`,
          '1053':  `נתוני עסקה שגויים`,
          '1054':  `מסוף לא ביצע הקמה`,
          '1055':  `כרטיס לא ניתמך, קוד שרות`,
          '1056':  `סולק לא קיים`,
          '1057':  `סט פרמטרים שגוי`,
          '1059':  `דחייה, כרטיס נטען`,
          '1060':  `נתוני עסקת הטבה שגויים`,
          '1061':  `אין הרשאה לביצוע עסקה`,
          '1062':  `נא הקש מיקוד הלקוח`,
          '1063':  `מזהה שגוי`,
          '2000':  `שגיאה אפליקטיבית`
        }
    },
    // EMV TRanslitaion
    emvAshStatusCodes: {
        "000": `מאושר`,
        "001": `כרטיס חסום`,
        "002": `גנוב החרם כרטיס`,
        "003": `התקשר לחברת האשראי`,
        "004": `העסקה אינה מאושרת. יש לפנות לחברת האשראי של בעל הכרטיס`,
        "005": `כרטיס מזוייף החרם`,
        "006": `דחה עסקה : cvc2/id שגוי`,
        "007": `דחה עסקה : cavv/ucaf שגוי`,
        "008": `דחה עסקה avs שגוי`,
        "009": `דחייה - נתק בתקשורת`,
        "010": `אישור חלקי`,
        "011": `דחה עסקה: חוסר בנקודות/כוכבים/מיילים/הטבה אחרת`,
        "012": `בכרטיס לא מורשה במסוף`,
        "013": `דחה בקשה - קוד יתרה שגוי`,
        "014": `דחייה - כרטיס לא משוייך לרשת`,
        "015": `דחה עסקה: הכרטיס אינו בתוקף`,
        "016": `דחייה - אין הרשאה לסוג מטבע`,
        "017": `דחייה - אין הרשאה לסוג אשראי בעסקה`,
        "026": `דחה עסקה - id שגוי`,
        "041": `ישנה חובת יציאה לשאילתא בגין תקרה בלבד לעסקה עם פרמטר j2`,
        "042": `ישנה חובת יציאה לשאילתא לא רק בגין תקרה, לעסקה עם פרמטר j2`,
        "051": `חסר קובץ ווקטור 1`,
        "052": `חסר קובץ ווקטור 4`,
        "053": `חסר קובץ ווקטור 6`,
        "055": `חסר קובץ ווקטור 11`,
        "056": `חסר קובץ ווקטור 12`,
        "057": `חסר קובץ ווקטור 15`,
        "058": `חסר קובץ ווקטור 18`,
        "059": `חסר קובץ ווקטור 31`,
        "060": `חסר קובץ ווקטור 34`,
        "061": `חסר קובץ ווקטור 41`,
        "062": `חסר קובץ ווקטור 44`,
        "063": `חסר קובץ ווקטור 64`,
        "064": `חסר קובץ ווקטור 80`,
        "065": `חסר קובץ ווקטור 81`,
        "066": `חסר קובץ ווקטור 82`,
        "067": `חסר קובץ ווקטור 83`,
        "068": `חסר קובץ ווקטור 90`,
        "069": `חסר קובץ ווקטור 91`,
        "070": `חסר קובץ ווקטור 92`,
        "071": `חסר קובץ ווקטור 93`,
        "073": `חסר קובץ PARAM_3_1`,
        "074": `חסר קובץ PARAM_3_2`,
        "075": `חסר קובץ PARAM_3_3`,
        "076": `חסר קובץ PARAM_3_4`,
        "077": `חסר קובץ PARAM_361`,
        "078": `חסר קובץ PARAM_363`,
        "079": `חסר קובץ PARAM_364`,
        "080": `חסר קובץ PARAM_61`,
        "081": `חסר קובץ PARAM_62`,
        "082": `חסר קובץ PARAM_63`,
        "083": `חסר קובץ CEIL_41`,
        "084": `חסר קובץ CEIL_42`,
        "085": `חסר קובץ CEIL_43`,
        "086": `חסר קובץ CEIL_44`,
        "087": `חסר קובץ DATA`,
        "088": `חסר קובץ JENR`,
        "089": `חבר קובץ Start`,
        "098": `ERROR_IN_NEG_FILE`,
        "099": `שגיאה כללית`,
        "101": `חסרה כניסה בוקטור 1`,
        "103": `חסרה כניסה בוקטור 4`,
        "104": `חסרה כניסה בוקטור 6`,
        "106": `חסרה כניסה בוקטור 11`,
        "107": `חסרה כניסה בוקטור 12`,
        "108": `חסרה כניסה בוקטור 15`,
        "110": `חסרה כניסה בוקטור 18`,
        "111": `חסרה כניסה בוקטור 31`,
        "112": `חסרה כניסה בוקטור 34`,
        "113": `חסרה כניסה בוקטור 41`,
        "114": `חסרה כניסה בוקטור 44`,
        "116": `חסרה כניסה בוקטור 64`,
        "117": `חסרה כניסה בוקטור 81`,
        "118": `חסרה כניסה בוקטור 82`,
        "119": `חסרה כניסה בוקטור 83`,
        "120": `חסרה כניסה בוקטור 90`,
        "121": `חסרה כניסה בוקטור 91`,
        "122": `חסרה כניסה בוקטור 92`,
        "123": `חסרה כניסה בוקטור 93`,
        "141": `חסרה כניסה מתאימה בקובץ פרמטרים 3.2`,
        "142": `חסרה כניסה מתאימה בקובץ פרמטרים 3.3`,
        "143": `חסרה כניסה בקובץ תחומי מועדון 3.6.1`,
        "144": `חסרה כניסה בקובץ תחומי מועדון 3.6.3`,
        "145": `חסרה כניסה בקובץ תחומי מועדון 3.6.4`,
        "146": `חסרה כניסה בקובץ תקרות לכרטיסי PL 4.1`,
        "147": `חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 0 4.2`,
        "148": `חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 1 4.3`,
        "149": `חסרה כניסה בקובץ תקרות לכרטיסי תייר 4.4`,
        "150": `חסרה כניסה בקובץ כרטיסים תקפים - ישראכרט`,
        "151": `חסרה כניסה בקובץ כרטיסים תקפים - כאל`,
        "152": `חסרה כניסה בקובץ כרטיסים תקפים - מנפיק עתידי`,
        "182": `שגיאה בערכי וקטור 4`,
        "183": `שגיאה בערכי וקטור 6/12`,
        "186": `שגיאה בערכי וקטור 18`,
        "187": `שגיאה בערכי וקטור 34`,
        "188": `שגיאה בערכי וקטור 64`,
        "190": `שגיאה בערכי וקטור 90`,
        "191": `נתונים לא תקינים בוקטור הרשאות מנפיק`,
        "192": `נתונים לא ולידים בסט הפרמטרים`,
        "193": `נתונים לא ולידים בקובץ פרמטרים ברמת מסוף`,
        "300": `אין הרשאה לסוג עסקה - הרשאת סולק`,
        "301": `אין הרשאה למטבע - הרשאת סולק`,
        "303": `אין הרשאת סולק לביצוע עסקה כאשר הכרטיס לא נוכח`,
        "304": `אין הרשאה לאשראי - הרשאת סולק`,
        "308": `אין הרשאה להצמדה - הרשאת סולק`,
        "309": `אין הרשאת סולק לאשראי במועד קבוע`,
        "310": `אין הרשאה להקלדת מספר אישור מראש`,
        "311": `אין הרשאה לבצע עסקאות לקוד שרות 587`,
        "312": `אין הרשאת סולק לאשראי דחוי`,
        "313": `אין הרשאת סולק להטבות`,
        "314": `אין הרשאת סולק למבצעים`,
        "315": `אין הרשאת סולק לקוד מבצע ספציפי`,
        "316": `אין הרשאת סולק לעסקת טעינה`,
        "317": `אין הרשאת סולק לטעינה / פריקה בקוד אמצעי התשלום בשילוב קוד מטבע`,
        "318": `אין הרשאת סולק למטבע בסוג אשראי זה`,
        "319": `אין הרשאת סולק לטיפ`,
        "341": `אין הרשאה לעסקה - הרשאת מנפיק`,
        "342": `אין הרשאה למטבע - הרשאת מנפיק`,
        "343": `אין הרשאת מנפיק לביצוע עסקה כאשר הכרטיס לא נוכח`,
        "344": `אין הרשאה לאשראי - הרשאת מנפיק`,
        "348": `"אין הרשאה לביצוע אישור בקשה יזומה ע"י קמעונאי""ין הרשאה "`,
        "349": `אין הרשאה מתאימה לביצוע בקשה לאישור ללא עסקה J5`,
        "350": `אין הרשאת מנפיק להטבות`,
        "351": `אין הרשאת מנפיק לאשראי דחוי`,
        "352": `אין הרשאת מנפיק לעסקת טעינה`,
        "353": `אין הרשאת מנפיק לטעינה / פריקה בקוד אמצעי התשלום`,
        "354": `אין הרשאת מנפיק למטבע בסוג אשראי זה`,
        "381": `אין הרשאה לבצע עסקת contactless מעל סכום מרבי`,
        "382": `במסוף המוגדר כשרות עצמי ניתן לבצע רק עסקאות בשירות עצמי`,
        "384": `מסוף מוגדר כרב - ספק /מוטב - חסר מספר ספק / מוטב`,
        "385": `במסוף המוגדר כמסוף סחר אלקטרוני חובה להעביר eci`,
        "401": `מספר התשלומים גדול מערך שדה מספר תשלומים מקסימלי`,
        "402": `מספר התשלומים קטן מערך שדה מספר תשלומים מינימלי`,
        "403": `סכום העסקה קטן מערך שדה סכום מינמלי לתשלום!`,
        "404": `לא הוזן שדה מספר תשלומים`,
        "405": `חסר נתון סכום תשלום ראשון / קבוע`,
        "406": `סה"כ סכום העסקה שונה מסכום תשלום ראשון + סכום תשלום קבוע * מספר תשלומים`,
        "408": `ערוץ 2 קצר מ 37- תווים`,
        "410": `דחיה מסיבת dcode`,
        "414": `בעסקה עם חיוב בתאריך קבוע הוכנס תאריך מאוחר משנה מבצוע העיסקה`,
        "415": `הוזנו נתונים לא תקינים`,
        "416": `תאריך תוקף לא במבנה תקין`,
        "417": `מספר מסוף/קופה אינו תואם למה שמוגדר ב-PinPad`,
        "418": `חסרים פרמטרים חיוניים`,
        "419": `שגיאה בהעברת מאפיין clientInputPan`,
        "420": `מספר כרטיס לא ולידי -במצב של הזנת ערוץ 2 בעסקה ללא כרטיס נוכח`,
        "421": `שגיאה כללית - נתונים לא ולידים`,
        "422": `שגיאה בבנית מסר ISO`,
        "424": `שדה לא נומרי`,
        "425": `רשומה כפולה`,
        "426": `הסכום הוגדל לאחר ביצוע בדיקות אשראית`,
        "428": `חסר קוד שרות בכרטיס`,
        "429": `כרטיס אינו תקף לפי קובץ כרטיסים תקפים`,
        "431": `שגיאה כללית`,
        "432": `אין הראשה להעברת כרטיס דרך קורא מגנטי`,
        "433": `חיוב להעביר ב - PinPad`,
        "434": `אסור להעביר כרטיס במכשיר ה- PinPad`,
        "435": `המכשיר לא מוגדר להעברת כרטיס מגנטי CTL`,
        "436": `המכשיר לא מוגדר להעברת כרטיס EMV CTL`,
        "439": `אין הרשאה לסוג אשראי לפי סוג עסקה`,
        "440": `כרטיס תייר אינו מורשה לסוג אשראי זה`,
        "441": `אין הרשאה לביצוע סוג עסקה - כרטיס קיים בוקטור 80`,
        "442": `אין לבצע in-Stand לאימות אישור לסולק זה`,
        "443": `לא ניתן לבצע עסקת ביטול - כרטיס לא נמצא בקובץ תנועות הקיים במסוף`,
        "445": `בכרטיס חיוב מיידי ניתן לבצע אשראי חיוב מיידי בלבד`,
        "447": `מספר כרטיס שגוי`,
        "448": `חיוב להקליד כתובת לקוח (מיקוד, מספר בית ועיר)`,
        "449": `חיוב להקליד מיקוד`,
        "450": `קוד מבצע מחוץ לתחום, צ"ל בתחום 1-12`,
        "451": `שגיאה במהלך בנית רשומת עסקה`,
        "452": `בעסקת טעינה/פריקה/בירור יתרה חיוב להזין שדה קוד אמצעי תשלום`,
        "453": `אין אפשרות לבטל עסקת פריקה 7.9.3`,
        "455": `לא ניתן לבצע עסקת חיוב מאולצת כאשר נדרשת בקשה לאישור (למעט תקרות)`,
        "456": `כרטיס נמצא בקובץ תנועות עם קוד תשובה 'החרם כרטיס'`,
        "457": `בכרטיס חיוב מיידי מותרת עסקת חיוב רגילה / זיכוי / ביטול`,
        "458": `קוד מועדון לא בתחום`,
        "472": `בעסקת חיוב עם מזומן חיוב להזין סכום במזומן`,
        "473": `בעסקת חיוב עם מזומן סכום המזומן צריך להיות קטן מסכום העסקה`,
        "474": `עסקת איתחול בהוראת קבע מחייבת פרמטר J5`,
        "475": `"עסקת ה"ק מחייבת אחד מהשדות: מספר תשלומים או סכום כולל""סקת ה"ק מחייבת אחד מהשדות: מספר תשלומים או סכו""קת ה"ק מחייבת אחד מהשדות: מספר תשלומים א""ת ה"ק מחייבת אחד מהשדות: מספר תשלומ"" ה"ק מחייבת אחד מהשדות: מספר תש""ה"ק מחייבת אחד מהשדות: מספר """ק מחייבת אחד מהשדות: מספר""ק מחייבת אחד מהשדות: מספר"`,
        "476": `עסקת תורן בהוראת קבע מחייבת שדה מספר תשלום`,
        "477": `עסקת תורן בהוראת קבע מחייבת מספר מזהה של עסקת איתחול`,
        "478": `עסקת תורן בהוראת קבע מחייבת מספר אישור של עסקת איתחול`,
        "479": `עסקת תורן בהוראת קבע מחייבת שדות תאריך וזמן עסקת איתחול`,
        "480": `חסר שדה מאשר עסקת מקור`,
        "481": `חסר שדה מספר יחידות כאשר העסקה מתבצעת בקוד אמצעי תשלום השונה ממטבע`,
        "482": `בכרטיס נטען מותרת עסקת חיוב רגילה / זיכוי / ביטול / פריקה / טעינה / בירור יתרה`,
        "483": `עסקה עם כרטיס דלק במסוף דלק חיוב להזין מספר רכב`,
        "484": `"מספר רכב המוקלד שונה ממספר הרכב הצרוב ע"ג הפס המגנטי / מספר בנק שונה מ 912 /ספרות שמאליות""ספר רכב המוקלד שונה ממספר הרכב הצרוב ע"ג הפס המגנ""פר רכב המו"`,
        "485": `"מספר רכב קצר מ- 6 ספרות/שונה ממספר הרכב המופיע ע"ג ערוץ 2) פוזיציה 34 בערוץ 2 (כרטיס מאפיין)""ספר רכב קצר מ- 6 ספרות/שונה ממספר הרכב המופ"`,
        "486": `ישנה חובת הקלדת קריאת מונה (פוזיציה 30) בערוץ 2 כרטיס מאפיין דלק של לאומי קארד`,
        "487": `רק במסוף המוגדר כדלק דו שלבי ניתן להשתמש בעדכון אובליגו`,
        "489": `בכרטיס דלקן מותרת עסקת חיוב רגילה בלבד (עסקת ביטול אסורה)`,
        "490": `בכרטיסי דלק/דלקן/דלק מועדון ניתן לבצע עסקאות רק במסופי דלק`,
        "491": `עסקה הכוללת המרה חייבת להכיל את כל השדות conversion_amount_06, conversion_rate_09, conversion_currency_51`,
        "492": `אין המרה על עסקאות שקל/דולר`,
        "493": `בעסקה הכוללת הטבה חיוב שיהיו רק אחד מהשדות הבאים : סכום הנחה / מספר יחידות/ % ההנחה`,
        "494": `מספר מסוף שונה`,
        "495": `אין הרשאת fallback`,
        "496": `לא ניתן להצמיד אשראי השונה מאשראי קרדיט / תשלומים`,
        "497": `לא ניתן להצמיד לדולר/מדד במטבע השונה משקל`,
        "498": `"כרטיס ישראכרט מקומי הספרטור צ "ל בפוזיציה 18""רטיס ישראכרט "`,
        "500": `העסקה הופסקה ע"י המשתמש`,
        "504": `חוסר התאמה בין שדה מקור נתוני הכרטיס לשדה מספר כרטיס`,
        "505": `ערך לא חוקי בשדה סוג עסקה`,
        "506": `ערך לא חוקי בשדה eci`,
        "507": `סכום העסקה בפועל גבוה מהסכום המאושר`,
        "512": `לא ניתן להכניס אישור שהתקבל ממענה קולי לעסקה זו`,
        "551": `מסר תשובה אינו מתאים למסר הבקשה`,
        "552": `שגיאה בשדה 55`,
        "553": `התקבלה שגיאה מהטנדם`,
        "554": `במסר התשובה חסר שדה mcc_18`,
        "555": `במסר התשובה חסר שדה response_code_25`,
        "556": `במסר התשובה חסר שדה rrn_37`,
        "557": `במסר התשובה חסר שדה comp_retailer_num_42`,
        "558": `במסר התשובה חסר שדה auth_code_43`,
        "559": `במסר התשובה חסר שדה f39_response_39`,
        "560": `במסר התשובה חסר שדה authorization_no_38`,
        "561": `במסר התשובה חסר / ריק שדה additional_data_48.solek_auth_no`,
        "562": `במסר התשובה חסר אחד מהשדות conversion_amount_06, conversion_rate_09, conversion_currency_51`,
        "563": `ערך השדה אינו מתאים למספרי האישור שהתקבלו auth_code_43`,
        "564": `במסר התשובה חסר/ריק שדה additional_amounts54.cashback_amount`,
        "565": `אי-התאמה בין שדה 25 לשדה 43`,
        "566": `במסוף המוגדר כתומך בדלק דו-שלבי יש חובה להחזיר שדות 90,116`,
        "567": `שדות 25,127 לא תקינים במסר עידכון אובליגו במסוף המוגדר כדלק דו-שלבי`,
        "700": `עסקה נדחתה ע"י מכשיר PinPad`,
        "701": `שגיאה במכשיר pinpad`,
        "702": `יציאת com לא תקינה`,
        "703": `PINPAD_TransactionError`,
        "704": `PINPAD_TransactionCancelled`,
        "705": `הלקוח לחץ על כפתור ביטול במסופון`,
        "706": `תם הזמן הפעולה בוטלה`,
        "707": `הכרטיס החכם הוצא לפני סוף העסקה`,
        "708": `PINPAD_UserRetriesExceeded`,
        "709": `PINPAD_PINPadTimeout`,
        "710": `PINPAD_PINPadCommsError`,
        "711": `PINPAD_PINPadMessageError`,
        "712": `PINPAD_PINPadNotInitialized`,
        "713": `PINPAD_PINPadCardReadError`,
        "714": `PINPAD_ReaderTimeout`,
        "715": `PINPAD_ReaderCommsError`,
        "716": `PINPAD_ReaderMessageError`,
        "717": `PINPAD_HostMessageError`,
        "718": `PINPAD_HostConfigError`,
        "719": `PINPAD_HostKeyError`,
        "720": `PINPAD_HostConnectError`,
        "721": `PINPAD_HostTransmitError`,
        "722": `PINPAD_HostReceiveError`,
        "723": `PINPAD_HostTimeout`,
        "724": `PINVerificationNotSupportedByCard`,
        "725": `PINVerificationFailed`,
        "726": `שגיאה בקליטת קובץ config.xml`,
        "730": `מכשיר אישר עסקה בניגוד להחלטת אשראית`,
        "731": `כרטיס לא הוכנס`,
    },
}