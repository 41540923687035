module PositiveTS {
  export module Service {
    export enum ValuCardActionTypes { LOAD_CARD = 1, PAY_WITH_CARD = 2, CANCEL_LOAD_CARD = 3, CANCEL_PAY_WITH_CARD = 4, RESET_BALANCE = 5};
    
    export const VALU_CARD_VOUCHER_TYPE_ID = "501";

    const responseTrxKey = "transaction_id"

    const valuLogLey =  "CHARGEABLE_CARD_TRX_IDS";
    var valuCardTrxAry:number[] = []

    export function valuCardSetSaleTrxKey(sale:PositiveTS.Storage.Entity.Sale, doSaleUpdated = true){
      if (valuCardTrxAry.length > 0){
        var jsonData = JSON.parse(sale.jsondata)
        jsonData[valuLogLey] = jsonData[valuLogLey] || [];
        jsonData[valuLogLey] = jsonData[valuLogLey].concat( valuCardTrxAry )
        sale.jsondata = JSON.stringify(jsonData)
        valuCardTrxAry = [];
        if (doSaleUpdated){
          posVC.saleUpdated();
        }
        
      }

      
    }

    export class Valu extends SmartVoucher {



      getBalance(cardNumber: string): Promise<GetBalanceResponse> {
        return new Promise<GetBalanceResponse>((resolve, reject) => {
          fetch(`/chargable_cards/${cardNumber}`, {
            headers: { 'Authorization': `Token token=${session.pos.access_token}` }
          })
            .then((response) => {
            return response.json()
          })
            .then((json) => {
            resolve({
              success: !Boolean(json.error),
              balance: json.current_balance,
              error: json.error
            })
          })
            .catch((error) => {
            console.error(error);
            resolve({
              success: false,
              balance: 0,
              error: "שגיאת רשת - אנא פנה לתמיכה"
            })
          })
        }
          )
      }


      getTransactions(cardNumber: string, fromDate, toDate): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          let url = `/chargable_card_transactions/${cardNumber}/?from_date=${encodeURIComponent(fromDate)}&to_date=${encodeURIComponent(toDate)}`
          
          fetch(url, {
            headers: { 'Authorization': `Token token=${session.pos.access_token}` }
          })
            .then((response) => {
            return response.json()
          })
            .then((json) => {
            resolve({
              success: !Boolean(json.error),
              transactions: json.transactions,
              error: json.error
            })
          })
            .catch((error) => {
            console.error(error);
            resolve({
              success: false,
              transactions: 0,
              error: "שגיאת רשת - אנא פנה לתמיכה"
            })
          })
        }
          )
      }

      pay(voucherData: any, amount: number): Promise<any> {
        return new Promise((resolve, reject) => {
 
          app.showLoadingMessage(i18next.t("voucherPaymentDebitPraxellLoadingMsg"));

          fetch(`/chargable_cards/${voucherData.barCode}`, {
            headers: { 'Authorization': `Token token=${session.pos.access_token}` }
          })
            .then((response) => {
            return response.json()
          })
          .then(response =>{
            var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
            if (response.is_tamash){
              if (amount < saleTotals.totalAmount){
                 throw new Error( i18next.t('tamashValuCardOtherPaymentAmountExists'))
              }

              return new PositiveTS.Storage.Entity.Voucher().promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID).
              then((result)=>{
                if  (result.filter((row)=>{return row.typeID == VALU_CARD_VOUCHER_TYPE_ID}).length == 0) {
                  throw new Error( i18next.t('tamashValuVoucher501NotDefined'))
                }
               })
            } 

          })
          .then( ()=>{
            return fetch(`/chargable_cards/${voucherData.barCode}`, {
              method: 'post',
              headers: {
                'Authorization': `Token token=${session.pos.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: this.createRequestData(voucherData, ValuCardActionTypes.PAY_WITH_CARD)
            })
          })
            .then((response) => {
            return response.json()
          })
            .then((json) => {
            app.hideLoadingMessage();
            let success = !Boolean(json.error)
            if (success) {
              this.appendTrxKeyToValuAry(json[responseTrxKey])
              voucherData.response = JSON.stringify(json);
              voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU;
              voucherData.isTamashCustomer = json.card.is_tamash;
              voucherData.voucher_type_id = voucherData.isTamashCustomer ? VALU_CARD_VOUCHER_TYPE_ID : voucherData.voucher_type_id
              resolve(voucherData);
            }
            else {
              reject(json.error)
            }
          }).catch((e:Error) => {
            app.hideLoadingMessage();

            if (posUtils.isString(e)) {
              reject(e);
            } else if (e.message){
              reject(e.message);
            } else {
              reject("שגיאה כללית אנא פנה לתמיכה");
            }
          });
        })
      }

      cancelPayment(voucherToCancel: any, doRemove = false): Promise<ActionResponse> {
        return new Promise<ActionResponse>((resolve, reject) => {

          fetch(`/chargable_cards/${voucherToCancel.barCode}`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: this.createRequestData(voucherToCancel, ValuCardActionTypes.CANCEL_PAY_WITH_CARD, doRemove)
          })
            .then((response) => {
            return response.json()
          })
            .then((json) => {
            let success = !Boolean(json.error)
            if (success && !doRemove) {
              this.appendTrxKeyToValuAry(json[responseTrxKey])
            }

            resolve({
              success: !Boolean(json.error),
              error: json.error
            })
          })
            .catch((e) => {
            resolve({ success: false, error: "שגיאה כללית אנא פנה לתמיכה" });
          })
        })
      }

      cancelLoad(paymentToCancel): Promise<any> {
        var reversedPayment, currentPayment;
        return new Promise((resolve, reject) => {

          fetch(`/chargable_cards/${paymentToCancel[0].card.number}`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: this.createRequestData({
              barCode: paymentToCancel[0].card.number,
              valuTypeId: paymentToCancel[0].card.chargable_card_type_id,
              amount: paymentToCancel[0].amount
            }, ValuCardActionTypes.CANCEL_LOAD_CARD)
          })
            .then((response) => {
            return response.json()
          })
            .then((json) => {

              let success = !Boolean(json.error)
              if (success) {
                this.appendTrxKeyToValuAry(json[responseTrxKey])
                currentPayment = new PositiveTS.Storage.Entity.SalePayment();
                currentPayment.amount = 0;
                currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VALU;
                paymentToCancel[0].amount = Math.abs( paymentToCancel[0].amount ) * -1;
                currentPayment.data = JSON.stringify(paymentToCancel);
                resolve(currentPayment)
              } else{
              reject(json.error)
            }
  
          })
        })
      }

      async getCardNumber():Promise<GetCardNumberResponse> {
        try {
          let selectedValuCardId;
          const valuCards = await this.getValuTypes();
          selectedValuCardId = await this.selectVauCardType(valuCards);
          const cardNumber:string = await this.getInputCardScan();
          return {
            cardNumber: cardNumber,
            options: { selectedValuCardId: selectedValuCardId }
          };
        } catch (err) {
          throw err;
        }
      }

      loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
        var timeStamp = (new Date()).getTime();
        return new Promise<SmartVoucherPaymentData>((resolve, reject) => {
          if ( !Boolean(cardNumber)){
            reject(i18next.t('mustInsertCardNumber'));
            return;
          }
          fetch(`/chargable_cards/${cardNumber}`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: this.createRequestData(
              { barCode: cardNumber, valuTypeId: options.selectedValuCardId, amount: amount, timestamp: timeStamp },
              ValuCardActionTypes.LOAD_CARD)
          })
            .then((response) => {
            return response.json()
          })
            .then(json => {

              let success = !Boolean(json.error)
              if (success) {
                this.appendTrxKeyToValuAry(json[responseTrxKey])
                json.actionType = ValuCardActionTypes.LOAD_CARD;
                json.amount = amount;
                json.timeStamp = timeStamp;
                resolve({
                  amount: 0,
                  method: PositiveTS.Storage.Entity.SalePayment.METHOD_VALU,
                  data: JSON.stringify([json])
                })
              } else {
                reject(json.error)
              }

          })
          .catch( e =>{
            reject(e.message);
          })
        })
      }


      private appendTrxKeyToValuAry(trxKey:number){
        valuCardTrxAry.push(trxKey)
      }

      private createRequestData(voucher, action: ValuCardActionTypes, doRemove = false): string {
        let cardNumber = Number(voucher.barCode);
        let timestamp = (new Date()).getTime();
        let dataDict = {
          card_type_id: voucher.valuTypeId,
          action_type: action,
          amount: Math.abs(voucher.amount),
          timestamp: timestamp,
          invoice_number: -1,
          do_remove: doRemove,
          transaction_id: null,
        };

        if (voucher.response ) {
          dataDict.transaction_id = JSON.parse( voucher.response ).transaction_id
        }

        //this double json.stringify BS is on purpose to retain backward compatibility
        return JSON.stringify({ data: JSON.stringify(dataDict) })
      }

      private getValuTypes(): Promise<any> {
        let strSql = 'select valuTypeId, name from voucher where smartVoucherType = \'' + SmartVoucher.VALU_HANDLER + '\'' + ' and isChargeable = 1';
        return new Promise((resolve, reject) => {
          PositiveTS.Service.WasmDB.promiseSql(strSql)
            .then(function(response) {
            if (response.length === 0) {
              reject(new Error('No Valu Cards defined in Voucher table'));
            }
            var _return = [];

            for (var i = 0; i < response.length; i++) {
              _return.push({
                code: response[i].valuTypeId,
                description: response[i].name
              });
            }

            resolve(_return);
          });
        })
      }

      private selectVauCardType(valuCards: Array<any>) {
        return new Promise(function(resolve) {
          PositiveTS.Service.ChargeableCardSelectDialog.open(valuCards,
            function(result) {
              resolve(result);
            });
        });
      }

      private getInputCardScan():Promise<string> {
        if (Pinia.globalStore.simpleSelfService) {
          return new Promise(async (resolve,reject) => {
            app.showLoadingMessage("העבר כרטיס");

            try {
              let cardNumber = await Service.EMV.swipe(null, Pinia.globalStore.simpleSelfService);
              app.hideLoadingMessage();  
              return resolve(cardNumber as string);  
            } catch(err) {
              return reject(err);
            }
          })
        }

        let options = new PositiveTS.Dialogs.InputDialogOptions()
        options.header = 'הזן כרטיס לטעינה'
        options.description = 'סרוק כרטיס לטעינה'
        options.inputPlaceHolder = 'סרוק כרטיס לטעינה'
        options.showCancelButton = false
        options.emptyErrorMessage = 'סרוק כרטיס לטעינה'
        options.showSwipeCard = session.pos.isEmv
        options.inputValidator = function(value) {
          if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
            return !posUtils.isNullOrUndefinedOrEmptyString(value);
          }
          
          return true;
        }
        return inputDg.open(options)
      };
    }
  }
}
