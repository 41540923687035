module PositiveTS {
  export module Components {
      export module ItemManage {
  
        export const route = "/item-manage";
        type CompType = any;
        
        export function getComponent() {
          
          return {
            template: JST.ItemManage(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            watch : {

              itemSearch () {
                this.searchDebounce()
              }
              
            },
            
            created () {
              this.searchDebounce = _.debounce(this.filterItems, 100);
            },

            methods: {
              async barcodeExists(barcode:string,otherBarcodes:Array<string>):Promise<boolean> {
                let mapBarcodes = session.allItemBarcodes;
                if (posUtils.isNullOrUndefinedOrEmptyString(barcode)) {
                  return false;
                }
                if(mapBarcodes.get(barcode) != null){
                  return true;
                }
                if(otherBarcodes.filter(res=> res == barcode).length > 0){
                  return true;
                }
                
                return false;
              },
              isAndroid: function(){
                return session.isAndroid;
              },
              usingNewPromoSystem: function(){
                return session.pos.useNewPromotions
              },
              isExportImportAvailable(){
                return session.isAndroid && Android.LoadItemsFromDOK !== undefined;
              },
              addNewItem() {
                VueServices.Router.goto(AddOrEditItem.route);
              },
              promotions(){
                VueServices.Router.goto(PromotionManage.route);
              },
              openDepartments() {
                VueServices.Router.goto(Departments.route);
              },
              editItem(item) {
                if(item.code == Shared.Constants.Item.GENERIC_ITEM){
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('itemManage.cannotEditGenericItem'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                  return;
                }
                else{
                  VueServices.Router.goto(AddOrEditItem.route,{code: item.code});
                }
              },
              depName(item:Storage.Entity.Item) {
                if (item.departmentId > 0) {
                  return this.departmentsMap.get(item.departmentId).name;
                }
                else {
                  return i18next.t("itemManage.withoutDepartment");
                }
              },
              async filterItems(initPage = true) {

                if (initPage) {
                  this.page = 0;
                }
                let isActive = (this.btns.filter( row =>{return row.isSelected})[0].id == "active")
                
                this.filteredItems = _.sortBy(this.items.filter(item => {
                  if (posUtils.isDefined(this.itemDepartment) && this.itemDepartment.id != item.departmentId) {
                    return false;
                  }

                  return Boolean(item.locked) == !isActive && 
                  (item.description.includes(this.itemSearch) || (item.barcode && item.barcode.includes(this.itemSearch))) 
                }),'description')
                
                this.totalItems = this.filteredItems.length;
                this.filteredItems = this.filteredItems.slice(this.page*this.itemsPerPage,this.page*this.itemsPerPage+this.itemsPerPage)
              },
              back() {
                pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null);
              },
              async importItems(items) {
                app.showLoadingMessage(i18next.t("itemManage.importingFileAndDep"))
                let localItems = await appDB.localItems.toArray();
                let lastItemCode = 10000;
                for(let item of localItems){
                  if(Number(item.code) > lastItemCode){
                    lastItemCode = Number(item.code);
                  }
                }
                lastItemCode++;
                  let rowNum = 1;
                  let barcodesFromLoadedItems = [];
                  for(let item of items){
                    if(!posUtils.isNullOrUndefinedOrEmptyString(item.barcode)){
                      barcodesFromLoadedItems.push(item.barcode); 
                    }
                  }
                    for(let item of items){
                      rowNum++;
                      try{
                        let newItem = new Storage.Entity.Item();
                        newItem.importFromObject(item);
                        newItem.code = String(lastItemCode);
                        let barcodeExists = await this.barcodeExists(item.barcode,barcodesFromLoadedItems);
                        if(barcodeExists){
                          if(!posUtils.isNullOrUndefinedOrEmptyString(newItem.barcode)){
                            newItem.barcode = newItem.code + "-" + newItem.barcode;
                          }
                        }
                        newItem.logicalOrGroupPrinters = "[]";
                        newItem.discounts = "1&2&3&4&5&6&7&8&9&10";
                        newItem.timestamp = new Date().getTime();
                        newItem.allowZeroPrice = true;
    
                        let tempItem = _.cloneDeep(newItem);
                        tempItem.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                        if (tempItem._data) {
                          tempItem._data.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                        }
                        let res = await Storage.Entity.Item.saveToDB(tempItem, true);
                        lastItemCode++;
                      }
                      catch{
                          this.failedRows.push(rowNum)
                      }
                    }
                    await Storage.Entity.IDBEntity.updateCaches();
                    this.items = await appDB.localItems.toCollection().sortBy('description');
                    this.filterItems();
                    
                    setTimeout(async function(){
                      app.hideLoadingMessage();
                    },0)
              },
              async createDepartmentFromItem(depName){
                if (depName == i18next.t("itemManage.withoutDepartment") || posUtils.isNullOrUndefinedOrEmptyString(depName)){
                  return -1;
                }
                else{
                  let allDeps = await appDB.departments.toArray();
                  let foundDep = allDeps.filter(dep => dep.name == depName)[0];

                  if(foundDep){
                    return foundDep.id;
                  }else{
                    let newDep = new Storage.Entity.Department("");
                    newDep.name = depName;
                    newDep.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                    await appDB.departments.put(newDep);
                    let lastRecord = await appDB.departments.orderBy('id').last();
                    return lastRecord.id;
                  }
                }
                
              },
              nextPage() {
                if (((this.page+1)*this.itemsPerPage) < this.totalItems) {
                  this.page += 1;
                  this.filterItems(false);
                }
              },
              prevPage() {
                if (this.page >= 1) {
                  this.page -= 1;
                  this.filterItems(false);
                }
              },
              async importFromFile(){
                let ans = await app.promiseShowAlert({
                  header: i18next.t("itemManage.importFromFile"),
                  content: i18next.t("itemManage.loadingFileConfirmation"),
                  continueButtonText: i18next.t("ok")
                });
                
                if(ans == "cancel"){
                  return;
                }

                app.showLoadingMessage(i18next.t("itemManage.loadingFile"))
                let result = Android.LoadItemsFromDOK(session.pos.deviceID,"items.csv"); 
                if(result.includes("שגיאה")){
                  app.hideLoadingMessage();
                  app.showAlert({
                    header: i18next.t("error"),
                    content: result,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                }else{
                  let items = [];
                  let stringRows = result.split("\r\n");
                  stringRows = stringRows.splice(1);
                  for(let row of stringRows){
                    if(row.includes(`,`)){
                      let splitRow = row.split(',');
                        let newItem = {
                          description: splitRow[0],
                          departmentId: await this.createDepartmentFromItem(splitRow[1]),
                          serverDepartmentId: -1,
                          barcode: posUtils.isNullOrUndefinedOrEmptyString(splitRow[2])? null : splitRow[2],
                          priceZarhan: splitRow[3],
                          currentInventory: splitRow[4],
                          hasWeight: splitRow[5].toLowerCase() == 'true',
                          noVat: splitRow[6].toLowerCase() == 'true',
                          locked: splitRow[7].toLowerCase() == 'true',
                          isAllowNameChange: splitRow[8].toLowerCase() == 'true'
                        };

                        items.push(newItem)
                    }
                  }
                  this.departments = await appDB.departments.toArray();
                  let departmentsMap = new Map();
                  for (let dep of this.departments) {
                    departmentsMap.set(dep.id,dep);
                  }
                  this.departmentsMap = departmentsMap;

                  app.hideLoadingMessage();
                  await this.importItems(items);

                  if(this.failedRows.length > 0){
                    let message = "טעינת פריטים נכשלה בשורות:\n";
                    for(let row of this.failedRows){
                      message = message + row + " ";
                    }
                      app.showAlert({
                        header: i18next.t("error"),
                        content: message,
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                      }, null, null);
                    this.failedRows = [];
                  }
                }
              },
              async exportToFile(){
                var universalBOM = "\uFEFF";
                let rows = [];
                let items = await appDB.localItems.toArray();
                rows.push(["שם פריט","שם מחלקה","ברקוד","מחיר צרכן","כמות במלאי","האם שקיל?","האם ללא מעמ?","האם נעול?","אפשר שינוי שם?"]);
                for(let item of items){
                  rows.push([item.description,this.depName(item),item.barcode,item.priceZarhan,item.currentInventory,Boolean(item.hasWeight),Boolean(item.noVat),Boolean(item.locked),Boolean(item.isAllowNameChange)])
                }
                let itemsContent = "";
                rows.forEach(function(rowArray){
                  let row = rowArray.join(",");
                  itemsContent += row + "\r\n";
                }); 

                let csvContent = universalBOM+itemsContent;
                
                let result = Android.BackupItemsToDOK(session.pos.deviceID,csvContent,"items.csv");
                let resultContent = result;
                if(result == "success"){
                  resultContent = i18next.t("itemManage.exportSuccess");
                }

                app.showAlert({
                  header: i18next.t("itemManage.exportTitle"),
                  content: resultContent,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              }
            
            },
            data: function():{
              items:Array<Storage.Entity.Item>,
              departments:Array<Storage.Entity.Department>,
              filteredItems:Array<Storage.Entity.Item>,
              departmentsMap: Map<number,Storage.Entity.Department>,
              loading:boolean,
              page:number,
              itemsPerPage:number,
              totalItems:number,
              itemSearch:string,
              btns: PositiveTS.Components.PositiveRadio.Btn[],
              failedRows: Array<Number>;
              itemDepartment: any

            } 
            {
              return {
                items: [],
                departments:[],
                filteredItems: [],
                departmentsMap: new Map(),
                loading: true,
                itemsPerPage: 25,
                page: 0,
                totalItems: 0,
                itemSearch: "",
                btns: [
                  new PositiveTS.Components.PositiveRadio.Btn("פעילים","active",true),
                  new PositiveTS.Components.PositiveRadio.Btn("נעולים","locked",false),
                ],
                failedRows: [],
                itemDepartment: null
              }
            },
            computed: {
              departmentsToChoose() {

                if (!this.departments) {
                  return [];
                }

                return [{name: i18next.t("itemManage.withoutDepartment"), id: -1}, ...this.departments]
              },
              mobileLayout(){
                return Pinia.globalStore.mobileLayout
              },
            },
            beforeRouteEnter: async function(to, from, next) {
              app.showLoadingMessage(i18next.t('loading'));
              let items = await appDB.localItems.toCollection().sortBy('description');
              let departments = await appDB.departments.toArray();
              let departmentsMap = new Map();
              for (let dep of departments) {
                departmentsMap.set(dep.id,dep);
              }
              next(vm => {
                let self = vm as CompType;
                self.items = items;
                self.totalItems = items.length;
                self.departments = departments;
                self.departmentsMap = departmentsMap;
                self.filterItems();
                app.hideLoadingMessage();
              });
            },
            mounted: function() { //these 3 methods are kept for reference so I don't have to google this shit every time
              (<any>this.$refs.isActiveRadio).btns = this.btns;
            },
          }
          
        }
        
      }
    }
  }
  