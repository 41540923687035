module PositiveTS {
export module Storage {
export module Entity {

export class Promotion extends WasmEntity{
	code:string
	name:string
	template:string
	fromDate:string
	toDate:string
	isActive:boolean
	allowAccumulationPointsAnyway:boolean
	isClubMembersOnly:boolean
	clubMemberPromotionType:number
	discountType:string
	discountValue:number
	discountPercent1:number
	discountPercent2:number
	discountPercent3:number
	discountPercent4:number
	minimumBuyAmount:number
	minimumBuyQuantity:number
	allowMultipleTimesSameSale:boolean
	isAutomatic:boolean
	allowWithOtherPromotions:boolean
	hasBarcode:boolean
	maxQuantityToGiveCustomer:number
	countByRows:boolean
	customerGroupId:number
	priority:number
	isHappyHour:boolean
	happyHourDays:string
	happyHourStartingMinutes:number
	happyHourStartingHour:number
	happyHourEndingMinutes:number
	happyHourEndingHour:number
	start_hour:number
	start_minute:number
	end_hour:number
	end_minute:number
	quantitySal2:number
	quantitySal3:number
	quantitySal4:number
	dependencies:string
	externalCode:string
	promotionAsDiscount:boolean
	message:string
	promotionBarcode:any //not persisted to DB
	private _dependantCodes:Array<string> //not persisted to DB
	discountGroup:Promotions.PromoGroup //not persisted to DB - for use with discounts
	paymentMethods: string
	itemGroupIds: string

	//roshemet fields
	public syncStatus: number
	public data: string
	public serverID: number

	get dependantCodes():Array<string> {
		if (this._dependantCodes == null) {
			this._dependantCodes = posUtils.isBlank(this.dependencies) ? [] : this.dependencies.split("&");
		}
		return this._dependantCodes;
	}

	set dependantCodes(value:Array<string>) {
		this._dependantCodes = value;
	}

	get discountTypeForSort():number {
		switch (this.discountType) {
			case "Fix":
				return 3;
			case "Amount":
				return 1;
			case "Percent":
				return 2;
			default:
				return 0; //We are not supposed to get here!
		}
	}

	get paymentMethodsArray (){
		let json = []
		
		if (!posUtils.isBlank(this.paymentMethods)){
			let pm = this.paymentMethods.replace(new RegExp("'", 'g'), "\"")
			try {
				json = JSON.parse(pm)
			}catch (e){
				console.error(e)
			}
		}

		return json
	}

	get itemGroupIdsArray (): Array<number> {
		if (posUtils.isPresent(this.itemGroupIds)){
			let itemGroupIds = this.itemGroupIds.split('&');
			return itemGroupIds.map(itemGroupId => Number.parseInt(itemGroupId));
		}

		return [];
	}

	constructor() {
		let meta = {
			name: 'Promotion',
			fields: {
				code: "TEXT",
				name: "TEXT",
				template: "TEXT",
				fromDate: "TEXT",
				toDate: "TEXT",
				isActive: "BOOL",
				allowAccumulationPointsAnyway: "BOOL",
				isClubMembersOnly: "BOOL",
				clubMemberPromotionType: "FLOAT",
				discountType: "TEXT",
				discountValue: "FLOAT",
				discountPercent1: "FLOAT",
				discountPercent2: "FLOAT",
				discountPercent3: "FLOAT",
				discountPercent4: "FLOAT",
				minimumBuyAmount: "FLOAT",
				minimumBuyQuantity: "FLOAT",
				allowMultipleTimesSameSale: "BOOL",
				isAutomatic: "BOOL",
				allowWithOtherPromotions: "BOOL",
				hasBarcode: "BOOL",
				maxQuantityToGiveCustomer: "FLOAT",
				countByRows: "BOOL",
				customerGroupId: "INT",
				priority: "INT",
				start_hour: "INT",
				start_minute: "INT",
				isHappyHour: "BOOL",
				happyHourDays: "TEXT",
				happyHourStartingMinutes: "INT",
				happyHourStartingHour: "INT",
				happyHourEndingMinutes: "INT",
				happyHourEndingHour: "INT",
				end_hour: "INT",
				end_minute: "INT",
				quantitySal2: "INT",
				quantitySal3: "INT",
				quantitySal4: "INT",
				dependencies: "TEXT",
				message: "TEXT",
				externalCode: "TEXT",
				promotionAsDiscount: "BOOL",
				paymentMethods: "TEXT",
				itemGroupIds: "TEXT",
			},
			unique: ['code']
		};

		if (session && session.pos && session.pos.isRoshemet) {
			meta.fields = Object.assign({
				syncStatus: "INT",
				data: "TEXT",
				serverID: "INT"
			},meta.fields)
		}
		super(meta)
	}


	
	static DISCOUNT_TYPE_AMOUNT = 'Amount';
	static DISCOUNT_TYPE_PERCENT = 'Percent';
	static DISCOUNT_TYPE_FIX = 'Fix';
	
	static async getLastCodePlusOne():Promise<string> {
		let promos:Array<Promotion> = await appDB.promotions.toArray();
		let lastCode = _.orderBy(promos,(p:Promotion) => Number(p.code),'desc')
			.map((p:Promotion) => p.code)[0]
		return lastCode ? String(parseInt(lastCode) + 1) : '1';
	}

	static findSalimByPromotion (promotion){

		let data = {
			salim: [
				[{ 
					selectAll: false, //this always false
					manualySelectedRows: [] //array with all items on promoBuy 
				}], //promoBuy
				[{ 
					selectAll: false, //this always false
					manualySelectedRows: [] //array with all items on promoGet
				}] //promoGet 
			]
		} 

		for (let item of session.allItems.values()){

			let promoBuy = item.promoBuy ? item.promoBuy.split('&') : []

			if (promoBuy.includes(promotion.code) && item.promoBuy && item.promoBuy != '9999'){				
				data = Promotion.setItemToData(data, 'buy', item)				
			}

			let promoGet = item.promoGet ? item.promoGet.split('&') : []

			if (promoGet.includes(promotion.code) && item.promoGet && item.promoGet != '9999'){
				data = Promotion.setItemToData(data, 'get', item)
			}
		}

		return data
		
	}

	static setItemToData (data, type: 'buy'| 'get', item: Item) {
		
		let sal = type == 'buy' ? data.salim[0] : data.salim[1]
		
		let itemToStore = {
			id: item.id,
			code: item.code,
			name: item.description
		}

		let itemsRows = sal[0].manualySelectedRows

		let indexOfItem = itemsRows ? itemsRows.findIndex(r => r.id == item.id) : -1

		if (indexOfItem >= 0){
			Object.assign(itemsRows[indexOfItem], itemToStore)
		}else{
			itemsRows.push(itemToStore)
		}

		return data

	}

	defaultVal(field) {
		switch (this.meta.fields[field]) {
			case "TEXT":
				return "";
			case "BOOL":
				return false;
			case "INT":
			case "FLOAT":
				return 0;
			case "JSON":
				return "{}";
			default:
				return undefined;
		}
	}
	
	importFromObject(importFrom) {
		if (session && session.pos && session.pos.isRoshemet) {
			for (let field in this.meta.fields) {
				if (!(field in importFrom)) {
					this[field] = this.defaultVal(field)
				}
				else {
					this[field] = importFrom[field];
				}
			}
			
			return this;
		}
		else {
			return super.importFromObject(importFrom);
		}
	}

	static import(promotion) {
		let promotionModel = new Promotion();
		return promotionModel.importFromObject(promotion);
	}

	static getPromotions(isAutomatic, storeID, isCustomer, codes, customerClubPromotionTypes, customerGroupId, externalPromotionCodes = []):Array<Promotion> {
		return session.allPromotions.filter(promo => {
			let inCode = codes.indexOf(promo.code) > -1;

			if (externalPromotionCodes != null && externalPromotionCodes.length > 0) {
				if (!posUtils.isBlank(promo.externalCode) && externalPromotionCodes.indexOf(promo.externalCode) == -1) {
					return false;
				}
			}
			else {
				if (!posUtils.isBlank(promo.externalCode)) {
					return false;
				}
			}
			
			let customerCondition = Promotion.getCustomerCondition(promo,isCustomer,customerClubPromotionTypes,customerGroupId);

			return inCode && (promo.isAutomatic == isAutomatic) && customerCondition;
		})
	}

	static fetchAutomaticByStoreAndCodes (storeID, isCustomer, codes, customerClubPromotionTypes, customerGroupId, externalPromotionCodes = []):Array<Promotion> {

		return Promotion.getPromotions(true, storeID, isCustomer, codes, customerClubPromotionTypes, customerGroupId, externalPromotionCodes);

	};

	/**
	 * @param {Integer} storeID The store ID.
	 * @param {Array} codes Promotion codes to search for.
	 */
	static fetcManualByStoreAndCodes (storeID, isCustomer, codes, customerClubPromotionTypes, customerGroupId):Array<Promotion> {

		return Promotion.getPromotions(false, storeID, isCustomer, codes, customerClubPromotionTypes, customerGroupId);
	};

	static getCustomerCondition(promo:Promotion, isCustomer, customerClubPromotionTypes, customerGroupId = null) {
		let clubCondition = true;
		if (!isCustomer) {
			clubCondition = (promo.isClubMembersOnly == false)
		}
		else if (isCustomer && (!customerClubPromotionTypes || posUtils.isEmptyArray(customerClubPromotionTypes)) ) {
			clubCondition = (posUtils.isNullOrUndefinedOrEmptyString(promo.clubMemberPromotionType));
		}
		else if (isCustomer && posUtils.isArray(customerClubPromotionTypes) && customerClubPromotionTypes.length > 0) {
			clubCondition = (posUtils.isNullOrUndefinedOrEmptyString(promo.clubMemberPromotionType) || 
							(customerClubPromotionTypes.findIndex((c) => c == promo.clubMemberPromotionType) > -1));

		}

		if (customerGroupId == null || customerGroupId === "") {
			clubCondition = clubCondition && posUtils.isNullOrUndefinedOrEmptyString(promo.customerGroupId);
		}
		else {
			clubCondition = clubCondition && (posUtils.isNullOrUndefinedOrEmptyString(promo.customerGroupId) || 
																				promo.customerGroupId == customerGroupId)
		}
		
		return clubCondition;
	}

	getCustomerString (isCustomer, customerClubPromotionTypes, customerGroupId = null) {
		var customerString = "";
		if (!isCustomer) {
			customerString += "AND isClubMembersOnly = 0";
		}
		else if (isCustomer && (!customerClubPromotionTypes || posUtils.isEmptyArray(customerClubPromotionTypes)) ) {
			customerString += " AND (clubMemberPromotionType is null OR clubMemberPromotionType='')";
		}
		else if (isCustomer && posUtils.isArray(customerClubPromotionTypes) && customerClubPromotionTypes.length > 0) {
			customerString += " AND ((clubMemberPromotionType is null OR clubMemberPromotionType='') OR (clubMemberPromotionType IN " + '(' + String(customerClubPromotionTypes) + ')))';
		}

		if (customerGroupId == null || customerGroupId === "") {
			customerString += ` AND (customerGroupId is null or customerGroupId = "")`;
		}
		else {
			customerString += ` AND (customerGroupId = ${customerGroupId} OR (customerGroupId is null or customerGroupId = ""))`;	
		}
		
		return customerString;
	};

	updatePromotionIfNoVatStore (db) {
		//patch for first POS run
		if (typeof(session.store) === "undefined") {
			return Promise.resolve();
		}

		return PositiveTS.Service.WasmDB.promiseSqlWithDB("select * from store where storeID=" + session.store.storeID, db)
		.then(function(result) {

			if (result.length === 0) {
				return Promise.resolve();
			}
			let store = new Store();
			store.importFromObject(result[0]);
			var containVat = store.containVat;
			if (Boolean(containVat) == true) {
				return PositiveTS.Service.WasmDB.promiseSqlWithDB(`select count(*) from store where containVat = 0 or containVat = 'false'`, db)
				.then(function(result: any[]):any {
					var totalStoresWithVat = result[0]['count(*)'];
					if (totalStoresWithVat > 0) {
						//update the promotion values for no VAT stores.
						let vatPct = (parseFloat(session.systemSettings.vat) + 100)/100;
						let sqlUpdateStr = "update promotion set discountvalue=round(discountvalue/" + vatPct.toString() + ",1) where discounttype='Fix'";						
						if(session.pos && session.pos.isCaveret) {
							sqlUpdateStr = "update promotion set discountvalue=round(discountvalue/" + vatPct.toString() + ",1) where discounttype='Fix' AND template <> -1";													
						}
	          		return Promise.resolve(db.exec(sqlUpdateStr));
					}
					else {
						//all stores don't have VAT (duty free stores) - don't do anything.
						return Promise.resolve();
					}
				})
			}
			else {
				//don't do anything for store that has containVat = 0
				return Promise.resolve();
			}
		});
	}

	static convertPromotionStringToObject(promotionString) {
		let obj = {};

		if (promotionString) {
			let promotions = promotionString.split(';');

			for (let promotionStr of promotions) {
				let keyVal = promotionStr.split('=');

				if (keyVal.length == 2) {
					obj[keyVal[0]] = keyVal[1];
				}
			}
		}

		return obj;
	}

	static convertPromotionObjectToString(promotionsObject) {
		let promotionsArray = [];
		for (let key in promotionsObject) {
			promotionsArray.push(`${key}=${promotionsObject[key]}`)
		}

		return promotionsArray.join(';') + ';';
	}

	// For flights sales
	static saleContainsDiscountOrPromotionCode(saleType, saleItems) {
		for (let item of saleItems) {
			let promosions = Promotion.convertPromotionStringToObject(item.promotions);

			if (promosions.hasOwnProperty(saleType)) {
				return true;
			}
		}

		return false;
	}

	static async getById (id) {
		let promotion = await appDB.promotions.get(id)
		let promotionEntity = PositiveTS.Storage.Entity.Promotion.import(promotion)
		promotionEntity.id = promotion.id

		return promotionEntity
	}

	static convertPromotionDateToMoment (date) {
		let momentDate = moment(date, 'DD/MM/YYYY')

		// is not DD/MM/YYYY format try parse again
		if (!momentDate.isValid()){
			momentDate = moment(date)
		}

		return momentDate
	}

	static convertPromotionToVueObject (promotion) {
		let promotionVue = Object.assign(promotion)
		promotionVue = _.omit(promotionVue, ['_data', '_new', 'meta'])
		promotionVue.fromDate = PositiveTS.Storage.Entity.Promotion.convertPromotionDateToMoment(promotion.fromDate).toDate() as Date
		promotionVue.toDate = PositiveTS.Storage.Entity.Promotion.convertPromotionDateToMoment(promotion.toDate).toDate() as Date

		return promotionVue
	}

	static convertVueObjectToPromotion (promotion) {
		let promotionEntity = PositiveTS.Storage.Entity.Promotion.import(promotion)
		promotionEntity.id = promotion.id
		let fromDate = PositiveTS.Storage.Entity.Promotion.convertPromotionDateToMoment(promotion.fromDate)
		let toDate = PositiveTS.Storage.Entity.Promotion.convertPromotionDateToMoment(promotion.toDate)
		promotionEntity.fromDate = fromDate.format('DD/MM/YYYY')
		promotionEntity.toDate = toDate.format('DD/MM/YYYY')
		promotionEntity.timestamp = moment().unix()
		promotionEntity.syncStatus = 1

		return promotionEntity
	}

	static async saveToDB(promotion){
        let result = {success: false, promotion: null}

		try  {
			let promotionEntity = PositiveTS.Storage.Entity.Promotion.convertVueObjectToPromotion(promotion)
			
			try {
				await appDB.promotions.put(promotionEntity)
				result.success = true
			}
			catch(e) {
				result.success = false
				Service.Logger.error(e);
				console.warn("trying to save promotion again - we should not get here");
				promotionEntity.code = await Storage.Entity.Promotion.getLastCodePlusOne();
				try {
					await appDB.promotions.put(promotionEntity)	
					result.success = true
				}catch (error) {
					Service.Logger.error(error)
					console.error("Fail save promotion after trying to save promotion again");
				}
			}
			
			Storage.Entity.Promotion.updatePromotionOnSession(promotionEntity);
		}catch (error) {
			result.success = false
			Service.Logger.error(error)
			console.error("Fail save promotion")
		}

		return result
	}

	static updatePromotionOnSession (promotion) {
		let indexOfPromotionSession = session.allPromotions.findIndex(p => p.code == promotion.code)
		if (indexOfPromotionSession >= 0){
			session.allPromotions.splice(indexOfPromotionSession, 1)

			if (promotion.isActive){
				session.allPromotions.push(promotion)
			}
		}else{
			if (promotion.isActive){
				session.allPromotions.push(promotion)
			}
		}
	}
}}}}
