module PositiveTS {
	export module Application {
		export module Controllers {
			export class ShutdownViewController extends AbstractViewControllerTS {

				// --------------------------------------------------------------------
				// Controller Life-Cycle
				// --------------------------------------------------------------------
				init(options) {
					var aThis = shutdownVC;
				}

				resume(options?) {
					var aThis = zReportVC;

				}

			}
		}
	}
}
declare var shutdownVC: PositiveTS.Application.Controllers.ShutdownViewController
shutdownVC = new PositiveTS.Application.Controllers.ShutdownViewController()
PositiveTS.Application.Controllers.instances.ShutdownViewController = shutdownVC
