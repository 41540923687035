
module PositiveTS {
	export module Printing {
        export module Reports{

            export function printWithdrawalReport(report,startDate,endDate) {
                var company_name = session.pos.companyName, store_name = session.pos.storeName, pos_id = session.pos.deviceID;
                var zebra = printer.jzebra;
        
                zebra.append(printer.allowComponents.init);
        
                zebra.append(printer.allowComponents.centerAlignment);
        
                printer.printLine(company_name);
                printer.printLine(store_name);
                printer.printLine(pos_id);
        
                zebra.append(printer.allowComponents.newLine)
                if (printer.isSunmi() ){
                    zebra.append(printer.allowComponents.bigFontSunmi);
                }
                else{
                    zebra.append(printer.allowComponents.bigFont);
                }
                zebra.append(printer.allowComponents.bold);
                zebra.append(printer.allowComponents.underline);
        
                printer.printLine(i18next.t(`printing.withdrawlReport`));
                zebra.append(printer.allowComponents.newLine)

                zebra.append(printer.allowComponents.init);
                zebra.append(printer.allowComponents.rightAlignment)

                printer.printLine(`${i18next.t("printing.fromDate")} :  ${startDate.format("DD/MM/YYYY")}`);
                printer.printLine(`${i18next.t("printing.toDate")} :  ${endDate.format("DD/MM/YYYY")}`);
                zebra.append(printer.allowComponents.newLine)

                zebra.append(printer.allowComponents.init);
                zebra.append(printer.allowComponents.rightAlignment)

                let totalsTableSizes = [Math.floor(0.34*printer.lineLength),Math.floor(0.34*printer.lineLength),Math.floor(0.31*printer.lineLength)];
                printer.printHeader(printer.getTableRow([i18next.t("printing.salesCount"),i18next.t("printing.withdrawalsAmount"),i18next.t("printing.feesAmount")],totalsTableSizes));
                
                if(printer.isSunmi() || printer.isPicturesPrinter()) {
                    printer.printTableRow([`${String(report.count)}`,String(report.withdrawn),String(report.fees)], totalsTableSizes);
                } else {
                    printer.printTableEnglishRow([`${String(report.count)}`,String(report.withdrawn),String(report.fees)].reverse(),totalsTableSizes.reverse());
                }

                zebra.append(printer.allowComponents.newLine)
                zebra.append(printer.allowComponents.init);

                if(!posUtils.isNullOrUndefined(report.sales)) {
                    let salesTotalsTableSizes = [Math.floor((0.25*printer.lineLength)),Math.floor((0.25*printer.lineLength)),Math.floor((0.25*printer.lineLength)),Math.floor((0.25*printer.lineLength))];
                    
                    printer.printHeader(printer.getTableRow([i18next.t("printing.invoiceTitle"),i18next.t("printing.date"),i18next.t("printing.withdrawn"),i18next.t("printing.surcharge")],salesTotalsTableSizes));
                    report.sales.forEach((sale) => {
                        if(session.pos.isRoshemet){
                            printer.printTableRow([String(sale.invoice), moment(sale.sold_at).format("DD/MM/YY"), String(sale.withdrawn), String(sale.surcharge)], [14,11,7,6]);
                        } else if(printer.isSunmi() || printer.isPicturesPrinter()) {
                            printer.printTableRow([String(sale.invoice), moment(sale.sold_at).format("DD/MM/YY"), String(sale.withdrawn), String(sale.surcharge)], salesTotalsTableSizes);
                        } else {
                            printer.printTableEnglishRow([String(sale.invoice), moment(sale.sold_at).format("DD/MM/YY"), String(sale.withdrawn), String(sale.surcharge)].reverse(), salesTotalsTableSizes);
                        }
                    })
                }
                zebra.append(printer.allowComponents.newLine)
                zebra.append(printer.allowComponents.init);
                printer.printLineCut();
                printer.print();

            }

            export function printPromotionsReport(promotions) {
                var company_name = session.pos.companyName, store_name = session.pos.storeName, pos_id = session.pos.deviceID;
                var zebra = printer.jzebra;
        
                zebra.append(printer.allowComponents.init);
        
                zebra.append(printer.allowComponents.centerAlignment);
        
                printer.printLine(company_name);
                printer.printLine(store_name);
                printer.printLine(pos_id);
        
                zebra.append(printer.allowComponents.newLine)
                if (printer.isSunmi() ){
                    zebra.append(printer.allowComponents.bigFontSunmi);
                }
                else{
                    zebra.append(printer.allowComponents.bigFont);
                }
                zebra.append(printer.allowComponents.bold);
                zebra.append(printer.allowComponents.underline);
        
                printer.printLine(i18next.t(`promotionsReport.title`));
                zebra.append(printer.allowComponents.newLine)

                zebra.append(printer.allowComponents.init);
                zebra.append(printer.allowComponents.rightAlignment)

                let totalsTableSizes = [Math.floor(0.34*printer.lineLength),Math.floor(0.34*printer.lineLength),Math.floor(0.31*printer.lineLength)];
                let promoNameTableSizes = [totalsTableSizes.reduce((total, size) => total + size), 0,0]
                printer.printHeader(printer.getTableRow([i18next.t("promotionsReport.nameOrCode"),i18next.t("promotionsReport.startDate"),i18next.t("promotionsReport.endDate")],totalsTableSizes));
                promotions.forEach((promotion) => {

                    if (printer.isHTMLBasedPrinting()) {
                        printer.printTableRow([promotion.name], [1]);
                    } else {
                        printer.printTableRow([promotion.name,"",""], promoNameTableSizes);
                    }

                        if (printer.isHTMLBasedPrinting()) {
                            printer.printTableRow([promotion.code, promotion.fromDate, promotion.toDate], totalsTableSizes);
                        } else if(jsonConfig.getVal(jsonConfig.KEYS.lang) == "en") {
                            printer.printTableEnglishRow([promotion.toDate, promotion.fromDate, promotion.code].reverse(), totalsTableSizes);
                        } else {
                            printer.printTableEnglishRow([promotion.toDate, promotion.fromDate, promotion.code], totalsTableSizes);
                        }
                })
                
                zebra.append(printer.allowComponents.newLine)
                zebra.append(printer.allowComponents.init);
                printer.printLineCut();
                printer.print();

            }

            export function printEmployeeTimeTrack(employee,mode, time_info, msg, timeTrack){
                if (Pinia.globalStore.isRoshemet) {
                    timeTrack.time_track_at = timeTrack.timestamp;
                }
                var company_name = session.pos.companyName, store_name = session.pos.storeName, pos_id = session.pos.deviceID;
                var zebra = printer.jzebra;
        
                zebra.append(printer.allowComponents.init);
        
                zebra.append(printer.allowComponents.centerAlignment);
        
                printer.printLine(company_name);
                printer.printLine(store_name);
                printer.printLine(pos_id);
        
                printer.printLine(` `);
        
                zebra.append(printer.allowComponents.underline);
        
                printer.printLine(i18next.t(`printing.hourReport`));

                zebra.append(printer.allowComponents.init);
                zebra.append(printer.allowComponents.centerAlignment);

        
                var date = new PositiveTS.DateUtils();
                if (timeTrack) {
                    date = new PositiveTS.DateUtils(new Date(timeTrack.time_track_at));
                }
                printer.printLine(`${i18next.t("printing.forDate")} : ` + date.getMonthDay() + `/` + date.getMonth() + `/` + date.getFullYear());
        
                printer.printLine(`${i18next.t("printing.forTime")} : ` + date.getHours() + `:` + date.getMinutes());
        
                zebra.append(printer.allowComponents.init);
        
                printer.printLine(` `);
        
                zebra.append(printer.allowComponents.init);
        
                zebra.append(printer.allowComponents.centerAlignment);
        
                if((mode == `exit`) || (mode === `יציאה`)){
                    printer.printLine(employee.name + ` ${i18next.t("printing.left")}`);
                } else {
                    printer.printLine(employee.name + ` ${i18next.t("printing.entered")}`);
                }
        
                printer.printLine(` `);
        
                if (time_info) {
                    printer.printLine(`${i18next.t("printing.entry")} : ` + time_info.start_time.substr(0,10) + ` ` +  time_info.start_time.substr(11,8) );
                    printer.printLine(`${i18next.t("printing.exit")} : ` + time_info.finish_time.substr(0,10) + ` ` +  time_info.finish_time.substr(11,8) );
                    printer.printLine(`${i18next.t("printing.break")} : ` + time_info.break_time);
                    printer.printLine(`${i18next.t("printing.hours")} : ` + time_info.hours);
                    printer.printLine(`${i18next.t("printing.totalSales")} : ` + time_info.amount);
                }
        
                if (Boolean(msg)){
                    zebra.append(printer.allowComponents.init);
                    zebra.append(printer.allowComponents.rightAlignment);
                    printer.printLine(` `);
                    let msgAry = msg.split(`<br/>`);
                    for (let i=0;i<msgAry.length;i++){
                        printer.printMultyLine(msgAry[i]);
                    }
                }
        
                printer.printLine(` `);
        
                zebra.append(printer.allowComponents.init);
        
                printer.printLineCut();
        
                zebra.append(printer.allowComponents.init);
        
                zebra.print();

            }

            export function printOtotAfterPurchaseByGamePoints(message, originalAmount, amountUsed, amountLeft){
                let company_name = session.pos.companyName, store_name = session.pos.storeName, pos_id = session.pos.deviceID
                let zebra = printer.jzebra
                
                zebra.append(printer.allowComponents.init)
                zebra.append(printer.allowComponents.bigFont)
                zebra.append(printer.allowComponents.centerAlignment)
                printer.printLine(company_name)
                printer.printLine(store_name)
                printer.printLine(pos_id)
                printer.printLine(printer.allowComponents.newLine)
                zebra.append(printer.allowComponents.underline)
                printer.printLine(i18next.t(`pos.purchaseByGamePoints`))
                zebra.append(printer.allowComponents.init)
                zebra.append(printer.allowComponents.centerAlignment)


                zebra.append(printer.allowComponents.init)
                zebra.append(printer.allowComponents.rightAlignment)
                printer.printLine(printer.allowComponents.newLine)

                printer.printLine(`${i18next.t(`otot.tagPointsBeforeUsed`)}: ${originalAmount}`)
                printer.printLine(printer.allowComponents.newLine)

                printer.printLine(`${i18next.t(`otot.tagPointsUsed`)}: ${amountUsed}`)
                printer.printLine(printer.allowComponents.newLine)

                printer.printLine(`${i18next.t(`otot.tagCurrentPoints`)}: ${amountLeft}`)
                printer.printLine(printer.allowComponents.newLine)

                printer.printLine(printer.allowComponents.newLine)
                printer.printLine(message)
                printer.printLine(printer.allowComponents.newLine)
                zebra.append(printer.allowComponents.init)
                printer.printLineCut()
                zebra.append(printer.allowComponents.init)
                zebra.print()
            }

            export function printAllEmployeesTimeReport(allHours,totalHours, startDate,endDate, rows,employees){
                let aThis = printer;
                let zebra = aThis.jzebra;

                let hoursString = "";
                let formattedHours = "";
                let formattedMinutes = "";
                let fullMinutes = 0;
                let minutesString = "";
                
                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                if (aThis.isSunmi() ){
                    zebra.append(aThis.allowComponents.bigFontSunmi);
                }
                else{
                    zebra.append(aThis.allowComponents.bigFont);
                }
                zebra.append(aThis.allowComponents.bold);
                aThis.printLine(`דו"ח שעות לעובדים`);
                zebra.append(aThis.allowComponents.newLine);

                if (aThis.isSunmi() ){
                    aThis.printLine("מ-" + startDate);
                    aThis.printLine("עד-" + endDate);
                }
                else{
                    aThis.printLine(startDate + " - " + endDate);
                }
                
                
                let widths = [10,5,6,6,6,5];
                let classes = ['regular-font', 'regular-font', 'regular-font', 'regular-font', 'regular-font', 'regular-font'];




                for(let i=0;i<employees.length;i++){
                    zebra.append(aThis.allowComponents.newLine);
                    zebra.append(aThis.allowComponents.newLine);
                    zebra.append(aThis.allowComponents.centerAlignment);
                    if (aThis.isSunmi() ){
                        zebra.append(aThis.allowComponents.bigFontSunmi);
                    }
                    else{
                        zebra.append(aThis.allowComponents.bigFont);
                    }				zebra.append(aThis.allowComponents.bold);
                    aThis.printLine(employees[i].name);

                    zebra.append(aThis.allowComponents.newLine);
                    zebra.append(aThis.allowComponents.rightAlignment);

                    
                    let totalPaymentSum = 0, totalPaymentAmount = 0;
                    aThis.printHeader(aThis.getTableRow([i18next.t('printing.date'),i18next.t('printing.day'),i18next.t('printing.entry'),i18next.t('printing.exit'),i18next.t('printing.hours'),i18next.t('printing.manual')],widths,classes ))

                    for(let row of rows[i]){
                        let curRow = row.enterRow || row.exitRow
                        let day  = moment(curRow.timestamp).format("ddd").replace(/.$/,"'")
                        let date = moment(curRow.timestamp).format("DD-MM-YY")
                        let hours = "";
                        let enterTime = " ";
                        let exitTime = "";
                        let isManualUpdate = ((row.exitRow && row.exitRow.isManualUpdate) || (row.enterRow && row.enterRow.isManualUpdate))


                        if (row.enterRow) {
                            enterTime = moment(row.enterRow.timestamp).format("HH:mm")
                        }
                        
                        if (row.exitRow) {
                            exitTime = moment(row.exitRow.timestamp).format("HH:mm")
                        }

                        if (row.enterRow && row.exitRow) {
                            hours = session.fixedNumber(  
                            (moment( new Date(row.exitRow.timestamp) ).diff(moment( new Date( row.enterRow.timestamp) ), 'minutes')/60) ,2 
                            )
                        }

                        printer.addRTLChar();
                        aThis.printTableRow([date,day,enterTime,exitTime,hours,isManualUpdate ? "כן" : ""],widths, classes)
                    }


                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.bold);
                    aThis.printFloatingLine(`-`.repeat(aThis.getTableRow([i18next.t('printing.date'),i18next.t('printing.day'),i18next.t('printing.entry'),i18next.t('printing.exit'),i18next.t('printing.hours'),i18next.t('printing.manual')],widths,classes).length),``);

                    hoursString = allHours[i].toFixed(2);
                    formattedHours = hoursString.indexOf(".") > -1? hoursString.substring(0,hoursString.indexOf(".")): hoursString;
                    formattedMinutes = hoursString.indexOf(".") > -1 ?hoursString.substring(hoursString.indexOf(".")+1):"0";
                    fullMinutes = parseInt(formattedMinutes) * 0.6;
                    minutesString = String(fullMinutes);
                    formattedMinutes = minutesString.indexOf(".") > -1? minutesString.substring(0,minutesString.indexOf(".")): minutesString;

                    aThis.printTableRow([`סה"כ שעות:`," "," "," ",allHours[i].toFixed(2)],widths, ['overflow-visible']);
                    zebra.append(aThis.allowComponents.newLine);
                    zebra.append(aThis.allowComponents.rightAlignment);
                    printer.addRTLChar();
                    aThis.printLine(formattedHours + " שעות" + " " + formattedMinutes + " דקות");

                }

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.newLine);
                zebra.append(aThis.allowComponents.newLine);
                zebra.append(aThis.allowComponents.newLine);
                zebra.append(aThis.allowComponents.bigFont);
                zebra.append(aThis.allowComponents.bold);
                

                hoursString = String(totalHours);
                formattedHours = hoursString.indexOf(".") > -1 ? hoursString.substring(0,hoursString.indexOf(".")): hoursString;
                formattedMinutes = hoursString.indexOf(".") > -1 ?hoursString.substring(hoursString.indexOf(".")+1):"0";
                fullMinutes = parseInt(formattedMinutes) * 0.6;
                minutesString = String(fullMinutes);
                formattedMinutes = minutesString.indexOf(".") > -1? minutesString.substring(0,minutesString.indexOf(".")): minutesString;
                
                zebra.append(aThis.allowComponents.rightAlignment);
                aThis.printLine(`סה"כ שעות: ` + hoursString)
                zebra.append(aThis.allowComponents.newLine);
                printer.addRTLChar();
                aThis.printLine(formattedHours + " שעות" + " " + formattedMinutes + " דקות");

                aThis.printLineCut();
                zebra.print();
                
            }
            export function printEmployeeTimeReport(name, totalHours, month, rows){
                let aThis = printer;
                let zebra = aThis.jzebra;
                
                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                if (aThis.isSunmi() ){
                    zebra.append(aThis.allowComponents.bigFontSunmi);
                }
                else{
                    zebra.append(aThis.allowComponents.bigFont);
                }
                
                zebra.append(aThis.allowComponents.bold);
                
                


                aThis.printLine(`דו"ח שעות לעובד`);
                zebra.append(aThis.allowComponents.newLine);
                aThis.printLine(`חודש ` + month);
                zebra.append(aThis.allowComponents.newLine);
                aThis.printLine(name);
                zebra.append(aThis.allowComponents.newLine);
                zebra.append(aThis.allowComponents.rightAlignment);

                let widths = [10,5,6,6,6,5];
                let classes = ['regular-font', 'regular-font', 'regular-font', 'regular-font', 'regular-font', 'regular-font'];
                let totalPaymentSum = 0, totalPaymentAmount = 0;
                aThis.printHeader(aThis.getTableRow([i18next.t('printing.date'),i18next.t('printing.day'),i18next.t('printing.entry'),i18next.t('printing.exit'),i18next.t('printing.hours'),i18next.t('printing.manual')],widths, classes))

                for(let row of rows){
                    let curRow = row.enterRow || row.exitRow
                    let day  = moment(curRow.timestamp).format("ddd").replace(/.$/,"'")
                    let date = moment(curRow.timestamp).format("DD-MM-YY")
                    let hours = "";
                    let enterTime = " ";
                    let exitTime = "";
                    let isManualUpdate = ((row.exitRow && row.exitRow.isManualUpdate) || (row.enterRow && row.enterRow.isManualUpdate))


                    if (row.enterRow) {
                        enterTime = moment(row.enterRow.timestamp).format("HH:mm")
                    }
                    
                    if (row.exitRow) {
                        exitTime = moment(row.exitRow.timestamp).format("HH:mm")
                    }

                    if (row.enterRow && row.exitRow) {
                        hours = session.fixedNumber(  
                        (moment( new Date(row.exitRow.timestamp) ).diff(moment( new Date( row.enterRow.timestamp) ), 'minutes')/60) ,2 
                        )
                    }

                    printer.addRTLChar();
                    aThis.printTableRow([date,day,enterTime,exitTime,hours,isManualUpdate ? "כן" : ""],widths, classes)
                }


                let formattedHours = totalHours.indexOf(".") > -1? totalHours.substring(0,totalHours.indexOf(".")): totalHours;
                let formattedMinutes = totalHours.substring(totalHours.indexOf(".")+1);
                let fullMinutes = parseInt(formattedMinutes) * 0.6;
                let minutesString = String(fullMinutes);
            
                formattedMinutes = minutesString.indexOf(".") > -1? minutesString.substring(0,minutesString.indexOf(".")): minutesString;



                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.bold);
                aThis.printFloatingLine(`-`.repeat(43),``);
                aThis.printTableRow([`סה"כ שעות:`," "," "," ",totalHours],widths, ['overflow-visible'])
                zebra.append(aThis.allowComponents.newLine);
                printer.addRTLChar();
                aThis.printLine(formattedHours + " שעות" + " " + formattedMinutes + " דקות");

                aThis.printLineCut();

                zebra.print();
                
            }
            export function printCashierStatementMultiCurrencies(type,company_name,store_name,pos_id,cashier_name,currencies,posCurrency){
                let showCoinsGap = jsonConfig.getVal(jsonConfig.KEYS.showMultiCurrCoinGap);
                let multiCurr = Service.MultiCurr.getInstance();
                let aThis = printer;
                var zebra = aThis.jzebra;
                zebra.append(aThis.allowComponents.init);
        
                zebra.append(aThis.allowComponents.centerAlignment);
        
                aThis.printLine(company_name);
                aThis.printLine(store_name);
                aThis.printLine(pos_id);
        
                aThis.printLine(` `);
        
                zebra.append(aThis.allowComponents.underline);
                
                var title = '' 
                if (type == Shared.Constants.CashierStatement.TYPE_START_DAY){
                    title = i18next.t("printing.declarationOpenPos");
                }
                if (type == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS){
                    title = i18next.t("cashierStatement.statementTitle.add");
                }
                if (type == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS){
                    title = i18next.t("cashierStatement.statementTitle.remove");
                }
                if (type == Shared.Constants.CashierStatement.TYPE_MID_DAY || type == Shared.Constants.CashierStatement.TYPE_END_DAY){
                    title = i18next.t("printing.declarationPosStatus");
                }
        
                aThis.printLine(title);

                zebra.append(aThis.allowComponents.init);        
                zebra.append(aThis.allowComponents.centerAlignment);

        
                var date = new PositiveTS.DateUtils();
        
                aThis.printLine(`${i18next.t("printing.forDate")} : ` + date.getMonthDay() + `/` + date.getMonth() + `/` + date.getFullYear());
        
                aThis.printLine(`${i18next.t("printing.forTime")} : ` + date.getHours() + `:` + date.getMinutes());
        
                zebra.append(aThis.allowComponents.init);
        
                aThis.printLine(` `);
        
                zebra.append(aThis.allowComponents.init);
        
                aThis.printLineForTopBox();
        
                aThis.printBoxCenteredLine(i18next.t("printing.cashByCoinDetails"));
        
                aThis.printLineForMiddleBox();
        
                zebra.append(aThis.allowComponents.init);
        
                var totalSum = 0;
                currencies.forEach((c) => {
                    totalSum += c.amount
                    let currencyAmount = session.fixedNumber(c.currencyAmount);
                    let amount = session.fixedNumber(c.amount);
                    aThis.printFloatingInBoxLine(c.code + ' ' + currencyAmount, amount + ` ${posCurrency}`); 
                });
                if (showCoinsGap) {
                    aThis.printLineForMiddleBox();
                    let widths = [5, 5, 5, 5];
                    let headers = []
                    headers.push(i18next.t("cashierStatementMultiCurrencies.currency"));
                    headers.push(i18next.t("printing.cashByCoinCurrenciesDetails.calcedCurrency"));
                    headers.push(i18next.t("printing.cashByCoinCurrenciesDetails.statedCurrency"));
                    headers.push(i18next.t("printing.cashByCoinCurrenciesDetails.statedAndCalcedGap"));
                    aThis.printHeader(aThis.getTableRow(headers, widths), true);
                    let printCurrencyLine = (row) => {
                        let currencyAmount = session.fixedNumber(row.currencyAmount);
                        let cashInPos = session.fixedNumber(row.cashInPos);
                        let statedAndCalcedGap = session.fixedNumber(row.statedAndCalcedGap);
                        aThis.printHeader(aThis.getTableRow([row.code, cashInPos, currencyAmount, statedAndCalcedGap], widths), true);
                    }
                    let posCurrencyDetails = currencies.find(c => c.code == posCurrency);
                    if (posCurrencyDetails) {
                        printCurrencyLine(posCurrencyDetails);
                    }
                    let otherMainCurrency = currencies.find(c => c.code == (posCurrency == 'ILS' ? 'USD' : 'ILS'));
                    if (otherMainCurrency) {
                        printCurrencyLine(otherMainCurrency);
                    }
                    let eurCurrency = currencies.find(c => c.code == 'EUR');
                    if (eurCurrency) {
                        printCurrencyLine(eurCurrency);
                    }
                    let restOfCurrencies = currencies.filter(c => ['ILS', 'USD', 'EUR'].includes(c.code) == false)
                    for (const curr of restOfCurrencies) {
                        printCurrencyLine(curr);
                    }
                }

                aThis.printLineForMiddleBox();

                let totalTitle = i18next.t("printing.totalInPos");
                if (type == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS){
                    totalTitle = i18next.t("cashManagement.addedCash");
                }
                if (type == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS){
                    totalTitle = i18next.t("cashManagement.cashToSafe");
                }
                    
                aThis.printFloatingInBoxLine(`${totalTitle}:`, 
                    session.fixedNumber(totalSum) + ` ${posCurrency}`);
        
                aThis.printLineForMiddleBox();
        
                printer.printLineCut();
        
                zebra.append(aThis.allowComponents.init);
        
                zebra.print();
            }
            export function printPunchCardTransactions(result){
                var company_name = session.pos.companyName;
                var store_name = session.pos.storeName;
                var pos_id = session.pos.deviceID;

                let aThis = printer;
                var zebra = aThis.jzebra;
                zebra.append(aThis.allowComponents.init);

                zebra.append(aThis.allowComponents.centerAlignment);

                aThis.printLine(company_name);
                aThis.printLine(store_name);
                aThis.printLine(pos_id);

                aThis.printLine(" ");

                zebra.append(aThis.allowComponents.underline);
                aThis.printLine("פעולות ל" + result.itemName);
                aThis.printLine("לכרטיסיה " + result.cardNumber);


                var date = new PositiveTS.DateUtils();

                aThis.printLine('תאריך : ' + date.getMonthDay() + '/' + date.getMonth() + '/' + date.getFullYear());
                aThis.printLine('שעה : ' + date.getHours() + ':' + date.getMinutes());
                

                zebra.append(aThis.allowComponents.init);

                aThis.printLine(" ");

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.rightAlignment);
                zebra.append(aThis.allowComponents.bold);

                aThis.printTableRow([i18next.t('printing.date'),"תנועה","חנות","כמות"],[17,7,13,5]);
                aThis.printTableRow(['-'.repeat(aThis.lineLength)],[aThis.lineLength]);

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.rightAlignment);

                for (let pct of result.punch_card_transactions) {
                    let date = moment(new Date(pct.created_at)).format("DD/MM/YYYY HH:mm");
                    let type = (pct.action_type == 1 ? "טעינה" : "פריקה");
                    
                    aThis.printTableRow([date,type,pct.store_name,String(pct.amount)],[17,7,13,5])
                };

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.underline);

                aThis.printLine("");

                aThis.printLine('יתרה : ' +result.punch_card_item.current_balance.toString())

                
                printer.printLineCut();

                zebra.append(aThis.allowComponents.init);

                zebra.print();	
            }
            export function printPunchCardItems(punchCardItems:any[], cardNumber:string){
                var company_name = session.pos.companyName;
                var store_name = session.pos.storeName;
                var pos_id = session.pos.deviceID;

                let aThis = printer;
                var zebra = aThis.jzebra;
                zebra.append(aThis.allowComponents.init);

                zebra.append(aThis.allowComponents.centerAlignment);

                aThis.printLine(company_name);
                aThis.printLine(store_name);
                aThis.printLine(pos_id);

                aThis.printLine(" ");

                zebra.append(aThis.allowComponents.underline);

                aThis.printLine("דוח יתרות לכרטיסיה " + cardNumber.substr(-6));


                var date = new PositiveTS.DateUtils();

                aThis.printLine('תאריך : ' + date.getMonthDay() + '/' + date.getMonth() + '/' + date.getFullYear());
                aThis.printLine('שעה : ' + date.getHours() + ':' + date.getMinutes());

                zebra.append(aThis.allowComponents.init);

                aThis.printLine(" ");

                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.rightAlignment);

                aThis.printLineForTopBox();

                aThis.printBoxCenteredLine("פירוט יתרות");

                aThis.printLineForMiddleBox();
                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.rightAlignment);
                var linetxt = ""
                for (let pci of punchCardItems) {
                    aThis.printFloatingInBoxLine(pci.item_name, pci.current_balance.toString(),22);					
                };
                aThis.printLineForMiddleBox();

                printer.printLineCut();

                zebra.append(aThis.allowComponents.init);

                zebra.print();	
            }
            export function printValuCardTransactions(currentCardNumber, currentBalance, cardTransactions){
                    var company_name = session.pos.companyName;
                    var store_name = session.pos.storeName;
                    var pos_id = session.pos.deviceID;
        
                    let aThis = printer;
                    var zebra = aThis.jzebra;
                    zebra.append(aThis.allowComponents.init);
        
                    zebra.append(aThis.allowComponents.centerAlignment);
        
                    aThis.printLine(company_name);
                    aThis.printLine(store_name);
                    aThis.printLine(pos_id);
        
                    aThis.printLine(` `);
        
                    zebra.append(aThis.allowComponents.underline);
        
                    aThis.printLine(`${i18next.t("printing.reportForCard")} ` + currentCardNumber);
        
        
                    var date = new PositiveTS.DateUtils();
        
                    aThis.printLine(`${i18next.t("printing.date")} : ` + date.getMonthDay() + `/` + date.getMonth() + `/` + date.getFullYear());
                    aThis.printLine(`${i18next.t("printing.hour")} : ` + date.getHours() + `:` + date.getMinutes());
        
                    zebra.append(aThis.allowComponents.init);
        
                    aThis.printLine(` `);
        
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.rightAlignment);
        
                    aThis.printLineForTopBox();
        
                    aThis.printBoxCenteredLine(i18next.t("printing.transactionDetail"));
        
                    aThis.printLineForMiddleBox();
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.rightAlignment);
                    var linetxt = ``
                    for (var i = 0; i < cardTransactions.length; i++) {
                        linetxt = cardTransactions[i].trxTime + ` ` + PositiveTS.Service.StrUtils.rpad(cardTransactions[i].trxAction, 15,` `) +
                        PositiveTS.Service.StrUtils.rpad(session.fixedNumber(cardTransactions[i].trxAmnt), 5, ` `);
                        aThis.printLine(linetxt);
                            //aThis.printFloatingInBoxLine( cardTransactions[i].trxTime + ` ` + cardTransactions[i].trxAction + ` ` , session.fixedNumber(cardTransactions[i].trxAmnt)  );
                    };
                    aThis.printLineForMiddleBox();
                    aThis.printLine(`${i18next.t("printing.balance")} ` + currentBalance + ` ${i18next.t("printing.currentCurrency")}`);
                    printer.printLineCut();
        
                    zebra.append(aThis.allowComponents.init);
        
                    zebra.print();
            }
            export function printCashierStatement(type,company_name,store_name,pos_id,cashier_name,money){
                let aThis = printer;
                var zebra = aThis.jzebra;
                zebra.append(aThis.allowComponents.init);
        
                zebra.append(aThis.allowComponents.centerAlignment);
        
                aThis.printLine(company_name);
                aThis.printLine(store_name);
                aThis.printLine(pos_id);
        
                aThis.printLine(` `);
        
                zebra.append(aThis.allowComponents.underline);
        
                var title = type == `enter` ? i18next.t("printing.declarationOpenPos") : i18next.t("printing.declarationPosStatus");
        
                aThis.printLine(title);

                zebra.append(aThis.allowComponents.init);        
                zebra.append(aThis.allowComponents.centerAlignment);
        
                var date = new PositiveTS.DateUtils();
        
        
                aThis.printLine(`${i18next.t("printing.forDate")} : ` + date.getMonthDay() + `/` + date.getMonth() + `/` + date.getFullYear());
        
                aThis.printLine(`${i18next.t("printing.forTime")} : ` + date.getHours() + `:` + date.getMinutes());
        
                zebra.append(aThis.allowComponents.init);
        
                aThis.printLine(` `);
        
                zebra.append(aThis.allowComponents.init);
        
                aThis.printLineForTopBox();
        
                aThis.printBoxCenteredLine(i18next.t("printing.cashByCoinAndBillsDetails"));
        
                aThis.printLineForMiddleBox();
        
                zebra.append(aThis.allowComponents.init);
        
                var totalSum = 0;
        
                for (var i = 0; i < money.length; i++) {
                    var s = money[i].value > 10 ? i18next.t("printing.bill") : i18next.t("printing.coin") , sum = Number(money[i].value * money[i].count);
                    totalSum = Number(totalSum + sum);
                    let sum_str = String(sum);
                    if(sum < 1){
                        sum_str = sum.toFixed(2);
                    }
                    printer.addRTLChar();
                    aThis.printFloatingInBoxLine(s + ` ` + money[i].value, money[i].count == 0 ? `--` : money[i].count + `x` + money[i].value + `=` + sum_str);
                };
        
                aThis.printLineForMiddleBox();
                printer.addRTLChar();
                aThis.printFloatingInBoxLine(i18next.t("printing.totalInPos"), totalSum.toFixed(2) + ` ${i18next.t("printing.currentCurrency")}`);
        
                aThis.printLineForMiddleBox();
        
                printer.printLineCut();
        
                zebra.append(aThis.allowComponents.init);
        
                zebra.print();
            }
            export function printCashManagementStatement(type,company_name,store_name,pos_id,cashier_name,money,title,sumTitle){
			let aThis = printer;
			var zebra = aThis.jzebra;
			zebra.append(aThis.allowComponents.init);
	
			zebra.append(aThis.allowComponents.centerAlignment);
	
			aThis.printLine(company_name);
			aThis.printLine(store_name);
			aThis.printLine(pos_id);
			aThis.printLine(cashier_name);
	
			aThis.printLine(` `);
	
			zebra.append(aThis.allowComponents.underline);
		
			aThis.printLine(title);
	
			var date = new PositiveTS.DateUtils();
	
	
			aThis.printLine(date.getMonthDay() + `/` + date.getMonth() + `/` + date.getFullYear() + ' '+ date.getHours() + `:` + date.getMinutes());
	
			zebra.append(aThis.allowComponents.init);
	
			aThis.printLine(` `);
	
			zebra.append(aThis.allowComponents.init);
	
			aThis.printLineForTopBox();
			printer.addRTLChar();
			aThis.printBoxCenteredLine(title);
	
			aThis.printLineForMiddleBox();
	
			zebra.append(aThis.allowComponents.init);
	
			var totalSum = 0;
	
			for (var i = 0; i < money.length; i++) {
				var s = money[i].value > 10 ? i18next.t("printing.bill") : i18next.t("printing.coin") , sum = Number(money[i].value * money[i].count);
				totalSum = Number(totalSum + sum);
				printer.addRTLChar();
				aThis.printFloatingInBoxLine(s + ` ` + money[i].value, money[i].count == 0 ? `--` : money[i].count + `x` + money[i].value + `=` + sum.toFixed(2));
			};
	
			aThis.printLineForMiddleBox();
			printer.addRTLChar();
			aThis.printFloatingInBoxLine(sumTitle, totalSum.toFixed(2) + ` ${i18next.t("printing.currentCurrency")}`);
	
			aThis.printLineForMiddleBox();
	
			printer.printLineCut();
	
			zebra.append(aThis.allowComponents.init);
	
			zebra.print();
            }
            export function printRoshemetVoucherReport(report,company_name,store_name,customer,summeryOnly?){
                let aThis = printer;
                var zebra = aThis.jzebra;
        
                //Reset the printer
                zebra.append(aThis.allowComponents.init);
                aThis.printLogo();
                zebra.append(aThis.allowComponents.centerAlignment);
                aThis.printLine(company_name);
                aThis.printLine(store_name);
                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                
                aThis.printLine("דוח ממשק עבור");
                aThis.printLine(customer);
                aThis.jzebra.append(printer.allowComponents.newLine);

                aThis.printLine(moment(report.startDate).format("D/M/YY") + " - " + moment(report.endDate).format("D/M/YY"));

                zebra.append(aThis.allowComponents.newLine);

                let widths = [13,11,7,7];
                if(!summeryOnly){
                    aThis.printHeader(aThis.getTableRow([i18next.t('printing.date'),"חשבונית","סכום","יתרה"],widths))
                }
                report.msg = report.msg.slice(1);
                let action = "";
                let salesCount = 0;
                let salesSum = 0;
                for(let row of report.msg){
                    salesSum += row.amount
                    salesCount++;
                    if(!summeryOnly){
                        let parsedDated = moment(row.actionDt,"DD/MM/YYYY");
                        aThis.printTableRow([parsedDated.format("dd") + `' ` + parsedDated.format("DD/MM/YY"),String(row.invoiceNumber),session.fixedNumber(row.amount),session.fixedNumber(row.bal)],widths)	
                    }
                }
                
                aThis.jzebra.append(printer.allowComponents.newLine);
                aThis.printHeader("סיכום ממשק");
                aThis.printFloatingLine(`כמות מכירות: ` + salesCount);
                aThis.printFloatingLine(`סה"כ מכירות: `  + session.fixedNumber(salesSum));


                aThis.printLineCut();
                aThis.print();
                
            }
            export function printRoshemetVoucherWithoutInterfacesReport(report,company_name,store_name,customer,summeryOnly = true){
                let aThis = printer;
                var zebra = aThis.jzebra;
        
                //Reset the printer
                zebra.append(aThis.allowComponents.init);
                aThis.printLogo();
                zebra.append(aThis.allowComponents.centerAlignment);
                aThis.printLine(company_name);
                aThis.printLine(store_name);
                zebra.append(aThis.allowComponents.init);
                zebra.append(aThis.allowComponents.centerAlignment);
                zebra.append(aThis.allowComponents.bigFont);
                
                aThis.printLine("דוח שובר");
                aThis.printLine(customer);
                aThis.jzebra.append(printer.allowComponents.newLine);

                aThis.printLine(moment(report.startDate).format("D/M/YY") + " - " + moment(report.endDate).format("D/M/YY"));

                zebra.append(aThis.allowComponents.newLine);

                let widths = [15,13,10];
                if(!summeryOnly){
                    aThis.printHeader(aThis.getTableRow([i18next.t('printing.date'),"חשבונית","סכום"],widths))
                }

                let action = "";
                let salesCount = 0;
                let salesSum = 0;
                for(let row of report.lines){
                    salesSum += row.amount
                    salesCount++;
                    if(!summeryOnly){
                        let parsedDated = moment(row.sold_at);
                        printer.addRTLChar();
                        aThis.printTableRow([parsedDated.format("dd") + "' " + parsedDated.format("DD/MM/YY"),String(row.invoice_number),session.fixedNumber(row.amount)],widths)	
                    }
                }
                
                aThis.jzebra.append(printer.allowComponents.newLine);
                aThis.printHeader("סיכום שובר");
                aThis.printFloatingLine(`כמות מכירות: ` + salesCount);
                aThis.printFloatingLine(`סה"כ מכירות: `  + session.fixedNumber(salesSum));


                aThis.printLineCut();
                aThis.print();
                
            }

            export function printCustomerSalesReport (sales, dates: {fromDate: Date, toDate: Date}, customer, bonusPrecent, reportByHakafaCustomers: boolean) {
                let aThis = printer
                let zebra = aThis.jzebra
        
                zebra.append(aThis.allowComponents.init)
                aThis.printLogo()
                zebra.append(aThis.allowComponents.centerAlignment)
                aThis.printLine(session.pos.companyName)
                aThis.printLine(session.pos.storeName)
                zebra.append(aThis.allowComponents.init)
                zebra.append(aThis.allowComponents.centerAlignment)
                zebra.append(aThis.allowComponents.bigFont)
                
                const title = reportByHakafaCustomers ? i18next.t('customerSalesReportDialog.titleHakafaCustomers') : i18next.t('customerSalesReportDialog.titleCustomers')
                aThis.printLine(title)
                if (!_.isEmpty(customer)) {
                    aThis.printLine(customer.name)
                }
                aThis.jzebra.append(printer.allowComponents.newLine)

                aThis.printLine(`${moment(dates.fromDate).format("DD/MM/YY")} - ${moment(dates.toDate).format("DD/MM/YY")}`)
                zebra.append(aThis.allowComponents.newLine)

                let widths
                let twoRowsWidths
                let salesSum = 0

                let isSunmiV1Printer = session.pos.printerType == "sunmiv1"

 
                    if (!_.isEmpty(customer)) {
                        widths = isSunmiV1Printer ? [printer.lineLength / 4, printer.lineLength / 4,printer.lineLength / 4,printer.lineLength / 4] : [11,11,16,10]
                        twoRowsWidths = [printer.lineLength/2 , printer.lineLength / 2]
                        aThis.printHeader(aThis.getTableRow(['חשבונית', 'תאריך', 'מסמך', 'סה"כ'], widths))
                        sales.forEach((sale) => {
                            salesSum += sale.amount
                            let soldAt = moment(sale.sold_at)
                            printer.addRTLChar();
                            if (isSunmiV1Printer) {
                                aThis.printTableRow([String(sale.invoice_number), soldAt.format("DD/MM/YY")], twoRowsWidths)
                                aThis.printTableRow([i18next.t(`posPastInvoices.invoiceTypes.${sale.invoice_type}`), String(session.fixedNumber(sale.amount))], twoRowsWidths)
                                aThis.printHr();
                            } else {
                                aThis.printTableRow([String(sale.invoice_number), soldAt.format("DD/MM/YY"),
                                i18next.t(`posPastInvoices.invoiceTypes.${sale.invoice_type}`), String(session.fixedNumber(sale.amount))], widths)
                            }
                        })
                    } else {
                        widths = [9,10,8,11,9]
                        twoRowsWidths = [[printer.lineLength/3, printer.lineLength/3, printer.lineLength/3], [printer.lineLength/2,printer.lineLength/2]]
                        if(isSunmiV1Printer){
                            aThis.printTableRow(['חשבונית', 'תאריך','לקוח'], twoRowsWidths[0])
                            aThis.printTableRow(['מסמך', 'סה"כ'], twoRowsWidths[1])
                            aThis.printHr();
                        }else {
                            aThis.printHeader(aThis.getTableRow(['חשבונית', 'תאריך','לקוח','מסמך', 'סה"כ'], widths)) 
                        }
                        sales.forEach((sale) => {
                            salesSum += sale.amount
                            let soldAt = moment(sale.sold_at)
                            printer.addRTLChar();
                            if(isSunmiV1Printer) {
                                aThis.printTableRow([String(sale.invoice_number), soldAt.format("DD/MM/YY"),String(sale.customer_name)], twoRowsWidths[0])
                                aThis.printTableRow([i18next.t(`posPastInvoices.invoiceTypes.${sale.invoice_type}`), String(session.fixedNumber(sale.amount))], twoRowsWidths[1])   
                                aThis.printHr();
                            } else {
                                aThis.printTableRow([String(sale.invoice_number), soldAt.format("DD/MM/YY"), String(sale.customer_name),
                                i18next.t(`posPastInvoices.invoiceTypes.${sale.invoice_type}`), String(session.fixedNumber(sale.amount))], widths)
                            }
                           
                        })
                    }

                aThis.jzebra.append(printer.allowComponents.newLine)
                aThis.printHeader("סיכום דוח")
                aThis.printFloatingLine(`סה"כ מכירות: ${session.fixedNumber(salesSum)}`)
                if (bonusPrecent > 0){
                    aThis.printFloatingLine(`סה"כ עמלה: ${session.fixedNumber(salesSum * (bonusPrecent / 100))}`)
                }

                aThis.printLineCut()
                aThis.print()
            }
        }
    }
}
