module PositiveTS {
	export module Application {
		export module Controllers {
			export class PosPastInvoicesViewController extends AbstractViewControllerTS {
				// A map between row IDs and there sale IDs in the table view
				rowIDsSaleIDs = {}
				
				// A map between row IDs and there data object
				rowIDsData = {}
				
				saleIDsRowIDs = {}
				
				rowIDsSales = {}
				
				customersNames = {}
				
				// The user selected row id
				selectedRowId = ''

				unsubscribe = null;

				currentPage = 0;
				invoicesPerPage = 50;
				totalAmountOfSales = 0;
				
				init(options?) {
					var aThis = posPastInvoicesVC;
					
					// Initialize the tableview
					var tableView = $('#pos-past-invoices-tableview').tableview();
					
					
					// Set the number of visible rows
					tableView.setNumberOfVisibleRows(5);
					
					//Initialize the item actions buttons
					posPastInvoicesVC.initalizeItemActions();
					
					// Set a listener for row select
					tableView.attachSelectRowListener('posPastInvoiceRowSelectListener', (theRow) => {
						PositiveTS.VueInstance.$refs.posPastInvoices.rowSelected(theRow)
						
					});

					let spaces ={
						invNumber: session.pos.isElal ? "13%" : "14%",
						invType:session.pos.isElal ? "12%" : "13%",
						soldAt: "14%",
						salesPerson: session.pos.isElal ? "12%" : "13%",
						quantity: session.pos.isElal ? "9%" : "10%",
						total: session.pos.isElal ? "10%" : "11%",
						customerName: session.pos.isElal ? "12%" : "17%",
						ticketType:  "10%" ,
						syncStatus: "6%",

					}
					
					// Set the table view header
					tableView.setHeader(`
					<div class='ui-grid-c'>
					<div class='ui-block-a' style='width: ${spaces.invNumber}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.invoiceNumber")}</div></div>
					<div class='ui-block-d' style='width: ${spaces.invType}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.invoiceType")}</div></div>
					<div class='ui-block-d' style='width: ${spaces.soldAt}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.date")}</div></div>
					<div class='ui-block-d' style='width: ${spaces.salesPerson}'><div class='ui-tableview-header-cell'>${jsonConfig.getVal(jsonConfig.KEYS.showSalespersonOnPastInovices) ? i18next.t("posPastInvoices.salesperson") : i18next.t("posPastInvoices.cashier")}</div></div>
					<div class='ui-block-d' style='width: ${spaces.quantity}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.quantity")}</div></div>
					<div class='ui-block-d' style='width: ${spaces.total}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.total")}</div></div>
					<div class='ui-block-d' style='width: ${spaces.customerName}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.customerName")}</div></div>
					${session.pos.isElal ? "<div class='ui-block-d' style='width:"+spaces.ticketType+"'><div class='ui-tableview-header-cell'>" + i18next.t("posPastInvoices.elalTicketType.type")+"</div></div>" : ""}
					<div class='ui-block-b' style='width: ${spaces.syncStatus}'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.syncStatus")}</div></div>
					</div><!-- /grid-c -->`);
					
					// Turn on auto-resize
					tableView.setAutoResizeOn('#pos-past-invoices');
					PositiveTS.VueInstance.$refs.posPastInvoices.open();
				}
				
				/**
				* Initialize the item actions buttons
				*/
				initalizeItemActions() {
					$('#pos-items-tab').click(posPastInvoicesVC.displaySaleItems);
					$('#pos-items-payments-tab').click(posPastInvoicesVC.getPosPastInvoicesPayments);
					$('#pos-past-invoices-show-payments').click(posPastInvoicesVC.getPosPastInvoicesPayments);
					$('#pos-payments-items-tab').click(posPastInvoicesVC.displaySaleItems);
					$('#pos-payments-tab').click(posPastInvoicesVC.getPosPastInvoicesPayments);
					
					
				}
				resume(options) {
					var aThis = posPastInvoicesVC;
					
					// Get the table view
					var tableView = $('#pos-past-invoices-tableview').tableview();
					
					//Select row
					if (aThis.selectedRowId != ''){
						tableView.selectRow(aThis.selectedRowId);
					}
					
					// Load initial data into the table view
					aThis.reload();
					
					// Listen for changes in the sales queue and update the table when they happen					
					if (aThis.unsubscribe != null) {
						aThis.unsubscribe();
						aThis.unsubscribe = null;
					}

					aThis.unsubscribe =  Vue.watch(() => Pinia.globalStore.offlineSaleCount, (newValue, oldValue) => {
						aThis.currentPage = 0;
						aThis.reload(false);
					});
				}
				stop () {
					var aThis = posPastInvoicesVC
					
					//Unbind click event
					$('#pos-items-tab').unbind('click');
					$('#pos-items-payments-tab').unbind('click');
					$('#pos-payments-items-tab').unbind('click');
					$('#pos-payments-tab').unbind('click');
					
					
					
					// Remove observers
					if (aThis.unsubscribe != null) {
						aThis.unsubscribe();
						aThis.unsubscribe = null;
					}
				}
				async reload(useLoadingMessage:boolean = true) {
					let aThis = posPastInvoicesVC;
					
					// Fetch all past sales
					try {

						let sales = []

						if (session.pos.hasFlights) {
							sales = await Storage.Entity.Sale.fetchPastSales();

							let currentLegsIds = (Pinia.flightsStore.currentTeamLegs || []).map(leg => leg.id);
							sales = sales.filter(sale => currentLegsIds.includes(sale.flightLegId))

						} else {

							if(jsonConfig.getVal(jsonConfig.KEYS.paginatePastInvoices)) {

								$("#pos-past-invoices-tableview .ui-tableview-container")[0].scrollTop = 0; //sorry lord

								if(useLoadingMessage) app.showLoadingMessage(i18next.t("posPastInvoices.loading"));
								aThis.totalAmountOfSales = await Storage.Entity.Sale.getPastSalesAmount();
								PositiveTS.VueInstance.$refs.posPastInvoices.updatePaginationParams(aThis.totalAmountOfSales, aThis.currentPage, aThis.invoicesPerPage);
								sales = await Storage.Entity.Sale.fetchPastSales(aThis.currentPage, aThis.invoicesPerPage);
								app.hideLoadingMessage();
							} else {
								sales = await Storage.Entity.Sale.fetchPastSales();
							}
						}
						
						
						//Save local copy
						var saleIDsRowIDsTemp = aThis.saleIDsRowIDs;
						
						//Init maps
						aThis.rowIDsSaleIDs = {};
						aThis.rowIDsData = {};
						aThis.saleIDsRowIDs = {};
						aThis.rowIDsSales = {};
						
						// Get the table view
						var tableView = $('#pos-past-invoices-tableview').tableview();
						
						// Empty the table
						tableView.empty();
						
						// Iterate over the sales and add them to the table
						for (let sale of sales) {
							
							// Convert the sync status to text
							var statusText = "fad fa-question-circle"
							switch (sale.syncStatus) {
								case PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT:
								statusText = "fad fa-dot-circle yellow-icon";
								break;
								case PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY:
								statusText = "fad fa-dot-circle green-icon"
								break;
								case PositiveTS.Storage.Entity.Sale.SYNC_STATUS_FAILED:
								statusText = "fad fa-dot-circle red-icon"
								break;
							}
							
							let svcCustomer = new PositiveTS.Service.CustomerClub(sale, undefined, undefined);
							let customerDisplayName = "";
							if ( svcCustomer.isCurrentCustomerExists() ){
								customerDisplayName = svcCustomer.getCustomerShortDisplayName()
							} else  if (sale?.customerName && sale?.customerName != "") {
								customerDisplayName = sale?.customerName;
							}
							
							
							let elalTicketType = "";
							if (session.pos.isElal && sale.items.length > 0) {
								let saleType = {
									emd: false,
									tkt: false,
								};
								sale.items.forEach(item => {
									if(item.elalTicketType == "EMD") {
										saleType.emd = true;
									} 
									if(item.elalTicketType == "TKT") {
										saleType.tkt = true;
									}
								})
								if(saleType.emd && saleType.tkt) {
									
									elalTicketType = i18next.t("posPastInvoices.elalTicketType.both");
								} else if(saleType.emd) {

									elalTicketType = i18next.t("posPastInvoices.elalTicketType.emd");
								} else if(saleType.tkt) {
									elalTicketType = i18next.t("posPastInvoices.elalTicketType.tkt");
								}
							}
							
							
							let salePerson = (sale.salespersonEmployeeName == null)? sale.cashierEmployeeName : sale.salespersonEmployeeName;
							let salePersonText = i18next.t("posPastInvoices.cashier")

							if (jsonConfig.getVal(jsonConfig.KEYS.showSalespersonOnPastInovices)) {
								salePersonText = i18next.t("posPastInvoices.salesperson");
								
								if (!posUtils.isBlank(sale.items[0]) && !posUtils.isBlank(sale.items[0].salespersonEmployeeName)) {
									salePerson = sale.items[0].salespersonEmployeeName;
								}
							}

							// Add the sale
							var theRow = null;
							let mobileLayout = Pinia.globalStore.mobileLayout;
							let tableRows: Array<Array<string>> =[
								[sale.invoiceSequence,'cell-inv-number',mobileLayout ? i18next.t("posPastInvoices.shortInvoiceNumber")+':' : ''],
								[this.getSaleInvoiceTypeName(sale), 'cell-invoice-type', ''],
								[posUtils.getDateStr(sale.createdAt, true),'cell-sold-at',''],
								[salePerson,'cell-sales-person',mobileLayout ? salePersonText+':' : ''],
								[session.fixedFloatTrunc(sale.totalQuantity,3),'cell-qty',mobileLayout ? i18next.t("posPastInvoices.quantity")+':' : ''],
								[session.fixedFloatTrunc(this.getSaleTotalAmount(sale),3),'cell-total',mobileLayout ? i18next.t("posPastInvoices.total")+':' : ''],
								(session.pos.hasFlights && mobileLayout ?
									[`${i18next.t("posPastInvoices.legNumber")}: ${parseInt((Pinia.flightsStore.getFlightLegById(sale.flightLegId) || {}).leg_number || 0) + 1}`, 'cell-leg-number', ''] :
									[`${mobileLayout ? i18next.t("posPastInvoices.customerName")+':' : ''}${customerDisplayName}`,'cell-customer-name',''])]
							if (session.pos.isElal) {
								tableRows.push([` ${elalTicketType}`, 'cell-ticket-type', ''])	
							}
							tableRows.push([`${mobileLayout ? i18next.t("posPastInvoices.syncStatus")+':' : ''}<i class="${statusText}"></i>`,'cell-sync-status',''])
							theRow = tableView.addRow(posUtils.sanitizeTableViewRow(tableRows));
							
							// Add it to the local array
							var rowId = theRow.attr('id');
							if (rowId != null){
								aThis.rowIDsSaleIDs[rowId] = sale.id;
								aThis.saleIDsRowIDs[sale.id] = rowId;
								aThis.rowIDsData[rowId] = sale.invoiceSequence + ';' + sale.createdAt + ';' + sale.cashierEmployeeName;
								aThis.customersNames[rowId] = sale.customerName;
								aThis.rowIDsSales[rowId] = sale;
							}
							
							//Select a row from the table
							if (aThis.selectedRowId == '') {
								aThis.selectedRowId = rowId;
								tableView.selectRow(aThis.selectedRowId);
							}else{
								if (aThis.selectedRowId == saleIDsRowIDsTemp[sale.id]){
									aThis.selectedRowId = rowId;
									tableView.selectRow(aThis.selectedRowId);
								}
							}
						}
					}
					catch(e) {
						// --- Failed to fetch past sales
						console.error(e.message);
						
						// Tell the user
						app.showAlert({
							header: i18next.t('error'),
							content: i18next.t('failedToFetchPastSales'),
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true
						}, null, null);
					}
				}
				getSelectedSaleId(){
					
					let selectedRow = $('#pos-past-invoices-tableview').tableview().getSelectedRow()[0];
					let rowID = $(selectedRow).attr('id');
					
					return this.rowIDsSaleIDs[rowID];
				}
				getSelectedSale():Storage.Entity.Sale {
					
					let selectedRow = $('#pos-past-invoices-tableview').tableview().getSelectedRow()[0];
					let rowID = $(selectedRow).attr('id');
					
					return this.rowIDsSales[rowID];
				}
				async getLastSaleTimestamp() {
					let lastSales = await appDB.sales.where('invoiceSequence').above(-1).reverse().sortBy('timestamp');
					return lastSales[0].timestamp;
				}

				getSelectedRowId(){
					
					let selectedRow = $('#pos-past-invoices-tableview').tableview().getSelectedRow()[0];
					let rowID = $(selectedRow).attr('id');
					
					return rowID;
				}

				getSaleTotalAmount(sale) {
					if(posUtils.isNullOrUndefined(sale.atmTransactionId) || sale.invoiceType == 30) {
						return sale.totalAmount;
					} else {
						let data = JSON.parse(sale.jsondata);
						if(sale.invoiceType == 2){
							return -data.withdrawn;
						}
						return data.withdrawn;
					}
				}

				getSaleInvoiceTypeName(sale) {
					let text;
					if(posUtils.isBlank(sale.atmTransactionId)) {
						text = i18next.t(`posPastInvoices.invoiceTypes.${sale.invoiceType}`);
						if(session.pos.hasFlights && sale.invoiceType == 1) {
								if(PositiveTS.Storage.Entity.Discount.isCrewDiscountSale(sale.items)) {
									text = i18next.t(`flightsCloseLegReport.saleTypes.2`);
								} else if(PositiveTS.Storage.Entity.Discount.isComplimentaryDiscountSale(sale.items)) {
									text = i18next.t(`flightsCloseLegReport.saleTypes.3`);
								} else {
									text = i18next.t(`flightsCloseLegReport.saleTypes.1`);
								}
						}

					} else {
						if(sale.invoiceType == 1){
							text = i18next.t(`posPastInvoices.invoiceTypes.30`);
						}else{
							text = i18next.t(`posPastInvoices.invoiceTypes.31`);
						}
						
					}
					return text;
				}
				displaySaleItems() {
					
					//Get the selected sale id
					var saleId = posPastInvoicesVC.getSelectedSaleId();
					
					// If there are no selected rows, finish
					if (saleId == null) {
						return;
					}
					
					var saleProxyOptions = {
						type: 				PositiveTS.Service.SaleProxy.LOCAL_TYPE,
						saleID: 	saleId
					};
					
					
					// Go to the sales-person dialog
					pNavigator.replacePage('pos-past-invoices-display-items', i18next.t('pageTitle.posPastInvoice'), '/pos-past-invoices', {initParams: {
						saleProxyOptions: saleProxyOptions
					}});
				}
				
				getPosPastInvoicesPayments() {
					//Get the selected sale id
					var saleId = posPastInvoicesVC.getSelectedSaleId();
					
					// If there are no selected rows, finish
					if (saleId == null) {
						return;
					}
					
					var saleProxyOptions = {
						type: 		PositiveTS.Service.SaleProxy.LOCAL_TYPE,
						saleID: 	saleId
					};
					
					// Go to the sales-person dialog
					pNavigator.replacePage('pos-past-invoices-display-payments', i18next.t('pageTitle.posPastInvoice'), '/pos-past-invoices', {initParams: {
						saleProxyOptions: saleProxyOptions
					}});
				}
			}
		}}}
		
		
		// Shortened for Pos Past invoices view controller
		declare var posPastInvoicesVC:PositiveTS.Application.Controllers.PosPastInvoicesViewController;
		posPastInvoicesVC = new PositiveTS.Application.Controllers.PosPastInvoicesViewController();
		//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
		PositiveTS.Application.Controllers.instances.PosPastInvoicesViewController = posPastInvoicesVC;
		