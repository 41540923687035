module PositiveTS {
export module Application {
export module Controllers {
export class PosSearchInvoicesViewController extends AbstractViewControllerTS {
	
	public rowIDsSales:any
	public customersNames:any
	public searchObject:any
	public showingResults:boolean
	public formRendered:boolean

	constructor() {
		super();
		this.rowIDsSales = {}
		this.customersNames = {}
		this.searchObject = null
		this.showingResults = false
		this.formRendered = false
	}

	// --------------------------------------------------------------------
	// Controller Life-Cycle
	// --------------------------------------------------------------------
	init(options?) {
		console.debug('init');
		let aThis = posSearchInvoicesVC;

		$('#pos-search-invoices-actions-credit').unbind();
		$('#pos-search-invoices-actions-credit').click(aThis.creditSelectedSale);
		// Initialize the tableview
		let tableView = $('#pos-search-invoices-tableview').tableview();

		//Initialize the item actions buttons
		posSearchInvoicesVC.initalizeItemActions();

		// Set the table view header
		tableView.setHeader(`
			<div class='ui-grid-c'>
				<div class='ui-block-a' style='width: 18%'><div class='ui-tableview-header-cell'>${i18next.t("posSearchInvoices.store")}</div></div>
				<div class='ui-block-d' style='width: 18%'><div class='ui-tableview-header-cell'>${i18next.t("posSearchInvoices.invoiceNumber")}</div></div>
				<div class='ui-block-d' style='width: 15%'><div class='ui-tableview-header-cell'>${i18next.t("posPastInvoices.invoiceType")}</div></div>
				<div class='ui-block-d' style='width: 13%'><div class='ui-tableview-header-cell'>${i18next.t("posSearchInvoices.date")}</div></div>
				<div class='ui-block-d' style='width: 16%'><div class='ui-tableview-header-cell'>${jsonConfig.getVal(jsonConfig.KEYS.showSalespersonOnPastInovices) ? i18next.t("posSearchInvoices.salesperson") : i18next.t("posSearchInvoices.cashier")}</div></div>
				<div class='ui-block-d' style='width: 7%'><div class='ui-tableview-header-cell'>${i18next.t("posSearchInvoices.quantity")}</div></div>
				<div class='ui-block-d' style='width: 13%'><div class='ui-tableview-header-cell'>${i18next.t("posSearchInvoices.total")}</div></div>
			</div><!-- /grid-c -->`);

		// Turn on auto-resize
		tableView.setAutoResizeOn('#pos-search-invoices');
		PositiveTS.VueInstance.$refs.posSearchInvoices.open();
	}

	async creditSelectedSale () {
		app.showLoadingMessage(i18next.t("posCreditInvoice.checkingSale"));
	
		let sale = posSearchInvoicesVC.getSelectedSale();
		if (sale == null) {
			app.hideLoadingMessage();
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('posSearchInvoices.noSaleSelected'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true,
			  })
			return;
		}
		const isCreditable = PositiveTS.Service.isCreditable;
		const saleProxyOptions = {
			type: PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
			store_id: sale.store_id,
			pos_device_id: sale.device_id,
			invoice_number: sale.invoice_number,
			invoice_type: sale.invoice_type
		};

		if (!Pinia.globalStore.isOnline && !session.pos.hasFlights) {
			app.hideLoadingMessage();
			app.showAlert({
				header: i18next.t('isCreditable.titleErrorModal'),
				content: i18next.t(`isCreditable.canNotCreditSalePosOffline`),
				continueButtonText: i18next.t('ok'),
				hideCancelButton: true
			  }, null, null);
			return;
		}

		let saleProxy = new PositiveTS.Service.SaleProxy(saleProxyOptions);

		await saleProxy.loadAllLevels()
			.then(result => {
				sale = result.length === 1 ? result[0] : null
			})

		const validateCredit = await isCreditable.validateSale(sale);

		if (!validateCredit.valid){
		  app.hideLoadingMessage();
		  app.showAlert({
			header: i18next.t('isCreditable.titleErrorModal'),
			content: validateCredit.errors.join("\n"),
			continueButtonText: i18next.t('ok'),
			hideCancelButton: true
		  }, null, null);
		  return;
		}

		const itemsAndSalesToCredit = PositiveTS.Helper.SaleHelper.getCreditedItemsAndPaymentsByFullSale(sale);	

		if(Service.Withdrawal.saleCanBeRefunded(sale)) {
			posSearchInvoicesVC.creditWithdrawal(sale)
		}

		if (isCreditable.isShowCredit(sale, itemsAndSalesToCredit).valid) {
			app.hideLoadingMessage();
			posVC.RedirectIfAuthorized(session.pos.parameterManagerApprovalCreditInvoice,
				posSearchInvoicesVC.creditSaleItem, [PositiveTS.Storage.Entity.Employee.CAN_CREDIT_PAYMENT]);
		}

		if (PositiveTS.Service.isCreditable.isShowReceiptCredit(sale, itemsAndSalesToCredit).valid) {
			posSearchInvoicesVC.creditReceiptItem();
		}

		if (PositiveTS.Service.TaxInvCredit.isTaxInvCreditable(sale, itemsAndSalesToCredit).valid) {
			posSearchInvoicesVC.creditTaxInvItem();
		}

		if (PositiveTS.Service.ShipmentInvCredit.isShipmentInvCreditable(sale, itemsAndSalesToCredit).valid) {
			posSearchInvoicesVC.creditShipmentInvItem();
		}
	}

	initalizeItemActions() {

		$('#pos-search-invoices-actions-search').click(posSearchInvoicesVC.showSearchForm);

		$('#pos-search-invoices-actions-display').click(posSearchInvoicesVC.displaySaleItems);
		$('#pos-items-tab').click(posSearchInvoicesVC.displaySaleItems);
		$('#pos-items-payments-tab').click(posSearchInvoicesVC.displaySalePayments);
		$('#pos-payments-items-tab').click(posSearchInvoicesVC.displaySaleItems);
		$('#pos-past-invoices-show-payments').click(posSearchInvoicesVC.displaySalePayments);
		$('#pos-payments-tab').click(posSearchInvoicesVC.displaySalePayments);

		$('#pos-search-invoices-actions-print').click(posSearchInvoicesVC.printSaleItem);

	}

	async doSearch() {
		console.debug('do search');
		let aThis = posSearchInvoicesVC;

		if (aThis.searchObject !== null && aThis.searchObject !== undefined && aThis.showingResults) {
			aThis.rowIDsSales = {};

			// Show loading message while we are loading data from remote server
			app.showLoadingMessage(i18next.t("loadingData"));
			try {
				let sales = await Service.Sale.SearchSales(aThis.searchObject)

				

				// Get the table view
				let tableView = $('#pos-search-invoices-tableview').tableview();
				tableView.empty();

				for (let i = 0; i < sales.length; i++) {
					let currentSale = sales[i];
					let currentSaleData = JSON.parse(currentSale.data);

					// Convert the sync status to text
					let statusText = i18next.t("unknown");
					switch (currentSale.status) {
						case PositiveTS.Storage.Entity.Sale.ERP_SYNC_STATUS_NEW:
							statusText = i18next.t("waitingToBeSent");
							break;
						case PositiveTS.Storage.Entity.Sale.ERP_SYNC_STATUS_TRANSMITTED:
							statusText = i18next.t("successfullySent");
							break;
						case PositiveTS.Storage.Entity.Sale.ERP_SYNC_STATUS_ERROR:
							statusText = i18next.t("syncFailed");
							break;
					}

					let cashierName = currentSaleData.sale.cashier_name;
					let salePersonText = i18next.t("posSearchInvoices.cashier");
					
					if (jsonConfig.getVal(jsonConfig.KEYS.showSalespersonOnPastInovices)) {
						salePersonText = i18next.t("posSearchInvoices.salesperson");

						if (!posUtils.isBlank(currentSaleData.items[0]) && !posUtils.isBlank(currentSaleData.items[0].salesperson_employee_name)) {
							cashierName = currentSaleData.items[0].salesperson_employee_name;
						}
					}

					// Add the sale
					let theRow = null;
					let mobileLayout = Pinia.globalStore.mobileLayout;
					theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
						currentSale.store.name,
						[currentSale.invoice_number,'cell-inv-number',mobileLayout ? i18next.t("posPastInvoices.shortInvoiceNumber")+':' : ''],
						[posSearchInvoicesVC.getSaleInvoiceTypeName(currentSale), 'cell-invoice-type', ''],
						[posUtils.getDateStr(currentSaleData.sale.timestamp),'cell-sold-at',''],
						[cashierName,'cell-sales-person',mobileLayout ? salePersonText+':' : ''],
						[session.fixedFloatTrunc(currentSaleData.sale.total_quantity,3),'cell-qty',mobileLayout ? i18next.t("posPastInvoices.quantity")+':' : ''],
						[session.fixedFloatTrunc(this.getSaleTotalAmount(currentSale),3),'cell-total',mobileLayout ? i18next.t("posPastInvoices.total")+':' : '']
					]));

					let rowId = theRow.attr('id');
					if (rowId != null) {
						aThis.rowIDsSales[rowId] = currentSale;
					}
				};

				app.hideLoadingMessage();

				if (sales.length == 100) {
					// Tell the user
					app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('searchInvoicesLongResults'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
				}

				if (sales.length == 0) {
					// Tell the user
					app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('searchInvoicesNoResults'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
				}

				aThis.showingResults = false;
			} 
			catch (e) {
				app.hideLoadingMessage();
				app.showAlert({
						header: i18next.t('error'),
						content: i18next.t("posSearchInvoices.errorInSearch"),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
			};
		}
	}

	showSearchForm() {
		pNavigator.pushPage('pos-search-invoices-form', i18next.t('pageTitle.posSearchInvoices'), '/pos-search-invoices', null);

	}
	resume(options) {
		console.debug('resume');
		let aThis = posSearchInvoicesVC;

		// Get the table view
		let tableView = $('#pos-search-invoices-tableview').tableview();

		if (!aThis.formRendered && aThis.searchObject === null) {
			aThis.showSearchForm();
		} else {
			aThis.doSearch();
		}
	}

	stop() {
		let aThis = posSearchInvoicesVC;

		$('#pos-search-invoices-actions-display').unbind('click');
		$('#pos-items-tab').unbind('click');
		$('#pos-items-payments-tab').unbind('click');
		$('#pos-payments-items-tab').unbind('click');
		$('#pos-payments-tab').unbind('click');
		$('#pos-past-invoices-show-payments').unbind('click')
		$('#pos-search-invoices-actions-print').unbind('click');
	}

	getSelectedSale(){

		//Get the selected row
		let selectedRow = $('#pos-search-invoices-tableview').tableview().getSelectedRow()[0];
		
		// Get the ID of the selected row
		let rowID = $(selectedRow).attr('id');

		//Return the sale id
		return this.rowIDsSales[rowID];

	}

	getSaleInvoiceTypeName(sale) {
		let text;
		let saleData = JSON.parse(sale.data);
		if(posUtils.isBlank(saleData.sale.atmTransactionId)) {
			text = i18next.t(`posPastInvoices.invoiceTypes.${sale.invoice_type}`);
			if(session.pos.hasFlights && sale.invoice_type == 1) {
				if(PositiveTS.Storage.Entity.Discount.isCrewDiscountSale(saleData.items)) {
					text = i18next.t(`flightsCloseLegReport.saleTypes.2`);
				} else if(PositiveTS.Storage.Entity.Discount.isComplimentaryDiscountSale(saleData.items)) {
					text = i18next.t(`flightsCloseLegReport.saleTypes.3`);
				} else {
					text = i18next.t(`flightsCloseLegReport.saleTypes.1`);
				}
			}
		} else {
			if(sale.invoice_type == 1){
				text = i18next.t(`posPastInvoices.invoiceTypes.30`);
			}else{
				text = i18next.t(`posPastInvoices.invoiceTypes.31`);
			}
		}
		return text;
	}

	getSaleTotalAmount(sale) {
		let data = JSON.parse(sale.jsondata)
		if(!posUtils.isBlank(data["withdrawn"])){
			if(sale.invoice_type == 2){
				return -data["withdrawn"];
			}
			return data["withdrawn"]
		} else {
			return sale.amount
		}
	}

	displaySaleItems() {
		let sale = posSearchInvoicesVC.getSelectedSale();

		if (sale === null || sale === undefined) {
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('posSearchInvoices.noSaleSelected'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true,
			  })
			console.warn('no sale selected');
			return;
		}

		let saleProxyOptions = {
			type: 				PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
			remote_sale: 	sale
		};


		// Go to the sales-person dialog
		pNavigator.replacePage('pos-past-invoices-display-items', i18next.t('pageTitle.posPastInvoice'), '/pos-search-invoices', {initParams: {
			saleProxyOptions: saleProxyOptions
		}});
	}

	displaySalePayments() {
		let sale = posSearchInvoicesVC.getSelectedSale();

		if (sale === null || sale === undefined) {
			console.error("unexpected error: selected sale was not found - this should not happen");
			return;
		}

		let saleProxyOptions = {
			type: 				PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
			remote_sale: 	sale
		};

		// Go to the sales-person dialog
		pNavigator.replacePage('pos-past-invoices-display-payments', i18next.t('pageTitle.posPastInvoice'), '/pos-search-invoices', {initParams: {
			saleProxyOptions: saleProxyOptions
		}});
	}

	async transmitTaxInvoice(){
		//Get the selected sale
		var sale = posSearchInvoicesVC.getSelectedSale();
		// If there are no selected rows, finish
		if (sale == null) {
			return;
		}
		let saleData = PositiveTS.Service.Sale.ToStorageTypeSale(sale);
		let res = await Pinia.componentsStore.openComponent( { componentName: "transmitTaxInvoiceDialog", args: [saleData, true, true] })
		if(res != 'cancel'){ // Rerender the table if the user transmitted the invoice
			posSearchInvoicesVC.showingResults = true;
			posSearchInvoicesVC.doSearch();
		}
	}

	printSaleItem() {
		let sale = posSearchInvoicesVC.getSelectedSale();

		if (sale === null || sale === undefined) {
			app.showAlert({
				header: i18next.t('error'),
				content: i18next.t('posSearchInvoices.noSaleSelected'),
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true,
			  })
			console.warn('no sale selected');
			return;
		}

		let saleData = PositiveTS.Service.Sale.ToStorageTypeSale(sale);
		let saleJsonData = posUtils.isBlank(saleData.jsondata) ? {} : JSON.parse(saleData.jsondata);
		PositiveTS.Printing.Invoice.printInvoice(saleData, saleItemHelper.unflattenSaleItems(saleData.items, true, saleJsonData.useNewPromotions), saleData.payments, false);
	}

	creditShipmentInvItem() {
		let aThis = posSearchInvoicesVC;
		app.hideLoadingMessage();
		PositiveTS.Service.ShipmentInvCredit.creditShipmentInvItem(aThis);
	}

	creditTaxInvItem() {
		let aThis = posSearchInvoicesVC;
		app.hideLoadingMessage();
		PositiveTS.Service.TaxInvCredit.creditTaxInvItem(aThis);
	}

	creditReceiptItem() {
			let aThis = posSearchInvoicesVC;
			app.hideLoadingMessage();
				// Ask the user
				app.showAlert({
					header: i18next.t('confirmReceiptCancelHeader'),
					content: i18next.t('confirmReceiptCencel'),
					continueButtonText: i18next.t('yes'),
					cancelButtonText: i18next.t("no")
				},  () => {
					aThis.creditSaleItem();
				}, null);


	}
	
	async creditWithdrawal(sale) {
		app.hideLoadingMessage();
		let saleValidation =  Service.Withdrawal.validateRefundSale(sale);
		if(saleValidation.isValid){
			Pinia.componentsStore.openComponent( {componentName:"withdrawalRefundDialog", args: [sale]});
		}else{
			app.showAlert({
				header: i18next.t('error'),
				content: saleValidation.message,
				hideCancelButton: true
			},null,null);
		}
	}

	async creditSaleItem() {
		let sale = posSearchInvoicesVC.getSelectedSale();



		if (sale == null) {
			console.error("unexpected error: selected sale was not found - this should not happen");
			return;
		}
		let saleProxyOptions = null;

		saleProxyOptions = {
			type: 		PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
			store_id: sale.store_id,
			pos_device_id: sale.device_id,
			invoice_number: sale.invoice_number,
			invoice_type: sale.invoice_type
		};


		// Go to the pos-cancel-invoice dialog
		pNavigator.replacePage('pos-credit-invoice', i18next.t('pageTitle.creditInvoice'), null, {initParams: {
			saleProxyOptions: saleProxyOptions,
			backTo: 'pos-search-invoices'
		}});
	}
		
}}}}


// Shortened for Pos Past invoices view controller
declare var posSearchInvoicesVC:PositiveTS.Application.Controllers.PosSearchInvoicesViewController;
posSearchInvoicesVC = new PositiveTS.Application.Controllers.PosSearchInvoicesViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.PosSearchInvoicesViewController = posSearchInvoicesVC;