module PositiveTS {
	export module Service {
		export module SalePaymentsService {

			interface CancelPaymentResponse {
				success: boolean,
				notCancelledPayments: any[]
			}

			export async function cancelAllPayments(): Promise<CancelPaymentResponse> {
				let salePayments: Storage.Entity.SalePayment[] = posVC.salePayments
				let salePayment = PositiveTS.Storage.Entity.SalePayment
				let response: CancelPaymentResponse = {success: true, notCancelledPayments: []}

				for (const payment of salePayments) {
					let result: CancelPaymentResponse

					switch (payment.method) {
						case salePayment.METHOD_CASH:
							result = await cancelCash()
							break;
						case salePayment.METHOD_CREDIT:
							result = await cancelCreditCard(payment)
							break;
						case salePayment.METHOD_VOUCHER:
						case salePayment.METHOD_VOUCHER_DISCOUNT:
						case salePayment.METHOD_CREDIT_VOUCHER_CHANGE:
							result = await cancelVoucher(payment)
							break;
						case salePayment.METHOD_CREDIT_VOUCHER:
							result = await cancelCreditVoucher()
							break;
						case salePayment.METHOD_CHECK:
							result = await cancelCheck()
							break;
						default:
							break;
					}

					if (!result.success) {
						result.notCancelledPayments.forEach(res => {
							response.notCancelledPayments.push(res)
						})
						response.success = false
					}

				}

				return response
			}


			async function cancelCash(): Promise<CancelPaymentResponse> {
				posVC.salePayments = posVC.salePayments.filter(payment => payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CASH)

				return { success: true, notCancelledPayments: [] }
			}

			async function cancelCreditCard(payment): Promise<CancelPaymentResponse> {
				let creditCardsData = JSON.parse(payment.data)
				let response = { success: true, notCancelledPayments: [] }
				for (const creditCardData of creditCardsData) {
					let result = await EMV.cancelPayment(creditCardData)

					if (!result.success) {
						response.notCancelledPayments.push(creditCardData)
					}
				}

				return response
			}


			async function cancelVoucher(payment): Promise<CancelPaymentResponse> {
				let vouchersData = JSON.parse(payment.data)
				let response = { success: true, notCancelledPayments: [] }

				for (const voucherData of vouchersData) {
					let result = await Service.VoucherPayment.deleteVoucherPaymentAndPersist(payment, voucherData)
					if (!result.success) {
						response.notCancelledPayments.push(voucherData)
					}
				}

				return response
			}

			async function cancelCreditVoucher(): Promise<CancelPaymentResponse> {
				posVC.salePayments = posVC.salePayments.filter(payment => payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER)

				return { success: true, notCancelledPayments: [] }

			}

			async function cancelCheck(): Promise<CancelPaymentResponse> {
				posVC.salePayments = posVC.salePayments.filter(payment => payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK)

				return { success: true, notCancelledPayments: [] }
			}

		}
	}
}


