import { defineStore } from 'pinia';

const state = {
    menusLoaded: false,
    menus: { /** [key as Lang-code]: PositiveTS.Types.MenuButton[] */ },
}

const getters = {
    currentMigvanId: () => {
        return Pinia.globalStore.effectiveMigvanId || 0;
    },
    currentActiveMenus: (state) => {
        return state.menus[Pinia.languageManagerStore.currentLang];
    },
    isFlightsGroupsMenu: () => {
        return Boolean(session.pos.hasFlights && !getters.currentMigvanId());
    },
    currentBarsetItemsAsItemsWithNotInventoryItem: (): PositiveTS.Storage.Entity.Item[] => {
        let currentBarsetItemsAsItems = Pinia.flightsStore.currentBarsetItemsAsItems;
        let itemsById = _.keyBy(currentBarsetItemsAsItems, 'id');
        for (let item of session.allItems.values()) {
            if (!item.hasInventory) {
                itemsById[item.id] = item;
            }
        }
        return Object.values(itemsById);
    },
}

const actions = {
    setMenusLoaded(payload: boolean) {
        this.menusLoaded = payload;
    },
    async loadMenusIfNeeded() {
        if (this.menusLoaded) {
            return;
        }
        let langsEnabled: string[] = [];
        if (Pinia.languageManagerStore.multiLangEnabled) {
            langsEnabled =  Pinia.languageManagerStore.langsEnabled;
        } else {
            langsEnabled = [Pinia.languageManagerStore.defaultLang]
        }
        for (const lang of langsEnabled) {
            this.menus[lang] = await PositiveTS.Service.MenuAndItemGroup.LoadMenuTree(lang);
        }
        this.setMenusLoaded(true);
    },
}


const posMenu = defineStore('posMenu', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default posMenu;