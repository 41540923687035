module PositiveTS {
export module Service {
export module Upsale {
    let currentPosUpsales = null;

    export function hasUpsales() {
        if (!session.pos.hasUpsales) {
            return false;
        }

        if (!currentPosUpsales) {
            currentPosUpsales = Storage.Entity.Upsale.getCurrentPosSordedUpsalesWithItems();
        }

        if (currentPosUpsales.length == 0) {
            return false;
        }

        return true;
    }

    function formatUpsalesTranslations(upsales: Upsale[] , lang: string): Upsale[] {
        return upsales.map(upsale => {
            let itemUpsales = _.cloneDeep(upsale.itemUpsales)
            upsale = PositiveTS.Service.Translation.translateModel(upsale, 'Upsale', lang);
            upsale.itemUpsales = itemUpsales.map(itemUpsale => {
                itemUpsale.item = PositiveTS.Service.Translation.translateModel(itemUpsale.item, 'Item', lang);
                return itemUpsale;
            });
            return upsale;
        });
    }
    

export async function showUpsales() {
        if (posVC.sale.upsaleOpened) {
            return;
        }
        let multiLangEnabled = Boolean(Pinia.languageManagerStore.multiLangEnabled);
        if(multiLangEnabled){
            currentPosUpsales = formatUpsalesTranslations(currentPosUpsales, Pinia.languageManagerStore.currentLang);
        }
        
        for (let upsale of currentPosUpsales) {
            await Pinia.componentsStore.openComponent( {componentName:"upsaleDialog", args: [upsale]});
        }
        posVC.sale.upsaleOpened = true;
        await posVC.saleUpdated();
    }
}}}
