module PositiveTS {
    export module Service {
        export module SplitSalePayment {

            const GENERIC_ITEM_CODE = "1000";
            const PARAMS_TO_MOVE_BETWEN_SALES = ['orderNumber', 'dalpakId', 'salespersonEmployeeID', 'salespersonEmployeeName'];

            export async function startSplitSaleProcess() {
                let result = await Pinia.componentsStore.openComponent( {componentName:"splitSalePaymentDialog", args: [Pinia.globalStore.splittedSalePaymentDialogInfo]});

                if (posUtils.isBlank(result) || posUtils.isBlank(result.amount) || result.amount <= 0) {
                    Pinia.globalStore.setSplittedSalePaymentDialogInfo(null);
                    return;
                }

                Pinia.globalStore.setSplittedSalePaymentDialogInfo(result);


                app.blockUIAndShowMessage(i18next.t('splitSalePayment.splittingSale'));

                let amount = result.amount;

                let item = getSplitPaymentItem();

                if (!item) {
                    app.resumeUIAndHideMessage();
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('splitSalePayment.noGenericItemFound', {item_code: GENERIC_ITEM_CODE}),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true,
                    });
                    return;
                }

                let paramsForNewSale = _.pick(posVC.sale, PARAMS_TO_MOVE_BETWEN_SALES);

                try {
                    await Service.HoldSale.holdCurrentSale(true, true);
                } catch(err) {
                    app.resumeUIAndHideMessage();
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('splitSalePayment.splittedSaleExists'),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true,
                    });

                    posVC.loadSale();
                    
                    return;
                }



                // add data to the new sale
                let jd = JSON.parse(posVC.sale.jsondata)
                jd.isSplittedSalePayment = true;
                posVC.sale.jsondata = JSON.stringify(jd);

                // Give the new sale some old sale params
                for (let param in paramsForNewSale) {
                    posVC.sale[param] = paramsForNewSale[param];    
                }

                if (!posUtils.isBlank(result.dedicatedTo)) {
                    Service.DedicatedTo.setSaleJsonData(result.dedicatedTo);
                    FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
                    posVC.setDedicatedTo();                
                }
                
                
                posVC.saleUpdated();
                await addItemToSale(item, amount, i18next.t('splitSalePayment.partialItemOrder', {orderNumber: paramsForNewSale.orderNumber}))
        

                app.resumeUIAndHideMessage();
                await posVC.goToPaymentScreen(true);
                PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod();

            }

            export async function getSplittedSaleIfExist() {
                return await appDB.sales.where({invoiceSequence: Storage.Entity.Sale.PRE_SPLITTED_SALE_INVOICE_SEQUENCE}).first()
            }

            export async function addItemToSale(item, itemPrice, description, qty = 1, isPaymentItem = false) {
                let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, {});
                saleItem.saleID = posVC.sale.id;
                saleItem.rowNumber = posVC.getRowNumber();
                saleItem.unitPrice = Number(itemPrice);
                saleItem.itemDescription = description;
                saleItem.quantity = qty;
                saleItem.noDiscount = true

                if (isPaymentItem) {
                    saleItem.isSplitSalePayment = true;
                }

                await posVC.persistNewSaleItem(saleItem, true);
                await posPaymentVC.updateAmountsIndicators(false);
                return saleItem;
            }

            export function getSplitPaymentItem() {
                return session.allItems.get(GENERIC_ITEM_CODE);
            }

            export function isSplitPaymentSale(sale) {
                if (!jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) {
                    return false;
                }

                let jd = JSON.parse(sale.jsondata);
                return posUtils.isDefined(jd.isSplittedSalePayment);
            }

            export function isBaseSplitSale(sale) {
                if (!jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) {
                    return false;
                }
                
                let jd = JSON.parse(sale.jsondata);
                return !posUtils.isBlank(jd.splittedPaymentsSaleItems) && !_.isEmpty(jd.splittedPaymentsSaleItems);
            }

            export function isSplittedSale(sale) {
                return isSplitPaymentSale(sale) || isBaseSplitSale(sale);
            }

            export function calculateSplitSaleAmount(sale, saleItems) {
                return saleItems.filter(si => si.rowNumber == 1)[0].unitPrice;
            }

            export function calculateBaseSaleSplittedPayments(sale, saleItems) {
                if ((!jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) || !isBaseSplitSale(sale)) {
                    return 0;
                }

                let jd = JSON.parse(sale.jsondata);

                let saleItemsRowNumbers = Object.keys(jd.splittedPaymentsSaleItems);
                let saleItemsByRowNumber = _.keyBy(saleItems, 'rowNumber');

                let total = 0;

                for (let rowNumber of saleItemsRowNumbers) {
                    total += saleItemsByRowNumber[rowNumber].unitPrice;
                }

                return total;
            }

            export function isPaymentItem(sale, saleItem) {
                if (!jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) {
                    return false;
                }

                if (saleItem.isSplitSalePayment) {
                    return true;
                }

                let jd = JSON.parse(sale.jsondata);

                if (posUtils.isBlank(jd.splittedPaymentsSaleItems)) {
                    return false;
                }

                return posUtils.isDefined(jd.splittedPaymentsSaleItems[saleItem.rowNumber]);
            }

            export function calculateAmountWintoutSplit(sale, saleItems, saleTotals) {
                let totalAmountWithoutSplit = saleTotals.totalAmount;
                
                if (Service.SplitSalePayment.isSplittedSale(sale)) {
                    if (Service.SplitSalePayment.isBaseSplitSale(sale)) {
                        totalAmountWithoutSplit += Service.SplitSalePayment.calculateBaseSaleSplittedPayments(sale, saleItems);
                    } else {
                        totalAmountWithoutSplit = 0;
                    }
                }

                return totalAmountWithoutSplit;
            }


            export async function afterSplittedSaleClosedActions() {
                let amountToRemove = Pinia.globalStore.splittedSalePaymentInfo.amount;
                let invoiceNumber = Pinia.globalStore.splittedSalePaymentInfo.invoiceNumber
                let item = Service.SplitSalePayment.getSplitPaymentItem();

                if (item) {
                    item.noDiscount = true;
                    let saleItem = await Service.SplitSalePayment.addItemToSale(item, amountToRemove, i18next.t('splitSalePayment.partialItemFor', {invoiceNumber: invoiceNumber}), -1, true);
                    let jd = JSON.parse(posVC.sale.jsondata);

                    if (posUtils.isBlank(jd.splittedPaymentsSaleItems)) {
                        // Key Value object that hold: 
                        // Key - The item row number(minus item - decrease the sale total with the child sale amount)
                        // Value - The invoice number of the splitted payment sale (child sale)
                        jd.splittedPaymentsSaleItems = {};
                    }

                    jd.splittedPaymentsSaleItems[saleItem.rowNumber] = invoiceNumber;

                    posVC.sale.jsondata = JSON.stringify(jd);
                    posVC.saleUpdated();
                } else {
                    console.error('Could not find general item for split sale');
                    Service.Logger.error('Could not find general item for split sale');
                }

                Pinia.globalStore.setSplittedSalePaymentInfo(null);
                Pinia.globalStore.setSplittedSalePaymentDialogInfo(null);

            }

            export function shouldRemainInPosScreenInDalpakim() {
                return jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment) && posUtils.isDefined(Pinia.globalStore.splittedSalePaymentInfo);
            }
        }
    }
}
