module PositiveTS { 
  export module Printing {
    
    export async function periodReport(data, payments, fromDateString, toDateString, 
      employeeId, employeeName, currentTitle, mode, selectedMont, detailedReport, reportBy) {
      let sales = data.sales;
      let categorySales = data.meta.primary_categories_sales;
      let itemsSales = data.meta.items_sales;
      let sourcePayments = data.meta.payments;
      let jsonDataRows = data.meta.json_data_array;
      let totalSalesAmount = data.meta.total_amount;
      let totalVatableAmount = data.meta.total_vatable_amount;
      let aThis = printer;
      let zebra = printer.jzebra;
      let company = session.company;
      let store = session.store;
      let paymentMethods = {2:"מ", 1:"א", 5: "ש", 4:"ז", 12:"מ+א", 21:"א+מ", 15:"א+ש",51:"ש+א", 25:"מ+ש", 52:"ש+מ", 99:"אחר", 
        20:"מ", 27:"הח", 24:"שובר", 42:"שובר"}

      zebra.append(aThis.allowComponents.init);
      aThis.printLogo();
      zebra.append(aThis.allowComponents.init);
      zebra.append(aThis.allowComponents.centerAlignment);
      var company_name = company.companyName;
      aThis.printLine(company_name);
      
      var store_name = store.storeName;
      aThis.printLine(store_name);
      
      if (!posUtils.isNullOrUndefinedOrEmptyString(store.registrationNum)) {
        aThis.printText(aThis.chetPeiAinMem() + `:`);
        if(session.pos.printerType != PRINTER_TYPE.sunmi){
          aThis.printText(`\t`);
        }
        aThis.printLine(store.registrationNum);
      }
      
      zebra.append(aThis.allowComponents.init);
      zebra.append(aThis.allowComponents.centerAlignment);
      zebra.append(aThis.allowComponents.bigFont);
      
      aThis.printLine(currentTitle);
      
      if (employeeId > 0 && mode == 2){
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.init);        
        aThis.printFloatingLine(`${i18next.t("periodReport.employeeName")}:`, `${employeeName}`); 
        zebra.append(aThis.allowComponents.newLine);         
      }
      
      if (selectedMont != "" && mode == 3){
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.init);   
        selectedMont = moment(selectedMont).format("MM/YY");     
        aThis.printFloatingLine(`${i18next.t("timespans.month")}:`, `${selectedMont}`); 
        zebra.append(aThis.allowComponents.newLine);         
      }
      
      if (mode != 3){
        zebra.append(aThis.allowComponents.init);
        aThis.printFloatingLine(`${i18next.t("periodReport.toDates")}:`, `${fromDateString}-${toDateString}`)
        zebra.append(aThis.allowComponents.newLine);
      }
      
      if (!Boolean(data?.meta?.has_result)){
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.init);
        zebra.append(aThis.allowComponents.centerAlignment);
        aThis.printLine(i18next.t("periodReport.noFoundSales"));
        aThis.printLineForMiddleBox();
        printer.printLineCut();
        await zebra.print();
      }else {
        let widths = [9,12,6,3,8];        
        let total = 0, totalTamash = 0, totalTaxInvoice = 0, totalVat = 0;
        let totalSalesNoVat = totalSalesAmount - totalVatableAmount; 

        if(detailedReport){
          zebra.append(aThis.allowComponents.rightAlignment);
          aThis.printHeader(i18next.t("periodReport.detailsDebitCreditSales"));
          
          aThis.printHeader(aThis.getTableRow([
            i18next.t("printing.invoiceTitle"),
            i18next.t("printing.date"),
            i18next.t("periodReport.receipt"),
            i18next.t("periodReport.so"),
            i18next.t("printing.amount")
          ], widths))

          widths = [9,12,6,3,8];    
          for (let i = 0; i < sales.length; i++) {
            let date = moment(sales[i].sold_at).format("DD/MM HH:mm")
            let num_rows = jsonDataRows.filter(function(row) { return (row.sale_id == sales[i].id) })[0].num_rows;   
            let method = jsonDataRows.filter(function(row) { return (row.sale_id == sales[i].id) })[0].method; 
            let vatAmount = jsonDataRows.filter(function(row) { return (row.sale_id == sales[i].id) })[0].vat_amount; 
            
            totalVat += vatAmount;
            if(sales[i].amount<0 && method==2){
              method = 27;  
            }
            let values = [String(sales[i].invoice_number), String(date), String(paymentMethods[method]), String(num_rows), session.fixedNumber(sales[i].amount, 2) ];
            total += sales[i].amount;  
            if(sales[i].invoice_type==9){
              totalTamash += sales[i].amount;
            }
            if(sales[i].invoice_type==7){
              totalTaxInvoice += sales[i].amount;
            }
            aThis.addRTLChar();
            aThis.printTableRow(values,widths);
          };
          aThis.printFloatingLine('',`-`.repeat(37));
          aThis.printTableRow([`${i18next.t("zxReport.totalVat")}:`, '', '', '', String(session.fixedNumber(totalVat,2))],[16,4,5,5,8]);
          aThis.printTableRow([`${i18next.t("periodReport.totalIncludeVat")}:`, '', '', '', String(session.fixedNumber(total,2))],[16,4,5,5,8]); 
        }

        
        if (reportBy == "departments") {
          let parsedCategories = JSON.parse(categorySales);
          if (parsedCategories.length > 0){
            try {
              zebra.append(aThis.allowComponents.init);
              aThis.printLine(" ");
              zebra.append(aThis.allowComponents.bold);
              zebra.append(aThis.allowComponents.smallLineSpace);
              let widths = [21,11,5], english = /^[A-Za-z]*$/;
              aThis.printHeader(i18next.t("printing.departmentSalesNet"));
              aThis.printHeader(aThis.getTableRow([i18next.t("printing.name"),i18next.t("printing.amount"),i18next.t("printing.quantity")],widths));
              
              zebra.append(aThis.allowComponents.init);
              for (let cat of parsedCategories) {
                if(cat.total != "0"){
                  let values = [cat.name.substr(0,13),session.fixedNumber(cat.total,2).substr(0,13), session.fixedNumber(cat.total_qty,1)];
                  let nameToCheck = cat.name.replace(/[\s]/g, '')
                  if (english.test(nameToCheck)){
                    widths = [6,11,20];    
                    values = [session.fixedNumber(cat.total_qty,1),session.fixedNumber(cat.total,2).substr(0,13), cat.name.substr(0,13)];
                    aThis.printTableEnglishRow(values,widths); 
                  } else{
                    widths = [21,11,5];
                    aThis.printTableRow(values,widths); 
                  } 
                }
              }
              widths = [21,11,5];
              aThis.printFloatingLine('',`-`.repeat(37));
              let allTotal = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total),0),2,true)
              let allAmount = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total_qty),0),1)
              aThis.printTableRow([`${i18next.t('printing.total')}:`, allTotal, allAmount],widths);
            }
            catch(e) {
              console.warn('could not parse primary category stats json')
            }
          }
        }else{
          let parsedItems = JSON.parse(itemsSales);
          zebra.append(aThis.allowComponents.init);
            aThis.printLine(" ");
            zebra.append(aThis.allowComponents.bold);
            zebra.append(aThis.allowComponents.smallLineSpace);
            let widths = [21,11,5], english = /^[A-Za-z]*$/;
            aThis.printHeader(i18next.t("periodReport.salesReportByItems"));
            aThis.printHeader(aThis.getTableRow([i18next.t("printing.name"),i18next.t("printing.amount"),i18next.t("printing.quantity")],widths));
            
            zebra.append(aThis.allowComponents.init);
            for (let item of parsedItems) {
              if(item.total != "0"){
                let values = [item.item_desc.substr(0,13),session.fixedNumber(item.total,2).substr(0,13), session.fixedNumber(item.total_qty,1)];
                let nameToCheck = item.item_desc.replace(/[\s]/g, '')
                if (english.test(nameToCheck)){
                  widths = [6,11,20];    
                  values = [session.fixedNumber(item.total_qty,1),session.fixedNumber(item.total,2).substr(0,13), item.item_desc.substr(0,13)];
                  aThis.printTableEnglishRow(values,widths); 
                } else{
                  widths = [21,11,5];
                  aThis.printTableRow(values,widths); 
                } 
              }
            }
            widths = [21,11,5];
            aThis.printFloatingLine('',`-`.repeat(37));
            let allTotal = session.fixedNumber(parsedItems.reduce((a,b) => a + Number(b.total),0),2,true)
            let allAmount = session.fixedNumber(parsedItems.reduce((a,b) => a + Number(b.total_qty),0),1)
            aThis.printTableRow([`${i18next.t('printing.total')}:`, allTotal, allAmount],widths);
        }
        
        aThis.printLine(" ");
        aThis.printHeader(i18next.t('printing.totalPaymeysByMethod')); 
        widths = [21,11,5];
        let totalPaymentSum = 0;
        let totalPaymentsCount =0
        aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'),i18next.t('printing.sum'),i18next.t('printing.quantity')],widths))
        for (let method in payments) {
          if( payments[method].isInXZ == 1 && payments[method].amount != 0 ) { 
            

            totalPaymentSum += payments[method].amount;
            if(payments[method].count=="0"){
              payments[method].count = "";
            }
            let  values = [payments[method].displayName, 
            session.fixedNumber(payments[method].amount), 
            String(payments[method].count)];
            aThis.printTableRow(values,widths);
            totalPaymentsCount += payments[method].count
          }
        };

        zebra.append(aThis.allowComponents.init);
        zebra.append(aThis.allowComponents.bold);
        aThis.printFloatingLine('',`-`.repeat(37));
        aThis.printTableRow([`${i18next.t('printing.totalPayments')}:`,session.fixedNumber(totalPaymentSum), String(totalPaymentsCount)],widths)
        
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.newLine);
        aThis.printHeader(i18next.t("periodReport.totalCredit"));
        widths = [21,11,5];
        aThis.printHeader(aThis.getTableRow([i18next.t("printing.name"),i18next.t("printing.amount"),i18next.t("printing.quantity")],widths))

        let cashRefundRows  = sourcePayments.filter(function(p) { return (p.invoiceType == 2 || p.invoiceType == 1) && p.method == 2 && p.amount<0 }) || [{amount:0}];
        let totalCashrefund = 0, countRows = 0 ;
        for (let i = 0; i < cashRefundRows.length; i++) {
          countRows ++;
          totalCashrefund += cashRefundRows[i].amount;
        }
        if(countRows>0){
          
          aThis.printTableRow([i18next.t("cashRefund"), session.fixedNumber(totalCashrefund),String(countRows)],widths);
        }
        let creditRefundRows  = sourcePayments.filter(function(p) { return (p.invoiceType == 2 || p.invoiceType == 1) && p.method == 1 && p.amount<0 }) || [{amount:0}];
        let totalCreditRefund = 0;
        countRows = 0 ;
        for (let i = 0; i < creditRefundRows.length; i++) {
          countRows ++;
          totalCreditRefund += creditRefundRows[i].amount;
        }
        if(countRows>0){
          aThis.printTableRow([i18next.t("periodReport.returnCreditCard"), session.fixedNumber(totalCreditRefund),String(countRows)],widths);
        }
        
        zebra.append(aThis.allowComponents.init);
        zebra.append(aThis.allowComponents.bold);
        if(totalTamash>0){
          aThis.printFloatingLine('',`-`.repeat(37));             
          aThis.printTableRow([`${i18next.t("periodReport.totalDeliveryCertificates")}:`,String(session.fixedNumber(totalTamash,2)), ''],widths)
        }
        if(totalTaxInvoice>0){
          aThis.printFloatingLine('',`-`.repeat(37));
          aThis.printTableRow([`${i18next.t("periodReport.totalDefferedTaxInvoices")}:`,String(session.fixedNumber(totalTaxInvoice,2)), ''],widths)
        }
        zebra.append(aThis.allowComponents.newLine);
        aThis.printHeader(i18next.t("periodReport.totalSalesWithoutVat"));
        aThis.printTableRow([`${i18next.t("periodReport.salesWithoutVat")}:`, '', '', '', String(session.fixedNumber(totalSalesNoVat,2))],[16,4,4,5,9]);
        
        zebra.append(aThis.allowComponents.newLine);
        printer.printLineCut();
        
        zebra.append(aThis.allowComponents.init);
        
        await zebra.print();
      }
      
      return;
    }

    // old for develop need to delete it some time
    export async function periodReportOld(data, payments, fromDateString, toDateString, 
      employeeId, employeeName, currentTitle, mode, selectedMont) {
      let sales = data.sales;
      let categorySales = data.meta.primary_categories_sales;
      let itemsSales = data.meta.items_sales;
      let sourcePayments = data.meta.payments;
      let jsonDataRows = data.meta.json_data_array;
      let totalSalesAmount = data.meta.total_amount;
      let totalVatableAmount = data.meta.total_vatable_amount;
      let aThis = printer;
      let zebra = printer.jzebra;
      let company = session.company;
      let store = session.store;
      let paymentMethods = {2:"מ", 1:"א", 5: "ש", 4:"ז", 12:"מ+א", 21:"א+מ", 15:"א+ש",51:"ש+א", 25:"מ+ש", 52:"ש+מ", 99:"אחר", 
        20:"מ", 27:"הח", 24:"שובר", 42:"שובר"}
      
      zebra.append(aThis.allowComponents.init);
      aThis.printLogo();
      zebra.append(aThis.allowComponents.init);
      zebra.append(aThis.allowComponents.centerAlignment);
      var company_name = company.companyName;
      aThis.printLine(company_name);
      
      var store_name = store.storeName;
      aThis.printLine(store_name);
      
      if (!posUtils.isNullOrUndefinedOrEmptyString(store.registrationNum)) {
        aThis.printText(aThis.chetPeiAinMem() + `:`);
        if(session.pos.printerType != PRINTER_TYPE.sunmi){
          aThis.printText(`\t`);
        }
        aThis.printLine(store.registrationNum);
      }
      
      zebra.append(aThis.allowComponents.init);
      zebra.append(aThis.allowComponents.centerAlignment);
      zebra.append(aThis.allowComponents.bigFont);
      
      aThis.printLine(currentTitle);
      
      if (employeeId > 0 && mode == 2){
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.init);        
        aThis.printFloatingLine(`${i18next.t("periodReport.employeeName")}:`, `${employeeName}`); 
        zebra.append(aThis.allowComponents.newLine);         
      }
      
      if (selectedMont != "" && mode == 3){
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.init);   
        selectedMont = moment(selectedMont).format("MM/YY");     
        aThis.printFloatingLine(`${i18next.t("timespans.month")}:`, `${selectedMont}`); 
        zebra.append(aThis.allowComponents.newLine);         
      }
      
      if (mode != 3){
        zebra.append(aThis.allowComponents.init);
        aThis.printFloatingLine(`${i18next.t("periodReport.toDates")}:`, `${fromDateString}-${toDateString}`)
        zebra.append(aThis.allowComponents.newLine);
      }
      
      if (sales.length == 0){
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.init);
        zebra.append(aThis.allowComponents.centerAlignment);
        aThis.printLine(i18next.t("periodReport.noFoundSales"));
        aThis.printLineForMiddleBox();
        printer.printLineCut();
        await zebra.print();
      }else {
        let widths = [9,12,6,3,8];        
        let total = 0, totalTamash = 0, totalTaxInvoice = 0, totalVat = 0;
        let totalSalesNoVat = totalSalesAmount - totalVatableAmount; 

        if((localStorage.getItem("detailedReports")) && (localStorage.getItem("detailedReports") == "true")){
          zebra.append(aThis.allowComponents.rightAlignment);
          aThis.printHeader(i18next.t("periodReport.detailsDebitCreditSales"));
          aThis.printHeader(aThis.getTableRow([
            i18next.t("printing.invoiceTitle"),
            i18next.t("printing.date"),
            i18next.t("periodReport.receipt"),
            i18next.t("periodReport.so"),
            i18next.t("printing.amount")
          ], widths))
          widths = [9,12,6,3,8];    
          for (let i = 0; i < sales.length; i++) {
            let date = moment(sales[i].sold_at).format("DD/MM HH:mm")
            let num_rows = jsonDataRows.filter(function(row) { return (row.sale_id == sales[i].id) })[0].num_rows;   
            let method = jsonDataRows.filter(function(row) { return (row.sale_id == sales[i].id) })[0].method; 
            let vatAmount = jsonDataRows.filter(function(row) { return (row.sale_id == sales[i].id) })[0].vat_amount; 
            
            totalVat += vatAmount;
            if(sales[i].amount<0 && method==2){
              method = 27;  
            }
            let values = [String(sales[i].invoice_number), String(date), String(paymentMethods[method]), String(num_rows), session.fixedNumber(sales[i].amount, 2) ];
            total += sales[i].amount;  
            if(sales[i].invoice_type==9){
              totalTamash += sales[i].amount;
            }
            if(sales[i].invoice_type==7){
              totalTaxInvoice += sales[i].amount;
            }
            aThis.addRTLChar();
            aThis.printTableRow(values,widths);
          };
          aThis.printFloatingLine('',`-`.repeat(37));
          aThis.printTableRow([`${i18next.t("zxReport.totalVat")}:`, '', '', '', String(session.fixedNumber(totalVat,2))],[16,4,5,5,8]);
          aThis.printTableRow([`${i18next.t("periodReport.totalIncludeVat")}:`, '', '', '', String(session.fixedNumber(total,2))],[16,4,5,5,8]); 
        }

        let parsedCategories = JSON.parse(categorySales);
        let parsedItems = JSON.parse(itemsSales);

        if ((parsedCategories.length>0) && ((!localStorage.getItem("reportsBy")) || ((localStorage.getItem("reportsBy") == "departments"))) ) {
          try { 
            zebra.append(aThis.allowComponents.init);
            aThis.printLine(" ");
            zebra.append(aThis.allowComponents.bold);
            zebra.append(aThis.allowComponents.smallLineSpace);
            let widths = [21,11,5], english = /^[A-Za-z]*$/;
            aThis.printHeader(i18next.t("printing.departmentSalesNet"));
            aThis.printHeader(aThis.getTableRow([i18next.t("printing.name"), i18next.t("printing.amount"), i18next.t("printing.quantity")],widths));
            
            zebra.append(aThis.allowComponents.init);
            for (let cat of parsedCategories) {
              if(cat.total != "0"){
                let values = [cat.name.substr(0,13),session.fixedNumber(cat.total,2).substr(0,13), session.fixedNumber(cat.total_qty,1)];
                let nameToCheck = cat.name.replace(/[\s]/g, '')
                if (english.test(nameToCheck)){
                  widths = [6,11,20];    
                  values = [session.fixedNumber(cat.total_qty,1),session.fixedNumber(cat.total,2).substr(0,13), cat.name.substr(0,13)];
                  aThis.printTableEnglishRow(values,widths); 
                } else{
                  widths = [21,11,5];
                  aThis.printTableRow(values,widths); 
                } 
              }
            }
            widths = [21,11,5];
            aThis.printFloatingLine('',`-`.repeat(37));
            let allTotal = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total),0),2,true)
            let allAmount = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total_qty),0),1)
            aThis.printTableRow([`${i18next.t('printing.total')}:`, allTotal, allAmount],widths);
          }
          catch(e) {
            console.warn('could not parse primary category stats json')
          }
        }else{
          zebra.append(aThis.allowComponents.init);
            aThis.printLine(" ");
            zebra.append(aThis.allowComponents.bold);
            zebra.append(aThis.allowComponents.smallLineSpace);
            let widths = [21,11,5], english = /^[A-Za-z]*$/;
            aThis.printHeader(`מכירות לפי פריטים`);

            aThis.printHeader(aThis.getTableRow([i18next.t("printing.name"),i18next.t("printing.amount"),i18next.t("printing.quantity")],widths));
            
            zebra.append(aThis.allowComponents.init);
            for (let item of parsedItems) {
              if(item.total != "0"){
                let values = [item.item_desc.substr(0,13),session.fixedNumber(item.total,2).substr(0,13), session.fixedNumber(item.total_qty,1)];
                let nameToCheck = item.item_desc.replace(/[\s]/g, '')
                if (english.test(nameToCheck)){
                  widths = [6,11,20];    
                  values = [session.fixedNumber(item.total_qty,1),session.fixedNumber(item.total,2).substr(0,13), item.item_desc.substr(0,13)];
                  aThis.printTableEnglishRow(values,widths); 
                } else{
                  widths = [21,11,5];
                  aThis.printTableRow(values,widths); 
                } 
              }
            }
            widths = [21,11,5];
            aThis.printFloatingLine('',`-`.repeat(37));
            let allTotal = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total),0),2,true)
            let allAmount = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total_qty),0),1)
            aThis.printTableRow([`${i18next.t('printing.total')}:`, allTotal, allAmount],widths);
        }
        
        aThis.printLine(" ");
        aThis.printHeader(i18next.t('printing.totalPaymeysByMethod')); 
        widths = [21,11,5];
        let totalPaymentSum = 0;
        let totalPaymentsCount = 0 

        aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'),i18next.t('printing.sum'),i18next.t('printing.quantity')],widths))
        for (let method in payments) {
          if( payments[method].isInXZ == 1 && payments[method].amount != 0 ) { 
            

            totalPaymentSum += payments[method].amount;
            if(payments[method].count=="0"){
              payments[method].count = "";
            }
            let  values = [payments[method].displayName, 
            session.fixedNumber(payments[method].amount), 
            String(payments[method].count)];
            aThis.printTableRow(values,widths);
            totalPaymentsCount += payments[method].count
          }
        };

        zebra.append(aThis.allowComponents.init);
        zebra.append(aThis.allowComponents.bold);
        aThis.printFloatingLine('',`-`.repeat(37));
        aThis.printTableRow([`${i18next.t('printing.totalPayments')}:`,session.fixedNumber(totalPaymentSum), String(totalPaymentsCount)],widths)
        
        zebra.append(aThis.allowComponents.newLine);
        zebra.append(aThis.allowComponents.newLine);
        aThis.printHeader(`סה"כ החזרים`);
        widths = [21,11,5];
        aThis.printHeader(aThis.getTableRow([i18next.t("printing.name"),i18next.t("printing.amount"),i18next.t("printing.quantity")],widths))

        let cashRefundRows  = sourcePayments.filter(function(p) { return (p.invoiceType == 2 || p.invoiceType == 1) && p.method == 2 && p.amount<0 }) || [{amount:0}];
        let totalCashrefund = 0, countRows = 0 ;
        for (let i = 0; i < cashRefundRows.length; i++) {
          countRows ++;
          totalCashrefund += cashRefundRows[i].amount;
        }
        if(countRows>0){
          aThis.printTableRow(["החזר מזומן", session.fixedNumber(totalCashrefund),String(countRows)],widths);
        }
        let creditRefundRows  = sourcePayments.filter(function(p) { return (p.invoiceType == 2 || p.invoiceType == 1) && p.method == 1 && p.amount<0 }) || [{amount:0}];
        let totalCreditRefund = 0;
        countRows = 0 ;
        for (let i = 0; i < creditRefundRows.length; i++) {
          countRows ++;
          totalCreditRefund += creditRefundRows[i].amount;
        }
        if(countRows>0){
          aThis.printTableRow(["החזר אשראי", session.fixedNumber(totalCreditRefund),String(countRows)],widths);
        }
        
        zebra.append(aThis.allowComponents.init);
        zebra.append(aThis.allowComponents.bold);
        if(totalTamash>0){
          aThis.printFloatingLine('',`-`.repeat(37));        
          aThis.printTableRow([`סה"כ תעודות משלוח:`,String(session.fixedNumber(totalTamash,2)), ''],widths)
        }
        if(totalTaxInvoice>0){
          aThis.printFloatingLine('',`-`.repeat(37));
          aThis.printTableRow([`סה"כ חשבוניות מס:`,String(session.fixedNumber(totalTaxInvoice,2)), ''],widths)
        }
        zebra.append(aThis.allowComponents.newLine);
        aThis.printHeader(`סה"כ מכירות ללא מע"מ`);
        aThis.printTableRow([`מכירות ללא מע"מ:`, '', '', '', String(session.fixedNumber(totalSalesNoVat,2))],[16,4,4,5,9]);
        
        zebra.append(aThis.allowComponents.newLine);
        printer.printLineCut();
        
        zebra.append(aThis.allowComponents.init);
        
        await zebra.print();
      }
      
      return;
    }
  }
  
}
