module PositiveTS {
  export module Service {
    export module NirDavidService {

      interface nirDavidBarcodeObj {
        orderId: string,
        productId: number
      }

      async function checkBarcode(orderId: string, productId: number): Promise<boolean> {
        try {
          let baseUrl = getBaseUrl()
          let url = `${baseUrl}use-ticket/${orderId}`

          let data = JSON.stringify({
            "body": JSON.stringify({ "product_id": productId }),
            "type": "POST",
            "url": url,
            "headers": _headers(),
          })

          app.showLoadingMessageDialog(i18next.t(`nirDavidService.checkingBarcode`))

          let response = await GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
          )

          let barcodeIsValid = (response?.request?.result === "true")

          app.hideLoadingMessageDialog()

          if (!barcodeIsValid) {
            await showAlert(i18next.t(`nirDavidService.barcodeIsInvalid`))
            logError(1, {orderId, productId}, JSON.stringify(response.request))
          }

          Filelog.log("mly-requests", JSON.stringify({ request: response.request.body.data, result: response.request.result }))

          return barcodeIsValid

        } catch (e) {
          app.hideLoadingMessageDialog()
          await showAlert(e)
          console.error(e)
          logError(1, {orderId, productId})
          return false
        }
      }

      async function cancelBarcode(barcode: nirDavidBarcodeObj): Promise<boolean> {
        try {
          let { orderId, productId } = barcode
          let baseUrl = getBaseUrl()
          let url = `${baseUrl}cancel-use-ticket/${orderId}`

          let data = JSON.stringify({
            "body": JSON.stringify({ "product_id": productId, "qty": 1 }),
            "type": "POST",
            "url": url,
            "headers": _headers(),
          })

          let response = await GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
          )

          let barcodeIsCancelled = (response?.request?.result === "true")

          if (!barcodeIsCancelled) {
            logError(2, barcode, JSON.stringify(response.request))
          }

          Filelog.log("mly-requests", JSON.stringify({ request: response.request.body.data, result: response.request.result }))

          return barcodeIsCancelled

        } catch (e) {
          console.error(e)
          logError(2, barcode)
          return false
        }
      }

      function _headers(): string {
        let credentials = encodeCredentials()
        return JSON.stringify({
          "Authorization": `Basic ${credentials}`,
          "Content-Type": "application/json"
        })
      }

      async function showAlert(msg: string): Promise<any> {
        return await app.showAlertDialog({
          header: i18next.t('message'),
          content: msg,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        })
      }

      function encodeCredentials(): string {
        return window.btoa(session.pos.nirDavidCredentials)
      }

      export async function checkIfSaleIsEntrance(): Promise<void> {
        let leftBarcodes = getBarcodesFromLocalStorage()

        if (leftBarcodes.length > 0) {
          await app.promiseShowAlert({
            content: i18next.t(`nirDavidService.cleanLeftBarcodesMessage`),
            continueButtonText: i18next.t(`ok`),
            hideCancelButton: true
          })

          if (!await cancelAllBarcodes()) {
            await showAlert(i18next.t(`nirDavidService.barcodeCancelErrorMessgae`))
          } else {
            clearBarcodesFromLocalStorage()
            await checkIfSaleIsEntrance()
          }

        } else {
          let saleIsEntrance = await app.promiseShowAlert({
            content: i18next.t(`nirDavidService.customerHasSignedInWebsite`),
            continueButtonText: i18next.t(`nirDavidService.yes`),
            cancelButtonText: i18next.t(`nirDavidService.no`),
            hideCancelButton: false
          })

          saleIsEntrance = saleIsEntrance == 'continue'


          Pinia.globalStore.setIsNirDavidEntranceSale(saleIsEntrance);
        }
      }

      function getBarcodesFromLocalStorage(): Array<any> {

        return JSON.parse(localStorage.getItem('nirDavidEntranceBarcodes')) || []
      }

      function addBarcodeToLocalStorage(nirDavidBarcodeObj: nirDavidBarcodeObj): void {
        let entranceBarcodes = getBarcodesFromLocalStorage()

        entranceBarcodes.push(nirDavidBarcodeObj)

        localStorage.setItem('nirDavidEntranceBarcodes', JSON.stringify(entranceBarcodes))
      }

      function clearBarcodesFromLocalStorage(): void {
        localStorage.setItem('nirDavidEntranceBarcodes', "[]")
      }

      async function cancelSaleValidation(): Promise<boolean> {
        app.hideLoadingMessage()
        let userCancelled = await app.promiseShowAlert({
          content: i18next.t(`nirDavidService.cancelBarcodesValidationMessage`),
          continueButtonText: i18next.t(`nirDavidService.yes`),
          cancelButtonText: i18next.t(`nirDavidService.no`),
          hideCancelButton: false
        })

        userCancelled = userCancelled == 'continue'

        return userCancelled
      }

      export async function cancelAllBarcodes(): Promise<boolean> {
        try {
          let barcodes = getBarcodesFromLocalStorage()
          let notCancelledBarcodes = []

          if (!barcodes || barcodes.length == 0) {
            app.hideLoadingMessageDialog()
            return false
          }

          app.showLoadingMessageDialog(i18next.t(`nirDavidService.cancellingBarcodes`))

          for (const barcode of barcodes) {
            let barcodeIsCancelled = await cancelBarcode(barcode)

            if (!barcodeIsCancelled) {
              notCancelledBarcodes.push(barcode)
            }
          }


          if (notCancelledBarcodes.length > 0) {
            app.hideLoadingMessageDialog()
            return false
          }

          app.hideLoadingMessageDialog()
          clearBarcodesFromLocalStorage()

          await showAlert(i18next.t(`nirDavidService.barcodesHasBeenCancelled`))

          return true

        } catch (e) {
          console.error(e)
          app.hideLoadingMessageDialog()
          await showAlert(i18next.t(`nirDavidService.barcodeCancelErrorMessgae`))
          return false
        }
      }

      export async function startScanningBarcodes(): Promise<boolean> {
        let entranceBarcode = jsonConfig.getVal(jsonConfig.KEYS.nirDavidEntranceItemCode)
        let entranceItemCount = 0

        posVC.saleItems.forEach(item => {
          if (entranceBarcode.find(barcode => barcode === item.barcode)) {
            entranceItemCount += item.quantity
          }
        })
        if (entranceItemCount > 0) {
          let index = 0

          while (index < entranceItemCount) {

            let result = await Pinia.componentsStore.openComponent( {componentName:"scanBarcodeDialog", args: [i18next.t("nirDavidService.restartSale"), `${i18next.t('scanBarcodeDialog.barcodeNumberOutOf', { indexNumber: (index + 1), totalBarcodes: entranceItemCount })}`]})
            let { success, barcode } = result
            barcode = barcode.split("|")

            let orderId = barcode[0]
            let productId = Number(barcode[1])

            if (!success) {
              if (getBarcodesFromLocalStorage().length == 0) {
                return false
              }
              let userCancelledSale = await cancelSaleValidation()
              if (userCancelledSale) {
                await cancelAllBarcodes()
                return false
              }
              continue
            }


            let barcodeIsValid = await checkBarcode(orderId, productId)

            if (barcodeIsValid) {
              addBarcodeToLocalStorage({ orderId, productId })
              index++
            }
          }
        }

        clearBarcodesFromLocalStorage()
        return true
      }

      function logError(messageType, barcodeObj: nirDavidBarcodeObj, serverResponse?) {
        let {deviceID} = session.pos

        switch (messageType) {
          case 1:
            console.error(`this barcode is not valid: ${JSON.stringify(barcodeObj)}`)
            Service.Logger.error(`this barcode is not valid: ${JSON.stringify(barcodeObj)}, deviceID: ${deviceID}`);
            if(serverResponse) {
              Service.Logger.error(serverResponse)
            }
            break;
          case 2:
            console.error(`this barcode was not cancelled: ${JSON.stringify(barcodeObj)}`)
            Service.Logger.error(`this barcode was not cancelled: ${JSON.stringify(barcodeObj)}, deviceID: ${deviceID}`);
            if(serverResponse) {
              Service.Logger.error(serverResponse)
            }
            break;
          default:
            break;
        }

        
      }

      function getBaseUrl(){
        return jsonConfig.getVal(jsonConfig.KEYS.nirDavidBaseUrl)
      }
    }

  }
}