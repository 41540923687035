module PositiveTS {
  export module Components {
      export module Cash {
          function cleanData() {
            let result = initData();
            for (let prop in result) {
              this[prop] = result[prop];
            }
          }
  
          function initFields() {
            let fields = {
              'amount': {
                order: 0,
                selected: true,
                allowDecimal: true,
                inEditMode: true,
                hasDecimal: false,
                type: 'number'
              }
            }
            for (let field in fields) {
              fields[field].model = field;
            }
            return fields
          }
  
          function initData() {
            return {
              internalAmount: 0,
              fields: initFields()
            }
          }

          async function verifyAndPay() {

            if (this.leftAmount <= 0) {
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('generalPaymentLeftAmountMandatoryError'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
              return;
            }
 
            if (String(this.amount).split('.')[1] && String(this.amount).split('.')[1].length > 2) {
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('amountPercisionTooBig'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
              return;
            }
            

            let cashPayment = null;
            let cashPaymentIndex = posVC.salePayments.findIndex(payment => payment.method == Storage.Entity.SalePayment.METHOD_CASH)
            if (cashPaymentIndex > -1) {
              cashPayment = posVC.salePayments[cashPaymentIndex];
            }
            else {
              cashPayment = new PositiveTS.Storage.Entity.SalePayment();
              cashPayment.saleID = posVC.sale.id;
              cashPayment.method = Storage.Entity.SalePayment.METHOD_CASH;
            }
        
            let roundAmount = 0;

            let amountToPay = this.amount;
            let baseCurrAmount, multiCurrRow;

            if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()){
              const paymentCurrency = this.paymentCurrency ? this.paymentCurrency : this.currency
              let multiCurrPayment = Service.MultiCurr.getInstance().translateAmountAndCreateRowSalePaymentData(this.amount, paymentCurrency);
              baseCurrAmount = multiCurrPayment[0];
              multiCurrRow = multiCurrPayment[1];
              amountToPay = baseCurrAmount;
            }

            if (isAmountCanBeAdded(cashPayment, amountToPay) && amountToPay != 0) {
              let prevAmount;

              if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
                cashPayment.data = Service.MultiCurr.getInstance().addRowSalePaymentData(cashPayment.data, multiCurrRow)
              }

              if (session.pos.isILSBasedCurrency) {
                if(session.fixedFloat(amountToPay, 1) != session.fixedFloat(amountToPay, 2)) { //if the inserted sum needs to be rounded
                  prevAmount = session.fixedFloat(amountToPay, 2);
                  amountToPay = session.fixedFloat(amountToPay, 1);
                }
                let originalPayAmount = prevAmount || amountToPay;
                if(originalPayAmount >= session.fixedFloat(posPaymentVC.leftAmount,1) && (session.fixedFloat(posPaymentVC.leftAmount, 1) != session.fixedFloat(posPaymentVC.leftAmount, 2)) ) { //if the last payment has to be rounded
                    roundAmount = session.fixedFloat(session.fixedFloat(posPaymentVC.leftAmount, 2) - session.fixedFloat(posPaymentVC.leftAmount, 1), 2);
                    await posVC.sale.addRoundAmountToSale(roundAmount);
                } 
              } 
            
              let origAmount = Number(cashPayment.amount);
              // Set the amount of the sale payment
              
              if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() && session.pos.hasFlights) {
                let data = JSON.parse(cashPayment.data);
                cashPayment.amount = session.fixedFloat(_.sumBy(data || [], paymentData => paymentData.lead_currency_amount));
              }
              else {
                cashPayment.amount = origAmount + amountToPay;
              }
      
              this.amount = 0;
              this.addSplittedPaymentIfNeeded();

              // Persist the sale payment
              return posVC.persistSalePayment(cashPayment, roundAmount);
            }
            
          }


          function isAmountCanBeAdded(cashPayment, amountToAdd) {
            if ( (amountToAdd - posPaymentVC.leftAmount  > session.pos.parameterMaxChangeInCash)) {
              app.showAlert({
                header: i18next.t('creditCardPaymentMandatoryHeader'),
                content:i18next.t('cashMaxChangeInCashError', {AMOUNT: session.pos.parameterMaxChangeInCash}),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
      
              return false;
            }

            if(!isValidAmount(amountToAdd)){
              return false;
            }

            let cashPaymentLimit = jsonConfig.getVal(jsonConfig.KEYS.cashPaymentLimit);
            let totalAmountInCash = cashPayment.amount + amountToAdd;

            if (cashPaymentLimit && cashPaymentLimit > 0 && Service.MultiCurr.getInstance().getPosCurrency() == 'ILS' && cashPaymentLimit < totalAmountInCash) {
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('cashPaymentReachedLimit', {cash: cashPaymentLimit}),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);

              return false;
            }
      
            return true;
          }

          function isValidAmount(amountToAdd) {
              if (amountToAdd < 0) {
              app.showAlert({
                header: i18next.t('error'),
                content:i18next.t('cashPaymentAmountMandatoryError'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
              return false;
            }

            let maxSaleAmount = Number(jsonConfig.getVal(jsonConfig.KEYS.maxSaleAmount));
            if(maxSaleAmount > 0 && amountToAdd > maxSaleAmount) {
                app.showAlert({
                  header: i18next.t('תשלום חשבונית'),
                  content:i18next.t('maxSaleAmountIs', {AMOUNT: String(maxSaleAmount)}),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
        
                return false;
            }

            return true;
          }



          export function clearCashAmount() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView;

            return new Promise(async (resolve,reject) => {
              posVC.sale.roundAmount = 0
              let cashPaymentIndex = posVC.salePayments.findIndex(payment => payment.method == Storage.Entity.SalePayment.METHOD_CASH)
              if (cashPaymentIndex > -1) {

                let currentCashPayment = posVC.salePayments[cashPaymentIndex];
                
                if (session.pos.hasFlights && Service.MultiCurr.getInstance().isMultiCurr() && currentCashPayment.amount > 0) {
                  let text = i18next.t('actionWillDeletePayments') + ': \n\n';

                  let data = JSON.parse(currentCashPayment.data);

                  for (let currPayment of data) {
                    text += `${session.fixedFloat(currPayment.paid_currency_amount, 2)} ${currPayment.paid_currency_symble} \n`
                  }

                  text += '\n' + i18next.t('areUSure');
              
                  let ans = await app.promiseShowAlert({
                    header:  i18next.t('warning'),
                    content: text,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: false,
                  });
                  
                  if(ans == "cancel"){
                    return resolve();
                  } 
                }

                posVC.salePayments.splice(cashPaymentIndex,1)
                let cashPayment = posVC.salePayments[cashPaymentIndex];
                Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
                .then(() => {                  
                  aThis.amount = 0;
                  aThis.removeSplittedPaymentIfNeeded(null);
                  aThis.fields["amount"].hasDecimal = false;
                  posPaymentVC.updateAmountsIndicators();
                  posVC.saleUpdated()
                  aThis.$refs.amount.focus();
                  resolve()
                })
              }
              else {
                aThis.amount = 0;
                aThis.fields["amount"].hasDecimal = false;
                posVC.saleUpdated()
                posPaymentVC.updateAmountsIndicators();
                aThis.$refs.amount.focus();
                resolve()
              }
      
            })
            // 
          }

          function onKeyTapped(key) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView
  
            let field = getSelectedField();
            aThis.keyPadHanlder(key,field,aThis);
          }

          function getSelectedField() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView
            for (let fieldName in aThis.fields) {
              if (aThis.fields[fieldName].selected) {
                return aThis.fields[fieldName]
              }
            }
            return undefined;
          }

          function addAmount(amountToAdd) {
            if (this.mobileLayout){
              this.$refs.amount.blur();
            }
            this.amount += amountToAdd;
            this.amount = session.fixedNumber(this.amount)
            

          }

          async function payAllRemaining() { //TODO we should not rely on data from VueX and not calculate ourselves
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView

            
            let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
            let amountLeft = totals.totalAmount - totals.totalPaid;
          
            if(!isValidAmount(amountLeft)){
                return false;
            }

            let amountToAdd = session.fixedFloat(Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(amountLeft), 2);

            if (aThis.hasFlights()) {
                const paymentCurrency = aThis.paymentCurrency ? aThis.paymentCurrency : aThis.currency

                let text = i18next.t('actionWillAddPayment') + ': \n\n';
                text += `${amountToAdd} ${paymentCurrency}`
                text += '\n\n' + i18next.t('areUSure');
            
                let ans = await app.promiseShowAlert({
                  header:  i18next.t('warning'),
                  content: text,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: false,
                });
                
                if(ans == "cancel"){
                  return;
                } 
              
            }
            
            if (this.isSplitPaymentActive()) {
              let splitPaymentAmount = this.getSplitPaymentAmount()
              amountToAdd = this.getSplitPaymentAmount() > 0 ? Math.min(amountToAdd, splitPaymentAmount) : amountToAdd
            }

            this.amount = amountToAdd;
            this.verifyAndPay()
          }

           async function refreshView() {
             if (PositiveTS.Service.SplitSalePayment.isSplittedSale(posVC.sale)) {
              let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              this.amount = totals.totalAmount - totals.totalPaid;  
             } else {
               this.amount = 0;
             }

             this.setSplitPaymentAmountIfNeeded();

            await PositiveTS.VueInstance.$nextTick();
            if(!Pinia.globalStore.mobileLayout){
              this.$refs.amount.focus();
            }
          }
          
          export function create() {
              var cashComponent = {
                  template: JST.cash(),
                  mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
                  methods: {
                      cleanData: cleanData,
                      onKeyTapped: onKeyTapped,
                      clearCashAmount: clearCashAmount,
                      verifyAndPay: verifyAndPay,
                      payAllRemaining: payAllRemaining,
                      addAmount: addAmount,
                      refreshView: refreshView,
                      selectMe(e:FocusEvent) {
                        (<HTMLInputElement>e.target).select();
                      },
                      hasFlights: () => session.pos.hasFlights,
                  },
                  data: initData,
                  computed: Object.assign(
                    {},
                    {
                      amount: {
                        get: function() { //we do not use arrow functions here intentionally to let vue.js handle the value of 'this'
                          if (this.internalAmount == null) {
                            this.internalAmount = 0;
                          }
                          return Number(this.internalAmount)
                        },  
                        set: function(value) { //we do not use arrow functions here intentionally to let vue.js handle the value of 'this'
                          this.internalAmount = Number(value);
                        }
                      }
                    },
                  ),
                  setup(){

                    const globalStore = Pinia.useGlobalStore();
                    const {currency,portraitMode,paymentCurrency,mobileLayout,leftAmount} = Pinia.storeToRefs(globalStore);
                    const globalStoreProps = {currency,portraitMode,paymentCurrency,mobileLayout,leftAmount}
      
                    return {...globalStoreProps}
                  },
                  mounted: function() {
                    $('.pos-payment-cash-money-img').off('mousedown');
                    $('.pos-payment-cash-money-img').on('mousedown', () => false);
                  }
              }
  
              VueApp.component('cash-view',cashComponent)
          }
      }
  }}  
