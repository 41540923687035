module PositiveTS {
export module Service {
export module XReport {
  const _url = '/x_reports/get_store_payments';

  export async function getStorePayments() {
    app.showLoadingMessage(i18next.t('reports.generatingXReport'));
    let response = await FetchReq.textReq(_url, 'get')
    
    if (!response.response.ok) { 
      throw new Error("SERVER_INTERNAL_ERROR"); 
    }

    let paymentDict = JSON.parse(response.result).payments; 
      
    let _ret:Storage.Entity.SalePayment[] = [];
    for (let i=0;i<paymentDict.length;i++){
      let entity = new PositiveTS.Storage.Entity.SalePayment();
      entity.importFromObject(paymentDict[i]);
      entity.invoiceType = paymentDict[i].invoiceType;
      _ret.push(entity)
    }
    app.hideLoadingMessage()
    return _ret;
  }

  export async function isStoreXReport(forCurrentPosOnly: boolean):Promise<boolean> {
    if (forCurrentPosOnly) { 
      return false;
    }

    if ( !(PositiveTS.Reachability.isOnline && session.pos.isShowCombinedXReport) ) { 
      return false;
    }

    let response = await app.promiseShowAlert({
      header: i18next.t('reports.xReport'),
      content: i18next.t('reports.selectXReport'),
      continueButtonText: i18next.t('reports.forStore'),
      cancelButtonText: i18next.t('reports.forPos'),
      yellowCancel: true
    })
    
    return (response === "continue");
  }
  
  export async function fetchSalePayments(forCurrentPosOnly: boolean) {
    app.hideLoadingMessage()
    let isStoreXReport = await Service.XReport.isStoreXReport(forCurrentPosOnly)
    let payments: Storage.Entity.SalePayment[];
    if (isStoreXReport) {
      payments = await Service.XReport.getStorePayments();
    }
    else {
      let salePaymentModel = new Storage.Entity.SalePayment();
      payments = await salePaymentModel.fetchByZNumber(PositiveTS.Storage.Entity.Sale.NULL_Z_REPORT, jsonConfig.getVal(jsonConfig.KEYS.isDalpakim));
    }
    return payments;
  }

}}}
