module PositiveTS {
  export module Service {
     export module Check {

       export class SoapActionParams {
         transactionStringXml: string = "";
         SecurityPassword: string = "";
         constructor(transactionStringXml, securityPassword) {
           this.transactionStringXml = transactionStringXml;
           this.SecurityPassword = securityPassword;
         }
       }
     }}}
