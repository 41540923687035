

module PositiveTS {
   export module Service {

      const HAKAFA_CUSTOMER_PTRFIX = "לקוח"
      const PREPARATION_INSTRUCTION_ID = "$$COMMENT$$"
      const DELIVERY_API_NAME = 'wolt'
      const _WoltServiceLogger = "wolt-orders";

      const DELIVERY_TYPES = {
         TA: 'takeaway',
         DELIVERY: 'homedelivery',
      }

      const PAYMENT_TYPE = {
         CASH: 0,
         CREDIT_CARD: 1,
         CIBUS: 2,
         TENBIS: 3,
      }

      const CHARGE_TYPES = {
         DISCOUNT: 0,
         DELIVERY_PRICE: 1,
         TIP: 2,
      }

      const VOUCHER_CODE = 205000;

      interface WoltOrder {
         id: string,
         attribution_id: string,
         consumer_comment: string,
         consumer_name: string,
         type: string,
         order_number: string,
         created_at: string,
         delivery: {status: string, time: string, type: string, fee: {amount: number, currency: string}},
         items:  Array<WoltOrderItem>,
         modified_at: string,
         order_status: string,
         pickup_eta: string,
         preorder: any,
         price: {
            amount: number,
            currency: string,
         },
         restaurant: {
            id: string,
            name: string,
         },
         review: {
            "attributions": Array<any>,
            "comment": string,
            "score": number,
         },
      }

      interface WoltOrderItem {
         base_price: {
            amount: number,
            currency: string,
         },
         category: {
            id: string,
            name: string,
         },
         count: number,
         id: string,
         name: string,
         options: Array<WoltOrderItemOption>,
         pos_id: string,
         price: {
            amount: number,
            currency: string,
         },
         total_price: {
            amount: number,
            currency: string,
         },
         unit_price: {
            amount: number,
            currency: string,
         },
      }

      interface WoltOrderItemOption {
         count: number,
         id: string,
         name: string,
         pos_id: string,
         price: {
            amount: number,
            currency: string,
         },
         value: string,
         value_pos_id: string,
      }
      export class WoltDeliveryConvertService extends BaseOrderConvertService {
         public apiData;

         getCustomerNumber () {
            return `${HAKAFA_CUSTOMER_PTRFIX} wolt`
         }

         getOrderNumber(order: WoltOrder) {
            return order.order_number;
         }

         getOrderTotal(order: WoltOrder) {
            let orderTotalAmount = this.convertAgorotToShkalim(order.price.amount)
            if (order.delivery.fee && order.delivery.fee.amount){
               orderTotalAmount += this.convertAgorotToShkalim(order.delivery.fee.amount)
            }

            return session.fixedFloat(orderTotalAmount)
         }

         async getOrderJsonData(order: WoltOrder, sale) {
            let deliveryTypeStr, deliveryStatus;

            if (order.delivery.type == DELIVERY_TYPES.DELIVERY) {
               deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalWolt,
               deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
            } else if (order.delivery.type == DELIVERY_TYPES.TA) {
               deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalWoltTA;
               deliveryStatus = PositiveTS.Storage.Entity.Sale.TA_PAID;
            } else {
               let error = "Unknown delivery type"
               PositiveTS.Service.Delivery.failedDeliveriesLogger(error,order.order_number,_WoltServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing)
               throw new Error(error)
            }

            let hakafaCustomer = await Service.Hakafa.getHakafaCustomerByCustomerNumber(this.getCustomerNumber());
            if(!hakafaCustomer){
               let error = "Wolt Hakafa customer missing";
               PositiveTS.Service.Delivery.failedDeliveriesLogger(error,order.order_number,_WoltServiceLogger,Delivery.FailedDeliveryType.HakafaCustomer);
               throw new Error(error);
            }


            let jsondata = JSON.parse(sale.jsondata)
            jsondata["promotions"] = [];
            jsondata["delivery"] = {
               deliveryType: deliveryTypeStr,
               status: deliveryStatus,
               serviceData: {
                  name: 'WOLT',
                  type: DELIVERY_API_TYPES.WOLT
               },
               ordererName: order.consumer_name,
               ordererPhone: '',
               ordererCallerRemarks: order.consumer_comment,
               ordererDeliveryRemarks: null,
               ordererDeliveryCutlery: null,
               orderHasBeenPaid: true,
               orderTime: new Date(order.created_at),
               address: "",
               externalId: order.id
            };

            jsondata["customer"] = {
               clubName: "HAKAFA",
               s_id_number: hakafaCustomer.tz,
               s_first_name: hakafaCustomer.first_name,
               s_last_name: hakafaCustomer.last_name,
               dt_birth_date: null,
               s_phone_number_1: '',
               customer_group_id: null,
               amount: 0,
               merakez: "",
               db_id: hakafaCustomer.id,
               is_offline: false,
               is_price_alut: false,
               printer_type: 0,
               discount_percent: 0,
               customer_number: hakafaCustomer.customer_number,
               obligo: null,
               address: null,
               is_tamash_customer: false,
               is_cibus_tenbis: true
            }

            return jsondata;
         }

         getOrderSaleItems(order: WoltOrder, sale) {
            let items = [];

            for (let orderItemIndex in order.items) {
               let orderItem = order.items[orderItemIndex];
               let saleItem = this.convertOrderItemToSaleItem(orderItem, sale, parseInt(orderItemIndex) + 1);
               let childRowNumber = 0

               for (let optionIndex in (orderItem.options || [])) {
                  let option = orderItem.options[optionIndex];
                  
                  if (option.pos_id == PREPARATION_INSTRUCTION_ID){
                     saleItem.hasPreparationInstructions = true
                     if (posUtils.isNullOrUndefinedOrEmptyString(saleItem.selectedPreparationInstructions)){
                        saleItem.selectedPreparationInstructions = JSON.stringify([option.value])
                     }else {
                        let preparationInstructions = JSON.parse(saleItem.selectedPreparationInstructions)
                        preparationInstructions.push(option.value)
                        saleItem.selectedPreparationInstructions = JSON.stringify(preparationInstructions)
                     }
                     continue
                  }
                  
                  for (let i = 0; option.count > i; i++){
                     let childSaleItem = this.convertOrderItemToSaleItem(option, sale, 20001 + childRowNumber, true);

                     childSaleItem.level = 1;
                     // childSaleItem.parentItemId = orderItem.id;//check it
                     childSaleItem.parentRowNumber = 1;
                     childSaleItem.itemGroupId = -1;
                     childSaleItem.parent = saleItem;
                     childSaleItem.quantity = 1;

                     saleItem.unitPrice += childSaleItem.priceNetoAfterDiscounts
                     saleItem.priceNetoAfterDiscounts += childSaleItem.priceNetoAfterDiscounts * saleItem.quantity
                     saleItem.children.push(childSaleItem);
                     childRowNumber += 1
                  }
               }

               if (orderItem.options && orderItem.options.length > 0) {
                  saleItem.hasGroups = true;
               }

               items.push(saleItem);
            }

            let chargesItems = this.getOrderChargeItems(order, sale, items.length);

            let allItems = items.concat(chargesItems);

            if (order.delivery.type == DELIVERY_TYPES.TA) {
               allItems.push(this.getTaSaleItem(sale, allItems.length + 1));
            }

            return allItems;
         }

         getOrderChargeItems(order, sale, rowNumberStartIndex) {
            let items = [];

            let chargeConvertFunctions = {
               [CHARGE_TYPES.DISCOUNT]: this.convertDiscountChargeToItem.bind(this),
               [CHARGE_TYPES.DELIVERY_PRICE]: this.convertDeliveryPriceChargeToItem.bind(this),
               [CHARGE_TYPES.TIP]: this.convertTipChargeToItem.bind(this),
            }

            for (let chargeIndex in order.charges) {
               let charge = order.charges[chargeIndex];
               if (chargeConvertFunctions[charge.type]) {
                  items.push(chargeConvertFunctions[charge.type](charge, sale, rowNumberStartIndex + parseInt(chargeIndex) + 1));
               } else {
                  let error = 'Unknown charge type'
                  PositiveTS.Service.Delivery.failedDeliveriesLogger(error,order.order_number,_WoltServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing)
                  throw new Error(error)
               }
            }

            return items;
         }

         convertDeliveryPriceChargeToItem(charge, sale, rowNumber) {
            let currentItem = null;

            if (jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode))
            }

            if (posUtils.isBlank(currentItem)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
               currentItem.description = charge.desc;
            }

            let deliverySaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });


            deliverySaleItem.unitPrice = this.convertAgorotToShkalim(charge.amount);
            deliverySaleItem.rowNumber = rowNumber;
            deliverySaleItem.saleID = sale.id;
            deliverySaleItem.priceNetoAfterDiscounts =  deliverySaleItem.unitPrice;

            return deliverySaleItem;
         }

         getTaSaleItem(sale, rowNumber) {
            let currentItem = null;

            if (jsonConfig.getVal(jsonConfig.KEYS.taItemCode)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode))
            }

            if (posUtils.isBlank(currentItem)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
               currentItem.description = 'TA';
            }

            let deliverySaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });

            deliverySaleItem.unitPrice = 0;
            deliverySaleItem.rowNumber = rowNumber;
            deliverySaleItem.saleID = sale.id;
            deliverySaleItem.priceNetoAfterDiscounts = 0;

            return deliverySaleItem;
         }

         convertDiscountChargeToItem(charge, sale, rowNumber) {
            let discountItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
            let discountSaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(discountItem, { size: 'null', color: 'null', barcode: null });

            discountSaleItem.itemDescription = String(charge.desc).trim();
            discountSaleItem.unitPrice = -(this.convertAgorotToShkalim(charge.amount));
            discountSaleItem.rowNumber = rowNumber;
            discountSaleItem.saleID = sale.id;
            discountSaleItem.priceNetoAfterDiscounts =  discountSaleItem.unitPrice;
            return discountSaleItem;
         }


         convertTipChargeToItem(charge, sale, rowNumber) {
            let tipItem;
            let changeDesc = false;

            if (Service.Tip.isHasTips()) {
               tipItem = Service.Tip.getTipsItem();
            } else {
               tipItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
               changeDesc = true;
            }

            let tipSaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(tipItem, { size: 'null', color: 'null', barcode: null });

            if (changeDesc) {
               tipSaleItem.itemDescription = String(charge.desc).trim();
            }

            tipSaleItem.unitPrice = this.convertAgorotToShkalim(charge.amount);
            tipSaleItem.rowNumber = rowNumber;
            tipSaleItem.saleID = sale.id;
            tipSaleItem.priceNetoAfterDiscounts = tipSaleItem.unitPrice;

            return tipSaleItem;
         }


         convertOrderItemToSaleItem(orderItem, sale, rowNumber, isOption = false): Storage.Entity.SaleItem {
            let currentItem = session.allItems.get(isOption ? orderItem.value_pos_id : orderItem.pos_id)

            if (!Boolean(currentItem)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
            }

            let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });
            saleItem.quantity = orderItem.count
            saleItem.barcode = saleItem.itemCode
            saleItem.saleID = sale.id;
            let unitPrice = isOption ? orderItem.price.amount : orderItem.base_price.amount
            saleItem.unitPrice = this.convertAgorotToShkalim(unitPrice)
            saleItem.originalUnitPrice = this.convertAgorotToShkalim(unitPrice)
            saleItem.hasPreparationInstructions = false
            saleItem.rowNumber = rowNumber;
            saleItem.invoiceSequence = sale.invoiceSequence
            saleItem.children = [];
            let totalPrice = (isOption ? orderItem.price.amount : orderItem.base_price.amount)
            if (!isOption){
               totalPrice = orderItem.count * totalPrice
            }
            saleItem.priceNetoAfterDiscounts = this.convertAgorotToShkalim(totalPrice)

            if (currentItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)) {
               saleItem.itemDescription = String(isOption ? orderItem.value : orderItem.name).trim();
               saleItem.itemDescription = String(posUtils.removeEmojisFromText(saleItem.itemDescription)).trim();
            }

            return saleItem;
         }

         getSalePayments(sale: Storage.Entity.Sale, order: WoltOrder): Storage.Entity.SalePayment[] {
            let paymentsByType = {}
            paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER] = new Storage.Entity.SalePayment();
            paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].method = Storage.Entity.SalePayment.METHOD_VOUCHER;
            paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].saleID = sale.id;
            paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].amount = sale.totalAmount
            let paymentData = [];

            let voucherData = {
               amount: sale.totalAmount,
               barCode: "",
               creditType: DELIVERY_API_NAME,
               voucher_type_id: VOUCHER_CODE,
               voucherType: PositiveTS.Storage.Entity.Voucher.DYNAMIC_DELIVERY_API,
               smartVoucherType: null,
               valuTypeId: null,
               praxellManpik: null,
               mutipassInit: null,
               isTamashCustomer: true,
               isDebitInvoice: false
            }

            paymentData.push(voucherData);
            paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].data = JSON.stringify(paymentData);

            return Object.values(paymentsByType)
         }

         convertAgorotToShkalim(amount) {
            return (amount / 100)
         }
      }
   }
}

