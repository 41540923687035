module PositiveTS {
  export module Components {
      export module CreditCard {
          function cleanData() {
            let result = initData();
            for (let prop in result) {
              this[prop] = result[prop];
            }
          }
  
          function initFields() {
            let fields = {
              'amount': {
                order: 0,
                selected: true,
                allowDecimal: true,
                inEditMode: false,
                type: 'number'
              },
              'payments': {
                order: 1,
                allowDecimal: false,
                selected: false,
                inEditMode: false,
                type: 'number',
                disabled: true
              },
              'firstPaymentInternal': {
                order: 2,
                allowDecimal: true,
                selected: false,
                inEditMode: false,
                type: 'number',
                disabled: true
              },
              'phone': {
                order: 3,
                selected: false,
                allowDecimal: false,
                inEditMode: false,
                type: 'string'
              }
            }
            for (let field in fields) {
              fields[field].model = field;
            }
            return fields
          }
  
          function initData() {
            let types = []
  
            for (let ccpt in session.creditCardPaymentTypes) {
              let ccptType = session.creditCardPaymentTypes[ccpt]
              ccptType.selected = false
              if (ccptType.ccTypeID == "1") {
                ccptType.selected = true
              }
              types.push(ccptType);
            }
            return {
              creditCardPaymentTypes: types,
              paymentsTypeSelected: false,
              cardNumber: "",
              cvv: "",
              expDate: "",
              payments: 1,
              amount: 0,
              isDuringEmvRequest: false,
              phone: "",
              manualConfirmationNumber: null,
              fields: initFields(),
              clearImg: `${(<any>window).images_path}/close.png`,
              creditPayments: [],
              firstPaymentInternal: "",
              visibleCreditCardPaymentTypes: jsonConfig.getVal(jsonConfig.KEYS.visibleCreditCardPaymentTypes),
              allowManualFirstPayment: jsonConfig.getVal(jsonConfig.KEYS.allowManualFirstPayment),
              disableManualCreditCardPayment: jsonConfig.getVal(jsonConfig.KEYS.disableManualCreditCardPayment)
            }
          }
  
          function selectCreditType(selectedType) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            for (let ccpt of aThis.creditCardPaymentTypes) {
              ccpt.selected = false;
  
              if (ccpt.ccTypeID == selectedType.ccTypeID) {
                ccpt.selected = true
                aThis.paymentsTypeSelected = Boolean(ccpt.allowPayments)
                if (!aThis.paymentsTypeSelected) {
                  aThis.payments = 1
                  aThis.fields['payments'].disabled = true
                  aThis.selectField('phone')
                }
                else {
                  aThis.fields['payments'].disabled = false
                  aThis.selectField('payments')
                }
              }
            }
          }
  
          function selectField(fieldName) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            for (let field in aThis.fields) {
              aThis.fields[field].selected = false
              aThis.fields[field].inEditMode = true
              aThis.fields[field].hasDecimal = false
              if (field == fieldName) {
                aThis.fields[field].selected = true
              }
            }
          }
  
          function onKeyTapped(key) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
  
            let field = getSelectedField();
            if (field) {
  
              if (field.inEditMode) {
                let modelStr = String(aThis.$data[field.model])
                switch (key) {
                  case '.':
                    if (field.allowDecimal) {
                      field.hasDecimal = true
                    }
                  break;
                  case 'bksp':
                    if (field.allowDecimal && (field.hasDecimal || modelStr.indexOf('.') >= 0)) {
                      modelStr = modelStr.slice(0, -1);
                      field.hasDecimal = false
                    }
                    else {
                      modelStr = modelStr.slice(0, -1);
                    }
                    if (field.type == 'number') {
                      aThis.$data[field.model] = Number(modelStr)
                    }
                    else {
                      aThis.$data[field.model] = modelStr
                    }
                  break;
                  default:
                    let decimalPrecision = session.pos.parameterDecimalPrecision || 2;
                    if (field.allowDecimal && (field.hasDecimal || modelStr.indexOf('.') >= 0)) {
                        if (modelStr.indexOf('.') === -1) {
                          modelStr += '.'
                        }
                        else {
                          if(modelStr.indexOf('.') + (decimalPrecision + 1) == modelStr.length) {
                            modelStr = modelStr.slice(0, -1);
                          }
                          if (key == '0') {
                            field.hasDecimal = false
                          }
                        }
                    }
  
                    modelStr += key
                    if (field.type == 'number') {
                      aThis.$data[field.model] = Number(modelStr)
                    }
                    else {
                      aThis.$data[field.model] = modelStr
                    }
                }
  
              }
              else {
                //set it in edit mode and change value to be the key
                field.inEditMode = true
                if (field.type == 'number') {
                  aThis.$data[field.model] = Number(key)
                }
                else {
                  aThis.$data[field.model] = key
                }
              }
            }
          }
  
          function getSelectedField() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            for (let fieldName in aThis.fields) {
              if (aThis.fields[fieldName].selected) {
                return aThis.fields[fieldName]
              }
            }
            return undefined;
          }
  
          function getRegularPaymentToRender(paymentData) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
                  //Build the display of the payments field in the table
                  let creditPaymentType = session.creditCardPaymentTypes[Number(paymentData.payment_type)];
                  let displayPayments = '';
  
                  if (creditPaymentType.allowPayments){
                    displayPayments = paymentData.payments_count + " תש'"
                  }else{
                    displayPayments = creditPaymentType.ccTypeName;
                  }
                  aThis.creditPayments.push({
                    amount: session.fixedNumber(Number(paymentData.amount), 2),
                    cardNumber: `${paymentData.card_number.substr(-4,paymentData.card_number.length)}****`,
                    paymentsTxt: displayPayments,
                    Uid: paymentData.Uid,
                    Xfield: paymentData.Xfield
                  })
  
          }
  
          function getEmvPaymentToRender(paymentData:PositiveShared.EmvOutput) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
  
            let creditPaymentType =  session.creditCardPaymentTypes[paymentData.internalPaymentType];
            let displayPayments = '';
            
            paymentData = Object.assign(new PositiveShared.EmvOutput(),paymentData);
  
            
            if (posUtils.isDefined(creditPaymentType?.allowPayments)) {
              displayPayments = `${paymentData.payments_count} ${i18next.t('paymentsShort')}`
            } else {
              displayPayments = creditPaymentType?.ccTypeName;
            }

            let pan = paymentData.Pan || '';

            if (aThis.hasFlights()) {
              pan = '*'.repeat(pan.length - 4) + pan.substr(-4);
            }

            aThis.creditPayments.push({
              amount: session.fixedNumber(Number(paymentData.Amount)/100, 2) + (aThis.hasFlights() ? ` ${Service.MultiCurr.getInstance().getCurrencySymbolEMV(paymentData)}` : ''),
              cardNumber: pan,
              paymentsTxt: displayPayments,
              Uid: paymentData.Uid,
              Xfield: paymentData.Xfield
            })
  
          }
  
          function getPaymentToRender() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            aThis.creditPayments = []
            let creditPayment = posVC.salePayments.filter(sp => sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT)[0]
            if (creditPayment) {
              try {
                let data = JSON.parse(creditPayment.data)
                for (let paymentData of data) {
                  if(paymentData.isGateway){
                    continue;
                  }
                  session.pos.isEmv ? getEmvPaymentToRender(paymentData) : getRegularPaymentToRender(paymentData)
                }
              }
              catch (e) {
                console.error(e);
                aThis.creditPayments = []
              }
            }
  
          }
  
          function onPaymentDeleted(creditPayment, paymentToDelete, data, index) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;

            data.splice(index,1)
  
            if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
              let creditLeadCurrencyAmount = parseFloat(paymentToDelete.creditLeadCurrencyAmount);
              creditPayment.amount -= creditLeadCurrencyAmount;

              if (session.pos.hasFlights) {
                creditPayment.amount = session.fixedFloat(_.sumBy(data,'creditLeadCurrencyAmount'));
              }
            } else {
              creditPayment.amount -= paymentToDelete.amount;
            }
  
            creditPayment.data = JSON.stringify(data);
            // Persist the sale payment
            Service.FullSale.saveCurrentSale()
            .then(async () => {
                aThis.removeSplittedPaymentIfNeeded();
                await refreshView()
                posPaymentVC.updateAmountsIndicators()
                app.hideLoadingMessage()
            })
          }

          function paymentNotFoundInPinpad(response){
            console.error("tried to cancel payment but payment was not found on pinpad")
            return response.success == false && response.result.ResultCode == "10048" && response.result.AshStatus == "443"
          }
  
          function deleteEmvPayment(creditPayment, paymentToDelete:PositiveShared.EmvOutput, data, index) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            let multiCurrenciesData = _.pick(paymentToDelete, ['creditCurrency','currencyRate', 'creditLeadCurrencyAmount'])

            //TODO: can I check if payment was with chargable card?
            app.showLoadingMessage(i18next.t('cancelChargePleaseWait'));
            Service.EMV.cancelPayment(paymentToDelete)
            .then(response => {
              if (response.success || (paymentNotFoundInPinpad(response)) ) {
                let result = Object.assign(response.result, multiCurrenciesData)
                onPaymentDeleted(creditPayment, result, data, index)
              }
              else {
                app.hideLoadingMessage()
                aThis.showAlertGeneric(response.error)
                return;
              }
            })
          }
  
          function deleteRegularPayment(creditPayment, paymentToDelete, data, index) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            if (paymentToDelete.card_number.substring(0,2) == '72') {
              console.warn('ניסיון למחוק תשלום של כרטיס נטען - לא ניתן לעשות זאת');
              return;
            }
            app.showLoadingMessage(i18next.t('cancelChargePleaseWait'));
            PositiveTS.Helper.SaleHelper.returnCreditCard(paymentToDelete,
              function (returnedArray) {
                onPaymentDeleted(creditPayment, paymentToDelete, data, index)
            }, function (err) {
              app.hideLoadingMessage();
              console.log(err);
              aThis.showAlertGeneric(err)
  
            });
          }
  
          function deleteCreditPayment(payment) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            console.debug(payment);
            if(session.pos.hasFlights && Pinia.globalStore.isOnline) {
              app.hideLoadingMessage();
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('emv.cancelPaymentNotAvailable'),
                continueButtonText: i18next.t('ok'),
                hideCancelButton: true
              }, null, null);
              return ;
            }

            app.showAlert({
              header: i18next.t('confirmSalePaymentItemDeleteHeader'),
              content: i18next.t('confirmSalePaymentItemDelete'),
              continueButtonText: i18next.t('remove'),
              cancelButtonText: i18next.t('cancel')
            }, function () {
              
              let creditPayment = posVC.salePayments.filter(sp => {return sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT})[0]
              let paymentToDelete = _.cloneDeep(payment)
              if (creditPayment) {
                let data = JSON.parse(creditPayment.data);
                let paymentToDeleteIndex = data.findIndex(paymentData =>  paymentData.Uid == paymentToDelete.Uid && paymentData.Xfield == paymentToDelete.Xfield)
                paymentToDelete = data[paymentToDeleteIndex]
                if (paymentToDelete && paymentToDeleteIndex>-1) {
                  paymentToDelete.isEmv ? deleteEmvPayment(creditPayment,paymentToDelete,data,paymentToDeleteIndex) :
                                          deleteRegularPayment(creditPayment, paymentToDelete, data, paymentToDeleteIndex)
                }
              }
            },null);
  
          }
  
          async function refreshView () {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView

            if (aThis.isSplitPaymentActive()) {
              aThis.setSplitPaymentAmount();
            } else {
              let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amountLeft = totals.totalAmount - totals.totalPaid;
                
              if (aThis.amount != amountLeft) {
                aThis.amount = session.fixedFloat(Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(amountLeft), 2)
              }
            }

            aThis.selectFirstPaymentMethod();

            selectField('amount')
            getPaymentToRender()
            await PositiveTS.VueInstance.$nextTick()

            
          }
  
          function verifyAmount() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            aThis.amount = Number(aThis.amount)
  
            return new Promise((resolve,reject) => {
              let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);
  
              if (aThis.amount <= 0) {
                aThis.showAlertGeneric(i18next.t('newCreditCard.AMOUNT_MUST_BE_BIGGER_THAN_ZERO'),reject)
              } else if (String(aThis.amount).split('.')[1] && String(aThis.amount).split('.')[1].length > 2) {
                aThis.showAlertGeneric(i18next.t('amountPercisionTooBig'),reject)
              } else if (aThis.amount > amountLeft) {
                if (Service.MultiCurr.getInstance().isMultiCurr() && 
                    aThis.amount <= session.fixedFloat(Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(posVC.getTotalLeftToPay()), 2)
                  ){
                  resolve() 
                  return 
                }
                aThis.showAlertGeneric(i18next.t('generalPaymentLeftAmountMandatoryError'),reject)
              }
              else {
                resolve()
              }
            })
  
          }
  
          function verifyPayments() {
            return new Promise((resolve,reject) => {
              let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
              if (aThis.paymentsTypeSelected) {
                let creditTypeID = aThis.creditCardPaymentTypes.filter((ccpt) => {return ccpt.selected === true})[0].ccTypeID
                let numberOfPaymentsAuthorizedForAmount =
                paymentLimit.creditCardNumberOfPaymentsAuthorizedForAmount( aThis.amount, creditTypeID !== 3 );
                if (aThis.payments == 0) {
                  aThis.showAlertGeneric(i18next.t('creditCardPaymentPaymentsMandatoryError'),reject)
                } else if (aThis.payments > session.pos.parameterMaxCreditCardPayments) {
                  aThis.showAlertGeneric(
                    i18next.t('creditCardPaymentPaymentsMaxMandatoryError', {COUNT: session.pos.parameterMaxCreditCardPayments}),
                      reject)
                } else if (aThis.payments > numberOfPaymentsAuthorizedForAmount) {
                  aThis.showAlertGeneric(i18next.t('creditCardPaymentPaymentsMaxForAmountError', {COUNT: paymentLimit.lastQueryOfCreditCardNumberOfPaymentsAuthorizedForAmount}), 
                    reject)
                }
                else if(aThis.firstPaymentInternal >= aThis.amount){
                  aThis.showAlertGeneric(i18next.t('creditCardPaymentFirstPaymentIsBiggerThanAmount'),reject)
                } else if(aThis.amount < jsonConfig.getVal(jsonConfig.KEYS.minAmountForPayments)) {
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('newCreditCard.minAmountForPaymentsNotMet', {amount: jsonConfig.getVal(jsonConfig.KEYS.minAmountForPayments)}),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, () => { reject() }, null);
                }
                else {
                  resolve()
                }
              }
              else {
                resolve()
              }
            })
          }
  
          function verify() {
            return verifyAmount()
            .then(verifyPayments)
          }
  
          function clearOldData() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
  
            aThis.cardNumber = ""
            aThis.cvv = ""
            aThis.expDate = ""
            aThis.manualConfirmationNumber = null
          }
  
          function getSignature(salePayment, amountToDisplay = null) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            return new Promise((resolve,reject) => {
              if (!Boolean(session.pos.storeSignature)) {
                resolve(salePayment)
              }
              else {
                app.showLoadingMessage(i18next.t("newCreditCard.NEED_SIGNATURE"))
                let requestId = String(new Date().getTime())
                PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                  {
                    portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
                    getSignature: true,
                    amount:String(amountToDisplay || aThis.amount),
                    requestId: requestId
                  },
                  "card_reader")
                .then(response => {
                  let responseObj = response.request.result;
                  console.log(response.request.result);
                  if (response.request.result.signatureData === "" || response.request.result.statusCode != 0) {
                    return getSignature(salePayment, amountToDisplay)
                    .then(resolve);
                  }
                  else {
                    let data = JSON.parse(salePayment.data);
                    data[data.length-1].signature = response.request.result.signatureData;
                    salePayment.data = JSON.stringify(data);
                    Service.FullSale.saveCurrentSale()
                    .then(resolve)
                  }
                })
  
              }
            })
          }
  
          function getMissingSignaturesIfNeeded() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;
            let shouldGetSignature = false;
            return new Promise((resolve,reject) => {
              if (!Boolean(session.pos.storeSignature)) {
                resolve()
              }
              else {
                let payments = posVC.salePayments;
                let creditPayments = payments.filter((payment) => {return (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT) });
                for (let salePayment of creditPayments) {
                  if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1) {
                    let data = JSON.parse(salePayment.data);
                    if (!data[data.length-1].signature || data[data.length-1].signature == "") {
                      shouldGetSignature = true
                      aThis.getSignature(salePayment,data[data.length-1].amount)
                      .then(() => {
                        app.hideLoadingMessage()
                        resolve()
                      })
                    }
                  }
                }
                if (!shouldGetSignature) {
                  resolve()
                }
              }
            })
  
  
          }
  
          function inTheAir(){
            return session.pos.hasFlights && !Pinia.globalStore.isOnline
          }

          async function emv(mode, verificationCode = null) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;
            console.log(`emv button click: ${aThis.amount}`)
            if (aThis.isDuringEmvRequest) {
              console.log("in the middle of request - exiting")
              return;
            }
            aThis.isDuringEmvRequest = true;
            let selectedPaymentType = aThis.creditCardPaymentTypes.filter((ccpt) => ccpt.selected)[0]
  
            if (verificationCode == null && !posUtils.isNullOrUndefinedOrEmptyString(aThis.manualConfirmationNumber)) {
              verificationCode = aThis.manualConfirmationNumber;
            }
  
            try {
              let response = await Service.EMV.payWithCreditCard(aThis.amount, mode, selectedPaymentType, aThis.payments, verificationCode,"1",aThis.firstPayment)
              if (response.success) {
                await savePaymentAndRenderView({salePayment: Service.CreditCardPayment.getSalePayment(),cardDetails:response.result })
                aThis.isDuringEmvRequest = false;
              }
              else {
                if (Service.EMV.manualConfirmationNumberRequired(response.result)) {
                  if(session.pos.hasFlights) {
                    aThis.showAlertGeneric(i18next.t('emvAshStatusCodes.004')); 
                    aThis.isDuringEmvRequest = false;
                    return ;
                  } else {
                    aThis.isDuringEmvRequest = false;
                    let verifcationCodeResult;

                    try {
                      verifcationCodeResult = await askForVerificationCode()
                    } catch(err) {
                      aThis.isDuringEmvRequest = false;
                      console.error(err);
                      return;
                    }
                    try {
                      aThis.isDuringEmvRequest = false;
                      let emvRes = await emv(mode, verifcationCodeResult)
                      return emvRes;
                    }
                    catch(error) {
                      aThis.showAlertGeneric(error)
                      aThis.isDuringEmvRequest = false;
                    }
                  }
                }
                else if (Service.EMV.isTimeoutResponse(response.result)) {
                  aThis.showAlertGeneric(i18next.t('newCreditCard.timeout')); 
                  aThis.isDuringEmvRequest = false;
                }
                else if (Service.EMV.oldFileExists(response.result)) {
                  if(inTheAir()){
                    aThis.showAlertGeneric(`${response.error}\n${i18next.t('emv.blacklistNotUpdatedAndOffline')}`)
                    Pinia.globalStore.setEmvRecoveryNeededWhenOnline(true)
                    return;
                    }
                    else{
                      aThis.showAlertGeneric(`${response.error}\n${i18next.t('emv.clickOKToCallShva')}`,async () => {
                        await Service.EMV.callShvaWithoutTransmittingTransactions();
                      })
                    }
                  
                  aThis.isDuringEmvRequest = false;
                }
                else if (Service.EMV.oldTranNotEmpty(response.result)) {
                  aThis.showAlertGeneric(response.error + ".\nיש לבצע סגירת יום לפני שניתן להמשיך לבצע עסקאות אשראי נוספות.")
                  aThis.isDuringEmvRequest = false;
                  if(inTheAir()){
                    Pinia.globalStore.setEmvRecoveryNeededWhenOnline(true)
                    return;
                  }
                }
                else {
                  aThis.showAlertGeneric(response.error)
                  if(Service.EMV.ERRORS_THAT_JUSTIFIES_RECOVERY.includes(Number(response.result.ResultCode))){
                    Pinia.globalStore.setEmvRecoveryNeeded(true)
                    PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod()
                  }
                  aThis.isDuringEmvRequest = false;
                  return;
                }
              }
            }
            catch(err) {
              console.error(err);
              aThis.isDuringEmvRequest = false;
              Pinia.globalStore.setEmvRecoveryNeeded(true)
              PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod()

              let errorMessage = i18next.t('emv.PINPAD_NOT_CONNECTED');

              if (!posUtils.isBlank(Service.EMV.getIpAddress())) {
                errorMessage = i18next.t('emv.PINPAD_NOT_CONNECTED_IP');
              }

              aThis.showAlertGeneric(errorMessage);
              
              throw err;
            }
          }
  
          function axiumPay() {
            if (session.isAndroid && typeof(Android) !== "undefined") {
              let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;
              let result = Android.axiumPayment(aThis.amount,"null");
              app.hideLoadingMessage();
              console.debug(result);
              console.debug(JSON.parse(result));
  
              let parsedResult = JSON.parse(result);
              parsedResult.resultCode = parseInt(parsedResult.resultCode);
              
              if (parsedResult.resultCode < 0) {
                aThis.showAlertGeneric("An error occoured. Please try again")
              }
              else if (parsedResult.resultCode == 0) {
                // aThis.showAlertGeneric("Success")
                let salePayment = new Storage.Entity.SalePayment()
                salePayment.amount = 0;
                salePayment.saleID = posVC.sale.id;
                salePayment.method = Storage.Entity.SalePayment.METHOD_CREDIT;
                salePayment.data = JSON.stringify([]);
                let cardDetails = {
                  ticket: parsedResult.ticket,
                  payment_type: 1,
                  amount: aThis.amount,
                  card_number: "******",
                  isAxium: true,
                }
                savePaymentAndRenderView({salePayment: salePayment,cardDetails: cardDetails});
              }
              else {
                switch (parsedResult.resultCode) {
                  case 1:
                    aThis.showAlertGeneric(`Error:  ${parsedResult.resultCode} - format error.`);
                  case 9:
                    aThis.showAlertGeneric(`Error:  ${parsedResult.resultCode} - POS not connected.`);
                  case 20:
                    aThis.showAlertGeneric(`${parsedResult.resultCode} - Transaction aborted or declined.`);
                  case 23:
                    aThis.showAlertGeneric(`${parsedResult.resultCode} - Transaction refused.`);
                }
              }
            }
            //
          }
            
          
  
          function msrAutomatic() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;
            clearOldData()
            app.showLoadingMessage(i18next.t("newCreditCard.PASS_CARD_AUTOMATIC_MESSAGE"));
            if (session.isAndroid && typeof(Android) !== "undefined") {
              setTimeout(() => aThis.axiumPay(),0)
            }
            else {
              let requestId = String(new Date().getTime())
              PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                {
                  portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
                  inputType: "4", //MSR Only
                  amount:String(aThis.amount*100),
                  requestId: requestId
                },
                "card_reader")
              .then(result => {
                  app.hideLoadingMessage()
                  parseAutomaticResponse(result,requestId)
              })
            }
          }
  
          function msrManual() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;
            clearOldData()
            app.showLoadingMessage(i18next.t("newCreditCard.PASS_CARD_MANUAL_MESSAGE"))
            let requestId = String(new Date().getTime())
            PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                {
                  portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
                  inputType: "8", //Keypad Only
                  amount:String(aThis.amount*100),
                  requestId: requestId
                },
                "card_reader")
              .then(result => {
                  app.hideLoadingMessage()
                  parseManualResponse(result, requestId)
              })
          }
  
          function getCardAutomatic() {
            verify()
            .then(() => {
              if (session.pos.isEmv) {
                emv(Service.EMV.PINPAD_DEAL)
              }
              else {
                msrAutomatic()
              }
            }).catch((e) => {console.error(e)})
  
          }
  
          function getCardManual() {
            verify()
            .then(() => {
              if (session.pos.isEmv) {
                emv(Service.EMV.PHONE_DEAL)
              }
              else {
                msrManual()
              }
            })
          }
  
          function parseAutomaticResponse(response,requestId) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            return new Promise( (resolve,reject) => {
              let responseObj = JSON.parse(response.request.result.jsonResponseStr)
              let statusCode = response.request.result.statusCode
              if (responseObj.ResponseGetData && responseObj.ResponseGetData.RequestId != requestId) {
                aThis.showAlertGeneric("שגיאה קריטית בקורא הכרטיסים.\n יש לנסות להעביר שוב כרטיס.\nבמידה והשגיאה חוזרת על עצמה יש לפנות לתמיכה.",reject)
                return;
              }
              if (statusCode != 0) {
                displayStatusError(statusCode, responseObj)
                .then(reject);
              }
              else {
                //parse response and goto payment!
                aThis.cardNumber = responseObj.ResponseGetData.Track2;
                createPaymentAndPay()
                .then(resolve)
              }
            })
  
          }
  
          function parseManualResponse(response, requestId) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            return new Promise( (resolve,reject) => {
              let responseObj = JSON.parse(response.request.result.jsonResponseStr)
              let statusCode = response.request.result.statusCode
              if (responseObj.ResponseGetData && responseObj.ResponseGetData.RequestId != requestId) {
                aThis.showAlertGeneric(("שגיאה קריטית בקורא הכרטיסים.\nיש לנסות להעביר שוב תשלום.\nבמידה והשגיאה חוזרת על עצמה יש לפנות לתמיכה."),reject)
                return;
              }
              if (statusCode != 0) {
                displayStatusError(statusCode, responseObj)
                .then(reject);
              }
              else {
                //parse response, verify it, and goto payment!
                let str = responseObj.ResponseGetData.Track2;
                aThis.cardNumber = str.slice(1,str.indexOf("T"))
                aThis.cvv = str.slice(str.indexOf("U")+1,str.length)
                aThis.expDate = str.slice(str.indexOf("T")+1,str.indexOf("T")+3) + '/' + str.slice(str.indexOf("T")+3,str.indexOf("T")+5)
                return verifyExpDate()
                .then(createPaymentAndPay)
                .then(resolve)
  
              }
            })
  
          }
  
          function verifyExpDate() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            let error = false
            return new Promise((resolve,reject) => {
              if (aThis.expDate === "" || !aThis.expDate) {
                aThis.showAlertGeneric(i18next.t('newCreditCard.EXP_DATE_MISSING'),reject)
                error = true
              }
              let expDate = moment(aThis.expDate, 'MM/YY').endOf('month')
              if (expDate <= moment(new Date())) {
                aThis.showAlertGeneric(i18next.t('creditCardPaymentExpDateOverMandatoryError'),reject)
                error = true
              }
  
              if (!error) {
                resolve()
              }
            })
          }
  
          function displayStatusError(statusCode, responseObj) {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            return new Promise((resolve,reject) => {
              switch (statusCode) {
                case 10000:
                  aThis.showAlertGeneric(i18next.t('newCreditCard.RETVAL_TIMEOUT'),resolve);
                case 10001:
                case 10002:
                  aThis.showAlertGeneric(i18next.t('newCreditCard.RETVAL_ERROR_OPENING_SERIAL_PORT'),resolve);
                  break;
                case 10003:
                  console.debug(responseObj);
                  if (responseObj && responseObj.ResponseGetData && responseObj.ResponseGetData.ResultCode) {
                    if (responseObj.ResponseGetData.ResultCode == "210") {
                      resolve()
                    }
                    else {
                      aThis.showAlertGeneric(getMsrError(responseObj.ResponseGetData.ResultCode),resolve)
                    }
                  }
                  else {
                    aThis.showAlertGeneric(i18next.t('newCreditCard.GENERAL_ERROR'),resolve)
                  }
                  break;
                default:
                  console.error(`general error in card reader - code is ${statusCode}`)
                  aThis.showAlertGeneric(i18next.t('newCreditCard.GENERAL_ERROR'),resolve)
              }
            })
  
          }
  
          function getMsrError(resultCode) {
            console.warn('reader input problem. result code was:' + resultCode)
            switch (resultCode) {
              case '201':
              case '202':
              case '203':
              case '204':
              case '205':
              case '206':
              case '207':
              case '208':
              case '209':
              case '210':
              case '211':
              case '212':
                return i18next.t(`newCreditCard.msr[${resultCode}]`)
              default:
                return i18next.t("newCreditCard.msr.generalError")
            }
  
          }
  
          function createPaymentAndPay() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
  
            app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
            return new Promise((resolve,reject) => {
              PositiveTS.Service.CreditCard.promiseGetEffectiveBalance(aThis.cardNumber,aThis.amount)
              .then(function( rAmount ){
                //if card is chargable - update the amount to the max available amount
                aThis.amount = rAmount
              })
              .then(pay)
              .then(resolve)
              .catch(err => {
                app.hideLoadingMessage();
                if (err) {
                  console.error(err);
                  aThis.showAlertGeneric(err || i18next.t('creditCardPaymentError'),reject)
                }
              });
            })
  
          }
  
          async function updateViewAndGlobalState() {
  
            await refreshView()
            await posPaymentVC.updateAmountsIndicators()
          }
  
          export async function savePaymentAndRenderView(result: {salePayment:Storage.Entity.SalePayment, cardDetails:any}) {
            try{
              let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amountLeft = totals.totalAmount - totals.totalPaid
              if (amountLeft <= 0) {
                app.showLoadingMessage(i18next.t('closingSale'));
              }
              let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
              let salePayment = await Service.CreditCardPayment.savePayment(result,aThis.amount,aThis.paymentCurrency,aThis.amountFullNumber);
              await getSignature(salePayment)
  
              aThis.addSplittedPaymentIfNeeded();
              
              return updateViewAndGlobalState()
            }catch(err){
              app.hideLoadingMessage();
              return Promise.reject(err)
            }

          }
  
          function askForVerificationCode() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
            return new Promise((resolve,reject) => {
              app.hideLoadingMessage()
              let dg = new PositiveTS.Dialogs.InputDialog()
              let options = new PositiveTS.Dialogs.InputDialogOptions()
              options.header = 'נדרש אישור מחברת האשראי'
              options.description = 'נא הקלד מספר אישור שהתקבל מחברת האשראי או לחץ על ביטול'
              options.showCancelButton = true
              dg.open(options)
              .then(result => {
                aThis.manualConfirmationNumber = result
                resolve(result)
              })
              .catch(err => {reject('פעולה בוטלה')})
            })
  
          }
  
          function getCardDetails() {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
  
            var creditCurrency = undefined;
            var creditLeadCurrencyAmount = undefined;
            // if MC supported get posPaymentVC.PaymentCreditSelectedCurrency.getVal()
            if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
              creditCurrency = posPaymentVC.PaymentCreditSelectedCurrency.getVal();
              creditLeadCurrencyAmount = PositiveTS.Service.MultiCurr.getInstance().checkIfNeedTranslateAmountAndReturn(aThis.amount);
            }
  
            let cardDetails = {
              'amount'    : aThis.amount,
              'cardNumber'   : aThis.cardNumber,
              'creditType' : aThis.creditCardPaymentTypes.filter((ccpt) => {return ccpt.selected === true})[0].ccTypeID,
              'experDate' : aThis.expDate || "",
              'phoneNumber': aThis.phone || "",
              'ccv' : aThis.cvv || "",
              'cardPayments'   : String(aThis.payments),
              'confNumber' : aThis.manualConfirmationNumber || "",
              'creditCurrency': creditCurrency,
              'creditLeadCurrencyAmount': creditLeadCurrencyAmount
            }
  
            return cardDetails;
  
          }
  
          function pay():Promise<any> {
            let aThis = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView
  
  
            return new Promise((resolve,reject) => {
  
              app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
              //create the cardDetails object and call debit credit card...
              debitCreditCard(Service.CreditCardPayment.getSalePayment(), getCardDetails())
              .catch(result => { //TODO: this should not be catch... it is a normal response - not an execption
                //if code is 003 - ask for confirmation number
                if (result && result.return_code && result.return_code === "003") {
                  return askForVerificationCode()
                  .then(() => {
                    app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
                    return debitCreditCard(Service.CreditCardPayment.getSalePayment(), getCardDetails())
                  })
                }
                else {
                  throw result.error_message
                }
              })
              .then(savePaymentAndRenderView)
              .then(app.hideLoadingMessage)
              .then(resolve)
              .catch(error => {
                app.hideLoadingMessage();
                console.error(error)
                aThis.showAlertGeneric(error,reject)
              });
            })
  
  
          }
  
          function debitCreditCard(salePayment, cardDetails) {
            return new Promise(function(resolve,reject) {
  
              PositiveTS.Service.CreditCard.payWithCreditCard(cardDetails.amount,cardDetails.cardNumber,cardDetails.creditType,cardDetails.ccv,
                cardDetails.cardPayments,cardDetails.experDate,cardDetails.phoneNumber,cardDetails.creditCurrency,cardDetails.confNumber, cardDetails.tz)
              .then(function (result) {
                  if (result.success === true) {
                    resolve({ salePayment: salePayment, cardDetails: result });
                  } else {
                    reject(result);
                  }
              })
              .catch(function(error) {
                reject(error)
              })
            });
          }
  
          export function create() {
              var newCreditCardComponent = {
                  template: JST.newCreditCard(),
                  mixins: [Mixins.modalsMixin, Mixins.paymentViewMixin],
                  methods: {
                      cleanData: cleanData,
                      selectCreditType: selectCreditType,
                      refreshView: refreshView,
                      selectField: selectField,
                      getCardAutomatic: getCardAutomatic,
                      msrManual: msrManual,
                      msrAutomatic: msrAutomatic,
                      axiumPay: axiumPay,
                      emv: emv,
                      getSignature: getSignature,
                      getCardManual: getCardManual,
                      createPaymentAndPay: createPaymentAndPay,
                      onKeyTapped: onKeyTapped,
                      deleteCreditPayment: deleteCreditPayment,
                      getMissingSignaturesIfNeeded: getMissingSignaturesIfNeeded,
                      selectFirstPaymentMethod(){
                          if(this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0){
                            selectCreditType(this.creditCardPaymentTypes[0])
                          }
                      },
                      isMultiCurrency() {
                        return Service.MultiCurr.getInstance().isMultiCurr();
                      },
                      hasFlights() {
                        return session.pos.hasFlights;
                      },
                  },
                  data: initData,
                  computed: {
                    buttonCreditCardPaymentTypes () {
                      return this.creditCardPaymentTypes.filter((ccpt) => {
                        return !this.visibleCreditCardPaymentTypes || !this.visibleCreditCardPaymentTypes[this.paymentCurrencyForCreditcardTypes] || this.visibleCreditCardPaymentTypes[this.paymentCurrencyForCreditcardTypes] && this.visibleCreditCardPaymentTypes[this.paymentCurrencyForCreditcardTypes][ccpt.ccTypeID]
                      })
                    },

                    firstPayment:{ get: function(){
                      if(this.fields['payments'].disabled){
                        return this.amount
                      }
                      else if(Number(this.firstPaymentInternal) == 0){
                        return "";
                      }
                      else{
                          return Number(this.firstPaymentInternal)
                      }
                    },
                    set:function(newValue){
                      this.firstPaymentInternal = newValue
                    }},
                    isEmv() {
                      return session.pos.isEmv
                    },
                    amountFullNumber () {
                      return this.paymentCurrency ? Service.MultiCurr.getInstance().translateAmount(this.amount, this.paymentCurrency) : Number(this.amount);
                    },
                    paymentCurrencyForCreditcardTypes(){
                      return Pinia.globalStore.paymentCurrency || Pinia.globalStore.currency
                    },
                  },
                  setup(){

                    const globalStore = Pinia.useGlobalStore();
                    const {portraitMode,paymentCurrency,mobileLayout} = Pinia.storeToRefs(globalStore);
                    const globalStoreProps = {portraitMode,paymentCurrency,mobileLayout}
      
                    return {...globalStoreProps}
                  },
                  watch : {
                    paymentCurrency () {
                      let amount = Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(posVC.getTotalLeftToPay());
                      this.amount = session.fixedFloat(amount, 2);
                    },
                  }
                    
                    
              }
  
              VueApp.component('new-credit-card-view',newCreditCardComponent)
          }
      }
  }
  }
  
