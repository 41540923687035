module PositiveTS {
  export module Service {
    export module PeriodReport {

      const url = '/PeriodReport?';

      export function getReport(fromDate:Date, toDate:Date, deviceId, employeeId, employeeName, selectedMonth, detailedReport, reportBy){
        return FetchReq.jsonReq(url + jQuery.param( {fromDate:fromDate, toDate:toDate, deviceId:deviceId, employeeId:employeeId, selectedMonth:selectedMonth, detailed_report: detailedReport, report_by: reportBy, new_report: true}), 'get')
        .then( (response) => {
           if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
           return response.result;
         });
      }

    }
  }
}
