module PositiveTS { 

    export module Printing {
    export class CashRefoundSlip extends AFactory {
        private _I18N = {
          FOR_DOCUMENT: "החזר מזומן עבור מסמך:",
          CASH_AMOUNT: "סך מזומן להחזר",
        };



        constructor(sale: any, company: any, salePayments: any) {
            super(sale, company, salePayments);
        }

        run(): void{
            if (!this._isCashReturnInvoice()) { return; }

            var aThis = this._aThis;
            var zebra = this._zebra;
            var sale = this._sale;



            this.printHeaderForSlipType(this._I18N.FOR_DOCUMENT);

            zebra.append(aThis.allowComponents.init);
            aThis.printFloatingLineWithPrice(this._I18N.CASH_AMOUNT,
              session.fixedNumber(this._totalPayed()));
            this.lineFeed();
            aThis.printLine("חתימה:     _________________");
            this.lineFeed();
            aThis.printLine("טלפון:     _________________");
            this.lineFeed();
            printer.printLineCut();
        }

        private _isCashReturnInvoice(): boolean {
            var sale = this._sale;
            return (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE &&
                this._totalPayed() > 0);

        }

        private _totalPayed():number{
          return PositiveTS.Helper.SaleHelper.getTotalPaidByMethod(this._salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_CASH);
        }


    }
  }
}
