(function( $ ){
	var pickerTemplate = '<div class="month-picker-container"><div class="month-picker-plus-button">+</div><div class="month-picker-value"><span class="month-picker-value-month"></span>/<span class="month-picker-value-year"></span></div><div class="month-picker-minus-button">-</div></div>';

	var methods = {
		init: function (options) {
			let date = new PositiveTS.DateUtils();
			let defaultOptions = {
				initialMonth: parseInt(date.getMonth(), 10),
				initialYear: parseInt(String(date.getFullYear()), 10),
				changed: function (newMonth, newYear) {}
			};

			return this.each(function(){
				this.settings = $.extend(defaultOptions, options);

				let $this = $(this);
				let inputEl = $this.find('input');

				// Insert the picker
				let $picker = $(pickerTemplate);
				if ($this.find('.month-picker-container').length === 0) {
					$this.append($picker);
					inputEl.hide();

					$this.data('month', this.settings.initialMonth);
					$this.data('year', this.settings.initialYear);
					$this.find('.month-picker-value').text($this.data('month') + '/' + $this.data('year'));

					$picker.find('.month-picker-plus-button').click({ input: this }, function (event) {
						methods.step.apply(event.data.input, [1]);
					});

					$picker.find('.month-picker-minus-button').click({ input: this }, function (event) {
						methods.step.apply(event.data.input, [-1]);
					});
				}
			});
		},
		/**
		 * Focus on the input field
		 */
		step: function (direction) {
			return $(this).each(function() {
				var $this = $(this);
				var data = $this.data();

				data.month = data.month + direction*1;
				if (data.month > 12) {
					data.year = data.year + 1;
					data.month = 1;
				} else if (data.month < 1) {
					data.year = data.year - 1;
					data.month = 12;
				}

				$this.data(data);
				methods['draw'].apply(this);
				this.settings.changed.apply(this, [data.month, data.year]);
			});
		},
		draw: function () {
			var data = $(this).data();
			$(this).find('.month-picker-value').text(data.month + '/' + data.year);
		},
		/**
		 * Returns the current month and year
		 */
		getMonth: function () {
			return $(this).data('month');
		},
		getYear: function () {
			return $(this).data('year');
		},
		/**
		 * Set month and year
		 */
		setMonth: function (month) {
			var data = $(this).data();
			data.month = month;
			this.settings.changed.apply(this, [data.month, data.year]);
			$(this).data(data);
			methods['draw'].apply(this);
		},
		setYear: function (year) {
			var data = $(this).data();
			data.year = year;
			this.settings.changed.apply(this, [data.month, data.year]);
			$(this).data(data);
			methods['draw'].apply(this);
		},
		setDefault: function () {
			$(this).data('month', this[0].settings.initialMonth);
			$(this).data('year', this[0].settings.initialYear);
			methods['draw'].apply(this);
		}
	};

	$.fn.monthPicker = function(method) {
		if (methods[method]) {
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof method === 'object' || ! method) {
			return methods.init.apply(this, arguments);
		} else {
			$.error('Method ' +  method + ' does not exist on inputField');
		}
	};
})( jQuery );