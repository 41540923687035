module PositiveTS {
export module Components {
export module PosPayment {
export module PluxeeView {

  export function create() {
    var c = {
      template:  JST.PluxeeView(),
      mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
      methods: {
        selectField: selectField,
        onKeyTapped: onKeyTapped,
        getSelectedField: getSelectedField,
        clearTapped: clearTapped,
        verifyAndPay: verifyAndPay,
        clearOldData: clearOldData,
        deleteCreditPayment: deleteCreditPayment,
        refreshView: refreshView,
        getCibusCompany: getCibusCompany,
        swipeCard: async function(){
          app.showLoadingMessage("העבר כרטיס");
          let cardNumber = await Service.EMV.swipe(this.amount);
          if(cardNumber){
            this.cardNumberText = cardNumber;
            await this.verifyAndPay();
          }
          app.hideLoadingMessage();
        },
        useSwipe: function(){
          return (jsonConfig.getVal( jsonConfig.KEYS.usePinPadSwipe )) && (session.pos.isEmv)
        },
        selectedCaveretCompany(companyCode: string) {
          // I.e. "toggled-on" a cibus company
          let choseCibusComp = this.caveretCibusCompanies.some(ccc => ccc.code === companyCode && ccc.selected == false);
          this.disableCompanySelection = choseCibusComp;
          this.caveretCibusCompanies.forEach(ccc => ccc.selected = ccc.code === companyCode && ccc.selected === false);
          if(choseCibusComp) {
            this.company = companyCode;
            this.selectField('cardNumberText');
          } else {
            this.company = '';
            this.selectField('cardNumberText');
          }
        },
        async checkBalance() {
          let cardNumber = this.cardNumberText;
          if(posUtils.isBlank(cardNumber)) {
            app.showLoadingMessage(i18next.t("swipeCard"));
            cardNumber = await Service.EMV.swipe(this.amount);
          }
          app.hideLoadingMessage();
          if(posUtils.isBlank(cardNumber)) {
            await app.promiseShowAlert({
              header: i18next.t('note'),
              content: i18next.t("pluxeeView.cardInputEmpty"),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true});
              return;
          }
          try {
            app.showLoadingMessage(i18next.t("pluxeeView.checkingBalanceLoading"));
            let balanceRes = await new PositiveTS.Service.Pluxee().getBalance(cardNumber, null, this.company);
            app.hideLoadingMessage();
            if (!balanceRes.success && balanceRes.error) {
              console.error("Pluxee ::: Error occured while fetching card balance ::: ", balanceRes);
              throw new Error(balanceRes.error);
            }
            await app.promiseShowAlert({
              content: i18next.t("pluxeeView.currentBalance",{ balance: balanceRes.balance }),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true});
          } catch (e) {
            app.hideLoadingMessage();
            await app.promiseShowAlert({
              header: i18next.t('error'),
              content: e.message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            });
          }

        }
      },
      data: initData,
      computed:  {
        isMobile(){
          return Pinia.globalStore.mobileLayout
        },
        isCaveret(): boolean { 
          return session.pos.isCaveret; 
        },
      }
    }

    VueApp.component('pluxee-view',c)
  }


  function initFields() {
    let fields = {

      'amount': {
        selected: false,
        allowDecimal: true,
        inEditMode: false,
        type: 'number',
        disabled: false
      },
      'cardNumberText': {
        selected: false,
        inEditMode: false,
        type: 'string',
        allowDecimal: false,
        disabled: false
      },
      'company': {
        selected: false,
        inEditMode: false,
        type: 'string',
        allowDecimal: false,
        disabled: false
      },

    }
    for (let field in fields) {
      fields[field].model = field;
    }
    return fields
  }

  async function getCibusCompany(){
    let aThis= this;
    let objComany: PositiveTS.Service.PluxeeService.Soap.CompanyItem = await PositiveTS.Dialogs.PluxeeCompanies.getCompanies();
    if (!objComany.companyCode) { 
      return;
    }
    aThis.company = objComany.companyCode;
    aThis.selectField('cardNumberText');
    if (aThis.isCaveret && aThis.caveretCibusCompanies) {
      aThis.caveretCibusCompanies.forEach(ccc => ccc.selected = false)
    }
  }

  async function refreshView(){
    let aThis = this;

    aThis.setCalculatedAmount();
    let smartVoucherType = PositiveTS.Service.Pluxee.getSmartVoucherType()
    let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(smartVoucherType)
    aThis.creditPayments = result;
    aThis.clearTapped()
    await PositiveTS.VueInstance.$nextTick()
  }

  function initCaveretCibusCompanies() {
    let caveretCompanies = [];
    if(session.pos.isCaveret) {
      caveretCompanies = session.pos.caveretCibusCompaniesArr;
      caveretCompanies.forEach(c => c["selected"] = false);
     }
     return caveretCompanies;
  }
  async function verifyAndPay(){
    let aThis=this;
    try {
      app.showLoadingMessage(i18next.t("voucherPaymentDebitPraxellLoadingMsg"));
      verify(this.amount);
      let smartVoucherType = PositiveTS.Service.Pluxee.getSmartVoucherType()
      let result = await PositiveTS.Service.VoucherPayment.payBySmartVoucherType(smartVoucherType, aThis.amount,aThis.cardNumberText, aThis.company);
      return result;
    } catch (e) {
      app.hideLoadingMessage();
      await app.promiseShowAlert({
        header: i18next.t('error'),
        content: e.message,
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      })
    } finally {
      app.hideLoadingMessage();
      aThis.addSplittedPaymentIfNeeded();
      aThis.cardNumberText = "";
      aThis.refreshView();
    }
  }
  

  function deleteCreditPayment(index){
    var aThis = this;
      return app.promiseShowAlert({
        header: i18next.t('confirmSalePaymentItemDeleteHeader'),
        content: i18next.t('confirmSalePaymentItemDelete'),
        continueButtonText: i18next.t('remove'),
        cancelButtonText: i18next.t('cancel')
      })
      .then( (result) =>{
        // if success this.refreshView
        if (result === "continue"){
           return  PositiveTS.Service.VoucherPayment.removeSmartVoucherPayment( aThis.creditPayments[index] );
        }

      })
      .then( ()=>{
        aThis.removeSplittedPaymentIfNeeded();
        aThis.refreshView();
      })
  }

  function verify(amount:number):void{
    let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
    let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);

    if (amount <= 0) {
      throw new Error(i18next.t("newCreditCard.AMOUNT_MUST_BE_BIGGER_THAN_ZERO"));    
    }
    else if (amount > amountLeft) {
      throw new Error(i18next.t("generalPaymentLeftAmountMandatoryError"));   
    }
  }

  function clearTapped() {
    let aThis = this;
    aThis.clearOldData()
    aThis.selectField('cardNumberText')
  }


  //clears the data after a single payment but when there might be still amount to pay
  function clearOldData() {
    let aThis = this;
    let result = initData()
    for (let prop in result) {
      if (prop === 'creditPayments' || prop === "amount") {
        continue;
      }
      aThis[prop] = result[prop];
    }

  }


  function onKeyTapped(key) {
    let aThis = this

    let field = aThis.getSelectedField();
    aThis.keyPadHanlder(key,field,aThis);
  }

  function getSelectedField() {
    let aThis = this
    for (let fieldName in aThis.fields) {
      if (aThis.fields[fieldName].selected) {
        return aThis.fields[fieldName]
      }
    }
    return undefined;
  }


  function initData() {
    let types = []

    return {
      cardNumberText: "",
      fields: initFields(),
      clearImg: `${(<any>window).images_path}/close.png`,
      amount: 0,
      company: jsonConfig.getVal(jsonConfig.KEYS.cibusDefaultCompanyId) || "",
      creditPayments: [],
      caveretCibusCompanies: initCaveretCibusCompanies(),
      disableCompanySelection: false,
    }
  }

  function selectField(fieldName) {
    let aThis = this;
    let refField = null;
    //not liking this code so much... but want to make sure that number will be rounded
    aThis.amount = session.fixedFloat(aThis.amount);
    for (let field in aThis.fields) {
      aThis.fields[field].selected = false
      aThis.fields[field].inEditMode = true
      aThis.fields[field].hasDecimal = false
      if (field == fieldName) {
        aThis.fields[field].selected = true
        refField = field
      }
    }
    if (refField != null) {
      this.$refs[refField].focus();
    }
  }
}}}}
