module PositiveTS {
  export module Components {
    export module DalpakActionsDialog {

      export const route = '/dalpak-actions';

      type CompType = any;

      export function getComponent() {
        return {
          template: JST.dalpakActionsDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            async unlockAndClose() {
              await Service.Dalpak.unlock(this.dalpak);
              this.close();
            },
            close() {
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
            async deleteDalpak() {
              let is_manager = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);
              if(is_manager){
                let isSuccess = await Service.Dalpak.deleteDalpak(this.dalpak);
                
                if (isSuccess) {
                  this.close();
                }
              }
            },
            async update() {
              let isSuccess = await Service.Dalpak.updateDalpak(this.dalpak);

              if (isSuccess) {
                this.close();
              }
            },
            async openMoveDalpak() {
              let freeDalpaks = this.allDalpaks.filter(dalpak => 
                                                                 posUtils.isBlank(dalpak.lockedBy) &&
                                                                 dalpak.id != this.dalpak.id);

              this.showMoveDalpak = true;
              this.freeDalpaksToMoveTo = freeDalpaks; 
            },
            closeMoveDalpak() {
              this.showMoveDalpak = false;
              this.freeDalpaksToMoveTo = [];
              this.selectedDalpakToMove = null;
              this.printMoveBon = true;
            },
            async moveDalpak() {
              let isSuccess = await Service.Dalpak.moveDalpakSale(this.dalpak, this.selectedDalpakToMove, this.printMoveBon);

              if (isSuccess) {
                this.close();
              }
            },
            async printInvoice() {
              await Service.Dalpak.printDalpakInvoice(this.dalpak);
            },
            async clearSale() {
              let techCardRes = await Pinia.componentsStore.openComponent( {componentName:"techCardDialogComponent", args: [null, true]});

              if (techCardRes.approved) {
                let confirmRes = await app.promiseShowAlert({
                  header: i18next.t('payAttention'),
                  content: i18next.t('dalpakActionsDialog.confirmSaleDeleteion'),
                  continueButtonText: i18next.t("ok"),
                })

                if (confirmRes == 'continue') {
                  app.showLoadingMessage(i18next.t('dalpakActionsDialog.clearingSale'));
                  let result = await Service.Dalpak.clearDalpakSale({...this.dalpak});
                  app.hideLoadingMessage();

                  if (result.success) {
                    this.close();
                  } else {
                    app.showAlert({
                      header: i18next.t('error'),
                      content:i18next.t('generalError'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                  }
                }
                
              } else if (!techCardRes.canceled) {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('errorTechnicalCard'),
                  hideCancelButton: true
                })
              }
            },
            isModbusDalpak() {
              return this.dalpak ? Service.Modbus.isActive() && Service.Modbus.isModbusTableDalpak(this.dalpak) : false;
            },
            async turnThisModbusTableOnOff(turnOn: boolean) {
              await Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(turnOn, this.dalpak)
            },
  
            async releaseDalpakActionsLock() {
              let employee = await app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_EDIT_DALPAKS]);

                if(employee?.isManagerDiscountConfirm || employee?.canEditDalpaks) {
                  this.lockDalpakActions = false
                } else {
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('dalpakActionsDialog.editDalpakPermissionError'),
                    hideCancelButton: true
                  })
                }
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {allDalpaks,allDalpakAreas} = Pinia.storeToRefs(dalpaksStore);

            const globalStore = Pinia.useGlobalStore();
            const {routerExtraData} = Pinia.storeToRefs(globalStore);
            return {routerExtraData, allDalpaks, allDalpakAreas}
          },
          data() {
            return {
              dalpak: null,
              dalpakBeforeChanges: null,
              showMoveDalpak: false,
              freeDalpaksToMoveTo: [],
              selectedDalpakToMove: null,
              printMoveBon: true,
              lockDalpakActions: false,
            }
          },
          beforeRouteEnter: function(to, from, next) {
            next(vm => {
              let self = vm as CompType;
              let dalpak = _.cloneDeep(vm.routerExtraData.dalpak)
              dalpak.data = posUtils.isBlank(dalpak.data) ? {} : dalpak.data;
              self.dalpak = dalpak;
              self.dalpakBeforeChanges = vm.routerExtraData.dalpak;
              self.lockDalpakActions = jsonConfig.getVal(jsonConfig.KEYS.isDalpakEditPermissionRequired)
            });
          }
        }
      }
    }
  }
}
