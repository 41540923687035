module PositiveTS {
    export module Service {
        export module SimplyClubService {
            export const jsonCustomerDataKey = "simply_club_customer";
            export const jsonSubTotalDataKey = "simply_club_customer_sub_total";
            export const jsonTranEndReqDataKey = "simply_club_customer_tran_end_req";
            export const jsonTranEndResDataKey = "simply_club_customer_tran_end_res";
            export const clubName = "simplyclub";
            export const VOUCHER_TYPE_ID = "888";

            export async function searchMemebers(searchText: string) {
                let res = await SimplyClubAPI.searchMemberNew({ searchParamProps: { all: searchText } });
                return res;
            }
            /** Please dont use this */
            export async function searchMemebersOld(searchText: string) {
                let res = await SimplyClubAPI.searchMembers({ searchParamProps: { cardField: searchText } });
                if (res.ErrorCode != 0) {
                    return res;
                }
                let res2 = await SimplyClubAPI.searchMembers({ searchParamProps: { cellPhone: searchText } });
                let res5 = await SimplyClubAPI.searchMembers({ searchParamProps: { firstName: searchText } });
                let res6 = await SimplyClubAPI.searchMembers({ searchParamProps: { lastName: searchText } });
                let res3 = await SimplyClubAPI.searchMembers({ searchParamProps: { personalId: searchText } });
                let res4 = await SimplyClubAPI.searchMembers({ searchParamProps: { phone: searchText } });
                let members = res.Members.concat(res2.Members).concat(res3.Members).concat(res4.Members).concat(res5.Members).concat(res6.Members)
                res.Members = _.uniqBy(members, m => m.Id);
                return res;
            }
            export async function sendSmsToJoin(phone: string) {
                let res = await SimplyClubAPI.sendSmsToJoin({ phoneNumber: phone });
                return res;
            }
            export async function addMember(member: SimplyClubAPI.MemberFieldsProps) {
                member.birthday = member.birthday ? moment(member.birthday).format('DD/MM/YYYY') : '';
                member.wedding_day = member.wedding_day ? moment(member.wedding_day).format('DD/MM/YYYY') : '';
                let req: SimplyClubAPI.AddMemberReq = {
                    memberInfo: {
                        MemberFieldsProps: member,
                        CardNumber: !posUtils.isBlank(member.card_field) ? member.card_field : '**auto**',
                        FirstName: member.first_name,
                        LastName: member.last_name,
                    },
                };
                member.card_field = "";
                let res = await SimplyClubAPI.addMember(req);
                return res;
            }
            export async function updateMember(cellPhone: string, member: SimplyClubAPI.MemberFieldsProps) {
                member.card_field = member.card_field ? member.card_field : '**auto**';
                member.birthday = member.birthday ? moment(member.birthday).format('DD/MM/YYYY') : '';
                member.wedding_day = member.wedding_day ? moment(member.wedding_day).format('DD/MM/YYYY') : '';
                let req: SimplyClubAPI.UpdateMemberReq = {
                    fieldId: 7,
                    fieldValue: cellPhone,
                    newMemberInfo: {
                        MemberFieldsProps: member,
                    }
                };
                let res = await SimplyClubAPI.updateMember(req);
                return res;
            }
            export async function updateMemberCardNumber(previousCardNumber: string, desiredCardNumber: string) {
                let req: SimplyClubAPI.updateMemberCardNumReq = {
                    fieldId: "17",
                    fieldValue: previousCardNumber,
                    newiFeldId: "17",
                    newFieldValue: desiredCardNumber,
                };
                let res = await SimplyClubAPI.switchMemberRecognition(req);
                return res;
            }
            export async function getCustomerDetails(card_field: string) {
                let res = await SimplyClubAPI.getMemberDetails({ card: card_field });
                return res;
            }

            export function isCustomerExistsOnSale(sale: PositiveTS.Storage.Entity.Sale): boolean {
                var saleJsondata = _getSaleJsondata(sale);
                return Boolean(saleJsondata[jsonCustomerDataKey]);
            }
            export function isSubTotalExistsOnSale(sale: PositiveTS.Storage.Entity.Sale): boolean {
                var saleJsondata = _getSaleJsondata(sale);
                return Boolean(saleJsondata[jsonSubTotalDataKey]);
            }
            export function isTranReqExistsOnSale(sale: PositiveTS.Storage.Entity.Sale): boolean {
                var saleJsondata = _getSaleJsondata(sale);
                return Boolean(saleJsondata[jsonTranEndReqDataKey]);
            }
            export function isTranEndResExistsOnSale(sale: PositiveTS.Storage.Entity.Sale): boolean {
                var saleJsondata = _getSaleJsondata(sale);
                return Boolean(saleJsondata[jsonTranEndResDataKey]);
            }
            export function getTranEndReqOnSale(sale: PositiveTS.Storage.Entity.Sale): SimplyClubAPI.Tran {
                return _getSaleJsondata(sale)[jsonTranEndReqDataKey];
            }
            export function getTranEndResOnSale(sale: PositiveTS.Storage.Entity.Sale): SimplyClubAPI.BaseResponse {
                return _getSaleJsondata(sale)[jsonTranEndResDataKey];
            }
            export function getCustomerOnSale(sale: PositiveTS.Storage.Entity.Sale): SimplyClubAPI.MemberDetails {
                return _getSaleJsondata(sale)[jsonCustomerDataKey];
            }
            export function getSubTotalOnSale(sale: PositiveTS.Storage.Entity.Sale): SimplyClubAPI.SubTotalResponse {
                return _getSaleJsondata(sale)[jsonSubTotalDataKey];
            }
            export async function setCustomerOnSale(customerObj: SimplyClubAPI.MemberDetails, sale: PositiveTS.Storage.Entity.Sale): Promise<any> {
                customerObj.clubName = clubName;
                var saleJsondata = _getSaleJsondata(sale);
                saleJsondata[jsonCustomerDataKey] = customerObj;
                posVC.sale.jsondata = JSON.stringify(saleJsondata);
                await Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
                return posVC.loadSale();
            }
            export async function setSubTotalOnSale(subTotal: SimplyClubAPI.SubTotalResponse, sale: PositiveTS.Storage.Entity.Sale): Promise<any> {
                for (const bene of subTotal.TotalDiscounts) {
                    bene.userField = bene.IsAuto;
                }
                for (const bene of subTotal.CashBackDiscounts) {
                    if (bene.BenefitType == 2) {
                        bene.userField = bene.IsAuto;
                    } else {
                        bene.userField = bene.IsAuto ? bene.Amount : 0;
                    }
                }
                var saleJsondata = _getSaleJsondata(sale);
                saleJsondata[jsonSubTotalDataKey] = subTotal;
                posVC.sale.jsondata = JSON.stringify(saleJsondata);
                await Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
                return posVC.loadSale();
            }
            export async function setTranEndReqOnSale(tranReq: SimplyClubAPI.Tran, sale: PositiveTS.Storage.Entity.Sale): Promise<any> {
                var saleJsondata = _getSaleJsondata(sale);
                saleJsondata[jsonTranEndReqDataKey] = tranReq;
                posVC.sale.jsondata = JSON.stringify(saleJsondata);
                let payment = createSalePayment(tranReq, sale);
                // posVC.salePayments.push(payment);
                await posVC.persistSalePayment(payment, undefined, false);
                // await Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
                return posVC.loadSale();
            }
            export async function setTranEndResOnSale(tranRes: any, sale: PositiveTS.Storage.Entity.Sale, save = true): Promise<any> {
                var saleJsondata = _getSaleJsondata(sale);
                saleJsondata[jsonTranEndResDataKey] = tranRes;
                sale.jsondata = JSON.stringify(saleJsondata);
                if (save) await appDB.sales.put(sale);
            }

            export function getPhoneNumberFromCustomer(member: SimplyClubAPI.MemberDetails) {
                return member.MemberFields.find(f => f.FieldId === "cell_number").FieldValue
            }
            export async function openDialogIfRequired(sale: PositiveTS.Storage.Entity.Sale, saleItems: PositiveTS.Storage.Entity.SaleItem[]) {
                if (!isCustomerExistsOnSale(sale)) {
                    return { isToContinue: true }
                }

                app.showLoadingMessage(i18next.t('simplyClubBenefits.checkingBenefits'));

                try {
                    if (isSubTotalExistsOnSale(sale)) {
                        await cancelTranOnSale(sale);
                    }
                    let subtotal = await SimplyClubAPI.subTotal(convertSaleToSubTotalReq(sale, saleItems));
                    if (subtotal.ErrorCode !== 0) {
                        throw new Error(subtotal.ErrorMessage);
                    }
                    await setSubTotalOnSale(subtotal, sale);
                    app.hideLoadingMessage();
                } catch (err) {
                    app.hideLoadingMessage();
                    throw err;
                }

                let result = await Pinia.componentsStore.openComponent( {componentName:"simplyclubBenefitsDialog", args: [sale, saleItems]});
                return result; // Result should contain if to continue or not
            }
            export async function validateAndSetSaleForTranEnd(subTotal: SimplyClubAPI.SubTotalResponse, sale: PositiveTS.Storage.Entity.Sale) {
                let tran = convertSubTotalToTranEndReq(subTotal, sale);
                let res = await SimplyClubAPI.checkIfCanEndTran(tran);
                if (res.ErrorCode === 0) {
                    setTranEndReqOnSale(tran, sale);
                }
                return res;
            }
            export async function tranEndWithSale(sale: PositiveTS.Storage.Entity.Sale) {
                let tran = getTranEndReqOnSale(sale);
                let res = await SimplyClubAPI.endTran(tran);
                if (res.ErrorCode !== 0) {
                    throw new Error(res.ErrorCode + " - " + res.ErrorMessage);
                }

                setTranEndResOnSale(res, sale);
                return res;
            }
            export async function saleRefund(sale: PositiveTS.Storage.Entity.Sale, refunedInvoiceNumber: string) {
                let tranEnd: any = getTranEndResOnSale(sale);
                let refundReq: SimplyClubAPI.RefundTran = {
                    refNumberType: 1,
                    refundTranNumber: refunedInvoiceNumber,
                    completedTranNumber: tranEnd.TranReferenceNumber,
                };
                let res = await SimplyClubAPI.refundTran(refundReq);
                if (res.ErrorCode !== 0) {
                    throw new Error(res.ErrorCode + " - " + res.ErrorMessage);
                }
                return res;
            }
            export async function cancelTranOnSale(sale: PositiveTS.Storage.Entity.Sale) {
                let subTotal = getSubTotalOnSale(sale);
                let res = await SimplyClubAPI.cancelTran({ refNumberType: 1, tranNumber: subTotal.ServiceTranNumber });
                return res; // Really doesn't matter to us if it was cancelled or not, because it's not our DB XD
                // Don't worry, this will work, I'm trolling
            }

            function _getSaleJsondata(sale: PositiveTS.Storage.Entity.Sale) {
                var saleJsonData: any = {};
                if (sale.jsondata) {
                    saleJsonData = JSON.parse(sale.jsondata);
                }
                return saleJsonData;
            }
            function convertSubTotalToTranEndReq(subTotalRes: SimplyClubAPI.SubTotalResponse, sale: PositiveTS.Storage.Entity.Sale): SimplyClubAPI.Tran {
                let subtotalReq = convertSaleToSubTotalReq(sale);
                let transaction: SimplyClubAPI.Transaction = {
                    TranNumber: subTotalRes.ServiceTranNumber,
                    TranType: subtotalReq.transaction.TranType,
                    SellerId: subtotalReq.transaction.SellerId,
                    TranDate: subtotalReq.transaction.TranDate,
                    OperatorId: subtotalReq.transaction.OperatorId,
                    TranMember: subtotalReq.transaction.TranMember,
                    TranItems: subTotalRes.TransactionItems.map(ti => ti.ItemSaleData),
                    TranDiscounts: [],
                    CashbackDifferent: 0,
                };
                for (const cashBack of subTotalRes.CashBackDiscounts) {
                    if (cashBack.userField === false || cashBack.userField === 0) {
                        continue;
                    }
                    transaction.TranDiscounts.push({
                        PromoId: cashBack.PromoId,
                        Quantity: 1,
                        ReferenceCode: "",
                        POSAmount: 0,
                        DiscountType: 1,
                        Amount: cashBack.userField === true ? cashBack.Amount : cashBack.userField
                    });
                }
                for (const discount of subTotalRes.TotalDiscounts) {
                    if (discount.userField === false) {
                        continue;
                    }
                    transaction.TranDiscounts.push({
                        PromoId: discount.PromoId,
                        Quantity: 1,
                        ReferenceCode: "",
                        POSAmount: 0,
                        DiscountType: 0,
                        Amount: discount.Amount,
                    });
                }
                let tran: SimplyClubAPI.Tran = { refNumberType: 1, transaction: transaction };
                tran.tranNumber = subTotalRes.ServiceTranNumber;
                return tran;
            }
            function convertSaleToSubTotalReq(sale: PositiveTS.Storage.Entity.Sale, saleItems?: PositiveTS.Storage.Entity.SaleItem[]): SimplyClubAPI.SubTotalTranReq {
                let currentTran = isSubTotalExistsOnSale(sale) ? getSubTotalOnSale(sale) : null;
                let customer = getCustomerOnSale(sale);
                let transaction: SimplyClubAPI.BaseTransaction = {
                    TranNumber: currentTran && currentTran.ServiceTranNumber ? currentTran.ServiceTranNumber : '',
                    TranType: 0,
                    SellerId: customer.SysId.toString(),
                    OperatorId: '',
                    TranDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                    // TranTenders: null,
                    // AdditionalInfo: null,
                    // CashbackDifferent: null,
                    // TranDiscounts: null,
                    TranMember: {
                        CardNumber: customer.CardNumber,
                        ClubId: customer.ClubId,
                        EnterType: 1,
                        MemberType: 1
                    },
                    TranItems: [],
                };
                if (saleItems) {
                    for (let index = 0; index < saleItems.length; index++) {
                        const saleItem = saleItems[index];
                        transaction.TranItems.push(covertSaleItemToTranItem(saleItem, index + 1));
                    }
                }
                let tran: SimplyClubAPI.Tran = { transaction: transaction, refNumberType: 1 };
                tran.tranNumber = currentTran && currentTran.ServiceTranNumber ? currentTran.ServiceTranNumber : "";

                return tran;
            }
            function covertSaleItemToTranItem(saleItem: PositiveTS.Storage.Entity.SaleItem, trxIdIndex: number): SimplyClubAPI.TranItem {
                let inputFlag = SimplyClubAPI.InputFlags.NONE;
                let promoAndDiscountString = saleItem.promotions || '';
                let promoCodesAndDiscounts = promoAndDiscountString.split(';').filter(s => s.trim()).map(s => s.split('=')[0]);
                if (promoCodesAndDiscounts.some(code => code.toLowerCase().startsWith('d'))) {
                    inputFlag += SimplyClubAPI.InputFlags.DISCOUNTED;
                }
                if (promoCodesAndDiscounts.some(code => isNaN(parseInt(code)) === false)) {
                    inputFlag += SimplyClubAPI.InputFlags.PROMONTED;
                }
                let tranItem: SimplyClubAPI.TranItem = {
                    PLUClassificationCode1: saleItem.size || '',
                    PLUClassificationCode2: saleItem.color || '',
                    Vendor: saleItem.item['supplierName'] || '',
                    UOM: saleItem.hasWeight ? 1 : 0,
                    AdditionalInfo: [],
                    PLUClassificationCode3: '',
                    Code: saleItem.itemCode,
                    ItemBarcode: saleItem.barcode,
                    Quantity: saleItem.quantity,
                    ItemDescription: saleItem.itemDescription,
                    TrxID: trxIdIndex,
                    Price: saleItem.unitPrice.toString(),
                    Amount: saleItem.unitPrice * saleItem.quantity,
                    Cost: 0,
                    InputFlags: inputFlag,
                    PromoCode: promoCodesAndDiscounts.join(','),
                    SysDiscount: (saleItem.unitPrice * saleItem.quantity) - saleItem.priceNetoAfterDiscounts,
                }
                if (saleItem.item['itemMainClassNumber']) {
                    tranItem.Department = saleItem.item['itemMainClassNumber'].toString();
                    tranItem.CategoryCode = (saleItem.item['itemMainSubClassNumber'] || '').toString();
                }
                return tranItem;
            }
            function createSalePayment(tran: SimplyClubAPI.Tran, sale: PositiveTS.Storage.Entity.Sale): PositiveTS.Storage.Entity.SalePayment {
                let payment = new PositiveTS.Storage.Entity.SalePayment();
                let subtotal = getSubTotalOnSale(sale);
                payment.method = Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;
                payment.saleID = sale.id;
                let amount = 0;
                for (const benefitUsed of tran.transaction.TranDiscounts) {
                    if (benefitUsed.DiscountType === 1) { // CASHBACK
                        let cashBackDiscount = subtotal.CashBackDiscounts.find(cb => cb.PromoId === benefitUsed.PromoId);
                        amount += benefitUsed.Amount * cashBackDiscount.ItemQty;
                    } else { // Discount
                        amount += benefitUsed.Amount;
                    }
                }
                payment.amount = 0;
                payment.data = JSON.stringify([{
                    amount: amount,
                    points: amount,
                    creditType: "SIMPLY_CLUB",
                    barCode: "",
                    voucher_type_id: VOUCHER_TYPE_ID,
                    smartVoucherType: false,
                    simplyClubTran: tran,
                }]);
                return payment;
            }
        }
    }
}
