module PositiveTS {
	export module Application {
	export module Controllers {
	export class StoreSelectViewController extends AbstractViewControllerTS {
		
		init() {
			// Initialize the table view
			let tableView = $('#store-select-tableview').tableViewQuick(undefined, "#store-select-input");
			tableView.setNumberOfVisibleRows(5);
			tableView.empty();
		}
		resume() {
			let aThis = storeSelectVC;
	
			// Get the table view for later use
			let tableView = $('#store-select-tableview').tableViewQuick();
	
			// Empty the table so we can be sure we are displaying only the latest results
			tableView.empty();
	
			let storeModel = new PositiveTS.Storage.Entity.Store();
	
			let stores = storeModel.fetchByTenantIDAndCompanyID(session.pos.tenantID, session.pos.companyID);
	
	
			if (stores === null || stores === undefined || stores.length < 1) {
				return;
			}
	
			let phone:string;
			for (let i = 0; i < stores.length; i++) {
				phone = stores[i].phone ? stores[i].phone: "";
				let theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
					stores[i].storeID + " " + stores[i].storeName + " " + phone
				]));
				theRow.data('store', stores[i]);
			}
			tableView.quickCurrentTable();
	
			// Bind the continue button to its listener
			$('#store-select-continue').click(aThis.continue);
		}
		stop() {
			// Unbind the listeners from the resume function
			$('#store-select-continue').unbind('click');
		}
		// --------------------------------------------------------------------
		// Listeners
		// --------------------------------------------------------------------
		continue() {
			// Get the selected row
			let theRow = $('#store-select-tableview').tableViewQuick().getSelectedRow();
	
			// If there are no selected rows, finish
			if (theRow.length === 0) {
				return;
			}
	
			//Get the user selection
			let store = theRow.data('store');
	
			posSearchInvoicesFormVC.selectStore(store.storeID, store.storeName);
	
			// Go back
			pNavigator.back();
		}

	}
}}}
declare var storeSelectVC:PositiveTS.Application.Controllers.StoreSelectViewController;
storeSelectVC = new PositiveTS.Application.Controllers.StoreSelectViewController();
PositiveTS.Application.Controllers.instances.StoreSelectViewController = storeSelectVC;