module PositiveTS {
export module Service {
export module HeNum {
  const _SHEKEL = "שקל";
  const _AGOROT = "אגורות";
  const _ONLY = "בלבד";
  
  const _K = "אלף";
  const _AND = "ו";

  const _toD1 = {
    1 : "אחד",
    2 : "שתיים",
    3 : "שלושה",
    4 : "ארבעה",
    5 : "חמישה",
    6 : "שישה",
    7 : "שבעה",
    8 : "שמונה",
    9 : "תשעה",
    10 : "עשרה",
    11 : "אחת עשרה",
    12 : "שתים עשרה",
    13 : "שלוש עשרה",
    14 : "ארבע עשרה",
    15 : "חמש עשרה",
    16 : "שש עשרה",
    17 : "שבע עשרה",
    18 : "שמונה עשרה",
    19 : "תשע עשרה",
  };

  const _toD2 = {
    1 : "עשר",
    2 : "עשרים",
    3 : "שלושים",
    4 : "ארבעים",
    5 : "חמישים",
    6 : "שישיםֿ",
    7 : "שבעים",
    8 : "שמונים",
    9 : "תשעים"
  };

  const _toD3 = {
    1 : "מאה",
    2 : "מתיים",
    3 : "שלוש מאות",
    4 : "ארבע מאות",
    5 : "חמש מאות",
    6 : "שש מאות",
    7 : "שבע מאות",
    8 : "שמונה מאות",
    9 : "תשע מאות"
  };

  export function getCurrencyInHebrew(number:number){    
    var _return = "";
    
    number = session.fixedFloatTrunc(number, 2);
    
    var strNumber = number.toString();
    var aryNumber = strNumber.split(".");
    var shekel = aryNumber[0];
    var agorot = aryNumber[1];
    
    _return = _return + _getIntegerInHebrew( parseInt(shekel) ) + " " + _SHEKEL;
    if (agorot) {
      agorot = PositiveTS.Service.StrUtils.rpad(agorot,2,"0");
      _return = _return + ", " + _AND + _getIntegerInHebrew( parseInt(agorot) ) + 
                  " " + 
                  _AGOROT;
    }
    _return = _return + " " + _ONLY;
    return _return;
  };
  

/*
 * Return array like
 *  123456
 *  ["one", "two", "and three", "for","five","and six"]
 */
function _getNumberAsTextArray(n:number, _return?:any) {
           
    if (!_return) {
      _return = [];
    }
    
    var currentArray = [];
    
    // for 123, parse the 23 first
    if (n % 100 < 20 && n % 100 > 10) {
      currentArray.push(undefined);
      currentArray.push(_toD1[n % 100]);
      n = Math.trunc(n / 100);
    } else {
      currentArray.push(_toD1[n % 10]);
      n = Math.trunc(n / 10);
      currentArray.push(_toD2[n % 10]);
      n = Math.trunc(n / 10);
    }
    

    // parse the handreds
    currentArray.push(_toD3[n % 10]);
    n = Math.trunc(n / 10);

    // Add keyword AND (Hebrew VE) if required
    _addHebrewKeywordsToLastWord(currentArray);

    _return = _return.concat( currentArray );
    // Recursivellt parse the thousands
    if (n > 0) {
      return _getNumberAsTextArray(n, _return);
    }

    return _return;
  };

  function _aryCountUndefined(ary:any[]) {    
    var _return = 0;
    for (var i=0;i<ary.length;i++){
      if (ary[i] === undefined) {
        _return ++;
      }
    }
    return _return;
  };


  /*
   * Add hebrew keywork AND to array
   * ["one", "two","three"] becomes ["one","two","AND three"]
   */
  function _addHebrewKeywordsToLastWord(numberAsTextArray:any[]) {    
    if (_aryCountUndefined( numberAsTextArray ) === 2) {return;} 
    
    for (var i=0; i<numberAsTextArray.length; i++ ) {
      if (numberAsTextArray[i] !== undefined ) {
        if ( numberAsTextArray[i][0] !== _AND  ) {
          numberAsTextArray[i] = _AND + numberAsTextArray[i];
        }
        
        break;
      }
    }
  };


  /* 1. and thousands keyword if required
   * 2. join array to one string
   * 
   * ex: 
   *  ["one", "two", "and three", "for"] becomes "one two and three  THOUSANDS for"
   */
  function _getStringFromArray(aryReturn:any[]){    
    if (aryReturn.length > 3){
      aryReturn.splice(3,0,_K);
    }
    
    var _return  = "";
    for (var i=0;i<aryReturn.length; i++ ){
      if ( aryReturn[i] ) {
        _return = aryReturn[i] + _return;
        _return = " " + _return ;

      }
    }
    return _return;
  };

  function _getIntegerInHebrew(number:number) {    
    var aryReturn = _getNumberAsTextArray(number);
    _addHebrewKeywordsToLastWord( aryReturn );
    
    return _getStringFromArray(aryReturn).trim();
  };

}}}


