module PositiveTS {
  export module Service {
    export module EmployeeBounsReport {
      export function getFromServer (bounsReportObject):Promise<string> {
        return new Promise((resolve,reject) => {
          if (posUtils.isBlank(bounsReportObject)) {
            throw new Error('bounsReportObject undefined');
            
          }
        
          $.ajax({
            type: "GET",
            url: Shared.Constants.remoteRoot + 'employee_bouns/',
            data: bounsReportObject,
            dataType: 'html',
            cache: false,
            success: function (returnedData) {
              resolve(returnedData);
            }
          }).error(function(err) {
            reject(err);
          });
        })
      
      };
    }
  }
}