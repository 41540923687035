module PositiveTS {
export module Components {
    export module ReleaseSaleDialog {

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            let mobileLayout = Pinia.globalStore.mobileLayout;  

            $("#release-sale-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: mobileLayout ? '100%' : '90%',
              height: mobileLayout ? $(window).height() : 550,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }



      function close() {
        $("#release-sale-dialog").dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }

      async function open() {
        this._initjQueryDialogIfNeeded()
        $(document).unbind('keypress');
        $("#release-sale-dialog").dialog('open');
        app.blockUIAndShowMessage(i18next.t("releaseSale.loading"),false);
        try {
          let saleObjs = await PositiveTS.Service.HoldSale.getSaleObjsOnHold()
          this.saleOnHold = saleObjs;
          this._orgSaleOnHold = saleObjs;
          await PositiveTS.VueInstance.$nextTick();
        }
        finally {
          app.resumeUIAndHideMessage(false);
        }
      }

      function deleteSale(sale){

        if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.isDeleteItemManagerApproval))) {
          return app.showManagerApprovalDialog()
          .then( ()=>{
            return PositiveTS.Service.HoldSale.deleteSaleOnHold( sale.id)
            .then( ()=>{
              if (this._orgSaleOnHold.length === 1){
          this.close();
              } else {
                this.open();
              }
              
        })
          })
        } else {
          return PositiveTS.Service.HoldSale.deleteSaleOnHold( sale.id)
          .then( ()=>{
            if (this._orgSaleOnHold.length === 1){
              this.close();
            } else {
              this.open();
            }
          })
        }


 
      }

      async function releaseSale(sale){
        app.blockUIAndShowMessage(i18next.t("pos.releasingSale"),false);
        try {
          await PositiveTS.Service.HoldSale.releaseSaleOnHold( sale.id)
          
          this.close();
          posVC.resume();
        }
        catch(e) {
          Service.Logger.error(e);
        }
        finally {
          app.resumeUIAndHideMessage(false);
        };
      }

      function _initData(){
        return {saleOnHold: [],
                tableFilter: "",
                _orgSaleOnHold: []};
      }

      function filterTable(){
        this.saleOnHold = [];
        for (let i=0; i<this._orgSaleOnHold.length; i++){
          let rowString = this._orgSaleOnHold[i].cashierEmployeeName +
                        this._orgSaleOnHold[i].customerName +
                        this._orgSaleOnHold[i].orderNumber.toString();
          if (!this.tableFilter ||
              rowString.indexOf(this.tableFilter) > -1

               ) {
            this.saleOnHold.push( this._orgSaleOnHold[i] );
          }
        }
      }

      function createdAtStr(createdAt) {
        if (!createdAt) {
          return "";
        }

        let date = moment(new Date(createdAt));
        return `${date.format("D/MM/YYYY")}\n${date.format("HH:mm")}`
      }

      export function create() {

        let releaseSaleDialog = {
            template: JST.releaseSaleDialog(),
            methods: {
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              close: close,
              open: open,
              createdAtStr: createdAtStr,
              deleteSale: deleteSale,
              releaseSale: releaseSale,
              filterTable: filterTable
            },
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {portraitMode,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {portraitMode,mobileLayout}

              return {...globalStoreProps}
            },
            data: _initData

        }

        VueApp.component('release-sale-dialog',releaseSaleDialog)

      }
    }
}
}
