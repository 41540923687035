module PositiveTS {
    export module Service {
        export module ElalApiService {
            /** Get's PNR's reservations which are used as sale items.
             * To use the items for the sale, you'll need to update the PNR with the reservations/items you want to use */
            export async function GetPNR(pnr: string) {
                let url = session.pos.elalUrlGetPNR + `?PNR=${pnr}`
                const res = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponse(url, "GET", null , undefined, logName("GetPNR"));
                return <Types.ElalGetPNRResponse>res.getJsonBody();
            }

            /** Wrapper of "Update PNR" which locks PNR items/reservations for sale */
            export async function ConfirmPNRItems(pnr: string, reservations: Array<Types.ElalGetPNRReservation>) {
                let arr: Types.ElalUpdatePNRReservationReq[] = reservations.map(res => { return { 
                    KupaStatus: "confirm", PNR: pnr, RecId: res.RecId 
                }});
                return await UpdatePNR(arr);
            }

            /** Wrapper of "Update PNR" which frees PNR items/reservations from sale */
            export async function CancelPNRItems(pnr: string, reservations: Array<Types.ElalGetPNRReservation>) {
                let arr: Types.ElalUpdatePNRReservationReq[] = reservations.map(res => { return { 
                    KupaStatus: "cancel", PNR: pnr, RecId: res.RecId 
                }});
                return await UpdatePNR(arr);
            }
            
            /** "Updates the PNR" - This updates PNR via setting the status of the reservations/items */
            export async function UpdatePNR(reservations: Array<Types.ElalUpdatePNRReservationReq>) {
                // Elal can't read a simple object. The order of the fields MATTERS.
                // They parsing it to XML and expect each field in a specific order..... so yes, this code is necessary 
                let req = { root: { reservations: reservations.map(res => {return { RecId: res.RecId, PNR: res.PNR, KupaStatus: res.KupaStatus }; }) } };
                const res = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponse(session.pos.elalUrlUpdateTMI, "POST", req, undefined, logName("UpdatePNR"));
                return <Types.ElalUpdatePNRResponse>res.getJsonBody();
            }

            //** Todo: I have no idea what this does */
            export async function UpdateEKT(reservations: Array<Types.ElalGetEKTReservationRequest>) {
                try{
                    let req = { root: { reservations: reservations } };
                    const res = await PositiveTS.Service.HTTPService.makeHttpRequestWithFullResponse(session.pos.elalUrlGetEKT, "POST", req, undefined, logName("GetEKT"));
                    return res.getJsonBody(); // Todo, add response
                } catch (e) {
                    return Promise.reject(e);
                }
            }

            export async function UpdateEktAndSendToServer(){
                try {
                    const reservationsToElal = await getReservationToSendToElal();
                    const elalResponse = await UpdateEKT(reservationsToElal);
                    await updateDocNumberTable(elalResponse);
                } catch (e) {
                    PositiveTS.Service.Logger.error(e);
                }    
            }

            async function updateDocNumberTable(reservationsFromElal) {
                if(reservationsFromElal?.root){ 
                    for (const res of reservationsFromElal.root?.TKT || []) {
                        for (const reservation of res.reservations || []) {
                            try {
                                await PositiveTS.Storage.Entity.ElalReservation.addOrUpdate({ 
                                    pnr: res.PNR,
                                    recId: reservation.RecId,
                                    docNumber: reservation.DOCNumber,
                                    docType : reservation.TypeDoc,
                                    status: PositiveTS.Storage.Entity.ElalReservation.SYNC_STATUS_WAITING_TO_BE_SENT_TO_SERVER,
                                });
                            } catch(error) {
                                console.log(error);
                            }
                        }
                    }
                }    
            }
            async function getReservationToSendToElal(){
                const reservations =  (await PositiveTS.Storage.Entity.ElalReservation.fetchByStatuses([1])).map(res => {return {
                    RecId: res.recId,
                    PNR: res.pnr
                }})
                return reservations
            }

            function logName(name: string) {
                return session?.pos?.deviceID + "-" + name;
            }

            export const fetchElalReservationsInterval = _.debounce(async () => {
                await UpdateEktAndSendToServer();
				fetchElalReservationsInterval();
			}, 1800000)
        }
    }
}