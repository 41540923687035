module PositiveTS {
  export module Service {
    export class CreditInvoice {
      public static reduceChildPaymentsFromOriginalPayments(childSale: any, salePayments: any) {
        for (let currentCreditPayment of childSale.payments) {

          if (CreditInvoice.isReplacementSale(childSale)) {
            continue;
          }

          // search similar sale item
          for (let currentSalePayment of salePayments) {

            if (currentSalePayment.method == currentCreditPayment.method &&
              currentSalePayment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER) {
                currentSalePayment.amount += currentCreditPayment.amount;

                if (PositiveTS.Storage.Entity.SalePayment.PAYMENTS_WITH_DATA.indexOf(currentSalePayment.method) > -1) {
                  var newDataObj = [];

                  var currentData = JSON.parse(currentSalePayment.data);
                  var creditData = JSON.parse(currentCreditPayment.data);

                  for (let creditDataItem of creditData) {
                    if (creditDataItem.amount) {
                      creditDataItem.amount = Math.abs(creditDataItem.amount)
                    }
                    var foundJ = -1;
                    for (let j=0; j<currentData.length; j++) {
                      if (JSON.stringify(currentData[j]) == JSON.stringify(creditDataItem)) {
                        foundJ = j;
                        break;
                      }

                      if (CreditInvoice.checkGPP(currentSalePayment,currentData[j],creditDataItem)) {

                        currentData[j].amount -= creditDataItem.amount;
                        currentSalePayment.data = currentData;
                        if (currentData[j].amount <= 0) {
                          foundJ = j;
                        }
                        break;
                      }
                      if (currentSalePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT) {

                        if (currentData[j].isEmv || currentData[j].isGateway) {
                          if(currentData[j].isEmv){
                            currentData[j] = Object.assign(new PositiveShared.EmvOutput(),currentData[j])
                            creditDataItem = Object.assign(new PositiveShared.EmvOutput(),creditDataItem)
                          }else{
                            currentData[j] = Object.assign(new PositiveShared.PaymentGatewayOutput(),currentData[j])
                            creditDataItem = Object.assign(new PositiveShared.PaymentGatewayOutput(),creditDataItem)
                          }
                          if((currentData[j].isEmv && creditDataItem.OriginalPaymentRequestId == currentData[j].RequestId)||(currentData[j].isGateway && creditDataItem.OriginalPaymentTranId == currentData[j].TranID)){
                            currentData[j].amount -= creditDataItem.amount;
                            currentSalePayment.data = currentData;

                            if (currentData[j].amount <= 0) {
                              foundJ = j;
                            }
                            break;    
                          }
                        } 
                        else if (currentData[j].token === creditDataItem.token) {

                          currentData[j].amount -= creditDataItem.amount;
                          currentSalePayment.data = currentData;

                          if (currentData[j].amount <= 0) {
                            foundJ = j;
                          }

                          break;
                        }
                      }
                    }

                    if (foundJ >= 0) {
                      currentData.splice(foundJ, 1);
                      currentSalePayment.data = JSON.stringify(currentData)
                    }
                  }
                }

                break;
            }
          }
        }
      }

      private static checkGPP(currentSalePayment,originalPaymentData,creditPaymentData):boolean {
        if (currentSalePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
          if (creditPaymentData.smartVoucherType === "GPP") {
            if (creditPaymentData.originalReference === originalPaymentData.actionReference) {
              return true;
            }
          }
        }
        return false;
      }

      public static reduceChildItemsFromOriginalItems(childSale: any, saleItems:Array<Storage.Entity.SaleItem>) {
        if (CreditInvoice.isReplacementSale(childSale)) {
          let itemReplacementData = JSON.parse(childSale.jsondata)['replacementSaleItems'];
          let saleItemsByRowNumber = _.keyBy(saleItems, 'rowNumber');
          let childSaleItemsByRowNumber = _.keyBy(childSale.items, 'rowNumber');

          for (let currentReplacementData of itemReplacementData) {
            saleItemsByRowNumber[currentReplacementData.parentSaleItemRowNumber].quantity += childSaleItemsByRowNumber[currentReplacementData.saleItemRowNumber].quantity;
          }
        } else {

          for (var i = 0; i < childSale.items.length; i++) {
            let currentCreditItem = childSale.items[i];

            // search similar sale item
            for (var j = 0; j < saleItems.length; j++) {
              var currentSaleItem = saleItems[j];

              if (currentSaleItem.similarItemUnsigned(currentCreditItem)) {
                currentSaleItem.quantity += currentCreditItem.quantity;
                break;
              }
            }
          }
        }
      }

      public static isReplacementSale(sale) {
        const allowToReplaceItemsInReplacementSale = jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)
        if ((sale.invoiceType == Storage.Entity.Sequence.TYPE_DEBIT_INVOICE ||( sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE && allowToReplaceItemsInReplacementSale)) && !posUtils.isBlank(sale.jsondata)) {

          let jsondata = JSON.parse(sale.jsondata);


          return !posUtils.isBlank(jsondata['replacementSaleItems']);
        }

        return false;
      }
    }
  }
}
