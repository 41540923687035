module PositiveTS {
export module Components {
export module ReportZByDate {
  const dialogSelector = "report-z-by-date-dialog"

  type CompType = any;


  function initData() {
    return {
      initialized: false,
      loading: false,
      rejectFunc: null,
      resolveFunc: null,
      dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
      dateFormat: "dd/MM/yyyy",
      fromDate: moment(new Date()).startOf("month").toDate(),
      toDate : moment(new Date()).endOf("month").toDate(),
      byFirstSale: false,
      keyboardImg: `${(<any>window).images_path}/keyboard.jpg`,

    };
  }

  export function create() {
    let component = {
        template: JST.reportZByDate(),
        methods: {
          open() {
            return new Promise((resolve,reject) => {
                  
              this.initialized = true;

              if (this.dialogElem == null) {
                this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
              }
              this.dialogElem.showModal();
              
              this.blurInputsOnElement(this.dialogElem)

              this.resolveFunc = resolve;
              this.rejectFunc = reject;
            })
        
        
          },
          close() { 
            let self = this as CompType;
            if (self.initialized) {
              if (self.dialogElem == null) {
                self.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
              }

              self.resolveFunc();                  
              self.dialogElem.close();
            }
            self.cleanData()
          },
          cleanData() {
            let self = this as CompType;
            let result = initData();
            for (let prop in result) {
              self[prop] = result[prop];
            }
            self.initialized = true;
          },
          sendReportByEmail() {
            let self = this as CompType;
            Pinia.componentsStore.openComponent( {
              componentName: 'emailDialog', 
              args: [
                Shared.Constants.EmailReportType.Z_REPORT, 
                { fromDate: String(self.fromDate), toDate: String(self.toDate), pos: session.pos.deviceID }
              ]
            });
          },
          async runReport() {
            let self = this as CompType;
            if (!PositiveTS.Reachability.isOnline) {
              await app.promiseShowAlert({
                header: "",
                content: i18next.t('rikuzZDialog.offLine'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
                noHandleEnterEscape: true,
              })
              return;
            }
        
            
            var diff = moment(self.fromDate).diff(moment(self.toDate), "days");
            if (Math.abs(diff) > 365) {
              await app.promiseShowAlert({
                header: "",
                content: i18next.t('rikuzZDialog.badDateRange'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
                noHandleEnterEscape: true,
              })
              return;
            }
        
        
        
            if (diff > 0){
              await app.promiseShowAlert({
                header: "",
                content: i18next.t('rikuzZDialog.toLargerThenFrom'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
                noHandleEnterEscape: true,
              })
              return;
            }
        
            // app show message
            try {
              app.showLoadingMessageDialog(i18next.t('rikuzZDialog.inProccess'));
              let result = await Service.ReportZByDate.getReport( self.fromDate, self.toDate, self.byFirstSale)
              let strFromDate = moment(self.fromDate).format("DD/MM/YYYY")
              let strToDate = moment(self.toDate).format("DD/MM/YYYY")
              await Printing.ReportZByDate(result.allPayments, result.allVouchers, result.all_credit_cards, strFromDate, strToDate,
                          result.totalPayments, result.totalVat, result.total_that_has_no_vat, result.totalOwsVat, result.merakez_data,
                          result.totalZ,result.primaryCategoryStats, result.tipsAmount,
                          result.totalSittingDiners, result.sittingDinersAvg, result.totalDeliveriesDiners, result.deliveriesDinersAvg, result.deliveriesAvg, result.interfaces, result.salesDiscountReport);
            
              app.hideLoadingMessageDialog();
              self.close();
            }
            catch {
              app.hideLoadingMessageDialog();
              app.showAlertDialog({
                header: i18next.t('error'),
                content: i18next.t('generalError'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
            }
        
        
          },

        },
        data: initData,
        computed: {
          isRoshemet() {
            return session.pos.isRoshemet;
          }
        },
    }
    VueApp.component('report-z-by-date',component)
    return component;
  }

}}}
