module PositiveTS {
		export module Service {
		export module VouchersReport {
		
			export async function listAvailableVouchers(){
				let availableVouchers = [];
				try{
						let tenbisCustomer =  await FetchReq.jsonReq(`/hakafa_customers?new_mode=true&search='לקוח תן ביס'`,"get");
						if(tenbisCustomer.result.customers.length > 0){
								availableVouchers.push({
										name: "תן ביס",
										value: String(tenbisCustomer.result.customers[0]["customer_number"])
								});
						}
						let cibusCustomer =  await FetchReq.jsonReq(`/hakafa_customers?new_mode=true&search='לקוח פלאקסי'`,"get");
						if(cibusCustomer.result.customers.length > 0){
								availableVouchers.push({
										name: "פלאקסי",
										value: String(cibusCustomer.result.customers[0]["customer_number"])
								});
						}
						let dtsCustomer =  await FetchReq.jsonReq(`/hakafa_customers?new_mode=true&search='לקוח DTS'`,"get");
						if(dtsCustomer.result.customers.length > 0){
								availableVouchers.push({
										name: "DTS נולדג'",
										value: String(cibusCustomer.result.customers[0]["customer_number"])
								});
						}
						if(!session.pos.isCaveret) {						
							let goodiCustomer =  await FetchReq.jsonReq(`/hakafa_customers?new_mode=true&search='לקוח גודי'`,"get");
							if(goodiCustomer.result.customers.length > 0){
									availableVouchers.push({
											name: "גודי",
											value: String(goodiCustomer.result.customers[0]["customer_number"])
									});
							}
						}
				}catch{
						await app.showAlertDialog({header: i18next.t('error'),
						content: i18next.t('vouchersReport.errorLoadingVouchers'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true})
				}

				return availableVouchers;
			};

			export async function printReport(customerNumber,customerName,fromDate,toDate,summeryOnly?){
				app.showLoadingMessageDialog(i18next.t('vouchersReport.generatingReport'));
				try {
					let report = await Hakafa.getReport(customerNumber,fromDate,toDate);
					Printing.Reports.printRoshemetVoucherReport(report,session.pos.companyName,session.pos.storeName,customerName,summeryOnly);          
				}
				catch(e) {
					console.error(e);
				}
				finally {
					app.hideLoadingMessageDialog();
				}
			}
		
		}}}
		
		
		
