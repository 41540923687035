(<any>window).keyPadMixin = {
  methods: {
    getDecimalPercision: function() {
      let decimalPrecision = 2;

      if (posUtils.isDefined(session.pos) && posUtils.isDefined(session.pos.parameterDecimalPrecision)) {
        decimalPrecision = session.pos.parameterDecimalPrecision;
      }
      return decimalPrecision;
    },
    keyPadHanlder: function(key, field, aThis, userDecimalPercision = undefined) {
      let decimalPrecision = userDecimalPercision ? userDecimalPercision : this.getDecimalPercision()

      if (field) {
        let allowDecimal = field.allowDecimal && decimalPrecision != 0;
        if (field.inEditMode) {
          let modelStr = String(aThis[field.model])
          switch (key) {
            case '.':
              if (allowDecimal) {
                field.hasDecimal = true
              }
            break;
            case '-':
              if(modelStr[0] != "-"){
                aThis[field.model]  = "-" + modelStr;
              }
            break;
            case 'bksp':
              if (allowDecimal && field.hasDecimal && modelStr.indexOf('.') >= 0) {
                if (modelStr.indexOf('.') === (modelStr.length - 2)) {
                  modelStr = modelStr.slice(0, -1);
                  field.hasDecimal = false
                }
                else {
                  modelStr = modelStr.slice(0, -1);
                  field.hasDecimal = modelStr[modelStr.length-1] != '0'
                }
              }
              else {
                modelStr = modelStr.slice(0, -1);
                if (field.hasDecimal && modelStr.indexOf('.') === -1){
                  field.hasDecimal = false
                }
              }
              
              if(modelStr == "-"){
                modelStr = "0";
              }

              if (field.type == 'number') {
                aThis[field.model] = Number(modelStr)
              }
              else {
                aThis[field.model] = modelStr
              }
            break;
            default:
              if (allowDecimal && (field.hasDecimal || modelStr.indexOf('.') >= 0)) {
                  if (modelStr.indexOf('.') === -1) {
                    modelStr += '.'
                  }
                  else {
                    if (modelStr.indexOf('.') === (modelStr.length - decimalPrecision - 1)) {
                      modelStr = modelStr.slice(0, -1);
                    }
                  }
              }

              modelStr += key
              // not break the bank
              if (field.type == 'number' && Number(modelStr) > 9999999999999999){
                return
              }

              if(modelStr[0] == "-" && modelStr[1] == "0" && field.type == 'number' && key !='0'){
                aThis[field.model] = Number(modelStr);
              }
              else if (modelStr[0] == '0' && field.type == 'number' && key != '0') {
                aThis[field.model] = Number(modelStr);
              }
              // else if((modelStr[0] != '0' || key != '0') && (((modelStr[0] != '-') || (modelStr[1] != '0')) || key != '0')){
              //   aThis[field.model] = modelStr
              // }
              else{
                aThis[field.model] = modelStr
              }

              if (field.type == 'number' && !posUtils.isBlank(field.max)) {
                aThis[field.model] = Math.min(Number(modelStr),Number(field.max))
              }
              if (field.type == 'number' && !posUtils.isBlank(field.min)) {
                aThis[field.model] = Math.max(Number(modelStr),Number(field.min))
              }
          }

        }
        else {
          //set it in edit mode and change value to be the key
          field.inEditMode = true
          if (field.type == 'number') {
            aThis[field.model] = Number(key)
          }
          else {
            aThis[field.model] = key
          }
        }
      }
    }
  }
}



declare var keyPadMixin:any;
