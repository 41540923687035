module PositiveTS {
  export module Components {
    export module PositiveDatepickerTranslations {
      const locale = require('date-fns/locale')
      const {ar} = locale
      const {en} = locale
      const {he} = locale

      export const translations = {
        ar,
        en,
        he,
      }
    }
  }
}
  
