
module PositiveTS {
/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"punchCardListBalance", arguments: ["10.50","123123"]})
*/
  export module Components {
    export module PunchCardListBalance {
      const _dialogSelector = "#positive-punch-card-list-balance-dialog"
      const _vueComponentName = 'punch-card-list-balance';
    
      export function create() {
        let vueObj = {
            template: JST.punchCardListBalance(),
            methods: {
              open: _open,
              close: _close,
              doConfirm: _doConfirm,
              doPrint: _doPrint,
              doShowTransactions: _doShowTransactions,
              doReject: _doReject
            },
            data: _initData,
    
    
        }
    
        VueApp.component(_vueComponentName,vueObj)
    
      }
    
    
    
      function _open(punchCardItems, cardNumber){
        
    
        $(document).unbind('keypress');
        $(function() {
          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: '80%',
            height: 600,
            resizable: false,
            closeOnEscape: false,
            draggable: false,
          });
          $(_dialogSelector).dialog('open');
        });
    
    
        return new Promise((resolve,reject) => {
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.punchCardItems = punchCardItems;
          this.cardNumber = cardNumber;
        })
    
      }
    
    
      function _initData(){
        return {
          _dialogSelector: _dialogSelector,
          rejectFunc: null,
          resolveFunc: null,
          punchCardItems: [],
          cardNumber: null
        };
      }

      function _doPrint(){
        Printing.Reports.printPunchCardItems(this.punchCardItems, this.cardNumber)
        this.close();
        this.resolveFunc();
      }
    
      function _doConfirm(){
          this.close();
          this.resolveFunc();
      }
    
      function _doShowTransactions(punchCardItemId, itemName){
        this.close();
        this.resolveFunc();
Pinia.componentsStore.openComponent( {componentName:"punchCardTransactionsReport", args: [punchCardItemId, itemName, this.cardNumber.substr(-6)]})
      }

      function _doReject(){
        this.close();
        this.rejectFunc(new Error(app.userCancelledGlobalMessage));
      }
    
      function _close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }
    
    
    }}}