module PositiveTS {
  export module Components {
    export module SelfServicePage {

      export function getComponent() {
        return {
          template: JST.selfServicePage(),
          computed: {
            selfServiceBackgroundImageActivated(){
              return Pinia.globalStore.selfServiceBackgroundImageActivated
            },
            autoStore(){
              return Pinia.posStore.autoStore
            },
						head_logo(): string {
							return this.autoStore.config.head_logo;
						},
            isOsherAd(){
              return Service.OsherAd.isEnabled();
            },
            withOpacity(){
              return this.selfServiceBackgroundImageActivated && !jsonConfig.getVal(jsonConfig.KEYS.isCancelOpacity);
            }
          },
          props: {
            title: {
              type: String,
              default: '',
            },
            subtitle: {
              type: String,
              default: '',
            },
            showGoBack: {
              type: Boolean,
              default: false,
            }
          },
        }
      }
    }
  }
}
