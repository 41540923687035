module PositiveTS {
  export module Service {
    export module Modbus {
      export async function sendDataToRegister(table: number, turnOn: boolean) {
        let ipAndPort = getIpAndPort();

        let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
          ip: ipAndPort.ip,
          port: ipAndPort.port,
          action: turnOn ? 1 : 0,
          table,
        }, 'modbus');

        if (!response) {
          throw new Error("Modbus Addon return null, Please check addon version");
        }

        if (response.request.result != "true") {
          throw new Error(response.request.result);
        }
      }

      export async function sendDataToRegisterIfNeededAndShowMessages(turnOn: boolean, dalpak = Pinia.dalpaksStore.currentSelectedDalpak) {
            if (Service.Modbus.isActive() && dalpak &&
            Service.Modbus.isModbusTableDalpak(dalpak)) {
              let table = parseInt(dalpak.name);
              app.showLoadingMessage(i18next.t('modbus.sendDataToRegister'))
              try {
                await Service.Modbus.sendDataToRegister(table, turnOn);
              } catch(err) {
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('modbus.errorCommunicatingModbusServer'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
              
                Service.Logger.error(err);
              }
    
              app.hideLoadingMessage();
        }
      }

      export function isActive() {
        return jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && !posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.modbusServer));
      }

      export function isModbusTableDalpak(dalpak) {
        return /^\d+$/.test(dalpak.name);
      }

      function getIpAndPort() {
        let splitted = jsonConfig.getVal(jsonConfig.KEYS.modbusServer).split(':')
        return {
          ip: splitted[0],
          port: parseInt(splitted[1])
        }
      }
    }
  }
}
