module PositiveTS {
    export module Service {
        export module YaadService {
            const apiUrl = "https://icom.yaad.net/p3/"
            let requestCancelled = false

            function headers() {
                return JSON.stringify({
                    "accept": "application/json",
                })
            }

            function boolConvert(boolToConvert: boolean) {
                if (boolToConvert) {
                    return "True"
                }

                return "False"
            }

            export function itemsToHeshDesc(saleItems: Array<Storage.Entity.SaleItem>) {
                let itemsStr = ""
                for (let index = 0; index < saleItems.length; index++) {
                    const saleItem = saleItems[index];
                    let row = `[0~${saleItem.itemDescription}~${saleItem.quantity}~${saleItem.unitPrice}]`
                    itemsStr = itemsStr + row
                }

                return itemsStr
            }

            export async function sendPaymentLinkToCustomer(Order, Amount, UserId, ClientName, Phone, saleItems?, ClientLName?, Street?, City?, ZipCode?, Cell?, Email?, Info?) {
                app.showLoadingMessage(i18next.t('yaadPayment.sendingPaymentLinkToCustomer'))
                let heshDesc = ""
                if (saleItems) {
                    heshDesc = itemsToHeshDesc(saleItems)
                }
                const apiSignResultData = await APISign(Order, Amount, UserId, ClientName, Phone, ClientLName, Street, City, ZipCode, Cell, Email, Info, heshDesc)
                const url = `${apiUrl}?action=pay&${apiSignResultData}`
                console.log("Yaad Sarig payment URL:" + url)
                const smsResponse = await FetchReq.jsonReq(`/yaadpay/send_sms`, "post",
                { payment_url: url,
                    phone: Phone})
                
                    
                app.hideLoadingMessage()
                return smsResponse.result
            }

            export async function startPaymentRequestPoll(uid) {
                requestCancelled = false
                let result = await getPaymentRequestFromServer(uid);
                while (result.success == false && requestCancelled == false) {
                    await wait(5000);
                    result = await getPaymentRequestFromServer(uid);
                }
                return result;
            }
            function wait(ms = 1000) {
                return new Promise(resolve => {
                    console.log(`Yaad Sarig - waiting ${ms} ms for customer payment...`);
                    if (requestCancelled) {
                        requestCancelled = false
                        resolve()
                    }
                    setTimeout(resolve, ms);
                });
            }

            export function cancelRequest() {
                requestCancelled = true
            }

            function jsonToQuery(params) {
                return Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }

            export async function getPaymentRequestFromServer(uid, initCancel?): Promise<{ success: boolean, data: string }> {
                const reqResponse = await FetchReq.jsonReq(`/yaadpay/get_payment_request?uid=${uid}`, "get")
                if (!_.isEmpty(reqResponse.result) && requestCancelled == false) {
                    return {
                        success: true,
                        data: jsonToQuery(JSON.parse(reqResponse.result.data))
                    }
                }
                else if (requestCancelled) {
                    console.log("user canclled yaad service call")
                    return {
                        success: false,
                        data: "request cancelled"
                    }
                }
                else {
                    return {
                        success: false,
                        data: "payment request not found"
                    }
                }
            }

            export async function CommitTransaction(transId) {
                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)
                const url = `${apiUrl}?action=commitTrans&Masof=${Masof}&TransId=${transId}&PassP=${session.pos.yaadSarigPassp}`

                let data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                });

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )

                const res = response.request.result

                const urlParams = new URLSearchParams(res);
                const CCode = urlParams.get('CCode');

                const success = (!posUtils.isNullOrUndefinedOrEmptyString(CCode) && Number(CCode) == 0)

                return {
                    success: success,
                    CCode: CCode
                }
            }

            export async function refundTransaction(transId, amount) {
                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)

                const url = `${apiUrl}?action=zikoyAPI&TransId=${transId}&Amount=${amount}Tash=1&PassP=${session.pos.yaadSarigPassp}&Masof=${Masof}`

                let data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                });

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )

                const res = response.request.result

                const urlParams = new URLSearchParams(res);
                const CCode = urlParams.get('CCode');

                const success = (!posUtils.isNullOrUndefinedOrEmptyString(CCode) && Number(CCode) == 0)

                return {
                    success: success,
                    CCode: CCode
                }
            }

            export async function cancelTransaction(transId) {
                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)
                const url = `${apiUrl}?action=CancelTrans&Masof=${Masof}&TransId=${transId}&PassP=${session.pos.yaadSarigPassp}`

                let data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                });

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )

                const res = response.request.result

                const urlParams = new URLSearchParams(res);
                const CCode = urlParams.get('CCode');

                const success = (!posUtils.isNullOrUndefinedOrEmptyString(CCode) && Number(CCode) == 0)

                return {
                    success: success,
                    CCode: CCode
                }
            }

            export async function verifyAndParseResult(successPageData,retryTime = 0) {
                const verifyResponse = await Verify(successPageData)
                const CCode = Number(verifyResponse.substr(verifyResponse.indexOf("=") + 1))
                if (!posUtils.isNullOrUndefinedOrEmptyString(CCode) && CCode == 0) {
                    const urlParams = new URLSearchParams(successPageData);
                    const barcode = urlParams.get('Id');

                    return {
                        success: true,
                        code: CCode,
                        voucherBarcode: barcode
                    }
                }
                else if(retryTime < 3){
                    await wait(5000)
                    return (await verifyAndParseResult(successPageData,retryTime + 1))
                }


                return {
                    success: false,
                    code: CCode,
                    voucherBarcode: null
                }
            }

            async function Verify(successPageData) {
                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)
                const apiToken = jsonConfig.getVal(jsonConfig.KEYS.yaadApiToken)
                const url = `${apiUrl}?action=APISign&What=VERIFY&KEY=${apiToken}&PassP=${session.pos.yaadSarigPassp}&Masof=${Masof}&${successPageData}`

                let data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                });

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )

                return response.request.result
            }

            async function APISign(Order, Amount, UserId, ClientName, Phone, ClientLName = "", Street = "", City = "", ZipCode = "", Cell = "", Email = "", Info = "", heshDesc = "") {
                //optional parameters:
                //Tash tashType FixTash TashFirstPayment pageTimeOut PageLang tmp Coin J5 ShowEngTashText
                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)
                const apiToken = jsonConfig.getVal(jsonConfig.KEYS.yaadApiToken)
                const UTF8 = boolConvert(true)
                const UTF8out = boolConvert(true)
                const sendemail = boolConvert(false)
                const MoreData = boolConvert(true)
                const Sign = boolConvert(true)
                const Postpone = boolConvert(true)
                const Pritim = boolConvert(heshDesc ? true : false)

                const url = `${apiUrl}?action=APISign&What=SIGN&KEY=${apiToken}&PassP=${session.pos.yaadSarigPassp}&Masof=${Masof}&UTF8=${UTF8}&UTF8out=${UTF8out}&sendemail=${sendemail}&MoreData=${MoreData}&Sign=${Sign}&Order=${Order}&Info=${Info}&Amount=${Amount}&UserId=${UserId}&ClientName=${ClientName}&ClientLName=${ClientLName}&street=${Street}&city=${City}&zip=${ZipCode}&phone=${Phone}&cell=${Cell}&email=${Email}&Postpone=${Postpone}&heshDesc=${heshDesc}&Pritim=${Pritim}`

                let data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                });

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )

                return response.request.result
            }

            async function getTransactionToken (transactionId) {
                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)
                const url = `${apiUrl}?action=getToken&Masof=${Masof}&TransId=${transactionId}&PassP=${session.pos.yaadSarigPassp}`
                const data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                })
 
                let response = await GenericAddon.sendNativeMessageToExtension({data},
                    "external_service")

                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )

                const res = response.request.result
                let tokenResult: any = posUtils.objectFromUrlSearchParams(res) 
                return tokenResult
            }

            export async function Soft (transactionId, heshDesc, Amount, Order, UserId, FirstName,
                LastName, Phone, Email) {
                
                const transactionToken = await getTransactionToken(transactionId)
                if (_.isEmpty(transactionToken)){
                  throw new Error('Error get transaction token')
                }

                const Masof = jsonConfig.getVal(jsonConfig.KEYS.yaadTerminalID)
                const Tmonth = transactionToken.Tokef.slice(2,4)
                const Tyear = `20${transactionToken.Tokef.slice(0,2)}`
                const Pritim = boolConvert(heshDesc ? true : false)
                let url = `${apiUrl}?action=soft&Coin=1&Info=positive-api`
                url += `&Token=True&CC=${transactionToken.Token}&Tmonth=${Tmonth}`
                url += `&Tyear=${Tyear}&Amount=${Amount}&Masof=${Masof}`
                url += `&Order=${Order}&ClientName=${FirstName}&ClientLName=${LastName}`
                url += `&phone=${Phone}&email=${Email}`
                url += `&UserId=${UserId}&sendemail=True&Token=True`
                url += `&PassP=${session.pos.yaadSarigPassp}&UTF8=True&UTF8out=True`
                
                const data = JSON.stringify({
                    "body": "",
                    "type": "GET",
                    "url": url,
                    "headers": headers()
                })

                let response = await GenericAddon.sendNativeMessageToExtension({data},"external_service")
                Filelog.log("yaad",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                )
                const res = response.request.result
                return res
            }
        }
    }
}  
