module PositiveTS {
  export module Components {
    export module SelfServiceCaveretSelectClubDialog {
      const _vueComponentName = 'self-service-caveret-select-club-dialog';
      export function create() {
        let selfServiceCibusSelectClubDialog = {
          template: JST.selfServiceCaveretSelectClubDialog(),
          mixins: [Mixins.oneClickMixin, Mixins.scrollableMixin],
          components: { scrollable: PositiveTS.Components.Scrollable.getComponent() },
          methods: {
            open() {
              this.$el.showModal();
              return new Promise(resolve => {
                this.resolveFunc = resolve
              })
            },
            close() {
              this.$el.close();
            },
            selectClubAndClose(club) {
              let res = { selected: true, club: club }
              this.close()
              this.resolveFunc(res)
            },

            back() {
              let res = { selected: false, club: null }
              this.close()
              this.resolveFunc(res)
            },
          },

          data() {
            return {
              resolveFunc: null,
              search: '',
              scrollSelector: "#caveret-clubs-btns"
            };
          },
          computed: {
            clubs() {
              return jsonConfig.getVal(jsonConfig.KEYS.caveretPaymentClubs);
            }
          },
        };
        
        VueApp.component(_vueComponentName, selfServiceCibusSelectClubDialog)
      }
    }

  }
}


