module PositiveTS {
  export module Components {
    export module SelfServiceTotalBeforePayDialog {
      const dialogSelector = "self-service-total-before-pay-dialog"

      

      function initData() {
        return {
          initialized: this.initialized,
          rejectFunc: null,
          resolveFunc: null,
          payments: 2,
          originalPayments: 1,
          dialogElem: document.getElementById(dialogSelector),
          fields: initFields(),
          sale: null,
          items: [],
        }
      }

      function initFields() {
        let fields = {
          'payments': {
            selected: true,
            allowDecimal: false,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'payments'
          }
        }
        return fields;
      }

      export function create() {

        let component = {
          template: JST.selfServiceTotalBeforePayDialog(),

          methods: {
            cleanData () {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },

            open () {
              this.sale = posVC.sale 
              this.setItems()
              return new Promise((resolve, reject) => {
                this.initialized = true;
      
                if (this.dialogElem == null) {
                  this.dialogElem = document.getElementById(dialogSelector)
                }
                this.dialogElem.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            },

            close() {
              if (this.initialized) {
                if (this.dialogElem == null) {
                  this.dialogElem = document.getElementById(dialogSelector)
                }
                this.dialogElem.close();
              }
              this.cleanData()
            },

            confirm () {
              Pinia.componentsStore.openComponent( {componentName:"selfServicePaymentDialog", args: []});
              this.close()
            },
            totalPricePerUnit(unit,price){
              if(unit == 1){
                return price;
              }
              let totalPrice = session.fixedNumber(unit * price,3)
              return totalPrice;
            },

            getPromotionsChild (saleItem: Storage.Entity.SaleItem) {
              let promotionsRow = this.promoGroups.filter(pg => pg.rowToShowOn == saleItem.rowNumber);
              let promotions = [];
              if (promotionsRow.length > 0){
                promotions = promotionsRow.map(pg => {
                  return {
                    discountAmountForGroup: pg.discountAmountForGroup,
                    promoName: pg.promoName,
                  }
                });
              }

              return promotions;
            },

            setItems () {
              let items = this.saleItemsFormatted.map((saleItem) => {     
                let promotionsRow = this.promoGroups.filter(pg => pg.rowToShowOn == saleItem.rowNumber)
                let promotions = promotionsRow.map(pg => {
                  return {
                    discountAmountForGroup: pg.discountAmountForGroup,
                    promoName: pg.promoName,
                  }
                })

                let children = [] 
                if (saleItem.children){
                  children = saleItem.children.map(child => {
                    if(this.multiLangEnabled){
                      child['preparationInstructions']  = JSON.parse(child.selectedPreparationInstructionsTranslations)?.map(pi => pi.text) || []
                    }else{
                      child['preparationInstructions'] = JSON.parse(child.selectedPreparationInstructions)
                    }
                    return child
                  })
                }

                let preparationInstructions = []
                if(this.multiLangEnabled){
                  preparationInstructions = JSON.parse(saleItem.selectedPreparationInstructionsTranslations)?.map(pi => pi.text) || []
                }else{
                  preparationInstructions = JSON.parse(saleItem.selectedPreparationInstructions)
                }

                return {
                  itemDescription: saleItem.itemDescription,
                  unitPrice: saleItem.unitPrice,
                  originalUnitPrice: saleItem.originalUnitPrice,
                  priceNetoAfterDiscounts: saleItem.priceNetoAfterDiscounts,
                  quantity: saleItem.quantity,
                  actualQuantity: saleItem.actualQuantity,
                  preparationInstructions: preparationInstructions,
                  children: children,
                  promotions: promotions,
                }
              })

              this.items = items
            }
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {saleItemsFormatted,saleTotal,promoGroups} = Pinia.storeToRefs(globalStore);

            const languageManagerStore = Pinia.useLanguageManagerStore();
            const {multiLangEnabled, currentLang} = Pinia.storeToRefs(languageManagerStore);
            const globalStoreProps = {saleItemsFormatted,saleTotal,promoGroups}
            
            

            return {...globalStoreProps,multiLangEnabled, currentLang}
          },
          computed: {
            posCurrencySign () {
              return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign()
            },
          },

          data: initData,
        }

        VueApp.component('self-service-total-before-pay-dialog', component)

      }
    }
  }
}

