module PositiveTS {
export module Components {
    export module PriorityImportOrderDialog {
      
      const dialogSelector = "priority-import-order-dialog"

      export function create() {

        let priorityImportOrderDialog = {
            template: JST.priorityImportOrderDialog(),
            methods: {
              cleanData() {
                this.searchOrder = ''
              },

              close() {
                if (this.dialogElem == null) {
                  this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                }
        
                this.dialogElem.close()
                $(document).unbind('keypress')
                $(document).keypress(posVC.onKeyPress)
                this.cleanData()
              },
        
              async open () {
                try{
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  
                  if (posVC.saleItems.length > 0){
                    this.showGenericError(i18next.t('priorityImportOrderDialog.errorHasItemsOnCurrentSale'))
                    return
                  }
 	          
                  this.dialogElem.showModal()
          
                  this.$nextTick(() => {
                    $(document).unbind('keypress')
                    this.$refs.searchOrder.focus()
                  })   
                }catch(e){
                  console.error(e)
                  this.showGenericError()
                }
              },

              showGenericError(error = '') {
                app.showAlert({
                  header: i18next.t('error'),
                  content: error || i18next.t('priorityImportOrderDialog.generalError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                },
                null,null)
              },

              async importOrder () {
                app.showLoadingMessage(i18next.t('priorityImportOrderDialog.importMessage'))
                const res = await PositiveTS.Service.PriorityService.importOrderToSale(this.searchOrder)
                
                if (!res.success){
                  app.showAlert({
                    header: i18next.t('error'),
                    content: res.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }

                app.hideLoadingMessage()
                this.close()
              }
            },
            data () {
              const data: {
                dialogElem: any,
                searchOrder: string,
              } = {
                dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
                searchOrder: ''
              }

              return  data 
            }

        }

        VueApp.component('priority-import-order-dialog', priorityImportOrderDialog)

      }
    }
  }
}

