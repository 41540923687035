module PositiveTS {
    export module Service {
    export module QRService {

    export function displayQR(text, timeout = 30) { 
        switch (jsonConfig.getVal(jsonConfig.KEYS.qrDisplay)) {
            case "PINPAD":
                return PositiveTS.Service.EMV.DisplayQR(text, timeout)
            case "SUNMI":
                Android.sendQRToLCD(text, 128, 40);
                break;
            case "CUSTOMERDISPLAY":
                PositiveTS.Service.CustomerDisplay.sendQRCode(text);
                break;
            case "POSDISPLAY":
                return Pinia.componentsStore.openComponent( {componentName:"QrDialog", args: [text]})
            default:
                break;
        }
    }

    export async function hideQR(){
        switch (jsonConfig.getVal(jsonConfig.KEYS.qrDisplay)) {
            case "PINPAD":
                break;
            case "SUNMI":
                PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalAmount()))
                break;
            case "CUSTOMERDISPLAY":
                PositiveTS.Service.CustomerDisplay.hideQRCode()
                break;
            case "POSDISPLAY":
                await PositiveTS.VueInstance.$refs.QrDialog.close()
                break;
            default:
                break;
        } 
    }

    //Icepark gate implemention
    export function generateQRHash(text){
        return Sha256.sha256.hash(text + Date.now().toString());
    }

    export async function gateControlLogin(){
        let url = "https://iceapi.smartagid.com/token";
        let user = "46331@46331.com";
        let password = "46331";
        let grantType = "password&username";
        let token = "";
        var _data = {
            "body": "grant_type=" + grantType + "&userName=" + user + "&password=" + password,
            "type": "POST",
            "url": url,
            "headers": JSON.stringify({
                "accept": "application/json",
            })
        };
        var data = JSON.stringify(_data)
        let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
        );
        let result = JSON.parse(response.request.result)
        if(result.access_token){
            token = result.access_token
        }
        return token;

    }

    export async function sendQRToGateControl(qrData,eventID){
        let url = "https://iceapi.smartagid.com/api/addTicket";

        let jsonData = {
            "SourceCode": qrData,
            "TicketCode": qrData,
            "SourceID": 1,
            "TicketType": 0, 
            "TerminalID": 88767, 
            "EventID": Number(eventID) 
        }

        var _data = {
            "body": JSON.stringify(jsonData),
            "type": "POST",
            "url": url,
            "headers": JSON.stringify({
                "accept": "application/json",
                "Authorization": "Bearer " + session.gateToken,
                "Content-Type": "application/json"
            })
        };

        var data = JSON.stringify(_data)
        let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
        );
        let result = JSON.parse(response.request.result)

        if(result.Status != true){
            console.error("Error in sending QR to gate control server\r\n" + result);
        }
    }
    
}}}
    
