module PositiveTS {
export module Components {
    export module CreditSelectModal {
      
      const dialogSelector = 'credit-select-modal'

      function initData () {
        let data :{
          dialogElem: any,
          showCashReturnButton: boolean,
          showCreditCardReturnButton: boolean,
          showCash: boolean,
          directHakafaPayment: boolean,
          showHakafaReturnButton: boolean
          showVoucherRefundButton: boolean
        } = {
          dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
          showCashReturnButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCashReturnButton)),
          showCreditCardReturnButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCreditCardReturnButton)),
          showCash: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCash)),
          directHakafaPayment: Boolean(jsonConfig.getVal(jsonConfig.KEYS.directHakafaPayment)),
          showHakafaReturnButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showHakafaReturnButton)),
          showVoucherRefundButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showVoucherRefundButton)),
        }
        return data
      }

      function cashCredit () {
        PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.cashCredit()
        this.close()
      }

      async function refundVoucher(){
        await PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.refundVoucher()
        this.close()
      }

      function returnCreditCard () {
        PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.returnCreditCard()
        this.close()
      }

      function giveCreditVoucher () {
        PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.giveCreditVoucher()
        this.close()
      }

      function returnHakafa () {
        PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.returnHakafa()
        this.close()
      }

      function hakafaCardPayment () {
        PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.hakafaCardPayment()
        this.close()
      }

      function close () {
        this.dialogElem.close()
        $(document).unbind('keypress')
        $(document).keypress(posVC.onKeyPress)  
      }

      function open () {
        
        if (this.dialogElem == null) {
          this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
        }

        this.dialogElem.show()

        this.$nextTick(() => {
          $(document).unbind('keypress')
        })      
      }

      export function create() {

        let creditSelectModal = {
            template: JST.creditSelectModal(),
            methods: {
              open,
              close,
              cashCredit,
              returnCreditCard,
              giveCreditVoucher,
              returnHakafa,
              hakafaCardPayment,
              refundVoucher,
            },
            data: initData,
            
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {hakafaCustomer,isWithdrawalSale} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {hasHakafaCustomer:hakafaCustomer,isWithdrawalSale} 

              return {...globalStoreProps}
            },
        }

        VueApp.component('credit-select-modal',creditSelectModal)

      }
    }
}
}
