module PositiveTS {
  export module Components {
      export module EmployeeReportMonthDialog {
  
        const dialogSelector = "employee-report-month-dialog"


        export interface RowUi {
          enterRow?:PositiveTS.Storage.Entity.EmployeeTimeTrack
          exitRow?:PositiveTS.Storage.Entity.EmployeeTimeTrack
        }

        interface CompData {
          timeReportRows:any[],
          totalHours:string,
          name:string,
        }
  
        
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            dateFormat: "dd/MM/yyyy",
            startDate: moment(new Date()).startOf("month").toDate(),
            endDate : moment(new Date()).endOf("month").toDate(),
            empList: []
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.employeeReportMonthDialog(),
            methods: {

              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              async formatReport() {
                let timeReportRowsArray = [];
                let totalHoursArray = [];
                let allEmpHoursTotal = 0;
                let startDate = this.startDate;
                let endDate = this.endDate;

                for(let emp of this.empList){
                let timeReportRows:RowUi[] = []; 
                
                let employeePunchedAry = (await appDB.employeeTimeTracks.where('employeeID').equals(emp.employeeID)
                                                .sortBy('timestamp')).filter(emp => !emp.markedForDelete);
      
                                            
                employeePunchedAry = employeePunchedAry
                    .filter( (row)=>{
                      return row.timestamp >= moment( startDate ).startOf('day').toDate().getTime() &&
                      row.timestamp <= moment( endDate ).endOf('day').toDate().getTime()
                    })
                 
                while (employeePunchedAry.length > 0) {
                  let currentTimeTrack = employeePunchedAry.splice(0,1)[0]
                  let nextEntry = employeePunchedAry[0] 
                  let nextEntryModeSameAsCurrent = nextEntry && currentTimeTrack.mode === nextEntry.mode
                  let nextEntryMoreThen20Hr = nextEntry && 
                        moment( currentTimeTrack.timestamp ).diff(moment( new Date( nextEntry.timestamp) ), 'hours') >= 20
        
                  let isNotValidToJoinNextRecord = employeePunchedAry.length === 0 || 
                                nextEntryModeSameAsCurrent || 
                                currentTimeTrack.mode === PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT ||
                                nextEntryMoreThen20Hr
                  
                  if ( isNotValidToJoinNextRecord ){
                    if (currentTimeTrack.mode === PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT) {
                      timeReportRows.push( {enterRow: undefined, exitRow: currentTimeTrack})
                    } else {
                      timeReportRows.push( {enterRow: currentTimeTrack, exitRow: undefined})
                    }
                  } else {
                    let exitRow = employeePunchedAry.splice(0,1)[0]
                    timeReportRows.push( {enterRow: currentTimeTrack, exitRow: exitRow})
                  }
                }

                timeReportRowsArray.push(timeReportRows);
        
                let totalHours = 0
                for(let row of timeReportRows ) {
                  let rowTotal = this.getRowTotalHours(row);
                  if(rowTotal){
                    totalHours = totalHours + parseFloat(rowTotal)
                  }
                }

                totalHoursArray.push(totalHours);

                allEmpHoursTotal+=totalHours;

              }

              
              Printing.Reports.printAllEmployeesTimeReport(totalHoursArray, session.fixedNumber(allEmpHoursTotal,2)
              ,moment(this.startDate).format("DD/MM/YYYY"),moment(this.endDate).format("DD/MM/YYYY") ,timeReportRowsArray,this.empList);
               
            },
            getRowTotalHours(row:RowUi){
              let _ret = ""
              if (row.enterRow && row.exitRow) {
                _ret = session.fixedNumber(  
                  (moment( new Date(row.exitRow.timestamp) ).diff(moment( new Date( row.enterRow.timestamp) ), 'minutes')/60) ,2 
                )
              }
              return _ret;     
            },

              cancel() {                
                this.resolveFunc()
                this.close();
              },
              
              async confirmAndClose() {
                if(moment( this.startDate ) > moment( this.endDate )){
                  let ans = await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('employeeReportMonth.startDateBiggerThanEnd'),
                    hideCancelButton: true
                  })
                }
                else{
                  await this.formatReport();
        
                  this.resolveFunc()
                  this.close();
                }
              },
      
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  this.dialogElem.close();
                }
                this.cleanData()
              },
      
              
        
              open(empList) {
                this.empList = empList;
                return new Promise((resolve,reject) => {
      
                  
                  this.initialized = true;
        
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  this.dialogElem.showModal();
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
            computed: {
            }
          }
  
          VueApp.component('employee-report-month-dialog',component)
        }
      }
    }
  }
  