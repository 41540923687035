module PositiveTS {
  export module Promotions {
  export module Templates {
  export class Dirbun extends TemplateAbstract {
    
    private maxBuyAmount:number
    private minBuyAmount:number
    private allowMultipleTimesPerSale:boolean
    private getPotentialSalePromotions:boolean
    
    private allItems:Array<Storage.Entity.SaleItem>
    
    constructor(initParameters) {
      super(initParameters)
      this.allItems = initParameters.updatedSaleItems;
      this.getPotentialSalePromotions = initParameters.getPotentialSalePromotions;
    }
  
    calculatePromotions() {
      //yalla azov oti me shtoyot
    }
  
    run(promotion:Storage.Entity.Promotion) {
      
      if (!this.getPotentialSalePromotions) {
        return;
      }
      
      let buyItems = [];
      this.maxBuyAmount = Number(promotion.discountValue);
      this.minBuyAmount = Number(promotion.minimumBuyAmount);
      
      let allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
      

      if (!allowWithOtherPromotions) {
        this.allItems = this.allItems.filter(saleItem => !((<any>saleItem).hasPromotion))
      }
      
      for (let si of this.allItems) {
        if (si.item.promoBuy.split("&").includes(promotion.code)) {
          buyItems.push(si);
        }
      }
      if (promotion.isHappyHour) {
        buyItems = buyItems.filter(saleItem => NewPromotionsEngine.happyHourIsActiveForSaleItem(promotion, saleItem));
      }
      let buyItemsTotal = _.sumBy(buyItems,'unitPrice');
      

      if (buyItemsTotal >= this.minBuyAmount && buyItemsTotal <= this.maxBuyAmount) {
        this.parameters.promoGroups = [this.createPromoGroup(promotion,buyItems)]
      }

    }

    private createPromoGroup(promotion,tempArr) {
      let getPromoGroup = {
        itemsCounter: {},
        rowValueCounter: {},
        promotion: promotion,
        discountAmountForGroup: 0,
        totalPriceForItemsBeforeDiscount: 0
      }
      getPromoGroup.totalPriceForItemsBeforeDiscount += _.sumBy(tempArr,'unitPrice');
      getPromoGroup.discountAmountForGroup = 0;
      tempArr.forEach(item => {
        this.addToItemsCounter(item,getPromoGroup.itemsCounter);
        this.addToRowValueCounter(item,getPromoGroup.rowValueCounter);
      });
      return getPromoGroup;
    }
  }
  }}}
  