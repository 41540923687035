<script setup lang="ts">
import { globalStore, languageManagerStore } from '@/Store/Pinia';
import { computed, onMounted, ref, watch, reactive, nextTick } from 'vue';
import { twMerge } from 'tailwind-merge';
import { onLongPress, useDebounceFn } from '@vueuse/core'

const props = defineProps({
    modelValue: {
        required: false,
        type: String,
        default: '',
    },
    layout: {
        required: false,
        type: [String, Array, Object],
        default: ["1 2 3 4 5 6 7 8 9 0 . {bksp}"],
    },
    display: {
        required: false,
        type: Object,
        default: {
            '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
        }              
    },
    buttonStyle: {
        required: false,
        type: String,
        default: ''
    },
    keyboardStyle: {
        required: false,
        type: String,
        default: ''
    },
    spaceButtonStyle: {
        required: false,
        type: String,
        default: ''
    },
    inputRef: {
        required: false,
        type: HTMLInputElement,
        default: null
    }
});

const emit = defineEmits(['update:modelValue', 'onKeyPress']);
const currentLayout = ref('default'); // Track the current layout state
const refKeys = reactive({});
const longPressIntervalFlag = ref(false);

const firstKeypressAfterShift = ref(false); // Track the first keypress after a language switch

function handleBackSpace() {
    if (!props.inputRef) {
        emit('update:modelValue', props.modelValue.slice(0, -1));
        return;    
    }
    const inputTag = props.inputRef;
    let [start, end] = [inputTag.selectionStart, inputTag.selectionEnd];
    if (start == 0 && end == 0) { // The caret was placed nowhere
        emit('update:modelValue', props.modelValue.slice(0, -1));
    } else if (start == end) { // User placed the caret somewhere and pressed backspace
        let start = inputTag.selectionStart - 1;
        let newValue = props.modelValue.slice(0, start) + props.modelValue.slice(inputTag.selectionEnd);
        emit('update:modelValue', newValue);
    } else { // User selected a range of text to delete
        let start = inputTag.selectionStart;
        let end = inputTag.selectionEnd;
        let newValue = props.modelValue.slice(0, start) + props.modelValue.slice(end);
        emit('update:modelValue', newValue);
    }
    if (start == 0 && end == 0) {
        return;
    }
    nextTick().then(() => {
        // Stay focused on the input and place caret where it was
        inputTag.focus();
        let newPosition = Math.max(start - 1, 0);
        inputTag.setSelectionRange(newPosition, newPosition);
    });  
}
function handleRegularKeyPress(keyChar) {
    if (!props.inputRef) {
        emit('update:modelValue', props.modelValue + keyChar);
        return;    
    }
    const inputTag = props.inputRef;
    let [start, end] = [inputTag.selectionStart, inputTag.selectionEnd];
    if (start == 0 && end == 0) { // The caret was placed nowhere
        emit('update:modelValue', props.modelValue + keyChar);
    } else if (start == end) { // User wants to insert a character at the caret position
        let start = inputTag.selectionStart;
        let newValue = props.modelValue.slice(0, start) + keyChar + props.modelValue.slice(inputTag.selectionEnd);
        emit('update:modelValue', newValue);
    } else { // User selected a range of text to replace with the keyChar
        let start = inputTag.selectionStart;
        let end = inputTag.selectionEnd;
        let newValue = props.modelValue.slice(0, start) + keyChar + props.modelValue.slice(end);        
        emit('update:modelValue', newValue);
    }
    if (start == 0 && end == 0) {
        return;
    }
    nextTick().then(() => {
        // Stay focused on the input and place caret where it was
        inputTag.focus();
        let newPosition = Math.max(start + 1, 0);
        inputTag.setSelectionRange(newPosition, newPosition);
    });
}
function onkeypress(button: string) {
    let isSpecialKey = button.startsWith('{') && button.endsWith('}');
    if (button === '{shift}') {
        if(currentLayout.value != 'default') {
            currentLayout.value = 'default';
        } else {
            currentLayout.value = 'caps';
            firstKeypressAfterShift.value = true;
        }
    } else if (button === '{caps}') {
        // Toggle 'caps' layout
        currentLayout.value = (currentLayout.value === 'caps') ? 'default' : 'caps';
    } else if (button === '{caps-fill}') {
        // Toggle between 'caps' and 'shift' directly
        currentLayout.value = (currentLayout.value !== 'shift') ? 'shift' : 'caps';
    } else if (button === '{bksp}') {
        // Handle backspace
        handleBackSpace();
    } else if (button === '{space}') {
        // Handle space
        handleRegularKeyPress(' ');
    } else if (!isSpecialKey) {
        // Handle regular keypress
        handleRegularKeyPress(button);
        if (firstKeypressAfterShift.value) {
            firstKeypressAfterShift.value = false; // Reset flag after first keypress
            if (currentLayout.value == 'caps') { 
                currentLayout.value = 'shift'; 
            }
        }
    }

    emit('onKeyPress', button);
}
function debouceLongPressLoop(key) {
    if (!longPressIntervalFlag.value) {
        return;
    }
    onkeypress(key);
    useDebounceFn(() => debouceLongPressLoop(key), 50)();
}
function onMouseUpCallback() {
    longPressIntervalFlag.value = false;
}

watch(() => languageManagerStore.currentLang, () => {
    if(languageManagerStore.currentLang == 'en'){
        currentLayout.value = 'caps';
        firstKeypressAfterShift.value = true;
    }else if(languageManagerStore.currentLang == 'he'){
        currentLayout.value = 'default';
        firstKeypressAfterShift.value = true;
    }
})

const displayOptions = computed(() => {
    return {
        '{space}': ' ',
        ...props.display,
    };
});


function getGridClass() {
    return `flex flex-row items-center justify-center gap-2`;
}

const parsedLayout = computed(() => {
    let layoutData;

    if (typeof props.layout === 'object' && !Array.isArray(props.layout)) {
        layoutData = props.layout[currentLayout.value] || props.layout.default;
    } else {
        layoutData = props.layout;
    }

    if (Array.isArray(layoutData)) {
        return layoutData.map(layoutString => {
            let layoutSplit = layoutString.split(" ").reverse();
            return layoutSplit.map((val) => ({ display: displayOptions.value[val] || val, value: val }));
        });
    } else {
        let layoutSplit = layoutData.split(" ").reverse();
        return [layoutSplit.map((val) => ({ display: displayOptions.value[val] || val, value: val }))];
    }
});

const keyboardStyle = computed(() => {
    let tailwindClasses = '';
    if (globalStore.simpleSelfService) {
        tailwindClasses = 'bg-trnasparent'
    } else {
        tailwindClasses = 'bg-gray-300'
    }
    return twMerge(tailwindClasses, props.keyboardStyle);
});

const buttonStyle = computed(() => {
    let tailwindClasses = '';
    if (globalStore.simpleSelfService) {
        tailwindClasses = 'select-none bg-disabled-color border border-white border-4 rounded-lg shadow-md p-2 min-h-20 cursor-pointer active:bg-gray-400 font-bold font-assistant text-4xl'
    } else {
        tailwindClasses = 'select-none bg-white border border-disabled-color rounded-md p-2 min-h-10 cursor-pointer active:bg-gray-400'
    }
    return twMerge(tailwindClasses, props.buttonStyle);
});

const spaceButtonStyle = computed(() => {
    let tailwindClasses = 'flex-1';
    return twMerge(tailwindClasses, props.buttonStyle, props.spaceButtonStyle);
});

onMounted(() => {
    if (languageManagerStore.currentLang === 'en' && typeof props.layout === 'object' && !Array.isArray(props.layout)) {
        currentLayout.value = 'caps';
        firstKeypressAfterShift.value = true;
    }    
});

watch(refKeys, (newRefKeys) => {
    let keys = Object.keys(newRefKeys);
    for (const key of keys) {
        if (['{caps}', '{shift}', '{caps-fill}'].includes(key)) {
            return;
        }
        longPressIntervalFlag.value = false;
        onLongPress(newRefKeys[key], () => {
            longPressIntervalFlag.value = true;
            debouceLongPressLoop(key);
        }, { delay: 600, distanceThreshold: 24, onMouseUp: (dur, dis, ilp) => onMouseUpCallback(), modifiers: { stop: true } });
    }
});

</script>
<template>
    <div class="grid gap-2 p-2 w-max mx-auto dir-rtl" :class="keyboardStyle">
        <div v-for="(layoutRow, index) in parsedLayout" :key="index" :class="getGridClass()">
            <button v-for="button in layoutRow" :key="button.value" :ref="(e) => (refKeys[button.value] = e)"
                :class="twMerge(buttonStyle, button.value === '{space}' ? spaceButtonStyle : 'aspect-square')"
                @click.stop="onkeypress(button.value)" v-html="button.display"></button>
        </div>
    </div>
</template>
