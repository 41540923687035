module PositiveTS {
  export module Components {
    export module selfServiceSittingTaDialog {

      
      const _vueComponentName = 'self-service-sitting-ta-dialog';

      export function create() {
        let selfServiceSittingTaDialog = {
          template: JST.selfServiceSittingTaDialog(),
          components: { 
            selfServicePage: SelfServicePage.getComponent(),            
            positiveIcon: PositiveIcon.getComponent()
           },
           setup(){

            const globalStore = Pinia.useGlobalStore();

            const {setIsSelfServiceTa} = globalStore
            const globalStoreActions = {setIsSelfServiceTa}


            return {...globalStoreActions}
          },
          methods: {
            open() {
              this.$el.showModal();
            },
            async setIsTaAndFinish(isTa: boolean = false) {
              Service.SittingTA.setIsTA(isTa);
              this.close();
            },
            close() {
              this.$el.close();
            },
          }
        };

        VueApp.component(_vueComponentName, selfServiceSittingTaDialog)
      }
    }
  }
}
