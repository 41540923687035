module PositiveTS {
    export module Components {
    export module Mixins {
    export var paymentViewMixin = {
        methods: {
            onKeyTapped(key) {
                let field = this.getSelectedField();
                this.keyPadHanlder(key,field,this);
            },
            getSelectedField() {
                for (let fieldName in this.fields) {
                  if (this.fields[fieldName].selected) {
                    return this.fields[fieldName];
                  }
                }
                return undefined;
            },
            clearTapped() {
                for (let fieldName in this.fields) {
                    this[fieldName] = this.initialValues[fieldName];
                    this.fields[fieldName].hasDecimal = false
                }
            },
            setSelectedField(field:string, selectText = false) {
                for (let fieldName in this.fields) {
                    if(field == this.fields[fieldName].model) {
                        this.fields[fieldName].selected = true;

                        if (selectText) {
                            this.$refs[fieldName].select();
                        }
                    } else {
                        this.fields[fieldName].selected = false;
                    }
                }
            },
            setCalculatedAmount() {
              if (this.isSplitPaymentActive()) {
                this.setSplitPaymentAmount();
              } else {
                let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                let amountLeft = totals.totalAmount - totals.totalPaid;
        
                if (this.amount != amountLeft) {
                  this.amount = session.fixedFloat(amountLeft);
                }
              }        
            },

            // SPLIT PAYMENT METHODS
            isSplitPaymentActive() {
                return this.splitTotalAmountEqually && posUtils.isDefined(this.splitPaymentData);
             },
             setSplitPaymentAmount() {
                this.amount = this.getSplitPaymentAmount()
             },
             
             getSplitPaymentAmount () {
                let amount = this.currentSplittedPaymentAmount;

                if (Service.MultiCurr.getInstance().isMultiCurr()) {
                amount = Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(amount);
                }

                return session.fixedFloat(amount, 1)
             },

             addSplittedPaymentIfNeeded(numberOfPayments = 1) {
                if (this.isSplitPaymentActive()) {
                   Pinia.globalStore.addSplittedPayment({
                      type: this.$el.id,
                      amount: numberOfPayments,
                   })
                }
             },
             // numberOfPayments - null == remove all
             removeSplittedPaymentIfNeeded(numberOfPayments = 1) {
                if (this.isSplitPaymentActive()) {
                   Pinia.globalStore.removeSplittedPayment({
                      type: this.$el.id,
                      amount: numberOfPayments,
                   })
                }
             },
             setSplitPaymentAmountIfNeeded() {
                if (this.isSplitPaymentActive()) {
                   this.setSplitPaymentAmount();
                }
             },
        },
        computed: {
            // SPLIT PAYMENT COMPUTED
            splitTotalAmountEqually() {
               return jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually);
            },
            splitPaymentData(){
                return Pinia.globalStore.splitPaymentData
            },
            currentSplittedPaymentAmount(){
                return Pinia.globalStore.currentSplittedPaymentAmount
            },
            leftAmount(){
                return Pinia.globalStore.leftAmount
            }
        },
        data() {
            return {
                clearImg: `${(<any>window).images_path}/close.png`,
            }
        },
        mounted() {
                        if(!posUtils.isNullOrUndefined(this.fields) && !posUtils.isNullOrUndefined(this.initialValues)) {
                for (let fieldName in this.fields) {
                    if(this.fields[fieldName] == undefined || this.initialValues == undefined) {
                        console.error(i18next.t("posPayment.missingRequiredMixinFields", {id: this.$el.id}));
                    }
                }
            }
        },
        watch: {
            // SPLIT PAYMENT WATCH
            leftAmount() {
               if (jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually) && posUtils.isDefined(this.splitPaymentData)) {
                  this.setSplitPaymentAmount();
               }
            }
         }
    }
}}}