module PositiveTS {
export module Storage {
export module Entity {
export class Barset extends IDBEntity {

	seals: string;
	barset_items: any[];
	name: string;
	status: number;

	constructor() {
		let meta = {
			name: 'Barset',
			fields: {
				seals: "JSON",
				barset_items: "JSON",
				name: "TEXT",
				status: "INT",
			},
		}
		super(meta)
	}

	static calculateCurrentInventory(lastCount, sales) {
		let count = _.clone(lastCount);
		for (let sale of sales) {
			for (let item of sale.items) {
				let barsetItems = Pinia.flightsStore.currentBarsetItemsByItemCode[item.itemCode];

				if (barsetItems && barsetItems.length > 0) {
					let quantityToReduce = item.quantity;

					// Reduce from the barset items that have enogh qty
					for (let barsetItem of barsetItems) {
						count[barsetItem.id] = parseInt(count[barsetItem.id] || 0);

						if (count[barsetItem.id] > 0) {
							let currentQtyToReduce = Math.min(quantityToReduce, count[barsetItem.id]);

							count[barsetItem.id] -= currentQtyToReduce;
							quantityToReduce -= currentQtyToReduce;
						}

						if (quantityToReduce == 0) {
							break;
						}
					}

					// If there is still items to reduce, remove from last item
					if (quantityToReduce > 0) {
						let lastBarsetItem = barsetItems[barsetItems.length - 1];
						count[lastBarsetItem.id] -= quantityToReduce;
					}
				}
			}
		}

		return count;
	}
}}}}
