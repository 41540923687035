module PositiveTS {
export module Components {
    export module DuplicateBarcodeDialog {
      const dialogSelector = "#duplicate-barcode-dialog"


      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }




      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '80%',
              height: 400,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          rejectFunc: null,
          resolveFunc: null,
          loading: true,
          items: []
        }
      }

      function close() {
        $(dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
        this.cleanData();
      }

      function cancel() {
        this.resolveFunc(null)
        this.close()
      }

      function open(codes) {
        return new Promise((resolve,reject) => {
          this._initjQueryDialogIfNeeded()
          $(document).unbind('keypress');
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.loading = true
          PositiveTS.Service.WasmDB.promiseSql(`select * from item where code in (${codes.map(code => `'${code}'`).join(',')})`)
          .then(result => {
            if (result.length === 0) {
              this.rejectFunc('no items found - closing')
              this.close()
              return
            }
            for (var i = 0; i < result.length; i++) {
              let entityItem = new PositiveTS.Storage.Entity.Item();
              entityItem.importFromObject(result[i]);
              this.items.push(entityItem);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err) {
              console.error(err)
            }
            this.showGenericError()

          })
          $(dialogSelector).dialog('open');

        })

      }

      function selectItem(item) {
        this.resolveFunc(item)
        this.close()
      }


      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.rejectFunc('general error')
          this.close();
        },null)
      }

      function showError(errStr) {
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      export function create() {

        let component = {
            template: JST.duplicateBarcodeDialog(),
            methods: {
              cleanData: cleanData,
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              cancel: cancel,
              close: close,
              showError: showError,
              selectItem: selectItem,
              showGenericError: showGenericError,
            },
            data: initData

        }

        VueApp.component('duplicate-barcode-dialog',component)

      }
    }
}
}
