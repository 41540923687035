module PositiveTS {
export module Service {
export class Goodi extends SmartVoucher {
    terminalId:string
    restaurantId:string
    soapEnvelopeAttributes:Array<SOAPRequestGenerator.SOAPAttribute>
    url:string
    constructor() {
        super();
        this.terminalId = jsonConfig.getVal(jsonConfig.KEYS.goodiTerminalId);
        this.restaurantId = jsonConfig.getVal(jsonConfig.KEYS.goodiRestaurantId);

        this.soapEnvelopeAttributes = new Array();
        this.soapEnvelopeAttributes.push({ name: "xmlns:xsi", value: "http://www.w3.org/2001/XMLSchema-instance"});
        this.soapEnvelopeAttributes.push({ name: "xmlns:xsd", value: "http://www.w3.org/2001/XMLSchema"});
        this.soapEnvelopeAttributes.push({ name: "xmlns:soap", value: "http://schemas.xmlsoap.org/soap/envelope/"});
        this.hasCVV = false;
        this.url = "https://www.goodi.co.il/WebServices/WSgoodi.asmx"


    }
    async getBalance(cardNumber: string, cvv?: string): Promise<GetBalanceResponse> {
        let soapFunction = this.createSoapRequest("GetBalance");
        if(session.pos.isCaveret) {
            soapFunction = this.createSoapRequest("GetBalance2");
        }
        soapFunction.params.push({name: "CardNumber", value: this.sanitizeCardNumber(cardNumber, session.pos.isCaveret)});
        let response = await Service.SOAPRequestGenerator.sendRequest(soapFunction, this.soapEnvelopeAttributes, this.url, "goodi");

        if (response){
            let result;
            if(session.pos.isCaveret) {
                result = response.GetBalance2Response.GetBalance2Result.split("|");
            } else {
                result = response.GetBalanceResponse.GetBalanceResult.split("|");
            }
            if(result.length == 2) {
                let balance = result[0];
                if(session.pos.isCaveret) {
                    balance = result[1];
                }
                return {
                    success: true,
                    error: "",
                    balance: balance.replace(",","")
                }
            }
            else {
                let errorMsg = i18next.t("goodi.error.default");
                if(session.pos.isCaveret) {
                    result = response.GetBalance2Response.GetBalance2Result;
                } else {
                    result = response.GetBalanceResponse.GetBalanceResult;
                }   
                if(i18next.te(`goodi.error.${result}`)){
                    errorMsg = i18next.t(`goodi.error.${result}`);
                }
                return {
                    success: false,
                    error: errorMsg,
                    balance: 0
                }
            }
        }
        else{
            return {
                success: false,
                error:  i18next.t("goodi.error.default"),
                balance: 0
            }
        }
        
       
    }    
    async pay(voucherData: any, amount: number, cvv?: string, companyId?: any): Promise<any> {
        let soapFunctions = this.createSoapRequest("ExecuteTransaction");
        soapFunctions.params.push({name: "Price", value: String(amount)});
        soapFunctions.params.push({name: "CardNumber", value: this.sanitizeCardNumber(voucherData.barCode, session.pos.isCaveret)});
        let response = await Service.SOAPRequestGenerator.sendRequest(soapFunctions, this.soapEnvelopeAttributes, this.url, "goodi");
        if (response){
            let result = response.ExecuteTransactionResponse.ExecuteTransactionResult.split("|");

            if(result[0] == "2000") {
                if(isNaN(result[2])) {
                    if(typeof result[2] == "string") {
                        result[2] = result[2].split(",").join("");
                    }
                    if(isNaN(result[2])) {
                        await this.sendCancelRequest(result[1])
                        let errorText = i18next.t(`goodi.error.responseError`);
                        throw new Error(errorText);
                    }
                }
                let data = {
                    transactionID: result[1],
                    amount: result[2]
                }
                voucherData.data = JSON.stringify(data);
                voucherData.smartVoucherType = Storage.Entity.Voucher.SMART_VOUCHER_GOODI;
                voucherData.allowPartialReturn = false;
                voucherData.amount = Number(data.amount);
                voucherData.isTaxInvoice = session.pos.isGoodiTaxInvoice;
                await Service.GoodiDiscountItemService.addDiscount(amount-Number(data.amount));
                return voucherData;
            } else {
                let errorText = i18next.t(`goodi.error.default`);
                if(i18next.te(`goodi.error.${result[0]}`)) {
                    errorText = i18next.t(`goodi.error.${result[0]}`);
                }
                throw new Error(errorText);
            }
        }
        else{
            let errorText = i18next.t(`goodi.error.default`);
            throw new Error(errorText);
        }
    }
    cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
        let transactionID = JSON.parse(voucherToCancel.data).transactionID;
        return this.sendCancelRequest(transactionID);
    }

    async sendCancelRequest(transactionID): Promise<any> {
        let soapFunctions = this.createSoapRequest("RefundTransaction");
        soapFunctions.params.push({name: "TransactionID", value: transactionID});
        soapFunctions.params.push({name: "RestaurantID", value: this.restaurantId});
        try {
            let response = await Service.SOAPRequestGenerator.sendRequest(soapFunctions, this.soapEnvelopeAttributes, this.url, "goodi")
            if(response.RefundTransactionResponse.RefundTransactionResult == "2000"){
                return ({
                    success: true,
                    error: ""
                    })
            }
            else{
                throw Error(response);
            }
        }   
        catch(err) {
            return ({
                success: false,
                error: err.message
              });
        }
    }
    cancelLoad(paymentToCancel: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
        throw new Error("Method not implemented.");
    }
    getCardNumber(): Promise<GetCardNumberResponse> {
        throw new Error("Method not implemented.");
    }
    async testConnection() {
        let soapFunction = this.createSoapRequest("ValidateTerminal");
        return await Service.SOAPRequestGenerator.sendRequest(soapFunction, this.soapEnvelopeAttributes, this.url, "goodi");
    }
    private createSoapRequest(functionName) {
        return {
            name: functionName,
            attributes: [{name: "xmlns", value:"http://tempuri.org/"}],
            params: [{name: "TerminalID", value: this.terminalId},
                     {name: "RestaurantID", value: this.restaurantId}]
        }
    }
    private sanitizeCardNumber(cardNumber:string, isCaveret:boolean) {
        if(isCaveret) {
            if(cardNumber.length < 34) return cardNumber;
            return cardNumber.substring(25, 34);
        }
        return cardNumber;
    }
}}}
