module PositiveTS {
export module Application {
export module Controllers {
export class ParamsViewController extends AbstractViewControllerTS {
	
	// --------------------------------------------------------------------
	// Controller Life-Cycle
	// --------------------------------------------------------------------
	init(options) {
	}
	heYesNo(bl){
		if (bl) {
			return i18next.t("yes");
		} else {
			return i18next.t("no");
		}
	}
	
	resume(options) {
		
		var that = paramsVC;
		PositiveTS.Service.Addon.isAddonInstalled()
		.then(async function(isAddonInstalled) {
			// Load the POS params
			if (session.pos !== null) {
				$('#params-pos-params-set-id').text(session.pos.paramsetID);
				$('#params-pos-params-decimal-precision').text(session.pos.parameterDecimalPrecision);
				
				$('#params-new-addon-installed').text(that.heYesNo(isAddonInstalled));
				
				var isZMasterSlave = serviceZMasterSlave.isZMasterSlave;
				$('#params-pos-params-isPrimaryPosExists').text(that.heYesNo( isZMasterSlave ));
				var isPrimaryPos = false;
				if (isZMasterSlave && serviceZMasterSlave.role === 1) {
					isPrimaryPos = true;
				}
				$('#params-pos-params-isPrimaryPos').text(that.heYesNo( isPrimaryPos ));
				new PositiveTS.Service.Multipass().setup().then(function(result){
					$('#params-pos-params-multipass').text(result.pos_parameters);
				});
				
				
				$('.params-PositiveVersionAppVersion').text(PositiveTS.Version.appVersion);
				$('.params-PositiveVersionAppDate').text(PositiveTS.Version.appDate);
				
				if(session.isAndroid){
					$('#params-androidVersion').text(Android.getVersion());
					if(Android.getPCLVersion != undefined && !jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment)){
						$('#params-androidPCLVersion').text(Android.getPCLVersion());
					}
				}
				
				if (!Boolean(session.pos.usePinPad)) {
					$('#params-pos-pin-pad-port').hide();
				}
				else {
					$('#params-pos-params-pin-pad-port-number').text(
						jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber) + ' (COM' + jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber) + ')')
					}
					
					var printerName = "OS Default";
					if (printer.jzebra.canSetDefaultPrinter) {
						printerName = printer.jzebra.getDefaultPrinter() || printerName;
					}
					$('#params-printer-name').text(printerName);
					$('#params-printer-name').unbind("click");
					$('#params-printer-name' ).click(function() {
						PositiveTS.Dialogs.PrinterDefault.selectPrintrer()
						.then(function(printerSelected){
							$('#params-printer-name').text(printerSelected);
							printer.jzebra.setDefaultPrinter(printerSelected);
						}).catch(() => {
							
						});
					});
					
					// if (jsonConfig.getVal(jsonConfig.KEYS.isLogicalPrinter)){ 
					//TODO: bon-printer check better condition if to show or not
					$("params-logical-printer-bon-print").show()
					// } else {
					// 	$("params-logical-printer-bon-print").hide()
					// }
					
					
					var scaleport = PositiveTS.Service.Scale.getSelectedCom()|| "לא מוגדר";
					$('#params-scale-name').text(scaleport);
					$('#params-new-addon-filesVer').html(`<div class="positive-small-lds-ring"><span></span><span></span><span></span><span></span></div>`);
					
					let addonVersions = [];
					await PositiveTS.Service.FileUpload.addonManifestVersions().then(function(response){
						addonVersions = response.versions
					})

					await PositiveTS.Service.FileUpload.addonLocalManifestVer().then(function(currentVersion){
						$('#params-new-addon-filesVer').text(currentVersion);
					})


					$('#params-new-addon-filesVer').unbind("click");
					$('#params-new-addon-filesVer' ).click(async function() {

						try{
							let currentVersion = $('#params-new-addon-filesVer').text();
							if(posUtils.isBlank(currentVersion)) return;

							let addonDialogResult = await PositiveTS.VueInstance.$refs.addonFilesUpdateDialogComponent.open(addonVersions,currentVersion);
							if(addonDialogResult.versionToUpdate == currentVersion) return;
							
							app.showLoadingMessage(i18next.t('addonUpdate.updatingMessage'));
							await PositiveTS.Service.FileUpload.addonFilesUpdate(addonDialogResult.versionToUpdate);
							await PositiveShared.Utils.sleep(3000);

							app.showAlert({
								header: i18next.t('addonUpdate.success'),
								content: `${i18next.t('addonUpdate.version')} ${i18next.t('addonUpdate.successMessage') }`,
								continueButtonText: i18next.t("ok"),
								hideCancelButton: true
							}, function () {
								// Update settings screen status to ok
								return window.location.reload();
							}, null);
							app.hideLoadingMessage();


						} catch(err){
							app.hideLoadingMessage();
							console.error(err)
						}
					});
					
					switch (session.pos.parameterRequireSalesperson) {
						case PositiveTS.Storage.Entity.Pos.REQUIRE_MANDATORY:
						$('#params-pos-params-require-salesperson').text(i18next.t("mandatory"));
						break;
						case PositiveTS.Storage.Entity.Pos.REQUIRE_OPTIONAL:
						$('#params-pos-params-require-salesperson').text(i18next.t("optional"));
						break;
						case PositiveTS.Storage.Entity.Pos.REQUIRE_DISABLED:
						$('#params-pos-params-require-salesperson').text(i18next.t("disabled"));
						break;
						default:
						$('#params-pos-params-require-salesperson').text(i18next.t("unknown"));
						break;
					}
					
					switch (session.pos.parameterRequireCustomer) {
						case PositiveTS.Storage.Entity.Pos.FIELD_MANUAL:
						$('#params-pos-params-require-customer').text(i18next.t("manual"));
						break;
						case PositiveTS.Storage.Entity.Pos.FIELD_AUTOMATIC:
						$('#params-pos-params-require-customer').text(i18next.t("automatic"));
						break;
						default:
						$('#params-pos-params-require-customer').text(i18next.t("unknown"));
						break;
					}
					
					switch (session.pos.parameterEnforceInventory) {
						case PositiveTS.Storage.Entity.Pos.ENFORCE_PREVENT:
						$('#params-pos-params-enforce-inventory').text(i18next.t("prevent"));
						break;
						case PositiveTS.Storage.Entity.Pos.ENFORCE_WARN:
						$('#params-pos-params-enforce-inventory').text(i18next.t("warn"));
						break;
						case PositiveTS.Storage.Entity.Pos.ENFORCE_DISABLED:
						$('#params-pos-params-enforce-inventory').text(i18next.t("disabled"));
						break;
						default:
						$('#params-pos-params-enforce-inventory').text(i18next.t("unknown"));
						break;
					}
					
					switch (session.pos.parameterEnforceInventoryLevel) {
						case PositiveTS.Storage.Entity.Pos.ENFORCE_LEVEL_ITEM:
						$('#params-pos-params-enforce-inventory-level').text(i18next.t("item"));
						break;
						case PositiveTS.Storage.Entity.Pos.ENFORCE_LEVEL_SIZE:
						$('#params-pos-params-enforce-inventory-level').text(i18next.t("size"));
						break;
						default:
						$('#params-pos-params-enforce-inventory-level').text(i18next.t("unknown"));
						break;
					}
					
					if (session.pos.parameterAllowNegativeSale) {
						$('#params-pos-params-allow-negative-sale').text(i18next.t("managerApproval"));
					} else {
						$('#params-pos-params-allow-negative-sale').text(i18next.t("blocked"));
					}
					
					if (session.pos.parameterIsAutomaticEmployeeStoreConnection) {
						$('#params-pos-params-is-automatic-employee-store-connection').text(i18next.t("automatic"));
					} else {
						$('#params-pos-params-is-automatic-employee-store-connection').html(i18next.t("manual"));
					}
					
					$('#params-pos-params-max-check-days').text(session.pos.parameterMaxCheckDays);
					$('#params-pos-params-max-credit-voucher-days').text(session.pos.parameterMaxCreditVoucherDays);
					$('#params-pos-params-max-credit-card-payments').text(session.pos.parameterMaxCreditCardPayments);
					$('#params-pos-params-allow-typing-credit-card').text(session.pos.parameterAllowTypingCreditCard ? i18next.t("possible") : i18next.t("inPossible"));
					
					$('#params-pos-params-invoice-suffix').text(session.pos.parameterDebitInvoiceSuffix);
					$('#params-pos-params-credit-invoice-suffix').text(session.pos.parameterCreditInvoiceSuffix);
					$('#params-pos-params-credit-voucher-suffix').text(session.pos.parameterCreditVoucherSuffix);
				}
				
				// Wait for the applet to load
				var observer = function (object) {
					PositiveTS.NotificationCenter.removeObserver(observer);
				};
				PositiveTS.NotificationCenter.removeObserver(observer);
				PositiveTS.NotificationCenter.addObserver(observer, printer.PrinterChangedNotification);
				
			});
	}

	stop() {
	}

	destroy() {
	}
		
}

}}}
declare var paramsVC:PositiveTS.Application.Controllers.ParamsViewController;
paramsVC = new PositiveTS.Application.Controllers.ParamsViewController();
PositiveTS.Application.Controllers.instances.ParamsViewController = paramsVC;
			