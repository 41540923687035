module PositiveTS {
export module Components {
export module PosPayment {
export module SafeCashView {
  interface paymentResponse {
    orderId: string,
    amount: number,
    status: number
  }

  export function getComponent() {
    const component = {
      template:  JST.SafeCashView(),
      mixins: [keyPadMixin, Mixins.paymentViewMixin],
      methods: {
        selectField (fieldName) {
          let refField = null;
          //not liking this code so much... but want to make sure that number will be rounded
          this.amount = session.fixedFloat(this.amount);
          for (let field in this.fields) {
            this.fields[field].selected = false
            this.fields[field].inEditMode = true
            this.fields[field].hasDecimal = false
            this.fields[field].max = this.leftAmount
            if (field == fieldName) {
              this.fields[field].selected = true
              refField = field
            }
          }
          if (refField != null) {
            this.$refs[refField].focus();
          }
        },

        onKeyTapped (key) {
          let field = this.getSelectedField();
          this.keyPadHanlder(key,field,this);
        },
      
        getSelectedField () {
          for (let fieldName in this.fields) {
            if (this.fields[fieldName].selected) {
              return this.fields[fieldName]
            }
          }
          return undefined;
        },

        clearTapped () {
          this.clearOldData();
          this.selectField('amount');
        },

        async QRPayment (el) {
          el.target.disabled = true;
          try{
            const safeCashPayment = new Service.SafeCashPayment(this.amount, 'QR')

            app.showLoadingMessageWithCancel({whiteTheme: true, fontSize: '1.25rem', message: i18next.t('safeCash.waitingGetPaymentFromCustomer')},
            paymentData => safeCashPayment.cancelRequest(paymentData), safeCashPayment)
            let result = await safeCashPayment.request()
            app.hideLoadingMessage()

            if (result.success){
              if (!result.isCanceled){
                this.verifyAndPay(safeCashPayment, result.response)
              }
            }else{
              if(!result.ignoreErrorMessage){
                app.showErrorAlert(result.error)
              }
            }

            app.hideLoadingMessageWithCancel()
            el.target.disabled = false
          }catch(e){
            PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalAmount()));
            app.hideLoadingMessageWithCancel();
            el.target.disabled = false;
      
            if (e !== 'cancelRequest'){
              console.error(e);
              app.promiseShowAlert({
                header: i18next.t('error'),
                content: e.error,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            }
          }
        },

        async codePayment (el) {
          el.target.disabled = true;
          try{
            const safeCashPayment = new Service.SafeCashPayment(this.amount, 'CODE')

            app.showLoadingMessage(i18next.t('safeCash.createRequestForCodePayment'))
            let responsePaymentCode = await safeCashPayment.requestCreateCode()
            app.hideLoadingMessage()

            if (responsePaymentCode.success){
              await app.showLoadingMessageWithCancel({
                whiteTheme: true,
                fontSize: '1.25rem',
                message: `${i18next.t('safeCash.codeForPay', {code: responsePaymentCode.code})}\n\n${i18next.t('safeCash.waitingGetPaymentFromCustomer')}`,
                }, paymentData => safeCashPayment.cancelRequest(paymentData), safeCashPayment)
              let resultPayment = await safeCashPayment.request(responsePaymentCode.transactionId)
              if (resultPayment.success){
                if (!resultPayment.isCanceled){
                  this.verifyAndPay(safeCashPayment, resultPayment.response)
                }
              }else {
                app.showErrorAlert(resultPayment.error)
              }
              
              app.hideLoadingMessageWithCancel()
            }else {
              app.showErrorAlert(responsePaymentCode.error)
            }

            el.target.disabled = false;
          }catch(e){
            PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalAmount()));
            app.hideLoadingMessageWithCancel();
            el.target.disabled = false;
      
            if (e !== 'cancelRequest'){
              console.error(e);
              app.promiseShowAlert({
                header: i18next.t('error'),
                content: e.error,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            }
          }
        },
        async  verifyAndPay(safeCashPayment, response){
          const sanitizedResponse = {
            ...response,
            companyBranchName: posUtils.removeQuotesAndCommas(response.companyBranchName),
            companyName: posUtils.removeQuotesAndCommas(response.companyName),
          }
          try {
            const additionalData: PositiveTS.Service.SafeCashPaymentAdditionalData = {
              requestPaymentData: safeCashPayment.requestPaymentData,
              responsePaymentData: sanitizedResponse,
            }
            
            await PositiveTS.Service.VoucherPayment.payBySmartVoucherType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH, response.amount, null, null, additionalData);
            this.addSplittedPaymentIfNeeded();
          }catch(e){
            app.hideLoadingMessage();
            app.promiseShowAlert({
              header: i18next.t('error'),
              content: e.message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
          }
      
          await this.refreshView();
        },

        clearOldData () {
          let result = initData();
          for (let prop in result) {
            if (prop === 'creditPayments' || prop === "amount") {
              continue;
            }
            this[prop] = result[prop];
          }
        },

        async deleteCreditPayment (index) {
          try{
      
            let result = await app.promiseShowAlert({
              header: i18next.t('confirmSalePaymentItemDeleteHeader'),
              content: i18next.t('confirmSalePaymentItemDelete'),
              continueButtonText: i18next.t('remove'),
              cancelButtonText: i18next.t('cancel')
            });
      
            if (result === "continue"){
              await PositiveTS.Service.VoucherPayment.removeSmartVoucherPayment(this.creditPayments[index]);
              this.removeSplittedPaymentIfNeeded();
            }
      
          }catch(e){
            console.error(e);
            app.hideLoadingMessage()
            app.showErrorAlert(i18next.t('safeCash.responseErrorSafeCash'))
          }
      
          await this.refreshView();
        },

        limitValue(event) {
          if (Number(event.target.value) > Number(event.target.max)) {
            this.amount = Number(event.target.max);
          }
        },

        async  refreshView () {
          try{
      
            this.setCalculatedAmount();
      
            let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH)
            this.creditPayments = result;
            this.clearTapped();
            await PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalLeftToPay()));
            await PositiveTS.VueInstance.$nextTick()
          }catch(e){
            console.error(e);
          }
        },
      },
      setup(){

        const globalStore = Pinia.useGlobalStore();
        const {leftAmount} = Pinia.storeToRefs(globalStore);
        const globalStoreProps = {leftAmount} 

        return {...globalStoreProps}
      },
      data: initData
    }
    return component
  }
  
  function initFields() {
    let fields = {
      'amount': {
        selected: false,
        allowDecimal: true,
        inEditMode: false,
        type: 'number',
        disabled: false
      }
    };

    for (let field in fields) {
      fields[field].model = field;
    }

    return fields;
  }

  function initData() {
    return {
      cardNumberText: "",
      fields: initFields(),
      clearImg: `${(<any>window).images_path}/close.png`,
      amount: 0,
      creditPayments: []
    }
  }
}}}}
