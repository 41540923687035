module PositiveTS {
  export module Storage {
  export module Entity {
  export class ItemDirectAddition extends WasmEntity {
    public itemId:number;
    public additionItemId:number;
    public additionItemCode:string;
    public additionItemDesc:string;
    public additionItemPrice:number;
    public additionItemColor:string;
    public additionItemSortOrder:number;

    constructor() {
        let meta = {
            name: 'ItemDirectAddition',
            fields: {
                itemId: "INT",
                additionItemId: "INT",
                additionItemCode: "TEXT",
                additionItemDesc: "TEXT",
                additionItemPrice: "FLOAT",
                additionItemColor: "TEXT",
                additionItemSortOrder: "INT"
            },
        }
        super(meta)
    }

    static getAdditionsForItem(saleItem:SaleItem) {
    let itemId = saleItem.item.id;
    let sql = `select * from ItemDirectAddition where itemId = ${itemId}
    order by additionItemSortOrder asc`;

    return this.execAndConvertResult(sql);
}}}}}