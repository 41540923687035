module PositiveTS {
  export module Components {
    export module currenciesRatesDialog {
      const dialogSelector = 'currencies-rates-dialog';
      export function create() {
        let currenciesRatesDialog = {
          template: JST.currenciesRatesDialog(),
          data() {
            return {
              currencies: {},
              resolveFunc: null,
              currenciesLastUpdated:null
            };
          },
          computed: {
            posCurrencySign() {
              return Service.MultiCurr.getInstance().getPosCurrencySign();
            },
          },
          methods: {
            initEnabledPosCurrencies() {
              let currencies = PositiveTS.Service.MultiCurr.getInstance().getPosCurrenciesEnabled();
              currencies.map((currency) => {
                const name = PositiveTS.Service.MultiCurr.getInstance().getCurrencyName(currency);
                const code = currency;
                const symbol = PositiveTS.Service.MultiCurr.getInstance().getCurrencySign(currency);
                this.currencies[currency] = { name, symbol, code,rate:0 };
              });
            },
            async loadCurrenciesRates() {
              let allCurrencies = posPaymentVC.Currencies;
              let lastUpdated = await Service.Currencies.getLastCurrenciesUpdatedAt();
              this.currenciesLastUpdated = moment(lastUpdated).format('DD/MM/YYYY HH:mm')
              Object.entries(allCurrencies).forEach(([key, value]) => {
                if(this.currencies[key]?.code){
                  this.currencies[key]["rate"] = value
                }
              });
              
            },
            async open() {
              this.initEnabledPosCurrencies();
              await this.loadCurrenciesRates()
              
              this.$el.showModal();
              return new Promise(resolve => { this.resolveFunc = resolve });
            },
            close(resolveResult) {
              this.$el.close();
              this.resolveFunc(resolveResult);
            },
            cancel() {
              return this.close();
            },
          },
        }
        VueApp.component(dialogSelector, currenciesRatesDialog)
      }
    }
  }
}
