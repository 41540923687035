module PositiveTS {
export module Service {
export module AutoEmployeeStore {

  const INTERFACE_URL = "/get_active_employee_for_store";
  const LOCAL_STORAGE_KEY = 'Service.AutoEmployeeStore'
  // const UPDATE_INTERVAL_IN_SEC = 60;
  // var intervalHndler = undefined;

  export function isEnabled(){
    return jsonConfig.getVal(jsonConfig.KEYS.isAutoEmployeeStore);
  };

  export function getEmployeeArray() {
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
  }

  // export function startInterval() {
  //   clearInterval(intervalHndler);
  //   if (! isEnabled() ) return;

  //   runService()
  //   .then(() => {
  //     intervalHndler = window.setInterval(() => {
  //       runService();
  //     }, UPDATE_INTERVAL_IN_SEC * 1000);
  //   })
  //   .catch(() => {
  //     intervalHndler = window.setInterval(() => {
  //       runService();
  //     }, UPDATE_INTERVAL_IN_SEC * 1000);
  //   })
    
  // };

  export function runService():Promise<void> {
    if (!session.pos.isRoshemet) {
      return new Promise((resolve,reject) => {
        if (! PositiveTS.Reachability.isOnline || !isEnabled()) {
          return resolve(); 
        }
  
        FetchReq.jsonReq(INTERFACE_URL, 'get')
        .then( (response) => {
          if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
          var employeeArray = response.result
          if (!Array.isArray (employeeArray)) {
            reject("Bad formatted get_active_employee_for_store");
          }
  
          window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify( employeeArray));
          resolve();
        })
        .catch((err) => {
          if (err) {
            console.error(err);
          }
          reject("Unexpected error on AutoEmployeeStore.runService");
        });  
      })
    }
    else {
      return Promise.resolve();
    }
  }


}}}
