module PositiveTS {
  export module Components {
    export const rootRoute = '/';
    export const root = {
      template: '<div display="none"></div>',
      methods: {
        
      },
      data: function() {return {}; },
      mounted: function() {
      }
    }
  }
}