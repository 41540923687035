module PositiveTS {
export module Components {
    export module PriorityCustomerReportDialog {
      
      const dialogSelector = "priority-customer-report-dialog"
      
      function initData() {
        return {
          dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
          invoices: [],
          customer: null,
          pageSize: 25,
          currentPage: 1,
        }
      }

      
      export function create() {

        let priorityCustomerReportDialog = {
            template: JST.priorityCustomerReportDialog(),
            methods: {
              close (cleanData = true, openPriorityCustomerClub = true) {
                if (this.dialogElem == null) {
                  this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                }
        
                this.dialogElem.close()
                if (openPriorityCustomerClub){
Pinia.componentsStore.openComponent( {componentName:"priorityCustomerClub", args: []})
                }

                if (cleanData){
                  this.cleanData()
                }
              },
        
              async open(customer, fetchData = true) {
                try{
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  
                  this.dialogElem.show()
                  app.showLoadingMessage()
                  if (customer && fetchData){
                    this.customer = customer
                  }

                  if (fetchData){
                    const params = {
                      '$filter': `CUSTNAME eq '${this.customer.customer_number}'`,
                      '$expand': 'EINVOICEITEMS_SUBFORM,EPAYMENT2_SUBFORM,EPAYMENT_SUBFORM',
                    }

                    let ainvoices = await PositiveTS.Service.PriorityService.getCustomerInvoices(this.customer.customer_number)
                    let einvoices = await PositiveTS.Service.PriorityService.getEinvoices(params)

                    if (!ainvoices.success || ainvoices.error){
                      app.showErrorAlertDialog(ainvoices.error)
                      app.hideLoadingMessage()
                      return
                    }

                    if (!einvoices.success || einvoices.error){
                      app.showErrorAlertDialog(einvoices.error)
                      app.hideLoadingMessage()
                      return
                    }

                    let invoices = [...ainvoices.invoices, ...einvoices.einvoices]
                    invoices = _.orderBy(invoices, 'IVDATE', 'desc')

                    this.invoices = invoices
                  }
                  
                  this.$nextTick(() => {
                    $(document).unbind('keypress')
                  })
                  app.hideLoadingMessage()  
                }catch(e){
                  console.error(e)
                  this.showGenericError()
                  app.hideLoadingMessage()
                }
              },
        
              showGenericError () {
                app.showAlert({
                  header: i18next.t('error'),
                  content: i18next.t('generalError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                },
                null,null)
              },
              
              cleanData () {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
              },

              async changePage (destination) {
                try{
                  app.showLoadingMessage()
                  this.invoices = []

                  if (destination === 'next'){
                    this.currentPage += 1
                  }else{
                    this.currentPage -= 1
                  }
  
                  let result = await PositiveTS.Service.PriorityService.getCustomerInvoices(this.customer.customer_number, this.pageSize, this.offset)
                  if (!result.success || result.error){
                    app.showErrorAlertDialog(result.error)
                    app.hideLoadingMessage()
                    return
                  }
  
                  this.invoices = result.invoices
                  app.hideLoadingMessage()  
                }catch(e){
                  console.error(e)
                  this.showGenericError()
                  app.hideLoadingMessage()
                }                
              },

              showInvoiceDetails (invoice) {
Pinia.componentsStore.openComponent( {componentName:"priorityCustomerInvoiceDetailsDialog", args: [invoice]})
                this.close(false, false)
              },

              invoiceFormat (value) {
                let invoice = ''
                if (typeof value == 'string'){
                  invoice = value.replace(`${session.pos.storeID}-`, '')
                }

                return invoice
              }
            },

            computed: {
              offset () {
                return (this.currentPage - 1) * this.pageSize
              }
            },

            data: initData

        }

        VueApp.component('priority-customer-report-dialog', priorityCustomerReportDialog)
      }
    }
  }
}

