module PositiveTS {
export module Dialogs {
	export class CreditCardDialog {

		cardModes: {
				'typing': 1,
				'reader': 2
		}

		cardMode = 1
		cardData = null
		amount = 0

		close() {
				$('#credit-card-dialog-cancel').unbind('click');
				$('#credit-card-dialog-continue').unbind('click');
				$('#credit-card-dialog-fields-container input[type="text"]').unbind('focus');
				$('#credit-card-dialog-fields-container input[type="text"]').unbind('focusout');
				$('#credit-card-dialog-fields-container input[type="text"]').unbind('keydown');
				(<any>window).CreditCardDialogUI.dialog('close');
				$(document).on('keyup',app.handleEnterEscape);
		}

		open(options) {
				app.hideLoadingMessage();
				return new Promise((resolve,reject) => {
					$(document.body).unbind('keyup');
					$(document).off('keyup',app.handleEnterEscape);
	
					// apply the options for te dialog
					options = creditCardDg.initDialog(options);
	
					$('#credit-card-dialog-continue').click(async function () {
						$('#credit-card-dialog-error-msg').hide();
						try {
							let cardDetails = await creditCardDg.creditTheCard()
							
							creditCardDg.close();
							resolve(cardDetails);
						}
						catch(err) {
							$('#credit-card-dialog-error-msg').text(err);
							$('#credit-card-dialog-error-msg').fadeIn('slow').delay(5000).fadeOut('slow');
							};
					});
	
					$('#credit-card-dialog-cancel').click(function () {
						creditCardDg.close();
						reject({userCanceled: true});
						PosPaymentDialogUI.dialog('close');
					});

					(<any>window).CreditCardDialogUI.dialog('open');
	
					// set focus on the credit card number field
					$('#credit-card-dialog-card-number').focus();
	
				})
				
				
		}

		initDialog(options) {
			// default settings
			let settings = {
							amount: 0
			};

			if (posUtils.isNullOrUndefinedOrEmptyString(options)) {
							options = {};
			}

			options = $.extend(settings, options);

			if (settings['amount'] === 0) {
							throw 'unable to credit 0 from credit card';
			}

			if (settings['amount'] < 0) {
							settings['amount'] *= -1;
			}

			creditCardDg.amount = settings['amount'];

			$('#credit-card-dialog-credit-amount').val(session.fixedNumber(settings['amount']));

			$('#credit-card-dialog-error-msg').hide();

			creditCardDg.initFields();

			return options;
		}

		initFields() {
			creditCardDg.cardMode = creditCardDg.cardModes['typing'];
			creditCardDg.cardData = null;

			$('#credit-card-dialog-card-number').val('');
			$('#credit-card-dialog-expiration-date').val('');
			$('#credit-card-dialog-phone-number').val('');
			$('#credit-card-dialog-ccv').val('');

			creditCardDg.changeToCardTypingMode();

			let keypad = $('#credit-card-dialog-credit-keypad').keypad();

			keypad.setUseFormatFlag(false);

			$("#credit-card-dialog-expiration-date").setMask("19/99");

			$('#credit-card-dialog-fields-container input[type="text"]').autoselected();

			$('#credit-card-dialog-fields-container input[type="text"]').each(function () {
							$(this).cleanable({ cleanSelector: '#' + $(this).attr('id') + '-clear' });
			});

			$('#credit-card-dialog-fields-container input[type="text"]').focus(function () {
							if ($(this).attr('id') === 'credit-card-dialog-expiration-date') {
											keypad.setInsertInputWithID($(this).attr('id'));
											keypad.setIsDateField(true);
											keypad.setDateFormat('19/99');
							} else {
											keypad.setInsertInputWithID($(this).attr('id'));
											keypad.setIsDateField(false);
							}
			});

			$('#credit-card-dialog-fields-container input[type="text"]').keydown(function(event) {
							if (event.which === 13 && $('#' + event.target.id).val() != '') {
											creditCardDg.goToNextField(event.target.id);
							}
			});

			$('#credit-card-dialog-card-number').focusout(function () {
							creditCardDg.afterInsertCardNumber();
			});
		}

		goToNextField(currentFieldId) {
			switch (currentFieldId) {
					case 'credit-card-dialog-card-number':
							creditCardDg.afterInsertCardNumber();
							$('#credit-card-dialog-expiration-date').focus();
							break;

					case 'credit-card-dialog-expiration-date':
							$('#credit-card-dialog-phone-number').focus();
							break;

					case 'credit-card-dialog-phone-number':
							$('#credit-card-dialog-ccv').focus();
							break;

					case 'credit-card-dialog-ccv':
							// TODO: Do nothing like now or send the form???
							break;
			}
		}

		afterInsertCardNumber() {
			let cardNumber = $('#credit-card-dialog-card-number').val();

			if (posUtils.isNullOrUndefinedOrEmptyString(cardNumber)) {
							creditCardDg.changeToCardTypingMode();
							creditCardDg.cardData = null;
							return;
			}

			if (cardNumber[cardNumber.length - 1] === '*' && creditCardDg.cardData != null) {
							creditCardDg.changeToCardReaderMode();
							return;
			}

			creditCardDg.cardData = $('#credit-card-dialog-card-number').val();

			if (cardNumber.indexOf('=') === -1) {
				// Inserted by typing
				creditCardDg.changeToCardTypingMode();
			} else {
				// Inserted card reader
				creditCardDg.changeToCardReaderMode();

				creditCardDg.formatCardNumber();
			}
		}

		changeToCardReaderMode() {
			creditCardDg.cardMode = creditCardDg.cardModes['reader'];

			$('#credit-card-dialog-expiration-date').prop('disabled', true);
			$('#credit-card-dialog-phone-number').prop('disabled', true);
			$('#credit-card-dialog-ccv').prop('disabled', true);

			$('#credit-card-dialog-expiration-date').val('');
			$('#credit-card-dialog-phone-number').val('');
			$('#credit-card-dialog-ccv').val('');
		}

		changeToCardTypingMode() {
			creditCardDg.cardMode = creditCardDg.cardModes['typing'];

			$('#credit-card-dialog-expiration-date').prop('disabled', false);
			$('#credit-card-dialog-phone-number').prop('disabled', false);
			$('#credit-card-dialog-ccv').prop('disabled', false);
		}

		formatCardNumber() {
			let cardNumber = $('#credit-card-dialog-card-number').val();
			let formatedNumber: string;
			cardNumber = cardNumber.substring(0,cardNumber.indexOf('='));

			if (cardNumber.length <= 8) {
				formatedNumber = cardNumber + '*';
			}
			else {
				formatedNumber = cardNumber.substring(0,8);

				for (let i = 7; i < cardNumber.length; i++) {
					formatedNumber += '*';
				}
			}

			$('#credit-card-dialog-card-number').val(formatedNumber);
		}

		creditTheCard() {
			if (creditCardDg.cardData === null) {
							return Q.reject('מספר כרטיס אשראי חסר');
			}

			let experDate = '';
			let phoneNumber = '0';
			let ccv = '0';

			if (creditCardDg.cardMode === creditCardDg.cardModes['typing']) {
					experDate = $('#credit-card-dialog-expiration-date').val();
					phoneNumber = $('#credit-card-dialog-phone-number').val();
					ccv = $('#credit-card-dialog-ccv').val();

					if (experDate.length !== 5) {
							return Promise.reject('תוקף חסר');
					}

					if (posUtils.isNullOrUndefinedOrEmptyString(phoneNumber)) {
							return Promise.reject('מספר טלפון חסר');
					}

					if (posUtils.isNullOrUndefinedOrEmptyString(ccv)) {
							return Promise.reject('ccv חסר');
					}
			}

			let cardDetails = {
					"amount"    : creditCardDg.amount,
					"cardNumber"   : creditCardDg.cardData,
					"creditType" : 1,
					"experDate" : experDate ,
					"phoneNumber": phoneNumber,
					"ccv" : ccv,
					"cardPayments"   : 1,
					"confNumber" : '0'
			};

			return new Promise((resolve,reject) => {
				PositiveTS.Service.CreditCard.creditCreditCardPayment(
					cardDetails["cardNumber"],
					cardDetails["amount"],
					cardDetails["creditType"],
					cardDetails["confNumber"],
					cardDetails["cardPayments"],
					cardDetails["experDate"],
					cardDetails["phoneNumber"],
					cardDetails["tz"],
					undefined,
					function (returnedArray) {
						if (returnedArray[0].success === true) {
										resolve(returnedArray[0]);
						} else {
										reject(returnedArray[0]['error_message']);
						}
					},
					function (err) {
							reject(err.statusText);
					}
				);
			})
		}
	}
}}


declare var creditCardDg:PositiveTS.Dialogs.CreditCardDialog;
creditCardDg = new PositiveTS.Dialogs.CreditCardDialog;

$(function() {
				(<any>window).CreditCardDialogUI = $( "#credit-card-dialog" ).dialog({
						autoOpen: false,
						modal: true,
						dialogClass: 'positive-dialog',
						width: '50%',
						resizable: false,
						closeOnEscape: false,
						draggable: false
				});
});
