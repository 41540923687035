module PositiveTS {

    export module Components {
    export module FeatureOrder {
        export module MobilePhoneRepair {
          
          export const route = "mobile-phone-repair"
          export const name = "MobilePhoneRepair"
          
          export function getComponent() {
  
            return {
  
              template: JST.MobilePhoneRepair(),

              computed :{
                customer () {
                  let customer = {
                    name: '',
                    tz: '',
                    phone: '',
                  }
                  
                  if (!_.isEmpty(this.customerDB)){
                    customer.name = `${this.customerDB.first_name} ${this.customerDB.last_name}` 
                    customer.tz = this.customerDB.customer_tz
                    customer.phone = this.customerDB.phone_number
                  } 
                  
                  return customer
                },
                posTabMode(){
                  return Pinia.globalStore.posTabMode
                },
              },
  
              methods: {
                ...Mixins.modalsMixin.methods,

                back () {
                    this.close()
                },

                close () {
                  if (this.returnToIndexMobilePhoneRepairs){
                    if(this.posTabMode)
                    {
                      pNavigator.pushPage('pages', '', null, { initParams: { route: '/cellular-tab',innerComponent: 'IndexMobilePhoneRepairs' } })
                    }
                    else{
                      VueServices.Router.goto(PositiveTS.Components.FeatureOrder.IndexMobilePhoneRepairs.name)
                      return
                    }
                  }
                  else{
                    PositiveTS.VueInstance.goToPos()
                  }
                },

                async selectCustomer () {
                  try{
                    let customer = await Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryCustomerClub", args: [false, true, true, true, false, true]})
                    this.customerDB = customer
                    $(document).unbind('keypress')
                    Service.Delivery.clearDeliveryAndCustomerFromCurrentSale()
                  }catch(error){
                    console.error(error)
                    $(document).unbind('keypress')
                  }
                },
  
                async save (needPrint = false) {
                  try {
                    app.showLoadingMessage(i18next.t('mobilePhoneRepair.saveRepair'))
                    const validate = this.validate()
                    
                    if (!validate.valid) {
                      app.hideLoadingMessage()
                      this.showAlertGeneric(validate.errors.join('<br>'))
                      return 
                    }
                    
                    let res: any = null
                    if (this.mode == 'create'){
                      res = await PositiveTS.Service.FutureOrder.create(name, this.prepareDataToServer())
                    }

                    if (this.mode == 'edit'){
                      res = await PositiveTS.Service.FutureOrder.update(this.futureOrder.id, name, this.prepareDataToServer())
                    }

                    if (!res.success){
                      if(Array.isArray(res.errors) && res.errors.length){
                        app.hideLoadingMessage()
                        this.showAlertGeneric(res.errors.join('<br>'))
                        return
                      }
                      
                      throw new Error('Error server on save')
                    }
                    
                    if (needPrint) {
                      if (this.mode == 'create'){
                        PositiveTS.Service.MobilePhoneRepair.print(res.data.future_order, true)
                      }

                      PositiveTS.Service.MobilePhoneRepair.print(res.data.future_order)
                    }

                    this.showAlertWithTitleGeneric(i18next.t('mobilePhoneRepair.saveSuccess'), i18next.t('message'), this.close)
                    app.hideLoadingMessage()
                  }catch (error) {
                    console.error(error)
                    app.hideLoadingMessage()
                    this.showAlertGeneric(i18next.t('mobilePhoneRepair.errorOnSaveRepair'))
                  }  
                },
                
                prepareDataToServer () {
                  let orderable = _.cloneDeep(this.mobilePhoneRepair)
                  
                  let futureOrder: Service.FutureOrder.FutureOrder = {
                    id: this.futureOrder.id,
                    closed_at: null,
                    customer: this.customerDB,
                    customer_id: this.customerDB.id,
                    company_id: null,
                    store_id: null, 
                    status: Service.MobilePhoneRepair.statusToFutureOrderStatus(orderable.additional_status),
                    notes: this.futureOrder.notes,
                    sequence: null,
                    opened_at: null,
                    orderable: orderable
                  }
                  
                  return futureOrder
                },
                
                validate() {
                  let errors :Array<string> = []
  
                  if (_.isEmpty(this.customerDB)) {
                    errors.push(i18next.t("mobilePhoneRepair.needToSelectcustomer"))
                  }
                  
                  if (posUtils.isBlank(this.futureOrder.notes)) {
                    errors.push(i18next.t("mobilePhoneRepair.notesCannotBeEmpty"))
                  }

                  if (posUtils.isBlank(this.mobilePhoneRepair.device_type)) {
                    errors.push(i18next.t("mobilePhoneRepair.deviceTypeCannotBeEmpty"))
                  }
                  
                  if (posUtils.isBlank(this.mobilePhoneRepair.additional_status)) {
                    errors.push(i18next.t("mobilePhoneRepair.needToSelectStatus"))
                  }else{
                    if (this.mobilePhoneRepair.additional_status == Service.MobilePhoneRepair.statuses.completed){
                      errors.push(i18next.t("mobilePhoneRepair.cannotSelectStatusCompletedBeforePayment"))
                    }
                  }
                  
                  return {valid: errors.length ? false : true, errors};
                }, 

                selectStatus($event){
                  this.$el.querySelectorAll('.status').forEach((n) => n.checked = false)
                  $event.target.checked = true
                  this.mobilePhoneRepair.additional_status = $event.target.value
                },

                async fetchDataFromServer (id) {
                  try{
                    app.showLoadingMessage(i18next.t('mobilePhoneRepair.loadingFutureOrder'))
                    let res = await PositiveTS.Service.FutureOrder.find(name, id)
                    if (!res.success){
                      throw new Error('Error on loading future order')
                    }
                    let futureOrder = res.data.future_order
                    posUtils.objectAssignExistPropertiesOnly(this.mobilePhoneRepair, futureOrder.orderable)
                    Object.assign(this.futureOrder, futureOrder)
                    this.customerDB = futureOrder.customer
                    app.hideLoadingMessage()
                  }catch(error){
                    console.error(error)
                    app.hideLoadingMessage()
                    this.showAlertGeneric(i18next.t('mobilePhoneRepair.errorOnFetchFutureOrder'), () => {
                      PositiveTS.VueServices.Router.goto(PositiveTS.Components.FeatureOrder.IndexMobilePhoneRepairs.name)
                    })
                  }             
                },

                print () {
                  PositiveTS.Service.MobilePhoneRepair.print(this.futureOrder)
                }, 

                async addPrintableNotes (type) {
                  let additionalNotesDialogTitle = ''

                  if (type == this.additionalNoteTypes.notes){
                    additionalNotesDialogTitle = i18next.t('mobilePhoneRepair.printableNotes')
                  }
                  if (type == this.additionalNoteTypes.regulations){
                    additionalNotesDialogTitle = i18next.t('mobilePhoneRepair.printableRegulations')
                  }
                  
                  let additionalNote = this.mobilePhoneRepair.additional_notes_attribute.find((note) => note.additional_type == type)
                  let additionalNoteStr = ''
                  if (additionalNote){
                    additionalNoteStr = additionalNote.note
                  }else{
                    const nameType = Object.keys(this.additionalNoteTypes)
                      .find(key => this.additionalNoteTypes[key] === type)
                    if (nameType){
                      additionalNoteStr = session.mobilePhoneRepairSettings[nameType].note
                    }
                  }

                  let options = {
                    title: additionalNotesDialogTitle,
                    existLines: additionalNoteStr,
                  }

                  try {
                    let res = await Pinia.componentsStore.openComponent( {componentName:"additionalNotesDialog", args: [options]})

                    if (additionalNote){
                      additionalNote.note = res.lines.join(`\n`)
                    }else{
                      this.mobilePhoneRepair.additional_notes_attribute.push({additional_type: type, note: res.lines.join(`\n`)})
                    }
                  }catch(e){
                    console.error(e)
                  }
                },

                updateNotes (event) {
                  let str = event.target.value.replace(/(\r\n|\n|\r)/gm,"")
                  let notes = str.match(/.{1,40}(\s|$)/g)
                  if (notes){
                    this.futureOrder.notes = notes.join(`\n`)
                  }else {
                    this.futureOrder.notes = ''
                  }
                },

                getCustomerAddress (customer) {
                  return PositiveTS.Service.CustomerClub.getShortDeliveryCustomerAddress(customer)
                },

                updateTabTitle(){
                  this.$emit("input",i18next.t('mobilePhoneRepair.title'))
                }
              },
  
              data () {
                let dataVal :{
                  mode: 'create'|'show'|'edit',
                  mobilePhoneRepair: Service.MobilePhoneRepair.MobilePhoneRepair,
                  futureOrder: typeof Service.FutureOrder.futureOrder,
                  customerDB: any,
                  statuses: typeof Service.MobilePhoneRepair.statusArray,
                  additionalNoteTypes: typeof Service.MobilePhoneRepair.additionalNoteTypes,
                  returnToIndexMobilePhoneRepairs: boolean,
                } = {
                  mode: 'create', 
                  mobilePhoneRepair: {...Service.MobilePhoneRepair.mobilePhoneRepair},
                  futureOrder: {...Service.FutureOrder.futureOrder}, 
                  customerDB: null,
                  statuses: [...Service.MobilePhoneRepair.statusArray],
                  additionalNoteTypes: {...Service.MobilePhoneRepair.additionalNoteTypes},
                  returnToIndexMobilePhoneRepairs: false,
                }

                return dataVal
              },
              created () {
                if(this.posTabMode){
                  this.returnToIndexMobilePhoneRepairs = true
                }
                if (this.$route.params && this.$route.params.mode){
                  this.mode = this.$route.params.mode
                  if (['edit', 'show'].includes(this.mode)){
                    this.returnToIndexMobilePhoneRepairs = true
                  }
                }

                if (this.$route.params && this.$route.params.id){
                  this.fetchDataFromServer(this.$route.params.id)
                }

                this.updateTabTitle()
              },
            }
  
          }
          
      }
    }
    }
  }

