module PositiveTS {
export module Storage {
export module Entity {
export class CustomerGroup extends WasmEntity {

	constructor() {
		let meta = {
			name: 'CustomerGroup',
			fields: {
	      code: "INT" ,
	      name: "TEXT" ,
			},
			unique: ['code']
		}
		super(meta)
	}
}}}}
