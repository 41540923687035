module PositiveTS { 
  export module Printing {
  export module HtmlInvoice{

        export class ObjHtmlInvoice {
          private _sale;
          private _saleItems;
          private _salePayments;
          private _isSource;
          private _printCalculatedVars;
          private _company;
          private _paymentsTypes;
          private _posNumber;
          private _imgSrc;
          private _printTotalLines;
          private _creditVoucherData = null;

          header: ObjHtmlInvoiceHeader;
          itemLines: ObjInvoiceItems;

          sourceString: string;
          saleNumberFormated: string;
          docTypeString: string;
          customerClub: ObjCustomerClub;
          overrideParameters: any;
          printPayments: boolean;
          printCopy: boolean
          dedicatedTo:string;
          dedicatedPhone:String


          constructor(sale, saleItems, salePayments, isSource, printCalculatedVars, company, paymentsTypes, posNumber, imgSrc, printWithCopy?) {
            this.overrideParameters = jsonConfig.getVal(jsonConfig.KEYS.reportParametersInvoiceA4);
            this._sale = sale;
            this._saleItems = saleItems;
            this._salePayments = salePayments;
            this._ignoreHakafaPaymentPrint(this._salePayments)
            this._isSource = isSource;
            this._printCalculatedVars = printCalculatedVars;
            this._company = company;
            this._paymentsTypes = paymentsTypes;
            this._posNumber = posNumber;
            this._imgSrc = imgSrc;
            this._populateFields();
            this.printPayments = this._salePayments.filter( ( row) => {return !row.ignorePrint}).length > 0;
            this.printCopy = printWithCopy || false;

            this.dedicatedTo = "";
            this.dedicatedPhone = ""
            let salejson = JSON.parse(sale.jsondata);
            if(!posUtils.isBlank(salejson.dedicatedTo)) {
              this.dedicatedTo = salejson.dedicatedTo;
            }
            if(!posUtils.isBlank(salejson.dedicatedPhone)) {
              this.dedicatedPhone = salejson.dedicatedPhone;
            }

            for (let payment of this._salePayments) {
              if (payment.method == Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER && payment.amount < 0) {
                let data = JSON.parse(payment.data)[0];
                let voucherNumber = data['promotionNumber'];

                let canvas = document.createElement("canvas");
                JsBarcode(canvas, voucherNumber);
                this._creditVoucherData =  {
                  number: voucherNumber,
                  image: canvas.toDataURL("image/png"),
                  imageHeight: canvas.height / 2,
                };
              }
            }
          }


          private _ignoreHakafaPaymentPrint(salePayments) {
            let totalHakafaPayments = 0
            for (let payment of salePayments) {
              if ( [PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT,
                    PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER].indexOf(payment.method) > -1) {
                if (payment.data != null) {
                  let data = JSON.parse(payment.data)
                  for (let voucherData of data) {
                    if (voucherData.voucher_type_id == Service.Hakafa.getVoucherID()) {
                      payment.ignorePrint = true;
                    }
                  }
                }
              }
            }

          }

          private _populateFields():void {
            var sale = this._sale;
            var saleItems = this._saleItems;
            var posNumber = this._posNumber;
            var salePayments = this._salePayments;
            var company = this._company;
            var imgSrc = this._imgSrc;
            this.header = new ObjHtmlInvoiceHeader(sale, company, posNumber, imgSrc);

            this.sourceString = this._getSourceString();
            this.saleNumberFormated = this._getSaleNumberFormated();
            this.docTypeString = this._getDocTypeString();
            this.customerClub = new ObjCustomerClub(sale, saleItems, salePayments)
            this.itemLines = new ObjInvoiceItems(sale, saleItems, salePayments )
    
            this._printTotalLines = (sale.invoiceType == 5) ? false : true;
          }

          
          private _getDocTypeString(): string {
            var sale = this._sale;
            if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
              if (session.getParameterInvoiceType() == 3) {
                return 'חשבון/קבלה: ';
              } else if (session.getParameterInvoiceType() == 2) {
                return 'חשבונית מס קבלה: ';
              } else {
                return 'חשבונית עסקה: ';
              }

            } else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
              if (session.getParameterInvoiceType() == 3) {
                return 'ביטול חשבון/קבלה: ';
              } else if (session.getParameterInvoiceType() == 2) {
                return  'חשבונית מס קבלה זיכוי: ';
              } else {
                return 'חשבונית עסקה זיכוי: ';
              }

            } else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT) {
              return 'קבלה: ';
            } else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV) {
              if (session.getParameterInvoiceType() == 3) {
                return 'חשבון/קבלה: ';
              } else if (session.getParameterInvoiceType() == 2){
                    return 'חשבונית מס: ';
                  } else {
                    return 'חשבון עסקה: ';
                  }
        		} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV) {
              if (session.getParameterInvoiceType() == 3) {
                return 'ביטול חשבון/קבלה: ';
              } else if (session.getParameterInvoiceType() == 2){
                    return 'חשבונית מס זיכוי: ';
                  } else {
                    return 'חשבון עסקה: ';
                  }
        		} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {
              if (session.getParameterInvoiceType() == 3) {
                return 'חשבון/קבלה: ';
              } else if (session.getParameterInvoiceType() == 2){
                    return 'תעודת משלוח:  ';
                  } else {
                    return 'תעודת משלוח:  ';
                  }
        		} else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) {
              if (session.getParameterInvoiceType() == 3) {
                return 'ביטול חשבון/קבלה: ';
              } else if (session.getParameterInvoiceType() == 2){
                    return 'תעודת החזרה: ';
                  } else {
                    return 'תעודת החזרה: ';
                  }
        		}
          }

          private _getSaleNumberFormated(): string {
            return this._sale.invoiceSequence.toString();
          }

          private _getSourceString(): string {
            return this._isSource === true ? '*מקור*' : '*העתק*';
          }

        };


  }}}
