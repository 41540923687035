module PositiveTS {
    export module Service {
    export module TenbisConvertService {
    
        const _TenbisPayment = 221;
        const _CreditCardPayment = 222;
        const _HakafaPayment = 223;
        const _TenbisCurrentPayment = 4;
        const _TenbisServiceLogger ="tenbis orders"


        function returnNullIfEmptyObj(obj){
            if(typeof obj === 'object' && obj !== null && _.isEmpty(obj)){
                return null
            }
            return obj
        }

        export function convertPoolOrderToSingleOrder(orderData, customerName, deliveryMethod, poolId){
            let order = orderData.Order
            let totalSum = 0
            order.BillingInfo.PaymentInfo.forEach(pi=>{
                totalSum += pi.Sum
            })

            order.TotalSum = totalSum
            order.CustomerName = customerName + ` (${order.user.FirstName} ${order.user.LastName})`
            order.DeliveryMethod = deliveryMethod
            order.isPoolOrder = true
            order.poolId = poolId
            order["OrderID"] = order["OrderId"]
            delete order.OrderId

            return order
        }
      
        export async function convertOrderToSale(order) {
            let orderNumber = order.OrderID,
                deliveryMethod = order.DeliveryMethod,
                deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalDelivery,
                deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;

            if(deliveryMethod == "Pickup"){
                deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.externalTA;    
                deliveryStatus = PositiveTS.Storage.Entity.Sale.TA_PAID;
            }

            let tenbisCustomer = await Service.Hakafa.getHakafaCustomerByCustomerNumber(Service.TenbisService.customer_number);
            if(!tenbisCustomer){
                PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"No tenbis hakafa customer found:", result:""}),orderNumber,_TenbisServiceLogger,Delivery.FailedDeliveryType.HakafaCustomer)
                throw new Error("No tenbis hakafa customer found");
            } 

            let orderData = await PositiveTS.Service.TenbisService.fetchSingleOrder(orderNumber);

            if(!orderData?.Data?.Order?.BillingInfo?.PaymentInfo){
                PositiveTS.Service.Delivery.failedDeliveriesLogger("Tenbis : Faild to get single order data", order.OrderID,_TenbisServiceLogger,Delivery.FailedDeliveryType.InvalidData)
                return;
            }
            // Ichsa Pichsa but Tenbis has a bug, in the initial request the total sum of the order is broken(for example: 34 instead of 134)
            // but in the payment data the data is more correct (hopefully it will stay that way)
            if (!order.isPoolOrder) {
                let totalSum = 0
                orderData.Data.Order.BillingInfo.PaymentInfo?.forEach(pi=>{
                    totalSum += pi.Sum
                })

                order.TotalSum = totalSum;
            }

            let sale = await Sale.getNewOrder(orderNumber, order.TotalSum,Storage.Entity.Sequence.TYPE_SHIPMENT_INV);


            if(!validateOrder(orderNumber, orderData)){
                sale.items = []
                return sale;
            }
           
            let jsondata = JSON.parse(sale.jsondata)
            jsondata.tenbisTotalAmount = sale.totalAmount
            jsondata["promotions"] = [];
            jsondata["delivery"] = {
            deliveryType: deliveryTypeStr, 
            status: deliveryStatus,
            ordererName: order.CustomerName,
            ordererPhone: returnNullIfEmptyObj(orderData.Data.Order.Header.UserPhone), 
            ordererCallerRemarks: returnNullIfEmptyObj(orderData.Data.Order.address.Remarks),
            ordererDeliveryRemarks: returnNullIfEmptyObj(orderData.Data.Order.OrderRemarks),
            ordererDeliveryCutlery: returnNullIfEmptyObj(orderData.Data.Order.Header.userDoNotWantCutlery),
            "deliveryAddress": {
                "address": {
                "name": returnNullIfEmptyObj(orderData.Data.Order.address.StreetName),
                "value": 0,
                "cityID": 0
                },
                "apartment":  returnNullIfEmptyObj(orderData.Data.Order.address.ApartementNumber),
                "house_number": returnNullIfEmptyObj(orderData.Data.Order.address.HouseNumber),
                "house_entrance": returnNullIfEmptyObj(orderData.Data.Order.address.Enterance),
                "house_floor": returnNullIfEmptyObj(orderData.Data.Order.address.Floor),
                "city": {
                "name": returnNullIfEmptyObj(orderData.Data.Order.address.CityName),
                "value": 0, 
                "cityID": 0
                },
            },
            "orderTime": new Date(),
            "TenbisRestID": order.ResID,
            "TenbisRestName": order.ResName,
            "TenbisExternalPaymentInfo": JSON.stringify(orderData.Data.Order.BillingInfo.PaymentInfo),
            "TenbisCouponId": orderData.Data.Order.BillingInfo.DiscountBillingLines[0] != null ? orderData.Data.Order.BillingInfo.DiscountBillingLines[0].CouponId : 0,
            "TenbisCouponName": orderData.Data.Order.BillingInfo.DiscountBillingLines[0] != null ? orderData.Data.Order.BillingInfo.DiscountBillingLines[0].Caption : "",
            "address": "",
            };
            jsondata["customer"] = {
            clubName: "HAKAFA",
            s_id_number: tenbisCustomer.tz,
            s_first_name: tenbisCustomer.first_name,
            s_last_name: tenbisCustomer.last_name,
            dt_birth_date: null,
            s_phone_number_1: tenbisCustomer.phone,
            customer_group_id: null,
            amount: 0,
            merakez: "",
            db_id: tenbisCustomer.id,
            is_offline: false,
            is_price_alut: false,
            printer_type: 0,
            discount_percent: 0,
            customer_number: "לקוח תן ביס",
            obligo: null,
            address: null,
            is_tamash_customer: true,
            is_cibus_tenbis: true
            }
            sale.orderTime = jsondata["delivery"]["orderTime"];
            sale.jsondata = JSON.stringify(jsondata);
            sale.deliveryStatus = deliveryStatus;

            let orderItems = orderData.Data.Order.dishList;

            let items = [], totalQuantity = 0, totalSumItems = 0; 

            orderItems.forEach(function (orderItem, index) {

                let currentItem = session.allItems.get(String(orderItem.CatalogId || orderItem.ID) )
                let instructions = []; 

                if(!Boolean(currentItem)){
                    currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                }

                if(Boolean(currentItem)){
                    let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, {size:'null',color:'null',barcode:null});
                    saleItem.quantity = orderItem.Quantity;
                    saleItem.barcode = saleItem.itemCode;
                    saleItem.saleID = sale.id;
                    saleItem.unitPrice = orderItem.TotalPrice/orderItem.Quantity; 
                    saleItem.originalUnitPrice = orderItem.DishPrice; 
                    saleItem.hasPreparationInstructions = false;
                    saleItem.rowNumber = index + 1; 
                    totalQuantity += orderItem.Quantity;
                    totalSumItems += orderItem.DishPrice*orderItem.Quantity; 
                    saleItem.children = [];
                    saleItem.priceNetoAfterDiscounts = orderItem.DishPrice;

                    if(currentItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)){
                        saleItem.itemDescription = String(orderItem.DishName).trim();
                    }

                    if(orderItem.DishNotes != "" && orderItem.DishNotes != null && !_.isEmpty(orderItem.DishNotes)){
                        saleItem.hasPreparationInstructions = true;                        
                        saleItem.selectedPreparationInstructions = JSON.stringify([orderItem.DishNotes]);
                    }

                    items.push(saleItem);

                    orderItem.Choices?.forEach(function (choice) {

                        let itemSubChoices = choice.SubsChosen || [];
                        itemSubChoices.forEach(function (subChoice, subIndex) {
                            let choicePrefix = String(subChoice.CatalogId || subChoice.ID).substr(0,3);

                            if(choicePrefix == "Ins"){
                                instructions.push(subChoice.Name);
                            }else{

                                let catalogId = extracCatalogId(String(subChoice.CatalogId || subChoice.ID));
                                    
                                let addItem = session.allItems.get(catalogId);
                                if(!Boolean(addItem)){
                                    addItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                                }
                                if(Boolean(addItem)){
                                    let add = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(addItem, {size:'null',color:'null',barcode:null});
                                    add.rowNumber = 20001 + subIndex; 
                                    add.unitPrice = subChoice.Price; 
                                    add.quantity = 1;
                                    totalSumItems += subChoice.Price*orderItem.Quantity;
                                    add.originalUnitPrice = subChoice.Price; 
                                    add.barcode = add.itemCode; 
                                    add.priceNetoAfterDiscounts = subChoice.Price
                                    add.level = 1;                                       

                                    if(addItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)){
                                        add.itemDescription = String(subChoice.Name).trim();
                                    }

                                    add.parentItemId = items[index].item.id;
                                    add.parentRowNumber = 1;                                        
                                    add.itemGroupId = -1;
                                    add.parent = items[index];

                                    items[index].priceNetoAfterDiscounts += add.priceNetoAfterDiscounts * items[index].quantity
                                    items[index].children.push(add);

                                }else{
                                    PositiveTS.Service.Filelog.log("tenbis orders",   JSON.stringify({request:"No Item subChoice found: ", result:`${String(subChoice.CatalogId || subChoice.ID)}`}));
                                    items.push({"item_desc": "NOT_FOUND"});
                                    console.error(`No Item subChoice found: ${String(subChoice.CatalogId || subChoice.ID)}`)
                                } 
                            }
                        
                        })
                    })

                    if(orderItem.Choices.length > 0){
                        items[index].hasGroups = 1;
                    }

                    if(instructions.length > 0){
                        if(items[index].hasPreparationInstructions){
                            let prevInstructions = JSON.parse(items[index].selectedPreparationInstructions) || [];
                            instructions.forEach(function (instructionItem) {
                                prevInstructions.push(instructionItem);  
                            })
                            items[index].selectedPreparationInstructions = JSON.stringify(prevInstructions);
                        }else{
                            items[index].hasPreparationInstructions = true;                        
                            items[index].selectedPreparationInstructions = JSON.stringify(instructions);
                        }
                    }
                }else{
                    PositiveTS.Service.Filelog.log("tenbis orders",   JSON.stringify({request:"No Order item found: ", result:`Code:${String(orderItem.CatalogId || orderItem.ID)} OrderId:${sale.orderNumber}`}));
                    items.push({"item_desc": "NOT_FOUND"});
                    console.error(`No Order item found: ${String(orderItem.CatalogId || orderItem.ID)}, OrderId: ${sale.orderNumber}`)
                }
            });

            let isTipIncluded = orderData.Data.Order.BillingInfo.BillingLines.find(bill => bill.Type === 0)

            if (isTipIncluded) {
                let tipItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
                tipItem.description = i18next.t("tenbis.courierTip")
                tipItem.priceZarhan = isTipIncluded.Amount

                let tipItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(tipItem, { size: 'null', color: 'null', barcode: null });
                tipItm.rowNumber = items.length + 1
                tipItm.saleID = sale.id
                tipItm.priceNetoAfterDiscounts =  tipItem.priceZarhan
                items.push(tipItm);
            }

            let itemsConsists = validateItemsConsist(items);

            if(itemsConsists && items.length > 0){

              let deliveryItem = new Storage.Entity.Item();
              switch (deliveryMethod) {
                  case "Delivery":
                  deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));   
                  deliveryItem.priceZarhan = orderData.Data.Order.restaurant.DeliveryPrice;                   
                  break;
                
                  case "Pickup":
                  deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode));    
                  deliveryItem.priceZarhan = 0;
                  break;
              }

              if(Number(orderData.Data.Order.restaurant.DiscountCouponPercent)>0){
                let apiDiscountItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiDiscountItemCode));
                if(!(apiDiscountItem)){
                    throw new Error("No tenbis api generic discount item!");
                }
                let discountItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(apiDiscountItem, {size:'null',color:'null',barcode:null});
                discountItem.rowNumber = items.length + 1;
                discountItem.unitPrice = (totalSumItems + deliveryItem.priceZarhan - order.TotalSum)*(-1);
                discountItem.quantity = 1;
                discountItem.priceNetoAfterDiscounts = discountItem.unitPrice
                items.push(discountItem);
              }   

              if(deliveryItem){
                let deliveryItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(deliveryItem, {size:'null',color:'null',barcode:null});
                deliveryItm.rowNumber = 0;
                deliveryItm.priceNetoAfterDiscounts = deliveryItem.priceZarhan
                items.push(deliveryItm);

                sale.items = items;
                sale.totalQuantity = String(totalQuantity);
                sale.payments = [];

                let currentPayment = createDeliveryPayment(order, sale, _TenbisCurrentPayment); // element.PaymentMethod
                sale.payments.push(currentPayment);

                let sequence = await Storage.Entity.Sequence.getSequenceForInvType(sale.invoiceType);
                sequence.sequence++;

                sale.invoiceSequence = sequence.sequence;
                await appDB.sequences.put(sequence);
                return sale;
            }else{
                PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"No delivery item in pos", result:`OrderId:${sale.orderNumber}`}),orderNumber,_TenbisServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing,true);
                sale.items = [];
                return sale;
            }
            }else{
                PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"Order item/add not found.", result:`OrderId:${sale.orderNumber}`}),orderNumber,_TenbisServiceLogger,Delivery.FailedDeliveryType.MissingItems,true);
                sale.items = [];
                return sale;
            }

            
        }

        function extracCatalogId(catalogIdStr){
            let pos = catalogIdStr.indexOf("_");
            if(pos == -1){
                return catalogIdStr;
            }else{
                return catalogIdStr.substring(pos+1);
            }
        }

        function createDeliveryPayment(order, sale, method){
            let currentPayment = new Storage.Entity.SalePayment(); 
            switch (method) {
                case _TenbisPayment:
                //Create Tenbis Payment
                break;
              
                case _CreditCardPayment:
                //Create Credit Card  Payment
                break;

                case _TenbisCurrentPayment:
                    currentPayment.saleID = sale.id;
                    currentPayment.method = METHOD_HAKAFA;
                    currentPayment.amount = order.TotalSum;
                    let voucherData = [
                        {
                        "amount": order.TotalSum,
                        //"creditType": "תשלום בהקפה",
                        "barCode": "",
                        "creditType": "תן ביס",
                        "voucher_type_id": "997",
                        // "voucher_type_id": "999",                        
                        "smartVoucherType": "Tenbis",
                        //"smartVoucherType": false,
                        "valuTypeId": null,
                        "praxellManpik": null,
                        "mutipassInit": null,
                        "isTamashCustomer": true
                        }
                    ]
                    currentPayment.data = JSON.stringify(voucherData)
                break;

            }
            
            return currentPayment;
        }

        function validateItemsConsist(items){
            let missingItems = items.filter(function(item) { return (item.item_desc == "NOT_FOUND") }).length;
            return (missingItems==0)
        }

         
          function validateOrder(orderNumber, orderData){
            if(orderNumber == 0 || orderData==undefined){
                PositiveTS.Service.Delivery.failedDeliveriesLogger(JSON.stringify({request:"Fetch Single Order Error or OrderNumber is zero:", result:""}),orderNumber,_TenbisServiceLogger,Delivery.FailedDeliveryType.InvalidOrderNumber)
                return false;
            }
            return true;
          }


    }}}    
