
module PositiveTS {
	export module Application {
	export module Controllers {
	export class PosItemChooserViewController extends AbstractViewControllerTS {

		colors = []
		sizes = []
		saleItem:Storage.Entity.SaleItem = null
		callbackBeforeBack:any = function () { }
		init(options) {
			var colorsTableView = $('#pos-item-chooser-color-tableview').tableview();
			var sizesTableView = $('#pos-item-chooser-size-tableview').tableview();
	
			colorsTableView.setNumberOfVisibleRows(7);
			sizesTableView.setNumberOfVisibleRows(7);
		}

		resume(options) {
	
			var aThis = posICVC;
	
			// Check that there is saleItemObject in options
			if (!('saleItemObject' in options) || !('callbackBeforeBack' in options)) {
				// No saleItemObject in options, so go back!
				pNavigator.back();
	
				// We are done!
				return;
			}
	
			// Take the sale item from the options object
			var saleItemObject = options.saleItemObject;
			aThis.callbackBeforeBack = options.callbackBeforeBack;
	
			// Create a SaleItem entity from the saleItemObject
			aThis.saleItem = new PositiveTS.Storage.Entity.SaleItem();
			aThis.saleItem.importFromObject(saleItemObject);
	
			// --- Sale item fetched successfully
			// Fetch the sizes and colors for this item
			aThis.openDialog(options)
		}

		async openDialog(options) {
	
			var aThis = posICVC;
	
			let itemBarcode = new PositiveTS.Storage.Entity.ItemBarcode();
			let sizesColors = await itemBarcode.fetchByCodeWithSizeColor(aThis.saleItem.itemCode);

			// No sizes and colors? than just add the item to sale!
			if (sizesColors.length == 0) {
				// Persist the item
				aThis.saleItem.barcode = aThis.saleItem.itemCode;
				if (options.callbackBeforeBack === "ItemInventoryItm") {
					pNavigator.back();
Pinia.componentsStore.openComponent( {componentName:"itemInventoryItm", args: [aThis.saleItem]})
					return;
				} else {
					await posVC.persistNewSaleItem(aThis.saleItem);
					pNavigator.back();
				}
	
				return;
			}
	
			// Only 1 size and color combination?
			if (sizesColors.length == 1) {
				// Set the size and color for the item to the only size and color combination
				aThis.saleItem.color = sizesColors[0].color;
				aThis.saleItem.size = sizesColors[0].size;
				aThis.saleItem.barcode = sizesColors[0].barcode;
	
				// Persist the item
				if (options.callbackBeforeBack === "ItemInventoryItm")  {
					pNavigator.back();
Pinia.componentsStore.openComponent( {componentName:"itemInventoryItm", args: [aThis.saleItem]})
					return;
				} else {
					await posVC.persistNewSaleItem(aThis.saleItem);
					pNavigator.back();
				}
	
				return;
			}
	
			// Get the table views
			var colorsTableView = $('#pos-item-chooser-color-tableview').tableview();
			var sizesTableView = $('#pos-item-chooser-size-tableview').tableview();
	
			// Empty both tables
			colorsTableView.empty();
			sizesTableView.empty();
	
			// There is more then 1 size and color combination, so let the user choose
			// Define local arrays for colors and sizes
			aThis.colors = [];
			aThis.sizes = [];
	
			for (var i = 0; i < sizesColors.length; i++) {
				// Get the current color and size
				var color = sizesColors[i]['color'];
				var size = sizesColors[i]['size'];
	
				// If the color is not an empty string and it is not already in the colors array, add it to the array and table
				if (color != '' && $.inArray(color, aThis.colors) == -1) {
					aThis.colors[aThis.colors.length] = color;
					colorsTableView.addRow([posUtils.sanitizeHtml(color) || " ללא צבע"]);
				}
	
				// If the size is not an empty string and it is not already in the sizes array, add it to the array and table
				if (size != '' && $.inArray(size, aThis.sizes) == -1) {
					aThis.sizes[aThis.sizes.length] = size;
					sizesTableView.addRow([posUtils.sanitizeHtml(size) || " ללא מידה"]);
				}
			}
	
			// If there is only 1 color, select it
			if (aThis.colors.length == 1) {
				colorsTableView.selectRow();
			}
	
			// If there is only 1 size, select it
			if (aThis.sizes.length == 1) {
				sizesTableView.selectRow();
			}
	
			// Bind the continue button to its listener
			$('#pos-item-chooser-continue').click(sizesColors,aThis.continue);
		}
		stop() {
			// Unbind the listeners from the resume function
			$('#pos-item-chooser-continue').unbind('click');
		}
		destroy() {
		}
		// --------------------------------------------------------------------
		// Listeners
		// --------------------------------------------------------------------
		continue(event) {
	
			var aThis = posICVC;
			var sizesColors = event.data;
	
			// Get the selected rows
			var theColor = $('#pos-item-chooser-color-tableview').tableview().getSelectedRow();
			var theSize = $('#pos-item-chooser-size-tableview').tableview().getSelectedRow();
	
			// Initialize an error message variable
			var message = '';
	
			// If there are colors but none selected from the table, tell the user to select a color
			if (theColor.length == 0 && aThis.colors.length > 0) {
				message = i18next.t("chooseColor");
			}
	
			// If there are sizes but none selected from the table, tell the user to select a size
			if (theSize.length == 0 && aThis.sizes.length > 0) {
				if (message != '') {
					message = message + '<br />';
				}
				message = i18next.t("chooseSize");
			}
	
			// If there is an error, display it to the user
			if (message != '') {
				app.showAlert({
					header: i18next.t('error'),
					content: message,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
	
				// We are done!
				return;
			}
	
			// Set the size and color for the item to the only size and color combination
			
			aThis.saleItem.color = $(theColor[0]).text() == " ללא צבע" ? null : $(theColor[0]).text();
			aThis.saleItem.size = $(theSize[0]).text() == " ללא מידה" ? null : $(theSize[0]).text();

			var itemByFilter = _.filter(sizesColors,function(item) {
				return (item.size === aThis.saleItem.size && item.color === aThis.saleItem.color)
			})[0];
			if (itemByFilter) {
				aThis.saleItem.barcode = itemByFilter.barcode;
			}
			else {
				app.showAlert({
					header: i18next.t('error'),
					content: i18next.t('noBarcodeForColorSizeCombo'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				}, null, null);
	
				// We are done!
				return;
			}
	
			// Persist the item
			if (aThis.callbackBeforeBack == "ItemInventoryItm") {
				pNavigator.back();
Pinia.componentsStore.openComponent( {componentName:"itemInventoryItm", args: [aThis.saleItem]});
				return;
			} else {
				posVC.persistNewSaleItem(aThis.saleItem).then(() => {
					pNavigator.back();
				})
			}
			// var executeCallbackResult = aThis.executeFunctionByName(aThis.callbackBeforeBack, aThis.saleItem);
			
		}

	}}}}



declare var posICVC:PositiveTS.Application.Controllers.PosItemChooserViewController;
posICVC = new PositiveTS.Application.Controllers.PosItemChooserViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.PosItemChooserViewController = posICVC;

