module PositiveTS {
    export module Components {
        export module HeaderComponent {
            export function create() {
                let component = {
                    props: {
                        // Never use this on the main header please! Try not to use this at all!
                        // Duplicating header components is a bad idea
                        manualState: {
                            type: Number,
                            default: undefined
                        }
                    },
                    template: JST.HeaderComponent(),
                    components: {
                        positiveIcon: PositiveIcon.getComponent(),
                    },
                    data() {
                        return {
                            /** @type {{ [key: number]: PositiveTS.Types.HeaderRouteState; }} */
                            routeStates: {
                            },
                            deviceID: session.pos.deviceID,
                            storeName: session.pos.storeName,
                        }
                    },
                    created() {
                        let routesHash: { [key: number]: PositiveTS.Types.HeaderRouteState; } = {};
                        routesHash[PositiveTS.Enums.RouteState.login] = {
                            leftClick: () => { },
                            leftIconCss: '',
                            rightClick: () => { },
                            rightIconCss: '',
                        };
                        routesHash[PositiveTS.Enums.RouteState.HomePage] = {
                            leftClick: () => this.root.logout(),
                            leftIconCss: 'fa-solid fa-sign-out',
                            rightClick: () => this.root.$refs.mobileInfo.toggle(),
                            rightIconCss: () => this.mobileLayout ? 'fal fa-info-circle regular' : '',
                        };
                        routesHash[PositiveTS.Enums.RouteState.default] = {
                            leftClick: () => this.root.goToHomepage(),
                            leftIconCss: 'fa-solid fa-house',
                            rightClick: () => this.root.$refs.mobileInfo.toggle(),
                            rightIconCss: () => this.mobileLayout ? 'fal fa-info-circle regular' : '',
                            htmlContent: false,
                            middleContent: '',
                        };
                        routesHash[PositiveTS.Enums.RouteState.Pos] = {
                            leftClick: () => this.root.goToHomepage(),
                            leftIconCss: 'fa-solid fa-house',
                            rightClick: () => this.root.$refs.mobileInfo.toggle(),
                            rightIconCss: 'fal fa-info-circle regular',

                        };
                        routesHash[PositiveTS.Enums.RouteState.SelectDalpak] = {
                            leftClick: () => this.root.goToHomepage(),
                            leftIconCss: 'fa-solid fa-house',
                            rightClick: () => {
                                if (this.mobileLayout) {
                                    this.root.$refs.mobileInfo.toggle()
                                }
                            },
                            showDeliveries: true,
                            rightIconCss: () => this.mobileLayout ? 'fal fa-info-circle regular' : '',
                            middleContent: i18next.t('selectDalpaksDialog.selectTable'),
                        };
                        routesHash[PositiveTS.Enums.RouteState.RestaurantItemMenu] = {
                            leftIconCss: 'fa-light fa-circle-xmark',
                            leftClick: () => this.root.goToHomepage(),
                            rightIconCss: 'fa-light fa-bars',
                            showDeliveries: true,
                            showTASitting: true,
                            rightClick() {
                                PositiveTS.VueInstance.$refs.menuSideBar.open();
                            },
                            middleContent: () => {
                                let dalpak = this.getCurrentSelectedDalpak;
                                if (!dalpak) return '';
                                let dalpakName: string = isNaN(parseInt(dalpak.name)) ? dalpak.name : `${i18next.t('dalpaks.table')} ${dalpak.name}`;
                                return dalpakName;
                            }
                        };
                        routesHash[PositiveTS.Enums.RouteState.RestaurantOrderSummary] = {
                            leftIconCss: 'fa-light fa-circle-xmark',
                            leftClick: () => this.root.goToHomepage(),
                            rightIconCss: 'fa-light fa-bars',
                            htmlContent: true,
                            showDeliveries: true,
                            showTASitting: true,
                            rightClick() {
                                PositiveTS.VueInstance.$refs.menuSideBar.open();
                            },
                            middleContent: () => {
                                let dalpak = this.getCurrentSelectedDalpak;
                                if (!dalpak) return '';
                                let dalpakName: string = isNaN(parseInt(dalpak.name)) ? dalpak.name : `${i18next.t('dalpaks.table')} ${dalpak.name}`;
                                if (dalpakName.length > 20 && this.mobileLayout) { // Make sure it doesn't overflow
                                    dalpakName = dalpakName.substr(0, 20) + "...";
                                }
                                let dalpakFormattedName = `
                                <div class="table-title-order-summary"><div class="text-ellipsis">${dalpakName}</div> ${i18next.t('dalpaks.orderSummary')}</div>`;
                                return dalpakFormattedName;
                            }
                        };
                        routesHash[PositiveTS.Enums.RouteState.PosPastInvoices] = {
                            leftClick: () => { this.root.goToPos(); },
                            leftIconCss: 'fa-light fa-circle-xmark',
                            rightClick() { PositiveTS.VueInstance.$refs.menuSideBar.open(); },
                            middleContent: i18next.t('pos.posPastInvoices'),
                            rightIconCss: 'fa-light fa-bars',
                        };
                        routesHash[PositiveTS.Enums.RouteState.PosSearchInvoices] = {
                            leftClick: () => { this.root.goToPos(); },
                            leftIconCss: 'fa-light fa-circle-xmark',
                            rightClick() { PositiveTS.VueInstance.$refs.menuSideBar.open(); },
                            middleContent: i18next.t('pos.posSearchInvoices'),
                            rightIconCss: 'fa-light fa-bars',
                        };
                        routesHash[PositiveTS.Enums.RouteState.MoveTable] = {
                            leftClick: () => this.root.goToHomepage(),
                            leftIconCss: 'fa-solid fa-house',
                            rightClick: () => {
                                if (this.mobileLayout) {
                                    this.root.$refs.mobileInfo.toggle()
                                }
                            },
                            showDeliveries: true,
                            rightIconCss: () => this.mobileLayout ? 'fal fa-info-circle regular' : '',
                            middleContent: i18next.t('selectDalpaksDialog.moveTable'),
                        };
                        routesHash[PositiveTS.Enums.RouteState.PosPayment] = {
                            leftClick: () => { },
                            leftIconCss: '',
                            rightClick() {
                                PositiveTS.VueInstance.$refs.menuSideBar.open();
                            },
                            rightIconCss: 'fa-light fa-bars',
                            middleContent: i18next.t('posPayment.headerTitle'),
                        };

                        this.routeStates = routesHash;
                    },
                    setup(){
                        const dalpaksStore = Pinia.useDalpaksStore();
                        const posStore = Pinia.usePosStore();
                        const {getCurrentSelectedDalpak,openedOrdersCount} = Pinia.storeToRefs(dalpaksStore);
                        const {isSaleTA} = Pinia.storeToRefs(posStore);

                        const globalStore = Pinia.useGlobalStore();
                        const {
                            cashierName,
                            route,
                            mobileLayout,
                            supportNumber,
                            emvPosNumber,
                            storeLogoUrl,
                            externalOrderIndicator,
                            deliveriesIndicator} = Pinia.storeToRefs(globalStore);
                            
                        const globalStoreProps = {cashierName,route,mobileLayout,supportNumber,emvPosNumber,storeLogoUrl,externalOrderIndicator,deliveriesIndicator}


                        return {...globalStoreProps,getCurrentSelectedDalpak,openedOrdersCount,isSaleTA}
                    },
                    computed: {
                        deliveryOrdersCount() {
                            return this.openedOrdersCount + this.externalOrderIndicator + this.deliveriesIndicator;
                        },
                        state() {
                            return this.manualState || this.route;
                        },
                        root() {
                            return PositiveTS.VueInstance;
                        },
                        isDalpakim() { return jsonConfig.getVal(jsonConfig.KEYS.isDalpakim); },
                        showDeliveries(): boolean {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            if (!jsonConfig.getVal(jsonConfig.KEYS.isDelivery)) {
                                return false;
                            }
                            return routeState?.showDeliveries ?? false;
                        },
                        showTASitting(): boolean {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.route];
                            if (!Service.SittingTA.isEnabled()) {
                                return false;
                            }
                            if (!this.isSaleTA) {
                                return false;
                            }
                            return routeState?.showTASitting ?? false;
                        },
                        leftIcon(): string {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            if (routeState) {
                                if (typeof (routeState.leftIconCss) == "string") {
                                    return routeState.leftIconCss;
                                } else {
                                    return routeState.leftIconCss();
                                }

                            } else {
                                return this.routeStates[PositiveTS.Enums.RouteState.default].leftIconCss;
                            }
                        },
                        rightIcon(): string {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            if (routeState && routeState.rightIconCss !== undefined) {
                                if (typeof (routeState.rightIconCss) === "string") {
                                    return routeState.rightIconCss;
                                } else {
                                    return routeState.rightIconCss();
                                }
                            } else {
                                return this.routeStates[PositiveTS.Enums.RouteState.default].rightIconCss;
                            }
                        },
                        middleContent(): string {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            let content: string = null
                            if (routeState && routeState.middleContent) {
                                if (typeof (routeState.middleContent) == "string") {
                                    content = routeState.middleContent;
                                } else {
                                    content = routeState.middleContent();
                                }
                            } else {
                                content = this.routeStates[PositiveTS.Enums.RouteState.default].middleContent;
                            }
                            if (this.isContentHtml) { // Just return, Html content must be containable
                                return content;
                            }
                            if (content.length > 15 && this.mobileLayout) { // Make sure it doesn't overflow
                                content = content.substr(0, 15) + "...";
                            } else if (content.length > 60 && !this.mobileLayout) { // Make sure it doesn't overflow
                                content = content.substr(0, 60) + "...";
                            }
                            return content;
                        },
                        isContentHtml(): boolean {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            if (routeState && !posUtils.isNullOrUndefined(routeState.htmlContent)) {
                                if (typeof (routeState.htmlContent) == "boolean") {
                                    return routeState.htmlContent;
                                } else {
                                    return routeState.htmlContent();
                                }
                            }
                            return this.routeStates[PositiveTS.Enums.RouteState.default].htmlContent;
                        },
                    },
                    methods: {
                        clickLeft() {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            if (routeState) {
                                routeState.leftClick();
                            } else {
                                this.routeStates[PositiveTS.Enums.RouteState.default].leftClick();
                            }
                        },
                        clickRight() {
                            let routeState: PositiveTS.Types.HeaderRouteState = this.routeStates[this.state];
                            if (routeState) {
                                routeState.rightClick();
                            } else {
                                this.routeStates[PositiveTS.Enums.RouteState.default].rightClick();
                            }
                        },
                        openDeliveries() {
                            PositiveTS.VueInstance.$refs.pos.openOpenDeliveries();
                        },
                        openTASittingDialog() {
                            Pinia.componentsStore.openComponent(
                                { componentName: "SittingOrTADialog", args: [true] });
                        },

                    }
                }
                return VueApp.component('header-component', component);
            }
        }
    }
}
