module PositiveTS {
  export module Components {
    export module SelectDalpakDialog {
      
      export const route = "/dalpak-selection";

      export function getComponent() {
        return {
          template: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView ) ? JST.selectTableDialog() : JST.selectDalpakDialog(),
          components: {
            tableItem: TableItem.getComponent(),
            tableSearchDialog: TableSearchDialog.getComponent(),
            positiveIcon: PositiveIcon.getComponent(),
            tableAttributesDialog: TableAttributesDialog.getComponent(),
            areaAttributesDialog: AreaAttributesDialog.getComponent(),
            draggable: posUtils.supportsVueDraggable() ? window.VueDraggable : PositiveTS.Components.DummyComponent.getComponent(),
            moveTableComponent: MoveTableComponent.getComponent(),
            changeWaiterComponent: changeWaiterComponent.getComponent(),
          },
          methods: {
            openDeliveries() {
              PositiveTS.VueInstance.$refs.pos.openOpenDeliveries();
            },
            async openCreateNewDalpak() {
              let result = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);

              if (result) {
                let order = 1;

                if (this.dalpaks) {
                  order = (_.max(this.dalpaks.map(d => d.sortOrder)) || 0) + 1;
                }

                VueServices.Router.goto(CreateDalpakDialog.route, { sortOrder: order });
              }
            },
            async openCreateNewDalpakArea() {
              let result = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);

              if (result) {
                let order = 1;

                if (this.dalpakAreas) {
                  order = (_.max(this.dalpakAreas.map(d => d.sortOrder)) || 0) + 1;
                }

                VueServices.Router.goto(CreateDalpakAreaDialog.route, { sortOrder: order });
              }
            },
            async openDalpakActions(dalpak) {

              let dalpakResult = await Service.Dalpak.getAndLock(dalpak.id);

              if (!dalpakResult.success || dalpak.lockedBy !== null) {
                return app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('selectDalpaksDialog.lockedDalpakActions'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
              }

              VueServices.Router.goto(DalpakActionsDialog.route, {}, { dalpak: dalpakResult.data });
            },
            openDalpakAreaActions(dalpakArea) {
              VueServices.Router.goto(PositiveTS.Components.DalpakAreaActionsDialog.route, {}, { dalpakArea: dalpakArea });
            },
            async loadDalpaks() {
              this.isLoading = true;

              this.$nextTick(async () => {
                await Service.Dalpak.loadAllDalpaks();
                this.isLoading = false;
              });
            },
            async loadDalpakAreas() {
              this.isLoading = true;

              await Service.Dalpak.loadAllDalpakAreas();
              this.isLoading = false
            },
            getDalpakSale(dalpak: Service.DalpakInfra.Dalpak) {
              return dalpak.sale;
            },
            getDalpakOpenedHour(dalpak) {
              let sale = this.getDalpakSale(dalpak);

              if (!sale) {
                return '';
              }

              return moment(sale.openDate).format('HH:mm');
            },
            getDalpakSaleCustomer(dalpak) {
              return Service.Dalpak.getDalpakSaleCustomer(dalpak);
            },
            async selectDalpak(dalpak) {
              try {
                await Service.Dalpak.selectDalpakAndGoToPos(dalpak);
              } catch (err) {
                console.error(err);
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: err.message,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
              }
            },
            toggleOffModelsIfClickedOutside() {
              this.toggleSortByModel(false);
              this.toggleFilterByAreaModel(false);
            },
            toggleSortByModel(bool?: boolean) {
              if (typeof bool === 'boolean') this.showSortBySelectionModel = bool;
              else this.showSortBySelectionModel = !this.showSortBySelectionModel;
              if (this.showSortBySelectionModel) {
                this.toggleFilterByAreaModel(false);
              }
            },
            toggleFilterByAreaModel(bool?: boolean) {
              if (typeof bool === 'boolean') this.showFilterByArea = bool;
              else this.showFilterByArea = !this.showFilterByArea;
              this.disableFilterAreaButtons = true;
              if (this.showFilterByArea) {
                this.toggleSortByModel(false);
                this.enableFilterAreaButtons();
              } else {
                this.enableFilterAreaButtons.cancel()
              }
            },
            toggleDalpakSearchDialogModel(bool?: boolean) {
              if (typeof bool === 'boolean') {
                this.showDalpakSearchDialogModel = bool;
              } else {
                this.showDalpakSearchDialogModel = !this.showDalpakSearchDialogModel;
              }
            },
            selectDalpakArea(dalpakArea) {
              this.dalpakAreaView.areaId = dalpakArea.railsId.toString()
              this.dalpakAreaView.name = dalpakArea.name;

              if (!this.editMode) {
                Service.Dalpak.saveCurrentSelectedDalpakAreaInLocalStorage(dalpakArea);
              }

              if (this.isDesktop) {
                this.toggleFilterByAreaModel(false);
              }
            },
            dalpakColorClass(dalpak) {
              let blackClass = "black";
              let whiteClass = "white";

              return posUtils.shouldBeBlackTextDependsOnBackgroud(dalpak.color) ? blackClass : whiteClass;
            },
            dalpakAreaColorAndSelectedClass(dalpakArea) {
              let blackClass = "black";
              let whiteClass = "white";

              let classToReturn = posUtils.shouldBeBlackTextDependsOnBackgroud(dalpakArea.color) ? blackClass : whiteClass;
              classToReturn = dalpakArea.railsId.toString() == this.dalpakAreaView.areaId ? classToReturn + ' selected' : classToReturn

              return classToReturn
            },
            isPrimaryDeliveryPos() {
              return jsonConfig.getVal(jsonConfig.KEYS.isPrimaryDeliveryPos);
            },
            isPrimaryPosOrSinglePos() {
              return Service.Dalpak.isPrimaryPosOrSinglePos();
            },
            isDelivery() {
              return jsonConfig.getVal(jsonConfig.KEYS.isDelivery);
            },
            isDalpakimOnline() {
              return jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline);
            },
            openCouriersAssignmentDialog() {
              return PositiveTS.VueInstance.$refs.pos.openCouriersAssignmentDialog();
            },
            openOpenDeliveries() {
              return PositiveTS.VueInstance.$refs.pos.openOpenDeliveries();
            },
            closeDeliveryOrdersDialog() {
              return PositiveTS.VueInstance.$refs.pos.closeDeliveryOrdersDialog();
            },

            isShowOpenDrawerOnSelectDalpaks() {
              return jsonConfig.getVal(jsonConfig.KEYS.showOpenDrawerOnSelectDalpaks);
            },

            openDrawerWithLog() {
              printer.openDrawerWithLog()
            },
            async printInvoice(dalpak) {
              await Service.Dalpak.printDalpakInvoice(dalpak);
            },
            getSortIcon(propName) {
              if (propName && propName == this.sort.prop) {
                if (this.isDesktop) {
                  return this.sort.desc ? 'fa-solid fa-arrow-up-wide-short' : 'fa-solid fa-arrow-down-wide-short';
                } else {
                  return this.sort.desc ? 'fa-solid fa-sort-circle-up' : 'fa-solid fa-sort-circle-down';
                }
              }

              return 'fa-sort-circle';
            },
            getSortLabel(propName) {
              return this.sortOptions[propName]?.label || i18next.t('sort');
            },
            sortBy(propName) {
              if (propName == this.sort.prop) {
                if (this.sort.desc) {
                  this.sort.desc = false;
                } else {
                  this.sort.prop = null;
                }
              } else {
                this.sort.prop = propName;
                this.sort.desc = true;
              }

              if (this.isDesktop) this.toggleSortByModel(false);
            },
            filterDalpakByFilters(dalpak) {
              if (this.filters.status != null && Service.Dalpak.getDalpakStatus(dalpak) != this.filters.status) {
                return false;
              }

              if (this.filters.waiter != null) {
                if (!dalpak.sale || dalpak.sale.salespersonEmployeeID != this.filters.waiter) {
                  return false;
                }
              }

              return true;
            },
            goToDalpakSearch() {
              if (this.mobileLayout) VueServices.Router.goto(TableSearchDialog.route);
              else this.toggleDalpakSearchDialogModel(true);
            },
            goToDalpakFilter() {
              VueServices.Router.goto(TableFilterDialog.route);
            },
            async askForManagerApprovalAndStartEditMode() {
              let result = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);

              if (result) {
                let lastSelectedDalpakArea = Service.Dalpak.getCurrentSelectedDalpakAreaFromLocalStorage()
                if((this.allDalpakAreas?.length > 0 && lastSelectedDalpakArea?.railsId == "-1" ) 
                || (this.allDalpakAreas?.length > 0 && !lastSelectedDalpakArea)){
                  this.dalpakAreaView.areaId = this.allDalpakAreas[0].railsId.toString()
                  this.dalpakAreaView.name = this.allDalpakAreas[0].name;
                  Service.Dalpak.saveCurrentSelectedDalpakAreaInLocalStorage(this.allDalpakAreas[0]);
                }
                this.startEditMode();
              }
            },
            async promptAndCancelEditMode() {
              let result = await app.showAlertDialog({
                header: i18next.t('selectDalpaksDialog.cancelChangesTitle'),
                content: i18next.t('selectDalpaksDialog.cancelChangesContent'),
                continueButtonText: i18next.t('ok'),
                cancelButtonText: i18next.t('cancel'),
                hideCancelButton: false,
                noHandleEnterEscape: true,
              })

              if (result != "cancel") {
                this.cancelEditModeChanges();
                this.resetDalpakAreaSelection();
              }
            },
            async saveEditModeChangesAndResetDalpakAreaSelection() {
              await this.saveEditModeChanges();

              if (!this.editMode) { // If finished successfully
                this.resetDalpakAreaSelection();
              }

            },
            resetDalpakAreaSelection() {
              if (this.sortedDalpakAreas && this.sortedDalpakAreas.length > 0) {
                this.selectDalpakArea(this.sortedDalpakAreas[0]);
              } else {
                Service.Dalpak.clearCurrentSelectedDalpakAreaFromLocalStorage();
                this.dalpakAreaView = {
                  areaId: null,
                  name: null,
                  sortOrder: null,
                };
              }
            },
            async openEditAreaAttributesDialog() {
              let dalpakArea = this.dalpakAreasInEditModeWithoutDeleted.filter(area => String(area.railsId) == String(this.selectedArea))[0]

              if (dalpakArea) {
                await this.$refs.areaAttributesDialog.open(dalpakArea, false);
                let isDalpakAreaExists = this.dalpakAreasInEditModeWithoutDeleted.filter(area => String(area.railsId) == String(this.selectedArea)) > 0;

                if ((!isDalpakAreaExists && this.hasDalpakAreasInEditMode)) {
                  this.selectDalpakArea(this.dalpakAreasInEditModeWithoutDeleted[0]);
                }
              } else {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('selectDalpaksDialog.pleaseSelectArea'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
              }
            },
            async openNewAreaAttributesDialog() {
              let id = Storage.createUUID()
              await this.$refs.areaAttributesDialog.open({ [Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW]: true, id, railsId: id, sortOrder: this.dalpakAreasInEditModeWithoutDeleted.length + 1 }, true);

              if (this.dalpakAreasInEditModeWithoutDeleted.length == 1) {
                this.selectDalpakArea(this.dalpakAreasInEditModeWithoutDeleted[0]);
              }
            },
            async openNewDalpakAttributesDialog() {
              await this.$refs.tableAttributesDialog.open({ [Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW]: true, id: Storage.createUUID(), sortOrder: this.dalpaksInEditMode.length + 1 });
            },
            async openMoveTableDialog(dalpak) {
              if (this.mobileLayout) {
                VueServices.Router.goto(MoveTableComponent.route, { dalpakId: dalpak.id });
              } else {
                let result = await this.$refs.moveTableComponent.open(dalpak.id);

                if (result) {
                  this.loadDalpaks();
                }
              }
            },
            async openChangeWaiterDialog(dalpak: string, employee: string) {
              if (this.mobileLayout) {
                VueServices.Router.goto(changeWaiterComponent.route, { dalpakId: dalpak, activeEmployee: employee });
              } else {
                await this.$refs.changeWaiterComponent.open(dalpak, employee);
                await this.loadDalpakAreas();
                await this.loadDalpaks();
              }
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {
              dalpaksByArea,
              openedOrdersCount,
              dalpakBoxView,filters,
              editMode,dalpaksInEditModeByArea,
              hasDalpakAreas,
              dalpakAreasInEditModeWithoutDeleted,
              hasDalpakAreasInEditMode,
              allDalpaks,
              allDalpakAreas
            } = Pinia.storeToRefs(dalpaksStore);

            const dalpaksProps = {
              dalpaks:allDalpaks,
              allDalpakAreas,
              dalpaksByArea,openedOrdersCount,
              dalpakBoxView,
              filters,
              editMode,
              dalpaksInEditModeByArea,
              hasDalpakAreas,
              dalpakAreasInEditModeWithoutDeleted,
              hasDalpakAreasInEditMode}

            const {
              setDalpaksPageTitle,
              setDalpakBoxView,
              setDalpaksInEditMode,
              startEditMode,
              cancelEditModeChanges,
              saveEditModeChanges} = dalpaksStore
              
            const dalpaksActions = {
              setDalpaksPageTitle,
              setDalpakBoxView,
              setDalpaksInEditMode,
              startEditMode,
              cancelEditModeChanges,
              saveEditModeChanges
            }

            const globalStore = Pinia.useGlobalStore();
            const {
              deliveriesIndicator,
              syncServerOnlineState,
              deliveryErrorExists,
              mobileLayout,
              externalOrderIndicator
            } = Pinia.storeToRefs(globalStore);

            const globalStoreProps = {deliveriesIndicator,
              syncServerOnlineState,
              deliveryErrorExists,
              mobileLayout,
              externalOrderIndicator
            }

            return {...globalStoreProps,...dalpaksProps,...dalpaksActions}
          },
          computed: {
            supportsVueDraggable() { return posUtils.supportsVueDraggable(); },
            dalpaksInEditMode: {
              get() {
                return _.sortBy(this.dalpaksInEditModeByArea[this.selectedArea || Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT], 'sortOrder');
              },
              set(dalpaks) {
                for (let index = 0; index < dalpaks.length; index++) {
                  dalpaks[index].sortOrder = index + 1;
                }

                let newDalpaks = _.clone(this.dalpaksInEditModeByArea)

                newDalpaks[this.selectedArea || Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT] = dalpaks;

                this.setDalpaksInEditMode(_.flatten(Object.values(newDalpaks)));
              }
            },

            dalpakAreas() {
              return this.editMode ? this.dalpakAreasInEditModeWithoutDeleted : this.allDalpakAreas
            },
            showDeliveries(): boolean {
              return this.isDelivery()
            },

            sortedDalpaks() {
              if (this.isLoading) {
                return null;
              }

              let dalpaks = [];

              if (posUtils.isBlank(this.selectedArea)) {
                dalpaks = this.dalpaksByArea[Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT];
              } else {
                if (this.selectedArea == "-1") {
                  for (let dalpakArea of this.dalpakAreas) {
                    dalpaks = dalpaks.concat(this.dalpaksByArea[dalpakArea.railsId.toString()] || []);
                  }
                } else {
                  dalpaks = this.dalpaksByArea[this.selectedArea];
                }
              }

              if (this.filters && dalpaks) {
                dalpaks = dalpaks.filter(this.filterDalpakByFilters);
              }


              if (this.sort.prop) {

                let res = _.sortBy(dalpaks, this.sortOptions[this.sort.prop]?.func);

                if (this.sort.desc) {
                  res = res.reverse();
                }

                return res;
              }

              return _.sortBy(dalpaks, 'sortOrder');
            },
            sortOptions() {
              const sortPropFunctions = {
                name: {
                  func: dalpak => dalpak.name,
                  label: i18next.t('selectDalpaksDialog.table'),
                },
                salespersonEmployeeName: {
                  func: dalpak => dalpak.sale ? dalpak.sale.salespersonEmployeeName : "",
                  label: i18next.t('selectDalpaksDialog.waiter'),
                },
                avgPerDiner: {
                  func: dalpak => Service.Dalpak.getDalpakTotals(dalpak).avgPerDiner,
                  label: i18next.t('selectDalpaksDialog.avgPerDiner'),
                },
                customerName: {
                  func: dalpak => dalpak.sale ? dalpak.sale.customerName : "",
                  label: i18next.t('selectDalpaksDialog.customerName'),
                },
                openingHour: {
                  func: dalpak => dalpak.sale ? new Date(dalpak.sale.createdAt) : "",
                  label: i18next.t('selectDalpaksDialog.openingHour'),
                },
                status: {
                  func: dalpak => Service.Dalpak.getDalpakStatus(dalpak),
                  label: i18next.t('selectDalpaksDialog.status'),
                }
              }
              return sortPropFunctions;
            },
            selectedSortByIcon() { // For Desktop usage
              return this.getSortIcon(this.sort.prop)
            },
            selectedSortByLabel() { // For Desktop usage
              return this.getSortLabel(this.sort.prop)
            },
            isDesktop() {
              return !this.mobileLayout;
            },
            sortedDalpakAreas() {
              let dalpakAreas = _.sortBy(this.dalpakAreas || [], 'sortOrder');

              if (jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) && !this.editMode) {
                dalpakAreas.unshift({ name: i18next.t('all'), railsId: '-1' })
              }

              return dalpakAreas;
            },
            selectedArea() {
              let selectedAreaToReturn = this.dalpakAreas && this.dalpakAreas.length > 0 ? this.dalpakAreaView.areaId : null

              return selectedAreaToReturn
            },
            selectedAreaName() {
              return this.dalpakAreas && this.selectedArea ? this.dalpakAreas.find(area => area === this.selectedArea) : 'הכול';
            },
            selectedSortName() {
              return this.prop.sort;
            },
            deliveryOrdersCount() {
              return this.openedOrdersCount + this.externalOrderIndicator + this.deliveriesIndicator;
            },
            serverStatus() {
              switch (this.syncServerOnlineState) {
                case Service.WebsocketSync.SyncServerClient.CONNECTION_STATUSES.DISCONNECTED:
                  return i18next.t('dalpaks.serverStatusDisconnected');
                  break;
                case Service.WebsocketSync.SyncServerClient.CONNECTION_STATUSES.WAITING_FOR_AUTH:
                  return i18next.t('dalpaks.serverStatusAuth');
                  break;
                case Service.WebsocketSync.SyncServerClient.CONNECTION_STATUSES.CONNECTED:
                  return i18next.t('dalpaks.serverStatusConnected');
                  break;
              }

              return null;
            },
          },
          data() {
            return {
              isLoading: false,
              dalpakRefreshInterval: null,
              showFilterByArea: false,
              showSortBySelectionModel: false,
              showDalpakSearchDialogModel: false,
              disableFilterAreaButtons: false,
              enableFilterAreaButtons: _.debounce(() => { this.disableFilterAreaButtons = false; }, 400),
              sortByOptions: {},
              dalpakAreaView: {
                areaId: null,
                name: null,
              },
              sort: {
                prop: null,
                desc: null,
              },
            }
          },
          async mounted() {
            await this.loadDalpakAreas();
            await this.loadDalpaks();
            this.dalpakRefreshInterval = setInterval(() => {
              if (!this.editMode) {
                this.loadDalpakAreas();
                this.loadDalpaks();
              }
            }, 1000 * 60 * 3);

            if (this.sortedDalpakAreas && this.sortedDalpakAreas.length > 0) {
              let lastSelectedDalpakArea = Service.Dalpak.getCurrentSelectedDalpakAreaFromLocalStorage()
              if (posUtils.isBlank(lastSelectedDalpakArea)) {
                this.dalpakAreaView.areaId = this.sortedDalpakAreas[0].railsId.toString()
                this.dalpakAreaView.name = this.sortedDalpakAreas[0].name
              } else {
                this.dalpakAreaView.areaId = lastSelectedDalpakArea.railsId.toString()
                this.dalpakAreaView.name = lastSelectedDalpakArea.name
              }
            }
          },
          beforeUnmount() {
            clearInterval(this.dalpakRefreshInterval)
          },
          beforeRouteEnter: async function (to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(i18next.t('selectDalpaksDialog.selectTable'))
            Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.SelectDalpak);
            next();
          },
          beforeRouteLeave: async function (to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(null)
            next();
          }
        }
      }
    }
  }
}
