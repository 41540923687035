module PositiveTS {
	export module Application {
	export module Controllers {
	export class OpenformatReportViewController extends AbstractViewControllerTS {

        chooseDateStart = new Date()
        chooseDateEnd = new Date()


        init(options) {


            $('#openformat-report-from_date').datepicker({
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                maxDate: "+0d",
            });


            $('#openformat-report-to_date').datepicker({
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                maxDate: "+0d",
            }); 

            $('#openformat-report-from_date-container').inputField()
            $('#openformat-report-to_date-container').inputField()

            $('#openformat-report-to_date').autoselected();
            $('#openformat-report-from_date').autoselected();

            $('#openformat-report-percent-container').inputField({
                keyboardOn: true,
                disclosureOn: false
            });

            let aThis = openformatReportVC;
            $('#openformat-report-month-container').positiveRadioSelect({

                changed: aThis.changeDate
            });
            $('#openformat-report-month-container').positiveRadioSelect('select', 1);
            $("#openformat-report-employee-card-note").hide();
        }
        changeDate() {

            openformatReportVC.chooseDateStart = $('#openformat-report-from_date').datepicker("getDate");
            openformatReportVC.chooseDateEnd = $('#openformat-report-to_date').datepicker("getDate");

            if (openformatReportVC.chooseDateStart == null || openformatReportVC.chooseDateEnd == null) {
                return;
            }

            openformatReportVC.chooseDateStart.setHours(0, 0, 0, 0);
            openformatReportVC.chooseDateEnd.setHours(23, 59, 59, 999);
            $("#openformat-report-employee-card-note").hide();
        }
        resume(options) {
            $('#openformat-report-continue').click(openformatReportVC.continueClicked);
        }
        stop() {
            // Unbind the listeners from the resume function
            $('#openformat-report-continue').unbind('click');

        }
        destroy() {}


        displayErrorMessage(errorMessage) {
            app.showAlert({
                header: i18next.t('error'),
                content: errorMessage,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            }, function() {}, null);
        }
        async continueClicked() {
            var aThis = exceptionsReportVC;

            openformatReportVC.changeDate();

            let from_date = openformatReportVC.chooseDateStart;
            let to_date = openformatReportVC.chooseDateEnd;

            if ((from_date == null) || (to_date == null)) {

                openformatReportVC.displayErrorMessage(i18next.t('openformatReport.datesNotSelected'));
                return;
            }

            app.showLoadingMessage(i18next.t('generatingReportWait'));

            var sof = new PositiveTS.Service.ServiceOpenformatAjax($('#openformat-report-from_date').datepicker("getDate"), 
                                                                $('#openformat-report-to_date').datepicker("getDate"));
            var isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

            try {
                const response = await sof.runReport();
                $("#openformat-report-employee-card-note").show();
                $("#openformat-report-localpath").text(response.pathLocal);
                $("#openformat-report-ini-txt").unbind("click");
                $("#openformat-report-ini-txt").click(PositiveTS.Service.OpenformatResult.getIni);
                $("#openformat-report-bkmvdata-txt").unbind("click");
                $("#openformat-report-bkmvdata-txt").click(PositiveTS.Service.OpenformatResult.getBkmvdata);
                app.hideLoadingMessage();
                if (isAndroid == true) {

                    var promises = [];

                    promises.push(PositiveTS.Service.OpenformatResult.getIni());
                    promises.push(PositiveTS.Service.OpenformatResult.getBkmvdata());

                    return Promise.all(promises).then(function (values) {
                        $("#openformat-report-instruct").hide();
                        $("#openformat-report-localpath").text("מיקום הקבצים:\n" + values[0] + "\n" + values[1]);
                        app.showAlert({
                            header: "שמירת קבצים",
                            content: "מיקום הקבצים:\n" + values[0] + "\n" + values[1],
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true
                        });
                    });

                }
                else {
                    await PositiveTS.Printing.printHtmlOpenFormat(response);
                }
            } catch (err) {
                aThis.displayErrorMessage(err.message);
                console.error(err);
                app.hideLoadingMessage();
            }
        }
        closeScreen() {
            pNavigator.back();
        }

    }
}}}

declare var openformatReportVC:PositiveTS.Application.Controllers.OpenformatReportViewController;
openformatReportVC = new PositiveTS.Application.Controllers.OpenformatReportViewController();
PositiveTS.Application.Controllers.instances.OpenformatReportViewController = openformatReportVC;
