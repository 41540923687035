module PositiveTS {
    export module Components {
    export module EmployeeManage {
      
      export const route = "/employee-manage";


    
      export function getComponent() {
        return {
            template: JST.employeeManage(),

            watch : {
              cardscan () {
                this.searchDebounce()
              }           
            },
            
            created () {
              this.searchDebounce = _.debounce(this.filterEmployee, 100);
            },

            methods: {
              
              async filterEmployee() {
                let isActive = (this.btns.filter( row =>{return row.isSelected})[0].id == "active")
                let cardscan = this.cardscan
                
                this.empList = await appDB.employees.filter(emp => 
                  emp.isLocked == !isActive && (emp.name.includes(cardscan) || (emp.taz && emp.taz.includes(cardscan)) ) ).toArray()
              },
              editEmployee(emp:Storage.Entity.Employee) {
                VueServices.Router.goto(EmployeeManageEdit.route,{id: emp.id})
              },
              
              employeeHours(emp:Storage.Entity.Employee){
                if(emp.syncStatus == Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY){
                  VueServices.Router.goto(EmployeeHours.route,{id: emp.id})
                } else{
                  app.showAlert({
                    header:  i18next.t('error'),
                    content: i18next.t('ett.employeeNotSynced'),
                    hideCancelButton: true,
                  })
                }
                
              },
          
              addEmployee(){
                VueServices.Router.goto(EmployeeManageEdit.route)
              },
          
              close() {
                pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null);
              },
              async pickDateReport(){
                await Pinia.componentsStore.openComponent( {componentName:"EmployeeReportMonthDialog", args: [this.empList]});
              }
          },
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },
            data() {
              return {
                rejectFunc: null,
                resolveFunc: null,
                intitialized: false,
                empList: [],
                timeReportRows: [],
                selectedDate: new Date(),
                employeeObj: null,
                totalHours: "0.00",
                name: "",
                cardscan: "",
                btns: [
                  new PositiveTS.Components.PositiveRadio.Btn("פעילים","active",true),
                  new PositiveTS.Components.PositiveRadio.Btn("נעולים","locked",false),
                ],
              };
            },
            async mounted() {
              // (<any>this.$refs.cardscan).focus();
              this.cardscan = "";
        
              //this.$refs.paymentTypes.btns = this.btns;
                
            
              (<any>this.$refs.isActivatedBtns).btns = this.btns;
              await this.filterEmployee();
            },
    
    
        }
    
      }  
    
    }}}