module PositiveTS {
    export module Components {
        export module SimpleKeyboard {
            
    
          export function create() {
    
            let component = {
              template: JST.SimpleKeyboard(),
              name: "SimpleKeyboard",
              props: {
                keyboardClass: {
                  default: "simple-keyboard",
                  type: String
                },
              },
              data: () => ({
                keyboard: null,
                inputEl: null,
                customFunction: null
              }),
              mounted() {
                Vue.watch(() => Pinia.languageManagerStore.currentLang, (newValue, oldValue) => {
                  if(newValue == 'en'){
                    this.keyboard.setOptions({
                      layoutName: 'shift'
                    });
                  } else {
                    this.keyboard.setOptions({
                      layoutName: 'default'
                    });
                  }
              });
                this.keyboard = new Keyboard({
                  onChange: this.onChange,
                  onKeyReleased: this.onKeyPress,
                  layout: KeyboardLayout,
                  mergeDisplay: true,
                  caps: true
                });
              },
              methods: {
                open() {
                    this.$el.show();
                },
                onChange(text) {
                    this.$emit("onChange", text);
                },
                onKeyPress(button) {
                  if (button === "{shift}" || button === "{lock}") {
                    this.handleShift();
                  }
                  if (button === "{continue}" ) {
                    this.customFunction();
                  }
                  if(button === "{enter}") {
                    this.handleEnter();
                  }
                  if(button === "{caps}" || button === "{caps-fill}") {
                    this.handleCaps();
                  }
                },
                setLayout(layout){
                    this.keyboard.setOptions({
                        layout: layout
                      });
                },
                setDisplay(display){
                    this.keyboard.setOptions({
                        display: display
                      });
                },
                setCustomFunction(func){
                    this.customFunction = func;
                },
                setNewInput(inputEl){
                    this.inputEl = inputEl;
                    this.keyboard.setOptions({
                        inputName: inputEl.id
                      });
                      this.keyboard.setInput(inputEl.value)
                      this.inputEl.addEventListener("input", (event) => {
                          this.keyboard.setInput(event.target.value)
                      })
                },
                handleShift() {
                  let currentLayout = this.keyboard.options.layoutName;
                  let shiftToggle = currentLayout === "default" ? "shift" : "default";
            
                  this.keyboard.setOptions({
                    layoutName: shiftToggle
                  });
                },
                handleCaps(){
                  let currentLayout = this.keyboard.options.layoutName;
                  if(currentLayout !== "default" ){
                    let shiftToggle = this.caps ? "shift" : "caps";
                    this.caps = !this.caps;
                    this.keyboard.setOptions({
                      layoutName: shiftToggle
                    });
                  }  
                },
                handleEnter() {
                  let {keyboard} = this
                  let currentText = keyboard.getInput()
                  currentText+= '\n'
                  keyboard.setInput(currentText)
                }
              },
              watch: {
              }
            }
    
            VueApp.component('simple-keyboard',component)
          }
        }
      }
    }
    