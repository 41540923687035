module PositiveTS {
  export module Service {
    export module Fingerprint {
      export class FingerprintUi{
        constructor() { throw new Error("Static class"); }

        static enroll(){
          var employeesTable = $('#employee-store-connection-tableview').tableview();
          var tableData = employeesTable.getSelectedRow().data();
          if (!tableData) {return;} //No employee selected
          var employeeUnq = tableData.employee.employeeID;

          return app.promiseShowAlert({
            header: i18next.t('fingerprintDefineHeader'),
            content: i18next.t('fingerprintDefineQuestion'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: false
          })
          .then((result)=>{
            if (result === "cancel") {return;}

            return Service.Fingerprint.enroll(employeeUnq)
            .then(()=>{
              return app.promiseShowAlert({
                header: i18next.t('fingerprintDefineHeader'),
                content: i18next.t('successfullyCompleted'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            })
            .catch((e)=>{
              console.error(e);
              app.hideLoadingMessage();
              return app.promiseShowAlert({
                header: i18next.t('fingerprintDefineHeader'),
                content: i18next.t('error') + ': ' + e.message,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            });
          });

        }
      }
    }}}
