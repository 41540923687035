/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"punchCardInput", arguments: ["10.50","123123"]})
*/
module PositiveTS {
    export module Components {
    export module PunchCardInput {
      const _dialogSelector = "#positive-punch-card-input-dialog"
      const _vueComponentName = 'punch-card-input';
    
      export function create() {
        let vueObj = {
            template: JST.punchCardInput(),
            methods: {
              open: _open,
              close: _close,
              doConfirm: _doConfirm,
              doReject: _doReject
            },
            data: _initData,
    
    
        }
    
        VueApp.component(_vueComponentName,vueObj)
    
      }
    
    
    
      function _open(){
        
    
        $(document).unbind('keypress');
        $(function() {
          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: '80%',
            height: 600,
            resizable: false,
            closeOnEscape: false,
            draggable: false,
          });
          $(_dialogSelector).dialog('open');
        });
    
    
        return new Promise((resolve,reject) => {
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.cardscan = "";
        })
    
      }
    
    
      function _initData(){
        return {
          _dialogSelector: _dialogSelector,
          rejectFunc: null,
          resolveFunc: null,
          cardscan: "",
        };
      }
    
      function _doConfirm(){
        if (this.cardscan){
          this.close();
          this.resolveFunc(this.cardscan);
        }
    
      }
    
      function _doReject(){
        this.close();
        this.rejectFunc(new Error(app.userCancelledGlobalMessage));
      }
    
      function _close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }
    
    
    }}}
