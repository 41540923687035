module PositiveTS {
export module Service {
export module CheckBalance {
    export function openCheckBalance(){
        return Pinia.componentsStore.openComponent( {componentName:"checkCardBalance", args: []})
        .then( result =>{
            app.showLoadingMessage(i18next.t('genericUserPleaseWait'))
            if (result.cardType === "chargableCard"){
                return _chargableCard(result.cardscan)
            } else if (result.cardType === "punchCard"){
                return PositiveTS.Service.PunchCard.showBalance(result.cardscan)
            } else if (result.cardType === "praxell"){
                return _praxell(result.cardscan)
            } else if (result.cardType === "valueCard"){
                return _valueCard(result.cardscan)
            } else if (result.cardType === "multipass"){
                return _multipass(result.cardscan)
            } else if (result.cardType === "chargableCreditCard"){
                return PositiveTS.Service.CreditCard.checkBalance(result.cardscan)
            }   else if (result.cardType === "multipassPolice"){
                return Pinia.componentsStore.openComponent( {componentName:"multipassPoliceBalance", args: [result.cardscan]});
            }
        })
        .then(()=>{
            app.hideLoadingMessage();
        })
    }



    function _multipass(cardNumber){
        return (new PositiveTS.Service.Multipass()).getBalance(cardNumber)
        .then( response => {
            app.hideLoadingMessage();
            if (response.success) {
                Pinia.componentsStore.openComponent( {
                    componentName: 'valuCardBalance', 
                    args: [session.fixedNumber(response.balance),cardNumber]
                });
            } else {
                app.showAlert({
                    header: i18next.t("checkBalance"),
                    content: response.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                    });
            }
            })
    }

    function _valueCard(cardNumber){
        return (new PositiveTS.Service.ValueCard()).getBalance(cardNumber)
        .then( response => {
            app.hideLoadingMessage();
            if (response.success) {
                Pinia.componentsStore.openComponent( {
                    componentName: 'valuCardBalance', 
                    args: [session.fixedNumber(response.balance),cardNumber]
                });
            } else {
                app.showAlert({
                    header: i18next.t("checkBalance"),
                    content: response.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                    });
            }
            })

    }

    function _praxell(cardNumber){
        return aPrxlAdon.promiseGetCardBalance(cardNumber)
        .then(function(praxellResponse) {
        app.hideLoadingMessage();

        if (praxellResponse.success) {
            app.showAlert({
            header: i18next.t("checkBalance"),
            content: i18next.t("voucherBalanceIs", {AMOUNT: session.fixedNumber(praxellResponse.amount)}),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
            });
        } else {
            app.showAlert({
            header: i18next.t("checkBalance"),
            content: praxellResponse.message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
            });
        }
        });
    }

    function _chargableCard(cardNumber){
        return (new PositiveTS.Service.Valu()).getBalance(cardNumber)
        .then( response => {
        app.hideLoadingMessage();
        if (response.success) {
            Pinia.componentsStore.openComponent( {
                componentName: 'valuCardBalance', 
                args: [session.fixedNumber(response.balance),cardNumber]
            });
        } else {
            app.showAlert({
                header: i18next.t("checkBalance"),
                content: response.error,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
                });
        }
        })
    }
}}}
