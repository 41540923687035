module PositiveTS {
  export module Components {
    export module FlightBarsetCount {

      export function getComponent() {
        return {
          template: JST.flightsBarsetCount(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.barsetMixin],
          methods: {
            skipStage() {
              this.showAlertWithTitleGeneric(i18next.t('flightsWizard.barsetCount.skipWarning'),
              i18next.t('warn'),
              this.resetCountAndGoNext,
              null,
              false);
            },
            resetCountAndGoNext() {
              this.autofill(this.barsetItems.map(this.barsetItemMapFunction));
              this.goToNextStep();
            },
            barsetCountGoBack() {
              if (this.isCounting) {
                this.isCounting = false;
              } else {
                this.goBack();
              }
            },
            startCount() {
              this.isCounting = true;
            },
            finishCount() {
              if (this.verifyData()) {
                this.goToNextStep();
              } else {
                this.showError(i18next.t('flightsWizard.barsetCount.validationFailed'));
              }
            },
            isValidCountNumber(value) {
              return (!isNaN(parseInt(value)) && value == parseInt(value)) && parseInt(value) >= 0;
            },
            verifyData() {
              this.showErrors = true;

              if (Object.keys(this.itemsCount).length != Object.keys(this.barsetItems).length) {
                return false;
              }

              for (let itemId in (this.itemsCount)) {
                if (!this.isValidCountNumber(this.itemsCount[itemId])) {
                  return false;
                }
              }

              return true;
            },
            nextInput(event) {
              let nextInputIndex = this.$refs.countInput.indexOf(event.target) + 1;

              if (this.$refs.countInput[nextInputIndex]) {
                this.$refs.countInput[nextInputIndex].focus();
                return;
              }

              this.finishCount();
            },
            autofill(items) {
              for (let barsetItem of items) {
               this.stepData.itemsCount[barsetItem.id] = this.zerofyMinus(barsetItem.calculated)
              }
            },
            isFilled(items) {
              for (let barsetItem of items) {
                if (this.zerofyMinus(this.stepData.itemsCount[barsetItem.id]) != this.zerofyMinus(barsetItem.calculated)) {
                  return false;
                }
              }

              return true;
            },
            tenantID() {
              return session.pos.tenantID;
            },
            companyID() {
              return session.pos.companyID;
            }
          },
          computed: {
            itemsCount() {
              return this.stepData && this.stepData.itemsCount || {};
            },
            usePictures(){
              return Pinia.globalStore.usePictures
            },
          },
          data() {
            return {
              isCounting: false,
              showErrors: false,
              itemsById: {},
            }
          },
          mounted() {
            app.showLoadingMessage(i18next.t("loading"));

            this.getCurrentBarset().then(barset => {
              if (!(this.stepData && this.stepData.itemsCount)) {
                this.stepData.itemsCount = {};
                this.autofill(this.barsetItems.map(this.barsetItemMapFunction));
              }

              this.$emit('input', { itemsCount: this.itemsCount });

            });
          }
        }
      }
    }
  }
}