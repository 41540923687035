module PositiveTS {
/*
usage:
    posId = "64001";
    posPassword = "675622";
    cashiersPassword = "222961";

  PositiveTS.Service.ValueCardService.getValuecardPayment("101001006",0.5,posVC.saleItems)
  PositiveTS.Service.ValueCardService.getLoadBudgetQuery("101001006",1)
  PositiveTS.Service.ValueCardService.getVoidTransaction(cardNumber:string,voidTransactionId:string)
  PositiveTS.Service.ValueCardService.getCardInformation("101001006")
*/
export module Service {
export module ValueCardService {
  export const jsonDataKey = "value_card_customer";
  export const clubName = "valuecard";
  const valueDiscountItemCode = jsonConfig.getVal(jsonConfig.KEYS.valueCardDiscountItemCode)
  
  export function getValueDiscountItemCode () {
    return valueDiscountItemCode
  }

  const _url = 'https://www.valuecard.co.il/App/Interface/WebServices/POSWebService.asmx';
  const _type = "POST";
  const _fileLog = "VALUE_CARD"
  const _soapService = {
     ValuecardPayment: "ValuecardPayment",
     LoadBudgetCommitQuery: "LoadBudgetCommitQuery",
     LoadBudgetQuery: "LoadBudgetQuery",
     VoidTransaction: "VoidTransaction",
     CardInformation: "CardInformation",
     RegisterClubMemberEx: "RegisterClubMemberEx",
     GetBenefits: "GetBenefitsQuery",
   }
  const _addonServiceTag = "external_service"
  const _valueCardBenefitsTrxKey = "value_card_benefits_trx"

  function _valuPosParams(){
    return {
      valuPosId: session.pos.valuePosId,
      valuePosPassword: session.pos.valuePosPassword,
      valueCashiersPassword: session.pos.valueCashiersPassword
    }
  }

  export function valuPosParamsSet(): boolean {
    let params = _valuPosParams();
    return Boolean(params.valuPosId && params.valuePosPassword && params.valueCashiersPassword);
  }

  //if has any discout on item line need to mark as 1
  export function getBusinessId(saleItem: PositiveTS.Storage.Entity.SaleItem, sale: PositiveTS.Storage.Entity.Sale){
    let businessId = 0
    if (session.pos.useNewPromotions){
      if (!posUtils.isBlank(saleItem.promotions)){
        businessId = 1
      }

      //fo credit sale check if saleItem on original sale has promotions discount 
      if (!posUtils.isBlank(sale) && Array.isArray(sale.items)){
        let originalSaleItem = sale.items.find(it => it.rowNumber == saleItem.rowNumber)
        if (originalSaleItem && originalSaleItem.promotions){
          businessId = 1
        }
      }
    }else{
      if (!posUtils.isBlank(saleItem.getPromotionCode) && saleItem.getPromotionCode != '-1' && saleItem.getPromotionCode != '0'){
        businessId = 1
      }
    }

    return businessId
  }

    //cardNumber test CardNumber
    export async function getLoadBudgetQuery(cardNumber: string, loadSum: number): Promise<ValueQueryParsed> {

      const result = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
        { data: JSON.stringify(_loadBudgetQueryData(cardNumber, loadSum)) },
        _addonServiceTag
      );

      _validateServerResponse(result);
      let vqp = new ValueQueryParsed();
      vqp.setByXmlAndValidate(result.request.result, cardNumber, loadSum);
      return _getLoadBudgetCommitQuery(vqp);
    }

    export function getValuecardBenefitsServerJson(cardNumber:string,transactionSum:number, couponNum:number, QueryTransactionId:string,
      itemLines:PositiveTS.Storage.Entity.SaleItem[], sale?: PositiveTS.Storage.Entity.Sale){
      transactionSum = session.fixedFloat(transactionSum)

      var common = {
        "VCToken": "A1AC1420440D4EB3BF6BF7A5DF5C676D",
        "POSId": _valuPosParams().valuPosId,
        "POSPassword": _valuPosParams().valuePosPassword,
        "CashiersPassword": _valuPosParams().valueCashiersPassword,
        "CardNumber": cardNumber,
        "RequestId": "13809"};


      var items = [];
      let itemsTotalSum = 0

      for (let i=0; i<itemLines.length; i++) {

        if(itemLines[i].barcode === valueDiscountItemCode){
          continue
        }
        
        let itemUnitPriceAfterDiscount = itemLines[i].priceNetoAfterDiscounts / itemLines[i].quantity
        itemUnitPriceAfterDiscount = session.fixedFloat(itemUnitPriceAfterDiscount)
        let itemTotalSum = itemLines[i].quantity * itemUnitPriceAfterDiscount
        itemTotalSum = session.fixedFloat(itemTotalSum)
        itemsTotalSum+= itemTotalSum

        itemsTotalSum = session.fixedFloat(itemsTotalSum)

        items.push({"TransactionItem":
        {
          "ItemNumberTextVersion": itemLines[i].itemCode,
          "Amount": itemLines[i].quantity,
          "ItemPrice": itemUnitPriceAfterDiscount,
          "TotalSum": itemTotalSum,
          "BusinessId": getBusinessId(itemLines[i], sale)
        }
        })
      }

      if (transactionSum !== itemsTotalSum) {
        let balanceLeft =  transactionSum - itemsTotalSum
        balanceLeft = session.fixedFloat(balanceLeft)
        let amountToAdd = balanceLeft / items.length
        items.forEach(item => {
          item.TransactionItem.TotalSum +=  session.fixedFloat(amountToAdd)
          item.TransactionItem.ItemPrice =  item.TransactionItem.TotalSum / item.TransactionItem.Amount
  
          item.TransactionItem.TotalSum = session.fixedFloat(item.TransactionItem.TotalSum)
          item.TransactionItem.ItemPrice = session.fixedFloat(item.TransactionItem.ItemPrice)
        })
      }


      var message =  { 
        "RequestParameters":{
        "Common": common,
        "CouponNum": Math.floor(couponNum),
        "PromoNum": -1,
        "TransactionSum": transactionSum,
        "QueryTransactionId": QueryTransactionId,
        "Items": items}};

      return message;
    }

    export function setValueCardBenefitsTrxKey(sale:Storage.Entity.Sale, discount, transactionId, serverJson, printMessage){
      var saleJsondata = JSON.parse(sale.jsondata);
      saleJsondata[_valueCardBenefitsTrxKey] = {discount:discount, transactionId: transactionId, serverJson:serverJson};
      saleJsondata[jsonDataKey].printMessage = printMessage;
      saleJsondata.isValueCardServerActionRequired = true;
      sale.jsondata = JSON.stringify( saleJsondata );
    }


    export async function creditValuePointsIfExists(sale:PositiveTS.Storage.Entity.Sale, createdItemListToCredit: PositiveTS.Storage.Entity.SaleItem[] ):Promise<any>{
      let parsedJson = JSON.parse( sale.jsondata );
      let isValuePointsExists = Boolean( parsedJson["value_card_benefits_trx"] );

      if (!isValuePointsExists){
        return;
      } 
      
      let cardNumber = parsedJson["value_card_customer"]["cardNumber"]
      let trxId = parsedJson["value_card_benefits_trx"]["serverResponseJson"]["transaction_id"]

      app.showLoadingMessage()
      let result = await ValueCardService.getVoidTransaction(cardNumber,trxId)
      app.hideLoadingMessage()
      return;
    }

    export async function transmitCreditInvoice(sale:PositiveTS.Storage.Entity.Sale, createdItemListToCredit: PositiveTS.Storage.Entity.SaleItem[] ):Promise<any>{
      let parsedJson = JSON.parse( sale.jsondata );
      let isValueCustomerExists = Boolean( parsedJson["value_card_customer"] );
      if (!isValueCustomerExists){
        return; 
      }
      
      app.showLoadingMessage()
      let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(sale, createdItemListToCredit, []);
      let transactionSum = saleTotals.totalAmount
      let cardNumber = parsedJson["value_card_customer"]["cardNumber"]

      if(!session.pos.useNewPromotions) {
        createdItemListToCredit = Storage.Entity.SaleItem.setPriceNetoAfterDiscountsForOldPromotionsItems(sale, createdItemListToCredit)
      }

      let response = await PositiveTS.Service.ValueCardService.getValuecardBenefits(cardNumber,transactionSum, -1, createdItemListToCredit, sale) 
      app.hideLoadingMessage()

      let serverJson = getValuecardBenefitsServerJson(cardNumber,transactionSum, 0, response.transactionId,createdItemListToCredit, sale)
      setValueCardBenefitsTrxKey(sale,response.discount, response.transactionId, serverJson, response.printMessage)
      
    }

    export function getValuecardBenefits(cardNumber:string,transactionSum:number, couponNum:number,itemLines:PositiveTS.Storage.Entity.SaleItem[], sale?: PositiveTS.Storage.Entity.Sale):Promise<ValueQueryParsed>{
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _valuecardGetBenefitsData(cardNumber, transactionSum, couponNum, itemLines, sale) ) },
          _addonServiceTag
      ).then((result) => {
        _validateServerResponse(result);
        let vqp = new ValueQueryParsed();
        vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
        return vqp;
      })
    }

    export function getValuecardPayment(cardNumber:string,transactionSum:number,itemLines:PositiveTS.Storage.Entity.SaleItem[]):Promise<ValueQueryParsed>{
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _valuecardPaymentData(cardNumber, transactionSum, itemLines) ) },
          _addonServiceTag
      ).then((result) => {
        _validateServerResponse(result);
        let vqp = new ValueQueryParsed();
        vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
        return vqp;
      })
    }

    export function getCardBalance(cardNumber:string):Promise<number>{
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _valuecardInformatioData(cardNumber) ) },
          _addonServiceTag
      ).then((result) => {
        _validateServerResponse(result);
        let vqp = new ValueQueryParsed();
        vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
        let prepaidBalance = parseFloat($(result.request.result).find("PrepaidBalance").text());
        let totalPoints = parseFloat($(result.request.result).find("TotalPoints").text());
        if(prepaidBalance > 0){
          return prepaidBalance;
        }else{
          return totalPoints;
        }
      })
    }



    export class CardInformationItf {
      cardNumber:string;
      memberCellPhone:string;
      memberFullName: string;
      prepaidBalance: number;
      totalPoints: number;
      message: string;
      clubName = clubName
    }



    export function  getRegisterClubMemberExQuery(cardNumber:string, firstName:string, lastName:string, isMale:boolean,
      birthDate:Date, anniversaryDate:Date, cellPhone:string, email = "", address = "", 
      zipCode = "", comments = ""):Promise<ValueQueryParsed>{
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _registerClubMemberExData(cardNumber, firstName, lastName, isMale,
      birthDate, anniversaryDate, cellPhone, email, address, 
      zipCode, comments) ) },
          _addonServiceTag
      ).then((result) => {
        _validateServerResponse(result);
        let vqp = new ValueQueryParsed();
        vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
        return vqp;
      })
    
    }


    export function getCardInformation(cardNumber:string):Promise<CardInformationItf>{
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _valuecardInformatioData(cardNumber) ) },
          _addonServiceTag
      ).then((result) => {
        _validateServerResponse(result);
        let vqp = new ValueQueryParsed();
        vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
        return _parseCardInformationResult(result.request.result, cardNumber);
      })
    }

    function _parseCardInformationResult(result:string, cardNumber:string):CardInformationItf{
      var vii = new CardInformationItf();
      vii.cardNumber = cardNumber
      vii.memberCellPhone = $(result).find("MemberCellPhone").text();
      vii.memberFullName = $(result).find("MemberFullName").text();
      vii.message = $(result).find("Message").text()
      vii.prepaidBalance = parseFloat( $(result).find("PrepaidBalance").text() );
      vii.totalPoints = parseFloat( $(result).find("TotalPoints").text() );
      return vii;
    }





    export function getVoidTransaction(cardNumber:string,voidTransactionId:string):Promise<ValueQueryParsed>{
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _valuecardVoidTransaction(cardNumber, voidTransactionId) ) },
          _addonServiceTag
      ).then((result) => {
        _validateServerResponse(result);
        let vqp = new ValueQueryParsed();
        vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
        return vqp;
      })
    }


  export class ValueQueryParsed {
      returnCode: string;
      isError: Boolean;
      transactionId: string;
      discount:number = 0;
      printMessage: string;
      Message: string;
      locationFooter: string;
      cardNumber:string;
      loadSum:number;

      setByXmlAndValidate(result:string, cardNumber:string, loadSum:number = 0){
        let parser = new DOMParser();
        let parsedResult = parser.parseFromString(result, "text/xml");
        this.returnCode = parsedResult.querySelector("ReturnCode")?.textContent;
        this.isError = parsedResult.querySelector("IsError")?.textContent === "true";
        this.transactionId = parsedResult.querySelector("TransactionId")?.textContent;
        
        // commit do not return the discount field
        let discount =  parseFloat(parsedResult.querySelector("Discount")?.textContent); 
        this.discount = discount; 

        this.printMessage = parsedResult.querySelector("PrintMessage")?.textContent + "\n" + parsedResult.querySelector("NewBenefits")?.textContent;
        this.Message = parsedResult.querySelector("Message")?.textContent + "\n" + parsedResult.querySelector("NewBenefits")?.textContent;
        this.locationFooter = parsedResult.querySelector("LocationFooter")?.textContent;
        this.cardNumber = cardNumber;
        this.loadSum = loadSum;
    
        this._ResponseValidation();
      }

      private _ResponseValidation():void{
        if(this.isError){
          throw new Error(this.Message + this.locationFooter);
        }
      }
  }

  class _ExternalServieItf{
    url = _url;
    type = _type;
    headers = "";
    body:string;

    constructor (action, body){
      this.headers = JSON.stringify({
            "Content-Type": "text/xml; charset=utf-8",
            "SOAPAction": `http://valuecard.co.il/${action}`
        })
      this.body = body;
    }
  }

    function _getLoadBudgetCommitQuery(loadBudgetQueryParams:ValueQueryParsed){
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: JSON.stringify( _loadBudgetCommitQueryData(loadBudgetQueryParams) ) },
          _addonServiceTag
      )
      .then( (result) =>{
        _validateServerResponse(result);
        loadBudgetQueryParams.setByXmlAndValidate(result.request.result, loadBudgetQueryParams.cardNumber, loadBudgetQueryParams.loadSum);
        return loadBudgetQueryParams;
      })
    }





    function _validateServerResponse(result):void{
      PositiveTS.Service.Filelog.log(_fileLog,
        JSON.stringify({ request: result.request.body.data, result: result.request.result })
       );
      if (result.request.result.startsWith("ERROR:")) { throw new Error(result.request.result) } 
    }




  function _registerClubMemberExData(cardNumber:string, firstName:string, lastName:string, isMale:boolean,
      birthDate:Date, anniversaryDate:Date, cellPhone:string, email:string, address:string, 
      zipCode:string, comments:string){
    var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:RegisterClubMemberEx>
         <!--Optional:-->
         <val:RequestParameters>
            <val:RequestId>13809</val:RequestId>
            <!--Optional:-->
            <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
            <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
            <!--Optional:-->
            <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
            <!--Optional:-->
            <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
            <!--Optional:-->
            <val:CardNumber>${cardNumber}</val:CardNumber>
            <!--Optional:-->
            <val:FirstName>${firstName}</val:FirstName>
            <!--Optional:-->
            <val:LastName>${lastName}</val:LastName>
            <val:IsMale>${ isMale ? "1" : "0"}</val:IsMale>
            <!--Optional:-->
            <val:BirthDate>${birthDate ? moment(birthDate).format("YYYY.MM.DD") : ''}</val:BirthDate>
            <!--Optional:-->
            <val:AnniversaryDate>${anniversaryDate ? moment(anniversaryDate).format("YYYY.MM.DD") : ''}</val:AnniversaryDate>
            <!--Optional:-->
            <val:Phone></val:Phone>
            <!--Optional:-->
            <val:CellPhone>${cellPhone}</val:CellPhone>
            <!--Optional:-->
            <val:Email>${email}</val:Email>
            <!--Optional:-->
            <val:Address>${address}</val:Address>
            <!--Optional:-->
            <val:ZipCode>${zipCode}</val:ZipCode>
            <!--Optional:-->
            <val:Comments>${comments}</val:Comments>
         </val:RequestParameters>
      </val:RegisterClubMemberEx>
   </soapenv:Body>
</soapenv:Envelope>
    `;

    return new _ExternalServieItf(_soapService.RegisterClubMemberEx, body);
  }

  function _valuecardInformatioData(cardNumber:string){
        var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:CardInformation>
         <!--Optional:-->
         <val:RequestParameters>
            <!--Optional:-->
            <val:Common>
               <val:RequestId>13809</val:RequestId>
               <!--Optional:-->
               <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
               <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
               <!--Optional:-->
               <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
               <!--Optional:-->
               <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
               <!--Optional:-->
               <val:CardNumber>${cardNumber}</val:CardNumber>
            </val:Common>
            <!--Optional:-->
            <val:PIN></val:PIN>
            <val:Coupon>0</val:Coupon>
         </val:RequestParameters>
      </val:CardInformation>
   </soapenv:Body>
</soapenv:Envelope>
    `;

    return new _ExternalServieItf(_soapService.CardInformation, body);
  }


  function _valuecardVoidTransaction(cardNumber:string,voidTransactionId:string):_ExternalServieItf{
    var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:VoidTransaction>
         <!--Optional:-->
         <val:RequestParameters>
            <!--Optional:-->
            <val:Common>
               <val:RequestId>13809</val:RequestId>
               <!--Optional:-->
               <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
               <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
               <!--Optional:-->
               <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
               <!--Optional:-->
               <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
               <!--Optional:-->
               <val:CardNumber>${cardNumber}</val:CardNumber>
            </val:Common>
            <!--Optional:-->
            <val:PIN></val:PIN>
            <val:VoidTransactionId>${voidTransactionId}</val:VoidTransactionId>
         </val:RequestParameters>
      </val:VoidTransaction>
   </soapenv:Body>
</soapenv:Envelope>
    `;

    return new _ExternalServieItf(_soapService.VoidTransaction, body);
  }





  function _valuecardPaymentData(cardNumber:string, transactionSum:number, itemLines:PositiveTS.Storage.Entity.SaleItem[]):_ExternalServieItf{


    var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:ValuecardPayment>
         <!--Optional:-->
         <val:RequestParameters>
            <!--Optional:-->
            <val:Common>
               <val:RequestId>13809</val:RequestId>
               <!--Optional:-->
               <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
               <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
               <!--Optional:-->
               <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
               <!--Optional:-->
               <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
               <!--Optional:-->
               <val:CardNumber>${cardNumber}</val:CardNumber>
            </val:Common>
            <!--Optional:-->
            <val:PIN></val:PIN>
            <val:TransactionSum>${session.fixedNumber(transactionSum)}</val:TransactionSum>
            <val:CouponNum>0</val:CouponNum>
            <!--Optional:-->
            <val:Items>
               <!--Zero or more repetitions:-->
               <!! for (var i=0; i<itemLines.length; i++){ !!>
                 <val:TransactionItem>
                    <val:ItemNumeber>0</val:ItemNumeber>
                    <!--Optional:-->
                    <val:ItemNumberTextVersion><!!=itemLines[i].itemCode !!></val:ItemNumberTextVersion>
                    <val:Amount><!!=itemLines[i].quantity !!></val:Amount>
                    <val:ItemPrice><!!= session.fixedNumber(itemLines[i].priceNetoAfterDiscounts / itemLines[i].quantity) !!></val:ItemPrice>
                    <val:TotalSum><!!= session.fixedNumber(itemLines[i].priceNetoAfterDiscounts) !!></val:TotalSum>
                    <val:BusinessId><!!= PositiveTS.Service.ValueCardService.getBusinessId(itemLines[i]) !!></val:BusinessId>
                 </val:TransactionItem>
               <!! } !!>
            </val:Items>
         </val:RequestParameters>
      </val:ValuecardPayment>
   </soapenv:Body>
</soapenv:Envelope>
    `;


    body = _.template(body)({itemLines:itemLines});
    return new _ExternalServieItf(_soapService.ValuecardPayment, body);
  }


function _valuecardGetBenefitsData(cardNumber:string, transactionSum:number, couponNum:number, itemLines:PositiveTS.Storage.Entity.SaleItem[], sale?: PositiveTS.Storage.Entity.Sale):_ExternalServieItf{
   

    var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:GetBenefitsQuery>
         <!--Optional:-->
         <val:RequestParameters>
            <!--Optional:-->
            <val:Common>
               <val:RequestId>13809</val:RequestId>
               <!--Optional:-->
               <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
               <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
               <!--Optional:-->
               <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
               <!--Optional:-->
               <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
               <!--Optional:-->
               <val:CardNumber>${cardNumber}</val:CardNumber>
            </val:Common>
            <val:TransactionSum>${session.fixedNumber(transactionSum)}</val:TransactionSum>
            <val:CouponNum>${parseInt(couponNum.toString())}</val:CouponNum>
            <val:PromoNum>-1</val:PromoNum>
            <!--Optional:-->
            <val:Pin></val:Pin>
            <!--Optional:-->
            <val:Items>
               <!--Zero or more repetitions:-->
               <!! for (var i=0; i<itemLines.length; i++){ !!>
                 <val:TransactionItem>
                    <val:ItemNumeber>0</val:ItemNumeber>
                    <!--Optional:-->
                    <val:ItemNumberTextVersion><!!=itemLines[i].itemCode !!></val:ItemNumberTextVersion>
                    <val:Amount><!!=itemLines[i].quantity !!></val:Amount>
                    <val:ItemPrice><!!= session.fixedNumber(itemLines[i].priceNetoAfterDiscounts / itemLines[i].quantity) !!></val:ItemPrice>
                    <val:TotalSum><!!= session.fixedNumber(itemLines[i].priceNetoAfterDiscounts) !!></val:TotalSum>
                    <val:BusinessId><!!= PositiveTS.Service.ValueCardService.getBusinessId(itemLines[i], sale) !!></val:BusinessId>
                 </val:TransactionItem>
               <!! } !!>
            </val:Items>
         </val:RequestParameters>
      </val:GetBenefitsQuery>
   </soapenv:Body>
</soapenv:Envelope>
    `;

   body = _.template(body)({itemLines:itemLines, sale: sale});
   return new _ExternalServieItf(_soapService.GetBenefits, body);

}
  

  function _loadBudgetCommitQueryData(loadBudgetQueryParams:ValueQueryParsed):_ExternalServieItf{


    var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:LoadBudgetCommitQuery>
         <!--Optional:-->
         <val:RequestParameters>
            <!--Optional:-->
            <val:Common>
               <val:RequestId>13809</val:RequestId>
               <!--Optional:-->
               <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
               <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
               <!--Optional:-->
               <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
               <!--Optional:-->
               <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
               <!--Optional:-->
               <val:CardNumber>${loadBudgetQueryParams.cardNumber}</val:CardNumber>
            </val:Common>
            <!--Optional:-->
            <val:PIN></val:PIN>
            <val:LoadSum>${loadBudgetQueryParams.loadSum}</val:LoadSum>
            <!--Optional:-->
            <val:PayMethod>
               <val:Method>0</val:Method>
               <val:PaymentSum>0.0</val:PaymentSum>
            </val:PayMethod>
            <val:QueryTransactionId>${loadBudgetQueryParams.transactionId}</val:QueryTransactionId>
         </val:RequestParameters>
      </val:LoadBudgetCommitQuery>
   </soapenv:Body>
</soapenv:Envelope>
    `;

    return new _ExternalServieItf(_soapService.LoadBudgetCommitQuery, body);
  }

  function _loadBudgetQueryData(cardNumber:string, loadSum:number):_ExternalServieItf {


    var body = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:val="http://valuecard.co.il/">
   <soapenv:Header/>
   <soapenv:Body>
      <val:LoadBudgetQuery>
         <!--Optional:-->
         <val:RequestParameters>
            <!--Optional:-->
            <val:Common>
               <val:RequestId>472222</val:RequestId>
               <!--Optional:-->
               <val:VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</val:VCToken>
               <val:POSId>${_valuPosParams().valuPosId}</val:POSId>
               <!--Optional:-->
               <val:POSPassword>${_valuPosParams().valuePosPassword}</val:POSPassword>
               <!--Optional:-->
               <val:CashiersPassword>${_valuPosParams().valueCashiersPassword}</val:CashiersPassword>
               <!--Optional:-->
               <val:CardNumber>${cardNumber}</val:CardNumber>
            </val:Common>
            <!--Optional:-->
            <val:PIN></val:PIN>
            <val:LoadSum>${session.fixedNumber(loadSum)}</val:LoadSum>
            <!--Optional:-->
            <val:PayMethod>
               <val:Method>0</val:Method>
               <val:PaymentSum>0.0</val:PaymentSum>
            </val:PayMethod>
         </val:RequestParameters>
      </val:LoadBudgetQuery>
   </soapenv:Body>
</soapenv:Envelope>
    `;

    return new _ExternalServieItf(_soapService.LoadBudgetQuery, body);
  }

}}}