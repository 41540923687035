module PositiveTS {
export module Components {
export module PosPayment {
export module ICMegaView {
 
  export function create() {
      const c = {
        template:  JST.ICMegaView(),
        mixins: [keyPadMixin, Mixins.paymentViewMixin, Mixins.oneClickMixin],
        methods: {
          selectField (fieldName) {
            let refField = null;
            this.quantity = session.fixedFloat(this.quantity);
            for (let field in this.fields) {
              this.fields[field].selected = false
              this.fields[field].inEditMode = true
              this.fields[field].hasDecimal = false
              if (field == fieldName) {
                this.fields[field].selected = true
                refField = field
              }
            }
            if (refField != null) {
              this.$refs[refField].focus();
            }
          },

          onKeyTapped (key) {
            let field = this.getSelectedField();
            this.keyPadHanlder(key,field,this);
          },

          getSelectedField () {
            for (let fieldName in this.fields) {
              if (this.fields[fieldName].selected) {
                return this.fields[fieldName]
              }
            }
            return undefined;
          }, 

          clearTapped () {
            this.clearOldData();
            this.selectField('quantity');
          },

          async verifyAndPay () {
            try {
              app.showLoadingMessage("מוסיף תשלום");

              let valid = await this.verify()
              if (!valid.success){
                app.hideLoadingMessage();
                await app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: valid.errors.join('\n'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
                return
              }

              let amount = posVC.saleItems[0].unitPrice * Number(this.quantity)
              // just add the data the use create on close sale 
              let salePayment = posVC.salePayments[0]
        
              if (salePayment == null) {
                salePayment = new PositiveTS.Storage.Entity.SalePayment()
                salePayment.saleID = posVC.sale.id
                salePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT
              }

              let voucher = PositiveTS.Service.icMega.getVoucher()

              let voucherData = {
                allowPartialReturn: false,
                actionType: Service.SmartVoucherActionTypes.WITHDRAW,
                barCode: this.barcode,
                amount: amount,
                quantity: Number(this.quantity),
                voucher_type_id: voucher.typeID,
                creditType:  PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA,
                smartVoucherType: PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA,
                data: valid.response,
              }
        
              await PositiveTS.Service.VoucherPayment.saveVoucher(salePayment, voucherData)
              this.addSplittedPaymentIfNeeded();
              this.refreshView();
              app.hideLoadingMessage();
            }catch(e){
              console.error(e)
              app.hideLoadingMessage();
              await app.promiseShowAlert({
                header: i18next.t('error'),
                content: i18next.t('generalError'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            }
          }, 

          clearOldData () {
            let result = initData();
            for (let prop in result) {
              if (prop === 'creditPayments' || prop === "quantity") {
                continue;
              }
              this[prop] = result[prop];
            }
          },

          async  deleteCreditPayment (index) {
            try{
        
              let result = await app.promiseShowAlert({
                header: i18next.t('confirmSalePaymentItemDeleteHeader'),
                content: i18next.t('confirmSalePaymentItemDelete'),
                continueButtonText: i18next.t('remove'),
                cancelButtonText: i18next.t('cancel')
              });
        
              if (result === "continue"){
                await PositiveTS.Service.VoucherPayment.removeSmartVoucherPayment(this.creditPayments[index]);
                this.removeSplittedPaymentIfNeeded();
              }
        
            }catch(e){
              console.error(e);
            }
        
            this.refreshView();
          },

          async refreshView () {
            try{
              let totalQuantity = posVC.getTotalQuantity()
              let paidQuantity = 0 
              if (posVC.salePayments.length){
                let data = JSON.parse(posVC.salePayments[0].data)
                paidQuantity = data.length
              }

              this.quantity = totalQuantity - paidQuantity
        
              this.setCalculatedAmount();
        
              let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA)
              this.creditPayments = result;
              this.clearTapped();
              await PositiveTS.VueInstance.$nextTick()
            }catch(e){
              console.error(e);
            }
          },

          async verify () {
            let result = {success: false, errors: [], response: null}

            if (this.quantity <= 0) {
              result.errors.push(i18next.t("icMega.quantityMustBeBiggerThanZero"))
            }
            
            if (this.quantity > posVC.getTotalQuantity()) {
              result.errors.push(i18next.t("icMega.quantityCannotBeBiggerThanTotalQuantity"))
            }

            if (posUtils.isBlank(this.barcode)){
              result.errors.push(i18next.t("icMega.barcodeIsRequired"))
            }else{
              let barcode = typeof this.barcode == 'string' ? this.barcode.trim() : this.barcode
              if (posVC.salePayments.length){
                let data = JSON.parse(posVC.salePayments[0].data)
                let paidBarcode = data.find(r => r.barCode == barcode)
                if (paidBarcode) {
                  result.errors.push(i18next.t("icMega.cannotUseSameBarcodeTwice"))
                }
              }
            }
            
            let voucher = PositiveTS.Service.icMega.getVoucher()
            if (_.isEmpty(voucher)){
              result.errors.push(i18next.t("icMega.icMegaVoucherNotExist"))
            }

            if (result.errors.length == 0) {
              let res = await PositiveTS.Service.icMega.checkOrUseIcMegaBarcode(this.barcode);
              let response = JSON.parse(res.request.result)
              result.response = response;
              //posVC.saleItems[0] can pay only for one item code cannot get difference item code
              let validate = PositiveTS.Service.icMega.validateResponseIfCanPay(response, posVC.saleItems[0], this.quantity)

              if (!validate.valid){
                result.errors.push(...validate.errors)
              }
            }
            
            result.success = result.errors.length == 0

            return result
          },

          async swipeCard () {
            app.showLoadingMessage("העבר כרטיס");
            let cardNumber = await Service.EMV.swipe()
            if(cardNumber){
              this.barcode = cardNumber
              this.verifyAndPay()
            }
            app.hideLoadingMessage()
          },
        },
        computed: {
          mobileLayout(){
            return Pinia.globalStore.mobileLayout
          },
        },
        data: initData
      }

      VueApp.component('icmega-view',c);
  }

  function initFields() {
    let fields = {
      'quantity': {
        selected: false,
        allowDecimal: true,
        inEditMode: false,
        type: 'number',
        disabled: false
      },
      'barcode': {
        selected: false,
        allowDecimal: false,
        inEditMode: false,
        type: 'text',
        disabled: false
      }
    };

    for (let field in fields) {
      fields[field].model = field;
    }

    return fields;
  }

  function initData() {

    return {
      cardNumberText: "",
      fields: initFields(),
      clearImg: `${(<any>window).images_path}/close.png`,
      quantity: 0,
      creditPayments: [],
      barcode: '',
    }
  }

}}}}
