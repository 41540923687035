module PositiveTS {
export module Storage {
export module Entity {
export class ItemPreparationInstruction extends WasmEntity {

  preparationInstructionId:number
  itemId:number
  
  constructor() {
    let meta = {
      name: 'ItemPreparationInstruction',
      fields: {
        preparationInstructionId: "INT" ,
        itemId: "INT",
      }
    }
    super(meta)
  }
}}}}
