/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
The Event object stores all the relevant event information.
*/
module WebSocketRails {
  export class Event {
    data:any
    success_callback:any
    failure_callback:any
    name:string
    id:any
    channel:Channel
    token:any
    connection_id:any
    result:boolean
    success:any

    constructor(data, success_callback?, failure_callback?) {
      this.success_callback = success_callback;
      this.failure_callback = failure_callback;
      this.name    = data[0];
      const attr     = data[1];
      if (attr != null) {
        this.id      = (attr['id'] != null) ? attr['id'] : (((1+Math.random())*0x10000)|0);
        this.channel = (attr.channel != null) ? attr.channel : undefined;
        this.data    = (attr.data != null) ? attr.data : attr;
        this.token   = (attr.token != null) ? attr.token : undefined;
        this.connection_id = data[2];
        if (attr.success != null) {
          this.result  = true;
          this.success = attr.success;
        }
      }
    }

    is_channel() {
      return (this.channel != null);
    }

    is_result() {
      return typeof this.result !== 'undefined';
    }

    is_ping() {
      return this.name === 'websocket_rails.ping';
    }

    serialize() {
        return JSON.stringify([this.name, this.attributes()]);
      }

    attributes() {
      return {
        id: this.id,
        channel: this.channel,
        data: this.data,
        token: this.token
      };
    }

    run_callbacks(success, result) {
      this.success = success;
      this.result = result;
      if (this.success === true) {
        return (typeof this.success_callback === 'function' ? this.success_callback(this.result) : undefined);
      } else {
        return (typeof this.failure_callback === 'function' ? this.failure_callback(this.result) : undefined);
      }
    }
  }
}