module PositiveTS {
export module Components {
export module MultipassPoliceCustomerPicker {

  const _dialogSelector = "#positive-multipass-police-customer-picker";
  const _vueComponentName = 'multipass-police-customer-picker';

  export function create() {
    let loginDialog = {
        template: JST.multipassPoliceCustomerPicker(),
        methods: {
          open: open,
          async close(saleCustomer = null) {
            $(_dialogSelector).dialog('close');
            let resolveResult = this.continueToPayment && saleCustomer ? 'continue' : "cancel"
            if(!this.continueToPayment) {
              $(document).keypress(posVC.onKeyPress);
            } else if(saleCustomer) {
              resolveResult = await Pinia.componentsStore.openComponent( {componentName:"multipassPoliceChoosePoints", args: [saleCustomer]});
            }
            this.resolveFunc(resolveResult);
          },
          async findCustomer() {
            app.showLoadingMessage(i18next.t('multipassPolice.lookingForCustomer'))
            let multipass = new Service.MultipassPolice();
            let cvv = null;
            if(!posUtils.isNullOrUndefinedOrEmptyString(this.pinCode) && this.card.length < 9) {
              cvv = this.pinCode
            } 
        
            this.customer = await multipass.getBalance(this.card, cvv);
            let isWorkingWithIDF = PositiveTS.Service.MultipassPolice.isWorkingWithIDF()
            if(Number(this.customer.ResultId) == 0){
              this.saleCustomerPoints = new Array();
              this.customer.PointsBudgetList.Point.forEach(point => { 
                this.saleCustomerPoints.push({
                  amount: Number(point.Amount) / 100,
                  type: point.Type,
                  preText: isWorkingWithIDF && this.customer.CompanyId == "1184" ? i18next.t('multipassPolice.pointTypes.stars') : ""
                })});
            }
            app.hideLoadingMessage()
          },
          async chooseCustomer() {
            let saleCustomer = {
              clubName: Shared.Constants.MultipassPolicePoints.CLUB_CODE,
              s_first_name: this.customer.EmployeeName,
              s_last_name: "",
              s_id_number: 0,
              points: this.availableSaleCustomerPoints,
              card: this.card,
              pinCode: this.pinCode,
              companyId: this.customer.CompanyId
            }

            if(PositiveTS.Service.MultipassPolice.shouldSaveCustomerInfoInSale()){
              let saleJson = JSON.parse(posVC.sale.jsondata);
              saleJson["customer"] = saleCustomer;
              posVC.sale.jsondata = JSON.stringify(saleJson);
              await Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);
              await posVC.loadSale();
            }
            await this.close(saleCustomer);
          },
          async swipe(){
            app.showLoadingMessage(i18next.t('multipassPolice.waitingForSwipe'))
            this.card = await Service.EMV.swipe();
            app.hideLoadingMessage()
            if(this.card){
              this.findCustomer()
            }
          },
          async cancel() {
            this.customer = null;
            this.card = null;
            this.pinCode = null;
            this.saleCustomerPoints = null;
            await this.close();
          }
        },
        data: _initData,
        computed:{
            availableSaleCustomerPoints() {
              let availablePointsForPos = PositiveTS.Service.MultipassPolice.getAvailableWalletsForPos();
              let saleCustomerPoints: PositiveTS.Types.MultipassPointsWallet[] = this.saleCustomerPoints || [];
              if (!PositiveTS.Service.MultipassPolice.canUseVirtualPoints()) {
                let virtualWalletNumber: number = (jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceVirtualPointNumber) || 0);
                saleCustomerPoints = saleCustomerPoints.filter(p => p.type != virtualWalletNumber.toString())
              }
              if (posUtils.isBlankLikeRails(availablePointsForPos)) {
                return saleCustomerPoints;
              } else {
                return saleCustomerPoints.filter(p => availablePointsForPos.includes(p.type));
              }              
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
            disablePromos() {
              return jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceDisablePromos);
            },
        }
    }
    VueApp.component(_vueComponentName,loginDialog)
  }

  function open(continueToPayment){
    let saleJson = JSON.parse(posVC.sale.jsondata);
    this.isEmv = session.pos.isEmv
    if(!posUtils.isNullOrUndefined(saleJson.customer) && saleJson.customer.clubName == Shared.Constants.MultipassPolicePoints.CLUB_CODE) {
      this.card = saleJson.customer.card;
      this.findCustomer();
    } else {
      this.customer = null;
      this.card = null;
      this.pinCode = null;
      this.saleCustomerPoints = null;
    }

    this.continueToPayment = continueToPayment

    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: 'positive-dialog',
        width: Pinia.globalStore.mobileLayout ? '95vw' : 800,
        height: Pinia.globalStore.mobileLayout ? window.outerHeight * 0.8 : 466,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });


    $(document).unbind('keypress');
    if (this.$refs.multipassPolicesearchTextInput) {
      this.$refs.multipassPolicesearchTextInput.focus();
    }
    return new Promise(async (resolve) => {
      if (PositiveTS.Service.MultipassPolice.policePointsHaveBeenPaid()) {
        await PositiveTS.Service.MultipassPolice.abortPayments();
      }
      this.resolveFunc = resolve;      
    });
  }

  function _initData(){
    return {
      customer: null,
      card: null,
      pinCode: null,
      saleCustomerPoints: null,
      isEmv: false,
      continueToPayment: false,
      resolveFunc: null,
    };
  }

}}}
