<script setup lang="ts">
import { ref } from 'vue';
import Qrcode from 'qrcode.vue';
import FetchRequest from '@m/service/shared/fetchReqService'
import GenericDialog from '../../shared/genericDialog.vue';

const dialogElement = ref(null);
const guideDialog = ref(null);
const taxUrl = ref(null);

const close = () => {
    taxUrl.value = null;
    dialogElement.value.close();
}

const open = async () => {
    dialogElement.value.showModal();
    if (taxUrl.value) return;
    let response = await FetchRequest.jsonReq('/tax_authority/get_url', 'get')
    taxUrl.value = response.result.url;
}

const openGuide = () => {
    guideDialog.value.open();

}

defineExpose({
    open
})
</script>

<template>
    <dialog ref="dialogElement" class="p-0 w-90vw p2:w-[55vw] p2-pro:fixed p2-pro:top-25">
        <div class="p-0">
            <div class="title w-full bg-primary-default text-center text-white py-2">
                {{ $t('taxAuthorityConnect.title') }}</div>
            <div class="p-4 flex flex-col items-center justify-center" v-if="taxUrl">
                <p class="text-center">{{ $t('taxAuthorityConnect.step1') }}</p>
                <Qrcode v-if="taxUrl" :value="taxUrl" :size="225"></Qrcode>
                <p class="text-center">{{ $t('taxAuthorityConnect.step2') }}</p>
                <span class="font-bold">{{ taxUrl }}</span>
            </div>
            <div v-else class="p-4 flex flex-col items-center justify-center">
                <p class="text-center">{{ $t('loading') }}</p>
            </div>
            <div data-role="footer">
                <div class="dialog-buttons-bottom">
                    <button @click="close" class="red positive-simple shadow">{{ $t("close") }}</button>
                    <button @click="openGuide" class="yellow positive-simple shadow">{{ $t('taxAuthorityConnect.guide') }}</button>
                </div>
            </div>
        </div>
    </dialog>
    <GenericDialog ref="guideDialog" class="p2-pro:fixed p2-pro:top-25">
        <template v-slot:title>
                {{ $t('taxAuthorityConnect.guide') }}
        </template>
        <template v-slot:content>
            <div class="flex justify-center items-center p-4">
                <img src="/tax_guide.webp" class="max-w-full">
            </div>
            <div data-role="footer">
                <div class="dialog-buttons-bottom">
                    <button @click="guideDialog.close" class="red positive-simple shadow">{{ $t("close") }}</button>
                </div>
            </div>
        </template>
    </GenericDialog>

</template>
