module PositiveTS {
  export module Components {
    export module chooseChangeCurrencyDialog {
      const dialogSelector = 'choose-change-currency-dialog';
      export function create() {
        let chooseChangeCurrencyDialog = {
          template: JST.chooseChangeCurrencyDialog(),
          components: {
            PositVueKeyboard: PositiveTS.Components.PositVueKeyboard.getComponent(),

          },
          data() {
            return {
              change: 0,
              leftChange: 0,
              currencies: {},
              paidCurrencies: {},
              paidCurrenciesSnapshot: {},
              resolveFunc: null,
              isValid: true,
              validMessage: "",
              showKeyboard: false,
              activeCurrency: null,
              layout: [
                "1 2 3 4 5 6 7 8 9 0 . {bksp}",
              ],
              display: {
                '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
              },
            };
          },
          computed: {
            posCurrencySign() {
              return Service.MultiCurr.getInstance().getPosCurrencySign();
            },
            isMobile(){
              return Pinia.globalStore.mobileLayout
            },
            filteredCurrencies() {
              let result = {};
              for (let key in this.paidCurrencies) {
                if (this.paidCurrencies.hasOwnProperty(key) && this.paidCurrencies[key] > 0) {
                  result[key] = this.paidCurrencies[key];
                }
              }
              return result;
            }
          },
          methods: {
            isValidInput(value) {
              const regex = /^\d*\.?\d*$/;
              return regex.test(value);
            },
            handleInput(code, value) {
              if (this.isValidInput(value)) {
                this.paidCurrencies[code] = value;
              } else {
                this.paidCurrencies[code] = this.paidCurrenciesSnapshot[code];
              }
              let sum = 0;
              for (let currency in this.paidCurrencies) {
                if (this.paidCurrencies[currency] < 0) {
                  this.paidCurrencies[currency] = 0;
                } else {
                  const rate = this.currencies[currency].rate;
                  sum += session.fixedFloat(this.paidCurrencies[currency] / rate, 2);
                }
              }
              if (session.fixedFloat(sum, 2) > session.fixedFloat(this.change, 2)) {
                const rate = this.currencies[code].rate;
                const amount = this.leftChange * rate + parseFloat(this.paidCurrenciesSnapshot[code] || 0);
                this.paidCurrencies[code] = session.fixedFloat(amount, 2);
              }

              this.calculateCurrencies();
              this.isValid = this.validateAmounts();
              this.takeSnapshot();
            },
            takeSnapshot() {
              this.paidCurrenciesSnapshot = _.cloneDeep(this.paidCurrencies);
            },
            async submit() {
              this.calculateCurrencies();
              if (this.validateAmounts()) {
                try {
                  this.close({ success: true, paidCurrencies: this.filteredCurrencies });
                } catch (error) {
                  console.error(error);
                  this.close({ success: false, paidCurrencies: this.paidCurrencies, error: error });
                }
              }
            },
            async toggleKeyboard(code) {
              this.showKeyboard = !this.showKeyboard;
              this.activeCurrency = code;
              if (this.showKeyboard) {
                this.focusElement(code);
              }
            },

            async setActiveInput(code) {
              if (this.paidCurrencies[code] == 0) {
                this.paidCurrencies[code] = "";
              }
              this.activeCurrency = code;
              if (this.showKeyboard) {
                this.focusElement(code);
              }
            },
            focusElement(code) {
              let inputElement = document.getElementById("paidCurrencies-" + code) // refrence for this shit: https://bobbyhadz.com/blog/focus-not-working-in-javascript 
              // js is great!@!!@!
              if (inputElement) {
                setTimeout(() => {
                  inputElement.focus();
                }, 0);
              }
            },
            keypress(button) {
              if (button == '{bksp}') {
                this.paidCurrencies[this.activeCurrency] = String(this.paidCurrencies[this.activeCurrency]).slice(0, -1);
              } else {
                this.paidCurrencies[this.activeCurrency] = this.paidCurrencies[this.activeCurrency] + (button);
              }
              this.handleInput(this.activeCurrency, this.paidCurrencies[this.activeCurrency])
              this.focusElement(this.activeCurrency)
            },
            validateAmounts() {
              let sum = 0;
              if (this.leftChange === this.change) {
                this.validMessage = i18next.t('multiCurrencyChange.enterChange')
                return false;
              }
              for (let currency in this.paidCurrencies) {
                const rate = this.currencies[currency].rate;
                sum += session.fixedFloat(this.paidCurrencies[currency] / rate, 2);
              }
              if (session.fixedFloat(sum, 2) != session.fixedFloat(this.change, 2)) {
                this.validMessage = i18next.t('multiCurrencyChange.invalidChangeAmount')
                return false;
              }
              this.validMessage = "";
              return true;

            },
            calculateCurrencies(inital = false) {
              this.leftChange = this.change;
              let allCurrencies = posPaymentVC.Currencies;
              let currencies = PositiveTS.Service.MultiCurr.getInstance().getPosCurrenciesEnabled();
              currencies.map((currency) => {
                const name = PositiveTS.Service.MultiCurr.getInstance().getCurrencyName(currency);
                const code = currency;
                const symbol = PositiveTS.Service.MultiCurr.getInstance().getCurrencySign(currency);
                const rate = allCurrencies[currency];
                if (inital) {
                  this.paidCurrencies[currency] = 0;
                } else {
                  // reduce the paid currency from the left change in pos currency
                  this.leftChange -= session.fixedFloat(this.paidCurrencies[currency] / rate, 2);
                }
                this.currencies[currency] = { name, symbol, rate, code };
              });
            },
            open() {
              let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let paidWithCash = PositiveTS.Helper.SaleHelper.getTotalPaidByMethod(posVC.salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_CASH);
              let change = PositiveTS.Helper.SaleHelper.calcuateChange(saleTotals.totalAmount, saleTotals.totalPaid, paidWithCash, saleTotals.totalRound)
              this.change = session.fixedFloat(Math.abs(change.cash), 2);
              this.calculateCurrencies(true);
              this.takeSnapshot();
              this.$el.showModal();
              $(document).unbind('keypress');
              return new Promise(resolve => { this.resolveFunc = resolve });
            },
            cancel(event) {
              event.preventDefault()
              event.stopImmediatePropagation()
              return this.close({ success: false});
            },
            close(resolveResult) {
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();
              this.resolveFunc(resolveResult);
            },
          },
        }
        VueApp.component(dialogSelector, chooseChangeCurrencyDialog)
      }
    }
  }
}
