module PositiveTS { 
  export module Printing {
    interface PaymentRowItem {
      account: string;
      totalAmount: number;
      amount: number;
      aryCheckNumber: string[];
      bank: string;
      branch: string;
      date: string;
      ern: string;
      manualApproval: string;
      number: string;
      paymentCount: number;
      timeStamp: number;
    }

    export class CheckErnSlip extends AFactory {

        private _METHOD = PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK;

        constructor(sale: any, company: any, salePayments: any) {
            super(sale, company, salePayments);
        }

        run(): void{
          if (!this._isRequired()) {return;}

          this._printCheckItems();
        }

        _printCheckItems():void{
          var uniquePaymentsDict  = this._getUniquePaymentItems()
          for (var paymentCheckTimestamp in uniquePaymentsDict) {
            this._printCheckItem( uniquePaymentsDict[paymentCheckTimestamp] );
          }
        }

        _printCheckItem(paymentRowItem:PaymentRowItem[]):void{
          var aThis = this._aThis;
          var zebra = this._zebra;
          var sale = this._sale;

          printer.printLineCut();
          this.printHeaderForSlipType("שובר :");

          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.rightAlignment);
          aThis.printLine(" ");

          if ( serviceCheckErn.isErnSupported() ){
            aThis.printFloatingLine("שובר", this._sale.invoiceSequence.toString());
            aThis.printFloatingLine("מספר אישור", paymentRowItem[0].ern);
            aThis.printFloatingLine('שעה ותאריך', this._sale.createdAt);
          }
          if ( this._sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE ) {
            aThis.printFloatingLine("סוג עסקה",
                                        "זיכוי");
          } else {
            aThis.printFloatingLine("סוג עסקה",
                                        "חיוב");
          }

          aThis.printFloatingLine("חשבון", paymentRowItem[0].account);

          this.lineFeed();
          var currentRow: PaymentRowItem;
          for (var i=0;i < paymentRowItem.length;i++){
            aThis.printHr();
            currentRow = paymentRowItem[i];
            if (currentRow.aryCheckNumber && currentRow.aryCheckNumber.length > 0){
              aThis.printLine("תשלום " + (i + 1).toString() + " " + "מתוך" + " " + currentRow.aryCheckNumber.length.toString());
            }
            aThis.printLine("סכום הצק " + session.fixedNumber(currentRow.amount));
            aThis.printLine("תאריך פרעון " + currentRow.date);
            aThis.printLine("צק מספר " + currentRow.number);
          }
          aThis.printHr();
          this.lineFeed();
          if ( this._sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE ) {
            aThis.printLine("זיכוי");
          } else {
            aThis.printLine("חיוב");
          }
          aThis.printFloatingLine("סכום העסקה", session.fixedNumber(paymentRowItem[0].totalAmount));
          this.lineFeed();
          zebra.append(aThis.allowComponents.rightAlignment);
          aThis.printLine("לקוח יקר, נבקש לעדכן אותך,");
          aThis.printLine("כי חברת אי.אר.אן. ישראל בע\"מ")
          aThis.printLine("שומרת פרטים ומידע אודותיך,")
          aThis.printLine("לרבות פרטי העסקה שביצעת והשיק/ים שמסרת,")
          aThis.printLine("ומשתמשת בהם לצרכיה ולטובת מתן שירותיה בלבד.")
          aThis.printLine("פרטים נוספים" + " " + "www.ern.co.il");
          this.lineFeed();
          aThis.printLine("תודה ולהתראות");
          this.lineFeed();
          printer.printLineCut();

        }

        _getUniquePaymentItems():{ [id: string] : PaymentRowItem[]; } {
          var currentCheckObject = PositiveTS.Helper.SaleHelper.findPaymentByMethod( this._salePayments, this._METHOD);
          var checkData = JSON.parse(currentCheckObject.data);
          var uniquePayments : { [id: string] : PaymentRowItem[]; } = {};

          var currentId: string;
          for (var i=0; i<checkData.length; i++){
            currentId = checkData[i].timeStamp.toString();
            if (!uniquePayments[currentId]) {uniquePayments[currentId] = [];}
            checkData[i].totalAmount = currentCheckObject.amount;
            uniquePayments[currentId].push(checkData[i]);
          }
          return uniquePayments;
        }

        _isRequired():boolean{
          return (  this._totalPayed()  !== 0 &&  serviceCheckErn.isErnSupported() );
        }

        private _totalPayed():number{
          return PositiveTS.Helper.SaleHelper.getTotalPaidByMethod(this._salePayments, this._METHOD);
        }
      }
    }
  }
