<script setup lang="ts">
import { componentsStore } from '@/Store/Pinia';
import FetchReq from '@m/service/shared/fetchReqService';
import { computed, reactive, ref } from 'vue';

interface transmitResponse {
    success: boolean;
    error: string | Array<any>;
    confirmation_number: string;
    log_id: number;
    response_code: number;
}

interface State {
    resolveFunc: Function | null;
    sale: PositiveTS.Storage.Entity.Sale | any | null;
    invoiceType: string;
    customer: { id: string | number, name: string, type: string };
    saleErrors: string;
    errors: { id: string, name: string };
    loading: boolean;
    transmitResponse: transmitResponse | null;
    isManual: boolean;
    isFromSearch: boolean;
    showConnectButton: boolean;
    allowFieldEdit: boolean;
    supportedInvoiceTypes: Array<number>;
}

const dialogElement = ref(null);
const state: State = reactive({
    resolveFunc: null,
    sale: null,
    invoiceType: "sale",
    customer: { id: "", name: "", type: "" },
    saleErrors: "",
    errors: {
        id: "",
        name: ""
    },
    loading: false,
    transmitResponse: null,
    isManual: true,
    isFromSearch: false,
    showConnectButton: false,
    allowFieldEdit: false,
    supportedInvoiceTypes: [
        PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE,
        PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE,
        PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV,
    ]
});

function cleanData() {
    state.sale = null;
    state.customer = { id: "", name: "", type: "" };
    state.saleErrors = "";
    state.errors = {
        id: "",
        name: ""
    };
    state.loading = false;
    state.transmitResponse = null;
    state.isManual = true;
    state.isFromSearch = false;
    state.allowFieldEdit = false;
    state.showConnectButton = false;
}
function cancel() {
    if (!state.isManual) {
        printInvoice(true);
    }
    return close("cancel");
}
function close(resolveResult = null) {
    dialogElement.value.close();
    state.resolveFunc(resolveResult);
    cleanData();
}
async function connect() {
    await componentsStore.openComponent({ componentName: "taxAuthorityConnect", args: [] })
    state.showConnectButton = false;
}
function open(sale, isManual = true, isFromSearch = false, invoiceType = "sale") {
    state.invoiceType = invoiceType;
    state.sale = sale;
    state.isManual = isManual;
    state.isFromSearch = isFromSearch;
    if (state.invoiceType == "sale") {
        prepareSaleData();
    } else {
        prepareInvoiceData();
    }
    if (!dialogElement.value.open) {
        dialogElement.value.showModal();
    }
    return new Promise((resolve) => {
        state.resolveFunc = resolve
    });
}
function prepareSaleData() {
    if (!state.supportedInvoiceTypes.includes(state.sale.invoiceType)) {
        state.saleErrors = i18next.t("transmitTaxInvoiceDialog.invoiceTypeNotSupported")
    }
    let jsondata = JSON.parse(state.sale.jsondata);
    state.customer.type = "none";
    let saleItemTotalWithoutVat = 0;

    let calcuatedVat = PositiveTS.Helper.SaleHelper.calculateVat(state.sale.totalVatableAmount, state.sale.vat);
    saleItemTotalWithoutVat = Math.abs(state.sale.totalVatableAmount) - Math.abs(calcuatedVat)

    if (saleItemTotalWithoutVat == 0) {
        state.saleErrors = i18next.t("transmitTaxInvoiceDialog.cantTransmitSaleWithoutVAT")
    }
    if (jsondata.tax_authority) {
        state.saleErrors = i18next.t("transmitTaxInvoiceDialog.saleTransmitted", { confirmation_number: jsondata.tax_authority.confirmation_number })
    }
    if (state.sale.syncStatus !== PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY && state.isManual) {
        state.saleErrors = i18next.t("transmitTaxInvoiceDialog.syncSaleToTransmit")
    }

    if (jsondata.customer) {
        if (jsondata.customer.clubName === "HAKAFA") {
            state.customer.id = jsondata.customer.s_id_number;
            state.customer.name = jsondata.customer.s_first_name + " " + jsondata.customer.s_last_name;
            state.customer.type = "hakafa";
        } else {
            state.customer.name = jsondata.customer.s_first_name + " " + jsondata.customer.s_last_name;
            state.customer.type = "club";
        }
    }
}
function prepareInvoiceData() {
    if (state.sale.total_vat == 0) {
        state.saleErrors = i18next.t("transmitTaxInvoiceDialog.cantTransmitSaleWithoutVAT")
    }
    state.customer.type = "hakafa";
    state.customer.id = state.sale.hakafa_customer.tz;
    state.customer.name = state.sale.dedicated_to_text;
}
async function transmit() {
    if (!validateCustomer()) return;
    state.loading = true;
    try {
        const response = await transmitInvoice();
        handleTransmitResponse(response);
    } catch (error) {
        console.error(error);
    } finally {
        state.loading = false;
    }
}
function validateCustomer() {
    const validationRules = [
        { key: 'id', message: i18next.t("transmitTaxInvoiceDialog.pleaseEnterValidId"), validator: posUtils.validateTz },
        { key: 'name', message: i18next.t("transmitTaxInvoiceDialog.pleaseEnterValidName"), validator: value => value.trim().length > 0 && value.trim().length <= 30 }
    ];
    return applyValidationRules(validationRules);
}
function applyValidationRules(rules) {
    let isValid = true;
    rules.forEach(({ key, message, validator }) => {
        const value = state.customer[key];
        if (!validator(value)) {
            state.errors[key] = message;
            isValid = false;
        } else {
            delete state.errors[key];
        }
    });
    return isValid;
}
async function transmitInvoice() {
    return await FetchReq.makeJobRequest({
        url: "/tax_authority", method: "post", data: {
            invoice_number: state.sale.invoiceSequence || state.sale.invoice_number,
            customer: state.customer,
            invoice_type: state.invoiceType,
        }
    }, true);
}
async function handleTransmitResponse(response) {
    state.transmitResponse = response.result;
    console.log(response);
    if (response.result.success) {
        if (state.invoiceType == "sale") {
            updateSaleData(response.result);
        } else {
            updateInvoiceData(response.result);
        }
        printInvoice(!state.isManual);
        close();
    } else {
        // We fetch the updated sale from the queue to see if it has any errors
        if (state.sale.id && !state.isManual) {
            let sale = await appDB.sales.get(state.sale.id);
            state.sale.syncStatus = sale.syncStatus;
            if (sale.syncStatus == PositiveTS.Storage.Entity.Sale.SYNC_STATUS_FAILED) {
                state.saleErrors = i18next.t("transmitTaxInvoiceDialog.saleSyncError")
            }
        }
        if (typeof response.result.error != 'string') {
            state.allowFieldEdit = true;
        }
    }
}
function updateSaleData(transmitResult) {
    const jd = JSON.parse(state.sale.jsondata);
    jd.tax_authority = { confirmation_number: transmitResult.confirmation_number, customer: state.customer };
    state.sale.jsondata = JSON.stringify(jd);
    if (!state.isFromSearch) {
        PositiveTS.Service.FullSale.persist(state.sale, state.sale.items, state.sale.payments);
    }
}
function updateInvoiceData(transmitResult) {
    state.sale.tax_confirmation_number = transmitResult.confirmation_number;
    if (PositiveTS.VueInstance.$refs['hakafaMerakezInvoicesDialog']) {
        PositiveTS.VueInstance.$refs['hakafaMerakezInvoicesDialog'].selectedInvoice.tax_confirmation_number = transmitResult.confirmation_number;
    }
}
function printInvoice(isSource = false) {
    if (state.invoiceType == "sale") {
        PositiveTS.Printing.Invoice.printInvoice(state.sale, saleItemHelper.unflattenSaleItems(state.sale.items), state.sale.payments, isSource);
    } else {
        PositiveTS.Printing.Invoice.printMerakezInvoice(state.sale)
    }
}
function handleStringError(errorString) {
    switch (errorString) {
        case "sale_transmitted_already":
            return i18next.t("transmitTaxInvoiceDialog.saleTransmitted");
        case "sale_not_found":
            return i18next.t("transmitTaxInvoiceDialog.saleNotFound");
        case "login_again_bo":
            state.showConnectButton = true;
            return i18next.t("transmitTaxInvoiceDialog.loginAgainBo");
        case "unknown_error":
            if (state.transmitResponse.log_id) {
                return i18next.t("transmitTaxInvoiceDialog.unknownError") + "log_id: " + state.transmitResponse.log_id;
            }
            return i18next.t("transmitTaxInvoiceDialog.unknownError");
        default:
            if (state.sale.syncStatus != PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY && !state.isManual) {
                return i18next.t("transmitTaxInvoiceDialog.saleSyncError");
            }
            return errorString;
    }
}

function buildErrorMessageFromArray(errorArray) {
    const errorMessage = errorArray.map(errorItem => {
        if (errorItem.param) {
            return i18next.t("transmitTaxInvoiceDialog.errors." + errorItem.param);
        }
        return errorItem?.message || errorItem?.msg || "";
    }).join("\n");
    if (state.transmitResponse.log_id) {
        return i18next.t("transmitTaxInvoiceDialog.transmitError") + "\n" + errorMessage + "\n" + " log_id: " + state.transmitResponse.log_id;
    }
    return i18next.t("transmitTaxInvoiceDialog.transmitError") + "\n" + errorMessage;
}

const transmitResponseMessage = computed(() => {
    if (state.transmitResponse === null) {
        return "";
    }

    if (state.transmitResponse.response_code === 406) { // 406 is a response code from the tax authority that means the user has issues with his account permissions
        return i18next.t("transmitTaxInvoiceDialog.transmitError") + "\n" + i18next.t("transmitTaxInvoiceDialog.errors." + state.transmitResponse.response_code);
    }

    if (state.transmitResponse.success) {
        return i18next.t("transmitTaxInvoiceDialog.transmitSuccess");
    }

    if (typeof state.transmitResponse.error === 'string') {
        return handleStringError(state.transmitResponse.error);
    }

    return buildErrorMessageFromArray(state.transmitResponse.error);
});

defineExpose({
    open, close
})
</script>

<template>
    <dialog ref="dialogElement" id="transmit-tax-invoice-dialog" class="p-0 w-100vw p2:w-[55vw]"
        @keydown.esc.stop.prevent>
        <div class="title w-full bg-primary-default text-center text-white py-2">
            {{ $t('transmitTaxInvoiceDialog.title') }}</div>
        <div v-if="!state.saleErrors">
            <div class="my-4 text-center mx-auto w-1/2 max-p2:w-full flex items-center flex-col gap-4">
                {{ $t('transmitTaxInvoiceDialog.content', { invoiceSequence: state.sale?.invoiceSequence }) }}
                <div class="flex items-center justify-center gap-2"
                    v-if="state.customer.type != 'hakafa' || state.allowFieldEdit || state.errors.id">
                    <label class="font-bold">{{ $t('transmitTaxInvoiceDialog.tz') }}</label>
                    <input type="number" class="h-6 border border-disabled-color flex-grow"
                        v-model.number="state.customer.id">
                </div>
                <div class="text-danger-active" v-if="state.errors.id">{{ state.errors.id }}</div>

                <div class="flex items-center justify-center gap-4"
                    v-if="state.customer.type == 'none' || state.allowFieldEdit || state.errors.name">
                    <label class="font-bold">{{ $t('transmitTaxInvoiceDialog.name') }}</label>
                    <input type="text" class="h-6 border border-disabled-color flex-grow" v-model="state.customer.name">
                </div>
                <div class="text-danger-active" v-if="state.errors.name">{{ state.errors.name }}</div>

                <div>
                    <p class="whitespace-pre-line" v-html="transmitResponseMessage"></p>
                    <button v-if="state.showConnectButton" @click="connect()" class="yellow positive-simple shadow">{{
                $t("transmitTaxInvoiceDialog.connect") }}</button>

                </div>
            </div>
            <div data-role="footer" class="my-4">
                <div class="dialog-buttons-bottom">
                    <button @click="transmit" :disabled="state.loading" class="green positive-simple shadow">
                        {{ state.loading ? $t('transmitTaxInvoiceDialog.transmitting') + '...' : $t('ok') }}
                    </button>
                    <button @click="cancel()" class="red positive-simple shadow">{{ $t("cancel") }}</button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="p-4 text-center whitespace-pre-line">
                <p v-html="state.saleErrors"></p>
            </div>
            <div data-role="footer" class="my-4">
                <div class="dialog-buttons-bottom">
                    <button @click="cancel()" class="red positive-simple shadow">{{ $t("cancel") }}</button>
                </div>
            </div>
        </div>
    </dialog>

</template>
