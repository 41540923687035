module PositiveTS {
  export module Components {
    export module SelfServicePluxee {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreen(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.selfServicePaymentMixin("selfService.pluxee", true, {type: Mixins.ICON_TYPES.POSITIVE_ICON, name: 'pluxee'})],
          methods: {
            async paymentAction() {
              try {
                await this.processPayment();
              } catch (error) {
                PositiveTS.Service.Filelog.log("An error hash occured in self service pluxee payment: ", JSON.stringify(error), false, 'error');
                console.error(error);
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('generalError'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                  this.setCurrentStep("SelfSelectPaymentMethod")
              }
            },
            async processPayment() {
              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
              let cardNumber = await this.getPaymentCardNumber(amount);
  
              if(!posUtils.isNullOrUndefinedOrEmptyString(cardNumber)){
                this.paymentInProcess = true
                let companyId = this?.getExtraData?.cibusCompanyId || jsonConfig.getVal(jsonConfig.KEYS.cibusDefaultCompanyId)
                let smartVoucherType = PositiveTS.Service.Pluxee.getSmartVoucherType();
                let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(smartVoucherType, amount,cardNumber, companyId);
                this.paymentInProcess = false
                if(response.success){
                  this.goToNextStep();
                }
                else{
                  console.error(response.error)
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: response.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                  this.setCurrentStep("SelfSelectPaymentMethod")
                }
              }else{
                  this.setCurrentStep("SelfSelectPaymentMethod")
              }
            },
          },   
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {getExtraData} = Pinia.storeToRefs(selfServicePaymentStore);
            return {getExtraData}
          },       
        }
      }
    }
  }
}