module PositiveTS {
export module Storage {
export module Entity {
export class ItemPackage extends WasmEntity {
	
	constructor() {
		let meta = {
			name: 'ItemPackage',
			fields: {
				name: "TEXT" ,
				weight: "INT",
				companyId: "INT" 
			},
			unique: []
		}
		super(meta)
	}
	
	static async getAllPackages(companyId) {
		let result = await Service.WasmDB.promiseSql(`select * from ItemPackage`);
		return result;
	}
	
}}}}
			