module PositiveTS {
    export module Service {
        export module GenericAddon {

            export async function sendNativeMessageToExtension(data, addonMethod?, splashMsg?): Promise<any> {
                let res;
                if (session.isAndroid && (<any>window).Android != undefined) {
                    let android: any = (<any>window).Android;
                    let androidVersion: number = parseFloat(android.getVersion().toString());
                    if (addonMethod === "http_service" && androidVersion < 1.62) {
                        app.hideLoadingMessage();
                        await app.promiseShowAlert({
                            header: i18next.t("error"),
                            content: i18next.t("httpService.minApkVersionError"),
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true,
                        });
                        throw new Error("Cannot perform http_service on Apk version lower than 1.62");
                    }
                    res = await AndroidAddon.sendNativeMessageToExtension(data, addonMethod, splashMsg)
                }
                else {
                    if (addonMethod === "http_service" && session.addonFilesVersion < 2.85) {
                        app.hideLoadingMessage();
                        await app.promiseShowAlert({
                            header: i18next.t("error"),
                            content: i18next.t("httpService.minAddonVersionError"),
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true,
                        });
                        throw new Error("Cannot perform http_service on lower Addon version than 2.85");
                    }
                    res = await Addon.sendNativeMessageToExtension(data, addonMethod, splashMsg);
                }

                return res;
            }
        }
    }
}
