module PositiveTS {
export module Service {


  export class ValueCardCustomerClubSelect {  



      saleHndlr:Storage.Entity.Sale
      constructor (saleHndlr:Storage.Entity.Sale){
        this.saleHndlr = saleHndlr;
      }

      isCustomerExistsOnSale():boolean{
        var saleJsondata = this._getSaleJsondata();
        return Boolean( saleJsondata[PositiveTS.Service.ValueCardService.jsonDataKey] );
      }

      getCustomerOnSale():Service.ValueCardService.CardInformationItf{
        return this._getSaleJsondata()[PositiveTS.Service.ValueCardService.jsonDataKey] ;
      }

      setCustomerOnSale(customerObj:Service.ValueCardService.CardInformationItf):Promise<any>{
        var saleJsondata = this._getSaleJsondata();
        saleJsondata[PositiveTS.Service.ValueCardService.jsonDataKey] = customerObj;
        posVC.sale.jsondata = JSON.stringify( saleJsondata );
        return Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
        .then(()=>{
          return posVC.loadSale()
          //return posVC.saleUpdated();
        });
      }

      private _getSaleJsondata(){
        var saleJsonData:any = {};
        if (this.saleHndlr.jsondata) {
          saleJsonData = JSON.parse( this.saleHndlr.jsondata );
        }
        return saleJsonData;
      }
  }
}}