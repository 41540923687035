function tableMatrix(thisElement, options) {

	// Read options from element
	var elementOptions:any = {};

	//The current row on the matrix
	this.currRow = null;

	//The current number of cells on the current row
	this.currCellCounter = 0;

	//The selected cell id that was chosen by the user
	this.selectedCellId = null;

	if (thisElement.attr('data-number-of-columns') != undefined) {
		elementOptions.numberOfColumns = thisElement.attr('data-number-of-columns');
	}

	if (thisElement.attr('data-columns-width') != undefined) {
		elementOptions.columnsWidth = thisElement.attr('data-columns-width').split(',');
	}

	if (thisElement.attr('data-number-of-visible-rows') != undefined) {
		elementOptions.numberOfVisibleRows = thisElement.attr('data-number-of-visible-rows');
	}

	if (thisElement.attr('data-header') != undefined) {
		elementOptions.headerText = thisElement.attr('data-header');
		elementOptions.hasHeader = true;
	}

	if (thisElement.attr('data-empty-message') != undefined) {
		elementOptions.emptyMessage = thisElement.attr('data-empty-message');
	}

	if (thisElement.attr('data-row-alternate-background') != undefined) {
		elementOptions.rowAlternateBackground = (thisElement.attr('data-row-alternate-background') === 'true');
	}

	var opts = $.extend(elementOptions, options);

	// Default options...
	var defaults = {
		columnsWidth: [],
		numberOfColumns: 4,
		numberOfVisibleRows: 4,
		hasHeader: false,
		headerText: '',
		emptyMessage: '',
		rowAlternateBackground: true,
		numberOfCellsOnRow : 1
	};
	var opts = $.extend(defaults, opts);

	var selectRowListeners = {};
	var selectCellListeners = {};

	// If number of columns is different from the columnsWidth array length, make the width equal
	if (opts.numberOfColumns != opts.columnsWidth.length) {
		opts.columnsWidth = [];
	}

	// Unique ID
	var id = 'ui-tablematrix-' + Math.floor(Math.random()*1000);
	this.id = id;

	// Add div which will bound the table in height
	thisElement.append('<div id="' + id + '-container" class="ui-tablematrix-container"></div>');
	var container = $(thisElement).find(' > #' + id + '-container');

	// Scroller
	var scrollerIndicatorContainer = $('<div></div>').addClass('ui-tablematrix-container-scroller-ind-container');
	var scrollerIndicator = $('<div></div>').addClass('ui-tablematrix-container-scroller-ind');
	scrollerIndicatorContainer.append(scrollerIndicator);

	var updateScrollerInd = function () {
		if ((tablematrix.height()/container.height()) == 1) {
			// If both are of the same height, there is no need to display the scroller
			scrollerIndicator.css({display: 'none'});
		} else {
			scrollerIndicator.css({display: 'block'});
		}

		// Set scroller height
		var scrollFactor = container.height() / (tablematrix.height()/container.height());
		scrollerIndicator.height(scrollFactor + 'px').css({
			'margin-top': (container.height()*(container.scrollTop()/tablematrix.height())) + 'px'
		});
	};

	var upScroll = $('<div><img src=" ' + (<any>window).images_path + 'scroll_up.png" /></div>').addClass('ui-tablematrix-container-scroller-up');
	upScroll.on('click', function () {
		if (container.hasClass('ui-tablematrix-scrolling')) {
			return;
		}
		container.addClass('ui-tablematrix-scrolling');
		container.animate({scrollTop:  container.scrollTop() - container.height()*0.9}, 200, function() {
			container.removeClass('ui-tablematrix-scrolling');
			updateScrollerInd();
		});
	});

	var downScroll = $('<div><img src=" ' + (<any>window).images_path + 'scroll_down.png" /></div>').addClass('ui-tablematrix-container-scroller-down');
	downScroll.on('click', function () {
		if (container.hasClass('ui-tablematrix-scrolling')) {
			return;
		}
		container.addClass('ui-tablematrix-scrolling');
		container.animate({scrollTop: container.scrollTop() + container.height()*0.9}, 200, function() {
			container.removeClass('ui-tablematrix-scrolling');
			updateScrollerInd();
		});
	});

	container.before(upScroll);
	container.before(scrollerIndicatorContainer);
	container.after(downScroll);

	// If we are in touch environment, add overflow-y: scroll (for swipe events...)
	if (Modernizr.touch){
		container.css({'overflow-y': 'scroll'});
		container.scroll(function () {
			updateScrollerInd();
		});
		container.on('touchmove touchend', function () {
			updateScrollerInd();
		});
	}


	// Add UL element which will contain all the li elements...
	container.append('<table id="' + id + '" class="ui-tablematrix"><tbody></tbody></table>');
	var tablematrix = $(container).find(' > #' + id + ' > tbody');

	var tablematrixHeader;
	if (opts.hasHeader) {
		// Prepend a default header
		thisElement.prepend('<div id="' + id + '-header" class="ui-tablematrix-header">' + opts.headerText + '</div>');
		tablematrixHeader = $(thisElement).find(' > #' + id + '-header');
	}

	// Append a footer
	tablematrix.append('<tr id="' + id + '-footer" class="ui-tablematrix-footer"></tr>');
	var tablematrixFooter = $(tablematrix).find(' > #' + id + '-footer');

	// Append a spacer
	tablematrix.append('<tr id="' + id + '-spacer" class="ui-tablematrix-spacer"><td colspan="' + opts.numberOfColumns + '"></td></tr>');
	var tablematrixSpacer = $(tablematrix).find(' > #' + id + '-spacer');

	// Empty message
	var emptyMessageRow = $('<tr><td colspan="' + opts.numberOfColumns + '"></td></tr>');
	emptyMessageRow.addClass('ui-tablematrix-row-empty');
	emptyMessageRow.find(' > td').html(opts.emptyMessage);
	tablematrixFooter.before(emptyMessageRow);

	// Set the columns width by adding a row with th elements
	var initializeColumns = function (options) {
		var sameWidth = false;
		if (options.columnsWidth.length == 0) {
			options.columnsWidth = 100 / options.numberOfColumns + '%';
			sameWidth = true;
		}

		var row = $('<tr></tr>');
		for (var i = 0; i < options.numberOfColumns; i++) {
			var width;
			if (sameWidth) {
				width = options.columnsWidth;
			} else {
				width = options.columnsWidth[i];
			}

			var cell = $('<th></th>');
			cell.css({width: width, height: '0px'});
			row.append(cell);
		}

		tablematrix.prepend(row);
	};
	initializeColumns(opts);

	//
	var autoResizeRelativeTo = window;
	var autoResizeComputeRelativeTo = null;
	var autoResizeTable = function () {
		// If the table is not visible, don't resize it
		if (!container.is(':visible')) {
			return;
		}

		var newTableHeightOffset = $(autoResizeRelativeTo).height() - $('#footer').height();
		if (autoResizeComputeRelativeTo != null && $(autoResizeComputeRelativeTo).length > 0) {
			newTableHeightOffset = newTableHeightOffset - $(autoResizeComputeRelativeTo).offset().top - $(autoResizeComputeRelativeTo).height();
		}
		var currentTableHeight = container.height();

		var newTableHeight = currentTableHeight  + newTableHeightOffset;
		if (newTableHeight < 200) {
		   newTableHeight = 200;
		}
		container.height(newTableHeight);

		updateScrollerInd();
	};

	this.setHeader = function (headerHtml) {
		if (opts.hasHeader) {
			tablematrixHeader.html(headerHtml);
		}
	};

	this.setFooter = function (cellValues) {
		// Remove any sub-elements of the footer row
		tablematrixFooter.empty();

		// Add the data
		for (var i = 0; i < cellValues.length; i++) {
			tablematrixFooter.append($('<td></td>').html(cellValues[i]).addClass('ui-tablematrix-row-cell'));
		}

		// Add onclick listener
		var aThis = this;
		tablematrixFooter.click(function (event) {
			aThis.selectRow($(this).attr('id'));
		});

		// Update scrollers
		updateScrollerInd();

		return tablematrixFooter;
	};

	this.removeFooter = function () {
		console.log('Removing footer');
		// Remove any sub-elements of the footer row
		tablematrixFooter.empty();
	};

	var rowIndex = 0;
	this.addRow = function () {
		return this.addRowBefore(tablematrixFooter);
	};

	this.addRowBefore = function (element) {
		// Remove the empty message if it is displayed
		if ($(tablematrix).find(' .ui-tablematrix-row-empty').length > 0) {
			$(tablematrix).find(' .ui-tablematrix-row-empty').remove();
		}

		var rowPosition = rowIndex;
		rowIndex = rowIndex + 1;
		var rowID = id + '-row-' + rowPosition;
		var row = $('<tr></tr>').attr({id: rowID}).addClass('ui-tablematrix-row');
		if (opts.rowAlternateBackground && rowPosition % 2 == 0) {
			row.addClass('ui-tablematrix-row-alt');
		}

		$(element).before(row);

		//Save the row
		this.currRow = row;
		this.currCellCounter = 0;

		// Update scrollers
		updateScrollerInd();

		return row;
	};

	this.addRowAfter = function (cellValues, selected, element) {
		// Remove the empty message if it is displayed
		if ($(tablematrix).find(' .ui-tablematrix-row-empty').length > 0) {
			$(tablematrix).find(' .ui-tablematrix-row-empty').remove();
		}

		var rowPosition = rowIndex;
		rowIndex = rowIndex + 1;
		var rowID = id + '-row-' + rowPosition;
		var row = $('<tr></tr>').attr({id: rowID}).addClass('ui-tablematrix-row');
		if (opts.rowAlternateBackground && rowPosition % 2 == 0) {
			row.addClass('ui-tablematrix-row-alt');
		}
		for (var i = 0; i < cellValues.length; i++) {
			row.append($('<td></td>').html(cellValues[i]).addClass('ui-tablematrix-row-cell'));
		}
		$(element).after(row);

		// Add onclick listener
		var aThis = this;
		row.click(function (event) {
			aThis.selectRow($(this).attr('id'));
		});

		// If the row needs to be selected, select it
		if (selected === true) {
			this.selectRow(rowID);
		}

		// Update scrollers
		updateScrollerInd();

		return row;
	};

	this.removeRow = function (rowID) {
		tablematrix.find(' > #' + rowID).remove();

		if (tablematrix.find(' > .ui-tablematrix-row').length == 0) {
			// If no rows left, display the empty message
			tablematrixFooter.before(emptyMessageRow);
		}

		// Update scrollers
		updateScrollerInd();
	};

	//Add a cell to the current row
	this.addCell = function (cellValue, selected) {
		var aThis = this;
		var cell = null;

		if (elementOptions.numberOfColumns != null){

			if (this.currRow == null || elementOptions.numberOfColumns == this.currCellCounter){
				this.addRow();
			}

			var rowId = this.currRow.attr('id');
			var cellId = rowId + '-cell-' + this.currCellCounter;
			cell = this.currRow.append($('<td></td>').attr({id:cellId}).html(cellValue).addClass('ui-tablematrix-row-cell'));
			this.currCellCounter++;

			 this.currRow.children('td').eq(this.currCellCounter-1).click(function(event) {
				aThis.selectCell($(this).attr('id'));
			 })

		}



		return cellId;
	};

	//Select a cell
	this.selectCell = function (cellID, allowMultiRowSelection) {

		if (arguments.length == 0) {
			// Select the first cell
			cellID = tablematrix.find(' > .ui-tablematrix-row-cell').first().attr('id');
		}

		if (allowMultiRowSelection != true) {
			tablematrix.find(' > .ui-tablematrix-row').each(function(){
				$(this).children("td").each(function(){
					$(this).removeClass('selected');
				})
			})
		}

		$('#' + cellID).addClass('selected');
		this.selectedCellId = cellID;

		var theCell = $('#' + cellID);
		$.each(selectCellListeners, function (id, listener) {
			listener.call(theCell, theCell);
		});
	};

	//Return the the selected cell id
	this.getSelectedCell = function () {
		return this.selectedCellId;
	};

	this.empty = function () {
		// Remove all the rows
		tablematrix.find(' > .ui-tablematrix-row').remove();

		// Display the empty message
		this.removeFooter();
		tablematrixFooter.before(emptyMessageRow);

		this.currRow = null;

		// Update scrollers
		updateScrollerInd();
	};

	this.setNumberOfVisibleRows = function (numberOfRows) {
		container.css({height: numberOfRows * 2.5 + 'em'});

		// Update scrollers
		updateScrollerInd();
	};

	this.scrollToRow = function (rowID, onlyIfRowIsHidden) {
		var rowPositionTop = $('#' + rowID).position().top;
		if (onlyIfRowIsHidden) {
			var containerScrollTop = container.scrollTop();
			if (rowPositionTop >= containerScrollTop && rowPositionTop + $('#' + rowID).height() <= containerScrollTop + container.height()) {
				return;
			}
		}
		container.animate({scrollTop: rowPositionTop}, 700, function() {
			updateScrollerInd();
		});
	};

	this.scrollBottom = function () {
		container.scrollTop(99999999);
		updateScrollerInd();
	};

	this.selectRow = function (rowID, allowMultiRowSelection) {
		if (arguments.length === 0) {
			// Select the last row
			rowID = tablematrix.find(' > .ui-tablematrix-row').last().attr('id');
		}

		if (allowMultiRowSelection != true) {
			tablematrix.find(' > .ui-tablematrix-row').removeClass('selected');
			tablematrixFooter.removeClass('selected');
		}
		$('#' + rowID).addClass('selected');

		var theRow = $('#' + rowID);
		$.each(selectRowListeners, function (id, listener) {
			listener.call(theRow, theRow);
		});
	};

	this.getSelectedRow = function () {
		return tablematrix.find(' > .selected');
	};

	this.attachSelectRowListener = function (id, listener) {
		selectRowListeners[id] = listener;
	};

	this.removeSelectRowListener = function (id) {
		delete selectRowListeners[id];
	};

	this.setAutoResizeOn = function (computeRelativeTo, relativeTo) {
		// Set auto resize off, to prevent duplicate listeners
		this.setAutoResizeOff();

		// Set the element to resize relative to it
		if (relativeTo == null || $(relativeTo).length == 0) {
			relativeTo = window;
		}
		autoResizeRelativeTo = relativeTo;

		// Set the element to compute the resize relative to it (can be null!)
		autoResizeComputeRelativeTo = computeRelativeTo;

		// Set the tablematrix's resize listener
		$(window).on('resize', autoResizeTable);

		// Call the resize function to force the first resize
		// $(window).trigger('resize');
		autoResizeTable();
	};

	this.setAutoResizeOff = function () {
		// Set the element to resize relative to it to null
		autoResizeRelativeTo = window;

		// Set the element to compute the resize relative to null
		autoResizeComputeRelativeTo = null;

		// Unbind the tablematrix's resize listener
		$(window).unbind('resize', autoResizeTable);
	};

	// By default, show only some of the rows
	this.setNumberOfVisibleRows(opts.numberOfVisibleRows);

	// Update scrollers
	updateScrollerInd();

	// Register the instance
	PositiveTS.UI.Base.registerInstance(id, this);

	return this;
};

// Add it to jQuery prototype
jQuery.fn.tablematrix = function (options) {
	if (this.find(' > .ui-tablematrix-container').length > 0) {
		return PositiveTS.UI.Base.getInstance(this.find(' > .ui-tablematrix-container > .ui-tablematrix').attr('id'));
	}

	return (new (tableMatrix as any)(this, options));
};