
module PositiveTS {
export module Application {
export module Controllers {
export class EmployeeHoursViewController extends AbstractViewControllerTS {
    // --------------------------------------------------------------------
    // Controller Life-Cycle
    // --------------------------------------------------------------------
    init(options?) {
    }
    resume(options?) {
        //PositiveTS.VueInstance.$refs.employeeHours.open()
    }
    stop() {
    }

    destroy() {
    }
    
    
}}}}

//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.EmployeeHoursViewController = new PositiveTS.Application.Controllers.EmployeeHoursViewController();
    