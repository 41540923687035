module PositiveTS {
  export module Service {
  export module VoucherRemoveLimitedRows {
  
    export async function promiseRemoveVouchers(){
      let discountVoucherTypes = await  getDiscountVouchers();
      if(paymentHasVouchersOfType(discountVoucherTypes)) {
        await app.promiseShowAlert({
          header: i18next.t('warn'),
          content: i18next.t('voucherAutoDeleteLimitPayments'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        })
        await removeVoucherTypesFromPayment( discountVoucherTypes );
      }
    };
  
    function paymentHasVouchersOfType(voucherTypeIdsWithLimit:any[]) {
      var voucherPayments = PositiveTS.Service.VoucherPayment.getVoucherPayments();
      let paymentsWithVouchersOfType = voucherPayments.filter(payment => {
        let paymentVouchers = JSON.parse(payment.data);
        if(Array.isArray(paymentVouchers)) {
          let matchingVouchers = paymentVouchers.filter(voucher => voucherTypeIdsWithLimit.includes(voucher.voucher_type_id));
          return matchingVouchers.length > 0
        }
        return false;
      });
      return paymentsWithVouchersOfType.length > 0;
    }
  
    async function removeVoucherTypesFromPayment( voucherTypeIdsWithLimit:any[] ) {
      var voucherPayments = PositiveTS.Service.VoucherPayment.getVoucherPayments();
      for(let y = 0; y < voucherPayments.length; y++) {
        let payment = voucherPayments[y];
        let paymentVouchers = JSON.parse(payment.data);
        for(let i = 0; i < paymentVouchers.length; i++) {
          if(voucherTypeIdsWithLimit.includes(paymentVouchers[i].voucher_type_id)) {
            await Service.VoucherPayment.deleteVoucherPaymentAndPersist(payment, paymentVouchers[i]);
          }
        }
      }      
    };
  
    async function getDiscountVouchers(){
      var voucher = new PositiveTS.Storage.Entity.Voucher();
      let allVouchers = (await voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID));
      return allVouchers.filter((voucher) => !posUtils.isNullOrUndefinedOrEmptyString( voucher.maxPercent ) ||
                                                                        !posUtils.isNullOrUndefinedOrEmptyString( voucher.minimumAmount) ||
                                                                        voucher.typeID == CustomerClubConstants.VOUCHER_TYPE_ID ||
                                                                        voucher.typeID == SimplyClubService.VOUCHER_TYPE_ID)
                                           .map(voucher => voucher.typeID);
    };
}}}
  
  
  