module PositiveTS {
export module Components {
    export module CurrencyConversionCalculator {
      
      const dialogSelector = 'currency-conversion-calculator'

      function cleanData() {
        this.$data = initData();
      }

      function initData () {
        let data :{
          amount: number,
          currencies: Array<object>,
          posBaseCurrency: string,
          dialogElem: any,
          closeWithoutBindKeyPress: boolean,
          convertFromCurrency: string
        } = {
          amount: 1,
          currencies: [],
          posBaseCurrency: Service.MultiCurr.getInstance().getPosCurrency(),
          dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
          closeWithoutBindKeyPress: false,
          convertFromCurrency: null,
        }
        return data
      }

      function close () {
        this.dialogElem.close()
        if (!this.closeWithoutBindKeyPress){
          $(document).unbind('keypress')
          $(document).keypress(posVC.onKeyPress)
        }   
      }

      function open (closeWithoutBindKeyPress: boolean = false) {
        
        this.closeWithoutBindKeyPress = closeWithoutBindKeyPress

        this.setCurrencies()

        if (this.dialogElem == null) {
          this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
        }

        this.dialogElem.showModal()

        this.$nextTick(() => {
          $(document).unbind('keypress')
        })      
      }

      function calculate () {

        let amount = parseFloat(this.amount)
        if (!amount){amount = 1}

        this.currencies.forEach(curr => {
          curr.result = (amount * curr.rate).toFixed(session.pos.hasFlights ? 4 : 2) 
        })

      }

      function setCurrencies () {
        
        this.currencies = []
  
        PositiveTS.Service.MultiCurr.getInstance().getCurrenciesEnabled().forEach((code) => {
          
          let rate: any = posPaymentVC.Currencies[code]

          if (this.posBaseCurrency != code){
            rate = parseFloat(rate).toFixed(session.pos.hasFlights ? 4 : 2)
            this.currencies.push({
                code,
                rate,
                result: rate
            })
          }

        })

      }

      function getCurrencyName (code: string): string {
        return PositiveTS.Service.MultiCurr.getInstance().getCurrencyName(code)
      }

      export function create() {

        let currencyConversionCalculator = {
            template: JST.currencyConversionCalculator(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            methods: {
              cleanData,
              open,
              close,
              calculate,
              setCurrencies,
              getCurrencyName,
              hasFlights() {
                return session.pos.hasFlights;
              },
              getCurrenciesForSelect() {
                return this.currencies.concat([this.baseCurrencyObject]).map(c => {
                  c.name = this.getCurrencyName(c.code);
                  return c;
                });
              },
              translateAmount(amount, currency) {
                return Service.MultiCurr.getInstance().translateAmount(amount, currency);
              },
              conversionAmount(amount, currency) {
                return Service.MultiCurr.getInstance().conversionAmount(amount, currency);
              },
              fixedFloat(number, persicion = 2) {
                return session.fixedFloat(number, persicion).toFixed(persicion);
              }
            },
            data: initData,
            computed: {
              baseCurrencyObject() {
                return {
                  code: this.posBaseCurrency,
                  rate: 1,
                  result: 1,
                }
              },
              fromCurrency: {
                get() {
                    return this.convertFromCurrency || this.baseCurrencyObject;
                  
                },
                set(val) {
                  this.convertFromCurrency = val;
                }
              }
            }

        }

        VueApp.component('currency-conversion-calculator',currencyConversionCalculator)

      }
    }
}
}
