module PositiveTS {
export module Service {
export module BadTranLog {

  const daysBackDefault = 50;
  const url = '/bad_tran_log';
  const addOnServiceName = "bad_tran_log";

  export function uploadBadTranLog(daysHistory = daysBackDefault){
    return _getDataFromAddon(daysHistory)
    .then( dataResult =>{
        return _postToServerDataResult(dataResult)
        .then(response=>{
          if (!response) {throw new Error("could not upload files check network tab")}
        });
    })



  }

  function _postToServerDataResult( dataResult ){
    return FetchReq.jsonReq(url,'post', {
            data: dataResult,
            "pos_device_id": session.pos.deviceID
          }).then((response) => {
            if (!response.response.ok) { return false}
           if (!response.result.success) {
             return false
           }
           return true;
          });
  }

  function _getDataFromAddon(daysHistory){
    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
      {data: daysHistory},
      addOnServiceName
    ).then(function(x){
      if (x.request.result.startsWith("ERROR:")){
        throw new Error(x.request.result);
      }
      return x.request.result;
    });
  }

}}}
