module PositiveTS {
    
    export module Components {
        
        export module checkPosProprietyDialog {

            const dialogSelector = 'check-pos-propriety-dialog'

            export function create() {
  
                let component = {
                  template: JST.checkPosProprietyDialog(),
                  methods: {
                    open () {

                        if (this.dialogElem == null) {
                            this.setDialogElement()               
                        }
        
                        this.dialogElem.showModal()
        
                        this.$nextTick(() => {
                            $(document).unbind('keypress')
                            document.addEventListener('keydown', this.disabledEscape, true)
                        })
                    },

                    close () {
                        $(document).unbind('keypress')
                        document.removeEventListener('keydown', this.disabledEscape, true)
                        $(document).keypress(posVC.onKeyPress)
                        
                        if (this.dialogElem == null) {
                            this.setDialogElement()               
                        }
                        
                        this.dialogElem.close()   
                    },

                    disabledEscape (e) {
                        if (e.key == 'Escape') {
                            e.preventDefault()
                        }
                    }, 

                    async confirm () {
                        try {
                            const statistics = statsVC.statistics()
                            if (statistics.state != storage.StatusOK){
                                app.showAlertDialog({
                                    content: i18next.t('checkPosProprietyDialog.updateDataMessage'),
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                }, () => {
                                    pNavigator.pushPage('stats', i18next.t('pageTitle.sync'), '/homepage', null)
                                })
                                this.close()
                                return
                            }
                            
                            app.showLoadingMessageDialog(i18next.t('checkPosProprietyDialog.loadingMessage'))
                            let res = await Service.CheckPosPropriety.check()
                            console.debug('Check pos propriety result:', res)


                            if(res.success) {
                                let salesCheck = await Service.CheckPosPropriety.syncLocalSalesWithServer()
                                console.debug('Check pos - compare local sales with server sales result:', salesCheck)
                                this.failedMode = !salesCheck.success
                            } else {
                                this.failedMode = true;
                            }

                            if(this.failedMode) {
                                Service.CheckPosPropriety.setStatus(Service.CheckPosPropriety.STATUSES.FAILED)
                            } else {
                                Service.CheckPosPropriety.setStatus(Service.CheckPosPropriety.STATUSES.PASSED)
                                app.showAlertDialog({
                                    content: i18next.t('checkPosProprietyDialog.passedMessage'),
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                }, null, null)
                                this.close()
                            }

                            app.hideLoadingMessageDialog()
                        }catch (e){
                            console.error(e)
                            app.showAlertDialog({
                                header: i18next.t('error'),
                                content: i18next.t('checkPosProprietyDialog.generalError'),
                                continueButtonText: i18next.t("ok"),
                                hideCancelButton: true
                            }, null, null)
                            app.hideLoadingMessageDialog()
                        }
                    },

                    setDialogElement () {
                        this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                    },

                    openLogout () {
Pinia.componentsStore.openComponent( {componentName:"logoutDialog", args: []})
                        this.close()
                        $(document).unbind('keypress')
                    },
                  },
                  data () {
                    const data: {
                        dialogElem: any,
                        failedMode: boolean
                    } = {
                        dialogElem: null,           
                        failedMode: false
                    }
                    return data
                  },  
                  mounted () {
                      this.setDialogElement()
                      this.failedMode = Service.CheckPosPropriety.isCheckPosProprietyFailed()
                  }
                }

                VueApp.component('check-pos-propriety-dialog',component)
            }

        }

    }

}
