module PositiveTS {
    export module Service {
    
    export module Pickup{

        export interface pickup {
            ReturnDt: string,
            flight: string,
            id: string,
            phone: string,
            DuetyFreeBarcode: string,
            packaging: number,
            notes: string
        }

        export function reminderPickupDialog (): Promise<string> {
            
            return app.showAlertDialog({
                header: i18next.t('reminderPickup.title'),
                content: i18next.t('reminderPickup.content'),
                hideCancelButton: false,
                continueButtonText: i18next.t('yes'),
                cancelButtonText: i18next.t('no')
            })
        }

        export function getPickupPackagingNumberFromJsonData (jsondata): number {
            let packagingNumber = 1 
            if (jsondata && jsondata.pickup && jsondata.pickup.packaging && jsondata.pickup.packaging > 0){
                packagingNumber = Number(jsondata.pickup.packaging)
            }

            return packagingNumber
        }

        export function resetPickupAfterCleanSale () {
            PositiveTS.VueInstance.$refs.pickupFormDialog.resetData()
            PositiveTS.VueInstance.$refs.pos.isTogglePickupAll = false
        }

        export function setPickupDataOnSale (pickup: pickup): void {

            let jsondata = JSON.parse(posVC.sale.jsondata)

            jsondata.pickup = pickup

            posVC.sale.jsondata = JSON.stringify(jsondata)

            Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments)
            
        }
        
        export function getPickupOnSale (): object {
            const jsondata = JSON.parse(posVC.sale.jsondata)
            if (!_.isEmpty(jsondata.pickup)){
                return jsondata.pickup
            }
            return null
        }

        export function getPickupObject (jsondata: any, seq: number): void {

            jsondata.pickup.DuetyFreeBarcode = getDuetyFreeBarcode(seq)
            jsondata.pickup.DuetyFreeBarcode2 = getNewDutyFreeBarcode(seq)

            return jsondata.pickup

        }

        export function saleHasObjectPickup (): boolean {
            const jsondata = JSON.parse(posVC.sale.jsondata)
            return !_.isEmpty(jsondata.pickup)
        }

        export function isReturnDateValid (date: string): validateObject {

            let valid = {valid: true, message: ''}

            if (date.length != 8){
                valid.valid = false
                valid.message = i18next.t('pickupFormDialog.returnDateIsNotValidFormat')
            }
    
            let _d1 = new Date();
            let _d2 = posUtils.parseIlDate(date);
            _d1.setHours(0,0,0,0);
            _d2.setHours(0,0,0,0);
            var _dd = new Service.DateDiff(_d1, _d2);
            if (_dd.diffDays < 0) {
                valid.valid = false
                valid.message = i18next.t('pickupFormDialog.returnDateIsSmallerFromToday')
            }

            if (_dd.diffDays > 365){
                valid.valid = false
                valid.message = i18next.t('pickupFormDialog.returnDateIsBiggerMoreThanYaer') 
            }
    
            var _d2_dict = posUtils.getIlDateAsDict(date)
            if (!posUtils.isDateValid(_d2_dict.year, _d2_dict.month, _d2_dict.day)) {
                valid.valid = false
                valid.message = i18next.t('pickupFormDialog.returnDateIsNotValid')
            }

            return valid
        }

        export function hasItemsPickupOnSale (saleItems:Array<Storage.Entity.SaleItem>): boolean {
            return _.some(saleItems, {isPickup: true})
        }

        export async function goToPaymentScreen() {

            const isOpeningPaymentScreen = {isOpeningPaymentScreen: false}
            try {
                if (Service.Pickup.hasItemsPickupOnSale(posVC.saleItems)) {
                    if (Service.DutyFree.isPickupEnabled()) {
                        try {
                            await Pinia.componentsStore.openComponent( {componentName:"pickupFormDialog", args: []});
                        } catch(err) {
                            return isOpeningPaymentScreen; 
                        }


                        const moreFlightCardRequired = PositiveTS.Service.Pickup.checkIfMoreFlightCardRequired()
                        if (moreFlightCardRequired > 0){
                            try {
                                await Pinia.componentsStore.openComponent( {componentName:"flightCardDialog", args: [moreFlightCardRequired]});
                            } catch(err) {
                                return isOpeningPaymentScreen; 
                            }
                        }
                    } else {
                        let result = await Pinia.componentsStore.openComponent( {componentName:"pickupShipsFormDialog", args: []});

                        if (result == 'cancel') {
                            return isOpeningPaymentScreen;
                        }
                    }

                    return await posVC.openPaymentScreen();
                }
            
                let res: string;
                if (Service.DutyFree.isPickupEnabled()) {
                    res = await Service.Pickup.reminderPickupDialog()
                } else {
                    res = 'cancel';
                }

                if (res == 'cancel'){
                    return await posVC.openPaymentScreen();
                } else{
                    return isOpeningPaymentScreen;
                }
            } catch (err) {
                Service.Logger.error(err);
                return isOpeningPaymentScreen;
            }
        }

        export function isPickupEnabled() {
            return Service.DutyFree.isPickupEnabled() || jsonConfig.getVal(jsonConfig.KEYS.isPickupEnabledShips);
        }

        //return the number of flight cards that still required to fill  
        export function checkIfMoreFlightCardRequired () {
            const numberOfFilghtCardOnSale = PositiveTS.Service.DutyFree.getNumberFlightsOnSale()
            const numberOfFlightCardRequired = checkNumberOfFlightCardRequired()
            return numberOfFlightCardRequired - numberOfFilghtCardOnSale
        }

        export function checkNumberOfFlightCardRequired (): number {
            let totalPickupAmount = _totalPickupAmount()
            return Math.ceil(totalPickupAmount / maxAmountPickupForFlightcard())
        }

        function maxAmountPickupForFlightcard () {
            return  jsonConfig.getVal(jsonConfig.KEYS.maxAmountPickupForFlightcard)
        }

        function _totalPickupAmount(){
            let total = 0;
            posVC.saleItems.forEach((i) => {
                if (i.isPickup){
                    total += _getItemRowAmnt(i)
                }
            })
            
            return total;
          }

          function _getItemRowAmnt(saleItem:Storage.Entity.SaleItem) {
            var results = PositiveTS.Application.Module.CreditInvoiceService.calculateTotalsWithAndWithoutPromotionsAndDiscounts(posVC.sale, posVC.saleItems);
        
            if (saleItem.quantity == 0) {
              return 0;
            }
            if (salePromotions.saleHasNewPromotions(posVC.sale) &&
              salePromotions.itemHasPromotions(saleItem, posVC.sale, posVC.saleItems)) {
              var price = salePromotions.calcuateItemPriceAfterPromotions(saleItem, posVC.sale, posVC.saleItems);
        
              if (PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(posVC.sale)) {
                if (Boolean(posVC.sale.saleDiscountAllowedWithOtherPromotions) && (_saleItemIncludedInSalePromotion(posVC.sale, saleItem, posVC.saleItems))) {
                  price = price * (1 - posVC.sale.saleDiscountAmount/results.totalAmountOfItemsIncludedInSaleDiscount);
                }
              }
              return price*saleItem.quantity;
            }
            else {
        
              var price = saleItem.unitPrice;
        
              var saleItemCopy = new PositiveTS.Storage.Entity.SaleItem();
              saleItemCopy.importFromObject(saleItem.exportToObject());
        
              var discountAmount = saleItemHelper.getSaleItemDiscountAmount(saleItemCopy, 1);
              var allowWithOtherPromotions = Boolean(posVC.sale.saleDiscountAllowedWithOtherPromotions);
        
              price -= discountAmount;
        
              if (PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(posVC.sale) && _saleItemIncludedInSalePromotion(posVC.sale, saleItem,posVC.saleItems)) {
                if (allowWithOtherPromotions || discountAmount === 0) {
                  price = price * (1 - posVC.sale.saleDiscountAmount/results.totalAmountOfItemsIncludedInSaleDiscount);
                }
              }
              else if (PositiveTS.Helper.SaleHelper.doesSaleHasDiscount(posVC.sale) && discountAmount == 0) {
                price -= price * Number(posVC.sale.discountPercent) / 100;
              }
        
              return price * saleItem.quantity;
            }
          }

        function _saleItemIncludedInSalePromotion(sale, saleItem, saleItems) {
            var jd = JSON.parse(sale.jsondata);
            var allowWithOtherPromotions = Boolean(sale.saleDiscountAllowedWithOtherPromotions);
        
            if ((salePromotions.itemHasPromotions(saleItem, sale, saleItems) || saleItemHelper.doesItemContainDiscountOrPromotion(saleItem))
            && !allowWithOtherPromotions) {
                return false;
            }
        
            if (jd.salePromotion) {
                return (jd.salePromotion.barcodesInPromotion.indexOf(saleItem.barcode) > -1);
            }
            else {
                return true;
            }
        }

        function getDuetyFreeBarcode (seq: number): string {
            
		    let invoice_seq = seq.toString();
            let d2 = new Date();
            d2.setHours(0,0,0);
            let d1 = new Date(d2.getFullYear(), 0, 1);
            let dc = new Service.DateDiff(d1,d2);
            
            
            const barcode = "7" +
            Service.StrUtils.lpad(dc.diffDays + 1,3) +
            Service.StrUtils.right(session.store.storeID,1) + 
            Service.StrUtils.right( session.pos.posNumber.toString(), 1) +
            Service.StrUtils.right(invoice_seq,3)
        
            return barcode
        
        }

        function getNewDutyFreeBarcode (seq: number): string {    
		    let invoice_seq = (1000000000 + seq).toString();
            let d = new Date();
            const barcode = `01${d.getFullYear()}${invoice_seq}`
        
            return barcode        
        }
        
        interface validateObject{
            valid: boolean, 
            message: string
        }
    
        }
    
    }
}    