module PositiveTS { 
  export module Printing {
  export module HtmlInvoice{

        export class ObjHtmlInvoiceHeader {
          private _sale:any;
          private _company:any;
          private _posNumber:any;

          dedicatedTo: string;
          storeName: string;
          companyNameArray: string[];
          storeAddress: string;
          storePhone: string;
          storeRegistrationNum: string;
          storeFreeText: string[];
          createdAt: string;
          posNumber: string;
          cashierEmployeeName: string;
          customerName: string;
          companyWebSite: string;
          imgSrc: string;
          doNotPrintAmounts: boolean;
          printVatItemLinesOnA4Invoice: boolean;
          printDiscountAsColoumnOnA4Invoices: boolean;

          constructor(sale, company, posNumber, imgSrc){
            this._sale = sale;
            this._company = company;
            this._posNumber = posNumber;
            this.dedicatedTo = this._getDedicatedTo();
            this.storeName = this._getStoreName();
            this.companyNameArray = this._getCompanyNameArray();
            this.storeAddress = this._getSaleProperty("storeAddress", "");
            this.storePhone = this._getSaleProperty("storePhone", "");
            this.storeRegistrationNum = this._getSaleProperty("storeRegistrationNum", "");
            this.storeFreeText = this._getSaleProperty("storeFreeText", []);
            this.createdAt = moment(sale.createdAt).format("DD/MM/YY");
            this.posNumber = this._posNumber;
            this.cashierEmployeeName = sale.cashierEmployeeName;
            this.customerName = this._getCustomerName();
            this.companyWebSite = this._getCompanyWebSite();
            this.imgSrc = imgSrc;
            this.doNotPrintAmounts = (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV &&
              jsonConfig.getVal(jsonConfig.KEYS.hakafaShipmentInvPrintWithNoPrice) );
            this.printVatItemLinesOnA4Invoice = jsonConfig.getVal(jsonConfig.KEYS.printVatItemLinesOnA4Invoice)
            this.printDiscountAsColoumnOnA4Invoices = jsonConfig.getVal(jsonConfig.KEYS.printDiscountAsColoumnOnA4Invoices)
          }


          private _getCompanyWebSite(): string {
            var company = this._company;
            if (!posUtils.isNullOrUndefinedOrEmptyString(company) && !posUtils.isNullOrUndefinedOrEmptyString(company.webSite)) {
              return company.webSite;
            } else {
              return "";
            }
          }

          private _getCustomerName(): string {
            var _return = this._getSaleProperty("customerName", "");
            return _return.replace(/[^A-Za-zא-ת0-9_-\s]+/g, ``);
          }

          private _getSaleProperty(propertyName: string, defaultVal: any): any {
            if (!posUtils.isNullOrUndefinedOrEmptyString(this._sale[propertyName])) {
              return this._sale[propertyName];
            } else {
              return defaultVal;
            }
          }

          private _getCompanyNameArray(): string[] {
            return this._company.companyName.split("$$$");
          }

          private _getDedicatedTo(): string {
            if (!this._sale.jsondata) { return ""; }
            var jsonData = JSON.parse(this._sale.jsondata);

            if (!jsonData.dedicatedTo) { return ""; }
            return jsonData.dedicatedTo;
          }

          private _getStoreName(): string {
            var sale = this._sale;
            var storeName = session.pos.storeName;
            if (sale.storeName !== null && sale.storeName !== undefined && sale.storeName !== "undefined" && sale.storeName != "null" && sale.storeName != "") {
              storeName = sale.storeName;
            }
            return storeName;
          }

        }

  }}}
