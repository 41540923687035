module PositiveTS {
  export module Service {
    export module EmployeeTimeTrack {

      export async function getTimeTracksForRoshemetLogin() {
        if (session.pos.isRoshemet) {
          let response = await FetchReq.jsonReq(`${Shared.Constants.remoteRoot}get_time_tracks_for_roshemet_login`,'GET');
          let timeTracks = response.result.map(serverEtt => {
            let ett = new PositiveTS.Storage.Entity.EmployeeTimeTrack()
            ett.serverID = serverEtt.id;
            ett.timestamp = (new Date(serverEtt.time_track_at)).getTime();
            ett.employeeID = String(serverEtt.employee_id);
            ett.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY;
            ett.mode = serverEtt.mode;
            ett.serverEmployeeID = serverEtt.employee_id;
            return ett;
          })
          await appDB.employeeTimeTracks.bulkPut(timeTracks);
        }
      }

      export class EmployeeTimeTrackEntry {
        constructor() { throw new Error("Static class"); }

        static async fingerprintEntry(mode): Promise<any> {
          let employeeId = await Service.Fingerprint.verify()
          let magneticCard = PositiveTS.Service.WasmDB.execAsObject(`SELECT employee.magneticCard FROM employee WHERE employee.employeeID = ${employeeId}`)
          if(!posUtils.isBlank(magneticCard) && magneticCard[0].magneticCard){
            magneticCard = magneticCard[0].magneticCard
          }
          let result = await EmployeeTimeTrackEntry.employeeTimeTrackHandler({
              mode:mode,
              magnetic_card: magneticCard,
              employee_id: employeeId,
              record_timestamp: PositiveTS.DateUtils.timestamp(),
              verified_employee_by: 'fingerprint'
          })
          return result;
        }

        static async magneticCardEntry(mode, employeeMagneticCard): Promise<any> {
          let msg = ""
          if (Pinia.globalStore.isRoshemet) {
            let employeeObj = await appDB.employees.where({magneticCard:employeeMagneticCard}).last()
            if (!employeeObj) {throw new Error(i18next.t("ett.employeeNotDefined")); }
            if (employeeObj && employeeObj.isLocked) {throw new Error(i18next.t("ett.employeeNotDefinedOrInactive")); }
            if (employeeObj.syncStatus != Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY){throw new Error(i18next.t("ett.employeeNotSynced"));}
            

            let ett = new PositiveTS.Storage.Entity.EmployeeTimeTrack()
            ett.employeeID = employeeObj.employeeID;
            ett.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
            ett.timestamp = PositiveTS.DateUtils.timestamp();
            ett.mode = mode;
            if (employeeObj.serverID && employeeObj.serverID > 0) {
              ett.serverEmployeeID = employeeObj.serverID;
            }
            let lastEmployeePunchedAry = await appDB.employeeTimeTracks.where({employeeID:employeeObj.employeeID})
                            .reverse().sortBy('timestamp');
            let lastEmployeePunched = lastEmployeePunchedAry[0]
            if (lastEmployeePunched && lastEmployeePunched.mode === mode){
              if ( moment(new Date()).diff(moment( new Date( lastEmployeePunched.timestamp) ), 'hours') <= 20 ){
                if (mode === PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT){
                  throw new Error(i18next.t("ett.exitWithoutEntry")); 
                 } else {
                   throw new Error(i18next.t("ett.entryWithoutExitInvalid", {dateTime:
                         moment( new Date( lastEmployeePunched.timestamp ) ).format("DD/MM/YYYY hh:mm")}));
                 }
              } else {
                msg = i18next.t("ett.missigPreviousEntryNotification", 
                  {dateTime:  moment( new Date(lastEmployeePunched.timestamp) ).format("YYYY-MM-DD hh:mm"), name: employeeObj.name})
              }

            } 

            let lastEmployeePunchedTime;
            if (lastEmployeePunched){
              lastEmployeePunchedTime = lastEmployeePunched.timestamp;
            }
              await appDB.employeeTimeTracks.put(ett)
              return {success: true, employee: employeeObj, mode:mode, timeTrack: ett, 
                msg: msg, lastTimeTrack: lastEmployeePunchedTime}
            

            // get last employee time track entry for current user
            // if the same and from last 10 hours reject
            // else insert
          } else {
            let isEnrolled = await this._isUserHasFingerprint(employeeMagneticCard)
            if (isEnrolled) {throw new Error(i18next.t("TIMETRACK_USER_MUST_USE_FINGERPRINT")); }

            return await EmployeeTimeTrackEntry.employeeTimeTrackHandler(
              {
                mode:mode,
                magnetic_card: employeeMagneticCard,
                employee_id: null,
                record_timestamp: PositiveTS.DateUtils.timestamp(),
                verified_employee_by: 'magnetic_card'
              }
            )
          }
        }

        static async openEmployeeInStore() {
          app.showLoadingMessageDialog(i18next.t("loading"))
          if(session.pos.isRoshemet){
            let employeesInShift = [];
            let timetracks = await appDB.employeeTimeTracks.orderBy('timestamp').reverse().toArray()
            let emps = await appDB.employees.toArray()
            for(let emp of emps){
              let ett = timetracks.filter((emptt) => {return emptt.employeeID == emp.employeeID});
              if((ett[0]) && (ett[0].mode == Storage.Entity.EmployeeTimeTrack.MODE_ENTER)){

                var now = moment(new Date()); //todays date
                var begin = moment(ett[0].timestamp); // another date
                var duration = moment.duration(now.diff(begin));

                let leadHZero = "";
                let leadMZero = "";
                if(duration.hours() < 10){
                  leadHZero = "0";
                }

                if(duration.minutes() < 10){
                  leadMZero = "0";
                }

                let durStr =leadHZero + duration.hours() + ":" + leadMZero + duration.minutes();
                employeesInShift.push({
                  name: emp.name,
                  time_track_at: moment(ett[0].timestamp).format("HH:mm"),
                  hours:  durStr
                })
              }
            }
            app.hideLoadingMessageDialog();
Pinia.componentsStore.openComponent( {componentName:"employeeInStore", args: [employeesInShift]});
          }
          else{
            return PositiveTS.Service.FetchReq.jsonReq('/get_report_active_employee_for_store', 'get')
            .then( (response) => {
              app.hideLoadingMessageDialog()
              if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
Pinia.componentsStore.openComponent( {componentName:"employeeInStore", args: [ response.result ]})

            })
            .catch((err) => {
              app.hideLoadingMessageDialog()
              if (err) {
                console.error(err);
              }
              throw("Unexpected error employee-time-track-list-on-shift ");
            });
          }
        }
        private static _postAddEmployeeTimeTrack(postObject){
          if (!PositiveTS.Reachability.isOnline) {
              return Promise.reject(new Error(i18next.t("ett.cannotPerformTimeTrackWhileOffline")))
          }


         app.showLoadingMessageDialog(i18next.t("ett.performingEntryExit"));
          return FetchReq.jsonReq('/pos_entry','post', postObject)
          .catch(e=>{
           app.hideLoadingMessageDialog();
            throw e
          })
          .then((response) => {
           app.hideLoadingMessageDialog();
            if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
           if (!response.result.success) {
             throw new Error(response.result.msg)
           }
           return response.result;
          });
        }

        private static _isUserHasFingerprint(pCardNumber):Promise<boolean>{
          var employeeEntity = new PositiveTS.Storage.Entity.Employee();
          //searchByMagneticCardOrEmployeeNumberAndNotInStore expects string only
          var cardNumber = pCardNumber.toString()

          return employeeEntity.searchByMagneticCardAndNotInStore(cardNumber, session.pos.storeID)
          .then( (result) => {
                if (result.length > 0){
                return result[0].employeeID;
              }
              return false;
          })
          .then(empId =>{
            return  Boolean(empId && PositiveTS.Service.Fingerprint.isEnrolled(empId));
          });

        }

        private static async validateEmployeeBreakTime (postObject): Promise<{possibleBreak:boolean, success:boolean, msg: string}> {
          app.showLoadingMessageDialog(i18next.t("ett.performingEntryExit"))
          let returnObj:{success:boolean, possibleBreak:boolean, msg:string };
          let lastTimeTrack = await FetchReq.jsonReq(`/employee_time_tracks/validate_break_time/${postObject.magnetic_card}`,'get')

          if (lastTimeTrack.response.ok) { 
            let possibleBreak = lastTimeTrack.result.time_between_entries < 2;
            let msg = possibleBreak ? "" : lastTimeTrack.result.msg;
            returnObj = { success: true, possibleBreak: possibleBreak, msg: msg }
          }
          else {
            returnObj = {success: false, possibleBreak: false, msg: lastTimeTrack.result.msg}
          }

          app.hideLoadingMessageDialog();
          return returnObj;
        }

        private static async postEmployeeBreakTime(postObject): Promise<any> {
          let employeeEntity = new PositiveTS.Storage.Entity.Employee()
          let employee = await employeeEntity.fetchByStoreAndMagneticCard(session.pos.storeID, postObject.magnetic_card)

          app.showLoadingMessageDialog(i18next.t("ett.performingEntryExit"));
          let addBreakTime =  await FetchReq.jsonReq(`/employee_time_tracks/${employee[0].employeeID}/add_break_time_to_employee`,'post', postObject)
          let returnObj:any;
          if (addBreakTime.result.success) {
            returnObj =  addBreakTime.result;
          } else {
            returnObj = {success: false, msg: addBreakTime.result.msg}
          }
          app.hideLoadingMessageDialog();
          return returnObj;

        }

        private static async employeeTimeTrackHandler (postObject): Promise<any> {
          let self = EmployeeTimeTrackEntry;
          if(postObject.mode === PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT) {
            return await self._postAddEmployeeTimeTrack(postObject)
          }

          let breakTimeCheck = await self.validateEmployeeBreakTime(postObject)
            if (breakTimeCheck.possibleBreak) {
              let isBreak = await PositiveTS.VueInstance.$refs.timeClockDialog.showBreakTimeTemplate()

              if (isBreak) {
                return await self.postEmployeeBreakTime(postObject)
              } else{
                return await self._postAddEmployeeTimeTrack(postObject)
              }

            }
            else {
              return await self._postAddEmployeeTimeTrack(postObject)
            }
        }

        static async printTimeTrack(employee,enMode, mode, timeTrack, msg, lastTimeTrack) {
          let INTERFACE_URL = "/employee_bouns/";
          let ajax = Service.Ajax;
          let timeInfo = null;
          if (mode == PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_EXIT &&
            PositiveTS.Reachability.isOnline && !session.pos.isRoshemet) {
            
            try {
              let result = await ajax.promiseJqAjax(INTERFACE_URL, { 
                employee_id: employee.employee_id, islUnformatted: true } ,"GET");
              
              let breakTime = lastTimeTrack.break_time || 0;
                
              let parsedResult = JSON.parse(result.request.result);
              timeInfo = { amount: parsedResult.amount };
              timeInfo.finish_time = moment(new Date(timeTrack.time_track_at)).format();
              timeInfo.start_time = moment(new Date(lastTimeTrack.time_track_at)).format();
              timeInfo.break_time = moment.utc(new Date(0)).add(breakTime, 'minutes').format("HH:mm:ss")
              timeInfo.hours = moment.utc(moment(timeInfo.finish_time).diff(moment(timeInfo.start_time)))
                                .subtract(breakTime, 'minutes').format("HH:mm:ss");
                
            }
            catch(error) {
              Service.Logger.error(error);
            }     
          } 
          
          if (!jsonConfig.getVal(jsonConfig.KEYS.dontPrintWhenEmployeeStartOrEndShift)) {  
            Printing.Reports.printEmployeeTimeTrack(employee,enMode, timeInfo,  msg, timeTrack);
          }
          
        }

        static async checkForOpenDeliveries(magneticCard: string) {
            let employees = await PositiveTS.Storage.Entity.Employee.fetchByStoreOrByShift(session.pos.storeID);
            let employee = employees.find(emp => emp.magneticCard == magneticCard)
            
            let openDeliveries = await PositiveTS.Service.Delivery.getOrdersByCourier(employee.employeeID,20);
            if(openDeliveries.length > 0){
              let ans = await app.promiseShowAlert({
                header:  i18next.t('error'),
                content: i18next.t("delivery.courierExitStillHaveOrders"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: false,
              });
              
              if(ans == "cancel"){
                return false;
              }
              else{
                for(let delivery of openDeliveries){
                  await PositiveTS.Service.Delivery.unassignCourier({
                    sale: delivery,
                    status: PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY,
                    courierID: null,
                    courierName: null,
                  });
                }
              }
              
            }
        }
      }
    }
  }
}
