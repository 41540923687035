module PositiveTS {
export module Promotions {
	export module Templates {
    export abstract class TemplateAbstract {
      public parameters;
      public allowWithOtherPromotions
      constructor(initParameters) {
        var defaultParameters = {
            // Holds all the sale items and their potential promotions
            saleItemsPotentialPromotions: {},

            // Hold the promotion that relevant to sale (no saleItem)
            salePromotion: null,

            // Hold the promotion discount percent for promotion that relevant to sale (no saleItem)
            salePromotionDiscountPercent: 0,

            // Holds all the promotions from buy type
            promotionsBuy: {},

            // Holds all the promotions from get type
            promotionsGet: {},

            // Holds all the promotion codes
            allPromotionsCodes: [],

            // Holds all buy promotions by sale item
            saleItemBuyPromotions: {},

            // Access all promotions by their code
            promotionsByCode: {},

            // The sale to calculate promotions on
            sale: null,

            // The sale items to calculate promotions on
            saleItems: null
        };

        this.parameters = $.extend(defaultParameters, initParameters);
      }

      abstract calculatePromotions()

      protected _generateUniqueKey (saleItem, promotionCode, buyGet) { //not used by new promotions
        return (saleItem.id + '&' + promotionCode + '&' + buyGet);
      }

      protected sortByUnitPriceFromExpensiveToCheapest(a,b) {
        return b.unitPrice - a.unitPrice;
      }

      protected sortByUnitPriceFromCheapestToExpensive(a, b) {
          return a.unitPrice - b.unitPrice;
      }

      protected promotionHasMinimumQuantityAndAmount(promotion, saleItems) { //not used by new promotions
          // Count the number of units in the buy side
          var totalQuantityAndAmount = this.countTotalQuantityAndAmountOfSaleItems(saleItems);

          // Add data about this promotion
          for (var key in this.parameters.saleItemsPotentialPromotions) {
              if (this.parameters.saleItemsPotentialPromotions[key].promotionCode === promotion.code) {
                  this.parameters.saleItemsPotentialPromotions[key].quantityForAllItemsInPromotion = totalQuantityAndAmount.totalQuantity;
                  this.parameters.saleItemsPotentialPromotions[key].priceForAllItemsInPromotion = totalQuantityAndAmount.totalPrice;
              }
          }

          return (totalQuantityAndAmount.totalQuantity >= parseFloat(promotion.minimumBuyQuantity) &&
              totalQuantityAndAmount.totalPrice >= parseFloat(promotion.minimumBuyAmount));
      }

      countTotalQuantityAndAmountOfSaleItems (saleItems) {
          // Iterate over the sale items for this promotions and count their total quantity and amount
          let totalPrice = 0;
          let totalQuantity = 0;
          for (var i = 0; i < saleItems.length; i++) {
            if (saleItems[i].quantity > 0) {
              totalQuantity = totalQuantity + saleItems[i].quantity;
              totalPrice = totalPrice + saleItems[i].quantity * saleItems[i].unitPrice;
            }
          }

          return {
              totalPrice:     totalPrice,
              totalQuantity:  totalQuantity
          };
      }

      flattenSaleItemsByQuantity(saleItems, flattenWeightItems = true):Array<Storage.Entity.SaleItem> {
        if (session.pos.useNewPromotions) { //items are already flat - we don't flatten them twice
          if(this.allowWithOtherPromotions) {
            return saleItems;
          } else {
            if(!posUtils.isBlank(saleItems) && Array.isArray(saleItems)) {
              return saleItems.filter((saleItem) => !((<any>saleItem).hasPromotion));
            }
          }
        }
        else {
          return saleItemHelper.flattenSaleItemsByQuantity(saleItems, flattenWeightItems);
        }
      }


      protected addToItemsCounter(saleItem:Storage.Entity.SaleItem, itemsCounter, quantity = null) {
				if (session.pos.separateItemLinesOn || Boolean(session.pos.useNewPromotions)) {
					itemsCounter[saleItem.rowNumber] = itemsCounter[saleItem.rowNumber] || 0;
          itemsCounter[saleItem.rowNumber] += quantity !== null ? quantity : 1;
				}
				else {
					itemsCounter[saleItem.barcode] = itemsCounter[saleItem.barcode] || 0;
					itemsCounter[saleItem.barcode] += quantity !== null ? quantity : 1;
				}

        return itemsCounter;
      }

      protected addToRowValueCounter(saleItem:Storage.Entity.SaleItem, rowValueCounter, quantity = null) {
        if (Boolean(session.pos.useNewPromotions)) {
					rowValueCounter[saleItem.rowNumber] = rowValueCounter[saleItem.rowNumber] || 0;
          rowValueCounter[saleItem.rowNumber] += quantity !== null ? quantity*saleItem.unitPrice : saleItem.unitPrice;
        }
        return rowValueCounter;
      }

    }
  }
}}
