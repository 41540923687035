module PositiveTS {
  export module Components {
      export module ItemButtonMenu {

        type CompType = any;
        type MenuButton =  PositiveTS.Types.MenuButton;

        interface CompData {
          currentLevel:number
          initialzied:boolean
          loading:boolean
          arrowImg:string
          addingInProgress:boolean
          parentButtonId: number,
          subButtonId: number,
          subThirdButtonId: number,
          isShowSelfServiceHakafa: boolean,
          isShowSelfServicePayWithValuCard: boolean
          selfServiceHakafaBtnDescription: boolean
          showPaymentButtonsInStandAlone: boolean,
          selfServiceScrollMenu: boolean
          scrollSelector: string,
          hasDefaultMenuItems: boolean,
          dalpakTablesView: boolean,
          arrowDownCss: { color: string },
          arrowUpCss: { color: string },
        }


        function openPaymentScreen(method) {
          if (posVC.saleItems.length == 0) {
            app.showAlert({
              header:"לא ניתן לשלם",
              content: "לא נבחרו פריטים",
              hideCancelButton: true
            })
            return;
          }
          posVC.goToPaymentScreen()
          .then(() => {
            PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod(method)
          })

        }

        function openVoucherId(voucherId,focusField = 'barcode') { //TODO: refactor this once we convert the voucher screen to Vue component
          return posVC.goToPaymentScreen()
          .then(function(){
            PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('voucher')
            var voucher = new PositiveTS.Storage.Entity.Voucher();
            return voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);
          })
          .then(function(result) {
            for (var i=0;i<result.length;i++){
              if (result[i].typeID === voucherId ){
                return result[i];
              }
            }
            throw new Error("No smart voucher Valu defined");
          })
          .then(async function(voucherToAdd) {
            Pinia.globalStore.setSelectedVoucherType(voucherToAdd);
            await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.putAllAmountToPayInAmountField();
          });
        }


        export function create() {

            let itemButtonMenu = {
                template: JST.itemButtonMenu(),
                mixins: [Mixins.scrollableMixin],
                components: {
                  PositiveIcon: PositiveIcon.getComponent(),
                  posMenuButton: PosMenuButton.getComponent(),
                  scrollable: Scrollable.getComponent() },
                methods: {
                  async menuItemClicked(button:MenuButton) {
                    let self = <CompType>this;

                    if (!button.id && !button.code) {
                      return;
                    }

                    if (button.isPrimaryMenuItem) {
                      self.setLoadingState()
                      await self.getPrimaryMenuItems(button);
                      self.currentLevel = 1;
                      self.loading = false;
                    }
                    if (button.isSecondaryMenuItem) {
                      self.setLoadingState()
                      await self.getSecondaryMenuItems(button)
                    }
                    if (button.isThirdMenuItem) {
                      self.setLoadingState()
                      await self.getThirdMenuItems(button)
                    }
                    if (button.isItem) {
                      self.addItemToSale(button)
                    }
                  },

                  async getTopLevelMenuItems(isRoshemetParam = null) {
                    let self = <CompType>this;
                    self.setLoadingState();
                    let menuButtons: Array<any> = this.currentActiveMenus;
                    if (!this.currentActiveMenus) {
                      return [];
                    }
                    if (menuButtons.length > 0) {
                      this.hasDefaultMenuItems = true;
                    }
                    return menuButtons;
                  },


                  async getPrimaryMenuItems(button:MenuButton) {
                    let self = <CompType>this;
                    self.parentButtonId = button.id;
                    self.setLoadingState();
                    return button.items;
                  },

                  async getSecondaryMenuItems(button:MenuButton) {
                    let self = <CompType>this;
                    self.subButtonId = button.id;
                    self.setLoadingState()
                    self.currentLevel = 2;
                    self.loading = false;
                  },

                  async getThirdMenuItems(button:MenuButton) {
                    let self = <CompType>this;
                    self.subThirdButtonId = button.id;
                    self.setLoadingState();
                    self.currentLevel = 3;
                    self.loading = false;
                  },

                  async addItemToSale(button:MenuButton) {
                    let self = <CompType>this;
                    if (!button.isItem || !button.code) {
                      return;
                    }

                    if (jsonConfig.getVal(jsonConfig.KEYS.allowOutOfStockItems)) {
                      if (this.outOfStockItemCodesByCode[button.code]) {
                        let message = jsonConfig.getVal(jsonConfig.KEYS.outOfStockItemMessage) || i18next.t('outOfStockItemsDialog.outOfStockMessage')
                        app.showAlertDialog({ header: i18next.t('error'), content: message, continueButtonText: i18next.t("ok"), hideCancelButton: true }, null, null);
                        return;
                      }
                    }

                    if (session.pos.isVendingMachinePos) {
                      let machineItems = await Pinia.vendingMachine.machineItems()
                      let machineItem = machineItems.find(item => item.itemCode == button.code);
                      let itemInvetory = await appDB.vendingMachineItemInventory.where('vendingMachineItemId').equals(machineItem?.railsId || 0).first();
                      if (!itemInvetory || itemInvetory.isOutOfStock) {
                        let message = jsonConfig.getVal(jsonConfig.KEYS.outOfStockItemMessage) || i18next.t('outOfStockItemsDialog.outOfStockMessage')
                        app.showAlertDialog({ header: i18next.t('error'), content: message, continueButtonText: i18next.t("ok"), hideCancelButton: true }, null, null);
                        Pinia.vendingMachine.addItemsAsOutOfStock([button.code]);
                        return;
                      }
                    }

                    if (this.isRoshemet) {
                      let item =  session.allItems.get(button.code); //await appDB.localItems.where('code').equals(button.code).first();
                      let itemBarcode = {size:'null',color:'null',barcode: item.code};
                      let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
                      saleItem.saleID = posVC.sale.id;
                      saleItem.rowNumber = posVC.getRowNumber();
                      saleItem.quantity = PositiveTS.VueInstance.$refs.pos.times;
                      PositiveTS.VueInstance.$refs.pos.times = 1;
                      let changeUnitPrice = null
                      const canFastChangeItemPriceByKeypad = (jsonConfig.getVal(jsonConfig.KEYS.fastChangeItemPriceByKeypad) && PositiveTS.VueInstance.$refs.pos.amount != 0);
                      if (canFastChangeItemPriceByKeypad) {
                        if(Service.Withdrawal.itemIsCashWithdrawal(saleItem)) {
                          app.showAlert({
                            header: i18next.t('error'),
                            content: i18next.t('withdrawal.roshemetFastBtnsError'),
                            continueButtonText: i18next.t("ok"),
                          hideCancelButton: true
                        }, null, null);
                          return;
                        }
                        const pos_ref =  PositiveTS.VueInstance.$refs.pos;
                        changeUnitPrice = pos_ref.amount;
                        pos_ref.amount = 0;
                        pos_ref.times = 1;
                        pos_ref.fields['amount'].hasDecimal = false;
                      }
                      // Add it to sale
                      await posVC.persistNewSaleItem(saleItem, false, false, changeUnitPrice);

                    }
                    else {
                      await posVC.addItemByBarcodeOrByCode(button.code, true, true)
                    }
                    if (jsonConfig.getVal(jsonConfig.KEYS.returnToMainMenuAfterSelectItemFromMenu) &&  self.currentLevel != 0) {
                      self.backToTop()
                    }
                    if (this.selfServiceQuickSale && posVC.saleItems?.length >= 1) {
                      await this.addItemToCartAnimation(button)
                      Pinia.componentsStore.openComponent( {componentName:"selfServicePaymentDialog", args: []});
                    }
                  },

                  itemId(button: MenuButton): string {
                    return 'item' + button.id + (button.isItem ? '0' : '1');
                  },

                  addItemToCartAnimation(button: MenuButton): Promise<void> {
                    return new Promise((resolve) => {
                      const item: HTMLElement = document.querySelector(`#${this.itemId(button)} img`);
                      const cart: HTMLElement = this.selfServiceCartTag;
                      if (!cart || !item) {
                        console.warn('one of HTML elements are not present ', item, cart);
                        resolve();
                      }
                      const clonedItem: HTMLImageElement = <any>item.cloneNode(true);
                      // Get the positions of both the item and the cart
                      const itemRect = item.getBoundingClientRect();
                      const cartRect = cart.getBoundingClientRect();
                      clonedItem.style.position = 'fixed';
                      clonedItem.style.left = `${itemRect.left}px`;
                      clonedItem.style.top = `${itemRect.top}px`;
                      clonedItem.style.width = `${itemRect.width}px`;  // Ensuring the width remains the same
                      clonedItem.style.zIndex = '999999';  // Ensure the clone is above other elements
                      clonedItem.style.pointerEvents = 'none';  // Make sure the cloned item is not clickable
                      clonedItem.style.opacity = '1';
                      clonedItem.style.boxSizing = 'border-box'
                      clonedItem.setAttribute('loading', 'eager');
                      item.parentNode.appendChild(clonedItem);
                      let afterTriedToLoadImg = () => { // wait for the image to load
                        clonedItem.offsetHeight; // Force a repaint to make sure the fixed position gets applied
                        const translateX = (cartRect.left - itemRect.left) + (cartRect.width / 50) - (itemRect.width / 50);
                        const translateY = cartRect.top - itemRect.top;
                        // Apply the transformation to move the item to the cart's position
                        clonedItem.style.transform = `translate(${translateX}px, ${translateY}px)`;
                        clonedItem.style.opacity = '0.1';
                        clonedItem.style.width = clonedItem.style.height = '9rem';
                        setTimeout(() => {
                          clonedItem.remove();
                          resolve();
                        }, 2000);
                      }
                      clonedItem.addEventListener('load', afterTriedToLoadImg); // if loaded successfully
                      clonedItem.addEventListener('error', afterTriedToLoadImg); // else, When image failed to load (for devs)
                    });
                  },

                  setLoadingState() {
                    let self = <CompType>this;
                    self.loading = true;
                  },

                  async loadDefaultMenu() {
                    await this.loadMenusIfNeeded();
                    let self = <CompType>this;
                    if(!self.selfServiceScrollMenu) {
                      self.backToTop();
                      return;
                    }
                    let items = await self.getTopLevelMenuItems();
                    if( items.length > 0) {
                      self.backToTop();
                      return;
                    }

                    self.setLoadingState();
                    let menuButtons:Array<MenuButton> = await (new PositiveTS.Storage.Entity.PrimaryMenuItem()).getAll(this.effectiveMigvanId || 0);
                    let button = menuButtons.find((btn) => posUtils.isPresent(btn.id) && btn.id == session.pos.defaultMenuId);
                    if(button) {
                      let result = await self.getPrimaryMenuItems(button);
                      self.currentLevel = 1;
                      self.loading = false;
                    }
                    else {
                      self.backToTop();
                      return;
                    }

                    document.querySelector("#pos-menu-buttons").scrollTop = 0;
                  },

                  backToTop() {
                    let self = <CompType>this;
                    self.setLoadingState()
                    self.getTopLevelMenuItems()
                    .then(result => {
                      self.currentLevel = 0;
                      self.loading = false;
                    })

                    document.querySelector("#pos-menu-buttons").scrollTop = 0
                  },
                  openPaymentScreen: openPaymentScreen,

                  payWithValuCard() {
                    if (posVC.saleItems.length == 0) {
                      app.showAlert({
                        header:"לא ניתן לשלם",
                        content: "לא נבחרו פריטים",
                        hideCancelButton: true
                      })
                      return;
                    }
                    var simplePosValuCardTypeID = jsonConfig.getVal(jsonConfig.KEYS.simplePosValuCardTypeID);
                    openVoucherId(simplePosValuCardTypeID)
                  },
                  qtyForButton(button:MenuButton) {
                    if (button.code) {
                      let val = this.codeToQtyMap.get(button.code);
                      return val ? val : 0;
                    }
                    else {
                      return 0;
                    }
                  },
                  payWithHakafa() {
                    if ( Service.Hakafa.showHakafaBtn() && Service.Hakafa.hasCustomer()) {
                        PositiveTS.Components.PosPaymentButtons.openVoucherId( PositiveTS.Service.Hakafa.getVoucherID() , 'amount');
                      } else {
                        app.promiseShowAlert({
                          header: i18next.t('error'),
                          content: i18next.t('itIsNotPossibleToPayHakafaWithMizdamen'),
                          continueButtonText: i18next.t('ok'),
                          hideCancelButton: true
                        })

                      }
                  },
                  companyID(){
                    return session.company.companyID;
                  },
                  tenantID(){
                    return session.company.tenantID;
                  },

                  calcArrowsCSS($event) {
                    let menuDiv: any = $event.target;
                    if (!menuDiv) {
                      return;
                    }
                    const scrollPosition = menuDiv.scrollTop;
                    const maxScrollPosition = menuDiv.scrollHeight - menuDiv.clientHeight;
                    const scrollPercentage = (scrollPosition / maxScrollPosition) * 100;
                    let percent = Number(scrollPercentage.toFixed(2))
                    let red = 192 - Math.round(1.92 * percent);
                    let green = 192 - Math.round(1.92 * percent);
                    let blue = 192 - Math.round(1.92 * percent);
                    let upColor = `rgb(${red}, ${green}, ${blue})`;
                    this.arrowUpCss = { color: upColor };
                    percent = 100 - percent;
                    red = 192 - Math.round(1.92 * percent);
                    green = 192 - Math.round(1.92 * percent);
                    blue = 192 - Math.round(1.92 * percent);
                    let downColor = `rgb(${red}, ${green}, ${blue})`;
                    this.arrowDownCss = { color: downColor };
                  },
                  buttonVForKey(button: MenuButton, index: number) {                    
                    return `${this.currentLevel}-${this.parentButtonId}-${this.subButtonId}-${this.subThirdButtonId}-${button.id}-${index}`;
                  },
                },
                setup(){
                  const flightsStore = Pinia.useFlightsStore();
                  const posStore = Pinia.usePosStore();
                  const posMenu = Pinia.usePosMenu();
                  const globalStore = Pinia.useGlobalStore();

                  const {currentBarsetItemsAsItems} = Pinia.storeToRefs(flightsStore);

                  const {selfServiceCartTag} = Pinia.storeToRefs(posStore);

                  const {currentActiveMenus,isFlightsGroupsMenu} = Pinia.storeToRefs(posMenu);


                  const {
                    outOfStockItemCodesByCode,
                    selfServiceQuickSale,
                    simpleSelfService,
                    codeToQtyMap,
                    isRoshemet,
                    isSpecialItemCode,
                    effectiveMigvanId,
                    saleItems,
                    selfServiceSuperMarket} = Pinia.storeToRefs(globalStore);

                  const globalStoreProps = {
                    isRoshemet,
                    saleItems,
                    outOfStockItemCodesByCode,
                    selfServiceQuickSale,
                    isSpecialItemCode,
                    effectiveMigvanId,
                    codeToQtyMap,
                    simpleSelfService,
                    selfServiceSuperMarket}

                  const {loadMenusIfNeeded} = posMenu
                  return {
                    ...globalStoreProps,
                    loadMenusIfNeeded,
                    currentBarsetItemsAsItems,
                    selfServiceCartTag,
                    currentActiveMenus,
                    isFlightsGroupsMenu
                  }
                },
                computed: {
                  isChargeGiftCard(){
                    return Pinia.globalStore.saleItems.length > 0 && Pinia.globalStore.saleItems[0].itemCode == "999991"
                  },
                  noSaleItems(){
                    return Pinia.globalStore.saleItems?.length == 0
                  },
                  showCredit(){
                    return Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCredit));
                  },
                  usePictures(){
                    return Boolean(jsonConfig.getVal(jsonConfig.KEYS.usePictures));
                  },
                  shouldShowOtotPrintBracelet() {
                    return Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotPrintBraceletButton);
                  },
                  showOtotRFIDBraceletActivation() {
                    return Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotRFIDBraceletActivation);
                  },
                  currentBarsetItemsAsItemsWithNotInventoryItem() {
                    let itemsById = _.keyBy(this.currentBarsetItemsAsItems, 'id');

                    for (let item of session.allItems.values()) {
                      if (!item.hasInventory) {
                        itemsById[item.id] = item;
                      }
                    }

                    return Object.values(itemsById);
                  },
                  alignItemsMenuToCenter() {
                    return jsonConfig.getVal(jsonConfig.KEYS.alignItemsMenuToCenter);
                  },
                  alignContentToStart() {
                    return this.selfServiceQuickSale || (this.selfServiceScrollMenu && this.buttons.length >= 9)
                  },
                  itemButtonsMenuScrollCSSClasses() {
                    return {
                      'with-top-buttons': this.currentLevel > 0,
                      'has-pictures':  this.usePictures,
                      'need-to-scroll': this.needToScrol,
                      'no-scroll-bar-ui': this.isAndroid,
                      'align-content-to-start': this.alignContentToStart,
                      'mx-16' : this.alignContentToStart,
                      'align-to-center': this.alignItemsMenuToCenter,
                    };
                  },
                  itemButtonsMenuSelfServiceScrollCSSClasses() {
                    return {
                      'with-top-buttons': this.currentLevel > 0,
                      'has-pictures':  this.usePictures,
                      'align-to-center': this.alignItemsMenuToCenter,
                      'need-to-scroll': this.needToScrol,
                      'align-content-to-start': this.alignContentToStart,
                    };
                  },
                  isAndroid() {
                    return session.isAndroid;
                  },

                  needToScrol() {
                    if((screen.height < 1441 && this.buttons.length > 9) || this.buttons.length > 15)
                      return true;
                    else
                      return false;
                  },
                  showBackButtonInScrollMode() {
                    return this.currentLevel > 1 || (this.currentLevel == 1 && this.hasDefaultMenuItems);
                  },
                  currentMenuTree() {
                    let currentMenuTreeButtons = [];

                    if (this.currentLevel >= 1) {
                      currentMenuTreeButtons.push(this.parentButton);
                    }
                    if (this.currentLevel >= 2) {
                      currentMenuTreeButtons.push(this.subButton);
                    }
                    if (this.currentLevel >= 3) {
                      currentMenuTreeButtons.push(this.subThirdButton);
                    }
                    return currentMenuTreeButtons.filter(button => button && button.id);
                  },
                  beforeLastMenuButton() {
                    return this.currentMenuTree[this.currentMenuTree.length - 2];
                  },
                  currentActiveMenusKeyVal() {
                    let menus: MenuButton[] = _.cloneDeep(this.currentActiveMenus);
                    if (posUtils.isBlankLikeRails(menus)) {
                      return null;
                    }
                    let keyValMenus = { items: menus, menus: {} };
                    for (const btn of menus) {
                      if (btn.isItem || btn.isNotAMenu) {
                        continue;
                      }
                      keyValMenus.menus[btn.id] = { items: btn.items, menus: {} };
                      for (const subBtn of btn.items) {
                        if (subBtn.isItem || subBtn.isNotAMenu) {
                          continue;
                        }
                        keyValMenus.menus[btn.id].menus[subBtn.id] = { items: subBtn.items, menus: {} };
                        for (const subSubBtn of subBtn.items) {
                          if (subSubBtn.isItem || subSubBtn.isNotAMenu) {
                            continue;
                          }
                          keyValMenus.menus[btn.id].menus[subBtn.id].menus[subSubBtn.id] = { items: subSubBtn.items };
                        }
                      }
                    }
                    return keyValMenus;
                  },
                  parentButton(): MenuButton {
                    try {
                      return this.currentActiveMenusKeyVal.items.find(m => !m.isItem && this.parentButtonId == m.id);
                    } catch {
                      return { id: undefined, name: "", color: undefined };
                    }
                  },
                  subButton(): MenuButton {
                    try {
                      return this.currentActiveMenusKeyVal.menus[this.parentButtonId].items.find(m => !m.isItem && this.subButtonId == m.id);
                    } catch {
                      return { id: undefined, name: "", color: undefined };
                    }
                  },
                  subThirdButton(): MenuButton {
                    try {
                      return this.currentActiveMenusKeyVal.menus[this.parentButtonId].menus[this.subButtonId].items.find(m => !m.isItem && this.subThirdButtonId == m.id);
                    } catch {
                      return { id: undefined, name: "", color: undefined }
                    }
                  },
                  buttons(): MenuButton[] {
                    try {
                      let buttons: MenuButton[] = [];
                      let currentLevel: number = this.currentLevel;
                      if (posUtils.isBlankLikeRails(this.currentActiveMenusKeyVal)) {
                        return [];
                      }
                      if (currentLevel == 0) {
                        return this.selfServiceScrollMenu ? this.currentActiveMenusKeyVal.items.filter(btn => btn.isItem || btn.isNotAMenu) : this.currentActiveMenusKeyVal.items;
                      } else if (currentLevel == 1) {
                        buttons = this.currentActiveMenusKeyVal.menus[this.parentButtonId].items;
                      } else if (currentLevel == 2) {
                        buttons = this.currentActiveMenusKeyVal.menus[this.parentButtonId].menus[this.subButtonId].items;
                      } else if (currentLevel == 3) {
                        buttons = this.currentActiveMenusKeyVal.menus[this.parentButtonId].menus[this.subButtonId].menus[this.subThirdButtonId].items;
                      }
                      return buttons;
                    } catch (error) {
                      console.error("error when computing menu buttons!!!!",error);
                      return [{ name: i18next.t('loading'), id: undefined, color: undefined }];
                    }
                  },
                  primaryMenuButtons(): MenuButton[] {
                    let currentActiveMenus: MenuButton[] = this.currentActiveMenus || [];
                    let menus = currentActiveMenus.filter(menu => !menu.isItem);
                    return menus;
                  }
                },
                data: function():CompData {
                  return {
                    currentLevel: 0,
                    initialzied: true,
                    loading: true,
                    arrowImg: `${(<any>window).images_path}pos/arrow.png`,
                    addingInProgress: false,
                    parentButtonId: -1,
                    subButtonId: -1,
                    subThirdButtonId: -1,
                    isShowSelfServiceHakafa: jsonConfig.getVal(jsonConfig.KEYS.isShowSelfServiceHakafa),
                    isShowSelfServicePayWithValuCard: jsonConfig.getVal(jsonConfig.KEYS.isShowSelfServicePayWithValuCard),
                    selfServiceHakafaBtnDescription: jsonConfig.getVal(jsonConfig.KEYS.selfServiceHakafaBtnDescription),
                    showPaymentButtonsInStandAlone: jsonConfig.getVal(jsonConfig.KEYS.showPaymentButtonsInStandAlone),
                    selfServiceScrollMenu: jsonConfig.getVal(jsonConfig.KEYS.selfServiceScrollMenu),
                    dalpakTablesView: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView),
                    scrollSelector: ".item-button-menu #pos-menu-buttons",
                    hasDefaultMenuItems: false,
                    arrowDownCss: { color: 'rgb(0,0,0)' },
                    arrowUpCss: { color: 'rgb(192,192,192)' },
                    }
                },
                watch: {
                  effectiveMigvanId(newMigvan,oldMigvan) {
                    this.backToTop();
                  }
                }
            }

            VueApp.component('item-button-menu',itemButtonMenu)
            return itemButtonMenu;
        }
      }
  }
  }
