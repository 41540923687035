module PositiveTS {
    export module Components {
        export module PosPayment {
            export module YaadView {
                export function create() {
                    let yaadView = {
                        template: JST.YaadView(),
                        mixins: [keyPadMixin, Mixins.paymentViewMixin, Mixins.oneClickMixin],
                        methods: {
                            itemInInvoice(): Array<Storage.Entity.SaleItem> {
                                let itemsInInoivce = [];
                                if (this.amount == this.amountLeft && this.amount == posVC.getTotalAmount()) {
                                    itemsInInoivce = itemsInInoivce.concat(posVC.saleItems)
                                    let itemsSum = 0
                                    for (let index = 0; index < posVC.saleItems.length; index++) {
                                        const saleItem = posVC.saleItems[index];
                                        itemsSum += saleItem.unitPrice * saleItem.quantity
                                    }
                                    if(itemsSum > this.amount){
                                        let genericDiscountItem = session.allItems.get(Shared.Constants.Item.GENERIC_ITEM)
                                        let genericDiscountItemSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(genericDiscountItem, genericDiscountItem.code);
                                        genericDiscountItemSaleItem.unitPrice = this.amount - itemsSum
                                        genericDiscountItemSaleItem.itemDescription = i18next.t('yaadPayment.discountsItem')
                                        itemsInInoivce.push(genericDiscountItemSaleItem)
                                    }
                                } else {
                                    let genericItem = session.allItems.get(Shared.Constants.Item.GENERIC_ITEM)
                                    let genericSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(genericItem, genericItem.code);
                                    genericSaleItem.unitPrice = Number(this.amount)
                                    genericSaleItem.itemDescription = i18next.t('yaadPayment.partialPaymentItem')
                                    itemsInInoivce.push(genericSaleItem)
                                }

                                return itemsInInoivce
                            },
                            inputValidations(){
                                let isValid = true
                                let errMsg = ""

                                if(!posUtils.validateTz(this.taz)){
                                    isValid = false
                                    errMsg = i18next.t('yaadPayment.invalidTaz')
                                }
                                if(!posUtils.isValidIsraelMobilePhone(this.phone)){
                                    isValid = false
                                    errMsg = i18next.t('yaadPayment.invalidPhone')
                                }
                                if(posUtils.isNullOrUndefinedOrEmptyString(this.firstName) || !posUtils.isValidStringNoSpecialChars(this.firstName)){
                                    isValid = false
                                    errMsg = i18next.t('yaadPayment.invalidName')
                                }
                                if(!posUtils.isNullOrUndefinedOrEmptyString(this.email) && !posUtils.isValidEmail(this.email))
                                {
                                    isValid = false
                                    errMsg = i18next.t('yaadPayment.invalidEmail')
                                }
                                if(!posUtils.isValidStringNoSpecialChars(this.lastName) && !posUtils.isNullOrUndefinedOrEmptyString(this.lastName)){
                                    isValid = false
                                    errMsg = i18next.t('yaadPayment.invalidLastName')
                                }
                                if(this.amount <= 0){
                                    isValid = false
                                    errMsg = i18next.t('yaadPayment.invalidAmount')
                                }

                                if(!isValid){
                                    app.showAlert({
                                        header: i18next.t('error'),
                                        content: errMsg,
                                        continueButtonText: i18next.t("ok"),
                                        hideCancelButton: true,
                                    })
                                }

                                return isValid
                            },
                            async verifyAndPay() {
                                if (this.isInCredit) {
                                    return
                                }

                                if(!this.inputValidations()){
                                    return
                                }

                                this.isInCredit = true;
                                const uuid = storage.createUUID()
                                let sendLinkResponse = await Service.YaadService.sendPaymentLinkToCustomer(uuid, this.amount, this.taz, this.firstName, this.phone,
                                    this.itemInInvoice(), this.lastName, undefined, undefined, undefined, undefined, this.email)
                                if(!sendLinkResponse.success){
                                    console.error("Error in sending YaadSarig sms link:" + sendLinkResponse.errors)
                                    app.showAlert({
                                        header: i18next.t('error'),
                                        content: i18next.t('yaadPayment.errorSendingSms'),
                                        continueButtonText: i18next.t("ok"),
                                        hideCancelButton: true,
                                    })
                                    return
                                }

                                app.showLoadingMessageWithCancel({ message: i18next.t('yaadPayment.waitingForCustomerToPay') },
                                    Service.YaadService.cancelRequest, null);
                                const pollResult = await Service.YaadService.startPaymentRequestPoll(uuid)
                                app.hideLoadingMessageWithCancel()

                                if (pollResult.success == true) {
                                    app.showLoadingMessage(i18next.t('yaadPayment.verifyingPayment'))
                                    const verifyResult = await Service.YaadService.verifyAndParseResult(pollResult.data)
                                    app.hideLoadingMessage()

                                    if (verifyResult.success) {
                                        app.showLoadingMessage(i18next.t('yaadPayment.commitingPayment'))
                                        const commitResponse = await Service.VoucherPayment.payBySmartVoucherTypeEx(Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT, this.amount, verifyResult.voucherBarcode, undefined, { orderData: pollResult.data });
                                        app.hideLoadingMessage()

                                        if (!commitResponse.success) {
                                            app.showAlert({
                                                header: i18next.t('error'),
                                                content: i18next.t('yaadPayment.errorCommitingPayment') + " " + commitResponse.error,
                                                continueButtonText: i18next.t("ok"),
                                                hideCancelButton: true,
                                            })
                                        }
                                        else {
                                            this.addSplittedPaymentIfNeeded();
                                            await this.refreshView();
                                        }
                                    }
                                    else {
                                        app.showAlert({
                                            header: i18next.t('error'),
                                            content: i18next.t('yaadPayment.verifyError') + " " + verifyResult.code,
                                            continueButtonText: i18next.t("ok"),
                                            hideCancelButton: true,
                                        })
                                    }
                                }


                                this.isInCredit = false;
                            },
                            async deletePayment(payment, index) {
                                try {

                                    let result = await app.promiseShowAlert({
                                        header: i18next.t('confirmSalePaymentItemDeleteHeader'),
                                        content: i18next.t('confirmSalePaymentItemDelete'),
                                        continueButtonText: i18next.t('remove'),
                                        cancelButtonText: i18next.t('cancel')
                                    });

                                    if (result === "continue") {
                                        await Service.VoucherPayment.removeSmartVoucherPayment(this.yaadPayments[index]);
                                        this.removeSplittedPaymentIfNeeded();
                                    }

                                } catch (e) {
                                    console.error(e);
                                }

                                await this.refreshView();

                            },
                            async refreshView() {
                                this.clearTapped();
                                this.yaadPayments = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT)
                                this.setCalculatedAmount();

                                await PositiveTS.VueInstance.$nextTick()
                                this.$refs.amount.focus();
                            },
                        },
                        data() {
                            return {
                                isInCredit: false,
                                lastName: "",
                                firstName: "",
                                taz: "",
                                phone: "",
                                amount: "0",
                                amountLeft: 0,
                                email: "",
                                yaadPayments: [],
                                initialValues: {
                                    amount: 0
                                },
                                fields: {
                                    amount: {
                                        selected: true,
                                        allowDecimal: true,
                                        inEditMode: true,
                                        type: 'number',
                                        disabled: false,
                                        model: 'amount'
                                    },
                                    phone: {
                                        selected: false,
                                        allowDecimal: false,
                                        inEditMode: false,
                                        type: 'string',
                                        disabled: false,
                                        model: 'phone'
                                    },
                                    taz: {
                                        selected: false,
                                        allowDecimal: false,
                                        inEditMode: false,
                                        type: 'string',
                                        disabled: false,
                                        model: 'taz'
                                    },
                                    email: {
                                        selected: false,
                                        allowDecimal: false,
                                        inEditMode: false,
                                        type: 'string',
                                        disabled: false,
                                        model: 'taz'
                                    },
                                    firstName: {
                                        selected: false,
                                        allowDecimal: false,
                                        inEditMode: false,
                                        type: 'string',
                                        disabled: false,
                                        model: 'firstName'
                                    },
                                    lastName: {
                                        selected: false,
                                        allowDecimal: false,
                                        inEditMode: false,
                                        type: 'string',
                                        disabled: false,
                                        model: 'lastName'
                                    },
                                },
                            }
                        }
                    }
                    VueApp.component('yaad-view', yaadView);
                }
            }
        }
    }
}