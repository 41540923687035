// jquery plugin to auto select input fields on click
// use: $("input field selector").autoselect();
(function( $ ){

  var methods = {
    init : function( options ) {
      return this.each(function(){
        $(this).click(function() {
          $(this).select();
        });
      });
    }
  };

  $.fn.autoselected = function( method ) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on rubysoft.autoselected' );
    }    

  };

})( jQuery );