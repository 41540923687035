module PositiveTS {
	export module Service {
		export class LoadRemoteDataJob {

			static READ_ONLY_DB = 'wasm';

			static dataTypesToLoad = [
				'positive-customer', 'hakafa-customer',
				'store-item', 'wasm'];
 
			static identifier = 'LoadRemoteDataJob';
			static LocalStorageIsLastUpdateComplete = 'Positive.LoadRemoteDataJob.isLastUpdateComplete';
			static JobCompletedNotification = 'PositiveServiceJobsJobCompletedNotification'
			static JobFailedNotification = 'PositiveServiceJobsJobFailedNotification'
			static JobProgressNotification = 'PositiveServiceJobsJobProgressNotification'
			
			remoteData:Array<any>
			dataDumps:Array<any>
			remoteDataCount:any

			constructor() {
				this.remoteData = [];
				this.dataDumps = [];
				this.remoteDataCount = {};
				//Checking if the pos is or *will be* delivery pos - we check it this way because we will know
				//if the pos is delivery pos only after the first catalog update and we don't want to do it twice
				let posJsonConfig = JSON.parse(session.pos.jsonConfig);
				let isCompanyUsePictures = session.pos.usePictures;
				
				if(posJsonConfig.isDelivery == true || posJsonConfig.mobilePhoneRepairModule == true) {	
					
					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('cities') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('cities');
					}
					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('streets') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('streets');
					}
				}

				if(posJsonConfig.usePictures == true && isCompanyUsePictures){
					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('item-pictures') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('item-pictures');
					}

					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('images') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('images');
					}
				}
				
				if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) && (jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableScreenSaver) || jsonConfig.getVal(jsonConfig.KEYS.selfServiceBackgroundImage))) {
					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('screen-savers') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('screen-savers');
					}
					if(jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverType) == 'video'){
						if (LoadRemoteDataJob.dataTypesToLoad.indexOf('videos') == -1) {
							LoadRemoteDataJob.dataTypesToLoad.push('videos');
						}
					}
				}

				if (session.pos.hasFlights) {
					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('secondary-category') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('secondary-category');
					}
				}

				if (session.pos.tenantID == "6" && session.pos.companyID == "2") {
					// remove positive-customer from array
					LoadRemoteDataJob.dataTypesToLoad = LoadRemoteDataJob.dataTypesToLoad
					.filter(item => item != 'positive-customer')
					.filter(item => item != 'hakafa-customer')
					.filter(item => item != 'store-item')
				}


				if (session.pos.tenantID == "11") {
					if (LoadRemoteDataJob.dataTypesToLoad.indexOf('items-promotions.dump') == -1) {
						LoadRemoteDataJob.dataTypesToLoad.push('items-promotions.dump');
					}
				}

				if (posJsonConfig.hasOnlyOnlineCustomerClub) {
					LoadRemoteDataJob.dataTypesToLoad = LoadRemoteDataJob.dataTypesToLoad.filter(item => item != 'positive-customer')
				}
			}

				/**
	 * The starting point
	 */
	run(fullUpdate = false) {
		// Check that there is a POS in session
		if (session.pos == null) {
			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: new Error('No POS defined in session')
			});
			console.error('No POS defined in session');

			// Exit!
			return;
		}

		// Initialize the remoteData array
		this.remoteData = [];

		// Initialize the remoteDataCount object
		this.remoteDataCount = {};

		// Mark the flag in LocalStorage to indicate that the last update wasn't completed
		window.localStorage.setItem(LoadRemoteDataJob.LocalStorageIsLastUpdateComplete, 'false');

		// Start by loading the POS params
		this.loadParams(fullUpdate);
	};


	private loadParamsErrorsInResult(results) {
		if ('error' in results) {
			// Extract the message from the error object
			var message = i18next.t("paramsLoadFaild") + ' (' + results.error.message + ')';

			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: new Error(message)
			});
			console.error(message);

			// We are finished!
			return true;
		} else if(results.valid === false) {
			// Extract the message from the error object
			let message: string = i18next.t(`updateCatalog.${results.message}`);

			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: new Error(message)
			});
			console.error(message);

			// We are finished!
			return true;
		}
		return false;
	};

	private loadParamsFailToVerifyData(results) {
		let hasAllData = true;
		hasAllData = hasAllData && ('posparamset_id' in results);
		hasAllData = hasAllData && ('decimal_precision' in results);
		hasAllData = hasAllData && ('require_salesperson' in results) && ('require_customer' in results);
		hasAllData = hasAllData && ('debit_invoice_suffix' in results) && ('credit_invoice_suffix' in results) 
						&& ('credit_voucher_suffix' in results) && ('positive_rcpt_suffix' in results) && ('pay_punch_card_suffix' in results);
		hasAllData = hasAllData && ('invoice_type' in results);
		hasAllData = hasAllData && ('enforce_inventory' in results) && ('enforce_inventoryLevel' in results);
		hasAllData = hasAllData && ('max_check_days' in results) && ('max_credit_voucher_days' in results) && ('max_credit_card_payments' in results);
		hasAllData = hasAllData && ('allow_typing_credit_card' in results) && ('max_change_in_cash' in results);
		hasAllData = hasAllData && ('allow_negative_sale' in results) && ('minimum_change_in_credit_voucher' in results);
		hasAllData = hasAllData && ('employee_magnetic_card_length' in results) && ('is_automatic_employee_store_connection' in results);
		hasAllData = hasAllData && ('allow_offline_credit_card' in results) && ('printer_type' in results) && ('offline_creditcard_limit' in results);

		if (!hasAllData) {
			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: new Error(i18next.t("missingDataFromRemoteServer"))
			});
			console.error('Got partial data from remote server when trying to get POS params');

			// We are finished!
			return true;
		}
		return false;
	};

	private buildPosParamSet(results) {
		session.pos.paramsetID											= results['posparamset_id'];
		session.pos.parameterDecimalPrecision			= results['decimal_precision'];
		session.pos.parameterRequireSalesperson		= results['require_salesperson'];
		session.pos.parameterRequireCustomer				= results['require_customer'];
		session.pos.parameterDebitInvoiceSuffix		= results['debit_invoice_suffix'];
		session.pos.parameterCreditInvoiceSuffix		= results['credit_invoice_suffix'];
		session.pos.parameterCreditVoucherSuffix		= results['credit_voucher_suffix'];
		session.pos.parameterPositiveRcptSuffix		= results['positive_rcpt_suffix'];
		session.pos.parameterPayPunchCardSuffix		= results['pay_punch_card_suffix'];
		session.pos.parameterInvoiceType						= results['invoice_type'];
		session.pos.parameterEnforceInventory			= results['enforce_inventory'];
		session.pos.parameterEnforceInventoryLevel	= results['enforce_inventoryLevel'];
		session.pos.parameterMaxCheckDays					= results['max_check_days'];
		session.pos.parameterMaxCreditVoucherDays	= results['max_credit_voucher_days'];
		session.pos.parameterMaxCreditCardPayments	= results['max_credit_card_payments'];
		session.pos.parameterAllowTypingCreditCard	= results['allow_typing_credit_card'];
		session.pos.parameterAllowOfflineCreditCard	= results['allow_offline_credit_card'];
		session.pos.parameterOfflineCreditCardLimit = results['offline_creditcard_limit'];
		session.pos.parameterMaxChangeInCash				= results['max_change_in_cash'];
		session.pos.parameterAllowNegativeSale			= results['allow_negative_sale'];
		session.pos.parameterAllowNegativeQty			= results['allow_negative_qty'];
		session.pos.parameterPrintLogo			= results['print_logo'];
		session.pos.parameterNoCheckDetails			= results['no_check_details'];
		session.pos.parameterEmployeeMagneticCardLength			= results['employee_magnetic_card_length'];
		session.pos.parameterIsAutomaticEmployeeStoreConnection	= results['is_automatic_employee_store_connection'];
		session.pos.printerType	= results['printer_type'];
	};

	private handleGetPosParamsResponse(results,fullUpdate = false) {
		// --- If there was an error, the remote server will return an error object
		if (this.loadParamsErrorsInResult(results)) {
			return;
		}


		// Check that the data from the remote server has all the needed data
		if (this.loadParamsFailToVerifyData(results)) {
			return;
		}

		// Set logrocket/openReplay auto open if needed
		localStorage.removeItem('shouldRecord')
		if (results.shouldRecord) {
			localStorage.setItem('shouldRecord', 'true')
		}

		// --- Request was successful, persist the data from the remote server
		// Persist the POS data
		this.buildPosParamSet(results);

		session.pos.persist(null)
		.then(async () => {
			app.showLoadingMessage(i18next.t("preparingLoadData"));
			await PositiveTS.Service.CashierStatement.loadCashierStatementLogsIfNeed(results.cashier_statement_logs_after_last_z_report)
			this.loadRemoteData(fullUpdate);
		}).catch(function (error) {
			// Post relevant notification
			app.hideLoadingMessage();

			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: new Error('Failed persisting POS')
			});
			console.error('Failed persisting POS');
			console.error('Error was: ' + error);
		});
	};

	/**
	 * Loads the POS params from the remote server
	 */
	private loadParams(fullUpdate = false) {
		// let aThis = this;
		app.showLoadingMessage(i18next.t("loadingPosParameters"));
		// Get the username and POS physical ID from session
		var data = {
			// username: session.pos.loginUsername,
			devicePhysicalId: session.pos.physicalID
		};

		var url = Shared.Constants.remoteRoot + 'getposparams';
		$.post(url, data, (results) => this.handleGetPosParamsResponse(results,fullUpdate) , 'json')
		.fail(function() {
			app.hideLoadingMessage();
			// --- An error was occured while loading POS parameters from remote server
			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: new Error(i18next.t("paramsLoadFaild") + ' (HTTP error)')
			});
			console.error('An HTTP error has occured while loading POS params from remote server');
		});
	};

	/**
	 * Loads data from remote server and populate the database
	 */
	loadRemoteData (fullUpdate = false) {
		let aThis = this;
		let lastUpdate = Pinia.globalStore.lastCatalogUpdate;
		let dataTypesToLoad = LoadRemoteDataJob.dataTypesToLoad;

		if(session.pos.isRoshemet && fullUpdate == true && lastUpdate != null){
			dataTypesToLoad = LoadRemoteDataJob.dataTypesToLoadRoshemet;
		}

		if (session.pos.isRoshemet && lastUpdate != null && fullUpdate == false) { //don't load remote files if this initial catalog update was already done
			if (posUtils.isBlank(localStorage.getItem("readOnlyDbDataLoadedAndWebDBRemoved"))) {
				dataTypesToLoad = LoadRemoteDataJob.dataTypesToLoadRoshemet;
			} else {
				dataTypesToLoad = ['store'];
			}
		}

		/**
		 * This function loads a single file of data by type from the remote server
		 */
		let asyncFunction = function(theArguments, successCallback, errorCallback) {
			// Get the data type to load from arguments
			var dataType = theArguments.dataType;

			// Build the url
			var url = Shared.Constants.remoteRoot + 'catalog/{what}';
			url = url.replace('{what}', dataType);

			// Get the data from remote server
			$.ajax({
				"url": url,
				"data": {
					"pos_device_id": session.pos.deviceID,
					"data_ext":  storage.isJsonDump(dataType) ? "json" : (storage.isDbDataFileDump(dataType) ? "db" : "dump")
				},
				cache: true,
				"dataType": storage.isDbDataFileDump(dataType) ? "binary" : "text",
            xhrFields:{
					responseType: storage.isDbDataFileDump(dataType) ? 'blob' : "text"
			   },
			   timeout: 30 * 60 * 1000,
				success: function(items) {
					aThis.dataDumps.push(items);
					successCallback({}); //use the super sophisticated mechanism (no time to re-write this bs)
				}
			})
			.error(function (error) {
				console.error(error);
				// --- An error was occured while loading POS parameters from remote server
				// Create the error message
				var message = i18next.t('loadingXFailed', {0: dataType}) + ' (HTTP error)';

				// Post relevant notification
				PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
					identifier: LoadRemoteDataJob.identifier,
					error: new Error(message)
				});
				console.error(message);

				// Call the error callback
				errorCallback(new Error(message));
			});
		};

		/**
		 * This function notifies that the job completed and imports the retrieved data into storage
		 */
		let successCallback = function () {
			// Mark the flag in LocalStorage to indicate that the last update was completed
			window.localStorage.setItem(LoadRemoteDataJob.LocalStorageIsLastUpdateComplete, 'true');
			window.localStorage.setItem("readOnlyDbDataLoadedAndWebDBRemoved", 'true');

			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobCompletedNotification, {
				identifier: LoadRemoteDataJob.identifier
			});

			// Import remote data into storage
			storage.importDataDumps(aThis.dataDumps, dataTypesToLoad)
			.then(
				function(values) {
					storage.importSuccessful().then(() => {
						isCatalogUpdated.isDuringCatalogUpdate = false;
					})
				},
				function(reason) {
					storage.importFailed(reason);
				}
			);
		};

		/**
		 * This function notifies that the job failed
		 */
		let errorCallback = function (e) {
			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobFailedNotification, {
				identifier: LoadRemoteDataJob.identifier,
				error: e
			});
			console.error(e.message);
			if (PositiveTS.Service.CheckPosPropriety.isCannotWorkOnPos()){
Pinia.componentsStore.openComponent( {componentName:"checkPosProprietyDialog", args: []}) 
			}
		};

		/**
		 * This function notifies that the job's progress
		 */
		let progressCallback = function (message, progressValue) {
			// Since we are using SerialRunner, the message will be an empty string, and the progress value will
			// be a number between 0 to 1. It can be multiplied by dataTypesToLoad.length-1 to get the current data type
			// being loaded
			// Calculate the data type index (ensure it is integer by rounding it)
			var dataTypeIndex = Math.floor(progressValue * (dataTypesToLoad.length - 1));

			// Get the data type being loaded
			var dataType = dataTypesToLoad[dataTypeIndex];

			// Post relevant notification
			PositiveTS.NotificationCenter.postNotification(LoadRemoteDataJob.JobProgressNotification, {
				identifier: LoadRemoteDataJob.identifier,
				message: i18next.t('downloadingDataFor') +  ' ' + dataType,
				progressValue: progressValue
			});
		};

		// Create an instance of SerialRunner
		var runner = new PositiveTS.SerialRunner(asyncFunction, successCallback, errorCallback, progressCallback);

		// Add run for each data type
		for (var i = 0; i < dataTypesToLoad.length; i++) {
			// Get the data type
			var dataType = dataTypesToLoad[i];

			// Add a run
			runner.addRun({dataType: dataType});
		}

		if (dataTypesToLoad.length > 0) {
			runner.run();
		}
		else {
			storage.importSuccessful();
			isCatalogUpdated.isDuringCatalogUpdate = false;
		}
	};
	static  get dataTypesToLoadRoshemet () {
		let dataTypesToLoadRoshemet = ['wasm', 'hakafa-customer']
		if (jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule)){
			dataTypesToLoadRoshemet.push('cities')
			dataTypesToLoadRoshemet.push('streets')
		}
		
		return dataTypesToLoadRoshemet
	}
}}}
