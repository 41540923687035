module PositiveTS {
  export module Service {
    export module TenbisService {

      var _he = {
        "920": "העסקה נכשלה - שגיאה לא ידועה",
        "921": "העסקה נכשלה - חריגה ממגבלת כרטיס תן ביס",
        "922": "העסקה נכשלה - כרטיס תן ביס נדחה",
        "923": "העסקה נכשלה - חריגה ממגבלת כרטיס אשראי",
        "924": "נדחה האשראי כרטיס - נכשלה העסקה",
        "925": "העסקה נכשלה - מגבלת עסקה כפולה. עסקה זהה בוצעה בשתי הדקות האחרונות",
        "926": "העסקה נכשלה - שגיאה כללית",
        "701": "שגיאת התחברות"
      }


      export const customer_number = "לקוח תן ביס"

      export async function cancelOrder(cardNumber, amount) {
        let token = await _getToken();
        var _do = `https://www.10bis.co.il/api/reshome/v2/ReshomeService.svc/RefundMoneycard/netpos/${session.pos.tenbisResId}/${cardNumber}/random/${amount}/${token}/auto`
        var _data = {
          "body": "", "type": "POST",
          "url": _do,
          "headers": _headers()
        };

        var data = JSON.stringify(_data);
        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )

        Service.Filelog.log("tenbis",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        );

        let result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          return result;
        } else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      export async function getBalance(cardNumber) {
        let token = await _getToken();
        var _do = `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/GetMoneycardDebitLimit/${cardNumber}/${session.pos.tenbisResId}/${token}`

        var _data = {
          "body": "", "type": "GET",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        var data = JSON.stringify(_data)
        let response = await Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )
        Filelog.log("tenbis",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        );
        var result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          return result;
        }
        else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      export async function addOrder(cardNumber, amount) {
        let token = await _getToken();

        let _do = `https://www.10bis.co.il/api/reshome/v2/ReshomeService.svc/DebitMoneycard/netpos/${session.pos.tenbisResId}/${cardNumber}/false/none/random/${amount}/${token}/auto`

        let _data = {
          "body": "", "type": "POST",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        let data = JSON.stringify(_data)

        let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        );

        await Service.Filelog.log("tenbis", JSON.stringify({ request: response.request.body.data, result: response.request.result }));

        let result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        return result;
      }

      export async function fetchTenbisDeliveryAndPoolOrders() {
        try {
          let orders = []
          let fetchedOrders = await fetchTenbisDeliveryOrders()


          for (const order of fetchedOrders) {
            let isPoolOrder = order.PoolID > 0 ? true : false
            if (isPoolOrder) {
              let poolOrder = await fetchPoolOrder(order.PoolID)
              let customerName = order.CustomerName
              let deliveryMethod = order.DeliveryMethod


              poolOrder.Data.Orders.forEach(poolOrderData => {
                let formatedOrder = Service.TenbisConvertService.convertPoolOrderToSingleOrder(poolOrderData, customerName, deliveryMethod, order.PoolID)
                orders.push(formatedOrder)
              })
            } else {
              orders.push(order)
            }
          }

          return orders
        } catch (error) {
          Logger.error(error)
        }
      }

      export async function fetchTenbisDeliveryOrders() {
        let token = await _getToken();
        let _do = `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/gettodaysorders/${token}/pending/${session.pos.tenbisResId}`
        let _data = {
          "body": "", "type": "GET",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        let data = JSON.stringify(_data)
        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )

        Filelog.log("tenbisorders",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        )

        let result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          if (session.isAndroid && result.Data["OrderLineElement"]) {
            return [].concat(result.Data["OrderLineElement"])
          }
          else {
            return result.Data;
          }
        } else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      export async function fetchSingleOrder(orderId) {
        let token = await _getToken();
        let _do = `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/getsingleorder/${orderId}/${token}/${session.pos.tenbisResId}`
        let _data = {
          "body": "", "type": "GET",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        let data = JSON.stringify(_data)

        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )

        Service.Filelog.log("tenbisorders",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        );

        let result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          return result;
        } else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      export async function fetchPoolOrder(poolId: string){
        let token = await _getToken();
        let _do = `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/getpoolorder/${poolId}/${token}/${session.pos.tenbisResId}/false`
        let _data = {
          "body": "", "type": "GET",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        let data = JSON.stringify(_data)

        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )

        Service.Filelog.log("tenbis-pool-orders",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        );

        let result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          return result;
        } else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      export async function updateOrderStatus(orderId, status) {
        let token = await _getToken();
        let _do = `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/changestandardorderstatusandsetmetadata/${orderId}/${status}/${token}/${session.pos.tenbisResId}`

        let _data = {
          "body": "", "type": "POST",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        let data = JSON.stringify(_data)
        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )

        Filelog.log("tenbisorders",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        );

        var result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          return result;
        } else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      export async function updatePoolOrderStatus(poolId: string, status: string) {
        let token = await _getToken();
        let _do = `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/changepooledorderstatus/${poolId}/${status}/${token}/${session.pos.tenbisResId}`

        let _data = {
          "body": "", "type": "POST",
          "url": _do,
          "headers": _headers(),
          "timeout": 120000
        };
        let data = JSON.stringify(_data)
        let response = await GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        )

        Filelog.log("tenbis-pool-orders",
          JSON.stringify({ request: response.request.body.data, result: response.request.result })
        );

        var result = JSON.parse(response.request.result);
        result = JSON.parse(result.body)
        if (result.Success) {
          return result;
        } else {
          throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
        }
      }

      function _getToken() {
        var token = _getLocalToken();
        if (token) {
          return Promise.resolve(token)
        } else {
          return _login()
            .then(() => {
              return _getLocalToken();
            })
        }
      }

      function _login(): Promise<void> {
        if (!_isLoginRequired()) {
          return Promise.resolve();
        }

        if (posUtils.isBlank(session.pos.tenbisUserName) || posUtils.isBlank(session.pos.tenbisUserPass) || posUtils.isBlank(session.pos.tenbisResId)) {
          throw new Error(i18next.t('tenbis.noUserPasswordConfigured'))
        }

        var _data = {
          "body": "", "type": "GET",
          "url": `https://www.10bis.co.il/api/reshome/v2/reshomeservice.svc/Login/${session.pos.tenbisUserName}/${session.pos.tenbisUserPass}/${session.pos.tenbisResId}/random`,
          "headers": _headers()
        };
        var data = JSON.stringify(_data)
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "http_service"
        ).then((x) => {
          PositiveTS.Service.Filelog.log("tenbis",
            JSON.stringify({ request: x.request.body.data, result: x.request.result })
          );

          var result = JSON.parse(x.request.result);
          result = JSON.parse(result.body)
          if (result.Success) {
            _saveToken(result.Data.Token)
            return;
          } else {
            throw new Error(`${getErrorText(result.Errors[0].ErrorCode)}\n הודעת שגיאה מפורטת: ${result.Errors[0].ErrorDesc}`)
          }

        });
      }


      function _getLocalToken(): string {
        if (_isLoginRequired()) {
          return "";
        } else {
          return JSON.parse(localStorage.getItem('tenbis')).token;
        }
      }

      function _isLoginRequired(): boolean {
        var tenbisStr = localStorage.getItem('tenbis')
        if (!tenbisStr) { return true; }
        var tenbisDict = JSON.parse(tenbisStr);
        var tokenDate: any = new Date(tenbisDict.at);
        if (moment(new Date()).diff(tokenDate, 'minutes') > 90) { return true; }

        return false;
      }

      function _saveToken(token: string): void {
        localStorage.setItem("tenbis", JSON.stringify({ at: new Date().toJSON(), token: token }))
      }

      function _headers() {
        return JSON.stringify({
          "accept": "application/json",
        })
      }

      function getErrorText(code) {
        if (posUtils.isBlank(_he[code])) {
          return i18next.t('tenbis.generalErrorComunication')
        } else {
          return _he[code];
        }
      }



    }
  }
}
