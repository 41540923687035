module PositiveTS {
    export module Components {
    export module CaveretPaymentCustomerPicker {
    
      const _dialogSelector = "#positive-caveret-payment-customer-picker";
      const _vueComponentName = 'caveret-payment-customer-picker';
      
      export function convertToPosStructure(customer) {
        let uglyCustObj = {}

        uglyCustObj["s_id_number"] = customer.customer_tz
        uglyCustObj["s_member_no"] = customer.customer_number
        uglyCustObj["customer_group_id"] = customer.customer_group_id
        uglyCustObj["customer_group_name"] = customer.customer_group_name
        uglyCustObj["s_first_name"] = customer.first_name
        uglyCustObj["s_last_name"] = customer.last_name
        uglyCustObj["email"] = customer.email
        uglyCustObj["msg_for_screen"] = "" //TODO
        uglyCustObj["msg_for_slip"] = "" //TODO
        uglyCustObj["cust_points"] = customer.current_points
        uglyCustObj["secondary_phone"] = customer.secondary_phone
        uglyCustObj["remarks"] = customer.remarks
        uglyCustObj["is_delivery_customer_only"] = customer.is_delivery_customer_only
        uglyCustObj["customer_addresses"] = customer.customer_addresses
        uglyCustObj["compensation"] = customer.compensation
        uglyCustObj["compensation_reason"] = customer.compensation_reason
        

        let pointsForUse = customer.current_points < jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse) ? 0 : customer.current_points //TODO - parameter at club level
        let multiplyPointsVal = jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication)
        if (pointsForUse > 0 && multiplyPointsVal != null && multiplyPointsVal > 0) {
          pointsForUse = pointsForUse - pointsForUse % multiplyPointsVal;
        }
        uglyCustObj["cust_points_for_use"] = pointsForUse
        uglyCustObj["i_point_value"] = 1 //TODO - parameter at club level
        uglyCustObj["i_club_id"] = customer.customer_club_id
        uglyCustObj["dt_birth_date"] = customer.date_of_birth
        uglyCustObj["address"] = `${customer.street_address || ""} ${customer.apartment_number || ""} ${customer.city || ""}`
        uglyCustObj["s_phone_number_1"] = customer.phone_number
        uglyCustObj["db_id"] = customer.id
        uglyCustObj["zip_code"] = customer.zip_code
        uglyCustObj["val_per_points"] = uglyCustObj["i_point_value"] * uglyCustObj["cust_points_for_use"]
        uglyCustObj["clubName"] = 'positive'
        uglyCustObj["discount_percent"] = customer.discount_percent

        //TODO: get promotion types from server. maybe use a serializer?
        uglyCustObj["promotion_types"] = customer.promotion_types
        //uglyCustObj["promotion_types"] = CustomerClub.get_promotion_types(customer)

        return uglyCustObj;
      }
    
      export function create() {
        let caveretPaymentCustomerPicker = {
            template: JST.caveretPaymentCustomerPicker(),
            methods: {
              open: open,
              close: close,
              async findCustomer() {
                let caveret = new Service.CaveretPayment();
                app.showLoadingMessage(i18next.t('caveretPayment.lookingForCustomer'))
                try{
                  let res = await caveret.getBalance(this.card, this.pinCode,this.inputMethod,this.selectedClub ? this.selectedClub.code : null);
                  if(res.ReplyCode == 1){
                    let result = await this.applyLocalCustomer(res.CustomerCode,res.CustomerName);
                    if(result){
                      this.budgets = [];
                      for(let i=0;i<res.Budgets.length; i++){
                        let newBudg = Object.assign({used: null},res.Budgets[i]);
                        newBudg.parsedDate = moment(newBudg.ToDate,"DD/MM/YYYY HH:mm:ss.SSS").format("DD/MM/YY");
                        this.budgets.push(newBudg);
                      }
                      if(this.budgets.length == 1){
                        this.budgets[0].used = Math.min(this.budgets[0].Balance,this.totalAmount) ;
                      }

                      for(let budget of this.budgets){
                        let promoTriggers = [];
                        if(budget.PromoTriggers){
                          promoTriggers = budget.PromoTriggers.split(",");
                        }
                        for(let promoTrigger of promoTriggers){
                          let promo = promoTrigger.split("#");
                          if(promo[1] && promo[1] == 0){
                            budget.used = 0;
                          }
                        }
                      }
                    }
                  }
                  else{
                    this.card = null;
                    this.pinCode = "0000";
                    app.showAlert({
                      header: res.ScreenCommentsHeader,
                      content: res.ScreenCommentsBody,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                  }
                }
                catch(error){
                  console.error(error);
                  this.card = null;
                  this.pinCode = "0000";
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPayment.error'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }


                app.hideLoadingMessage();
  
              },
              async pay() {
                let totalBudgetUsed = 0;
                let usedBudgets = [];
                let soloBudget = null;

                for(let budget of this.budgets){

                  if(!posUtils.isNullOrUndefinedOrEmptyString(budget.used)){
                    usedBudgets.push(budget);

                    if(budget.AllowOtherPayments == false){
                      soloBudget = budget
                    }
                  }
                }

                if(soloBudget && (usedBudgets.length > 1 || posVC.salePayments.length > 0))
                {
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPayment.solobudgetSelected',{name: soloBudget.BudgetName}),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                  return;
                }

                
                for(let budget of this.budgets){
                  let promoTriggers = [];

                  if(budget.used >= 0){
                    if(this.promotionApplied == false){
                      if(budget.PromoTriggers){
                        promoTriggers = budget.PromoTriggers.split(",");
                      }
                      for(let promoTrigger of promoTriggers){
                        let promo = promoTrigger.split("#");
                        if(promo[1]){
                          if(budget.used >= promo[1])
                          this.applyPromotion(promo[0]);
                        }
                      }
                    }
                    totalBudgetUsed+= Number(budget.used);
                  }
                }

                if(totalBudgetUsed > this.totalAmount){
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPayment.tooMuchBudget'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }
                else if(totalBudgetUsed < this.totalAmount && soloBudget){
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPayment.tooLittleBudgetAndSolo',{name: soloBudget.BudgetName}),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }
                else if(usedBudgets.length == 0){
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPayment.noBudgetsSelected'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }
                else{
                  let amountBefore = this.totalAmount;
                  if(this.promotionApplied){

                      let updatedSaleItems = posVC.updatePromotions()
                      posVC.saleUpdated(updatedSaleItems);
                      this.totalAmount = posVC.getTotalLeftToPay();
                      posPaymentVC.updateAmountsIndicators();

                      if(this.totalAmount == amountBefore){
                        await Service.VoucherPayment.payBySmartVoucherType(Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT,totalBudgetUsed,this.card,undefined,{budgets: usedBudgets,pincode: this.pinCode,clubCode: this.selectedClub ? this.selectedClub.code : null});
                      }
                      else{
                        let res = await Pinia.componentsStore.openComponent( {componentName:"caveretPaymentSaleDialog", args: []});
                        if(res == true){
                          let nonZeroBudgets = [];
                          for(let budget of usedBudgets){
                            if(Number(budget.used) > 0){
                              nonZeroBudgets.push(budget);
                            }
                          }
                          if(nonZeroBudgets.length == 1 && nonZeroBudgets[0].used > this.totalAmount){
                            for(let budget of usedBudgets){
                              if(budget == nonZeroBudgets[0]){
                                budget.used = this.totalAmount;
                              }
                            }
                            totalBudgetUsed = this.totalAmount;
                          }
                          
                          if(totalBudgetUsed > this.totalAmount){
                            return;
                          }
                          else{
                            await Service.VoucherPayment.payBySmartVoucherType(Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT,totalBudgetUsed,this.card,undefined,{budgets: usedBudgets,pincode: this.pinCode,clubCode: this.selectedClub ? this.selectedClub.code : null});
                          }
                        }
                        else{
                          posVC.externalPromotions = [];
                          let updatedSaleItems = posVC.updatePromotions()
                          posVC.saleUpdated(updatedSaleItems);
                          this.totalAmount = posVC.getTotalLeftToPay();
                          this.promotionApplied = false;
                          return;
                        }
                    }
                  }
                  else{
                    await Service.VoucherPayment.payBySmartVoucherType(Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT,totalBudgetUsed,this.card,undefined,{budgets: usedBudgets,pincode: this.pinCode,clubCode: this.selectedClub ? this.selectedClub.code : null});
                  }
                  for(let budget of this.budgets){
                    if(budget.used != 0){
                      budget.used = null;
                    }
                  }
                  this.selectedClub = null
                  this.promotionApplied = false
                  let paymentMethods = PositiveTS.VueInstance.$refs.posPaymentDialog.paymentMethods
                  for(let paymentMethod in paymentMethods){
                    if(paymentMethods[paymentMethod].visible == true){
                      PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod(paymentMethod)
                    }
                  }
                  close();
                }
              },
              async swipeCard(){
                app.showLoadingMessage(i18next.t('caveretPayment.swipeCard'));
                let cardNumber = await Service.EMV.swipe();
                if(cardNumber){
                  this.inputMethod = 1;
                  this.card = cardNumber;
                  await this.findCustomer();
                }
                app.hideLoadingMessage();
              },
              manualInput(event){
                if(this.inputMethod == 1){
                  if(event.key.length == 1){
                    this.card = event.key;
                    this.inputMethod = 4;
                  }
                }
              },
              payAll(selectedBudget){

                for(let i=0;i<this.budgets.length;i++){
                  if(this.budgets[i].BudgetID == selectedBudget.BudgetID){
                    this.budgets[i].used = this.totalAmount;
                  }
                  else{
                    this.budgets[i].used = null;
                  }
                }
              },
              applyPromotion(promoCode){
                posVC.addExternalPromotion(promoCode);
                this.promotionApplied = true;
              },
              detectBardcode(event){
                let inputFromScanner = (event.timeStamp - this.lastKeypressTimestamp) < 15
                this.lastKeypressTimestamp = event.timeStamp
                if(inputFromScanner){
                  this.inputMethod = 2;
                }
              },
              chooseBudget(budget){
                let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
                let cust = svcCustomer.getCurrentSelectedCustomer();
                cust.selectedBudget = budget.Balance;

                let salejd = JSON.parse(posVC.sale.jsondata);
                salejd.customer = cust;
                posVC.sale.jsondata = JSON.stringify(salejd);
                posVC.setCustomerState();
                this.close();
              },
              async applyLocalCustomer(customerCode,customerName){
                let searchResults = await Storage.Entity.PositiveCustomer.search(customerCode);
                if(searchResults.result.i_return_code == "1"){
                  if(searchResults.result.customers.length > 1){
                    app.showAlert({
                      header: i18next.t('error'),
                      content: i18next.t('caveretPayment.moreThanOneCustomerFound'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                  }
                  else{
                    let customerClubService = new Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
                    let foundCustomer = searchResults.result.customers[0];

                    if(!posUtils.isNullOrUndefinedOrEmptyString(customerName)){
                      if(customerName.indexOf(" ") == -1){
                        foundCustomer.first_name = customerName;
                        foundCustomer.last_name = "";
                      }
                      else{
                        foundCustomer.first_name = customerName.substr(0,customerName.indexOf(" "));
                        foundCustomer.last_name = customerName.substr(customerName.indexOf(" ")+1);
                      }
                    }

                    await customerClubService.setCurrentSelectedCustomer(convertToPosStructure.call(this,foundCustomer));
                    posVC.saleUpdated()

                    this.customerName = i18next.t("caveretPayment.customer") + ":" + customerCode + " " + customerClubService.getCustomerShortDisplayName()
                    return true;
                  }
                }
                else{
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPayment.caveretAndLocalCustomerMismatch'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });
                }

                return false;
              },
              openClubChooserDialog(){
                this.clubChooserOpen = true;                
              },
              chooseClub(club){
                this.selectedClub = club
                this.clubChooserOpen = false
                if(club.defaultCard){
                  this.card = club.defaultCard
                  this.findCustomer();
                }
              },
              cancel() {
                this.budgets = [];
                this.card = "";
                this.pinCode = "0000";
                this.selectedClub = null;
                let customerClubService = new Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
                posVC.externalPromotions = [];
                let updatedSaleItems = posVC.updatePromotions()
                customerClubService.clearCustomer();
                posVC.saleUpdated(updatedSaleItems);
                close();
              },
              cleanData(){
                this.budgets = [];
                this.card = "";
                this.pinCode = "0000";
              }
            },
            data: _initData,
        }
        VueApp.component(_vueComponentName,caveretPaymentCustomerPicker)
      }

    
      function open(){
        this.cleanData();
        let caveretClubs = jsonConfig.getVal(jsonConfig.KEYS.caveretPaymentClubs)
        if(!_.isEmpty(caveretClubs) && !this.selectedClub){
          this.clubs = caveretClubs;
          if (this.clubs.length == 1){
            this.selectedClub(this.clubs[0])
          }
          else{
            this.openClubChooserDialog();
          }
        }

        this.totalAmount = posVC.getTotalLeftToPay();
        posPaymentVC.init()
        if(posVC.saleItems.length > 0){
          posPaymentVC.updateAmountsIndicators()
        }
        

        this.paymentsExists = posVC.salePayments.length > 0;
        
        let saleJson = JSON.parse(posVC.sale.jsondata)
        this.caveretCustomerExists = saleJson.customer && saleJson.customer.selectedBudget;

        let aThis = this;
        document.getElementById('caveretPaymentCustomerSearch-keyboard-icon').onclick = function(){
          aThis.manualInput();
        } 
        $(function() {
          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: '82%',
            height: 466,
            resizable: false,
            closeOnEscape: false,
            draggable: false,
            position: 'center'
          });
        $(_dialogSelector).dialog('open');
        });
    
    
        $(document).unbind('keypress');
        if (this.$refs.caveretPaymentsearchTextInput) {
          this.$refs.caveretPaymentsearchTextInput.focus();
        }
      }
    
      function _initData(){
        return {
          budgets: [],
          card: null,
          pinCode: "0000",
          saleCustomerPoints: null,
          customerName: "",
          promotionApplied: false,
          inputMethod: 4, 
          totalAmount: 0,
          allowOtherPayments: false,
          lastKeypressTimestamp: 0,
          paymentsExists: false,
          caveretCustomerExists: false,
          clubChooserOpen: false,
          clubs: {},
          selectedClub: null
        };
      }
    
      function close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }
    }}}
