module PositiveTS {
    export module Components {
        export module SelfServiceKeypad {
            export function getComponent() {
                return {
                    template: JST.SelfServiceKeypad(),
                    props: {
                        placeHolder: {
                            type: String,
                            required: false,
                            default: i18next.t('selfService.cardNumber'),
                        },
                        displayInputField: {
                            type: Boolean,
                            required: false,
                            default: true,
                        },
                        modelValue: {
                            default: undefined,
                            required: false,
                        },
                        loading: {
                            type: Boolean,
                            default: false,
                            required: false,
                        },
                        allowZero: {
                            type: Boolean,
                            default: false,
                            required: false,
                        },
                        clearOnFirstDigit: {
                            type: Boolean,
                            default: false,
                        },
                    },
                    data() {
                        return {
                            numberString: '',
                            isFirstDigit: true,
                        }
                    },
                    computed: {
                        useModelVal() {
                            return this.modelValue !== undefined;
                        },
                        isCardNumSet() {
                            if(this.allowZero) return true;
                            return this.numberString || this.modelValue;
                        },
                        inputEmpty() {
                            return !this.isCardNumSet;
                        },
                        inputReadonly() {
                            return !posUtils.isDevelopment && !posUtils.isStaging;
                        },
                    },
                    methods: {
                        enterDigit(digit: string) {
                            if (this.useModelVal) {
                                if (this.clearOnFirstDigit && this.isFirstDigit) {
                                    this.$emit('update:modelValue', digit);
                                    this.isFirstDigit = false;
                                }else{
                                    let newVal = this.modelValue + digit;
                                    this.$emit('update:modelValue', newVal);
                                }
                            } else {
                                this.numberString += digit;    
                            }
                        },
                        backspace() {
                            if (this.useModelVal) { 
                                let newVal = this.modelValue.substr(0, this.modelValue.length - 1);
                                this.$emit('update:modelValue', newVal);
                            } else {
                                this.numberString = this.numberString.substr(0, this.numberString.length - 1);
                            }
                        },
                        submit() {
                            if (this.useModelVal) { 
                                this.$emit('submit', this.modelValue);
                            } else {
                                this.$emit('submit', this.numberString);
                            }
                            
                        },
                        clear(){
                            if (this.useModelVal) { 
                                this.$emit('update:modelValue', '');
                            } else {
                                this.numberString = '';
                            }
                            this.isFirstDigit = true;
                        }
                    },
                    async created() { },
                }
            }
        }
    }
}
