module PositiveTS {
  export module Components {
    export module SelfServiceGoodi {
      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreen(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.selfServicePaymentMixin("selfService.goodi", true, {type: Mixins.ICON_TYPES.POSITIVE_ICON, name: 'goodi'})],
          methods: {
            async paymentAction() {
              let totals = Helper.SaleHelper.calcuateSaleTotals(
                posVC.sale,
                posVC.saleItems,
                posVC.salePayments
              );
              let amount = session.fixedFloat(
                totals.totalAmount - totals.totalPaid
              );
              let cardNumber = await this.getPaymentCardNumber(amount);
  
              if (!posUtils.isNullOrUndefinedOrEmptyString(cardNumber)) {
                this.paymentInProcess = true;
                let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(
                  Storage.Entity.Voucher.SMART_VOUCHER_GOODI,
                  amount,
                  cardNumber
                );
                this.paymentInProcess = false;
                if (response.success) {
                  this.goToNextStep();
                } else {
                  console.error(response.error);
                  app.showAlertDialog({
                    header: i18next.t("error"),
                    content: response.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });
                  this.setCurrentStep("SelfSelectPaymentMethod");
                }
              } else {
                this.setCurrentStep("SelfSelectPaymentMethod");
              }
            },
          }
        }
      }
    }
  }
}
