module PositiveTS {
  export module Components {
    export module AddItemDialog {
      const dialogSelector = "add-item-dialog"

      function initData() {
        return {
          item: {
            code: '',
            name: '',
            priceZarhan: 0,
            hasWeight: false, 
            noVat: false,
            primaryCategoryId: 0,
          },
        }
      }

      export function create() {

        let component = {
          template: JST.addItemDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            open(searchText = null) {
              if (!this.isOnline && !session.pos.isRoshemet) {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('mustBeOnline'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
                return Promise.resolve();
              }

              this.cleanData();
              $(document).unbind('keypress');

              if (posUtils.isDefined(searchText)) {
                if (posUtils.isNumeric(searchText)) {
                  this.item.code = searchText;
                } else {
                  this.item.name = searchText;
                }
              }

              return new Promise((resolve, reject) => {
                this.$el.show();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            async confirmAndClose(returnItem = false) {
              let itemObj = null;
              try{
                let newItem = await Service.AddItem.createItem(this.item);
                if (posUtils.isBlank(newItem)) {
                  return;
                }
                
                if (returnItem) {
                  itemObj = new Storage.Entity.Item();
                  itemObj.importFromObject(newItem)
                }

                this.resolveFunc(itemObj);
                return this.close();
              }catch(e){
                console.error(e)
                this.showError(i18next.t('addItemDialog.generalError'))
              }
            },
            cancel() {
              this.resolveFunc()
              this.close();
            },
            showError(errStr) {
              app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              });
            },
            getPrimaryCategories() {
              return this.isRoshemet ? [] : (new Storage.Entity.PrimaryCategory()).allWithoutPromise();
            }
          },
          data: initData,

          computed: {
            isRoshemet(){
              return session.pos.isRoshemet
            },
            isOnline(){
              return Pinia.globalStore.isOnline
            },
          }
        }

        VueApp.component('add-item-dialog', component)

      }
    }
  }
}
