module PositiveTS {
    export module Components {
        export module SelfServiceAlignScreen {
            export function getComponent() {
                let selfServiceAlignScreen =  {
                    template: JST.selfServiceAlignScreen(),
                    mixins: [Mixins.selfServicePaymentMixin('')],
                    data() {
                        return {
                            storeName: session?.store?.storeName || '',
                            title: jsonConfig.getVal(jsonConfig.KEYS.alignSelfServiceTitle),
                            subtitle: jsonConfig.getVal(jsonConfig.KEYS.alignSelfServiceSubtitle),
                        }
                    },
                    methods: {
                        async goToPay (paymentType) {
                            await this.createSaleToPay()
                            if (paymentType == 'cibus'){
                                this.setCurrentStep("SelfServiceCibus")
                            }

                            if (paymentType == 'creditCard'){
                                this.setCurrentStep('SelfServiceEmv')
                            }
                        },

                        async createSaleToPay () {
                            if (posVC.saleItems.length > 0){
                                posVC.saleItems = []
                            }

                            let lunchItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.alignSelfServiceLunchItem))
                            let lunchSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(lunchItem, {size:'null',color:'null',barcode:lunchItem.itemCode})
                            lunchSaleItem.rowNumber = posVC.getRowNumber()
                            lunchSaleItem.saleID = posVC.sale.id
                            lunchSaleItem.priceNetoAfterDiscounts = lunchSaleItem.unitPrice
                            posVC.saleItems.push(lunchSaleItem)

                            posVC.sale.totalQuantity = '1'
                            posVC.sale.totalAmount = lunchSaleItem.unitPrice
                            posVC.sale.totalVatableAmount = lunchSaleItem.unitPrice

                            await PositiveTS.Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
                        },

                    },
                }

                return selfServiceAlignScreen
            }
        }
    }
}

