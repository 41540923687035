module PositiveTS {
	
	export module Service {

		export module DailyZReport {
            const localStorageKey = 'LastDailyZReportRunAt'
         
            export function isNeedToRunZReport () {
                let now = moment()

                let time = jsonConfig.getVal(jsonConfig.KEYS.dailyZReportTime)
                if (posUtils.isBlank(time)) {
                    return false

                }

                let lastRun = getLastRunAt() || moment(new Date()).subtract(1,'days').toDate() //handle edge use case of first run
                let lastRunAt = moment(lastRun)
                

                //needToRunZAt - if the current hours and minutes now are smaller than target time - we take the date of yesterday
                //if they are bigger - we take the date of today
                let targetHour = parseInt(time.split(":")[0])
                let targetMinute = parseInt(time.split(":")[1])
                let currHour = parseInt(now.format("HH"))
                let currMinute = parseInt(now.format("mm"))
                let daysToReduce = 0
                if (currHour < targetHour || (currHour == targetHour && currMinute < targetMinute)) {
                    daysToReduce = 1
                }

                let needToRunZAt = moment().hours(targetHour).minutes(targetMinute).seconds(0).subtract(daysToReduce,'days')
                if (lastRunAt >= needToRunZAt) { //we already run the z today - exit
                    return false 
                }
                else if (now >= needToRunZAt) {
                    return true
                }
                
                return false
            }

            export function setInterval () {
				if (!jsonConfig.getVal(jsonConfig.KEYS.dailyZReportTime)) {
					return
                }
                
				window.setInterval(() => {
					run()
				}, 1000 * 60 * 5)
            }
            
            export function run () {
                if (isNeedToRunZReport()){
                    runZReport()
                }
            }

            export function runZReport () {
                try {
                    setLastRunAt(moment().toDate().toString())
                    PositiveTS.Service.ZReport.openZReport(false) 
                }catch(e){
                    console.error(e)
                }
            }

            export function setLastRunAt (rununAt: string) {
                localStorage.setItem(localStorageKey, rununAt)
            }

            export function getLastRunAt () {
                return localStorage.getItem(localStorageKey)
            }          
        }
    }
}
