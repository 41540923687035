module PositiveTS {
   export module Service {
      export module DalpakInfra {
         export class DalpakWebsocketWithLocalEngine extends DalpakEngine implements WebsocketSync.PrimaryPosOfflineManager {

            static DISCONNECT_ACTIONS = {
               CANCEL: 0,
               TRY_AGAIN: 1,
               MOVE_TO_OFFLINE: 2,
            }

            localEngine: DalpakLocalEngine;
            websocketEngine: DalpakWebsocketEngine;
            activeEngine: DalpakEngine;
            onDisconnectedButtons: any[];
            dataRecieved: boolean = false;
            waitForActionToFinishPromise = Promise.resolve();

            constructor(isPrimaryPos: boolean) {
               super();
               this.websocketEngine = new DalpakWebsocketEngine(isPrimaryPos, this);
               this.localEngine = new DalpakLocalEngine(true);

               if (localStorage.getItem('dalpaksIsOffline') == 'true') {
                  this.activeEngine = this.localEngine;
               } else {
                  this.activeEngine = this.websocketEngine;
               }
               this.onDisconnectedButtons = [
                  { text: i18next.t('tryAgain'), class: 'green', value: DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.TRY_AGAIN },
                  { text: i18next.t('dalpaks.moveToOffline'), class: 'yellow', value: DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.MOVE_TO_OFFLINE },
                  { text: i18next.t('cancel'), class: 'red', value: DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.CANCEL },
               ];
            }

            async syncDataFromPrimary(): Promise<boolean> {
               let dataToSync = await this.getDataToSync();
               if (dataToSync && dataToSync.dalpaks) {
                  let dalpakAreasRes = null
                  let res = await this.websocketEngine.primaryPosSyncToServer(dataToSync.dalpaks)

                  if(res.success) {
                     dalpakAreasRes =  await this.websocketEngine.primaryPosSyncDalpakAreasToServer(dataToSync.dalpakAreas)
                  }
                  return res.success && (dalpakAreasRes && dalpakAreasRes.success);
               }

               return true;
            }

            async getDalpak(dalpakId: string): Promise<InfraResult<Dalpak>> {
               return await this.handleAction(() => this.activeEngine.getDalpak(dalpakId));
            }
            async getDalpakArea(dalpakAreaId: string): Promise<InfraResult<DalpakArea>> {
               return await this.handleAction(() => this.activeEngine.getDalpakArea(dalpakAreaId));
            }
            async getAndLock(dalpakId: string): Promise<InfraResult<Dalpak>> {
               return await this.handleAction(() => this.activeEngine.getAndLock(dalpakId));
            }
            async getAll(): Promise<InfraResult<Dalpak[]>> {
               return await this.handleAction(() => this.activeEngine.getAll());
            }
            async getAllDalpakAreas(): Promise<InfraResult<Dalpak[]>> {
               return await this.handleAction(() => this.activeEngine.getAllDalpakAreas());
            }
            async saveDalpakAttributesAndUnlock(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.handleAction(async () => {
                  if (this.isLocalEngineActive() && posUtils.isBlank(dalpak.id)) {
                     dalpak.syncWithoutId = true;
                  }
   
                  return await this.activeEngine.saveDalpakAttributesAndUnlock(dalpak)
               });
            }
            async saveDalpakAreaAttributes(dalpakArea: DalpakArea): Promise<InfraResult<DalpakArea>> {
               return await this.handleAction(async () => {
                  return await this.activeEngine.saveDalpakAreaAttributes(dalpakArea)
               });
            }
            async markSaleAsPrinted(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.handleAction(() => this.activeEngine.markSaleAsPrinted(dalpak));
            }
            async saveSaleAndUnlock(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.handleAction(() => this.activeEngine.saveSaleAndUnlock(dalpak));
            }
            async saveSale(dalpak: Dalpak, unlock: boolean): Promise<InfraResult<Dalpak>> {
               return await this.handleAction(() => this.activeEngine.saveSale(dalpak, unlock));
            }
            async deleteDalpak(dalpak: Dalpak): Promise<InfraResult<boolean>> {
               return await this.handleAction(() => this.activeEngine.deleteDalpak(dalpak));
            }
            async deleteDalpakArea(dalpakArea: Dalpak): Promise<InfraResult<boolean>> {
               return await this.handleAction(() => this.activeEngine.deleteDalpakArea(dalpakArea));
            }
            async unlock(dalpak: Dalpak): Promise<InfraResult<boolean>> {
               return await this.handleAction(() => this.activeEngine.unlock(dalpak));
            }

            async bulkUpdateDalpaksAndAreasAttributes(data: Dalpak[]): Promise<InfraResult<boolean>> {
               return await this.handleAction(() => this.activeEngine.bulkUpdateDalpaksAndAreasAttributes(data));
            }

            
            async bulkPut(data: Dalpak[]): Promise<InfraResult<boolean>> {
               // websocket because need always to create on the websocet
               if (this.isOnlineEngineActive()) {
                  return await this.handleAction(() => this.activeEngine.bulkPut(data));
               }

               return {
                  success: false,
                  data: null,
                  errorMessage: i18next.t('dalpaks.errorConnectingToDalpakServer'),
               };
            }
            async bulkPutDalpakAreas(data: DalpakArea[]): Promise<InfraResult<boolean>> {
               // websocket because need always to create on the websocet
               if (this.isOnlineEngineActive()) {                  
                  return await this.handleAction(() => this.activeEngine.bulkPutDalpakAreas(data));
               }

               return {
                  success: false,
                  data: null,
                  errorMessage: i18next.t('dalpaks.errorConnectingToDalpakServer'),
               };
            }

            async isCurrentDataValid(): Promise<InfraResult<boolean>> {
               return await this.handleAction(() => this.activeEngine.isCurrentDataValid());
            };

            async moveDalpaksToArea(dalpaks: Dalpak[], newDalpakAreaRailsId: string): Promise<InfraResult<boolean>>  {
               return await this.handleAction(() => this.activeEngine.moveDalpaksToArea(dalpaks,newDalpakAreaRailsId ));
            }

            isDataAlwaysUpToDate(): boolean {
               return this.activeEngine.isDataAlwaysUpToDate();
            }

            onConnectionEstablished() {
               localStorage.setItem('dalpaksRecieved', 'true');
            }

            onConnectionCompleted() {
               // Closes the dialog if needed
               PositiveTS.VueInstance.$refs.multiButtonDialog.close(DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.TRY_AGAIN, 'dalpakSyncDisconnectedMessage');
            }


            async getDataToSync() {
               if (this.isLocalEngineActive()) {
                  await this.waitForActionToFinishPromise;
               }


               if (localStorage.getItem('dalpaksRecieved') == 'true' && localStorage.getItem('dalpaksIsOffline') == 'true') {
                  let dataToSync = {
                     dalpaks: null,
                     dalpakAreas : null
                  };
                  let dalpaks = (await this.localEngine.getAll());
                  let areas = (await this.localEngine.getAllDalpakAreas())

                  if (dalpaks.success) {
                     dataToSync.dalpaks = dalpaks.data;
                  } 

                  if (areas.success) {
                     dataToSync.dalpakAreas = areas.data;
                  } 

                  // remove local id for the new temp dalpaks
                  dataToSync.dalpaks = dataToSync.dalpaks.map(d => {
                     if (d.syncWithoutId) {
                        delete d.id;
                     }

                     delete d.syncWithoutId;

                     return d;
                  })

                  dataToSync.dalpakAreas = dataToSync.dalpakAreas.map(d => {
                     delete d.id;

                     return d;
                  })

                  console.log('SYNCING DALPAKS AND DALPAKAREAS', dataToSync);
                  return dataToSync;
               } else {
                  return null;
               }
            }

            moveToOnline() {
               this.activeEngine = this.websocketEngine;
               console.log('Working with websocket dalpaks (Online mode)');
               localStorage.removeItem('dalpaksIsOffline');
            }

            moveToOffline() {
               this.activeEngine = this.localEngine;
               console.log('Working with local dalpaks (offline mode)');
               localStorage.setItem('dalpaksIsOffline', 'true');

               this.syncFailedDeliveriesIfNeeded();
            }

            protected isOnlineEngineActive() {
               return this.activeEngine == this.websocketEngine;
            }

            protected isLocalEngineActive() {
               return this.activeEngine == this.localEngine;
            }

            private async handleAction(action: () => Promise<InfraResult<any>>): Promise<InfraResult<any>> {
               let result;

               // wait for last action to finish if needed
               await this.waitForActionToFinishPromise;


               // set the current action
               this.waitForActionToFinishPromise = new Promise(async resolve => {
                  result = await action();

                  let shouldTryAgain = true;

                  // If sync server is offline
                  while (Pinia.globalStore.syncServerOnlineState != WebsocketSync.SyncServerClient.CONNECTION_STATUSES.CONNECTED &&
                     this.isOnlineEngineActive() &&
                     result.success == false &&
                     shouldTryAgain) {
                     let wantedAction = await Pinia.componentsStore.openComponent( {componentName:"multiButtonDialog", args: [this.onDisconnectedButtons, i18next.t('dalpaks.errorConnectingToDalpakServer'), 'dalpakSyncDisconnectedMessage']});

                     // if still offline and not became online till user answer
                     if (Pinia.globalStore.syncServerOnlineState == WebsocketSync.SyncServerClient.CONNECTION_STATUSES.CONNECTED) {
                        result = await action();
                     } else {
                        switch (wantedAction) {
                           case DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.CANCEL:
                              shouldTryAgain = false;
                              break;
                           case DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.TRY_AGAIN:
                              result = await action();
                              break;
                           case DalpakWebsocketWithLocalEngine.DISCONNECT_ACTIONS.MOVE_TO_OFFLINE:
                              this.moveToOffline();
                              result = await action();
                              break;
                        }
                     }
                  }

                  resolve();
               });

               await this.waitForActionToFinishPromise;

               return result;
            }
         }
      }
   }
} 
