module PositiveTS {
export module Service {
export module Quicksale {

  const quicksaleItemCode = Shared.Constants.Item.GENERIC_ITEM;

  export function addItem(price:number):Promise<void>{
    if (price === 0 ){return Promise.resolve();}

    return _addSaleitem(price)
    .then( ()=>{
      return posVC.saleUpdated();
    });
      
  }


  function _addSaleitem(price:number):Promise<PositiveTS.Storage.Entity.SaleItem>{
    var itemCode = quicksaleItemCode;

    var item = new PositiveTS.Storage.Entity.Item();
    return item.searchByBarcode(itemCode)
    .then( (itemsAndBarcodes) => {

      if (itemsAndBarcodes.length !== 1){
        throw new Error(`Bad POS configuration, no itemCode ${itemCode}`)
      }

      // Pick the first item and get the item and its barcode
      var item:PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
      var itemBarcode = itemsAndBarcodes[0].itemBarcode;

      // Create new sale item entity
      item.priceZarhan = price;
      var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
      saleItem.saleID = posVC.sale.id;
      saleItem.rowNumber = posVC.getRowNumber();
      saleItem.quantity = 1;
      
      

      return posVC.persistNewSaleItem(saleItem);

    });
  }

}}}