module PositiveTS {
  export module Service {

  
    export class ValueCard extends SmartVoucher {
      hasCVV = false

      // on error return success: false
      // balance 0
      // error "what ever in hebrew"
      getBalance(cardNumber: string, cvv: string = ""): Promise<GetBalanceResponse> {
        return PositiveTS.Service.ValueCardService.getCardBalance(cardNumber)
        .then( (result )=>{
          return {
            balance: result,
            error: "",
            success: true,
          };
          
        })
        .catch((e:Error)=>{
          return  ({success: false,
          balance: 0,
          error: e.message
                });
      });
    }


      pay(voucherData: any, amount: number, cvv?: string, companyId?: string): Promise<any> {
        let cardNumber = voucherData.barCode;

        let saleItems = posVC.saleItems;

        if(!session.pos.useNewPromotions) {
          saleItems = Storage.Entity.SaleItem.setPriceNetoAfterDiscountsForOldPromotionsItems(posVC.sale, saleItems)
        }
  

        return PositiveTS.Service.ValueCardService.getValuecardPayment(cardNumber,amount, saleItems) 
         .then((response) => {

            voucherData.barCode = cardNumber
            voucherData.actionType = SmartVoucherActionTypes.WITHDRAW
            voucherData.allowPartialReturn = false
            voucherData.amount = amount - response.discount;
            voucherData.discount = response.discount;
            voucherData.orderId = response.transactionId;
            voucherData.companyId = companyId || ""
            voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALUE_CARD
            voucherData.actionReference = response.transactionId;
            voucherData.printMessage = response.printMessage;


            return PositiveTS.Service.ValueCardDiscountItemService.addDiscount(voucherData.discount)
            .then( ()=>{
              return voucherData;
            }) 
          })
      }

      cancelPayment(voucherToCancel: any): Promise<ActionResponse> {
        return PositiveTS.Service.ValueCardService.getVoidTransaction(voucherToCancel.barCode, voucherToCancel.orderId)
        .then( response=>{
          return PositiveTS.Service.ValueCardDiscountItemService.addDiscount( (voucherToCancel.discount) * -1 )
          .then( ()=>{
            return {
                success: true,
                error: ""
              }            
          })

        })
        .catch((e:Error) => {
            return ({ success: false, error: e.message });
         })

      }

      cancelLoad(paymentToCancel): Promise<any> {
         var currentPayment;
         paymentToCancel = paymentToCancel[0];
         return PositiveTS.Service.ValueCardService.getVoidTransaction(paymentToCancel.barCode,
                  paymentToCancel.actionReference)
          .then((response) => {
            currentPayment = new PositiveTS.Storage.Entity.SalePayment()
            currentPayment.amount = 0
            currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VALUE_CARD;
            paymentToCancel.amount = -Math.abs(paymentToCancel.amount)
            paymentToCancel.actionType = SmartVoucherActionTypes.CANCEL_DEPOSIT
            paymentToCancel.actionReference = response.transactionId;
            paymentToCancel.printMessage = response.printMessage;
            currentPayment.data = JSON.stringify([paymentToCancel])
            return currentPayment;
          })

      }

       // This is UI input box returns card number scan
      getCardNumber() {
        return new Promise<GetCardNumberResponse>((resolve, reject) => {
          return this._getInputCardScan()
            .then(cardNumber => {
            resolve({
              cardNumber: cardNumber,
              options: {}
            })
          })
        })
      }

      private _getInputCardScan() {
        let options = new PositiveTS.Dialogs.InputDialogOptions()
        options.header = 'הזן כרטיס לטעינה'
        options.description = 'סרוק כרטיס לטעינה'
        options.inputPlaceHolder = 'סרוק כרטיס לטעינה'
        options.showCancelButton = false
        options.emptyErrorMessage = 'סרוק כרטיס לטעינה'
        options.keyboardLayout = "hebrew-qwerty-custom",

        options.inputValidator = function() {
          return true;
        }
        return inputDg.open(options)
      };


      loadCard(cardNumber: string, amount: number, options?: any): Promise<SmartVoucherPaymentData> {
        return PositiveTS.Service.ValueCardService.getLoadBudgetQuery(cardNumber,amount)
        .then( (response) => {
          var extResponse:any = response;
          extResponse.amount = response.loadSum;
          extResponse.actionType =  SmartVoucherActionTypes.DEPOSIT;
          extResponse.actionReference = response.transactionId;
          extResponse.barCode = response.cardNumber;

          var ret:SmartVoucherPaymentData = {
            amount: 0,
            data: JSON.stringify([extResponse]),
            method: PositiveTS.Storage.Entity.SalePayment.METHOD_VALUE_CARD,
          };

          return ret;

        })


       }

    }
  }
}
