export const translation = {

            zReport: {
              title: "דוח Z",
              sessionNumber: 'אסמכתה',
              printTransmitReport: 'הדפס דוח שידור',
              dbOrDiskError: "שגיאה בעת גיבוי מכירות - לא ניתן להמשיך. אנא נסו שוב להריץ דוח Z. במידה והבעיה חוזרת על עצמה - חובה לפנות לתמיכה.",
              openDeliveryOrders: "הזמנות משלוח פתוחות",
              openDeliveryOrdersWarning: "לא ניתן לסגור דוח Z עם הזמנות פתוחות, האם ברצונך לסגור אותן?",
              closeOrders: "סגור הזמנות",
              cancel: "ביטול",
              payAttention: "שים לב!",
              currentlyInDeliveryCash: `ש"ח נמצאים כרגע במזומן משלוחים`,
              failedToTransmit: "שים לב - שידור עסקאות האשראי לשבא נכשל! אנא בדוק את התקשורת בקופה ונסה להריץ שוב דוח זד.\nבמידה והבעיה ממשיכה - חובה לפנות לתמיכה.",
              areYouSure: "האם לבצע דו\"ח Z ולסגור את הקופה?",
              salesOnHoldError: "לא ניתן לסגור את הקופה כל עוד קיימות מכירות בהשהייה",
              newSaleHasPaymentsError: "לא ניתן לסגור דוח Z שיש מכירה עם אמצעי תשלום פעילים",
              lastPosSequenceNotFound:"המספר עוקב של הZ של הקופה לא נמצא",
              cashPaymentsDisplay: "עסקאות מזומן",
              cashPaymentsDisplayDalpakim: "עסקאות מזומן ללא משלוחים",
              zStatement: "הצהרת סגירה",
              roundTotals: "סה\"כ עיגולים",
              sendingTranBackups: "משדר גיוביי תנועות אשראי לשרת",
              sendingTranBackupsError: "הזהרה! הזד נסגר בצורה תקינה אך גיבויי תנועות האשראי לא גובו בשרת. נא לצור קשר עם התמיכה הטכנית.",
              tamashTitle: "תעודת משלוח",
              tamashTotal: "סכום",
              newZ: "Z חדש",
              restoreZ: "שחזור Z קיים",
              promptGenerateNewReport: "האם לאשר הפקת Z חדש?",
              show: "הצגה",
              sendEmail: "שלח במייל",
              checkForActiveEmployees: "בודק אם ישנם עובדים שביצעו כניסה ולא ביצעו יציאה",
              paraxelCardLoading: "פרקסל טעינת כרטיסים",
              insideCardLoading: "טעינת כרטיס פנימי",
              cellularCard: "טעינת תו סלולארי",
              multipassCardsLoading: "מולטיפס טעינת כרטיסים",
              valuCardLoading: "טעינת ואליו-כארד",
              ototLoading: "טעינת צמיד",
              cashWIthdrawal: "משיכת מזומנים",
              forceZReport: 'לא בוצע דו"ח Z ביממה האחרונה, נא להוציא דו"ח Z על מנת להמשיך לעבוד',
              tipsTotal: 'סה"כ טיפים',
              doAllZTitle: 'האם תרצה לסגור את כל הקופות בחנות הנוכחית?',
              doAllZBigTitle : 'הפקדת דוח Z מרוחק',
              leumiCard: `סה"כ לאומי קארד :`,
            },
            ZReportAllProgressDialog: {
              title: 'התקדמות דו"ח Z לכל הקופות בחנות',
              posID: 'מזהה קופה',
              status: "מצב",
              message: "הודעה",
              Remaining: "קופות נותרו",
              Offline: "קופות מנותקות",
              Success: "קופות סגורות בהצלחה",
              Error: "קופות עם שגיאות",
              Printed: "דוחות שהודפסו",
              noChannels: "לא נמצאו קופות עם מכירות להפקת דוח Z מרוחק.",
              ContinueToInitatorZ: "המשך להפקת דוח לקופה זו",
              fetchingPOS : "מחפש קופות",
              noSales: 'לא בוצעו מכירות בקופה זו',
              Table: {
                finished: "סיים",
                finished_with_error: "התקבלה שגיאה",
                started: "בתהליך",
                offline: "מנותק",
                pending: "ממתין",
                printed: "דוח הודפס",
              }
            },
            itemUnits: {
              unit: "יח'",
              kilo: 'ק"ג'
            },
            addon: {
              networkError: "שגיאת תקשורת",
              versionIncorrect: "שגיאה בתוסף/גרסת אנדרואיד - במידה וזו קופת windows, אנא וודא שהתוסף מותקן ומעודכן לגרסה אחרונה. בקופת אנדרואיד, אנא וודא שגרסת האפליקציה מעודכנת."
            },
            currInvReport: {
              title: "דוח מלאי נוכחי",
              itemName: "תאור",
              barcode: "ברקוד",
              department: "מחלקה:",
              currInv: "מלאי",
              withoutDepartment: "פריטים ללא מחלקה",
              generatingReport: "מפיק דוח מלאי",
              error: "ארעה שגיאה בעת הפקת דוח מלאי - אנא נסה שנית.\nבמידה והבעיה ממשיכה יש לפנות לתמיכה",
              totalAll: 'סה"כ מלאי לכל הפריטים:',
              totalDep: 'סה"כ מלאי למחלקה:',
            },
            itemManage: {
              name: "שם",
              price: "מחיר",
              inventory: "מלאי",
              title: "ניהול פריטים",
              departments: "מחלקות",
              department: "מחלקה",
              addItem: "הוספת פריט",
              addItemTitle: "הוספת פריט",
              itemName: "תאור פריט",
              barcode: "ברקוד",
              consumerPrice: "מחיר לצרכן",
              availableInventory: "מלאי זמין",
              hasWeight: "פריט שקיל",
              noVat: 'פריט ללא מע"מ',
              locked: 'פריט נעול',
              allowPriceChange: "אפשר שינוי מחיר",
              allowNameChange: "אפשר שינוי שם",
              saveAndExit: "שמור וסגור",
              exitWithoutSave: "צא ללא שמירה",
              edit: "עריכה",
              back: "חזרה",
              actions: "פעולות",
              departmentMustBeSelected: "חובה לבחור מחלקה",
              noDepartmentsExist: "לא קיימות מחלקות - יש להקים מחלקה ממסך מחלקות",
              updateInventory: "עדכון מלאי",
              addToInventory: "הוסף למלאי",
              currentInventory: "מלאי נוכחי",
              updateInventoryMessage: "אנא הזן מלאי חדש",
              updateInventoryPlaceholder: "מלאי חדש",
              addToInventoryMessage: "אנא הזן מלאי שהתווסף",
              addToInventoryPlaceholder: "מלאי שהתווסף",
              pleaseEnterAValue: "מלאי לא יכול להיות ריק",
              savingItem: "שומר פריט",
              searchItem: "חיפוש פריט",
              withoutDepartment: "ללא מחלקה",
              itemState: "הצג פריטים:",
              cannotSaveItem: "לא ניתן לשמור פריט, אנא בדוק את הערכים שהוזנו",
              nameCannotBeEmpty: "חובה להזין שם",
              barcodeAlreadyExists: "לא ניתן לשמור פריט - הברקוד של פריט זה כבר קיים לפריט אחר בקטלוג הפריטים",
              importFromFile: "טעינה מקובץ",
              exportToFile: "גיבוי לקובץ",
              exportTitle: "גיבוי פריטים",
              exportSuccess: "הפריטים נשמרו בהצלחה",
              importingFileAndDep: "טוען פריטים ומחלקות...",
              loadingFile: "טוען קובץ...",
              prev: "קודם",
              next: "הבא",
              loadingFileConfirmation: "האם אתה בטוח שברצונך לטעון קובץ פריטים?",
              cannotEditGenericItem: "פריט כללי הינו פריט מערכת ואינו ניתן לעריכה",
              promotions: 'מבצעים',
              hideFromMenu: 'אל תציג פריט בתפריט',
              showInMainMenu: 'הצג פריט בתפריט ראשי',
              allowChangingName: 'אפשר שינוי שם לפריט',
              mustBePrice0ToChangeName: 'פריט חייב להיות על מחיר 0 בשביל לאפשר שינוי שמו',
              bonPrinterSelect: "מדפסת בונים",
              menuItemSortOrder: "מיקום בתפריט (מס' נמוך יותר יופיע קודם)"
            },
            shutdown: {
              poweroff: "כיבוי",
              restart: "הפעלה מחדש",
              logout: "התנתקות",
              back: "חזרה",
              title: "אפשרויות כיבוי"
            },
            externalOrdersDifferences: {
              title: "הפרשים בהזמנה",
              warning: "שים לב - ישנם הבדלים בין מה שסופק למה שהוזמן",
              barcode: "ברקוד",
              name: "שם",
              quantity: "כמות",
              markAs: "סמן כ-",
              marked: "סומן",
              missing: "חסר",
              alterior: "חליפי",
              item: "מוצר",
              missingItemsWarning: "פריטים בהזמנה שלא קיימים בקופה"
            },
            externalOrdersList: {
              title: "הזמנות אינטרנט",
              loading: "טוען הזמנות...",
              errorLoading: "חלה שגיאה בטעינת ההזמנות מהשרת",
              status: "סטטוס",
              type: "סוג משלוח",
              searchByText: "מספר הזמנה/שם לקוח",
              internetOrders: "הזמנות\nאינטרנט",
              internetError: "הקופה לא מצליחה להתחבר לשרת. נא לוודא שהחיבור לאינטרנט תקין.  במקרה שהאינטרנט עובד תקין יש ליצור קשר עם התמיכה",
              chooseAction: "בחר פעולה",
              moreActions: "פעולות נוספות",
              inProgress: "בטיפול",
              markAsNew: "סמן כחדש",
              readyForShipping: "מוכן למשלוח",
              errorChangingStatus: "חלה שגיאה בעידכון הסטאטוס. נא ליצור קשר עם התמיכה",
              scanItems: "העבר פריטים בקופה",
              thereAreSaleItemsInPos: "יש פריטים שנסרקו בקופה. יש לנקות קודם את המכירה.",
              printItems: "הדפס פריטים",
              customer: "לקוח",
              orderNum: "הזמנה מס",
              cancelOrder: "בטל הזמנה",
              errorInCancel: "חלה שגיאה בביטול",
              cancellingOrder: "מבטל הזמנה",
              statuses: {
                1: "חדש",
                2: "בטיפול",
                3: "נמחק",
                4: "מוכן לשליחה",
              },
              deliveryType: {
                "TA": "איסוף עצמי",
                "Delivery": "משלוח",
                "default": "לא ידוע"
              },
              lookingForInvoiceInServer: "מאתר חשבונית..."
            },
            delivery: {
              failedDeliveriesExists :'ישנן הזמנות שנכשלו בקליטה',
              deliveryErrorExists: 'שים לב, אירעה שגיאה בקבלת חלק מההזמנות. קיימות הזמנות שאינם מופיעות במערכת. אנא פנה לתמיכה ע"מ לפתור תקלה זו',
              totalOrders: 'כמות הזמנות',
              showDeliveries: 'משלוחים',
              showTa: 'TA',
              showAll: 'הכל',
              order: 'הזמנה',
              deliveryTimeTitle: "מועד אספקה",
              partialOrdersExists: 'קיימות הזמנות פתוחות ללא תשלום מלא, יש לסגור את כל ההזמנות לפני ביצוע Z',
              subtotal: 'סה"כ',
              tax: 'מס',
              tip: 'טיפ',
              showOpenOrders: "הזמנות פתוחות",
              showDeliveredOrders: "הזמנות שסופקו",
              qty: 'כמות',
              cashier: 'קופאי/ית',
              orderDetails: "פרטי הזמנה",
              invoiceSequence: "מספר חשבונית",
              orderNotExistsOnDisk: "המכירה לא קיימת בדיסק, יתכן כי נמחקה על ידי קופה אחרת",
              lockingOrder: "נועל הזמנה",
              theFollowingOrdersAreLocked: "ההזמנות הבאות נעולות ולא ניתן לבצע בהן שינוי: ",
              lockByPos: "הזמנה פתוחה בקופה אחרת",
              incompleteDelivery: "משלוח לא הושלם, לחץ אישור על מנת להשלים את פרטי המשלוח או ביטול על מנת לאפס את המכירה",
              cantDeleteDefaultAddress: "לא ניתן למחוק כתובת ראשית",
              cantDeleteSelectedAddress: "יש לבחור כתובת אחרת למשלוח תחילה",
              orderNum: "מספר הזמנה",
              city: "עיר",
              unspecified: "לא צויין",
              address: "כתובת",
              orderTime: "ת. הזמנה",
              assignedCourierTime: "שעת ציוות",
              ordererName: "שם מזמין",
              courier: "שליח",
              customerName: "שם הלקוח",
              status: "סטטוס",
              title: "הזמנת משלוח",
              prevOrderChoosen: "נבחרה הזמנה",
              loadingOrder: "טוען הזמנה",
              type: "סוג",
              street: "רחוב",
              floor: "קומה",
              apartment: "דירה",
              entrance: "כניסה",
              details: "פירוט",
              nameOfPlace: "שם מקום",
              phoneShort: "טלפון",
              externalWoltBtn: "וולט",
              customerRemarks: "הערות לקוח",
              deliveryRemarks: "הערות הזמנה",
              openOrdersTitle: "הזמנות פתוחות",
              filterLabel: "מס' הזמנה/לקוח/כתובת",
              noOpenDeliveries: "אין הזמנות פתוחות",
              noOrdersFound: "לא נמצאו הזמנות",
              noOptions: "אין נתונים",
              phone: "מספר טלפון",
              assignedOrders: "הזמנות מצוותות",
              assignCourier: "צוות",
              unassignCourier: "הסר ציוות",
              notAssignedOrders: "הזמנות לא מצוותות",
              closeDialog: "סגור חלון",
              openDelivery: "פתוחה",
              assignedDelivery: "מצוותת(מזומן בחוץ)",
              closedDelivery: "מצוותת",
              cashToReceive: "מזומן לקבל מ",
              totalCashToReceive: `סה"כ מזומן לקבל משליחים`,
              closeAllOrdersForCourier: "סגור את כל ההזמנות לשליח זה",
              shouldCloseAllOrdersForCourier: "האם לסגור את כל ההזמנות עבור שליח זה?",
              closeOrder: "סגור הזמנה",
              totalAmount: "סכום כולל",
              actions: "פעולות",
              amount: "סכום",
              closeAll: "סגור הזמנות",
              confirm: "אישור",
              compensation: "פיצוי",
              compensationTitle: "פיצוי ללקוח",
              compensationReason: "סיבת פיצוי",
              removeCompensation: "הסר פיצוי",
              lookingForCompensation: "מאתר פיצוי ללקוח...",
              updatingCompensation: "מעדכן פיצוי ללקוח...",
              streetNotFound: "לא נמצא רחוב",
              cityNotFound: "לא נמצאה עיר",
              chooseCityFirst: "יש לבחור רחוב תחילה או להזין רחוב ידנית",
              loadingStreets: "טוען רחובות וערים...",
              delivery: "משלוח",
              deliveryFromExternal: "אינטרנטית",
              ExternalTenbisDelivery: "תן-ביס משלוח",
              ExternalTenbisTA: "תן-ביס איסוף עצמי",
              ExternalPluxeeDelivery: "פלאקסי משלוח",
              ExternalPluxeeTA: "פלאקסי איסוף עצמי",
              externalPaiditTA: "פייד-איט איסוף עצמי",
              externalPaiditDelivery: "פייד-איט משלוח",
              externalPaiditSitting: "פייד-איט ישיבה",
              externalPrestoSitting: "Presto Contactless Dine In",
              externalPrestoTA: "Presto Contactless Take Out",
              externalTenbisBtn: "תן-ביס",
              externalPaiditBtn: "פייד-איט",
              externalPluxeeBtn: "פלאקסי",
              externalMishloha: "משלוחה משלוח",
              externalMishlohaTA: "משלוחה איסוף עצמי",
              externalWolt: "משלוח Wolt",
              externalWoltTA: "איסוף עצמי Wolt",
              TA: "TA",
              loadingDeliveryOrdersData: "טוען מידע עבור משלוחים",
              compensationPlaceHolder: "נא להזין פיצוי",
              compensationReasonPlaceHolder: "נא להזין סיבת פיצוי",
              courierExitStillHaveOrders: "לעובד (שליח) צוותו הזמנות ב-20 דקות האחרונות, האם להמשיך?",
              releaseSale: "שחרור",
              customerDetails: "פרטי לקוח",
              taPaid: "שולם",
              delivered: "סופק",
              cancelSandiDelivery: "ביטול שליח סנדי מן",
              deliveryCancelled: "בוטל",
              cancellingOrder: "מבטל הזמנה...",
              sandiCreateOrderError: "אירעה שגיאה ביצירת משלוח של סנדי, יש ליצור קשר עם התמיכה",
              sandiCancelOrderError: "אירעה שגיאה בביטול משלוח של סנדי, יש ליצור קשר עם התמיכה",
              bump: 'Bump',
              customerExists: `לא ניתן להתחיל משלוח במכירה עם לקוח קיים.\n  יש למחוק את המכירה הנוכחית ולנסות שוב`,
              cash: "מזומן",
              otherPayments: "תשלומים אחרים",
              totalotherPayments: `סה"כ תשלומים אחרים`,
              notDeliveryPos: "הקופה לא מוגדרת כקופת משלוחים",
              total: 'סכום',
              none: 'אין',
              loadingDeliveries: 'טוען הזמנות...',
              failedToGetOrders: 'ארעה שגיאה בטעינת ההזמנות, אנא נסה שנית',
              tryingToAddExistsingCustomer: "קיים כבר לקוח עם מספר לקוח/תעודת זהות/טלפון זהה",
              wolt: "וולט",
              mishloha: "משלוחה",
              paidit: "פידאיט",
              tenbis: "תן ביס",
              pluxee: "פלאקסי",
              HttpError: "הגדרות ממשק {service} לא תקינות, שגיאה זו יכולה להיות בעקבות API KEY לא תקין או מזהה מסעדה לא נכון.",
            },
            directHakafa: {
              customerSearch: 'מחפש לקוח - אנא המתן',
              cardRequired: 'חובה להזין כרטיס',
              moreThanObligo: 'לא ניתן להוסיף תשלום - סך רכישות מעל האובליגו',
              notExists: 'לקוח הקפה אינו קיים',
              error: 'שגיאה',
              generalError: "שגיאה כללית בתשלום. אנא נסה שנית",
              title: 'קורא קרבה',
              description: 'קרב כרטיס',
              placeHolder: 'כרטיס לקוח',
              foundMoreThanOneCustomer: 'קיימת כפילו בכרטיסי לקוח, לא ניתן להשלים את הקניה',
            },
            departmentForm: {
              createTitle: "מחלקה חדשה",
              editTitle: "עריכת מחלקה",
              name: "שם",
              prev: "הקודם",
              next: "הבאה",
              actions: 'פעולות',
              activeItems: "פריטים פעילים",
              lockedItems: "פריטים נעולים",
              itemState: "הצג:",
              searchItem: "חיפוש",
              itemsToAssociateTitle: "פריטים משויכים",
              itemBarcode: "ברקוד פריט",
              itemName: "שם פריט",
              relatingItemTitle: "שיוך פריטים",
              relateItemToGroup: "שייך",
              unRelateItemToGroup: "הסר שיוך",
              exitWithoutSave: "צא ללא שמירה",
            },
            departments: {
              title: "ניהול מחלקות",
              addDepartmentLabel: "הוסף מחלקה",
              back: "חזרה",
              name: "שם מחלקה",
              actions: "פעולות",
              add: "שמור",
              cancel: "ביטול",
              edit: "עריכה",
              addNewItemPlaceholder: "שם מחלקה",
              departmentNameCantBeEmpty: "שם מחלקה לא יכול להיות ריק",
              departmentSortOrderCantBeNegative: "מיקום בתפריט הראשי לא יכול להיות שלילי",
              couldNotAddDepartment: "לא ניתן להוסיף מחלקה. יש לנסות שוב. אם הבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
              departmentAlreadyExists: "כבר קיימת מחלקה באותו השם",
              editDepartment: "עריכת מחלקה",
              enterANewName: "שם מחלקה חדש",
              inputPlaceHolder: "שם מחלקה",
              pleaseEnterAValue: "חובה להזין ערך",
              delete: "מחיקה",
              deleteDepartmentConfirm: "האם אתה בטוח? כל הפריטים שבמחלקה זו יהפכו להיות נעולים וללא מחלקה.",
              departmentSortOrder: "מיקום בתפריט הראשי בקופה",
              positionInMenu: 'מיקום בתפריט'
            },
            superFriendsCustClub: {
              title: "Super Friends",
              searchCustomerPlaceholder: "לפי ת.ז/כרטיס",
              formNumber: 'טופס הרשמה',
              mustEnterCustomerNumber: "חובה להזין מזהה/כרטיס",
              mustEnterFormNumber: 'חובה להזין מספר טופס הרשמה',
              customerDetails: 'מצב לקוח',
              pointsForUse: 'נקודות למימוש',
              searchCustomerByCellularPlaceholder: 'לפי טלפון',
              searchLabel: "חיפוש לפי ת.ז/כרטיס",
              searchByCellularLabel: "חיפוש לפי טלפון",
              orSearchBy: 'או',
              cannotAddItemClubCustomerNotFound: 'לא ניתן להוסיף פריט מועדון לא נמצא לקוח מועדון במכירה',
              cannotAddItemClubNewMemberMoreThanOne: 'לא ניתן להוסיף פריט הצטרפות למועדון יותר מפעם אחת במכירה',
              cannotAddItemClubNewMemberOnExistsMember: 'לא ניתן להוסיף פריט הצטרפות למועדון ללקוח קיים',
              cannotAddItemClubRenewMemberOnNewMember: 'לא ניתן להוסיף פריט חידוש מועדון ללקוח חדש',
              cannotAddItemClubRenewMemberMoreThanOne: 'לא ניתן להוסיף פריט חידוש מועדון יותר מפעם אחת במכירה',
              cannotAddItemClubRenewMemberOnBlockCustomerClub: 'לא ניתן להוסיף פריט חידוש מועדון לסוג מועדון זה',
      customerPromotionAmountExeeded: 'תקרת הקניה עם מבצע המועדון הוא {promotionsLimit} והקניות המצטברות עומדות על {totalPurchases}. הקנייה הנוכחית עם הנחות יכולה להיות עד לגובה של {allowedAmount}. לא ניתן לבצע את העסקה. יש לבטל את ההנחות או לרכוש בסכום נמוך לתקרה המותרת',
              scanCardForCustomer: 'העבר כרטיס מועדון של לקוח',
              numberAndAssignment: 'מספר ושיוך',
              incompleteCustomerData: "לא ניתן לבחור את הלקוח, יש לבצע הרשמה/עדכון פרטים באמצעות הצטרפות דיגיטלית",
              clubsNames: {
                '13': 'S. אתרי סחר',
                '15': 'C. אתרי סחר',
                '19': 'VIP',
                '20': 'SUPER FRIENDS',
                '32': 'אתרי סחר',
                other: 'לקוח',
              }
            },
            positiveDeliveryCustomerClub: {
              card: 'מזהה/כרטיס',
              id: 'תז',
              name: 'שם',
              phone: 'טלפון',
              birthdate: 'תאריך לידה',
              birthdateShort: 'ת. לידה',
              chooseCustomer: 'בחר לקוח',
              previousTransactions: 'עסקאות קודמות',
              noCustomersFound: 'לא נמצאו לקוחות',
              requiredField: 'שדה חובה',
              firstName: 'שם פרטי',
              lastName: 'שם משפחה',
              deliveryAddress: 'כתובת למשלוח',
              email: 'Email',
              customerGroup: 'קבוצת לקוחות',
              additionalPhone: 'טלפון נוסף',
              customerRemarks: 'הערות לקוח',
              hakafaPaymentCustomer: 'לקוח משלם בהקפה',
              city: 'עיר',
              street: 'רחוב',
              addressNickname: 'כינוי לכתובת',
              house: 'בית',
              entrance: 'כניסה',
              floor: 'קומה',
              apartment: 'דירה',
              mainAddrress: 'כתובת ראשית',
              deleteAddress: 'מחק כתובת',
              selectThisAddressToDelivery: 'בחר כתובת זו למשלוח',
              existingCustomer: 'לקוח קיים',
              addNewCustomer: 'הוספת לקוח חדש',
              addCustomer: 'הוסף לקוח',
              searchCustomer: 'חיפוש לקוח',
              search: 'חיפוש',
              fullSearch: 'חיפוש מלא',
              searchParams: 'לפי ת.ז/שם/טלפון',
              historyCustomer: 'היסטוריה לקוח',
            },
            positiveDeliveryPreviousOrders: {
              title: 'הזמנות קודמות',
              time: 'זמן',
              address: 'כתובת',
              itemsQty: 'כמות פריטים',
              sum: 'סכום',
              actions: 'פעולות',
              selectOrder: 'בחר הזמנה',
              show: 'הצג',
            },
            positiveDeliveryTableView: {
              code: 'קוד',
              name: 'שם',
              qty: 'כמות',
              price: 'מחיר',
            },
            pluxeeView: {
              selectCompany: "בחר חברה",
              cardNumber: "מס' כרטיס",
              card: "כרטיס",
              price: "סכום",
              clearFields: "נקה שדות",
              swipeOrInputCard: "העבר כרטיס או הקלד מס' כרטיס",
              caveretPaymnetMethods: "תשלום בחוגר",
              checkBalance: "בירור יתרה",
              noCompanySelected: "לא נבחר חברה",
              cardInputEmpty: "מספר כרטיס לא הוזן",
      currentBalance: "יתרה נוכחית: {balance}₪",
              checkingBalanceLoading: "בודק יתרה...",
              err : {
                '1':'שגיאה כלית',
                '2':'שגיאה כללית ',
                '3':'גירסה שגויה לא ניתן להזמין',
                '4':'מספר עובד שגוי לא ניתן להזמין',
                '5':'מספר חברה שגוי לא ניתן להזמין',
                '6':'אין תקשורת פנה ל 1-700-70-11-30',
                '7':'בעיית תקשורת נסה שנית',
                '8':'התשלום בוצע NULL',
                '9':'ההזמנה בוצעה ללא אישור',
                '10':'ניתן להזמין ע"י כרטיס בלבד',
                '11':'מכשיר לא מזוהה NULL',
                '12':'תקלה פנה ל 1-700-70-11-30',
                '13':'קוד שגוי פנה ל  1-700-70-11-30',
                '14':'ההזמנה לא בוצעה חריגה בזמנים',
                '15':'ההזמנה לא בוצעה חריגה בהזמנות',
                '16':'ההזמנה לא בוצעה חריגה בסובסידיה',
                '17':'ההזמנה לא בוצעה מסעדה לא מאושרת',
                '18':'לא ניתן לבטל מספר הזמנה שגוי',
                '19':'כרטיס שגוי NULL',
                '20':'משתמש לא מאושר לא ניתן להזמין',
                '21':'משתמש לא רשאי לבטל הזמנה ',
                '22':'חריגה מהתקציב ',
                '23':'עסקה ללא סכום ',
                '24':'קוד הארוחה שנבחר אינו פעיל ',
                '25':'שגיאה לא ידועה פנה ל 1-700-70-11-30 ',
                '35':'שגיאה - דחית פוליסה ',
                '36':'שגיאת מסעדה ',
                '37':'שגיאה - מגבלת מפעיל ',
                '38':'שגיאה - נעול ',
                '39':'POS_ERR_REST_LOC לא בשימוש',
                '40':'PCELL – כתובת IP של הקופה לא מורשה. '
              }
            },
            positiveDeliveryOrderDetail: {
              ordererName: 'שם מזמין',
              ordererPhone: 'טלפון מזמין',
              customerDetails: 'פרטי הלקוח',
              name: 'שם',
              phone: 'טלפון',
              address: 'כתובת',
              changeAddAddress: 'שנה / הוסף כתובת',
              deliveryRemarks: 'הערות למשלוח',
              ordererDeliveryRemarksPlaceholder: 'המלל שפה גם נשמר וגם מודפס בסוף החשבונית',
              ordererCallerRemarks: 'הערות למוקדן',
              ordererCallerRemarksPlaceholder: 'המלל שפה נשמר על המכירה אבל לא מודפס',
              previousOrders: 'הזמנות קודמות',
              shouldCompensate: 'יש לפצות לקוח זה!',
              compensation: 'פיצוי',
              title: 'פרטי הזמנה',
              titlePickCustomerMode: 'פרטי לקוח'
            },
            positiveDeliveryShowOrder: {
              customerName: 'שם לקוח',
              ordererName: 'שם מזמין',
              phone: 'טלפון',
              city: 'עיר',
              street: 'רחוב',
              addressNickname: 'כינוי לכתובת',
              houseNumber: 'בית',
              entrance: 'כניסה',
              floor: 'קומה',
              apartment: 'דירה',
              address: 'כתובת',
              paymentType: 'צורת תשלום',
              sum: 'סכום',
      tenbisCertificate: "תן ביס (תעודה מס' {splitInvoice})",
      pluxeeCertificate: "פלאקסי (תעודה מס' {splitInvoice})",
      goodiCertificate: "גודי (תעודה מס' {splitInvoice})",
              selectOrder: "בחר הזמנה",
            },
            positiveCustClub: {
              renew:{
                header:"חידוש מועדון",
                isCustomerAgreed:"האם לקוח מעונין לחדש את המועדון?"
              },
              comments: "הערות",
              seeComments: "צפה בהערות",
              title: "מועדון לקוחות POSITIVE",
              existingCustomer: "לקוח קיים",
              addNewCustomer: "הוספת לקוח חדש",
              searchLabel: "חיפוש לקוח",
              searchPlaceholder: "לפי ת.ז/כרטיס/שם/טלפון",
              searchPlaceholderWithNoName: "לפי ת.ז/כרטיס/טלפון",
              search: "חיפוש",
              idCard: "מזהה/כרטיס",
              ID: "ת.ז",
              name: "שם",
              phone: "טלפון",
              dateOfBirth: "תאריך לידה",
              validUntil: "בתוקף עד",
              points: "נקודות",
              selectCust: "בחר לקוח",
              pastTransactions: "עסקאות קודמות",
              firstName: "שם פרטי",
              lastName: "שם משפחה",
              dateOfBirthYear4Digits: "תאריך לידה (שנה בארבע ספרות)",
              email: "Email",
              customerGroup: "קבוצת לקוחות",
              customerDiscountPercent: "אחוז הנחה ללקוח",
              totalPoints: 'סה"כ נקודות',
              editCust: "ערוך לקוח",
              addCustomer: "הוסף לקוח",
              noCustomersFound: "לא נמצאו לקוחות",
              canWritePhoneIfNoCard: "ניתן לרשום טלפון/תז במידה ואין כרטיס",
              findingCustomer: 'מחפש לקוח - אנא המתן',
              checkingIfCustExists: 'בודק אם לקוח כבר קיים - אנא המתן',
              mustEnterFirstName: "חובה להזין שם פרטי",
              mustEnterCardNumber: "חובה להזין מזהה / כרטיס",
              toLongTextInput: " מספר לקוח ארוך מדי",
              mustEnterLastName: "חובה להזין שם משפחה",
              updateCustomer: "עדכן לקוח",
              mustEnterPhone: "חובה להזין טלפון",
              customerNotFound: "לקוח לא קיים במערכת - צור קשר עם התמיכה",
              discountPercentMustBeNumericOrEmpty: "אחוז הנחה ללקוח חייב להיות ערך מיספרי או ריק",
              mustEnterTZ: "חובה להזין תעודת זהות",
              TZIsNotValid: 'תעודת זהות לא תקינה',
              emailInvalid: 'כתובת מייל לא תקינה',
              phoneInvalid: 'טלפון לא תקין',
              createCustomer: 'מקים לקוח...',
              errorOnAddCustomer: 'שגיאה כללית בהוספת לקוח נסה שנית במידה וחוזר צור קשר עם התמיכה',
              errorCannotCreateCustomerOnOffline: 'לא ניתן להקים לקוח שהקופה במצב אופליין',
              companyHasOnlineOnlySearch: "לחברה זו מוגדר מועדון לקוחות מקוון בלבד - נא לוודא חיבור לרשת",
              posHasOnlineOnlySearch: "לקופה זו מוגדר מועדון לקוחות מקוון בלבד - נא לוודא חיבור לרשת",
              errorCannotSelectCustomerForDeliveryOnOffline: "לא ניתן לעשות משלוח שהקופה באופליין",
              extensionValidUntil: 'הארכת תוקף',
              extensionValidUntilModalTitle: 'הארכת תוקף לקוח מועדון',
      extensionValidUntilModalMessage: 'תוקף הלקוח יוארך לתאריך {date}, האם להמשיך?',
              cannotExtensionValidUntil: 'לא ניתן להאריך תוקף ללקוח שהוא כבר בתוקף',
              errorOnEditCustomer: 'שגיאה כללית בעדכון לקוח נסה שנית במידה וחוזר צור קשר עם התמיכה',
              errorExtensionValidUntil: `שגיאה כללית בהארכת תוקף לקוח מועדון.
              נסו שנית. במידה והשגיאה חוזרת על עצמה יש ליצור קשר עם התמיכה`,
              errorCannotUpdateCustomerOnOffline: 'לא ניתן לעדכן לקוח שהקופה במצב אופליין',
      customerExists: "קיים כבר לקוח עם מספר מזהה/כרטיס זה ({first_name} {last_name})",
              cannotAddNewCustomerOnCreditSale: 'לא ניתן להוסיף לקוח כאשר יש מכירת זיכוי.',
              updatedCustomerSuccessfully: "לקוח עודכן בהצלחה",
              updateCustomerComments: "עדכן הערות"
            },
            valueCardCustClub:{
              title: "מועדון לקוחות Value Card",
              existingCustomer: "לקוח קיים",
              addNewCustomer: "הוספת לקוח חדש",
              searchLabel: "חיפוש לקוח",
              searchPlaceholder: "לפי ת.ז/כרטיס/שם/טלפון",
              swipeCard: "העבר הכרטיס מועדון",
              addCustomer: "הוסף לקוח",
              selectCust: "בחר לקוח",
              idCard: "מזהה/כרטיס",
              canWritePhoneIfNoCard: "ניתן לרשום טלפון/תז במידה ואין כרטיס",
              custEmail: "Email",
              custBirthDay: "תאריך לידה",
              custMarriageDate: "תאריך יום נישואין",
              address: "כתובת",
              postalCode: "מיקוד",
              remarks: "הערות",
              card: 'כרטיס',
              balance: "יתרה",
              messageForCustomer: "הודעה ללקוח",
              customerNumberCard: "מספר לקוח/כרטיס",
              searchingCustomerPleaseWait: 'מחפש לקוח - אנא המתן',
              apiKeysAreMissing: 'הגדרות Value Card חסרות - יש לדבר עם תמיכה',
              budgetOrPoints: 'נקודות/תקציב',
              invalidValuecardPoints: "שגיאה: מספר הנקודות לא תקין. אנא הכנס מספר נקודות תקני ",
            },
            simplyClubCustClub: {
              title: "מועדון לקוחות Simply Club",
              existingCustomer: "לקוח קיים",
              addNewCustomer: "הוספת לקוח חדש",
              searchLabel: "חיפוש לקוח",
              balance: "יתרה",
              messageForCustomer: "הודעה ללקוח",
              addCustomer: "הוסף לקוח",
              updateCustomer: 'עדכן פרטי לקוח',
              selectCust: "בחר לקוח",
              sendSmsToAddCustomer: 'שלח הודעת הצטרפות',
              searchingCustomerPleaseWait: 'מחפש לקוח - אנא המתן',
              searchPlaceholder: 'חיפוש לפי כרטיס/שם/נייד/ת.ז.',
              writePhoneHereToSendSms: "ניתן לרשום כאן טלפון נייד לשליחת מסרון",
              idCard: 'מספר כרטיס',
              idCardInputPlaceHolder: 'יאותחל אוטומטית',
              custBirthDay: "תאריך לידה",
              custMarriageDate: "תאריך יום נישואין",
              custEmail: "אימייל",
              sendSmsToJoinInstead: "שלח SMS הצטרפות במקום",
              sending: "שולח...",
              ifSendSms: 'אפשר לשלוח SMS?',
              ifSendEmail: 'אפשר לשלוח מייל?',
              sentCheckSms: 'נשלח בהצלחה, יש ליידע את הלקוח למסרון',
              memberNotFoundSentJoinSms: 'מספר נייד חבר מועדון לא נמצא, נשלח הודעת SMS הצטרפות למועדון.',
              addedCustomerSuccessfully: 'לקוח נוסף בהצלחה',
              updatedCustomerSuccessfully: 'לקוח עודכן בהצלחה',
              memberExpiredDoYouWantToRenew: 'לקוח לא בתוקף. האם לחדש את החברות?',
              expireDate: 'תאריך תוקף',
              lastSaleDate: 'תאריך קנייה אחרונה',
              benefits: 'הטבות',
              points: 'נקודות',
              discounts: 'הנחות',
              accumulating: 'צוברים',
              errField: {
                firstNameRequired: 'יש להזין שם פרטי',
                lastNameRequired: 'יש להזין שם משפחה',
                mobileRequired: "יש להזין שם מס' נייד",
              },
            },
            simplyClubBenefits: {
              title: 'הטבות חבר מועדון Simply Club',
              continueWithoutBenefits: 'המשך ללא הטבות',
              cardBalance: 'יתרת כרטיס',
              overallInitialPrice: 'סכום עסקה מקורי',
              overallBenefitsSelected: 'סה"כ הטבות שנבחרו',
              overallPrice: 'סה"כ לתשלום אחרי הטבות',
              noBenefits: 'אין הטבות',
              benefitsPointsCannotBeAbovePrice: 'סה"כ ההטבות לא יכול להיות גדול מהמכירה עצמה',
              cols: {
                benefitName: 'שם הטבה',
                notes: 'הערות',
                mimush: 'מימוש',
                maximumMimush: 'מימוש מרבי',
                unitVal: 'שווי יחידה',
                overallBenefit: 'סה"כ הטבה',
                useDiscount: 'בחר הנחה',
                discountWorth: 'שווי הנחה',
              },
              errorOccuredWhileClosingSale: "אירעה שגיאה בעת שליחת עסקה ל-Simply Club. יש לדבר עם תמיכה",
              checkingBenefits: 'בודק הטבות ללקוח, אנא המתן...',
            },
            employeeHours: {
              title: "שעות לעובד",
              hoursForMonth: "שעות בחודש זה",
              addEntry: "הוספת כניסה",
              addExit: "הוספת יציאה",
              addBoth: "הוספת כניסה ויציאה",
              printReport: "הדפס דוח שעות",
              deleteConfirm: "האם אתה בטוח שברצונך למחוק רשומה זו?",
              date: 'תאריך',
              day: 'יום',
              entrance: 'כניסה',
              exit: 'יציאה',
              manualUpdate: 'עדכון ידני',
              totalHours: 'סה"כ שעות',
              actions: 'פעולות',
            },
            ett: {
              title: "דיווח שעות",
              employeeCard: "כרטיס עובד",
              whoIsInShift: "מי במשמרת?",
              enter: "כניסה",
              exit: "יציאה",
              managerApprovalSms:"SMS למנהל",
              toManager: "למנהל",
              performingEntryExit: "מבצע רישום שעות",
              cannotPerformTimeTrackWhileOffline: "הקופה נמצאת במצב נתק.\n לא ניתן לדווח שעות בנתק. \n אנא רשום את השעות שלך באופן ידני ודווח למנהל שלך לצורך רישום שעות ידני.",
              fingerPrint: "טביעת אצבע",
              description: "העבר כרטיס או הקלד קוד עובד",
              descriptionRoshemet: "הקלד כרטיס עובד או לחץ על 'העבר כרטיס'",
              employeeNotDefined:
                `עובד לא משויך לחנות זו.\n לא ניתן לבצע כניסה/יציאה לעובד בחנות זו. \n
              יש לוודא שהוקלד קוד עובד נכון. \n
              במידה והוקלד קוד עובד נכון, יש לשייך עובד לחנות זו דרך ניהול עובדים ולאחר מכן לנסות שוב.`,
              employeeNotDefinedOrInactive:
                `לא ניתן לבצע החתמת שעות לעובד שמוסמן כלא פעיל.
              יש לוודא שהוקלד קוד עובד נכון.
              במידה והוקלד קוד עובד נכון, יש לסמן עובד כ-פעיל דרך ניהול עובדים ב-backoffice ולאחר מכן לנסות שוב.`,
              exitWithoutEntry:
                `לא ניתן לבצע יציאה ללא כניסה. \n
              אנא פנה למנהל שלך לצורך עדכון שעות ידני`,
              entryWithoutExitInvalid:
                `כבר בוצעה כניסה ב {dateTime}
               לא ניתן לבצע כניסה נוספת ללא רישום יציאה`,
              missigPreviousEntryNotification:
                `כניסה לעובד {name} בוצעה בהצלחה.
              \n שים לב! לא נרשמה במערכת יציאה בתאריך
              {dateTime'}
               - יש לפנות למנהל לצורך עדכון שעות ידני`,
              emptyMagneticCard: "יש להעביר כרטיס או להזין קוד עובד",
              emptyMode: "יש לבחור כניסה או יציאה",
              employeeNotSynced: `העובד לא סונכרן לשרת.\n יש לוודא שהקופה במצב מקוון ולנסות שנית`,
              swipeCard: 'העבר כרטיס עובד',
              swipeManagerCard: "העבר כרטיס מנהל",
            },
            ettBreakTime: {
              title: "דיווח שעות",
              descriptionMain: "בוצעה כניסה פחות משעתיים אחרי היציאה האחרונה",
              descriptionSec: "האם חזרת מהפסקה או שזו משמרת חדשה?",
              resumeShift: "חזרה מהפסקה",
              newTimeTrack: "משמרת חדשה",
              shiftStartTime: "זמן תחילת המשמרת",
              currentBreakTime: "הפסקה נוכחית",
              totalBreakTime: "סהכ זמן הפסקה במשמרת"
            },
            customers: {
              internalClub: "מועדון לקוחות פנימי",
              creditClub: "מכירה בהקפה",
              customerDiscount: "הנחת לקוח",
              simplyClub: "Simply Club",
            },
            loadingSoftware: "טוען תוכנה...",
            cachingItems: "עושה כל מיני דברים חשובים שקופה צריכה לעשות...",
            conversionFailed: "המרת מסד נתונים נכשלה. לא ניתן להמשיך בעליית קופה. חובה לפנות לתמיכה",
            currencies: {
              USD: "דולר",
              ILS: "שקל",
              EUR: "יורו",
              GBP: 'לירה',
              AUD: 'דולר אוסטרלי',
              CAD: 'דולר קנדי',
              CHF: 'פרנק שוויצרי',
              DKK: 'כתר דנמרק',
              EGP: 'לירה מצרית',
              JOD: 'דינר ירדני',
              JPY: 'יין יפני',
              LBP: 'לירה לבנונית',
              NOK: 'כתר נורווגיה',
              SEK: 'כתר שוודיה',
              ZAR: 'רנד דרום אפריקה',
              printer: {
                title: "פירוט מטבעות במזומן",
                currency: "מטבע",
                sum: "סכום",
                sumIn: "סכום ב-",
              }
            },
            homepage: {
              pos: "קופה",
              cashier: "קופאי",
              timeClock: "שעון נוכחות",
              reports: "דוחות",
              xreport: "דוח X",
              zreport: "סגירת קופה Z",
              bandDeposit: "הפקדה לבנק",
              dbUpdate: "עדכון מידע",
              vendingMachineInventoryUpdate: 'עדכון מלאי',
              resetVendingMachine: "איפוס מכונה",
              cashierStatement: "הצהרת קופאי",
              parameters: "פרמטרים",
              technicianSettings: "הגדרות טכנאי",
              employeeManage: "ניהול עובדים",
              itemManage: "ניהול פריטים",
              hakafaCustomersManage: "ניהול לקוחות הקפה",
              cashierStatementError: "לא ניתן לעבוד על הקופה ללא ביצוע הצהרת פתיחה. האם לבצע הצהרת פתיחה?",
              cashierStatementZError: "לא ניתן לסגור את קופה ללא ביצוע הצהרת סגירה. האם לבצע הצהרת סגירה?",
              doCashierStatement: "בצע",
              cashManagement: "ניהול מזומן",
              startPosStatement: "הצהרת פתיחת קופה",
              alreadyOpened: "קיימת הצהרת פתיחה בקופה היום",
              caveretShortcut: "תוכנת כוורת",
              caveretStarterRunning: "מפעיל תוכנת כוורת",
              caveretStarterLoading: "ממתין לעליית התוכנה",
              caveretStarterFailed: "הרצת התוכנה נכשלה. נא ליצור קשר עם התמיכה הטכנית",
              employeeNotFoundOrNoExternalId: "העובד לא נמצא במערכת או שאין לו זיהוי חיצוני\nנא לעשות עידכון מידע ולנסות שוב",
              employeeNotInSystem: "הקופאי לא נמצא בקופה\n נא לעשות עידכון מידע ולנסות שוב",
              poweroff: "כיבוי",
              closePos: "סגירת קופה",
              remoteAssistence: "תמיכה מרחוק",
              openFlight: "פתח טיסה",
              closeFlight: "סיים טיסה",
              openLeg: "פתח לג",
              closeLeg: "סגור לג",
              disconnectFlight: "נתק טיסה",
              currencyCalculator: "מחשבון המרה",
              activateSelfServicePos: 'הפעל קופה בשירות עצמי',
              deactivateSelfServicePos: 'בטל קופה בשירות עצמי',
              outOfStockItems: 'פריטים חסומים למכירה',
              lockSelfServicePos: 'נעילת עמדה',
            },
            employeeManageEdit: {
              titleEdit: "עריכת עובד",
              titleAdd: "הוספת עובד",
              employeeName: "שם עובד",
              managerCard: "כרטיס עובד",
              tz: "ת. זהות",
              isManager: "האם מנהל",
              isCashier: "האם קופאי",
              isLocked: "האם נעול",
              saveAndExit: "שמירה ויציאה",
              exitWithoutSave: "יציאה ללא שמירה",
              errorCheckValues: "שגיאה - בדוק ערכים",
              nameCannotBeEmpty: "חובה להזין שם",
              cardAlreadyUsed: "כרטיס עובד כבר נמצא בשימוש",
              nameCantBeEmpty: "חובה להזין שם",
              cardCantBeEmpty: "חובה להזין כרטיס עובד",
              cardCantBeWithSpacialChars: "כרטיס עובד יכול להכיל מספרים ואותיות באנגלית בלבד",
              cannotLockEmpInShift: "לא ניתן לנעול עובד שנמצא כרגע במשמרת",
              cannotRemoveLastManager: "חובה לסמן לפחות עובד אחד במערכת כמנהל - אחרת לא יהיה ניתן לחזור למסך ניהול העובדים",
              phone: "מספר טלפון",
              email: "כתובת אימייל",
              magneticCardError: "קיימים תווים לא חוקיים בכרטיס מגנטי"
            },
            employeeManage: {
              title: "ניהול עובדים",
              showEmployee: "הצג עובדים:",
              searchEmployee: "חיפוש עובד:",
              nameOrEmployeeNumber: "שם או מיספר עובד",
              employeeName: "שם עובד",
              id: "ת.זהות",
              isManager: "האם מנהל",
              actions: "פעולות",
              yes: "כן",
              no: "לא",
              edit: "עריכה",
              employeeHours: "שעות לעובד",
              addEmployee: "הוספת עובד",
              printNewReport: "הדפס דוח חודשי",
              back: "חזור",
            },
            employeeInStore: {
              title: "דיווח שעות",
              employeeName: "שם עובד",
              entryTime: "שעת כניסה",
              currentShiftLength: "כמה זמן עובד",
              noRecordsFound: "לא נמצאו רשומות",
              close: "סגור"
            },
            cashierStatement: {
              closeConfirmation: "האם ברצונך לצאת? שים לב, נתוני ההצהרה שהזנת ימחקו",
              zDifferenceProblem: "הסכום שהצהרת שונה מהסכום בקופה",
              zDifferenceProblemOk: "אשר הצהרה",
              zDifferenceProblemCancel: "תיקון ההצהרה",
              zReportTitle: "דו\"ח Z",
              statementTitle: {
                enter: "הצהרת פתיחת יום",
                Z: "הצהרת סגירת קופה",
                exit: "הצהרת אמצע/סוף יום",
                add: "הוספת מזומן לקופה",
                remove: "הוצאת מזומן מהקופה"
              },
              errorLoadCashierStatementLogs: 'שגיאה בטעינה הצהרות מזומנים יש ליצור קשר עם התמיכה',
            },
            qtyDialog: {
              title: "הזן כמות לפריט",
              desc: "נא להזין כמות חדשה לפריט",
              cannotApplyDecimalQtyToRegularItem: "לא ניתן לתת כמות עם נקודה עשרונית לפריט שאינו שקיל"
            },
            priceDialog: {
              title: "הזן מחיר",
              desc: "אנא הזן מחיר עבור הפריט",
              priceForLoad: "אנא הזן כמות לטעינה",
            },
            rikuzZDialog: {
              print: "הדפס",
              cancel: "ביטול",
              title: "דוח ריכוז Z",
              fromDate: "מתאריך",
              toDate: "עד תאריך",
              byFirstSale: 'תאריך ה-Z הוא לפי המכירה הראשונה ב-Z',
              sendMail: "שלח במייל",
              badDateRange: "לא ניתן לבחור טווח תאריכים גדול משנה",
              inProccess: "מפיק דוח",
              offLine: "לא ניתן להפיק דוח זה במצב נתק",
              toLargerThenFrom: "תאריך עד לא יכול להיות קודם לתאריך מ",
            },
            unifiedEmployeesReportDialog: {
              badDateRange: "לא ניתן לבחור טווח תאריכים גדול משנה",
              inProccess: "מפיק דוח",
              offLine: "לא ניתן להפיק דוח זה במצב נתק",
              toLargerThenFrom: "תאריך עד לא יכול להיות קודם לתאריך מ",
              systemError: "שגיאת מערכת, יש לפנות לתמיכה",
              noData: "אין שעות במערכת לטווח תאריכים זה",
            },
            hakafaInvoicesDialog: {
              invoiceNumber: "מספר חשבונית",
              date: "תאריך חשבונית",
              amount: "סכום חשבונית",
              stillLeftToPay: "נותר סכום לחיוב",
              loadingInvoices: "טוען חשבוניות",
              offlineMode: "לא ניתן לבצע תשלום חוב הקפה של חשבוניות במצב נתק",
              noMoreLeftToPay: "לא נותר סכום לתשלום",
              choose: "בחר",
              title: "סגירת חשבוניות",
              ok: "אישור",
              cancel: "ביטול",
              paid: "שולם",
              totalAmountPaid: `סך הכל חשבוניות ששולמו`,
              positiveBalance: "נותר סכום להתאמה",
              totalInvoicesPaid: `כמות חשבוניות ששולמו`,
              desc: "בחר את החשבוניות שברצונך לשלם עבורן",
              amountLeftToPay: "יתרת סכום להתאמה",
              amountLeftToPayInvoice: "יתרה לתשלום",
              amountMoreThanHakafa: "לא ניתן להזין סכום גדול מסך החוב",
              noInvoicesChecked: "לא נבחרו חשבוניות",
              totalToPay: 'סה"כ לתשלום',
              amountIsHigherThanLeftToPay: "סכום שהוזן גבוה מיתרה לתשלום",
              amountCannotBeNegative: "סכום שהוזן לא יכול להיות שלילי",
              customer: "לקוח"
            },
            dtsCustomerClub: {
              title: "מועדון לקוחות DTS \ נולדג'",
              cardNo: "כרטיס",
              search: "חיפוש",
              swipe: "העבר כרטיס",
              swipeCard: "העבר כרטיס",
              cancel: "ביטול",
              fromClub: "ממועדון",
              itemCode: "קוד הטבה",
              choose: "בחר",
              pay: "אשר בחירה",
              itemDescription: "תיאור הטבה",
              itemAvailableQty: "כמות מימושים",
              itemQtyToUse: "כמות למימוש נוכחי",
              insertCardNo: "העבר או סרוק כרטיס לקוח",
              customer: "לקוח",
              chargingCard: "מחייב כרטיס",
              error: "שגיאה בתקשורת מול DTS",
              lookingForCustomer: "מחפש לקוח...",
              noItemsInSale: "לא קיימים פריטים במכירה",
      itemNotFoundInPos: "פריט {code} לא קיים בקופה",
              itemsExistsInSale: "קיימים כבר פריטים בחשבון זה, סגור את המכירה הנוכחית ונסה שנית"
            },
            posAlertLog:{
              selfServicePinpadConnectionProblem:`אין תקשורת לקורא כרטיסים בעמדת שירות עצמי מס' {deviceID}, אנא בדוק את החיבור בעמדה או פנה למוקד התמיכה לקבלת עזרה נוספת`,
              productExpiredDueToTemp: "פג התוקף למוצר ספציפי בגלל שינוי בטמפרטורה במכונה בחנות {storeName} בעמדה {deviceID}. אנא וודאו את תקינות מד החום. שעת תיעוד: {date}",
              vendingStuck:"התרעה! המכונה תקועה ולא ניתן למכור מוצרים בחנות {storeName}, עמדה {deviceID} העמדה ננעלה. שעת תיעוד: {date}",
              vendingDoorOpen:"התרעה! דלת המכונה פתוחה בחנות {storeName}, עמדה {deviceID}. שעת תיעוד: {date}",
              powerDetectorFaulty: "התגלתה בעיה בגלאי צריכת החשמל של המכונה בחנות {storeName} בעמדה {deviceID}. אנא וודאו את תקינות הגלאי.  שעת תיעוד: {date}",
              elevatorMinorFault: "מעלית המכונה לא מצליחה לגשת למוצר מסוים בחנות {storeName} בעמדה {deviceID}. אנא וודאו את תקינות המכונה.  שעת תיעוד: {date}",
              elevatorMajorFault: "התגלתה תקלה משביתה במעלית או בגלאי המוצר של המכונה בחנות {storeName} בעמדה {deviceID}. אנא וודאו את תקינות המכונה.  שעת תיעוד: {date}",
              tempControllerFaulty: "התגלתה תקלה במד החום של המכונה בחנות {storeName} בעמדה {deviceID}. אנא וודאו את תקינות מד החום.  שעת תיעוד: {date}",
              vendingTempAboveX: "טמפרטורת המקרר בחנות {storeName} בעמדה {deviceID} עלתה מעל ל {temp} מעלות. אנא וודאו את תקינות המכונה.  שעת תיעוד: {date}",
            },
            caveretPayment: {
              clubChooserDialogTitle: "בחר מועדון לקוחות כוורת",
              caveretClub: "מועדון לקוחות: ",
              customerPickerTitle: "בחירת לקוח כוורת",
              insertCardNo: "העבר או סרוק כרטיס לקוח",
              cardNo: "כרטיס",
              search: "חיפוש",
              pay: "שלם",
              close: "סגור",
              chooseCustomer: "בחר לקוח",
              cancel: "ביטול",
              customer: "לקוח",
              payAll: "שלם הכל",
              chooseBudget: "בחר ארנק לחשבון",
              caveretPayment: "תשלום כוורת",
              untill: "בתוקף עד ",
              balanceText: "ארנקים אפשריים",
              type: "תקציבים",
              amount: "יתרת תקציב",
              toUse: "סכום לשימוש",
              lookingForCustomer: "מחפש לקוח...",
              chargingCard: "מחייב כרטיס",
              pin: "קוד סודי",
              swipe: "העבר כרטיס",
              swipeCard: "העבר כרטיס",
              error: "שגיאה בתקשורת מול כוורת",
              promosUpdated: `שים לב! מבצעים הוחלו על החשבון, להלן החשבון העדכני`,
              totalToPay: `סה"כ נותר לתשלום`,
              moreThanOneCustomerFound: `לא נמצא לקוח במועדון הלקוחות של הקופה`,
              caveretAndLocalCustomerMismatch: `לא נמצא לקוח במועדון הלקוחות של הקופה`,
              tooLittleBudget: `נותר סכום לתשלום`,
              tooMuchBudget: `סכום התקציבים לא יכול להיות גבוה מהסכום הנותר לתשלום`,
              otherPaymentsNotAllowed: "לא ניתן להשתמש בתשלום כוורת בחשבון זה\nקיימים כבר אמצעי תשלום אחרים",
      solobudgetSelected: `לא ניתן להשתמש בתקציב "{name}" עם תקציבים או אמצעי תשלום נוספים`,
              noBudgetsSelected: "לא נבחרו תקציבים",
      tooLittleBudgetAndSolo: `לא ניתן לבצע תשלום חלקי בתקציב "{name}"`,
              emvSwipeCard: 'העבר כרטיס במסוף',
              pleaseSwipeOrTypeCard: 'נא להעביר כרטיס במסוף או להקליד ידנית',
              swipeOrTypeCardNumber: 'העבר כרטיס או הקלד מספר כרטיס',
              manyBudgetsError: 'בכרטיס זה קיימים מספר ארנקים, יש לעבור לגשת לקופאי',
              pleaseTypePin: 'נא להקליד קוד סודי'
            },
            gppPayment: {
              gppPayment: 'תו הזהב',
              cardNo: "כרטיס",
              pleaseSwipeOrTypeCard: 'נא להעביר כרטיס במסוף או להקליד ידנית',
              swipeOrTypeCardNumber: 'העבר כרטיס או הקלד מספר כרטיס',
              emvSwipeCard: 'העבר כרטיס במסוף',
            },
            dbErrors: {
              error: "שגיאה",
              hardDiskFull: "שים לב אין מספיק מקום פנוי בדיסק הקשיח. יש לפנות מקום מהדיסק לצורך הפעולה התקינה של הקופה"
            },
            prepInstructions: {
              dialogHeader: "הוראת הכנה כללית",
              dialogDescription: "מלל עבור הוראת ההכנה",
              dialogEmptyError: "חובה להזין מלל'",
              wouldYouLikeChanges: "תרצו שינויים במנה?",
              notes: 'הערות',
              ok: 'סיימתי',
              noChanges: 'ללא שינויים',
              additionalNotes: 'הערות נוספות',
              typeHere: 'הקלד כאן',
            },
            pricelist: {
              POSIsOffline: "הקופה במצב נתק או אין גישה לשרת. אם הבעיה חוזרת על עצמה אנא פנה לתמיכה טכנית.",
              searchingForCustomers: "מאתר מחירון בשרת...",
              title: "בחירת מחירון",
              customerNumber: "מס' מחירון",
              customerName: "שם מחירון",
              chooseCustomer: "בחר לקוח",
              noPricelistCustomersFound: "לא נמצא לקוח מחירון"
            },
            offlineSalesDialog: {
              title: "אבחון מכירות בנתק",
              invoiceSequenceTitle: "מספר פתקית",
              errorTitle: "שגיאה",
              copy: "העתק"
            },
            menuSideBar: {
              internetDeliveries: 'משלוחי אינטרנט',
            },
            pos: {
              moveDalpak: 'העבר דלפק',
              collectOtotTickets: 'איסוף כרטיסים',
              reprintOtotTickets: 'איסוף כרטיסים - הדפסה מחדש',
              activateRfidBracelet: 'הפעלת צמיד חכם',
              purchaseByGamePoints: 'מימוש נקודות וידיאו',
              braceletBalanceCheck: 'בירור יתרת צמיד',
              saleRemarks: "הערה למכירה",
              finishTime: 'סיום פריט זמן',
              finishTimeDetails: 'פירוט זמנים',
              diners: 'סועדים',
              dalpak: 'דלפק',
              counter: 'שולחן',
              dalapkExit: 'יציאה מהדלפק',
              printInvoice: 'הדפסת חשבון',
              withoutPrintInvoice: 'ללא הדפסה',
              toRefund: "להחזר",
              loadingSale: "טוען מכירה",
              orderDetails: "פרטי ההזמנה",
              holdingSale: "משהה מכירה",
              releasingSale: "משחרר מכירה",
              printingBons: "מדפיס בונים",
              selfServiceTableViewEmptyMessage: "ההזמנה שלך ריקה.\n אולי תזמינו משהו? ",
              sale: "מכירה",
              saleActionsMobile: "פעולות חשבון",
              showItemRows: "הצג מכירה",
              showButtons: "תפריט פריטים",
              posPastInvoices: "חשבוניות קודמות",
              posSearchInvoices: "איתור חשבוניות",
              cellularTab: "תיקונים",
              hakafaTab: "מכירה בהקפה",
              posSearchPlaceholder: "סרוק פריט או הקלד פרטי פריט לחיפוש",
              posItemQuerySearchPlaceholder: "סרוק קוד פריט לבדיקת מחיר",
              code: "קוד",
              ordererPickupRemarks: "הערות להזמנה",
              enterOrdererPickupRemarks: "הזן הערות להזמנה",
              ordererPickupRemarksEdit: "עריכת הערות להזמנה",
              ordererPickupRemarksDescription: "ניתן לערוך את ההערה.<br> על מנת למחוק את ההערה יש להשאיר את השורה למטה ריקה וללחוץ המשך",
              dedicatedTo: "לכבוד",
              nameRequired: "חובה להזין ערך",
              nameTooLong: "שם לא יכול להיות יותר מ-{maxChars} תווים",
              remarkTooLong: "הערה לא יכולה להיות יותר מ-{maxChars} תווים",
              openDrawer: "פתח מגירה",
              zeroScale: "איפוס משקל",
              currentWeight: "משקל",
              description: "תאור",
              salesperson: "מוכר/ת",
              editItem: "עריכת פריט",
              edit: "עריכה",
              delete: "מחיקה",
              add: "הוספה",
              reduce: "הפחתה",
              changeQty: "שינוי כמות",
              reScale: "שקילה מחדש",
              deleteRow: "מחק שורה",
              itemDiscount: "הנחת פריט",
              priceChange: "שינוי מחיר",
              nameChange: "שינוי שם",
              inventoryForItem: "מלאי לפריט",
              returnNote: "פתק החלפה",
              prepInstructions: "הוראות הכנה",
              loading: "בטעינה...",
              startNewOrder: "בטל הזמנה",
              balanceInquiry: "בירור יתרה",
              balanceInquiryOtot: "בירור יתרת צמיד",
              search: "חיפוש",
              printCopy: "הדפס העתק חשבונית מס",
              fastPos: "קופה מהירה",
              continueToPayment: "המשך לתשלום",
              backToSale: "חזרה לחשבון",
              saleDiscount: "הנחת חשבון",
              clubMember: "חבר מועדון",
              deliveryDetails: "פרטי משלוח",
              priceList: "מחירון",
              saleActions: "פעולות נוספות",
              togglePromotionsOn: "אפשר מבצעים בחשבון",
              togglePromotionsOff: "בטל מבצעים בחשבון",
              potentialPromotionsExistHeader: "מבצעי חשבון",
              potentialPromotionsExist: "שים לב! ישנם מבצעים שניתן לקבל אם יוסיפו את הפריטים לחשבון:",
              quantity: "כמות",
              total: `סה"כ`,
              totalForPay: "לתשלום",
              changeForCustomer: 'יש להחזיר עודף של {change} {currencySign} ללקוח',
              returnItem: "החזרה",
              package: "אריזה",
              searchPlaceholderRoshemet: "חיפוש פריט",
              openDeliveryOrders: "הזמנות פתוחות",
              assignCouriers: "ציוות שליחים",
              closeDeliveries: "סגירת הזמנות",
              printBonsAgain: "כל המנות כבר הודפסו, האם להדפיס בון שוב?",
              printBonsAgainTitle: "הדפסת בון",
              noItemsToBePrinted: "אין פריטים להדפסה",
              print: "הדפס",
              cancel: "ביטול",
              compensation: "פיצוי",
              removeCompensation: "הסר פיצוי",
              itemsWillBeRemovedMigvanUpdate: "השתנה מגוון, כל הפריטים במכירה הנוכחית ימחקו, האם להמשיך?",
              printBon: "הדפסת בונים",
              printBonAndHoldSale: "השהייה והדפסה",
              printAndExit: "הדפסה ויציאה",
              noCouriersFound: "אין שליחים במשמרת",
              noItemsInSale: "לא ניתן לסגור מכירה רק עם פריט משלוח או TA",
              allDeliveries: "הזמנות",
              generalEmployee: "עובד כללי",
              changeQuantityError: "חובה להזין מספר חיובי או שלילי וקטן מעשרת אלפים",
              changeQuantityMustBePositiveError: "חובה להזין מספר חיובי",
              priceZeroError: "חובה להזין מספר גדול מ-0",
              noPriceError: "חובה להזין מחיר",
              HTTPError: "תקלת תקשורת\nנסה שנית, אם הבעיה ממשיכה יש לפנות לתמיכה",
              outOfStock: "אזל במלאי",
              itemNotAvailableYet: "הפריט עדיין לא זמין למכירה",
              itemNotAvailableAnymore: "הפריט כבר לא זמין למכירה",
              currencyConversionCalculator: "מחשבון המרת מטבע",
              currenciesRates:'שערי מט"ח',
              pickup: 'פיקאפ',
              pickupAll: 'פיקאפ הכל',
              payAttention: "נא לשים לב",
              cannotPickupItemsBeyondMaxPrice: "לא ניתן לשמור בפיק אפ פריט במחיר העולה על {price}{currency}",
              bigSaleLawWarning: 'החוק קובע כי לכל מכירה בסכום של 5000 ש"ח ומעלה צריך לצרף את פרטי הלקוח.<br/ > אפשר להציב לקוח קיים או להקים לקוח במערכת דרך כפתור חבר מועדון',
              bigSaleLawWarningRoshemet: 'החוק קובע כי לכל מכירה בסכום של 5000 ש"ח ומעלה צריך לצרף את פרטי הלקוח.<br/ >ניתן להזין את פרטי הלקוח באמצעות כפתור "לכבוד"',
              backToPos: "חזור למסך קופה",
              posNotAvailable:"העמדה אינה זמינה",
              ItemNotFound: "שגיאת פריט - יש פריטים בחשבון בקופה שכבר לא קיימים במערכת",
              contactSupport: "נא לפנות לתמיכה טכנית",
              toPaymentScreen: 'מעבר לתשלום',
              typeBarcode: 'הקלדת ברקוד',
              paymentsMinimalQuantityIs2: "מספר תשלומים המינימלי הוא 2",
              keyPadPaymentsTitle: 'אנא בחר את מספר התשלומים',
              sendSmsToCustomerButton: 'שליחת SMS ללקוח',
              blockedPosPinPadConnectionFailuresMessage: "הייתה בעיית תקשורת עם המסופון בזמן ביצוע הפעולה. נא לוודא כי החיבור תקין וללחוץ על אישור לביטול הפעולה.",
              cannotRescaleMoreThanOneItem: 'לא ניתן לבצע שקילה מחדש ליותר מפריט שקיל אחד במקביל. יש לבחור פריט שקיל אחד',
              cannotAddDiscountOnNonDiscountableItems: 'ישנם פריטים מסומנים שאינם יכולים לקבל הנחה. נא לבחור רק פריטים שיכולים לקבל הנחה',
              cannotChangeQuantiyForSelectedItems: "לא ניתן לשנות כמות לחלק מהפריטים שנבחרו",
              selectedItemsCount: "נבחרו",
              selectOnlyOneGroupItemToEdit: "יש לבחור פריט בודד בכדי לערוך אותו",
              selectOnlyOneItemToCheckStock: "יש לבחור פריט בודד",
              offerSelection: "בחירת מבצעים",
              offerSelectionLong: ": בחר מבצעים שברצונך להפעיל על החשבונית",
              selectOffer: "בחר מבצעים",
              noOffers: "לא נמצאו מבצעים",
              continue: "המשך",
              promotions: "מבצעים",
              backToInvoice: "חזרה לחשבונית",
              continueToPay: "המשך לתשלום",
              successfullyUpdated: "המבצעים התעדכנו בהצלחה בחשבונית",
              insertBarcode: "הזן ברקוד",
              price: 'מחיר',
              dalpakPage: {
                orderSummary: 'הזמנה',
                exitWithNoPrint: 'ללא הדפסה',
                goToPayment: 'תשלום',
                restaurantItemMenu: 'תפריט',
                mainMenuItems: 'פריטים בודדים',
              },
              dedicated: {
                toName:{
                  placeHolder: "שם הלקוח",
                  titleName:"לכבוד",
                },
                toPhone:{
                  placeHolder:"הזן מספר טלפון",
                  titleName:"הזינו מספר טלפון"
                },
                errors:{
                  emptyString: "חובה להזין ערך",
                  userCanceled:"המשתמש לחץ על ביטול",
                  notValidPhoneNumber:"המספר טלפון שהוזן אינו תקין, אנא הזן מספר טלפון תקני"
                }
              }
            },
            sendSmsToCustomerDialog: {
              title: "הכנס מספר טלפון לשליחת הודעת SMS ללקוח",
              placeHolder: "מספר טלפון",
              requiredMessage: "חובה להכניס מספר טלפון",
              invalidPhone: "מספר הטלפון שהוקלד לא תקין, יש להכניס מספר תקין"
            },
            saleRemarksDialog: {
              title: "הערה למכירה",
              placeHolder: "הכנס הערה למכירה...",
              toLongText: "לא ניתן לרשום יותר מ255 תווים"
            },
            posPaymentButtons: {
              paymentPage:'דף תשלום',
              splitPaymentAmount: 'פיצול תשלום',
              splitSalePayment: 'פיצול חשבון',
              tip: "טיפ",
              returnCash: "החזר מזומן",
              returnCreditCard: "החזר אשראי",
              returnCreditVoucher: "שובר זיכוי",
              returnOnCredit: "החזר הקפה",
              returnOnVoucher: "החזר שובר",
              invalidVoucherRefund: "לא ניתן להחזיר לשובר כאשר סכום ההחזר לא שווה לערך השובר",
              deletePrevCashPayments:"מחק תשלום מזומן קודם עבור חשבון זה",
              voucherRefundMessage:  `האם לאשר החזר שובר של {amount} ש"ח`,
              hakafaRefundMessage:  `האם לאשר החזר הקפה של {amount} ש"ח`,
              missingVoucherMessage:"חסר את הvoucher",
              punchCard: "נקב כרטיסיה",
              cash: "מזומן",
              creditCard: "אשראי",
              creditSale: "הקפה",
              beengo: "בינגו",
              tenbis: "תן ביס",
              pluxee: "פלאקסי",
              check: "צק",
              goodi: "גודי",
              dts: "DTS",
              otot: 'צמיד',
              internalCard: "כרטיס",
              vouchers: "תווים",
              creditVoucher: "שובר זיכוי",
              caveretPayment: "כוורת",
              hakafaCardPayment: "קרב כרטיס",
              banx: "banx",
              safeCash: "Kash\nCash",
              yaad: "תשלום אינטרנטי",
              multipassPolice: "מולטיפס נקודות",
              interService: "Inter Service",
              icmega: 'ICMega',
            },
            posSalesPerson: {
              title: "בחר מוכר/ת",
              content: "בחר מוכר/ת",
              tableHeader: "בחר מוכר/ת",
              tableEmptyMessage: "לא קיימים מוכרים במשמרת. יש לוודא שבוצעה כניסה לעובדים בשעון נוכחות.",
              approve: "המשך",
            },
            multipass: {
              companyNotAllowed: "אין הרשאה לחברה זו",
              emptySaleValidation: "לא ניתן להוסיף הטענת כרטיס למכירה עם פריטים נוספים",
              additionalItemsValidation: "לא ניתן להוסיף פריטים לעסקת הטענת כרטיס",
              additionalQuantityValidation: "לא ניתן להטעין יותר מכרטיס אחד בכל פעם",
              noCardSwiped: "לא הועבר כרטיס",
              deleteItemValidation: "לא ניתן למחוק פריט זה מהמכירה. יש למחוק את המכירה ולהתחיל מחדש",
              changePriceValidation: "לא ניתן לשנות מחיר של הפריט. יש למחוק את המכירה ולהתחיל מחדש",
              checkingCard: "בודק את כרטיס המולטיפס",
              errorLoading: "שגיאה בטעינת הכרטיס, יש לנסות שוב. אם השגיאה חוזרת יש ליצור קשר עם התמיכה",
              multipassError: "התקבלה שגיאה ממולטיפס בניסיון ההטענה",
              multipassErrorCode: "קוד שגיאה: ",
              loadingCard: "טוען כרטיס מולטיפס",
            },
            swipeCardDialog: {
              title: "העבר או הקלד כרטיס",
              card: "כרטיס: ",
              swipeCard: "העבר בפינפאד",
              placeholder: "העבר כרטיס או הקלד מספר כרטיס",
              noCardError: "לא הוזן כרטיס",
              swipe: "העבר כרטיס בפינפאד",
            },
            usbDevices: {
              vendorID: "מספר ספק",
              productID: "מספר התקן",
              name: "שם",
              permission: "בעל הרשאה",
              actions: "פעולות",
              close: "סגור",
              title: "התקני USB",
              askPermission: "בקש הרשאה",
              permissionTrue: "יש הרשאה",
              permissionFalse: "אין הרשאה",
            },
            employeeEditHours: {
              title: "עריכת שעות",
              dateLabel: "תאריך",
              entryTime: "שעת כניסה",
              exitTime: "שעת יציאה",
            },
            employeeReportMonth: {
              startDate: "החל מתאריך",
              endDate: "עד תאריך",
              title: "דוח שעות לתאריכים",
              print: "הדפס דוח",
              startDateBiggerThanEnd: "תאריך ההתחלה לא יכול להיות מאוחר יותר מתאריך הסיום"
            },
            reportEmployeeTime: {
              fromDate: 'מתאריך',
              toDate: 'עד תאריך',
              pos: 'קופה',
              cashier: "קופאי/ת",
              title: "דוח שעות עובדים מרוכז",
              date: 'תאריך',
              day: 'יום',
              hoursRange: 'טווח שעות',
              hours: 'שעות',
              breakTime: 'הפסקה',
              totalEmployeeHours: 'סה"כ שעות לעובד',
              noEntry: "חסר"
            },
            periodReport: {
              startDate: "מתאריך",
              endDate: "עד תאריך",
              title: "דוח תקופתי",
              titleEmp: "דוח לעובד",
              titleMonth: "דוח חודשי",
              run: "אישור",
              startDateBiggerThanEnd: "תאריך ההתחלה לא יכול להיות מאוחר יותר מתאריך הסיום",
              chooseEmployee: "בחר עובד",
              noEmployees: "לא נמצאו עובדים פעילים",
              chooseMonth: "בחר חודש",
              print: "הדפס",
              sendMail: "שלח במייל",
              printDetailedReport: 'הדפס דו"ח מפורט',
              employeeName: 'שם העובד',
              toDates: "לתאריכים",
              noFoundSales: 'לא נמצאו עסקאות בטווח התאריכים',
              detailsDebitCreditSales: 'פירוט עסקאות חיוב זיכוי',
              so: 'שו',
              receipt: 'תקבול',
              totalIncludeVat: 'סה"כ כולל מע"מ',
              salesReportByItems: 'מכירות לפי פריטים',
              totalCredit: 'סה"כ החזרים',
              returnCreditCard: "החזר אשראי",
              totalDeliveryCertificates: 'סה"כ תעודות משלוח',
              totalDefferedTaxInvoices: 'סה"כ חשבוניות מס',
              salesWithoutVat: 'מכירות ללא מע"מ',
              totalSalesWithoutVat: `סה"כ מכירות ללא מע"מ`,
            },
            periodReportSettings: {
              title: "הגדרת דוחות",
              saveSettings: "שמור שינויים",
            },
            vouchersReport: {
              title: "דוח ממשקים",
              print: "הדפס מכירות",
              printSum: "הדפס סיכום",
              selectVoucher: "בחר ממשק",
              noVouchers: "לא קיימים ממשקים",
              periodTooLong: "לא ניתן לבחור טווח גדול משנה",
              loadingVouchers: "טוען רשימת ממשקים",
              errorLoadingVouchers: "שגיאה בטעינת רשימת ממשקים",
              generatingReport: "מייצר דוח",
            },
            posPastInvoices: {
              salesperson: 'מוכר/ת',
              printOtotBracelet: "הדפסת צמידים",
              show: "הצגה",
              print: "הדפסה",
              printInvoice: 'חשבון',
              noBonsInSale: "אין בונים להדפסה בחשבונית זו",
              creditInvoice: "זיכוי",
              creditReciept: "זיכוי קבלה",
              creditTaxInv: "זיכוי חשבונית מס",
              creditShipmentInv: "זיכוי ת. משלוח",
              invoiceNumber: "מספר חשבונית",
              shortInvoiceNumber: "מס חשבונית",
              transmitTaxInvoice: "שידור לרשות המיסים",
              date: "תאריך",
              cashier: "קופאי/ת",
              quantity: "כמות",
              total: "סך מכירה",
              printBon: "הדפסת בונים",
              Bons: 'בונים',
              send: 'שלח',
              customerName: "שם לקוח",
              syncStatus: "סטטוס",
              creditWithdrawal: "ביטול משיכה",
              nextPage: "הבא",
              previousPage: "קודם",
              loading: "טוען חשבוניות",
              invoiceType: "סוג",
              legNumber: "מספר לג",
              elalTicketType:{
                both: "TKT EMD",
                tkt: "TKT",
                emd: "EMD",
                type: "סוג כרטיס",
              },
              invoiceTypes: {
                '1': 'ח. מס/קבלה',
                '2': 'זיכוי',
                '5': 'קבלה',
                '7': 'חשבונית מס',
                '8': 'ז. חשבונית מס',
                '9': 'ת. משלוח',
                '10': 'זיכוי ת. משלוח',
                '21': 'ניקוב כרטיסיה',
                '30': 'משיכת מזומן',
                '31': 'ב. משיכת מזומן'
              }
            },
            isCreditable: {
              validateCreditGlobalError: 'שגיאה כללית בזיכוי. צור קשר עם התמיכה',
              canNotCreditSaleGiftCardUsed: 'לא ניתן לזכות כרטיס נטען כבר מומש',
              maxDaysToCreditExceeded: 'לא ניתן לזכות חשבונית - החשבונית ישנה יותר מהמקסימום ימים לזיכוי שהוגדר לקופה',
              canNotCreditSalePosOffline: 'לא ניתן לזכות כשהקופה בנתק',
              canNotCreditSaleReceiptOnMinus: 'לא ניתן לזכות קבלה במינוס',
              canNotCreditSale: 'לא ניתן לזכות חשבונית',
              canNotCreditSaleNoItemsAvailableToCredit: 'לא ניתן לזכות אין פריטים זמינים לזיכוי',
              canNotCreditSaleNotSync: 'לא ניתן לזכות מכירה שלא סונכרנה לשרת',
              canNotCreditSaleReceiptLinkedToTaxInvoice: 'לא ניתן לזכות קבלה משויכת לחשבונית מס',
              canNotCreditSaleNoAvailableAmount: 'לא ניתן לזכות חשבונית ללא יתרה',
              canNotCreditSalePunchCard: 'לא ניתן לזכות מכירת ניקוב כרטיסייה',
              canNotCreditSaleTaxInvoiceLinkedToReceipt: 'לא ניתן לזכות חשבונית מס משויכת לקבלה',
              titleErrorModal: 'שגיאה זיכוי',
              canNotCreditWithdrawal: "לא ניתן לזכות משיכת מזומן",
              canNotCreditSplitPaymentSale: "לא ניתן לזכות עסקה שהיא תשלום חלקי מעסקה אחרת",
              canNotCreditSaleUsedOnChargableCardLoadBulk: 'לא ניתן לזכות קבלה שבוצע איתה טעינת כרטיסים',
            },
            posPastInvoicesDisplayItems: {
              items: "פריטים",
              invoiceNumber: "מספר חשבונית",
              invoiceDate: "תאריך",
              cashier: "קופאי/ת",
              payments: "תשלומים",
              salesperson: "מוכר/ת",
              originalPrice: "מחיר מקורי עבור יחידה",
              total: `סה"כ`,
              remarkTitle: "הערות להזמנה",
              printReturnNote: "הדפס פתק החלפה",
              return: "חזרה",
              customer: "לקוח",
              showPayments: "תשלומים"
            },
            posPastInvoicesDisplayPayments: {
              items: "פריטים",
              invoiceNumber: "מספר חשבונית",
              invoiceDate: "תאריך",
              cashier: "קופאי/ת",
              payments: "תשלומים",
              salesperson: "מוכר/ת",
              originalPrice: "מחיר מקורי עבור יחידה",
              total: `סה"כ`,
              printReturnNote: "הדפס פתק החלפה",
              return: "חזרה",
              customer: "לקוח",
              emptyPayments: "לא קיימים תשלומים",
            },
            posCreditInvoice: {
              refund: 'החזר',
              items: "פריטים",
              checkingSale: "בודק אם ניתן לזכות את המכירה",
              toCredit: "לזיכוי: ",
              creditInvoice: "זיכוי חשבונית",
              invoiceNumber: "מספר חשבונית",
              invoiceDate: "תאריך",
              cashier: "קופאי/ת",
              payments: "תשלומים",
              salesperson: "מוכר/ת",
              originalPrice: "מחיר מקורי עבור יחידה",
              total: `סה"כ לזיכוי`,
              printReturnNote: "הדפס פתק החלפה",
              return: "חזרה",
              customer: "לקוח",
              noItemsToCredit: "לא קיימים פריטים לזיכוי בחשבונית זו",
              itemsToCredit: "פריטים לזיכוי",
              byCreditVoucher: "בשובר זיכוי",
              byMoneyBack: "באמצעי תשלום",
              selectAll: "בחר הכל",
              replacement: "החלפה",
              next: "הבא",
              itemCode: "קוד פריט",
              details: "פרטים",
              description: "תיאור",
              quantity: "כמות",
              beforeDiscount: "לפני הנחה",
              amountToCredit: "סכום לזיכוי",
              quantityToCredit: "כמות לזיכוי",
              paymentType: "סוג תשלום",
              amount: "סכום",
              amountToReturn: "סכום להחזר",
              cancelReturn: "החזר כספי",
              returnPayment: "שובר זיכוי",
              change: "עודף",
              cash: "מזומן",
              credit: "אשראי",
              regular: "רגיל",
              validity: "תוקף",
              phoneNumber: "טלפון",
              confirmationNumber: "מ.אישור",
              actionCanceled: "פעולה בוטלה",
              creditingVouchers: 'מזכה תווים. אנא המתן...',
              check: "צ'ק",
              bank: "בנק",
              account: "חשבון",
              branch: "סניף",
              expiryDate: "ת.פרעון",
              creditVoucherChange: "עודף בשובר זיכוי",
              creditVoucherPayment: "תשלום בשובר זיכוי",
              creditVoucher: "שובר זיכוי",
              notForDisplay: "לא להצגה",
              voucherNumber: "מספר שובר",
              voucher: "תו/שובר",
              barcode: "ברקוד",
              creditType: "סוג אשראי",
              emptyPaymentsTable: "לא קיימים תשלומים לזיכוי/החזר בחשבונית זו",
              phone: "טלפון",
              cannotCreditLockedItem: "לא ניתן לזכות פריטים שלא קיימים בקופה זו",
              creditVouchers: "מזכה שוברים",
              loadingSale: "טוען מכירה",
              cannotBeRefundedWithMoney: "לא ניתן לזכות מכירה זו. אין אפשרות להחזר כספי בלבד והשימוש בשוברי זיכוי חסום בקופה."
            },
            customerDetailsFill: {
              header: "פרטי לקוח",
              message: "נא למלא פרטי לקוח",
              name: "שם לקוח",
              phone: "טלפון",
              id: "תעודת זהות"
            },
            paymentPage:{
              loading:"טוען דף תשלום...",
              pleaseInsertAmount:"אנא הזן סכום לתשלום",
              cardNumber:"מספר כרטיס",
              amount:"סכום",
              errors:{
                amountMustBeGreaterThanZero: "יש להזין סכום לתשלום גדול מאפס",
                generalPaymentLeftAmountMandatory: 'לא ניתן להוסיף תשלום, סכום לתשלום לא יכול להיות גבוה מהיתרה לתשלום',
                fetchDataError:"העסקה בוטלה! ישנה בעיה בפניה לדף התשלום, אנא פנה לתמיכה",
                pleaseCheckTheMpiMid:"אנא בדוק שה פרמטר mid (מספר דף תשלום) מוגדר",
                pleaseCheckSettings:"אנא וודא שההגדרות לדף התשלום מוגדרות בצורה תקינה",
                pleaseCancelOrContinue:"אנא השלימו את תהליך החיוב הידני או בטלו אותו טרם מעבר לאמצעי תשלום אחר"
              }
            },
            creditCardPayment:{
              cancelPayment:"מבצע ביטול עסקה...",
              refundPayment:"מבצע זיכוי לאשראי...",
              gateway:{
                creatingTransmitReport:"מייצר דוח שידור...",
                transmitTxs:"משדר עסקאות למתג. יש להמתין לסיום הפעולה...",
                errors:{
                  slaveTerminalNumberNotSet:"מזהה קופה EMV למתג (עבור דרישת תשלום) לא הוגדר",
                  gatewayZInquireRequest: "ישנה בעיה בפניה למתג",
                  trasmitFaild:"השידור נכשל. אנא נסה שנית.",
                  cannotCancelPayment:"שגיאה: לא התאפשר זיכוי אשראי אשר נסלק באמצעות דף תשלום",
                  cannotRefundPartialPayment:"שגיאה: בשלב זה לא ניתן לבצע זיכוי חלקי לאשראי אשר נסלק באמצעות דף תשלום",
                  generalMessage:"שגיאה: התגלתה בעיית תקשורת בניסיון זיכוי אשראי אשר נסלק באמצעות דף תשלום, אנא נסו מאוחר יותר. במידה והתקלה חוזרת על עצמה, אנא פנו לתמיכה.",
                  transmitReportNotValid:"הדוח שידור שחזר מהמתג אינו תקין, אם הבעיה חוזרת אנא פנה לתמיכה"
                }
              }
            },
            newCreditCard: {
              in: 'ב',
              amount: "סכום",
              regular: "רגיל",
              payments: "תשלומים",
              firstPayment: "תשלום ראשון",
              currency: "מטבע",
              creditPayments: "קרדיט תשלומים",
              isracardGamish: "ישראכרט גמיש",
              visaPlus30: "ויזה + 30",
              directCharge: "חיוב מיידי",
              phone: "טלפון",
              confirmationNum: "מספר אישור",
              cardNumber: "מ. כרטיס",
              msrAutomatic: "העבר כרטיס",
              msrManual: "הקלדה ידנית",
              debitType: "סוג עסקה",
              AMOUNT_MUST_BE_BIGGER_THAN_ZERO: "יש להזין סכום לתשלום גדול מאפס",
              AMOUNT_MUST_BE_SMALLER_THAN_ZERO: "בהחזר אשראי הסכום חייב להיות קטן מאפס",
              RETVAL_ERROR_OPENING_SERIAL_PORT: "בעיה בחיבור לקורא הכרטיסים - אנא נסה שנית",
              RETVAL_TIMEOUT: "תם הזמן - אנא העבר כרטיס/הקלד מספר כרטיס מחדש",
              GENERAL_ERROR: "שגיאה כללית, אנא נסה שוב. אם הבעיה חוזרת על עצמה יש לפנות לתמיכה",
              EXP_DATE_MISSING: "לא הוקלד תוקף/תוקף לא חוקי",
              PASS_CARD_MANUAL_MESSAGE: 'אנא הקלד מספר כרטיס, תוקף ו-CVV. לביטול יש ללחוץ על הכפתור האדום בקורא הכרטיסים',
              PASS_CARD_AUTOMATIC_MESSAGE: 'אנא העבר כרטיס - לביטול - יש ללחוץ על הכפתור האדום בקורא הכרטיסים',
              NEED_SIGNATURE: 'יש לחתום על קורא הכרטיסים',
      minAmountForPaymentsNotMet: 'הסכום המינימאלי האפשרי לביצוע תשלומים הוא {amount}',
              timeout: "תם הזמן",
              msr: {
                201: "בעיה בקריאת מספר כרטיס",
                202: "בעיה בהצפנה",
                203: "בעיה בפרמטרים של קורא כרטיסים - אנא פנה לתמיכה",
                204: "קלט לא חוקי - אנא נסה שנית. אם הבעיה חוזרת על עצמה אנא פנה לתמיכה",
                205: "קוד מטבע לא חוקי",
                206: "קוד שפה לא חוקי",
                207: "סוג קלט לא תקין - אנא נסה שנית. אם הבעיה חוזרת על עצמה אנא פנה לתמיכה",
                208: "סוג עסקה לא חוקי",
                209: "לא ניתן להתחיל עדכון תוכנה",
                210: 'הפעולה בוטלה ע"י המשתמש',
                211: "Aborted by the ECR - אנא פנה לתמיכה",
                212: "קוד בקשה לא חוקי - אנא פנה לתמיכה",
                generalError: "שגיאה כללית - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
                noConnection: "שגיאה קריטית בקורא הכרטיסים.\n יש לנסות להעביר שוב כרטיס.\nבמידה והשגיאה חוזרת על עצמה יש לפנות לתמיכה."
              }
            },
            yaadPayment: {
              required: "שדה חובה",
              amount: "סכום",
              taz: "תעודת זהות",
              firstName: "שם פרטי",
              lastName: "שם משפחה",
              email: "דואר אלקטרוני",
              phone: "סלולרי",
              sendingPaymentLinkToCustomer: "שולח קישור לתשלום ללקוח...",
              waitingForCustomerToPay: "ממתין לקבל תשלום מהלקוח...",
              verifyError: "אימות תשלום נכשל",
              verifyingPayment: "מוודא תשלום",
              commitingPayment: "מבצע חיוב",
              errorCommitingPayment: "שגיאה בביצוע חיוב",
              partialPaymentItem: "תשלום חלקי",
              discountsItem: "הנחות ומבצעים",
              paymentId: "אסמכתה",
              cancelError: "שגיאה בביטול עסקה",
              cancellingTransaction: "מבטל עסקה...",
              invalidTaz: "תעודת זהות לא תקינה",
              invalidPhone: "מספר טלפון לא תקין",
              invalidName: "שדה שם פרטי ריק או מכיל תווים לא חוקיים",
              invalidLastName: "שדה שם משפחה ריק או מכיל תווים לא חוקיים",
              invalidEmail: "כתובת דואר אלקטרוני לא תקינה",
              invalidAmount: "סכום לא תקין",
              dealDoesntExists: "עסקה לא קיימת",
              errorSendingSms: "שגיאה בשליחת SMS",
      errorCommitingPaymentWithErrorCode: "שגיאה בביצוע חיוב קוד שגיאה: {code}",
            },
            tipPayment: {
              invalidAmount: "סכום לא תקין, הסכום חייב להיות גדול מ-0",
      rawAmount: "סכום ({currency})",
              percentageAmount: "אחוז (%)",
      finalAmount: "סכום סופי ({currency})",
              resetTip: 'אפס סכום',
              tipInCurrency: 'טיפ בשקלים',
              tipInPercentage: 'טיפ באחוזים',
            },
            posPayment: {
              headerTitle: 'מסך תשלום',
              cantDeleteOrderVoucher: 'לא ניתן למחוק תשלום שהגיע בהזמנה, התשלום בוצע באפליקציה חיצונית לקופה',
              title: "קבלת תשלום",
              tip: 'טיפ',
              creditCard: "אשראי",
              cash: "מזומן",
              vouchers: "תווים",
              creditVoucher: "שובר זיכוי",
              check: "צ'ק",
              tenbis: "תן ביס",
              pluxee: "פלאקסי",
              goodi: "גודי",
              otot: 'צמיד',
              caveretPayment: "תשלום כוורת",
              multipassPolice: "מולטיפס נקודות",
              amount: "סכום",
              resetCash: "אפס מזומן",
              resetTip: "אפס טיפ",
              barcode: "ברקוד",
              companyCode: "קוד חברה",
              selectCompany: "בחר חברה",
              voucherType: "סוג שובר",
              checkBalance: "בירור יתרה",
              voucherNumber: "מספר שובר",
              bank: "בנק",
              branch: "סניף",
              account: "חשבון",
              checkNumber: "מספר צ'ק",
              dueDate: "תאריך פרעון",
              numberOfPayments: "מס תשלומים",
              id: "ת.ז",
              guarantorId: "ערב",
              manualConfirmationNumber: "אישור ידני",
              clearFields: "נקה שדות",
              due: "לתשלום",
              paid: "שולם",
              confirm: "אישור",
              remaining: "יתרה לתשלום",
              back: "חזרה",
              selectCurrency: 'בחירת מטבע',
              details: "פירוט",
              totalAmountCurrencyToPay: 'יתרה לתשלום ב{currency}',
              ratePaymentCurrency: 'שער המרה',
              dueInCurrency: 'לתשלום ב{currency}',
              detailMultiCurrenciesPayments: 'פירוט מטבעות ששולם',
              emptyDetailMultiCurrenciesPayments: 'לא נמצא מטבעות ששולמו במכירה',
              banx: "banx",
              safeCash: "KashCash",
              yaad: "תשלום אינטרנטי",
              interService: "Inter Service",
              missingRequiredMixinFields: 'Payment View "{id}" does not have the required fields for mixin to work',
              voucherNotFound: "התו לא נמצא",
              voucherOverNumberCanUseOnSale: 'שובר {name} מוגבל ל-{number} שימושים בחשבון',
              hakafa: "הקפה",
              paymentPage:"דף תשלום",
              icmega: "icmega",
              loadPayment: 'טוען מסך תשלום',
              pay: "תשלום",
              confirmPay: "תשלום",
              cancelPay: "ביטול תשלום",
            },
            elal:{
              salesWithoutPNR:{
                header: "המכירה בוצעה ללא משיכת PNR",
                cancel:"ביטול",
                ok:"אישור",
                content:{
                  header:"בבקשה השלימו מזהה TKT או EMD בהתאם"
                }
              },
              errors:{
                saleWithoutPNR:"נתוני PNR לא הוזנו בצורה תקינה אנא אנסה שנית או פנה לתמיכה",
                ticketNumberLength:"קוד כרטיס חייב להיות עד 20 תווים",
                pnrNotFound: "ה-PNR לא נמצא, האם תרצה להמשיך בתהליך ללא איתור ה-PNR?",
                somePNRItemsCouldNotBeAdded: 'חלק מפריטי ה-PNR לא יוכלו להתווסף למכירה. זה יכול',
                pnrItemsCouldNotBeAdded: 'פריטי ה-PNR לא יוכלו להתווסף למכירה, האם תרצה להמשיך בהתליך ללא הוספת פריטים?',
                errorOnAddingPNRItems: 'אירעה שגיאה בעת הכנסת פריטי PNR.',
                cannotAddItemsPNRToSale: 'לא ניתן להוסיף פריטים לאחר משיכת PNR',
                cannotDeletePNRItem: 'לא ניתן למחוק פריט PNR במכירת PNR',
                cannotChangePricePNRItem: 'לא ניתן לשנות מחיר פריט PNR',
                cannotChangeQuantityPNRItem: 'לא ניתן לשנות כמות פריט PNR',
                cannotDuplicatePNRItem: 'לא ניתן לשכפל פריט PNR',
                cannotEditPNRItem: 'לא ניתן לעדכן פריט PNR',
                errorNotInGroupContent: "משתמש  {username} אינו שייך לקבוצת ההרשאות המותרת להפעלת הקופה, אנא פנה לתמיכה",
                errorLoadingADGroups: "שגיאה בגישה למערכת ההרשאות של אלעל.\n אנא סגור את הקופה ונסה שוב. במידה והתקלה חוזרת על עצמה אנא פנה לתמיכה",
                groupsErrorHeader: "שגיאת הרשאות",
              },
              PNRDialog: {
                pleaseEnter: 'אנא הזן PNR',
                elalSequence:"מזהה אלעל לעסקה: {seq}",
                OrderNumber: "מספר הזמנה PNR",
                enterPnrTitle:"אנא הזינו PNR למשיכה ממערכת אמדאוס",
                cols: {
                  RecId: 'מזהה רשומה',
                  cusName: 'שם לקוח',
                  FlightNum: 'טיסה',
                  org: 'מקור',
                  dest: 'יעד',
                  serviceCD: 'פריט',
                  serviceDesc: 'תיאור',
                  price: 'מחיר',
                }
              },
            },
            updateCatalog: {
              title: "עדכון מידע",
              updateCatalogDate: "עדכון מידע אחרון התבצע בתאריך",
              statusNotOK: "שים לב! מצב המידע בקופה אינו תקין. כדי לקבל מידע עדכני יש ללחוץ על 'רענון כל מידע'",
              status: "מצב",
              back: "חזרה",
              updateCatalog: "רענון כל המידע",
              updateCatalogRoshemet: "עדכון פרמטרים",
              updateFullCatalog: "רענון כל המידע",
              catalogEmpty: "לא התבצע עדכון מידע אף פעם",
              preperingCatalogLoad: "מכין עדכון קטלוג - אנא המתן",
              generatingCatalog: "מייצר קטלוג - אנא המתן",
              generatingItemListFile: "מייצר קובץ פריטים",
              flightsCatalogError: 'ארעה שגיאה בעת עדכון הקטלוג לפני הטיסה, והטיסה נותקה אוטומטית. למידע נוסף, לחץ על כפתור ה-"עדכון מידע"',
              offlineError: "קופה בנתק, אין אפשרות לעדכן מידע",
              unmatchedDeviceId: "קופה אינה מזוהה - אנא פנה לתמיכה",
              physicalIdRemoved: "מזהה PhysicalId לא נמצא, יש לצאת ולהתחבר מחדש לקופה",
              unmatchedPhysicalId: "הID בקופה לא תואם את הID בשרת. נא ליצור קשר עם התמיכה.",
              generealError: "אירעה שגיאה בעת עדכון מידע - אנא פנה לתמיכה"
            },
            mainHeader: {
              elalSequence:"מזהה אלעל לעסקה",
              pos: "קופה",
              cashier: "קופאי/ת",
              newCatalogExists: "קיים קטלוג חדש",
              flightId: "מספר נסיעה",
              flihgtName: "טיסה",
              legName: "לג",
              legRoute: "מסלול",
              totalSales: "סה\"כ מכירות"
            },
            logoutScreen: {
              logoutFromUser: "האם להתנתק ממשתמש",
              password: "סיסמה",
              clearId: "ניקוי ID",
              title: "התנתקות",
              STATUS_IDLE: "הקלד סיסמה",
              INVALID_PASSWORD: "סיסמה לא נכונה",
            },
            selectCashier: {
              title: "בחר קופאי/ת",
              noCashiers: "אין קופאים לחנות זו‫.‬ אנא פנה לתמיכה",
              cashierExit: "יציאת קופאי",
              lockPos: "נעילת קופה",
            },
            posSearchInvoices: {
              salesperson: 'מוכר/ת',
              printBon: 'הדפסת בונים',
              bons: 'בונים',
              send: 'שלח',
              invoice: 'חשבונית',
              search: 'חיפוש',
              storeNumber: "מספר חנות",
              orSelectStore: "או בחר חנות",
              fromDate: "מתאריך",
              toDate: "עד תאריך",
              noSaleSelected: "לא נבחרה מכירה",
              fromAmount: "מסכום",
              toAmount: "עד סכום",
              invoiceNumber: "מספר חשבונית",
              customerName: "שם לקוח",
              customerPhone: "טלפון לקוח",
              paymentType: "סוג תשלום",
              itemCode: "קוד פריט",
              cc4digits: "אשראי 4 ספרות",
              phone: "טלפון",
              ID: "ת.ז",
              show: "הצגה",
              print: "הדפסה",
              creditInvoice: "זיכוי חשבונית",
              creditReciept: "זיכוי קבלה",
              creditTaxInv: "זיכוי חשבונית מס",
              creditShipmentInv: "זיכוי ת. משלוח",
              date: "תאריך",
              credit: "זיכוי",
              cashier: "קופאי/ת",
              quantity: "כמות",
              total: "סך מכירה",
              syncStatus: "סטטוס",
              errorInSearch: "שגיאה בחיפוש חשבונית",
              store: "חנות",
              newSearch: "חיפוש חדש",
              orderNumber: "מספר הזמנה",
              street: "רחוב",
              city: "עיר",
              deliveryType: "סוג משלוח",
              dedicatedTo: "לכבוד"
            },
            shipmentInv: {
              splitPaymentForInvoice: "פיצול תשלום למסמך",
              minusBecauseSplitTaxPayment: "הפחתה בגין חשבונית מס מספר",
              minusBecauseSplitShipmentPayment: "הפחתה בגין תעודה מספר"
            },
            printing: {
              numberOrder: "הזמנה מספר",
              cancelOrderNumber: "ביטול הזמנה מספר",
              updateOrderNumber: "עדכון הזמנה מספר",
              notPaid: 'לא שולם',
              a4printNotSupported: 'הדפסת A4 אינה נתמכת ב{at}',
              printerError: 'שגיאה בהדפסה',
              moveItemsDalpak: '***העברת פריטים***',
              fromCounter: 'מדלפק',
              courier: 'שליח',
              toCounter: 'אל דלפק',
              dalpakMovement: 'העברת דלפק',
              dalpakMovement_new_rest: 'העברת שולחן',
              orderer: 'שם מזמין',
              dinersDetails: 'פירוט סועדים',
              dinersNumber: 'סועדים',
              totalSittingDiners: 'סה"כ סועדים בישיבה',
              totalDeliveriesDiners: 'סה"כ סועדים במשלוחים',
              sittingDinersAvg: 'ממוצע לסועד בישיבה',
              deliveriesDinersAvg: 'ממוצע לסועד במשלוחים',
              deliveriesAvg: 'ממוצע למשלוח',
              counter: "דלפק",
              counter_new_rest: "שולחן",
              fromCounter_new_rest: "משולחן",
              toCounter_new_rest: "אל שולחן",
              wifiPrinter: "מדפסת WiFi",
              internalSunmiPrinter: "מדפסת מובנת",
              continuePrintingTitle: 'הדפסה',
              continuePrintingMessage: 'אנא חתוך את הנייר ולחץ "אישור" להמשך הדפסה',
              promotion: 'מבצע',
              payments: 'תשלומים',
              terminalName: 'שם מסוף',
              terminalNumber: 'מספר מסוף',
              softwareVersion: 'גרסת תוכנה',
              buisnessNumberAtCreditCompany: 'מספר עסק בחברת האשראי',
              transactionDateTime: 'תאריך ושעת עסקה',
              cardName: 'שם כרטיס',
              cardNumber: 'מספר כרטיס',
              voucherNumber: 'מספר שובר',
              transactionType: 'סוג עסקה',
              approvalNumber: 'מספר אישור',
              approvalAuthority: 'גורם מאשר',
              executionMethod: 'אופן ביצוע',
              applicationIdInChip: 'זיהוי ישום בשבב',
              creditType: 'סוג אשראי',
              atc: 'סידורי ש',
              cardSeqNumber: 'סידורי כ',
              firstPayment: 'תשלום ראשון',
              paymentsCount: 'מספר תשלומים',
              additionalPayments: "נוספים",
              everyOtherPayment: 'כל תשלום נוסף',
              businessNumber: 'מספר עסק',
              printingDateAndHour: 'תאריך ושעת הדפסה',
              salespersonName: 'שם מוכרן',
              order: 'הזמנה',
              orderCancel: 'ביטול הזמנה',
              itemsAttachmentForTA: "ספח פריטים לאיסוף עצמי",
              itemsAttachmentForSitting: "ספח פריטים לישיבה במקום",
              itemsAttachmentForDelivery: "ספח פריטים למשלוח",
              totalPaymeysByMethod: 'סה"כ תקבולים לפי אמצעי תשלום',
              sum: 'סכום',
              count: 'מונה',
              totalPayments: 'סה"כ תקבולים',
              defferedSalesDetails: 'פירוט קניות בהקפה',
              description: 'תיאור',
              defferedTaxInvoice: 'הקפה בחשבוניות מס',
              defferedDeliveryCertificate: 'הקפה בתעודות משלוח',
              totalDefferedSales: 'סה"כ קניות בהקפה',
              price: "מחיר",
              quantity: "כמות",
              gram: 'גרם',
              kilogram: "קג'",
              itemNameCode: 'שם/מק"ט',
              salesperson: "מוכר/ת",
              noShadowPlaceholder: "שגיאה באתחול הדפסת תמונות. ככל הנראה גרסאת דפדפן אינה תואמת",
              fromDate: "מתאריך",
              toDate: "עד תאריך",
              salesCount: "כמות עסקאות",
              withdrawalsAmount: "סכום משיכות",
              feesAmount: "סכום עמלות",
              withdrawlReport: "דו\"ח משיכת מזומן",
              invoiceTitle: "חשבונית",
              withdrawn: "נמשך",
              surcharge: "עמלה",
              androidRongtaPrinterError: "בעיה בהדפסה - אנא וודא כי המדפסת מחוברת למכשיר וכי יש לה הרשאה",
              androidPrintingError: "שגיאה בהדפסה\r\n יש לנסות להדפיס שוב לאחר הטיפול בתקלה\r\nחשבוניות ניתן להדפיס ממסך 'חשבוניות קודמות'",
              t2liteconfigerror: "וודא כי המדפסת מוגדרת כמדפסת Rongta",
              credit: "כרטיס אשראי",
              hourReport: "דיווח שעות",
              cutlery: 'לקוח מוותר על סכו"ם',
              forDate: "נכון לתאריך",
              forTime: "נכון לשעה",
              left: "יצא\/ה",
              entered: "נכנס\/ה",
              entry: "כניסה",
              exit: "יציאה",
              hours: "שעות",
              totalSales: 'סה"כ מכירות',
              declarationOpenPos: "הצהרת פתיחת קופה",
              declarationPosStatus: "הצהרת מצב קופה",
              cashByCoinDetails: `פירוט מזומן בקופה לפי מטבעות`,
              cashByCoinCurrenciesDetails: {
                calcedCurrency: 'מחושב',
                statedCurrency: 'מוצהר',
                statedAndCalcedGap: 'הפרש',
              },
              cashByCoinAndBillsDetails: `פירוט מזומן בקופה לפי שטרות מטבעות`,
              totalInPos: 'סה"כ בקופה',
              reportForCard: "דוח תנועות לכרטיס",
              date: "תאריך",
              hour: "שעה",
              transactionDetail: "פירוט תנועות",
              balance: "יתרה",
              currentCurrency: 'ש"ח',
              bill: "שטר",
              coin: "מטבע",
              currency: "מטבע",
              POS: "קופה",
              cashier: "קופאי",
              xReport: "דוח X",
              zReport: "דוח Z",
              xReportForPos: 'דוח X לקופה',
              posStatusReport: "דוח מצב קופה",
              reportNumber: "מספר דוח",
              unifiedReport: "דוח מאוחד",
              unifiedReportNumber: "מספר דוח מאוחד",
              middleReport: "דוח ביניים",
              zSalesAnalysis: "ניתוח מכירות ל Z",
              cancels: "ביטולים",
              canceledItemsNumber: "מספר פריטים שבוטלו",
              refunds: "החזרים",
              itemsRefundsCount: "מספר פריטים שהוחזרו",
              totalDiscounts: 'סה"כ הנחות',
              totalCustomers: 'סה"כ לקוחות',
              totalDeliveryCertificates: 'כמות תעודות משלוח',
              averageCustomerSale: 'מכירה ממוצעת ללקוח',
              soldItemsAmount: 'כמות פריטים שנמכרו',
              averageCustomerAmount: 'כמות ממוצעת ללקוח',
              averageItemPrice: 'מחיר ממוצע לפריט',
              salesAbove5000ILS: 'עסקאות מעל 5000 ש"ח',
              canceledBonnes: 'בונים שבוטלו',
              canceledBonnesWorth: 'שווי בונים שבוטלו',
              departmentSalesNet: 'מכירות למחלקה נטו',
              itemDetailsInZReport: 'פירוט פריטים',
              creditCardTransmittingReport: 'דוח שידור אשראי',
              total: 'סה"כ',
              vat: 'מע"מ',
              voucherForItem: "שובר לפריט",
              original: "מקור",
              copy: "העתק",
              returnNoteNo: "פתק החלפה מס",
              creditVoucher: "שובר זיכוי",
              customer: "לקוח",
              for: "לכבוד",
              customerNumber: "מספר לקוח",
              ID: "ת.ז",
              phone: "טלפון",
              producedInInvoice: "הופק בחשבונית",
              producedForInvoice: "הופק עבור חשבונית",
              company: "חברה",
              branch: "סניף",
              year: "שנה",
              creditAmount: "לזיכוי",
              voucherExpiration: "תוקף הזיכוי הוא לשנתיים מיום מסירתו",
              limitingConditionsForVoucher: "אין תנאים מגבילים לשימוש בתו זה",
              amount: "סכום",
              customerName: "שם לקוח",
              signature: "חתימה",
              totalForPayment: "לתשלום",
              totalPaid: "שולם",
              originalTotalForPayment: "סכום חשבון מקורי",
              totalForRefund: "לזיכוי",
              totalOwsVat: 'סה"כ חייב במע"מ',
              totalIncVat:'סה"כ כולל במע"מ',
              itemCode: "פריט",
              name: "שם",
              invReceipt: "חשבון/קבלה",
              InvReceiptAppended: "ספח לחשבון/קבלה",
              cancelInvReceipt: "ביטול חשבון/קבלה",
              taxInvReceipt: "חשבונית מס קבלה",
              taxInv: "חשבונית מס",
              taxInvReceiptAppended: "ספח לחשבונית מס קבלה",
              taxCreditReceipt: "חשבונית מס קבלה זכות",
              taxCreditReceiptAppended: "ספח לחשבונית מס זיכוי",
              cancelattionInvAppended: "ספח לביטול חשבון/קבלה",
              taxCreditInvoice: "חשבונית מס זיכוי",
              refundMethod: 'אופן החזר כספי',
              invoice: "חשבון עסקה",
              orderNumber: "מספר הזמנה",
              saleMidBill: "חשבון",
              creditInvoice: "חשבון עסקה זכות",
              creditTaxInvoice: "חשבונית מס זיכוי",
              deliveryNote: "תעודת משלוח",
              returnNote: "תעודת החזרה",
              cash: "מזומן",
              creditCard: "אשראי",
              creditSale: "הקפה",
              beengo: "בינגו",
              tenbis: "תן ביס",
              pluxee: "פלאקסי",
              check: "צק",
              creditTaxInvReceipt: "חשבונית מס קבלה זכות",
              receipt: "קבלה",
              totalFreeOfVat: 'סה"כ לא חייב במע"מ',
              inThisSaleYouSaved: `בקניה זו חסכת`,
              items: "פריטים",
              discount: "הנחה",
              taxIdNum: "ע.מ",
              customerIdNumber: "ח.פ/ת.ז",
              companyNum: "ח.פ",
              noTaxNum: "ע.פ",
              amota: "ע.ר",
              addedCashPos: "הוספת קרן פתיחה לקופה",
              addedCashSum: "סכום שנוסף לקרן הפתיחה",
              grandTotal: "גראנד טוטאל",
              withdrawalSum: "סכום משיכה",
              manual: 'ידני',
              day: 'יום',
              break: 'הפסקה',
              chargableCard: 'כרטיס נטען',
              valueCarChargableCard: "טעינת כרטיס Value Card",
              interfaces: 'ממשקים',
              time: 'שעה',
              printer: 'מדפסת',
              merakezInvoiceNum: "חשבונית מס ריכוז מספר",
              merakezetInvoiceDate: "תאריך חשבונית",
              documentNumber: "מספר תעודה",
              saleDate: "מועד רכישה",
              hakafaCustomerBalance: "יתרתך הנוכחית",
              salesDiscountReport: 'פירוט הנחות',
              forItem: 'עבור',
              bankDeposit: {
                depositTime: "זמן הפקדה",
                employeeName: "שם המפקיד/ה",
                totalCashZdsSelected: `סה"כ מזומן דוחות זד`,
                totalCashDeclared: `סה"כ מזומן בהפקדה`,
                cashDiff: `סה"כ הפרש`,
                comments: `הערות`,
                zNumber: `מס זד`,
                createdAt:`תאריך`,
                totalAmount: `סה"כ זד`,
                totalZCashAmount: `מזומן בזד`,
                bank: 'בנק',
                branchNumber: 'מספר סניף',
              },
              performa: {
                yes: 'כן',
                no: 'לא',
                askToPrintWithoutItemPricesMessage: "האם להדפיס את החשבון עם מחיר לצרכן?"
              },
              taxAuthorityConfirmationNumber: "הקצאה מספר",
            },
            reports: {
              xReport: "דוח X",
              zReport: "דוח Z",
              zRikuz: "דוח ריכוז Z",
              employeesHoursCentered: "דוח שעות עובדים מרוכז",
              employeesBonus: "דוח בונוסים לעובדים",
              employeesHours: "דוח שעות לעובד",
              exceptions: "דוח חריגים",
              unified: "דוח במבנה אחיד",
              promotions: "דוח מבצעים",
              vouchers: "דוח שוברים",
              customerSales: "דוח מכירות מועדון לקוחות",
              hakafaCustomerSales: "דוח מכירות לקוחות הקפה",
              selectXReport: "בחר דוח X",
              forPos: "לקופה",
              forStore: "מאוחד לחנות",
              generatingXReport: "מייצר דוח X",
              printLegSalesReport: "הדפס דוח מכירות",
              printCurrenciesReport: "הדפס דוח שערי מטבע",

            },
            zxReport: {
              showMultiCurrencyDetails:"הצג פירוט מטבעות",
              failedLoadingSales: 'אירעה שגיאה בטעינת המכירות, אנא נסה שנית. אם הבעייה חוזרת על עצמה, אנא פנה לתמיכה',
              totalTips: 'סה"כ טיפ',
              total: 'סה"כ',
              reportNumber: 'מספר דוח',
              zNumber: "מס. Z",
              reportDate: "תאריך הדוח",
              invoicesCount: "כמות חשבוניות",
              totalInvoices: 'סה"כ חשבוניות',
              totalCash: 'סה"כ מזומן',
              totalCredit: 'סה"כ אשראי',
              totalVat: 'סה"כ מע"מ',
              producingTime: 'זמן הפקה',
              status: 'סטאטוס',
              statusDate: 'תאריך סטאטוס',
              paymentType: "סוג תשלום",
              inPosAmount: "כמות בקופה",
              inPosSum: "סכום בקופה",
              voucherType: "סוג שובר",
              producingVouchers: "הנפקת שוברי זיכוי",
              totalPluxee: 'סה"כ פלאקסי',
              totalTenbis: "סה״כ תן ביס",
              totalGoody: 'סה"כ גודי',
              totalDTS: 'סה"כ DTS',
              totalMishloha: 'תשלום במשלוחה',
              totalYaad: 'סה"כ יעד שריג',
              totalBuyingVoucher: 'סה״כ שובר קניה',
              totalKofaPayment: 'סה״כ תשלום בהקפה',
              noVouchersInPos: 'לא קיימים שוברי קנייה בקופה.',
              noPaymentsInPos: 'אין סוגי תשלומים במערכת.',
              noReportsToRestore: 'אין דוחות קודמים לשחזור',
              deliveryPayments: 'סה"כ תקבולים ממשלוחים',
              deliveriesAmount: 'כמות משלוחים',
              outsideCash: 'מזומן בחוץ',
              canceledBonnes: 'בונים שבוטלו',
              totalPaymentsWithoutOpeningStatement: 'סה"כ תקבולים ללא קרן פתיחה',
              actualCashInPos: 'סך כל מזומן הקופה בפועל',
              totalDeliveryCertificates: 'סה"כ תעודות משלוח',
              totalDefferedTaxInvoices: 'סה"כ חשבוניות מס (הקפה)',
              showDetails: 'הצג פירוט',
              totalPaidit: 'סה"כ פיידאיט',
              dalpaks: {
                dalpaksReport: "דוח דלפקים",
                name: "שם",
                amount: "כמות",
                totalDiners: "כמות לקוחות",
                totalOpenTables: "כמות שולחנות פתוחים",
                OpenTablesSum: "סכום שולחנות פתוחים",
              }
            },
            itemDiscount: {
              step1Title: "שלב 1: בחירת הנחה",
              step2Title: "שלב 2: בחירת גובה הנחה",
              step3Title: "שלב 3: אישור מנהל",
              setPrice: "קביעת מחיר",
              selectedDiscountDetails: "פרטי ההנחה הנבחרת",
              approvedPct: "מאושר",
              approvedManagerPct: "מנהל",
              specialDiscount: "הנחה מיוחדת",
              specialDiscountShort: "מיוחדת",
              specialDiscountError: "לא מוגדרת הנחת חשבון. לא ניתן לבצע הנחה מיוחדת בקופה ללא הנחת חשבון",
              selectItems: "בחירת פריטים",
              discountName: "שם ההנחה",
              approvedDiscount: "אחוז מאושר",
              managerDiscount: "אחוז מנהל",
              confirmedDate: "תאריך אישור",
              allowerName: "שם מאשר",
              customerName: "שם לקוח",
              comment: "הערה",
              selectSaleDiscount: "בחר הנחת חשבון",
              noDiscountsWereFound: "לא נמצאו הנחות למימוש.",
              managerCard: "כרטיס מנהל",
            },
            employeeStoreConnection: {
              linkEmpsToStore: "קישור עובדים לחנות",
              noAvailableEmps: "לא קיימים עובדים בחנות",
              back: "חזרה",
              addEmpToStore: "הוסף עובד לחנות",
              fingerprint: "טביעת אצבע"
            },
            params: {
              setOrientaion: "הגדרת כיוון המסך",
              orientationBo: "הגדרת ברירת מחדל",
              orientationLandscape: "אופקי",
              orientationPortrait: "אנכי",
              vorlonSupportStart: "אפשר תמיכה מרחוק",
              vorlonSupportStop: "הפסק תמיכה מרחוק",
              pinpadMaintenance: "תחזוקת מסופון",
              androidPinpadMaintenance: "תחזוקת מסופון ב-Android",
              techActions: "פעולות טכנאי",
              transmit: "שדר לשבא\\מתג",
              printLastTransmit: "הדפס שידור אשראי אחרון",
              skipCashWithdrawalCorrectnessCheck: "בטל בדיקת תאימות במשיכת מזומן",
              updatePinPad: "עדכן גירסת מסופון",
              settings: "הגדרות",
              posPrinter: "מדפסת קופה",
              printerName: "שם מדפסת",
              scale: "משקל",
              displaySettings: "הגדרות תצוגה",
              useBigRoshemetLayout: "השתמש בתצוגה מוגדלת",
              manageEmployees: "נהל עובדים",
              version: "גרסה",
              newAddonInstalled: "האם תוסף חדש מותקן?",
              addonVersion: "גרסאות קבצי תוסף",
              primaryPosExists: "האם ישנה קופה ראשית",
              isPrimaryPos: "האם קופה ראשית",
              pinPadPortNum: "מספר פורט לקורא כרטיסים",
              multipassString: "מחרוזת מולטיפס",
              cleanSaleMinusOne: "ניקוי מכירה מינוס 1",
              transmitErrorCheck: "שידור בקרת שגיאות שבא",
              resetLastTimeSaleSyncFailed: "שלח עסקאות שנכשלו לשרת",
              selectBonPrinter: "שיוך מדפסת בונים",
              sendEMVLogsToCaspitFTP: "שידור לוגים לשרת FTP",
              callCaspitTechServer: "עדכון פרמטרים כספיט",
              updateEmvIpAddressHeader: "עדכון כתובת IP למסוף אשראי",
              updateEmvIpAddress: "כתובת IP למסוף אשראי עודכן בהצלחה",
              androidAppVersion: "גירסת אפליקציה",
              PCLVersion: "גירסת PCL",
              androidDeviceSerialNumber: "מספר סידורי של המכשיר",
              posVersion: "גירסת קופה",
              posVersionDate: "תאריך עדכון גירסת קופה",
              validCurrencies: "שערי מטבעות תקינים",
              hardwareAndPosInfo: "הגדרות חומרה ומידע נוסף",
              startPCL: "התחל PCL",
              stopPCL: "הפסק PCL",
              restartingPinpadConnection: "איתחול תקשורת",
              usbDevices: "התקני USB",
              pinpadSerialMode: "עבודה במצב Serial",
              pinpadUSBMode: "עבודה במצב USB",
              lunchTeamViewer: "הפעל TeamViewer",
              remoteAssistance: "שליטה מרחוק",
              useWifiPrinter: "שימוש במדפסת רשת",
              closeFlightWithoutPinpind: "סגור טיסה ללא שידור",
              clearStorage: "פינוי מקום בדיסק",
              storageUsage: "מקום פנוי בדיסק",
              credits: 'תודות',
              openSourceDevs: 'פיתוחי קוד פתוח',
              terminalCheck: ' שב”א - ללא עסקאות'
            },
            storagemanager: {
              usage: "מקום בשימוש בדיסק",
              makeSureOnlineBeforeClearStorage: "נא לוודא שהנך מחובר לאינטרנט לפני ביצוע פעולה זו",
              storageUsage: "מקום פנוי בדיסק",
              performingCleaningProcess: "מבצע תהליך ניקיון"
            },
            fullSearch: {
              title: "חיפוש פריט",
              searchLabel: "חפש לפי קוד פריט/ברקוד/תיאור פריט",
              search: "חיפוש",
              explanation: 'לחץ על "בחר" בתוצאות חיפוש בכדי להוסיף פריט למכירה. ניתן לנווט עם החצים בתוצאות החיפוש.',
              code: "קוד",
              description: "תאור",
              price: "מחיר",
              color: "צבע",
              size: "מידה",
              supplier: "ספק",
              barcode: "ברקוד",
              actions: "פעולות",
              select: "בחר",
              promotion: "מבצעים",
              inventory: "מלאי",
              noItemsFound: "לא נמצאו פריטים",
              none: 'אין',
              itemPromotionsTitle: 'מבצעים לפריט',
              notFoundItemPromotionsTitle: 'לא נמצא מבצעים לפריט',
            },
            balanceCheck: {
              title: "ברור יתרה",
              message: "העבר כרטיס",
            },
            shekuloTovBooks: {
              "302": "ברקוד חייב להיות עם ערך מספרי",
              "304": "לא נמצא ספר, אנא נסה ברקוד אחר",
              "303": "ברקוד חייב להיות מספרי ובאורך של 6-8 תווים",
              "305": "הספר לא זמין למכירה",
              "701": "לא ניתן לסגור מכירה, אנא בדוק את הברקודים שנשלחו",
              "404": "ברקודים לא קיימים במערכת, אנא נסה ברקוד אחר",
              "genericError": "שגיאה כללית, אנא נסה שוב. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              "genericCommunicationError": "שגיאה בתקשורת עם השרת ספרים, אנא נסה שוב.",
              "importingBookInfo": "מייבא פרטי ספר",
              "outOfStock": "אין מלאי לפריט",
              "sendingSaleInfo": "מעדכן מלאי ספרים, אנא המתן..",
            },
            scanBarcodeDialog: {
              barcode: "ברקוד",
              barcodeIsRequired: "יש להכניס ברקוד",
              barcodeTypeOrScan: "הקלד או סרוק ברקוד",
              qrcode: "קוד QR",
              braceletId: 'מזהה צמיד',
              qrcodeIsRequired: "יש להכניס קוד QR",
              qrcodeTypeOrScan: "הקלד או סרוק קוד QR",
              qrcodeScan: "סרוק קוד QR",
              cardNumber: "מספר כרטיס",
              cardIsRequired: "יש להכניס מספר כרטיס",
              cardTypeOrScan: "הקלד או העבר כרטיס",
      barcodeNumberOutOf: "ברקוד מספר {indexNumber} מתוך {totalBarcodes}",
            },
            sms: {
              requestServerMessage: 'שולח בקשה לשרת, אנא המתן...',
              mustBeOnline: "לא ניתן לשלוח SMS כשהקופה בנתק"
            },
            paymentTypes: {
              '0': "עודף",
              '1': "כרטיס אשראי",
              '2': "מזומן",
              '3': "שובר קניה",
              '4': "שובר זיכוי",
              '5': "צ'ק",
              '6': "שובר הנחה",
              '7': "הנפקת שובר זיכוי",
              '9': "טעינת כרטיס POSITIVE",
              '12': 'טעינת כרטיס Value Card',
              '30': "משיכת מזומן",
              '31': "החזר משיכת מזומן"
            },
            invoiceTypes: {
              '1': 'תקבול',
              '2': 'זיכוי',
              '3': 'שובר זיכוי',
              '4': 'דוח Z',
              '5': 'קבלה',
              '6': 'דוח Z ראשי',
              '7': 'חשבונית מס',
              '8': 'חשבונית מס זיכוי',
              '9': 'תעודת משלוח',
              '10': 'זיכוי תעודת משלוח',
              '20': 'פרפורמה',
              '21': 'רכישת כרטיסייה',
              '30': 'משיכת מזומנים',
              '31': 'זיכוי משיכת מזומנים'
            },
            creditCardPaymentTypes: {
              '1': "רגיל",
              '2': "תשלומים",
              '3': "קרדיט בתשלומים",
              '4': "ישראכרט גמיש",
              '7': "ויזה + 30",
              '8': "חיוב מיידי"
            },
            accountActions: {
              title: "פעולות חשבון נוספות",
              itemQuery: "שאילתא לפריט",
              prepInst: "הוראות הכנה",
              holdSale: "השהיית חשבון",
              restartSale: "מחק חשבון",
              releaseSale: "שחרור חשבון",
              printInvoice: "הדפסת חשבון",
              cannotHoldSaleWithoutItems: "שים לב כי לא ניתן להשהות מכירה ללא פריטים",
              cannotHoldReleaseSaleWhenPaymentsExist: "לא ניתן להשהות/לשחרר חשבון כאשר יש אמצעי תשלום בחשבון הקיים",
              calculator: "מחשבון",
              feedPaper: "קדם נייר",
              currencyConversionCalculator: 'מחשבון המרה',
              createItem: 'יצירת פריט מהיר',
              importOrderFromPriority: 'משיכת הזמנה מפריוריטי',
              ExitDalpakWithoutPrintingBons: 'יציאה ללא הדפסת בונים',
              customers: "לקוחות",
              hakafaSale: "מכירה בהקפה",
              openNewRepair: 'פתח תיקון',
              repairsReport: 'דוח תיקונים',
              mobilePhoneRepairsSettings: 'הגדרות מודול סלולר',
              completeOrderViaPayment: 'השלם תשלום להזמנה',
              saleSittingOrTA: 'סוג הזמנה'
            },
            releaseSale: {
              title: "שחרור חשבון",
              filterLabel: "סינון לפי מספר חשבון (הזמנה)/שם מוכר/שם לקוח",
              noSalesInHold: "לא קיימים חשבונות בהשהייה",
              holdTime: "זמן השהייה",
              orderNum: "מספר הזמנה",
              saleAmount: "סכום חשבון",
              saleQty: "כמות פריטים",
              cashier: "קופאי/מוכר",
              customer: "לקוח",
              actions: "פעולות",
              release: "שחרר",
              delete: "מחק",
              noCustomer: "אין",
              loading: "טוען חשבוניות",
            },
            genericItemDescription: {
              name: "תאור",
              price: "מחיר",
            },
            itemQuery: {
              title: "שאילתה לפריט",
              searchLabel: "חפש לפי קוד פריט/ברקוד/תיאור פריט",
              noItemsFound: "לא נמצאו פריטים",
              code: "קוד",
              description: "תאור",
              price: "מחיר",
              color: "צבע",
              size: "מידה",
              supplier: "ספק",
              barcode: "ברקוד",
              moreInfo: "מידע נוסף לפריט",
              search: "חיפוש",
              promotions: "מבצעים",
              inventory: "מלאי",
              showPromotions: 'הצג מבצעים',
              none: 'אין',
              promotion: 'מבצע',
              validUntil: 'בתוקף עד תאריך',
              validUntilShort: 'בתוקף עד',
            },
            loginDialog: {
              changePasswordTitle: "החלפת סיסמה",
              pressToPassCard: "לחץ להעברת כרטיס חוגר",
              adminPassword: "סיסמת מנהל",
              manualPassword: "סיסמה להקלדה ידנית:",
              personalId: "מספר אישי:",
              password: "סיסמה:",
              back: "חזרה",
              lockPos: "נעילת קופה",
              login: "כניסה למערכת",
              userOrPassNotFoud: "משתמש או סיסמה לא קיימים",
              blocked: "נחסמת ל-30 שניות",
              waitingPinPad: "ממתין להעברת כרטיס העובד במסוף",
              noPermission: "אין לך הרשאה לעבוד בחנות זו",
              loggingIn: "מתחבר",
              logout: "יציאה מהקופה",
              notManager: "העובד לא מוגדר כמנהל",
              pinpadError: "בעיה בקריאת הכרטיס",
            },
            caveretPasswordDialog: {
              typePaswordTitle: 'הקלד סיסמה',
              password: "סיסמה:",
              userOrPassNotFoud: "משתמש או סיסמה לא קיימים",
              noPermission: "אין לך הרשאה לעבוד בחנות זו",
              loggingIn: "מתחבר",
              pleaseSwipeCard: 'אנא העבר כרטיס'
            },
            withdrawal: {
              gettingReport: "אנא המתן",
              reportError: "הייתה שגיאה בתקשורת מול השרת בזמן הפקת הדו\"ח. אם הבעיה חוזרת נא ליצור קשר עם התמיכה",
              validationCannotAdd: "לא ניתן להוסיף פריטים למשיכת מזומן",
              validationCannotBeAdded: "לא ניתן לבצע משיכת מזומנים אם יש עוד פריטים בחשבון",
              validationCannotChangeAmount: "לא ניתן לשנות כמות של משיכת מזומנים",
              validationSaleIsStuckWithdrawal: "בעקבות שגיאה במשיכת המזומן הקודמת, לא ניתן להמשיך ולמכור בקופה. נא ליצור קשר עם התמיכה",
              gettingParams: "מקבל נתונים מקורא כרטיסים",
              pickAmount: "אנא הזן סכום למשיכה",
      amountTooSmall: "סכום המשיכה חייב להיות גדול מ {amount} ש\"ח",
      amountTooBig: "סכום המשיכה המירבי יכול להיות עד {amount} ש\"ח",
      amountIncrementProblem: "הסכום חייב להיות בכפולות של {amount} ש\"ח",
              surchargeConfirmation: "הפעולה הבאה תחוייב בדמי שרות כמפורט להלן:",
              waitingForEmv: "ממתין להעברת הכרטיס וקבלת אישור",
              withdrawalSuccess: "המשיכה הצליחה",
              withdrawalRefundSuccess:"פעולת ביטול משיכת מזומנים הושלמה בהצלחה",
              withdrawalTitle: "משיכת מזומנים",
              refundTitle: "ביטול משיכת מזומנים",
              refundWithdrawal: "החזר מזומן",
              refundSurcharge: "החזר דמי שירות",
              refundPastAllowedDays:"לא ניתן לבצע זיכוי עבור משיכת מזומנים אחרי {days} ימים מעסקה מקורית",
              saleIsNotWithdrawal:"העסקה היא לא מסוג משיכת מזומנים",
              saleIsNotRefund:"העסקה היא לא מסוג ביטול משיכת מזומנים",
              attention: "שים לב!",
              charge: "חיוב",
              payAttention: "האם לאשר את הפעולה?",
              surchargeTitle: "דמי שירות",
              notEnoughCashInPos: "אין מספיק מזומן בקופה",
              total: "סה\"כ",
              emvError: "שגיאה בקורא הכרטיסים, האם הוא מחובר ומתאים למשיכת מזומנים?",
              noWithdrawalParams: "לא קיימים פרמטרים למשיכת מזומנים. נא ליצור קשר עם התמיכה",
              debitInv: "חשבונית מס קבלה: ",
              creditInv: "חשבונית מס קבלה זכות: ",
              confirmError: "חלה שגיאה באישרור העיסקה, אם הבעיה חוזרת נא ליצור קשר עם התמיכה",
              noCashWithdrawal: "לקופה לא מוגדר משיכת מזומן",
              withdrawnCount: "מספר משיכות מזומן",
              terminalNo: "מס. מסוף כספומיני:",
              tranNo: "מס. עסקה:",
              idNo: "מס. משיכה:",
              withdrawalError: "פעולת המשיכה נכשלה / סורבה",
              paytechTitle: 'השירות ניתן באמצעות חברת פייטק - כספומיני',
              paytechPhone: 'לברורים ~ושירות: 08-9292182',
              atmNo: 'מס. :ATM',
              chargeTotal: 'סה"כ לחיוב:',
              cash: 'מזומן',
              returnCash: "החזר מזומן ללקוח",
              customerCopy: 'עותק ללקוח',
              cashierCopy: 'עותק לקופה',
              signature: 'חתימה:..................',
              loadingWithdrawalParams: "טוען פרמטרים של משיכת מזומן",
              checkingCashWithdrawalLastSale: "בודק תקינות של משיכת מזומן אחרונה",
              cashWithdrawalLastSaleInvalid: "לא ניתן לבצע משיכות מזומן - קיים חוסר תאימות בין המידע של המשיכות מזומן בקופה לבין המידע במסופון, אנא פנה לתמיכה",
              roshemetFastBtnsError: "לא ניתן להזין מחיר לפריט זה",
              confirmAndSwipeCard: "יש ללחוץ על אישור ולאחר מכן להעביר כרטיס במסופון",
              refundSuccess: "זיכוי משיכת מזומן עבר בהצלחה",
              cardError: {
                "93": "פנה לסניפך",
                "0": "שינוי נוסח",
                "1": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "4": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "5": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "6": "תקלה זמנית בשירות.",
                "7": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "12": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "13": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "14": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "15": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "19": "סכום משיכה גבוה מהמותר",
                "25": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "30": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "38": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "40": "תקלה זמנית בשירות.",
                "41": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "43": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "51": "סכום משיכה גבוה מהיתרה",
                "52": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "53": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "54": "כרטיס פג תוקף",
                "55": "קוד סודי שגוי",
                "56": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "57": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "58": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "61": "חריגה מסכום משיכה יומי",
                "62": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "63": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "65": "חריגה מכמות משיכות יומית",
                "75": "קוד סודי שגוי",
                "78": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "79": "תקלה זמנית בשירות.",
                "80": "תקלה זמנית בשירות.",
                "81": "תקלה זמנית בשירות.",
                "82": "תקלה זמנית בשירות.",
                "83": "תקלה זמנית בשירות.",
                "84": "תקלה זמנית בשירות.",
                "85": "תקלה זמנית בשירות.",
                "86": "תקלה זמנית בשירות.",
                "87": "תקלה זמנית בשירות.",
                "88": "תקלה זמנית בשירות.",
                Z3: "נדחה",
        else: "\nקוד שגיאה ({code})",
                title: "הפעולה לא הצליחה.",
                timeout: "הזמן המוקצב לתקשורת עם המוסופון נגמר. נא לבדוק את המסופון"
              },
              atmError: {
                10044: "הפעולה בוטלה מהמסופון",
                10099: "נא לנסות שוב",
                10041: "לא מצליח להתחבר למסופון",
                10049: "לא נמצאה עסקה",
                11001: "שגיאה בקריאת הכרטיס",
                10029: "פעולת המשיכה לא נמצאה בקורא הכרטיסים",
                "5037": "הכרטיס שהועבר הוא לא כרטיס בנקאי",

              },
              waitintForPinPadToRecover: "ממתין לרענון המסופון",
              waitingForWithdrawalConfirmation: "ממתין לאישור משיכת המזומן...",
            },
            goodi: {
              awaitingResponse: "מתחבר לגודי",
              noCard: "לא הוזן כרטיס",
              error: {
                "1024": "הכרטיס לא קיים במערכת",
                "1000": "הכרטיס לא קיים במערכת",
                "1001": "כרטיס חסום לשימוש",
                "1002": "הכרטיס לא מורשה לעבור בטווח השעות הנוכחי",
                "1003": "כרטיס זה אינו מתאים - אנא פנה לגודי",
                "1004": "לא נמצאה העיסקית המבוקשת",
                "1005": "לא נמצא מחיר קבוע לכרטיס זה",
                "1006": "מספר הארוחות חורג מהמספר המותר",
                "1007": "בקשתך חורגת מהתקציב היומי המותר",
                "1008": "בקשתך חורגת מהתקציב החודשי המותר",
                "1010": "שגיאה בהגדרות הכרטיס",
                "1012": "כרטיס זה אינו פעיל בחנות זו",
                "1013": "שגיאה בהגדרות הכרטיס",
                "1016": "בקשתך חורגת מהתקציב שהוגדר לכרטיס זה",
                "1020": "מס' הזמנה זה לא קיים במערכת",
                "1023": "אין מספיק יתרה לשימוש במספר הזמנה זה",
                default: "שגיאה בתקשורת מול גודי, נא לפנות לתמיכה",
                responseError: "יש בעיה בתשובה של גודי. נא ליצור קשר עם התמיכה"
              }
            },
            interService: {
              awaitingResponse: "מתחבר לאינטר סרוויס",
              error: {
                error: 'שגיאה',
                badVoucherNumber: "מספר שובר לא תקין - אנא נסה אחר",
                voucherIsNotValid: "השובר לא בתוקף"
              }
            },
            multipassPolice: {
              canPayedOnlyCashCreditMultipass:"לקוח יקר, בעסקה זו ניתן לשלם את היתרה באמצעות מזומן, אשראי או מולטיפס נקודות",
              canotUseCasparPoints:"לא ניתן לממש נקודות כספר כאשר קיימים אמצעי תשלום אחרים בחשבון",
              balance: "נקודות",
              balanceText: "להלן מצב הנקודות המעודכנות בכרטיס",
              noticePromosAreDisabled: "שים לב! המבצעים הפעילים בעסקה יבוטלו בעת מימוש נקודות מולטיפס",
              noItemsForWalletToPay: 'אין פריטים שאפשר לשלם עם ארנק',
              type: "סוג",
              totalPoints: 'סה"כ',
              pointsType: "סוג נקודה",
              amountUsedInSale: "נוצל",
              startSalePointsAmount: "יתרת פתיחה",
              pointsDetails: "פירוט נקודות",
              endSalePointsAmount: "יתרת סגירה",
              amount: "נקודות צבורות",
              error: "שגיאה במולטיפאס: ",
              name: "מולטיפאס נקודות",
              cardNo: "מספר כרטיס:",
              approvmentNumber: "מספר אישור מולטיפאס:",
              cardPass: "קוד סודי:",
              swipe: "העבר כרטיס",
              waitingForSwipe: "העבר כרטיס במסופון",
              insertCardNo: "נא להזין מספר כרטיס או להעביר",
              insertPinCode: "נא להזין קוד סודי",
              search: "חפש",
              cancel: "ביטול",
              searching: "מקבל נתונים",
              pointsToUse: "נקודות למימוש",
              pleaseChoose: "נא לבחור את התפלגות הנקודות למימוש בכרטיס.",
              rest: "יתרה לתשלום לאחר שימוש בנקודות",
              tooManyPointsError: "בחרת ביותר מידי נקודות",
              tooManyPointsInWallet: "בארנק \"{wallet}\" השתמשו ביותר נקודות מהמותר. בקנייה זו מותר לנצל עד {max} נקודות בארנק.",
              pointTypes: {
                "1": "מדים",
                "2": "מדים משולבות - לא פעיל",
                "3": "אישיות",
                "4": "וירטואליות - לא פעיל",
                "10": "ספורט",
                "11": "אישיות",
                "12": "ספורשיות",
                "13": "מדים",
                "14": "כספר",
                stars: "כוכבים"
              },
              item: {
                code: 'קוד פריט',
                desc: 'שם פריט',
                price: 'מחיר לצרכן',
              },
              discountName: "הנחת נקודות",
              maxPointsUsage: "ניצול נקודות מקסימלי",
              maxPoints: "מקסימום",
              showItems: 'הצג פריטים',
              paymentBackWarning: "פעולה זו תבטל את חיוב הנקודות, האם להמשיך?",
              alreadyHasPointsWarning: "לא ניתן לבצע שני תשלומים שונים באמצעות מולטיפס נקודות </br> לחיצה על אישור תבטל את חיוב המולטיפס נקודות הקודם ותאפשר לך לבחור נקודות מחדש",
              maxDiscount: "הנחה אפשרית מקסימאלית",
              currentDiscount: "הנחה נוכחית",
              customerPickerTitle: "העבר כרטיס או הקש פרטים",
              lookingForCustomer: "מאתר לקוח...",
              commitingTransaction: "מבצע חיוב",
              cancellingPayment: "מבטל חיוב..."
            },
            nirDavidService: {
              barcodeIsValid: "ברקוד תקין!",
              barcodeIsInvalid: "ברקוד לא תקין! אנא נסה אחר",
              checkingBarcode: "בודק ברקוד",
              cancellingBarcodes: "מבטל ברקודים",
              customerHasSignedInWebsite: "האם הלקוח נרשם באתר?",
              cancelBarcodesValidationMessage: "האם למחוק את כל החשבון ולהתחיל מחדש?",
              yes: "כן",
              no: "לא",
              cleanLeftBarcodesMessage: "שים לב! הברקודים של הכניסה לאתר שנסרקו לפני סגירת החשבון יבוטלו ויפתחו לשימוש חוזר.\n יש לסרוק אותם מחדש.",
              barcodesHasBeenCancelled: "ברקודים בוטלו בהצלחה!",
              barcodeCancelErrorMessgae: 'שגיאה בביטול הברקודים, אנא צור קשר עם התמיכה אם הבעיה חוזרת על עצמה.',
              restartSale: 'התחל חשבון נוכחי מחדש',
              posTitle: "לקוח הגיע דרך האתר",
              pleaseInsertValidBarocde: 'אנא הכנס ברקוד תקין'
            },
            textKeyboardLayout: "hebrew-qwerty-custom",
            cashManagement: {
              name: "ניהול מזומן",
              instructions: "נא לבחור את הפעולה שברצונך לבצע",
              addButton: "הוספת מזומן לקופה",
              removeButton: "הוצאת מזומן מהקופה",
              addedCash: "הוספת מזומן",
              removedCash: "הוצאת מזומן",
              cashToSafe: "מזומן שהוצא מהקופה",
              areYouSure: "האם את/ה בטוח/ה?",
              addedCashApprove: "הוספת קרן פתיחה לקופה: {sum} ש\"ח </br>תאריך: {date} שעה: {time}. </br> האם לאשר?",
              removeCashApprove: "הוצאת מזומן מהקופה: {sum} ש\"ח </br>תאריך: {date} שעה: {time}. </br> האם לאשר?",
              addCashWarning: "שים לב. הפעולה הבאה תוסיף את הסכום שנבחר לקרן הפתיחה",
              removeCashWarning: "שים לב. הפעולה הבאה תחסר את הסכום שנבחר מהמזומן בקופה",
              warning: "שים לב, פירוט המזומן בקופה הוא נכון לקופה זו בלבד ולא מאוחד לחנות.",
              enterStatement: "קרן פתיחה (לקופה זו בלבד)",
              checkCashInPos: "בדיקת מזומן בקופה",
              midOrEndOfDay: "אמצע או סוף יום",
              depositReport: "דיווח על הפקדות",
              printer: {
                title: "ניהול מזומן",
                description: "פירוט מצב המזומן בקופה",
                action: "פעולה",
                sum: "סכום",
                total: "סך כל המזומן בקופה",
                enterStatement: "קרן פתיחה",
                movedToSafe: "הוצאת מזומן מהקופה",
                cashWithdrawal: "משיכת מזומן",
                cashInvoices: "עסקאות מזומן",
                coin: "ש\"ח"
              }
            },
            addOrShowBankDeposit: {
              title: "הפקדה חדשה",
              select: "בחירה",
              bank: "בנק",
              branchNum: "מספר סניף",
              selectedZReports:"דוחות זד בהפקדה זו",
              savingDeposit: "שומר הפקדה",
              noZReportsSelected: "לא נבחרו דוחות זד",
              cashMismatchMessage: "סכום המזומן שהוצהר שונה מסכום המזומן בדוחות הזד שנבחרו - האם להמשיך?",
              zNumber: "מספר זד",
              createdAt: "תאריך זד",
              totalAmount: `סה"כ סכום זד`,
              totalZCashAmount:`סה"כ מזומן ב-זד`,
              step1Title: "בחירת דוחות זד להפקדה:",
              continue: "המשך",
              finish: "סיום",
              depositEmployee: "מבצע ההפקדה",
              closeDepositConfirmation: "האם לסגור את ההצהרה? לא ניתן לבטל פעולה זו!",
              closeDepositConfirmationTitle: "סגירת הצהרה",
              totalCashZdsSelected: `סה"כ מזומן בדוחות זד בהצהרה`,
              totalCashDeclared: `סה"כ מזומן מוצהר`,
              depositCreatedSuccessfully: "ההפקדה נשמרה בהצלחה",
              failedToSaveDeposit: "שגיאה בעת שמירת ההפקדה, יש לנסות שוב.\nבמידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              comments: "הערות להפקדה",
              print: "הדפסה",
              actions:"פעולות",
              totalCashDeclaredIsRequired: 'יש להזין סכום סה"כ מזומן מוצהר',
            },
            depositReport: {
              title: "ניהול הפקדות",
              newDeposit: "הפקדה חדשה",
              depositTime: "זמן הפקדה",
              employee: "מפקיד",
              showDeposit: "פירוט הפקדה",
              selectedZReports: "דוחות Z שדווחו",
              totalZCashAmount: "סכום מזומן דוחות Z",
              depositAmount: "סכום הפקדה",
              actions: "פעולות",
            },
            sort: 'מיון',
            or: 'או',
            android: 'אנדרואיד',
            primaryPosPrinter: 'הדפסה מקופה ראשית',
            actions: 'פעולות',
            save: "שמור",
            saveAction: "שמירה",
            addAction: "הוספה",
            noOptions: "אין אפשרויות לבחירה",
            checkingBalance: 'מברר יתרה..',
            mustInsertCardNumber: 'חובה להזין מספר כרטיס',
            pleaseScanQrCode: 'אנא סרוק קוד QR',
            payAttention: 'שים לב',
            tryAgain: 'נסה שנית',
            paymentIsCancelled: 'העסקה בוטלה',
            requestFailed: 'שליחת הבקשה נכשלה. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            newVersionPleaseUpdateCatalog: "זוהתה גרסא חדשה - חובה לבצע עדכון מידע בכדי לסיים את העדכון לפני שניתן יהיה להשתמש בקופה. נא לחץ אישור בכדי לבצע עדכון מידע",
            amountPercisionTooBig: "הסכום לתשלום יכול להכיל רק 2 ספרות אחרי הנקודה",
            actionWillDeletePayments: "הפעולה הבאה תמחק את התשלומים הבאים",
            actionWillAddPayment: "הפעולה הבאה תסגור את המכירה לאחר הוספת התשלום הבא",
            areUSure: "האם אתה בטוח?",
            warning: "אזהרה",
            posOfflineMessage: 'התחברות לשרת נכשלה - קופה במצב נתק - הקופה תחזור למצב אונליין כשיחזור החיבור',
            cancelChargePleaseWait: 'מבטל חיוב. אנא המתן...',
            paymentsShort: 'תש',
            flight: 'טיסה',
            phone: 'טלפון',
            cellPhone: 'נייד',
            name: 'שם',
            firstName: 'שם פרטי',
            lastName: 'שם משפחה',
            gender: 'מין',
            sum: 'סכום',
            govId: 'ת.ז',
            prev: "קודם",
            next: "הבא",
            clubMembershipCard: 'כרטיס חבר מועדון',
            cashRefund: 'החזר מזומן',
            refundCustomer: 'האם לזכות את יתרת הלקוח?',
            noSaleItems: "לא קיימים פריטים/תשלומים למכירה",
            creditCard: 'אשראי',
            generateReport: 'הפק דו"ח',
            generatingReportWait: 'הדו"ח בהפקה, אנא המתן...',
            errorTechnicalCard: "כרטיס טכנאי שגוי",
            message: "הודעה",
            note: "הערה",
            content: "תוכן",
            goBack: "חזור",
            print: "הדפס",
            close: "סגור",
            cancelled: 'בוטל',
            cardNumberCantBeEmpty: "חובה להזין מספר כרטיס",
            select: "בחר",
            loading: "בטעינה...",
            convertingServerInfo: "ממיר נתונים מהענן ושומר בקופה...",
            discount: "הנחה",
            promotion: "מבצע",
            deleteDiscount: "מחק הנחה",
            deletePromotions: "מחק מבצעים",
            deleteSaleDiscount: "מחק הנחת חשבון",
            saleDiscount: "הנחת חשבון",
            saleDiscountPromotion: "מבצע הנחת חשבון",
    nEntriesAdded: 'נוספו {n} כניסות',
            currentCurrency: 'ש"ח',
            amount: "סכום",
            search: "חיפוש",
            filter: "סינון",
            cash: "מזומן",
            credit: "כרטיס אשראי",
            swipeCard: "העבר כרטיס",
            all: "הכל",
            vouchers: "תווים",
            creditVoucher: "שובר זיכוי",
            check: "צ'ק",
            back: "חזרה",
            changeQuantityTitle: "שינוי כמות",
            changeQuantityMessage: "אנא הזן כמות חדשה לפריט",
            checkingForNewVersion: "בודק אם יש גרסה חדשה",
            support: "לתמיכה",
            main: "ראשי",
            mainMenu: 'תפריט ראשי',
            loadingPosParameters: "טוען פרמטרי קופה מהשרת",
            preparingLoadData: "טוען קבצי נתונים",
            offlineSales: "מכירות בנתק",
            posVersion: "גירסת קופה",
            emvPosNumber: "מספר קופת מסופון",
            noSales: "אין מכירות",
            loginTitle: "כניסה למערכת",
            username: "שם משתמש",
            password: "סיסמה",
            login: "כניסה",
            fromDate: "מתאריך",
            toDate: "עד תאריך",
            managerApprovalPlaceholder: "אנא העבר או הקלד כרטיס מנהל",
            managerApprovalBySmsPlaceholder: "אנא העבר או הקלד קוד לאישור",
            managerApprovalTitle: "אנא העבר או הקלד כרטיס מנהל",
            managerApprovalBySmsError:"שליחת הודעות SMS למנהל נכשלה. אנא נסה מאוחר יותר, או פנה לתמיכה.",
            managerApprovalBySmsLoading:"שולח הודעות SMS...",
            detailedManagerPrompt: "נדרש אישור מנהל, אנא העבר או הקלד קוד לאישור",
            detailedManagerPromptOnlyFingerPrint: "נדרש אישור טביעת אצבע של מנהל",
            emptyTableViewMessage: 'סרוק פריט או הקלד פרטי פריט לחיפוש למעלה',
            emptyTableViewMessageMobile: 'אין פריטים במכירה',
            otherTabsAlreadyOpen: 'הקופה פתוחה בחלון אחר - יש לסגור את כל החלונות שבהם הקופה פתוחה, ולהפעיל את הקופה פעם אחת בלבד',
            autoSuggestStartText: 'סרוק פריט או הקלד פרטי פריט לחיפוש',
            autoSuggestEmptyText: 'לא קיים פריט',
            error: 'שגיאה',
            none: 'אין',
            payAll: 'שלם הכל',
            exact: 'מדוייק',
            ok: 'אישור',
            actionCancelled: 'פעולה בוטלה',
            generalItem: 'פריט כללי',
            times: 'כפול',
            clear: 'נקה',
            cancel: 'ביטול',
            refresh: 'רענן',
            enterPrice: 'אנא הזן מחיר עבור הפריט',
            enterPriceTitle: 'הזן מחיר',
            remove: 'מחיקה',
            yes: 'כן',
            skip: 'דלג',
            svaErr25TranExists: " שים לב - קיימות עסקאות אשראי שלא שודרו. חובה לבצע תהליך סוף יום (דוח Z) בקופה זו לפני שניתן יהיה לבצע עסקאות אשראי נוספות.",
            svaErr25TranNotExists: "שים לב, בקופה זו לא בוצע שידור כבר מספר ימים. <br/> חובה לבצע שידור לפני שניתן יהיה לעשות עסקאות באשראי מקופה זו. <br/> לחץ אישור לביצוע שידור.",
            no: 'לא',
            areYouSure: 'האם אתה בטוח?',
            moveNext: 'המשך',
            continue: 'המשך',
            cannotMoveToMinusWhenSaleHasPayments: 'לא ניתן להפוך סכום מכירה לשלילי כאשר יש אמצעי תשלום בחשבון. אנא מחק אמצעי תשלום ונסה שנית',
            cantAddTipItem: 'לא ניתן להוסיף פריט טיפ מחוץ למסף התשלום',
            entityStatusValid: 'תקין',
            entityStatusInvalid: 'לא תקין, יש לרענן מידע',
            entityStatusPartial: 'מידע חסר',
            entityStatusEmpty: 'אין מידע',
            incompletePayment: 'יש לשלם את כל הסכום לתשלום',
            nonNumericValue: 'יש להקיש מספרים בלבד',
            loggingIn: 'מתחבר',
            loggingOut: 'מתנתק',
            logout: 'הת.נתקות',
            never: 'אף פעם',
            doLogout: 'האם להתנתק ממשתמש {USERNAME}?',
            loginFailed: 'התחברות נכשלה.',
            numberItem: "מספר פריט ",
            mustBeOnline: 'יש לוודא שהקופה מחוברת לאינטרנט.',
            logoutFailed: 'התנתקות נכשלה. נסה להתנתק שוב. אם הבעיה חוזרת על עצמה נסה לרענן את הדף ולפנות לתמיכה טכנית.',
            logoutOfflineSale: 'לא ניתן להתנתק מהקופה כאשר יש מכירות בנתק או מכירות בהשהייה. יש למחוק את המכירות שבהשהייה במידה ויש כאלו, או לסגור אותן.',
            logoutActiveDalpak: 'לא ניתן להתנתק כאשר יש מכירה פעילה בדלפק הנוכחי שנבחר, יש לצאת מהדלפק הנוכחי',
            logoutItemsNotSync: 'לא ניתן להתנתק כאשר יש {failedItemsLength} פריטים שלא הסתנכרנו לשרת, \n {failedItems}. \n יש לוודא את תקינות הפריטים. אם הבעיה חוזרת על עצמה, יש לפנות לתמיכה.',
            logoutEmployeesNotSync: 'לא ניתן להתנתק כאשר יש {failedEmployeesLength} עובדים שלא הסתנכרנו לשרת,\n {failedEmployees} \n יש לוודא רת תקינות העובדים. אם הביה חוזרת על עצמה יש לפנות לתמיכה.',
            usernamePasswordDoesntExists: 'שם משתמש/סיסמה לא קיימים',
            wrongPassword: 'הסיסמה שהזנת שגויה.',
            noAvailablePOS: 'לא ניתן לפתוח תחנות קופה נוספות לחנות {STORE_NAME}. נא לפנות למנהל מערכת לצורך הגדרת קופה חדשה',
            noAvailablePOSforUser: 'לא קיימת קופה פנויה למשתמש זה',
            unknownErrorHasOccured: 'ארעתה שגיאה לא ידועה',
            missingDataFromRemoteServer: 'התקבל מידע חלקי מהשרת. אנא נסה להתחבר שוב. אם הבעיה חוזרת על עצמה, אנא פנה לתמיכה טכנית.',
            mandatory: 'חובה',
            optional: 'אופציונאלי',
            disabled: 'כבוי',
            automatic: 'אוטומטי',
            manual: 'ידני',
            unknown: 'לא ידוע',
            prevent: 'מניעה',
            warn: 'אזהרה',
            item: 'פריט',
            size: 'מידה',
            userCanceled: 'ביטול משתמש',
            paramsLoadFaild: 'טעינת פרמטרים נכשלה',
            downloadingDataFor: 'מוריד נתונים עבור',
            updatingMigvanPrices: 'מעדכן מחירי מגוון',
            updatingMigvanItems: 'מעדכן פריטי מגוון',
            loadingXFailed: 'טעינת {0} נכשלה',
            relogin: 'התחברות מחדש',
            restartingPinpadConnection: "מאתחל תקשורת למסופון",
            posDisabledPleaseLogin: 'התחנה לא פעילה יותר. יש לבצע כניסה מחדש.',
            softwareUpdateFailed: 'עדכון גרסה נכשל',
            softwareUpdateFailedMessage: 'הקופה במצב נתק או אין גישה לשרת. אם הבעיה חוזרת על עצמה אנא פנה לתמיכה טכנית.',
            managerCardMissing: 'ההנחה שנבחרה דורשת להעביר כרטיס מנהל. אנא העבר כרטיס מנהל למעלה.',
            givenCardIsNotmanagerCard: 'הכרטיס שהועבר אינו כרטיס עובד. אנא העבר כרטיס עובד.',
            managerApprovalGenericError:'הקוד שהוזן אינו תקין',
            removeSaleDiscountTitle: 'הסרת הנחת חשבון',
            removeSaleDiscountMessage: 'האם להסיר את הנחת החשבון מהמכירה?',
            discountPrecentHigherThenAllowed: 'לא ניתן לתת הנחה מעל הנחת מנהל',
            posRemoveDiscountFromItemTitle: 'הסרת הנחה מפריט',
            posRemoveDiscountFromItemBody: 'האם להסיר את ההנחה מהפריט?',
            posRemovePromotionsFromItem: 'האם להסיר את המבצעים מהפריט?',
            posDiscountTypeSale: 'הנחת חשבון',
            cashOutside: "מזומן בחוץ",
            cashOutsideDalpakim: "מזומן בחוץ (לכל החנות)",
            totalDeliveriesCash: "מזומן משלוחים (לכל החנות)",
            cashPlusCashOb: "סך מזומן בקופה",
            posDiscountTypeItem: 'הנחת פריט',
            posDiscount: 'הנחה',
            salesPersonEmployee: 'מוכר',
            cannotFetchPosFromDBReload: 'לא ניתן לטעון מידע על התחנה. נסה לרענן את הדף. אם הבעיה חוזרת על עצמה, אנא פנה לתמיכה טכנית.',
            storingDataLocally: 'מאחסן מידע על התחנה. תהליך זה יכול לקחת כמה דקות, אנא המתן...',
            itemStockInfo: 'מלאי בחנויות לפריט: {ITEM_CODE} צבע: {COLOR} מידה: {SIZE}',
            remoteDataLoadFailed: 'טעינת מידע נכשלה. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            loadingItemFromStorageFailed: 'לא ניתן לטעון פריט. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            loadingItemSizesColorsFailed: 'לא ניתן לטעון מידות וגדלים לפריט. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            loadingData: 'טוען מידע, אנא המתן...',
            loadingZreportdataError: 'לא ניתן לטעון נתוני דוח Z. אנא נסה שוב. אם ההודעה חוזרת על עצמה, פנה לתמיכה טכנית',
            offlineZreportMessage: 'לא ניתן לשלוף נתוני דוח Z כשהקופה במצב נתק',
            localBackupOfSales: "מבצע גיבוי מקומי של נתוני מכירות",
            creatingZReport: "מייצר דוח זד",
            sendZReportToServer: "מסנכרן דוח זד עם שרת",
            compareLocalSalesWithServer: 'מוודא תקינות נתוני מכירות',
            checkingFlightSales: "בודק את מכירות הטיסה",
            transmittingFlight: "משדר את נתוני הטיסה",
            flightUploadedNoSales: "נתוני הטיסה שודרו בהצלחה",
            loadingStatistics: 'מעדכן סטטיסטיקות, אנא המתן...',
            waitingToBeSent: 'ממתין לשליחה',
            successfullySent: 'נשלח בהצלחה',
            selectOption: 'בחר אפשרות',
            successfullyCompleted: 'בוצע בהצלחה',
            syncFailed: 'השליחה נכשלה',
            failedToFetchSale: 'לא ניתן לטעון מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToFetchPastSales: 'לא ניתן לטעון מכירות. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToFetchSaleTotals: 'לא ניתן לטעון סכום למכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            chooseColor: 'יש לבחור צבע',
            chooseSize: 'יש לבחור מידה',
            missingDataFromItem: 'חסר מידע לפריט. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToFetchDiscounts: 'לא ניתן לטעון הנחות. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            noDiscounts: 'אין הנחות זמינות',
            managerNotFound: 'הקוד מנהל שהוקש הוא של עובד שאינו מנהל, או של עובד שאינו משוייך לחנות זו. יש לוודא שההגדרות נכונות במשרד האחורי.',
            failedToFetchManagers: 'לא ניתן לטעון רשימת מנהלים. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            mustSelectCashier: 'יש לבחור קופאי במסך הראשי כדי להשתמש בקופה',
            specialitemAndVoucherNotAllowed: 'לא ניתן לשלם עם שובר עבור פריט מיוחד.',
            specialitemAndCreditVoucherNotAllowed: 'לא ניתן לשלם עם שובר זיכוי עבור פריט מיוחד.',
            pfcChromeBrowserMinimumRequirementFailed: 'גרסת דפדפן אינה תואמת, פנה לתמיכה טכנית',
            itemInMinusShouldBeOnlyOneSalesPerson: 'לא ניתן למכור פריט במינוס בחשבונית עם יותר ממוכר אחד',
            mustRestartAfterDataUpdate: 'חובה לפתוח את הדפדפן מחדש לאחר עדכון נתונים, על מנת להבטיח שמירת נתוני העדכון בדיסק הקשיח',
            shvaConfiguredButNotRunning: 'קופה מוגדרת לעבודה עם שבא אך שבא אינו פעיל',
            shvaPendingTranFile: 'שידור לשבא לא הסתיים תקין, מבצע שידור מחדש.' + '<br/>' +
              'לא ניתן לבצע עסקאות עד אשר שידור לשבא מסתיים.' + '<br/>' +
              'יש לוודא שמתקבל דוח שידור משבא',
            shvaInProccess: 'שידור קודם לשבא טרם הסתיים.' + '<br/>' +
              'מבצע התאוששות, יש לוודא שלא סוגרים קופה ולא מתחילים מכירה חדשה' + '<br/>' +
              'לפני שמתקבל דוח שידור משבא.',
            noneTransmitedSecondaryPoss: "הקופות המשניות הבאות לא סגרו זד: ",
            needToDoZreportInThosePoss: ' - יש לבצע דוח Z בקופות הללו ולאחר מכן ניתן להמשיך ולסגור בקופה זו דוח Z מאוחד.',
            zReportFailedError: 'שגיאה בעת יצירת דוח זד, אנא פנה לתמיכה.',
            kupaNotCompletellyLoaded: 'טרם הסתיימה טעינת הקופה - נסה שנית',
            printingAddonNotInstalled: 'תוסף הדפסה אינו מותקן',
            addonNotInstalledCorrectly: 'תוסף לא הותקן או מכובה או לא הותקן נכון',
            installAddon: 'התקן תוסף',
            NoCurrencyRatesFromBankOfIsrael: 'חסרים שערי מטבעות מבנק ישראל, פנה לתמיכה טכנית',
            MissinCurrencyRatesFromBankOfIsrael: 'חסרים שערי מטבעות מעודכנים מבנק ישראל, פנה לתמיכה טכנית',
            CurrencyRatesFromBankOfIsraelOk: 'שערי מטבעות עודכנו בהצלחה',
            AutoEmpStoreUpdateIsNowAuto: 'עדכון עובדים עבר למצב אוטומטי ',
            posClockTimeIsIncorrect: 'בעיה בשעת המכירה, חובה ליפנות לתמיכה.',
            itIsNotPossibleToPayHakafaWithMizdamen: "לא ניתן לשלם בהקפה עבור לקוח מזדמן",
            AutoEmpStoreUpdateIsNowManual: 'עדכון עובדים עבר למצב ידני ',
            cannotCreateNewSale: 'לא ניתן ליצור מכירה חדשה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToPersistSale: 'לא ניתן לשמור מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToPersistSaleItem: 'לא ניתן לשמור פריט מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToPersistSalePayment: 'לא ניתן לשמור תשלום מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToDeleteSaleItem: 'לא ניתן למחוק פריט מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToDeleteSalePayment: 'לא ניתן למחוק תשלום מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToDeleteSalePaymentPending: 'לא ניתן למחוק מכירה מכיוון שיש אמצעי תשלום פעילים',
            failedToDeleteSaleBeacuseActivePayments:" לא ניתן למחוק פריט ממכירה שהתקבלו עבורה תשלומים.\n אנא מחק אמצעי תשלום טרם מחיקת פריטים.",
            failedToDeletePickupSale: 'לא ניתן למחוק מכירת פיקאפ',
            failedSpecialitemExists: 'על פריט ייחודי להופיע לבדו בחשבונית',
            failedDeliveryItemOnNotEmptySale: 'לא ניתן לייצר הזמנה על מכירה שקיימים בה פריטים, אנא התחל מכירה חדשה על מנת לבצע הזמנה',
            failedOpenCustomerClubSpecialItemGiftCardExists: 'על פריט ייחודי GIFT CARD לא ניתן להשתמש בחבר מועדון',
            failedAddSpecialItemGiftCardCustomerClubExistsOnSale: 'לא ניתן להוסיף פריט ייחודי GIFT CARD כשקיים לקוח מועדון במכירה',
            cannotAddDiscountOnSpecialItemGiftCard: 'לא ניתן לבצע הנחה על פריט ייחודי GIFT CARD',
            failedSalePercentAdditionExists: 'לא ניתן להוסיף יותר מפריט אחוז אחד בחשבונית',
            confirmErnErrorHeader: 'שגיאת ERN האם להמשיך בכל זאת?',
            confirmErnErrorForceContinue: 'שגיאה {ERROR} האם להמשיך בכל זאת?',
            confirmSaleItemDeleteHeader: 'מחיקת פריט',
            noItemsSelectedToDelete: 'לא נבחרו פריטים למחיקה',
            confirmMultipleSaleItemsDelete: 'שים לב, פעולה זו תמחוק {numberOfSaleItems} שורות. האם להמשיך?',
            fingerprintEnrollNotification: "העבר את האצבע שלוש פעמים על הקורא.",
            scaleRequest: "מקבל משקל עבור פריט",
            scaleEmpty: "אנא שים פריט על המשקל",
            scaleGenericError: "בעיה בקליטת משקל - לא ניתן להוסיף פריט שקיל למכירה. אם הבעיה חוזרת על עצמה יש לפנות לתמיכה טכנית.",
            scaleAndroidError: "בעיה בקליטת משקל - אנא וודא כי המשקל מחובר למכשיר וכי יש לו הרשאה",
            FINGERPRINT_UPDATE_DATA: "שגיאת מערכת, בצע עדכון מידע, אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.",
            FINGERPRINT_NO_READER_FOUND: "לא נמצא קורא טביעות אצבע",
            FINGERPRINT_REGISTRATION_FAILED: "הגדרת המשתמש וטביעת האצבע נכשלה.",
            FINGERPRINT_USER_NOT_FOUND: "לא נמצא משתמש",
            FINGERPRINT_ERROR_EMPLOYEE_ALREADY_ENROLLED: "משתמש הוגדר בעבר, אנא פנה לתמיכה",
            FINGERPRINT_ERR_INCORRECT_USER: "שגיאת מערכת, יש לפנות לתמיכה.",
            FINGERPRINT_ERROR_SAVE_FINGERPRINT_RECORD: "שגיאת מערכת, יש לפנות לתמיכה.",
            NO_ITEM_OR_BARCODE_FOUND: "לא נמצא פריט: ",
            TIMETRACK_USER_MUST_USE_FINGERPRINT: "עבור משתמש זה מוגדרת טביעת אצבע, לא ניתן להשתמש בכרטיס לדיווח שעות.",
            EMPLOYEE_NOT_FOUND_FOR_STORE: "עובד לא נמצא/משוייך לחנות זו",
            fingerprintDefineHeader: "הגדרת טביעת אצבע",
            fingerprintDefineQuestion: 'האם להגדיר טביעת אצבע לעובד זה?',
            TIMETRACK_EMPLOYEE_NOT_EXISTS: 'עובד לא קיים',
            TIMETRACK_EMPLOYEE_ALREADY_AS_ENTRY_TODAY: 'לא ניתן להקליד שתי כניסות/יציאות עוקבות לאותו היום.',
            TIMETRACK_CANT_EXIT_WITHOUT_ENTRY: 'לא בוצעה כניסה ב-20 שעות האחרונות לעובד זה.\nלא ניתן להקליד יציאה ללא כניסה.\nיש לפנות למנהל לצורך רישום שעות ידני.',
            TIMETRACK_COULD_NOT_PERSIST: 'לא ניתן לרשום את דיווח השעות',
            fingerprintVerifyNotification: "העבר אצבע לזיהוי על הקורא.",
            confirmSaleItemDelete: 'האם למחוק את הפריט מהמכירה?',
            confirmSalePaymentItemDeleteHeader: 'מחיקת אמצעי תשלום',
            confirmSalePaymentItemDelete: 'האם למחוק אמצעי תשלום?',
            confirmSaleRestartHeader: 'מכירה חדשה',
            confirmSaleRestart: 'האם למחוק את כל הפריטים ולהתחיל מכירה חדשה?',
            confirmSaleRestartExistingPaymenyError: 'לא ניתן למחקות את המכירה. קיימים תשלומים באשראי שתרם הוחזרו.',
            confirmReceiptCancelHeader: 'ביטול קבלה',
            errorOccurred: "אירעה שגיאה",
            shouldPrintA4Msg: 'בחר היכן להדפיס את החשבונית',
            shouldPrintInvoiceAtPrimaryPos: 'האם ברצונך להדפיס את החשבונית?\nשים לב! ההדפסה תצא בקופה הראשית',
            shouldPrintAtPrimaryPos: 'האם ברצונך להדפיס?\nשים לב! ההדפסה תצא בקופה הראשית',
            shouldPrintA4Title: 'בחירת מדפסת',
            a4Printer: 'מדפסת A4',
            posPrinter: 'מדפסת קופה',
            confirmReceiptCencel: 'האם לבטל את הקבלה?',
            confirmShipmentCancelHeader: 'ביטול תעודת משלוח',
            confirmShipmentCencel: 'האם לבטל את תעודת המשלוח?',
            confirmSaleCustomerDeleteHeader: 'הסרת לקוח',
            closingSale: 'סוגר מכירה...',
            confirmSaleCustomerDelete: 'האם אתה בטוח שברצונך להסיר את הלקוח מהמכירה?',
            voucherAutoDeleteLimitPayments: 'שורות המכילות שוברים שאינם כרטיסים נטענים ימחקו',
            failedToCloseSaleSequenceFetchFailed: 'לא ניתן לסגור את המכירה בגלל בעיה בקבלת מס. סידורי. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית',
            failedToCloseSale: 'לא ניתן לסגור את המכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית',
            creditCardOnlineMode: 'לא ניתן להתקשר לשבא, אנא פנה לתמיכה הטכנית',
            creditCardOfflineLimitExceeded: 'הקופה בנתק ולא ניתן לבצע עיסקה באשראי מעל לסכום: {AMOUNT}',
            creditCardPaymentMandatoryHeader: 'הוספת תשלום',
            maxSaleAmountIs: 'סכום החשבונית מעל המותר. לא ניתן לשלם מעל {AMOUNT} ש"ח',
            creditCardPaymentAmountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סכום',
            generalPaymentLeftAmountMandatoryError: 'לא ניתן להוסיף תשלום, סכום לתשלום לא יכול להיות גבוה מהיתרה לתשלום',
            cashPaymentReachedLimit: 'בקופה זו הוגדרה מגבלה לקבלת מזומן בגובה {cash} ש\"ח בלבד',
            creditCardPaymentCreditCardMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה מספר כרטיס',
            creditCardPaymentCreditTypeMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סוג אשראי',
            creditCardPaymentPaymentsMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה תשלומים',
            creditCardPaymentFirstPaymentIsBiggerThanAmount: "תשלום ראשון חייב להיות נמוך מהסכום לתשלום",
            creditCardPaymentPaymentsMaxMandatoryError: 'לא ניתן לשלם ביותר מ-{COUNT} תשלומים בכרטיס אשראי',
            creditCardPaymentPaymentsMaxForAmountError: 'לא ניתן לשלם ביותר מ-{COUNT} תשלומים בכרטיס אשראי לסכום זה',
            checkPaymentPaymentsMaxForAmountError:' מספר תשלומים לא תקין, יש להזין מ-1 עד {MAX_COUNT} תשלומים',
            checkPaymentsMustSelectAllPayments: 'חובה לסמן את כל התשלומים עבור הצק הנבחר',
            creditCardPaymentExpDateMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה תוקף',
            creditCardPaymentExpDateOverMandatoryError: 'לא ניתן להוסיף תשלום, תוקף הכרטיס פג',
            creditCardPaymentPhoneMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה טלפון',
            creditCardPaymentCCVMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה CVV',
            creditCardPaymentAllowTypingMandatoryError: 'לא ניתן להזין כרטיס אשראי בצורה ידנית',
            creditCardPaymentError: 'שגיאה באשראי, אנא פנה לתמיכה',
            creditCardCreditFailedTitle: 'זיכוי כרטיס האשראי נכשל',
            creditCardCreditFailedContent: 'לא ניתן לזכות כרטיס זה',
            creditCardPaymentNotAllowedCard: 'לא ניתן לחייב כרטיס זה בנתק.',
            creditCardPaymentNoPhoneNumber: 'הקופה בנתק, נא להזין מספר טלפון על מנת להשלים את החיוב.',
            checkPaymentMandatoryHeader: 'הוספת תשלום',
            checkPaymentAmountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סכום',
            checkPaymentBankMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה בנק',
            checkPaymenIdMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא ת.ז חוקית.',
            checkPaymenIdComercialChecNotValid: 'יש להקליד ת.ז. עבור ערב.',
            checkPaymentBankCodeMandatoryError: 'קוד בנק אינו חוקי',
            checkPaymentBranchMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סניף',
            checkPaymentBrancLengthError: 'לא ניתן להוסיף תשלום שדה סניף יכול להכיל עד 3 ספרות בלבד',
            checkPaymentBankLengthError: 'לא ניתן להוסיף תשלום שדה בנק יכול להכיל עד 3 ספרות בלבד',
            checkPaymentAccountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה חשבון',
            checkPaymentCheckNumMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא ערך חוקי בשדה צ\'ק',
            checkPaymentPaymentsCountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא ערך חוקי בשדה מספר תשלומים',
            checkBadErnConfirmationNumber: 'מספר אישור אי אר אן אינו תקין.',
            checkErnTooManyRejectsMustConfirmationNumber: 'נסיונות כושלים רבים מידי מול ERN חובה להקליד מיספר אישור.',
            checkPaymentDateMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה תאריך פרעון',
            checkPaymentDateNotValidMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא תאריך תקין עבור תאריך פרעון',
            checkPaymentDateMaxMandatoryError: 'לא ניתן להוסיף תשלום, תאריך פרעון מקסימלי הינו כ-{COUNT} ימים מהיום',
            checkPaymentDateMaxMandatoryTo10Error: 'עבור צק לא ניתן תאריך פרעון מאוחר מהעשירי לחודש',
            checkManyPaymentsMustBeTodayOrTommorow: 'צק ראשון, חייב להיות להיום או למחר.',
            checkDateBeforeYesturday: 'לא ניתן להזין תאריך פרעון קודם לאתמול.',
            checkDateSingleCheckError: 'תאריך פרעון לצק יחיד מאוחר מהמותר.',
            checkDateUntillThreeMonthOld: 'תאריך פרעון הצק אינו יכול להיות מוקדם משלושה חודשים מהיום',
            voucherPaymentMandatoryHeader: 'הוספת תשלום',
            voucherPaymentAmountMandatoryError: 'לא ניתן להוסיף תשלום אנא וודא ששדה סכום גדול מאפס',
            voucherPaymentVoucherMandatoryError: 'יש לבחור סוג שובר',
            cashPaymentAmountMandatoryError: 'לא ניתן להוסיף תשלום אנא וודא ששדה סכום גדול מאפס',
            voucherPaymentQuantityInInventoryMandatoryError: 'לא ניתן להשתמש בתווים מסוג \'{TYPE}\' מעל ל-{MAXIMUM_COUNT} פעמים בחשבונית',
            voucherPaymentMinimunAmountMandatoryError: 'ניתן להשתמש בשובר מסוג \'{TYPE}\' עבור כל {MINIMUM_AMOUNT} שקלים',
            voucherPaymentMaxDiscountPrecetageMandatoryError: 'לא ניתן לשלם יותר מ-{PRECETAGE} אחוז מהחשבון בתווים מסוג \'{TYPE}\'.',
            voucherPaymentBarcodeNotExistMandatoryError: 'ברקוד לא קיים עבור השובר',
            voucherPaymentBarcodeUsedMandatoryError: 'שובר בשימוש',
            voucherPaymentBarcodeUsedLocallyMandatoryError: 'השובר כבר בשימוש בחשבון',
            voucherPaymentBarcodeOfflineMandatoryError: 'לא ניתן לממש שובר שכולל ברקוד ללא חיבור לרשת',
            voucherPaymentBarcodeEmptyMandatoryError: 'חובה להזין ברקוד עבור השובר',
            voucherPaymentBarcodeOnlineValidationLoadingMsg: 'מוודא שובר מול שרת מרוחק, אנא המתן...',
            hakafaPaymentCanOnlyBeSingle: 'לא ניתן לשלם בהקפה כאשר קיימים תשלומים אחרים בחשבון',
            promotionOffLineMode: 'לא ניתן לקבל שובר זיכוי בנתק',
            promotionNonValid: 'לא ניתן להוסיף שובר זיכוי',
            promotionNotExist: 'שובר לא קיים',
            promotionUsed: 'שובר נוצל בעבר',
            promotionExpired: 'שובר לא בתוקף, נא לפנות לשירות לקוחות',
            salesPersonUnSelected: 'לא נבחר מוכר , אנא בחר מוכר',
            customerClub: 'חבר מועדון',
            customerNotFound: 'לקוח לא נמצא',
            customersNotFound: 'לא נמצאו לקוחות',
            customer: 'לקוח',
            noEmployeesInShift: "לא קיימים מוכרים במשמרת. יש לוודא שבוצעה כניסה לעובדים בשעון נוכחות.",
            specialDiscountUse: 'מכין את ההנחה המיוחדת לשימוש עבור הלקוח.',
            specialDiscountUseError: 'לא ניתן לממש את ההנחה, אם הבעיה חוזרת על עצמה פנה למנהל המערכת.',
            specialDiscountsNotFound: 'לא נמצאו הנחות מיוחדות למימוש.',
            searchInvoicesLongResults: 'מספר רב מידי של רשומות תאם את החיפוש. מוצגות 100 רשומות ראשונות',
            searchInvoicesNoResults: 'לא נמצאו רשומות התואמות את החיפוש',
            searchInvoicesStoreSelectError: 'חובה לבחור חנות על מנת לחפש חשבוניות.',
            searchInvoices4digCcError: 'ערך לא תקין עבור ארבע ספרות כרטיס אשראי',
            searchInvoicesStoreNotFoundError: 'חנות מספר {STORE_ID} לא קיימת במאגר החנויות.',
            searchInvoiceDateRangeNotValid: 'נבחר טווח תאריכים גדול מדי. יש לבחור טווח תאריכים של עד 3 חודשים מתאריך ההתחלה עד תאריך הסיום',
            zReportCreatingErrorNoSales: 'הקופה נסגרה בהצלחה. לתשומת לבכם - לא נוצר דוח Z מאחר ואין מכירות על הקופה',
            zReportCreatingErrorOnHoldSales: 'לא ניתן לייצר דוח כאשר קיימות מכירות בהשהייה',
            zReportCreatingErrorOpenedDalpak: 'לא ניתן לייצר דוח כאשר יש מכירות פעילות על הדלפקים, אנא וודא שכל הדלפקים ריקים',
            zReportCreatingErrorOfflineSales: 'לא ניתן לייצר דוח Z כאשר יש מכירות בנתק. אנא המתן שכל המכירות ישודרו. במידה והמכירות נשארות בנתק, חובה לפנות לתמיכה',
            zReportCreatingErrorPendingOfflineSales: 'לא ניתן לייצר דוח, יש מכירות ממתינות לחיוב בנתק',
            zReportTransmitFailed: 'השידור לשבא נכשל. יש לנסות להריץ דוח Z שוב. במידה והשידור נכשל שוב - חובה לפנות לתמיכה.',
            zReportFlightTransmitFailed: 'שידור הטיסה נכשל. יש לנסות להריץ דוח שוב. במידה והשידור נכשל שוב - חובה לפנות לתמיכה',
            zReportCreatingErrorOffline: 'לא ניתן לייצר דוח בנתק.',
            zReportSyncToRemoteServerError: 'אירעה שגיאה בעת סנכרון דוח Z עם השרת, יש לנסות להריץ שוב - במידה ונכשל שוב - חובה לפנות לתמיכה',
            zReportLocalAndServerSalesDoesNotMatch : 'לא ניתן לייצר דוח. המכירות בקופה אינן תואמות את המכירות בשרת. נא ליצור קשר עם התמיכה',
            cancelSaleNoDebitError: 'ניתן לבטל חשבוניות חיוב בלבד.',
            cancelSaleVoucherBarcodeOfflineError: 'לא ניתן לבטל חשבון עם שובר מסומן במצב לא מקוון. יש לנסות שנית כשהקופה במצב מקוון.',
            cancelSaleCreditCardOfflineError: 'לא ניתן לבטל חשבון עם כרטיס אשראי במצב לא מקוון. יש לנסות שנית כשהקופה במצב מקוון.',
            cancelSaleCreditCardError: 'לא ניתן לבטל חשבוניות שכוללת תשלום בכטיס אשראי כרגע.',
            cancelSaleCreditVoucherError: 'לא ניתן לבטל חשבוניות שכוללת תוי זיכוי כרגע.',
            cancelSaleAllreadyCancelError: 'החשבונית כבר בוטלה בעבר.',
            creditSaleMustSelectItemsToCreditError: 'חובה לבחור לפחות פריט אחד לזיכוי.',
            creditSaleMustbeNegative: 'לא נבחרו פריטים עם סכום לזיכוי גדול מ-0',
            creditSaleMustSelectAllItemsWhereContainAutoCreditVouchersError: 'חובה לבחור את כל הפריטים כאשר החשבונית מכילה תשלומים בשוברים שמזוכים אוטומטית',
            creditSaleNotEnoughCreditBackError: 'היתרה לזיכוי אינה מספיק גבוהה על מנת להחזיר את אמצעי התשלום.',
            creditSaleAllowCreditVoucher: 'אישור החזר של {AMOUNT} {CURRENCY} בשובר זיכוי',
            creditSaleItemCreditedAmountMsg: '{AMOUNT} פריטים מהמוצר הנ"ל כבר זוכו בעבר עבור חשבונית זו.',
            possible: 'אפשרי',
            generalError: "שגיאה כללית - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
            inPossible: 'לא אפשרי',
            closeSaleNoItems: 'חוסר בפריטים לעומת תשלומים, לחץ אישור על מנת לשחזר את החשבונית.',
            closeSaleNoTotalQuantity: 'כמות אינה תקינה, לחץ אישור לתיקון.',
            closeSaleMissingItems: 'כמות התשלומים גבוהה יותר מסך כל הפריטים בחשבון. יש להוסיף פריטים לחשבון כדי שיתאימו לכמות התשלום ששולם, או לבטל את התשלום הקיים ולהכניס תשלום חדש בסכום ששווה לחשבון',
            closeSaleMissingPayments: 'חסרים תשלומים, לחץ אישור לשיחזור החשבונית.',
            closeSaleNotValid: 'שגיאה בתקינות החשבונית, לחץ אישור על מנת לשחזר את החשבונית.',
            cashMaxChangeInCashError: 'לא ניתן לשלם במזומן כך שחוזר ללקוח יותר מ{AMOUNT} ש"ח עודף במזומן.',
            blocked: 'חסום',
            managerApproval: 'אישור מנהל',
            employeeTimeTrackAddedSuccessfully: '{STATUS} התבצעה בהצלחה עבור: {EMPLOYEE_NAME}.',
            checkBalance: 'בירור יתרה',
            voucherPaymentBarcodeCheckBalanceLoadingMsg: 'מברר יתרת כרטיס, אנא המתן...',
            voucherBalanceIs: 'יתרת הכרטיס עומדת על: {AMOUNT}',
            voucherPaymentDebitPraxellLoadingMsg: 'מחייב כרטיס, אנא המתן...',
            voucherPaymentDebitZeroBalance: 'יתרת כרטיס אפס, לא ניתן לחייב.',
            voucherPaymentCancelPraxellLoadingMsg: 'מזכה כרטיס, אנא המתן...',
            smartVoucherCancelingLoadCard: 'מבטל טעינת כרטיס, אנא המתן...',
            smartVoucherNotFound: "לא הוגדר שובר חכם {name} במערכת",
            voucherPaymentAddValuPraxellLoadingMsg: 'טוען כרטיס, אנא המתן...',
            okToRemoveCustomerCancelToCorrect: 'אישור למחיקת נתוני לקוח בהקפה, ביטול להשלמת הקלדת הפרטים החסרים.',
            voucherPaymentPraxellAmountExceeds: 'סכום גבוה מעלות המכירה.',
            valuCardBarcodeMotInteger: 'ברקוד כרטיס נטיען אינו תואם לסוג הכרטיס',
            syncComplete: 'עדכון הושלם',
            RecoveringUnfinishedPraxellTransaction: 'מבטל טרנזקציית פרקסל שלא הושלמה',
            creditInvoiceNoItemSelected: "לא נבחר פריט לזיכוי",
            creditInvoiceRemovePendingOtherReplacementItemsFirst: "חובה לנקות פריטים להחלפה שאינם שייכים לזיכוי זה.",
            noBarcodeForColorSizeCombo: "לא קיים ברקוד לפריט בצבע והמידה שנבחרו",
            tamashValuCardOtherPaymentAmountExists: "על כרטיס חיוב תעודת משלוח להופיע לבדו בחשבונית",
            tamashValuVoucher501NotDefined: "לא ניתן להשתמש בכרטיסים שמוגדים כתעודת משלוח - יש לבדוק מול התמיכה שמוגדר שובר 501",
            attemptingToClose: "סוגר את הקופה",
            closeFailed: "פעולת הסגירה נכשלה, נא ליצור קשר עם התמיכה",
            closePos: "סגור קופה",
            closeSaleCreditCardAmountsError: "הסכומים של תשלומי האשראי לא תואמים. נא ליצור קשר עם התמיכה",
            dtsNoledg: "DTS נולדג'",
            genericUserPleaseWait: 'מבצע פניה לשרת...',
            pleaseWait: "נא להמתין...",
            clearFilters: 'נקה סינון',
            dalpak: 'דלפק',
            counter: 'דלפק',
            table: 'שולחן',
            pleaseUpdateWebView: 'יש לעדכן את ה-WebView, יש ליצור קשר עם התמיכה להנחיות',
            xOutOfY: '{index} מתוך {length}',
            mustWorkWithNewPromos: 'שגיאה: קופה חייבת לעבוד במערכת מבצעים חדשה כדי',
            httpService: {
              minApkVersionError: "שגיאה: יש לשדרג גרסת Apk לפחות ל1.62",
              minAddonVersionError: "שגיאה: יש לשדרג גרסת קבצי תוסף בקופה לפחות ל2.85",
            },
            smartVoucher: {
              deposit: 'טעינת כרטיס',
              cancelDeposit: 'ביטול טעינת כרטיס',
              transactionNumber: 'מספר אסמכתא',
              actionSum:'סכום פעולה',
              currentBalance: 'יתרה נוכחית',
              incompatableCard: 'כרטיס לא תואם לשובר',
            },
            emv: {
              paymentNotFoundInRecovery: "שגיאה: החיוב לא הצליח עקב תקלת תקשורת. נא לנסות לחייב פעם נוספת.",
              pinpadResetInstructionsMessage: "אותרה תקלת תקשורת. נא לבצע אתחול לפינפד ע\"י לחיצה על סולמית ועל הכפתור הצהוב במקביל \n לאחר אתחול המכשיר והופעת הלוגו או מספר המסוף, יש ללחוץ על כפתור האישור",
              pinpadResetInstructionsConfirmButton: "ביצעתי, נסה שוב",
              killingPinpadAddon: "אופס….  זיהינו שיש לנו בעיית תקשורת מול המסופון אנא המתינו, מבצעים אתחול למסוף האשראי",
              pinpadRecoveryProcess: "שגיאת תקשורת, מבצע תהליך שחזור עסקה",
              performingPinpadConfiguration: "קורא הגדרות מהמסופון",
              queryTransaction: "מאתר עסקה בפינפאד",
              filteringFaildTran: "מוודא התאמה של עסקאות לפני שידור",
              payAttention: "שים לב",
              PINPAD_NOT_CONNECTED: "החיבור לקורא הכרטיסים אינו תקין. יש לוודא כי קורא הכרטיסים מחובר לקופה ודולק ולנסות שוב.\n במידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
              PINPAD_NOT_CONNECTED_ANDROID: "החיבור לקורא הכרטיסים אינו תקין. יש לוודא כי קורא הכרטיסים מחובר לקופה ודולק ולנסות שוב. \n\nמומלץ לסגור את אפליקציית הקופה ולהיכנס מחדש - כפי שמודגם בתמונה מטה.\n\n במידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
              PINPAD_NOT_CONNECTED_IP: "החיבור לקורא הכרטיסים אינו תקין. יש לוודא כי המסוף דולק ומחובר לרשת. במידה וזה ממשיך, יש לסגור את האפליקצית תשלום ולפתוח אותה מחדש ולנסות שוב.\n במידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
              TRANSMIT_ERROR: "שגיאה בעת שידור תנועות אשראי. לא ניתן להמשיך.\nחובה לפנות לתמיכה.",
              USER_CANCEL: 'הפעולה בוטלה ע"י המשתמש',
              CANNOT_CREDIT_IN_RECOVERY_MODE: 'לא ניתן לזכות כרטיס אשראי במצב עבודה ללא אשראי.\nהחיבור לקורא הכרטיסים אינו תקין או קיים קובץ תנועות ישן על המסופון שטרם שודר',
              OLD_TRAN_FILE_PRESENT: "קיים קובץ תנועות ישן על המסופון שטרם שודר. חובה לבצע התאוששות.\nלחץ על אישור בכדי לנסות לשדר תנועות שוב ולבצע התאוששות.\nמומלץ לפנות לתמיכה.",
              emptyTrack2: "בעיה בקריאת הכרטיס.",
              errorNum: "קוד שגיאה: {error}",
              creditPaymentsExistsButStillGot10019: "שים לב, לא היו עסקאות במסופון לשליחה למתג למרות שקיימות עסקאות אשראי",
              clickOKToCallShva: "לחץ אישור לקליטת נתונים חדשים משבא",
              cardReaderConnectivity: "בודק קישוריות לקורא הכרטיסים",
              EMV_IN_PROGRESS: "מבצע חיוב",
              EMV_RETURN_IN_PROGRESS: "מבצע החזר",
              EMV_CREDIT_IN_PROGRESS: "מזכה כרטיס יש להעביר כרטיס בקורא הכרטיסים",
              GENERAL_ERROR: "שגיאה כללית - יש לוודא כי המסופון מחובר לקופה ולנסות שוב. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              REQUEST_AND_OUTPUT_MISMATCH: "חוסר התאמה בין בקשה לתשובה שהתקבלה - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה.",
              EMV_CREATING_Z_REPORT: "מייצר דוח Z",
              EMV_PERFORMING_TRANSMIT: "מבצע שידור",
              EMV_PERFORMING_TRANSMIT_TO_SWITCH: "מבצע שידור למתג",
              DELETING_TRAN_FILE: "מוחק קובץ TRAN מהמסופון",
              CALLING_SHVA_WITHOUT_TRANSMITTING_TRAN: "קולט נתונים משבא",
              ERROR_WHILE_DELETING_TRAN: "שגיאה בעת מחיקת קובץ TRAN",
              GETTING_TRANS_REPORT: "מקבל דוח שידור משבא",
              CREDIT_HEADER: "זיכוי אשראי",
              CREDIT_DIALOG_CONTENT: "בחר העברת כרטיס או זיכוי ידני",
              CREDIT_DIALOG_CONTINUE_BUTTON_TEXT: "העבר כרטיס",
              CREDIT_DIALOG_CANCEL_BUTTON_TEXT: "הקלדה ידנית",
              PREPARING_TRANSMISSION: "מכין נתונים לשידור",
              CHECKING_PINPAD_CONNECTION: "מוודא חיבור לקורא כרטיסים",
              UPDATING_PINPAD: "מעדכן גירסת תוכנה של המסופון",
              SENDING_LOGS_TO_FTP: "שולח לוגים ל FTP",
              CALLING_CASPIT_TECH_SERVER: "מתחבר לשרת טכנאים של כספיט",
              ERROR_WHILE_DOING_TRANSMIT: "שגיאה בעת שידור עסקאות.\n אנא וודא כי המסוף מחובר לקופה וכי יש חיבור לאינטרנט.\nחובה לפנות לתמיכה בכדי לבצע התאוששות.",
              ERROR_WHILE_DOING_TRANSMIT_WITH_RETRY: "התקשורת למסוף האשראי נכשלה. אנא נסו להפיק Z בשנית. במידה ולא הצלחתם פנו למוקד התמיכה",
              ERROR_WHILE_DOING_TRANSMIT_REPORT_AND_ATTACH_TO_Z: "ארעה שגיאה בעת שידור העסקאות/קבלת דוח העסקאות מ-שבא.\n יש לנסות לסגור דוח זד מחדש.\nבמידה והבעיה חוזרת על עצמה, יש לפנות לתמיכה",
              pinpadConfigurationError: "שגיאה בקבלת הגדרות מסופון",
              blockListFileOutdatedUpdatingIt: 'קובץ חסומים לא מעודכן. מעדכן, אנא המתן...',
              cancelingPayment: "מזכה אשראי",
              checkingForSaleInPinpad: "מבצע בדיקה אם קיים חיוב עבור הכרטיס אשראי לאחר אתחול",
              responseIsNotComplete: "התשובה שחזרה מהמסופון לא מלאה. החיוב בוטל, נא לנסות שוב.",
              responseIsNotCompleteTitle: 'חיוב בוטל',
              badResponseWasntCancelled: 'הייתה שגיאה בחיוב, נא ליצור קשר עם חברת האשראי על מנת לבטל אותו',
              badResponseWasntCancelledTitle: 'שגיאה בחיוב',
              cancelPaymentNotAvailable: "לא ניתן לבטל את תשלום האשראי בשלב זה. יש להשלים את המכירה ואז לבטל את המכירה דרך חשבוניות קודמות",
              pinpadRecovery: {
                title: "התאוששות אשראי",
                content: "אותרה במסוף האשראי עסקה על סך {amount}{currency}.\nהאם לקשר את החיוב לעסקה הנוכחית?\nנא לשים לב – ביטול השיוך יבטל את חיוב האשראי של הלקוח",
                confirmContent: "החיוב שויך בהצלחה למכירה, יש ללחוץ אישור על מנת להיכנס למסך התשלומים"
              },
              creditTerms: {
                regular: "רגיל",
                isracredit: "ישראקרדיט",
                adifPlus30: "עדיף/+30",
                credit: "קרדיט",
                immediateCharge: "חיוב מיידי",
                payments: "תשלומים"
              },
              panEntries: {
                magnetic: "מגנטי",
                cellular: "סלולרי",
                manual: "ידני",
                kaf: "כ"
              },
              authCodesManpik: {
                card: "כרטיס",
                company: "חברה",
                shva: 'שב"א',
                manual: "ידני"
              },
              tranTypes: {
                informative: "אינפורמטיבי",
                debit: "חובה",
                dischrage: "פריקה",
                enforced: "מאולצת",
                cash: "מזומן",
                standingOrder: "הוראת קבע",
                checkBalance: "בירור יתרה",
                credit: "זכות",
                charge: "טעינה"
              },
              cardDenied: "סירוב",
              errors: {
                "10000": "לא התקבלה תשובה",
                "10003": "מספר מסוף שגוי",
                "10022": "מספר קופה שגוי (ECR)",
                "10036": "XML_MISSING_TERMINAL_ID_TAG",
                "10041": "אין תקשורת למסופון",
                "10042": "XML_MISSING_REQUEST_ID_TAG",
                "10043": "יש לבצע עדכון מידע לקופה. \n התגלתה שגיאה בחיבור למסופון האשראי, במידה וזה חוזר על עצמו לאחר עדכון מידע יש להתקשר לתמיכה 10043 ",
                "10048": "CHECK_OTHER_STATUSES",
                "10050": "DOUBLE_TRANSACTION",
                "10053": "המסופון החזיר תשובה ריקה",
                "10054": "הגדרה שגויה של אשראית",
                "10100": "INVALID_PAN_ENTRY_MODE",
                "10101": "INVALID_MTI",
                "10102": "INVALID_X_FIELD",
                "10501": "הכרטיס לא הועבר בזמן",
                "11000": "לא ניתן להשתמש בכרטיס אשראי בפעולה זו",
                "11001": "הועבר כרטיס לא תואם",
              },
              pinpadErrorTitle: "בעיה בחיבור למסוף אשראי",
            },
            hakafa: {
              SERVER_INTERNAL_ERROR: "שגיאה כללית",
              FOUND_CUSTOMER: "לקוח נבחר",
              RECORD_NOT_EXISTS: "לא נמצא לקוח",
              MULTIPLE_CLIENTS_WITH_SAME_TZ: "MULTIPLE_CLIENTS_WITH_SAME_TZ",
              MISSING_TZ_PARAM: "MISSING_TZ_PARAM",
              BIRTHDAY_FORMAT_NOT_IN_DDMMYYYY: "תאריך אינו בפורמט של dd/mm/yyyy",
              ALL_FIELDS_ARE_MANDATORY_MISSING_VALS: "חובה להקליד ערך בכל השדות מלבד תאריך",
              NO_CLIENT_SELECTED: "חובה לבחור לקוח",
              INVALID_TZ: "מבנה ת.ז. שגוי",
              HAKAFA_ITEM_WITH_NO_CUSTOMER: "לא ניתן לשלם על חשבון הקפה ללא לקוח הקפה",
              HAKAFA_PRICE_GT_DEBIT_AMNT: "לא ניתן לזכות חשבון יותר מיתרת החוב",
              QTY_GT_ONE: "לא ניתן להציב פריט מיוחד עם כמות גדולה מאחד",
              HAKAFA_ITEM_MOST_BE_ONLY_ITEM: "פריט תשלום חוב הקפה חייב להופיע לבדו בחשבונית",

            },
            beengo: {
              itemReservedForBeengo: "פריט שמור עבור אפליקציית Beengo",
              transactionRejectedInApplication: "העסקה לא אושרה באפליקציה",
              beengoAuthorizationRejected: "בקשת לאישור בינגו נדחתה",
              waitingForUserApproval: "ממתין לאישור הלקוח...",
              beengoNotAllowedAlreadyHakafaUserSelected: "לא ניתן לשלם באפליקציה אם נבחר לקוח הקפה",
              amountZero: "לא ניתן לשלם סכום אפס",
              paymentUsingTheApplication: "תשלום באמצעות האפליקציה",
              enterBeengoUserPhoneNumber: "נא להזין את הטלפון הנייד של הלקוח",
              valueRequired: "חובה להזין ערך",
              beengoPrintMessage: "התקבל באפליקציית BEENGO, טרנזקציה:",
            },
            voucher:{
              title: "סוג שובר",
              selectVoucherType: "בחר סוג שובר",
              continue: "המשך",
              back: "חזרה",
              dontExist: "לא קיימים שוברים לחנות זו",
              clear: "נקה שדות"
            },
            pageTitle: {
              init: 'טוען...',
              employeeTimeTrack: 'דיווח שעות',
              homepage: 'דף הבית',
              sync: 'עדכון מידע',
              cashier: 'בחר קופאי/ת',
              params: 'פרמטרי קופה',
              login: 'התחברות',
              lock: 'הקופה נעולה',
              pos: 'קופה',
              posPastInvoices: 'חשבוניות קודמות שלי',
              posPastInvoice: 'חשבונית קודמת',
              posSearchInvoices: 'איתור חשבוניות מהרשת',
              posItemStock: 'בדיקת מלאי בחנויות',
              posItemChooser: 'בחר מידה/צבע',
              posDiscount: 'בחירת הנחה',
              posCustomerClub: 'חבר מועדון',
              posPayment: 'קבלת תשלום',
              posCloseSale: 'סיום חשבון',
              posSalesPerson: 'בחר מוכר/ת',
              creditTypePicker: 'בחר סוג אשראי',
              xreport: 'דוח x',
              cancelInvoice: 'ביטול חשבונית',
              creditInvoice: 'זיכוי חשבונית',
              storeSelect: 'בחר חנות'
            },
            addItemsToPromotion: {
              title: 'הוספת פריטים למבצע',

            },
            xReport: {
              tamashTitle: "תשלומי הקפה",
              tamashTotal: "סכום"
            },
            qrDialog: {
              title: "קוד QR",
              ok: "סגור"
            },
            promotionManage: {
              title: 'ניהול מבצעים',
              searchPromotion: 'חיפוש מבצע',
              code: 'קוד',
              name: 'שם',
              template: 'סוג מבצע',
              actions: 'פעולות',
              priority: 'עדיפות',
              status: 'סטטוס',
              isActive: 'פעיל',
              isNotActive: 'לא פעיל',
              edit: 'עריכה',
              addPromotionTitle: 'הוספת מבצע',
              editPromotionTitle: 'עריכת מבצע',
              addPromotionPriority: 'עדיפות - מספר בין 1 ל 100 - ככל שהמספר נמוך, העדיפות גבוהה יותר',
              addPromotionDays: 'ימים בשבוע בהם המבצע בתוקף',
              addPromotionFromDate: 'בתוקף מתאריך',
              addPromotionToDate: 'בתוקף עד תאריך',
              addPromotionActive: 'פעיל - אם לא מסומן, המבצע לא יופעל בקופה',
              addPromotionActiveShort: 'פעיל - יש לסמן להפעלת המבצע',
              saveAndExit: 'שמור וסגור',
              exitWithoutSave: 'צא ללא שמירה',
              back: 'חזרה',
              addPromotion: 'הוספת מבצע',
              noTemplatesExist: 'לא נמצא סוג מבצע',
              nameCannotBeEmpty: "חובה להזין שם",
              templateCannotBeEmpty: "חובה לבחור סוג מבצע",
              priorityCannotBeEmpty: "חובה להזין עדיפות",
              priorityNeedToBeNumber: 'עדיפות צריך להיות מספר שלם גדול מ-0',
              addItemsToPromotion: 'הוסף פריטים למבצע',
              KneBeDiscountType: 'מהפריטים שנבחרו למבצע',
              KneBeDiscountValue: 'פריטים ב- ',
              KneBeMinimumBuyQuantity: 'קנה',
              cannotAddItemHasWeightOrZeroPrice: 'לא ניתן להוסיף למבצע פריט שקיל או פריט עם מחיר לצרכן 0',
              itemNotRelatedToPromotion: 'בחר',
              isItemRelatedToPromotion: 'בטל בחירה',
              discountTypeAmount: 'ש"ח הנחה',
              discountTypePercent: 'אחוז הנחה',
              discountTypeFix: 'ש"ח',
              templateNameKneBe: 'קנה X פריטים בהנחה/מחיר קבועים',
              templateNameHaZolMe: 'קנה X פריטים קבל הנחה על הזול מביניהם',
              templateNameKneKabel: 'קנה/קבל - קנה X פריטים קבל הנחה/מחיר קבוע על פריט Y',
              buyOptionAmount: 'שקלים',
              buyOptionQuantity: 'פריטים',
              kneCannotBeEmpty: 'ערך קנה שדה חובה',
              kneNeedToBeBiggerThenZero: 'ערך קנה חייב להיות מעל 0',
              minimumBuyQuantityMinimum2: 'ערך קנה חייב להיות מינימום 2',
              kabelCannotBeEmpty: 'ערך קבל שדה חובה',
              kabelNeedToBeBiggerThenZero: 'ערך קבל חייב להיות מעל 0',
              discountValueCannotBeEmpty: 'ערך הנחה שדה חובה',
              discountValueNeedToBeBiggerThenZero: 'ערך הנחה חייב להיות מעל 0',
              addItemsToPromotionBuy: 'הוסף פריטים לקבוצה 1',
              addItemsToPromotionGet: 'הוסף פריטים לקבוצה 2',
              itemsBuyCannotBeEmpty: 'חובה לבחור פריטים לקבוצה 1',
              itemsGetCannotBeEmpty: 'חובה לבחור פריטים לקבוצה 2',
              itemsCannotBeEmpty: 'חובה לבחור פריטים המשתתפים במבצע',
              toDateCannotBeLittleFromDate: 'פעיל עד תאריך לא יכול להיות קטן מפעיל מתאריך',
              errorOnLoad: 'שגיאה כללית בטעינת מבצע - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה',
              errorOnSave: 'שגיאה כללית בשמירת מבצע - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה',
            },


            currenciesNames: {
              ILS: 'שקל חדש',
              USD: 'דולר אמריקאי',
              EUR: 'יורו',
              GBP: 'לירה שטרלינג',
              AUD: 'דולר אוסטרלי',
              CAD: 'דולר קנדי',
              CHF: 'פרנק שוויצרי',
              DKK: 'כתר דנמרק',
              EGP: 'לירה מצרית',
              JOD: 'דינר ירדני',
              JPY: 'יין יפני',
              LBP: 'לירה לבנונית',
              NOK: 'כתר נורווגיה',
              SEK: 'כתר שוודיה',
              ZAR: 'רנד דרום אפריקה'
            },
            rate: 'שער',

            selectCurrencyPayment: {
              title: 'בחר מטבע תשלום',
              noCurrencies: 'לא נמצאו מטבעות זמינים (יש להגדיר מטבעות מאופשרים בהגדרות משרד)',
      unsupportedCurrency: 'מטבע {currency} לא נתמך'
            },

            currencyConversionCalculator: {
              title: 'מחשבון המרת מטבעות',
              back: 'חזרה',
      amount: 'סכום ב{currency}'
            },
            advanceItemSearch :{
              title: "חיפוש פריט מתקדם",
              itemCodeContains: "קוד פריט מכיל",
              itemDescriptionContains: "תיאור פריט מכיל",
              brand: "מותג",
              age: "גיל",
              department: "מחלקה",
              under: "תת",
              subcategory: "משנית",
              priceFrom: "ממחיר",
              priceTo: "עד"
          },
            pickupFormDialog: {
              title: 'פרטי איסוף',
              id: 'ת.ז',
              passport: "דרכון",
              phone: 'טלפון',
              returnDate: 'תאריך חזרה',
              flight: 'טיסה',
              idIsNotValid: 'תעודת זהות לא תקינה',
              phoneIsNotValid: 'טלפון לא תקין',
              returnDateIsNotValidFormat: "פורמט תאריך אינו dd/mm/yy.",
              returnDateIsNotValid: "תאריך חזרה אינו חוקי",
              returnDateIsSmallerFromToday: "תאריך חזרה לא יכול להיות קטן מהיום",
              returnDateIsBiggerMoreThanYaer: 'תאריך חזרה גדול משנה',
              flightIsNotValid: 'מספר טיסה לא תקין',
              packaging: 'אריזות',
              notes: 'הערות',
              passengerName: 'שם נוסע',
              roomNumber: 'מספר חדר',
              pickup: 'פיקאפ',
              totalPickup: 'סה"כ פיקאפ',
              passportDetails: 'פרטי דרכון של הנוסעים',
              declaringSignature: 'הנני מצהיר בחתימתי',
              acceptingItems: 'קבלת המוצרים הנ"ל',
            },

            reminderPickup: {
              title: 'תזכורת פיקאפ',
              content: 'משאיר לפיקאפ?'
            },

            validate: {
      required: '{input} שדה חובה',
      maxCharLength: '{input} לא יכול להיות מעל {max} תווים',
      minCharLength: '{input} חייב להיות לפחות {min} תווים',
            },

            flightCardDialog: {
              title: 'פרטי נוסע',
              originNotSelect: 'יש לבחור סוג תייר (תייר/ישראלי)',
              id: 'מספר דרכון',
              barcode: 'כרטיס טיסה',
              tourist: 'תייר',
              israeli: 'ישראלי',
              idIsNotValid: 'מספר דרכון לא תקין',
              approvalTitle: 'בחר',
      multiFlightsMode: '{from} מתוך {to}',
              barcodeOrIdAlreadyUsed: 'מספר דרכון או מספר ברקוד כבר בשימוש'
            },

            cashierStatementMultiCurrencies: {
              enter: 'פתיחת קופה',
              exit: 'סגירת קופה',
              currency: 'מטבע',
              currencyAmount: 'סכום מטבע',
              currencyPosAmount: 'סכום ב{currency}',
              cashierStatementCurrencyTitle: 'הצהרת קופאי סכום למטבע',
              selectedCurrency: 'הצהרה למטבע {currency}',
              calcedCurrency: 'סה"כ מחושב המטבע',
              statedCurrency: 'סה"כ מוצהר המטבע',
              statedCurrencyInCurrencyPosAmount: 'סה"כ מוצהר המטבע ב{currency}',
              statedAndCalcedGap: 'סה"כ הפרש הצהרה המטבע',
              amount: 'סכום',
              quantity: 'כמות',
              total: 'סה"כ',
              type: 'סוג הצהרה',
              totalCashInPos: 'מזומן בקופה',
              totalCashStatement: 'סה"כ הצהרה',
            },

            vouchersWithoutInterfacesReport: {
              title: "דוח שוברים",
              print: "הדפס מכירות",
              printSum: "הדפס סיכום",
              selectVoucher: "בחר שובר",
              noVouchers: "לא קיימים שוברים",
              periodTooLong: "לא ניתן לבחור טווח גדול משנה",
              loadingVouchers: "טוען רשימת שוברים",
              errorLoadingVouchers: "שגיאה בטעינת רשימת שוברים",
              generatingReport: "מייצר דוח",
            },

            banxPayment: {
              globalError: 'שגיאה בתשלום צור קשר עם התמיכה',
              customerDeclinedPayment: 'הלקוח דחה את הבקשה לתשלום',
              errorApiToken: 'שגיאת חיבור לbanx צור קשר עם התמיכה',
              responseErrorBanx: 'שגיאת בקשה מול שירות banx'
            },

            safeCash: {
              globalError: 'שגיאה בתשלום KashCash צור קשר עם התמיכה',
              customerDeclinedPayment: 'הלקוח דחה את הבקשה לתשלום',
              customerCancelledPayment: 'הבקשה בוטלה באפליקציית KashCash',
              errorApiToken: 'שגיאת חיבור לKashCash צור קשר עם התמיכה',
              responseErrorSafeCash: 'שגיאת בקשה מול שירות KashCash',
              createQR: 'צור QR',
              createPaymentCode: 'צור קוד תשלום',
              waitingGetPaymentFromCustomer: 'ממתין לקבלת תשלום מהלקוח...',
              createRequestForCodePayment: 'מייצר בקשה לקוד תשלום מול KashCash...',
              codeForPay: `הקוד לתשלום: {code}`,
              errorCancelTransaction: 'שגיאה בביטול טרנזקציה מול KashCash',
              cancelTransaction: 'מבטל טרנזקציה מול KashCash',
              errorMessage: 'הודעת שגיאה KashCash',
              safeCashPayment: 'תשלום KashCash',
              scanBarcodeWithApp: 'יש לסרוק את קוד ה-QR שמופיע על המסך באמצעות אפליקציית KashCash',
              validation:{
                insertValidAmount:"יש להזין סכום גדול מ-0"
              },
              proccessingPayment: 'מעבד בקשה לתשלום, אנא המתן...',
              seeCode: "לא מצליחים לסרוק? לחצו כאן ליצירת קוד",
              codeTitle: "הקוד לתשלום:",
              waiting: "ממתין לקבלת תשלום מהלקוח",
              cancel: "ביטול",
            },

            flightSaleWithPassDialog: {
              scan: "אנא סרוק כרטיס עליה למטוס:",
              name: "שם לקוח",
              date: "תאריך",
              flightNumber: "מספר טיסה",
              typing: "הזנת נתוני טיסה",
              continue: "המשך",
              cancel: "ביטול",
              wrongBarcode: "הברקוד קצר מדי",
              wrongDate: "תאריך הטיסה שונה מהטווח המותר",
              wrongFlightNumber: "מספר הטיסה שגוי",
              fillAllFields: "נא למלא כל השדות",
              scanButton: "סרוק"
            },
            flightsOnlineLoginDialog: {
              loginError: "התחברות נכשלה. אנא נסה לפתוח את הקופה מחדש. אם הבעיה ממשיכה - אנא פנה לתמיכה.",
              flightAttendantLogin: "התחברות כלכל",
              flightAttendantNumber: "מספר כלכל",
              loginOnlyOnline: "ההתחברות דורשת חיבור לרשת.",
              selectFlightTitle: "בחר טיסה",
              flightNumber: "מספר טיסה",
              flightEmptyError: "אנא הכנס מספר טיסה",
              enterFlightNumber: "הכנס מספר טיסה ידנית",
              scanQr: "סרוק קוד QR",
              scanningQr: "סורק קוד",
              scanDispatchNote: "אנא סרוק את הקוד QR של הטיסה",
              flightNotFound: "טיסה לא נמצאה, אנא נסה שנית",
              searchingFlight: "מחפש טיסה",
              flightDetailsTitle: "פרטי טיסה",
              attachToFlight: "מתחבר לטיסה",
              flightInProgressTitle: "שים לב!",
              flightInProgressBody: "טיסה זו נפתחה כבר.",
      errorUpdaingBlacklist: "שגיאה בהורדת קובץ חסומים - {code} \n האם לנסות שנית?",
              pinpadInitFailed: "אתחול מסופון נכשל, להמשיך בכל זאת?"
            },

            flightsDisconnectDialog: {
              checkingData: "בודק מידע",
              disconnectTitle: "נתק טיסה",
              confirmDisconnect: "האם אתה בטוח שאתה רוצה להתנתק מהטיסה?",
              cantDisconnectWifi: "לא ניתן להתנתק מהטיסה במצב לא מקוון.",
              cantDisconnectSales: "לא ניתן להתנק מהטיסה כשיש מכירות לקופה.",
              errorDisconnect: "ארעה שגיאה בעת הניתוק מהטיסה, אנא נסה שוב מאוחר יותר.",
              disconnecting: "מתנתק"
            },
            selfServiceSelectCustomer: {
              description: "נא הזינו את שמכם המלא",
              namePlaceHolder: "שם מלא",
              descriptionSubtext: "נקרא בשמכם כשההזמנה תהיה מוכנה"
            },
            selfServicePaymentMethod: {
              selectPayment: "איך אתם רוצים לשלם?",
              leftToPay: `סה"כ לתשלום`,
              errorMessages:{
                noPaymentSelected: "לא נבחר כמות תשלומים - פעולה בוטלה"
              }
            },
            selfService: {
              printerError: 'שגיאת מדפסת: \n {errors}',
              printerErrorCodes: {
                PRINTER_OFFLINE: "מדפסת לא זמינה",
                PAPER_FEEDING: "שגיאה בהזנת נייר",
                COVER_OPEN: "דלת המדפסת פתוחה",
                PRINTER_ERROR: "שגיאת הדפסה",
                CUTTER_ERROR: "שגיאת חותך",
                PAPER_NEAR_END: "נייר עומד להיגמר",
                OUT_OF_PAPER: "נגמר הנייר",
              },
              ototBracelet: 'צמיד',
              loadCard: 'כרטיס לטעינה',
              creditCardShort: "אשראי",
              creditCardPaymentsShort: "אשראי תשלומים",
              hakafaCustomerPayment: 'תשלום בהקפה',
              creditCard: "כרטיס אשראי",
              cash: 'מזומן',
              cashPayment: {
                note: 'יש לגשת לקופה הראשית לאחר אישור ההזמנה',
                creatingOrder: 'יוצר הזמנה',
                noteAfterConfirm: 'יש לגשת לקופה הראשית לביצוע התשלום וסיום ההזמנה',
                orderNumber: 'מספר הזמנה',
                takeReceiptPlease: "נא לקחת את הפתקית ולהציג אותה לקופאי בבקשה",
                offlineError: 'בעיית תקשורת בעמדה - יש לפנות לקופאי',
              },
              pleaseSwipeCard: "נא להעביר אמצעי תשלום ולהמתין לאישור",
              pleaseSwipeCardNoPayment: "יש להעביר אמצעי תשלום ולהמתין לאישור",
              cancelText: "לחץ על הכפתור האדום בקורא הכרטיסים לביטול הפעולה",
              pleaseWait: "נא להמתין...",
              chargingCard: "מחייב כרטיס",
              amountExceedsTotalAmount: 'סכום התשלום בשובר גבוה מסה"כ העסקה. \n סכום השובר עודכן לסכום הנותר בעסקה.',
              tenbis: "תן ביס",
              pluxee: "פלאקסי",
              hakafa: "כרטיס עובד",
              goodi: "גודי",
              hakafaCustomer: "הקפה",
              safeCash: 'KashCash',
              chargeableCard: "כרטיס נטען",
              balanceMsg: `אין מספיק יתרה בכרטיס \n
      לרשותך {balance} בכרטיס זה, \n
              האם ברצונך לחייב את יתרת הכרטיס ולהמשיך לשלם את היתרה באמצעי תשלום אחר?`,
              balanceMsgOK: "חייב יתרה",
              saleperson: "",
              caveret: "כוורת",
              hoger: "חוגר",
              gpp: 'תו הזהב',
              paymentX: 'תשלום {payment}',
              insertCardNumber: 'יש להקליד את מספר הכרטיס',
              howDoYouPay: 'איך אתם משלמים?',
              keypadInput: 'הקלדה ידנית',
              swipeCard: 'העברת כרטיס',
              cardNumber: "מספר כרטיס",
              cancelSwipeOnPinpad: 'לביטול הפעולה יש ללחוץ על הכפתור האדום בקורא הכרטיסים',
            },
            selfServiceSupermarket: {
              salePerson: "שירות עצמי",
              posHeader: "סרוק מוצר או בחר מהתפריט",
              managerActions: "פעולות מנהל",
              managerApprovalTitle: 'מכירה זו צריכה לעבור אישור מנהל',
              selectPaymentType: 'אנא בחר סוג תשלום',
              swipeCard: 'העבר כרטיס',
              errors: {
                fieldsAreRequired: 'שדות חייבים להיות מלאים'
              },
            },
            selfServiceAfterSaleScreen: {
              genericTitle: 'מכירה הסתיימה בהצלחה',
              thanksForBuying: "תודה שקנית אצלנו",
              dontForgetReceiptAndItems: "זכרו לקחת את הפריטים והקבלה",
              total: "סהכ שולם",
              printReceiptHeader: "הדפסת חשבונית",
              printReceiptContent: "האם להדפיס חשבונית?",
              yes: "אישור",
              no: "ביטול"
            },
            flightsWizard: {
              finishTitle: "התהליך הסתיים",
              finish: "התהליך הסתיים. להמשיך?",
              noMoreLegs: "לטיסה זו אין יותר לגים",
              openLegCompleted: "פתיחת לג הושלמה",
              closeLegCompleted: "סדירת לג הושלמה",
              commitChanges: "לבצע שינויים?",
              israirFinishFlight: "האם לסגור את הטיסה? שים לב, לאחר פעולה זו לא ניתן לבצע מכירות",
              goBack: "חזרה",
              approve: "אישור",
              thereIsOpenSale: "קיימת מכירה פתוחה, אנא סגור אותה על מנת להמשיך",
              thereIsHoldSale: "קיימת מכירה בהשהייה, אנא סגור אותה על מנת להמשיך",
              closeFlightOfflineError: "לא ניתן לסגור טיסה במצב נתק",
              cantOpenPosWithoutZ: "הטיסה נסגרה, אנא בצע דוח Z ופתח טיסה חדשה על מנת להמשיך",


              numberOfPassengers: {
                title: "סגירת לג",
                legNumber: "מספר לג",
                numberOfPassengers: "מספר נוסעים"
              },
              offlineLogin: {
                title: "התחברות",
                legNumber: "מספר לג",
                onlyManager: "רק דייל ראשי רשאי להתחבר",
                onlyAuhtorizedTeam: "רק דיילים מהצוות של הלג הקודם רשאים להתחבר",
                userNotFound: "מספר דייל שגוי, אנא נסה שנית"
              },
              authorizesEmployees: {
                title: "בחר מורשי טיסה",
                employees: "מורשי טיסה",
                noAuthorizedEmployees: "אין מורשי טיסה נוספים זמינים"
              },
              barsetCount: {
                title: "ספירת מלאי",
                startCount: "ספירת פתיחה",
                endCount: "ספירת סגירה",
                promptBarsetCount: "האם ברצונך לבצע ספירת מלאי?",
                item: "פריט",
                itemName: "שם",
                calculatedCount: "מחושב",
                actualCount: "נספר",
                validationFailed: "חלק מהמידע שהוכנס חסר או אינו תקין, אנא וודא את המספרים שהונסו.",
                diff: "הפרש",
                skipWarning: "שים לב! בעת דילוג את הספירת מלאי הספירה מתאפסת לכמות המחושבת, והאחריות על כמויות הפריטים והחוסרים שייכת לך."
              },
              printBarsetCountReport: {
                title: "הדפס דוח",
                promptPrintReport: "האם ברצונך להדפיס דוח מלאי?",
              },
              changeTeam: {
                title: "חילוף צוות",
                prompt: "האם צוות הטיסה מתחלף?",
                lastLeg: "זהו הלג האחרון של טיסה זו.",
                attention: "שים לב!",
              },
              printFlightLegSumReport: {
                title: "דוח סיכום לג",
                promptPrintReport: "הדפס דוח סיכום לג",
              },
              cashierStatement: {
                title: "הצהרת מזומנים",
                currency: "מטבע",
                calculated: "צפי",
                actual: "פועל",
                diff: "הפרש",
                total: "סהכ",
        totalExpInCurr: "צפי {currency}",
        totalActInCurr: "{currency} בפועל",
        totalDiffInCurr: "הפרש {currency}",
        expInCurr: "צפי {currency}",
        actInCurr: "{currency} בפועל",
        diffInCurr: "הפרש {currency}",
                overpay: "עודף",
                underpay: "חוסר",
                equal: "שווה",
                totalCreditAppendants: "מספר ספחי אשראי",
                totalCreditPayments: "סכום תשלומי אשראי",
                creditAppendants: "ספחי אשראי",
                creditPayments: "סה\"כ אשראי",

              },
              enterFlightCode: {
                title: "הכנס קוד טיסה",
                flightCode: "קוד טיסה"
              },
              stockCount: {
                title: "ספירת מלאי",
                promptBarsetCount: "האם ברצונך לבצע ספירת מלאי?",
                item: "פריט",
                itemName: "שם",
                calculatedCount: "מחושב",
                validationFailed: "חלק מהמידע שהוכנס חסר או אינו תקין, אנא וודא את המספרים שהוכנסו.",
                diff: "הפרש",
                skipWarning: "שים לב! בעת דילוג את הספירת מלאי הספירה מתאפסת לכמות המחושבת, והאחריות על כמויות הפריטים והחוסרים שייכת לך.",
                startCount: "פתיחה",
                salesCount: "נמכר",
                currentCount: "כמות נוכחית",
                actualCount: "כמות בפועל",
                fill: "מלא",
              }
            },
            flightsInventoryReport: {
              title: "דוח מלאי",
              currentCount: "ספירה נוכחית",
              startCount: "ספירת פתיחה",
              endCount: "ספירת סגירה",
              flightNumber: "מספר טיסה",
              legNumber: "מספר לג",
              journeyKey: "קוד טיסה",
              date: "תאריך",
              origin: "מקור",
              destination: "יעד",
              user: "עובד",
              printed: "הודפס",
              tableHeaders: {
                product: "פריט",
                packed: "א",
                counted: "נ",
                diff: "ה",
                countedLong: 'נספר',
                start: 'פתיח',
                sold: 'נמכר',
                diffLong: 'הפרש',
              },
            },
            flightsCashierStatementReport: {
              title: "הצהרת מזומנים",
              approvedReport: "מאושר",
              notApprovedReport: "לא מאושר",
              tableHeaders: {
                currency: "מטבע",
                expected: "צ",
                actual: "פ",
                diff: "ה",
              }
            },
            flightsCurrenciesReport: {
              title: "שערי מטבע",
              conversion: "המרה",
              rate: "שער",
            },
            flightsCloseLegReport: {
              title: "דוח סיכום לג",
              totalPayments: "סה\"כ תשלומים",
              currencyPayments: "תשלומים במטבעות",
              itemSales: "מכירות ע\"פ פריט",
              discount: "הנחה",
              allLegs: "סיכום לגים",
              legNumber: "לג מספר",
              flightsCloseLegReportTable: {
                name: "שם",
                sum: "סכום",
                count: "כמות"
              },
              flightsCloseLegReportCurrenciesTable: {
                type: "סוג",
                currency: "מטבע",
                sum: "סכום",
                creditCount: "אשראי"
              },
              totalPaymentsSum: "סה\"כ תשלומים: ",
              totalItemsSum: "סה\"כ פריטים: ",
              saleTypes: {
                all: "הכל",
                1: "Retail",
                2: "Crew",
                3: "Complimentary",
              },
              sales: ""
            },

            flightZReport: {
              title: "סגירת טיסה",
              message: "פעולה זו תסגור את הטיסה ותיצור דוח Z. להמשיך?",
              closingFlightWait: "סוגר טיסה, אנא המתן..."
            },

            barset: {
              stockUnitTypes: {
                1: 'עגלה',
                2: 'קונטיינר',
              },
              drawer: 'מגירה',
            },

            flightsCrewDiscountEmployee: {
              title: "קוד עובד",
              body: "קוד עובד עבור ההנחה",
              placeholder: "קוד",
              fieldRequired: "אנא הכנס קוד עובד",
            },
            emailDialog: {
              title: "שליחת מייל",
              emailLabel: "בחירת מען",
              emailSent: "המייל נשלח בהצלחה",
              emailSendError: "בעיה בשליחת המייל, אנא נסה שנית מאוחר יותר",
              back: "חזרה",
              noEmailError: "חובה לבחור עובדים עם מייל לשליחה. במידה והרשימה ריקה, יש להגדיר כתובת מייל לעובדים תחת מסך ניהול עובדים",
              send: "שלח",
              sending: "שולח מיילים"
            },
            withdrawalReport: {
              title: "דו\"ח משיכת מזומן",
            },
            techCardDialog: {
              title: "כרטיס טכנאי",
              desc: "נדרש כרטיס טכנאי, אנא העבר או הקלד כרטיס טכנאי",
              placeholder: "העבר או הקלד כרטיס טכנאי",
            },
            addonFilesUpdateDialog: {
              title: "עדכון גרסה",
              desc: "אנא בחר גרסה לעדכון",
              currentVersion:"גרסה נוכחית",
            },
            addonUpdate:{
              updatingMessage:"מעדכן גרסת תוסף...",
              success:"עדכון הושלם",
              version:"גרסה",
              successMessage:"עודכנה בהצלחה"
            },
            employeesBonusReport: {
              title: "דוח בונוס לעובד",
              seller: "מוכר",
              emptyFieldForAll: "יש להשאיר שדה ריק עבור כל העובדים בחנות",
              bonus: "בונוס",
            },
            selectEmployeeDialog: {
              selectEmployee: "בחר עובד",
              noEmployees: "אין עובדים לחנות הנוכחית",
            },
            employeesHoursReport: {
              title: "דוח שעות לעובד",
              employee: "עובד",
              emptyFieldForAll: "יש להשאיר שדה ריק עבור כל העובדים בחנות",
              month: "חודש",
            },
            exceptionsReportDialog: {
              title: 'דוח  חריגים',
              irregularReturns: 'החזרות חריגות',
              manualDiscounts: 'הנחות ידניות',
              priceChanges: 'שינוי מחירים',
              noExceptionSelected: 'לא נבחרה סוג חריגה',
              noDateSelected: 'לא נבחרו תאריכים',
            },
            openformatReport: {
              title: 'דוח לרשות המיסים',
              filesLocation: 'את הקבצים יש לשמור בבספריה זו',
              datesNotSelected: 'לא נבחרו תאריכים',
            },
            reportPromotionDialog: {
              title: 'דוח מבצעים פעילים',
              promotionName: 'שם מבצע',
              promotionCode: 'קוד',
              fromDate: 'מתאריך',
              toDate: 'לתאריך',
            },
            promotionsReport: {
              title: "דוח מבצעים",
              promotionName: "שם מבצע",
              promotionCode: "קוד מבצע",
              startDate: "תאריך התחלה",
              endDate: "תאריך סוף",
              nameOrCode: "שם\\קוד מבצע",
            },
            lockScreenDialog: {
              title: 'קופה נעולה',
              swipeOrPressButton: 'העבר כרטיס לשחרור מסך או לחץ על כפתור טביעת אצבע',
              continue: 'המשך',
              fingerPrint: 'טביעת אצבע',
              statuses: {
                idle: 'ממתין להעברת כרטיס',
                employeeNotFound: 'לא נמצא עובד התואם לטביעת אצבע או כרטיס עובד',
                employeeNotCashierOrManager: 'רק עובד עם הרשאות מנהל או קופאי יכולים לשחרר נעילת קופה',
              }
            },
            fingerprint: {
              fetchingFingerprintsFromServer: "מעדכן טביעות אצבע מהשרת",
              fetchFromServerError: "שגיאה בקבלת טביעות אצבע מהשרת, נא לוודא שהקופה במצב און ליין",
              updatingDeviceDB: "מעדכן מסד נתונים בקורא",
              errorInPopulating: "שגיאה בעדכון נתונים בקורא",
              enrollError: "שגיאה בשמירת טביעת אצבע",
              alreadyEnrolled: "לעובד זה כבר מוגדרת טביעת אצבע",
              enrolling: "נא להניח את האצבע על הקורא 3 פעמים",
              updateFingerprintInServerError: "שומר טביעת אצבע בשרת",
              verifying: "נא להניח את האצבע על הקורא",
              verifyingError: "שגיאה בזיהוי טביעת אצבע",
              refreshDB: "רענון קורא טביעות אצבע",
              updatingFingerprintInServer: "מעדכן טביעת אצבע בשרת",
              addonUpdateIsNeeded: "גירסת תוסף לא מעודכנת, יש לעדכן תוסף דרך מסך פרמטרים",
              error: {
                "1001": "לא נמצא קורא טביעות אצבע",
                "1002": "רישום טביעת אצבע נכשל",
                "1003": "משתמש לא נמצא",
                "1004": "טביעת אצבע כבר רשומה",
                "1005": "איכות טביעת האצבע נמוכה",
                "1006": "מסד נתונים בקורא האצבעות לא מעודכן",
                "-1000": "שגיאה כללית בקורא טביעת אצבע, נא לנסות שנית",
                default: "שגיאה בקורא טביעת אצבע, נא לפנות לתמיכה"
              }
            },
            loadingMessageRefresh: {
              oops: 'אופס...',
              posStuck: 'נראה שהפעולה לוקחת ממש הרבה זמן או  תקועה לחץ על הכפתור לביטול הפעולה ורענון הקופה',
              refresh: 'רענון קופה',
            },
            itemInventoryDialog: {
              itemInventory: 'מלאי לפריט',
              itemInventoryError: 'שגיאה בדיקת מלאי לפריט',
              itemInventoryFor: 'מלאי לפריט',
              itemInventoryNotFoundInventory: 'לא קיימים נתוני מלאי לפריט זה',
              itemInventoryGlobalError: 'שגיאה כללית בדיקת מלאי לפריט. צור קשר עם התמיכה',
              itemInventoryNotFoundDataFromPriority: 'לא קיימים נתוני מלאי לפריט זה בפריורטי',
              storeCode: "מספר חנות",
              storeName: "שם חנות",
              quantity: "כמות",
              changeSizeColor: "שנה מידה וצבע"
            },
            checkCardBalanceDialog: {
              title: 'בירור יתרה',
              selectCardType: 'בחר סוג כרטיס',
              swipeCard: 'העבר כרטיס',
              chargableCard: 'כרטיס נטען פנימי',
              chargableCreditCard: 'כרטיס אשראי נטען',
              punchCard: 'כרטיסייה',
              paraxel: "פרקסל",
              valueCard: "VALUE CARD",
              multipass: "מולטיפס",
              multipassPoints: "מולטיפס נקודות"
            },
            complimentarySale: {
              cantAdd: "לא ניתן להוסיף פריט",
              onlyComplimentaryItems: "רק פריטי Complimentary יכולים להתווסף למכירה זו",
              notAllComplimentaryItems: "המכירה הנוכחית מכילה פריטים שהם אינם Complimentary"
            },
            closeSaleDialog: {
              title: "סיום מכירה",
              totalQuantity: 'סה"כ פריטים',
      totalAmount: 'סה"כ סכום({currency})',
      totalDiscount: 'סה"כ הנחה({currency})',
      totalPayed: 'סה"כ שולם({currency})',
      actualCurrencyChange: 'עודף - {currency}',
            },

            creditSelectModal: {
              title: 'בחירת החזר'
            },

            weights: {
              kg: 'קג`',
              g: 'גרם',
            },

            mizdamenOrHakafaDialog: {
              welcome: 'שלום, ברוכים הבאים',
              selectCustomerType: 'יש לבחור סוג לקוח',
              cannotSaleCustomerIsLocked: "לא ניתן למכור ללקוח נעול",
              existingCustomer: 'לקוח קיים',
              occasionalCustomer: 'לקוח מזדמן',
              occasionalCustomerOnly: 'מזדמן',
              enterClientDetails : 'הזן מספר לקוח',
              swipeCustomerCard: 'העבר כרטיס לקוח',
              happyToSeeYou: 'שמחים שחזרת :-)',
              pleaseSwipeCard: 'נא להעביר כרטיס לקוח'
            },
            clubMemberSelfServiceDialog:{
              welcome: 'שלום, ברוכים הבאים',
              isMember: 'האם אתם חברי מועדון?',
              memberClub: 'חבר מועדון',
              existingCustomer: 'לקוח קיים',
              occasionalCustomer: 'לקוח מזדמן',
              pleaseTypePhone:"אנא הזן מספר טלפון",
              pleaseTypeHakafaIdentifier: 'אנא הזן מזהה לקוח הקפה',
              hakafaIdenfifierPlaceHolder: "ת.ז/כרטיס/שם/טלפון",
              phone:"טלפון",
              areYouNotMemberClub:"אתם עדיין לא חברי מועדון? \nגשו לעמדה מאויישת לרישום",
              memberNotFound: "לא נמצא חבר מועדון עם מספר טלפון זה",
              hakafaNotFound: "לא נמצא מזהה לקוח הקפה עם מזהה זה",
            },
            creditCardDialog: {
              title: 'החזר בכרטיס אשראי',
              swipeCard: 'העבר כרטיס',
              manualInput: 'הקלדה ידנית',
              card: 'כרטיס',
              swipeCardOrManualInput: 'העבר כרטיס או הקלד מספר כרטיס',
              expDate: 'תוקף',
              clearFields: 'נקה שדות',
            },

            priorityHakafaReportDialog: {
              title: 'דוח עסקאות',
              subtitle: 'לקוח',
              dataNotFound: 'לא נמצאו עסקאות',
              amount: 'יתרה נוכחית',
              maxObligo: 'תקרת אשראי',
              obligo: 'תקרת אשראי נוכחית',
              cannotSearchPriorityHakafaCustomerFieldIsHakafaCustomerIsMiss: 'לא ניתן לחפש לקוחות הקפה בפריוריטי חסרה הגדרה שם שדה לקוח הקפה בפריוריטי',
            },
            hakafaReportDialog: {
              title: "דוח הקפה",
              headerTitle: "נא להזין תעודת זהות של הלקוח וטווח תאריכים",
              fromDate: "מתאריך",
              toDate: "עד תאריך",
              actionDt: "תאריך תנועה",
              invoiceNumber: "מספר פתקית",
              amount: "סכום",
              balance: "יתרה",
              actioType: "פירוט",
              taz: "ת.ז.",
              customerNumber: "מספר לקוח",
              hakafaTotalAmount: 'סה"כ יתרת לקוח:'
            },

            priorityImportOrderDialog: {
              title: 'משיכת הזמנה מפריוריטי',
              labelInput: 'הקלד מספר הזמנה:',
              importOrder: 'משוך הזמנה',
              importMessage: 'מייבא הזמנה מפריוריטי...',
              errorHasItemsOnCurrentSale: `לא ניתן למשוך הזמנה שקיימים פריטים במכירה נוכחית
              יש לנקות מכירה לפני משיכת הזמנה מפריוריטי`,
      errorNotFoundItemsOnOrder: `הזמנה {order} לא נמצא נתונים`,
      errorNotFoundItemOnPos: `פריט {item} לא נמצא בקופה`,
              generalError: 'שגיאה כללית במשיכת הזמנה מפריוריטי',
      errorCannotPayOrderOnStatus: `לא ניתן לשלם על הזמנה בסטטוס {status}`,
              errorCannotAddItemDiscount: `שגיאה בהחלת הנחת לפריט`,
            },

            creditInvoiceReplaceItem: {
              cannotReplaceSaleReceipt: 'לא ניתן לבצע החלפה לקבלה',
              alreadyHasOpenedReplacementSale: 'קיימת מכירת החלפה פתוחה, אנא בצע איפוס למכירה הנוכחית על מנת לבצע מכירת החלפה חדשה',
              cantReplaceMinusSale: 'לא ניתן לבצע החלפה על מכירה שמכילה פריטים במינוס',
              cantReplaceWhenThereAreNoItemsSelected: "לא ניתן לבצע החלפה אם לא נבחרו פריטים",
              managerAprrovalRequired: 'נדרש אישור מנהל'
            },

            hakafaCustomerDialog: {
              title: 'מכירה בהקפה',
              errorRequestCustomersPriority: 'שגיאת תקשורת פריורטי',
              isParentHakafaCustomer: 'האם לקוח מרכז?',
              parentHakafaCustomer: 'לקוח מרכז',
              noParentHakafaCustomer: 'לא נמצא לקוחות מרכזים',
              errorCannotAddNewCustomerWhenPosOffline: 'לא ניתן להוסיף לקוח הקפה שהקופה בנתק',
              errorCannotEditCustomerWhenPosOffline: 'לא ניתן לערוך לקוח הקפה שהקופה בנתק',
              errorCannotSetParentCustomerToParentCustomer: "לא ניתן להגדיר לקוח מרכז ללקוח מרכז",
              messageImportParentHakafaCustomers: 'מייבא לקוחות מרכזים...',
              errorImportParentHakafaCustomers: 'שגיאה ביבוא לקוחות מרכזים',
              addNewCustomer: 'הוספת לקוח חדש',
              search: 'חיפוש',
              customerSearch: "חיפוש לקוח",
              showAllCustomers: "הצג את כל הלקוחות",
              advancedSearch: 'חיפוש מתקדם',
              existCustomer: "לקוח קיים",
              allCustomers: 'כל הלקוחות',
              active: 'פעילים',
              locked: 'נעולים',
              group: 'קבוצות',
              noGroups: "לא נמצאו קבוצות",
              customerNumber: "מספר לקוח",
              tazChetpey: 'תז/ח.פ',
              name: "שם",
              phone: "טלפון",
              dateOfBirth: "תאריך לידה",
              balance: "יתרה",
              obligo: "אובליגו",
              discountPercent: "אחוז הנחה",
              customerDiscountPercent: "אחוז הנחה ללקוח",
              actions: "פעולות",
              selectCustomer: 'בחר לקוח',
              showCustomer: "הצג לקוח",
              previousTransactions: 'עסקאות קודמות',
              report: "דוח",
              transactionsReport: "דוח עסקאות",
              merakezInvoice: "חשבונית מרכזת",
              noCustomersFound: "לא נמצאו לקוחות",
              customerNumberCard: "מספר לקוח/כרטיס",
              customerNumberPlaceholder: "ניתן לרשום טלפון/תז במידה ואין כרטיס",
              firstName: 'שם פרטי',
              lastName: 'שם משפחה',
              isTamashCustomer: "לקוח עובד עם תעודות משלוח",
              isLocked: "לקוח נעול - לא ניתן יהיה למכור ללקוח זה בקופה",
              addCustomer: 'הוסף לקוח',
              save: "שמור",
              searchPlaceholder: "לפי ת.ז/כרטיס/שם/טלפון",
              edit: 'עריכה',
              offlineError: 'מכירה ללקוחות הקפה מחייבת חיבור של הקופה לאינטרנט',
              savingCustomer: 'שומר לקוח',
              searchingCustomer: 'מחפש לקוח - אנא המתן',
              checkIfCustomerExists: 'בודק אם לקוח כבר קיים - אנא המתן',
              pageInfo: "עמוד {page} מתוך {pages}",
              comments: "הערות",
              customerComments: 'הערות לקוח',
              errors: {
                negativeCustomerNumber: "מספר לקוח לא יכול להיות קטן מאפס",
                tazRequired: "חובה להזין ת.ז/ח.פ",
                tazLength: 'ת.ז לא יכול להיות ארוך יותר מ-9 ספרות',
                firstNameRequired: "חובה להזין שם פרטי",
                phoneNumberRequired: "חובה להזין טלפון",
                discountPercent: 'אחוז הנחה ללקוח חייב להיות מספרי',
                obligo: 'אובליגו חייב להיות מספרי',
                customerNumberExists: "קיים כבר לקוח עם אותו מספר לקוח ות.ז",
                generalError: 'שגיאה כללית',
                customerNotFound: 'לקוח לא נמצא',
              },
              addingCustomerMessage: "מוסיף לקוח הקפה",
              merakez: "לקוח מרכז"
            },

            hakafaMerakezInvoiceDialog: {
              title: "חשבונית מרכזת",
              search: "חפש",
              cancel: "ביטול",
              newInvoice: "חשבונית חדשה",
              latestInvoices: "חשבוניות אחרונות",
              newView: {
                title: "מסמכים פתוחים ללקוח",
                fromDate: "מתאריך",
                toDate: "עד תאריך",
                invoiceDate: "תאריך ערך חשבונית",
                select: "בחר",
                date: "תאריך",
                invoiceNumber: "מספר מסמך",
                amount: "סכום",
                leftToPay: "יתרה לתשלום",
                invoiceType: "סוג מסמך",
                noInvoices: "אין מסמכים פתוחים",
                createInvoice: "הפק חשבונית מרכזת",
                selectAll: "בחר הכול",
                clearSelection: "נקה בחירה",
                customer: 'לקוח',
                totalToPay: 'סה"כ לתשלום'
              },
              indexView: {
                title: "חשבוניות מרכזות אחרונות",
                merakezInvoiceNumber: "מספר חשבונית מרכזת",
                invoiceNumber: "מספר חשבונית",
                date: "תאריך",
                amount: "סכום",
                details: "פירוט",
                pageInfo: "עמוד {page} מתוך {pages}",
                fromDate: "מתאריך",
                toDate: "עד תאריך",
                customer: 'לקוח'
              },
              showView: {
                title: "חשבונית מרכזת מספר {invoiceNumber}",
                docNumber: "מספר חשבונית",
                rowNumber: 'מספר שורה',
                invoiceType: "סוג מסמך",
                customer: "לקוח",
                invoiceDate: "תאריך חשבונית",
                date: "תאריך",
                amount: "סכום",
                leftToPay: "לתשלום",
                owsVat: 'סה"כ חייב במע"מ',
                vat: 'מע"מ',
                printSource: "הדפס מקור",
                printCopy: "הדפס העתק",
                back: "חזרה",
                taxConfirmationNumberShort: "הקצאה מספר",
                taxConfirmationNumberFull: "מספר הקצאה מלא",
                transmitToTaxAuthority: "שידור לרשות המיסים"
              },
              messages: {
                searchingInvoice: 'מחפש חשבונית',
                noCustomerInvoices: 'אין חשבוניות מרכזות ללקוח',
                noInvoiceFound: 'לא נמצאה חשבונית מרכזת',
                noInvoicesSelected: "לא נבחרו פריטים",
                printing: "מדפיס",
                invoiceDateErrorHeader: 'תאריך ערך שגוי',
                invoiceDateCannotBeGreater: 'תאריך החשבונית לא יכול להיות גדול מהיום',
                invoiceDateCannotBeLesser: 'תאריך החשבונית אינו יכול להיות קטן מתאריך חשבונית מרכזת אחרונה שהוא',
                offlineMode: "לא ניתן ליצור חשבוניות מרכזות כשהקופה בנתק",
                loadingInvoices: "טוען חשבוניות",
                savingMerakezInvoice: "שומר חשבונית מרכזת...",
                merakezetCreatedSuccess: 'חשבונית נוצרה בהצלחה',
                searching: "מחפש",
                noItemsSelected: "לא נבחרו פריטים",
                amountIsHigherThanLeftToPay: "סכום שהוזן גבוה מיתרה לתשלום",
                amountIsHigherThanLeftToRefund: "סכום שהוזן גבוה מיתרה לזיכוי",
                amountCannotBeNegative: "סכום שהוזן לא יכול להיות שלילי",
                amountCannotBePositive: "סכום שהוזן לא יכול להיות חיובי",
              }
            },

            customerSalesReportDialog: {
              titleHakafaCustomers: 'דוח מכירות לקוחות הקפה',
              titleCustomers: 'דוח מכירות מועדון לקוחות',
              noCustomers: 'לא נמצאו לקוחות',
              noFoundSales: 'לא נמצאו מכירות לתאריכים שנבחרו',
              bonus: 'עמלה',
              loadingCustomers: 'טוען לקוחות...',
              loadingReport: 'טוען דוח...',
              printReport: 'הדפס דוח',
              shoeReport: 'הצג דוח',
            },

            showCustomerSalesReportDialog: {
              store: 'חנות',
              fromDate: 'מתאריך',
              toDate: 'עד תאריך',
              bonusPercent: 'אחוז עמלה',
              invoiceNumber: 'מספר חשבונית',
              date: 'תאריך',
              time: 'שעה',
              customer: 'לקוח',
              quantity: 'כמות פריטים',
              invoiceType: 'מסמך',
              total: 'סה"כ חשבונית',
              noData: 'לא נמצא נתונים'
            },

            customerPointsDialog: {
              title: 'חבר מועדון - מימוש נקודות',
              remainingPoints: 'יתרת נקודות ברשותך',
              saleUsablePoints: 'נקודות לשימוש בחשבון זה',
              points: 'נקודות',
              total: 'סכום',
              fillValidNumber: 'אנא מלא מספר נקודות תקני',
              continueWithoutPoint: 'המשך ללא נקודות',
              minPointsToUse: 'מינימום נקודות למימוש',
              multiplierPointsUse: 'מימוש בכפולות של',
            },

            sendInvoiceSmsDialog: {
              title: "שלח חשבונית ב- SMS",
              phone: "מספר טלפון",
              send: "שלח SMS",
              invalidPhone: "מספר טלפון לא תקין",
              mustBeOnline: "לא ניתן לשלוח SMS כשהקופה בנתק",
              saleNotSelected: "לא נבחרה חשבונית",
              saleNotSychronized: "לא ניתן לשלוח חשבונית למכירה בנתק",
              notAllowed: "קופה זו אינה מורשית לשלוח SMS-ים",
              requestFailed: 'שליחת הבקשה נכשלה. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
              requestServerMessage: 'שולח בקשה לשרת, אנא המתן...',
              smsInvoiceButton: "שלח ב-SMS",
      lessThenXMessagesLeft: "נותרו {quota} הודעות SMS, אנא פנה לתמיכה כדי לרכוש הודעות נוספות.\n במידה ולא יישארו הודעות במכסה, ההודעות לא יישלחו",
              noMessagesLeft: "לא נותרו הודעות SMS לשליחה, נא פנה לתמיכה כדי לרכוש הודעות נוספות",
              printOnly: 'הדפסה בלבד',
              smsOnly: 'SMS בלבד',
              smsAndPrint: 'SMS והדפסה',
              doNotSendSms: 'ללא שליחת SMS',
            },

            blockItemPaymentMethod: {
              errorTitle: 'חסימת אמצעי תשלום',
              smartVouchers: { tenbis: 'תן ביס', cibus: 'פלאקסי', banx: 'banx', goodi: 'גודי', yaad: 'תשלום אינטרנטי', valu: 'כרטיס נטען' },
              hakafa: 'הקפה',
              blockItem: 'פריט חסום',
              blockItems: 'פריטים חסומים',
              errorAfterBlockItems: 'במכירה לתשלום באמצעי תשלום',
      errorBlockPaymentOnSale: 'לא ניתן להוסיף פריט {itemDescription} כשיש במכירה תשלום ב{paymentName} שחסום לתשלום עם הפריט',
      voucher: 'שובר {voucherName}',
              credit: "אשראי",
              cash: "מזומן",
              vouchers: "תווים",
              creditVoucher: "שובר זיכוי",
              check: "צ'ק",
              item:"הפריט",
              items:"הפריטים",
              blockedToItems:'למכירה לאמצעי התשלום הבאים:',
              isBlocked:"חסום",
              areBlocked:"חסומים",
            },
            itemWithGroupsDialog: {
              employeeCannotEditItemWithBonPrint: 'לעובד אין את הרשאות לערוך פריט שיצא לו בון',
              back: 'הקודם',
              next: 'הבא',
              addItem: 'הוספת מנה',
              cancel: 'ביטול',
              confirm: 'אישור',
              deleteRow: 'מחק שורה',
              editItem: 'עריכת פריט',
              groupInfoGroupName: 'שם קבוצה: {groupName}, כמות פריטים שנבחרו מקבוצה זו: {numberOfItemsSelectedInCurrentGroup}',
              groupInfoMinimumItems: 'מינימום פריטים לבחירה: {minItemsToChoose}',
              groupInfoMaximumItems: 'מקסימום פריטים לבחירה: {maxItemsToChoose}',
              groupInfoFreeItems: 'כמות פריטים אשר ניתן לבחור בחינם: {numberOfFreeItems}',
              groupInfoFreeItemsNew: 'עד {numberOfFreeItems} פריטים ללא תוספת תשלום',
              minimumItemsValidationMessage: "יש לבחור לפחות {minItemsToChoose} פריטים בקבוצה '{groupName}' לפני שניתן להמשיך",
              cannotAddItemMessage: "לא ניתן להוסיף פריט, יש לבחור לפחות {minItemsToChoose} פריטים מקבוצה '{groupName}'.",
              cancelMessage: "האם לבטל את הוספת הפריט ולחזור למסך הראשי?",
              loading: 'טוען...',
              maximuItemsSelectedMessage: 'לא ניתן לבחור עוד פריטים בקבוצה זו - מקסימום פריטים נבחרו',
              maximuItemsSelectedMessageShort: 'לא ניתן לבחור עוד פריטים בקבוצה זו',
              groupTitle: "בחירת {groupName} לפריט: {itemDescription}",
              chooseAddon: "בחר תוספות עבור {itemDescription}",
              selfServiceGroupTitle: "נבחרו {itemsSelectedQuantity} מתוך {quantityItemsToSelected}",
              dalpakTableViewMobileItemsGroupTitle: "בחרתם {itemsSelectedQuantity} מתוך {quantityItemsToSelected}",
              continue: 'המשך',
              minimalChoiceNotComitted: 'יש לבחור לפחות {itemText}',
              oneItem: 'פריט אחד',
              xItems: '{count} פריטים',
              addition: 'הוספה',
              notes: 'הערות',
              add: 'הוסף מנה',
            },
            preparationInstructionsDialog: {
              next: "הבא",
              cancel: "ביטול"
            },
            selectDalpaksDialog: {
              title: 'בחר דלפק',
              areaTitle: 'בחר אזור',
              addNewDalpak: 'הוספת דלפק',
              addNewDalpakArea: 'הוספת אזור',
              noDalpaks: 'לא נמצאו דלפקים',
              actions: 'פעולות דלפק',
              select: 'פתח',
              salesperson: 'מוכר',
              openedHour: 'שעת פתיחה',
              refresh: 'רענן',
              noDalpaksFound: 'שים לב! לא נמצאו דלפקים. אנא פנה לתמיכה',
              lockedDalpakActions: 'לא ניתן לבצע פעולות על דלפק נעול',
              clickDalpakToSelect: 'לחץ על הדלפק כדי להיכנס אליו',
              clickDalpakAreaToSelect: 'לחץ על אזור כדי להיכנס אליו',
              forDalpakActions: 'בשביל פעולות דלפק',
              forDalpakAreaActions: 'בשביל פעולות אזור',
              customer: 'לקוח',
              area: 'אזור',
              table: 'שולחן',
              waiter: 'מלצר',
              avgPerDiner: 'ממוצע לסועד',
              changeWaiter: 'החלפת מלצר',
              moveTable: 'העברת שולחן',
              printSale: 'הדפסת חשבון',
              minutesAgoShort: 'ד\'',
              selectTable: 'בחירת שולחן',
              openDrawer: 'פתיחת מגירה',
              totalTable: 'סה\"כ שולחן',
              enterTable: 'כניסה לשולחן',
              editTables: 'עריכת שולחנות',
              changeArea: 'שינוי אזור',
              openingHour: 'שעת פתיחה',
              customerName: 'לקוח',
              status: 'סטטוס',
              delete: 'מחיקה',
              cancelChangesTitle: 'יציאה ללא שמירה',
              cancelChangesContent: 'קיימים שינויים שלא נשמרו - האם לצאת ממסך עריכת השולחנות ללא שמירה?',
              addTable: 'הוספת שולחן',
              deleteTablePrompt: 'האם אתה בטוח שברצונך למחוק את שולחן זה?',
              cantDeleteTableWithSale: 'לא ניתן למחוק שולחן שיש עליו מכירה פעילה',
              changeToArea: 'שינוי לאזור',
              addArea: 'הוספת אזור',
              editArea: 'עריכת אזור',
              pleaseSelectArea: 'אנא בחר אזור',
              deliveryManagement: "ניהול משלוחים",
              assignCouriers: "ציוות שליחים",
              closeDeliveries: "סגירת הזמנות",
            },
            areaAttributesDialog: {
              newAreaTitle: 'הוספת אזור',
              editAreaTitle: 'עריכת אזור',
              areaName: 'שם אזור',
              sortOrder: 'מיקום (1 - ראשון, 2 - שני וכן הלאה)',
              areaNameIsRequiredField: 'שם אזור הוא שדה חובה',
              areaNameAlreadyExists: 'שם אזור כבר קיים',
              sortOrderIsRequiredField: 'מיקום אזור הוא שדה חובה',
              deleteArea: 'מחיקת אזור',
            },
            tableAttributesDialog: {
              newTableTitle: 'הוספת שולחן',
              editTableTitle: 'עריכת שולחן',
              tableName: 'שם שולחן',
              tableDiscount: 'הנחה קבועה (באחוזים)',
              tableNameEmpty: 'שם שולחן הוא שדה חובה',
              tableDiscountNotValid: 'הנחה קבועה חייב להיות מספר בין 0 ל- 100',
              dalpakAreaMandetory: 'חובה לבחור אזור לשולחן',
              selectArea: 'נא לבחור איזור',
              tableNameAlreadyExists: 'קיים שולחן בשם זה',
            },
            deleteAreaDialog: {
              title: 'מחיקת אזור',
              content: 'קיימים שולחנות באזור זה - יש לבחור אזור אליו יועברו השולחנות',
            },
            searchDalpakDialog: {
              title: 'חיפוש שולחן',
              inputToSearch: 'הקלידו לחיפוש',
              inputToSearchPlaceHolder: 'חיפוש לפי שם שם לקוח, שולחן או מלצר',
            },
            filterDalpakDialog: {
              title: 'סינון שולחנות',
              waiter: 'מלצר',
              tableStatus: 'סטטוס שולחן',
            },
            moveTableComponent: {
              dialogTitle: 'החלפת שולחן',
              from: 'משולחן',
              to: 'לשולחן',
              partialMovement: 'העברה חלקית',
              partial: 'חלקית',
              locateTable: 'איתור שולחן',
              tablesMandatory: 'חובה לבחור שולחן שממנו מעבירים ושולחן שאליו מעבירים',
              printBon: 'הדפסת בון העברה',
            },
            changeWaiterComponent: {
              dialogTitle: 'החלפת מלצר',
              table: 'שולחן',
              waiter: 'מלצר',
              locateWaiter: 'בחירת מלצר',
              waiterMandatory: 'חובה לבחור מלצר להעברה',
            },
            positiveInput: {
              selection: 'בחירת {label}',
              selectedOptionNotInOptions: 'הבחירה לא קיימת במאגר',
            },
            selectDalpakAreaModal: {
              title: 'בחר איזור להעביר אליו את הדלפקים',
              noDalpakAreaSelectedMessage: 'לא נבחר אזור'
            },
            createDalpakDialog: {
              title: 'יצירת דלפק חדש',
              areaError: 'נא לבחור אזור לדלפק.'
            },
            createDalpakAreaDialog: {
              title: 'יצירת אזור חדש',
              firstAreaMessage: "אזור ראשון לחנות - כל הדלפקים ישוייכו אליו אוטומטית"
            },
            dalpakActionsDialog: {
              printMoveBon: 'הדפס בון העברה',
              clearSale: 'מחיקת חשבון',
              clearingSale: 'מוחק חשבון...',
              confirmSaleDeleteion: 'שים לב! פעולה זו תמחק את המכירה ואת כל המידע שיש עליה ללא יכולת שחזור. האם אתה בטוח שברצונך לבצע פעולה זו?',
              editDalpak: 'עריכת דלפק',
              title: 'פעולות דלפק',
              update: 'שמור דלפק',
              delete: 'מחק דלפק',
              moveDalpak: 'העבר חשבון',
              sourceDalpak: 'מדלפק',
              targetDalpak: 'לדלפק',
              move: 'העבר',
              printInvoice: 'הדפסת חשבון',
              noFreeDalpaks: 'אין דלפקים פנויים',
              modbusActions: 'פעולות בקר חכם',
              turnModbusOn: 'הדלק אור',
              turnModbusOff: 'כבה אור',
              edit: 'עריכה',
              editDalpakPermissionError: 'אין לך הרשאות לבצע שינויים בדלפק'
            },
            dalpakAreaActionsDialog: {
              title: 'פעולות אזור',
              update: 'שמור אזור',
              delete: 'מחק אזור',
              editDalpak: 'עריכת אזור',
              edit: 'עריכה',
              editDalpakAreaPermissionError: 'אין לך הרשאות לבצע שינויים באיזור'
            },
            dalpaks: {
              errorClosedSaleDalpak: 'שים לב! מכירה זו נסגרה אך ארעה שגיאה בניקוי המכירה. בוצע ניקוי למכירה. אנא פנה לתמיכה ע"מ שנוכל לטפל בבעייה בסגירת המכירה',
              saleWithPayments: 'שים לב! למכירה זו קיים תשלום מקופה זו, ולכן דלפק זה יישאר נעול ולא יהיה ניתן לגשת אליו מקופות אחרות.',
              cantReleaseOrderInDalpak: 'לא ניתן לשחרר הזמנה כאשר יש דלפק פתוח, אנא צא מהדלפק ולאחר מכן שחרר את ההזמנה',
              cantAddItemToDeliveryDalpak: 'לא ניתן לבצע מכירות בדלפק פיקאפ, יש לצאת מהדלפק הנוכחי ולבחור דלפק תקין',
              mustBeOnline: 'יש לוודא שהקופה מחוברת לאינטרנט.',
              loadingDalpaks: 'טוען דלפקים',
              errorLoadingDalpaks: 'ארעה שגיאה בטעינת הדלפקים, אנא פנה לתמיכה',
              errorConnectingToDalpakServer: 'ארעה שגיאה בהתחברות לשרת הסנכרון, אנא וודא שקיים חיבור לאינטרנט, שהסטטוס תקשורת במצב מחובר, ושהקופה ראשית מחוברת ועובדת תקין. במידה והבעיה חוזרת כאשר יש חיבור לאינטרנט - אנא פנה לתמיכה',
              order: 'מיקום',
              orderSummary: 'סיכום הזמנה',
              totalToPay: 'סה"כ לתשלום',
              name: 'שם',
              color: 'צבע',
              area: 'אזור',
              dalpakNameMissing: 'חובה לבחור שם ליצירת דלפק',
              invalidOrder: 'שדה מיקום חייב להיות מספר שלם',
              invalidFixedDiscount: 'שדה הנחה קבועה חייב להיות מספר בין 0 ל-100',
              creatingDalpak: 'יוצר דלפק, אנא המתן...',
              updatingDalpak: 'מעדכן דלפק, אנא המתן...',
              deletionDalpak: 'מוחק דלפק, אנא המתן...',
              enteringDalpak: 'נכנס דלפק, אנא המתן...',
              lockingDalpak: 'נועל דלפק, אנא המתן...',
              table: 'שולחן',
              releasingDalpak: 'משחרר דלפק, אנא המתן...',
              dalpakIsLocked: '{dalpak} זה נעול ע"י הקופה {lockedBy}',
              namedDalpakIsLocked: 'ה{dalpak} {name} נעול ע"י הקופה {lockedBy}',
              updatingTables: 'מעדכן שולחנות, אנא המתן...',
              pleaseSelectDalpak: 'אנא בחר דלפק',
              dalpakDoesntHasSale: 'לא קיימת מכירה לדלפק',
              activeDalpakPaymentCannotBeTransfered:'לא ניתן להעביר שולחן שבוצע בו תשלום חלקי',
              targetDalpakHasSale: 'קיימת מכירה ל{dalpak} היעד',
              generalError: 'אירעה שגיאה, אנא פנה לתמיכה',
              movingSale: 'מעביר מכירה',
              printing: 'מדפיס...',
              savingDalpaks: 'שומר דלפקים, אנא המתן...',
              restartSaleFailed: 'ארעה שגיאה באיפוס המכירה. אנא פנה לתמיכה',
              communication: 'סטטוס תקשורת',
              serverStatusDisconnected: 'מנותק',
              serverStatusAuth: 'מבצע התחברות...',
              serverStatusConnected: 'מחובר',
              socketBonNotConfigured: 'לא ניתן להדפיס בונים דרך קופה ראשית, דלפקי רשת אינם מוגדים',
              mainPosConnectionProblem: 'בעיית חיבור לקופה ראשית - אנא ודא שיש תקשורת לקופה ראשית',
              moveToOffline: 'עבור למצב אופליין',
              moveToOnline: 'עבור למצב אונליין',
              continueAsOffline: 'המשך לעבוד באופליין',
              reconnectingToSync: 'קיים חיבור לשרת הדלפקים. האם ברצונך לעבור לעבודה במצב אונליין ולבצע סנכרון של המידע?',
              actionCanceled: 'הפעולה בוטלה',
              minusOneSaleExists: 'קיימת מכירה בקופה. במידה וזה קופה חדשה, יש לבצע ניקוי מכירה מינוס 1. במידה וזה קופה שאינה חדשה ובוצע בה מכירות, יש לפנות לתמיכה בשביל לא לאבד מידע',
              deliveryOnNotDeliveryPos: 'קיימת מכירת משלוח על הדלפק.\n ניתן לגשת למכירות משלוח רק מקופה שתומכת במשלוחים',
              noDalpakAreaOptions: "אין אזורים לבחירה",
              fixedDiscount: "הנחה קבועה (%)",
              errorDiscountNotFound: 'לא נמצאה הנחה בקופה. יש להגדיר הנחת חשבון עם קוד הנחה 2 במשרד האחורי',
              errorNoNewPromotions: 'ההנחת חשבון לדלפק עובדת אך ורק במערכת מצבעים החדשה. נא להפעיל מערכת מבצעים חדשה על מנת לקבל הנחה אוטמטית על דלפק',
              statuses: {
                '0': 'פנויים',
                '1': 'פעילים',
                '2': 'מסיימים',
                '3': 'התראות',
              },
              unableToTransferItems: `שימו לב - במכירה זו קיימים פריטים שמחייבים את העברת המכירה כולה. לא ניתן לבצע העברת שולחן רק לחלק מהפריטים במכירה זו.
              האם אתם רוצים להעביר את כל המכירה לשולחן אחר?`,
              someItemsCantBeMoved : "חלק מן הפריטים לא ניתנים להעברה, ניתן להעביר את המכירה כולה או  להסיר את הפריטים האלו",
              toMoveSale: "העבר מכירה"
            },
            dalpakAreas: {
              loadingDalpakAreas: 'טוען אזור דלפקים',
              errorLoadingDalpakAreas: 'ארעה שגיאה בטעינת אזורים, אנא פנה לתמיכה',
              dalpakAreaNameMissing: 'חובה לבחור שם ליצירת אזור',
              creatingDalpakArea: 'יוצר אזור, אנא המתן...',
              deletingDalpakArea: 'מוחק אזור, אנא המתן...',
              updatingDalpakArea: 'מעדכן אזור, אנא המתן...',
              savingDalpakAreas: 'שומר אזור דלפקים, אנא המתן...',
              order: 'מיקום',
              name: 'שם',
              color: 'צבע',
              transferingDalpaks: "מעביר דלפקים...",
              noDalpaks: "אין דלפקים באיזור זה",
              dalpaks: "דלפקים",
              transferDalpaks:"שיוך דלפקים",
              noDalpakOptions: "אין דלפקים לבחירה"
            },
            dalpakOrders: {
              cantSaveOrder: 'ארעה שגיאה בשמירת ההזמנה. פירוט: ',
              updatingOrder: 'מעדכן הזמנה, אנא המתן...',
              failedToDeleteOrders: 'ארעה שגיאה במחיקת ההזמנות בשרת הסנכרון. אנא וודא שיש חיבור לשרת הסנכרון ונסה שנית'
            },
            syncServer: {
              errors: {
                serverError: 'ארעה שגיאה בשרת הסנכרון. אנא פנה לתמיכה',
                outOfStockItemsCannotUpdate: 'נא לבדוק חיבור לאינטרנט ולנסות שוב. אם הבעיה חוזרת יש ליצור קשר עם התמיכה',

              }
            },

            addItemDialog: {
              title: 'הוספת פריט',
              itemCode: 'ברקוד',
              name: 'שם פריט',
              price: 'מחיר',
              insertValidPrice: 'אנא הזן מחיר תקין',
              fillAllFields: 'אנא מלא את השדות שם פריט, ברקוד ומחיר',
              addingItemPleaseWait: 'מוסיף פריט, אנא המתן...',
              noGenericItemFound: 'לא נמצא פריט כללי עבור ביצוע הפעולה! יש להגדיר פריט עם קוד {item_code} ב- BO',
              itemNotFoundCreate: 'הפריט {code} לא קיים. האם ברצונך ליצור אותו?',
              save: 'שמור',
              saveAndAddToSale: 'שמור והוסף למכירה',
              invalidNameChar: 'שם פריט לא יכול להכיל תווים מיוחדים',
              generalError: "שגיאה כללית - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              hasWeight: 'פריט שקיל',
              noVat: 'פריט ללא מע"מ',
              primaryCategory: 'קטגורייה',
              noCategory: 'ללא קטגוריה',
            },


            tenbis: {
              generalErrorComunication: 'קיימת בעית תקשורת, אנא נסה שוב. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה',
              noUserPasswordConfigured: 'חלק מההגדרות עבור השירות חסרות, אנא פנה לתמיכה עבור מילוי ההגדרות הנדרשות',
              courierTip: 'טיפ לשליח',
             },
            mishloha: {
              generalErrorComunication: 'קיימת בעית תקשורת, אנא נסה שוב. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה',
              generalError: "שגיאה כללית - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              discount: "הנחה",
              paymentName: "אתר משלוחה",
              creditCardPaymentName: "אשראי ישיר משלוחה",
             },
            splitSalePaymentDialog: {
              title: 'פיצול חשבון',
              amountReminding: 'יתרה לתשלום',
              amountToPay: 'סכום לתשלום',
              invalidAmount: 'מספר לא תקין, אנא הזן מספר גדול מ-0 וקטן או שווה להיתרה לתשלום',
              selectedItemsAmount: 'סכום הפריטים שנבחרו'
            },

            selectDinersNumberDialog: {
              title: 'מספר סועדים',
              numberOfDiners: 'מספר סועדים',
              invalidNumberOfDiners: 'מספר לא תקין, אנא הזן מספר גדול מ-0',
              aboveNineDines: 'שים לב! כמות הסועדים שנבחרה גדולה מ-9. האם ברצונך להמשיך?'
            },
            selectIItemQuantityDialog: {
              title: 'כמות לפריט',
              errors:{
                belowTwoItems: 'לא ניתן לשנות לכמות קטנה מ-2',
                above99Items:"לא ניתן לעדכן כמות לגדולה מ-99"
              },
            },

            splitSalePayment: {
      partialItemOrder: 'תשלום חלקי עבור הזמנה {orderNumber}',
      partialItemFor: 'עבור תשלום חלקי שבוצע בחשבונית {invoiceNumber}',
      noGenericItemFound: 'לא נמצא פריט כללי עבור ביצוע הפעולה! ע"מ שנוכל לפצל את החשבון, יש להגדיר פריט עם קוד {item_code}',
              splittedSaleExists: 'מכירה מפוצלת כבר קיימת, אנא פנה לתמיכה',
              cantRestartSale: 'לא ניתן למחוק מכירה עם פיצול חשבון, האם ברצונך למחוק את הפריטים?',
              splittingSale: 'מפצל חשבון, אנא המתן...',
            },

            splitPaymentAmountDialog: {
              title: 'פיצול תשלום',
              splitPaymentQty: 'כמות סועדים',
              invalidAmount: 'מספר לא תקין, אנא הזן מספר גדול מ-0 וקטן או שווה להיתרה לתשלום',
            },

            checkPosProprietyDialog: {
              title: 'בדיקת תקינות קופה',
              message: `חובה לבצע בדיקת הגדרות ותקינות לקופה לפני שניתן למכור \n
              לחץ אישור לביצוע הבדיקה`,
              loadingMessage: 'מבצע בדיקות...',
              failedMessage: 'קופה לא תקינה יש לבצע מחיקה מלאה של נתונים וID ולהתחבר מחדש',
              passedMessage: 'בדיקת תקינות קופה עברה בהצלחה',
              generalError: 'שגיאה כללית בדיקת תקינות קופה. צור קשר עם התמיכה',
              updateDataMessage: 'יש לבצע עדכון מידע לפני בדיקת הקופה',
              logoutPos: 'התנתקות מהקופה',
              close: 'סגור חלון',
            },
            externalDeliveryOrders: {
              notItemsErrorTitle: "לא ניתן להפעיל בקופה ממשקי משלוחים או הזמנות",
              notItemsError: "<b>שים לב! לא ניתן להפעיל בקופה ממשקי משלוחים או הזמנות</b>\nנא לוודא כי קיימים הפריטים הבאים במערכת:",
              missingItems: {
                pickup: "פריט איסוף עצמי ({itemCode})",
                delivery: "פריט משלוחים ({itemCode})",
                generalItem: "פריט כללי להזמנות ({itemCode})",
                tenbisApiDiscountItem: "פריט תן-ביס/פיידאיט ({itemCode})"
              },
              failedDeliveriesMessage: "הזמנה {orderNumber} ממשק {interface} נכשלה, אנה פנה לתמיכה."
            },

            customerSaleItemLimit: {
              checkingCustomerItems: 'בודק קניות פריטים ללקוח, אנא המתן...',
      boughtMoreThenOnce: 'שים לב, לקוח {customer}, רכש את הפריט {item} ב {day} בשעה {hour}.\nהאם להמשיך בתהליך המכירה?',
      boughtMoreThenQty: 'שים לב, לקוח {customer} כבר רכש את הפריט {item} ב{timespan} האחרון {boughtQty} פעמים, אך הפריט מוגבל ל-{allowedQty} פעמים. האם להמשיך בתהליך המכירה?',
      wantsToByMoreThenAllowedQty: 'שים לב, קיימת הגבלה על קניית הפריט {item} - {allowedQty} ל{timespan}. הכמות הנוכחית הינה {currentQty}.\nהאם ברצונך להמשיך?',
            },

            timespans: {
              day: 'יום',
              week: 'שבוע',
              month: 'חודש',
            },

            // EMV TRanslitaion
            emvAshStatusCodes: {
              "000": `מאושר`,
              "001": `כרטיס חסום`,
              "002": `גנוב החרם כרטיס`,
              "003": `התקשר לחברת האשראי`,
              "004": `העסקה לא אושרה`,
              "005": `כרטיס מזוייף החרם`,
              "006": `דחה עסקה : cvc2/id שגוי`,
              "007": `דחה עסקה : cavv/ucaf שגוי`,
              "008": `דחה עסקה avs שגוי`,
              "009": `דחייה - נתק בתקשורת`,
              "010": `אישור חלקי`,
              "011": `דחה עסקה: חוסר בנקודות/כוכבים/מיילים/הטבה אחרת`,
              "012": `בכרטיס לא מורשה במסוף`,
              "013": `דחה בקשה - קוד יתרה שגוי`,
              "014": `דחייה - כרטיס לא משוייך לרשת`,
              "015": `דחה עסקה: הכרטיס אינו בתוקף`,
              "016": `דחייה - אין הרשאה לסוג מטבע`,
              "017": `דחייה - אין הרשאה לסוג אשראי בעסקה`,
              "026": `דחה עסקה - id שגוי`,
              "041": `ישנה חובת יציאה לשאילתא בגין תקרה בלבד לעסקה עם פרמטר j2`,
              "042": `ישנה חובת יציאה לשאילתא לא רק בגין תקרה, לעסקה עם פרמטר j2`,
              "051": `חסר קובץ ווקטור 1`,
              "052": `חסר קובץ ווקטור 4`,
              "053": `חסר קובץ ווקטור 6`,
              "055": `חסר קובץ ווקטור 11`,
              "056": `חסר קובץ ווקטור 12`,
              "057": `חסר קובץ ווקטור 15`,
              "058": `חסר קובץ ווקטור 18`,
              "059": `חסר קובץ ווקטור 31`,
              "060": `חסר קובץ ווקטור 34`,
              "061": `חסר קובץ ווקטור 41`,
              "062": `חסר קובץ ווקטור 44`,
              "063": `חסר קובץ ווקטור 64`,
              "064": `חסר קובץ ווקטור 80`,
              "065": `חסר קובץ ווקטור 81`,
              "066": `חסר קובץ ווקטור 82`,
              "067": `חסר קובץ ווקטור 83`,
              "068": `חסר קובץ ווקטור 90`,
              "069": `חסר קובץ ווקטור 91`,
              "070": `חסר קובץ ווקטור 92`,
              "071": `חסר קובץ ווקטור 93`,
              "073": `חסר קובץ PARAM_3_1`,
              "074": `חסר קובץ PARAM_3_2`,
              "075": `חסר קובץ PARAM_3_3`,
              "076": `חסר קובץ PARAM_3_4`,
              "077": `חסר קובץ PARAM_361`,
              "078": `חסר קובץ PARAM_363`,
              "079": `חסר קובץ PARAM_364`,
              "080": `חסר קובץ PARAM_61`,
              "081": `חסר קובץ PARAM_62`,
              "082": `חסר קובץ PARAM_63`,
              "083": `חסר קובץ CEIL_41`,
              "084": `חסר קובץ CEIL_42`,
              "085": `חסר קובץ CEIL_43`,
              "086": `חסר קובץ CEIL_44`,
              "087": `חסר קובץ DATA`,
              "088": `חסר קובץ JENR`,
              "089": `חבר קובץ Start`,
              "098": `ERROR_IN_NEG_FILE`,
              "099": `שגיאה כללית`,
              "101": `חסרה כניסה בוקטור 1`,
              "103": `חסרה כניסה בוקטור 4`,
              "104": `חסרה כניסה בוקטור 6`,
              "106": `חסרה כניסה בוקטור 11`,
              "107": `חסרה כניסה בוקטור 12`,
              "108": `חסרה כניסה בוקטור 15`,
              "110": `חסרה כניסה בוקטור 18`,
              "111": `חסרה כניסה בוקטור 31`,
              "112": `חסרה כניסה בוקטור 34`,
              "113": `חסרה כניסה בוקטור 41`,
              "114": `חסרה כניסה בוקטור 44`,
              "116": `חסרה כניסה בוקטור 64`,
              "117": `חסרה כניסה בוקטור 81`,
              "118": `חסרה כניסה בוקטור 82`,
              "119": `חסרה כניסה בוקטור 83`,
              "120": `חסרה כניסה בוקטור 90`,
              "121": `חסרה כניסה בוקטור 91`,
              "122": `חסרה כניסה בוקטור 92`,
              "123": `חסרה כניסה בוקטור 93`,
              "141": `חסרה כניסה מתאימה בקובץ פרמטרים 3.2`,
              "142": `חסרה כניסה מתאימה בקובץ פרמטרים 3.3`,
              "143": `חסרה כניסה בקובץ תחומי מועדון 3.6.1`,
              "144": `חסרה כניסה בקובץ תחומי מועדון 3.6.3`,
              "145": `חסרה כניסה בקובץ תחומי מועדון 3.6.4`,
              "146": `חסרה כניסה בקובץ תקרות לכרטיסי PL 4.1`,
              "147": `חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 0 4.2`,
              "148": `חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 1 4.3`,
              "149": `חסרה כניסה בקובץ תקרות לכרטיסי תייר 4.4`,
              "150": `חסרה כניסה בקובץ כרטיסים תקפים - ישראכרט`,
              "151": `חסרה כניסה בקובץ כרטיסים תקפים - כאל`,
              "152": `חסרה כניסה בקובץ כרטיסים תקפים - מנפיק עתידי`,
              "182": `שגיאה בערכי וקטור 4`,
              "183": `שגיאה בערכי וקטור 6/12`,
              "186": `שגיאה בערכי וקטור 18`,
              "187": `שגיאה בערכי וקטור 34`,
              "188": `שגיאה בערכי וקטור 64`,
              "190": `שגיאה בערכי וקטור 90`,
              "191": `נתונים לא תקינים בוקטור הרשאות מנפיק`,
              "192": `נתונים לא ולידים בסט הפרמטרים`,
              "193": `נתונים לא ולידים בקובץ פרמטרים ברמת מסוף`,
              "300": `אין הרשאה לסוג עסקה - הרשאת סולק`,
              "301": `אין הרשאה למטבע - הרשאת סולק`,
              "303": `אין הרשאת סולק לביצוע עסקה כאשר הכרטיס לא נוכח`,
              "304": `אין הרשאה לאשראי - הרשאת סולק`,
              "308": `אין הרשאה להצמדה - הרשאת סולק`,
              "309": `אין הרשאת סולק לאשראי במועד קבוע`,
              "310": `אין הרשאה להקלדת מספר אישור מראש`,
              "311": `אין הרשאה לבצע עסקאות לקוד שרות 587`,
              "312": `אין הרשאת סולק לאשראי דחוי`,
              "313": `אין הרשאת סולק להטבות`,
              "314": `אין הרשאת סולק למבצעים`,
              "315": `אין הרשאת סולק לקוד מבצע ספציפי`,
              "316": `אין הרשאת סולק לעסקת טעינה`,
              "317": `אין הרשאת סולק לטעינה / פריקה בקוד אמצעי התשלום בשילוב קוד מטבע`,
              "318": `אין הרשאת סולק למטבע בסוג אשראי זה`,
              "319": `אין הרשאת סולק לטיפ`,
              "341": `אין הרשאה לעסקה - הרשאת מנפיק`,
              "342": `אין הרשאה למטבע - הרשאת מנפיק`,
              "343": `אין הרשאת מנפיק לביצוע עסקה כאשר הכרטיס לא נוכח`,
              "344": `אין הרשאה לאשראי - הרשאת מנפיק`,
              "348": `"אין הרשאה לביצוע אישור בקשה יזומה ע"י קמעונאי""ין הרשאה "`,
              "349": `אין הרשאה מתאימה לביצוע בקשה לאישור ללא עסקה J5`,
              "350": `אין הרשאת מנפיק להטבות`,
              "351": `אין הרשאת מנפיק לאשראי דחוי`,
              "352": `אין הרשאת מנפיק לעסקת טעינה`,
              "353": `אין הרשאת מנפיק לטעינה / פריקה בקוד אמצעי התשלום`,
              "354": `אין הרשאת מנפיק למטבע בסוג אשראי זה`,
              "381": `אין הרשאה לבצע עסקת contactless מעל סכום מרבי`,
              "382": `במסוף המוגדר כשרות עצמי ניתן לבצע רק עסקאות בשירות עצמי`,
              "384": `מסוף מוגדר כרב - ספק /מוטב - חסר מספר ספק / מוטב`,
              "385": `במסוף המוגדר כמסוף סחר אלקטרוני חובה להעביר eci`,
              "401": `מספר התשלומים גדול מערך שדה מספר תשלומים מקסימלי`,
              "402": `מספר התשלומים קטן מערך שדה מספר תשלומים מינימלי`,
              "403": `סכום העסקה קטן מערך שדה סכום מינמלי לתשלום!`,
              "404": `לא הוזן שדה מספר תשלומים`,
              "405": `חסר נתון סכום תשלום ראשון / קבוע`,
              "406": `סה"כ סכום העסקה שונה מסכום תשלום ראשון + סכום תשלום קבוע * מספר תשלומים`,
              "408": `ערוץ 2 קצר מ 37- תווים`,
              "410": `דחיה מסיבת dcode`,
              "414": `בעסקה עם חיוב בתאריך קבוע הוכנס תאריך מאוחר משנה מבצוע העיסקה`,
              "415": `הוזנו נתונים לא תקינים`,
              "416": `תאריך תוקף לא במבנה תקין`,
              "417": `מספר מסוף/קופה אינו תואם למה שמוגדר ב-PinPad`,
              "418": `חסרים פרמטרים חיוניים`,
              "419": `שגיאה בהעברת מאפיין clientInputPan`,
              "420": `מספר כרטיס לא ולידי -במצב של הזנת ערוץ 2 בעסקה ללא כרטיס נוכח`,
              "421": `שגיאה כללית - נתונים לא ולידים`,
              "422": `שגיאה בבנית מסר ISO`,
              "424": `שדה לא נומרי`,
              "425": `רשומה כפולה`,
              "426": `הסכום הוגדל לאחר ביצוע בדיקות אשראית`,
              "428": `חסר קוד שרות בכרטיס`,
              "429": `כרטיס אינו תקף לפי קובץ כרטיסים תקפים`,
              "431": `שגיאה כללית`,
              "432": `אין הראשה להעברת כרטיס דרך קורא מגנטי`,
              "433": `חיוב להעביר ב - PinPad`,
              "434": `אסור להעביר כרטיס במכשיר ה- PinPad`,
              "435": `המכשיר לא מוגדר להעברת כרטיס מגנטי CTL`,
              "436": `המכשיר לא מוגדר להעברת כרטיס EMV CTL`,
              "439": `אין הרשאה לסוג אשראי לפי סוג עסקה`,
              "440": `כרטיס תייר אינו מורשה לסוג אשראי זה`,
              "441": `אין הרשאה לביצוע סוג עסקה - כרטיס קיים בוקטור 80`,
              "442": `אין לבצע in-Stand לאימות אישור לסולק זה`,
              "443": `לא ניתן לבצע עסקת ביטול - כרטיס לא נמצא בקובץ תנועות הקיים במסוף`,
              "445": `בכרטיס חיוב מיידי ניתן לבצע אשראי חיוב מיידי בלבד`,
              "447": `מספר כרטיס שגוי`,
              "448": `חיוב להקליד כתובת לקוח (מיקוד, מספר בית ועיר)`,
              "449": `חיוב להקליד מיקוד`,
              "450": `קוד מבצע מחוץ לתחום, צ"ל בתחום 1-12`,
              "451": `שגיאה במהלך בנית רשומת עסקה`,
              "452": `בעסקת טעינה/פריקה/בירור יתרה חיוב להזין שדה קוד אמצעי תשלום`,
              "453": `אין אפשרות לבטל עסקת פריקה 7.9.3`,
              "455": `לא ניתן לבצע עסקת חיוב מאולצת כאשר נדרשת בקשה לאישור (למעט תקרות)`,
              "456": `כרטיס נמצא בקובץ תנועות עם קוד תשובה 'החרם כרטיס'`,
              "457": `בכרטיס חיוב מיידי מותרת עסקת חיוב רגילה / זיכוי / ביטול`,
              "458": `קוד מועדון לא בתחום`,
              "472": `בעסקת חיוב עם מזומן חיוב להזין סכום במזומן`,
              "473": `בעסקת חיוב עם מזומן סכום המזומן צריך להיות קטן מסכום העסקה`,
              "474": `עסקת איתחול בהוראת קבע מחייבת פרמטר J5`,
              "475": `"עסקת ה"ק מחייבת אחד מהשדות: מספר תשלומים או סכום כולל""סקת ה"ק מחייבת אחד מהשדות: מספר תשלומים או סכו""קת ה"ק מחייבת אחד מהשדות: מספר תשלומים א""ת ה"ק מחייבת אחד מהשדות: מספר תשלומ"" ה"ק מחייבת אחד מהשדות: מספר תש""ה"ק מחייבת אחד מהשדות: מספר """ק מחייבת אחד מהשדות: מספר""ק מחייבת אחד מהשדות: מספר"`,
              "476": `עסקת תורן בהוראת קבע מחייבת שדה מספר תשלום`,
              "477": `עסקת תורן בהוראת קבע מחייבת מספר מזהה של עסקת איתחול`,
              "478": `עסקת תורן בהוראת קבע מחייבת מספר אישור של עסקת איתחול`,
              "479": `עסקת תורן בהוראת קבע מחייבת שדות תאריך וזמן עסקת איתחול`,
              "480": `חסר שדה מאשר עסקת מקור`,
              "481": `חסר שדה מספר יחידות כאשר העסקה מתבצעת בקוד אמצעי תשלום השונה ממטבע`,
              "482": `בכרטיס נטען מותרת עסקת חיוב רגילה / זיכוי / ביטול / פריקה / טעינה / בירור יתרה`,
              "483": `עסקה עם כרטיס דלק במסוף דלק חיוב להזין מספר רכב`,
              "484": `"מספר רכב המוקלד שונה ממספר הרכב הצרוב ע"ג הפס המגנטי / מספר בנק שונה מ 912 /ספרות שמאליות""ספר רכב המוקלד שונה ממספר הרכב הצרוב ע"ג הפס המגנ""פר רכב המו"`,
              "485": `"מספר רכב קצר מ- 6 ספרות/שונה ממספר הרכב המופיע ע"ג ערוץ 2) פוזיציה 34 בערוץ 2 (כרטיס מאפיין)""ספר רכב קצר מ- 6 ספרות/שונה ממספר הרכב המופ"`,
              "486": `ישנה חובת הקלדת קריאת מונה (פוזיציה 30) בערוץ 2 כרטיס מאפיין דלק של לאומי קארד`,
              "487": `רק במסוף המוגדר כדלק דו שלבי ניתן להשתמש בעדכון אובליגו`,
              "489": `בכרטיס דלקן מותרת עסקת חיוב רגילה בלבד (עסקת ביטול אסורה)`,
              "490": `בכרטיסי דלק/דלקן/דלק מועדון ניתן לבצע עסקאות רק במסופי דלק`,
              "491": `עסקה הכוללת המרה חייבת להכיל את כל השדות conversion_amount_06, conversion_rate_09, conversion_currency_51`,
              "492": `אין המרה על עסקאות שקל/דולר`,
              "493": `בעסקה הכוללת הטבה חיוב שיהיו רק אחד מהשדות הבאים : סכום הנחה / מספר יחידות/ % ההנחה`,
              "494": `מספר מסוף שונה`,
              "495": `אין הרשאת fallback`,
              "496": `לא ניתן להצמיד אשראי השונה מאשראי קרדיט / תשלומים`,
              "497": `לא ניתן להצמיד לדולר/מדד במטבע השונה משקל`,
              "498": `"כרטיס ישראכרט מקומי הספרטור צ "ל בפוזיציה 18""רטיס ישראכרט "`,
              "500": `העסקה הופסקה ע"י המשתמש`,
              "504": `חוסר התאמה בין שדה מקור נתוני הכרטיס לשדה מספר כרטיס`,
              "505": `ערך לא חוקי בשדה סוג עסקה`,
              "506": `ערך לא חוקי בשדה eci`,
              "507": `סכום העסקה בפועל גבוה מהסכום המאושר`,
              "512": `לא ניתן להכניס אישור שהתקבל ממענה קולי לעסקה זו`,
              "551": `מסר תשובה אינו מתאים למסר הבקשה`,
              "552": `שגיאה בשדה 55`,
              "553": `התקבלה שגיאה מהטנדם`,
              "554": `במסר התשובה חסר שדה mcc_18`,
              "555": `במסר התשובה חסר שדה response_code_25`,
              "556": `במסר התשובה חסר שדה rrn_37`,
              "557": `במסר התשובה חסר שדה comp_retailer_num_42`,
              "558": `במסר התשובה חסר שדה auth_code_43`,
              "559": `במסר התשובה חסר שדה f39_response_39`,
              "560": `במסר התשובה חסר שדה authorization_no_38`,
              "561": `במסר התשובה חסר / ריק שדה additional_data_48.solek_auth_no`,
              "562": `במסר התשובה חסר אחד מהשדות conversion_amount_06, conversion_rate_09, conversion_currency_51`,
              "563": `ערך השדה אינו מתאים למספרי האישור שהתקבלו auth_code_43`,
              "564": `במסר התשובה חסר/ריק שדה additional_amounts54.cashback_amount`,
              "565": `אי-התאמה בין שדה 25 לשדה 43`,
              "566": `במסוף המוגדר כתומך בדלק דו-שלבי יש חובה להחזיר שדות 90,116`,
              "567": `שדות 25,127 לא תקינים במסר עידכון אובליגו במסוף המוגדר כדלק דו-שלבי`,
              "700": `עסקה נדחתה ע"י מכשיר PinPad`,
              "701": `שגיאה במכשיר pinpad`,
              "702": `יציאת com לא תקינה`,
              "703": `PINPAD_TransactionError`,
              "704": `PINPAD_TransactionCancelled`,
              "705": `הלקוח לחץ על כפתור ביטול במסופון`,
              "706": `תם הזמן הפעולה בוטלה`,
              "707": `הכרטיס החכם הוצא לפני סוף העסקה`,
              "708": `PINPAD_UserRetriesExceeded`,
              "709": `PINPAD_PINPadTimeout`,
              "710": `PINPAD_PINPadCommsError`,
              "711": `PINPAD_PINPadMessageError`,
              "712": `PINPAD_PINPadNotInitialized`,
              "713": `PINPAD_PINPadCardReadError`,
              "714": `PINPAD_ReaderTimeout`,
              "715": `PINPAD_ReaderCommsError`,
              "716": `PINPAD_ReaderMessageError`,
              "717": `PINPAD_HostMessageError`,
              "718": `PINPAD_HostConfigError`,
              "719": `PINPAD_HostKeyError`,
              "720": `PINPAD_HostConnectError`,
              "721": `PINPAD_HostTransmitError`,
              "722": `PINPAD_HostReceiveError`,
              "723": `PINPAD_HostTimeout`,
              "724": `PINVerificationNotSupportedByCard`,
              "725": `PINVerificationFailed`,
              "726": `שגיאה בקליטת קובץ config.xml`,
              "730": `מכשיר אישר עסקה בניגוד להחלטת אשראית`,
              "731": `כרטיס לא הוכנס`,
            },

            mobilePhoneRepair: {
              title: 'טופס תיקון',
              approveWithPrint: 'אישור עם הדפסה',
              approveWithoutPrint: 'אישור ללא הדפסה',
              printableNotes: 'הערות תיקון',
              selectCustomer: 'בחר לקוח',
              changeCustomer: 'החלף לקוח',
              customerName: 'שם לקוח',
              customerAddress: 'כתובת',
              customerTZ: 'ת.ז/ע.מ',
              customerPhone: 'טלפון',
              additionalPhone: 'טלפון נוסף',
              deviceType: 'סוג מכשיר',
              imei: 'IMEI',
              devicePassword: 'סיסמה נעילה',
              color: 'צבע',
              repairEstimatedCost: 'עלות תיקון משוערת',
              deviceDetails: 'פרטי המכשיר',
              repairDetails: 'פרטי תקלה',
              status: 'סטטוס',
              needToSelectcustomer: 'יש לבחור לקוח',
              saveRepair: 'שומר תיקון...',
              deviceTypeCannotBeEmpty: 'סוג מכשיר שדה חובה',
              notesCannotBeEmpty: 'פרטי תקלה שדה חובה',
              needToSelectStatus: 'יש לבחור סטטוס',
              errorOnSaveRepair: 'שגיאה בשמירת טופס תיקון נסה שנית במידה וחוזר צור קשר עם התמיכה',
              saveSuccess: 'שמירת טופס תיקון בוצע בהצלחה',
              statuses: {
                processingOnLab: 'בטיפול - מעבדה',
                processingOnExternalLab: 'בטיפול - נשלח למעבדה חיצונית',
                pendingRepaired: 'תוקן - ממתין ללקוח',
                pendingWithoutRepair: 'לא תוקן - ממתין ללקוח',
                completed: 'שולם הוחזר ללקוח',
                cancel: 'הוחזר ללקוח ללא חיוב',
              },
              printableRegulations: 'הערות תקנון',
              loadingFutureOrder: 'טוען טופס תיקון...',
              errorOnFetchFutureOrder: 'שגיאה בטעינת טופס תיקון',
              cannotSelectStatusCompletedBeforePayment: 'לא ניתן לבחור סטטוס שולם הוחזר ללקוח, יש לבצע תשלום',
      alertPayLessThenRepairEstimatedCost: 'שים לב אתה עומד לשלם פחות מעלות תיקון משוערת {cost}',
              alertCannotDeleteItemMobilePhoneRepairFromPaymentSale: `מכירה זו מקושרת לתיקון קיים. לא ניתן למחוק פריט תיקון.\nבמידת הצורך - ניתן למחוק את כל המכירה ולהתחיל מכירה חדשה.`,
            },

            indexMobilePhoneRepairs: {
              title: 'דוח תיקונים',
              showMobilePhoneRepair: 'הצג טופס תיקון',
              edit: 'עריכה',
              print: 'הדפס',
              toPay: 'לתשלום',
              cancelWithoutPay: 'הוחזר ללקוח ללא חיוב',
              date: 'תאריך',
              time: 'שעה',
              sequence: 'מספר',
              customerName: 'שם לקוח',
              imei: 'IMEI',
              phone: 'טלפון',
              deviceType: 'סוג מכשיר',
              details: 'פרטי תקלה',

              status: 'סטטוס',
              loadingFutureOrders: 'טוען דוח תיקונים...',
              errorOnFetchFutureOrders: 'שגיאה בטעינת דוח תיקונים',
              updateMobilePhoneRepairCancelWithoutPay: 'מעדכן הוחזר ללקוח ללא חיוב',
              errorOnUpdateMobilePhoneRepairCancelWithoutPay: 'שגיאה בעדכון הוחזר ללקוח ללא חיוב',
              addMobilePhoneRepairToSale: 'מוסיף תיקון למכירה',
              cannotAddMobilePhoneRepairToSale: 'לא ניתן להוסיף תיקון למכירה יש לעשות ניקוי מכירה ולאחר מכן לנסות שוב',
              cannotAddMobilePhoneRepairToSaleMissItemRepair: 'לא ניתן להוסיף תיקון למכירה חסר פריט תיקון',
              cannotAddMobilePhoneRepairToSaleGenralError: 'לא ניתן להוסיף תיקון למכירה שגיאה כללית נסה שנית. במידה וחוזר שנית צור קשר עם התמיכה',
      cannotEditMobilePhoneRepairOnStatus: 'לא ניתן לערוך תיקון בסטטוס {status}',
      cannotAddMobilePhoneRepairToSaleOnStatus: 'לא ניתן לשלם תיקון בסטטוס {status}',
      cannotUpdateMobilePhoneRepairCancelWithoutPayOnStatus: 'לא ניתן לעדכן הוחזר ללקוח ללא חיוב בסטטוס {status}',
            },

            additionalNotesDialog: {
              title: 'הערות הדפסה',
      maxLengthOfLine: '({length} תווים בשורה)',
            },

            mobilePhoneRepairSettings: {
              title: 'הגדרות מודול סלולר',
              save: 'שמור',
              notes: 'הערות תיקון',
              regulations: 'הערות תקנון',
              saveSettings: 'שומר הגדרות...',
              loadingSettings: 'טוען הגדרות...',
              errorOnFetchSettings: 'שגיאה בטעינת הגדרות',
              errorOnSaveSettings: 'שגיאה בשמירת הגדרות מודול סלולר',
              saveSuccessed: 'שמירת הגדרות בוצעה בהצלחה',
            },

            currenciesMessages: {
              notUpdatedTitle: 'שערי מטבע לא מעודכנים',
              noCurrenciesAtDb: 'עדכון שערי מטבע נכשל. \nשים לב! לא קיימים שערי מטבע בקופה. אנא התחבר לאינטרנט ובצע עדכון מיידי על מנת לאפשר עבודה בקופה.',
              noCurrenciesAtDbElal: 'עדכון שערי מטבע נכשל. \nשים לב! לא קיימים שערי מטבע בקופה. אנא התחבר לאינטרנט, תוודא שקיים קובץ שערי מטבעות ובצע עדכון מיידי על מנת לאפשר עבודה בקופה.',
              currenciesNotUpdated: 'שים לב! עדכון שערי מטבע נכשל. אנא התחבר לאינטרנט על מנת לבצע עדכון. ניתן לבצע עדכון מאוחר יותר ע"י סגירת הקופה ופתיחתה מחדש.',
              updateNow: 'עדכן שערים',
              useOldRates: 'השתמש בשערים הקיימים',
            },

            finishTimeItemDialog: {
              title: 'סיום זמן {item}',
              startDate: 'תאריך התחלה',
              startTime: 'זמן התחלה',
              endDate: 'תאריך סיום',
              endTime: 'זמן סיום',
              totalTime: 'סה"כ זמן',
              totalPrice: 'סה"כ מחיר',
              details: 'פירוט',
              detailsQuantity: 'כמות דקות',
              detailsRate: 'תעריף',
              detailsSum: 'מחיר',
              rateText: '{rate} {currency} ל{rateTime} דקות',
              minutes: 'דקות',
              edit: 'עריכה',
              saleHasOpenTimeItems: 'קיימים פרטי זמן שלא נסגרו עדיין. אנא סגור את כל הפריטי הזמן הפעילים על מנת לסגור את המכירה.'
            },

            icMega: {
              cannotAddItem: "לא ניתן להוסיף פריט",
              onlyIcMegaItemsCanAdd: "רק פריטי ICMega יכולים להתווסף למכירה זו.\nבמידת הצורך - ניתן למחוק את כל המכירה ולהתחיל מכירה חדשה.",
              canPaymentOnlyByIcMegaVoucher: 'מכירה עם פריטי ICMega, ניתן לשלם רק באמצעות תשלום ICMega.\nבמידת הצורך - ניתן למחוק את כל המכירה ולהתחיל מכירה חדשה.',
              cannotPaymentByIcMegaNotFoundIcMegaItemOnSale: 'לא נמצא פריט במכירה שמאופשר לתשלום באמצעות ICMega',
              cannotAddDifferentcICMegaItemCodes: 'לא ניתן להוסיף פריט ICMege בקוד פריט שונה מהפריט הקיים במכירה',
              quantityMustBeBiggerThanZero: 'יש להזין כמות לחיוב גדולה מאפס',
              barcodeIsRequired: 'יש להזין ברקוד',
              generalIcMegaServerError: 'שגיאה כללית ICMega',
              icMegaServerErrorNotFound: 'ברקוד לא נמצא ICMega',
              cannotPaySaleItemWithBarcode: 'לא ניתן לשלם על הפריט במכירה עם ברקוד זה',
      cannotPayQuantityBiggerThanAvailableQuantity: 'לא ניתן לחייב כמות גדולה מכמות הזמינה {quantity} לחיוב',
              cannotPayNoAvailableQuantity: 'לא ניתן לחיוב אין כמות זמינה לחיוב',
              cannotUseSameBarcodeTwice: 'לא ניתן לחייב פעמיים אותו ברקוד. במידת הצורך ניתן למחוק את החיוב הקיים ולחייב מחדש',
              quantityCannotBeBiggerThanTotalQuantity: 'לא ניתן לחייב כמות גדולה מכמות הפריט בחשבון',
      cannotPayLessThanAvailableQuantity: 'לא ניתן לחייב כמות קטנה מ{quantity} הזמינה בקופון',
              canNotCreditIcMega: 'לא ניתן לזכות מכירה עם תשלום ICMega',
              cannotAddIcMegaItemWithOtherItem: 'פריט ICMega לא יכול להימכר עם פריטים נוספים.\n במידת הצורך יש למחוק את כל המכירה ולהתחיל מכירה חדשה',
              icMegaVoucherNotExist: 'שובר ICMega לא קיים בקופה',
            },
            modbus: {
              errorCommunicatingModbusServer: 'ארעה שגיאה בהתקשרות מול הבקר החכם, אנא בדוק שהבקר עובד כראוי והוגדר תקין',
              sendDataToRegister: 'מבצע עדכון לבקר חכם, אנא המתן...',
            },

            priorityCustomerClub: {
              title: 'לקוחות פריוריטי',
              searchPlaceholder: "לפי מספר לקוח/שם/טלפון",
              customerName: 'שם לקוח',
              mustEnterCustomerName: 'חובה להזין שם לקוח',
              errorCustomerExist: 'לקוח כבר קיים בפריוריטי',
              edit: "עריכה",
              update: 'עדכן',
              acceptMailing: 'מאשר קבלת דיוור',
              errorOnUpdateCustomer: 'שגיאה בעדכון לקוח',
            },

            priorityCustomerReportDialog: {
              title: 'דוח עסקאות לקוח פריוריטי',
              showInvoiceDetails: 'הצג פרטי חשבונית',
              page: 'עמוד',
            },

            priorityCustomerInvoiceDetailsDialog: {
              title: 'פירוט חשבונית פריוריטי',
              notFoundTinvoice: 'שגיאה במשיכת חשבונית מפריוריטי נסה שנית במידה וחוזר צור קשר עם התמיכה',
              invoice: 'חשבונית',
              customerName: 'שם לקוח',
              totalInvoice: 'סה"כ חשבונית',
              itemCode: 'קוד פריט',
              itemDescription: 'תיאור פריט',
              quantity: 'כמות',
              total: 'סה"כ',
              items: 'פריטים',
              payments: 'אמצעי תשלום',
              paymentType: 'סוג תשלום',
              cash: 'מזומן',
              check: 'צק',
              itemBasePrice: 'מחיר בסיסי לפריט',
            },
            packageDialog: {
              title: 'בחר אריזה לפריט',
              noPackage: 'ללא אריזה',
              tooMuchPackage: 'כמות האריזות גדולה מהמשקל של הפריט',
            },
            migvanSelectDialog: {
              pleaseSelectMigvan: 'בחר מסעדה להזמנה',
            },
            sittingTaDialog: {
              selectOrderType: 'יש לבחור סוג הזמנה',
              sitting: 'לשבת',
              ta: 'לקחת',
              sittingInPlace: 'ישיבה במקום',
            },
            otot: {
              generalError: "שגיאה כללית בממשק הצמיד החכם - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              printingTag: 'מדפיס צמיד, אנא המתן...',
              activatingRfidBracelet: 'מפעיל צמיד...',
              validationError: 'ארעה שגיאה בהזדהות מול ממשק אותות, אנא וודא את תקינות ההגדרות',
              scanBraceletOrInputNumber: 'סרוק צמיד או הקלד מספר צמיד',
              scanBracelet: 'סרוק צמיד',
              braceletNumber: 'מספר צמיד',
              clearBraceletNumber: 'נקה מספר צמיד',
              cantPayWithOtherPayments: 'לא ניתן לשלם תשלום חלקי בצמיד',
              balances: {
                currentAmount: 'יתרת כסף בצמיד',
                entriesLeft: 'אטרקציות',
                loyaltyPointsLeft: 'מג\'יק פויינטס'
              },
              purchasingTags: 'מבצע רכישת צמיד/ים, אנא המתן...',
              loadBracelet: 'טוען כסף לצמיד, אנא המתן...',
              notEnoughBalanceToCredit: 'אין מספיק יתרה בצמיד',
              scanQrBracelet: 'סרוק צמיד QR',
              scanQrBraceletForLoad: 'יש לסרוק את הצמיד אליו מעוניינים לטעון כסף',
              scanRFIDBraceletToLoadTicket: 'סרוק צמיד לכרטיס: {ticketName}',
              scanRFIDBraceletToActivate: 'הפעלת צמיד חכם',
              activateBraceletFor: 'היי {name}, להפעלת {productName}, הצמד את הצמיד לקורא',
              braceletActivated: 'ההצמדה הצליחה!',
              pleaseScanBracelet: 'נא לסרוק צמיד ולהמתין לאישור',
              chargingBracelet: 'מחייב צמיד',
              priceForLoad: "כמה כסף תרצה להטעין",
              scanTicketQr: 'הצג קוד QR של כרטיס',
              noBraceletOnSale: "למכירה זו אין צמידים להדפסה",
              braceletLoadSuccessfully: 'טעינת הצמיד בוצעה בהצלחה',
      braceletLoadTotalAmount: 'יתרתך בצמיד: {amount}',
              purchaseByGamePointsAmountRequiredMessage: 'חובה להזין כמות נקודות למימוש',
              purchaseByGamePointsAmountNeedToBeGreaterThanZero: 'יש להזין כמות נקודות למימוש גדולה מ0',
              pointsAmount: 'כמות נקודות',
              tagPointsBeforeUsed: 'יתרה לפני מימוש',
              tagPointsUsed: 'כמות נקודות שמומשו',
              tagCurrentPoints: 'יתרתך הנוכחית',
              reprintWarning: 'פעולה זו תבטל את כל הצמידים שיצאו מ- QR זה - יש לאסוף את כל הצמידים שהודפסו מהלקוח',
              printOrLoadBrancelet: 'האם תרצו להדפיס צמיד חדש או לטעון צמיד שברשותכם?',
              printNewBracelet: 'הדפסת צמיד חדש',
              loadExistingBracelet: 'טעינת צמיד קיים',
              tagNotScanned: 'לא הוזן צמיד, המכירה בוטלה'
            },
            outOfStockItemsDialog: {
              title: 'פריטים חסומים למכירה',
              selectLockedItems: 'אנא בחר פריטים לנעילה',
      confirmLock: 'האם אתה בטוח שברצונך לנעול את הפריט {name}?',
      confirmUnlock: 'האם אתה בטוח שברצונך לבטל נעילה לפריט {name}?',
              outOfStock: 'אזל במלאי',
              outOfStockMessage: 'פריט זה אזל',
              dialogDescription: 'אנא בחר פריט לנעילה/שחרור נעילה'
            },
            selfServiceTotalBeforePayDialog: {
              title: 'סיכום הזמנה',
              totalToPay: 'סה"כ לתשלום',
              back: 'חזרה',
              pay: 'לתשלום',
              promotion: 'מבצע',
            },
            selfServicePluxeeSelectCompanyDialog: {
              title: 'בחירת חברה',
              selectCompany: 'בחר חברה',
            },
            selectDalpakFromListDialog: {
              title: 'בחר דלפק',
              dalpak: 'דלפק',
              dalpakArea: 'אזור',
              movingItems: 'מעביר פריטים, אנא המתן...',
            },
            cashcow: {
              orderFee: 'עמלת הזמנה',
            },
            posCloseSaleDailog: {
              title: "סיום חשבון",
              totalQuantity: 'סה"כ חשבון',
              totalSale: 'סה"כ חשבון',
              totalPaid: 'סה"כ שולם',
              changeInUSD: 'עודף ב$',
              changeInILS: 'עודף ב₪',
              changeInCash: 'עודף במזומן',
              changeInCreditCard: 'עודף בכרטיס אשראי',
              changeInCreditVoucher: 'עודף בשובר זיכוי',
            },
            upsaleDialog: {
              wouldYouLikeItems: 'תרצו להוסיף גם את אחד מהמוצרים הבאים?',
              wouldYouLikeAItem: 'בא לכם {item} אולי?',
              cancel: 'לא הפעם',
              ok: 'יאללה בכיף',
            },

            valuCardReport: {
              actions: {
                resetBalance: "איפוס כרטיס",
              },
            },

            restaurant: {
              EnableEpandedView: 'תצוגה מורחבת',
              DisableEpandedView: 'בטל תצוגה מורחבת',
              EnableSelectMode: 'בחירה מרובה',
              DisableSelectMode: 'בטל בחירה מרובה',
              noItems: 'בחר מנות להוספה',
              emptyTable: 'השולחן ריק. תזמין משהו',
              noResults: 'לא נמצאו מנות מתאימות לחיפוש זה',
              searchPlaceholder: 'הקלד כאן...',
              back: "חזרה",
              bottomActions: {
                search: 'חיפוש פריט',
                for: 'לכבוד',
                numberOfDiners: 'מספר סועדים',
                findCustomer: 'חבר מועדון',
                noPrint: 'ללא הדפסה',
                discount: 'הנחת חשבון',
                tip: 'טיפ',
                calculator: 'מחשבון',
              }
            },

            midragPaymets:{
              numOfPaymentsTitle: "כמות תשלומים:",
              ok:"אישור",
              cancel:"ביטול"
            },

            valueCardSelfService: {
              'valueCard' : 'מועדון Value Card',
              'activeBenefits': 'הטבות בתוקף',
              'birthdayBenefit': 'הטבת ליום הולדת',
              'pointsShop': 'חנות נקודות',
              'punchCards' : 'כרטיסיות',
              'cashbackBalance' : 'תקציב/קאשבק',
              'doYouWantToRedeemPoints' : 'האם תרצה לממש נקודות?',
              'youHave' : 'ברשותך',
              'hi' : 'היי',
              'points' : 'נקודות',
              'redeemablePoints' : 'נקודות למימוש',
              'noBenefits' : 'אין הטבות',
              'noPointsStore': 'אין חנות נקודות',
              'noPunchCards' : 'אין כרטיסיות פעילות',
            },
            osherAd: {
              saleScanReady: "סיום קנייה",
              cannotAddItemsToNonEmptyCart : 'לא ניתן להוסיף פריטים לעגלה לא ריקה',
              itemName : 'שם הפריט',
              itemPrice : 'מחיר',
              itemQuantity : 'כמות',
              goToPay : 'מעבר לתשלום',
              totalToPay: 'סה"כ לתשלום',
              cannotConnectToServer: 'אנא נסו מאוחר יותר',
              smsInvoice: "האם תרצה לקבל חשבונית בSMS?",
              smsInvoicePlaceholder: "הזן מספר נייד לקבלת SMS",
              preferPrinted: "מעדיפים חשבונית מודפסת",
              sendSMS: "אישור",
              print: "הדפסה",
              clickToScan: "לחצו להתחלה",
            },
            vendingMachine:{
              manageMachine: "ניהול מכונה",
              resetMachine: "איפוס מכונה",
              requestPort: "בחירת פורט מכונה",
              inventoryUpdate: 'עדכון מלאי',
              resetingMachine: "פקודת איפוס מכונה נשלחה בהצלחה",
              disableLogs: "כבה לוגים מכונה",
              enableLogs: "הפעל לוגים מכונה",
              tempCheck: "בדיקת טמפרטורה",
              queringMachine: "מתשאל מכונה..",
              tempIs: "הטמפרטורה היא ",
              tempCheckError: "שגיאה בבדיקת טמפרטורה",
              checkStatus: "בדיקת מצב מכונה",
              success:"הפעולה בוצעה בהצלחה",
              closingSale: "מנפיק פריטים",
              codes: {
                '00': 'שגיאה בתקשורת עם מכונה',
                '30': 'מוכן לפעולה',
                '31': 'IN PROCESS - BUSY',
                '32': 'מגש פריט לא תקין',
                '33': 'רצועת פריט לא תקין',
                '34': 'לקוח יקר, המוצר הנבחר אזל מהמלאי ולכן אנחנו מזכים אותך כעת על העסקה. ניתן לבחור פריט חלופי בהזמנה חדשה',
                '35': 'FAULT IN ELEVATOR MOTOR (POSSIBLE JAM)',
                '37': 'FAULT IN ANY OF THE CABINET\'S PHOTOTRANSISTORS',
                '38': 'NO CHANNEL DETECTED',
                '39': 'מזהה פריט',
                '41': 'FAULT IN 485 BUS',
                '42': 'PRODUCT UNDER THE ELEVATOR ALARM',
                '43': 'ERROR WHEN ELEVATOR APROACHING TO A POSITION',
                '44': 'FAULT IN KEYBOARD',
                '45': 'EEPROM WRITING ERROR',
                '46': 'FAULT COMMUNICATING WITH TEMPERATURE CONTROL',
                '47': 'THERMOMETER DISCONNECTED',
                '48': 'THERMOMETER PROGRAMMING LOST',
                '49': 'THERMOMETER FAULTY',
                '4a': 'CHANNELS POWER COMSUMPTION DETECTOR FAULTY',
                '4b': 'ELEVATOR DOES NOT FIND CHANNEL/TRAY',
                '4c': 'ELEVATOR DOES NOT FIND DELIVERY PRODUCT POSITION',
                '4d': 'INTERIOR OF THE ELEVATOR BLOCKED',
                '4e': 'ERROR IN TESTER OF PRODUCT DETECTOR',
                '4f': 'פריט נופק בהצלחה, פתח את הדלת על מנת לקחת את הפריט',
                '50': 'PRODUCT IS EXPIRED BECAUSE TEMPERATURE',
                '59': 'PRODUCT IS EXPIRED (NSF ALARM 1)',
                '5a': 'PRODUCT IS EXPIRED (NSF ALARM 2)',
                '61': 'PRODUCT IS EXPIRED (NSF ALARM 3)',
                '62': 'PRODUCT IS EXPIRED (NSF ALARM 4)',
                '63': 'PRODUCT IS EXPIRED (NSF ALARM 5)',
                '64': 'PRODUCT DETECTOR DID NOT CHANGE DURING ITS VERIFICATION TEST'
              },
              zReportInProgress: "אנא המתן, המערכת מבצעת תהליך עדכון נתונים ומיד תשוב לפעילות",
              inventory: {
                onCloseSaleOutOfStockError: 'לקוח יקר, המוצר הנבחר אזל מהמלאי ולכן אנחנו מזכים אותך כעת על העסקה. ניתן לבחור פריט חלופי בהזמנה חדשה',
                areYouSureYouWantToReload: 'האם לטעון את מלאי הפריטים במכונה מהשרת?',
                loading: 'טוען מלאי פריטים...',
                errorOnLoad: 'אירעה שגיאה בעת עדכון מלאי מכונה. אם הודעה זו חוזרת פנה לתמיכה',
                cantLoadWhenOfflineSales: 'אין אפשרות לעדכן מלאי כשיש מכירות בנתק. אם הודעה זו חוזרת פנה לתמיכה',
                success: 'מלאי פריטים של המכונה עודכן בהצלחה',
                catalogUpdatedBut: "קטלוג עודכן אבל - ",
              }
            },
            multiCurrencyChange: {
              storeCurrencyChange: "עודף במטבע חנות",
              storeCurrencyChangeLeft: "עודף נותר במטבע חנות",
              title: "קליטת עודף ללקוח",
              invalidChangeAmount: "נא להזין את כל סכום העודף",
              enterChange: "נא להזין את סכום העודף",
              changeIncurrency: "עודף במטבע",
              changeInput: "קלט עודף",
            },
            multiCurrencyCashDetails: {
              posTitle: "פירוט מטבעות לקופה",
              storeTitle: "פירוט מטבעות לחנות",
            },
            currenciesRates: {
              title:'שערי מט"ח',
              lastUpdate: "השערים מעודכנים נכון לתאריך: ",
            },
            creditInOGCashCurrency: {
              title: 'יש להחזיר מזומן על פי הפירוט',
              cols: {
                currency: 'מטבע',
                howMuchToRefund: 'כמה להחזיר במטבע',
              },
            },
            components:{
              componentNotExist:'החלון {componentName} לא קיים'
            },
            languages: {
              title: 'שפות',
              en: 'אנגלית',
              he: 'עברית',
              ru: 'רוסית',
              ar: 'ערבית',
            },
            transmitTaxInvoiceDialog: {
              title: "שידור חשבונית לרשות המיסים",
              name: "שם העוסק",
              tz: "ת\"ז / ח\"פ",
              transmitting: "משדר חשבונית",
              pleaseEnterValidId:"אנא הזן ת\"ז או ח\"פ תקין",
              pleaseEnterValidName:  "אנא הקלד שם עוסק תקין(בין 2 ל-30 תווים)",
              content: "האם לשדר את חשבונית מספר {invoiceSequence} לרשות המיסים?",
              transmitSuccess: "החשבונית נשלחה בהצלחה",
              saleTransmitted: "החשבונית כבר שודרה לרשות המיסים \n מספר הקצאה: <strong>{confirmation_number}</strong>",
              transmitError: "שגיאה בשידור חשבונית לרשות המיסים",
              saleNotFound: "העסקה לא סונכרנה לשרת אנא נסה שנית כשאין מכירות בנתק",
              saleSyncError: "העסקה לא סונכרנה לשרת ולכן לא קיבלה מספר הקצאה לרשות המיסים. ניתן לבקש מספר הקצאה דרך מסך חשבוניות קודמות לאחר סנכרון העסקה לשרת",
              syncSaleToTransmit: "לא ניתן לשדר מכירה בנתק",
              loginAgainBo: "פג תוקף ההתחברות לרשות המיסים \nיש לבצע חיבור מחדש דרך המשרד האחורי במסך “התחברות לרשות המיסים” שנמצא תחת תפריט “הגדרות”",
              cantTransmitSaleWithoutVAT: "לא ניתן לשדר מכירה שסכום המע\"מ בה שווה ל-0",
              invoiceTypeNotSupported: "סוג מסמך זה לא נתמך בשידור לרשות המיסים",
              unknownError: "שגיאה בשידור חשבונית לרשות המיסים, אם הודעה זו חוזרת פנה לתמיכה.",
              errors: {
                Customer_VAT_Number: "אנא הזן ת\"ז או ח\"פ תקין",
                Vat_Number: 'מספר ח"פ של בית העסק לא תקין, אנא פנה לתמיכה',
                406: "ישנה שגיאה בניסיון לייצר הקצאה עבור חשבונית הלקוח בחשבוניות ישראל, נראה שחלה בעיה ברישום בית העסק. \n יש לנסות ולבצע רישום מחדש. במידה והבעיה לא נפתרת יש לפנות לעזרה מול צוות תמיכה של חשבוניות ישראל",
              },
              connect: "חיבור לרשות המיסים",
            },
            taxAuthorityConnect: {
              title: "חיבור למודל חשבוניות ישראל",
              step1: "בכדי להשתמש במודל חשבוניות ישראל יש להתחבר לאתר רשות המיסים דרך ה-QR",
              step2: "אם אין ביכולתך לסרוק את ה-QR, יש להעתיק את הקישור הבא לדפדפן אינטרנט:",
              guide: "הדרכה לרישום",
            },
            completeOrderWithPaymentDialog: {
              title: 'הצגת הזמנה לתשלום במזומן מעמדת שירות עצמי',
              freeSearch: 'חיפוש כללי',
              cols: {
                createdAt: 'זמן הזמנה',
                orderNumber: "מס' הזמנה",
                customerName: 'שם לקוח',
                loadOrder: 'טען הזמנה {orderNum}',
              },
            },
          }

