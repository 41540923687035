module PositiveTS { 
  export module Printing {

    export class TenbisSlip extends AFactory {
        private _I18N = {
          TITLE_DEBIT: "אישור ביצוע חיוב בתן ביס",
          TITLE_CREDIT: "אישור ביצוע זיכוי בתן ביס",
          TRX_ID: "מזהה העסקה",
          CARD_NUMBER: "מספר הכרטיס",
          AMOUNT_SUM: "סכום העסקה",
          HAPPY_HOUR_DISCOUNT: "הנחת תן ביס",
          ACTUALLY_PAIED: "חיוב בפועל",
          SHIPMENT_DOC: "תעודת משלוח",
        };



        constructor(sale: any, company: any, salePayments: any) {
            super(sale, company, salePayments);
        }

        run(): void{
          var voucherPayments = PositiveTS.Helper.SaleHelper.findPaymentByMethod(this._salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER);
          if (voucherPayments) {
            var tenbisPayments = JSON.parse(voucherPayments.data).filter( (row)=>{return row.smartVoucherType === "Tenbis"});
            if ( !(tenbisPayments.length) ){return;}

            for (let i=0;i<tenbisPayments.length;i++){
              this._printTenbisPayment(tenbisPayments[i])
            }
          }
          else {
            return;
          }

        }

        private _printTenbisPayment(tenbisPayment){
          var aThis = this._aThis;
          var zebra = this._zebra;
          var sale = this._sale;

          Printing.Invoice.printSlipHeader(this._sale, this._company);

          zebra.append(aThis.allowComponents.centerAlignment);

          if (tenbisPayment.amount > 0){
            aThis.printLine(this._I18N.TITLE_DEBIT);
          } else {
            aThis.printLine(this._I18N.TITLE_CREDIT);
          }

          aThis.printHr();

          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.rightAlignment);
          aThis.printFloatingLine(this._I18N.TRX_ID, tenbisPayment.orderId.toString())
          aThis.printFloatingLine(this._I18N.CARD_NUMBER, "****" + tenbisPayment.barCode.substr(tenbisPayment.barCode.length - 4) )
          aThis.printFloatingLine(this._I18N.AMOUNT_SUM, session.fixedNumber(tenbisPayment.amount))
          aThis.printFloatingLine(this._I18N.HAPPY_HOUR_DISCOUNT, session.fixedNumber(tenbisPayment.happyHoutDiscounts))
          aThis.printFloatingLine(this._I18N.ACTUALLY_PAIED, session.fixedNumber(tenbisPayment.amount - tenbisPayment.happyHoutDiscounts ))
          aThis.printFloatingLine(this._I18N.SHIPMENT_DOC, Printing.Invoice.getSaleNumberFormated(sale).toString())


          this.lineFeed();
          zebra.append(aThis.allowComponents.init);

          this.lineFeed();
          printer.printLineCut();
        }
    }


  }
}
