export enum DialogAnswer {
    OK = "OK",
    CANCEL = "CANCEL",
    
}

export default interface ILoadingMessageAndDialogManager {
    showLoadingMessage(message: string);
    hideLoadingMessage();
    showAlert(message:string, title: string);
    showAlertWithAnswer(message:string, title: string, continueButtonText?: string, cancelButtonText?: string, yellowCancel?: boolean): Promise<DialogAnswer> ;
}