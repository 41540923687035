
module PositiveTS {
/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"punchCardGenericSelect", arguments: ["10.50","123123"]})
*/
  export module Components {
    export module PunchCardGenericSelect {
      const _dialogSelector = "#positive-punch-card-generic-select-dialog"
      const _vueComponentName = 'punch-card-generic-select';
    
      export function create() {
        let vueObj = {
            template: JST.punchCardGenericSelect(),
            methods: {
              open: _open,
              close: _close,
              doConfirm: _doConfirm,
              doReject: _doReject
            },
            data: _initData,
    
    
        }
    
        VueApp.component(_vueComponentName,vueObj)
    
      }
    
    
    
      function _open(punchCardItems){
        
    
        $(document).unbind('keypress');
        $(function() {
          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: '80%',
            height: 600,
            resizable: false,
            closeOnEscape: false,
            draggable: false,
          });
          $(_dialogSelector).dialog('open');
        });
    
    
        return new Promise((resolve,reject) => {
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.punchCardItems = punchCardItems;

        })
    
      }
    
    
      function _initData(){
        return {
          _dialogSelector: _dialogSelector,
          rejectFunc: null,
          resolveFunc: null,
          punchCardItems: [],
          cardNumber: null
        };
      }


      function _doConfirm(row){
        this.close();
        this.resolveFunc(row)
      }
      

      function _doReject(){
        this.close();
        this.rejectFunc(new Error(app.userCancelledGlobalMessage));
      }
    
      function _close() {
        $(_dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }
    
    
    }}}