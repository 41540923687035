module PositiveTS {
export module Components {
    export module PosPayment {
      const modalMultiCurrenciesPaymentDetailsSelector = 'modal-multi-currencies-payment-details'
      function initData() {
        return {
          paymentMethods: {
            cash: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCash)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_CASH,
              domId: '#pos-payment-cash'
            },
            credit: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCredit)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT,
              domId: '#pos-payment-credit',
            },
            voucher: {
              visible: false,
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-voucher'
            },
            creditVoucher: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCreditVoucher)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER,
              domId: '#pos-payment-promotion'
            },
            check: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCheck)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK,
              domId: '#pos-payment-check'
            },
            cibus: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCibus)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-cibus'
            },
            tenbis: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showTenbis)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-tenbis'
            },
            otot: {
              visible: false,
              enabled: Service.Otot.isOtotActive() && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showOtotPayment)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-otot'
            },
            goodi: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showGoodi)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-goodi'
            },
            banx: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentBanx)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-banx'
            },
            safeCash: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentSafeCash)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-banx'
            },
            yaad: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showYaadPayment)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-yaad'
            },
            interService: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showInterServicePayment)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-interService'
            },
            caveretPayment: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.isCaveretPayment)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-caveret'
            },
            multipassPolice: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.multipassPolicePosId)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-multipass'
            },
            tip: {
              visible: false,
              enabled: Service.Tip.isHasTips(),
              paymentMethod: null,
              domId: '#pos-payment-tip'
            },
            hakafa: {
              visible: false,
              paymentMethod: null,
              domId: '#pos-payment-hakafa'
            },
            icmega: {
              visible: false,
              enabled: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentIcMega)),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER,
              domId: '#pos-payment-icmega'
            },
            paymentPage: {
              visible: false,
              enabled: Service.PaymentPage.isEnabled(),
              paymentMethod: PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT,
              domId: '#pos-payment-page'
            },
          },
          currentView: null,
          isDuringSwipe: false,
          cardReader: Boolean(session.pos.usePinPad),
          paymentLeft: 0,
          paymentType: null,
          isMultiCurr: PositiveTS.Service.MultiCurr.getInstance().isMultiCurr(),
          posBaseCurrency: PositiveTS.Service.MultiCurr.getInstance().getPosCurrency(),
          modalMultiCurrenciesPaymentDetails: document.getElementById(modalMultiCurrenciesPaymentDetailsSelector)
        };
      }

      function openCalculator(){
        if (this.isMultiCurr){
Pinia.componentsStore.openComponent( {componentName:"currencyConversionCalculator", args: [true]})
          return;
        }
Pinia.componentsStore.openComponent( {componentName:"calculatorDialog", args: []}); 
      }

      function selectPaymentMethod(method) {
        if(this.isPaymentPageActive && method != 'paymentPage'){
          return app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('paymentPage.errors.pleaseCancelOrContinue'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          }, null, null);

        }

        if(!Service.MultipassPolice.checkIfCanBePayedWithCasparIfNeeded(method)){
          return
        }
        
        const isSmartVoucher = PositiveTS.Service.BlockItemPaymentMethod.smartVouchers.includes(method)
        if (method != 'voucher' && PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(posVC.saleItems, isSmartVoucher ? 'voucher' : method, isSmartVoucher ? method : null, true)){
          return 
        }

        if (method == "icmega" && !Service.icMega.checkIfCurrentSaleCanPayByIcMegaMethod()){
          return
        }
        
        if (PositiveTS.Service.icMega.checkIfCurrentSaleIsIcMegaItemAndNotAllowMethod(method, true)){
          for (let paymentMehtod in this.paymentMethods) {
            if (paymentMehtod != 'icmega'){
              this.paymentMethods[paymentMehtod].visible = false
            }
          }
          
          this.paymentMethods.icmega.visible = true
          return
        }

        this.paymentType = method
        const noScreenPaymentMethods = ["caveretPayment","multipassPolice", "hakafa"]
        if (session.pos.hasFlights && method == 'credit') {  
          PositiveTS.Service.Currencies.updatePosPaymentCurrency(Storage.Entity.CurrencieCodes.ILS);
        } else {
          PositiveTS.Service.Currencies.updatePosPaymentCurrency(null);
        }

        if (!noScreenPaymentMethods.includes(method)) {
          for (let paymentMehtod in this.paymentMethods) {
            this.paymentMethods[paymentMehtod].visible = (paymentMehtod == method)
          }
          
          posPaymentVC.currentPaymentMethod = {
            method: this.paymentMethods[method].paymentMethod,
            id: this.paymentMethods[method].domId
          };
        }
      

        this.setAndRefreshCurrentView(method);

      }
      function openSelectCurrencyPayment () {
        this.$refs.SelectCurrencyPayment.open()
        PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView.selectFirstPaymentMethod()
      }

      export function create() {
        var component = {
          watch: {
            mobileLayout () {
              if (this.isMultiCurr){
                const root = document.documentElement
                let value = 'repeat(2, 1fr)'
                if (this.mobileLayout){
                  value = 'repeat(4, 1fr)'
                }else{
                  value = 'repeat(3, 1fr)'
                }
                root.style.setProperty('--pos-payment-main-actions-columns', value)
                root.style.setProperty('--pos-payment-main-actions-min-width', '70px')
              }
            }
          },
          
          template: JST.posPayment(),
          
          components: {
            SafeCashView: SafeCashView.getComponent(),
            positiveIcon: PositiveIcon.getComponent(),
          },

          methods: {
            open() { // Mimic the "mounted" hook
              Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.PosPayment);
            },
            setAndRefreshCurrentView(method) {
              if (method == 'cibus') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.pluxeeView;
                this.refreshCurrentView();
              }
              else if (method == 'tenbis') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.tenbisView;
                this.refreshCurrentView();
              }
              else if (method == 'otot') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.ototView;
                this.refreshCurrentView();
              }
              else if (method == 'check') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.checkView;
                this.refreshCurrentView();
              }
              else if (method == 'goodi') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.goodiView;
                this.refreshCurrentView();
              }
              else if (method == 'banx') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.banxView;
                this.refreshCurrentView();
              }
              else if (method == 'safeCash') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.safeCashView;
                this.refreshCurrentView();
              }
              else if (method == 'yaad') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.yaadView;
                this.refreshCurrentView();
              }
              else if (method == 'paymentPage') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.paymentPage;
                this.refreshCurrentView();
              }
              else if (method == 'interService') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.interServiceView;
                this.refreshCurrentView();
              }
              else if(method == 'caveretPayment'){
                this.currentView = PositiveTS.VueInstance.$refs.caveretPaymentCustomerPicker;
Pinia.componentsStore.openComponent( {componentName:"caveretPaymentCustomerPicker", args: []});
              }
              else if(method == 'multipassPolice'){
                if(Service.MultipassPolice.policePointsHaveBeenPaid()){
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('multipassPolice.alreadyHasPointsWarning'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: false
                  }, async () => {
                    await Service.MultipassPolice.abortPayments();
                    this.currentView = PositiveTS.VueInstance.$refs.multipassPoliceCustomerPicker;
                    await this.openMultipassPoliceCustomerPickerDialog();
                  }, null);
                }
                else{
                  this.currentView = PositiveTS.VueInstance.$refs.multipassPoliceCustomerPicker
                  this.openMultipassPoliceCustomerPickerDialog();
                }
              }
              else if (method == 'credit') { //TODO: refactor this once I have all the payment methods as components
                if (Boolean(session.pos.usePinPad)) {
                  if (PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView) {
                    this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.newCreditCardView;
                    this.refreshCurrentView();
                  }
                }
                else {
                  this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.creditCardViewNoPinPad;
                  this.refreshCurrentView();
                }
              }
              else if (method == 'cash') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.cashView;
                this.refreshCurrentView();                    
              }
              else if (method == 'creditVoucher') {
                this.currentView = this.$refs.creditVoucherView;
                this.refreshCurrentView();                    
              }
              else if (method == 'voucher') {
                this.currentView = this.$refs.voucherView;
                this.refreshCurrentView();                    
              }
              else if (method == 'tip') {
                this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.tipView;
                this.refreshCurrentView();                    
              }
              else if (method == 'hakafa') {
                if (PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(posVC.saleItems, 'voucher', 999, true)){
                  return
                }
                let custSvc = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
        
                if ( PositiveTS.Service.Hakafa.showHakafaBtn() ) {
                  if (PositiveTS.Service.Hakafa.hasCustomer()) {
                    PositiveTS.Components.PosPaymentButtons.openVoucherId( PositiveTS.Service.Hakafa.getVoucherID() , 'amount');
                    this.currentView = this.$refs.voucherView;
                        this.refreshCurrentView(); 
                      } else {
                        // // Open select hakafa customer dialog
                        // if successfull open voucher payment
Pinia.componentsStore.openComponent( {componentName:"vueDialog", args: [Components.HakafaCustomerDialog.componentName]})
                      }
                    } 
                  }
                  else if (method == 'icmega') {
                    this.currentView = PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.icmegaView;
                    this.refreshCurrentView();
                  }
                  else {
                    this.currentView = null;
                    throw new Error("Unknown payment method selected")
                  }
                },
          
                async refreshCurrentView() {
                  app.showLoadingMessage(i18next.t('posPayment.loadPayment'))
                  if (posUtils.isDefined(this.currentView) && posUtils.isDefined(this.currentView.refreshView)) {
                    await this.currentView.refreshView();
                  }
                  app.hideLoadingMessage()
                },          
                hasFlights() {
                  return session.pos.hasFlights;
                },
                baseCurrency() {
                  return Service.MultiCurr.getInstance().getPosCurrency();
                },
                selectPaymentMethod: selectPaymentMethod,
                selectFirstEnabledPaymentMethod() {
                  if (this.paymentMethods.icmega.enabled && PositiveTS.Service.icMega.checkIfCurrentSaleIsIcMegaItem()) {
                    this.selectPaymentMethod('icmega');
                    return;
                  }

              for (let method in this.paymentMethods) {
                if (this.paymentMethods[method].enabled) {
                  this.selectPaymentMethod(method);
                  return;
                }
              }
            },
            openCalculator: openCalculator,
            openSelectCurrencyPayment: openSelectCurrencyPayment,
            closePayment: async function() {
              if (PositiveTS.Service.MultipassPolice.isEnable()) { 
                let result = await PositiveTS.Service.MultipassPolice.alertUserAboutPaymentBackIfNeeded()
                if (result === false) {
                  return;
                }
              }
              
              if (Service.SplitSalePayment.isSplitPaymentSale(posVC.sale)) {
                let result = await posVC.confirmSaleRestart(true);

                if (result) {
                  posPaymentVC.close();
                }
                
                return;
              }

              await Service.VoucherRemoveLimitedRows.promiseRemoveVouchers();
              posPaymentVC.close();
            },
            swipeCard: async function() {
              
              if (this.isDuringSwipe) {
                return;
              }
              this.isDuringSwipe = true;
              await PositiveTS.VueInstance.$nextTick();
              
              try {

                app.showLoadingMessage("העבר כרטיס");
                $('#pos-payment-voucher-barcode').inputField('focus');
                
                let cardNumber = await Service.EMV.swipe()
                this.isDuringSwipe = false;
                if(!posUtils.isNullOrUndefinedOrEmptyString(cardNumber)){
                  $("#pos-payment-voucher-barcode").val(cardNumber);
                }
                app.hideLoadingMessage();
              }
              catch(e)  {
                this.isDuringSwipe = false;
                app.hideLoadingMessage();
                console.error(e);
              }
                
            },
            useSwipe: function(){
              return (jsonConfig.getVal( jsonConfig.KEYS.usePinPadSwipe )) && (session.pos.isEmv)
            },
            async openMultipassPoliceCustomerPickerDialog() {
              await Pinia.componentsStore.openComponent( {componentName:"multipassPoliceCustomerPicker", args: [true]});
            },           

            openModalMultiCurrenciesPaymentDetails () {
              if (!this.modalMultiCurrenciesPaymentDetails){
                this.modalMultiCurrenciesPaymentDetails = document.getElementById(modalMultiCurrenciesPaymentDetailsSelector)
              }
              
              this.modalMultiCurrenciesPaymentDetails.showModal();
            },

            closeModalMultiCurrenciesPaymentDetails () {
              if (!this.modalMultiCurrenciesPaymentDetails){
                this.modalMultiCurrenciesPaymentDetails = document.getElementById(modalMultiCurrenciesPaymentDetailsSelector)
              }
              
              this.modalMultiCurrenciesPaymentDetails.close();
            },
            async openSplitPaymentSelection() {
              await Pinia.componentsStore.openComponent( {componentName:"selectSplitPaymentAmountDialog", args: []});
              this.refreshCurrentView();
            },
            async openSpitSaleSaleSelection(){
              await Service.SplitSalePayment.startSplitSaleProcess();
            }
          },
          
          data: initData,
          setup(){
            const posStore = Pinia.usePosStore();
            const {isPaymentPageActive} = Pinia.storeToRefs(posStore);

            const globalStore = Pinia.useGlobalStore();
            const {
              saleItems,
              isSaleGiftCard,
              mobileLayout,
              salePayments,
              leftAmount,
              paymentCurrency,
              emvRecoveryNeeded,
              emvRecoveryNeededWhenOnline,
              isTenbisOrCibus,
              simpleSelfService,
              isSpecialItemCode,
              currency
            } = Pinia.storeToRefs(globalStore);

            const globalStoreProps = {
              isMobile:mobileLayout,
              saleItems,
              isSaleGiftCard,
              mobileLayout,
              salePayments,
              leftAmount,
              paymentCurrency,
              emvRecoveryNeeded,
              emvRecoveryNeededWhenOnline,
              isTenbisOrCibus,
              simpleSelfService,
              isSpecialItemCode,
              currency
            }


            return {...globalStoreProps,isPaymentPageActive}
          },
          computed: {

            tenbisTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.tenbisTotalAmount)},
            cibusTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.cibusTotalAmount)},
            goodiTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.goodiTotalAmount)},
            banxTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.banxTotalAmount)},
            safeCashTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.safeCashTotalAmount)},
            yaadTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.yaadTotalAmount)},
            interServiceTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.interServiceTotalAmount)},
            caveretPaymentTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.caveretPaymentTotalAmount)},
            multipassPoliceTotalAmount() {return session.fixedNumber(Pinia.globalStore.cibusTenbis.multipassPoliceTotalAmount)},
            allowInvoiceSplit(){return jsonConfig.getVal(jsonConfig.KEYS.allowInvoiceSplit);},
            splitTotalAmountEqually() {return jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually)},
            allowSplitSalePayment() {return Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment))},
            allowGiftCardPaymentWithVoucher(){return jsonConfig.getVal(jsonConfig.KEYS.allowGiftCardPaymentWithVoucher)},
            tenbisTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.tenbisTotalAmount > 0},
            cibusTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.cibusTotalAmount > 0},
            goodiTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.goodiTotalAmount > 0},
            banxTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.banxTotalAmount > 0},
            safeCashTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.safeCashTotalAmount > 0},
            yaadTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.yaadTotalAmount > 0},
            interServiceTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.interServiceTotalAmount > 0},
            caveretPaymentTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.caveretPaymentTotalAmount > 0},
            multipassPoliceTotalAmountVisible() {return  Pinia.globalStore.cibusTenbis.multipassPoliceTotalAmount > 0},
            showPayment() {return  Pinia.globalStore.posState == "payment"},
            voucherEnabled() {return  Pinia.globalStore.voucherEnabled},
            saleHasItems() {
              return this.saleItems && this.saleItems.length > 0
            },

            tipTotalAmount() {
              return session.fixedNumber(Service.Tip.totalSaleItemsTips(this.saleItems));
            },

            paymentLeftRounded() { 
              if(this.paymentType == "cash") {
                return session.fixedFloat(this.paymentLeft, 1);
              } else {
                return session.fixedFloat(this.paymentLeft);
              }
            },

            showGoodi() {
              if(this.paymentMethods.goodi.enabled) {
                if(session.pos.isCaveret) {
                  return true;
                }
                if(this.isTenbisOrCibus || this.allowInvoiceSplit) {
                  return true;
                }
              }
              return false;
            },

            showHakafaMobileButton(){
              return  Pinia.globalStore.mobileLayout && !session.pos.hasFlights && (!session.pos.isRoshemet || (session.pos.isRoshemet && jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos)))
            },
              
            paymentCurrencyName () {
              return i18next.t(`currenciesNames.${this.paymentCurrency}`)
            },

            ratePaymentCurrency () {
              return parseFloat(posPaymentVC.Currencies[this.paymentCurrency]).toFixed(4)
            },  

            totalAmountCurrencyToPay () {
              return Service.MultiCurr.getInstance().checkIfNeedConversionAmountAndReturn(this.leftAmount).toFixed(2)
            },

            currenciesPayment () {
      
                let data = {}
        
                this.salePayments.forEach((p) => {
                  
                  if (p.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CASH){
        
                    let paymentData = JSON.parse(p.data)
        
                    if (paymentData){
        
                      paymentData.forEach((pd) => {
        
                        if (data[pd.paid_currency_symble]){
                          data[pd.paid_currency_symble] += pd.paid_currency_amount
                        }else{
                          data[pd.paid_currency_symble] = pd.paid_currency_amount
                        }
        
                      })
                  
                    }
        
                  }
        
                })
        
                return data
            },

            selectedForeignCurrency () {
              return this.isMultiCurr && this.paymentCurrency && (this.paymentCurrency != this.currency)
            },
          },          
          updated() {
            let posHeader = document.getElementById("pos-payment-header");
            if(posHeader)
              posHeader.scrollLeft = posHeader.scrollWidth;
          }

        }

        VueApp.component('pos-payment',component)
      }
    }
  }
}
