module PositiveTS {
	export module ItemsQueue {

		export async function launchQueueExt() {
			try {
				await launchQueue();
			} catch(error) {
				let msg = "";
				if (error && error.message) {
					msg = error.message;
				}
				if (typeof(error) === "string") {
					msg = error;
				}
				console.error(`Did not send sales to server. Reason: ${msg}`);
			}

			// Schedule the next queue launch
			setTimeout(() => { ItemsQueue.launchQueueExt(); }, 5000);			
		}

	 async function launchQueue() {

			let items = await workerDB.localItems.where('syncStatus').anyOf(
				[Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT, Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED]).toArray()
			
			let allItems = items;

			items = items.filter(items => (items.departmentId != 0 && items.serverDepartmentId != null && items.serverDepartmentId != 0))


			if (items.length == 0) {
				if (allItems.length > 0) {
					let badItems = allItems.filter(item => item.departmentId != 0 && (item.serverDepartmentId == null || item.serverDepartmentId == 0));
					let allDeps = await workerDB.departments.toArray();
					let promises = []
					badItems.forEach(item => {
						let department = allDeps.filter(dep => dep.id == item.departmentId)[0];
						if (department != null) {
							item.serverDepartmentId = department.serverID;
							promises.push(workerDB.localItems.where('code').equals(item.code).modify({serverDepartmentId: department.serverID}));
						}
					})
					await Promise.all(promises);
				}
				return;
			}

			if (!PositiveTS.QueueWorker.Utils.isOnline) { 
				console.debug('Aborting (offline)');
				return;
			}
			
			await sendItemsToServer(items);
			return;
		}

		async function sendItemsToServer(items) {
	
			console.debug('Items to sync: ');
			console.debug(items);
	
			// Send the sales to the remote server
			let url = Shared.Constants.remoteRoot + Shared.Constants.ItemsRemote;
	
			let results = await PositiveTS.QueueWorker.FetchReq.jsonReq(url,"POST",PositiveTS.QueueWorker.Utils.token,{ data: JSON.stringify(items) })
			console.debug(results);
			
			let promises = [];
			// --- If there was an error, the remote server will return an error object
			if ('error' in results.result) {
				await saveFailures(items)
				console.error(results.result.error);
				throw new Error(results.result.error);
			}
			else {
				for (let itemResult of results.result) {

					if (itemResult.success) {
						
						promises.push(workerDB.localItems.where('code').equals(itemResult.code)
						.modify({syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY,invDelta: 0}))
					}
					else {
						//error
						promises.push(workerDB.localItems.where('code').equals(itemResult.code)
						.modify({syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED}))
					}
				}

				await Promise.all(promises);
				return;
			}
		}

		async function saveFailures(items) {
			
			// Mark all sales sync status as failed
			await workerDB.localItems.where('code').anyOf(items.map(item => item.code))
			.modify({ syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED })
			return;
		}

	}
}
