module PositiveTS {
  export module Components {
    export module SelfServiceTenbis {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreen(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.selfServicePaymentMixin("selfService.tenbis", true, {type: Mixins.ICON_TYPES.POSITIVE_ICON, name: 'tenbis'})],
          methods: {
            async paymentAction() {
              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
              let cardNumber = await this.getPaymentCardNumber(amount);
  
              if(!posUtils.isNullOrUndefinedOrEmptyString(cardNumber)){
                this.paymentInProcess = true
                let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(Storage.Entity.Voucher.SMART_VOUCHER_TENBIS, amount,cardNumber);
                this.paymentInProcess = false
                if(response.success){
                  this.goToNextStep();
                }
                else{
                  this.paymentInProcess = true;
                  //not enough balance in card
                  if (posUtils.isPresent(response.rawResponse?.Errors) && response.rawResponse.Errors[0].ErrorCode == 921) {
                    let balanceResponse = await Service.TenbisService.getBalance(cardNumber)
                    let balance  = balanceResponse.Data.MoneycardLimit
                    let answer = await app.showAlertDialog({
                      header: i18next.t('error'),
                      content: i18next.t('selfService.balanceMsg',{balance:String(balance)}),
                      continueButtonText: i18next.t("selfService.balanceMsgOK"),
                    })
                    if(answer == "continue"){
                      let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(Storage.Entity.Voucher.SMART_VOUCHER_TENBIS, balance ,cardNumber);
                      if(response.success){
                        this.goToNextStep();
                      }else{
                        console.error(response.error)
                        app.showAlertDialog({
                          header: i18next.t('error'),
                          content: response.error,
                          continueButtonText: i18next.t("ok"),
                          hideCancelButton: true,
                        })
                        this.paymentInProcess = false
                        this.setCurrentStep("SelfSelectPaymentMethod")
                      }
                    }
                    else{
                      this.setCurrentStep("SelfSelectPaymentMethod")
                    }
                  }
                  else{
                    console.error(response.error)
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: response.error,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                    this.setCurrentStep("SelfSelectPaymentMethod")
                  }
                }
              }
              else{
                this.setCurrentStep("SelfSelectPaymentMethod")
              }
              }
          }
        }
      }
    }
  }
}
