module PositiveTS {
export module Storage {
export module Entity {
export class Airport extends IDBEntity {

	country: string;
	name: string;
	symbol: string;

	constructor() {
		let meta = {
			name: 'Airport',
			fields: {
				country: "TEXT",
				name: "TEXT",
				symbol: "TEXT",
			},
		}
		super(meta)
	}
}}}}
