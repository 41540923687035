
module PositiveTS {
    export module Components {
        export module PositiveButtons {
            declare type CompType = InstanceType<ReturnType<typeof create>>;

            function getDefaultProps() {
                return {
                    items: [] as Array<ReturnType<typeof PositiveTS.Components.PositiveButton.getDefaultProps>>,
                    columns: 1,
                    rows: 1,
                }
            }

            export function create() {
                const defaultProps = getDefaultProps()

                const component = {
                    template: JST.PositiveButtons(),

                    props: {
                        items: {
                            type: Array,
                            default: defaultProps.items,
                        },

                        columns: {
                            type: Number,
                            default: defaultProps.columns,
                        },

                        rows: {
                            type: Number,
                            default: defaultProps.rows,
                        },
                        cssClasses: {
                            type: String,
                            default: '',
                        }
                    },

                    computed: {
                        itemButtons() {
                            let itemButtons = [];

                            if (Array.isArray(this.items)) {
                                const positiveButtonDefaultProps = PositiveTS.Components.PositiveButton.getDefaultProps();
                                itemButtons = this.items.map(item => {
                                    let itemButton = { ...positiveButtonDefaultProps, ...item };
                                    if (itemButton.onClick == undefined) {
                                        itemButton.onClick = () => { };
                                    }

                                    return itemButton;
                                })
                            }

                            return itemButtons;
                        },

                        classes() {
                            let classes = [];

                            if (this.mobileLayout) {
                                classes.push('mobile')
                            }
                            classes.push(this.cssClasses);

                            return classes
                        },

                        mobileLayout(){
                            return Pinia.globalStore.mobileLayout
                          },
                    },

                    mounted() {
                        if (this?.$el?.style) {
                            this.$el.style.setProperty('--positive-buttons-columns', `repeat(${this.columns}, 1fr)`);
                            this.$el.style.setProperty('--positive-buttons-rows', `repeat(${this.rows}, 1fr)`);
                        }
                    }
                }

                VueApp.component('positive-buttons', component);
                return defineComponent(component);
            }
        }
    }
}
