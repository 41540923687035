module PositiveTS {
    export module Service {
      export class Dts extends SmartVoucher {
          dtsBaseUrl = "https://dtsproservices.dts.co.il/DtsPosServices.asmx/"
          //dtsBaseUrl = "http://212.29.215.11/DtsPosServices/DtsPosServices.asmx/"
          _headers() {
            return JSON.stringify({
                "accept": "application/json",
            })
          }

          async getBalance(cardNumber: string, cvv?: string, inputMethod?:number): Promise<any> {
            let terminalID = jsonConfig.getVal(jsonConfig.KEYS.dtsId)
            let url = this.dtsBaseUrl + `GetBalance?Request={"Request":{"ForeignTerminal":"${terminalID}","SearchString": "${cardNumber}"}}`

            var data = JSON.stringify({
              "body": "",
              "type": "GET",
              "url": url,
              "headers":this._headers()
            });
            let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
              { data: data },
              "external_service"
            )

            Filelog.log("DTS",
              JSON.stringify({ request: response.request.body.data, result: response.request.result })
            );

            return JSON.parse((posUtils.xml2json(response.request.result))["string"]["#text"])
          }
          async doRequest(url,request){
            url = url + JSON.stringify(request)
            var data = JSON.stringify({
              "body": "",
              "type": "GET",
              "url": url,
              "headers":this._headers()
            });
            let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
              { data: data },
              "external_service"
            )

            Filelog.log("DTS",
              JSON.stringify({ request: response.request.body.data, result: response.request.result })
            );
            
            response = JSON.parse((posUtils.xml2json(response.request.result))["string"]["#text"])

            return response
          }
          async pay(voucherData: any, amount: number, cvv?: string,companyID?:number,additionalData?): Promise<any> {
            let terminalID = jsonConfig.getVal(jsonConfig.KEYS.dtsId)
            let url = this.dtsBaseUrl + `UseBenefits?Request=`
            let distinctMemberIds = [...new Set(additionalData.items.map(x => x.MemberId))]
            let request = {
              "Request": {
                "ForeignTerminal": terminalID,
                "TerminalNumber": session.pos.deviceID,
                "OriginalRequestId": String((new Date()).getTime())
              },
              "Customer": {
                "OrganizationId": "",
                "OrganizationName": "",
                "MemberId": "", 
                "FirstName": additionalData.customer.FirstName,
                "LastName": additionalData.customer.LastName
              },
              "DtsConfirmationNumber": null,
              "ConfirmationOrganizationId": null,
              "Items": []
            }

            voucherData.data = []
            voucherData.confirmationNumbers = []

            for(let memberId of distinctMemberIds){
              request["Customer"]["MemberId"] = String(memberId)
              for(let item of additionalData.items){
                if(item.qtyToUse > 0){
                  request["Items"].push({
                    "OrganizationId": item.OrganizationId,
                    "FullBarCode": item.FullBarCode,
                    "PosBarcode": "",
                    "Quantity": item.qtyToUse,
                    "Name": item.Name
                  })
                }
              }

              let response = await this.doRequest(url,request)
              if(response.Result.ResultCode != 0){
                for(let prevVoucherData of voucherData.data){
                  this.cancelPayment(prevVoucherData)
                }
                throw new Error(response.Result.ResultCode + " - " + response.Result.ResultFriendlyMessage)
              }else{
                voucherData.data.push(response)
                voucherData.confirmationNumbers.push({DtsConfirmationNumber: response.DtsConfirmationNumber,
                  ConfirmationOrganizationId: response.ConfirmationOrganizationId})
                request["Customer"]["MemberId"] = ""
                request["Items"] = []
              }
            }
            
            

            
            
            voucherData.smartVoucherType = Storage.Entity.Voucher.SMART_VOUCHER_DTS;
            voucherData.voucher_type_id = Pinia.globalStore.selectedVoucherType.typeID;
            voucherData.storeID = session.store.storeID;
            voucherData.deviceID = session.pos.deviceID;
            voucherData.isTamashCustomer = true;
            return voucherData;
          }

          async cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
            let terminalID = jsonConfig.getVal(jsonConfig.KEYS.dtsId)
            let url = this.dtsBaseUrl + `Cancel?Request=`
            let request = {
              "Request": {
                "ForeignTerminal": terminalID,
                "TerminalNumber": session.pos.deviceID
              },
              "DtsConfirmationNumber": null,
              "ConfirmationOrganizationId": null
            }
            let paymentsCanceledSuccessfully = 0;
            for(let confirmationNumber of voucherToCancel.confirmationNumbers){
              request["DtsConfirmationNumber"] = confirmationNumber.DtsConfirmationNumber
              request["ConfirmationOrganizationId"] = confirmationNumber.ConfirmationOrganizationId
              let response = await this.doRequest(url,request)
              if(response.Result.ResultCode == 0){
                paymentsCanceledSuccessfully++; 
              }
              else{
                return {
                  success: false,
                  error: response.Result.ResultCode + " - " + response.Result.ResultFriendlyMessage
                }
              }
            }

            if(paymentsCanceledSuccessfully == voucherToCancel.confirmationNumbers.length){
              return {
                success: true,
                error: ""
              }
            }
          }
          cancelLoad(paymentToCancel: any): Promise<any> {
              throw new Error("Method not implemented.");
          }
          loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
              throw new Error("Method not implemented.");
          }
          getCardNumber(): Promise<GetCardNumberResponse> {
              throw new Error("Method not implemented.");
          }

      }
    }
}