module PositiveTS {
    export module Components {
    export module DepositReport {
      export const route = '/deposit-report';

     

      type CompType = any;

      interface CompData {
        bankDeposits:Array<any>,
        employee:Storage.Entity.Employee,
        page:number,
        itemsPerPage:number,
        totalItems:number,
      }
    
      export function getComponent() {

        return {
            template: JST.depositReport(),
            methods: {
              timeFormat(depositTime) {
                return moment(depositTime).format("DD/MM/YYYY HH:mm");
              },
              newDeposit() {
                let self = this as CompType;
                VueServices.Router.goto(AddOrShowBankDeposit.route, {mode: 'new'}, {employee: self.employee});
              },
              showDeposit(bankDeposit) {
                let self = this as CompType;
                VueServices.Router.goto(AddOrShowBankDeposit.route, {mode: 'show', page: self.page}, {bankDeposit: bankDeposit, employee: self.employee});
              },
              close() {
                pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null);
              },
              async filterItems(initPage = true) {
                let self = this as CompType;
                if (initPage) {
                  this.page = 1;
                }
                try {
                  app.showLoadingMessage(i18next.t('loading'));
                  let response = await Service.FetchReq.jsonReq(`/bank_deposits?page=${self.page}&per=${self.itemsPerPage}`,'get')
                  self.bankDeposits = response.result.bank_deposits;
                  self.totalItems = response.result.meta.total_count;
                  return response;
                }
                finally {
                  app.hideLoadingMessage();
                }
              },
              nextPage() {
                if (((this.page)*this.itemsPerPage) < this.totalItems) {
                  this.page += 1;
                  this.filterItems(false);
                }
              },
              prevPage() {
                if (this.page >= 2) {
                  this.page -= 1;
                  this.filterItems(false);
                }
              },
            },
            setup(){
              const globalStore = Pinia.useGlobalStore();
              const {routerExtraData,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {routerExtraData, mobileLayout} 
  
              return {...globalStoreProps}
            },
            data: ():CompData => {
                return {
                  bankDeposits: [],
                  employee: null,
                  itemsPerPage: 25,
                  page: 1,
                  totalItems: 0,
                };
            },
            
            beforeRouteEnter: async function(to, from, next) {
              try {
                next(async (vm) => {
                  let self:CompType = <CompType>vm;
                  self.page = <any>to.params.page;  
                  await self.filterItems(self.page == null);
                  self.employee = _.cloneDeep(vm.routerExtraData.employee)
                });
              }
              catch(error) {
                app.hideLoadingMessage();
                pNavigator.pushPage('homepage', i18next.t('pageTitle.homepage'), null, null);
              };
            },
    
    
        }
    
      }
    
         
}}}
