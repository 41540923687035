module PositiveTS {
  export module Components {
    export module FlightsCashierStatement {

      export function getComponent() {
        return {
          template: JST.flightsCashierStatement(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, Mixins.barsetMixin],
          methods: {
            finishCount() {
              if (this.verifyData()) {
                this.stepData = { currencies: this.stepData.currencies.map(currency => {
                  currency.amount = PositiveTS.Service.MultiCurr.getInstance().translateAmount(currency.currencyAmount, currency.code)
                  return currency;
                })}

                this.print(true);
                this.goToNextStep();
              } else {
                this.showError(i18next.t('flightsWizard.barsetCount.validationFailed'));
              }
            },
            isValidCountNumber(value) {
              return (!isNaN(parseFloat(value)) && value == parseFloat(value)) && parseFloat(value) >= 0;
            },
            verifyData() {
              this.showErrors = true;

              for (let currencyItem of this.currentCurrencies) {
                if (!this.isValidCountNumber(currencyItem.currencyAmount)) {
                  return false;
                }
              }

              return true;
            },
            nextInput(event) {
              let nextInputIndex = this.$refs.countInput.indexOf(event.target) + 1;

              if (this.$refs.countInput[nextInputIndex]) {
                this.$refs.countInput[nextInputIndex].focus();
                return;
              }

              this.finishCount();
            },
            tenantID() {
              return session.pos.tenantID;
            },
            companyID() {
              return session.pos.companyID;
            },
            baseCurrency() {
              return session.pos.baseCurrency;
            },
            getCurrencyDiff(currency) {
              return ((currency.currencyAmount || 0) - currency.calculated).toFixed(2);
            },
            getCurrencyDiffInBaseCurrency(currency) {
              if (!posPaymentVC.Currencies) {
                return '-';
              }

              return PositiveTS.Service.MultiCurr.getInstance().translateAmount(this.getCurrencyDiff(currency), currency.code).toFixed(2);
            },
            getCurrencyClass(currency) {
              let currencyAmount = parseFloat(currency.currencyAmount || 0);
              let calculated = parseFloat(currency.calculated || 0);

              return this.getAmountClass(currencyAmount - calculated);
            },
            getAmountClass(amount) {
              return amount < 0 ? 'incorrect' : (amount > 0 ? 'correct' : null);
            },
            getTotalDiff() {
              return session.fixedFloat(this.getTotal('currencyAmount', false) - this.getTotal('calculated', false), 2);
            },
            getTotal(param, withFixedFloat = true) {
              let currencies = this.stepData.currencies || [];
              return this.calculateTotal(currencies, param, withFixedFloat);
            },
            calculateTotal(currencies, param, withFixedFloat = true) {
              let total = currencies.reduce((totalSum, currency) =>
                            PositiveTS.Service.MultiCurr.getInstance().translateAmount(Number(currency[param] || 0), currency.code) + Number(totalSum), 0);

              return withFixedFloat ? session.fixedFloat(total, 2) : total;
            },
            async print(isFinal) {
              let currentEmployee = (await Storage.Entity.Employee.fetchByEmployeeID(session.pos.employeeID));

              Printing.FlightReports.printFlightCashierStatement(session.store.storeName,
                this.currentFlight,
                this.wizardData.selectedLeg,
                this.stepData.currencies,
                currentEmployee,
                isFinal,
                this.baseCurrency(),
                {
                  calculated: this.getTotal('calculated'),
                  currencyAmount: this.getTotal('currencyAmount'),
                  diffAmount: this.getTotalDiff(),
                  creditAppendants: this.creditAppendants,
                  creditPaymetsSum: this.creditPaymetsSum,
                },
                isFinal ? 2 : 1);
            }
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight,currentTeamLegs,getPaymentData} = Pinia.storeToRefs(flightsStore);
            return {currentFlight,currentTeamLegs,getPaymentData}
          },
          computed: {
            currentCurrencies() {
              return this.stepData && this.stepData.currencies || [];
            },
            creditAppendants() {
              return this.getPaymentData(Storage.Entity.SalePayment.METHOD_CREDIT).creditCount || 0;
            },
            creditPaymetsSum() {
              return session.fixedFloat(this.getPaymentData(Storage.Entity.SalePayment.METHOD_CREDIT).amount || 0, 2);
            },
          },
          data() {
            return {
              showErrors: false,
              itemsById: {},
              currentStateSalesCount: -1,
            }
          },
          async mounted() {

            app.showLoadingMessage(i18next.t("loading"));

            let currentLegsIds = this.currentTeamLegs.map(leg => leg.id);
            let legsSales = (await appDB.sales.where('invoiceSequence').above(-1).toArray()).filter(sale => currentLegsIds.includes(sale.flightLegId));

            try {
              // Init for the first time
              if (!(this.stepData && this.stepData.currencies)) {
                let currencies = PositiveTS.Service.MultiCurr.getInstance().getPosCurrenciesEnabled().map((c) => {
                  return { code: c, currencyAmount: 0.0, calculated: 0 }
                });

                this.stepData = {
                  currencies
                };
              }

            } catch (err) {
              console.error(err);
              this.showGenericError();
            }

            // next tick becase setting step data cause an emit wich takes time
            this.$nextTick(() => {
              try {

                // Refresh/update calculated amount
                if (legsSales.length != this.currentStateSalesCount) {
                  let cashCalculated = Service.MultiCurrencyPayments.getCurrentCashBySalesAndCurrencies(legsSales);

                  for (let currency of this.stepData.currencies) {
                    currency.calculated = (cashCalculated.currencies[currency.code] && cashCalculated.currencies[currency.code].amount || 0).toFixed(2);
                  }
                }

              } catch (err) {
                console.error(err);
                this.showGenericError();
              }

              app.hideLoadingMessage();
            })
          }
        }
      }
    }
  }
}