module PositiveTS {
  export module Storage {
  export module Entity {
  export class Department {
  

    createTable() {
      return;
    }

    id:number;
    name:string
    syncStatus:number
    serverID:number
    timestamp:number
    markedForDelete:boolean
    sortOrder: number;

    constructor(name) {
      this.name = name;
      this.markedForDelete = false;
      this.sortOrder = 0;
    }
    
    
  }}}}  