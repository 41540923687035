module PositiveTS {
export module Storage {
export module Entity {
export class CreditCardPaymentTypes extends WasmEntity {

	ccTypeID:string
	ccComapnyID:string
	ccTypeName:string
	allowPayments:boolean
	emvTypeCode:number
	
	constructor() {
		let meta = {
			name: 'CreditCardPaymentTypes',
			fields: {
				ccComapnyID: "TEXT",
			  	ccTypeID: "TEXT",
			  	ccTypeName: "TEXT",
			  	allowPayments: "BOOL",
					emvTypeCode: "INT",
			},
			unique: ['ccComapnyID','ccTypeID']
		};
		super(meta)
	}

}}}}
