module PositiveTS {
  export module Components {
      export module EmployeeEditHours {
  
        const dialogSelector = "employee-edit-hours"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            dateFormat: "dd/MM/yyyy",

            minutesRange: Array(60).fill(0).map( (x,i)=>{return {text: (<any>String(i)).padStart(2,"0"), value: i}}),
            hoursRange: Array(24).fill(0).map( (x,i)=>{return {text: (<any>String(i)).padStart(2,"0"), value: i}}),
            entryDate: new Date(),
            entryMin: 0,
            entryHr: 0,
            exitMin: 0,
            exitHr: 0,
            enterMode: false,
            exitMode: false,
            _timeRow: null,
            employeeID: null,
            serverEmployeeID: 0,
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.employeeEditHours(),
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },

              cancel() {                
                this.resolveFunc()
                this.close();
              },
              
              async confirmAndClose() {


                let timeRow:EmployeeHours.RowUi = this._timeRow;

                let entryHr = this.entryHr
                let entryMin = this.entryMin
                let exitHr = this.exitHr
                let exitMin = this.exitMin
                let entryDate:Date = this.entryDate
      

                let enterDate:Date = new Date( entryDate.getTime() )
                let exitDate:Date = new Date( entryDate.getTime() )
                if (entryHr > exitHr){
                  exitDate = moment(exitDate).add(1,"d").toDate();
                }
                enterDate.setHours(entryHr);
                enterDate.setMinutes(entryMin);
                exitDate.setHours(exitHr);
                exitDate.setMinutes(exitMin);
      
               
                if (!this.exitMode) {
                  if (!timeRow.enterRow) {
                    timeRow.enterRow = new Storage.Entity.EmployeeTimeTrack();
                    timeRow.enterRow.employeeID = this.employeeID;
                    timeRow.enterRow.mode = Storage.Entity.EmployeeTimeTrack.MODE_ENTER;

                  }
                  timeRow.enterRow.serverEmployeeID = this.serverEmployeeID;
                  timeRow.enterRow.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  timeRow.enterRow.timestamp = enterDate.getTime();
                  timeRow.enterRow.isManualUpdate = true;
                  await appDB.employeeTimeTracks.put(timeRow.enterRow);
                }
                 
                if (!this.enterMode) {
                  if (!timeRow.exitRow) {
                    timeRow.exitRow = new Storage.Entity.EmployeeTimeTrack();
                    timeRow.exitRow.employeeID = this.employeeID;
                    timeRow.exitRow.mode = Storage.Entity.EmployeeTimeTrack.MODE_EXIT;
                  }
                  timeRow.exitRow.serverEmployeeID = this.serverEmployeeID;
                  timeRow.exitRow.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  timeRow.exitRow.timestamp = exitDate.getTime();
                  timeRow.exitRow.isManualUpdate = true;
                  await appDB.employeeTimeTracks.put(timeRow.exitRow);
                }  
      
                this.resolveFunc()
                this.close();
              },
      
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  this.dialogElem.close();
                }
                this.cleanData()
              },
      
              
        
              open(row: EmployeeHours.RowUi, employeeID, severEmployeeID, mode = 0) {
                switch(mode) {
                  case Storage.Entity.EmployeeTimeTrack.MODE_ENTER:
                    this.enterMode = true
                    break;
                  case Storage.Entity.EmployeeTimeTrack.MODE_EXIT:
                    this.exitMode = true
                    break;
                  default:
                    this.enterMode = false;
                    this.exitMode = false;
                }
                this._timeRow = row;
                this.employeeID = employeeID;
                this.serverEmployeeID = severEmployeeID;
      
                let entryDate = moment();
                
                if (row.enterRow){
                  entryDate = moment(row.enterRow.timestamp)
                } 
                else if (row.exitRow) {
                  entryDate = moment(row.exitRow.timestamp)
                }
                else {
                  entryDate = moment(new Date());
                }
                this.entryDate = entryDate.toDate();
                this.entryMin = 0
                this.entryHr = 0
                this.exitMin = 0
                this.exitHr = 0

                if (row.exitRow || row.enterRow) {
                  if (row.enterRow) {
                    this.entryMin = moment(row.enterRow.timestamp).minutes()
                    this.entryHr = moment(row.enterRow.timestamp).hours()
                  }
        

                  if (row.exitRow){
                    this.exitMin = moment(row.exitRow.timestamp).minutes()
                    this.exitHr = moment(row.exitRow.timestamp).hours()
                  }
                }
      
                return new Promise((resolve,reject) => {
      
                  
                  this.initialized = true;
        
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  this.dialogElem.showModal();
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
            computed: {
            }
          }
  
          VueApp.component('employee-edit-hours-dialog',component)
        }
      }
    }
  }
  