module PositiveTS {
  export module Components {
    export module CreditCreditCardDialog {

      const dialogSelector = "#credit-credit-card-dialog"


      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {

          let isMobile = this.mobileLayout;
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: isMobile ? '95%' : '70%',
              height: isMobile ? 300 : 400,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }
      //clears the component completely - called after sale is closed (cleanSale)
      function cleanData() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let result = initData();
        for (let prop in result) {
          aThis[prop] = result[prop];
        }
      }

      //clears the data after a single payment but when there might be still amount to pay
      function clearOldData() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let result = initData()
        for (let prop in result) {
          if (prop === 'creditPayments' || prop === "amount" || prop === "resolveFunc" || prop === "rejectFunc") {
            continue;
          }
          aThis[prop] = result[prop];
        }

      }

      function clearTapped() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        aThis.clearOldData()
        aThis.selectField('cardNumberText')
      }

      function initFields() {
        let fields = {
          'amount': {
            selected: false,
            allowDecimal: true,
            inEditMode: false,
            type: 'number',
            disabled: true
          },
          'cardNumberText': {
            selected: false,
            inEditMode: false,
            type: 'string',
            allowDecimal: false,
            disabled: false
          },
          'payments': {
            allowDecimal: false,
            selected: false,
            inEditMode: false,
            type: 'number',
            disabled: true
          },
          'phone': {
            selected: false,
            allowDecimal: false,
            inEditMode: false,
            type: 'string',
            disabled: false
          },
          'cvv': {
            selected: false,
            allowDecimal: false,
            inEditMode: false,
            type: 'string',
            disabled: false
          },
          'expDate': {
            selected: false,
            allowDecimal: false,
            inEditMode: false,
            type: 'string',
            disabled: false
          },
          'tz': {
            selected: false,
            allowDecimal: false,
            inEditMode: false,
            tyoe: 'number',
            disabled: false
          }
        }
        for (let field in fields) {
          fields[field].model = field;
        }
        return fields
      }

      function disableFields() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        for (let field in aThis.fields) {
          aThis.fields[field].disabled = true
        }
      }

      function enableFields(originalFieldsState) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        for (let field in aThis.fields) {
          aThis.fields[field].disabled = originalFieldsState[field].disabled;
        }
      }

      function initData() {
        return {
          cardNumber: "",
          cardNumberText: "",
          cvv: "",
          resolveFunc: null,
          rejectFunc: null,
          loading: false,
          expDateText: "",
          payments: 1,
          amount: 0,
          phone: "",
          tz: "",
          usePinPad: session.pos.usePinPad,
          manualConfirmationNumber: null,
          fields: initFields(),
          clearImg: `${(<any>window).images_path}/close.png`,
          creditPayments: []
        }
      }

      function selectField(fieldName) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let refField = null;
        //not liking this code so much... but want to make sure that number will be rounded
        aThis.amount = session.fixedFloat(aThis.amount);
        for (let field in aThis.fields) {
          aThis.fields[field].selected = false
          aThis.fields[field].inEditMode = true
          aThis.fields[field].hasDecimal = false
          if (field == fieldName) {
            aThis.fields[field].selected = true
            refField = field
          }
        }
        if (refField != null && !aThis.usePinPad) {
          aThis.$refs[refField].focus();
        }
      }

      function onKeyTapped(key) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog

        let field = getSelectedField();
        aThis.keyPadHanlder(key,field,aThis);
      }

      function getSelectedField() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        for (let fieldName in aThis.fields) {
          if (aThis.fields[fieldName].selected) {
            return aThis.fields[fieldName]
          }
        }
        return undefined;
      }

      function refreshView() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
        let amountLeft = totals.totalAmount - totals.totalPaid;

        if (aThis.amount != amountLeft) {
          aThis.amount = session.fixedFloat(amountLeft);
        }
        aThis.selectField('cardNumberText')

      }

      function verifyAmount() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog

          let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
          let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);

          if (aThis.amount > 0) {
          showAlertGeneric(i18next.t('newCreditCard.AMOUNT_MUST_BE_SMALLER_THAN_ZERO'))
          return false
          }
          else if (aThis.amount > amountLeft) {
          showAlertGeneric(i18next.t('generalPaymentLeftAmountMandatoryError'))
          return false
          }

        return true
      }

      function verifyPayments() {
        return new Promise((resolve,reject) => {
          let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
          if (aThis.paymentsTypeSelected) {
            let creditTypeID = aThis.creditCardPaymentTypes.filter((ccpt) => {return ccpt.selected === true})[0].ccTypeID
            let numberOfPaymentsAuthorizedForAmount =
            paymentLimit.creditCardNumberOfPaymentsAuthorizedForAmount( aThis.amount, creditTypeID !== 3 );
            if (aThis.payments == 0) {
              showAlertGeneric(i18next.t('creditCardPaymentPaymentsMandatoryError'),reject)
            } else if (aThis.payments > session.pos.parameterMaxCreditCardPayments) {
              showAlertGeneric(
                i18next.t('creditCardPaymentPaymentsMaxMandatoryError', {COUNT: session.pos.parameterMaxCreditCardPayments}),
                reject)
            } else if (aThis.payments > numberOfPaymentsAuthorizedForAmount) {
              showAlertGeneric(i18next.t('creditCardPaymentPaymentsMaxForAmountError', 
                {COUNT: paymentLimit.lastQueryOfCreditCardNumberOfPaymentsAuthorizedForAmount }),reject)
            }
            else {
              resolve()
            }
          }
          else {
            resolve()
          }
        })
      }

      function verify() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let verificationResult = verifyAmount()
        if(verificationResult && !aThis.usePinPad){
          verificationResult =  verifyExpDate()
          }
        return verificationResult
      }

      function cardChanged() {
        if (this.cardNumberText.indexOf('*') !== -1) { //card was already swiped and we just pressed enter...
          return this.verifyAndPay();
        }
        if (this.cardNumberText.indexOf('=') !== -1) { //swiped

          this.cardNumber = this.cardNumberText;
          let formattedValue = ''
          if (this.cardNumberText.length > 8) {
            formattedValue += this.cardNumberText.substring(0,8);

            for (let i = 7; i < this.cardNumberText.length; i++) {
              formattedValue += '*';
            }
          }
          this.cardNumberText = formattedValue
          return this.verifyAndPay();
        }
        else { //manual
          this.cardNumber = this.cardNumberText;
          this.selectField('expDate')
        }
      }

      async function verifyAndPay() {
          if (this.cardNumberText.indexOf('*') === -1) {
            this.cardNumber = this.cardNumberText;
          }

          let verificationResult = verify()

          if(verificationResult){
            await createPaymentAndPay()
            clearOldData();
          }
      }

      function verifyExpDate() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
          if (aThis.swiped) {
            return true
          }

          if (aThis.expDate === "" || !aThis.expDate) {
            showAlertGeneric(i18next.t('newCreditCard.EXP_DATE_MISSING'))
            return false
          }

          let expDate = moment(aThis.expDate, 'MM/YY').endOf('month')
          if (expDate <= moment(new Date())) {
            showAlertGeneric(i18next.t('creditCardPaymentExpDateOverMandatoryError'))
            return false
          }

          return true
      }

      function createPaymentAndPay() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let orignialFieldsState = JSON.parse(JSON.stringify(aThis.fields));
        aThis.disableFields()
        app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
        return new Promise((resolve,reject) => {
          return aThis.pay()
          .then(() => {
            aThis.enableFields(orignialFieldsState)
            resolve()
          })
          .catch(err => {
            app.hideLoadingMessage()
            aThis.enableFields(orignialFieldsState)
            if (err) {
              console.error(err);
              showAlertGeneric(err || i18next.t('creditCardPaymentError'),reject)
            }
          });
        })

      }

      function getSalePayment() {
        let salePayment = posVC.salePayments.filter(sp => {
          return (sp.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT)
        })[0]


        if (!salePayment) {
          salePayment = new PositiveTS.Storage.Entity.SalePayment();
          salePayment.saleID = posVC.sale.id;
          salePayment.amount = 0;
          salePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT;
        }

        return salePayment;
      }

      function updateViewAndGlobalState(salePayment) {

        let inserted = false
        for (let i=0; i < posVC.salePayments.length; i++) {
          if (posVC.salePayments[i].method == salePayment.method) {
            posVC.salePayments[i] = salePayment;
            inserted = true;
            break;
          }
        }

        if (!inserted) {
          posVC.salePayments.push(salePayment)
        }
        refreshView()
        posPaymentVC.updateAmountsIndicators()
      }

      function askForVerificationCode() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        return new Promise((resolve,reject) => {
          app.hideLoadingMessage()
          let dg = new PositiveTS.Dialogs.InputDialog()
          let options = new PositiveTS.Dialogs.InputDialogOptions()
          options.header = 'נדרש אישור מחברת האשראי'
          options.description = 'נא הקלד מספר אישור שהתקבל מחברת האשראי או לחץ על ביטול'
          options.showCancelButton = true
          dg.open(options)
          .then(result => {
            aThis.manualConfirmationNumber = result
            resolve()
          })
          .catch(err => {reject('פעולה בוטלה')})
        })

      }

      function getCardDetails() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog

        var creditCurrency = undefined;
        var creditLeadCurrencyAmount = undefined;
        // if MC supported get posPaymentVC.PaymentCreditSelectedCurrency.getVal()
        if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
          creditCurrency = posPaymentVC.PaymentCreditSelectedCurrency.getVal();
          creditLeadCurrencyAmount = posPaymentVC.moduleMultiCurrencyCredit.getAmountTranslatedIfRequired(aThis.amount);
        }

        let cardDetails = {
          'amount'    : aThis.amount,
          'cardNumber'   : aThis.cardNumber,
          'creditType' : 1,
          'experDate' : aThis.expDate || "",
          'phoneNumber': aThis.phone || "",
          'ccv' : aThis.cvv || "",
          'cardPayments'   : String(1),
          'confNumber' : aThis.manualConfirmationNumber || "",
          'creditCurrency': creditCurrency,
          'creditLeadCurrencyAmount': creditLeadCurrencyAmount,
          'tz': aThis.tz || ""
        }

        return cardDetails;

      }

      function pay() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog

        return new Promise((resolve,reject) => {

          app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
          //create the cardDetails object and call debit credit card...
          debitCreditCard(getSalePayment(), getCardDetails())
          .catch(result => { //TODO: this should not be catch... it is a normal response - not an execption
            //if code is 003 - ask for confirmation number
            if (result && result.return_code && result.return_code === "003") {
              return askForVerificationCode()
              .then(() => {
                app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
                return debitCreditCard(getSalePayment(), getCardDetails())
              })
            }
            else {
              throw result.error_message
            }
          })
          .then((result) => {
            app.hideLoadingMessage();
            aThis.resolveFunc(result)
            aThis.close()
          })
          .catch(error => {
            app.hideLoadingMessage();
            console.error(error)
            showAlertGeneric(error,reject)
          });
        })


      }

      function debitCreditCard(salePayment, cardDetails) {
        return new Promise((resolve,reject) => {
          PositiveTS.Service.CreditCard.creditCreditCardPayment(
            cardDetails.cardNumber,
            Math.abs(cardDetails.amount),
            1,
            '0',
            1,
            cardDetails.experDate,
            cardDetails.phoneNumber,
            cardDetails.tz,
            undefined,
            (returnedArray) => {


              if (returnedArray[0].success === true) {
                resolve({ salePayment: salePayment, cardDetails: returnedArray[0] });
              } else {
                reject(returnedArray[0]);
              }
            },
            (err) => {
              reject(err.statusText);
            }
            );  
        })

      }

      function showAlertGeneric(message, okCallback = null, cancelCallback = null) {
        app.showAlert({
          header: i18next.t('error'),
          content: message,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        }, okCallback, cancelCallback);
      }

      function open(amount) {
        return new Promise((resolve,reject) => {
          this._initjQueryDialogIfNeeded()
          $(document).unbind('keypress');
          $(dialogSelector).dialog('open');
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.amount = amount;
        })
      }

      function close() {
        $(dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
        this.cleanData();
      }

      function cancel() {
        this.resolveFunc(false)
        this.close()
      }

      function msrAutomatic() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        clearOldData()
        app.showLoadingMessage(i18next.t("newCreditCard.PASS_CARD_AUTOMATIC_MESSAGE"))
        PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
        {
          portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
          inputType: "4", //MSR Only
          amount:String(Math.abs(aThis.amount*100))
        },
        "card_reader")
        .then(result => {
          app.hideLoadingMessage()
          parseAutomaticResponse(result)
        })
        
      }

     function emv(mode, verificationCode?) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        let selectedPaymentType = { emvTypeCode:1, ccTypeID:"1", ccTypeName:"רגיל" }

        Service.EMV.payWithCreditCard(Math.abs(aThis.amount), mode, selectedPaymentType, aThis.payments, verificationCode,"53")
        .then(response => {
          if (response.success) {
            app.hideLoadingMessage();
            let creditCardSalePayment = new Storage.Entity.SalePayment();
            creditCardSalePayment.method = Storage.Entity.SalePayment.METHOD_CREDIT;
            creditCardSalePayment.saleID = posVC.sale.id;
            creditCardSalePayment.amount = -response.result.amount;
            creditCardSalePayment.data = JSON.stringify([response.result]);

            posVC.salePayments.push(creditCardSalePayment);
            // }

            Service.FullSale.saveCurrentSale()
            .then(() => {
              aThis.resolveFunc(response.result)
              aThis.close()
            })
          }
          else {
            if (Service.EMV.manualConfirmationNumberRequired(response.result)) {
              askForVerificationCode()
              .then((verifcationCodeResult) => {
                return emv(mode, verifcationCodeResult)
              })
              .catch(error => {
                showAlertGeneric(error)
              })
            }
            else if (Service.EMV.isTimeoutResponse(response.result)) {
              aThis.showAlertGeneric(i18next.t('newCreditCard.timeout')); 
            }
            else if (Service.EMV.oldFileExists(response.result)) {
              aThis.showAlertGeneric(response.error + ".\nלחץ אישור לביצוע שידור",() => {
                Service.EMV.transmitAndDeleteTranIfNeeded();
              })
            }
            else if (Service.EMV.oldTranNotEmpty(response.result)) {
              aThis.showAlertGeneric(response.error + ".\nיש לבצע סגירת יום לפני שניתן להמשיך לבצע עסקאות אשראי נוספות.")
            }
            else {
              showAlertGeneric(response.error)
              return;
            }
          }
        })
        .catch(error => {
          app.hideLoadingMessage();
          console.error(error)
          showAlertGeneric(error)
        });

      }

      function getCardAutomatic() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        if(verify()){
          if (session.pos.isEmv) {
            emv(Service.EMV.PINPAD_DEAL)
          }
          else {
            msrAutomatic()
          }
        }
      }

      function getCardManual() {
          if(verify()){
            if (session.pos.isEmv) {
              emv(Service.EMV.PHONE_DEAL)
            }
            else {
              msrManual()
            }
          }
        }

      function msrManual() {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        clearOldData()
        app.showLoadingMessage(i18next.t("newCreditCard.PASS_CARD_MANUAL_MESSAGE"))
        PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
        {
          portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
          inputType: "8", //Keypad Only
          amount:String(aThis.amount*100)
        },
        "card_reader")
        .then(result => {
          app.hideLoadingMessage()
          parseManualResponse(result)
        })
      }

      function parseAutomaticResponse(response) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        return new Promise( (resolve,reject) => {
          let responseObj = JSON.parse(response.request.result.jsonResponseStr)
          let statusCode = response.request.result.statusCode
          if (statusCode != 0) {
            displayStatusError(statusCode, responseObj)
            .then(reject);
          }
          else {
            //parse response and goto payment!
            aThis.cardNumber = responseObj.ResponseGetData.Track2;
            createPaymentAndPay()
            .then(resolve)
          }
        })

      }

      function parseManualResponse(response) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        return new Promise( (resolve,reject) => {
          let responseObj = JSON.parse(response.request.result.jsonResponseStr)
          let statusCode = response.request.result.statusCode
          if (statusCode != 0) {
            displayStatusError(statusCode, responseObj)
            .then(reject);
          }
          else {
            //parse response, verify it, and goto payment!
            let str = responseObj.ResponseGetData.Track2;
            aThis.cardNumber = str.slice(1,str.indexOf("T"))
            aThis.cvv = str.slice(str.indexOf("U")+1,str.length)
            aThis.expDate = str.slice(str.indexOf("T")+1,str.indexOf("T")+3) + '/' + str.slice(str.indexOf("T")+3,str.indexOf("T")+5)
            if(verifyExpDate()){
              createPaymentAndPay().then(resolve)
            }
          }
        })

      }

      function displayStatusError(statusCode, responseObj) {
        return new Promise((resolve,reject) => {
          switch (statusCode) {
            case 10000:
            showAlertGeneric(i18next.t('newCreditCard.RETVAL_TIMEOUT'),resolve);
            case 10001:
            case 10002:
            showAlertGeneric(i18next.t('newCreditCard.RETVAL_ERROR_OPENING_SERIAL_PORT'),resolve);
            break;
            case 10003:
            console.debug(responseObj);
            if (responseObj && responseObj.ResponseGetData && responseObj.ResponseGetData.ResultCode) {
              if (responseObj.ResponseGetData.ResultCode == "210") {
                resolve()
              }
              else {
                showAlertGeneric(getMsrError(responseObj.ResponseGetData.ResultCode),resolve)
              }
            }
            else {
              showAlertGeneric(i18next.t('newCreditCard.GENERAL_ERROR'),resolve)
            }
            break;
            default:
            console.error(`general error in card reader - code is ${statusCode}`)
            showAlertGeneric(i18next.t('newCreditCard.GENERAL_ERROR'),resolve)
          }
        })

      }

      function getMsrError(resultCode) {
        console.warn('reader input problem. result code was:' + resultCode)
        switch (resultCode) {
          case '201':
          case '202':
          case '203':
          case '204':
          case '205':
          case '206':
          case '207':
          case '208':
          case '209':
          case '210':
          case '211':
          case '212':
          return i18next.t(`newCreditCard.msr[${resultCode}]`)
          default:
          return i18next.t("newCreditCard.msr.generalError")
        }

      }

      function getSignature(salePayment, amountToDisplay = null) {
        let aThis = PositiveTS.VueInstance.$refs.creditCreditCardDialog
        return new Promise((resolve,reject) => {
          if (!Boolean(session.pos.storeSignature)) {
            resolve(salePayment)
          }
          else {
            app.showLoadingMessage(i18next.t("newCreditCard.NEED_SIGNATURE"))
            PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
            {
              portNumber: jsonConfig.getVal(jsonConfig.KEYS.pinPadPortNumber),
              getSignature: true,
              amount:String(amountToDisplay || aThis.amount)
            },
            "card_reader")
            .then(response => {
              let responseObj = response.request.result;
              console.log(response.request.result);
              if (response.request.result.signatureData === "" || response.request.result.statusCode != 0) {
                return getSignature(salePayment, amountToDisplay)
                .then(resolve);
              }
              else {
                let data = JSON.parse(salePayment.data);
                data[data.length-1].signature = response.request.result.signatureData;
                salePayment.data = JSON.stringify(data);
                Service.FullSale.saveCurrentSale()
                .then(resolve)
              }
            })

          }
        })
      }


      export function create() {
        var component = {
          template: JST.creditCreditCardDialog(),
          mixins: [keyPadMixin,Mixins.modalsMixin, Mixins.oneClickMixin],
          methods: {
            cleanData: cleanData,
            refreshView: refreshView,
            disableFields: disableFields,
            enableFields: enableFields,
            verifyAndPay: verifyAndPay,
            cancel: cancel,
            close: close,
            pay: pay,
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            selectField: selectField,
            getCardAutomatic: getCardAutomatic,
            getSignature: getSignature,
            getCardManual: getCardManual,
            cardChanged: cardChanged,
            clearOldData: clearOldData,
            clearTapped: clearTapped,
            createPaymentAndPay: createPaymentAndPay,
            onKeyTapped: onKeyTapped,
          },
          data: initData,
          computed: {
            'expDate': {
              set: function(newValue: any) {
                if (!this.usePinPad) {
                  //force jquery.mask plugin to work and then get the value into the model
                  $(this.$refs.expDate).val(newValue);
                  $(this.$refs.expDate).trigger(jQuery.Event("keyup.mask"))
                  this.expDateText = $(this.$refs.expDate).val();
                }
                else {
                  this.expDateText = newValue
                }
              },
              get: function() {
                return this.expDateText
              }
            },
            'swiped': function() {
              return (this.cardNumber.indexOf('=') !== -1)
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }

        }

        VueApp.component('credit-credit-card-dialog',component)
      }
    }
  }
}
