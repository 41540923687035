module PositiveTS {
  export module Components {
      export module AlertDialog {
  
        export const okResult = "continue";
        export const cancelResult = "cancel"
  
        function initData() {
          return {
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            header: "",
            content: "",
            imageUrl: null,
            imageHeight: null,
            hideCancelButton: false,
            hideContinueButton:false,
            continueButtonText: "",
            cancelButtonText: "",
            cancelCallback: null,
            continueCallback: null,
            isRestaurantNewPOS: Service.Dalpak.isRestaurantNewPOS()
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.alertDialog(),
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              cancel() {     
                if(this.cancelCallback != null){
                  this.cancelCallback();
                }           
                this.resolveFunc(cancelResult);
                this.close();
              },
              
              async confirmAndClose() {
                if(this.continueCallback != null){
                  this.continueCallback();
                }
                  this.resolveFunc(okResult);
                  this.close();
              },
      
              close() {
                this.$el.close();
                this.cleanData();
              },

              
        
              open() {
                return new Promise((resolve,reject) => {      
                  if(!this.$el.open){
                    this.$el.showModal();
                  }
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {onSelfServicePaymentDialog,simpleSelfService} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {selfService:onSelfServicePaymentDialog,simpleSelfService} 

              return {...globalStoreProps}
            },
            computed: {
              getBtnClasses(){
                if(this.selfService){
                  return ["positive-simple", "self-service-btn"]
                }
                else{
                  return ["positive-simple-button"]
                }
              }
            }
          }
  
          VueApp.component('alert-dialog',component)
        }
      }
    }
  }
  