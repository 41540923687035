module PositiveTS {

    export module Components {
    export module FeatureOrder {
        export module IndexMobilePhoneRepairs {
          
          export const route = "index-mobile-phone-repairs"
          export const name = "IndexMobilePhoneRepairs"
          const type = "MobilePhoneRepair"

          interface FutureOrder {
            customer_id: number,
            notes: string
          }

          interface MobilePhoneRepair {
            additional_phone_number: string, 
            device_type: string, 
            imei: string, 
            device_password: string, 
            color: string, 
            repair_estimated_cost: number,
            additional_status: number,
            additional_notes_attribute: Array<any>,
            status: number|string,
          }

          export function getComponent() {
  
            return {
  
              template: JST.IndexMobilePhoneRepairs(),

              computed :{
                filteredItems () {
                  let filteredItems = []
                  if (Array.isArray(this.items)){
                    filteredItems = this.items.map((item) => {
                      return item
                    })
                  }

                  return filteredItems
                },
              },
              setup(){

                const globalStore = Pinia.useGlobalStore();
                const {mobileLayout,posTabMode} = Pinia.storeToRefs(globalStore);
                const globalStoreProps = {mobileLayout,posTabMode}
  
                return {...globalStoreProps}
              },
              methods: {
                ...Mixins.modalsMixin.methods,
                
                getCustomerName (item) {
                  let customer = ''
                  if (!posUtils.isBlank(item.customer.first_name)){
                    customer += item.customer.first_name
                  }

                  if (!posUtils.isBlank(item.customer.last_name)){
                    customer += ` ${item.customer.last_name}`
                  }

                  return customer
                },

                async fetchFutureOrders (showLoadingMessage = true, returnDataOnly = false){
                  try {
                    if (showLoadingMessage){
                      app.showLoadingMessage(i18next.t('indexMobilePhoneRepairs.loadingFutureOrders'))
                    }
                    let res = await PositiveTS.Service.FutureOrder.all(type, this.filter)
                    if (!res.success){
                      throw new Error('Error on loading future orders')
                    }
                    let data = res && res.data ? res.data : {}
                    const futureOrders = data.future_orders || []
                    const futureOrdersCount = data.future_orders_count || 0
                    if (returnDataOnly){
                      if (showLoadingMessage){
                        app.hideLoadingMessage()
                      }
                      return data
                    }
                    
                    this.items = futureOrders
                    this.totalItems = futureOrdersCount
                    this.selectedFutureOrder = futureOrders.length > 0 ? futureOrders[0] : {}
                    if (showLoadingMessage){
                      app.hideLoadingMessage()
                    }
                  }catch(error){
                    console.error(error)
                    if (showLoadingMessage){
                      app.hideLoadingMessage()
                    }
                    this.showAlertGeneric(i18next.t('indexMobilePhoneRepairs.errorOnFetchFutureOrders'))
                  }
                }, 

                updateTabTitle(){
                  this.$emit("input",i18next.t('indexMobilePhoneRepairs.title'))
                },

                getClassTableCell (item, index) {
                  let className = 'even'
                  if (_.isEmpty(this.selectedFutureOrder)){
                    if (index == 0){
                      className = 'odd'
                    }
                  }else{
                    className = this.selectedFutureOrder.id == item.id ? 'odd' : 'even'
                  }
                  return className
                },

                editMobilePhoneRepair () {
                  let statusId = this.selectedFutureOrder.orderable.additional_status
                  if ([PositiveTS.Service.MobilePhoneRepair.statuses.completed, PositiveTS.Service.MobilePhoneRepair.statuses.cancel].includes(statusId)){
                    this.showAlertGeneric(i18next.t(`indexMobilePhoneRepairs.cannotEditMobilePhoneRepairOnStatus`, {status: this.getStatusName(statusId)}))
                    return
                  }

                  if(this.posTabMode){
                    pNavigator.pushPage('pages', '', null, { initParams: { route: '/cellular-tab',innerComponent: 'MobilePhoneRepair',id: this.selectedFutureOrder.id, mode: 'edit'} })
                  }
                  else{
                    VueServices.Router.goto(MobilePhoneRepair.name, {id: this.selectedFutureOrder.id, mode: 'edit'})
                  }

                },

                showMobilePhoneRepair () {
                  if(this.posTabMode){
                    pNavigator.pushPage('pages', '', null, { initParams: { route: '/cellular-tab',innerComponent: 'MobilePhoneRepair',id: this.selectedFutureOrder.id, mode: 'show'} })
                  }
                  else{
                    VueServices.Router.goto(MobilePhoneRepair.name, {id: this.selectedFutureOrder.id, mode: 'show'})
                  }
                },

                printMobilePhoneRepair () {
                  PositiveTS.Service.MobilePhoneRepair.print(this.selectedFutureOrder)
                }, 

                getStatusName (statusId) {
                  const status = this.statuses.find((s) => s.id == statusId)
                  return i18next.t(`mobilePhoneRepair.statuses.${status.name}`)
                },

                openNewMobilePhoneRepair () {
                  if(this.posTabMode){
                    pNavigator.pushPage('pages', '', null, { initParams: { route: '/cellular-tab',innerComponent: 'MobilePhoneRepair' } })
                  }
                  else{
                    pNavigator.pushPage('pages', '', null, { initParams: { route: PositiveTS.Components.FeatureOrder.MobilePhoneRepair.name } })
                  }
                },

                async addMobilePhoneRepairToSale () {
                  try{
                    let statusId = this.selectedFutureOrder.orderable.additional_status
                    if ([PositiveTS.Service.MobilePhoneRepair.statuses.completed, PositiveTS.Service.MobilePhoneRepair.statuses.cancel].includes(statusId)){
                      this.showAlertGeneric(i18next.t(`indexMobilePhoneRepairs.cannotAddMobilePhoneRepairToSaleOnStatus`, {status: this.getStatusName(statusId)}))
                      return
                    }

                    let jsondata = JSON.parse(posVC.sale.jsondata)
                    jsondata.mobilePhoneRepairEstimatedCost = this.selectedFutureOrder.orderable.repair_estimated_cost
                    if (posVC.saleItems.length > 0 || !_.isEmpty(jsondata.customer)){
                      this.showAlertGeneric(i18next.t('indexMobilePhoneRepairs.cannotAddMobilePhoneRepairToSale'))
                      return
                    }

                    app.showLoadingMessage(i18next.t('indexMobilePhoneRepairs.addMobilePhoneRepairToSale'))
                    posVC.sale.futureOrderId = this.selectedFutureOrder.id
                    posVC.sale.jsondata = JSON.stringify(jsondata)

                    await Service.CustomerClub.putCustomerInSale(this.selectedFutureOrder.customer)
                    try {
                      app.hideLoadingMessage()
                      await posVC.addItemByBarcodeOrByCode(jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairItemCode), true)
                    }catch(error){
                      console.error(error)
                      this.showAlertGeneric(i18next.t('indexMobilePhoneRepairs.cannotAddMobilePhoneRepairToSaleMissItemRepair'))
                      posVC.restartSale()
                      return
                    }
                    
                    this.close()
                  }catch(error){
                    console.error(error)
                    app.hideLoadingMessage()
                    this.showAlertGeneric(i18next.t('indexMobilePhoneRepairs.cannotAddMobilePhoneRepairToSaleGenralError'))
                  }
                },

                async mobilePhoneRepairCancelWithoutPay () {
                  let statusId = this.selectedFutureOrder.orderable.additional_status
                  if ([PositiveTS.Service.MobilePhoneRepair.statuses.completed, PositiveTS.Service.MobilePhoneRepair.statuses.cancel].includes(statusId)){
                    this.showAlertGeneric(i18next.t(`indexMobilePhoneRepairs.cannotUpdateMobilePhoneRepairCancelWithoutPayOnStatus`, {status: this.getStatusName(statusId)}))
                    return
                  }

                  try {
                    app.showLoadingMessage(i18next.t('indexMobilePhoneRepairs.updateMobilePhoneRepairCancelWithoutPay'))
                    let futureOrder = _.cloneDeep(this.selectedFutureOrder)
                    futureOrder.status = PositiveTS.Service.FutureOrder.statuses.cancel
                    futureOrder.orderable.additional_status = PositiveTS.Service.MobilePhoneRepair.statuses.cancel
                    let res = await PositiveTS.Service.FutureOrder.update(futureOrder.id, PositiveTS.Service.MobilePhoneRepair.orderableType, futureOrder)
                    if (!res.success){
                      throw new Error('Error on mobilePhoneRepairCancelWithoutPay')
                    }

                    let futureOrderIndex = this.items.findIndex((fo) => fo.id == this.selectedFutureOrder.id)
                    this.items[futureOrderIndex] = res.data.future_order
                    this.selectedFutureOrder = res.data.future_order
                    app.hideLoadingMessage()
                  }catch(error){
                    console.error(error)
                    app.hideLoadingMessage()
                    this.showAlertGeneric(i18next.t('indexMobilePhoneRepairs.errorOnUpdateMobilePhoneRepairCancelWithoutPay'))
                  }
                },

                async scroll (type: 'up'|'down'|'scrollOutsideButton') {
                  let navigationNumber = 120

                  if (type != 'scrollOutsideButton'){
                    if (type == 'up'){
                      this.$refs.content.scrollTop = this.$refs.content.scrollTop - navigationNumber
                    }else{
                      
                      this.$refs.content.scrollTop = this.$refs.content.scrollTop + navigationNumber
                    }
                  }
                  
                  if ((this.$refs.content.scrollHeight - this.$refs.content.scrollTop == this.$refs.content.clientHeight) && this.totalItems > this.items.length){
                    this.filter.page += 1
                    let res = await this.fetchFutureOrders(true, true)
                    const futureOrders = res.future_orders || []
                    this.items.push(...futureOrders)
                    this.$nextTick(() => {
                      this.$refs.content.scrollTop = this.$refs.content.scrollTop + navigationNumber
                    })
                  }
                },

                close () {
                  PositiveTS.VueInstance.goToPos()
                },


                async search (event) {
                  this.filter.search = event.target.value
                  this.filter.page = 1
                  if (this.searchDebounce.cancel) {
                    this.searchDebounce.cancel()
                  }
                  
                  this.searchDebounce(false)
                },
              },
  
              data() {
                let dataVal :{
                  items: Array<any>,
                  totalItems: number,
                  selectedFutureOrder: any,
                  statuses: typeof Service.MobilePhoneRepair.statusArray,
                  imagesPath: string,
                  filter: {page: number, per: number, search: string},
                  searchDebounce: any,
                } = {
                  items: [],
                  totalItems: 0,
                  selectedFutureOrder: {},
                  statuses: [...Service.MobilePhoneRepair.statusArray],
                  imagesPath: window['images_path'],
                  filter: {page: 1, per: 25, search: ''},
                  searchDebounce: null,
                }
                return dataVal;
              },
          
              async created () {
                await this.fetchFutureOrders()
                this.searchDebounce = _.debounce(this.fetchFutureOrders, 200)
                this.updateTabTitle()
              },
            }
  
          }
          
      }
    }
    }
  }

