module PositiveTS {
export module Service {
export module BeengoService {

  export const _baseUrl = 'https://beengo.me:8443/BeengoWebService/rest/PointOfSaleService'
  


  export function createPayWithBeengoAndClose():Promise<any>{
    var voucherId = jsonConfig.getVal(jsonConfig.KEYS.beengoVoucherId);
    return posVC.goToPaymentScreen()
     .then( () => {
       PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('voucher')
       var voucher = new PositiveTS.Storage.Entity.Voucher();
       return voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);
     })
     .then( (result) => {
       for (let voucher of result){
         if (voucher.typeID === voucherId && voucher.smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BEENGO){
           return voucher;
         }
       }
       throw new Error("No smart voucher Beengo/Hakafa defined");
     })
     .then(async (voucherToAdd) => {
       //TODO: all this shit needs to move to an action that gets the voucher, creates the payment if applicable, and pays with it
       Pinia.globalStore.setSelectedVoucherType(voucherToAdd);
        let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);

        PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.amount = totals.totalAmount-totals.totalPaid;
        PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.$refs.amountField.focus();

        await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.verifyAndPay();

     });
  }




  export function _cancelRequestPurchaseEvent(posTransactionId:string, purchaseEventId:string): Promise<boolean> {

     const url = `${_baseUrl}/cancelPurchaseEvent`;
     const requestType = "POST";
     const headers = JSON.stringify({
         "Content-Type": "application/json; charset=utf-8"
     })

     var requestJson = JSON.stringify({
         posTransactionId: posTransactionId,
         purchaseEventId: purchaseEventId
     });


     var data = JSON.stringify({
         "body": requestJson, "type": requestType,
         "url": url,
         "headers": headers
     });

     return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
         { data: data },
         "external_service"
     ).then((x) => {
         PositiveTS.Service.Filelog.log("beengo",
             JSON.stringify({ request: x.request.body.data, result: x.request.result })
         );
         if (x.request.result.startsWith("ERROR:")) { throw new Error(x.request.result) }

         if (!x.request.result) {return false;}
         return JSON.parse(x.request.result);
     });

  }

  export class _RepeatCheckReponse {
    private _timeOutQuit = false;
    private _exitTimeOut = jsonConfig.getVal(jsonConfig.KEYS.beengoTimeout);
    private _tryEvery = jsonConfig.getVal(jsonConfig.KEYS.beengoCheckEvery);

    getPurchaseEventLoop(purchaseEventId, posTransactionId):Promise<_GetPurchaseEventItf>{
      app.showLoadingMessage(i18next.t("beengo.waitingForUserApproval"))
      this._promiseTimeout(this._exitTimeOut).then( ()=>{
        this._timeOutQuit = true;
      })

      return _getPurchaseEvent(purchaseEventId)
      .then ( (response:_GetPurchaseEventItf) =>{
        if (!this._timeOutQuit && (!response || response.status === 0)) {
          return this._promiseTimeout(this._tryEvery)
          .then( ()=>{
            return this.getPurchaseEventLoop(purchaseEventId, posTransactionId);
          })
        }

        if (this._timeOutQuit){
          _cancelRequestPurchaseEvent(posTransactionId, purchaseEventId);
        }

        app.hideLoadingMessage()

        return response;
      })
    }

    private _promiseTimeout (time):Promise<number> {
      return new Promise(function(resolve,reject){
        setTimeout(function(){resolve(time);},time);
      });
    }

  }


  export function _preFlightCheck():{isOk:boolean, err:string}{
    var isOk = true;
    var err = ""
    if (PositiveTS.Service.Hakafa.hasCustomer()){
      isOk = false;
      err = i18next.t("beengo.beengoNotAllowedAlreadyHakafaUserSelected");
    }

    var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
    if (saleTotals.totalAmount === 0 ) {
      isOk = false;
      err = i18next.t("beengo.amountZero");
    }

    return {isOk: isOk, err: err};
  }


  export function _phoneInputDialog():Promise<string>{
    var inppBox = new PositiveTS.Dialogs.InputDialog()
    var dialogOptions = new PositiveTS.Dialogs.InputDialogOptions();
    dialogOptions.header = i18next.t("beengo.paymentUsingTheApplication");
    dialogOptions.description = i18next.t("beengo.enterBeengoUserPhoneNumber");
    dialogOptions.emptyErrorMessage = i18next.t("beengo.valueRequired");
    dialogOptions.showCancelButton = true;
    return inppBox.open(dialogOptions);
  }

  export function _createPurchaseEventWithReply(posTransactionId:string, phoneNumber:string, amount:number): Promise<{
      purchaseEventId: string, message: string, wasSent: boolean
  }> {

      const url = `${_baseUrl}/createPurchaseEventWithReply`;
      const requestType = "POST";
      const headers = JSON.stringify({
          "Content-Type": "application/json; charset=utf-8"
      })

      var purchasedItems:any[] = [];
      for (var i=0;i<posVC.saleItems.length;i++){
        purchasedItems.push({
          id: posVC.saleItems[i].itemCode,
          name: posVC.saleItems[i].itemDescription,
          price: posVC.saleItems[i].unitPrice,
          amount: posVC.saleItems[i].quantity
        })
      }

      var requestJson = JSON.stringify({
          businessId: session.store.beengoBusinessId ,
          branchId: session.store.beengoBranchId,
          phoneNumber: phoneNumber,
          amount: amount,
          posTransactionId: posTransactionId,
          purchasedItems: purchasedItems
      });


      var data = JSON.stringify({
          "body": requestJson,
          "type": requestType,
          "url": url,
          "headers": headers
      });

      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "external_service"
      ).then((x) => {
          PositiveTS.Service.Filelog.log("beengo",
              JSON.stringify({ request: x.request.body.data, result: x.request.result })
          );
          if (x.request.result.startsWith("ERROR:")) { throw new Error(x.request.result) }
          //{purchaseEventId:"123123", message:"shalom olam", wasSent:true}
          return JSON.parse(x.request.result);
      });
  }


  interface _GetPurchaseEventItf {
        businessId: string,
        branchId: string,
        phoneNumber: string,
        amount: number,
        discountAmount: number,
        status: number
  }

  export function _getPurchaseEvent(purchaseEventId:string): Promise<_GetPurchaseEventItf> {

      var url = `${_baseUrl}/getPurchaseEvent/purchaseEventId=${encodeURIComponent(purchaseEventId)}`;

      const requestType = "GET";
      const headers = JSON.stringify({
          "Content-Type": "text/plain; charset=utf-8"
      })

      var data = JSON.stringify({
          "body": "",
          "type": requestType,
          "url": url,
          "headers": headers
      });

      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "external_service"
      ).then((x) => {
          PositiveTS.Service.Filelog.log("beengo",
              JSON.stringify({ request: x.request.body.data, result: x.request.result })
          );
          if (x.request.result.startsWith("ERROR:")) { throw new Error(x.request.result) }
          //{purchaseEventId:"123123", message:"shalom olam", wasSent:true}

          if (!x.request.result) {
            return "";
          }
          return JSON.parse(x.request.result);
      });

  }



}
}
}
