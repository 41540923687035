module PositiveTS {
  export module Components {
    export module OutOfStockItemsDialog {
      const _vueComponentName = 'out-of-stock-items-dialog';

      export function create() {
        let OutOfStockItemsDialog = {
          template: JST.outOfStockItemsDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            async open() {
              await app.showLoadingMessageAsync(i18next.t('loading'));
              this.outOfStockItemCodes = [...this.vuexOutOfStockItemCodes];
              
              app.hideLoadingMessage();
              this.$el.show();
            },
            finish() {
              if(this.syncServerStatus < 2 ){
                let closeOnSyncServerError = this.close
                app.showAlert({
                  header: i18next.t("error"),
                  content: i18next.t("syncServer.errors.outOfStockItemsCannotUpdate"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, function () {
                  // Go back
                  pNavigator.back();
                  closeOnSyncServerError();
                }, null);
                return;
              }
              this.setOutOfStockItemsCodesAndSave(this.outOfStockItemCodes);
              this.close();
            },
            close() {
              this.$el.close();
            },
            showError(errorText) {
              return app.showAlertDialog({
                header: i18next.t('error'),
                content: errorText,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            },
            getItemImage(itemCode) {
              return this.allImagesByItemCode[itemCode];
            },
            companyID(){
              return session.company.companyID;
            },
            tenantID(){
              return session.company.tenantID;
            },
            async onSearch(searchText, loading) {
              this.searchText = searchText;
              loading(true);
              
              let result = posUtils.isBlank(this.searchText) ? [] : (await Storage.Entity.Item.smartSearch(this.searchText));
              let filteredResult = [];
              for (let r of result) {
                if (!this.outOfStockItemByCode[r.item.code]) {
                  let item = this.addLabelFunction(r.item);
                  item.hasPicture = session.allItems.get(item.code).hasPicture;
                  filteredResult.push(item);
                }
              }

              this.selectOptions = filteredResult;

              loading(false);              
            },
            addLabelFunction(item) {
              item.label = `${item.code} - ${item.description}`;
              return item;
            },
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {syncServerOnlineState,outOfStockItemsCodes} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {syncServerStatus:syncServerOnlineState,vuexOutOfStockItemCodes:outOfStockItemsCodes} 

            const {setOutOfStockItemsCodesAndSave} = globalStore
            const globalStoreActions = {setOutOfStockItemsCodesAndSave}


            return {...globalStoreActions,...globalStoreProps}
          },

          computed: {
            outOfStockItems: {
              get() {
                return this.outOfStockItemCodes ? _.compact(this.outOfStockItemCodes.map(code => this.addLabelFunction(session.allItems.get(code)))) : [];
              },
              set(newVal: any[]) {
                this.outOfStockItemCodes = newVal.map(item => item.code);
              }
            },
            outOfStockItemByCode() {
              return _.keyBy(this.outOfStockItems, 'code');
            },
          },
          data() {
            return {
              outOfStockItemCodes: [],
              allSelectedItemsByCode: [],
              searchText: "",
              selectOptions: [],
            }
          }
        }
        VueApp.component(_vueComponentName, OutOfStockItemsDialog)
      }
    }
  }
}
