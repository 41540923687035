// jquery plugin to auto select input fields on click
// use: $("input field selector").autoselect();
(function( $ ){

  var pickerTemplate = "<div class='number-picker-container'><div class='number-picker-plus-button'>+</div><div class='number-picker-value'>0</div><div class='number-picker-minus-button'>-</div></div>";

  var methods = {
    init : function( options ) {
      var defaultOptions = {
        min: null,
        max: null,
        step: 1,
        changed: function (newVal) {}
      };
      
      return this.each(function(){
        this.settings = $.extend(defaultOptions, options);

        let $this = $(this);

        if (isNaN($this.val()) || $this.val() == "") {
          $this.val('0');
        }

        let $picker = $(pickerTemplate);

        $this.after($picker);
        this.$picker = $picker;
        $this.hide();

        $picker.find('.number-picker-plus-button').click({ input: this }, function (event) {
          methods.step.apply(event.data.input, [event.data.input.settings.step]);
        });

        $picker.find('.number-picker-minus-button').click({ input: this }, function (event) {
          methods.step.apply(event.data.input, [-event.data.input.settings.step]);
        });
      });
    },
    step: function (count) {
      return $(this).each(function() {
        var $this = $(this);
        if (isNaN($this.val()) || $this.val() == "") {
          $this.val('0');
        }

        var newValue = parseInt($this.val()) + count;

        if (this.settings.min != null && newValue < this.settings.min) {
          newValue = this.settings.min;
        }
        if (this.settings.max != null && newValue > this.settings.max) {
          newValue = this.settings.max; 
        }

        $this.val(newValue);
        this.$picker.find('.number-picker-value').text(newValue);
        this.settings.changed.apply(this, [newValue]);
      });
    }
  };

  $.fn.numberPicker = function( method ) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on rubysoft.numberPicker' );
    }    
  };

})( jQuery );