module PositiveTS {
    export module Service {
        export class OsherAd {
            protected serverUrl: string;
            protected standNumber: string;

            protected websocket;

            static instance = null;


            constructor() {
                this.serverUrl = session.pos.autoStoreConfigObj.server;
                this.standNumber = jsonConfig.getVal(jsonConfig.KEYS.takeSmartStandNumber);
            }
            public static getInstance(): OsherAd {
                if (!this.instance) {
                    this.instance = new OsherAd();
                }

                return this.instance;
            }

            public static isEnabled() {
                return session.pos.isAutoStore && session.pos.autoStoreType == "osherad"
                    && jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService);
            }

            init() {
                console.log("OsherAd Service init");
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Initial)
                Pinia.posStore.setAutoStoreConfig({ ...session.pos.autoStoreConfigObj })
                try {
                    this.websocket = io('wss://' + this.serverUrl, {
                        transports: ['websocket'],
                        query: {
                            version: '4.0',
                            timestamp: Date.now(),
                        },
                        rejectUnauthorized: false,
                        reconnection: true,
                        reconnectionDelay: 5000,
                        reconnectionDelayMax: 10000,
                    });

                    this.websocket.on('connect', () => {
                        console.log("OsherAd connected");
                        Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Initial)
                        this.websocket.emit('join', this.standNumber);
                    });

                    this.websocket.on('CartEntered', async (data) => {
                        if (this.blockActions()) {
                            return;
                        }
                        if (data.standNumber == this.standNumber) {
                            console.log("CartEntered", data);
                            if (posVC.sale) {
                                await posVC.restartSale(false);
                            }
                            Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Scanning)
                        }
                    })
                    this.websocket.on('CartScanFinished', async (data) => {
                        if (this.blockActions()) {
                            return;
                        }
                        if (data.standNumber == this.standNumber) {
                            if (posVC.salePayments.length > 0) {
                                await OsherAd.SaleError(i18next.t("osherAd.cannotAddItemsToNonEmptyCart"));
                                return;
                            }
                            if (posVC.saleItems.length > 0) {
                                await OsherAd.SaleError(i18next.t("osherAd.cannotAddItemsToNonEmptyCart"));
                                return;
                            }
                            let errors = [];
                            if (data.barcodes?.length > 0) {
                                // Loop through the barcodes and add them to the cart
                                const barcodes = data.barcodes;
                                const countedBarcodes = barcodes.reduce((acc, barcode) => {
                                    acc[barcode] = (acc[barcode] || 0) + 1;
                                    return acc;
                                }, {});

                                for (let barcode in countedBarcodes) {
                                    try {
                                        await this.addItemWithAmount(barcode, countedBarcodes[barcode]);
                                        console.log(`Barcode: ${barcode}, Count: ${countedBarcodes[barcode]}`);
                                    } catch (err) {
                                        console.log("CartScanFinished Error: " + err.message);
                                        errors.push(err.message);
                                    }
                                }
                                console.log("CartScanFinished", data);
                                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Cart)
                                if (errors.length > 0) {
                                    await OsherAd.SaleError(errors[0]);
                                    Logger.error("CartScanFinished Error: Invalid barcodes: " + errors.join(", "));
                                }
                            }
                        }
                    })

                    this.websocket.on('CartError', async (data) => {
                        if (data.standNumber == this.standNumber) {
                            console.log("CartError", data);
                            await OsherAd.SaleError(data.error);
                        }
                    });

                    this.websocket.on('CartResetSale', async (data) => {
                        if (data.standNumber == this.standNumber) {
                            console.log("CartResetSale", data);
                            await OsherAd.resetSale();
                        }
                    });

                    this.websocket.on('disconnect', () => {
                        console.log('disconnected');
                        this.terminate();
                        Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.NetWorkError)
                        Pinia.posStore.setAutoStoreError(i18next.t("osherAd.cannotConnectToServer"));
                        this.init();
                    });

                    this.websocket.on("connect_error", (err) => {
                        console.log(`connect_error due to ${err.message}`);
                        Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.NetWorkError)
                        Pinia.posStore.setAutoStoreError(i18next.t("osherAd.cannotConnectToServer"));
                    });

                } catch (err) {
                    this.terminate();
                    console.error("connection attempt failed: " + err);
                }
            }

            public blockActions() {
                if (Pinia.selfServicePaymentStore.currentComponent != null) {
                    return true;
                }
                return false;
            }

            public static closeSale() {
                let server: OsherAd = this.getInstance();
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Initial);
                server.websocket.emit('SaleFinished', {
                    standNumber: server.standNumber,
                    posId: session.pos.deviceID
                });
            }

            public static async cancelSale() {
                let server: OsherAd = this.getInstance();
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Initial);
                if (posVC.sale) {
                    await posVC.restartSale(false);
                }
                server.websocket.emit('SaleCanceled', {
                    standNumber: server.standNumber,
                    posId: session.pos.deviceID
                });
            }

            public static startScan() {
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Scanning)
                let server: OsherAd = this.getInstance();
                server.websocket.emit('SaleScanBegin', {
                    standNumber: server.standNumber,
                    posId: session.pos.deviceID
                });
            }

            public static cancelScan() {
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Initial);
                let server: OsherAd = this.getInstance();
                server.websocket.emit('SaleCanceled', {
                    standNumber: server.standNumber,
                    posId: session.pos.deviceID
                });
            }

            public static saleScanReady() {
                let server: OsherAd = this.getInstance();
                server.websocket.emit('SaleScanReady', {
                    standNumber: server.standNumber,
                    posId: session.pos.deviceID
                });
            }

            public static async SaleError(err: string) {
                let server: OsherAd = this.getInstance();
                server.websocket.emit('SaleError', {
                    standNumber: server.standNumber,
                    posId: session.pos.deviceID,
                    error: err
                });
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Error);
                Pinia.posStore.setAutoStoreError(err);
                if (posVC.sale) {
                    await posVC.restartSale(false);
                }
            }

            public static async resetSale() {
                Pinia.posStore.setAutoStoreStep(Enums.OsherAdSteps.Initial);
                if (posVC.sale) {
                    await posVC.restartSale(false);
                }
            }

            public async terminate(err = null) {
                if (!this.websocket) {
                    return;
                }

                this.websocket.removeListener('disconnect');
                this.websocket.disconnect();
                this.websocket = null;
            }

            private async addItemWithAmount(code, amount) {
                const itemModel = new PositiveTS.Storage.Entity.Item();
                const itemsAndBarcodes = await itemModel.searchByBarcode(code);
                if (itemsAndBarcodes.length == 0) {
                    let items: any = await itemModel.search(code);

                    if (items.length !== 1) {
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].item.code === code) {
                                return await posVC.selectSizeColorForSaleItem(items[i].item, null, true, amount);
                            }
                        }
                        const itemWeightFound = await PositiveTS.Service.Scale.tryToGetFromScaleServerAndAddItem(code);
                        if (!itemWeightFound) {
                            throw new Error(i18next.t("NO_ITEM_OR_BARCODE_FOUND") + code)
                        }
                    } else {
                        return await posVC.selectSizeColorForSaleItem(items[0].item, null, true, amount);
                    }
                    if (items.length == 0) {
                        throw new Error(i18next.t("NO_ITEM_OR_BARCODE_FOUND") + code)
                    }
                } else {
                    // Pick the first item and get the item and its barcode
                    let item: PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
                    var itemBarcode = itemsAndBarcodes[0].itemBarcode;

                    // Create new sale item entity
                    var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
                    saleItem.saleID = posVC.sale.id;
                    saleItem.rowNumber = posVC.getRowNumber();
                    saleItem.quantity = amount;
                    return await posVC.persistNewSaleItem(saleItem);
                }



            }
        }
    }
}
