module PositiveTS {
    export module Service {

        export class MultipassPolice extends SmartVoucher {

            URL = '/multipass_police'
            static caveretPointsTypes = {
                sport: "10",
                personal: "11",
                sportPersonal: "12",
                kaspar: "14",
            }
            static POS_DISCOUNT = "multipassPolicePosDiscount";


            async getBalance(cardNumber: string, cvv?: string): Promise<GetBalanceResponse> {
                let params = null;
                if (cvv) {
                    params = { CardId: cardNumber };
                } else {
                    params = { CardCode: cardNumber };
                }
                let result = await this.sendRequest(params, "GetBudget", true);
                return result;
            }

            convertPointsToXML(points){
                let pointsString = ""
                points.forEach(point => {
                    let pointString = '<Point>';
                    for (let key in point) {
                        pointString += `<${key}>${point[key]}</${key}>`;
                    }
                    pointString += '</Point>';
                    pointsString += pointString;
                })

                return pointsString
            }
            
            static getAvailableWalletsForPos() {
                try {
                    let val: string = jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceDisplaySpecificPoints)
                    if (!val) {
                        return [];
                    }
                    return val.replaceAll(" ",'').split(",");
                } catch {
                    return [];
                }
            }

            static getWalletsPriority() {
                try {
                    let val: string = jsonConfig.getVal(jsonConfig.KEYS.multipassWalletsPriority)
                    if (!val) {
                        return [];
                    }
                    return val.replaceAll(" ",'').split(",");
                } catch {
                    return [];
                }
            }

            /**
             * Set to true if it's a Caveret POS, 
             * or set explicitly as a Multipass-points POS that was set to work with IDF wallets
             * @returns true if so, else false
             */
            static isWorkingWithIDF(): boolean {
                return Boolean(session.pos.isCaveret || jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceIDF));
            }

            static disablePromos(): boolean {
                return Boolean(jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceDisablePromos));
            }

            static isEnable(): boolean {
                return posUtils.isPresent(jsonConfig.getVal(jsonConfig.KEYS.multipassPolicePosId));
            }            

            static shouldSaveCustomerInfoInSale(): boolean {
                return !this.isWorkingWithIDF() && Boolean(jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceShouldSaveCustomerInSale));
            }

            async pay(voucherData: any, amount: number, cvv?: string, companyId?: any, additionalData?: any): Promise<any> {
               
                let pointsString = this.convertPointsToXML(additionalData.points)
                let result = await this.addTransaction(amount,pointsString,companyId,voucherData.barCode)

                if (result.ResultId == "0") {
                    let data = {
                        points: additionalData.points,
                        transactionId: result.MltpTransactionId,
                        cardCompanyId: additionalData.cardCompanyId,
                        customerName: additionalData.customerName,
                        pinCode: additionalData.pinCode,
                        cvv: companyId,
                        pointsBudgetList: result.PointsBudgetList.Point,
                        cardNumber: voucherData.barCode.slice(-8),
                    }
                    voucherData.data = JSON.stringify(data);
                    voucherData.smartVoucherType = Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE;
                    voucherData.allowPartialReturn = false;
                }

                let responseObj: ActionResponse = {
                    success: result.ResultId == "0",
                    error: result.ErrorMessage,
                    rawResponse: result,
                    voucher: voucherData
                }
                return responseObj;


            }

            async addTransaction(amount,points,cvv,cardNumber,isRefund?){
                let loginParams;
                if (cvv) {
                    loginParams = {
                        CardId: cardNumber,
                        PinNumber: cvv
                    }
                } else {
                    loginParams = {
                        CardCode: cardNumber
                    }
                }
                let tranValue = isRefund ? 0 : Math.floor(Number(amount) * 100)
                let params = {
                    TranType: 'S',
                    IsMaster: "N",
                    MasterAmount: "0",
                    TranSum: (isRefund ? -1 : 1) * Math.floor(Number(amount) * 100),
                    TranValue: tranValue,
                    PointsList: points,
                    PosTranId: `${session.pos.storeID}|${session.pos.deviceID}|${new Date().getTime().toString()}`
                }
                params = Object.assign(loginParams, params);
                let result = await this.sendRequest(params, "AddTransaction", false);
                return result
            }

            async deleteTransaction(transactionId){
                let params = {
                    TransactionId: transactionId
                }
                app.showLoadingMessage(i18next.t('multipassPolice.cancellingPayment'))
                let result = await this.sendRequest(params, "DeleteTransaction", true)
                app.hideLoadingMessage()

                return result
            }

            async cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
                let multipassDiscountItemsToDelete = posVC.saleItems.filter(si => {
                    let prepArr: string[] = posUtils.jsonParse(si.selectedPreparationInstructions);
                    return prepArr && prepArr.some(txt => [MultipassPolice.POS_DISCOUNT].includes(txt));
                })
                for (const multiPassDiscountSaleItem of multipassDiscountItemsToDelete) {
                    await posVC._deleteSaleItem(multiPassDiscountSaleItem);
                }
                for (let saleItem of posVC.saleItems) {
                    saleItem.multipassDiscount = 0;
                }
                let data = JSON.parse(voucherToCancel.data);
                let points = data.points
                let tranSum = 0;
                app.showLoadingMessage(i18next.t('multipassPolice.cancellingPayment'))
                for (let index = 0; index < points.length; index++) {
                    const point = points[index];
                    tranSum += Number(point.Amount);
                    point.Amount = "-" + point.Amount
                }
                tranSum = tranSum / 100;
                let pointsString = this.convertPointsToXML(points);
                let result = await this.addTransaction(tranSum,pointsString,data.cvv,voucherToCancel.barCode,true)

                app.hideLoadingMessage()
                if (result.ResultId == "0") {
                    return ({
                        success: true,
                        error: ""
                    });
                } else {
                    return ({
                        success: false,
                        error: result.ErrorMessage
                    });
                }
            }

            override async afterCancelPayment(voucherToCancel: any, doRemove?: boolean) {
                if (PositiveTS.Service.MultipassPolice.disablePromos()) {
                    await PositiveTS.Helper.SaleHelper.togglePromotions(false);
                }
                await posPaymentVC.updateAmountsIndicators();
            }

            cancelLoad(paymentToCancel: any): Promise<any> {
                return null;
            }
            loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
                return null;
            }
            getCardNumber(): Promise<GetCardNumberResponse> {
                return null;
            }

            async sendRequest(params: any, action: string, showError: boolean = false) {
                let login = {
                    OrganizationID: jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceOrganizationID),
                    OrganizationUserName: jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceOrganizationUser),
                    OrganizationPassword: jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceOrganizationPassword),
                    PosId: jsonConfig.getVal(jsonConfig.KEYS.multipassPolicePosId),
                }
                params = Object.assign(login, params);
                let response = await fetch(this.URL, {
                    method: "POST",
                    headers: {
                        'Authorization': `Token token=${session.pos.access_token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        req: params,
                        soapAction: action
                    })
                });

                let responseJson = await response.json()

                if (showError && responseJson["ResultId"] != "0") {
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('multipassPolice.error') + responseJson["ErrorMessage"],
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true,
                    })
                }
                PositiveTS.Service.Filelog.log("multipasspoints", JSON.stringify({request: params, action:action, response: responseJson}))

                return responseJson;
            }

            async setup() {
                let result = await this.sendRequest({}, "Setup");
                console.log(result);
                return result;
            }

            static posHasMultipassPolice() {
                let config = JSON.parse(localStorage.getItem('json_config'));
                if (config.memberOfClubs.find(item => { return item == Shared.Constants.MultipassPolicePoints.CLUB_CODE })) {
                    return true;
                }
                return false;
            }

            static saleHashMultipassPoliceCustomer(sale: any) {
                let saleJson = JSON.parse(sale.jsondata);
                if (posUtils.isNullOrUndefined(saleJson.customer) || saleJson.customer.clubName != Shared.Constants.MultipassPolicePoints.CLUB_CODE) {
                    return false;
                }
                return true;
            }

            static policePointsHaveBeenPaid() {
                if (posUtils.isBlankLikeRails(posVC?.salePayments)) {
                    return false;
                }
                for (const payment of posVC.salePayments) {
                    let data = JSON.parse(payment?.data || "{}");
                    if (!posUtils.isNullOrUndefinedOrEmptyString(data) && data.length > 0 &&
                        data[0].smartVoucherType == Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE) {
                        return true;
                    }
                }
                return false;
            }

            static async abortPayments() {
                let voucherToRemove = null;
                let paymentToRemove = null
                posVC.salePayments.some((payment) => {
                    if (payment.method == 3) {
                        let paymentData = JSON.parse(payment.data);
                        if (paymentData[0].smartVoucherType == Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE) {
                            voucherToRemove = paymentData[0];
                            paymentToRemove = payment
                            return true;
                        }
                    }
                    return false;
                });
                if (voucherToRemove != null) {
                    await Service.VoucherPayment.deleteVoucherPaymentAndPersist(paymentToRemove, voucherToRemove);
                }
            }

            /**
             * Alerts the user about payment back if needed.
             * If the police points have been paid, it shows an alert dialog to the user with a warning message.
             * If the user chooses to continue, it aborts the payments.
             * @returns {Promise<boolean>} A promise that resolves to true if the user chooses to continue, false otherwise. if null, the user didn't need to be alerted
             */
            static async alertUserAboutPaymentBackIfNeeded() {
                if (this.policePointsHaveBeenPaid() == false) {
                    return null;
                }
                let result = await app.showAlertDialog ({
                    header: i18next.t('payAttention'),
                    content: i18next.t('multipassPolice.paymentBackWarning'),
                    continueButtonText: i18next.t('ok'),
                    cancelButtonText: i18next.t("cancel"),
                    hideCancelButton: false
                });
                if (result === 'continue') {
                    await Service.MultipassPolice.abortPayments();
                }
                return result === 'continue';
            }

            static getWalletsArrayAndClassification(saleItem: Storage.Entity.SaleItem, companyId?: string): { wallets: string[], classification: string } {
                let itemWallets = [], classification;
                if(!saleItem.item.wallets){
                    return { wallets: itemWallets, classification: classification }
                }
                if (!companyId) {
                    itemWallets = saleItem.item.wallets.split('&');
                }
                else {
                    let walletsAndCompArr = saleItem.item.wallets.split("|")
                    for (let i = 0; i < walletsAndCompArr.length; i++) {
                        if (walletsAndCompArr[i].includes("=")) {
                            let compId = walletsAndCompArr[i].substr(0, walletsAndCompArr[i].indexOf("="))
                            if (companyId == compId) {
                                let wallets = walletsAndCompArr[i].substr(walletsAndCompArr[i].indexOf("=") + 1)
                                itemWallets = wallets.split("&")
                                if (!walletsAndCompArr[walletsAndCompArr.length - 1].includes("=")) {
                                    classification = walletsAndCompArr[walletsAndCompArr.length - 1]
                                }
                            }
                        }
                    }

                }

                return { wallets: itemWallets, classification: classification }
            }

            static groupItemsByWallets(saleItems: Storage.Entity.SaleItem[], companyId: string) {
                let groupByItems = {};
                for (const saleItem of saleItems) {
                    let walletsStr = saleItem.item.wallets;
                    if (posUtils.isBlank(walletsStr)) {
                        continue;
                    }
                    let walletsAndClassification = this.getWalletsArrayAndClassification(saleItem, companyId);
                    let walletTypes: string[] = walletsAndClassification.wallets;
                    for (const walletType of walletTypes) {
                        groupByItems[walletType] = groupByItems[walletType] || []
                        groupByItems[walletType].push(saleItem);
                    }
                }
                return groupByItems;
            }
            static isActivePaymentUsedCasparWallet(){
                return posVC.salePayments.some((payment) => {
                    if (payment.method == 3) {
                      try{
                        let parsedData = JSON.parse(payment.data)[0];
                        if(parsedData.creditType == "MultipassPolice"){
                          let multiPassParsedData = JSON.parse(parsedData.data);
                          for(let pointData of multiPassParsedData.points){
                            if(pointData.Type == 14){
                              return true;
                            }
                          }
                        }
                      }catch(err){
                        Logger.error(err);
                        return false;
                      }
                    }
                    return false;
                });
            }

            static getMaxAllowedPointForWallet(pointType: string, companyId?: string) {
                let maxPoints = 0;
                posVC.saleItems.forEach(item => {
                    item["pointsPrice"] = Number(item.priceNetoAfterDiscounts) * (1 - (Number(jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceDiscount) / 100)));
                    if (posUtils.isPresent(item.item.wallets)) {
                        let types = this.getWalletsArrayAndClassification(item, companyId).wallets
                        if (types.indexOf(pointType) > -1) {
                            maxPoints += item["pointsPrice"];
                        }
                    }
                });
                return maxPoints;
            }

            /**
             * Gets the discount percent for the multipass points. Default will be 0
             */
            static discountPercent(): number {
                return Number(jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceDiscount));
            }

            /** true when multipassPoliceDiscount is set above 0 && virutal wallet number is defined */
            static canUseVirtualPoints(): boolean {
                return MultipassPolice.discountPercent() > 0 && posUtils.isPresent(jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceVirtualPointNumber));
            }

            /** Used to Calc the amount of points to use for the item in MP */ 
            static calcItemPointPrice(saleItem: Storage.Entity.SaleItem): number {
                return Number(saleItem.priceNetoAfterDiscounts) * (1 - (MultipassPolice.discountPercent() / 100));
            }

            /**
             * Gets the price of the items that can be paid with multipass points (before discount)
             * @param companyId i.e. Card type that has the wallets
             * @param saleItems The sale items
             * @returns The price of the items that can be paid with multipass points (before discount)
             */
            static totalPriceforAllMPItems(companyId: string, saleItems: PositiveTS.Storage.Entity.SaleItem[],byWalletUsed?:string) {
                let totalPrice = 0;
                for (const saleItem of saleItems) {
                    if (posUtils.isPresent(saleItem.item.wallets)) {
                        let wallets = this.getWalletsArrayAndClassification(saleItem, companyId).wallets
                        if (posUtils.isPresent(wallets)) { // Check that the wallets array is not empty or null or whatever
                            if(byWalletUsed){
                                for (let wallet of wallets) {
                                    if (wallet == byWalletUsed) {
                                        totalPrice += Number(saleItem.priceNetoAfterDiscounts)
                                    }
                                  }
                            }else{
                                totalPrice += Number(saleItem.priceNetoAfterDiscounts);
                            }
                        }
                    }
                }
                return totalPrice;
            }

            /**
             * Gets a clean calculation of the max discount you can get in a sale
             * @param companyId i.e. Card type that has the wallets
             * @param saleItems The sale items
             * @returns Gets a clean calculation of the max discount (which can include obsurd decimal values)
             */
            static calcMaxDiscount(companyId: string, saleItems: PositiveTS.Storage.Entity.SaleItem[]) {
                let totalPrice = this.totalPriceforAllMPItems(companyId, saleItems);
                let discount = MultipassPolice.discountPercent();
                let result = totalPrice * (discount / 100);
                return result;
            }

            /**
             * Based on the clean calculation, we round the result to 2 decimal points in case we have a 3rd decimal point
             * @param companyId i.e. Card type that has the wallets
             * @param saleItems The sale items
             * @returns The final result of the max discount you can get in a sale, USE THIS!!!!
             */
            static getMaxDicountAUserCanGet(companyId: string, saleItems: PositiveTS.Storage.Entity.SaleItem[]): number {
                let result = this.calcMaxDiscount(companyId, saleItems);
                if ((result * 100) % 1 > 0) { // Oh no! We have a 3rd decimal point
                    result = Math.ceil(result * 100) / 100;
                }
                return result;
            }

            /**
             * Based on "getMaxDicountYouCanGet" we can calculate the max points a user can use
             * @param companyId i.e. Card type that has the wallets
             * @param saleItems The sale items
             * @returns Returns the max points a user can use (USE THIS TO PREVENT USE FROM USING MORE THAN HE NEEDS)
             */
            static getMaxPointsAUserCanUse(companyId: string, saleItems: PositiveTS.Storage.Entity.SaleItem[]): number {
                let maxDiscount = this.calcMaxDiscount(companyId, saleItems);
                let maxPoints = this.totalPriceforAllMPItems(companyId, saleItems);
                return maxPoints - maxDiscount;
            }

            /** Calcs how much can be paid with multipass points for a specific wallet (BASED ON DISCOUNT) */
            static totalPointsMPItemsForWallet(args: PositiveTS.Types.MutlipassCalcParams, exclusiveArgs: PositiveTS.Types.TotalPointsMPItemsForWalletArgs): number {
                let { companyId, saleItems } = args;
                let { wallet, excludeItemsWithWallet, substractPointsFromOtherWallets } = exclusiveArgs;
                let currentUsedPoints: { [key: string]: number } = _.clone(args.currentUsedPoints);
                let totalPrice = 0;

                for (const saleItem of saleItems) {
                    if (posUtils.isBlankLikeRails(saleItem.item.wallets)) {
                        continue
                    }

                    let wallets = this.getWalletsArrayAndClassification(saleItem, companyId).wallets
                    let shouldExludeItemFromCalc = posUtils.isPresent(excludeItemsWithWallet) && wallets.includes(excludeItemsWithWallet);
                    if (posUtils.isBlankLikeRails(wallets) || wallets.includes(wallet) == false || shouldExludeItemFromCalc) { 
                        continue;
                    }                    

                    let points = MultipassPolice.calcItemPointPrice(saleItem);
                    if (substractPointsFromOtherWallets) {
                        for (const walletCode of wallets) {
                            if (points <= 0) {
                                break;
                            }
                            if (walletCode == wallet || currentUsedPoints[walletCode] <= 0) {
                                continue;
                            }
                            let spendPoints = Math.min(points, currentUsedPoints[walletCode]);
                            currentUsedPoints[walletCode] -= spendPoints;
                            points -= spendPoints;
                        }
                    }
                    totalPrice += Math.max(points, 0);
                    

                }
                return totalPrice;
            }
            static checkIfCanBePayedWithCasparIfNeeded(paymentMethod: string){
                if(session.pos.isCaveret) {
                    let activeMultipassPaymentUsedCaspar = Service.MultipassPolice.isActivePaymentUsedCasparWallet();
                    
                    if(activeMultipassPaymentUsedCaspar && ['cash', 'credit', 'multipassPolice'].indexOf(paymentMethod) === -1){
                      app.showAlert({ 
                        header: i18next.t('error'),
                        content:i18next.t('multipassPolice.canPayedOnlyCashCreditMultipass') ,
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                      }, null, null);
                      return false
                    }
                }
                return true;
            }

            static getBestPointsUsage(args: PositiveTS.Types.MutlipassCalcParams) {
                let { companyId, saleItems, pointsAvailable } = args;
                let pointsAvailableHash: { [key: string]: number } = {};
                let pointsToUse: { [key: string]: number } = {}
                Object.keys(pointsToUse).forEach(walletCode => pointsToUse[walletCode] = 0); // reset the points                
                let walletsPriority = this.getWalletsPriority();
                // Prepare 2 things
                for (const wallet of pointsAvailable) {
                    // 1. add wallets that weren't set in priority to the end of the array
                    if (walletsPriority.includes(wallet.type) == false) {
                        walletsPriority.push(wallet.type); // Add it to the end of the array, as it's with the loweset priority
                    }
                    // 2. add the wallet to the hash
                    pointsAvailableHash[wallet.type] = wallet.amount;
                    pointsToUse[wallet.type] = 0;
                }                
            
                for (const saleItem of saleItems) {
                    if (posUtils.isBlankLikeRails(saleItem.item.wallets)) { // ITEM NOT APART OF THE CALCULATION
                        continue;
                    }
                    let itemPoints = this.calcItemPointPrice(saleItem);
                    let viableWalletsForItem = this.getWalletsArrayAndClassification(saleItem, companyId).wallets;
                    for (const walletCode of walletsPriority) {
                        // If the wallet is not viable for the item, or the wallet is depleted, continue to the next wallet
                        if (viableWalletsForItem.includes(walletCode) == false || !pointsAvailableHash[walletCode] ||pointsAvailableHash[walletCode] <= 0) {
                            continue;
                        }
                        // Virtual Wallet is useless here, skip to the next wallet
                        if (walletCode == MultipassPolice.caveretPointsTypes.kaspar && MultipassPolice.canUseVirtualPoints() == false) {
                            continue;
                        }
                        // spend points of the other wallet and deplete the item with it until the wallet is dry
                        let spendPoints = Math.min(itemPoints, pointsAvailableHash[walletCode]);
                        pointsAvailableHash[walletCode] -= spendPoints;
                        itemPoints -= spendPoints;
                        pointsToUse[walletCode] += spendPoints;
                        if (itemPoints <= 0) { // item was paid with points, break to move to the next item
                            break;
                        }                        
                    }
                }
                // Todo, incase with have a 3rd decimal point, we need to round the points and add to one of them                
                return pointsToUse;
            }

            static addRestOfThePointsFromWallet(walletCodeToUse: string, args: PositiveTS.Types.MutlipassCalcParams) {
                let { companyId, saleItems, pointsAvailable } = args;
                

                // Get max points a user can use
                let pointsAvailableForWallet = pointsAvailable.find(p => p.type == walletCodeToUse);
                if (posUtils.isBlankLikeRails(pointsAvailableForWallet) || pointsAvailableForWallet.amount == 0) {
                    // This wallets empty, so we can't use it
                    return 0;
                }

                // Prepare the current used points
                let currentUsedPoints: { [key: string]: number } = _.clone(args.currentUsedPoints);
                let walletCodes = Object.keys(currentUsedPoints);
                for (const walletCode of walletCodes) {
                    if (walletCode == walletCodeToUse || posUtils.isBlank(currentUsedPoints[walletCode]) || currentUsedPoints[walletCode] <= 0) { 
                        continue;
                    }
                    // Virtual Wallet is useless here, skip to the next wallet (and make sure it doesn't get used)
                    if (walletCode == MultipassPolice.caveretPointsTypes.kaspar && MultipassPolice.canUseVirtualPoints() == false) {
                        currentUsedPoints[walletCode] = 0;
                        continue;
                    }
                    // get the remaning points of the wallets after spending their points on items which cannot be paid by the walletCodeToUse
                    let otherArgs = { wallet: walletCode, excludeItemsWithWallet: walletCodeToUse, substractPointsFromOtherWallets: true };
                    currentUsedPoints[walletCode] -= MultipassPolice.totalPointsMPItemsForWallet(args, otherArgs);
                }                

                let points = 0;
                for (const si of saleItems) {
                    if (posUtils.isBlankLikeRails(si.item.wallets)) { // ITEM NOT APART OF THE CALCULATION
                        continue;
                    }
                    let wallets = this.getWalletsArrayAndClassification(si, companyId).wallets
                    if (wallets.includes(walletCodeToUse) == false) { // This item can't be paid with the wallet, continue to the next item
                        continue;
                    }
                    let itemPoints = this.calcItemPointPrice(si);                    
                    // Now, let's do the following calc: "itemPoints" - "pointsFromOtherWallets"
                    for (const walletCode of wallets) {
                        if (!currentUsedPoints[walletCode] || currentUsedPoints[walletCode] <= 0 || walletCode == walletCodeToUse) {
                            continue;
                        }
                        // spend points of the other wallet and delplete the item with it until the wallet is dry
                        let spendPoints = Math.min(itemPoints, currentUsedPoints[walletCode]);
                        itemPoints -= spendPoints // Subtract from "totalPointsMPItemsForWallet"
                        currentUsedPoints[walletCode] -= spendPoints; // Subtract from "currentUsedPoints"
                    }
                    // With the remaining points of the item, let's add them to the wallet we want to use
                    points += Math.max(itemPoints, 0);
                }         
                // Todo, incase with have a 3rd decimal point, we need to round the points and add to one of them   
                return Math.min(points, pointsAvailableForWallet.amount);
            }            
        }
    }
}