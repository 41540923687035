module PositiveTS {
  export module Components {
      export module TechCardDialog {
  
        const dialogSelector = "tech-card-dialog"
        const HARD_CODED_PASSWORD = "9255995635725742285=20201231000000000"
        const passwords = ['gruper', 'n0p455']
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            password: '',
            encryptedPassword: null,
            resolveOnCancel: false,
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.techCardDialog(),
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },

              cancel() {  
                if (this.resolveOnCancel) {
                  this.resolveFunc({approved: false, canceled: true})
                }
                
                this.close();
              },
              
              async confirmAndClose() {
                let approved = this.verfyPassword();              

                this.resolveFunc({approved: approved})
                this.close();
              },
              verfyPassword() {
                if (this.password == HARD_CODED_PASSWORD || passwords.includes(this.password.toLowerCase())) {
                  return true;
                }

                if (!posUtils.isBlank(this.encryptedPassword)) {
                  let encryptedPassword = PositiveTS.Service.Sha256.sha256.hash(this.password);

                  if (encryptedPassword == this.encryptedPassword) {
                    return true;
                  }
                }

                return false;
              },
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  this.dialogElem.close();
                }
                this.cleanData()
              },
      
        
              open(additinalEncryptedPassword = null, resolveOnCancel = false) {
      
                return new Promise((resolve,reject) => {
     
                  this.initialized = true;
                  this.password = '';

                  if (!posUtils.isBlank(additinalEncryptedPassword)) {
                    this.encryptedPassword = additinalEncryptedPassword;
                  } else {
                    this.encryptedPassword = null;
                  }
        
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  this.dialogElem.show();
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                  this.resolveOnCancel = resolveOnCancel;
                })
              },
              swipeCard: async function(){
                app.showLoadingMessage("העבר כרטיס");
                          let cardNumber = await Service.EMV.swipe();
                  if(cardNumber){
                    this.password = cardNumber;
                    this.confirmAndClose();
                  }
                app.hideLoadingMessage();
              },
            },
            data: initData,
            computed: {
            }
          }
  
          VueApp.component('tech-card-dialog-component',component)
        }
      }
    }
  }
  