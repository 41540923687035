module PositiveTS {
  export module Pages {
    export module PosSearchInvoices {
      export function create() {
        var component = {
          template: JST.posSearchInvoices(),
          methods: {
            open() { // Mimic the hooklife cycle. consider this as the "mounted" ,more or less
              Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.PosSearchInvoices);
            },
            async printBon() {
              await this.root.printBon();
            },
            transmitTaxInvoice() {
              posSearchInvoicesVC.transmitTaxInvoice();
            },
            openSendSmsInvoiceDialog() {
              this.root.openSendSmsInvoiceDialog();
            },
            async printOtotBracelet() {
              await this.root.printOtotBracelet();
            },
            displaySaleItems() {
              posSearchInvoicesVC.displaySaleItems();
            },
            printSaleItem() {
              posSearchInvoicesVC.printSaleItem();
            },
            creditSelectedSale() {
              posSearchInvoicesVC.creditSelectedSale();
            },
            showSearchForm() {
              posSearchInvoicesVC.showSearchForm()
            },
          },
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
            isEmv() { return session.pos.isEmv; },
            isMultiCurrency() { return Service.MultiCurr.getInstance().isMultiCurr(); },
            canSendInvoiceSms() { return jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice); },
            isDalpakimOnline() { return jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline); },
            mobilePhoneRepairModule() { return jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule); },
            canManageHakafaCustomer() { return jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos); },
            posUsesBonsPrinting() { return jsonConfig.getVal(jsonConfig.KEYS.allowBonPrinting); },
            restaurantNewPOS() { return jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS); },
            isOtot() { return Service.Otot.isOtotActive(); },
            taxAuthorityTransmitEnabled() { 
              return session.pos.taxAuthorityInvoiceAllocationAmount > 0
            },
            root() {
              return PositiveTS.VueInstance;
            },
            actions(): Array<PositiveTS.Types.PositiveButtonProps> {
              let btns: Array<PositiveTS.Types.PositiveButtonProps> = [
                {
                  onClick: this.displaySaleItems,
                  text: i18next.t("posSearchInvoices.show"),
                  icon: 'fa-light fa-circle-check',
                  iconClass: 'success',
                  border: false,
                  textClass: 'big-txt',
                },
                {
                  onClick: this.printSaleItem,
                  text: i18next.t("posSearchInvoices.invoice"),
                  icon: 'fa-light fa-print',
                  iconClass: 'primary-active',
                  border: false,
                  textClass: 'big-txt',
                },
                {
                  onClick: this.creditSelectedSale,
                  text: i18next.t("posSearchInvoices.credit"),
                  icon: 'fal fa-undo flip-horizontolly',
                  iconClass: 'primary-active',
                  border: false,
                  textClass: 'big-txt',
                },
                {
                  if: this.posUsesBonsPrinting,
                  onClick: this.printBon,
                  text: i18next.t("posSearchInvoices.bons"),
                  icon: 'printBon',
                  iconClass: 'print-bon',
                  border: false,
                  textClass: 'big-txt',
                },
                {
                  onClick: this.showSearchForm,
                  text: i18next.t("posSearchInvoices.search"),
                  icon: 'fa-light fa-magnifying-glass-dollar',
                  border: false,
                  iconClass: 'primary-active',
                  textClass: 'big-txt',
                },
                {
                  if: this.canSendInvoiceSms,
                  onClick: this.showSearchForm,
                  text: i18next.t("posSearchInvoices.send"),
                  icon: 'fa-light fa-comment-sms',
                  border: false,
                  textClass: 'big-txt',
                },
                {
                  if: this.isOtot,
                  onClick: this.printOtotBracelet,
                  text: i18next.t("posPastInvoices.printOtotBracelet"),
                  border: false,
                  textClass: 'big-txt',
                }
              ];
              return btns.filter(btn => btn.if === undefined || btn.if);
            },
          }
        }
        VueApp.component('pos-search-invoices', component)
      }
    }
  }
}

