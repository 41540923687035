module PositiveTS {
    export module Components {
        export module RestaurantItemsDisplay {
            export function getComponent() {
                let component = {
                    template: JST.RestaurantItemsDisplay(),
                    mixins: [keyPadMixin, Mixins.itemButtonMenuMixin, Mixins.itemImageMixin],
                    props: ['item'],
                    computed: {
                        currencySign() {
                            return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                        },
                    },
                    setup(){
                        const posStore = Pinia.usePosStore();
                        const {activeMenu,activeMenuName} = Pinia.storeToRefs(posStore);

                        const globalStore = Pinia.useGlobalStore();
                        const {usePictures} = Pinia.storeToRefs(globalStore);
          
                        return {activeMenu,activeMenuName,usePictures}
                    },
                    methods: {
                        addItem(item: Types.MenuButton) {
                            this.addItemToSale(item);
                        },
                    }
                }
                return component;
            }
        }
    }
}