module PositiveTS {
	export module Dialogs {
		export class CloseSaleDialog {
			change:any
			totalAmount = 0
			totalPaid:number


			setDataAndVerify() {

				let aThis = closeSaleDg;
				let saleTotals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
		
				// save total amount in the scope
				aThis.totalAmount	= saleTotals.totalAmount;
				aThis.totalPaid		= saleTotals.totalPaid;
		
				// Check that the user paid for all the sale
				if (Helper.SaleHelper.customerPaidEnough(saleTotals)) {
					// Tell the user
					app.hideLoadingMessage();
					app.showAlert({
						header: i18next.t('error'),
						content: i18next.t('incompletePayment'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, function () {
						// Go back to the payment screen
						CloseSaleDialogUI.dialog('close');
					}, null);
	
					return false;
				}
		
				let paidWithCash = PositiveTS.Helper.SaleHelper.getTotalPaidByMethod(posVC.salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_CASH);
		
				// calcuate sale change
				aThis.change = PositiveTS.Helper.SaleHelper.calcuateChange(saleTotals.totalAmount, saleTotals.totalPaid, paidWithCash, saleTotals.totalRound)
				return saleTotals;
		
			}

			open() {
				$(document.body).unbind('keyup');
				let aThis = closeSaleDg;
		
				let saleTotals = aThis.setDataAndVerify();
				if (saleTotals === false) {
					return;
				}
		
				let paidWithCash = PositiveTS.Helper.SaleHelper.getTotalPaidByMethod(posVC.salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_CASH);
		
				// calcuate sale change
				aThis.change = PositiveTS.Helper.SaleHelper.calcuateChange(saleTotals.totalAmount, saleTotals.totalPaid, paidWithCash, saleTotals.totalRound)
		
				// --- Display all the data collected above
				$('#pos-close-sale-total-quantity').text(session.fixedNumber(saleTotals.totalAbsoluteQuantity));
				$('#pos-close-sale-total-amount').text(session.fixedNumber(saleTotals.totalAmount));
				$('#pos-close-sale-paid').text(session.fixedNumber(saleTotals.totalPaid));
				$('#pos-close-sale-change').text(session.fixedNumber(-aThis.change.cash));
		
				if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
					$(".hideForDutyFree").hide();
					$(".showForDutyFree").show();
					
					const change = -aThis.change.cash
					const changeUSD = change * posPaymentVC.Currencies.USD
					const changeILS = change * posPaymentVC.Currencies.ILS
		
					$('#pos-close-sale-change-USD').text(session.fixedNumber(changeUSD, 2));
					$('#pos-close-sale-change-ILS').text(session.fixedNumber(changeILS,2));
		
				} else {
					$(".hideForDutyFree").show();
					$(".showForDutyFree").hide();
				}
		
		
				$('#pos-close-sale-change-credit-voucher').text(session.fixedNumber(-aThis.change.creditVoucher));
				$('#pos-close-sale-change-credit-card-field').val(session.fixedNumber(-aThis.change.creditCard));
		
		
				if (aThis.change.creditVoucher < 0) {
					aThis.applyChangeInCreditCard();
				} else {
					aThis.disableChangeInCreditCard();
				}
		
				// Set a click listener for the continue button
				$('#pos-close-sale-continue').unbind('click');
				$('#pos-close-sale-back').unbind('click');
				$('#pos-close-sale-continue').click(aThis.continue);
				$('#pos-close-sale-back').click(aThis.back);
			}
		
			async handleSmartCardLoad() {
				let smartVoucher = PositiveTS.Service.SmartVoucher.getSmartVoucherObjectToLoadIfApplicable(posVC.saleItems,posVC.Specialitems);

				if (!smartVoucher) {
					return;
				}

				let doSmartVoucherLoad = true;

				while (doSmartVoucherLoad) {
					try {
						if (Pinia.globalStore.simpleSelfService) {
							Pinia.selfServicePaymentStore.setCurrentStep('selfServiceLoadCard')
						}
						
						let dialogResponse = await smartVoucher.getCardNumber();
						app.showLoadingMessageDialog(smartVoucher instanceof Service.Otot ? i18next.t('otot.loadBracelet') : i18next.t("voucherPaymentAddValuPraxellLoadingMsg"));

						let paymentObj:any = await smartVoucher.loadCard(dialogResponse.cardNumber,posVC.saleItems[0].unitPrice,dialogResponse.options)

						//create the salePayment for the load card
						var salePayment = new PositiveTS.Storage.Entity.SalePayment();
						salePayment.amount = paymentObj.amount;
						salePayment.method = paymentObj.method;
						salePayment.data = paymentObj.data;
						salePayment.saleID = posVC.sale.id;
						
						posVC.salePayments.push(salePayment)
						await PositiveTS.Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)

						app.hideLoadingMessageDialog();
						doSmartVoucherLoad = false;

						if (smartVoucher instanceof PositiveTS.Service.Otot && Pinia.globalStore.simpleSelfService) {
							Pinia.globalStore.setOtotSmartCardLoadedNumber(dialogResponse.cardNumber);
						}

						return;
					} catch (err) {
						app.hideLoadingMessageDialog();
						let result = null;

						result = await app.showAlertDialog({
							header: i18next.t('error'),
							content: err instanceof Error ? err.message : err,
							continueButtonText: i18next.t('tryAgain'),
							cancelButtonText: i18next.t('cancel'),
							hideCancelButton: false,
							noHandleEnterEscape: true,
						})


						if (result == "cancel") {
							doSmartVoucherLoad = false;

							if (Pinia.globalStore.simpleSelfService) {
								await PositiveTS.Service.SalePaymentsService.cancelAllPayments()
								await posVC.restartSale();
								await PositiveTS.VueInstance.$refs.selfServicePaymentDialog.close();
								if (err instanceof Error) {
									err.message = i18next.t('paymentIsCancelled')
								} else {
									err = i18next.t('paymentIsCancelled')
								}
							}

							throw err instanceof Error ? err : new Error(err);
						}
					}
				}
					
			}

			async handleOtotPurchaseIfNeeded() {
				if (!Service.Otot.isOtotActive() || !Service.Otot.isOtotPurchaseTagItem(posVC.saleItems[0])) {
					return;
				}
				
				try {
					let braceletToLoad = await Service.Otot.getNeededBraceletsForSale();
					app.showLoadingMessageDialog(i18next.t('otot.purchasingTags'));
					let ototResponse = await Service.Otot.buyEntranceCard(posVC.saleItems, braceletToLoad.bracelet);
					let data = posUtils.isBlank(posVC.sale.jsondata) ? {} : JSON.parse(posVC.sale.jsondata);
					if (braceletToLoad.status === Service.Otot.BRACELET_STATUS.NEW || braceletToLoad.status === Service.Otot.BRACELET_STATUS.INVALID) {
						data.ototTagsPurchased = ototResponse?.body?.generatedCardsDetails;
					} else {
						data.ototTagsLoaded = ototResponse.body;
					}
					if(ototResponse.headers?.transactionId){
						data.ototTransactionId = ototResponse.headers?.transactionId;
					}else{
						data.ototTransactionId = ototResponse?.transactionId;
					}
					posVC.sale.jsondata = JSON.stringify(data);
					await PositiveTS.Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
				} catch(err) {
					await PositiveTS.Service.SalePaymentsService.cancelAllPayments()
					await posVC.restartSale();
					if (Pinia.globalStore.simpleSelfService) {
						await PositiveTS.VueInstance.$refs.selfServicePaymentDialog.close();
					} else {
						posPaymentVC.close();
					}
					
					throw err;
				}
				finally {
					app.hideLoadingMessageDialog();
				}
				
			}
		
			async continue() {
				await PositiveTS.Service.CloseSale.closeSale();
				closeSaleDg.afterSaleClosed();
				return;
			}
			
			back() {
				var aThis = closeSaleDg;
		
				posPaymentVC.fromPos = false;
				CloseSaleDialogUI.dialog('close'); //pNavigator.back();
			}
		
			// --------------------------------------------------------------------
			// Utilities
			// --------------------------------------------------------------------
			disableChangeInCreditCard() {
				$('#pos-close-sale-change-credit-card-field').attr('disabled', 'disabled');
			}
			applyChangeInCreditCard() {
				var aThis = closeSaleDg;
		
				$('#pos-close-sale-change-credit-card-field').removeAttr('disabled');
		
				if (!Modernizr.touch){
								$('#pos-close-sale-change-credit-card-field').keyboard({
										layout     : 'only-numbers',
										usePreview : false,
										autoAccept : true,
										css        : { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" },
										position   : {
												of : $('#footer'),
												my : 'center top',
												at : 'center bottom'
										}
								})//.addTyping();
						}
		
						$('#pos-close-sale-change-credit-card-field').autoselected();
		
				$('#pos-close-sale-change-credit-card-field').focusout(function() {
					var newValue = $('#pos-close-sale-change-credit-card-field').val();
		
					var value = 0;
					try {
						value = -session.fixedFloat(newValue);
					} catch (ex) {
						value = 0;
					}
		
					if (value > 0) {
						value = 0;
					}
		
					var notCashChange = session.fixedFloat(aThis.change.totalChange - aThis.change.cash);
		
					if (value < notCashChange) {
						value = notCashChange;
					}
		
					aThis.change.creditCard = value;
		
					aThis.change.creditVoucher = notCashChange - aThis.change.creditCard;
		
					$('#pos-close-sale-change-credit-card-field').val(session.fixedNumber(-aThis.change.creditCard));
					$('#pos-close-sale-change-credit-voucher').text(session.fixedNumber(-aThis.change.creditVoucher));
				});
			}
			afterSaleClosed() {
				posPaymentVC.fromPos = false;
				
				posPaymentVC.close();
				
				PositiveTS.Service.PunchCard.reopenPayPunchCardIfRequired();
				isCatalogUpdated.forceCatalogUpdaeIfRequired();

				if (session.pos.hasFlights) {
					Pinia.flightsStore.calculateSalesData();
				}
			}
			customerDetailsFill(withoutGoBack = false) {
				return new Promise((resolve,reject) => {
					var aThis = this;
		
					var saleContainAllCustomerDetails = PositiveTS.Helper.SaleHelper.doesSaleContainAllCustomerDetails(posVC.sale);
					
					if (aThis.change.creditVoucher < 0 && !saleContainAllCustomerDetails) {
						
						$(document.body).unbind('keyup');
						app.showCustomerDetailsFill(
							function (name, phone, customerID) {
								posVC.sale.customerName = name;
								posVC.sale.customerPhone = phone;
								posVC.sale.customerID = customerID;
								let stringContent = ''
								if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
									stringContent = i18next.t('creditSaleAllowCreditVoucher', {AMOUNT: session.fixedNumber(-aThis.change.creditVoucher), CURRENCY: 'דולר'})
								} else {
									stringContent = i18next.t('creditSaleAllowCreditVoucher', {AMOUNT: session.fixedNumber(-aThis.change.creditVoucher), CURRENCY: 'ש"ח'})
								}
								app.showAlert({
									header: i18next.t('creditVoucher'),
									content: stringContent,
									continueButtonText: i18next.t("ok"),
									hideCancelButton: withoutGoBack,
									cancelButtonText: i18next.t('cancel'),
									blueCancel: false
								}, function() {
									resolve();
								}, function () {
									reject({userCanceled: true});
								});
							},
							function () {
								reject({ userCanceled: true, message: 'לא מולאו פרטי לקוח'});
							},
							withoutGoBack);
					} else {
						return resolve()
					}
				})
				
			}
		
		}
	}
}

declare var closeSaleDg:PositiveTS.Dialogs.CloseSaleDialog;
closeSaleDg = new PositiveTS.Dialogs.CloseSaleDialog();
$(function() {
	(<any>window).CloseSaleDialogUI = $( "#pos-close-sale-dialog" ).dialog({
      autoOpen: false,
      modal: true,
      dialogClass: 'positive-dialog',
      width: '50%',
      resizable: false,
      closeOnEscape: false,
      draggable: false,
      open: function(event,ui) {
		// Set translation
		$("#pos-close-sale-dialog").parent().find(".ui-dialog-title").text(i18next.t("posCloseSaleDailog.title"));
		$("#pos-close-sale-total-quantity-label").text(i18next.t("posCloseSaleDailog.totalQuantity"));
		$("#pos-close-sale-total-amount-label").text(i18next.t("posCloseSaleDailog.totalSale"));
		$("#pos-close-sale-paid-label").text(i18next.t("posCloseSaleDailog.totalPaid"));
		$("#pos-close-sale-change-label").text(i18next.t("posCloseSaleDailog.changeInCash"));
		$("#pos-close-sale-change-USD-label").text(i18next.t("posCloseSaleDailog.changeInUSD"));
		$("#pos-close-sale-change-ILS-label").text(i18next.t("posCloseSaleDailog.changeInILS"));
		$("#pos-close-sale-change-credit-card-label").text(i18next.t("posCloseSaleDailog.changeInCreditCard"));
		$("#pos-close-sale-change-credit-voucher-label").text(i18next.t("posCloseSaleDailog.changeInCreditVoucher"));
		$("#pos-close-sale-continue").text(i18next.t("ok"));
		$("#pos-close-sale-back").text(i18next.t("back"));
		closeSaleDg.open(); $('#pos-close-sale-dialog').focus(); 
	  },
      close: function(event,ui) {
      	$('#pos-close-sale-continue').unbind('click');
				$('#pos-close-sale-back').unbind('click');
				$('#pos-close-sale-change-credit-card-field').unbind('focusout');
      }
    });
});

