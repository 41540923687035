
module PositiveTS {
  export module Service {
    export module AppWorkerHandler {
      const HEARTBEAT_TIMEOUT = 1000 * 60 * 5 ;
      const HEARTBEAT_INTERVAL = 1000 *60 ;
      export function init(posAccessToken:string,currentDBVersion) {
        try {
          setupHeartbeat(posAccessToken,currentDBVersion);
          session.worker = new (<any>window).Worker("assets/queueWorker.js");
          session.worker.onmessage = incomingMessage;
          let realDBVersion = Number(currentDBVersion);
          const isOnline = window.localStorage.getItem("isOnline") == 'true';
          sendMessage(Shared.Constants.Messaging.InitQueues,{token: posAccessToken, dbVersion: realDBVersion, isOnline: isOnline});
        }
        catch (e) {
          console.error('failed initializing worker');
          console.error(e);
        }
      }

      export function sendMessage(method:string,payload:any) {
        if (session && session.worker) {
          console.debug(`outgoing message: ${method}, payload is: ${payload}`);
          session.worker.postMessage({method: method,payload: payload});
        }
        else {
          console.error('worker not initialized');
        }
      }

      function incomingMessage(event) {
        if (event.data === 'heartbeat-response') {
          console.debug('incomingMessage - heartbeat-response');
          return Pinia.globalStore.setWebWorkerData({lastHeartbeat: Date.now()});
        }
        switch (event.data.type) {
          case PositiveTS.Shared.Constants.Messaging.OfflineSalesChanged:
          case PositiveTS.Shared.Constants.Messaging.OnlineStatusChanged:
          case PositiveTS.Shared.Constants.Messaging.removeXField:
            console.debug(event.data);
            Pinia.globalStore[event.data.type](event.data.payload)
            break;
          case PositiveTS.Shared.Constants.Messaging.errorLogMessages:
            console.error(event.data.payload);
            break;
          default:
            console.debug(event.data);
        }

      }

      function setupHeartbeat(posAccessToken,currentDBVersion) {

        let setupHeartbeatInterval = Pinia.globalStore.webWorkerData.setupHeartbeatInterval;
        if (setupHeartbeatInterval) {
          clearInterval(setupHeartbeatInterval);
        }
        let newSetupHeartbeatInterval = setInterval(() => {
          if (session && session.worker) {
            let lastHeartbeat = Pinia.globalStore.webWorkerData.lastHeartbeat;
            session.worker.postMessage('heartbeat');
            if (lastHeartbeat && Date.now() - lastHeartbeat > HEARTBEAT_TIMEOUT) {
              console.error('Worker may be terminated or unresponsive');
              session.worker.terminate();
              init(Pinia.globalStore.webWorkerData.posAccessToken,Pinia.globalStore.webWorkerData.currentDBVersion);
            }
          }
        }, HEARTBEAT_INTERVAL);
        Pinia.globalStore.setWebWorkerData({
          posAccessToken,
          currentDBVersion,
          lastHeartbeat: Date.now(),
          setupHeartbeatInterval: newSetupHeartbeatInterval
        });
      }
    }
  }
}
