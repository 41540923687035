module PositiveTS {
  export module Components {
    export module selectItemQuantityDialog {
      function initData() {
        return {
          quantity: '',
          restaurantNewPOS: jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS),
          fields: initFields()
        }
      }

      function initFields() {
        let fields = {
          'quantity': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'quantity'
          }
        }
        return fields;
      }

      export function create() {

        let component = {
          template: JST.selectItemQuantityDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            open(quantity) {
              this.cleanData();
              this.quantity = quantity
              $(document).unbind('keypress');

              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;

                this.$nextTick(() => {
                  this.$refs.quantity.focus();
                })
              })
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },
            cancel() {
              this.resolveFunc(null);
              return this.close();
            },
            async confirmAndClose() {
              if (posUtils.isBlank(this.quantity)) {
                this.quantity = 1;
              }

              if (isNaN(this.quantity) || this.quantity < 2) {
                return this.showError(i18next.t("selectIItemQuantityDialog.errors.belowTwoItems"));
              }
              if (this.quantity > 99) {
                return this.showError(i18next.t("selectIItemQuantityDialog.errors.above99Items"));
              }

              this.quantity = parseInt(this.quantity);

              this.resolveFunc(this.quantity);
              return this.close();
            },
            async exitOnEscape() {
              this.resolveFunc(null);
              return this.close();
            },
            onKeyTapped(key) {
              this.keyPadHanlder(key, this.fields["quantity"], this, 3);
            },
            clearTapped() {
              this.quantity = 2;
            },
            showError(errStr) {
              let aThis = this;
              app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                () => {
                  this.$refs.quantity.focus();
                }, null)
            }
          },
          data: initData,
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }
        }

        VueApp.component('select-item-quantity-dialog', component)

      }
    }
  }
}
