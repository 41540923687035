module PositiveTS {
  export module Service { 
    export module CustomerClubConstants {
      export const VOUCHER_TYPE_ID = "167";
      export const VOUCHER_TYPE_NAME = "CLUB_POINTS";
    }

    class CustomerClubPointsPaymentData {
      public amount;
      public points;
      public creditType:string;
      public barCode:string;
      public voucher_type_id:string;
      public smartVoucherType:boolean;

      constructor(amount, points) {
        this.amount = amount;
        this.points = points;
        this.creditType = CustomerClubConstants.VOUCHER_TYPE_NAME;
        this.barCode = "";
        this.voucher_type_id = CustomerClubConstants.VOUCHER_TYPE_ID;
        this.smartVoucherType = false;
      }
    };

    export class CustomerPoints {
      private helper:CustomerClub
      private CURRENT_METHOD_TYPE:number

      constructor() {
        this.helper = new CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
        this.CURRENT_METHOD_TYPE = this.helper.CURRENT_METHOD_TYPE;
      }

      public async updateCustomerPointsPaymentData(amount, points) {
        // This to ensure parameters are numbers
        amount = parseFloat(amount);
        points = parseFloat(points);
        
        let salePayment =  this.getExistingVoucherPaymentIfExists();
        let salePaymentData;
        if (salePayment) {
          salePaymentData = JSON.parse(salePayment.data);
          this.cleanPointsPaymentData(salePaymentData);
        } else {
          salePayment = new PositiveTS.Storage.Entity.SalePayment();
          salePayment.saleID = posVC.sale.id;
          salePayment.method = this.CURRENT_METHOD_TYPE;
          salePaymentData = [];
        }
        
        if (amount > 0) {
          let paymentData = new CustomerClubPointsPaymentData(amount, points);
          salePaymentData.push(paymentData);     
        }
    
        
        // Discountvoucher amount is always 0
        salePayment.amount = 0; 
        
        salePayment.data = JSON.stringify(salePaymentData);
        await posVC.persistSalePayment(salePayment,undefined,false); 
        
      };

      public getMaxAllowedAmounts() {
        let customer = this.helper.getCurrentSelectedCustomer();
        
        let saleToPointsRatio = this.helper.saleToPointsRatio;
        let cust_points_for_use = parseFloat( customer.cust_points_for_use );
        let invoiceAmount = posVC.getTotalAmount();
        if (jsonConfig.getVal(jsonConfig.KEYS.forbidUsingPointsOnUnaccumulableItems)) {
          posVC.saleItems
            .filter(si => si.avoidFromAccumulatingClubPoints && si.priceNetoAfterDiscounts > 0)
            .forEach(si => invoiceAmount -= si.priceNetoAfterDiscounts);
        }
        let minAmount = Math.min(cust_points_for_use * saleToPointsRatio, invoiceAmount);

        let minPoints = (minAmount / saleToPointsRatio);

        let minAmountToUse = jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse);

        if (minAmountToUse && minAmountToUse > 0 && minPoints < minAmountToUse) {
          minPoints = 0;
        }

        let multiplyPointsVal = jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication);

        if (multiplyPointsVal && multiplyPointsVal > 0) {
          minPoints = minPoints - (minPoints % multiplyPointsVal);
        }

        
       return {amount: minPoints * saleToPointsRatio, points: minPoints, ratio: saleToPointsRatio};
    
      };

      public getPrevousSelectedAmounts() {
        var currentPayment =  this.getExistingVoucherPaymentIfExists();
        var aloudPoints = this.getMaxAllowedAmounts();
        if (currentPayment) {
          this.setPreviouslySelectedAmountAndPoints(currentPayment, aloudPoints); 
        } 
        return aloudPoints;
    
      };
      
      public hideIfPointsAmountRatioOne() {
        var customer = this.helper.getCurrentSelectedCustomer();
        if (customer.val_per_points === customer.cust_points){
          $(".CulbSetPoints-hideIfRatioOne").hide();
        }
       
      };
        
      public getNotificationMsg(maxAllowedAmounts) {
        var template = $("#CulbSetPoints-statusTemplate").html();
    
        var customer = this.helper.getCurrentSelectedCustomer();
        var templateDictionary = $.extend({},maxAllowedAmounts, customer);
        
        return _.template(template)(templateDictionary);
      };

      private getExistingVoucherPaymentIfExists() {
        // Find a sale payment entity with the same method
        let salePayment = null;
        for (var i = 0; i < posVC.salePayments.length; i++) {
          // Get the payment
          var aSalePayment = posVC.salePayments[i];

          // Check that the sale payment has the current method
          if (aSalePayment.method == this.CURRENT_METHOD_TYPE) {
            salePayment = aSalePayment;
            break;
          }
        }
        
        return salePayment;
      };

      private cleanPointsPaymentData(salePaymentData) {
        for (var i=0;i<salePaymentData.length;i++){
          if (salePaymentData[i].voucher_type_id == CustomerClubConstants.VOUCHER_TYPE_ID) {
            salePaymentData.splice(i, 1);
          }
        }
      };

      public isPointsPaymentExists() {
        let salePayment =  this.getExistingVoucherPaymentIfExists();

        if (salePayment) {
          let paymentsData = JSON.parse(salePayment.data)
                                .filter(salePaymentData => salePaymentData.voucher_type_id == CustomerClubConstants.VOUCHER_TYPE_ID)
          
          return paymentsData.length > 0;
        }
      }

      private setPreviouslySelectedAmountAndPoints(currentPayment, byRefAloudPoints){
        var salePaymentData = JSON.parse(currentPayment.data );
        for (var i=0;i<salePaymentData.length;i++){
          if (salePaymentData[i].voucher_type_id == CustomerClubConstants.VOUCHER_TYPE_ID) {
            byRefAloudPoints.amount = salePaymentData[i].amount;
            byRefAloudPoints.points = salePaymentData[i].points;
          }
        }
      };

    }
  }
}
