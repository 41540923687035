
module PositiveTS {
  export module Components {
    export module AreaAttributesDialog {

      export function getComponent() {
        return {
          template: JST.areaAttributesDialog(),
          components: { deleteAreaDialog: DeleteAreaDialog.getComponent() },
          methods: {
            async open(dalpakArea = {}, isNewArea: boolean) {
              this.newArea = isNewArea;
              
              this.dalpakAreaToEdit = _.cloneDeep(dalpakArea);
              await this.$refs.dialog.open();
            },

            validateAndSave () {
              let errors = this.validateDalpakArea();

              if (posUtils.isBlank(errors)) {
                this.updateEditModeDalpakArea(this.dalpakAreaToEdit);
                return true;
              }

              app.showAlertDialog({
                header: i18next.t('error'),
                content: errors.join('\n'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              });

              return false;
            },

            validateDalpakArea () {
              let errors = []

              if (posUtils.isBlank(this.dalpakAreaToEdit.name)) {
                errors.push(this.$t('areaAttributesDialog.areaNameIsRequiredField'))
              } else {
                if (this.dalpakAreasInEditModeWithoutDeleted.some(da => da.name.trim() == this.dalpakAreaToEdit.name.trim() && da.id != this.dalpakAreaToEdit.id)) {
                  errors.push(this.$t('areaAttributesDialog.areaNameAlreadyExists'))
                }
              }

              if (posUtils.isBlank(this.dalpakAreaToEdit.sortOrder)) {
                errors.push(this.$t('areaAttributesDialog.sortOrderIsRequiredField'))
              }

              return errors
            },

            focusOnNextStepOrFinish (currentStep = null) {
              let steps = ['areaName', 'areaSortOrder']

              let currentStepIndex = steps.indexOf(currentStep);
              let currentComponentToFocus = null;

              if (currentStepIndex == -1 && steps.length > 0) {
                currentComponentToFocus = steps[0];
              } else {
                currentComponentToFocus = steps[currentStepIndex + 1];
              }

              if (currentComponentToFocus && this.$refs[currentComponentToFocus]) {
                this.$refs[currentComponentToFocus].focus();
              } else {
                this.$refs.dialog.buttonClicked(this.saveButton);
              }
            },
            async deleteDalpakArea() {
              let isDeleted = true;

              if (this.dalpaksInEditModeByArea[String(this.dalpakAreaToEdit.railsId)] && this.dalpakAreasInEditModeWithoutDeleted.length > 1) {
                isDeleted = await this.$refs.deleteAreaDialog.open(this.dalpakAreaToEdit);
              } else {
                let newDalpakArea = null;

                if (this.dalpakAreasInEditModeWithoutDeleted) {
                  newDalpakArea = Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT;
                }

                this.deleteEditModeDalpakArea({areaToDelete: this.dalpakAreaToEdit, dalpaksTargetAreaRailsId: newDalpakArea});
              }
              
              if (isDeleted) {
                this.$refs.dialog.close();
              }
            },
          },

          computed: {
            saveButton () {
                return {
                  text: this.newArea ? this.$t('addAction') : this.$t('saveAction'),
                  class: 'tables-green',
                  name: 'delete',
                  beforeCloseAction: () => this.validateAndSave(),
                }
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {dalpaksInEditModeByArea,dalpakAreasInEditModeWithoutDeleted} = Pinia.storeToRefs(dalpaksStore);
            const dalpaksProps = {dalpaksInEditModeByArea,dalpakAreasInEditModeWithoutDeleted}

            const {updateEditModeDalpakArea,deleteEditModeDalpakArea} = dalpaksStore
            const dalpaksActions = {updateEditModeDalpakArea,deleteEditModeDalpakArea}

            return {...dalpaksProps,...dalpaksActions}
          },
          data () {
            return {
              dalpakAreaToEdit: null,
              newArea: false,
            }
          }
        }
      }
    }
  }
}
