module PositiveTS {
export module Dialogs {
export module EmployeeSelectorDialog {

    export function close () {
		$('#employee-selector-dialog-cancel').unbind('click');
        $('#employee-selector-dialog-continue').unbind('click');
        $('#employee-selector-dialog').hide();
        $('#dialog-overlay').hide();
	}

	export function open () {
        return new Promise((resolve,reject) => {
            var employeesTable = $('#employee-selector-dialog-tableview').tableview();
            employeesTable.empty();
    
            $('#employee-selector-dialog-card').val('');
    
            $('#employee-selector-dialog-card').typing({
                start: function () {
                    employeesTable.empty();
                },
                stop: async function () {
                    let employees = await EmployeeSelectorDialog.searchEmployees()
                    EmployeeSelectorDialog.addEmployees(employees);
                },
                delay: 400
            });
    
            $('#employee-selector-dialog-continue').click(function () {
                EmployeeSelectorDialog.close();
                resolve(EmployeeSelectorDialog.getSelectedEmployee());
            });
    
            $('#employee-selector-dialog-cancel').click(function () {
                EmployeeSelectorDialog.close();
                reject();
            });
    
            $('#dialog-overlay').show();
            $('#employee-selector-dialog').show();
    
        })
		
	}

	export function searchEmployees () {
        var employeeNumber = $('#employee-selector-dialog-card').val();

        var employeeEntity = new PositiveTS.Storage.Entity.Employee();
        return employeeEntity.searchByMagneticCardOrEmployeeNumberAndNotInStore(employeeNumber, session.pos.storeID);
    }

    export function addEmployees (employees) {
    	var employeesTable = $('#employee-selector-dialog-tableview').tableview();
        employeesTable.empty();

        for (var i = 0; i < employees.length; i++) {
            var row = employeesTable.addRow(posUtils.sanitizeTableViewRow([employees[i].name]));
            row.data('employee', employees[i]);
        }

        employeesTable.selectFirstRow();
    }

    export function getSelectedEmployee() {
    	var employeesTable = $('#employee-selector-dialog-tableview').tableview();
    	return employeesTable.getSelectedRow().data('employee');
    }
}
}
}

