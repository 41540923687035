let debugWord = '';
module PositiveTS {
	export module Application {
		export var CheckedNewVersion = false;
		export var initialized = false;
		export var PosIconClick:boolean;
		export var loadingMessageTimeout;
		export const userCancelledGlobalMessage = "user cancelled";
		export const defaultKeyboardConfig = {
			layout      : "hebrew-qwerty-custom",//"hebrew-qwerty-custom",
			usePreview  : false,
			autoAccept  : true,
			openOn      : 'keyboard-icon-click',
			position    : {
			of : '#footer',
			my : 'bottom',
			at : 'bottom'
			},
			validate: function (keyboard, value, isClosing) {
			return true;
			},
			initialized: function(e, keyboard, el) {},
			beforeVisible: function(e, keyboard, el) {},
			visible: function(e, keyboard, el) {},
			beforeInsert: function(e, keyboard, el, textToAdd) {
				return textToAdd;
			},
			beforeClose: function(e, keyboard, el, accepted) {},
			accepted: function(e, keyboard, el) {console.debug('accepted')},
			canceled: function(e, keyboard, el) {
				console.debug('cancelled');
				return false;
			},
			restricted: function(e, keyboard, el) {},
			hidden: function(e, keyboard, el) {},
			change: function(e,keyboard,el){ }
		}
		export function switchVersionIfNeeded() {

			let currVersion = window.localStorage.getItem("Positive.VersionPath");
			if (currVersion && currVersion != location.pathname) {
				location.replace(currVersion);
			}

		}
		export async function init () {
			let lang = jsonConfig.getVal(jsonConfig.KEYS.lang) || 'he';
			let queryString = window.location.search;
			let urlParams = new URLSearchParams(queryString);
			let allowedLanguages = ['en', 'en_flight', 'he']

			if (urlParams.get('lang') != null && allowedLanguages.includes(urlParams.get('lang'))) {
				lang = urlParams.get('lang');
			}

			//switch to the current version if the last version is different than the shortcut URL
			app.switchVersionIfNeeded();

			Modernizr.touch = false; //TODO: why is this false?

			// underscore  recognized tags
			_.templateSettings =  {evaluate: /<!!([\s\S]+?)!!>/g, interpolate: /<!!=([\s\S]+?)!!>/g, escape: /<!!-([\s\S]+?)!!>/g};

			// Initialize storage
			// if (navigator.userAgent.toLowerCase().indexOf('android') > -1 &&
			// 		navigator.userAgent.toLowerCase().indexOf('t1') == -1 && navigator.userAgent.toLowerCase().indexOf('t2') == -1 && window.outerWidth < 700) {
			// 	document.querySelector("meta[name=viewport]").setAttribute('content',
			// 	'width=device-width, initial-scale='+(window.outerWidth/600)+', maximum-scale=1, user-scalable=no');
			// }
			
			PositiveTS.Service.Localization.init(lang);
			PositiveShared.SharedConfig.init(lang);
			app.showLoadingMessage(i18next.t("loadingSoftware"));
			appDB = new PositiveTS.Service.PositiveDB();
			let currentDBVersion = await appDB.init();
			storage.init()
			.then(async function() {

				let isCaveret = false;

				let poses = await (new PositiveTS.Storage.Entity.Pos()).all();

				if (poses.length > 0) {
					let pos = poses[0];
					isCaveret = pos.isCaveret;
				}

				let onlyOnePosOpened = await PositiveTS.Service.MultiplePosesOpened.verifyOnlyOnePosIsOpen(isCaveret);

				if(!onlyOnePosOpened) {
					throw new Error('More than one pos are open');
				};

				// Initialize sales queue
				// PositiveTS.SalesQueue.init(); //TODO: gruper start worker here

				// Initialize header and footer and other general view related tasks
				app.initView();

				// If this is the first time Positive is running, do some first run work
				if (window.localStorage.getItem('Positive.Application.isFirstRun') == null || window.localStorage.getItem('Positive.Application.isFirstRun') == 'true') {
					window.localStorage.setItem('Positive.Application.isFirstRun', 'false');
					app.firstRun();
				}

				// Add an observer for session change. Only load the next page on successfully initializing session.
				var observer = function (object) {
					// Remote self as an observer (so it won't be called again next time the session changes)
					PositiveTS.NotificationCenter.removeObserver(observer);

					// Initialize MVC
					pNavigator.init();

						// Decide to which page to go based on the login state from session
					app.hideLoadingMessage();

					if (session.isLoggedIn) {
						// Go to the homepage

						if (Pinia.globalStore.simpleSelfService || jsonConfig.getVal(jsonConfig.KEYS.itemQueryOnlyPos) || jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet)) {
							pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
						} else {
							// PositiveTS.VueServices.Router.goto(PositiveTS.Components.EmployeeHours.route,{id: '48C4B0FEBA7045B58534FB566F2FA616'});
							pNavigator.replacePage('homepage', i18next.t('pageTitle.homepage'), null, null);
							// pNavigator.pushPage('pos', i18next.t('pageTitle.pos'), null, null);
							// pNavigator.replacePage('employee-manage', i18next.t('homepage.itemManage'), null, null);
							// pNavigator.replacePage('pos', i18next.t('pageTitle.homepage'), null, null); //for dev purposes only
						}
					} else {
						// Go to the login page
						pNavigator.replacePage('login', i18next.t('pageTitle.login'), null, null);
					}
				};
				PositiveTS.NotificationCenter.addObserver(observer, session.SessionChangedNotification);

				// Initialize session
				session.init()
				.then( function(){

					Pinia.posStore.expandedView =jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOSExpandedDefault);

					let standaloneMode = jsonConfig.getVal(jsonConfig.KEYS.standaloneMode)
					let simpleSelfService = jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)

					PositiveTS.Service.AppWorkerHandler.init(session.pos.access_token,currentDBVersion);
					
					if(standaloneMode && simpleSelfService) { 
					    Pinia.connectivityStore.startConnectivityCheck();
						Pinia.globalStore.lockSelfServiceByOpeningHours()
						Pinia.globalStore.checkSelfServiceOfflineMode()
						Pinia.globalStore.startSelfServiceOfflineLockManagment()
						let lockManager = JSON.parse(localStorage.getItem('selfServiceLockManager')) || [];
						let lockedBy = lockManager.length>0 ? lockManager[0] : null;
						if( Pinia.globalStore.isSelfServicePosLoked || lockedBy) {
							Pinia.globalStore.lockSelfServicePos(lockedBy)
						}
					}

					if(PositiveTS.Service.OsherAd.isEnabled()){
						Pinia.componentsStore.openComponent( {componentName:"OsherAdComponent", args: []}) 
						if(posVC.saleItems.length > 0){
							Pinia.posStore.setAutoStoreStep(PositiveTS.Enums.OsherAdSteps.Cart)
						}
					}

					if(session.pos.isVendingMachinePos){
						console.log("This POS uses a vending machine, initializing vending machine service");
						Pinia.vendingMachine.init();
						Pinia.globalStore.loadVendingMachineOutOfStockItemsCodes()
					}

					if(Pinia.globalStore.isScreenLocked){
						Pinia.componentsStore.openComponent( {componentName:"lockScreen", args: []}) 
					}

					if (PositiveTS.Service.CheckPosPropriety.isCannotWorkOnPos()){
						Pinia.componentsStore.openComponent( {componentName:"checkPosProprietyDialog", args: []}) 
					}

					if(session.pos.isEmv){
						Service.EMV.getInstance().initRecoveryProccessIfNeeded();
					}


					app.initialized = true;
					// END Application classes initialization
				});
			})

		}
		export function handleEnterEscape(event) {
			if (event.keyCode === 13) {

				var elements = $('[data-approve-button]:visible');
				elements.sort(function(a, b) {
				  return(Number($(b).zIndex()) - Number($(a).zIndex()));
				});
				var elem = elements[0];
				if (typeof(elem) !== "undefined") {
					event.stopPropagation();
					elem.click();
				}
			}
			if (event.keyCode === 27) {
				var elements = $('[data-cancel-button]:visible');
				elements.sort(function(a, b) {
				  return(Number($(b).zIndex()) - Number($(a).zIndex()));
				});
				var elem = elements[0];
				if (typeof(elem) !== "undefined") {
					event.stopPropagation();
					elem.click();
				}
			}
		}

		export function turnOffPosResults() { //פשוט נורא ואיום...
			posAS.activate = false;
			var elem = document.getElementById('as-results-pos-search-field')
			if (elem) {
				elem.style.display = "none";
			}
		}

		export function initView () {


			// Initialize the clock in the footer
			$('#footer-time').text(PositiveTS.DateUtils.longFormat());
			setInterval(function () { $('#footer-time').text(PositiveTS.DateUtils.longFormat()); }, 60 * 1000);

			// Initialize UI buttons
			$('[data-role="button"]').uibutton();


			window.addEventListener('touchstart', function onFirstTouch() {
				// we could use a class
				document.body.classList.add('user-is-touching');

				// or set some global variable
				(<any>window).USER_IS_TOUCHING = true;

				// we only need to know once that a human touched the screen, so we can stop listening now
				window.removeEventListener('touchstart', onFirstTouch, false);
			}, false);

			if (jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView)) {
				document.body.classList.add('tables-view');
				if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)){
					document.body.classList.add('new-restaurant');
				}
			}

			if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceKeyboardBiggerFont)) {
				document.body.classList.add('bigger-simple-keyboard');
			}


			$(document).off('keyup',app.handleEnterEscape);
			$(document).on('keyup',app.handleEnterEscape);
			$(document).off('click',app.turnOffPosResults);
			$(document).on('click',app.turnOffPosResults);


			// Display/hide data in header based on the current state
			var observer = function (object) {
				// Check that the object includes all needed data
				if (!('page' in object)) {
					// The object does not have all the needed data, exit!
					return;
				}

				// Get the current state from object
				var page = object.page;

				// Decide what to do based on the current page
				switch (page) {
					case 'homepage':
						// For the homepage, hide the homepage button and display the logout button
						Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.HomePage);
						$('.header-cell').show();
						$('#header-homepage-button').hide();
						$('#header-logout-button').show();
						break;
					case 'login':
						// For the login page, all the data in the header
						Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.login);
						$('.header-cell').hide();
						$('#header-homepage-button').hide();
						$('#header-logout-button').hide();
						$('#pos-info-icon').hide();
						break;
					case 'pos':
					case 'pos-past-invoices':
					case 'pos-search-invoices':
						if(jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS) === true) {
							Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.RestaurantItemMenu);
						}else{
							Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.Pos);
						}
						$('.header-cell').show();
						$('#header-homepage-button').show();
						$('#header-logout-button').hide();
						$('#pos-info-icon').show();
						break;
					default:
						// For all the rest, show all the data in the header, except the logout button
						Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.default);
						$('.header-cell').show();
						$('#header-homepage-button').show();
						$('#header-logout-button').hide();
						$('#pos-info-icon').show();
						break;
				}
			};
			PositiveTS.NotificationCenter.addObserver(observer, pNavigator.StateChangedNotification);

			// --- Reachability Indicator ---------------------------------------------
			// Update the online indicator every time the reachability state changes
			PositiveTS.NotificationCenter.addObserver(function (object) {
				if ("isOnline" in object) {
					if (object['isOnline']) {
						if (!(<any>window).firstTimeOnline) {
							(<any>window).firstTimeOnline = true
						}
						else{
							if(jsonConfig.getVal(jsonConfig.KEYS.usePinpadInSwitchMode) &&
							jsonConfig.getVal(jsonConfig.KEYS.transmitTranToSwitchWhenBackToOnline)){
							   PositiveTS.Service.EMV.transmitAndDeleteTranIfNeeded();
						   }
						}

						$('#footer-online-indicator-text').text('Online');
						$('#footer-online-indicator').removeClass('footer-online-indicator-offline').addClass('footer-online-indicator-online');
					} else {
						$('#footer-online-indicator-text').text('Offline');
						$('#footer-online-indicator').removeClass('footer-online-indicator-online').addClass('footer-online-indicator-offline');
					}
				} else {
					$('#footer-online-indicator').text('Unknown');
				}
			}, PositiveTS.Reachability.PositiveReachabilityStateChangedNotification);

			// Initialize the online indicator in the footer
			if (PositiveTS.Reachability.isOnline) {
				$('#footer-online-indicator-text').text('Online');
				$('#footer-online-indicator').removeClass('footer-online-indicator-offline').addClass('footer-online-indicator-online');
			} else {
				$('#footer-online-indicator-text').text('Offline');
				$('#footer-online-indicator').removeClass('footer-online-indicator-online').addClass('footer-online-indicator-offline');
			}

			// --- Header Data ---------------------------------------------
			PositiveTS.NotificationCenter.addObserver(function (object) {
				// Check whether we have pos in session
				if (session.pos == null) {
					// --- No, we don't have pos in session
					// Reset header
					$('.header-store-name').text('');
					$('.header-pos-number').text('');
					$('.header-cashier').text('');

					// We are done!
					return;
				}

				// Update the header info with the data from the session
				$('.header-store-name').text(session.pos.storeName);
				$('.header-pos-number').text(session.pos.deviceID);

				// If there is a cashiers, include it in the header
				if (session.pos.employeeID != "-1") {
					$('.header-cashier').text(session.pos.employeeName);
					Pinia.globalStore.setCashierName(session.pos.employeeName);
				}
				if (session.pos.employeeName == null) {
					$('.header-cashier').text('');
				}
			}, session.SessionChangedNotification);

			// --- Dialog Auto-Resize (Updated Syntax) -----------------------------
			$(window).on('resize', function () {
				$('.dialog').center();
			});
			$(window).trigger('resize'); // Triggers the resize event handler immediately


		}
		export function firstRun () {
		}
		// --------------------------------------------------------------------
		// Loading Message Functions
		// --------------------------------------------------------------------

		export function blockUIAndShowMessage(message, blockEvents = true) {
			return PositiveTS.Service.GlobalUI.blockUIAndShowMessage(message, blockEvents);
		}

		export function resumeUIAndHideMessage(resumeEvents = true) {
			return PositiveTS.Service.GlobalUI.resumeUIAndHideMessage(resumeEvents);
		}

		export function showLoadingMessageDialog(content){
			debugWord = content;
			console.time(`showLoadingMessageDialog`);
			console.log('start loading message text: ' + debugWord);
			Pinia.globalStore.setIsLoadingMessageActive(true)

			return PositiveTS.Service.GlobalUI.showLoadingMessageDialog(content);
		}

		export function hideLoadingMessageDialog(){
			console.timeEnd(`showLoadingMessageDialog`);
			console.log('end loading message text: ' + debugWord);

			Pinia.globalStore.setIsLoadingMessageActive(false)

			return PositiveTS.Service.GlobalUI.hideLoadingMessageDialog();
		}
		// Sometimes The showLoadingMessage dosn't render the message in time.
		// This function makes sure that there is a loader displayed on the screen.
		export function showLoadingMessageAsync (message, progressValue?, showRefreshTimeout = true) {
			return new Promise((resolve, reject) => {
				app.showLoadingMessage(message, progressValue, showRefreshTimeout);
				window.requestAnimationFrame(() => window.requestAnimationFrame(() => setTimeout(resolve, 0)));
			});
		}

		export function showLoadingMessage (message?, progressValue?, showRefreshTimeout = true) {
			switch (arguments.length) {
				case 0:
					message = i18next.t("loading")
					break;
				case 1:
					progressValue = null;
					break;
			}

			if (progressValue != null) {
				if (progressValue < 0) {
					progressValue = 0;
				} else if (progressValue > 1) {
					progressValue = 1;
				}
			}

			// Show the loading message overlay
			$('#loading-message-overlay').show();

			// Set the loading message
			$('#loading-message-text').text(message);
			clearTimeout(loadingMessageTimeout);
			if(showRefreshTimeout) {
				loadingMessageTimeout = setTimeout(function(){$('#loading-message-refresh').show() }, 120000);
			}
			// If there is a progress value, display the progress bar. Otherwise, hide it.
			if (progressValue == null) {
				//$('#loading-message-progress-bar').addClass('animate');
				$('#loading-message-progress-bar > span').css({'width': '100%'});
			} else {
				//$('#loading-message-progress-bar').removeClass('animate');
				$('#loading-message-progress-bar > span').css({'width': progressValue * 100 + '%'});
			}
			Pinia.globalStore.setIsLoadingMessageActive(true)
			if(session.pos && session.pos.isVendingMachinePos && Pinia.globalStore.isLoadingMessageDisabled){
				return;
			}
			// Show the loading message
			$('#loading-message').show();
		}
		export function hideLoadingMessage () {
			clearTimeout(loadingMessageTimeout);
			// Hide the loading message
			$('#loading-message-refresh').hide()
			$('#loading-message').hide();


			// Hide the loading message overlay
			$('#loading-message-overlay').hide();
			Pinia.globalStore.setIsLoadingMessageActive(false)
		}
		export function promiseShowAlert(options, continueCallback?, cancelCallback?) {
			return PositiveTS.Service.GlobalUI.promiseShowAlert(options, continueCallback, cancelCallback);
		}

		export function showLoadingMessageWithCancel(options, handlerCancel = null, ...handlerParams) {
			Pinia.globalStore.setIsLoadingMessageActive(true)
			Pinia.componentsStore.openComponent( {componentName:"loadingMessageWithCancel", args: [options, handlerCancel, handlerParams]});
		}

		export function hideLoadingMessageWithCancel (){
			Pinia.globalStore.setIsLoadingMessageActive(false)
			PositiveTS.VueInstance.$refs.loadingMessageWithCancel.close();
		}
		// --------------------------------------------------------------------
		// Alert Functions
		// --------------------------------------------------------------------
		/**
		 * The alert displays a dialog with an header, content and one or two buttons which are called the continue and cancel buttons
		 * All the above can be customized through the options object (see below for the possible properties)
		 *
		 * @param {Object} [options] Configuration object
		 * @param {String} [options.header] HTML that will be set in the header
		 * @param {String} [options.content] HTML string that will be used as the content of the alert dialog
		 * @param {String} [options.continueButtonText] HTML string that will be used as the content for the continue button
		 * @param {String} [options.cancelButtonText] HTML string that will be used as the content for the cancel button
		 * @param {Boolean} [options.hideCancelButton] True to hide the cancel button (so only the continue button will be visible)
		 * @param {Boolean} [options.noHandleEnterEscape] User must click dialog, no Esc, or Enter supported
		 */
		export async function showAlertDialog(options, continueCallback?, cancelCallback?) {
			var dialog = PositiveTS.VueInstance.$refs.alertDialog;
			dialog.header = options.header || i18next.t('message');
			dialog.content = options.content || i18next.t('content');
			dialog.hideCancelButton = options.hideCancelButton || false;
			dialog.hideContinueButton = options.hideContinueButton || false;
			dialog.continueButtonText = options.continueButtonText || i18next.t('ok');
			dialog.cancelButtonText = options.cancelButtonText || i18next.t('cancel');
			dialog.continueCallback = continueCallback || null;
			dialog.cancelCallback = cancelCallback || null;
			dialog.imageUrl = options.imageUrl || null;
			dialog.imageHeight = options.imageHeight || null;
			
			return await Pinia.componentsStore.openComponent( {componentName:"alertDialog", args: []});
			
		}

		export async function hideAlertDialog() {
			if(PositiveTS.VueInstance.$refs.alertDialog){
				PositiveTS.VueInstance.$refs.alertDialog.close();
			}
		}

		export function showErrorAlertDialog(content: string = i18next.t('generalError')) {
			showAlertDialog({
			  header: i18next.t('error'),
			  content: content,
			  continueButtonText: i18next.t("ok"),
			  hideCancelButton: true,
			});
		}

		export function showErrorAlert(content: string) {
			showAlert({
			  header: i18next.t('error'),
			  content: content,
			  continueButtonText: i18next.t("ok"),
			  hideCancelButton: true,
			});
		}

		export function showAlert(options, continueCallback?, cancelCallback?) {
			var alert = $( "#positive-alert" ).dialog({
			  autoOpen: false,
			  modal: true,
			  stack: true,
			  dialogClass: 'positive-dialog alert-dialog',
			  title: options.header || i18next.t('message'),
			  width: Pinia.globalStore.mobileLayout ? options.width || '90vw' : options.width || '50vw',
			  resizable: false,
			  closeOnEscape: false,
			  draggable: false,
			  close: function(event,ui) {
				  $('#positive-alert #positive-alert-content').html("");
			  }
		});

		var buttonHandler = function(cb) {
			if (typeof cb === 'function') {
					cb();
				}
			alert.dialog('close');
		}

			if (options.noHandleEnterEscape){
				$(document).off('keyup',app.handleEnterEscape);
			}
		let restaurantNewPOS =  jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS);
		let dalpakTablesView =  jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView);
		let isRestaurantNewPOS = dalpakTablesView && restaurantNewPOS;

		var cancelButtonDisplay = options.hideCancelButton ? "none" : "flex";
		var cancelClass = options.blueCancel ? "ui-button-style-azure-background" : "ui-button-style-red-background";
		var oldCancelClass = options.blueCancel ? "ui-button-style-red-background" : "ui-button-style-azure-background";

		const cancelIcon = `<i class="fa-${isRestaurantNewPOS ?'light' : 'regular' } fa-circle-xmark icon"></i>`;
		const continueIcon = `<i class="fa-${isRestaurantNewPOS ?'light' : 'regular' } fa-circle-check icon"></i>`;

			cancelClass = options.yellowCancel ? "ui-button-style-yellow-background" : "ui-button-style-red-background";
		oldCancelClass = options.yellowCancel ? "ui-button-style-red-background" : "ui-button-style-yellow-background";

		$('#positive-alert .alert-cancel .ui-button-style').addClass(cancelClass).removeClass(oldCancelClass);

		$('#positive-alert .alert-cancel').css({display: cancelButtonDisplay}).unbind('click').bind('click',
				function() {
					$(document).off('keyup',app.handleEnterEscape);
					$(document).on('keyup',app.handleEnterEscape);
					buttonHandler(cancelCallback);
				});

		$('#positive-alert .alert-continue').unbind('click').bind('click',
			function() {
					$(document).off('keyup',app.handleEnterEscape);
					$(document).on('keyup',app.handleEnterEscape);
				buttonHandler(continueCallback);
			});

			const cancelText = options.cancelButtonText || i18next.t('cancel');
			const continueText = options.continueButtonText || i18next.t('ok');

			if(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) ||isRestaurantNewPOS){
				console.log(options.cancelButtonText);
				$('#positive-alert .alert-continue').html(continueIcon + continueText);
				$('#positive-alert .alert-cancel').html(cancelIcon + cancelText);
			}else{
				$('#positive-alert .alert-continue').html(options.continueButtonText || i18next.t("continue"));
				$('#positive-alert .alert-cancel').html(options.cancelButtonText || i18next.t("cancel"));
			}

			if (options.imageUrl) {
				$('#positive-alert #positive-alert-content').html(
					(options.content || "") + "<br><img src='" + options.imageUrl +
					"' " + (options.imageHeight ? ("height='" + options.imageHeight + "'") : '') + ">");
			}
			else {
				$('#positive-alert #positive-alert-content').html(options.content || "");
			}

		alert.dialog('open');
		}
		export function showManagerApprovalDialog (permissions?) {
			return PositiveTS.Service.GlobalUI.showManagerApprovalDialog(permissions);
		}

		export async function showManagerApprovalDialogAndGetEmployee(permissions?) {
			try {
				return await showManagerApprovalDialog(permissions);
			} catch {
				// no employee
				return null;
			}
		}

		export function showCustomerDetailsFill (continueCallback, cancelCallback, hideCancelButton = false) {
			// Push the dialog down a little bit
			$('#prompt-customer-fill').css({"top": "20%"});

			$('#prompt-customer-fill input').autoselected();

			$('#prompt-customer-fill input[name="customer-name"]').val('');
			$('#prompt-customer-fill input[name="customer-phone"]').val('');
			$('#prompt-customer-fill input[name="customer-id"]').val('');

			$('#prompt-customer-fill input[name="customer-name"]').cleanable({
				cleanSelector: '#prompt-customer-fill-customer-name-clear'
			});
			$('#prompt-customer-fill input[name="customer-phone"]').cleanable({
				cleanSelector: '#prompt-customer-fill-customer-phone-clear'
			});
			$('#prompt-customer-fill input[name="customer-id"]').cleanable({
				cleanSelector: '#prompt-customer-fill-customer-id-clear'
			});

			// If this is not a touch device, enable keyboard
			if (!Modernizr.touch){
				$('#prompt-customer-fill input[name="customer-name"]').keyboard({
					layout     : "hebrew-qwerty-custom",//'hebrew-qwerty-custom',
					usePreview : false,
					autoAccept : true,
					position   : {
						of : $('#footer'),
						my : 'center top',
						at : 'center bottom'
					}
				})//.addTyping();
				$('#prompt-customer-fill input[name="customer-phone"],#prompt-customer-fill input[name="customer-id"]').keyboard({
					layout     : 'only-numbers',
					usePreview : false,
					autoAccept : true,
					css        : { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" },
					position   : {
						of : $('#footer'),
						my : 'center top',
						at : 'center bottom'
					}
				})//.addTyping();
			}

			// Is there a callback for the continue button?
			// Unbind the listener of the continue button
			$('#prompt-customer-fill-continue').unbind('click');
			$('#prompt-customer-fill-continue').click(function () {
				$('#prompt-customer-fill').hide();
				$('#alert-overlay').hide();
			});
			if (typeof continueCallback == 'function') {
				// --- Yes, there is a callback
				// Bind the continue button to its listener
				$('#prompt-customer-fill-continue').click(function() {
					var customerName = $('#prompt-customer-fill input[name="customer-name"]').val();
					var customerPhone = $('#prompt-customer-fill input[name="customer-phone"]').val();
					var customerID = $('#prompt-customer-fill input[name="customer-id"]').val();
					continueCallback(customerName, customerPhone, customerID);
				});
			} else {
				// --- No, there isn't a callback
			}

			// Is there a callback for the cancel button?
			// Unbind the listener of the cancel button
			$('#prompt-customer-fill-cancel').unbind('click');

			if (hideCancelButton) {
				$('#prompt-customer-fill-cancel').hide();
			} else {
				$('#prompt-customer-fill-cancel').show();
				$('#prompt-customer-fill-cancel').click(function () {
					$('#prompt-customer-fill').hide();
					$('#alert-overlay').hide();
				});
				if (typeof cancelCallback == 'function') {
					// --- Yes, there is a callback
					// Bind the cancel button to its listener
					$('#prompt-customer-fill-cancel').click(cancelCallback);
				} else {
					// --- No, there isn't a callback
				}
			}

			// Show the alert dialog overlay
			$('#alert-overlay').show();

			// Show the alert dialog
			$('#prompt-customer-fill').show();
		}

		export function sendMessageToServiceWorker(msg: {type: string, data?: any }) {
			if (navigator.serviceWorker && navigator.serviceWorker.controller) {
				navigator.serviceWorker.controller.postMessage(msg);
			}
		}

	}
}
declare var app:typeof PositiveTS.Application;
app = PositiveTS.Application;
//loadingMessageTimeout = undefined;
