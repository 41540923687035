module PositiveTS {
export module Components {
export module PosPayment {
export module BanxView {
  interface paymentResponse {
    orderId: string,
    amount: number,
    status: number
  }

  export function create() {
      const c = {
        template:  JST.BanxView(),
        mixins: [keyPadMixin, Mixins.paymentViewMixin],
        methods: {
          selectField: selectField,
          onKeyTapped: onKeyTapped,
          getSelectedField: getSelectedField,
          clearTapped: clearTapped,
          QRPayment: QRPayment,
          verifyAndPay: verifyAndPay,
          clearOldData: clearOldData,
          deleteCreditPayment: deleteCreditPayment,
          refreshView: refreshView
        },
        data: initData
      }

      VueApp.component('banx-view',c);
  }

  async function QRPayment (el) {
    el.target.disabled = true;
    try{
      const banxPayment = new Service.BanxPayment(posVC.sale, this.amount);
      app.showLoadingMessageWithCancel({message: 'ממתין לקבלת תשלום מהלקוח...'},
        banxPayment.cancelRequest, banxPayment);
      let response = await banxPayment.request();
      console.debug(response);
      this.verifyAndPay(banxPayment, response);
      app.hideLoadingMessageWithCancel();
      el.target.disabled = false;

    }catch(e){
      PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalAmount()));
      app.hideLoadingMessageWithCancel();
      el.target.disabled = false;

      if (e !== 'cancelRequest'){
        console.error(e);
        app.promiseShowAlert({
          header: i18next.t('error'),
          content: e.error,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        });
      }
    }
  }

  function initFields() {
    let fields = {
      'amount': {
        selected: false,
        allowDecimal: true,
        inEditMode: false,
        type: 'number',
        disabled: false
      }
    };

    for (let field in fields) {
      fields[field].model = field;
    }

    return fields;
  }

  async function refreshView(){
    try{

      this.setCalculatedAmount();

      let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT)
      this.creditPayments = result;
      this.clearTapped();
      await PositiveTS.Service.CustomerDisplay.setTextTotalSum(session.fixedNumber(posVC.getTotalLeftToPay()));
      await PositiveTS.VueInstance.$nextTick()
    }catch(e){
      console.error(e);
    }
  }

  async function verifyAndPay(banxPayment, response){
    try {
      await verify(response);
      await PositiveTS.Service.VoucherPayment.payBySmartVoucherType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT, response.amount, null, null, banxPayment);
      this.addSplittedPaymentIfNeeded();
    }catch(e){
      app.hideLoadingMessage();
      app.promiseShowAlert({
        header: i18next.t('error'),
        content: e.message,
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      })
    }

    await this.refreshView();
  }

  async function deleteCreditPayment(index){
    try{

      let result = await app.promiseShowAlert({
        header: i18next.t('confirmSalePaymentItemDeleteHeader'),
        content: i18next.t('confirmSalePaymentItemDelete'),
        continueButtonText: i18next.t('remove'),
        cancelButtonText: i18next.t('cancel')
      });

      if (result === "continue"){
        await PositiveTS.Service.VoucherPayment.removeSmartVoucherPayment(this.creditPayments[index]);
        this.removeSplittedPaymentIfNeeded();
      }

    }catch(e){
      console.error(e);
    }

    await this.refreshView();
  }

  function verify(response: paymentResponse):Promise<void>{
    let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
    let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);

    if (response.amount <= 0) {
      return Promise.reject(new Error(i18next.t("newCreditCard.AMOUNT_MUST_BE_BIGGER_THAN_ZERO")))
    }
     
    if (response.amount > amountLeft) {
      return Promise.reject(new Error(i18next.t("generalPaymentLeftAmountMandatoryError")))
    }

    return Promise.resolve();
  }

  function clearTapped() {
    this.clearOldData();
    this.selectField('amount');
  }


  //clears the data after a single payment but when there might be still amount to pay
  function clearOldData() {
    let result = initData();
    for (let prop in result) {
      if (prop === 'creditPayments' || prop === "amount") {
        continue;
      }
      this[prop] = result[prop];
    }
  }


  function onKeyTapped(key) {
    let field = this.getSelectedField();
    this.keyPadHanlder(key,field,this);
  }

  function getSelectedField() {
    for (let fieldName in this.fields) {
      if (this.fields[fieldName].selected) {
        return this.fields[fieldName]
      }
    }
    return undefined;
  }


  function initData() {

    return {
      cardNumberText: "",
      fields: initFields(),
      clearImg: `${(<any>window).images_path}/close.png`,
      amount: 0,
      creditPayments: []

    }
  }

  function selectField(fieldName) {
    let refField = null;
    //not liking this code so much... but want to make sure that number will be rounded
    this.amount = session.fixedFloat(this.amount);
    for (let field in this.fields) {
      this.fields[field].selected = false
      this.fields[field].inEditMode = true
      this.fields[field].hasDecimal = false
      if (field == fieldName) {
        this.fields[field].selected = true
        refField = field
      }
    }
    if (refField != null) {
      this.$refs[refField].focus();
    }
  }
}}}}
