module PositiveTS {
export module Storage {
export module Entity {
export class Paymentlimit extends WasmEntity {

	constructor() {
		let meta = {
			name: 'Paymentlimit',
			fields: {
				settingsID: "TEXT",
				tenant_id: "INT",
				company_id: "INT",
				payment_type: "TEXT" ,
				paymentcount: "INT" ,
				high_bound: "FLOAT" ,
				low_bound: "FLOAT"
			},
			unique: ['settingsID']
		}
		super(meta)
	}
}}}}
