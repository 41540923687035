module PositiveTS {
export module Promotions {
export module Templates {
export class BuyXGetAmountDiscountOnY extends TemplateAbstract {
  constructor(initParameters) {
    super(initParameters)
  }

  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (var promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      // Get the promotion
      var promotion = this.parameters.promotionsByCode[promotionCode];

      // Check that this promotion is from template 5
      if (promotion.template !== '5') { // -- Not from template 5, skip!
        continue;
      }

      this.applyPromotion(promotion);
    }
  }

  applyPromotion(promotion) {
    // -----------------------------------------------
    // | Minimum quantity and amount check
    // -----------------------------------------------
    if (!this.promotionHasMinimumQuantityAndAmount(promotion, this.parameters.promotionsBuy[promotion.code])) {
      return;
    }

    // -----------------------------------------------
    // | Is promotion in get side also check
    // -----------------------------------------------
    if (!(promotion.code in this.parameters.promotionsGet)) {
      return;
    }

    // -----------------------------------------------
    // | Flatten sale items and sort them by unitPrice
    // -----------------------------------------------
    var flattenedSaleItemsBySide = {
      'buy': this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code]),
      'get': this.flattenSaleItemsByQuantity(this.parameters.promotionsGet[promotion.code])
    };

    flattenedSaleItemsBySide.buy.sort(this.sortByUnitPriceFromExpensiveToCheapest);
    flattenedSaleItemsBySide.get.sort(this.sortByUnitPriceFromExpensiveToCheapest);

    // -----------------------------------------------
    // | Apply promotion for sale items
    // -----------------------------------------------
    // Initialize an object to save the discount per sale item
    var discountAmountForSaleItem = {};
    var itemToPromote;

    var totals = this.countTotalQuantityAndAmountOfSaleItems(this.parameters.promotionsBuy[promotion.code]);

    var allowMultipleTimesPerSale = Boolean(promotion.allowMultipleTimesSameSale);
    var allowWithOtherPromotions = Boolean(promotion.allowWithOtherPromotions);
    var promotionApplied = false;

    var i = 1;
    var promoGroups = {};


    // Start to peer item from the get side to items in the buy side
    var discountValueAmount = 0;

    let discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT;
    var minBuyQuantity = parseInt(promotion.minimumBuyQuantity);
    for (var i = 0; i <= flattenedSaleItemsBySide['buy'].length -minBuyQuantity ; i += minBuyQuantity) {

      if (!allowMultipleTimesPerSale && promotionApplied) {
        break;
      }

      itemToPromote = flattenedSaleItemsBySide['buy'][i+minBuyQuantity-1];
      if (!promoGroups[itemToPromote.id]) {
        promoGroups[itemToPromote.id] = {
          itemsCounter: {},
          promotion: promotion,
          discountAmountForGroup: 0,
          totalPriceForItemsBeforeDiscount: 0
        }
      }

      //add the promotion code to followed other items affilicated with this promotion
      for (var j=i; j < i+minBuyQuantity - 1; j++) {
        promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(flattenedSaleItemsBySide['buy'][j],promoGroups[itemToPromote.id].itemsCounter);
        promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += flattenedSaleItemsBySide['buy'][j].unitPrice;
      }

      // Initialize entry in discountAmountForSaleItem
      if (!(itemToPromote.id in discountAmountForSaleItem)) {
        discountAmountForSaleItem[itemToPromote.id] = 0;
      }
      promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(itemToPromote,promoGroups[itemToPromote.id].itemsCounter);
      promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += itemToPromote.unitPrice;

      //Apply the discount
      discountValueAmount = parseFloat(promotion.discountValue);

      //for calculations in a later stage
      discountAmountForSaleItem[itemToPromote.id] += discountValueAmount;
      promoGroups[itemToPromote.id].discountAmountForGroup += discountValueAmount;
      promotionApplied = true;
    }

    for (var saleItemID in discountAmountForSaleItem) {
      var key = this._generateUniqueKey({id: saleItemID}, promotion.code, 'get');

      // Update data...
      var saleItemTotalAmount = this.parameters.saleItemsPotentialPromotions[key].unitPrice * this.parameters.saleItemsPotentialPromotions[key].quantity;
      this.parameters.saleItemsPotentialPromotions[key].discountAbsoluteValue = session.fixedNumber(discountAmountForSaleItem[saleItemID]);
      this.parameters.saleItemsPotentialPromotions[key].discountPrecentValue = session.fixedNumber(discountAmountForSaleItem[saleItemID] / saleItemTotalAmount * 100);
      this.parameters.saleItemsPotentialPromotions[key].discountType = discountType;
      this.parameters.saleItemsPotentialPromotions[key].isPromotionGiven = true;

      promoGroups[saleItemID].item = this.parameters.saleItemsPotentialPromotions[key];
    }
    for (let key in promoGroups) {
      this.parameters.promoGroups.push(promoGroups[key]);
    }

  }
}
}}}
