module PositiveTS {
export module Components {
export module PosPayment {
export module VoucherView {
    
    export function create() {
        let voucherView = {
            template:  JST.VoucherView(),
            mixins: [keyPadMixin, Mixins.paymentViewMixin, Mixins.oneClickMixin],
            methods: {
                getPops() {
                    return this.$parent;
                },
                openVoucherDialog() {
                    voucherTypeDialog.open();
                },
                handleAmountChanged(){
                    if(posUtils.isBlank(this.voucher.value)) {
                        if(this.amount > this.amountLeft) {
                            app.showAlertDialog({
                                header: i18next.t('error'),
                                content: i18next.t('selfService.amountExceedsTotalAmount'),
                                continueButtonText: i18next.t("ok"),
                                hideCancelButton: true
                            }, null, null);
                            this.initialValues.amount = '';
                            this.putAllAmountToPayInAmountField();
                        }
                    }
                },

                async swipeCard() {
                    try {
                        app.showLoadingMessage("העבר כרטיס");
                        
                        let cardNumber = await Service.EMV.swipe(this.amount)
                        if(!posUtils.isNullOrUndefinedOrEmptyString(cardNumber)){
                           this.barcode = cardNumber;
                        }
                        app.hideLoadingMessage();
                    }
                    catch(e)  {
                        app.hideLoadingMessage();
                        console.error(e);
                    }
                },
                async checkBalance() {
                    let response = await this.voucher.getBalance(this.barcode, this.cvv);
                    if(response.success) {
                        app.showAlert({
                            header: i18next.t('balanceCheck.title'),
                            content: response.balance,
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true
                          }, null, null);
                    } else {
                        app.showAlert({
                            header: i18next.t('error'),
                            content: response.error,
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true
                          }, null, null);
                    }
                },
                async putAllAmountToPayInAmountField() {
                    await PositiveTS.VueInstance.$nextTick();

                    this.setCalculatedAmount();
                    this.$refs.amountField.focus();
                    await PositiveTS.VueInstance.$nextTick();
                },
                async setBarcodeFocus(){
                    await PositiveTS.VueInstance.$nextTick()
                    this.$refs.barcodeField.focus();
                },
                async verifyAndPay() {
                    try {
                        let amount = this.amount;
                        let barcode = this.barcode;
                        let cvv = this.cvv;

                        this.clearTapped();

                        let valid = await Service.VoucherPayment.validateVoucherPayment(this.voucherType, amount, barcode, this.voucherSalePaymentData);
                        if(valid.success) {
                            app.showLoadingMessage(i18next.t('selfService.chargingCard'));
                            let salePayment = posPaymentVC.getOrCreatePayment(this.paymentType);
                            let response = await Service.VoucherPayment.payWithVoucherEx(salePayment, Number(amount), barcode, undefined, this.voucherType, undefined, cvv)
                            app.hideLoadingMessage();
                            if(!response.success) {
                                app.showAlert({
                                    header: i18next.t('error'),
                                    content: response.error,
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                }, null, null);
                            } else {
                                this.addSplittedPaymentIfNeeded();
                            }
                        } else {
                            app.showAlert({
                                header: i18next.t('error'),
                                content: valid.error,
                                continueButtonText: i18next.t("ok"),
                                hideCancelButton: true
                            }, null, null);
                        }
                    } catch(err) {
                        app.hideLoadingMessage();
                        app.showAlert({
                            header: i18next.t('error'),
                            content: err.message,
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true
                        }, null, null);
                    }
                    this.updateVoucherSalePaymentData()
                },
                onKeyTappedForVoucher(key) {
                    this.onKeyTapped(key);
                    this.handleAmountChanged()
                },

                async refreshView() {
                    this.clearTapped();
                    Pinia.globalStore.setSelectedVoucherType(null)
                    this.updateVoucherSalePaymentData()
                    await PositiveTS.VueInstance.$nextTick()
                },

                async deleteCreditPayment(paymentData, index) {
                    try {
                        let voucherPayments = PositiveTS.Service.VoucherPayment.getVoucherPayments();
                        for (let vpIndex = 0; vpIndex < voucherPayments.length; vpIndex++) {  
                            let res = await Service.VoucherPayment.deleteVoucherPaymentAndPersist(voucherPayments[vpIndex], paymentData);

                            if (this.isSplitPaymentActive() && res.success) {
                                this.removeSplittedPaymentIfNeeded();
                                this.setSplitPaymentAmount();    

                            }
                            if (res.success) {
                                break;
                            }

                            if (posUtils.isDefined(res.error) &&  vpIndex + 1 === voucherPayments.length) {
                                app.showAlert({
                                    header: i18next.t('error'),
                                    content: res.error,
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                }, null, null);
                            }
                        }
                    } catch (err) {
                        console.error(err);
                    }

                    this.updateVoucherSalePaymentData()
                },

                updateVoucherSalePaymentData () {
                    let result = []

                    let voucherPayments = posVC.salePayments.filter((payment) => 
                        [Storage.Entity.SalePayment.METHOD_VOUCHER,
                        Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT].indexOf(payment.method) > -1);
                    
                    if(!posUtils.isNullOrUndefined(voucherPayments[0]) && !posUtils.isBlank(voucherPayments[0].data)) {
                        let data = JSON.parse(voucherPayments[0].data);
                        //in case we have both discount vouchers and regular vouchers...
                        if (!posUtils.isNullOrUndefined(voucherPayments[1]) && !posUtils.isBlank(voucherPayments[1].data)) {
                            data = data.concat(JSON.parse(voucherPayments[1].data))
                        }
                        result = data;
                    }

                    this.voucherSalePaymentData = result
                },
            },

            data() {
                return {
                    amount: "0",
                    voucher: {},
                    barcode: null,
                    cvv: null,
                    initialValues: {
                        amount: 0,
                        barcode: "",
                        cvv: ""
                    },
                    fields: {
                        amount: {
                            selected: true,
                            allowDecimal: true,
                            inEditMode: true,
                            type: 'number',
                            model: 'amount'
                          },
                          barcode: {
                            selected: false,
                            allowDecimal: false,
                            inEditMode: true,
                            type: 'string',
                            model: 'barcode'
                          },
                          cvv: {
                            selected: false,
                            allowDecimal: false,
                            inEditMode: true,
                            type: 'number',
                            model: 'cvv'
                          },
                    },
                    voucherSalePaymentData: []
                }
            },

            computed:  {
                voucherName(){
                    if(!posUtils.isBlank(Pinia.globalStore.selectedVoucherType) && !posUtils.isBlank(Pinia.globalStore.selectedVoucherType.name)) {
                        return Pinia.globalStore.selectedVoucherType.name;
                    }
                    return "";
                },
                voucherValue(){
                    if(!posUtils.isBlank(Pinia.globalStore.selectedVoucherType) && !posUtils.isBlank(Pinia.globalStore.selectedVoucherType.value)) {
                        return Pinia.globalStore.selectedVoucherType.value;
                    }
                    return null;
                },
                hasBarcode(){
                    if(!posUtils.isBlank(Pinia.globalStore.selectedVoucherType) && !posUtils.isBlank(Pinia.globalStore.selectedVoucherType.hasBarcode) && (Pinia.globalStore.selectedVoucherType.hasBarcode || Pinia.globalStore.selectedVoucherType.hasBarcode == 1)) {
                        return true;
                    }
                    return false;
                },
                isSmartVoucher(){
                    if(!posUtils.isBlank(Pinia.globalStore.selectedVoucherType)) {
                        return Service.SmartVoucher.isSmartVoucher(Pinia.globalStore.selectedVoucherType.smartVoucherType);
                    }
                    return false;
                },
                paymentType() {
                    if(this.voucherType && this.voucherType.isDiscountVoucher) {
                        return Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;
                    }
                    return Storage.Entity.SalePayment.METHOD_VOUCHER;
                }
            },
            setup(){

                const globalStore = Pinia.useGlobalStore();
                const {mobileLayout,selectedVoucherType,leftAmount} = Pinia.storeToRefs(globalStore);
                const globalStoreProps = {isMobile:mobileLayout,voucherType:selectedVoucherType,amountLeft:leftAmount} 
  
                return {...globalStoreProps}
              },
            watch: {
                voucherType(newValue, oldValue) {
                    if(!posUtils.isBlank(newValue)) {
                        if(Service.SmartVoucher.isSmartVoucher(newValue.smartVoucherType)) {
                            this.voucher =  PositiveTS.Service.SmartVoucherFactory.createVoucher(newValue.smartVoucherType);
                        } else {
                            this.voucher = newValue;
                        }
                        if(!posUtils.isBlank(newValue.value)) {
                            this.amount = newValue.value;
                            this.initialValues.amount = newValue.value;
                        }
                        else {
                            this.amount = '';
                            this.initialValues.amount = '';
                            this.putAllAmountToPayInAmountField();
                        }
                    } else {
                        this.voucher = {};
                        this.initialValues.amount = '';
                    }
  
                    if(this.voucherType?.typeID === "500"){
                        this.setBarcodeFocus()
                    }
                },
            }
        }
        VueApp.component('voucher-payment-view',voucherView);
    }
}}}}
