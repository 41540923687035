import { ref } from 'vue';

export function useOneClick() {
  const functionRunning = ref(false);

  async function oneTimeRun(functionToRun, ...args) {
    if (functionRunning.value) {
      console.log(functionToRun);
      PositiveTS.Service.Logger.error("prevented double click");
      return;
    }
    functionRunning.value = true;
    try {
      console.log(`oneClick Running on function ${functionToRun.name}`);
      console.log(functionToRun);
      await functionToRun(...args);
    } catch (err) {
      console.log(`oneClick Failed on function ${functionToRun.name}`);
      if (!posUtils.isNullOrUndefined(err)) {
        console.error(err);
      }
    } finally {
      console.log(`oneClick Finished function ${functionToRun.name}`);
      functionRunning.value = false;
    }
  }

  return { functionRunning, oneTimeRun };
}
