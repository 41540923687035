module PositiveTS {
    export module Components {
        export module SelfServiceScreenSaver {
            const dialogSelector = "self-service-screen-saver"


            export function create() {
                let component = {
                    template: JST.selfServiceScreenSaver(),
                    methods: {
                        openIfNeeded() {
                            if (this.hasAnyPicturesAtAll && !this.imagesIntervalFunc && !this.deactivateScreenSaver) {
                                this.open()
                            } else {
                                if (this.hasAnyVideosAtAll && !this.isPlayingVideo && !this.deactivateScreenSaver) {
                                    this.open()
                                }
                            }

                        },

                        open() {
                            if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverType) === "video") {
                                this.videoIndex = 0;
                                this.showVideo = true;
                                this.isPlayingVideo = true;
                                this.videoCycle();
                            } else {
                                this.imageIndex = 0;
                                this.showLocalPicture();
                            }
                            this.$el.showModal();
                            clearTimeout(this.screenSaverTimeoutFunc)
                        },

                        close(showWelcomeScreen = true) {
                            if (Pinia.globalStore.isSelfServicePosLoked) {
                                PositiveTS.Service.GlobalUI.closeManagerApproval();
                                if (!this.deactivateScreenSaver && !this.isLockedPinpadSelfService) {
                                    this.activateWithTimeout(15000)
                                }
                            }
                            this.$el.close();
                            clearInterval(this.imagesIntervalFunc)
                            this.imagesIntervalFunc = null;
                            this.isPlayingVideo = false;
                            if (session.pos.welcomeScreen && showWelcomeScreen) {
                                Pinia.componentsStore.openComponent({ componentName: "selfServiceWelcomeScreen", args: [] });
                            }
                        },

                        async showLocalPicture() {
                            this.imagesIntervalFunc = setInterval(async () => {
                                if (this.useOldScreenSaver) {
                                    await this.oldImagesCycle();
                                } else {
                                    this.newImagesCycle();
                                }
                            }, this.screenSaverImagesIntervalTimer)
                        },

                        async oldImagesCycle() {
                            this.showImage = false
                            if (this.imageIndex >= this.imageFileNamesArray.length) {
                                this.imageIndex = 0;
                            }
                            this.imageToShow = 'data:image/gif;base64,' + await PositiveTS.Service.FileUpload.readFileBase64(`c:/valu/promo_pictures/${this.imageFileNamesArray[this.imageIndex].trim()}`);
                            this.showImage = true;
                            this.imageIndex++;
                        },

                        async videoEnded() {
                            await this.videoCycle();
                        },
                        async videoCycle() {
                            if (this.videoIndex >= this.videoUrls.length) {
                                this.videoIndex = 0;
                            }
                            this.videoToShow = this.videoUrls[this.videoIndex];
                            this.videoIndex++;
                            // Logic to preload the next video
                            const videoElement = this.$refs.videoPlayer;
                            if (videoElement) {
                                // When the video metadata is loaded, set up the timer
                                videoElement.onloadedmetadata = () => {
                                    const duration = videoElement.duration;
                                    const preloadTime = 1000; // Time in milliseconds before the video ends to start preloading the next video

                                    setTimeout(() => {
                                        this.preloadNextVideo();
                                    }, (duration * 1000) - preloadTime);
                                };
                            }
                        },

                        async preloadNextVideo() {
                            if (this.videoIndex >= this.videoUrls.length) {
                                this.videoIndex = 0;
                            }
                            this.nextVideoToShow = this.videoUrls[this.videoIndex];
                            this.videoIndex++;
                        },

                        activateWithTimeout(ms) {
                            this.clearScreenSaverTimeout();
                            this.screenSaverTimeout = setTimeout(() => {
                                this.openIfNeeded();
                            }, ms)
                        },
                        clearScreenSaverTimeout() {
                            if (this.screenSaverTimeout) {
                                clearTimeout(this.screenSaverTimeout)
                                this.screenSaverTimeout = null
                            }
                        },
                        newImagesCycle() {
                            this.showImage = false
                            if (this.imageIndex >= this.dbBase64Imgs.length) {
                                this.imageIndex = 0;
                            }
                            this.imageToShow = this.currentScreenSaverImage();
                            this.showImage = true;
                            this.imageIndex++;
                        },

                        currentScreenSaverImage() {
                            return this.dbBase64Imgs[this.imageIndex].base64;
                        },

                        async loadPictures() {
                            this.showVideo = false;
                            this.allDbBase64Imgs = await appDB.screenSavers.toArray();
                            let selectedArr: Array<any> = jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverSelectedImages);
                            this.dbBase64Imgs = selectedArr.length > 0 ? selectedArr.map(id => this.allDbBase64Imgs.find(i => i.id == id)) : this.allDbBase64Imgs;
                            this.dbBase64Imgs = this.dbBase64Imgs.filter(img => posUtils.isBlank(img) === false);

                            const allimages = await PositiveTS.Service.FileManager.listDirFiles("C:\\valu\\promo_pictures");
                            this.imageFileNamesArray = allimages.request.result.data !== "Empty diretory" ? allimages.request.result.data.split("\n") : [];
                        },

                        async loadVideos() {
                            this.showImage = false;
                            let videos = await appDB.videos.toArray();
                            let selectedArr: Array<any> = jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverSelectedVideos);
                            this.selctedVideos = selectedArr.length > 0 ? selectedArr.map(id => videos.find(i => i.id == id)) : videos;
                            this.videoUrls = this.selctedVideos.map(video => video.url);
                        }
                    },
                    data() {
                        return {
                            imagesIntervalFunc: null,
                            screenSaverTimeout: null,
                            imageToShow: null,
                            imageFileNamesArray: [], // Aka the old pictures loaded from PC's storage
                            allDbBase64Imgs: [], // Aka the new pictures loaded from Indexed DB
                            dbBase64Imgs: [], // Selected Images for this POS
                            imageIndex: 0,
                            showImage: true,
                            // Videos
                            videoUrls: [],
                            videoIndex: 0,
                            videoToShow: null,
                            nextVideoToShow: null,
                            showVideo: false,
                            isPlayingVideo: false,
                        }
                    },
                    setup() {

                        const globalStore = Pinia.useGlobalStore();
                        const {
                            isLockedPinpadSelfService,
                            deactivateScreenSaver,
                            onSelfServicePaymentDialog,
                            isLoadingMessageActive
                        } = Pinia.storeToRefs(globalStore);

                        const globalStoreProps = {
                            isLockedPinpadSelfService,
                            deactivateScreenSaver,
                            isLoadingMessgaeActive: isLoadingMessageActive,
                            isOnPaymentScreen: onSelfServicePaymentDialog
                        }

                        return { ...globalStoreProps }
                    },
                    computed: {
                        canActivateScreenSaver() {
                            return jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)
                                && jsonConfig.getVal(jsonConfig.KEYS.standaloneMode)
                                && jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableScreenSaver);
                        },
                        screenSaverImagesIntervalTimer() {
                            let seconds: number = jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverImageTimer);
                            if (seconds < 2) {
                                seconds = 2;
                            }
                            return seconds * 1000;
                        },
                        hasAnyPicturesAtAll() {
                            return this.imageFileNamesArray.length > 0 || this.allDbBase64Imgs.length > 0;
                        },
                        hasAnyVideosAtAll() {
                            return this.videoUrls.length > 0;
                        },
                        useOldScreenSaver() {
                            if (this.allDbBase64Imgs.length > 0) {
                                return false;
                            } else if (this.imageFileNamesArray.length > 0) {
                                return true;
                            } if (this.showVideo) {
                                return false;
                            } else {
                                console.warn("no screen savers set in Pos and the Store")
                                return null;
                            }
                        },
                    },
                    async mounted() {
                        if (!this.canActivateScreenSaver) {
                            return;
                        }
                        if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverType) === "video") {
                            await this.loadVideos();
                        } else {
                            await this.loadPictures(); // This must be before 'useOldScreenSaver'.
                            if (this.useOldScreenSaver) {
                                this.imageToShow = 'data:image/gif;base64,' + await PositiveTS.Service.FileUpload.readFileBase64('c:/valu/promo_pictures/' + this.imageFileNamesArray[0].trim())
                            } else {
                                if (this.dbBase64Imgs.length <= 0) { // In case the some genius forgot to setup correctly POS/Store
                                    console.warn('No (base64) images were found for the POS/Store. Please check everything is set correctly');
                                    this.dbBase64Imgs.push({ base64: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' });
                                }
                                this.imageToShow = this.currentScreenSaverImage();
                            }
                        }

                    },
                    watch: {
                        isLoadingMessgaeActive: function (isLoadingMessageActive: Boolean) {
                            if (this.canActivateScreenSaver && isLoadingMessageActive) {
                                this.close(false)
                            }
                        },

                        isOnPaymentScreen: function (isOnPaymentScreen: Boolean) {
                            if (this.canActivateScreenSaver && isOnPaymentScreen) {
                                this.close(false)
                            }
                        },
                    }
                }

                VueApp.component('self-service-screen-saver', component)
            }



        }
    }
}
