module PositiveTS {
    export module Components {
    export module PosPayment {
    export module CreditVoucherView {
        export function create() {
            let creditVoucherView = {
                template:  JST.CreditVoucherView(),
                mixins: [keyPadMixin, Mixins.paymentViewMixin, Mixins.oneClickMixin],
                methods: {            
                    isPromotionValid(promotionNumber):Promise<{amount:number,error:string}> {
                        
                        return new Promise((resolve,reject) => {
                          // Find the voucher method type
                          let currentMethodType = PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER;
                                
                          // Find a sale payment entity with the same method
                          let salePayment = null;
                          for (let i = 0; i < posVC.salePayments.length; i++) {
                            // Get the payment
                            let aSalePayment = posVC.salePayments[i];
                            
                            // Check that the sale payment has the current method
                            if (aSalePayment.method == currentMethodType) {
                              salePayment = aSalePayment;
                              break;
                            }
                          }
                  
                          // Do not allow to use same promotion in same invoice
                          if (salePayment != null) {
                            var paymentData = JSON.parse(salePayment.data);
                            
                            for (var i = 0; i < paymentData.length; i++) {
                              if (paymentData[i].promotionNumber == promotionNumber) {
                                return resolve({amount: 0, error: i18next.t("promotionUsed")});
                              }
                            }
                          }
                  
                  
                          app.showLoadingMessage(i18next.t("voucherPaymentBarcodeOnlineValidationLoadingMsg"));
                  
                          // Call the service and get the voucher
                          PositiveTS.Service.CreditVoucher.GetCreditVoucher(
                            promotionNumber,
                            function(data) {
                              app.hideLoadingMessage();
                              if (data === null || data === undefined || typeof(data) !== 'object') {
                                return resolve({amount: 0, error: i18next.t("promotionNotExist")});
                              }
                              
                              if (data.is_used) {
                                return resolve({amount: 0, error: i18next.t("promotionUsed")});
                              }
                                
                              return resolve({amount: data.amount, error: null});
                            }
                            );
                        })
                        
                        
                    },
                    async verifyAndPay() {
                      let salePayment = posPaymentVC.getOrCreatePayment(Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER);
                      let promotionNumber = this.voucher;
                      if (this.isInCredit) {
                          return;
                      }
                      this.isInCredit = true;
                  
                      if (session.pos.parameterSpecialItemBlockCreditVouchers &&
                        PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems,posVC.Specialitems) ){
                          this.isInCredit = false;
                            app.showAlert({
                              header: i18next.t('error'),
                              content: i18next.t('specialitemAndCreditVoucherNotAllowed'),
                              continueButtonText: i18next.t("ok"),
                              hideCancelButton: true,
                              noHandleEnterEscape: true
                            }, function () {
                              return;
                            }, null);
                            return;
                      }
                  
                      //Build the data object
                      try {
                    
                        let result = await this.isPromotionValid(promotionNumber);
                        let amount = result.amount;
                        let error = result.error;
                    
                        if (amount <= 0) {
                          //Show alert to the user
                          this.isInCredit = false;
                          app.showAlert({
                            header: i18next.t('error'),
                            content: error !== undefined ? error : i18next.t('promotionNonValid'),
                            continueButtonText: i18next.t("ok"),
                            hideCancelButton: true,
                            noHandleEnterEscape: true
                          }, function () {
                            return;
                          }, null);
                          return;
                        }
                
                        //Create dataObject array
                        var dataObj = [];
                
                        //init the old dataObject
                        if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1){
                          dataObj = JSON.parse(salePayment.data);
                        }
                
                        dataObj.push ({
                          'amount' 		   : amount,
                          'promotionNumber'  : promotionNumber.trim(),
                          'creditVoucherType': PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_DEBIT
                        });
                
                        //Set the salePayment with the data object
                        salePayment.data  = JSON.stringify(dataObj);
                
                
                        // Set the amount of the sale payment
                        if (amount == null || <any>amount == '') {
                          salePayment.amount = 0;
                        } else {
                          salePayment.amount += amount;
                        }
                
                        // Persist the sale payment
                        await posVC.persistSalePayment(salePayment);
                      }
                      finally {
                        this.isInCredit = false;
                        this.updateCreditVoucherSalePaymentData()
                      }
                    },
                    async deleteCreditPayment(payment, index){

                        let creditVoucherPayment = this.creditVoucherPayment;
                        creditVoucherPayment.amount -= Number(payment.amount);
                        
                        let paymentData = JSON.parse(creditVoucherPayment.data);
                        paymentData.splice(index, 1);
                        creditVoucherPayment.data = JSON.stringify(paymentData);
                        await posVC.persistSalePayment(creditVoucherPayment);
                        this.updateCreditVoucherSalePaymentData()
                    },
                    async refreshView() {
                        this.clearTapped();
                        this.updateCreditVoucherSalePaymentData()
                        await PositiveTS.VueInstance.$nextTick()
                        this.$refs.voucherInput.focus();
                    },

                    updateCreditVoucherSalePaymentData () {
                      let result = []
                      let creditVouchers = posVC.salePayments.filter((payment) => payment.method == Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER);
                      if(!posUtils.isNullOrUndefined(creditVouchers[0]) && !posUtils.isBlank(creditVouchers[0].data)) {
                        result = JSON.parse(creditVouchers[0].data);
                      }
                      this.creditVoucherSalePaymentData = result
                    },
                },
                computed:  {
                  creditVoucherPayment () {
                    return posVC.salePayments.find(payment => payment.method == Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER)
                  },
                  isMobile(){
                    return Pinia.globalStore.mobileLayout
                  },
                },

                data() {
                    return {
                        isInCredit: false,
                        voucher: "",
                        initialValues: {
                            voucher: ""
                        },
                        fields: {
                            voucher: {
                                selected: true,
                                allowDecimal: false,
                                inEditMode: false,
                                type: 'string',
                                disabled: false,
                                model: 'voucher'
                              },
                        },
                        creditVoucherSalePaymentData: []
                    }
                }
            }
            VueApp.component('credit-voucher-payment-view',creditVoucherView);
        }
    }}}}
