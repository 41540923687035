module PositiveTS {
export module Application {
export module Controllers {
export class HomepageViewController extends AbstractViewControllerTS {
	init() {
		var aThis = homepageVC;
		$('.params-PositiveVersionAppVersion').text(`${PositiveTS.Version.appVersion}`);
		$('.params-PositiveVersionAppDate').text(` - ${PositiveTS.Version.appDate}`);
		if (PositiveTS.Reachability.isOnline) {
			aThis.checkVersionAndRedirectIfNeeded();
		}
	}
	async checkVersionAndRedirectIfNeeded() {
		if (app.CheckedNewVersion) {
			return;
		}

		try {
			app.showLoadingMessage(i18next.t("checkingForNewVersion"));
			let response = await Service.Ajax.promiseJqAjax('/get_version_url?device_id=' + session.pos.deviceID,null,"GET")
			response = JSON.parse(response.request.result);
			window.localStorage.setItem("Positive.VersionPath",response.url);
			if (response.url !== location.pathname) {
				location.replace(response.url);
				return;
			}
			await this.unregisterUnreleventServiceWorkers(response.url);
			app.hideLoadingMessage();
			app.CheckedNewVersion = true;
			if(session.pos.isCaveret) {
				await Service.CaveretFiles.createVersionFile();
			}
			if(localStorage.getItem("shouldReloadData") == "true"){
				localStorage.removeItem("shouldReloadData");
				statsVC.reloadAllData(true);
			}
			await Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.POS_LOADED, session.pos.employeeID);
		} catch (err) {
			console.error(err);
			Service.Logger.error(err);
			app.hideLoadingMessage()
			app.CheckedNewVersion = true;	
		}
		
		
	}

	async unregisterUnreleventServiceWorkers(versionPath: string) {
		console.info('Removing unrelated Service Workers for current version');
		if ('serviceWorker' in navigator) {
			let registrations = await navigator.serviceWorker.getRegistrations();
	
			for(let registration of registrations) {
				if(!registration.scope.endsWith(versionPath)) {
					console.info('Unregister Service Worker for ::: ', registration.scope);
					await registration.unregister();
				}
			 }
		} else {
			console.warn('Service Worker not found.')
		}   
	}
}}}}
declare var homepageVC:PositiveTS.Application.Controllers.HomepageViewController;
homepageVC = new PositiveTS.Application.Controllers.HomepageViewController()
PositiveTS.Application.Controllers.instances.HomepageViewController = homepageVC;
