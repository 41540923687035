module PositiveTS {
   export module Service {
      export module DalpakInfra {

         export class DalpakWebsocketEngine extends DalpakEngine implements WebsocketSync.DalpakSyncClientListener {

            static ERROR_CODE_TRANSLATION_KEYS = {
               0: 'dalpaks.errorConnectingToDalpakServer',
               1: 'dalpaks.dalpakIsLocked',
               2: 'dalpaks.mainPosConnectionProblem',
            };

            private webSocket: WebsocketSync.SyncServerClient;

            constructor(private isPrimaryPos: boolean, primaryPosPrimaryPosOfflineManager: WebsocketSync.PrimaryPosOfflineManager = null) {
               super();

               let websocketModule = new WebsocketSync.DalpakSyncModule(this, primaryPosPrimaryPosOfflineManager);
               this.webSocket = WebsocketSync.SyncServerClient.getInstance();
               this.webSocket.addModule(websocketModule);
            }
            onConnectionEstablished() {
               this.syncFailedDeliveriesIfNeeded();
            }

            protected error(errCode: number = null, data: any = null, tanslateData = {}): InfraResult<any> {

               let errorKeyCode = 'generalError';

               if (DalpakWebsocketEngine.ERROR_CODE_TRANSLATION_KEYS[errCode]) {
                  errorKeyCode = DalpakWebsocketEngine.ERROR_CODE_TRANSLATION_KEYS[errCode];
               }

               return {
                  success: false,
                  data: data,
                  errorMessage: i18next.t(errorKeyCode, tanslateData),
               };
            }

            protected success(data: any = null): InfraResult<any> {
               return {
                  success: true,
                  data: data,
                  errorMessage: null
               };
            }

            dalpakCreated(dalpak: Dalpak) {
               Pinia.dalpaksStore.dalpakCreated(dalpak)
               this.backupData();
            }
            dalpakUpdated(dalpak: Dalpak) {
               Pinia.dalpaksStore.dalpakUpdated(dalpak)
               this.backupData();
            }
            dalpakDeleted(dalpak: Dalpak) {
               Pinia.dalpaksStore.dalpakDeleted(dalpak)
               this.backupData();
            }
            setDalpaks(dalpaks: Dalpak[]) {
               Pinia.dalpaksStore.setDalpaks(dalpaks)
               this.backupData();
            }

            dalpaksAreasChanged(dalpaks: Dalpak[]) {
               Pinia.dalpaksStore.dalpaksAreasChanged(dalpaks)
               this.backupData();
            }

            setDalpakAreas(dalpakAreas: DalpakArea[]) {
               let areasArray = _.isEmpty(dalpakAreas) ? [] : dalpakAreas;
               Pinia.dalpaksStore.setDalpakAreas(areasArray)
            }

            async backupData() {
               if (this.isPrimaryPos) {
                  await appDB.dalpaks.clear();
                  await appDB.dalpaks.bulkPut(this.getDalpaksState());  
                  await appDB.dalpakAreas.clear();
                  await appDB.dalpakAreas.bulkPut(this.getDalpaksAreaState()); 
               }
            }

            protected async makeActionAndWaitForResult(actionName: string, actionData = null): Promise<any> {
               try {
                  return await this.webSocket.makeActionAndWaitForResult(actionName, actionData);
               } catch(err) {
                  return {
                     success: false,
                     data: false,
                     error: 0,
                  }
               }
            }

            getDalpaksState() {
               return Pinia.dalpaksStore.allDalpaks;
            }

            getDalpaksAreaState() {
               return Pinia.dalpaksStore.allDalpakAreas;
            }

            private getDalpakById(dalpakId) {
               return Pinia.dalpaksStore.dalpaksById[dalpakId];
            }

            async getDalpak(dalpakId: string): Promise<InfraResult<Dalpak>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  if (!this.isPrimaryPos) {
                     return await this.makeActionAndWaitForResult('getFullSales')
                  }

                  return this.success(this.getDalpakById(dalpakId));
               });
            }

            async getDalpakArea(dalpakAreaId: string): Promise<InfraResult<Dalpak>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  if (!this.isPrimaryPos) {
                     return await this.makeActionAndWaitForResult('getAllDalpakAreas')
                  }

                  return this.success(this.getAllDalpakAreas());
               });
            }

            async getAndLock(dalpakId: string): Promise<InfraResult<Dalpak>> {
               let currentDalpak = this.getDalpakById(dalpakId);

               if (this.isLocked(currentDalpak)) {
                  return this.error(1, currentDalpak, { pos: currentDalpak.lockedBy });
               }

               return this.makeActionAndUpdateDalpak('lockDalpak', dalpakId);
            }

            async getAll(): Promise<InfraResult<Dalpak[]>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  return this.success(this.getDalpaksState());
               });
            };

            async getAllDalpakAreas(): Promise<InfraResult<DalpakArea[]>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  return this.success(this.getDalpaksAreaState());
               });
            };

            async saveDalpakAttributesAndUnlock(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  if (dalpak.id) {
                     return await this.makeActionAndUpdateDalpak('updateDalpak', dalpak)
                  } else {
                     let result = await this.makeActionAndWaitForResult('createDalpak', dalpak);

                     if (result.success) {
                        this.dalpakCreated(result.data);
                     }

                     return result;
                  }
               });
            }

            async saveDalpakAreaAttributes(dalpakArea: Dalpak): Promise<InfraResult<DalpakArea>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result 
                  if (dalpakArea.id) {
                     result =  await this.makeActionAndWaitForResult('updateDalpakArea', dalpakArea)
                  } else {
                     result = await this.makeActionAndWaitForResult('createDalpakArea', dalpakArea);
                  }
                  
                  return result;
               });
            }

            async saveSale(dalpak: Dalpak, unlock: boolean): Promise<InfraResult<Dalpak>> {
               return await this.makeActionAndUpdateDalpak(unlock ? 'saveSaleAndUnlock' : 'saveSale', dalpak);
            }

            async deleteDalpak(dalpak: Dalpak): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result = await this.makeActionAndWaitForResult('deleteDalpak', dalpak);

                  if (result.success) {
                     this.dalpakDeleted(result.data);
                  }

                  return result;

               });
            }

            async deleteDalpakArea(dalpakArea: DalpakArea): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result = await this.makeActionAndWaitForResult('deleteDalpakArea', dalpakArea);

                  return result;

               });
            }

            async markSaleAsPrinted(dalpak: Dalpak): Promise<InfraResult<Dalpak>> {
               return await this.makeActionAndUpdateDalpak('markSaleAsPrinted', dalpak);
            }

            async unlock(dalpak: Dalpak): Promise<InfraResult<boolean>> {
               let res:any = await this.makeActionAndUpdateDalpak('unlockDalpak', dalpak);
               res.data = res.success;

               return res;
            }

            async bulkUpdateDalpaksAndAreasAttributes(data: any): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result = await this.makeActionAndWaitForResult('bulkUpdateDalpaksAndAreasAttributes', data);

                  
                  if (result.success) {
                     this.setDalpaks(result.data.dalpaks);
                     this.setDalpakAreas(result.data.dalpakAreas);
                  }
                  
                  return result;

               });
            }

            async bulkPut(data: Dalpak[]): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result = await this.makeActionAndWaitForResult('bulkPut', data);

                  if (result.success) {
                     this.setDalpaks(result.data);
                  }
                  
                  return result;
               });
            }

            async bulkPutDalpakAreas(data: DalpakArea[]): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  
                  let result = await this.makeActionAndWaitForResult('bulkPutDalpakAreas', data);
                  

                  return result;
               });
            } 


            async primaryPosSyncToServer(data: Dalpak[]): Promise<InfraResult<boolean>> {
               return await this.makeActionAndWaitForResult('bulkPut', data);
            }

            async primaryPosSyncDalpakAreasToServer(data: DalpakArea[]): Promise<InfraResult<boolean>> {
               return await this.makeActionAndWaitForResult('bulkPutDalpakAreas', data);
            }

            async makeActionAndUpdateDalpak(action, data): Promise<InfraResult<Dalpak>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result = await this.makeActionAndWaitForResult(action, data);

                  if (result.success) {
                     this.dalpakUpdated(result.data);
                  }

                  return result;
               });
            }


            async moveDalpaksToArea(dalpaks: Dalpak[], newDalpakAreaRailsId: string): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  let result = await this.makeActionAndWaitForResult('moveDalpaksToArea', {dalpaks, newDalpakAreaRailsId});

                  if (result.success) {
                     this.dalpaksAreasChanged(result.data);
                  }
                  
                  return result;
               });
            }

            async validateConnectionAndDo(action: () => Promise<any>): Promise<any> {
               let result:any = await this.webSocket.validateConnectionAndDo(action);

               return result.success ? this.success(result.data) : this.error(result.errorCode,result.data, result.data)
            }

            async isCurrentDataValid(): Promise<InfraResult<boolean>> {
               return await this.validateConnectionAndDo(async (): Promise<any> => {
                  return this.success(true);
               });
            };
            
            isDataAlwaysUpToDate(): boolean {
               return true;
            }
         }
      }
   }
}
