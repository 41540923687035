module PositiveTS {
    export module Service {
        export module InactivityService {

            let config:any = {};
            let timers:any = {};
            let allowResetTimers = true;
            let resetTimersThrottle = _.throttle(()=>{resetTimersIfNeeded()}, 1000);

            export function init() {

                setConfig();

                if (Object.keys(config).length > 0) { // If there is at least one inactivity timer, set the events
                    Vue.watch(
                        () => Pinia.globalStore.onSelfServicePaymentDialog,
                        (newValue) => {
                            setAllowResetTimersAndHandleTimers(newValue);
                        }
                    );
        
                    Vue.watch(
                        () => Pinia.globalStore.isLoadingMessageActive,
                        (newValue) => {
                            setAllowResetTimersAndHandleTimers(newValue);
                        }
                    );
                    setAllowResetTimersAndHandleTimers(Pinia.globalStore.onSelfServicePaymentDialog || Pinia.globalStore.isLoadingMessageActive);
                    
                    document.addEventListener('click', resetTimerThrottleIfNeeded);
                    document.addEventListener('mousemove',resetTimerThrottleIfNeeded);
                    document.addEventListener('mousedown',resetTimerThrottleIfNeeded);
                    document.addEventListener('touchstart',resetTimerThrottleIfNeeded);
                    document.addEventListener('keypress',resetTimerThrottleIfNeeded);
                }
            }

            function setConfig() {
                if(jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) && jsonConfig.getVal(jsonConfig.KEYS.standaloneMode) && jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableScreenSaver)){
                    let screenSaverTime: number = jsonConfig.getVal(jsonConfig.KEYS.selfServiceScreenSaverTimer);
                    if (screenSaverTime <= 0.2) {
                        screenSaverTime = 0.2;
                    }

                    screenSaverTime = screenSaverTime * 60000

                    config.screenSaver = {
                        time: screenSaverTime,
                        action: () => PositiveTS.VueInstance.$refs.selfServiceScreenSaver.openIfNeeded(),
                    };
                }

                if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceInactivityTimer) > 0) {
                    config.restartSale = {
                        time: jsonConfig.getVal(jsonConfig.KEYS.selfServiceInactivityTimer) * 60000,
                        action: () => restartSaleIfNeeded(),
                    }
                }
            }


            function resetTimerThrottleIfNeeded(event) {
                if (event.isTrusted) { // ignore programmatically events, like BackgroundKeeper
                    resetTimersThrottle();
                }
            }

            function resetTimersIfNeeded() {
                if (!allowResetTimers) {
                    return;
                }

                for (let timerName in config) {
                    if (timers[timerName]) {
                        clearTimeout(timers[timerName])
                    }

                    timers[timerName] = setTimeout(config[timerName].action, config[timerName].time);
                }
            }

            function stopTimers() {
                for (let timerName in timers) {
                    if (timers[timerName]) {
                        clearTimeout(timers[timerName])
                        delete timers[timerName];
                    }
                }
            }

            function setAllowResetTimersAndHandleTimers(shouldStop) {
                if (shouldStop) {
                    allowResetTimers = false;
                    stopTimers();
                } else {
                    allowResetTimers = true;
                    resetTimersIfNeeded();
                }
            }

            async function restartSaleIfNeeded() {
                if (posVC.saleItems.length > 0 && posVC.salePayments.length == 0){
                    await posVC.restartSale()
                    location.reload()
                }
            }
        }
    }

}