module PositiveTS {
    export module Components {
        export module PositiveInput {
            const INPUT_TYPES = {
                TEXT: 'text',
                PASSWORD: 'password',
                NUMBER: 'number',
                SELECT: 'select',
                CHECKBOX: 'checkbox',
            }

            export function create() {
                let positiveInput =  {
                    inheritAttrs: false,
                    template: JST.positiveInput(),
                    components: {
                        positiveSelect: PositiveSelect.getComponent(),
                        positiveCheckbox: PositiveCheckbox.getComponent()
                    },
                    props: {
                        type: {
                            type: String,
                        },
                        label: {
                            type: String,
                            default: null,
                        },
                        modelValue: {
                            type: null,
                        },
                        showClearButton: {
                            type: Boolean,
                            default: false,
                        },
                        placeHolder: {
                            type: String,
                            default: '',
                        },
                        readonly: {
                            type: Boolean,
                            default: false,
                        }
                    },
                    methods: {
                        focus() {
                            if (this.$refs.input && this.$refs.input.focus) {
                                this.$refs.input.focus();
                            }
                        },
                        clear() {
                            this.updateValue(null);
                        },
                        updateValue(newVal) {
                            this.$emit('update:modelValue', newVal)
                        },
                        finish() {
                            this.$emit('finish');
                        }
                    },
                    computed: {
                        isSimpleInput() {
                            return [INPUT_TYPES.TEXT, INPUT_TYPES.NUMBER, INPUT_TYPES.PASSWORD].includes(this.type);
                        },
                        attrsToTransfer() {
                            let result = {...this.$attrs, label: this.label};

                            return result;
                        },
                        inputComponent() {
                            if (this.type == INPUT_TYPES.SELECT) {
                                return 'positiveSelect';
                            }

                            if (this.type == INPUT_TYPES.CHECKBOX) {
                                return 'positiveCheckbox';
                            }

                            return this.type;
                        },
                        inputClasses() {
                            let classes = (this.$attrs.class || '').split(' ');

                            if (this.type == INPUT_TYPES.CHECKBOX) {
                                classes.push('no-border-bottom');
                                classes.push('hide-label');
                            }

                            return classes;
                        }
                    }
                }

                VueApp.component('positive-input', positiveInput);
            }
        }
    }
}  