module PositiveTS {
export module Service {
export module Hakafa {
  export const CLUB_IDENTIFIER = "HAKAFA"
  const _FIELDS_PREFIX = "HakafaSetCustomer-";
  export const VOUCHER_TYPE_ID = "999";
  let cachedHakafaVoucher = null;

  export async function getObligoCustomerFromPriority (customer:HakafaDialogServerHakafaCustomer, withInvoicesReport = false) {
    let obligo = {};
    const endPoint = `/OBLIGO?$filter=CUSTNAME eq '${customer.customer_number}'${withInvoicesReport ? '&$expand=OBLIGO_FNCITEMS_SUBFORM' : ''}`
    let res =  await PositiveTS.Service.PriorityService.request('get', endPoint);
    if (res && !_.isEmpty(res.data)){
      obligo = res.data[0]
    }
    return obligo;
  }

  export async function convertToPosStructure(customer:HakafaDialogServerHakafaCustomer, isPriorityApi = false) {
    if (isPriorityApi || this.isPriorityApi){
      let obligo = await getObligoCustomerFromPriority(customer)
      customer.amount = obligo['CREDIT'] * -1
      customer.obligo = obligo['MAX_CREDIT']
    }
    let uglyCustObj = new HakafaDialogCustomerDetails()
    uglyCustObj.s_id_number = customer.tz
    uglyCustObj.customer_number = customer.customer_number
    uglyCustObj.s_first_name = customer.first_name
    uglyCustObj.s_last_name = customer.last_name
    uglyCustObj.dt_birth_date = customer.birth_date
    uglyCustObj.s_phone_number_1 = customer.phone
    uglyCustObj.customer_group_id = customer.customer_group_id;
    uglyCustObj.amount = customer.amount
    uglyCustObj.db_id = customer.id
    uglyCustObj.merakez = customer.merakez
    uglyCustObj.merakez_id = (customer.hakafaMerakezCustomer) ? customer.hakafaMerakezCustomer.id : undefined
    uglyCustObj.obligo = customer.obligo
    uglyCustObj.address = customer.address
    uglyCustObj.mode = customer.mode
    uglyCustObj.is_price_alut = customer.is_price_alut
    uglyCustObj.is_tamash_customer = customer.is_tamash_customer;
    uglyCustObj.printer_type = customer.printer_type;
    uglyCustObj.discount_percent = customer.discount_percent;
    uglyCustObj.is_pricelist_customer = customer.is_pricelist_customer
    uglyCustObj.cannot_pay_by_hakafa = customer.cannot_pay_by_hakafa
    uglyCustObj.is_eilat_customer = customer.is_eilat_customer
    uglyCustObj.is_not_eilat_customer = customer.is_not_eilat_customer
    uglyCustObj.is_parent_hakafa_customer = customer.is_parent_hakafa_customer
    uglyCustObj.parent_hakafa_customer_id = customer.parent_hakafa_customer_id
    uglyCustObj.comments = customer.comments
    if (customer.is_offline) {
      uglyCustObj.is_offline = true;
    }

    return uglyCustObj;
  }


  export function getVoucherID(){
    return VOUCHER_TYPE_ID;
  }

  export async function getHakafaCustomerByCustomerNumber(customer_number){
    let response =await PositiveTS.Service.WasmDB.promiseSql(`select * from hakafaCustomer where customer_number = "${customer_number}"`)
    return response[0];
  }


  export function getHakafaVoucher():Promise<any>{
    if (cachedHakafaVoucher !== null) {
      return Promise.resolve(cachedHakafaVoucher);
    }
    return PositiveTS.Service.WasmDB.promiseSql(`select * from voucher where typeID = ${VOUCHER_TYPE_ID}`)
    .then(result => {
      let items = [];
      for (let i = 0; i < result.length; i++) {
        var entity = new PositiveTS.Storage.Entity.Voucher();
        entity.importFromObject(result[i]);
        items.push(entity);
      }
      cachedHakafaVoucher = items[0];
      return items[0];
    })

  }


  export class HakafaDialogCustomerDetails {
    clubName = "HAKAFA";
    s_id_number = "";
    s_first_name = "";
    s_last_name = "";
    dt_birth_date = "";
    s_phone_number_1 = "";
    customer_group_id:number = undefined;
    amount = 0;
    merakez = "";
    db_id = undefined
    merakez_id = undefined;
    customer_number:string
    obligo:number
    address:string
    is_tamash_customer:boolean
    mode = 0;
    is_offline:boolean = false
    is_price_alut:boolean = false
    printer_type:number = 0;
    discount_percent:number = 0;
    is_pricelist_customer:boolean = false
    cannot_pay_by_hakafa:boolean = false
    is_eilat_customer:boolean = false
    is_not_eilat_customer:boolean = false
    is_parent_hakafa_customer:boolean = false
    parent_hakafa_customer_id:number = null
    comments: string = ""
  }

  export class HakafaDialogServerHakafaCustomer {
    id:number
    first_name:string
    last_name:string
    tz:string
    store:any
    amount:number
    birth_date:string
    phone:string
    name:string
    hakafaMerakezCustomer:any
    merakez:string
    obligo:number
    is_monthly_obligo:boolean
    is_tamash_customer:boolean
    address:string
    customer_number:string
    mode = 0
    is_price_alut:boolean = false
    printer_type:number = 0
    discount_percent:number = 0
    customer_group_id:number = undefined;
    is_offline:boolean = false
    is_pricelist_customer:boolean = false
    cannot_pay_by_hakafa:boolean = false
    is_eilat_customer:boolean = false
    is_not_eilat_customer:boolean = false
    is_parent_hakafa_customer:boolean = false
    parent_hakafa_customer_id:number = null
    comments: string = ""
  }

  export function sumHakafaPaymentsByTamashOrTaxInv(salePayments:Storage.Entity.SalePayment[]):{totalTamashHakafaPayments:number, totalTaxInvHakafaPayments:number} {
    let totalTamashHakafaPayments = 0
    let totalTaxInvHakafaPayments = 0
    for (let payment of salePayments) {
      if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
        if (payment.data != null) {
          let data = JSON.parse(payment.data)
          for (let voucherData of data) {
            if (voucherData.voucher_type_id == VOUCHER_TYPE_ID) {
              if (voucherData.isTamashCustomer){
                totalTamashHakafaPayments += voucherData.amount
              } else {
                totalTaxInvHakafaPayments += voucherData.amount
              }
            }
          }
        }
      }
    }
    return {
      totalTamashHakafaPayments: totalTamashHakafaPayments,
      totalTaxInvHakafaPayments: totalTaxInvHakafaPayments
    }
  }

  export function sumHakafaPayments(salePayments):number {
    let totalHakafaPayments = 0
    for (let payment of salePayments) {
      if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
        if (payment.data != null) {
          let data = JSON.parse(payment.data)
          for (let voucherData of data) {
            if (voucherData.voucher_type_id == VOUCHER_TYPE_ID) {
              totalHakafaPayments += voucherData.amount
            }
          }
        }
      }
    }
    return totalHakafaPayments
  }

  export function isCurrentCustomerTamash(voucher){
    if (voucher.typeID == VOUCHER_TYPE_ID) {
      let saleJsonData = _getSaleJsondata();
      return (saleJsonData.customer && saleJsonData.customer.is_tamash_customer)
    }
  }

  export function validateHakafaPayment(voucher, amount, sale, saleItems, salePayments) {
    if (voucher.typeID != getVoucherID()) {
      return {valid: true, message: "" } //no need to validate vouchers that are not hakafa
    }

    //TODO: only if allow invoice split is off...
    var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(sale, saleItems, salePayments);
    if (saleTotals.totalAmount  !== amount && !(jsonConfig.getVal(jsonConfig.KEYS.allowInvoiceSplit))) {
        return {valid: false, message: 'תו הקפה חייב להיות על כל החשבון'}
    }

    let hakafaPaymentsSum = sumHakafaPayments(salePayments)
    let saleJsonData = _getSaleJsondata();
    if (!saleJsonData.customer.is_offline && saleJsonData.customer && saleJsonData.customer.obligo) {
      // if (saleJsonData.customer.obligo
      let cust = saleJsonData.customer
      if ((-cust.amount+hakafaPaymentsSum+amount) > cust.obligo) {
        let customerAmount = Number(cust.amount)
        let remainingBalance = cust.obligo + customerAmount - hakafaPaymentsSum;
        remainingBalance = Math.round(remainingBalance*1000)/1000
        return {valid: false, message: `לא ניתן להוסיף תשלום - יתרתך לניצול הינה  ${remainingBalance} ש"ח` }
      }
    }

    return {valid: true, message: ""}
  }

  export function setJsondata(enumMode:PositiveTS.Dialogs.Hakafa.EnumMode, dialogVals:PositiveTS.Dialogs.Hakafa.Fields){
    var _customerObj = new HakafaCustomerDetails(enumMode, dialogVals);
    var saleJsondata = _getSaleJsondata();
    saleJsondata.customer = _customerObj;
    posVC.sale.jsondata = JSON.stringify( saleJsondata );
    return posVC.saleUpdated();
  }

  export function getJsondata():PositiveTS.Dialogs.Hakafa.Fields {
    var fields = new PositiveTS.Dialogs.Hakafa.Fields();
    var saleJsonData = _getSaleJsondata();

    if (saleJsonData.customer && saleJsonData.customer.clubName === CLUB_IDENTIFIER) {
      for(var key in fields) {
        fields[key] = saleJsonData.customer[key.substr(_FIELDS_PREFIX.length)]
      }
    }

    return fields;
  }

  export function getJsondataMode():PositiveTS.Dialogs.Hakafa.EnumMode{
    var fields = new PositiveTS.Dialogs.Hakafa.Fields();
    var saleJsonData = _getSaleJsondata();

    if (saleJsonData.customer && saleJsonData.customer.clubName === CLUB_IDENTIFIER) {
      return saleJsonData.customer.mode;
    }
    return PositiveTS.Dialogs.Hakafa.EnumMode.search;
  }

  export function hasCustomer(sale?:PositiveTS.Storage.Entity.Sale):boolean {
    let saleJsonData = _getSaleJsondata(sale);

    if(saleJsonData.customer && saleJsonData.customer.enable_hakafa_payment){
      return true;
    }

    if (saleJsonData.customer && saleJsonData.customer.clubName === CLUB_IDENTIFIER) {
      return true;
    } else {
      return false;
    }
  }

  export function getSaleCustomerAmount(sale:PositiveTS.Storage.Entity.Sale) {
    return _getSaleJsondata(sale).customer.amount;
  }

  export function expectedCustomerBalance(sale:PositiveTS.Storage.Entity.Sale, specialitems:SpecialItem[], 
              saleItems:PositiveTS.Entity.SaleItem[], salePayments:PositiveTS.Storage.Entity.SalePayment[] ):number{
    if (! hasCustomer(sale)) { return 0;}
    
    let currentCustomerBal = getSaleCustomerAmount(sale);
    if (!currentCustomerBal){ currentCustomerBal = 0;}
    
    let hakafaPayed = sumHakafaPayments(salePayments);
    
    let hakafaLoaded = 0;

    let hakafaSpecialItem = specialitems.filter((item) => {return item.hndlr == "HAKAFA";})[0]
    if (hakafaSpecialItem && hakafaSpecialItem.code) {
      let specialSaleItem = saleItems.filter( (saleItem) => {
        return (saleItem.itemCode === hakafaSpecialItem.code)})[0]

      if(specialSaleItem) {
        hakafaLoaded = specialSaleItem.unitPrice
      } 
    }

    return (currentCustomerBal - hakafaPayed + hakafaLoaded)
  }

  function otherCustomerInSale():boolean {
    let saleJsonData = _getSaleJsondata();

    if(saleJsonData.customer && saleJsonData.customer.enable_hakafa_payment){
      return false;
    }

    if (saleJsonData.customer && saleJsonData.customer.clubName != CLUB_IDENTIFIER) {
      return true
    }
    return false
  }

  export function showHakafaBtn():boolean{
    let hakafaCustomerInSale = hasCustomer();
    let hasSpecialItem = (hakafaCustomerInSale && posVC.saleItems.length == 1 &&
      PositiveTS.Service.SmartVoucher.isSpecialItemCode(posVC.saleItems[0].itemCode) )
    if (hasSpecialItem) {
      return false
    }

    if (otherCustomerInSale()) {
      return false
    }

    return true;
  }

  export function needPrintHakafaCustomerNumber (): boolean {
    return jsonConfig.getVal(jsonConfig.KEYS.printHakafaCustomerNumber)
  }

  class HakafaCustomerDetails {
    clubName = CLUB_IDENTIFIER;
    s_id_number = "";
    s_first_name = "";
    s_last_name = "";
    dt_birth_date = "";
    s_phone_number_1 = "";
    customer_group_id = undefined;
    amount = "0";
    merakez = "";
    merakez_id = undefined;


    mode:PositiveTS.Dialogs.Hakafa.EnumMode;

    constructor(enumMode:PositiveTS.Dialogs.Hakafa.EnumMode, dialogVals:PositiveTS.Dialogs.Hakafa.Fields){
      this.mode = enumMode;
      for(var key in dialogVals) {
        this[key.substr(_FIELDS_PREFIX.length)] = dialogVals[key];
      }
    }
  }

  function _getSaleJsondata(saleParam?:PositiveTS.Storage.Entity.Sale){
    let sale = saleParam || posVC.sale;
    var saleJsonData:any = {};
    if (sale.jsondata) {
      saleJsonData = JSON.parse( sale.jsondata );
    }
    return saleJsonData;
  }

}}}
