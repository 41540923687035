module PositiveTS {
  export module Components {
    export module FlightsIsrairOpenDialog {

      const _dialogSelector = "#positive-flights-israir-open-dialog";
      const _vueComponentName = 'flights-israir-open-dialog';

      export function create() {
        let flightOnlineloginDialog = {
          template: JST.flightsIsrairOpenDialog(),
          mixins: [Mixins.modalsMixin],
          components: {
            FlightsIsrairEnterFlightCode: FlightsIsrairEnterFlightCode.getComponent(),
            FlightsIsrairSelectAuthorizedEmployees: FlightsIsrairSelectAuthorizedEmployees.getComponent(),
            FlightsIsrairStockCount: FlightsIsrairStockCount.getComponent(),
            FlightsIsrairPrintStockCountReport: FlightsIsrairPrintStockCountReport.getComponent(),
          },
          methods: {
            open() {
              this.$el.show();
            },
            close() {
              this.$el.close();
            },
            goNext() {
              this.step++;
            },
            goBack() {
              this.step--;
            },
            finishWizard() {
              this.showAlertWithTitleGeneric(i18next.t('flightsWizard.commitChanges'),
                i18next.t('flightsWizard.finishTitle'),
                this.applyAndCloseWizard,
                this.goBack,
                false,
                i18next.t('flightsWizard.approve'),
                i18next.t('flightsWizard.goBack'));
            },
            async applyAndCloseWizard() {
              app.showLoadingMessage(i18next.t("loading"));

              try {
                await this.openIsrairFlight(this.allWizardData);
                app.hideLoadingMessage();
              } catch (err) {
                console.error(err);
                app.hideLoadingMessage();
                this.showGenericError();
                this.goBack();
                return;
              }

              this.clearWizard();
              this.close();
            },
            clearWizard() {
              this.step = 0;
              this.wizardStepsData = [];
            },
            getDefaultWizardData() {
              return { openFlight: true };
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {openIsrairFlight} = flightsStore;
            return {openIsrairFlight}
          },
          computed: {
            allWizardData() {
              return this.wizardStepsData.reduce((allData, currentData) => Object.assign(allData, currentData), this.getDefaultWizardData());
            },
            currentStepsComponets() {
              return [
                'FlightsIsrairEnterFlightCode',
                'FlightsIsrairSelectAuthorizedEmployees',
                'FlightsIsrairStockCount',
                'FlightsIsrairPrintStockCountReport'
              ]
            },
          },
          data() {
            return {
              step: 0,
              wizardStepsData: [],
            };
          },
          watch: {
            step() {
              if (this.step < 0) {
                this.step = 0;
                this.close();
              }

              if (this.step >= this.currentStepsComponets.length) {
                this.finishWizard();
              }
            }
          },
        }
        VueApp.component(_vueComponentName, flightOnlineloginDialog)
      }
    }
  }
}
