module PositiveTS {
    export module Service {
        export type ZAllReportChannel = {
            channel: string
            pos_id: String
            message: String
            status: String
            report_num: number
            last_alive?: number
        }
        export module ZReportAll {

            export type remoteOptions = {
                print: Boolean
                report_channel: String
            }

            export let isRemoteZ: Boolean;
            export let isPrimaryPos: Boolean;
            export let isRemoteAllZActive: Boolean;
            export let isRemoteZEnabledWithoutPrimary: Boolean;
            export let isRemoteZEnabledWithPrimary: Boolean;
            export let reportChannel: String = '';
            export let channels: ZAllReportChannel[] = [];
            export let remoteOptions: remoteOptions;
            export let initiatorSocket = null;

            export const keepAliveInterval: number = 10; // Interval in seconds to check progress of Z reports

            export const STATUS = {
                WAITING_FOR_START: 'pending',
                STARTED: 'started',
                IN_PROGRESS: 'in_progress',
                FINISHED: 'finished',
                ERROR: 'finished_with_error',
                NO_SALES: 'no_sales',
                NO_RESPONSE: 'offline',
                PRINTED: 'printed',
            }

            export function setParams() {
                isRemoteZ = session.pos.hasRemoteZ;
                isPrimaryPos = jsonConfig.getVal(jsonConfig.KEYS.z_master_slave_primary_pos_device_id) == session.pos.deviceID;
                isRemoteZEnabledWithoutPrimary = isRemoteZ && !jsonConfig.getVal(jsonConfig.KEYS.z_master_slave_primary_pos_device_id);
                isRemoteZEnabledWithPrimary = isRemoteZ && isPrimaryPos;
                reportChannel = '';
            }

            export async function start() {

                // Clear event binds for fresh report
                socketManager.channelStore.unbind('z_report_info');
                app.showLoadingMessage(i18next.t("ZReportAllProgressDialog.fetchingPOS"));

                let req = await PositiveTS.Service.FetchReq.jsonReq(`/z_reports_all`, "post", { initiator: session.pos.deviceID })

                Service.Logger.error(JSON.stringify(req));

                if (req.response.status === 200) {
                    reportChannel = req.result.initiator.channel;
                    channels = req.result.channels;

                    PositiveTS.VueInstance.$refs.ZReportAllProgressDialog.open(channels);
                    app.hideLoadingMessage();

                } else {
                    console.error(req.result.message)
                    this.showError(req.result.message)
                }
            }

            export async function remoteZ() {
                isRemoteAllZActive = true;
                socketManager.channelStore.trigger('z_report_info', { from: session.pos.deviceID, status: ZReportAll.STATUS.STARTED });
                let keepAlive = setInterval(() => {
                    socketManager.channelStore.trigger('z_report_info', { from: session.pos.deviceID, status: ZReportAll.STATUS.STARTED });
                }, keepAliveInterval * 1000);
                await new Promise((resolve, reject) => {
                    PositiveTS.Service.ZReport.makeZReport(resolve, false, false)
                });

                $('#zreport-results-back').click();
                setInterval(() => {
                    $('div.alert-continue').click();
                }, 1000);

                clearInterval(keepAlive)
            }

            export async function paymentsToPrint(payments) {
                let paymentsToPrint = {};
                let cachedPayments = JSON.parse(payments)
                let totalWithdrawal = 0;
                for (let key in cachedPayments) {
                    let keyNum = Number(key)
                    if (keyNum != PositiveTS.Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL) {
                        paymentsToPrint[keyNum] = cachedPayments[keyNum]
                    } else {
                        totalWithdrawal = cachedPayments[keyNum].amount
                    }
                }
                return paymentsToPrint;
            }

            export async function getAllVouchers(vouchers) {
                let voucherModel = new PositiveTS.Storage.Entity.Voucher();
                let allVouchers: Storage.Entity.Voucher[] = await voucherModel.all();

                let relevantVouchers = allVouchers.filter(voucher => Object.keys(vouchers).indexOf(voucher.typeID) > -1)
                relevantVouchers = _.sortBy(relevantVouchers, 'typeID')

                let result = []
                for (let voucher of relevantVouchers) {
                    result.push({ voucher: voucher, amount: vouchers[voucher.typeID] })
                }

                return result;
            }


            export async function printFinished(channels: ZAllReportChannel[]) {
                let finishedChannels = channels
                    .filter(channel => channel.status === ZReportAll.STATUS.FINISHED)
                    .map(channel => { return { pos_id: channel.pos_id, report_number: channel.report_num } })
                let req = await PositiveTS.Service.FetchReq.jsonReq(`/z_reports_all?pos_ids=${JSON.stringify(finishedChannels)}`, "get")
                req.result.forEach(async report => {
                    let z_report = await Helper.ReportsHelper.convertServerReportToZReport(report.z_report, {})
                        .then(function (reportEntity) {
                            return reportEntity;
                        });

                    if (z_report.emvTransmitReport) {
                        let emvTransmitRep = JSON.parse(z_report.emvTransmitReport)
                        if (emvTransmitRep.SessionNumber && emvTransmitRep.Date && emvTransmitRep.DepositReport && (emvTransmitRep.DepositReport.length == 0 || emvTransmitRep.DepositReport[0].length == 0)) {
                            let res = await Service.EMV.getDepositReport(emvTransmitRep.SessionNumber, emvTransmitRep.Date);
                            emvTransmitRep.DepositReport[0] = res;
                            z_report.emvTransmitReport = JSON.stringify(emvTransmitRep);
                        }
                    }

                    if (z_report.withdrawnCash > 0) {
                        z_report.withdrawnCash = (z_report.withdrawnCash * -1)
                    }

                    let args: Printing.Reports.PrintZXReportArgs = {
                        company_name: report.pos.company_name,
                        store_name: report.pos.store_name,
                        pos_id: report.pos.device_id,
                        cashier_name: report.pos.cashier_name,
                        payments: await this.paymentsToPrint(z_report.payments),
                        vouchers: await this.getAllVouchers(z_report.vouchers),
                        date: z_report.createdAt,
                        zNumber: z_report.zNumber,
                        vat: z_report.totalVat,
                        isPrimary: z_report.isPrimary,
                        primaryZReportNumber: z_report.primaryZReportNumber,
                        debitSales: z_report.debitSales,
                        creditSales: z_report.creditSales,
                        zReport: z_report,
                        deliveriesSum: z_report.deliveriesSum,
                        deliveriesCount: z_report.deliveriesCount,
                        canceledBon: z_report.canceledBonCount,
                        withdrawalTotal: z_report.withdrawnCash,
                        cashToSafe: z_report.cashMovedToSafe,
                        enterStatement: z_report.enterStatement,
                        zStatement: z_report.zStatement,
                        withdrawnCount: z_report.withdrawnCount,
                        tipsAmount: z_report.tipsCount,
                        type: 'Z',
                        tenbisCibusAmount: null,
                        currenciesSummary: JSON.parse(z_report.currenciesSummary),
                    }
                    Printing.Reports.ZXReports.printZReport(args);
                })
            }

            export async function showError(message) {
                app.showAlertDialog({
                    header: i18next.t('error'),
                    content: message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                },
                    null, null)
            }
        }
    }
}