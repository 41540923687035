module PositiveTS {
  export module Components {
  export module EmailDialog {
  
    const _vueComponentName = 'email-dialog';
    type CompType = any;

    export function create() {
      let emailDialog = {
          template: JST.emailDialog(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            ...Mixins.modalsMixin.methods,
            open(type:number, params:any) {
              this.type = type;
              this.params = params;
              this.populateEmployees();
              this.$el.showModal();              
            },
            async populateEmployees() {
              this.availableEmployees = (await appDB.employees.toArray()).filter((emp) => { return !posUtils.isBlank(emp.email) && !emp.isLocked });
            },
            close() {
              this.email = [];
              this.$el.close();
            },
            async send() {
              let self = this as CompType
              if (posUtils.isBlank(self.email)) {
                self.showErrorDialog(i18next.t('emailDialog.noEmailError'));
                return;
              }
              app.showLoadingMessageDialog(i18next.t(`emailDialog.sending`));
              try {
                let response = await Service.EmailSend.sendReport(this.type, this.email, this.params);
                app.hideLoadingMessageDialog();
                if (response.result == true) {
                  self.showAlertGenericDialog(i18next.t('emailDialog.emailSent'))
                }
              }
              catch {
                app.hideLoadingMessageDialog();
                self.showErrorDialog(i18next.t('emailDialog.emailSendError'));
                return;
              }
              
              this.close();
            }
          },
          data() {
            return {
              availableEmployees: [],
              email: null,
            };
          },
      }
      VueApp.component(_vueComponentName,emailDialog);
      return emailDialog;
    }
  }}}
