module Promotions {

  // var counter = 0;
  export class SalePromotions {
    private separateItemLines;

    private get promotionCounterField() {
      return this.separateItemLines ? 'rowNumber' : 'barcode';
    }

    private getPromotionsForSale(sale) {
      if (!sale || !sale.jsondata) {
        return null;
      }

      var jsondata = JSON.parse(sale.jsondata);
      if (!jsondata.promotions) {
        return null;
      }
      return jsondata.promotions;

    }

    private getItemByBarcodeOrId(value, saleItems) {
      var filteredItems = saleItems.filter((item) => {
        return item[this.promotionCounterField] == value;
      });
      if (filteredItems.length === 1) {
        return filteredItems[0];
      }
      //end of TODO
      else {
        return undefined;
      }
    }

    private calculateTotalPriceForItemsInGroup(group, saleItems) {
      var totalPrice = 0;
      var filteredItems, item;
      for (var itemBarcode in group.itemsCounter) {
        item = this.getItemByBarcodeOrId(itemBarcode, saleItems);
        if (item) {
          totalPrice += item.unitPrice * group.itemsCounter[itemBarcode];
        }
      }
      return parseFloat(totalPrice.toFixed(1));
    }


    public flattenPromotionsForSale(sale, saleItems) {
      if (posUtils.isNullOrUndefined(saleItems)) {
        throw new Error("Missing sale items");
      }

      this.separateItemLines = Boolean(JSON.parse(sale.jsondata).separateItemLines)
      var promotions = this.getPromotionsForSale(sale);
      var group, numberOfItems, saleItem;
      var result = {};
      for (var i = 0; i < promotions.length; i++) {
        group = promotions[i];
        group.totalPriceForAllItemsInPromotion = this.calculateTotalPriceForItemsInGroup(group, saleItems);
        group.discountAmountForGroup = session.fixedFloat(group.discountAmountForGroup);
        group.discountPercentForGroup = Number((group.discountAmountForGroup / group.totalPriceForAllItemsInPromotion) * 100);
        for (var itemBarcode in group.itemsCounter) {
          if (!result[itemBarcode]) {
            result[itemBarcode] = 0;
          }
          numberOfItems = group.itemsCounter[itemBarcode];
          saleItem = this.getItemByBarcodeOrId(itemBarcode, saleItems);
          if (saleItem) {
            result[itemBarcode] += group.discountPercentForGroup / 100 * numberOfItems * saleItem.unitPrice;
          }
        }
      }
      return result;
    }

    public saleHasNewPromotions(sale) {
      this.separateItemLines = Boolean(JSON.parse(sale.jsondata).separateItemLines)
      return !(posUtils.isNullOrUndefined(this.getPromotionsForSale(sale)));
    }

    public itemHasPromotions(saleItem, sale, saleItems) {
      if (session.pos.useNewPromotions) {
        return (!posUtils.isBlank(saleItem.promotions));
      }
      this.separateItemLines = Boolean(JSON.parse(sale.jsondata).separateItemLines)
      if (!this.saleHasNewPromotions(sale)) {
        return false;
      }

      //cache the flattened promotions so we don't have to repeat this calculation so many times
      sale.flattenedPromotions = sale.flattenedPromotions || this.flattenPromotionsForSale(sale, saleItems);

      return (typeof (sale.flattenedPromotions[saleItem[this.promotionCounterField]]) !== 'undefined');
    }

    public totalOfItemsWithoutPromotionsOrDiscountsAndDiscountAllowedNotIncludingSalePromotion(
        sale:PositiveTS.Storage.Entity.Sale, saleItems:Array<PositiveTS.Storage.Entity.SaleItem>) {
      this.separateItemLines = Boolean(JSON.parse(sale.jsondata).separateItemLines)

      let jd = JSON.parse(sale.jsondata)
      if (PositiveTS.Helper.SaleHelper.doesSaleHasDiscount(sale)) {
        return {total: 0, items: []};
      }
      let total = 0;
      let itemsWithoutPromotionOrDiscount = []
      let itemsWithoutDiscount = saleItems.filter(saleItem => saleItem.discountAmount == null || saleItem.discountAmount == "-1")
      if (!jd.promotions || jd.promotions.length == 0) {
        for (let item of itemsWithoutDiscount) {
          total+= item.unitPrice*item.quantity;
          itemsWithoutPromotionOrDiscount.push(item)
        }
      }
      else {
        let allItemsCounter = {}
        jd.promotions.forEach(promo => {
          for (let prop in promo.itemsCounter) {
            if (allItemsCounter[prop] == null) {
              allItemsCounter[prop] = 0
            }
            allItemsCounter[prop] += promo.itemsCounter[prop]
          }
        })
        let itemsClone = itemsWithoutDiscount.map(item => item.clone())
        for (let item of itemsClone) {
          if (allItemsCounter[item[this.promotionCounterField]] && allItemsCounter[item[this.promotionCounterField]] > 0) {
            let quantToReduce = Math.min(item.quantity,allItemsCounter[item[this.promotionCounterField]])
            item.quantity -= quantToReduce;
            allItemsCounter[item[this.promotionCounterField]] -= quantToReduce;
          }
        }
        for (let item of itemsClone) {
          if (item.quantity > 0) {
            total+= item.unitPrice*item.quantity; 
            itemsWithoutPromotionOrDiscount.push(item)
          }
        }
      }
      return {total: total, items: itemsWithoutPromotionOrDiscount }
    }

    public calcuateItemPriceAfterPromotions(saleItem, sale, saleItems):number {
      this.separateItemLines = Boolean(JSON.parse(sale.jsondata).separateItemLines)
      //cache the flattened promotions so we don't have to repeat this calculation so many times
      sale.flattenedPromotions = sale.flattenedPromotions || this.flattenPromotionsForSale(sale, saleItems);

      var realSaleItem = this.getItemByBarcodeOrId(saleItem[this.promotionCounterField], saleItems);

      var totalDiscountForItem = sale.flattenedPromotions[saleItem[this.promotionCounterField]];

      if (totalDiscountForItem) {
        // console.log(((realSaleItem.quantity*realSaleItem.unitPrice - totalDiscountForItem)/realSaleItem.quantity)); //session.fixedFloat(
        return ((realSaleItem.quantity * realSaleItem.unitPrice - totalDiscountForItem) / realSaleItem.quantity);
      }
      else { //item is not in any promotion - TODO: check if it is in discount...
        return saleItem.unitPrice;
      }
    }
}
}
declare var salePromotions:Promotions.SalePromotions;
salePromotions = new Promotions.SalePromotions();
