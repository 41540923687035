module PositiveTS {
  export module Components {
    export module PositVueKeyboard {
      export function getComponent() {

        let vueKeyboard = {
          template: JST.PositVueKeyboard(),
          props: {
            modelValue: {
              required: false,
              default: [],
            },
            layout: {
              required: false,
              type: [String, Array],
              default: ["1 2 3 4 5 6 7 8 9 0 . {bksp}"],
            },
            display: {
              required: false,
              type: Object,
              default: () => ({}),
            },
          },
          computed: {
            parsedLayout() {
              if (Array.isArray(this.layout)) {
                // If this.layout is an array, process each string in the array
                return this.layout.map(layoutString => {
                  let layoutSplit = layoutString.split(" ").reverse();
                  return layoutSplit.map((val) => {
                    return { display: this.display[val] || val, value: val };
                  });
                });
              } else {
                // If this.layout is a single string, process it as before
                let layoutSplit = this.layout.split(" ").reverse();
                return layoutSplit.map((val) => {
                  return { display: this.display[val] || val, value: val };
                });
              }
            }
          },
          methods: {
            onkeypress(button) {
              this.$emit("onKeyPress", button);
            },
            getGridClass(layoutRow) {
              return `grid grid-cols-${layoutRow.length} gap-2`;
            },
          }
        }
        return vueKeyboard;
      }
    }
  }
}
