export default {
    error: 'Error',
    generalError: 'General Error. Contact support if the problem persists',
    success: 'Success',
    gatewayPayment:{
      transactionTypes:{
        blockedCard:"Blocked Card",
        regularDebit:"Regular Debit",
        approvedDebit:"Approved Debit",
        forced:"Forced",
        cashback:"Cashback",
        cash:"Cash",
        recurringDebit:"Recurring Debit",
        balanceEnquiry:"Balance Enquiry",
        regularCredit:"Regular Credit",
        cancel:"Cancel",
        approvedCredit:"Approved Credit",
        load:"Load",
        reversal:"Reversal",
        discharge:"Discharge",
        retrieveCardNumber:"Retrive Card Number"
      },
      authcCodeCreditCompany:{
        issuer: "Issuer",
        shva: 'Shva in STIP Service',
        card: "Smart Card",
        voiceCall: "Voice Call",
      }
    },
    emv: {
        performingPinpadConfiguration: "Fetching pinpad configuartions",
        queryTransaction: "Location pinpad transaction",
        filteringFaildTran: "Verifing compatibillity of data",
        payAttention: "Please notice",
        PINPAD_NOT_CONNECTED: "The connection to the card reader is not valid. Please Verify the connection of the card reader to the pos and make sure that the card reader is on, and try again.\n If the problem persists, please contact support.",
        PINPAD_NOT_CONNECTED_ANDROID: "The connection to the card reader is not valid. Please Verify the connection of the card reader to the pos and make sure that the card reader is on, and try again.\n\n It is recommended to close the POS app and start it again as illutrated in the image below.\n\n If the problem persists, please contact support.",
        PINPAD_NOT_CONNECTED_IP: "The connection to the terminal device is not valid. Please Verify the connection of the terminal device is on ant connected to the network, and try to restart the payment app, then try this action again. \n If the problem persists, please contact support.",
        TRANSMIT_ERROR: "There was an error transmitting credit card movements, Contact support.",
        USER_CANCEL: 'The action canceled by the user',
        CANNOT_CREDIT_IN_RECOVERY_MODE: 'לא ניתן לזכות כרטיס אשראי במצב עבודה ללא אשראי.\nהחיבור לקורא הכרטיסים אינו תקין או קיים קובץ תנועות ישן על המסופון שטרם שודר',
        OLD_TRAN_FILE_PRESENT: "קיים קובץ תנועות ישן על המסופון שטרם שודר. חובה לבצע התאוששות.\nלחץ על אישור בכדי לנסות לשדר תנועות שוב ולבצע התאוששות.\nמומלץ לפנות לתמיכה.",
        emptyTrack2: "Error in Card Reader.",
        errorNum: "Error Code: {{error}}",
        creditPaymentsExistsButStillGot10019: "שים לב, לא היו עסקאות במסופון לשליחה למתג למרות שקיימות עסקאות אשראי",
        clickOKToCallShva: "לחץ אישור לקליטת נתונים חדשים משבא",
        cardReaderConnectivity: "Checking card reader connection",
        EMV_IN_PROGRESS: "Performing transaction",
        EMV_CANCEL_IN_PROGRESS: "Canceling Transaction",
        EMV_RETURN_IN_PROGRESS: "Performing credit transaction",
        EMV_CREDIT_IN_PROGRESS: "Refund Card, Please Swipe The Card",
        GENERAL_ERROR: "General Error - Please validate the pinpad connection to the pos and try again. contact support if the problem persists",
        REQUEST_AND_OUTPUT_MISMATCH: "חוסר התאמה בין בקשה לתשובה שהתקבלה - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה.",
        EMV_CREATING_Z_REPORT: "Creating Z Report",
        EMV_PERFORMING_TRANSMIT: "Transmitting data",
        EMV_PERFORMING_TRANSMIT_TO_SWITCH: "Transmitting data to switch",
        DELETING_TRAN_FILE: "מוחק קובץ TRAN מהמסופון",
        CALLING_SHVA_WITHOUT_TRANSMITTING_TRAN: "Reciving data from SHVA",
        ERROR_WHILE_DELETING_TRAN: "שגיאה בעת מחיקת קובץ TRAN",
        GETTING_TRANS_REPORT: "Reciving transimition report from SHVA",
        CREDIT_HEADER: "Credit Refund",
        CREDIT_DIALOG_CONTENT: "Select Card Swipe Or Manual Refund",
        CREDIT_DIALOG_CONTINUE_BUTTON_TEXT: "Card Swipe",
        CREDIT_DIALOG_CANCEL_BUTTON_TEXT: "Manual Refund",
        PREPARING_TRANSMISSION: "Prepering Transmission",
        CHECKING_PINPAD_CONNECTION: "Checking card reader connection",
        UPDATING_PINPAD: "Updating Pinpad",
        SENDING_LOGS_TO_FTP: "Sending Logs to FTP",
        CALLING_CASPIT_TECH_SERVER: "Calling Caspit Tech Server",
        CHECKING_TERMINAL_CONNECTION: "Checking terminal connection",
        connectionOk: 'Communication status OK',
        ERROR_WHILE_DOING_TRANSMIT: "Error while transmitting transactions.\n Please check your Pinpad connectivity and POS internet connectivity.\n contact support if the problem persists",
        pinpadConfigurationError: "Error while fetching pinpad configuration",
        blockListFileOutdatedUpdatingIt: 'Block list file outdated. updating, please wait...',
        cancelingPayment: "Refunding credit card payment",
        checkingForSaleInPinpad: "Verifying payment status",
        responseIsNotComplete: "The response from the PinPad is not complete. The payment has been cancelled, please try  again.",
        responseIsNotCompleteTitle: 'Payment has been cancelled',
        badResponseWasntCancelled: 'Failed to charge credit card, please call the credit card company to cancel it',
        badResponseWasntCancelledTitle: 'Failed to charge credit card',
        cancelPaymentNotAvailable: "The payment cannot be cancelled at this time. Please finish the sale and then cancel it via the past sales screen",
        checkingAfterEmptyResult: "There is an empty response, checking again...",
        emptyResponse: "Received an empty response",
        creditTerms: {
          regular: "Regular",
          isracredit: "IsraCredit",
          adifPlus30: "Preffered\\+30",
          credit: "Credit",
          immediateCharge: "Immediate Charge",
          payments: "Payments"
        },
        panEntries: {
          magnetic: "Magnetic",
          cellular: "Cellular",
          manual: "Manual",
          kaf: "כ"
        },
        authCodesManpik: {
          card: "Card",
          company: "Company",
          shva: 'SHVA',
          manual: "Manual"
        },
        tranTypes: {
          informative: "Informative",
          debit: "Debit",
          dischrage: "Discharge",
          enforced: "Enforced",
          cash: "Cash",
          standingOrder: "Standing Order",
          checkBalance: "Check Balance",
          credit: "Refund",
          charge: "Charge"
        },
        cardDenied: "Denied",
        errors: {
          "10000": "No Response",
          "10003": "Incorrect pinpad number",
          "10022": "Incorrect pos number (ECR)",
          "10036": "XML_MISSING_TERMINAL_ID_TAG",
          "10041": "No connection to pinpad",
          "10042": "XML_MISSING_REQUEST_ID_TAG",
          "10043": "CASPIT_WINDOWS_SERVICE_NOT_RESPONDING",
          "10048": "CHECK_OTHER_STATUSES",
          "10050": "DOUBLE_TRANSACTION",
          "10053": "Empty response",
          "10054": "Ashrait wrong configuration",
          "10100": "INVALID_PAN_ENTRY_MODE",
          "10101": "INVALID_MTI",
          "10102": "INVALID_X_FIELD",
          "10501": "The card wasn't swiped in time.",
          "11000": "Credit card access denied",
          "11001": "Incompatible card",
        },
        pinpadErrorTitle: "Pinpad Connection Error",
        statusCodes: {
          '0000': `Approve`,
          '1001': `Swipe / Insert / Show Card`,
          '1002': `Swipe / Show Card`,
          '1003': `Swipe / Insert Card`,
          '1004': `Insert card for smart reader`,
          '1005': `Show Card`,
          '1006': `Please remove card`,
          '1007': `Please remove card`,
          '1008': `Swipe Again`,
          '1009': `Use magnetic stripe reader`,
          '1010': `The terminal is not allowed to make card transactions`,
          '1011 ': `Please wait, Leave card`,
          '1012': `Invalid card`,
          '1013': `Unsupported card`,
          '1014': `There are old transactions. Please transmit`,
          '1015': `Blocked file not updated. Please transmit`,
          '1016': `Unauthorized credit card type`,
          '1017': `The currency is not authorized`,
          '1018': `Incorrect card number`,
          '1019': `The card is not authorized at the terminal`,
          '1020': `Unauthorized card for the type of transaction`,
          '1021': `card out of bounds`,
          '1022': `Club code not in domain`,
          '1023': `Issuer does not exist`,
          '1024': `Brand does not exist`,
          '1025': `Incorrect vehicle number`,
          '1026': `Card not valid`,
          '1027': `Please enter additional data`,
          '1028': `Tap last 3 digits on the back of the card`,
          '1029': `Press 4 digits printed on the card`,
          '1030': `Please press ID no. Including review digit`,
          '1031': `ID no. Wrong`,
          '1032': `ID no. Wrong Try again`,
          '1033': `Please enter a customer address`,
          '1034': `force or call`,
          '1035': `Press F1 to request approval`,
          '1036': `Call a credit company`,
          '1037': `Approved, issued card`,
          '1038': `The transaction was not approved`,
          '1039': `Rejection. Communication malfunction`,
          '1040': `Rejected transaction cvv2 or ID no. Wrong`,
          '1041': `Rejection. Card not associated with the network`,
          '1042': `Rejected a transaction. wrong cavv / ucaf`,
          '1043': `Rejected a transaction. Incorrect address`,
          '1044': `Stolen card. Please boycott`,
          '1045': `Blocked card`,
          '1046': `Fake card. Please boycott`,
          '1047': `Rejected request. Incorrect balance code`,
          '1048': `Rejected a transaction. Lack of bank / stars / emails / other benefit`,
          '1049': `Pending approval. Leave the card`,
          '1050': `The deal was approved. Remove card`,
          '1051': `Please type a voice confirmation number received from the credit card company`,
          '1052': `Incorrect Software Edition`,
          '1053': `Incorrect transaction data`,
          '1054': `Terminal did not perform Initialization`,
          '1055': `Unsupported card, service code`,
          '1056': `Removed does not exist`,
          '1057': `Incorrect parameter set`,
          '1059': `Rejection, recharged card`,
          '1060': `Incorrect benefit transaction data`,
          '1061': `There is no authorization to execute a transaction`,
          '1062': `Please tap Customer zip code`,
          '1063': `Incorrect ID`,
          '2000': `Applicable Error`
        }
    },
    // EMV TRanslitaion
    emvAshStatusCodes: {
        "000": 'Approved',
        "001": 'Blocked card',
        "002": 'Stolen Card, confiscate the card',
        "003": 'Call the Credit Company',
        "004": 'transaction not approved',
        "005": 'Fake Card, confiscate the card',
        "006": 'Reject transaction: cvc2 / id incorrect',
        "007": 'Reject transaction: cavv / ucaf incorrect',
        "008": 'Declined wrong transaction',
        "009": 'Card rejected',
        "010": "Partial Approved",
        "011": 'Reject Transaction: Lack of Points / Stars / Miles / Other Benefit',
        "012": "An unauthorized terminal card",
        "013": 'Deny request - wrong balance code',
        "014": 'Rejection - Unaffiliated Network Card',
        "015": 'Reject transaction: card is invalid',
        "016": 'Rejection - No Currency Type Permission',
        "017": 'Rejection - No Credit Type Transaction Authorization',
        "026": 'Decline transaction - wrong id',
        "041": "There is a query obligation for only a ceiling for a transaction with parameter j2",
        "042": 'There is a query requirement not only for a ceiling, for a transaction with parameter j2',
        "051": 'Missing vector file 1',
        "052": 'Missing vector 4 file',
        "053": 'Missing vector 6 file',
        "055": 'Missing vector 11 file',
        "056": 'Missing vector 12 file',
        "057": 'Missing vector 15 file',
        "058": 'Missing vector file 18',
        "059": 'Missing 31 File File',
        "060": 'Missing 34 vector file',
        "061": 'Missing 41 vector file',
        "062": 'Missing vector file 44',
        "063": 'Missing 64 vector file',
        "064": 'Missing 80 file',
        "065": 'Missing 81 File File',
        "066": 'Missing 82 vector file',
        "067": 'Missing 83 File File',
        "068": 'Missing vector file 90',
        "069": 'Missing 91 vector file',
        "070": 'Missing vector file 92',
        "071": 'Missing vector 93 file',
        "073": "PARAM_3_1 file missing",
        "074": 'Missing file PARAM_3_2',
        "075": 'PARAM_3_3 file is missing',
        "076": 'Missing PARAM_3_4 file',
        "077": 'file PARAM_361 missing',
        "078": 'PARAM_363 file missing',
        "079": 'PARAM_364 file missing',
        "080": 'Missing PARAM_61 file',
        "081": 'PARAM_62 file missing',
        "082": 'PARAM_63 file missing',
        "083": 'file missing CEIL_41',
        "084": 'CEIL_42 file missing',
        "085": 'file missing CEIL_43',
        "086": 'CEIL_44 file missing',
        "087": 'Missing DATA File',
        "088": 'missing JENR file',
        "089": 'Start File Member',
        "098": 'ERROR_IN_NEG_FILE',
        "099": 'General error',
        "101": 'Missing entry to Vector 1',
        "103": 'There is no entry for Vector 4',
        "104": 'Missing entry in Vector 6',
        "106": 'There is no entry for Vector 11',
        "107": 'There is no entry for Vector 12',
        "108": "Missing the 15th entry",
        "110": 'There is no entry for Vector 18',
        "111": 'There is no entry for Vector 31',
        "112": 'There is no entry for Vector 34',
        "113": 'There is no entry for Vector 41',
        "114": 'There is no entry for Vector 44',
        "116": 'Missing the 64 entry',
        "117": 'Missing entry 81 vector',
        "118": 'There is no entry for Doctor 82',
        "119": 'There is no entry for Vector 83',
        "120": "Missing the 90th entry",
        "121": 'Missing entry 91',
        "122": 'There is no entry for Vector 92',
        "123": 'Missing the 93 entry',
        "141": 'Missing proper login in parameter file 3.2',
        "142": 'Missing proper login in parameter file 3.3',
        "143": 'Club 3.6.1 file domain missing',
        "144": 'Club 3.6.3 file domain missing',
        "145": 'Club 3.6 domain file missing',
        "146": 'PL 4.1 card ceiling file missing',
        "147": 'Ceiling file missing for Israeli tickets other than PL Method 0 4.2',
        "148": 'Ceiling file missing for Israeli tickets other than PL Method 1 4.3',
        "149": 'There is no entry for a tourist card ceiling file 4.4',
        "150": 'Is missing a valid ticket file - Isracard',
        "151": 'There is missing a valid ticket file - as',
        "152": 'Missing valid ticket file - future issuer',
        "182": 'Vector 4 error',
        "183": 'Error in Vector Values ​​6/12',
        "186": 'Error in vector values ​​18',
        "187": 'Vector 34 error',
        "188": 'Vector 64 error',
        "190": 'Vector 90 error',
        "191": "Invalid data from issuer permissions",
        "192": "No data in the parameter set",
        "193": 'Non-Invalid Data in Terminal Level Parameters File',
        "300": 'No transaction type authorization - clearing permission',
        "301": 'No Currency Permit - Cleared',
        "303": 'No transaction authorization has been cleared when the card is not present',
        "304": 'No Credit Authorization - Permitted',
        "308": 'No Pinning Permission - Removed Permission',
        "309": 'No credit authorization has been cleared on a fixed date',
        "310": 'No authorization to enter a pre-authorization number',
        "311": "You are not authorized to transact service code 587",
        "312": 'No credit authorization has been cleared',
        "313": 'No Benefit Redeemed',
        "314": "No promotional authorization has been cleared",
        "315": "No specific permission code has been cleared",
        "316": 'No debit authorization for a charge transaction',
        "317": "No debit / discharge authorization in the form of payment code combined with currency code",
        "318": 'No currency clearing authorization for this type of credit',
        "319": 'No permission to remove tip',
        "341": 'No transaction authorization - issuer authorization',
        "342": 'No Currency Permit - Issuer Permission',
        "343": 'There is no issuer authorization to execute a transaction when the card is not present',
        "344": 'No Credit Authorization - Issuer Authorization',
        "348": "No authorization to execute an application initiated by a retailer",
        "349": 'There is no proper authorization to execute an application without a J5 transaction',
        "350": 'No Benefit Issuer Authorization',
        "351": 'There is no issuer authorization for deferred credit',
        "352": 'No issuer authorization for a charge transaction',
        "353": 'No issuer authorization to load / unload in payment method code',
        "354": "There is no currency issuer authorization for this type of credit",
        "381": 'No contactless transaction is allowed over a maximum amount',
        "382": "In the self-service terminal, only self-service transactions can be performed",
        "384": 'Terminal is set to multi - provider / beneficiary - missing provider / beneficiary number',
        "385": "In the e-commerce terminal that is required to transmit eci",
        "401": 'Number of payments is greater than Maximum Payments field value',
        "402": 'Payment Number is Less Than Minimum Payment Field Value',
        "403": 'The transaction amount is less than the minimum field value for payment!',
        "404": 'No number of payments field entered',
        "405": 'Missing First / Fixed Payment Amount',
        "406": "Total transaction amount is different from first payment amount + fixed payment amount * number of payments",
        "408": 'Channel 2 is shorter than 37 characters',
        "410": 'dcode party rejection',
        "414": "In transaction with fixed date billing, sub-date of transaction is entered",
        "415": 'Invalid data entered',
        "416": "Expiration date not in proper format",
        "417": 'Terminal / POS number does not match what is set in PinPad',
        "418": 'missing essential parameters',
        "419": 'ClientInputPan property passed error',
        "420": "No card number next to me - in the mode of entering Channel 2 in a transaction without a current card",
        "421": 'General Error - Non-Valid Data',
        "422": 'ISO message build error',
        "424": 'non-numeric field',
        "425": 'double entry',
        "426": 'Amount increased after credit checks',
        "428": 'Service card missing card',
        "429": 'Card is not valid by valid ticket file',
        "431": "General error",
        "432": 'No license to transfer a card through a magnetic reader',
        "433": 'Transfer Charge on PinPad',
        "434": 'Card must not be transferred on the PinPad',
        "435": 'Device not configured to transfer CTL magnetic card',
        "436": 'Device not configured to transfer EMV CTL card',
        "439": "No credit type authorization by transaction type",
        "440": 'Tourist card is not authorized for this type of credit',
        "441": 'No authorization to execute transaction type - existing card at Vector 80',
        "442": 'In-Stand Verify Certificate for this Removal is not allowed',
        "443": 'Cannot cancel transaction - card not found in existing transaction file in terminal',
        "445": "Instant debit card only instant debit credit can be made",
        "447": 'Wrong card number',
        "448": "Billing to enter customer address (zip code, house number and city)",
        "449": "Billing to type zip code",
        "450": 'Off-Code Operation Code, IDF Field 1-12',
        "451": "Error while building a transaction record",
        "452": "In a charge / discharge / debit transaction, enter a payment method code field",
        "453": 'Unable to cancel discharge 7.9.3',
        "455": "A forced debit transaction cannot be executed when an approval request is required (except for ceilings)",
        "456": "A ticket is in a movement file with an answer code 'confiscated Card'",
        "457": "Instant debit card allows a regular debit / credit / cancellation transaction",
        "458": 'Club code not in field',
        "472": "In a debit transaction with a cash charge enter a cash amount",
        "473": "In a cash transaction transaction, the cash amount should be less than the transaction amount",
        "474": 'Direct debit initialization transaction requires parameter J5',
        "475": "HQ transaction requires one of the fields: number of payments or total amount  HQ transaction requires one of the fields: number of payments or cash HQ transaction requires one of the fields: number of payments K requires one of the fields: Payment number K requires one of the fields: Number KK requires one of the fields: K requires one of the fields: K requires one of the fields: number",
        "476": "Direct Order Mast Transaction Requires Payment Number Field",
        "477": "A direct debit mast transaction requires a reboot transaction ID",
        "478": "A direct debit mast transaction requires a reboot transaction number",
        "479": "Direct Order Mast Transaction Requires Date and Time Boot Transaction Fields",
        "480": "No field than source transaction",
        "481": "Missing a number of units when the transaction is made in a form of payment different from currency",
        "482": "A debit card is allowed on a regular debit / credit / cancellation / cancellation / discharge / charge / balance check",
        "483": 'Transaction with a fuel card at a billing fuel terminal enter a car number',
        "484": "Typed vehicle number is different from burned vehicle number due to magnetic stripe / bank number is different from 912 / left digit Typed vehicle book is different from burned vehicle number due to magnetic stripe",
        "485": " Vehicle number shorter than 6 digits / different from vehicle number appearing on Channel 2) Position 34 on Channel 2 (property card) Vehicle book shorter than 6 digits / different from numbered vehicle",
        "486": "There is a duty to enter a meter reading (position 30) on Channel 2 Leumi Card fuel characteristic card",
        "487": "Only the two-stage fuel can be used in the oligo update",
        "489": "A standard debit card is allowed on the Delcan card only (cancellation is not allowed)",
        "490": 'Fuel / gasoline / fuel club tickets can only be transacted at gas terminals',
        "491": "A conversion transaction must contain all conversion_amount_06, conversion_rate_09, conversion_currency_51 fields",
        "492": 'No conversion on NIS / dollar transactions',
        "493": "In a transaction that includes a billing benefit that will only have one of the following fields: discount amount / number of units /% discount",
        "494": 'Different terminal number',
        "495": 'No fallback permission',
        "496": "Credit Different From Credit / Payments Cannot Be Linked",
        "497": 'Cannot be linked to dollar / index in different currency weight',
        "498": "Local Isracard Card The Spartan Cell at Position 18 Retis Isracard",
        "500": 'The transaction has been terminated by the user',
        "504": "mismatch between card data source field and card number field",
        "505": 'Invalid value in transaction type field',
        "506": 'Invalid value in eci field',
        "507": "Actual transaction amount is higher than approved amount",
        "512": "Cannot enter voice mail received for this transaction",
        "551": 'Reply message does not match request',
        "552": 'Field 55 error',
        "553": 'Tandem error received',
        "554": 'The reply message is missing field mcc_18',
        "555": 'The response message is missing field response_code_25',
        "556": 'The reply message is missing field rrn_37',
        "557": 'The reply message is missing field comp_retailer_num_42',
        "558": 'The reply message is missing auth_code_43 field',
        "559": 'The reply message is missing field f39_response_39',
        "560": 'The reply message is missing field authorization_no_38',
        "561": 'The reply message is missing / empty field additional_data_48.solek_auth_no',
        "562": 'The reply message is missing one of the conversion_amount_06, conversion_rate_09, conversion_currency_51 fields',
        "563": 'The field value does not match the certificate numbers obtained auth_code_43',
        "564": 'The reply message is missing / empty field additional_amounts54.cashback_amount',
        "565": "Field 25 and Field 43 mismatch",
        "566": "In the terminal that supports two-stage fuel, fields 90,116 must be returned",
        "567": "Invalid fields 25,127 in oligo update message in terminal configured as 2-step fuel",
        "700": 'transaction rejected by PinPad device',
        "701": 'Pinpad device error',
        "702": 'Bad com port',
        "703": 'PINPAD_TransactionError',
        "704": 'PINPAD_TransactionCancelled',
        "705": 'Customer Click Cancel on PINPAD',
        "706": "The operation is canceled",
        "707": 'The smart card was issued before the end of the transaction',
        "708": 'PINPAD_UserRetriesExceeded',
        "709": 'PINPAD_PINPadTimeout',
        "710": 'PINPAD_PINPadCommsError',
        "711": 'PINPAD_PINPadMessageError',
        "712": 'PINPAD_PINPadNotInitialized',
        "713": 'PINPAD_PINPadCardReadError',
        "714": 'PINPAD_ReaderTimeout',
        "715": 'PINPAD_ReaderCommsError',
        "716": 'PINPAD_ReaderMessageError',
        "717": 'PINPAD_HostMessageError',
        "718": 'PINPAD_HostConfigError',
        "719": 'PINPAD_HostKeyError',
        "720": 'PINPAD_HostConnectError',
        "721": 'PINPAD_HostTransmitError',
        "722": 'PINPAD_HostReceiveError',
        "723": 'PINPAD_HostTimeout',
        "724": 'PINVerificationNotSupportedByCard',
        "725": 'PINVerificationFailed',
        "726": 'Error recording config.xml file',
        "730": "Device Approved Transaction Against Credit Decision",
        "731": 'Card not inserted',
    },
}