module PositiveTS {
	export module Printing {
        export module Reports{
            export module ZXReports{
                export function printGrandTotal(zReport:Storage.Entity.ZReport){
                    if(zReport.grandTotal && jsonConfig.getVal(jsonConfig.KEYS.printGrandTotal) == true) {
                        printer.jzebra.append(printer.allowComponents.init)
                        printer.printTableRow([i18next.t('printing.grandTotal'),session.fixedNumber(zReport.grandTotal || 0)],printer.zReportTableWidths) 
                        printer.jzebra.append(printer.allowComponents.init)
                    }
                }
                export function printTransmitReportStandalone(depositReport:Array<string>):Promise<any> {
                    let aThis = printer;
            
                    var zebra = aThis.jzebra;
            
            
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.bold);
                    zebra.append(aThis.allowComponents.smallLineSpace);
                    aThis.printLine(` `);
                    aThis.printFloatingLine(i18next.t('printing.creditCardTransmittingReport'),"")
                    printer.printHr();

                    zebra.append(aThis.allowComponents.init);
                    for (let line of depositReport) {
                        if(printer.isHebrewText(aThis.supportHebrew(line,true).trim())){
                            aThis.printFloatingLine( aThis.supportHebrew(line,true),"")
                        }
                        else{
                            aThis.printFloatingLine( aThis.supportHebrew(line,true),"",undefined,undefined,true)
                        }
                    }
            
                    printer.printLineCut();
                    zebra.append(aThis.allowComponents.init);
                    return zebra.print();
                }

                export function printEmvTransmitReportAlone(zReport) {
                    let aThis = printer;
                    var zebra = aThis.jzebra;

                    zebra.append(aThis.allowComponents.init);
                    printEmvReport(zReport);
                    printer.printLineCut();
                    zebra.append(aThis.allowComponents.init);
                    return zebra.print();
                }

                export function printGatewayTransmitReportAlone(zReport) {
                    let aThis = printer;
                    var zebra = aThis.jzebra;

                    zebra.append(aThis.allowComponents.init);
                    printGatewayReport(zReport);
                    printer.printLineCut();
                    zebra.append(aThis.allowComponents.init);
                    return zebra.print();
                }

                export function printZReport(args: PrintZXReportArgs) {
                    args.type = 'Z';
                    args.enterStatement = args.enterStatement || 0;
                    printZXreport(args);
                }
                export function printXReport(args: PrintZXReportArgs) {
                    args.type = 'X';
                    args.enterStatement = args.enterStatement || 0;
                    printZXreport(args);
                }
                export function printPrimaryCategoryStats(zReport:Storage.Entity.ZReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    if (zReport.primaryCategoryStats) {
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }		
                        try {
                            let parsedCategories = JSON.parse(zReport.primaryCategoryStats)
                            zebra.append(aThis.allowComponents.init);
                            aThis.printLine(" ");
                            zebra.append(aThis.allowComponents.bold);
                            zebra.append(aThis.allowComponents.smallLineSpace);
                            let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth,aThis.thirdDefaultColWidth]
                            aThis.printHeader(i18next.t('printing.departmentSalesNet'));
                            aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'),i18next.t('printing.sum'),i18next.t('printing.quantity')],widths))

                            zebra.append(aThis.allowComponents.init);
                            for (let cat of parsedCategories) {
                                let values = [cat.name.substr(0,19), 
                                                            session.fixedNumber(cat.total,2).substr(0,14), 
                                                            session.fixedNumber(cat.amount,1)];
                                aThis.printTableRow(values,widths);
                            }
                            aThis.printHr();
                            let allTotal = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.total),0),2,true)
                            let allAmount = session.fixedNumber(parsedCategories.reduce((a,b) => a + Number(b.amount),0),1)
                            aThis.printTableRow([i18next.t('printing.total'), allTotal, allAmount],widths);
                        }
                        catch(e) {
                            console.warn('could not parse primary category stats json')
                        }
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }		
                    }
                }
                function printZXreport(args: PrintZXReportArgs) {
                    let aThis = printer;
                    let isZ = args.type=='Z' ? true : false;

                    let zebra = aThis.jzebra;
                    args.debitSales = args.debitSales == null ? `` : args.debitSales;
                    args.creditSales = args.creditSales == null ? `` : args.creditSales;
                    
            
                    let company = session.company;
                    let store = session.store;

                    //Reset the printer

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startGriddedReport();
                        PositiveTS.Printing.HtmlPrinting.startReportHeader();
                    }
                    
                    aThis.printLogo();
                    
                    
                    zebra.append(aThis.allowComponents.init);
            
                    zebra.append(aThis.allowComponents.centerAlignment);
            
                    var companyNameArray = company.companyName.split(`$$$`);

                    if((!companyNameArray.length) || (companyNameArray.length == 0)){
                        aThis.printMultyLine(args.company_name);
                    }
            
                    for (var i = 0; i < companyNameArray.length; i++) {
                        aThis.printMultyLine(companyNameArray[i]);
                    };
            
                    aThis.printLine(args.store_name);
            
                    if (!posUtils.isNullOrUndefinedOrEmptyString(store.address)) {
                        aThis.printMultyLine( store.address );
                    }
            
                    if (!posUtils.isNullOrUndefinedOrEmptyString(store.phone)) {
                        aThis.printTextWithPrintLineText(store.phone,
                        `${i18next.t("printing.phone")}:`)
                    }
            
                    if (!posUtils.isNullOrUndefinedOrEmptyString(store.registrationNum)) {
                        aThis.printTextWithPrintLineText(store.registrationNum,
                            `${aThis.chetPeiAinMem()}:`)
                    }

            
                    aThis.printLine(` `);
            
                    zebra.append(aThis.allowComponents.init);
            
            
                    aThis.printFloatingLine(`${i18next.t("printing.date")}:`, args.date);
                    aThis.printFloatingLine(`${i18next.t("printing.POS")}:`, args.pos_id);
                    aThis.printFloatingLine(`${i18next.t("printing.cashier")}:`, args.cashier_name);
            
                    zebra.append(aThis.allowComponents.init);            
            
                    aThis.printLine(` `);

                    if (aThis.isPicturesPrinter()) {
                        zebra.append(aThis.allowComponents.newLine);
                    }
            
                    zebra.append(aThis.allowComponents.underline);
            
                    if (args.type === `X`) {     
                        if (aThis.isPicturesPrinter()) {
                            zebra.append(aThis.allowComponents.init);
                            zebra.append(aThis.allowComponents.centerAlignment);
                            zebra.append(aThis.allowComponents.bigFont);
                        }

                        if (PositiveTS.Reachability.isOnline) {                      
                            aThis.printLine(i18next.t('printing.xReport'))
                        } else {
                            aThis.printLine(i18next.t("printing.xReportForPos") + " " + session.pos.deviceID)
                        }

                        if (aThis.isPicturesPrinter()) {
                            zebra.append(aThis.allowComponents.init);
                        }

                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }

                        if(args.zReport) {
                            printXZPayments(args.payments,args.cashOutside, args.zReport.roundedAmount, args.zReport.roundedCount, args.zReport.tipsAmount);
                        }
                        else {
                            printXZPayments(args.payments,args.cashOutside, null, null, args.tipsAmount);
                        }
                        printCashManagementReport(args.payments[2].amount,false,args.withdrawalTotal,args.cashToSafe,args.enterStatement);
                        aThis.printLine(" ")
                        printCurrenciesReport(args.currenciesSummary);
                        aThis.printLine(" ");
                        if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
                            printDeliveryDetails(args.deliveriesCount,args.deliveriesSum,args.cashOutside,args.canceledBon);
                            aThis.printLine(" ");
                        }			
                        printCanceledBonSummery("X");
                        aThis.printLine(" ");
                        printVoucherDetails(args.vouchers);
                        aThis.printLine(" ")
                        printRecieptDetails(args.payments, args.zReport);
                        aThis.printLine(" ");
                        printHakafaDetails(args.zReport, args.tenbisCibusAmount);

                        if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                            printDalpaksDetails(args.dalpakTotals)
                        }

                    } else {
                        if (aThis.isPicturesPrinter()) {
                            zebra.append(aThis.allowComponents.init);
                            zebra.append(aThis.allowComponents.centerAlignment);
                            zebra.append(aThis.allowComponents.bigFont);
                        }

                        aThis.printFloatingLine(i18next.t('printing.posStatusReport') + " " + args.type, "");

                        if (aThis.isPicturesPrinter()) {
                            zebra.append(aThis.allowComponents.init);
                        }
                    }
            
                    if (args.type == 'Z') {
                        var hasPrimaryZReportNumber = serviceZMasterSlave.isZMasterSlave && args.isPrimary && args.primaryZReportNumber
                        if ( serviceZMasterSlave.isZMasterSlave ) {
                            if (args.isPrimary) {
                                aThis.printFloatingLine(i18next.t('printing.unifiedReport'),"");
                                if (hasPrimaryZReportNumber) {
                                    aThis.printFloatingLine(`${i18next.t('printing.unifiedReportNumber')}: ${args.primaryZReportNumber}`,"");
                                }
                            } else {
                                aThis.printFloatingLine(i18next.t('printing.middleReport'),"");
                            }
                        }
            
                        if (!hasPrimaryZReportNumber) {
                            aThis.printFloatingLine(i18next.t('printing.reportNumber') + ": ",args.zNumber);
                        }
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }
                        aThis.printLine(" ");

                        if (aThis.isPicturesPrinter()) {
                            zebra.append(aThis.allowComponents.newLine);
                        }
                        if(args.zReport) {
                            printXZPayments(args.payments, 0, args.zReport.roundedAmount, args.zReport.roundedCount, args.zReport.tipsAmount);					
                        }
                        else {
                            printXZPayments(args.payments, 0, null, null, args.tipsAmount);				
                        }
                        printCashManagementReport(args.payments[2].amount, isZ, args.withdrawalTotal, args.cashToSafe, args.enterStatement, args.zStatement, args.withdrawnCount);
                        aThis.printLine(" ");
                        printCurrenciesReport(args.currenciesSummary);
                        aThis.printLine(" ")
                        printCreditCardSummary(args.zReport);
                        aThis.printLine(" ");
                        if(!jsonConfig.getVal(jsonConfig.KEYS.shortZSlip)) {
                            printVoucherDetails(args.vouchers);
                            aThis.printLine(" ");
                            printRecieptDetails(args.payments, args.zReport);
                            aThis.printLine(" ");
                            printHakafaDetails(args.zReport);
                            aThis.printLine(" ");
                            if(printer.isHTMLBasedPrinting()) {
                                PositiveTS.Printing.HtmlPrinting.newPage();
                            }
                            printSaleAnalysis(args.zReport);	
                            aThis.printLine(" ");
                            printPrimaryCategoryStats(args.zReport);
                            aThis.printLine(" ");

                            if (jsonConfig.getVal(jsonConfig.KEYS.sumSalesDiscountReportOnZReport)){
                                printSumSalesDiscountReportZReport(args.zReport)
                            }
                        }
                        
                        if (jsonConfig.getVal(jsonConfig.KEYS.printItemDetailsInZReport)){
                            printItemDetails(args.zReport)
                            aThis.printLine(" ")
                        }

                        if(jsonConfig.getVal( jsonConfig.KEYS.isDelivery ) ||
                        args.zReport.isPrimary && args.deliveriesCount > 0){
                            printDeliveryDetails(args.deliveriesCount, args.deliveriesSum, -1, args.canceledBon);
                        }

                        aThis.printLine(" ");
                        if(!jsonConfig.getVal(jsonConfig.KEYS.shortZSlip)) {
                            printZVolumeInfo(args.zReport, args.payments, args.withdrawalTotal);
                            aThis.printLine(" ");
                        }
            
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportGrid();
                        }

                        printGrandTotal(args.zReport);

                        if (args.zReport.gatewayTransmitReport && PositiveTS.Service.Gateway.isEnabled()) {
                            printGatewayReport(args.zReport);
                        }else if (args.zReport.emvTransmitReport && args.zReport.emvTransmitReport != ""){
                            printEmvReport(args.zReport);
                        }	
                    }                   
            
                    zebra.append(aThis.allowComponents.init);

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }

                    printer.printLineCut();

                    zebra.printForceMultiline();
                }

                export function printFullEmvReport(zReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    printEmvReport(zReport, true);

                    printer.printLineCut();
                    zebra.append(aThis.allowComponents.init);
                    zebra.print();
                }
                export function printFullGatewayEmvReport(zReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    printGatewayReport(zReport);

                    printer.printLineCut();
                    zebra.append(aThis.allowComponents.init);
                    zebra.print();
                }

                function printEmvReport(zReport, forceFullPrint = false) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.newPage();						
                        PositiveTS.Printing.HtmlPrinting.startReportHeader();
                    }		
                    try {
                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        zebra.append(aThis.allowComponents.smallLineSpace);
                        aThis.printLine(" ");
                        aThis.printFloatingLine(i18next.t('printing.creditCardTransmittingReport'),"")
                        printer.printHr();
                        zebra.append(aThis.allowComponents.init);

                        if (jsonConfig.getVal(jsonConfig.KEYS.printOnlyEMVReportSessionNumbersInZ) && !posUtils.isBlank(zReport.emvSessionNumbers) && !forceFullPrint) {
                            let sessionNumbers = JSON.parse(zReport.emvSessionNumbers);

                            for (let sessionNumber of sessionNumbers) {
                                aThis.printFloatingLine(`${i18next.t('zReport.sessionNumber')}: ${sessionNumber}`, '');
                            }

                        } else {
                            let emvTransmitReport:PositiveShared.StaticObj
                            emvTransmitReport = Object.assign(new PositiveShared.StaticObj(),JSON.parse(zReport.emvTransmitReport))
        
                            if(emvTransmitReport.DepositReport){
                                for (let depRep of emvTransmitReport.DepositReport) {
                                    if(Array.isArray(depRep)){
                                        for (let line of depRep) {
                                            if(printer.isHebrewText(aThis.supportHebrew(line,true).trim())){
                                                aThis.printFloatingLine( aThis.supportHebrew(line,true),"")
                                            }
                                            else{
                                                aThis.printFloatingLine( aThis.supportHebrew(line,true),"",undefined,undefined,true)
                                            }
                                        }
                                    }
                                }	
                            }
                        }
                    }
                    catch(e) {
                        console.error(`error while trying to parse emvTransmitReport for printing`)
                        console.error(e)
                    }		
                }

                function printGatewayReport(zReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.newPage();						
                        PositiveTS.Printing.HtmlPrinting.startReportHeader();
                    }		
                    try {
                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        zebra.append(aThis.allowComponents.smallLineSpace);
                        aThis.printLine(" ");
                        aThis.printFloatingLine(i18next.t('printing.creditCardTransmittingReport'),"")
                        printer.printHr();
                        zebra.append(aThis.allowComponents.init);

                        let  gatewayTransmitReport = zReport.gatewayTransmitReport ? JSON.parse(zReport.gatewayTransmitReport) :null;

                        if (posUtils.isPresent(gatewayTransmitReport)) {
                            gatewayTransmitReport.forEach((tr) => {
                                if(tr["DepositReport"]){
                                    let depositReport = tr["DepositReport"][0]
                                    for (let line of depositReport) {
                                        if(printer.isHebrewText(aThis.supportHebrew(line,true,true).trim())){
                                            aThis.printFloatingLine( aThis.supportHebrew(line,true,true),"")
                                        }
                                        else{
                                            aThis.printFloatingLine( aThis.supportHebrew(line,true,true),"",undefined,undefined,true)
                                        }
                                    }
                                }
                                aThis.printLine(" ");
                                aThis.printLine(" ");
                            })
                        }
                    }
                    catch(e) {
                        console.error(`error while trying to parse emvTransmitReport for printing`)
                        console.error(e)
                    }		
                }


                function printRecieptDetails(payments,zReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;


                    let widths = printer.zReportTableWidths;
                    let totalReciepts = 0;

                    let counterSpecialitems = 0;
                    for (let method in payments) {
                        if(payments[method].isInXZ == 1 && PositiveTS.Service.SmartVoucher.isPaymentmethodSpecialitem(method) ) {
                            if (session.fixedFloat(payments[method].amount,2) != 0) {
                                counterSpecialitems++;
                            }
                        }
                    }

                    let shouldPrint = false;
                    if (zReport) {
                        shouldPrint = zReport.totalHakafaDebtPayments != null && zReport.totalHakafaDebtPayments != 0;
                    }

                    if (shouldPrint) {
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }
                        
                        aThis.printHeader("פירוט קבלות");
            
                        if (zReport) {
                            aThis.printTableRow([`תשלום חוב הקפה:`,session.fixedNumber(zReport.totalHakafaDebtPayments)],widths)
                            totalReciepts += zReport.totalHakafaDebtPayments;
                        }

                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        aThis.printFloatingLine(`-`.repeat(aThis.lineLength),'');
                        aThis.printTableRow([`סה"כ קבלות:`, session.fixedNumber(totalReciepts)],widths);
                        zebra.append(aThis.allowComponents.init);
                    }

                    if (counterSpecialitems > 0) {
                        if(printer.isHTMLBasedPrinting() && !shouldPrint) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }
                        let totalReciepts = 0;

                        aThis.printLine(" ");
                        aThis.printHeader("פירוט קבלות של טעינת כרטיסים");
            
                        for (let method in payments) {
                            if(payments[method].isInXZ == 1) {
                                if ( PositiveTS.Service.SmartVoucher.isPaymentmethodSpecialitem(method) ) {
                                    if (payments[method].amount != 0) {
                                        aThis.printTableRow([payments[method].displayName + ":", session.fixedNumber(-payments[method].amount)],widths);
                                        totalReciepts -= payments[method].amount;
                                    }
                                }
                            }
                        };

                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        aThis.printFloatingLine(`-`.repeat(aThis.lineLength),'');
                        aThis.printTableRow([`סה"כ טעינת כרטיסים:`, session.fixedNumber(totalReciepts)],widths);
                        zebra.append(aThis.allowComponents.init);
                        
                    }
                    if(printer.isHTMLBasedPrinting() && (shouldPrint || counterSpecialitems > 0)) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }
                    
                }
                function printSaleAnalysis(zReport: PositiveTS.Storage.Entity.ZReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    let widths = printer.zReportTableWidths;

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }		

                    aThis.printHeader(i18next.t('printing.zSalesAnalysis'))
            
            
                    zebra.append(aThis.allowComponents.init);
                    aThis.printTableRow([`${i18next.t('printing.cancels')}:`,session.fixedNumber(zReport.totalCreditAmount || 0)],widths) 
                    aThis.printTableRow([`${i18next.t('printing.canceledItemsNumber')}:`,session.fixedNumber(zReport.totalCreditQty || 0)],widths)
                    aThis.printTableRow([`${i18next.t('printing.refunds')}:`,session.fixedNumber(zReport.totalReturnAmount || 0)],widths)
                    aThis.printTableRow([`${i18next.t('printing.itemsRefundsCount')}:`,session.fixedNumber(zReport.totalReturnQty || 0)],widths)
                    aThis.printTableRow([`${i18next.t('printing.totalDiscounts')}:`,session.fixedNumber(zReport.totalDiscountsAmount || 0)],widths)
                    // aThis.printFloatingLine(`סה"כ מחזור: ${zReport.totalPayments}`,"")
                    aThis.printTableRow([`${i18next.t('printing.totalCustomers')}:`,`${zReport.debitSales}`],widths)
                    if(zReport.tamashCount > 0){
                        aThis.printTableRow([`${i18next.t('printing.totalDeliveryCertificates')}:`,`${zReport.tamashCount}`],widths)
                    }
                    aThis.printTableRow([`${i18next.t('printing.averageCustomerSale')}:`,`${zReport.averageSale}`],widths)
                    aThis.printTableRow([`${i18next.t('printing.soldItemsAmount')}:`,`${session.fixedFloat(zReport.totalItems)}`],widths)
                    aThis.printTableRow([`${i18next.t('printing.averageCustomerAmount')}:`,`${zReport.averageQtyPerCustomer}`],widths)
                    aThis.printTableRow([`${i18next.t('printing.averageItemPrice')}:`,`${zReport.averageItemPrice}`],widths)
                    aThis.printTableRow([`${i18next.t('printing.salesAbove5000ILS')}:`,`${zReport.salesAbove5k}`],widths)

                    if(zReport.canceledBonCount){
                        aThis.printTableRow([`${i18next.t('printing.canceledBonnes')}:`,String(zReport.canceledBonCount)],widths);
                        aThis.printTableRow([`${i18next.t('printing.canceledBonnesWorth')}:`,session.fixedNumber(zReport.canceledBonSum)],widths);
                    }

                    if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners)) {
                        aThis.printTableRow([`${i18next.t('printing.totalSittingDiners')}:`,String(zReport.totalSittingDiners)],widths);
                        aThis.printTableRow([`${i18next.t('printing.sittingDinersAvg')}:`,session.fixedNumber(zReport.getSittingDinersAvg(), 2)],widths);

                        if (jsonConfig.getVal(jsonConfig.KEYS.isDelivery)) {
                            aThis.printTableRow([`${i18next.t('printing.totalDeliveriesDiners')}:`,String(zReport.totalDeliveriesDiners)],widths);
                            aThis.printTableRow([`${i18next.t('printing.deliveriesDinersAvg')}:`,session.fixedNumber(zReport.getDeliveriesDinersAvg(), 2)],widths);
                            aThis.printTableRow([`${i18next.t('printing.deliveriesAvg')}:`,session.fixedNumber(zReport.getDeliveriesAvg(), 2)],widths);    
                        }
                    }
                    
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }		
                }
                function printVoucherDetails(vouchers) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    let widths = printer.zReportTableWidths;

                    if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }

                    let totalVoucher = 0;
                    let vouchersNoTenbisCibus = vouchers.filter(voucher => [ "Tenbis","Cibus" ].indexOf(voucher.voucher.name) === -1);
                    let regularVouchers = vouchersNoTenbisCibus.filter(voucher => !voucher.voucher.isDiscountVoucher)
                    if (regularVouchers.length > 0) {
                        
                        aThis.printHeader("פירוט שוברי קניה");
                        // aThis.printHeader(aThis.getTableRow(["שם","סכום"],widths))

                        for (let voucher of regularVouchers) {
                            aThis.printTableRow([voucher.voucher.name + ":", session.fixedNumber(voucher.amount)],widths);
                            totalVoucher += voucher.amount;
                        };

                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        printer.printHr();				
                        aThis.printTableRow([`סה"כ שוברי קניה:`, session.fixedNumber(totalVoucher)],widths);
                        
                        zebra.append(aThis.allowComponents.init);
                        aThis.printLine(" ")
                    }

                    let discountVouchers = vouchersNoTenbisCibus.filter(voucher => voucher.voucher.isDiscountVoucher)
                    if (discountVouchers.length > 0) {
                        totalVoucher = 0;
                        aThis.printHeader("פירוט שוברי הנחה - לא נכלל בתקבולים");
                        // aThis.printHeader(aThis.getTableRow(["שם","סכום"],widths))

                        for (let voucher of discountVouchers) {
                            aThis.printTableRow([voucher.voucher.name + ":", session.fixedNumber(voucher.amount)],widths);
                            totalVoucher += voucher.amount;
                        };

                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        printer.printHr();
                        aThis.printTableRow([`סה"כ שוברי הנחה:`, session.fixedNumber(totalVoucher)],widths);
                    }

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }		
                }
                function printCreditCardSummary(zReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;
                    let widths = printer.zReportTableWidths;

                    let parsedSummary = {}
                    if (zReport.creditCardSummary) {
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }		
                        try {
                            parsedSummary = JSON.parse(zReport.creditCardSummary)
                            zebra.append(aThis.allowComponents.init);
                            aThis.printLine(" ");
                            zebra.append(aThis.allowComponents.bold);
                            zebra.append(aThis.allowComponents.smallLineSpace);
                            aThis.printHeader(`פירוט עסקאות אשראי`)

                            zebra.append(aThis.allowComponents.init);
                            aThis.printTableRow([`סה"כ ויזה כאל:`, session.fixedNumber(parsedSummary['VisaCal'],2)],widths)
                            aThis.printTableRow([`סה"כ ישראכרט:`,session.fixedNumber(parsedSummary['Isracard'],2)],widths)
                            aThis.printTableRow([`סה"כ דיינרס:`, session.fixedNumber(parsedSummary['Diners'],2)],widths)
                            aThis.printTableRow([`סה"כ אמריקן אקספרס:`,session.fixedNumber(parsedSummary['AmericanExpress'],2)],widths)
                            aThis.printTableRow([i18next.t("zReport.leumiCard"),session.fixedNumber(parsedSummary['LeumiCard'],2)],widths) 
                        }
                        catch(e) {
                            console.warn('could not parse credit type summary json')
                        }
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }		
                    }
                }
                function printZVolumeInfo(zReport:Storage.Entity.ZReport,payments,totalWithdrawal?) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    if (zReport.totalReceipts == null) { //if zReport is in old structure - don't print this segment
                        return;
                    }
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }		
                    let widths=printer.zReportTableWidths
                    aThis.printHeader(`דוח מע"מ ל Z`);

                    let totalReceiptsWithoutSpecial = zReport.totalReceipts;
                    for (let method in payments) {
                        if(payments[method].isInXZ == 1 && PositiveTS.Service.SmartVoucher.isPaymentmethodSpecialitem(method) ) {
                            totalReceiptsWithoutSpecial += payments[method].amount;
                        }
                    }

                    let vatPct = session.fixedFloat(Number(session.systemSettings.vat)/100,3);
                    let totalOwsVat = zReport.totalVat/vatPct;
                    
                    let totalTaxInv = zReport.totalHakafa-zReport.totalHakafaTamash;
                    const deliveryApiTotals = posUtils.jsonParse(zReport.deliveryApiTotals)
                    let deliveryApiTotalsTamashAmountSum = 0
                    let deliveryApiTotalsTamashCountSum = 0

                    if (deliveryApiTotals) {
                        for (let deliveryApiTotal of Object.values(deliveryApiTotals)) {
                            deliveryApiTotalsTamashAmountSum += (deliveryApiTotal as any).totalTamashAmount
                            deliveryApiTotalsTamashCountSum += (deliveryApiTotal as any).tamashCount
                        }
                    }

                    let totalShipmentInv = zReport.totalHakafa-totalTaxInv+zReport.cibusTotalAmount+zReport.tenbisTotalAmount + zReport.goodiTotalAmount + zReport.valuTotalAmount + zReport.dtsTotalAmount + zReport.mishlohaTotalAmount + zReport.paiditTotalAmount + deliveryApiTotalsTamashAmountSum;
                    let zTotalPayments = zReport.totalPayments+totalTaxInv-totalReceiptsWithoutSpecial;
                    let zTotalDebitInvoice = zReport.totalPayments - totalReceiptsWithoutSpecial;
                    let totalZWithoutVat = (zTotalPayments-zReport.totalVat-zReport.totalVat/vatPct);

                    let totalFreeOfVatToPrint = 0;
                    if (Math.abs(totalZWithoutVat) > Math.abs((zReport.totalVat+0.1)*(1/vatPct+1) - (zReport.totalVat)*(1/vatPct+1))) {
                totalFreeOfVatToPrint = totalZWithoutVat
                    }

                    aThis.printTableRow([`סה"כ חשבוניות מס קבלה:`,session.fixedNumber(zTotalDebitInvoice)],widths);
                    aThis.printTableRow([`סה"כ חשבוניות מס:`,session.fixedNumber(totalTaxInv)],widths);
                    aThis.printFloatingLine("-".repeat(widths.reduce((a,b)=>a+b,0)));
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.bold);
                    aThis.printTableRow([`סה"כ חשבוניות:`,session.fixedNumber(zTotalDebitInvoice+totalTaxInv)],widths);
                    aThis.printTableRow([`מתוכן פטורות ממע"מ:`,Base.numberWithCommas(session.fixedNumber(totalFreeOfVatToPrint))],widths);
                    aThis.printTableRow([`סה"כ חייבות במע"מ:`, Base.numberWithCommas(session.fixedNumber(totalOwsVat+zReport.totalVat))],widths);

                    aThis.printTableRow([`סה"כ מע"מ:`,Base.numberWithCommas(session.fixedNumber(zReport.totalVat))],widths);
                    
                    zebra.append(aThis.allowComponents.init);
                    aThis.printLine(" ");

                    aThis.printHeader(`דוח מכירות לפי מסמך`);
                    aThis.printTableRow([`סה"כ חשבוניות מס קבלה:`,session.fixedNumber(zTotalDebitInvoice)],widths);
                    aThis.printTableRow([`סה"כ חשבוניות מס:`,session.fixedNumber(totalTaxInv)],widths);
                    aThis.printTableRow([`סה"כ תעודות משלוח:`,session.fixedNumber(totalShipmentInv)],widths);
                    printer.printHr();
                    aThis.printHeader(aThis.getTableRow(['סה"כ מכירות:', session.fixedNumber(zTotalDebitInvoice+totalTaxInv+totalShipmentInv)],widths));

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }		
                }
                function printCashManagementReport(cashPayments,printStatementDifference = false, totalWithdrawal=null, cashToSafe=0,enterStatement=0,zStatement?,withdrawnCount?) {
                    let zebra = printer.jzebra;
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }
                    let widths = [printer.firstDefaultColWidth+printer.thirdDefaultColWidth, printer.lineLength - (printer.firstDefaultColWidth+printer.thirdDefaultColWidth)]
                    let totalWithdrawalText = totalWithdrawal ? `${session.fixedNumber(totalWithdrawal)}` : session.fixedNumber(0);
                    let totalCashToSafe = cashToSafe > 0 ? `${session.fixedNumber(cashToSafe)} -` : session.fixedNumber(0);
                    let cashToSafeNegative = (Number(cashToSafe) * -1)
                    
                    zebra.append(printer.allowComponents.init);
                    printer.printLine(' ');
                    printer.printHeader(i18next.t("cashManagement.printer.title"));

                    printer.printHeader(printer.getTableRow([i18next.t("cashManagement.printer.action"), i18next.t("cashManagement.printer.sum")], widths));
                    printer.printTableRow([i18next.t("cashManagement.printer.enterStatement"), session.fixedNumber( enterStatement)], widths);
                    printer.printTableRow([i18next.t("cashManagement.printer.cashInvoices"),  session.fixedNumber(cashPayments)], widths);
                    if(totalWithdrawal)  printer.printTableRow([i18next.t("cashManagement.printer.cashWithdrawal"), totalWithdrawalText], widths);
                    printer.printTableRow([i18next.t("cashManagement.printer.movedToSafe"),  totalCashToSafe], widths);

                    printer.printFloatingLine(`-`.repeat(printer.lineLength),``);

                    let total = (Number(enterStatement) + cashPayments) + (cashToSafeNegative + Number(totalWithdrawal));
                    printer.printHeader(printer.getTableRow([i18next.t("cashManagement.printer.total"), 
                                                            `${session.fixedNumber(total)}`], widths));
                    
                    if((Service.CashierStatement.forceCashDeclarationsAtEndOfDay()) &&printStatementDifference) {
                        let totalIncomeOfCash = cashPayments + Number(enterStatement);
                        let totalOutcomeOfCash = cashToSafeNegative + totalWithdrawal;
                        zStatement = zStatement == undefined ? Service.CashierStatement.getCashierZStatement() : zStatement;

                        
                        let difference = ((totalIncomeOfCash + totalOutcomeOfCash) - Number(zStatement)) * -1;


                        printer.printTableRow([i18next.t("zReport.zStatement"), session.fixedNumber(Number(zStatement))], widths);
                        printer.printTableRow(["הפרש בין הצהרה לקופה: ", session.fixedNumber(difference)], widths);
                    }
                    if(!posUtils.isNullOrUndefined(withdrawnCount)) {
                            if(withdrawnCount > 0) {
                                printer.printTableRow([i18next.t("withdrawal.withdrawnCount"), session.fixedNumber(withdrawnCount)], widths);
                            }
                        }
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }
                }
                function printCurrenciesReport(currenciesSummary) {
                    let multiCurr = Service.MultiCurr.getInstance();
                    if (multiCurr.isMultiCurr() == false) {
                        return; // A regular POS doesn't need this detail and doesn't have such detail at all
                    }

                    let widths = [printer.secondDefaultColWidth, printer.thirdDefaultColWidth, printer.secondDefaultColWidth];

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }

                    printer.printHeader(i18next.t("currencies.printer.title"));
                    let headerCols = [];
                    headerCols.push(i18next.t("currencies.printer.currency"));
                    headerCols.push(i18next.t("currencies.printer.sum"));
                    headerCols.push(i18next.t(`currencies.printer.sumIn`) + multiCurr.i18nextPosCurrency);
                    printer.printTableRow(headerCols,widths);
                    let currencies: Array<string> = _.uniq(multiCurr.getPosCurrenciesEnabled().concat(Object.keys(currenciesSummary)));
                    for (const currency of currencies) {
                        let currencySummary = currenciesSummary[currency];
                        let currencyName = multiCurr.getI18nextCurrency(currency);
                        let totalFromCurrency = session.fixedNumber(currencySummary?.total || 0);
                        let totalAsCurrentPosCurrency = session.fixedNumber(currencySummary?.totalInPosCurrency || 0);
                        printer.printTableRow([currencyName, totalFromCurrency.toString(), totalAsCurrentPosCurrency.toString()], widths);
                    }
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }
                }
                function printXZPayments(payments,cashOutside = 0, roundedAmount?, roundedCount?, tipsAmount?) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;
                    
                    zebra.append(aThis.allowComponents.init);
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }
                    aThis.printHeader(i18next.t('printing.totalPaymeysByMethod'));
                    
                    
                    let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth,aThis.thirdDefaultColWidth];

                    if (aThis.isPicturesPrinter()) {
                        widths[0] -= 1;
                        widths[2] += 1;
                    }
                    
                    let totalPaymentSum = 0, totalPaymentAmount = 0;
                    aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'),i18next.t('printing.sum'),i18next.t('printing.count')],widths))
            
                    for (let method in payments) {
                        if( payments[method].isInXZ == 1) { // && ! PositiveTS.Service.SmartVoucher.isPaymentmethodSpecialitem(method) ) {
                            totalPaymentSum += payments[method].amount;
                            totalPaymentAmount += payments[method].count || 0;
                            let amount = payments[method].amount;
                            if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
                                if (parseInt(method) === PositiveTS.Storage.Entity.SalePayment.METHOD_CASH) {
                                    amount = amount - cashOutside;
                                }
                            }

                            amount = amount || 0;

                            let values = [payments[method].displayName, 
                                                        session.fixedNumber(amount), 
                                                        String(payments[method].count || 0)]
                            if(amount != 0){
                                aThis.printTableRow(values,widths)
                            }
                        }
                    };
            
                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.bold);
                    printer.printHr();
                    if(!posUtils.isNullOrUndefined(roundedAmount)) {
                        zebra.append(aThis.allowComponents.init);
                        aThis.printTableRow([i18next.t('zReport.roundTotals'),session.fixedNumber(roundedAmount, 2), String(roundedCount)],widths);
                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                    }
                    
                    aThis.printTableRow([`${i18next.t('printing.totalPayments')}:`,session.fixedNumber(totalPaymentSum - cashOutside), String(totalPaymentAmount)],widths)
                    zebra.append(aThis.allowComponents.init);


                    if (Service.Tip.isHasTips() && tipsAmount  && tipsAmount > 0) {
                        let values = [i18next.t('zReport.tipsTotal'), 
                        session.fixedNumber(tipsAmount)];
                        zebra.append(printer.allowComponents.newLine);
                        aThis.printHeader(aThis.getTableRow(values,widths))
                        zebra.append(aThis.allowComponents.init);

                    }

                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }
                }
                function printCanceledBonSummery(type){
                    let aThis = printer;
                    let zebra = aThis.jzebra;
                    let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth,aThis.thirdDefaultColWidth];

                    let bonTotalSumAndQuantityCanceled = PositiveTS.Service.HoldSale.getBonTotalSumAndQuantityCanceled();
                    zebra.append(aThis.allowComponents.init);
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }
                    zebra.append(aThis.allowComponents.rightAlignment);

                    if ( bonTotalSumAndQuantityCanceled.totalQuantity){
                        aThis.printLine(" ");
                        aThis.printHeader(`ביטולי בונים`);
                        aThis.printHeader(aThis.getTableRow(["שם","סכום","מונה"],widths))

                        aThis.printTableRow([`סה"כ ביטולי פריטים:`,session.fixedNumber(bonTotalSumAndQuantityCanceled.totalPrice),String(bonTotalSumAndQuantityCanceled.totalQuantity)],widths);
                    }
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }
                }
                function printDeliveryDetails(deliveriesCount,deliveriesSum,cashOutside,canceledBon){
                        let aThis = printer;
                        let zebra = aThis.jzebra;
                        
                        zebra.append(aThis.allowComponents.init);
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }

                        aThis.printHeader(`דוח משלוחים`);
                        let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth,aThis.thirdDefaultColWidth];
                        let totalPaymentSum = 0, totalPaymentAmount = 0;
                        aThis.printHeader(aThis.getTableRow(["שם","סכום","מונה"],widths))
                

                
                        zebra.append(aThis.allowComponents.init);
                        aThis.printTableRow([`סה"כ משלוחים:`,session.fixedNumber(deliveriesSum), String(deliveriesCount)],widths)
                        if(cashOutside != -1){
                            aThis.printTableRow(["מזומן בחוץ:", session.fixedNumber(cashOutside)],widths);
                        }
                        zebra.append(aThis.allowComponents.init);
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }
                }

                function printDalpaksDetails(dalpakTotals) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;
                    let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth];

                    zebra.append(aThis.allowComponents.init);
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.startReportTable();
                    }

                    aThis.printHeader(i18next.t('zxReport.dalpaks.dalpaksReport'));
                    aThis.printHeader(aThis.getTableRow([i18next.t('zxReport.dalpaks.name'), i18next.t('zxReport.dalpaks.amount')],widths))

                    aThis.printTableRow([`${i18next.t('zxReport.dalpaks.totalDiners')}:`, String(dalpakTotals.totalDiners)],widths)
                    aThis.printTableRow([`${i18next.t('zxReport.dalpaks.totalOpenTables')}:`, String(dalpakTotals.totalOpenTables)],widths)
                    aThis.printTableRow([`${i18next.t('zxReport.dalpaks.OpenTablesSum')}:`,String(dalpakTotals.OpenTablesSum)],widths)

                    zebra.append(aThis.allowComponents.init);
                    zebra.append(aThis.allowComponents.init);
                    if(printer.isHTMLBasedPrinting()) {
                        PositiveTS.Printing.HtmlPrinting.endReportElement();
                    }
                }

                function printHakafaDetails(zReport, tenbisPluxeeAmount = null) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;
                    
                    let tamashTotalAmount = 0;
                    
                    if (zReport){
                        tamashTotalAmount = zReport.tamashTotalAmount
            
                    } else {
                        tamashTotalAmount = tenbisPluxeeAmount.tamashTotalAmount
                    }
            
                    let totalHakafaTamash = 0
                    let totalHakafa = 0;
                    let hakafaCount = 0;
                    let tamashCount = 0;
                    let pluxeeTotalAmount = 0;
                    let tenbisCount = 0
                    let mishlohaCount = 0
                    let cibusCount = 0
                    let tenbisTotalAmount = 0;
                    let mishlohaTotalAmount = 0;
                    let goodiTotalAmount = 0;
                    let goodiCount = 0;
                    let dtsTotalAmount = 0;
                    let dtsCount = 0;
                    let yaadTotalAmount = 0;
                    let yaadCount = 0;
                    let valuTotalAmount = 0;
                    let valuCount = 0;
                    let tamashHakafaCount = 0;
                    let taxInvHakafaCount = 0;
                    let deliveryApiTotals = null;
                    let paiditTotalAmount = 0
                    let paiditCount = 0

                    if (zReport){
                        pluxeeTotalAmount = zReport.cibusTotalAmount
                        tenbisCount = zReport.tenbisCount
                        mishlohaCount = zReport.mishlohaCount
                        cibusCount = zReport.cibusCount
                        tenbisTotalAmount = zReport.tenbisTotalAmount
                        mishlohaTotalAmount = zReport.mishlohaTotalAmount
                        goodiTotalAmount = zReport.goodiTotalAmount;
                        goodiCount = zReport.goodiCount
                        dtsTotalAmount = zReport.dtsTotalAmount;
                        dtsCount = zReport.dtsCount
                        yaadTotalAmount = zReport.yaadTotalAmount;
                        yaadCount = zReport.yaadCount
                        valuTotalAmount = zReport.valuTotalAmount
                        valuCount = zReport.valuCount
                        totalHakafaTamash = zReport.totalHakafaTamash
                        hakafaCount = zReport.hakafaCount
                        tamashCount = zReport.tamashCount
                        paiditTotalAmount = zReport.paiditTotalAmount
                        paiditCount = zReport.paiditCount

                        totalHakafa = zReport.totalHakafa;
                        deliveryApiTotals = posUtils.isBlank(zReport.deliveryApiTotals) ? null : JSON.parse(zReport.deliveryApiTotals);
                    } else {
                        pluxeeTotalAmount = tenbisPluxeeAmount.cibusTotalAmount
                        tenbisCount = tenbisPluxeeAmount.tenbisCount
                        mishlohaCount = tenbisPluxeeAmount.mishlohaCount
                        cibusCount = tenbisPluxeeAmount.cibusCount
                        tenbisTotalAmount = tenbisPluxeeAmount.tenbisTotalAmount
                        mishlohaTotalAmount = tenbisPluxeeAmount.mishlohaTotalAmount
                        totalHakafaTamash = tenbisPluxeeAmount.totalHakafaTamash
                        goodiTotalAmount = tenbisPluxeeAmount.goodiTotalAmount;
                        dtsTotalAmount = tenbisPluxeeAmount.dtsTotalAmount;
                        yaadTotalAmount = tenbisPluxeeAmount.yaadTotalAmount;
                        valuTotalAmount = tenbisPluxeeAmount.valuTotalAmount
                        valuCount = tenbisPluxeeAmount.valuCount
                        goodiCount = tenbisPluxeeAmount.goodiCount;
                        dtsCount = tenbisPluxeeAmount.dtsCount;
                        yaadCount = tenbisPluxeeAmount.yaadCount;
                        hakafaCount = tenbisPluxeeAmount.hakafaCount
                        tamashCount = tenbisPluxeeAmount.tamashCount
                        totalHakafa = tenbisPluxeeAmount.totalHakafa
                        deliveryApiTotals = tenbisPluxeeAmount.deliveryApiTotals;
                        paiditTotalAmount = tenbisPluxeeAmount.paiditTotalAmount
                        paiditCount = tenbisPluxeeAmount.paiditCount
                    }

                    let deliveryApiTotalsAmountSum = 0;
                    let deliveryApiTotalsCountSum = 0;

                    if (deliveryApiTotals) {
                        for (let deliveryApiTotal of Object.values(deliveryApiTotals)) {
                            deliveryApiTotalsAmountSum += (deliveryApiTotal as any).amount;
                            deliveryApiTotalsCountSum += (deliveryApiTotal as any).count;
                        }
                    }


                    
                    let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth,aThis.thirdDefaultColWidth];

                    if (aThis.isPicturesPrinter()) {
                        widths[0] -= 1;
                        widths[2] += 1;
                    }

                    if (tamashTotalAmount != 0 || totalHakafa != 0) {
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }
                        aThis.printHeader(i18next.t('printing.defferedSalesDetails'));

                        aThis.printHeader(aThis.getTableRow([i18next.t('printing.description'),i18next.t('printing.sum'),i18next.t('printing.count')],widths));

                        if (tamashTotalAmount != totalHakafa && totalHakafa != 0) {
                            aThis.printTableRow([i18next.t('printing.defferedTaxInvoice') + ":", 
                                session.fixedNumber(totalHakafa - totalHakafaTamash),
                                session.fixedNumber(hakafaCount-(tamashCount-tenbisCount-cibusCount-goodiCount-dtsCount))],widths);
                        }
                        if (totalHakafaTamash) {
                            aThis.printTableRow([`${i18next.t('printing.defferedDeliveryCertificate')}:`, 
                                session.fixedNumber(totalHakafaTamash),
                                session.fixedNumber(tamashCount-tenbisCount-cibusCount-goodiCount-dtsCount)],widths);
                        }

                        if (tamashTotalAmount) {
                            if (pluxeeTotalAmount){
                                    aThis.printTableRow(["פלאקסי", session.fixedNumber(pluxeeTotalAmount),  session.fixedNumber(cibusCount)],widths);
                            }
                            if (tenbisTotalAmount){
                                    aThis.printTableRow(["תן ביס", session.fixedNumber(tenbisTotalAmount),session.fixedNumber(tenbisCount)],widths);
                            }
                            if (mishlohaTotalAmount){
                                aThis.printTableRow(["משלוחה", session.fixedNumber(mishlohaTotalAmount),session.fixedNumber(mishlohaCount)],widths);
                            }
                            if (goodiTotalAmount){
                                aThis.printTableRow(["גודי", session.fixedNumber(goodiTotalAmount),session.fixedNumber(goodiCount)],widths);
                            }
                            if (paiditTotalAmount){
                                aThis.printTableRow(["פיידאיט", session.fixedNumber(paiditTotalAmount),session.fixedNumber(paiditCount)],widths);
                            }
                            if (dtsTotalAmount){
                                aThis.printTableEnglishRow(["DTS", session.fixedNumber(dtsTotalAmount),session.fixedNumber(dtsCount)].reverse(),widths.reverse());
                                widths.reverse()
                            }
                            if (yaadTotalAmount){
                                aThis.printTableEnglishRow(["יעד שריג", session.fixedNumber(yaadTotalAmount),session.fixedNumber(yaadCount)].reverse(),widths.reverse());
                                widths.reverse()
                            }
                            if (valuTotalAmount){
                                aThis.printTableRow(["שובר קניה", session.fixedNumber(valuTotalAmount),session.fixedNumber(valuCount)],widths);
                            }
                            if (deliveryApiTotals) {
                                for (let voucherTypeId in deliveryApiTotals) {
                                    let voucherTotals = deliveryApiTotals[voucherTypeId];
                                    const voucherName = Storage.Entity.Voucher.getVoucherNameByTypeId(voucherTypeId)
                                    let arrayRow = [voucherName, session.fixedNumber(voucherTotals.amount),session.fixedNumber(voucherTotals.count)]

                                    if (aThis.isHTMLBasedPrinting() || aThis.isHebrewText(voucherName)){
                                        aThis.printTableRow(arrayRow, widths);
                                    }else{
                                        aThis.printTableEnglishRow(arrayRow.reverse(), widths.reverse())
                                    }
                                }
                            }
                        }
                        zebra.append(aThis.allowComponents.init);
                        zebra.append(aThis.allowComponents.bold);
                        aThis.printFloatingLine(`-`.repeat(widths.reduce((a,b) => a+b,0)));
                        aThis.printTableRow([`${i18next.t('printing.totalDefferedSales')}:`, 
                                session.fixedNumber(totalHakafa+pluxeeTotalAmount+goodiTotalAmount+tenbisTotalAmount+valuTotalAmount+dtsTotalAmount+yaadTotalAmount+mishlohaTotalAmount+deliveryApiTotalsAmountSum + paiditTotalAmount),
                                session.fixedNumber(hakafaCount+tenbisCount+goodiCount+cibusCount+valuCount+dtsCount+yaadCount+mishlohaCount+deliveryApiTotalsCountSum + paiditCount)],widths);
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }
                    }
                }

                function printItemDetails (zReport:Storage.Entity.ZReport) {
                    let aThis = printer;
                    let zebra = aThis.jzebra;

                    if (zReport.itemDetails) {
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }

                        try {
                            let parsedItems = JSON.parse(zReport.itemDetails)
                            zebra.append(aThis.allowComponents.init)
                            aThis.printLine(" ")
                            zebra.append(aThis.allowComponents.bold)
                            zebra.append(aThis.allowComponents.smallLineSpace)
                            let widths = [aThis.firstDefaultColWidth,aThis.secondDefaultColWidth,aThis.thirdDefaultColWidth]
                            aThis.printHeader(i18next.t('printing.itemDetailsInZReport'))
                            aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'), i18next.t('printing.sum'), i18next.t('printing.quantity')], widths))

                            zebra.append(aThis.allowComponents.init);
                            for (let cat of parsedItems) {
                                let values = [cat.item_name.substr(0,30), session.fixedNumber(cat.sum_total,2), session.fixedNumber(cat.quantity,1)]
                                aThis.printTableRow(values,widths)
                            }
                            aThis.printHr()
                            let allQuantity = session.fixedNumber(parsedItems.reduce((a,b) => a + Number(b.quantity),0),1)
                            let allSumTotal = session.fixedNumber(parsedItems.reduce((a,b) => a + Number(b.sum_total),0),1)
                            aThis.printTableRow([i18next.t('printing.total'), allSumTotal, allQuantity], widths)

                        }
                        catch(error) {
                            console.error('could not parse item details json', error)
                        }
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }		
                    }
                }

                function printSumSalesDiscountReportZReport (zReport:Storage.Entity.ZReport) {
                    const salesDiscountReport = JSON.parse(zReport.salesDiscountReport)

                    if (!_.isEmpty(salesDiscountReport)) {
                        printTableSumSalesDiscountReportZReport(salesDiscountReport)
                    }
                }

                export function printTableSumSalesDiscountReportZReport (salesDiscountReport) {
                    let aThis = printer
                    let zebra = aThis.jzebra
                    try {
                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.startReportTable();
                        }

                        zebra.append(aThis.allowComponents.init)
                        aThis.printLine(aThis.allowComponents.newLine)
                        zebra.append(aThis.allowComponents.bold)
                        zebra.append(aThis.allowComponents.smallLineSpace)
                        let widths = [printer.firstDefaultColWidth+printer.thirdDefaultColWidth, printer.lineLength - (printer.firstDefaultColWidth+printer.thirdDefaultColWidth)]
                        aThis.printHeader(i18next.t('printing.salesDiscountReport'))
                        aThis.printHeader(aThis.getTableRow([i18next.t('printing.name'), i18next.t('printing.amount')], widths))
                        zebra.append(aThis.allowComponents.init);
                        let sumDiscounts = 0 

                        for (let discount in salesDiscountReport){
                            sumDiscounts += Number(salesDiscountReport[discount])
                            let values = [discount.substr(0,30), session.fixedNumber(salesDiscountReport[discount],  2)]
                            aThis.printTableRow(values,widths)
                        }

                        aThis.printHr()
                        aThis.printTableRow([i18next.t('printing.total'), session.fixedNumber(sumDiscounts, 2)], widths)
                        aThis.printLine(aThis.allowComponents.newLine)

                        if(printer.isHTMLBasedPrinting()) {
                            PositiveTS.Printing.HtmlPrinting.endReportElement();
                        }
                    }
                    catch(error) {
                        console.error(error)
                    }
                }
            }
        }
    }
}
