module PositiveTS {
  export module Components {
    export module TextInputDialog {
      const dialogSelector = "text-input-dialog"
      const textInputSelector = 'text-input'
      const textAreaInputSelector = 'text-input-dialog-textarea'

      export const keyboardTypes = {
        simpleKeyboard: "simpleKeyboard",
        keyPad: "keyPad",
      }

      function initData() {
        return {
          initialized: this.initialized,
          rejectFunc: null,
          resolveFunc: null,
          dialogElem: document.getElementById(dialogSelector),
          titleText: "",
          inputPlaceholderText: "",
          inputValue: "",
          requiredMessage: "",
          keyboardType: keyboardTypes.simpleKeyboard,
          showKeyboard: false,
          keyPadLayout: false,
          inputEl: null,
          inputType: "",
	        validateFunc: null,
          isAndroid: session.isAndroid,
        }
      }

      export function create() {

        let component = {
          template: JST.textInputDialog(),
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            
            open(titleText: string, inputPlaceholderText: string, requiredMessage: string, keyboardType: string, inputType:string = 'text', inputValue:string | number = "", validateFunc?: Function,showKeyboard:boolean = false): Promise<{ success: boolean, result: string }> {
              return new Promise((resolve, reject) => {
                this.initialized = true;

                if (this.dialogElem == null) {
                  this.dialogElem = document.getElementById(dialogSelector)
                }

                this.titleText = titleText
                this.inputPlaceholderText = inputPlaceholderText
                this.requiredMessage = requiredMessage
                this.keyboardType = keyboardTypes[keyboardType]
                this.keyPadLayout = this.keyboardType === 'keyPad'
                this.inputType = inputType
                this.inputValue = inputValue
                this.validateFunc = validateFunc
                this.isAndroid = session.isAndroid
                this.showKeyboard = showKeyboard
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
                this.dialogElem.showModal();
                $(document).unbind('keypress');
              })
            },
            close() {
              if (this.initialized) {
                if (this.dialogElem == null) {
                  this.dialogElem = document.getElementById(dialogSelector)
                }

                this.dialogElem.close();
                $(document).keypress(posVC.onKeyPress);
                this.inputEl.blur()
              }
              this.cleanData()
            },
            confirmAndClose() {
              if (typeof this.validateFunc == 'function'){
                if (!this.validateFunc(this)){
                  return
                }
              }else {
                if (posUtils.isBlank(this.inputValue) && !posUtils.isBlank(this.requiredMessage)) {
                  this.showError(this.requiredMessage);
                  this.inputValue = ""
                  return;
                }
              }

              this.resolveFunc({ success: true, result: this.inputValue });
              this.close();
              return;
            },
            cancelAndClose() {
              this.resolveFunc({ success: false, result: "" });
              this.close();
            },
            clearTapped(stayOpen?) {
              this.inputValue = ""

              if (!stayOpen) {
                this.cancelAndClose();
              }
            },
            showError(errStr) {
              app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              })
            },
            onKeyBoardTapped(value) {
              this.inputValue = value
            },
            onKeyPadTapped(key) {
              if (key == 'bksp') {
                this.inputValue = this.inputValue.slice(0, -1);
              } else {
                this.inputValue += key;
              }
              this.inputEl.focus()
            },
            toggleKeyboard() {
              this.showKeyboard = !this.showKeyboard
              this.inputEl.focus()
            }
          },
          data: initData,
          watch: {
            async inputType(newInputType, oldInputType) {
              if(!posUtils.isBlank(newInputType)) {
                  await this.$nextTick()
                  if(newInputType) {
                    this.inputEl = newInputType == 'textarea' ? document.getElementById(textAreaInputSelector) : document.getElementById(textInputSelector)
                    this.inputEl.focus()
                  }
              }
            }
        },
        }

        VueApp.component(dialogSelector, component)
      }
    }
  }
}
