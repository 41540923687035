module PositiveTS {
    export module Service {
        export module FileManager {
            export const DEFAULT_PATH = 'C:\\valu';

            export async function readFIle(path){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    action: "READ_FILE"
                },"file_manager" )

                return response
            }

            export async function readDirContent(path,ext = ""){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    ext: ext,
                    action: "READ_DIR"
                },"file_manager" )

                return response
            }

            export async function listDirFiles(path,ext = ""){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    ext: ext,
                    action: "LIST_DIR"
                },"file_manager" )

                return response
            }

            export async function writeFile(path, content){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    content: content,
                    action: "WRITE_FILE"
                },"file_manager" )

                return response
            }

            export async function fileExists(path){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    action: "FILE_EXISTS"
                },"file_manager" )

                return response
            }

            export async function deleteFile(path){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    action: "DELETE_FILE"
                },"file_manager" )

                return response
            }

            export async function deleteDirFiles(path, ext = ""){
                let response = await GenericAddon.sendNativeMessageToExtension({
                    path: path,
                    ext: ext,
                    action: "DELETE_DIR_FILES"
                },"file_manager" )

                return response
            }

            export function getPath(additionalExt = '') {
                return DEFAULT_PATH + additionalExt;
            }


            export async function readDirContentAndConvertToArray(path,ext = ""){
                let dataArray = []
                let files = await this.readDirContent(path,ext)
        
                if (!files.request.result.success ||files.request.result.data.toLowerCase().includes("empty diretory")){
                    return dataArray;
                }
        
                let filesArray = files.request.result.data.split("\n")
                for (let file of filesArray) {
                    dataArray.push(JSON.parse(file))
                }
        
                return dataArray;
            }

            export async function listDirFilesAndConvertToArray(path,ext = ""){
                let dataArray = []
                let files = await this.listDirFiles(path,ext)
        
                if (!files.request.result.success ||files.request.result.data.toLowerCase().includes("empty diretory")){
                    return dataArray;
                }
        
                let filesArray = files.request.result.data.split("\n")
        
                return filesArray;
            }

        }
    }
}