module PositiveTS { export module Dialogs { export module Hakafa {
  export enum EnumMode {
    search,
    add
  }
  
  export class Fields {
      "HakafaSetCustomer-s_id_number": string = "";
      "HakafaSetCustomer-s_first_name": string = "";
      "HakafaSetCustomer-s_last_name": string = "";
      "HakafaSetCustomer-dt_birth_date": string = "";
      "HakafaSetCustomer-s_phone_number_1": string = "";
      "HakafaSetCustomer-amount": string = "0";
      "HakafaSetCustomer-merakez": string = "";
  }
}}}
