module PositiveTS { export module Dialogs { export module HakafaReport {
  const DIALOG_SELECTOR = "#HakafaResultReport-dialog"




  export class HakafaReportResultDialog {

    private _dialog: JQuery;
    private _tz:string;




    constructor(){
      this._superInit(DIALOG_SELECTOR);
      this._bindAction();
    }



    open(html, currentBalance){
      $(document).unbind('keypress');
      this._dialog.dialog('open');
      $("#HakafaResultReport-result").html(html);
      $("#HakafaResultReport-amount").html(currentBalance);
    }









  private _bindAction(){
    this._dialog.find('#HakafaResultReport-ok').unbind('click');
    this._dialog.find('#HakafaResultReport-ok').bind('click', () => {
        this._close();
    });


  }


      private _close():void{
        this._dialog.dialog('close');
      }

    private _superInit(dialogSelector):void {
      this._dialog = $( dialogSelector ).dialog({
          autoOpen: false,
          modal: true,
          dialogClass: 'positive-dialog',
          width: '90%',
          height: 550,
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });

        var dlg:any = this._dialog;
        dlg.find( '.ui-input' ).inputField({
            keyboardOn: true,
            clearOn: true,
            disclosureOn: true
          });
      dlg.find( '.ui-input-date' ).inputField({
          keyboardOn: true,
          clearOn: true,
          disclosureOn: true,
          type: 'date'
        });
    }

  }

}}}
