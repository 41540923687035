module PositiveTS {
export module Components {
export module CashManagement {

  const _dialogSelector = "#positive-cash-management"
  const _vueComponentName = 'cash-management';

  export function create() {
    let cashManagement = {
        template: JST.cashManagement(),
        methods: {
          open: open,
          close: close,
          async depositReport() {
            this.close();
            try {
                let employee = await app.showManagerApprovalDialog()
                pNavigator.pushPage('pages', '', null, { initParams: { route: Components.DepositReport.route, extraData: {employee}}})
            }catch{
            }
          },
          openStatement(statementType) {
            
            if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()){
Pinia.componentsStore.openComponent( {componentName:"CashierStatementMultiCurrencies", args: [statementType]});
            }else{
              
              Pinia.globalStore.setCashierStatementType(statementType);
Pinia.componentsStore.openComponent( {componentName:"CashierStatement", args: []});
            } 
            this.close();
          },
        },
        setup(){

          const globalStore = Pinia.useGlobalStore();
          const {isRoshemet,mobileLayout} = Pinia.storeToRefs(globalStore);
          const globalStoreProps ={isRoshemet,mobileLayout}

          return {...globalStoreProps}
        },
        data: _initData,
    }
    VueApp.component(_vueComponentName,cashManagement)
  }

  function open(){
    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: 'positive-dialog',
        width: Pinia.globalStore.mobileLayout ? '80vw' : '33%',
        minHeight: 450,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });

  }

  function _initData(){
    return {
      
    };
  }

  function close() {
    $(_dialogSelector).dialog('close');
  }
}}}
