module PositiveTS {
export module Storage {
export module Entity {
export class CreditCardCompanies extends WasmEntity {

	constructor() {
		let meta = {
			name: 'CreditCardCompanies',
			fields: {
				ccID: "TEXT",
			  	ccName: "TEXT"
			},
			unique: ['ccID']
		};
		super(meta)
	}
}}}}
