module PositiveTS {
  export module Components {
    export module RestaurantItemMenu {
      export function getComponent() {
        let component = {
          template: JST.RestaurantItemMenu(),
          data() {
            return {
              /** @type {Array<Types.RestaurantMenuButton>} } */
              currentMenusAndItemsToDisplay: [],
              /** @type {Array<Types.HorizonalSelectTab>} */
              subMenusTabs: [],
              lastMenuSelected: null,
              lastSubMenuSelected: null,
            };
          },
          async created() {
            let initialMenusToDisplay = await this.LoadMenu();
            this.currentMenusAndItemsToDisplay = initialMenusToDisplay;
            this.open();
          },
          async mounted() {
            this.$el.parentElement.style.margin = '0';
          },
          mixins: [keyPadMixin, Mixins.itemButtonMenuMixin],
          components: {
            'horizontal-select': PositiveTS.Components.HorizontalSelect.getComponent(),
            'horizontal-scroll': PositiveTS.Components.HorizontalScrollContainer.getComponent(),
            'collapse-select': PositiveTS.Components.CollapseSelect.getComponent(),
            'item-menu-item-info': PositiveTS.Components.ItemInfo.getComponent(),
          },
          
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {effectiveMigvanId,totalQuantity,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {effectiveMigvanId,totalQuantity,mobileLayout}

            return {...globalStoreProps}
          },
          computed: {
            mainMenusTabsToDisplay(): Array<Types.HorizonalSelectTab> {
              let arr: Array<Types.HorizonalSelectTab> = []
              let sourceArr: Array<Types.RestaurantMenuButton> = this.currentMenusAndItemsToDisplay;
              for (const menu of sourceArr) {
                arr.push({ name: menu.menuName });
              }
              return arr;
            },
            orderSummaryBtn(): Types.PositiveButtonProps {
              return {
                text: i18next.t('pos.dalpakPage.orderSummary'),
                border: false,
                classes: 'restaurant-btn',
                icon: 'fa-light fa-clipboard-list-check',
                onClick: async () => {
                  PositiveTS.VueInstance.$refs.pos.showOrderSummary = true
                  Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.RestaurantOrderSummary);
                  // if new restaurant POS, scroll to bottom of order items
                  if (document.querySelector(".order-items") && jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)) {
                    document.querySelector(".order-items").scrollTop = document.querySelector(".order-items").scrollHeight
                  }
                }
              }
            },
            exitWithNoPrintBtn(): Types.PositiveButtonProps {
              return {
                text: i18next.t('pos.dalpakPage.exitWithNoPrint'),
                border: false, classes: 'restaurant-btn',
                icon: 'fa-light fa-circle-xmark',
                textClass: 'btn-txt', iconClass: 'btn-icon',
                onClick: () => { PositiveTS.VueInstance.$refs.pos.dalpaksSaveExit(true); }
              };
            },
            goToPaymentBtn(): Types.PositiveButtonProps {
              return {
                text: i18next.t('pos.dalpakPage.goToPayment'),
                border: false, classes: 'restaurant-btn',
                icon: 'fa-light fa-money-bill-1',
                onClick: PositiveTS.VueInstance.$refs.pos.openPaymentScreenSingleButton,
              };
            },
            displaygoToPaymentBtn() {
              return this.totalQuantity > 0;
            },
            hasSubMenus(): boolean {
              let currentMenusAndItemsToDisplay: Array<Types.RestaurantMenuButton> = this.currentMenusAndItemsToDisplay;
              return currentMenusAndItemsToDisplay.some(menu => posUtils.isBlank(menu.subMenus) === false);
            },
            shouldDisplaySubMenusTab(): boolean {
              return this.hasSubMenus && this.subMenusTabs.length > 0
            },
            lastSelectedMenuRef() {
              return this.$refs.mainMenus[this.lastMenuSelected];
            },
            lastSelectedSubMenuRef() {
              // What I did here was not avoidable.
              // This stupid Vue, flattens the refs in a v-for for the sub-menus that are inside v-for for main-menus
              // Not only that, but only, it doesn't even keep them orderly inside the ref array
              return document.querySelector(`#menu${this.lastMenuSelected}-sub${this.lastSubMenuSelected}`);
            },
          },
          watch:{
          async effectiveMigvanId(newVal,oldVal){
                if(newVal != oldVal ){
                  let initialMenusToDisplay = await this.LoadMenu();
                  this.currentMenusAndItemsToDisplay = initialMenusToDisplay;
                  this.open();
                }
            }
        },
          methods: {
            open() {
              this.resetData();
              Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.RestaurantItemMenu);
              if (this.$refs.mainMenus) {
                for (const menu of this.$refs.mainMenus) {
                  menu.$data.isOpen = true;
                }
              }
            },
            resetData() {
              this.subMenusTabs = [];
              this.lastMenuSelected = null;
              this.lastSubMenuSelected = null;
            },
            addItem(item: Types.MenuButton) {
              this.addItemToSale(item);
            },
            setSubMenuId(menuIndex: number, subMenuIndex: number) {
              return `menu${menuIndex}-sub${subMenuIndex}`;
            },
            async menuTabClicked(args: Types.HorizonalTabClickedArgs) {
              this.lastSubMenuSelected = null;
              this.lastMenuSelected = args.index;
              let menu = this.lastSelectedMenuRef;
              menu.toggle(true);
              if (this.hasSubMenus) {
                this.setSubMenusTabs();
              }
              let menuElement: HTMLElement = menu.$el;
              await this.$nextTick();
              menuElement.scrollIntoView({ block: "start", behavior: "smooth" });
            },
            afterMenuOpened(menuIndex: number) {
              this.lastSubMenuSelected = null;
              this.lastMenuSelected = menuIndex;
              if (this.hasSubMenus) {
                this.setSubMenusTabs();
              }
              this.$refs.menuTabSelect.setSelectedManually(menuIndex);
            },
            async subMenuTabClicked($event: Event, subMenu: Types.HorizonalSelectTab, index: number) {
              this.lastSubMenuSelected = index;
              let menu = this.lastSelectedMenuRef;
              menu.$data.isOpen = true; // Make sure to open if it's closed
              let lastSelectedSubMenuEl: HTMLElement = this.lastSelectedSubMenuRef;
              await this.$nextTick();
              lastSelectedSubMenuEl.scrollIntoView({ block: "start", behavior: "smooth" });
            },
            setSubMenusTabs(): Array<Types.HorizonalSelectTab> {
              let arr: Array<Types.HorizonalSelectTab> = []
              if (!this.hasSubMenus || this.lastMenuSelected == null) return this.subMenusTabs = arr;

              let currentMenusAndItemsToDisplay: Array<Types.RestaurantMenuButton> = this.currentMenusAndItemsToDisplay;
              let menu = currentMenusAndItemsToDisplay[this.lastMenuSelected];
              if (!menu || !menu.subMenus || menu.subMenus.length <= 0) return this.subMenusTabs = arr;

              for (const subMenu of menu.subMenus) {
                arr.push({ name: subMenu.menuName });
              }
              return this.subMenusTabs = arr;
            },
            async LoadMenu() {
              let orderByDesc = (a, b) => (posUtils.isBlank(b) === false && a < b ? 1 : -1);
              let orderByAsc = (a, b) => (posUtils.isBlank(b) === false && a < b ? -1 : 1);
              let arr: Array<Types.RestaurantMenuButton> = [];
              let topLvlItemsAndMenus: Array<Types.MenuButton> = await this.getTopLevelMenuItems(this.isRoshemet);
              let mainMenuItems = topLvlItemsAndMenus.filter(btn => btn.isItem).sort(orderByDesc);
              if (mainMenuItems.length > 0) {
                arr.push({ menuName: i18next.t('pos.dalpakPage.mainMenuItems'), items: mainMenuItems });
              }
              let menus = topLvlItemsAndMenus.filter(btn => !btn.isItem && btn.isPrimaryMenuItem).sort(orderByDesc);
              for (const menu of menus) {
                let menuItemsAndSubMenus: Array<Types.MenuButton> = await this.getPrimaryMenuItems(menu);
                let menuItems = menuItemsAndSubMenus.filter(btn => btn.isItem).sort(orderByAsc);
                let restaurantMenu: Types.RestaurantMenuButton = { menuName: menu.name, items: menuItems };
                let subMenus = menuItemsAndSubMenus.filter(btn => !btn.isItem && btn.isSecondaryMenuItem).sort(orderByAsc);
                restaurantMenu.subMenus = [];
                for (const subMenu of subMenus) {
                  let menuItemsAndSubMenus: Array<Types.MenuButton> = await this.getSecondaryMenuItems(subMenu);
                  let subMenuItems = menuItemsAndSubMenus.filter(btn => btn.isItem).sort(orderByAsc);
                  let restaurantSubMenu: Types.RestaurantMenuButton = { menuName: subMenu.name, items: subMenuItems, subMenus: [] };
                  let thirdSubMenus = menuItemsAndSubMenus.filter(btn => !btn.isItem && btn.isThirdMenuItem).sort(orderByAsc);
                  restaurantMenu.subMenus.push(restaurantSubMenu);
                  if (this.mobileLayout == false) { // Don't load items for Desktop
                    continue;
                  }
                  for (const subSubMenu of thirdSubMenus) {
                    let thirdMenuItems: Array<Types.MenuButton> = await this.getThirdMenuItems(subSubMenu);
                    thirdMenuItems = thirdMenuItems.filter(btn => btn.isItem).sort(orderByAsc);
                    let thirdMenu: Types.RestaurantMenuButton = { menuName: subSubMenu.name, items: thirdMenuItems };
                    restaurantSubMenu.subMenus.push(thirdMenu);
                  }
                }
                if (restaurantMenu.items.length <= 0 && // If they're ABSOLUTELY no items on the menu, DON'T ADD IT!
                  (restaurantMenu.subMenus.length <= 0 || restaurantMenu.subMenus.every(
                    menu2ndLvl => menu2ndLvl.items.length <= 0 && (menu2ndLvl.subMenus.length <= 0 || menu2ndLvl.subMenus.every(
                      menu3rdLvl => menu3rdLvl.items.length <= 0))))) {
                  continue;
                }
                arr.unshift(restaurantMenu);
              }
              return arr;
            },
            isLastItem(menuItemIndex: number, menuLength: number): boolean {
              return menuLength == (menuItemIndex + 1)
            }
          }
        }
        return component;
      }
    }
  }
}