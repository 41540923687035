module PositiveTS {
  export module Components {
    export module FlightsCloseLegWizardDialog {

      const _dialogSelector = "#positive-flights-close-leg-wizard-dialog";
      const _vueComponentName = 'flights-close-leg-wizard-dialog';

      export function create() {
        let flightOnlineloginDialog = {
          template: JST.flightsCloseLegWizardDialog(),
          mixins: [Mixins.modalsMixin],
          components: {
            FlightsNumberOfPassengers: FlightsNumberOfPassengers.getComponent(),
            FlightChangeTeam: FlightsChangeTeam.getComponent(),
            FlightsBarsetCount: FlightBarsetCount.getComponent(),
            FlightsPrintBarsetCountReport: FlightsPrintBarsetCountReport.getComponent(),
            FlightsPrintFlightLegSumReport: FlightsPrintFlightLegSumReport.getComponent(),
            FlightsCashierStatement: FlightsCashierStatement.getComponent(),
          },
          methods: {
            async open() {
              let currentSale = await appDB.sales.where('invoiceSequence').equals(-1).first();

              if (currentSale && currentSale.items && currentSale.items.length) {
                this.showError(i18next.t('flightsWizard.thereIsOpenSale'));
                return;
              }

              if ((await Service.HoldSale.salesInHoldCollection()).length > 0) {
                this.showError(i18next.t('flightsWizard.thereIsHoldSale'));
                return;
              }

              this.$el.show();
              this.step = 0;
            },
            close() {
              this.$el.close();
            },
            goNext() {
              this.step++;
            },
            goBack() {
              this.step--;
            },
            finishWizard() {
              this.showAlertWithTitleGeneric(i18next.t('flightsWizard.commitChanges'),
                i18next.t('flightsWizard.closeLegCompleted'),
                this.applyAndCloseWizard,
                this.goBack,
                false,
                i18next.t('flightsWizard.approve'),
                i18next.t('flightsWizard.goBack'));
            },
            async applyAndCloseWizard() {
              app.showLoadingMessage(i18next.t("loading"));

              try {
                await this.closeLeg(this.allWizardData);
                app.hideLoadingMessage();
              } catch (err) {
                console.error(err);
                app.hideLoadingMessage();
                this.showGenericError();
                this.goBack();
                return;
              }

              this.clearWizard();
              this.close();

              // If finish flight
              if (!this.nextUnopenedLeg && this.stateIsOnline) {
                Service.CloseFlight.CloseFlightAndZReport();
              }
            },
            clearWizard() {
              this.step = 0;
              this.wizardStepsData = [];
            },
            getDefaultWizardData() {
              return { selectedLeg: this.currentLeg };
            },
          },
          computed: {
            allWizardData() {
              return this.wizardStepsData.reduce((allData, currentData) => Object.assign(allData, currentData), this.getDefaultWizardData());
            },
            currentStepsComponets() {
              let currentComponents = ['FlightsNumberOfPassengers', 'FlightChangeTeam'];

              if (this.allWizardData.isTeamChange) {
                currentComponents = currentComponents.concat([
                  'FlightsBarsetCount',
                  'FlightsPrintBarsetCountReport',
                  'FlightsCashierStatement',
                  'FlightsPrintFlightLegSumReport'
                ])
              }

              return currentComponents;
            },
            stateIsOnline(){
              return Pinia.globalStore.isOnline
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {nextUnopenedLeg,currentLeg,isLastLeg} = Pinia.storeToRefs(flightsStore);


            const {closeLeg} = flightsStore;


            return {nextUnopenedLeg,currentLeg,isLastLeg,closeLeg}
          },
          data() {
            return {
              step: -1,
              wizardStepsData: [],
            };
          },
          watch: {
            step() {
              if (this.step < 0) {
                this.step = 0;
                this.close();
              }

              if (this.step >= this.currentStepsComponets.length) {
                this.finishWizard();
              }
            }
          },
        }
        VueApp.component(_vueComponentName, flightOnlineloginDialog)
      }
    }
  }
}