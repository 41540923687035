module PositiveTS {
  export module Service {
    export module PrimaryZReport {

      const i18n = {
        MISSIN_TRAN_FILES_OF_SECONDARY_REPORTS: "שגיאה קריטית חסרים דוחות שבא עבור קופות משניות.",
        CREATING_PRIMARY_Z_REPORT: "מייצר דוח זד מאוחד.",
        ENSURE_ALL_ZDS_TRANSMITTED: "מוודא שכל דוחות הזד מכל הקופות שודרו לשרת. אנא המתן."
      }



      class PrimaryZResponse {
        is_valid = true;
        grouped_tran_base64 = "";
        grouped_sales_count = 0;
        grouped_payment_count = 0;
        grouped_credit_sales = 0;
        grouped_debit_sales = 0;
        grouped_total_items = 0
        grouped_average_item_price = 0
        grouped_average_qty_per_customer = 0
        grouped_sales_above_5k = 0
        grouped_total_hakafa_debt_payments = 0
        grouped_total_receipts = 0
        grouped_total_credit_qty = 0
        grouped_total_credit_amount = 0
        grouped_total_return_qty = 0
        grouped_total_return_amount = 0
        grouped_total_discounts_amount = 0
        grouped_average_sale = 0
        grouped_credit_card_summary = "{}"
        zStatement = 0;
        invoices_list = [];
        shipment_and_tax_invs = [];
        withdrawalInvs = [];
        payments = [];
        vouchers = {};
        list_zNumber = [];
        total_vat = 0;
        itemDetails = [];
        tenbisTotalAmount = 0
        mishlohaTotalAmount = 0
        mishlohaCashTotalAmount=0
        mishlohaCashCount = 0
        cibusTotalAmount  = 0
        goodiTotalAmount  = 0
        dtsTotalAmount  = 0
        yaadTotalAmount  = 0
        tamashTotalAmount = 0
        totalHakafaTamash = 0
        totalHakafa = 0
        tamashCount = 0
        hakafaCount = 0
        cibusCount = 0
        tenbisCount = 0
        mishlohaCount = 0
        roundedAmount = 0
        roundedCount = 0
        goodiCount = 0
        dtsCount = 0
        yaadCount = 0
        paiditTotalAmount = 0
        paiditCount = 0
        cashMovedToSafe = 0
        enterStatement = 0
        grandTotal = 0
        withdrawnCash = 0
        tipsCount = 0
        tipsAmount = 0
        withdrawnCount = 0
        canceledBonCount = 0
        canceledBonSum = 0
        deliveriesCount = 0
        deliveriesSum = 0
        withoutTransmit = false
        externalOrdersAmount = 0
        totalSittingDiners = 0
        totalDeliveriesDiners = 0
        totalSittingDinersSalesAmount = 0
        totalDeliveriesDinersSalesAmount = 0
        deliveryApiTotals = {}
        salesDiscountReport = {}
        currenciesSummary = {}
      }


      function getPrimaryZDetailsFromServer() {
        return Ajax.promiseJqAjax('/primary_z_reports/detail?move_files=true',{},"GET");
      }

      function parsePrimaryZDetailsFromServer(response) {
        var returned_dict = JSON.parse(response.request.result);

        let error
        if ( !returned_dict.is_valid ) {
          console.error(returned_dict);
          if(returned_dict.error_id == 'NONE_TRANSMITTED_SECONDARY') {
            error = new ErrorWithData('noneTransmitedSecondaryPoss', returned_dict.error_data) 
          } else {
            error = new Error(i18n[returned_dict.error_id])
          }
          throw error;
        }

        let priZResponse = new PrimaryZResponse();
        priZResponse.is_valid = returned_dict.is_valid;
        priZResponse.grouped_tran_base64 = returned_dict.grouped_tran_base64;
        priZResponse.grouped_sales_count = returned_dict.grouped_sales_count;
        priZResponse.grouped_payment_count = returned_dict.grouped_payment_count;
        priZResponse.grouped_credit_sales = returned_dict.grouped_credit_sales;
        priZResponse.grouped_debit_sales = returned_dict.grouped_debit_sales;
        priZResponse.list_zNumber = returned_dict.list_zNumber;
        priZResponse.invoices_list = returned_dict.grouped_data.invoices_list;
        priZResponse.shipment_and_tax_invs = returned_dict.grouped_data.shipment_and_tax_invs;
        priZResponse.withdrawalInvs = returned_dict.grouped_data.withdrawalInvs;
        priZResponse.total_vat = returned_dict.grouped_data.total_vat;
        priZResponse.grouped_total_items = returned_dict.grouped_total_items;
        priZResponse.grouped_average_item_price = returned_dict.grouped_average_item_price;
        priZResponse.grouped_average_qty_per_customer = returned_dict.grouped_average_qty_per_customer;
        priZResponse.itemDetails = returned_dict.itemDetails;
        priZResponse.grouped_sales_above_5k = returned_dict.grouped_sales_above_5k;
        priZResponse.grouped_total_hakafa_debt_payments = returned_dict.grouped_total_hakafa_debt_payments;
        priZResponse.grouped_total_receipts = returned_dict.grouped_total_receipts;
        priZResponse.grouped_total_credit_qty        = returned_dict.grouped_total_credit_qty;
        priZResponse.grouped_total_credit_amount     = returned_dict.grouped_total_credit_amount;
        priZResponse.grouped_total_return_qty        = returned_dict.grouped_total_return_qty;
        priZResponse.grouped_total_return_amount     = returned_dict.grouped_total_return_amount;
        priZResponse.grouped_total_discounts_amount  = returned_dict.grouped_total_discounts_amount;
        priZResponse.grouped_credit_card_summary     = returned_dict.grouped_credit_card_summary;
        priZResponse.grouped_average_sale = returned_dict.grouped_average_sale;

        priZResponse.tenbisTotalAmount = returned_dict.tenbis_total_amount
        priZResponse.mishlohaTotalAmount = returned_dict.mishloha_total_amount
        priZResponse.mishlohaCashTotalAmount = returned_dict.mishloha_cash_total_amount
        priZResponse.mishlohaCashCount = returned_dict.mishloha_cash_count
        priZResponse.cibusTotalAmount = returned_dict.cibus_total_amount
        priZResponse.goodiTotalAmount = returned_dict.goodi_total_amount
        priZResponse.dtsTotalAmount = returned_dict.dts_total_amount
        priZResponse.yaadTotalAmount = returned_dict.yaad_total_amount
        priZResponse.paiditTotalAmount = returned_dict.paidit_total_amount
        priZResponse.tamashTotalAmount = returned_dict.tamash_total_amount

        priZResponse.totalHakafaTamash = returned_dict.total_hakafa_tamash
        priZResponse.totalHakafa = returned_dict.total_hakafa
        priZResponse.tamashCount = returned_dict.tamash_count
        priZResponse.hakafaCount = returned_dict.hakafa_count
        priZResponse.cibusCount = returned_dict.cibus_count
        priZResponse.tenbisCount = returned_dict.tenbis_count
        priZResponse.mishlohaCount = returned_dict.mishloha_count
        priZResponse.goodiCount = returned_dict.goodi_count
        priZResponse.yaadCount = returned_dict.yaad_count
        priZResponse.paiditCount = returned_dict.paidit_count
        priZResponse.deliveryApiTotals = JSON.stringify(returned_dict.delivery_api_totals || {})
        priZResponse.currenciesSummary = returned_dict.currenciesSummary;

        priZResponse.salesDiscountReport = returned_dict.sales_discount_report || {}
        priZResponse.zStatement = returned_dict.zStatement;
        
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.enterStatement)) {
          priZResponse.enterStatement = returned_dict.enterStatement;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.cashMovedToSafe)) {
          priZResponse.cashMovedToSafe = returned_dict.cashMovedToSafe;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.roundedAmount)) {
          priZResponse.roundedAmount = session.fixedFloat(returned_dict.roundedAmount, 2)
          priZResponse.roundedCount = returned_dict.roundedCount
        } else {
          priZResponse.roundedAmount = 0;
          priZResponse.roundedCount = 0;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.withdrawnCash)) {
          priZResponse.withdrawnCash = returned_dict.withdrawnCash;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.tipsCount)) {
          priZResponse.tipsCount = returned_dict.tipsCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.tipsAmount)) {
          priZResponse.tipsAmount = returned_dict.tipsAmount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.withoutTransmit)) {
          priZResponse.withoutTransmit = returned_dict.withoutTransmit;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.withdrawnCount)) {
          priZResponse.withdrawnCount = returned_dict.withdrawnCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.externalOrdersAmount)) {
          priZResponse.externalOrdersAmount = returned_dict.externalOrdersAmount;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.canceledBonCount)) {
          priZResponse.canceledBonCount = returned_dict.canceledBonCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.canceledBonSum)) {
          priZResponse.canceledBonSum = returned_dict.canceledBonSum;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.deliveriesCount)) {
          priZResponse.deliveriesCount = returned_dict.deliveriesCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.deliveriesSum)) {
          priZResponse.deliveriesSum = returned_dict.deliveriesSum;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalSittingDiners)) {
          priZResponse.totalSittingDiners = returned_dict.totalSittingDiners;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalDeliveriesDiners)) {
          priZResponse.totalDeliveriesDiners = returned_dict.totalDeliveriesDiners;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalSittingDinersSalesAmount)) {
          priZResponse.totalSittingDinersSalesAmount = returned_dict.totalSittingDinersSalesAmount;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalDeliveriesDinersSalesAmount)) {
          priZResponse.totalDeliveriesDinersSalesAmount = returned_dict.totalDeliveriesDinersSalesAmount;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalSittingDiners)) {
          priZResponse.totalSittingDiners = returned_dict.totalSittingDiners;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalDeliveriesDiners)) {
          priZResponse.totalDeliveriesDiners = returned_dict.totalDeliveriesDiners;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalSittingDinersSalesAmount)) {
          priZResponse.totalSittingDinersSalesAmount = returned_dict.totalSittingDinersSalesAmount;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(returned_dict.totalDeliveriesDinersSalesAmount)) {
          priZResponse.totalDeliveriesDinersSalesAmount = returned_dict.totalDeliveriesDinersSalesAmount;
        }
        
        
        priZResponse.grandTotal = returned_dict.grandTotal || 0


        let salesPayments = paymentsArrayToSalePaymentsObjArray(returned_dict.grouped_data.payments,
          returned_dict.grouped_data.vouchers);

        return PositiveTS.Helper.ReportsHelper.calculateZReport(salesPayments)
        .then(function (report:any) {
          priZResponse.payments = report.payments;

          priZResponse.vouchers = getCalculatedVoucherDict( returned_dict.grouped_data.vouchers );
          return priZResponse;
        });
      }

      function getCalculatedVoucherDict(returned_dict_grouped_data_vouchers) {
        var voucherDict = {};
        var row
        for (var i=0; i<returned_dict_grouped_data_vouchers.length; i ++ ) {
          row = returned_dict_grouped_data_vouchers[i];
          if (Object.keys(voucherDict).indexOf(row.voucher_type_id) === -1) {
            voucherDict[row.voucher_type_id] = row.amount;
          } else {
            voucherDict[row.voucher_type_id] += row.amount;
          }
        }
        return voucherDict;
      }

      function paymentsArrayToSalePaymentsObjArray(payments, vouchers) {
        var aryObjSalePayments = [];
        var p;
        var isVoucherDataAlreadyCalculated = false;

        for (var paymentCounter = 0; paymentCounter < payments.length; paymentCounter++) {
          p = new PositiveTS.Storage.Entity.SalePayment();
          p._data = payments[paymentCounter];
          if (payments[paymentCounter].count != null) {
            p.countForPriZ = payments[paymentCounter].count;
          }
          else {
            p.countForPriZ = 0;
          }
          

          p.method  = parseInt(p._data.payment_type_id);
          if (
            ( p.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER ||
              p.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT )) {
            if (!isVoucherDataAlreadyCalculated) {
              isVoucherDataAlreadyCalculated = true;

              var salePaymentdata = [];
              for (var i=0; i<vouchers.length; i++){
                salePaymentdata.push({voucher_type_id: vouchers[i].voucher_type_id ,
                  amount: vouchers[i].amount});
              }
              let totalVoucherAmount = payments.filter(pay => pay["payment_type_id"] == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER)
                                       .map(pd => Number(pd["amount"] || 0)).reduce((a,b) => a+b,0)
              p._data.amount = totalVoucherAmount;
              p.data = JSON.stringify(salePaymentdata);
              aryObjSalePayments.push(p);
            }
          }
          else {
            aryObjSalePayments.push(p);
          }

        }
        return aryObjSalePayments;
      }

      async function composeObjPrimaryZResponse() {
        let response = await getPrimaryZDetailsFromServer();
        return parsePrimaryZDetailsFromServer(response);
      };

      function composeZObjetFromPrimaryZResponse(response:PrimaryZResponse){
        let zReport = Helper.ReportsHelper.createNewZReportObjectFromSession();

        if (response.invoices_list.length === 0 &&
        (response.tenbisTotalAmount + response.cibusTotalAmount + response.goodiTotalAmount + response.dtsTotalAmount + response.yaadTotalAmount + response.tamashTotalAmount + response.mishlohaTotalAmount) === 0) {
          throw new Error('noSalesError');
        }



        zReport.invoicesList = JSON.stringify( response.invoices_list );
        zReport.shipmentAndTaxInvs = JSON.stringify(response.shipment_and_tax_invs || []);
        zReport.withdrawalInvs = JSON.stringify(response.withdrawalInvs || []);
        zReport.isPrimary = true;
        zReport.payments = JSON.stringify( response.payments );
        zReport.slaveZReports = JSON.stringify( response.list_zNumber );
        zReport.syncStatus = PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_WAITING_TO_BE_SENT;
        zReport.totalPayments = response.grouped_payment_count;
        zReport.totalSales = response.grouped_sales_count;
        zReport.creditSales = response.grouped_credit_sales;
        zReport.debitSales = response.grouped_debit_sales;
        zReport.totalVat = response.total_vat;
        zReport.averageSale = response.grouped_average_sale;
        zReport.averageItemPrice = response.grouped_average_item_price;
        zReport.salesAbove5k = response.grouped_sales_above_5k;
        zReport.totalHakafaDebtPayments = response.grouped_total_hakafa_debt_payments;
        zReport.totalReceipts         = response.grouped_total_receipts;
        zReport.totalCreditQty        = response.grouped_total_credit_qty;
        zReport.totalCreditAmount     = response.grouped_total_credit_amount;
        zReport.totalReturnQty        = response.grouped_total_return_qty;
        zReport.totalReturnAmount     = response.grouped_total_return_amount;
        zReport.totalDiscountsAmount  = response.grouped_total_discounts_amount;
        zReport.creditCardSummary     = JSON.stringify(response.grouped_credit_card_summary);
        zReport.totalItems = response.grouped_total_items;
        zReport.averageQtyPerCustomer = response.grouped_average_qty_per_customer;
        zReport.vouchers = JSON.stringify( response.vouchers );
        zReport.tenbisTotalAmount = response.tenbisTotalAmount;
        zReport.mishlohaTotalAmount = response.mishlohaTotalAmount;
        zReport.mishlohaCashTotalAmount = response.mishlohaCashTotalAmount;
        zReport.mishlohaCashCount = response.mishlohaCashCount;
        zReport.cibusTotalAmount  = response.cibusTotalAmount;
        zReport.goodiTotalAmount  = response.goodiTotalAmount;
        zReport.dtsTotalAmount  = response.dtsTotalAmount;
        zReport.yaadTotalAmount  = response.yaadTotalAmount;
        zReport.tamashTotalAmount = response.tamashTotalAmount;
        zReport.totalHakafaTamash = response.totalHakafaTamash;
        zReport.totalHakafa = response.totalHakafa;
        zReport.tamashCount = response.tamashCount;
        zReport.hakafaCount = response.hakafaCount;
        zReport.cibusCount = response.cibusCount;
        zReport.tenbisCount = response.tenbisCount;
        zReport.mishlohaCount = response.mishlohaCount;
        zReport.goodiCount = response.goodiCount;
        zReport.dtsCount = response.dtsCount;
        zReport.yaadCount = response.yaadCount;
        zReport.paiditTotalAmount = response.paiditTotalAmount;
        zReport.paiditCount = response.paiditCount;
        zReport.grandTotal = response.grandTotal;
        zReport.itemDetails = JSON.stringify(response.itemDetails);
        zReport.deliveryApiTotals = response.deliveryApiTotals || {};
        zReport.salesDiscountReport = JSON.stringify(response.salesDiscountReport)
        zReport.zStatement = response.zStatement;

        if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners)) {
          zReport.totalSittingDiners = response.totalSittingDiners;
          zReport.totalDeliveriesDiners = response.totalDeliveriesDiners;
          zReport.totalSittingDinersSalesAmount = response.totalSittingDinersSalesAmount;
          zReport.totalDeliveriesDinersSalesAmount = response.totalDeliveriesDinersSalesAmount;
        }

        if (jsonConfig.getVal(jsonConfig.KEYS.isMultiCurr)) {
          zReport.currenciesSummary = response.currenciesSummary;
        }

        if(!posUtils.isNullOrUndefinedOrEmptyString(response.enterStatement)) {
          zReport.enterStatement = response.enterStatement;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.cashMovedToSafe)) {
          zReport.cashMovedToSafe = response.cashMovedToSafe;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.roundedAmount)) {
          zReport.roundedAmount = response.roundedAmount;
          zReport.roundedCount = response.roundedCount;
        } else {
          zReport.roundedAmount = 0;
          zReport.roundedCount = 0;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.withdrawnCash)) {
          zReport.withdrawnCash = response.withdrawnCash;
        }
        if(!posUtils.isBlank(response.tipsCount)) {
          zReport.tipsCount = response.tipsCount;
        }
        if(!posUtils.isBlank(response.tipsAmount)) {
          zReport.tipsAmount = response.tipsAmount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.externalOrdersAmount)) {
          zReport.externalOrdersAmount = response.externalOrdersAmount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.withoutTransmit)) {
          zReport.withoutTransmit = response.withoutTransmit;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.withdrawnCount)) {
          zReport.withdrawnCount = response.withdrawnCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.canceledBonCount)) {
          zReport.canceledBonCount = response.canceledBonCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.canceledBonSum)) {
          zReport.canceledBonSum = response.canceledBonSum;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.deliveriesCount)) {
          zReport.deliveriesCount = response.deliveriesCount;
        }
        if(!posUtils.isNullOrUndefinedOrEmptyString(response.deliveriesSum)) {
          zReport.deliveriesSum = response.deliveriesSum;
        }

        
        return zReport;
      };

      async function createNewPrimaryZReport():Promise<Storage.Entity.ZReport> {
        let zReport:Storage.Entity.ZReport;
        let objPrimaryZResponse = await composeObjPrimaryZResponse()
        zReport = await composeZObjetFromPrimaryZResponse(objPrimaryZResponse);
        await zReportVC.getPrimaryCategoryStats(zReport);
        await zReport.closePrimaryReportAndTransmit();
      
        return zReport;
      }

      export async function createPrimaryZ(): Promise<Storage.Entity.ZReport> {
        app.showLoadingMessage(i18n.CREATING_PRIMARY_Z_REPORT);
        try {
          return await createNewPrimaryZReport();
        } finally {
          app.hideLoadingMessage();
        }
      }

    }
  }
}
