module WebSocketRails {
/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
WebSocket Interface for the WebSocketRails client.
*/

  export class WebSocketConnection extends AbstractConnection {
    connection_type:string

    constructor(url, dispatcher) {
      super(url,dispatcher)
      this.connection_type = 'websocket'
      if (this.url.match(/^wss?:\/\//)) {
          console.log("WARNING: Using connection urls with protocol specified is depricated");
      } else if (window.location.protocol === 'https:') {
          this.url             = `wss://${this.url}`;
      } else {
          this.url             = `ws://${this.url}`;
        }
        if(this.url.includes('localhost')){
          this.url = this.url.replace(/(8\d{3})/g,'3000')
        }
        
      this._conn           = new WebSocket(this.url);
      this._conn.onmessage = event => { 
        const event_data = JSON.parse(event.data);
        return this.on_message(event_data);
      };
      this._conn.onclose   = event => { 
        return this.on_close(event);
      };
      this._conn.onerror   = event => { 
        return this.on_error(event);
      };
    }

    close() {
      return this._conn.close();
    }

    send_event(event) {
      super.send_event(...arguments);
      return this._conn.send(event.serialize());
    }
  }
}

