module PositiveTS {
	export module Service {
		export module ZReport {

			export async function sendReports(zReports) {
				//I'm stringifying the JSON to preserve backwards compatibility with the old API
				//I tried to change the server side, but the returned JSON is different and causes compatibility issues
				let response = await Service.FetchReq.jsonReq('/z_reports','post', { data: JSON.stringify(zReports)})
				return response.result;
			}

			export function getReports(posDeviceID) {
				return new Promise((resolve, reject) => {
					if (posUtils.isNullOrUndefined(posDeviceID)) {
						return reject('posDeviceID undefined')
					}

					$.ajax({
						type: "GET",
						url: Shared.Constants.remoteRoot + 'z_reports',
						data: {
							'pos_device_id': posDeviceID
						},
						dataType: 'json',
						success: function (returnedData) {
							resolve(returnedData);
						}
					}).error(function (err) {
						reject(err);
					});
				})
			}
			export async function getLastPosZnumberRow(){
				try{
					let lastPosZreportSequence;
					
					if(serviceZMasterSlave.isZMasterSlave && serviceZMasterSlave.role == serviceZMasterSlave.ROLES.master){
						lastPosZreportSequence  = await appDB.sequences.where('type').equals(PositiveTS.Storage.Entity.Sequence.TYPE_Z_MASTER_REPORT).first();
					}else{
						lastPosZreportSequence  = await appDB.sequences.where('type').equals(PositiveTS.Storage.Entity.Sequence.TYPE_Z_REPORT).first();
					}
					
					if(!lastPosZreportSequence){
						throw new Error(i18next.t('zReport.lastPosSequenceNotFound'))
					}
					return lastPosZreportSequence;
					
				}catch(err){
					console.error(err)
					return Promise.reject(err);
				}
			}

			export async function makeZReport(resolve, withPrompt = true, forceWithoutPinpad = !session.pos.isEmv) {

				if (jsonConfig.getVal(jsonConfig.KEYS.prohibitZWhenSalesOnHold)) {
					let areSalesOnHold = await Service.HoldSale.hasSalesOnHold();
					if (areSalesOnHold) {
						await app.promiseShowAlert({
							header: i18next.t('cashierStatement.zReportTitle'),
							content: i18next.t('zReport.salesOnHoldError'),
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true
						});
						resolve(false);
						return;
					}
				}

				let sale = await appDB.sales.where('syncStatus').equals(PositiveTS.Storage.Entity.Sale.SYNC_STATUS_NEW).first()
				if (sale && sale.payments && sale.payments.length > 0) {
					await app.promiseShowAlert({
						header: i18next.t('cashierStatement.zReportTitle'),
						content: i18next.t('zReport.newSaleHasPaymentsError'),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					})
					resolve(false)
					return
				}

				if (Service.CashierStatement.forceCashDeclarationsAtEndOfDay()) {
					if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
						Pinia.componentsStore.openComponent( {componentName:"CashierStatementMultiCurrencies", args: [Shared.Constants.CashierStatement.TYPE_END_DAY]});
					} else {
						Pinia.globalStore.setCashierStatementType(Shared.Constants.CashierStatement.TYPE_END_DAY);
						Pinia.componentsStore.openComponent( {componentName:"CashierStatement", args: []});
					}
				} else {
					let res = withPrompt ? await app.promiseShowAlert({
						header: i18next.t('cashierStatement.zReportTitle'),
						content: i18next.t("zReport.areYouSure"),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: false
					}) : "continue";

					if (res == "continue") {
						if(session.pos.isVendingMachinePos){
							app.showAlertDialog({
								content: i18next.t("vendingMachine.zReportInProgress"),
								hideContinueButton: true,
								hideCancelButton: true,
							})
							Pinia.globalStore.setIsLoadingMessageDisabled(true);
						}else{
							pNavigator.pushPage('zreport', i18next.t('homepage.zreport'), '/homepage', null);
						}
						resolve(await zReportVC.createNewZReportMain(forceWithoutPinpad));

						return;
					}
				}

				resolve(false);
			}

			export async function openZReport(withPrompt = true, forceWithoutPinpad = !session.pos.isEmv, remote = false, remoteData?: Service.ZReportAll.remoteOptions) {

				return new Promise(async (resolve, reject) => {
					if (!remote) {
						posVC.RedirectIfAuthorized(session.pos.parameterManagerApprovalZXReport, async () => {
							Service.ZReportAll.setParams();
							if ((Service.ZReportAll.isRemoteZEnabledWithoutPrimary && !remote)
							|| (!remote && Service.ZReportAll.isRemoteZEnabledWithPrimary)) {
								let res = await app.promiseShowAlert({
									header: i18next.t('zReport.doAllZBigTitle'),
									content: i18next.t("zReport.doAllZTitle"),
									continueButtonText: i18next.t("ok"),
									hideCancelButton: false
								});
								if (res == "continue") {
									await Service.ZReportAll.start()
								}
							}
							makeZReport(resolve, withPrompt, forceWithoutPinpad)
						}, [Storage.Entity.Employee.CAN_RUN_Z_REPORT])
					} else {
						Service.ZReportAll.remoteOptions = remoteData
						makeZReport(resolve, withPrompt, forceWithoutPinpad)
					}

				});
			}
		}
	}
}
