module PositiveTS {
    export module Components {
        export module CaveretPasswordDialog {
            export function create() {

                const _vueComponentName = 'caveret-password-dialog';
                const dialogSelector = 'caveret-password-dialog'
                let dialog = {
                    template: JST.caveretPasswordDialog(),
                    methods: {
                        open() {
                            return new Promise((resolve, reject) => {
                                if (this.dialogElem == null) {
                                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                                }
                                this.dialogElem.showModal();
                                this.resolveFunc = resolve;
                                this.rejectFunc = reject;
                                
                            })
                        },

                        close(cancel?) {
                            this.dialogElem.close();
                            if (cancel) {
                                this.rejectFunc()
                            }
                            this.cleanData()

                        },

                        async confirmPassword() {
                            app.showLoadingMessage(i18next.t("caveretPasswordDialog.loggingIn"));
                            let response = await PositiveTS.Service.CaveretEmployeeApproval.verifyPassword(this.taz, this.magneticCard, this.password)
                            app.hideLoadingMessage();

                            if (response.success) {
                                this.resolveFunc(response.success)
                                this.close()
                            } else {
                                await app.showAlertDialog({
                                    header: i18next.t('error'),
                                    content: i18next.t('caveretPasswordDialog.noPermission'),
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                })
                            }

                        },

                        cleanData() {
                            this.resolveFunc = null;
                            this.rejectFunc = null;
                            this.taz = null;
                            this.magneticCard = null;
                            this.password = null;
                            this.showKeyboard = false;
                        },
                        async toggleKeyboard() {
                            this.showKeyboard = !this.showKeyboard

                            if (this.showKeyboard) {
                                await this.$nextTick();
                                
                                this.$refs.SimpleKeyboardInstance.setNewInput(this.$refs.password)
                                if(this.isSimpleSelfService) {
                                    this.$refs.SimpleKeyboardInstance.setLayout(this.selfServicelayout)
                                    this.$refs.SimpleKeyboardInstance.setDisplay(this.display)
                                    this.$refs.SimpleKeyboardInstance.setCustomFunction(this.confirmPassword)
                                }
                            }
                        },
                        updateVal(value) {
                            this.password = value
                        },

                    },
                    data() {
                        return {
                            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
                            resolveFunc: null,
                            rejectFunc: null,
                            taz: null,
                            magneticCard: null,
                            password: null,
                            showKeyboard: false,
                            selfServicelayout: {  default: [
                                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                                "/ ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 \\",
                                "\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3",
                                "\u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . {shift}",
                                "{continue} {space}"
                              ],
                              shift: [
                                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                                "Q W E R T Y U I O P { } {caps-fill}",
                                'A S D F G H J K L : "',
                                "Z X C V B N M < > ? {shift}",
                                "{continue} {space}"
                              ],
                              caps: [
                                "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                                "q w e r t y u i o p { } {caps}",
                                'a s d f g h j k l : "',
                                "z x c v b b m < > ? {shift}",
                                "{continue} {space}"
                              ],
                            },
                              display: {
                                '{continue}': i18next.t('posPayment.confirm'),
                                '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
                                '{shift}': '<i class="far fa-globe"></i>',
                                '{caps}': '<i class="fa-regular fa-up"></i>',
                                '{caps-fill}': '<i class="fa-solid fa-up"></i>'
                              }
                        }
                    },
                    computed:{
                        simpleSelfService(){
                            return Pinia.globalStore.simpleSelfService
                        }
                    },
                }

                VueApp.component(_vueComponentName, dialog)
            }






        }
    }
}