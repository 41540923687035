module PositiveTS {
    export module Components {
        export module ItemInfo {
            export function getComponent() {
                let component = {
                    template: JST.ItemInfo(),
                    mixins: [],
                    props: {
                        item: {
                            /** @type {PositiveTS.Types.MenuButton} */
                            type: [PositiveTS.Storage.Entity.ItemMenuItem, Object],
                            required: true,
                        },
                        noBottomBorder: {
                            type: Boolean,
                            required: false,
                            default: false,
                        },
                    },
                    data() { return {} },
                    beforeMount() {

                    },
                    setup(){
                        const globalStore = Pinia.useGlobalStore();
                        const {countItemsInSaleItems,defaultImages,usePictures} = Pinia.storeToRefs(globalStore);
                        const globalStoreProps = {countItemsInSaleItems,defaultImages,usePictures}
          
                        return {...globalStoreProps}
                    },
                    computed: {
                        itemsInOrderCount(){
                            return this.countItemsInSaleItems(this.item?.code) ?? 0;
                        },
                        posCurrency(): string {
                            return Service.MultiCurr.getInstance().getPosCurrencySign();
                        },
                        priceTag(): string {
                            return `${this.item.price} ${this.posCurrency}`;
                        },
                        noDesc(): boolean {
                            return posUtils.isBlank(this.item.details);
                        },
                        hasDesc(): boolean {
                            return !this.noDesc;
                        },
                        hasImg() {
                            return Boolean(this.usePictures && this.item.hasPicture);
                        },
                        noImg() {
                            return !this.usePictures || !this.hasImg;
                        },
                        srcImg() {
                            if (this.usePictures == false) {
                                return '';
                            }
                            let item: PositiveTS.Types.MenuButton = this.item;
                            if (item.pictureUrl) {
                                return item.pictureUrl;
                            } else if (item.hasPicture) {
                                return `/catalog-images/items/${session.pos.tenantID}/${session.pos.companyID}/${this.item.code}`;
                            } else {
                                return this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM];
                            }
                        },
                        conditionalCss() {
                            return {
                                'no-desc': this.noDesc,
                                'no-img': this.noImg,
                            };
                        },
                    },
                    methods: {
                        onclick($event) {
                            this.$emit('click', $event);
                        }
                    }
                }
                return component;
            }
        }
    }
}

