module PositiveTS {
  export module Service {
    export module StrUtils {
      export function lpad(n, width, z?) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      };
      
      export function splitByNewLine(str){
        return str.match(/[^\r\n]+/g);
      };
      
      export function right (n, width) {
        if (width <= n.length) {
          return n.substring(n.length - width);
        } else {
          return n;
        }
      };
      
      export function lpadRight (n, width, z?) {
        return this.right( this.lpad(n, width, z),  width);
      };
      
      export function rpad (n, width, c) {
        if(n.length > width) {
          return n
        }
      
        return n + Array(width - n.length + 1).join(c || " ");
      };
      
      export function lpadLeft (n, width, z) {
        return this.right(
                          this.lpad(n, width, z),
                          width);
      };
      
      export function dateToDdmmyyyySlashed(d){
        var day = this.lpad(d.getUTCDate(),2);
        var month = this.lpad((d.getUTCMonth() + 1).toString(),2);
        var year = d.getUTCFullYear().toString();
        return day + "/" + month + "/" + year;
      };
      
      export function dateToDdmmyySlashed(d){
        var day = this.lpad(d.getDate(),2);
        var month = this.lpad((d.getMonth() + 1).toString(),2);
        var year = d.getFullYear().toString();
        return day + "/" + month + "/" + this.right(year, 2);
      };
      
      export function getHosturl() {
        var arr = window.location.href.split("/");
        var result = arr[0] + "//" + arr[2];
        return result;
      };
      
      export function cleanCC(credit_card_number){
        return credit_card_number.replace(/^[0]*/,"");
      };
      
      export function hashCC(credit_card_number){
        var _cc = this.cleanCC( credit_card_number );
        return Array( _cc.length - 3 ).join("*") + _cc.substring(_cc.length -4);
      };
      
      export function praseFormattedFloat(s){
        return parseFloat( s.replace(/,/g,"") );
      };
      
      
      export function extractYFromRequest(_request) {
        var _ret = ""
        if ( _request.indexOf('Y') > -1) {
          _ret = _request.substring(_request.indexOf('Y') + 1 , _request.search(/[X]/) );
        }
        return _ret;
      };
      
      export function extractAmountfromRequest(_request) {
        return _request.substring(_request.indexOf('C') + 1 , _request.search(/[D-Z]/) );
      };
      
      export function extractCCfromRequest(_request) {
        var _return = _request.substr(_request.indexOf('A') + 1, _request.search(/[B-Z]/) - 1);
        // if not card scan, it must be manual scan
        if (!_return) {
          _return = _request.substr(_request.indexOf('B') + 1, _request.search(/[C-Z]/) - 1);
        }
        return _return;
      };
      
      export function extractMcCreditCurrency(_request) {
        var shva_curr_code =  _request.substr(_request.indexOf('D') + 4,1);
        var _return;
        if (shva_curr_code == "1") {
          _return = "ILS";
        } else if (shva_curr_code == "2") {
          _return = "USD";
        }
        return _return;
      };
      
      export function sliceStringToArrayMaxSize(str, maxLineSize) {
              var locationOfSpace;
              var returnedAry = [];
              if (!str) {
                return returnedAry;
              }
              while  (str.length > 0) {
                      for (locationOfSpace=maxLineSize;
                              locationOfSpace>0 && str[locationOfSpace] !== ' ' && maxLineSize < str.length ;
                              locationOfSpace--  ) {}
      
                      if (locationOfSpace === 0) { locationOfSpace=Math.min(str.length,maxLineSize)  ; }
                      returnedAry.push(str.substring(0,locationOfSpace)) ;
                      str = str.substr(locationOfSpace );
              }
              return returnedAry;
      };
      
    }
  }
}

