/**
 * This class is used for aggregating tasks, and then launching them asynchronously one after the other
 * 
 * All tasks must use the same function, which is supplied to the constructor as the asyncFunction parameter.
 * After all the tasks are complete, a success callback will be called (successCallback).
 * If one of the tasks fails, the execution stops (no more tasks will be run), and an error callback is called (errorCallback)
 * For monitoring the state of tasks execution, a progress callback can be specified (progressCallback).
 * All callbacks are handled via an PositiveTS.Callback class.
 * Read more about PositiveTS.Callback to understand what parametes each callback receives.
 *
 * Adding a task to the SerialRunner is done by calling PositiveTS.SerialRunner.addRun with an array of arguments.
 * As was noted eariler, all tasks share a common function (asyncFunction which is supplied to the constructor).
 * The arguments added via PositiveTS.SerialRunner.addRun will be delivered to the asyncFunction upon execution.
 *
 * The asyncFunction is executed each time with 3 arguments:
 * 1. An array of arguments which were added earlier via PositiveTS.SerialRunner.addRun
 * 2. A success callback
 * 3. An error callback
 * The function must call the callbacks when appropriate (on success call the success callback, etc.).
 * Otherwise, the SerialRunner won't continue executing!
 * 
 * @example
 * var asyncFunction = function(theArguments, successCallback, errorCallback) {
 *     console.log('I am a task called: ' + theArguments['name']);
 *     successCallback({});
 * }
 * 
 * let runner = new PositiveTS.SerialRunner(asyncFunction, successCallback, errorCallback, progressCallback);
 * runner.addRun({name: 'Bar'});
 * runner.addRun({name: 'Foo'});
 * runner.addRun({name: 'BarFoo'});
 * runner.run();
 *
 * Output to console:
 * I am a task called: Bar
 * I am a task called: Foo
 * I am a task called: BarFoo
 * 
 * Note that after starting the SerialRunner, new tasks cannot be added anymore (even after it finished).
 */
module PositiveTS {
	export function SerialRunner(asyncFunction, successCallback, errorCallback, progressCallback) {
		// The arguments to use, per run (or task)
		let argumentsPerRun = new Array();
		
		// The current iteration
		let currentIteration = 0;
		
		// A flag indicating whether we are iterating or not
		let isIterating = false;
		
		let callback = null;
		
		/**
		 * Adds a run (or task) to the SerialRunner. The arguments supplied to this function will be used for
		 * executing the asyncFunction supplied as the first argument to the constructor of PositiveTS.SerialRunner.
		 * 
		 * @param {Array} theArguments An array of arguments.
		 */
		this.addRun = function (theArguments) {
			// If we are running, don't let adding runs!
			if (isIterating) {
				return;
			}
			
			// Add the arguments to the arguments list
			argumentsPerRun[argumentsPerRun.length] = theArguments;
		};
		
		/**
		 * Runs the SerialRunner
		 */
		this.run = function () {
			// We are starting to iterate so change the isIterating flag to true
			isIterating = true;
			
			// Define a PositiveTS.Callback object, which will manage the callbacks lifecycle for us
			callback = new PositiveTS.Callback(argumentsPerRun.length, successCallback, errorCallback, progressCallback);
			
			// Run the first iteration
			iteration();
		};
		
		/**
		 * Run an iteration
		 */
		let iteration = function () {
			// Runs the asyncFunction with the arguments for this iteration and custom callbacks
			asyncFunction(argumentsPerRun[currentIteration], successfullIteration, failedIteration);
		};
		
		/**
		 * A callback which will be called when an iteration was successful
		 */
		let successfullIteration = function (aObject) {
			// Update the currentIteration counter
			currentIteration = currentIteration + 1;
			
			// Call the progress callback of the callback object
			callback.progress('', currentIteration/argumentsPerRun.length);
			
			// Inform the callback object that an iteration was successful
			callback.successWithObject(aObject);
			
			// If we still have iteration left, contiune running
			if (currentIteration < argumentsPerRun.length) {
				iteration();
			}
		};
		
		/**
		 * A callback which will be called when an iteration failed
		 */
		let failedIteration = function (error) {
			// Inform the callback object that an iteration failed
			return callback.error(error);
		};
	}
}