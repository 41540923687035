module PositiveTS {
    export module Components {
        export module PositiveSelect {
            export function getComponent() {
                return {
                    inheritAttrs: false,
                    template: JST.positiveSelect(),
                    props: {
                        modelValue: {
                            type: null,
                        },
                        options: {
                            type: Array,
                            default: () => [],
                        },
                        optionsLabel: {
                            type: [String, Function],
                            default: 'label',
                        },
                        optionsValue: {
                            type: [String, Function],
                            default: null,
                        },
                        placeholder: {
                            type: String,
                            default: '',
                        },
                        label: {
                            type: String,
                            default: '',
                        },
                        noBackground: {
                            type: Boolean,
                            default: false,
                        },
                        dialogTitle: {
                            type: String,
                            default: null,
                        },
                        serachInputLabel: {
                            type: String,
                            default: null,
                        },
                        groupBy: {
                            type: String,
                            default: null,
                        },
                        groupByLabelMapFunction: {
                            type: Function,
                            default: null,
                        },
                    },
                    methods: {
                        async toggleOpen() {
                            this.isOpen = !this.isOpen;

                            if (this.isOpen) {
                                await this.$nextTick();
                                this.$refs.input.focus();
                            } else {
                                this.$emit('finish');
                            }
                        },
                        selectOption(option) {
                            if (option.isCategory) {
                                return;
                            }

                            this.$emit('update:modelValue', option.value);
                            this.toggleOpen();
                        },
                        focus() {
                            this.toggleOpen();
                        },
                        highlightSearchTextWithSpan(label) {
                            if (!posUtils.isBlank(this.search) && !posUtils.isBlank(label)) {
                                return label.split(this.search).join(`<span class="bold">${this.search}</span>`);
                            }

                            return label;
                        }
                    },
                    computed: {
                        optionValueMapFunction() {
                            // this computed return a function that manipulate the option object to the desired value
                            // if null, return the option object
                            // if string, return the property
                            // if map function, return the map function
                            if (this.optionsValue) {
                                if (posUtils.isString(this.optionsValue)) {
                                    return option => option[this.optionsValue];
                                }

                                return this.optionsValue;
                            }

                            return option => option;
                        }, 
                        optionLabelMapFunction() {
                            // this computed return a function that manipulate the option object to the desired label
                            // if string, return the property
                            // if map function, return the map function
                            if (posUtils.isString(this.optionsLabel)) {
                                return option => option[this.optionsLabel];
                            }
                         
                            return this.optionsLabel;
                        }, 
                        selectedOption() {
                            return this.options.filter(opt => _.isEqual(this.modelValue, this.optionValueMapFunction(opt)))[0] || null;
                        },
                        selectedOptionText() {
                            if (this.selectedOption) {
                                return this.optionLabelMapFunction(this.selectedOption)
                            }

                            if (this.modelValue != null) {
                                return this.$t('positiveInput.selectedOptionNotInOptions');
                            }

                            return this.placeholder;
                        },
                        filteredAndMappedLabelValueOptions() {
                            let result = [];
                            let shouldDoSearch = !posUtils.isBlank(this.search)

                            for (let option of this.options) {
                                let optionLabel = this.optionLabelMapFunction(option);

                                if (shouldDoSearch && !optionLabel.includes(this.search)) {
                                    continue;
                                }

                                let currentRowToAdd:any = {value: this.optionValueMapFunction(option),
                                                           label: optionLabel,
                                                           highlightedLabel: this.highlightSearchTextWithSpan(optionLabel),
                                                           option: option };

                                if (this.groupBy) {
                                    currentRowToAdd.groupByFieldData = option[this.groupBy];
                                }

                                result.push(currentRowToAdd);
                            }

                            return result;
                        },
                        flattenLabelValueOptionsToShow() {
                            if (this.groupBy) {
                                let result = [];

                                let groupedOptions =  _.groupBy(this.filteredAndMappedLabelValueOptions, 'groupByFieldData')

                                for (let groupByValue in groupedOptions) {
                                    let label = this.groupByLabelMapFunction ? this.groupByLabelMapFunction(groupByValue) : String(groupByValue);
                                    if(!posUtils.isBlank(label)){
                                        result.push({label, isCategory: true});
                                    }
                                    result = result.concat(groupedOptions[groupByValue]);
                                }

                                return result;
                            }

                            return this.filteredAndMappedLabelValueOptions;
                        },
                        cssClasses() {
                            return {
                                'no-background': this.noBackground,
                            };
                        },
                    },
                    data() {
                        return {
                            isOpen: false,
                            search: '',
                        }
                    }
                }
            }
        }
    }
}  