module PositiveTS {
  export module Service {

    export enum DELIVERY_API_TYPES {
      POSITIVE = 1,
      WOLT = 2,
    }

    export enum DELIVERY_API_ORDER_STATUSES {
      NEW = 0,
      IN_PROGRESS = 1,
      READY = 2,
      DELIVERED = 3,
    }

    export abstract class BaseDeliveryAPIService {
      protected allowSplitSale = false;


      constructor(protected convertService: BaseOrderConvertService) {
      }

      protected async getToken(): Promise<string> {
        let token = this.getLocalToken();
        if (!token) {
          await this.login();
          token = this.getLocalToken();
        }

        return token;
      }

      protected getLocalToken(): string {
        if (this.isLoginRequired()) {
          return null;
        } else {
          return JSON.parse(localStorage.getItem(this.getTokenLocalStorageKey())).token;
        }
      }

      protected isLoginRequired(): boolean {
        return false;
      }

      protected abstract login(): Promise<void>;
      protected abstract getTokenLocalStorageKey(): any;
      protected abstract getOpenOrders(): Promise<any[]>;
      public abstract setOrderStatus(order: any, status: DELIVERY_API_ORDER_STATUSES, data?: any);
      public abstract serviceName();
      protected async makeHttpRequest(url: string, type: string, body: any = null, headers = null) {
        try{
          let request = await Service.HTTPService.makeHttpRequestWithFullResponse(url, type, body, headers || await this.getHeaders())
          return request;
        }catch(e){
          PositiveTS.Service.Delivery.failedDeliveriesLogger(e,null,this.serviceName(),Delivery.FailedDeliveryType.HttpError, false)
          PositiveTS.Service.Logger.error(e);
          return null;
        }
      }

      protected async getHeaders() {
        return { "accept": "application/json", "Content-Type": "application/json", "Authorization": await this.getToken() };
      }

      public static getServiceFromJsonData(apiData) {
        if (apiData.type == DELIVERY_API_TYPES.POSITIVE) {
          return new PositiveDeliveryAPIService(apiData);
        }

        if (apiData.type == DELIVERY_API_TYPES.WOLT) {
          return new WoltDeliveryAPIService(apiData);
        }

        throw new Error("UNKNOWN API TYPE")
      }

      public async handleNewOrders() {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && !Pinia.dalpaksStore.allDalpaks) {
          return;
        }

        let orders = await this.getOpenOrders();
        if(!orders){
          Service.Logger.error('Error in fetching orders from ' + this.serviceName());
          return;
        }
        
        const sales = await appDB.sales.toArray()
        const salesGroupByCustomerNumber = _.groupBy(sales, (sale) => {
          let saleJsondata = JSON.parse(sale.jsondata)
          return saleJsondata.customer ? saleJsondata.customer.customer_number : null
        })
        const salesByCustomerNumber = salesGroupByCustomerNumber[this.convertService.getCustomerNumber()]
        const usedOrderNumbers = _.keyBy(salesByCustomerNumber, 'orderNumber')

        for (let order of orders) {
          try {
            // if order already exists
            if (usedOrderNumbers[this.convertService.getOrderNumber(order)]) {
              continue;
            }

            let sale = await this.convertService.convertOrderToSale(order);

            if (sale.items.length > 0) {
              let jsondata = JSON.parse(sale.jsondata);

              if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners) && (posUtils.isBlank(sale.diners) || sale.diners == 0)) {
                sale.diners = 1;
              }

              if (posUtils.isBlank(sale.customerName) && posUtils.isBlank(sale.customerPhone) && posUtils.isDefined(jsondata.customer)) {
                sale.customerName = `${jsondata.customer.s_first_name} ${jsondata.customer.s_last_name}`
                sale.customerPhone = jsondata.customer.s_phone_number_1;
              }

              let isPickup = jsondata.delivery.isPickup;

              // Remove sale cash payment for pickup so the sale won't close when we go to the payment screen
              if (isPickup) {
                sale.payments = sale.payments.filter(p => p.method != Storage.Entity.SalePayment.METHOD_CASH)
              }

              let fullSale = new PositiveTS.Service.FullSale(sale, sale.items, sale.payments);


              if (this.allowSplitSale) {
                fullSale = await this.splitSaleIfNeeded(fullSale);
              }

              let fullSaleFormat = PositiveTS.Service.FullSale.getFullSaleFormat(sale, sale.items, sale.payments);

              if (isPickup) {
                await Service.LogicalPrinterBonPrint.printBons(fullSale)
                await Service.LogicalPrinterBonPrint.updateSaleItemsBonPrinted(fullSale)

                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  await Service.DalpakSharedOrders.createOrderDalpak(fullSaleFormat, sale.orderNumber);
                } else {
                  Service.HoldSale.markSaleAsHoldedSale(sale);
                  await Service.FullSale.persist(sale, sale.items, sale.payments);
                }
              } else {
                sale.syncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
                sale.syncLastMessageTimestamp = DateUtils.fullFormat();
                let persistedSale = await Service.FullSale.persist(sale, sale.items, sale.payments);

                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  await Service.DalpakSharedOrders.createOrderDalpak(fullSaleFormat);
                }

                await Service.LogicalPrinterBonPrint.printBons(fullSale)
              }

              if (jsonConfig.getVal(jsonConfig.KEYS.autoTenbisOrderPrintInvoice) && !isPickup) {
                await Printing.Invoice.printInvoice(sale, saleItemHelper.unflattenSaleItems(sale.items), sale.payments, true);
              }

              if (jsonConfig.getVal(jsonConfig.KEYS.printItemListBon)) {
                Printing.Invoice.printItemListBon(sale, saleItemHelper.unflattenSaleItems(sale.items));
              }



              try {
                await this.setOrderStatus(order, DELIVERY_API_ORDER_STATUSES.IN_PROGRESS);
              } catch(err) {
                Service.Logger.error('Error set order status in ' + this.serviceName());
                Service.Logger.error(err);
              }

            }
          } catch (err) {
            Service.Logger.error('Error in Converting order from ' + this.serviceName());
            Service.Logger.error(err);
            Service.Logger.error(order);
          }
        }
      }

      async splitSaleIfNeeded(fullSale) {
        let customer = posUtils.isBlank(fullSale.sale.jsondata) ? null : JSON.parse(fullSale.sale.jsondata)['customer']
        let hakafaCustomer = null;

        if (customer && customer.clubName == PositiveTS.Service.Hakafa.CLUB_IDENTIFIER) {
          hakafaCustomer = customer;
        }

        let splitInfo = Service.CloseSale._getSplitSaleInfo(fullSale.sale, fullSale.saleItems, fullSale.salePayments, hakafaCustomer)

        if (splitInfo.saleSplitRequired && splitInfo.tamashPct > 0) {
          let regularSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)
          let tamashSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_SHIPMENT_INV)
          //regular inv is the main invoice
          //create a new tamash invoice for the split
          let tamashSale = await Service.ShipmentInv.createShipmentInvForSplit(fullSale, tamashSeq, regularSeq)
          await Service.CloseSale.persistChanges(fullSale, tamashSale, tamashSeq)
          await Printing.Invoice.printInvoice(tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, true)
          if (jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)) {
            await Printing.Invoice.printInvoice(tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, false)
          }
        }

        let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(fullSale.sale, fullSale.saleItems, fullSale.salePayments);
        fullSale.sale.totalVatableAmount = session.store.containVat ? 0 : saleTotals.totalVatableAmount;
        fullSale.sale.totalAmount = saleTotals.totalAmount

        return fullSale;
      }
    }
  }
}