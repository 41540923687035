module PositiveTS {
export module Components {
export module PosPayment {
export module CheckView {

  export function create() {
      var c = {
        template:  JST.CheckView(),
        mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
        methods: {
          selectField: selectField,
          onKeyTapped: onKeyTapped,
          getSelectedField: getSelectedField,
          clearTapped: clearTapped,
          verifyAndPay: verifyAndPay,
          clearOldData: clearOldData,
          deleteCreditPayment: deleteCreditPayment,
          updateCheckNumber: updateCheckNumber,
          refreshView: refreshView,
          getConfirmationNumber: getConfirmationNumber,
          isAndroid: isAndroid,
          isCaveret: isCaveret,
          showTz: () => { return jsonConfig.getVal(jsonConfig.KEYS.isErn) || !jsonConfig.getVal(jsonConfig.KEYS.hideTzOnChecks) },
        },
        setup(){

          const globalStore = Pinia.useGlobalStore();
          const {isRoshemet,mobileLayout} = Pinia.storeToRefs(globalStore);
          const globalStoreProps = {isMobile:mobileLayout,isRoshemet} 

          return {...globalStoreProps}
        },
        data: initData,
      }

      VueApp.component('check-view',c)
  }


  function isAndroid(){
    return session.isAndroid;
  }

  function isCaveret(){
    return session.pos.isCaveret;
  }


  function initFields() {
    let fields = {

      'amount': {
        selected: false,
        allowDecimal: true,
        inEditMode: false,
        type: 'number',
        disabled: false
      },
      'checkBank': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      },
      'checkBranch': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      },
      'checkAccount': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      },
      'checkNumber': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      }, 
      'checkDate': {
        selected: false,
        inEditMode: false,
        type: 'string',
        allowDecimal: false,
        disabled: false
      },      
      'checkPaymentCount': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      }, 
      'checkManualApproval': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      }, 
      'checkCustomerId': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      }, 
      'checkGuarantorId': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      }, 
      'checkManualConfirmationNumber': {
        selected: false,
        inEditMode: false,
        type: 'number',
        allowDecimal: false,
        disabled: false
      }, 
    }
    for (let field in fields) {
      fields[field].model = field;
    }
    return fields
  }

  function getConfirmationNumber(ernString){
    var strErn = i18next.t('none');
    if (ernString && ernString !==serviceCheckErn.ERN_FORCED_CONTINUE){
      strErn = ernString;
    }
    return strErn;
  }

  async function refreshView() {
    let aThis=this;

    aThis.setCalculatedAmount();
    
    aThis.creditPayments = Service.Check.CheckHelper.getListOfChecks()
    aThis.clearTapped();
    await PositiveTS.VueInstance.$nextTick()
  }

  async function verifyAndPay(){
    let data = { 
      amount: parseFloat(this.amount.toString().replace(',','')),
      bank: this.checkBank.toString(),
      branch : this.checkBranch,
      account: this.checkAccount,
      number : this.checkNumber,
      date   : this.checkDate,
      paymentCount:  this.checkPaymentCount,
      manualApproval: this.checkManualConfirmationNumber,
      customerId: this.checkCustomerId,
      guarantorId: this.checkGuarantorId,
    }

    // if((session.pos.isRoshemet) || (session.pos.isCaveret)){
      data.date = moment(data.date).format("D/M/YY")
    //}

    let isSuccessful = await Service.Check.CheckHelper.verifyAndPay(data)
    
    if (isSuccessful) {
      this.addSplittedPaymentIfNeeded();
      this.clearOldData()
      this.refreshView();
    }
  }

  function updateCheckNumber(index){
    if (this.isErnSupported){
      return;
    }
    return PositiveTS.Service.Check.CheckHelper.updateCheckNumber(index)
    .then( ()=>{
      this.refreshView();
    })

  }

  function deleteCreditPayment(index){

    var aThis = this;
      return app.promiseShowAlert({
        header: i18next.t('confirmSalePaymentItemDeleteHeader'),
        content: i18next.t('confirmSalePaymentItemDelete'),
        continueButtonText: i18next.t('remove'),
        cancelButtonText: i18next.t('cancel')
      })
      .then( (result) =>{
        if (result === "continue"){
          return Service.Check.CheckHelper.spliceCheckPayment(index);
        }
      })
      .then( ()=>{
        aThis.removeSplittedPaymentIfNeeded();
        aThis.refreshView();
      })
  }

  function clearTapped() {
    let aThis = this;
    aThis.clearOldData()
    aThis.selectField('amount')
  }


  //clears the data after a single payment but when there might be still amount to pay
  function clearOldData() {
    let aThis = this;
    let result = initData()
    for (let prop in result) {
      if (prop === 'creditPayments' || prop === "amount") {
        continue;
      }
      aThis[prop] = result[prop];
    }

  }

  

  function onKeyTapped(key) {
    let aThis = this

    let field = aThis.getSelectedField();
    aThis.keyPadHanlder(key,field,aThis);
  }

  function getSelectedField() {
    let aThis = this
    for (let fieldName in aThis.fields) {
      if (aThis.fields[fieldName].selected) {
        return aThis.fields[fieldName]
      }
    }
    return undefined;
  }


  function initData() {
    let types = []

    return {
      cardNumberText: "",
      fields: initFields(),
      clearImg: `${(<any>window).images_path}/close.png`,
      amount: 0,
      checkBank: "",
      checkBranch: "",
      checkAccount: "",
      checkNumber: "",
      checkDate: null,
      checkPaymentCount: 1,
      checkManualApproval: "",
      checkCustomerId: "",
      checkGuarantorId: "",
      checkManualConfirmationNumber: "",
      creditPayments: [],
      isErnSupported: serviceCheckErn.isErnSupported(),
    }
  }

  function selectField(fieldName) {
    let aThis = this;
    let refField = null;
    //not liking this code so much... but want to make sure that number will be rounded
    aThis.amount = session.fixedFloat(aThis.amount);
    for (let field in aThis.fields) {
      aThis.fields[field].selected = false
      aThis.fields[field].inEditMode = true
      aThis.fields[field].hasDecimal = false
      if (field == fieldName) {
        aThis.fields[field].selected = true
        refField = field
      }
    }
    if (refField != null) {
      this.$refs[refField].focus();
    }
  }
}}}}
