
module PositiveTS {
	export module Components {
		export module OsherAdSaleComponent {
			export function getComponent() {
				return {
					template: JST.OsherAdSaleComponent(),
					mixins: [Mixins.selfServicePaymentMixin('')],
					components: {
						SingleItem: PositiveTS.Components.RestaurantSingleItem.getComponent(),
						PositiveIcon: PositiveTS.Components.PositiveIcon.getComponent(),
					},
					data() {
						return {
							isScrollTop: true,
							isScrollBottom: false,
							showScroll: false,
							scrollAmount: 500,
						}
					},
					mounted() {
						this.updateScrollStatus();
						this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
					},
					beforeUnmount() {
						this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
					},
					methods: {
						async goToPay() {
							Pinia.componentsStore.openComponent( { componentName: "selfServicePaymentDialog", args: [] });
						},
						resetSaleAndStep() {
							Service.OsherAd.cancelSale();
						},
						scrollUp() {
							const container = this.$refs.scrollContainer;
							const scrollPosition = container.scrollTop - this.scrollAmount;
							this.doTheScroll(container, scrollPosition);
						},
						scrollDown() {
							const container = this.$refs.scrollContainer;
							const scrollPosition = container.scrollTop + this.scrollAmount;
							this.doTheScroll(container, scrollPosition);
						},
						doTheScroll(container, scrollPosition) {
							container.scrollTo({
								top: scrollPosition,
								behavior: 'smooth',
							});
							setTimeout(() => {
								this.updateScrollStatus();
							}, 300);
						},
						updateScrollStatus() {
							const container = this.$refs.scrollContainer;
							this.isScrollTop = container.scrollTop === 0;
							this.showScroll = container.scrollHeight > container.clientHeight
							this.isScrollBottom = container.scrollHeight - container.scrollTop <= container.clientHeight;
						},
						handleScroll() {
							this.updateScrollStatus();
						},
						promotionsForRow(saleItem: Storage.Entity.SaleItem) {
							let totalWithDiscounts = Number(saleItem.displayTotal);
							let promoGroupsToDisplay = [];

							for (let i = 0; i < this.promoGroups.length; i++) {
							  const pg = this.promoGroups[i];
							  if (!pg.isSaleDiscount && pg.rowToShowOn == saleItem.rowNumber && pg.discountAmountForGroup != 0) {
								totalWithDiscounts -= pg.discountAmountForGroup;
								promoGroupsToDisplay.push({
								  ...pg,
								  totalToDisplay: this.getTotalAmountString(totalWithDiscounts),
								  isNotLast: i < (this.promoGroups.length - 1)
								});
							  }
							}

							return promoGroupsToDisplay;

						},
						getTotalAmountString(total: number): string {
							return `${session.fixedNumber(Math.abs(total))}${total < 0 ? '-' : ''}`;
						},
					},
					setup(){

						const globalStore = Pinia.useGlobalStore();
						const {saleItems,saleTotal,mobileLayout,sale,promoGroups,totalQuantity} = Pinia.storeToRefs(globalStore);
						const globalStoreProps = {saleItems,saleTotal,mobileLayout,sale,promoGroups,totalQuantity}
		  
						return {...globalStoreProps}
					  },
					computed: {
						autoStore(){
							return Pinia.posStore.autoStore
						},						
						head_logo(): string {
							return this.autoStore.config.head_logo;
						},
						currencySign() {
							return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
						},
					},
				}
			}
		}
	}
}
