export default class EmvRequest {
    public AccessControl:string
    public Addendum1:string
    public Addendum1Settl:string
    public Addendum2:string
    public Addendum2Settl:string
    public Addendum3Settl:string
    public Addendum5Settl:string
    public Addendum4Settl:string
    public Address:string
    public Amount:string
    public AshReasonCredit:string
    public AuthorizationCodeManpik:string
    public AuthorizationCodeSolek:string
    public AuthorizationNo:string
    public BlockDoubleTrans:string
    public CapDpa:string
    public CarNumber:string
    public CashbackAmount:string
    public CavvUcaf:string
    public Cellular:string
    public CheckCaspitHost:string
    public CheckCertServer:string
    public CheckReportServer:string
    public CheckShva:string
    public CheckSwitch:string
    public CheckTMS:string
    public City:string
    public ClubNumber:string
    public Command:string
    public Commission:string
    public ConversionAmount:string
    public ConversionCurrency:string
    public ConversionRate:string
    public CreditTerms:string
    public Currency:string
    public CurrentRecord:string
    public RecordsPerRequest:string
    public Cvv2:string
    public Date:string
    public DeferMonths:string
    public DueDate:string
    public Eci:string
    public EnableFullPAN:string
    public EnableIdleSwipe:string
    public EnableSmartSwipe:string
    public ExpirationDate:string
    public ExtraXml:string
    public FileNo:string
    public FirstPayment:string
    public GasolineLiter:string
    public GasolineType:string
    public GiftCode:string
    public Id:string
    public IndexPayment:string
    public IpayAmount:string
    public IpayCode:string
    public IpayNumber:string
    public IpayPercent:string
    public Mti:string
    public NoPayments:string
    public NotFirstPayment:string
    public OfferCode:string
    public OilAmount:string
    public OilLiter:string
    public OriginalAmount:string
    public OriginalAuthNum:string
    public OriginalAuthorizationCodeManpik:string
    public OriginalAuthorizationCodeSolek:string
    public OriginalAuthorizedAmount:string
    public OriginalAuthSolekNum:string
    public OriginalLinkIncrAuth:string
    public OriginalTranDate:string
    public OriginalTranTime:string
    public OriginalUid:string
    public Otp:string
    public PanEntryMode:string
    public ParameterJ:string
    public ProductCode:string
    public RequestId:string
    public Rrn:string
    public SapakMutavNo:string
    public SelfServiceTrans:string
    public ServiceAmount:string
    public SessionNumber:string //Session-Number
    public ShvaTime:string
    public ShvaTimerType:string
    public SpecialProjectCode:string
    public SpecialProjectInfo1:string
    public Speedometer:string
    public StndOrdrFreq:string
    public StndOrdrNo:string
    public StndOrdrTotalNo:string
    public StndOrdrTotalSum:string
    public StndOrdrUniqueRef:string
    public SubCommand:string
    public TerminalId:string
    public TermNo:string
    public TimeoutInSeconds:string
    public Tip:string
    public TranType:string
    public XField:string
    public Xid:string
    public Zdata:string
    public Zip:string
    public rawXml:string
    public rawXmlMode:boolean
    public SwitchSend:string
  
  }