module PositiveTS {
export module Storage {
export module Entity {
export class SystemSettings extends WasmEntity {


	constructor() {
		let meta = {
			name: 'SystemSettings',
			fields: {
				settingsID: "TEXT",
				vat: "TEXT"
			},
			unique: ['settingsID']
		}
		super(meta);
	}
}}}}
