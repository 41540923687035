
module PositiveTS {
  export module Service {
  export module OrdererPickupRemarks {

    export async function setOrdererPickupRemarks(title = null, description = null, inputPlaceHolder = null, allowEmptyRemark = true, 
                                         allowLongRemark = false, tooLongErrorMessage = null, strDefault?) {

      title = strDefault ? i18next.t('pos.ordererPickupRemarksEdit') : title || i18next.t('pos.ordererPickupRemarks');
      description = strDefault ?  i18next.t('pos.ordererPickupRemarksDescription') : description || i18next.t('pos.enterOrdererPickupRemarks');
      inputPlaceHolder = inputPlaceHolder || i18next.t('pos.ordererPickupRemarks');
      let ordererPickupRemarks = await inputDg.open({
                                    header: title,
                                    description: description,
                                    strDefault: strDefault,
                                    dialogPosition: {my: 'center top+150', at: 'top'},
                                    inputPlaceHolder: inputPlaceHolder,
                                    showCancelButton: true,
                                    keyboardLayout: "hebrew-qwerty-custom",//'hebrew-qwerty-custom',
                                    tooLongErrorMessage: tooLongErrorMessage || i18next.t('pos.remarkTooLong',{maxChars: "255"}),
                                    inputValidator: function(value) {
                                      if(allowEmptyRemark){
                                        return true;
                                      }else{
                                        return !posUtils.isNullOrUndefinedOrEmptyString(value);
                                      }
                                    },
                                    inputLengthValidator: (value) => allowLongRemark || value && value.length > 255 ? true : false
                                  })

      let saleWithRemarks = setSaleJsonData(posVC.sale, ordererPickupRemarks);
      posVC.sale = saleWithRemarks
      await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)

    }

    export function setSaleJsonData(sale, ordererPickupRemarks) {

      if(!sale.jsondata) {
        sale.jsondata = JSON.stringify({});
      }

      var jsonData = JSON.parse(sale.jsondata);
      if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && sale.isDelivery){
        if (posUtils.isBlank(jsonData.delivery)) {
          jsonData.delivery = {};
        }
        if(ordererPickupRemarks?.length > 0) {
          jsonData.delivery.isPickup = true;
          jsonData.delivery.ordererDeliveryRemarks = ordererPickupRemarks;
        } else {
          if(jsonData.delivery.ordererDeliveryRemarks) {
            delete jsonData.delivery.ordererDeliveryRemarks
          }
          if(jsonData.delivery.hasOwnProperty('isPickup')) {
            delete jsonData.delivery.isPickup
          }
        }
      }
      sale.jsondata = JSON.stringify(jsonData);
      return sale
    }
  }}}
