module PositiveTS {
export module Storage {
export module Entity {
export class PromotionStore extends WasmEntity {


	promotionID:string
	storeID:string

	constructor() {
		let meta = {
			name: 'PromotionStore',
			fields: {
				promotionID: "TEXT",
			  	storeID: "TEXT"
			}
		}
		super(meta)
	}

}}}}
