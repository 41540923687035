module PositiveTS {
	export class Dispatcher {
		currentURL = null;
		/**
		 * Title prefix for all pages. See pNavigator.pushPage and pNavigator.replacePage for more info
		 * @type String
		 */
		titlePrefix = '';
	
		/** Posted when a state changes */
		StateChangedNotification = 'PositiveApplicationDispatcherStateChangedNotification';
	
		init() {
			(function(window,undefined){
				// Check whether History is enabled or not
				var jqHistory = (<any>window).History;
				if (!jqHistory.enabled) {
					// Display an alert (since we can't use the built-in dialog yet
					alert('History.js is disabled for this browser');
					return false;
				}
	
				var zIndex = 900;
	
				var initializeAndShowPage = function (page) {
					// Determine the controller for the new page:
					// The page name is made of one word or several words separated by hyphen
					// The controller name can be found from the page name by taking each word and camel casing them
					var pageNameParts = page.split('-');
					for(var loop in pageNameParts) {
						pageNameParts[loop] = pageNameParts[loop].charAt(0).toUpperCase() + pageNameParts[loop].slice(1);
					}
					var controllerName = pageNameParts.join('') + 'ViewController';
	
					// Check that this controller exists
					if (!(controllerName in PositiveTS.Application.Controllers.instances)) {
						console.error('Controller for page with name ' + page + ' does not exist');
	
						// We are done!
						return null;
					}
	
					// The page is the ID of the page
					var pageID = '#' + page;
	
					// Check that there is actually a page with an ID of pageID
					if ($(pageID).length == 0) {
						console.error('Page with name ' + page + ' does not exist');
	
						// We are done!
						return null;
					}
	
					// Show the page of the current state
					$(pageID).show();
	
					// Initialize and resume the view controller of the current state
					var newController = PositiveTS.Application.Controllers.instances[controllerName];
					newController.init();
	
					// Give it an z-index and decrease the z-index counter
					$(pageID).css({
						'z-index': zIndex
					});
					zIndex = zIndex - 1;
	
					// If the page requires an overlay, add it
					var overlayAttribute = $(pageID).attr('data-overlay');
					if (overlayAttribute != undefined) {
						// Create an overlay div before the page
						var overlay = $('<div></div>').addClass('overlay');
						$(pageID).before(overlay);
	
						// Give it an z-index and decrease the z-index counter
						overlay.css({
							'z-index': zIndex
						});
						zIndex = zIndex - 1;
					}
	
					return controllerName;
				};
	
				// When the state of the page changes, run the dispatcher to change the current controller
				jqHistory.Adapter.bind(window, 'statechange', function () {
					// Get the new state
					var State = jqHistory.getState();
	
					// The data object that comes with State has to have some properties:
					// 1. page - The name of the page (also its ID)
					// 2. relationship - The relationship of the page to other pages
					// 3. options - An object of options that will be passed to the controller
					if (!('data' in State) || !('page' in State.data) || !('relationship' in State.data) || !('options' in State.data)) {
						// If one of the properties is missing, than it means to display the init page
						initializeAndShowPage('init');
	
						// We are done!
						return;
					}
	
					// Extract the page and options of the new state
					var page = State.data.page;
					var relationship = State.data.relationship;
					var options = State.data.options;
					var pageTitle = State.title;
					var pageURL = State.url;
	
					// Define defaults for the options parameter
					let defaults = {
						// By default, there are no initialize parameters
						initParams: {}
					};
	
					// Generate new options which includes the defaults for any missing properties
					options = $.extend(defaults, options);
	
					// If there is no user logged-in, ensure we only allow the login page
					if (!session.isLoggedIn && page != 'login') {
						// Go to the login page
						pNavigator.replacePage('login', i18next.t('pageTitle.login'), null, null);
	
						// We are done
						return;
					}
	
					// --- Post a Notification ------------------------------------------------
					PositiveTS.NotificationCenter.postNotification(pNavigator.StateChangedNotification, {page: page});
	
					// --- Cleanup ------------------------------------------------------------
					// Stop all controllers
					for (var controllerName in PositiveTS.Application.Controllers.instances) {
						if(!PositiveTS.Application.Controllers.instances.hasOwnProperty(controllerName)) {
							continue;
						}
						PositiveTS.Application.Controllers.instances[controllerName].stop();
					}
	
					// Hide all pages
					$('[data-role="page"]').hide();
	
					// Remove all overlays
					$('.overlay').remove();
	
					// Hide loading message
					app.hideLoadingMessage();
	
					// Reset the z-index
					zIndex = 900;
	
					// --- Show, Initialize and Resume Current State --------------------------
					// Initialize and show the page
					var controllerName = initializeAndShowPage(page);
					if (controllerName == null) {
						// The controller doesn't exists, go back!
						jqHistory.back();
	
						// The page cannot be initialized or shown, so exit
						return;
					}
	
					// Initialize and resume the view controller of the current state
					var newController = PositiveTS.Application.Controllers.instances[controllerName];
					newController.resume(options.initParams);
	
					// Set the current URL so it can be used for setting relationships relative to the current state
					pNavigator.currentURL = relationship + page;
	
					// --- Show and Initialize Current State Relationship --------------------
					// If the relationship is the init page, than just initialize and show it
					if (relationship === '/') {
						initializeAndShowPage('init');
						return;
					}
	
					// The relationship is made of page names separated by a slash
					var relationshipChain = relationship.split('/');
	
					// If the last page (which is the first element) in the relationship chain is empty, set it to the init page
					if (relationshipChain[0] === '') {
						relationshipChain[0] = 'init';
					}
	
					// Iterate over the relationship chain, end to start
					for (var i = relationshipChain.length - 1; i >= 0; i--) {
						// Get the current page from the chain
						var aPage = relationshipChain[i];
	
						// Skip over empty strings
						if (aPage === '') {
							continue;
						}
	
						// Initialize and show the page
						var controllerName = initializeAndShowPage(aPage);
						if (controllerName == null) {
							// The page cannot be initialized or shown, so exit
							return;
						}
					}
				});
	
				// --- Load Initial State ----------------------------------------------------
				// Trigget window's statechange event to load the initial state
				pNavigator.pushPage('init');
				jqHistory.Adapter.trigger(window, 'statechange');
			})(window);
	
		}
		/**
		 * This function is used as a shortcut for the History.pushState function
		 *
		 * The relationship is made of page names separated by a slash
		 *
		 * @param {String} page The page name. Should be the ID of the page.
		 * @param {String} title The page title that will displayed in the browser title
		 * @param {String} [relationship] The relationship of the page to other pages. Can be null.
		 * @param {Object} [options] An object that will be passed to the controller of the new page. Can be null.
		 */
		pushPage(page, title?, relationship?, options?) {
			// The default relationship is to the 'default' page
			if (relationship == null) {
				relationship = '/';
			}
			
			// Check that the last character in the relationship is a slash
			if (relationship.substr(relationship.length - 1, 1) != '/') {
				relationship = relationship + '/';
			}
	
			let jqHistory = (<any>window).History;
	
			jqHistory.pushState({page: page, relationship: relationship, options: options}, pNavigator.titlePrefix + title, '?' + relationship + page);
			Pinia.globalStore.setCurrentPage(page);
		}
		/**
		 * This function is used as a shortcut for the History.replacePage function
		 *
		 * The relationship is made of page names separated by a slash
		 *
		 * @param {String} page The page name. Should be the ID of the page.
		 * @param {String} title The page title that will displayed in the browser title
		 * @param {String} [relationship] The relationship of the page to other pages. Can be null.
		 * @param {Object} [options] An object that will be passed to the controller of the new page. Can be null.
		 */
		replacePage(page, title, relationship, options) {
			// The default relationship is to the 'default' page
			if (relationship == null) {
				relationship = '/';
			}
	
			// Check that the last character in the relationship is a slash
			if (relationship.substr(relationship.length - 1, 1) != '/') {
				relationship = relationship + '/';
			}
			let jqHistory = (<any>window).History;
	
			jqHistory.replaceState({page: page, relationship: relationship, options: options}, pNavigator.titlePrefix + title, '?' + relationship + page);
		}
		/**
		 * This function is used as a shortcut for the History.go function
		 *
		 * @param {Integer} X How many times to go. If X is negative go back through history X times, if X is positive go forwards through history X times
		 */
		go(X) {
			let jqHistory = (<any>window).History;
			jqHistory.go(X);
		}
		/**
		 * This function is used as a shortcut for the History.back function
		 * Go back once through the history (same as hitting the browser's back button)
		 */
		back() {
			let jqHistory = (<any>window).History;
			jqHistory.back();
		}
		/**
		 * This function is used as a shortcut for the History.forward function
		 * Go forward once through the history (same as hitting the browser's forward button)
		 */
		forward() {
			let jqHistory = (<any>window).History;
			jqHistory.forward();
		}
	}
}


// Note: the navigator var of the browser can be accessed through windowNavigator
declare var pNavigator:PositiveTS.Dispatcher;
pNavigator = new PositiveTS.Dispatcher();
