module PositiveTS {
  export module Service {
    export module Scale {

      export const S2Scale = "S2"

      const errPrefix = "ERROR:";
      const weightTimeout = _.debounce(() => {
        isInGetWLoop = false;
      },20000);

      // not running, running, complete
      var isInGetWLoop = false;
      var isScaleWasEmpty = false;

      export function getSelectedCom():string{
        return jsonConfig.getVal(jsonConfig.KEYS.scaleCom)
      }

      export function tryToGetFromScaleServerAndAddItem(barcode:string):Promise<boolean> {
        return new Promise((resolve,reject) => {
          if (barcode[0] != "2" || !posUtils.verifyBarcodeChecksum(barcode,13)) {
            return resolve(false)
          }

          let itemCode  = String(parseInt(barcode.substr(1,6)));

          Storage.Entity.Item.searchByCode(itemCode)
          .then(items => {
            if (items.length === 0) {
             return resolve(false)
            }
            if (items.length === 1) {
              let item:Storage.Entity.Item = items[0].item
              let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(item,{size:'null',color:'null',barcode: item.code})
              saleItem.saleID = posVC.sale.id;
              saleItem.rowNumber = posVC.getRowNumber();
              
              if (jsonConfig.getVal(jsonConfig.KEYS.scaleBarcodeIsByWeight)){
                let itemWeight = Number(barcode.substr(7,5)) / 1000;
                saleItem.quantity = itemWeight;
              }else{
                let itemPrice = Number(barcode.substr(7,5))/100
                saleItem.quantity = Number(((itemPrice / (item.priceZarhan == 0 ? itemPrice : item.priceZarhan) *1000)/1000).toFixed(3))
                if (item.priceZarhan == 0) {
                  saleItem.unitPrice = itemPrice;
                }
              }
              
              
              posVC.saleItems.push(saleItem);
              posVC.afterSaleItemPersisted(saleItem)
              PositiveTS.Service.CustomerDisplay.setItemDetails(saleItem);
              

              if (session.pos.parameterRequireSalesperson == PositiveTS.Storage.Entity.Pos.REQUIRE_MANDATORY) { //sync - for parit ben also
                // update sale item sales person
                Helper.SalesPerson.updateSalesPersonForItemInDB(saleItem);
              }
              // async function that do not return promise
              posVC.parameterRequireSalespersonHandler(saleItem)
              .then(() => {
                return resolve(true)
              })

              
            }
            else { //not sure if we can even get here...
              return resolve(false)
            }
          })
        })
      }

      export function getW(originalQuantity = null):Promise<number> {
        let comPort = getSelectedCom()
        return new Promise((resolve,reject) => {

          getManualWeight(originalQuantity)
          .then((qty) => {
            isInGetWLoop = false;
            clearTimeout(weightTimeout);
            return resolve(qty);
          })

          if (comPort !== "-1" && isInGetWLoop == false) {

            isInGetWLoop = true;
            isScaleWasEmpty = false;

            weightTimeout();

            return getLoop()
            .then(quantity => {
              if (!isNaN(quantity)) {
                PositiveTS.VueInstance.$refs.weightDialog.close();
                isInGetWLoop = false;
                weightTimeout.cancel()
                resolve(quantity)
              }
            })
            .catch(e => {
              //do nothing... wait for user to confirm with manual dialog
              isInGetWLoop = false;
              weightTimeout.cancel()
              console.warn(e);
            })
          }
          

          
        })
      }

      async function getLoop() {
        let quantity = await _getW()
        if (quantity != Number.NEGATIVE_INFINITY) {
          if (quantity) {
            if (isScaleWasEmpty){
              isScaleWasEmpty = false;
              return getLoop();
            }
            return quantity;
          } else {
            isScaleWasEmpty = true;
            if( isInGetWLoop) {
              return getLoop();
            } else {
              console.warn(i18next.t("scaleEmpty"));
            }
          }
        }
        else {
          if (isInGetWLoop) {
            return getLoop()
          };
        };
      }

      function getManualWeight(originalQuantity = null) {
        return Pinia.componentsStore.openComponent( {componentName:"weightDialog", args: [originalQuantity]})
        .then(result => {
          isInGetWLoop = false;
          weightTimeout.cancel()
          if (result.approved) {
            return result.weight;
          }
          else {
            isInGetWLoop = false;
            throw new Error(app.userCancelledGlobalMessage)
          }
        })
      }

      export async function zeroScale(){
        Service.GenericAddon.sendNativeMessageToExtension({ 
          data: JSON.stringify({com: getSelectedCom(), cmd:"z"}),
          action: "open"}, "scale" )
      }

      async function _getW():Promise<number>{
        let response = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ 
                              data: JSON.stringify({com: getSelectedCom(), cmd:"W",
                              newLineChar:jsonConfig.getVal(jsonConfig.KEYS.scaleNewLineChar),
                              baudRate:jsonConfig.getVal(jsonConfig.KEYS.scaleBaudRate)}),
                              action: "open"}, "scale" )
        let hasError = didGetError(response);
        if (hasError) {
          return Number.NEGATIVE_INFINITY
        }
        let quantity = parseFloat( response.request.result.replace(/[^\.0-9\-]/gmi,"")  );
        if (Number.isNaN(quantity)) {
          quantity = 0;
        }
        quantity = Math.round(quantity*1000)/1000;
        return quantity;
      }


      function didGetError(response):boolean{
        var err:string = response.request.result;
        if ( err.startsWith(errPrefix) ) {
            if(err.includes("Android")) {
              isInGetWLoop = false;
               app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('scaleAndroidError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                  imageHeight: 384,
                  imageUrl: `${(<any>window).images_path}pos/usb-permission.gif`
                })
            }
          return true;
        }
        return false;
      }
    }
  }
}
