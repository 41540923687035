module PositiveTS {
   export module Service {
      interface updateOrderParams {
         store_id?: number, //required
         order_id: number|string, //required
         email_address: string, //required
         token?: string, //required
         order_status_type?: number,
         total_price?: number,
         invoice_number?: string|number,
         invoice_url?: string,
      }
      export class CashCowService extends ECommerceAPIService {
         static baseUrl =  "https://api.cashcow.co.il/Api/"; 
         static apiName = "CashCow"
         static receivedOrders = [];
         
         private static headers() {
            return JSON.stringify({
               "accept": "application/json",
           })
         }

         protected static async getToken() {
            return jsonConfig.getVal(jsonConfig.KEYS.cashCowToken);
         }

         protected static async getCashCowStoreId() {
            return jsonConfig.getVal(jsonConfig.KEYS.cashCowStoreId);
         }


         private static async getOrderListByStatus(token: string, status: number) : Promise<Array<any>> {
            let totalPages = 1;
            let orders = []

            for(let page = 1; page <= totalPages; page++) {
               const data = {
                  "body": "",
                  "url": `${this.baseUrl}Stores/Orders?${this.convertObjectToFormUrlEncodedFormat({
                     token: token,
                     order_status: status,
                     page: page,
                     page_size: 20,
                     store_id: jsonConfig.getVal(jsonConfig.KEYS.cashCowStoreId),
                     DaysFromNow: 7,
                     include_payment_gateway_response: true
                  })}&order_status=1`,
                  "type": "GET",
                  "headers": this.headers()
               };
               try {
                  const response = await GenericAddon.sendNativeMessageToExtension(
                     { data: JSON.stringify(data) },
                     "external_service"
                  )
                  Service.Filelog.log("cashcow",
                        JSON.stringify({request:response.request.body.data, result:response.request.result}))
      
                  const result = JSON.parse(response.request.result);
                  
                  if(result && result.total_records && result.total_records >= 0) {
                     totalPages = Math.ceil(result.total_records / 20);
                     orders = orders.concat(result.result);
                  }
                  return  orders;
               } catch(error) {
                  console.error(error);
                  throw error;
               }
            }
         }
         
         protected static async makeFetchOrdersRequest(): Promise<ECommerceResponse> {
            let eCommerceResponse: ECommerceResponse = {
               success: false
            }
            
            let token = await this.getToken();
            if(posUtils.isBlank(token)) {
               return eCommerceResponse;
            }
            
            try {
               const orderStatus = CashCowService.isJ5Payment() ? 7 : 4
               let totalOrders = (await CashCowService.getOrderListByStatus(token, orderStatus))
               eCommerceResponse.success = true
               eCommerceResponse.data = totalOrders
               return eCommerceResponse
            } catch (error) {
               console.error(error)
               return eCommerceResponse;
            }

            
         }

         protected static async updateOrderStatus(orderId, newStatus): Promise<ECommerceResponse> {
            //TODO: update order status after cashcow develop
            return {success: true}
         }

         private static async filterAlreadyReceivedOrders(orders:Array<any>) {
            if(this.receivedOrders.length == 0) {
               let response = await FetchReq.jsonReq(`/external_orders/get_orders_last_days?orderOrigin=${Delivery.ExternalOrderOrigin.CashCow}&daysAgo=8`, 'get');
               if(response && response.result) {
                  this.receivedOrders = response.result;
               }
            }

            return orders.filter(order => !this.receivedOrders.includes(order.Id));
         }

         private static async addOrderToReceivedList(id:number) {            
            this.receivedOrders.push(id)
         }

         protected static async getVoucherForPayment(possibleVouchers:Array<Storage.Entity.Voucher>, sale:Storage.Entity.Sale, order:Storage.Entity.ExternalOrder)
         : Promise<Storage.Entity.Voucher> {
            return possibleVouchers.find(voucher => voucher.typeID == jsonConfig.getVal(jsonConfig.KEYS.cashCowVoucherNumber))
         }
         
         protected static async convertApiOrderToPosExternalOrder(result: any): Promise<Array<PositiveTS.Storage.Entity.ExternalOrder>> {
            let externalOrdersArray = [];
            
            if(!result){
               return externalOrdersArray
            }

            let apiGenericItem = jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)
            result = await this.filterAlreadyReceivedOrders(result);

            ordersLoop: for (let apiOrder of result) {

               let externalOrder = new Storage.Entity.ExternalOrder()
               const isBlankStreetNameAndNumber = posUtils.isBlank(apiOrder.StreetNameAndNumber)
               externalOrder.orderNum = posVC._getNewOrderNumber()
               externalOrder.totalAmount = apiOrder.TotalPrice
               externalOrder.deliveryType = apiOrder.IsSelfDelivery ? Service.Delivery.DeliveryType.ta : Service.Delivery.DeliveryType.delivery
               externalOrder.orderOrigin = Service.Delivery.ExternalOrderOrigin.CashCow
               externalOrder.orderType = Service.Delivery.ExternalOrderType.SaleItemsFormat
               externalOrder.externalOrderId = apiOrder.Id
               externalOrder.date = moment(apiOrder.OrderDate).toDate().toString()
               externalOrder.status = Service.Delivery.ExternalOrderStatus.New
               externalOrder.customerName = `${apiOrder.FirstName} ${apiOrder.LastName}`
               externalOrder.phone = apiOrder.Phone
               externalOrder.street = isBlankStreetNameAndNumber ? apiOrder.Address : apiOrder.StreetNameAndNumber
               externalOrder.city = isBlankStreetNameAndNumber ? '' : apiOrder.City
               externalOrder.floor = isNaN(apiOrder.FloorNumber) ? 0 : Number(apiOrder.FloorNumber);
               externalOrder.apartmentNumber = apiOrder.ApartmentNumber
               externalOrder.remarks = apiOrder.CustomerInstructions
               externalOrder.houseNumber = null;
               externalOrder.syncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT
               externalOrder.paid = CashCowService.isJ5Payment() || apiOrder.OrderStatus == 1 ? false : true
               externalOrder.isPhoneOrder = apiOrder.OrderStatus == 1;
               externalOrder.saleItems = []
               externalOrder.paymentData = JSON.stringify(CashCowService.generatePaymentData(apiOrder, externalOrder.orderNum))

               let items = apiOrder.Products;
               let itemsCount = 0;
               for (let orderItem of items) {
                  let itemAndBarcode = null;
                  let itemBarcodes = [];

                  if(orderItem.Attributes && orderItem.Attributes.length > 0 && !posUtils.isBlank(orderItem.Attributes[0].SKU)) {
                     itemBarcodes = await (new PositiveTS.Storage.Entity.Item()).searchByBarcode(orderItem.Attributes[0].SKU)
                  }

                  if(itemBarcodes.length < 1) {
                     let item = session.allItems.get(orderItem.sku);
                     if(!posUtils.isBlank(item)) {
                        itemAndBarcode = { item: item, itemBarcode: {barcode: null, code: item.code, color: null, size: null} }
                     }
                  } else {
                     itemAndBarcode = itemBarcodes[0];
                  }

                  if(posUtils.isBlank(itemAndBarcode)) {
                     let item = session.allItems.get(apiGenericItem);
                     if(!posUtils.isBlank(item)) {
                        itemAndBarcode = { item: item, itemBarcode: {barcode: null, code: item.code, color: null, size: null} }
                     }
                  }

                  if (!posUtils.isBlank(itemAndBarcode)) {
                     let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(itemAndBarcode.item, itemAndBarcode.itemBarcode);
                     saleItem.quantity = orderItem.Qty;
                     saleItem.unitPrice = orderItem.Total / orderItem.Qty;
                     saleItem.originalUnitPrice = orderItem.price_before_discount / orderItem.Qty;
                     saleItem.rowNumber = itemsCount++;
                     saleItem.priceNetoAfterDiscounts = orderItem.Total;
                     if(itemAndBarcode.item.code == apiGenericItem) {
                        saleItem.itemDescription = orderItem.Name
                     }

                     externalOrder.saleItems.push(saleItem);
                  }
                  else {
                     console.error(`Error adding sale item, e-commerce server:${this.apiName}\n Item missing ID: ${orderItem.sku}`)
                     continue ordersLoop
                  }

               }

               if(apiOrder.ShipingPrice > 0) {
                  let deliveryItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));   
                  deliveryItem.priceZarhan = Number(apiOrder.ShipingPrice)
                  let deliveryItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(deliveryItem, {size:'null',color:'null',barcode:null});
                  deliveryItm.priceNetoAfterDiscounts = deliveryItem.priceZarhan
                  externalOrder.saleItems.push(deliveryItm)
               }

               if (apiOrder.FeePrice > 0) {
                  let feeItem = session.allItems.get(apiGenericItem)
                  feeItem.priceZarhan = parseFloat(apiOrder.FeePrice)
                  let feeItm = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(feeItem, {size:'null',color:'null',barcode:null})
                  feeItm.priceNetoAfterDiscounts = feeItem.priceZarhan
                  feeItm.itemDescription = i18next.t('cashcow.orderFee')
                  externalOrder.saleItems.push(feeItm)
               }

               externalOrdersArray.push(externalOrder);
               CashCowService.addOrderToReceivedList(Number(externalOrder.externalOrderId));

            }
            return externalOrdersArray;
         }

         protected static generatePaymentData (order, externalOrderNum) {
            
            let paymentData: any = {}
            let queryResponseObject = order.FullResponse ? JSON.parse(order.FullResponse) : ''
            if (queryResponseObject){
               paymentData = posUtils.objectFromUrlSearchParams(queryResponseObject.query)
               paymentData.Email = order.Email
               paymentData.FirstName = order.FirstName
               paymentData.LastName = order.LastName
               paymentData.Phone = order.Phone
               paymentData.externalOrderNum = externalOrderNum
            }
            
            return paymentData
         }

         public static async soft (saleItems: Array<Storage.Entity.SaleItem>, saleAmount: number, paymentData) {
            let heshDesc = ''
            if (saleItems) {
               heshDesc = Service.YaadService.itemsToHeshDesc(saleItems)
            }

            let res = await Service.YaadService.Soft(paymentData.Id, heshDesc, saleAmount, 
               paymentData.Order, paymentData.UserId, paymentData.FirstName,
               paymentData.LastName, paymentData.Phone, paymentData.Email)
            let resOb: any = posUtils.objectFromUrlSearchParams(res)
            
            return {success: resOb.CCode == '0', errorCode: resOb.CCode && resOb.CCode != '0' ? resOb.CCode : null, data: resOb}
         }
         
         public static async updateOrder(params: updateOrderParams): Promise<ECommerceResponse> {
            const url = `${this.baseUrl}Stores/SendOrderUpdate`
            params = {...params,...{store_id: await this.getCashCowStoreId(), token: await this.getToken()}}
            const data = JSON.stringify({
               "body": JSON.stringify(params),
               "type": "POST",
               "url": url,
               "headers": JSON.stringify({
                  "accept": "application/json",
                  "Content-Type": "application/json",
              })
            })

            let response = await GenericAddon.sendNativeMessageToExtension({data},"external_service")
            Service.Filelog.log("cashcow",
                        JSON.stringify({request:response.request.body.data, result:response.request.result}))
            let result = posUtils.jsonParse(response.request.result)
            
            return {success: result && result.is_updated != null ? !!result.is_updated : false, data: result}
         }

         public static async cancelOrder(order: Storage.Entity.ExternalOrder){
            let paymentData = JSON.parse(order.paymentData);
            if(!posUtils.isBlank(paymentData) && !posUtils.isBlank(paymentData.Email)) {
               if(order.paid) {
                  if(!posUtils.isBlank(paymentData.Id)) {
                     let yaadResponse = await Service.YaadService.cancelTransaction(paymentData.Id);
                     if(!yaadResponse.success && Number(yaadResponse.CCode) != 920) {
                        throw Error(`Yaad Error ${yaadResponse.CCode}`);
                     }
                  }
               }

               //update cashcow order
               const resCashCow = await PositiveTS.Service.CashCowService.updateOrder({
                  order_id: order.externalOrderId,
                  email_address: paymentData.Email,
                  order_status_type: 8,
               }) 
            }
            await PositiveTS.Service.FetchReq.jsonReq(`/external_orders/${order.remoteDBId}`, 
            'put', {status: Service.Delivery.ExternalOrderStatus.Deleted});

            if(order.paid && !posUtils.isBlank(order.saleInvoiceSequence)) {
               const saleProxyOptions = {
                  type: PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
                  store_id: session.pos.storeID,
                  pos_device_id: session.pos.deviceID,
                  invoice_number: order.saleInvoiceSequence,
                  invoice_type: Storage.Entity.Sequence.TYPE_DEBIT_INVOICE
               };
               
               let saleProxy = new PositiveTS.Service.SaleProxy(saleProxyOptions);
               
               //create credit sale
               let saleObj = (await saleProxy.loadAllLevels())[0];
               if(!posUtils.isBlank(saleObj)) {
                  let saleItems = saleObj.items.map((item) => PositiveTS.Service.CreditInvoiceUtils.createCreditSaleItem(item, item.quantity, saleObj));
                  let creditedPayments = saleObj.payments
                  let entitySequenceType = PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE;
                  let creditedSale = await PositiveTS.Helper.SaleHelper.creditSale(saleObj, saleItems, creditedPayments, entitySequenceType);
                  creditedSale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
                  creditedSale.syncLastMessage			= 'Sale added to queue successfully';
                  creditedSale.syncLastMessageTimestamp	= PositiveTS.DateUtils.fullFormat();
                  await Service.FullSale.persist(creditedSale,saleItems,creditedPayments);
                  await Service.HoldSale.cancelBonItemsIfRequired(saleItems,true);
                  Printing.Invoice.printInvoice(creditedSale, saleItemHelper.unflattenSaleItems(saleItems), creditedPayments, true);
               }
            }
         }

         public static async cancelPayment(sale:Storage.Entity.Sale) {
            let saleData   = JSON.parse(sale.jsondata);
            let paymentId;
            let paymentData;
            paymentData = JSON.parse(saleData.delivery.paymentData);

            if(saleData.delivery.j5) {
               let voucherData = JSON.parse(sale.payments[0].data)[0];
               paymentId = voucherData.barCode;
            } else {
               paymentId = paymentData.Id;
            }

            if(posUtils.isBlank(paymentId)) {
               throw Error("no PaymentData ID found")
            }

            let yaadResponse = await Service.YaadService.cancelTransaction(paymentData.Id);
            if(!yaadResponse.success  && Number(yaadResponse.CCode) != 920) {
               throw Error(`Yaad Error ${yaadResponse.CCode}`);
            }

            //update cashcow order
            const resCashCow = await PositiveTS.Service.CashCowService.updateOrder({
               order_id: saleData.delivery.externalOrderId,
               email_address: paymentData.Email,
               order_status_type: 8,
            })

            let remoteDbId = saleData.delivery.remoteDBId;
            if(posUtils.isBlank(remoteDbId) || remoteDbId == 0) {
               let response = await PositiveTS.Service.FetchReq.jsonReq(
                  `/external_orders/get_order_by_origin?orderOrigin=${saleData.delivery.orderOrigin}&externalOrderId=${saleData.delivery.externalOrderId}`, 'get');   
               remoteDbId = response.result.remoteDBId;
            }

            await PositiveTS.Service.FetchReq.jsonReq(`/external_orders/${remoteDbId}`, 
            'put', {status: Service.Delivery.ExternalOrderStatus.Deleted});
         }
      }
   }
}
