module PositiveTS {
  export module Dialogs {
    export class AdvancedItemSearchAutoselectUI {
      private internal;
      constructor(internalObj) {
        this.internal = internalObj;
    
        this.internal.selectionChange = (selectionSelector, internalHandler) => {
          if (internalHandler.selectedItem && internalHandler.selectedItem.valu) {
           this.internal.ctrl.dictUi[selectionSelector].selected = internalHandler.selectedItem.valu;
         } else {
           this.internal.ctrl.dictUi[selectionSelector].selected  = undefined;
         }
         
        }
    
    
    
        this.internal.bindAutoCompleteDelKey = (selectionAutoComplete) => {
          $(selectionAutoComplete).unbind("keyup");
          $(selectionAutoComplete).keyup(function(){
              if(! $(this).val()) {
                this.internal[ this.internal.uiInputs["#"+$(this).attr("id")].internalVarName].selectedItem = undefined;
                $("#AdvancedItemSearch-ItemCode" ).trigger("change");
              }
          }); 
        }
      
        this.internal.saveSelectionValuesOnChange = () => {
          for (var key in this.internal.uiInputs){
            if (this.internal.uiInputs[key].isAutoComplete) {
              this.internal.selectionChange(key, this.internal[ this.internal.uiInputs[key].internalVarName]);
            }
          }
        }
      
      
      }
    
      
      public saveSelectionValuesOnChange() {
        this.internal.saveSelectionValuesOnChange();
      }
    
      public bindAllUiSelectionDelKey(){
      for (var key in this.internal.uiInputs){
        if (this.internal.uiInputs[key].isAutoComplete) {
          this.internal.bindAutoCompleteDelKey(key);
        }
      }
      };
    
      public bindAllUiSelection = function(){
      for (var key in this.internal.uiInputs){
          if (this.internal.uiInputs[key].isAutoComplete) {
          
          var control = this.internal.uiInputs[key];
          var dataArray = control.internalDataArrayName;
          this.internal[ control.internalVarName] = new UI.AutoComplete({
                                dataArray:this.internal[dataArray], 
                                selectorField: key,
                                onChange: this.internal.chnageTrigger
                              }); 
          
          }
        }  
      }
    }
    
        
  }
}

