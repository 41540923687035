module PositiveTS {
export module Storage {
export module Entity {
export class PosUpsale extends WasmEntity {

	constructor() {
		let meta = {
			name: 'PosUpsale',
			fields: {
				upsaleId: "INT",
				posDeviceId: "TEXT",
			},
		};
		super(meta)
	}
}}}}
