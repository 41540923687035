
// use: $("div container selector").positiveRadioSelect();
(function( $ ){

	var methods = {
		init : function( options ) {

			var defaultOptions = {
				fieldSelector: '.positive-radio-option',
				selectedClass: 'selected',
				changed: function (newVal, field) { }
			};

			return this.each(function () {
				var settings = this.settings = $.extend(defaultOptions, options);

				var $this = $(this);

				var $fields = $this.find(settings.fieldSelector);

				$fields.click(function () {
					$fields.removeClass(settings.selectedClass);
					$(this).addClass(settings.selectedClass);
					settings.changed.apply($this, [$(this).attr("value"), this]);
				});
			});
		},

		select: function (value) {
			return this.each(function () {
				let $this = $(this);

				var settings = this.settings;

				var $fields = $this.find(settings.fieldSelector);

				$fields.removeClass(settings.selectedClass);

				var $fieldToSelect = $this.find(settings.fieldSelector + '[value=' + value + ']');
				$fieldToSelect.addClass(settings.selectedClass);
				settings.changed(value, $fieldToSelect);
			});
		},

		getValue: function () {
			var $this = this.first();

			var settings = null;

			this.each(function () {
				settings = this.settings;
			});

			var $selectedField = $this.find(settings.fieldSelector + '.' + settings.selectedClass);
			return $selectedField.val();
		},

		destroy: function () {
			return this.each(function () {
				var settings = this.settings;

				if (settings === null || settings === undefined) {
					return;
				}

				var $this = $(this);

				var $fields = $this.find(settings.fieldSelector);

				$fields.unbind('click');
			});
		}
	};

	$.fn.positiveRadioSelect = function( method ) {
		if ( methods[method] ) {
			return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
		} else if ( typeof method === 'object' || ! method ) {
			return methods.init.apply( this, arguments );
		} else {
			$.error( 'Method ' +  method + ' does not exist on rubysoft.positiveRadioSelect' );
		}
	};
})( jQuery );