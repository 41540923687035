module PositiveTS {
export module Storage {
export module Entity {
export class Upsale extends WasmEntity {

	constructor() {
		let meta = {
			name: 'Upsale',
			fields: {
				upsaleId: "INT",
				textToShow: "TEXT",
				sort: "INT",
				singleItemDisplay: "BOOL",
				translations: "TEXT",
			},
		};
		super(meta)
	}


	static getCurrentPosSordedUpsalesWithItems() {
		let upsales = this.execAndConvertResult(`
			SELECT Upsale.* FROM Upsale
			INNER JOIN PosUpsale 
			ON PosUpsale.upsaleId = Upsale.upsaleId
			WHERE PosUpsale.posDeviceId = '${session.pos.deviceID}'
			ORDER BY sort
		`);

		let upsalesWithItemProp = ItemUpsale.addItemsToUpsales(upsales);

		return upsalesWithItemProp.filter(u => u.itemUpsales.length > 0);
	}
}}}}
