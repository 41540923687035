/*
* decaffeinate suggestions:
* DS101: Remove unnecessary use of Array.from
* DS102: Remove unnecessary code created because of implicit returns
* DS207: Consider shorter variations of null checks
* Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
*/
/*
The channel object is returned when you subscribe to a channel.

For instance:
var dispatcher = new WebSocketRails('localhost:3000/websocket');
var awesome_channel = dispatcher.subscribe('awesome_channel');
awesome_channel.bind('event', function(data) { console.log('channel event!'); });
awesome_channel.trigger('awesome_event', awesome_object);
*/
module WebSocketRails {
  export class Channel {
    name:string
    private _dispatcher:Dispatcher
    is_private:boolean
    on_success:any
    on_failure:any
    private _callbacks:any
    private _token:any
    private _queue:any
    private connection_id:string

    constructor(name, _dispatcher, is_private, on_success, on_failure) {
      let event_name;
      this._success_launcher = this._success_launcher.bind(this);
      this._failure_launcher = this._failure_launcher.bind(this);
      this.name = name;
      this._dispatcher = _dispatcher;
      if (is_private == null) { is_private = false; }
      this.is_private = is_private;
      this.on_success = on_success;
      this.on_failure = on_failure;
      this._callbacks = {};
      this._token = undefined;
      this._queue = [];
      if (this.is_private) {
        event_name = 'websocket_rails.subscribe_private';
      } else {
        event_name = 'websocket_rails.subscribe';
      }
      
      this.connection_id = this._dispatcher._conn != null ? this._dispatcher._conn.connection_id : undefined;
      const event = new Event( [event_name, {data: {channel: this.name}}, this.connection_id], this._success_launcher, this._failure_launcher);
      this._dispatcher.trigger_event(event);
    }
    
    destroy() {
      if (this.connection_id === (this._dispatcher._conn != null ? this._dispatcher._conn.connection_id : undefined)) {
        const event_name = 'websocket_rails.unsubscribe';
        const event =  new Event( [event_name, {data: {channel: this.name}}, this.connection_id] );
        this._dispatcher.trigger_event(event);
      }
      return this._callbacks = {};
    }

    unbind(event_name) {
      this._callbacks[event_name] = [];
    }
    
    bind(event_name, callback) {
      if (this._callbacks[event_name] == null) { this._callbacks[event_name] = []; }
      return this._callbacks[event_name].push(callback);
    }
    
    trigger(event_name, message) {
      const event = new Event( [event_name, {channel: this.name, data: message, token: this._token}, this.connection_id] );
      if (!this._token) {
        return this._queue.push(event);
      } else {
        return this._dispatcher.trigger_event(event);
      }
    }
    
    dispatch(event_name, message) {
      if (event_name === 'websocket_rails.channel_token') {
        this.connection_id = this._dispatcher._conn != null ? this._dispatcher._conn.connection_id : undefined;
        this._token = message['token'];
        return this.flush_queue();
      } else {
        if (this._callbacks[event_name] == null) { return; }
        return Array.from(this._callbacks[event_name]).map((callback:any) => callback(message));
      }
    }
    
    // using this method because @on_success will not be defined when the constructor is executed
    _success_launcher(data) {
      if (this.on_success != null) { return this.on_success(data); }
    }
    
    // using this method because @on_failure will not be defined when the constructor is executed
    _failure_launcher(data) {
      if (this.on_failure != null) { return this.on_failure(data); }
    }
    
    flush_queue() {
      for (let event of Array.from(this._queue)) {
        this._dispatcher.trigger_event(event);
      }
      return this._queue = [];
    }
  }
}