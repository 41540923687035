module PositiveTS {
  export module Components {
    export module ValuecardPointsDialog {
      const dialogSelector = "#valuecard-points-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function initData() {
        return {
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          noCustomersFound: false,
          valuecardPoints: 0,
          customerMessage: "",
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          isSimpleSelfService: jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService),
          fields: initFields(),
          customerInfo: null,

        }
      }

      function initFields() {
        let fields = {
          'valuecardPoints': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'valuecardPoints'
          }
        }
        return fields;
      }

      export function create() {

        let component = {
          template: JST.valuecardPointsDialog(),
          mixins: [keyPadMixin],
          components: {
            selfServiceKeypad: SelfServiceKeypad.getComponent(),
            positiveIcon: PositiveIcon.getComponent(),

          },
          methods: {
            cleanData: cleanData,
            open(originalValuecardPoints = null, customerInfo = null) {
              return new Promise((resolve, reject) => {
                this.customerInfo = customerInfo;
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
                if(customerInfo.prepaidBalance === 0 && customerInfo.totalPoints === 0){
                  this.confirmAndClose();
                  return;
                }
                this.$el.showModal();

                if (originalValuecardPoints != null) {
                  this.valuecardPoints = originalValuecardPoints
                }
                this.customerMessage = customerInfo.message.trim();
                $(document).unbind('keypress');


              })
            },
            close() {
              this.$el.close();
              this.cleanData();
              if(this.$refs.selfServiceKeypad){
                this.$refs.selfServiceKeypad.clear();
              }
              $(document).unbind('keypress');
              $(document).keypress(posVC.onKeyPress);
            },
            confirmAndClose() {
              var valuecardPoints = Number(this.valuecardPoints);

              if (PositiveTS.Service.ValuecardPointsDialog.pointsAmountValidation(valuecardPoints)) {
                this.resolveFunc({ approved: true, valuecardPoints: valuecardPoints })
                this.close();
              } else {
                this.showError(i18next.t("valueCardCustClub.invalidValuecardPoints"))
              }


            },
            cancelTapped() {
              this.resolveFunc({ approved: false, valuecardPoints: null })
              this.close();
            },
            onKeyTapped(key) {
              this.keyPadHanlder(key, this.fields["valuecardPoints"], this, 3);
            },
            showError(errStr) {
              return app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              })
            },
            showGenericError() {
              app.showAlertDialog({
                header: i18next.t('error'),
                content: "שגיאה כללית",
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                null, null)
            },
          },
          data: initData,
          computed: {
            saleHasItems() {
              return  Pinia.globalStore.saleItems.length != 0;
            }
          }
        }

        VueApp.component('valuecard-points-dialog', component)

      }
    }
  }
}
