module PositiveTS {
    export module Components {
        export module showCustomerSalesReportDialog {
            const dialogSelector = "show-customer-sales-report-dialog"

            export function create() {
                let component = {
                    template: JST.showCustomerSalesReportDialog(),
                    props: {
                        startDate: {
                            type: Date
                        },
                        endDate: {
                            type: Date
                        },
                        bonusPercent: {
                            type: Number,
                            default: 0
                        },
                        selectedCustomer: {
                            type: Object || null,
                        }
                    },
                    computed: {
                        posCustomersReportByHakafaCustomers: () => jsonConfig.getVal(jsonConfig.KEYS.posCustomersReportByHakafaCustomers),
                        title: () => jsonConfig.getVal(jsonConfig.KEYS.posCustomersReportByHakafaCustomers) ? i18next.t('customerSalesReportDialog.titleHakafaCustomers') : i18next.t('customerSalesReportDialog.titleCustomers'),
                        mobileLayout(){
                            return Pinia.globalStore.mobileLayout
                        },
                    },
                    data() {
                        return {
                            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
                            urlPrefix: '/customer_sales',
                            storeName: session?.store?.storeName,
                            reports: [],
                            totalSalesSumAmount: 0,
                            store: session?.store?.storeName
                        }
                    },
                    methods: {
                        async open() {
                            if (this.dialogElem == null) {
                                this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                            }

                            try {
                                app.showLoadingMessageDialog(i18next.t('customerSalesReportDialog.loadingReport'))
                                this.reports = await this.getReportFromServer()
                                this.totalSalesSumAmount = this.calculateSalesSumAmount()
                                this.dialogElem.showModal()


                            } catch (e) {
                                console.error(e)
                                this.showGeneralError()
                            } finally {
                                app.hideLoadingMessageDialog()
                            }
                        },
                        close() {
                            this.dialogElem.close()
                        },
                        async getReportFromServer() {
                            let fromDate = this.startDate.toJSON();
                            let toDate = this.endDate.toJSON();
                            let reports = []

                            let url = `${this.urlPrefix}/?from_date=${fromDate}&to_date=${toDate}&byHakafaCustomers=${Boolean(this.posCustomersReportByHakafaCustomers)}`
                            if (this.selectedCustomer) {
                                url += `&customer_id=${this.selectedCustomer.id}`
                            }
                            let res = await PositiveTS.Service.FetchReq.jsonReq(url, 'get')

                            if (res.response.ok) {
                                reports = res.result.sales
                            }

                            return reports
                        },
                        printReport() {
                            PositiveTS.Printing.Reports.printCustomerSalesReport(this.reports,
                                { fromDate: this.startDate, toDate: this.endDate }, this.selectedCustomer, this.bonusPercent, this.posCustomersReportByHakafaCustomers)
                        },

                        calculateSalesSumAmount() {
                            let amount = 0
                            if (this.reports.length > 0) {
                                this.reports.map(sale => {
                                    amount += sale.amount
                                })
                            }
                            return amount
                        },
                        showGeneralError() {
                            app.showAlertDialog({
                                header: i18next.t('error'),
                                content: i18next.t('generalError'),
                                continueButtonText: i18next.t("ok"),
                                hideCancelButton: true
                            }, null, null);
                        }
                    },
                }
                VueApp.component('show-customer-sales-report-dialog', component)
            }
        }
    }
}
