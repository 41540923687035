export default class PicturesPrinting {
    static canvasId = 'pictures-printer-canvas';
    static contentId = 'pictures-printer-content';
    static placeHolderId = null;

    static printersWidth = {
        default: '420px',
        ibm: '420px',
        sunmiv1: '385px',
        sunmi: '575px',
        sunmik1: '575px',
    }

    static printerSpacing = {
        default: 100,
        ibm: 100,
        sunmiv1: 50,
        sunmi: 0,
    }

    // change to true for easy debugging
    static isDebugging() {
        return window.localStorage.getItem("picturePrintDebug") == "true";
    }

    static init(printerType = null, placeHolderId = 'pictures-printing-shadow-placeholder') {
        this.placeHolderId = placeHolderId;
        let shadowPlaceholder = document.getElementById(this.placeHolderId);

        if (!shadowPlaceholder) {
            console.error("NO PRINTING PLACEHOLDER, PLEASE CHECK!");

            return;
        }

        let shadow = this.isDebugging() ? shadowPlaceholder : shadowPlaceholder.attachShadow({ mode: 'open' });

        if (this.isDebugging()) {

        let canvas = document.createElement('canvas');
        canvas.setAttribute('id', this.canvasId);

        let content = document.createElement('div');
        content.setAttribute('id', this.contentId);
        content.style.width = this.printersWidth[printerType] || this.printersWidth.default;

            (<HTMLElement>shadow).style.height = 'auto';
            (<HTMLElement>shadow).style.top = '100%';
            (<HTMLElement>shadow).style.overflow = 'initial';

            content.style.position = 'absolute';
            content.style.top = '0';
            content.style.left = '0';
            content.style.zIndex = '99999';

            canvas.style.position = 'absolute';
            canvas.style.top = '0';
            canvas.style.right = '0';
            canvas.style.zIndex = '99999';

            shadow.appendChild(canvas);
            shadow.appendChild(content);

        }
    }

    static async convertHtmlToBase64Img(html: string, printerType = null, removeGrayFromImage = false, ratio = 1, width = null): Promise<string> {

        let timestamp = Date.now();
        let placeHolderIdToUse = this.placeHolderId;
        let canvasIdToUse = this.canvasId;
        let contentIdToUse = this.contentId;

        if (!this.isDebugging()) {
            // Clone the nodes
            let timeStampAddition = '-' + timestamp.toString();
            let placeHolder = document.getElementById(placeHolderIdToUse);
            let placeHolderClone = placeHolder.cloneNode(true) as HTMLElement;
            placeHolderClone.id = placeHolderClone.id + timeStampAddition;
            placeHolder.parentElement.appendChild(placeHolderClone);
            
            placeHolderIdToUse += timeStampAddition;
            canvasIdToUse += timeStampAddition;
            contentIdToUse += timeStampAddition;

            let canvas = document.createElement('canvas');
            canvas.setAttribute('id', canvasIdToUse);

            let content = document.createElement('div');
            content.setAttribute('id', contentIdToUse);
            content.style.width = width || this.printersWidth[printerType] || this.printersWidth.default;

            let shadow = placeHolderClone.attachShadow({ mode: 'open' });

            shadow.appendChild(canvas);
            shadow.appendChild(content);

        }


        // Fix stupid fucking bug caused by god himself cause he hates me
        // The bug injects &rlm; and similar chars at random places and fucks up the dom
        html = html.replace(/\u200f|\u001b\!\u0008|\u0019|\u001b|\u0008|\u001d|\u000e|\u000f/g, '');

        let shadowElement = this.isDebugging() ? document : document.getElementById(placeHolderIdToUse).shadowRoot;
        let canvas = <HTMLCanvasElement>shadowElement.getElementById(canvasIdToUse);
        let content = shadowElement.getElementById(contentIdToUse);

        content.innerHTML = html;

        let base64 = await this.renderHtmlToCanvasAndGetBase64Img(html, 0, 0, content.offsetWidth, content.offsetHeight + (this.printerSpacing[printerType] || 0), canvas, removeGrayFromImage, ratio);

        if (!this.isDebugging()) {
            canvas.remove();
            document.getElementById(placeHolderIdToUse).remove();
        }

        return base64;
    }

    static renderHtmlToCanvasAndGetBase64Img(html, x, y, width, height, canvas, removeGrayFromImage = false, ratio = 1): Promise<string> {
        return new Promise((resolve, reject) => {
            var xml = this.convertHtmlToXml(html);
            xml = xml.replace(/\#/g, '%23');
            var data = "data:image/svg+xml;charset=utf-8," + '<svg xmlns="http://www.w3.org/2000/svg" width="' + width + '" height="' + height + '">' +
                '<foreignObject width="100%" height="100%">' +
                '<style>body{margin: 0;}</style>' +
                xml +
                '</foreignObject>' +
                '</svg>';

            canvas.width = width * ratio;
            canvas.height = height * ratio;   
            let ctx = canvas.getContext('2d'); 

            var img = new Image();
            img.onload = function () {
                if (canvas.clientHeight <= 0) {
                    return resolve(null);
                }
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(canvas, 0, 0);

                ctx.drawImage(img, x, y, canvas.width, canvas.height);

                if (removeGrayFromImage) {
                    PicturesPrinting.removeGrayFromCanvas(canvas, ctx)
                }

                let base64 = canvas.toDataURL();

                if (PicturesPrinting.isDebugging() || window.localStorage.getItem("alwaysLogPrinterPictureBase64Image") == "true") {
                    console.log(base64);
                }

                resolve(base64)
            }

            img.onerror = function (err) {
                console.log('error converting html to base64 image', err);
                console.log(data);
                reject(err);
            }

            img.src = data;

        })
    }

    static removeGrayFromCanvas(canvas, ctx) {
        let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);

        for(let y = 0; y < pixels.height; y++){
            for(let x = 0; x < pixels.width; x++){
                //here is x and y are multiplied by 4 because every pixel is four bytes: red, green, blue, alpha
                let index = (y * 4) * pixels.width + x * 4;
                let avg = (pixels.data[index] + pixels.data[index + 1] + pixels.data[index + 2]) / 3;
                let newVal = avg > 180 ? 255 : 0;
            
                //set values to array
                pixels.data[index] = newVal; 
                pixels.data[index + 1] = newVal; 
                pixels.data[index + 2] = newVal;
            }
        }

        ctx.putImageData(pixels, 0, 0, 0, 0, pixels.width, pixels.height); 
    }

    static convertHtmlToXml(html) {
        var doc = document.implementation.createHTMLDocument('');

        doc.write(html);

        // You must manually set the xmlns if you intend to immediately serialize     
        // the HTML document to a string as opposed to appending it to a
        // <foreignObject> in the DOM
        doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI);

        // Get well-formed markup
        html = (new XMLSerializer).serializeToString(doc.body);
        return html;
    }
}
