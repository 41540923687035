/* globals $ */
$(function() {
  (<any>window).OptionSelectUI = $( "#OptionSelect-dialog" ).dialog({
      autoOpen: false,
      modal: true,
      dialogClass: 'positive-dialog',
      width: '90%',
      height: 500,
      resizable: false,
      closeOnEscape: false,
      draggable: false,
    });

    (<any>window).OptionSelectUI.resetUi = function(){
      $('#OptionSelect-dialog-cancel').unbind('click');
      $('#OptionSelect-dialog-continue').unbind('click');
      $('#OptionSelect-dialog-cancel').show();
      $('#OptionSelect-dialog-continue').show();
  };
});

declare var OptionSelectUI: { dialog: (arg0: string) => void; resetUi: () => void; };
