module PositiveTS {

    export module Service {

        export module MultiCurrencyPayments {

            export function getCurrentCashBySalesAndCurrencies(sales: Storage.Entity.Sale[]): any {

                return this.getPaymentsBySalesAndCurrencies(sales)[Storage.Entity.SalePayment.METHOD_CASH]
                    || {
                        creditCount: {},
                        count: 0,
                        currencies: {},
                    };

            }

            export function getPaymentsBySalesAndCurrencies(sales: Storage.Entity.Sale[]): any {

                let allPaymentDataByMethod = {};
                for (let sale of sales) {

                    for (let payment of sale.payments) {
                        let paymentMethod = payment.method;
                        let isChange = false;
                        let isCreditCard = paymentMethod == Storage.Entity.SalePayment.METHOD_CREDIT;

                        if (paymentMethod == Storage.Entity.SalePayment.METHOD_CHANGE) {
                            paymentMethod = Storage.Entity.SalePayment.METHOD_CASH;
                            isChange = true;
                        }

                        allPaymentDataByMethod[paymentMethod] = allPaymentDataByMethod[paymentMethod] || {
                            creditCount: 0,
                            count: 0,
                            amount: 0,
                            currencies: {},
                        }

                        let currentPaymentData = JSON.parse(payment.data);
                        if (Array.isArray(currentPaymentData) && currentPaymentData.length > 0) {
                            for (let currencyPayment of currentPaymentData) {
                                allPaymentDataByMethod[paymentMethod] = addCurrencyPayment(allPaymentDataByMethod[paymentMethod], convertCurrencyPaymentToStandard(currencyPayment, paymentMethod), isChange, isCreditCard);
                            }
                        } else if (payment.method != Storage.Entity.SalePayment.METHOD_CREDIT) {
                            allPaymentDataByMethod[paymentMethod] = addCurrencyPayment(allPaymentDataByMethod[paymentMethod], getBaseCurrencyPaymentData(payment.amount), isChange, isCreditCard);
                        }

                    }

                }
                return allPaymentDataByMethod;

            }
            function addCurrencyPayment(allPaymentData, currentPaymentData, isChange = false, isCreditCard = false) {

                let negativeIfChange = isChange ? -1 : 1;
                if (!allPaymentData.currencies) {
                    allPaymentData.currencies = {};
                }

                if (!allPaymentData.currencies[currentPaymentData.paid_currency_symble]) {
                    allPaymentData.currencies[currentPaymentData.paid_currency_symble] = {
                        amount: 0,
                        creditCount: 0

                    };

                }

                allPaymentData.currencies[currentPaymentData.paid_currency_symble].amount += currentPaymentData.paid_currency_amount * negativeIfChange;

                allPaymentData.amount += currentPaymentData.lead_currency_amount * negativeIfChange;

                if (isCreditCard) {
                    allPaymentData.currencies[currentPaymentData.paid_currency_symble].creditCount++;
                    allPaymentData.creditCount++;
                }

                return allPaymentData;

            }
            export function convertCurrencyPaymentToStandard(currencyPayment, paymentMethod) {

                switch (paymentMethod) {
                    case Storage.Entity.SalePayment.METHOD_CASH:
                    case Storage.Entity.SalePayment.METHOD_CHANGE:
                        return currencyPayment;
                        break;

                    case Storage.Entity.SalePayment.METHOD_CREDIT:
                    let factor = Service.EMV.isNegativeCharge(currencyPayment) ? -1 : 1;
                    let currency = Service.MultiCurr.getInstance().getCurrencySymbolEMV(currencyPayment);
                        return {
                            lead_currency_amount: Service.MultiCurr.getInstance().getCurrrencyPaymentDataBaseCurrencyAmount(currencyPayment) * factor,
                            paid_currency_symble: currency,
                            paid_currency_amount: (currencyPayment.Amount / 100) * factor,
                        }
                        break;

                }
                console.error(`Unsupported money type: ${paymentMethod}`, currencyPayment);

                return {
                    lead_currency_amount: 0,
                    paid_currency_symble: session.pos.baseCurrency,
                    paid_currency_amount: 0,
                };

            }
            function getBaseCurrencyPaymentData(amount: Number) {

                return {
                    lead_currency_amount: amount,
                    paid_currency_symble: session.pos.baseCurrency,
                    paid_currency_amount: amount,
                };
            }
        }
    }
}
