module PositiveTS {
  export module Service {
    interface validate_object {
      valid: boolean, 
      errors: Array<string>
    }
    class IsCreditable {
      
      private itemsAndSalesToCredit;

      constructor (){
        this.itemsAndSalesToCredit = [];
      }


      /**
       * Function that checks if the sale is a debit Invoice and can be credited
       * @param sale 
       * @param itemsAndSalesToCredit 
       * @returns if the sale is valid and can be credited
       */
      isShowCredit (sale: any, itemsAndSalesToCredit: any): validate_object {
        let result = {valid: false, errors: []};

        if (sale.invoiceType === PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE){
          if (itemsAndSalesToCredit.itemsToCredit.length <= 0) {
            result.errors.push(i18next.t('isCreditable.canNotCreditSaleNoItemsAvailableToCredit'));
          }

          if (sale.totalAmount < 0){
            result.errors.push(i18next.t('isCreditable.canNotCreditSaleNoAvailableAmount'));
          }

          if (PositiveTS.Service.PunchCard.isPunchCardSale(sale)){
            result.errors.push(`${i18next.t("isCreditable.canNotCreditSale")} ${i18next.t(`invoiceTypes.${PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE}`)}`);
          }

          result.valid = result.errors.length === 0;
        }

        return result;
      }

      isShowReceiptCredit (sale: any, itemsAndSalesToCredit: any): validate_object {
        let result = {valid: false, errors: []};

        if (sale.invoiceType === PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT){
          if (itemsAndSalesToCredit.itemsToCredit.length <= 0) {
            result.errors.push(i18next.t('isCreditable.canNotCreditSaleNoItemsAvailableToCredit'));
          }

          if (!this._notLinkedToTaxinv(sale)) {
            result.errors.push(i18next.t('isCreditable.canNotCreditSaleReceiptLinkedToTaxInvoice'));
          }

          result.valid = result.errors.length === 0;
        }
        
        return result;
      }

      isMaxInvoiceReturnDaysAllowed(saleDateString:string, forceAllowCredit?): boolean {
        var maxDays: number = 10000;
        if (Boolean(forceAllowCredit)) {
          return true;
        }
        if (!posUtils.isNullOrUndefinedOrEmptyString(session.pos.maxInvoiceReturnDays)) {
          maxDays = Number(session.pos.maxInvoiceReturnDays);
        }
        if (posUtils.isBlank(saleDateString)) {
          saleDateString = (new Date()).toString();
        }
        var invoiceDays: number = this._getInvoiceDays(new Date(saleDateString));
        return invoiceDays <= maxDays;
      }

      async  validateSale (sale) {

        let result =  {valid: false, errors: []};
        let allowToReplaceItemsInReplacementSale = jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)
        try{
          if (posUtils.isNullOrUndefined(sale)) {
            result.errors.push(i18next.t('isCreditable.validateCreditGlobalError'));
            return result;
          }
  
          if (Service.SplitSalePayment.isSplitPaymentSale(sale)) {
            result.errors.push(i18next.t('isCreditable.canNotCreditSplitPaymentSale'));
          }
  
          let isSaleUsedOnChargableCardLoadBulk = await PositiveTS.Service.ChargableCardBulkLoad.checkIfSaleUsedOnChargableCardLoadBulk(sale)
          if (isSaleUsedOnChargableCardLoadBulk.errors.length > 0){
            result.errors.push(...isSaleUsedOnChargableCardLoadBulk.errors)
          }
  
          let isAllowCreditGiftCardSale = await PositiveTS.Service.SmartVoucher.isAllowCreditGiftCardSale(sale);
  
          if (!isAllowCreditGiftCardSale){
            result.errors.push(i18next.t('isCreditable.canNotCreditSaleGiftCardUsed'));
          }
  
          const invoiceTypesAllow: Array<number> = [
            Storage.Entity.Sequence.TYPE_DEBIT_INVOICE, Storage.Entity.Sequence.TYPE_TAX_INV,
            Storage.Entity.Sequence.TYPE_SHIPMENT_INV, Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_INVOICE,
            Storage.Entity.Sequence.TYPE_RECEIPT
          ];

          if (allowToReplaceItemsInReplacementSale) {
            invoiceTypesAllow.push(Storage.Entity.Sequence.TYPE_CREDIT_INVOICE)
          }
          
          if (!invoiceTypesAllow.includes(sale.invoiceType)){
            if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE){
              result.errors.push(i18next.t('isCreditable.canNotCreditSalePunchCard'));
            }else{
              result.errors.push(`${i18next.t("isCreditable.canNotCreditSale")} ${i18next.t(`invoiceTypes.${sale.invoiceType}`)}`);
            }
          }
  
          if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT && sale.totalAmount < 0){
            result.errors.push(i18next.t(`isCreditable.canNotCreditSaleReceiptOnMinus`));
          }
  
          if (!PositiveTS.Reachability.isOnline && !session.pos.hasFlights){
            result.errors.push(i18next.t(`isCreditable.canNotCreditSalePosOffline`));
          }
  
          if (!this._isInvoiceSync(sale) && !session.pos.hasFlights){
            result.errors.push(i18next.t(`isCreditable.canNotCreditSaleNotSync`));
          }
  

          
          
          if (!isCreditable.isMaxInvoiceReturnDaysAllowed(sale.soldAt || sale.createdAt, sale.forceAllowCredit)) {
            result.errors.push(i18next.t(`isCreditable.maxDaysToCreditExceeded`))
          }
  
          if(jsonConfig.getVal(jsonConfig.KEYS.moneyOnlyRefund)) {
            let allSalePaymentsCanBeRefundedWithMoney = sale.payments.filter(payment => payment.method != 0).reduce((canBeRefunded, salePayment) => {
              return canBeRefunded && posCIVC.isPaymentReturnAbleEx(salePayment, sale.createdAt, sale.posDeviceID);
            }, true)
      
            if(!allSalePaymentsCanBeRefundedWithMoney) {
              result.errors.push(i18next.t('posCreditInvoice.cannotBeRefundedWithMoney'));
            }
          }
          
          if(Service.icMega.checkIfSaleIsPaymentByIcMega(sale)) {
            result.errors.push(i18next.t("icMega.canNotCreditIcMega"))
          }

          if (Service.Otot.isOtotActive()) {
            let ototLoadSalePayment = sale.payments.filter(payment => payment.method == Storage.Entity.SalePayment.METHOD_OTOT)[0];

            if (ototLoadSalePayment) {
              let paymentData = JSON.parse(ototLoadSalePayment.data)[0];
              let amountToCancel = paymentData.amount;
              
              try {
                let balances = await await Service.Otot.getFullBalance(paymentData.barCode);

                if (balances.currentAmount < amountToCancel) {
                  result.errors.push(i18next.t('otot.notEnoughBalanceToCredit'));
                }
              } catch (err) {
                result.errors.push(err.message);
              }
            }
          }

          if (result.errors.length === 0) {
             
            const saleProxyOptions = {
              type: 		PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
              store_id: sale.storeID,
              pos_device_id: sale.posDeviceID,
              invoice_number: sale.invoiceSequence,
              invoice_type: sale.invoiceType
            };
             
            let saleProxy = new PositiveTS.Service.SaleProxy(saleProxyOptions);
    
            let res = await saleProxy.loadAllLevels()
  
            if (res.length < 1) {
              result.errors.push(i18next.t('isCreditable.validateCreditGlobalError'));
            }
  
            //remove child items from the calculations...
            res[0].items = res[0].items.filter(item => item.level == null || item.level === 0)
            let validateSaleReceiptOrInvoiceResult = undefined;
            this.itemsAndSalesToCredit = PositiveTS.Helper.SaleHelper.getCreditedItemsAndPaymentsByFullSale(res[0]);
            validateSaleReceiptOrInvoiceResult = this.validateSaleReceiptOrInvoice(sale, this.itemsAndSalesToCredit);
            result.errors = [...result.errors, ...validateSaleReceiptOrInvoiceResult.errors];
  
            if (res[0].childrens != null) {
              let showFlag = false;
              const itemsToCredit = this.itemsAndSalesToCredit.itemsToCredit;
              for (let i = 0; i < itemsToCredit.length; i++) {
                if (itemsToCredit[i].quantity > 0) {
                  showFlag = true;
                  break;
                }
              }
    
              if (!showFlag) {
                result.errors.push(i18next.t('isCreditable.canNotCreditSaleNoItemsAvailableToCredit'));
              }
            }
          }
        }catch(error){
          console.error(error)
          result.errors.push(i18next.t('isCreditable.validateCreditGlobalError'))
        }
       
        result.valid = result.errors.length === 0
        return result;
      }

      private validateSaleReceiptOrInvoice (sale, itemsAndSalesToCredit) {
        let result = {valid: false, errors: []};
        const isShowCredit = this.isShowCredit(sale, itemsAndSalesToCredit);
        const isShowReceiptCredit = this.isShowReceiptCredit(sale, itemsAndSalesToCredit);
        const isTaxInvCreditable = Service.TaxInvCredit.isTaxInvCreditable(sale, itemsAndSalesToCredit);
        const isShipmentInvCreditable = Service.ShipmentInvCredit.isShipmentInvCreditable(sale, itemsAndSalesToCredit);

        result.errors = [
          ...isShowCredit.errors, ...isShowReceiptCredit.errors, 
          ...isTaxInvCreditable.errors, ...isShipmentInvCreditable.errors
        ];

        result.valid = result.errors.length === 0;
        return result;
      }

      private _notLinkedToTaxinv(sale:any){
        return !(sale.jsondata && JSON.parse(sale.jsondata)[PositiveTS.Service.TaxInv.taxInvSeqTag]);
      }

      private _getInvoiceDays(saleDateString: Date): number {
        return Math.abs(moment(saleDateString).diff(moment(new Date()), 'days'));
      }

      private _isInvoiceSync(sale: any): boolean {
        return (sale.syncStatus == PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY)
      }

    }
    export var isCreditable: IsCreditable = new IsCreditable();
  }
}
