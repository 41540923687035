module PositiveTS {
  export module Components {
    export module PosPayment {
      export module TenbisView {


        export function create() {
          var c = {
            template: JST.TenbisView(),
            mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
            methods: {
              selectField: function (fieldName) {
                let refField = null;
                //not liking this code so much... but want to make sure that number will be rounded
                this.amount = session.fixedFloat(this.amount);
                for (let field in this.fields) {
                  this.fields[field].selected = false
                  this.fields[field].inEditMode = true
                  this.fields[field].hasDecimal = false
                  if (field == fieldName) {
                    this.fields[field].selected = true
                    refField = field
                  }
                }
                if (refField != null) {
                  this.$refs[refField].focus();
                }
              },
              onKeyTapped: function (key) {
                let field = this.getSelectedField();
                this.keyPadHanlder(key, field, this);
              },

              getSelectedField: function () {
                for (let fieldName in this.fields) {
                  if (this.fields[fieldName].selected) {
                    return this.fields[fieldName]
                  }
                }
                return undefined;
              },
              clearTapped: function() {
                this.clearOldData()
                this.selectField('cardNumberText')
              },
              clearOldData: function () {
                let result = initData()
                for (let prop in result) {
                  if (prop === 'creditPayments' || prop === "amount") {
                    continue;
                  }
                  this[prop] = result[prop];
                }
              },
              deleteCreditPayment: function (index) {
                return app.promiseShowAlert({
                  header: i18next.t('confirmSalePaymentItemDeleteHeader'),
                  content: i18next.t('confirmSalePaymentItemDelete'),
                  continueButtonText: i18next.t('remove'),
                  cancelButtonText: i18next.t('cancel')
                })
                  .then((result) => {
                    // if success this.refreshView
                    if (result === "continue") {
                      return PositiveTS.Service.VoucherPayment.removeSmartVoucherPayment(this.creditPayments[index]).then(() => {
                        this.removeSplittedPaymentIfNeeded();
                      });
                    }

                  })
                  .then(async () => {
                    await this.refreshView();
                  })
              },
              async refreshView () {
                this.setCalculatedAmount();

                let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS)
                this.creditPayments = result;
                this.clearTapped()
                await PositiveTS.VueInstance.$nextTick()
              },
              verifyAndPay: async function () {
                try {
                  await verify(this.amount)
                  let payBySmartVoucherResult: Service.ActionResponse = await Service.VoucherPayment.payBySmartVoucherType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS, this.amount, this.cardNumberText)
                  if (!posUtils.isBlank(payBySmartVoucherResult) && !payBySmartVoucherResult.success) {
                    app.promiseShowAlert({
                      header: i18next.t('error'),
                      content: payBySmartVoucherResult.error,
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    })
                  } else {
                    this.addSplittedPaymentIfNeeded();
                  }
                }
                catch (error) {
                  app.hideLoadingMessage();
                  app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: error.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }
                finally {
                  this.cardNumberText = "";
                  await this.refreshView();
                }
              },
              swipeCard: async function () {
                app.showLoadingMessage("העבר כרטיס");
                let cardNumber = await Service.EMV.swipe(this.amount);
                if (cardNumber) {
                  this.cardNumberText = cardNumber;
                  await this.verifyAndPay();
                }
                app.hideLoadingMessage();
              },
              useSwipe: function () {
                return (jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv)
              }
            },
            data: initData,
            computed:  {
              isMobile(){
              return Pinia.globalStore.mobileLayout
            },}
          }

          VueApp.component('tenbis-view', c)
        }





        function initFields() {
          let fields = {

            'amount': {
              selected: false,
              allowDecimal: true,
              inEditMode: false,
              type: 'number',
              disabled: false
            },
            'cardNumberText': {
              selected: false,
              inEditMode: false,
              type: 'string',
              allowDecimal: false,
              disabled: false
            },

          }
          for (let field in fields) {
            fields[field].model = field;
          }
          return fields
        }

        function verify(amount: number): Promise<void> {
          let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
          let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);

          if (amount <= 0) {
            return Promise.reject(new Error(i18next.t("newCreditCard.AMOUNT_MUST_BE_BIGGER_THAN_ZERO")))
          }
          else if (amount > amountLeft) {
            return Promise.reject(new Error(i18next.t("generalPaymentLeftAmountMandatoryError")))
          }
          return Promise.resolve();

        }

        function initData() {
          let types = []

          return {
            cardNumberText: "",
            fields: initFields(),
            clearImg: `${(<any>window).images_path}/close.png`,
            amount: 0,
            creditPayments: []
          }
        }
      }
    }
  }
}
