module PositiveTS {
  export module Components {
    export module ExternalOrdersList {
      export function create() {
        let externalOrdersList = {
          template: JST.externalOrdersList(),
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          methods: {
            async open() {
              try {
                if (Pinia.globalStore.isOnline) {
                  app.showLoadingMessageDialog(i18next.t("externalOrdersList.loading"));
                  let response = await Service.FetchReq.jsonReq('/external_orders', 'get');
                  this.orders = <Array<Storage.Entity.ExternalOrder>>response.result
                  app.hideLoadingMessageDialog();
                  $(document).unbind('keypress');
                  this.$el.showModal();
                } else {
                  app.hideLoadingMessageDialog();
                  app.showAlertDialog({
                    header: i18next.t("error"),
                    content: i18next.t("externalOrdersList.internetError"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);

                }
              } catch (error) {
                app.hideLoadingMessage();
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("externalOrdersList.errorLoading"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);

              }
            },
            close() {
              this.$el.close();
              this.selectedType = null;
              this.selectedStatus = null;
              this.freeSearch = null;
              this.orderInActions = null;
              $(document).unbind('keypress');
              $(document).keypress(posVC.onKeyPress);
            },
            getDateString(date: Date) {
              return moment(date).format("DD/MM/YYYY HH:mm")
            },
            getOrderStatus(order: Storage.Entity.ExternalOrder) {
              return !posUtils.isBlank(order) ? i18next.t(`externalOrdersList.statuses.${order.status}`) : ""
            },
            getOrderType(order: Storage.Entity.ExternalOrder) {
              return !posUtils.isBlank(order) ? i18next.t(`externalOrdersList.deliveryType.${order.deliveryType || 'default'}`) : ""
            },
            async cancelOrder(order:Storage.Entity.ExternalOrder) {
              try{
                app.showLoadingMessageDialog("externalOrdersList.cancellingOrder");
                Service.CashCowService.cancelOrder(order);
                this.orders.forEach(o => {
                  if (o.remoteDBId == order.remoteDBId) {
                    o.status = Service.Delivery.ExternalOrderStatus.Deleted;
                  }
                });
                this.closeMoreActions();
                app.hideLoadingMessageDialog();
              } catch (err) {
                console.error(err);
                app.hideLoadingMessage();
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("externalOrdersList.errorInCancel"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              }
            },
            async showOrder(order: Storage.Entity.ExternalOrder) {
              this.$el.close()
              await Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryShowOrder", args: [order, null, null]})
              this.$el.showModal()
            },
            showMoreActions(order: Storage.Entity.ExternalOrder) {
              this.orderInActions = order;
              this.$refs.actionsDialog.showModal();
            },
            closeMoreActions() {
              this.orderInActions = null;
              this.$refs.actionsDialog.close();
            },
            async scanItems(order: Storage.Entity.ExternalOrder) {
              if(this.posSaleItems.length > 0) {
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("externalOrdersList.thereAreSaleItemsInPos"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
                return ;
              }

              const salesOnHold = await Service.HoldSale.getSaleObjsOnHold();
              const saleOfOrderIndex = salesOnHold.findIndex((sale) => { 
                console.log(sale)
                let res = sale.orderNumber == order.orderNum;
                return res;
              });
              if(saleOfOrderIndex != -1) {
                await Service.HoldSale.releaseSaleOnHold(salesOnHold[saleOfOrderIndex].id);
                posVC.resume();
              } else {

                this.updateOrderStatus(order, Service.Delivery.ExternalOrderStatus.InProccess);
                let saleData = JSON.parse(posVC.sale.jsondata)
                saleData.delivery = {
                  deliveryAddress: {
                    address: {name: order.street, value: -1},
                    apartment: posUtils.isBlank(order.apartmentNumber) ? '' : String(order.apartmentNumber),
                    city: {name: order.city, value: -1},
                    house_entrance: order.entrance,
                    house_floor: posUtils.isBlank(order.floor) ? '' : String(order.floor),
                    house_number: posUtils.isBlank(order.houseNumber) ? '' : String(order.houseNumber),
                    is_default: true,
                    is_selected: true
                  },
                  isExternalOrder: true,
                  orderOrigin: order.orderOrigin,
                  deliveryType: order.deliveryType,
                  orderTime: moment(order.date).toISOString(),
                  ordererName: order.customerName,
                  ordererPhone: order.phone,
                  status: 1,
                  remoteDBId: order.remoteDBId, 
                  paymentData: order.paymentData,
                  externalOrderId: order.externalOrderId,
                  j5: !order.paid && !order.isPhoneOrder,
                  isPhoneOrder: order.isPhoneOrder
                }
                saleData.dedicatedTo = order.customerName;
                if(order.orderType == Service.Delivery.ExternalOrderType.SaleItemsFormat) {
                  saleData.externalOrderItems = order.saleItems;
                }
                posVC.sale.jsondata = JSON.stringify(saleData);
                posVC.sale.customerName = order.customerName;
                posVC.sale.orderNumber = order.orderNum;
                posVC.sale.isDelivery = true;

                posVC.sale.persist();
                posVC.setCustomerState()
              }
              let deliveryItem = order.saleItems.find(item => item.itemCode == jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));
              let deliveryPrice = !posUtils.isBlank(deliveryItem) ? deliveryItem.unitPrice : 0;
              let item = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode));
              item.priceZarhan = deliveryPrice;
              item.allowZeroPrice = true;
              await posVC.selectSizeColorForSaleItem(item);

              this.closeMoreActions();
              this.close();

            },
            getStatusChageText(order: Storage.Entity.ExternalOrder) {
              if (posUtils.isBlank(order)) {
                return ""
              }
              switch (order.status) {
                case Service.Delivery.ExternalOrderStatus.InProccess:
                  return i18next.t("externalOrdersList.markAsNew");
                default:
                  return i18next.t("externalOrdersList.inProgress")
              }
            },
            printItems(order: Storage.Entity.ExternalOrder) {
              Printing.Invoice.printOrderList(order);
              this.updateOrderStatus(order, Service.Delivery.ExternalOrderStatus.InProccess);
            },
            async updateOrderStatus(order: Storage.Entity.ExternalOrder, status?: Service.Delivery.ExternalOrderStatus) {
              try {
                app.showLoadingMessageDialog(i18next.t("externalOrdersList.loading"));
                let newStatus;
                if (status) {
                  newStatus = status;
                } else {
                  newStatus = order.status == Service.Delivery.ExternalOrderStatus.New ? Service.Delivery.ExternalOrderStatus.InProccess : Service.Delivery.ExternalOrderStatus.New;
                }
                let response = await Service.FetchReq.jsonReq(`/external_orders/${order.remoteDBId}`, 'put', {
                  status: newStatus
                });

                if (response && response.result && response.result.success) {
                  this.orders.forEach(o => {
                    if (o.remoteDBId == order.remoteDBId) {
                      o.status = newStatus
                    }
                  });
                  this.closeMoreActions();
                }
                else if (response && response.result) {
                  app.showAlertDialog({
                    header: i18next.t("error"),
                    content: response.result.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                } else {
                  app.showAlertDialog({
                    header: i18next.t("error"),
                    content: i18next.t("externalOrdersList.errorChangingStatus"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                }
              } catch (error) {
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("externalOrdersList.errorChangingStatus"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              }
              app.hideLoadingMessageDialog();
            },
            async makeOrderReadyForShipment(order: Storage.Entity.ExternalOrder) {
              app.showLoadingMessage(i18next.t('externalOrdersList.lookingForInvoiceInServer'))
              const saleFromServer = await Service.Sale.getSaleFromServer(order.storeId, order.posDeviceId, order.saleInvoiceSequence, Storage.Entity.Sequence.TYPE_DEBIT_INVOICE);
              const saleToPrint = Service.Sale.ToStorageTypeSale(saleFromServer);
              await Printing.Invoice.printInvoice(saleToPrint, saleToPrint.items, saleToPrint.payments, true);
              await this.updateOrderStatus(order, Service.Delivery.ExternalOrderStatus.ReadyForShipment)
              app.hideLoadingMessage()
            }
          },
          setup(){
            const globalStore = Pinia.useGlobalStore();
            const {saleItems} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {posSaleItems:saleItems} 

            return {...globalStoreProps}
          },
          computed: {
            filteredOrders() {
              return this.orders.filter((order: Storage.Entity.ExternalOrder) => {
                if (!posUtils.isBlank(this.selectedStatus) && order.status != this.selectedStatus.value) {
                  return false;
                }
                if (!posUtils.isBlank(this.selectedType) && order.deliveryType != this.selectedType.value) {
                  return false;
                }
                if (!posUtils.isBlank(this.freeSearch) && !(String(order.orderNum).toLowerCase().includes(this.freeSearch.toLowerCase()) || 
                      order.customerName.toLowerCase().includes(this.freeSearch.toLowerCase()))) {
                  return false;
                }
                return true;
              })
            }
          },
          data() {
            return {
              orders: [],
              statuses: [
                { label: i18next.t(`externalOrdersList.statuses.${Service.Delivery.ExternalOrderStatus.New}`), value: Service.Delivery.ExternalOrderStatus.New },
                { label: i18next.t(`externalOrdersList.statuses.${Service.Delivery.ExternalOrderStatus.InProccess}`), value: Service.Delivery.ExternalOrderStatus.InProccess }
              ],
              types: [
                { label: i18next.t(`externalOrdersList.deliveryType.${Service.Delivery.DeliveryType.delivery}`), value: Service.Delivery.DeliveryType.delivery },
                { label: i18next.t(`externalOrdersList.deliveryType.${Service.Delivery.DeliveryType.ta}`), value: Service.Delivery.DeliveryType.ta }
              ],
              selectedType: null,
              selectedStatus: null,
              freeSearch: null,
              orderInActions: null
            }

          },
        }
        VueApp.component('external-orders-list', externalOrdersList)

      }
    }
  }
}
