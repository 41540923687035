module PositiveTS {
  export module Components {
    export module DepartmentForm {
      export const route = '/department-form';
      var component = null;

      export function getComponent() {
        if (component == null) {
          component = {
            template: JST.departmentForm(),
            data() {
              return {
                items: [],
                activeItems: true,
                newDepartment: true,
                department: new Storage.Entity.Department(""),
                itemsPerPage: 25,
                totalItems: 0,
                page: 0,
                itemSearch: "",
                // Used to only 
                toggledItems: []
              }
            },
            async beforeRouteEnter(to, from, next) {
              let items = await appDB.localItems.toCollection().sortBy('description');
              next(vm => {
                vm.$data.items = items;
                let department = _.cloneDeep(vm.routerExtraData.department)
                vm.$data.newDepartment = posUtils.isBlank(department)
                if (!vm.$data.newDepartment) {
                  vm.$data.department = department
                }
              });
            },
            computed: {
              lockedItemsToggle() { return !this.activeItems; },
              filteredItems() {
                let arr: Array<Storage.Entity.Item> = this.items;
                arr = arr.filter(i => Boolean(i.locked) === this.lockedItemsToggle);
                if (!posUtils.isBlank(this.itemSearch)) {
                  arr = arr.filter(i => i.description.includes(this.itemSearch) || (i.barcode && i.barcode.includes(this.itemSearch)));
                }
                this.totalItems = arr.length;
                arr = arr.slice(this.page * this.itemsPerPage, this.page * this.itemsPerPage + this.itemsPerPage);
                return _.sortBy(arr,'description');
              },
            },
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {routerExtraData,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {routerExtraData,mobileLayout} 
  
              return {...globalStoreProps}
            },
            methods: {
              ...Mixins.modalsMixin.methods,
              nextPage() {
                if (((this.page + 1) * this.itemsPerPage) < this.totalItems) {
                  this.page += 1;
                }
              },
              prevPage() {
                if (this.page >= 1) {
                  this.page -= 1;
                }
              },
              async save() {
                let department: Storage.Entity.Department = this.department;
                department.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                department.timestamp = (new Date()).getTime();
                if (this.newDepartment === false) {
                  appDB.departments.put(department);
                  let index = session.allDepartments.findIndex(dep => dep.id == department.id);
                  session.allDepartments[index] = department;
                } else {
                  await appDB.departments.put(department);
                  this.departments = await appDB.departments.orderBy('name').toArray();
                  session.allDepartments = this.departments;
                }
                await this.updatedToggledItems();
                this.close();
              },
              async updatedToggledItems() {
                let items: Array<Storage.Entity.Item> = this.toggledItems;
                if (this.newDepartment) {
                  let department = await appDB.departments.get(this.department.id);
                  items.forEach(i => { i.departmentId = department.id; i.serverDepartmentId = department.serverID; });
                }
                for (const item of items) {
                  //we only update the sync status on a clone of the item - to prevent confusion with the allItems cache
                  let tempItem: Storage.Entity.Item = _.cloneDeep(item);
                  tempItem.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  if (tempItem._data) {
                    tempItem._data.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  }
                  let res = await Storage.Entity.Item.saveToDB(tempItem, true);
                }
                Pinia.posMenu.setMenusLoaded(false);
                Pinia.posMenu.loadMenusIfNeeded();
              },
              toggleItemToDepartment(item: Storage.Entity.Item) {
                if (this.toggledItems.every(i => i.id !== item.id)) {
                  this.toggledItems.push(item)
                }
                if (this.isItemRelated(item)) {
                  item.departmentId = -1;
                  item.serverDepartmentId = -1;
                } else {
                  item.departmentId = this.department.id;
                  item.serverDepartmentId = this.department.serverID;
                }
              },
              isItemRelated(item: Storage.Entity.Item): boolean { return item.departmentId == this.department.id },
              close() { VueServices.Router.goto(Departments.route); },
            },
          }
        }
        return component;
      }

    }
  }
}
