module PositiveTS {
/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"SalesPersonDialog", arguments: []})
*/

export module Components {
export module SalesPersonDialog {
  const _dialogSelector = "#sales-person-dialog"
  const _vueComponentName = 'sales-person-dialog'//'cashier-by-code';

  const i18 = {
    EMPLOYEE_NOT_FOUND: "לא נמצא עובד - עובד לא קיים",
  }

  export function create() {

    let salesPersonDialog = {
        template: JST.salesPersonDialog(),
        methods: {
          open: open,
          close: close,
          async doSearchEmployee () {
            try{
              let employee = new PositiveTS.Storage.Entity.Employee();
              let ary = []
              app.showLoadingMessage("רושם מוכר");
              ary.push( employee.fetchByStoreAndEmployeeID(session.pos.storeID, this.searchEmployee) );
              ary.push( employee.fetchByStoreAndMagneticCard(session.pos.storeID, this.searchEmployee) );
              let items = await Promise.all(ary)
              
              let emp;
              if (items[0].length > 0) {emp = items[0][0]}
              if (items[1].length > 0) {emp = items[1][0]}
        
              if(emp=='' || !emp) {
                this._clearForm();
        
                app.promiseShowAlert({
                  header: "",
                  content: 'עובד לא נמצא - עובד לא קיים',
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                  noHandleEnterEscape: true,
                })
                app.hideLoadingMessage()
                return
              }
        
              if (items[0].length > 0 || items[1].length > 0) {
                this.close();
              } else {
                this.status = i18.EMPLOYEE_NOT_FOUND;
                this._clearForm();
              }
              if (this.selectForAllSale) {
                await posVC.updateSalesPersonForSale(emp)
              } else {
                await posVC.updateSalesPersonForItem(this.saleItemsCopy, emp);
              }
        
              this.resolveFunc()
              this.close()
              app.hideLoadingMessage();
            }catch (err){
              app.hideLoadingMessage();
              console.error(err);
              app.showErrorAlertDialog()
            }
          },
          _clearForm: _clearForm,
          back() {
            this.resolveFunc();
            this.close();
          },
        },
        data: _initData

    }

    VueApp.component(_vueComponentName,salesPersonDialog)
  }

  function open(saleItems: Array<Storage.Entity.SaleItem>, selectForAllSale: boolean = false, showBackButton: boolean = false){
    
    this.searchEmployee = "";
    this.showBackButton = showBackButton;
    this.selectForAllSale = selectForAllSale;

    return new Promise((resolve,reject) => {
      this.saleItemsCopy = _.cloneDeep(saleItems)

      if (this.saleItemsCopy == null && !selectForAllSale) {
        return resolve();
      }

      $(document).unbind('keypress');
      $(function() {
        $(_dialogSelector).dialog({
          autoOpen: true,
          modal: true,
          dialogClass: 'positive-dialog',
          width: '80%',
          height: 300,
          resizable: false,
          closeOnEscape: false,
          draggable: false,
        });
        $(_dialogSelector).dialog('open');
      });
      
      $(document).unbind('keypress');

      if (this.$refs.searchEmployee) {
        this.$refs.searchEmployee.focus();
      }
      this.resolveFunc = resolve;
      this.rejectFunc = reject;

    })
   
  }


  function _clearForm(){

    this.searchEmployee = "";
    this.$refs.searchEmployee.focus();
  }

  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      searchEmployee: "",
      status: "",
      loading: false,
      resolveFunc: null,
      saleItemsCopy: null,
      selectForAllSale: false,
      showBackButton: false,
    };
  }


  function close() {
    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
    Pinia.globalStore.setIsScreenLocked(false);
  }


}}}
