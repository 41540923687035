module PositiveTS {
  export module Components {
    export module PosPaymentButtons {

      function initData() {
        return {
          showQuickVoucherButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.quickVoucher)),
          showValuCardPay: Boolean(jsonConfig.getVal(jsonConfig.KEYS.isShowSimplePosValuCardPay)),
          showCash: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCash)),
          showTips: Service.Tip.isHasTips(),
          showPunchCard: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPunchCard)),
          showCheck: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCheck)),
          showHakafa: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showHakafa)),
          showCashReturnButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCashReturnButton)),
          showCreditVoucherPaymentButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCreditVoucherPaymentButton)),
          showCreditCardReturnButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCreditCardReturnButton)),
          showHakafaReturnButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showHakafaReturnButton)),
          showVoucherRefundButton: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showVoucherRefundButton)),
          showCredit: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCredit)),
          showCibus: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showCibus)),
          showTenbis: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showTenbis)),
          showOtot: Service.Otot.isOtotActive() && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showOtotPayment)),
          showPaymentBanx: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentBanx)),
          showPaymentSafeCash: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentSafeCash)),
          showYaadPayment: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showYaadPayment)),
          showInterServicePayment: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showInterServicePayment)),
          showSplitSale: Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showSplitSalePaymentButton)),
          showPaymentPageHypButton: Service.PaymentPage.isEnabled() && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentPageHypButton)),
          showSplitPayment: Boolean(jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually)),
          hideTenbisCibus: false,
          hideAllButTenbisCibus: false,
          showPaymentButtonsInStandAlone: jsonConfig.getVal(jsonConfig.KEYS.showPaymentButtonsInStandAlone),
          directHakafaPayment: Boolean(jsonConfig.getVal(jsonConfig.KEYS.directHakafaPayment)), 
          allowGiftCardPaymentWithVoucher: Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowGiftCardPaymentWithVoucher)),
          showPaymentIcMega: Boolean(jsonConfig.getVal(jsonConfig.KEYS.showPaymentIcMega)),
        }
      }

      export async function openPaymentScreen(method?:string) {
        let tamashSmartVouchers  = ["tenbis","cibus","goodi","banx", "yaad"];
        if(session.pos.isCaveret) {
          tamashSmartVouchers  = ["tenbis","cibus"];
        }
        let isSmartVoucher = tamashSmartVouchers.includes(method)


        if (Service.TimeItem.isSaleHasOpenTimeItems(posVC.saleItems)) {
          app.showAlert({
            header: i18next.t("payAttention"),
            content: i18next.t('finishTimeItemDialog.saleHasOpenTimeItems'),
            continueButtonText: i18next.t("back"),
            hideCancelButton: true,
          });

          return;
        }
        
        if (method == "icmega" && !Service.icMega.checkIfCurrentSaleCanPayByIcMegaMethod()){
          return
        }

        if (session.pos.worksWithCustomerSaleItemLimit) {
          let dontCloseSale = await Service.CustomerSaleItemLimit.checkIfNeedToAlertForCustomerItems();

          if (dontCloseSale) {
            return;
          }
        }

        if (PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(posVC.saleItems, isSmartVoucher ? 'voucher' : method, isSmartVoucher ? method : null, true)){
          return
        }

        if(jsonConfig.getVal(jsonConfig.KEYS.emvRecoveryBeforePaymentScreen) && Pinia.globalStore.emvRecoveryNeeded){
            await Service.EMV.checkIfRecoveryNeeded(Service.Pickup.isPickupEnabled())
        }

        if(Pinia.globalStore.isOnline){
          Pinia.globalStore.setEmvRecoveryNeededWhenOnline(false);
        }

        if(!posVC.sale.isDelivery && !posVC.isSaleCashWithdrawal){
          Pinia.globalStore.paymentScreenOpen(isSmartVoucher)
          posVC.goToPaymentScreen()
          .then((res) => {
            if (!res || res.isOpeningPaymentScreen){
              if (!method) {
                PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod()
              }
              else {
                PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod(method)
              }
            }
          })
        }
        else if(posVC.isSaleCashWithdrawal) {

Pinia.componentsStore.openComponent( {componentName:"withdrawalDialog", args: []});
        }
        else{
          if(session.pos.hasInternetStore) {
            if(!(await canContinueWithExternalOrder())) {
              return ;
            }
            let saleData = JSON.parse(posVC.sale.jsondata);
            if(saleData.delivery && saleData.delivery.isExternalOrder && saleData.delivery.j5) {
              try{
                app.showLoadingMessage(i18next.t('yaadPayment.commitingPayment'))
                let saleData = JSON.parse(posVC.sale.jsondata)
                switch(saleData.delivery.orderOrigin) {
                  case Service.Delivery.ExternalOrderOrigin.CashCow:
                    await this.paySoftCashCow();
                    break;
                  case Service.Delivery.ExternalOrderOrigin.Digitrade:
                    await Service.DigitradeService.finalizeSale(posVC.sale, posVC.saleItems);
                    break;
                  default:
                      throw Error("ExternalOrder Origin not recognized");
                }
              } catch(e) {
                console.error(e)
                app.showAlert({
                  header: i18next.t('error'),
                  content: e.message || i18next.t("yaadPayment.errorCommitingPayment"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
              }finally{
                app.hideLoadingMessage()
                return ;
              }
            }
          }

          if(checkIfEmptyDelivery()){
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t("pos.noItemsInSale"),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            });
          }
          else
          {
            
            Pinia.globalStore.paymentScreenOpen((tamashSmartVouchers.indexOf( method ) > -1 ))
            posVC.goToPaymentScreen()
            .then((res) => {
              if (!res || res.isOpeningPaymentScreen){
                if (!method) {
                  PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod()
                }
                else {
                  PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod(method)
                }
              }
            })
          }
        }
      }

      async function returnCreditCard() {
        
        let manager = await app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_CREDIT_PAYMENT])
        let jsondata = JSON.parse(posVC.sale.jsondata)
        jsondata['sale_minus_approval_employee_id'] = manager.employeeID;
        jsondata = JSON.stringify(jsondata)
        let amount = posVC.getTotalAmount()
        posVC.sale.jsondata = jsondata
        let result = await Pinia.componentsStore.openComponent( {componentName:"creditCreditCardDialog", args: [amount]})
          
        if (result.cardDetails || result.isEmv) {
          if (!result.isEmv) { 
            await posVC.createChangeInCreditCardPayment(amount,result.cardDetails)
          }
          closeSaleDg.open();
          await closeSaleDg.continue();
          // await posPaymentVC.close();
        }
      }

      function giveCreditVoucher():Promise<any> {

        return new Promise((resolve,reject) => {


          let amount = posVC.getTotalAmount()

          $(document).unbind('keypress');
          app.showCustomerDetailsFill((name, phone, customerID) => {
            posVC.sale.customerName = name;
            posVC.sale.customerPhone = phone;
            posVC.sale.customerID = customerID;
            let stringContent = ''
            
            if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
              stringContent = i18next.t('creditSaleAllowCreditVoucher', {AMOUNT: session.fixedNumber(-amount), CURRENCY: 'דולר'})
            } else {
              stringContent = i18next.t('creditSaleAllowCreditVoucher', {AMOUNT: session.fixedNumber(-amount), CURRENCY: 'ש"ח'})
            }
            app.showAlert({
              header: i18next.t('creditVoucher'),
              content: stringContent,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: false,
              cancelButtonText: i18next.t('cancel'),
              blueCancel: false
            }, () => {
              posVC.createChangeInCreditVoucherPayment(amount)
              .then(() => {
                // posVC.saleUpdated()
                Service.FullSale.saveCurrentSale()
                //close the sale... //TODO: this is pretty stupid that the sale closes this way....
                .then(() => {
                  closeSaleDg.open()
                  return closeSaleDg.continue()
                })
                .then(resolve)
                
                
                // .then(() => posPaymentVC.close())
                
              })

            }, () => {
              reject({userCanceled: true});
            });
          },
          () => {
            reject({ userCanceled: true, message: 'לא מולאו פרטי לקוח'});
          });
        })
      }



      function cashCredit() {
        var employee
        app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_CREDIT_PAYMENT])
        .then( response=>{
          employee = response
          return app.promiseShowAlert({
            header: i18next.t('cashRefund'),
            content:  i18next.t('refundCustomer'),
            continueButtonText: i18next.t("ok"),
            cancelButtonText: i18next.t("cancel")
          })
          .then (response, approval_employee=>{
            if ( response === "cancel") {throw new Error(app.userCancelledGlobalMessage)}
          })

        })
        .then( () => {

          let employeeID = employee.employeeID 
          app.showLoadingMessage(i18next.t("closingSale"));
          let salePayment = new Storage.Entity.SalePayment();

          //TODO: talk 2 shay sakal about what to do if there are payments (credit card/vouchers and suddenly the invoice becomes minus...)
          let cashPayment = posVC.salePayments.find(payment =>
            payment.method === Storage.Entity.SalePayment.METHOD_CASH && payment.amount != 0
          );
          if (cashPayment) {
            throw new Error(i18next.t('posPaymentButtons.deletePrevCashPayments'));
          }
          let jsondata = JSON.parse(posVC.sale.jsondata)
          jsondata['sale_minus_approval_employee_id'] = employeeID;
          jsondata = JSON.stringify(jsondata)
          posVC.sale.jsondata = jsondata
          salePayment.saleID = posVC.sale.id;
          salePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_CASH;
          salePayment.amount = posVC.getTotalAmount() ;
          salePayment.data = '[]'
          posVC.salePayments.push(salePayment);
          Service.FullSale.saveCurrentSale()
          .then(() => {  
            posVC.saleUpdated()
            //close the sale...
            closeSaleDg.open();
            return closeSaleDg.continue();
            // .then(() => posPaymentVC.close())
          })
        }).catch((e:Error) => {
          app.hideLoadingMessage()
          if (e) {
            if (e.message === app.userCancelledGlobalMessage){
              return;
            }
            console.error(e);
          }
        })

      }

      function payWithValuCard() {
        var simplePosValuCardTypeID = jsonConfig.getVal(jsonConfig.KEYS.simplePosValuCardTypeID);
        if (PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(posVC.saleItems, 'voucher', simplePosValuCardTypeID, true)){
          return
        }
        openVoucherId(simplePosValuCardTypeID)
      }

      function payWithHakafa() {
        if (PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(posVC.saleItems, 'voucher', 999, true)){
          return
        }
        let custSvc = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);

        if ( PositiveTS.Service.Hakafa.showHakafaBtn() ) {
          if (PositiveTS.Service.Hakafa.hasCustomer()) {
            openVoucherId( PositiveTS.Service.Hakafa.getVoucherID() , 'amount');
          } else {
            // // Open select hakafa customer dialog
            // if successfull open voucher payment
Pinia.componentsStore.openComponent( {componentName:"vueDialog", args: [Components.HakafaCustomerDialog.componentName]})
          }
        }
      }

      async function setRefundVoucherPayment(voucherToAdd){
        let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
        let voucherRefundAmount = totals.totalAmount-totals.totalPaid;
        if (!posUtils.isBlank(voucherToAdd.value) && (-1* voucherRefundAmount) != voucherToAdd.value){
          app.showAlertDialog({header: i18next.t('error'),
          content: i18next.t('posPaymentButtons.invalidVoucherRefund'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,})
          return;
        }
        let voucherData = [{
            'amount': voucherRefundAmount,
            'creditType': voucherToAdd.name,
            'barCode': "",
            'voucher_type_id': voucherToAdd.typeID,
            'smartVoucherType': false,
            'valuTypeId': voucherToAdd.valuTypeId,
            'praxellManpik': voucherToAdd.praxellManpik,
            'mutipassInit': voucherToAdd.mutipassInit,
            'isTamashCustomer': Service.Hakafa.isCurrentCustomerTamash(voucherToAdd),
            'response': undefined
          }];

        app.showLoadingMessage(i18next.t("closingSale"));
        let salePayment = new Storage.Entity.SalePayment();
        let cashPayment = posVC.salePayments.find(payment =>
          payment.method === Storage.Entity.SalePayment.METHOD_CASH && payment.amount != 0);
        if (cashPayment) {
          throw new Error(i18next.t('posPaymentButtons.deletePrevCashPayments'));
        }

        salePayment.saleID = posVC.sale.id;
        salePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
        salePayment.amount = totals.totalAmount-totals.totalPaid ;
        salePayment.data = JSON.stringify(voucherData)
        posVC.salePayments.push(salePayment)
        await Service.FullSale.saveCurrentSale()
        posVC.saleUpdated()
        closeSaleDg.open();
        await closeSaleDg.continue()
      }


      export async function refundVoucher(){
        if (!this.showVoucherRefundButton) {
          return;
        }
        try {
          let response = await app.promiseShowAlert({
            header: i18next.t('posPaymentButtons.returnOnVoucher'),
            content: i18next.t('posPaymentButtons.voucherRefundMessage',{amount:Math.abs(posVC.getTotalAmount())}) ,
            continueButtonText: i18next.t("ok"),
            cancelButtonText: i18next.t('cancel')
          })
          
          if ( response === "cancel") {
            throw new Error(app.userCancelledGlobalMessage)
          }

          let voucher = new PositiveTS.Storage.Entity.Voucher();
          let result = await voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);

          const filteredVouchers = result.filter(voucher => 
            voucher.isActive === true &&
            voucher.isDiscountVoucher === false &&
            voucher.hideFromVoucherScreen === false &&
            voucher.smartVoucherType === null
          );

          let voucherToAdd:any = await voucherTypeDialog.openWithPromise(filteredVouchers);
          if (voucherToAdd == null) {
            return;
          }
          await setRefundVoucherPayment(voucherToAdd);
        } 
        catch(e) {
          app.hideLoadingMessage()
          if (e) {
            if (e.message === app.userCancelledGlobalMessage){
              return;
            }
            PositiveTS.Service.Logger.error(e);
						return;
          }
        }
      }

      export async function returnHakafa(){

        if ( !(Service.Hakafa.showHakafaBtn() && Service.Hakafa.hasCustomer()) ) {
          return;
        }
        
        try {

          let response = await app.promiseShowAlert({
            header:i18next.t('posPaymentButtons.returnOnCredit'),
            content: i18next.t('posPaymentButtons.hakafaRefundMessage',{amount:Math.abs(posVC.getTotalAmount())}) ,
            continueButtonText: i18next.t("ok"),
            cancelButtonText: i18next.t('cancel')
          })
          
          if ( response === "cancel") {
            throw new Error(app.userCancelledGlobalMessage)
          }
          

          app.showLoadingMessage(i18next.t("closingSale"));

          let voucher = new PositiveTS.Storage.Entity.Voucher();
          let result = await voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);

          let voucherToAdd = null
          let voucherId = PositiveTS.Service.Hakafa.getVoucherID()
          for (let i=0;i<result.length;i++){
            if (result[i].typeID === voucherId ){
              voucherToAdd = result[i];
            }
          }
          if (voucherToAdd == null) {
            throw new Error(i18next.t('posPaymentButtons.missingVoucherMessage'));
          }

          await setRefundVoucherPayment(voucherToAdd);   

        } 
        catch(e) {
          app.hideLoadingMessage()
          if (e) {
            if (e.message === app.userCancelledGlobalMessage){
              return;
            }
            console.error(e);
          }
        }
      }

      function payWithVoucher() {
        posVC.goToPaymentScreen()
        .then((res) => {
          if (!res || res.isOpeningPaymentScreen){
            PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('voucher')
            voucherTypeDialog.open();
          }         
        });
      }

      function creditVoucherPayment(){
        if (PositiveTS.Service.BlockItemPaymentMethod.hasBlockPaymentOnSaleItems(posVC.saleItems, 'creditVoucher', null, true)){
          return 
        }
         posVC.goToPaymentScreen()
        .then((res) =>{
          if (!res || res.isOpeningPaymentScreen){
            PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('creditVoucher')
            setTimeout(()=>{$("#pos-payment-promotion-number").focus()}, 500)
          } 
        });
      }

      function payWithBeengo(){
        return PositiveTS.Service.BeengoService.createPayWithBeengoAndClose()

      }

      function payWithCaveret(){
        openPaymentScreen('caveretPayment')
      }


      async function payWithMultipass(){
        let result: string = await Pinia.componentsStore.openComponent( {componentName:"multipassPoliceCustomerPicker", args: [true]});
        if (result === "continue") {
          await this.payWithCash();
        }        
      }

      export async function openVoucherId(voucherId,focusField = 'barcode') { //TODO: refactor this once we convert the voucher screen to Vue component
        if(posVC.sale.isDelivery){
          if(checkIfEmptyDelivery()){
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t("pos.noItemsInSale"),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            });

            return;
          }
        }
        await posVC.goToPaymentScreen()
        
        PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('voucher')
        let voucher = new PositiveTS.Storage.Entity.Voucher();
        let result = await voucher.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID);
        let voucherToAdd = null;
        
        for (var i=0; i<result.length; i++) {
          if (result[i].typeID === voucherId ){
            voucherToAdd = result[i];
            break;
          }
        }
        if (voucherToAdd == null) {
          throw new Error("No smart voucher Valu defined");
        }
        
        Pinia.globalStore.setSelectedVoucherType(voucherToAdd);
        
        if (isNaN(Number(voucherToAdd.value)) || Number(voucherToAdd.value) == 0) {
          await PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.putAllAmountToPayInAmountField();
        }
        else {
          PositiveTS.VueInstance.$refs.posPaymentDialog.$refs.voucherView.$refs.amountField.focus();
        }
        
      }


      function punchCardPay(){
        return PositiveTS.Service.PunchCard.payPunchCard();
      }

      async function printBonAndCloseSale(){
        if(posVC.salePayments.length == 0){
          let cashPayment = new Storage.Entity.SalePayment();
          cashPayment.saleID = posVC.sale.id;
          cashPayment.method = Storage.Entity.SalePayment.METHOD_CASH;  
          cashPayment.amount = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments).totalAmount;
          cashPayment.data = "[]";
          posVC.salePayments.push(cashPayment);
          await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments);
          await PositiveTS.Service.CloseSale.closeSale();
          closeSaleDg.afterSaleClosed();
        }
        else{
          openPaymentScreen("cash");
        }

      }

      function checkIfEmptyDelivery(){
        if(posVC.saleItems.length == 1){
          if(posVC.saleItems[0].isDelivery){
           return true;
          }
        }
        return false;
      }

      async function payWithCash(){
        if((!this.isDeliverySale) || (Service.Delivery.isTaOrder(JSON.parse(posVC.sale.jsondata).delivery.deliveryType))){
            openPaymentScreen('cash');
        }
        else{
          if(!(await this.canContinueWithExternalOrder())) {
            return ;
          }
          if(checkIfEmptyDelivery()){
            app.showAlert({
              header: i18next.t('error'),
              content: i18next.t("pos.noItemsInSale"),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            });
          }
          else{
            printBonAndCloseSale();
          }
        }
      }


      function openTipPaymentScreen() {
        if (Service.Tip.isHasTips()) {
          Pinia.globalStore.setOpenPaymentsForTip(true)
          openPaymentScreen('tip');
        }
      }

      async function canContinueWithExternalOrder() {
        if(!session.pos.hasInternetStore || !posVC.sale.isDelivery) {
          return true;
        }
        let saleData = JSON.parse(posVC.sale.jsondata);
        if(saleData.delivery) {
          if (saleData.delivery.isExternalOrder){
            return await PositiveTS.VueInstance.$refs.externalOrdersDifferences.openIfNeededAndReturnIfToContinue();
          }
          return true;
        }

        return false;
      }

      function hakafaCardPayment(){
        Service.HakafaCardPayment.generateDirectHakafaPayment();
      }

      async function paySoftCashCow () {
        let sale = posVC.sale
        const saleJsondata = JSON.parse(sale.jsondata)
        const paymentData = saleJsondata && saleJsondata.delivery && saleJsondata.delivery.paymentData ? 
          JSON.parse(saleJsondata.delivery.paymentData) : null
        
        if (_.isEmpty(paymentData)){
          console.error('Empty paymentData on External Order')
          throw new Error()
        }

        const saleAmount = posVC.getTotalAmount()
        const saleItems = posVC.saleItems
        let salePayments = posVC.salePayments
        const resSoft = await PositiveTS.Service.CashCowService.soft(saleItems, saleAmount, paymentData)

        if (resSoft.success) {
          let sequence = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)
          sequence.sequence += 1
          sale.invoiceSequence = sequence.sequence

          //close sal
          const payment = await Service.ECommerceAPIService.getPaymentForJ5Pay(sale, saleAmount, resSoft.data.Id)
          posVC.salePayments.push(payment)
          await posVC.closeCurrentSale(
            {cash: 0,creditCard: 0,creditVoucher: 0,roundedCash: 0,totalChange: 0}, null)
          await posVC.createNewSale()
        }else{
          throw new Error(i18next.t("yaadPayment.errorCommitingPaymentWithErrorCode", {code: resSoft.errorCode}))
        }
      }

        export function create() {
          var component = {
            template: JST.posPaymentButtons(),
            methods: {
              showAlertGenericDialog: Mixins.modalsMixin.methods.showAlertGenericDialog,
              openPaymentScreen: openPaymentScreen,
              payWithValuCard: payWithValuCard,
              payWithHakafa: payWithHakafa,
              payWithVoucher: payWithVoucher,
              payWithCash: payWithCash,
              openTipPaymentScreen: openTipPaymentScreen,
              hakafaCardPayment: hakafaCardPayment,
              creditVoucherPayment: creditVoucherPayment,
              payWithBeengo: payWithBeengo,
              payWithCaveret: payWithCaveret,
              payWithMultipass: payWithMultipass,
              giveCreditVoucher: giveCreditVoucher,
              returnCreditCard: returnCreditCard,
              returnHakafa: returnHakafa,
              refundVoucher:refundVoucher,
              cashCredit: cashCredit,
              punchCardPay: punchCardPay,
              paySoftCashCow: paySoftCashCow,
              canContinueWithExternalOrder: canContinueWithExternalOrder,
              setPaymentButtonsNumber() {
                //וואלה מגעיל מה שעשיתי פה
                let buttonsWidth = '74px';
                if (this && this.$el && this.$el.children && this.$el.children[0] && this.$el.children[0].childElementCount) {
                  let btnNumbers = this.$el.children[0].childElementCount;
                  buttonsWidth = `min(calc(${100/btnNumbers}% - 0.25rem), 168px)`;
                }
                document.documentElement.style.setProperty('--payment-button-width', `${buttonsWidth}`);
              },
              async splitSalePayment() {
                await Service.SplitSalePayment.startSplitSaleProcess();
              },
              async splitPaymentAmount() {
                await Pinia.componentsStore.openComponent( {componentName:"selectSplitPaymentAmountDialog", args: [true]});
              }
            },
            data: initData,
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {
                bigPaymentButtons,
                isRoshemet,
                isSaleGiftCard,
                isWithdrawalSale,
                allowInvoiceSplit,
                isCaveretPayment,
                isBeengoSupported,
                hakafaDisabled,
                simpleSelfService,
                isSpecialItemCode,
                isDeliverySale,
                emvRecoveryNeeded,
                mobileLayout
              } = Pinia.storeToRefs(globalStore);

              const globalStoreProps = {
                bigPaymentButtons,
                isRoshemet,
                isSaleGiftCard,
                isWithdrawalsale:isWithdrawalSale,
                allowInvoiceSplit,isCaveretPayment,
                showBeengo:isBeengoSupported,
                hakafaDisabled,
                simpleSelfService,
                isSpecialItemCode,
                isDeliverySale,
                emvRecoveryNeeded,
                mobileLayout
              }

              return {...globalStoreProps}
            },
            computed:  {
            isExternalOrderSale(){
              if(Pinia.globalStore.isDeliverySale) {
                let saleData = JSON.parse(posVC.sale.jsondata);
                if(!posUtils.isBlank(saleData.delivery) && saleData.delivery.isExternalOrder && saleData.delivery.j5) {
                  return true;
                }
              }
              return false;
            },
            showCreditButtons(){
              return Pinia.globalStore.saleTotal < 0
            },
            cannotPayByHakafa(){
              return Boolean(Pinia.globalStore.hakafaCustomer) && Boolean(Pinia.globalStore.hakafaCustomer.cannot_pay_by_hakafa)
            },
            noSaleItems(){
              return Pinia.globalStore.saleItems.length == 0
            },
            hasHakafaCustomer(){
              return Boolean(Pinia.globalStore.hakafaCustomer);
            },
            showMultipassPolice: function() {
              if(Boolean(jsonConfig.getVal(jsonConfig.KEYS.multipassPolicePosId))) {
                if(session.pos.isCaveret) {
                  return true;
                }
                if(!this.hideTenbisCibus) {
                  return true;
                }
              }
              return false;
            },
            showGoodi: function() {
              if(Boolean(jsonConfig.getVal(jsonConfig.KEYS.showGoodi))) {
                if(session.pos.isCaveret) {
                  return true;
                }
                if(!this.hideTenbisCibus) {
                  return true;
                }
              }
              return false;
            },
            dalpakTablesView() {
              return Boolean(jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView));
            },
            isDalpakTablesViewMobile() {
              return this.dalpakTablesView;
            },
            restaurantNewPOS() {
              return jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS);
            },
            
          }
          }

          VueApp.component('pos-payment-buttons',component)
        }
      }
    }
  }
