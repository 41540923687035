
module PositiveTS {
  export module Service {

    export module CustomerClubSpecialItem {

      export const ClubMemberRenewHndlr = "positive_renew_member";

      function getSpecialItemCode(handler) {
        var _return = "";
        for (var i=0; i< posVC.Specialitems.length; i++) {
          if (posVC.Specialitems[i].hndlr === handler) {
            _return = posVC.Specialitems[i].code;
          }
        }
        return _return;
      };

      export function getSpecialItemCodes():string[] {
        let codes = []
        for (let si of posVC.Specialitems) {
          if (si.hndlr == ClubMemberRenewHndlr || si.hndlr == jsonConfig.getVal( jsonConfig.KEYS.ClubMemberSpecialItemHndlr )) {
            codes.push(si.code)
          }
        }
        return codes;
      }

      export function saleHasClubMemberRenewItem(saleItems:Storage.Entity.SaleItem[]): boolean {
        let specialItem = posVC.Specialitems.find(si => si.hndlr == ClubMemberRenewHndlr);
        return specialItem && saleItems.some(si => si.itemCode === specialItem.code);
      }

      export function getSpecialItemPriceInSale(saleItems:Storage.Entity.SaleItem[]) {
        let specialItemCodes = getSpecialItemCodes();
        for (let saleItem of saleItems){
          if (specialItemCodes.indexOf(saleItem.itemCode) > -1) {
            return saleItem.unitPrice * saleItem.quantity;
          }
        }
        return 0;
      }

      export async function addSpecialItemIfThereIs(handler = null) {
        try {
          if (handler == null) {
            handler = jsonConfig.getVal( jsonConfig.KEYS.ClubMemberSpecialItemHndlr )
          }
          const specialItemCode = getSpecialItemCode(handler);
          if(posUtils.isPresent(specialItemCode)){
            return await addSpecialItem(handler,specialItemCode);
          }
          return;
        } catch (error) {
          console.error(`Could not get special Item ${handler || jsonConfig.getVal(jsonConfig.KEYS.ClubMemberSpecialItemHndlr)}`)
          console.error('addSpecialItemIfThereIs error ::: ', error);
        }
      };

      export async function addSpecialItem(handler = null, specialItemCode?) {
        if (handler == null) {
          handler = jsonConfig.getVal( jsonConfig.KEYS.ClubMemberSpecialItemHndlr )
        }
        let specialItemCodeToCheck = null;
        if (specialItemCode) {
          specialItemCodeToCheck = specialItemCode;
        } else {
          specialItemCodeToCheck = getSpecialItemCode(handler);
        }
        return await posVC.addItemByBarcodeOrByCode(specialItemCodeToCheck, true);
      };
    }

  }
}

