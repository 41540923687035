module PositiveTS {
export module Components {
    export module CalculatorDialog {

      let lastKeyWasEqual = false;

      function dragElement(elmnt) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
          /* if present, the header is where you move the DIV from:*/
          document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
          /* otherwise, move the DIV from anywhere inside the DIV:*/
          elmnt.onmousedown = dragMouseDown;
        }
      
        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }
      
        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          let topPosition = elmnt.offsetTop - pos2
          if ( topPosition > 0 && (window.innerHeight - topPosition) > elmnt.offsetHeight ){
            elmnt.style.top = (topPosition) + "px";
          }

          let leftPosition = elmnt.offsetLeft - pos1
          if (leftPosition > 0 && (window.innerWidth - leftPosition) > elmnt.offsetWidth ){
            elmnt.style.left = leftPosition + "px";
          }
          
        }
      
        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }


      function initData() {
        return {
          initialized: this.initialized,
          isShow: false,
          current: '',
        }
      }

      function close() {
        this.isShow = false;
      }

      async function open() {

        this.isShow = true;
        
        if (!this.initialized){
          this.initialized = true;
          await PositiveTS.VueInstance.$nextTick();
          dragElement(document.getElementById("calculatordiv"));
        }
        

      }

      function press(event) {
        let me = this
        let key = event.target.textContent
  
        if (
          key != '=' && 
          key != 'C' &&
          key != '*' &&
          key != '/' &&
          key != '√' &&
          key != "x ²" &&
          key != "%" &&
          key != "<=" && 
          key != "±" && 
          key != "sin" && 
          key != "cos" && 
          key != "tan" && 
          key != "log" && 
          key != "ln" && 
          key != "x^" && 
          key != "x !" && 
          key != "π" && 
          key != "e" && 
          key != "rad" && 
          key != "∘"
        ) {
          if ((lastKeyWasEqual) && (key != "-") && (key != "+")){
            me.current = "";
            lastKeyWasEqual = false;
          }

          me.current += key
          
        } else if (key === '=') {
          
          if (me.current.toString().indexOf('^') > -1) {
            let base = (me.current).slice(0, (me.current).indexOf('^'))
            let exponent = (me.current).slice((me.current).indexOf('^') + 1)
            me.current = eval('Math.pow(' + base + ',' + exponent + ')')
          } else {
            me.current = eval(me.current)
          }
        
          me.current = session.fixedFloat(me.current, 3)

          lastKeyWasEqual = true;

        } else if (key === 'C') {
          
          me.current = ''
  
        } else if (key === '*') {
          
          me.current += '*'
  
        } else if (key === '/') {
          
          me.current += '/'
  
        } else if (key === '+') {
          
          me.current += '+'
  
        } else if (key === '-') {
          
          me.current += '-'
  
        } else if (key === '±') {
          
          if ((me.current).charAt(0) === '-') {
            me.current = (me.current).slice(1)
          } else {
            me.current = '-' + me.current
          }
  
        } else if (key === '<=') {
          
          me.current = me.current.substring(0, me.current.length - 1)
  
        } else if (key === '%') {
          
          me.current = me.current / 100
  
        } else if (key === 'π') {
          
          me.current = me.current * Math.PI
  
        } else if (key === 'x ²') {
          
          me.current = Math.pow(me.current,2)
  
        } else if (key === '√') {
          
          me.current = Math.sqrt(me.current)
  
        } else if (key === 'sin') {
          
          me.current = Math.sin(me.current)
  
        } else if (key === 'cos') {
          
          me.current = Math.cos(me.current)
  
        } else if (key === 'tan') {
          
          me.current = Math.tan(me.current)
  
        } else if (key === 'log') {
          
          me.current = Math.log10(me.current)
  
        } else if (key === 'ln') {
          
          me.current = Math.log(me.current)
  
        } else if (key === 'x^') {
          
          me.current += '^'
  
        } else if (key === 'x !') {
  
          let number = 1
          if (me.current === 0) {
            me.current = '1'
          } else if (me.current < 0) {
            me.current = NaN
          } else {
            let number = 1
            for (let i = me.current; i > 0; i--) {
              number *= i
            }
            me.current = number
          }
  
        } else if (key === 'e') {
          
          me.current = Math.exp(me.current)
  
        } else if (key === 'rad') {
          
          me.current = me.current * (Math.PI / 180)
  
        } else if (key === '∘') {
  
          me.current = me.current * (180 / Math.PI)
  
        }

        if(key != "="){
          lastKeyWasEqual = false;
        }
      }



      export function create() {

        let component = {
          template: JST.calculatorDialog(),
          methods: {
            open: open,
            close: close,  
            press: press,     
          },
          data: initData,
        }

        VueApp.component('calculator-dialog',component)

      }
    }
  }
}
