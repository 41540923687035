module PositiveTS {

  export module Components {
      export module FeatureOrder {
        
        export const route = "/feature-order"
        export const name = "FeatureOrder"
        
        export function getComponent() {
          return {
            template: JST.FeatureOrder(),
          }
        }
    }
  }
}

