
module PositiveTS {
export module Components {
export module PosPayment {
export module GoodiView {


  export function create() {
      var c = {
        template:  JST.GoodiView(),
        mixins: [keyPadMixin, Mixins.oneClickMixin, Mixins.paymentViewMixin],
        methods: {
          selectField: selectField,
          onKeyTapped: onKeyTapped,
          getSelectedField: getSelectedField,
          clearTapped: clearTapped,
          verifyAndPay: verifyAndPay,
          clearOldData: clearOldData,
          deleteCreditPayment: deleteCreditPayment,
          refreshView: refreshView,
          swipeCard: async function(action:string){
            app.showLoadingMessage("העבר כרטיס");
            let cardNumber = await Service.EMV.swipe(this.amount);
            if(!posUtils.isNullOrUndefinedOrEmptyString(cardNumber)){
              this.cardNumberText = cardNumber;
              if(action == 'pay') {
                this.verifyAndPay()
              } else if(action == 'balance') {
                this.checkBalance();
              }
            }
            app.hideLoadingMessage();
          },
          checkBalance: async function(){
            app.showLoadingMessage(i18next.t("goodi.awaitingResponse"));
            let balance = await Service.SmartVoucher.getBalance(Service.SmartVoucher.GOODI_HANDLER,this.cardNumberText,"0000");
            app.hideLoadingMessage();
            if(balance.success){
                app.showAlert({
                  header: i18next.t('checkBalance'),
                  content: i18next.t('voucherBalanceIs', {AMOUNT: session.fixedNumber(balance.balance)}),
                  continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            }
            else {
              app.showAlert({
              header: i18next.t('checkBalance'),
              content: balance.error,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            });
      }
          },
          useSwipe: function(){
            return (jsonConfig.getVal( jsonConfig.KEYS.usePinPadSwipe )) && (session.pos.isEmv)
          }
        },
        data: initData,
      }

      VueApp.component('goodi-view',c)
  }





  function initFields() {
    let fields = {

      'amount': {
        selected: false,
        allowDecimal: true,
        inEditMode: false,
        type: 'number',
        disabled: false
      },
      'cardNumberText': {
        selected: false,
        inEditMode: false,
        type: 'string',
        allowDecimal: false,
        disabled: false
      },

    }
    for (let field in fields) {
      fields[field].model = field;
    }
    return fields
  }

  async function refreshView(){
    let aThis = this;

    aThis.setCalculatedAmount();

    let result = await PositiveTS.Service.VoucherPayment.getVoucherPaymentsByType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI)
    aThis.creditPayments = result;
    aThis.clearTapped()
    await PositiveTS.VueInstance.$nextTick()
  }

  function verifyAndPay(){
    let aThis=this;
    if(posUtils.isBlank(this.cardNumberText)) {
      app.promiseShowAlert({
        header: i18next.t('error'),
        content: i18next.t('goodi.noCard'),
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      });
      return ;
    }
    return verify( this.amount )
    .then( ()=>{
      return PositiveTS.Service.VoucherPayment.payBySmartVoucherType(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI, aThis.amount,aThis.cardNumberText)
    })
    .catch( (e:Error)=>{
      app.hideLoadingMessage();
      app.promiseShowAlert({
        header: i18next.t('error'),
        content: e.message,
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true
      })
    })
    .then(async ()=>{
      aThis.addSplittedPaymentIfNeeded();
      aThis.cardNumberText = "";
      await aThis.refreshView();
    })

  }

  function deleteCreditPayment(index){
    var aThis = this;
      return app.promiseShowAlert({
        header: i18next.t('confirmSalePaymentItemDeleteHeader'),
        content: i18next.t('confirmSalePaymentItemDelete'),
        continueButtonText: i18next.t('remove'),
        cancelButtonText: i18next.t('cancel')
      })
      .then( (result) =>{
        // if success this.refreshView
        if (result === "continue"){
           return  PositiveTS.Service.VoucherPayment.removeSmartVoucherPayment( aThis.creditPayments[index] );
        }

      })
      .then(async ()=>{
        aThis.removeSplittedPaymentIfNeeded();
        await aThis.refreshView();
      })
  }

  function verify(amount:number):Promise<void>{
    let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
    let amountLeft = session.fixedFloat(totals.totalAmount - totals.totalPaid);

    if (amount <= 0) {
      return Promise.reject( new Error(i18next.t("newCreditCard.AMOUNT_MUST_BE_BIGGER_THAN_ZERO")))
    }
    else if (amount > amountLeft) {
      return Promise.reject( new Error(i18next.t("generalPaymentLeftAmountMandatoryError")) )
    }
    return Promise.resolve();

  }

  function clearTapped() {
    let aThis = this;
    aThis.clearOldData()
    aThis.selectField('cardNumberText')
  }


  //clears the data after a single payment but when there might be still amount to pay
  function clearOldData() {
    let aThis = this;
    let result = initData()
    for (let prop in result) {
      if (prop === 'creditPayments' || prop === "amount") {
        continue;
      }
      aThis[prop] = result[prop];
    }

  }


  function onKeyTapped(key) {
    let aThis = this

    let field = aThis.getSelectedField();
    aThis.keyPadHanlder(key,field,aThis);
  }

  function getSelectedField() {
    let aThis = this
    for (let fieldName in aThis.fields) {
      if (aThis.fields[fieldName].selected) {
        return aThis.fields[fieldName]
      }
    }
    return undefined;
  }


  function initData() {
    let types = []

    return {
      cardNumberText: "",
      fields: initFields(),
      clearImg: `${(<any>window).images_path}/close.png`,
      amount: 0,
      creditPayments: []

    }
  }

  function selectField(fieldName) {
    let aThis = this;
    let refField = null;
    //not liking this code so much... but want to make sure that number will be rounded
    aThis.amount = session.fixedFloat(aThis.amount);
    for (let field in aThis.fields) {
      aThis.fields[field].selected = false
      aThis.fields[field].inEditMode = true
      aThis.fields[field].hasDecimal = false
      if (field == fieldName) {
        aThis.fields[field].selected = true
        refField = field
      }
    }
    if (refField != null) {
      this.$refs[refField].focus();
    }
  }
}}}}
