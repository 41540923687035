module PositiveTS {
export module Components {

    export module PositiveDeliveryPreviousOrders {
      const dialogSelector = "#positive-delivery-previous-orders"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      export function openPreviousOrders(customerId, isCustomerClubMode = false){
        app.showLoadingMessage("מאתר הזמנות קודמות");
        return PositiveTS.Service.FetchReq.jsonReq(`/sales?customer_id=${customerId}`,"get")
        .then( ret=>{
          let deliveryOrders = [];
          for (let row of ret.result){
            if(isCustomerClubMode || ((JSON.parse(row.jsondata).delivery) && (JSON.parse(row.jsondata).delivery.deliveryType == "Delivery"))){
              row.formattedTime = moment(new Date(row.sold_at)).format("DD/MM/YYYY HH:mm")
              if (!isCustomerClubMode){
                row.shortDeliveryAddress = PositiveTS.Service.Delivery.getShortDeleveryCustomerAddress(row.jsondata)
              }
              row.totalAmount = JSON.parse(row.data).sale.total_amount
              row.totalQuantity = JSON.parse(row.data).sale.total_quantity
              deliveryOrders.push(row);
            }
          }
          app.hideLoadingMessage()
          return Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryPreviousOrders", args: [deliveryOrders, isCustomerClubMode]})  
         
      })
    }

     
      function _initjQueryDialogIfNeeded() {
        let aThis = this;
        if (!this.initialized) {

          //TODO: find a more elegant way to open a dialog or create a vue modal...

            let mobileLayout = Pinia.globalStore.mobileLayout;


            $(dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: "100vw", //250,
              height: mobileLayout ? window.outerHeight : 620,//380,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });

          this.initialized = true
        }


      }
      
      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          fields: initFields(),
          customerOrders: [],
          isCustomerClubMode: false,
        }
      }

      function initFields() {
        let fields = {
        }
        return fields;
      }

      function close() {
        if (this.initialized) {
          this.resolveFunc();
          $(dialogSelector).dialog('close');
        }
        this.cleanData()
      }

      function open(customerOrders, isCustomerClubMode = false) {
        this.customerOrders = customerOrders;
        return new Promise((resolve,reject) => {
          _initjQueryDialogIfNeeded.call(this)

          $(document).unbind('keypress');

          $(dialogSelector).dialog('open');
          this.isCustomerClubMode = isCustomerClubMode
          this.resolveFunc = resolve;
          this.rejectFunc = reject;


          })
          .catch(e=>{
            app.hideLoadingMessage()
            this.close();
            this.rejectFunc(e);
          })    
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t("error"),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        let aThis = this;
        app.showAlert({
          header: i18next.t("error"),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.$refs.searchField.focus();
        },null)
      }

      function confirmAndClose() {
        this.resolveFunc({approved:true, weight: Number(this.weight)})
  
        this.close();
      }

      function showOrder(saleParam){
        let sale = Service.Sale.ToStorageTypeSale(saleParam)
Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryShowOrder", args: [sale, sale.items,null,saleParam, this.isCustomerClubMode]})
      }

       async function addSaleItemsAndClose(sale){
        app.showLoadingMessage(i18next.t('delivery.loadingOrder'))
        await PositiveTS.Service.Delivery.addSaleItems(sale);
        app.showAlert({
          header: i18next.t('delivery.title'),
          content: i18next.t('delivery.prevOrderChoosen'),
          hideCancelButton: true
        }); 
        app.hideLoadingMessage();
        this.confirmAndClose();
      }

      export function create() {

        let component = {
          template: JST.positiveDeliveryPreviousOrders(),
          mixins: [],
          methods: {
            cleanData: cleanData,
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            showError: showError,
            showGenericError: showGenericError, 
            addSaleItemsAndClose: addSaleItemsAndClose,
            showOrder: showOrder,           
          },
          data: initData,
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }
        }

        VueApp.component('positive-delivery-previous-orders',component)

      }
    }
  }
}
