module PositiveTS {
    export module Shared {
        export module SaleSync {
            
            export async function countAndNotifyOfflineSales(db) {

                let syncStatusesToCount = [PositiveTS.Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT, PositiveTS.Shared.Constants.Sale.SYNC_STATUS_FAILED];

                let badSalesCount = await countBadSales(db);
                let restSalesCount = await countBySyncStatuses(db, syncStatusesToCount);
                let allOfflinesSales = restSalesCount + badSalesCount;

                let totalOfflineSales = await db.keyValueStore.where('key').equals(PositiveTS.Shared.Constants.SaleSync.totalOfflineSales).first()

                if (!totalOfflineSales || totalOfflineSales.value != allOfflinesSales) {
                    await db.keyValueStore.put({ key: PositiveTS.Shared.Constants.SaleSync.totalOfflineSales, value: allOfflinesSales })
                }

                return allOfflinesSales;
            }

            export async function countBySyncStatuses(db, syncStatuses) {
                return await db.sales.where('syncStatus')
                    .anyOf(syncStatuses)
                    .count();
            };

            export async function countBadSales(db) {
                let normalStatuses = [PositiveTS.Shared.Constants.Sale.SYNC_STATUS_FAILED, PositiveTS.Shared.Constants.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY,
                PositiveTS.Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT];
                return await db.sales.where('syncStatus')
                    .noneOf(normalStatuses)
                    .and(sale => sale.invoiceSequence > 0)
                    .count();
            }

        }
    }
}
