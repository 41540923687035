
module PositiveTS {
  export module Service {
    export interface JsonConfigKeyVal {
      key: string;
      default: any;
    }

    export class PosJsonConfig {
      static parsedJsonConfig:any;
      KEYS = {
        isEMV: { key: "isEMV", default: false },
        ClubMemberSpecialItemHndlr: { key: "ClubMemberSpecialItemHndlr", default: "positive_new_member" },
        customerUsePointsRatio: { key: "customerUsePointsRatio", default: 1 },
        gainPointsFactor: { key: "gainPointsFactor", default: 0.1 },
        customerClubMinPointsToUse: { key: "customerClubMinPointsToUse", default: 50 },
        fullClub: {key: "fullClub", default: false },
        dontGivePointsOnItemsWithPromotionOrDiscount: {key: "dontGivePointsOnItemsWithPromotionOrDiscount", default: false},
        pointsForUseInMultiplication: {key: "pointsForUseInMultiplication", default: null},
        memberOfClubs: { key: "memberOfClubs", default: ['internal'] },

        // gainable points threshold parameters
        customerFactorPctThresholdToSubstruct: { key: "customerFactorPctThresholdToSubstruct", default: undefined },
        customerFactorToSubstruct: { key: "customerFactorToSubstruct", default: undefined },

        loginUsername: { key: "loginUsername", default: '' },
        loginPassword: { key: "loginPassword", default: '' },

        PosNotVatLaiable: { key: "PosNotVatLaiable", default: 0 },

        physicalID: { key: "physicalID", default: undefined },
        searchByCode: { key: "searchByCode", default: true },

        isPrintCreditVoucherOriginOnly: { key: "isPrintCreditVoucherOriginOnly", default: true },
        isPrintCreditVoucherexpiration: { key: "isPrintCreditVoucherexpiration", default: false },

        MaxDaysForCashReturn: { key: "MaxDaysForCashReturn", default: 1 },
        minAmountForPayments: { key: "minAmountForPayments", default: 0},

        isAutoEmployeeStore: { key: "isAutoEmployeeStore", default: true },

        isXByLastCashierStatement: { key: "isXByLastCashierStatement", default: false },

        is_z_master_slave: { key: "is_z_master_slave", default: false },
        bigPaymentButtons: {key: "bigPaymentButtons", default: false},
        z_master_slave_primary_pos_device_id: { key: "z_master_slave_primary_pos_device_id", default: "" },
        isErn: { key: "isErn", default: false },
        // when canSuppressErnErrors=false && isErn = true,
        // Ern approval is Mandatory
        isCanSuppressErnErrors: { key: "isCanSuppressErnErrors", default: false },
        ernMId: { key: "ernMId", default: undefined },

        reportParametersInvoiceA4: { key: "reportParametersInvoiceA4", default: {} },

        isMultiCurr: { key: "isMultiCurr", default: false },
        creditInOriginalCashCurrencyRelatively: { key: "creditInOriginalCashCurrencyRelatively", default: false },
        loadWasmWithLocalDumps: { key: "loadWasmWithLocalDumps", default: false },
        currenciesEnabled: { key: "currenciesEnabled", default: null},
        langsEnabled: { key: "langsEnabled", default: []},
        isPrint20: { key: "isPrint20", default: true },
        simplePosValuCardTypeID: { key: "simplePosValuCardTypeID", default: "163" },
        isShowSimplePosValuCardPay: { key: "isShowSimplePosValuCardPay", default: false },
        pinPadPortNumber: { key: "pinPadPortNumber", default: "8" },
        useSmartMenu: { key: "useSmartMenu", default: false },
        quickVoucher: { key: "quickVoucher", default: false},
        //sodexo
        cibusRestaurantId: { key: "cibusRestaurantId", default: undefined},
        cibusPosId: { key: "cibusPosId", default: undefined},
        cibusDefaultCompanyId: { key: "cibusDefaultCompanyId", default: ""},
        showCibusCompanySelectOnSelfServie: { key: "showCibusCompanySelectOnSelfServie", default: false},
        mishlohaRestId: {key: "mishlohaRestId", default: ""},
        mishlohaVoucherNumber: {key: "mishlohaVoucherNumber", default: 9996},
        mishlohaCreditCardVoucherNumber: {key: "mishlohaCreditCardVoucherNumber", default: 9966},
        isMishlohaOrdersApiActive: { key: "isMishlohaOrdersApiActive", default: false},
        scaleCom:  { key: "scaleCom", default: "COM2"},
        scaleNewLineChar:  { key: "scaleNewLineChar", default: "10"},
        fingerprintReaderType:  { key: "fingerprintReaderType", default: "ZKT"},
        wifiPrinterType:  { key: "wifiPrinterType", default: "ibm"},
        scaleBaudRate:  { key: "scaleBaudRate", default: "9600"},
        standaloneMode: {key: "standaloneMode", default: false },
        mizdamenOrHakafaMode: {key: "mizdamenOrHakafaMode", default: false},
        askForClubMembershipeMode: {key: "askForClubMembershipeMode", default: false},
        isHaveToChooseClubMembership: {key: "isHaveToChooseClubMembership", default: false},
        showCash: { key: "showCash", default: true},
        showPunchCard: { key: "showPunchCard", default: false},
        showCredit: { key: "showCredit", default: true},
        showHakafa: {key: "showHakafa", default: true},
        showCheck: {key: "showCheck", default: true},
        showVoucher: {key: "showVoucher", default: true},
        allowGiftCardPaymentWithVoucher: {key: "allowGiftCardPaymentWithVoucher", default: false},
        showOtotPayment: {key: "showOtotPayment", default: false},
        allowPaymentPageHyp: {key: "allowPaymentPageHyp", default: false},
        showCreditVoucher: {key: "showCreditVoucher", default: true},
        showCibus: { key: "showCibus", default: false},
        showTenbis: { key: "showTenbis", default: false},
        showGoodi: { key: "showGoodi", default: false},
        showHoger: { key: "showHoger", default: false},
        showAllEmployees: {key: "showAllEmployees", default: false},
        isScreenLocked: { key: "isScreenLocked", default: false },
        showInvForItemButton: {key: "showInvForItemButton", default: true},
        showGiftForItemButton: {key: "showGiftForItemButton", default: true},
        alwaysShowCloseSaleDialog: {key: "alwaysShowCloseSaleDialog", default: false},
        showChangePopup: {key: "showChangePopup", default: false},
        chooseChangeCurrency: {key: "chooseChangeCurrency", default: false},
        beengoCheckEvery: {key: "beengoCheckEvery", default: 2000},
        beengoTimeout: {key: "beengoTimeout", default: 30000},
        beengoDiscountSaleItemCode: {key: "beengoDiscountSaleItemCode", default: "8888-BINGO"},
        beengoVoucherId: {key: "beengoVoucherId", default: "8888"},
        isCaveretPayment: {key: "isCaveretPayment", default: false},
        dontSeparateWeightLines: {key: "dontSeparateWeightLines", default: true},
        hideButtonMenu: {key: "hideButtonMenu", default :false},
        showCashReturnButton: {key: "showCashReturnButton", default: true},
        showCreditVoucherPaymentButton: {key: "showCreditVoucherPaymentButton", default: false},
        showCreditCardReturnButton: {key: "showCreditCardReturnButton", default: true},
        showHakafaReturnButton: {key: "showHakafaReturnButton", default: true},
        showVoucherRefundButton: {key: "showVoucherRefundButton", default: false},
        returnMoneyAsDefault: {key: "returnMoneyAsDefault", default: true},
        blockItemPriceChange: {key: "blockItemPriceChange", default: false},
        selectSalesPersonByCode: {key: "selectSalesPersonByCode", default: false},
        selectSalesPersonWithoutContinueBtn: {key: "selectSalesPersonWithoutContinueBtn", default: false},
        shouldNotOpenDrawerWithCreditCard: {key: "shouldNotOpenDrawerWithCreditCard", default: false},
        posSearchInvoicesDisableStores: {key: "posSearchInvoicesDisableStores", default: false},
        askForConfirmationDeleteSale: {key: "askForConfirmationDeleteSale", default: true},
        askForConfirmationDeleteItem: {key: "askForConfirmationDeleteItem", default: true},
        separateAllLinesAlways: {key: "separateAllLinesAlways", default: true},
        isCustomerDisplay: {key: "isCustomerDisplay", default: false},
        simpleSelfService: {key: "simpleSelfService", default: false},
        itemWithDialogXL: {key: "itemWithDialogXL", default: false},
        customerDisplayCom: {key: "customerDisplayCom", default: "COM6"},
        qrDisplay: {key: "qrDisplay", default: "PINPAD"},
        customerScreenWelcomeText: {key: "customerScreenWelcomeText", default: null},
        customerScreenGoodbyeText: {key: "customerScreenGoodbyeText", default: null},
        doNotPrintOriginalInvoice: {key: "doNotPrintOriginalInvoice", default: false},
        doNotPrintOriginalInvoiceIfCash: {key: "doNotPrintOriginalInvoiceIfCash", default: false},
        openDrawerWhenNotPrintingOriginalInvoice: {key: "openDrawerWhenNotPrintingOriginalInvoice", default: false},
        allowSetNewCustomerDiscountPercent: {key: "allowSetNewCustomerDiscountPercent", default: false},
        isLogicalPrinter: {key: "isLogicalPrinter", default: false},
        isDeleteItemManagerApproval: {key: "isDeleteItemManagerApproval", default: false},
        isManagerApproveActionBySMS:{key: "isManagerApproveActionBySMS", default: false},
        isDeleteInvoiceManagerApproval: {key: "isDeleteInvoiceManagerApproval", default: false},
        isOpenDrawerManagerApproval: {key: "isOpenDrawerManagerApproval", default: false},
        isReducingQuantityManagerApproval: {key: "isReducingQuantityManagerApproval", default: false},
        isNegativeChangingQuantityManagerApproval: {key: "isNegativeChangingQuantityManagerApproval", default: false},
        printWithChannel: { key: "printWithChannel", default: true },
        isShowQuicksaleButton: { key: "isShowQuicksaleButton", default: false },
        alwaysGetDedicatedTo: {key: "alwaysGetDedicatedTo", default: false },
        isHomeScreenAuthorizationRequired: {key: "isHomeScreenAuthorizationRequired", default: false },
        isElalPNRHomeBtnRequiresApproval: {key: "isElalPNRHomeBtnRequiresApproval", default: false },
        managerApprovalForCashManagement: {key: "managerApprovalForCashManagement", default: false },
        managerApprovalForGeneralItemPriceChange: {key: "managerApprovalForGeneralItemPriceChange", default: false},
        isEmployeesMangAuthorizationRequired: {key: "isEmployeesMangAuthorizationRequired", default: true },
        isItemsMangAuthorizationRequired: {key: "isItemsMangAuthorizationRequired", default: true },
        isHakafaCustomersMangAuthorizationRequired: {key: "isHakafaCustomersMangAuthorizationRequired", default: true },
        isRoshemetLayout: {key: "isRoshemetLayout", default: false },
        isNewPrinting: {key: "isNewPrinting", default: true },
        printItemCode: {key: "printItemCode", default: false },
        printItemMultiline: {key: "printItemMultiline", default: false },
        printItemCodeAlways: {key: "printItemCodeAlways", default: false },
        allowInvoiceSplit: {key: "allowInvoiceSplit", default: false },
        valueCardDiscountItemCode: {key: "valueCardDiscountItemCode", default: "7777"},
        goodiCardDiscountItemCode: {key: "goodiCardDiscountItemCode", default: "2120"},
        multipassDiscountItemCode: {key: "multipassDiscountItemCode", default: "MULTIPASS"},
        tenbisApiDiscountItemCode: {key: "tenbisApiDiscountItemCode", default: "TENBISAPI"},
        simplyClubJoinItemCode: {key: "simplyClubJoinItemCode", default: "simplyJ"},
        simplyClubRenewItemCode: {key: "simplyClubRenewItemCode", default: "simplyR"},
        isPunchCardSelfService: { key: "isPunchCardSelfService", default: false},
        printHakafaCustomerNumber: {key: "printHakafaCustomerNumber", default: false},
        showHakafaCustomerSonAmount: {key: "showHakafaCustomerSonAmount", default: false},
        canCreateHakafaCustomer: {key: "canCreateHakafaCustomer", default: true },
        canManageHakafaCustomerInPos: {key: "canManageHakafaCustomerInPos", default: false },
        hakafaTaxInvPrintCopyWithOriginal: {key: "hakafaTaxInvPrintCopyWithOriginal", default: false },
        printCopyWithOriginalAllDocuments: {key: "printCopyWithOriginalAllDocuments", default: false },
        hakafaShipmentInvPrintCopyWithOriginal: {key: "hakafaShipmentInvPrintCopyWithOriginal", default: false },
        hakafaShipmentInvPrintWithNoPrice: {key: "hakafaShipmentInvPrintWithNoPrice", default: false },
        isMultipassItemInterface: { key: "isMultipassItemInterface", default: false },
        sandiManUsername: { key: "sandiManUsername", default: "" },
        useSandiManForCibus: { key: "useSandiManForCibus", default: false },
        useSandiManForMishloha: { key: "useSandiManForMishloha", default: false },
        useSandiManForTenBis: { key: "useSandiManForTenBis", default: false },
        taItemCode: {key: "taItemCode", default: "6666"},
        sittingItemCode: {key: "sittingItemCode", default: "3333"},
        showTip: {key: "showTip", default: false},
        payCashInSelfService: {key: "payCashInSelfService", default: false},
        paymentForOrderCompletion: {key: "paymentForOrderCompletion", default: false},
        deliveryItemCode: {key: "deliveryItemCode", default: "5555"},
        cibusDeliveriesAPIUser: {key: "cibusDeliveriesAPIUser", default: ""},
        parameterMidForPaymentPage: {key: "parameterMidForPaymentPage", default: ""},
        isGatewayPayment: {key: "isGatewayPayment", default: false},
        gatewayEmvPosNum: { key: "gatewayEmvPosNum", default: "" },
        cibusDeliveriesAPIPassword: {key: "cibusDeliveriesAPIPassword", default: ""},
        cibusDeliveriesResID: {key: "cibusDeliveriesResID", default: ""},
        digitradeOrdersAPIUser: {key: "digitradeOrdersAPIUser", default: ""},
        digitradeOrdersAPIPassword: {key: "digitradeOrdersAPIPassword", default: ""},
        digitradeOrdersResID: {key: "digitradeOrdersResID", default: ""},
        cashCowToken: {key: "cashCowToken", default: ""},
        cashCowStoreId: {key: "cashCowStoreId", default: 0},
        cashCowVoucherNumber: {key: "cashCowVoucherNumber", default:9998},
        digitradeBaseUrl: {key: "digitradeBaseUrl", default: ""},
        digitradeVoucherNumber: {key: "digitradeVoucherNumber", default:9997},
        j5Payment: {key: "j5Payment", default:false},
        deliveryDefaultCity: {key: "deliveryDefaultCity", default: ""},
        allowBelowMinimunOrders: {key: "allowBelowMinimunOrders", default: false},
        tenbisApiGenericItem: {key: "tenbisApiGenericItem", default: "8888"},
        isDelivery:{key: "isDelivery", default: false},
        allowBonPrinting: { key: "allowBonPrinting", default: false },
        allowBonPrintingAndHold: { key: "allowBonPrintingAndHold", default: false},
        allowQuickBonPrinting: { key: "allowQuickBonPrinting", default: false},
        allowQuickAccountActions: { key: "allowQuickAccountActions", default: false},
        allowBonCancelPrinting: {key: "allowBonCancelPrinting", default: true},
        allowBonCancelPrintingOnCreditInvoice: {key: "allowBonCancelPrintingOnCreditInvoice", default: true},
        printBonItemsOnSeperateBons: { key: "printBonItemsOnSeperateBons", default: false},
        hidePositiveAddCustomer: { key: "hidePositiveAddCustomer", default: false },
        postName: { key: "postName", default: "" },
        isNameOnBonPrecede: { key: "isNameOnBonPrecede", default: false},
        doNotAskForConfirmationNumber: { key: "doNotAskForConfirmationNumber", default: false},
        doNotSeachInternalCustomerClubByName: { key: "doNotSeachInternalCustomerClubByName", default: false},
        autoPopSelectItemPackage: { key: "autoPopSelectItemPackage", default: false},
        isShowSelfServiceHakafa: { key: "isShowSelfServiceHakafa", default: false},
        isShowSelfServicePayWithValuCard: { key: "isShowSelfServicePayWithValuCard", default: true},
        selfServiceShowCheckBalance: { key: "selfServiceShowCheckBalance", default: false},
        selfServiceShowCustomerNameAfterPaymentMethod: { key: "selfServiceShowCustomerNameAfterPaymentMethod", default: false},
        selfServiceKeyboardBiggerFont: { key: "selfServiceKeyboardBiggerFont", default: false},
        selfServiceEnableTheftDetection: { key: "selfServiceEnableTheftDetection", default: false},
        selfServiceTheftDetectionRules: {key: "selfServiceTheftDetectionRules", default: []},
        isSelfServiceSuperMarketPos: {key: "isSelfServiceSuperMarketPos", default: false},
        requestManagerApprovalOnItemSearch: {key: "requestManagerApprovalOnItemSearch", default: false},
        selfServiceInactivityTimer: {key: "selfServiceInactivityTimer", default: 0},
        selfServiceEnableScreenSaver: {key: "selfServiceEnableScreenSaver", default: false},
        selfServiceBackgroundImage: {key: "selfServiceBackgroundImage", default: null},
        isLockedPinpadSelfService: {key: "isLockedPinpadSelfService", default: false},
        selfServiceScreenSaverTimer: {key: "selfServiceScreenSaverTimer", default: 1},
        selfServiceScreenSaverImageTimer: {key: "selfServiceScreenSaverImageTimer", default: 5},
        selfServiceScreenSaverType: {key: "selfServiceScreenSaverType", default: 'picture'},
        selfServiceScreenSaverSelectedImages: {key: "selfServiceScreenSaverSelectedImages", default: []},
        selfServiceScreenSaverSelectedVideos: {key: "selfServiceScreenSaverSelectedVideos", default: []},
        customerDisplayWithImgs: {key: "customerDisplayWithImgs", default: false },
        customerDisplayWithCurrenciesScreen: {key: "customerDisplayWithCurrenciesScreen", default: false },
        customerDisplayIdleImageIds: {key: "customerDisplayIdleImageIds", default: [] },
        customerDisplayOnSaleImagesTimeout: {key: "customerDisplayOnSaleImagesTimeout", default: 10 },
        customerDisplayOnSaleImageId: {key: "customerDisplayOnSaleImageId", default: null },
        selfServiceEnableAfterSaleScreen: {key: "selfServiceEnableAfterSaleScreen", default: false},
        assetsScreenConfirmation: {key: "assetsScreenConfirmation", default: false},
        selfServiceToggleButton : {key: "selfServiceToggleButton", default: false},
        showScrollArrowButtons : {key: "showScrollArrowButtons", default: false},
        selfServiceShowTotalOrderBeforePay : {key: "selfServiceShowTotalOrderBeforePay", default: true},
        selfServiceSittingOrTaScreen : {key: "selfServiceSittingOrTaScreen", default: false},
        enableSittingOrTA : {key: "enableSittingOrTA", default: false},
        selfServiceSupermarketAllowPayments: {key: "selfServiceSupermarketAllowPayments", default: false},
        selfServiceMidragPayments: {key: "selfServiceMidragPayments", default: false},
        selfServiceHakafaBtnDescription: { key: "selfServiceHakafaBtnDescription", default: "תשלום בהקפה"},
        selfServiceColorPickerForHeadlines: { key: "selfServiceColorPickerForHeadlines", default: "#111111"},
        shekuloTovBooksService: {key: "shekuloTovBooksService", default: false},
        isPrimaryDeliveryPos: {key: "isPrimaryDeliveryPos", default: false},
        usePinPadSwipe: {key:"usePinPadSwipe", default: false},
        useQRCode: {key:"useQRCode", default: false},
        maxSaleAmount: { key: "maxSaleAmount", default: 100000},
        hasDarkTheme: {key: "hasDarkTheme", default: false},
        hideCustomerBalance: {key: "hideCustomerBalance", default: false},
        useOnScanLibrary: {key: "useOnScanLibrary", default: false},
        forceInvoiceClosingOnHakafa: {key: "forceInvoiceClosingOnHakafa", default: false},
        overrideParameterInvoiceType: { key: "overrideParameterInvoiceType", default: null},
        showPaymentButtonsInStandAlone: { key: "showPaymentButtonsInStandAlone", default: false},
        sprateBtnImgText: { key: "sprateBtnImgText", default: false},
        fullBtnImgText: { key: "fullBtnImgText", default: false},
        isDalpakim: {key: "isDalpakim", default: false },
        isDalpakimOnline: {key: "isDalpakimOnline", default: false },
        dalpakTablesView: {key: "dalpakTablesView", default: false },
        stayInDalpakScreenAfterSale : {key: "stayInDalpakScreenAfterSale", default: false },
        isDalpakEditPermissionRequired: {key: "isDalpakEditPermissionRequired", default: false},
        dalpakimNewInstractionsScreen: {key: "dalpakimNewInstractionsScreen", default: false},
        restaurantNewPOS: {key: "restaurantNewPOS", default: false},
        restaurantNewPOSExpandedDefault: {key: "restaurantNewPOSExpandedDefault", default: false},
        hasQuickAccessToPrintBill: {key: "hasQuickAccessToPrintBill", default: false},
        tableWarningTimeInMin: {key: "tableWarningTimeInMin", default: null},
        hasDiners: {key: "hasDiners", default: false },
        dalpkaimPrintOnlyInMainPos: {key: "dalpkaimPrintOnlyInMainPos", default: false },
        printBonOnDalpakExit: {key: "printBonOnDalpakExit", default: true },
        modbusServer: {key: "modbusServer", default: null },
        zplLabelPrinterName: {key: "zplLabelPrinterName", default: null },
        zplPrinterLabelSize: {key: "zplPrinterLabelSize", default: '26.5mm' },
        forceQRScanWithUsbScanner: {key: "forceQRScanWithUsbScanner", default: false },
        isAndroidExernalCashDrawer: {key: "isAndroidExernalCashDrawer", default: false },
        allowOutOfStockItems: {key: "allowOutOfStockItems", default: false },
        outOfStockItemMessage: {key: "outOfStockItemMessage", default: null },
        forceLogRocket: {key: "forceLogRocket", default: false },
        createItemsFromPos: {key: "createItemsFromPos", default: false },
        selfServiceCancelPreparationsInstructionsNotes: {key: "selfServiceCancelPreparationsInstructionsNotes", default: false },
        returnToMainMenuAfterSelectItemFromMenu: {key: 'returnToMainMenuAfterSelectItemFromMenu', default: false},
        blockDeliveryOnSaleWithItems: {key: "blockDeliveryOnSaleWithItems", default: true },
        dailyZReportTime: {key: "dailyZReportTime", default: null },
        numberOfMenuItemsPerRow: {key: "numberOfMenuItemsPerRow", default: null },
        isTenbisDeliveryApiActive: {key: "isTenbisDeliveryApiActive", default: false },
        isPaiditDeliveryApiActive: { key: "isPaiditDeliveryApiActive", default: false},
        isCibusDeliveryApiActive: { key: "isCibusDeliveryApiActive", default: false},
        isDigitradeOrdersApiActive: { key: "isDigitradeOrdersApiActive", default: false},
        isCashCowOrdersApiActive: { key: "isCashCowOrdersApiActive", default: false},
        autoTenbisOrderPrintInvoice: {key: "autoTenbisOrderPrintInvoice", default: true },
        printItemListBon: { key: "printItemListBon", default: true},
        soundNotificationDeliveryAtIncomingDelivery: { key: "soundNotificationDeliveryAtIncomingDelivery", default: false},
        directHakafaPayment: { key: "directHakafaPayment", default: false },
        allowCreditLastInvoiceWithoutApproval: { key: "allowCreditLastInvoiceWithoutApproval", default: false},
        bonLineSpace: { key: "bonLineSpace", default: false },
        bonFontHuge: { key: "bonFontHuge", default: false },
        forceCashDeclarations: {key: "forceCashDeclarations", default: false},
        hideCashAmountOnDeclarations: {key: "hideCashAmountOnDeclarations", default: false},
        requireEmployeeLoginOnCashStatements: {key: "requireEmployeeLoginOnCashStatements", default: false},
        atmTerminalNumber: {key: "atmTerminalNumber", default: null},
        emvPosNumber: {key: "emvPosNumber", default: null},
        emvIpAddress: {key: "emvIpAddress", default: null},
        adaptiveEmvPosNumber: { key: "adaptiveEmvPosNumber", default: false },
        allowManualFirstPayment: { key: "allowManualFirstPayment", default: false },
        allowedDeclarationToXDifference: {key: "allowedDeclarationToXDifference", default: 0},
        prohibitZWhenSalesOnHold: { key: "prohibitZWhenSalesOnHold", default: false },
        dontEraseEnterStatementOnZ: { key: "dontEraseEnterStatementOnZ", default: false},
        showMultiCurrCoinGap: { key: "showMultiCurrCoinGap", default: false},
        goodiTerminalId: {key: "goodiTerminalId", default: null},
        goodiRestaurantId: {key: "goodiRestaurantId", default:null},
        dtsId: {key: "dtsId", default:null},
        yaadTerminalID: {key: "yaadTerminalID", default: null},
        yaadApiToken: {key: "yaadApiToken", default: null},
        showYaadPayment:{key: "showYaadPayment", default: false},
        allowSplitSalePayment:{key: "allowSplitSalePayment", default: false},
        showSplitSalePaymentButton:{key: "showSplitSalePaymentButton", default: false},
        showPaymentPageHypButton:{key: "showPaymentPageHypButton", default: false},
        multipleRowsPaymentButtons:{key: "multipleRowsPaymentButtons", default: false},
        splitTotalAmountEqually:{key: "splitTotalAmountEqually", default: false},
        hasCaveretStarterShortcut: { key: "hasCaveretStarterShortcut", default: false },
        caveretPaymentClubs: { key: "caveretPaymentClubs", default: {} },
        passwordProtected: {key: "passwordProtected", default: false},
        useCreditInvoiceOnMinusSale: {key: "useCreditInvoiceOnMinusSale", default: true},
        paginatePastInvoices: { key: "paginatePastInvoices", default:false},
        hasOnlyOnlineCustomerClub: { key: "hasOnlyOnlineCustomerClub", default:false},
        selfServiceOpeningHours:{key:'selfServiceOpeningHours',default:[]},
        allowCreateCustomerWithoutName: { key: "allowCreateCustomerWithoutName", default:false},
        disableManualCreditCardPayment: { key: "disableManualCreditCardPayment", default:false},
        cashPaymentLimit: { key: "cashPaymentLimit", default: 6000},
        checkPaymentsLimitMax: {key : "checkPaymentsLimitMax" ,default : 99},
        printCreditCardInvoicesWithCopyOnA4: { key: "printCreditCardInvoicesWithCopyOnA4", default: false},
        hideTzOnChecks: { key: "hideTzOnChecks", default:false},
        hideDiscounts: {key: "hideDiscounts", default:false},
        hideSearchBtn: {key: "hideSearchBtn", default:false},
        shortEmvSlip: {key: "shortEmvSlip", default: false},
        shortZSlip: {key: "shortZSlip", default: false},
        printCatsInRikuzZ: {key: "printCatsInRikuzZ", default: false},
        printGrandTotal: {key: "printGrandTotal", default: false},
        printOnlyEMVReportSessionNumbersInZ: {key: "printOnlyEMVReportSessionNumbersInZ", default: false},
        printItemDetailsInZReport: {key: "printItemDetailsInZReport", default: false},
        dontPrintVatRowsIfIsZero: {key: "dontPrintVatRowsIfIsZero", default: false},
        printVatItemLinesOnA4Invoice: {key: "printVatItemLinesOnA4Invoice", default: false},
        printDiscountAsColoumnOnA4Invoices: {key: "printDiscountAsColoumnOnA4Invoices", default: false},
        printDiscountsInPercentagesOnA4Invoices: {key:"printDiscountsInPercentagesOnA4Invoices", default: false},
        cancelPrintZReport: {key:"cancelPrintZReport", default: false},
        doNotPrintCahsierOnInvoice: {key:"doNotPrintCahsierOnInvoice", default: false},
        isDutyFree: {key: "isDutyFree", default: false},
        isPickupEnabledDutyFree: {key: "isPickupEnabledDutyFree", default: true},
        maxPriceForDutyFreePickupItem: {key: "maxPriceForDutyFreePickupItem", default: null },
        isPickupEnabledShips: {key: "isPickupEnabledShips", default: false},
        maxAmountPickupForFlightcard: {key: 'maxAmountPickupForFlightcard', default: 400},
        allowItemsChangeFromCreditInvoice: {key: 'allowItemsChangeFromCreditInvoice', default: true},
        scaleBarcodeIsByWeight: {key: "scaleBarcodeIsByWeight", default: false},
        hideReturnItemWeight: {key: "hideReturnItemWeight", default:false},
        usePictures: {key: 'usePictures', default: false},
        usePinpadInSwitchMode: {key: "usePinpadInSwitchMode", default:false},
        transmitTranToSwitchWhenBackToOnline: {key: "transmitTranToSwitchWhenBackToOnline", default: false},
        dontPrintMerchantCreditCardSlip: {key: "dontPrintMerchantCreditCardSlip", default:false},
        dontPrintWhenEmployeeStartOrEndShift: {key:"dontPrintWhenEmployeeStartOrEndShift", default: false },
        exactSearchAndBarcodeScan: {key: 'exactSearchAndBarcodeScan', default: false},
        barcodeSearchOnly: {key: 'barcodeSearchOnly', default: false},
        disableHoldSale: {key: 'disableHoldSale', default: false},
        showTogglePromotionsBtn: {key: 'showTogglePromotionsBtn', default: true},
        disableReScale: {key: 'disableReScale', default: false},
        disablePrintPerforma: {key: 'disablePrintPerforma', default: false},
        displayPricesOnButtons: {key: "displayPricesOnButtons", default: true},
        disableSaleFiltering: {key: "disableSaleFiltering", default: false},
        saleFilteringFaileds: {key: "saleFilteringFaileds", default: true},
        hasCashWithdrawal: {key: "hasCashWithdrawal", default: false},
        cashWithdrawalMinAmount: {key: "cashWithdrawalMinAmount",default: 50},
        cashWithdrawalMaxAmount: {key: "cashWithdrawalMaxAmount", default: 500},
        cashWithdrawalCancelMaxDays: {key: "cashWithdrawalCancelMaxDays", default: 7},
        cashWithdrawalMultiplyBy:{key: "cashWithdrawalMultiplyBy", default: 50},
        itemQueryOnlyPos: {key: "itemQueryOnlyPos", default: false},
        lang: {key: "lang", default: 'he'},
        forceCustomerOnBigSales: {key: "forceCustomerOnBigSales", default: false},
        showPaymentBanx: {key: 'showPaymentBanx', default: false},
        showPaymentSafeCash: {key: 'showPaymentSafeCash', default: false},
        safeCashUserName: {key: 'safeCashUserName', default: null},
        safeCashPassword: {key: 'safeCashPassword', default: null},
        simplyClubAccountId: {key: 'simplyClubAccountId', default: null},
        simplyClubUser: {key: 'simplyClubUser', default: null},
        simplyClubPassword: {key: 'simplyClubPassword', default: null},
        takeSmartStandNumber: {key: 'takeSmartStandNumber', default: ""},
        bypassElalActiveDirectoryCheck: {key: 'bypassElalActiveDirectoryCheck', default: false},
        showInterServicePayment: {key: 'showInterServicePayment', default: false},
        forceZEveryDay: {key: 'forceZEveryDay', default: false},
        isPicturesPrinter: {key: "isPicturesPrinter", default:false},
        askPrinterTypeForInvoice: {key: "askPrinterTypeForInvoice", default:false},
        printWithPrimaryPos: {key: "printWithPrimaryPos", default:false},
        isPrimaryPosPrinter: {key: "isPrimaryPosPrinter", default:false},
        isAllowedSendSmsInvoice: {key: "isAllowedSendSmsInvoice", default:false},
        openDrawerOnCashSalesWithSmsInvoice: {key: "openDrawerOnCashSalesWithSmsInvoice", default: false},
        sendSmsAtSaleClose: {key: "sendSmsAtSaleClose", default:false},
        showPrintButtonsOnSendSmsSale: {key: "showPrintButtonsOnSendSmsSale", default:true},
        canChangeQuantityWithDecimalFraction: {key: "canChangeQuantityWithDecimalFraction", default:false},
        smsAlertIfQuotaBelow: {key: "smsAlertIfQuotaBelow", default: 100},
        smsAlertEveryXMessagesIfLowQuota: {key: "smsAlertEveryXMessagesIfLowQuota", default: 10},
        smsAlertEveryMessageIfQuotaBelow: {key: "smsAlertEveryMessageIfQuotaBelow", default: 5},
        showSendSmsButton: {key: "showSendSmsButton", default:false},
        textToSendToCustomerInSms: {key: "textToSendToCustomerInSms", default: ""},
        isDeliveriesTablet: {key: "isDeliveriesTablet", default: false},
        useAmericanTimeAndDateFormat: {key: "useAmericanTimeAndDateFormat", default: false},
        showCurrencySign: {key: "showCurrencySign", default: false},
        hoursToSaveSales: {key: "hoursToSaveSales", default: null},
        isAndroidWebviewRefresh: {key: "isAndroidWebviewRefresh", default:false},
        allowedCurrenciesPerPaymentMethod: {key:"allowedCurrenciesPerPaymentMethod",default: null},
        approvalOnPrinterLineCut: {key: "approvalOnPrinterLineCut", default:false},
        waitOnPrinterLineCut: {key: "waitOnPrinterLineCut", default:false},
        timesToPrintBon: {key: "timesToPrintBon", default:1},
        printDeliveryInformationOnBon: {key: "printDeliveryInformationOnBon", default: false},
        printSalesPersonInBigFont: {key: "printSalesPersonInBigFont", default: false},
        unflattenSaleItemsOnBon: {key: "unflattenSaleItemsOnBon", default: true},
        isIsrairPos: {key: "isIsrairPos", default:false},
        sunmiInternalEMVPayment: {key: "sunmiInternalEMVPayment", default:false},
        TabletDefaultOrientation: {key: "TabletDefaultOrientation", default:null},
        emvRecoveryBeforePaymentScreen: {key: "emvRecoveryBeforePaymentScreen", default:false},
        emvTimeout: { key: 'emvTimeout', default: 60},
        atmTimeout: { key: 'atmTimeout', default: 120},
        visibleCreditCardPaymentTypes:{key: "visibleCreditCardPaymentTypes", default: null},
        moneyOnlyRefund: {key: "moneyOnlyRefund", default: false},
        discountOnlyGetGroupOnPromotions: { key: "discountOnlyGetGroupOnPromotions", default: false },
        fullSaleRefundOnly: {key: "fullSaleRefundOnly", default: false},
        validateEmailOnAddingCustomer: {key: "validateEmailOnAddingCustomer", default: false},
        validatePhoneOnAddingCustomer: {key: "validatePhoneOnAddingCustomer", default: false},
        posCustomersReportByHakafaCustomers: {key: "posCustomersReportByHakafaCustomers", default: true},
        allowGiftCardWithCustomerClub: {key: "allowGiftCardWithCustomerClub", default: false},
        allowExtensionValidUntilCustomerClub: {key: "allowExtensionValidUntilCustomerClub", default: false},
        allowToEditCustomerClub: { key: "allowToEditCustomerClub", default: false },
        allowToEditCustomerClubValidUntil: { key: "allowToEditCustomerClubValidUntil", default: false },
        forbidUsingPointsOnUnaccumulableItems: { key: "forbidUsingPointsOnUnaccumulableItems", default: false },
        showGoToCustomerCommentsButton: { key: "showGoToCustomerCommentsButton", default: false },
        mobilePhoneRepairModule: {key: "mobilePhoneRepairModule", default: false},
        addressCityAndApartmentFieldsRequired: {key: "addressCityAndApartmentFieldsRequired", default: false},
        mobilePhoneRepairItemCode: {key: "mobilePhoneRepairItemCode", default: '1973'},
        externalOrderCheckInterval: {key: "externalOrderCheckInterval", default: 10},
        internetOrderDiscountCode: {key: "internetOrderDiscountCode", default: 8888},
        keepAliveEmv: {key: "keepAliveEmv", default: false},
        keepAliveEmvMinutesInterval: {key: "keepAliveEmvMinutesInterval", default: 30},
        multipassPoliceOrganizationID: { key: "multipassPoliceOrganizationID", default: null},
        pinpadCommunicationErrors: { key: "pinpadCommunicationErrors", default: "10043,10053,10041"},
        multipassPoliceOrganizationUser: { key: "multipassPoliceOrganizationUser", default: null},
        multipassPoliceOrganizationPassword: { key: "multipassPoliceOrganizationPassword", default: null},
        multipassPolicePosId: { key: "multipassPolicePosId", default: null },
        multipassPoliceDiscount: { key: "multipassPoliceDiscount", default: 0 },
        multipassPoliceDisablePromos: { key: "multipassPoliceDisablePromos", default: false },
        multipassPoliceVirtualPointNumber: { key: "multipassPoliceVirtualPointNumber", default: 0 },
        multipassPoliceDisplaySpecificPoints: { key: "multipassPoliceDisplaySpecificPoints", default: '' },
        multipassWalletsPriority:  {key: "multipassWalletsPriority", default: ""},
        multipassPoliceIDF:  {key: "multipassPoliceIDF", default: false},
        multipassPoliceShouldSaveCustomerInSale:  {key: "multipassPoliceShouldSaveCustomerInSale", default: false},        
        isCustomerDisplayNewDesign:  {key: "isCustomerDisplayNewDesign", default: false},        
        identificationEmployeeByFingerPrintOnly: {key: 'identificationEmployeeByFingerPrintOnly', default: false},
        identificationManagerByFingerPrintOnly: {key: 'identificationManagerByFingerPrintOnly', default: false},
        nirDavidScanEntranceBarcode: {key: 'nirDavidScanEntranceBarcode', default: false},
        nirDavidEntranceItemCode: {key: 'nirDavidEntranceItemCode', default: null},
        printEmployeeTimeReportInOldFormat: {key: 'printEmployeeTimeReportInOldFormat', default: false},
        nirDavidVoucherCode: {key: 'nirDavidVoucherCode', default: null},
        nirDavidBaseUrl: {key: 'nirDavidBaseUrl', default: 'https://nirdavid-stage.mly.co.il/wp-json/mly/v1/'},
        allowBonPrintingOnEcommerceOrder: {key: "allowBonPrintingOnEcommerceOrder", default: false},
        openDrawerOnReceiveDelivery: {key: 'openDrawerOnReceiveDelivery', default: false},
        openDrawerOnSaleClosed: {key: 'openDrawerOnSaleClosed', default: false},
        showOpenDrawerOnSelectDalpaks: {key: 'showOpenDrawerOnSelectDalpaks', default: false},
        alignItemsMenuToCenter: {key: 'alignItemsMenuToCenter', default: false},
        showSalespersonOnPastInovices: {key: 'showSalespersonOnPastInovices', default: false},
        allowMultipleItemPackages: {key: 'allowMultipleItemPackages', default: false},
        askToPrintPerformaWithoutItemPrices: {key: 'askToPrintPerformaWithoutItemPrices', default: false},
        allowMultipleSaleItemsSelection: {key: 'allowMultipleSaleItemsSelection', default: false},
        icMegaItems: {key: 'icMegaItems', default: []},
        icMegaSapakId: {key: 'icMegaSapakId', default: null},
        showPaymentIcMega: {key: 'showPaymentIcMega', default: false},
        printBonsWithBigFontCustomerName: {key: 'printBonsWithBigFontCustomerName', default: false},
        printUpdateBonsWithForItemInfo: {key: 'printUpdateBonsWithForItemInfo', default: false},
        printBonOnMoveDalpak: {key: 'printBonOnMoveDalpak', default: false},
        ototFingerprint: {key: 'ototFingerprint', default: null},
        ototPurchaseTagItemCode: {key: 'ototPurchaseTagItemCode', default: null},
        showOtotPurchaseByGamePoints: {key: 'showOtotPurchaseByGamePoints', default: null},
        showOtotPrintBraceletButton: {key: 'showOtotPrintBraceletButton', default: false},
        showOtotReprintQrCode: {key: 'showOtotReprintQrCode', default: false},
        supportOtotRFID: {key: 'supportOtotRFID', default: false},
        showOtotRFIDBraceletActivation: {key: 'showOtotRFIDBraceletActivation', default: false},
        ototPrintTagOnPrinterPos: {key: 'ototPrintTagOnPrinterPos', default: false},
        ototPurchaseTagMode: {key: 'ototPurchaseTagMode', default: 0},
        deliveryApis: {key: 'deliveryApis', default: []},
        withdrawalFailurePinPadRefreshTime: {key: 'withdrawalFailurePinPadRefreshTime', default: 20000},
        withdrawalFailureMaxNumberOfAttempts: {key: 'withdrawalFailureMaxNumberOfAttempts', default: 6},
        migvanIds: {key: 'migvanIds', default: null},
        syncServerUrls: {key: 'syncServerUrls', default: ['gruper.valu.co.il', 'leandro.valu.co.il']},
        sumSalesDiscountReportOnZReport: {key: "sumSalesDiscountReportOnZReport", default: false},
        allowToReplaceItemsInReplacementSale: {key: 'allowToReplaceItemsInReplacementSale', default: false},
        showPromotionsOnSearchItemPos: {key: 'showPromotionsOnSearchItemPos', default: false},
        allowSaleRemarks: {key: 'allowSaleRemarks', default: false},
        isAllowToReplaceItemsInReplacementSaleAuthorizationRequired: {key: 'isAllowToReplaceItemsInReplacementSaleAuthorizationRequired', default: false},
        preventUseOfGoogleTranslate: {key: 'preventUseOfGoogleTranslate', default: false},
        allowToDisablePromotionsOnItem: {key: "allowToDisablePromotionsOnItem", default: false},
        showLastItemAddedInPos: {key: 'showLastItemAddedInPos', default: false},
        addItemWithGroupIfLastGroupGotMaxAndOtherGroupsGotMin: {key: 'addItemWithGroupIfLastGroupGotMaxAndOtherGroupsGotMin', default: false},
        showItemDetails: {key: 'showItemDetails', default: false},
        selfServiceScrollMenu: {key: 'selfServiceScrollMenu', default: false},
        selfServiceNewItemButtonStyle: {key: 'selfServiceNewItemButtonStyle', default: false},
        selfServiceAfterSaleTitle: { key: "selfServiceAfterSaleTitle", default: ""},
        selfServiceAfterSaleSubTitle: { key: "selfServiceAfterSaleSubTitle", default: ""},
        selfServiceAfterSaleDuration: { key: "selfServiceAfterSaleDuration", default: 4.8 },
        selfServiceQuickSale: {key: 'selfServiceQuickSale', default: false},
        selfServiceAlignPrintingSettings: {key: 'selfServiceAlignPrintingSettings', default: false},
        selfServiceMultiLang: {key: 'selfServiceMultiLang', default: false},
        useShortDetails: {key: 'useShortDetails', default: false},
        isAlignSelfService: {key: 'isAlignSelfService', default: false},
        alignSelfServiceTitle: {key: 'alignSelfServiceTitle', default: ''},
        alignSelfServiceSubtitle: {key: 'alignSelfServiceSubtitle', default: ''},
        alignSelfServiceLunchItem: {key: 'alignSelfServiceLunchItem', default: ''},
        fastChangeItemPriceByKeypad: {key: 'fastChangeItemPriceByKeypad', default: false},
        flightPass: {key: 'flightPass', default: false},
        isCancelOpacity: {key: 'isCancelOpacity', default: false},
        shouldCacheCatalog: {key: 'shouldCacheCatalog', default: false},
        cannotAllowDeleteItemsWhenPaymentMethodIsActive: {
          key: 'cannotAllowDeleteItemsWhenPaymentMethodIsActive',
          default: false
        }
      };

      /*
       * Usage:
       * a.getVal(a.KEYS.ClubMemberSpecialItemHndlr)
       */
      getVal(keyDict: JsonConfigKeyVal): any {
        var _return = keyDict.default;

        if (PosJsonConfig.parsedJsonConfig == null) {
          PosJsonConfig.parsedJsonConfig = JSON.parse(localStorage.getItem("json_config"));
        }

        if (PosJsonConfig.parsedJsonConfig !== null && typeof (PosJsonConfig.parsedJsonConfig[keyDict.key]) !== 'undefined') {
          _return = PosJsonConfig.parsedJsonConfig[keyDict.key];
        }

        return _return;
      };

      setVal(keyDict: JsonConfigKeyVal, val: any): void {

        if (PosJsonConfig.parsedJsonConfig == null) {
          PosJsonConfig.parsedJsonConfig = JSON.parse(localStorage.getItem("json_config"));
        }

        if (val == undefined) {
          PosJsonConfig.parsedJsonConfig[keyDict.key] = keyDict.default;
        } else {
          PosJsonConfig.parsedJsonConfig[keyDict.key] = val;
        }
        localStorage.setItem("json_config", JSON.stringify(PosJsonConfig.parsedJsonConfig));
      };

      showString(): string {
        return localStorage.getItem("json_config");
      };

      showDict(): any {
        return JSON.parse(localStorage.getItem("json_config"));
      };

      async updateLocalStorageFromAjax(): Promise<any> {
        let data = { devicePhysicalId: session.pos.physicalID, deviceId: session.pos.deviceID };
        let INTERFACE_URL = "/getposparams/";

        let result = await PositiveTS.Service.Ajax.promiseJqAjax(INTERFACE_URL, data, "POST")
        let results = JSON.parse(result.request.result);

        if (results.valid === false) {
          throw new Error(results.message);
        }
        let _res = await  session.persistHomeControllerResult(jsonConfig.showDict().loginUsername,
                                                              jsonConfig.showDict().loginPassword,results);
        return _res;
      };


      updateLocalStorageFromResults(results, loginUsername, loginPassword): void {
        var _json_config = JSON.parse(results.json_config);
        _json_config.physicalID = session.pos.physicalID;
        _json_config.loginUsername = loginUsername;
        _json_config.loginPassword = loginPassword;
        _json_config.is_z_master_slave = results.is_z_master_slave;
        _json_config.z_master_slave_primary_pos_device_id = results.z_master_slave_primary_pos_device_id;
        var sjson_config = JSON.stringify(_json_config);

        localStorage.setItem("json_config", sjson_config);
      };

    }
  }
}


declare var jsonConfig: PositiveTS.Service.PosJsonConfig;
jsonConfig = new PositiveTS.Service.PosJsonConfig();
