import EmvOutput from "./EmvOutput";
import { isNullOrUndefined, xml2json } from "../../Utils";
import PinpadConfig from "./PinpadConfig";
import * as moment from 'moment';
import IAddon from "../../pinpad/interfaces/IAddon";
import ILogger from "../../ILogger";
import ILoadingMessageAndDialogManager from "../../ILoadingMessageAndDialogManager";
import KeepAlive from "./KeepAlive";
import _ from 'loadsh';


export default class Pinpad {
  protected pinpadTransactionRunning = false;
  protected keepAlive: KeepAlive = null;
  
  constructor(protected configurations: PinpadConfig,
              protected addon: IAddon,
              protected loadingMessageAndDialogManager: ILoadingMessageAndDialogManager,
              protected logger: ILogger) {
  }

    async killPinpadAddon() {
      if(this.configurations.isNewVersionAddonFiles && !this.configurations.isHttpConnection) {
        if(this.configurations.isAndroid){
          Android.StopPCL();
          Android.StartPinpadConnection();
        }else{
          await this.addon.sendDataToPinpad({xml:"exit"}, "pinpad_addon");
        }
      }
      return ;
    }

    async initializePinpadAddon() {
      await this.killPinpadAddon();

      if(this.keepAlive) {
        this.keepAlive.startKeepAlive();
      }

      return;
    }

    getXmlRequest(command: string, timeout:number, additionalParams?:any, customRequestId?:string, androidIntentTimeout?:number) {
        let terminalNumber
        let request:any = {
            rawXmlMode: true,
            Command: command
        }
        let requestId = customRequestId ? customRequestId : moment(new Date()).format("YYYYMMDDHHmmss");
        let posNumber = this.configurations.posNumber;
        if(Number(command) < 100 || Number(command) > 199) {
            terminalNumber = this.configurations.terminalNumber;
        } else {
            terminalNumber = this.configurations.atmTerminalNumber;
        }
        request.rawXml = `<Request>
          <Command>${command}</Command>
          <TerminalId>${terminalNumber}</TerminalId>
          <TimeoutInSeconds>${timeout}</TimeoutInSeconds>
          <TermNo>${String(posNumber).substr(0, 3)}</TermNo>
          <RequestId>${requestId}</RequestId>`
        if(additionalParams) {
          for(let key in additionalParams) {
            request.rawXml += `
            <${String(key)}>${String(additionalParams[key])}</${String(key)}>`;
          }
        }
        request.rawXml +=`
        </Request>`;
        if(this.configurations.isAndroid && androidIntentTimeout){
          request.androidIntentTimeout = androidIntentTimeout
        }
        return request;
      }

    async sendRequestToPinpad(request, RequestType, splashMsg?) {
      try {
        if(this.pinpadTransactionRunning) {
          await this.waitForPreviousTran();
        }

        this.pinpadTransactionRunning = true;

        this.logger.info(request.rawXml);

        if(this.configurations.isNewVersionAddonFiles && !this.configurations.isAndroid) {
          request = { xml: request.rawXml };
          RequestType = "pinpad_addon"
        }

        if (splashMsg) {
          this.loadingMessageAndDialogManager.showLoadingMessage(splashMsg);
        }
        
        let result = await this.addon.sendDataToPinpad(request, RequestType);

        return result;
        
      } catch(err) {
        throw err;
      } finally {
        if (splashMsg) {
          this.loadingMessageAndDialogManager.hideLoadingMessage();
        }

        this.pinpadTransactionRunning = false;
      }
    }

    waitForPreviousTran() {
      let timesTried = 0;
      return new Promise<void>((resolve, reject) => {
          let waitForTran = () => {
              if (!this.pinpadTransactionRunning || timesTried > 120) {
                return resolve();
              }
              timesTried++;
              setTimeout(waitForTran, 1000);
          }
          waitForTran();
      });
    }

    parseResult(emvOutputFromAddon) {
        let result:any;
        if (this.configurations.isHttpConnection) {
          result = this.parseResultFromHttp(emvOutputFromAddon.body);
        }
        else if (this.configurations.isAndroid) {
          result = this.parseResultFromAndroid(emvOutputFromAddon);
        } else {
          let isAtmRequest = false;
          if(Number(emvOutputFromAddon.request.body.Command) > 99 && Number(emvOutputFromAddon.request.body.Command)) {
            isAtmRequest = true
          }
          result = this.parseResultFromWindows(emvOutputFromAddon.request.result, isAtmRequest);
        }
        if(Number(result.Command) < 100) {
            return Object.assign((new EmvOutput()), result);
        }
        return result;
    }

    parseResultFromHttp(result) {
      result = result.replace(/Session-Number/g,"SessionNumber");
      this.logger.info(result);
      let parsedOutput = xml2json(result);

      let key = Object.keys(parsedOutput)[0];
      parsedOutput = parsedOutput[key];


      if(parsedOutput.DepositReport){
        parsedOutput.DepositReport = parsedOutput.DepositReport["Line"];
        for(let i=0;i<parsedOutput.DepositReport.length; i++){
          if(parsedOutput.DepositReport[i] == null){
            parsedOutput.DepositReport[i] = "";
          }
        }
      }

      if (!this.configurations.keepReceipts) {
        parsedOutput.ReceiptMerchant = "";
        parsedOutput.ReceiptCustomer = "";  
      }
      

      return parsedOutput;
    }


      parseResultFromAndroid(emvOutputFromAddon) {
        emvOutputFromAddon = emvOutputFromAddon.replace(/Session-Number/g,"SessionNumber");
        this.logger.info(emvOutputFromAddon);
        let parsedOutput = xml2json(emvOutputFromAddon);
        //Avoid root element
        var key = Object.keys(parsedOutput)[0];
        parsedOutput = parsedOutput[key];

        if(parsedOutput.DepositReport){
            parsedOutput.DepositReport = parsedOutput.DepositReport["Line"];
            for(let i=0;i<parsedOutput.DepositReport.length; i++){
              if(parsedOutput.DepositReport[i] == null){
                parsedOutput.DepositReport[i] = "";
              }
          }
        }

        if (!this.configurations.keepReceipts) {
          parsedOutput.ReceiptMerchant = "";
          parsedOutput.ReceiptCustomer = "";  
        }
        
        return parsedOutput;
      }

      parseResultFromWindows(emvOutputFromAddon, isAtmRequest) {
        let parsedOutput ;
        emvOutputFromAddon = emvOutputFromAddon.replace(/Session-Number/g,"SessionNumber");
        this.logger.info(emvOutputFromAddon);
        if(!this.configurations.isNewVersionAddonFiles) {
            emvOutputFromAddon = JSON.parse(emvOutputFromAddon);
            if(!isAtmRequest) {
              emvOutputFromAddon = emvOutputFromAddon.rawXmlResponse;
              parsedOutput = xml2json(emvOutputFromAddon);
            } else {
              parsedOutput = emvOutputFromAddon;
            }
        } else {
          parsedOutput = xml2json(emvOutputFromAddon);
        }
        if(!isNullOrUndefined(parsedOutput.Response)) {
          parsedOutput = parsedOutput.Response
        }
        else if(!isNullOrUndefined(parsedOutput.EMV_Output)) {
          parsedOutput = parsedOutput.EMV_Output
        }

        for (let prop in parsedOutput) {
          if (parsedOutput[prop] === "") { //remove empty properties so we don't have to serialize all this junk....
            delete parsedOutput[prop]
          }
      }

        if(parsedOutput.DepositReport){ //fixing caspit reponse to match the expected response
            parsedOutput.DepositReport = parsedOutput.DepositReport["Line"];
            parsedOutput.DepositReport = parsedOutput.DepositReport.map((line) => line || "");
        }
        if(parsedOutput.StaticObj) { //fixing StaticObj in response to have both property names with "-" separating each word and not having "-"
          let staticObj = _.clone(parsedOutput.StaticObj);
          for(let key in parsedOutput.StaticObj) {
            if(key.indexOf("-") >= 0) {
              staticObj[key.split("-").join("")] = parsedOutput.StaticObj[key];
            }
          }
          parsedOutput.StaticObj = staticObj;
        }

        if (parsedOutput.ReceiptMerchant) {
          parsedOutput.ReceiptMerchant = "";
        }

        if (parsedOutput.ReceiptCustomer) {
          parsedOutput.ReceiptCustomer = "";
        }

        return parsedOutput;
      }

      setKeepAlive(keepAlive: KeepAlive) {
        this.keepAlive = keepAlive;
      }

      getPinpadTransactionRunning() {
        return this.pinpadTransactionRunning;
      }
      
      isCommunicationError(errorCode: number) {
        let errorCodes = this.configurations.pinpadCommunicationErrors.split(",")
        return errorCodes.includes(`${errorCode}`);
      }
}