<script setup lang="ts">
import { ref, onMounted, computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n'
import { useOneClick } from '@m/composables/oneClickComposable';
import { useKeyPad } from '@m/composables/keypadComposable';
const { oneTimeRun } = useOneClick();
const { keyPadHandler } = useKeyPad();
const { t } = useI18n();

const dialogSelector = "price-dialog"

const initialized = ref(false);
const searchInput = ref('');
const loading = ref(false);
const rejectFunc = ref(null);
const resolveFunc = ref(null);
const price = ref(0);
const priceRef = ref(null);
const originalPrice = ref(0);
const saleItem = ref(null);
const allowZero = ref(false);
const allowNeg = ref(false);
const hakafaTaxMode = ref(false);
const arrowImg = ref(`${(<any>window).images_path}/pos/arrow.png`);
const dialogElem = ref(null);
const fields = reactive(initFields());
const dialogTitle = ref('priceDialog.desc');
const valdationCallback = ref(null);
const showCancel = ref(true);

const selfService = computed(() => {
    return Pinia.globalStore.simpleSelfService
})

function initFields() {
    return {
        'price': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'price',
            hasDecimal: false,
        }
    };
}
const cleanData = () => {
    // Initialize the values here
    initialized.value = false;
    searchInput.value = "";
    loading.value = false;
    rejectFunc.value = null;
    resolveFunc.value = null;
    price.value = 0;
    originalPrice.value = 0;
    saleItem.value = null;
    allowZero.value = false;
    allowNeg.value = false;
    hakafaTaxMode.value = false;
    arrowImg.value = `${(<any>window).images_path}/pos/arrow.png`;
    dialogElem.value = document.getElementById("price-dialog");
    fields['price'] = initFields()['price'];
    dialogTitle.value = 'priceDialog.desc';
    valdationCallback.value = null;
    showCancel.value = true;
}

const close = () => {
    if (initialized) {
        if (dialogElem == null) {
            dialogElem.value = document.getElementById(dialogSelector)
        }
        dialogElem.value.close();
    }
    cleanData()
    $(document).unbind('keypress');
    $(document).keypress(posVC.onKeyPress);
}

const open = (originalPriceParam, saleItemParam = null, allowZeroParam = false,
    dialogTitleParam = "priceDialog.desc", validationFunction = null, showCancelParam = true) => {
    return new Promise((resolve, reject) => {
        initialized.value = true;
        allowZero.value = allowZeroParam;
        originalPrice.value = originalPriceParam;
        saleItem.value = saleItemParam;
        showCancel.value = showCancelParam;

        let hakafaSpecialItem = PositiveTS.Service.Hakafa.HakafaSpecialItemValidation.getHakafaSpecialItem(posVC.Specialitems);
        if (saleItem.value && hakafaSpecialItem && hakafaSpecialItem.code === saleItem.value.itemCode
            && jsonConfig.getVal(jsonConfig.KEYS.forceInvoiceClosingOnHakafa)) {
            let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
            if (!svcCustomer.getCurrentSelectedCustomer().is_tamash_customer) {
                hakafaTaxMode.value = true;
            }
        }

        dialogTitle.value = dialogTitleParam;
        valdationCallback.value = validationFunction;

        $(document).unbind('keypress');

        if (!dialogElem.value) {
            dialogElem.value = document.getElementById(dialogSelector);
        }
        dialogElem.value.showModal();
        resolveFunc.value = resolve;
        rejectFunc.value = reject;
    });
}

const showError = (errStr) => {
    app.showAlertDialog({
        header: i18next.t('error'),
        content: errStr,
        continueButtonText: i18next.t("ok"),
        hideCancelButton: true,
    },
        () => {
            if (priceRef.value) {
                priceRef.value.focus();
            }
        }, null)
}

const confirmAndClose = () => {
    let priceToSave = Number(price.value);

    if (!session.isNumber(price.value) || Number(price.value) < 0) {
        showError(i18next.t("pos.noPriceError"));
        return;
    }

    if (Number(price.value) === 0 && !allowZero.value) {
        showError(i18next.t("pos.priceZeroError"));
        return;
    }

    var hakafaValidation = PositiveTS.Service.Hakafa.HakafaSpecialItemValidation.isItemValid(saleItem.value, posVC.Specialitems, Number(price.value));
    if (!hakafaValidation.valid) {
        showError(i18next.t(`hakafa.${hakafaValidation.msg}`));
        return;
    }

    if (hakafaTaxMode.value) {
        let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
        let cust = svcCustomer.getCurrentSelectedCustomer();
        if ((cust.amount * -1) < priceToSave) {
            showError(i18next.t("hakafaInvoicesDialog.amountMoreThanHakafa"));
            return;
        }
    }
    if (valdationCallback.value !== null && !valdationCallback.value(priceToSave)) return;

    resolveFunc.value({ approved: true, price: priceToSave });
    close();
    return;
}

const cancelTapped = () => {
    resolveFunc.value({ approved: false, priceToSave: originalPrice.value })
}

function clearTapped(stayOpen?) {
    fields["price"].hasDecimal = false;
    price.value = 0;
    if (!stayOpen) {
        rejectFunc.value(app.userCancelledGlobalMessage);
        close();
    }
}

const onKeyTapped = (key) => {
    keyPadHandler(key, fields["price"], price, 3);
}

// Example of onMounted lifecycle hook
onMounted(() => {
    dialogElem.value = document.getElementById(dialogSelector);
});
defineExpose({
    open
});
</script>

<template>
    <dialog id="price-dialog" class="positive-dialog" :class="selfService ? 'self-service pop-in' : ''">
        <div class="dialog-container">
            <div class="title">{{ t(dialogTitle) }}</div>
            <div class="price-dialog-content content">
                <div class="form-row">
                    <div id="label">{{ t(dialogTitle) }}</div>
                    <input min="0" @keyup.enter.stop="oneTimeRun(confirmAndClose)" @keyup.esc.stop="cancelTapped"
                        data-nosoftkeyboard="true" v-model.trim="price" ref="priceRef" type="number" />
                </div>
                <keypad ref="keypad" :key-tapped="onKeyTapped" :dialog-layout="true" :allow-neg="false"
                    :show-clear-button="showCancel" :clear-tapped="clearTapped" :clear-text="t('cancel')"
                    :show-confirm-button="true" :confirm-tapped="confirmAndClose"></keypad>
            </div>
        </div>

    </dialog>
</template>

<style lang="scss">
#price-dialog{
    
    &.self-service {
        min-height: 15vh;
        font-size: 1em;

        td:not(.keypad-clear-button):not(.red-key):not(.green-bg) {
            background: var(--primary-default);
        }

        .dialog-container {
            padding: 10px;

            td:not(.green-bg),
            .keypad-clear-button,
            .red-key,
            .diabled-key,
            .green-bg .positive-simple {
                color: white;
                border: 6px solid white !important;
                border-radius: 10px;
                font-family: 'Assistant';
                font-size: 1em;
                box-shadow: var(--button-shadow);
                line-height: 20px;
                text-align: center;
                cursor: pointer;
                text-decoration: none;
            }

            .green-bg {
                box-shadow: unset;
                background: unset;
            }
        }


        .title {
            display: none;
        }

        .self-service-btn {
            line-height: unset;
        }

        .content {
            display: flex;
            flex-direction: column;
        }

        [data-role="footer"] {
            margin-top: 5vh;
        }

        font-family: 'Assistant';
        font-size: 20px;
        border: solid 6px var(--primary-default);
        border-radius: 6px;
    }

    .price-dialog-content {
        display: flex;
        flex-direction: column;

        height: 100%;

        .ui-keypad td {
            height: 2em;
            width: 3em;
            font-size: var(--keypad-font-size) !important;
        }

        #label {
            font-size: 1.4em;
            margin-bottom: 0.5em;
        }

        .form-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 10px 6px 0;

            label {
                line-height: 34px;
                font-weight: bolder;
            }

        }

        input[type=number] {
            border: 1px solid #ccc;
            font-size: 20px;
            padding: 4px 7px;

            &:focus {
                outline-color: var(--primary-default);
            }

        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
</style>