module PositiveTS {
  export module Components {
    export module FlightsIsrairEnterFlightCode {

      export function getComponent() {
        return defineComponent({
          template: JST.flightsIsrairEnterFlightCode(),
          mixins: [Mixins.wizardItemMixin],
          methods: {
            enterFlightCode() {
              if (!this.isFormFilled) {
                return;
              }
              
              this.goToNextStep();
            },
          },
          computed: {
            isFormFilled() {
              return this.stepData.flightCode != null;
            }
          },
          mounted() {
            this.$refs.flightCode.focus();
          }
        })
      }
    }
  }
}
