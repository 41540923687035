module PositiveTS {
export module Service {
export module PluxeeService {

export module Soap {

  export const companiesLocalStorageKey = "companiesLocalStorageKey";

  export class CompanyItem{
    companyCode:string;
    companyName:string;
    constructor(companyCode:string, companyName:string){
      this.companyCode = companyCode;
      this.companyName = companyName;
    }
  }


    function getURL(){
      let url = "https://poslb.mysodexo.co.il/poswebservice/posws.asmx"

      //this is the old cibus url, as of 12/2019 it should not work at all
      //the new one requires TLS 1.2 support which only addon version 2.73 supports
      if(session.addonFilesVersion <= 2.72 && session.isAndroid == false){
        url = "https://pos.mysodexo.co.il/poswebservice/posws.asmx"
      }

      return url;
    }

    export async function addOrder(companyCode: string, companyCard: string, price: number) {
      if (!companyCode) {companyCode = "0";}
      try {
        let _data = {
          "body": _actionAddOrder(companyCode, companyCard, price), "type": "POST",
          "url": getURL(),
          "headers": _headersAddOrder()
        };
        let data = JSON.stringify(_data);
        PositiveTS.Service.Filelog.log("cibus", JSON.stringify({request: data}));
        const result = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "external_service"
        );
        PositiveTS.Service.Filelog.log("cibus", JSON.stringify({result: result.request.result}));
        return result.request.result;
      } catch (error) {
        PositiveTS.Service.Filelog.log("cibus", JSON.stringify({error: error}));
        throw error;
      }
    }

    export function cancelOrder(oderId: string) {
        var that = this;

        var _data = {
            "body": _actionCancelOrder(oderId), "type": "POST",
            "url": getURL(),
            "headers": _headersCancelOrder()
        };
        var data = JSON.stringify(_data)
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
        ).then((x) => {
            PositiveTS.Service.Filelog.log("cibus",x.request.result);
            return x.request.result;
        });
    }

    export function listCompanies():Promise<any> {

      if (!PositiveTS.Service.PluxeeService.Voucher.isAuthorizedCibus()){return Promise.resolve();}

      var that = this;

      var _data = {
          "body": _actionListCompanies(), "type": "POST",
          "url": getURL(),
          "headers": _headersListCompanies()
      };
      var data = JSON.stringify(_data)
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          { data: data },
          "external_service"
      ).then((result) => {
        Service.Filelog.log("cibus",result.request.result);
        _saveListOfCompaniesToLocalStorage(result);
      })
    }

    export async function getBudgetBalance(companyId: string | number, companyCard: string): Promise<string> {
      if (!PositiveTS.Service.PluxeeService.Voucher.isAuthorizedCibus()) {
        return "";
      }
      var _data = {
          "body": _actionGetBudgetBalance(companyId || '0', companyCard),
          "type": "POST", "url": getURL(),
          "headers": _headersGetBudgetBalance()
      };
      var data = JSON.stringify(_data)
      let res = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data },"external_service");
      PositiveTS.Service.Filelog.log("cibus", res.request.result);
      return res.request.result;
    }

    function _saveListOfCompaniesToLocalStorage(result:any){
      var companyCodes = $(result.request.result).find("companyCodeList").find("int");
      var companyNames = $(result.request.result).find("companyNameList").find("string");

      var _listOfCompanies:CompanyItem[]=[];
      _listOfCompanies.push(new CompanyItem("0", "Cibus"));
      for (var i=0;i<companyCodes.length;i++){
        if (parseInt($(companyCodes[i]).text())){
            _listOfCompanies.push(new CompanyItem($(companyCodes[i]).text(), $(companyNames [i]).text()));
        }
      }
      localStorage.setItem(companiesLocalStorageKey, JSON.stringify(_listOfCompanies));
    }

    function _actionListCompanies() {
        return `<?xml version="1.0" encoding="utf-8"?>
<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
  <soap:Body>
    <LoadCompanies1255 xmlns="www.cibusnet.co.il">
      <restaurantID>${jsonConfig.getVal(jsonConfig.KEYS.cibusRestaurantId)}</restaurantID>
      <posID>${jsonConfig.getVal(jsonConfig.KEYS.cibusPosId)}</posID>
      <Prefix>0</Prefix>
      <companyCodeList>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
        <int>0</int>
      </companyCodeList>
      <companyNameList>
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
        <string xsi:nil="true" />
      </companyNameList>
      <isCMBPrefix>false</isCMBPrefix>
      <listItemsCount>0</listItemsCount>
    </LoadCompanies1255>
  </soap:Body>
</soap:Envelope>`;
    }

    function _headersListCompanies() {
        return JSON.stringify({
            "Content-Type": "text/xml; charset=utf-8",
            "SOAPAction": "www.cibusnet.co.il/LoadCompanies1255"
        })
    }

    function _actionAddOrder(companyCode: string, companyCard: string, price: number) {
        return `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:cib="www.cibusnet.co.il">
 <soapenv:Header/>
 <soapenv:Body>
    <cib:AddOrder>
       <cib:restaurantID>${jsonConfig.getVal(jsonConfig.KEYS.cibusRestaurantId)}</cib:restaurantID>
       <cib:posID>${jsonConfig.getVal(jsonConfig.KEYS.cibusPosId)}</cib:posID>
       <cib:companyCode>${companyCode}</cib:companyCode>
       <cib:companyCard>${companyCard}</cib:companyCard>
       <cib:price>${price}</cib:price>
       <cib:budget>0</cib:budget>
       <cib:orderID>0</cib:orderID>
    </cib:AddOrder>
 </soapenv:Body>
</soapenv:Envelope>
`;
    }

    function _headersAddOrder() {
        return JSON.stringify({
            "Content-Type": "text/xml; charset=utf-8",
            "SOAPAction": "www.cibusnet.co.il/AddOrder"
        })
    }

    function _actionCancelOrder(oderId: string) {
        return `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:cib="www.cibusnet.co.il">
<soapenv:Header/>
<soapenv:Body>
  <cib:CancelOrder>
     <cib:restaurantID>${jsonConfig.getVal(jsonConfig.KEYS.cibusRestaurantId)}</cib:restaurantID>
     <cib:posID>${jsonConfig.getVal(jsonConfig.KEYS.cibusPosId)}</cib:posID>
     <cib:orderID>${oderId}</cib:orderID>
     <cib:price>0</cib:price>
     <cib:budget>0</cib:budget>
     <cib:outOrderID>0</cib:outOrderID>
  </cib:CancelOrder>
</soapenv:Body>
</soapenv:Envelope>
`;
    }

    function _headersCancelOrder() {
        return JSON.stringify({
            "Content-Type": "text/xml; charset=utf-8",
            "SOAPAction": "www.cibusnet.co.il/CancelOrder"
        })
    }

    function _headersGetBudgetBalance() {
      return JSON.stringify({
        "Content-Type": "text/xml; charset=utf-8",
        "SOAPAction": "www.cibusnet.co.il/GetBudgetBalance"
      });
    }

    function _actionGetBudgetBalance(companyId: string | number, companyCard: string) {
      return `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <GetBudgetBalance xmlns="www.cibusnet.co.il">
            <sCompanyID>${companyId}</sCompanyID>
            <restaurantID>${jsonConfig.getVal(jsonConfig.KEYS.cibusRestaurantId)}</restaurantID>
            <posID>${jsonConfig.getVal(jsonConfig.KEYS.cibusPosId)}</posID>
            <companyCard>${companyCard}</companyCard>
            <budgetBalance>${''}</budgetBalance>
            <errorMessage>${''}</errorMessage>
          </GetBudgetBalance>
        </soap:Body>
      </soap:Envelope>`;
    }

}

}

}
}
