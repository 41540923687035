module PositiveTS {
  export module Service {
    export module ReportEmployeeTime {

      const url = '/EmployeeTimeTracks/report_by_date_range?';

      export interface entryRowObject {
        fromDateHour: string,
        toDateHour: string,
        formatedBreakTime: string,
        totalHoursWithBreakTime: string,
        date: string,
        entryDay: string
      }

      export function getReport(fromDate: Date, toDate: Date) {
        return FetchReq.jsonReq(url + jQuery.param({ fromDate: fromDate, toDate: toDate }), 'get')
          .then((response) => {
            if (!response.response.ok) { throw new Error("SERVER_INTERNAL_ERROR"); }
            return response.result;
          });
      }

      export function createEmployeeReport(reports): entryRowObject[] {
        let _ret = [];
        let startDt = "";
        let endDt = "";
        let breakTime = 0

        for (let i = 0; i < reports.length; i++) {

          let row = reports[i];
          if (row.mode === 1) {

            if (startDt) {
              _ret.push(Service.ReportEmployeeTime.createEntryRow(startDt, endDt, breakTime))
            }
            startDt = row.time_track_at;
            endDt = "";
            breakTime = row.break_time ? row.break_time : 0
          } else {
            endDt = row.time_track_at;
            //if hours diff more then 24 it is two sepperated entries
            if (moment(endDt).diff(moment(startDt), "hour") > 24) {
              _ret.push(Service.ReportEmployeeTime.createEntryRow("", endDt, breakTime))
              _ret.push(Service.ReportEmployeeTime.createEntryRow(startDt, "", breakTime))
            } else {
              _ret.push(Service.ReportEmployeeTime.createEntryRow(startDt, endDt, breakTime))
            }

            startDt = "";
            endDt = "";
            breakTime = 0
          }

        }
        return _ret;
      }


      export function createEntryRow(fromDate: string, toDate: string, breakTime: number): entryRowObject {
        let row: entryRowObject = {
          fromDateHour: fromDate ? moment(fromDate).format("HH:mm") : i18next.t('reportEmployeeTime.noEntry'),
          toDateHour: toDate ? moment(toDate).format("HH:mm") : i18next.t('reportEmployeeTime.noEntry'),
          formatedBreakTime: moment.utc(new Date(0)).add(breakTime, 'minutes').format("H:mm"),
          totalHoursWithBreakTime: fromDate && toDate ? session.fixedNumber((Math.abs(moment(fromDate).diff(toDate, "minute")) - breakTime) / 60) : i18next.t('reportEmployeeTime.noEntry'),
          date: fromDate ? moment(fromDate).format("DD/MM/YY") : moment(toDate).format("DD/MM/YY"),
          entryDay: fromDate ? moment(fromDate).format("dd") : moment(toDate).format("dd")
        }

        return row
      }

      export function getTotalEmployeeHours(employeeEntries: entryRowObject[]): number {
        let totalHours = 0

        for (const row of employeeEntries) {
          if (!isNaN(Number(row.totalHoursWithBreakTime)))
            totalHours += Number(row.totalHoursWithBreakTime)
        }

        return totalHours
      }

    }
  }
}
