module PositiveTS {
    export module Service {
        export module PluxeeService {

            export const applicationID = "F5E25DBA-CD17-4BDA-8EEB-B0BA6F2CAB5E";
            export const customer_number = "לקוח פלאקסי"
            export let errors_types;
            const url = "https://restapi.mysodexo.co.il/api/main.py"


            function pluxeeDeliveryServiceHeader() {
                return JSON.stringify({
                    "Application-Id": applicationID,
                    "Content-Type": "application/json"
                }
                );
            }

            async function getToken(forceRequest?) {
                if (forceRequest || !localStorage.getItem("cibusDeliveriesToken")) {
                    await login();
                }

                return localStorage.getItem("cibusDeliveriesToken")
            }

            export async function login() {
                let user = jsonConfig.getVal(jsonConfig.KEYS.cibusDeliveriesAPIUser)
                let password = jsonConfig.getVal(jsonConfig.KEYS.cibusDeliveriesAPIPassword)

                let _data = {
                    "body": JSON.stringify({ "type": "rest_contact_login", "user_login_pswd": password, "user_login_name": user }),
                    "url": url,
                    "type": "POST",
                    "headers": pluxeeDeliveryServiceHeader()
                };
                let data = JSON.stringify(_data)

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("cibusorders",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                );

                let result = JSON.parse(response.request.result)
                if (result.code == 0) {
                    localStorage.setItem("cibusDeliveriesToken", result.token)
                }
                else {
                    console.error("cibus deliveries service - login error")
                    console.error("response was: " + response)
                }

            }

            export function getErrorMsgAndTypeByCode(code) {
                for (let error of errors_types) {
                    if (error.code_list.includes(code)) {
                        return {
                            msg: error.msg,
                            type: error.type
                        }
                    }
                }
            }

            export async function init() {
                let _data = {
                    "body": JSON.stringify({ "type": "rp_init", "token": await getToken(true) }),
                    "url": url,
                    "type": "POST",
                    "headers": pluxeeDeliveryServiceHeader()
                };

                let data = JSON.stringify(_data)

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("cibusorders",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                );

                let result = JSON.parse(response.request.result);
                if (result.code == 0) {
                    errors_types = result.init_cfg_dict.error_types
                }
                else {
                    console.error("cibus deliveries service - error init service")
                    console.error("response was: " + response)
                }
            }

            async function set_packages(ids){
                let _data = {
                    "body": JSON.stringify({ "type": "set_packages", "package_ids": ids, "status": 2, "token": await getToken() }),
                    "url": url,
                    "type": "POST",
                    "headers": pluxeeDeliveryServiceHeader()
                };

                let data = JSON.stringify(_data)

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("cibusorders",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                );

                let result = JSON.parse(response.request.result);
                if (result.code == 0) {
                    return result
                }
                else if (getErrorMsgAndTypeByCode(result.code).type == "auth_reject") {
                    getToken(true)
                }
                else {
                    console.error("cibus deliveries service - error in get_packages")
                    console.error("response was: " + response)
                    console.error(result.code + ":" + getErrorMsgAndTypeByCode(result.code).msg)
                }
            }

            export async function get_packages() {
                let _data = {
                    "body": JSON.stringify({ "type": "get_packages", "expand": "orders", "status": 1, "token": await getToken() }),
                    "url": url,
                    "type": "POST",
                    "headers": pluxeeDeliveryServiceHeader()
                };

                let data = JSON.stringify(_data)

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                Filelog.log("cibusorders",
                    JSON.stringify({ request: response.request.body.data, result: response.request.result })
                );

                let result = JSON.parse(response.request.result);
                if (result.code == 0) {
                    return result.packages
                }
                else if (getErrorMsgAndTypeByCode(result.code).type == "auth_reject") {
                    getToken(true)
                }
                else {
                    console.error("cibus deliveries service - error in get_packages")
                    console.error("response was: " + response)
                    console.error(result.code + ":" + getErrorMsgAndTypeByCode(result.code).msg)
                }
            }

            export function removeOrderFromLocalOrders(orderId){
                let currentLocalOrders = localStorage.getItem("cibusOrders") || JSON.stringify([])
                let currentLocalOrdersArr = JSON.parse(currentLocalOrders)
                currentLocalOrdersArr = currentLocalOrdersArr.filter(function( obj ) {
                    return obj.ID !== orderId;
                  });

                  localStorage.setItem("cibusOrders",JSON.stringify(currentLocalOrdersArr))
            }

            export async function fetchCibusDeliveryOrders() {
                try {
                    let packages = await get_packages();

                    // Cibus dosn't return packages if there is no packages
                    if (posUtils.isBlank(packages)) {
                        packages = [];
                    }

                    let orders = []
                    let package_ids = []
                    if (!jsonConfig.getVal(jsonConfig.KEYS.allowBelowMinimunOrders)) {
                        packages = packages.filter(package => package.overTheMin == 1)
                    }
                    for (let package of packages) {
                        for (let order of package.orders) {
                            let orderWithPackageInfo = { ...order, ...package, orders: null, ID: order.ID, packageID: package.ID, price: order.price }
                            orders = orders.concat(orderWithPackageInfo)
                        }
                        package_ids.push(package.ID)
                    }
                    if (packages.length > 0) {
                        await set_packages(package_ids)
                    }

                    let currentLocalOrdersArr = [];
                    let currentLocalOrders = localStorage.getItem("cibusOrders") || JSON.stringify([])
                    currentLocalOrdersArr = JSON.parse(currentLocalOrders)

                    for (let order of orders) {
                        if (currentLocalOrdersArr.filter(o => o.ID == order.ID).length == 0) {
                            currentLocalOrdersArr.push(order)
                        }
                    }

                    localStorage.setItem("cibusOrders", JSON.stringify(currentLocalOrdersArr))
                    return currentLocalOrdersArr;
                } catch (error) {
                    Logger.error(error);
                }
            }
        }
    }
}
