module PositiveTS {
  export module Components {
    export module TableFilterDialog {

      export const route = "/dalpak-filter";

      export function getComponent() {
        return {
          template: JST.tableFilterDialog(),
          methods: {
            finish(newFilters) {
              let isNewFiltersSelected = newFilters && Object.values(newFilters).filter(val => val != null).length > 0;

              this.setFilters(isNewFiltersSelected ? newFilters : null);
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {filters} = Pinia.storeToRefs(dalpaksStore);

            const {setFilters} = dalpaksStore

            return {setFilters,filters}
        },
          computed: {
            statusOptions() {
              let statuses = [
                {label: this.$t('all'), value: null},
              ]

              for (let dalpakStatus of Object.values(Service.Dalpak.DALPAK_STATUSES)) {
                statuses.push({label: this.$t('dalpaks.statuses.' + String(dalpakStatus)), value: dalpakStatus});
              }

              return statuses;
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },
          data() {
            return {
              employeesToShow: [],
              currentFilters: {
                waiter: null,
                status: null,
              },
            }
          },
          async created() {
            if (this.filters) {
              this.currentFilters = {...this.filters};
            }

            this.employeesToShow = await Storage.Entity.Employee.fetchByStoreOrByShift(session.pos.storeID);
          },
          beforeRouteEnter: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(i18next.t('filterDalpakDialog.title'))
            Pinia.dalpaksStore.setHideHamburgerMenu(true)
            Pinia.dalpaksStore.setGoBackAction(() => VueServices.Router.goto(SelectDalpakDialog.route))
            next();
          },
          beforeRouteLeave: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(null)
            Pinia.dalpaksStore.setHideHamburgerMenu(false)
            Pinia.dalpaksStore.setGoBackAction(null)
            next();
          }
        }
      }
    }
  }
}
