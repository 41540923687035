// jquery plugin to auto select input fields on click
// use: $("input field selector").autoselect();
(function( $ ){

  let stateSelector = `.boolean-state-changer`;


  var methods = {
    init : function( options ) {

      let defaultOptions = {
        defaultValue: false,
        trueText: 'אישור',
        falseText: 'ביטול',
        trueClass: 'boolean-state-changer-true',
        falseClass: 'boolean-state-changer-false',
        changed: function (newVal) { },
      };
      
      return this.each(function(){
        this.settings = $.extend(defaultOptions, options);

        let aThis = $(this);
        let stateTemplate = `
        <div class='boolean-state-changer'>
          <div class="state-changer-false selected ${this.settings.falseClass}">${this.settings.falseText}</div>
          <div class="state-changer-true ${this.settings.trueClass}">${this.settings.trueText}</div>
        </div>`;
        let state = $(stateTemplate);

        aThis.append(state);

        $('.state-changer-false',aThis).click(() => { methods.toggle(false,this) });
        $('.state-changer-true',aThis).click(() => { methods.toggle(true,this)} );

        if (aThis.data('value') === undefined) {
          aThis.data('value', !this.settings.defaultValue);
        }

        _toggle.apply(this,[this.settings.defaultValue]);
      });
    },
    toggle: function (value:boolean,elem) {
      return $(this).each(function() {
        var $this = $(this);

        let cssClass = value ? elem.settings.trueClass : elem.settings.falseClass;
        let didChange = !($(elem).find(`${stateSelector} > .${cssClass}`).hasClass('selected'))

        if (didChange) {
          let ableToChange = elem.settings.changed.apply(elem, [value]);
          if (ableToChange) {
            _toggle.apply(elem,[value]);
          }
        }
      });
    }
  };

  function _toggle (value) {
    var $this = $(this);

    // $this.data('value', !$this.data('value'));

    if (value) {
      $this.find(`${stateSelector} > .${this.settings.falseClass}`).removeClass('selected');
      $this.find(`${stateSelector} > .${this.settings.trueClass}`).addClass('selected');
    } else {
      $this.find(`${stateSelector} > .${this.settings.trueClass}`).removeClass('selected');
      $this.find(`${stateSelector} > .${this.settings.falseClass}`).addClass('selected');
    }
  };

  $.fn.booleanStateChanger = function( method ) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on rubysoft.booleanStateChanger' );
    }    
  };

})( jQuery );