module PositiveTS {
    export module Components {
    export module PositiveDeliveryCustomerCompensation {
      const _dialogSelector = "#positive-delivery-customer-compensation-dialog"
      const _vueComponentName = 'positive-delivery-customer-compensation';

    
      export function create() {
        let vueObj = {
            template: JST.positiveDeliveryCustomerCompensation(),
            methods: {
              open: _open,
              close: _close,
              doConfirm: _doConfirm,
              clearInputs: function (){
                this.compensation = "";
                this.reason = "";
              },
              removeCompensation: removeCompensation
            },
            data: _initData,
    
    
        }
    
        VueApp.component(_vueComponentName,vueObj)
    
      }

      function _open(customer_id:number){    
        $(document).unbind('keypress');
        $(function() {

          let mobileLayout = Pinia.globalStore.mobileLayout;

          $(_dialogSelector).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: 'positive-dialog',
            width: mobileLayout ? '100%' : '80%',
            resizable: false,
            closeOnEscape: false,
            draggable: false,
          });
          $(_dialogSelector).dialog('open');
        });

        app.showLoadingMessage(i18next.t("delivery.lookingForCompensation"));

    
        return new Promise((resolve,reject) => {
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.customerId = customer_id;
          
          return PositiveTS.Service.Delivery.getDeliveryCustomerCompensation(this.customerId)
          .then( result=>{
            this.compensation = result.compensation;
            this.reason = result.reason;
            app.hideLoadingMessage();
          })
          .catch(e=>{
            this.rejectFunc(e);
          })
        })
    
      }
    
    
      function _initData(){
        return {
          _dialogSelector: _dialogSelector,
          rejectFunc: null,
          resolveFunc: null,
          customerId:null,
          reason: "",
          compensation: ""

    
        };
      }

      async function removeCompensation(){
        this.compensation = "";
        this.reason = "";

        let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
        let customer = svcCustomer.getCurrentSelectedCustomer();



        app.showLoadingMessage(i18next.t("delivery.updatingCompensation"));


        return PositiveTS.Service.Delivery.setDeliveryCustomerCompensation(this.customerId,this.reason,this.compensation)
        .then( result=>{
          customer.compensation = "";
          svcCustomer.setCurrentSelectedCustomer(customer);
          
          Pinia.globalStore.setCustomerCompensation(false)
          app.hideLoadingMessage();
          this.close();
        })
        .catch(e=>{
          this.rejectFunc(e);
          this.close();

        })
      }

      function _doConfirm(){

        app.showLoadingMessage(i18next.t("delivery.updatingCompensation"));


        return PositiveTS.Service.Delivery.setDeliveryCustomerCompensation(this.customerId,this.reason,this.compensation)
        .then( result=>{
          app.hideLoadingMessage();
          this.clearInputs();
          this.close();
        })
        .catch(e=>{
          this.rejectFunc(e);
          this.close();

        })
          
      }
    
      function _doReject(){
        this.close();
        this.rejectFunc(new Error(app.userCancelledGlobalMessage));
      }
    
      function _close() {
        $(_dialogSelector).dialog('close');
      }
    
    
    }}}
