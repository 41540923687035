module PositiveTS {
  export module Service {
    export module PriceList {

      let _cachedEffectivePriceLists: any = undefined;
      let _cachedEffectivePriceListsByCode: any = undefined;

      export async function setPricelistHakafaCustomerInSale(customer) {
        let customerClubService = new PositiveTS.Service.CustomerClub(
          posVC.sale,
          posVC.salePayments,
          posVC.saleItems
        );
        const posCustomer = await Service.Hakafa.convertToPosStructure.call(this, customer);
        await customerClubService.setCurrentSelectedCustomer(posCustomer);
        await posVC.saleUpdated();
      }

      export async function setOrOpenPricelistHakafaCustomers() {
        if (!Pinia.globalStore.isOnline) {
          app.showAlertDialog({
            header: i18next.t("error"),
            content: i18next.t("pricelist.POSIsOffline"),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          });
          return;
        }

        app.showLoadingMessage(i18next.t("pricelist.searchingForCustomers"));

        let req = await Service.FetchReq.jsonReq("/hakafa_customers/get_pricelist_customer", "get");
        let customers = req.result;
        if (customers.length == 1) {
          setPricelistHakafaCustomerInSale(customers[0]);
        } else if (customers.length > 1) {
          Pinia.componentsStore.openComponent({
            componentName: "pricelistCustomerPicker",
            args: [customers],
          });
        } else {
          app.showAlertDialog({
            header: i18next.t("error"),
            content: i18next.t("pricelist.noPricelistCustomersFound"),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          });
        }
        app.hideLoadingMessage();
      }

      async function _cacheEffectivePriceListsIfRequired() {
        if (_cachedEffectivePriceLists) {
          return;
        }

        const p = new PositiveTS.Storage.Entity.PriceList();
        const result: PositiveTS.Entity.PriceList[] = await p.all();
        _cachedEffectivePriceLists = result.filter(
          (val: PositiveTS.Entity.PriceList) => {
            return (val.companyId = session.pos.companyID);
          }
        );
        _cachedEffectivePriceListsByCode = _.keyBy(_cachedEffectivePriceLists, "code");
      }

      export async function getEffectivePriceForCurrentSale(
        sale,
        salePayments,
        saleItems,
        saleItem: Storage.Entity.SaleItem
      ): Promise<_PriceListCalculator> {
        let price = new _PriceListCalculator(sale, salePayments, saleItems, saleItem.item);
        await price.getEffectivePrice();
        return price;
      }

      export interface PriceAndListCode {
        price: any;
        priceListCode: any;
      }
      class _PriceListCalculator {
        _saleObj: any;
        _salePaymentsObj: any;
        _saleItemsObj: any;
        _newItm: Storage.Entity.Item;
        minimumPrice = 0;
        minimumPriceListCode = null;
        minimumPriceListNoDiscount = false;

        constructor(saleObj, salePaymentsObj, saleItemsObj, newItm: any) {
          this._saleObj = saleObj;
          this._salePaymentsObj = salePaymentsObj;
          this._saleItemsObj = saleItemsObj;
          this._newItm = newItm;
        }

        _getPriceZarhanOrAlut(): number {
          if (PositiveTS.Service.Hakafa.hasCustomer() && JSON.parse(posVC.sale.jsondata).customer.is_price_alut) {
            return this._newItm.priceAlut;
          }
          if (session.store.containVat == true && session.store.tenantID == "9") {
            return this._newItm.priceEilat;
          } else {
            return this._newItm.priceZarhan;
          }
        }

        async getEffectivePrice(): Promise<void> {
          let priceListCodes: string[] = await this._getEffectivePricelistCodes();

          if (priceListCodes.length === 0 || this._newItm.hasGroups) {
            this.minimumPrice = this._getPriceZarhanOrAlut();
            return;
          }

          let ipl = new PositiveTS.Storage.Entity.ItemPriceList();
          let result: PriceAndListCode[] = await ipl.getPricesByItemCodeAndPriceListCodes(this._newItm.code, priceListCodes);
          let priceAry = result.map((i) => {
            return i.price;
          });
          this.minimumPrice = Math.min(...priceAry);
          this.minimumPriceListCode = result.filter((k) => {
            return k.price == this.minimumPrice;
          })[0].priceListCode;

          await _cacheEffectivePriceListsIfRequired();
          this.minimumPriceListNoDiscount = _cachedEffectivePriceListsByCode[this.minimumPriceListCode].noDiscount;
        }

        private async _getEffectivePricelistCodes(): Promise<string[]> {
          const saleObj = this._saleObj;
          const salePaymentsObj = this._salePaymentsObj;
          const saleItemsObj = this._saleItemsObj;
          const newItm = this._newItm;

          if (!newItm.priceLists || newItm.priceLists.trim() === "") {
            return [];
          }

          const newAryItmPriceLists = newItm.priceLists.trim().replace(/ /g, "").split("&");

          const svcCustomerClub = new PositiveTS.Service.CustomerClub(saleObj, salePaymentsObj, posVC.saleItems);
          const currentSelectedCustomer = svcCustomerClub.getCurrentSelectedCustomer();
          let clubname, customerGroupId, dbId;
          if (!svcCustomerClub.isCurrentCustomerExists() || !currentSelectedCustomer.clubName) {
            clubname = null
            customerGroupId = 0;
            dbId = 0;
          }else{
            clubname = currentSelectedCustomer.clubName;
            customerGroupId = currentSelectedCustomer.customer_group_id;
            dbId = currentSelectedCustomer.db_id;
          }


          const result: PositiveTS.Entity.PriceList[] = await this._getEffectivePriceLists(clubname, customerGroupId, dbId);

          if (result.length === 0) {
            return [];
          }
          const codesAry = result.map((el) => el.code.toString());
          return _.intersection(codesAry, newAryItmPriceLists);
        }


        private async _getEffectivePriceLists(clubname: string, customerGroupId: number, dbId: number): Promise<PositiveTS.Entity.PriceList[]> {
          await _cacheEffectivePriceListsIfRequired();

          return _cachedEffectivePriceLists.filter((val: PositiveTS.Entity.PriceList) => {

            // Init the condition checks
            const isHakafa = val.isHakafa && clubname == PositiveTS.Service.Hakafa.CLUB_IDENTIFIER;
            const isCustomerClub = val.isCustomerClub && clubname === "positive";
            const isCustomerGroupId = val.customerGroupId && val.customerGroupId == customerGroupId;
            const isCustomerId = val.customerId && dbId && clubname === "positive" && val.customerId == dbId;
            const isHakafaCustomerId = val.hakafaCustomerId && dbId && clubname == PositiveTS.Service.Hakafa.CLUB_IDENTIFIER && val.hakafaCustomerId == dbId;

            // Do we have any conditions to check?
            const isCheckingConditions = val.isHakafa || val.isCustomerClub || val.customerGroupId || val.customerId || val.hakafaCustomerId;

            // Actual condition check
            const conditionCheck = (isHakafa || isCustomerClub || isCustomerGroupId || isCustomerId || isHakafaCustomerId);

            // We have a few conditions to check
            // 1. Is the price list a hakafa price list
            // 2. Is the price list a customer club price list
            // 3. Does the price list requires a specific customer group
            // 4. Does the price list requires a specific store

            // Store is checked after all other conditions beacuse you can select a price list only for a store, or a price list for a condition and a store
            const priceListStores = JSON.parse(val.stores) || [];
            // If the price list is not for a specific store, we can return the condition check
            if (priceListStores.length === 0) {
              return conditionCheck;
            } else {
              // If the price list is for a specific store, we need to check if the current store is in the price list stores, and if so, return the condition check
              // There are 2 conditions to check, if we need to check other contitions we check them with the store, if not, we just check the store
              const isStore = priceListStores.includes(parseInt(session.pos.storeID));
              return isCheckingConditions ? (conditionCheck && isStore ) : isStore;
            }
          });
        }

      }


    }
  }
}
