module PositiveTS {
  export module Components {
    export module FlightsNumberOfPassengers {

      export function getComponent() {
        return {
          template: JST.flightsNumberOfPassengers(),
          mixins: [Mixins.wizardItemMixin],
          methods: {
            selectLeg() {
              if (!this.isFormFilled) {
                return;
              }
              
              this.goToNextStep();
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight,nextUnopenedLeg} = Pinia.storeToRefs(flightsStore);
            return {currentFlight,nextUnopenedLeg}
          },
          computed: {
            isFormFilled() {
              return this.stepData.numberOfPassengers != null;
            }
          },
          mounted() {
            this.$refs.numberOfPassengers.focus();
          }
        }
      }
    }
  }
}