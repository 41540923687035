module PositiveTS {
	export module UI {
		export function KeyPad(thisElement, options) {
			// Read options from element
			var elementOptions:any = {};
			if (thisElement.attr('data-insert-into-input-with-id') != undefined) {
				elementOptions.insertIntoInputWithID = thisElement.attr('data-insert-into-input-with-id');
			}
			if (thisElement.attr('data-with-special-keys') != undefined) {
				elementOptions.withSpecialKeys = (thisElement.attr('data-with-special-keys') === 'true');
			}
			if (thisElement.attr('data-min') != undefined) {
				elementOptions.min = thisElement.attr('data-min');
			}
			if (thisElement.attr('data-max') != undefined) {
				elementOptions.max = thisElement.attr('data-max');
			}
			if (thisElement.attr('data-step') != undefined) {
				elementOptions.step = thisElement.attr('data-step');
			}
			var opts = $.extend(elementOptions, options);
		
			// Default options...
			var defaults = {
				insertIntoInputWithID: null,
				withSpecialKeys: true,
				min: 0,
				max: 'inf',
				format: {format:"###0.##", locale:"il"},
				isPercentage: false,
				dateFormat:'39/19/9999',
				useFormat:true,
				textSelected:false,
				isDateField:false,
			};
			var opts = $.extend(defaults, opts);
		
		
			var id = 'ui-keypad-' + Math.floor(Math.random()*1000);
			this.id = id;
		
			thisElement.append('<div id="' + id + '-container" class="ui-keypad-container"></div>');
			var container = $(thisElement).find(' > #' + id + '-container');
		
			container.append('<table id="' + id + '" class="ui-keypad"><tbody></tbody></table>');
			var keypad = $(container).find(' > #' + id + ' > tbody');
		
			var numberOfColumns = 5;
			var columnWidth = (100-2)/4 + '%';
			if (!opts.withSpecialKeys) {
				numberOfColumns = 3;
				columnWidth = 100/3 + '%';
			}
		
			var row = $('<tr></tr>');
			for (var i = 0; i < numberOfColumns; i++) {
				var width = columnWidth;
				if (i == 3) {
					width = '2%';
				}
		
				var cell = $('<th></th>');
				cell.css({width: width, height: '0px'});
				row.append(cell);
			}
			keypad.prepend(row);
		
		
			var rtlVal = function(rtlVar,ltrVal) {
				return Pinia.globalStore.rtlMode ? rtlVar : ltrVal
			}
		
			// Append the keys
			keypad.append(`
				<tr>
					<td data-value="${rtlVal(3,1)}">${rtlVal(3,1)}</td>
					<td data-value="2">2</td>
					<td data-value="${rtlVal(1,3)}">${rtlVal(1,3)}</td>
					<td class="ui-keypad-space"></td> 
					<td rowspan="2" id="${id}-specialkey-1" class="ui-keypad-specialkey ui-keypad-specialkey-1"></td>
				</tr>`);
			keypad.append(`
				<tr>
					<td data-value="${rtlVal(6,4)}">${rtlVal(6,4)}</td>
					<td data-value="5">5</td>
					<td data-value="${rtlVal(4,6)}">${rtlVal(4,6)}</td>
					<td class="ui-keypad-space"></td>
				</tr>`);
			keypad.append(`
				<tr>
					<td data-value="${rtlVal(9,7)}">${rtlVal(9,7)}</td>
					<td data-value="8">8</td>
					<td data-value="${rtlVal(7,9)}">${rtlVal(7,9)}</td>
					<td class="ui-keypad-space"></td>
					<td rowspan="2" id="${id}-specialkey-2" class="ui-keypad-specialkey ui-keypad-specialkey-2"></td>
				</tr>`);
			keypad.append('\
				<tr>\
					<td data-action="bksp"><li class="bksp fa-solid fa-delete-left"></li></td>\
					<td data-value="0">0</td>\
					<td data-value=".">.</td>\
					<td class="ui-keypad-space"></td> \
				</tr>');
			if (!opts.withSpecialKeys) {
				$(keypad).find(' .ui-keypad-space').hide();
				$(keypad).find(' .ui-keypad-specialkey').hide();
			}
		
			var setDateFormat = function(value){
				opts.dateFormat=value;
			}
		
			var setUseFormatFlag = function(value){
				opts.useFormat=value;
			}
		
			var setTextSelectedFlag = function(value){
				opts.textSelected=value;
			}
		
			var setIsDateField = function(value){
				opts.isDateField=value;
			}
		
			var insertIntoInput = $('#' + opts.insertIntoInputWithID);
			var applyValueConstraints = function (value) {
				var inputFloatValue = parseFloat(value);
				if (isNaN(inputFloatValue)) {
					return opts.min;
				}
		
				if (opts.min != '-inf' && inputFloatValue < opts.min) {
					value = opts.min;
				}
				if (opts.max != 'inf' && inputFloatValue > opts.max) {
					value = opts.max;
				}
		
				return value;
			};
		
			//This method will return the fieldId value
			var getValueFrom = function (fieldId,isAmount) {
				if (fieldId == null){
					return
				}
		
				var fieldObj = $('#' + fieldId);
				var value = fieldObj.val();
		
				if (isAmount){
					if (opts.isPercentage) {
						value = value.substring(0, value.length-1);
					}
					return $.parseNumber(value, opts.format);
				}
		
				return value;
			};
		
			//This method will return value of the focused field
			var getValue = function () {
				var value = insertIntoInput.val();
				if (opts.isPercentage) {
					value = value.substring(0, value.length-1);
				}
				return $.parseNumber(value, opts.format);
			};
		
			//Set value to specific field
			var setValueTo = function(value,fieldId,isAmount){
				if (fieldId == null){
					return;
				}
		
				var fieldObj = null;
				if (fieldId.indexOf('#') == 0 ){
					fieldObj = $(fieldId)
				}else{
					fieldObj = $('#' + fieldId)
				}
		
				if (!isAmount){
					fieldObj.val(value);
					return;
				}
		
				//The field is amount
				value = applyValueConstraints(value);
				value = $.formatNumber(value, opts.format);
		
				if (opts.isPercentage) {
					value = value + '%';
				}
		
				fieldObj.val(value);
			};
		
			var setValue = function (value) {
		
				if (opts.useFormat){
					value = applyValueConstraints(value);
					value = $.formatNumber(value, opts.format);
		
					if (opts.isPercentage) {
						value = value + '%';
					}
				}
		
				insertIntoInput.val(value);
			};
		
			var onKeyClick = function () {
				if ($(this).attr('data-value') != null) {
					if (insertIntoInput.caret().start != insertIntoInput.caret().end) {
						setValue('');
					}
		
					if (opts.isDateField){
						var id = insertIntoInput.attr('id');
						var currVal = insertIntoInput.val();
						var val = "";
						if (currVal == ""){
							val = $.mask.string($(this).attr('data-value'),opts.dateFormat );
						}else{
							val = $.mask.string(currVal+$(this).attr('data-value'),opts.dateFormat );
						}
						setValueTo(val,id,false);
						return;
					}
		
					if (!opts.useFormat){
						var id = insertIntoInput.attr('id');
						var existValue = insertIntoInput.val();
		
						if (existValue == '' || opts.textSelected){
							setValueTo($(this).attr('data-value'),id,false);
							opts.textSelected = false;
						}else{
							setValueTo(existValue + $(this).attr('data-value'),id,false);
						}
						return;
					}
		
					// If not a number, add it directly
					if (isNaN(parseFloat($(this).attr('data-value')))) {
						insertIntoInput.val(insertIntoInput.val() + $(this).attr('data-value'));
					} else {
						var currentValue = insertIntoInput.val();
						if (currentValue[currentValue.length - 1] == '.') {
							setValue(getValue() + '.' + $(this).attr('data-value'));
						} else {
							setValue(getValue() + $(this).attr('data-value'));
						}
					}
				}
		
				if ($(this).attr('data-action') != null && $(this).attr('data-action') == 'bksp') {
					var value = getValue() + '';
					setValue(value.substring(0, value.length-1));
				}
			};
			$(keypad).on('click', 'td', onKeyClick);
			$(insertIntoInput).on('keyup', function () {
				let value =  insertIntoInput.val()
				value = value.replace(/[^0-9.]+/g, "")
				setValue(value)
			});
			
			this.setFormat = function (format) {
				opts.format = format;
			};
		
			this.setIsPercentage = function (value) {
				opts.isPercentage = value;
			};
		
			this.setSpecialKeyHtml = function (specialKeyNumber, html, classes) {
				if (specialKeyNumber != 1 && specialKeyNumber != 2) {
					return;
				}
		
				var specialKeyID = id + '-specialkey-' + specialKeyNumber;
				$('#' + specialKeyID).html(html);
		
				if (arguments.length > 2) {
					$('#' + specialKeyID).addClass(classes);
				}
		
				return $('#' + specialKeyID);
			};
		
			this.setSpecialKeyListener = function (specialKeyNumber, listener) {
				if (specialKeyNumber != 1 && specialKeyNumber != 2) {
					return;
				}
		
				var specialKeyID = id + '-specialkey-' + specialKeyNumber;
				$('#' + specialKeyID).unbind();
				$('#' + specialKeyID).click(listener);
				return $('#' + specialKeyID);
			};
		
			this.insertCharWithCode = function (charCode) {
				if (charCode == 46 || (charCode >= 48 && charCode <= 57)) {
					$('#' + id + ' [data-value="' + String.fromCharCode(charCode) + '"]').click();
				}
			};
		
			this.insertDigitOrEqualWithCode = function (charCode) {
				var fieldId = insertIntoInput.attr('id');
				if (charCode == 61  || charCode == 46 || (charCode >= 48 && charCode <= 57))
				{
					if (!opts.textSelected){
						this.setValueTo(insertIntoInput.val()+String.fromCharCode(charCode),fieldId,false);
					}else{
						this.setValueTo(String.fromCharCode(charCode),fieldId,false);
						opts.textSelected = false;
					}
		
				}
			};
		
			this.setMin = function (min) {
				opts.min = min;
			};
			this.setMax = function (max) {
				opts.max = max;
			};
			this.setStep = function (step) {
				opts.step = step;
			};
		
			this.getValue = function () {
				return getValue();
			};
		
			this.getValueFrom = function(fieldId,isAmount){
				return getValueFrom(fieldId,isAmount);
			};
		
			this.setValue = function (value) {
				return setValue(value);
			};
		
			this.setValueTo = function(value,fieldId,isAmount){
				setValueTo(value,fieldId,isAmount);
			};
		
			this.setInsertInputWithID = function(fieldId){
				opts.insertIntoInputWithID = fieldId;
				insertIntoInput = $('#' + opts.insertIntoInputWithID);
		
			};
		
			this.getInsertIntoInputId = function(){
				return insertIntoInput.attr('id');
			};
		
			this.setDateFormat = function(dateFormat){
				setDateFormat(dateFormat);
			};
		
			this.setIsDateField = function(isDateField){
				setIsDateField(isDateField);
			};
		
			this.setUseFormatFlag = function(value){
				setUseFormatFlag(value);
			};
		
			this.setTextSelectedFlag = function(value){
				setTextSelectedFlag(value);
			};
		
			// Register the instance
			PositiveTS.UI.Base.registerInstance(id, this);
		
			return this;
		};
		
	}
}
 
// Add it to jQuery prototype
jQuery.fn.keypad = function (options) {
	if (this.find(' > .ui-keypad-container').length > 0) {
		return PositiveTS.UI.Base.getInstance(this.find(' > .ui-keypad-container > .ui-keypad').attr('id'));
	}

	return (new (PositiveTS.UI.KeyPad as any)(this, options));
};
