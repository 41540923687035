module PositiveTS {
export module Service {
export class Logger {
  static CATEGORY = { SVA: 1};
  static logger;

  private static getLogger() {
		if (Logger.logger == null) {
			Logger.logger = log4javascript.getLogger();

			// let ajaxAppender = new log4javascript.AjaxAppender('/logs?pos_device_id=' + session.pos.deviceID);

			// ajaxAppender.setBatchSize(10);

			// ajaxAppender.addHeader('Authorization', "Token token=" + session.pos.access_token)

			// Logger.logger.addAppender(ajaxAppender);
		}

		return Logger.logger;
	}

	static info() {
		let log = Logger.getLogger();

		log.info.apply(Logger.logger, arguments);
	}

	static trace() {
		var log =  Logger.getLogger();

		log.trace.apply(Logger.logger, arguments);
	}

	static debug () {
		let log =  Logger.getLogger();

		log.debug.apply(Logger.logger, arguments);
	}

	static warn() {
		var log =  Logger.getLogger();

		log.warn.apply(Logger.logger, arguments);
	}

	static error(err: Error | ErrorEvent | PromiseRejectionEvent | string) {
		try {
			let wrappedError: Error | ErrorEvent | PromiseRejectionEvent;
			if (typeof(err) == "string") {
				wrappedError = new Error(err);
			}
			else {
				wrappedError = err;
			}
			datadogLogs.logger.error((<any>wrappedError).message || (<any>wrappedError).reason);
		}
		catch(e) {
			console.error('LogRocket/datadog/OpenReplay exception');
			console.error(e);
		}
	}

	static fatal() {
		var logger =  Logger.getLogger();

		logger.fatal.apply(Logger.logger, arguments);
	}
}}}