module PositiveTS {
  export module Components {
      export module PrintReceiptDialog {
  
        export function create() {
  
          let component = {
            template: JST.printReceiptDialog(),
            methods: {
              printReceipt(input) {   
                this.resolveFunc(input);
                this.close();
              },              
      
              close() {
                  if (this.dialogElem == null) {
                    this.dialogElem = this.$el; 
                  }
                  this.dialogElem.close();
                  this.isOpen = false;
              },  
                      
              open() {
                return new Promise((resolve) => {
                  if (this.dialogElem == null) {
                    this.dialogElem = this.$el; 
                  }
                  this.dialogElem.showModal();
                  this.resolveFunc = resolve;
                  this.isOpen = true;
                })
              }
            },
            data() {
              return {
                isOpen: false,
                resolveFunc: null,
              }
            }    
          }
  
          VueApp.component('print-receipt-dialog',component)
        }
      }
    }
  }
  