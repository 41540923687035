import { SalePayment } from "./types/salePayment";
import { PaymentData } from "./types/paymentData";
import { CurrenciesSummaryResult } from "./types/result";
import SalePaymentMethod from "../enums/SalePaymentMethod";

export default class CurrenciesSummary {

    constructor(private baseCurrency: string, private i18next: any) { }



    calc(payments: Array<SalePayment>): CurrenciesSummaryResult {
        return this.calculate(payments);
    }

    calcCash(payments: Array<SalePayment>): CurrenciesSummaryResult {
        return this.calculate(payments, { methods: [SalePaymentMethod.CHANGE, SalePaymentMethod.CASH] });
    }

    private calculate(payments: Array<SalePayment>, args?): CurrenciesSummaryResult {
        let acceptedMethods = args?.methods || [SalePaymentMethod.CREDIT, SalePaymentMethod.CASH, SalePaymentMethod.CHANGE];
        let currenciesSummary: CurrenciesSummaryResult = {};
        for (const payment of payments) {
            if (acceptedMethods.every(method => payment.method != method)) {
                // Default: No actual money is gained or lost in this sale
                continue;
            }
            let paymentDataArr: Array<PaymentData> = JSON.parse(payment.data);
            if (!paymentDataArr || paymentDataArr.length <= 0) { // No way to determine the payment's currency, asume its default
                let key = this.baseCurrency;
                if (!currenciesSummary[key]) {
                    currenciesSummary[key] = { currency: this.i18next.t(`currenciesNames.${key}`), total: 0, totalInPosCurrency: 0 }
                }
                let amountFactor = payment.method == SalePaymentMethod.CHANGE ? -1 : 1;
                currenciesSummary[key].total += payment.amount * amountFactor;
                currenciesSummary[key].totalInPosCurrency += payment.amount * amountFactor;
            } else {
                for (const paymentData of paymentDataArr) {
                    let key = paymentData.paid_currency_symble || paymentData.creditCurrency;
                    if (!key) {
                        continue;
                    }
                    if (!currenciesSummary[key]) {
                        currenciesSummary[key] = { currency: this.i18next.t(`currenciesNames.${key}`), total: 0, totalInPosCurrency: 0 }
                    }
                    let amountFactor = payment.method == SalePaymentMethod.CHANGE ? -1 : 1;
                    if (paymentData.paid_currency_amount) { // From here, we know the currency + the amount of it
                        let amount = paymentData.paid_currency_amount;
                        currenciesSummary[key].total += amount * amountFactor; // This can be minus/plus (minus for refund of course)
                        currenciesSummary[key].totalInPosCurrency += Number(amount / paymentData.paid_currency_rate)  * amountFactor;
                    } else if (paymentData.creditCurrencyFullNumber || paymentData.creditLeadCurrencyAmount) { // This is an EMV deal
                        let amountFactor: number = CurrenciesSummary.isEmvNegativePayment(paymentData) ? -1 : 1;
                        let amountField: number = paymentData.creditCurrencyFullNumber || paymentData.creditLeadCurrencyAmount;
                        let amount = amountField * amountFactor;
                        currenciesSummary[key].total += amount * amountFactor;
                        currenciesSummary[key].totalInPosCurrency += Number(amount / paymentData.currencyRate) * amountFactor;
                    } else {
                        console.warn("What is this special deal I do not no how to READ!?!?", payment, paymentData);
                    }
                }
            }
        }
        return currenciesSummary;
    }

    static isEmvNegativePayment(paymentData: PaymentData) {
        return paymentData.TranType?.toString() == "53" || paymentData.Mti?.toString() == "400"
    }

}

