module PositiveTS {
    export module Service {
        export module SimplyClubAPI {
            export interface SubTotalResponse extends BaseResponse {
                IncentiveMessages: any[];
                SuggestedInfo: any[];
                Vouchers: any[];
                TranDiscountPercent: number;
                ServiceTranNumber: string;
                ImmidiateDiscounts: any[];
                CashBackDiscounts: CashBackDiscount[];
                TotalDiscounts: TotalDiscount[];
                TransactionItems: TransactionItem[];
                AdditionalInfo: any[];
            }

            export interface PromotedItem {
                TranNumber: number;
                PromoId: number;
                LineId: string;
                Code: string;
                Amount: number;
                CostDiscount: number;
                Discount: number;
                TranDiscount: number;
                Quantity: number;
                Description: string;
                CreatedDate: Date;
                UpdateDate: Date;
                OwnerSysId: number;
                SysId: number;
                IsManualSysID: boolean;
                ObjectType: number | BenefitObjectType;
            }

            export interface BaseBenefit {
                PromotedItems: PromotedItem[];
                IsAuto: boolean;
                Description: string;
                Amount: number;
                POIDescrption: string;
                ItemCode: string;
                SysId: number;
                PromoId: number;
                TranNumber: number;
                CreatedDate: Date;
                UpdateDate: Date;
                OwnerSysId: number;
                IsManualSysID: boolean;
                ObjectType: number | BenefitObjectType;
                /** Custom Field to calc overall benefit's usage value for the sale
                 * 
                 * - Boolean for "all or nothing" Cashbacks/Discounts
                 * - Number for Cashbacks that allow partial usage (i.e. use 10 points out of 20)
                  */
                userField: number | boolean;
            }

            export interface CashBackDiscount extends BaseBenefit {
                /** This represents the Cashback's value to the ILS currency */
                ItemQty: number;
                IsRequireSelectItems: boolean;
                BenefitType: number;
            }            

            export interface TotalDiscount extends BaseBenefit {
                BruttoAmount: number;
                AllItemsDiscountPercent: number;
                TotalDiscountType: number;
                MaxAddItemQuantity: number;
                BurnAccumID: number;
                BurnQuantity: number;
            }

            export interface AdditionalInfo {
                FieldId: string;
                FieldValue: string;
            }
            export interface TransactionItem {
                ItemSaleData: TranItem;
                TranNumber: number;
                TotalDiscount: number;
                OperationDiscount: number;
                PromoPercent: number;
                PromoPrice: number;
                PromoCost: number;
                OriginalPrice: number;
                TrxTotalAmount: number;
                iTrxTotalAmount: number;
                Quantity: number;
                NettoAmount: number;
                Amount: number;
                Price: number;
                SysDiscount: number;
                Discount: number;
                Description: string;
                CreatedDate: Date;
                UpdateDate: Date;
                OwnerSysId: number;
                SysId: number;
                IsManualSysID: boolean;
                ObjectType: number | BenefitObjectType;
            }

            export enum BenefitObjectType {
                DISCOUNT = 68,
                CASHBACK = 69
            }
        }

    }
}