

export default class FetchReq {
    constructor() { throw new Error("Static class"); }

    static req(reqUrl: string, reqType: string, reqObj?: any, noAcceptHeader?: boolean, headers?: object): Promise<Response> {
        let fetchFunc = window.fetch;
        return fetchFunc(reqUrl, new FetchObj(reqType, reqObj, noAcceptHeader, headers));
    }

    static textReq(reqUrl: string, reqType: string, reqObj?: any, noAcceptHeader?: boolean, headers?: object): Promise<{ response: Response, result: string }> {
        var _response: Response;
        var objFetch = new FetchObj(reqType, reqObj, noAcceptHeader, headers);
        let fetchFunc = window.fetch;

        return fetchFunc(reqUrl, objFetch)
            .then((response) => {
                _response = response;
                return response.text();
            })
            .then((str) => {
                return { response: _response, result: str };
            });
    }

    static async jsonReq(reqUrl: string, reqType: string, reqObj?: any): Promise<{ response: Response, result: any }> {
        var _response: Response;
        let fetchFunc = window.fetch;

        let response = await fetchFunc(reqUrl, new FetchObj(reqType, reqObj))
        _response = response;
        let obj = await response.json();
        if (session.isAndroid) {
            console.debug({ type: "XHR Request", payload: reqObj });
            console.debug({ type: "XHR Response", payload: obj });
        }
        return { response: _response, result: obj };
    }

    static async fileRequestAndDownload(reqUrl: string, fileName: string, reqType = 'get', reqObj?: any): Promise<undefined> {
        let fetchFunc = window.fetch;
        let response = await fetchFunc(reqUrl, new FetchObj(reqType, reqObj));
        let blob = await response.blob();

        if (session.isAndroid) {
            console.debug({ type: "XHR Request", payload: reqObj });
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();

        return undefined;
    }

    static async jsonReqWithTimeout(reqUrl: string, reqType: string, timeout: number, reqObj?: any): Promise<any> {
        return Promise.race([
            new Promise(async (resolve, reject) => {
                this.jsonReq(reqUrl, reqType, reqObj).then((response) => { resolve(response); })
                    .catch((error) => { console.error(error); resolve(null) })
            }),
            new Promise((resolve, reject) => {
                setTimeout(() => { resolve(null) }, timeout);
            })
        ]);
    }

    static jsonRequestPromise(reqUrl: string, reqType: string, reqObj?: any, noAcceptHeader?: boolean, headers?: object): Promise<{ success: boolean, data: any, errors: Array<any>, response: Response }> {
        return new Promise(async (resolve, reject) => {
            let result = { success: false, data: null, errors: [], response: new Response }
            let fetchFunc = (<any>window).trackerFetch || window.fetch; //use openReplay plugin for fetch if available

            try {
                let response = await fetchFunc(reqUrl, new FetchObj(reqType, reqObj, noAcceptHeader, headers));
                result.response = response
                if (response.ok) {
                    result.data = await response.json()
                    result.success = true
                    resolve(result)
                } else {
                    let responseResult = await response.json()
                    if (responseResult) {
                        if (responseResult.errors) {
                            result.errors = responseResult.errors
                        }
                    }
                    reject(result)
                }
            } catch (error) {
                console.error(error)
                reject(result)
            }
        })
    }

    static async makeJobRequest(requestData: any, runAsJob: boolean) {
        let action: Function;
        const modifiedRequestData = { ...requestData };
        if (runAsJob) {
            action = async () => await PositiveTS.Service.SocketManager.makeRequestAndWaitForSocketResult(modifiedRequestData)
        } else {
            action = async () => await this.jsonReq(requestData.url, requestData.method, requestData.data)
        }
        try {
            let result = await action();
            return result

        } catch (e) {
            console.error(e)
            throw e
        }
    }
}



class FetchObj implements RequestInit {
    method: string;
    body: string;

    headers: { [index: string]: string } = {
        'Authorization': `Token token=${session.pos.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    constructor(method: string, body?: any, noAcceptHeader?: boolean, headers?: object) {
        this.method = method;

        if (headers) {
            Object.assign(this.headers, headers)
        }

        if (noAcceptHeader) {
            this.headers['Accept'] = '';
        }

        this.headers['Accept-Language'] = jsonConfig.getVal(jsonConfig.KEYS.lang);

        if (body) {
            this.body = JSON.stringify(body);
        }
    }
} 