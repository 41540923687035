module PositiveTS {
export module Components {
    export module CouriersAssignmentDialog {

      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $("#couriers-assignment-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '100%',
              height: 550,
              position: 'top',
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }



      function close() {
        $("#couriers-assignment-dialog").dialog('close');
        $(document).keypress(posVC.onKeyPress);
        this.selectedOrdersIds = [];

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          this.deactivateDalpaksOrderGetters();
        }
      }

      function open() {
        this._initjQueryDialogIfNeeded()
        $(document).unbind('keypress');
        $("#couriers-assignment-dialog").dialog('open');
        PositiveTS.Service.Delivery.getCouriers().then(couriers =>{
          this.couriers = couriers;
          this.selectedCourierID = this.couriers[0].employeeID;
          this.selectedCourier = this.couriers[0].name;
        })

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          this.activateDalpaksOrderGetters();
        }

        this.loadOrders();
      }

      function _initData(){
        return {openOrders: [],
                tableFilter: "",
                _orgopenOrders: [],
                selectedCourier: "",
                selectedCourierID: null,
                selectedStatus: "",
                selectedOrdersIds: [],
                selectedType: "",
                couriers: [],
                statuses: [PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY
                  ,PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY
                  ,PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH],
                types: ['Delivery','TA','External']};
      }

      function showOrder(saleParam){
Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryShowOrder", args: [saleParam, saleParam.items, false ]})
      }

      function createdAtStr(createdAt) {
        if (!createdAt) { 
          return "";
        }

        let date = moment(createdAt)
        return `${date.format("DD/MM HH:mm")}`
      }      

      async function assignCourier(){
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          let ordersToAssing = this.selectedOrders.filter(order => order.status == PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY);
          for (let order of ordersToAssing) {
            let isSuccess = await PositiveTS.Service.Delivery.setCourier(_.cloneDeep(order), this.selectedCourierID, this.selectedCourier); 

            if (isSuccess) {
              this.unselectOrder(order);
            }
          }

          return;
        }


        for (let order of this.selectedOrders) {
          if(order.status == PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY && this.isSelected(order)){
            order.status = PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH;
            order.assignedCourierTime = Date.now();  
            this.unselectOrder(order);
            
            await PositiveTS.Service.Delivery.setCourier(order, this.selectedCourierID, this.selectedCourier);
          }
          
        }
      }

      

      function selectCourier(courier){
        this.selectedCourier = courier.name;
        this.selectedCourierID = courier.employeeID;
      }

      async function unassignCourier(){
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          let ordersToUnAssing = this.selectedOrders.filter(order => order.status != PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY);

          for (let order of ordersToUnAssing) {
            let isSuccess = await PositiveTS.Service.Delivery.unassignCourier(_.cloneDeep(order)); 

            if (isSuccess) {
              this.unselectOrder(order);
            }
          }

          return;
        }

        for (let order of this.selectedOrders) {
          if(order.status != PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY && this.isSelected(order)){

            order.status = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
            this.unselectOrder(order);
            
            await PositiveTS.Service.Delivery.unassignCourier(order);
          }
        }
      }

      export function create() {

        let couriersAssignmentDialog = {
            template: JST.couriersAssignmentDialog(),
            methods: {
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              close: close,
              open: open,
              createdAtStr: createdAtStr,
              showOrder: showOrder,
              assignCourier:assignCourier,
              selectCourier: selectCourier,
              unassignCourier: unassignCourier,
              toggleSelectOrder(order){
                if (this.selectedOrdersIds.includes(order.id)) {
                  this.unselectOrder(order);
                } else {
                  this.selectOrder(order);
                }
              },
              selectOrder(order) {
                this.selectedOrdersIds.push(order.id);
              },
              unselectOrder(order) {
                let orderIdIndex = this.selectedOrdersIds.indexOf(order.id);

                if (orderIdIndex != -1) {
                  this.selectedOrdersIds.splice(orderIdIndex, 1);
                }
              },
              isSelected(order) {
                return this.selectedOrdersIds.includes(order.id)
              },
              ordersForCourier(courierId) {
                return this.ordersToRender.filter(order => order.courierID == courierId)
              },
              loadOrders() {

                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  app.showLoadingMessageDialog(i18next.t('delivery.loadingDeliveries'));
                  Service.Dalpak.isDalpaksDataValid().then(result => {
                    app.hideLoadingMessageDialog();

                    if (!result.success) {
                      app.promiseShowAlert({
                        header: i18next.t('error'),
                        content: result.errorMessage,
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                      });
                      this.close();
                    }
                  })

                  return;
                }

                PositiveTS.Service.Delivery.getAllOrders()
                .then( (openOrders =>{
                  
                  this.openOrders = [];
                  for(let i=0;i<openOrders.length;i++)
                  {
                    if(this.isToShowOrder(openOrders[i])){
                      this.openOrders.push(openOrders[i]);
                    }
                  }
                  this._orgopenOrders = openOrders;
                }));
              },
              isToShowOrder(order) {
                return !order.isTa && order.status != Storage.Entity.Sale.DELIVERED && !Service.Delivery.isPartiallyFilledOrder(order);
              },
            },
            setup(){
              const dalpaksStore = Pinia.useDalpaksStore();
              const {dalpaksOrders} = Pinia.storeToRefs(dalpaksStore);

              const {activateDalpaksOrderGetters,deactivateDalpaksOrderGetters} = dalpaksStore
              const dalpaksActions = {activateDalpaksOrderGetters,deactivateDalpaksOrderGetters}

              const globalStore = Pinia.useGlobalStore();
              const {portraitMode} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {portraitMode} 
              
              return {...globalStoreProps,...dalpaksActions,dalpaksOrders}
            },
            computed: {
              selectedOrders() {
                return this.ordersToRender.filter(order => this.isSelected(order));
              },
              ordersToRender() {
                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  return this.dalpaksOrders.filter(order => this.isToShowOrder(order));
                }

                return this.openOrders;
              },

              openStatusOrders () {
                return this.ordersToRender.filter(order => order.status == 1)
              }
            },
            data: _initData

        }

        VueApp.component('couriers-assignment-dialog',couriersAssignmentDialog)

      }
    }
}
}
