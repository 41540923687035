module PositiveTS {
export module Storage {
export module Entity {
export class Company extends WasmEntity {
	tenantID:string
	companyID:string
	companyName:string
	city:string
	street:string
	buildingNo:string
	registrationNum:string
	phone:string
	logo:string
	contactPerson:string
	contactPersonPhone:string
	webSite:string

	static dbCache:Array<Company> = null


	constructor() {
		let meta = {
			name: 'Company',
			fields: {
				tenantID: "TEXT",
				companyID: "TEXT",
				companyName: "TEXT",
				city: "TEXT",
				street: "TEXT",
				buildingNo: "TEXT",
				registrationNum: "TEXT",
				phone: "TEXT",
				logo: "TEXT",
				contactPerson: "TEXT",
				contactPersonPhone: "TEXT",
				webSite: "TEXT"
			},
			unique: ['tenantID','companyID']
		}
		super(meta)
	}

	getCache(fieldName:string) {
		return super.getCache<Company>("dbCache");
	}

	promiseFetchByTenantIDAndCompanyID(tenantID, companyID) {
		return Promise.resolve(this.fetchByTenantIDAndCompanyID(tenantID, companyID)[0])
	};

	fetchByTenantIDAndCompanyID (tenantID, companyID) {
		let query = `SELECT * FROM ${this.meta.name}
						 	WHERE tenantID = ${tenantID} AND 
							companyID = ${companyID}`;


		return this.execAndConvertResult(query);
	};

}}}}
