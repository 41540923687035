module PositiveTS {
    export module Components {
        export module TimeClockDialog {

            const componentName = "time-clock-dialog"

            export function create() {

                let component = {
                    template: JST.timeClockDialog(),
                    methods: {
                        open(): void {
                            $(document).unbind('keypress');
                            this.$el.showModal();
                            this.$nextTick(() => {
                                this.focusInput()
                            })
                        },

                        showBreakTimeTemplate() {
                            return new Promise((resolve, reject) => {
                                this.resolveFunc = resolve
                                this.rejectFunc = reject
                                this.setViewType('employeeTimeBreak')
                            })
                        },

                        close(): void {
                            this.$el.close()
                            this.clearData()
                        },

                        setMode(modeType: number): void {
                            this.focusInput()
                            this.mode = modeType
                        },

                        focusInput(): void {
                            this.$refs.magneticCardInput.focus()
                        },

                        clearMagneticCardField(): void {
                            this.employeeMagneticCard = ""
                            this.focusInput()
                        },

                        toggleKeyboard(): void {
                            this.showKeyboard = !this.showKeyboard
                            this.focusInput();
                        },

                        updateVal(value: string): void {
                            this.employeeMagneticCard = value
                        },

                        openEmployeeInStore(): void {
                            PositiveTS.Service.EmployeeTimeTrack.EmployeeTimeTrackEntry.openEmployeeInStore()
                        },

                        async swipeCard(): Promise<void> {
                            app.showLoadingMessageDialog(i18next.t("ett.swipeCard"));

                            if ((jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv)) {
                                this.employeeMagneticCard = await Service.EMV.swipe();
                                if (this.employeeMagneticCard) {
                                    await this.magneticCardEntry()
                                }
                            }
                            app.hideLoadingMessageDialog();
                        },

                        async magneticCardEntry(): Promise<void> {
                            try {
                                let mode = this.mode
                                let employeeMagneticCard = this.employeeMagneticCard
                                let entry = await Service.EmployeeTimeTrack.EmployeeTimeTrackEntry.magneticCardEntry(mode, employeeMagneticCard)

                                if (entry.success) {
                                    let currentBreakTime =  !posUtils.isBlank(entry.current_break_time_minutes) ? entry.current_break_time_minutes : null
                                    let totalBreakTime = !posUtils.isBlank(entry.total_break_time_minutes) ? entry.total_break_time_minutes : null

                                    this.afterSavedSuccessfully(entry.employee, mode, entry.timeTrack, entry.msg, entry.lastTimeTrack, currentBreakTime, totalBreakTime)
                                } else {
                                    app.showAlertDialog({
                                        content: entry.msg,
                                        continueButtonText: i18next.t("ok"),
                                        hideCancelButton: true
                                    })

                                }

                            } catch (e) {
                                Service.Logger.error(e);
                                app.hideLoadingMessageDialog();
                                app.showAlertDialog({
                                    content: e.message,
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                })
                            }
                        },

                        async fingerprintEntry(): Promise<void> {
                            try {
                                let entry = await PositiveTS.Service.EmployeeTimeTrack.EmployeeTimeTrackEntry.fingerprintEntry(this.mode)
                                if (entry.success) {
                                    let currentBreakTime =  !posUtils.isBlank(entry.current_break_time_minutes) ? entry.current_break_time_minutes : null
                                    let totalBreakTime = !posUtils.isBlank(entry.total_break_time_minutes) ? entry.total_break_time_minutes : null

                                    this.afterSavedSuccessfully(entry.employee, this.mode, entry.timeTrack, entry.msg, entry.lastTimeTrack, currentBreakTime, totalBreakTime)
                                }
                            } catch (e) {
                                Service.Logger.error(e);
                                app.hideLoadingMessageDialog();
                                app.showAlertDialog({
                                    content: e.message,
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                })
                            }
                        },

                        async afterSavedSuccessfully(employee, mode, timeTrack, svrMsg, lastTimeTrack, currentBreakTime, totalBreakTime): Promise<void> {
                            let modeText = '', enMode = '';
                            if (mode == PositiveTS.Storage.Entity.EmployeeTimeTrack.MODE_ENTER) {
                                modeText = i18next.t("ett.enter");
                                enMode = i18next.t("ett.enter");
                            } else {
                                modeText = i18next.t("ett.exit");
                                enMode = i18next.t("ett.exit");
                            }

                            let dialogMessage = svrMsg || i18next.t('employeeTimeTrackAddedSuccessfully', {STATUS: modeText, EMPLOYEE_NAME: employee.name})
                            let printedMessage = i18next.t('employeeTimeTrackAddedSuccessfully', {STATUS: modeText, EMPLOYEE_NAME: employee.name})

                            if(!posUtils.isBlank(currentBreakTime) && !posUtils.isBlank(totalBreakTime)) {
                                const breakTimes = [
                                    {
                                        rowTitle: i18next.t('ettBreakTime.currentBreakTime'),
                                        rowValue: currentBreakTime
                                    },
                                    {
                                        rowTitle: i18next.t('ettBreakTime.totalBreakTime'),
                                        rowValue: totalBreakTime
                                    }
                                ]

                                dialogMessage += '\n'
                                printedMessage  = `${i18next.t('ettBreakTime.shiftStartTime')}: ${moment(lastTimeTrack.time_track_at).format("HH:mm")} \n${breakTimes[1].rowTitle}: ${moment.utc(new Date(0)).add(breakTimes[1].rowValue, 'minutes').format("HH:mm:ss")}`

                                for (const breakTime of breakTimes) {
                                    let {rowTitle, rowValue } = breakTime

                                    dialogMessage += `${rowTitle}: ${moment.utc(new Date(0)).add(rowValue, 'minutes').format("HH:mm:ss")} \n`
                                }
                            }
                            
                            const afterContinueCallback = async () => {
                                await PositiveTS.Service.EmployeeTimeTrack.EmployeeTimeTrackEntry.printTimeTrack(employee, enMode, mode, timeTrack, printedMessage, lastTimeTrack);
                                if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery) && mode === Storage.Entity.EmployeeTimeTrack.MODE_EXIT){
                                    let magneticCard = this.employeeMagneticCard
                                    await Service.EmployeeTimeTrack.EmployeeTimeTrackEntry.checkForOpenDeliveries(magneticCard)
                                }
                                await PositiveTS.Service.AutoEmployeeStore.runService();
                                this.close();
                            }

                            

                            app.showAlertDialog(
                                {
                                    header: i18next.t('pageTitle.employeeTimeTrack'),
                                    content: dialogMessage,
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                },
                                afterContinueCallback,
                                null
                            );
                        },

                        setIsTimeBreak(isTimeBreak) {
                            this.resolveFunc(isTimeBreak)
                        },

                        setViewType(viewType) {
                            this.viewType = viewType
                        },
                        
                        clearData(): void {
                            $(document).unbind('keypress');
                            this.mode = 1,
                            this.employeeMagneticCard = "",
                            this.showKeyboard = false,
                            this.viewType = "employeeEntry",
                            this.resolveFunc = null,
                            this.rejectFunc = null,
                            this.isRoshemet = session.pos.isRoshemet
                        }
                    },
                    data() {
                        return {
                            mode: 1,
                            employeeMagneticCard: "",
                            showKeyboard: false,
                            viewType: "employeeEntry",
                            resolveFunc: null,
                            rejectFunc: null,
                            isRoshemet: session.pos.isRoshemet,
                            isAndroid: session.isAndroid,
                        }
                    },
                }

                VueApp.component(componentName, component)
            }



        }
    }
}