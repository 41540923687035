module PositiveTS {
export module Components {
    export module ItemSheilta {

      const componentName = "item-sheilta"
      const componentSelector = "#" + componentName;

      function _initjQueryDialogIfNeeded() {
        var that = this;
        if (!this._initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(componentSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: Pinia.globalStore.mobileLayout ? '100vw' : '90%',
              height:  Pinia.globalStore.mobileLayout ? $(window).height() : 600,
              resizable: false,
              position: Pinia.globalStore.mobileLayout ? { my: "top", at: "top", of: window} : { my: "center", at: "center", of: window },
              closeOnEscape: false,
              draggable: false,
            });

          this._initialized = true
        }





        this.$refs.itemCode.focus();

      }



      function close() {
        $(componentSelector).dialog('close');
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      function open() {
        this._initjQueryDialogIfNeeded();
        $(document).unbind('keypress');
        $(componentSelector).dialog('open');
        // this.rows = [];
        this.$refs.itemCode.focus();
      }





      function searchItem(){

        // search by code or barcode
        app.showLoadingMessage()

        return _getItemSearch(this.itemCode, this.showPromotions)
        .then( retult =>{
          this.rows = retult;
          this.$refs.itemCode.focus();
          this.$refs.itemCode.select();
          app.hideLoadingMessage()

        })



      }

      function _initData(){
        return {
          _initialized: false,
          itemCode: "",
          rows: [],
          showPromotions: true
        };
      }


      function showPromo(row:itemList){
        return _promotionsByPromotionString(row.promoBuy, row.promoGet)
        .then(result =>{
Pinia.componentsStore.openComponent( {componentName:"itemPromoList", args: [result, row]})
        })

      }

      function showInv(row:itemList){
        Pinia.componentsStore.openComponent( {
          componentName: 'itemInventoryItm', 
          args: [{itemCode:row.code, itemDescription: row.description, 
          color: row.color, size:row.size}, false, false]});
      }

      export function create() {

        let component = {
            template: JST.ItemSheilta(),
            methods: {
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              close: close,
              searchItem: searchItem,
              showPromo: showPromo,
              showInv: showInv
            },
            computed:{
              mobileLayout(){
                return Pinia.globalStore.mobileLayout
              },
              portraitMode(){
                return Pinia.globalStore.portraitMode
              },
            },

            data: _initData,

        }

        VueApp.component(componentName,component)

      }

       interface itemList {
          code: string;
          description: string;
          priceZarhan: number;
          promoBuy: string;
          promoGet: string;
          barcode: string;
          size: string;
          color:string;
          supplierName: string;
          promotions: Array<object>;
        }

        const _maxFuzzySearchRows = 12;


        function _promotionsByPromotionString(promoBuyString: string, promoGetString: string):Promise<{code:number, description:string}[]>{

          let promoBuy = promoBuyString.split("&").filter((value) =>{return Boolean(value)})
          let promoGet = promoGetString.split("&").filter((value) =>{return Boolean(value)})  
          let promos =  _.merge(promoBuy, promoGet).join(",")
          let _return: any = []

          session.allPromotions.forEach(p => {
            
            if (p.isActive 
                && (promoBuy.includes(p.code) || promoGet.includes(p.code))
                && (moment(p.toDate, 'DD/MM/Y') >= moment() )
                ){
                  _return.push({
                    code: p.code,
                    description: p.name,
                    toDate: p.toDate
                  })

            }

          })

          return _return

        }


        function _getItemSearch(search:string, includePromotions: boolean){
          return _exactSearch(search, includePromotions)
          .then( (result) =>{
            if (result.length > 0) {
              return result
            }
            return _fuzzySearch(search, includePromotions);
          })
          .then(result =>{
            return _.sortBy(result, function(o) { return o.code + o.size + o.coloe ; })
          })
        }



        function _fuzzySearch(search: string, includePromotions: boolean):Promise<itemList[]>{

          search = search.trim()

          var sql = `
          select item.code, item.description, item.priceZarhan, item.promoBuy, item.promoGet,
                itembarcode.barcode, itembarcode.size, itembarcode.color, item.supplierName
          from item left join itembarcode on item.code = itembarcode.code
          where
            upper(item.code) like '${search}%' or upper(item.code) like '%${search}' or
            upper(itembarcode.barcode) like '${search}%' or upper(itembarcode.barcode) like '%${search}' or
            upper(item.description) like '%${search}%'
          limit ${_maxFuzzySearchRows}
          `.toUpperCase();

          return PositiveTS.Service.WasmDB.promiseSql( sql )
          .then( async (result) => {
              var _return:itemList[] = [];
              for (var i=0;i<result.length;i++){
                _return.push({
                  code: result[i].code,
                  description: result[i].description,
                  priceZarhan: result[i].priceZarhan,
                  promoBuy: result[i].promoBuy,
                  promoGet: result[i].promoGet,
                  barcode: result[i].barcode,
                  size: result[i].size,
                  color: result[i].color,
                  supplierName: result[i].supplierName,
                  promotions: includePromotions ? await _promotionsByPromotionString(result[i].promoBuy, result[i].promoGet) : []
                })
              }
              return _return;
          })
        }



        function _exactSearch(search:string, includePromotions: boolean):Promise<itemList[]>{

          search = search.trim()

          var sql = `
            select item.code, item.description, item.priceZarhan, item.promoBuy, item.promoGet,
              itembarcode.barcode, itembarcode.size, itembarcode.color, item.supplierName
            from itembarcode left join item on item.code = itembarcode.code
            where itembarcode.code = '${search}' or itembarcode.barcode = '${search}'
            `;

          return PositiveTS.Service.WasmDB.promiseSql( sql )
          .then( async (result) => {
              var _return:itemList[] = [];
              for (var i=0;i<result.length;i++){
                let ob = {
                  code: result[i].code,
                  description: result[i].description,
                  priceZarhan: result[i].priceZarhan,
                  promoBuy: result[i].promoBuy,
                  promoGet: result[i].promoGet,
                  barcode: result[i].barcode,
                  size: result[i].size,
                  color: result[i].color,
                  supplierName: result[i].supplierName,
                  promotions: includePromotions ? await _promotionsByPromotionString(result[i].promoBuy, result[i].promoGet) : []
                }

                _return.push(ob)
              }
              return _return;
          })
        }


}
}
}
