module PositiveTS {
export module Components {
    export module PriorityHakafaReportDialog {
      
      const dialogSelector = "priority-hakafa-report-dialog"
      
      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function initData() {
        return {
          dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
          obligo: {}
        }
      }

      function close() {
        if (this.dialogElem == null) {
          this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
        }

        this.dialogElem.close()
        this.cleanData()
      }

      async function open(customer) {
        try{
          if (this.dialogElem == null) {
            this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
          }

          this.obligo = await Service.Hakafa.getObligoCustomerFromPriority(customer, true)
          this.dialogElem.showModal() 
          this.$nextTick(() => {
            $(document).unbind('keypress')
          })   
        }catch(e){
          console.error(e)
          this.showGenericError()
        }
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      export function create() {

        let priorityHakafaReportDialog = {
            template: JST.priorityHakafaReportDialog(),
            methods: {
              cleanData,
              open,
              close,
              showGenericError: showGenericError,
              invoiceFormat (value) {
                if (typeof value == 'string'){
                  return value.replace(`${session.pos.storeID}-`, '')
                }

                return ''
              },
            },
            computed:{
              getObligoItems () {
                if (this.obligo){
                  return _.orderBy(this.obligo.OBLIGO_FNCITEMS_SUBFORM, 'BALDATE', 'desc')
                }

                return []
              }
            },

            data: initData

        }

        VueApp.component('priority-hakafa-report-dialog', priorityHakafaReportDialog)

      }
    }
  }
}
