module PositiveTS {
export module Service {
export module Delivery {
    export enum DeliveryType 
        {ta = "TA",
        delivery ="Delivery",
        externalDelivery = "ExternalTenbisDelivery",
        externalTA = "ExternalTenbisTA",        
        externalPaiditDelivery = "externalPaiditDelivery",
        externalPaiditTA = "externalPaiditTA",
        externalPaiditSitting = "externalPaiditSitting",
        externalCibus = "externalCibus",
        externalCibusTA = "externalCibusTA",
        externalMishloha = "externalMishloha",
        externalMishlohaTA = "externalMishlohaTA",
        externalWolt = "externalWolt",
        externalWoltTA = "externalWoltTA",
        external = "External"
    };

    export enum ExternalDeliveryService{
        Tenbis = 1,
        Pluxee = 2,
        Paidit = 3,
        Mishloha = 4
    }

    export enum ExternalOrderType {
        SaleItemsFormat = 1,
        FreeTextFormat = 2
    }

    export enum ExternalOrderOrigin {
        Telegram = 1,
        Digitrade = 2,
        CashCow = 3
    }

    export enum ExternalOrderStatus {
        New = 1,
        InProccess = 2,
        Deleted = 3,
        ReadyForShipment = 4
    }

    export enum DifferenceReason {
        Missing = 1,
        Alter = 2
    }

    export enum FailedDeliveryType {
        HakafaCustomer = 1,
        OrderDataNull = 2,
        MissingItems = 3,
        MissingPayments = 4,
        InvalidData = 5,
        DeliveryTypeMissing = 6,
        InterfaceItemMissing  = 7,
        InvalidSum = 8,
        InvalidOrderNumber = 9,
        ChargeType = 10,
        HttpError = 11,
    }

    const DELIVERY_TYPE_TEXT_TRANS_KEYS = {
        [DeliveryType.delivery]: 'delivery.delivery',
        [DeliveryType.external]: 'delivery.deliveryFromExternal',
        [DeliveryType.ta]: 'delivery.TA',
        [DeliveryType.externalDelivery]: 'delivery.ExternalTenbisDelivery',
        [DeliveryType.externalTA]: 'delivery.ExternalTenbisTA',
        [DeliveryType.externalCibus]: 'delivery.ExternalPluxeeDelivery',
        [DeliveryType.externalCibusTA]: 'delivery.ExternalPluxeeTA',
        [DeliveryType.externalPaiditDelivery]: 'delivery.externalPaiditDelivery',
        [DeliveryType.externalPaiditTA]: 'delivery.externalPaiditTA',
        [DeliveryType.externalPaiditSitting]: 'delivery.externalPaiditSitting',
        [DeliveryType.externalMishloha]: 'delivery.externalMishloha',
        [DeliveryType.externalMishlohaTA]: 'delivery.externalMishlohaTA',
        [DeliveryType.externalWolt]: 'delivery.externalWolt',
        [DeliveryType.externalWoltTA]: 'delivery.externalWoltTA',
    }

    export async function getCouriers():Promise<any>{
        let couriers = [];
        let employees = await PositiveTS.Storage.Entity.Employee.fetchByStoreOrByShift(session.pos.storeID);

        couriers = employees.filter(emp => emp.iscourier);

        return couriers;
    }

    export async function getOrdersByCourier(courierID,assignedMinutesTimeframe?){
        let courierSales = [];
        let sales = (await appDB.sales.orderBy('timestamp').reverse()
        .filter(sale => Boolean(sale.isDelivery))
        .toArray()).map(sale => PositiveTS.Storage.Entity.Sale.import(sale));

        for(let sale of sales){
            let jsondata = JSON.parse(sale.jsondata);
            if(jsondata.delivery){
                if((jsondata.delivery.courierID) && (jsondata.delivery.courierID == courierID)){
                    if(assignedMinutesTimeframe){
                        let minutesAgo = moment(Date.now()).subtract(assignedMinutesTimeframe,"minutes");
                        if(moment(jsondata.delivery.assignedCourierTime).isAfter(minutesAgo)){
                            courierSales.push(sale);
                        }
                    }
                    else{
                        courierSales.push(sale);
                    }
                    
                }
            }
        }

        return courierSales;
    }

    export function isExternalDelivery(sale:Storage.Entity.Sale){
        let jsonData = JSON.parse(sale.jsondata)
        if(jsonData.delivery && jsonData.delivery.deliveryType){
            if(isExternalDeliveryFromJsonData(jsonData)){
                    return true;
            }
        }

        return false
    }

    export function isExternalDeliveryFromJsonData(jsonData){
        if (jsonData.delivery && jsonData.delivery.deliveryType) {
            let deliveryType = jsonData.delivery.deliveryType;
            return (deliveryType != Service.Delivery.DeliveryType.ta && deliveryType != Service.Delivery.DeliveryType.delivery)
                || !posUtils.isBlank(jsonData.delivery.serviceData); // For the new deliveries
        }

        return false;
    }

    export function failedDeliveriesLogger(errorMessage,orderNumber,interfaceType:string,errorType,notSendToCustomer?,externalOrderNumber?){
        const existingExternalOrdersErrors = JSON.parse(localStorage.getItem('deliveryErrors')) || {};
        if (!existingExternalOrdersErrors[orderNumber]) {
            const orderErrors = {
              errorTypes: []
            };  
            existingExternalOrdersErrors[orderNumber] = orderErrors;
          }
          
        const orderErrors = existingExternalOrdersErrors[orderNumber];
        if (!orderErrors.errorTypes.includes(errorType) ) {
            let fullErrorMessage = "";
            if (!posUtils.isBlank(orderNumber)){
                fullErrorMessage += orderNumber + " "
            }
            if (!posUtils.isBlank(externalOrderNumber)){
                fullErrorMessage += externalOrderNumber + " "
            }
            if (!posUtils.isBlank(errorMessage)){
                fullErrorMessage += errorMessage
            }
            if(errorType == FailedDeliveryType.HttpError){
                fullErrorMessage = i18next.t('delivery.HttpError', {service: interfaceType})
            }
            PositiveTS.Service.Filelog.log(interfaceType,fullErrorMessage,false,"error")
            const delimiterRegex = /[ -]/;
            if(orderErrors.errorTypes.length == 0 && !notSendToCustomer){
                let failedDeliveryObj = {
                  interfaceType: i18next.t(`delivery.${interfaceType.split(delimiterRegex)[0].toLocaleLowerCase()}`),
                  orderNumber,
                  externalOrderNumber,
                  errorMessage:fullErrorMessage
                };
                Pinia.globalStore.addFaildDelivery(failedDeliveryObj)
                Pinia.globalStore.setDeliveryErrorExists();
            }
            orderErrors.errorTypes.push(errorType)
            localStorage.setItem('deliveryErrors', JSON.stringify(existingExternalOrdersErrors));
        }
    }

    export function clearDevlieryErrorsByOrderNumberAndType(orderNumber ,errorType){
        const existingExternalOrdersErrors = JSON.parse(localStorage.getItem('deliveryErrors')) || {};
        existingExternalOrdersErrors[orderNumber].errorTypes = existingExternalOrdersErrors[orderNumber].errorTypes.filter(type => type != errorType)
        localStorage.setItem('deliveryErrors', JSON.stringify(existingExternalOrdersErrors));

        const existstingDeliveryErrors = Pinia.globalStore.failedDeliveriesOrders;
        Pinia.globalStore.setFaildDeliveries(existstingDeliveryErrors.filter(delivery => delivery.orderNumber != orderNumber))
        Pinia.globalStore.setDeliveryErrorExists(false);
    }




    export async function startDeliveryDBHook(){
        if(jsonConfig.getVal(jsonConfig.KEYS.isDelivery)){
            Pinia.globalStore.setDeliveriesIndicator(Number(localStorage.getItem('deliveriesIndicator')))
            
            appDB.sales.hook("creating", async function (primKey, obj, transaction){
                  if(isExternalDelivery(obj)){
                    if (jsonConfig.getVal(jsonConfig.KEYS.soundNotificationDeliveryAtIncomingDelivery)){
                        PositiveTS.Service.NotificationsAudio.playSound('incomingDelivery')
                    }
                    Pinia.globalStore.setDeliveriesIndicator(Pinia.globalStore.deliveriesIndicator + 1)
                    localStorage.setItem("deliveriesIndicator",String(Pinia.globalStore.deliveriesIndicator))
                  }
            });
        }
    }

    export async function creditDelivery(creditSale){
        let deliveries = await appDB.sales.where('invoiceSequence').equals(Number(creditSale.parentSaleInvoiceSequence)).toArray()
        let delivery = deliveries[0];
        if(delivery){
            delivery.deliveryStatus = Storage.Entity.Sale.CREDITED;
            await appDB.sales.put(delivery);
        } 
    }

    export function filterSalesToGetOrders(sales) {
        return sales.filter(sale =>  {
            let saleJsondata = posUtils.isBlank(sale.jsondata) ? null : JSON.parse(sale.jsondata);
            let isExternalOrder = posUtils.isDefined(saleJsondata) && posUtils.isDefined(saleJsondata.delivery) && saleJsondata.delivery.isExternalOrder;
            let isCurrentActiveSale = sale.invoiceSequence == -1;

            if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                if (posUtils.isBlank(Pinia.dalpaksStore.currentSelectedDalpak)) {
                    isCurrentActiveSale = false;
                } else {
                    isCurrentActiveSale = isCurrentActiveSale && Pinia.dalpaksStore.currentSelectedDalpak.railsId == sale.dalpakId;
                }
            }

            return Boolean(sale.isDelivery) && !isCurrentActiveSale && sale.deliveryStatus != Storage.Entity.Sale.CREDITED && !isExternalOrder;
        }).map(sale => PositiveTS.Storage.Entity.Sale.import(sale))
    }

    export function getDeliveryTypeText(deliveryType): string {
        if (posUtils.isDefined(DELIVERY_TYPE_TEXT_TRANS_KEYS[deliveryType])) {
            return i18next.t(DELIVERY_TYPE_TEXT_TRANS_KEYS[deliveryType]);
        }

        return '';
    }

    export function convertSaleToOrder(sale) {
        let svcCustomer = new PositiveTS.Service.CustomerClub(sale, sale.payments, sale.items);
        let customerName = ""
        let ordererName  = ""
        let saleJsondata = sale.jsondata ? JSON.parse(sale.jsondata) : null;
        
        if (svcCustomer.isCurrentCustomerExists() ) {
          customerName = `${svcCustomer.getCurrentSelectedCustomer(sale).s_first_name} ${svcCustomer.getCurrentSelectedCustomer(sale).s_last_name}`;
        }
        else
        {
          if (saleJsondata && saleJsondata.delivery.ordererName) {
              customerName = saleJsondata.delivery.ordererName
           }
        }

        if (saleJsondata && saleJsondata.delivery.ordererName) {
          ordererName = saleJsondata.delivery.ordererName
        }

        let deliveryType = "";
        let typeText = "";
        if (saleJsondata && saleJsondata.delivery.deliveryType) {
          let isPresto = jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet);
          deliveryType = saleJsondata.delivery.deliveryType;
          typeText = getDeliveryTypeText(deliveryType);
        }
        let statusText = "NO STATUS"
        let status = null;
        if (saleJsondata && saleJsondata.delivery.status) {
          status = saleJsondata.delivery.status;

          switch (status) {
              case Storage.Entity.Sale.OPEN_DELIVERY:
                  statusText = i18next.t('delivery.openDelivery');
                  break;

              case Storage.Entity.Sale.ASSIGNED_DELIVERY:
                  statusText = i18next.t('delivery.closedDelivery');
                  break;

              case Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH:
                  statusText = i18next.t('delivery.assignedDelivery');
                  break;
              
              case Storage.Entity.Sale.TA_PAID:
                  statusText = i18next.t('delivery.taPaid');
                  break;
              case Storage.Entity.Sale.DELIVERED:
                  statusText = i18next.t('delivery.delivered');
                  break;
              case Storage.Entity.Sale.CANCELED_DELIVERY:
                  statusText = i18next.t('delivery.deliveryCancelled');
                  break;
          
              default:
                  break;
          }
        }

        let assignedCourierTime = "";
        if (saleJsondata && saleJsondata.delivery.assignedCourierTime) {
          assignedCourierTime = saleJsondata.delivery.assignedCourierTime;
        }

        let courierName = "";
        if (saleJsondata && saleJsondata.delivery.courierName) {
          courierName = saleJsondata.delivery.courierName;
        }

        let courierID = null;
        if (saleJsondata && saleJsondata.delivery.courierID) {
          courierID = saleJsondata.delivery.courierID;
        }

        let phoneNumber = "";
        if (saleJsondata && saleJsondata.delivery.ordererPhone) {
          phoneNumber = saleJsondata.delivery.ordererPhone;
        }

        let isTa = false;

        if (saleJsondata && isTaOrder(saleJsondata.delivery.deliveryType)) {
            isTa = true;
        }

        let isPickup = false;

        if (saleJsondata && saleJsondata.delivery.isPickup) {
            isPickup = true;
        }

        return {
            customerName: customerName,
            id: sale.id,
            createdAt: sale.orderTime,
            orderNumber: sale.orderNumber,
            courierName: courierName,
            courierID: courierID,
            assignedCourierTime: assignedCourierTime,
            status: status,
            sale: sale,
            statusText: statusText,
            isSelected: false,
            items: sale.items,
            type: typeText,
            phoneNumber: phoneNumber,
            address: getShortDeleveryCustomerAddress(sale.jsondata),
            totalAmount: sale.totalAmount,
            ordererName: ordererName,
            isTa: isTa,
            isPickup: isPickup,
          };
    }
    

    export async function getAllOrders():Promise<any>{
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {

            let isDalpakDataValid = await Dalpak.isDalpaksDataValid()
            if (!isDalpakDataValid.success) {
                throw new Error('Cant get dalpaks orders')
            }
            
            await Service.Dalpak.refreshDalpaksVuexIfNeeded();
            Pinia.dalpaksStore.activateDalpaksOrderGetters()
            let result = Pinia.dalpaksStore.dalpaksOrders;
            Pinia.dalpaksStore.deactivateDalpaksOrderGetters()
            
            return result;
        }

        let sales = filterSalesToGetOrders(await appDB.sales.orderBy('timestamp').reverse().toArray());
        return sales.map(sale => convertSaleToOrder(sale));
    }

    export function isDelivered(sale) {
        return sale.deliveryStatus == PositiveTS.Storage.Entity.Sale.DELIVERED || sale.deliveryStatus == PositiveTS.Storage.Entity.Sale.TA_PAID
    }
    
    export function isDeliveryCancelled(sale) {
        return sale.deliveryStatus == PositiveTS.Storage.Entity.Sale.CANCELED_DELIVERY;
    }

    export function isFurtherActionNeeded(sale) {
        return sale.deliveryStatus == PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
    }

    export async function addSaleItems(sale){


            var saleProxy = new PositiveTS.Service.SaleProxy({type:SaleProxy.REMOTE_DATA_TYPE
                ,saleID:sale.id,remote_sale: sale
                ,store_id: sale.store_id,
                pos_device_id: sale.device_id
                ,invoice_type:sale.invoice_type, invoice_number: sale.invoice_number});
        
        
                let loadedSales = await saleProxy.loadAllLevels();
                let loadedSale = loadedSales[0]

                appDB.transaction("rw",appDB.sales, async ()=>{
                    await appDB.sales.where('invoiceSequence').equals(-1).delete()

                    let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
                    let cust = svcCustomer.getCurrentSelectedCustomer() || {}

                    let jd = JSON.parse(loadedSale.jsondata);
                    jd.customer = cust;

                    loadedSale.jsondata = JSON.stringify(jd);

                    loadedSale.invoiceSequence = -1;
                    loadedSale.posDeviceID = posVC.sale.posDeviceID;
                    loadedSale.posNumber = posVC.sale.posNumber;
                    loadedSale.storeID = posVC.sale.storeID;
                    loadedSale.posPhysicalID = posVC.sale.posPhysicalID;
                    loadedSale.storeName = posVC.sale.storeName;
                    loadedSale.storePhone = posVC.sale.storePhone;
                    loadedSale.storeFreeText = posVC.sale.storeFreeText;
                    loadedSale.zNumber = Storage.Entity.Sale.NULL_Z_REPORT;
                    loadedSale.payments = [];
                    loadedSale.orderNumber = localStorage.orderNumber;
                    setDeliveryType(JSON.parse(posVC.sale.jsondata).delivery.deliveryType,loadedSale);

                    for (let index = 0; index < loadedSale.items.length; index++) {
                        let saleItem = loadedSale.items[index];
                        if((saleItem.item) && (saleItem.itemCode != Shared.Constants.Item.GENERIC_ITEM) 
                        && (saleItem.itemCode != jsonConfig.getVal(jsonConfig.KEYS.taItemCode))
                        && (saleItem.itemCode != jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)))
                        {
                            if(saleItem.isChild == false){
                                saleItem.unitPrice = saleItem.item.priceZarhan;
                              
                                let children = loadedSale.items.filter(item => item.parentRowNumber == saleItem.rowNumber);
                                for(let child of children){
                                    if(child.unitPrice == child.originalUnitPrice){
                                        child.unitPrice = child.item.priceZarhan;
                                    }
                                    let grandChildren = loadedSale.items.filter(item => item.parentRowNumber == child.rowNumber);
                                    for(let grandChild of grandChildren){
                                        if(grandChild.unitPrice == grandChild.originalUnitPrice){
                                            grandChild.unitPrice = grandChild.item.priceZarhan;
                                        }
                                        child.unitPrice+=grandChild.unitPrice
                                    }
                                    saleItem.unitPrice+=child.unitPrice;
                                }

                            }
                        }
                        else{
                            loadedSale.items.splice(index,1);
                        }
                    }

                    let deliveryTAItem = posVC.saleItems[0];
                    deliveryTAItem.saleID = loadedSale.id;
                    loadedSale.items.push(deliveryTAItem); //Addding the delivery/TA item
                                        
                    FullSale.persist(loadedSale,loadedSale.items,loadedSale.payments);
                }).then( () => {
                        posVC.loadSale();
                    }
                )
                .catch(err => {
                    console.error("Could not load sale, error: " + err);
                })
    }


    export function getDeliveryCustomerAddresses(customerId){
        if (!customerId){return Promise.resolve([])}

        return PositiveTS.Service.FetchReq.jsonReq(`/customer_addresses/${customerId}`,"get")
        .then( ret=>{
            return ret.result; 
        })
    }

    export function getDeliveryCustomerCompensation(customerId){
        if (!customerId){return Promise.resolve([])}

        return PositiveTS.Service.FetchReq.jsonReq(`/delivery_customer_club/${customerId}/customer_compensation`,"get")
        .then( ret=>{
            return ret.result; 
        })
    }

    export function setDeliveryCustomerCompensation(customerId,reason,compensation){
        if (!customerId){return Promise.resolve([])}

        return PositiveTS.Service.FetchReq.jsonReq(`/delivery_customer_club/${customerId}/update_customer_compensation`,"post"
        ,{reason: reason, compensation: compensation})
        .then( ret=>{
            return ret.result; 
        })
    }


    async function _addSaleItem(itemCode, quantity){


        let itemsAndBarcodes = await Storage.Entity.Item.searchByCode(itemCode);
    
          if (itemsAndBarcodes.length !== 1){
            //TODO: Add a nice message to inform cashier
            console.log("Item " + itemCode + " is not available anymore");
          }
          else{
            var item:PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
            var itemBarcode = itemsAndBarcodes[0].itemBarcode;
        
            var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromObject(item);
            saleItem.saleID = posVC.sale.id;
            saleItem.rowNumber = posVC.getRowNumber();
            saleItem.quantity = quantity;
            //Update price

            await posVC.persistNewSaleItem(saleItem);
          }
        }

    export function isItemValid(saleItem:Storage.Entity.SaleItem, saleItems:Storage.Entity.SaleItem[]):boolean {

        let existedDeliveryItems = saleItems.filter( (row)=>{ return row.isDelivery && row.id !== saleItem.id } )
        if (existedDeliveryItems.length > 0 && saleItem.isDelivery) {return false;}
 
		return true;
    }

    export async function promptDeliveryDialogs(sale:Storage.Entity.Sale, saleItem:Storage.Entity.SaleItem){
        try{
            var deliveryType:DeliveryType;
            let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
        
            if (saleItem.itemCode === jsonConfig.getVal(jsonConfig.KEYS.taItemCode)){
                let userSelectedType = await  _promptDeliveryType();
                if (userSelectedType == DeliveryType.ta) {
                    try {
                        let isRestaurantNewPOS =  jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)
                        if(isRestaurantNewPOS){
                            let askForPhone = true;
                            Pinia.globalStore.migvanUpdated(session.pos.migvanTaId || 0);
                            
                            let res = await Pinia.componentsStore.openComponent( {componentName:"dedicatedToDialog",args:[askForPhone]})
                            if(!res){
                                throw new Error(i18next.t("pos.dedicated.errors.userCanceled"));
                            }
                        }else{
                            await Service.DedicatedTo.setDedicatedTo(undefined,undefined,undefined,true,false)
                        }
                    } catch (error) {
                        console.error(error)
                        posVC.restartSale();
                        throw new Error("TA בוטל");
                    } 
                } else{
                    try{
                        await Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryCustomerClub", args: []});
                        let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
                        if( !svcCustomer.isCurrentCustomerExists(undefined, "positive")){
                            await posVC.restartSale();
                            throw new Error("לא נבחר לקוח"); 
                        }
                    } catch (error) {
                        throw new Error("לא נבחר לקוח")
                    }
                }

                sale.deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
                sale.deliverySyncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_NEW;
                await FullSale.persist(sale,[],[]);
                deliveryType = DeliveryType.ta;
            } 
        
            if ( saleItem.itemCode === jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)){
                try {
                    await Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryCustomerClub", args: []});
                    if( !svcCustomer.isCurrentCustomerExists(undefined, "positive")){
                        await posVC.restartSale();
                        throw new Error("לא נבחר לקוח");
                    }

                    deliveryType = DeliveryType.delivery;
                } catch (error) {
                    throw new Error("לא נבחר לקוח");
                }
            }

            await setDeliveryType(deliveryType)
            await changeMigvanIfNeeded();
        } catch (error) {
            console.error(error)
        }
    }

    export async function changeMigvanIfNeeded(){
        if (session.pos.isRoshemet || Service.MultipleMigvans.isActive()) {
            return;
        }
        if ((posVC.sale.isDelivery != null) && (posVC.sale.isDelivery != false)){            
            if (JSON.parse(posVC.sale.jsondata).delivery.deliveryType == "TA"){
                Pinia.globalStore.migvanUpdated(session.pos.migvanTaId || 0);
            } else {
                Pinia.globalStore.migvanUpdated(session.pos.migvanDeliveryId || 0);
                 
            }
        }
        else{
            Pinia.globalStore.migvanUpdated(session.pos.migvanId || 0);
        }    
    }


    export function setDeliveryType(deliveryType:DeliveryType,existingSale?):Promise<any>{
        //for some reason the byRef pass was incorrect
        
        var sale = posVC.sale;

        if(existingSale){
            sale = existingSale;
        }

        var jsondata = JSON.parse( sale.jsondata );
        sale.isDelivery = true; 
        Pinia.globalStore.setIsDeliverySale(true);
        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.deliveryType = deliveryType;

        if (!posUtils.isBlank(jsondata.dedicatedTo)) {
            jsondata.delivery.ordererName = jsondata.dedicatedTo;
        }

        if (!posUtils.isBlank(jsondata.dedicatedPhone)) {
            jsondata.delivery.ordererPhone = jsondata.dedicatedPhone;
        }

        if(deliveryType == "TA"){
            jsondata.delivery.orderTime = new Date();
            sale.orderTime = jsondata.delivery.orderTime
            jsondata.delivery.status = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
        }

        sale.jsondata = JSON.stringify(jsondata);
        return  sale.persist();
    }

    export function setDeliveryCustomerAddress( deliveryAddressObj ):void{
        var sale = posVC.sale;

        var jsondata = JSON.parse( sale.jsondata );
        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.deliveryAddress = deliveryAddressObj;
        sale.jsondata = JSON.stringify(jsondata)    
        
        PositiveTS.Service.FullSale.persist(posVC.sale,posVC.sale.items || [],posVC.sale.payments || []);

    }

    export async function setCourier(order, courierID, courierName)
    {
        var sale = order.sale;
        let status = PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY

        for(let payment of sale.payments){
            if(payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CASH){
                status = PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH
            }
        }

        sale.deliveryStatus = status;
        sale.deliverySyncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;

        order.courierID = courierID;
        order.status = status;
        var jsondata = JSON.parse( sale.jsondata );
        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.courierID = courierID;
        jsondata.delivery.assignedCourierTime = Date.now();
        jsondata.delivery.courierName = courierName;
        jsondata.delivery.status = status;

        sale.jsondata = JSON.stringify(jsondata) 
        
        return await saveOrderSaleUpdates(sale);
    }

    async function saveOrderSaleUpdates(sale):Promise<boolean> {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
            return await Service.DalpakSharedOrders.saveOrderSaleUpdates(sale)
        } else {
            await PositiveTS.Service.FullSale.persist(sale,sale.items,sale.payments);
            return true;
        }
    }

    export async function deliverOrder(order){
        let sale = order.sale;

        let status = Storage.Entity.Sale.DELIVERED;

        var jsondata = JSON.parse( sale.jsondata );

        order.status = status;

        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.status = status;
        sale.deliveryStatus = status;
        sale.deliverySyncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;

        sale.jsondata = JSON.stringify(jsondata) 
        await saveOrderSaleUpdates(sale);
    }

    export async function cancelDeliverOrder(order){
        let sale = order.sale;

        let status = Storage.Entity.Sale.CANCELED_DELIVERY;

        var jsondata = JSON.parse( sale.jsondata );

        order.status = status;

        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.status = status;
        sale.deliveryStatus = status;
        sale.deliverySyncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;

        sale.jsondata = JSON.stringify(jsondata) 
        await saveOrderSaleUpdates(sale);
    }

    export async function closeTASale(sale,saleItems,salePayments){
        let status = Storage.Entity.Sale.TA_PAID;

        sale.deliveryStatus = status;
        
        var jsondata = JSON.parse( sale.jsondata );
        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.status = status;
        sale.jsondata = JSON.stringify(jsondata) 
        await PositiveTS.Service.FullSale.persist(sale,saleItems,salePayments);

    }

    export async function closeOrder(order){
        var sale = order.sale;
        let status = Storage.Entity.Sale.DELIVERED;

        var jsondata = JSON.parse( sale.jsondata );

        order.status = status;


        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.status = status;
        sale.deliveryStatus = status;
        sale.deliverySyncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;

        sale.jsondata = JSON.stringify(jsondata) 

        return await saveOrderSaleUpdates(sale);
    }

    export async function closeAllOrders(){
        let openDeliveryOrders = await PositiveTS.Service.Delivery.getAllOrders();
        openDeliveryOrders = openDeliveryOrders.filter(order => order.status == PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY
        || order.status == PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH || order.status == PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY );
        
        let isSuccess = true;

        for(let order of openDeliveryOrders){
            isSuccess = isSuccess && (await closeOrder(order));
        }

        return isSuccess;
    }

    export async function deliveryCashRightNow(){
        let openDeliveryOrders = await PositiveTS.Service.Delivery.getAllOrders();
		let openCashOrders = openDeliveryOrders.filter(order => order.status == PositiveTS.Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH);
        let missingCash = 0;
		if(openCashOrders.length > 0){
			for(let order of openCashOrders){
				missingCash += getCashFromSale(order.sale);
			}
        }
        
        return missingCash;
    }

    export function getPaymentsButCashFromSale(sale){
        let payments = 0;
        for(let payment of sale.payments){
            if((payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CASH)
            && (payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE)){
                payments+=payment.amount;
            }
        }

        let jsondata = JSON.parse(sale.jsondata);
        if(jsondata.splitSaleNumber && posUtils.isNumeric(jsondata.splitSaleAmount)){
            payments +=  jsondata.splitSaleAmount;
        }
  
        return payments;
    }

    export function getOrderTotalAmount(order) {
        let sale = order.sale;
        let saleTotalAmount = sale.totalAmount;
        let jd = JSON.parse(sale.jsondata);

        if(posUtils.isDefined(jd.splitSaleNumber)&& posUtils.isNumeric(jd.splitSaleAmount)){
            saleTotalAmount +=  jd.splitSaleAmount;
        }

        return saleTotalAmount;
    }

    export function getCashFromSale(sale){
        let cashPayments = 0;
        let change = 0;
        if((sale.isDelivery == false) || (JSON.parse(sale.jsondata).delivery.deliveryType != DeliveryType.ta)){
            for(let payment of sale.payments){
            if(payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CASH){
                cashPayments+=payment.amount;
            }
            if(payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE){
                change+=payment.amount;
            }
            }
        }

        return cashPayments - change;
      }

    export async function unassignCourier(order)
    {
        var sale = order.sale;
        order.status = Storage.Entity.Sale.OPEN_DELIVERY
        order.courierID = null;
        order.courierName = null;
        
        var jsondata = JSON.parse( sale.jsondata );
        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.courierID = null;
        jsondata.delivery.courierName = "";
        jsondata.delivery.status = Storage.Entity.Sale.OPEN_DELIVERY
        sale.deliveryStatus = Storage.Entity.Sale.OPEN_DELIVERY
        sale.deliverySyncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT

        sale.jsondata = JSON.stringify(jsondata) 
        return await saveOrderSaleUpdates(sale);
    }

    export function getShortDeleveryCustomerAddress(saleJsondata:string):string{
        var ret = ""
        var jsondata = JSON.parse( saleJsondata );
        if(jsondata.delivery.deliveryType && jsondata.delivery.deliveryType != Delivery.DeliveryType.externalDelivery && jsondata.delivery.deliveryType != Delivery.DeliveryType.delivery && 
            jsondata.delivery.deliveryType != Delivery.DeliveryType.externalPaiditDelivery && jsondata.delivery.deliveryType != Delivery.DeliveryType.externalMishloha ){
            return ret;
        }

        if (jsondata.delivery && jsondata.delivery.deliveryAddress) {
            let addr = jsondata.delivery.deliveryAddress;
            let acceptableTypes = (val) => val === null || ["number","string","undefined"].some(t => t === typeof(val));
            if (acceptableTypes(addr.city) && acceptableTypes(addr.address) && acceptableTypes(addr.house_number)) {
                ret = `${addr.city || ''} ${addr.address || ''} ${addr.house_number || ''}`;
            } else if(acceptableTypes(addr.city?.name) && acceptableTypes(addr.address?.name) && acceptableTypes(addr.house_number)) {
                ret = `${addr.city?.name || ''} ${addr.address?.name || ''} ${addr.house_number || ''}`;
            }
        }
        return ret;
    }

    export function getCurrentSelectedAddress(saleJsondata:string):string{
        var ret = ""
        var jsondata = JSON.parse( saleJsondata );
        if ( jsondata.delivery && jsondata.delivery.deliveryAddress ){
            ret = jsondata.delivery.deliveryAddress
        }
        return ret;
    }

    function _promptDeliveryType():Promise<DeliveryType>{
        return app.promiseShowAlert({
            header: "",
            content: "לקוח קיים או לקוח מזדמן",
            continueButtonText: "לקוח קיים",
            cancelButtonText: "לקוח מזדמן",
            hideCancelButton: false,
            noHandleEnterEscape: true,
        }).then(response =>{
            if (response ==="cancel"){
                return DeliveryType.ta
            }
            return DeliveryType.delivery
        })

    }
    let splitSalesCount = 0;
    export async function calculateZ(sales){
        splitSalesCount = 0;
        let count = 0,sum = 0, refundSalesCount = 0;
        let salesToCalculate = [];

        for(let sale of sales){
            let deliveryParent = await isParentDeliveryAndNotTA(sale);
            let splitDeliverySale = await isSplitFromDeliveryAndNotTA(sale);
            if((sale.isDelivery) || (deliveryParent) || (splitDeliverySale)){
                let jd = JSON.parse(sale.jsondata);
                if((!jd.delivery) || (jd.delivery.deliveryType != DeliveryType.ta)){
                    sum+=sale.totalAmount;  
                    salesToCalculate.push(sale);
                    if(sale.totalAmount < 0){
                        refundSalesCount++;
                    }else{
                        count++;
                    }
                }
            }
        }

        return {count: count-splitSalesCount-refundSalesCount, sum: sum}
    } 

    async function isSplitFromDeliveryAndNotTA(sale){
        let jd = JSON.parse(sale.jsondata);
        if(jd.splitSaleDelivery){
            if(jd.splitSaleDelivery.deliveryType != DeliveryType.ta){
                splitSalesCount++;
                return true;
            }
        }

        return false;
    }

    async function isParentDeliveryAndNotTA(sale){

        if(sale.parentSaleDelivery){
            let jd = JSON.parse(sale.parentSaleDelivery);
            if(jd.deliveryType != DeliveryType.ta){
                return true;
            }
        }
        else
        {
            return isSplitFromDeliveryAndNotTA(sale);
        }
        return false;
    }

    export async function getAllPosSalesIncludingDalpakim(){
        let localSales = await appDB.sales.where('invoiceSequence').above(-1).toArray()
        let dalpakimSales = []

        if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)){
            app.showLoadingMessage(i18next.t('dalpaks.loadingDalpaks'));
            let result = await Service.Dalpak.isDalpaksDataValid();
            app.hideLoadingMessage();

            if (!result.success) {
                throw new Error('Error getting dalpaks');
            } 

            dalpakimSales = Pinia.dalpaksStore.dalpaksSales;
        }

        let allsales = _.unionBy(dalpakimSales,localSales,'id') //this function prefers the dalpakim sale in case of duplicates since dalpakimSales is the first parameter

        return allsales

    }

    export async function calculateX(isStoreXReport:boolean, salesToCalculate:any[]){
        splitSalesCount = 0;
        let count = 0,sum = 0,cashOutside = 0,canceledBons = 0, refundSalesCount = 0, totalDeliveriesCash = 0

        if(isStoreXReport){
            for(let sale of salesToCalculate){
                if(sale.isDelivery){
                    let jd = JSON.parse(sale.jsondata);
                    if(jd.delivery.deliveryType != DeliveryType.ta){
                        let deliveryCash = getCashFromSale(sale)

                        if((sale.deliveryStatus == Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH) || 
                        (sale.deliveryStatus == Storage.Entity.Sale.OPEN_DELIVERY 
                            || sale.deliveryStatus == Storage.Entity.Sale.CREDITED)){
                            cashOutside+=deliveryCash
                        }
                         
                        totalDeliveriesCash+=deliveryCash
                        

                        sum+=sale.totalAmount;
                        count++;
                        if(sale.totalAmount < 0){
                            refundSalesCount++;
                        }
                        
                    }
                }
                else if(await isParentDeliveryAndNotTA(sale)){
                    sum+=sale.totalAmount;
                    count++;
                    if(!await isSplitFromDeliveryAndNotTA(sale)){  //Case of invoice credit
                        cashOutside+=getCashFromSale(sale);
                    }
                }
                else if(await isSplitFromDeliveryAndNotTA(sale)){
                    sum+=sale.totalAmount;
                    count++;
                }
            }
            count = count - splitSalesCount - refundSalesCount;
        }
        else{
            for(let sale of salesToCalculate){
                let deliveryCash = getCashFromSale(sale)

                if(sale.isDelivery){
                    totalDeliveriesCash+=deliveryCash
                    if((sale.deliveryStatus == Storage.Entity.Sale.ASSIGNED_DELIVERY_CASH) || 
                    (sale.deliveryStatus == Storage.Entity.Sale.OPEN_DELIVERY) ||
                    (sale.deliveryStatus == Storage.Entity.Sale.CREDITED)){
                        cashOutside+=deliveryCash
                    }
                    
                }
                else if((await isParentDeliveryAndNotTA(sale)) && (!await isSplitFromDeliveryAndNotTA(sale))){  //Case of invoice credit
                    totalDeliveriesCash+=deliveryCash

                    cashOutside+=deliveryCash
                }
            }

            let totals = await calculateZ(salesToCalculate);
            count = totals.count;
            sum = totals.sum;
        }

        canceledBons = HoldSale.getBonTotalSumAndQuantityCanceled().totalQuantity;
        
        return {count: count, cashOutside: cashOutside, sum: sum, canceledBons: canceledBons, totalDeliveriesCash: totalDeliveriesCash}
    }
    
    export function isTaOrder(deliveryType) {
        let taTypes = [Service.Delivery.DeliveryType.ta,
                       Service.Delivery.DeliveryType.externalTA,
                       Service.Delivery.DeliveryType.externalCibusTA,
                       Service.Delivery.DeliveryType.externalPaiditTA,
                       Service.Delivery.DeliveryType.externalWoltTA,
                       Service.Delivery.DeliveryType.externalMishlohaTA];

        return taTypes.includes(deliveryType)
    } 
    
    export function isPickupSale(sale) {
        if (posUtils.isBlank(sale.jsondata)) {
            return false;
        }

        let jd = JSON.parse(sale.jsondata);

        return posUtils.isDefined(jd.delivery) && jd.delivery.isPickup == true;
    }

    export function isPartiallyFilledOrder(order) {
        return order.sale.invoiceSequence < 0;
    }

    export async function clearDeliveryAndCustomerFromCurrentSale () {
        posVC.sale.isDelivery = false
        let jsonDataDict = posUtils.isBlank(posVC.sale.jsondata) ? {} : JSON.parse(posVC.sale.jsondata)
        delete jsonDataDict.delivery
        delete jsonDataDict.customer

        posVC.sale.jsondata = JSON.stringify(jsonDataDict)
        await PositiveTS.Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments)
    }
}}}
