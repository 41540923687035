module PositiveTS {
  export module Components {
      export module PackageDialog {
        const dialogSelector = "package-dialog"
  
        function cleanData() {
          let result = initData();
          for (let prop in result) {
            this[prop] = result[prop];
          }
        }
  
        function initData() {
          return {
            initialized: this.initialized,
            searchInput: "",
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            noCustomersFound: false,
            itemPackages: [],
            weight: 0,
            arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
            dialogElem: document.getElementById(dialogSelector),
            selectedPackagesById: {},
            saleItem: null,
          }
        }
  
        function close() {
          if (this.initialized) {
            if (this.dialogElem == null) {
              this.dialogElem = document.getElementById(dialogSelector)
            }
            this.dialogElem.close();
          }
          this.cleanData()
          $(document).unbind('keypress');
          $(document).keypress(posVC.onKeyPress);
        }
  
        function open( rows = [], saleItem ) {
  
          this.itemPackages = rows;
          this.saleItem = saleItem;
          this.selectedPackagesById = posUtils.isBlank(saleItem.selectedPackagesById) ? {} : JSON.parse(saleItem.selectedPackagesById);
  
          return new Promise((resolve,reject) => {
            this.initialized = true;
           
            $(document).unbind('keypress');
  
            if (this.dialogElem == null) {
              this.dialogElem = document.getElementById(dialogSelector)
            }
            this.dialogElem.showModal();
            this.resolveFunc = resolve;
            this.rejectFunc = reject;
          })
        }
  
        function showGenericError() {
          app.showAlert({
            header: i18next.t("error"),
            content: "שגיאה כללית",
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          },
          null,null)
        }
  
        function showError(errStr) {
          let aThis = this;
          app.showAlert({
            header: i18next.t("error"),
            content: errStr,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          },null,null)
        }
  
        function confirmAndClose(weight) {
  
          this.resolveFunc({approved:true, weight: Number(weight), selectedPackagesById: this.selectedPackagesById})
          this.close();
          
        }
  
        function noPackage(){
          this.resolveFunc({approved:true, weight: 0, selectedPackagesById: {}})
          this.close();
          
        }
  
        function cancel() {
          this.resolveFunc({approved:false, weight: 0, selectedPackagesById: {}})
          this.close();
        }
  
        export function create() {
  
          let component = {
            template: JST.packageDialog(),
            methods: {
              cleanData: cleanData,
              open: open,
              cancel: cancel,
              close: close,
              confirmAndClose: confirmAndClose,
              noPackage: noPackage,
              showError: showError,
              showGenericError: showGenericError,
              isMultiplePackages() {
                return jsonConfig.getVal(jsonConfig.KEYS.allowMultipleItemPackages);
              },
              selectPackage(selectedPackage) {
                if (this.isMultiplePackages()) {
                  if (this.totalSelectedWeight + parseInt(selectedPackage.weight) > this.saleItem.quantity * 1000) {
                    app.showAlertDialog({
                      header: i18next.t('error'),
                      content: i18next.t('packageDialog.tooMuchPackage'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true,
                    })
                    return;
                  }

                  this.selectedPackagesById[selectedPackage.id] = (this.selectedPackagesById[selectedPackage.id] || 0) + 1
                } else {
                  this.confirmAndClose(parseInt(selectedPackage.weight))
                }
              },
              confirmMultiPackagesAndClose() {
                this.confirmAndClose(this.totalSelectedWeight);
              },
              clearSelection() {
                this.selectedPackagesById = {};
              }
            },
            computed: {
              itemPackagesById() {
                return this.itemPackages ? _.keyBy(this.itemPackages, 'id') : {};
              },
              totalSelectedWeight() {
                let total = 0;

                for (let packageId in (this.selectedPackagesById || {})) {
                  total += this.itemPackagesById[packageId] ? parseInt(this.itemPackagesById[packageId].weight) * this.selectedPackagesById[packageId] : 0;
                }

                return total;
              }
            },
            data: initData,
          }
  
          VueApp.component('package-dialog',component)
  
        }
      }
    }
  }
  