module PositiveTS {
export module Storage {
export module Entity {
export class ItemUpsale extends WasmEntity {

	constructor() {
		let meta = {
			name: 'ItemUpsale',
			fields: {
				upsaleId: "INT",
				itemCode: "TEXT",
				price: "FLOAT",
			},
		};
		super(meta)
	}


	static addItemsToUpsales(upsales) {
		let upsalesIds = upsales.map(u => u.upsaleId);
		let query = `SELECT * FROM ItemUpsale
					 WHERE upsaleId IN (${upsalesIds.join(',')})`;


		let itemUpsales = this.execAndConvertResult(query);
		let itemUpsalesToSave = [];

		for (let itemUpsale of itemUpsales) {
			let item = session.allItems.get(itemUpsale.itemCode);

			if (item) {
				itemUpsale.item = item;
				itemUpsalesToSave.push(itemUpsale);
			}
		}

		let itemsByUpsale = _.groupBy(itemUpsalesToSave, 'upsaleId');


		for (let upsale of upsales) {
			upsale.itemUpsales = itemsByUpsale[upsale.upsaleId] || [];
		}

		return upsales;
	}
}}}}
