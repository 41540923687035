module PositiveTS {
  export module Service { 
    export module CreditInvoiceUtils {
      export function calculateTotalsWithAndWithoutPromotionsAndDiscounts(sale:Storage.Entity.Sale, saleItems:Array<Storage.Entity.SaleItem>) {
        var totalAmount = 0;
        var totalQuantity = 0;
        var totalQuantityWithoutItemsWithDiscount = 0;
        var totalAmountWithoutItemsWithDiscount = 0;
        var totalAmountOfItemsIncludedInSaleDiscount = 0;
        var itemPrice;

        for (var i = 0; i < saleItems.length; i++) {
          itemPrice = saleItems[i].unitPrice * saleItems[i].quantity;
          if (saleItems[i].discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL ||
              salePromotions.itemHasPromotions(saleItems[i],sale,saleItems))
          {
            var itemDiscount = saleItemHelper.getSaleItemDiscountAmount(saleItems[i]);
            itemPrice -= itemDiscount;
            totalAmount += itemPrice;
          } else {
            totalAmountWithoutItemsWithDiscount += itemPrice;
            totalQuantityWithoutItemsWithDiscount += saleItems[i].quantity;
          }
          if (saleItemIncludedInSalePromotion(sale,saleItems[i], saleItems)) {
            totalAmountOfItemsIncludedInSaleDiscount += itemPrice;
          }
          totalQuantity += saleItems[i].quantity;
        }

        totalAmount += totalAmountWithoutItemsWithDiscount;

        return {
          totalAmount: totalAmount,
          totalQuantity: totalQuantity,
          totalAmountWithoutItemsWithDiscount: totalAmountWithoutItemsWithDiscount,
          totalQuantityWithoutItemsWithDiscount: totalQuantityWithoutItemsWithDiscount,
          totalAmountOfItemsIncludedInSaleDiscount: totalAmountOfItemsIncludedInSaleDiscount
        };
      }

      function saleItemIncludedInSalePromotion(sale:Storage.Entity.Sale, saleItem:Storage.Entity.SaleItem, saleItems:Array<Storage.Entity.SaleItem>) {
        var jd = JSON.parse(sale.jsondata);
        var allowWithOtherPromotions = Boolean(sale.saleDiscountAllowedWithOtherPromotions);
        if ((salePromotions.itemHasPromotions(saleItem, sale, saleItems) || saleItemHelper.doesItemContainDiscountOrPromotion(saleItem))
          && !allowWithOtherPromotions) {
          return false;
      }

      if (jd.salePromotion) {
        return (jd.salePromotion.barcodesInPromotion.indexOf(saleItem.barcode) > -1);
      }
      else {
        return true;
      }
    }
    export function calculateItemPriceAfterAllDiscounts(parentSale, saleItem:Storage.Entity.SaleItem) {
      let results = calculateTotalsWithAndWithoutPromotionsAndDiscounts(parentSale, parentSale.items);

      if (saleItem.quantity == 0) {
        return 0;
      }
      let jd = JSON.parse(parentSale.jsondata);
      if (jd.useNewPromotions) {
        if(saleItem.originalQuantity != null){
          return saleItem.priceNetoAfterDiscounts/saleItem.originalQuantity;
        }else{
          return saleItem.priceNetoAfterDiscounts/saleItem.quantity;
        }
      }
      if (saleItem.noDiscount) {
        return saleItem.unitPrice;
      }
      if (salePromotions.saleHasNewPromotions(parentSale) &&
        salePromotions.itemHasPromotions(saleItem, parentSale, parentSale.items)) {
        var price = salePromotions.calcuateItemPriceAfterPromotions(saleItem, parentSale, parentSale.items);

      if (PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(parentSale)) {
        if (Boolean(parentSale.saleDiscountAllowedWithOtherPromotions) && (saleItemIncludedInSalePromotion(parentSale, saleItem, parentSale.items))) {
          price = price * (1 - parentSale.saleDiscountAmount/results.totalAmountOfItemsIncludedInSaleDiscount);
        }
      }
      else {
        if (Helper.SaleHelper.doesSaleHasDiscount(parentSale) && Boolean(parentSale.saleDiscountAllowedWithOtherPromotions)) {
          price = price * (1 - Number(parentSale.discountPercent)/100)
        }
      }
      return price;
    }
    else {

      var price = saleItem.unitPrice;
      let saleItemCopy = saleItem;

      if (!posUtils.isNullOrUndefinedOrEmptyString(saleItem.originalQuantity)) { //this can happen in item returns in debit invoice
        saleItemCopy = new PositiveTS.Storage.Entity.SaleItem();
        saleItemCopy.importFromObject(saleItem.exportToObject());
        saleItemCopy.quantity = saleItem.originalQuantity;
      }

      var discountAmount = saleItemHelper.getSaleItemDiscountAmount(saleItemCopy, 1);
      var allowWithOtherPromotions = Boolean(parentSale.saleDiscountAllowedWithOtherPromotions);

      price -= discountAmount;

      if (PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(parentSale) && saleItemIncludedInSalePromotion(parentSale, saleItem, parentSale.items)) {
        if (allowWithOtherPromotions || discountAmount === 0) {
          price = price * (1 - parentSale.saleDiscountAmount/results.totalAmountOfItemsIncludedInSaleDiscount);
        }
      }
      else if (PositiveTS.Helper.SaleHelper.doesSaleHasDiscount(parentSale) && discountAmount == 0) {
        price -= price * Number(parentSale.discountPercent) / 100;
      }

      return price;
    }
  }

  export function createCreditSaleItem(saleItem, quantity, originalSale) {
    // Create new negetive saleItme objects per original sale items
    var creditSaleItem = new PositiveTS.Storage.Entity.SaleItem();

    creditSaleItem.rowNumber                    = saleItem.rowNumber;
    creditSaleItem.barcode                      = saleItem.barcode;
    creditSaleItem.itemCode                     = saleItem.itemCode;
    creditSaleItem.itemDescription              = saleItem.itemDescription;
    creditSaleItem.size                         = saleItem.size;
    creditSaleItem.color                        = saleItem.color;
    creditSaleItem.quantity                     = -quantity;
    creditSaleItem.unitPrice                    = saleItem.unitPrice;
    creditSaleItem.originalUnitPrice            = saleItem.originalUnitPrice;
    creditSaleItem.isPresent                    = saleItem.isPresent;
    creditSaleItem.isPickup                     = false;
    creditSaleItem.addTimestamp                 = PositiveTS.DateUtils.fullFormat();
    creditSaleItem.discountID                   = '-1';//saleItem.discountID;
    creditSaleItem.discountName                 = "";//saleItem.discountName;
    creditSaleItem.discountPercent              = 0;//saleItem.discountPercent;
    creditSaleItem.discountAmount               = 0; // calcuate later
    creditSaleItem.discountType                 = null;//saleItem.discountType;
    creditSaleItem.buyPromotionCode             = saleItem.buyPromotionCode;
    creditSaleItem.getPromotionCode             = saleItem.getPromotionCode;
    creditSaleItem.discountApprovedByEmployeeID   = saleItem.discountApprovedByEmployeeID;
    creditSaleItem.salespersonEmployeeID        = saleItem.salespersonEmployeeID;
    creditSaleItem.salespersonEmployeeName      = saleItem.salespersonEmployeeName;
    creditSaleItem.item                         = saleItem.item;
    creditSaleItem.originalItemId               = saleItem.originalItemId;
    creditSaleItem.noVat                        = saleItem.noVat;
    creditSaleItem.alwaysHasVat                        = saleItem.alwaysHasVat;
    creditSaleItem.priceAlut                        = saleItem.priceAlut;
    creditSaleItem.priceListId                  = saleItem.priceListId;
    creditSaleItem.hasWeight                    = saleItem.hasWeight;
    creditSaleItem.isAbstract                   = saleItem.isAbstract;
    creditSaleItem.isAddition                   = saleItem.isAddition;
    creditSaleItem.bonPrintedAt                 = saleItem.bonPrintedAt;
    creditSaleItem.elalPNR                      = saleItem.elalPNR;
    creditSaleItem.elalRecId                    = saleItem.elalRecId;
    creditSaleItem.elalTicketNumber             = saleItem.elalTicketNumber;
    creditSaleItem.elalTicketType               = saleItem.elalTicketType;
    creditSaleItem.elalServiceCD               = saleItem.elalServiceCD;
    creditSaleItem.parentItemId                 = -1
    creditSaleItem.parentRowNumber              = -1 

    var saleItemCopy = saleItem;
    if (!posUtils.isNullOrUndefinedOrEmptyString(saleItem.originalQuantity)) {
      let saleItemCopy:PositiveTS.Storage.Entity.SaleItem = new PositiveTS.Storage.Entity.SaleItem();
      saleItemCopy.importFromObject(saleItem.exportToObject());
      saleItemCopy.quantity = saleItem.originalQuantity;
    }

    creditSaleItem.meta.money = creditSaleItem.meta.money.filter(field => field != "unitPrice") //use better decimal percision for credit invoice
    creditSaleItem.unitPrice = calculateItemPriceAfterAllDiscounts(originalSale, saleItem);
    creditSaleItem.priceNetoAfterDiscounts = creditSaleItem.unitPrice*creditSaleItem.quantity;
    creditSaleItem.promotions = null;
    creditSaleItem.multipassDiscount = saleItem?.multipassDiscount ? (saleItem.multipassDiscount * -1) : 0 ;
    creditSaleItem.kasparDiscount = saleItem?.kasparDiscount ? (saleItem.kasparDiscount * -1) : 0 ;


    return creditSaleItem;
  };

}}}





