module PositiveTS {
  export module Components {
  export module Directives {
  export module MaskJquery {
    export function createDirective () {  
      VueApp.directive('mask-jquery', {
        beforeMount (el, binding, vNode) {
          $(el).setMask(binding.value);
        },
        unmounted (el, binding, vNode) {
          $(el).unsetMask();
        }

      })
    }
}}}}