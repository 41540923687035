module PositiveTS { 
  export module VueServices {
    export module Router {
      const routes = [   
        { path: Components.AddItemsToPromotion.route, name: Components.AddItemsToPromotion.route, component: Components.AddItemsToPromotion.getComponent()},
        { 
          path: Components.AddOrEditPromotion.route,
          name: Components.AddOrEditPromotion.route,
          component: Components.AddOrEditPromotion.getComponent(),
          children: [
            {path: Components.AddOrEditPromotion.KneBe.route, component: Components.AddOrEditPromotion.KneBe.getComponent()},
            {path: Components.AddOrEditPromotion.KneKabel.route, component: Components.AddOrEditPromotion.KneKabel.getComponent()},
            {path: Components.AddOrEditPromotion.HaZolMe.route, component: Components.AddOrEditPromotion.HaZolMe.getComponent()}
          ]
        },
        { 
          path: Components.FeatureOrder.route,
          name: Components.FeatureOrder.name,
          component: Components.FeatureOrder.getComponent(),
          children: [
            {
              path: Components.FeatureOrder.MobilePhoneRepair.route, 
              name: Components.FeatureOrder.MobilePhoneRepair.name,
              component: Components.FeatureOrder.MobilePhoneRepair.getComponent()
            },
            {
              path: Components.FeatureOrder.IndexMobilePhoneRepairs.route, 
              name: Components.FeatureOrder.IndexMobilePhoneRepairs.name,
              component: Components.FeatureOrder.IndexMobilePhoneRepairs.getComponent()
            },
            {
              path: Components.FeatureOrder.MobilePhoneRepairSettings.route, 
              name: Components.FeatureOrder.MobilePhoneRepairSettings.name,
              component: Components.FeatureOrder.MobilePhoneRepairSettings.getComponent()
            },
          ]
        },
        { path: Components.changeWaiterComponent.route, name: Components.changeWaiterComponent.route, component: Components.changeWaiterComponent.getComponent() },
        { path: Components.SelectDalpakDialog.route, name: Components.SelectDalpakDialog.route, component: Components.SelectDalpakDialog.getComponent() },
        { path: Components.TableSearchDialog.route, name: Components.TableSearchDialog.route, component: Components.TableSearchDialog.getComponent() },
        { path: Components.MoveTableComponent.route, name: Components.MoveTableComponent.route, component: Components.MoveTableComponent.getComponent() },
        { path: Components.TableFilterDialog.route, name: Components.TableFilterDialog.route, component: Components.TableFilterDialog.getComponent() },
        { path: Components.DalpakActionsDialog.route, name: Components.DalpakActionsDialog.route, component: Components.DalpakActionsDialog.getComponent() },
        { path: Components.CreateDalpakDialog.route, name: Components.CreateDalpakDialog.route, component: Components.CreateDalpakDialog.getComponent() },
        { path: Components.CreateDalpakAreaDialog.route, name: Components.CreateDalpakAreaDialog.route, component: Components.CreateDalpakAreaDialog.getComponent() },
        { path: Components.DalpakAreaActionsDialog.route, name: Components.DalpakAreaActionsDialog.route, component: Components.DalpakAreaActionsDialog.getComponent() },
        { path: Components.PromotionManage.route, name: Components.PromotionManage.route, component: Components.PromotionManage.getComponent()},
        { path: Components.ItemManage.route, name: Components.ItemManage.route, component: Components.ItemManage.getComponent()},
        { path: Components.Departments.route, name: Components.Departments.route, component: Components.Departments.getComponent()},
        { path: Components.DepartmentForm.route, name: Components.DepartmentForm.route, component: Components.DepartmentForm.getComponent()},
        { path: Components.AddOrEditItem.route, name: Components.AddOrEditItem.route, component: Components.AddOrEditItem.getComponent()},
        { path: Components.EmployeeManage.route, name: Components.EmployeeManage.route, component: Components.EmployeeManage.getComponent()},
        { path: Components.EmployeeManageEdit.route, name: Components.EmployeeManageEdit.route, component: Components.EmployeeManageEdit.getComponent()},
        { path: Components.EmployeeHours.route, name: Components.EmployeeHours.route, component: Components.EmployeeHours.getComponent()},
        { path: Components.CellularTab.route, name: Components.CellularTab.route, component: Components.CellularTab.getComponent()},
        { path: Components.HakafaTab.route, name: Components.HakafaTab.route, component: Components.HakafaTab.getComponent()},
        { path: Components.DepositReport.route, name: Components.DepositReport.route, component: Components.DepositReport.getComponent()},
        { path: Components.AddOrShowBankDeposit.route, name: Components.AddOrShowBankDeposit.route, component: Components.AddOrShowBankDeposit.getComponent()},
        { path: Components.rootRoute, name: Components.rootRoute, component: Components.root},
      ]
      
      let router = null
    
      export function getRouter() {
        router = createRouter({
          routes: routes,
          history: createMemoryHistory(),
        })

        router.beforeEach((to,from,next) => {
          if (to.path !== Components.rootRoute) { //patch to remove old router (dispatcher) screens
            $("[data-role='page']").hide(); 
          }
          next();
        })

        return router;
      }
      
      export function goto(name, params = {}, extraData = null) {
        Pinia.globalStore.setRouterExtraData(extraData)
        
        if (router) {
          router.push({name: name, params: params});
        }
      }
    }
    
    
  }   
}
 
