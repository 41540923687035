module PositiveTS {
   export module Service {
      export module WebsocketSync {
         export class PrimaryPosPrinterSyncModule extends BaseSyncModule {

            constructor(private onPrimaryPosPrint: (data: any) => void) {
               super();
            }

            public setWebsocketEvents(websocket) {
               if (Service.WebsocketSync.SyncServerClient.isPrimaryPos()) {
                  websocket.on('primaryPosPrint', this.onPrimaryPosPrint);
               }
            }

            async sendPrintJobToPrimaryPos(printData: any): Promise<any> {
               let result = await this.getSocket().validateConnectionAndDo(async (): Promise<any> => {
                  return await this.getSocket().makeActionAndWaitForResult('printAtPrimaryPos', printData);                  
               });

               if (!result.success && !result.errorMessage) {
                  result.errorMessage = i18next.t('dalpaks.errorConnectingToDalpakServer');
               }

               return result;
            }


            public setInitialConnectionDataAndConnect() {
               // Action not needed here
               return;
            }

            public async waitForInitialData() {
               // Action not needed here
               return;
            }            
         }
      }
   }
}
