module PositiveTS {
  export module Dialogs {
      export module PluxeeCompanies{
        export function getCompanies(): Promise<any>{
          var pluxeeCompaniesDialogDialog = new PluxeeCompaniesDialog();
          return new Promise((resolve)=>{
            	pluxeeCompaniesDialogDialog.open((response)=>{
            		resolve(response);})
            	})
        }

        class PluxeeCompaniesDialog {
          private ui: any;
          private initialized: boolean = false;
          private _rowsUnfiltered: PositiveTS.Service.PluxeeService.Soap.CompanyItem[] = [];
          private _quickInputSelector = "#cibus-companies-input"

          private _callbak:any;

          constructor() {
            if (!this.initialized) {
              var self = this;
              this.ui = $("#cibus-companies").dialog({
                  autoOpen: false,
                  modal: true,
                  dialogClass: 'positive-dialog',
                  width: '90%',
                  resizable: false,
                  closeOnEscape: true,
                  draggable: false,
                  create: function(event, ui) { self.init(); }
              });
              this.initialized = true;              
            }
          }

          close() {
            this.ui.dialog('close')
            posPaymentVC.open();
          }

          open(callback) {
            this._callbak = callback;
            this.ui.dialog('open');

            this.init();
            // $(document).bind('keydown',posUtils.ignoreEvent);

            // Fetch all the available voucher types
              this._rowsUnfiltered = PositiveTS.Service.PluxeeService.Voucher.listOfCompanies();
              this.getItemsList(this._rowsUnfiltered)
          }

          private init() {
            let tableView = $('#cibus-companies-tablematrix').tablematrix();
            $("#cibus-companies-back").unbind("click");
            $("#cibus-companies-back").bind("click", () => {
              this._callbak({});
              this.close();
            })
            tableView.empty()
            this._bindFilterChange();
          }

          private _bindFilterChange(){
            new UI.UiInput(this._quickInputSelector);
            $(this._quickInputSelector).val('');
            $(this._quickInputSelector).unbind("keyup focus");
            $(this._quickInputSelector).bind("keyup focus", () => {
              var filterString: string = $(this._quickInputSelector).val().toString();
              this._filterCurrentTable(filterString);
            });
          }

          private _filterCurrentTable(filter: string) {
            var vouchers = []
            for (let i = 0; i < this._rowsUnfiltered.length; i++) {
              let voucher = this._rowsUnfiltered[i];
              let searchIn = voucher.companyCode + voucher.companyName;
              if (searchIn.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
                vouchers.push(voucher)
              }
            }
            this.getItemsList(vouchers)
          }






          private getItemsList(listOfCompanies:PositiveTS.Service.PluxeeService.Soap.CompanyItem[]) {
            // Get the table view for later use
            var tableView = $('#cibus-companies-tablematrix').tablematrix();

            // Empty the table so we can be sure we are displaying only the latest results
            tableView.empty();



            if (listOfCompanies.length === 0) {
              return
            }

            var selectedRowId = "";

            for (let i = 0; i < listOfCompanies.length; i++) {


              // Add a row with the payment type name
              var id = this.uiAddRow(listOfCompanies[i], tableView)


              // if 0 it is the default
              if (i === 0) {selectedRowId = id;}

            }

            //Select the first row
            tableView.selectRow(selectedRowId);
          }

          private uiAddRow(companyObj:PositiveTS.Service.PluxeeService.Soap.CompanyItem, tableView) {

            var theRowId = tableView.addCell(companyObj.companyCode + " - " + companyObj.companyName )

            $("#" + theRowId).data('id', companyObj);
            $("#" + theRowId).click(() => { this.continue() });

            return theRowId;
          }

          private continue() {

            // Get the selected row
            var theRowId = $('#cibus-companies-tablematrix').tablematrix().getSelectedCell();

            // If there are no selected rows, finish
            if (theRowId.length == 0) {
              return;
            }

            //Get the user selection
            //var userSelection = $("#" + theRowId).text();
            this._callbak($("#" + theRowId).data('id'))

            this.close()
          }
        };
      }
  }
}
