module PositiveTS {
export module Service {
    export module MobilePhoneRepair {
        export const orderableType = 'MobilePhoneRepair'
    
        export interface MobilePhoneRepair {
            additional_phone_number: string, 
            device_type: string, 
            imei: string, 
            device_password: string,
            color: string,
            repair_estimated_cost: number, 
            additional_status: number, 
            additional_notes_attribute: Array<{additional_type: number, note: string}>,
        }
        interface Settings {
            notes: {id: number, additional_type: number, note: string},
            regulations: {id: number, additional_type: number, note: string},
        }

        export const mobilePhoneRepair: MobilePhoneRepair = {
            additional_phone_number: null, 
            device_type: null, 
            imei: null, 
            device_password: null,
            color: null,
            repair_estimated_cost: null, 
            additional_status: null, 
            additional_notes_attribute: [],
        }

        export const statuses = {
            processingOnLab: 1,
            processingOnExternalLab: 2,
            pendingRepaired: 3,
            pendingWithoutRepair: 4,
            completed: 5,
            cancel: 6,
        }

        export const statusArray: Array<{name: string, id: number}> = [
            {name: 'processingOnLab', id: 1},
            {name: 'processingOnExternalLab', id: 2},
            {name: 'pendingRepaired', id: 3},
            {name: 'pendingWithoutRepair', id: 4},
            {name: 'completed', id: 5},
            {name: 'cancel', id: 6},
        ]

        export const additionalNoteTypes  = {
            notes: 1,
            regulations: 2,
            genral_notes: 3,
            genral_regulations: 4,
        }

        export const settings: Settings = {
            notes: {id: null, additional_type: additionalNoteTypes.genral_notes, note: ''},
            regulations: {id: null, additional_type: additionalNoteTypes.genral_regulations, note: ''},
        }

        export function print (futureOrder, isSource = false) {
            let aThis = printer
            let zebra = aThis.jzebra
            const company = session.company
            const store = session.store
            const storeName = (store.storeName || session.pos.storeName).replace(`״`, `"`)
            const posNumber = session.pos.deviceID

            zebra.append(aThis.allowComponents.init)
            aThis.printLogo()

            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.bigFont)
            zebra.append(aThis.allowComponents.centerAlignment)
                
            let companyNameArray = company.companyName.split(`$$$`)
            for (var i = 0; i < companyNameArray.length; i++) {
                aThis.printMultyLine(companyNameArray[i], printer.lineLengthBigFont);
            }

            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.centerAlignment)
            aThis.printMultyLine(storeName)

            if (!posUtils.isNullOrUndefinedOrEmptyString(store.address)) {
                aThis.printLine(store.address)
            }

            let isRTL = aThis.isRTL
            if (isRTL && printer.isSunmi()) { isRTL = !isRTL }

            if (!posUtils.isNullOrUndefinedOrEmptyString(store.phone)) {
                if (aThis.isHTMLBasedPrinting()) {
                    aThis.printLine(i18next.t("printing.phone") + `: ` + store.phone)
                } else {
                    if (isRTL) {
                        aThis.printText(store.phone)
                        aThis.printText(`\t`)
                        aThis.printLine(`${i18next.t("printing.phone")}:`)
                    }
                    else {
                        aThis.printText(`${i18next.t("printing.phone")}:`)
                        if (printer.isSunmi() == false) {
                            aThis.printText(`\t`)
                        }
                        aThis.printLine(store.phone)
                    }
                }
            }

            if (!posUtils.isNullOrUndefinedOrEmptyString(store.registrationNum)) {
                if (aThis.isHTMLBasedPrinting()) {
                    aThis.printLine(aThis.chetPeiAinMem() + `: ` + store.registrationNum)
                } else {

                    if (isRTL) {
                        aThis.printText(store.registrationNum)
                        aThis.printText(`\t`)
                        aThis.printLine(aThis.chetPeiAinMem() + `:`)
                    }
                    else {
                        aThis.printText(aThis.chetPeiAinMem() + `:`)
                        if (printer.isSunmi() == false) {
                            aThis.printText(`\t`);
                        }
                        aThis.printLine(store.registrationNum)
                    }
                }
            }

            if (!posUtils.isNullOrUndefinedOrEmptyString(store.freeText)) {
                var freeTextArray = store.freeText.split(`$$$`)
                for (var i = 0; i < freeTextArray.length; i++) {
                    aThis.printMultyLine(freeTextArray[i])
                }
            }

            aThis.printLine(` `)
            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.rightAlignment)

            let dateToPrint;
            if (String(futureOrder.opened_at).match(/[a-z]/i)) {
                dateToPrint = moment(new Date(futureOrder.opened_at))
            }
            else {
                dateToPrint = moment(futureOrder.opened_at, "DD/MM/YYYYY HH:mm")
            }

            aThis.printFloatingLine(i18next.t('printing.POS') + ':', posNumber + ' ')
            aThis.printFloatingLine(i18next.t('printing.cashier') + ':', session.pos.employeeName)
            aThis.printLine(` `)
            aThis.printFloatingLine(`${i18next.t('printing.date')}: ${dateToPrint.format("DD/MM/YYYY")}`, `${i18next.t('printing.time')}: ${dateToPrint.format("HH:mm")}`)

            aThis.printHr()
            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.centerAlignment)
            zebra.append(aThis.allowComponents.bold)
            let originalCopyTransKey  = isSource === true ? 'original' : 'copy';
            aThis.printLine(i18next.t(`printing.${originalCopyTransKey}`))
            zebra.append(aThis.allowComponents.bigFont)
            aThis.printLine(futureOrder.sequence)

            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.rightAlignment)
            aThis.printHr()

            //customer
            let customerName = ''
            if (!posUtils.isNullOrUndefinedOrEmptyString(futureOrder.customer.first_name)) {
                customerName += futureOrder.customer.first_name
            }
            if (!posUtils.isNullOrUndefinedOrEmptyString(futureOrder.customer.last_name)) {
                customerName += ` ${futureOrder.customer.last_name}`
            }

            aThis.printLine(`${i18next.t("printing.customer")}: ${customerName}`)
            if (!posUtils.isNullOrUndefinedOrEmptyString(futureOrder.customer.customer_tz)) {
                aThis.printLine(`${i18next.t("printing.phone")}: ${futureOrder.customer.customer_tz}`)
            }

            let customerAddress = PositiveTS.Service.CustomerClub.getShortDeliveryCustomerAddress(futureOrder.customer)
            if (!posUtils.isNullOrUndefinedOrEmptyString(customerAddress)) {
                aThis.printMultyLine(`${i18next.t("mobilePhoneRepair.customerAddress")}: ${customerAddress}`, 40)
            }
            
            if (!posUtils.isNullOrUndefinedOrEmptyString(futureOrder.customer.phone_number)) {
                aThis.printLine(`${i18next.t("printing.ID")}/${i18next.t("printing.taxIdNum")}: ${futureOrder.customer.phone_number}`)
            }
            aThis.printHr()

            aThis.printFloatingLine(`${i18next.t('mobilePhoneRepair.deviceType')}`, futureOrder.orderable.device_type)
            aThis.printFloatingLine(`${i18next.t('mobilePhoneRepair.imei')}`, futureOrder.orderable.imei)
            aThis.printFloatingLine(`${i18next.t('mobilePhoneRepair.color')}`, futureOrder.orderable.color)
            aThis.printFloatingLine(`${i18next.t('mobilePhoneRepair.devicePassword')}`, futureOrder.orderable.device_password)
            aThis.printHr()

            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.centerAlignment)
            zebra.append(aThis.allowComponents.bold)
            aThis.printLine(i18next.t('mobilePhoneRepair.repairDetails'))

            zebra.append(aThis.allowComponents.init)
            zebra.append(aThis.allowComponents.rightAlignment)
            zebra.append(aThis.allowComponents.bold)
            if (!posUtils.isBlank(futureOrder.notes)){
                aThis.printLine(` `)
                futureOrder.notes.split(`\n`).forEach((line) => {
                    aThis.printLine(line)
                })
            }
            
            aThis.printHr()
            if (!posUtils.isNullOrUndefinedOrEmptyString(futureOrder.orderable.repair_estimated_cost)) {
                zebra.append(aThis.allowComponents.init)
                zebra.append(aThis.allowComponents.rightAlignment)
                aThis.printFloatingLine(`${i18next.t('mobilePhoneRepair.repairEstimatedCost')}`, `${futureOrder.orderable.repair_estimated_cost}`)
                aThis.printHr()
            }
            
            let notes = null
            let regulations = null
            if (Array.isArray(futureOrder.orderable.additional_notes_attribute)){
                const additionalNotes = futureOrder.orderable.additional_notes_attribute
                notes = additionalNotes.find((note) => note.additional_type == additionalNoteTypes.notes)
                regulations = additionalNotes.find((note) => note.additional_type == additionalNoteTypes.regulations)
            }

            if (!notes){
                notes = session.mobilePhoneRepairSettings.notes
            }

            if (!regulations){
                regulations = session.mobilePhoneRepairSettings.regulations
            }

            if (notes || regulations){
                if (notes){
                    if (!posUtils.isBlank(notes.note)){
                        aThis.printLine(` `)
                        notes.note.split(`\n`).forEach((line) => {
                            aThis.printLine(line)
                        })
                    }
                }

                if (regulations){
                    if (!posUtils.isBlank(regulations.note)){
                        aThis.printLine(` `)
                        regulations.note.split(`\n`).forEach((line) => {
                            aThis.printLine(line)
                        })
                    }
                }

                aThis.printLine(` `)
            }

            aThis.printLine(` `)
            aThis.printLine(` `)
            aThis.printLine(`אישור בחתימת הלקוח _______________________`)
            aThis.printLine(` `)
            aThis.printLine(` `)
            aThis.printLine(`הוחזר בתאריך _____________________________`)
            aThis.printLine(` `)
            aThis.printLine(` `)
            aThis.printLine(`חתימת הלקוח ______________________________`)

            aThis.printLineCut()
            aThis.print()
        }

        export function statusToFutureOrderStatus (status: number|string) {
            let statusFutureOrder = FutureOrder.statuses.open

            if (status == statuses.cancel) {
                statusFutureOrder = FutureOrder.statuses.cancel
            }

            if (status == statuses.completed) {
                statusFutureOrder = FutureOrder.statuses.completed
            }
            
            return statusFutureOrder
        }

        export async function fetchSettings () {
            try {
              const additionalTypes = `${additionalNoteTypes.genral_notes},${additionalNoteTypes.genral_regulations}`
              let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(`/additional_notes/?type=MobilePhoneRepair&additional_type=${additionalTypes}`, 'get')
              let settings = MobilePhoneRepair.settings

              if (Array.isArray(res.data)){
                const notes = res.data.find((an) => an.additional_type == additionalNoteTypes.genral_notes)
                const regulations = res.data.find((an) => an.additional_type == additionalNoteTypes.genral_regulations)
                
                if (notes){
                    settings.notes = notes
                }

                if (regulations){
                    settings.regulations = regulations
                }         
              }

              session.mobilePhoneRepairSettings = settings
              
            }catch(error){
              console.error(error)
              if (!session.mobilePhoneRepairSettings){
                session.mobilePhoneRepairSettings = MobilePhoneRepair.settings
              }
            }
        }

        export function hasMobilePhoneRepairModule (): boolean { 
            return jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule)
        }

        export async function goToPaymentScreen(sale) {
            const jsondata = JSON.parse(sale.jsondata)
            if (posVC.getTotalAmount() < jsondata.mobilePhoneRepairEstimatedCost){
                let res = await app.promiseShowAlert({
                    header: i18next.t('message'),
                    content: i18next.t('mobilePhoneRepair.alertPayLessThenRepairEstimatedCost', {cost: jsondata.mobilePhoneRepairEstimatedCost}),
                    continueButtonText: i18next.t("ok"),
                    cancelButtonText: i18next.t("back"),
                })

                if (res == 'continue'){
                    posVC.openPaymentScreen()
                }
            }else{
                posVC.openPaymentScreen()
            }            
          }

        export function deleteMobilePhoneRepairDataFromSaleJsonData (sale) {
            let jsonDataDict = posUtils.isBlank(sale.jsondata) ? {} : JSON.parse(sale.jsondata)

            if (jsonDataDict.hasOwnProperty('mobilePhoneRepairEstimatedCost')){
                delete jsonDataDict.mobilePhoneRepairEstimatedCost
                sale.jsondata = JSON.stringify(jsonDataDict)
            }
        }
    }
}
}

