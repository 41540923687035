module PositiveTS {
export module Service {
export module GovIlAddresses {
    export function findStreet(cityName:string, streetName:string){
        var searchString = encodeURI(`${cityName} ${streetName}`)
        var _data={"body":"",
                    "type":"GET",
                    "url":`https://data.gov.il/api/action/datastore_search?resource_id=a7296d1a-f8c9-4b70-96c2-6ebb4352f8e3&q=${searchString}&limit=10`};
	    var data = JSON.stringify(_data)
	    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                    {data: data, action: "HTTP_ROUTER" },
                    "http_router"
        ).then(function(x){
        	
        	var result = JSON.parse(x.request.result)
            var retAry = result.result.records.map( row=>{return row["שם_רחוב"].trim()});
            return retAry;
	    })
        
    }

    export function findCity(cityName:string){
        var searchString = encodeURI(cityName)
        var _data={"body":"",
                    "type":"GET",
                    "url":`https://data.gov.il/api/action/datastore_search?resource_id=a68209f0-8b97-47b1-a242-690fba685c48&q=${searchString}&limit=10`};
	    var data = JSON.stringify(_data)
	    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
                    {data: data, action: "HTTP_ROUTER" },
                    "http_router"
        ).then(function(x){
        	
        	var result = JSON.parse(x.request.result)
            var retAry = result.result.records.map( row=>{return row["שם_ישוב"].trim()});
            var exactMatch = retAry.filter( row=>{return row === cityName})
            if (exactMatch.length > 0){
                return exactMatch
            } 
            return retAry;
	    })
        
    }

}}}