module PositiveTS {
export module Storage {
export module Entity {
export class Store extends WasmEntity {
	
	tenantID:string
	companyID:string
	storeID:string
	storeName:string
	address:string
	registrationNum:string
	phone:string
	logo:string
	contactPerson:string
	contactPersonPhone:string
	containVat:boolean
	freeText:string
	isOsekMurshe:boolean
	isOsekPatur:boolean
	isAmotaReshoma:boolean
	allowDuplicateBarcodes:boolean
	beengoBranchId:string
	beengoBusinessId:string

	static dbCache:Array<Store> = null

	constructor() {
		let meta = {
			name: 'Store',
			fields: {
				tenantID: "TEXT",
				companyID: "TEXT",
				storeID: "TEXT",
				storeName: "TEXT",
				address: "TEXT",
				registrationNum: "TEXT",
				phone: "TEXT",
				logo: "TEXT",
				contactPerson: "TEXT",
				contactPersonPhone: "TEXT",
				containVat: "BOOL",
				freeText: "TEXT",
				isOsekMurshe: "BOOL",
				isOsekPatur: "BOOL",		
				isAmotaReshoma: "BOOL",		
				allowDuplicateBarcodes: "BOOL",
				beengoBranchId: "TEXT",
				beengoBusinessId: "TEXT",
			},
			unique: ['tenantID','companyID','storeID'],
		}
		super(meta);
	}

	getCache() {
		return super.getCache<Store>("dbCache");
	}

	fetchByTenantIDAndCompanyIDAndStoreID(tenantID, companyID, storeID) {
		let query = `SELECT * FROM ${this.meta.name}
						 WHERE 
						tenantID = ${tenantID} AND 
						companyID = ${companyID} AND 
						storeID = ${storeID}`;

		return this.execAndConvertResult(query);
	}

	fetchByTenantIDAndCompanyID(tenantID, companyID) {
		let query = `SELECT * FROM ${this.meta.name}
						 WHERE 
						tenantID = ${tenantID} AND 
						companyID = ${companyID} 
						ORDER BY storeName`;


		return this.execAndConvertResult(query);
	}

	static getCache():Promise<Array<Store>> {
		let store = new Store()
		return store.getCache()
	}

	static getStoreName(tenantId, companyId, storeId):Promise<string> {
		return new Promise((resolve,reject) => {
			Store.getCache()
			.then(stores => {
				let store = stores.filter(store => store.tenantID == tenantId && store.companyID == companyId && store.storeID == storeId)[0]
				if (store == null) {
					reject("no store name found for store id")
				}
				else {
					resolve(store.storeName)
				}
			})
		})		
  }
}}}}
