module PositiveTS {
export module Service {
export module PaiditService {


  function getUrlPrefix() {
    return 'https://system-management.paidit.io/api/PosOperations';
  }

  export async function fetchPaiditDeliveryOrders() {
       
    // let _do = `https://dev.paidit.io:8005/api/PosOperations/GetCaspitOpenOrders?restId=${session.pos.paiditResId}` //test
    let _do = `${getUrlPrefix()}/GetCaspitOpenOrders?restId=${session.pos.paiditResId}`
    
    var _data = { 
        "body": "", "type": "GET",
        "url": _do,
        "headers": _headers(),
        "timeout": 120000
    };
    var data = JSON.stringify(_data)
    let x = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data },"external_service")
    
    Filelog.log("paidit",
      JSON.stringify({ request: x.request.body.data, result: x.request.result })
    );
    
    let result = JSON.parse(x.request.result);
    if (result && result.Data.length > 0) {
      return result.Data;
    } else {
      if (x.request.status != "200" || result === null) {
        let error = new Error(`${session.pos.deviceID}-fetchPaiditDeliveryOrders :: ` + JSON.stringify(x));
        Logger.error(error);
      }
    }

  }


  export async function fetchSingleOrder(orderId) {

    // let _do = `https://dev.paidit.io:8005/api/PosOperations/GetCaspitSingelOrder?orderId=${orderId}` //test
    let _do = `${getUrlPrefix()}/GetCaspitSingelOrder?orderId=${orderId}`

    let _data = { 
      "body": "", "type": "GET",
      "url": _do,
      "headers": _headers(),
      "timeout": 120000
    };
    let data = JSON.stringify(_data)
    let x = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data },"external_service");

    Filelog.log("paidit",
      JSON.stringify({ request: x.request.body.data, result: x.request.result })
    );

    let result = JSON.parse(x.request.result);
    if (result.Data) {
      return result;
    } else {
      if (x.request.status != "200") {
        console.error(x)
      }
    }
  }
  

  function _headers() {
      return JSON.stringify({
          "accept": "application/json",
      })
  }


  export async function updateOrderStatus(orderId, status) {
    
    // let _do = `https://dev.paidit.io:8005/api/PosOperations/SetCaspitOrderStatus?orderId=${orderId}&status=${status}` //test
    let _do = `${getUrlPrefix()}/SetCaspitOrderStatus?orderId=${orderId}&status=${status}`

    var _data = { 
      "body": "", "type": "POST",
      "url": _do,
      "headers": _headers(),
      "timeout": 120000
    };
    var data = JSON.stringify(_data)
    let x = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data },"external_service")

    Filelog.log("paidit",
      JSON.stringify({ request: x.request.body.data, result: x.request.result })
    );

    let result = JSON.parse(x.request.result);
    if (result) {
      return result;
    } else {
      if (x.request.status != "200") {
        console.error(x)
      }
    }
  }



}}}
