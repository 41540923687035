module PositiveTS {
    export module Service {
        export module InterServiceService {
            const apiUrl = "https://api.interservice.co.il/api/post/caspit/V2/checkShovar.asp"

            function headers() {
                return JSON.stringify({
                    "accept": "application/json",
                })
            }

            function fetchUserData() {
                return {
                    username: session.pos.interServiceUserName,
                    password: session.pos.interServicePass,
                    branchCode: session.pos.interServiceBranchCode
                }
            }

            export async function interServiceVoucherHandler(voucherNumber:string, action:string ) {
               const userData = fetchUserData()

                let data = JSON.stringify({
                    "body": JSON.stringify({
                        "user": userData.username,
                        "password": userData.password,
                        "branch_code": userData.branchCode,
                        "Voucher_Number": voucherNumber,
                        "checkORkill": action
                    }),
                    "type": "POST",
                    "url": apiUrl,
                    "headers": headers()
                });

                let response = await GenericAddon.sendNativeMessageToExtension(
                    { data: data },
                    "external_service"
                )

                const res = JSON.parse(response.request.result.toLowerCase()) 

                return res
            } 

        }
    }
}