module PositiveTS {
    export module Components {
    export module MobileInfo {
    
      const _vueComponentName = 'mobile-info';

    
    function getComponent() {
        return {
            template: JST.mobileInfo(),
            methods: {
              toggle() {
                let overlay = document.getElementById('mobile-info');
                let overlayBackground = document.getElementById("loading-message-overlay");

                if( !overlay ){
                  return;
                }
                let container = document.querySelector('div#vue-components-wrapper')
                window.requestAnimationFrame(() => {
                  if( overlay.classList.contains('open') ) {
                    document.removeEventListener('click',this.listener)
                    overlay.classList.remove('open' );
                    overlayBackground.style.display = 'none';
                    container.classList.remove('overlay-open');
                    overlay.classList.add('close');
                    let self = this;
                    let onEndTransitionFn = function(this:Element, ev? ) {
                        
                        if( ev.propertyName !== 'visibility' ) return;
                        this.removeEventListener( 'transitionend', onEndTransitionFn );
                        
                        overlay.classList.remove('close');
                    };
                    
                    overlay.addEventListener( 'transitionend', onEndTransitionFn );
                    
                    
                    }
                    else if (overlay.classList.contains('close')) {
                      document.addEventListener('click',this.listener)
                      overlay.classList.remove('close');  
                      overlay.classList.add('open');
                      overlayBackground.style.display = 'block';
                      container.classList.add('overlay-open');
                    }
                })
                
              },
              hasFlights: () => session.pos.hasFlights,
              close: close,
              baseCurrencySign: () => Service.MultiCurr.getInstance().getPosCurrencySign(),
            },
            data: function() {
                return {
                    listener: () => PositiveTS.VueInstance.$refs.mobileInfo.toggle(),
                    deviceID: session.pos.deviceID,
                    storeName: session.pos.storeName,
                    initialTime: PositiveTS.DateUtils.longFormat()
                }
            },
            computed:  {
            offlineSaleCount(){
              return Pinia.globalStore.offlineSaleCount == 0 ? i18next.t('none') : Pinia.globalStore.offlineSaleCount
            },  
            dalpakName: () => {
              let name = null
              if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && Pinia.dalpaksStore.currentSelectedDalpak){
                name = Pinia.dalpaksStore.currentSelectedDalpak.name
              }
              return name
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight,currentLeg,totalSalesAmount} = Pinia.storeToRefs(flightsStore);

            const globalStore = Pinia.useGlobalStore();
            const {
              selfServiceSuperMarket,
              emvPosNumber,
              simpleSelfService,
              supportNumber,
              isOnline,
              passwordProtected,
              avoidNexLogin,
              mobileLayout
            } = Pinia.storeToRefs(globalStore);

            const globalStoreProps = {
              isSelfServiceSupermarket:selfServiceSuperMarket,
              emvPosNumber,
              simpleSelfService,
              supportNumber,
              mobileLayout,
              avoidNextLogin:avoidNexLogin,
              stateIsOnline:isOnline,
              statePasswordProtected:passwordProtected
            } 

            return {...globalStoreProps,currentFlight,currentLeg,totalSalesAmount}
          },
            
            mounted: function() {
            }

        }
      }
      
      export function create() {
        VueApp.component(_vueComponentName,getComponent())
      }
    
    
    
}}}
    