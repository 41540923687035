module PositiveTS {

    export module Components {
    export module FeatureOrder {
        export module MobilePhoneRepairSettings {
          export const route = "/mobile-phone-repair-settings"
          export const name = "MobilePhoneRepairSettings"

          export function getComponent() {
  
            return {
  
              template: JST.MobilePhoneRepairSettings(),

              computed :{
                mobileLayout(){
                  return Pinia.globalStore.mobileLayout
                },
              },
  
              methods: {
                ...Mixins.modalsMixin.methods,
                
                close () {
                  PositiveTS.VueInstance.goToPos()
                },

                async save () {
                   app.showLoadingMessage(i18next.t('mobilePhoneRepairSettings.saveSettings'))

                  try {
                    let additional_notes = [
                      this.settings.notes,
                      this.settings.regulations,
                    ]
                    
                    let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(`/additional_notes/create_or_update_bulk/?type=MobilePhoneRepair`, 'post', {additional_notes})
                    session.mobilePhoneRepairSettings = _.cloneDeep(this.settings)
                    app.hideLoadingMessage()
                    app.showAlertDialog({content: i18next.t('mobilePhoneRepairSettings.saveSuccessed'), hideCancelButton: true})
                  }catch(error){
                    console.error('Error on PositiveTS.Service.FutureOrder.all', error)
                    app.hideLoadingMessage()
                    this.showAlertGeneric(i18next.t('mobilePhoneRepairSettings.errorOnSaveSettings'))
                  }                  
                },

                fetchSettings () {
                  this.settings = _.cloneDeep(session.mobilePhoneRepairSettings)
                },

                updateNotes (event, type) {
                  let str = event.target.value.replace(/(\r\n|\n|\r)/gm,"")
                  let notes = str.match(/.{1,40}(\s|$)/g)
                  if (notes){
                    this.settings[type].note = notes.join(`\n`)
                  }else {
                    this.settings[type].note = ''
                  }
                }
              },
  
              data() {
                let dataVal :{
                  settings: typeof PositiveTS.Service.MobilePhoneRepair.settings,
                } = {
                  settings: {...PositiveTS.Service.MobilePhoneRepair.settings},
                }
                return dataVal
              },
          
              created () {
                this.fetchSettings()
              },
            }
  
          }
          
      }
    }
    }
  }

