module PositiveTS {
	export module SuspiciousActivityLogWorker {

		export async function launchQueueExt() {
			try {
				await launchQueue();
			} catch(error) {
				let msg = "";
				if (error && error.message) {
					msg = error.message;
				}
				if (typeof(error) === "string") {
					msg = error;
				}
				console.error(`Did not send suspicious actibity to server. Reason: ${msg}`);
			}

			// Schedule the next queue launch
			setTimeout(() => { SuspiciousActivityLogWorker.launchQueueExt(); }, 5000);			
		}

	 async function launchQueue() {

		let items = await workerDB.suspiciousActivityLog.
		filter((item) =>  item.syncStatus == Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT ||
						item.syncStatus == Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED).toArray();

		if (items.length == 0) {
			return;
		}

		if (!PositiveTS.QueueWorker.Utils.isOnline) { 
			return;
		}
		
		let itemsForServer = new Array();
		items.forEach(item => {
			itemsForServer.push({
				pos_log_id: item.id,
				logged_at: item.timestamp,
				employee_id: item.employee,
				target_name: item.target_name,
				action: item.action,
				employee_manager_id: item.manager,
				amount: item.amount || null			})
		})

		await sendItemsToServer(itemsForServer);
		return;
	}

	async function sendItemsToServer(items) {
			// Send the sales to the remote server
			let url = Shared.Constants.remoteRoot + Shared.Constants.SuspiciousActivityRemote;

		let results = await PositiveTS.QueueWorker.FetchReq.jsonReq(url,"POST",PositiveTS.QueueWorker.Utils.token ,{ data: JSON.stringify(items) })
		
		let promises = [];
		// --- If there was an error, the remote server will return an error object
		if ('error' in results.result) {
			await saveFailures(items)
			throw new Error(results.result.error);
			}
			else {
			for (let itemResult of results.result) {

				if (itemResult.success) {
					promises.push(workerDB.suspiciousActivityLog.where('id').equals(itemResult.pos_log_id)
					.modify({syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY, serverId: itemResult.id}))
				}
				else {
					promises.push(workerDB.suspiciousActivityLog.where('id').equals(itemResult.pos_log_id)
					.modify({syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED}))
				}
			}

			await Promise.all(promises);
			return;
		}
	}

		async function saveFailures(items) {
			
			// Mark all sales sync status as failed
			await workerDB.suspiciousActivityLog.where('id').anyOf(items.map(item => item.id))
			.modify({ syncStatus: Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED })
			 return;
		}

	}
}
