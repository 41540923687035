module PositiveTS {
  export module Components {
    export module MultiCurrencyChangeCloseSaleDialog {

      const _dialogSelector = "#multicurrency-close-sale-dialog";
      const _vueComponentName = 'multi-currency-change-close-sale-dialog';

      export function create() {
        let multiCurrencyChangeCloseSaleDialog = {
          template: JST.multiCurrencyChangeCloseSaleDialog(),
          methods: {
            open() {
              // if already opened
              if (this.$el.hasAttribute('open')) {
                return;
              }

              this.saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);

              // calcuate sale change
              this.change = PositiveTS.Helper.SaleHelper.calcuateChange(this.saleTotals.totalAmount, 0, 0)

              this.$el.showModal();
            },
            close() {
              this.$el.close();
            },
            async clearChange() {
              let changePaymentIndex = posVC.salePayments.findIndex(payment => payment.method == Storage.Entity.SalePayment.METHOD_CHANGE)

              if (changePaymentIndex > -1) {
                posVC.salePayments.splice(changePaymentIndex, 1)
                await posVC.saleUpdated();
              }
            },
            async finishSale() {
              if (this.isOkClicked) {
                return;
              }

              this.isOkClicked = true;
              try {
                await this.addMultiCurrencyChange();
                await this.closeSale();
                this.close();
              } catch (err) {
                console.error(err);

                try {
                  await this.clearChange();
                } catch(innerErr) {
                  console.error(innerErr)
                }
                

                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('generalError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              } finally {
                this.isOkClicked = false;
              }
            },
            async addMultiCurrencyChange() {
              await this.clearChange();

              if (this.getChangeAmountInBaseCurrency() == 0) {
                return;
              }

              let changePayment = new PositiveTS.Storage.Entity.SalePayment();
              changePayment.saleID = posVC.sale.id;
              changePayment.method = Storage.Entity.SalePayment.METHOD_CHANGE;


              let roundAmount = 0;

              let changeCurrencyAmount = PositiveTS.Service.MultiCurr.getInstance().conversionAmount(this.getChangeAmountInBaseCurrency(), this.paymentCurrency);
              let amount: number, row: any;
              [amount, row] = Service.MultiCurr.getInstance().translateAmountAndCreateRowSalePaymentData(changeCurrencyAmount, this.paymentCurrency)
              changePayment.data = Service.MultiCurr.getInstance().addRowSalePaymentData(changePayment.data, row)

              let origAmount = Number(changePayment.amount);
              // Set the amount of the sale payment
              changePayment.amount = origAmount + amount;

              // Persist the sale payment
              return await posVC.persistSalePayment(changePayment, roundAmount, false);
            },
            async closeSale() {
              await PositiveTS.Service.CloseSale.ashkaraCloseSale();
            },
            setChangeCurrency(newCurrencyCode) {
              PositiveTS.Service.Currencies.updatePosPaymentCurrency(newCurrencyCode);
              this.selectCurrencyPage = false;
            },
            getChangeInCurrencies() {

              let currencies = []

              PositiveTS.Service.MultiCurr.getInstance().getCurrenciesEnabled().forEach((code) => {

                let rate: any = posPaymentVC.Currencies[code];

                rate = parseFloat(rate)

                currencies.push({
                  code,
                  rate: rate.toFixed(2),
                  result: PositiveTS.Service.MultiCurr.getInstance().conversionAmount(this.getChangeAmountInBaseCurrency(), code)
                })

              });

              return currencies;
            },
            getChangeAmountInBaseCurrency() {
              return parseFloat((this.change.cash || 0).toFixed(2)) * -1;
            },
          },
          data() {
            return {
              selectCurrencyPage: false,
              saleTotals: null,
              change: null,
              isOkClicked: false,
            };
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {currency,portraitMode,paymentCurrency,mobileLayout} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {baseCurrency:currency,portraitMode,paymentCurrency,mobileLayout}

            const {setPaymentCurrency} = globalStore

            return {...globalStoreProps,setPaymentCurrency}
          },
          computed: {
            paymentCurrency(){
              return this.paymentCurrency || this.currency
            },
            dataWithTitles() {
              return [
                { title: i18next.t('closeSaleDialog.totalQuantity'), data: this.saleTotals.totalAbsoluteQuantity },
                { title: i18next.t('closeSaleDialog.totalAmount', { currency: this.baseCurrency }), data: this.saleTotals.totalAmount },
                { title: i18next.t('closeSaleDialog.totalDiscount', { currency: this.baseCurrency }), data: this.saleTotals.totalDiscount },
                { title: i18next.t('closeSaleDialog.totalPayed', { currency: this.baseCurrency }), data: this.saleTotals.totalPaid },
                { title: i18next.t('closeSaleDialog.actualCurrencyChange', { currency: this.paymentCurrency }), data: PositiveTS.Service.MultiCurr.getInstance().conversionAmount(this.getChangeAmountInBaseCurrency(), this.paymentCurrency).toFixed(2) },
              ]
            },
          },
        }

        VueApp.component(_vueComponentName, multiCurrencyChangeCloseSaleDialog)
      }
    }
  }
}