export default abstract class PinpadConfig {
    terminalNumber: string;
    posNumber: string;
    isAndroid: boolean;
    atmTerminalNumber?: string|null = null;
    usePinpadInSwitchMode?: boolean = false;
    displayPanEntryModeTypeFirst?: boolean = false;
    emvTimeout?: number = 60;
    isNewVersionAddonFiles?: boolean = true;
    moneyOnlyRefund?: boolean = false;
    skipVerificationCode?: boolean = false;
    pinpadCommunicationErrors?: string = "10043,10053,10041";
    keepAliveEmvMinutesInterval?: number = 30;
    keepAliveEmv?: boolean = false;
    keepReceipts?: boolean = false;
    isGateway?: boolean = false;
    preventDuplicateTransactionWithXFields?: boolean = false;
    isHttpConnection?: boolean = false;
    numberOfRetriesInNullResult?: number = 0;
    numberOfSecondsToSleep?: number = 15;
    shouldCancelOnCredit(): boolean {
        return true;
    }
}