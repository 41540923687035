module PositiveTS {
  export module Components {
      export module Departments {
        export const route = '/departments';
        var component = null;
  
        export function getComponent() {
          if (component == null) {
            component = {
              template: JST.departments(),
              
              methods: {
                ...Mixins.modalsMixin.methods,

                initData() {
                  return {
                    addDepartmentOpen: false,
                    currentDepartment: this.blankDepartment(),
                    sortOrder: null
                  }
                },
        
                blankDepartment() {
                  return new Storage.Entity.Department("");
                },
        
                 cleanData() {
                  let result = this.initData();
                  for (let prop in result) {
                    this[prop] = result[prop];
                  }
                },
          
        
                close() {
                  this.cleanData()
                  VueServices.Router.goto(ItemManage.route);
                },
        
                async addDepartment() {
                  this.addDepartmentOpen = true;
                  await PositiveTS.VueInstance.$nextTick();
                  (<any>this.$refs.newDepartmentInput).focus();
                },

                async deleteDepartment(department:Storage.Entity.Department) {
                  let result = await this.promiseShowAlertGenericDialog(i18next.t("departments.deleteDepartmentConfirm"),false);
                  if (result == "continue") {
                    console.debug('delete department');
                    await appDB.transaction("rw",appDB.localItems,appDB.departments,async () => {
                      let promises = [];
                      promises.push(appDB.departments.where('id').equals(department.id).modify({
                        markedForDelete:true,
                        syncStatus:Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT
                      }));
                      
                      promises.push(appDB.localItems.where('departmentId').equals(department.id).modify({
                        locked:true, departmentId:-1, serverDepartmentId: -1,
                        syncStatus:Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT
                      }));

                      await Dexie.waitFor(Promise.all(promises));
                    })

                    this.departments = this.departments.filter(dep => dep.id != department.id);
                    //TODO: this code smells... we need some global update cache method...
                    let itemsToLockInCache = await appDB.localItems.where('departmentId').equals(department.id).toArray();
                    itemsToLockInCache.forEach(item => { 
                      item.locked = true; 
                      session.allItems.set(item.code,item);
                    })
                    session.allDepartments = this.departments;
                  // update the menus when a new item is added or updated
                  Pinia.posMenu.setMenusLoaded(false);
                  Pinia.posMenu.loadMenusIfNeeded();
                  }
                },
        
                async editDepartment(department:Storage.Entity.Department) {
                  VueServices.Router.goto(DepartmentForm.route, {}, {department: department});
                },
        
                cancelAddDepartment() {
                  this.currentDepartment = this.blankDepartment();
                  this.addDepartmentOpen = false;
                  this.sortOrder = null;
                },
        
                async addNewDepartment() {
                  if (posUtils.isNullOrUndefinedOrEmptyString(this.currentDepartment.name)) {
                    this.showAlertGeneric(i18next.t('departments.departmentNameCantBeEmpty'));
                    return;
                  }
                  if(this.sortOrder && this.sortOrder < 0){
                    this.showAlertGeneric(i18next.t('departments.departmentSortOrderCantBeNegative'));
                    return;
                  }
                  this.addDepartmentOpen = false;
                  try {
                    this.currentDepartment.sortOrder = this.sortOrder? parseInt(this.sortOrder) : 0;
                    this.currentDepartment.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                    this.currentDepartment.timestamp = (new Date()).getTime();
                    await appDB.departments.put(this.currentDepartment)
                  }
                  catch(err) {
                    if (err && err.name == "ConstraintError") {
                      this.showAlertGeneric(i18next.t('departments.departmentAlreadyExists'));
                    }
                    else {
                      this.showAlertGeneric(i18next.t('departments.couldNotAddDepartment'));
                    }
                    console.error(err);
                  }
                  this.departments = await appDB.departments.orderBy('[sortOrder+name]').toArray();
                  session.allDepartments = this.departments;
                  this.currentDepartment = this.blankDepartment();
                   // update the menus when a new item is added or updated
                  Pinia.posMenu.setMenusLoaded(false);
                  Pinia.posMenu.loadMenusIfNeeded();
                  await PositiveTS.VueInstance.$nextTick();
                  let objDiv = document.getElementById("departments-list");
                  objDiv.scrollTop = objDiv.scrollHeight;
                }
              },
              data() {
                return {
                  addDepartmentOpen: false,
                  currentDepartment: new Storage.Entity.Department(""),
                  sortOrder: 0,
                  departments: []
                }
              },
              mounted: async function() {
                this.departments = await appDB.departments.orderBy('[sortOrder+name]').toArray();
              },
              computed: {
                mobileLayout(){
                  return Pinia.globalStore.mobileLayout
                },
              }
            }
          }
          return component;
        } 

    }
  }
}
