module PositiveTS {
export module Service {
export module MultiplePosesOpened {
    export async function verifyOnlyOnePosIsOpen(isCaveret = false) {
        app.hideLoadingMessage();
        let otherPosesOpen = await posUtils.checkIfOtherTabsAreOpen();
        if(otherPosesOpen) {
            showOtherTabsMessage(isCaveret);
        }
        return !otherPosesOpen;
    }

    export function showOtherTabsMessage(isCaveret = false) {
        let okBtn = i18next.t("ok");
        if(isCaveret) {
            okBtn = i18next.t('closePos');
        }
        app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('otherTabsAlreadyOpen'),
            continueButtonText: okBtn,
            hideCancelButton: true
        }, function() {
            if(!isCaveret) {
                setTimeout(showOtherTabsMessage, 100);
            } else {
                closeChrome();
            }
        });
    }

    async function closeChrome() {
        app.showLoadingMessage(i18next.t('attemptingToClose'));
        PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({command: "killchrome", arguments: ""}, "positive_runner");
        await setTimeout(async () => {
            app.hideLoadingMessage();
            showCloseError();
        }, 5000);
    }

    function showCloseError() {
        app.showAlert({
            header: i18next.t('error'),
            content: i18next.t('closeFailed'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
        }, function () {
            setTimeout(showCloseError, 100)
        });
    }


}}}