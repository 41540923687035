module PositiveTS {
    export module Promotions {
        export module Templates {
            export class BrillCustomerClubBirthday extends TemplateAbstract {
                
                constructor(initParameters) {
                    super(initParameters)
                }

                calculatePromotions() {
                    for (var promotionCode in this.parameters.promotionsByCode) {
                
                        var promotion = this.parameters.promotionsByCode[promotionCode];
                
                        // Check that this promotion is from template 1
                        if (promotion.template !== '10') {
                            continue;
                        }
                
                        this.applyPromotion(promotion);
                    }
                }

                applyPromotion(promotion) {
                    let parameters = this.parameters;
              
                    let totalAmount = saleItemHelper.getSaleItemsTotalAmount(parameters.saleItems);
                    let totalQuantity = saleItemHelper.getSaleItemsTotalQuantity(parameters.saleItems);
              
                    // var total = this.calcuateSaleTotalAmount(promotion.allowWithOtherPromotions == 1);
              
                    if (Number(promotion.minimumBuyAmount) > totalAmount) {
                        return;
                    }
              
                    if (Number(promotion.minimumBuyQuantity) > totalQuantity) {
                        return;
                    }
                    if (promotion.discountType != PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT) {
                        return;
                    }
              
                    this.parameters.salePromotion = promotion; //just mark the sale promotion to be applied
                }
            }
        }
    }
}

