module PositiveTS {
	
	export module Service {

		export class DateDiff {
      dt1:Date;
      dt2:Date;
      diffMs:number
      diffDays:number
      diffHrs:number
      diffMins:number
      diffTotalMins:number

      constructor(dt1: Date, dt2: Date) {
        this.dt1 = dt1;
        if ( !dt2 ){
          dt2 = new Date();
        }
        this.dt2 = dt2;
        
        this.diffMs = (+dt2 - +dt1); // milliseconds between d2 & d1
        this.diffDays = Math.round(this.diffMs / 86400000); // days
        this.diffHrs = Math.round((this.diffMs % 86400000) / 3600000); // hours
        this.diffMins = Math.round(((this.diffMs % 86400000) % 3600000) / 60000); // minutes
        this.diffTotalMins = Math.round(this.diffMs / 60000); // minutes
      }
    }
  }
  
}