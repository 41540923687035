module PositiveTS {
    export module Storage {
        export module Entity {
            export class VendingMachineItemInventory extends IDBEntity {
                currentInventory: number
                vendingMachineItemId: number
                isOutOfStock: boolean
                itemCode: string

                constructor() {
                    let meta = {
                        name: 'VendingMachineItemInventory',
                        fields: {
                            currentInventory: "INT",
                            vendingMachineItemId: "INT",
                            isOutOfStock: "BOOL",
                            itemCode: "TEXT",
                        },
                    }
                    super(meta)
                }
            }
        }
    }
}
