  module PositiveTS {
    export module Application {
    export module Controllers {
    export class ReportViewController extends AbstractViewControllerTS {
      currentStep = 1
      wizard:any
      stepMap = {
        main: 1,
        priceChange: 2,
        deliveryNote: 3,
        monthlyTargetStand: 4,
        deadSupply: 5,
        storeMinus: 6
      }

      
  
  // --------------------------------------------------------------------
  // Controller Life-Cycle
  // --------------------------------------------------------------------
  init(options?) {
    var aThis = posReportsVC;
    
    // // Initialize the table views
    // aThis.initTableViews();

    // // Initialize the slideshow plugin which is used to mimic the wizard style flow
     aThis.initWizard();
  }
  initWizard() {
    var aThis = posReportsVC;

    // // Initialize the slideshow plugin which is used to mimic the wizard style flow
    if (aThis.wizard == null) {
      aThis.wizard = $('#report [data-role="content"]').bjqs({
        'width'     : '100%',
        'height'    : '100%',
        'showMarkers'   : false,
        'showControls'  : false,
        'centerMarkers' : false,
        'automatic'   : false,
        'useCaptions' : false,
        'keyboardNav' : false
      });
      $('#report [data-role="content"]').css({
          'width': ''
      });
      if(session.pos.isRoshemet){
        $("#report-promotions").hide();
        $("#report-exceptions").hide();
        $("#report-employee-time").hide();
        $("#report-bonus").hide();
        $("#report-employee-time-merukaz").hide();
        
      }
      else {
        $("#report-period-1").hide();
        $("#report-period-2").hide();
        $("#report-period-3").hide();
        $("#report-inv-1").hide();
        $("#report-main-settings").hide();
        $("#report-vouchers").hide();
      }
    }
  }
  changeStep(step) {
    let aThis = posReportsVC;

    // if (aThis.currentStep + 1 == step) {
    //   aThis.wizard.bjqsGo('forward', false);
    // } else if (aThis.currentStep - 1 == step) {
    //   aThis.wizard.bjqsGo('backward', false);
    // } else {
    //   aThis.wizard.bjqsGo(null, step);
    // }

    // aThis.currentStep = step;

    aThis.resume();
  }
  resume(options?) {
    // UGLY! Remove this when solving the resume issue in dialogs!!!
    employeeTimeReportVC.fromEmployeeSelect = false;
    employeeBounsReportVC.fromEmployeeSelect = false;
    // -- END of UGLY
  }
}
}}}

declare var posReportsVC:PositiveTS.Application.Controllers.ReportViewController;
posReportsVC = new PositiveTS.Application.Controllers.ReportViewController();
//add it to the list of the controllers to retain backward compatibility until we convert all controllers to be TS classes...
PositiveTS.Application.Controllers.instances.ReportViewController = posReportsVC;