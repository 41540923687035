

import { defineStore } from 'pinia';

const state = {
    promotion: {} as PositiveTS.Storage.Entity.Promotion,
    itemsBuy: [] as Array<object>,
    itemsGet: [] as Array<object>,
    itemsBuyToDeleteFromPromotion: [] as Array<object>,
    itemsGetToDeleteFromPromotion: [] as Array<object>,
    isNewPromotion: false as boolean,
    templates: [],
    discountTypes: [],
    buyOptions: [],
    buyOptionSelected: 'quantity',
    valueBuyOptionSelected: 1,
    errorOnSavePromotion: false,
}

const getters = {
    getErrorOnSavePromotion: (state) => {
        return state.errorOnSavePromotion
    },

    getPromotion: (state) => {
        return state.promotion
    },

    getItemsBuy: (state) => {
        return state.itemsBuy
    },

    getItemsGet: (state) => {
        return state.itemsGet
    },

    getItemsBuyToDeleteFromPromotion: (state) => {
        return state.itemsBuyToDeleteFromPromotion
    },

    getItemsGetToDeleteFromPromotion: (state) => {
        return state.itemsGetToDeleteFromPromotion
    },

    getIsNewPromotion: (state) => {
        return state.isNewPromotion
    },

    getTemplates: (state) => {
        return state.templates
    },

    getDiscountTypes: (state) => {
        return state.discountTypes
    },

    getBuyOptions: (state) => {
        return state.buyOptions
    },

    getBuyOptionSelected: (state) => {
        return state.buyOptionSelected
    },

    getValueBuyOptionSelected: (state) => {
        return state.valueBuyOptionSelected
    }

}


const actions = {

    SET_ERROR_ON_SAVE_PROMOTION(hasError: boolean) {
        this.errorOnSavePromotion = hasError
    },

    SET_PROMOTION(promotion) {
        this.promotion = PositiveTS.Storage.Entity.Promotion.convertPromotionToVueObject(promotion)
    },

    RESET_STATE() {
        this.promotion = {}
        this.itemsBuy = []
        this.itemsGet = []
        this.itemsBuyToDeleteFromPromotion = []
        this.itemsGetToDeleteFromPromotion = []
        this.isNewPromotion = false
        console.log('mutation promotionManage/RESET_STATE state: ', state)
    },

    SET_PROMOTION_PROPERTY(property) {
        let value = property.value
        if (['fromDate', 'toDate'].includes(property.key)) {
            value = moment(value).format('DD/MM/YYYY')
        }

        this.promotion[property.key] = value
        console.log('mutation promotionManage/SET_PROMOTION_PROPERTY state: ', state)
        console.log('mutation promotionManage/SET_PROMOTION_PROPERTY data: ', property)
    },

    SET_ITEMS_BUY(items) {
        this.itemsBuy = items
        console.log('mutation promotionManage/SET_ITEMS_BUY state: ', state)
        console.log('mutation promotionManage/SET_ITEMS_BUY data: ', items)
    },

    SET_ITEMS_GET(items) {
        this.itemsGet = items
        console.log('mutation promotionManage/SET_ITEMS_GET state: ', state)
        console.log('mutation promotionManage/SET_ITEMS_GET data: ', items)
    },

    SET_ITEMS_BUY_TO_DELETE_FROM_PROMOTION(items) {
        this.itemsBuyToDeleteFromPromotion = items
        console.log('mutation promotionManage/SET_ITEMS_BUY_TO_DELETE_FROM_PROMOTION state: ', state)
        console.log('mutation promotionManage/SET_ITEMS_BUY_TO_DELETE_FROM_PROMOTION data: ', items)
    },

    SET_ITEMS_GET_TO_DELETE_FROM_PROMOTION(items) {
        this.itemsGetToDeleteFromPromotion = items
        console.log('mutation promotionManage/SET_ITEMS_GET_TO_DELETE_FROM_PROMOTION state: ', state)
        console.log('mutation promotionManage/SET_ITEMS_GET_TO_DELETE_FROM_PROMOTION data: ', items)
    },

    SET_TEMPLATES(templates) {
        this.templates = templates
        console.log('mutation promotionManage/SET_TEMPLATES state: ', state)
        console.log('mutation promotionManage/SET_TEMPLATES data: ', templates)
    },

    SET_DISCOUNT_TYPES(discountTypes) {
        this.discountTypes = discountTypes
        console.log('mutation promotionManage/SET_DISCOUNT_TYPES state: ', state)
        console.log('mutation promotionManage/SET_DISCOUNT_TYPES data: ', discountTypes)
    },

    SET_BUY_OPTIONS(buyOptions) {
        this.buyOptions = buyOptions
        console.log('mutation promotionManage/SET_BUY_OPTIONS state: ', state)
        console.log('mutation promotionManage/SET_BUY_OPTIONS data: ', buyOptions)
    },

    SET_BUY_OPTION_SELECTED( buyOptionSelected) {
        this.buyOptionSelected = buyOptionSelected
        console.log('mutation promotionManage/SET_BUY_OPTION_SELECTED state: ', state)
        console.log('mutation promotionManage/SET_BUY_OPTION_SELECTED data: ', buyOptionSelected)
    },

    SET_BUY_VALUE_BUY_OPTION_SELECTED(valueBuyOptionSelected) {
        this.valueBuyOptionSelected = valueBuyOptionSelected
        console.log('mutation promotionManage/SET_BUY_VALUE_BUY_OPTION_SELECTED state: ', state)
        console.log('mutation promotionManage/SET_BUY_VALUE_BUY_OPTION_SELECTED data: ', valueBuyOptionSelected)
    },
    async setNewPromotion() {

        this.isNewPromotion = true

        let now = moment().format('DD/MM/YYYY');
        let incrementCode = await PositiveTS.Storage.Entity.Promotion.getLastCodePlusOne();

        let promotion = new PositiveTS.Storage.Entity.Promotion()
        //set default values
        promotion.code = incrementCode as any
        promotion.fromDate = now
        promotion.toDate = now
        promotion.isActive = true
        promotion.template = ''
        promotion.isClubMembersOnly = false
        promotion.allowMultipleTimesSameSale = true
        promotion.isAutomatic = true
        promotion.allowWithOtherPromotions = false
        promotion.hasBarcode = false
        promotion.priority = 1
        promotion.start_hour = 0
        promotion.start_minute = 0
        promotion.end_hour = 23
        promotion.end_minute = 59
        promotion.maxQuantityToGiveCustomer = null
        promotion.discountPercent1 = null
        promotion.discountPercent2 = null
        promotion.discountPercent3 = null
        promotion.discountPercent4 = null
        promotion.customerGroupId = null
        promotion.dependantCodes = []
        promotion.discountType = 'Fix'
        promotion.minimumBuyAmount = 1
        promotion.minimumBuyQuantity = 1
        promotion.clubMemberPromotionType = null

        await this.setPromotionWithItemsBuyAndGet({ promotion, saveToDBOnEmptyData: false });

    },

    async setPromotion(promotion: PositiveTS.Storage.Entity.Promotion) {
        await this.setPromotionWithItemsBuyAndGet({ promotion, saveToDBOnEmptyData: false });
    },

    addItemToDeleteFromPromotion( payload) {
        let type = payload.type
        let item = payload.item
        let items = type == 'buy' ? this.getItemsBuyToDeleteFromPromotion : this.getItemsGetToDeleteFromPromotion

        if (!items.includes(item.id)) {
            // Directly mutating the state
            items.push(item.id);
            if (type == 'buy') {
              this.SET_ITEMS_BUY_TO_DELETE_FROM_PROMOTION(items);
            } else {
              this.SET_ITEMS_GET_TO_DELETE_FROM_PROMOTION(items);
            }
          }
        //need to delete from items state
        let promotionItems = type == 'buy' ? this.getItemsBuy : this.getItemsGet

        let indexOfItem = promotionItems.findIndex(i => i.id == item.id || i.code == item.code)

        if (indexOfItem >= 0) {
            promotionItems.splice(indexOfItem, 1)

            if (type == 'buy') {
                this.SET_ITEMS_BUY(promotionItems)
            } else {
                this.SET_ITEMS_GET(promotionItems)
            }
        }

    },

    addItemToAddPromotion( payload) {
        let type = payload.type
        let item = payload.item
        let items = type == 'buy' ? this.getItemsBuy : this.getItemsGet
        let indexOfItem = items.findIndex(i => i.id == item.id || i.code == item.code)

        if (0 > indexOfItem) {
            items.push({
                id: item.id,
                code: item.code,
                name: item.description
            })

            if (type == 'buy') {
                this.SET_ITEMS_BUY(items)
            } else {
                this.SET_ITEMS_GET(items)
            }
        }

    },

    //remove promotion code from local items
    async removePromotionFromItems() {

        let itemsBuy = this.getItemsBuyToDeleteFromPromotion
        let itemsGet = this.getItemsGetToDeleteFromPromotion
        let promotion = this.getPromotion
        let promosItem = null

        for (let item of session.allItems.values()) {

            let itemsBuyIncludesItem = itemsBuy.includes(item.id)

            if (itemsBuyIncludesItem) {

                promosItem = item.promoBuy.split('&')

                let indexOfPromo = promosItem.findIndex(p => p == promotion.code)

                if (indexOfPromo >= 0) {
                    promosItem.splice(indexOfPromo, 1)
                }

                item.promoBuy = promosItem.join('&')

            }

            let itemsGetIncludesItem = itemsGet.includes(item.id)

            if (itemsGetIncludesItem) {

                promosItem = item.promoGet.split('&')

                let indexOfPromo = promosItem.findIndex(p => p == promotion.code)

                if (indexOfPromo >= 0) {
                    promosItem.splice(indexOfPromo, 1)
                }

                item.promoGet = promosItem.join('&')

            }

            if (itemsGetIncludesItem || itemsBuyIncludesItem) {
                let res = await PositiveTS.Storage.Entity.Item.saveToDB(item);
            }

        }

    },

    //add promotion code from local items
    async addPromotionToItems() {

        let itemsBuy = this.getItemsBuy.map(({ id }) => id)
        let itemsGet = this.getItemsGet.map(({ id }) => id)
        let promotion = this.getPromotion
        let promosItem = null

        for (let item of session.allItems.values()) {

            let itemsBuyIncludesItem = itemsBuy.includes(item.id)

            if (itemsBuyIncludesItem) {

                promosItem = item.promoBuy.split('&')

                if (!promosItem.includes(promotion.code)) {
                    promosItem.push(promotion.code)
                }

                item.promoBuy = promosItem.join('&')

            }

            let itemsGetIncludesItem = itemsGet.includes(item.id)

            if (itemsGetIncludesItem) {

                promosItem = item.promoGet.split('&')

                if (!promosItem.includes(promotion.code)) {
                    promosItem.push(promotion.code)
                }

                item.promoGet = promosItem.join('&')

            }

            if (itemsGetIncludesItem || itemsBuyIncludesItem) {
                let res = await PositiveTS.Storage.Entity.Item.saveToDB(item);
            }

        }

    },

    async setPromotionWithItemsBuyAndGet(payload) {

        let promotion = payload.promotion
        let saveToDBOnEmptyData = payload.hasOwnProperty('saveToDBOnEmptyData') ? payload.saveToDBOnEmptyData : false

        if (promotion.data) {

            let salim = JSON.parse(promotion.data).salim

            if (salim) {
                let itemsBuy = salim[0][0].manualySelectedRows
                let itemsGet = salim[1][0].manualySelectedRows
                this.SET_ITEMS_BUY(itemsBuy)
                this.SET_ITEMS_GET(itemsGet)
            }

        } else {

            let data = PositiveTS.Storage.Entity.Promotion.findSalimByPromotion(promotion)

            if (data.salim) {
                let itemsBuy = data.salim[0][0].manualySelectedRows
                let itemsGet = data.salim[1][0].manualySelectedRows
                this.SET_ITEMS_BUY(itemsBuy)
                this.SET_ITEMS_GET(itemsGet)
            }

            promotion.data = JSON.stringify(data)
            await this.SET_PROMOTION(promotion)

            if (saveToDBOnEmptyData) {
                await this.saveToDB()
            }

        }

        if (this.getErrorOnSavePromotion == false) {
            await this.SET_PROMOTION(promotion)
        }
    },

    async setItemsToSalimOnPromotionData() {
        let promotion = this.getPromotion
        let data = JSON.parse(promotion.data)

        data.salim[0][0].manualySelectedRows = this.getItemsBuy
        data.salim[1][0].manualySelectedRows = this.getItemsGet
        promotion.data = JSON.stringify(data)
        await this.SET_PROMOTION(promotion)

    },

    async saveToDB() {
        await this.SET_ERROR_ON_SAVE_PROMOTION(false) 
        this.removePromotionFromItems()
        this.addPromotionToItems()
        this.setItemsToSalimOnPromotionData()

        let promotion = this.getPromotion

        let res = await PositiveTS.Storage.Entity.Promotion.saveToDB(promotion)
        if (!res.success) {
            await this.SET_ERROR_ON_SAVE_PROMOTION(true)
        }
    }

}

const promotionsManageStore = defineStore('promotionsManage', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
  });
  
  export default promotionsManageStore;
