module PositiveTS {

  export module Printing {

    export abstract class AFactory {
      protected _sale: any;
      protected _company: any;
      protected _salePayments: any;

      protected _aThis = printer;
      protected _zebra = printer.jzebra;

      constructor(sale: any, company: any, salePayments: any) {
        this._sale = sale;
        this._company = company;
        this._salePayments = salePayments;
        this._aThis = printer;
        this._zebra = printer.jzebra;
      }

      protected lineFeed(): void {
        var aThis = this._aThis;
        aThis.printLine(" ");
      }

      protected printHeaderForSlipType(slipType: string): void {
        var aThis = this._aThis;
        var zebra = this._zebra;
        Printing.Invoice.printSlipHeader(this._sale, this._company);

        zebra.append(aThis.allowComponents.centerAlignment);
        zebra.append(aThis.allowComponents.bold);
        aThis.printText(Invoice.getSaleNumberFormated(this._sale));

        aThis.printLine(slipType);
        this.lineFeed();


        zebra.append(aThis.allowComponents.init);
      }

      protected printInvoiceNumberBold(preText: string): void {
        var aThis = this._aThis;
        var zebra = this._zebra;
        var sale = this._sale;
        zebra.append(aThis.allowComponents.centerAlignment);
        zebra.append(aThis.allowComponents.bold);
        aThis.printText(Invoice.getSaleNumberFormated(sale));
        aThis.printLine(preText);

      }


    }
  }
}
