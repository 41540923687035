module PositiveTS {
export module Storage {
export module Entity {
	export class Entity {
	public meta:any;
	public _data:any;
	public _new:boolean;
	public id:string;
	public timestamp:number;

	constructor(meta) {
		var aThis = this;
		this.meta = meta;
		this._data = {};
		this._new = true;
		this.id = storage.createUUID();
		this.timestamp = -1;

		if (!('meta' in this) || meta == null) {
			return;
		}

		for (var field in this.meta.fields) {
			// -- Initialize data for field
			switch (this.meta.fields[field]) {
			case 'TEXT':
				this._data[field] = '';
				break;
			case 'INT':
				this._data[field] = 0;
				break;
			case 'FLOAT':
				this._data[field] = 0;
				break;
			case 'BOOL':
				this._data[field] = false;
				break;
			case 'JSON':
				this._data[field] = '""';
				break;
			}

			// -- Define getter and setter for field
			(function () {
				var f = field;
				if (aThis.meta.fields.hasOwnProperty(field)) {
					aThis.defineProp(aThis, field, function (value) {

						// Allow using null
						if (aThis.isValueEmpty(value)) {
							aThis._data[f] = null;
							return;
						}
						// --- Setter -----------------------------
						// Convert value to the type of field. Throws an error on failure.
						var newValue = null;
						switch (aThis.meta.fields[f]) {
							case 'TEXT':
								if (aThis.isMoneyField(f) && session.isNumber(value)) {
									value = session.fixedFloat(value);
								}

								newValue = '' + value;
								break;
							case 'INT':
								newValue = parseInt(value);
								if (isNaN(newValue)) {
									throw new TypeError('Value cannot be converted to INT');
								}
								break;
							case 'FLOAT':
								if (aThis.isMoneyField(f) && session.isNumber(value)) {
									value = session.fixedFloat(value);
								}

								newValue = parseFloat(value);
								if (isNaN(newValue)) {
									throw new TypeError('Value cannot be converted to FLOAT');
								}
								break;
							case 'BOOL':
								if (value == true || value == 'true' || value == 'Y') {
									newValue = 1;
								} else if (value == false || value == 'false' || value == 'N') {
									newValue = 0;
								} else {
									throw new TypeError('Value cannot be converted to BOOL');
								}
								break;
							case 'JSON':
								// --- Check that the value is a JSON
								// If it is a string, check whether the string is a JSON
								try {
									// Parse the string
									JSON.parse(value);

									// --- It is a JSON
									// Don't process the value
									newValue = value;
								} catch (e) {
									// --- It is not a JSON
									// Make it a JSON
									try {
										newValue = JSON.stringify(value);
									} catch (e) {
										throw new TypeError('Value is not valid JSON');
									}
								}
								break;
						}

						aThis._data[f] = newValue;
					}, function () {
						// --- Getter -----------------------------
						var val = aThis._data[f];

						// Fix empty fields
						if (aThis.isValueEmpty(val)) {
							switch (aThis.meta.fields[f]) {
								case 'BOOL':
									return false;
							}
							return null;
						}
						// Fix money floating point
						if (aThis.isMoneyField(f) && session.isNumber(val)) {
							switch (aThis.meta.fields[f]) {
								case 'TEXT':
									val = session.fixedFloat(val);
									val = '' + val;
									break;
								case 'FLOAT':
									val = session.fixedFloat(val);
									val = parseFloat(val);
									break;
							}
						}
						switch (aThis.meta.fields[f]) {
							case 'BOOL':
								return Boolean(val);
							default:
								return val;
						}



					});
				}
			}());
		}
	}

	isValueEmpty(v) {
		return v === null || v === undefined || v === "null" || v === "undefined" || v === "";
	}

	isMoneyField(f) {
		if (!Array.isArray(this.meta.money)) {
			return false;
		}

		return this.meta.money.indexOf(f) > -1;
	}

	getCreateTableSql() {
		var aThis = this;

		var fieldString = '', primaryKeyField;
		for (var field in this.meta.fields) {
			fieldString = fieldString + ', `' + field + '` ' + this.meta.fields[field];
		}

		var uniqueString = '';
		if (('unique' in this.meta) && this.meta.unique.length > 0) {
			uniqueString = this.meta.unique.join(',');
			uniqueString = ', UNIQUE(' + uniqueString + ') ON CONFLICT FAIL';
		}
		if (this.meta.primaryKey) {
			primaryKeyField = this.meta.primaryKey;
		}
		else {
			primaryKeyField = 'id';
		}

		let sql = 'CREATE TABLE IF NOT EXISTS `' + aThis.meta.name + '` (`' + primaryKeyField + '` TEXT PRIMARY KEY' + fieldString + ', `timestamp` INT' + uniqueString + ');'

		return sql;
	}

	createTable(tx) {
		throw new Error('If you here, you did something wrong. there are not supposed to be tables in the web db');
	}

	defineProp(scope, field, setterCallback, getterCallback) {
		scope.__defineSetter__(field, function (value) {
			setterCallback(value);
		});
		scope.__defineGetter__(field, function () {
			return getterCallback();
		});
	}

	equals(entity) {
		// Try to check that entity is indeed an entity
		if (!('meta' in entity)) {
			return false;
		}

		// Check that both have the same name
		if (this.meta.name != entity.meta.name) {
			return false;
		}

		// This entity does not use unique? So compare the entire data
		for (var field in this.meta.fields) {
			if (this.meta.fields.hasOwnProperty(field)) {
				// Check that the data for this field is identical
				if (this[field] != entity[field]) {
					return false;
				}
			}
		}

		// They are equal!
		return true;
	}

	static staticImport<T extends Entity>(entity: {new(): T;},objectToImport):T {
		let ent = new entity();
		return ent.importFromObject(objectToImport)
	}

	importFromObject(importFrom) {
		// Check that all fields exists in the array
		for (let field in this.meta.fields) {
			//why do this check??? worst case the field will be undefined...
			//it costs performance in each object instansiation
			// if (!(field in importFrom)) {
			// 	//throw new Error('Cannot import, there is a missing field (' + field + ')');
			// }
			this[field] = importFrom[field];
		}

		// If there is an ID, mark the entity as not new
		if ('id' in importFrom) {
			this.id = importFrom.id;
			this.timestamp = importFrom.timestamp;
			this._new = false;
		}
		return this;
	};

	exportToObject() {
		// Initialize the object
		var objectExport = {};

		// Add the fields with their data to the object
		for (var field in this.meta.fields) {
			objectExport[field] = this[field];
		}

		// If this entity is new, don't export the ID since the ID is not from the db
		if (!this._new) {
			objectExport['id'] = this.id;
			objectExport['timestamp'] = this.timestamp;
		}

		return objectExport;
	}

	clone() {
		let itemCopy = new (<any>this).constructor()
    	itemCopy.importFromObject(this.exportToObject())
    	return itemCopy
	}

	getCache<T extends Entity>(fieldName:string):Promise<Array<T>> {
		throw new Error('If you here, you did something wrong. there are not supposed to be tables in the web db');
	}

	clearCache<T>(fieldName:string){
		(this.constructor)[fieldName] = null
	}

	persist(tx?) {
		var aThis = this;
		var placeholderArray, fieldsString, placeholdersString, primaryKeyField;

	
		return new Promise((resolve,reject) => {
			// Update the timestamp
			this.timestamp = PositiveTS.DateUtils.timestamp();

			// If the entity is new, insert it. Otherwise, update it.
			if (this._new) {
				// Create list of fields and placeholders
				fieldsString = '';
				placeholdersString = '';
				placeholderArray = [];
				for (var field in this.meta.fields) {
					fieldsString = fieldsString + field + ', ';
					placeholdersString = placeholdersString + '?, ';
					placeholderArray[placeholderArray.length] = this._data[field];
				}
				fieldsString = fieldsString.substr(0, fieldsString.length-2);
				placeholdersString = placeholdersString.substr(0, placeholdersString.length-2);

					// Add the id and timestamp as the last elements in the placeholders array
				placeholderArray[placeholderArray.length] = this.timestamp;
				if (this.meta.primaryKey) {
					placeholderArray[placeholderArray.length] = this._data[this.meta.primaryKey];
					primaryKeyField = this.meta.primaryKey;
				}
				else {
					placeholderArray[placeholderArray.length] = this.id;
					primaryKeyField = 'id';
				}

				tx.executeSql('INSERT INTO ' + aThis.meta.name + ' (' + fieldsString + ', timestamp, ' + primaryKeyField + ') VALUES (' + placeholdersString + ', ?, ?)',
					placeholderArray,
					function (tx, result) {
						aThis._new = false;
						// posUtils.logTime('persist','end');
						//callback.successWithObject({entity: aThis});
						resolve({entity: aThis});
					}, function (tx, error) {
						console.error(fieldsString);
						console.error(placeholderArray);
						// posUtils.logTime('persist','end');
						console.error(error);
						//callback.error(error);
						reject(error);
						return true;
					}
				);
			} else {
				// Create list of fields and placeholders
				fieldsString = '';
				placeholderArray = [];
				for (var field in this.meta.fields) {
					fieldsString = fieldsString + field + ' = ?, ';
					placeholderArray[placeholderArray.length] = this._data[field];
				}
				fieldsString = fieldsString.substr(0, fieldsString.length-2);

				// Add the id and timestamp as the last elements in the placeholders array
				placeholderArray[placeholderArray.length] = this.timestamp;
				if (this.meta.primaryKey) {
					placeholderArray[placeholderArray.length] = this._data[this.meta.primaryKey];
					primaryKeyField = this.meta.primaryKey;
				}
				else {
					placeholderArray[placeholderArray.length] = this.id;
					primaryKeyField = 'id';
				}

				tx.executeSql('UPDATE ' + aThis.meta.name + ' SET ' + fieldsString + ', timestamp = ? WHERE ' + primaryKeyField +' = ?',
					placeholderArray,
					function (tx, result) {
						// posUtils.logTime('persist','end');
						//callback.successWithObject({entity: aThis});
						resolve({entity: aThis});
					}, function (tx, error) {
						// posUtils.logTime('persist','end');
						console.error(error);
						//callback.error(error);
						reject(error);
						return true;
					}
				);
			}
		})

	}

	delete(tx) {
		throw new Error('If you here, you did something wrong. there are not supposed to be tables in the web db');
	}

	findByIdNew(id) {
		throw new Error('If you here, you did something wrong. there are not supposed to be tables in the web db');
	}

	all(tx = null):Promise<any[]> {
		var aThis = this;
		
		return new Promise((resolve,reject) => {
			tx.executeSql('SELECT * FROM ' + aThis.meta.name,
				[],
				function(tx, result) {
					let items = new Array();
					for (var i = 0; i < result.rows.length; i++) {
						var entity = new (<any>aThis).constructor();
						entity.importFromObject(result.rows.item(i));
						items[i] = entity;
					}
					resolve(items);
				}, function(tx, error) {
					reject(error);
					return true;
				}
			);
		})
	}


	static forEachModelEntity(callback) {

		for (var entity in PositiveTS.Storage.Entity) {
			if (PositiveTS.Storage.Entity.hasOwnProperty(entity)) {
				if (entity === "Entity" || entity === "IDBEntity" || entity === "WasmEntity" || entity === "ConvertedTable") {
					continue;
				}

				callback(entity);
			}
		}
	}

	
    static convertResultsToEntity(result) {
		return result.map(entity => {
		  // creates an object from the child class
		  let convertedEntity = new (Object.create(this.prototype)).constructor
  
		  convertedEntity.importFromObject(entity);
  
		  return convertedEntity;
		})
	}

}}}}
