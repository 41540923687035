module PositiveTS {
    export module Components {
        export module LoadingMessageWithCancel {
        
          const dialogSelector = 'loading-message-dialog';
          function initData() {
            const data: {
                isShow: boolean,
                message: string,
                buttonText: string,
                buttonClass: string,
                handlerCancel: any, 
                handlerParams: Array<any>
                whiteTheme: boolean,
                dialogElem: Element
            } = {
                isShow: false,
                message: '',
                buttonText: i18next.t('cancel'),
                buttonClass: 'positive-simple red shadow',
                handlerCancel: null,
                handlerParams: null,
                whiteTheme: false,
                dialogElem: document.getElementById(dialogSelector),

            }

            return data;
          }
    
          export function create() {
    
            let component = {
              template: JST.LoadingMessageWithCancel(),
              methods: {
                cancel () {
                    if (typeof this.handlerCancel === 'function'){
                        this.handlerCancel.apply(null, this.handlerParams);
                    }
                    this.close();
                },
                
                close () {
                  this.$el.close();
                },
        
                open(options, handlerCancel = null, handlerParams = []) {
                    const defualtValues = initData();
                    if (typeof options != 'object'){
                        options = {}
                    }

                    if (options?.fontSize){
                      this.$el.style.setProperty('--loading-message-with-cancel-text-font-size', options.fontSize)
                    }else{
                      this.$el.style.setProperty('--loading-message-with-cancel-text-font-size', '1rem')
                    }
                    this.message = options.message || defualtValues.message;
                    this.buttonText = options.buttonText || defualtValues.buttonText;
                    this.buttonClass = options.buttonClass || defualtValues.buttonClass;
                    this.whiteTheme = options.whiteTheme || defualtValues.whiteTheme;
                    this.handlerCancel = handlerCancel;
                    this.handlerParams = handlerParams;
                    this.isShow = true;
                    this.$el.showModal();
                }
              },
              data: initData
            }
    
            VueApp.component('loading-message-with-cancel',component)
          }
        }
      }
    }
    
