module PositiveTS {
  export module Service {
    export module Ajax {
      export function promiseJqAjax(sUrl, sBody, sType):Promise<any> {
        PositiveTS.Reachability.ignoreCheckReachability  = true;
        return new Promise((resolve, reject) => {
          jQuery.ajax({
            type: sType,
            url: sUrl,
            data: sBody
          }).then((data, textStatus, jqXHR) => {
            PositiveTS.Reachability.ignoreCheckReachability  = false;

            let _return:any ={};
            _return.request = {};
            _return.request.body = sBody;
            _return.request.result = jqXHR.responseText;
            _return.request.status = jqXHR.status;
            _return.request.url = sUrl;

            //delete jqXHR.then; // treat xhr as a non-promise
            resolve(_return);
          }, (jqXHR, textStatus, errorThrown) => {
            PositiveTS.Reachability.ignoreCheckReachability  = false;

            let _return:any = {};
            _return.request = {};
            _return.request.body = sBody;
            _return.request.result = jqXHR.responseText;
            _return.request.status = jqXHR.status;
            _return.request.url = sUrl;

            //delete jqXHR.then; // treat xhr as a non-promise
            reject(_return);
          });
        });

      }
    }
  }
}
