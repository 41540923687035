module PositiveTS {
  export module Components {
    export module selfServiceMigvanSelect {

      
      const _vueComponentName = 'self-service-migvan-select-dialog';

      export function create() {
        let SelfServiceMigvanSelect = {
          template: JST.selfServiceMigvanSelect(),
          setup(){

            const globalStore = Pinia.useGlobalStore();

            const {migvanUpdated} = globalStore
            const globalStoreActions = {migvanUpdated}


            return {...globalStoreActions}
          },
          methods: {
            open() {
              this.$el.showModal();
            },
            isActive() {
              return Service.MultipleMigvans.isActive();
            },
            getMigvans() {
              let allMigvansById = _.keyBy((new Storage.Entity.Migvan()).allWithoutPromise(), 'id');
              let relaventMigvans = [];

              let posMigvans = jsonConfig.getVal(jsonConfig.KEYS.migvanIds) || [];

              for (let migvanId of posMigvans) {
                if (allMigvansById[migvanId]) {
                  relaventMigvans.push(allMigvansById[migvanId]);
                }
              }


              return relaventMigvans;
            },
            getMigvanStyle(migvan) {

              let style:any = {};

              if (!posUtils.isBlank(migvan.color)) {
                style['color'] = migvan.color;
                style['border-color'] = migvan.color;
              }

              return style;
            },
            async setMigvanAndFinish(migvan) {
              localStorage.setItem('lastKnownMigvan', migvan.id)
              this.migvanUpdated(migvan.id);
              await this.$nextTick();
              this.close();
            },
            close() {
              this.$el.close();
            },
          }
        }
        VueApp.component(_vueComponentName, SelfServiceMigvanSelect)
      }
    }
  }
}
