module PositiveTS {
    
    export module Components {
        
        export module PickupShipsFormDialog {
            interface validateObject {
                valid: Boolean,
                errors: Array<String>
            }


            export function create() {
                VueApp.component('pickup-ships-form-dialog', {
                    template: JST.PickupShipsFormDialog(),
                    mixins: [Mixins.modalsMixin],
                    data() {
                        return {
                            resolveFunc: null,
                            rejectFunc: null,
                            pickup: {
                                passengerName: '',
                                roomNumber: '',
                            }        
                        }
                    },
                    methods: {
                        open() {
                            this.fetchPickupFromSale()
                            this.$el.showModal()
            
                            this.$nextTick(() => {
                                $(document).unbind('keypress')
                            })
                            
                            return new Promise((resolve,reject) => {
                                this.resolveFunc = resolve
                                this.rejectFunc = reject
                            })
                        },
                        cancel() {
                            this.resolveFunc('cancel')
                            this.close()
                        },
                        close() {
                            this.$el.close()
                            $(document).unbind('keypress')
                            $(document).keypress(posVC.onKeyPress)
                        },
                        confirm() {          
                            const validate = this.validate()
                            
                            if (validate.valid){
                                Service.Pickup.setPickupDataOnSale(this.pickup)
                                this.resolveFunc('ok')
                                this.close()
                            }else{
                                this.showErrors(validate.errors)
                            }
                        },
                        showErrors(errors: Array<string>) {
                            this.showAlertGenericDialog(errors.join("\n"))
                        },
                        fetchPickupFromSale() {
                            let pickup = PositiveTS.Service.Pickup.getPickupOnSale();
                            if (pickup){
                                this.pickup = pickup
                                return;
                            }
            
                            this.pickup = {passengerName: '', roomNumber: ''};
                        },
                        validate(): validateObject {
                            let errors = []
            
                            if (posUtils.isBlank(this.pickup.passengerName)){
                                errors.push(i18next.t('validate.required', {input: i18next.t('pickupFormDialog.passengerName')}))
                            } else if ((this.pickup.passengerName || '').trim().length < 2){
                                errors.push(i18next.t('validate.minCharLength', {input: i18next.t('pickupFormDialog.passengerName'), min: 2}))
                            }
                            
                            if (posUtils.isBlank(this.pickup.roomNumber)){
                                errors.push(i18next.t('validate.required', {input: i18next.t('pickupFormDialog.roomNumber')}))
                            } else if ((this.pickup.roomNumber || '').trim().length > 5){
                                errors.push(i18next.t('validate.maxCharLength', {input: i18next.t('pickupFormDialog.roomNumber'), max: 5}))
                            }

                            return {valid: errors.length ? false : true, errors}
                        },
                        nextInput (ref: string): void {
                            this.$refs[ref].focus()
                        },
        
                    }
                });
            }

        }

    }

}
