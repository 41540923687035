module PositiveTS {
  export module Service {
    export class Sms {
      static SMS_URL = "/sales/send_sms";
      static SMS_MESSAGES_PER_INVOICE = 2;
      static GENERIC_SMS_URL = "/sms/send_single_sms"

      static async openPhoneDialogAndSendInvoiceSms(sale) {
        if (!this.validateCanSendSmsAndShowErrors(sale)) {
          return;
        }

        await Pinia.componentsStore.openComponent( {componentName:"sendInvoiceSmsDialog", args: [sale, this.sendSmsRequestToServer.bind(this), false]});
      }

      static async addToSaleSmsPhoneNumberIfNeeded(sale) {
        if (jsonConfig.getVal(jsonConfig.KEYS.sendSmsAtSaleClose) && jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice)) {

          app.hideLoadingMessage();

          if (Pinia.globalStore.isOnline && this.getReminderQuota() < this.SMS_MESSAGES_PER_INVOICE) {
            if(PositiveTS.Service.OsherAd.isEnabled()){
              return;
            }
            await app.showAlertDialog({
              header: i18next.t('warning'),
              content: i18next.t('sendInvoiceSmsDialog.noMessagesLeft'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
            return;    
          }
 

          let phoneDialogResult = await Pinia.componentsStore.openComponent( {componentName:"sendInvoiceSmsDialog", args: [sale, null, true]});
          if (phoneDialogResult != null) {
            let {print, phone} = phoneDialogResult

            if (!posUtils.isBlank(phone)) {
              let jsonDataDict = posUtils.isBlank(sale.jsondata) ? {} : JSON.parse(sale.jsondata);
              jsonDataDict.smsPhoneNumber = phone;
              sale.jsondata = JSON.stringify(jsonDataDict);
            }

            if(!print) {
              let jsonDataDict = posUtils.isBlank(sale.jsondata) ? {} : JSON.parse(sale.jsondata);
              jsonDataDict.printSmsInvoice = false;
              sale.jsondata = JSON.stringify(jsonDataDict);
            }

            this.setReminderQuota(this.getReminderQuota() - this.SMS_MESSAGES_PER_INVOICE);

            if (Pinia.globalStore.isOnline) {
              await this.promiseAlertIfQuotaIsLow();
            }
          }

          app.showLoadingMessage(i18next.t("closingSale"));

        }
      }

      static async sendSmsRequestToServer(sale, phone) {
        try {
          app.showLoadingMessageDialog(i18next.t('sendInvoiceSmsDialog.requestServerMessage'))

          let response = await PositiveTS.Service.FetchReq.jsonReq(this.SMS_URL, 'post', {
            sale_invoice: sale.invoiceSequence,
            pos_device_id: sale.posDeviceID,
            phone: phone});

          if (!(response.result && response.result.success)) {

            if (response.result.errors) {

              let errors = Object.values(response.result.errors);

              if (errors.length > 0 && (errors[0] as any).length > 0) {
                app.hideLoadingMessageDialog();
                this.showError(errors[0][0]);
                return false;
      
              } else {
                throw new Error(response.result);
              }

            } else {
              throw new Error(response.result);
            }

          }

          app.hideLoadingMessageDialog();

          let qouta = this.setReminderQuota(response.result.remain_qouta);
          this.promiseAlertIfQuotaIsLow(qouta);

          return true;
        } catch(err) {
          app.hideLoadingMessageDialog();
          this.showError(i18next.t('sendInvoiceSmsDialog.requestFailed'));
          return false;
        }
      }

      static async sendGenericSms(phoneNumber: string, smsMessage: string): Promise<void> {
        try {
          app.showLoadingMessageDialog(i18next.t('sms.requestServerMessage'))

          if (!Pinia.globalStore.isOnline) {
            app.hideLoadingMessageDialog()
            this.showError(i18next.t('sms.mustBeOnline'));
            return;
          }

          await this.promiseAlertIfQuotaIsLow()

          if(this.getReminderQuota() === 0){
            app.hideLoadingMessageDialog()
            return
          }

          let response = await PositiveTS.Service.FetchReq.jsonReq(this.GENERIC_SMS_URL, 'POST', {
            message: smsMessage,
            phone: phoneNumber});

            if(!response.response.ok) {
              app.hideLoadingMessageDialog()
              this.showError(response.result.error)
              return
            }

            if(response.result.success){
              app.hideLoadingMessageDialog()
              return app.showAlertDialog({
                content: response.result.message,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            }

        } catch (error) {
          app.hideLoadingMessageDialog()
          this.showError(error)
        }
      }

      static setReminderQuota(quota = 0) {
        window.localStorage.setItem('remainingQuota', String(quota));

        return quota;
      }

      static getReminderQuota() {
        return parseInt(window.localStorage.getItem('remainingQuota'));
      }

      static promiseAlertIfQuotaIsLow(remainingQuota = null): Promise<any> {
        if (remainingQuota === null) {
          remainingQuota = this.getReminderQuota();
        }

        if ((remainingQuota <= jsonConfig.getVal(jsonConfig.KEYS.smsAlertIfQuotaBelow) &&
          Math.floor(((remainingQuota + 1) % jsonConfig.getVal(jsonConfig.KEYS.smsAlertEveryXMessagesIfLowQuota)) / this.SMS_MESSAGES_PER_INVOICE) == 0) || jsonConfig.getVal(jsonConfig.KEYS.smsAlertEveryMessageIfQuotaBelow) >= remainingQuota) {
            if(PositiveTS.Service.OsherAd.isEnabled()){
              // Yuli said dont show this message for osher ad so we return
              return Promise.resolve();
            }
            return app.showAlertDialog({
              header: i18next.t('warning'),
              content: i18next.t('sendInvoiceSmsDialog.lessThenXMessagesLeft', {quota: remainingQuota}),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
        }

        return Promise.resolve();
      }

      static showError(errorText) {
        return app.showAlertDialog({
          header: i18next.t('error'),
          content: errorText,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        })
      }

      static validateCanSendSmsAndShowErrors(sale) {
        if (!jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice)) {
          this.showError(i18next.t('sendInvoiceSmsDialog.notAllowed'));
          return;
        }

        if (!Pinia.globalStore.isOnline) {
          this.showError(i18next.t('sendInvoiceSmsDialog.mustBeOnline'));
          return false;
        }

        if (!sale) {
          this.showError(i18next.t('sendInvoiceSmsDialog.saleNotSelected'));
          return false;
        }

        if (sale.syncStatus != PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY) {
          this.showError(i18next.t('sendInvoiceSmsDialog.saleNotSychronized'));
          return false;
        }

        return true;
      }
    }
  }
}
