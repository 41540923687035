module PositiveTS {
export module Service {
export module CloseSale {

	export async function closeSale():Promise<any>{
		if (PositiveTS.Service.icMega.hasIcMegaItems() && PositiveTS.Service.icMega.checkIfCurrentSaleIsIcMegaItem()){
			let result = await PositiveTS.Service.icMega.useBarcodesOnSalePayment(posVC.salePayments[0])

			if(!result.success) {
				app.showAlertDialog({
					header: i18next.t('error'),
					content: result.error || i18next.t('generalError'),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				})

				return
			}
		}

		if(Service.OsherAd.isEnabled()){
			Service.OsherAd.closeSale();
		}

		if(session.pos.isVendingMachinePos){
			let vendingMachine = Pinia.vendingMachine;
			let VendingSaleStatus =  await vendingMachine.closeSale();
			if(!VendingSaleStatus){
				await PositiveTS.Service.SalePaymentsService.cancelAllPayments()
				await posVC.restartSale();
				return;
			}
		}

		if (PositiveTS.Service.SimplyClubService.isTranReqExistsOnSale(FullSale.posVCSale.sale)) {
			try {
				let res = await PositiveTS.Service.SimplyClubService.tranEndWithSale(FullSale.posVCSale.sale);
				if (res.ErrorCode !== 0) {
					throw new Error(res.ErrorCode + " - " + res.ErrorMessage);
				}
			} catch (error) {
				console.error("SimplyClub ::: TranEnd ::: Error ", error);
				app.showErrorAlert(i18next.t('simplyClubBenefits.errorOccuredWhileClosingSale'));
				return;
			}
		}

		if(jsonConfig.getVal(jsonConfig.KEYS.nirDavidScanEntranceBarcode) && !posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.nirDavidEntranceItemCode)) && Pinia.globalStore.isNirDavidEntranceSale){
			let success = await PositiveTS.Service.NirDavidService.startScanningBarcodes()
			Pinia.globalStore.setShowNirDavidEntranceDialog(true);

			if(!success){
				app.hideLoadingMessage()
				await PositiveTS.Service.SalePaymentsService.cancelAllPayments()
				await posVC.restartSale();
				return
			}
		}

		if(Pinia.globalStore.multipassPendingLoadBudgetSale) {
			let result = await Service.MultipassLoadBudget.loadCardBudget();

			if(!result.success) {
				app.showAlertDialog({
					header: i18next.t('error'),
					content: result.error,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				})
				return;
			}
		}

		if(jsonConfig.getVal(jsonConfig.KEYS.shekuloTovBooksService) && Service.ShekuloTovBooks.checkBarcodesLength()) {
			try {
				app.showLoadingMessage(i18next.t('shekuloTovBooks.sendingSaleInfo'));
				let result = await Service.ShekuloTovBooks.prepareBarcodesAndSend()

				if(result.error !== null){
					app.hideLoadingMessage()
					app.showAlertDialog({
						header: i18next.t('error'),
						content: result.error,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true,
					})

					return
				}

				Pinia.globalStore.handleShekuloTovBarcodes({type: "clear"})
				// No hide loading message on purpuse, need to be continued until close sale loading message
				// if needed, do hide loading message when needed
			} catch (e){
				app.hideLoadingMessage()
				app.showAlertDialog({
					header: i18next.t('error'),
					content: i18next.t(`shekuloTovBooks.genericCommunicationError`),
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true,
				})

				throw e
			}
		}


		if (jsonConfig.getVal(jsonConfig.KEYS.splitTotalAmountEqually)) {
			Pinia.globalStore.clearSplitPaymentData();
		}

		let splitInfo = _getSplitSaleInfo();

			if(jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableTheftDetection)){
				let theftSalesCounter = Number(localStorage.getItem('theftSalesCounter'))
				localStorage.setItem('theftSalesCounter', String(++theftSalesCounter))
			}

		if (splitInfo.saleSplitRequired ) {
			try {
				if (splitInfo.cannotSplitSale) {
					throw new Error("בחשבונית קיימים אמצעי תשלום גם של חשבונית מס קבלה גם של תעודת משלוח וגם של חשבונית מס - לא ניתן לפצל חשבון - יש לבטל חלק מאמצעי התשלום")
				}

				let posVCFullSale = FullSale.posVCSale;

				//get taxinv sale data and get updated increment sequence.
				if (splitInfo.taxInvPct > 0) {
					let taxInvSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_TAX_INV)
					if (splitInfo.tamashPct > 0) {
						//tax inv is the main invoice
						//create a new tamash invoice for the split
						let tamashSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_SHIPMENT_INV)
						let tamashSale = await Service.ShipmentInv.createShipmentInvForSplit(posVCFullSale, tamashSeq, taxInvSeq)
						await persistChanges(posVCFullSale,tamashSale,tamashSeq)
						await Printing.Invoice.printInvoice( tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, true)
						if(jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)){
							await Printing.Invoice.printInvoice( tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, false)
						}
						return _closeCurrentSale(true);
					}
					else {
						//tax inv is the split invoice
						let regularSeq =  await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)
						let taxInvSale = await Service.TaxInv.createTaxInvForSplit(posVCFullSale, taxInvSeq, regularSeq)
						await persistChanges(posVCFullSale,taxInvSale,taxInvSeq)
						await Printing.Invoice.printInvoice( taxInvSale.sale, taxInvSale.saleItems, taxInvSale.salePayments, true)
						if(jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)){
							await Printing.Invoice.printInvoice( taxInvSale.sale, taxInvSale.saleItems, taxInvSale.salePayments, false)
						}
						return _closeCurrentSale(true);

					}
				}

				//get tamash sale data and get updated increment sequence.
				if (splitInfo.tamashPct > 0) {
					let regularSeq =  await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)
					let tamashSeq = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_SHIPMENT_INV)
					//regular inv is the main invoice
					//create a new tamash invoice for the split
					let tamashSale = await Service.ShipmentInv.createShipmentInvForSplit(posVCFullSale, tamashSeq, regularSeq)
					await persistChanges(posVCFullSale,tamashSale,tamashSeq)
					await Printing.Invoice.printInvoice( tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, true)
					if(jsonConfig.getVal(jsonConfig.KEYS.printCopyWithOriginalAllDocuments)){
						await Printing.Invoice.printInvoice( tamashSale.sale, tamashSale.saleItems, tamashSale.salePayments, false)
					}
					return _closeCurrentSale(true);
				}

			}
			catch(e) {
				await app.promiseShowAlert({
					header: i18next.t('error'),
					content: e.message,
					continueButtonText: i18next.t("ok"),
					hideCancelButton: true
				})
			}

		} else {
			return _closeCurrentSale();
		}

	}

	export async function ashkaraCloseSale() {
		closeSaleDg.setDataAndVerify();
		await closeSale();
		closeSaleDg.afterSaleClosed();
	}

	export async function persistChanges(mainSale:FullSale, SplitSalePayment:FullSale, splitSeq) {
		let promises = []

		await appDB.transaction("rw",appDB.sales,appDB.sequences,async () => {

			let mainSaleVoucherPayment = mainSale.salePayments.filter(pay => pay.method == Storage.Entity.SalePayment.METHOD_VOUCHER)[0]
			let data = JSON.parse(mainSaleVoucherPayment.data)
			if (data.length == 0) {
				let index = mainSale.salePayments.findIndex(pay => pay.method == Storage.Entity.SalePayment.METHOD_VOUCHER)
				mainSale.salePayments.splice(index,1)
			}


			promises = SplitSalePayment.getIncrementAndPersistPromises(splitSeq);

			await Promise.all(promises)

		})

	}

	export function _getSplitSaleInfo(sale = posVC.sale, saleItems = posVC.saleItems, salePayments = posVC.salePayments, hakafaCustomer = Pinia.globalStore.hakafaCustomer) {
		let _return = {
			saleSplitRequired: false,
			tamashAmount: 0,
			taxInvAmount: 0,
			cannotSplitSale: false,
			standardInvAmount: 0,
			tamashHakafaAmount: 0,
			taxInvHakafaAmount: 0,
			tamashPct: 0,
			taxInvPct: 0,
			standardInvPct: 0,
		}
		if ( !(jsonConfig.getVal(jsonConfig.KEYS.allowInvoiceSplit)) ){
			return _return;
		}

		let tamashAmount = 0, taxInvAmount = 0, standardInvAmount = 0, tamashHakafaAmount = 0, taxInvHakafaAmount = 0

		let tenbisCibusAmnt = Storage.Entity.SalePayment.getTenbisPluxeeAmount(salePayments);
		tamashAmount = tenbisCibusAmnt.tenbisTotalAmount + tenbisCibusAmnt.cibusTotalAmount + tenbisCibusAmnt.goodiTotalAmount + tenbisCibusAmnt.yaadTotalAmount + tenbisCibusAmnt.mishlohaTotalAmount;

		if (tenbisCibusAmnt.deliveryApiTotals) {
			for (let deliveryApiVouncher in tenbisCibusAmnt.deliveryApiTotals) {
				tamashAmount += tenbisCibusAmnt.deliveryApiTotals[deliveryApiVouncher].totalTamashByPaymentDataAmount;
			}
		}

		if (hakafaCustomer) {
			let sumHakafaPayments = Hakafa.sumHakafaPaymentsByTamashOrTaxInv(salePayments);
			tamashAmount += sumHakafaPayments.totalTamashHakafaPayments;
			tamashHakafaAmount += sumHakafaPayments.totalTamashHakafaPayments;

			taxInvAmount += sumHakafaPayments.totalTaxInvHakafaPayments;
			taxInvHakafaAmount += sumHakafaPayments.totalTaxInvHakafaPayments;

		}

		let totalPaid = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(sale, saleItems, salePayments).totalPaid;
		let discountVcAmount = 0
		let discountVouchers = salePayments.filter( (row)=>{return row.method === 6})
		if (discountVouchers.length != 0) {
			let dataAry = JSON.parse(discountVouchers[0].data);
			discountVcAmount = dataAry.reduce((sum, row) => sum + row.amount, 0);
		}
		let totalPaidWithDiscountVc = totalPaid + discountVcAmount

		standardInvAmount = totalPaidWithDiscountVc - taxInvAmount - tamashAmount

		let tamashPct = tamashAmount/totalPaidWithDiscountVc;
		let taxInvPct = taxInvAmount/totalPaidWithDiscountVc;
		let standardInvPct = standardInvAmount/totalPaidWithDiscountVc;
		const ALLOW_STANDARD_INVOICE_AMOUNT = 0.10;

		let isSplitInt = 0
		if (tamashAmount > 0) {
			isSplitInt++
		}
		if (taxInvAmount > 0) {
			isSplitInt++
		}
		if (standardInvAmount >= ALLOW_STANDARD_INVOICE_AMOUNT) {
			isSplitInt++
		}
		_return.saleSplitRequired = (isSplitInt > 1);
		_return.cannotSplitSale = (isSplitInt == 3);
		_return.tamashAmount = tamashAmount
		_return.taxInvAmount = taxInvAmount
		_return.standardInvAmount = standardInvAmount
		_return.tamashPct = tamashPct
		_return.taxInvPct = taxInvPct
		_return.standardInvPct = standardInvPct
		_return.tamashHakafaAmount = tamashHakafaAmount
		_return.taxInvHakafaAmount = taxInvHakafaAmount
		return _return;
	}

	function cancelSmartVoucherLoadCard() {

		return new Promise((resolve,reject) => {
			if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems,posVC.Specialitems)) {
				var payment,smartVoucher,i;
				for (i=0; i<posVC.salePayments.length; i++) {
					payment = posVC.salePayments[i];
					smartVoucher = PositiveTS.Service.SmartVoucher.getSmartVoucherFromLoadPaymentMehtod(payment.method);
					if (smartVoucher) {
						break;
					}
				}
				if (smartVoucher) {
					app.showLoadingMessage(i18next.t("smartVoucherCancelingLoadCard"));
					smartVoucher.cancelLoad(JSON.parse(payment.data))
					.then(function() {
						app.hideLoadingMessage();
						return payment.delete()
					})
					.then(function() {
						posVC.salePayments.splice(i,1);
						resolve()
					})
					.catch(function(err) {
						app.hideLoadingMessage();
						reject(err);
					});
				}
				else {
					resolve()
				}
			}
			else {
				resolve()
			}
		})
	}

	async function sendToSandiManApi(fullSale: PositiveTS.Service.FullSale) {
		if (posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.sandiManUsername))) {
			return;
		}
		try {
			await PositiveTS.Service.Sandi.SandiService.CreateDelivery(fullSale.sale);
			await fullSale.persist();
		} catch (error) {
			console.error(error);
			app.showErrorAlert(i18next.t('delivery.sandiCreateOrderError'));
		}
	}

	async function _closeCurrentSale(withoutGoBack = false){
		try {
			let aThis = closeSaleDg;
			let fullSale = FullSale.posVCSale;

			if ((posVC.salePayments.length == 1)
			 && (posVC.sale.isDelivery)
			 && (JSON.parse(posVC.sale.jsondata).delivery.deliveryType == Service.Delivery.DeliveryType.delivery)){
				let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale,posVC.saleItems,posVC.salePayments);
				let saleTotalAmount = saleTotals.totalAmount;
				let saleTotalNoRound = saleTotalAmount - saleTotals.totalRound;
				if((posVC.salePayments[0].amount == saleTotalNoRound)){
					aThis.change = {
						cash: 0,
						creditVoucher: 0,
						creditCard: 0,
						totalChange: 0
					}
				}
			 }

			 if(posVC.sale.isDelivery){
				 let deliveryType = JSON.parse(posVC.sale.jsondata).delivery.deliveryType;
				 if(deliveryType == Service.Delivery.DeliveryType.ta || deliveryType == Service.Delivery.DeliveryType.externalMishlohaTA){
					 await Service.Delivery.closeTASale(posVC.sale,posVC.saleItems,posVC.salePayments);
				 }
			 }


			PositiveTS.Service.valuCardSetSaleTrxKey(fullSale.sale)

			await aThis.handleOtotPurchaseIfNeeded();



			// Disable continue, so no click twice
			CloseSaleDialogUI.dialog('close');
			if(Pinia.elalStore.isOn){
				if (!Pinia.elalStore.isSalesWithPNR) {
					await Pinia.elalStore.updateSaleItemsPNRdata();
				}
			}
			if ( PositiveTS.Service.TaxInv.isCurrentSaleTaxInv(fullSale) ){
				app.showLoadingMessage(i18next.t("closingSale"));
				await TaxInv.createTaxInvIfRequired(fullSale)
				await Service.FullSale.updateInventory(fullSale.sale, fullSale.saleItems)
				if (Service.Otot.isOtotActive() && Service.Otot.isOtotPurchaseTagItem(fullSale.saleItems[0])) {
					await Service.Otot.printTagsFromSale(fullSale.sale);
				}
				app.hideLoadingMessage();
				// aThis.afterSaleClosed()
				return;
			}


			if ( PositiveTS.Service.ShipmentInv.isCurrentSaleShipmentInv(fullSale) ) {
				app.showLoadingMessage(i18next.t("closingSale"));
				Service.VoucherPayment.fixSplitSaleCustomers(fullSale.sale, fullSale.salePayments)
				await Service.ShipmentInv.createShipmentInvIfRequired(fullSale)
				await Service.FullSale.updateInventory(fullSale.sale, fullSale.saleItems)
				if (Service.Otot.isOtotActive() && Service.Otot.isOtotPurchaseTagItem(fullSale.saleItems[0])) {
					await Service.Otot.printTagsFromSale(fullSale.sale);
				}
				app.hideLoadingMessage();
				// aThis.afterSaleClosed()
				return;
			}



			let isCurrentTimestampValid = await posVC.sale.isCurrentTimestampValid()

				if (!isCurrentTimestampValid) {
					throw new Error(i18next.t("posClockTimeIsIncorrect"));
				}

			await aThis.customerDetailsFill(withoutGoBack)

			await aThis.handleSmartCardLoad();

			let creditCard = null;
			if (aThis.change.creditCard < 0) {
				app.hideLoadingMessage();
				creditCard = await creditCardDg.open({amount: aThis.change.creditCard});
			}

			app.showLoadingMessage(i18next.t("closingSale"));
			await posVC.closeCurrentSale(aThis.change, creditCard, withoutGoBack);


			app.hideLoadingMessage();
				//aThis.afterSaleClosed()
			return;

		}
		catch(err) {
				if (err && err.userCanceled) {
				return; //user canceled the closing of the sale
				}
				var error_msg = undefined;
				if (err.message && err.stack) {
					error_msg = err.message;
				} else {
					error_msg = err;
				}
				app.hideLoadingMessage();
				Service.Logger.error(err);
			await cancelSmartVoucherLoadCard()

					// -- Closing sale failed
					// Tell the user
			await app.showAlertDialog({
						header: i18next.t('error'),
						content: error_msg,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
				}, null, null);
			throw err;
		}


	}

	export async function afterSaleClosedActions(fullSale: PositiveTS.Service.FullSale, emptyMessage = null, printBons = true) {
		if(printBons){
				await Service.LogicalPrinterBonPrint.printBons(fullSale);
		}

		let fullSaleFormat = PositiveTS.Service.FullSale.getFullSaleFormat(fullSale.sale, fullSale.saleItems, fullSale.salePayments);
		let stayOnDalpak = false;

		if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)){
			if (jsonConfig.getVal(jsonConfig.KEYS.stayInDalpakScreenAfterSale)
				 && Pinia.dalpaksStore.currentSelectedDalpak.area != Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES) {
				stayOnDalpak = true;
			} else {
				let res = await Service.Dalpak.afterSaleOverActions(fullSaleFormat);
				stayOnDalpak = !res.success;
			}

			Service.DalpakSharedOrders.createOrderDalpakIfNeeded(fullSaleFormat);
		}

		if (Service.SplitSalePayment.isSplitPaymentSale(fullSale.sale)) {
				let amount = Service.SplitSalePayment.calculateSplitSaleAmount(fullSale.sale, fullSale.saleItems);

				Pinia.globalStore.setSplittedSalePaymentInfo({amount: amount, invoiceNumber: fullSale.sale.invoiceSequence});
		}

		let ototSmartCardLoadedNumber = Pinia.globalStore.ototSmartCardLoadedNumber;
		if (ototSmartCardLoadedNumber) {
			Pinia.globalStore.setOtotSmartCardLoadedNumber(null);

			let totalBraceletAmount = null;
			try {
				let result = await Service.Otot.getFullBalance(ototSmartCardLoadedNumber);

				totalBraceletAmount = result.currentAmount;
			} catch (err) {
				Service.Logger.error(err);
			}

			let text = i18next.t('otot.braceletLoadSuccessfully');

			if (totalBraceletAmount) {
				text += '\n' + i18next.t('otot.braceletLoadTotalAmount', {amount: totalBraceletAmount});
			}

			app.showAlertDialog({
				header: i18next.t('message'),
				content: text,
				continueButtonText: i18next.t("ok"),
				hideCancelButton: true
			  }, null, null);
		}
		if(Service.Otot.isOtotActive()){
			Pinia.globalStore.setOtotSaleBracelet({
				bracelet: null,
				status: Service.Otot.BRACELET_STATUS.NEW
			});
		}

		if(fullSale.sale.isDelivery) {
			await sendToSandiManApi(fullSale);
		}

		posVC.cleanSale(emptyMessage, stayOnDalpak);
		PositiveTS.Shared.SaleSync.countAndNotifyOfflineSales(appDB);
	}
}}}
