module PositiveTS {
	export module Storage {
		export module Entity {
			export class ItemGroup extends WasmEntity {

				itemGroupItems: Array<ItemGroupItem>
				sortOrder: number;
				name: string
				maxItemsToChoose: number
				minItemsToChoose: number
				translations: string

				constructor() {
					let meta = {
						name: 'ItemGroup',
						fields: {
							parentItemId: "INT",
							maxItemsToChoose: "FLOAT",
							minItemsToChoose: "FLOAT",
							numberOfFreeItems: "FLOAT",
							freeItemsExpensiveFirst: 'BOOL',
							sortOrder: "INT",
							name: "TEXT",
							itemCount: "INT",
							additionCount: "INT",
							translations: "TEXT",
						}
					};
					super(meta)
					this.itemGroupItems = [];
				}

				static getGroupsAndItemsByItemId(itemId): Promise<Array<ItemGroup>> {
					return new Promise((resolve, reject) => {
						Service.WasmDB.promiseSql(`select * from itemgroup where parentItemId=${itemId} order by sortOrder asc`)
							.then(function (result: any) {
								let itemGroups = []
								let itemGroupIds = []
								let idToGroupMap = {};
								for (let i = 0; i < result.length; i++) {
									let dbId = result[i].id;
									itemGroupIds.push(dbId)

									let ig = new PositiveTS.Storage.Entity.ItemGroup();
									ig.importFromObject(result[i]);
									idToGroupMap[dbId] = ig;
									itemGroups.push(ig)
								}
								Service.WasmDB.promiseSql(`select * from itemgroupitem where itemGroupId in (${itemGroupIds.join(',')}) order by sortOrder asc`)
									.then((igiResult: any) => {
										for (let i = 0; i < igiResult.length; i++) {
											let igi = new PositiveTS.Storage.Entity.ItemGroupItem();
											igi.importFromObject(igiResult[i]);
											idToGroupMap[igi.itemGroupId].itemGroupItems.push(igi);
										}
										resolve(itemGroups)
									})

							})
					})
				}

				static getGroupNameByItemGroupID(itemGroupId) {
					let response = Service.WasmDB.execAsObjectWithDB(`select name from itemgroup where id=${itemGroupId} order by sortOrder asc`, (<any>window).wasmDB)
					return response[0]?.name ?? ''
				}
			}
		}
	}
}