module PositiveTS {
  export module Components {
    export module valueCardAssetsScreen {

      const STEPS = {
        SELECT_CUSTOMER: 0,
        KEYPAD_INPUT: 1,
      }

      export function create() {

        let component = {
          template: JST.valueCardAssetsScreen(),
          components: {
            positiveIcon: PositiveIcon.getComponent(),
          },
          data(): {
            customer: PositiveTS.Service.ValueCardV5Service.CardInformationExItf | null,
            rejectFunc: null | Function,
            resolveFunc: null | Function,
            selfService: boolean
          } {
            return {
              customer: null,
              rejectFunc: null,
              resolveFunc: null,
              selfService: jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)
            }
          },
          methods: {
            open(customer) {
              this.customer = customer;
              return new Promise((resolve, reject) => {
                this.$el.showModal();
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
              })
            },
            close() {
              this.$el.close();
              this.customer = null;
            },
            cancel() {     
              this.resolveFunc('cancel');
              this.close();
            },
            
            async confirmAndClose() {
                this.resolveFunc('confirm');
                this.close();
            },
          },
          computed: {
            isMobile(){
              return Pinia.globalStore.mobileLayout
            },
            isValidCustomer() {
              if (!this.customer) return false;
              return moment(this.customer.expirationDate, "DD/MM/YYYY") > moment()
            }, 
          }

        }
        VueApp.component('value-card-assets-screen', component)
      }
    }
  }
}
