module PositiveTS {
	export module Service {
		export module Voucher {
			export function ValidateVoucherBarcode(voucherTypeId, barcode, tenantId, companyId, callback) {

				if (voucherTypeId === null || voucherTypeId === undefined) {
					callback({sucess: false});
					return false;
				}
			
				if (barcode === null || barcode === undefined) {
					callback({sucess: false});
					return false;
				}
			
				if (tenantId === null || tenantId === undefined) {
					tenantId = session.pos.tenantID;
				}
				if (companyId === null || companyId === undefined) {
					companyId = session.pos.companyID;
				}
				
				$.getJSON(
					Shared.Constants.remoteRoot + 'vouchers/' + voucherTypeId,
					{
						voucher_barcode: barcode
					},
					callback
				).error(function(err) {
					callback(null);
				});
			};

			export async function getValidatedVoucherBarcode(voucherTypeId, barcode, tenantId, companyId) {

				if (voucherTypeId === null || voucherTypeId === undefined) {
					return {sucess: false};
				}
			
				if (barcode === null || barcode === undefined) {
					return {sucess: false};
				}
			
				if (tenantId === null || tenantId === undefined) {
					tenantId = session.pos.tenantID;
				}
				if (companyId === null || companyId === undefined) {
					companyId = session.pos.companyID;
				}
				try {
					let isBarcodeValid = false
					let timesToCheckBarcodeWithServer = barcode[0] == "0" ? 2 : 1
					let index = 0
					let res

					while (index < timesToCheckBarcodeWithServer && !isBarcodeValid) {
						let barcodeToCheck = barcode
						if(timesToCheckBarcodeWithServer > 1 && index == 0) {
							barcodeToCheck = String(barcode).substring(1)
						}

						res = await Service.FetchReq.jsonReq(`${Shared.Constants.remoteRoot}vouchers/${voucherTypeId}?voucher_barcode=${barcodeToCheck}`, "GET");
						
						isBarcodeValid = res?.result?.success 
						index++
					}

					return res.result					
				} catch  (err) {
					return {success: false}
				}
			};
			
			export function UnuseVoucherBarcode(voucher_type_id, barcode) {
				return new Promise((resolve,reject) => {
					// Data validations
					if (voucher_type_id === null || voucher_type_id === undefined) {
						reject('voucher type id undefined');
						return false;
					}
				
					if (barcode === null || barcode === undefined) {
						reject('barcode undefined');
						return false;
					}
				
				
					$.ajax({
						type: "DELETE",
						url: Shared.Constants.remoteRoot + 'vouchers/' + voucher_type_id,
						data: {
							voucher_barcode: barcode
						},
						dataType: 'json',
						success: function (returnedData) {
							if (returnedData.success == true) {
								resolve(returnedData);
							} else {
								reject(returnedData.error);
							}
						}
					}).error(function(err) {
						reject(err);
					});
				})
			
			}
		}
	}
}
