import i18next from "i18next";
import CreditCardBaseOutput from './CreditCardBaseOutput';
export default class PaymentGatewayOutput extends CreditCardBaseOutput {
  constructor() {
    super();
    this.isGateway=true
  }
  public TerminalId: string;
  public isGateway:boolean;
  public CardId:string;
  // public AuthNumber:string;
  // public AuthCodeCreditCompany:string;
  public Version:string;
  public CardMask: string;
  public CardLength:number;
  public CardNo:string;
  public MpiTransactionId: string;
  public TranID:string;
  public FileNumber:string;
  public ShiftId1:string;
  public ShiftId2:string; 
  public ShiftId3:string; 
  public PeriodicalPayment: string;
  public CurrencyCode:string;
  public SupplierNumber: string;
  public AuthNumber:string;
  public SlaveTerminalNumber: string;
  public SlaveTerminalSequence: string;
  public IdFlag:string;
  public CvvFlag:string;
  public AuthCodeCreditCompany: string;
  public AuthCodeAquirer: string;
  public TransactionType:string;
  public TransactionCode:string;
  public CardAcquirer:string;
  public CardBrand:string;
  public CreditType:string;
  public User:string; 
  public CreditCompany:string;
  public Language: string;
  public BaseCurrency: string;
  public BaseAmount: string;
  public OriginalPaymentTranId:string;
  public CardExpiration: string;
  public OriginalTerminalId: string;

  get Solek(){  return this.CreditCompany;}
  get Manpik(){ return this.CardAcquirer;}
  get spType(){ return this.CreditType;}
  get Brand(){  return this.CardBrand;}
  get PanEntryMode(){ return this.TransactionCode;}
  get getPanEntryMode(){return this.TransactionCode;}
  get FileNo(){ return this.FileNumber;}
  get TranType(){ return this.TransactionType}
  get ComRetailerNum(){ return this.SupplierNumber;}
  get AuthManpikNo(){ return Number(this.AuthCodeManpik) != 0  ?  this.AuthNumber : ""}
  get AuthSolekNo(){ return Number(this.AuthCodeAquirer)!= 0  ? this.AuthNumber : ""}
  get ResponseId(){ return this.IdFlag}
  get ResponseCvv2(){ return this.CvvFlag}
  get AuthCodeManpik(){ return this.AuthCodeCreditCompany}
  get TermNo(){ return this.SlaveTerminalNumber;}
  get TermSeq(){  return this.SlaveTerminalSequence;}
  get appVersion(){ return this.Version;}
  get Pan(){  return this.CardMask;}
  get card_number() { return this.CardMask;}
  get cardNumberForPrint() {  return this.CardMask.substr(Math.max(this.CardMask.length - 4, 0), Math.min(4, this.CardMask.length));}
  get payment_type() {  return 1;}
  get confirmation_number() { return this.AuthManpikNo || "0000000";}
  get card_type() { return this.CreditCompany;}

  set setCommand(command){
    this.Command = command
    if(command == "doDeal"){
      this.Command = "1"
    }
  }
  transactionTypes:{
    retrieveCardNumber:"איתור מספר כרטיס",
    
  }
  get Xfield(){
    return this.User;
  }
  get tranTypeHebrew() {
    switch (Number(this.TransactionType)) {
      case 0:
        return i18next.t("gatewayPayment.transactionTypes.blockedCard")
      case 1:
        return i18next.t("gatewayPayment.transactionTypes.regularDebit")
      case 2:
        return i18next.t("gatewayPayment.transactionTypes.approvedDebit")
      case 3:
        return i18next.t("gatewayPayment.transactionTypes.forced")
      case 6:
        return i18next.t("gatewayPayment.transactionTypes.cashback")
      case 7:
        return i18next.t("gatewayPayment.transactionTypes.cash")
      case 11:
        return i18next.t("gatewayPayment.transactionTypes.recurringDebit")
      case 30:
        return i18next.t("gatewayPayment.transactionTypes.balanceEnquiry")
      case 51:
        return i18next.t("gatewayPayment.transactionTypes.regularCredit")
      case 52:
        return i18next.t("gatewayPayment.transactionTypes.cancel")
      case 53:
        return i18next.t("gatewayPayment.transactionTypes.approvedCredit")
      case 55:
        return i18next.t("gatewayPayment.transactionTypes.load")
      case 58 || 0:
        return i18next.t("gatewayPayment.transactionTypes.reversal")
      case 82 || 0:
        return i18next.t("gatewayPayment.transactionTypes.discharge")
      case 201 || 0:
        return i18next.t("gatewayPayment.transactionTypes.retrieveCardNumber")
      default:
        return ""
    }
  }
  get authCodeManpikHebrew() {
    switch (Number(this.AuthCodeCreditCompany)) {
      case 1:
        return i18next.t("gatewayPayment.authcCodeCreditCompany.issuer")
      case 3:
        return i18next.t("gatewayPayment.authcCodeCreditCompany.shva")
      case 5:
        return i18next.t("gatewayPayment.authcCodeCreditCompany.voiceCall")
      case 7 || 8:
        return i18next.t("gatewayPayment.authcCodeCreditCompany.card")
      default:
        return i18next.t("gatewayPayment.authcCodeCreditCompany.shva")
    }
  }

}


