import StaticObj from "./StaticObj";
import i18next from "i18next";
import CreditCardBaseOutput from '../../CreditCardBaseOutput';
export default class EmvOutput extends CreditCardBaseOutput{
  

    constructor() {
      super();
      this.isEmv = true;
    }
    
    public AddDspBalance:string
    public AddDspF111:string
    public addendum1:string
    public Addendum1Settl:string
    public Addendum2:string
    public Addendum2Settl:string
    public Addendum3Settl:string
    public Addendum4Settl:string
    public Addendum5Settl:string
    public AshStatus:string
    public AshStatusDes:string
    public AuthorizedAmount:string
   
    public CashbackAmount:string
    public CavvUcafResult:string
    public DueDate:string
    public Deferred:string
    public DspBalance:string
    public HostVersion:string
    public IpayAmount:string
    public IndxPayment:string
    public F39Response:string
    public ExpirationDate:string
    public IpayNumber:string
    public IpayPercent:string
    public PhaseRequest2:string
    public ResponseAvs:string
    public ResponseCvv2:string
    public Retailer:string
    public TelNoCom:string
    public Track2:string
    public TranRecord:string
    public VerifiedByPIN:string
    public AshraitEMVVersion:string
    public ParsedRecords:Array<any>
    public internalPaymentType:number
    public ZData:string
    public PossibleCreditTerms: any




    public ARC:string
    public AID:string
    public appVersion:string
    public ATC:string
    public AuthCodeManpik:string
    public AuthCodeSolek:string
    public AuthManpikNo:string
    public AuthSolekNo:string
    public Brand:string
    public CardSeqNumber:string
    public ComRetailerNum:string
    public Data:string //for transmit output
    public DepositReport:Array<string>
    
    
    public FileNo:string
    public Manpik:string
    public NotFirstPayment:string
    public Pan:string
    public PanEntryMode:string
    public ReceiptCustomer:any
    public ReceiptMerchant:any
    
    
    public ResponseId:string
    
    public Rrn:string
    public Solek:string
    public spType:string
    public Status:string
   
    // public TerminalId:string
    public TerminalName:string
    public TermNo:string
    public TermSeq:string
    public Tip:string
    
    
    public TranType:string
    public TSI:string
    public TVR:string
    
    public Xfield:string
    
    public RequestId:string
    public OriginalPaymentRequestId:string //internal to positive - for use to track payments that were already credited...
    // public ResultCode:string
    
    
    public TotalNumberOfRecords:string
    public StaticObj:StaticObj //for get Total file (TOTAL.ASH)
    public SessionNumber:string //for transmit output
    //Positive Internal fields
    public isEmv:boolean
    
    public timestamp: number
    
  
    //getters for pos compatibility
    get card_number() {
      return this.Pan;
    }
  
    get cardNumberForPrint() {
      return this.Pan.substr(Math.max(this.Pan.length-4,0),Math.min(4,this.Pan.length))
    }

    get payment_type() {
      return this.internalPaymentType;
    }
  
    get confirmation_number() {
      return this.AuthManpikNo || "0000000";
    }
  
    get card_type() {
      return this.Solek;
    }
    get getPanEntryMode(){return this.PanEntryMode;}

    get tranTypeHebrew() {
      switch(Number(this.TranType)) { //TODO-EMV: need to implement ביטול...
        case 0:
          return i18next.t("emv.tranTypes.informative")
        case 1:
          return i18next.t("emv.tranTypes.debit")
        case 2:
          return i18next.t("emv.tranTypes.dischrage")
        case 3:
          return i18next.t("emv.tranTypes.enforced")
        case 4:
          return "cashback"
        case 5:
          return i18next.t("emv.tranTypes.cash")
        case 11:
          return i18next.t("emv.tranTypes.standingOrder")
        case 30:
          return i18next.t("emv.tranTypes.checkBalance")
        case 53:
          return i18next.t("emv.tranTypes.credit")
        case 55:
          return i18next.t("emv.tranTypes.charge")
        default:
          return ""
      }
    }
    get authCodeManpikHebrew() {
      switch (this.AuthCodeManpik) {
        case "0":
        case "7":
          return i18next.t("emv.authCodesManpik.card")
        case "1":
        case "2":
        case "7":
          return i18next.t("emv.authCodesManpik.company")
        case "3":
        case "4":
          return i18next.t("emv.authCodesManpik.shva")
        case "5":
        case "6":
          return i18next.t("emv.authCodesManpik.manual")
        default:
          return ""
      }
    }
    
  

  
  }  