module PositiveTS {
  export module Service {
    export module Ontrust {
      export module Payment {


        export function setPayment(): Promise<any> {
          _removePreviousVoucher();
          return _addOntrustVoucher();
        }

        class OntrustVoucherObj {
          amount: number = 0;
          creditType = "ONTRUST";
          barCode = "";
          voucher_type_id = _OntrustVoucherTypeId();
          smartVoucherType = false;

          constructor(amount) {
            this.amount = amount;
          }

        };

        function _OntrustVoucherTypeId():string{
          return session.pos.ontrustVoucherId.toString();
        }


        function _removePreviousVoucher(): void {
          var paymentObj = PositiveTS.Helper.SaleHelper.findPaymentByMethod(
            posVC.salePayments,
            PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER);

          if (!paymentObj){
            return;
          }
          let salePaymentData = JSON.parse(paymentObj.data);

          for (var i = 0; i < salePaymentData.length; i++) {
            if (salePaymentData[i].voucher_type_id == _OntrustVoucherTypeId()) {
              salePaymentData.splice(i, 1);
            }
          }
        }

        function _addOntrustVoucher(): Promise<any> {
          var amount = posVC.getTotalAmount();
          var currentObj = new OntrustVoucherObj(amount);
          var salePayment = PositiveTS.Helper.SaleHelper.findPaymentByMethod(
            posVC.salePayments,
            PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER);

          var salePaymentData;
          if (salePayment) {
            salePaymentData = JSON.parse(salePayment.data);
            _removePreviousVoucher();
          } else {
            salePayment = new PositiveTS.Storage.Entity.SalePayment();
            salePayment.saleID = posVC.sale.id;
            salePayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER;
            salePaymentData = [];
          }


          salePaymentData.push(currentObj);
          salePayment.amount = amount;
          salePayment.data = JSON.stringify(salePaymentData);
          return posVC.persistSalePayment(salePayment);

        }

      }
    }
  }
}
