module PositiveTS {
    export module Dialogs {
        export module CustomerClubSelectDialog {
            
            export function open(callback) {

                CustomerClubSelectDialog.initSelectTable();
                OptionSelectUI.resetUi();
        
                $('#OptionSelect-dialog-continue').click(function () {
                    callback( CustomerClubSelectDialog.onSelected() );
                });
        
                $('#OptionSelect-dialog-cancel').click(function () {
                    CustomerClubSelectDialog.close();
                });
        
                OptionSelectUI.dialog('open');
        
            }
            export function close() {
                $('#OptionSelect-dialog-cancel').unbind('click');
                $('#OptionSelect-dialog-continue').unbind('click');
                OptionSelectUI.dialog('close');
        
            }
            
            export function initSelectTable() {
                var tableView = $('#OptionSelect-dialog-tableview').tableview();
                tableView.setNumberOfVisibleRows(6);
        
                // Empty the table so we can be sure we are displaying only the latest results
                tableView.empty();
                 
                let itemList = PositiveTS.Service.CustomerClub.supportedClubs;
                for (var i = 0; i < itemList.length; i++) {
                    // Add a row with the z report data
                    var theRow = tableView.addRow([itemList[i].description],false,(row) => {
                        let clubId = CustomerClubSelectDialog.onSelected();
                        posVC.openCustomerDialog(clubId);
                    }, 'customer-club-table-row-cell');
                    theRow.data('data', i);
                }
            }
        
            export function onSelected() {
                var tableView = $('#OptionSelect-dialog-tableview').tableview();
                if (tableView.getSelectedRow().data('data') !== undefined) {
                  CustomerClubSelectDialog.close();
                  return Service.CustomerClub.supportedClubs[tableView.getSelectedRow().data('data')].code;
                }
        
            }
        }
    }
}
