module PositiveTS {
    export module Components {
        export module HorizontalScrollContainer {
            export function getComponent() {
                let component = {
                    template: JST.HorizontalScrollContainer(),
                    mixins: [],
                    props: {
                        noScrollBar: { // Set 
                            type: Boolean,
                            default: true,
                        },
                        // Must be a hash-like object with boolean as value of key
                        customCssClasses: {
                            type: Object,
                            default: {},
                        }
                    },
                    data() {
                        return {
                            width: '',
                            height: '',
                        }
                    },
                    computed: {
                        dynamicClasses() {
                            let cssClasses = {
                                'no-scroll-bar': this.noScrollBar,
                            };
                            let customCssClasses = Object.keys(this.customCssClasses);
                            for (const cssClass of customCssClasses) {
                                cssClasses[cssClass] = customCssClasses[cssClass];
                            }
                            return cssClasses;
                        }
                    },
                    methods: {}
                }
                return component;
            }
        }
    }
}

