module PositiveTS {
  export module Components {
    export module MoveTableComponent {

      export const route = "/move-table";

      export function getComponent() {
        return {
          template: JST.moveTableComponent(),
          props: {
            asDialog: {
              type: Boolean,
              default: false,
            }
          },
          methods: {
            initData(selectedDalpakId = null) {
              this.printBon = true;
              this.lastAvailableDalpaksState = null;

              this.moveDalpakData = {
                from: selectedDalpakId,
                to: null,
              }

            },
            async open(selectedDalpakId) {
              if (this.asDialog) {
                this.initData(selectedDalpakId);
                return await this.$refs.dialog.open();
              }
            },
            async validateAndFinish() {
              let errors = [];

              if (posUtils.isBlank(this.moveDalpakData.from) || posUtils.isBlank(this.moveDalpakData.to) ||
                  posUtils.isBlank(this.fromDalpak) || posUtils.isBlank(this.toDalpak)) {
                errors.push(this.$t('moveTableComponent.tablesMandatory'));
              }

              if (errors.length > 0) {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: errors.join('\n'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });

                return false;
              }

              return await this.finish();
            }, 
            async finish() {
              this.lastAvailableDalpaksState = this.dalpaksWithoutCurrent;
              let result = await Service.Dalpak.moveDalpakSale(this.fromDalpak, this.toDalpak, this.printBon, true);
              
              if (!result) {
                this.lastAvailableDalpaksState = null;
              }

              if (result && !this.asDialog) {
                this.goBack();
              }

              return result;
            },
            goBack() {
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
            getDalpakAreaNameById(dalpakAreaId) {
              return this.dalpakAreasByRailsId[String(dalpakAreaId)]?.name;
            },
            async selectDalpak() {
              if (!posUtils.isBlank(this.moveDalpakData.from)) {
                try {
                  await Service.Dalpak.selectDalpakAndGoToPos(this.fromDalpak);
                } catch (err) {
                  console.error(err);
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: err.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }
              }

            },
            shouldPreventIfLocked(dalpak, event) {
              let toDalpakSale = dalpak.sale;
              if (toDalpakSale && toDalpakSale.payments?.length> 0) {
                event.stopPropagation();
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: this.$t('dalpaks.targetDalpakHasSale',{...dalpak, dalpak: i18next.t('table')}),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
                return;
              }

              if (dalpak && dalpak.lockedBy) {
                event.stopPropagation();
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: this.$t('dalpaks.namedDalpakIsLocked', {...dalpak, dalpak: i18next.t('table')}),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
              }
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {
              dalpaksByArea,
              openedOrdersCount,
              dalpakAreas,
              allDalpaksWithoutSpecialAreas,
              allDalpaks} = Pinia.storeToRefs(dalpaksStore);
              
            return {dalpaksByArea,openedOrdersCount,dalpakAreas,allDalpaksWithoutSpecialAreas,dalpaks:allDalpaks}
          },
          computed: {
            fromDalpak() {
              return this.allDalpaksWithoutSpecialAreas.filter(d => d.id == this.moveDalpakData.from)[0];
            },
            toDalpak() {
              return this.allDalpaksWithoutSpecialAreas.filter(d => d.id == this.moveDalpakData.to)[0];
            },
            dalpaksToMoveTo() {
              return this.lastAvailableDalpaksState ||  this.dalpaksWithoutCurrent;
            },
            dalpaksWithoutCurrent() {
              return this.allDalpaksWithoutSpecialAreas.filter(d => d != this.fromDalpak);
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
          },
            dialogButtons() {
              return [
                {
                  text: this.$t('saveAction'),
                  class: 'tables-green',
                  name: 'save',
                  value: true,
                  beforeCloseAction: async () => await this.validateAndFinish(),
                }
              ]
            },
            dalpakAreasByRailsId() {
              return _.keyBy(this.dalpakAreas, dalpakArea => String(dalpakArea.railsId));
            },
            getRootItemBindings() {
              if (this.asDialog) {
                return {
                  faIcon: "fa-solid fa-arrow-right-to-arc",
                  animation: "open-from-left",
                  buttons: this.dialogButtons,
                  title: this.$t('moveTableComponent.dialogTitle'),
                };
              }

              return {};
            },

          },
          data() {
            return {
              isRestaurantNewPOS: Service.Dalpak.isRestaurantNewPOS(),
              moveDalpakData: {
                from: null,
                to: null,
              },
              printBon: true,
              lastAvailableDalpaksState: null,
            }
          },
          mounted() {
            if (!this.asDialog) {
              this.initData(this.$router.currentRoute.value?.params?.dalpakId);
            }
          },
          beforeRouteEnter: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle( i18next.t('selectDalpaksDialog.moveTable'))
            Pinia.dalpaksStore.setHideHamburgerMenu(true)
            Pinia.dalpaksStore.setGoBackAction(() => VueServices.Router.goto(SelectDalpakDialog.route))
						Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.MoveTable);
            next();
          },
          beforeRouteLeave: async function(to, from, next) {


            Pinia.dalpaksStore.setDalpaksPageTitle(null)
            Pinia.dalpaksStore.setHideHamburgerMenu(false)
            Pinia.dalpaksStore.setGoBackAction(null)
            Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.SelectDalpak);
            next();
          }
        }
      }
    }
  }
}