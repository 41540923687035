module PositiveTS {
  export module Components {
    export module FlightsIsrairSelectAuthorizedEmployees {

      export function getComponent() {
        return {
          template: JST.flightsIsrairSelectAuthorizedEmployees(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          components: {positiveVSelect: PositiveVueSelect.getComponent()},
          computed: {
            availableEmployees() {
              return this.allEmployees.filter(employee => (!(this.stepData.authorizesEmployees || []).includes(employee))).map(employee => {
                employee.nameAndMagneticCard = `${employee.name} - ${employee.magneticCard}`;

                return employee;
              });
            },
            isFormFilled() {
              return this.stepData.authorizesEmployees && this.stepData.authorizesEmployees.length;
            }
          },
          methods: {
            selectAuthorizedEmployees() {
              if (!this.isFormFilled) {
                return;
              }
              
              this.goToNextStep();
            },
            reduceFunc(employee) {
              return ;
            }
          },
          data() {
            return {
              allEmployees: [],
            }
          },
          created() {
            app.showLoadingMessage(i18next.t("loading"));
            PositiveTS.Storage.Entity.Employee.fetchByStore(session.pos.storeID).then((result) => {
              this.allEmployees = result;
              app.hideLoadingMessage();
            })
            .catch(err => {
              app.hideLoadingMessage();
              console.error(err);
              this.showGenericError();
              this.goBack();
            })
          }
        }
      }
    }
  }
}