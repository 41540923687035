module PositiveTS {
   export module Service {
      export module DalpakSharedOrders {

         export async function createOrderDalpakIfNeeded(sale) {
            if (sale.isDelivery) {
               await createOrderDalpak(sale);
            }
         }

         export async function createOrderDalpak(sale, name = null) {
            let res = await Dalpak.createDalpakAtEngine({
               name: posUtils.isDefined(name) ? name : sale.invoiceSequence.toString(),
               area: DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES,
               sale
            });

            if (res.success) {
               if (sale.syncToSyncServerFailed) {
                  delete sale.syncToSyncServerFailed;
                  await appDB.sales.put(sale);
               }
            } else {
               if (!sale.syncToSyncServerFailed) {
                  sale.syncToSyncServerFailed = true;
                  await appDB.sales.put(sale);
               }
            }
         }

         export async function saveOrderSaleUpdates(sale): Promise<boolean> {
            let dalpak = Service.Dalpak.getDalpakBySale(sale);

            if (posUtils.isBlank(dalpak)) {
               showError(i18next.t('generalError'))
               Service.Logger.error('cant find dalpak' + sale.dalpakEngineId);

               return false;
            }

            app.showLoadingMessage(i18next.t('dalpakOrders.updatingOrder'));
            let res = await Service.Dalpak.saveDalpakSaleAndUnlock({ ...dalpak }, sale); // duplicate dalpak so it wont override vuex dalpak

            if (res.success) {
               await Service.Dalpak.refreshDalpaksVuexIfNeeded();
               app.hideLoadingMessage();
            } else {
               app.hideLoadingMessage();
               showError(res.errorMessage);
            }


            return res.success;
         }


         export async function deleteAllOrders() {
            let isDalpakDataValid = await Dalpak.isDalpaksDataValid()
            if (!isDalpakDataValid.success) {
               return isDalpakDataValid;
            }

            await Service.Dalpak.loadAllDalpaks();

            let dalpaksWithoutOrders = Pinia.dalpaksStore.allDalpaks.filter(d => d.area != Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES);

            return await Service.Dalpak.setDalpaksAtEngine(dalpaksWithoutOrders);
         }

         export async function syncFailedSales() {
            let salesToSync = await appDB.sales.filter(sale => sale.syncToSyncServerFailed).toArray();

            console.log('Sync failed deliveries to sync server', salesToSync);

            for (let sale of salesToSync) {
               await createOrderDalpak(sale);
            }
         }

         function showError(errorText) {
            app.showAlert({
               header: i18next.t('error'),
               content: errorText,
               continueButtonText: i18next.t("ok"),
               hideCancelButton: true
            }, null, null);
         }
      }

   }
}