module PositiveTS {
  export module Service {
    export module ValueCardV5Service {
      export const jsonDataKey = "value_card_customer";
      export const clubName = "valuecard";

      const _url = 'https://ws.valuecard.co.il/pos/V5/Default.asmx';
      const _type = "POST";
      const _fileLog = "VALUE_CARD"
      const _soapService = {
        CardInformationEx: "CardInformationEx",
      }
      const _addonServiceTag = "external_service"

      function getParameters() {
        return {
          posId: session.pos.valuePosId,
          posPassword: session.pos.valuePosPassword,
          cashiersPassword: session.pos.valueCashiersPassword
        }
      }

      export function hasParamset(): boolean {
        let params = getParameters();
        return Boolean(params.posId && params.posPassword && params.cashiersPassword);
      }

      export class CardInformationExItf {
        cardNumber: string;
        cardId: string;
        cardGroup: string;
        memberCellPhone: string;
        memberFullName: string;
        prepaidBalance: number;
        punchCardBalances: String[];
        availableBenefits: String[];
        totalPoints: number;
        birthDate: string;
        expirationDate: string;
        message: string;
        clubName = clubName
        printMessage: string | null;
      }

      export async function getCardInformationEx(cardNumber: string) {
        try {
          const data = JSON.stringify(_valuecardInformationExData(cardNumber));
          const result = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data }, _addonServiceTag);
      
          _validateServerResponse(result);
          let vqp = new ValueQueryParsed();
          vqp.setByXmlAndValidate(result.request.result, cardNumber, 0);
      
          return parseCardInformation(result.request.result, cardNumber, vqp.printMessage);
        } catch (error) {
          PositiveTS.Service.Logger.error(error);
          throw error
        }
      }
      

      function parseCardInformation(result: string, cardNumber: string,printMessage:string): CardInformationExItf {
        let card = new CardInformationExItf();
        card.cardNumber = cardNumber;
      
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(result, "application/xml");
      
        card.cardId = xmlDoc.querySelector("CardId").textContent;
        card.cardGroup = xmlDoc.querySelector("CardGroup").textContent;
        card.memberCellPhone = xmlDoc.querySelector("MemberCellPhone").textContent;
        card.memberFullName = xmlDoc.querySelector("MemberFullName").textContent;
        card.message = xmlDoc.querySelector("Message").textContent;
        card.prepaidBalance = parseFloat(xmlDoc.querySelector("PrepaidBalance").textContent);
        card.punchCardBalances = xmlDoc.querySelector("PunchCardBalances").textContent.split("|").filter(Boolean);
        card.availableBenefits = xmlDoc.querySelector("AvailableBenefits").textContent.split(";").map(el => el.split("|")[1] ?? "").filter(Boolean);
        card.totalPoints = parseFloat(xmlDoc.querySelector("TotalPoints").textContent);
        card.birthDate = xmlDoc.querySelector("BirthDate").textContent;
        card.expirationDate = xmlDoc.querySelector("ExpirationDate").textContent;
        card.birthDate = moment(card.birthDate).format("DD/MM/YYYY");
        card.expirationDate = moment(card.expirationDate).format("DD/MM/YYYY");
        card.printMessage = jsonConfig.getVal(jsonConfig.KEYS.standaloneMode)? null: printMessage
      
        return card;
      }

      export class ValueQueryParsed {
        returnCode: string;
        isError: Boolean;
        transactionId: string;
        discount: number = 0;
        printMessage: string;
        Message: string;
        locationFooter: string;
        cardNumber: string;
        loadSum: number;

        setByXmlAndValidate(result: string, cardNumber: string, loadSum: number = 0) {
          this.returnCode = $(result).find("ReturnCode").text();
          this.isError = $(result).find("IsError").text() === "true";
          this.transactionId = $(result).find("TransactionId").text();

          // commit do not return the discount field
          let discount = parseFloat($(result).find("Discount").text());
          this.discount = discount;

          this.printMessage = $(result).find("PrintMessage").text() + "\n" + $(result).find("NewBenefits").text();
          this.Message = $(result).find("Message").text() + "\n" + $(result).find("NewBenefits").text();
          this.locationFooter = $(result).find("LocationFooter").text(),
          this.cardNumber = cardNumber;
          this.loadSum = loadSum;

          this._ResponseValidation();
        }

        private _ResponseValidation(): void {
          if (this.isError) {
            throw new Error(this.Message + this.locationFooter);
          }
        }
      }

      class _ExternalServiceItf {
        url = _url;
        type = _type;
        headers = "";
        body: string;

        constructor(action, body) {
          this.headers = JSON.stringify({
            "Content-Type": "text/xml; charset=utf-8",
            "SOAPAction": `https://ws.valuecard.co.il/pos/V5/${action}`
          })
          this.body = body;
        }
      }


      function _validateServerResponse(result): void {
        PositiveTS.Service.Filelog.log(_fileLog,
          JSON.stringify({ request: result.request.body.data, result: result.request.result })
        );
        if (result.request.result.startsWith("ERROR:")) {
          throw new Error(result.request.result)
        }
      }


      function _valuecardInformationExData(cardNumber: string) {
        var body = `
<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
        <CardInformationEx xmlns="https://ws.valuecard.co.il/pos/V5/">
            <RequestParameters>
                <Common>
                    <RequestId>13809</RequestId>
                    <VCToken>A1AC1420440D4EB3BF6BF7A5DF5C676D</VCToken>
                    <POSId>${getParameters().posId}</POSId>
                    <POSPassword>${getParameters().posPassword}</POSPassword>
                    <CashiersPassword>${getParameters().cashiersPassword}</CashiersPassword>
                </Common>
                <CardNumber>${cardNumber}</CardNumber>
                <PIN></PIN>
                <Coupon>0</Coupon>
                <TemporaryToken>string</TemporaryToken>
            </RequestParameters>
        </CardInformationEx>
    </soap:Body>
</soap:Envelope>
`;

        return new _ExternalServiceItf(_soapService.CardInformationEx, body);
      }

    }
  }
}