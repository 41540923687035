module PositiveTS {
  export module Components {
    export module SittingOrTADialog {


      const _vueComponentName = 'sitting-or-ta-dialog';

      export function create() {
        let SittingOrTADialog = {
          template: JST.SittingOrTADialog(),
          components: {
            positiveIcon: PositiveIcon.getComponent()
          },
          data: () => {
            return {
              currentSaleId: null,
            }
          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {getCurrentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);

            const globalStore = Pinia.useGlobalStore();

            const {setIsSelfServiceTa} = globalStore
            const globalStoreActions = {setIsSelfServiceTa}
            return {...globalStoreActions,getCurrentSelectedDalpak}
        },
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },
          watch: {
            getCurrentSelectedDalpak() {
              if (!this.getCurrentSelectedDalpak) {
                this.currentSaleId = null;
              }
            },
          },
          methods: {
            open(force = false) {
              if (!posVC.sale && !force) {
                return;
              }
              if (Service.SittingTA.isTASittingSelected() && !force) {
                return;
              }
              if (posVC.sale.id !== this.currentSaleId) {
                this.currentSaleId = posVC.sale.id;
              }
              this.$el.showModal();
            },

            setSitting() {
              Pinia.posStore.setIsTA(false);
              this.close();
            },
            setTA() {
              Pinia.posStore.setIsTA(true);
              Service.SittingTA.setTAItemInSale();
              this.close();
            },

            async setIsTaAndFinish(isTa: boolean = false) {
              this.setIsSelfServiceTa(isTa);
              this.close();
            },
            close() {
              this.$el.close();
            },
          }
        };

        VueApp.component(_vueComponentName, SittingOrTADialog)
      }
    }
  }
}
