module PositiveTS {
    export module Service {
        export class InterService extends SmartVoucher {

            async getBalance(cardNumber: string, cvv?: string): Promise<GetBalanceResponse> {
                const action = "check"
                const response = await PositiveTS.Service.InterServiceService.interServiceVoucherHandler(cardNumber, action)
                              
                let resObj:GetBalanceResponse =  {
                    success: false ,
                    error: "",
                    balance: 0
                }

                if (response.status === "valid" ){
                    resObj.success = true
                    resObj.balance = Number(response.voucher_cost)

                }else if (response.error === "try a vaild number of voucher"){
                    resObj.error = i18next.t("interService.error.badVoucherNumber")   
                }    
                else {
                    resObj.error = i18next.t("interService.error.voucherIsNotValid")
                }
                
                return resObj
            }
            
            async pay(voucherData: any, amount: number, cvv?: string, companyId?: any, additionalData?: any): Promise<any> {
                const action = "kill" 
                let cardNumber = voucherData.barCode;
                const response = await Service.InterServiceService.interServiceVoucherHandler(cardNumber, action)

                let resObj:ActionResponse =  {
                    success: false ,
                    error: "",
                    rawResponse: response,
                    voucher: voucherData
                }
                 
                if (response.status === "valid" && response.killed === "yes"){ 
                    voucherData.barCode = cardNumber
                    voucherData.allowPartialReturn = false
                    voucherData.amount = Number(response.voucher_cost)
                    voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_INTERSERVICE
                    voucherData.isTamashCustomer = false

                    resObj.success = true

                } else{
                    resObj.error = i18next.t("interService.error.voucherIsNotValid")
                }

                return resObj;
            }

            async cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
                const action = "unkill"
                let cardNumber = voucherToCancel.barCode;
                const response = await Service.InterServiceService.interServiceVoucherHandler(cardNumber, action)

                let resObj:ActionResponse =  {
                    success: false ,
                    error: "",
                    rawResponse: response,
                    voucher: voucherToCancel
                }
                 
                if (response.status === "valid" && response.unkilled === "yes"){ 
                    resObj.success = true
                } else {
                    resObj.error = i18next.t("interService.error.voucherIsNotValid")
                }
                
                return resObj;
            }

            cancelLoad(paymentToCancel: any): Promise<any> {
                throw new Error("Method not implemented.");
            }
            loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
                throw new Error("Method not implemented.");
            }
            getCardNumber(): Promise<GetCardNumberResponse> {
                throw new Error("Method not implemented.");
            }

        }
    }
}