module PositiveTS {
    export module Components {
        export module SelfServiceKeypadModal {
            export function create() {
                let comp = {
                    template: JST.SelfServiceKeypadModel(),
                    components: {
                        selfServiceKeypad: PositiveTS.Components.SelfServiceKeypad.getComponent(),
                    },
                    setup(){

                        const globalStore = Pinia.useGlobalStore();
                        const {selfServiceKeypadInputVal,selfServiceKeypadInput} = Pinia.storeToRefs(globalStore);
                        const globalStoreProps = {selfServiceKeypadInputVal,selfServiceKeypadInput}

                        const {setSelfServiceKeypadInput,submitSelfServiceKeypadInput} = globalStore
                        const globalStoreActions = {submitSelfServiceKeypadInput,setSelfServiceKeypadInput}
          
                        return {...globalStoreProps,...globalStoreActions}
                      },
                    computed: {
                        modelVal: {
                            get() {
                                return this.selfServiceKeypadInput;
                            },
                            set(newValue) {
                                this.setSelfServiceKeypadInput(newValue);
                            }
                        }
                    },
                    watch: {
                        selfServiceKeypadInputVal(newVal, oldVal) {
                          this.modelVal = newVal;
                        }
                    },
                    methods: {
                        async open() {
                            this.setSelfServiceKeypadInput('');
                        },
                        enterDigit(newValue) {
                            this.setSelfServiceKeypadInput(newValue);
                        },
                        submit(submittedValue) {
                            this.submitSelfServiceKeypadInput(submittedValue);
                        },
                    },

                }
                VueApp.component('self-service-keypad-model', comp)
            }
        }
    }
}
