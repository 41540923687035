module PositiveTS {
    export module Service {
        export module PaymentPage {
            
            const CANCEL_URL = window.location.origin + '/payment_page/cancel'
            const FAIL_URL = window.location.origin + '/payment_page/fail'
            const SUCCESS_URL = window.location.origin + '/payment_page/success'

            export function isEnabled() {
                return Service.Gateway.isEnabled() && Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowPaymentPageHyp))
            }
            export function  getSuccessURL(){
                return SUCCESS_URL
            }
            export function  getFailURL(){
                return FAIL_URL
            }
            export function  getCancelURL(){
                return CANCEL_URL
            }

            export async function buildInitRequestIntInXml(creditAmount, creditCurrency,Xfield) {
                try {
                    let totalInAgorot = Math.round(creditAmount * 100);
                    let mpiMid = getMpiMid()
                    let ppsJSONConfig = {"uiCustomData":{"customStyle":""}};
                    let terminalNumber = Service.Gateway.terminalEmvWithChecksum();
                    let currency = creditCurrency;
                    let uniqueId = Storage.createUUID();
                    let shiftId1 = Service.Gateway.getShiftId1();
                    let shiftId2 = Service.Gateway.getShiftId2();
                    let shiftId3 = await Service.Gateway.getShiftId3();
                    let shiftTxnDate = moment().format('YYYY-MM-DD HH:mm:ss');
                    let successUrl = SUCCESS_URL;
                    let errorUrl = FAIL_URL;
                    let cancelUrl = CANCEL_URL;
                    if(window.innerWidth == 1024){
                        ppsJSONConfig["uiCustomData"]["virtualKeyboard"] = true;
                        ppsJSONConfig["uiCustomData"]["customStyle"] = "#cg-pd-title, #cg-key-currency, #cg-val-currency {display:none;} ";
                    }
                    if (window.innerWidth <= 1367){
                        ppsJSONConfig["uiCustomData"]["customStyle"] += " #cg-header {display:none;} .ng-star-inserted {margin-bottom:0px !important;} #cg-tx-info {width:33% !important} lib-virutal-numeric-keyboard.ng-star-inserted {display: block !important} #cg-form-title {padding-bottom:8px;} .cg-form-controls {margin:0px !important} .form-group{min-height:70px} body::-webkit-scrollbar {width: 0px;} body::-webkit-scrollbar-thumb {background: transparent;}"
                    }
                    return `<ashrait>
                                <request>
                                    <version>2000</version>
                                    <language>HEB</language>
                                    <dateTime/>
                                    <requestId/>
                                    <command>doDeal</command>
                                    <doDeal>
                                        <terminalNumber>${terminalNumber}</terminalNumber>
                                        <cardNo>CGMPI</cardNo>
                                        <total>${totalInAgorot}</total>
                                        <transactionType>Debit</transactionType>
                                        <creditType>RegularCredit</creditType>
                                        <currency>${currency}</currency>
                                        <transactionCode>Phone</transactionCode>
                                        <validation>TxnSetup</validation>
                                        <mid>${mpiMid}</mid>
                                        <user>${Xfield}</user>
                                        <uniqueid>${uniqueId}</uniqueid>
                                        <mpiValidation>AutoCommHold</mpiValidation>
                                        <shiftId1>${shiftId1}</shiftId1>
                                        <shiftId2>${shiftId2}</shiftId2>
                                        <shiftId3>${shiftId3}</shiftId3>
                                        <shiftTxnDate>${shiftTxnDate}</shiftTxnDate>
                                        <successUrl>${successUrl}</successUrl>
                                        <errorUrl>${errorUrl}</errorUrl>
                                        <cancelUrl>${cancelUrl}</cancelUrl>
                                        <paymentPageData>
                                            <ppsJSONConfig>${JSON.stringify(ppsJSONConfig)}</ppsJSONConfig>
                                        </paymentPageData>
                                    </doDeal>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    Promise.reject(err)
                }
            }

            export async function buildInquireIntInXml(txId) {
                try {
                    let mpiMid = getMpiMid()
                    let terminalNumber = Service.Gateway.terminalEmvWithChecksum(); 
                    return `<ashrait>
                                <request>
                                    <requestId></requestId>
                                    <version>2000</version>
                                    <language>HEB</language>
                                    <dateTime/>
                                    <command>inquireTransactions</command>
                                    <inquireTransactions>
                                        <terminalNumber>${terminalNumber}</terminalNumber>
                                        <queryName>mpiTransaction</queryName>
                                        <mid>${mpiMid}</mid>
                                        <mpiTransactionId>${txId}</mpiTransactionId>
                                    </inquireTransactions>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err)
                }
            }



            export async function buildJ9IntInXml(inquireData) {
                try {
                    let inquireResponse = inquireData?.row?.cgGatewayResponseXML?.ashrait?.response;
                    if (!(inquireData?.row?.statusCode == "0")) {
                        throw new Error(inquireData.statusText)
                    }
                    return `<ashrait>
                                <request>
                                    <version>2000</version>
                                    <language>HEB</language>
                                    <dateTime/>
                                    <requestid/>
                                    <command>doDeal</command>
                                    <doDeal>
                                        <terminalNumber>${inquireResponse.doDeal?.terminalNumber}</terminalNumber>
                                        <validation>AutoCommRelease</validation>
                                        <tranId>${inquireResponse.tranId}</tranId>
                                    </doDeal>
                                </request>
                            </ashrait>`
                } catch (err) {
                    console.error(err);
                    return Promise.reject(err);
                }
            }

            export function setLasGatewayPaymentTxUrl(lastTx) {

                Pinia.posStore.setIsPaymentPageActive(true)
                localStorage.setItem('lasGatewayPaymentTxUrl', lastTx)
            }
            export function getTxIdFromLasGatewayPaymentUrl() {
                return getParamByKey("txId")
            }
            export function isTxExpired() {
                let expiredDate = getParamByKey("createdAt")
                if(!expiredDate){
                    return true;
                }
                const createdAt = moment(expiredDate);
                const now = moment();
                const diffInMinutes = now.diff(createdAt, 'minutes');
                return diffInMinutes >= 10;
            }
            export function activePayments(){
                let payments = [];
                try{
                    let allPayments = posVC.salePayments;
                    if(allPayments.length ==0){
                        return [];
                    }
                    let creditCardPayment = allPayments.filter(p => p.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT);
                    if(creditCardPayment.length ==0){
                        return [];
                    }
                    let creditCardPaymentsData = JSON.parse(creditCardPayment[0].data)
                    return creditCardPaymentsData.filter(p => p.isGateway)
                    
                }catch(err){
                    Logger.error(err);
                    return payments;
                }
                
            }
            function getParamByKey(key) {
                let lasGatewayPaymentTxUrl = getLasGatewayPaymentTxUrl();
                if (lasGatewayPaymentTxUrl) {
                    let url = new URL(lasGatewayPaymentTxUrl);
                    let params = new URLSearchParams(url.search);
                    let value = params.get(key);
                    return value;
                }
            }
            export function convertToGatewayOutput(inquireResponse, j9Response) {
                try {
                    let inquireData = inquireResponse?.row?.cgGatewayResponseXML?.ashrait?.response?.doDeal
                    let paymentGatewayOutput = new PositiveShared.PaymentGatewayOutput()
                    let terminalNumber = Service.Gateway.terminalEmvWithChecksum();
                    paymentGatewayOutput.CreditTerms = "1";
                    paymentGatewayOutput.setCommand = j9Response.command;
                    paymentGatewayOutput.TerminalId = inquireData.terminalNumber ;
                    paymentGatewayOutput.OriginalTerminalId = terminalNumber ;
                    paymentGatewayOutput.ParameterJ = inquireData.validation["@code"];
                    paymentGatewayOutput.Status = j9Response.doDeal.status;
                    paymentGatewayOutput.ResultCode = j9Response.result;
                    paymentGatewayOutput.Mti = j9Response.doDeal.ashraitEmvData.mti;
                    paymentGatewayOutput.IdFlag = inquireData.ashraitEmvData.idFlag;
                    paymentGatewayOutput.CvvFlag = inquireData.ashraitEmvData.cvvFlag;
                    paymentGatewayOutput.CardId = j9Response.doDeal.cardId;
                    paymentGatewayOutput.CardExpiration = j9Response.doDeal.cardExpiration;
                    paymentGatewayOutput.CardName = inquireData.cardName;
                    paymentGatewayOutput.SlaveTerminalNumber = inquireData.slaveTerminalNumber ?? "";
                    paymentGatewayOutput.SlaveTerminalSequence = inquireData.slaveTerminalSequence ?? ''
                    paymentGatewayOutput.SupplierNumber = j9Response.doDeal.supplierNumber;
                    paymentGatewayOutput.DateTime = j9Response.dateTime;
                    paymentGatewayOutput.AuthNumber = j9Response.doDeal.authNumber;
                    paymentGatewayOutput.AuthCodeCreditCompany = j9Response.doDeal.ashraitEmvData.authCodeCreditCompany? j9Response.doDeal.ashraitEmvData.authCodeCreditCompany["@code"] : '';
                    paymentGatewayOutput.Version = j9Response.version;
                    paymentGatewayOutput.User = j9Response.doDeal.user;
                    paymentGatewayOutput.TransactionType = j9Response.doDeal.transactionType["@code"];
                    paymentGatewayOutput.Amount = j9Response.doDeal.total;
                    paymentGatewayOutput.CardMask = j9Response.doDeal.cardMask;
                    paymentGatewayOutput.CardLength = j9Response.doDeal.cardLength;
                    paymentGatewayOutput.CardNo = j9Response.doDeal.cardNo;
                    paymentGatewayOutput.CurrencyCode = j9Response.doDeal.currency["@code"];
                    paymentGatewayOutput.Currency = j9Response.doDeal.currency["#text"];
                    paymentGatewayOutput.TransactionCode = j9Response.doDeal.transactionCode["@code"];
                    paymentGatewayOutput.CardAcquirer = j9Response.doDeal.cardAcquirer ? j9Response.doDeal.cardAcquirer["@code"] : '';
                    paymentGatewayOutput.CardBrand = j9Response.doDeal.cardBrand ?j9Response.doDeal.cardBrand["@code"] : '';
                    paymentGatewayOutput.CreditCompany = j9Response.doDeal.creditCompany ? j9Response.doDeal.creditCompany["@code"] : '';
                    paymentGatewayOutput.Uid = j9Response.doDeal.ashraitEmvData.uid;
                    paymentGatewayOutput.CreditType = j9Response.doDeal.creditType["@code"];
                    paymentGatewayOutput.TranID = inquireResponse?.row?.cgGatewayResponseXML?.ashrait?.response?.tranId;
                    paymentGatewayOutput.BaseAmount = j9Response.doDeal.baseAmount ? j9Response.doDeal.baseAmount : '';
                    paymentGatewayOutput.BaseCurrency = j9Response.doDeal.baseCurrency ? j9Response.doDeal.baseCurrency : '';
                    paymentGatewayOutput.MpiTransactionId = inquireResponse.row.mpiTransactionId;
                    paymentGatewayOutput.NoPayment = j9Response.doDeal.numberOfPayments ? j9Response.doDeal.numberOfPayments : '';
                    paymentGatewayOutput.PeriodicalPayment = j9Response.doDeal.periodicalPayment ? j9Response.doDeal.periodicalPayment : '';
                    paymentGatewayOutput.FileNumber = inquireData.fileNumber ?? '';
                    paymentGatewayOutput.Language = j9Response.language;
                    paymentGatewayOutput.ShiftId1 = j9Response.doDeal.shiftId1 ?? '';
                    paymentGatewayOutput.ShiftId2 = j9Response.doDeal.shiftId2 ?? '';
                    paymentGatewayOutput.ShiftId3 = j9Response.doDeal.shiftId3  ?? '';
                    paymentGatewayOutput.FirstPayment = j9Response.doDeal.firstPayment ? j9Response.doDeal.firstPayment : '';
                    return paymentGatewayOutput;

                } catch (err) {
                    console.error(err);
                    return Promise.reject(err);
                }
            }

            export function removeLastGatewayPaymentTxUrl() {
                Pinia.posStore.setIsPaymentPageActive(false)
                localStorage.removeItem('lasGatewayPaymentTxUrl')
            }

            export function getLasGatewayPaymentTxUrl() {
                return localStorage.getItem('lasGatewayPaymentTxUrl')
            }

            function getMpiMid() {
                try {
                    let mpiMid = jsonConfig.getVal(jsonConfig.KEYS.parameterMidForPaymentPage);
                    if (!mpiMid) {
                        throw new Error(i18next.t('paymentPage.errors.pleaseCheckTheMpiMid'))
                    }
                    return mpiMid
                } catch (err) {
                console.error(err);
                    return Promise.reject(err)
                }
            }
            export async function goToPosPaymentPageIfNeeded(){
                if(Service.PaymentPage.isTxExpired()){
                    Service.PaymentPage.removeLastGatewayPaymentTxUrl();
                    return;
                }
                try{
                    let lastTx = Service.PaymentPage.getTxIdFromLasGatewayPaymentUrl()
                    let int_in = await Service.PaymentPage.buildInquireIntInXml(lastTx);
                    await Service.Gateway.sendRequestToCreditGuard(int_in);
                }catch(err){
                    if(err.message.contains('689')){
                        Service.PaymentPage.removeLastGatewayPaymentTxUrl();
                        return;
                    }
                }
                await posVC.goToPaymentScreen()
                Pinia.posStore.setIsPaymentPageActive(true);
                PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('paymentPage')
            }
        }
    }
}
