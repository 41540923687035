
module PositiveTS {
	export module Components {
		export module OsherAdComponent {
			export const name = 'OsherAdComponent';
			export function create() {
				let component = {
					template: JST.OsherAdComponent(),
					components: {
						positiveIcon: PositiveIcon.getComponent(),
						positiveLottie: PositiveLottie.getComponent(),
						OsherAdError: OsherAdError.getComponent(),
						OsherAdSaleComponent: OsherAdSaleComponent.getComponent(),
					},
					data() {
						return {
							visible: false,
							isWaitingForSaleScanReady: false,
						}
					},
					methods: {
						goToHomepage() {
							PositiveTS.VueInstance.goToHomepage();
						},
						open() {
							this.visible = true;
						},
						close() {
							this.visible = false;
						},
						setStep(step: number) {
							Pinia.posStore.setAutoStoreStep(step)
						},
						saleScanReady() {
							if (this.isWaitingForSaleScanReady) {
								return;
							}
							this.isWaitingForSaleScanReady = true;
							Service.OsherAd.saleScanReady();
							setTimeout(() => {
								this.isWaitingForSaleScanReady = false;
							}, 5000);
						},
						startScan(){
							Service.OsherAd.startScan();
						},
						cancelScan(){
							Service.OsherAd.cancelScan();
						},
					},
					computed: {
						autoStore() {
							return Pinia.posStore.autoStore
						},
						step(): number {
							return this.autoStore?.step || Enums.OsherAdSteps.Initial;
						},
						steps() {
							return this.autoStore.config.steps;
						},
						logo(): string {
							return this.autoStore.config.logo;
						},
						icon(): string {
							return this.autoStore.config.icon;
						},
						stepInObject(): boolean {
							return this.step in this.steps;
						},
						hypLogo() {
							return `/versions/${PositiveTS.Version.appVersion}/assets/images/logos/hyp.png`
						},
					}

				}
				VueApp.component('osher-ad-component', component);
			}
		}
	}
}
