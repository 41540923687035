module PositiveTS {
  export module Components {
    export module PriorityCustomerClub {
      const dialogSelector = "priority-customer-club-dialog"

      function convertToPosStructure(customer) {
        let uglyCustObj = {}

        uglyCustObj["s_id_number"] = customer.customer_tz
        uglyCustObj["s_member_no"] = customer.customer_number
        uglyCustObj["customer_group_id"] = customer.customer_group_id
        uglyCustObj["customer_group_name"] = customer.customer_group_name
        uglyCustObj["s_first_name"] = customer.first_name//has only one field customer name on priority
        // uglyCustObj["s_last_name"] = customer.last_name
        uglyCustObj["email"] = customer.email
        uglyCustObj["msg_for_screen"] = "" //TODO
        uglyCustObj["msg_for_slip"] = "" //TODO
        uglyCustObj["cust_points"] = customer.current_points
        uglyCustObj["is_valid"] = (moment(new Date(customer.valid_until)).diff(moment(new Date()), 'days') >= 0)

        let pointsForUse = customer.current_points < jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse) ? 0 : customer.current_points //TODO - parameter at club level
        let multiplyPointsVal = jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication)
        if (pointsForUse > 0 && multiplyPointsVal != null && multiplyPointsVal > 0) {
          pointsForUse = pointsForUse - pointsForUse % multiplyPointsVal;
        }
        uglyCustObj["cust_points_for_use"] = pointsForUse
        uglyCustObj["i_point_value"] = 1 //TODO - parameter at club level
        uglyCustObj["i_club_id"] = customer.customer_club_id
        uglyCustObj["dt_birth_date"] = customer.date_of_birth
        uglyCustObj["address"] = `${customer.street_address || ""} ${customer.apartment_number || ""} ${customer.city || ""}`
        uglyCustObj["s_phone_number_1"] = customer.phone_number || ''
        uglyCustObj["db_id"] = customer.id
        uglyCustObj["zip_code"] = customer.zip_code
        uglyCustObj["val_per_points"] = uglyCustObj["i_point_value"] * uglyCustObj["cust_points_for_use"]
        uglyCustObj["clubName"] = 'priority'
        uglyCustObj["discount_percent"] = customer.discount_percent

        uglyCustObj["promotion_types"] = customer.promotion_types

        uglyCustObj["block_from_mailing_list"] = customer.block_from_mailing_list
        uglyCustObj["accept_mailing"] = customer.accept_mailing

        return uglyCustObj;
      }
      function convertFromPosStructure(uglyCustObj) {
        this.customer = {}
        this.customer.customer_tz = uglyCustObj["s_id_number"]
        this.customer.customer_number = uglyCustObj["s_member_no"]
        this.customer.customer_group_id = uglyCustObj["customer_group_id"]
        this.customer.customer_group_name = uglyCustObj["customer_group_name"]
        this.customer.discount_percent = uglyCustObj["discount_percent"]
        this.customer.street_address = uglyCustObj["address"]
        this.customer.first_name = uglyCustObj["s_first_name"]//has only one field customer name on priority
        // this.customer.last_name = uglyCustObj["s_last_name"]
        this.customer.id = uglyCustObj["db_id"]
        this.customer.email = uglyCustObj["email"]
        this.customer.current_points = uglyCustObj["cust_points"]

        this.customer.customer_club_id = uglyCustObj["i_club_id"]
        this.customer.date_of_birth = uglyCustObj["dt_birth_date"]
        this.customer.phone_number = uglyCustObj["s_phone_number_1"] || ''
        this.customer.zip_code = uglyCustObj["zip_code"]
        this.customer.block_from_mailing_list = uglyCustObj["block_from_mailing_list"]
        this.customer.accept_mailing = uglyCustObj["accept_mailing"]

      }
      function initData() {
        return {
          searchInput: "",
          loading: false,
          customerSelected: false,
          showSearchResults: false,
          searchCustomerMode: false,
          noCustomersFound: false,
          customer: {},
          customers: [],
          onSelectCustomerOnlyMode: false,
          selectedCustomerResolve: null,
          dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
          addNewCustomerMode: false,
          priorityUserData: PositiveTS.Service.PriorityService.getPriorityUserData(),
          hasCustomerFieldIdentityCard: false,
          editCustomerMode: false,
          hasCustomerFieldAcceptMailing: false,
        }
      }

      export function create() {
        let priorityCustomerClub = {
          template: JST.priorityCustomerClub(),
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },
          methods: {
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },

            async open() {
              try {
                let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
                let customer = customerClubService.getCurrentSelectedCustomer()
                this.setValuesAterCreatedOrOpen()

                if (customer) {
                  convertFromPosStructure.call(this, customer)
                  this.customerSelected = true
                } else {
                  this.searchCustomerMode = true
                }

                if (this.dialogElem == null) {
                  this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                }

                this.dialogElem.show()

                this.$nextTick(() => {
                  $(document).unbind('keypress')
                })
              } catch (e) {
                console.error(e)
                this.showGenericError()
              }
            },

            close(cleanData = true) {
              if (this.dialogElem == null) {
                this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
              }

              this.dialogElem.close()
              if (cleanData) {
                this.cleanData()
              }
            },
            async swipeCard(): Promise<void> {
              app.showLoadingMessageDialog(i18next.t("mizdamenOrHakafaDialog.swipeCustomerCard"));
              const swipeCardInput = await Service.EMV.swipe();
              if (this.$refs.searchInputText) {
                this.searchInput = swipeCardInput || "";
                if (this.searchInput) {
                  await this.searchCustomer()
                }
              } else if (this.$refs.customerNumber) {
                this.customer.customer_number = swipeCardInput || "";
              }

              app.hideLoadingMessageDialog();
            },

            async searchCustomer() {
              try {
                let search = this.searchInput.trim()
                if (search == "") {
                  return
                }

                this.loading = true
                app.showLoadingMessage(i18next.t("positiveCustClub.findingCustomer"));

                let result = await PositiveTS.Service.PriorityService.searchCustomersPriority(search)
                if (!result.success || result.error) {
                  this.showError(result.error)
                  app.hideLoadingMessage()
                  return
                }

                this.showCustomers({ customers: this.parseCustomersPriority(result.customers) })
                app.hideLoadingMessage()
                console.debug(this.searchInput)
              } catch (error) {
                console.error(error)
                app.hideLoadingMessage()
                this.showGenericError()
              } finally {
                this.loading = false
              }
            },

            parseCustomersPriority(customers: Array<object>) {
              let result = [];

              if (Array.isArray(customers)) {
                customers.map((customer) => {
                  let customerObject: any = {
                    address: `${customer['ADDRESS'] || ''} ${customer['ADDRESS2'] || ''} ${customer['ADDRESS3'] || ''}`,
                    amount: customer['CUST'],
                    customer_number: customer['CUSTNAME'],
                    email: customer['EMAIL'],
                    name: customer['CUSTDES'],
                    phone_number: customer['PHONE'],
                    first_name: customer['CUSTDES'],
                  }

                  if (this.hasCustomerFieldIdentityCard) {
                    customerObject.customer_tz = customer[this.priorityUserData.customer_field_identity_card] || null
                  }

                  if (this.hasCustomerFieldAcceptMailing) {
                    customerObject.accept_mailing = customer[this.priorityUserData.customer_field_accept_mailing] == 'Y' ? true : false
                  }

                  result.push(customerObject)
                })
              }

              return result
            },

            showCustomers(result: any) {
              this.customers = result.customers
              this.showSearchResults = true
              this.noCustomersFound = result.customers.length == 0
            },

            showError(errStr) {
              app.showAlert({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                null, null)
            },

            showGenericError() {
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('generalError'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                null, null)
            },

            async selectCustomer(customer) {
              try {
                this.customer = customer
                let result = await (new PositiveTS.Storage.Entity.CustomerGroup()).all()

                if (customer.customer_group_id) {
                  customer.customer_group_name = result.filter(row => { return row.code == customer.customer_group_id })[0].name;
                }

                let customerClubService = new Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
                await customerClubService.setCurrentSelectedCustomer(convertToPosStructure.call(this, customer))
                await posVC.loadSale()
                this.customerSelected = true
                this.close()
              } catch (e) {
                console.error(e)
                this.showGenericError()
              }
            },

            async customerHistory(customer) {
              try {
                app.showLoadingMessage()
                if (posUtils.isBlank(customer)) {
                  this.showGenericError()
                  console.error('customer is blank')
                  return
                }

                await Pinia.componentsStore.openComponent( { componentName: "priorityCustomerReportDialog", args: [customer] })
                this.close(false)
                app.hideLoadingMessage()
              } catch (error) {
                console.error(error)
                app.hideLoadingMessage()
                this.showGenericError()
              }
            },

            async putCustomerInSale(customer) {
              if (moment(new Date(customer.valid_until)).diff(moment(new Date()), 'days') < 0) {
                await Service.CustomerClubSpecialItem.addSpecialItem(Service.CustomerClubSpecialItem.ClubMemberRenewHndlr)
              }
              if (this.onSelectCustomerOnlyMode && this.selectedCustomerResolve) {
                this.selectedCustomerResolve(customer)
                this.close()
                return
              }
              this.selectCustomer(customer);
            },

            setMode(mode) {
              switch (mode) {
                case "search":
                  this.searchCustomerMode = true
                  this.addNewCustomerMode = false
                  this.editCustomerMode = false
                  break;
                case "new":
                  this.searchCustomerMode = false
                  this.addNewCustomerMode = true
                  this.editCustomerMode = false
                  break;
                case "edit":
                  this.editCustomerMode = true
                  this.searchCustomerMode = false
                  this.addNewCustomerMode = false
                  break;
                default:
                  //do noting
                  break;
              }
            },

            selectInput(field) {
              this.$refs[field].focus();
            },

            validate(): Promise<Array<any>> {
              //todo
              return new Promise((resolve, reject) => {
                let errors = []

                if (!this.customer.customer_number || this.customer.customer_number?.trim() === "") {
                  errors.push({ field: "customerNumber", error: i18next.t("positiveCustClub.mustEnterCardNumber") })
                }
                if (this.customer.customer_number?.trim().length > PositiveTS.Service.CustomerClub.CUSTOMER_TEXT_INPUT_LEGTH) {
                  errors.push({ field: "customerNumber", error: i18next.t("positiveCustClub.toLongTextInput") })
                }
                if (!this.customer.first_name || this.customer.first_name.trim() === "") {
                  errors.push({ field: "firstName", error: i18next.t("priorityCustomerClub.mustEnterCustomerName") })
                }

                resolve(errors)
              })
            },

            async addNewCustomer() {
              try {
                let errors = await this.validate()
                if (errors.length > 0) {
                  this.showErrors(errors)
                }
                else {
                  const customer = this.prepareCustomerPriorityObejct()
                  let res = await PositiveTS.Service.PriorityService.request('POST', 'CUSTOMERS', customer)
                  if (res.success) {
                    this.selectCustomer(this.customer)
                  } else {
                    console.error('Priority addNewCustomer response', res)
                    if (res.status = 409) {
                      this.showError(i18next.t("priorityCustomerClub.errorCustomerExist"))
                      return
                    }
                    this.showError(i18next.t("positiveCustClub.errorOnAddCustomer"))
                  }
                }
              } catch (e) {
                console.error(e)
                let message = i18next.t("positiveCustClub.errorOnAddCustomer")
                this.showError(message)
              } finally {
                app.hideLoadingMessage()
              }
            },

            async updateCustomer() {
              try {
                let errors = await this.validate()
                if (errors.length > 0) {
                  this.showErrors(errors)
                }
                else {
                  const customer = this.prepareCustomerPriorityObejct()
                  let res = await PositiveTS.Service.PriorityService.request('PATCH', `CUSTOMERS('${customer.CUSTNAME}')`, customer)
                  if (res.success) {
                    this.selectCustomer(this.customer)
                  } else {
                    console.error('Priority updateCustomer response', res)
                    this.showError(i18next.t("priorityCustomerClub.errorOnUpdateCustomer"))
                  }
                }
              } catch (e) {
                console.error(e)
                this.showError(i18next.t("priorityCustomerClub.errorOnUpdateCustomer"))
              } finally {
                app.hideLoadingMessage()
              }
            },

            showErrors(errors: Array<{ field: string, error: string }>) {
              let errStr = ''
              errors.forEach((el) => { errStr += `${el.error}<br>` })
              app.showAlert({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              },
                () => {
                  this.$refs[errors[0].field].focus()
                }, null)
            },

            prepareCustomerPriorityObejct() {
              let customer = {
                CUSTNAME: this.customer.customer_number,
                CUSTDES: this.customer.first_name,
                PHONE: posUtils.isBlank(this.customer.phone_number) ? null : this.customer.phone_number,
                EMAIL: posUtils.isBlank(this.customer.email) ? null : this.customer.email,
              }

              if (this.hasCustomerFieldIdentityCard && !posUtils.isBlank(this.customer.customer_tz)) {
                customer[`${this.priorityUserData.customer_field_identity_card}`] = this.customer.customer_tz
              }

              if (this.hasCustomerFieldAcceptMailing) {
                customer[`${this.priorityUserData.customer_field_accept_mailing}`] = this.customer.accept_mailing ? 'Y' : 'N'
              }

              return customer
            },

            setValuesAterCreatedOrOpen() {
              this.hasCustomerFieldIdentityCard = !posUtils.isBlank(this.priorityUserData?.customer_field_identity_card)
              this.hasCustomerFieldAcceptMailing = !posUtils.isBlank(this.priorityUserData?.customer_field_accept_mailing)
            }
          },
          data: initData,
          created() {
            this.setValuesAterCreatedOrOpen()
          }
        }
        VueApp.component('priority-customer-club', priorityCustomerClub)
      }
    }
  }
}

