module PositiveTS {
export module Service {
export module Coupon {
  function getAllCounpons() {
    let coupon = new Storage.Entity.Coupon();
    return coupon.getCache<Storage.Entity.Coupon>("dbCache");
  }

  function parsePaymentData(byRefAry, paymentData) {
    var prasedAry = JSON.parse(paymentData);
    var voucher_type_id;
    for (var i=0;i<prasedAry.length;i++ ){
      voucher_type_id = prasedAry[i].voucher_type_id;
      if (voucher_type_id) {
        byRefAry.push( voucher_type_id );
      }
    }
  }

  function getVoucherPaymentsVoucherTypeIds(salePayments) {
     var _return = [];
    for (var i = 0; i < salePayments.length; i++) {
      var payment = salePayments[i];
      if (payment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER)  {
        parsePaymentData(_return, payment.data);
      }
    }
    return _return;
  }

  function isRowEffectiveVoucher(row, salePayments) {
    var vcTypeIds = getVoucherPaymentsVoucherTypeIds(salePayments);
    var isMandatoryVcEffective =  vcTypeIds.indexOf( row.mandatory_voucher )  > -1;

    return isMandatoryVcEffective;
  }

  function isRowEffective(row, salePayments, totalPrice){
    var allowedStors = [];
    var isAllowedStore = true;
    if (row.store_list) {
      allowedStors = row.store_list.split(",");
      isAllowedStore = allowedStors.indexOf( session.store.storeID ) > -1 ;
    }


    var isAllowedCompany = session.company.companyID == row.company_id;
    var isAllowedTenant = session.company.tenantID == row.tenant_id;

    var isDateEffective = new Date(row.used_at) < new Date() &&
                          new Date(row.expire_at) > new Date();

    var isAmntEffective = row.amount_threshold < totalPrice;

    var isVoucherEffective = !row.mandatory_voucher || isRowEffectiveVoucher(row, salePayments);

    return (isAllowedStore && isAllowedCompany && isAllowedTenant && isDateEffective && isAmntEffective && isVoucherEffective);
  };

  function filterEffectiveRows(data, salePayments, totalPrice) {
    var all_data = data;
    var _return_array = [];
    var row;
    // for each data item
    for (var i = 0; i < data.length; i++) {
      row = all_data[i];
      if (
          isRowEffective(row, salePayments, totalPrice)
         ) {
        _return_array.push({
          operation_code: row.operation_code,
          remark: row.remark
        });
      }
    }

    return _return_array;
   };

  function getVoucherResumeAryForSaleJsondata(data, salePayments, totalPrice) {
    var _return_array = [];
    var row;
    for (var i = 0; i < data.length; i++) {
      row = data[i];
      if (isRowEffective(row, salePayments, totalPrice) && row.resume_voucher){
        _return_array.push(row.resume_voucher);
      }
    }
    return _return_array;
  };

  function saveResumeVoucherArray(sale, resumeVoucherArray) {
    if (resumeVoucherArray.length === 0) {
      return
    }


    var jsonDataDict = JSON.parse( sale.jsondata );
    jsonDataDict.resumeVoucherArray = resumeVoucherArray;
    jsonDataDict.voucherBarcode = generateCouponNumber(resumeVoucherArray.length)
    sale.jsondata = JSON.stringify(jsonDataDict);
  };

  export function getEffectiveCouponsForPrinting(salePayments, totalPrice) {
    return getAllCounpons()
    .then (function(data){
      return filterEffectiveRows(data, salePayments, totalPrice);
    });
  };

  export function setSaleJsondata(sale, salePayments, totalPrice) {

    return getAllCounpons()
    .then((data) => {
      let resumeVoucherArray = getVoucherResumeAryForSaleJsondata(data, salePayments, totalPrice);
      return saveResumeVoucherArray(sale, resumeVoucherArray);
    })
  };

  export function generateCouponNumber(numberOfCoupons = 1): Array<string> {
    let couponNumbers = []

    for (let index = 0; index < numberOfCoupons; index++) {
      let increment = (index + 1)
      couponNumbers.push((((Date.now() + increment) * 99999999)).toString())
    }

    return couponNumbers
  }
}}}









  
