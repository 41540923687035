module PositiveTS {
    export module Service {
        export module Fingerprint {

            //TODO: replace all "throw Error()" into real response!
            //the functions called here ends up in a very old code
            //and i don't have the time to refactor everything



            const fingerprintUrl = '/fingerprints';

            const errors = {
                NO_READER_FOUND: 1001,
                REGISTRATION_FAILED: 1002,
                USER_NOT_FOUND: 1003,
                USER_ALREADY_EXISTS: 1004,
                QUALITY_TOO_LOW: 1005,
                INTERNAL_DB_NOT_POPULATED: 1006,
                GENERIC_ERROR: -1000,
            }

            function versionCheck() {
                //TODO!!!!! what addon version should it be?!
                if (session.addonFilesVersion < 2.82 && session.isAndroid == false) {
                    return false;
                }

                return true;
            }

            export async function populateDB() {

                let localFingerprintsDB = localStorage.getItem("localFingerprints");

                if (Pinia.globalStore.isOnline) {
                    app.showLoadingMessage(i18next.t('fingerprint.fetchingFingerprintsFromServer'))
                    let response = await FetchReq.textReq(fingerprintUrl, 'get')
                    if (response.response.ok) {
                        localStorage.setItem("localFingerprints", response.result)
                        localFingerprintsDB = response.result
                    }
                    else {
                        console.error("error in fetching fingerprints from server: " + response + "\n fetching fingerprint from local storage");
                    }
                    app.hideLoadingMessage()
                }
                else {
                    console.error("POS is offline \n fetching fingerprint from local storage");
                }


                if (!localFingerprintsDB) {
                    return {
                        success: false,
                        data: "No local fingerprints db"
                    }
                }

                app.showLoadingMessage(i18next.t('fingerprint.updatingDeviceDB'))

                let addonResponse = await Service.GenericAddon.sendNativeMessageToExtension({
                    type: jsonConfig.getVal(jsonConfig.KEYS.fingerprintReaderType),
                    posDB: localFingerprintsDB,
                    action: "POPULATE"
                }, "fingerprint_v2");
                app.hideLoadingMessage();

                if (addonResponse.request.result.success) {
                    return {
                        success: true,
                        data: localFingerprintsDB
                    }
                }
                else {
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('fingerprint.errorInPopulating') + "\n" + i18next.t(`fingerprint.error.${addonResponse.request.result.error}`),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true,
                    })
                    return {
                        success: false,
                        data: addonResponse
                    }
                }
            }



            export function isEnrolled(employeeID, db?): boolean {
                if (!db) {
                    if(localStorage.getItem("localFingerprints") == null){
                        return false;
                    }
                    else{
                        db = localStorage.getItem("localFingerprints")
                    }
                }
                let parsedDB = JSON.parse(db).db
                if (parsedDB[employeeID]) {
                    return true;
                }

                return false;
            }

            export async function enroll(employeeID) {
                if (!versionCheck()) {
                    throw Error(i18next.t('fingerprint.addonUpdateIsNeeded'));
                }

                let populateResponse = await populateDB();

                if (!populateResponse.success) {
                    throw Error(i18next.t('fingerprint.enrollError'))
                }
                else if (isEnrolled(employeeID, populateResponse.data)) {
                    throw Error(i18next.t('fingerprint.alreadyEnrolled'))
                }

                app.showLoadingMessage(i18next.t('fingerprint.enrolling'))
                let addonResponse = await Service.GenericAddon.sendNativeMessageToExtension({
                    type: jsonConfig.getVal(jsonConfig.KEYS.fingerprintReaderType),
                    employeeID: employeeID,
                    action: "ENROLL"
                }, "fingerprint_v2");
                app.hideLoadingMessage();

                if (addonResponse.request.result.success) {
                    app.showLoadingMessage(i18next.t("fingerprint.updatingFingerprintInServer"))

                    let updateInServerResponse = await FetchReq.jsonReq(fingerprintUrl, 'post', {
                        employee_unq: employeeID,
                        employee_hash: addonResponse.request.result.data
                    })

                    app.hideLoadingMessage();

                    if (updateInServerResponse.result.success) {
                        console.log("fingerprint successfull")
                    }
                    else {
                        await Service.GenericAddon.sendNativeMessageToExtension({
                            type: jsonConfig.getVal(jsonConfig.KEYS.fingerprintReaderType),
                            employeeID: employeeID,
                            action: "REMOVEFROMDB"
                        }, "fingerprint_v2");

                        console.error("error in fetching fingerprints from serrver: " + updateInServerResponse);
                        throw Error(i18next.t('fingerprint.updateFingerprintInServerError'))
                    }
                }
                else {
                    console.error("error in fingerprint enroll :" + addonResponse)
                    throw Error(i18next.t('fingerprint.enrollError') + "\n" + i18next.t(`fingerprint.error.${addonResponse.request.result.error}`))
                }
            }

            export async function verify(): Promise<string> {
                if (!versionCheck()) {
                    throw Error(i18next.t('fingerprint.addonUpdateIsNeeded'));
                }

                app.showLoadingMessageDialog(i18next.t('fingerprint.verifying'))
                let addonResponse = await Service.GenericAddon.sendNativeMessageToExtension({
                    type: jsonConfig.getVal(jsonConfig.KEYS.fingerprintReaderType),
                    action: "VERIFY"
                }, "fingerprint_v2");
                app.hideLoadingMessageDialog();

                if (addonResponse.request.result.success) {
                    return addonResponse.request.result.data;
                }
                else {
                    console.error(addonResponse);
                    if (addonResponse.request.result.error == errors.INTERNAL_DB_NOT_POPULATED) {
                        let populateResult = await populateDB();
                        if (!populateResult.success) {
                            return;
                        }
                        return verify();
                    } else {
                        throw Error(i18next.t('fingerprint.verifyingError') + "\n" + i18next.t(`fingerprint.error.${addonResponse.request.result.error}`))
                    }
                }
            }
        }
    }
}