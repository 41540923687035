module PositiveTS {
  export module Components {
    export module FlightsIsrairPrintStockCountReport {

      export function getComponent() {
        return {
          template: JST.flightsIsrairPrintStockCountReport(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin],
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {currentFlight} = Pinia.storeToRefs(flightsStore);
            return {currentFlight}
          },
          computed: {
            isStartCount() {
              return this.wizardData.openFlight;
            },
            flightCode() {
              return this.isStartCount ? this.wizardData.flightCode : this.currentFlight.code;
            }
          },
          methods: {
            print() {
              try {
                Printing.FlightReports.printIsrairFlightInventoryReport(session.store.storeName,
                  this.flightCode,
                  Array.from(session.allItems.values()),
                  this.wizardData.openFlight,
                  this.wizardData.itemsCount,
                  this.wizardData.itemsStartCountByCode,
                  this.wizardData.itemsSoldByCode);

                this.goToNextStep();
              } catch (err) {
                app.hideLoadingMessage();
                console.error(err);
                this.showGenericError();
              }
            }
          },
        }
      }
    }
  }
}