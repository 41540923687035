module PositiveTS {
export module Components {
export module ValuCardBalance {
  const _dialogSelector = "#positive-valu-card-balance-dialog"
  const _vueComponentName = 'valu-card-balance';

  export function create() {
    let vueObj = {
        template: JST.valuCardBalance(),
        methods: {
          open: _open,
          close: _close,
          openValuCardReport: _openValuCardReport,
        },
        data: _initData

    }

    VueApp.component(_vueComponentName,vueObj)

  }

  function _openValuCardReport(){

    this.close();
Pinia.componentsStore.openComponent( {componentName:"valuCardReport", args: [this.currentBalance, this.valuCardNumber]});



  }

  function _open(currentBalance: string, valuCardNumber: string){
    $(document).unbind('keypress');
    $(function() {
      $(_dialogSelector).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: 'positive-dialog',
        width: '80%',
        height: 230,
        resizable: false,
        closeOnEscape: false,
        draggable: false,
      });
      $(_dialogSelector).dialog('open');
    });

    this.currentBalance = currentBalance;
    this.valuCardNumber = valuCardNumber;
  }


  function _initData(){
    return {
      _dialogSelector: _dialogSelector,
      currentBalance: "0.0",
      valuCardNumber: "0000",
    };
  }

  function _close() {
    $(_dialogSelector).dialog('close');
    $(document).keypress(posVC.onKeyPress);
  }


}}}
