module PositiveTS {
  export module Service {
    enum ROLES {
      slave,
      master
    }
    export class ZMasterSlave {
      
      public role: ROLES;

      constructor() {
        
        this.role = this.getRole();
      }
      
    
      get ROLES() {
        return ROLES;
      }
      
      get isZMasterSlave():boolean {
        return jsonConfig.getVal(jsonConfig.KEYS.is_z_master_slave) as boolean;
      }

    
      get zMasterSlavePrimaryPosDeviceId():string {
        return jsonConfig.getVal(jsonConfig.KEYS.z_master_slave_primary_pos_device_id);
      }
    
      getRole(){
        let _return: ROLES;
        if ( this.isZMasterSlave ){
          if (this.zMasterSlavePrimaryPosDeviceId == session.pos.deviceID) {
            _return = ROLES.master;
          } else {
            _return = ROLES.slave;
          }
        }
        return _return;
      };
    
    
    }
  }
}
declare var serviceZMasterSlave:PositiveTS.Service.ZMasterSlave;
