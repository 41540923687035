module PositiveTS {
export module Storage {
export module Entity {
export class ItemCategory extends WasmEntity {

	constructor() {
		let meta = {
			name: 'ItemCategory',
			fields: {
				typeIdentifier: "TEXT",
			  	categoryID: "TEXT",
			  	name: "TEXT"
			}
		}
		super(meta)
	}
}}}}
