module PositiveTS {
export module Service {
export module Check {
export module CheckHelper{

export interface CheckFieldsData 
{ 
    amount: number;
    bank: string;
    branch : string;
    account: string,
    number : string,
    date   : string,
    paymentCount:  string,
    manualApproval: string,
    customerId: string,
    guarantorId: string,
};

export function verifyAndPay(checkFieldsData:CheckFieldsData):Promise<boolean>{
    var errMsg = _isRowCanBeAdded(checkFieldsData)
    if (errMsg != null && errMsg != ''){
        
        return app.promiseShowAlert({
            header: i18next.t('checkPaymentMandatoryHeader'),
            content: errMsg,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
        })
        .then(()=>{
            return false;    
        });
    }


    var salePayment = getCheckPaymentObj();
    if (!salePayment){
        salePayment = new Storage.Entity.SalePayment();
        salePayment.saleID = posVC.sale.id;
        salePayment.method = Storage.Entity.SalePayment.METHOD_CHECK;		
    }
    
    var dataObj = [];
    if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1){
      dataObj = JSON.parse(salePayment.data);
    }

    return _checkPayment.doErnTransactionIfRequired(checkFieldsData)
    .catch(function (e) {
      return promiseCheckAlertDialog(e.message, 'הודעה מ-ERN');
    })
    .then(function(ernResponse){
      return _updateCheckPaymentWithErnResponseIfRequiredAndSave(ernResponse, dataObj, checkFieldsData, salePayment, checkFieldsData.amount);
    })
    .then(()=>{
      return true;    
    })
    .catch(function (e) {
        console.error(e.message);
        console.error(e.stack);
        //Show error
        return app.promiseShowAlert({
            header: i18next.t('checkPaymentMandatoryHeader'),
            content: e.message,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          })
          .then(()=>{
            return false;    
        });;
    });    


}


function _updateCheckPaymentWithErnResponseIfRequiredAndSave(ernResponse, dataObj, fieldsData, salePayment, amount) {    

    if (ernResponse) {
      fieldsData.ern = ernResponse;
    }

    _checkPayment.addPaymentToDataObject( dataObj, fieldsData);

    return  _persistAndRecalcTotal(salePayment, dataObj)
};

function _isRowCanBeAdded(checkFieldsData:CheckFieldsData){
    
    var VALID_BANK_CODE =
    ["1",
    "4",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "17",
    "18",
    "19",
    "20",
    "21",
    "24",
    "26",
    "28",
    "30",
    "31",
    "33",
    "34",
    "35",
    "40",
    "41",
    "42",
    "44",
    "46",
    "47",
    "48",
    "50",
    "52",
    "54",
    "58",
    "59",
    "64",
    "65",
    "68",
    "69",
    "75",
    "77",
    "80",
    "86",
    "90",
    "91",
    "92",
    "94",
    "99"];

    var amountVal = checkFieldsData.amount;
    if (!amountVal || amountVal == 0 || Number.isNaN(amountVal)) {
    return i18next.t("checkPaymentAmountMandatoryError");
    }

    if (posPaymentVC.leftAmount <  amountVal  ) {
    return i18next.t("generalPaymentLeftAmountMandatoryError");
    }

    let maxSaleAmount = Number(jsonConfig.getVal(jsonConfig.KEYS.maxSaleAmount));
    if(maxSaleAmount > 0 && amountVal > maxSaleAmount){
        return i18next.t('maxSaleAmountIs', {AMOUNT: String(maxSaleAmount)});
    }

    if (!posUtils.isBlank(checkFieldsData.bank) && checkFieldsData.bank.length > 3) {
      return i18next.t("checkPaymentBankLengthError");
    }

    if (!posUtils.isBlank(checkFieldsData.branch) && checkFieldsData.branch.length > 3) {
      return i18next.t("checkPaymentBrancLengthError");
    }

    // no more validation required
    if (session.pos.parameterNoCheckDetails){return '';}

    var bankVal = checkFieldsData.bank;
    if (!bankVal || bankVal == "0" || bankVal == ""){
    return i18next.t("checkPaymentBankMandatoryError");
    }


    var customerIdVal = checkFieldsData.customerId;
    var guarantorIdVal = checkFieldsData.guarantorId;

    if(jsonConfig.getVal(jsonConfig.KEYS.isErn) || !jsonConfig.getVal(jsonConfig.KEYS.hideTzOnChecks)) {
      if (!customerIdVal || customerIdVal == "0" || customerIdVal == ""){
        return i18next.t("checkPaymenIdMandatoryError");
      }
      if ( posUtils.isChetPei(customerIdVal) && ( !guarantorIdVal || guarantorIdVal == "0" || guarantorIdVal == "" || !posUtils.validateTz(guarantorIdVal) )) {
        return i18next.t("checkPaymenIdComercialChecNotValid");
      }
      if ( !posUtils.validateTz(customerIdVal)  ) {
        return i18next.t("checkPaymenIdMandatoryError");
      }
    }

    if ( VALID_BANK_CODE.indexOf(bankVal) === -1){
    return i18next.t("checkPaymentBankCodeMandatoryError");
    }


    var branchVal = checkFieldsData.branch;
    if (!branchVal || branchVal == "0" || branchVal == ""){
    return i18next.t("checkPaymentBranchMandatoryError");
    }


    var accountVal = checkFieldsData.account;
    if (!accountVal || accountVal == "0" || accountVal == ""){
    return i18next.t("checkPaymentAccountMandatoryError");
    }



    var checkNumVal = checkFieldsData.number;
    if (!checkNumVal || checkNumVal == "0" || checkNumVal == "" || checkNumVal.length > 8){
    return i18next.t("checkPaymentCheckNumMandatoryError");
    }


    var paymentCount = parseInt( checkFieldsData.paymentCount );
    var maxPaymentsAllowed = jsonConfig.getVal(jsonConfig.KEYS.checkPaymentsLimitMax);
    
    
    if (isNaN(paymentCount)) {
    return i18next.t("checkPaymentPaymentsCountMandatoryError");
    }
    if ( paymentCount > maxPaymentsAllowed || paymentCount < 1 ) {
        return i18next.t('checkPaymentPaymentsMaxForAmountError', {MIN_COUNT: 1,MAX_COUNT: maxPaymentsAllowed});
    }



    var checkDateVal = checkFieldsData.date;
    if (!checkDateVal || checkDateVal == "0" || checkDateVal == ""){
    return i18next.t("checkPaymentDateMandatoryError");
    } else {
    var validDate = session.isValidDate(checkDateVal, '/', 0, 1, 2, true);

    if (!validDate) {
        return i18next.t("checkPaymentDateNotValidMandatoryError");
    } else {
        var isDateValid = _checkPayment.isCheckDateValid( validDate, paymentCount );
        if (!isDateValid.isValid) {
        return isDateValid.msg;
        }
    }
    }

    var ernConfirmationNumber = checkFieldsData.manualApproval;
    if ( !_checkPayment.isCheckConfirmationNumberValid(ernConfirmationNumber)){
    return i18next.t("checkBadErnConfirmationNumber");
    }

    var fieldValidation = Service.CheckTypes.check([
        {type:'number', param: Number(amountVal), errorMsg: i18next.t('checkPaymentAmountMandatoryError')},
        {type:'int', param: Number(bankVal), errorMsg: i18next.t('checkPaymentBankMandatoryError'), maxLen: 2},
        {type:'int', param: Number(customerIdVal), errorMsg: i18next.t('checkPaymenIdMandatoryError'), maxLen: 9},
        {type:'int', param: Number(branchVal), errorMsg: i18next.t('checkPaymentBranchMandatoryError'), maxLen: 3},
        {type:'int', param: Number(accountVal), errorMsg: i18next.t('checkPaymentAccountMandatoryError'), maxLen: 10},
        {type:'int', param: Number(paymentCount), errorMsg: i18next.t('checkPaymentPaymentsCountMandatoryError'), maxLen: 2},
        ]);

    if (!fieldValidation.isValid) {
        return fieldValidation.message;
    }

    //Can add a row
    return '';
}

 export function getCheckPaymentObj(){
    return posVC.salePayments.filter(row => row.method == Storage.Entity.SalePayment.METHOD_CHECK)[0];
}

export function updateCheckNumber(positionInDataObj){
    var salePayment:any = getCheckPaymentObj();
    var dataObj = getListOfChecks();

    var currentRowObj = dataObj[positionInDataObj];
    var checkNumber = currentRowObj.number;

    var strDescription = "בנק:" +
                        currentRowObj.bank +
                        ", סניף:" +
                        currentRowObj.branch +
                        ", חשבון:" +
                        currentRowObj.account +
                        "<br/>" +
                        'אנא הזן מספר צק';


    return inputDg.open({
        header: 'מספר צק',
        description: strDescription,
        inputPlaceHolder: 'מספר צק',
        resultInDictFormat: true,
        showContinue2Button: true,
        textContinue2Button: "הדפס",
        showCancelButton: true,
        emptyErrorMessage: 'חובה להזין מספר צק',
        strDefault: checkNumber,
        inputValidator: function (value) {
        return session.isNumber(value);
        }
    }).then(function (dictCheckNumber) {
        var newCheckNumber = dictCheckNumber.val;

        dataObj[positionInDataObj].number = newCheckNumber;
        salePayment.data = JSON.stringify( dataObj );
        return posVC.persistSalePayment(salePayment)
        .then(function(){
        if (dictCheckNumber.key === "input-dialog-2ndContinue"){
            // Print dataObj[positionInDataObj]
            var objCheckRecord = new PositiveTS.Service.Check.CheckRecord( dataObj[positionInDataObj] );
            Printing.Invoice.printCheck( objCheckRecord );
        }
        });
    })
    
}

export async function spliceCheckPayment(index){
    let checkPayment = getCheckPaymentObj();
    let listOfChecks = getListOfChecks();
    listOfChecks.splice(index,1);
    if (listOfChecks.length === 0) {
        posVC.salePayments = posVC.salePayments.filter((row)=>{return row.id !== checkPayment.id})
	}
	else {
		checkPayment.data = JSON.stringify( listOfChecks );
		checkPayment.amount =  listOfChecks.reduce((a,b) => a+b.amount,0);
	}
	
	await Service.FullSale.persist(posVC.sale,posVC.saleItems,posVC.salePayments)
		
	posPaymentVC.updateAmountsIndicators();
	posVC.saleUpdated()
		
}

async function _persistAndRecalcTotal(checkPayment, listOfChecks) {
    checkPayment.data = JSON.stringify( listOfChecks );
    checkPayment.amount =  listOfChecks.reduce((a,b) => a+b.amount,0);

    // let inserted = false
    // for (let i=0; i < posVC.salePayments.length; i++) {
    //   if (posVC.salePayments[i].method == checkPayment.method) {
    //     posVC.salePayments[i] = checkPayment;
    //     inserted = true;
    //     break;
    //   }
    // }

    // if (!inserted) {
    //   posVC.salePayments.push(checkPayment)
	// }
	
	return posVC.persistSalePayment(checkPayment);

}

export function getListOfChecks():any[]{
    var paymentObj = getCheckPaymentObj();
    if (!paymentObj) {
		return [];
	}
    return JSON.parse(paymentObj.data)
}


 export function promiseCheckAlertDialog(msg, msgHeader:string = null){
    return new Promise(function(resolve, reject) {
      //
      if ( jsonConfig.getVal( jsonConfig.KEYS.isCanSuppressErnErrors ) ){
         let strErrorMsg = i18next.t("confirmErnErrorForceContinue", {ERROR: msg})
         app.showAlert({
           header: i18next.t('confirmErnErrorHeader'),
           content: strErrorMsg,
           continueButtonText: i18next.t('moveNext'),
           continueButtonAttribute: 'data-approve-button',
           cancelButtonText: i18next.t('cancel')
         }, function () {
           // Continue, ignore ern error
           resolve( serviceCheckErn.ERN_FORCED_CONTINUE );
         },
           function () {
             $( '#positive-alert' ).dialog('close');
             reject(Error(i18next.t("userCanceled")));
           }
         );
      } else {
         app.showAlert({
           header: msgHeader ? msgHeader : i18next.t('error'),
           content: msg,
           continueButtonText: i18next.t("ok"),
           hideCancelButton: true
         }, function () {
           // Go back
           $( '#positive-alert' ).dialog('close');
           reject(Error(i18next.t("userCanceled")));
         }, null);
      }
    });
 
 }

}}}}