 module PositiveTS {
 export module Service { 
 export class Beengo extends SmartVoucher {
    hasCVV = false;

    getBalance(cardNumber: string, cvv: string): Promise<GetBalanceResponse> {
      return;
    }

    pay(voucherData: any, amount: number, cvv?: string, companyId?: string): Promise<any> {
      return this._payRequestWithBeengo(amount)
          .then( (response) => {
            var jsonData = JSON.parse(posVC.sale.jsondata);
            //remove second decimal point from beengo discount...
            response.discountAmount = session.fixedFloat(String(response.discountAmount || "0"))
            jsonData.Beengo = response;
            posVC.sale.jsondata = JSON.stringify(jsonData);
            return this._createBeengoItemDiscountIfRequired(response.discountAmount)
          })
          .then ( ()=>{
            posVC.saleUpdated();
            return;
          })
          .catch( (e) =>{
            if (e != app.userCancelledGlobalMessage) {
              var jsonData = JSON.parse(posVC.sale.jsondata);
              if (jsonData.Beengo && jsonData.Beengo.posTransactionId && jsonData.Beengo.purchaseEventId) {
                  BeengoService._cancelRequestPurchaseEvent(jsonData.Beengo.posTransactionId, 
                    jsonData.Beengo.purchaseEventId)
                  .then( ()=>{
                    delete jsonData.Beengo;
                    posVC.sale.jsondata = JSON.stringify( jsonData );
                  })
              }
            }

            throw e;
          })
          .then((response) => {
            var jsonData = JSON.parse(posVC.sale.jsondata)
            voucherData.barCode = jsonData.Beengo.phoneNumber;
            voucherData.actionType = SmartVoucherActionTypes.WITHDRAW
            voucherData.allowPartialReturn = false
            voucherData.amount = (jsonData.Beengo.amount - jsonData.Beengo.discountAmount)
            voucherData.orderId = jsonData.Beengo.posTransactionId

            voucherData.posTransactionId = jsonData.Beengo.posTransactionId;
            voucherData.purchaseEventId = jsonData.Beengo.purchaseEventId;
            voucherData.discountAmount = jsonData.Beengo.discountAmount;
            voucherData.phoneNumber = jsonData.Beengo.phoneNumber;

            voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BEENGO
            return (voucherData)
          })



    }


    private _cleanBeengoDiscountItem(voucherToCancel):Promise<any> {
      return new Promise((resolve,reject) => {
        let beengoItemCode = jsonConfig.getVal(jsonConfig.KEYS.beengoDiscountSaleItemCode);
        for (let saleItem of posVC.saleItems) {
          if (saleItem.itemCode == beengoItemCode && -saleItem.unitPrice == voucherToCancel.discountAmount) {
            return posVC.deleteSaleItem(saleItem)
            .then( ()=>{
              resolve()
            })
          }
        }
        resolve()  
      })
      
    }


    cancelPayment(voucherToCancel: any): Promise<ActionResponse> {

      return this._cleanBeengoDiscountItem(voucherToCancel)
      .then( ()=>{
        return BeengoService._cancelRequestPurchaseEvent(voucherToCancel.posTransactionId, voucherToCancel.purchaseEventId);
      })
      .then((isSuccessfull)=>{
        if (isSuccessfull){
          return ({
            success: true,
            error: ""
          });
        } else {
          return ({
            success: false,
            error: "Unexpected Error: Beengo rejected cancel request"
          });
        }


      });

    }

    cancelLoad(paymentToCancel): Promise<any> {
      return Promise.reject("Not implemented");
    }

    getCardNumber(): Promise<any> {
      return Promise.reject("NOT IMPLEMENTED");
    }

    loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
      var _return:any = Promise.reject("Not implemented");
      return _return;
    }

    private _payRequestWithBeengo(amount:number):Promise<{posTransactionId: string, purchaseEventId:string, discountAmount: number,
     amount: number, phoneNumber:string}>{

     var isValidDict = BeengoService._preFlightCheck();
     if (!isValidDict.isOk) {
       let promiseErr:any = Promise.reject(new Error(isValidDict.err));
       return promiseErr;
     }

     var posTransactionId = new Date().getTime().toString();
     var purchaseEventId = undefined;

     app.hideLoadingMessage();
     return  BeengoService._phoneInputDialog()
     .then( (phoneNumber) =>{
       app.showLoadingMessage(i18next.t("beengo.waitingForUserApproval"))
       return BeengoService._createPurchaseEventWithReply( posTransactionId, phoneNumber, amount)
       .then(response =>{
         if (!response.purchaseEventId || !response.wasSent){throw new Error(response.message)}
         purchaseEventId = response.purchaseEventId;
         return response;
       })
     })
     .then( (reponse)=>{
       var rcr = new BeengoService._RepeatCheckReponse();
       return rcr.getPurchaseEventLoop(reponse.purchaseEventId, posTransactionId);
     })
     .then ( (response) =>{
       if (!response || response.status === 2) {
         throw new Error(i18next.t("beengo.transactionRejectedInApplication"));
       }

       if (!response || response.status !== 1) {
         throw new Error(i18next.t("beengo.beengoAuthorizationRejected"))
       }

       return {posTransactionId:posTransactionId, purchaseEventId:purchaseEventId,
         discountAmount: response.discountAmount, amount: response.amount, phoneNumber: response.phoneNumber };
     })

   }

    private _createBeengoItemDiscountIfRequired(discountAmount:number):Promise<any>{
      if (discountAmount === 0) {
        return Promise.resolve();
      }

      var beengoDiscountSaleItemCode = jsonConfig.getVal(jsonConfig.KEYS.beengoDiscountSaleItemCode);
      var unitPrice = discountAmount

      return Storage.Entity.Item.searchByCode(beengoDiscountSaleItemCode)
      .then( (items:any) =>{
        if (items.length !== 1) {throw new Error("Beengo saleItem.code not found");}


        var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(items[0].item, {size:'null',color:'null',barcode: items[0].item.code});
        saleItem.unitPrice = unitPrice * -1
        saleItem.saleID = posVC.sale.id;
        saleItem.rowNumber = posVC.getRowNumber();
        saleItem.quantity = 1
        posVC.saleItems.push( saleItem )
  
        return posVC.afterSaleItemPersisted(saleItem)
      })

    }

  }}
}