module PositiveTS {
  export module Components {
    export module FlightsOnlineLoginDialog {

      const _dialogSelector = "#positive-flights-online-login-dialog";
      const _vueComponentName = 'flights-online-login-dialog';
      const FLIGHT_STATUSES = {
        CREATED: 0,
        DISPATCHED: 1,
        IN_PROGRESS: 2,
        READY_FOR_CHECKING: 3,
        STOCK_CHECKED: 4,
        COMPLETED: 5,
        CANCALED: 6
    };

      export function create() {
        let flightOnlineloginDialog = {
          template: JST.flightsOnlineLoginDialog(),
          methods: {
            open() {
              this.$el.show();
              $(document).unbind('keypress',posVC.onKeyPress);

              if (!this.stateIsOnline) {
                this.showError(i18next.t('mustBeOnline'));
              }
            },
            close() {
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();
            },
            async login() {
              if (!this.stateIsOnline) {
                await this.showError(i18next.t('mustBeOnline'));
                return;
              }

              app.showLoadingMessage(i18next.t("loginDialog.loggingIn"));

              let request;

              try {
                request = await this.serverLogin();
              } catch (err) {
                app.hideLoadingMessage();
                await this.showError(i18next.t('generalError'));
                console.error(err);
                return;
              }

              app.hideLoadingMessage();

              if (!(request.response && request.response.ok && request.result)) {
                await this.showLoginError(request.result && request.result.error ? request.result.error : i18next.t('generalError'));
                return;
              }
              
              // this.sessionEmployeeId = employee.employeeID;
              // VueStore.dispatch('login', { changePass: employee.changePass || false, employeeID: employee.employeeID, employeeName: employee.name });

              this.numOfAttempts = 0;
              this.isLoggedIn = true;
            },
            serverLogin() {
              return PositiveTS.Service.FetchReq.jsonReq('/flights/login', 'post', { username: String(this.username), password: String(this.password)});
            },
            setFlight() {
              return PositiveTS.Service.FetchReq.jsonReq('/flights/attach_pos', 'post', { username: String(this.username), password: String(this.password), flight_id: this.flightId, pos_device_id: session.pos.deviceID});
            },
            getFlight() {
              return PositiveTS.Service.FetchReq.jsonReq(`/flights/${this.flightId}`, 'get');
            },
            async showLoginError(errorText) {
              this.numOfAttempts++;
              await this.showError(errorText)
              if (this.numOfAttempts > 4) {
                app.showLoadingMessage(i18next.t('loginDialog.blocked'));
                setTimeout(function () { app.hideLoadingMessage() }, 30000);
              }
            },
            showError(errorText) {
              return app.promiseShowAlert({
                header: i18next.t('error'),
                content: errorText,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            },
            async downloadFlight() {
              if (!this.flightId) {
                await this.showError(i18next.t('flightsOnlineLoginDialog.flightEmptyError'));
                this.$refs.flight.focus();
                return;
              }

              if (!this.stateIsOnline) {
                await this.showError(i18next.t('mustBeOnline'));
                return;
              }

              app.showLoadingMessage(i18next.t("flightsOnlineLoginDialog.searchingFlight"));

              let request;

              try {
                request = await this.getFlight();
              } catch (err) {
                app.hideLoadingMessage();
                await this.showLoginError(i18next.t('generalError'));
                console.error(err);
                return;
              }

              if (!(request.response && request.response.ok && request.result)) {
                app.hideLoadingMessage();
                await this.showError(request.result && request.result.error ? request.result.error : i18next.t('generalError'));
                return;
              }

              let flightData = request.result.flight;

              if (flightData.status == FLIGHT_STATUSES.DISPATCHED) {
                this.downloadAndSetFlight();
              } else {
                this.flightData = flightData;
                app.hideLoadingMessage();
              }
            },
            clearFlightData() {
              this.flightData = null;
              this.flightId = null;
            },
            async preFlightPinpadInit():Promise<boolean>{
              
              await Service.EMV.setAdapativeEMVPosNumberIfNeeded()

              let transmitAndDeleteResult = await Service.EMV.transmitAndDeleteTranIfNeeded()
              let transmitResult = transmitAndDeleteResult[0]

              if(transmitResult.ResultCode != 0 && transmitResult.ResultCode != 10021 && transmitResult.ResultCode != 10019){
                await this.showError(i18next.t("emv.ERROR_WHILE_DOING_TRANSMIT") + " " + transmitResult.ResultCode)
                return false;
              }

              let shvaBlackListUpdateResult = await Service.EMV.callShvaWithoutTransmittingTransactions();
              if(shvaBlackListUpdateResult.ResultCode != 0){
                let ans = await app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: i18next.t('flightsOnlineLoginDialog.errorUpdaingBlacklist',{code: shvaBlackListUpdateResult.ResultCode}),
                  continueButtonText: i18next.t("ok"),
                })

                if(ans == "continue"){
                  await Service.EMV.callShvaWithoutTransmittingTransactions();
                }
              }

              return true

            },
            async downloadAndSetFlight() {
              if (!this.flightId) {
                await this.showError(i18next.t('flightsOnlineLoginDialog.flightEmptyError'));
                this.$refs.flight.focus();
                return;
              }

              if (!this.stateIsOnline) {
                await this.showError(i18next.t('mustBeOnline'));
                return;
              }

              let preFlightPinpadInitRes = await this.preFlightPinpadInit()
              if(!preFlightPinpadInitRes){
                let ans = await app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: i18next.t(i18next.t("flightsOnlineLoginDialog.pinpadInitFailed")),
                  continueButtonText: i18next.t("ok"),
                })
                if(ans != "continue")
                {
                  return;
                }
              }

              app.showLoadingMessage(i18next.t("flightsOnlineLoginDialog.attachToFlight"));

              let request;

              try {
                request = await this.setFlight();
              } catch (err) {
                app.hideLoadingMessage();
                await this.showLoginError(i18next.t('generalError'));
                console.error(err);
                return;
              }

              if (!(request.response && request.response.ok && request.result)) {
                app.hideLoadingMessage();
                await this.showError(request.result && request.result.error ? request.result.error : i18next.t('generalError'));
                return;
              }

              let flightData = request.result;
              
              try {
                await this.setFlightsData(flightData);
              } catch (err) {
                app.hideLoadingMessage();
                await this.showLoginError(i18next.t('generalError'));
                console.error(err);
                return;
              }
              
              app.hideLoadingMessage();

              this.updateCatalog();
              this.reset();
              this.close();
            },
            selectFlightBack() {
              if (this.insertManualFlightId) {
                this.insertManualFlightId = false;
              } else {
                this.isLoggedIn = false;
              }
            },
            updateCatalog() {
              statsVC.reloadAllData();
            },
            reset() {
              this.numOfAttempts = 0;
              this.isLocked = false;
              this.username = null;
              this.password = null;
              this.isLoggedIn = false;
              this.flightId = null;
              this.insertManualFlightId = false;
              this.flightData = null;
            },
            showManualEnterFlightId() {
              this.insertManualFlightId = true;
              this.$nextTick(() => {
                this.$refs.flight.focus();
              });
            },
            getFlightDataLegs() {
              return _.sortBy(this.flightData.legs || [], 'leg_number');
            },
            canScanQrCode() {
              return session.isAndroid && typeof(Android) !== "undefined" && Android.scanQrCode
            },
            scanQrCode() {
              if (this.canScanQrCode()) {
                app.showLoadingMessage(i18next.t("flightsOnlineLoginDialog.scanningQr"));

                this.$nextTick(() => {
                  let flightId = Android.scanQrCode();

                  app.hideLoadingMessage();

                  if (flightId) {
                    this.flightId = flightId;
                    this.downloadFlight();
                  }
                });
              }
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {setFlightsData} = flightsStore
            return {setFlightsData}
          },
          data() {
            return {
              numOfAttempts: 0,
              isLocked: false,
              username: null,
              password: null,
              isLoggedIn: false,
              flightId: null,
              insertManualFlightId: false,
              flightData: null,
            };
          },
          computed: {
            stateIsOnline(){
              return Pinia.globalStore.isOnline
            },
          },
        }
        VueApp.component(_vueComponentName, flightOnlineloginDialog)
      }
    }
  }
}
