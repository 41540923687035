module PositiveTS {
export module Components {
    export module PositiveDeliveryShowOrder {
      const dialogSelector = "#positive-delivery-show-order"

      
      function _initjQueryDialogIfNeeded() {
        let aThis = this;
        if (!aThis.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(dialogSelector).dialog(aThis.dialogParams);

          aThis.initialized = true
        }


      }
      
      function initData() {
        return {
          initialized: false,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          fields: initFields(),
          isExternalOrder: false,
          sale: {},
          delivery: {deliveryAddress:{
            city:{

            },
            address:{
              
            }
          }},
          saleItems: [],
          remoteSale: {},
          payments: [],
          ordererCallerRemarks: "",
          ordererDeliveryRemarks: "",
          ordererPhone: "",
          ordererName: "",
          orderTime: "",
          tenbisAmount: 0,
          cibusAmount: 0,
          goodiAmount: 0,
          splitInvoice: 0,
          customerName: "",
          allowSelect: true,
          isCustomerClubMode: false,
        }
      }

      function initFields() {
        let fields = {
        }
        return fields;
      }

      function close() {
        $(dialogSelector).dialog('close');
        $(document).keypress(posVC.onKeyPress);
      }

      async function open(sale:any, saleItems, allowSelect?, remoteSale?, isCustomerClubMode = false) {

        if(sale.externalOrderId !== undefined){
          this.isExternalOrder = true
          let saleAndSequence = await Service.ECommerceAPIService.convertExternalOrderToSaleAndPayment(sale)
          sale = saleAndSequence[0]
          saleItems = sale.items.map(item => Storage.Entity.SaleItem.import(item));
          allowSelect = false
        }

        this.isCustomerClubMode = isCustomerClubMode
        this.sale = sale;
        this.saleItems = saleItems;
        this.remoteSale = remoteSale;
        this.payments = [["עודף",0],["אשראי",0],["מזומן",0],["שובר",0],["שובר זיכוי",0],["שיק",0],["שובר הנחה",0],["עודף שובר זיכוי",0]];
        this.tenbisAmount = 0;
        this.cibusAmount = 0;
        this.splitInvoice = 0;

        if(allowSelect != null){
          this.allowSelect = allowSelect;
        }

        if(jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet)){
          saleItems = saleItems.filter(item => item.barcode != jsonConfig.getVal(jsonConfig.KEYS.sittingItemCode))
        }

        PositiveTS.VueInstance.$refs.positiveDeliveryShowOrder.$refs.tableView.sale = sale
        PositiveTS.VueInstance.$refs.positiveDeliveryShowOrder.$refs.tableView.saleItems = saleItems

        this.ordererCallerRemarks = ""
        this.ordererDeliveryRemarks = ""
        this.ordererName = ""

        if(sale.isInHold){
          let saleTotals = Helper.SaleHelper.calcuateSaleTotals(sale,sale.items,sale.payments);
          this.sale.totalAmount = saleTotals.totalAmount;
          this.sale.totalQuantity = saleTotals.totalQuantity;
        }


        let jsondata = JSON.parse(sale.jsondata);
        if (jsondata.delivery){
          this.delivery = jsondata.delivery;
          this.ordererCallerRemarks = jsondata.delivery.ordererCallerRemarks
          this.ordererDeliveryRemarks = jsondata.delivery.ordererDeliveryRemarks
          this.ordererPhone = jsondata.delivery.ordererPhone
          this.ordererName = jsondata.delivery.ordererName
          this.orderTime = jsondata.delivery.orderTime
        } 

        for(let payment of sale.payments){
          this.payments[payment.method][1]+=payment.amount;
        }

        if(jsondata.splitSaleNumber){
          this.splitInvoice = jsondata.splitSaleNumber;
          let res = await appDB.sales.filter(sale => sale.invoiceSequence == jsondata.splitSaleNumber).toArray();
          let splittedSale = res[0];
          if(!splittedSale){
            app.showLoadingMessage("טוען הזמנה מהשרת");
            let saleFromServer = await Service.Sale.getSaleFromServer(sale.storeID,sale.posDeviceID,jsondata.splitSaleNumber,PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV);  
            splittedSale = Service.Sale.ToStorageTypeSale(saleFromServer);
            app.hideLoadingMessage();
          }
          let splitJD = JSON.parse(splittedSale.jsondata);
          if(splitJD.tenbisTotalAmount){
            this.tenbisAmount = splitJD.tenbisTotalAmount;
          }
          if(splitJD.cibusTotalAmount){
            this.cibusAmount = splitJD.cibusTotalAmount;
          }
          if(splitJD.goodiTotalAmount){
            this.goodiAmount = splitJD.goodiTotalAmount;
          }

          this.sale.totalAmount = this.sale.totalAmount + this.cibusAmount + this.tenbisAmount + this.goodiAmount;
        }

        let svcCustomer = new PositiveTS.Service.CustomerClub(sale, sale.payments, sale.items);
          if (svcCustomer.isCurrentCustomerExists() ) {
            this.customerName = `${svcCustomer.getCurrentSelectedCustomer(sale).s_first_name} ${svcCustomer.getCurrentSelectedCustomer(sale).s_last_name}`;
          }
          else if(sale.customerName){
            this.customerName = sale.customerName
          }

        let aThis = this;

        return new Promise((resolve,reject) => {
          _initjQueryDialogIfNeeded.call(this)

          $(document).unbind('keypress');
          $(dialogSelector).dialog('open');
          aThis.resolveFunc = resolve;
          aThis.rejectFunc = reject;
        })
      }

         function createdAtStr(createdAt) {
        if (!createdAt) { 
          return "";
        }

        let date = moment(createdAt)
        let format = "DD/MM HH:mm"
        if(jsonConfig.getVal(jsonConfig.KEYS.useAmericanTimeAndDateFormat)){
          format = "MM/DD hh:mm A"
        }
        return `${date.format(format)}`
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t("error"),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        let aThis = this;
        app.showAlert({
          header: i18next.t("error"),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.$refs.searchField.focus();
        },null)
      }


      async function addSaleItemsAndClose(){
        app.showLoadingMessage(i18next.t('delivery.loadingOrder'))
        await PositiveTS.Service.Delivery.addSaleItems(this.remoteSale);
        app.showAlert({
          header: i18next.t('delivery.title'),
          content: i18next.t('delivery.prevOrderChoosen'),
          hideCancelButton: true
        }); 
        app.hideLoadingMessage();
        this.confirmAndClose();
      }

      

      export function create() {

        let component = {
          template: JST.positiveDeliveryShowOrder(),
          mixins: [],
          methods: {
            cleanData(){
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            close: close,
            confirmAndClose() {
              this.resolveFunc(true);
              close();
            },
            showError: showError,
            showGenericError: showGenericError,  
            addSaleItemsAndClose: addSaleItemsAndClose,  
            createdAtStr:createdAtStr,
            isDeliveriesTablet: () => jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet),
            formatPrice(price) {
              return Service.MultiCurr.getInstance().addCurrencySignIfNeeded(session.fixedNumber(price));
            },
            isDalpakOpenSale(sale) {
              if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                return sale.invoiceSequence == -1 && posUtils.isDefined(this.getSaleDalpak(sale));
              }

              return false;
            },
            getSaleDalpak(sale) {
              return Service.Dalpak.getDalpakBySale(sale);
            }
          },
          data: initData,
          computed: {
            dialogParams() {
              let tabletLayout = jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet);  
              let mobileLayout = Pinia.globalStore.mobileLayout;  

              let dialogParams:any = {
                autoOpen: true,
                modal: true,
                dialogClass: 'positive-dialog',
                width: tabletLayout || mobileLayout ? '100vw' : "90%", 
                position: 'top',
                resizable: false,
                closeOnEscape: false,
                draggable: false,
              }
              if (mobileLayout) {
                dialogParams.height = window.outerHeight;
              }
              return dialogParams;
            },
            totalSaleVat() {
              return PositiveTS.Helper.SaleHelper.calculateVat(
                this.sale.totalVatableAmount != null ? this.sale.totalVatableAmount : this.sale.totalAmount, this.sale.vat);
            },
            getOrderTotalsWithKeys() {
              return {
                [i18next.t('delivery.qty')]: this.sale.totalQuantity,
                [i18next.t('delivery.amount')]: this.sale.totalAmount,
              }
            },
            paymentsWithoutZero () {
              return this.payments.filter(payment => payment[1] > 0);
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }
        }

        VueApp.component('positive-delivery-show-order',component)

      }
    }
  }
}
