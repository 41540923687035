module PositiveTS {
  export module Service {
    export module ValuecardPointsDialog {




      export function pointsAmountValidation(requestedAmount: number) {
        var saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
        if (isNaN(requestedAmount) || requestedAmount < 0) {
          return false;
        } else {
          return true;
        }
      }



      export async function openDialogIfRequired(): Promise<any> {
        let vcc = new PositiveTS.Service.ValueCardCustomerClubSelect(posVC.sale);
        if (!vcc.isCustomerExistsOnSale()) { return Promise.resolve(); }

        await PositiveTS.Service.ValueCardDiscountItemService.clearPreviousDiscountIfExists(posVC.saleItems)
        let customerInfo = vcc.getCustomerOnSale();
        // prepaidBalance
        let response: { approved: boolean, valuecardPoints: number } = await Pinia.componentsStore.openComponent( { componentName: "valuecardPointsDialog", args: [0, customerInfo] })
        if (!(response.approved)) { throw new Error("User canceled"); }

        let itemLines = posVC.saleItems;


        let saleTotals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);

        if (!session.pos.useNewPromotions) {
          itemLines = Storage.Entity.SaleItem.setPriceNetoAfterDiscountsForOldPromotionsItems(posVC.sale, itemLines)
        }

        return _getBenefits(customerInfo.cardNumber, saleTotals.totalAmount, response.valuecardPoints, itemLines)

      }


      async function _getBenefits(cardNumber: string, transactionSum: number, couponNum: number, itemLines: PositiveTS.Storage.Entity.SaleItem[]) {
        app.showLoadingMessage()

        let valueCardBenifits = await PositiveTS.Service.ValueCardService.getValuecardBenefits(cardNumber, transactionSum, couponNum, itemLines)
        let serverJson = PositiveTS.Service.ValueCardService.getValuecardBenefitsServerJson(cardNumber, transactionSum, couponNum, valueCardBenifits.transactionId, itemLines, posVC.sale)
        PositiveTS.Service.ValueCardService.setValueCardBenefitsTrxKey(posVC.sale, valueCardBenifits.discount, valueCardBenifits.transactionId, serverJson,
          valueCardBenifits.printMessage)

        app.hideLoadingMessage()


        let serverMessageAlert;

        if (!jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
          serverMessageAlert = await app.showAlertDialog({
            header: "הודעה משרת מועדון",
            content: valueCardBenifits.printMessage.replace("\n", ". "),
            hideCancelButton: true,
            noHandleEnterEscape: true,
          })
        } else {
          serverMessageAlert = 'continue'
        }


        if (serverMessageAlert === "continue") {
          await Service.FullSale.saveCurrentSale()
          await posVC.loadSale()
          await PositiveTS.Service.ValueCardDiscountItemService.addDiscount(valueCardBenifits.discount)
        }
      }
    }
  }
}