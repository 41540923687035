module PositiveTS {

  export module Components {
      export module AddOrEditPromotion {
        
        export const route = "/add-or-edit-promotion";

        type CompType = any;
        
        export function getComponent() {

          return {

            template: JST.AddOrEditPromotion(),
          
            beforeRouteLeave (to, from, next) {

              //need to destroy the promotion in state if not go to add-items-to-promotion
              if (to.path != '/add-items-to-promotion'){
                Pinia.promotionsManageStore.RESET_STATE()
              }

              next()

            },

            async beforeRouteEnter(to, from, next) {

              app.showLoadingMessage(i18next.t('loading'))

              const id = to.params.id

              next(async (vm) => {
                let self = <CompType>vm;

                if (!(Object.keys(Pinia.promotionsManageStore.promotion).length)){

                  if (id){
                    let promotion = await PositiveTS.Storage.Entity.Promotion.getById(id)

                    await Pinia.promotionsManageStore.setPromotion(promotion)
                    if (vm.hasErrorOnSavePromotion){
                      app.showErrorAlertDialog(i18next.t('promotionManage.errorOnLoad'))
                      await Pinia.promotionsManageStore.SET_PROMOTION({})
                    }
                  }else{                   
                    await Pinia.promotionsManageStore.setNewPromotion()
                    self.editMode = false   
                  }

                }

                self.selectedTemplate()

                app.hideLoadingMessage()  

              })
       
            },

            computed :{
           
              isKneKabel (){
                return this.template == 12
              },
              
              ...Shared.computed
              
            },

            methods: {
              ...Mixins.modalsMixin.methods,
              
              ...Shared.methods,

              selectedTemplate () {

                if ( this.template == 14){
                  this.$router.push({ path: route + '/' + KneBe.route})

                }

                if (this.template == 12){
                  this.$router.push({ path: route + '/' + KneKabel.route})
                }

                if (this.template == 15){
                  this.$router.push({ path: route + '/' + HaZolMe.route})
                }

              },

              async save () {

                let validate = this.validate()
                
                if (!validate.valid) {
                  app.hideLoadingMessage()
                  this.showAlertGeneric(validate.errors.join('<br>'))
                  return 
                }
                
                if (this.templateIsKneKabel()){

                  if ( this.buyOptionSelected == 'amount'){
                    Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'minimumBuyAmount',  value: this.valueBuyOptionSelected })
                    Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'minimumBuyQuantity',  value: 1})
                  }

                  if ( this.buyOptionSelected == 'quantity'){
                    Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'minimumBuyQuantity',  value: this.valueBuyOptionSelected })
                    Pinia.promotionsManageStore.SET_PROMOTION_PROPERTY({key: 'minimumBuyAmount',  value: 1})
                  }

                }

                //dispacth save promotion to db
                await Pinia.promotionsManageStore.saveToDB()

                if (this.hasErrorOnSavePromotion){
                  app.showErrorAlertDialog(i18next.t('promotionManage.errorOnSave'))
                }else{
                  VueServices.Router.goto(PromotionManage.route)
                }
              },

              cancel () {

                VueServices.Router.goto(PromotionManage.route)
               
              }

            },

            data() {
              let dataVal :{
                editMode: boolean,
              }
              = {
                editMode: true,
              }
              return dataVal;
            }
        
          }

        }
        
    }
  }
}
