module PositiveTS {
  export module QueueWorker {
    export class WorkerDB extends Dexie {

      sales: Dexie.Table<any,string>
      localItems: Dexie.Table<any,number>
      employees: Dexie.Table<any,string>
      employeeTimeTracks: Dexie.Table<any,string>
      departments: Dexie.Table<any,number>
      suspiciousActivityLog: Dexie.Table<any, number>
      promotions: Dexie.Table<any,string>
      externalOrders: Dexie.Table<any,string>
      elalDocNumbers: Dexie.Table<any,string>
      cashStatementLogs: Dexie.Table<any,string>
      poses: Dexie.Table<any,string>
      keyValueStore: Dexie.Table<any,string>
      // backupSales: Dexie.Table<Storage.Entity.Sale,string>
      // sequences: Dexie.Table<Storage.Entity.Sequence,string>
      // zReports: Dexie.Table<Storage.Entity.ZReport,string>
      // svaTransmitLogs: Dexie.Table<Storage.Entity.SvaTransmitLog,string>
      // emvTransmitLogs: Dexie.Table<Storage.Entity.EmvTransmitLog,string>
      // convertedTables: Dexie.Table<Storage.Entity.ConvertedTable,number>

      constructor(dbVersion) {
        super("positive")
        Shared.DB.positiveDBVersions(this);
        if (dbVersion > this.verno) {
          this.version(dbVersion).stores({});
        }
      }

    }

    Dexie.addons.push(Shared.DB.putAddon);

  }
}

declare var workerDB:PositiveTS.QueueWorker.WorkerDB;