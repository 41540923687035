import Pinpad from './Pinpad'
import Emv from './Emv'
import RequestType from '../enums/EmvRequestType';
import { isNullOrUndefinedOrEmptyString } from "../../Utils";
import ILogger from '../../ILogger';

export default class KeepAlive {
    constructor(protected pinpad: Pinpad, protected emv:Emv, protected logger: ILogger, protected keepAliveEmvMinutesInterval: number) {}
    
    async startKeepAlive(consecutiveTimesFailed = 0) {
        
        if(!this.pinpad.getPinpadTransactionRunning()) {
            let request = this.emv.createCheckConnectionRequestObj();
            let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV);
            let result = this.pinpad.parseResult(addonResponse);

            if(!isNullOrUndefinedOrEmptyString(result.ResultCode) && result.ResultCode == "0") {
                consecutiveTimesFailed = 0;
            } else {
                consecutiveTimesFailed++;
            }
        }

        if(consecutiveTimesFailed > 3) {
            this.logger.error("keep alive failed 4 times")
            return;
        }

        setTimeout(() => { this.startKeepAlive(consecutiveTimesFailed) }, this.keepAliveEmvMinutesInterval * 60000);

    }
}