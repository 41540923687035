module PositiveTS {
export module Service {
  export class PaymentLimit {
    private NO_LIMIT = 9999
    public ALLOWED_PAYMENT_METHODS = {
      METHOD_CREDIT: 1,
      METHOD_CHECK: 5
    }
    public lastQueryOfCreditCardNumberOfPaymentsAuthorizedForAmount = this.NO_LIMIT;

    public creditCardNumberOfPaymentsAuthorizedForAmount = function(amount, isPaymentTypeCredit){
      amount = parseInt(amount);
    	var numberOfPaymentsAuthorized = this.NO_LIMIT;
    	this.lastQueryOfCreditCardNumberOfPaymentsAuthorizedForAmount = numberOfPaymentsAuthorized;

    	if (isPaymentTypeCredit) {
  			numberOfPaymentsAuthorized =  this.getAllowedPayments( parseInt( amount ) ) ;
  			this.lastQueryOfCreditCardNumberOfPaymentsAuthorizedForAmount = numberOfPaymentsAuthorized;
  		}

  	 return numberOfPaymentsAuthorized;
    }

    public getAllowedPayments = function(amount:number, paymentType?:any) {
      let numberOfPaymentsAuthorized = this.NO_LIMIT;

      for (let k in posVC.Paymentlimits) {
        var _row = posVC.Paymentlimits[k];
        if (_row.low_bound <= amount && _row.high_bound >= amount ) {
          if (paymentType && paymentType.toString() !== _row.payment_type.toString()){continue;}
          numberOfPaymentsAuthorized = parseInt( _row.paymentcount );
        }
      }
      return numberOfPaymentsAuthorized;
    }

    public promiseCashTable = function(){
  	   return this._promiseCashTable()
      .catch(function onError() {
      });
    }

    private _promiseCashTable = function() {

      var _sql = "select * from Paymentlimit"
      return PositiveTS.Service.WasmDB.promiseSql( _sql )
        .then(function(result){
          if (result.length === 0){
            return Promise.reject( {status:0, description: "no data"});
          } else {
            posVC["Paymentlimits"] = {};

            for (var i = 0 ; i<result.length;i++){
              posVC["Paymentlimits"][result[i]["settingsID"]] = result[i];
            }
            return;
          }
        });
      };
  }
}}

declare var paymentLimit:PositiveTS.Service.PaymentLimit;
paymentLimit = new PositiveTS.Service.PaymentLimit();
