module PositiveTS {
export module Promotions {
export module Templates {
export class BuyAboveXGetSaleDiscount extends TemplateAbstract {
  constructor(initParameters) {
    super(initParameters)
  }

  calculatePromotions() {
    for (var promotionCode in this.parameters.promotionsByCode) {

        var promotion = this.parameters.promotionsByCode[promotionCode];

        // Check that this promotion is from template 1
        if (promotion.template !== '4') {
            continue;
        }

        this.applyPromotion(promotion);
    }
  }

  applyPromotion(promotion) {
      var parameters = this.parameters;

      var totalAmount = saleItemHelper.getSaleItemsTotalAmount(parameters.saleItems);
      var totalQuantity = saleItemHelper.getSaleItemsTotalQuantity(parameters.saleItems);

      // var total = this.calcuateSaleTotalAmount(promotion.allowWithOtherPromotions == 1);

      if (Number(promotion.minimumBuyAmount) > totalAmount) {
          return;
      }

      if (Number(promotion.minimumBuyQuantity) > totalQuantity) {
          return;
      }

      if (promotion.discountType != PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT &&
        promotion.discountType != PositiveTS.Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT) {
          return;
      }

      this.parameters.salePromotion = promotion; //just mark the sale promotion to be applied
  }

}
}}}
