module PositiveTS {
	export module Service {
		export module NotificationsAudio {
      export async function playSound(notificationName: string) {
        const audio = window.notificationsAudio[notificationName];
        if (audio instanceof Audio){
          if(!audio.paused){
            audio.pause();
            audio.currentTime = 0;
          }
          await audio.play();
        }else{
          console.error(`Notification name: ${notificationName} is not found.`);
        }    
      }
    }
  }
}
