module PositiveTS {
  export module Components {
    export module MultiCurr {
      export module ConfirmCashCreditPaymentDialog {
        const dialogSelector = 'confirm-cash-credit-payment-dialog';
        export function create() {
          let dialogComp = {
            template: JST.ConfirmCashCreditPaymentDialog(),
            components: {
              positTable: PositiveTS.Components.PositTable.getComponent(),
            },
            data() {
              let multiCurr = PositiveTS.Service.MultiCurr.getInstance()
              return {
                resolveFunc: null,
                rejectFunc: null,
                paymentData: [],
                colDefs: [ // Types.PositTableHeader
                  { title: i18next.t('creditInOGCashCurrency.cols.currency'), field: 'lead_currency_symble', class: 'text-center',
                    computedField: row => multiCurr.getCurrencySign(row.paid_currency_symble) + ' ' + i18next.t(`currencies.${row.paid_currency_symble}`)
                  },
                  { title: i18next.t('creditInOGCashCurrency.cols.howMuchToRefund'), field: 'paid_currency_amount', type: 'number', class: 'text-center' },
                ],                
              };
            },
            computed: {
              title() {
                return i18next.t('creditInOGCashCurrency.title');
              },
            },
            methods: {
              open(args: Types.ConfirmCashCreditPaymentDialogArgs) {
                app.showLoadingMessage();
                this.$el.showModal();
                $(document).unbind('keypress');
                this.paymentData = args.paymentData;
                return new Promise((res, rej) => {
                  app.hideLoadingMessage();
                  this.resolveFunc = res;
                  this.rejectFunc = rej;
                });
              },
              close(confirm: boolean) {
                $(document).keypress(posVC.onKeyPress);
                this.$el.close();
                confirm ? this.resolveFunc() : this.rejectFunc(i18next.t('cancelled'));
              },
            },
          }
          VueApp.component(dialogSelector, dialogComp)
        }
      }
    }
  }
}
