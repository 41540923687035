module PositiveTS {
export module Components {
    export module PositiveCustomerClub {
      enum ViewState {
        SEARCH = 0,
        NEW = 1,
        EDIT = 2,
      }
      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }
      
      function convertFromPosStructure(uglyCustObj) {
        this.customer = {}
        this.customer.customer_tz = uglyCustObj["s_id_number"]
        this.customer.customer_number = uglyCustObj["s_member_no"]
        this.customer.customer_group_id = uglyCustObj["customer_group_id"]
        this.customer.customer_group_name = uglyCustObj["customer_group_name"]
        this.customer.discount_percent = uglyCustObj["discount_percent"]
        this.customer.street_address = uglyCustObj["address"]
        this.customer.first_name = uglyCustObj["s_first_name"]
        this.customer.last_name = uglyCustObj["s_last_name"]
        this.customer.id = uglyCustObj["db_id"]
        this.customer.email = uglyCustObj["email"]
        // uglyCustObj["msg_for_screen"] = "" //TODO
        // uglyCustObj["msg_for_slip"] = "" //TODO
        this.customer.current_points = uglyCustObj["cust_points"]

        this.customer.customer_club_id = uglyCustObj["i_club_id"]
        this.customer.date_of_birth = moment(uglyCustObj["dt_birth_date"]).toDate()
        this.customer.phone_number = uglyCustObj["s_phone_number_1"]
        this.customer.zip_code = uglyCustObj["zip_code"]
        this.customer.block_from_mailing_list = uglyCustObj["block_from_mailing_list"]
        this.customer.comments = uglyCustObj["comments"]

      }
      function _initjQueryDialogIfNeeded() {
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
          
            this.theDialog = $("#positive-customer-club-dialog").dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: Pinia.globalStore.mobileLayout ? '100vw' : '90vw',
              height: Pinia.globalStore.mobileLayout ? window.outerHeight : 670,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          // });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          customerSelected: false,
          showSearchResults: false,
          mode: ViewState.SEARCH,
          noCustomersFound: false,
          theDialog:null,
          showPositiveAddCustomer: !jsonConfig.getVal(jsonConfig.KEYS.hidePositiveAddCustomer),
          customer: {},
          customerToEdit: {},
          customers: [],
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          onSelectCustomerOnlyMode: false, 
          selectedCustomerResolve: null,
          showGoToCustomerCommentsButton: jsonConfig.getVal(jsonConfig.KEYS.showGoToCustomerCommentsButton)
        }
      }

      function close() {
        this.setMode("search");
        $("#positive-customer-club-dialog").dialog('close');
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
        if (this.onSelectCustomerOnlyMode && this.selectedCustomerResolve){
          this.selectedCustomerResolve()
          return
        }
      }

      function open() {
        this.onSelectCustomerOnlyMode = false
        let customerClubService = new PositiveTS.Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
        let customer = customerClubService.getCurrentSelectedCustomer()
        if (customer) {
          convertFromPosStructure.call(this,customer)
          this.customerSelected = true
        }
        else {
          this.customerSelected = false;
          this.customer = {block_from_mailing_list: false}
        }

        this._initjQueryDialogIfNeeded()

        $(document).unbind('keypress');

        this.theDialog.dialog('option', {
          modal: true,
          dialogClass: 'positive-dialog',
          resizable: false,
          closeOnEscape: false,
          draggable: false,
          width: Pinia.globalStore.mobileLayout ? '100vw' : '90vw',
          height: Pinia.globalStore.mobileLayout ? window.outerHeight : 670,
        })
        .dialog('open');
        if (this.$refs.searchInputText) {
          this.$refs.searchInputText.focus();
        }
        else if (this.$refs.customerNumber) {
          this.$refs.customerNumber.focus();
        }

      }
      async function swipeCard(): Promise<void> {
        app.showLoadingMessageDialog(i18next.t("mizdamenOrHakafaDialog.swipeCustomerCard"));
        const swipeCardInput = await Service.EMV.swipe();
        if (this.$refs.searchInputText) {
          this.searchInput = swipeCardInput || "";
          if (this.searchInput) {
            await this.searchCustomer()
          }
        }
        else if (this.$refs.customerNumber) {
          this.customer.customer_number = swipeCardInput || "";
        }
        
        app.hideLoadingMessageDialog();
      }

      async function searchCustomer() {
        if (this.searchInput.trim() == "") {
          return;
        }
        this.loading=true;
        app.showLoadingMessage(i18next.t("positiveCustClub.findingCustomer"));
        try {
            let ret = await PositiveTS.Storage.Entity.PositiveCustomer.search(this.searchInput);
          switch (ret.result.i_return_code) {
            case "1":
            this.noCustomersFound = false
              this.showCustomer(ret.result);
              break;
            case "4":
              this.noCustomersFound = true
              break;
            case "2":
              this.showError(ret.result.s_return_msg);
              break;
            default:
              this.showGenericError()
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.loading=false
          app.hideLoadingMessage()
        }
        console.debug(this.searchInput);
      }

      function showCustomer(result:any) {

        this.customers = []
        this.showSearchResults = true;
        this.customers = result.customers;
        this.customers.forEach(cust => {
          cust.expired = cust.valid_until ? (moment(cust.valid_until).toDate() < new Date()) : false
        })
      }

      function checkIfCustomerExists() {
        return new Promise((resolve,reject) => {
          app.showLoadingMessage(i18next.t("postiveCustomerClub.checkingIfCustExists"));
          PositiveTS.Service.FetchReq.jsonReq(`/positive_customer_club?club_name=positive&search=${this.customer.customer_number}`,"get")
          .then(ret => {
            switch (ret.result.i_return_code) {
              case "1":
                ret.result.customers.forEach((cust) => {
                  if (String(cust.customer_number) === this.customer.customer_number.trim()) {
                    resolve(true)
                  }
                })
                resolve(false)
                break;
              case "4":
                resolve(false)
                break;
              case "2":
                this.showError(ret.result.s_return_msg);
                break;
              default:
                this.showGenericError()
            }
            this.loading=false
            app.hideLoadingMessage()
          })
        })
      }

      function validate():Promise<Array<any>> {
        return new Promise((resolve,reject) => {
          let errors = []
          let hasPhoneNumber = true

          if(session.pos.customerClubNumberRequired) {
            if (!this.customer.customer_number || this.customer.customer_number.trim() === "") {
              errors.push({field:"customerNumber", error:i18next.t("positiveCustClub.mustEnterCardNumber")})
            }
          }

          if(this.customer.customer_number) {
            if (this.customer.customer_number.length > PositiveTS.Service.CustomerClub.CUSTOMER_TEXT_INPUT_LEGTH) {
              errors.push({field:"customerNumber", error:i18next.t("positiveCustClub.toLongTextInput")})
            }
          }

          if (!jsonConfig.getVal(jsonConfig.KEYS.allowCreateCustomerWithoutName)) {
            if (!this.customer.first_name || this.customer.first_name.trim() === "") {
              errors.push({field:"firstName", error:i18next.t("positiveCustClub.mustEnterFirstName")})
            }
            if (!this.customer.last_name || this.customer.last_name.trim() === "") {
              errors.push({field:"lastName", error:i18next.t("positiveCustClub.mustEnterLastName")})
            }
          }

          if (!this.customer.phone_number || String(this.customer.phone_number || '') === "") {
            hasPhoneNumber = false
            errors.push({field:"phoneNumber", error:i18next.t("positiveCustClub.mustEnterPhone")})
          }
          if(this.customer.discount_percent && isNaN(this.customer.discount_percent)) {
            errors.push({field:"discountPercent", error:i18next.t("positiveCustClub.discountPercentMustBeNumericOrEmpty")})
          }
  
          if (jsonConfig.getVal(jsonConfig.KEYS.validateEmailOnAddingCustomer) && !posUtils.isBlank(this.customer.email)){
            let email = this.customer.email.trim()
            if (!posUtils.isValidEmail(email)){
              errors.push({field:"email", error:i18next.t("positiveCustClub.emailInvalid")})
            }
          }

          if (jsonConfig.getVal(jsonConfig.KEYS.validatePhoneOnAddingCustomer) && hasPhoneNumber){
            let phone = String(this.customer.phone_number || '')
            if (!posUtils.isValidIsraelMobilePhone(phone)){
              errors.push({field:"phoneNumber", error:i18next.t("positiveCustClub.phoneInvalid")})
            }
          }

          // if (errors.length === 0) {
          //   checkIfCustomerExists.call(this)
          //   .then(custExists => {
          //     if (custExists) {
          //       errors.push({field: "customerNumber", error: "קיים כבר לקוח עם אותו מספר לקוח"})
          //     }
          //     resolve(errors)
          //   })
          // }
          // else {
            resolve(errors);
          // }
        })
      }

      async function addNewCustomer() {
        try {
          let errors = await validate.call(this)
          if (errors.length > 0) {
            let errStr = ''
            errors.forEach((el) => {errStr += `${el.error}<br>`})
            app.showAlert({
              header: i18next.t('error'),
              content: errStr,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
            },
            () => {
              this.$refs[errors[0].field].focus();              
            },null)
          }
          else {
            if (this.isRoshemet){
              if (!Pinia.globalStore.isOnline){
                this.showError(i18next.t("positiveCustClub.errorCannotCreateCustomerOnOffline"))
                return
              }
              app.showLoadingMessage(i18next.t("positiveCustClub.createCustomer"))
              let res = await PositiveTS.Service.FetchReq.jsonReq('/positive_customer_club?club_name=positive&create_from_pos=true', 'post', {customer: this.customer});
              if (res.result && Array.isArray(res.result.errors)){
                this.showError(res.result.errors.join(`<br>`))
                return
              }
              this.customer.id = res.result.customer.id
            } else {
              if (Pinia.globalStore.isOnline){
                try {
                  app.showLoadingMessage(i18next.t('positiveCustClub.findingCustomer'));
                  let res = await PositiveTS.Service.FetchReq.jsonReq(`/positive_customer_club/${this.customer.customer_number}?club_name=positive&by_customer_number=true`, "get")

                  app.hideLoadingMessage();

                  if (res.result && res.result.status == 1) {
                    this.showError(i18next.t("positiveCustClub.customerExists", res.result.customer))
                    return
                  }
                } catch (err) {
                  app.hideLoadingMessage();
                  Service.Logger.error("Error trying check if customer exists");
                  Service.Logger.error(err);
                }
              }

            }

            this.customer.valid_until = moment().add(1, 'year').toString()

            if (this.onSelectCustomerOnlyMode && this.selectedCustomerResolve){
              this.selectedCustomerResolve(this.customer)
              this.close()
              return
            }
            await Service.CustomerClubSpecialItem.addSpecialItemIfThereIs();
            let customer = await Service.CustomerClub.putCustomerInSale(this.customer)
            this.customer = customer;
            this.customerSelected = true;
            this.close(); 
          }
        }catch (e){
          console.error(e)
          this.showError(i18next.t("positiveCustClub.errorOnAddCustomer"))
        }finally{
          app.hideLoadingMessage()
        }
      }

      function customerHistory(customer) {
        if (!customer || !customer.id) {
          return;
        }

        posSearchInvoicesVC.searchObject = {customer_id: customer.id};
        posSearchInvoicesVC.showingResults = true;
        posSearchInvoicesVC.formRendered = true;
        this.close();

        pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content:  i18next.t('error'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function setMode(mode) {
        mode = mode ? mode.toLowerCase() : '';
        switch (mode) {
          case "search":
            this.revertingEditingChanges();
            this.mode = ViewState.SEARCH;
            break;
          case "new":
          {
            this.revertingEditingChanges();
            if (posVC?.sale?.parentSaleInvoiceSequence){
              app.showAlert({
                header: i18next.t('error'),
                content: i18next.t("positiveCustClub.cannotAddNewCustomerOnCreditSale"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              })
              break
            }
            this.mode = ViewState.NEW;
            this.customer = {};
            break;
          }
          case "edit":{
            this.mode = ViewState.EDIT;
            break;
            }
          default:
            //do noting
            break;
        }
      }

      function selectInput(field) {
        this.$refs[field].focus();
      }

      function customerGroupNameFocus() {
        return PositiveTS.Dialogs.CustomerGroupSelect.selectCustomerGroup().
        then( selected =>{
          this.$refs["customerGroupName"].value = selected.name;
          this.customer.customer_group_name = selected.name;
          this.customer.customer_group_id = selected.code;
          if (this.allowSetNewCustomerDiscountPercent) {
            this.$refs["discountPercent"].focus();
          } else {
            this.$refs["phoneNumber"].focus();
          }

        })
      }

      function getSearchPlaceholder(){
        if( jsonConfig.getVal(jsonConfig.KEYS.doNotSeachInternalCustomerClubByName) ){
          return i18next.t("positiveCustClub.searchPlaceholderWithNoName")
        } else {
          return i18next.t("positiveCustClub.searchPlaceholder")
        }
      }

      function searchDBHook(){
        appDB.positiveCustomer.hook("creating", function (primKey, obj, trans) {
          let first_name = obj.first_name || ""
          let last_name = obj.last_name || ""
          obj.search = [obj.phone_number || "",first_name,last_name,String(obj.customer_tz) || "",obj.customer_number || "", first_name + " " + last_name];

          if(!posUtils.isNullOrUndefinedOrEmptyString(obj.phone_number)){
            obj.search.push(String(obj.phone_number).replace("-",""))
          }
        });
        
        appDB.positiveCustomer.hook("updating", function (mods,primKey, obj, trans) {
          let first_name = obj.first_name || ""
          let last_name = obj.last_name || ""
          obj.search = [obj.phone_number || "",first_name,last_name,String(obj.customer_tz) || "",obj.customer_number || "", first_name + " " + last_name];

          if(!posUtils.isNullOrUndefinedOrEmptyString(obj.phone_number)){
            obj.search.push(String(obj.phone_number).replace("-",""))
          }
          return obj;
      	});
      }
      export function create() {

        let positiveCustomerClub = {
            template: JST.positiveCustomerClub(),
            methods: {
              cleanData: cleanData,
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              close: close,
              searchCustomer: searchCustomer,
              swipeCard: swipeCard,
              showCustomer: showCustomer,
              showError: showError,
              showGenericError: showGenericError,
              addNewCustomer: addNewCustomer,
              async updateExistingCustomer() {
                try {
                  let errors = await validate.call(this)
                  if (errors.length > 0) {
                    let errStr = ''
                    errors.forEach((el) => {errStr += `${el.error}<br>`});
                    let txtRes: string = await app.promiseShowAlert({ header: i18next.t('error'), content: errStr, continueButtonText: i18next.t("ok"),hideCancelButton: true });
                    if(txtRes === "continue") {
                      this.$refs[errors[0].field].focus();
                    }
                    return;
                  }

                  if (!Pinia.globalStore.isOnline) {
                    this.showError(i18next.t("positiveCustClub.errorCannotUpdateCustomerOnOffline"));
                    return;
                  }

                  app.showLoadingMessage(i18next.t("positiveCustClub.updateCustomer"));
                  let res = await PositiveTS.Service.FetchReq.jsonReq(`/positive_customer_club/${this.customer.id}`,'put',{ customer : this.customer });
                  if (res.response.ok) {
                    app.hideLoadingMessage();
                    await app.promiseShowAlert({ header: i18next.t('message'), hideCancelButton: true, content: i18next.t('positiveCustClub.updatedCustomerSuccessfully')});
                    this.customer = {};
                    this.setMode("search");
                  } else {
                    console.error(res);
                    if (res.response.status === 404) this.showError(i18next.t("positiveCustClub.customerNotFound"));
                    else this.showError(i18next.t("positiveCustClub.errorOnEditCustomer"));
                  }
                } catch (e) {
                  console.error(e);
                  this.showError(i18next.t("positiveCustClub.errorOnEditCustomer"));
                } finally {
                  app.hideLoadingMessage();
                }
              },
              async updateCustomerComments() {
                try {
                  let errors = await validate.call(this)
                  if (errors.length > 0) {
                    let errStr = ''
                    errors.forEach((el) => {errStr += `${el.error}<br>`});
                    let txtRes: string = await app.promiseShowAlert({ header: i18next.t('error'), content: errStr, continueButtonText: i18next.t("ok"),hideCancelButton: true });
                    if(txtRes === "continue") {
                      this.$refs[errors[0].field].focus();
                    }
                    return;
                  }

                  if (!Pinia.globalStore.isOnline) {
                    this.showError(i18next.t("positiveCustClub.errorCannotUpdateCustomerOnOffline"));
                    return;
                  }

                  app.showLoadingMessage(i18next.t("positiveCustClub.updateCustomer"));
                  let res = await PositiveTS.Service.FetchReq.jsonReq(`/positive_customer_club/${this.customer.id}`,'put',{ customer : {comments: this.customer.comments}});
                  if (res.response.ok) {
                    app.hideLoadingMessage();
                    await app.promiseShowAlert({ header: i18next.t('message'), hideCancelButton: true, content: i18next.t('positiveCustClub.updatedCustomerSuccessfully')});
                    let customer = await Service.CustomerClub.putCustomerInSale(this.customer)
                    this.customer = customer;
                    this.customerSelected = true;
                    this.close();
                  } else {
                    console.error(res);
                    if (res.response.status === 404) this.showError(i18next.t("positiveCustClub.customerNotFound"));
                    else this.showError(i18next.t("positiveCustClub.errorOnEditCustomer"));
                  }
                } catch (e) {
                  console.error(e);
                  this.showError(i18next.t("positiveCustClub.errorOnEditCustomer"));
                } finally {
                  app.hideLoadingMessage();
                }
              },
              async editCustomer(customer) {
                this.customer = customer;
                if (customer.customer_group_id > 0 && customer.customer_group_name  == null){
                  let customerGroup = await new PositiveTS.Storage.Entity.CustomerGroup().findByKeyValue('code', customer.customer_group_id)
                  if (customerGroup){
                    customer.customer_group_name = customerGroup.name
                  }
                }
                this.customerToEdit = _.clone(customer);
                this.setMode('edit');
              },
              revertingEditingChanges() {
                if (this.customerToEdit) {
                  Object.keys(this.customerToEdit).forEach(prop => this.customer[prop] = this.customerToEdit[prop])
                  this.customerToEdit = null;
                }
              },
              setMode: setMode,
              selectInput: selectInput,
              customerHistory: customerHistory,
              customerGroupNameFocus: customerGroupNameFocus,
              getSearchPlaceholder: getSearchPlaceholder,
              async putCustomerInSale(customer) {
                await Service.CustomerClub.renewMemberClub(customer)
                if (this.onSelectCustomerOnlyMode && this.selectedCustomerResolve){
                  this.selectedCustomerResolve(customer)
                  this.close()
                  return
                }
                let cust = await Service.CustomerClub.putCustomerInSale(customer)
                this.customer = cust;
                this.customerSelected = true;
                this.close();
              },
              openSelectedCustomerOnly () {
                this._initjQueryDialogIfNeeded()
        
                $(document).unbind('keypress');
        
                this.theDialog.dialog('option', {
                  width: Pinia.globalStore.mobileLayout ? '100vw' : '90vw',
                  height: Pinia.globalStore.mobileLayout ? window.outerHeight : 670,
                })
                .dialog('open');
                if (this.$refs.searchInputText) {
                  this.$refs.searchInputText.focus();
                }
                else if (this.$refs.customerNumber) {
                  this.$refs.customerNumber.focus();
                }
                
                this.onSelectCustomerOnlyMode = true
                return new Promise ((resolve) => {
                  this.selectedCustomerResolve = resolve
                })
              },

              async extensionValidUntil (customer) {
                try{
                  let validUntilDate = moment(customer.valid_until)
                  let now = moment()

                  if (validUntilDate.diff(now) > 0){
                    this.showError(i18next.t('positiveCustClub.cannotExtensionValidUntil'))
                    return
                  }

                  let message = i18next.t('positiveCustClub.extensionValidUntilModalMessage', {date: now.clone().add(1, 'year').format("DD/MM/YY")}) 
                  
                  let resAlert = await app.promiseShowAlert({
                    header: i18next.t('positiveCustClub.extensionValidUntilModalTitle'),
                    content: message,
                    continueButtonText: i18next.t('ok'),
                    cancelButtonText: i18next.t('back'),
                    hideCancelButton: false,
                    noHandleEnterEscape: true,
                  })

                  if (resAlert == "continue"){
                    app.showLoadingMessage()
                    let res = await PositiveTS.Service.FetchReq.jsonReq(`/positive_customer_club/${customer.id}/extension_valid_until`, 'post', {extension_valid_until_date: now.clone().add(1, 'year').format("YYYY-MM-DD")})
                    
                    if (res?.result?.customer?.valid_until){
                      customer.valid_until = res.result.customer.valid_until
                      customer.expired = false
                    }

                    app.hideLoadingMessage()
                  }
                } catch (error){
                  console.error(error)
                  this.showError(i18next.t('positiveCustClub.errorExtensionValidUntil'))
                  app.hideLoadingMessage()
                }
              },
              scrollToCustomerComments() {
                let elementId = 'positive-customer-comments-input'
                if(this.customerSelected) {
                  elementId = 'selected-positive-customer-comments-input'
                }
                let commentsEl = document.getElementById(elementId)
                if(commentsEl) {
                  commentsEl.focus()
                  commentsEl.scrollIntoView();
                } else {
                  console.error(`${elementId}, element was not found`)
                }

              },
            },
            data: initData,
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {fullClub,allowSetNewCustomerDiscountPercent,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {fullClub,allowSetNewCustomerDiscountPercent,mobileLayout}

              return {...globalStoreProps}
            },
            computed:  {
              formattedPhoneNumber: {
                get() {
                  return this.customer?.phone_number ? this.customer?.phone_number : "";
                },
                set(value) {
                  if(!value || value == ''){
                    this.customer.phone_number = ''
                  }else{
                    this.customer.phone_number = value.replace(/\D/g, '');
                  }
                }
              },
              isRoshemet: () => session.pos.isRoshemet,
              allowExtensionValidUntilCustomerClub: () => jsonConfig.getVal(jsonConfig.KEYS.allowExtensionValidUntilCustomerClub),
              allowToEditCustomerClub: () => jsonConfig.getVal(jsonConfig.KEYS.allowToEditCustomerClub),
              allowToEditCustomerClubValidUntil: () => jsonConfig.getVal(jsonConfig.KEYS.allowToEditCustomerClubValidUntil),
              searchCustomerMode(): boolean { return this.mode === ViewState.SEARCH },
              addNewCustomerMode(): boolean { return this.mode === ViewState.NEW },
              editExistingCustomerMode(): boolean { return this.mode === ViewState.EDIT },
              isHaveToChooseClubMembership: () => jsonConfig.getVal(jsonConfig.KEYS.isHaveToChooseClubMembership),
            }

        }

        searchDBHook();

        VueApp.component('positive-customer-club',positiveCustomerClub)

      }
    }
}
}
