module PositiveTS {
    export module Components {
        export module RestaurantPromoGroupItem {
            export function getComponent() {
                let component = {
                    template: JST.RestaurantPromoGroupItem(),
                    props: {
                        promoGroup: {
                            type: Object,
                        },
                    },
                    computed: {
                        currencySign() {
                            return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                        },
                        promoName(){
                            return this.promoGroup.promoName.substr(0,22);
                        },
                        discountAmount(){
                            return this.fixedNumber(this.lodash.round(this.promoGroup.discountAmountForGroup,2))
                        }
                    },
                    methods: {
                        confirmSaleDiscountDelete: () => posVC.confirmSaleDiscountDelete(),
                    }
                }
                return component;
            }
        }
    }
}