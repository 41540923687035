module PositiveTS {
export module Service {
export module ForceDailyZ {
    
    export function hasToPerformZ() {
        if(Pinia.globalStore.hasToPerformZ) {
            app.showAlert({
                header: i18next.t('error'),
                content: i18next.t("zReport.forceZReport"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              }, null, null);
            return true;
        }
        return false;
    }

    export function startCheckingIfZHasBeenDone() {
        checkIfZHasBeenDone();
        setInterval(async () => {
            checkIfZHasBeenDone();
        }, 60 * 60  * 1000)
    }

    async function checkIfZHasBeenDone() {
        let offlineSales = await appDB.sales.toArray();
        offlineSales = offlineSales.filter(sale => sale.syncStatus != 2 && sale.invoiceSequence > 0);
        if(Pinia.globalStore.isOnline && offlineSales.length == 0 ) {
            let oldestSale = await appDB.sales.orderBy("timestamp").first();
            if(oldestSale.invoiceSequence != -1 && moment.duration(moment().diff(moment(oldestSale.timestamp))).asHours() >= 22 && !Pinia.globalStore.hasToPerformZ) {
                Pinia.globalStore.setHasToPerformZ(true);
                hasToPerformZ();
            }
        }
    }
}}}