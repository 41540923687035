module PositiveTS {
  export module Components {
    export module SendInvoiceSmsDialog {
      const _vueComponentName = 'send-invoice-sms-dialog';

      export function create() {
        let SendInvoiceSmsDialog = {
          template: JST.sendInvoiceSmsDialog(),
          mixins: [keyPadMixin],
          components: {
            selfServiceKeypad: SelfServiceKeypad.getComponent(),
          },
          methods: {
            open(sale, actionBeforeFinish:(sale, phone) => Promise<Boolean> = null, keyPadWithAdvancedActions) {
              this.sale = sale;
              this.actionBeforeFinish = actionBeforeFinish;
              this.keyPadWithAdvancedActions = keyPadWithAdvancedActions

              return new Promise((resolve, reject) => {
                this.resolve = resolve;

                this.setPhoneFromSaleAndShow();
              });
            },
            cancel() {
              this.resolve(null);
              this.close();
            },
            close() {
              this.sale = null;
              this.phone = "";
              this.$el.close();
            },
            setPhoneFromSaleAndShow() {
              if (!posUtils.isBlank(this.sale.jsondata)) {
                let jsondata = JSON.parse(this.sale.jsondata);

                if (!posUtils.isBlank(jsondata.customer) && !posUtils.isBlank(jsondata.customer.s_phone_number_1))
                  this.phone = jsondata.customer.s_phone_number_1;
              }

              this.$el.showModal();
              this.$nextTick().then(() => {
                this.$refs.phone.focus();
              });
            },
            async finish(shouldPrint = false) {
              if (!posUtils.isValidIsraelMobilePhone(this.phoneStripped)) {
                this.showError(i18next.t('sendInvoiceSmsDialog.invalidPhone'));
                return;
              }

              let isSuccesfull = true;

              if (this.actionBeforeFinish) {
                  isSuccesfull = await this.actionBeforeFinish(this.sale, this.phoneStripped);
              }

              if (isSuccesfull) {
                this.resolve({print: shouldPrint, phone: this.phoneStripped});
                this.close();  
              }
            },
            showError(errorText) {
              return app.showAlertDialog({
                header: i18next.t('error'),
                content: errorText,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
            },
            clearTapped(stayOpen?) {
              if (stayOpen) {
                this.phone = "";
              } else {
                this.cancel();
              }
            },
            onKeyTapped(key) {
              if (key == 'bksp') {
                this.phone = this.phone.slice(0, -1);
              } else {
                  this.phone += key;
              }
            }
          },
          computed: {
            isOsherAd(){
              return PositiveTS.Service.OsherAd.isEnabled();
            },
            phoneStripped() {
              return this.phone.replace(/-/g, '');
            },

            showPrintButtonsOnSendSmsSale () {
              return jsonConfig.getVal(jsonConfig.KEYS.showPrintButtonsOnSendSmsSale)
            }
          },
          data() {
            return {
              phone: "",
              actionBeforeFinish: null,
              sale: null,
              resolve: null,
              keyPadWithAdvancedActions: false
            }
          }
        }
        VueApp.component(_vueComponentName, SendInvoiceSmsDialog)
      }
    }
  }
}
