module PositiveTS {
export module Promotions {
export module Templates {
export class PercentDiscountOnAllWhenBuyingAboveNItems extends TemplateAbstract {
  constructor(initParameters) {
    super(initParameters)
  }

  calculatePromotions() {
    // Go over the promotions to decide what to do next
    for (var promotionCode in this.parameters.promotionsBuy) {
      // Check that the promotion is in the promotion by code object
      if (!(promotionCode in this.parameters.promotionsByCode)) {
        continue;
      }

      // Get the promotion
      var promotion = this.parameters.promotionsByCode[promotionCode];

      // Check that this promotion is from template 6
      if (promotion.template !== '6') {
        // -- Not from template 2, skip!
        continue;
      }

      // -- This promotion is from template 6
      this.applyPromotion(promotion);
    }
  }

  applyPromotion(promotion) {
    if (!this.promotionHasMinimumQuantityAndAmount(promotion, this.parameters.promotionsBuy[promotion.code])) {
      return;
    }

    var saleItems = this.flattenSaleItemsByQuantity(this.parameters.promotionsBuy[promotion.code]);
    saleItems.sort(this.sortByUnitPriceFromExpensiveToCheapest);
    // flattenedSaleItemsBySide.get.sort(this.sortByUnitPriceFromExpensiveToCheapest);

    // -----------------------------------------------
    // | Apply promotion for sale items
    // -----------------------------------------------
    // Initialize an object to save the discount per sale item
    var discountAmountForSaleItem = {};
    var itemToPromote;

    var promoGroups = {};
    var discountValueAmount = 0;
    var discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT;

    var minBuyQuantity = parseInt(promotion.minimumBuyQuantity);
    for (var i = 0; i < saleItems.length ; i += 1) {

      itemToPromote = saleItems[i];
      if (!promoGroups[itemToPromote.id]) {
        promoGroups[itemToPromote.id] = {
          itemsCounter: {},
          promotion: promotion,
          discountAmountForGroup: 0,
          totalPriceForItemsBeforeDiscount: 0
        }
      }

      // Initialize entry in discountAmountForSaleItem
      if (!(itemToPromote.id in discountAmountForSaleItem)) {
        discountAmountForSaleItem[itemToPromote.id] = 0;
      }
      promoGroups[itemToPromote.id].itemsCounter = this.addToItemsCounter(itemToPromote,promoGroups[itemToPromote.id].itemsCounter);
      promoGroups[itemToPromote.id].totalPriceForItemsBeforeDiscount += itemToPromote.unitPrice;

      //Apply the discount
      discountValueAmount = itemToPromote.unitPrice * promotion.discountValue / 100;

      //for calculations in a later stage
      discountAmountForSaleItem[itemToPromote.id] += discountValueAmount;
      promoGroups[itemToPromote.id].discountAmountForGroup += discountValueAmount;
    }
    this.createPromoGroups(discountAmountForSaleItem, discountType, promoGroups, promotion);
  }

  // Go over the discount amount per sale item and apply it
  createPromoGroups(discountAmountForSaleItem, discountType, promoGroups, promotion) {
    for (var saleItemID in discountAmountForSaleItem) {
      var key = this._generateUniqueKey({id: saleItemID}, promotion.code, 'get');

      // Update data...
      var saleItemTotalAmount = this.parameters.saleItemsPotentialPromotions[key].unitPrice * this.parameters.saleItemsPotentialPromotions[key].quantity;
      this.parameters.saleItemsPotentialPromotions[key].discountAbsoluteValue = session.fixedNumber(discountAmountForSaleItem[saleItemID]);
      this.parameters.saleItemsPotentialPromotions[key].discountPrecentValue = session.fixedNumber(discountAmountForSaleItem[saleItemID] / saleItemTotalAmount * 100);
      this.parameters.saleItemsPotentialPromotions[key].discountType = discountType;
      this.parameters.saleItemsPotentialPromotions[key].isPromotionGiven = true;

      promoGroups[saleItemID].item = this.parameters.saleItemsPotentialPromotions[key];
    }
    for (let key in promoGroups) {
      this.parameters.promoGroups.push(promoGroups[key]);
    }
  }

}
}}}
