module PositiveTS {
  export module Components {
    export module SelfServiceGpp {

      export function getComponent() {
        return {
          template: JST.selfServiceGpp(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, keyPadMixin],
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {setCurrentStep} = selfServicePaymentStore;
            return {setCurrentStep}
          },
          methods: {
            async swipeCard() {
              app.showLoadingMessageDialog(i18next.t('caveretPayment.swipeCard'));

              this.cardNumber = await Service.EMV.swipe(this.amount);

              app.hideLoadingMessageDialog();
              this.$refs.cardNumber.type = 'password'
              this.$refs.cardNumber.disabled = true
              this.$refs.cardNumber.style.backgroundColor = "#c5c5c5"
              this.verifyAndPay()
            },

            async verifyAndPay() {
              if (!posUtils.isNullOrUndefinedOrEmptyString(this.cardNumber)) {
                app.showLoadingMessageDialog(i18next.t('selfService.chargingCard'));
                this.paymentInProcess = true
                let response = await Service.VoucherPayment.payBySmartVoucherTypeEx(Storage.Entity.Voucher.SMART_VOUCHER_GPP, this.amount, this.cardNumber);
                this.paymentInProcess = false
                if (response.success) {
                  app.hideLoadingMessageDialog();
                  this.goToNextStep();
                }
                else {
                  app.hideLoadingMessageDialog();
                  this.clearFields()
                  console.error(response.error)
                  await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: response.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                  this.$refs.cardNumber.focus()
                }
              }
              else {
                await app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t("selfServiceSupermarket.errors.fieldsAreRequired"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
                this.$refs.cardNumber.focus()
                
              }

            },
            clearFields() {
              this.cardNumber = ""
              this.$refs.cardNumber.type = 'text'
              this.$refs.cardNumber.disabled = false
              this.$refs.cardNumber.style.backgroundColor = "white"
            },
            onKeyTapped(key) {
              this.$refs.cardNumber.focus()
              this.keyPadHanlder(key, this.cardNumberField, this);
            },
            back() {
              this.goBack()
              this.clearFields()
            }
          },
          data: function () {
            return {
              paymentInProcess: false,
              cardNumber: "",
              amount: Pinia.globalStore.totalAmount,
              posWithPinPad: (jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv) ? true : false,
              cardNumberField: {
                allowDecimal: false,
                disabled: false,
                hasDecimal: false,
                inEditMode: true,
                model: "cardNumber",
                type: "text"
              }
            }
          },
          mounted() {
            this.$refs.cardNumber.focus()

          },
        }
      }
    }
  }
}