module PositiveTS {
    export module Service {
        export module ScheduledJobs {
            export enum WeekDays {
                SUNDAY,
                MONDAY,
                TUESDAY,
                WEDNESDAY,
                THURSDAY,
                FRIDAY,
                SATURDAYS,
              }
              export function scheduleOnceAWeek(time : string,dayWeek : string,fn : Function) {
                let hour = Number(time.split(':')[0]);
                let minute = Number(time.split(':')[1]);
                const ONE_WEEK_IN_HOURS = 168;
                const ONE_WEEK = 7;

                const today = new Date(); 
                const currentDay = today.getDay();
                const daysDistanceFromToday = Number(WeekDays[dayWeek]) - currentDay;
                let scheduledJobRunAt = new Date(today.getFullYear(), today.getMonth(), (today.getDate() + daysDistanceFromToday), hour, minute);

                if (scheduledJobRunAt.getTime() < today.getTime()) {
                    scheduledJobRunAt.setDate(scheduledJobRunAt.getDate() + ONE_WEEK);
                }

                const firstTriggerAfterMs = scheduledJobRunAt.getTime() - today.getTime();

                setTimeout(function () {
                    fn();
                    setInterval(fn, ONE_WEEK_IN_HOURS * 60 * 60 * 1000);
                }, firstTriggerAfterMs);

            }
            export function isInRange(value, range) {
                return value >= range[0] && value <= range[1];
            }

        }
    }
}