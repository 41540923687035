module PositiveTS {
  export module Components {
    export module hakafaMerakezInvoicesDialog {
      const dialogSelector = "hakafa-merakez-invoices-dialog"

      function initData() {
        return {
          initialized: this.initialized,
          invoicesPaid: 0,
          invoices: [],
          dialogElem: document.getElementById(dialogSelector),
          fromDate: moment(new Date()).startOf("month").toDate(),
          toDate: moment(new Date()).endOf("day").toDate(),
          invoiceDate: moment(new Date()).toDate(),
          customer: null,
          lastInvoice: null,
          mode: 'new',
          page: 1,
          per: 25,
          invoiceNumberSearch: "",
          customerInvoices: [],
          selectedInvoice: {},
          totals: 0,
          resolveFunc: null,
          rejectFunc: null
        }
      }

      export function create() {

        let component = {
          template: JST.hakafaMerakezInvoicesDialog(),
          methods: {

            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            },

            async open(customer) {
              return new Promise(async (resolve, reject) => {
                this.resolveFunc = resolve
                this.rejectFunc = reject
                try {
                  this.initialized = true;
  
                  if (!PositiveTS.Reachability.isOnline) {
                    await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.offlineMode'))
                    return;
                  }
                  app.showLoadingMessage(i18next.t('hakafaMerakezInvoiceDialog.messages.loadingInvoices'));
                  this.customer = customer;
                  this.invoices = await this.getShipmentDocsFromServer(customer.id);
                  this.lastInvoice = await this.getLastInvoice()
                  app.hideLoadingMessage();
  
                  if (this.dialogElem == null) {
                    this.dialogElem = document.getElementById(dialogSelector)
                  }
                  this.dialogElem.showModal();
  
                } catch (e) {
                  app.hideLoadingMessage();
                  console.log(e);
  
                  await this.showError(e.message)
                }
            });
            },

            close(needToRefreshCustomer = false) {
              if (this.initialized) {
                if (this.dialogElem == null) {
                  this.dialogElem = document.getElementById(dialogSelector)
                }
                this.dialogElem.close();
              }
              this.resolveFunc({
                needToRefreshCustomer: needToRefreshCustomer
              })
              this.cleanData()
            },

            async setMode(modeParam: string) {
              if(this.mode == modeParam){
                return
              }

              this.mode = modeParam

              switch (modeParam) {
                case 'show':
                  this.selectedInvoice = {}
                  break;

                case 'index':
                    this.invoiceNumberSearch = ""
                    await this.getCustomerInvoices()
                  break;

                default:
                  break;
              }
            },

            selectAll() {
              if (!this.invoices.length){
                return
              }
              for (const invoice of this.invoices) {
                if(invoice.checked) {
                  continue
                }
                invoice.current_tamash_amount_paid = invoice.tamash_bal
                invoice.checked = true
                this.invoicesPaid++

              }
            },
            diselectAll(){
              if (!this.invoices.length){
                return
              }
              for (const invoice of this.invoices) {
                if(!invoice.checked) {
                  continue
                }
                invoice.checked = false
                invoice.current_tamash_amount_paid = 0
                this.invoicesPaid--
              }
            },

            async getCustomerInvoices() {
              try {
                let hakafa_customer_id = this.customer.id;
              let page = this.page
              let per = this.per
              let invoice_number = this.invoiceNumberSearch
              let fromDate = this.fromDate
              let toDate = this.toDate
              let url = `/merakez_invoices/get_customer_invoices?page=${page}&per=${per}&hakafa_customer_id=${hakafa_customer_id}&invoice_number=${invoice_number}&fromDate=${fromDate}&toDate=${toDate}`

              app.showLoadingMessageDialog(i18next.t('hakafaMerakezInvoiceDialog.messages.searchingInvoice'))
              let req = await PositiveTS.Service.FetchReq.jsonReq(url, "get");

              if (!req.response.ok) {
                app.hideLoadingMessageDialog()
                await this.showError(req.result.error)
                return
              }

              if (req.result.merakez_invoices && req.result.merakez_invoices.length > 0) {
                this.customerInvoices = req.result.merakez_invoices
                this.totals = req.result.meta.total
              } else {
                let errMessage = posUtils.isBlank(invoice_number) ? i18next.t('hakafaMerakezInvoiceDialog.messages.noCustomerInvoices') : i18next.t('hakafaMerakezInvoiceDialog.messages.noInvoiceFound')
                await this.showError(errMessage)
              }

              app.hideLoadingMessageDialog()
              }
              catch (e) {
                app.hideLoadingMessageDialog()
                console.log(e);
                this.showError(e.message)
              }
            },

            async changePageAndFetch(num: number) {
              if (this.page === 1 && num === -1) {
                return
              }
              this.page += num
              await this.getCustomerInvoices()
            },

            async showError(errStr: string) {
              await app.showAlertDialog({
                header: i18next.t('error'),
                content: errStr,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true,
              })
            },

            async selectInvoice(invoice: object) {
              await this.setMode('show')

              let aInvoice = _.cloneDeep(invoice)
              aInvoice.merakez_invoice_lines = _.orderBy(aInvoice.merakez_invoice_lines, ['invoice_number'],['asc'])
              this.selectedInvoice = aInvoice
            },

            soldAtStr(soldAt) {
              if (!soldAt) {
                return "";
              }

              let date = moment(soldAt)
              return `${date.format("DD/MM/YYYY")}`
            },

            checkInvoice(invoice) {
              if (invoice.checked) {
                invoice.checked = false;
                this.invoicesPaid--;
                invoice.current_tamash_amount_paid = 0
              }
              else {
                invoice.checked = true;
                this.invoicesPaid++;
                if (!invoice.current_tamash_amount_paid) {
                  invoice.current_tamash_amount_paid = invoice.amount - invoice.tamash_amount_paid
                }
              }
            },

           async validateAmount(invoice, inputIndex){
              let {current_tamash_amount_paid, tamash_bal, invoice_type } = invoice;
              let el = document.getElementById('invoice-total-input' + inputIndex);

              if(invoice_type == Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {

                if(Number(current_tamash_amount_paid) > tamash_bal ){
                  await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.amountIsHigherThanLeftToPay'));
                  invoice.current_tamash_amount_paid = tamash_bal;
                  el.focus();
                }

                if(Number(current_tamash_amount_paid) < 0 ){
                  await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.amountCannotBeNegative'));
                  invoice.current_tamash_amount_paid = tamash_bal;
                  el.focus();
                }
              } else if (invoice_type == Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) { 

                if(Number(current_tamash_amount_paid) > 0){
                  await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.amountCannotBePositive'));
                  invoice.current_tamash_amount_paid = tamash_bal;
                  el.focus();
                }

                if(Number(current_tamash_amount_paid) < tamash_bal ){
                  await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.amountIsHigherThanLeftToRefund'));
                  invoice.current_tamash_amount_paid = tamash_bal;
                  el.focus();
                }
              }
              
              if(Number(current_tamash_amount_paid) === 0 ){
                invoice.checked = false
                invoice.current_tamash_amount_paid = 0
                this.invoicesPaid--
              }

              if(!Number.isInteger(Number(current_tamash_amount_paid)) && current_tamash_amount_paid.split(".")[1].length > 2) {
                invoice.current_tamash_amount_paid = session.fixedFloat(current_tamash_amount_paid, 2)
              }
            },

            showDocument(invoice_number: string) {
              posSearchInvoicesVC.searchObject = { invoice_number: invoice_number };
              posSearchInvoicesVC.showingResults = true;
              posSearchInvoicesVC.formRendered = true;
              this.close();
              PositiveTS.VueInstance.$refs.vueDialog.close()
              pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
            },

            async search() {
              if (!PositiveTS.Reachability.isOnline) {
                await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.offlineMode'))
                return;
              }

              try {
                app.showLoadingMessageDialog(i18next.t('hakafaMerakezInvoiceDialog.messages.searching'))
                let customer_id = this.customer.id
                this.invoicesPaid = 0
                this.invoices = await this.getShipmentDocsFromServer(customer_id)
                app.hideLoadingMessageDialog()
              }
              catch (e) {
                app.hideLoadingMessageDialog()
                this.showError(e)
              }
            },

            async getLastInvoice() {
              let req = await PositiveTS.Service.FetchReq.jsonReq(`/merakez_invoices/get_last_merakez_inv`, "get");
              return new Date(req.result.merakez_invoice.invoice_date)
            },

            async createMerakezInvoice() {
              if (!PositiveTS.Reachability.isOnline) {
                await this.showError(i18next.t('hakafaMerakezInvoiceDialog.messages.offlineMode'))
                return;
              }

              app.showLoadingMessageDialog(i18next.t('hakafaMerakezInvoiceDialog.messages.savingMerakezInvoice'))
              let rows = []

              for (const invoice of this.invoices) {
                if (invoice.checked) {
                  rows.push({
                    sale_id: invoice.sale_id,
                    amount: Number(invoice.current_tamash_amount_paid)
                  })
                }
              }

              if (rows.length === 0) {
                await app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('hakafaMerakezInvoiceDialog.messages.noItemsSelected'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
                app.hideLoadingMessageDialog()
                return
              }

              if (!await this.validateMerakezDate()) {
                app.hideLoadingMessageDialog()

                return
              }

              try {
                let customerName =`${this.customer.first_name} ${this.customer.last_name ?? ''}`;
                 
                let data = {
                  hakafaCustomerId: this.customer.id,
                  rows: rows,
                  dedicatedTo: customerName,
                  composedAt: this.invoiceDate.toString()
                }
  
                let req = await PositiveTS.Service.FetchReq.jsonReq("/merakez_invoices", "POST", data);
  
                app.hideLoadingMessageDialog()
  
                if (req.response.ok) {
                  Printing.Invoice.printMerakezInvoice(req.result.merakez_invoice)
                  await app.showAlertDialog({
                    content: i18next.t('hakafaMerakezInvoiceDialog.messages.merakezetCreatedSuccess'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                  this.close(true)
  
                } else {
                  await app.showAlertDialog({
                    content: req.result.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  })
                }
              } catch(e) {
                app.hideLoadingMessageDialog()
                this.showError(e.message)
              }
            },

            printInvoice(invoice, printAsCopy?) {
              let merakezInvoice = _.cloneDeep(invoice)
              app.showLoadingMessageDialog(i18next.t('hakafaMerakezInvoiceDialog.messages.printing'))

              if (printAsCopy) {
                merakezInvoice.is_copy = true
              }

              Printing.Invoice.printMerakezInvoice(merakezInvoice)

              app.hideLoadingMessageDialog()
            },

            async validateMerakezDate() {
              let today = new Date();

              if (this.invoiceDate > today) {

                await app.showAlertDialog({
                  header: i18next.t('hakafaMerakezInvoiceDialog.messages.invoiceDateErrorHeader'),
                  content:i18next.t('hakafaMerakezInvoiceDialog.messages.invoiceDateCannotBeGreater'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
                return false;
              }

              if (moment(_.clone(this.invoiceDate).setHours(0, 0, 0, 0)).diff(moment(_.clone(this.lastInvoice).setHours(0, 0, 0, 0)), 'days') < 0) {
                let formatedDate = moment(this.lastInvoice).format("DD/MM/YYYY")
                await app.showAlertDialog({
                  header: i18next.t('hakafaMerakezInvoiceDialog.messages.invoiceDateErrorHeader'),
                  content: `${i18next.t('hakafaMerakezInvoiceDialog.messages.invoiceDateCannotBeLesser')} \n ${formatedDate}`,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
                return false;
              }
              return true;
            },

            async getShipmentDocsFromServer(customer_id) {
              let self = VueInstance.$refs.hakafaMerakezInvoicesDialog;
              let fromDate = moment(self.fromDate).format("DD-MM-YYYY");
              let toDate = moment(self.toDate).format("DD-MM-YYYY");
              let invoicesFromServer = await PositiveTS.Service.FetchReq.jsonReq(`/sales?hakafa_customer_id=${customer_id}&by_date=true&from_date=${fromDate}&to_date=${toDate}&disable_range=true&include_hakafa_children_sales=true`, "get");
              let result = invoicesFromServer.result;
              let invoices = [];
              let acceptedInvoiceTypesArr = [Storage.Entity.Sequence.TYPE_SHIPMENT_INV, Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV];
              let acceptedInvoiceTypes = Object.assign({}, ...acceptedInvoiceTypesArr.map(key => ({ [key]: true })));
              for (let invoice of result) {
                if ((invoice.tamash_amount_paid != invoice.amount) && (acceptedInvoiceTypes[invoice.invoice_type])) {
                  invoice.checked = false;
                  invoice.current_tamash_amount_paid = 0
                  if (!invoice.tamash_amount_paid) {
                    invoice.current_tamash_amount_paid = 0;
                  }
                  invoice.sale_id = invoice.id
                  delete invoice.id
                  invoices.push(invoice);
                }
              }
              return invoices;
            },

            transmitInvoice(invoice){
              Pinia.componentsStore.openComponent({ componentName: "transmitTaxInvoiceDialog", args: [invoice, true, false, "merakez"] })
            }
          },

          data: initData,

          computed: {
            amount() {
              let amount = 0
              for (const invoice of this.invoices) {
                if(invoice.checked){
                  amount += Number(invoice.current_tamash_amount_paid)
                }
              }
              return amount
            },
            shortConfirmationNumber(){
              if(!this.selectedInvoice.tax_confirmation_number){
                return 0;
              }
              let length = this.selectedInvoice.tax_confirmation_number.length;
							return this.selectedInvoice.tax_confirmation_number.substring(length - 9, length);
            },
            
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          },


          watch: {
            mode: {
              handler() {
                this.fromDate = moment(new Date()).startOf("month").toDate(),
                this.toDate = moment(new Date()).endOf("day").toDate()
              },
              deep: true
            }
          }
        }

        VueApp.component('hakafa-merakez-invoices-dialog', component)

      }
    }
  }
}
