module PositiveTS {
export module Storage {
export module Entity {
export class Employee extends WasmEntity {

	name: string;
	employeeID: string
	magneticCard: string
	isCashier:boolean
	isManagerDiscountConfirm: boolean;
	isManagerMinusConfirm: boolean;
	isApprovesManagerDiscount:  boolean;
	canRunZReport:  boolean;
	canViewXReport:  boolean;
	canViewReports:  boolean;
	canCreditPayment:  boolean;
	canCancelSale:  boolean;
	canChangePrice:  boolean;
	canEditDalpaks: boolean;
	canEditItemsWithBonPrint: boolean;
	iscourier:boolean
	externalIdentification:number

	//roshemet fields
	isLocked: boolean;
	taz:string;
	serverID:number;
	syncStatus:number;
	email:string
	phone:string
	//end roshemet fields

	//permissions
	static IS_MANAGER = "isManagerDiscountConfirm" // זה כל הפעולות מנהל
	static APPROVES_DISCOUNT = "isApprovesManagerDiscount" // אישור על הנחה
	static CAN_VIEW_REPORTS = "canViewReports" // יכול לצפות בדוחות (מסך דוחות מהקופה)
	static CAN_RUN_Z_REPORT = "canRunZReport" // יכול להריץ דוח זד
	static CAN_VIEW_X_REPORT = "canViewXReport" // יכול לראות דוח X
	static CAN_CREDIT_PAYMENT = "canCreditPayment" // יכול לזכות תשלומים
	static CAN_CANCEL_SALE = "canCancelSale" // יכול למחוק מכירה
	static CAN_CHANGE_PRICE = "canChangePrice" // יכול להחליף מחיר לפריט
	static IS_MANAGEER_MINUS_CONFIRM = "isManagerMinusConfirm" //יכול לאשר פריט במינוס
	static CAN_EDIT_DALPAKS = "canEditDalpaks" // יכול לערוך דלפקים
	static 	CAN_EDIT_ITEMS_WITH_BON_PRINT = 'canEditItemsWithBonPrint' //עריכת פריטים אחרי הדפסת בונים

	constructor() {
		let meta = {
			name: 'Employee',
			fields: {
				employeeID: "TEXT",
				name: "TEXT",
				isCashier: "BOOL",
				magneticCard: "TEXT",
				isManagerDiscountConfirm: "BOOL",
				isManagerMinusConfirm: "BOOL",
				isApprovesManagerDiscount: "BOOL",
				canRunZReport: "BOOL",
				canViewXReport: "BOOL",
				canViewReports: "BOOL",
				canCreditPayment: "BOOL",
				canCancelSale: "BOOL",
				canChangePrice: "BOOL",
				canEditDalpaks: "BOOL",
				canEditItemsWithBonPrint: "BOOL",
				iscourier: "BOOL",
				taz: "TEXT",
				externalIdentification: "INT"
			},
			unique: ['employeeID']
		}

		if (session && session.pos && session.pos.isRoshemet) {
			meta.fields = Object.assign({
				isLocked: "BOOL",
				taz: "TEXT",
				serverID: "INT",
				syncStatus: "INT",
				email: "TEXT",
				phone: "TEXT"
			},meta.fields)
		}

		super(meta)
	}

	static shortMagneticCard(magneticCard) {
		if (posUtils.isNullOrUndefinedOrEmptyString(session.pos.parameterEmployeeMagneticCardLength)) {
			return magneticCard;
		}

		if (magneticCard.length >= session.pos.parameterEmployeeMagneticCardLength) {
			return magneticCard.substr(-session.pos.parameterEmployeeMagneticCardLength);
		} else {
			return posUtils.addZeroLeadingToString(magneticCard, Number(session.pos.parameterEmployeeMagneticCardLength));
		}
	}

	static import(emp) {
		let employeeModel = new Employee();
		return employeeModel.importFromObject(emp);
	}


	//no need to take roshemet into account here
	async searchByMagneticCardOrEmployeeNumberAndNotInStore (employeeNumberOrMagneticCard, notInStoreID) { 
		// Get the EmployeeStore table name
		let employeeStoreEntityName = (new PositiveTS.Storage.Entity.EmployeeStore()).meta.name;

		return this.execAndConvertResult(`
			SELECT e.* FROM ${this.meta.name} AS e
			LEFT JOIN ${employeeStoreEntityName} AS es
			ON e.employeeID = es.employeeID AND es.storeID = ${notInStoreID}
			WHERE
			(
				e.employeeID = '${employeeNumberOrMagneticCard}'
			OR
				e.magneticCard = '${Employee.shortMagneticCard(employeeNumberOrMagneticCard)}'
			)
			AND
				( es.id IS NULL )

		`)
	};

	//no need to take roshemet into account here
	async searchByMagneticCardAndNotInStore (magneticCard, notInStoreID) {
		// Get the EmployeeStore table name
		let employeeStoreEntityName = (new PositiveTS.Storage.Entity.EmployeeStore()).meta.name;

		return this.execAndConvertResult(`
			SELECT e.* FROM ${this.meta.name} AS e
			LEFT JOIN ${employeeStoreEntityName} AS es
			ON e.employeeID = es.employeeID AND es.storeID = ${notInStoreID}
			WHERE
			(
				e.magneticCard = '${Employee.shortMagneticCard(magneticCard)}'
			)
			AND
				( es.id IS NULL )
		`)
	};

	async fetchByStoreAndMagneticCardAndPermissions(storeID,magneticCard,permissions=[]){
		if (session.pos.isRoshemet) {
			return await this.fetchByStoreAndMagneticCardAndCanConfirmManagerDiscount(storeID,magneticCard);
		}
		else{
			let employeeStoreEntityName = (new EmployeeStore()).meta.name;
			let sql = `SELECT e.* FROM ${this.meta.name} AS e
			JOIN ${employeeStoreEntityName} AS es ON e.employeeID = es.employeeID
			WHERE es.storeID = ${storeID} AND magneticCard = '${Employee.shortMagneticCard(magneticCard)}' 
			AND (${Employee.IS_MANAGER} = 1`;

			for(let permission of permissions){
				sql = sql + ` OR ${permission} = 1`
			}

			sql = sql + ` )`

			return this.execAndConvertResult(sql);
		}
	}

	async fetchByStoreAndMagneticCardAndCanConfirmManagerDiscount (storeID, magneticCard) {
		
		let aThis = this;
		let items:Employee[]
		if (session.pos.isRoshemet) {
			items = (await appDB.employees.where('magneticCard').equals(magneticCard).toArray())
							.filter(e => Boolean(e.isManagerDiscountConfirm)).map(e => Employee.import(e));
		}
		else {
			let employeeStoreEntityName = (new EmployeeStore()).meta.name;
			items = this.execAndConvertResult(`SELECT e.* FROM ${aThis.meta.name} AS e
					JOIN ${employeeStoreEntityName} AS es ON e.employeeID = es.employeeID
					WHERE es.storeID = ${storeID} AND magneticCard = '${Employee.shortMagneticCard(magneticCard)}' AND isManagerDiscountConfirm = 1`);
		}
		return items;
	};

	async fetchByStoreAndIsCashier (storeID) {
		let aThis = this;
		let employeeStoreEntityName = (new EmployeeStore()).meta.name;
		let items:Employee[];

		if (session.pos.isRoshemet) {
			items = (await appDB.employees.filter(emp => Boolean(emp.isCashier)).toArray()).map(emp => Employee.import(emp));
		}
		else {
			items = this.execAndConvertResult(`SELECT e.* FROM ${aThis.meta.name} AS e 
						JOIN ${employeeStoreEntityName} AS es ON e.employeeID = es.employeeID
						WHERE es.storeID = ${storeID} AND isCashier = 1`)
		}
				
		return items;

	};


	static async fetchByStore (storeID):Promise<Employee[]> {

		if (session.pos.isRoshemet) {
			//no need to join store as in roshemet there is always one store
			let items = await appDB.employees.where("syncStatus").equals(Shared.Constants.SyncStatuses.SYNC_STATUS_SYNCED_SUCCESFULLY).toArray();
			return items;
		}
		else {
			let employeeStoreEntityName = (new PositiveTS.Storage.Entity.EmployeeStore()).meta.name;

			return this.execAndConvertResult(`SELECT e.* FROM Employee AS e JOIN 
											  ${employeeStoreEntityName} AS es ON e.employeeID = es.employeeID WHERE es.storeID = ${storeID} ORDER BY e.name`);
		}
	}

	static async fetchByStoreOrByShift (storeID) {
		
		// if (Boolean(jsonConfig.getVal(jsonConfig.KEYS.showAllEmployees))) {
		let result = await Employee.fetchByStore(storeID)
		if (Service.AutoEmployeeStore.isEnabled()) {
			if (session.pos.isRoshemet) {
				let yesterdayTS = moment(new Date()).subtract(24,'hour').toDate().getTime();
				let empIds = [];
				let timeTracks = await appDB.employeeTimeTracks.where('timestamp').aboveOrEqual(yesterdayTS).sortBy('timestamp');
				for (let tt of timeTracks) {
					if (tt.mode == EmployeeTimeTrack.MODE_ENTER) {
						empIds.push(tt.employeeID)
					}
					if (tt.mode == EmployeeTimeTrack.MODE_EXIT) {
						_.pull(empIds,tt.employeeID)
					}
				}
				return result.filter(row => empIds.indexOf(row.employeeID) > -1)
			}
			else {
				let employeeIds = await Service.AutoEmployeeStore.getEmployeeArray();
				return result.filter(row => employeeIds.indexOf(parseInt(row.employeeID)) > -1)
			}
		}
		return result;
		
	}


	async fetchByStoreAndEmployeeID (storeID, employeeID) {
		
		let aThis = this;
		let items:Employee[];
		
		if (session.pos.isRoshemet) {
			items = (await appDB.employees.where('employeeID').equals(employeeID).toArray()).map(e => Employee.import(e));
		}
		else {
			let employeeStoreEntityName = (new EmployeeStore()).meta.name;
			items = this.execAndConvertResult(`SELECT e.* FROM  ${aThis.meta.name} AS e
					JOIN ${employeeStoreEntityName} AS es ON e.employeeID = es.employeeID
					WHERE es.storeID = ${storeID} AND e.employeeID = ${employeeID}`)
		}

		return items;
	};

	async fetchByStoreAndMagneticCard (storeID, magneticCard) {
		
		let aThis = this;
		let items:Employee[];
		
		if (session.pos.isRoshemet) {
			items = (await appDB.employees.where('magneticCard').equals(magneticCard).toArray()).map(emp => Employee.import(emp));
		}
		else {
			let employeeStoreEntityName = (new EmployeeStore()).meta.name;

			items = this.execAndConvertResult(`SELECT e.* FROM ${aThis.meta.name} AS e
					JOIN ${employeeStoreEntityName} AS es ON e.employeeID = es.employeeID
					WHERE es.storeID = ${storeID} AND magneticCard = '${Employee.shortMagneticCard(magneticCard)}'`)
		}
		return items;

	};

	static async fetchByTaz (employeeTaz) {
		try {
			let result = this.execAndConvertResult(`select e.* from Employee as e where e.taz=${String(employeeTaz)}`)		

			if (result.length == 0) {
				throw new Error('Employee not found');
			}

			return result[0];
		}
		catch(e) {
			console.error("Error while trying to fetch employee by TZ");
			console.error(e);
			throw e;
		}
	};

	static async fetchByEmployeeID(id) {
		try {
			let result = this.execAndConvertResult(`select e.* from Employee as e where e.employeeID = ${String(id)}`)		

			if (result.length == 0) {
				throw new Error('Employee not found');
			}

			return result[0];
		}
		catch(e) {
			console.error("Error while trying to fetch employee by ID");
			console.error(e);
			throw e;
		}
	}
}}}}
