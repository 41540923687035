module PositiveTS {
  export module Service {
    export class PositiveDB extends Dexie {

      sales: Dexie.Table<Storage.Entity.Sale,string>
      elalDocNumbers: Dexie.Table<Storage.Entity.ElalReservation,string>
      backupSales: Dexie.Table<Storage.Entity.Sale,string>
      sequences: Dexie.Table<Storage.Entity.Sequence,string>
      zReports: Dexie.Table<Storage.Entity.ZReport,string>
      svaTransmitLogs: Dexie.Table<Storage.Entity.SvaTransmitLog,string>
      emvTransmitLogs: Dexie.Table<Storage.Entity.EmvTransmitLog,string>
      convertedTables: Dexie.Table<Storage.Entity.ConvertedTable,number>
      departments: Dexie.Table<Storage.Entity.Department,number>
      localItems: Dexie.Table<Storage.Entity.Item,number>
      promotions: Dexie.Table<Storage.Entity.Promotion,number>
      employees: Dexie.Table<Storage.Entity.Employee,string>
      employeeTimeTracks: Dexie.Table<Storage.Entity.EmployeeTimeTrack,string>
      suspiciousActivityLog: Dexie.Table<Storage.Entity.SuspiciousActivityLog, string>
      cashStatementLogs: Dexie.Table<Storage.Entity.CashStatementLog, string>
      currencies: Dexie.Table<Storage.Entity.Currencies, string>
      screenSavers: Dexie.Table<Storage.ScreenSaver,string>
      itemPictures: Dexie.Table<Storage.ItemPicture,string>
      airports: Dexie.Table<Storage.Entity.Airport,string>
      barsets: Dexie.Table<Storage.Entity.Barset,string>
      flights: Dexie.Table<Storage.Entity.Flight,string>
      positiveCustomer: Dexie.Table<Storage.Entity.PositiveCustomer,string>
      secondaryCategory: Dexie.Table<Storage.Entity.SecondaryCategory,string>
      dataDumps: Dexie.Table<any,string>
      externalOrders: Dexie.Table<Storage.Entity.ExternalOrder,string>
      dalpaks: Dexie.Table<Storage.Entity.Dalpak,string>
      dalpakAreas: Dexie.Table<Storage.Entity.DalpakArea,string>
      poses: Dexie.Table<Storage.Entity.Pos, string>
      vendingMachineItemInventory: Dexie.Table<Storage.Entity.VendingMachineItemInventory, string>
      videos: Dexie.Table<Storage.Video,string>
      keyValueStore: Dexie.Table<Storage.Entity.KeyValueStore,string>
      images: any


      constructor() {
        super("positive");
        Shared.DB.positiveDBVersions(this);
        
      }

      async init(){
        return await this._checkAndSetVersion();
      }

      private async _checkAndSetVersion() {
        try {
          let currentDBVersion = await this.getDatabaseVersion("positive");
          currentDBVersion = currentDBVersion /10;
          if (currentDBVersion > this.verno) {
            this.version(currentDBVersion).stores({});
          }else{
            currentDBVersion = this.verno;
          }
          //backward support cause without it on older versions it will breaks
          window.localStorage.setItem("positive-db-version",String(currentDBVersion));
          return currentDBVersion;
        } catch (error) {
          console.error("Failed to get current indexDB database version: ", error);
          window.localStorage.setItem("positive-db-version",String(this.verno));
          return this.verno;
        }
      }

      async getDatabaseVersion(dbName: string): Promise<number> {
        return new Promise((resolve, reject) => {
          const request = indexedDB.open(dbName);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const version = db.version;
            db.close();  // Close the database once you have the version
            resolve(version);
          };

          request.onerror = (event) => {
            reject('Error opening database: ' + (event.target as IDBOpenDBRequest).error?.message);
          };

          request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const version = db.version;
            db.close();  // Close the database once you have the version
            resolve(version);
          };
        });
      }

      async clearAllTables(){
        try{
           return Promise.all(appDB.tables.map( table=>  table.clear()));
        }catch(err){
          console.log(err);
        }
      }

    }

    Dexie.addons.push(Shared.DB.putAddon);
    Dexie.addons.push(Shared.DB.bulkPutAddon);

  }
}

declare var appDB:PositiveTS.Service.PositiveDB;