module PositiveTS {
  export module Service {
    export module ItemStock {

      interface Row {
        STORE_ID: string;
        STORE_NAME: string;
        STOCK_QTY: string;
      }

      const itemStockUrl = '/item_stock/';

      const genericError = "SERVER_INTERNAL_ERROR";

      export function getItemStock(itemBarcode): Promise<Row[]> {
        var _return: Row[];
        return FetchReq.jsonReq(itemStockUrl + itemBarcode, 'get')
          .then((response) => {
          if (!response.response.ok) { throw new Error(genericError); }
          _return = response.result.ROWSET.ROW;
          _removeZeroQuantity(_return);
          return _repopulateStoreNames(_return);
        })
          .then(() => {
          return _return;
        })
          .catch(() => {
          throw new Error(genericError);
        });
      }

      export function getCurrentStoreQty(rows: Row[]): string {
        for (var i = 0; i < rows.length; i++) {
          let currentRow = rows[i];
          if (currentRow.STORE_ID === session.pos.storeID) {
            return currentRow.STOCK_QTY;
          }
        }
        return "0";
      }

      function _removeZeroQuantity(rows: Row[]):void{
        for(var i = rows.length; i--;) {
            if(rows[i].STOCK_QTY === "0") {
                rows.splice(i, 1);
            }
        }
      }

      function _repopulateStoreNames(rows: Row[]): any {
        var _return = [];

        for (var i = 0; i < rows.length; i++) {
          _return.push(_overideStoreName(rows[i]));
        }
        return Promise.all(_return);
      }

      function _overideStoreName(row: Row): Promise<void> {
        return Storage.Entity.Store.getStoreName(session.pos.tenantID, session.pos.companyID, row.STORE_ID)
          .then((STORE_NAME) => {
          row.STORE_NAME = STORE_NAME;
          return;
        })
          .catch((e: Error) => {
          // Silent errors of none existed store ids.
          console.log(e.message);
        });
      }

    }

  }
}
