module PositiveTS {
    export module Service {
        export module AndroidNativeMessage {
            export module NativeHttpService {
                function removeEmptyFirstAndLastLines(str) {
                    if (str.charAt(0) == ' ') {
                        str = str.substr(1);
                    }
                    if (str.charAt(str.length - 1) == ' ') {
                        str = str.substr(0, str.length - 1);
                    }

                    return str
                }

                export async function sendNativeMessage(jsonData) {
                    let jsonDataParsed = JSON.parse(jsonData.data);
                    let headersString = ""

                    if (jsonDataParsed.headers) {
                        let headers = JSON.parse(jsonDataParsed.headers)
                        for (let header in headers) {
                            headersString = headersString + header + "=" + headers[header] + ","
                        }
                        headersString = headersString.substr(0, headersString.length - 1)
                    }

                    let timeout = 30000;
                    if (jsonData.data.includes("timeout")) {
                        timeout = JSON.parse(jsonData.data).timeout;
                    }

                    let requestBody = removeEmptyFirstAndLastLines(jsonDataParsed.body);

                    let response = await Service.AndroidService.makeActionAsyncIfSupported('HTTPRequestTimeoutFull', jsonDataParsed.url, jsonDataParsed.type, requestBody, timeout, headersString);

                    return {
                        request: {
                            body: {
                                data: jsonData.data
                            },
                            result: response,
                            url: "android.http"
                        }
                    };
                }
            }
        }
    }
}