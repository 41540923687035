module PositiveTS {
    export module Components {
        export module RestaurantDesktopOrderSummary {
            export function getComponent() {
                let component = {
                    template: JST.RestaurantDesktopOrderSummary(),
                    components: {
                        SingleItem: PositiveTS.Components.RestaurantSingleItem.getComponent(),
                        RestaurantItemActions: PositiveTS.Components.RestaurantItemActions.getComponent(),
                        PromoGroupItem: PositiveTS.Components.RestaurantPromoGroupItem.getComponent(),
                        PositiveIcon: PositiveIcon.getComponent(),
                    },
                    data() {
                        return {
                            search: '',
                            searchModeActive: false,
                            hasNewPromotions: session.pos.useNewPromotions,
                            dedicatedTo: "",
                        };
                    },
                    updated() {
                        $(document).unbind('keypress');
                        this.dedicatedTo = PositiveTS.Service.DedicatedTo.getCurrentDedicatedTo(
                            posVC.sale
                          );
                    },
                    computed: {
                        getCurrentCustomer() {
                            return this.dedicatedTo !=
                              this.posCustomerNameData?.displayName && this.dedicatedTo != ""
                              ? this.dedicatedTo
                              : this.posCustomerNameData?.displayName;
                        },
                        getSaleDiscounts() {
                            let result = this.promoGroups.filter((pg: Promotions.PromoGroup) => {
                                return pg.isSaleDiscount && pg.discountAmountForGroup;
                            })
                            return result;
                        },
                        currencySign() {
                            return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                        },

                        computedSaleItems() {
                            if (this.search == '') {
                                return this.saleItems;
                            }
                            return this.saleItems.filter((item) => {
                                return item.itemDescription.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                            });
                        },
                    },
                    setup(){
                        const dalpaksStore = Pinia.useDalpaksStore();
                        const posStore = Pinia.usePosStore();

                        const {getCurrentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);
                        const {expandedView,selectModeActive,selectedCount} = Pinia.storeToRefs(posStore);

                        const {leaveEditMode,setManualQuantitiy} = posStore

                        const globalStore = Pinia.useGlobalStore();
                        const {posCustomerNameData,
                            totalQuantity,
                            promoGroups,
                            sale,
                            canChangeQuantity,
                            posState,saleItems,
                            saleTotal,
                            mobileLayout} = Pinia.storeToRefs(globalStore);
                        const globalStoreProps = {posCustomerNameData,
                            totalQuantity,
                            promoGroups,
                            sale,
                            mobileLayout,
                            saleTotal,
                            saleItems,
                            canChangeQuantity,
                            showPos:(posState.value == "pos")} 
          

                        return {...globalStoreProps,getCurrentSelectedDalpak,expandedView,selectModeActive,selectedCount,leaveEditMode,setManualQuantitiy}
                    },
                    methods: {
                        async updateItemQuantity(data){
                            let saleItems = this.saleItems
                            
                            if(this.canChangeQuantity(data.item)){
                                await this.setManualQuantitiy({selectedItem:data.item, quantityToUpdate: data.quantityToUpdate})
                            }
                        },
                        toggleExpanedView() {
                            Pinia.posStore.toggleExpandedView();
                        },
                        toggleSelectMode() {
                            Pinia.posStore.toggleSelectMode();
                        },
                        toggleSearchMode() {
                            this.searchModeActive = !this.searchModeActive;
                            if (!this.searchModeActive) { // if we are leaving search mode
                                this.search = '';
                            }
                        },

                    }
                }
                return component;
            }
        }
    }
}