module PositiveTS {
  export module Components {
    export module Mixins {
      export let barsetMixin = {
        data() {
          return {
            barset: null,
          }
        },
        computed: {
          currentBarsetItems(){
            return Pinia.flightsStore.currentBarsetItems;
          },
          getItemInventoryByBarsetItemId(){
            return Pinia.flightsStore.getItemInventoryByBarsetItemId;
          },
          barsetItems() {
            return this.currentBarsetItems;
          },
        },
        methods: {
          getCurrentBarset() {
            return new Promise((resolve, reject) => {
              appDB.barsets.toCollection().first().then(async (result) => {
                app.hideLoadingMessage();
                this.barset = result;
                resolve(result);
              })
                .catch(err => {
                  app.hideLoadingMessage();
                  console.error(err);
                  this.showGenericError();
                  reject(err);
                });
            })
          },
          getBarsetItemsByDrawer(stockSetItems, mapFunction = null) {
            let stockUnits = {};

            for (let stockUnitItemIndex = 0; stockUnitItemIndex < stockSetItems.length; stockUnitItemIndex++) {
              let stockUnitItem = { ...stockSetItems[stockUnitItemIndex] };

              stockUnitItem.container_number--;
              stockUnitItem.drawer_number--;
              stockUnitItem.item_number--;

              if (!stockUnits[stockUnitItem.container_type]) {
                stockUnits[stockUnitItem.container_type] = [];
              }

              if (!stockUnits[stockUnitItem.container_type][stockUnitItem.container_number]) {
                stockUnits[stockUnitItem.container_type][stockUnitItem.container_number] = [];
              }

              if (!stockUnits[stockUnitItem.container_type][stockUnitItem.container_number][stockUnitItem.drawer_number]) {
                stockUnits[stockUnitItem.container_type][stockUnitItem.container_number][stockUnitItem.drawer_number] = [];
              }

              let item = { ...stockUnitItem, container_number: stockUnitItem.container_number + 1, drawer_number: stockUnitItem.drawer_number + 1, item_number: stockUnitItem.item_number + 1 };

              stockUnits[stockUnitItem.container_type][stockUnitItem.container_number]
                [stockUnitItem.drawer_number][stockUnitItem.item_number] = mapFunction ? mapFunction(item) : item;
            }

            return stockUnits;
          },
          getBarsetItems(mapFunc = null) {
            if (!this.barset) {
              return {};
            }

            return this.getBarsetItemsByDrawer(this.barsetItems, mapFunc);
          },
          getBarsetItemsMapped() {
            return this.getBarsetItems(this.barsetItemMapFunction);
          },
          barsetItemMapFunction(item) {
            return {
              code: item.code,
              description: this.getItemByCode(item.code).description,
              calculated: this.getCalculatedItemAmount(item),
              counted: this.getBarsetItemCount(item),
              id: this.getBarsetItemIdProperty(item),
            };
          },
          getBarsetItemCount(item) {
            return this.wizardData && this.wizardData.itemsCount && 
            this.wizardData.itemsCount[this.getBarsetItemIdProperty(item)] != null ?
            this.wizardData.itemsCount[this.getBarsetItemIdProperty(item)] : this.getCalculatedItemAmount(item);
          },
          getCalculatedItemAmount(item) {
            return this.zerofyMinus(this.getItemInventoryByBarsetItemId(item.id));
          },
          getBarsetItemIdProperty(barsetItem) {
            return barsetItem.id;
          },
          getItemByCode(code) {
            return session.allItems.get(code) || {};
          },
          getDrawerTitle(stockUnitType, stockUnitNumber, drawerNumber) {
            return i18next.t(`barset.stockUnitTypes.${stockUnitType}`) + ` ${stockUnitNumber + 1}, ${i18next.t('barset.drawer')} ${drawerNumber + 1}`
          },
          zerofyMinus(number) {
            return number < 0 ? 0 : number;
          },
        },
      }
    }
  }
}
