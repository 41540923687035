module PositiveTS {
	export module Service {
		export enum CATALOG_UPDATE_STATUS {
			UNDEFINED = 0,
			NOT_UPDATED = 1,
			UPDATED = 2
		};
		export const CATALOG_LAST_UPDATE_LOCAL_STORAGE_KEY = "catalogLastUpdate";
		export class IsCatalogUpdated {
			
			private isCriticalUpdate:boolean;
			public isDuringCatalogUpdate:boolean
			public updateStatus:CATALOG_UPDATE_STATUS;
			public refInterval:number;

			constructor() {
				this.isCriticalUpdate =  false;
				this.isDuringCatalogUpdate = false;
				this.updateStatus = CATALOG_UPDATE_STATUS.UNDEFINED;
			}

			public setIsLocalCatalogUpdatedStatus() {
				
				if (localStorage.getItem("serverCatalogDate") == null || session.pos.isRoshemet) {
					return;
				}
				
				let serverCatalogDate = new Date(localStorage.getItem("serverCatalogDate"));
				let serverIsCriticalUpdate = localStorage.getItem("serverCatalogisCritical") == "true"
				
				try {
				  
					let date = this.getLocalCatalogDate();
					if (date) {
						this.setUpdateStatus(serverCatalogDate, date, serverIsCriticalUpdate);
						if (this.updateStatus === CATALOG_UPDATE_STATUS.NOT_UPDATED && serverIsCriticalUpdate && !this.isDuringCatalogUpdate) {
							if (this.canForceUpdate()) {
								console.debug('forcing critical update!');
								this.isDuringCatalogUpdate = true; //this will be set to false after refresh
								this.forceCatalogUpdaeIfRequired();
							}
						}
						if(session.pos.isCaveret && date < serverCatalogDate ) {
							var isAutoRunHour = moment().isBetween((new Date()).setHours(3), (new Date()).setHours(7));
							var autoRunAlreadyBeenDone = false;
							if(localStorage.getItem("lastAutoUpdate")) {
								autoRunAlreadyBeenDone = moment(localStorage.getItem("lastAutoUpdate")).isBetween((new Date()).setHours(0), (new Date()).setHours(23,59));
							}
							if(isAutoRunHour && !autoRunAlreadyBeenDone) {
								localStorage.setItem("lastAutoUpdate", moment().format());
								//this local db update will run on 320 poses simultaneosly, so I created a number based on the store id and ran the script after that time.
								//with this and the lord almighty's help the server shall survive, 
								var minutesToWait = Math.round(Number(String(session.pos.storeID).substr(0, 3))*0.06) + Math.floor(Math.random() * 4); 
								setTimeout(function () { statsVC.reloadAllData(false) }, minutesToWait * 60 * 1000);
							}
						}
						
					}
					else {
						console.warn("Not setting catalog date becase POS has no catalog");
					}
				}
				catch(e) {
					Service.Logger.error(e);
				};
			}

			public forceCatalogUpdaeIfRequired() {
				if (this.canForceUpdate()) {
				  statsVC.triggerUpdateDbUsingUi();
				}
			  };

			public saveLocalStorageCatalogUpdateDate() {
				Pinia.globalStore.setLastCatalogUpdate(new Date().toJSON());
			};

			getLocalCatalogDate() {
				let lastUpdateString = Pinia.globalStore.lastCatalogUpdate;
				if (lastUpdateString) {
					return new Date( lastUpdateString );
				} else {
					return undefined; //if we got to here it means that this is a new POS and we never ran עדכון מידע
				}
			}

			setUpdateStatus(serverCatalogDate, localCatalogDate, serverIsCriticalUpdate) {
				this.isCriticalUpdate = serverIsCriticalUpdate;
					let d = new Service.DateDiff(serverCatalogDate, localCatalogDate);
					if (d.diffTotalMins < -5) {
						this.updateStatus = CATALOG_UPDATE_STATUS.NOT_UPDATED;
					} else {
						this.updateStatus = CATALOG_UPDATE_STATUS.UPDATED;
					}
					this.setUiStatus();
			};

			setUiStatus() {
				if ( this.updateStatus == CATALOG_UPDATE_STATUS.NOT_UPDATED && !session.pos.isRoshemet ) {
					$("#header_new_catalog_available").show();
				} else {
					$("#header_new_catalog_available").hide();
				}
			};

			canForceUpdate() {
				return ( this.updateStatus === CATALOG_UPDATE_STATUS.NOT_UPDATED && this.isCriticalUpdate && (!posVC.saleItems || posVC.saleItems.length === 0) );
			}

			

		}
	}
}
