module PositiveTS {
   export module Service {
      export module OutOfStock {

         let syncModule: WebsocketSync.OutOfStockSyncModule = null;

         export function init() {
            syncModule = new WebsocketSync.OutOfStockSyncModule();
            WebsocketSync.SyncServerClient.getInstance().addModule(syncModule);
         }

         export async function updateOutOfStock(outOfStockItemCodes){
            try{
               let itemCodes = JSON.stringify(outOfStockItemCodes);
               let tenantId = session.pos.tenantID;
               let storeId = session.pos.storeID;
               const dataToUpdate = {tenantId,storeId,outOfStockItemCodes:itemCodes};
               await syncModule.sendOutOfStockUpdatedData(dataToUpdate);
            }catch(err){
                console.log(err)
            }
         }

         export async function getUpdatedOutOfStockData(){
            return syncModule.outOfStockData;
         }
         export function reLoadOutOfStockItemsCodes(){
            Pinia.globalStore.loadOutOfStockItemsCodes();
         }

      }
   }
}