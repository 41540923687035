module PositiveTS {
export module Storage {
export module Entity {
export class SuspiciousActivityLog extends IDBEntity {

	action: number;
	employee: number;
	serverId: number;
	syncStatus: number;
	manager: number;
	amount: number;
	target_name: string;

	constructor() {
		let meta = {
			name: 'Employee',
			fields: {
				action: "INT",
				employee: "INT",
				serverId: "INT",
				syncStatus: "INT",
				manager: "INT",
				amount: "FLOAT",
				target_name: "TEXT"
			},
		}
		super(meta)

		this.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
		this.timestamp = Math.floor(Date.now() / 1000);
	}

	static async logSuspiciousActivity(action, employee = null, manager = null, amount = null, target_name = null) {
		let activity = new SuspiciousActivityLog();
		activity.action = action;
		activity.employee = employee;
		activity.manager = manager;
		activity.amount = amount;
		activity.target_name = target_name;
		await appDB.suspiciousActivityLog.put(activity);
		
	}

}}}}
