module PositiveTS {
export module Service {
export module PromotionsReport {
   
    
    function getAllData(){
        var sql = "select name, code, fromDate, toDate from promotion order by fromdate";
        return PositiveTS.Service.WasmDB.promiseSql(sql);
      };
    
      function getAryOfPromotionIDsForStore(rows){
        var _return = [];
        for (var i=0; i<rows.length; i++){
          _return.push(rows[i].promotionID);
        }
        return _return;
      };
    
      function filterByStoreId(result){
        var sql = "select promotionID, storeID from promotionStore where storeID = " + session.pos.storeID;
        return PositiveTS.Service.WasmDB.promiseSql(sql)
        .then(function(promotionStoreRows){
          if (promotionStoreRows.length === 0){return result;}
          var _return_result = [];
    
          var aryOfPromotionIDsForStore = getAryOfPromotionIDsForStore( promotionStoreRows );
          
          for (var i=0; i<result.length; i++){
           if ( aryOfPromotionIDsForStore.indexOf( result[i].code ) > -1) {
              _return_result.push(result[i]);
           }  
          }
          return _return_result;
        });
      };
    
    
      function filterByDateRange(result){
        return result.filter(promotion => {
          let startHour = promotion.start_hour == null ? "00" : String(promotion.start_hour).padStart(2,"0");
          let startMinute = promotion.start_minute == null ? "00" : String(promotion.start_minute).padStart(2,"0");
          let endHour = promotion.end_hour == null ? "23" : String(promotion.end_hour).padStart(2,"0");
          let endMinute = promotion.start_minute == null ? "59" : String(promotion.end_minute).padStart(2,"0");
  
          let fromDate = moment(`${promotion.fromDate} ${startHour}:${startMinute}:00`,"DD/MM/YYYY HH:mm:ss")
          let toDate = moment(`${promotion.toDate}  ${endHour}:${endMinute}:59`,"DD/MM/YYYY HH:mm:ss")
  
          return moment().isBetween(fromDate,toDate);
        });
      };
    
      function filterResult(result){
        return filterByStoreId(result)
        .then(function(result){
          return filterByDateRange(result);
        });
      };
    
      export function getData(){
        return getAllData()
        .then(function(result){
          return filterResult(result);
        });
      };
   
}}}
