module PositiveTS {
export module Components {
    export module PositiveDeliveryOrderDetail {
      const dialogSelector = "#positive-delivery-order-detail"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

     
      function _initjQueryDialogIfNeeded() {
        let aThis = this;
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...


            let mobileLayout = Pinia.globalStore.mobileLayout;

            $(dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: "100%",
              position: 'top', 
              height: mobileLayout ? window.outerHeight : 640,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });

          this.initialized = true
        }

      }
      
      function initData() {
        
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          fields: initFields(),

          shortCustomerAddress: "",
          customerName: null,
          customerId: null,
          phoneNumber: null,

          ordererName: null,
          ordererPhone: null,
          ordererDeliveryRemarks: null,
          ordererCallerRemarks: null,

          hasCompensation: false,
          isCustomerClubMode: false,
          notForDelivery: false,
          title: i18next.t("positiveDeliveryOrderDetail.title"),
          customer: {},
        }
      }

      function initFields() {
        let fields = {
          'customerName': {
            order: 0,
            selected: false,
            allowDecimal: false,
            inEditMode: false,
            type: 'string'
          }
        }
        return fields;
      }

      function fixCustomerAddressesIfNeeded(customer){
        for(let address of customer.customer_addresses){
          if(!address.city.name){
            address.city = {
              name: address.city,
              value: address.city_id,
              cityID: address.city_id
            }

            if(!address.address.name){
              address.address = {
                name: address.address,
                value: address.street_id,
                cityID: address.city_id
              }
            }
          }
        }
      }


      async function close(cancel?:boolean) {
        if (this.initialized) {
          $(dialogSelector).dialog('close');
        }

        let sale = posVC.sale;
        let jsondata = JSON.parse( sale.jsondata );
        jsondata.delivery = jsondata.delivery || {}
        jsondata.delivery.ordererName = this.ordererName;
        jsondata.delivery.ordererPhone = this.ordererPhone;
        jsondata.delivery.ordererDeliveryRemarks = this.ordererDeliveryRemarks;
        jsondata.delivery.ordererCallerRemarks = this.ordererCallerRemarks
        jsondata.delivery.status = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
        fixCustomerAddressesIfNeeded(jsondata.customer);

        jsondata.delivery.orderTime = new Date();

        sale.orderTime = jsondata.delivery.orderTime;
        sale.deliveryStatus = Storage.Entity.Sale.OPEN_DELIVERY;
        sale.jsondata = JSON.stringify(jsondata);

        posVC.setCustomerState();
        
        PositiveTS.Service.FullSale.persist(posVC.sale,posVC.saleItems,[]);

        if(cancel){
          await posVC.restartSale();
          this.rejectFunc()
        }else{
          this.resolveFunc()
        }

        this.cleanData()
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
        
      }

      function open(isCustomerClubMode = false, customer = {}, notForDelivery = false) {

        return new Promise((resolve,reject) => {
          _initjQueryDialogIfNeeded.call(this)

          $(document).unbind('keypress');

          $(dialogSelector).dialog('open');
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          this.isCustomerClubMode = isCustomerClubMode
          if (isCustomerClubMode){
            this.title = i18next.t("positiveDeliveryOrderDetail.titlePickCustomerMode")
          }
          this.notForDelivery = notForDelivery;
          this.customer = customer

          var jsondata = JSON.parse( posVC.sale.jsondata );
          this.customerName = `${jsondata.customer.s_first_name} ${jsondata.customer.s_last_name}`
          this.customerId = jsondata.customer.db_id
          this.phoneNumber = jsondata.customer.s_phone_number_1  
          this.shortCustomerAddress = PositiveTS.Service.Delivery.getShortDeleveryCustomerAddress(posVC.sale.jsondata) 
          if (posUtils.isBlank(this.shortCustomerAddress)){
            this.shortCustomerAddress = PositiveTS.Service.CustomerClub.getShortDeliveryCustomerAddress(this.customer)
          }
          if((jsondata.customer.compensation != "") && (jsondata.customer.compensation != null)){
            this.hasCompensation = true;
          }

          jsondata.delivery = jsondata.delivery || {}
          
          if(this.ordererName == null){
            this.ordererName = `${jsondata.customer.s_first_name} ${jsondata.customer.s_last_name}`
          }

          if(this.ordererPhone == null){
            this.ordererPhone = jsondata.customer.s_phone_number_1  
          }

          if(this.ordererDeliveryRemarks == null){
            this.ordererDeliveryRemarks = jsondata.delivery.ordererDeliveryRemarks;
          }
          if(this.ordererCallerRemarks == null){
            this.ordererCallerRemarks = jsondata.customer.remarks
          }

          if(session.isAndroid){
            let element = document.getElementById("ordererName")
            if (element){
              element.blur()
            }
          }
        })
      }

      function _deliveryPreviousOrders(){
        PositiveTS.Components.PositiveDeliveryPreviousOrders.openPreviousOrders(parseInt(this.customerId), this.isCustomerClubMode);
        
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t("error"),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        let aThis = this;
        app.showAlert({
          header: i18next.t("error"),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.$refs.searchField.focus();
        },null)
      }

      function confirmAndClose() {
        this.resolveFunc({approved:true, weight: Number(this.weight)})
        this.close();
        
      }

       function openCompensation(){
Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryCustomerCompensation", args: [this.customerId]});
      }

      async function _selectAddress(){
        try{
          await Pinia.componentsStore.openComponent( {componentName:"positiveDeliveryCustomerClub", args: [true, this.isCustomerClubMode, null, false, true, this.notForDelivery]})
          this.shortCustomerAddress = PositiveTS.Service.Delivery.getShortDeleveryCustomerAddress(posVC.sale.jsondata)
        }catch(error){
          console.error(error)
        }
      }

      export function create() {

        let component = {
          template: JST.positiveDeliveryOrderDetail(),
          mixins: [],
          methods: {
            cleanData: cleanData,
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            showError: showError,
            showGenericError: showGenericError,   
            selectAddress: _selectAddress,
            openCompensation: openCompensation, 
            deliveryPreviousOrders: _deliveryPreviousOrders,         
          },
          data: initData,
          computed: {
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
          }
        }

        VueApp.component('positive-delivery-order-detail',component)

      }
    }
  }
}
