module PositiveTS {
export module Service {
export module Check {
export module DialogErnSetCheckNumber {
  var _aryCheckNumbers:string[] = []
  var _firstPaymentAmountForDisplay = 0
  var _otherPaymentAmountForDisplay = 0

  var _I18L = {
    CHECK_NUMBER: "מספר צק: ",
    SUM: "סכום: ",
    TYPE_CHECK_NUMBER: 'אנא הזן מספר צק',
  };


  export function promiseReturnCheckNumbersArray(aryCheckNumbers:string[], dictSoapData:ClassDictTran):Promise<string[]>{
    _firstPaymentAmountForDisplay = posUtils.fromInt(dictSoapData.CheckSum,2);
    _otherPaymentAmountForDisplay = dictSoapData.payments[0].amount;  
    _aryCheckNumbers = aryCheckNumbers;
    return new Promise(function(resolve) {
      _bindContinueButtons(function(response){
        resolve(response);
      });
      _open();
    
    });    
  };
  

  function _bindContinueButtons(callback:Function){    
    OptionSelectUI.resetUi();    
    $('#OptionSelect-dialog-cancel').hide();
    $('#OptionSelect-dialog-continue').show();
    
    $('#OptionSelect-dialog-continue').unbind('click');
    $('#OptionSelect-dialog-continue').click(function () {
            OptionSelectUI.dialog('close');
            callback(_aryCheckNumbers);
    });
  };


  function _getItemList(){
    var _return = [];
    for (var i=0; i< _aryCheckNumbers.length; i++ ){
      _return.push({
        code: _aryCheckNumbers[i], 
        description: _getCheckNumberText( _aryCheckNumbers[i], i ), 
      });
    }
    return _return;
  };


  function _open(){
    OptionSelectUI.dialog('open');
    _paintTableView();
  };

  function _paintTableView() {
    var itemList = _getItemList(); 
    var tableView = $('#OptionSelect-dialog-tableview').tableview();
    tableView.setNumberOfVisibleRows(6);

    tableView.empty();
        
    for (var i=0;i<itemList.length; i++){
      var theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
                  itemList[i].description
      ]));
      theRow.data('data', i);      
    }    

    $("#OptionSelect-dialog-tableview .ui-tableview-row-cell").on('click',function(m){
      var clickedIdx =  $(m.target).closest('tr').data().data;
      
      return inputDg.open({
        header: _I18L.TYPE_CHECK_NUMBER,
        description: _I18L.TYPE_CHECK_NUMBER,
        inputPlaceHolder: _I18L.TYPE_CHECK_NUMBER,
        showCancelButton: false,
        emptyErrorMessage: _I18L.TYPE_CHECK_NUMBER,
        strDefault: _aryCheckNumbers[clickedIdx],
        inputValidator: function (value) {
          if (value) {return true;}
        }
      })
      .then(function(checkNumber){
        _aryCheckNumbers[clickedIdx] = PositiveTS.Service.StrUtils.lpad( checkNumber, 9);
        _paintTableView();
      });
      

    });
  };

  function _getCheckNumberText(checkNumber:string, idxInArray:number){    
    var str = _I18L.CHECK_NUMBER + checkNumber+ " ," + _I18L.SUM + " ";
    if (idxInArray === 0){
      str += _firstPaymentAmountForDisplay.toString();
    } else {
      str += _otherPaymentAmountForDisplay.toString();
    }
    str += ".";
       
    return str;
  };

}}}}

