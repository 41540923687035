module PositiveTS {
export module Service {
export class BackgroundKeeper {

    private interval:any
    private timeout = 5 * 60 * 1000; //5 minutes
    
    constructor() {
        this.interval = 0;
    }

    private keepFromBackground() {
        
        if (moment().hours() < 6) { //don't run between midnight to 6 am
            return;
        }

        let mouseMoveEvent = document.createEvent("MouseEvents");

        mouseMoveEvent.initMouseEvent(
           "mousemove", //event type : click, mousedown, mouseup, mouseover, mousemove, mouseout.  
           true, //canBubble
           false, //cancelable
           window, //event's AbstractView : should be window 
           1, // detail : Event's mouse click count 
           Math.round((Math.random()*100)), // screenX
           0, // screenY
           Math.round((Math.random()*100)), // clientX
           0, // clientY
           false, // ctrlKey
           false, // altKey
           false, // shiftKey
           false, // metaKey 
           0, // button : 0 = click, 1 = middle button, 2 = right button  
           null // relatedTarget : Only used with some event types (e.g. mouseover and mouseout). In other cases, pass null.
        );

        document.dispatchEvent(mouseMoveEvent);
    }

    start() {
        if (this.interval != 0) {
            console.warn('timer is already running - please stop it before starting again')
            return;
        }
        this.interval = setInterval(this.keepFromBackground,this.timeout);
    }

    stop() {
        clearInterval(this.interval);
        this.interval = 0;
    }
}}}
