export function fixedNumber(numberParam: string | number, decPrec?:number, cosmeticOnly = false): string {
    // Make sure the number is indeed a number
    let num = parseFloat(String(numberParam));
    if (isNaN(num)) {
      console.trace('Error fixedNumber arguments:', [...arguments])
      throw new TypeError(`Value: ${numberParam} cannot be converted to float`);
    }

    let decimalPrecision = 2;
    let posDecimalPrecision = null;


    if (decPrec) {
      decimalPrecision = decPrec;
    }
    // Make sure that sign does not affect the rounding (-1.45 is rounded to -1.4 while 1.45 is rounded to 1.5)
    // So we ignore the sign and add it back later. this way -1.45 is rounded to -1.5 and 1.45 is rounded to 1.5
    let sign = num < 0 ? -1 : 1;
    // Format number according to POS parameters
    if (posDecimalPrecision != decimalPrecision && posDecimalPrecision != null && cosmeticOnly) {
      return (Number((Math.abs(num)).toFixed(posDecimalPrecision))*sign).toFixed(decimalPrecision);
    }
    else {
      return (Number((Math.abs(num)).toFixed(decimalPrecision))*sign).toFixed(decimalPrecision);
    }
  }

  export function fixedFloat(num: number | string, decPrec?:number): number {
    return Number(fixedNumber(num,decPrec));
  }

  export function isNullOrUndefined(value:any):boolean {
    if (typeof (value) === 'undefined') {
      return true;
    }
    if (value === null) {
      return true;
    }
    return false;
  }

  export function isNullOrUndefinedOrEmptyString(value:any):boolean {
    return isNullOrUndefined(value) || String(value).trim() == '';
  }

  export function xml2json(xml:string, tab = "") {
    let xmlDoc;
    let parsedXml = (new DOMParser()).parseFromString(xml, "text/xml"); 
    var X = {
      toObj: function(xml) {
          var o = {};
          if (xml.nodeType==1) {   // element node ..
            if (xml.attributes.length)   // element with attributes  ..
                for (var i=0; i<xml.attributes.length; i++)
                  o["@"+xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue||"").toString();
            if (xml.firstChild) { // element has child nodes ..
                var textChild=0, cdataChild=0, hasElementChild=false;
                for (var n=xml.firstChild; n; n=n.nextSibling) {
                  if (n.nodeType==1) hasElementChild = true;
                  else if (n.nodeType==3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
                  else if (n.nodeType==4) cdataChild++; // cdata section node
                }
                if (hasElementChild) {
                  if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                      X.removeWhite(xml);
                      for (var n=xml.firstChild; n; n=n.nextSibling) {
                        if (n.nodeType == 3)  // text node
                            o["#text"] = X.escape(n.nodeValue);
                        else if (n.nodeType == 4)  // cdata node
                            o["#cdata"] = X.escape(n.nodeValue);
                        else if (o[n.nodeName]) {  // multiple occurence of element ..
                            if (o[n.nodeName] instanceof Array)
                              o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                            else
                              o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                        }
                        else  // first occurence of element..
                            o[n.nodeName] = X.toObj(n);
                      }
                  }
                  else { // mixed content
                      if (!xml.attributes.length)
                        o = X.escape(X.innerXml(xml));
                      else
                        o["#text"] = X.escape(X.innerXml(xml));
                  }
                }
                else if (textChild) { // pure text
                  if (!xml.attributes.length)
                      o = X.escape(X.innerXml(xml));
                  else
                      o["#text"] = X.escape(X.innerXml(xml));
                }
                else if (cdataChild) { // cdata
                  if (cdataChild > 1)
                      o = X.escape(X.innerXml(xml));
                  else
                      for (var n=xml.firstChild; n; n=n.nextSibling)
                        o["#cdata"] = X.escape(n.nodeValue);
                }
            }
            if (!xml.attributes.length && !xml.firstChild) o = null;
          }
          else if (xml.nodeType==9) { // document.node
            o = X.toObj(xml.documentElement);
          }
          else
            alert("unhandled node type: " + xml.nodeType);
          return o;
      },
      toJson: function(o, name, ind) {
          var json = name ? ("\""+name+"\"") : "";
          if (o instanceof Array) {
            for (var i=0,n=o.length; i<n; i++)
                o[i] = X.toJson(o[i], "", ind+"\t");
            json += (name?":[":"[") + (o.length > 1 ? ("\n"+ind+"\t"+o.join(",\n"+ind+"\t")+"\n"+ind) : o.join("")) + "]";
          }
          else if (o == null)
            json += (name&&":") + "null";
          else if (typeof(o) == "object") {
            var arr = [];
            for (var m in o)
                arr[arr.length] = X.toJson(o[m], m, ind+"\t");
            json += (name?":{":"{") + (arr.length > 1 ? ("\n"+ind+"\t"+arr.join(",\n"+ind+"\t")+"\n"+ind) : arr.join("")) + "}";
          }
          else if (typeof(o) == "string")
            json += (name&&":") + "\"" + o.toString() + "\"";
          else
            json += (name&&":") + o.toString();
          return json;
      },
      innerXml: function(node) {
          var s = ""
          if ("innerHTML" in node)
            s = node.innerHTML;
          else {
            var asXml = function(n) {
                var s = "";
                if (n.nodeType == 1) {
                  s += "<" + n.nodeName;
                  for (var i=0; i<n.attributes.length;i++)
                      s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue||"").toString() + "\"";
                  if (n.firstChild) {
                      s += ">";
                      for (var c=n.firstChild; c; c=c.nextSibling)
                        s += asXml(c);
                      s += "</"+n.nodeName+">";
                  }
                  else
                      s += "/>";
                }
                else if (n.nodeType == 3)
                  s += n.nodeValue;
                else if (n.nodeType == 4)
                  s += "<![CDATA[" + n.nodeValue + "]]>";
                return s;
            };
            for (var c=node.firstChild; c; c=c.nextSibling)
                s += asXml(c);
          }
          return s;
      },
      escape: function(txt) {
          return txt.replace(/[\\]/g, "\\\\")
                    .replace(/[\"]/g, '\\"')
                    .replace(/[\n]/g, '\\n')
                    .replace(/[\r]/g, '\\r');
      },
      removeWhite: function(e) {
          e.normalize();
          for (var n = e.firstChild; n; ) {
            if (n.nodeType == 3) {  // text node
                if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
                  var nxt = n.nextSibling;
                  e.removeChild(n);
                  n = nxt;
                }
                else
                  n = n.nextSibling;
            }
            else if (n.nodeType == 1) {  // element node
                X.removeWhite(n);
                n = n.nextSibling;
            }
            else                      // any other node
                n = n.nextSibling;
          }
          return e;
      }
    };
    if (parsedXml.nodeType == 9) // document node
      xmlDoc = parsedXml.documentElement;
    var json = X.toJson(X.toObj(X.removeWhite(xmlDoc)), xmlDoc.nodeName, "\t");
    return JSON.parse("{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}");
  }

  export function isBlank(value:any):boolean {
    return isNullOrUndefinedOrEmptyString(value);
  }
  
  export function createUUID() {
    var s = [];
    var hexDigits = "0123456789ABCDEF";
    for ( var i = 0; i < 32; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[12] = "4";
    s[16] = hexDigits.substr((s[16] & 0x3) | 0x8, 1);
  
    var uuid = s.join("");
    return uuid;
  }

  export function refreshApp() {
    if (typeof(Android) !== "undefined" && Android.refreshWebview) {
      Android.refreshWebview();
    } else {
      window.location.reload();
    }
  }

  export function isAndroidActionExists(actionName): boolean {
    return typeof Android != "undefined" && Android[actionName];
  }


  export function generateDeferredPromise() {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      [resolve, reject] = [res, rej];
    });
    return {promise, reject, resolve};
  }

  export function isString(text) {
    return (typeof text === 'string' || text instanceof String);
  }

  export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  }
  

  export function LightenDarkenColor(col, amt) {

    var usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);
    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let g = ((num >> 8) & 0x00FF) + amt;

    if (g > 255) g = 255;
    else if  (g < 0) g = 0;

    let b = (num & 0x0000FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;


    return `rgb(${r},${g},${b})`
    //(usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  }

  export function sleep(milliseconds: number) {
    return new Promise(res => setTimeout(res, milliseconds));
  }