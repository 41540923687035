module PositiveTS {
export module Service {
export module Hakafa {
    const url = "/hakafa_customers/report"
    const startOfTime = new Date("2000-01-01");

    class inParams {
      customer_number: string;
      fromDate: string;
      toDate: string;

      constructor (customerNumber:string, fromDate = startOfTime, toDate = new Date()) {
          this.customer_number = customerNumber;
          this.fromDate = fromDate.toJSON();
          this.toDate = toDate.toJSON();
      }
    }

    interface outParams {
      action_type: number;
      amount: number;
      created_at: string;
      hakafa_customer_id: number;
      id:number;
      sale_id: number;
      transaction_time: string;
      updated_at: string;
      invoice_number: string;
    }

    var _actionType = {
      0: "יתרת פתיחה",
      7: "חשבונית מס",
      8: "חשבונית מס זיכוי",
      1: "חשבונית מס קבלה",
      3: "יתרת פתיחה",
      2: "חשבונית מס קבלה זכות",
      5: "קבלה",
      9: "תעודת משלוח",
      10: "תעודת החזרה"
    }

    class HakafaReportHtmlRow {
      actioType:string;
      actionDt: string;
      amount: string;
      invoiceNumber:string = "";
      bal: string;

      constructor(amount,actionTypeId?:number, actionDt?, invoiceNumber?:string, bal?: string){
        //moment( s, 'DD/MM/YYYY')
        if (actionTypeId) {
          this.actioType = _actionType[actionTypeId];
          this.invoiceNumber = invoiceNumber;
        } else {
          this.actioType =  "";
          this.invoiceNumber = _actionType[0];
        }
        this.actionDt = moment(actionDt).format('DD/MM/YYYY');
        this.amount = amount;
        this.bal = bal;
      }
    }

    /*
    a = PositiveTS.Service.Hakafa.getReport("26", undefined, undefined)
    */
    export function getReport(customerNumber:string, fromDate = startOfTime, toDate = new Date()): Promise<{status:number,msg:any[]}>{
        return FetchReq.jsonReq(`${url}` , 'post',new inParams(customerNumber, fromDate, toDate))
        .then(response => {
          if (!response.response.ok) { return { status: 1, msg: i18next.t("hakafa.SERVER_INTERNAL_ERROR") } }
          if (response.result.status === 1) { return response.result; }
          response.result.startDate = fromDate;
          response.result.endDate = toDate;
          response.result.msg = _formatAray(response.result, fromDate);
          return response.result;
        });
      }

    function _formatAray(result, fromDate){
      var _ret = [];
      _ret.push( new HakafaReportHtmlRow( result.msg[0].amount, 0, fromDate,undefined, result.msg[0].bal ))
      for (var i=1; i < result.msg.length;i++){
        _ret.push(new HakafaReportHtmlRow(result.msg[i].amount,
                                  result.msg[i].action_type,
                                  new Date(result.msg[i].transaction_time ),
                                  result.msg[i].invoice_number,
                                  result.msg[i].bal
                                ))
      }
      return _ret;
    }


}}}
