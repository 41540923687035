module PositiveTS {
  export module Service {
    export class CustomerClub {
      public isEnabled: boolean
      public saleToPointsRatio: number
      private POS_CUSTOMER_BTN_SELECTOR = "#pos-sale-actions-customer-club"
      protected FACTOR_PCT_THRESHOLD_TO_SUBSTRUCT //todo: this should be per instance
      protected FACTOR_TO_SUBSTRUCT //todo: this should be per instance
      protected GAINABLE_PAYMENT_METHODS: Array<number>

      protected static clubVoucherTypeId = "167";
      static CUSTOMER_DISCOUNT_ID = '9999999';
      public static CUSTOMER_TEXT_INPUT_LEGTH = 255;
      static get CUSTOMER_DISCOUNT_NAME() {
        return i18next.t("customers.customerDiscount");
      } 
      public CURRENT_METHOD_TYPE = PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT;

      public static ACTION_TYPES = {
        findCustomer: "findCustomer",
        updateCustomer: "updateCustomer"
      }
      public static CUSTOMER_CLUBS = {
        "golf": {
          code: "golf",
          description: "7 Card Fasion",
          interfaceUrl: {
            findCustomer: "/golfinterface", updateCustomer: "/golfinterface"
          }
        },
        "valu": {
          code: "valu",
          get description() {
            return i18next.t("customers.internalClub");
          },
          interfaceUrl: {
            findCustomer: "/customer_interface", updateCustomer: "/customer_interface"
          }
        },
        "positive": {
          code: "positive",
          get description() {
            return i18next.t("customers.internalClub")
          },
          interfaceUrl: {
            findCustomer: "/positive_customer_club/index", updateCustomer: "/positive_customer_club"
          }
        },
        "hakafa": {
          code: "hakafa",
          get description() {
            return i18next.t("customers.creditClub")
          }
        },
        "valuecard": {
          code: "valuecard",
          description: "Value Card"
        },   
        "simplyclub": {
          code: "simplyclub",
          get description() {
            return i18next.t("customers.simplyClub")
          }
        },
        "bril": {
          code: "bril",
          description: "Super Friends"
        },
        "dts": {
          code: "dts",
          get description() { return i18next.t('dtsNoledg') }   
        },
        "multipassPolice": {
          code: "multipassPolice",
          get description() { return i18next.t("multipassPolice.name") }
        },
        "priority": {
          code: "priority",
          get description() { return i18next.t("priorityCustomerClub.title") }
        },    
      }


      public static get CustomerClubVoucherType() {
        return CustomerClub.clubVoucherTypeId;
      }


      public static get supportedClubs() {
        let enabled = CustomerClub.getClubEnabledAndSupported()
        console.log(enabled)
        let result = []
        for (let clubKey in CustomerClub.CUSTOMER_CLUBS) {
          if (enabled.indexOf(clubKey) > -1) {
            result.push(CustomerClub.CUSTOMER_CLUBS[clubKey])
          }
        }
        return result
      }

      public static hasCutomerClubOnSale (sale){
        let result = false;

        if (sale && sale.jsondata) {
          let json = JSON.parse(sale.jsondata);
          if (json){
            if (!_.isEmpty(json.customer) && json.customer.clubName.toLowerCase() != 'hakafa'){
              result = true;
            }
          }
        }
        return result;
      }

      constructor(private sale:Storage.Entity.Sale, private salePayments:Array<Storage.Entity.SalePayment>,
        private saleItems:Array<Storage.Entity.SaleItem>) {


        this.isEnabled = false
        this.saleToPointsRatio = 0

        this.FACTOR_PCT_THRESHOLD_TO_SUBSTRUCT = jsonConfig.getVal(jsonConfig.KEYS.customerFactorPctThresholdToSubstruct);
        this.FACTOR_TO_SUBSTRUCT = jsonConfig.getVal(jsonConfig.KEYS.customerFactorToSubstruct);


        this.GAINABLE_PAYMENT_METHODS = [
          PositiveTS.Storage.Entity.SalePayment.METHOD_CASH,
          PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE,
          PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK,
          PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT
        ]

        if (session.pos.companyAllowGainCustomerClubPointsPaymentVouchers){
          this.GAINABLE_PAYMENT_METHODS.push(...[PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER, 
            PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER])
        }

        this.isEnabled = (CustomerClub.getClubEnabledAndSupported().length > 0);
        this.setSaleToPointsRatio();
      }

      public getUsedPoints() {
        for (let i = 0; i < this.salePayments.length; i++) {
          var payment = this.salePayments[i];
          if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
            var paymentsArray = JSON.parse(this.salePayments[i].data);
            for (let i1 = 0; i1 < paymentsArray.length; i1++) {
              if (paymentsArray[i1].voucher_type_id == CustomerClub.clubVoucherTypeId) {
                let intPoints = Math.abs(Number(paymentsArray[i1].points));
                if (parseFloat(paymentsArray[i1].amount) < 0) {
                  intPoints = intPoints * -1;
                }

                return intPoints;
              }
            }
          }
        }
        return 0;
      };

      public getExternalClubDialogName() {
        if (this.isCurrentCustomerExists()) {
          return this.getCurrentSelectedCustomer().clubName.toLowerCase()
        }
      }

      public getClubMemberIdFromString(str) { //TODO: golf specific method
        var _return = {
          id: "",
          cardClub: "",
          CardExpMmyyyy: ""
        };
        if (str.length === 37) {
          _return.cardClub = str.substring(12, 15);
          _return.id = str.substring(15, 31);
          _return.CardExpMmyyyy = str.substring(31, 37);
        } else {
          _return.id = str;
        }
        return _return;
      };

      /*
       * Should be called on
       *    every new customer search
       *    clear sale click
       */
      public clearCustomer() {
        if (this.isCurrentCustomerExists()) {
          return this.setCurrentSelectedCustomer({})
        } else {
          return Promise.resolve();
        }
      };

      public copyClientDataFromSale(cloneFromSaleHndlr) {
        if (this.isCurrentCustomerExists(cloneFromSaleHndlr)) {
          var clientDictToCopyFrom = this.getCurrentSelectedCustomer(cloneFromSaleHndlr);
          clientDictToCopyFrom.cust_points = parseFloat(clientDictToCopyFrom.cust_points) +
            Number(clientDictToCopyFrom.points_accumulated_in_sale) -
            Number(clientDictToCopyFrom.points_used_in_sale);

          return this.setCurrentSelectedCustomer(clientDictToCopyFrom, undefined, false);
        }
        else {
          return Promise.resolve();
        }
      }

      public postUpdateCustomerSale(isCreditSale?, originalSaleItemsAndPayments?) {
        if (!this.isCurrentCustomerExists()) { 
          return Promise.resolve();
        }
        if (this.isCurrentCustomerExists(undefined, "valuecard")) {
          return Promise.resolve();
        }
        if (this.isCurrentCustomerExists(undefined, "simplyclub")) {
          return Promise.resolve();
        }
        var isCustomerHasValidConfiguration = !!CustomerClub.CUSTOMER_CLUBS[this.getCurrentSelectedCustomer().clubName];
        // Hakafa customers do not calculate Gained Points
        if (!isCustomerHasValidConfiguration) return Promise.resolve();

        var used_points = this.getUsedPoints();
        var gained_points = 0;
        let jsonDataDict = JSON.parse(this.sale.jsondata);
        let canGainPointsOnThisRenewMembershipSale = (session.pos.gainUponOnRenewMemberSale && CustomerClubSpecialItem.saleHasClubMemberRenewItem(this.saleItems));
        if(!jsonDataDict.customer.is_delivery_customer_only){
          if (isCreditSale) {
            gained_points = this.getGainedPoints(originalSaleItemsAndPayments);
          } else if (jsonDataDict.customer.is_valid || canGainPointsOnThisRenewMembershipSale) {
            // Add only if Membership is VALID OR ===> (if the club setting allows to gain points on a renew sale AND if it's not ZolStock)
            // Removed:  && !posUtils.isZolStockTenant()) because zolstock wants to gain points on renew sale
            gained_points = this.getGainedPoints();
          }
        }

        jsonDataDict.customer.points_accumulated_in_sale = parseFloat(gained_points.toFixed(2));
        jsonDataDict.customer.points_used_in_sale = parseFloat(used_points.toFixed(2));

        this.sale.jsondata = JSON.stringify(jsonDataDict);

      }

      private postToCustomerClubServer(stringParameter, clubName) {
        var action = JSON.parse(stringParameter).action;
        // interface url should be based on action
        var interfaceUrl = CustomerClub.CUSTOMER_CLUBS[clubName].interfaceUrl[action];
        return PositiveTS.Service.Ajax.promiseJqAjax(interfaceUrl, { data: stringParameter }, "POST");
      }

      private static getClubEnabledAndSupported() {
        var allMemberOf = jsonConfig.getVal(jsonConfig.KEYS.memberOfClubs);
        //change dev tenantID
        if (session.company.tenantID == '9' && !allMemberOf.includes('bril')){
          allMemberOf.push('bril')
        }
        var allSupported = Object.keys(this.CUSTOMER_CLUBS);
        return _.intersection(allMemberOf, allSupported);
      }

      public static async renewMemberClub(customer) {
      	try {
          let tenant= session.pos.tenantName;
          if (moment(new Date(customer.valid_until)).diff(moment(new Date()),'days') < 0) {
            let askForMemberClubRenew = session.pos.askForMemberClubRenew;
            let skipRenewItem = false;
            if (askForMemberClubRenew) {
              let continueAddingItem = await app.promiseShowAlert({
                header: i18next.t('positiveCustClub.renew.header'),
                hideCancelButton: false,
                content: i18next.t('positiveCustClub.renew.isCustomerAgreed')
              })
              if (continueAddingItem == "cancel") skipRenewItem = true;
            }
            if (!skipRenewItem) {
              await Service.CustomerClubSpecialItem.addSpecialItemIfThereIs(Service.CustomerClubSpecialItem.ClubMemberRenewHndlr)
            }
          }
          else if ((customer.total_purchases == null || customer.total_purchases == 0) && (tenant == 'lametayel' || tenant == 'staging')) {
            await Service.CustomerClubSpecialItem.addSpecialItemIfThereIs();
          }
      	} catch (err) {
      		console.error(err);
      	}
      }
      public static async putCustomerInSale(customer) {
        try{
          let result =  await (new PositiveTS.Storage.Entity.CustomerGroup()).all()
          if (customer.customer_group_id) {
            customer.customer_group_name = result.filter( row=>{return row.code == customer.customer_group_id})[0].name;
          }
          let customerClubService = new Service.CustomerClub(posVC.sale,posVC.salePayments,posVC.saleItems)
          await customerClubService.setCurrentSelectedCustomer(Service.CustomerClub.convertToPosStructure(customer))
          
          customer.date_of_birth = moment(customer.date_of_birth).toDate()
          await posVC.loadSale();
          return customer;
        }catch(err){
          console.error(err);
        }
      }
      public getCurrentSelectedCustomer(optionalSaleHndlr?) {
        var hndlr = this.sale;
        if (optionalSaleHndlr) {
          hndlr = optionalSaleHndlr;
        }

        var _return;
        if (hndlr && hndlr.jsondata) {
          var _json = JSON.parse(hndlr.jsondata);
          if (_json.customer) {
            _return = _json.customer;
          } else if (_json[PositiveTS.Service.ValueCardService.jsonDataKey]) {
            _return = _json[PositiveTS.Service.ValueCardService.jsonDataKey];
          } else if (_json[PositiveTS.Service.SimplyClubService.jsonCustomerDataKey]) {
            _return = _json[PositiveTS.Service.SimplyClubService.jsonCustomerDataKey];
          }
        }
        return _return;
      }

      private removeCustomerDiscount() { //TODO: add support for this for new promotions
        if (this.sale.discountID == CustomerClub.CUSTOMER_DISCOUNT_ID) {
          this.sale.discountID = '-1';
          this.sale.discountName = '';
          this.sale.saleDiscountAmount = 0;
          this.sale.discountPercent = '-1';
          this.sale.discountApprovedByEmployeeID = '-1';
        }
      }

      public static createCustomerDiscount() {
        let discountObj = {
          discountID:CustomerClub.CUSTOMER_DISCOUNT_ID,
          allowDuplicatePromotion: true,
          name: CustomerClub.CUSTOMER_DISCOUNT_NAME,
          approvedPrecent: 100,
          managerPrecent: 100,
          typeID: 2,
          isClubMemberOnly: true,
          isActive: true,
          minimumAmount: "0",
          fromDate: "",
          toDate: ""
        }
        let discount = (new Storage.Entity.Discount()).importFromObject(discountObj);
        return discount;
      }

      public static getShortDeliveryCustomerAddress(customer):string{
        let ret = ""
        if (customer && Array.isArray(customer.customer_addresses)){
          let addr = customer.customer_addresses.find((cs) => cs.is_default)
          if (!addr){
            addr = customer.customer_addresses[0]
          }

          if (addr){
            if(addr.city && addr.city.name){
              ret = `${addr.city.name} ${addr.address.name} ${addr.house_number}`
            }else{
              ret = `${addr.city} ${addr.address} ${addr.house_number}`
            }
          }
        }
        
        return ret
      }

      private async applyCustomerDiscount(customer) {
        if (this.sale && customer && customer.discount_percent) {
          if (session.pos.useNewPromotions) {
            let discount = CustomerClub.createCustomerDiscount();
            await posDiscountVC.addDiscountNewStructure(null,discount,customer.discount_percent,[this.sale],1,
              customer.discount_percent,this.sale)
          }
          else {
            this.sale.saleDiscountAllowedWithOtherPromotions = true
            this.sale.discountID = CustomerClub.CUSTOMER_DISCOUNT_ID;
            this.sale.discountName = CustomerClub.CUSTOMER_DISCOUNT_NAME;
            this.sale.discountPercent = customer.discount_percent;
            this.sale.discountType = '1';
            this.sale.promotionCode = '';
          }
        }
      }

      public async setCurrentSelectedCustomer(val, cardScanDict?, applyDiscount = true) {
        let jsonDataDict: any = {};
        if (val && val.discount_percent && this.sale.invoiceType != 2 && applyDiscount) {
          await this.applyCustomerDiscount(val)
        }
        else {
          this.removeCustomerDiscount()
        }
        if (this.sale.jsondata) {
          jsonDataDict = JSON.parse(this.sale.jsondata);
        }
        jsonDataDict.customer = val;

        if (cardScanDict) {
          jsonDataDict.customer.cardScan = cardScanDict;
        }
        this.sale.jsondata = JSON.stringify(jsonDataDict);

        if(val.is_eilat_customer && session.store.containVat == false){
          localStorage.setItem("eilatCustomerInSaleInNonEilatStore", "true");
          session.store.containVat = true
        }

        if(val.is_not_eilat_customer && session.store.containVat == true){
          localStorage.setItem("notEilatCustomerInSaleInEilatStore", "true");
          session.store.containVat = false
        }

        await PositiveTS.Service.FullSale.persist(this.sale,this.saleItems,this.salePayments);
        return;
      }

      public isHideCustomerDeleteIcon(optionalSaleHndlr?) {
        return this.isCurrentCustomerExists(optionalSaleHndlr);
      }

      public isCurrentCustomerExists(optionalSaleHndlr?,clubName?:string) {
        let hndlr = this.sale;
        if (optionalSaleHndlr) {
          hndlr = optionalSaleHndlr;
        }

        var c = this.getCurrentSelectedCustomer(hndlr);
        if (c && Object.keys(c).length > 0) {
          if (clubName) {
            return c.clubName == clubName;
          }
          else {
            return true;
          }
        } else {
          return false;
        }
      }

      public shouldOpenCustomerPointsDialog() {
        var c = this.getCurrentSelectedCustomer();
        let customerPointsService = new CustomerPoints();
        return  this.isCurrentCustomerExists() && 
                c.cust_points_for_use && c.cust_points_for_use > 0 && 
                customerPointsService.getMaxAllowedAmounts().points > 0 &&
                (!this.forbidUsingPointsOnUnaccumulableItems || posVC.saleItems.some(si => !si.avoidFromAccumulatingClubPoints));
      }

      public getCustomerShortDisplayName(optionalSaleHndlr?) {
        var customerObj = this.getCurrentSelectedCustomer(optionalSaleHndlr);
        
        if (customerObj.clubName === "valuecard") {
          let pointsToShow = customerObj.prepaidBalance;
          if(customerObj.prepaidBalance == 0){
            pointsToShow = customerObj.totalPoints
          }
          return `Value Card: ${customerObj.memberFullName.split(" ")[0]} (${pointsToShow} ${i18next.t('valueCardCustClub.budgetOrPoints')})`
        }
        if (customerObj.clubName === PositiveTS.Service.SimplyClubService.clubName) {
          let c: PositiveTS.Service.SimplyClubAPI.MemberDetails = customerObj;
          return `${c.FirstName} ${c.LastName}`
        }
      
        if (customerObj.s_first_name) {
          return `${customerObj.s_first_name} ${customerObj.s_last_name == null ? "" : customerObj.s_last_name}`;
        }

        if ( customerObj.s_id_number ){
          return customerObj.s_id_number.toString();
        } 

        if (!posUtils.isBlank(customerObj.s_phone_number_1)) {
          return customerObj.s_phone_number_1;
        }

        return '';
      }

      public static convertToPosStructure(customer){

        let  convertedCustomerObj = {}
        
        convertedCustomerObj["s_id_number"] = customer.customer_tz
        convertedCustomerObj["s_member_no"] = customer.customer_number
        convertedCustomerObj["customer_group_id"] = customer.customer_group_id
        convertedCustomerObj["customer_group_name"] = customer.customer_group_name
        convertedCustomerObj["s_first_name"] = customer.first_name
        convertedCustomerObj["s_last_name"] = customer.last_name
        convertedCustomerObj["email"] = customer.email
        convertedCustomerObj["msg_for_screen"] = "" //TODO
        convertedCustomerObj["msg_for_slip"] = "" //TODO
        convertedCustomerObj["cust_points"] = customer.current_points
        convertedCustomerObj["is_valid"] = (moment(new Date(customer.valid_until)).diff(moment(new Date()),'days') >= 0)

        let pointsForUse = customer.current_points < jsonConfig.getVal(jsonConfig.KEYS.customerClubMinPointsToUse) ? 0 : customer.current_points //TODO - parameter at club level
        let multiplyPointsVal = jsonConfig.getVal(jsonConfig.KEYS.pointsForUseInMultiplication)
        if (pointsForUse > 0 && multiplyPointsVal != null && multiplyPointsVal>0) {
          pointsForUse = pointsForUse-pointsForUse%multiplyPointsVal;
        }
        convertedCustomerObj["cust_points_for_use"] = pointsForUse
        convertedCustomerObj["i_point_value"] = 1 //TODO - parameter at club level
        convertedCustomerObj["i_club_id"] = customer.customer_club_id
        convertedCustomerObj["dt_birth_date"] = customer.date_of_birth
        convertedCustomerObj["address"] = `${customer.street_address || ""} ${customer.apartment_number || ""} ${customer.city || ""}`
        convertedCustomerObj["s_phone_number_1"] = customer.phone_number
        convertedCustomerObj["db_id"] = customer.id
        convertedCustomerObj["zip_code"] = customer.zip_code
        convertedCustomerObj["val_per_points"] = convertedCustomerObj["i_point_value"]*convertedCustomerObj["cust_points_for_use"]
        convertedCustomerObj["clubName"] = 'positive'
        convertedCustomerObj["discount_percent"] = customer.discount_percent

        //TODO: get promotion types from server. maybe use a serializer?
        convertedCustomerObj["promotion_types"] = customer.promotion_types
        //convertedCustomerObj["promotion_types"] = CustomerClub.get_promotion_types(customer)

        convertedCustomerObj["block_from_mailing_list"] = customer.block_from_mailing_list
        convertedCustomerObj["comments"] = customer.comments

        convertedCustomerObj["total_purchases"] = customer.total_purchases;
        
        return convertedCustomerObj;

      }

      public get accumulatableItemsPriceInSale(): number {
        let price = 0;
        let codes = Service.CustomerClubSpecialItem.getSpecialItemCodes();
        let accumulatableSaleItems = this.saleItems.filter(i => !i.avoidFromAccumulatingClubPoints && codes.every(c => c != i.itemCode));
        if (!session.pos.useNewPromotions) {
          accumulatableSaleItems = PositiveTS.Storage.Entity.SaleItem.setPriceNetoAfterDiscountsForOldPromotionsItems(this.sale, accumulatableSaleItems);
        }
        accumulatableSaleItems.forEach(i => price += i.priceNetoAfterDiscounts);
        return price;
      }

      public get unAccumulatableItemsPriceInSale(): number {
        let price = 0;
        let codes = Service.CustomerClubSpecialItem.getSpecialItemCodes();
        let unAccumulatableSaleItems = this.saleItems.filter(i => i.avoidFromAccumulatingClubPoints && codes.every(c => c != i.itemCode));
        if (!session.pos.useNewPromotions) {
          unAccumulatableSaleItems = PositiveTS.Storage.Entity.SaleItem.setPriceNetoAfterDiscountsForOldPromotionsItems(this.sale, unAccumulatableSaleItems);
        }
        unAccumulatableSaleItems = unAccumulatableSaleItems.filter(i => i.priceNetoAfterDiscounts > 0);
        unAccumulatableSaleItems.forEach(i => price += i.priceNetoAfterDiscounts);
        return price;
      }

      private get forbidUsingPointsOnUnaccumulableItems(): boolean {
        return jsonConfig.getVal(jsonConfig.KEYS.forbidUsingPointsOnUnaccumulableItems);
      } 

      private setSaleToPointsRatio() {
        if (this.isEnabled) {
          this.saleToPointsRatio = jsonConfig.getVal(jsonConfig.KEYS.customerUsePointsRatio);
        }
      }

      private getGainFactor(totalAmount, originalSaleItemsAndPayments, gainableAmount, usedPoints) {


        var gainFactor = 1;
        if (originalSaleItemsAndPayments) { //credit invoice - take the gain factor of the parent
          try {
            var jd = JSON.parse(originalSaleItemsAndPayments.jsondata);
            var accumPct = 0.1;
            var gainPointRatio = jsonConfig.getVal(jsonConfig.KEYS.gainPointsFactor);
            if (jd.customer) {
              accumPct = Math.round(jd.customer.points_accumulated_in_sale / originalSaleItemsAndPayments.totalAmount * 100) / 100;
              gainFactor = accumPct / gainPointRatio;
            }

          }
          catch (err) {
            console.error(err)
          }
        }
        else {
          return this.getGainFactorInternal(totalAmount, gainableAmount, usedPoints)
        }
        console.debug('gain factor is: ' + gainFactor);
        return gainFactor;
      }

      private getGainFactorInternal(totalAmount, gainableAmount, usedPoints) {
        let totalWithoutDiscounts = 0;
        let gainFactor = 1;
        let discountPercentForSale: number;
        if (!jsonConfig.getVal(jsonConfig.KEYS.dontGivePointsOnItemsWithPromotionOrDiscount)) {
          this.saleItems.forEach((item) => {
            totalWithoutDiscounts += item.unitPrice * item.quantity;
          });
          discountPercentForSale = Math.round((1 - totalAmount / totalWithoutDiscounts) * 100) / 100;

          if (this.FACTOR_PCT_THRESHOLD_TO_SUBSTRUCT && discountPercentForSale >= this.FACTOR_PCT_THRESHOLD_TO_SUBSTRUCT) {
            if (this.FACTOR_TO_SUBSTRUCT) {
              gainFactor = this.FACTOR_TO_SUBSTRUCT;
            }
          }
        }
        else {
          let sumToGain = 0;
          if (Helper.SaleHelper.doesSaleHasDiscount(this.sale) || gainableAmount == 0) {
            console.debug('gain factor is: 0')
            return 0;
          }

          if (this.sale.useNewPromotions) {
            const promotionCodesAllowAccumulationPoints = []
            session.allPromotions.forEach(promotion => {
              if (promotion.allowAccumulationPointsAnyway === true){
                promotionCodesAllowAccumulationPoints.push(promotion.code)
              }
            })
            
            this.saleItems.forEach(saleItem => {
              if (posUtils.isBlank(saleItem.promotions)){
                sumToGain += saleItem.priceNetoAfterDiscounts
              }else{
                if (promotionCodesAllowAccumulationPoints.length > 0){
                  let saleItemHasOnlyPromotionsAllowAccumulationPoints = false
                  let saleItemPromotionsArray = saleItem.promotions.split(';')

                  for (let ps of saleItemPromotionsArray){
                    if (ps.length > 0){
                      let promotionCode: string = ps.split('=')[0]
                    
                      if (promotionCodesAllowAccumulationPoints.includes(promotionCode)){
                        saleItemHasOnlyPromotionsAllowAccumulationPoints = true
                      }else{
                        saleItemHasOnlyPromotionsAllowAccumulationPoints = false
                        break
                      }
                    }
                  }

                  if (saleItemHasOnlyPromotionsAllowAccumulationPoints){
                    sumToGain += saleItem.priceNetoAfterDiscounts
                  }
                }
              }
            })
          }
          else {
            let result = salePromotions.totalOfItemsWithoutPromotionsOrDiscountsAndDiscountAllowedNotIncludingSalePromotion(this.sale,this.saleItems);
            if (Helper.SaleHelper.doesSaleHasPromotion(this.sale)) {
              let saleItemsWithPromotion = result.items.filter((saleItem) => {
                let item = saleItem.item;
                return (item.promoBuy != null && (item.promoBuy.split("&").indexOf(this.sale.promotionCode) > -1));
              });
              let barcodesInSalePromotion = saleItemsWithPromotion.map(item => item.barcode);
              let finalItems = result.items.filter(item => barcodesInSalePromotion.indexOf(item.barcode) > -1)
              for (let item of finalItems) {
                sumToGain += item.unitPrice * item.quantity
              }
            }
            else {
              sumToGain = result.total;
            }
          }
          sumToGain = Math.max(0,sumToGain-usedPoints);

          gainFactor = (sumToGain/totalAmount);
        }
        console.debug('gain factor is: ' + gainFactor);
        return gainFactor;
      }

      private getGainedPoints(originalSaleItemsAndPayments?) {
        var gainPointRatio = jsonConfig.getVal(jsonConfig.KEYS.gainPointsFactor);
        var gainableAmount = 0;
        var noneGainableAmount = 0;
        var allAmount = 0; // gainableAmount + noneGainableAmount
        var gainablePct = 0.0 // gainableAmount / noneGainableAmount
        var usedPoints = this.getUsedPoints();
        // Add valida paymnet type
        for (const payment of this.salePayments) {
          if (this.GAINABLE_PAYMENT_METHODS.some(method => method === payment.method)) {
            if (payment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE) {
              gainableAmount -= payment.amount;
            } else {
              gainableAmount += payment.amount;
            }
          } else {
            if (payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
              noneGainableAmount += payment.amount;
            }
          }
        }
        allAmount = gainableAmount + noneGainableAmount;

        if (allAmount === 0 || (!jsonConfig.getVal(jsonConfig.KEYS.fullClub))) {
          return 0;
        } else {
          gainablePct = gainableAmount / allAmount;
          gainableAmount -= gainablePct * Service.CustomerClubSpecialItem.getSpecialItemPriceInSale(this.saleItems);
          if (this.forbidUsingPointsOnUnaccumulableItems) {
            gainableAmount -= gainablePct * this.unAccumulatableItemsPriceInSale;
          } else {
            gainableAmount = Math.min(gainableAmount, this.accumulatableItemsPriceInSale);
          }
          let overAllGainedPoints = (gainPointRatio * gainableAmount * this.getGainFactor(allAmount, originalSaleItemsAndPayments, gainableAmount, usedPoints));
          return overAllGainedPoints
        }
      }

    }
  }
}
