module PositiveTS {
    export module Components {
    export module CaveretPaymentSaleDialog {
    
      const _dialogSelector = "#positive-caveret-payment-sale-dialog";
      const _vueComponentName = 'caveret-payment-sale-dialog';
    
      export function create() {
        let caveretPaymentSaleDialog = {
            template: JST.caveretPaymentSaleDialog(),
            methods: {
              open: open,
              close: close,
              cancel() {
                this.resolveFunc(false);
                close();
              },
              confirm() {
                this.resolveFunc(true);
                close();
              }
            },
            data: _initData,
        }
        VueApp.component(_vueComponentName,caveretPaymentSaleDialog)
      }
    
      function open(){
        return new Promise((resolve,reject) => {
          this.qty = posVC.getTotalQuantity();
          this.totalAmount = posVC.getTotalAmount();
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
          $(function() {
            $(_dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: 600,
              height: 350,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          $(_dialogSelector).dialog('open');
          });
        });
      }
    
      function _initData(){
        return {
          rejectFunc: null,
          resolveFunc: null,
          totalAmount: 0,
          qty: 0
        };
      }
    
      function close() {
        $(_dialogSelector).dialog('close');
      }
    }}}