module PositiveTS {
    export module Application {
    export module Controllers {
    export class ExceptionsReportViewController extends AbstractViewControllerTS {
        chooseDateStart = new Date()
        chooseDateEnd = new Date()
           
       // --------------------------------------------------------------------
     // Controller Life-Cycle
     // --------------------------------------------------------------------
     init(options) {
   
   
       $('#exceptions-report-from_date').datepicker({
           dateFormat: 'dd/mm/yy',
           changeMonth: true,
           changeYear: true,
   
           onClose: function( selectedDate ) {
               $( "#exceptions-report-from_date" ).datepicker( "option", "minDate", selectedDate );
               exceptionsReportVC.chooseDateStart = $( '#exceptions-report-from_date' ).datepicker( "getDate" );
   
               exceptionsReportVC.chooseDateStart.setHours(0,0,0,0);
   
           }            
       });                
       
       $( "#exceptions-report-buttons").buttonset();
       
       $('#exceptions-report-to_date').datepicker({
           dateFormat: 'dd/mm/yy',
           changeMonth: true,
           changeYear: true,
           onClose: function( selectedDate ) {
               $( "#exceptions-report-to_date" ).datepicker( "option", "minDate", selectedDate );
               exceptionsReportVC.chooseDateEnd = $( '#exceptions-report-to_date' ).datepicker( "getDate" );
           }            
       });    
   
       $('#exceptions-report-to_date').autoselected();            
       $('#exceptions-report-from_date').autoselected();                    
       
   
       var aThis = exceptionsReportVC;
       $('#exceptions-report-month-container').positiveRadioSelect({
   
           changed: aThis.changeDate
       });
   
       exceptionsReportVC.chooseDateStart.setDate(1);
       $('#exceptions-report-from_date').datepicker( "setDate", exceptionsReportVC.chooseDateStart);
       $('#exceptions-report-to_date').datepicker( "setDate", exceptionsReportVC.chooseDateEnd);
     }

     changeDate(val){
   
       exceptionsReportVC.chooseDateStart = new Date()      
       exceptionsReportVC.chooseDateEnd = new Date()
   
   
       if( val == 1) {
       //sets the report to this day
           
           exceptionsReportVC.chooseDateStart.setHours(0,0,0,0);
           exceptionsReportVC.chooseDateEnd.setHours(23,59,59,999);
       }
       else if( val == 2){
   
           //sets the report to currnet month
   
           exceptionsReportVC.chooseDateStart.setHours(0,0,0,0);
           exceptionsReportVC.chooseDateStart.setDate(1);
           exceptionsReportVC.chooseDateEnd.setHours(23,59,59,999);        
           exceptionsReportVC.chooseDateEnd.setMonth(exceptionsReportVC.chooseDateEnd.getMonth()+1);
           exceptionsReportVC.chooseDateEnd.setDate(0);
           
       } 
       else if( val == 3){
   
           //sets the report to past month
   
           exceptionsReportVC.chooseDateStart.setHours(0,0,0,0);
           exceptionsReportVC.chooseDateStart.setDate(1);
           exceptionsReportVC.chooseDateStart.setMonth(exceptionsReportVC.chooseDateEnd.getMonth()-1);
   
           exceptionsReportVC.chooseDateEnd.setHours(23,59,59,999);        
           exceptionsReportVC.chooseDateEnd.setDate(0);
       }     
           
           $('#exceptions-report-from_date').datepicker( "setDate", exceptionsReportVC.chooseDateStart);
           $('#exceptions-report-to_date').datepicker( "setDate", exceptionsReportVC.chooseDateEnd);                
   
     }
     resume(options) {   
   
       $('#exceptions-report-continue').click(exceptionsReportVC.continueClicked);
       $('#exceptions-report-items_return').attr('checked','checked');
       $('#exceptions-report-manual_discount').attr('checked','checked');
       $( "#exceptions-report-buttons").buttonset( "refresh" );
     }
     stop() {
       // Unbind the listeners from the resume function
       $('#exceptions-report-continue').unbind('click');
     }
     destroy() {
     }
     // --------------------------------------------------------------------
       // View Related Functions
       // --------------------------------------------------------------------
       
       displayErrorMessage(errorMessage) {
           app.showAlert({
               header: i18next.t('error'),
               content: errorMessage,
               continueButtonText: i18next.t("ok"),
               hideCancelButton: true
           }, function () {
           }, null);
       }
       continueClicked() {
   
           
           let itemsReturn    =  $('#exceptions-report-items_return').is(":checked");
           let manualDiscount = $('#exceptions-report-manual_discount').is(":checked");
           let priceChange = $('#exceptions-report-price-change').is(":checked");
   
           let fromDate   = exceptionsReportVC.chooseDateStart;
           let toDate     = exceptionsReportVC.chooseDateEnd;
   
           exceptionsReportVC.chooseDateEnd.setHours(23,59,59,999);
       
           if ((fromDate == null)||(toDate == null)){
               
               exceptionsReportVC.displayErrorMessage(i18next.t('exceptionsReportDialog.noDateSelected'));
               return;
           }        
           if ( (itemsReturn == false ) && (manualDiscount == false ) && (priceChange == false) ){
   
               exceptionsReportVC.displayErrorMessage(i18next.t('exceptionsReportDialog.noExceptionSelected'));
               return
           }
           
           app.showLoadingMessage(i18next.t('generatingReportWait'));        
           // Generate report for the supplied parameters
           exceptionsReportVC.generateReport(fromDate, toDate, itemsReturn, manualDiscount, priceChange );
       }
       closeScreen() {
           pNavigator.back();
       }
       // --------------------------------------------------------------------
       // Logic Related Functions
       // --------------------------------------------------------------------
       generateReport( fromDate: any, toDate: any, itemsReturn: boolean, manualDiscount: boolean, priceChange: boolean ) {
   
           if(itemsReturn==false)
               itemsReturn = undefined;
           if(manualDiscount==false)
               manualDiscount = undefined;
           if(priceChange==false)
               priceChange = undefined;
   
           
           return Service.ExceptionsReport.getFromServer({
               from_date:     fromDate,
               to_date:       toDate,
               items_return: itemsReturn,
               manual_discount: manualDiscount,
               price_change: priceChange,
   
           })
           .then(function (htmlResponse) {
               app.hideLoadingMessage();
               let x = window.open('', '_blank', 'width=1024px,height=768px');
               x.document.open();
               x.document.write(htmlResponse);
               x.document.close();
           })
           .catch(function (error) {
               app.hideLoadingMessage();
               console.error(error);
               exceptionsReportVC.displayErrorMessage(error.responseText);
           });
       }
    }
}}}
declare var exceptionsReportVC:PositiveTS.Application.Controllers.ExceptionsReportViewController;
exceptionsReportVC = new PositiveTS.Application.Controllers.ExceptionsReportViewController;
PositiveTS.Application.Controllers.instances.ExceptionsReportViewController = exceptionsReportVC;