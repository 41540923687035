module PositiveTS {
  export module Components {
    export module PositTable {
      export function getComponent() {

        let positTable = {
          components: {
            positiveIcon: PositiveIcon.getComponent(),            
          },
          template: JST.PositTable(),
          props: {
            modelValue: {
              required: false,
              default: [],
            },
            columnDefs: { // PositiveTS.Types.PositTableHeader
              required: true
            },
            options: {
              required: false,
              /** @type{PositiveTS.Types.PositTableOptions}  */
              default: {
                class: (row) => { return '' },
              },
            },
            meta: { // PositiveTS.Types.PositTableMeta
              required: false,
              default: null,
            }
          },
          data() {
            return {
              checkboxHeaders: {},
            };
          },
          created() {
            let arr: Types.PositTableHeader[] = this.columnDefs;
            for (const header of arr) {
              if (header.allowCheckAll) {
                this.checkboxHeaders[header.field] = false;
              }
            }
          },
          computed: {
            inLastPage() {
              let meta: PositiveTS.Types.PositTableMeta = this.meta;
              return meta.current_page === meta.last_page;
            },
            inFirstPage() {
              let meta: PositiveTS.Types.PositTableMeta = this.meta;
              return meta.current_page === 1;
            },
            shouldDisplayPagination() {
              let meta: PositiveTS.Types.PositTableMeta = this.meta;
              return posUtils.isPresent(meta) && meta.last_page > 1;
            }
          },
          methods: {
            emitAction(col: Types.PositTableHeader, row: any) {
              this.$emit(col.action, row);
            },
            checkboxColSelected(col: Types.PositTableHeader, row: any) {
              this.$emit('update', this.modelValue);
                let val: boolean = row[col.field];
                if (col.allowCheckAll && !val) {
                  this.checkboxHeaders[col.field] = false;
                }
            },
            numFormat(val) {
              if (val !== 0 && !val) {
                console.warn('PositTable: the given value was not set : ', val);
                return 0;
              } if (Number.isInteger(Number(val))) {
                return val.toLocaleString();
              }
              return session.fixedNumberAndToLocalString(val);
            },
            checkAllToggleCol(col: Types.PositTableHeader) {
              const isChecked: boolean = this.checkboxHeaders[col.field];
              this.modelValue.forEach(row => { row[col.field] = isChecked });
              this.$emit('update', this.modelValue);
            },
            previousPage() {
              let meta: PositiveTS.Types.PositTableMeta = this.meta;
              if (meta.current_page === 1) {
                return;
              }
              this.$emit('previous-page');
            },
            nextPage() {
              let meta: PositiveTS.Types.PositTableMeta = this.meta;
              if (meta.current_page === meta.last_page) {
                return;
              }
              this.$emit('next-page');
            },
          },
        }
        return positTable;
      }
    }
  }
}
