module PositiveTS {
  export module Service {
    export module MishlohaService {

      export const customer_number = "לקוח משלוחה"

      export function getTotalCashOrders(sales: Array<Storage.Entity.Sale>) {
        return sales.reduce((totals, sale) => { 
          if(sale.isDelivery) {
            let saleData = JSON.parse(sale.jsondata);
            if(posUtils.isDefined(saleData.delivery.mishlohaId) && sale.payments.length > 0 && sale.payments[0].method != 3) {
              totals.amount += sale.totalAmount;
              totals.count++;
            }
          }
          return totals;
        }, {amount: 0, count: 0})
      }

      export async function fetchMishlohaDeliveryOrders(): Promise<any> {
        try {
          let token = await _getToken();
          let restId = jsonConfig.getVal(jsonConfig.KEYS.mishlohaRestId)
          let _do = `https://pos.mishloha.co.il/api/POS/GetOpenOrders/${restId}/1/${token}`
          let _data = {
            "body": "", "type": "GET",
            "url": _do,
            "headers": _headers(),
            "timeout": 120000
          };
          let data = JSON.stringify(_data)
          let response = await GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
          )
          Filelog.log("mishloha-orders", JSON.stringify({ request: response.request.body.data, result: response.request.result }))

          let result = JSON.parse(response.request.result);

          return result

        } catch (e) {
          Logger.error(e)
        }
      }

      export async function updateOrderStatus(orderId, status): Promise<any> {
        try {
          let token = await _getToken();
          let restId = jsonConfig.getVal(jsonConfig.KEYS.mishlohaRestId)
          let _do = `https://pos.mishloha.co.il/api/pos/setorderstatus?poskey=${token}`


          let _data = {
            "body": JSON.stringify({
              "restId": restId,
              "posId": "",
              "status": status,
              "data": "error reason or other data",
              "shareToken": orderId
            }),
            "type": "POST",
            "url": _do,
            "headers": _headers(),
            "timeout": 120000
          };

          let data = JSON.stringify(_data)
          let response = await GenericAddon.sendNativeMessageToExtension(
            { data: data },
            "external_service"
          )

          Filelog.log("mishloha-orders", JSON.stringify({ request: response.request.body.data, result: response.request.result }));

          var result = JSON.parse(response.request.result);

          if (result === "0") {
            return result;

          } else {
            let error = new Error(i18next.t("mishloha.generalErrorComunication"));
            Logger.error(error);
          }

        } catch (e) {
          Logger.error(e);
        }
      }

      async function _getToken(): Promise<string> {
        return session.pos.mishlohaToken
      }

      function _headers(): string {
        return JSON.stringify({
          "accept": "application/json",
        })
      }
    }
  }
}