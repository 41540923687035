module PositiveTS {
  export module Components {
      export module periodReportSettingsDialog {
  
        const dialogSelector = "period-report-settings-dialog"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            keyboardImg: `${(<any>window).images_path}/keyboard.jpg`,
            showReportsByOptions: [{name: 'פריטים', value:'items'},{name: 'מחלקות', value:'departments'}],
            selectedReportsBy: {name: 'פריטים', value:'items'},
            detailedReportOptions: [{name: 'כן', value:'true'},{name: 'לא', value:'false'}],
            selectedDetailedReport: {name: 'כן', value:'true'}
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.periodReportSettingsDialog(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              cancel() {                
                this.resolveFunc()
                this.close();
              },
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }

                  this.resolveFunc();                  
                  this.dialogElem.close();
                }
                this.cleanData()
              },
              saveSettings(){
                localStorage.setItem("reportsBy",this.selectedReportsBy.value);
                localStorage.setItem("detailedReports",this.selectedDetailedReport.value);
                this.close();
              },
              async open(mode) {
                return new Promise((resolve,reject) => {
                  
                  this.initialized = true;

                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }

                  let reportsBy = localStorage.getItem("reportsBy");
                  let detailedReports = localStorage.getItem("detailedReports");

                  if((!reportsBy) || (reportsBy == "departments")){
                    this.selectedReportsBy = {name: 'מחלקות', value:'departments'};
                  }
                  else{
                    this.selectedReportsBy = {name: 'פריטים', value:'items'};
                  }

                  if((!detailedReports) || (detailedReports == "false")){
                    this.selectedDetailedReport = {name: 'לא', value:'false'};
                  }
                  else{
                    this.selectedDetailedReport = {name: 'כן', value:'true'};
                  }

                  this.dialogElem.showModal();
                  
                  
                  
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
          }
  
          VueApp.component('period-report-settings-dialog',component)
        }
      }
    }
  }
