module PositiveTS {
  export module Components {
    export module PositiveGenericDialog {

      export function create() {
        let component = {
          template: JST.positiveGerericDialog(),
          components: {positiveIcon: PositiveIcon.getComponent()},
          props: {
            title: {
              type: String,
              default: '',
            },
            faIcon: {
              type: String,
              default: null,
            },
            positiveIcon: {
              type: String,
              default: null,
            },
            animation: {
              type: String, 
              default: null,
            },
            buttons: {
              type: Array,
              default() {
                return [{
                  text: i18next.t('ok'),
                  value: 1,
                }]
              }
            }
          },
          methods: {
            open() {
              return new Promise(async res => {
                this.resolve = res;
                this.isOpen = true;
                await this.$nextTick();
                this.$emit('afterOpen')
              })
            },
            async buttonClicked(button) {
              if (button.beforeCloseAction) {
                let shouldCloseDialog = await button.beforeCloseAction();

                if (!shouldCloseDialog) {
                  return;
                }
              }

              this.close(button.value);
            },
            close(result = null) {
              this.resolve(result);
              this.isOpen = false;
            } 
          },
          data() {
            return {
              isOpen: false,
              resolve: null,
            }
          }
        };

        VueApp.component('positive-generic-dialog',component)
      }
    }
  }
}