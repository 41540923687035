module PositiveTS {
export module Storage {
export module Entity {
export class EmployeeStore extends WasmEntity {
	static EMPLOYEE_STORE_LOCAL_STORAGE_KEY = 'storeEmployeeIds';

	public employeeID;
	public storeID;
	
	constructor() {
		let meta = {
			name: 'EmployeeStore',
			fields: {
				employeeID: "TEXT",
			  	storeID: "TEXT"
			}
		}
		super(meta)
	}

	static getEmployeeStoreIdsFromLocalStorage() {
		let storeEmployeeIds = localStorage.getItem(EmployeeStore.EMPLOYEE_STORE_LOCAL_STORAGE_KEY);

		if (!storeEmployeeIds) {
			return [];
		}

		return JSON.parse(storeEmployeeIds);
	}

	static async addEmployeeStore(employeeID, storeID) {
		let storeEmployeeIds = EmployeeStore.getEmployeeStoreIdsFromLocalStorage();
		storeEmployeeIds.push(employeeID);
		EmployeeStore.saveFromArray(storeEmployeeIds);

		return await Service.WasmDB.execAndSave(`INSERT INTO 'EmployeeStore' (id, storeID, employeeID) VALUES('${storage.createUUID()}', ${storeID}, ${employeeID})`)
	}

	async deleteEmployeeStore(employeeID, storeID) {
		let storeEmployeeIds = EmployeeStore.getEmployeeStoreIdsFromLocalStorage();
		storeEmployeeIds = storeEmployeeIds.filter(id => id != employeeID);
		EmployeeStore.saveFromArray(storeEmployeeIds);

		return await Service.WasmDB.execAndSave(`DELETE FROM ${this.meta.name} WHERE employeeID = ${employeeID} AND storeID = ${storeID}`)
	}

	static async loadFromLocalStorageIfNeeded(db) {
		if (!session.pos.parameterIsAutomaticEmployeeStoreConnection) {
			db.exec(EmployeeStore.getLoadFromLocalStorageQuery());
		}
	}

	static getLoadFromLocalStorageQuery() {
		let employeeStores = EmployeeStore.getEmployeeStoreIdsFromLocalStorage();

		let employeeStoresLinesSql =  employeeStores.map(employeeId => `INSERT INTO 'EmployeeStore' (id, storeID, employeeID) VALUES('${storage.createUUID()}', ${session.pos.storeID}, ${employeeId})`)

		return "BEGIN; DROP TABLE IF EXISTS `EmployeeStore`; " + (new EmployeeStore()).getCreateTableSql() + employeeStoresLinesSql.join(';') + "; END;"
	}

	static async saveFromArray(employeeIds) {
		localStorage.setItem(EmployeeStore.EMPLOYEE_STORE_LOCAL_STORAGE_KEY, JSON.stringify(_.uniq(employeeIds)));
	}
}}}}
