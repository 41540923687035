import { ILogger } from '..';
import { createUUID } from './Utils';

declare var Android;

export default class AndroidService {
   static asyncActionPromises = {};
   static logger: ILogger = null;

   static setLogger(loggerToSet: ILogger) {
      AndroidService.logger = loggerToSet;
   }

   static async makeActionAsync(action, ...params) {
      if (Android.runFunctionAsync == undefined) {
         throw new Error('Make async function not supported on this android version.');
      }

      let uid = createUUID();

      let temp;

      let promise = new Promise((resolve, reject) => {
         temp = { resolve, reject }
      });

      AndroidService.asyncActionPromises[uid] = { promise, ...temp };

      Android.runFunctionAsync(action, uid, JSON.stringify(params))

      let res;

      try {
         res = await AndroidService.asyncActionPromises[uid].promise;
      } finally {
         delete AndroidService.asyncActionPromises[uid];
      }

      return res;
   }

   static async makeActionAsyncIfSupported(action, ...params) {
      if (Android.runFunctionAsync == undefined) {
         return Android[action](...params);
      } else {
         return await AndroidService.makeActionAsync(action, ...params)
      }
   }


   static handleResult(uid, success, data) {
      if (AndroidService.asyncActionPromises[uid]) {
         if (success) {
            AndroidService.asyncActionPromises[uid].resolve(data);
         } else {
            AndroidService.asyncActionPromises[uid].reject(data);
         }
      } else {
         // If gets to here, its a bug that needed a fix

         let message = `Android http request with id ${uid} not found. data: ${data}`;
         
         if (AndroidService.logger) {
            AndroidService.logger.error(message);
         } else {
            console.error(message); 
         }
      }
   }
}