module PositiveTS { 
  export module Printing {

    export function ReportZByDate(paymentDictionary, voucherDictionary, allCreditCards, fromDateString, toDateString,
      totalPayment, totalVat, totalThatHasNoVat, totalOwsVat, merakezData, totalZ,primaryCategoryStats, tipsAmount,
      totalSittingDiners, sittingDinersAvg, totalDeliveriesDiners, deliveriesDinersAvg, deliveriesAvg, interfaces, salesDiscountReport) {
      var allPaymentTypesDict:any = {};
      var allVoucherTypeDict:any = {};
      return _allPaymentTypesDict()
      .then( (response) => {
        allPaymentTypesDict = response;
        return _allVoucherTypeDict();
      })
      .then( (response) => {
        allVoucherTypeDict = response;
        _print(allPaymentTypesDict, allVoucherTypeDict,
          paymentDictionary, voucherDictionary,allCreditCards,
          fromDateString, toDateString, totalPayment, totalVat, totalThatHasNoVat, totalOwsVat, merakezData, totalZ,primaryCategoryStats, tipsAmount,
          totalSittingDiners, sittingDinersAvg, totalDeliveriesDiners, deliveriesDinersAvg, deliveriesAvg, interfaces, salesDiscountReport);
      });
    }

    function _allPaymentTypesDict():Promise<any>{
      return new PositiveTS.Storage.Entity.PaymentType().all()
      .then( response =>{
        var _ret = {}
        for (var i=0; i<response.length; i ++){
          _ret[response[i].seq] = response[i].displayName;
        }
        return _ret;
      })
    }

    function _allVoucherTypeDict():Promise<any>{
      return new PositiveTS.Storage.Entity.Voucher().all()
      .then( response =>{
        var _ret = {}
        for (var i=0; i<response.length; i ++){
          _ret[response[i].typeID] = response[i].name;
        }
        return _ret;
      })
    }


    function _print(allPaymentTypesDict, allVoucherTypeDict,
      paymentDictionary, voucherDictionary, allCreditCards, fromDateString, toDateString, totalPayment, totalVat, totalThatHasNoVat,
      totalOwsVat, merakezData, totalZ,primaryCategoryStats, tipsAmount = 0,
      totalSittingDiners = 0, sittingDinersAvg = 0, totalDeliveriesDiners = 0, deliveriesDinersAvg = 0, deliveriesAvg = 0, interfaces: any = {}, salesDiscountReport: any = {}) {

      let aThis = printer;
      let zebra = printer.jzebra;

      let company = session.company;
      let store = session.store;

      aThis.printLogo();
      var company_name = company.companyName;
      var store_name = store.storeName;

	    zebra.append(aThis.allowComponents.init);
	
			zebra.append(aThis.allowComponents.centerAlignment);
	
			var companyNameArray = company.companyName.split(`$$$`);

			if((!companyNameArray.length) || (companyNameArray.length == 0)){
				aThis.printMultyLine(company_name);
			}
	
			for (var i = 0; i < companyNameArray.length; i++) {
				aThis.printMultyLine(companyNameArray[i]);
			};
	
			aThis.printLine(store_name);
	
			if (!posUtils.isNullOrUndefinedOrEmptyString(store.address)) {
				aThis.printLine( store.address );
			}
	
			if (!posUtils.isNullOrUndefinedOrEmptyString(store.phone)) {
				aThis.printTextWithPrintLineText(store.phone,
				`${i18next.t("printing.phone")}:`)
			}
	
			if (!posUtils.isNullOrUndefinedOrEmptyString(store.registrationNum)) {
				aThis.printTextWithPrintLineText(store.registrationNum,
					`${aThis.chetPeiAinMem()}:`)
			}


      aThis.printLine(" ");
      zebra.append(aThis.allowComponents.init);
      // aThis.printFloatingLine('מתאריך:', fromDateString);
      // aThis.printFloatingLine('עד תאריך:', toDateString);
      aThis.printFloatingLine('קופה:', session.pos.deviceID);
      aThis.printFloatingLine('קופאי:', session.pos.employeeName);

      zebra.append(aThis.allowComponents.init);
      aThis.printLine(" ");
      zebra.append(aThis.allowComponents.centerAlignment);
      zebra.append(aThis.allowComponents.bigFont);
      // zebra.append(aThis.allowComponents.underline);
      aThis.printLine("דוח ריכוז Z");

      zebra.append(aThis.allowComponents.init);
      aThis.printLine(` `)
      zebra.append(aThis.allowComponents.bold);
      zebra.append(aThis.allowComponents.centerAlignment);
      aThis.printLine(`דיווח למע"מ לתקופה:`)
      aThis.printLine(`${fromDateString}-${toDateString}`)
      aThis.printLine(` `)
      zebra.append(aThis.allowComponents.init);
      
      if(printer.isSunmi()){
        aThis.printFloatingLine(`עסקאות שחייבות במע"מ (כולל מע"מ)`,'')
      }else{
        aThis.printFloatingLine(`עסקאות שחייבות במע"מ )כולל מע"מ(`,'')
      }
      
      zebra.append(aThis.allowComponents.init);
      aThis.printFloatingLine(`סה"כ חשבוניות מהקופה: ${session.fixedNumber(totalOwsVat+totalVat,2)}`,'')
      aThis.printFloatingLine(`סה"כ חשבוניות מרכזות: ${session.fixedNumber(merakezData.total_ows_vat_include_vat,2)}`,'')
      aThis.printLine(` `)
      zebra.append(aThis.allowComponents.bold);
      let aInReport = totalOwsVat+totalVat+merakezData.total_ows_vat_include_vat;
      aThis.printFloatingLine(`סה"כ: ${session.fixedNumber(aInReport,2)} A`,'')
      zebra.append(aThis.allowComponents.init);
      
      aThis.printLine(` `)
      zebra.append(aThis.allowComponents.bold);
      aThis.printFloatingLine(`עסקאות שפטורות ממע"מ:`,'')
      zebra.append(aThis.allowComponents.init);
      aThis.printFloatingLine(`סה"כ חשבוניות קופה: ${session.fixedNumber(totalThatHasNoVat,2)}`,'')
      aThis.printFloatingLine(`סה"כ חשבוניות מרכזות: ${session.fixedNumber(merakezData.total_free_of_vat,2)}`,'')
      aThis.printLine(` `)
      zebra.append(aThis.allowComponents.bold);
      let bInReport = totalThatHasNoVat+merakezData.total_free_of_vat
      aThis.printFloatingLine(`סה"כ עסקאות פטורות ממע"מ: ${session.fixedNumber(bInReport,2)} B`,'')
      aThis.printLine(" ");
      aThis.printFloatingLine(`סה"כ מחזור כל העסקאות`,'')
      printer.addRTLChar();
      if(session.pos.printerType === Printing.PRINTER_TYPE.sunmi ){
        aThis.printFloatingLine(`(כולל מע"מ) : ${session.fixedNumber(aInReport+bInReport,2)} A+B`,'')
      }else{
        aThis.printFloatingLine(`)כולל מע"מ( : ${session.fixedNumber(aInReport+bInReport,2)} A+B`,'')
      }
      aThis.printFloatingLine(`סה"כ מע"מ: ${session.fixedNumber(merakezData.total_vat+totalVat,2)}`,'')

      if(jsonConfig.getVal(jsonConfig.KEYS.printCatsInRikuzZ) == true) {
        let dummyZReport = new Storage.Entity.ZReport()
        dummyZReport.primaryCategoryStats = JSON.stringify(primaryCategoryStats);
        Printing.Reports.ZXReports.printPrimaryCategoryStats(dummyZReport)
      }
      
      aThis.printLine(" ");
      aThis.printLine(" ");
      zebra.append(aThis.allowComponents.init);
      aThis.printLineForTopBox();
      printer.addRTLChar();
      aThis.printBoxCenteredLine("סה\"כ פדיון כללי לפי אמצעי תשלום");
      aThis.printLineForMiddleBox();
      zebra.append(aThis.allowComponents.init);

      var arySpecialitems = [];

      for (let key in paymentDictionary){
        if ( PositiveTS.Service.SmartVoucher.isPaymentmethodSpecialitem(key) ) {
           if(key === String(PositiveTS.Storage.Entity.SalePayment.METHOD_VALU)){
            arySpecialitems.push( {key:`${i18next.t("printing.chargableCard")}:`, val: session.fixedNumber(paymentDictionary[key]).toString() }) 
            continue
          } 
          arySpecialitems.push( {key:allPaymentTypesDict[key] + ":", val: session.fixedNumber(paymentDictionary[key]).toString() })
        } else {
          printer.addRTLChar();
          aThis.printFloatingInBoxLine(allPaymentTypesDict[key] + ":", session.fixedNumber(paymentDictionary[key]).toString());
        }
      }
      aThis.printLineForMiddleBox();
      printer.addRTLChar();
      aThis.printFloatingInBoxLine("סה\"כ:", session.fixedNumber(totalZ));


      if (Service.Tip.isHasTips() && tipsAmount && tipsAmount > 0) {
        printer.addRTLChar();
        aThis.printFloatingInBoxLine(i18next.t('zReport.tipsTotal') + ":", session.fixedNumber(tipsAmount));
    }

      aThis.printLineForMiddleBox();
      printer.addRTLChar();
      aThis.printBoxCenteredLine("פירוט לפי סוג אשראי");
      for (let key in allCreditCards){
        aThis.printFloatingInBoxLine(key + ":", session.fixedNumber(allCreditCards[key]).toString());
      }
      aThis.printLineForMiddleBox();
      printer.addRTLChar();
      aThis.printBoxCenteredLine("פירוט תווים");
      aThis.printLineForMiddleBox();

      var totalVoucher = 0;
      for (let key in voucherDictionary){
        aThis.printFloatingInBoxLine(allVoucherTypeDict[key] + ":", session.fixedNumber(voucherDictionary[key]).toString());
        totalVoucher += voucherDictionary[key];
      }

      if ( Object.keys(voucherDictionary).length > 0 ) {
        aThis.printLineForMiddleBox();
        aThis.printFloatingInBoxLine("סה\"כ תווים:", session.fixedNumber(totalVoucher).toString());
      } else {
        printer.addRTLChar();
        aThis.printBoxCenteredLine("אין תווי קניה בקופה");
      }


      if ( arySpecialitems) {
        aThis.printLineForMiddleBox();
        printer.addRTLChar();
        aThis.printBoxCenteredLine("פירוט טעינת כרטיסים");
        aThis.printLineForMiddleBox();

        for (let i=0;i< arySpecialitems.length;i++){
          let permitList = [`${i18next.t("printing.chargableCard")}:`, `${i18next.t("printing.valueCarChargableCard")}:`];
          if (permitList.includes(arySpecialitems[i].key) == false) {
            continue
          }
          aThis.printFloatingInBoxLine(arySpecialitems[i].key, arySpecialitems[i].val);
        }

      }

      if (jsonConfig.getVal(jsonConfig.KEYS.sumSalesDiscountReportOnZReport) && !_.isEmpty(salesDiscountReport)){
        PositiveTS.Printing.Reports.ZXReports.printTableSumSalesDiscountReportZReport(salesDiscountReport)
      }

      if (interfaces){
        aThis.printLine(aThis.allowComponents.newLine)
        aThis.printLineForMiddleBox()
        printer.addRTLChar()
        aThis.printBoxCenteredLine(i18next.t("printing.interfaces"))
        aThis.printLineForMiddleBox()
        aThis.printFloatingInBoxLine(`${i18next.t("posPayment.tenbis")}:`, session.fixedNumber(interfaces.tenbisTotalAmount || 0))
        aThis.printFloatingInBoxLine(`${i18next.t("posPayment.pluxee")}:`, session.fixedNumber(interfaces.cibusTotalAmount || 0))
        aThis.printFloatingInBoxLine(`${i18next.t("posPayment.goodi")}:`, session.fixedNumber(interfaces.goodiTotalAmount || 0))
        aThis.printFloatingInBoxLine(`${i18next.t("mishloha.paymentName")}:`, session.fixedNumber(interfaces.mishlohaTotalAmount || 0))
        aThis.printFloatingInBoxLine(`${i18next.t("zxReport.totalPaidit")}:`, session.fixedNumber(interfaces.paiditTotalAmount || 0))

        if (interfaces.deliveryApiTotals) {
          for (let voucherTypeId in interfaces.deliveryApiTotals) {
              let voucherTotals = interfaces.deliveryApiTotals[voucherTypeId];
              const voucherName = Storage.Entity.Voucher.getVoucherNameByTypeId(voucherTypeId)
              let voucherPrint = `${voucherName}:`
              if (!aThis.isHebrewText(voucherName) && !printer.isPicturesPrinter()){
                voucherPrint = `:${voucherName}`
              }

              aThis.printFloatingInBoxLine(voucherPrint, session.fixedNumber(voucherTotals.amount))
          }
        }
      }
      
      aThis.printLineForMiddleBox();

      if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners)) {
        aThis.printLineForMiddleBox();
        printer.addRTLChar();
        aThis.printBoxCenteredLine(i18next.t('printing.dinersDetails'));
        aThis.printLineForMiddleBox();  


        aThis.printFloatingInBoxLine(`${i18next.t('printing.totalSittingDiners')}:`,String(totalSittingDiners));
        aThis.printFloatingInBoxLine(`${i18next.t('printing.sittingDinersAvg')}:`,session.fixedNumber(sittingDinersAvg, 0));

        if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners)) {
            aThis.printFloatingInBoxLine(`${i18next.t('printing.totalDeliveriesDiners')}:`,String(totalDeliveriesDiners));
            aThis.printFloatingInBoxLine(`${i18next.t('printing.deliveriesDinersAvg')}:`,session.fixedNumber(deliveriesDinersAvg, 0));
            aThis.printFloatingInBoxLine(`${i18next.t('printing.deliveriesAvg')}:`,session.fixedNumber(deliveriesAvg, 0));    
        }

        aThis.printLineForMiddleBox();
      }




      printer.printLineCut();

      zebra.append(aThis.allowComponents.init);

      zebra.print();

    }

  }
}
