module PositiveTS {
   export module Service {
      export interface ECommerceResponse {
         success: boolean,
         data?: any,
      }
      export abstract class ECommerceAPIService {
         //Needs to be implemented in child class
         protected static baseUrl = null;
         protected static endpoints = {};
         protected static tokenMinutesUntillExpiration = 90;
         protected static localStorageTokenKey
         protected static apiName
         protected static apiStatues = { new: "New", InProccess: "InProccess" };

         protected static async getToken() {
            let tokenResponse = this.getLocalToken();
            if (tokenResponse.success) {
               return tokenResponse.data
            } else {
               await this.login()
               tokenResponse = this.getLocalToken()
               return tokenResponse.success ? tokenResponse.data : null;
            }
         }

         public static isJ5Payment () {
            return !!jsonConfig.getVal(jsonConfig.KEYS.j5Payment)
         }

         private static getLocalToken(): ECommerceResponse {
            if (this.isLoginRequired()) {
               return {
                  success: false
               }
            } else {
               return {
                  success: true,
                  data: JSON.parse(localStorage.getItem(this.localStorageTokenKey)).token
               };
            }
         }

         private static isLoginRequired(): boolean {
            const tokenStr = localStorage.getItem(this.localStorageTokenKey)

            if (!tokenStr) {
               return true;
            }

            const tokenoObj = JSON.parse(tokenStr);
            const tokenDate = moment(tokenoObj.at);
            if (moment(new Date()).diff(tokenDate, 'minutes') > this.tokenMinutesUntillExpiration) {
               return true;
            }

            return false;
         }

         static async login(): Promise<void> {
            if (!this.isLoginRequired()) {
               return;
            }

            const result = await this.makeLoginRequest();
            if (result.success) {
               this.saveToken(result);
            }
         }

         public static async fetchOrders() {
            const result = await this.makeFetchOrdersRequest();
            if (result.success) {
               await this.handleIncomingOrders(result.data);
            }
            else {
               console.error(`Error fetching orders from e-commerce service: ${this.apiName}\ndata: ${result.data}`)
            }
         }

         protected static saveToken(tokenResponse: ECommerceResponse): void {
            localStorage.setItem(this.localStorageTokenKey, JSON.stringify({ at: new Date().toJSON(), token: tokenResponse.data }))
         }

         protected static convertObjectToFormUrlEncodedFormat(object: any) {
            return Object.keys(object).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(object[k])).join('&');
         }

         //Needs to be implemented in child class
         protected static async makeLoginRequest(): Promise<ECommerceResponse> { return { success: false } }

         //Needs to be implemented in child class
         protected static async makeFetchOrdersRequest(): Promise<ECommerceResponse> { return { success: false } }

         //Needs to be implemented in child class
         protected static async convertApiOrderToPosExternalOrder(result: any): Promise<Array<Storage.Entity.ExternalOrder>> {
            return [];
         }

         //Needs to be implemented in child class
         protected static async updateOrderStatus(order, newStatus): Promise<ECommerceResponse> { return { success: false } }



         private static async handleIncomingOrders(result) {
            let ordersAsExternalOrder = await this.convertApiOrderToPosExternalOrder(result);

            for (let externalOrder of ordersAsExternalOrder) {
               externalOrder.posDeviceId = session.pos.deviceID
               externalOrder.storeId = Number(session.pos.storeID)
               let updateOrderRes = await this.updateOrderStatus(externalOrder.externalOrderId, this.apiStatues.InProccess)
               if (updateOrderRes.success && externalOrder.paid) {
                  let saleAndSequence = await this.convertExternalOrderToSaleAndPayment(externalOrder)
                  if (saleAndSequence) {
                     let sale = saleAndSequence[0]
                     let sequence = saleAndSequence[1]
                     sale.syncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
                     sale.syncLastMessageTimestamp = DateUtils.fullFormat();

                     await appDB.sequences.put(sequence)
                     let formattedFullSale =  await Service.FullSale.persist(sale, sale.items, sale.payments);
                     if (jsonConfig.getVal(jsonConfig.KEYS.allowBonPrintingOnEcommerceOrder)){
                        let saleBon = PositiveTS.Storage.Entity.Sale.import(formattedFullSale)
                        if (await Service.LogicalPrinterBonPrint.saleHasBonItems(saleBon)){
                           Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(saleBon, saleBon.items,saleBon.payments,null,true)
                        }
                     }

                     externalOrder.saleInvoiceSequence = sequence.sequence
                  }

               }
               await externalOrder.persist();
            }
         }

         private static async convertExternalOrderToSale(order: Storage.Entity.ExternalOrder): Promise<Storage.Entity.Sale> {
            let sale = await Sale.getNewOrder(order.orderNum, order.totalAmount, Storage.Entity.Sequence.TYPE_DEBIT_INVOICE);
            sale.orderTime = order.date
            sale.customerName = order.customerName

            const deliveryStatus = Service.Delivery.ExternalOrderStatus.InProccess;
            const deliveryTypeStr = order.deliveryType

            let jsondata = JSON.parse(sale.jsondata)
            jsondata.cibusTotalAmount = sale.totalAmount
            jsondata["promotions"] = [];
            jsondata["delivery"] = {
               isExternalOrder: true,
               orderOrigin: order.orderOrigin,
               deliveryType: deliveryTypeStr,
               status: deliveryStatus,
               ordererName: order.customerName,
               ordererPhone: order.phone,
               ordererCallerRemarks: "",
               ordererDeliveryRemarks: order.remarks,
               ordererDeliveryCutlery: "",
               remoteDBId: order.remoteDBId, 
               paymentData: order.paymentData,
               externalOrderId: order.externalOrderId,
               j5: !order.paid,
               isPhoneOrder: order.isPhoneOrder,
               "deliveryAddress": {
                  "address": {
                     "name": order.street,
                     "value": 0,
                     "cityID": 0
                  },
                  "apartment": order.apartmentNumber,
                  "house_number": order.houseNumber,
                  "house_entrance": order.entrance,
                  "house_floor": order.floor,

                  "city": {
                     "name": order.city,
                     "value": 0,
                     "cityID": 0
                  }
               },
               "orderTime": sale.orderTime,
               "address": order.addressName,
            };

            jsondata["dedicatedTo"] = order.customerName

            sale.jsondata = JSON.stringify(jsondata);
            let itemsForSale: Array<Storage.Entity.SaleItem> = []
            let totalQuantity = 0
            let totalSumItems = 0
            let totalVatableAmount = 0

            for (const orderItem of order.saleItems) {
               orderItem.saleID = sale.id
               totalQuantity += orderItem.quantity;
               totalSumItems += orderItem.unitPrice * orderItem.quantity;

               if (!orderItem.noVat || orderItem.alwaysHasVat) {
                  totalVatableAmount += orderItem.unitPrice * orderItem.quantity
               }

               itemsForSale.push(orderItem)
            }

            sale.items = itemsForSale

            sale.totalQuantity = String(totalQuantity);
            sale.totalAmount = totalSumItems;
            sale.totalVatableAmount = session.store.containVat ? 0 : totalVatableAmount;
            sale.payments = [];

            return sale
         }

         public static async addSaleDiscount(sale:Storage.Entity.Sale, order:Storage.Entity.ExternalOrder) {
            if(!order.paid) {
               return sale;
            }
            let discountAmount = sale.totalAmount - order.totalAmount;
            if(discountAmount > 0) {
               await posDiscountVC.addDiscountForInternetSale(discountAmount, sale.totalAmount, sale)
            }
            return sale;
         }

         protected static async getVoucherForPayment(possibleVouchers:Array<Storage.Entity.Voucher>, sale:Storage.Entity.Sale, order:Storage.Entity.ExternalOrder)
         : Promise<Storage.Entity.Voucher> {
            const apiName = this.apiName ? this.apiName : Delivery.ExternalOrderOrigin[order.orderOrigin]
            return possibleVouchers.find(voucher => voucher.name == apiName)
         }

         public static async getPaymentForJ5Pay (sale: Storage.Entity.Sale, amount: number, barcode?: string) {
            const saleData = JSON.parse(sale.jsondata);
            let payment = new Storage.Entity.SalePayment()
            payment.saleID = sale.id
            payment.method = Storage.Entity.SalePayment.METHOD_VOUCHER
            payment.amount = amount
            const voucherEntity = new Storage.Entity.Voucher()
            const vouchers = await voucherEntity.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID)
            const selectedVoucher = await ECommerceAPIService.getClassForOrigin(saleData.delivery.orderOrigin).getVoucherForPayment(vouchers, sale, null)
            if(posUtils.isBlank(barcode)) {
               barcode = ""
            }
            const voucherData = [
               {
                  "amount": amount,
                  "barCode": barcode,
                  "creditType": selectedVoucher.name,
                  "voucher_type_id": selectedVoucher.typeID,
                  "smartVoucherType": false,
                  "valuTypeId": null,
                  "praxellManpik": null,
                  "mutipassInit": null,
                  "isTamashCustomer": false
               }
            ]

            payment.data = JSON.stringify(voucherData)
            return payment
         }

         public static async getPaymentForExternalOrderSale(sale:Storage.Entity.Sale, order:Storage.Entity.ExternalOrder) {
            let currentPayment = new Storage.Entity.SalePayment();
            currentPayment.saleID = sale.id;
            currentPayment.method = Storage.Entity.SalePayment.METHOD_VOUCHER;
            currentPayment.amount = order.totalAmount;

            const voucherEntity = new Storage.Entity.Voucher();
            const vouchers = await voucherEntity.promiseFetchByStoreAndAllowedTypeIds(session.pos.storeID)
            
            let selectedVoucher = await ECommerceAPIService.getClassForOrder(order).getVoucherForPayment(vouchers, sale, order);

            if (!selectedVoucher) {
               console.log("ECommerce API - No voucher found for " + this.apiName)
               return null
            }

            const voucherData = [
               {
                  "amount": order.totalAmount,
                  "barCode": order.paymentToken,
                  "creditType": selectedVoucher.name,
                  "voucher_type_id": selectedVoucher.typeID,
                  "smartVoucherType": false,
                  "valuTypeId": null,
                  "praxellManpik": null,
                  "mutipassInit": null,
                  "isTamashCustomer": false
               }
            ]

            currentPayment.data = JSON.stringify(voucherData)

            return currentPayment;
         }

         public static async convertExternalOrderToSaleAndPayment(order: Storage.Entity.ExternalOrder): Promise<[Storage.Entity.Sale, Storage.Entity.Sequence]> {
            let sale = await this.convertExternalOrderToSale(order)
            sale = await this.addSaleDiscount(sale, order);
            let voucher = await this.getPaymentForExternalOrderSale(sale, order)
            sale.payments.push(voucher);

            let sequence = await Storage.Entity.Sequence.getSequenceForInvType(sale.invoiceType);
            sequence.sequence++;
            sale.invoiceSequence = sequence.sequence;

            return [sale, sequence]
         }

         public static calculateTotalExternalOrderSales(vouchers) {
            let total = 0;
            if(jsonConfig.getVal(jsonConfig.KEYS.isCashCowOrdersApiActive)) {
               total += vouchers[String(jsonConfig.getVal(jsonConfig.KEYS.cashCowVoucherNumber))] || 0
            }
            if(jsonConfig.getVal(jsonConfig.KEYS.isDigitradeOrdersApiActive)) {
               total += vouchers[String(jsonConfig.getVal(jsonConfig.KEYS.digitradeVoucherNumber))] || 0
            }
            return total;
         }

         public static getClassForOrder(order: Storage.Entity.ExternalOrder):  any {
            return ECommerceAPIService.getClassForOrigin(order.orderOrigin);
         }

         public static getClassForOrigin(origin:number):  any {
            switch(origin) {
               case Service.Delivery.ExternalOrderOrigin.CashCow:
                  return CashCowService;
               case Service.Delivery.ExternalOrderOrigin.Digitrade:
                  return DigitradeService;
               default:
                  return ECommerceAPIService;
            }
         }


      }
   }
}
