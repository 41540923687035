module PositiveTS {
    export module Service {
        export module CloseFlight {
            export async function CloseFlightAndZReport(forceWithoutPinpad = !session.pos.isEmv) {
                if (session.pos && session.pos.hasFlights &&Pinia.flightsStore.flight) {

                    let res = await app.promiseShowAlert({
                        header: i18next.t('flightZReport.title'),
                        content: i18next.t("flightZReport.message"),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: false
                    });

                    if (res != "continue") {
                        return;
                    }

                    app.showLoadingMessage(i18next.t("checkingFlightSales"));

                    let sales = await Helper.ReportsHelper.getSalesForNewZReportNoImport();

                    app.hideLoadingMessage();

                    if (Pinia.globalStore.isOnline) {
                        try {
                            // No sales, don't need z
                            if (sales.length < 1) {
                                await transmitAndCloseFlight();

                                app.showAlert({
                                    header: i18next.t('noSales'),
                                    content: i18next.t('flightUploadedNoSales'),
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                }, null, null);

                                return;
                            } else {
                                // There is sales, need Z

                                    // DO Z 
                                    let zReportSuccessfully = await PositiveTS.Service.ZReport.openZReport(false, forceWithoutPinpad);                                    ;

                                    if (zReportSuccessfully) {
                                        await transmitAndCloseFlight();
                                    }
                                
                            }
                        }
                        catch (e) {
                            app.hideLoadingMessage();
                            console.error(e);
                            console.error(e.stack);
                            Service.Logger.error(e);
                            PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportFlightTransmitFailed"));
                            return;
                        }

                        app.hideLoadingMessage();
                    } else {
                        // NOT ONLINE ERROR
                        app.hideLoadingMessage();
                        PositiveTS.Helper.ReportsHelper.showZReportError(i18next.t("zReportCreatingErrorOffline"));
                    }
                }
            }

            async function transmitAndCloseFlight() {
                if (session.pos && session.pos.hasFlights && Pinia.flightsStore.flight) {
                    app.showLoadingMessage(i18next.t("transmittingFlight"));

                    await transmitFlight();
                    await Pinia.flightsStore.finishFlight();

                    app.hideLoadingMessage();
                }
            }
        }


        async function transmitFlight() {
            let request;
            let flight = Pinia.flightsStore.flight;

            try {
                request =
                    await PositiveTS.Service.FetchReq.jsonReq('/flights/finish_flight', 'post', { flight, pos_device_id: session.pos.deviceID });
            } catch (err) {
                throw new Error("flightTransmitError")
            }

            if (!(request.response && request.response.ok && request.result)) {
                throw new Error("flightTransmitError")
            }
        }
    }
}