module PositiveTS {
  export module Printing {
    export class HtmlFactory {

      static isTestPrintOnly = false;

      constructor() {
        throw new Error("Static Class");
      }


      static promiseGetCurrentCompanyLogoUrl():Promise<string>{
        return this._promiseConvertToDataURLviaCanvas(this._getCurrentCompanyLogoUrl());
      }

      private static _promiseConvertToDataURLviaCanvas(url: string, outputFormat?: string): Promise<string> {
        return new Promise((resolve, reject) => {
          this._convertToDataURLviaCanvas(url, outputFormat)
          .then((base64Img) => {
            resolve(base64Img);
          })
          .catch(e => {
            resolve(null);
          })
        });
      }

      /*
      Usage:
      convertToDataURLviaCanvas('http://bit.ly/18g0VNp', function(base64Img){
      // Base64DataURL
      });
      */
      private static _convertToDataURLviaCanvas(url: any, outputFormat: any): Promise<any> {
        return new Promise((resolve,reject) => {
          var img = new Image();
          img.crossOrigin = 'Anonymous';
          img.onload = function() {
            var canvas: any = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = (<any>this).height;
            canvas.width = (<any>this).width;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
            canvas = null;
          };
          img.onerror = function() {
            reject("Error loading image");
          }
          img.src = url;
        });
      }

      private static _getCurrentCompanyLogoUrl(): string {
        var imgSrc = Shared.Constants.remoteRoot + 'logo/{TENANT_ID}/{COMPANY_ID}/logo';
        var pos = session.pos;
        imgSrc = imgSrc.replace('{TENANT_ID}', pos.tenantID).replace('{COMPANY_ID}', pos.companyID);
        return imgSrc;
      }


      
      static async popupPrintWithPromise(data: string): Promise<any> {
        let resolveFunc = () => {};
        const promise = new Promise((resolve) => {
          resolveFunc = resolve;
        });

        let mywindow = window.open('', '', 'height=400,width=900');
        mywindow.document.write('<html><head><title> </title>');
        /*optional stylesheet*/ //mywindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
        mywindow.document.write('</head><body >');
        mywindow.document.write(data);
        mywindow.document.write('</body></html>');

        if (!this.isTestPrintOnly) {
          setTimeout(() => {
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10
          }, 500); // Adjust the delay as needed
          
          var theScript = document.createElement('script');
          var injectThis = function() {
              // The code you want to inject goes here
              window.print();
              setTimeout(() => {
                window.close();
              }, 500); // Adjust the delay as needed
          }
          theScript.innerHTML = 'window.onload = ' + injectThis.toString() + ';';
          mywindow.document.body.appendChild(theScript);

          // Add event listener for popup window close
          mywindow.addEventListener('beforeunload', () => {
            resolveFunc();
          });
        }

        return promise;
      }

      static popupPrint(data: string): void {
        var mywindow = window.open('', '', 'height=400,width=900');

        mywindow.document.write('<html><head><title> </title>');
        /*optional stylesheet*/ //mywindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
        mywindow.document.write('</head><body >');
        mywindow.document.write(data);
        mywindow.document.write('</body></html>');

        if (!this.isTestPrintOnly) {
          mywindow.document.close(); // necessary for IE >= 10
          mywindow.focus(); // necessary for IE >= 10

          
          var theScript = document.createElement('script');
          var injectThis = function() {
              // The code you want to inject goes here
              window.print();
              window.close();    
          }
          theScript.innerHTML = 'window.onload = ' + injectThis.toString() + ';';
          mywindow.document.body.appendChild(theScript);

          
        }

        return;
      }
    }


    export module HtmlInvoice {
      export class HtmlInvoice {

        constructor() { }

        print(sale, saleItems, salePayments, isSource, jqTemplateString: string): Promise<void> {
          return this._getTemplateDictionary(sale, saleItems, salePayments, isSource)
            .then((oObjHtmlInvoice) => {
            HtmlFactory.popupPrint(this._getPrintingString(oObjHtmlInvoice, jqTemplateString));
            //window.JST.a4Print(oObjHtmlInvoice)
          })
          .catch( (err)=>{
            console.error(err);
            return app.promiseShowAlert({
              header: "",
              content: `בעיה בהדפסת A4, בדר"כ זה קורה כאשר יש חסימה של חלונות קופצים - נא לפנות לתמיכה.
  ניתן להדפיס את החשבונית לאחר מכן דרך מסך חשבוניות קודמות.`,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true,
              noHandleEnterEscape: true,
            });

          });
        }

        private _getPrintingString(obj: ObjHtmlInvoice, jqTemplateString: string): string {
          return _.template(jqTemplateString)(obj);
        }

        private _getTemplateDictionary(sale, saleItems, salePayments, isSource): Promise<ObjHtmlInvoice> {

          var printCalculatedVars = { parentSaleStoreName: undefined };
          if (sale.parentSaleStoreID) {
            return Storage.Entity.Store.getStoreName(sale.tenantID, sale.parentSaleCompanyID, sale.parentSaleStoreID)
              .then((parentSaleStoreName) => {
              printCalculatedVars.parentSaleStoreName = parentSaleStoreName;
              return this._prepairAndGetConstructedInvoiceObject(sale, saleItems, salePayments, isSource, printCalculatedVars);
            })
          } else {
            return this._prepairAndGetConstructedInvoiceObject(sale, saleItems, salePayments, isSource, printCalculatedVars);
          }
        }



        private _prepairAndGetConstructedInvoiceObject(sale, saleItems, salePayments, isSource, printCalculatedVars): Promise<ObjHtmlInvoice> {

          var company;
          var paymentsTypes;
          var posNumber;
          var imgSrc;
          var shouldPrintWithCopy = false;

          if(jsonConfig.getVal(jsonConfig.KEYS.printCreditCardInvoicesWithCopyOnA4)) {
            salePayments.forEach(payment => {
              if(payment.method == Storage.Entity.SalePayment.METHOD_CREDIT) {
                shouldPrintWithCopy = true;
              }
            });
          }

          return PositiveTS.Printing.HtmlFactory.promiseGetCurrentCompanyLogoUrl()
          .then((img) => {
            imgSrc = img;
          })
          .then(() => {
            var companyModel = new PositiveTS.Storage.Entity.Company();
            return companyModel.promiseFetchByTenantIDAndCompanyID(sale.tenantID, sale.companyID)
          })
          .then((companyResult) => {
            company = companyResult

            var paymentTypeModel = new PositiveTS.Storage.Entity.PaymentType();
            return paymentTypeModel.getCalcuatedPaymentTypesObject();
          })
          .then((paymentsTypes) => {
            posNumber = session.pos.posNumber;
            if (sale.posNumber !== null && sale.posNumber !== undefined && sale.posNumber !== "undefined" && sale.posNumber != "null" && sale.posNumber != "") {
              posNumber = sale.posNumber;
            }
            return this._getConstructedInvoiceObject(sale, saleItems, salePayments, isSource, printCalculatedVars, company, paymentsTypes, posNumber, imgSrc, shouldPrintWithCopy)
          });

        }

        private _getConstructedInvoiceObject(sale, saleItems, salePayments, isSource, printCalculatedVars, company, paymentsTypes, posNumber, imgSrc, shouldPrintWithCopy?): ObjHtmlInvoice {
          return new ObjHtmlInvoice(sale, saleItems, salePayments, isSource, printCalculatedVars, company, paymentsTypes, posNumber, imgSrc, shouldPrintWithCopy);
        }

      }

      export function numberFormat(number){
        return _numberFormat(number, 2, ".", ",")
      }

      function _numberFormat(number, decimals, dec_point, thousands_sep) {
          var n = !isFinite(+number) ? 0 : +number,
              prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
              sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
              dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
              toFixedFix = function (n, prec) {
                  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                  var k = Math.pow(10, prec);
                  return Math.round(n * k) / k;
              },
              s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
          if (s[0].length > 3) {
              s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
          }
          if ((s[1] || '').length < prec) {
              s[1] = s[1] || '';
              s[1] += new Array(prec - s[1].length + 1).join('0');
          }
          return s.join(dec);
      }

      export function getDiscountInPercentages(discountAmount, itemPriceBeforeDiscounts): string {
        return `${((Math.abs(discountAmount) / itemPriceBeforeDiscounts) * 100).toFixed(2)}%`
      }

    }
  }
}
