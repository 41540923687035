import { defineStore } from 'pinia';
const positiveSearchModeLocalStrageKey = 'positiveSearchMode'
const isSelfServicePosLoked = 'isSelfServicePosLoked';
const isMessageSentOnPinpadFault = 'isMessageSentOnPinpadFault';

let selfServiceTemporaryRelaeseLockDebounce = _.debounce(function () {
  localStorage.removeItem(isMessageSentOnPinpadFault)
  Pinia.globalStore.isLockedPinpadSelfService = true;
}, 1000 * 60 * 10);

let allowedItemActionsConfig = {
  canChangeQuantity: {
    checkFunction: (selectedSaleItem) => {
      let isImmutableItem = Pinia.globalStore.isImmutableItem
      let isReplacementItem = Pinia.globalStore.isReplacementItem
      let isSpecialItemSelected = Pinia.globalStore.isSpecialItemSelected


      let isSingleItemSelection = !jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)

      let canChangeQuantityCondition = selectedSaleItem && !selectedSaleItem.isChild && !selectedSaleItem.salePercentAddition &&
        !selectedSaleItem.item.isPunchCard && !isSpecialItemSelected &&
        !selectedSaleItem.bonPrintedAt && !selectedSaleItem.isDelivery && !isReplacementItem(posVC.sale, selectedSaleItem)
        && !isImmutableItem(posVC.sale, selectedSaleItem);


      if (isSingleItemSelection) {
        canChangeQuantityCondition = canChangeQuantityCondition && !selectedSaleItem.hasWeight && !selectedSaleItem.timeItemVersion
      }

      return canChangeQuantityCondition
    },
    allowOnMultipleSelection: true
  },

  canDeleteItem: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem, isReplacementItem } = Pinia.globalStore
      let codes = PositiveTS.Service.CustomerClubSpecialItem.getSpecialItemCodes();
      return selectedSaleItem && !selectedSaleItem.isChild && !selectedSaleItem.isDelivery && !isReplacementItem(posVC.sale, selectedSaleItem)
        && !isImmutableItem(posVC.sale, selectedSaleItem) && !codes.includes(selectedSaleItem.barcode);
    },
    allowOnMultipleSelection: true
  },

  canFinishTimeItem: {
    checkFunction: (selectedSaleItem) => {
      return Boolean(selectedSaleItem && selectedSaleItem.timeItemVersion && !selectedSaleItem.endTimestamp)
    },

    allowOnMultipleSelection: false
  },

  canShowTimeItemFinishDetails: {
    checkFunction: (selectedSaleItem) => {
      return Boolean(selectedSaleItem && selectedSaleItem.timeItemVersion && selectedSaleItem.endTimestamp)
    },

    allowOnMultipleSelection: false
  },

  canReScale: {
    checkFunction: (selectedSaleItem) => {
      let disableScale = jsonConfig.getVal(jsonConfig.KEYS.disableReScale)
      return selectedSaleItem && !disableScale && selectedSaleItem.hasWeight && !selectedSaleItem.bonPrintedAt;
    },

    allowOnMultipleSelection: false
  },

  canMakeItemDiscount: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem } = Pinia.globalStore
      let { isSpecialItemSelected, standaloneMode, isSelectedSpecialItemEligibleForDiscount } = Pinia.globalStore

      if (PositiveTS.Service.Otot.isOtotActive() && PositiveTS.Service.Otot.isOtotPurchaseTagItem(selectedSaleItem)) {
        return false;
      }

      return selectedSaleItem && !standaloneMode && !selectedSaleItem.isChild && !selectedSaleItem.noDiscount &&
        !selectedSaleItem.salePercentAddition && (!isSpecialItemSelected || isSelectedSpecialItemEligibleForDiscount) && !selectedSaleItem.isDelivery &&
        !jsonConfig.getVal(jsonConfig.KEYS.hideDiscounts) && !session.pos.hasFlights && !isImmutableItem(posVC.sale, selectedSaleItem);
    },

    allowOnMultipleSelection: true
  },

  canIncrementQuantity: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem, isReplacementItem } = Pinia.globalStore
      let { isSpecialItemSelected } = Pinia.globalStore

      return selectedSaleItem && !selectedSaleItem.isChild && !selectedSaleItem.hasWeight && !selectedSaleItem.salePercentAddition &&
        !(selectedSaleItem && selectedSaleItem.item.isPunchCard) && !isSpecialItemSelected &&
        !selectedSaleItem.bonPrintedAt && !selectedSaleItem.isDelivery && !isReplacementItem(posVC.sale, selectedSaleItem)
        && !isImmutableItem(posVC.sale, selectedSaleItem) && !selectedSaleItem.timeItemVersion;
    },
    allowOnMultipleSelection: true
  },

  canDecrementQuantity: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem, isReplacementItem } = Pinia.globalStore
      let { isSpecialItemSelected } = Pinia.globalStore

      return selectedSaleItem && !selectedSaleItem.isChild && !selectedSaleItem.hasWeight && !selectedSaleItem.salePercentAddition &&
        !(selectedSaleItem && selectedSaleItem.item.isPunchCard) && !isSpecialItemSelected &&
        !selectedSaleItem.bonPrintedAt && !selectedSaleItem.isDelivery && !isReplacementItem(posVC.sale, selectedSaleItem)
        && !isImmutableItem(posVC.sale, selectedSaleItem) && !selectedSaleItem.timeItemVersion;
    },
    allowOnMultipleSelection: true
  },

  canEditItem: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem, isReplacementItem } = Pinia.globalStore
      let hasGroups = selectedSaleItem.hasGroups
      let hasPreparationInstructions = selectedSaleItem.hasPreparationInstructions;
      return selectedSaleItem && (hasGroups || hasPreparationInstructions) && !selectedSaleItem.item.disableItemGroupEditing && selectedSaleItem.level === 0 && !isReplacementItem(posVC.sale, selectedSaleItem)
        && !isImmutableItem(posVC.sale, selectedSaleItem);
    },
    allowOnMultipleSelection: true
  },

  canCheckStock: {
    checkFunction: (selectedSaleItem) => {
      let { standaloneMode, showInvForItemButton, isSpecialItemSelected } = Pinia.globalStore

      return !standaloneMode && showInvForItemButton && selectedSaleItem && !selectedSaleItem.salePercentAddition && !isSpecialItemSelected && !selectedSaleItem.isDelivery
    },
    allowOnMultipleSelection: true
  },

  canAddReturnNote: {
    checkFunction: (selectedSaleItem) => {
      let { standaloneMode, showGiftForItemButton, isWithdrawalSale } = Pinia.globalStore
      let { isImmutableItem } = Pinia.globalStore
      let isDutyFree = jsonConfig.getVal(jsonConfig.KEYS.isDutyFree)


      return selectedSaleItem && !standaloneMode && !isWithdrawalSale && !selectedSaleItem.isChild &&
        showGiftForItemButton && !selectedSaleItem.salePercentAddition &&
        !selectedSaleItem.isDelivery && !isDutyFree && !session.pos.hasFlights &&
        !isImmutableItem(posVC.sale, selectedSaleItem);
    },
    allowOnMultipleSelection: true
  },

  canReturnItemWeight: {
    checkFunction: (selectedSaleItem) => {
      if (selectedSaleItem == null || jsonConfig.getVal(jsonConfig.KEYS.hideReturnItemWeight)) {
        return false
      }

      return selectedSaleItem && selectedSaleItem.hasWeight && selectedSaleItem.quantity > 0;
    },
    allowOnMultipleSelection: true
  },

  canSelectSalesPerson: {
    checkFunction: (selectedSaleItem) => {
      let showSalesPersonButton = (session.pos.parameterRequireSalesperson != PositiveTS.Storage.Entity.Pos.REQUIRE_DISABLED)
      let { standaloneMode, mobileLayout } = Pinia.globalStore
      return selectedSaleItem && showSalesPersonButton && !standaloneMode && !selectedSaleItem.isChild && !selectedSaleItem.isDelivery && !session.pos.hasFlights && !mobileLayout
    },
    allowOnMultipleSelection: true
  },

  canChangePrice: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem, isReplacementItem } = Pinia.globalStore;
      let { standaloneMode, blockItemPriceChange } = Pinia.globalStore;
      return selectedSaleItem && !standaloneMode && !selectedSaleItem.hasGroups && !selectedSaleItem.isChild && !blockItemPriceChange &&
        !selectedSaleItem.salePercentAddition && !session.pos.hasFlights && !isReplacementItem(posVC.sale, selectedSaleItem)
        && !isImmutableItem(posVC.sale, selectedSaleItem) && !selectedSaleItem.timeItemVersion;
    },
    allowOnMultipleSelection: true
  },

  canSelectPreparationInstructions: {
    checkFunction: (selectedSaleItem) => {
      let { standaloneMode } = Pinia.globalStore;

      return selectedSaleItem && !standaloneMode && selectedSaleItem.hasPreparationInstructions && !selectedSaleItem.bonPrintedAt
    },
    allowOnMultipleSelection: true
  },

  canGetPackage: {
    checkFunction: (selectedSaleItem) => {
      return selectedSaleItem && selectedSaleItem.hasWeight && selectedSaleItem.quantity > 0
    },
    allowOnMultipleSelection: true
  },

  canCustomerCompensation: {
    checkFunction: (selectedSaleItem) => {
      let { customerCompensation } = Pinia.globalStore
      return selectedSaleItem && customerCompensation && selectedSaleItem.hasCompensation && !selectedSaleItem.isChild
    },
    allowOnMultipleSelection: true
  },
  canMoveDalpak: {
    checkFunction: (selectedSaleItem) => {
      let { isImmutableItem, isReplacementItem } = Pinia.globalStore
      let { isSpecialItemSelected } = Pinia.globalStore

      return jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && selectedSaleItem &&
        !(selectedSaleItem && selectedSaleItem.item.isPunchCard) && !isSpecialItemSelected &&
        !isReplacementItem(posVC.sale, selectedSaleItem) && !isImmutableItem(posVC.sale, selectedSaleItem) && !selectedSaleItem.isChild && !selectedSaleItem.isDelivery
    },
    allowOnMultipleSelection: true
  }
}

const state = {
    saleUpdatedTriggered:false,
    standaloneMode: false,
    hakafaDisabled:false,
    mizdamenOrHakafaMode: false,
    askForClubMembershipeMode: false,
    isHaveToChooseClubMembership: false,
    isLockedPinpadSelfService: false,
    saleItems: [],
    selectedSaleItem: null,
    multipleSelectedSaleItems: [],
    sale: null,
    hakafaCustomer: null,
    totalQuantity: 0,
    effectiveMigvanId: 0,
    totalAmount: 0,
    saleTotal: 0,
    codeToQtyMap: new Map<String, number>(),
    customerText: "",
    saleItemsTableViewMessage: "",
    voucherEnabled: true,
    isScreenLocked: false,
    showInvForItemButton: true,
    bigPaymentButtons: false,
    fullClub: false,
    allowSetNewCustomerDiscountPercent: false,
    showGiftForItemButton: true,
    isShowQuicksaleButton: false,
    blockItemPriceChange: false,
    allowInvoiceSplit: false,
    addressCityAndApartmentFieldsRequired: false,
    mobilePhoneRepairModule: false,
    isBeengoSupported: false,
    isCaveretPayment: false,
    isDeliverySale: false,
    simpleSelfService: false,
    isSelfServicePosLoked: localStorage.getItem(isSelfServicePosLoked) == 'true',
    itemWithDialogXL: false,
    promoGroups: [],
    isSpecialItemCode: false,
    isSpecialItemSelected: false,
    isSelectedSpecialItemEligibleForDiscount: false,
    isReopenQuicksaleOnNewSale: false,
    hideButtonMenu: false,
    searchMode: window.localStorage.getItem(positiveSearchModeLocalStrageKey) || "byCode",
    emvRecoveryNeeded: localStorage.getItem("emvRecoveryNeeded") == "true",
    emvRecoveryNeededWhenOnline: localStorage.getItem("emvRecoveryNeededWhenOnline") == "true",
    cibusTenbis: {safeCashTotalAmount:0, tenbisTotalAmount: 0, cibusTotalAmount: 0, goodiTotalAmount: 0, dtsTotalAmount: 0, banxTotalAmount: 0, yaadTotalAmount: 0, caveretPaymentTotalAmount: 0, multipassPoliceTotalAmount: 0, interServiceTotalAmount: 0 },
    isOnline: true,
    isTenbisOrCibus: false,
    portraitMode: false,
    rtlMode: true,
    currency: "ILS",
    posState: "pos",
    offlineSaleCount: 0,
    isRoshemet: false,
    customerCompensation: false,
    passwordProtected: false,
    darkThemeMode: false,
    holdSaleAvailable: false,
    releaseSaleAvailable: false,
    isPunchCardMode: Boolean(localStorage.getItem("is_punch_card_mode")),
    cashierStatementType: 'enter',
    isWithdrawalSale: false,
    isPickupSale: false,
    useRoshemetBigLayout: (<any>window).localStorage.getItem("useRoshemetBigLayout") == "true",
    isCashierLoggedIn: false,
    lastCatalogUpdate: localStorage.getItem("catalogLastUpdate"),
    cashierHasToChangePassword: false,
    avoidNexLogin: JSON.parse(localStorage.getItem('avoidNextLogin')) ? true : false,
    paymentCurrency: null,
    leftAmount: 0,
    isLoadingMessageDisabled:false,
    salePayments: [],
    mobileLayout: false,
    mobilePosNavState: PositiveTS.Components.PosViewState.Buttons,
    routerExtraData: null,
    route: PositiveTS.Enums.RouteState.HomePage,
    usePictures: false,
    slowConnection: false,
    supportNumber: '',
    isSubmitedSelfServiceKeypadInput:false,
    isSaleGiftCard: false,
    uiBlockMessage: null,
    uiBlocked: false,
    uiBlockedEvents: false,
    deliveriesIndicator: 0,
    newExternalOrdersIndicator: false,
    externalOrderIndicator: 0,
    hasToPerformZ: false,
    onSelfServicePaymentDialog: false,
    emvTimeout: 60,
    atmTimeout: 120,
    multipassPendingLoadBudget: JSON.parse(localStorage.getItem("multipassPendingLoadBudget")),
    emvPosNumber: null,
    openPaymentsForTip: false,
    selectedVoucherType: null,
    currentPage: null,
    posCustomerNameData: null,
    syncServerOnlineState: null,
    splittedSalePaymentInfo: null,
    splittedSalePaymentDialogInfo: null,
    streets: [],
    cities: [],
    selfServiceSuperMarket: false,
    isLoadingMessageActive: false,
    posTabMode: false,
    isSaleApproved: false,
    shekuloTovBookBarcodes: JSON.parse(localStorage.getItem('shekuloTovBookBarcodes')) || [],
    isExternalDeliveryOrdersActive: false,
    splitPaymentData: null,
    pinpadTransactionRunning: false,
    isNirDavidEntranceSale: false,
    showNirDavidEntranceDialog: true,
    deliveryErrorExists: false,
    failedDeliveriesOrders: [],
    allowedActionsForSelectedItemsCounter: {},
    outOfStockItemsCodes: [],
    ototSmartCardLoadedNumber: null,
    ototSaleBracelet: { bracelet:null,status:3},
    isSelfServiceTa: false,
    selfServiceBackgroundImageActivated: false,
    defaultImages: {},
    storeLogoUrl: null,
    cashierName: '',
    selfServiceKeypadInputVal: '',
    EMVisAsync: false,
    EMVDisableClick: true,
    EMVHideLoader: false,
    deactivateScreenSaver: false,
    isVendingMachine:false,
    vendingMachineOutOfStockItemCodes: JSON.parse(localStorage.getItem("vendingMachineOutOfStockItemCodes")) || [],
    isSoglowekPos: false,
    askCustomerClubBeforePayment:{paymentMethod:null,isNeedToAsk:true},
    webWorkerData:{lastHeartbeat:null,posAccessToken:null,currentDBVersion:null,setupHeartbeatInterval:null},


}

const getters = {
  saleItemsFormatted: (state) => {
    let saleItems = state.saleItems;
    let currentLang: string = Pinia.languageManagerStore.currentLang;
    return PositiveTS.Service.Translation.formatSaleItems({ saleItems: saleItems, lang: currentLang });
  },
  shouldShowOtotPrintBracelet: () => {
    return PositiveTS.Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotPrintBraceletButton);
  },
  showOtotRFIDBraceletActivation: () => {
    return PositiveTS.Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotRFIDBraceletActivation);
  },
  selfServiceKeypadInput: (state) => {
    return state.selfServiceKeypadInputVal;
  },
  isRTL: (): boolean => {
    let lang = i18next.locale;
    const rtlLanguages = ['ar', 'fa', 'ur', 'he'];
    return rtlLanguages.includes(lang);
  },
  isLTR: (): boolean => {
    return !getters.isRTL();
  },
  selfServiceQuickSale: () => {
    return jsonConfig.getVal(jsonConfig.KEYS.selfServiceQuickSale);
  },
  getRouterExtraData: (state) => {
    return state.routerExtraData
  },

  currentSplittedPaymentAmount: (state) => {
    if (!state.sale || getters.splitPaymentCurrentQuantity(state) == 0) {
      return 0;
    }

    if (posUtils.isBlank(state.splitPaymentData)) {
      return state.leftAmount;
    }

    return session.fixedFloat(state.leftAmount / getters.splitPaymentCurrentQuantity(state), 1);
  },

  allowedItemActions: (state) => {
    let allowedActionsForSelectedItems = {}
    let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)
    let isRestaurantNewPOS = jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)
    if (allowMultipleSaleItemsSelection && !isRestaurantNewPOS) {
      for (const key in state.allowedActionsForSelectedItemsCounter) {
        if (allowedItemActionsConfig[key].allowOnMultipleSelection) {
          allowedActionsForSelectedItems[key] = state.multipleSelectedSaleItems.length > 0 && state.multipleSelectedSaleItems.length == state.allowedActionsForSelectedItemsCounter[key]
        } else {
          allowedActionsForSelectedItems[key] = state.multipleSelectedSaleItems.length == 1 && state.allowedActionsForSelectedItemsCounter[key] == 1
        }
      }
    } else {
      let selectedItem = state.selectedSaleItem
      for (const key in state.allowedActionsForSelectedItemsCounter) {
        allowedActionsForSelectedItems[key] = allowedItemActionsConfig[key].checkFunction(selectedItem)
      }
    }

    return allowedActionsForSelectedItems
  },
  splitPaymentCurrentQuantity: (state) => {
    return posUtils.isDefined(state.splitPaymentData) ? state.splitPaymentData.quantity : null;
  },
  multipassPendingLoadBudgetSale: (state) => {
    return posUtils.isDefined(state.multipassPendingLoadBudget);
  },
  isCurrentSaleEmpty: (state) => {
    return state.saleItems.length < 1 && !PositiveTS.Service.CustomerClub.hasCutomerClubOnSale(state.sale)
  },
  isReplacementItem: () => (sale: PositiveTS.Storage.Entity.Sale, saleItem: PositiveTS.Storage.Entity.SaleItem) => {
    let jsondata = posUtils.isBlank(sale.jsondata) ? {} : JSON.parse(sale.jsondata);

    if (!posUtils.isBlank(jsondata['replacementSaleItems'])) {
      return jsondata['replacementSaleItems'].filter(replacementData => replacementData.saleItemRowNumber == saleItem.rowNumber).length > 0;
    }

    return false;
  },
  isImmutableItem: () => (sale: PositiveTS.Storage.Entity.Sale, saleItem: PositiveTS.Storage.Entity.SaleItem) => {
    if (PositiveTS.Service.SplitSalePayment.isPaymentItem(sale, saleItem)) {
      return true;
    }
    if (saleItem.isPNRItem) {
      return true;
    }

    return false;
  },
  canMoveDalpak: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    if (PositiveTS.Service.SmartVoucher.isSpecialItemCode(saleItem.itemCode)) {
      return false;
    }
    return allowedItemActionsConfig.canMoveDalpak.checkFunction(saleItem)
  },
  canChangeQuantity: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    // return getters.
    return allowedItemActionsConfig.canChangeQuantity.checkFunction(selectedSaleItem)
  },
  canDeleteItem: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem
    return allowedItemActionsConfig.canDeleteItem.checkFunction(selectedSaleItem)
  },
  canFinishTimeItem: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem
    return allowedItemActionsConfig.canFinishTimeItem.checkFunction(selectedSaleItem)
  },
  canShowTimeItemFinishDetails: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem
    return allowedItemActionsConfig.canShowTimeItemFinishDetails.checkFunction(selectedSaleItem)
  },
  canEditItem: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canEditItem.checkFunction(selectedSaleItem)
  },
  canReScale: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem
    return allowedItemActionsConfig.canReScale.checkFunction(selectedSaleItem)
  },
  canMakeItemDiscount: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem
    return allowedItemActionsConfig.canMakeItemDiscount.checkFunction(selectedSaleItem)
  },

  canIncrementQuantity: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canIncrementQuantity.checkFunction(selectedSaleItem)
  },

  canDecrementQuantity: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canDecrementQuantity.checkFunction(selectedSaleItem)
  },
  canCheckStock: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canCheckStock.checkFunction(selectedSaleItem)
  },
  canAddReturnNote: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem
    return allowedItemActionsConfig.canAddReturnNote.checkFunction(selectedSaleItem)
  },
  canReturnItemWeight: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canReturnItemWeight.checkFunction(selectedSaleItem)
  },
  canSelectSalesPerson: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canSelectSalesPerson.checkFunction(selectedSaleItem)
  },

  canChangePrice: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canChangePrice.checkFunction(selectedSaleItem)
  },

  canSelectPreparationInstructions: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canSelectPreparationInstructions.checkFunction(selectedSaleItem)
  },

  canGetPackage: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canGetPackage.checkFunction(selectedSaleItem)
  },

  canCustomerCompensation: () => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return allowedItemActionsConfig.canCustomerCompensation.checkFunction(selectedSaleItem)
  },
  canNameChange: (state) => (saleItem?: PositiveTS.Storage.Entity.SaleItem) => {
    let selectedSaleItem = saleItem

    return selectedSaleItem && !state.isSpecialItemSelected && !selectedSaleItem.bonPrintedAt && !selectedSaleItem.isDelivery && selectedSaleItem.item.isAllowNameChange
  },
  outOfStockItemCodesByCode: (state) => {
    let res = {};

    for (let code of state.outOfStockItemsCodes) {
      res[code] = true;
    }
    for (let code of state.vendingMachineOutOfStockItemCodes) {
      res[code] = true;
    }

    return res;
  },
  getSaleRemarks: (state) => {
    return state.sale.saleRemarks
  },
  isItemInSaleItems: (state) => (code: string) => {
    return state.saleItems.some(saleItem => saleItem.itemCode == code)
  },
  countItemsInSaleItems: (state) => (code: string) => {
    let count = 0
    for (let saleItem of state.saleItems) {
      if (saleItem.itemCode == code) {
        count += saleItem.quantity
      }
    }
    return count
  },
  showMultiCurrCoinGap: () => {
    return Boolean(jsonConfig.getVal(jsonConfig.KEYS.showMultiCurrCoinGap));
  },
  getTotalPaymentsOfSmartVoucherByType: (state) => (smartVoucherType: string) => {
    let total = 0;
    let payments: PositiveTS.Storage.Entity.SalePayment[] = state.salePayments;
    for (let payment of payments) {
      if (payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
        continue;
      }
      let paymentDataArr: any[] = JSON.parse(payment.data);
      for (let paymentData of paymentDataArr) {
        if (paymentData.smartVoucherType == smartVoucherType) {
          total += paymentData.amount;
        }
      }
    }
    return total;
  },
  totalCreditCardPayment: (state) => {
    let total = 0;
    let payments: PositiveTS.Storage.Entity.SalePayment[] = state.salePayments;
    for (let payment of payments) {
      if (payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT) {
        continue;
      }
      total += payment.amount;
    }
    return total;
  },
  saleHasMultipassPointsItems: (state) => {
    return state.saleItems.some((saleItem) => {
      return posUtils.isPresent(saleItem.item.wallets);
    });
  },
  multipassPointsHaveBeenUsed: (state) => {
    let salePayments: PositiveTS.Storage.Entity.SalePayment[] = state.salePayments;
    if (posUtils.isBlankLikeRails(salePayments)) {
      return false;
    }
    for (const payment of salePayments) {
      let data = JSON.parse(payment?.data || "{}");
      if (!posUtils.isNullOrUndefinedOrEmptyString(data) && data.length > 0 &&
        data[0].smartVoucherType == PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE) {
        return true;
      }
    }
    return false;
  },
  totalPluxeePayments: (state) => {
    let smartVoucherType = PositiveTS.Service.Pluxee.getSmartVoucherType();
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(smartVoucherType);
  },
  totalTenbisPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS);
  },
  totalGoodiPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI);
  },
  totalMultipassPointsPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE);
  },
  totalGppPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GPP);
  },
  totalChargeableCardPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU);
  },
  totalOtotPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT);
  },
  totalCaveretPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT);
  },
  totalSafeCashPayments: (state) => {
    return getters.getTotalPaymentsOfSmartVoucherByType(state)(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH);
  },
  totalHakafaPayments: (state) => {
    let total = 0;
    let hakafaVoucherId = PositiveTS.Service.Hakafa.getVoucherID();
    let payments: PositiveTS.Storage.Entity.SalePayment[] = state.salePayments;
    for (let payment of payments) {
      if (payment.method != PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER) {
        continue;
      }
      let paymentDataArr: any[] = JSON.parse(payment.data);
      for (let paymentData of paymentDataArr) {
        if (paymentData.voucher_type_id == hakafaVoucherId) {
          total += paymentData.amount;
        }
      }
    }
    return total;
  },
}

const actions = {
  setWebWorkerData(payload:any){
    this.webWorkerData = {...this.webWorkerData,...payload};
  },
  setAskCustomerClubBeforePayment(payload: any){
    this.askCustomerClubBeforePayment = {...this.askCustomerClubBeforePayment,...payload};
  },
  setIsSoglowekPos(val: boolean) {
    this.isSoglowekPos = val;
  },

  setIsVendingMachinePos(val: boolean) {
    this.isVendingMachine = val;
  },
  setVendingMachineOutOfStockItemCodes(itemCodes: string[]) {
    this.vendingMachineOutOfStockItemCodes = itemCodes;
  },
  setIsLoadingMessageDisabled(payload) {
    this.isLoadingMessageDisabled = payload;
  },
  setSelfServiceKeypadInput(newInput: string) {
    this.selfServiceKeypadInputVal = newInput;
  },
  submitSelfServiceKeypadInput(newInput: string) {
    this.isSubmitedSelfServiceKeypadInput = !this.isSubmitedSelfServiceKeypadInput;
    this.selfServiceKeypadInputVal = newInput;
  },
  setCashierName(cashierName: string) {
    this.cashierName = cashierName;
  },
  setRouterState(route: PositiveTS.Enums.RouteState) {
    this.route = route;
  },
  setRouterExtraData(payload: any) {
    this.routerExtraData = payload
  },
  setMobilePosNavState(payload: PositiveTS.Components.PosViewState) {
    this.mobilePosNavState = payload;
  },
  setMultipassPendingLoadBudget(payload: any) {
    this.multipassPendingLoadBudget = payload;
    localStorage.setItem("multipassPendingLoadBudget", JSON.stringify(payload));
  },
  cleanMultipassPendingLoadBudget() {
    this.multipassPendingLoadBudget = null;
    localStorage.setItem("multipassPendingLoadBudget", JSON.stringify(null));
  },
  pinpadTransactionRunning(payload: boolean) {
    this.pinpadTransactionRunning = payload;
  },
  setIsLoadingMessageActive(payload: boolean) {
    this.isLoadingMessageActive = payload
  },
  setPosTabMode(payload: boolean) {
    this.posTabMode = payload;
  },
  setOnSelfServicePaymentDialog(payload: boolean) {
    this.onSelfServicePaymentDialog = payload
  },
  setCurrency(payload: string) {
    this.currency = payload
  },
  setSelectedVoucherType(payload: any) {
    this.selectedVoucherType = payload
  },
  setsyncServerOnlineState(payload: number) {
    console.log(`SYNC SERVER online state: ${payload} (${['disconnected', 'making auth', 'connected'][payload]})`);
    this.syncServerOnlineState = payload;
  },
  setPosCustomerNameData(payload: any) {
    this.posCustomerNameData = payload
  },
  setSplittedSalePaymentInfo(payload: any) {
    this.splittedSalePaymentInfo = payload
  },
  setSplittedSalePaymentDialogInfo(payload: any) {
    this.splittedSalePaymentDialogInfo = payload
  },
  setSplitPaymentData(payload: any) {
    this.splitPaymentData = payload
  },
  setCurrentPage(payload: any) {
    this.currentPage = payload
  },
  setLeftAmount(payload: number) {
    this.leftAmount = payload
  },
  setEmvPosNumber(payload: string) {
    this.emvPosNumber = payload;
  },
  setSupportNumber(payload: string) {
    this.supportNumber = payload;
  },
  setPaymentCurrency(payload: string) {
    this.paymentCurrency = payload;
  },
  setEmvTimeout(payload: number) {
    this.emvTimeout = payload;
  },
  setAtmTimeout(payload: number) {
    this.atmTimeout = payload;
  },
  setStreets(payload: any) {
    this.streets = payload;
  },
  setCities(payload: any) {
    this.cities = payload;
  },
  setLastCatalogUpdate(payload: string) {
    if (payload) {
      this.lastCatalogUpdate = payload;
      localStorage.setItem("catalogLastUpdate", payload);
    }
  },
  setBlockUIStatus(payload: { blocked: boolean, message: string, blockEvents: boolean }) {
    this.uiBlocked = payload.blocked;
    this.uiBlockedEvents = payload.blockEvents;
    this.uiBlockMessage = payload.message;
  },
  setAvoidNextLogin(payload: boolean) {
    this.avoidNexLogin = payload;
    localStorage.setItem("avoidNextLogin", JSON.stringify(payload));
  },
  isSlowConnection(payload: boolean) {
    this.slowConnection = payload;
  },
  setSaleApproval(payload: boolean) {
    this.isSaleApproved = payload
  },
  cashierLogin() {
    this.isCashierLoggedIn = true;
  },
  cashierLogout() {
    this.isCashierLoggedIn = false;
  },
  cashierHasToChangePassword(payload: boolean) {
    this.cashierHasToChangePassword = payload;
  },
  setPunchCardMode(payload: boolean) {
    if (payload) {
      localStorage.setItem("is_punch_card_mode", "true")
    }
    else {
      localStorage.setItem("is_punch_card_mode", "")
    }
    this.isPunchCardMode = payload;
  },
  setPromotions(payload: Array<PositiveTS.Promotions.PromoGroup>) {
    if (payload == null) {
      this.promoGroups = []
    }
    else {
      this.promoGroups = payload;
    }
  },
  setIsNirDavidEntranceSale(payload: boolean) {
    this.isNirDavidEntranceSale = payload
  },

  setShowNirDavidEntranceDialog(payload: boolean) {
    this.showNirDavidEntranceDialog = payload
  },
  setDeliveryErrorExists(payload = true) {
    this.deliveryErrorExists = payload;
  },
  setFaildDeliveries(payload: Array<any>) {
    this.failedDeliveriesOrders = payload;
  },
  addFaildDelivery(payload: any) {
    this.failedDeliveriesOrders.push(payload);
  },

  setCurrentWeightOnScale(payload: number) {
    this.currentWeightOnScale = payload;
  },
  setHasToPerformZ(payload: boolean) {
    this.hasToPerformZ = payload;
  },
  setWithdrawalSale(payload: boolean) {
    this.isWithdrawalSale = payload;
  },
  setPickupSale(payload: boolean) {
    this.isPickupSale = payload;
  },
  setPasswordProtected(payload: boolean) {
    this.passwordProtected = payload;
  },
  setCashierStatementType(payload: string) {
    this.cashierStatementType = payload;
  },
  setRoshemet(payload: boolean) {
    this.isRoshemet = payload;
  },
  setNewExternalOrdersIndicator(payload: boolean) {
    this.newExternalOrdersIndicator = payload;
  },
  setDeliveriesIndicator(payload: number) {
    this.deliveriesIndicator = payload;
  },
  setExternalOrderIndicator(payload: number) {
    this.externalOrderIndicator = payload;
  },
  setPosState(payload: string) {
    this.posState = payload;
  },
  OfflineSalesChanged(payload: number) {
    this.offlineSaleCount = payload;
  },
  removeXFieldFromArrays(currentXfields, xFields) {
    if (!xFields) return xFields;
    const removeItemFromArray = (array, item) => {
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    };
    for (let currentXfield of currentXfields) {
      xFields.successfullXFields = removeItemFromArray(xFields.successfullXFields, currentXfield);
      xFields.failedXFields = removeItemFromArray(xFields.failedXFields, currentXfield);
      xFields.unknownXFields = removeItemFromArray(xFields.unknownXFields, currentXfield);
    }

    return xFields;
  },
  removeXField(payload: string[]) {
    let xFields = JSON.parse(localStorage.getItem("XFields"));
    xFields = this.removeXFieldFromArrays(payload, xFields);
    localStorage.setItem("XFields", JSON.stringify(xFields))
  },
  activateExternalDeliveryOrders() {
    this.isExternalDeliveryOrdersActive = true
  },
  setRtlMode(payload: boolean) {
    this.rtlMode = payload;
    this.saleItemsTableViewMessage = i18next.t("emptyTableViewMessage");
    if (payload) {
      this.currency = "ILS";
    }
    else {
      this.currency = "EUR";
    }

  },
  portraitModeChange(payload: boolean) {
    this.portraitMode = payload;
  },
  mobileLayoutChange(payload: boolean) {
    this.mobileLayout = payload;
    if (payload) {
      document.documentElement.classList.add('positive-mobile-layout')
    }
    else {
      document.documentElement.classList.remove('positive-mobile-layout')
    }
  },
  saleItemSelected(payload: PositiveTS.Storage.Entity.SaleItem) {
    this.selectedSaleItem = payload;
    if (payload) {
      this.isSpecialItemSelected = PositiveTS.Service.SmartVoucher.isSpecialItemCode(this.selectedSaleItem.itemCode);
      this.isSelectedSpecialItemEligibleForDiscount = PositiveTS.Service.SmartVoucher.discountAllowedOnSpecialItem(this.selectedSaleItem.itemCode);
    }
    else {
      this.isSpecialItemSelected = false;
      this.isSelectedSpecialItemEligibleForDiscount = false;
    }
  },

  setMultipleSaleItemSelections(payload: Array<PositiveTS.Storage.Entity.SaleItem>) {
    this.multipleSelectedSaleItems = payload
    let hasSpecialItem = payload.some(item => PositiveTS.Service.SmartVoucher.isSpecialItemCode(item.itemCode))
    let discountAllowed = payload.some(item => PositiveTS.Service.SmartVoucher.discountAllowedOnSpecialItem(item.itemCode));

    this.isSpecialItemSelected = hasSpecialItem;
    this.isSelectedSpecialItemEligibleForDiscount = discountAllowed;
  },

  updateItemFromMultipleSaleItems(payload: PositiveTS.Storage.Entity.SaleItem) {
    let indexFromSelectedItems = this.multipleSelectedSaleItems.find(saleItem => saleItem.rowNumber == payload.rowNumber)

    if (indexFromSelectedItems > -1) {
      this.multipleSelectedSaleItems[indexFromSelectedItems] = payload
    }
  },

  setallowedActionsForSelectedItemsCounterer(payload) {
    this.allowedActionsForSelectedItemsCounter = payload
  },
  setIsSelfServiceTa(payload: boolean) {
    this.isSelfServiceTa = payload;
  },
  saleTotalUpdated(payload: number) {
    this.saleTotal = payload;
  },
  saleUpdated(payload: {
    items: Array<PositiveTS.Storage.Entity.SaleItem>,
    sale: PositiveTS.Storage.Entity.Sale,
    payments: Array<PositiveTS.Storage.Entity.SalePayment>,
    updatedOrAddedRows: Map<number, PositiveTS.Storage.Entity.SaleItem>,
    deletedRow: PositiveTS.Storage.Entity.SaleItem,
    saleTotal: number
  }) {
    this.saleUpdatedTriggered = !this.saleUpdatedTriggered
    this.totalAmount = payload.saleTotal;
    this.saleTotal = payload.saleTotal;
    this.salePayments = [...payload.payments];
    let isNewSale = (this.sale == null || this.sale.id != payload.sale.id);

    let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)
    let selectLastItem = (payload.items.length != this.saleItems.length);

    if (!selectLastItem && !allowMultipleSaleItemsSelection) {
      for (let origItem of this.saleItems) {
        let newItem = payload.items.filter((item) => { return (origItem.rowNumber == item.rowNumber) })[0]
        if (newItem && !newItem.equals(origItem)) {
          this.selectedSaleItem = newItem;

          break;
        }
      }
    } else if (!selectLastItem && allowMultipleSaleItemsSelection) {
      for (let origItem of this.saleItems) {
        let newItem = payload.items.filter((item) => { return (origItem.rowNumber == item.rowNumber) })[0]
        if (newItem && !newItem.equals(origItem)) {
          if (this.multipleSelectedSaleItems.length == 1) {
            Pinia.globalStore.clearSelectedItemsAndAllowedActions()
            Pinia.globalStore.addToSelectedItems(newItem)
          } else {
            Pinia.globalStore.updateItemFromMultipleSaleItems(newItem)
          }
        }
      }
    }
    else {
      let lastItem = posVC.saleItems[posVC.saleItems.length - 1]

      if (allowMultipleSaleItemsSelection) {
        Pinia.globalStore.clearSelectedItemsAndAllowedActions()
      } else {
        this.selectedSaleItem = _.cloneDeep(lastItem);
      }

    }

    if (payload.items && payload.items.length == 1 && PositiveTS.Service.SmartVoucher.isSpecialItemCodeGiftCard(payload.items[0].barcode)) {
      this.isSaleGiftCard = true;
    } else {
      this.isSaleGiftCard = false;
    }

    if (payload.items.length > 0) {
      this.isSpecialItemCode = PositiveTS.Service.SmartVoucher.isSpecialItemCode(payload.items[0].itemCode);
      if (allowMultipleSaleItemsSelection && this.multipleSelectedSaleItems.length > 0) {
        this.isSpecialItemSelected = this.multipleSelectedSaleItems.some(saleItem => PositiveTS.Service.SmartVoucher.isSpecialItemCode(saleItem.itemCode))
        this.isSelectedSpecialItemEligibleForDiscount = this.multipleSelectedSaleItems.some(saleItem => PositiveTS.Service.SmartVoucher.discountAllowedOnSpecialItem(saleItem.itemCode));
      }
      if (!allowMultipleSaleItemsSelection && this.selectedSaleItem) {
        this.isSpecialItemSelected = PositiveTS.Service.SmartVoucher.isSpecialItemCode(this.selectedSaleItem.itemCode);
        this.isSelectedSpecialItemEligibleForDiscount = PositiveTS.Service.SmartVoucher.discountAllowedOnSpecialItem(this.selectedSaleItem.itemCode);
      }
    } else {
      this.isSpecialItemCode = false;
      this.isSpecialItemSelected = false;
      this.isSelectedSpecialItemEligibleForDiscount = false;
    }

    this.isDeliverySale = payload.sale.isDelivery;
    if (isNewSale) {
      while (this.saleItems.length) {
        this.saleItems.pop()
      }
    }



    this.totalQuantity = 0
    let saleCopy = new PositiveTS.Storage.Entity.Sale()
    saleCopy.importFromObject(payload.sale.exportToObject())
    this.sale = saleCopy;
    this.sale.id = payload.sale.id;
    this.sale.saleRemarks = saleCopy.saleRemarks

    var saleJsonData: any = {};
    if (this.sale.jsondata) {
      saleJsonData = JSON.parse(posVC.sale.jsondata);
    }
    if (saleJsonData.customer && saleJsonData.customer.clubName === PositiveTS.Service.Hakafa.CLUB_IDENTIFIER) {
      this.hakafaCustomer = saleJsonData.customer;
    } else {
      this.hakafaCustomer = null;
    }

    if (!isNewSale) {
      if (payload.deletedRow != null) {
        this.saleItems = this.saleItems.filter(si => si.rowNumber != payload.deletedRow.rowNumber)
      }

      for (let i = 0; i < this.saleItems.length; i++) {
        //TODO: items should be reactive - this is the only way for the POS to be performant.

        //TODO: on one hand - it is not *correct* to have all the posVC.saleItems become reactive (and update the view before they are actually persisted!)
        //TODO: on the other hand - cloning each item every time is a performance penalty - when all the POS will use Vuex there won't be a need for cloning,
        //TODO: as only Actions will commmit  mutations and only mutations will change the items...
        //TODO: third option is to clone only the changed items - but i fear this this will be even more expensive as we'll have to compare each element.

        //TODO: (12/2019) - seems like all the items are already reactive - we don't really need to clone them anymore
        //                  but I am to scared to remove the clone - we should do it at a later version and run a full test suite

        let rowNumber = this.saleItems[i].rowNumber;
        let itemToUpdate = payload.updatedOrAddedRows.get(rowNumber);

        if (itemToUpdate != null) {
          this.saleItems[i] = _.clone(itemToUpdate) // this.saleItems[i] = _.clone(itemToUpdate);
          payload.updatedOrAddedRows.delete(rowNumber)
        }

      }
      payload.updatedOrAddedRows.forEach(saleItem => { //should be only one item in this case!!!
        this.saleItems.push(_.clone(saleItem))//$.extend(false,{},saleItem))
      })

    }


    let tmpMap = new Map<String, number>()

    for (let item of payload.items) {


      if (isNewSale) {
        this.saleItems.push(_.clone(item))//$.extend(false,{},item));
      }
      // this.saleItems.push(item);


      if (item.quantity > 0 && !PositiveTS.Service.Tip.isTipItemCode(item.itemCode)) {
        if (item.hasWeight) {
          this.totalQuantity += 1;
        }
        else {
          this.totalQuantity += item.quantity;
        }
        let currQty = tmpMap.get(item.itemCode);
        currQty ? tmpMap.set(item.itemCode, currQty + item.quantity) : tmpMap.set(item.itemCode, item.quantity)
      }
    }
    this.codeToQtyMap = tmpMap; //trigger reactivity update
    this.totalQuantity = Math.round(this.totalQuantity * 1000) / 1000

    this.cibusTenbis = PositiveTS.Storage.Entity.SalePayment.getTenbisPluxeeAmount(payload.payments);
    this.holdSaleAvailable = !(payload.payments.length > 0 || this.saleItems.length === 0)
    this.releaseSaleAvailable = payload.payments.length == 0;

  },
  migvanUpdated(payload: number) {
    this.effectiveMigvanId = payload;
  },
  paymentScreenOpen(payload: boolean) {
    this.isTenbisOrCibus = payload;
  },
  setHakafaDisabled(payload: boolean) {
    this.hakafaDisabled = payload
  },
  setReopenQuicksaleOnNewSale(payload: boolean) {
    this.isReopenQuicksaleOnNewSale = payload
  },
  setCustomerMizdamen() {
    this.customerText = "לקוח מזדמן"
  },
  setCustomerHakafa(payload) {
    this.customerText = `${payload.s_first_name} ${payload.s_last_name} - יתרה לשימוש: ${Math.round((payload.obligo + payload.amount) * 100) / 100}`
  },
  setPositiveCustomerInSale(payload) {
    this.positiveCustomerInSale = payload
  },
  setCustomerCompensation(payload) {
    this.customerCompensation = payload;
  },
  cleanSale(payload) {
    this.customerText = ""

    if (posUtils.isNullOrUndefinedOrEmptyString(payload)) {
      this.saleItemsTableViewMessage = i18next.t("emptyTableViewMessage");
    }
    else {
      this.saleItemsTableViewMessage = payload;
    }
    this.sale = null;
    while (this.saleItems.length) {
      this.saleItems.pop()
    }
    this.selectedSaleItem = null;
    this.multipleSelectedSaleItems = []
  },
  setIsScreenLocked(payload: boolean) {
    jsonConfig.setVal(jsonConfig.KEYS.isScreenLocked, payload)
    this.isScreenLocked = payload;
  },
  setBeengoState(payload: PositiveTS.Storage.Entity.Store) {
    this.isBeengoSupported = !posUtils.isNullOrUndefinedOrEmptyString(payload.beengoBranchId) &&
      !posUtils.isNullOrUndefinedOrEmptyString(payload.beengoBusinessId)
  },
  setCaveretPaymentState(payload: boolean) {
    this.isCaveretPayment = payload;
  },
  setIsDeliverySale(payload: boolean) {
    this.isDeliverySale = payload;
  },
  setSearchMode(payload: string) {
    this.searchMode = payload;
    window.localStorage.setItem(positiveSearchModeLocalStrageKey, payload)
  },
  setEmvRecoveryNeeded(payload: boolean) {
    this.emvRecoveryNeeded = payload
    window.localStorage.setItem("emvRecoveryNeeded", String(payload))
  },
  setEmvRecoveryNeededWhenOnline(payload: boolean) {
    this.emvRecoveryNeededWhenOnline = payload
    window.localStorage.setItem("emvRecoveryNeededWhenOnline", String(payload))
  },
  setItemWithDialogXL(payload: boolean) {
    this.itemWithDialogXL = payload;
  },
  setSimpleSelfService(payload: boolean) {
    this.simpleSelfService = payload;

    if (this.simpleSelfService) {
      document.body.classList.add('food-self-service');
      document.documentElement.style.setProperty('--menu-button-flex', '0 0 calc(50% - 4px)');
      document.documentElement.style.setProperty('--menu-button-height', '150px');
      document.documentElement.style.setProperty('--menu-button-font-size', '35px');

    }
    else {
      // document.documentElement.style.setProperty('--menu-button-flex','0 0 33.333%');
      // document.documentElement.style.setProperty('--menu-button-height','80px');
      document.body.classList.remove('food-self-service');
      document.documentElement.style.setProperty('--menu-button-font-size', '17px');
    }
  },
  setLockSelfServicePos(payload: boolean) {
    this.isSelfServicePosLoked = payload;
    localStorage.setItem(isSelfServicePosLoked, JSON.stringify(payload))
  },
  setDarkThemeMode(payload: boolean) {
    this.darkThemeMode = payload;
    if (this.darkThemeMode) {
      document.documentElement.style.setProperty('--primary-default', '#005C7A');
      document.documentElement.style.setProperty('--primary-active', '#004A63');
      document.documentElement.style.setProperty('--text-on-background-color', 'white');
      document.documentElement.style.setProperty('--text-on-light-background-color', 'black');
      document.documentElement.style.setProperty('--success-default', '#009713');
      document.documentElement.style.setProperty('--success-default', '#007B0F');
      document.documentElement.style.setProperty('--danger-default', '#C30A00');
      document.documentElement.style.setProperty('--danger-active', '#9F0800');
      document.documentElement.style.setProperty('--secondary-text', '#333');
      document.documentElement.style.setProperty('--additional-default', '#C36400');
      document.documentElement.style.setProperty('--additional-default-border', '#9F5200');
      document.documentElement.style.setProperty('--primary-active', '#003648');
      document.documentElement.style.setProperty('--primary-default', '#004A63');
      document.documentElement.style.setProperty('--primary-default', '#0097C9');
      document.documentElement.style.setProperty('--primary-default-border', '#007196');
      document.documentElement.style.setProperty('--disabled-color', '#c5c5c5');
      document.documentElement.style.setProperty('--input-bg-color', '#FFFFFF');
    }
  },
  setOpenPaymentsForTip(payload: boolean) {
    this.openPaymentsForTip = payload;
  },
  setRoshemetBigLayout(payload: boolean) {
    this.useRoshemetBigLayout = payload;
    if (payload) {
      document.body.classList.add("big");
    }
    else {
      document.body.classList.remove("big");
    }


  },
  onlineStatusChanged(payload: boolean) {
    this.isOnline = payload;
    localStorage.setItem('isOnline', String(payload));
  },

  handleShekuloTovBarcodes(payload: any) {
    let barcodesData = JSON.parse(localStorage.getItem('shekuloTovBookBarcodes')) || []

    switch (payload.type) {
      case "insert":
        barcodesData.push(payload.item)
        break;
      case "remove":
        barcodesData = barcodesData.filter(bookItem => { return bookItem.rowNumber !== payload.rowNumber })
        break;
      case "clear":
        barcodesData = []
        break;
    }
    this.shekuloTovBookBarcodes = barcodesData
    localStorage.setItem('shekuloTovBookBarcodes', JSON.stringify(barcodesData))
  },
  setOutOfStockItemsCodes(payload: string[]) {
    this.outOfStockItemsCodes = payload;
  },
  setOtotSmartCardLoadedNumber(payload: string) {
    this.ototSmartCardLoadedNumber = payload;
  },
  setOtotSaleBracelet(payload: PositiveTS.Types.OtotBracelet) {
    this.ototSaleBracelet = payload;
  },
  setSelfServiceBackgroundImageActivated(isActive) {
    this.selfServiceBackgroundImageActivated = isActive;
  },
  setDefaultImages(defaultImages) {
    this.defaultImages = defaultImages;
  },

  setStoreLogoUrl(storeLogoUrl) {
    this.storeLogoUrl = storeLogoUrl;
  },
  setEMVDisableClick(payload) {
    this.EMVDisableClick = payload;
  },
  setEMVisAsync(payload) {
    this.EMVisAsync = payload;
  },
  setEMVHideLoader(payload) {
    this.EMVHideLoader = payload;
  },
  async login({ changePass, employeeID, employeeName }) {
    session.pos.employeeID = employeeID;
    session.pos.employeeName = employeeName;
    $('.header-cashier').text(employeeName);
    session.pos.persist();
    this.cashierLogin();

    if (session.pos.isCaveret) {
      PositiveTS.Service.CaveretFiles.eraseNotInUsePosFlag();
      //update local DB if needed
      if (this.isOnline && !this.slowConnection) {
        try {
          let serverLastCatalogDate = (await Posimod.Service.FetchReq.jsonReq("/catalog/last_update_date?new_version=true", "GET")).result.catalog_date
          if (moment(this.lastCatalogUpdate).isBefore(serverLastCatalogDate)) {
            if (!changePass) this.setAvoidNextLogin(true);
            statsVC.reloadAllData(false);
            return;
          }
        } catch (err) {
          console.error(err);
        }
        this.cashierHasToChangePassword(changePass);
      }
    }

    this.isSlowConnection(false);
    await PositiveTS.Storage.Entity.Sale.updateCashierToOpenSale(session.pos.employeeID, session.pos.employeeName);
  },
    async zReportCompleted(zReport: PositiveTS.Storage.Entity.ZReport) {
      if (!jsonConfig.getVal(jsonConfig.KEYS.dontEraseEnterStatementOnZ)) {
        PositiveTS.Service.CashierStatement.setLastEnterCashierStatement("0");
        let multiCurr = PositiveTS.Service.MultiCurr.getInstance();
        if (multiCurr.isMultiCurr()) {
          PositiveTS.Service.CashierStatement.resetLastEnterCashierStatementCurrencies();
        }
      }

      if (jsonConfig.getVal(jsonConfig.KEYS.passwordProtected)) {
        session.pos.employeeID = null;
        session.pos.employeeName = null;
        $('.header-cashier').text("");
        session.pos.persist();
      }

      this.setHasToPerformZ(false);

      if (session.pos.isCaveret) await PositiveTS.Service.CaveretFiles.createNotInUsePosFlag();
      PositiveTS.Service.CashierStatement.resetCashSumWithdrawnToSafe(0);
      PositiveTS.Service.CashierStatement.deleteCashCurrencuesSumWithdrawnToSafe();
    },
  clearSplitPaymentData() {
    this.setSplitPaymentData(null);
  },
  addSplittedPayment({ amount, type }) {
    if (posUtils.isBlank(amount)) {
      amount = 1;
    }

    let data = this.splitPaymentData;

    data.quantity -= amount;
    if (posUtils.isBlank(data.paymentData[type])) {
      data.paymentData[type] = amount;
    } else {
      data.paymentData[type] += amount;
    }

    this.setSplitPaymentData(data);
  },
  removeSplittedPayment({ amount, type }) {
    let data = this.splitPaymentData;

    if (posUtils.isBlank(data.paymentData[type])) {
      return;
    }

    if (posUtils.isBlank(amount) || amount > data.paymentData[type]) {
      amount = data.paymentData[type];
    }

    data.quantity += amount;
    data.paymentData[type] -= amount;

    if (data.paymentData[type] <= 0) {
      delete data.paymentData[type];
    }

    this.setSplitPaymentData(data);
  },
  initSplitPayment( amount) {
    let data = { quantity: amount, paymentData: {} };

    this.setSplitPaymentData(data);
  },
    async addSaleRemarks(saleRemark) {
    posVC.sale.saleRemarks = saleRemark
    await posVC.saleUpdated()
  },

  selectSaleItem(saleItem) {
    let actionIsSelect = true
    let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)

    if (allowMultipleSaleItemsSelection) {
      let selectedSaleItems = this.multipleSelectedSaleItems
      if (saleItem) {
        let itemIndexFromSelectedItems = selectedSaleItems.findIndex(item => item.rowNumber == saleItem.rowNumber)
        actionIsSelect = itemIndexFromSelectedItems == -1
        let isChild = saleItem.parentRowNumber >= 0
        let isChildItemAndParentIsAlreadySelected = false
        let isParentItemAndChildIsAlreadySelected = false

        let index = 0

        while (actionIsSelect && !isChildItemAndParentIsAlreadySelected && !isParentItemAndChildIsAlreadySelected && index <= selectedSaleItems.length) {
          let selectedSaleItem = selectedSaleItems[index]
          isChildItemAndParentIsAlreadySelected = saleItem.level > 0 && selectedSaleItem?.rowNumber == saleItem.parentRowNumber
          isParentItemAndChildIsAlreadySelected = saleItem.level == 0 && selectedSaleItem?.parentRowNumber == saleItem.rowNumber

          index += 1
        }

        if (isChildItemAndParentIsAlreadySelected) {
          return
        }

        if (isParentItemAndChildIsAlreadySelected) {
          let childItems = selectedSaleItems.filter(selectedItem => selectedItem.parentRowNumber == saleItem.rowNumber)
          for (const childItem of childItems) {
            this.removeFromSelectedItems(childItem)
          }
        }

        if (actionIsSelect) {
          if (isChild && saleItem.parentRowNumber > 2000) {
            console.error('cant select grandchild item')

            return
          }

          this.addToSelectedItems(saleItem)
        }
        else {
          this.removeFromSelectedItems(saleItem)
        }
      } else {
        this.clearSelectedItemsAndAllowedActions()
      }
    }
    else {
      this.saleItemSelected(saleItem)
    }

  },

  addToSelectedItems(saleItem) {
    let selectedSaleItems = this.multipleSelectedSaleItems
    selectedSaleItems.push(saleItem)
    let allowedActionsForSelectedItemsCounter = this.allowedActionsForSelectedItemsCounter

    for (const key in allowedItemActionsConfig) {
      if (allowedItemActionsConfig[key].checkFunction(saleItem)) {
        allowedActionsForSelectedItemsCounter[key] += 1
      }

    }
    this.setallowedActionsForSelectedItemsCounterer(allowedActionsForSelectedItemsCounter)
    this.setMultipleSaleItemSelections(selectedSaleItems)
  },
    async lockSelfServiceByOpeningHours() {
    try {

      let weeklyOpeningHours: Array<any> = [];
      let posOpeningHours = jsonConfig.getVal(jsonConfig.KEYS.selfServiceOpeningHours);
      let storeOpeningHours = JSON.parse(session.pos.storeSelfServiceOpeningHours);
      if (posOpeningHours?.length > 0) {
        weeklyOpeningHours = posOpeningHours
      } else if (storeOpeningHours?.length > 0) {
        weeklyOpeningHours = storeOpeningHours;
      }

      if (weeklyOpeningHours?.length > 0) {
        weeklyOpeningHours.forEach(dayilyOpeningHours => {
          let fromHourToLock;
          let toHourToUnlock;
          let dayilyHours: [] = dayilyOpeningHours.hours
          if (dayilyOpeningHours.dayStatus == 'close') {
            fromHourToLock = '00:00'
            toHourToUnlock = '23:59'
            PositiveTS.Service.ScheduledJobs.scheduleOnceAWeek(fromHourToLock, dayilyOpeningHours.dayName, () => { this.lockSelfServicePos() })
            PositiveTS.Service.ScheduledJobs.scheduleOnceAWeek(toHourToUnlock, dayilyOpeningHours.dayName, () => { this.unLockSelfServicePos() })
          } else if (dayilyOpeningHours.dayStatus == 'manual_setting' && dayilyHours) {
            dayilyOpeningHours.hours.forEach(hours => {
              fromHourToLock = hours.from_hour
              toHourToUnlock = hours.to_hour
              PositiveTS.Service.ScheduledJobs.scheduleOnceAWeek(fromHourToLock, dayilyOpeningHours.dayName, () => { this.lockSelfServicePos() })
              PositiveTS.Service.ScheduledJobs.scheduleOnceAWeek(toHourToUnlock, dayilyOpeningHours.dayName, () => { this.unLockSelfServicePos() })
            })
          }
          if (!Pinia.globalStore.isSelfServicePosLoked && dayilyOpeningHours.dayStatus != 'open') {
            const today = new Date();
            const currentDay = today.getDay();
            let currentMinutes = String(today.getMinutes()).padStart(2, "0")
            let currentHours = String(today.getHours()).padStart(2, "0")
            let ScheduledDay = Number(PositiveTS.Service.ScheduledJobs.WeekDays[dayilyOpeningHours.dayName]);
            let isCurrentTimeInRangeOfLockTime = PositiveTS.Service.ScheduledJobs.isInRange(`${currentHours}:${currentMinutes}`, [fromHourToLock, toHourToUnlock]);

            let isCurrentTimeToLock = (currentDay == ScheduledDay) && isCurrentTimeInRangeOfLockTime;
            if (isCurrentTimeToLock) {
              this.lockSelfServicePos()
            }
          }

        })
      }
    } catch (err) {
      console.error(err)
    }
  },
  startSelfServiceOfflineLockManagment: _.debounce(function () {
    Pinia.globalStore.checkSelfServiceOfflineMode();
  }, 20000),
    async reportOfflineIfNeeded() {
    let lockManager = JSON.parse(localStorage.getItem('selfServiceLockManager')) || [];
    if (!Pinia.connectivityStore.isOnline) {
      this.deactivateScreenSaver = true
      this.lockSelfServicePos('posOffline')
    } else if(lockManager.includes('posOffline')){
      this.deactivateScreenSaver = false
      this.unLockSelfServicePos('posOffline')
    }
  },
  async reportPinPadOnErrorIfNeeded() {

    if (!localStorage.getItem('lastEMVErrorCode') && Boolean(jsonConfig.getVal(jsonConfig.KEYS.isLockedPinpadSelfService))) {
      this.isLockedPinpadSelfService = true
      localStorage.removeItem(isMessageSentOnPinpadFault)
    }
    if (this.isLockedPinpadSelfService) {
      let isFirstLock = !localStorage.getItem(isMessageSentOnPinpadFault);
      if (localStorage.getItem('lastEMVErrorCode')) {
        if (!localStorage.getItem(isMessageSentOnPinpadFault)) {
          localStorage.setItem(isMessageSentOnPinpadFault, 'true')
          let activityMessage = i18next.t("posAlertLog.selfServicePinpadConnectionProblem", { deviceID: session.pos.deviceID })
          await PositiveTS.Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(PositiveTS.Shared.Constants.SuspiciousActions.SELF_SERVICE_PINPAD_CONNECTION_PROBLEM, null, null, null, activityMessage);
        }

        try {
          await PositiveTS.Service.EMV.checkIfRecoveryNeeded(false)
        } catch (err) {
          if (isFirstLock) {
            PositiveTS.Service.Logger.error(err);
          }
        }
        this.deactivateScreenSaver = true
        this.lockSelfServicePos()
      } else {
        this.deactivateScreenSaver = false
        this.unLockSelfServicePos()
      }
    }
  },
    async checkSelfServiceOfflineMode() {
    try {
      this.reportOfflineIfNeeded()
      if (Pinia.connectivityStore.isOnline) {
        await this.reportPinPadOnErrorIfNeeded()
      }
    } catch (err) {
      console.error(err)
    }
    this.startSelfServiceOfflineLockManagment();
  },
    async lockSelfServicePos(lockedBy = null) {
    try {

      if(lockedBy){
        let lockManager = JSON.parse(localStorage.getItem('selfServiceLockManager')) || [];
        if (!lockManager.includes(lockedBy)) {
          lockManager.push(lockedBy);
          localStorage.setItem('selfServiceLockManager', JSON.stringify(lockManager));
        }
      }

      let selfServiceEnableScreenSaver = jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableScreenSaver);
      let deactivateScreenSaverSelfServiceLock = this.deactivateScreenSaver
      if (selfServiceEnableScreenSaver) {
        if (deactivateScreenSaverSelfServiceLock) {
          PositiveTS.VueInstance.$refs.selfServiceScreenSaver.clearScreenSaverTimeout();
          PositiveTS.VueInstance.$refs.selfServiceScreenSaver.close()
        } else {
          PositiveTS.VueInstance.$refs.selfServiceScreenSaver.activateWithTimeout(15000)
        }
      }
      this.setLockSelfServicePos(true)
      await app.showAlertDialog({ content: i18next.t('pos.posNotAvailable'), hideContinueButton: true }, null)
        .catch(err => console.error(err));
      if (selfServiceEnableScreenSaver) {
        if (deactivateScreenSaverSelfServiceLock) {
          PositiveTS.VueInstance.$refs.selfServiceScreenSaver.clearScreenSaverTimeout();
          PositiveTS.VueInstance.$refs.selfServiceScreenSaver.close()
        } else {
          PositiveTS.VueInstance.$refs.selfServiceScreenSaver.activateWithTimeout(15000)
        }
      }
      let isApproved = await app.showManagerApprovalDialog();
      if (isApproved) {
        localStorage.setItem('selfServiceLockManager', JSON.stringify([]));
        if (localStorage.getItem('lastEMVErrorCode') && Boolean(jsonConfig.getVal(jsonConfig.KEYS.isLockedPinpadSelfService))) {
          this.isLockedPinpadSelfService = false;
          selfServiceTemporaryRelaeseLockDebounce();
        }
        this.setLockSelfServicePos(false)
        PositiveTS.Service.InactivityService.init()
      }
    } catch (err) {
      this.lockSelfServicePos()
    }
  },
    async unLockSelfServicePos(unLockedBy = null) {
    try {
      if(unLockedBy){
        let lockManager = JSON.parse(localStorage.getItem('selfServiceLockManager')) || [];
        let index = lockManager.indexOf(unLockedBy);
        if (index > -1) {
          lockManager.splice(index, 1);
          localStorage.setItem('selfServiceLockManager', JSON.stringify(lockManager));
        }else{
          return;
        }
      }

      let selfServiceEnableScreenSaver = jsonConfig.getVal(jsonConfig.KEYS.selfServiceEnableScreenSaver);
      let isLastLocked = this.isSelfServicePosLoked;
      this.setLockSelfServicePos(false)
      if (selfServiceEnableScreenSaver && isLastLocked) {
        PositiveTS.Service.InactivityService.init()
      }
      PositiveTS.VueInstance.$refs.alertDialog.close();
      PositiveTS.Service.GlobalUI.hideManagerApproval();
    } catch (err) {
      console.error(err)
    }
  },
  removeFromSelectedItems(saleItem) {
    let selectedSaleItems = this.multipleSelectedSaleItems
    selectedSaleItems = selectedSaleItems.filter(item => item.rowNumber !== saleItem.rowNumber)

    let allowedActionsForSelectedItemsCounter = this.allowedActionsForSelectedItemsCounter
    for (const key in allowedItemActionsConfig) {
      if (allowedItemActionsConfig[key].checkFunction(saleItem)) {
        allowedActionsForSelectedItemsCounter[key] += -1
      }

    }
    this.setallowedActionsForSelectedItemsCounterer(allowedActionsForSelectedItemsCounter)
    this.setMultipleSaleItemSelections(selectedSaleItems)
  },

  clearSelectedItemsAndAllowedActions() {
    let allowedActionsForSelectedItemsCounter = {}

    for (const key in allowedItemActionsConfig) {
      allowedActionsForSelectedItemsCounter[key] = 0
    }

    this.setallowedActionsForSelectedItemsCounterer(allowedActionsForSelectedItemsCounter)
    this.setMultipleSaleItemSelections([])
  },

  setOutOfStockItemsCodesAndSave(outOfStockItemCodes) {
    try {
      let data = JSON.stringify(outOfStockItemCodes);
      localStorage.setItem('outOfStockItems', data);
      PositiveTS.Service.OutOfStock.updateOutOfStock(JSON.parse(data));
      this.setOutOfStockItemsCodes(outOfStockItemCodes);
    } catch (err) {
      console.log(err)
    }
  },
  setVendingMachineOutOfStockItemCodesFactor(args: PositiveTS.Types.VendingMachineOutOfStockItemCodesArgs) {
    switch (args.action) {
      case "set": {
        this.setVendingMachineOutOfStockItemCodes(args.itemCodes);
        break;
      }
      case 'add': {
        let currentItemsOutOfStock: string[] = this.vendingMachineOutOfStockItemCodes;
        currentItemsOutOfStock.push(...args.itemCodes)
        this.setVendingMachineOutOfStockItemCodes(currentItemsOutOfStock);
        break;
      }
      case 'remove': {
        let currentItemsOutOfStock: string[] = this.vendingMachineOutOfStockItemCodes;
        currentItemsOutOfStock = currentItemsOutOfStock.filter(code => args.itemCodes.includes(code) == false);
        this.setVendingMachineOutOfStockItemCodes(currentItemsOutOfStock);
        break;
      }
    }
  },
  async loadVendingMachineOutOfStockItemsCodes() {
    let itemsInventory = await appDB.vendingMachineItemInventory.toArray();
    let vendingMachineItems: PositiveTS.Storage.Entity.VendingMachineItem[] = await (new PositiveTS.Storage.Entity.VendingMachineItem()).all()
    let hashInv: { [key: string]: PositiveTS.Storage.Entity.VendingMachineItemInventory } = _.keyBy(itemsInventory, 'itemCode');
    let itemCodes: string[] = [];
    for (const i of vendingMachineItems) {
      if (posUtils.isBlank(hashInv[i.itemCode]) || hashInv[i.itemCode].isOutOfStock) {
        itemCodes.push(i.itemCode);
      }
      if (hashInv[i.itemCode].currentInventory <= 0) {
        let itemInv = hashInv[i.itemCode];
        itemInv.isOutOfStock = true;
        await appDB.vendingMachineItemInventory.put(itemInv);
        itemCodes.push(i.itemCode);
      }
    }
    this.setVendingMachineOutOfStockItemCodes(itemCodes);
  },
  async loadOutOfStockItemsCodes() {
    let localStorageData = localStorage.getItem('outOfStockItems');
    let updatedOutOfStock = await PositiveTS.Service.OutOfStock.getUpdatedOutOfStockData();

    let outOfStockItemCodes = [];

    if (!posUtils.isBlank(updatedOutOfStock)) {
      outOfStockItemCodes = JSON.parse(updatedOutOfStock.data);
      outOfStockItemCodes = outOfStockItemCodes.filter(itemCode => session.allItems.get(itemCode));
    }
    else if (!posUtils.isBlank(localStorageData)) {
      outOfStockItemCodes = JSON.parse(localStorageData).filter(itemCode => session.allItems.get(itemCode))
    }

    this.setOutOfStockItemsCodes(outOfStockItemCodes)
  },

}

const initStore =()=>{
  Pinia.globalStore.standaloneMode = Boolean(jsonConfig.getVal(jsonConfig.KEYS.standaloneMode))
  Pinia.globalStore.mizdamenOrHakafaMode= Boolean(jsonConfig.getVal(jsonConfig.KEYS.mizdamenOrHakafaMode))
  Pinia.globalStore.askForClubMembershipeMode= Boolean(jsonConfig.getVal(jsonConfig.KEYS.askForClubMembershipeMode))
  Pinia.globalStore.isHaveToChooseClubMembership= Boolean(jsonConfig.getVal(jsonConfig.KEYS.isHaveToChooseClubMembership))
  Pinia.globalStore.isLockedPinpadSelfService= Boolean(jsonConfig.getVal(jsonConfig.KEYS.isLockedPinpadSelfService))
  Pinia.globalStore.voucherEnabled= Boolean(jsonConfig.getVal(jsonConfig.KEYS.showVoucher))
  Pinia.globalStore.isScreenLocked= Boolean(jsonConfig.getVal(jsonConfig.KEYS.isScreenLocked))
  Pinia.globalStore.showInvForItemButton= Boolean(jsonConfig.getVal(jsonConfig.KEYS.showInvForItemButton))
  Pinia.globalStore.bigPaymentButtons= Boolean(jsonConfig.getVal(jsonConfig.KEYS.bigPaymentButtons))
  Pinia.globalStore.fullClub= Boolean(jsonConfig.getVal(jsonConfig.KEYS.fullClub))
  Pinia.globalStore.allowSetNewCustomerDiscountPercent= Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSetNewCustomerDiscountPercent))
  Pinia.globalStore.showGiftForItemButton= Boolean(jsonConfig.getVal(jsonConfig.KEYS.showGiftForItemButton))
  Pinia.globalStore.isShowQuicksaleButton= Boolean(jsonConfig.getVal(jsonConfig.KEYS.isShowQuicksaleButton))
  Pinia.globalStore.blockItemPriceChange= Boolean(jsonConfig.getVal(jsonConfig.KEYS.blockItemPriceChange))
  Pinia.globalStore.allowInvoiceSplit= Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowInvoiceSplit))
  Pinia.globalStore.addressCityAndApartmentFieldsRequired= Boolean(jsonConfig.getVal(jsonConfig.KEYS.addressCityAndApartmentFieldsRequired))
  Pinia.globalStore.mobilePhoneRepairModule= Boolean(jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule))
  Pinia.globalStore.hideButtonMenu= Boolean(jsonConfig.getVal(jsonConfig.KEYS.hideButtonMenu))
  Pinia.globalStore.selfServiceSuperMarket= Boolean(jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos))
  Pinia.globalStore.usePictures= Boolean(jsonConfig.getVal(jsonConfig.KEYS.usePictures))
  Pinia.globalStore.saleItemsTableViewMessage = i18next.t("emptyTableViewMessage")
  Pinia.globalStore.isOnline = PositiveTS.Reachability.isOnline
  Pinia.globalStore.ototSaleBracelet= { bracelet: null, status: PositiveTS.Service.Otot.BRACELET_STATUS.NEW }

  for (const key in allowedItemActionsConfig) {
      Pinia.globalStore.allowedActionsForSelectedItemsCounter[key] = 0
  }


  if (!posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.emvPosNumber))) {
    Pinia.globalStore.emvPosNumber = String(jsonConfig.getVal(jsonConfig.KEYS.emvPosNumber)).padStart(3, "0");
  }


}


const useGlobalStore = defineStore('global', {
  state: () => ({ ...state }),
  getters: { ...getters },
  actions: { ...actions }
});

export {
  useGlobalStore,
  initStore
}
