module PositiveTS {
export module Printing {
export module HtmlPrinting {
    const TABLE_WIDTH = 340;
    const classes = {
        rightAlignment: 'right',
        centerAlignment: 'center',
        leftAlignment: 'left',
        underline:	'underline',
        bold: 'bold',
        regular: 'not-bold',
        bigFont: 'big-font',
        bigFontSunmi: 'big-font',
        bigAndBoldFont: 'bold big-font',
        noBigFont: 'regular-font',
        hugeFont:'big-font',
        superHugeFont:'super-huge-font'
    }
    var classesInUse = ``;
    var isGrid = false;
    
    export function print(content) {
        HtmlFactory.popupPrint(getPrintingHtml(content));
        classesInUse = "";
        isGrid = false;
    }

    export function getPrintingHtml(content, additionalClass = ''):string {
        let templateString = JST.HtmlPrinting();
        
        return _.template(templateString)({content: content, tableWidth: TABLE_WIDTH, additionalClass: additionalClass});
    }

    export function floatingLine(rightText:string, leftText:string) {
        if(rightText.split('').filter((char) => char == '-').length == rightText.length) {
            return ``;
        }
        if(leftText == "" || leftText == " ") {
            return line(rightText);
        }
        return `<div class="line line-float"><div class="floating ${classesInUse}"><div>${fixChars(rightText)}</div><div>${fixChars(leftText)}</div></div></div>`;
    }

    export function line(text:string) {
        if(String(text).split('').filter((char) => char == '-').length == text.length) {
            return ``;
        }
        if(isGrid && (text == " " || posUtils.isNullOrUndefinedOrEmptyString(text))) {
            return ``
        }
        return `<div class="line  ${classesInUse}">${fixChars(text)} </div>`
    }

    export function header(text:string) {
        return `<div class="line title  ${classesInUse}">${fixChars(text)}</div>`;
    }

    export function tableRow(cols:String[], widths:number[], classes: string[] = []) {
        let totalWidth = widths.reduce((total, width) => width + total, 0);
        widths = widths.map((width) => (width/totalWidth)*100);
        let columnsText = cols.reduce((text, col, colIndex) => `${text}<div style="width: ${widths[colIndex]}&percnt;"${classes[colIndex] && classes[colIndex].length ? (' class=\'' + classes[colIndex] + '\'') : ''}>${fixChars(col)}</div>`, ``)
        return `<div class="table-row  ${classesInUse}">${columnsText}</div>`;
    }

    export function applyComponent(componentCode:string) {
        let componentName = _.invert(printer.allowComponents)[componentCode];
        let apeended = '';

        if(!posUtils.isNullOrUndefined(classes[componentName])) {
            classesInUse = `${classesInUse} ${classes[componentName]}`;
        } 
        else if(componentName == 'init') {
            classesInUse = ``;
        } else if (componentName == 'newLine') {
            apeended += '<div class="line"></div>'
        } else if (componentName == 'cutLine') {
            apeended += printer.allowComponents.picturePrinterCutLine;
        }

        return apeended;
    }

    export function startReportTable() {
        printer.jzebra.append(`<div class="report-table">`);
    }

    export function endReportElement() {
        printer.jzebra.append(`</div>`);
    }

    export function startReportHeader() {
        printer.jzebra.append(`<div class="report-header">`)
    }

    export function startGriddedReport() {
        printer.jzebra.append(`<div class="report-grid">`);
        isGrid = true;
    }

    export function endReportGrid() {
        endReportElement();
        isGrid = false;
    }

    export function newPage() {
        let newPageString = printer.isA4() ? `<div class="line new-page"></div>` : ``;
        if(isGrid) {
            newPageString = `</div>${newPageString}<div class="report-grid">`;
        }
        printer.jzebra.append(newPageString);
    }

    export function getBoxTitle(text) {
        return `<div class="line title  ${classesInUse}">${tableRow([text], [1])}</div>`;
    }

    export function getBoxLine(rightText, leftText) {
        return tableRow([rightText, leftText], [1,1]);
    }

    function fixChars(text) {
        if(!posUtils.isBlank(text)) {
            text = String(text);
            text = text.replace(/%/g, '<span>%</span>');
            text = text.replace("(", ")").replace(")", "(");
            let words = text.split(" ");
            words = words.map((word) => word.replace(/^(?<!\d)((-[0-9,]+\.[0-9]+)|^(-[0-9,]+))$/, `<span dir="ltr">$1</span>`));
            return words.join(" ");
        }

        return '';
    }

}}}