module PositiveTS {
  export module Storage {
    export module Entity {
      export class PrimaryCategory extends WasmEntity {

        name: string
        sortOrder: number;

        constructor() {
          let meta = {
            name: 'PrimaryCategory',
            fields: {
              name: "TEXT",
              sortOrder: "INT",
            }
          };
          super(meta)
        }

        static getByIds(ids: Array<string>, orderBy = null): Promise<Array<PrimaryCategory>> {
          let order = orderBy ? ` order by ${orderBy}` : ''
          return this.getFromSql(`select * from primaryCategory where id in (${ids.join(',')})${order}`);
        }

        static getFromSql(sql:string, convertToEntity = true): Promise<Array<PrimaryCategory|any>> {
          return convertToEntity ? this.execAndConvertResult(sql) : Service.WasmDB.execAsObject(sql);
        }
      }
    }
  }
}