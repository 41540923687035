module PositiveTS {
    export module Components {
        export module KeypadBtn {
            export function getComponent() {
                return {
                    template: JST.KeypadBtn(),
                    props: {
                        btnHeight:{
                            type:String,
                            default: 'h-5vh max-pos:h-4.5vh t1:h-[4.5vh]  t2-mini:h-6vh p2-pro:w-20vw p2-lite:w-20vw'
                        },
                        btnWidth:{
                            type:String,
                            default: 'w-5.5vw max-pos:w-5vw t1:w-[4.5vw]'
                        },
                        txtSize:{
                            type:String,
                            default: 'text-lg t1:text-sm max-pos:text-sm'
                        },
                        btnBorder:{
                            type:String,
                            default: 'border border-neutral-200 border-solid shadow-keypad-btn rounded-md'
                        },
                        btnTransition:{ 
                            type:String,
                            default: 'active:gery active:text-white  active:transition active:duration-150 active:ease-in-out'
                        },
                        btnColor:{
                            type:String,
                            default: 'bg-light-grey' 
                        },
                        modelValue: {
                            default: undefined,
                            required: false,
                        },
                        showSubmitBtn:{
                            type:Boolean,
                            default:true
                        },
                        submitBtnCSS:{ 
                            type: String,
                            default:'text-white bg-grey max-pos:text-sm text-md'
                        },
                        removeBtnColor:{ 
                            type: String,
                            default:'text-black'
                        }
                    },
                    computed: {
                        useModelVal() {
                            return this.modelValue !== undefined;
                        },
                    },
                    methods: {
                        clear(){
                            let newVal = "";
                            this.$emit('update:modelValue', newVal);  
                        },
                        enterDigit(digit: string) {
                            let newVal = this.modelValue + digit;
                            let match = newVal.match(/^\d*(\.\d{0,2})?$/);
                            if (match){
                                let parts = match[0].split("");
                                if(parts[0] == '.'){
                                    match[0] = `0${match[0]}`
                                }
                            }
                            newVal = match ? match[0] : this.modelValue;
                            this.$emit('update:modelValue', newVal);
                        },
                        backspace() {
                            let newVal = this.modelValue.substr(0, this.modelValue.length - 1);
                            this.$emit('update:modelValue', newVal);                        },
                        submit() {
                            if(!this.showSubmitBtn){
                                return
                            }
                            this.$emit('submit', this.numberString);
                        },
                    },
                    async created() {},
                }
            }
        }
    }
}
