module PositiveTS {
  export module Components {
    export module PositiveVueSelect {

      export function getComponent() {
        return {
          template: JST.positiveVueSelect(),
          data() {
            return {
              id: null,
            }
          },
          
          emits: [
            'update:modelValue', 
            'search', 
            'search:focus', 
            'search:blur',
          ],

          props: {
            modelValue: {
            },

            options: {
              type: Array,
              default() {
                return []
              },
            },
            disabled: {
              type: Boolean,
              default: false,
            },
            clearable: {
              type: Boolean,
              default: true,
            },
            multiple: {
              type: Boolean,
              default: false,
            },
            placeholder: {
              type: String,
              default: '',
            },
            clearSearchOnSelect: {
              type: Boolean,
              default: true,
            },
            closeOnSelect: {
              type: Boolean,
              default: true,
            },
            label: {
              type: String,
              default: 'label',
            }, 
            dir: {
              type: String,
              default: 'auto',
            },
            noOptionsLabel: {
              type: String,
              default: null,
            },
            index: {
              type: String,
              default: null,
            },
            reduce: {
              type: Function,
              default: null,
            },
            taggable: {
              type: Boolean,
              default: false,
            },
            keyboard: {
              type: Boolean,
              default: false,
            },
            dataKeyboardIconFontSize: {
              type: String,
              default: null,
            },
            dataNosoftkeyboard: {
              type: Boolean,
              default: false,
            },
            dataOpenOnTop: {
              type: Boolean, 
              default: false,
            },
            filterable: {
              type: Boolean,
              default: true,
            },
          },
          
          methods: {
            keyboardBlurFunction(searchValue, originalBlurFunction) { // fix for the keyboard and blur of vue-select, because the v-keyboard and this may collapse and do problems
              if (searchValue && this.$refs.searchInput && Array.from(this.$refs.searchInput.classList).includes("ui-keyboard-input-current")) {
                return () => {};
              }

              return originalBlurFunction;
            }
          },
          computed: {
            getReduceFunc() {
              if (this.reduce) {
                return this.reduce;
              }

              if (this.index) {
                return (option) => option[this.index];
              }

              return option => option;
            },
            shouldAddKeyboard() {
              return this.keyboard && !session.isAndroid;
            },

            selectModelValue: {
              get () {
                  return this.modelValue
              },
              set(value) {
                  this.$emit('update:modelValue', value)
              }
            }
          },
          created() {
            this.id = storage.createUUID();
          }
        }
      }
    }
  }
}