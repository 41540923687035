
module PositiveTS {
    export module Application {
    export module Controllers {
    export class EmployeeSelectViewController extends AbstractViewControllerTS {
        employees = {};
        resolveFunc = null

        openRelativeTo(relativeURL) {
            let promise = new Promise((resolve,reject) => {
                employeeSelectVC.resolveFunc = resolve;
            });
            pNavigator.pushPage('employee-select', "בחר עובד", relativeURL, null);
            return promise;
        }
        // --------------------------------------------------------------------
        // Controller Life-Cycle
        // --------------------------------------------------------------------
        init(options) {
            // Initialize the table view
            var tableView = $('#employee-select-tableview').tableview();
            tableView.setNumberOfVisibleRows(5);
            tableView.empty();
        }
        resume(options) {
            $('#employee-select-continue').click(employeeSelectVC.continueClicked);
            employeeSelectVC.initEmployeeTable();
        }
        stop() {
            // Unbind the listeners from the resume function
            $('#employee-select-continue').unbind('click');
        }
        destroy() {
        }
        // --------------------------------------------------------------------
        // View Related Functions
        // --------------------------------------------------------------------
        async initEmployeeTable() {
            let  employees = await employeeSelectVC.getEmployees();
            
            let tableView = $('#employee-select-tableview').tableview();

            tableView.empty();

            employeeSelectVC.employees = {};

            if (employees.length === 0) {
                return;
            }

            for (let i = 0; i < employees.length; i++) {
                // Add a row with the employee name
                let theRow = tableView.addRow(posUtils.sanitizeTableViewRow([employees[i].name]));
                employeeSelectVC.employees[theRow.attr('id')] = employees[i];
            }    
            
        }

        getSelectedEmployee() {
            // Get the selected row
            let theRow = $('#employee-select-tableview').tableview().getSelectedRow();
    
            if (theRow.length === 0) {
                return null;
            }
    
            return employeeSelectVC.employees[theRow.attr('id')];
        }

        displayErrorMessage(errorMessage) {
            app.showAlert({
                header: i18next.t('error'),
                content: errorMessage,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            }, function () {
            }, null);
        }

        continueClicked() {
            employeeSelectVC.returnSelectedEmployee(employeeSelectVC.getSelectedEmployee());
        }

        closeScreen() {
            pNavigator.back();
        }

        // --------------------------------------------------------------------
        // Logic Related Functions
        // --------------------------------------------------------------------
        async getEmployees() {
            let result = [];
            try {
                result = await Storage.Entity.Employee.fetchByStore(session.pos.storeID)
            } catch(e) {
                console.error('Fetching employees failed. Please contact support.');
                Service.Logger.error(e);
                employeeSelectVC.displayErrorMessage('Fetching employees failed. Please contact support.');
            }
            return result;
        }
        returnSelectedEmployee(employee) {
            // UGLY! Remove this when solving the resume issue in dialogs!!!
            employeeTimeReportVC.fromEmployeeSelect = true;
            employeeBounsReportVC.fromEmployeeSelect = true;
            // -- END of UGLY
    
            employeeSelectVC.resolveFunc(employee);
            employeeSelectVC.closeScreen();
        }
    
    }
}}}

declare var employeeSelectVC:PositiveTS.Application.Controllers.EmployeeSelectViewController;
employeeSelectVC = new PositiveTS.Application.Controllers.EmployeeSelectViewController();
PositiveTS.Application.Controllers.instances.EmployeeSelectViewController = employeeSelectVC;

