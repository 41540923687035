module WebSocketRails {
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 Abstract Interface for the WebSocketRails client.
*/

  export class AbstractConnection {
    dispatcher:WebSocketRails.Dispatcher
    message_queue:any
    connection_id:any
    new_message:any //function callback
    _conn:any
    url:string

    constructor(url?, dispatcher?) {
      this.url = url;
      this.dispatcher = dispatcher;
      this.message_queue   = [];
    }

    close() {}

    trigger(event) {
      if (this.dispatcher.state !== 'connected') {
        return this.message_queue.push(event);
      } else {
        return this.send_event(event);
      }
    }

    send_event(event?) {
      // Events queued before connecting do not have the correct
      // connection_id set yet. We need to update it before dispatching.
      if (this.connection_id != null) { return event.connection_id = this.connection_id; }
    }

      // ...
      
    on_close(event) {
      if (this.dispatcher && (this.dispatcher._conn === this)) {
        const close_event = new Event(['connection_closed', event]);
        this.dispatcher.state = 'disconnected';
        return this.dispatcher.dispatch(close_event);
      }
    }

    on_error(event) {
      if (this.dispatcher && (this.dispatcher._conn === this)) {
        const error_event = new Event(['connection_error', event]);
        this.dispatcher.state = 'disconnected';
        return this.dispatcher.dispatch(error_event);
      }
    }

    on_message(event_data) {
      if (this.dispatcher && (this.dispatcher._conn === this)) {
        return this.dispatcher.new_message(event_data);
      }
    }

    setConnectionId(connection_id) {
      this.connection_id = connection_id;
    }

    flush_queue() {
      for (let event of Array.from(this.message_queue)) {
        this.trigger(event);
      }
      return this.message_queue = [];
    }
  }
}
