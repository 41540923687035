
import { defineStore } from 'pinia';
const state = {
  allDalpaks: null,
  allDalpakAreas: null,
  currentSelectedDalpak: null,
  isDalpakOrdersGetterActive: false,
  dalpakBoxView: false,
  dalpaksPageTitle: null,
  hideHamburgerMenu: false,
  goBackAction: null,
  filters: null,
  editMode: false,
  dalpaksInEditMode: null,
  dalpakAreasInEditMode: null,
}


const getters = {

  getCurrentSelectedDalpak: (state) => {
    return state.currentSelectedDalpak;
  },
  allDalpaksWithoutSpecialAreas: (state) => {
    let specialAreas = [PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES, PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELETED]
    return state.allDalpaks.filter(dalpak => !specialAreas.includes(dalpak.area));
  },
  dalpaksById: (state) => {
    return _.keyBy(state.allDalpaks, 'id');
  },
  dalpaksByArea: (state) => {
    return _.groupBy(state.allDalpaks, d => d.area || PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT);
  },
  dalpaksBySaleId: (state) => {
    return _.keyBy(state.allDalpaks.filter(d => !posUtils.isBlank(d.sale)), d => d.sale.id);
  },
  dalpaksSales: (state) => {
    if (posUtils.isBlank(state.allDalpaks)) {
      return [];
    }

    let sales = [];

    for (let dalpak of state.allDalpaks) {
      if (posUtils.isDefined(dalpak.sale)) {
        sales.push(dalpak.sale);
      }
    }

    return sales;
  },
  dalpaksOrders: (state) => {
    if (!state.isDalpakOrdersGetterActive) {
      return [];
    }

    let sales = _.sortBy(getters.dalpaksSales(state), 'timestamp').reverse();
    let filteredSales = PositiveTS.Service.Delivery.filterSalesToGetOrders(sales);
    return filteredSales.map(sale => PositiveTS.Service.Delivery.convertSaleToOrder(sale));
  },
  openedOrdersCount: (state) => {
    if (!jsonConfig.getVal(jsonConfig.KEYS.isDelivery)) {
      return 0;
    }

    return getters.dalpaksSales(state).filter(sale => sale.isDelivery && PositiveTS.Service.Delivery.isFurtherActionNeeded(sale)).length;
  },
  dalpaksInEditModeByArea: (state) => {
    return _.groupBy(state.dalpaksInEditMode, d => d.area || PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT);
  },
  hasDalpakAreas: (state) => {
    return !posUtils.isBlank(state.allDalpakAreas);
  },
  hasDalpakAreasInEditMode: (state) => {
    return getters.dalpakAreasInEditModeWithoutDeleted(state).length > 0;
  },
  dalpakAreas: (state) => {
    return state.allDalpakAreas;
  },
  dalpakAreasInEditModeWithoutDeleted: (state) => {
    return state.dalpakAreasInEditMode.filter(dalpakArea => !dalpakArea[PositiveTS.Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.DELETE])
  },
}

const actions = {
  setCurrentSelectedDalpak(payload: any) {
    this.currentSelectedDalpak = payload;
  },
  setDalpaks(payload: any) {
    if (Array.isArray(payload)) {
      this.allDalpaks = [...payload]
    } else {
      this.allDalpaks = null;
    }
  },
  setDalpakAreas(payload: any) {
    if (Array.isArray(payload)) {
      this.allDalpakAreas = [...payload]
    } else {
      this.allDalpakAreas = null;
    }
  },
  dalpakCreated(dalpak) {
    let dalpaks = this.allDalpaks;
    dalpaks.push(dalpak);
    this.allDalpaks = [...dalpaks];
  },
  dalpakUpdated(dalpak) {
    let dalpaks = this.allDalpaks;
    let dalpakIndex = _.findIndex(dalpaks, d => d.id == dalpak.id);
    dalpaks[dalpakIndex] = dalpak;
    this.allDalpaks = [...dalpaks];
  },
  dalpakDeleted(dalpak) {
    let dalpaks = this.allDalpaks;
    dalpaks = dalpaks.filter(d => d.id != dalpak.id);
    this.allDalpaks = [...dalpaks];
  },
  activateDalpaksOrderGetters() {
    this.isDalpakOrdersGetterActive = true
  },
  deactivateDalpaksOrderGetters() {
    this.isDalpakOrdersGetterActive = false
  },
  setDalpakBoxView(dalpakBoxView) {
    this.dalpakBoxView = dalpakBoxView;
  },
  setDalpaksPageTitle(title) {
    this.dalpaksPageTitle = title;
  },
  setHideHamburgerMenu(value: boolean) {
    this.hideHamburgerMenu = value;
  },
  setGoBackAction(action) {
    this.goBackAction = action;
  },
  setFilters(filters) {
    this.filters = filters;
  },
  setEditMode(editMode) {
    this.editMode = editMode;
  },
  setDalpaksInEditMode(dalpaksInEditMode) {
    this.dalpaksInEditMode = dalpaksInEditMode;
  },

  setDalpakAreasInEditMode(dalpakAreasInEditMode) {
    this.dalpakAreasInEditMode = dalpakAreasInEditMode
  },
  dalpaksAreasChanged(dalpaks) {
    for (const updatedDalpak of dalpaks) {
      this.dalpakUpdated(updatedDalpak)
    }
  },
  startEditMode() {
    let specialAreas = [PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES, PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELETED]
    let dalpaksInEditMode = _.cloneDeep(this.allDalpaks)
      .reduce((acc, dalpak) => {
        if (!specialAreas.includes(dalpak.area)) {
          delete dalpak.sale;
          delete dalpak.lockedBy;
          acc.push(dalpak);
        }
        return acc;
      }, [])
    this.setDalpaksInEditMode(dalpaksInEditMode)
    this.setDalpakAreasInEditMode(_.cloneDeep(this.allDalpakAreas))
    this.setEditMode(true)
  },
  exitEditMode() {
    this.setEditMode(false)
    this.setDalpaksInEditMode(null)
  },
  cancelEditModeChanges() {
    this.exitEditMode()
  },
  async saveEditModeChanges() {
    let isSuccess = await PositiveTS.Service.Dalpak.bulkUpdateDalpaksAndAreasAttributes(JSON.parse(JSON.stringify(this.dalpaksInEditMode)), JSON.parse(JSON.stringify(this.dalpakAreasInEditMode)));

    if (isSuccess) {
      this.exitEditMode()
    }
  },
  updateEditModeDalpak(dalpakToSave) {
    let dalpaks = this.dalpaksInEditMode;
    let dalpakIndex = dalpaks.findIndex(d => d.id == dalpakToSave.id);

    if (dalpakIndex >= 0) {
      dalpaks[dalpakIndex] = dalpakToSave;
    } else {
      dalpaks.push(dalpakToSave);
    }

    this.setDalpaksInEditMode(Object.values(dalpaks));
  },
  deleteEditModeDalpak(dalpakToDelete) {
    let dalpaks = this.dalpaksInEditMode;
    let dalpakIndex = dalpaks.findIndex(d => d.id == dalpakToDelete.id);

    if (dalpakToDelete[PositiveTS.Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW]) {
      dalpaks.splice(dalpakIndex, 1);
    } else {
      dalpaks[dalpakIndex][PositiveTS.Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.DELETE] = true;
      dalpaks[dalpakIndex].area = PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DELETED;
    }

    this.setDalpaksInEditMode(dalpaks);
  },
  updateEditModeDalpakArea(dalpakAreaToSave) {
    let dalpakAreas = this.dalpakAreasInEditMode;
    let dalpakIndex = dalpakAreas.findIndex(da => da.id == dalpakAreaToSave.id);

    if (dalpakIndex >= 0) {
      dalpakAreas[dalpakIndex] = dalpakAreaToSave;
    } else {
      dalpakAreas.push(dalpakAreaToSave);
    }

    this.setDalpakAreasInEditMode(dalpakAreas);

    if (this.dalpakAreasInEditModeWithoutDeleted.length == 1) {
      this.setDalpaksInEditMode(this.dalpaksInEditMode.map((dalpak) => {
        dalpak.area = this.dalpakAreasInEditModeWithoutDeleted[0].railsId;
        return dalpak;
      }));
    }
  },
  deleteEditModeDalpakArea({ areaToDelete, dalpaksTargetAreaRailsId }) {
    let dalpakAreas = this.dalpakAreasInEditMode;
    let dalpakIndex = dalpakAreas.findIndex(da => da.id == areaToDelete.id);

    if (areaToDelete[PositiveTS.Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW]) {
      dalpakAreas.splice(dalpakIndex, 1);
    } else {
      dalpakAreas[dalpakIndex][PositiveTS.Service.Dalpak.DALPAK_EDIT_MARKS_PROPS.DELETE] = true;
    }

    this.setDalpakAreasInEditMode(dalpakAreas);

    if (dalpaksTargetAreaRailsId) {
      this.setDalpaksInEditMode(this.dalpaksInEditMode.map((dalpak) => {
        if (String(dalpak.area) == String(areaToDelete.railsId)) {
          dalpak.area = String(dalpaksTargetAreaRailsId);
        }

        return dalpak;
      }));
    } else if (this.dalpakAreasInEditModeWithoutDeleted.length == 0) {
      this.setDalpaksInEditMode(this.dalpaksInEditMode.map((dalpak) => {
        dalpak.area = PositiveTS.Service.DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT;
        return dalpak;
      }));
    }
  },
}

const dalpaksStore = defineStore('dalpaks', {
  state: () => ({ ...state }),
  getters: { ...getters },
  actions: { ...actions }
});

export default dalpaksStore;