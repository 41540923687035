module PositiveTS {
	export module UI  {
		export class Base {
			// Saves instances of UI widgets for fast retrieval
			static instances = {}
			/**
			 * UI widgets can register instances of themselves here instead of initalizing on every call
			 */
			static registerInstance(id, instance) {
				this.instances[id] = instance;
			}
			/**
			 * Get a regitered instance
			 */
			static getInstance(id) {
				return this.instances[id];
			}

			static destroyInstance(parentElem,id) {
				Array.from(parentElem.children()).forEach(function(child) { parentElem[0].removeChild(child) });
				delete this.instances[id];
			}
		}
	}
}