import heLang from "./lang/he";
import enLang from "./lang/en";
import i18next from "i18next";
import { isNullOrUndefined } from "./Utils";
import _ from 'loadsh'

export default class SharedConfig {
  
    static init(language = 'he', languagePackages = null) {
        let langResources = {he: heLang, en: enLang};

        if (!isNullOrUndefined(languagePackages)) {
          langResources = _.merge(langResources, languagePackages)
        }
    
    
        i18next.init({
          lng: language,
          resources: {
            en: {
              translation: langResources.en,
            },
            he: {
              translation: langResources.he,
            }
          }
        })
    }
}