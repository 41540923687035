module PositiveTS {
  export module Components {
    export module HomePage {
      function initData() {
        return {
          canManageHakafaCustomer: jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos),
          needManagerApprovalForCashManagement: jsonConfig.getVal(jsonConfig.KEYS.managerApprovalForCashManagement)
        }
      }

      function isAndroidTeamViewerQSAvaible() {
        return Android.LunchTeamViewerQS !== undefined
      }

      function LunchTeamViewerQS() {
        Android.LunchTeamViewerQS();
      }

      function shutdown() {
        pNavigator.pushPage('shutdown', i18next.t('homepage.shutdown'), '/homepage', null);
      }



      function openXReport() {

        posVC.RedirectIfAuthorized(session.pos.parameterManagerApprovalZXReport,
          function () {
            pNavigator.pushPage('xreport', i18next.t('homepage.xreport'), '/homepage', null);
          }, [Storage.Entity.Employee.CAN_VIEW_X_REPORT]
        )
      }

      function openReports() {

        posVC.RedirectIfAuthorized(session.pos.isManagerApprovalReports,
          function () {
            pNavigator.pushPage('report', i18next.t('homepage.reports'), '/homepage', null);
          }, [Storage.Entity.Employee.CAN_VIEW_REPORTS]
        )
      }

      function openTimeClock() {
        Pinia.componentsStore.openComponent({ componentName: "timeClockDialog", args: [] })

      }

      function RedirectItemsMngtIfAuthorized() {
        if (Pinia.globalStore.posState != 'pos') {
          return;
        }
        posVC.RedirectIfAuthorized(jsonConfig.getVal(jsonConfig.KEYS.isItemsMangAuthorizationRequired)
          , () => {
            pNavigator.pushPage('pages', '', null, { initParams: { route: '/item-manage' } })
          });
      }

      function RedirecEmpMngtIfAuthorized() {
        if (Pinia.globalStore.posState != 'pos') {
          return;
        }
        posVC.RedirectIfAuthorized(jsonConfig.getVal(jsonConfig.KEYS.isEmployeesMangAuthorizationRequired)
          , () => {
            pNavigator.pushPage('pages', '', null, { initParams: { route: '/employee-manage' } })
          });
      }

      async function RedirecTechnicianCard() {
        let paramsPagePassword = session.pos.getPassword('params_page');

        if (session.pos.isRoshemet || session.pos.isCaveret || paramsPagePassword) {
          await Pinia.componentsStore.openComponent({ componentName: "techCardDialogComponent", args: [paramsPagePassword] })
            .then(function (res) {
              if (res.approved) {
                pNavigator.pushPage('params', i18next.t('homepage.parameters'), null, null);
              } else {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('errorTechnicalCard'),
                  hideCancelButton: true
                })
              }
            })
        }
        else {
          pNavigator.pushPage('params', i18next.t('homepage.parameters'), null, null);
        }
      }

      async function RedirecTechnicianCardShutdown() {
        await Pinia.componentsStore.openComponent({ componentName: "techCardDialogComponent", args: [] })
          .then(function (res) {
            if (res.approved) {
              pNavigator.pushPage('shutdown', i18next.t('homepage.shutdown'), null, null);
            } else {
              app.showAlertDialog({
                header: i18next.t('error'),
                content: i18next.t('errorTechnicalCard'),
                hideCancelButton: true
              })
            }
          })
      }

      async function openHakfaCustomerDialog() {
        posVC.RedirectIfAuthorized(jsonConfig.getVal(jsonConfig.KEYS.isHakafaCustomersMangAuthorizationRequired)
          , () => {
            Pinia.componentsStore.openComponent({
              componentName: 'vueDialog',
              args: [Components.HakafaCustomerDialog.componentName, {
                openedFromHomePage: true
              }]
            })
          });
      }


      export function create() {
        const component = {
          template: JST.homepage(),
          methods: {
            openXReport: openXReport,
            openReports: openReports,
            RedirecEmpMngtIfAuthorized: RedirecEmpMngtIfAuthorized,
            RedirectItemsMngtIfAuthorized: RedirectItemsMngtIfAuthorized,
            RedirecTechnicianCard: RedirecTechnicianCard,
            RedirecTechnicianCardShutdown: RedirecTechnicianCardShutdown,
            LunchTeamViewerQS: LunchTeamViewerQS,
            isAndroidTeamViewerQSAvaible: isAndroidTeamViewerQSAvaible,
            shutdown: shutdown,
            openTimeClock: openTimeClock,
            openHakfaCustomerDialog: openHakfaCustomerDialog,
            lockSelfServicePos() {
              Pinia.globalStore.lockSelfServicePos();
            },
            async reprintOtotTag() {
              await Service.Otot.reprintEntranceTagFromExistingQrCode();
            },
            async openPos() {
              if (isOtherInstanceAlreadyOpen) {
                Service.MultiplePosesOpened.showOtherTabsMessage(session.pos.isCaveret);
                return;
              }

              if (PositiveTS.Service.CheckPosPropriety.isCannotWorkOnPos()) {
                Pinia.componentsStore.openComponent({ componentName: "checkPosProprietyDialog", args: [] });
                return;
              }

              if (session.isAndroid) {
                posUtils.oneTimeVersionsClearIfNeeded();
                posUtils.cleanAndroidStorageRoutine()
              }

              if (posUtils.isBlank(localStorage.getItem("readOnlyDbDataLoadedAndWebDBRemoved"))) {
                await app.promiseShowAlert({
                  header: i18next.t("error"),
                  content: i18next.t("newVersionPleaseUpdateCatalog"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
                statsVC.reloadAllData();
                return;
              }

              if (!session.pos.isRoshemet && (session.pos.employeeID == "-1" || posUtils.isNullOrUndefined(session.pos.employeeID)) && !Pinia.globalStore.selfServiceSuperMarket && !jsonConfig.getVal(jsonConfig.KEYS.standaloneMode)) {
                app.showAlert({
                  header: i18next.t("error"),
                  content: i18next.t("mustSelectCashier"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
                return;
              }

              if (Service.CashierStatement.forceCashDeclarationsAtBeginningOfDay()) {
                let pastSales = await PositiveTS.Storage.Entity.Sale.fetchPastSales();
                let cashierStatementDone = await Storage.Entity.CashStatementLog.enterHasBeenDone()
                if (pastSales.length < 1 && !cashierStatementDone) {
                  let res = await app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: i18next.t("homepage.cashierStatementError"),
                    continueButtonText: i18next.t("homepage.doCashierStatement"),
                    hideCancelButton: false
                  });
                  if (res == "continue") {
                    if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
                      Pinia.componentsStore.openComponent({ componentName: "CashierStatementMultiCurrencies", args: [Shared.Constants.CashierStatement.TYPE_START_DAY] });
                    } else {
                      Pinia.globalStore.setCashierStatementType('enter');
                      Pinia.componentsStore.openComponent({ componentName: "CashierStatement", args: [] });
                    }
                  }
                  return;
                }
              }
              app.PosIconClick = true;

              if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                let currentSale = await appDB.sales.where({ invoiceSequence: -1 }).first();
                await Service.Dalpak.loadAllDalpakAreas()
                let dalpaks = await Service.Dalpak.loadAllDalpaks();


                let shouldGoToLastDalpakBecauseIsOffline = (jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline) &&
                  this.syncServerOnlineState != Service.WebsocketSync.SyncServerClient.CONNECTION_STATUSES.CONNECTED &&
                  !Service.WebsocketSync.SyncServerClient.isPrimaryPos() &&
                  !posUtils.isBlank(localStorage.getItem('currentDalpak')));

                if (posUtils.isDefined(currentSale) || shouldGoToLastDalpakBecauseIsOffline) {
                  Service.Dalpak.setCurrentSelectedDalpakFromLocalStorage();
                } else {
                  return Service.Dalpak.openDalpakScreen();
                }
              }

              pNavigator.pushPage('pos', i18next.t('homepage.pos'), null, null);
            },
            async openCashManagement() {
              if (this.needManagerApprovalForCashManagement) {
                let isManager = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);

                if (isManager) {
                  Pinia.componentsStore.openComponent({ componentName: "cashManagement", args: [] });
                }
              }
              else {
                Pinia.componentsStore.openComponent({ componentName: "cashManagement", args: [] });
              }
            },
            async openOutOfStockItems() {
              Pinia.componentsStore.openComponent({ componentName: "outOfStockItemsDialog", args: [] });
            },
            async openEnterStatement() {
              if (this.needManagerApprovalForCashManagement) {
                await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER]);
              }

              if (Service.CashierStatement.forceCashDeclarationsAtBeginningOfDay() && Service.CashierStatement.forceCashDeclarationsAtEndOfDay()) {
                let pastSales = await PositiveTS.Storage.Entity.Sale.fetchPastSales()
                if (pastSales.length > 0) {
                  app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: i18next.t("homepage.alreadyOpened"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                  return;
                }
              }

              if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr()) {
                Pinia.componentsStore.openComponent({ componentName: "CashierStatementMultiCurrencies", args: [Shared.Constants.CashierStatement.TYPE_START_DAY] });
              } else {
                Pinia.globalStore.setCashierStatementType(Shared.Constants.CashierStatement.TYPE_START_DAY);
                Pinia.componentsStore.openComponent({ componentName: "CashierStatement", args: [] });
              }
            },
            async closeFlight() {
              await Service.CloseFlight.CloseFlightAndZReport();
            },
            async closeIsrairFlight() {
              if (this.isIsrairPos()) {
                Pinia.componentsStore.openComponent({ componentName: "flightsIsrairCloseDialog", args: [] });
              }
            },
            async closeIsrairZReport() {
              if (!Pinia.globalStore.isOnline) {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('flightsWizard.closeFlightOfflineError'),
                  hideCancelButton: true
                })
                return;
              }

              if (PositiveTS.Service.CheckPosPropriety.isCheckPosProprietyFailed()) {
                Pinia.componentsStore.openComponent({ componentName: "checkPosProprietyDialog", args: [] });
                return;
              }

              await Service.CloseIsrairFlight.CloseFlightAndZReport();
            },
            async openZReport() {
              if (PositiveTS.Service.CheckPosPropriety.isCheckPosProprietyFailed()) {
                Pinia.componentsStore.openComponent({ componentName: "checkPosProprietyDialog", args: [] });
                return;
              }

              PositiveTS.Service.ZReport.openZReport();
            },

            async runCaveretShortcut() {
              app.showLoadingMessage(i18next.t("homepage.caveretStarterRunning"))
              let emp;
              try {
                emp = await Storage.Entity.Employee.fetchByEmployeeID(session.pos.employeeID);
              } catch (err) {
                app.hideLoadingMessage();
                app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: i18next.t("homepage.employeeNotInSystem"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
                return;
              }
              if (posUtils.isNullOrUndefined(emp) || posUtils.isNullOrUndefinedOrEmptyString(emp.externalIdentification)) {
                app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: i18next.t("homepage.employeeNotFoundOrNoExternalId"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
                return;
              }

              let identification = `${session.pos.employeeID};${emp.externalIdentification};${session.pos.storeID};${(new Date()).getTime()}`;
              identification = btoa(identification);
              let data = JSON.stringify({
                type: "CMD",
                url: "C:\\Caveret\\CaveretStarter.exe",
                body: `-ui:${identification}`,
              });

              let res = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
                data: data
              }, "external_service");

              let response = JSON.parse(res.request.result);
              if (response && response.success) {
                app.showLoadingMessage(i18next.t("homepage.caveretStarterLoading"));
                setTimeout(() => { app.hideLoadingMessage() }, 2000);
              } else {
                let errorStr = i18next.t("homepage.caveretStarterFailed")
                if (!posUtils.isNullOrUndefinedOrEmptyString(response.message)) {
                  errorStr = `${errorStr}\n${response.message}`
                }
                app.hideLoadingMessage();
                app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: errorStr,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
              }
            },
            async closePos() {
              app.showManagerApprovalDialog()
                .then(function (managerEmployee) {
                  app.showLoadingMessage(i18next.t("attemptingToClose"));
                  PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
                    command: "killchrome",
                    arguments: ""
                  }, "positive_runner");
                  setTimeout(() => {
                    app.hideLoadingMessage();
                    app.promiseShowAlert({
                      header: i18next.t('error'),
                      content: i18next.t('closeFailed'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    });
                  }, 10000);
                })
                .catch(function (error) {
                  console.log('DID NOT CHOOSE APPROVAL!!');
                });


            },
            openIsrairPos() {
              if (this.currentFlight && this.currentFlight.is_closed) {
                app.promiseShowAlert({
                  header: i18next.t('error'),
                  content: i18next.t('flightsWizard.cantOpenPosWithoutZ'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
                return;
              }

              this.openPos();
            },
            openIsrairFlightLogin() {
              if (this.isIsrairPos()) {
                if (PositiveTS?.VueInstance?.$refs?.flightsIsrairOpenDialog) {
                  Pinia.componentsStore.openComponent({ componentName: "flightsIsrairOpenDialog", args: [] });
                }
              }
            },
            openFlightLogin() {
              if (this.hasFlights) {
                if (PositiveTS?.VueInstance?.$refs?.flightsOnlineLoginDialog) {
                  Pinia.componentsStore.openComponent({ componentName: "flightsOnlineLoginDialog", args: [] });
                }
              }
            },
            openDisconnectFlight() {
              if (this.hasFlights) {
                if (PositiveTS?.VueInstance?.$refs?.flightsDisconnectDialog) {
                  Pinia.componentsStore.openComponent({ componentName: "flightsDisconnectDialog", args: [] });
                }
              }
            },
            openFlightLegDialog() {
              if (this.hasFlights) {
                if (PositiveTS?.VueInstance?.$refs?.flightsOpenLegWizardDialog) {
                  Pinia.componentsStore.openComponent({ componentName: "flightsOpenLegWizardDialog", args: [] });
                }
              }
            },
            openFlightCloseLegDialog() {
              if (this.hasFlights) {
                if (PositiveTS?.VueInstance?.$refs?.flightsCloseLegWizardDialog) {
                  Pinia.componentsStore.openComponent({ componentName: "flightsCloseLegWizardDialog", args: [] });
                }
              }
            },
            openCalculator() {
              Pinia.componentsStore.openComponent({ componentName: "currencyConversionCalculator", args: [true] })
            },
            selfServicePosToggler() {
              let selfServiceConfigObj = {
                standaloneMode: jsonConfig.getVal(jsonConfig.KEYS.standaloneMode),
                simpleSelfService: jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService),
                isSelfServiceSuperMarketPos: jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos)
              }

              for (const key in selfServiceConfigObj) {
                jsonConfig.setVal(jsonConfig.KEYS[key], !selfServiceConfigObj[key])
              }

              location.reload()

            },
            manageMachine(){
              Pinia.componentsStore.openComponent({ componentName: "vendingManageMachine", args: [] })
            },
            taxAuthorityConnect(){
              Pinia.componentsStore.openComponent({ componentName: "taxAuthorityConnect", args: [] })
            }
          },
          components: {
            positiveIcon: PositiveTS.Components.PositiveIcon.getComponent(),
          },
          data: initData,
          setup() {
            const flightsStore = Pinia.useFlightsStore();
            const { isLastLeg, currentFlight, leg, flight } = Pinia.storeToRefs(flightsStore);

            const globalStore = Pinia.useGlobalStore();
            const {standaloneMode, isRoshemet, passwordProtected, syncServerOnlineState } = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {standaloneMode, isRoshemet, passwordProtected, syncServerOnlineState }

            return { ...globalStoreProps, isFlightLastLeg: isLastLeg, currentFlight, leg, flight }
          },
          computed: {
            isMultiCurr: () => PositiveTS.Service.MultiCurr.getInstance().isMultiCurr(),
            isSelfServicePos: () => {
              if (jsonConfig.getVal(jsonConfig.KEYS.standaloneMode) && jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
                return true
              }
              return false
            },
            showSelfServiceToggle: () => {
              return jsonConfig.getVal(jsonConfig.KEYS.selfServiceToggleButton)
            },
            isCaveret() {
              return session.pos.isCaveret;
            },
            isVendingMachine(){
              return session.pos.isVendingMachinePos;
            },
            hasFlights() {
              return session.pos && session.pos.hasFlights;
            },
            isIsrairPos() {
              return jsonConfig.getVal(jsonConfig.KEYS.isIsrairPos);
            },
            isOtotAndAllowReprintQR() {
              return PositiveTS.Service.Otot.isOtotActive() && jsonConfig.getVal(jsonConfig.KEYS.showOtotReprintQrCode);
            },
            isAndroid: function () {
              return session.isAndroid;
            },
            isDeliveriesTablet() {
              return jsonConfig.getVal(jsonConfig.KEYS.isDeliveriesTablet);
            },
            isAllowOutOfStockItems() {
              return jsonConfig.getVal(jsonConfig.KEYS.allowOutOfStockItems);
            },
            hasCaveretShortcut() {
              return jsonConfig.getVal(jsonConfig.KEYS.hasCaveretStarterShortcut);
            },

          },
          async mounted() {
            if (session.pos.isElal && !jsonConfig.getVal(jsonConfig.KEYS.bypassElalActiveDirectoryCheck)) {
              await Pinia.elalStore.validateElalActiveDirectory();
            }
          }
        }

        VueApp.component('homepage', component)
      }
    }
  }
}
