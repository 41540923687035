module PositiveTS {
export module Components {
export module OneClickButton {
    const _vueComponentName = 'one-click-button';

    export function create() {
        let vueObj = {
            template: JST.oneClickButton(),
            mixins: [Mixins.oneClickMixin],
            props: ["clickedFunction", "btnClass"],
            data() {
                return {}
            },
            methods: {
                async click() {
                    await this.oneTimeRun(this.clickedFunction);
                }
            }
        }
        VueApp.component(_vueComponentName,vueObj)
    }
}}}