module PositiveTS {
export module Components {
export module WithdrawalDialog {

  const _dialogSelector = "#positive-withdrawal-dialog"
  const _vueComponentName = 'withdrawal-dialog';

  export function create() {
    
    let withdrawalDialog = {
        template: JST.withdrawalDialog(),
        methods: {
          close: close,
          loadRegularCreditCardPaymentType(){
            for (let ccpt in session.creditCardPaymentTypes) {
              let ccptType = session.creditCardPaymentTypes[ccpt]
              ccptType.selected = false
              if (ccptType.ccTypeID == "1") {
                this.selectedPaymentType = ccptType;
              }
            }
          },
          async withdraw() {
            app.showLoadingMessage(i18next.t("withdrawal.waitingForEmv"));
            let response = await this.withdrawWithCreditCard(Service.EMV.PINPAD_DEAL);
              if(response.success) {
                
                let cashWithdrawalResponse = response.result;
                let sale = await Service.Withdrawal.createSale(cashWithdrawalResponse);

                let withdrawalAmount;
                let saleData = JSON.parse(sale.sale.jsondata);
                withdrawalAmount = saleData.withdrawn; 
                Printing.Invoice.printCashWithdrawalInvoice(sale.sale, sale.saleItems[0], 
                                        Service.Withdrawal.getWithdrawalParam('surcharge'),
                                        cashWithdrawalResponse.TerminalId, sale.sale.atmTransactionId, false, withdrawalAmount);

                app.hideLoadingMessage();
                await app.promiseShowAlert({
                    header: i18next.t("successfullyCompleted"),
                    content: i18next.t("withdrawal.withdrawalSuccess"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                });
                this.close();
              } 
              else {
                app.hideLoadingMessage();
                await app.promiseShowAlert({
                  header: i18next.t("error"),
                  content: i18next.t("withdrawal.confirmError"),
                })
              }
          },
          async showAlert(message){
            return await app.promiseShowAlert({
              header: i18next.t("error"),
              content: message,
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            });
          },
          askForVerificationCode() {
            return new Promise((resolve,reject) => {
              app.hideLoadingMessage()
              let dg = new PositiveTS.Dialogs.InputDialog()
              let options = new PositiveTS.Dialogs.InputDialogOptions()
              options.header = 'נדרש אישור מחברת האשראי'
              options.description = 'נא הקלד מספר אישור שהתקבל מחברת האשראי או לחץ על ביטול'
              options.showCancelButton = true
              dg.open(options)
              .then(result => {
                this.manualConfirmationNumber = result
                resolve(result)
              })
              .catch(err => {reject('פעולה בוטלה')})
            })
            
          },
          inTheAir(){
            return session.pos.hasFlights && !Pinia.globalStore.isOnline
          },
          async withdrawWithCreditCard(mode, verificationCode = null){
            if (this.isDuringEmvRequest) {
              console.log("in the middle of request - exiting")
              return;
            }
            this.isDuringEmvRequest = true;
            let selectedPaymentType = this.selectedPaymentType;

            if (verificationCode == null && !posUtils.isNullOrUndefinedOrEmptyString(this.manualConfirmationNumber)) {
              verificationCode = this.manualConfirmationNumber;
            }

            try {
              let totalWithrawalAmount = this.withdrawalItem.unitPrice + this.withdrawalSurcharge/100;
              let numOfPayments = "1";
              let firstPayment = totalWithrawalAmount
              let response = await Service.EMV.payWithCreditCard(totalWithrawalAmount, mode, selectedPaymentType, numOfPayments, verificationCode,"1",firstPayment)
              
              if (response.success) {
                 this.isDuringEmvRequest = false;
                return response;
              } 
              else {
                if (Service.EMV.manualConfirmationNumberRequired(response.result)) {
                  if(session.pos.hasFlights) {
                    this.showAlert(i18next.t('emvAshStatusCodes.004')); 
                    this.isDuringEmvRequest = false;
                    return ;
                  } else {
                    this.isDuringEmvRequest = false;
                    let verifcationCodeResult;

                    try {
                      verifcationCodeResult = await this.askForVerificationCode()
                    } catch(err) {
                      this.isDuringEmvRequest = false;
                      console.error(err);
                      return;
                    }
                    try {
                      this.isDuringEmvRequest = false;
                      let emvRes = await this.withdrawWithCreditCard(mode, verifcationCodeResult)
                      return emvRes;
                    }
                    catch(error) {
                      this.showAlert(error)
                      this.isDuringEmvRequest = false;
                    }
                  }
                }
                else if (Service.EMV.isTimeoutResponse(response.result)) {
                  this.showAlert(i18next.t('newCreditCard.timeout')); 
                  this.isDuringEmvRequest = false;
                }
                else if (Service.EMV.oldFileExists(response.result)) {
                  if(this.inTheAir()){
                    this.showAlert(`${response.error}\n${i18next.t('emv.blacklistNotUpdatedAndOffline')}`)
                    Pinia.globalStore.setEmvRecoveryNeededWhenOnline(true)
                    return;
                    }
                    else{
                      this.showAlert(`${response.error}\n${i18next.t('emv.clickOKToCallShva')}`,async () => {
                        await Service.EMV.callShvaWithoutTransmittingTransactions();
                      })
                    }
                  
                  this.isDuringEmvRequest = false;
                }
                else if (Service.EMV.oldTranNotEmpty(response.result)) {
                  this.showAlert(response.error + ".\nיש לבצע סגירת יום לפני שניתן להמשיך לבצע עסקאות אשראי נוספות.")
                  this.isDuringEmvRequest = false;
                  if(this.inTheAir()){
                    Pinia.globalStore.setEmvRecoveryNeededWhenOnline(true)
                    return;
                  }
                }
                else {
                  this.showAlert(response.error)
                  if(Service.EMV.ERRORS_THAT_JUSTIFIES_RECOVERY.includes(Number(response.result.ResultCode))){
                    Pinia.globalStore.setEmvRecoveryNeeded(true)
                    PositiveTS.VueInstance.$refs.posPaymentDialog.selectFirstEnabledPaymentMethod()
                  }
                  this.isDuringEmvRequest = false;
                  return;
                }
              }
            }
            catch(err) {
              console.error(err);
              this.isDuringEmvRequest = false;
              Pinia.globalStore.setEmvRecoveryNeeded(true)

              let errorMessage = i18next.t('emv.PINPAD_NOT_CONNECTED');

              if (!posUtils.isBlank(Service.EMV.getIpAddress())) {
                errorMessage = i18next.t('emv.PINPAD_NOT_CONNECTED_IP');
              }
              this.showAlert(errorMessage);
        
              throw err;
            }
          },
          async open(){
            this.loadRegularCreditCardPaymentType()
            this.withdrawalItem = posVC.saleItems[0];
            this.withdrawalSurcharge = Service.Withdrawal.getWithdrawalParam('surcharge');

            if(session.pos.isCaveret) {
              let hasEnoughCash = await Service.Withdrawal.posHasEnoughCash(this.withdrawalItem.unitPrice)
              if(!hasEnoughCash) {
                app.showAlert({
                  header: i18next.t("error"),
                  content: i18next.t("withdrawal.notEnoughCashInPos"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
                return ;
            }
          }
            $(function() {
              $(_dialogSelector).dialog({
                autoOpen: true,
                modal: true,
                dialogClass: 'positive-dialog',
                width: '60%',
                height: 360,
                resizable: false,
                closeOnEscape: false,
                draggable: false,
              });
            $(_dialogSelector).dialog('open');
          });
          },
        },
        data: _initData
    }

    VueApp.component(_vueComponentName,withdrawalDialog)
  }

  function close() {
    $(_dialogSelector).dialog('close');
  }

  function _initData () {
    return {
      withdrawalItem: null,
      withdrawalSurcharge: null,
      selectedPaymentType:null,
      isDuringEmvRequest:false,
      manualConfirmationNumber:null
    }
  }

}}}
