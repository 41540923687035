module PositiveTS {
    export module Service {
    export module Withdrawal {
        const WTHDRWLFEE_CODE = 'WTHDRWLFEE';
        const HANDLER = "WITHDRAWAL"
        const FEE_HANDLER = "WITHDRAWALFEE"
        let feeItemAndBarcode;
        let withdrawalSpecialItemExists;
        
        export function posHasCashWithdrawals() {
            if(posUtils.isBlank(withdrawalSpecialItemExists)) {
                let withdrawalSpecialItem = posVC.Specialitems.find((item) => { return item.hndlr == HANDLER });
                withdrawalSpecialItemExists = withdrawalSpecialItem ? true : false;
            }
            let cashWithdrawalParamOn = jsonConfig.getVal(jsonConfig.KEYS.hasCashWithdrawal);
            return withdrawalSpecialItemExists && cashWithdrawalParamOn;
        }

        export function saleIsWithdrawal(sale) {
            if(!sale.items || sale.items.length != 1) {
                return false;
            }
            return itemIsCashWithdrawal(sale.items[0]);
        }

        export function itemIsCashWithdrawal(saleItem:any) {
            let withdrawalSpecialItem = posVC.Specialitems.find((item) => { return (item.hndlr == HANDLER || item.hndlr == FEE_HANDLER) && item.code == saleItem.itemCode });           
            return withdrawalSpecialItem ? true : false;
        }

        export function isItemValidInSale(saleItem:any) {
            let saleItems = posVC.saleItems;
            let withdrawalSpecialItem = posVC.Specialitems.find((item) => { return item.hndlr == HANDLER });
            
    
            if(!withdrawalSpecialItem) {
                return { valid: true, hasWithdrawalItem: false }
            }
    
            if(saleItem.itemCode != withdrawalSpecialItem.code) {
                let saleHasWithdrawalItems = saleItems.find((item) => { return item.itemCode == withdrawalSpecialItem.code});
                if(saleHasWithdrawalItems) {
                    return {
                        valid: false,
                        msg: i18next.t('withdrawal.validationCannotAdd')
                    }
                }
                return { valid: true }
            }
    
            if(saleItem.itemCode == withdrawalSpecialItem.code && !session.pos.isCaveret && !jsonConfig.getVal(jsonConfig.KEYS.hasCashWithdrawal)) {
                return {
                    valid: false,
                    msg: i18next.t('withdrawal.noCashWithdrawal')
                }
            }
    
            if ((saleItems.length === 1 && saleItems[0].id !== saleItem.id) || saleItems.length > 1) {
                return {
                    valid: false,
                    msg: i18next.t('withdrawal.validationCannotBeAdded')
                }
            }
    
            if (saleItem.quantity !== 1) {
                return {
                    valid: false,
                    msg: i18next.t('withdrawal.validationCannotChangeAmount')
                }
            }
    
            return { valid: true }
        }
        export function getMinLimitAmount(){
            return jsonConfig.getVal(jsonConfig.KEYS.cashWithdrawalMinAmount);
        }
        export function getMultiplyBy(){
            return jsonConfig.getVal(jsonConfig.KEYS.cashWithdrawalMultiplyBy);
        }
        export function getMaxLimitAmount(){
            return jsonConfig.getVal(jsonConfig.KEYS.cashWithdrawalMaxAmount);
        }
        export function cancelTxMaxDays(){
            return jsonConfig.getVal(jsonConfig.KEYS.cashWithdrawalCancelMaxDays);
        }
        export function cashWithdrawalCancelMaxDays(){
            return jsonConfig.getVal(jsonConfig.KEYS.cashWithdrawalCancelMaxDays);
        }
        export function getDaysFromSale(sale:Storage.Entity.Sale):number{
                let saleCreatedAt = moment(new Date(sale.createdAt)); 
                let now = moment();
                return now.diff(saleCreatedAt, "days");
        }
        export function saleCanBeRefunded(sale:Storage.Entity.Sale):boolean {
            if(!saleIsWithdrawal(sale)) {
                return false;
            }
            
            if(saleIsWithdrawalRefund(sale)) {
                return false;
            }
            return true;
        }

        export function validateRefundSale(sale:Storage.Entity.Sale):{isValid:boolean,message:string} {
            let response = {isValid:true,message:''}
            if(!saleIsWithdrawal(sale)) {
                response.isValid = false;
                response.message = i18next.t('withdrawal.saleIsNotWithdrawal')
                return response;
            }
            if(saleIsWithdrawalRefund(sale)) {
                response.isValid = false;
                response.message = i18next.t('withdrawal.saleIsNotRefund')
                return response;
            }

            if(getDaysFromSale(sale) > cashWithdrawalCancelMaxDays()){
                response.message = i18next.t('withdrawal.refundPastAllowedDays',{days:cashWithdrawalCancelMaxDays()})
                response.isValid = false;
                return response;
            }
            return response
        }
    
 
    
        export function isSaleAnUnclosedWithdrawalSale(saleItem:Storage.Entity.SaleItem) {
            let sale = posVC.sale;
            if(posHasCashWithdrawals() && !posUtils.isBlank(sale.atmTransactionId)) {
                return { 
                    valid: false,
                    msg: i18next.t('withdrawal.validationSaleIsStuckWithdrawal')
                }
            }
    
            return { valid: true }
            
        }
    
        export async function updateWithdrawalParams() {
            try{
                let withrawalFeeItem = await PositiveTS.Storage.Entity.Item.searchByCode(WTHDRWLFEE_CODE);
                if(withrawalFeeItem.length == 0 || !posUtils.isBlank(withrawalFeeItem[0].item.priceZarhan)){
                    // handle error here
                }
                let withrawalFeePrice = withrawalFeeItem[0].item.priceZarhan;
                let withdrawalParams = {
                    maxWithdrawal:  getMaxLimitAmount()*100,
                    minWithdrawal: getMinLimitAmount()*100,
                    surcharge: withrawalFeePrice*100,
                    amountIncrements: getMultiplyBy()*100,
                    internationalCardAllowed: false
                }
                localStorage.setItem("cashWithdrawal", JSON.stringify(withdrawalParams));
            }catch(err){
                console.log(err);
            }

        }
        export function validateWithdrawalAmount(amount:number) {
            try {
                amount = amount * 100;
    
                if(isNaN(getWithdrawalParam("amountIncrements"))) {
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('withdrawal.emvError'),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                    }, null, null);
                    return false;
                }
                if(amount < getWithdrawalParam("minWithdrawal")) {
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('withdrawal.amountTooSmall', { amount: getWithdrawalParam("minWithdrawal")/100 }),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                    }, null, null);
                    return false;
                }
                if(amount > getWithdrawalParam("maxWithdrawal")) {
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('withdrawal.amountTooBig', { amount: getWithdrawalParam("maxWithdrawal")/100 }),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                    }, null, null);
                    return false;
                }
                if(amount % getWithdrawalParam("amountIncrements") != 0) {
                    app.showAlertDialog({
                        header: i18next.t('error'),
                        content: i18next.t('withdrawal.amountIncrementProblem', { amount: getWithdrawalParam("amountIncrements")/100 }),
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                    }, null, null);
                    return false;
                }
    
                return true;
            }
            catch(err) {
                console.error(err);
                app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('withdrawal.noWithdrawalParams'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                }, null, null);
                return false;
            }
        }

    
        export function getWithdrawalParam(param:string) {
            let params = JSON.parse(localStorage.getItem("cashWithdrawal"));
            return Number(params[param]);
        }    

        export async function createRefundSale(originalPayment,response){

            let results = [];
            response.result["OriginalPaymentRequestId"] = originalPayment.requestId;
            results.push(response.result)

            let invoiceType = Storage.Entity.Sequence.TYPE_CREDIT_INVOICE

            let refundedSale = await PositiveTS.Helper.SaleHelper.initCreditSale(originalPayment.sale, invoiceType);
            refundedSale.atmTransactionId = await Service.Withdrawal.getAndIncrementWithdrawalRefundSequence()

            const surchargeInNis = Number(originalPayment.surcharge) / 100;
            const totalCharged = surchargeInNis + Number(originalPayment.amount);


            //this payment contains the actual credit card transaction that has been done
            let creditCardPayment = new PositiveTS.Storage.Entity.SalePayment(); 
            //this payment represents the cash that the customer returns. should be look at like a METHOD_CASH payment
            let withdrawalPayment = new PositiveTS.Storage.Entity.SalePayment();

            creditCardPayment.method = Storage.Entity.SalePayment.METHOD_CREDIT;
            creditCardPayment.saleID = refundedSale.id;
            creditCardPayment.amount = totalCharged * -1;
            creditCardPayment.data = JSON.stringify(results);

            withdrawalPayment.method = Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL_REFUND;
            withdrawalPayment.saleID = refundedSale.id;
            withdrawalPayment.amount = originalPayment.amount;
            withdrawalPayment.data = JSON.stringify({
                withdrawalSequence: refundedSale.atmTransactionId
            });


            
            let refundItems = originalPayment.sale.items;
            refundItems.forEach(item => {
              item.saleID = refundedSale.id;
              item.quantity *= -1;
            })

            refundedSale.totalAmount = refundItems[0].unitPrice * -1;

            refundedSale.isWithdrawal = true;

            let parentSaleJsonData = JSON.parse(originalPayment.sale.jsondata)
            let saleJsonData = JSON.parse(refundedSale.jsondata);
            saleJsonData.requestId = response.result.RequestId
            saleJsonData.terminalId = response.result.TerminalId;
            saleJsonData.terminalTranId = response.result.Uid;
            saleJsonData.FullTx = response.result;
            saleJsonData.withdrawn = parentSaleJsonData.withdrawn;
            saleJsonData.withdrawalSequence = refundedSale.atmTransactionId
            

            let sequence = await PositiveTS.Storage.Entity.Sequence.getSequenceForInvType(invoiceType);
            sequence.sequence = sequence.sequence + 1;

            refundedSale.invoiceSequence			= sequence.sequence;
            refundedSale.invoiceType 				= sequence.type;
            refundedSale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
            refundedSale.syncLastMessageTimestamp	= PositiveTS.DateUtils.fullFormat();
            refundedSale.totalVatableAmount = originalPayment.sale.totalVatableAmount * -1;

            refundedSale.jsondata = JSON.stringify(saleJsonData);
            refundedSale.totalQuantity = "-1";

            await Service.FullSale.persist(refundedSale, refundItems, [creditCardPayment, withdrawalPayment]);
            await appDB.sequences.put(sequence);
            return refundedSale



        }

        export async function createSale(response) {

                let withdrawalAmount;
                //this payment contains the actual credit card transaction that has been done
                let creditCardPayment = new PositiveTS.Storage.Entity.SalePayment(); 
                //this payment represents the money that is given to the customer. should be look at like a METHOD_CHANGE payment
                let withdrawalPayment = new PositiveTS.Storage.Entity.SalePayment();
                let dataObj = [];
    
                posVC.sale.createdAt = PositiveTS.DateUtils.fullFormat();
                posVC.sale.totalQuantity = "1";
                withdrawalAmount = posVC.saleItems[0].unitPrice;
    
                let sequenceTxId = await getAndIncrementWithdrawalSequence();
                posVC.sale.atmTransactionId = sequenceTxId;
                posVC.sale.isWithdrawal = true;
    
                dataObj.push(response);
    
                creditCardPayment.method = Storage.Entity.SalePayment.METHOD_CREDIT;
                creditCardPayment.saleID = posVC.saleItems[0].saleID;
                creditCardPayment.data = JSON.stringify(dataObj);

                withdrawalPayment.method = Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL
                withdrawalPayment.saleID = posVC.saleItems[0].saleID;
                withdrawalPayment.data = JSON.stringify({
                    withdrawalSequence: sequenceTxId
                });
                withdrawalPayment.amount = withdrawalAmount;
        
                let saleJsonData = JSON.parse(posVC.sale.jsondata);
                saleJsonData.requestId = response.RequestId
                saleJsonData.terminalId = response.TerminalId;
                saleJsonData.terminalTranId = response.Uid;
                saleJsonData.FullTx = response;
                
                let item = await getFeeItemAndBarcode();
                let feeSaleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item.item, item.itemBarcode);
        
                feeSaleItem.unitPrice = session.fixedFloat(getWithdrawalParam('surcharge')/100, 2);
                posVC.deleteSaleItem(posVC.saleItems[0]);
                feeSaleItem.saleID = posVC.sale.id;
                feeSaleItem.rowNumber = posVC.getRowNumber();
                await posVC.persistNewSaleItemWithoutGroups(feeSaleItem, true);
                creditCardPayment.amount = feeSaleItem.unitPrice + withdrawalAmount;
                posVC.sale.invoiceType = PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
                posVC.sale.totalVatableAmount = feeSaleItem.unitPrice;
                saleJsonData.withdrawn = withdrawalAmount;
                saleJsonData.withdrawalSequence = sequenceTxId;
        
                posVC.sale.jsondata = JSON.stringify(saleJsonData);
                posVC.sale.totalAmount = posVC.saleItems[0].unitPrice;
                posVC.salePayments.push(creditCardPayment);
                posVC.salePayments.push(withdrawalPayment);
                posVC.sale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
                await PositiveTS.Service.FullSale.posVCSale.IncrementSeqAndPersist();
                let fullSale = PositiveTS.Service.FullSale.posVCSale.clone();
                fullSale.salePayments.push(creditCardPayment);
                fullSale.salePayments.push(withdrawalPayment);
                posVC.cleanSale();
                await posVC.loadSale();
                posVC.afterSaleLoaded();
                posVC.isSaleCashWithdrawal = false;
                Pinia.globalStore.setWithdrawalSale(false);
                return fullSale;
        }

        export async function getWithdrawalsXZ() {
            let sales = await appDB.sales.where('invoiceSequence').above(0).toArray();
            
            let totals = getWithdrawalsCountAndAmount(sales)
    
            return { sum: totals.amount, count: totals.count};
        }

        export async function cashInPos() {
            let report = await xReportVC.calculateReport(true);
            let payments = report.payments[Storage.Entity.SalePayment.METHOD_CASH].amount
            if(!posUtils.isNullOrUndefinedOrEmptyString(report.payments[Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL])) {
                payments += report.payments[Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL].amount;
            }
            return ((payments + Number(Service.CashierStatement.getLastEnterCashierStatementNumber())) - Number(Service.CashierStatement.getCashSumWithdrawnToSafe()));
        }
        export async function posHasEnoughCash(withdrawal) {
            let cashInPos = await Withdrawal.cashInPos();
            return withdrawal <= cashInPos;
        }
        function saleIsWithdrawalRefund(sale:Storage.Entity.Sale) {
            return sale.invoiceType == Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_REFUND_INVOICE || sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE;
        }
        export function getWithdrawalsCountAndAmount(sales) {
            let totalAmount = 0;
            let saleCount = 0;
            sales.forEach((sale) => {
                if(saleIsWithdrawal(sale)) {
                        let data = JSON.parse(sale.jsondata)
                        if(sale.invoiceType == Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
                            totalAmount += data.withdrawn;
                            saleCount++;
                        } 
                        else if(sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
                            totalAmount -= data.withdrawn;
                            saleCount--;
                        }
                    // }
                    
                }
            });
    
            if(totalAmount > 0) {
                totalAmount = (totalAmount * -1)
            }
            return {amount: totalAmount, count: saleCount}
        }
    
        export function printInvoiceCopy(sale) {
            let saleJson = JSON.parse(sale.jsondata);
            let isRefund = saleIsWithdrawalRefund(sale)
            let withdrawalAmount, surchargeAmount;
            withdrawalAmount = saleJson.withdrawn;
            surchargeAmount = sale.items[0].unitPrice;
       
            Printing.Invoice.printCashWithdrawalInvoice(sale, sale.items[0], 
                surchargeAmount,
                saleJson.terminalId, saleJson.terminalTranId, isRefund, withdrawalAmount, false);
        }
    
        export function fixLength(number:string, desiredLength:number) {
            if(number.length == desiredLength) {
                return number;
            }
            else if(number.length < desiredLength) {
                let zeroes = "0".repeat(desiredLength - number.length)
                return `${zeroes}${number}`
            }
            else {
                return number.slice(desiredLength*-1);
            }
        }
    
        async function getFeeItemAndBarcode() {
            if(posUtils.isBlank(feeItemAndBarcode)) {
                let feeSpecialItem = posVC.Specialitems.find((item) => { return item.hndlr == FEE_HANDLER });
                //let itemEntity = new PositiveTS.(); 
                feeItemAndBarcode = (await Storage.Entity.Item.searchByCode(feeSpecialItem.code))[0]
                feeItemAndBarcode.itemBarcode = { size: 'null', color: 'null', barcode: 'null'};
            }
            return feeItemAndBarcode;
        }
    
        async function getAndIncrementWithdrawalSequence() {
            return appDB.transaction("rw",appDB.sales,appDB.sequences,async () => {
                let sequence = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_INVOICE);
                sequence.sequence++;
                await appDB.sequences.put(sequence);
                return sequence.sequence;
            })
        }
    
        export async function getAndIncrementWithdrawalRefundSequence() {
            return appDB.transaction("rw",appDB.sales,appDB.sequences,async () => {
                let sequence = await Storage.Entity.Sequence.getSequenceForInvType(Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_REFUND_INVOICE);
                sequence.sequence++;
                await appDB.sequences.put(sequence);
                return sequence.sequence;
            })
        }
    
    }}}
    