module PositiveTS {
export module Components {
export module PricelistCustomerPicker {

  const _vueComponentName = 'pricelist-customer-picker';

  export function create() {
    let pricelistCustomerPicker = {
        template: JST.pricelistCustomerPicker(),
        methods: {
          async open(customers) {
            this.customers = customers
            this.$el.showModal();
          },
          close() {
            this.$el.close();
          },
          async chooseCustomer(customer) {
            await Service.PriceList.setPricelistHakafaCustomerInSale(customer)
            this.close()
          }
        },
        data() {
          return { 
            customers: []
          }
        },
        computed: {
          
        }
    }

    VueApp.component(_vueComponentName,pricelistCustomerPicker)
  }
}}}
