module PositiveTS {
    
    export module Components {
        
        export module AdditionalNotesDialog {
            const dialogSelector = 'additional-notes-dialog'
            
            interface AdditionalNotesDialogOptions {
                title: string,
                existLines: string,
            }

            function disabledEscape (e) {
                if (e.key == 'Escape') {
                    e.preventDefault()
                }
            }

            export function create() {
  
                let component = {
                  name: 'additional-notes-dialog',
                  template: JST.AdditionalNotesDialog(),
                  methods: {
                    ...Mixins.modalsMixin.methods,
                    open (options: AdditionalNotesDialogOptions) {
                        if (this.dialogElem == null) {
                            this.setDialogElement()               
                        }

                        this.setOptions(options)
                        this.dialogElem.showModal()

                        this.$nextTick(() => {
                            $(document).unbind('keypress')
                            document.addEventListener('keydown', disabledEscape, true)
                        })
                        
                        return new Promise((resolve,reject) => {
                            this.resolveFunc = resolve
                            this.rejectFunc = reject
                        })
                    },
                    
                    close (closeJustTheDialog: boolean = false): void {
                        if (!closeJustTheDialog){
                            $(document).unbind('keypress')
                            document.removeEventListener('keydown', disabledEscape, true)
                            $(document).keypress(posVC.onKeyPress)
                        }
                        
                        if (this.dialogElem == null) {
                            this.setDialogElement()               
                        }
                        
                        this.dialogElem.close()   
                    },

                    cancel () {
                        this.close()
                        if (this.rejectFunc){
                            this.rejectFunc()
                        }
                        this.resetData()
                    },

                    setDialogElement () {
                        this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                    }, 

                    confirm () {
                        let lines = _.clone(this.lines)
                        _.remove(lines, (line) => {
                            return posUtils.isBlank(line)
                        })

                        if (this.resolveFunc){
                            this.resolveFunc({lines})
                        }
                        this.close()
                    },
                    
                    setLines (existLines = '') {
                        let lines: Array<string> = []
                        let mexLines = 12
                        
                        if (!posUtils.isBlank(existLines)){
                            lines = existLines.split(`\n`)
                            mexLines = mexLines - lines.length
                        }

                        for (let i = 0; i < mexLines; i++){
                            lines.push('')
                        }

                        this.lines = lines
                    },

                    backwardLine (index) {
                        if (index >= 0){
                            let elemnt = this.$refs['line'][index]
                            elemnt.focus()
                        }
                    },

                    nextLine (index) {
                        if (!this.isLineEmpty(index - 1)){
                            let elemnt = this.$refs['line'][index]
                            elemnt.focus()
                        }
                    },

                    isLineEmpty (index) {
                        return !this.lines[index] || !this.lines[index].trim()
                    },

                    resetData () {
                        Object.assign(this.$data, this.$options.data.call(this))
                    },

                    setOptions (options: AdditionalNotesDialogOptions) {
                        let defualtOptions = {
                            title: i18next.t('additionalNotesDialog.title'),
                            existLines: '',
                        }
                        
                        let optionsAssign = Object.assign(defualtOptions, options)

                        this.title = optionsAssign.title
                        this.setLines(optionsAssign.existLines)
                    }
                  },
                  data () {
                    const data: {
                        dialogElem: any,
                        title: string,
                        lines: Array<string>,
                        maxLengthOfLine: number,
                        resolveFunc: any,
                        rejectFunc: any,
                    } = {
                        dialogElem: null,
                        title: i18next.t('additionalNotesDialog.title'),
                        maxLengthOfLine: 40,
                        lines: [],
                        resolveFunc: null,
                        rejectFunc: null,
                    }
                    return data
                },
                  computed: {
                  },  
                  mounted () {
                      this.setDialogElement()
                      this.setLines()
                  }
                }

                VueApp.component('additional-notes-dialog',component)
            }

        }

    }

}

