module PositiveTS {
	export module UI {
		export function TableView(thisElement, options) {
			let elementOptions:any = {};
			if (thisElement.attr('data-number-of-columns') != undefined) {
				elementOptions.numberOfColumns = thisElement.attr('data-number-of-columns');
			}
		
			if (thisElement.attr('data-columns-width') != undefined) {
				elementOptions.columnsWidth = thisElement.attr('data-columns-width').split(',');
			}
		
			if (thisElement.attr('data-number-of-visible-rows') != undefined) {
				elementOptions.numberOfVisibleRows = thisElement.attr('data-number-of-visible-rows');
			}
		
			if (thisElement.attr('data-header') != undefined) {
				elementOptions.headerText = thisElement.attr('data-header');
				elementOptions.hasHeader = true;
			}
		
			if (thisElement.attr('data-empty-message') != undefined) {
				elementOptions.emptyMessage = thisElement.attr('data-empty-message');
			}
		
			if (thisElement.attr('data-row-alternate-background') != undefined) {
				elementOptions.rowAlternateBackground = (thisElement.attr('data-row-alternate-background') === 'true');
			}
			if (thisElement.attr('data-number-of-cells-on-row') != undefined) {
				elementOptions.numberOfCellsOnRow = thisElement.attr('data-number-of-cells-on-row');
			}
		
			if (thisElement.attr('resize-with-css-calc') != undefined) {
				elementOptions.resizeWithCssCalc = true
			}
			var opts = $.extend(elementOptions, options);
		
			// Default options...
			var defaults = {
				columnsWidth: [],
				resizeWithCssCalc: false,
				numberOfColumns: 4,
				numberOfVisibleRows: 4,
				hasHeader: false,
				headerText: '',
				emptyMessage: '',
				rowAlternateBackground: true,
				numberOfCellsOnRow : 1,
				scollable: true,
				isMultiSelection: false
			};
			var opts = $.extend(defaults, opts);
		
			var selectRowListeners = {};
		
			// If number of columns is different from the columnsWidth array length, make the width equal
			if (opts.numberOfColumns != opts.columnsWidth.length) {
				opts.columnsWidth = [];
			}
		
			// Unique ID
			var id = 'ui-tableview-' + Math.floor(Math.random()*1000);
			this.id = id;
		
			// Add div which will bound the table in height
			thisElement.append('<div id="' + id + '-container" class="ui-tableview-container"></div>');
			var container = $(thisElement).find(' > #' + id + '-container');
		
			// Scroller
			var scrollerIndicatorContainer = $('<div></div>').addClass('ui-tableview-container-scroller-ind-container');
			var scrollerIndicator = $('<div></div>').addClass('ui-tableview-container-scroller-ind');
			scrollerIndicatorContainer.append(scrollerIndicator);
		
			var upScroll = $('<div><img src="' + (<any>window).images_path + 'scroll_up.png" /></div>').addClass('ui-tableview-container-scroller-up');
			upScroll.click(function () {
				if (container.hasClass('ui-tableview-scrolling')) {
					return;
				}
				container.addClass('ui-tableview-scrolling');
				container.animate({scrollTop:  container.scrollTop() - container.height()*0.9}, 100, function() {
					container.removeClass('ui-tableview-scrolling');
				});
			});
		
			var downScroll = $('<div><img src="' + (<any>window).images_path + 'scroll_down.png" /></div>').addClass('ui-tableview-container-scroller-down');
			downScroll.click(function () {
				if (container.hasClass('ui-tableview-scrolling')) {
					return;
				}
				container.addClass('ui-tableview-scrolling');
				container.animate({scrollTop: container.scrollTop() + container.height()*0.9}, 100, function() {
					container.removeClass('ui-tableview-scrolling');
				});
			});
		
			if (opts.scollable) {
				container.before(upScroll);
				container.before(scrollerIndicatorContainer);
				container.after(downScroll);
		
				// If we are in touch environment, add overflow-y: scroll (for swipe events...)
				if (Modernizr.touch) {
					container.css({'overflow-y': 'scroll'});
				}
			}
		
		
			// Add UL element which will contain all the li elements...
			container.append('<table id="' + id + '" class="ui-tableview"><tbody></tbody></table>');
			var tableView = $(container).find(' > #' + id + ' > tbody');
		
			var tableViewHeader;
			if (opts.hasHeader) {
				// Prepend a default header
				thisElement.prepend('<div id="' + id + '-header" class="ui-tableview-header">' + opts.headerText + '</div>');
				tableViewHeader = $(thisElement).find(' > #' + id + '-header');
			}
		
			// Append a footer
			tableView.append('<tr id="' + id + '-footer" class="ui-tableview-footer"></tr>');
			var tableViewFooter = $(tableView).find(' > #' + id + '-footer');
		
			// Append a spacer
			tableView.append('<tr id="' + id + '-spacer" class="ui-tableview-spacer"><td colspan="' + opts.numberOfColumns + '"></td></tr>');
			var tableViewSpacer = $(tableView).find(' > #' + id + '-spacer');
		
			// Empty message
			var emptyMessageRow = $('<tr><td colspan="' + opts.numberOfColumns + '"></td></tr>');
			emptyMessageRow.addClass('ui-tableview-row-empty');
			emptyMessageRow.find(' > td').html(opts.emptyMessage);
			tableViewFooter.before(emptyMessageRow);
		
			// Set the columns width by adding a row with th elements
			var initializeColumns = function (options) {
				var sameWidth = false;
				if (options.columnsWidth.length == 0) {
					options.columnsWidth = 100 / options.numberOfColumns + '%';
					sameWidth = true;
				}
		
				var row = $('<tr></tr>');
				for (var i = 0; i < options.numberOfColumns; i++) {
					var width;
					if (sameWidth) {
						width = options.columnsWidth;
					} else {
						width = options.columnsWidth[i];
					}
		
					var cell = $('<th></th>');
					cell.css({width: width, height: '0px'});
					row.append(cell);
				}
		
				tableView.prepend(row);
			};
			initializeColumns(opts);
		
			//
			var autoResizeRelativeTo = window;
			var autoResizeComputeRelativeTo = null;
			var autoResizeTable = function () {
				if (opts.resizeWithCssCalc) {
					return;
				}
				var newTableHeightOffset = $(autoResizeRelativeTo).height() - $('#footer').height();
				if (autoResizeComputeRelativeTo != null && $(autoResizeComputeRelativeTo).length > 0) {
					newTableHeightOffset = newTableHeightOffset - $(autoResizeComputeRelativeTo).offset().top - $(autoResizeComputeRelativeTo).height();
				}
				var currentTableHeight = container.height();
		
				var newTableHeight = currentTableHeight  + newTableHeightOffset;
				if (newTableHeight < 200) {
				   newTableHeight = 200;
				}
				container.height(newTableHeight);
		
			};
		
			this.setHeader = function (headerHtml) {
				if (opts.hasHeader) {
					tableViewHeader.html(headerHtml);
				}
			};
		
			this.setFooter = function (cellValues) {
				// Remove any sub-elements of the footer row
				tableViewFooter.empty();
		
				// Add the data
				for (var i = 0; i < cellValues.length; i++) {
					tableViewFooter.append($('<td></td>').html(cellValues[i]).addClass('ui-tableview-row-cell'));
				}
		
				// Add onclick listener
				var aThis = this;
				tableViewFooter.click(function (event) {
					aThis.selectRow($(this).attr('id'));
				});
		
				return tableViewFooter;
			};
		
			this.footer = function () {
				return tableViewFooter;
			};
		
			this.removeFooter = function () {
				// Remove any sub-elements of the footer row
				tableViewFooter.empty();
			};
		
			var rowIndex = 0;
			this.addRow = function (cellValues, selected, clickHandler?, additionalClassName?) {
				return this.addRowBefore(cellValues, selected, tableViewFooter, clickHandler, additionalClassName);
			};
		
			this.addRowBefore = function (cellValues, selected, element, clickHandler?, additionalClassName?) {
				// Remove the empty message if it is displayed
				if ($(tableView).find(' .ui-tableview-row-empty').length > 0) {
					$(tableView).find(' .ui-tableview-row-empty').remove();
				}
				var rowPosition = rowIndex;
				rowIndex = rowIndex + 1;
				var rowID = id + '-row-' + rowPosition;
				var row = $('<tr></tr>').attr({id: rowID}).addClass('ui-tableview-row');
				if (opts.rowAlternateBackground && rowPosition % 2 == 0) {
					row.addClass('ui-tableview-row-alt');
				}
				for (var i = 0; i < cellValues.length; i++) {
					let cellVal = cellValues[i];
					let cellClass = '';
					let cellPrefix = '';
					if (Array.isArray(cellValues[i])) {
						cellVal = cellValues[i][0];
						cellClass = cellValues[i][1] || '';
						cellPrefix = cellValues[i][2] || '';
					}
					
					let elem = $('<td></td>').html(cellPrefix+cellVal).addClass(`ui-tableview-row-cell ${additionalClassName || ''}`).addClass(cellClass)
					if (typeof cellVal === "number") {
						elem = elem.addClass('cell-number')
					}
					row.append(elem);
				}
				$(element).before(row);
		
				// Add onclick listener
				var aThis = this;
				row.click(function (event) {
					aThis.selectRow($(this).attr('id'), opts.isMultiSelection);
					if (clickHandler) {
						clickHandler(row);
					}
				});
		
		
				// If the row needs to be selected, select it
				if (selected === true) {
					this.selectRow(rowID);
				}
		
				return row;
			};
		
			// this.addRowAfter = function (cellValues, selected, element) {
			// 	// Remove the empty message if it is displayed
			// 	if ($(tableView).find(' .ui-tableview-row-empty').length > 0) {
			// 		$(tableView).find(' .ui-tableview-row-empty').remove();
			// 	}
		
			// 	var rowPosition = rowIndex;
			// 	rowIndex = rowIndex + 1;
			// 	var rowID = id + '-row-' + rowPosition;
			// 	var row = $('<tr></tr>').attr({id: rowID}).addClass('ui-tableview-row');
			// 	if (opts.rowAlternateBackground && rowPosition % 2 == 0) {
			// 		row.addClass('ui-tableview-row-alt');
			// 	}
			// 	for (var i = 0; i < cellValues.length; i++) {
			// 		let elem = $('<td></td>').html(cellValues[i]).addClass('ui-tableview-row-cell')
			// 		if (typeof cellValues[i] === "number") {
			// 			elem = elem.addClass('cell-number')
			// 		}
			// 		row.append(elem);
			// 	}
			// 	$(element).after(row);
		
			// 	// Add onclick listener
			// 	var aThis = this;
			// 	row.click(function (event) {
			// 		aThis.selectRow($(this).attr('id'));
			// 	});
		
			// 	// If the row needs to be selected, select it
			// 	if (selected === true) {
			// 		this.selectRow(rowID);
			// 	}
		
			// 	return row;
			// };
		
			this.removeRow = function (rowID) {
				tableView.find(' > #' + rowID).remove();
		
				if (tableView.find(' > .ui-tableview-row').length == 0) {
					// If no rows left, display the empty message
					tableViewFooter.before(emptyMessageRow);
				}
		
			};
		
			this.empty = function () {
				// Remove all the rows
				tableView.find(' > .ui-tableview-row').remove();
		
				// Display the empty message
				this.removeFooter();
				tableViewFooter.before(emptyMessageRow);
		
			};
		
			this.setEmptyMessage = (emptyMessageText) => {
				$('.ui-tableview-row-empty > td').html(emptyMessageText);
			}
		
			this.setNumberOfVisibleRows = function (numberOfRows, options) {
				if (opts.resizeWithCssCalc) {
					return;
				}
				options = options || {};
				options.em = options.em || 2.5;
				container.css({height: numberOfRows * Number(options.em) + 'em'});
		
			};
		
			// A timeout used to scroll the tableview. If there is more than 1 request to scroll to tableview in 1sec, only the last one will be executed.
			var scrollTimeout = null;
		
			this.scrollToRow = function (rowID, onlyIfRowIsHidden) {
				var rowPositionTop = $('#' + rowID).position().top;
				if (onlyIfRowIsHidden) {
					var containerScrollTop = container.scrollTop();
					if (rowPositionTop >= containerScrollTop && rowPositionTop + $('#' + rowID).height() <= containerScrollTop + container.height()) {
						return;
					}
				}
		
				// Clear the previoud timeout
				clearTimeout(scrollTimeout);
		
				// Set the new timeout
				scrollTimeout = setTimeout(function () {
					container.animate({scrollTop: rowPositionTop}, 100,() => {});
				}, 10);
			};
		
			this.scrollBottom = function () {
				// Clear the previoud timeout
				clearTimeout(scrollTimeout);
		
				// Set the new timeout
				scrollTimeout = setTimeout(() => {
					container.scrollTop(99999999);
				}, 10);
			};
		
			this.selectRow = function (rowID, allowMultiRowSelection) {
				if (arguments.length == 0) {
					// Select the last row
					rowID = tableView.find(' > .ui-tableview-row').last().attr('id');
				}
		
				if (allowMultiRowSelection != true) {
					tableView.find(' > .ui-tableview-row').removeClass('selected');
					tableViewFooter.removeClass('selected');
					$('#' + rowID).addClass('selected');
				} else {
					$('#' + rowID).toggleClass('selected');
				}
		
				var theRow = $('#' + rowID);
				$.each(selectRowListeners, function (id, listener) {
					listener.call(theRow, theRow);
				});
			};
		
			this.selectFirstRow = function () {
				tableView.find(' > .ui-tableview-row').removeClass('selected');
				tableViewFooter.removeClass('selected');
		
				tableView.find(' > .ui-tableview-row').first().addClass('selected');
			};
		
			this.getSelectedRow = function () {
				return tableView.find(' > .selected');
			};
		
			this.getAllRows = function(){
			 return tableView.find(' > .ui-tableview-row')
			}
		
		
			this.attachSelectRowListener = function (id, listener) {
				selectRowListeners[id] = listener;
			};
		
			this.removeSelectRowListener = function (id) {
				delete selectRowListeners[id];
			};
		
			this.setAutoResizeOn = function (computeRelativeTo, relativeTo) {
				// Set auto resize off, to prevent duplicate listeners
				this.setAutoResizeOff();
		
				// Set the element to resize relative to it
				if (relativeTo == null || $(relativeTo).length === 0) {
					relativeTo = window;
				}
				autoResizeRelativeTo = relativeTo;
		
				// Set the element to compute the resize relative to it (can be null!)
				autoResizeComputeRelativeTo = computeRelativeTo;
		
				// Set the tableView's resize listener
				$(window).on('resize', autoResizeTable);
		
				// Call the resize function to force the first resize
				// $(window).trigger('resize');
				autoResizeTable();
			};
		
			this.setAutoResizeOff = function () {
				// Set the element to resize relative to it to null
				autoResizeRelativeTo = window;
		
				// Set the element to compute the resize relative to null
				autoResizeComputeRelativeTo = null;
		
				// Unbind the tableView's resize listener
				$(window).unbind('resize', autoResizeTable);
			};
		
			// By default, show only some of the rows
			this.setNumberOfVisibleRows(opts.numberOfVisibleRows);
		
			//Return the number of rows in the tables
			this.size = function(){
				return rowIndex;
			};
		
			// Register the instance
			PositiveTS.UI.Base.registerInstance(id, this);
		
			return this;
		}
	}
}


// Add it to jQuery prototype
jQuery.fn.tableview = function (options) {
	if (this.find(' > .ui-tableview-container').length > 0) {
		if (options && options.freshInstance) {
			let currId = this.find(' > .ui-tableview-container > .ui-tableview').attr('id');
			PositiveTS.UI.Base.destroyInstance(this,currId);
			return (new (PositiveTS.UI.TableView as any)(this, options));
		}
		else {
			let instance = PositiveTS.UI.Base.getInstance(this.find(' > .ui-tableview-container > .ui-tableview').attr('id'));
			if(instance){
				return instance;
			}
			else{
				let currId = this.find(' > .ui-tableview-container > .ui-tableview').attr('id');
				PositiveTS.UI.Base.destroyInstance(this,currId);
				return (new (PositiveTS.UI.TableView as any)(this, options));
			}
		}
	}

	return (new (PositiveTS.UI.TableView as any)(this, options));
};
