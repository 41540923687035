module PositiveTS {
  export module Service {
    export module Dalpak {
      const URL = "/dalpaks";
      const DALPAK_AREA_URL = "/dalpak_areas"
      let dalpakEngine: DalpakInfra.DalpakEngine = null;

      export const DALPAK_STATUSES = {
        EMPTY: 0,
        MEAL: 1,
        PAYMENT: 2,
        WARNING: 3,
      }

      export const DALPAK_COLORS_BY_STATUS = {
        [DALPAK_STATUSES.EMPTY]: "var(--dark-lite)",
        [DALPAK_STATUSES.MEAL]: "var(--additional-default)",
        [DALPAK_STATUSES.PAYMENT]: "var(--success-default)",
        [DALPAK_STATUSES.WARNING]: "var(--danger-default)",
      }

      export const DALPAK_ICONS_BY_STATUS = {
        [DALPAK_STATUSES.MEAL]: "fa-fork-knife",
        [DALPAK_STATUSES.PAYMENT]: "fa-ballot-check",
        [DALPAK_STATUSES.WARNING]: "fa-bell-exclamation",
      }

      export const DALPAK_EDIT_MARKS_PROPS = {
        NEW: '_new',
        DELETE: '_destroy',
      }
      
      export function getDalpakStatus(dalpak) {
        if (!dalpak.sale) {
          return DALPAK_STATUSES.EMPTY;
        }

        if (!posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.tableWarningTimeInMin)) && 
            jsonConfig.getVal(jsonConfig.KEYS.tableWarningTimeInMin) < getDalpakLastChangedMinutes(dalpak)) {
          return DALPAK_STATUSES.WARNING;
        }

        if (dalpak.isPrinted) {
          return DALPAK_STATUSES.PAYMENT;
        }

        return DALPAK_STATUSES.MEAL;
      }

      export function getDalpakLastChangedMinutes(dalpak) {
        let sale = dalpak.sale;

        if (!sale) {
          return null;
        }
        if(sale.items?.length > 0) {
          let lastSaleItem = sale.items[sale.items.length - 1];
          let formatDateTime = 'DD/MM/YYYY HH:mm:ss';
          let saleItemAddedAt = moment(lastSaleItem.addTimestamp, formatDateTime);
          
          return Math.floor(moment.duration(moment().diff(saleItemAddedAt)).asMinutes());
        }else {
          return null;
        }

      }

     export function getDalpakSaleCustomer(dalpak) {
        let sale = dalpak.sale;

        if (!sale || posUtils.isBlank(sale.jsondata)) {
          return null;
        }

        let jd = JSON.parse(sale.jsondata);

        if (jd.dedicatedTo) {
          return jd.dedicatedTo;
        }

        if (jd.customer) {
          if (jd.customer?.s_first_name && !jd.customer?.s_last_name) {
            return `${jd.customer.s_first_name}`.trim();
          }
          if (jd.customer?.s_first_name && jd.customer?.s_last_name) {
            return `${jd.customer.s_first_name} ${jd.customer.s_last_name}`.trim();
          }

          if ( jd.customer.s_id_number ){
            return jd.customer.s_id_number.toString();
          } 
  
          if (!posUtils.isBlank(jd.customer.s_phone_number_1)) {
            return jd.customer.s_phone_number_1;
          }
        }

        return null;
      }
      export function isRestaurantNewPOS(){
          let restaurantNewPOS =  jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS);
          let dalpakTablesView =  jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView);
          return dalpakTablesView && restaurantNewPOS;
      }

      export function getDalpakTotals(dalpak) {
        let sale = dalpak.sale;
        let totals = {
          avgPerDiner: 0,
          totalAmount: 0,
        }

        if (sale) {

          let saleTotals = Helper.SaleHelper.calcuateSaleTotals(sale, sale.items, sale.payments);

          if (sale.diners) {
            totals.totalAmount = saleTotals.totalAmount;
          }

          totals.avgPerDiner = saleTotals.totalAmount / sale.diners;

          if (sale.diners === 0) {
            totals.avgPerDiner = saleTotals.totalAmount;
          }
        }

        return totals;
      };


      export async function exitDalpakAndSaveSale() {
        let dalpak = Pinia.dalpaksStore.currentSelectedDalpak;
        let result;

        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline) && !posUtils.isBlank(posVC.salePayments)) {
            app.hideLoadingMessage();
            await app.promiseShowAlert({
              header: i18next.t('payAttention'),
              content: i18next.t('dalpaks.saleWithPayments'),
              continueButtonText: i18next.t("ok"),
              hideCancelButton: true
            })
          result = await saveDalpakSale(dalpak, FullSale.getFullSaleFormat(posVC.sale, posVC.saleItems, posVC.salePayments));
        } else {
          result = await saveDalpakSaleAndUnlock(dalpak, FullSale.getFullSaleFormat(posVC.sale, posVC.saleItems, posVC.salePayments));
        }  

        if (result.success) {
          await appDB.sales.delete(posVC.sale.id);
          posVC.cleanSale(null, false);
          openDalpakScreen();
        } else {
          return app.showAlertDialog({
            header: i18next.t('error'),
            content: result.errorMessage || i18next.t('requestFailed'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          });
        }
      }

      export async function loadDalpakSale(dalpak) {
        if (posUtils.isBlank(dalpak.sale)) {
          throw new Error('sale not found on dalpak ' + dalpak.id);
        }



        dalpak.sale.posPhysicalID = session.pos.physicalID;
        dalpak.sale.posDeviceID = session.pos.deviceID;
        dalpak.sale.posNumber = session.pos.posNumber;

        await appDB.sales.put(dalpak.sale);

        await posVC.fetchSale(dalpak.sale);
      }

      export function init() {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline)) {

          if (WebsocketSync.SyncServerClient.isPrimaryPos()) {
            dalpakEngine = new DalpakInfra.DalpakWebsocketWithLocalEngine(true);
          } else {
            dalpakEngine = new DalpakInfra.DalpakWebsocketEngine(false);
          }
        } else {
          dalpakEngine = new DalpakInfra.DalpakLocalEngine();
        }

        if (localStorage.getItem('dalpaksUpdateNeeded') == 'true') {
          if (isPrimaryPosOrSinglePos()) {
            createDataAtEngineIfNeeded().then(() => localStorage.removeItem('dalpaksUpdateNeeded'));
          } else {
            localStorage.removeItem('dalpaksUpdateNeeded');
          }
        }
      }

      export function isPrimaryPosOrSinglePos() {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline)) {
          return WebsocketSync.SyncServerClient.isPrimaryPos();
        } else {
          return true;
        }

      }

      function showError(errorText: string = null) {
        return app.showAlertDialog({
          header: i18next.t('error'),
          content: errorText || i18next.t('generalError'),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        })
      }

      function showErrorAndLog(err: Error, errorMessage: string) {
        showError(errorMessage);
        console.error(err)
        Service.Logger.error(err);
      }

      export async function loadAllDalpaks() {
        app.showLoadingMessage(i18next.t("dalpaks.loadingDalpaks"));

        let dalpaksResult = null;

        try {
          dalpaksResult = await dalpakEngine.getAll();

          if (dalpaksResult.success) {
            if (!dalpakEngine.isDataAlwaysUpToDate()) {
              Pinia.dalpaksStore.setDalpaks(dalpaksResult.data)
            }
          } else {
            showError(dalpaksResult.errorMessage || i18next.t('dalpaks.errorLoadingDalpaks'));
            app.hideLoadingMessage();
            return null;
          }

        } catch (err) {
          showErrorAndLog(err, i18next.t('dalpaks.errorLoadingDalpaks'));
          app.hideLoadingMessage();
          return null;
        }

        app.hideLoadingMessage();

        return dalpaksResult.data;
      }

      export async function loadAllDalpakAreas() {
        app.showLoadingMessage(i18next.t("dalpakAreas.loadingDalpakAreas"));
        let dalpakAreas = null;
        try {
          dalpakAreas = await dalpakEngine.getAllDalpakAreas();

          if (dalpakAreas.success) {
            if (!dalpakEngine.isDataAlwaysUpToDate()) {
              Pinia.dalpaksStore.setDalpakAreas(dalpakAreas.data)
            }
          } else {
            showError(dalpakAreas.errorMessage || i18next.t('dalpakAreas.errorLoadingDalpakAreas'));
            app.hideLoadingMessage();
            return null;
          }

        } catch (err) {
          showErrorAndLog(err, i18next.t('dalpakAreas.errorLoadingDalpakAreas'));
          app.hideLoadingMessage();
          return null;
        }

        app.hideLoadingMessage();

        return dalpakAreas.data;
      }

      export async function refreshDalpaksVuexIfNeeded() {
        if (!dalpakEngine.isDataAlwaysUpToDate()) {
          let dalpakAreasResult = await dalpakEngine.getAllDalpakAreas()
          if (dalpakAreasResult.success) {
            Pinia.dalpaksStore.setDalpakAreas(dalpakAreasResult.data)
          }

          let dalpaksResult = await dalpakEngine.getAll();
          if (dalpaksResult.success) {
            Pinia.dalpaksStore.setDalpaks(dalpaksResult.data)
          }
        }
      }

      export function openDalpakScreen() {
        if (Pinia.globalStore.posState != 'pos') {
          return;
        }

        if (isOtherInstanceAlreadyOpen) {
          Service.MultiplePosesOpened.showOtherTabsMessage(session.pos.isCaveret);
          return
        }

        posVC.RedirectIfAuthorized(false, () => {
          pNavigator.pushPage('pages', '', null, { initParams: { route: '/dalpak-selection' } })
        });
      }

      export function getDalpakFromRailsId(railsId: string) {
        if (posUtils.isBlank(Pinia.dalpaksStore.allDalpaks)) {
          if (posUtils.isDefined(Pinia.dalpaksStore.currentSelectedDalpak) && Pinia.dalpaksStore.currentSelectedDalpak.railsId  == railsId) {
            return Pinia.dalpaksStore.currentSelectedDalpak;
          }
          
          return null;
        }

        return Pinia.dalpaksStore.allDalpaks.filter(d => d.railsId == railsId)[0];
      }

      export function validateDalpakAndShowErrors(dalpak) {
        if (!Pinia.globalStore.isOnline) {
          showError(i18next.t('mustBeOnline'));
          return false;
        }

        if (posUtils.isBlank(dalpak) || posUtils.isBlank(dalpak.name)) {
          showError(i18next.t('dalpaks.dalpakNameMissing'));
          return false;
        }


        if (posUtils.isBlank(dalpak) || posUtils.isBlank(dalpak.sortOrder) || !posUtils.isNumeric(dalpak.sortOrder)) {
          showError(i18next.t('dalpaks.invalidOrder'));
          return false;
        }

        if (!posUtils.isBlank(dalpak.data)) {
          if (!posUtils.isBlank(dalpak.data.fixedDiscount)) {
            if (!posUtils.isNumeric(dalpak.data.fixedDiscount) || dalpak.data.fixedDiscount < 0 || dalpak.data.fixedDiscount > 100) {
              showError(i18next.t('dalpaks.invalidFixedDiscount'));
              return false;
            }
          }
        }

        return true;
      }

      export function validateDalpakAreaAndShowErrors(dalpakArea) {
        if (!Pinia.globalStore.isOnline) {
          showError(i18next.t('mustBeOnline'));
          return false;
        }

        if (posUtils.isBlank(dalpakArea) || posUtils.isBlank(dalpakArea.name)) {
          showError(i18next.t('dalpakAreas.dalpakAreaNameMissing'));
          return false;
        }

        return true;
      }

      export async function createNewDalpak(dalpak) {
        if (!validateDalpakAndShowErrors(dalpak)) {
          return false;
        }

        try {
          app.showLoadingMessage(i18next.t('dalpaks.creatingDalpak'))

          let response = await PositiveTS.Service.FetchReq.jsonReq(URL, 'post', convertDataToRails(dalpak));

          if (!(response.result && response.result.success && response.result.dalpak)) {

            if (response.result.errors) {

              let errors = Object.values(response.result.errors);

              if (errors.length > 0 && (errors[0] as any).length > 0) {
                app.hideLoadingMessage();
                showError(errors[0][0]);
                return false;

              } else {
                throw new Error(response.result);
              }

            } else {
              throw new Error(response.result);
            }

          }

          let result = await createDalpakAtEngine(convertToEngineData(response.result.dalpak));

          if (!result.success) {
            showError(result.errorMessage || i18next.t('requestFailed'));
          }

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showError(i18next.t('requestFailed'));
          return false;
        }

      }

      export async function createNewDalpakArea(dalpakArea, dalpaksToTransfer) {
        if (!validateDalpakAreaAndShowErrors(dalpakArea)) {
          return false;
        }

        try {
          app.showLoadingMessage(i18next.t('dalpakAreas.creatingDalpakArea'))

          let converetedDalpaks = dalpaksToTransfer && dalpaksToTransfer.length > 0 ? dalpaksToTransfer.map(dalpak => {
            return convertDataToRails(dalpak)
          }) : []

          let response = await PositiveTS.Service.FetchReq.jsonReq(DALPAK_AREA_URL, 'post', {dalpakArea: convertDataToRails(dalpakArea), dalpaksToTransfer: converetedDalpaks});

          if (!(response.result && response.result.success && response.result.dalpak_area)) {

            if (response.result.errors) {

              let errors = Object.values(response.result.errors);

              if (errors.length > 0 && (errors[0] as any).length > 0) {
                app.hideLoadingMessage();
                showError(errors[0][0]);
                return false;

              } else {
                throw new Error(response.result);
              }

            } else {
              throw new Error(response.result);
            }

          }

          let result = await dalpakEngine.saveDalpakAreaAttributes(convertToEngineData(response.result.dalpak_area));

          if (!result.success) {
            showError(result.errorMessage || i18next.t('requestFailed'));
            app.hideLoadingMessage();
            return false;
          }
          if(dalpaksToTransfer.length > 0 ) {
            let dalpakAreaRailsIdToTransfer = response.result.dalpak_area.id
            let moveDalpaksResult = await dalpakEngine.moveDalpaksToArea(dalpaksToTransfer, dalpakAreaRailsIdToTransfer)
            if (!moveDalpaksResult.success) {
              showError(moveDalpaksResult.errorMessage || i18next.t('requestFailed'));
            }
          }

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showError(i18next.t('requestFailed'));
          return false;
        }

      }

      export async function deleteDalpak(dalpak) {
        app.showLoadingMessage(i18next.t('dalpaks.deletionDalpak'))

        try {
          let response = await PositiveTS.Service.FetchReq.jsonReq(`${URL}/${dalpak.railsId}`, 'delete', dalpak);

          if (!(response.result && response.result.success)) {

            if (response.result.error) {

              app.hideLoadingMessage();
              showError(response.result.error);
              return false;

            } else {
              throw new Error(response.result);
            }
          }

          let result = await dalpakEngine.deleteDalpak(dalpak);

          if (!result.success) {
            showError(result.errorMessage || i18next.t('requestFailed'));
          }

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showErrorAndLog(err, i18next.t('requestFailed'));
          return false;
        }
      }


      export async function deleteDalpakArea(dalpakArea: DalpakInfra.DalpakArea, dalpakAreaRailsIdToTransfer: string, dalpaksToMove: DalpakInfra.Dalpak[]) {
        app.showLoadingMessage(i18next.t('dalpakAreas.deletingDalpakArea'))

        try {
          let response = await PositiveTS.Service.FetchReq.jsonReq(`${DALPAK_AREA_URL}/${dalpakArea.railsId}`, 'delete', dalpakArea);

          if (!(response.result && response.result.success)) {

            if (response.result.error) {

              app.hideLoadingMessage();
              showError(response.result.error);
              return false;

            } else {
              throw new Error(response.result);
            }
          }

          let canDeleteDalpakArea = true

          if(!posUtils.isBlank(dalpaksToMove)) {
            let moveDalpaksResult = await dalpakEngine.moveDalpaksToArea(dalpaksToMove, dalpakAreaRailsIdToTransfer)
            if (!moveDalpaksResult.success) {
              canDeleteDalpakArea = false
              showError(moveDalpaksResult.errorMessage || i18next.t('requestFailed'));
            }
          }

          if(canDeleteDalpakArea) {
            let result = await dalpakEngine.deleteDalpakArea(dalpakArea);
            if (!result.success) {
              showError(result.errorMessage || i18next.t('requestFailed'));
            }
          }

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showErrorAndLog(err, i18next.t('requestFailed'));
          return false;
        }
      }  

      export async function dalpakUpdateArea(dalpaks, newDalpakAreaId) {
        app.showLoadingMessage(i18next.t('dalpakAreas.transferingDalpaks'))

        let dalpakIds = dalpaks.map(dalpak => {
          return parseInt(dalpak.railsId)
        }) 

        try {
          let response = await PositiveTS.Service.FetchReq.jsonReq(`${URL}/update_area`, 'post', {dalpakIds: dalpakIds, newDalpakAreaId: newDalpakAreaId});

          if (!(response.result && response.result.success)) {

            if (response.result.error) {

              app.hideLoadingMessage();
              showError(response.result.error);
              return false;

            } else {
              throw new Error(response.result);
            }
          }

          let result = await dalpakEngine.moveDalpaksToArea(dalpaks, newDalpakAreaId)
          if (!result.success) {
            showError(result.errorMessage || i18next.t('requestFailed'));
          }

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showErrorAndLog(err, i18next.t('requestFailed'));
          return false;
        }
      }

      export async function saveDalpakSaleAndUnlock(dalpak, sale): Promise<DalpakInfra.InfraResult<any>> {
        dalpak.sale = sale;

        if (dalpak.area == DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES && posUtils.isBlank(sale)) {
          return await dalpakEngine.deleteDalpak(dalpak);
        }

        return await dalpakEngine.saveSaleAndUnlock(dalpak);
      }

      export async function saveDalpakSale(dalpak, sale) {
        dalpak.sale = sale;

        return await dalpakEngine.saveSale(dalpak, false);
      }


      export async function bulkUpdateDalpaksAndAreasAttributes(dalpaks, dalpakAreas) {
        if (!Pinia.globalStore.isOnline) {
          showError(i18next.t('mustBeOnline'));
          return false;
        }

        let response = null;

        try {
          app.showLoadingMessage(i18next.t('dalpaks.updatingTables'))

          let dataToUpdate = {dalpaks: _.cloneDeep(dalpaks).map(convertDataToRails),
                              dalpakAreas: _.cloneDeep(dalpakAreas).map(convertDataToRails)}

          response = await PositiveTS.Service.FetchReq.jsonReq(`${URL}/bulk_update_attributes`, 'post', dataToUpdate);

          if (!(response.result && response.result.dalpaks)) {
            throw new Error(response.result);
          }

        } catch (err) {
          app.hideLoadingMessage();
          showError(i18next.t('requestFailed'));
          return false;
        }

        try {
          let serverDalpaksByName = _.keyBy(response.result.dalpaks, 'name');
          let dalpakServerAttributesWithLocal = dalpaks.map(dalpak => {
            let dalpakFromServer = serverDalpaksByName[dalpak.name] ? convertToEngineData(serverDalpaksByName[dalpak.name]) : {};

            if (dalpakFromServer.area) {
              dalpak.area = dalpakFromServer.area;
            }
            return {...dalpakFromServer, ...dalpak};
          });

          let serverDalpakAreasById = _.keyBy(response.result.dalpak_areas, area => area.old_id || area.id);
          let dalpakAreasServerAttributesWithLocal = dalpakAreas.map(dalpakArea => {
            let areaFromServer = serverDalpakAreasById[dalpakArea.id] ? convertToEngineData(serverDalpakAreasById[dalpakArea.id]) : {};

            if (dalpakArea[Dalpak.DALPAK_EDIT_MARKS_PROPS.NEW]) {
              delete dalpakArea.railsId;
              delete areaFromServer.oldId;
            }

            return {...areaFromServer, ...dalpakArea};
          });          

          let result = await dalpakEngine.bulkUpdateDalpaksAndAreasAttributes({dalpaks: dalpakServerAttributesWithLocal, dalpakAreas: dalpakAreasServerAttributesWithLocal});    

          if (!result.success) {
            throw new Error(result.errorMessage);
          }

          await refreshDalpaksVuexIfNeeded();      
        } catch (err) {
          Service.Logger.error(err);
          app.hideLoadingMessage();
          showError(i18next.t('generalError'));
          return false;
        }

        app.hideLoadingMessage();

        return true;

      }

      export async function updateDalpak(dalpak) {
        if (!validateDalpakAndShowErrors(dalpak)) {
          return false;
        }

        try {
          app.showLoadingMessage(i18next.t('dalpaks.updatingDalpak'))

          let response = await PositiveTS.Service.FetchReq.jsonReq(`${URL}/${dalpak.railsId}`, 'put', convertDataToRails(dalpak));

          if (!(response.result && response.result.success && response.result.dalpak)) {

            if (response.result.errors) {

              let errors = Object.values(response.result.errors);

              if (errors.length > 0 && (errors[0] as any).length > 0) {
                let errorMessage = Array.isArray(errors[0]) ? errors[0][0] : errors[0];

                app.hideLoadingMessage();
                showError(errorMessage);
                return false;

              } else {
                throw new Error(response.result);
              }

            } else {
              throw new Error(response.result);
            }

          }

          // Doing like this so it won't delete order number and other local attributes
          let dalpakServerAttributesWithLocal = { ...dalpak, ...convertToEngineData(response.result.dalpak, dalpak.id) }

          await dalpakEngine.saveDalpakAttributesAndUnlock(dalpakServerAttributesWithLocal);

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showError(i18next.t('requestFailed'));
          return false;
        }

      }


      export async function updateDalpakArea(dalpakArea) {
        if (!validateDalpakAndShowErrors(dalpakArea)) {
          return false;
        }

        try {
          app.showLoadingMessage(i18next.t('dalpakAreas.updatingDalpakArea'))

          let response = await PositiveTS.Service.FetchReq.jsonReq(`${DALPAK_AREA_URL}/${dalpakArea.railsId}`, 'put', convertDataToRails(dalpakArea));

          if (!(response.result && response.result.success && response.result.dalpak_area)) {

            if (response.result.errors) {

              let errors = Object.values(response.result.errors);

              if (errors.length > 0 && (errors[0] as any).length > 0) {
                let errorMessage = Array.isArray(errors[0]) ? errors[0][0] : errors[0];

                app.hideLoadingMessage();
                showError(errorMessage);
                return false;

              } else {
                throw new Error(response.result);
              }

            } else {
              throw new Error(response.result);
            }

          }

          // Doing like this so it won't delete order number and other local attributes
          let dalpakAreaServerAttributesWithLocal = { ...dalpakArea, ...convertToEngineData(response.result.dalpak_area, dalpakArea.id) }

          await dalpakEngine.saveDalpakAreaAttributes(dalpakAreaServerAttributesWithLocal);

          app.hideLoadingMessage();

          return true;
        } catch (err) {
          app.hideLoadingMessage();
          showError(i18next.t('requestFailed'));
          return false;
        }

      }

      export async function getAndLock(dalpak_id) {
        app.showLoadingMessage(i18next.t("dalpaks.lockingDalpak"));
        let result = await dalpakEngine.getAndLock(dalpak_id);
        app.hideLoadingMessage();
        return result;
      }

      export async function setSaleExtraDalpaksDataIfNeeded(dalpak) {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && dalpak && !posVC.sale.dalpaksExtraDataFilled &&
            dalpak.area != DalpakInfra.DALPAK_SPECIAL_AREAS.DELIVERIES) {
          let shouldContinue = await setSaleExtraDalpaksData();
          return shouldContinue;
        }

        return true;
      }

      export async function setSaleExtraDalpaksData() {
        if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners)) {
          let res = await Pinia.componentsStore.openComponent( {componentName:"selectDinersNumberDialog", args: []});

          if (!res) {
            if (posVC.saleItems.length > 0) {
              await exitDalpakAndSaveSale();
            } else {
              await posVC.restartSale();
            }
            return false;
          }
        }

        if (session.pos.parameterRequireSalesperson == PositiveTS.Storage.Entity.Pos.REQUIRE_MANDATORY) {
          await posVC.parameterRequireSalespersonHandler(null, true, true);

          if (posUtils.isBlank(posVC.sale.salespersonEmployeeID)) {
            if (posVC.saleItems.length > 0) {
              await exitDalpakAndSaveSale();
            } else {
              await posVC.restartSale();
            }

            return false;
          }
        }

        posVC.sale.dalpaksExtraDataFilled = true;
        await posVC.saleUpdated();

        return true;
      }

      export async function selectDalpakAndGoToPos(dalpak) {
        app.showLoadingMessage(i18next.t('dalpaks.enteringDalpak'))

        if (isOtherInstanceAlreadyOpen) {
          Service.MultiplePosesOpened.showOtherTabsMessage(session.pos.isCaveret);
          return false;
        }


        if ((await appDB.sales.where('invoiceSequence').equals(-1).count()) > 0) {
          app.hideLoadingMessage();
          Service.Logger.error('-1 sale exists on dalpakim!');
          showError(i18next.t('dalpaks.minusOneSaleExists'));
          pNavigator.pushPage('params', i18next.t('homepage.parameters'), null, null);
          return false;
        }

        let dalpakResult = null;

        try {

          dalpakResult = await dalpakEngine.getAndLock(dalpak.id);


          if (!dalpakResult.success) {
            if (posUtils.isDefined(dalpakResult.data) && dalpakResult.data.lockedBy != session.pos.deviceID) {
              showError(i18next.t('dalpaks.dalpakIsLocked', {...dalpakResult.data, dalpak: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) ? i18next.t('table') : i18next.t('counter')}));
            } else {
              showError(dalpakResult.errorMessage || i18next.t('requestFailed'));
            }

            app.hideLoadingMessage();
            return false;
          }
        } catch (err) {
          app.hideLoadingMessage();
          showErrorAndLog(err, i18next.t('requestFailed'));
          return false;
        }

        dalpak = dalpakResult.data;

        if (dalpak.sale && dalpak.sale.isDelivery && !jsonConfig.getVal(jsonConfig.KEYS.isDelivery)) {
          dalpakResult = await dalpakEngine.saveSaleAndUnlock(dalpak);
          showError(i18next.t('dalpaks.deliveryOnNotDeliveryPos'));
          app.hideLoadingMessage();
          return false;
        }

        if (dalpak.sale) {
          let saleFromDb = await appDB.sales.get(dalpak.sale.id);

          if (saleFromDb && (saleFromDb.invoiceSequence > 0 || saleFromDb.syncStatus == Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY)) {
            Service.Logger.error('Found sale that already synced, please check for bugs in the close sale code');

            await clearDalpakSale(dalpak);

            showError(i18next.t('dalpaks.errorClosedSaleDalpak'));
            app.hideLoadingMessage();
            return false;
          }
        }

        setCurrentSelectedDalpakAndSaveInLocalStorage(dalpak);

        if (dalpak.sale) {
          await loadDalpakSale(dalpak)
        } else {
          await posVC.createNewSale();
          app.hideLoadingMessage();
          let shouldContinue = await setSaleExtraDalpaksData();

          if (!shouldContinue) {
            setCurrentSelectedDalpakAndSaveInLocalStorage(null);
            return false;
          }

          app.showLoadingMessage(i18next.t('dalpaks.enteringDalpak'))
        }

        await setDalpakDiscountIfNeeded(dalpak);

        pNavigator.pushPage('pos', i18next.t('homepage.pos'), null, null);

        app.hideLoadingMessage();
        return true;

      };

      export async function setDalpakDiscountIfNeeded(dalpak) {
        if (dalpak.data && dalpak.data.fixedDiscount && !posVC.sale.dalpakDiscountRemoved) {
          if (session.pos.useNewPromotions) {
            let discount:Storage.Entity.Discount = session.allDiscounts.filter(d => d.discountID == "2")[0];

            if (posUtils.isBlank(discount)) {
              showError(i18next.t('dalpaks.errorDiscountNotFound'));
            } else {
              posDiscountVC.removeSaleDiscount();
              posDiscountVC.addDiscountNewStructure(null, discount, dalpak.data.fixedDiscount, [posVC.sale], Number(PositiveTS.Storage.Entity.Discount.TYPE_SALE), 0)
            }
          } else {
            showError(i18next.t('dalpaks.errorNoNewPromotions'));
          }

        }
      }

      export async function afterSaleOverActions(sale) {
        if (Service.SplitSalePayment.isSplitPaymentSale(sale)) {
          return { success: true, data: true };
        }

        return await clearDalpakSale(Pinia.dalpaksStore.currentSelectedDalpak);
      }

      export async function clearDalpakSale(dalpak): Promise<DalpakInfra.InfraResult<any>> {
        return await saveDalpakSaleAndUnlock(dalpak, null);
      }

      export async function unlock(dalpak) {
        app.showLoadingMessage(i18next.t("dalpaks.releasingDalpak"));
        let result = await dalpakEngine.unlock(dalpak);
        app.hideLoadingMessage();

        return result;
      }

      export async function dalpakPrinted(dalpak) {
        if (dalpak.isPrinted) {
          return;
        }

        try {
          await dalpakEngine.markSaleAsPrinted(dalpak);
          dalpak.isPrinted = true;
        } catch (err) {
          console.error(err);
          Service.Logger.error(err);
        }
      }

      export async function printDalpakInvoice(dalpak) {
        if (posUtils.isBlank(dalpak.sale)) {
          showError(i18next.t('dalpaks.dalpakDoesntHasSale'));
          return false;
        }

        app.showLoadingMessage(i18next.t('dalpaks.printing'));

        let saleToConvert = dalpak.sale;

        let sale = new Storage.Entity.Sale();
        sale = sale.importFromObject(saleToConvert);

        let saleItems = saleToConvert.items.map(item => Storage.Entity.SaleItem.import(item));
        let salePayments = saleToConvert.payments.map(item => Storage.Entity.SalePayment.import(item));

        saleItems = saleItemHelper.unflattenSaleItems(saleItems);

        await PositiveTS.Service.Performa.printPerforma(sale, saleItems, salePayments, i18next.t('dalpaks.printing'));

        if (jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView)) {
          // this function updates the timestamp
          await Service.Dalpak.saveDalpakSale(dalpak, FullSale.getFullSaleFormat(sale, saleItems, salePayments));
        }

        await dalpakPrinted(dalpak);
        app.hideLoadingMessage();

        let totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(sale, saleItems, salePayments);
        let total = session.fixedFloat(totals.totalAmount);
        Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.PERFORMA_PRINTED, sale.cashierEmployeeID, null, total);


        return true;
      }
      async function lockDalpakById(id) : Promise<DalpakInfra.Dalpak>{
        try{
          let lockSourceDalpakResult = await getAndLock(id);
          if (!lockSourceDalpakResult.success) {
            if (lockSourceDalpakResult.data && lockSourceDalpakResult.data.lockedBy) {
              showError(i18next.t('dalpaks.dalpakIsLocked', {...lockSourceDalpakResult.data, dalpak: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) ? i18next.t('table') : i18next.t('counter')}));
            }
            return Promise.reject();
          }
          return lockSourceDalpakResult.data;
        }catch(err){
          return Promise.reject(err);
        }

      }

      export async function moveDalpakSale(from: DalpakInfra.Dalpak, to: DalpakInfra.Dalpak, printBon = false, lockFrom = false) {
        try{
          
          if (posUtils.isBlank(from) || posUtils.isBlank(to)) {
            showError(i18next.t('dalpaks.pleaseSelectDalpak'));
            return false;
          }
  
          if (posUtils.isBlank(from.sale)) {
            showError(i18next.t('dalpaks.dalpakDoesntHasSale'));
            return false;
          }

          if (!posUtils.isBlank(to.sale) && from.sale.payments?.length >0) {
            showError(i18next.t('dalpaks.activeDalpakPaymentCannotBeTransfered'));
            return false;
          }

          if (lockFrom) {
              from = await lockDalpakById(from.id)
          }
          to = await lockDalpakById(to.id);
          app.showLoadingMessage(i18next.t('dalpaks.movingSale'))

          let sale = from.sale;
          let isPrinted = from.isPrinted;
          sale.dalpakId = to.railsId;

          if (!posUtils.isBlank(to.sale)) {
            to.sale.diners += from.sale.diners;
            let saleItemsToMove = saleItemHelper.unflattenSaleItems((from.sale.items || []).map(item => (new Storage.Entity.SaleItem()).importFromObject(item)), false, session.pos.useNewPromotions);
            let movedItemsResult = await this.moveSaleItemsToDalpak(from.sale,saleItemsToMove,to)
            let result = await saveDalpakSaleAndUnlock(to, FullSale.getFullSaleFormat((new Storage.Entity.Sale()).importFromObject(movedItemsResult.sale), movedItemsResult.saleItems, movedItemsResult.salePayments));
            if (!result.success) {
              throw new Error(result.errorMessage || i18next.t('requestFailed'));
            }
          }
          else{
            to.sale = sale;
            await dalpakEngine.saveSaleAndUnlock(to);
          }

          from.sale = null;
          await dalpakEngine.saveSaleAndUnlock(from);

          if (isPrinted) {
            await dalpakEngine.markSaleAsPrinted(to);
          }

          if (printBon) {
            let convertedSale = Storage.Entity.Sale.import(sale);
            let fullSale = new FullSale(convertedSale, convertedSale.items, convertedSale.payments);
            fullSale.sale.movedFromDalpak = from.name;
            await Service.LogicalPrinterBonPrint.printBons(fullSale, true ,true,true)
          }

          if (Service.Modbus.isActive() && Service.TimeItem.isSaleHasOpenTimeItems(sale.items)) {
            await Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(false, from);
            await Service.Modbus.sendDataToRegisterIfNeededAndShowMessages(true, to);
          }

          app.hideLoadingMessage();
          return true;

        }catch(err){
          Logger.error(err);
          showError();
          await dalpakEngine.unlock(from);
          await dalpakEngine.unlock(to);
          app.hideLoadingMessage();
          return false;
        }
      }

      export async function getAllDalpaksWithOpenedSales() {

        let dalpaks = await loadAllDalpaks();

        if (posUtils.isNullOrUndefined(dalpaks)) {
          return null;
        }

        return dalpaks.filter(dalpak => !posUtils.isBlank(dalpak.sale) && (posUtils.isBlank(dalpak.sale.invoiceSequence) || dalpak.sale.invoiceSequence < 0));
      }

      export async function createDalpakAtEngineIfNeeded() {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          let dalpaksFromPositiveServer = Service.WasmDB.execAsObject(`select * from dalpaks where store_id = ${session.pos.storeID}`);
          dalpaksFromPositiveServer = dalpaksFromPositiveServer.map(d =>  {
            d.data = JSON.parse(d.data);
            return convertToEngineData(d)
          });

          let dalpaksFromInfra = await loadAllDalpaks();

          // If moved to offline
          if (dalpaksFromInfra == null) {
            dalpaksFromInfra = await loadAllDalpaks();
          }

          if (dalpaksFromInfra == null) {
            console.error('Unable to get current state of dalpaks, cannot create dalpaks at infra');
            return;
          }

          let dalpaksToCreateOnEngine = _.differenceBy(dalpaksFromPositiveServer, dalpaksFromInfra, 'railsId');

          app.showLoadingMessage(i18next.t("dalpaks.savingDalpaks"));

          try {
            for (let dalpakToSave of dalpaksToCreateOnEngine) {
              dalpakToSave.area = dalpakToSave.area || dalpakToSave.dalpak_area_id || DalpakInfra.DALPAK_SPECIAL_AREAS.DEFAULT;

              let result = await createDalpakAtEngine(dalpakToSave);

              if (!result.success) {
                PositiveTS.Service.Logger.error(`error creating dalpak ${dalpakToSave.name}(rails id: ${dalpakToSave.railsId})`);
                console.log(dalpakToSave);
              }
            }
          } catch (err) {
            console.error(err);
            PositiveTS.Service.Logger.error(err);
          }

          app.hideLoadingMessage();
        }
      }

      export async function createDalpakAreaAtEngineIfNeeded() {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
          let dalpakAreasFromPositiveServer = Service.WasmDB.execAsObject(`select * from dalpakArea where store_id = ${session.pos.storeID}`);
          dalpakAreasFromPositiveServer = dalpakAreasFromPositiveServer.map(da => convertToEngineData(da));

          let dalpakAreasFromInfra = await loadAllDalpakAreas();


          // If moved to offline
          if (dalpakAreasFromInfra == null) {
            dalpakAreasFromInfra = await loadAllDalpakAreas();
          }

          if (dalpakAreasFromInfra == null) {
            console.error('Unable to get current state of dalpak areas, cannot create dalpak areas at infra');
            return;
          }


          let dalpakAreasToCreateOnEngine = _.differenceBy(dalpakAreasFromPositiveServer, dalpakAreasFromInfra, 'railsId');

          app.showLoadingMessage(i18next.t("dalpakAreas.savingDalpakAreas"));

          try {
            for (let dalpakAreaToSave of dalpakAreasToCreateOnEngine) {
              let result = await dalpakEngine.saveDalpakAreaAttributes(dalpakAreaToSave);

              if (!result.success) {
                PositiveTS.Service.Logger.error(`error creating dalpak area ${dalpakAreaToSave.name}(rails id: ${dalpakAreaToSave.railsId})`);
                console.log(dalpakAreaToSave);
              }
            }
          } catch (err) {
            console.error(err);
            PositiveTS.Service.Logger.error(err);
          }

          app.hideLoadingMessage();
        }
      }

      export async function createDataAtEngineIfNeeded() {
        await createDalpakAreaAtEngineIfNeeded()
        await createDalpakAtEngineIfNeeded()
      }

      export async function createDalpakAtEngine(dalpakToCreate) {
        let res = await dalpakEngine.saveDalpakAttributesAndUnlock(dalpakToCreate);

        return res;

      }

      export function convertToEngineData(data, dataEngineId = null) {
        let dataToConvert = {...data};
        dataToConvert.railsId = data.id;
        dataToConvert.id = dataEngineId;

        if(!posUtils.isBlank(data.dalpak_area_id)) {
          dataToConvert.area = data.dalpak_area_id.toString()
          delete data.dalpak_area_id
        } 

        

        return _.mapKeys(dataToConvert, (v, k) => { 
          if (k[0] == '_') {
            return k;
          }

          return _.camelCase(k)
        })
      }

      export function convertDataToRails(data) {
        let dataToConvert = {...data};
        dataToConvert.id = data.railsId;
        delete dataToConvert.railsId;

        return _.mapKeys(dataToConvert, (v, k) => { 
          if (k[0] == '_') {
            return k;
          }

          return _.snakeCase(k)
        })
      }

      export function setCurrentSelectedDalpakAndSaveInLocalStorage(dalpak: DalpakInfra.Dalpak) {
        Pinia.dalpaksStore.setCurrentSelectedDalpak(dalpak)
        if (posUtils.isBlank(dalpak)) {
          localStorage.removeItem('currentDalpak');
        } else {
          localStorage.setItem('currentDalpak', JSON.stringify(dalpak));
        }
      }

      export function setCurrentSelectedDalpakFromLocalStorage() {
        let dalpak = localStorage.getItem('currentDalpak');

        if (!posUtils.isBlank(dalpak)) {
          dalpak = JSON.parse(dalpak);
          Pinia.dalpaksStore.setCurrentSelectedDalpak(dalpak)
        }
      }

      export function saveCurrentSelectedDalpakAreaInLocalStorage(dalpakArea: DalpakInfra.DalpakArea) {
        if (posUtils.isBlank(dalpakArea)) {
          localStorage.removeItem('currentDalpakArea');
        } else {
          localStorage.setItem('currentDalpakArea', JSON.stringify(dalpakArea));
        }
      }

      export function getCurrentSelectedDalpakAreaFromLocalStorage() {
        let dalpakArea = localStorage.getItem('currentDalpakArea');

        if(!posUtils.isBlank(dalpakArea)) {
          return JSON.parse(dalpakArea)
        } 

        return dalpakArea
      }

      export function clearCurrentSelectedDalpakAreaFromLocalStorage() {
        localStorage.setItem('currentDalpakArea', null)
      }

      export function getDalpakBySale(sale) {
        return Pinia.dalpaksStore.dalpaksBySaleId[sale.id];
      }

      export async function isDalpaksDataValid() {
        return await dalpakEngine.isCurrentDataValid();
      }

      export async function setDalpaksAtEngine(dalpaks: DalpakInfra.Dalpak[]) {
        return await dalpakEngine.bulkPut(dalpaks);
      }

      export async function setDalpakAreasAtEngine(dalpakAreas: DalpakInfra.DalpakArea[]) {
        return await dalpakEngine.bulkPutDalpakAreas(dalpakAreas);
      }

      export function getDalpakBySaleOrCurrentDalpak(sale) {
        let dalpak = null
        let dalpaksBySaleId = Pinia.dalpaksStore.dalpaksBySaleId
        if (dalpaksBySaleId){
          dalpak = dalpaksBySaleId[sale.id]
        }

        if (posUtils.isBlank(dalpak)){
          dalpak = Pinia.dalpaksStore.currentSelectedDalpak
        }

        return dalpak 
      }

      export async function  moveSaleItemsToDalpak(cuurentSale,saleItemsToMove,destinationDalpak){

        try{
          let sale = null;
          let saleItems = [];
          let salePayments = [];
          let saleItemsToPrintUpdateBon = []
          let lastPrintedItemsHashStrings = JSON.parse(cuurentSale.jsondata)["lastPrintedItemsHashStrings"]
          let newBonItemsHashString = {}

          if (posUtils.isBlank(destinationDalpak.sale)) {
            sale = posVC.initNewSaleFields(false);
            sale.dalpakId =  destinationDalpak.railsId;
            sale.isDelivery =  false;
            saleItems =  [];
            salePayments =  [];
            sale.salespersonEmployeeID = posVC.sale.salespersonEmployeeID;
            sale.salespersonEmployeeName = posVC.sale.salespersonEmployeeName;
            sale.orderNumber = posVC.sale.orderNumber
          } else {
            sale = destinationDalpak.sale;
            saleItems = saleItemHelper.unflattenSaleItems((destinationDalpak.sale.items || []).map(item => (new Storage.Entity.SaleItem()).importFromObject(item)), false, session.pos.useNewPromotions);
            salePayments = (destinationDalpak.sale.payments || []).map(payment => (new Storage.Entity.SalePayment()).importFromObject(payment));
          }
          let saleNewItemRowNumber = 1;

          for (let saleItem of saleItems) {
            saleNewItemRowNumber = Math.max(saleNewItemRowNumber, saleItem.rowNumber + 1)
          }
          
          for (let itemToMove of saleItemsToMove) {
            let saleItemToAdd = (new Storage.Entity.SaleItem()).importFromObject({
              ...itemToMove.clone(),
              saleID: sale.id,
              rowNumber: saleNewItemRowNumber,
            });
            saleItemToAdd.children = [];

            let itemToMoveHasPrintedBonHash = lastPrintedItemsHashStrings && lastPrintedItemsHashStrings[itemToMove.rowNumber]

            if(itemToMoveHasPrintedBonHash) {
              newBonItemsHashString[saleItemToAdd.rowNumber] = lastPrintedItemsHashStrings[itemToMove.rowNumber]
            }

            if (itemToMove.children && itemToMove.children.length > 0) {
              for (let childItemIndex in itemToMove.children) {
                let childItem = itemToMove.children[childItemIndex]
                let childSaleItemToAdd = (new Storage.Entity.SaleItem()).importFromObject({
                  ...childItem.clone(),
                  saleID: sale.id,
                  parentItemId: saleItemToAdd.item.id,
                  // we dont need to calculate the child row number because getFullSaleFormat already does 
                  rowNumber: 20001 + parseInt(childItemIndex),
                  parentRowNumber: 1,
                });

                childSaleItemToAdd.children = [];

                if (childItem.children && childItem.children.length > 0) {
                  for (let grandChildItemIndex in childItem.children) {
                    let grandChildItem = childItem.children[grandChildItemIndex]
                    let grandChildSaleItemToAdd = (new Storage.Entity.SaleItem()).importFromObject({
                      ...grandChildItem.clone(),
                      saleID: sale.id,
                      parentItemId: childItem.item.id,
                      // we dont need to calculate the child and parent row number because getFullSaleFormat already does 
                      rowNumber: 20001 + parseInt(grandChildItemIndex),
                      parentRowNumber: 1,
                    });

                    childSaleItemToAdd.children.push(grandChildSaleItemToAdd);
                }
              }

              saleItemToAdd.children.push(childSaleItemToAdd);
              }
            }

            if (jsonConfig.getVal(jsonConfig.KEYS.printBonOnMoveDalpak) && saleItemToAdd.bonPrintedAt) {
              saleItemsToPrintUpdateBon.push(saleItemToAdd)
            }
            saleItems.push(saleItemToAdd) 

            saleNewItemRowNumber++;
          }

          if(!_.isEmpty(newBonItemsHashString)) {
            let jsondata = JSON.parse(sale.jsondata)
            jsondata["lastPrintedItemsHashStrings"] = {...jsondata["lastPrintedItemsHashStrings"],...newBonItemsHashString }

            sale.jsondata = JSON.stringify(jsondata)
          }
          let saleData = {sale,saleItems,salePayments,saleItemsToPrintUpdateBon}
          return saleData;
        }
        catch(err){
          return Promise.reject(err)
        }

      }

      export async function moveFullSale (dalpakToMoveTo) {
        let currentSelectedDalpak =  Pinia.dalpaksStore.currentSelectedDalpak
        currentSelectedDalpak = {...currentSelectedDalpak, sale:posVC.sale};
        this.moveDalpakSale(currentSelectedDalpak,dalpakToMoveTo);
        let jd = JSON.parse(posVC.sale.jsondata);
        jd.splittedPaymentsSaleItems = null;
        posVC.sale.jsondata = JSON.stringify(jd);
        // Not doing persist because below I am doing delete which updates the sale.
        let saleItemsToMove = Storage.Entity.SaleItem.flattenItems(posVC.saleItems).map(item => _.cloneDeep(item));
        return saleItemsToMove;
      }

      export async function moveItemsFromCurrentDalpak(saleItemsToMove, dalpakToMoveTo) {
        
        app.showLoadingMessage('moveItemsFromCurrentDalpak.movingItems');
        try {
          const hasImmutableItemInSale = posVC.saleItems.some(item => Pinia.globalStore.isImmutableItem(posVC.sale, item));
          const hasUnmovableItemsInSelection = saleItemsToMove.some(item => !Pinia.globalStore.canMoveDalpak(item));
          if (hasImmutableItemInSale || hasUnmovableItemsInSelection){
            let result = null;
            app.hideLoadingMessage();
						result = await app.showAlertDialog({
							header: i18next.t('error'),
							content: hasImmutableItemInSale ? i18next.t('dalpaks.unableToTransferItems') : i18next.t('dalpaks.someItemsCantBeMoved '),
							continueButtonText: i18next.t('ok'),
							cancelButtonText: i18next.t('cancel'),
							hideCancelButton: false,
							noHandleEnterEscape: true,
						})
            if (result == "continue") {
              saleItemsToMove = await this.moveFullSale (dalpakToMoveTo);
            } else{
              return false;
            }
          }
          
          dalpakToMoveTo = await lockDalpakById(dalpakToMoveTo.id)
          let movedItemsResult = await this.moveSaleItemsToDalpak(posVC.sale,saleItemsToMove,dalpakToMoveTo)
          let result = await saveDalpakSaleAndUnlock(dalpakToMoveTo, FullSale.getFullSaleFormat((new Storage.Entity.Sale()).importFromObject(movedItemsResult.sale), movedItemsResult.saleItems, movedItemsResult.salePayments));
          
          if (jsonConfig.getVal(jsonConfig.KEYS.printBonOnMoveDalpak)) {
            if (movedItemsResult.saleItemsToPrintUpdateBon.length > 0){
              PositiveTS.Service.LogicalPrinterBonPrint.sendBonToLogicalPrintersByBonType((new PositiveTS.Storage.Entity.Sale()).importFromObject(movedItemsResult.sale), movedItemsResult.saleItemsToPrintUpdateBon, movedItemsResult.salePayments, PositiveTS.Service.LogicalPrinterBonPrint.BON_TYPES.MOVE_ITEMS, [Pinia.dalpaksStore.currentSelectedDalpak.name, dalpakToMoveTo.name])
            }
          }
          if (!result.success) {
            showError(result.errorMessage || i18next.t('requestFailed'));
            app.hideLoadingMessage();
            return false;
          }

          for (let itemToRemove of saleItemsToMove) {
            await posVC.deleteSaleItem(itemToRemove);
          }
          
          app.hideLoadingMessage();
          return true;
        } catch (err) {
          await dalpakEngine.unlock(dalpakToMoveTo);
          showErrorAndLog(err, i18next.t('generalError'));
          app.hideLoadingMessage();
          return false;
        }
      }
      export async function updateDalpakEmployee(dalpak:DalpakInfra.Dalpak, employee: PositiveTS.Storage.Entity.Employee) {

        let dalpakResult = await dalpakEngine.getAndLock(dalpak.id);
        if (!dalpakResult.success) {
          app.hideLoadingMessage();

          if (dalpakResult.data && dalpakResult.data.lockedBy) {
            showError(i18next.t('dalpaks.dalpakIsLocked', {...dalpakResult.data, dalpak: jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView) ? i18next.t('table') : i18next.t('counter')}));
          } else {
            showError();
          }

          return false;
        }
        if (posUtils.isBlank(dalpakResult.data.sale)) {
          throw new Error('sale not found on dalpak ' + dalpak.id);
        }
        // Change Sale Employee
        dalpakResult.data.sale.salespersonEmployeeID = employee.employeeID;
        dalpakResult.data.sale.salespersonEmployeeName = employee.name;

        dalpakResult = await dalpakEngine.saveSaleAndUnlock(dalpakResult.data);
        
        return true
      }
    }
  }
}