module PositiveTS { 
  export module Printing {
  export module HtmlInvoice{


    export class ObjInvoiceItems {
      totalPrice = 0;
      totalPriceString = "";
      totalQuantity = 0;
      totalDiscount = 0;
      vatableTotalAmount = 0;
      totalBeforeVat = 0;
      itemLines: ObjInvoiceItemsRow[] = [];

      saleDiscountAmount = "";
      saleDiscountPct  = "";
      saleDiscountName = "";
      afterSaleDiscountTotalPrice = 0;
      afterSaleDiscountTotalDiscount = 0;
      isSaleDiscount = false;
      freeTextArray = [];

      grandToPayOrCredit = {
        payOrCreditString: "",
        payOrCreditFormattedAmount: "",
      }
      vat = {
        vatPct: "",
        calcuatedVat: "",
        totalBeforeVat: "",
        isPrintVatLiable: false,
        isPrintTotalVat: false,
        showFreeOfVat: false,
        freeOfVat: "",
      }


      creditCardPayments: ObjCreditCardPayment[] = [];

      private _paymentsObj = {
        changePayment: null,
        discountVoucherPayment: null,
        creditVoucherPayment: null,
        creditCardPayment: null,
        invoicePayments: [],
        creditVouchers: [],
      }

      constructor(sale, saleItems, salePayments) {
        this._setItemLinesAndTotals(saleItems, salePayments, sale);
        this._setPaymentsObj(salePayments);
        this._setSaleDiscuntAmounts(sale);
        this._setGrandToPayOrCredit(sale);
        this._setVatAmounts(sale);
        this._setCreditCardPayment(sale);

        if ( !posUtils.isNullOrUndefinedOrEmptyString( sale.storeFreeText) ) {
          var freeTextArray = sale.storeFreeText.split("$$$");

          for (var i = 0; i < freeTextArray.length; i++) {
            this.freeTextArray.push(freeTextArray[i]);
          };
        }
      }

      _setCreditCardPayment(sale):void{
        if (!this._paymentsObj.creditCardPayment) {return;}
        var creditCardData = JSON.parse(this._paymentsObj.creditCardPayment.data);
        for (var i = 0; i < creditCardData.length; i++) {
          this.creditCardPayments.push(new ObjCreditCardPayment(creditCardData[i], sale))
        }


      }


      private _setVatAmounts(sale):void{
        var vat = sale.vat;
        if (sale.invoiceType !=  PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT) {
          var calcuatedVat = 0;
          var totalBeforeVat = 0;


          calcuatedVat = PositiveTS.Helper.SaleHelper.calculateVat(sale.totalVatableAmount, sale.vat);
          totalBeforeVat = sale.totalVatableAmount  - calcuatedVat;

          let totalFreeOfVat = sale.totalAmount - sale.totalVatableAmount;

          if (totalFreeOfVat != 0) {
            this.vat.showFreeOfVat = true;
            this.vat.freeOfVat = PositiveTS.Printing.HtmlInvoice.numberFormat(Math.abs(totalFreeOfVat));
          }

          if ( [PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV,
                PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV].indexOf(sale.invoiceType) === -1 ){
            this.vat.isPrintTotalVat = true;
          }

          this.vat.vatPct = vat.toString();

          this.vat.calcuatedVat = PositiveTS.Printing.HtmlInvoice.numberFormat(Math.abs(calcuatedVat));
          this.vat.totalBeforeVat = PositiveTS.Printing.HtmlInvoice.numberFormat(Math.abs(totalBeforeVat));
          //aThis.printFloatingLineWithPrice('כולל ' + vat + '% מע"מ', session.fixedNumber(calcuatedVat));



        }
      }

      private _setGrandToPayOrCredit(sale):void{

        if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE ||
            sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV ||
            sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV) {

          if ( sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV){
            this.grandToPayOrCredit.payOrCreditString = 'סה"כ'
          } else {
              this.grandToPayOrCredit.payOrCreditString = "לתשלום"
          }


          this.grandToPayOrCredit.payOrCreditFormattedAmount = PositiveTS.Printing.HtmlInvoice.numberFormat(this.afterSaleDiscountTotalPrice);
        } else if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE ||
          sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV ||
         sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV) {

           if ( sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV){
             this.grandToPayOrCredit.payOrCreditString = 'סה"כ'
           } else {
               this.grandToPayOrCredit.payOrCreditString = "לזיכוי";
           }

          if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {

            this.grandToPayOrCredit.payOrCreditFormattedAmount = PositiveTS.Printing.HtmlInvoice.numberFormat(-this.afterSaleDiscountTotalPrice) + "$";
          } else {
            this.grandToPayOrCredit.payOrCreditFormattedAmount = PositiveTS.Printing.HtmlInvoice.numberFormat(-this.afterSaleDiscountTotalPrice);
          }

        }
      }

      private _setSaleDiscuntAmounts(sale):void{
        var totalPrice = this.totalPrice;
        var totalDiscount = this.totalDiscount;
        this.totalPriceString = PositiveTS.Printing.HtmlInvoice.numberFormat(totalPrice)
        this.afterSaleDiscountTotalPrice = totalPrice;
        this.afterSaleDiscountTotalDiscount = totalDiscount;

        if (sale.discountPercent > 0 || this._paymentsObj.discountVoucherPayment != null) {

          if (sale.discountPercent > 0) {
            this.isSaleDiscount = true;
            var saleDiscount = sale.saleDiscountAmount;

            totalDiscount += saleDiscount;
            totalPrice -= saleDiscount;

            var priceText = '-' + PositiveTS.Printing.HtmlInvoice.numberFormat(saleDiscount);
            var precentText = sale.discountPercent + '%';


            if (sale.discountName === "" || typeof(sale.discountName) === "undefined" || sale.discountName === null) {
              sale.discountName = "הנחה";
            }



            this.saleDiscountAmount = priceText;

            this.saleDiscountPct  = precentText;

            this.saleDiscountName = sale.discountName;

            this.afterSaleDiscountTotalPrice = totalPrice;

            this.afterSaleDiscountTotalDiscount = totalDiscount;


          }

          if (this._paymentsObj.discountVoucherPayment != null) {
            var discountVoucher = JSON.parse(this._paymentsObj.discountVoucherPayment.data);
            var currVoucher;
            for (var i = 0; i < discountVoucher.length; i++) {
              currVoucher = discountVoucher[i];

              totalDiscount += parseFloat(currVoucher.amount) ;
              totalPrice -= parseFloat( currVoucher.amount );

              this._paymentsObj.creditVouchers.push({creditType: currVoucher.creditType, amount:  '-' + PositiveTS.Printing.HtmlInvoice.numberFormat(currVoucher.amount)});
            };
            this.afterSaleDiscountTotalPrice = totalPrice;
            this.afterSaleDiscountTotalDiscount = totalDiscount;
          }
        }
      }

      private _setPaymentsObj(salePayments):void{
        var payment
        for (var i = 0; i < salePayments.length; i++) {
					payment = salePayments[i];

					switch(payment.method){
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE:
							this._paymentsObj.changePayment = payment;
							break;
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER:
							this._paymentsObj.creditVoucherPayment = payment;
							this._paymentsObj.invoicePayments.push(payment);
							break;
						case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT:
							this._paymentsObj.discountVoucherPayment = payment;
							this._paymentsObj.invoicePayments.push(payment);
							break;
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT:
							this._paymentsObj.creditCardPayment = payment;
							this._paymentsObj.invoicePayments.push(payment);
							break;
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CASH:
						case PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK:
						case PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER:
							this._paymentsObj.invoicePayments.push(payment);
							break;
					}
				};

        if (this._paymentsObj.discountVoucherPayment != null) {
          try {
            var discountVoucherData = JSON.parse(this._paymentsObj.discountVoucherPayment.data);

            if (discountVoucherData == null || discountVoucherData.length < 1) {
              this._paymentsObj.discountVoucherPayment = null;
            }
          } catch (err) {
            this._paymentsObj.discountVoucherPayment = null;
          }
        }



      }

      private _setItemLinesAndTotals(saleItems, salePayments, sale:Storage.Entity.Sale):void {
        const useNewPromotions = sale.useNewPromotions
        let discounts = {}
        if (useNewPromotions) {
          let printDiscountAsColoumnOnA4Invoices = jsonConfig.getVal(jsonConfig.KEYS.printDiscountAsColoumnOnA4Invoices)
          if(printDiscountAsColoumnOnA4Invoices){
            this.getDiscountsNewPromotionsForColumnView(saleItems, discounts)
          }else {
            this.getDiscountsNewPromotions(sale, discounts)
          }
        }

        for (var i = 0; i < saleItems.length; i++) {
          var itemPrice = saleItems[i].unitPrice * saleItems[i].quantity;
          if (saleItems[i].discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
            var itemDiscount = saleItemHelper.getSaleItemDiscountAmount(saleItems[i]);
            this.totalDiscount += itemDiscount;
            itemPrice -= itemDiscount;
          }
          this.totalPrice += itemPrice;


          if (PositiveTS.Service.DutyFree.isDutyFree()) {

						this.vatableTotalAmount += itemPrice;

					}
					else {
						if (!Boolean(saleItems[i].noVat)) { //Boolean() here is used for backwards compatibility - in case saleItem[i].noVat == undefined....
							this.vatableTotalAmount += itemPrice;
						}
					}


          this.totalQuantity += saleItems[i].quantity;
          let discountsRow = discounts[saleItems[i].rowNumber]
          this._appendItemLine(sale, saleItems[i], null, discountsRow);
        }

        this.vatableTotalAmount = this._reduceDiscountVouchersFromVatableAmount(salePayments, this.vatableTotalAmount);
        this.vatableTotalAmount -= sale.saleDiscountAmount;
        if (useNewPromotions && !_.isEmpty(discounts)){
          for(let index in discounts){
            discounts[index].forEach((discount) => {
              this.vatableTotalAmount -= discount.amount
              this.totalPrice -= discount.amount
           }) 
          }
        }
      }

      private _appendItemLine(sale:Storage.Entity.Sale, item, options?, discounts?):void {
        let printDiscountAsColoumnOnA4Invoices = jsonConfig.getVal(jsonConfig.KEYS.printDiscountAsColoumnOnA4Invoices)
        var print_price = true;
        if (options && !options.print_price) {
          print_price = false;
        }

        var colorSize = [];
        if (item.color) {
          colorSize[0] = item.color;
        }
        if (item.size) {
          colorSize[colorSize.length] = item.size;
        }

        var description = item.itemDescription;

        if (colorSize.length) {
          description += " " + colorSize.join(' ');
        }


        var code = item.itemCode;
        let printDicounts = false
        let printDicountsNewPromotions = false
        let printDiscountsInPercentages= false
        const useNewPromotions = sale.useNewPromotions

        if ((item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) && !printDiscountAsColoumnOnA4Invoices ) {
          printDicounts = true
        }

        if ((useNewPromotions && !_.isEmpty(discounts)) &&!printDiscountAsColoumnOnA4Invoices){
          printDicountsNewPromotions = true
        }

        printDiscountsInPercentages = jsonConfig.getVal(jsonConfig.KEYS.printDiscountsInPercentagesOnA4Invoices)

        var price = item.unitPrice * item.quantity;

        var priceItemBefoeVat
        if (item.noVat || (session.store.containVat && !item.alwaysHasVat)) {
          priceItemBefoeVat = session.fixedNumber(item.unitPrice)
        } else {
          let cuatedVat = PositiveTS.Helper.SaleHelper.calculateVat(item.unitPrice, parseFloat(sale.vat) );
          let beforeVat = item.unitPrice - cuatedVat;
          priceItemBefoeVat = session.fixedNumber(beforeVat);
        }


        if (print_price) {
          let discountAmount = 0
          let discoutAmountText = ""

          if(printDiscountAsColoumnOnA4Invoices && !_.isEmpty(discounts)) {
            discounts.forEach((discount) => {
              discountAmount += (discount.amount * -1)
            })
            discoutAmountText = PositiveTS.Printing.HtmlInvoice.numberFormat(discountAmount)

            if (printDiscountsInPercentages) {
              discoutAmountText = PositiveTS.Printing.HtmlInvoice.getDiscountInPercentages(discountAmount, price)
            }
          }
          price += discountAmount

          this.itemLines.push(new ObjInvoiceItemsRow(code, description, PositiveTS.Printing.HtmlInvoice.numberFormat(price),
                          PositiveTS.Printing.HtmlInvoice.numberFormat(item.unitPrice),
                          item.quantity,
                          priceItemBefoeVat,
                          discoutAmountText
                        ))
        } else {
          this.itemLines.push(new ObjInvoiceItemsRow(code, description, "", "", item.quantity));
        }

        if (printDicounts) {
          let discountPriceText = "";
          let discountNameText = "";
          if (item.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) {
            let discountPrice = saleItemHelper.getSaleItemDiscountAmount(item);

            discountPriceText = PositiveTS.Printing.HtmlInvoice.numberFormat(-discountPrice);
            if(printDiscountsInPercentages) {
              discountPriceText = PositiveTS.Printing.HtmlInvoice.getDiscountInPercentages(discountPrice, price)
            }

            // ***** discountPriceText

            discountNameText = item.discountName;
            if (posUtils.isNullOrUndefined(discountNameText)) {
              discountNameText = "";
            }

          }

          this.itemLines.push(new ObjInvoiceItemsRow("", discountNameText, discountPriceText, "", ""))
        }

        if (printDicountsNewPromotions) {
          discounts.forEach((discount) => {
            let discountName = `${discount.code && discount.code[0] == 'D' ? i18next.t("printing.discount") : i18next.t("printing.promotion")}: ${discount.name}`
            let discountAmount =  PositiveTS.Printing.HtmlInvoice.numberFormat(-discount.amount)
            if(printDiscountsInPercentages) {
              discountAmount = PositiveTS.Printing.HtmlInvoice.getDiscountInPercentages(discount.amount, price)
            }
            this.itemLines.push(new ObjInvoiceItemsRow("", discountName, discountAmount, "", ""))
          })
        }

      }



      private _reduceDiscountVouchersFromVatableAmount(salePayments, totalVatableAmount) {
        var result = totalVatableAmount;
        var payment, paymentData;
        for (var i = 0; i < salePayments.length; i++) {
          payment = salePayments[i];
          if (payment.method === PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT) {
            try {
              paymentData = JSON.parse(payment.data);
              if (paymentData[0] && paymentData[0].amount) {
                result -= paymentData[0].amount;
              }
            }
            catch (error) {
              console.error("there was an error parsing a voucher_discount sale payment.");
              if (payment.saleID) {
                console.error("SaleID is: " + payment.saleID);
              }
              console.error(error);
            }
          }
        }
        return result;
      }

      private getDiscountsNewPromotions (sale, discounts) {
        let jd = JSON.parse(sale.jsondata)
          if (jd.promotions) {
            for (let promotion of jd.promotions) {
              if (discounts[promotion.rowToShowOn]) {
                discounts[promotion.rowToShowOn].push({
                  amount: promotion.discountAmountForGroup,
                  name: promotion.promoName,
                  code: promotion.promotionCode
                })
              }
              else {
                discounts[promotion.rowToShowOn] = [{
                  amount: promotion.discountAmountForGroup,
                  name: promotion.promoName,
                  code: promotion.promotionCode
                }]
              }
            }
          }
      }

      private getDiscountsNewPromotionsForColumnView(itemLines, discounts){
        itemLines.forEach(item => {
          if(item.promotions) {
            let itemDiscounts = Storage.Entity.Promotion.convertPromotionStringToObject(item.promotions);

            for (const discount in itemDiscounts) {
              if(discounts[item.rowNumber]){
                discounts[item.rowNumber].push({
                  amount: itemDiscounts[discount],
                })
              }else {
                discounts[item.rowNumber] = [{
                  amount: itemDiscounts[discount],
                }]
              }
            }
          }
        });
      }

    }

    class ObjInvoiceItemsRow {
      code: string;
      description: string;
      price: string;
      unitPrice: string;
      quantity: string;
      priceBeforeVat: string;
      discountAmount: string;
      constructor(code, description, price, unitPrice, quantity, priceBeforeVat = "", discountAmount = "") {
        this.code = code;
        this.description = description;
        this.price = price;
        this.unitPrice = unitPrice;
        this.quantity = quantity;
        this.priceBeforeVat = priceBeforeVat;
        this.discountAmount = discountAmount;
      }
    }

    class ObjCreditCardPayment {
      cardNumber: string = "";
      cardType: string = "";
      paymentsCount: string = "";
      validUntil: string = "";
      confirmationNumber: string = "";
      amount: string = "";

      constructor(creditCard, sale){
        if(creditCard.isEmv) {
          let emvCreditCard = _.clone(creditCard);
          creditCard = {};
          creditCard.card_number = emvCreditCard.Pan.slice(-4);
          creditCard.valid_until = " ";
          creditCard.card_type = emvCreditCard.Manpik;
          if(posUtils.isNullOrUndefinedOrEmptyString(emvCreditCard.NoPayment)) {
            creditCard.payments_count = 1;  
          } else {
            creditCard.payments_count =   Number(emvCreditCard.NoPayment) + 1;
          }
          creditCard.confirmation_number = emvCreditCard.AuthManpikNo;
          creditCard.amount = String(Number(emvCreditCard.Amount) / 100);
        }

        this.cardNumber = creditCard.card_number;


        var cardType:any = $.grep(PositiveTS.Storage.Entity.SalePayment.CreditCardTypes,  (type)=> {
          var anyType: any = type;
          return anyType.id == creditCard.card_type
        });

        this.cardType = cardType[0].name;
        if (creditCard.all_data) {
          var creditType = serviceShva.getShvaPaymentTypeOfficialNameFromRequestString(JSON.parse(creditCard.all_data).request);
          this.cardType = creditType;
        }

        if (creditCard.payments_count > 1) {
          this.paymentsCount = creditCard.payments_count.toString();
        } else {
          this.paymentsCount = "1";
        }

        this.validUntil = creditCard.valid_until;
        this.confirmationNumber = creditCard.confirmation_number;

        var currency_hebrew = ' ש"ח';
        if (creditCard.creditCurrency == 'USD') {
          currency_hebrew = ' דולר';
        }

        if(parseFloat(sale.totalAmount) >= 0){
          this.amount = PositiveTS.Printing.HtmlInvoice.numberFormat(creditCard.amount) + currency_hebrew;
        } else {
          this.amount = PositiveTS.Printing.HtmlInvoice.numberFormat(creditCard.amount * -1)  + currency_hebrew;
        }
      }
    }
  }}}
