module PositiveTS {
  export module Service {

    export class CreditInvoiceReplaceItem {
      static async isValid(): Promise <{ status: boolean, err: string }> {
        var originalInvoiceType = posCIVC.originalSaleItemsAndPayments.invoiceType
        let allowToReplaceItemsInReplacementSale = jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)
        let isManagerApprovalRequired = jsonConfig.getVal(jsonConfig.KEYS.isAllowToReplaceItemsInReplacementSaleAuthorizationRequired)
        let isReplacementSale = PositiveTS.Service.CreditInvoice.isReplacementSale(posCIVC.originalSaleItemsAndPayments)

        let errors = {
          noItemSelected: i18next.t("creditInvoiceNoItemSelected"),
          removePendingItemFirst: i18next.t("creditInvoiceRemovePendingOtherReplacementItemsFirst"),
          managerAprrovalRequired: i18next.t('creditInvoiceReplaceItem.managerAprrovalRequired'),
          cannotReplaceSaleReceipt: i18next.t('creditInvoiceReplaceItem.cannotReplaceSaleReceipt'),
          cantReplaceMinusSale: i18next.t('creditInvoiceReplaceItem.cantReplaceMinusSale')
        }

        if (Storage.Entity.Sequence.TYPE_RECEIPT == originalInvoiceType){
          return { status: false, err: errors.cannotReplaceSaleReceipt};
        }

        if (!Service.SplitSalePayment.isBaseSplitSale(posCIVC.originalSaleItemsAndPayments)) {
          let saleItems = posCIVC.originalSaleItemsAndPayments.items;
          for (let saleItem of saleItems) {
            if (saleItem.quantity < 0 && !allowToReplaceItemsInReplacementSale) {
              return { status: false, err: errors.cantReplaceMinusSale };
            }
          }
        }

        if (this.calcuateItemsAmountToCredit() === 0 && !allowToReplaceItemsInReplacementSale){
          return { status: false, err: errors.noItemSelected };
        }

        var parentSaleOfPendingItemsIfExists = this._currentSaleParentItem();
        var originalInvoiceSequence = posCIVC.originalSaleItemsAndPayments.invoiceSequence;
        if (posVC.saleItems.length !== 0 && parentSaleOfPendingItemsIfExists
          && parentSaleOfPendingItemsIfExists !==  originalInvoiceSequence) {
          return { status: false, err: errors.removePendingItemFirst };
        }

        if (allowToReplaceItemsInReplacementSale && isReplacementSale) {

          let selectedItemsCount = this.countSelectedItems()
          if (selectedItemsCount == 0 ) {
            return { status: false, err: errors.noItemSelected };
          }

          if(isManagerApprovalRequired){
            let managerApproves = await this.validateSaleItemsCountAndOpenManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGER])

            if(!managerApproves){
              return { status: false, err: errors.managerAprrovalRequired };
            }
          }
        }

        let jsondata = posUtils.isBlank(posVC.sale.jsondata) ? {} : JSON.parse(posVC.sale.jsondata);

        if (posVC.sale.parentSaleInvoiceSequence && !posUtils.isBlank(jsondata['replacementSaleItems'])) {
          return { status: false, err: i18next.t('creditInvoiceReplaceItem.alreadyHasOpenedReplacementSale') };
        }

        return { status: true, err: "" }
      }

      static addSelectedItemsToInvoice(): void {
        // get items, amount, count to add
        // mark items with parent invoice, and add them
        // logic:
        //    can not chnage quantity, price....
        //    can add same item and it would be in different row
        //    items as already credited ... for parrent invoice.
        
        this._saveParentSaleInfo()
        let itemsToAdd = this._addSelectedItemsToInvoice()
        posVC.saleUpdated(itemsToAdd);
      }

      private static async validateSaleItemsCountAndOpenManagerApprovalDialog(permissions: string[]): Promise<boolean> {
        try {
          await app.showManagerApprovalDialog(permissions)
          return true
        } catch (e) {
          return false
        }
      }

      private static _saveParentSaleInfo() {
        var aThis = posCIVC;
        
        var aThisOriginalSaleItemsAndPayments = aThis.originalSaleItemsAndPayments;

        posVC.sale.parentSaleCompanyID          = aThisOriginalSaleItemsAndPayments.companyID;
        posVC.sale.parentSaleStoreID            = aThisOriginalSaleItemsAndPayments.storeID;
        posVC.sale.parentSalePosDeviceID        = aThisOriginalSaleItemsAndPayments.posDeviceID;
        posVC.sale.parentSalePosNumber          = aThisOriginalSaleItemsAndPayments.posNumber;
        posVC.sale.parentSaleInvoiceSequence    = aThisOriginalSaleItemsAndPayments.invoiceSequence;
        posVC.sale.parentSaleInvoiceType        = aThisOriginalSaleItemsAndPayments.invoiceType;        
      }


      private static _addSelectedItemsToInvoice() {
        let aThis = posCIVC;
        let aThisOriginalSaleItemsAndPayments: Storage.Entity.Sale = aThis.originalSaleItemsAndPayments; 
        let itemsToAdd = new Map<number,Storage.Entity.SaleItem>() 
        let allowToReplaceItemsInReplacementSale = jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)

        for (let currentItemId in aThis.itemsIdAmount) {
          let letCurrentItemId = currentItemId;
          if (aThis.itemsIdAmount.hasOwnProperty(letCurrentItemId)) {
            let itemObject:Storage.Entity.SaleItem = aThis.itemsIdSaleItem[letCurrentItemId];

            let quantity = aThis.itemsIdAmount[letCurrentItemId];
            let unitPrice = this.getItemAmountToCredit(letCurrentItemId);

            let parentSaleInvoiceType        = aThisOriginalSaleItemsAndPayments.invoiceType;
            let parentSaleInvoiceSequence    = aThisOriginalSaleItemsAndPayments.invoiceSequence;

            if (quantity !== 0) {
              if(allowToReplaceItemsInReplacementSale && quantity < 0) {
                  continue
                }
              let itemToAdd = this._addItem(itemObject,quantity, unitPrice, parentSaleInvoiceSequence);
              aThis.childrenItems.forEach(child => {
              if(itemObject.rowNumber == child.parentRowNumber) {
                child.saleID = posVC.sale.id;
                child.unitPrice = child.isDeposit ? (child.priceNetoAfterDiscounts/child.quantity) : 0;
                child.priceNetoAfterDiscounts = child.priceNetoAfterDiscounts*-1;
                let grandChildren = aThis.childrenItems.filter(grandChild => grandChild.parentRowNumber == child.rowNumber);
                grandChildren.forEach(grandChild => {
                  grandChild.saleID = posVC.sale.id
                  grandChild.unitPrice = grandChild.isDeposit ? (grandChild.priceNetoAfterDiscounts/grandChild.quantity) : 0;
                  grandChild.priceNetoAfterDiscounts = grandChild.priceNetoAfterDiscounts * -1;
                  child.children = child.children || [];
                  child.children.push(grandChild);
                });
                itemToAdd.children = itemToAdd.children || [];
                itemToAdd.children.push(child);
              }
            });

              itemsToAdd.set(itemToAdd.rowNumber, itemToAdd);
            }
          }
        }

        return itemsToAdd;
        
      }


      static countSelectedItems():number {
        let aThis = posCIVC;
        let {itemsIdAmount} = aThis
        let selectedItemsCount = 0
        for (var currentItemId in itemsIdAmount) {
          selectedItemsCount += itemsIdAmount[currentItemId] > 0 ? itemsIdAmount[currentItemId] : 0
        }

        return selectedItemsCount
      }




      static calcuateItemsAmountToCredit():number{
        var aThis = posCIVC;

        var itemsAmountToCredit = 0;
        for (var currentItemId in aThis.itemsIdAmount) {
          itemsAmountToCredit -= this._getTotalRowItemAmountToCredit(currentItemId);
        }

        return session.fixedFloat(itemsAmountToCredit);
      }

      private static _addItem(itemObject:Storage.Entity.SaleItem, itemQty, unitPrice: number,
                               parentSaleInvoiceSequence:number) {

        let rowNumber = posVC.getRowNumber();
        let saleItem = new PositiveTS.Storage.Entity.SaleItem();
        saleItem.quantity = itemQty * -1;
        saleItem.unitPrice = unitPrice;
        saleItem.originalUnitPrice = itemObject.originalUnitPrice;
        saleItem.barcode = itemObject.barcode;
        saleItem.itemCode = itemObject.itemCode;
        saleItem.item = itemObject.item;
        saleItem.itemDescription = itemObject.itemDescription + " מ: " + parentSaleInvoiceSequence;
        saleItem.saleID = posVC.sale.id;
        saleItem.salespersonEmployeeID = itemObject.salespersonEmployeeID
        saleItem.salespersonEmployeeName = itemObject.salespersonEmployeeName
        saleItem.parentSaleInvoiceSequence = parentSaleInvoiceSequence;
        saleItem.discountApprovedByEmployeeID = -1;
        saleItem.size = itemObject.size;
        saleItem.color = itemObject.color;
        saleItem.noDiscount = true;
        saleItem.isPickup = itemObject.isPickup;
        saleItem.noVat = itemObject.noVat;
        saleItem.hasWeight = itemObject.hasWeight;
        saleItem.isAddition = itemObject.isAddition;
        saleItem.isAbstract = itemObject.isAbstract;
        saleItem.invoiceSequence = -1;
        saleItem.rowNumber = rowNumber;
        saleItem.parentRowNumber = -1;
        saleItem.parentItemId = -1;
        saleItem.priceNetoAfterDiscounts  = itemObject.priceNetoAfterDiscounts;


        posVC.saleItems.push(saleItem);

        let jsondata = {};

        if (!posUtils.isBlank(posVC.sale.jsondata)) {
          jsondata = JSON.parse(posVC.sale.jsondata)
        }

        jsondata['replacementSaleItems'] = jsondata['replacementSaleItems'] || [];
        jsondata['replacementSaleItems'].push({
          saleItemRowNumber: rowNumber,
          parentSaleItemRowNumber:  itemObject.rowNumber
        });

        posVC.sale.jsondata = JSON.stringify( jsondata );

        return saleItem;

      }

      static getItemAmountToCredit(currentItemId:string):number {
        var aThis = posCIVC;
        return aThis.calcuateItemPriceAfterDiscounts(aThis.itemsIdSaleItem[currentItemId]);
      }

      private static _getTotalRowItemAmountToCredit(currentItemId:string):number {
        var aThis = posCIVC;
        return aThis.itemsIdAmount[currentItemId] * this.getItemAmountToCredit(currentItemId);
      }

      private static _currentSaleParentItem(): number {
        var saleItems:PositiveTS.Entity.SaleItem[] = posVC.saleItems;
        for (var j = 0; j < saleItems.length; j++) {
          let currentSaleItem:PositiveTS.Entity.SaleItem = saleItems[j];

          if (currentSaleItem.parentSaleInvoiceSequence) {
            return (currentSaleItem.parentSaleInvoiceSequence);
          }
        }
        return 0;
      }

    }
  }
}
