module PositiveTS {
export module Service {
export module MultipassItems {
    interface _MultipassTranType {
        multipassTranType:"S"|"T"
        catItemsList?:string
    }


    class _PosItem {
        Amount: number;
        CatalogItemCode: string;
        Price: number;
        PriceSum: number

        readonly Bussiness = 0;
        readonly CatalogItemId = 0;
        readonly IsActive = true;

        constructor(saleItem:PositiveTS.Storage.Entity.SaleItem){
            this.Price = Math.trunc((parseFloat(saleItem.displayTotal)/saleItem.quantity) * 100);
            this.Amount = Math.trunc(saleItem.quantity * 100);
            this.PriceSum = Math.trunc( parseFloat(saleItem.displayTotal) * 100 )
            this.CatalogItemCode = saleItem.itemCode; 
        }
    }    


    export function getMultipasslItems(saleItems:PositiveTS.Storage.Entity.SaleItem[]):_MultipassTranType{
        let multipassTranType: "S" | "T" = jsonConfig.getVal( jsonConfig.KEYS.isMultipassItemInterface) ? "T" : "S"
        var _ret:_MultipassTranType = {multipassTranType:multipassTranType, catItemsList:undefined}
        if (_ret.multipassTranType === "S"){return _ret;} 

        let posItemAry:{PosItem:_PosItem}[] = [];

        for (let item of PositiveTS.Storage.Entity.SaleItem.flattenItems(saleItems)){
            let posItem = new _PosItem(item);
            if(posItem.PriceSum){
                posItemAry.push({PosItem:posItem});
            } 
        }

        if(posItemAry.length > 0){
            _ret.catItemsList = JSON.stringify(posItemAry)
        }
        


        return _ret;
    }
}}}