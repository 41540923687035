module PositiveTS {
    export module Components {
    export module AddOrShowBankDeposit {
      export const route = '/add-or-show-bank-deposit';

      class Bank {
        public id:number
        public name:string
        public labelText:string
        constructor(id,name) {
          this.id = id
          this.name = name
          this.labelText = `${this.id} - ${this.name}`
        }
        
      }
      

      let banks = [
        new Bank(4,  `בנק יהב לעובדי המדינה בע"מ`),
        new Bank(10, `בנק לאומי לישראל בע"מ`),
        new Bank(11, `בנק דיסקונט לישראל בע"מ`),
        new Bank(12, `בנק הפועלים בע"מ`),
        new Bank(13, `בנק אגוד לישראל בע"מ`),
        new Bank(14, `בנק אוצר החייל בע"מ`),
        new Bank(17, `בנק מרכנתיל דיסקונט בע"מ`),
        new Bank(20, `בנק מזרחי טפחות בע"מ`),
        new Bank(26, `יובנק בע"מ`),
        new Bank(31, `הבנק הבינלאומי הראשון לישראל בע"מ`),
        new Bank(34, `בנק ערבי ישראלי בע"מ`),
        new Bank(46, `בנק מסד בע"מ`),
        new Bank(52, `בנק פועלי אגודת ישראל בע"מ`),
        new Bank(54, `בנק ירושלים בע"מ`),
        new Bank(68, `בנק דקסיה ישראל בע"מ`),
      ]
     

      type CompType = any;

      interface CompData {
        zReports: Array<any>,
        banks: Array<{id:number,name:string}>,
        selectedZReports: Array<any>,
        mode: "new" | "show",
        step: number;
        branchNumber: Number,
        depositEmployee: any;
        authenticatedEmployee: Storage.Entity.Employee,
        totalSelected: number;
        depositTime: any;
        declarationComments: string;
        totalCashZdsSelected: number;
        totalCashDeclared: number;
        pageToReturnTo: number;
        selectedBank:Bank
      }
    
      export function getComponent() {

        return {
            template: JST.addOrShowBankDeposit(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            methods: {
              ...Mixins.modalsMixin.methods,
              validateStep1() {
                let self = this as CompType;
                if (self.selectedZReports.length == 0) {
                  return i18next.t("addOrShowBankDeposit.noZReportsSelected")
                }
                return null;
              },

              async back(toStep) {
                let self = this as CompType;
                if (self.mode == "show") {
                  VueServices.Router.goto(Components.DepositReport.route, {page: self.pageToReturnTo}, { employee: self.authenticatedEmployee });
                }
                else {
                  await self.gotoStep(toStep);
                }
              },

              print() {
                let self = this as CompType;
                let bankDeposit = {
                  depositEmployee: self.depositEmployee,
                  totalCashZdsSelected: self.totalCashZdsSelected,
                  totalCashDeclared: self.totalCashDeclared,
                  comments: self.declarationComments,
                  depositTime: self.depositTime,
                  zReports: self.selectedZReports,
                  branchNumber: self.branchNumber || '',
                  bankName: posUtils.isPresent(self.selectedBank) ? self.selectedBank.name : '' 
                }
                Printing.Invoice.printBankDeposit(bankDeposit)
              },

              async gotoStep(stepNum:number) {
                let self = this as CompType;
                switch(stepNum) {
                  case 1:
                    self.step = stepNum;
                    return;
                  case 2:
                    let error = self.validateStep1();
                    if (error == null) {
                      self.selectedZReports = _.sortBy(self.selectedZReports,'originalCreatedAt');
                      self.step = stepNum;
                      return;
                    }
                    else {
                      let self = this as CompType;
                      await self.promiseShowError(error);
                      return;
                    }

                }                
              },

              async closeDeposit() {
                let self = this as CompType;
                let result = await self.promiseShowAlertGenericDialog(
                  i18next.t("addOrShowBankDeposit.closeDepositConfirmation"),
                  false, i18next.t("ok"), i18next.t("cancel"), 
                  i18next.t("addOrShowBankDeposit.closeDepositConfirmationTitle"));
                if (result == AlertDialog.okResult) {
                  //verify deposit cash
                  if (posUtils.isBlank(this.totalCashDeclared)){
                    self.showError(i18next.t("addOrShowBankDeposit.totalCashDeclaredIsRequired"))
                    return
                  }

                  if (self.totalCashDeclared != self.totalCashZdsSelected) {
                    result = await self.promiseShowAlertGenericDialog(i18next.t("addOrShowBankDeposit.cashMismatchMessage"),false);
                    if (result == AlertDialog.cancelResult) {
                      return;
                    }
                  }

                  //close deposit
                  try {
                    app.showLoadingMessage(i18next.t("addOrShowBankDeposit.savingDeposit"));
                    self.depositTime = String(new Date());
                    let result = await Service.FetchReq.jsonReq(`/bank_deposits`,'post',{bank_deposit: {
                      total_cash_declared: self.totalCashDeclared,
                      total_cash_in_zds: self.totalCashZdsSelected,
                      employee: self.depositEmployee,
                      bank_number: self.selectedBank ? self.selectedBank.id : null,
                      bank_branch_number: self.branchNumber,
                      deposit_time: self.depositTime,
                      z_reports_attributes: self.selectedZReports.map(z => { return {id: z.id}}),
                      comments: self.declarationComments,
                    }})
                    app.hideLoadingMessage();
                    if (result.response.ok) {
                      self.print();
                      await self.promiseShowAlertGeneric(i18next.t("addOrShowBankDeposit.depositCreatedSuccessfully"), 
                        true, i18next.t("message"));
                      VueServices.Router.goto(Components.DepositReport.route, {page: self.pageToReturnTo}, { employee: self.authenticatedEmployee });
                    }
                    else {
                      self.showError(i18next.t("addOrShowBankDeposit.failedToSaveDeposit"))  
                    }
                  }
                  catch(error) {
                    app.hideLoadingMessage();
                    Service.Logger.error(error);
                    self.showError(i18next.t("addOrShowBankDeposit.failedToSaveDeposit"))
                  }
                }
              },

              toggleSelection(zReport) {
                let self = this as CompType;
                zReport.selected ? self.selectedZReports.push(zReport) : _.remove(self.selectedZReports, {id: zReport.id})
                self.totalSelected = self.selectedZReports.length;
                self.totalCashZdsSelected = session.fixedFloat(_.sumBy(self.selectedZReports, 'totalZCashAmount'),2);
              },

              close() {
                let self = this as CompType;
                VueServices.Router.goto(DepositReport.route, {page: self.pageToReturnTo}, { employee: self.authenticatedEmployee });
              }
            },
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {routerExtraData,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {routerExtraData,mobileLayout} 

              return {...globalStoreProps}
            },
            data: ():CompData => {
                return {
                  banks: banks,
                  zReports: [],
                  selectedZReports: [],
                  selectedBank:null,
                  branchNumber:null,
                  authenticatedEmployee: null,
                  mode: "new",
                  step: 1,
                  totalSelected: 0,
                  totalCashDeclared: 0,
                  declarationComments: "",
                  depositEmployee: {},
                  depositTime: null,
                  totalCashZdsSelected: 0,
                  pageToReturnTo: 1,
                };
            },
            beforeRouteEnter: async function(to, from, next) {
              app.showLoadingMessage(i18next.t('loading'));
              
              let zReports = [];

              let mapFunc = (z) => {
                let data = JSON.parse(z.data);
                return {
                  selected: false,
                  id: z.id,
                  bankDepositId: z.bank_deposit_id,
                  zNumber: z.zNumber,
                  createdAt: moment(z.created_at).format("DD/MM/YYYY HH:mm"),
                  originalCreatedAt: z.created_at,
                  totalAmount: z.payment_count,
                  totalZCashAmount: data.z_report.payments.filter(p => p.payment_type_id == "2")[0]?.amount || 0,
                }
              }

              if (to.params.mode == "new") {
                let masterSlaveService = (new PositiveTS.Service.ZMasterSlave());
                let response = await Service.FetchReq.jsonReq(`/z_reports?type=deposit`,'get')
                let getOnlyPrimaryReports = !posUtils.isBlank(masterSlaveService.zMasterSlavePrimaryPosDeviceId);
                zReports = response.result.filter((z) => {
                  if (z.bank_deposit_id != null) {
                    return false;
                  }
                  if (getOnlyPrimaryReports) {
                    return z.is_primary;
                  }
                  else {
                    return true;
                  }
                }).map(mapFunc);
              }
              
              next(async (vm) => {
                let self:CompType = <CompType>vm;
                self.zReports = zReports;
                self.totalSelected = 0;
                self.authenticatedEmployee = _.cloneDeep(vm.routerExtraData.employee);
                self.mode = <any>to.params.mode;
                if (self.mode == "new") {
                  self.step = 1;
                  self.depositEmployee = _.cloneDeep(vm.routerExtraData.employee);
                }
                
                if (self.mode == "show") {
                  let depositToShow = <any>(vm.routerExtraData.bankDeposit);
                  self.depositEmployee = depositToShow.employee;
                  self.totalCashDeclared = depositToShow.total_cash_declared;
                  self.declarationComments = depositToShow.comments;
                  let bankIndex = banks.findIndex(bank => bank.id == depositToShow.bank_number);
                  if (bankIndex >= 0) {
                    self.selectedBank = banks[bankIndex]
                  }
                  self.branchNumber = depositToShow.bank_branch_number;
                  self.totalCashZdsSelected = depositToShow.total_cash_in_zds;
                  self.depositTime = depositToShow.deposit_time;
                  self.pageToReturnTo = <any>(to.params.page);
                  self.selectedZReports = _.sortBy(depositToShow.z_reports.map(mapFunc),'originalCreatedAt');
                  self.step = 2;
                }

                app.hideLoadingMessage();
              });
            },
    
    
        }
    
      }

         
}}}
