module PositiveTS {
  export module Service {

    export interface SpecialItem {
      code:string,
      company_id: number,
      tenant_id: number,
      hndlr: string,
      settingsID: string
    }

    export interface ActionResponse {
	    response?: any
      success: boolean,
      error: string,
      rawResponse?: any,
      voucher?: any
      pos_parameters?: string
    }

    export interface GetBalanceResponse extends ActionResponse {
      balance: number,
      device_id?: string
    }

    export interface GetCardNumberResponse {
      cardNumber:string,
      options: any
    }

    export interface SmartVoucherPaymentData {
      data: string,
      method: number,
      amount: number
    }

    export enum SmartVoucherActionTypes {
      DEPOSIT = 'deposit',
      CANCEL_DEPOSIT = 'cancel_deposit',
      WITHDRAW = 'withdraw',
      CANCEL_WITHDRAW = 'cancel_withdraw',
    }

    export abstract class SmartVoucher {

      static VALU_HANDLER = 'Valu'
      static PRAXELL_HANDLER = 'px'
      static GPP_HANDLER = 'GPP'
      static PLUXEE_HANDLER = 'Pluxee'
      static TENBIS_HANDLER = 'Tenbis'
      static BEENGO_HANDLER = 'BEENGO'
      static VALUE_CARD_HANDLER = 'VALUE_CARD'
      static MULTIPASS_HANDLER = 'MULTIPASS'
      static GOODI_HANDLER = "goodi"
      static BANX_HANDLER = "banx"
      static SAFECASH_HANDLER = 'KashCash'
      static YAAD_HANDLER = "yaad"
      static MULTIPASS_POLICE_HANDLER = 'MULTIPASSPOLICE'
      static INTERSERVICE_HANDLER = 'InterService'
      static CHARGABLE_CARD_BULK_ITEM_HANDLER = 'CHARGABLE_CARD_BULK_ITEM'
      static ICMEGA_HANDLER = "ICMega"
      static OTOT_HANDLER = 'OTOT'


      static CARD_PREFIX_FIELD = 'multipassCompanyIdLimit'
      

      hasCVV = false

      abstract getBalance(cardNumber: string, cvv?:string):Promise<GetBalanceResponse>

      abstract pay(voucherData:any, amount: number, cvv?: string, companyId?: any,additionalData?: any):Promise<any>

      abstract cancelPayment(voucherToCancel:any, doRemove?:boolean):Promise<ActionResponse>
      async afterCancelPayment(voucherToCancel:any, doRemove?:boolean):Promise<any> {
        return new Promise((res) => { res(); }); // Override me if you need
      }

      abstract cancelLoad(paymentToCancel):Promise<any>

      abstract loadCard(cardNumber:string,amount:Number,options?:any):Promise<SmartVoucherPaymentData>

      abstract getCardNumber():Promise<GetCardNumberResponse>

      static getBalance(type,cardNumber,cvv){
        let smartVoucher = SmartVoucherFactory.createVoucher(type);
        return smartVoucher.getBalance(cardNumber,cvv);
      }

      static isActionResponse(object: any): object is ActionResponse {
        return 'success' in object && 'error' in object;
      }

      static isSmartVoucher(voucherType:string):boolean {
        switch (voucherType) {
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PRAXELL:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GPP:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALUE_CARD:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BEENGO:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_INTERSERVICE:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT:
            return true
          default:
            return false
        }
      }

      static worksInOffline(voucherType:string):boolean {
        switch (voucherType) {
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PRAXELL:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALUE_CARD:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI:
            return true
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GPP:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BEENGO:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_INTERSERVICE:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA:
            return false
          default:
            return false
        }
      }

      static isSmartVoucherHandler(hndlr):boolean {
        switch (hndlr) {
          case SmartVoucher.VALU_HANDLER:
          case SmartVoucher.GPP_HANDLER:
          case SmartVoucher.PRAXELL_HANDLER:
          case SmartVoucher.PLUXEE_HANDLER:
          case SmartVoucher.TENBIS_HANDLER:
          case SmartVoucher.BEENGO_HANDLER:
          case SmartVoucher.VALUE_CARD_HANDLER:
          case SmartVoucher.MULTIPASS_HANDLER:
          case SmartVoucher.GOODI_HANDLER:
          case SmartVoucher.MULTIPASS_POLICE_HANDLER:
          case SmartVoucher.INTERSERVICE_HANDLER:
          case SmartVoucher.CHARGABLE_CARD_BULK_ITEM_HANDLER:
          case SmartVoucher.OTOT_HANDLER:
            return true
          default:
            return false
        }

      }

      static isLoadCardSale(saleItems:any, specialItems:Array<SpecialItem>):boolean {
        let specialItem = SmartVoucher.getSpecialItemOrNull(saleItems,specialItems);
        if (specialItem) {
          if (specialItem.hndlr == SmartVoucher.CHARGABLE_CARD_BULK_ITEM_HANDLER){
            return false
          }
          return SmartVoucher.isSmartVoucherHandler(specialItem.hndlr)
        }
        else {
          return false
        }
      }

      static getSmartVoucherObjectToLoadIfApplicable(saleItems:any,specialItems:Array<SpecialItem>):SmartVoucher {
        let specialItem = SmartVoucher.getSpecialItemOrNull(saleItems,specialItems);
        if (specialItem) {

          switch (specialItem.hndlr) {
            case SmartVoucher.VALU_HANDLER:
              return new PositiveTS.Service.Valu()
            case SmartVoucher.GPP_HANDLER:
              return new PositiveTS.Service.GPP()
            case SmartVoucher.MULTIPASS_HANDLER:
              return new PositiveTS.Service.Multipass()
            case SmartVoucher.PLUXEE_HANDLER:
                return new PositiveTS.Service.Pluxee()
            case SmartVoucher.TENBIS_HANDLER:
                return new PositiveTS.Service.Tenbis()
            case SmartVoucher.BEENGO_HANDLER:
                return new PositiveTS.Service.Beengo()
            case SmartVoucher.VALUE_CARD_HANDLER:
                return new PositiveTS.Service.ValueCard()
            case SmartVoucher.PRAXELL_HANDLER:
              return new PositiveTS.Service.Praxell();
            case SmartVoucher.GOODI_HANDLER:
              return new PositiveTS.Service.Goodi();
            case SmartVoucher.BANX_HANDLER:
              return new PositiveTS.Service.BanxPayment(null, null);
            case SmartVoucher.SAFECASH_HANDLER:
              return new PositiveTS.Service.SafeCashPayment(null, null);
            case SmartVoucher.YAAD_HANDLER:
              return new PositiveTS.Service.YaadPayment();
            case SmartVoucher.MULTIPASS_POLICE_HANDLER:
              return new PositiveTS.Service.MultipassPolice();
            case SmartVoucher.INTERSERVICE_HANDLER:
              return new PositiveTS.Service.InterService();
            case SmartVoucher.ICMEGA_HANDLER:
              return new PositiveTS.Service.icMega();
            case SmartVoucher.OTOT_HANDLER:
              return new PositiveTS.Service.Otot();
            default:
              return null
          }
        }
        else {
          return null
        }
      }

      static getSmartVoucherFromLoadPaymentMehtod(paymentMehtod:number):any {
        switch (paymentMehtod) {
          case PositiveTS.Storage.Entity.SalePayment.METHOD_PRAXELL:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PRAXELL);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_VALU:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_GPP:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GPP);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_VALUE_CARD:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALUE_CARD);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_MULTIPASS:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_GOODI:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_MULTIPASS_POLICE:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE);
          case PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT:
            return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT);
          // case PositiveTS.Storage.Entity.SalePayment.METHOD_CIBUS:
          //     return SmartVoucherFactory.createVoucher(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE);
          default:
            return null;
        }
      }

      static isItemValid(saleItem:any,saleItems:any,specialItems:Array<SpecialItem>):boolean {
        let foundItemArr;
        let counter = 0
        let isItemSpecial = (specialItems.filter( (specialItem) => {
          return (saleItem.itemCode === specialItem.code && SmartVoucher.isSmartVoucherHandler(specialItem.hndlr))
        }).length > 0);
        if (isItemSpecial) {
          if (saleItems.length > 1) {
            return false
          }
          if (saleItems.length === 1 && saleItems[0].id !== saleItem.id) {
            return false
          }
          if (saleItem.quantity !== 1) {
            return false
          }
        }
        else { //item is not special - check if there are any special items
          for (var item of saleItems) {
            foundItemArr = specialItems.filter( (specialItem) => {
              return (item.itemCode === specialItem.code && SmartVoucher.isSmartVoucherHandler(specialItem.hndlr))});
            counter += foundItemArr.length;
            if (counter > 0) {
              return false;
            }
          }
        }

        return true;
      }

      static isPaymentmethodSpecialitem(method_id){
        return [PositiveTS.Storage.Entity.SalePayment.METHOD_PRAXELL,
        PositiveTS.Storage.Entity.SalePayment.METHOD_VALUE_CARD,
        PositiveTS.Storage.Entity.SalePayment.METHOD_OTOT,
        Storage.Entity.SalePayment.METHOD_VALU,
        Storage.Entity.SalePayment.METHOD_MULTIPASS,
        Storage.Entity.SalePayment.METHOD_YAAD,
        Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL,
        Storage.Entity.SalePayment.METHOD_MULTIPASS_POLICE,
        PositiveTS.Storage.Entity.SalePayment.METHOD_GPP].indexOf(parseInt(method_id)) > -1
      }

      static getSpecialItemByCode(code){
        var specialItems = posVC.Specialitems;
        for (var item of specialItems) {
          if (item.code == code && code != PositiveTS.Service.ChargableCardBulkLoad.CODE_CARD_BULK_ITEM) {
            return item;
          }
        }
        return null;
      }


      static isSpecialItemCode(code){
        let specialItem = this.getSpecialItemByCode(code);
        return posUtils.isPresent(specialItem);
      }

      static discountAllowedOnSpecialItem(code){
        let specialItem = this.getSpecialItemByCode(code);
        return posUtils.isPresent(specialItem) && Boolean(specialItem.discountAllowed);
      }

      static async populateSpecialitems() {
        var specialitem = new PositiveTS.Storage.Entity.Specialitem();
        let data = await specialitem.all()
        posVC.Specialitems = [];
        for (var i = 0; i < data.length; i++) {
          if (session.pos.companyID == data[i].company_id && session.pos.tenantID == data[i].tenant_id) {
            posVC.Specialitems.push(data[i]._data);
          }
        }
        
      };

      static getSpecialItemOrNull(saleItems:any,specialItems:Array<SpecialItem>):any {
        if (saleItems.length === 1 && saleItems[0].quantity === 1) {
          let code = saleItems[0].itemCode
          let lst = posVC.Specialitems;
          let foundItemArr = specialItems.filter( (specialItem) => {return code === specialItem.code});
          if (foundItemArr.length !== 1) {
            return null
          }
          return foundItemArr[0];
        }
        else {
          return null
        }
      }

      static isSpecialItemCodeGiftCard (code) {
        return posVC.Specialitems.filter(si => si.code == code && [SmartVoucher.VALU_HANDLER, SmartVoucher.OTOT_HANDLER].includes(si.hndlr))[0] != null;
      }

      static async isAllowCreditGiftCardSale (sale) {
        let result = true;
        const [cardNumber, cardLoadAmount] = SmartVoucher.getGiftCardNumberAndLoadAmountFromSale(sale);
        let currentBalance = await SmartVoucher.getCurrentBalanceGiftCard(cardNumber as string);
        
        if (Number(cardLoadAmount) > currentBalance){
          result = false;
        }

        return result;
      }

      static async getCurrentBalanceGiftCard (cardNumber: string) {
        let currentBalance = 0;

        if (cardNumber){
          let valu = new PositiveTS.Service.Valu;
                
          await valu.getBalance(cardNumber).then((r) => {
            if (r.success){
              currentBalance = r.balance
            }
          })
        }

        return currentBalance;
      }

      static getGiftCardNumberAndLoadAmountFromSale (sale) {
        let card = ['',0];

        if (sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT && sale.items.length == 1 && SmartVoucher.isSpecialItemCodeGiftCard(sale.items[0].barcode)){
          sale.payments.map((p) => {
            if (p.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VALU){
              let paymentData = JSON.parse(p.data);
              if (paymentData && paymentData[0].success && paymentData[0].card){
                card[0] = paymentData[0].card.number
                card[1] = paymentData[0].amount
              }
            }
          })
        }
        return card;
      }
    }

    export class SmartVoucherFactory {
      static createVoucher(voucherType: string):PositiveTS.Service.SmartVoucher {
        switch (voucherType) {
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PRAXELL:
            return new PositiveTS.Service.Praxell()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALU:
            return new PositiveTS.Service.Valu()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GPP:
            return new PositiveTS.Service.GPP()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_VALUE_CARD:
            return new PositiveTS.Service.ValueCard()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS:
            return new PositiveTS.Service.Multipass()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE:
            return new PositiveTS.Service.MultipassPolice()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CIBUS:
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PLUXEE:
            return new PositiveTS.Service.Pluxee()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_TENBIS:
            return new PositiveTS.Service.Tenbis()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BEENGO:
              return new PositiveTS.Service.Beengo()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT:
              return new PositiveTS.Service.CaveretPayment()
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GOODI:
            return new PositiveTS.Service.Goodi();
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_DTS:
            return new PositiveTS.Service.Dts();
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_BANXPAYMENT:
            return new PositiveTS.Service.BanxPayment(null, null);
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_SAFECASH:
            return new PositiveTS.Service.SafeCashPayment(null, null);
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT:
            return new PositiveTS.Service.YaadPayment();
            case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_INTERSERVICE:
              return new PositiveTS.Service.InterService();
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_ICMEGA:
            return new PositiveTS.Service.icMega();
          case PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_OTOT:
            return new PositiveTS.Service.Otot();
          default:
            return null;
        }
      }
    }
  }
}
