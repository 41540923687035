module PositiveTS {
  export module Components {
      export module LoadingMessage {
  
        const dialogSelector = "dialog-loading-message"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            header: "",
            content: "",
            imageUrl: null,
            imageHeight: null,
            hideCancelButton: false,
            continueButtonText: "",
            cancelButtonText: "",
            cancelCallback: null,
            continueCallback: null,
            tooLong: false,
            tooLongTimeout : null,
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.loadingMessage(),
            methods: {
              reload(){
                window.location.reload();
              },
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              cancel() {     
                if(this.cancelCallback != null){
                  this.cancelCallback();
                }           
                this.resolveFunc("cancel");
                this.close();
              },
              
              async confirmAndClose() {
                if(this.continueCallback != null){
                  this.continueCallback();
                }
                  this.resolveFunc("continue");
                  this.close();
              },
      
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }
                  if(this.tooLongTimeout != null){
                    clearTimeout(this.tooLongTimeout);
                  }
                  this.dialogElem.close();
                }
                this.cleanData()
              },
      
              
        
              open() {
                return new Promise((resolve,reject) => {
                  this.initialized = true;
        
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  if (!this.dialogElem.open) {
                    this.dialogElem.showModal();
                    this.tooLongTimeout = setTimeout(() => {
                      this.tooLong = true;
                    },120000)
                  }
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
            computed: {
            }
          }
  
          VueApp.component('loading-message',component)
        }
      }
    }
  }
  