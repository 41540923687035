module PositiveTS {
  export module Components {
    export module ElalPermissionDialog {
      export function create() {
        let component = {
          template: JST.ElalPermissionDialog(),
          data: () => {
            return {
              content: '',
            }
          },
          methods: {
            close() {
              this.$el.close();
              this.cleanData();
            },
            async updateDB() {
              Pinia.globalStore.setAvoidNextLogin(false);
              await statsVC.reloadAllData();
            },
            reload() {
              window.location.reload();
            },
            open(content: string) {
              this.content = content;
              return new Promise((resolve, reject) => {
                if (!this.$el.open) {
                  this.$el.showModal();
                }
              })
            }
          },
          setup(){

            const globalStore = Pinia.useGlobalStore();
            const {onSelfServicePaymentDialog,simpleSelfService} = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {selfService:onSelfServicePaymentDialog,simpleSelfService} 

            return {...globalStoreProps}
          },

          computed: {
          }
        }

        VueApp.component('elal-permission-dialog', component)
      }
    }
  }
}
