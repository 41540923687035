module PositiveTS {
  export module ElalDocNumberQueue {
    export async function launchQueueExt() {
      try {
        let pos = await workerDB.poses.toArray();
        if (pos[0].isElal) {
          await launchQueue();
        }
      } catch (error) {
        let msg = "";
        if (error && error.message) {
          msg = error.message;
        }
        if (typeof error === "string") {
          msg = error;
        }
        console.error(`Did not send ElalReservation to server. Reason: ${msg}`);
      }

      // Schedule the next queue launch
      setTimeout(() => {
        ElalDocNumberQueue.launchQueueExt();
      }, 18000000);
    }

    async function launchQueue() {
      if (!PositiveTS.QueueWorker.Utils.isOnline) {
        console.debug("Aborting (offline)");
        return;
      }
      await sendToServerUpdatetEkt();
      return;
    }

    export async function sendToServerUpdatetEkt() {
      const reservationsToServer = await getReservationToSendToServer();
      const failedReservations = await sendToServerUpdatedEKT(reservationsToServer);
      if(failedReservations) {
        await deleteUnfailedReservations(failedReservations);
      }
    }
    export async function fetchByStatuses(statuses: Array<number>) {
      let elalReservation = await workerDB.elalDocNumbers
        .where("status")
        .anyOf(statuses)
        .toArray();
      return elalReservation;
    }
    async function deleteUnfailedReservations(failedReservations) {
      const reservations = await fetchByStatuses([2]);
      if (reservations.length > 0) {
        const unfailedReservations =
          failedReservations.length > 0
            ? reservations.filter(
                (reservation) =>
                  !failedReservations.some(
                    (failedReservation) =>
                      failedReservation.pnr === reservation.pnr &&
                      failedReservation.recId === reservation.recId
                  )
              )
            : reservations;
        for (let {recId, pnr} of unfailedReservations) {
          await workerDB.elalDocNumbers.where({recId, pnr}).delete();
        }
      }
    }
    async function sendToServerUpdatedEKT(reservations) {
      try {
        let req = { reservations: reservations };
        const res = await PositiveTS.QueueWorker.FetchReq.req(
          `${Shared.Constants.remoteRoot}sales/update_elal_docnumbers`,
          "POST",
          PositiveTS.QueueWorker.Utils.token,
          req
        );
        return res.json();
      } catch (error) {
        let msg = "";
        if (error && error.message) {
          msg = error.message;
        }
        if (typeof error === "string") {
          msg = error;
        }
        console.error(`Did not send ElalReservation to server. Reason: ${msg}`);
        return false;
      }
    }

    async function getReservationToSendToServer() {
      const reservations = (await fetchByStatuses([2])).map((res) => {
        return {
          rec_id: res.recId,
          pnr: res.pnr,
          ticket_number: res.docNumber,
          ticket_type: res.docType,
        };
      });
      return reservations;
    }
  }
}
