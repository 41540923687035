module PositiveTS {
  export module Components {
    export module FlightsOpenLegWizardDialog {

      const _dialogSelector = "#positive-flights-open-leg-wizard-dialog";
      const _vueComponentName = 'flights-open-leg-wizard-dialog';

      export function create() {
        let flightOnlineloginDialog = {
          template: JST.flightsOpenLegWizardDialog(),
          mixins: [Mixins.modalsMixin],
          components: {
            FlightsOfflineLogin: FlightsOfflineLogin.getComponent(),
            FlightsSelectAuthorizedEmployees: FlightsSelectAuthorizedEmployees.getComponent(),
            FlightsBarsetCount: FlightBarsetCount.getComponent(),
            FlightsPrintBarsetCountReport: FlightsPrintBarsetCountReport.getComponent(),
          },
          methods: {
            async open() {
              this.$el.show();

              if (!this.nextUnopenedLeg) {
                await this.promiseShowAlertGeneric(i18next.t('flightsWizard.noMoreLegs'));
                this.close();
              }
            },
            close() {
              this.$el.close();
            },
            goNext() {
              this.step++;
            },
            goBack() {
              this.step--;
            },
            finishWizard() {
              this.showAlertWithTitleGeneric(i18next.t('flightsWizard.commitChanges'),
                i18next.t('flightsWizard.openLegCompleted'),
                this.applyAndCloseWizard,
                this.goBack,
                false,
                i18next.t('flightsWizard.approve'),
                i18next.t('flightsWizard.goBack'));
            },
            async applyAndCloseWizard() {
              app.showLoadingMessage(i18next.t("loading"));

              try {
                await this.openLeg(this.allWizardData);
                app.hideLoadingMessage();
              } catch (err) {
                console.error(err);
                app.hideLoadingMessage();
                this.showGenericError();
                this.goBack();
                return;
              }

              this.clearWizard();
              this.close();
            },
            clearWizard() {
              this.step = 0;
              this.wizardStepsData = [];
            },
            getDefaultWizardData() {
              return {selectedLeg: this.nextUnopenedLeg};
            },
          },
          computed: {
            allWizardData() {
              return this.wizardStepsData.reduce((allData, currentData) => Object.assign(allData, currentData), this.getDefaultWizardData());
            },
            currentStepsComponets() {
              let currentComponents = ['FlightsOfflineLogin'];

              if (!this.isPreviousClosedLegTeam) {
                currentComponents = currentComponents.concat(['FlightsSelectAuthorizedEmployees',
                                                              'FlightsBarsetCount',
                                                              'FlightsPrintBarsetCountReport']);
              }

              return currentComponents;
            },
          },
          setup(){
            const flightsStore = Pinia.useFlightsStore();
            const {nextUnopenedLeg,lastClosedLeg,isPreviousClosedLegTeam} = Pinia.storeToRefs(flightsStore);

            const {openLeg} = flightsStore;
            return {nextUnopenedLeg,lastClosedLeg,isPreviousClosedLegTeam,openLeg}
          },
          data() {
            return {
              step: 0,
              wizardStepsData: [],
            };
          },
          watch: {
            step() {
              if (this.step < 0) {
                this.step = 0;
                this.close();
              }

              if (this.step >= this.currentStepsComponets.length) {
                this.finishWizard();
              }
            }
          },
        }
        VueApp.component(_vueComponentName, flightOnlineloginDialog)
      }
    }
  }
}