module PositiveTS {
export module Service {
export module WasmDB {


	export function execAsObject(query:string, bindValues?:any) {
		let wasmDB = (<any>window).wasmDB;
		return execAsObjectWithDB(query, wasmDB, bindValues);
	}

	export function promiseSql(strSql):Promise<Array<any>> {
		let wasmDB = (<any>window).wasmDB;

		return promiseSqlWithDB(strSql, wasmDB);
	};

	export async function getTableColumnsInfo(tableName: string): Promise<PositiveTS.Types.WasmDBTableColInfo[]> {
		return await promiseSql(`PRAGMA table_info(${tableName});`)
	}

	export async function getTableColumnsName(tableName: string) {
		let info = await getTableColumnsInfo(tableName);
		return info.map(col => col.name);
	}

	export async function getExistingTableColumns(tableName: string, cols: string[]) {
		let existingColumns = await getTableColumnsName(tableName);
		return cols.filter(col => existingColumns.includes(col));
	}

	export function execAsObjectWithDB(query:string, wasmDB:any, bindValues?:any) {
		let stmt = wasmDB.prepare(query);
		let result = [];
		try {
			if (!posUtils.isBlank(bindValues)){
				stmt.bind(bindValues)
			}
			while (stmt.step()) {
				result.push(stmt.getAsObject())
			}
			stmt.free();
		}
		catch(err) {
			stmt.free();
			console.error(err);
			throw err;
		}
		
		return result;
	}

	export function promiseSqlWithDB(strSql, db):Promise<Array<any>> {
		return Promise.resolve(execAsObjectWithDB(strSql, db));
	};


	export async function loadSQLWASM() {
      console.time("load full DB");
      console.time("init sql wasm");
      (<any>window).SQL = await (<any>window).initSqlJs();
      console.timeEnd("init sql wasm");
      console.time("create buffer from IDB");
      let rec:any = await appDB.dataDumps.get(PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB);

      if (rec) {
        try {
          let buffer = await rec.data.arrayBuffer();
          let data = new Uint8Array(buffer);
          (<any>window).wasmDB =  new (<any>window).SQL.Database(data);
        } catch(err) {
          console.error("Failed to load CACHE DB Data");
          console.error(err);
        }
      } else {
        console.log("No wasm db data");
        (<any>window).wasmDB =  new (<any>window).SQL.Database();
		}
		
      console.timeEnd("create buffer from IDB");
      console.timeEnd("load full DB")
	 }
	 
	 export async function execAndSave(sql) {
		let activeWasmDB = (<any>window).wasmDB;
		activeWasmDB.exec(sql);
		await appDB.dataDumps.put({name: PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB, data: new Blob([activeWasmDB.export().buffer])})
	 }

	 export function tableExists(tableName, db = null) {
		let res = execAsObject(`select count(*) as cnt from sqlite_master where name='${tableName}' and type='table'`)
		return res[0].cnt == 1
	}
}}}
