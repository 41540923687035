class SaleItemHelper {

	extractSaleItemChildrenAndGrandchildren (saleItem: PositiveTS.Storage.Entity.SaleItem, fetchGrandchildren = false) {
        let result: {children: Array<PositiveTS.Storage.Entity.SaleItem>, grandchildren: Array<PositiveTS.Storage.Entity.SaleItem>} = {
          children: [],
          grandchildren: []
        };

		if (saleItem.hasGroups && Array.isArray(saleItem.children)){
			result.children = saleItem.children;
		}

		if (fetchGrandchildren){
			result.children.forEach(child => {
				if (child.hasGroups && Array.isArray(child.children)) {
					result.grandchildren.push(...child.children);
				}
			});
		}
		
        return result;
	}

	unflattenSaleItems(saleItems, isFromServer = false, isNewPromotions = true){
		if (saleItems.findIndex(item => item.level != null && item.level > 0) == -1) {
			return saleItems;
		}
		
		let flattendSaleItems = saleItems.map(item => _.clone(item))
		for(let saleItem of flattendSaleItems){
			if(saleItem.level != 2){
				saleItem.children = [];
			}
		}

		let flattenSaleItemsByRowNumber = _.keyBy(flattendSaleItems, 'rowNumber')
		
		for (let rowNumber in flattenSaleItemsByRowNumber) {
			let saleItem = flattenSaleItemsByRowNumber[rowNumber];

			if (saleItem.parentRowNumber == -1) {
				continue;
			}

			let potentialFather = flattenSaleItemsByRowNumber[saleItem.parentRowNumber];
			saleItem.parent = potentialFather;

			potentialFather.children.push(saleItem)
		}

		flattendSaleItems = Object.values(flattenSaleItemsByRowNumber).filter((item:any) => item.level == null || item.level == 0)


		// fix for printing - because it not aligned in the system
		if (isFromServer) {
			for (let rootSaleItem of flattendSaleItems) {
				if (posUtils.isBlank(rootSaleItem.children)) {
					continue;
				}

				let unitPriceToAdd = 0;

				for (let childSaleItem of rootSaleItem.children) {
					childSaleItem.quantity = session.pos.companyUsesSaleDataJson ? childSaleItem.quantity : 1;
					unitPriceToAdd += childSaleItem.quantity * childSaleItem.unitPrice;

					if (!posUtils.isBlank(childSaleItem.children)) {
						for (let grandChildSaleItem of childSaleItem.children) {
							grandChildSaleItem.quantity = session.pos.companyUsesSaleDataJson ? grandChildSaleItem.quantity : 1;
							unitPriceToAdd += grandChildSaleItem.quantity * grandChildSaleItem.unitPrice;
						}
					}
				}

				if (isNewPromotions && !session.pos.companyUsesSaleDataJson) {
					rootSaleItem.unitPrice += session.fixedFloat(unitPriceToAdd);
				}
			}
		}

		return flattendSaleItems;
	}

	flattenSaleItemsByQuantity(saleItems:PositiveTS.Storage.Entity.SaleItem[], flattenWeightItems = true, cloneWithChildren = false) {
		saleItems = saleItems || []
		let itemsCopy = saleItems.map(item => item.clone(cloneWithChildren));
		let flattenedSaleItems = [];

		// Flatten sale items so will have quantity = 1
		itemsCopy.forEach((saleItem) => {
			let wholeItemsAmount = Math.floor(saleItem.quantity);
			let restOfWholeItem = saleItem.quantity % 1;
			
			if (!flattenWeightItems && saleItem.hasWeight) {
				saleItem.realQuantity = saleItem.quantity;
				flattenedSaleItems[flattenedSaleItems.length] = saleItem;
			} else {
				if (wholeItemsAmount > 0){
					flattenedSaleItems = flattenedSaleItems.concat(Array.from({length: wholeItemsAmount}, () => {
						let itemToDuplicate = saleItem.clone(cloneWithChildren);
						itemToDuplicate.realQuantity = itemToDuplicate.quantity;
						itemToDuplicate.quantity = 1;
						return itemToDuplicate;
					}));
				}
				
				if(restOfWholeItem > 0) {
					let itemToDuplicate = saleItem.clone(cloneWithChildren);
					itemToDuplicate.realQuantity = itemToDuplicate.quantity;
					itemToDuplicate.quantity = restOfWholeItem;
					flattenedSaleItems.push(itemToDuplicate);
				}
			}
			
		})

		return flattenedSaleItems;
	}

	splitSaleItemByQty(saleItem: PositiveTS.Storage.Entity.SaleItem, quantityToSplit: number) {
		if (saleItem.quantity < quantityToSplit) {
			return [saleItem];
		}

		let baseSaleItem = saleItem.clone();
		baseSaleItem.realQuantity = saleItem.realQuantity;


		let clonedSaleItem = saleItem.clone();
		clonedSaleItem.realQuantity = saleItem.realQuantity;

		clonedSaleItem.quantity = session.fixedFloat(clonedSaleItem.quantity - quantityToSplit, 3);
		baseSaleItem.quantity = quantityToSplit;

		return [baseSaleItem, clonedSaleItem];
	}

	removePromotionFromSaleItem(saleItem) {
		saleItem.buyPromotionCode = -1;
		saleItem.alreadyUsedForFixed = false;

		if (saleItem.getPromotionCode != -1) {
			saleItem.getPromotionCode = -1;
			saleItem.discountID = -1;
			saleItem.discountPercent = -1;
			saleItem.discountAmount = -1;
			saleItem.discountType = PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL;
			saleItem.discountName = '';
		}

		return saleItem;
	}

	addPromotionToSaleItem(saleItem, promotionObject) {
		if (promotionObject.buyGet == 'buy') {
			saleItem.buyPromotionCode = promotionObject.promotionCode;
		}
		if (saleItem.alreadyUsedForFixed) {
			return;
		}

		saleItem.alreadyUsedForFixed = promotionObject.alreadyUsedForFixed;

		if (promotionObject.isPromotionGiven &&
				(promotionObject.discountAbsoluteValue >= 0) ) {
			saleItem.getPromotionCode = promotionObject.promotionCode;

			saleItem.discountID = -1;
			saleItem.discountPercent = promotionObject.discountPrecentValue;
			saleItem.discountAmount = promotionObject.discountAbsoluteValue;
			saleItem.discountType = promotionObject.discountType;
			saleItem.discountName = promotionObject.promotionName;
		}
	}

	getSaleItemsTotalAmount(saleItems) {
		var totalAmount = 0;

		for (var i = saleItems.length - 1; i >= 0; i--) {
			var saleItem = saleItems[i];

			totalAmount += saleItem.unitPrice * saleItem.quantity;
		};

		return session.fixedFloat(totalAmount);
	}

	getSaleItemsTotalQuantity(saleItems, pickupOnly = false) {
		var totalQuantity = 0;

		for (var i = saleItems.length - 1; i >= 0; i--) {
			var saleItem = saleItems[i];

			var itemCondition = saleItem.quantity > 0;
			if (pickupOnly) {
				itemCondition = saleItem.quantity > 0 &&
								saleItem.isPickup;
			}

			if (itemCondition) {
				totalQuantity += saleItem.quantity;
			}
		};

		return totalQuantity;
	}

	getSaleItemTotalPrice(saleItem) {
		var price = saleItem.unitPrice * saleItem.quantity;

		return price - saleItemHelper.getSaleItemDiscountAmount(saleItem);
	}

	//sync
	getSaleItemDiscountAmount(saleItem:PositiveTS.Storage.Entity.SaleItem, quantity = undefined):number {
		if (quantity === undefined) {
			quantity = saleItem.quantity;
		}

		switch (saleItem.discountType) {
			case PositiveTS.Storage.Entity.SaleItem.DiscountType.PERCENT:
			case PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT:
				return Number(saleItem.discountAmount) * quantity / saleItem.quantity;
			case PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL:
				return 0;
			default:
				return 0;
		}
	}

	doesItemContainDiscountOrPromotion (saleItem) {
		return (saleItem.discountType != PositiveTS.Storage.Entity.SaleItem.DiscountType.NULL) ||
		(saleItem.getPromotionCode != '-1' && saleItem.getPromotionCode != null);
	}

	async createNewSaleItemFromGenernicItem(name: string, price: number, persist = true, quantity = 1, otherArgs: PositiveTS.Types.CreateNewSaleItemFromGenernicItemOptionalArgs = {}) {
		try {
			let item: PositiveTS.Storage.Entity.Item = session.allItems.get(PositiveTS.Shared.Constants.Item.GENERIC_ITEM);
			if (!item) {
				if (otherArgs.dontThrowErrorOnItemNotFound) {
					item = new PositiveTS.Storage.Entity.Item()
					item.code = '1000';
				} else {
					throw new Error("general item not found!");
				}
			}
			let sizesColors = [{ size: 'null', color: 'null', barcode: item.code }];
			let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, sizesColors[0]);
			saleItem.saleID = posVC.sale.id;
			saleItem.rowNumber = posVC.getRowNumber();
			saleItem.quantity = quantity;
			saleItem.originalUnitPrice = price;
			saleItem.unitPrice = price;
			saleItem.priceAlut = price;
			saleItem.itemDescription = name;
			if (posUtils.isPresent(otherArgs?.preparationInstructions)) {
				saleItem.selectedPreparationInstructions = JSON.stringify(otherArgs?.preparationInstructions);
			}
			saleItem.hasPreparationInstructions = Boolean(otherArgs?.hasPreparationInstructions);
			// Set the size and color for the item to the only size and color combination
			saleItem.color = sizesColors[0].color;
			saleItem.size = sizesColors[0].size;
			saleItem.barcode = sizesColors[0].barcode;
			if (persist) {
				await posVC.persistNewSaleItem(saleItem, true);
			}
			return saleItem;
		} catch (error) {
			PositiveTS.Service.Filelog.log("Error on createNewSaleItemFromGenernicItem" + error.message, JSON.stringify(error), false, 'error');
			console.error(error);
			return null;
		}

	}

}
declare var saleItemHelper:SaleItemHelper;
saleItemHelper = new SaleItemHelper();
