module PositiveTS {
export module Storage {
export module Entity {
export class DalpakArea extends IDBEntity implements Service.DalpakInfra.DalpakArea {

	color: string;
	name: string;
	sortOrder: number;
	railsId: number;

	constructor() {
		let meta = {
			name: 'DalpakAreas',
			fields: {
				color: "TEXT",
				name: "TEXT",
				sortOrder: "INT",
				railsId: "INT",
			},
		}
		super(meta)
	}
}}}}
