module PositiveTS {
    export module Components {
    export module EmployeeManageEdit {
      export const route = '/edit-employee';
    
      type CompType = any;

      export function getComponent() {
        return {
            template: JST.employeeManageEdit(),
            methods: {
              
              ...Mixins.modalsMixin.methods, //instead of mixins: [Mixins.modalsMixin] which does not compile.
              close() {
                VueServices.Router.goto(EmployeeManage.route)
              },

              async validate() {
                if (posUtils.isNullOrUndefinedOrEmptyString(this.employee.name)) {
                  await this.promiseShowAlertGeneric(i18next.t('employeeManageEdit.nameCantBeEmpty'));
                  return false;
                }
                if (posUtils.isNullOrUndefinedOrEmptyString(this.employee.magneticCard)) {
                  await this.promiseShowAlertGeneric(i18next.t('employeeManageEdit.cardCantBeEmpty'));
                  return false;
                }
                if(!posUtils.isMagneticCardValid(this.employee.magneticCard)){
                  await this.promiseShowAlertGeneric(i18next.t('employeeManageEdit.cardCantBeWithSpacialChars'));
                  return false;
                }
                let empsWithSameCard = await appDB.employees.where('magneticCard').equals(this.employee.magneticCard)
                  .filter(emp => emp.id != this.employee.id).count()
                if (empsWithSameCard > 0) {
                  await this.promiseShowAlertGeneric(i18next.t('employeeManageEdit.cardAlreadyUsed'));
                  return false;
                }

                let lastEmpTimeTrack = await appDB.employeeTimeTracks.orderBy('timestamp').reverse().filter(emptt => emptt.employeeID == this.employee.employeeID).toArray()
                if( (lastEmpTimeTrack.length > 0) && (lastEmpTimeTrack[0].mode == Storage.Entity.EmployeeTimeTrack.MODE_ENTER) && (this.employee.isLocked)){
                  await this.promiseShowAlertGeneric(i18next.t('employeeManageEdit.cannotLockEmpInShift'));
                  return false;
                }

                if (!this.employee.isManagerDiscountConfirm) {
                  let managers = await appDB.employees.filter(emp => emp.isManagerDiscountConfirm).toArray();
                  if (managers.length == 0 || ((managers.length == 1) && (managers[0].employeeID == this.employee.employeeID))) {
                    await this.promiseShowAlertGeneric(i18next.t("employeeManageEdit.cannotRemoveLastManager"));
                    return false;
                  }
                }
                return true;
              },

              swipeCard: async function(){
                app.showLoadingMessage("העבר כרטיס");
                let cardNumber = await Service.EMV.swipe();
                  if(cardNumber){
                    this.employee.magneticCard = cardNumber;
                  }
                app.hideLoadingMessage();
              },
              useSwipe: function(){
                return (jsonConfig.getVal( jsonConfig.KEYS.usePinPadSwipe )) && (session.pos.isEmv)
              },
              async doConfirm() {
                try {
                  
                  let valid = await this.validate()
                  if (!valid) {
                    return;
                  }
                
                  this.employee.isManagerMinusConfirm = this.employee.isManagerDiscountConfirm;
                  this.employee.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
                  if (posUtils.isNullOrUndefinedOrEmptyString(this.employee.employeeID)) {
                    this.employee.employeeID = this.employee.id;
                  }
                  await appDB.employees.put(this.employee)

                  this.close();
                          
                } 
                catch (e){
                  console.error(e);
                  await app.promiseShowAlert({
                    header: i18next.t("error"),
                    content: i18next.t("employeeManageEdit.errorCheckValues"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true  })
                }
              },
              doReject(){
                this.close();
              },
            },
            data():{employee:Storage.Entity.Employee, editMode: boolean} {
              return {      
                employee: new Storage.Entity.Employee(),
                editMode: false,
              };
            },
            beforeRouteEnter: async function(to, from, next) {
              app.showLoadingMessage(i18next.t('loading'));
              let employee = null;
              let editMode = false;
              if (to.params.id != null) {
                editMode = true;
                employee = await appDB.employees.get(to.params.id);
              }
              
              next(vm => {
                let self = <CompType>vm;
                self.editMode = editMode;
                if (self.editMode) {
                self.employee = employee;
                }
                
                app.hideLoadingMessage();
              });
            },
    
    
        }
    
    
      }
    
}}}
