module PositiveTS {
    export module Service {
    export module ChargableCardBulkLoad {
        export const CODE_CARD_BULK_ITEM = 'C_BULK_ITEM'

        export async function checkIfSaleUsedOnChargableCardLoadBulk (sale: Entity.Sale): Promise<{hasBulkLoad: boolean, errors: Array<string>}>{
            let result =  {hasBulkLoad: false, errors: []}

            try {
                const hasBulkItem = sale.items.findIndex(i => i.itemCode == CODE_CARD_BULK_ITEM) > -1

                if (hasBulkItem){     
                    let url = `/sales/${sale.invoiceSequence}/check_if_used_on_chargable_card_load_bulk/?store_id=${sale.storeID}&pos_device_id=${sale.posDeviceID}`
                    let res = await PositiveTS.Service.FetchReq.jsonRequestPromise(url, 'get')
                    if (res.data){
                        result.hasBulkLoad = res.data.has_bulk_load
                        if (result.hasBulkLoad){
                            result.errors.push(i18next.t('isCreditable.canNotCreditSaleUsedOnChargableCardLoadBulk'))
                        }
                    }                    
                }
            }catch(error){
                console.error(error)
                result.errors.push(i18next.t('isCreditable.validateCreditGlobalError'))
            }

            return result 
        }
    }
}}

