module PositiveTS {
    export module Components {
        export module HakafaReportDialogNew {
            const dialogSelector = "hakafa-report-dialog-new"

            export function create() {

                let component = {
                    template: JST.hakafaReportDialogNew(),
                    methods: {
                        open(customerNumber) {
                            this.customerNumber = customerNumber
                            this.$el.showModal();
                        },

                        close() {
                            this.$el.close();
                            this.clearData()
                        },

                        async search() {
                            app.showLoadingMessageDialog(i18next.t("preparingLoadData"));
                            let report = await PositiveTS.Service.Hakafa.getReport(this.customerNumber, this.fromDate, this.toDate)
                            app.hideLoadingMessageDialog()
                            let result = report.msg
                            if (report.status !== 0) {
                                this.showError(result)
                                return;
                            }
                            this.hakafaReport = result
                            this.totalAmount = result[result.length -1].bal
                        },

                        showError(errorMessage) {
                            app.showAlertDialog({
                                header: i18next.t('error'),
                                content: errorMessage,
                                continueButtonText: i18next.t("ok"),
                                hideCancelButton: true
                            })
                        },

                        goBack() {
                            this.hakafaReport = []
                        },

                        clearData() {
                            this.customerNumber = "",
                            this.fromDate = null,
                            this.toDate = null,
                            this.hakafaReport = [],
                            this.totalAmount = 0
                        }
                    },
                    data() {
                        return {
                            customerNumber: "",
                            fromDate: null,
                            toDate: null,
                            dateFormat: "dd/MM/yyyy",
                            hakafaReport: [],
                            totalAmount: 0
                        }
                    },
                }

                VueApp.component(dialogSelector, component)
            }
        }
    }
}
