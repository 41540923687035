/*|-----------------------------------------------------------------------------
 *| WebSQL API
 *|-----------------------------------------------------------------------------
 *| Database:
 *|  Database openDatabase(in DOMString name,
 *|                        in DOMString version,
 *|                        in DOMString displayName,
 *|                        in unsigned long estimatedSize,
 *|                        in optional DatabaseCallback creationCallback);
 *|  Note: estimatedSize is in Bytes
 *|
 *| Asynchronous database API:
 *|  void transaction(in SQLTransactionCallback callback,
 *|          in optional SQLTransactionErrorCallback errorCallback,
 *|          in optional SQLVoidCallback successCallback);
 *|
 *| SQLTransaction:
 *|  void executeSql(in DOMString sqlStatement,
 *|                  in optional ObjectArray arguments,
 *|                  in optional SQLStatementCallback callback,
 *|                  in optional SQLStatementErrorCallback errorCallback);
 *|
 *|-----------------------------------------------------------------------------
 */
module PositiveTS {
	export module Storage {
		/* Means that the storage was just created */
		export const StatusInitial = 'PositiveStorageStatusInitial';
		/* Means that the data in storage is complete */
		export const StatusOK = 'PositiveStorageStatusOk';
		/* Means that there is partial data in storage */
		export const StatusPartial = 'PositiveStorageStatusPartial';
		/* Means that storage is in invalid state, like no data at all, or some undefined state */
		export const StatusInvalid = 'PositiveStorageStatusInvalid';

		/* Means that an entity has all the data in it */
		export const EntityStatusValid = 'PositiveStorageEntityStatusValid';
		/* Means that the localStorage was deleted but storage not */
		export const EntityStatusInvalid = 'PositiveStorageEntityStatusInvalid';
		/* Means that that there is a missing data in an entity */
		export const EntityStatusPartial = 'PositiveStorageEntityStatusPartial';
		/* Means that that there is no data in an entity */
		export const EntityStatusNoData = 'PositiveStorageEntityStatusNoData';

		export const LocalStorageStateKey = 'Positive.Storage.state';
		export const LocalStorageStateMessageKey = 'Positive.Storage.stateMessage';
		export const LocalStorageTimestampKey = 'Positive.Storage.timestamp';
		export const LocalStorageEntityCountKeyTemplate = 'PositiveTS.Storage.Entity.{ENTITY_NAME}.count';

		export var db: any = null;
		/**
		 * Open the database and creates the needed tables (if not already exist)
		 */
		export async function init() {
			var promises = [];
			// --- Open db --------------------------------------------------------
			try {
				// if (!window.openDatabase) {

				// 	app.showAlert({
				// 		header: i18next.t('error'),
				// 		content: 'WebSQL is not supported on this browser. Positive cannot work without it. Please contact support.',
				// 		continueButtonText: i18next.t("ok"),
				// 		hideCancelButton: true
				// 	}, null, null);

				// 	// throw new Error("DB NOT SUPPORTED")
				// }

				await PositiveTS.Service.WasmDB.loadSQLWASM();

				PositiveTS.Storage.Entity.Entity.forEachModelEntity((entity) => {
					// Create the table for this entity
					promises.push((new PositiveTS.Storage.Entity[entity]()).createTable());

					window.localStorage.setItem(storage.LocalStorageTimestampKey, String(PositiveTS.DateUtils.timestamp()));
				});

				let res = await Promise.all(promises);

				return res;
			}
			catch (e) {
				// --- There was an error opening the database
				if (e === 2 || e === "2") {
					// Version number mismatch, tell the user
					app.showAlert({
						header: i18next.t('error'),
						content: 'Database version is invalid. Please contact support.',
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
				} else {
					app.showAlert({
						header: i18next.t('error'),
						content: 'There was an error opening the database. Please contact support. (' + e.message + ')',
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					}, null, null);
				}
				Service.Logger.error(e.message);

				return Promise.reject(e);
			}
		}
		export function addColumnToTable(tx, tableName, columnName, columnType) {
			var deferred = Q.defer();

			// For no we ignore errors
			tx.executeSql(
				'ALTER TABLE `' + tableName + '` ADD `' + columnName + '` ' + columnType,
				[], function (tx, result) {
					deferred.resolve();
				}, function (tx, error) {
					deferred.resolve();
				}
			);

			return deferred.promise;
		}
		export function isJsonDump(dumpName) {
			var jsonDumps = ["item-pictures", "positive-customer", "secondary-category", "screen-savers", "videos", "images"]
			return jsonDumps.includes(dumpName)
		}
		export function isDbDataFileDump(dumpName) {
			return dumpName == PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB;
		}
		export function shouldSaveAsDump(dumpName) {
			return storage.isDbDataFileDump(dumpName) || storage.uniquePerPosDataDumps().includes(dumpName)
		}
		export function uniquePerPosDataDumps() {
			// Store is here for the update pos params in roshemet
			return ['store-item', 'hakafa-customer', 'cities', 'streets', 'store'];
		}
		export function importDataDumps(dataDumps, dataDumpsNames) {
			var promises = [];
			console.debug('Persisting remote data...');
			if (dataDumps.length === 0) {
				console.error('No remote data to insert');
				return Promise.reject({ message: "Data dumps array is empty!" });
			}

			//(<any>window).html5sql.openDatabase('positive', 'Positive WebSQL Database', 50 * 1024 * 1024);
			var importFailed = false;

			let wasmDataDump = null;
			let perPosDataDumps = [];

			$.each(dataDumps, function (index, dump) {

				promises.push(new Promise(function (resolve, reject) {
					//if one of the imports failed - reject all remaining promises and finish...
					if (importFailed) {
						reject(new Error("failed because of previous failure"));
						dump.length = 0; //clear the memory of the current dump string
						return;
					}

					var dumpName = dataDumpsNames[index];
					console.time("loading " + dumpName);

					if (storage.isDbDataFileDump(dumpName)) {
						wasmDataDump = dump;
						resolve(index);
						console.timeEnd("loading " + dumpName);
						return;
					} else if (storage.uniquePerPosDataDumps().includes(dumpName)) {
						perPosDataDumps.push(dump);
						resolve(index);
						console.timeEnd("loading " + dumpName);
						return;
					} else if (storage.isJsonDump(dumpName)) {	//JSON dump
						var camelCasedDumpName = dumpName.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });

						return bulkputJsonDataDump(camelCasedDumpName, dump).then(function (index) {
							resolve(index);
							console.timeEnd("loading " + dumpName);
						}).catch(err => {
							console.timeEnd("loading " + dumpName);
							console.error("Error: " + err.message);
							console.error("Failing In loading json dump");

							reject(err);
						})
					}
					else {															//SQL dump
						var arr = dump.split(';');
						console.log(arr.length);
						dump.length = 0; //clear the memory of the current dump string
						console.warn("skip loading " + dumpName);
						resolve(index);
						// (<any>window).html5sql.process(
						// 	arr,
						// 	function () { //Success
						// 		arr.length = 0;
						// 		resolve(index);
						// 		console.timeEnd("loading " + dumpName);
						// 	},
						// 	function (error, failingQuery) { //Failure
						// 		console.error(index);
						// 		// console.debug(dataDumps[index]);
						// 		arr.length = 0;
						// 		console.timeEnd("loading " + dumpName);
						// 		console.error("Error: " + error.message);
						// 		console.error("Failing Query: " + failingQuery);
						// 		importFailed = true;
						// 		reject(error);
						// 	}
						// );
					}
				}));

			});
			return Promise.all(promises).then(async result => {
				let isNewWasm = dataDumpsNames.includes(PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB);
				let dbRecordData = (isNewWasm && wasmDataDump) ? wasmDataDump : ((await appDB.dataDumps.where('name').equals(PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB).first()).data);
				let buffer = await dbRecordData.arrayBuffer();
				let data = new Uint8Array(buffer);
				let db = new (<any>window).SQL.Database(data);

				await this.updateWasmDbBeforeSave(db, isNewWasm, perPosDataDumps);

				await appDB.dataDumps.put({ name: PositiveTS.Service.LoadRemoteDataJob.READ_ONLY_DB, data: new Blob([db.export().buffer]) })

				// After db save
				localStorage.setItem('dalpaksUpdateNeeded', 'true');

				return result;
			});
		}

		async function bulkputJsonDataDump(dumpName, dump) {
			
			if (['itemPictures', 'screenSavers', 'images'].includes(dumpName)) {
				console.log(dumpName);
				let chunkSize = 20;
				let data = JSON.parse(dump);
				let dataToSave = [];

				let lastImageTimestamp = parseInt(localStorage.getItem('lastImageTimestamp') || "0");
				let updatedLastImageTimestamp = lastImageTimestamp;

				for (let pictureData of data) {
					if (lastImageTimestamp < pictureData.updatedAt) {
						updatedLastImageTimestamp = Math.max(updatedLastImageTimestamp, pictureData.updatedAt);
						dataToSave.push(pictureData)
					}
				}

				let lastIndex = null;

				while (dataToSave.length > 0) {
					lastIndex = await appDB[dumpName].bulkPut(dataToSave.splice(0, chunkSize));
				}

				localStorage.setItem('lastImageTimestamp', String(updatedLastImageTimestamp))

				// do clean up
				let pkName = appDB[dumpName].schema.primKey.name;
				let allDbPkValues = (await appDB[dumpName].orderBy(pkName).keys()).sort();
				let allDumpPkValues = data.map(row => row[pkName]);
				let diffKeys = _.difference(allDbPkValues, allDumpPkValues);

				if (diffKeys.length > 0) {
					appDB[dumpName].bulkDelete(diffKeys);
				}

				return lastIndex;

			} else {
				return await appDB[dumpName].bulkPut(JSON.parse(dump));
			}
		}

		export async function updateWasmDbBeforeSave(db, isNewWasmDownloaded, perPosDataDumps) {
			for (let dump of perPosDataDumps) {
				db.exec("BEGIN;" + dump + ";END;");
			}

			if (isNewWasmDownloaded) {
				// Another updates that should only apply once on wasm
				await (new PositiveTS.Storage.Entity.Promotion()).updatePromotionIfNoVatStore(db);
				await PositiveTS.Storage.Entity.ItemGroupItem.updateItemGroupItemPricesIfNoVatStore(db);
				await PositiveTS.Service.MigvanItem.updateItemsByMigvan(db);
				await PositiveTS.Storage.Entity.EmployeeStore.loadFromLocalStorageIfNeeded(db);
			}

			let createVirtualTable = `DROP TABLE IF EXISTS itemSearch;
											CREATE VIRTUAL TABLE itemSearch USING fts3(barcode,code,color,size,description,priceZarhan, barcoderev, coderev);
											insert into itemSearch (barcode, code, color, size, description, priceZarhan, barcoderev, coderev) select itembarcode.barcode,itembarcode.code,color,size,item.description,item.priceZarhan, itembarcode.barcoderev, itembarcode.coderev
											from itembarcode left outer join item on item.code = itembarcode.code where item.cannotBeSoldSeparately = 0;`

			db.exec("BEGIN;" + createVirtualTable + "END;");


			return db;
		}
		export function importSuccessful() {
			window.localStorage.setItem(storage.LocalStorageStateKey, storage.StatusOK);
			window.localStorage.setItem(storage.LocalStorageStateMessageKey, '');
			window.localStorage.setItem(storage.LocalStorageTimestampKey, String(PositiveTS.DateUtils.timestamp()));

			return statsVC.storageObserver(true)
			// .then(function() {
			// 	return session.loadSessionDataFromStorage();
			// })

			// PositiveTS.NotificationCenter.postNotification(storage.DataHasChangedNotification, null);


		}
		export function importFailed(errors) {
			console.dir(errors);
			window.localStorage.setItem(storage.LocalStorageStateKey, storage.StatusInvalid);
			PositiveTS.NotificationCenter.postNotification(PositiveTS.Service.LoadRemoteDataJob.JobFailedNotification, {
				identifier: PositiveTS.Service.LoadRemoteDataJob.identifier,
				error: new Error(i18next.t("paramsLoadFaild") + ' (Failed to fetch data dump)')
			});

			statsVC.storageObserver(false);
			//PositiveTS.NotificationCenter.postNotification(storage.InsertFailedNotification, null);
		}
		/**
		 * Generates a UUID according to http://www.ietf.org/rfc/rfc4122.txt
		 */
		export function createUUID() {
			var s = [];
			var hexDigits = "0123456789ABCDEF";
			for (var i = 0; i < 32; i++) {
				s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
			}
			s[12] = "4";
			s[16] = hexDigits.substr((s[16] & 0x3) | 0x8, 1);

			var uuid = s.join("");
			return uuid;
		}
		export async function deleteAll() {
			await Storage.Entity.Pos.deletePosTables();
			await appDB.delete();
			localStorage.clear();
		}

		export function clearPhysicalId(isClearPhisicalId) {
			if (!isClearPhisicalId) { return Promise.resolve(); }

			var data = { devicePhysicalId: session.pos.physicalID };
			var INTERFACE_URL = "/clear_physical_id/";

			return PositiveTS.Service.Ajax.promiseJqAjax(INTERFACE_URL, data, "POST")
				.then(function (result) {
					if (result.request.result !== "true") { throw new Error("internal error") }
					localStorage.removeItem("json_config")


					session.pos.physicalID = null;
					return session.pos.persist();
				})
				.then(() => {
					let promises = [];
					promises.push(appDB.sequences.clear());
					promises.push(appDB.sales.clear());
					if (session.pos.isRoshemet) {
						promises.push(appDB.employeeTimeTracks.clear());
						promises.push(appDB.localItems.clear());
						promises.push(appDB.employees.clear());
					}
					//TODO: not sure about this section....

					// promises.push(appDB.localItems.clear());
					// promises.push(appDB.localItems.clear());

					return Promise.all(promises)
				})
				.then(() => {
					localStorage.clear();
				})
		}

	}
}
declare var storage: typeof PositiveTS.Storage;
storage = PositiveTS.Storage;
