module PositiveTS {
  export module Dialogs {
    export module PrinterDefault {
      export function selectPrintrer():Promise<string>{
        return printer.jzebra.getPrinterList()
        .then( (listOfPrinters)=>{
            OptionSelectUI.dialog('open');
            return _initTable(listOfPrinters);
        })
      }

      function _initTable(itemList:string[]):Promise<string>{

        $('#OptionSelect-dialog-continue').hide();

        var tableView = $('#OptionSelect-dialog-tableview').tableview();
        tableView.setNumberOfVisibleRows(6);

        tableView.empty();

        for (var i=0;i<itemList.length; i++){
          var theRow = tableView.addRow(posUtils.sanitizeTableViewRow([
                      itemList[i]
          ]));
          theRow.data('data', itemList[i]);
        }

        

        return new Promise( (resolve, reject) => {
          $("#OptionSelect-dialog-tableview .ui-tableview-row-cell").on('click',function(m){
            var selected =  $(m.target).closest('tr').data().data;
            OptionSelectUI.dialog('close');
            resolve(selected);
          });

          $('#OptionSelect-dialog-cancel').show().on('click', function() {
            OptionSelectUI.dialog('close');

            reject(false)
          });
        });




      }

    }
  }
}
