module PositiveTS {
  export module Components {
      export module CustomerSalesReportDialog {
  
        const dialogSelector = "customer-sales-report-dialog"
  
        function initData() {
          interface data {
            loading: boolean,
            dialogElem: HTMLDialogElement,
            customers: Array<object>,
            selectedCustomer: object,
            dateFormat: string,
            startDate: Date,
            endDate: Date,
            bonusPercent: number,
            urlPrefix: string
          }
          
          const data: data = {
            loading: false,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            customers: [],
            selectedCustomer: null,
            dateFormat: "dd/MM/yyyy",
            startDate: moment().toDate(),
            endDate: moment().toDate(),
            bonusPercent: 0,
            urlPrefix: '/customer_sales'
          }

          return data
        }
  
        export function create() {
  
          let component = {
            template: JST.customerSalesReportDialog(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            computed: {
              posCustomersReportByHakafaCustomers: () => jsonConfig.getVal(jsonConfig.KEYS.posCustomersReportByHakafaCustomers),
              dialogElement () {
                if (this.dialogElem == null) {
                  this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                }
                return this.dialogElem
              },
            },
            methods: {
              
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
              },

              async printReport () {
                try{
                  app.showLoadingMessage(i18next.t('customerSalesReportDialog.loadingReport'))
                  const res = await this.getReportFromServer()
                  if (_.isEmpty(res.result) || _.isEmpty(res.result.sales)){
                    app.showAlertDialog({
                      header: i18next.t('customerSalesReportDialog.title'),
                      content: i18next.t('customerSalesReportDialog.noFoundSales'),
                      continueButtonText: i18next.t("ok"),
                      hideCancelButton: true
                    }, null, null);
                    return
                  }
                  PositiveTS.Printing.Reports.printCustomerSalesReport(res.result.sales, 
                    {fromDate: this.startDate, toDate: this.endDate}, this.selectedCustomer, this.bonusPercent, this.posCustomersReportByHakafaCustomers)
                }catch(e){
                  console.error(e)
                  this.showGeneralError()
                }finally{
                  app.hideLoadingMessage()
                }             
              },

              async showReport () {
                this.$refs.showCustomerSalesReportDialog.open()
              },

              async getReportFromServer () {
                let fromDate = this.startDate.toJSON();
                let toDate = this.endDate.toJSON();
              
                let url = `${this.urlPrefix}/?from_date=${fromDate}&to_date=${toDate}&byHakafaCustomers=${Boolean(this.posCustomersReportByHakafaCustomers)}`
                if (this.selectedCustomer) {
                  url += `&customer_id=${this.selectedCustomer.id}`
                }
                
                return PositiveTS.Service.FetchReq.jsonReq(url, 'get')
              },

              close() {
                this.dialogElement.close()
                this.cleanData()
              },

              async open () {
                this.dialogElement.show()
                this.blurInputsOnElement(this.dialogElement)
              },

              showGeneralError () {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t('generalError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              },

              async getCustomers (search) {
                let data = []
                try{
                  let res = await PositiveTS.Service.FetchReq.jsonReq(`${this.urlPrefix}/get_customers/?byHakafaCustomers=${Boolean(this.posCustomersReportByHakafaCustomers)}&search=${search}&limit=25`, 'get')
                  if (res && res.result){
                    data = res.result.customers
                    data.forEach((customer) => {
                      customer.name = `${customer.first_name ? customer.first_name : ''} ${customer.last_name ? customer.last_name : ''} - ${customer.customer_number}` 
                    })
                  }
                }catch(e){
                  console.error(e)
                }
                
                return data;
              },

              onSearch(search, loading) {
                if(search.length) {
                  loading(true)
                  this.search(search, loading, this)
                }
              },

              async fetchCustomers (search, loading) {
                try{
                  let customers = await this.getCustomers(search)
                  this.customers = customers
                  loading(false)
                }catch(error){
                  console.error(error)
                }
              },
              
              resetCustomers () {
                this.customers = []
              },

              search: _.debounce((search, loading, vm) => vm.fetchCustomers(search, loading), 200)
            },

            data: initData,
          }
          
          VueApp.component('customer-sales-report-dialog',component)
        }
      }
    }
  }
