module PositiveTS {
    export module Components {
        export module SelectDalpakAreaModal {
            let compName = 'select-dalpak-area-modal'

            export function create() {
                let vueObj = {
                    template: JST.selectDalpakAreaModal(),
                    props: {
                        currentDalpakArea: Object,
                    },
                    methods: {
                        open() {
                            return new Promise((resolve, reject) => {
                                this.resolveFunc = resolve
                                this.rejectFunc = reject

                                this.showModal = !this.showModal
                            })
                        },
                        close() {
                            this.showModal = !this.showModal
                        },
                        confirmSelectedDalpakArea() {
                            if(posUtils.isBlank(this.selectedDalpakArea)) {
                                app.showAlertDialog({
                                    header: i18next.t('error'),
                                    content: i18next.t('selectDalpakAreaModal.noDalpakAreaSelectedMessage'),
                                    continueButtonText: i18next.t("ok"),
                                    hideCancelButton: true
                                  })
                            }
                            this.resolveFunc(JSON.parse(this.selectedDalpakArea).railsId)
                            this.close()
                        }
                    },
                    data() {
                        return {
                            showModal: false,
                            selectedDalpakArea: null,
                            rejectFunc: null,
                            resolveFunc: null
                        }
                    },
                    setup(){
                        const dalpaksStore = Pinia.useDalpaksStore();
                        const {allDalpakAreas} = Pinia.storeToRefs(dalpaksStore);
                        return {allDalpakAreas}
                    },
                    computed: {
                        dalpakAreaOptions() {
                            return this.allDalpakAreas.filter(da => da.railsId != this.currentDalpakArea.railsId)
                        }
                    }
                }

                VueApp.component(compName, vueObj)

            }
        }
    }
}