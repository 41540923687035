module PositiveTS {
export module Components {
    export module WeightDialog {
      const dialogSelector = "weight-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          noCustomersFound: false,
          weight: 0,
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
          dialogElem: document.getElementById(dialogSelector),
          fields: initFields()

        }
      }

      function initFields() {
        let fields = {
          'weight': {
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            type: 'number',
            disabled: false,
            model: 'weight'
          }
        }
        return fields;
      }

      function close() {
        if (this.initialized) {
          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.close();
        }
        this.cleanData()
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      function open(originalWeight = null) {
        return new Promise((resolve,reject) => {
          this.initialized = true;

          if (originalWeight != null) {
            this.weight = originalWeight
          }
          $(document).unbind('keypress');

          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.showModal();
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
        })
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        let aThis = this;
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.$refs.searchField.focus();
        },null)
      }

      function confirmAndClose() {
        if (Number(this.weight) === 0) {
          return;
        }
        this.resolveFunc({approved:true, weight: Number(this.weight)})
        this.close();
        
      }

      function cancelTapped() {
        this.resolveFunc({approved: false, weight: null})
        this.close();
      }

        function onKeyTapped(key) {
          this.keyPadHanlder(key,this.fields["weight"],this,3);
        }

      export function create() {

        let component = {
          template: JST.weightDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData: cleanData,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            cancelTapped: cancelTapped,
            onKeyTapped: onKeyTapped,
            showError: showError,
            showGenericError: showGenericError,            
          },
          data: initData,
          computed: {
            saleHasItems() {
              return  Pinia.globalStore.saleItems.length != 0;
            }
          }
        }

        VueApp.component('weight-dialog',component)

      }
    }
  }
}
