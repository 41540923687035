module PositiveTS {
export module Service {
export module MultipassDiscountItemService{

    export function addDiscount(discountAmount:number, doNotAddPrice = false):Promise<void>{
        if (discountAmount === 0 && !doNotAddPrice){
            return Promise.resolve();       
        }
    
        return _getdDiscountSaleitem().
        then( (saleitem)=>{
          
          saleitem.unitPrice -= discountAmount;  
          
          if (saleitem.unitPrice === 0) {
            return posVC.deleteSaleItem(saleitem)
          } else {
          return saleitem.persist();   
          }    
        })
        .then( ()=>{
          return posVC.saleUpdated();
        });
          
      }
    
      export function isMultipassDiscountItemExists(saleItems:Storage.Entity.SaleItem[]){
        var itemCode = jsonConfig.getVal(jsonConfig.KEYS.multipassDiscountItemCode)
        for (let saleItem of saleItems){
          if (saleItem.itemCode === itemCode) {
            return true
          }
        }
        return false;
      }
    
      function _getdDiscountSaleitem():Promise<PositiveTS.Storage.Entity.SaleItem>{
        var itemCode = jsonConfig.getVal(jsonConfig.KEYS.multipassDiscountItemCode)
        for (let i=0; i< posVC.saleItems.length;i++){
          if (posVC.saleItems[i].itemCode === itemCode) {
            return Promise.resolve(posVC.saleItems[i])
          }
        }
    
        var item = new PositiveTS.Storage.Entity.Item();
        return item.searchByBarcode(itemCode)
        .then( (itemsAndBarcodes) => {
    
          if (itemsAndBarcodes.length !== 1){
            throw new Error(`Bad POS configuration, no itemCode ${itemCode}`)
          }
    
          // Pick the first item and get the item and its barcode
          var item:PositiveTS.Storage.Entity.Item = itemsAndBarcodes[0].item;
          var itemBarcode = itemsAndBarcodes[0].itemBarcode;
    
          // Create new sale item entity
          var saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, itemBarcode);
          saleItem.saleID = posVC.sale.id;
          saleItem.rowNumber = posVC.getRowNumber();
          saleItem.quantity = 1;
          return posVC.persistNewSaleItem(saleItem)
          .then( ()=>{
            return saleItem;
          });
    
        });
      }
    
}}}