module PositiveTS {
export module Service {
export module CreditCard {
			
			
	function _validateDebit(amount,card_number,payment_type,cvv,payments_count,
													valid_until,phone_number,creditCurrency,confNumber, tz) {
		if (amount === null || amount === undefined) {
			return 'amount undefined';
		}
		
		if ( creditCurrency === 'USD' && parseInt(payments_count) > 1 ) {
			return 'USD with payments_count greater then 1';
		}
		
		if (card_number === null || card_number === undefined) {
			return 'card_number undefined';
		}
		if (payment_type === null || payment_type === undefined) {
			return 'payment_type undefined';
		}
		if (cvv === null || cvv === undefined) {
			return 'cvv undefined';
		}
		if (payments_count === null || payments_count === undefined) {
			return 'payments_count undefined';
		}
		if (valid_until === null || valid_until === undefined) {
			return 'valid_until undefined';
		}
		if (phone_number === null || phone_number === undefined) {
			return 'phone_number undefined';
		}
		
		return null;
	}

	export function payWithCreditCard(amount,card_number,payment_type,cvv,payments_count,
		valid_until,phone_number,creditCurrency,confNumber, tz):Promise<any> {
		
		let error = _validateDebit(amount,card_number,payment_type,cvv,payments_count,valid_until,phone_number,creditCurrency,confNumber,tz)
		if (error != null) {
			return Promise.reject(error);
		}
		amount = session.fixedFloatTrunc(amount);
		
		return new Promise((resolve,reject) => {
			var _dictToPost = {
				'CreditCard': {
					'amount': amount,
					'card_number': card_number,
					'payment_type': payment_type,
					'cvv': cvv,
					'payments_count': payments_count,
					'valid_until': valid_until,
					'phone_number': phone_number,
					'creditCurrency': creditCurrency,
					'confNumber': confNumber,
					'tz': tz
				},
				'store': {
					'credit_card_vendor': localStorage.getItem('credit_card_vendor')
				}
			};
			
			
			PositiveTS.Service.CreditDal.promiseTransmitDebitDictionary( _dictToPost )
			.then(function(return_hash){
				return_hash = $.extend(return_hash,
					PositiveTS.Service.CreditDal._formatFirstPaymentAndFixedAmount(
						PositiveTS.Service.CreditDal._getCreditAmountFormat(return_hash.amount),
						return_hash.payments_count)
					)
					resolve(return_hash);
				});
				
		});
			
	}
		
	export function creditCreditCardPayment(credit_card_token, amount, credit_type, confirmation_number, payments_count, valid_until,
																					phone_number, tz, creditCurrency, successCallback, errorCallback) {
		var callback = new PositiveTS.Callback(1, successCallback, errorCallback);
		// Data validations
		if (credit_card_token === null || credit_card_token === undefined) {
			callback.error('credit_card_token undefined');
			return false;
		}
		
		if (credit_type === null || credit_type === undefined) {
			callback.error('credit_type undefined');
			return false;
		}
		
		if (amount === null || amount === undefined) {
			callback.error('amount undefined');
			return false;
		}
		amount = session.fixedFloatTrunc(amount);
		
		if (confirmation_number === null || confirmation_number === undefined) {
			callback.error('confirmation_number undefined');
			return false;
		}
		if (payments_count === null || payments_count === undefined) {
			callback.error('payments_count undefined');
			return false;
		}
		if (valid_until === null || valid_until === undefined) {
			var today = new Date();
			var m = today.getMonth() + 1;
			if (m == 12) {
				m = 1;
			} else {
				m += 1;
			}
			var y = today.getFullYear().toString().slice(2, 4);
			valid_until = m + '/' + y;
		}
		if (phone_number === null || phone_number === undefined) {
			callback.error('phone_number undefined');
			return false;
		}
			
			
		// Are we supporting credit200 addon?
		var c = PositiveTS.Service.CreditDal;
		
		var _dictToPost = {
			'CreditCard': {
				'amount': amount,
				'payment_type': credit_type,
				'payments_count': payments_count,
				'valid_until': valid_until,
				'phone_number': phone_number,
				'card_number': credit_card_token,
				'confNumber' :confirmation_number,
				'creditCurrency': creditCurrency,
				'tz': tz
				
			},
			'store': {
				'credit_card_vendor': localStorage.getItem('credit_card_vendor')
			}
		};
		c.promiseTransmitCreditDictionary( _dictToPost )
		.then(function(returnedData){
			var CALL_CREDIT_CARD_COMPANY = 3;
			if (! returnedData.success && parseInt(returnedData.return_code) === CALL_CREDIT_CARD_COMPANY)
			{
				return _promptForConfirmationNumberAndRetryTransmit(_dictToPost, returnedData)
				.then(function(returnedData){
					if (! returnedData.success) {
						callback.error(returnedData.error_message);
						return false;
					} else {
						callback.successWithObject(returnedData);
					}
				});
				
				
			}
			else {
				callback.successWithObject(returnedData);
			}
			
		});
	};
			
			
	
	export function updateSvaIfErr025AndNoTranExists(returnedData) {
		return new Promise((resolve,reject) => {
			if ( returnedData.return_code == "025" || returnedData.return_code == "032") {
				serviceShva.promiseIS_TRAN_EXISTS()
				.then((isTranExists) => {
					app.hideLoadingMessage();
					if (isTranExists) {
						app.promiseShowAlert({
							header: "",
							content: i18next.t('svaErr25TranExists'),
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true
						})
						.then((response)=>{
							reject(app.userCancelledGlobalMessage)
						});
					} else {
						app.promiseShowAlert({
							header: "",
							content: i18next.t('svaErr25TranNotExists'),
							continueButtonText: i18next.t("ok"),
							hideCancelButton: true
						})
						.then((response)=>{
							let dict = {rval:true,maxZ:(new Date().getTime() * -1),"payments":[],"isForced":true}
							serviceShva.promiseTRANS(  dict)
							.then(function(response){
								if (response.request.status == "200"){
									if (response.request.result.startsWith("ERROR")) {
										reject(new Error( response.request.result ));
									}
									else {
										resolve(returnedData);
									}
								}
								else {
									reject(new Error(response.request.result));
								}
							})
						});
					}
				});
			}
			else {
				resolve(returnedData)	
			}
		})
		
	}
			
	/*
	* If chargable card effective balance exists,
	* 	return min(cardbalance, ammount)
	* If no chargable card return amount
	*/
	export function promiseGetEffectiveBalance(card_number, amount){
		// Data validations
		if (card_number === null || card_number === undefined) {
			return Promise.reject('card_number undefined');
		}
		
		var _fReturn =  amount;
		var c = PositiveTS.Service.CreditDal;
		
		var _dictToPost = {
			'CreditCard': {
				'amount': 0,
				'payment_type': 1,
				'payments_count': 1,
				'valid_until': '',
				'phone_number': 'phone_number',
				'card_number': card_number,
				'confirmationNumber' : 0
			},
			'store': {
				'credit_card_vendor': localStorage.getItem('credit_card_vendor')
			}
		};
		
		
		function _GetBalance(){
			return c.promiseTransmitConfirmDictionary( _dictToPost)
			.then(function(returnedData){
				var returnedAmount  = parseFloat( returnedData.BalanceOut ) / 100;
				if(returnedData.success === true && returnedAmount > 0 && returnedAmount < _fReturn){
					_fReturn = session.fixedFloatTrunc( returnedAmount );
				}
				return (_fReturn);
			});
		}
		
		// Submit request, isConfirmed = true
		return c.promiseTransmitJ2Dictionary(_dictToPost)
		.then( function(returnedData){
			app.showLoadingMessage('פותח תקשורת מול חברת האשראי, אנא המתן');
			if (returnedData && returnedData.isChargableCard) {
				return true;
			} else {
				return false;
			}
		})
		.then(function(isRechargableCard){
			if (isRechargableCard) {
				return _GetBalance();
			} else {
				// Card not chargable, no balance check
				return (_fReturn);
			}
		});
		
		
	};
	
	// REQUEST ISING ADDON
	export function checkBalance(card_number) {
		
		return new Promise((resolve,reject) => {
			// Data validations
			if (card_number === null || card_number === undefined) {
				return reject('card_number undefined');
			}

			
			let c = PositiveTS.Service.CreditDal;
			// Are we supporting credit200 addon?
			let _dictToPost = {
				'CreditCard': {
					'amount': 0,
					'payment_type': 1,
					'payments_count': 1,
					'valid_until': '',
					'phone_number': 'phone_number',
					'card_number': card_number,
					'confirmationNumber' : 0
				},
				'store': {
					'credit_card_vendor': localStorage.getItem('credit_card_vendor')
				}
			};

			// Submit request, isConfirmed = true
			c.promiseTransmitConfirmDictionary( _dictToPost)
			.then(function(returnedData){
				app.hideLoadingMessage();
				if(returnedData.success === true){
					app.showAlert({
						header: i18next.t('checkBalance'),
						content: i18next.t('voucherBalanceIs', {AMOUNT: (parseFloat(returnedData.BalanceOut) / 100)}),
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					});
					resolve(returnedData);
				}
				else{
					app.showAlert({
						header: i18next.t('checkBalance'),
						content: returnedData.error_message,
						continueButtonText: i18next.t("ok"),
						hideCancelButton: true
					});
					reject(returnedData.error_message);
				}
				
			});

		})		
	}
			
	function _promptForConfirmationNumberAndRetryTransmit(_dictToPost, oldReturnedData) {
		var c = PositiveTS.Service.CreditDal;
		
		var input = new PositiveTS.Dialogs.InputDialog();
		var options = new PositiveTS.Dialogs.InputDialogOptions();
		options.header = "זיכוי אשראי נכשל, אם ברשותך מספר אישור הזן ולחץ אישור לנסיון חוזר.";
		return input.open(options)
		.then(function(newConfirmationNumber){
			_dictToPost.CreditCard.confNumber = newConfirmationNumber;
			return c.promiseTransmitCreditDictionary( _dictToPost );
		})
		.catch(function(){
			return oldReturnedData;
		});
		
	};
}}}
						