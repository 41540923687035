module PositiveTS {
export module Storage {
export module Entity {
export class Pos extends IDBEntity {

	static REQUIRE_MANDATORY = 1;
	static REQUIRE_OPTIONAL = 2;
	static REQUIRE_DISABLED = 3;

	static FIELD_MANUAL = 1;
	static FIELD_AUTOMATIC = 2;

	static ENFORCE_PREVENT = 1;
	static ENFORCE_WARN = 2;
	static ENFORCE_DISABLED = 3;

	static ENFORCE_LEVEL_ITEM = 1;
	static ENFORCE_LEVEL_SIZE = 2;

	posNumber:number
	tenantID: string
	tenantName:string
	companyID:string
	companyName:string
	checkCompanyName:string
	storeID:string
	storeName:string
	physicalID:string
	deviceID:string
	isLoggedIn:boolean
	loginUsername:string
	loginPassword:string
	access_token:string
	paramsetID:number
	parameterDecimalPrecision:number
	parameterRequireSalesperson:number
	parameterRequireCustomer:number
	parameterPreformaSuffix:string
	parameterDebitInvoiceSuffix:string
	parameterCreditInvoiceSuffix:string
	parameterCreditVoucherSuffix:string
	parameterPositiveRcptSuffix:string
	parameterPayPunchCardSuffix:string
	parameterInvoiceType:number
	parameterEnforceInventory:number
	parameterEnforceInventoryLevel:number
	parameterMaxCheckDays:number
	parameterMaxCreditVoucherDays:number
	parameterMaxCreditCardPayments:number
	parameterAllowTypingCreditCard:boolean
	parameterAllowOfflineCreditCard:boolean
	parameterOfflineCreditCardLimit:number
	parameterAllowChangeItemPrice:boolean
	parameterSelectAllowChangeItemPrice:number
	parameterSpecialItemBlockVouchers:boolean
	parameterSpecialItemBlockCreditVouchers:boolean
	parameterBlockItemInMinusWithMultipleSalespersons:boolean
	parameterMaxChangeInCash:number
	parameterAllowNegativeSale:boolean
	parameterAllowNegativeQty:boolean
	parameterHideCreditCardConfirmationNumber:boolean
	parameterMinimumChangeInCreditVoucher:number
	parameterEmployeeMagneticCardLength:number
	parameterIsAutomaticEmployeeStoreConnection:boolean
	parameterManagerApprovalChangeItemPrice:boolean
	parameterManagerApprovalCreditInvoice:boolean
	parameterManagerApprovalZXReport:boolean
	parameterManagerApprovalReplacementSlip:boolean
	printerType:string
	jsonConfig:string
	employeeID:string
	employeeName:string
	salesPersonID:string
	salesPersonName:string
	praxellCode:string
	maxInvoiceReturnDays:string
	maxOneCheckDays:number
	ontrustVoucherId:number
	usePinPad:boolean
	private separateItemLines:boolean
	parameterPrintLogo:boolean
	parameterNoCheckDetails:boolean
	noBarcodeMode:boolean
	storeSignature:boolean
	backofficeIsControl:boolean
	isShowCombinedXReport: boolean
	onDemandCatalog:boolean
	shouldPrintBon: boolean
    openDrawerCode:string
	emvTerminalNumber:string
	isManagerApprovalReports:boolean
	onlyPrintBonAfterPayment:boolean
	tenbisUserName:string
	tenbisUserPass:string
	tenbisResId:string 
	banxUserName:string
	banxUserPass:string
	interServiceUserName:string
	interServicePass:string
	interServiceBranchCode:string
	mishlohaToken:string
	sandiBasicAuthUsername:string
	sandiBasicAuthPassword:string
	creditguardAuthUsername:string
	creditguardAuthPassword:string
	sandiUrl:string
	elalUrlGetPNR:string
	elalUrlUpdateTMI:string
	creditGuardUrl:string
	elalUrlGetEKT:string
	simplyClubUrl:string
	ototDefaultUrl: string
	nirDavidCredentials:string
	paiditResId:string
	migvanId:number
	migvanDeliveryId:number
	migvanTaId:number
	migvanItemsId:number
	valuePosId:string
	valuePosPassword:string
	useNewPromotions:boolean
	valueCashiersPassword:string
	isRoshemet:boolean
	useMinPriceForNegItem:boolean
	isGoodiTaxInvoice:boolean
	isCaveret:boolean
	caveretCibusCompanies: string
	companyWorksWithMultiLanguage: boolean
	companySupportedLanguages: string
	storeSelfServiceOpeningHours:string
	midragPayments:string
	askForMemberClubRenew:boolean
	isElal:boolean
	systemId:string
	passwordProtected: boolean
	isRestartRequired:boolean //not persisted
	baseCurrency:string
	supportPhone:string
	customerClubNumberRequired:boolean
	gainUponOnRenewMemberSale:boolean
	onlineOnlyMemberClub:boolean
	hasFlights:boolean
	companyUsePriorityApi:number
	priorityUserData:string
	passwords:string
	yaadSarigPassp:string
	hasInternetStore:boolean
	singleStorePos:boolean
	tipItemCode:string
	worksWithCustomerSaleItemLimit: boolean
	hasStoreMigvans: boolean
	brillConfig: string
	companyUsesSaleDataJson: boolean
	hasUpsales: boolean
	companyAllowGainCustomerClubPointsPaymentVouchers: boolean
	defaultImages: string	
	defaultMenuId: string
	hasRemoteZ: boolean
	isAutoEmployeeSignOutRemoteZ: boolean
	isAddTextDigitalInvoice: boolean
	usePictures:boolean
	customerDisplayOnSaleImgUrl: string
	customerDisplayIdleModeImgUrls: string
	isAutoStore: boolean
	autoStoreConfig: string
	useVendingMachines: boolean
	isVendingMachinePos: boolean
	vendingMachineRailsId: number
	taxAuthorityInvoiceAllocationAmount: number
	welcomeScreen: string
	suspiciousActivityAlertsData: string
	

	get isEmv():boolean {
		return !(posUtils.isNullOrUndefinedOrEmptyString(this.emvTerminalNumber))
	}

	get separateItemLinesOn():boolean {
		return Boolean(jsonConfig.getVal(jsonConfig.KEYS.separateAllLinesAlways)) || this.separateItemLines;
	}

	get isILSBasedCurrency() {
		return Service.MultiCurr.getInstance().getPosCurrency() == PositiveTS.Storage.Entity.CurrencieCodes.ILS;
	}

	get caveretCibusCompaniesArr(): Array<{code: string, name: string}> {
		return JSON.parse(this.caveretCibusCompanies || "[]");
	}

	get companySupportedLanguagesArr(): Array<string> {
		return JSON.parse(this.companySupportedLanguages || "[]");
	}

	get customerDisplayIdleModeImgUrlArr(): Array<string> {
		return JSON.parse(this.customerDisplayIdleModeImgUrls || "[]");
	}

	get autoStoreType(): string {
		return this.autoStoreConfigObj.type || "";
	}

	get autoStoreConfigObj(): any {
		return JSON.parse(this.autoStoreConfig || "{}");
	}

	get welcomeScreenObj(): any {
		return JSON.parse(this.welcomeScreen || "{}");
	}
	
	get suspiciousActivityAlertsDataObj(): any {
		return JSON.parse(this.suspiciousActivityAlertsData || "{}");
	}

	get hasAnyPrinterSet() {
		return this.printerType !== 'none';
	}

	getPassword(passwordKey: string) {
		if (!posUtils.isBlank(this.passwords)) {
			let passwords = JSON.parse(session.pos.passwords);
  
			if (!posUtils.isBlank(passwords[passwordKey])) {
			  return passwords[passwordKey];
			}
		  }
  
		  return null;
	}

	constructor() {
		let meta = {
				name: 'poses',
				internal: true,
				fields: {
					posNumber: "INT",
					tenantID: "TEXT",
					tenantName: "TEXT",
					companyID: "TEXT",
					companyName: "TEXT",
					checkCompanyName: "TEXT",
					storeID: "TEXT",
					storeName: "TEXT",
					physicalID: "TEXT",
					deviceID: "TEXT",
					isLoggedIn: "BOOL",
					loginUsername: "TEXT",
					loginPassword: "TEXT",
					access_token: "TEXT",
					paramsetID: "INT",
					parameterDecimalPrecision: "INT",
					parameterRequireSalesperson: "INT",
					parameterRequireCustomer: "INT",
					parameterPreformaSuffix: "TEXT",
					parameterDebitInvoiceSuffix: "TEXT",
					parameterCreditInvoiceSuffix: "TEXT",
					parameterCreditVoucherSuffix: "TEXT",
					parameterPositiveRcptSuffix: "TEXT",
					parameterPayPunchCardSuffix: "TEXT",
					parameterInvoiceType: "INT",
					parameterEnforceInventory: "INT",
					parameterEnforceInventoryLevel: "INT",
					parameterMaxCheckDays: "INT",
					parameterMaxCreditVoucherDays: "INT",
					parameterMaxCreditCardPayments: "INT",
					parameterAllowTypingCreditCard: "BOOL",
					parameterAllowOfflineCreditCard: "BOOL",
					parameterOfflineCreditCardLimit: "INT",
					parameterAllowChangeItemPrice: "BOOL",
					parameterSelectAllowChangeItemPrice: "INT",
					parameterSpecialItemBlockVouchers: "BOOL",
					parameterSpecialItemBlockCreditVouchers: "BOOL",
					parameterBlockItemInMinusWithMultipleSalespersons: "BOOL",
					parameterMaxChangeInCash: "INT",
					parameterAllowNegativeSale: "BOOL",
					parameterAllowNegativeQty: "BOOL",
					parameterHideCreditCardConfirmationNumber: "BOOL",
					parameterMinimumChangeInCreditVoucher: "INT",
					parameterEmployeeMagneticCardLength: "INT",
					parameterIsAutomaticEmployeeStoreConnection: "BOOL",
					parameterManagerApprovalChangeItemPrice: "BOOL",
					parameterManagerApprovalCreditInvoice: "BOOL",
					parameterManagerApprovalZXReport: "BOOL",
					parameterManagerApprovalReplacementSlip: "BOOL",
					printerType: "TEXT",
					jsonConfig: "TEXT",
					employeeID: "TEXT",
					employeeName: "TEXT",
					salesPersonID: "TEXT",
					salesPersonName: "TEXT",
					praxellCode: "TEXT",
					maxInvoiceReturnDays: "TEXT",
					maxOneCheckDays: "INT",
					ontrustVoucherId: "INT",
					usePinPad: "BOOL",
					separateItemLines: "BOOL",
					parameterPrintLogo: "BOOL",
					parameterNoCheckDetails: "BOOL",
					noBarcodeMode: "BOOL",
					storeSignature: "BOOL",
					backofficeIsControl: "BOOL",
					isShowCombinedXReport: "BOOL",
					onDemandCatalog: "BOOL",
					shouldPrintBon:  "BOOL",
					openDrawerCode: "TEXT",
					emvTerminalNumber: "TEXT",
					isManagerApprovalReports: "BOOL",
					onlyPrintBonAfterPayment: "BOOL",
					tenbisUserName: "TEXT",
					tenbisUserPass: "TEXT",
					tenbisResId: "TEXT",
					banxUserName: "TEXT",
					banxUserPass: "TEXT",
					interServiceUserName: "TEXT",
					interServicePass: "TEXT",
					interServiceBranchCode: "TEXT",
					mishlohaToken: "TEXT",
					sandiBasicAuthUsername: "TEXT",
					sandiBasicAuthPassword: "TEXT",
					creditguardAuthUsername:"TEXT",
					creditguardAuthPassword:"TEXT",
					sandiUrl: "TEXT",
					elalUrlGetPNR: "TEXT",
					elalUrlUpdateTMI: "TEXT",
					creditGuardUrl: "TEXT",
					elalUrlGetEKT: "TEXT",
					ototDefaultUrl: "TEXT",
					simplyClubUrl: "TEXT",
					nirDavidCredentials: "TEXT",
					paiditResId: "TEXT",
					migvanId: "INT",
					migvanDeliveryId: "INT",
					migvanTaId: "INT",
					migvanItemsId: "INT",
					valuePosId: "TEXT",
					valuePosPassword: "TEXT",
					valueCashiersPassword: "TEXT",
					isRoshemet: "BOOL",
					useMinPriceForNegItem: "BOOL",
					isGoodiTaxInvoice: "BOOL",
					isCaveret: "BOOL",
					companyWorksWithMultiLanguage: "BOOL",
					caveretCibusCompanies: "JSON",
					companySupportedLanguages: "JSON",
					storeSelfServiceOpeningHours: "JSON",
					midragPayments: "JSON",
					askForMemberClubRenew: "BOOL",
					isElal: "BOOL",
					systemId:"TEXT",
					passwordProtected: "BOOL",
					useNewPromotions: "BOOL",
					baseCurrency: "TEXT",
					supportPhone: "TEXT",
					customerClubNumberRequired: "BOOL",
					gainUponOnRenewMemberSale: "BOOL",
					onlineOnlyMemberClub: "BOOL",
					hasFlights: "BOOL",
					companyUsePriorityApi: "INT",
					priorityUserData: "TEXT",
					passwords: "TEXT",
					yaadSarigPassp: "TEXT",
					hasInternetStore: "BOOL",
					singleStorePos: "BOOL",
					tipItemCode: "TEXT",
					worksWithCustomerSaleItemLimit: "BOOL",
					hasStoreMigvans: "BOOL",
					brillConfig: "TEXT",
					companyUsesSaleDataJson: "BOOL",
					hasUpsales: "BOOL",
					companyAllowGainCustomerClubPointsPaymentVouchers: "BOOL",
					defaultImages: "TEXT",
					defaultMenuId: "TEXT",
					hasRemoteZ: "BOOL",
					isAutoEmployeeSignOutRemoteZ: "BOOL",
					isAddTextDigitalInvoice:"BOOL",
					usePictures:"BOOL",
					customerDisplayOnSaleImgUrl: "TEXT",
					customerDisplayIdleModeImgUrls: "JSON",
					isAutoStore: "BOOL",
					autoStoreConfig: "JSON",
					useVendingMachines: "BOOL",
					isVendingMachinePos: "BOOL",
					vendingMachineRailsId: "INT",
					taxAuthorityInvoiceAllocationAmount: "INT",
					welcomeScreen: "JSON",
					suspiciousActivityAlertsData: "JSON",
				},
				unique: ['tenantID','companyID','storeID','physicalID']
			}
		super(meta)
	}

	async all(param?) {
		let result = await super.all();
		
		// For backward compatibillity
		if (result.length == 0) {
			try {
				let dbResult;
				let db = Service.WebDB.getDbIfSupported();

				if (db && await Service.WebDB.tableExists('Pos', db)) {
					dbResult = await PositiveTS.Service.WebDB.promiseSql('select * from Pos', db);

					if (dbResult && dbResult.rows.length > 0) {
						let pos = dbResult.rows[0];
						await appDB.poses.put(pos);
						return await super.all();
					}
				}
			} catch (err) {
				// If webdb not exists
				Service.Logger.error(err);
			}
		}

		return result;
	}

	async persist(tx?: any): Promise<void> {
		await appDB.poses.put(this);
	}

	static async deletePosTables() {
		appDB.poses.clear();

		let db = Service.WebDB.getDbIfSupported();
		if (db && await Service.WebDB.tableExists('Pos', db)) {
			await PositiveTS.Service.WebDB.promiseSql('DROP TABLE Pos', db);
		}
	}

}
}}}
