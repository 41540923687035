class PromotionsSelectorDialog {
    private ui:any;
    private promotionsTable:any;
    constructor() {
        //init the jquery dialog
        $(() => {
            this.ui = $( "#promotions-selector-dialog" ).dialog({
                autoOpen: false,
                modal: true,
                dialogClass: 'positive-dialog',
                width: '50%',
                height: 'auto',
                resizable: false,
                closeOnEscape: false,
                draggable: false
            });
        });
    }
	close() {
		$('#promotions-selector-dialog-cancel').unbind('click');
        $('#promotions-selector-dialog-continue').unbind('click');
        this.promotionsTable.removeSelectRowListener('promotionBarcode');
        posVC.initializeKeyboard();
        this.ui.dialog('close');
	}

	open(promotions, selectedPromotionsCodes) {

        // Set translation
        $( "#promotions-selector-dialog" ).dialog( "option", "title", i18next.t("pos.offerSelection") );
        $('#promotions-selector-dialog-tableview').attr("data-header", (i18next.t("pos.selectOffer")));
        $('#promotions-selector-dialog-tableview').attr("data-empty-message", (i18next.t("pos.noOffers")));
        $('.promotions-selector-dialog-description').text(i18next.t("pos.offerSelectionLong"));
        $('#promotions-selector-dialog-cancel-label').text(i18next.t("pos.cancel"));
        $('#promotions-selector-dialog-continue-label').text(i18next.t("pos.continue"));   

        $(document).unbind('keypress');

		var deferred = Promise.defer();

        if (posUtils.isNullOrUndefinedOrEmptyString(selectedPromotionsCodes)) {
            selectedPromotionsCodes = [];
        }

        this.promotionsTable = $('#promotions-selector-dialog-tableview').tableview({
            isMultiSelection: true
        });

        this.promotionsTable.setNumberOfVisibleRows(7);

        this.promotionsTable.empty();

        $('#promotions-selector-dialog-continue').click(function () {

            promotionsSelectorDg.close();

            var selectedPromotions = promotionsSelectorDg.getSelectedPromotions();

            deferred.resolve(selectedPromotions);
        });

        $('#promotions-selector-dialog-cancel').click(() => {
            this.close();
            deferred.reject();
        });

        this.ui.dialog('open');

        promotionsSelectorDg.loadPromotionsToView(promotions, selectedPromotionsCodes);

        this.promotionsTable.attachSelectRowListener('promotionBarcode',(row) => this.handlePromotionWithBarcode(row));

        return deferred.promise;
	}

    private getSelectedPromotions() {
        var promotionsTable = $('#promotions-selector-dialog-tableview').tableview();

        var selectedRow = promotionsTable.getSelectedRow();

		if (selectedRow.length === 0) {
			return [];
		} else {
            var selectedPromotions = [];
            for (var i = 0; i < selectedRow.length; i++) {
                var row = selectedRow[i];
                selectedPromotions.push($(row).data('promotion'));
            };
			return selectedPromotions;
		}
    }

    private loadPromotionsToView(promotions, selectedPromotionsCodes) {
        var promotionsTable = $('#promotions-selector-dialog-tableview').tableview();
        promotionsTable.empty();

        for (var i = 0; i < promotions.length; i++) {
            var row = promotionsTable.addRow(posUtils.sanitizeTableViewRow([promotions[i].name]));

            row.data('promotion', promotions[i]);

            if (selectedPromotionsCodes.includes(promotions[i].code)) {
                promotionsTable.selectRow(row.attr('id'), true);
            }
        };
    }

    private handlePromotionWithBarcode(row) {
        if (!this.isRowSelected(row)) {
            return;
        }

        let promotion = row.data().promotion;
        const hasBarcode = Boolean(promotion.hasBarcode);

        if (!hasBarcode) {
            return;
        }

        if (!PositiveTS.Reachability.isOnline) {
            app.showAlert({content: 'לא ניתן לבחור מבצע זה בנתק',hideCancelButton: true});
            this.deselectRow(row);
            return
        }

        let promoWithBarcode = new PositiveTS.Promotions.PromotionWithBarcode();

        promoWithBarcode.process(promotion.code)
        .then((result) => {
            if (!result.success) {
                if (!result.userCanceled) {
                    app.showAlert({header: 'שגיאה',content: result.error,hideCancelButton: true});
                }
                this.deselectRow(row);
            }
            else {
                promotion.promotionBarcode = result.promotionBarcode; //save the selected barcode on the promotion;
                row.data('promotion',promotion);
            }
        });
    }

    private deselectRow(row) {
        $(row).removeClass('selected');
    }

    private isRowSelected(row):boolean {
        return $(row).hasClass('selected');
    }
};

declare var promotionsSelectorDg:PromotionsSelectorDialog;
promotionsSelectorDg = new PromotionsSelectorDialog();
