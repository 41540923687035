module PositiveTS {
    export module Service {
        export class YaadPayment extends SmartVoucher {

            getBalance(cardNumber: string, cvv?: string): Promise<GetBalanceResponse> {
                throw new Error("Method not implemented.");
            }
            async pay(voucherData: any, amount: number, cvv?: string, companyId?: any, additionalData?: any): Promise<any> {
                let errorMsg = ""
                const response = await Service.YaadService.CommitTransaction(voucherData.barCode)

                if (response.success) {
                    voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_YAADPAYMENT
                    voucherData.data = JSON.stringify(response);
                    voucherData.orderData = additionalData.orderData
                    voucherData.createdAt = new Date()
                    voucherData.allowPartialReturn = true
                } else {
                    if (response.CCode == "250") {
                        errorMsg = `250 - ${i18next.t('yaadPayment.dealDoesntExists')}`
                    }
                    else {
                        errorMsg = response.CCode
                    }
                }

                let responseObj: ActionResponse = {
                    success: response.success,
                    error: errorMsg,
                    rawResponse: response,
                    voucher: voucherData
                }
                return responseObj;
            }
            async cancelPayment(voucherToCancel: any, doRemove?: boolean): Promise<ActionResponse> {
                let errorMsg = i18next.t('yaadPayment.cancelError')
                let response;
                const now = moment()
                const endOfDay = moment(voucherToCancel.createdAt).endOf('day').subtract(30, "minutes")

                let isCancel = false    //determine if voucher will be refunded or cancelled

                if (now.isBefore(endOfDay)) {
                    const orderData = voucherToCancel.orderData
                    const urlParams = new URLSearchParams(orderData);
                    const totalAmount = Number(urlParams.get('Amount'))
                    const minusMulti = doRemove ? 1 : -1
                    if ((voucherToCancel.amount * minusMulti) == totalAmount) {
                        isCancel = true
                    }
                }


                if (isCancel) {
                    response = await Service.YaadService.cancelTransaction(voucherToCancel.barCode)
                }
                else {
                    response = await Service.YaadService.refundTransaction(voucherToCancel.barCode, voucherToCancel.amount * -1)
                }

                if (!response.success) {
                    if (response.CCode == "920") {
                        errorMsg = `\n920 - ${i18next.t('yaadPayment.dealDoesntExists')}`
                    }
                    else {
                        errorMsg += '\n' + response.CCode
                    }
                }


                let responseObj: ActionResponse = {
                    success: response.success,
                    error: errorMsg,
                    rawResponse: response,
                    voucher: voucherToCancel
                }
                return responseObj;
            }
            cancelLoad(paymentToCancel: any): Promise<any> {
                throw new Error("Method not implemented.");
            }
            loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
                throw new Error("Method not implemented.");
            }
            getCardNumber(): Promise<GetCardNumberResponse> {
                throw new Error("Method not implemented.");
            }

        }
    }
}