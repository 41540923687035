module PositiveTS {
    export module Service {
        export module AndroidNativeMessage {
            export module ExternalService {

                function replaceAll(str, find, replace) {
                    return str.replace(new RegExp(find, 'g'), replace);
                }

                function hasJsonStructure(str) {
                    if (typeof str !== 'string') return false;
                    try {
                        const result = JSON.parse(str);
                        const type = Object.prototype.toString.call(result);
                        return type === '[object Object]'
                            || type === '[object Array]';
                    } catch (err) {
                        return false;
                    }
                }

                function nativeType(value) {
                    var nValue = Number(value);
                    if (!isNaN(nValue)) {
                        return nValue;
                    }
                    var bValue = value.toLowerCase();
                    if (bValue === 'true') {
                        return true;
                    } else if (bValue === 'false') {
                        return false;
                    }
                    return value;
                }




                function backwardCompatibilityParser(response) {

                    var removeJsonTextAttribute = function (value, parentElement) {
                        try {
                            var keyNo = Object.keys(parentElement._parent).length;
                            var keyName = Object.keys(parentElement._parent)[keyNo - 1];
                            parentElement._parent[keyName] = nativeType(value);
                        } catch (e) { }
                    }

                    var options = {
                        compact: true,
                        trim: true,
                        ignoreDeclaration: true,
                        ignoreInstruction: true,
                        ignoreAttributes: true,
                        ignoreComment: true,
                        ignoreCdata: true,
                        ignoreDoctype: true,
                        textFn: removeJsonTextAttribute,
                        alwaysArray: ['Transactions', 'Errors', 'OrderLineElement', 'dishList', 'Choices', 'PaymentInfo', 'BillingLines', 'SubsChosen', 'DishList']
                    };

                    let result = response;
                    if(!response.includes("soap")){
                        //Tenbis extra santiation to fit windows addon response
                        response = response.replace("<MoneycardTransactionElementDTO>", "").replace("</MoneycardTransactionElementDTO>", "");
                        response = replaceAll(response, "<a:", "<");
                        response = replaceAll(response, "</a:", "</");
                        response = replaceAll(response, "<b:", "<");
                        response = replaceAll(response, "</b:", "</");
                        response = replaceAll(response, "<Error>", "");
                        response = replaceAll(response, "</Error>", "");
                        response = replaceAll(response, "<DishToSubmitDTO>", "");
                        response = replaceAll(response, "</DishToSubmitDTO>", "");

                        let json = xml2jsGlobal.xml2js(response, options);
                        result = JSON.stringify(json[Object.keys(json)[0]])
                    }
                    return result;
                }

                function removeEmptyFirstAndLastLines(str) {
                    if (str.charAt(0) == ' ') {
                        str = str.substr(1);
                    }
                    if (str.charAt(str.length - 1) == ' ') {
                        str = str.substr(0, str.length - 1);
                    }

                    return str
                }

                export async function sendNativeMessage(jsonData) {
                    return new Promise((resolve, reject) => {
                        setTimeout(async function () {
                            let androidVersion = Number(Android.getVersion())
                            let jsonDataParsed = JSON.parse(jsonData.data);
                            let requestBody = null;

                            requestBody = removeEmptyFirstAndLastLines(jsonDataParsed.body);
                            let response;
                            if (Android.HTTPRequestTimeout !== undefined) {
                                let timeout = 30000;
                                if (jsonData.data.includes("timeout")) {
                                    timeout = JSON.parse(jsonData.data).timeout;
                                }
                                if (androidVersion >= 1.49) {
                                    let headersString = ""

                                    if (jsonDataParsed.headers) {
                                        let headers = JSON.parse(jsonDataParsed.headers)
                                        for (let header in headers) {
                                            headersString = headersString + header + "=" + headers[header] + ","
                                        }
                                        headersString = headersString.substr(0, headersString.length - 1)
                                    }

                                    response = await Service.AndroidService.makeActionAsyncIfSupported('HTTPRequestTimeout', jsonDataParsed.url, jsonDataParsed.type, requestBody, timeout, headersString);
                                }
                                else {
                                    response = Android.HTTPRequestTimeout(jsonDataParsed.url, jsonDataParsed.type, requestBody, timeout);
                                }
                            }
                            else {
                                response = Android.HTTPRequest(jsonDataParsed.url, jsonDataParsed.type, requestBody);
                            }

                            let responseData = response

                            if (!hasJsonStructure(responseData) && androidVersion < 1.49) {
                                responseData = backwardCompatibilityParser(response)
                            }

                            response = {
                                request: {
                                    body: {
                                        data: jsonData.data
                                    },
                                    result: responseData,
                                    status: "200",
                                    url: "android.external"
                                }
                            };

                            if (posUtils.isNullOrUndefinedOrEmptyString(response.request.result)) {
                                reject(i18next.t(`pos.HTTPError`));
                            }

                            resolve(response);
                        }, 0);
                    })
                }
            }
        }
    }
}