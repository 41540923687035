module PositiveTS {
  export module Components {
    export module SelectPNRItemsDialog {
      const dialogSelector = 'select-pnr-items-dialog';
      export function create() {
        let enterPNRCodeDialog = {
          components: {
            positTable: PositiveTS.Components.PositTable.getComponent(),
          },
          template: JST.SelectPNRItemsDialog(),
          data() {
            return {
              resolveFunc: undefined,
              pnrDetails: undefined,
              reservations: [],
            };
          },
          computed: {
            getLastElalSaleSequence(){
              return Pinia.elalStore.getLastElalSaleSequence
            },
            elalSequence() {
              return i18next.t('elal.PNRDialog.elalSequence', { seq: this.getLastElalSaleSequence });
            },
            title() {
              return `PNR = ${this.pnrDetails?.root?.PNR || 'N/A'}`
            },
            tableOptions(): Types.PositTableOptions {
              return {
                class: (row: Types.ElalGetPNRReservation) => { return row.KupaStatus == 'cancel' ? 'bg-red-300' : '' },
              };
            },
            colDefs(): Types.PositTableHeader[] {
              let cols: Types.PositTableHeader[] = [
                { field: 'selected', type: "checkbox", title: '', action: 'toggle', class: 'w-6', allowCheckAll: true },
                { field: 'RecId', type: 'string', title: i18next.t('elal.PNRDialog.cols.RecId') + ' RecId', class: 'w-28' },
                { field: 'Name', type: 'string', title: i18next.t('elal.PNRDialog.cols.cusName') + ' CusName', class: 'w-24' },
                { field: 'FltNum', type: 'string', title: i18next.t('elal.PNRDialog.cols.FlightNum') + ' FltNum', class: 'w-28' },
                { field: 'ORG', type: 'string', title: i18next.t('elal.PNRDialog.cols.org') + ' ORG', class: 'w-24' },
                { field: 'DEST', type: 'string', title: i18next.t('elal.PNRDialog.cols.dest') + ' DEST', class: 'w-24' },
                { field: 'ServiceCD', type: 'string', title: i18next.t('elal.PNRDialog.cols.serviceCD') + ' ServiceCD', class: 'w-28' },
                { field: 'ServiceDescription', type: 'string', title: i18next.t('elal.PNRDialog.cols.serviceDesc') + ' ServiceDescription', class: 'w-28' },
                { field: 'FormOfPayment', type: 'number', title: i18next.t('elal.PNRDialog.cols.price') + ' Amount', class: 'w-24',
                 computedField: (row) => { 
                  let localNum = Number(row.FormOfPayment).toLocaleString()
                  return `${localNum}${PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign()}`;
                } },
              ];
              return cols;
            },
            selectedAnyItem(): boolean {
              return this.reservations.some(res => res.selected);
            },
          },
          methods: {
            toggle(args: Types.PositTableColEmitArgs) {
              for (let i = 0; i < this.reservations.length; i++) {
                let res = this.reservations[i];
                if (args.row.RecId !== res.RecId) {
                  continue;
                }
                res.selected = !res.selected;
                return;
              }
              console.warn('Could not find reservation to toggle, args : ', args);
            },
            async submit() {
              try {
                let selectedItems: Types.ElalGetPNRReservation[] = this.reservations.filter(res => res.selected);
                app.showLoadingMessage();
                let res = await Service.ElalApiService.ConfirmPNRItems(this.pnrDetails.root.PNR, selectedItems);
                app.hideLoadingMessage();
                this.close(res);
              } catch (error) {
                app.hideLoadingMessage();
                console.error(error);
                this.close(error);
              }
            },
            open(pnrDetails: Types.ElalGetPNRResponse) {
              this.pnrDetails = pnrDetails;
              this.pnrDetails.root.reservations = this.pnrDetails.root.reservations.map(res => { return { ...res, selected: true }; });
              this.reservations = this.pnrDetails.root.reservations;
              this.$el.showModal();
              $(document).unbind('keypress');
              return new Promise(resolve => { this.resolveFunc = resolve });
            },
            close(resolveResult) {
              $(document).keypress(posVC.onKeyPress);
              this.$el.close();
              this.resolveFunc(resolveResult);
            },
            cancel(event) {
              event.preventDefault()
              event.stopImmediatePropagation()
              return this.close();
            },
          },
        }
        VueApp.component(dialogSelector, enterPNRCodeDialog)
      }
    }
  }
}
