module PositiveTS {
  export module Service {
    export class Praxell extends SmartVoucher {
       getBalance(cardNumber: string):Promise<GetBalanceResponse> {
         return new Promise<GetBalanceResponse>( (resolve,reject) => {
           aPrxlAdon.promiseGetCardBalance(cardNumber)
           .then(response => resolve({
             success: response.success,
             balance: response.amount,
             error: response.message
           }))
         })
       }

       pay(voucherData:any, amount: number, cvv?: string):Promise<any> {
         voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_PRAXELL;

         // debit the praxell card
         return new Promise( (resolve,reject) => {
          let voucherConfig = PositiveTS.Service.WasmDB.execAsObject(`select ${SmartVoucher.CARD_PREFIX_FIELD} as cardPrefix from Voucher WHERE typeID = '${voucherData.voucher_type_id}'`)[0]

          if (voucherConfig && !posUtils.isBlank(voucherConfig.cardPrefix)) {
            if (!voucherData.barCode.startsWith(voucherConfig.cardPrefix)) {
              reject(i18next.t('smartVoucher.incompatableCard'));
              return;
            }
          }

           var sequence = new PositiveTS.Storage.Entity.Sequence();
           sequence.findByTenantAndCompanyAndStoreAndPosAndType(null, session.pos.tenantID, 
            session.pos.companyID, session.pos.storeID, session.pos.physicalID, 
            PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE)
            .then(result => {
               // -- Sequence found successfully
               // Get the sequence
               var sequence = result;

               var invoiceNumber = session.pos.posNumber.toString() + '1000';

               // If there is no sequence for this type, create it
               if (sequence !== null) {
                 invoiceNumber = session.pos.posNumber.toString() + (sequence.sequence + 1).toString();
               }

               aPrxlAdon.promiseGetCardBalance(voucherData.barCode)
               .then((response) => {
                 if (voucherData.barCode.indexOf('=') < 0) {
                   voucherData.barCode = response.responseDict.transaction_id.substring(0,14) + '=1962' + voucherData.barCode;
                 }



                 let barcodeManpik = voucherData.barCode.substring(0,3);
                 let voucherManpik = voucherData.praxellManpik;

                 if (voucherManpik && barcodeManpik !== voucherManpik) {
                   console.error( "Voucher manpik:" + voucherManpik + " is not barcode manpik " + barcodeManpik);
                   return reject("מנפיק שגוי");
                 }
                 
                 // Using Addon
                 aPrxlAdon.promiseDebitCard(voucherData.barCode, amount, invoiceNumber).then( (praxellResponse) => {


                   if (praxellResponse.success) {

                     voucherData.amount = praxellResponse.debit_amount;
                     voucherData.transaction_id = praxellResponse.transaction_id;
                     voucherData.invoice_number = invoiceNumber;

                     if (praxellResponse.debit_amount === 0) {

                       aPrxlAdon.resetLastTrxBackup();

                       reject(i18next.t("voucherPaymentDebitZeroBalance"));
                     } else {
                       resolve(voucherData);
                     }

                   } else {
                     // Error returned by server
                     reject(praxellResponse.message);
                   }

                 }).catch( (error) => {
                   // Error 500 returned by server, or totally unexpected error
                   reject("ארעה שגיאה כללית");
                 });
               });
             });
            })
       }

       cancelPayment(voucherToCancel:any):Promise<ActionResponse> {
         return new Promise<ActionResponse>( (resolve,reject) => {
           return aPrxlAdon.promiseCancelDebitCard(voucherToCancel.barCode, voucherToCancel.transaction_id, voucherToCancel.invoice_number)
           .then( (praxellResponse) => {
             if (praxellResponse.success) {
              resolve({success: true,error:""})
             } else {
               resolve({success:false,error: praxellResponse.message})
             }
           });
         })
       }

       cancelLoad(paymentToCancel) {
         var currentPayment;
         paymentToCancel = paymentToCancel[0];

         return aPrxlAdon.promiseCancelDebitCard(paymentToCancel.barCode,
                  paymentToCancel.actionReference, "1000")
          .then((json) => {
          if (json.success) {
            currentPayment = new PositiveTS.Storage.Entity.SalePayment()
            currentPayment.amount = 0
            currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_PRAXELL
            paymentToCancel.amount = -Math.abs(paymentToCancel.amount)
            paymentToCancel.actionType = SmartVoucherActionTypes.CANCEL_DEPOSIT
            paymentToCancel.balance = json.card_balance
            paymentToCancel.actionReference = json.transaction_id;
            currentPayment.data = JSON.stringify([paymentToCancel])
            return Promise.resolve(currentPayment)
          }
          else {
            throw new Error(json.message);
          }
        })
        .catch((error) => {
          if (error.message){
            return Promise.reject(error.message);
          } else {
            return Promise.reject("שגיאה כללית - אנא נסה שנית מאוחר יותר או פנה לתמיכה");
          }

        })
       }

       getCardNumber():Promise<GetCardNumberResponse> {
         return this.getInputCardScan()
         .then((response)=>{
           return ({cardNumber:response, options: undefined});
         })

       }

       private getInputCardScan():Promise<string>{
         var dlgOption = new PositiveTS.Dialogs.InputDialogOptions();
         dlgOption.header = 'הזן כרטיס לטעינה';
         dlgOption.description = 'סרוק כרטיס לטעינה';
         dlgOption.inputPlaceHolder = 'סרוק כרטיס לטעינה';
         dlgOption.showCancelButton = false;
         dlgOption.emptyErrorMessage = 'סרוק כרטיס לטעינה';
         dlgOption.inputValidator =  (value)=> {
                 return true;
         }

         return inputDg.open(dlgOption);

       }

       loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {
         return aPrxlAdon.promiseAddValueCard( cardNumber
                    ,amount
                    ,"1000" )
        .then( (json) => {
          if (json.success) {
            json.actionType = SmartVoucherActionTypes.DEPOSIT;
            json.amount = amount;
            json.actionReference = json.transaction_id;
            json.barCode = cardNumber
            return Promise.resolve({
              amount: 0,
              method: PositiveTS.Storage.Entity.SalePayment.METHOD_PRAXELL,
              data: JSON.stringify([json])
            });
          }
          else {
            throw new Error(json.message);
          }
        })
        .catch((error) => {
          if (error.message){
            return Promise.reject(error.message);
          } else {
            return Promise.reject("שגיאה כללית - אנא נסה שנית מאוחר יותר או פנה לתמיכה");
          }

        })

       }
    }
  }
}
