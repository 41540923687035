module PositiveTS {
  export module Components {
    export module MizdamenOrHakafaDialog {

      function initData () {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          noCustomersFound: false,
          dialogSelector: "#mizdamen-or-hakafa-dialog",
          customer: {},
          arrowImg: `${(<any>window).images_path}/pos/arrow.png`,
        }
      }

      export function create() {

        let component = {
          template: JST.mizdamenOrHakafaDialog(),
          components: {
            selfServicePage: SelfServicePage.getComponent(),
            positiveIcon: PositiveIcon.getComponent(),
            selfServiceKeypad: SelfServiceKeypad.getComponent(),
          },
          computed: {
            restaurantNewPos: () => jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)
          },
          methods: {
            open() {
              this.customer = Service.MizdamenOrHakafaSerive.getCustomerFromSaleIfExists() || {};

              this.$el.showModal();
              $(document).unbind('keypress');
            },
            close() {
              this.$el.close();
              $(document).keypress(posVC.onKeyPress);
            },

            async showError() {
              await app.showAlertDialog({
                header: i18next.t('error'),
                content: i18next.t('hakafaCustomerDialog.errors.customerNotFound'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
              this.$refs.hakafaSearchInput.focus()
            },

            async searchCustomer() {
              if (this.searchInput.trim() == "") {
                return;
              }

              this.loading = true;
              app.showLoadingMessage(i18next.t('hakafaCustomerDialog.searchingCustomer'));

              try {
                let result = await Service.MizdamenOrHakafaSerive.searchCustomer(this.searchInput);
                this.loading = false;
                app.hideLoadingMessage()

                if (result.success) {
                  this.noCustomersFound = false
                  this.selectCustomer(result.customer);
                } else {
                  if (result.status == 4) {
                    this.noCustomersFound = true
                    this.searchInput = ""
                  }
                  this.showError();
                }
              } catch (err) {
                this.showError();
                Service.Logger.error(err);
                this.loading = false
                app.hideLoadingMessage()

              }
            },
            async selectMizdamen() {
              await Service.MizdamenOrHakafaSerive.selectOccasionalCustomer();
              this.close()
            },
            async selectCustomer(customer) {
              this.customer = customer;
              await Service.MizdamenOrHakafaSerive.selectCustomer(customer);
              this.close();
            },
            async swipeCard(): Promise<void> {
              app.showLoadingMessageDialog(i18next.t("mizdamenOrHakafaDialog.swipeCustomerCard"));
              try{
                this.searchInput = await Service.EMV.swipe(0,true);
              } catch (e) {
                await app.showAlertDialog({
                  header: i18next.t('error'),
                  content: e.message,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                })
              }
              if (this.searchInput) {
                await this.searchCustomer()
              }
              app.hideLoadingMessageDialog();
            },
            cleanData() {
              let result = initData();
              for (let prop in result) {
                this[prop] = result[prop];
              }
            }
          },
          data: initData,
        }

        VueApp.component('mizdamen-or-hakafa-dialog', component)

      }
    }
  }
}
