module PositiveTS {
export module Components {
    export module ItemPromoList {

      const componentName = "item-promo-list"
      const componentSelector = "#" + componentName;

      function _initjQueryDialogIfNeeded() {
        if (!this._initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(componentSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: '70%',
              height: 550,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this._initialized = true
        }
      }


      function close() {
        $(componentSelector).dialog('close');
        // this already been done bye caller dialog
        //$(document).keypress(posVC.onKeyPress);
      }

      function open(rows, item) {
        this._initjQueryDialogIfNeeded();
        // this already been done bye caller dialog
        //$(document).unbind('keypress');
        $(componentSelector).dialog('open');
        this.rows = rows;
        this.item = item;
      }

      function _initData(){
        return {
          _initialized: false,
          item: {},
          rows: [],
        };
      }



      export function create() {

        let component = {
            template: JST.ItemPromoList(),
            methods: {
              _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
              open: open,
              close: close,
            },
            data: _initData,

        }

        VueApp.component(componentName,component)

      }
    }
}
}
