module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="select-table-dialog" class="vue-router-page">\n	<template v-if="mobileLayout">\n		' +
((__t = ( JST.selectTableDialogMobile() )) == null ? '' : __t) +
'\n	</template>\n	<template v-else>\n		' +
((__t = ( JST.selectTableDialogDesktop() )) == null ? '' : __t) +
'\n	</template>\n\n</div>';

}
return __p
}