module PositiveTS {
    export module Components {
        export module PosPayment {
            export module TipView {
                export function create() {
                    let tipView = {
                        template: JST.TipView(),
                        mixins: [keyPadMixin, Mixins.paymentViewMixin, Mixins.oneClickMixin],
                        methods: {
                            inputValidations(){
                                let isValid = true
                                let errMsg = ""

                                if(this.totalAmount <= 0){
                                    isValid = false
                                    errMsg = i18next.t('tipPayment.invalidAmount')
                                }

                                if(!isValid){
                                    app.showAlert({
                                        header: i18next.t('error'),
                                        content: errMsg,
                                        continueButtonText: i18next.t("ok"),
                                        hideCancelButton: true,
                                    })
                                }

                                return isValid
                            },
                            clearTapped() {
                                this.rawAmount = 0;
                            },
                            setRawAmount(amount) {
                                this.rawAmount = amount;
                            },
                            setPercentageAmount(amount) {
                                this.percentageAmount = amount;
                            },
                            async verifyAndPay() {
                                if(!this.inputValidations()){
                                    return
                                };

                                await Service.Tip.addTipToSale(this.totalAmount);

                                this.$parent.selectFirstEnabledPaymentMethod();
                            },
                            saleTotalWithoutTips() {
                                if (this.totals) {
                                    return this.totals.totalAmount - Service.Tip.totalSaleItemsTips(this.saleItems);
                                }

                                return 0;
                            },
                            async refreshView() {
                                this.clearTapped();
                                this.totals = PositiveTS.Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
                                await PositiveTS.VueInstance.$nextTick()
                            },
                            activeTipPayments() {
                                return this.saleItems.filter(saleItem => Service.Tip.isTipItem(saleItem.item));
                            },
                            getPaymentSaleItemAmount(paymentSaleItem) {
                                return session.fixedFloat(paymentSaleItem.quantity * paymentSaleItem.unitPrice);
                            },
                            async deleteTipPayment(paymentSaleItem) {
                                let saleItemToDelete = posVC.saleItems.filter(saleItem => saleItem.rowNumber == paymentSaleItem.rowNumber)[0];

                                if (saleItemToDelete) {
                                   await posVC._deleteSaleItem(saleItemToDelete);
                                   await posPaymentVC.updateAmountsIndicators(false);
                                }
                            }
                        },
                        computed: {
                            percentageAmount: {
                                set(value: number) {
                                    this.rawAmountHolder = session.fixedFloat(value * (this.saleTotalWithoutTips() / 100));
                                    this.totalAmount = Math.round(this.rawAmount);
                                },
                                get() {
                                    if (this.saleTotalWithoutTips() == 0) {
                                        return 0;
                                    }
                                    return session.fixedFloat((this.rawAmount / this.saleTotalWithoutTips()) * 100);
                                }
                            },
                            rawAmount: {
                                set(value: number) {
                                    this.totalAmount = session.fixedFloat(value);
                                    this.rawAmountHolder = session.fixedFloat(value);
                                },
                                get() {
                                    return this.rawAmountHolder;
                                }
                            },
                            currencySign() {
                                return Service.MultiCurr.getInstance().getPosCurrencySign();
                            },
                        },
                        setup(){

                            const globalStore = Pinia.useGlobalStore();
                            const {mobileLayout,sale,saleItems} = Pinia.storeToRefs(globalStore);
                            const globalStoreProps = {mobileLayout,sale,saleItems}
              
                            return {...globalStoreProps}
                          },
                        data() {
                            return {
                                rawAmountHolder: 0,
                                totalAmount: 0,
                                totals: null,
                                fields: {
                                    rawAmount: {
                                        selected: true,
                                        allowDecimal: true,
                                        inEditMode: true,
                                        type: 'number',
                                        disabled: false,
                                        model: 'rawAmount'
                                    },
                                    percentageAmount: {
                                        selected: false,
                                        allowDecimal: true,
                                        inEditMode: true,
                                        type: 'number',
                                        disabled: false,
                                        model: 'percentageAmount'
                                    },
                                    totalAmount: {
                                        selected: false,
                                        allowDecimal: false,
                                        inEditMode: false,
                                        type: 'number',
                                        disabled: true,
                                        model: 'totalAmount'
                                    },
                                },
                                initialValues: {
                                    rawAmount: 0
                                },
                                speedTipAmounts: [10,12,15,20]
                            }
                        }
                    }
                    VueApp.component('tip-view', tipView);
                }
            }
        }
    }
}