module PositiveTS {
  export module Service {
    export module HoldSale {
      const _canceledBonsString = "canceledBons"
      export async function holdCurrentSale(createNew = true, toSplitSale = false): Promise<void> {
        if (posVC.saleItems.length == 0) {
          return;
        }

        console.debug(`holding sale ${posVC.sale.id}`);
        posVC.sale.isInHold = true;
        posVC.sale.invoiceSequence = toSplitSale ? Storage.Entity.Sale.PRE_SPLITTED_SALE_INVOICE_SEQUENCE : posVC.sale.timestamp * -1;
        await Service.FullSale.persist(posVC.sale, posVC.saleItems, posVC.salePayments);

        posVC.cleanSale(null, toSplitSale);

        if (createNew) {
          await posVC.createNewSale();
        }

      }

      export async function markSaleAsHoldedSale(sale) {
        sale.isInHold = true;
        sale.invoiceSequence = sale.timestamp * -1;
      }

      export async function salesInHoldCollection() {
        let sales = await appDB.sales.where('invoiceSequence').below(-2).toArray(); // SPLIT SALE PAYMENT USES -2

        return sales.filter(sale => Boolean(sale.isInHold))
      }


      export async function getSaleObjsOnHold() {

        let tableResults = []
        let sales = await salesInHoldCollection()

        for (let sale of sales) {
          //remove child items from calculation of totals
          sale.items = sale.items.filter((item) => { return (item.level == null || item.level == 0) })

          var totals = Helper.SaleHelper.calcuateSaleTotals(sale, sale.items, sale.payments);

          let svcCustomer = new PositiveTS.Service.CustomerClub(sale, sale.payments, sale.items);
          let customerName = ""
          if (sale.jsondata && JSON.parse(sale.jsondata).dedicatedTo) {
            customerName = JSON.parse(sale.jsondata).dedicatedTo;
          }
          if (svcCustomer.isCurrentCustomerExists()) {
            customerName = `${svcCustomer.getCurrentSelectedCustomer(sale).s_first_name} ${svcCustomer.getCurrentSelectedCustomer(sale).s_last_name}`;
          }

          tableResults.push({
            customerName: customerName,
            cashierEmployeeName: sale.salespersonEmployeeName || sale.cashierEmployeeName,
            id: sale.id,
            createdAt: sale.createdAt,
            amount: totals.totalAmount,
            quantity: totals.totalQuantity,
            orderNumber: sale.orderNumber
          })
        }
        return tableResults;
      }

      export async function cancelBonItemsIfRequired(saleItems: PositiveTS.Storage.Entity.SaleItem[], isCreditInvoice = false): Promise<any> {

        let permissionGranted = false;

        let allLogicalPrinters = await Storage.Entity.LogicalPrinter.getCache()


        let items = [];

        for (let saleItem of saleItems) {
          let childQty = 0;

          let shouldCancel = false;

          if (saleItem.bonPrintedAt) {
            shouldCancel = true;
          }
          if (isCreditInvoice) {
            let itemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(saleItem, allLogicalPrinters);
            if (itemPrinters.length > 0) {
              shouldCancel = true;
            }
          }

          if (shouldCancel) {

            if ((!isCreditInvoice) && (!permissionGranted)) {
              await app.showManagerApprovalDialog();
              permissionGranted = true;
            }

            if (!isCreditInvoice) {
              saleItem.quantity = saleItem.quantity * -1;
            }

            _incrementBonTotalSumAndQuantityCanceled(saleItem.quantity, saleItem.unitPrice);

            if (saleItem.children) {
              for (let child of saleItem.children) {
                let itemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(child, allLogicalPrinters);
                if (itemPrinters.length > 0) {
                  childQty += child.quantity;
                }
                if (child.children) {
                  for (let grandchild of child.children) {
                    let grandItemPrinters = Storage.Entity.LogicalPrinter.getPrintersForItem(grandchild, allLogicalPrinters);
                    if (grandItemPrinters.length > 0) {
                      childQty += grandchild.quantity;
                    }
                  }
                }
              }
            }

            if (!isCreditInvoice) {
              incrementCanceledBonQty(childQty * -1);
            }
            else {
              incrementCanceledBonQty(childQty);
            }

            items.push(saleItem);
          }
        }

        if (isCreditInvoice) {
          if (jsonConfig.getVal(jsonConfig.KEYS.allowBonCancelPrintingOnCreditInvoice)) {
            await PositiveTS.Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(posVC.sale, items, posVC.salePayments, true)
          }
        }
        else {
          if (jsonConfig.getVal(jsonConfig.KEYS.allowBonCancelPrinting)) {
            await PositiveTS.Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(posVC.sale, items, posVC.salePayments, true)
          }
        }
      }

      export function getBonTotalSumAndQuantityCanceled(): { totalQuantity: number, totalPrice: number } {
        var canceledBonsString = localStorage.getItem(_canceledBonsString)
        var canceledBons = { totalQuantity: 0, totalPrice: 0 }
        if (canceledBonsString) {
          canceledBons = JSON.parse(canceledBonsString);
        } else {
          resetBonTotalSumAndQuantityCanceled()
        }
        return canceledBons;
      }

      export function resetBonTotalSumAndQuantityCanceled(): void {
        var canceledBons = { totalQuantity: 0, totalPrice: 0 }
        localStorage.setItem(_canceledBonsString, JSON.stringify(canceledBons))
      }

      function incrementCanceledBonQty(qty: number) {
        var _ret = getBonTotalSumAndQuantityCanceled()
        _ret.totalQuantity += Math.abs(qty);
        localStorage.setItem(_canceledBonsString, JSON.stringify(_ret))
      }

      function _incrementBonTotalSumAndQuantityCanceled(quantity: number, price: number): void {
        var _ret = getBonTotalSumAndQuantityCanceled()
        _ret.totalQuantity += Math.abs(quantity);
        _ret.totalPrice += price * Math.abs(quantity);

        localStorage.setItem(_canceledBonsString, JSON.stringify(_ret))
      }

      export function isHoldSaleAvailable(): boolean {
        if ((posVC.salePayments.length > 0 && !jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) || posVC.saleItems.length === 0) {
          return false;
        }
        return !jsonConfig.getVal(jsonConfig.KEYS.disableHoldSale);
      }

      export async function isReleaseSaleAvailable(): Promise<boolean> {
        if (posVC.salePayments.length > 0) {
          return false;
        }
        return (await salesInHoldCollection()).length > 0

      }

      export async function hasSalesOnHold(): Promise<boolean> {
        return (await salesInHoldCollection()).length > 0
      }

      export async function releaseSaleOnHold(saleIdToRestore: string) {

        if (posVC.salePayments.length > 0) {
          throw new Error("hold current sale prerequesists failed");
        }

        let saleToRelease;

        saleToRelease = await appDB.sales.get(saleIdToRestore)

        if (saleToRelease == null || !Boolean(saleToRelease.isInHold)) {
          throw new Error("sale id not exists")
        }

        if (posVC.saleItems.length > 0) {
          await holdCurrentSale(false);
        }
        else {
          if (posVC.sale) {
            await appDB.sales.delete(posVC.sale.id);
          }
        }

        await _updateSaleAsReleased(saleIdToRestore);
        return true
      }

      export async function deleteSaleOnHold(saleId: string) {
        return appDB.sales.delete(saleId);
      }


      async function _updateSaleAsReleased(saleIdToRestore) {
        let propsToUpdate: any = { invoiceSequence: -1, isInHold: false };

        await appDB.sales.update(saleIdToRestore, PositiveTS.Shared.DB.checkIfNeedCloneProxy(propsToUpdate))
      }

    }
  }
}
