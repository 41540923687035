module PositiveTS {
  export module Components {
    export module SelfServiceCaveret {

      export function getComponent() {
        return {
          template: JST.selfServiceCaveret(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin, keyPadMixin],
          methods: {
            async findCustomer() {
              let caveret = new Service.CaveretPayment();
              try {
                let res = await caveret.getBalance(
                  this.cardNumber,
                  this.pinCode,
                  this.inputMethod,
                  this.getExtraData?.caveretClubCode || null
                );
                if (res.Budgets !== null && res.Budgets.length > 1) {
                  await app.showAlertDialog({
                    header: i18next.t("error"),
                    content: i18next.t("caveretPayment.manyBudgetsError"),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });
                  return false;
                }

                if (res.ReplyCode === 1) {
                  let result = await this.applyLocalCustomer(
                    res.CustomerCode,
                    res.CustomerName
                  );
                  if (result) {
                    this.budget = Object.assign({ used: null }, res.Budgets[0]);
                    this.budget.parsedDate = moment(
                      this.budget.ToDate,
                      "DD/MM/YYYY HH:mm:ss.SSS"
                    ).format("DD/MM/YY");
                  }
                  let promoTriggers = [];
                  if (this.budget.PromoTriggers) {
                    promoTriggers = this.budget.PromoTriggers.split(",");
                  }
                  for (let promoTrigger of promoTriggers) {
                    let promo = promoTrigger.split("#");
                    if (promo[1] && promo[1] == 0) {
                      this.budget.used = 0;
                    }
                  }

                  this.budget.used = Math.min(
                    this.budget.Balance,
                    this.totalAmount
                  );

                  return true;
                } else {
                  this.clearFields()
                  app.showAlertDialog({
                    header: res.ScreenCommentsHeader,
                    content: res.ScreenCommentsBody,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });

                  return false
                }
              } catch (error) {
                console.error(error);
                this.clearFields();
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("caveretPayment.error"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
              }
            },

            async applyLocalCustomer(
              customerCode: string,
              customerName: string
            ) {
              let searchResults = await Storage.Entity.PositiveCustomer.search(
                customerCode
              );
              if (searchResults.result.i_return_code == "1") {
                if (searchResults.result.customers.length > 1) {
                  app.showAlertDialog({
                    header: i18next.t("error"),
                    content: i18next.t(
                      "caveretPayment.moreThanOneCustomerFound"
                    ),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });
                } else {
                  let customerClubService = new Service.CustomerClub(
                    posVC.sale,
                    posVC.salePayments,
                    posVC.saleItems
                  );
                  let foundCustomer = searchResults.result.customers[0];

                  if (!posUtils.isNullOrUndefinedOrEmptyString(customerName)) {
                    if (customerName.indexOf(" ") == -1) {
                      foundCustomer.first_name = customerName;
                      foundCustomer.last_name = "";
                    } else {
                      foundCustomer.first_name = customerName.substr(
                        0,
                        customerName.indexOf(" ")
                      );
                      foundCustomer.last_name = customerName.substr(
                        customerName.indexOf(" ") + 1
                      );
                    }
                  }

                  await customerClubService.setCurrentSelectedCustomer(
                    PositiveTS.Components.CaveretPaymentCustomerPicker.convertToPosStructure.call(
                      this,
                      foundCustomer
                    )
                  );
                  posVC.saleUpdated();

                  this.customerName =
                    i18next.t("caveretPayment.customer") +
                    ":" +
                    customerCode +
                    " " +
                    customerClubService.getCustomerShortDisplayName();
                  return true;
                }
              } else {
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t(
                    "caveretPayment.caveretAndLocalCustomerMismatch"
                  ),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
              }

              return false;
            },
            async pay() {
              let totalBudgetUsed = 0;
              let usedBudgets = [];
              let soloBudget = null;

              if (!posUtils.isNullOrUndefinedOrEmptyString(this.budget.used)) {
                usedBudgets.push(this.budget);

                if (this.budget.AllowOtherPayments == false) {
                  soloBudget = this.budget;
                }
              }

              if (
                soloBudget &&
                (usedBudgets.length > 1 || posVC.salePayments.length > 0)
              ) {
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("caveretPayment.solobudgetSelected", {
                    name: soloBudget.BudgetName,
                  }),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
                return;
              }
              let promoTriggers = [];

              if (this.budget.used >= 0) {
                if (this.promotionApplied == false) {
                  if (this.budget.PromoTriggers) {
                    promoTriggers = this.budget.PromoTriggers.split(",");
                  }
                  for (let promoTrigger of promoTriggers) {
                    let promo = promoTrigger.split("#");
                    if (promo[1]) {
                      if (this.budget.used >= Number(promo[1]))
                        this.applyPromotion(promo[0]);
                    }
                  }
                }
                totalBudgetUsed += Number(this.budget.used);
              }

              if (totalBudgetUsed < this.totalAmount && soloBudget) {
                app.showAlertDialog({
                  header: i18next.t("error"),
                  content: i18next.t("caveretPayment.tooLittleBudgetAndSolo", {
                    name: soloBudget.BudgetName,
                  }),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
                return false;
              } else {
                if (this.promotionApplied) {
                  let updatedSaleItems = posVC.updatePromotions();
                  posVC.saleUpdated(updatedSaleItems);
                  this.totalAmount = posVC.getTotalLeftToPay();
                  posPaymentVC.updateAmountsIndicators();

                  totalBudgetUsed = this.totalAmount;
                  usedBudgets[0].used = this.totalAmount;
                }

                let paymentResponse = await Service.VoucherPayment.payBySmartVoucherTypeEx(
                  Storage.Entity.Voucher.SMART_VOUCHER_CAVERETPAYMENT,
                  totalBudgetUsed,
                  this.cardNumber,
                  undefined,
                  {
                    budgets: usedBudgets,
                    pincode: this.pinCode,
                    clubCode: null,
                  }
                );

                if (!paymentResponse.success){
                  app.showAlertDialog({
                    header: i18next.t("error"),
                    content: paymentResponse.error,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  });
                  return paymentResponse.success
                }

                if (this.budget.used != 0) {
                  this.budget.used = null;
                }
                this.promotionApplied = false;

                return paymentResponse.success;
              }
            },
            applyPromotion(promoCode) {
              posVC.addExternalPromotion(promoCode);
              this.promotionApplied = true;
            },
            selectField(fieldName: string) {
              let refField = null;

              for (let field in this.fields) {
                this.fields[field].selected = false
                this.fields[field].inEditMode = true
                this.fields[field].hasDecimal = false
                if (field == fieldName) {
                  this.fields[field].selected = true
                  refField = field
                }
              }
              if (refField != null) {
                this.$refs[refField].focus();

              }
            },
            onKeyTapped(key) {
              let field = this.getSelectedField();

              this.$refs[field.model].focus()
              this.keyPadHanlder(key, field, this);

            },
            getSelectedField() {
              for (let fieldName in this.fields) {
                if (this.fields[fieldName].selected) {
                  return this.fields[fieldName]
                }
              }
              return undefined;
            },
            clearFields() {
              this.cardNumber = "",
                this.pinCode = ""
            },
            async swipeCard() {
              app.showLoadingMessageDialog(i18next.t('caveretPayment.swipeCard'));

              this.cardNumber = await Service.EMV.swipe();
              if(posUtils.isNullOrUndefinedOrEmptyString(this.cardNumber)){
                app.hideLoadingMessageDialog();
                this.$refs.cardNumber.focus()
                return
              }
              this.$refs.cardNumber.type = 'password'
              this.$refs.cardNumber.disabled = true
              this.$refs.cardNumber.style.backgroundColor = "#c5c5c5"

              app.hideLoadingMessageDialog();

              this.$refs.pinCode.focus()
            },

            async verifyAndPay() {
              if (!posUtils.isNullOrUndefinedOrEmptyString(this.cardNumber)) {
                app.showLoadingMessageDialog(i18next.t('selfService.chargingCard'));
                this.paymentInProcess = true;
                this.pinCode = posUtils.isNullOrUndefinedOrEmptyString(this.pinCode) ? "0000" : this.pinCode
                let customer = await this.findCustomer();
                if (!customer) {
                  app.hideLoadingMessageDialog();
                  this.setCurrentStep("SelfSelectPaymentMethod");
                  return
                }
                let payment = await this.pay();
                if (!payment) {
                  app.hideLoadingMessageDialog();
                  this.setCurrentStep("SelfSelectPaymentMethod");
                  return
                }
                this.goToNextStep();
              } else {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: i18next.t("selfServiceSupermarket.errors.fieldsAreRequired"),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });
              }

            },

            back() {
              this.goBack()
              this.clearFields()
            }
          },
          computed: {
            fields() {
              let fields = {

                'cardNumber': {
                  selected: false,
                  allowDecimal: false,
                  inEditMode: false,
                  type: 'number',
                  disabled: false
                },
                'pinCode': {
                  selected: false,
                  inEditMode: false,
                  type: 'password',
                  allowDecimal: false,
                  disabled: false
                },

              }
              for (let field in fields) {
                fields[field].model = field;
              }
              return fields
            },
          },
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {getExtraData} = Pinia.storeToRefs(selfServicePaymentStore);
            const {setCurrentStep} = selfServicePaymentStore;
            return {getExtraData,setCurrentStep}
          },
          data: function () {
            return {
              paymentInProcess: false,
              inputMethod: 1,
              cardNumber: "",
              pinCode: "",
              budget: {},
              clearImg: `${(<any>window).images_path}/close.png`,
              totalAmount: posVC.getTotalLeftToPay(),
              promotionApplied: false,
              posWithPinPad: (jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv) ? true : false,
              showErrors: false
            };
          },
          mounted() {
            this.$refs.cardNumber.focus()
          },
        }
      }
    }
  }
}
