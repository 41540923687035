
export const translation = {


            zReport: {
              sessionNumber: 'Reference Number',
              printTransmitReport: 'Print Transmit Report',
              cashPaymentsDisplayDalpakim: "Cash payments without deliveries",
              dbOrDiskError: "Error backiung up sales, Please try again. If the problem persists, Contact support.",
              openDeliveryOrders: "Opened Delivery Orders",
              openDeliveryOrdersWarning: "Making Z Report while there is an opened delivery orders is not allowed, Do you wish to close them?",
              closeOrders: "Close Orders",
              cancel: "Cancel",
              payAttention: "Please Notice!",
              currentlyInDeliveryCash: `ש"ח נמצאים כרגע במזומן משלוחים`,
              failedToTransmit: "Plaese notice - Transmittig the credit cards has failed. Please check the pos network and try again. If the problem persists, Contact support.",
              areYouSure: "Do you wish to close the pos and process the Z-report?",
              salesOnHoldError: "You can't make z report while there is on-hold sales",
              newSaleHasPaymentsError: "לא ניתן לסגור דוח Z שיש מכירה עם אמצעי תשלום פעילים",
              lastPosSequenceNotFound:"Pos last Z sequence not found.",
              cashPaymentsDisplay: "Cash Payments",
              zStatement: "Closing Statement",
              roundTotals: "Rounds Total",
              sendingTranBackups: "Transmitting credit backups to the server",
              sendingTranBackupsError: "Warning! The Z Reportd was closed successfully but the Credit Movement did not backed-up in the server. Contact support.",
              tamashTitle: "Delivery Certificate",
              tamashTotal: "Sum",
              newZ: "New z",
              restoreZ: "Restore existing Z",
              promptGenerateNewReport: "Do you want to generate a new report?",
              show: "Show",
              sendEmail: "Send via email",
              checkForActiveEmployees: "Checking for employees that in shift",
              paraxelCardLoading: "Paraxel Card Load",
              insideCardLoading: "Inside Card Load",
              cellularCard: "Cellular Line Load",
              multipassCardsLoading: "Multipass Card Load",
              valuCardLoading: "Value-Card Load",
              ototLoading: "Load Bracelet",
              cashWIthdrawal: "Cash Withdrawal",
              forceZReport: 'There was no Z report done in the last 24 hours. In  order to continue working, please do a Z report',
              tipsTotal: 'Total Tips',
              doAllZTitle: 'Do you want to remotely generate Z report for all POS?',
              doAllZBigTitle : 'Generate Remote Z Report',
              leumiCard: 'Total Leumi Card:',
            },
            ZReportAllProgressDialog: {
              title: 'Do you want to generate Z report to all POS in the store?',
              posID: 'POS ID',
              status: "Status",
              message: "Message",
              Remaining: "Remaining POS",
              Offline: "Offline POS",
              Success: "Successful POS",
              Error: "Error POS",
              printed: "Printed Reports",
              noChannels: "No POS found to generate Z report",
              ContinueToInitatorZ: "Generate Z Report for this POS",
              fetchingPOS : " Fetching POS",
              noSales: 'No sales to close',
              Table: {
                finished: "Finished",
                finished_with_error: "Error",
                started: "In Progress",
                offline: "Offline",
                pending: "Pending",
                printed: "Printed",
              },
            },
            itemUnits: {
              unit: "Units'",
              kilo: 'Kg'
            },
            currInvReport: {
              title: "דוח מלאי נוכחי",
              itemName: "תאור",
              barcode: "ברקוד",
              department: "מחלקה:",
              currInv: "מלאי",
              withoutDepartment: "פריטים ללא מחלקה",
              generatingReport: "מפיק דוח מלאי",
              error: "ארעה שגיאה בעת הפקת דוח מלאי - אנא נסה שנית.\nבמידה והבעיה ממשיכה יש לפנות לתמיכה",
              totalAll: 'סה"כ מלאי לכל הפריטים:',
              totalDep: 'סה"כ מלאי למחלקה:',
            },
            itemManage: {
              name: "שם",
              price: "מחיר",
              inventory: "מלאי",
              title: "ניהול פריטים",
              departments: "מחלקות",
              department: "מחלקה",
              addItem: "הוספת פריט",
              addItemTitle: "הוספת פריט",
              itemName: "תאור פריט",
              barcode: "ברקוד",
              consumerPrice: "מחיר לצרכן",
              availableInventory: "מלאי זמין",
              hasWeight: "פריט שקיל",
              noVat: 'פריט ללא מע"מ',
              locked: 'פריט נעול',
              allowPriceChange: "אפשר שינוי מחיר",
              allowNameChange: "אפשר שינוי שם",
              saveAndExit: "שמור וסגור",
              exitWithoutSave: "צא ללא שמירה",
              edit: "עריכה",
              back: "חזרה",
              actions: "פעולות",
              departmentMustBeSelected: "חובה לבחור מחלקה",
              noDepartmentsExist: "לא קיימות מחלקות - יש להקים מחלקה ממסך מחלקות",
              updateInventory: "עדכון מלאי",
              addToInventory: "הוסף למלאי",
              currentInventory: "מלאי נוכחי",
              updateInventoryMessage: "אנא הזן מלאי חדש",
              updateInventoryPlaceholder: "מלאי חדש",
              addToInventoryMessage: "אנא הזן מלאי שהתווסף",
              addToInventoryPlaceholder: "מלאי שהתווסף",
              pleaseEnterAValue: "מלאי לא יכול להיות ריק",
              savingItem: "שומר פריט",
              searchItem: "חיפוש פריט",
              withoutDepartment: "ללא מחלקה",
              itemState: "הצג פריטים:",
              cannotSaveItem: "לא ניתן לשמור פריט, אנא בדוק את הערכים שהוזנו",
              nameCannotBeEmpty: "Value is required",
              barcodeAlreadyExists: "לא ניתן לשמור פריט - הברקוד של פריט זה כבר קיים לפריט אחר בקטלוג הפריטים",
              importFromFile: "טעינה מקובץ",
              exportToFile: "גיבוי לקובץ",
              exportTitle: "גיבוי פריטים",
              exportSuccess: "הפריטים נשמרו בהצלחה",
              importingFileAndDep: "טוען פריטים ומחלקות...",
              loadingFile: "טוען קובץ...",
              prev: "קודם",
              next: "הבא",
              loadingFileConfirmation: "האם אתה בטוח שברצונך לטעון קובץ פריטים?",
              cannotEditGenericItem: "פריט כללי הינו פריט מערכת ואינו ניתן לעריכה",
              promotions: 'מבצעים',
              hideFromMenu: 'אל תציג פריט בתפריט',
              showInMainMenu: 'הצג פריט בתפריט ראשי',
              allowChangingName: "Allow changing item's name",
              mustBePrice0ToChangeName: 'Must be price 0 to change name',
              bonPrinterSelect: "Bon Printer",
              menuItemSortOrder: "Item order in menu(lower number shows first)"
            },
            shutdown: {
              poweroff: "Poweroff",
              restart: "Restart",
              logout: "Logout",
              back: "Back",
              title: "Shutdown Options"
            },
            externalOrdersDifferences: {
              title: "Differences between the sale and order",
              warning: "Warning! there are items that appear on the order and not on the sale",
              barcode: "Barcode",
              name: "Name",
              quantity: "Quantity",
              markAs: "Mark as",
              marked: "Marked",
              missing: "Missing",
              alterior: "Alterior",
              item: "Item",
              missingItemsWarning: "Items in order that do not exist on POS",
            },
            externalOrdersList: {
              title: "Web orders",
              loading: "Loading orders...",
              errorLoading: "There was an error loading the orders from the server",
              status: "Status",
              type: "delivery type",
              searchByText: "Order num/customer name",
              internetOrders: "Web orders",
              internetError: "The pos cannot reach the server. Please check your internet connection. if the internet is ok, please contact support",
              chooseAction: "Choose action",
              moreActions: "More actions",
              inProgress: "In process",
              markAsNew: "Mark as new",
              readyForShipping: "Ready for shipping",
              errorChangingStatus: "There was an error updating the status, please contact support",
              scanItems: "Scan Items",
              thereAreSaleItemsInPos: "There are Items in the active sale. please restart the sale first",
              printItems: "Print Items",
              customer: "Customer",
              orderNum: "Order Num",
              cancelOrder: "Cancel order",
              errorInCancel: "there was an error cancelling the order",
              cancellingOrder: "Cancelling order",
              statuses: {
                1: "New",
                2: "Processing",
                3: "Deleted",
                4: "Sent",
              },
              deliveryType: {
                "TA": "Take Away",
                "Delivery": "Delivery",
                "default": "Unknown"
              },
              lookingForInvoiceInServer: "Looking for invoice"
            },
            delivery: {
              wolt: "wolt",
              mishloha: "mishloha",
              paidit: "paidit",
              tenbis: "tenbis",
              pluxee: "Pluxee",
              deliveryErrorExists: 'Please Notice, There is an error getting the orders, Some of the orders won\'t be visible. Please contact support.',
              totalOrders: 'Total Orders',
              showDeliveries: 'Deliveries',
              showTa: 'TA',
              showAll: 'All',
              order: 'Order',
              partialOrdersExists: 'There are partially payed orders, please handle them before creating the Z Report',
              subtotal: 'Subtotal',
              tax: 'Tax',
              tip: 'Tip',
              total: 'Total',
              showOpenOrders: "Back to Active Orders",
              showDeliveredOrders: "History",
              qty: 'Quantity',
              cashier: 'Cashier',
              orderDetails: "Order Details",
              invoiceSequence: "Invoice Number",
              orderNotExistsOnDisk: "Order does not exists on disk - it may have benn deleted by another POS",
              lockingOrder: "Locking order",
              theFollowingOrdersAreLocked: "The following order are locked:",
              lockByPos: "Order is locked by another POS",
              cantDeleteDefaultAddress: "לא ניתן למחוק כתובת ראשית",
              cantDeleteSelectedAddress: "יש לבחור כתובת אחרת למשלוח תחילה",
              orderNum: "Order Number",
              city: "City",
              address: "Address",
              orderTime: "Order Time and Date",
              ordererName: "Table\\Orderer Name",
              assignedCourierTime: "Assigned Courier Time",
              courier: "Courier",
              customerName: "Customer Name",
              status: "Status",
              nameOfPlace: "Address Nickname",
              phoneShort: "Phone",
              title: "הזמנת משלוח",
              prevOrderChoosen: "נבחרה הזמנה",
              loadingOrder: "טוען הזמנה",
              type: "Type",
              street: "רחוב",
              floor: "קומה",
              apartment: "דירה",
              entrance: "כניסה",
              details: "Details",
              customerRemarks: "Customer Remarks",
              deliveryRemarks: "Order Remarks",
              openOrdersTitle: "הזמנות פתוחות",
              filterLabel: "סינון לפי מס' הזמנה/לקוח/כתובת",
              noOpenDeliveries: "אין הזמנות פתוחות",
              noOrdersFound: "No orders found",
              noOptions: "No Options found",
              phone: "מספר טלפון",
              assignedOrders: "הזמנות מצוותות",
              assignCourier: "צוות",
              unassignCourier: "הסר ציוות",
              notAssignedOrders: "הזמנות לא מצוותות",
              closeDialog: "Close",
              openDelivery: "Open",
              assignedDelivery: "Assigned(Cash Out)",
              closedDelivery: "Assigned",
              cashToReceive: "מזומן לקבל מ",
              totalCashToReceive: `סה"כ מזומן לקבל משליחים`,
              closeAllOrdersForCourier: "סגור את כל ההזמנות לשליח זה",
              shouldCloseAllOrdersForCourier: "האם לסגור את כל ההזמנות עבור שליח זה?",
              closeOrder: "סגור הזמנה",
              totalAmount: "סכום כולל",
              actions: "פעולות",
              amount: "Sum",
              closeAll: "סגור הזמנות",
              confirm: "OK",
              compensation: "Compensation",
              compensationTitle: "פיצוי ללקוח",
              compensationReason: "סיבת פיצוי",
              removeCompensation: "הסר פיצוי",
              lookingForCompensation: "מאתר פיצוי ללקוח...",
              updatingCompensation: "מעדכן פיצוי ללקוח...",
              streetNotFound: "לא נמצא רחוב",
              cityNotFound: "לא נמצאה עיר",
              chooseCityFirst: "יש לבחור רחוב תחילה או להזין רחוב ידנית",
              loadingStreets: "טוען רחובות וערים...",
              delivery: "Delivery",
              deliveryFromExternal: "Internet Delviery",
              ExternalTenbisDelivery: "Tenbis Delivery",
              ExternalTenbisTA: "Tenbis Pickup",
              ExternalPluxeeDelivery: "Pluxee Delivery",
              ExternalPluxeeTA: "Pluxee Pickup",
              externalPaiditTA: "Paidit T.A",
              externalPaiditDelivery: "Paidit deliveries",
              externalPaiditSitting: "Paidit Sitting",
              externalPrestoSitting: "Presto Contactless Dine In",
              externalPrestoTA: "Presto Contactless Take Out",
              externalMishloha: "Mishloha Delivery",
              externalMishlohaTA: "Mishloha Take-Away",
              externalWolt: "Wolt Delivery",
              externalWoltTA: "Wolt Take-Away",
              externalTenbisBtn: "Tenbis",
              externalPaiditBtn: "Paidit",
              externalPluxeeBtn: "Pluxee",
              externalWoltBtn: "Wolt",
              TA: "TA",
              loadingDeliveryOrdersData: "טוען מידע עבור משלוחים",
              compensationPlaceHolder: "נא להזין פיצוי",
              compensationReasonPlaceHolder: "נא להזין סיבת פיצוי",
              courierExitStillHaveOrders: "לעובד (שליח) צוותו הזמנות ב-20 דקות האחרונות, האם להמשיך?",
              releaseSale: "Release",
              customerDetails: "Customer",
              taPaid: "Paid",
              delivered: "Delivered",
              cancelSandiDelivery: "Cancel Sandiman delivery",
              deliveryCancelled: "Cancelled",
              cancellingOrder: "Cancelling Order...",
              sandiCreateOrderError: "Failed to create Sandiman Delivery, Please try again. Else, please contact support",
              sandiCancelOrderError: "Failed to cancel Sandiman Delivery, Please contact support",
              bump: 'BUMP',
              customerExists: `לא ניתן להתחיל משלוח במכירה עם לקוח קיים.\n  יש למחוק את המכירה הנוכחית ולנסות שוב`,
              cash: "Cash",
              otherPayments: "תשלומים אחרים",
              totalotherPayments: `סה"כ תשלומים אחרים`,
              notDeliveryPos: "הקופה לא מוגדרת כקופת משלוחים",
              none: 'None',
              loadingDeliveries: 'Loading deliveries...',
              failedToGetOrders: 'Failed to load orders, please try again',
              tryingToAddExistsingCustomer: "The customer with the same number/id/phone alreadey exists",
              HttpError: "The {service} interface settings are not valid. This error may be due to an invalid API KEY or an incorrect restaurant identifier."
            },
            directHakafa: {
              customerSearch: 'מחפש לקוח - אנא המתן',
              cardRequired: 'חובה להזין כרטיס',
              moreThanObligo: 'לא ניתן להוסיף תשלום - סך רכישות מעל האובליגו',
              notExists: 'לקוח הקפה אינו קיים',
              error: 'שגיאה',
              generalError: "שגיאה כללית בתשלום. אנא נסה שנית",
              title: 'קורא קרבה',
              description: 'קרב כרטיס',
              placeHolder: 'כרטיס לקוח',
              foundMoreThanOneCustomer: 'קיימת כפילו בכרטיסי לקוח, לא ניתן להשלים את הקניה',
            },
            departmentForm: {
              createTitle: "New Department",
              editTitle: "Edit Department",
              name: "Name",
              prev: "Previous",
              next: "Next",
              actions: 'Actions',
              activeItems: "Active Items",
              lockedItems: "Locked Items",
              itemState: "Show:",
              searchItem: "Search",
              itemsToAssociateTitle: "Related Items",
              relatingItemTitle: "Item Relation",
              relateItemToGroup: "Attach",
              unRelateItemToGroup: "Renive relation",
              exitWithoutSave: "Exit without save",
              itemBarcode: "Item Barcode",
              itemName: "Item Name",
            },
            departments: {
              title: "ניהול מחלקות",
              addDepartmentLabel: "הוסף מחלקה",
              back: "חזרה",
              name: "שם מחלקה",
              actions: "פעולות",
              add: "שמור",
              cancel: "Cancel",
              edit: "עריכה",
              addNewItemPlaceholder: "שם מחלקה",
              departmentNameCantBeEmpty: "שם מחלקה לא יכול להיות ריק",
              couldNotAddDepartment: "לא ניתן להוסיף מחלקה. יש לנסות שוב. אם הבעיה חוזרת על עצמה, יש לפנות לתמיכה.",
              departmentAlreadyExists: "כבר קיימת מחלקה באותו השם",
              editDepartment: "עריכת מחלקה",
              enterANewName: "שם מחלקה חדש",
              inputPlaceHolder: "שם מחלקה",
              pleaseEnterAValue: "חובה להזין ערך",
              delete: "מחיקה",
              deleteDepartmentConfirm: "האם אתה בטוח? כל הפריטים שבמחלקה זו יהפכו להיות נעולים וללא מחלקה.",
              departmentSortOrder: "Position in the main menu",
              departmentSortOrderCantBeNegative: "Position in the main menu can't be negative" ,
              positionInMenu: "Position in Menu",
            },
            superFriendsCustClub: {
              title: "Super Friends",
              searchCustomerPlaceholder: "לפי ת.ז/כרטיס",
              formNumber: 'טופס הרשמה',
              mustEnterCustomerNumber: "חובה להזין מזהה/כרטיס",
              mustEnterFormNumber: 'חובה להזין מספר טופס הרשמה',
              customerDetails: 'מצב לקוח',
              pointsForUse: 'נקודות למימוש',
              searchCustomerByCellularPlaceholder: 'לפי טלפון',
              searchLabel: "חיפוש לפי ת.ז/כרטיס",
              searchByCellularLabel: "חיפוש לפי טלפון",
              orSearchBy: 'או',
              cannotAddItemClubCustomerNotFound: 'לא ניתן להוסיף פריט מועדון לא נמצא לקוח מועדון במכירה',
              cannotAddItemClubNewMemberMoreThanOne: 'לא ניתן להוסיף פריט הצטרפות למועדון יותר מפעם אחת במכירה',
              cannotAddItemClubNewMemberOnExistsMember: 'לא ניתן להוסיף פריט הצטרפות למועדון ללקוח קיים',
              cannotAddItemClubRenewMemberOnNewMember: 'לא ניתן להוסיף פריט חידוש מועדון ללקוח חדש',
              cannotAddItemClubRenewMemberMoreThanOne: 'לא ניתן להוסיף פריט חידוש מועדון יותר מפעם אחת במכירה',
              cannotAddItemClubRenewMemberOnBlockCustomerClub: 'לא ניתן להוסיף פריט חידוש מועדון לסוג מועדון זה',
      customerPromotionAmountExeeded: 'The maximum buy limit with the customer club promotions is {promotionsLimit} and the total purchase is {totalPurchases}. The current sale with discounts can be up to {allowedAmount}. The sale is not permitted. Please cancel the promotions or remove items from the sale.',
              scanCardForCustomer: 'Scan Customer Club Card',
              numberAndAssignment: 'Number And Assignment',
              incompleteCustomerData: "לא ניתן לבחור את הלקוח, יש לבצע הרשמה/עדכון פרטים באמצעות הצטרפות דיגיטלית",
              clubsNames: {
                '13': 'S. אתרי סחר',
                '15': 'C. אתרי סחר',
                '19': 'VIP',
                '20': 'SUPER FRIENDS',
                '32': 'אתרי סחר',
                other: 'לקוח',
              }
            },
            positiveDeliveryCustomerClub: {
              card: 'Card/Number',
              id: 'ID',
              name: 'Name',
              phone: 'Phone',
              birthdate: 'Birth date',
              birthdateShort: 'Birth',
              chooseCustomer: 'Choose Customer',
              previousTransactions: 'Previous Orders',
              noCustomersFound: 'Customers not found',
              requiredField: 'Required Field',
              firstName: 'First Name',
              lastName: 'Last Name',
              deliveryAddress: 'Delivery address',
              email: 'Email',
              customerGroup: 'Customers group',
              additionalPhone: 'Additional phone',
              customerRemarks: 'Customer remarks',
              hakafaPaymentCustomer: 'Customer pay with hakafa',
              city: 'City',
              street: 'Street',
              addressNickname: 'Address nickname',
              house: 'House',
              entrance: 'Entrance',
              floor: 'Floor',
              apartment: 'Apartment',
              mainAddrress: 'Main address',
              deleteAddress: 'Delete address',
              selectThisAddressToDelivery: 'Choose this address for delivery',
              existingCustomer: 'Existing customer',
              addNewCustomer: 'Add new customer',
              addCustomer: 'Add customer',
              searchCustomer: 'Search customer',
              search: 'Search',
              fullSearch: 'Full search',
              searchParams: 'By ID/name/phone',
            },
            positiveDeliveryPreviousOrders: {
              title: 'Previous Orders',
              time: 'Time',
              address: 'Address',
              itemsQty: 'Items Count',
              sum: 'Sum',
              actions: 'Actions',
              selectOrder: 'Select Order',
              show: 'Show',
            },
            positiveDeliveryTableView: {
              code: 'Code',
              name: 'Name',
              qty: 'Qty',
              price: 'Price',
            },
            pluxeeView: {
              selectCompany: "Select Company",
              cardNumber: "Card Number",
              card: "Card",
              price: "Price",
              clearFields: "Clear Fields",
              swipeOrInputCard: "Swipe card or input number",
              caveretPaymnetMethods: "Payment Methods",
              checkBalance: "Check Balance",
              noCompanySelected: "No Company Selected",
              cardInputEmpty: "Card number empty",
      currentBalance: "Current balance: {balance}₪",
              checkingBalanceLoading: "checking balance...",
              err : {
                '1':'שגיאה כלית',
                '2':'שגיאה כללית ',
                '3':'גירסה שגויה לא ניתן להזמין',
                '4':'מספר עובד שגוי לא ניתן להזמין',
                '5':'מספר חברה שגוי לא ניתן להזמין',
                '6':'אין תקשורת פנה ל 1-700-70-11-30',
                '7':'בעיית תקשורת נסה שנית',
                '8':'התשלום בוצע NULL',
                '9':'ההזמנה בוצעה ללא אישור',
                '10':'ניתן להזמין ע"י כרטיס בלבד',
                '11':'מכשיר לא מזוהה NULL',
                '12':'תקלה פנה ל 1-700-70-11-30',
                '13':'קוד שגוי פנה ל  1-700-70-11-30',
                '14':'ההזמנה לא בוצעה חריגה בזמנים',
                '15':'ההזמנה לא בוצעה חריגה בהזמנות',
                '16':'ההזמנה לא בוצעה חריגה בסובסידיה',
                '17':'ההזמנה לא בוצעה מסעדה לא מאושרת',
                '18':'לא ניתן לבטל מספר הזמנה שגוי',
                '19':'כרטיס שגוי NULL',
                '20':'משתמש לא מאושר לא ניתן להזמין',
                '21':'משתמש לא רשאי לבטל הזמנה ',
                '22':'חריגה מהתקציב ',
                '23':'עסקה ללא סכום ',
                '24':'קוד הארוחה שנבחר אינו פעיל ',
                '25':'שגיאה לא ידועה פנה ל 1-700-70-11-30 ',
                '35':'שגיאה - דחית פוליסה ',
                '36':'שגיאת מסעדה ',
                '37':'שגיאה - מגבלת מפעיל ',
                '38':'שגיאה - נעול ',
                '39':'POS_ERR_REST_LOC לא בשימוש',
                '40':'PCELL – כתובת IP של הקופה לא מורשה. '
              }
            },
            positiveDeliveryOrderDetail: {
              ordererName: 'Orderer name',
              ordererPhone: 'Orderer phone',
              customerDetails: 'Customer Details',
              name: 'Name',
              phone: 'Phone',
              address: 'Address',
              changeAddAddress: 'Change / Add address',
              deliveryRemarks: 'Delivery Remarks',
              ordererDeliveryRemarksPlaceholder: 'This text is saved on the sale and printed on the invoice',
              ordererCallerRemarks: 'Orderer caller remarks',
              ordererCallerRemarksPlaceholder: 'This text is saved on the sale',
              previousOrders: 'Previous orders',
              shouldCompensate: 'This customer should be compensated!',
              compensation: 'Compensation',
              title: 'Order Details',
              titlePickCustomerMode: 'Customer Details'
            },
            positiveDeliveryShowOrder: {
              customerName: 'Customer Name',
              ordererName: 'Ordere Name',
              phone: 'Phone',
              city: 'City',
              street: 'Street',
              addressNickname: 'Address nickname',
              houseNumber: 'House',
              entrance: 'Entrance',
              floor: 'Floor',
              apartment: 'Apartment',
              address: 'Address',
              paymentType: 'Payment Type',
              sum: 'Sum',
      tenbisCertificate: "Tenbis (Invoice number {splitInvoice})",
      pluxeeCertificate: "Pluxee (Invoice number {splitInvoice})",
      goodiCertificate: "Goodi (Invoice number {splitInvoice})",
              selectOrder: "Select Order",
            },
            positiveCustClub: {
              renew:{
                header:"Renewing Customer Club",
                isCustomerAgreed:"Is a customer interested in renewing the club?"
              },
              comments: "Comments",
              seeComments: "See comments",
              title: "POSITIVE Customer Club",
              existingCustomer: "Existing Customer",
              addNewCustomer: "Add New Customer",
              searchLabel: "Find Customer",
              searchPlaceholder: "By ID/Phone/Name",
              searchPlaceholderWithNoName: "By ID/Phone",
              search: "Search",
              idCard: "Card No",
              ID: "ID",
              name: "Name",
              phone: "Phone",
              dateOfBirth: "Date of Birth",
              validUntil: "Valid Until",
              points: "Points",
              selectCust: "Select Customer",
              pastTransactions: "Past Transactions",
              firstName: "First Name",
              lastName: "Last Name",
              dateOfBirthYear4Digits: "Date of Birth (Year in 4 Digits)",
              email: "Email",
              customerGroup: "Customer Group",
              customerDiscountPercent: "Discount Percent",
              totalPoints: 'Total Points',
              addCustomer: "Add Customer",
              noCustomersFound: "No Customers Found",
              canWritePhoneIfNoCard: "Enter a phone number if no card is used",
              findingCustomer: 'Searching Customers - Please Wait',
              checkingIfCustExists: 'Checking if customer already exist',
              mustEnterFirstName: "Please enter a first name",
              mustEnterCardNumber: "Please enter an id / card number",
              mustEnterLastName: "Please enter a last name",
              toLongTextInput: "Customer number is too long ",
              mustEnterPhone: "Please enter a phone number",
              discountPercentMustBeNumericOrEmpty: "Discount percent has to be either a number or an empty value",
              mustEnterTZ: "חובה להזין תעודת זהות",
              TZIsNotValid: 'תעודת זהות לא תקינה',
              emailInvalid: 'כתובת מייל לא תקינה',
              phoneInvalid: 'טלפון לא תקין',
              createCustomer: 'מקים לקוח...',
              errorOnAddCustomer: 'שגיאה כללית בהוספת לקוח נסה שנית במידה וחוזר צור קשר עם התמיכה',
              errorCannotCreateCustomerOnOffline: 'לא ניתן להקים לקוח שהקופה במצב אופליין',
              companyHasOnlineOnlySearch: "Customer club is available only in online mode in this company - please check you network connection",
              posHasOnlineOnlySearch: "Customer club is available only in online mode in this POS - please check you network connection",
              errorCannotSelectCustomerForDeliveryOnOffline: "לא ניתן לעשות משלוח שהקופה באופליין",
              extensionValidUntil: 'הארכת תוקף',
              extensionValidUntilModalTitle: 'הארכת תוקף לקוח מועדון',
      extensionValidUntilModalMessage: 'תוקף הלקוח יוארך לתאריך {date}, האם להמשיך?',
              cannotExtensionValidUntil: 'לא ניתן להאריך תוקף ללקוח שהוא כבר בתוקף',
              errorExtensionValidUntil: `שגיאה כללית בהארכת תוקף לקוח מועדון.
              נסו שנית. במידה והשגיאה חוזרת על עצמה יש ליצור קשר עם התמיכה`,
      customerExists: "There is already a customer with this Card No ({first_name} {last_name})",
              cannotAddNewCustomerOnCreditSale: 'לא ניתן להוסיף לקוח כאשר יש מכירת זיכוי.',
              updateCustomerComments: "Update Comments"

            },
            valueCardCustClub:{
              title: "Value-Card's Customer Club",
              existingCustomer: "Existant Customer",
              addNewCustomer: "Add a new customer",
              searchLabel: "search customer",
              searchPlaceholder: "By ID/Card/Name/Phone",
              swipeCard: "Swipe Club Card",
              addCustomer: "Add Customer",
              selectCust: "Select Customer",
              idCard: "Id/Card",
              canWritePhoneIfNoCard: "You can write by Phone/ID if there's no card",
              custEmail: "Email",
              custBirthDay: "BirthDay",
              custMarriageDate: "Marriage Date",
              address: "Address",
              postalCode: "Postal Code",
              remarks: "Remarks",
              card: 'Card',
              balance: "Balance",
              messageForCustomer: "Message For Customer",
              customerNumberCard: "Customer/Card number",
              searchingCustomerPleaseWait: 'Searching customer - Please wait',
              apiKeysAreMissing: 'Value Card Settings are missing - please call support',
              budgetOrPoints: 'Points/Budget',
              invalidValuecardPoints: "Error: The number of points is not valid. Please enter a valid number of points.",
            },
            simplyClubCustClub: {
              title: "Customer Club - Simply Club",
              existingCustomer: "Existing Member",
              addNewCustomer: "Add new Member",
              searchLabel: "Search Member",
              balance: "balance",
              messageForCustomer: "Message for member",
              addCustomer: "Add member",
              updateCustomer: 'Update member',
              selectCust: "Select member",
              sendSmsToAddCustomer: 'Send SMS Join registration',
              searchingCustomerPleaseWait: 'Searching member - please wait',
              searchPlaceholder: 'search via name/phone/ID/card.',
              writePhoneHereToSendSms: "Write here phone number to send SMS",
              idCard: 'Card Number',
              custBirthDay: "Birthday",
              custMarriageDate: "Anniversary",
              custEmail: "Email",
              sendSmsToJoinInstead: "Send SMS to join instead",
              sending: "Sending...",
              ifSendSms: 'Can send SMS?',
              ifSendEmail: 'Can send Emails?',
              sentCheckSms: 'Sent succeessfully, plese notify the customer',
              memberNotFoundSentJoinSms: 'Member not found, sent member register SMS with the searched phone number',
              addedCustomerSuccessfully: 'Added member successfully',
              updatedCustomerSuccessfully: 'Updated Member successfully',
              memberExpiredDoYouWantToRenew: 'Membership expired, would you like to renew?',
              expireDate: 'Expire Date',
              lastSaleDate: 'Last Sale Date',
              benefits: 'Benefits',
              points: 'Points',
              discounts: 'Discounts',
              accumulating: 'Accumulatables',
              errField: {
                firstNameRequired: 'First name required',
                lastNameRequired: 'Last name required',
                mobileRequired: "(Cell) Phone required",
              },
            },
            simplyClubBenefits: {
              title: 'Simply Club benefits',
              continueWithoutBenefits: 'Continue without benefits',
              cardBalance: 'Card Balance',
              overallInitialPrice: 'Original Total',
              overallBenefitsSelected: 'Overall selected benefits',
              overallPrice: 'Overall after discount & cashback',
              noBenefits: 'No benefits',
              benefitsPointsCannotBeAbovePrice: "Overall points cannot be above the sale's price",
              cols: {
                benefitName: 'Name',
                notes: 'Notes',
                mimush: 'Used',
                maximumMimush: 'Maximun value',
                unitVal: "Value's worth",
                overallBenefit: 'Overall',
                useDiscount: 'Use discount',
                discountWorth: 'Discount worth',
              },
              errorOccuredWhileClosingSale: "An error occured when trying to process simply club sale. Please contact support",
              checkingBenefits: 'Checking customer benefits, please wait...',
            },
            employeeHours: {
              title: "Employee hours",
              hoursForMonth: "שעות בחודש זה",
              addEntry: "הוספת כניסה",
              addExit: "הוספת יציאה",
              addBoth: "הוספת כניסה ויציאה",
              printReport: "הדפס דוח שעות",
              deleteConfirm: "האם אתה בטוח שברצונך למחוק רשומה זו?",
              date: 'תאריך',
              day: 'יום',
              entrance: 'כניסה',
              exit: 'יציאה',
              manualUpdate: 'עדכון ידני',
              totalHours: 'סה"כ שעות',
              actions: 'פעולות',
            },
            addon: {
              networkError: "Network error",
              versionIncorrect: "Addon error/App version error - For windows, Please validate the addon installation and the version, and upgrade to the last version. For android, Please update the application."
            },
            ett: {
              title: "Time Clock",
              employeeCard: "Employee Code",
              whoIsInShift: "Who is in shift?",
              enter: "Enter",
              exit: "Exit",
              performingEntryExit: "Writing Entry/Exit...",
              cannotPerformTimeTrackWhileOffline: "POS is offline. \n Cannot perform time track while offline. \n Please write your hours manually and inform your manager.",
              fingerPrint: "Fingerprint",
              managerApprovalSms:"SMS To Manager",
              toManager: "To Manager",
              employeeNotDefined: "Employee not defined",
              employeeNotDefinedOrInactive: "Employee not defined or inactive",
              exitWithoutEntry: "Exit without entry",
              entryWithoutExitInvalid: 'Time entry already exists at {dateTime}',
              missigPreviousEntryNotification: 'Missig previous entry {name} {dateTime}',
              description: "Swipe or type employee magnetic card",
              descriptionRoshemet: "Type employee card or press 'Swipe card'",
              emptyMagneticCard: "Please swipe or type employee card",
              emptyMode: "Choose between Entry or Exit",
              employeeNotSynced:'Employee synchronization to the server has failed, <br/> please check that the POS is online and try again',
              swipeCard: 'Swipe card',
              swipeManagerCard: "Swipe manager card",
            },
            ettBreakTime: {
              title: "Time Clock",
              descriptionMain: "a New entry was made less than two hours after the last exit",
              descriptionSec: "Did you return from a break or is it a new shift?",
              resumeShift: "Resume shift",
              newTimeTrack: "New shift",
              shiftStartTime: "Shift starting time",
              currentBreakTime: "Current break time",
              totalBreakTime: "Total shift break time"
            },
            customers: {
              internalClub: "Positive Loyalty Club",
              creditClub: "Credit Customers",
              customerDiscount: "Customer Discount",
              simplyClub: "Simply Club",
            },
            loadingSoftware: "Starting Application...",
            cachingItems: "Doing some important POS stuff...",
            conversionFailed: "המרת מסד נתונים נכשלה. לא ניתן להמשיך בעליית קופה. חובה לפנות לתמיכה",
            currencies: {
              USD: "USD",
              ILS: "ILS",
              EUR: "EUR",
              GBP: 'Pound Sterling',
              AUD: 'Australian Dollar',
              CAD: 'Canadian Dollar',
              CHF: 'Swiss Franc',
              DKK: 'Danish Krone',
              EGP: 'Egyptian Pound',
              JOD: 'Jordanian Dinar',
              JPY: 'Yen',
              LBP: 'Lebanese Pound',
              NOK: 'Norwegian Krone',
              SEK: 'Swedish Krona',
              ZAR: 'Rand',
              printer: {
                title: "Currency Details",
                currency: "Currency",
                sum: "Total",
                sumIn: "Total in ",
              }
            },
            homepage: {
              pos: "POS",
              cashier: "Cashier",
              timeClock: "Time Clock",
              reports: "Reports",
              xreport: "X Report",
              technicianSettings: "Technician Settings",
              zreport: "Z Report",
              bandDeposit: "Bank Deposit",
              dbUpdate: "Update Catalog",
              vendingMachineInventoryUpdate: 'Update Inventory',
              resetVendingMachine: "Reset Vending Machine",
              cashierStatement: "Cashier's Statement",
              parameters: "Parameters",
              itemManage: "Item Manage",
              employeeManage: "Employee Management",
              cashManagement: "Cash Management",
              hakafaCustomersManage: "Credit Customers",
              startPosStatement: "Start Pos Statement",
              cashierStatementError: "לא ניתן לעבוד על הקופה ללא ביצוע הצהרת פתיחה. האם לבצע הצהרת פתיחה?",
              cashierStatementZError: "לא ניתן לסגור את קופה ללא ביצוע הצהרת סגירה. האם לבצע הצהרת סגירה?",
              doCashierStatement: "בצע",
              alreadyOpened: "קיימת הצהרת פתיחה בקופה היום",
              caveretShortcut: "תוכנת כוורת",
              caveretStarterRunning: "מפעיל תוכנת כוורת",
              caveretStarterLoading: "ממתין לעליית התוכנה",
              caveretStarterFailed: "הרצת התוכנה נכשלה. נא ליצור קשר עם התמיכה הטכנית",
              employeeNotFoundOrNoExternalId: "העובד לא נמצא במערכת או שאין לו זיהוי חיצוני\nנא לעשות עידכון מידע ולנסות שוב",
              employeeNotInSystem: "הקופאי לא נמצא בקופה\n נא לעשות עידכון מידע ולנסות שוב",
              poweroff: "Power Off",
              closePos: "סגירת קופה",
              remoteAssistence: "Remote Assistance",
              openFlight: "Open Journey",
              closeFlight: "Close Journey",
              openLeg: "Open Leg",
              closeLeg: "Close Leg",
              disconnectFlight: "Disconnect Journey",
              currencyCalculator: "Currency Calculator",
              activateSelfServicePos: 'Activate Self Service',
              deactivateSelfServicePos: 'Deactivate Self Service',
              outOfStockItems: 'Manage locked items',
              lockSelfServicePos: 'Lock pos',
            },
            employeeManageEdit: {
              titleEdit: "Edit employee",
              titleAdd: "Add employee",
              employeeName: "Employee name",
              managerCard: "Manager card",
              tz: "Personal ID",
              isManager: "Is manager",
              isCashier: "Is a Cashier",
              isLocked: "Is locked",
              saveAndExit: "Save and exit",
              exitWithoutSave: "Exit without save",
              errorCheckValues: "Error check values",
              nameCannotBeEmpty: "The name is required",
              cardAlreadyUsed: "כרטיס עובד כבר נמצא בשימוש",
              nameCantBeEmpty: "The name is required",
              cardCantBeEmpty: "חובה להזין כרטיס עובד",
              cannotLockEmpInShift: "לא ניתן לנעול עובד שנמצא כרגע במשמרת",
              cannotRemoveLastManager: "חובה לסמן לפחות עובד אחד במערכת כמנהל - אחרת לא יהיה ניתן לחזור למסך ניהול העובדים",
              phone: "Phone Number",
              email: "Email Address",
              magneticCardError: "קיימים תווים לא חוקיים בכרטיס מגנטי"
            },
            employeeManage: {
              title: "Employee Management",
              showEmployee: "Show employee:",
              searchEmployee: "Search employee:",
              nameOrEmployeeNumber: "Name or employee number",
              employeeName: "Employee name",
              id: "Id",
              isManager: "Is Manager",
              actions: "Actions",
              yes: "yes",
              no: "no",
              edit: "edit",
              employeeHours: "Employee hours",
              addEmployee: "Add employee",
              printNewReport: "Print new report",
              back: "Back",
            },
            employeeInStore: {
              title: "Hours Report",
              employeeName: "Employee Name",
              entryTime: "Entry Time",
              currentShiftLength: "Shift Length",
              noRecordsFound: "No Records Found",
              close: "Close"
            },
            cashierStatement: {
              closeConfirmation: "Are you sure you want to exit? please notice, the data won't be save",
              zDifferenceProblem: "The statement amount is diffrent from the pos amount",
              zDifferenceProblemOk: "Approve Statement",
              zDifferenceProblemCancel: "Repair Statement",
              zReportTitle: "Z Report",
              statementTitle: {
                enter: "Open Pos Statement",
                Z: "Close Pos Statement",
                exit: "Cash Statement",
                add: "Add Cash To Pos",
                remove: "Remove Cash From Pos"
              },
              errorLoadCashierStatementLogs: 'שגיאה בטעינה הצהרות מזומנים יש ליצור קשר עם התמיכה',
            },
            qtyDialog: {
              title: "Insert Item Quantity",
              desc: "Please enter a new item quantity",
              cannotApplyDecimalQtyToRegularItem: "Cannot apply decimal quantity to a regular item"
            },
            priceDialog: {
              title: "Enter price",
              desc: "Please enter item price",
              priceForLoad: "Please enter the amount of money to load",
            },
            rikuzZDialog: {
              print: "Print",
              cancel: "Cancel",
              title: "Unified Z Report",
              fromDate: "From Date",
              toDate: "To Date",
              byFirstSale: 'תאריך ה-Z הוא לפי המכירה הראשונה ב-Z',
              sendMail: "Send Mail",
              badDateRange: "The date range must be within a year",
              inProccess: "Generation Report",
              offLine: "The pos must be online for this report",
              toLargerThenFrom: "To Date must be after From Date",
            },
            unifiedEmployeesReportDialog: {
              badDateRange: "The date range must be within a year",
              inProccess: "Generation Report",
              offLine: "The pos must be online for this report",
              toLargerThenFrom: "To Date must be after From Date",
              systemError: "System Error, Please Contact Support",
              noData: "There are no data for this date range",
            },
            hakafaInvoicesDialog: {
              invoiceNumber: "מספר חשבונית",
              date: "תאריך חשבונית",
              amount: "סכום חשבונית",
              stillLeftToPay: "נותר סכום לחיוב",
              loadingInvoices: "טוען חשבוניות",
              offlineMode: "לא ניתן לבצע תשלום חוב הקפה של חשבוניות במצב נתק",
              noMoreLeftToPay: "לא נותר סכום לתשלום",
              choose: "בחר",
              title: "סגירת חשבוניות",
              ok: "OK",
              cancel: "Cancel",
              paid: "שולם",
              totalAmountPaid: `סך הכל חשבוניות ששולמו`,
              positiveBalance: "נותר סכום להתאמה",
              totalInvoicesPaid: `כמות חשבוניות ששולמו`,
              desc: "בחר את החשבוניות שברצונך לשלם עבורן",
              amountLeftToPay: "יתרת סכום להתאמה",
              amountLeftToPayInvoice: "יתרה לתשלום",
              amountMoreThanHakafa: "לא ניתן להזין סכום גדול מסך החוב",
              noInvoicesChecked: "לא נבחרו חשבוניות",
              totalToPay: 'Total To Pay',
              amountIsHigherThanLeftToPay: "The inserted amount is higher than the left to pay amount",
              amountCannotBeNegative: "The inserted amount cannot be negative",
              customer: "Customer"
            },
            dtsCustomerClub: {
              title: "מועדון לקוחות DTS \ נולדג'",
              cardNo: "כרטיס",
              search: "חיפוש",
              swipe: "העבר כרטיס",
              swipeCard: "העבר כרטיס",
              cancel: "Cancel",
              fromClub: "ממועדון",
              itemCode: "קוד הטבה",
              choose: "בחר",
              pay: "אשר בחירה",
              itemDescription: "תיאור הטבה",
              itemAvailableQty: "כמות מימושים",
              itemQtyToUse: "כמות למימוש נוכחי",
              insertCardNo: "העבר או סרוק כרטיס לקוח",
              customer: "לקוח",
              chargingCard: "מחייב כרטיס",
              error: "שגיאה בתקשורת מול DTS",
              lookingForCustomer: "מחפש לקוח...",
              noItemsInSale: "לא קיימים פריטים במכירה",
      itemNotFoundInPos: "פריט {code} לא קיים בקופה",
              itemsExistsInSale: "קיימים כבר פריטים בחשבון זה, סגור את המכירה הנוכחית ונסה שנית"
            },
            posAlertLog:{ 
              selfServicePinpadConnectionProblem: "No connection to card reader on device number {deviceID}, please check the device connection or contact to support",
              productExpiredDueToTemp: "Product specific expiry due to temperature change in the machine at {storeName} location {deviceID}. Please verify the accuracy of the thermometer. Documentation time: {date}",
              vendingStuck: "Alert! The machine is stuck and products cannot be sold at {storeName}, position {deviceID}. The position is locked. Documentation time: {date}",
              vendingDoorOpen: "Alert! The machine's door is open at {storeName}, position {deviceID}. Documentation time: {date}",
              powerDetectorFaulty: "A problem was detected in the power consumption detector of the machine at {storeName} location {deviceID}. Please verify the detector's accuracy. Documentation time: {date}",
              elevatorMinorFault: "The machine's elevator fails to access a specific product at {storeName} location {deviceID}. Please verify the machine's accuracy. Documentation time: {date}",
              elevatorMajorFault: "A disabling fault was detected in the elevator or product detector of the machine at {storeName} location {deviceID}. Please verify the machine's accuracy. Documentation time: {date}",
              tempControllerFaulty: "A fault was detected in the machine's thermometer at {storeName} location {deviceID}. Please verify the accuracy of the thermometer. Documentation time: {date}",
              vendingTempAboveX: "The refrigerator temperature at {storeName} location {deviceID} has risen above {temp} degrees. Please verify the machine's accuracy"
            },
            caveretPayment: {
              clubChooserDialogTitle: "בחר מועדון לקוחות כוורת",
              customerPickerTitle: "בחירת לקוח כוורת",
              insertCardNo: "העבר או סרוק כרטיס לקוח",
              cardNo: "כרטיס",
              search: "חיפוש",
              pay: "שלם",
              close: "סגור",
              chooseCustomer: "בחר לקוח",
              cancel: "Cancel",
              customer: "לקוח",
              payAll: "שלם הכל",
              chooseBudget: "בחר ארנק לחשבון",
              caveretPayment: "תשלום כוורת",
              untill: "בתוקף עד ",
              balanceText: "ארנקים אפשריים",
              type: "תקציבים",
              amount: "יתרת תקציב",
              toUse: "סכום לשימוש",
              lookingForCustomer: "מחפש לקוח...",
              chargingCard: "מחייב כרטיס",
              pin: "קוד סודי",
              swipe: "העבר כרטיס",
              swipeCard: "העבר כרטיס",
              error: "שגיאה בתקשורת מול כוורת",
              promosUpdated: `שים לב! מבצעים הוחלו על החשבון, להלן החשבון העדכני`,
              totalToPay: `סה"כ נותר לתשלום`,
              moreThanOneCustomerFound: `לא נמצא לקוח במועדון הלקוחות של הקופה`,
              caveretAndLocalCustomerMismatch: `לא נמצא לקוח במועדון הלקוחות של הקופה`,
              tooLittleBudget: `נותר סכום לתשלום`,
              tooMuchBudget: `סכום התקציבים לא יכול להיות גבוה מהסכום הנותר לתשלום`,
              otherPaymentsNotAllowed: "לא ניתן להשתמש בתשלום כוורת בחשבון זה\nקיימים כבר אמצעי תשלום אחרים",
      solobudgetSelected: `לא ניתן להשתמש בתקציב "{name}" עם תקציבים או אמצעי תשלום נוספים`,
              noBudgetsSelected: "לא נבחרו תקציבים",
      tooLittleBudgetAndSolo: `לא ניתן לבצע תשלום חלקי בתקציב "{name}"`,
              emvSwipeCard: 'Swipe Card',
              pleaseSwipeOrTypeCard: 'Please Swipe Or Type Card Number',
              swipeOrTypeCardNumber: 'Swipe Or Type Card Number',
              manyBudgetsError: 'בכרטיס זה קיימים מספר ארנקים, יש לגשת לקופאי',
              pleaseTypePin: 'Please type your Pin code'
            },
            gppPayment: {
              gppPayment: 'Gpp',
              cardNo: "Card Number",
              pleaseSwipeOrTypeCard: 'Please Swipe Or Type Card Number',
              swipeOrTypeCardNumber: 'Swipe Or Type Card Number',
              emvSwipeCard: 'Swipe Card',
            },
            dbErrors: {
              error: "שגיאה",
              hardDiskFull: "שים לב אין מספיק מקום פנוי בדיסק הקשיח. יש לפנות מקום מהדיסק לצורך הפעולה התקינה של הקופה"
            },
            prepInstructions: {
              dialogHeader: "הוראת הכנה כללית",
              dialogDescription: "מלל עבור הוראת ההכנה",
              dialogEmptyError: "חובה להזין מלל'",
              wouldYouLikeChanges: "Would you like some changes?",
              notes: 'Notes',
              ok: 'Finish',
              noChanges: 'No Changes',
              additionalNotes: 'Additional Notes',
              typeHere: 'Type Here',
            },
            pricelist: {
              POSIsOffline: "POS is offline or server cannot be reached. Working in offline mode.",
              searchingForCustomers: "Looking for pricelists....",
              title: "Choose Pricelist",
              customerNumber: "Pricelist Number",
              customerName: "Pricelist Name",
              chooseCustomer: "Choose Customer",
              noPricelistCustomersFound: "No pricelist customer found"
            },
            selfServiceSupermarket: {
              salePerson: "Self Service",
              posHeader: "Scan Item Or Select From Menu",
              managerActions: "Supervisor Actions",
              selectPaymentType: 'Please Select Payment Type',
              managerApprovalTitle: 'This Sale Needs To Be Validated By The Supervisor',
              swipeCard: 'Swipe Card',
              errors: {
                fieldsAreRequired: 'All fields must be filled out'
              },
            },
            selfService: {
              printerError: 'Printer Error: \n {error}', 
              "printerErrorCodes": {
                PRINTER_OFFLINE: "Printer not available",
                PAPER_FEEDING: "Paper feeding error",
                COVER_OPEN: "Printer cover open",
                PRINTER_ERROR: "Printing error",
                CUTTER_ERROR: "Cutter error",
                PAPER_NEAR_END: "Paper near end",
                OUT_OF_PAPER: "Out of paper"
              },
              ototBracelet: 'Bracelet',
              loadCard: 'Load card',
              creditCardShort: "Credit Card",
              creditCardPaymentsShort: "Credit Payments",
              hakafaCustomerPayment: 'Credited Payment',
              creditCard: "Credit Card",
              cash: 'Cash',
              cashPayment: {
                note: "Note that you'll need to go to the main cashier to proceed with the cash payment",
                creatingOrder: 'Creating Order',
                noteAfterConfirm: 'Please go to the main cashier to proceed with the cash payment and to complete the order',
                orderNumber: 'Order number',
                takeReceiptPlease: "Please take the receipt and present it to the cashier",
              },
              pleaseSwipeCard: "Please swipe payment method and wait for approval",
              pleaseSwipeCardNoPayment: "Please swipe card and wait for approval",
              cancelText: "Press the red button on the device to cancel the action",
              pleaseWait: "Please wait...",
              chargingCard: "Charging card",
              amountExceedsTotalAmount: "The amount of voucher exceeds the total amount. \n The amount will be adjusted to the total amount",
              tenbis: "Tenbis",
              pluxee: "Pluxee",
              hakafa: "Employee Card",
              goodi: "Goodi",
              hakafaCustomer: "Hakafa",
              safeCash: 'KashCash',
              chargeableCard: "Chargeable Card",
              balanceMsg: `There isn't enough balance in the card \n
      you have {balance} at your disposal \n
              Would you like to charge the card balance and continue to pay the rest by another payment method?
              `,
              balanceMsgOK: "Charge balance",
              saleperson: "",
              caveret: "Caveret",
              hoger: "Hoger",
              gpp: 'Gpp',
              paymentX: '{payment} Payment',
              insertCardNumber: 'Enter card number',
              howDoYouPay: 'How do you pay?',
              keypadInput: 'Type manually',
              swipeCard: 'Swipe card',
              cardNumber: "Card number",
              cancelSwipeOnPinpad: 'Press on the red button on the card reader in order to cancel the action',
            },
            selfServiceAfterSaleScreen: {
              genericTitle: 'Sale successfully paid',
              thanksForBuying: "Thank You For Buying",
              dontForgetReceiptAndItems: "Remember to take the items and the receipt",
              total: "Total paid",
              printReceiptHeader: "Print Receipt",
              printReceiptContent: "Would you like to print a receipt?",
              yes: "Yes",
              no: "No"
            },
            offlineSalesDialog: {
              title: "Disconnecting Sales Diagnosis",
              invoiceSequenceTitle: "Invoice Sequence",
              errorTitle: "Error",
              copy: "Copy"
            },
            menuSideBar: {
              internetDeliveries: 'Internet Deliveries',
            },
            pos: {
              moveDalpak: 'Move Dalpak',
              collectOtotTickets: 'Print Bracelet',
              reprintOtotTickets: 'Reprint Bracelet',
              activateRfidBracelet: 'Acticate Bracelet',
              purchaseByGamePoints: 'מימוש נקודות וידיאו',
              braceletBalanceCheck: 'Check Bracelet Balance',
              saleRemarks: "Sale Remark",
              finishTime: 'Finish Time Item',
              finishTimeDetails: 'Time Item Details',
              diners: 'Diners',
              dalpak: 'Counter',
              dalapkExit: 'Exit',
              printInvoice: 'Print Invoice',
              withoutPrintInvoice: 'Without Print Invoice',
              toRefund: "Refund",
              loadingSale: "Loading Sale",
              orderDetails: "Order Details:",
              holdingSale: "Holding Sale",
              releasingSale: "Releasing Sale",
              printingBons: "Printing Order",
              selfServiceTableViewEmptyMessage: "Your order is empty \nPlease add an item ",
              sale: "Sale",
              saleActionsMobile: "Sale Actions",
              showItemRows: "Cart",
              showButtons: "Items Menu",
              posPastInvoices: "Past Sales",
              posSearchInvoices: "Search Sale",
              totalForPay: "Total",
              cellularTab: "Repairs",
              hakafaTab: "Deffered Sale",
              posSearchPlaceholder: "Scan an item or type details to search",
              posItemQuerySearchPlaceholder: "סרוק קוד פריט לבדיקת מחיר",
              code: "Code",
              ordererPickupRemarks: "Pick up remark",
              enterOrdererPickupRemarks: "Enter pick up remark",
              ordererPickupRemarksEdit: "Edit pick up remark",
              ordererPickupRemarksDescription: "You can edit the remark.<br>In order to Delete the remark kepp this line empty and click 'continue'",
              dedicatedTo: "Client \n Name",
              nameRequired: "Value is required",
              nameTooLong: "Value can't be more than {maxChars} chars",
              remarkTooLong: "Pick up remark can't be more than {maxChars} chars",
              openDrawer: "Open \n Drawer",
              zeroScale: "איפוס משקל",
              currentWeight: "משקל",
              description: "Desc",
              salesperson: "Sales Person",
              editItem: "Edit Item",
              edit: "Edit",
              delete: "Delete",
              add: "Add",
              reduce: "Reduce",
              changeQty: "Change Quantity",
              reScale: "Get Weight",
              deleteRow: "Delete Row",
              itemDiscount: "Item Discount",
              priceChange: "Price Change",
              nameChange: "שינוי שם",
              inventoryForItem: "Inventory for item",
              returnNote: "Return Note",
              prepInstructions: "Preparation Instructions",
              loading: "Loading...",
              startNewOrder: "Clear Order",
              balanceInquiry: "Balance Inquiry",
              balanceInquiryOtot: "Bracelet Balance Inquiry",
              search: "Item \n Search",
              printCopy: "Print a copy of a Tax Invoice",
              fastPos: "Fast Mode",
              continueToPayment: "Proceed To Payment",
              backToSale: "חזרה לחשבון",
              saleDiscount: "Discount",
              clubMember: "Loyalty \n Plans",
              priceList: "Price \n List",
              deliveryDetails: "Dlivery Details",
              saleActions: "More \n Actions",
              togglePromotionsOn: "Allow sale promotions",
              togglePromotionsOff: "Cancel sale promotions",
              potentialPromotionsExistHeader: "מבצעי חשבון",
              potentialPromotionsExist: "שים לב! ישנם מבצעים שניתן לקבל אם יוסיפו את הפריטים לחשבון:",
              togglePromotions: "P",
              quantity: "QTY",
              total: `Total`,
              changeForCustomer: 'Customer should get {change} {currencySign} in change',
              returnItem: "החזרה",
              package: "אריזה",
              searchPlaceholderRoshemet: "חיפוש פריט",
              openDeliveryOrders: "הזמנות פתוחות",
              assignCouriers: "Assign Couriers",
              closeDeliveries: "Close Orders",
              printBonsAgain: "כל המנות כבר הודפסו, האם להדפיס בון שוב?",
              printBonsAgainTitle: "הדפסת בון",
              noItemsToBePrinted: "אין פריטים להדפסה",
              print: "Print",
              cancel: "Cancel",
              compensation: "Compensation",
              removeCompensation: "Remove Compensation",
              itemsWillBeRemovedMigvanUpdate: "השתנה מגוון, כל הפריטים במכירה הנוכחית ימחקו, האם להמשיך?",
              printBon: "הדפסת בונים",
              printBonAndHoldSale: "Hold Sale & Print",
              printAndExit: "Print & Exit",
              noCouriersFound: "There are no couriers on this shift",
              noItemsInSale: "לא ניתן לסגור מכירה רק עם פריט משלוח או TA",
              allDeliveries: "Orders",
              generalEmployee: "עובד כללי",
              changeQuantityError: "The number requires to be positive or negative and below 10,000",
              changeQuantityMustBePositiveError: "The number requires to be positive",
              priceZeroError: "Price must be bigger than 0",
              noPriceError: "חובה להזין מחיר",
              HTTPError: "תקלת תקשורת\nנסה שנית, אם הבעיה ממשיכה יש לפנות לתמיכה",
              outOfStock: "Sold out",
              itemNotAvailableYet: "הפריט עדיין לא זמין למכירה",
              itemNotAvailableAnymore: "הפריט כבר לא זמין למכירה",
              currencyConversionCalculator: "Currency Calculator",
              currenciesRates: "Currencies Rates",
              pickup: 'Pickup',
              pickupAll: 'Pickup \n All',
              cannotPickupItemsBeyondMaxPrice: "Unable to pickup an item in the shopping cart with a price higher than {price}{currency}",
              payAttention: "Warning",
              bigSaleLawWarning: 'Every sale over 5000 NIS is required by law to contain the customer information. <br /> It is possible to add or create a customer to the sale through the Customer Club button',
              bigSaleLawWarningRoshemet: 'Every sale over 5000 NIS is required by law to contain the customer information. <br /> It is possible to add customer details to the sale through the "Dedicated To" button',
              backToPos: "Go back to pos",
              posNotAvailable:"Pos is not available",
              ItemNotFound: "Item Error: There are items in the order that are no londer available",
              contactSupport: "Please contact support",
              toPaymentScreen: 'Go to payment screen',
              typeBarcode: 'Type Barcode',
              paymentsMinimalQuantityIs2: "מספר תשלומים המינימלי הוא 2",
              keyPadPaymentsTitle: 'אנא בחר את מספר התשלומים',
              sendSmsToCustomerButton: 'send SMS message',
              blockedPosPinPadConnectionFailuresMessage: "There was an error during the withdrawal procedure. please make sure the pinpad is connected to the POS and press Ok to continue",
              cannotRescaleMoreThanOneItem: 'Cannot rescale more than one weightable item at a time. Please select one weightable item.',
              cannotAddDiscountOnNonDiscountableItems: 'Some of the selected items cannot get a discount. Please select only items that can get a discount.',
              cannotChangeQuantiyForSelectedItems: "Cannot change quantity for some of the selected items",
              selectedItemsCount: "Selected",
              selectOnlyOneGroupItemToEdit: "Select only 1 item to edit",
              selectOnlyOneItemToCheckStock: "Select only 1 item to show stock",
              offerSelection: "Offer Selection",
              offerSelectionLong: "Select offers you want to use:",
              selectOffer: "Select Offers",
              noOffers: "No Offers are Found",
              continue: "Continue",
              promotions: "Offers",
              backToInvoice: "Back to Invoice",
              continueToPay: "To Payment",
              successfullyUpdated: "The Offers Have Been Successfully Updated",
              insertBarcode: "Insert Barcode",
              price: 'Price',
              dalpakPage: {
                orderSummary: 'order',
                exitWithNoPrint: "Don't Print",
                goToPayment: 'Payment',
                restaurantItemMenu: 'Menu',
                mainMenuItems: 'Stand-Alone Items',
              },
              dedicated: {
                toName:{
                  placeHolder: "Enter customer name",
                  titleName:"To",
                },
                toPhone:{
                  placeHolder:"Insert Your Phone Number",
                  titleName:"Phone Number"
                },
                errors:{
                  emptyString: "Insert an valid input",
                  userCanceled:"User canceled",
                  notValidPhoneNumber:"The phone number is not valid, Please insert a valid phone number"
                }
              },
              dedicatedToText: {
                placeHolder: "Enter customer name/phone/card",
              }
            },
            sendSmsToCustomerDialog: {
              title: "Insert phone number to send SMS message",
              placeHolder:"Phone Number",
              requiredMessage: "Phone number is required",
              invalidPhone: "Phone number is invalid! please type a new one"
            },
            saleRemarksDialog: {
              title: "Sale Remaeks",
              placeHolder: "Insert remark...",
              toLongText: "You cannot enter more than 255 characters"
            },
            posPaymentButtons: {
              paymentPage:'Payment Page',
              splitPaymentAmount: 'Split Payment',
              splitSalePayment: 'Split Sale',
              tip: "Tip",
              returnCash: "Cash Return",
              returnCreditCard: "Credit Card Return",
              returnCreditVoucher: "Credit Voucher",
              returnOnCredit: "Return on Credit",
              returnOnVoucher: "Return on Voucher",
              missingVoucherMessage:"Voucher not defined", 
              voucherRefundMessage:  "Would you like to approve a voucher refund of {amount} NIS?",
              hakafaRefundMessage:  "Would you like to approve a Credit refund of {amount} NIS?",
              punchCard: "Punch Card",
              invalidVoucherRefund: "A voucher cannot be returned when the refund amount is not equal to the value of the voucher",
              deletePrevCashPayments: "Delete previous cash payment.",
              cash: "Cash",
              creditCard: "Credit Card",
              creditSale: "Sale on Credit",
              beengo: "Beengo",
              tenbis: "TenBis",
              pluxee: "Pluxee",
              check: "Check",
              goodi: "גודי",
              dts: "DTS",
              otot: 'Bracelet',
              internalCard: "Card",
              vouchers: "Vouchers",
              creditVoucher: "Credit Voucher",
              caveretPayment: "כוורת",
              hakafaCardPayment: "Customer Card",
              banx: "banx",
              safeCash: "Kash\nCash",
              yaad: "Internet Payment",
              multipassPolice: "Multipass Points",
              interService: "Inter Service",
              icmega: 'ICMega',
            },
            posSalesPerson: {
              title: "Select Salesperson",
              content: "Select Salesperson",
              tableHeader: "Select Salesperson",
              tableEmptyMessage: "There are currently no employees in this shift. Please make sure that employees have entered into this shift in the Time Clock.",
              approve: "Approve",
            },
            multipass: {
              companyNotAllowed: "No permissions for company",
              emptySaleValidation: "A Load card item must be the only item in a sale",
              additionalItemsValidation: "A Load card item must be the only item in a sale",
              additionalQuantityValidation: "A Load card item must be the only item in a sale",
              noCardSwiped: "No card was swiped",
              deleteItemValidation: "It is not possible to delete the item, please restart the sale",
              changePriceValidation: "It is not possible to change the item's price. please restart the sale",
              checkingCard: "Checking card",
              errorLoading: "Error Charging the card, please try again. if the problem persists contact support",
              multipassError: "There was an error while charging the card",
              multipassErrorCode: "Error code: ",
              loadingCard: "Charging card",
            },
            swipeCardDialog: {
              title: "Swipe or type card number",
              card: "Card: ",
              swipeCard: "Swipe card in pinpad",
              placeholder: "Swipe or type card number",
              noCardError: "No card",
              swipe: "Swipe in pinpad",
            },
            usbDevices: {
              vendorID: "מספר ספק",
              productID: "מספר התקן",
              name: "שם",
              permission: "בעל הרשאה",
              actions: "פעולות",
              close: "סגור",
              title: "התקני USB",
              askPermission: "בקש הרשאה",
              permissionTrue: "יש הרשאה",
              permissionFalse: "אין הרשאה",
            },
            employeeEditHours: {
              title: "Edit hours",
              dateLabel: "תאריך",
              entryTime: "שעת כניסה",
              exitTime: "שעת יציאה",
            },
            employeeReportMonth: {
              startDate: "From Date",
              endDate: "To Date",
              title: "דוח שעות לתאריכים",
              print: "Print Report",
              startDateBiggerThanEnd: "From date must be before to date"
            },
            reportEmployeeTime: {
              fromDate: 'From Date',
              toDate: 'To Date',
              pos: 'POS',
              cashier: "Cashier",
              title: "Employees Hours Report",
              date: 'Date',
              day: 'Day',
              hoursRange: 'Hours Range',
              hours: 'Hours',
              breakTime: 'Break Time',
              totalEmployeeHours: 'Total Hours',
              noEntry: "No Entry"
            },
            periodReport: {
              startDate: "From Date",
              endDate: "To Date",
              title: "Period Report",
              titleEmp: "Employee Report",
              titleMonth: "Monthly Report",
              run: "OK",
              startDateBiggerThanEnd: "From date must be before to date",
              chooseEmployee: "Select Employee",
              noEmployees: "There aren't any active employees",
              chooseMonth: "Select Month",
              print: "Print",
              sendMail: "Send Mail",
              printDetailedReport: 'Print detailed report',
              employeeName: 'Employee Name',
              toDates: "To Dates",
              noFoundSales: 'No Found Sales On Dates Range',
              detailsDebitCreditSales: 'Details Debit Credit Sales',
              so: 'שו',
              receipt: 'Receipt',
              totalIncludeVat: 'Total Include Vat',
              salesReportByItems: 'Sales Report By Items',
              totalCredit: 'Total Credit',
              returnCreditCard: "Return Credit Card",
              totalDeliveryCertificates: 'Total Delivery Certificates',
              totalDefferedTaxInvoices: 'Total Deffered Tax Invoices',
              salesWithoutVat: 'Sales Without Vat',
              totalSalesWithoutVat: `Total Sales Without Vat`,
            },
            periodReportSettings: {
              title: "הגדרת דוחות",
              saveSettings: "שמור שינויים",
            },
            vouchersReport: {
              title: "דוח ממשקים",
              print: "הדפס מכירות",
              printSum: "הדפס סיכום",
              selectVoucher: "בחר ממשק",
              noVouchers: "לא קיימים ממשקים",
              periodTooLong: "לא ניתן לבחור טווח גדול משנה",
              loadingVouchers: "טוען רשימת ממשקים",
              errorLoadingVouchers: "שגיאה בטעינת רשימת ממשקים",
              generatingReport: "מייצר דוח",
            },
            posPastInvoices: {
              salesperson: 'Salesperson',
              printOtotBracelet: "Print bracelets",
              show: "Show",
              print: "Print",
              printInvoice: 'חשבון',
              creditInvoice: "Credit Invoice",
              creditReciept: "Credit Receipt",
              creditTaxInv: "Credit Tax Inv",
              creditShipmentInv: "Cancel Delivery Note",
              invoiceNumber: "Invoice Number",
              shortInvoiceNumber: "Sale Num",
              transmitTaxInvoice: "Transmit Tax Invoice",
              date: "Date",
              cashier: "Cashier",
              quantity: "Quantity",
              total: "Total",
              printBon: "Print Bons",
              Bons: 'Bons',
              send: 'Send',
              customerName: "Customer",
              syncStatus: "Status",
              creditWithdrawal: "ביטול משיכה",
              nextPage: "הבא",
              previousPage: "קודם",
              loading: "טוען חשבוניות",
              invoiceType: "Type",
              legNumber: "Leg Number",
              elalTicketType:{
                both: "TKT EMD",
                tkt: "TKT",
                emd: "EMD",
                type: "Card type",
              },
              invoiceTypes: {
                '1': 'Tax Inv.',
                '2': 'Refund',
                '3': 'Voucher',
                '5': 'Inv.',
                '7': 'Tax Inv.',
                '8': 'C. Tax Inv.',
                '9': 'Shipping',
                '10': 'C. shipping',
                '21': 'Puch c.',
                '30': "Withdrawal",
                '31': "Refund"
              }
            },
            isCreditable: {
              validateCreditGlobalError: 'שגיאה כללית בזיכוי. צור קשר עם התמיכה',
              canNotCreditSaleGiftCardUsed: 'לא ניתן לזכות כרטיס נטען כבר מומש',
              maxDaysToCreditExceeded: 'לא ניתן לזכות חשבונית - החשבונית ישנה יותר מהמקסימום ימים לזיכוי שהוגדר לקופה',
              canNotCreditSalePosOffline: 'לא ניתן לזכות כשהקופה בנתק',
              canNotCreditSaleReceiptOnMinus: 'לא ניתן לזכות קבלה במינוס',
              canNotCreditSale: "Can't refund a sale that was already refunded",
              canNotCreditSaleNoItemsAvailableToCredit: 'Can\'t credit sale, No available items to credit',
              canNotCreditSaleNotSync: 'Can\'t credit sale that was\'t synchronized to the server',
              canNotCreditSaleReceiptLinkedToTaxInvoice: 'לא ניתן לזכות קבלה משויכת לחשבונית מס',
              canNotCreditSaleNoAvailableAmount: "Can't refund a negative sale",
              canNotCreditSalePunchCard: 'לא ניתן לזכות מכירת ניקוב כרטיסייה',
              canNotCreditSaleTaxInvoiceLinkedToReceipt: 'לא ניתן לזכות חשבונית מס משויכת לקבלה',
              titleErrorModal: 'Refund Error',
              canNotCreditWithdrawal: "There is no crediting cash withdrawals",
              canNotCreditSplitPaymentSale: "Can't refund splitted sale payment",
              canNotCreditSaleUsedOnChargableCardLoadBulk: 'לא ניתן לזכות קבלה שבוצע איתה טעינת כרטיסים',
            },
            posPastInvoicesDisplayItems: {
              items: "Items",
              invoiceNumber: "Invoice Number",
              invoiceDate: "Date",
              cashier: "Cashier",
              payments: "Payments",
              salesperson: "Salesperson",
              originalPrice: "Original Unit Price",
              total: `Total`,
              remarkTitle: "Pickup Remarks",
              printReturnNote: "Print Return Note",
              return: "Back",
              customer: "Customer",
              showPayments: "Payments"
            },
            posPastInvoicesDisplayPayments: {
              items: "Items",
              invoiceNumber: "Invoice Number",
              invoiceDate: "Date",
              cashier: "Cashier",
              payments: "Payments",
              salesperson: "Salesperson",
              originalPrice: "Original Unit Price",
              total: `Total`,
              printReturnNote: "Print Return Note",
              return: "Back",
              customer: "Customer",
              emptyPayments: "No Payments",
            },
            posCreditInvoice: {
              refund: 'Refund',
              items: "Items",
              checkingSale: "Verifying that invoice can be credited",
              toCredit: "To Credit: ",
              creditInvoice: "Credit Invoice",
              invoiceNumber: "Invoice Number",
              invoiceDate: "Date",
              cashier: "Cashier",
              payments: "PYMTS",
              salesperson: "Salesperson",
              originalPrice: "Original Unit Price",
              total: `Total to Credit`,
              printReturnNote: "Print Return Note",
              return: "Back",
              customer: "Customer",
              noItemsToCredit: "There are no items to credit in this invoice",
              itemsToCredit: "Items to credit",
              byCreditVoucher: "By Credit Voucher",
              byMoneyBack: "By Money Back",
              selectAll: "Select All",
              replacement: "Replace Items",
              next: "Next",
              itemCode: "Code",
              details: "Details",
              description: "Description",
              quantity: "Quantity",
              beforeDiscount: "Price Before Discount",
              amountToCredit: "Amount to Credit",
              quantityToCredit: "Quantity to Credit",
              paymentType: "Payment Type",
              amount: "Amount",
              amountToReturn: "Amount to Return",
              cancelReturn: "Refund",
              returnPayment: "Credit Voucher",
              change: "Change",
              cash: "Cash",
              credit: "Credit Card",
              regular: "Regular",
              validity: "Valid By",
              phoneNumber: "Phone",
              confirmationNumber: "Conf No",
              actionCanceled: "Action Cancelled",
              creditingVouchers: 'Crediting Vouchers. Please Wait...',
              check: "Check",
              bank: "Bank",
              account: "Account",
              branch: "Branch",
              expiryDate: "Date",
              creditVoucherChange: "Credit Voucher Change",
              creditVoucherPayment: "Credit Voucher Payment",
              creditVoucher: "Credit Voucher",
              notForDisplay: "Not for Display",
              voucherNumber: "Voucher No",
              voucher: "Voucher",
              barcode: "Barcode",
              creditType: "Credit Type",
              emptyPaymentsTable: "There are no payments to credit in this invoice.",
              phone: "Phone",
              cannotCreditLockedItem: "לא ניתן לזכות פריטים שלא קיימים בקופה זו",
              creditVouchers: "מזכה שוברים",
              loadingSale: "Loading Sale",
              cannotBeRefundedWithMoney: "The sale cannot be credited. It is no longer possible to refund it with cash or credit and the use of credit vouchers has been blocked in this POS"
            },
            customerDetailsFill: {
              header: "Customer Details",
              message: "Please enter the customer's details:",
              name: "Name",
              phone: "Phone",
              id: "ID"
            },
            newCreditCard: {
              in: 'in ',
              amount: "Amount",
              regular: "Debit",
              payments: "Payments",
              firstPayment: "First Payment",
              currency: "Curr",
              creditPayments: "Credit Payments",
              isracardGamish: "Isracard Flex",
              visaPlus30: "Visa+30",
              directCharge: "Direct",
              phone: "Phone",
              confirmationNum: "Conf Num",
              cardNumber: "Card Num",
              msrAutomatic: "Automatic",
              msrManual: "Manual",
              debitType: "Type",
              AMOUNT_MUST_BE_BIGGER_THAN_ZERO: "Amount must be greater than 0",
              AMOUNT_MUST_BE_SMALLER_THAN_ZERO: "בהחזר אשראי הסכום חייב להיות קטן מאפס",
              RETVAL_ERROR_OPENING_SERIAL_PORT: "בעיה בחיבור לקורא הכרטיסים - אנא נסה שנית",
              RETVAL_TIMEOUT: "תם הזמן - אנא העבר כרטיס/הקלד מספר כרטיס מחדש",
              GENERAL_ERROR: "שגיאה כללית, אנא נסה שוב. אם הבעיה חוזרת על עצמה יש לפנות לתמיכה",
              EXP_DATE_MISSING: "לא הוקלד תוקף/תוקף לא חוקי",
              PASS_CARD_MANUAL_MESSAGE: 'אנא הקלד מספר כרטיס, תוקף ו-CVV. לביטול יש ללחוץ על הכפתור האדום בקורא הכרטיסים',
              PASS_CARD_AUTOMATIC_MESSAGE: 'Please insert/swipe card in card reader',
              NEED_SIGNATURE: 'יש לחתום על קורא הכרטיסים',
      minAmountForPaymentsNotMet: 'The minimun amount allow for payments is {amount}',
              timeout: "Timeout",
              msr: {
                201: "בעיה בקריאת מספר כרטיס",
                202: "בעיה בהצפנה",
                203: "בעיה בפרמטרים של קורא כרטיסים - אנא פנה לתמיכה",
                204: "קלט לא חוקי - אנא נסה שנית. אם הבעיה חוזרת על עצמה אנא פנה לתמיכה",
                205: "קוד מטבע לא חוקי",
                206: "קוד שפה לא חוקי",
                207: "סוג קלט לא תקין - אנא נסה שנית. אם הבעיה חוזרת על עצמה אנא פנה לתמיכה",
                208: "סוג עסקה לא חוקי",
                209: "לא ניתן להתחיל עדכון תוכנה",
                210: 'הפעולה בוטלה ע"י המשתמש',
                211: "Aborted by the ECR - אנא פנה לתמיכה",
                212: "קוד בקשה לא חוקי - אנא פנה לתמיכה",
                generalError: "שגיאה כללית - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
                noConnection: "There was an error connecting to the pinpad, if the problem persists please contact support"
              }
            },
            posPayment: {
              headerTitle: 'Payment Page',
              cantDeleteOrderVoucher: 'Cannot delete order payment - the payment paid in an external application',
              title: "Payment",
              tip: 'Tip',
              creditCard: "Credit Card",
              cash: "Cash",
              vouchers: "Vouchers",
              creditVoucher: "Credit Voucher",
              check: "Check",
              tenbis: "TenBis",
              pluxee: "Pluxee",
              goodi: "Goodi",
              otot: 'Bracelet',
              caveretPayment: "Caveret Payment",
              multipassPolice: "Multipass Points",
              amount: "Amount",
              resetCash: "Clear Cash",
              resetTip: "Clear Tip",
              barcode: "Barcode",
              companyCode: "CompanyCode",
              confirm: "Confirm",
              selectCompany: "Select Company",
              voucherType: "VoucherType",
              checkBalance: "Balance Inquiry",
              voucherNumber: "VoucherNumber",
              bank: "Bank",
              branch: "Branch",
              account: "Account",
              checkNumber: "Check No",
              dueDate: "Due Date",
              numberOfPayments: "Payments",
              id: "ID",
              guarantorId: "Guarantor",
              manualConfirmationNumber: "Manual Conf",
              clearFields: "Clear Fields",
              due: "Due",
              paid: "Paid",
              remaining: "Remaining",
              back: "Back",
              selectCurrency: 'Select Curr.',
              details: "Details",
              totalAmountCurrencyToPay: 'Remaining {currency}',
              ratePaymentCurrency: 'Currency Rate',
              dueInCurrency: 'Total {currency}',
              detailMultiCurrenciesPayments: 'Paid currency details',
              emptyDetailMultiCurrenciesPayments: "There aren't any paid currencies",
              banx: "banx",
              safeCash: "KashCash",
              yaad: "Internet Payment",
              interService: "Inter Service",
              missingRequiredMixinFields: "Payment View does not have the required fields for mixin to work",
              voucherNotFound: "התו לא נמצא",
              voucherOverNumberCanUseOnSale: 'שובר {name} מוגבל ל-{number} שימושים בחשבון',
              hakafa: "Credit Sale",
              icmega: "icmega",
              loadPayment: 'טוען מסך תשלום',
              pay: "תשלום",
              confirmPay: "Payment",
              paymentPage:"Payment Page",
              cancelPay: "Cancel Payment",
            },
            paymentPage:{
              cardNumber:"Card Number",
              amount:"Amount",
              loading:"Loading Payment Page...",
              pleaseInsertAmount:"Please insert payment amount",
              errors:{
                amountMustBeGreaterThanZero: "Please enter amount greater than 0",
                generalPaymentLeftAmountMandatory: "You cant add payment amount, Payment amount cannot be greater than the left amount",
                fetchDataError:"Payment Fail! There is a problem with payment page, please try again or contact to support",
                pleaseCheckTheMpiMid:"Please check that mid (payment page number) is defined correctly",
                pleaseCheckSettings: "Please check that the payment page settings defined correctly",
                pleaseCancelOrContinue:"Please complete or cancel the manual payment before you switch to another payment method" 
              }
            },
            creditCardPayment:{ 
              cancelPayment:"Canceling Payment...",
              refundPayment:"Refunding Credit Card...",
              gateway:{
                creatingTransmitReport:"Creating Trasmint Report...",
                transmitTxs:"Transmitting Transactions. Please wait for the operation to complete...",
                errors:{
                  slaveTerminalNumberNotSet: "Slave terminal number not set",
                  gatewayZInquireRequest: "There is an error in the request to gateway",
                  trasmitFaild:"The transmit faild. Please try again.",
                  cannotCancelPayment:"Error: Cannot cancel payment that made with gateway",
                  cannotRefundPartialPayment:"Error: Currencly Cannot cancel partial payment that made with payment page",
                  generalMessage:"Errror: Connection problem with a credit credit attempt that was resolved through a payment page, please try again later. If the problem recurs, please contact support",
                  transmitReportNotValid:"Gateway Transmit report is not valid,Please contact support if the problem recurs"

                }
              }
            },
            yaadPayment: {
              required: "reqired field",
              amount: "Amount",
              taz: "ID",
              firstName: "First Name",
              lastName: "Last Name",
              email: "Email",
              phone: "Mobile",
              sendingPaymentLinkToCustomer: "Sending payment link to customer...",
              waitingForCustomerToPay: "Waiting for payment",
              verifyError: "Payment verification failed",
              verifyingPayment: "Verifying payment",
              commitingPayment: "Commiting payment",
              errorCommitingPayment: "Error while commiting payment",
              partialPaymentItem: "Partial Payment",
              discountsItem: "Discounts",
              paymentId: "Payment ID",
              cancelError: "Error while cancelling transaction",
              cancellingTransaction: "Cancelling transaction",
              invalidTaz: "Invalid ID",
              invalidPhone: "Invalid Phone Number",
              invalidName: "First Name Is Missing or Contains Illegal Characheters",
              invalidLastName: "Last Name Is Missing or Contains Illegal Characheters",
              invalidEmail: "Invalid Email",
              invalidAmount: "Invalid Amount",
              dealDoesntExists: "Deal doesn't exists",
              errorSendingSms: "Error in sending SMS message",
      errorCommitingPaymentWithErrorCode: "שגיאה בביצוע חיוב קוד שגיאה: {code}",
            },
            tipPayment: {
              invalidAmount: "Invalid Amount",
      rawAmount: "Total ({currency})",
              percentageAmount: "Total Percantages (%)",
      finalAmount: "Final Amount (Rounded - {currency})",
              resetTip: 'Reset Total',
              tipInCurrency: 'Currency tip',
              tipInPercentage: 'Percentage Tip',
            },
            elal:{
              salesWithoutPNR:{
                cancel:"Cancel",
                ok:"Ok",
                header: "Sale was made without PNR",
                elalSequence:"Elal Sequence Num: {seq}",
              },
              errors:{
                saleWithoutPNR:"Sale PNR data not insered correctly, please try again or contact to support.",
                ticketNumberLength:"Ticket number must be up to 20 characters",
                pnrNotFound: "PNR Not Found, would you like to continue the regardless?",
                somePNRItemsCouldNotBeAdded: "Some PNR items/reseravtions couldn't be added",
                pnrItemsCouldNotBeAdded: "PNR items/reseravtions couldn't be added, would you like to continue the regardless?",
                errorOnAddingPNRItems: 'אירעה שגיאה בעת הכנסת פריטי PNR.',
                cannotAddItemsPNRToSale: 'More items cannot be added after fetching PNR items',
                cannotDeletePNRItem: 'Cannot delete a PNR Item in a PNR Sale',
                cannotChangePricePNRItem: 'Cannot change a price of a PNR item',
                cannotChangeQuantityPNRItem: 'Cannot change a quantity of a PNR item',
                cannotDuplicatePNRItem: 'Cannot duplicate a PNR item',
                cannotEditPNRItem: 'Cannot Edit a PNR item',
                errorNotInGroupContent: "User {username} is not a member of the group that is allowed to operate the cash register. Please contact support.",
                errorLoadingADGroups: "Error accessing EL AL's permissions system. Please close the cash register and try again. If the problem persists, please contact support.",
                groupsErrorHeader: "Permissions error"
              },
              PNRDialog: {
                pleaseEnter: 'Please enter PNR',
                elalSequence:"Elal Sequence: {seq}",
                OrderNumber: "Order Number PNR",
                enterPnrTitle:"Please enter PNR to get data from amadeus",
                cols: {
                  RecId: 'Identifier',
                  cusName: 'Customer Name',
                  FlightNum: 'Flight Number',
                  org: 'ORIGIN',
                  dest: 'Destination',
                  serviceCD: 'Item',
                  serviceDesc: 'Description',
                  price: 'Price',
                }
              },
            },
            updateCatalog: {
              title: "Update Catalog",
              updateCatalogDate: "Last Catalog update was done on",
              statusNotOK: 'Offline catalog is not a valid state. Please click on "Update Catalog" to get the latest Catalog',
              status: "Status",
              back: "Back",
              updateCatalog: "Update Catalog",
              updateCatalogRoshemet: "Update Parameters",
              updateFullCatalog: "Refresh All Data",
              catalogEmpty: "Catalog update was never done on this POS",
              preperingCatalogLoad: "Preparing Catalog Update - Please wait",
              generatingCatalog: "Generating Catalog - Please wait",
              generatingItemListFile: "Generating items file",
              flightsCatalogError: 'There was an error updating the catalog pre-journey, Journey is automatically disconnected from the pos. For more info, Please click the "Update Catalog" button',
              offlineError: "Offline, please make sure you're connected",
              unmatchedDeviceId: "Unmatched Device ID - contact support",
              physicalIdRemoved: "PhysicalId not found, please signout and login to POS",
              unmatchedPhysicalId: "PhysicalId in POS is not the same in Server - contact support",
              generealError: "An HTTP Error occured - please contact support"
            },
            mainHeader: {
              elalSequence:"Elal Sequence Num",
              pos: "POS",
              cashier: "Cashier",
              newCatalogExists: "New Catalog",
              flightId: "Journey Key",
              flihgtName: "Journey",
              legName: "Leg",
              legRoute: "Route",
              totalSales: "Total Sales"
            },
            logoutScreen: {
              logoutFromUser: "Logout from user",
              password: "Password",
              clearId: "Clear ID",
              title: "Logout",
              STATUS_IDLE: "Please enter the password",
              INVALID_PASSWORD: "Wrong password",
            },
            selectCashier: {
              title: "Select Cashier",
              noCashiers: "No Cashiers assigned to this store. Please run Update Catalog.\n If the problem persists - contact support",
              cashierExit: "Cashier Exit",
              lockPos: "Lock POS",
            },
            posSearchInvoices: {
              salesperson: 'Salesperson',
              printBon: 'Print Bons',
              bons: 'Bons',
              send: 'Send',
              invoice: 'Invoice',
              search: 'Seach',
              storeNumber: "Store Number",
              orSelectStore: "Or Select Store",
              fromDate: "From Date",
              noSaleSelected: "No Sale Selected.",
              toDate: "To Date",
              fromAmount: "From Amount",
              toAmount: "To Amount",
              invoiceNumber: "Invoice Number",
              customerName: "Customer Name",
              customerPhone: "Customer Phone",
              paymentType: "Payment Type",
              itemCode: "Item Code",
              cc4digits: "CC 4 Digits",
              phone: "Phone",
              ID: "ID",
              show: "Show",
              print: "Print",
              creditInvoice: "Credit Invoice",
              creditReciept: "Credit Receipt",
              creditTaxInv: "Credit Tax Invoice",
              creditShipmentInv: "Cancel Delivery Note",
              date: "Date",
              credit: "Refund",
              cashier: "Cashier",
              quantity: "QTY",
              total: "Total",
              syncStatus: "Status",
              errorInSearch: "An error occurred while searching",
              store: "Store",
              newSearch: "New Search",
              orderNumber: "Order Number",
              street: "Street",
              city: "City",
              deliveryType: "Delivery Type",
              dedicatedTo: "Dedicated To"
            },
            shipmentInv: {
              splitPaymentForInvoice: "split payment for invoice number",
              minusBecauseSplitTaxPayment: "reduction by tax invoice number",
              minusBecauseSplitShipmentPayment: "reduction by shipment invoice number",
            },
            printing: {
              numberOrder: "Order Number",
              cancelOrderNumber: "Cancel Order Number",
              updateOrderNumber: "Update Order Number",
              notPaid: 'Not Paid',
              counter_new_rest: "Counter",
              fromCounter_new_rest: "From Counter",
              toCounter_new_rest: "To Counter",
              a4printNotSupported: 'A4 Prints not supported on {at}',
              moveItemsDalpak: '***Moving items***',
              fromCounter: 'From Counter',
              toCounter: 'To Counter',
              dalpakMovement: 'Dalpak transfer',
              dalpakMovement_new_rest: 'Counter transfer',
              orderer: 'Orderer Name',
              dinersDetails: 'Diners details',
              dinersNumber: 'Diners',
              totalSittingDiners: 'Total sitting diners',
              totalDeliveriesDiners: 'Total deliveries diners',
              sittingDinersAvg: 'Sitting diners average',
              deliveriesDinersAvg: 'Deliveries diners average',
              deliveriesAvg: 'Delivery average',
              counter: "Counter",
              wifiPrinter: "Wifi Rongta Printer",
              internalSunmiPrinter: "Built In Printer",
              continuePrintingTitle: 'Printing',
              continuePrintingMessage: 'Please cut the paper, and press "OK" to continue',
              promotion: 'Promotion',
              payments: 'Payments',
              terminalName: 'Terminal Name',
              terminalNumber: 'Terminal Number',
              softwareVersion: 'Software Version',
              buisnessNumberAtCreditCompany: 'Credit Business ID',
              transactionDateTime: 'Transaction Date Time',
              cardName: 'Card Name',
              cardNumber: 'Card Number',
              voucherNumber: 'Voucher Number',
              transactionType: 'Transaction Type',
              approvalNumber: 'Approval Number',
              approvalAuthority: 'Approval Authority',
              executionMethod: 'Execution Method',
              applicationIdInChip: 'Application ID In Chip',
              creditType: 'Credit Type',
              atc: 'ATC',
              cardSeqNumber: 'Card Sequence Number',
              firstPayment: 'First Payment',
              paymentsCount: 'Payments Count',
              additionalPayments: "Additional",
              everyOtherPayment: 'Additional Payments',
              businessNumber: 'Business Number',
              printingDateAndHour: 'Printing Date Time',
              salespersonName: 'Salesperson Name',
              order: 'Order',
              orderCancel: 'Order Cancellation',
              itemsAttachmentForTA: "Items Attachment For TA",
              itemsAttachmentForSitting: "Items Attachment For Sitting",
              itemsAttachmentForDelivery: "Items Attachment For Delivery",
              totalPaymeysByMethod: 'Total Payments By Method',
              sum: 'Sum',
              count: 'Count',
              totalPayments: 'Total Payments',
              defferedSalesDetails: 'Deferred Payment Sales Details',
              description: 'Description',
              defferedTaxInvoice: 'Tax Invoices',
              defferedDeliveryCertificate: 'Delivery Note',
              totalDefferedSales: 'Total Deferred Payment Sales',
              price: "Price",
              quantity: "Qty",
              gram: 'gram',
              kilogram: "KG",
              itemNameCode: 'Name/Code',
              salesperson: "Salesperson",
              noShadowPlaceholder: "An error occurred in picture printing initialization. This is probably due to an incompatible browser",
              fromDate: "From date",
              toDate: "To date",
              salesCount: "Withdrawal count",
              withdrawalsAmount: "Total withdrawals",
              feesAmount: "Total fees",
              withdrawlReport: "Withdrawals report",
              invoiceTitle: "Invoice",
              withdrawn: "Withdrawn",
              surcharge: "Fee",
              androidRongtaPrinterError: "בעיה בהדפסה - אנא וודא כי המדפסת מחוברת למכשיר וכי יש לה הרשאה",
              androidPrintingError: "שגיאה בהדפסה\r\n יש לנסות להדפיס שוב לאחר הטיפול בתקלה\r\nחשבוניות ניתן להדפיס ממסך 'חשבוניות קודמות'",
              t2liteconfigerror: "וודא כי המדפסת מוגדרת כמדפסת Rongta",
              credit: "Credit Card",
              hourReport: "Time Entry",
              cutlery: "Cutlery not necessary",
              forDate: "For Date",
              forTime: "For Time",
              left: "Left",
              entered: "Entered",
              entry: "Entry",
              exit: "Exit",
              hours: "Hours",
              totalSales: 'Total Sales',
              declarationOpenPos: "Open Pos Statement",
              declarationPosStatus: "Declare Pos Statement",
              cashByCoinDetails: `Cash Details By Coins`,
              cashByCoinCurrenciesDetails: {
                calcedCurrency: 'Calculated',
                statedCurrency: 'Stated',
                statedAndCalcedGap: 'Gap',
              },
              cashByCoinAndBillsDetails: `Cash Details By Coins And Bills`,
              totalInPos: 'Total In Pos',
              reportForCard: "Card Movement Report",
              date: "Date",
              hour: "Time",
              transactionDetail: "Transaction Detail",
              balance: "Balance",
              currentCurrency: 'NIS',
              bill: "Bill",
              coin: "Coin",
              currency: "Currency",
              POS: "POS",
              cashier: "Cashier",
              xReport: "X Report",
              zReport: "Z Report",
              xReportForPos: 'X Report For Pos',
              posStatusReport: "Pos status Report",
              reportNumber: "Report Number",
              unifiedReport: "Unified Report",
              unifiedReportNumber: "Unified Report Number",
              middleReport: "Middle Report",
              zSalesAnalysis: "Z Sales Analysis",
              cancels: "Cancels",
              canceledItemsNumber: "Canceled Items Count",
              refunds: "Refunds",
              itemsRefundsCount: "Refund Items Count",
              totalDiscounts: 'Total Discounts',
              totalCustomers: 'Total Customers',
              totalDeliveryCertificates: 'Total Delivery Certificates',
              averageCustomerSale: 'Average Customer Sale',
              soldItemsAmount: 'Sold Items Amount',
              averageCustomerAmount: 'Average Customer Amount',
              averageItemPrice: 'Average Item Price',
              salesAbove5000ILS: 'Sales Above 5000 ILS',
              canceledBonnes: 'Canceled Bones',
              canceledBonnesWorth: 'Canceled Bones Worth',
              departmentSalesNet: 'Net Department Sales',
              itemDetailsInZReport: 'פירוט פריטים',
              creditCardTransmittingReport: 'Credit Transmittion Reports',
              total: 'Total',
              vat: 'VAT',
              voucherForItem: "Item Voucher",
              original: "Original",
              copy: "Copy",
              returnNoteNo: "Return Note Number",
              creditVoucher: "Credit Voucher",
              customer: "Customer",
              for: "For",
              customerNumber: "Customer Number",
              customerIdNumber: "custId",
              ID: "ID",
              phone: "Phone",
              producedInInvoice: "הופק בחשבונית",
              producedForInvoice: "הופק עבור חשבונית",
              company: "Company",
              branch: "Branch",
              year: "Year",
              creditAmount: "Credit Amount",
              voucherExpiration: "תוקף הזיכוי הוא לשנתיים מיום מסירתו",
              limitingConditionsForVoucher: "There are no limitations for this voucher",
              amount: "Amount",
              customerName: "Customer Name",
              signature: "Signature",
              totalForPayment: "Total",
              totalPaid: "Total Paid",
              originalTotalForPayment: "Total original amount",
              totalForRefund: "Total",
              totalOwsVat: 'Vatable  Amount',
              totalIncVat:'Total Inc Vatable Amount',
              itemCode: "Code",
              name: "Name",
              invReceipt: "Invoice/Receipt",
              InvReceiptAppended: "Invoice/Receipt Attachment",
              cancelInvReceipt: "Cancel Invoice/Receipt",
              taxInvReceipt: "Tax Invoice/Receipt",
              taxInv: "Tax Invoice",
              taxInvReceiptAppended: "Tax Invoice/Receipt Attachment",
              taxCreditReceipt: "Tax Invoice Credit Receipt",
              taxCreditReceiptAppended: "Tax Invoice Credit Receipt Attachment",
              cancelattionInvAppended: "Invoice/Receipt Cancellation Attachment",
              taxCreditInvoice: "Tax Credit Invoice",
              refundMethod: 'Refund Method',
              invoice: "Invoice",
              orderNumber: "Order Number",
              saleMidBill: "Bill",
              creditInvoice: "Credit Invoice",
              creditTaxInvoice: "Credit Tax Invoice/Receipt",
              deliveryNote: "Delivery Note",
              returnNote: "Return Note",
              cash: "Cash",
              creditCard: "Credit Card",
              creditSale: "Sale on Credit",
              beengo: "Beengo",
              tenbis: "Ten Bis",
              pluxee: "Pluxee",
              check: "Check",
              creditTaxInvReceipt: "Credit Tax Inv/Receipt",
              receipt: "Receipt",
              totalFreeOfVat: 'Vat Free',
              inThisSaleYouSaved: `Total Savings`,
              items: "Items",
              discount: "Discount",
              taxIdNum: "Tax ID",
              companyNum: "Company No",
              noTaxNum: "Exempt Dealer",
              amota: "ע.ר",
              addedCashPos: "Add Open Statement Cash",
              addedCashSum: "Addedd Cash To Open Statement",
              grandTotal: "Grand Total",
              withdrawalSum: "Withdrawn amount",
              manual: 'Manual',
              day: 'Day',
              break: 'Break',
              chargableCard: 'Chargeable Card',
              valueCarChargableCard: 'Chargeable Value-Card',
              interfaces: 'ממשקים',
              time: 'Time',
              printer: 'Printer',
              merakezInvoiceNum: "חשבונית מס ריכוז מספר",
              merakezetInvoiceDate: "Invoice Date",
              documentNumber: "Document Number",
              saleDate: "Sale Date",
              hakafaCustomerBalance: "Your Current Balance",
              salesDiscountReport: 'פירוט הנחות',
              forItem: 'For Item',
              bankDeposit: {
                depositTime: "זמן הפקדה",
                employeeName: "שם המפקיד/ה",
                totalCashZdsSelected: `סה"כ מזומן דוחות זד`,
                totalCashDeclared: `סה"כ מזומן בהפקדה`,
                cashDiff: `סה"כ הפרש`,
                comments: `הערות`,
                zNumber: `מס זד`,
                createdAt:`תאריך`,
                totalAmount: `סה"כ זד`,
                totalZCashAmount: `מזומן בזד`,
                bank: 'בנק',
                branchNumber: 'מספר סניף',
              },
              performa: {
                yes: 'Yes',
                no: 'No',
                askToPrintWithoutItemPricesMessage: "Print the proforma invoice with prices?"
              },
              taxAuthorityConfirmationNumber: "Confirmation Number",
            },
            reports: {
              xReport: "X Report",
              zReport: "Z Report",
              zRikuz: "Unified Z Report",
              employeesHoursCentered: "Unified Employees Hours",
              employeesBonus: "Employees Bonuses",
              employeesHours: "Employees Hours",
              exceptions: "Irregularity Report",
              unified: "Unified Structure Report",
              promotions: "Promotions Report",
              vouchers: "Vouchers Report",
              customerSales: "דוח מכירות מועדון לקוחות",
              hakafaCustomerSales: "דוח מכירות לקוחות הקפה",
              selectXReport: "Select X Report",
              forPos: "For Pos",
              forStore: "For Store",
              generatingXReport: "Generating X Report",
              printLegSalesReport: "Print Sales Report",
              printCurrenciesReport: "Print Currencies Rates Report",
            },
            zxReport: {
              showMultiCurrencyDetails:"Show Currencies Details",
              failedLoadingSales: 'Failed to load sales, Please try again.\n If the problem persists - contact support.',
              totalTips: 'Total Tips',
              total: 'Total',
              reportNumber: 'Z Number',
              zNumber: "Z Number",
              reportDate: "Report Date",
              invoicesCount: "Invoices Amount",
              totalInvoices: 'Invoices Total',
              totalCash: 'Total Cash',
              totalCredit: 'Total Credit',
              totalVat: 'Total Vat',
              producingTime: 'Creation Date',
              status: 'Status',
              statusDate: 'Status Date',
              paymentType: "Payment Type",
              inPosAmount: "Amount In Pos",
              inPosSum: "Sum In Pos",
              voucherType: "Voucher Type",
              producingVouchers: "Vouchers Producing",
              totalPluxee: "Total Pluxee",
              totalTenbis: "Total 10bis",
              totalGoody: 'Total Goody',
              totalDTS: 'Total DTS',
              totalMishloha: 'Total Mishloha',
              totalYaad: 'Total Yaad',
              totalBuyingVoucher: 'Total buying vouchers',
              totalKofaPayment: 'Total Deferred Payments',
              noVouchersInPos: "There are'nt any vouchers in pos",
              noPaymentsInPos: "There are'nt any payments in pos",
              noReportsToRestore: "There are'nt any reports to restore",
              deliveryPayments: 'Total Delivery Payments',
              deliveriesAmount: 'Deliveries Amount',
              outsideCash: 'Outside Cash',
              canceledBonnes: 'Canceled Bones',
              totalPaymentsWithoutOpeningStatement: 'Total Payments Without Opening Statement',
              actualCashInPos: 'Total Actual Cash In Pos',
              totalDeliveryCertificates: 'Total Deliveries Certificates',
              totalDefferedTaxInvoices: 'Total Tax Invoices (Deffered Invoices)',
              showDetails: 'Show Details',
              totalPaidit: 'Total Paidit',
              dalpaks: {
                dalpaksReport: "Dalpaks Report",
                name: "Name",
                amount: "Amount",
                totalDiners: "Number Of Diners",
                totalOpenTables: "Number Of Tables",
                OpenTablesSum: "Total Tables Amount",
                orderSummary: 'Order Summary',
                totalToPay: 'Total To Pay',
              }
            },
            itemDiscount: {
              step1Title: "Step 1: Select Discount",
              step2Title: "Step 2: Select Discount Amount",
              step3Title: "Step 3: Manager Approval",
              setPrice: "Set Price",
              selectedDiscountDetails: "Selected Discount Details",
              approvedPct: "Approved",
              approvedManagerPct: "Mgr Pct",
              specialDiscount: "Special Discount",
              specialDiscountShort: "Special",
              specialDiscountError: "לא מוגדרת הנחת חשבון. לא ניתן לבצע הנחה מיוחדת בקופה ללא הנחת חשבון",
              selectItems: "Select Items",
              discountName: "Discount Name",
              approvedDiscount: "Approved Pct",
              managerDiscount: "Manager Pct",
              confirmedDate: "Confirmed At",
              allowerName: "שם מאשר",
              approverName: "Approver Name",
              customerName: "Customer Name",
              comment: "Comment",
              selectSaleDiscount: "Select Sale Discount",
              noDiscountsWereFound: "No discounts were found.",
              managerCard: "Manager Card",
            },
            employeeStoreConnection: {
              linkEmpsToStore: "Add Employees to Store",
              noAvailableEmps: "No Available Employees for This Store",
              back: "Back",
              addEmpToStore: "Add Employees to Store",
              fingerprint: "Fingerprint"
            },
            params: {
              vorlonSupportStart: "Start Remote Support",
              vorlonSupportStop: "Stop Remote Support",
              pinpadMaintenance: "Pinpad Maintenance",
              androidPinpadMaintenance: "Android Pinpad Maintenance",
              techActions: "Technician Actions",
              transmit: "Transmit to SHVA\\Switch",
              printLastTransmit: "Print Last Transmit",
              skipCashWithdrawalCorrectnessCheck: "Skip withdrawal correctness check",
              updatePinPad: "Update Pinpad Version",
              settings: "Settings",
              posPrinter: "POS Printer Management",
              printerName: "Printer Name",
              scale: "Scale",
              displaySettings: "Display Settings",
              useBigRoshemetLayout: "Use Large Layout",
              manageEmployees: "Manage Employees",
              version: "Version",
              updateEmvIpAddressHeader: "Pinpad IP address update",
              updateEmvIpAddress: "Pinpad IP address has been updated successfully",
              newAddonInstalled: "Addon Installed?",
              addonVersion: "Addon Files Version",
              primaryPosExists: "Store has primary POS",
              isPrimaryPos: "Is POS primary?",
              pinPadPortNum: "PinPad Port Number",
              multipassString: "Multipass string",
              cleanSaleMinusOne: "Clean 'Minus 1' Sale",
              transmitErrorCheck: "Send SHVA Error Log",
              resetLastTimeSaleSyncFailed: "Send Failed Sales To Server",
              selectBonPrinter: "Config bon printers",
              sendEMVLogsToCaspitFTP: "Send EMV Logs to FTP",
              callCaspitTechServer: "Update Caspit Parameters",
              androidAppVersion: "App Version",
              PCLVersion: "PCL Version",
              androidDeviceSerialNumber: "Device S/N",
              posVersion: "POS Version",
              posVersionDate: "POS Version Date",
              validCurrencies: "Valid Currencied Conversion",
              hardwareAndPosInfo: "Hardware & POS Information",
              startPCL: "Start PCL",
              stopPCL: "Stop PCL",
              restartingPinpadConnection: "Restart Pinpad Connection",
              usbDevices: "USB Devices",
              pinpadSerialMode: "Serial Mode",
              pinpadUSBMode: "USB Mode",
              lunchTeamViewer: "launch TeamViewer",
              remoteAssistance: "Remote Assistance",
              useWifiPrinter: "Use WiFi Printer",
              closeFlightWithoutPinpind: "Force Close Journey",
              clearStorage: "Clear Storage",
              storageUsage: "Disk space usage",
              credits: 'Credits',
              openSourceDevs: 'Open Source Developments',
              unblockPosFromPinPadConnectionFailures: 'Release POS from - PinPad connection error block',
              terminalCheck: 'Checking communication with ABS'
            },
            storagemanager: {
              makeSureOnlineBeforeClearStorage: "Make sure you are connected to the internet before proceeding",
              storageUsage: "Disk space usage",
              usage: "Disk space",
              performingCleaningProcess: "Performing cleanning process"
            },
            fullSearch: {
              title: "Search Item",
              searchLabel: "Search by Code/Barcode/Description",
              search: "Search",
              explanation: 'Click on "Select" in the search results to add an item to the Sale.',
              code: "Code",
              description: "DESC",
              price: "Price",
              color: "Color",
              size: "Size",
              supplier: "Supplier",
              barcode: "Barcode",
              actions: "Actions",
              select: "Select",
              promotion: "PROMO",
              inventory: "INV",
              noItemsFound: "No items were found.",
              none: 'None',
              itemPromotionsTitle: 'Item promotions',
              notFoundItemPromotionsTitle: 'Not found item promotions',
            },
            balanceCheck: {
              title: "Balance Inquiry",
              message: "Enter Card",
            },
            shekuloTovBooks: {
              "302": "Barcodes must contain numbers only",
              "304": "No copy found with given barcode, please try another",
              "303": "Barcode length must be between 6 and 8 numbers",
              "305": "Copy is not available for sale",
              "701": "Cannot close sale, please check the barcodes",
              "404": "Barcode doesn't exists, please try another",
              "genericError" : "Error - please try again.\n If the problem persists - contact support.",
              "genericCommunicationError": "There was an error communicating with the books server, please try again.",
              "importingBookInfo" : "Importing book info",
              "outOfStock": "Item is out of stock",
              "sendingSaleInfo": "Updating books inventory, please wait..",
            },
            scanBarcodeDialog: {
              barcode: "Barcode",
              barcodeIsRequired: "Barcode is required",
              barcodeTypeOrScan : "Type or scan barcode",
              qrcode: "QR Code",
              braceletId: 'Bracelet Identifier',
              qrcodeIsRequired: "QR code is required",
              qrcodeTypeOrScan: "Type or scan QR code",
              qrcodeScan: "Scan QR code",
              cardNumber: "Card Number",
              cardIsRequired: "Card number is required",
              cardTypeOrScan: "Swipe card or type card number",
      barcodeNumberOutOf: "Barcode number {indexNumber} out of {totalBarcodes}"
            },
            sms: {
              requestServerMessage: 'Sending request, plase wait...',
              mustBeOnline: "It is not possible to send SMS in offline mode"
            },
            paymentTypes: {
              '0': "Change",
              '1': "Credit Card",
              '2': "Cash",
              '3': "Voucher",
              '4': "Credit Voucher",
              '5': "Check",
              '6': "Discount Voucher",
              '7': "Discount Voucher Issue",
              '9': "Charge VALU Card",
              '12': 'Value Card Charge',
              '30': "Cash Withdrawal",
              '31': "Cash Withdrawal Return"
            },
            invoiceTypes: {
              '1': 'PAyment',
              '2': 'Credit',
              '3': 'Credit Voucher',
              '4': 'Z Report',
              '5': 'Recipt',
              '6': 'דוח Z ראשי',
              '7': 'חשבונית מס',
              '8': 'Tax Credit Invoice',
              '9': 'תעודת משלוח',
              '10': 'זיכוי תעודת משלוח',
              '20': 'פרפורמה',
              '21': 'רכישת כרטיסייה',
              '30': 'משיכת מזומנים',
              '31': 'זיכוי משיכת מזומנים'
            },
            creditCardPaymentTypes: {
              '1': "Regular",
              '2': "Payments",
              '3': "Credit Payments",
              '4': "Isracard Flexible",
              '7': "Visa + 30",
              '8': "Direct"
            },
            accountActions: {
              title: "Additional Sale Actions",
              itemQuery: "Item Query",
              prepInst: "Preparation Instructions",
              holdSale: "Hold Sale",
              restartSale: "Empty Cart",
              releaseSale: "Release Sale",
              printInvoice: "Print Invoice / Proforma",
              cannotHoldSaleWithoutItems: "Cannot hold sale that has no items",
              cannotHoldReleaseSaleWhenPaymentsExist: "Cannot Hold/Release sale that has payments",
              calculator: "Calculator",
              feedPaper: "Feed Paper",
              currencyConversionCalculator: 'Curr. Calc',
              createItem: 'Fast item creation',
              importOrderFromPriority: 'Import order from Priority',
              ExitDalpakWithoutPrintingBons: 'Exit without printing bons',
              customers: "Customers",
              hakafaSale: "Credit Sale",
              openNewRepair: 'Open new repair',
              repairsReport: 'Repairs report',
              mobilePhoneRepairsSettings: 'Mobile phone repairs settings',
              completeOrderViaPayment: 'Complete order via payment',
              saleSittingOrTA: 'Sale Type',
            },
            releaseSale: {
              title: "Release Sale",
              filterLabel: "Filter by Order No/Cashier/Customer",
              noSalesInHold: "There are no sales on hold",
              holdTime: "Hold Time",
              orderNum: "Order No",
              saleAmount: "Amount",
              saleQty: "QTY",
              cashier: "Cashier",
              customer: "Customer",
              actions: "Actions",
              release: "Release",
              delete: "Delete",
              noCustomer: "None",
              loading: "Loading Sales",
            },
            genericItemDescription: {
              name: "תאור",
              price: "מחיר",
            },
            itemQuery: {
              title: "Item Query",
              searchLabel: "Search by Item Code/Barcode/Description",
              noItemsFound: "No items were found.",
              code: "Code",
              description: "DESC",
              price: "Price",
              color: "Color",
              size: "Size",
              supplier: "Supplier",
              barcode: "Barcode",
              moreInfo: "More Info",
              search: "Search",
              select: "Select",
              promotions: "Promotions",
              inventory: "Inventory",
              showPromotions: 'Show promotions',
              none: 'None',
              promotion: 'Promotion',
              validUntil: 'Valid until',
              validUntilShort: 'Valid until',
            },
            loginDialog: {
              changePasswordTitle: "החלפת סיסמה",
              pressToPassCard: "לחץ להעברת כרטיס חוגר",
              adminPassword: "סיסמת מנהל",
              manualPassword: "סיסמה להקלדה ידנית:",
              personalId: "מספר אישי:",
              password: "סיסמה:",
              back: "חזרה",
              lockPos: "נעילת קופה",
              login: "כניסה למערכת",
              userOrPassNotFoud: "The username or password are incorrent",
              blocked: "You are blocked for 30 seconds",
              waitingPinPad: "ממתין להעברת כרטיס העובד במסוף",
              noPermission: "אין לך הרשאה לעבוד בחנות זו",
              loggingIn: "Logging in",
              logout: "Log out",
              notManager: "העובד לא מוגדר כמנהל",
              pinpadError: "בעיה בקריאת הכרטיס",
            },
            caveretPasswordDialog: {
              typePaswordTitle: 'Password',
              password: "Password:",
              userOrPassNotFoud: "Invalid User or Password ",
              noPermission: "You Dont Permission To Work In This Store",
              loggingIn: "Logging in",
              pleaseSwipeCard: 'Please Swipe Card'
            },
            withdrawal: {
              reportError: "There was an error creating the report. if the problem continues please contact support.",
              validationCannotAdd: "לא ניתן להוסיף פריטים למשיכת מזומן",
              validationCannotBeAdded: "לא ניתן לבצע משיכת מזומנים אם יש עוד פריטים בחשבון",
              validationCannotChangeAmount: "לא ניתן לשנות כמות של משיכת מזומנים",
              validationSaleIsStuckWithdrawal: "בעקבות שגיאה במשיכת המזומן הקודמת, לא ניתן להמשיך ולמכור בקופה. נא ליצור קשר עם התמיכה",
              gettingParams: "מקבל נתונים מקורא כרטיסים",
              pickAmount: "אנא הזן סכום למשיכה",
      amountTooSmall: "סכום המשיכה חייב להיות גדול מ {amount} ש\"ח",
      amountTooBig: "סכום המשיכה המירבי יכול להיות עד {amount} ש\"ח",
      amountIncrementProblem: "הסכום חייב להיות בכפולות של {amount} ש\"ח",
              surchargeConfirmation: "הפעולה הבאה תחוייב בדמי שרות כמפורט להלן:",
              waitingForEmv: "ממתין להעברת הכרטיס וקבלת אישור",
              withdrawalSuccess: "המשיכה הצליחה",
              withdrawalRefundSuccess:"Cash withdrawal cancellation completed successfully",
              withdrawalTitle: "משיכת מזומנים",
              refundTitle: "ביטול משיכת מזומנים",
              refundWithdrawal: "החזר מזומן",
              refundSurcharge: "החזר דמי שירות",
              refundPastAllowedDays:"No credit can be made for withdrawals after 7 days of the original transaction.",
              saleIsNotWithdrawal:" The transaction is not a cash withdrawal type",
              saleIsNotRefund:"The transaction is not a cancel cash withdrawal type",
              attention: "שים לב!",
              charge: "חיוב",
              payAttention: "האם לאשר את הפעולה?",
              surchargeTitle: "דמי שירות",
              notEnoughCashInPos: "אין מספיק מזומן בקופה",
              total: "סה\"כ",
              emvError: "שגיאה בקורא הכרטיסים, האם הוא מחובר ומתאים למשיכת מזומנים?",
              noWithdrawalParams: "לא קיימים פרמטרים למשיכת מזומנים. נא ליצור קשר עם התמיכה",
              debitInv: "Tax invoice: ",
              creditInv: "Credit tax invoice: ",
              confirmError: "There was an error confirming the withdrawal, if the error continues contact support",
              noCashWithdrawal: "Cash withdrawal is not configured in this pos",
              withdrawnCount: "Number of withdrawals",
              terminalNo: "Caspomini Terminal:",
              tranNo: "Transaction No.:",
              withdrawalError: "Withdrawal Error or denial",
              paytechTitle: 'השירות ניתן באמצעות חברת פייטק - כספומיני',
              paytechPhone: 'לברורים ~ושירות: 08-9292182',
              atmNo: 'ATM No.:',
              idNo: "Withdrawal No.:",
              chargeTotal: 'Total to be charged',
              cash: 'Cash',
              returnCash: "Cash to give back",
              customerCopy: 'Copy for customer',
              cashierCopy: 'Cashier Copy',
              signature: 'Signature:..................',
              loadingWithdrawalParams: "Loading cash withdrawal parameters",
              checkingCashWithdrawalLastSale: "Checking last cash withdrawal correctness",
              cashWithdrawalLastSaleInvalid: "Cash withdrawal Error - The data is inconsistent between the pos and the pinpad, Please contact support",
              roshemetFastBtnsError: "It is not possible to use the keypad with this item",
              confirmAndSwipeCard: "Press Ok and then swipe card in pinpad",
              refundSuccess: "Withdrawal was refunded successfully",
              cardError: {
                "93": "פנה לסניפך",
                "0": "שינוי נוסח",
                "1": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "4": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "5": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "6": "תקלה זמנית בשירות.",
                "7": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "12": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "13": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "14": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "15": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "19": "סכום משיכה גבוה מהמותר",
                "25": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "30": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "38": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "40": "תקלה זמנית בשירות.",
                "41": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "43": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "51": "סכום משיכה גבוה מהיתרה",
                "52": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "53": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "54": "כרטיס פג תוקף",
                "55": "קוד סודי שגוי",
                "56": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "57": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "58": "משיכה לא אושרה. פנה לתמיכה טכנית.",
                "61": "חריגה מסכום משיכה יומי",
                "62": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "63": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "65": "חריגה מכמות משיכות יומית",
                "75": "קוד סודי שגוי",
                "78": "משיכה לא אושרה. הפנה לקוח לברור בסניף הבנק שלו.",
                "79": "תקלה זמנית בשירות.",
                "80": "תקלה זמנית בשירות.",
                "81": "תקלה זמנית בשירות.",
                "82": "תקלה זמנית בשירות.",
                "83": "תקלה זמנית בשירות.",
                "84": "תקלה זמנית בשירות.",
                "85": "תקלה זמנית בשירות.",
                "86": "תקלה זמנית בשירות.",
                "87": "תקלה זמנית בשירות.",
                "88": "תקלה זמנית בשירות.",
                Z3: "נדחה",
        else: "\nError code: ({code})",
                title: "Action Failed.",
                timeout: "Pinpad connection timeout. Please check the pinpad."
              },
              atmError: {
                10044: "הפעולה בוטלה מהמסופון",
                10099: "נא לנסות שוב",
                10041: "לא מצליח להתחבר למסופון",
                10049: "לא נמצאה עסקה",
                11001: "שגיאה בקריאת הכרטיס",
                10029: "פעולת המשיכה לא נמצאה בקורא הכרטיסים",
                "5037": "הכרטיס שהועבר הוא לא כרטיס בנקאי",

              },
              waitintForPinPadToRecover: "Waiting For The PinPad To Recover",
              waitingForWithdrawalConfirmation: "Waiting For WithdrawalConfirmation...",
            },
            goodi: {
              awaitingResponse: "מתחבר לגודי",
              noCard: "לא הוזן כרטיס",
              error: {
                "1024": "הכרטיס לא קיים במערכת",
                "1000": "הכרטיס לא קיים במערכת",
                "1001": "כרטיס חסום לשימוש",
                "1002": "הכרטיס לא מורשה לעבור בטווח השעות הנוכחי",
                "1003": "כרטיס זה אינו מתאים - אנא פנה לגודי",
                "1004": "לא נמצאה העיסקית המבוקשת",
                "1005": "לא נמצא מחיר קבוע לכרטיס זה",
                "1006": "מספר הארוחות חורג מהמספר המותר",
                "1007": "בקשתך חורגת מהתקציב היומי המותר",
                "1008": "בקשתך חורגת מהתקציב החודשי המותר",
                "1010": "שגיאה בהגדרות הכרטיס",
                "1012": "כרטיס זה אינו פעיל בחנות זו",
                "1013": "שגיאה בהגדרות הכרטיס",
                "1016": "בקשתך חורגת מהתקציב שהוגדר לכרטיס זה",
                "1020": "מס' הזמנה זה לא קיים במערכת",
                default: "שגיאה בתקשורת מול גודי, נא לפנות לתמיכה",
                responseError: "יש בעיה בתשובה של גודי. נא ליצור קשר עם התמיכה"

              }
            },
            interService: {
              awaitingResponse: "Connecting InterService",
              error: {
                error: 'Error',
                badVoucherNumber: "Voucher number is not valid - please try another",
                voucherIsNotValid: "השובר לא בתוקף"
              }
            },
            multipassPolice: {
              canPayedOnlyCashCreditMultipass:"Dear Customer, you can pay only with cash, credit card or Multipass points",
              canotUseCasparPoints:"Cannot use caspar points while there have other active payments",
              balance: "points",
              balanceText: "Current points balance",
              noticePromosAreDisabled: "Notice! Active promotions in the deal will be canceled when redeeming Multipass points.",
              noItemsForWalletToPay: 'There are no items to pay with this wallet',
              type: "Type",
              amount: "Amount",
              pointsType: "Type of point",
              amountUsedInSale: "Amount used in transaction",
              startSalePointsAmount: "Opening balance",
              endSalePointsAmount: "Closing balance",
              error: "Multipass Error: ",
              name: "Multipass Points",
              cardNo: "Card number:",
              cardPass: "PIN:",
              swipe: "Swipe Card",
              waitingForSwipe: "Swipe card in Pinpad",
              insertCardNo: "Please enter or swipe card",
              insertPinCode: "Please enter PIN code",
              search: "Search",
              cancel: "Cancel",
              searching: "Searching...",
              pointsToUse: "Points to user",
              pleaseChoose: "Please choose your points distribution",
              rest: "Left to pay after using points",
              tooManyPointsError: "You choose too many points",
              tooManyPointsInWallet: "You used more points than available in wallet \"{wallet}\" in this sale you can use up to {max} points in this wallet",
              pointTypes: {
                "1": "Uniform",
                "2": '"Combined Uniform" - Obsolete',
                "3": "Personal",
                "4": "Virtual - Obsolete",
                "10": "Sport",
                "11": "Personal",
                "12": "Sport + Personal",
                "13": "Uniform",
                "14": "Caspar",
                stars: "Stars"
              },
              item: {
                code: 'Item Code',
                desc: 'Item Name',
                price: 'Price',
              },            
              discountName: "Points Discount",
              maxPointsUsage: "Maximum points usage",
              maxPoints: "Max",
              showItems: 'Show Items',
              paymentBackWarning: "This operation will cancel points payments, continue?",
              alreadyHasPointsWarning: "There cannot be two payments of Multipass Point </br> pressing 'OK' will delete the payment and will allow you to choose points once more",
              maxDiscount: "Max discount",
              currentDiscount: "Current Discount",
              customerPickerTitle: "Fill details of swipe card",
              lookingForCustomer: "Looking for customer",
              commitingTransaction: "Commiting Transaction",
              cancellingPayment: "Cancelling Payment"
            },
            nirDavidService: {
              barcodeIsValid: "Barcode is valid!",
              barcodeIsInvalid: "Invalid barcode! Please try another one",
              checkingBarcode: "Checking barcode",
              cancellingBarcodes: "Cancelling Barcodes",
              customerHasSignedInWebsite: "Customer has been registered in the website?",
              cancelBarcodesValidationMessage: "Do you wish to cancel the barcodes?",
              yes: "Yes",
              no: "No",
              cleanLeftBarcodesMessage: "Alert! The barcodes that were scanned before closing the sale will be cancelled, please scan them again. ",
              barcodesHasBeenCancelled: "Barcodes has been cancelled successfully!",
              barcodeCancelErrorMessgae: 'Error accured while cancelling barcodes, please contact support if the problem persists',
              restartSale: 'Restart Sale',
              posTitle: "Website Customer",
              pleaseInsertValidBarocde: 'Please insert a valid barcode'
            },
            textKeyboardLayout: "english-qwerty-custom",
            cashManagement: {
              name: "Cash Management",
              instructions: "Please select an action",
              addButton: "Add Cash To Pos",
              removeButton: "Remove Cash From Pos",
              addedCash: "Add Cash",
              removedCash: "Remove Cash",
              cashToSafe: "Cash Removal",
              areYouSure: "Are You Sure?",
      addedCashApprove: "הוספת קרן פתיחה לקופה: {sum} ש\"ח </br>תאריך: {date} שעה: {time}. </br> האם לאשר?",
      removeCashApprove: "הוצאת מזומן מהקופה: {sum} ש\"ח </br>תאריך: {date} שעה: {time}. </br> האם לאשר?",
              addCashWarning: "Please Notice, This action will add the calculated amount to the opening statement",
              removeCashWarning: "Please Notice, This action will remove the calulared amount from the pos cash",
              warning: "Please Notice, The cash details is only for this pos and not for the entire store.",
              enterStatement: "Open Statement (This pos only)",
              checkCashInPos: "Check Cash",
              midOrEndOfDay: "Midday/End Day",
              depositReport: "Report Cash Deposit",
              printer: {
                title: "Cash Management",
                description: "Pos Cash Status Details",
                action: "Action",
                sum: "Sum",
                total: "Pos Total Cash",
                enterStatement: "Opening Statement",
                movedToSafe: "Cash Removal",
                cashWithdrawal: "Cash Withdrawal",
                cashInvoices: "Cash Sales",
                coin: "ILS"
              }
            },
            addOrShowBankDeposit: {
              title: "הפקדה חדשה",
              select: "בחירה",
              bank: "בנק",
              branchNum: "מספר סניף",
              selectedZReports:"דוחות זד בהפקדה זו",
              savingDeposit: "שומר הפקדה",
              noZReportsSelected: "לא נבחרו דוחות זד",
              cashMismatchMessage: "סכום המזומן שהוצהר שונה מסכום המזומן בדוחות הזד שנבחרו - האם להמשיך?",
              zNumber: "מספר זד",
              createdAt: "תאריך זד",
              totalAmount: `סה"כ סכום זד`,
              totalZCashAmount:`סה"כ מזומן ב-זד`,
              step1Title: "בחירת דוחות זד להפקדה:",
              continue: "המשך",
              finish: "סיום",
              depositEmployee: "מבצע ההפקדה",
              closeDepositConfirmation: "האם לסגור את ההצהרה? לא ניתן לבטל פעולה זו!",
              closeDepositConfirmationTitle: "סגירת הצהרה",
              totalCashZdsSelected: `סה"כ מזומן בדוחות זד בהצהרה`,
              totalCashDeclared: `סה"כ מזומן מוצהר`,
              depositCreatedSuccessfully: "ההפקדה נשמרה בהצלחה",
              failedToSaveDeposit: "שגיאה בעת שמירת ההפקדה, יש לנסות שוב.\nבמידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              comments: "הערות להפקדה",
              print: "הדפסה",
              actions:"פעולות",
              totalCashDeclaredIsRequired: 'יש להזין סכום סה"כ מזומן מוצהר',
            },
            depositReport: {
              title: "ניהול הפקדות",
              newDeposit: "הפקדה חדשה",
              depositTime: "זמן הפקדה",
              employee: "מפקיד",
              showDeposit: "פירוט הפקדה",
              selectedZReports: "דוחות Z שדווחו",
              totalZCashAmount: "סכום מזומן דוחות Z",
              depositAmount: "סכום הפקדה",
              actions: "פעולות",
            },
            sort: 'Sort',
            or: 'or',
            android: 'Adnroid',
            primaryPosPrinter: 'Primary Pos Printer',
            actions: 'actions',
            save: "Save",
            saveAction: "Save",
            addAction: "Add",
            noOptions: "Sorry, no matching options",
            checkingBalance: 'Checking Balance..',
            mustInsertCardNumber: 'Card number is required',
            pleaseScanQrCode: 'Please scan QR Code',
            payAttention: 'Please notice',
            tryAgain: 'Try Again',
            paymentIsCancelled: 'The transaction is cancelled',
            requestFailed: 'There was an error sending request to the server, Please try again. If the problem persists, Contact support.',
            newVersionPleaseUpdateCatalog: "There is a new version, please click ok to update the catalog",
            amountPercisionTooBig: "Amount must have only 2 digits after the percision point",
            actionWillDeletePayments: "This action will delete the following cash payments",
            actionWillAddPayment: "This action will close the sale after adding the following cash payment",
            areUSure: "Are You sure?",
            warning: "Warning",
            posOfflineMessage: 'The connection to the server has failed. The pos is working in offline mode. When the connection is restored, online mode will be turned',
            cancelChargePleaseWait: 'Cancelling charge, Please wait...',
            paymentsShort: 'PYMTS',
            flight: 'Journey',
            phone: 'Phone',
            cellPhone: 'Cell Phone',
            name: 'Name',
            firstName: 'First Name',
            lastName: 'Last Name',
            gender: 'Gender',
            sum: 'Sum',
            govId: 'ID',
            prev: "Previous",
            next: "Next",
            cashRefund: 'Cash Refund',
            refundCustomer: 'Do you want to refund the customer?',
            noSaleItems: "There are no items/payments in the sale",
            creditCard: "Credit Card",
            swipeCard: "Swipe Card",
            generateReport: 'Generate Report',
            generatingReportWait: "Generating Report, Please wait...",
            errorTechnicalCard: "Technician card is wrong",
            note: "Note",
            message: "Message",
            content: "Content",
            goBack: "Go Back",
            print: "Print",
            cardNumberCantBeEmpty: "Please Enter a card number",
            select: "Select Option",
            loading: "Loading...",
            convertingServerInfo: "ממיר נתונים מהענן ושומר בקופה...",
            discount: "Discount",
            promotion: "Promotion",
            deleteDiscount: "Remove Discount",
            deletePromotions: "Remove Promotions",
            deleteSaleDiscount: "Remove Discount",
            saleDiscount: "Sale Discount",
            clubMembershipCard: 'Membership card',
            saleDiscountPromotion: "Sale Discount Promotion",
    nEntriesAdded: '{n} entries added',
            currentCurrency: 'EUR',
            amount: "Amount",
            search: "Search",
            filter: "Filter",
            cash: "Cash",
            credit: "Credit Card",
            all: "All",
            vouchers: "Vouchers",
            creditVoucher: "Credit Voucher",
            check: "Check",
            back: "Back",
            close: "Close",
            cancelled: 'Cancelled',
            changeQuantityTitle: "Change Quantity",
            changeQuantityMessage: "Please enter a new quantity for the item",
            checkingForNewVersion: "Checking if there is a newer version...",
            support: "Support",
            main: "Main",
            mainMenu: 'Main Menu',
            loadingPosParameters: "Loading Pos Parameters",
            preparingLoadData: "Loading Data Files",
            offlineSales: "Offline Sales",
            posVersion: "POS Version",
            emvPosNumber: "EMV Pos Number",
            noSales: "No Sales",
            loginTitle: "Login",
            username: "Username",
            password: "Password",
            login: "Login",
            fromDate: "From Date",
            toDate: "To Date",
            managerApprovalPlaceholder: "Please enter supervisor code",
            managerApprovalBySmsPlaceholder: "Please pass or enter supervisor code",
            managerApprovalTitle: "Please enter supervisor code",
            managerApprovalBySmsError:"Sending SMS messages codes to the administrator failed. Please try again later, or contact support.",
            managerApprovalBySmsLoading:"Sending SMS Messages...",
            detailedManagerPrompt: "Authorization required, Please swipe manager card or enter code",
            emptyTableViewMessage: 'Scan an Item or insert item details at the search box',
            emptyTableViewMessageMobile: 'Cart is empty',
            otherTabsAlreadyOpen: 'POS is already open in other window/instance. Please close this window',
            autoSuggestStartText: 'Scan or type item code',
            autoSuggestEmptyText: 'Item not found',
            error: 'Error',
            none: 'No',
            payAll: 'Pay All',
            exact: 'Exact',
            ok: 'OK',
            actionCancelled: 'Action Cancelled',
            generalItem: 'פריט כללי',
            times: 'כפול',
            clear: 'Clear',
            cancel: 'Cancel',
            refresh: 'Refresh',
            enterPrice: 'Please enter a price for the item',
            enterPriceTitle: 'Enter a price',
            remove: 'Delete',
            yes: 'Yes',
            skip: 'Skip',
            svaErr25TranExists: " שים לב - קיימות עסקאות אשראי שלא שודרו. חובה לבצע תהליך סוף יום (דוח Z) בקופה זו לפני שניתן יהיה לבצע עסקאות אשראי נוספות.",
            svaErr25TranNotExists: "שים לב, בקופה זו לא בוצע שידור כבר מספר ימים. <br/> חובה לבצע שידור לפני שניתן יהיה לעשות עסקאות באשראי מקופה זו. <br/> לחץ אישור לביצוע שידור.",
            no: 'No',
            areYouSure: 'Are You Sure?',
            moveNext: 'Continue',
            continue: 'OK',
            cannotMoveToMinusWhenSaleHasPayments: 'Cannot make sale amount negative while there are sale payments. Please remove existing sale payments in order to make the sale amount negative.',
            cantAddTipItem: 'Cannot add tip item outside the payment screen',
            entityStatusValid: 'Valid',
            entityStatusInvalid: 'Not valid, update catalog required',
            entityStatusPartial: 'Missing Info',
            entityStatusEmpty: 'No info',
            incompletePayment: 'Missing Payment, please pay all amount.',
            nonNumericValue: 'Please enter only numbers',
            loggingIn: 'Connecting',
            loggingOut: 'Logging Out',
            logout: 'Logout',
            never: 'Never',
            doLogout: 'Logout from user {USERNAME}?',
            loginFailed: 'Login Failed.',
            mustBeOnline: 'Please make sure that the POS has a working internet connection.',
            logoutFailed: 'Logout failed. Please try to re-open the POS software. If the problem persists - contact support.',
            logoutOfflineSale: 'לא ניתן להתנתק מהקופה כאשר יש מכירות בנתק',
            logoutActiveDalpak: 'לא ניתן להתנתק כאשר יש מכירה פעילה בדלפק הנוכחי שנבחר, יש לצאת מהדלפק הנוכחי',
            logoutEmployeesNotSync: 'Logout failed. there are {failedEmployeesLength} employees that are not synced to the server, \n {failedEmployees}. \n plese check this employees and try again. If the problem persists - contact support.',
            logoutItemsNotSync: 'Logout failed. there are {failedItemsLength} items that are not synced to the server, \n {failedItems}. \n plese check this items and try again. If the problem persists - contact support.',
            usernamePasswordDoesntExists: 'Username/Password does not exist',
            wrongPassword: 'Wrong Password.',
            noAvailablePOS: 'Unable to register another POS in store {STORE_NAME}. Please contact support in order to add another POS to this store.',
            noAvailablePOSforUser: 'There is no available POS for this user',
            unknownErrorHasOccured: 'Unknown error occurred',
            missingDataFromRemoteServer: 'Got partial information from server. Please contact support',
            mandatory: 'Mandatory',
            optional: 'Optional',
            disabled: 'Disabled',
            automatic: 'Automatic',
            manual: 'Manual',
            unknown: 'Unknown',
            prevent: 'Prevent',
            warn: 'Warning',
            item: 'Item',
            size: 'Size',
            userCanceled: 'User Canceled',
            paramsLoadFaild: 'Failed to update parameters',
            downloadingDataFor: 'Downloading Data for',
            updatingMigvanPrices: 'Updating migvan prices',
            updatingMigvanItems: 'Updating migvan items',
            loadingXFailed: 'Loading {0} failed',
            relogin: 'Login Again',
            restartingPinpadConnection: "Initializing pinpad connection",
            posDisabledPleaseLogin: 'POS is disabled. Please login again',
            softwareUpdateFailed: 'Version Update Failed',
            softwareUpdateFailedMessage: 'POS is offline or server cannot be reached. Working in offline mode.',
            managerCardMissing: 'ההנחה שנבחרה דורשת להעביר כרטיס מנהל. אנא העבר כרטיס מנהל למעלה.',
            givenCardIsNotmanagerCard: 'The given card is not a manager/supervisor card.',
            managerApprovalGenericError:'The code is incorrect',
            removeSaleDiscountTitle: 'Remove Sale Discount',
            removeSaleDiscountMessage: 'Remove sale discount from sale?',
            discountPrecentHigherThenAllowed: 'Cannot give a discount higher than approved manager percent.',
            posRemoveDiscountFromItemTitle: 'Remove Discount From Item',
            posRemoveDiscountFromItemBody: 'Remove the discount from the item?',
            posRemovePromotionsFromItem: 'Remove promotions from the item?',
            posDiscountTypeSale: 'Sale Discount',
            cashOutside: "Cash Not In Pos",
            cashOutsideDalpakim: "Cash Not In Pos(Store)",
            totalDeliveriesCash: "Deliveries Cash(Store)",
            cashPlusCashOb: "Total Cash In Pos",
            posDiscountTypeItem: 'Item Discount',
            posDiscount: 'Discount',
            salesPersonEmployee: 'Salesperson',
            cannotFetchPosFromDBReload: 'לא ניתן לטעון מידע על התחנה. נסה לרענן את הדף. אם הבעיה חוזרת על עצמה, אנא פנה לתמיכה טכנית.',
            storingDataLocally: 'Storing Data on local DB, this may take a few minutes...',
            itemStockInfo: 'מלאי בחנויות לפריט: {ITEM_CODE} צבע: {COLOR} מידה: {SIZE}',
            remoteDataLoadFailed: 'טעינת מידע נכשלה. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            loadingItemFromStorageFailed: 'לא ניתן לטעון פריט. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            loadingItemSizesColorsFailed: 'לא ניתן לטעון מידות וגדלים לפריט. אנא נסה שוב. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            loadingData: 'Loading data, please wait...',
            loadingZreportdataError: 'Z report loading data error, please try again or contact support',
            offlineZreportMessage: 'Z report data is not possible in offline state',
            localBackupOfSales: "מבצע גיבוי מקומי של נתוני מכירות",
            creatingZReport: "Creating Z Report",
            sendZReportToServer: "Synchronizing ZReport to server",
            compareLocalSalesWithServer: 'Validating sales data',
            checkingFlightSales: "Checking Journey sales",
            transmittingFlight: "Transmitting Journey data",
            flightUploadedNoSales: "Journey data transmitted successfully",
            loadingStatistics: 'מעדכן סטטיסטיקות, אנא המתן...',
            waitingToBeSent: 'Waiting to be sent',
            successfullySent: 'Successfully Sent',
            selectOption: 'Select Option',
            successfullyCompleted: 'Completed',
            syncFailed: 'Sync Failed',
            failedToFetchSale: 'לא ניתן לטעון מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToFetchPastSales: 'לא ניתן לטעון מכירות. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToFetchSaleTotals: 'לא ניתן לטעון סכום למכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            chooseColor: 'Please select a color',
            chooseSize: 'Please select a size',
            missingDataFromItem: 'Missing data for item. Please contact support',
            failedToFetchDiscounts: 'Failed to fetch discounts.',
            noDiscounts: 'No available discounts',
            managerNotFound: 'Manager not found',
            failedToFetchManagers: 'There was an error while retrieving the list of managers. Please try again',
            mustSelectCashier: 'You must select a cashier in the main screen in order to use the POS',
            specialitemAndVoucherNotAllowed: 'לא ניתן לשלם עם שובר עבור פריט מיוחד.',
            specialitemAndCreditVoucherNotAllowed: 'לא ניתן לשלם עם שובר זיכוי עבור פריט מיוחד.',
            pfcChromeBrowserMinimumRequirementFailed: 'גרסת דפדפן אינה תואמת, פנה לתמיכה טכנית',
            itemInMinusShouldBeOnlyOneSalesPerson: 'לא ניתן למכור פריט במינוס בחשבונית עם יותר ממוכר אחד',
            mustRestartAfterDataUpdate: 'חובה לפתוח את הדפדפן מחדש לאחר עדכון נתונים, על מנת להבטיח שמירת נתוני העדכון בדיסק הקשיח',
            shvaConfiguredButNotRunning: 'קופה מוגדרת לעבודה עם שבא אך שבא אינו פעיל',
            shvaPendingTranFile: 'שידור לשבא לא הסתיים תקין, מבצע שידור מחדש.' + '<br/>' +
              'לא ניתן לבצע עסקאות עד אשר שידור לשבא מסתיים.' + '<br/>' +
              'יש לוודא שמתקבל דוח שידור משבא',
            shvaInProccess: 'שידור קודם לשבא טרם הסתיים.' + '<br/>' +
              'מבצע התאוששות, יש לוודא שלא סוגרים קופה ולא מתחילים מכירה חדשה' + '<br/>' +
              'לפני שמתקבל דוח שידור משבא.',
            noneTransmitedSecondaryPoss: "The following secondary poss did not transmit a Z report: ",
            zReportFailedError: 'There was an error creating z-report, Please contact support',
            kupaNotCompletellyLoaded: 'טרם הסתיימה טעינת הקופה - נסה שנית',
            printingAddonNotInstalled: 'Printing addon not installed',
            addonNotInstalledCorrectly: `"Valu POS" Chrome Extension wasn't installed or enabled or not installed correctly`,
            installAddon: 'Install Extension',
            NoCurrencyRatesFromBankOfIsrael: 'חסרים שערי מטבעות מבנק ישראל, פנה לתמיכה טכנית',
            MissinCurrencyRatesFromBankOfIsrael: 'חסרים שערי מטבעות מעודכנים מבנק ישראל, פנה לתמיכה טכנית',
            CurrencyRatesFromBankOfIsraelOk: 'שערי מטבעות עודכנו בהצלחה',
            AutoEmpStoreUpdateIsNowAuto: 'עדכון עובדים עבר למצב אוטומטי ',
            posClockTimeIsIncorrect: 'בעיה בשעת המכירה, חובה ליפנות לתמיכה.',
            itIsNotPossibleToPayHakafaWithMizdamen: "לא ניתן לשלם בהקפה עבור לקוח מזדמן",
            AutoEmpStoreUpdateIsNowManual: 'עדכון עובדים עבר למצב ידני ',
            cannotCreateNewSale: 'לא ניתן ליצור מכירה חדשה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToPersistSale: 'לא ניתן לשמור מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToPersistSaleItem: 'לא ניתן לשמור פריט מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToPersistSalePayment: 'Failed to save sale payment. Please try again. If the problem persists - contact support.',
            failedToDeleteSaleItem: 'לא ניתן למחוק פריט מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToDeleteSalePayment: 'לא ניתן למחוק תשלום מכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.',
            failedToDeleteSalePaymentPending: 'Unable to delete a sale with active payments',
            failedToDeleteSaleBeacuseActivePayments: 'It is not possible to delete an item from a sale in which payment has already been made. Please delete a payment method',
            failedToDeletePickupSale: 'Unable to delete Pickup sale',
            failedSpecialitemExists: 'על פריט ייחודי להופיע לבדו בחשבונית',
            failedDeliveryItemOnNotEmptySale: 'Orders are only available on empty sale, please restart sale to create an order',
            failedOpenCustomerClubSpecialItemGiftCardExists: 'על פריט ייחודי GIFT CARD לא ניתן להשתמש בחבר מועדון',
            failedAddSpecialItemGiftCardCustomerClubExistsOnSale: 'לא ניתן להוסיף פריט ייחודי GIFT CARD כשקיים לקוח מועדון במכירה',
            cannotAddDiscountOnSpecialItemGiftCard: 'לא ניתן לבצע הנחה על פריט ייחודי GIFT CARD',
            failedSalePercentAdditionExists: 'לא ניתן להוסיף יותר מפריט אחוז אחד בחשבונית',
            confirmErnErrorHeader: 'שגיאת ERN האם להמשיך בכל זאת?',
            confirmErnErrorForceContinue: 'שגיאה {ERROR} האם להמשיך בכל זאת?',
            confirmSaleItemDeleteHeader: 'Delete Row',
            noItemsSelectedToDelete: 'No items were selected for deletion',
    confirmMultipleSaleItemsDelete: 'Note, this action will delete {numberOfSaleItems} lines, would you like to continue?',
            fingerprintEnrollNotification: "העבר את האצבע שלוש פעמים על הקורא.",
            scaleRequest: "Getting weight from scale",
            scaleEmpty: "Please place an item on the scale",
            scaleGenericError: "בעיה בקליטת משקל - לא ניתן להוסיף פריט שקיל למכירה. אם הבעיה חוזרת על עצמה יש לפנות לתמיכה טכנית.",
            scaleAndroidError: "בעיה בקליטת משקל - אנא וודא כי המשקל מחובר למכשיר וכי יש לו הרשאה",
            FINGERPRINT_UPDATE_DATA: "שגיאת מערכת, בצע עדכון מידע, אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית.",
            FINGERPRINT_NO_READER_FOUND: "לא נמצא קורא טביעות אצבע",
            FINGERPRINT_REGISTRATION_FAILED: "הגדרת המשתמש וטביעת האצבע נכשלה.",
            FINGERPRINT_USER_NOT_FOUND: "לא נמצא משתמש",
            FINGERPRINT_ERROR_EMPLOYEE_ALREADY_ENROLLED: "משתמש הוגדר בעבר, אנא פנה לתמיכה",
            FINGERPRINT_ERR_INCORRECT_USER: "שגיאת מערכת, יש לפנות לתמיכה.",
            FINGERPRINT_ERROR_SAVE_FINGERPRINT_RECORD: "שגיאת מערכת, יש לפנות לתמיכה.",
            NO_ITEM_OR_BARCODE_FOUND: "Item not found: ",
            TIMETRACK_USER_MUST_USE_FINGERPRINT: "עבור משתמש זה מוגדרת טביעת אצבע, לא ניתן להשתמש בכרטיס לדיווח שעות.",
            EMPLOYEE_NOT_FOUND_FOR_STORE: "עובד לא נמצא/משוייך לחנות זו",
            fingerprintDefineHeader: "Fingerprint Enrollment",
            fingerprintDefineQuestion: 'Enroll a new fingerprint for the selected employee?',
            TIMETRACK_EMPLOYEE_NOT_EXISTS: 'Employee does not exist',
            TIMETRACK_EMPLOYEE_ALREADY_AS_ENTRY_TODAY: 'לא ניתן להקליד שתי כניסות/יציאות עוקבות לאותו היום.',
            TIMETRACK_CANT_EXIT_WITHOUT_ENTRY: 'לא בוצעה כניסה ב-20 שעות האחרונות לעובד זה.\nלא ניתן להקליד יציאה ללא כניסה.\nיש לפנות למנהל לצורך רישום שעות ידני.',
            TIMETRACK_COULD_NOT_PERSIST: 'לא ניתן לרשום את דיווח השעות',
            fingerprintVerifyNotification: "Please put your finger on the fingerprint reader.",
            confirmSaleItemDelete: 'Are you sure you want to remove this item from the sale?',
            confirmSalePaymentItemDeleteHeader: 'Delete Sale Payment',
            confirmSalePaymentItemDelete: 'Delete sale payment?',
            confirmSaleRestartHeader: 'New Sale',
            confirmSaleRestart: 'Are you sure you want to empty the cart?',
            confirmSaleRestartExistingPaymenyError: 'לא ניתן למחקות את המכירה. קיימים תשלומים באשראי שתרם הוחזרו.',
            confirmReceiptCancelHeader: 'Cancel Receipt',
            errorOccurred: "Error Occurred",
            shouldPrintA4Msg: 'Select where to print the invoice',
            shouldPrintInvoiceAtPrimaryPos: 'Do you want to print the invoice?\nPlease notice, the invoices will be printed at the primary pos',
            shouldPrintAtPrimaryPos: 'Do you want to print?\nPlease notice, the data print will be printed at the primary pos',
            shouldPrintA4Title: 'Select Printer',
            a4Printer: 'A4 Printer',
            posPrinter: 'POS Printer',
            confirmReceiptCencel: 'Continue With Cancel Receipt?',
            confirmShipmentCancelHeader: 'Cancel Delivery Note',
            confirmShipmentCencel: 'Continue With Cancel Delivery Note?',
            confirmSaleCustomerDeleteHeader: 'Remove Customer',
            closingSale: 'Closing Sale...',
            confirmSaleCustomerDelete: 'Remove customer from sale?',
            voucherAutoDeleteLimitPayments: 'שורות המכילות שוברים שאינם כרטיסים נטענים ימחקו',
            failedToCloseSaleSequenceFetchFailed: 'לא ניתן לסגור את המכירה בגלל בעיה בקבלת מס. סידורי. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית',
            failedToCloseSale: 'לא ניתן לסגור את המכירה. אם הבעיה חוזרת על עצמה, פנה לתמיכה טכנית',
            creditCardOnlineMode: 'לא ניתן להתקשר לשבא, אנא פנה לתמיכה הטכנית',
            creditCardOfflineLimitExceeded: 'הקופה בנתק ולא ניתן לבצע עיסקה באשראי מעל לסכום: {AMOUNT}',
            creditCardPaymentMandatoryHeader: 'Add Payment',
            creditCardPaymentAmountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סכום',
            generalPaymentLeftAmountMandatoryError: 'Can\'t add payments, The amount to pay is bigger than the total amount',
            cashPaymentReachedLimit: 'There is a cash payment limit of {cash} ILS',
            creditCardPaymentCreditCardMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה מספר כרטיס',
            creditCardPaymentCreditTypeMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סוג אשראי',
            creditCardPaymentPaymentsMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה תשלומים',
            creditCardPaymentFirstPaymentIsBiggerThanAmount: "First payment must be smaller than the amount to pay",
            creditCardPaymentPaymentsMaxMandatoryError: 'The maximum payments for credit card is {COUNT}',
            creditCardPaymentPaymentsMaxForAmountError: 'The maximum payments for credit card with the requested amount is {COUNT}',
            checkPaymentPaymentsMaxForAmountError: 'The number of payments is incorrect, you must enter from 1 to {MAX_COUNT} payments',
            checkPaymentsMustSelectAllPayments: 'חובה לסמן את כל התשלומים עבור הצק הנבחר',
            creditCardPaymentExpDateMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה תוקף',
            creditCardPaymentExpDateOverMandatoryError: 'לא ניתן להוסיף תשלום, תוקף הכרטיס פג',
            creditCardPaymentPhoneMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה טלפון',
            creditCardPaymentCCVMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה CVV',
            creditCardPaymentAllowTypingMandatoryError: 'לא ניתן להזין כרטיס אשראי בצורה ידנית',
            creditCardPaymentError: 'שגיאה באשראי, אנא פנה לתמיכה',
            creditCardCreditFailedTitle: 'זיכוי כרטיס האשראי נכשל',
            creditCardCreditFailedContent: 'לא ניתן לזכות כרטיס זה',
            creditCardPaymentNotAllowedCard: 'לא ניתן לחייב כרטיס זה בנתק.',
            creditCardPaymentNoPhoneNumber: 'הקופה בנתק, נא להזין מספר טלפון על מנת להשלים את החיוב.',
            checkPaymentMandatoryHeader: 'Add Payment',
            checkPaymentAmountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סכום',
            checkPaymentBankMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה בנק',
            checkPaymenIdMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא ת.ז חוקית.',
            checkPaymenIdComercialChecNotValid: 'יש להקליד ת.ז. עבור ערב.',
            checkPaymentBankCodeMandatoryError: 'קוד בנק אינו חוקי',
            checkPaymentBranchMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה סניף',
            checkPaymentBrancLengthError: 'לא ניתן להוסיף תשלום שדה סניף יכול להכיל עד 3 ספרות בלבד',
            checkPaymentBankLengthError: 'לא ניתן להוסיף תשלום שדה בנק יכול להכיל עד 3 ספרות בלבד',
            checkPaymentAccountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה חשבון',
            checkPaymentCheckNumMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא ערך חוקי בשדה צ\'ק',
            checkPaymentPaymentsCountMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא ערך חוקי בשדה מספר תשלומים',
            checkBadErnConfirmationNumber: 'מספר אישור אי אר אן אינו תקין.',
            checkErnTooManyRejectsMustConfirmationNumber: 'נסיונות כושלים רבים מידי מול ERN חובה להקליד מיספר אישור.',
            checkPaymentDateMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא את שדה תאריך פרעון',
            checkPaymentDateNotValidMandatoryError: 'לא ניתן להוסיף תשלום אנא מלא תאריך תקין עבור תאריך פרעון',
            checkPaymentDateMaxMandatoryError: 'לא ניתן להוסיף תשלום, תאריך פרעון מקסימלי הינו כ-{COUNT} ימים מהיום',
            checkPaymentDateMaxMandatoryTo10Error: 'עבור צק לא ניתן תאריך פרעון מאוחר מהעשירי לחודש',
            checkManyPaymentsMustBeTodayOrTommorow: 'צק ראשון, חייב להיות להיום או למחר.',
            checkDateBeforeYesturday: 'לא ניתן להזין תאריך פרעון קודם לאתמול.',
            checkDateSingleCheckError: 'תאריך פרעון לצק יחיד מאוחר מהמותר.',
            checkDateUntillThreeMonthOld: 'תאריך פרעון הצק אינו יכול להיות מוקדם משלושה חודשים מהיום',
            voucherPaymentMandatoryHeader			: 'Add Payment',
            voucherPaymentAmountMandatoryError			: 'Can\'t add payment, please make sure that the payment is bigger than 0' ,
            voucherPaymentVoucherMandatoryError			: 'Please select voucher type' ,
            cashPaymentAmountMandatoryError			: 'Can\'t add payment, please make sure that the payment is bigger than 0' ,
            voucherPaymentQuantityInInventoryMandatoryError			: 'לא ניתן להשתמש בתווים מסוג \'{TYPE}\' מעל ל-{MAXIMUM_COUNT} פעמים בחשבונית' ,
            voucherPaymentMinimunAmountMandatoryError			: 'ניתן להשתמש בשובר מסוג \'{TYPE}\' עבור כל {MINIMUM_AMOUNT} שקלים' ,
            voucherPaymentMaxDiscountPrecetageMandatoryError			: 'לא ניתן לשלם יותר מ-{PRECETAGE} אחוז מהחשבון בתווים מסוג \'{TYPE}\'.' ,
            voucherPaymentBarcodeNotExistMandatoryError			: 'ברקוד לא קיים עבור השובר' ,
            voucherPaymentBarcodeUsedMandatoryError			: 'Voucher was already used' ,
            voucherPaymentBarcodeUsedLocallyMandatoryError			: 'Voucher was already used.' ,
            voucherPaymentBarcodeOfflineMandatoryError 					: 'לא ניתן לממש שובר שכולל ברקוד ללא חיבור לרשת',
            voucherPaymentBarcodeEmptyMandatoryError 					: 'Please enter a value',
            voucherPaymentBarcodeOnlineValidationLoadingMsg 		: 'מוודא שובר מול שרת מרוחק, אנא המתן...',
            hakafaPaymentCanOnlyBeSingle: 'לא ניתן לשלם בהקפה כאשר קיימים תשלומים אחרים בחשבון',
            promotionOffLineMode: 'Cannot accept credit voucher while POS is offline.',
            promotionNonValid: 'לא ניתן להוסיף שובר זיכוי',
            promotionNotExist: 'Voucher does not exist',
            promotionUsed: 'שובר נוצל בעבר',
            promotionExpired: 'שובר לא בתוקף, נא לפנות לשירות לקוחות',
            salesPersonUnSelected: 'לא נבחר מוכר , אנא בחר מוכר',
            customerClub: 'Club Member',
            customerNotFound: 'Customer not found',
            customersNotFound: 'No customers were found',
            customer: 'Customer',
            noEmployeesInShift: "No employees are currently in this shift. Please make sure that employees enter the shift using the Time Clock.",
            specialDiscountUse: 'מכין את ההנחה המיוחדת לשימוש עבור הלקוח.',
            specialDiscountUseError: 'לא ניתן לממש את ההנחה, אם הבעיה חוזרת על עצמה פנה למנהל המערכת.',
            specialDiscountsNotFound: 'לא נמצאו הנחות מיוחדות למימוש.',
            searchInvoicesLongResults: 'מספר רב מידי של רשומות תאם את החיפוש. מוצגות 100 רשומות ראשונות',
            searchInvoicesNoResults: 'No sales matching the search criteria where found.',
            searchInvoicesStoreSelectError: 'חובה לבחור חנות על מנת לחפש חשבוניות.',
            searchInvoices4digCcError: 'ערך לא תקין עבור ארבע ספרות כרטיס אשראי',
            searchInvoicesStoreNotFoundError: 'חנות מספר {STORE_ID} לא קיימת במאגר החנויות.',
            searchInvoiceDateRangeNotValid: 'נבחר טווח תאריכים גדול מדי. יש לבחור טווח תאריכים של עד 3 חודשים מתאריך ההתחלה עד תאריך הסיום',
            zReportCreatingErrorNoSales: 'It is not possible to create a report where there isn\'t any sales.',
            zReportCreatingErrorOnHoldSales: 'לא ניתן לייצר דוח כאשר קיימות מכירות בהשהייה',
            zReportCreatingErrorOpenedDalpak: 'לא ניתן לייצר דוח כאשר יש מכירות פעילות על הדלפקים, אנא וודא שכל הדלפקים ריקים',
            zReportCreatingErrorOfflineSales: 'You must wait for all the sales to be synchronized to the server before this action. Try again in a minute, if this error continues please contact support',
            zReportCreatingErrorPendingOfflineSales: 'לא ניתן לייצר דוח, יש מכירות ממתינות לחיוב בנתק',
            zReportTransmitFailed: 'השידור לשבא נכשל. יש לנסות להריץ דוח Z שוב. במידה והשידור נכשל שוב - חובה לפנות לתמיכה.',
            zReportFlightTransmitFailed: 'Failed to transmit journey data. Please try again. If the problem persists - contact support',
            zReportCreatingErrorOffline: 'It is not possible to create a report in offline mode.',
            zReportSyncToRemoteServerError: 'An error occured upon syncing ZReport to server, try to run again, if this error shows again - contact support',
            zReportLocalAndServerSalesDoesNotMatch: 'Cannot create report. The pos sales doesn not match with the server sales. Please contact support.',
            cancelSaleNoDebitError: 'ניתן לבטל חשבוניות חיוב בלבד.',
            cancelSaleVoucherBarcodeOfflineError: 'לא ניתן לבטל חשבון עם שובר מסומן במצב לא מקוון. יש לנסות שנית כשהקופה במצב מקוון.',
            cancelSaleCreditCardOfflineError: 'לא ניתן לבטל חשבון עם כרטיס אשראי במצב לא מקוון. יש לנסות שנית כשהקופה במצב מקוון.',
            cancelSaleCreditCardError: 'לא ניתן לבטל חשבוניות שכוללת תשלום בכטיס אשראי כרגע.',
            cancelSaleCreditVoucherError: 'לא ניתן לבטל חשבוניות שכוללת תוי זיכוי כרגע.',
            cancelSaleAllreadyCancelError: 'החשבונית כבר בוטלה בעבר.',
            creditSaleMustSelectItemsToCreditError: 'Please select at least one item to credit.',
            creditSaleMustbeNegative: 'The total of items to be credited must be greater than 0.',
            creditSaleMustSelectAllItemsWhereContainAutoCreditVouchersError: 'חובה לבחור את כל הפריטים כאשר החשבונית מכילה תשלומים בשוברים שמזוכים אוטומטית',
            creditSaleNotEnoughCreditBackError: 'Remaining amount to credit is not high enough for returning the money to the customer.',
            creditSaleAllowCreditVoucher: 'Issue a credit voucher of {AMOUNT} {CURRENCY}',
            creditSaleItemCreditedAmountMsg: '{AMOUNT} פריטים מהמוצר הנ"ל כבר זוכו בעבר עבור חשבונית זו.',
            possible: 'Possible',
            generalError: "Error - Please try again.\nIf the problem persists - contact support.",
            inPossible: 'Not Possible',
            closeSaleNoItems: 'חוסר בפריטים לעומת תשלומים, לחץ אישור על מנת לשחזר את החשבונית.',
            closeSaleNoTotalQuantity: 'כמות אינה תקינה, לחץ אישור לתיקון.',
            closeSaleMissingItems: 'There are more payments than items. Please remove the current payments and add a payment that equals to the total of the items, or add another items to the sale',
            closeSaleMissingPayments: 'חסרים תשלומים, לחץ אישור לשיחזור החשבונית.',
            closeSaleNotValid: 'שגיאה בתקינות החשבונית, לחץ אישור על מנת לשחזר את החשבונית.',
            cashMaxChangeInCashError: 'Cannot Accept cash payment.\n Amount should be not over {AMOUNT} EUR higher than the total amount.',
            blocked: 'Blocked',
            managerApproval: 'Manager Approval',
            employeeTimeTrackAddedSuccessfully: '{STATUS} was done for: {EMPLOYEE_NAME}.',
            checkBalance: 'Balance Check',
            voucherPaymentBarcodeCheckBalanceLoadingMsg: 'Checking card balance, please wait...',
            voucherBalanceIs: "The card's balance is: {AMOUNT}",
            voucherPaymentDebitPraxellLoadingMsg: 'Debiting card, please wait...',
            voucherPaymentDebitZeroBalance: "The card's balance is zero, cannot debit card.",
            voucherPaymentCancelPraxellLoadingMsg: 'Crediting card, please wait...',
            smartVoucherCancelingLoadCard: 'Cancelling card load, please wait...',
            smartVoucherNotFound: "No smart voucher {name} defined",
            voucherPaymentAddValuPraxellLoadingMsg: 'Updating card balance, please wait...',
            okToRemoveCustomerCancelToCorrect: 'אישור למחיקת נתוני לקוח בהקפה, ביטול להשלמת הקלדת הפרטים החסרים.',
            voucherPaymentPraxellAmountExceeds: 'The amount exceeds the sale amount',
            valuCardBarcodeMotInteger: "The supplied barcode does not match the card's type",
            syncComplete: 'Update Complete',
            RecoveringUnfinishedPraxellTransaction: 'Reverting Praxell transaction',
            creditInvoiceNoItemSelected: "No item was selected to credit",
            creditInvoiceRemovePendingOtherReplacementItemsFirst: "חובה לנקות פריטים להחלפה שאינם שייכים לזיכוי זה.",
            noBarcodeForColorSizeCombo: "There is no barcode for the selected Color/Size combination",
            tamashValuCardOtherPaymentAmountExists: "Shipment chargable card must appear solely",
            tamashValuVoucher501NotDefined: "Shipment valu voucher 501 not defined",
            attemptingToClose: "Closing the pos",
            closeFailed: "Closing ation failed, Please contact support",
            closePos: "Close Pos",
            closeSaleCreditCardAmountsError: "The credit sums is invalid. Please contact support.",
            dtsNoledg: "DTS",
            genericUserPleaseWait: 'Quering Server...',
            pleaseWait: "Please wait...",
            clearFilters: 'Clear Filters',
            dalpak: 'counter',
            table: 'table',
            pleaseUpdateWebView: 'Please Update WebView, contact support for instructions',
            xOutOfY: '{index} out of {length}',
            mustWorkWithNewPromos: 'Error: The cashier must work with the new promotions system in order to',
            httpService: {
              minApkVersionError: "Error: Please upgrade Apk version to altleast 1.62",
              minAddonVersionError: "Error: Please upgrade Addon version files to atleast 2.85",
            },
            smartVoucher: {
              deposit: 'Load card',
              cancelDeposit: 'Cancel Load Card',
              transactionNumber: 'Transaction number',
              actionSum:'Action total',
              currentBalance: 'Current balance',
              incompatableCard: 'Card is not compatible with the voucher',
            },
            emv: {
              paymentNotFoundInRecovery: "Error: The charge failed due to a communication issue. Please try to charge again.",
              pinpadResetInstructionsMessage: "A communication error was detected. Please reset the pinpad by pressing the ladder button and the yellow button simultaneously. \n After restarting the device and seeing the logo or terminal number, press the confirm button.",
              pinpadResetInstructionsConfirmButton: "I did, try again.",
              killingPinpadAddon: "Oops... We detected that we have a communication problem with the terminal. Please wait, resetting the credit terminal.",
              pinpadRecoveryProcess: "Communication error, executing transaction recovery process.",
              performingPinpadConfiguration: "Fetching pinpad configuartions",
              queryTransaction: "Location pinpad transaction",
              filteringFaildTran: "Verifing compatibillity of data",
              payAttention: "Please notice",
              PINPAD_NOT_CONNECTED: "The connection to the card reader is not valid. Please Verify the connection of the card reader to the pos and make sure that the card reader is on, and try again.\n If the problem persists, please contact support.",
              PINPAD_NOT_CONNECTED_ANDROID: "The connection to the card reader is not valid. Please Verify the connection of the card reader to the pos and make sure that the card reader is on, and try again.\n\n It is recommended to close the POS app and start it again as illutrated in the image below.\n\n If the problem persists, please contact support.",
              PINPAD_NOT_CONNECTED_IP: "The connection to the terminal device is not valid. Please Verify the connection of the terminal device is on ant connected to the network, and try to restart the payment app, then try this action again. \n If the problem persists, please contact support.",
              TRANSMIT_ERROR: "There was an error transmitting credit card movements, Contact support.",
              USER_CANCEL: 'The action canceled by the user',
              CANNOT_CREDIT_IN_RECOVERY_MODE: 'לא ניתן לזכות כרטיס אשראי במצב עבודה ללא אשראי.\nהחיבור לקורא הכרטיסים אינו תקין או קיים קובץ תנועות ישן על המסופון שטרם שודר',
              OLD_TRAN_FILE_PRESENT: "קיים קובץ תנועות ישן על המסופון שטרם שודר. חובה לבצע התאוששות.\nלחץ על אישור בכדי לנסות לשדר תנועות שוב ולבצע התאוששות.\nמומלץ לפנות לתמיכה.",
              emptyTrack2: "Error in Card Reader.",
      errorNum: "Error Code: {error}",
              creditPaymentsExistsButStillGot10019: "שים לב, לא היו עסקאות במסופון לשליחה למתג למרות שקיימות עסקאות אשראי",
              clickOKToCallShva: "לחץ אישור לקליטת נתונים חדשים משבא",
              cardReaderConnectivity: "Checking card reader connection",
              EMV_IN_PROGRESS: "Performing transaction",
              EMV_RETURN_IN_PROGRESS: "Performing credit transaction",
              EMV_CREDIT_IN_PROGRESS: "Refund Card, Please Swipe The Card",
              GENERAL_ERROR: "General Error - Please validate the pinpad connection to the pos and try again. contact support if the problem persists",
              REQUEST_AND_OUTPUT_MISMATCH: "חוסר התאמה בין בקשה לתשובה שהתקבלה - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה.",
              EMV_CREATING_Z_REPORT: "Creating Z Report",
              EMV_PERFORMING_TRANSMIT: "Transmitting data",
              EMV_PERFORMING_TRANSMIT_TO_SWITCH: "Transmitting data to switch",
              DELETING_TRAN_FILE: "מוחק קובץ TRAN מהמסופון",
              CALLING_SHVA_WITHOUT_TRANSMITTING_TRAN: "Reciving data from SHVA",
              ERROR_WHILE_DELETING_TRAN: "שגיאה בעת מחיקת קובץ TRAN",
              GETTING_TRANS_REPORT: "Reciving transimition report from SHVA",
              CREDIT_HEADER: "Credit Refund",
              CREDIT_DIALOG_CONTENT: "Select Card Swipe Or Manual Refund",
              CREDIT_DIALOG_CONTINUE_BUTTON_TEXT: "Card Swipe",
              CREDIT_DIALOG_CANCEL_BUTTON_TEXT: "Manual Refund",
              PREPARING_TRANSMISSION: "Prepering Transmission",
              CHECKING_PINPAD_CONNECTION: "Checking card reader connection",
              UPDATING_PINPAD: "Updating Pinpad",
              SENDING_LOGS_TO_FTP: "Sending Logs to FTP",
              CALLING_CASPIT_TECH_SERVER: "Calling Caspit Tech Server",
              ERROR_WHILE_DOING_TRANSMIT: "Error while transmitting transactions.\n Please check your Pinpad connectivity and POS internet connectivity.\n contact support if the problem persists",
              ERROR_WHILE_DOING_TRANSMIT_WITH_RETRY: "Error while transmitting transactions.\n Please check your Pinpad connectivity and POS internet connectivity.\n contact support if the problem persists",
              ERROR_WHILE_DOING_TRANSMIT_REPORT_AND_ATTACH_TO_Z: "An error occurred during the transmission of transactions/receiving the transactions report from Shva.\nPlease try to close the report and try again.\nIf the problem persists, please contact support.",
              pinpadConfigurationError: "Error while fetching pinpad configuration",
              blockListFileOutdatedUpdatingIt: 'Block list file outdated. updating, please wait...',
              cancelingPayment: "Refunding credit card payment",
              checkingForSaleInPinpad: "Verifying payment status",
              responseIsNotComplete: "The response from the PinPad is not complete. The payment has been cancelled, please try  again.",
              responseIsNotCompleteTitle: 'Payment has been cancelled',
              badResponseWasntCancelled: 'Failed to charge credit card, please call the credit card company to cancel it',
              badResponseWasntCancelledTitle: 'Failed to charge credit card',
              cancelPaymentNotAvailable: "The payment cannot be cancelled at this time. Please finish the sale and then cancel it via the past sales screen",
              pinpadRecovery: {
                title: "Pinpad Recovery",
                content: "A transaction of {amount}{currency} was detected on the credit terminal.\nWould you like to link this charge to the current transaction?\nPlease note – cancelling the linkage will void the customer's credit charge.",
                confirmContent: "The charge was successfully linked to the sale, click confirm to proceed to the payments screen."
              },
              creditTerms: {
                regular: "Regular",
                isracredit: "IsraCredit",
                adifPlus30: "Preffered\\+30",
                credit: "Credit",
                immediateCharge: "Immediate Charge",
                payments: "Payments"
              },
              panEntries: {
                magnetic: "Magnetic",
                cellular: "Cellular",
                manual: "Manual",
                kaf: "כ"
              },
              authCodesManpik: {
                card: "Card",
                company: "Company",
                shva: 'SHVA',
                manual: "Manual"
              },
              tranTypes: {
                informative: "Informative",
                debit: "Debit",
                dischrage: "Discharge",
                enforced: "Enforced",
                cash: "Cash",
                standingOrder: "Standing Order",
                checkBalance: "Check Balance",
                credit: "Refund",
                charge: "Charge"
              },
              cardDenied: "Denied",
              errors: {
                "10000": "No Response",
                "10003": "Incorrect pinpad number",
                "10022": "Incorrect pos number (ECR)",
                "10036": "XML_MISSING_TERMINAL_ID_TAG",
                "10041": "No connection to pinpad",
                "10042": "XML_MISSING_REQUEST_ID_TAG",
                "10043": "Need to do Update Catalog.\nAn error was detected when connecting to the credit terminal, if this happens again after updating information, call support\n 10043",
                "10048": "CHECK_OTHER_STATUSES",
                "10050": "DOUBLE_TRANSACTION",
                "10053": "Empty response",
                "10054": "Ashrait wrong configuration",
                "10100": "INVALID_PAN_ENTRY_MODE",
                "10101": "INVALID_MTI",
                "10102": "INVALID_X_FIELD",
                "10501": "The card wasn't swiped in time.",
                "11000": "Credit card access denied",
                "11001": "Incompatible card",
              },
              pinpadErrorTitle: "Pinpad Connection Error",
            },
            hakafa: {
              SERVER_INTERNAL_ERROR: "שגיאה כללית",
              FOUND_CUSTOMER: "לקוח נבחר",
              RECORD_NOT_EXISTS: "לא נמצא לקוח",
              MULTIPLE_CLIENTS_WITH_SAME_TZ: "MULTIPLE_CLIENTS_WITH_SAME_TZ",
              MISSING_TZ_PARAM: "MISSING_TZ_PARAM",
              BIRTHDAY_FORMAT_NOT_IN_DDMMYYYY: "תאריך אינו בפורמט של dd/mm/yyyy",
              ALL_FIELDS_ARE_MANDATORY_MISSING_VALS: "חובה להקליד ערך בכל השדות מלבד תאריך",
              NO_CLIENT_SELECTED: "חובה לבחור לקוח",
              INVALID_TZ: "מבנה ת.ז. שגוי",
              HAKAFA_ITEM_WITH_NO_CUSTOMER: "לא ניתן לשלם על חשבון הקפה ללא לקוח הקפה",
              HAKAFA_PRICE_GT_DEBIT_AMNT: "לא ניתן לזכות חשבון יותר מיתרת החוב",
              QTY_GT_ONE: "לא ניתן להציב פריט מיוחד עם כמות גדולה מאחד",
              HAKAFA_ITEM_MOST_BE_ONLY_ITEM: "פריט תשלום חוב הקפה חייב להופיע לבדו בחשבונית",

            },
            beengo: {
              itemReservedForBeengo: "פריט שמור עבור אפליקציית Beengo",
              transactionRejectedInApplication: "העסקה לא אושרה באפליקציה",
              beengoAuthorizationRejected: "בקשת לאישור בינגו נדחתה",
              waitingForUserApproval: "ממתין לאישור הלקוח...",
              beengoNotAllowedAlreadyHakafaUserSelected: "לא ניתן לשלם באפליקציה אם נבחר לקוח הקפה",
              amountZero: "לא ניתן לשלם סכום אפס",
              paymentUsingTheApplication: "תשלום באמצעות האפליקציה",
              enterBeengoUserPhoneNumber: "נא להזין את הטלפון הנייד של הלקוח",
              valueRequired: "חובה להזין ערך",
              beengoPrintMessage: "התקבל באפליקציית BEENGO, טרנזקציה:",
            },
            voucher:{
              title: "voucher type",
              selectVoucherType: "select voucher type",
              continue: "continue",
              dontExist: "This store doesn't have any vouchers",
              clear: "Clear"
            },
            pageTitle: {
              init: 'Loading...',
              employeeTimeTrack: 'Time Clock',
              homepage: 'Homepage',
              sync: 'Update Catalog',
              cashier: 'Select Cashier',
              params: 'POS Parameters',
              login: 'Login',
              lock: 'POS is locked',
              pos: 'POS',
              posPastInvoices: 'Past Invoices',
              posPastInvoice: 'Past Invoices',
              posSearchInvoices: 'Search Invoice',
              posItemStock: 'Check Inventory',
              posItemChooser: 'Select Color/Size',
              posDiscount: 'Select Discount',
              posCustomerClub: 'Club Member',
              posPayment: 'Pos Payment',
              posCloseSale: 'Close Sale',
              posSalesPerson: 'Select Salesperson',
              creditTypePicker: 'Select Credit Type',
              xreport: 'XReport',
              cancelInvoice: 'Cancel Invoice',
              creditInvoice: 'Credit Invoice',
              storeSelect: 'Select Store'
            },
            addItemsToPromotion: {
              title: 'Add items to promotion',

            },
            xReport: {
              tamashTitle: "Deffered Payments",
              tamashTotal: "Total"
            },
            qrDialog:{
              title: "QR Code",
              ok: "Close"
            },
            promotionManage: {
              title: 'ניהול מבצעים',
              searchPromotion: 'חיפוש מבצע',
              code: 'קוד',
              name: 'שם',
              template: 'סוג מבצע',
              actions: 'פעולות',
              priority: 'עדיפות',
              status: 'סטטוס',
              isActive: 'פעיל',
              isNotActive: 'לא פעיל',
              edit: 'עריכה',
              addPromotionTitle: 'הוספת מבצע',
              editPromotionTitle: 'עריכת מבצע',
              addPromotionPriority: 'עדיפות - מספר בין 1 ל 100 - ככל שהמספר נמוך, העדיפות גבוהה יותר',
              addPromotionDays: 'ימים בשבוע בהם המבצע בתוקף',
              addPromotionFromDate: 'בתוקף מתאריך',
              addPromotionToDate: 'בתוקף עד תאריך',
              addPromotionActive: 'פעיל - אם לא מסומן, המבצע לא יופעל בקופה',
              addPromotionActiveShort: 'Active',
              saveAndExit: 'שמור וסגור',
              exitWithoutSave: 'צא ללא שמירה',
              back: 'חזרה',
              addPromotion: 'הוספת מבצע',
              noTemplatesExist: 'לא נמצא סוג מבצע',
              nameCannotBeEmpty: "Value is required",
              templateCannotBeEmpty: "חובה לבחור סוג מבצע",
              priorityCannotBeEmpty: "חובה להזין עדיפות",
              priorityNeedToBeNumber: 'עדיפות צריך להיות מספר שלם גדול מ-0',
              addItemsToPromotion: 'הוסף פריטים למבצע',
              KneBeDiscountType: 'מהפריטים שנבחרו למבצע',
              KneBeDiscountValue: 'פריטים ב- ',
              KneBeMinimumBuyQuantity: 'קנה',
              cannotAddItemHasWeightOrZeroPrice: 'לא ניתן להוסיף למבצע פריט שקיל או פריט עם מחיר לצרכן 0',
              itemNotRelatedToPromotion: 'בחר',
              isItemRelatedToPromotion: 'בטל בחירה',
              discountTypeAmount: 'ש"ח הנחה',
              discountTypePercent: 'אחוז הנחה',
              discountTypeFix: 'ש"ח',
              templateNameKneBe: 'קנה X פריטים בהנחה/מחיר קבועים',
              templateNameHaZolMe: 'קנה X פריטים קבל הנחה על הזול מביניהם',
              templateNameKneKabel: 'קנה/קבל - קנה X פריטים קבל הנחה/מחיר קבוע על פריט Y',
              buyOptionAmount: 'שקלים',
              buyOptionQuantity: 'פריטים',
              kneCannotBeEmpty: 'ערך קנה שדה חובה',
              kneNeedToBeBiggerThenZero: 'ערך קנה חייב להיות מעל 0',
              minimumBuyQuantityMinimum2: 'ערך קנה חייב להיות מינימום 2',
              kabelCannotBeEmpty: 'ערך קבל שדה חובה',
              kabelNeedToBeBiggerThenZero: 'ערך קבל חייב להיות מעל 0',
              discountValueCannotBeEmpty: 'ערך הנחה שדה חובה',
              discountValueNeedToBeBiggerThenZero: 'ערך הנחה חייב להיות מעל 0',
              addItemsToPromotionBuy: 'הוסף פריטים לקבוצה 1',
              addItemsToPromotionGet: 'הוסף פריטים לקבוצה 2',
              itemsBuyCannotBeEmpty: 'חובה לבחור פריטים לקבוצה 1',
              itemsGetCannotBeEmpty: 'חובה לבחור פריטים לקבוצה 2',
              itemsCannotBeEmpty: 'חובה לבחור פריטים המשתתפים במבצע',
              toDateCannotBeLittleFromDate: 'פעיל עד תאריך לא יכול להיות קטן מפעיל מתאריך',
              errorOnLoad: 'General error loading Promotion - please try again. If the problem recurs, contact support',
              errorOnSave: 'General error saving Promotion - please try again. If the problem recurs, contact support',
            },

            currenciesNames: {
              ILS: 'New Israeli Shekel',
              USD: 'US Dollar',
              EUR: 'Euro',
              GBP: 'Pound Sterling',
              AUD: 'Australian Dollar',
              CAD: 'Canadian Dollar',
              CHF: 'Swiss Franc',
              DKK: 'Danish Krone',
              EGP: 'Egyptian Pound',
              JOD: 'Jordanian Dinar',
              JPY: 'Yen',
              LBP: 'Lebanese Pound',
              NOK: 'Norwegian Krone',
              SEK: 'Swedish Krona',
              ZAR: 'Rand'
            },
            rate: 'Rate',

            selectCurrencyPayment: {
              title: 'Select Payment Currency',
              noCurrencies: 'There are no currencies defined. (You can define them in the Back-Office)',
      unsupportedCurrency: 'Unsupported Currency - {currency}'
            },

            currencyConversionCalculator: {
              title: 'Currency Rate Calculator',
              back: 'Back',
      amount: '{currency} amount'
            },
            advanceItemSearch : {
              title: "Advanced Item Search",
              itemCodeContains: "Item Code ",
              itemDescriptionContains: "Item Description",
              brand: "Brand",
              age: "Age",
              department: "Department",
              under: "Sub",
              subcategory: "Subcategory",
              priceFrom: "Price From",
              priceTo: "To"
            },

            pickupFormDialog: {
              title: 'Pickup Details',
              id: 'ID',
              passport: "Passport",
              phone: 'Phone',
              returnDate: 'Return Date',
              flight: 'Flight',
              idIsNotValid: 'Id not valid',
              phoneIsNotValid: 'Phone not valid',
              returnDateIsNotValidFormat: "Date format is not dd/mm/yy.",
              returnDateIsNotValid: "Return date is not valid",
              returnDateIsSmallerFromToday: "Return date must be after today",
              returnDateIsBiggerMoreThanYaer: 'Return date is more than a year',
              flightIsNotValid: 'Flight is not valid',
              packaging: 'Packagings',
              notes: 'Notes',
              passengerName: 'Passenger Name',
              roomNumber: 'Room Number',
              pickup: 'Pickup',
              totalPickup: 'Total pickup',
              passportDetails: 'Passengers passport details',
              declaringSignature: 'With this signature I declare',
              acceptingItems: 'accepting this items',
            },

            reminderPickup: {
              title: 'Pickup reminder',
              content: 'Leave for pickup?'
            },

            validate: {
      required: '{input} is required',
      maxCharLength: '{input} must be less or equal to {max} characters',
      minCharLength: '{input} must be at least {min} characters',
            },

            flightCardDialog: {
              title: 'פרטי נוסע',
              originNotSelect: 'יש לבחור סוג תייר (תייר/ישראלי)',
              id: 'מספר דרכון',
              barcode: 'כרטיס טיסה',
              tourist: 'תייר',
              israeli: 'ישראלי',
              idIsNotValid: 'מספר דרכון לא תקין',
              approvalTitle: 'בחר',
      multiFlightsMode: '{from} מתוך {to}',
              barcodeOrIdAlreadyUsed: 'מספר דרכון או מספר ברקוד כבר בשימוש'
            },

            cashierStatementMultiCurrencies: {
              enter: 'Open Pos',
              exit: 'Close Pos',
              currency: 'Currency',
              currencyAmount: 'Currency Amount',
              currencyPosAmount: 'Amount in {currency}',
              cashierStatementCurrencyTitle: 'Cashier Statement Currency Amount',
              selectedCurrency: '{currency} Currency Statement',
              calcedCurrency: 'Total Calculated',
              statedCurrency: 'Total Stated',
              statedCurrencyInCurrencyPosAmount: 'Total Stated in currency {currency}',
              statedAndCalcedGap: 'Total currency declaration discrepancy',
              amount: 'Amount',
              quantity: 'Quantity',
              total: 'Total',
              type: 'Statement Type',
              totalCashInPos: 'Cash In Pos',
              totalCashStatement: 'Total statement'
            },

            vouchersWithoutInterfacesReport: {
              title: "Vouchers Report",
              print: "Print Sales",
              printSum: "Print Sum",
              selectVoucher: "Select Voucher",
              noVouchers: "There aren't any vouchers",
              periodTooLong: "The date rage must be smaller than a year",
              loadingVouchers: "Loading Voucher List",
              errorLoadingVouchers: "Error loading voucher list",
              generatingReport: "Generating Report",
            },

            banxPayment: {
              globalError: 'שגיאה בתשלום צור קשר עם התמיכה',
              customerDeclinedPayment: 'הלקוח דחה את הבקשה לתשלום',
              errorApiToken: 'שגיאת חיבור לbanx צור קשר עם התמיכה',
              responseErrorBanx: 'שגיאת בקשה מול שירות banx'
            },

            safeCash: {
              globalError: 'שגיאה בתשלום צור קשר עם התמיכה',
              customerDeclinedPayment: 'הלקוח דחה את הבקשה לתשלום',
              customerCancelledPayment: 'The Request was cancelled by the KashCash app',
              errorApiToken: 'שגיאת חיבור לKashCash צור קשר עם התמיכה',
              responseErrorSafeCash: 'שגיאת בקשה מול שירות KashCash',
              createQR: 'Create QR',
              createPaymentCode: 'Create Payment Code',
              waitingGetPaymentFromCustomer: 'Waiting Get Payment From Customer...',
              createRequestForCodePayment: 'Create Request For Code Payment KashCash...',
              codeForPay: `Code to Pay: {code}`,
              errorCancelTransaction: 'Error To Cancel Transaction KashCash',
              cancelTransaction: 'Cancel Transaction',
              errorMessage: 'Error Message From KashCash',
              safeCashPayment: 'KashCash Payment',
              scanBarcodeWithApp: 'Please scan the QR code with the KashCash app',
              validation:{
                insertValidAmount:"Amount must be greater than 0"
              },
              proccessingPayment: 'Proccessing payment, please wait...',
              seeCode: "Can not scan? Press to get code",
              codeTitle: "Payment code",
              waiting: "Waiting to receive payment from a customer",
              cancel: "Cancel",
            },
            flightSaleWithPassDialog: {
              scan: "Please scan a boarding pass:",
              name: "Full name",
              date: "Date",
              flightNumber: "Flight number",
              typing: "Enter flight details",
              continue: "Continue",
              cancel: "Cancel",
              wrongBarcode: "The barcode is too short",
              wrongDate: "The flight date is different from the allowed range",
              wrongFlightNumber: "The flight number is wrong",
              fillAllFields: "Please fill in all fields",
              scanButton: "Scan"
            },
            flightsOnlineLoginDialog: {
              loginError: "Login failed. Please try to re-open the POS software. If the problem persists - contact support",
              flightAttendantLogin: "Cashier Login",
              flightAttendantNumber: "Cashier Number",
              loginOnlyOnline: "The login requires online mode.",
              selectFlightTitle: "Select Journey",
              flightNumber: "Journey Number",
              flightEmptyError: "Please insert Journey number",
              enterFlightNumber: "Enter Journey Number Manually",
              scanQr: "Scan QR Code",
              scanningQr: "Scanning QR Code",
              scanDispatchNote: "Scan Journey's QR Code",
              flightNotFound: "Journey not found, Please try again",
              searchingFlight: "Searching Journey",
              flightDetailsTitle: "Journey Details",
              attachToFlight: "Attaching Journey",
              flightInProgressTitle: "Please Notice!",
              flightInProgressBody: "This Journey is already in progress.",
      errorUpdaingBlacklist: "Error in fetching SHVA blacklist - {code} \n Try again?",
              pinpadInitFailed: "Pinpad Initialization Failed\nContinue anyway?"
            },

            flightsDisconnectDialog: {
              checkingData: "Checking Data",
              disconnectTitle: "Disconnect Journey",
              confirmDisconnect: "Are you sure you want to disconnect from the journey?",
              cantDisconnectWifi: "Journey can't be disconnected without online mode.",
              cantDisconnectSales: "Journey can't be disconnected when it has sales.",
              errorDisconnect: "There was an error trying to disconnect the Journey, Please try again later.",
              disconnecting: "Disconnecting"
            },

            selfServiceSelectCustomer: {
              description: 'Please enter your name',
              namePlaceHolder: 'Name',
              descriptionSubtext: 'We will call by your name once the order will be ready',
            },

            selfServicePaymentMethod: {
              selectPayment: "How would you prefer to pay?",
              leftToPay: `Total Pay`,
              errorMessages:{
                noPaymentSelected: "No number of payments selected - action canceled"
              }
            },

            flightsWizard: {
              finishTitle: "Wizard completed",
              finish: "The wizard is completed. continue?",
              noMoreLegs: "There are no more legs for this Journey.",
              openLegCompleted: "Open Leg Complete",
              closeLegCompleted: "Close Leg Complete",
              commitChanges: "Commit Changes?",
              israirFinishFlight: "Are you sure you want to close the journey? Please notice, after this action you can't make sales again",
              goBack: "Go Back",
              approve: "Approve",
              thereIsOpenSale: "There is an opened sale in the pos, Please close the sale in order to continue.",
              thereIsHoldSale: "There are holded sales in the pos, Please close the hold sales in order to continue.",
              closeFlightOfflineError: "Closing the Journey is available in online mode only",
              cantOpenPosWithoutZ: "The journey is closed, please submit the Z report and open a new Journey in order to continue",

              numberOfPassengers: {
                title: "Close Leg",
                legNumber: "Leg Number",
                numberOfPassengers: "Number of passengers"
              },
              offlineLogin: {
                title: "Open Leg - Login",
                legNumber: "Leg Number",
                onlyManager: "Only main cashier may log in",
                onlyAuhtorizedTeam: "Only previous leg crew can log in",
                userNotFound: "Incorrect cashier number, Try again."
              },
              authorizesEmployees: {
                title: "Select crew",
                employees: "Crew",
                noAuthorizedEmployees: "There aren't more available cashiers"
              },
              barsetCount: {
                title: "Inventory Count",
                startCount: "Open Count",
                endCount: "Close Count",
                promptBarsetCount: "Count inventory?",
                item: "Item",
                itemName: "Name",
                calculatedCount: "Exp",
                actualCount: "Act",
                validationFailed: "Please provide a value (valid number) in all of the items.",
                diff: "Diff",
                skipWarning: "Dear Cashier, Please pay attention! Skipping the inventory count is on your own risk. By Skipping, the responsibility for missing items  will be yours."
              },
              printBarsetCountReport: {
                title: "Inventory Report",
                promptPrintReport: "Print inventory report?",
              },
              changeTeam: {
                title: "Crew Change",
                prompt: "Crew change in the next leg?",
                lastLeg: "This is the last leg of this journey.",
                attention: "Notice:",
              },
              printFlightLegSumReport: {
                title: "End of Leg Report",
                promptPrintReport: "Print End of Leg Report",
              },
              cashierStatement: {
                title: "Cash Decleration",
                currency: "Currency",
                calculated: "Exp",
                actual: "Act",
                diff: "Diff",
                total: "Total",
        totalExpInCurr: "Total Expected In {currency}",
        totalActInCurr: "Total Actual In {currency}",
        totalDiffInCurr: "Total Diff In {currency}",
        expInCurr: "Expected In {currency}",
        actInCurr: "Actual In {currency}",
        diffInCurr: "Diff In {currency}",
                overpay: "Overpay",
                underpay: "Underpay",
                equal: "Equal",
                totalCreditAppendants: "Num of CC Reciepts",
                totalCreditPayments: "Total CC Payments in USD",
                creditAppendants: "Credit Card Receipts",
                ccReceipts: "CC Receipts",
        creditPayments: "Total CC In {currency}",
              },
              enterFlightCode: {
                title: "Enter Journey Code",
                flightCode: "Journey Code"
              },
              stockCount: {
                title: "Inventory Count",
                promptBarsetCount: "Count inventory?",
                item: "Item",
                itemName: "Name",
                calculatedCount: "Exp",
                validationFailed: "Please provide a value (valid number) in all of the items.",
                diff: "Diff",
                skipWarning: "Please notice! When skipping the barset count, The count reset to the calculated amount, and the responsibility of the items quantity and missing items is yours.",
                startCount: "Strat",
                salesCount: "Sold",
                currentCount: "Currenct",
                actualCount: "Actual",
                fill: "Fill",
              }
            },
            flightsInventoryReport: {
              title: "Inventory Report",
              currentCount: "Current Count",
              startCount: "Open Count",
              endCount: "Close Count",
              flightNumber: "Journey Number",
              legNumber: "Leg Number",
              journeyKey: "Journey Key",
              date: "Date",
              origin: "Origin",
              destination: "Destination",
              user: "User",
              printed: "Printed",
              tableHeaders: {
                product: "Prod",
                packed: "P",
                counted: "C",
                diff: "D",
                countedLong: "Counted",
                start: 'Open',
                sold: 'Sold',
                diffLong: 'Diff',
              }
            },
            flightsCashierStatementReport: {
              title: "Cashier Statement",
              approvedReport: "Approved",
              notApprovedReport: "Not Approved",
              tableHeaders: {
                currency: "Cur",
                expected: "E",
                actual: "C",
                diff: "D",
              }
            },
            flightsCurrenciesReport: {
              title: "Currency Rates",
              conversion: "Conversion",
              rate: "Rate",
            },
            flightsCloseLegReport: {
              title: "End of Leg Report",
              totalPayments: "Total Payments",
              currencyPayments: "Currency Payments",
              itemSales: "Sales by item",
              discount: "Discount",
              allLegs: "All Legs",
              legNumber: "Leg Number",
              flightsCloseLegReportTable: {
                name: "Name",
                sum: "Sum",
                count: "Count"
              },
              flightsCloseLegReportCurrenciesTable: {
                type: "Type",
                currency: "Curr",
                sum: "Sum",
                creditCount: "CC"
              },
              totalPaymentsSum: "Total Payments: ",
              totalItemsSum: "Total Items: ",
              saleTypes: {
                all: "All",
                1: "Retail",
                2: "Crew",
                3: "Complimentary",
              },
              sales: "Sales"
            },

            flightZReport: {
              title: "Close Journey",
              message: "This will close the journey and transmit payments. Continue?",
              closingFlightWait: "Closing Journey, Please wait..."
            },

            barset: {
              stockUnitTypes: {
                1: 'Cart',
                2: 'Container',
              },
              drawer: 'Drawer',
            },
            emailDialog: {
              title: "שליחת מייל",
              emailLabel: "בחירת מען",
              emailSent: "המייל נשלח בהצלחה",
              emailSendError: "בעיה בשליחת המייל, אנא נסה שנית מאוחר יותר",
              noEmailError: "חובה לבחור עובדים עם מייל לשליחה. במידה והרשימה ריקה, יש להגדיר כתובת מייל לעובדים תחת מסך ניהול עובדים",
              back: "back",
              send: "send"
            },

            flightsCrewDiscountEmployee: {
              title: "Employee Code",
              body: "Employee Code For Discount",
              placeholder: "Code",
              fieldRequired: "Please fill employee code",
            },

            withdrawalReport: {
              title: "Cash withdrawal report",
            },
            techCardDialog: {
              title: "Technician Card",
              desc: "Technician card is required, Please swipe or enter card",
              placeholder: "Swipe or enter technician card",
            },
            addonFilesUpdateDialog: {
              title: "Addon Update",
              desc: "Please select a version to update",
              currentVersion:"Current Version",
            },
            addonUpdate:{
              updatingMessage:"Updating Addon...",
              success:"Update Complete",
              version:"Version",
              successMessage:"successfully updated"
            },
            employeesBonusReport: {
              title: "Employees Bonuses Report",
              seller: "Seller",
              emptyFieldForAll: "Leave empty for all employees in the store",
              bonus: "Bonus",
            },
            selectEmployeeDialog: {
              selectEmployee: "Select Employee",
              noEmployees: "There aren't any employees for this store",
            },
            employeesHoursReport: {
              title: "Employees Hours Report",
              employee: "Employee",
              emptyFieldForAll: "Leave empty for all employees in the store",
              month: "Month",
            },
            exceptionsReportDialog: {
              title: 'Irregularity Report',
              irregularReturns: 'Irregular Returns',
              manualDiscounts: 'Manual Discounts',
              priceChanges: 'Price Changes',
              noExceptionSelected: 'No Irregularity Selected',
              noDateSelected: 'No Dates Selected',
            },
            openformatReport: {
              title: 'IRS Report',
              filesLocation: 'The files needed to be saved in this location',
              datesNotSelected: 'Please select dates',
            },
            reportPromotionDialog: {
              title: 'Active Promotions Report',
              promotionName: 'Name',
              promotionCode: 'Code',
              fromDate: 'From Date',
              toDate: 'To Date',
            },
            promotionsReport: {
              title: "Promotions Report",
              promotionName: "Name",
              promotionCode: "Code",
              startDate: "Start date",
              endDate: "End date",
              nameOrCode: "N/C",
            },
            lockScreenDialog: {
              title: 'Pos is Locked',
              swipeOrPressButton: 'Please swipe a card to unlock screen or press the Fingerprint button',
              continue: 'Continue',
              fingerPrint: 'Fingerprint',
              statuses: {
                idle: 'Waiting for card swiping',
                employeeNotFound: 'Employee not found for the card/fingerprint',
                employeeNotCashierOrManager: 'Only manager/cashier can unlock the pos',
              }
            },
            fingerprint: {
              fetchingFingerprintsFromServer: "Fetching fingerprints from server",
              fetchFromServerError: "Error while fetching fingerprints from server, please ensure that the POS is online",
              updatingDeviceDB: "Updating fingerprint reader local database",
              errorInPopulating: "Error while updating fingerprint local database",
              enrollError: "Fingerprint enroll error",
              alreadyEnrolled: "The employee already has an enrlloed fingerprint",
              enrolling: "Enrolling, please put and lift your finger on the reader 3 times",
              updateFingerprintInServerError: "Error while saving fingerprint in the server",
              verifying: "Please put you finger on the reader",
              verifyingError: "Error while verifying fingerprint",
              refreshDB: "Refresh Fingerprint Reader DB",
              updatingFingerprintInServer: " Saving fingerprint in the server",
              addonUpdateIsNeeded: "Addon version is outdated, please update it via parameters screen",
              error: {
                "1001": "Fingerprint reader not found",
                "1002": "Enrollment failed",
                "1003": "User not found",
                "1004": "Fingerprint already enrolled",
                "1005": "Fingerprint quality too low",
                "1006": "Fingerprint reader's local DB is not updated",
                "-1000": "Gener error - please try again",
                default: "Error in fingerprint reader device, please contact support"
              }
            },
            loadingMessageRefresh: {
              oops: 'Oops...',
              posStuck: 'Looks like the action takes too long or stuck, Please hit the refresh button to cancel the action and refresh the pos',
              refresh: 'Refresh Pos',
            },
            itemInventoryDialog: {
              itemInventory: 'Item Inventory',
              itemInventoryError: 'Item Inventory Error',
              itemInventoryFor: 'Item Inventory For',
              itemInventoryNotFoundInventory: 'לא קיימים נתוני מלאי לפריט זה',
              itemInventoryGlobalError: 'שגיאה כללית בדיקת מלאי לפריט. צור קשר עם התמיכה',
              itemInventoryNotFoundDataFromPriority: 'לא קיימים נתוני מלאי לפריט זה בפריורטי',
              storeCode: "Store Number",
              storeName: "Store Name",
              quantity: "Quantity",
              changeSizeColor: "Change Size And Color"
            },
            checkCardBalanceDialog: {
              title: 'Balance Inquiry',
              selectCardType: 'Select Card Type',
              swipeCard: 'Swipe Card',
              chargableCard: 'Chargeable Card',
              chargableCreditCard: 'Chargeable Credit Card',
              punchCard: 'Punch Card',
              paraxel: "Parxel",
              valueCard: "Value Card",
              multipass: "Multipass",
            },
            complimentarySale: {
              cantAdd: "Can't Add Item",
              onlyComplimentaryItems: "Only Complimentary items can be added to a complimentary sale",
              notAllComplimentaryItems: "The current sale contains items that are not complimentary"
            },
            closeSaleDialog: {
              title: "Finish Sale",
              totalQuantity: "Total Quantity",
      totalAmount: "Total Amount({currency})",
      totalDiscount: "Total Discount({currency})",
      totalPayed: "Total Payed({currency})",
      actualCurrencyChange: "Given Change - {currency}",
            },

            creditSelectModal: {
              title: 'Refund Method'
            },

            weights: {
              kg: 'kg',
              g: 'gram',
            },

            mizdamenOrHakafaDialog: {
              welcome: 'Welcome',
              selectCustomerType: 'Select Customer Type',
              cannotSaleCustomerIsLocked: "This customer is locked",
              existingCustomer: 'Existing Customer',
              occasionalCustomer: 'Frequent Customer',
              swipeCustomerCard: 'Swipe Customer Card',
              happyToSeeYou: 'Glad to see you :-)',
              pleaseSwipeCard: 'Please swipe customer card'
            },
            clubMemberSelfServiceDialog:{
              welcome: 'Hello, Welcome',
              isMember: 'Are you part of a loyalty program?',
              memberClub: 'Member Club',
              existingCustomer: 'Existing Customer',
              occasionalCustomer: 'Frequent Customer',
              occasionalCustomerOnly: 'Occasional',
              enterClientDetails: 'Enter client Number',
              pleaseTypePhone:"Please insert you phone number",
              pleaseTypeHakafaIdentifier: 'Please insert the following identifiers',
              hakafaIdenfifierPlaceHolder: "ID/Card/Name/Phone",
              phone:"Phone",
              areYouNotMemberClub:"Are you not a member yet? \n Please go to registration pos",
              memberNotFound: "Not membership found with that phone number",
              hakafaNotFound: "Not Hakafa Customer was found with that identifier",
            },
            creditCardDialog: {
              title: 'Credit Card Refund',
              swipeCard: 'Swipe Card',
              manualInput: 'Manual Input',
              card: 'Card',
              swipeCardOrManualInput: 'Swipe card or input manually',
              expDate: 'Exp. Date',
              clearFields: 'Clear Fields',
            },

            priorityHakafaReportDialog: {
              title: 'דוח עסקאות',
              subtitle: 'לקוח',
              dataNotFound: 'לא נמצאו עסקאות',
              amount: 'יתרה נוכחית',
              maxObligo: 'תקרת אשראי',
              obligo: 'תקרת אשראי נוכחית',
              cannotSearchPriorityHakafaCustomerFieldIsHakafaCustomerIsMiss: 'לא ניתן לחפש לקוחות הקפה בפריוריטי חסרה הגדרה שם שדה לקוח הקפה בפריוריטי',
            },

            hakafaReportDialog: {
              title: "דוח הקפה",
              headerTitle: "Please type the customer ID number and date range",
              fromDate: "From Date",
              toDate: "To Date",
              actionDt: "Date",
              invoiceNumber: "Invoice Number",
              amount: "Amount",
              balance: "Balance",
              actioType: "Type",
              taz: "ID",
              customerNumber: "Customer Number",
              hakafaTotalAmount: 'Total Amount:'
            },

            priorityImportOrderDialog: {
              title: 'משיכת הזמנה מפריוריטי',
              labelInput: 'הקלד מספר הזמנה:',
              importOrder: 'משוך הזמנה',
              importMessage: 'מייבא הזמנה מפריוריטי...',
              errorHasItemsOnCurrentSale: `לא ניתן למשוך הזמנה שקיימים פריטים במכירה נוכחית
              יש לנקות מכירה לפני משיכת הזמנה מפריוריטי`,
      errorNotFoundItemsOnOrder: `הזמנה {order} לא נמצא נתונים`,
      errorNotFoundItemOnPos: `פריט {item} לא נמצא בקופה`,
              generalError: 'שגיאה כללית במשיכת הזמנה מפריוריטי',
      errorCannotPayOrderOnStatus: `לא ניתן לשלם על הזמנה בסטטוס {status}`,
              errorCannotAddItemDiscount: `שגיאה בהחלת הנחת לפריט`,
            },

            creditInvoiceReplaceItem: {
              cannotReplaceSaleReceipt: 'לא ניתן לבצע החלפה לקבלה',
              alreadyHasOpenedReplacementSale: 'Please reset the current replacement sale in order to make a new replacement sale',
              cantReplaceMinusSale: 'It is not possible to credit sale that has items with negative quantity',
              cantReplaceWhenThereAreNoItemsSelected: 'Replacing items is not permitted if there  are no items selected',
              managerAprrovalRequired: 'Manager approval is required'
            },
            hakafaCustomerDialog: {
              title: 'Hakafa Customer',
              errorRequestCustomersPriority: 'שגיאת תקשורת פריורטי',
              isParentHakafaCustomer: 'האם לקוח מרכז?',
              parentHakafaCustomer: 'לקוח מרכז',
              noParentHakafaCustomer: 'לא נמצא לקוחות מרכזים',
              errorCannotAddNewCustomerWhenPosOffline: 'לא ניתן להוסיף לקוח הקפה שהקופה בנתק',
              errorCannotEditCustomerWhenPosOffline: 'Cannot edit customer when the POS is offline',
              errorCannotSetParentCustomerToParentCustomer: "לא ניתן להגדיר לקוח מרכז ללקוח מרכז",
              messageImportParentHakafaCustomers: 'מייבא לקוחות מרכזים...',
              errorImportParentHakafaCustomers: 'שגיאה ביבוא לקוחות מרכזים',
              addNewCustomer: 'Add New Customer',
              search: 'Search',
              customerSearch: 'Customer Search',
              showAllCustomers: 'Show All Customers',
              advancedSearch: "Advanced Search",
              existCustomer: 'Exist Customer',
              allCustomers: 'All',
              active: 'Active',
              locked: 'Locked',
              group: 'Groups',
              noGroups:"Groups Not Found",
              customerNumber: "Customer Number",
              tazChetpey: 'ID / Company Number',
              name: "Name",
              phone: "Phone",
              dateOfBirth: "Date Of Birth",
              balance: "Balance",
              obligo: "Obligo",
              discountPercent: "Discount Percent",
              customerDiscountPercent: "Discount Percent",
              actions: "Actions",
              selectCustomer: 'Select',
              showCustomer: "Show",
              previousTransactions: 'Previous Transactions',
              report: "Report",
              transactionsReport: "Transactions Report",
              merakezInvoice: "Merakez Invoice",
              noCustomersFound: "No Coustomers Found",
              customerNumberCard: "Customer Number / Card",
              customerNumberPlaceholder: "Insert Phone/ID in case where there isnt a card number",
              firstName: 'First Name',
              lastName: 'Last Name',
              isTamashCustomer: "Shipment Docs Customer",
              isLocked: "Locked Customer - Cannot make any purchase",
              comments: "Comments",
              addCustomer: 'Add Customer',
              save: "Save",
              searchPlaceholder: "By ID/Card/Name/Phone",
              edit: 'Edit',
              offlineError: 'POS must be online in order to make credit sales',
              savingCustomer: 'Saving Customer',
              searchingCustomer: 'Searching - Please Hold',
              checkIfCustomerExists: 'Checking If Customer Already Exists - Please Hold',
              pageInfo: "Page {page} Of {pages}",
              commets: "Comments",
              customerComments: 'Customer Comments',
              errors: {
                negativeCustomerNumber: "Customer Number cannot be less than zero",
                tazRequired: "ID / Company Number is required!",
                tazLength: 'ID Cannot be longer than 9 digits!',
                firstNameRequired: "First Name is required!",
                phoneNumberRequired: "Phone is required!",
                discountPercent: 'Discount Percent must be numerical',
                obligo: 'Obligo Percent must be numerical',
                customerNumberExists: "Customer with this ID is already exists",
                generalError: 'General Error',
                customerNotFound: 'Customer Not Found',
              },
              addingCustomerMessage: "Adding Credit Customer",
              merakez: "Merakez"
            },
            hakafaMerakezInvoiceDialog: {
              title : "חשבונית מרכזת",
              search: "Search",
              cancel: "Cancel",
              newInvoice: "New Invoice",
              latestInvoices: "Latest Invoices",
              newView: {
                title: "Open Documents",
                fromDate: "From Date",
                toDate: "To Date",
                invoiceDate: "Invoice Date",
                select:"Select",
                invoiceNumber: "Invoice Number",
                date: "Date",
                amount: "Amount",
                leftToPay: "Left To Pay",
                invoiceType: "Invoice Type",
                noInvoices: "No Open Documents",
                createInvoice: "הפק חשבונית מרכזת",
                selectAll: "Select All",
                clearSelection: "Clear Selection",
                customer: "Customer",
                totalToPay: 'Total To Pay'
              },
              indexView: {
                title: "חשבוניות מרכזות אחרונות",
                merakezInvoiceNumber: "מספר חשבונית מרכזת",
                invoiceNumber: "Invoice Number",
                date: "Date",
                amount: "AMount",
                details: "Details",
                pageInfo: "Page {page} Of {pages}",
                fromDate: "From Date",
                toDate: "To Date",
                customer: "Customer"
              },
              showView: {
                title: "חשבונית מרכזת מספר {invoiceNumber}",
                invoiceNumber: "Invoice Number",
                docNumber: "Invoice Type",
                rowNumber: "Row Number",
                customer: "Customer",
                invoiceDate: "Invoice Date",
                date: "Date",
                amount: "Amount",
                leftToPay: "Left To Pay",
                owsVat:'Total Without Vat',
                vat: 'Vat',
                printSource: "Print Spurce",
                printCopy: "Print Copy",
                back: "Back",
                taxConfirmationNumberShort: "Confirmation Number",
                taxConfirmationNumberFull: "Full Confirmation Number",
                transmitToTaxAuthority: "Transmit To Tax Authority",
              },
              messages: {
                searchingInvoice: 'Searching Invoice',
                noCustomerInvoices: 'אין חשבוניות מרכזות ללקוח',
                noInvoiceFound: 'לא נמצאה חשבונית מרכזת',
                noInvoicesSelected:"לא נבחרו פריטים",
                printing: "Printing",
                invoiceDateErrorHeader: 'Invalid Date',
                invoiceDateCannotBeGreater: 'Invoice date can not be greater than today',
                invoiceDateCannotBeLesser: 'תאריך החשבונית אינו יכול להיות קטן מתאריך חשבונית מרכזת אחרונה שהוא',
                offlineMode: "לא ניתן ליצור חשבוניות מרכזות כשהקופה בנתק",
                loadingInvoices: "Loading Invoices",
                savingMerakezInvoice: "שומר חשבונית מרכזת...",
                merakezetCreatedSuccess: 'Invoice Created Successfully',
                searching: "Searching",
                noItemsSelected: "No items selected",
                amountIsHigherThanLeftToPay: "The inserted amount is higher than the left to pay amount",
                amountIsHigherThanLeftToRefund: "The inserted amount is higher than the left to refund amount",
                amountCannotBeNegative: "The inserted amount cannot be negative",
                amountCannotBePositive: "The inserted amount cannot be positive",
              }
            },

            customerSalesReportDialog: {
              titleHakafaCustomers: 'דוח מכירות לקוחות הקפה',
              titleCustomers: 'דוח מכירות מועדון לקוחות',
              noCustomers: 'לא נמצאו לקוחות',
              bonus: 'עמלה',
              loadingCustomers: 'טוען לקוחות...',
              loadingReport: 'טוען דוח...',
              printReport: 'הדפס דוח',
              shoeReport: 'הצג דוח',
            },

            showCustomerSalesReportDialog: {
              store: 'Store',
              fromDate: 'From Date',
              toDate: 'To Date',
              bonusPercent: 'Bonus Percent',
              invoiceNumber: 'Invoice Number',
              date: 'Date',
              time: 'Time',
              customer: 'Customer',
              quantity: 'Quantity',
              invoiceType: 'Invoice Type',
              total: 'Total',
              noData: 'There is no data'
            },

            customerPointsDialog: {
              title: 'Customer Club - Use Points',
              remainingPoints: 'Remaining Points',
              saleUsablePoints: 'Usable Points',
              points: 'Points',
              total: 'Amount',
              fillValidNumber: 'Please fill a valid points number',
              continueWithoutPoint: 'Continue without points',
              minPointsToUse: 'Minimum points to use',
              multiplierPointsUse: 'Points usage multiplier',
            },

            sendInvoiceSmsDialog: {
              title: "Send Invoice SMS",
              phone: "Phone number",
              send: "Send SMS",
              invalidPhone: "Invalid Phone Number",
              mustBeOnline: "It is not possible to send SMS in offline mode",
              saleNotSelected: "Please select invoice",
              saleNotSychronized: "Please wait until the sale is synchronized with the server",
              notAllowed: "This pos is not allowed to send sms",
              requestFailed: 'There was an error sending request to the server, Please try again. If the problem persists, Contact support.',
              requestServerMessage: 'Sending request, plase wait...',
              smsInvoiceButton: "Send as SMS",
      lessThenXMessagesLeft: "There are only {quota} SMS Messages left, please contact support for purchasing more messages. If there are no messages left, the messages will not send.",
              noMessagesLeft: "There are'nt any SMS Messages left, please contact support for purchasing more messages.",
              printOnly: 'Print Only',
              smsOnly: 'SMS Only',
              smsAndPrint: 'Print and SMS',
              doNotSendSms: 'Do not send SMS',
            },
            tenbis: {
              generalErrorComunication: 'There was an error communicating with tenbis, please try again.  If the problem persists, Contact support.',
              noUserPasswordConfigured: 'Some of the tenbis configurations are missing, please contact support for configuring the necessary configurations',
            },
            mishloha: {
              generalErrorComunication: 'There was an error communicating with tenbis, please try again.  If the problem persists, Contact support.',
              discount: "Discount",
              generalError: "Error - Please try again.\nIf the problem persists - contact support.",
              paymentName: "אתר משלוחה",
              creditCardPaymentName: "אשראי ישיר משלוחה",
            },
            splitSalePaymentDialog: {
              title: 'Split Payment',
              amountReminding: 'Sale total',
              amountToPay: 'Payment amount',
              invalidAmount: 'Invalid number, please enter a number greater than 0 and smaller than the sale total',
              selectedItemsAmount: "Selected items amount"
            },


            selectDinersNumberDialog: {
              title: 'Select Diners',
              numberOfDiners: 'Number of diners',
              invalidNumberOfDiners: 'Invalid number, please enter number that greater than 0',
              aboveNineDines: 'Please note: The number of diners is above 9, would you like to continue?'
            },
            selectIItemQuantityDialog: {
              title: 'Item Quantity',
              errors:{
                belowTwoItems: 'It is not possible to update a quantity below 2.',
                above99Items:"It is not possible to update a quantity above 99"
              },
            },

            splitSalePayment: {
      partialItemOrder: 'Partial payment for order {orderNumber}',
      partialItemFor: 'Partial payment on invoice {invoiceNumber}',
      noGenericItemFound: 'There are no general item for the action! Please configure an item with the code {item_code}',
              splittedSaleExists: 'Splitted sale already exists, please contact support',
              cantRestartSale: 'Sale with splitted payment can\'t be deleted, Do you want to delete the items?',
              splittingSale: 'Splitting sale, please wait...',
            },

            splitPaymentAmountDialog: {
              title: 'Split Payment',
              splitPaymentQty: 'Number of Diners',
              invalidAmount: 'Invalid number, please enter a number greater than 0 and smaller than the sale total',
            },

            blockItemPaymentMethod: {
              errorTitle: 'חסימת אמצעי תשלום',
              smartVouchers: { tenbis: 'תן ביס', cibus: 'פלאקסי', banx: 'banx' },
              hakafa: 'הקפה',
              blockItem: 'פריט חסום',
              blockItems: 'פריטים חסומים',
              errorAfterBlockItems: 'במכירה לתשלום באמצעי תשלום',
      errorBlockPaymentOnSale: 'לא ניתן להוסיף פריט {itemDescription} כשיש במכירה תשלום ב{paymentName} שחסום לתשלום עם הפריט',
      voucher: 'שובר {voucherName}',
              credit: "אשראי",
              cash: "מזומן",
              vouchers: "תווים",
              creditVoucher: "שובר זיכוי",
              check: "צ'ק",
              item:"הפריט",
              items:"הפריטים",
              blockedToItems:'למכירה לאמצעי התשלום הבאים:',
              isBlocked:"חסום",
              areBlocked:"חסומים",
            },
            itemWithGroupsDialog: {
              employeeCannotEditItemWithBonPrint: 'Employee does not have the permission to edit item that has been printed',
              back: 'Back',
              next: 'Next',
              addItem: 'Add Item',
              cancel: 'Cancel',
              confirm: 'Confirm',
              deleteRow: 'Delete Row',
              editItem: 'Edit Item',
              groupInfoGroupName: 'Group name: {groupName}, Number of items selected in this group: {numberOfItemsSelectedInCurrentGroup}',
              groupInfoMinimumItems: 'Minimum items to choose: {minItemsToChoose}',
              groupInfoMaximumItems: 'Maximum items to choose: {maxItemsToChoose}',
              groupInfoFreeItems: 'Number of free items allowed to choose: {numberOfFreeItems}',
              minimumItemsValidationMessage: "Need to choose at least {minItemsToChoose} from group '{groupName}' before you can continue",
              cannotAddItemMessage: "Cannot add item, at least {minItemsToChoose} need to be chosen from group '{groupName}.'",
              cancelMessage: "Would you like to cancel?",
              loading: 'Loading...',
              maximuItemsSelectedMessage: 'You can no longer select items in this group - Maximum items selected',
              maximuItemsSelectedMessageShort: 'You can no longer select items in this group',
              groupTitle: "Choose {groupName} from item: {itemDescription}",
              chooseAddon: "pick addons for {itemDescription}",
              selfServiceGroupTitle: "Selected {itemsSelectedQuantity} From {quantityItemsToSelected}",
              dalpakTableViewMobileItemsGroupTitle: "Selected {itemsSelectedQuantity} From {quantityItemsToSelected}",
              continue: 'Continue',
              minimalChoiceNotComitted: 'Please select al least {itemText}',
              oneItem: 'one item',
              xItems: '{count} items',
              addition: 'Add',
              notes: 'Notes',
              add: 'Add',
            },
            preparationInstructionsDialog: {
              next: "Next",
              cancel: "Cancel"
            },
            checkPosProprietyDialog: {
              title: 'Pos Valid State Check',
              message: `Pos did not pass validity check yet \nPress OK to begin`,
              loadingMessage: 'Performing Test...',
              failedMessage: 'Pos is not in a valid state, please contact support',
              passedMessage: 'Pos validity test passed successfully',
              generalError: 'An error occured whilte performing validity test. Please contact support.',
              updateDataMessage: 'Please perform update catalog first',
              close: 'Close',
            },
            externalDeliveryOrders: {
              notItemsErrorTitle: "לא ניתן להפעיל בקופה ממשקי משלוחים או הזמנות",
              notItemsError: "<b>שים לב! לא ניתן להפעיל בקופה ממשקי משלוחים או הזמנות</b>\nנא לוודא כי קיימים הפריטים הבאים במערכת:",
              missingItems: {
                pickup: "פריט איסוף עצמי ({itemCode})",
                delivery: "פריט משלוחים ({itemCode})",
                generalItem: "פריט כללי להזמנות ({itemCode})",
                tenbisApiDiscountItem: "פריט תן-ביס/פיידאיט ({itemCode})"
              },
              failedDeliveriesMessage:  "Order {orderNumber} from {interface} failed, please contact support."
            },

            selectDalpaksDialog: {
              title: 'Select Counter',
              areaTitle: 'Select Area',
              addNewDalpak: 'Add Counter',
              addNewDalpakArea: 'Add Area',
              noDalpaks: 'No Counters Found',
              actions: 'Actions',
              select: 'Open',
              salesperson: 'Sales Person',
              openedHour: 'Opened at',
              refresh: 'Refresh',
              noDalpaksFound: 'Please Note! There are no counters found, please contact support.',
              lockedDalpakActions: 'The counter is locked',
              clickDalpakToSelect: 'Click on the counter to enter',
              clickDalpakAreaToSelect: 'Click on the area to enter',
              forDalpakActions: 'For counter actions',
              forDalpakAreaActions: 'For area actions',
              customer: 'Customer',
              area: 'Area',
              table: 'Table',
              waiter: 'Waiter',
              avgPerDiner: 'Avg per diner',
              changeWaiter: 'Change Waiter',
              moveTable: 'Move Table',
              printSale: 'Print Sale',
              minutesAgoShort: 'm.',
              selectTable: 'Select Table',
              openDrawer: 'Open Drawer',
              totalTable: 'Table Total',
              enterTable: 'Enter Table',
              editTables: 'Edit Tables',
              changeArea: 'Change Area',
              openingHour: 'Opening Time',
              status: 'Status',
              customerName: 'Customer',
              delete: 'Delete',
              cancelChangesTitle: 'Cancel Changes',
              cancelChangesContent: 'Are you sure you want to exit tables edit mode wihtout saving?',
              addTable: 'Add Table',
              deleteTablePrompt: 'Are you sure you want to delete this table?',
              cantDeleteTableWithSale: 'Tables with sales cannot be deleted',
              changeToArea: 'Change to',
              addArea: 'Add Area',
              editArea: 'Edit Area',
              pleaseSelectArea: 'Please Select Area',
              deliveryManagement: "Delivery management",
              assignCouriers: "Assign Couriers",
              closeDeliveries: "Close Orders",
            },
            areaAttributesDialog: {
              newAreaTitle: 'Add Area',
              editAreaTitle: 'Edit Area',
              areaName: 'Area Name',
              sortOrder: 'Location (1 - First, 2 - Second...)',
              areaNameIsRequiredField: 'Area name is reuqired',
              areaNameAlreadyExists: 'Area name already exists',
              sortOrderIsRequiredField: 'Sort order is required',
              deleteArea: 'Delete Area',
            },
            tableAttributesDialog: {
              newTableTitle: 'Add Table',
              editTableTitle: 'Edit Table',
              tableName: 'Table Name',
              tableDiscount: 'Fixed Discount (Percentage)',
              tableNameEmpty: 'Table name is requires',
              tableDiscountNotValid: 'Fixed discount must be a number between 0 and 100',
              dalpakAreaMandetory: 'Dalpak area is required',
              selectArea: 'Select area',
              tableNameAlreadyExists: 'Table name already exists',
            },
            deleteAreaDialog: {
              title: 'Delete Area',
              content: 'There are tables in this area - Please select area to move the tables into',
            },
            searchDalpakDialog: {
              title: 'Search Table',
              inputToSearch: 'Search',
              inputToSearchPlaceHolder: 'Search by Customer name, table or waiter',
            },
            filterDalpakDialog: {
              title: 'Filter Tables',
              waiter: 'Waiter',
              tableStatus: 'Table Status',
            },
            moveTableComponent: {
              dialogTitle: 'Move Table',
              from: 'From',
              to: 'To',
              partialMovement: 'Partial Movement',
              partial: 'Partial',
              locateTable: 'Locate Table',
              tablesMandatory: 'From table and to table are mandetory',
              printBon: 'Print move bon',
            },
            changeWaiterComponent: {
              dialogTitle: 'Change Waiter',
              table: 'Table',
              waiter: 'Waiter',
              locateWaiter: 'Select Waiter',
              waiterMandatory: 'A waiter is mandatory',
            },
            positiveInput: {
              selection: 'Select {label}',
              selectedOptionNotInOptions: 'Invalid Option',
            },
            createDalpakDialog: {
              title: 'Create New Counter',
              areaError: 'Area is required.'
            },
            createDalpakAreaDialog: {
              title: 'Create New Area',
              firstAreaMessage: "First Area - All counters will automatically be attached to this area"
            },
            selectDalpakAreaModal: {
              title: 'Pick which area to transfer the counters to',
              noDalpakAreaSelectedMessage: 'No area selected'
            },

            dalpakActionsDialog: {
              printMoveBon: 'Print Transfer Bon',
              clearSale: 'Delete Sale',
              clearingSale: 'Deleting sale...',
              confirmSaleDeleteion: 'Please Notice! This action will delete all the sale data, And it will not be possible to restore. Are you sure you want to continue?',
              editDalpak: 'Edit Counter',
              title: 'Counter Actions',
              update: 'Update',
              delete: 'Delete Counter',
              moveDalpak: 'Move Sale',
              sourceDalpak: 'From Counter',
              targetDalpak: 'To Counter',
              move: 'Move',
              printInvoice: 'Print Invoice',
              noFreeDalpaks: 'No Available Counters',
              modbusActions: 'Modbus Actions',
              turnModbusOn: 'Light On',
              turnModbusOff: 'Light Off',
              edit: 'Edit',
              editDalpakPermissionError: 'You do not have permission to make changes to the counter'
            },
            dalpakAreaActionsDialog: {
              title: 'Area Actions',
              update: 'Save Area',
              delete: 'Delete Area',
              editDalpak: 'Edit Area',
              edit: 'Edit',
              editDalpakAreaPermissionError: 'You do not have permission to make changes to the counter area'
            },

            dalpaks: {
              errorClosedSaleDalpak: 'Please notice! There was an error cleaning the dalpak sale after closing. clean up will be done now. Please contact support for further treatment.',
              saleWithPayments: 'Please notice! This sale has payments and can\'t be opened from another pos, so the counter will remain locked.',
              cantReleaseOrderInDalpak: 'Please exit the dalpak before realeasing order',
              cantAddItemToDeliveryDalpak: 'New sales are not allowed in a delivery counter, please exit the counter',
              mustBeOnline: 'Please make sure that the POS has a working internet connection.',
              loadingDalpaks: 'Loading Counters',
              errorLoadingDalpaks: 'There was an error loading the counters, please contact support',
              errorConnectingToDalpakServer: 'There was an error connecting to the counter server, please contact support',
              order: 'Order',
              name: 'Name',
              color: 'Color',
              dalpakNameMissing: 'Counter name is mandatory',
              invalidOrder: 'The order field must be an integer',
              invalidFixedDiscount: 'The fixed discount field must be numeric and between 0 and 100',
              creatingDalpak: 'Creating counter, Please wait...',
              updatingDalpak: 'Updating counter, Please wait...',
              deletionDalpak: 'Deleting counter, Please wait...',
              enteringDalpak: 'Entering counter, Please wait...',
              releasingDalpak: 'Releasing counter lock, Please wait...',
              lockingDalpak: 'Locking counter, Please wait..',
              table: 'table',
              dalpakIsLocked: 'The {dalpak} is locked by {lockedBy}',
              namedDalpakIsLocked: 'The {dalpak} {name} is locked by {lockedBy}',
              updatingTables: 'Updating tables, Please wait...',
              pleaseSelectDalpak: 'Please select counter',
              dalpakDoesntHasSale: 'Counter has no sale',
              activeDalpakPaymentCannotBeTransfered:'It is not possible to move a table where a partial payment has been made',
              targetDalpakHasSale: 'There is a sale in the target {dalpak}',
              generalError: 'There was an error, please contact support',
              movingSale: 'Moving Sale',
              printing: 'Printing...',
              savingDalpaks: 'Saving dalpkas, please wait...',
              restartSaleFailed: 'There was an error restarting the sale. please contact support',
              communication: 'Communication Status',
              serverStatusDisconnected: 'Disconnected',
              serverStatusAuth: 'Connecting...',
              serverStatusConnected: 'Connected',
              socketBonNotConfigured: 'Can\'t print bon, dalpak web connection not configured',
              mainPosConnectionProblem: 'Primary pos connection problem - please verify primary pos connection',
              moveToOffline: 'Work In Offline Mode',
              moveToOnline: 'Work In Online Mode',
              continueAsOffline: 'Continue In Offline Mode',
              reconnectingToSync: 'There is a connection to the server. Do you want to sync the data and contie work in online mode?',
              actionCanceled: 'Action cancelled',
              minusOneSaleExists: 'A -1 sale exists. if the pos is new, please do -1 sale clean. otherwise, contanct suppoer to prevent data loss',
              deliveryOnNotDeliveryPos: 'The counter contains delivery. Only deliveries poses allowed to access deliveries sales',
              noDalpakAreaOptions: "No area options",
              fixedDiscount: "Fixed Discount (%)",
              errorDiscountNotFound: 'No discount has been found. please configure a sale discount with the code 2',
              errorNoNewPromotions: 'The counter sale discount works only with new promotions system. Please enable it in order to get automatically sale discount',
              statuses: {
                '0': 'Available',
                '1': 'Active',
                '2': 'Finishing',
                '3': 'Alert',
              },
              unableToTransferItems: "Please note - in this sale there are items that require transferring the entire sale. It is not possible to transfer only part of the items in this sale to another table. Do you want to transfer the entire sale to another table?",
              someItemsCantBeMoved : "Some of the items cannot be transferred, you can either transfer the entire sale or remove these items.",
              toMoveSale: "Move Sale"
            },
            dalpakAreas: {
              loadingDalpakAreas: 'Loading Cunter Areas...',
              errorLoadingDalpakAreas: 'There was an error loading the counter areas, please contact support',
              dalpakAreaNameMissing: 'Area name is required',
              creatingDalpakArea: 'Creating area, Please wait...',
              deletingDalpakArea: 'Deleting area, Please wait...',
              updatingDalpakArea: 'Updating area, Please wait...',
              savingDalpakAreas: 'Saving area, Please wait...',
              order: 'Order',
              name: 'Name',
              color: 'Color',
              transferingDalpaks: "Transfering counters...",
              noDalpaks: "No counters in this area",
              dalpaks: "Counters",
              transferDalpaks:"Transfer Counters",
              noDalpakOptions: "No counter options"
            },
            dalpakOrders: {
              cantSaveOrder: 'Error saving order. Details: ',
              updatingOrder: 'Updating order, please wait...',
              failedToDeleteOrders: 'There was an error connecting to the sync server. please validate the network connection and try again.'
            },
            syncServer: {
              errors: {
                serverError: 'There was an error with the sync server. Please contact support.',
                outOfStockItemsCannotUpdate: 'Please check your internet connection and try again. If the problem recurs, please contact the support team.',
              }
            },

            addItemDialog: {
              title: 'Add Item',
              itemCode: 'Barcode',
              name: 'Item Name',
              price: 'Price',
              insertValidPrice: 'Please insert a valid price',
              fillAllFields: 'Please fill all the the fields',
              addingItemPleaseWait: 'Adding item, please wait...',
      noGenericItemFound: 'There are no general item for the action! Please configure an item with the code {item_code} in the BO',
      itemNotFoundCreate: 'Item {code} not found. Do you want to create it?',
              save: 'Save',
              saveAndAddToSale: 'Save & Add to sale',
              invalidNameChar: 'Item name can\'t contain special characters',
              generalError: "שגיאה כללית - אנא נסה שנית. במידה והבעיה חוזרת על עצמה יש לפנות לתמיכה",
              hasWeight: 'Has weight',
              noVat: 'No vat item',
              primaryCategory: 'Category',
              noCategory: 'No category'
            },

            customerSaleItemLimit: {
              checkingCustomerItems: 'Checking customer items, please wait...',
      boughtMoreThenOnce: 'Please notice, The customer {customer}, bought the item {item} at {day} at the hour {hour}.\nWould you like to continue?',
      boughtMoreThenQty: 'Please notice, The customer {customer}, bought the item {item} in the last {timespan} {allowedQty} times. Would you like to continue?',
      wantsToByMoreThenAllowedQty: 'Please notice, There is a buyinh limit on the item {item} - {allowedQty} per {timespan}. The current quantity is {currentQty}.\nWould you like to continue?',
            },

            timespans: {
              day: 'day',
              week: 'week',
              month: 'month',
            },

            // EMV TRanslitaion
            emvAshStatusCodes: {
              "000": 'Approved',
              "001": 'Blocked card',
              "002": 'Stolen Card, confiscate the card',
              "003": 'Call the Credit Company',
              "004": 'transaction not approved',
              "005": 'Fake Card, confiscate the card',
              "006": 'Reject transaction: cvc2 / id incorrect',
              "007": 'Reject transaction: cavv / ucaf incorrect',
              "008": 'Declined wrong transaction',
              "009": 'Card rejected',
              "010": "Partial Approved",
              "011": 'Reject Transaction: Lack of Points / Stars / Miles / Other Benefit',
              "012": "An unauthorized terminal card",
              "013": 'Deny request - wrong balance code',
              "014": 'Rejection - Unaffiliated Network Card',
              "015": 'Reject transaction: card is invalid',
              "016": 'Rejection - No Currency Type Permission',
              "017": 'Rejection - No Credit Type Transaction Authorization',
              "026": 'Decline transaction - wrong id',
              "041": "There is a query obligation for only a ceiling for a transaction with parameter j2",
              "042": 'There is a query requirement not only for a ceiling, for a transaction with parameter j2',
              "051": 'Missing vector file 1',
              "052": 'Missing vector 4 file',
              "053": 'Missing vector 6 file',
              "055": 'Missing vector 11 file',
              "056": 'Missing vector 12 file',
              "057": 'Missing vector 15 file',
              "058": 'Missing vector file 18',
              "059": 'Missing 31 File File',
              "060": 'Missing 34 vector file',
              "061": 'Missing 41 vector file',
              "062": 'Missing vector file 44',
              "063": 'Missing 64 vector file',
              "064": 'Missing 80 file',
              "065": 'Missing 81 File File',
              "066": 'Missing 82 vector file',
              "067": 'Missing 83 File File',
              "068": 'Missing vector file 90',
              "069": 'Missing 91 vector file',
              "070": 'Missing vector file 92',
              "071": 'Missing vector 93 file',
              "073": "PARAM_3_1 file missing",
              "074": 'Missing file PARAM_3_2',
              "075": 'PARAM_3_3 file is missing',
              "076": 'Missing PARAM_3_4 file',
              "077": 'file PARAM_361 missing',
              "078": 'PARAM_363 file missing',
              "079": 'PARAM_364 file missing',
              "080": 'Missing PARAM_61 file',
              "081": 'PARAM_62 file missing',
              "082": 'PARAM_63 file missing',
              "083": 'file missing CEIL_41',
              "084": 'CEIL_42 file missing',
              "085": 'file missing CEIL_43',
              "086": 'CEIL_44 file missing',
              "087": 'Missing DATA File',
              "088": 'missing JENR file',
              "089": 'Start File Member',
              "098": 'ERROR_IN_NEG_FILE',
              "099": 'General error',
              "101": 'Missing entry to Vector 1',
              "103": 'There is no entry for Vector 4',
              "104": 'Missing entry in Vector 6',
              "106": 'There is no entry for Vector 11',
              "107": 'There is no entry for Vector 12',
              "108": "Missing the 15th entry",
              "110": 'There is no entry for Vector 18',
              "111": 'There is no entry for Vector 31',
              "112": 'There is no entry for Vector 34',
              "113": 'There is no entry for Vector 41',
              "114": 'There is no entry for Vector 44',
              "116": 'Missing the 64 entry',
              "117": 'Missing entry 81 vector',
              "118": 'There is no entry for Doctor 82',
              "119": 'There is no entry for Vector 83',
              "120": "Missing the 90th entry",
              "121": 'Missing entry 91',
              "122": 'There is no entry for Vector 92',
              "123": 'Missing the 93 entry',
              "141": 'Missing proper login in parameter file 3.2',
              "142": 'Missing proper login in parameter file 3.3',
              "143": 'Club 3.6.1 file domain missing',
              "144": 'Club 3.6.3 file domain missing',
              "145": 'Club 3.6 domain file missing',
              "146": 'PL 4.1 card ceiling file missing',
              "147": 'Ceiling file missing for Israeli tickets other than PL Method 0 4.2',
              "148": 'Ceiling file missing for Israeli tickets other than PL Method 1 4.3',
              "149": 'There is no entry for a tourist card ceiling file 4.4',
              "150": 'Is missing a valid ticket file - Isracard',
              "151": 'There is missing a valid ticket file - as',
              "152": 'Missing valid ticket file - future issuer',
              "182": 'Vector 4 error',
              "183": 'Error in Vector Values ​​6/12',
              "186": 'Error in vector values ​​18',
              "187": 'Vector 34 error',
              "188": 'Vector 64 error',
              "190": 'Vector 90 error',
              "191": "Invalid data from issuer permissions",
              "192": "No data in the parameter set",
              "193": 'Non-Invalid Data in Terminal Level Parameters File',
              "300": 'No transaction type authorization - clearing permission',
              "301": 'No Currency Permit - Cleared',
              "303": 'No transaction authorization has been cleared when the card is not present',
              "304": 'No Credit Authorization - Permitted',
              "308": 'No Pinning Permission - Removed Permission',
              "309": 'No credit authorization has been cleared on a fixed date',
              "310": 'No authorization to enter a pre-authorization number',
              "311": "You are not authorized to transact service code 587",
              "312": 'No credit authorization has been cleared',
              "313": 'No Benefit Redeemed',
              "314": "No promotional authorization has been cleared",
              "315": "No specific permission code has been cleared",
              "316": 'No debit authorization for a charge transaction',
              "317": "No debit / discharge authorization in the form of payment code combined with currency code",
              "318": 'No currency clearing authorization for this type of credit',
              "319": 'No permission to remove tip',
              "341": 'No transaction authorization - issuer authorization',
              "342": 'No Currency Permit - Issuer Permission',
              "343": 'There is no issuer authorization to execute a transaction when the card is not present',
              "344": 'No Credit Authorization - Issuer Authorization',
              "348": "No authorization to execute an application initiated by a retailer",
              "349": 'There is no proper authorization to execute an application without a J5 transaction',
              "350": 'No Benefit Issuer Authorization',
              "351": 'There is no issuer authorization for deferred credit',
              "352": 'No issuer authorization for a charge transaction',
              "353": 'No issuer authorization to load / unload in payment method code',
              "354": "There is no currency issuer authorization for this type of credit",
              "381": 'No contactless transaction is allowed over a maximum amount',
              "382": "In the self-service terminal, only self-service transactions can be performed",
              "384": 'Terminal is set to multi - provider / beneficiary - missing provider / beneficiary number',
              "385": "In the e-commerce terminal that is required to transmit eci",
              "401": 'Number of payments is greater than Maximum Payments field value',
              "402": 'Payment Number is Less Than Minimum Payment Field Value',
              "403": 'The transaction amount is less than the minimum field value for payment!',
              "404": 'No number of payments field entered',
              "405": 'Missing First / Fixed Payment Amount',
              "406": "Total transaction amount is different from first payment amount + fixed payment amount * number of payments",
              "408": 'Channel 2 is shorter than 37 characters',
              "410": 'dcode party rejection',
              "414": "In transaction with fixed date billing, sub-date of transaction is entered",
              "415": 'Invalid data entered',
              "416": "Expiration date not in proper format",
              "417": 'Terminal / POS number does not match what is set in PinPad',
              "418": 'missing essential parameters',
              "419": 'ClientInputPan property passed error',
              "420": "No card number next to me - in the mode of entering Channel 2 in a transaction without a current card",
              "421": 'General Error - Non-Valid Data',
              "422": 'ISO message build error',
              "424": 'non-numeric field',
              "425": 'double entry',
              "426": 'Amount increased after credit checks',
              "428": 'Service card missing card',
              "429": 'Card is not valid by valid ticket file',
              "431": "General error",
              "432": 'No license to transfer a card through a magnetic reader',
              "433": 'Transfer Charge on PinPad',
              "434": 'Card must not be transferred on the PinPad',
              "435": 'Device not configured to transfer CTL magnetic card',
              "436": 'Device not configured to transfer EMV CTL card',
              "439": "No credit type authorization by transaction type",
              "440": 'Tourist card is not authorized for this type of credit',
              "441": 'No authorization to execute transaction type - existing card at Vector 80',
              "442": 'In-Stand Verify Certificate for this Removal is not allowed',
              "443": 'Cannot cancel transaction - card not found in existing transaction file in terminal',
              "445": "Instant debit card only instant debit credit can be made",
              "447": 'Wrong card number',
              "448": "Billing to enter customer address (zip code, house number and city)",
              "449": "Billing to type zip code",
              "450": 'Off-Code Operation Code, IDF Field 1-12',
              "451": "Error while building a transaction record",
              "452": "In a charge / discharge / debit transaction, enter a payment method code field",
              "453": 'Unable to cancel discharge 7.9.3',
              "455": "A forced debit transaction cannot be executed when an approval request is required (except for ceilings)",
              "456": "A ticket is in a movement file with an answer code 'confiscated Card'",
              "457": "Instant debit card allows a regular debit / credit / cancellation transaction",
              "458": 'Club code not in field',
              "472": "In a debit transaction with a cash charge enter a cash amount",
              "473": "In a cash transaction transaction, the cash amount should be less than the transaction amount",
              "474": 'Direct debit initialization transaction requires parameter J5',
              "475": "HQ transaction requires one of the fields: number of payments or total amount  HQ transaction requires one of the fields: number of payments or cash HQ transaction requires one of the fields: number of payments K requires one of the fields: Payment number K requires one of the fields: Number KK requires one of the fields: K requires one of the fields: K requires one of the fields: number",
              "476": "Direct Order Mast Transaction Requires Payment Number Field",
              "477": "A direct debit mast transaction requires a reboot transaction ID",
              "478": "A direct debit mast transaction requires a reboot transaction number",
              "479": "Direct Order Mast Transaction Requires Date and Time Boot Transaction Fields",
              "480": "No field than source transaction",
              "481": "Missing a number of units when the transaction is made in a form of payment different from currency",
              "482": "A debit card is allowed on a regular debit / credit / cancellation / cancellation / discharge / charge / balance check",
              "483": 'Transaction with a fuel card at a billing fuel terminal enter a car number',
              "484": "Typed vehicle number is different from burned vehicle number due to magnetic stripe / bank number is different from 912 / left digit Typed vehicle book is different from burned vehicle number due to magnetic stripe",
              "485": " Vehicle number shorter than 6 digits / different from vehicle number appearing on Channel 2) Position 34 on Channel 2 (property card) Vehicle book shorter than 6 digits / different from numbered vehicle",
              "486": "There is a duty to enter a meter reading (position 30) on Channel 2 Leumi Card fuel characteristic card",
              "487": "Only the two-stage fuel can be used in the oligo update",
              "489": "A standard debit card is allowed on the Delcan card only (cancellation is not allowed)",
              "490": 'Fuel / gasoline / fuel club tickets can only be transacted at gas terminals',
              "491": "A conversion transaction must contain all conversion_amount_06, conversion_rate_09, conversion_currency_51 fields",
              "492": 'No conversion on NIS / dollar transactions',
              "493": "In a transaction that includes a billing benefit that will only have one of the following fields: discount amount / number of units /% discount",
              "494": 'Different terminal number',
              "495": 'No fallback permission',
              "496": "Credit Different From Credit / Payments Cannot Be Linked",
              "497": 'Cannot be linked to dollar / index in different currency weight',
              "498": "Local Isracard Card The Spartan Cell at Position 18 Retis Isracard",
              "500": 'The transaction has been terminated by the user',
              "504": "mismatch between card data source field and card number field",
              "505": 'Invalid value in transaction type field',
              "506": 'Invalid value in eci field',
              "507": "Actual transaction amount is higher than approved amount",
              "512": "Cannot enter voice mail received for this transaction",
              "551": 'Reply message does not match request',
              "552": 'Field 55 error',
              "553": 'Tandem error received',
              "554": 'The reply message is missing field mcc_18',
              "555": 'The response message is missing field response_code_25',
              "556": 'The reply message is missing field rrn_37',
              "557": 'The reply message is missing field comp_retailer_num_42',
              "558": 'The reply message is missing auth_code_43 field',
              "559": 'The reply message is missing field f39_response_39',
              "560": 'The reply message is missing field authorization_no_38',
              "561": 'The reply message is missing / empty field additional_data_48.solek_auth_no',
              "562": 'The reply message is missing one of the conversion_amount_06, conversion_rate_09, conversion_currency_51 fields',
              "563": 'The field value does not match the certificate numbers obtained auth_code_43',
              "564": 'The reply message is missing / empty field additional_amounts54.cashback_amount',
              "565": "Field 25 and Field 43 mismatch",
              "566": "In the terminal that supports two-stage fuel, fields 90,116 must be returned",
              "567": "Invalid fields 25,127 in oligo update message in terminal configured as 2-step fuel",
              "700": 'transaction rejected by PinPad device',
              "701": 'Pinpad device error',
              "702": 'Bad com port',
              "703": 'PINPAD_TransactionError',
              "704": 'PINPAD_TransactionCancelled',
              "705": 'Customer Click Cancel on PINPAD',
              "706": "The operation is canceled",
              "707": 'The smart card was issued before the end of the transaction',
              "708": 'PINPAD_UserRetriesExceeded',
              "709": 'PINPAD_PINPadTimeout',
              "710": 'PINPAD_PINPadCommsError',
              "711": 'PINPAD_PINPadMessageError',
              "712": 'PINPAD_PINPadNotInitialized',
              "713": 'PINPAD_PINPadCardReadError',
              "714": 'PINPAD_ReaderTimeout',
              "715": 'PINPAD_ReaderCommsError',
              "716": 'PINPAD_ReaderMessageError',
              "717": 'PINPAD_HostMessageError',
              "718": 'PINPAD_HostConfigError',
              "719": 'PINPAD_HostKeyError',
              "720": 'PINPAD_HostConnectError',
              "721": 'PINPAD_HostTransmitError',
              "722": 'PINPAD_HostReceiveError',
              "723": 'PINPAD_HostTimeout',
              "724": 'PINVerificationNotSupportedByCard',
              "725": 'PINVerificationFailed',
              "726": 'Error recording config.xml file',
              "730": "Device Approved Transaction Against Credit Decision",
              "731": 'Card not inserted',
            },

            mobilePhoneRepair: {
              title: 'טופס תיקון',
              approveWithPrint: 'אישור עם הדפסה',
              approveWithoutPrint: 'אישור ללא הדפסה',
              printableNotes: 'הערות תיקון',
              selectCustomer: 'בחר לקוח',
              changeCustomer: 'החלף לקוח',
              customerName: 'שם לקוח',
              customerAddress: 'כתובת',
              customerTZ: 'ת.ז/ע.מ',
              customerPhone: 'טלפון',
              additionalPhone: 'טלפון נוסף',
              deviceType: 'סוג מכשיר',
              imei: 'IMEI',
              devicePassword: 'סיסמה נעילה',
              color: 'צבע',
              repairEstimatedCost: 'עלות תיקון משוערת',
              deviceDetails: 'פרטי המכשיר',
              repairDetails: 'פרטי תקלה',
              status: 'סטטוס',
              needToSelectcustomer: 'יש לבחור לקוח',
              saveRepair: 'שומר תיקון...',
              deviceTypeCannotBeEmpty: 'סוג מכשיר שדה חובה',
              notesCannotBeEmpty: 'פרטי תקלה שדה חובה',
              needToSelectStatus: 'יש לבחור סטטוס',
              errorOnSaveRepair: 'שגיאה בשמירת טופס תיקון נסה שנית במידה וחוזר צור קשר עם התמיכה',
              saveSuccess: 'שמירת טופס תיקון בוצע בהצלחה',
              statuses: {
                processingOnLab: 'בטיפול - מעבדה',
                processingOnExternalLab: 'בטיפול - נשלח למעבדה חיצונית',
                pendingRepaired: 'תוקן - ממתין ללקוח',
                pendingWithoutRepair: 'לא תוקן - ממתין ללקוח',
                completed: 'שולם הוחזר ללקוח',
                cancel: 'הוחזר ללקוח ללא חיוב',
              },
              printableRegulations: 'הערות תקנון',
              loadingFutureOrder: 'טוען טופס תיקון...',
              errorOnFetchFutureOrder: 'שגיאה בטעינת טופס תיקון',
              cannotSelectStatusCompletedBeforePayment: 'לא ניתן לבחור סטטוס שולם הוחזר ללקוח, יש לבצע תשלום',
      alertPayLessThenRepairEstimatedCost: 'שים לב אתה עומד לשלם פחות מעלות תיקון משוערת {cost}',
              alertCannotDeleteItemMobilePhoneRepairFromPaymentSale: `מכירה זו מקושרת לתיקון קיים. לא ניתן למחוק פריט תיקון.\nבמידת הצורך - ניתן למחוק את כל המכירה ולהתחיל מכירה חדשה.`,
            },

            indexMobilePhoneRepairs: {
              title: 'דוח תיקונים',
              showMobilePhoneRepair: 'הצג טופס תיקון',
              edit: 'עריכה',
              print: 'הדפס',
              toPay: 'לתשלום',
              cancelWithoutPay: 'הוחזר ללקוח ללא חיוב',
              date: 'תאריך',
              time: 'שעה',
              sequence: 'מספר',
              customerName: 'שם לקוח',
              imei: 'IMEI',
              phone: 'טלפון',
              deviceType: 'סוג מכשיר',
              details: 'פרטי תקלה',

              status: 'סטטוס',
              loadingFutureOrders: 'טוען דוח תיקונים...',
              errorOnFetchFutureOrders: 'שגיאה בטעינת דוח תיקונים',
              updateMobilePhoneRepairCancelWithoutPay: 'מעדכן הוחזר ללקוח ללא חיוב',
              errorOnUpdateMobilePhoneRepairCancelWithoutPay: 'שגיאה בעדכון הוחזר ללקוח ללא חיוב',
              addMobilePhoneRepairToSale: 'מוסיף תיקון למכירה',
              cannotAddMobilePhoneRepairToSale: 'לא ניתן להוסיף תיקון למכירה יש לעשות ניקוי מכירה ולאחר מכן לנסות שוב',
              cannotAddMobilePhoneRepairToSaleMissItemRepair: 'לא ניתן להוסיף תיקון למכירה חסר פריט תיקון',
              cannotAddMobilePhoneRepairToSaleGenralError: 'לא ניתן להוסיף תיקון למכירה שגיאה כללית נסה שנית. במידה וחוזר שנית צור קשר עם התמיכה',
      cannotEditMobilePhoneRepairOnStatus: 'לא ניתן לערוך תיקון בסטטוס {status}',
      cannotAddMobilePhoneRepairToSaleOnStatus: 'לא ניתן לשלם תיקון בסטטוס {status}',
      cannotUpdateMobilePhoneRepairCancelWithoutPayOnStatus: 'לא ניתן לעדכן הוחזר ללקוח ללא חיוב בסטטוס {status}',
            },

            additionalNotesDialog: {
              title: 'הערות הדפסה',
      maxLengthOfLine: '({length} תווים בשורה)',
            },

            mobilePhoneRepairSettings: {
              title: 'הגדרות מודול סלולר',
              save: 'שמור',
              notes: 'הערות תיקון',
              regulations: 'הערות תקנון',
              saveSettings: 'שומר הגדרות...',
              loadingSettings: 'טוען הגדרות...',
              errorOnFetchSettings: 'שגיאה בטעינת הגדרות',
              errorOnSaveSettings: 'שגיאה בשמירת הגדרות מודול סלולר',
              saveSuccessed: 'שמירת הגדרות בוצעה בהצלחה',
            },

            currenciesMessages: {
              notUpdatedTitle: 'Currency rates is outdated',
              noCurrenciesAtDb: 'Failed to update currency rates. \nPlease Notice! The are no currency rates saved on the pos. Please validate your internet connection and make sure that the currency rates file exists and update the currency rates.',
              currenciesNotUpdated: 'Please Notice! Failed to update currency rates. Please validate your internet connection in order to update the currencies rates. You can force update them later by closing and reopening the pos.',
              updateNow: 'Update Rates',
              useOldRates: 'Use Existing Rates',
            },

            finishTimeItemDialog: {
              title: 'End {item} time',
              startDate: 'Start Date',
              startTime: 'Start Time',
              endDate: 'End Date',
              endTime: 'End Time',
              totalTime: 'Total Time',
              totalPrice: 'Total Price',
              details: 'Details',
              detailsQuantity: 'Minutes Amount',
              detailsRate: 'Rate',
              detailsSum: 'Price',
              rateText: '{rate} {currency} for {rateTime} minutes',
              minutes: 'Minutes',
              edit: 'Edit',
              saleHasOpenTimeItems: 'There are opened time items. Please close all opened time item in order to close the sale.'
            },

            icMega: {
              cannotAddItem: "לא ניתן להוסיף פריט",
              onlyIcMegaItemsCanAdd: "רק פריטי ICMega יכולים להתווסף למכירה זו.\nבמידת הצורך - ניתן למחוק את כל המכירה ולהתחיל מכירה חדשה.",
              canPaymentOnlyByIcMegaVoucher: 'מכירה עם פריטי ICMega, ניתן לשלם רק באמצעות תשלום ICMega.\nבמידת הצורך - ניתן למחוק את כל המכירה ולהתחיל מכירה חדשה.',
              cannotPaymentByIcMegaNotFoundIcMegaItemOnSale: 'לא נמצא פריט במכירה שמאופשר לתשלום באמצעות ICMega',
              cannotAddDifferentcICMegaItemCodes: 'לא ניתן להוסיף פריט ICMege בקוד פריט שונה מהפריט הקיים במכירה',
              quantityMustBeBiggerThanZero: 'יש להזין כמות לחיוב גדולה מאפס',
              barcodeIsRequired: 'יש להזין ברקוד',
              generalIcMegaServerError: 'שגיאה כללית ICMega',
              icMegaServerErrorNotFound: 'ברקוד לא נמצא ICMega',
              cannotPaySaleItemWithBarcode: 'לא ניתן לשלם על הפריט במכירה עם ברקוד זה',
      cannotPayQuantityBiggerThanAvailableQuantity: 'לא ניתן לחייב כמות גדולה מכמות הזמינה {quantity} לחיוב',
              cannotPayNoAvailableQuantity: 'לא ניתן לחיוב אין כמות זמינה לחיוב',
              cannotUseSameBarcodeTwice: 'לא ניתן לחייב פעמיים אותו ברקוד. במידת הצורך ניתן למחוק את החיוב הקיים ולחייב מחדש',
              quantityCannotBeBiggerThanTotalQuantity: 'לא ניתן לחייב כמות גדולה מכמות הפריט בחשבון',
      cannotPayLessThanAvailableQuantity: 'לא ניתן לחייב כמות קטנה מ{quantity} הזמינה בקופון',
              canNotCreditIcMega: 'לא ניתן לזכות מכירה עם תשלום ICMega',
              cannotAddIcMegaItemWithOtherItem: 'פריט ICMega לא יכול להימכר עם פריטים נוספים.\n במידת הצורך יש למחוק את כל המכירה ולהתחיל מכירה חדשה',
              icMegaVoucherNotExist: 'שובר ICMega לא קיים בקופה',
            },
            modbus: {
              errorCommunicatingModbusServer: 'There was an error communication with modbus controller, Please check the modbus status and current configuration',
              sendDataToRegister: 'Updating modbus controller, please wait...',
            },

            priorityCustomerClub: {
              title: 'לקוחות פריוריטי',
              searchPlaceholder: "לפי מספר לקוח/שם/טלפון",
              customerName: 'שם לקוח',
              mustEnterCustomerName: 'חובה להזין שם לקוח',
              errorCustomerExist: 'לקוח כבר קיים בפריוריטי',
              edit: "עריכה",
              update: 'עדכן',
              acceptMailing: 'מאשר קבלת דיוור',
              errorOnUpdateCustomer: 'שגיאה בעדכון לקוח',
            },

            priorityCustomerReportDialog: {
              title: 'דוח עסקאות לקוח פריוריטי',
              showInvoiceDetails: 'הצג פרטי חשבונית',
              page: 'עמוד',
            },

            priorityCustomerInvoiceDetailsDialog: {
              title: 'פירוט חשבונית פריוריטי',
              notFoundTinvoice: 'שגיאה במשיכת חשבונית מפריוריטי נסה שנית במידה וחוזר צור קשר עם התמיכה',
              invoice: 'חשבונית',
              customerName: 'שם לקוח',
              totalInvoice: 'סה"כ חשבונית',
              itemCode: 'קוד פריט',
              itemDescription: 'תיאור פריט',
              quantity: 'כמות',
              total: 'סה"כ',
              items: 'פריטים',
              payments: 'אמצעי תשלום',
              paymentType: 'סוג תשלום',
              cash: 'מזומן',
              check: 'צק',
              itemBasePrice: 'מחיר בסיסי לפריט',
            },
            packageDialog: {
              title: 'Select Item Package',
              noPackage: 'No Package',
              tooMuchPackage: 'The packages wieght is greater than the item weight',
            },
            migvanSelectDialog: {
              pleaseSelectMigvan: 'Please select store for the order',
            },
            sittingTaDialog: {
              selectOrderType: 'Select Order Type',
              sitting: 'Sitting',
              ta: 'Take Away',
              sittingInPlace: 'Sitting',
            },
            otot: {
              generalError: "Error in smart bracelet interface- Please try again.\nIf the problem persists - contact support.",
              printingTag: 'Printing bracelet, please wait...',
              activatingRfidBracelet: 'Activating bracelet, please wait...',
              validationError: 'Error in Otot interface authentication, Please validate the pos configurations',
              scanBraceletOrInputNumber: 'Scan bracelet or enter bracelet number',
              scanBracelet: 'Scan bracelet',
              braceletNumber: 'Bracelet number',
              clearBraceletNumber: 'Clear bracelet number',
              cantPayWithOtherPayments: 'This payment can\'t be used with other payments',
              balances: {
                currentAmount: 'Bracelet balance',
                entriesLeft: 'Attractions',
                loyaltyPointsLeft: 'Magic Points'
              },
              purchasingTags: 'Purchasing bracelets, Please wait...',
              loadBracelet: 'Loading bracelet, please wait...',
              notEnoughBalanceToCredit: 'Not enough credit on bracelet',
              scanQrBracelet: 'Scan QR Bracelet',
              scanQrBraceletForLoad: 'Please scan the bracelet to load money into',
              scanRFIDBraceletToLoadTicket: 'Please scan the bracelet for Ticket: {ticketName}',
              scanRFIDBraceletToActivate: 'הפעלת צמיד חכם',
              activateBraceletFor: 'Hello {name}, To activate {productName}, scan the bracelet',
              braceletActivated: 'Bracelet successfully activated',
              pleaseScanBracelet: 'Please scan bracelet and wait for approval',
              chargingBracelet: 'Charging bracelet',
              priceForLoad: "Please enter the amount of money to load",
              scanTicketQr: 'Scan Ticket QR Code',
              noBraceletOnSale: "There are no bracelets to print on this sale",
              braceletLoadSuccessfully: 'Bracelet loaded successfully ',
      braceletLoadTotalAmount: 'Current amount: {amount}',
              purchaseByGamePointsAmountRequiredMessage: 'חובה להזין כמות נקודות למימוש',
              purchaseByGamePointsAmountNeedToBeGreaterThanZero: 'יש להזין כמות נקודות למימוש גדולה מ0',
              pointsAmount: 'כמות נקודות',
              tagPointsBeforeUsed: 'יתרה לפני מימוש',
              tagPointsUsed: 'כמות נקודות שמומשו',
              tagCurrentPoints: 'יתרתך הנוכחית',
              reprintWarning: 'פעולה זו תבטל את כל הצמידים שיצאו מ- QR זה - יש לאסוף את כל הצמידים שהודפסו מהלקוח',
              printOrLoadBrancelet: 'Would you like to print a new bracelet or to load an existing one?',
              printNewBracelet: 'Print New Bracelet',
              loadExistingBracelet: 'Load Existing Bracelet',
              tagNotScanned: 'Sale was canceled'
            },
            outOfStockItemsDialog: {
              title: 'Manage Locked Items',
              selectLockedItems: 'Please select items to lock',
      confirmLock: 'Are you sure you want to lock the item {name}?',
      confirmUnlock: 'Are you sure you want to unlock the item {name}?',
              outOfStock: 'Sold out',
              outOfStockMessage: 'This item is sold out',
              dialogDescription: 'Please select item to lock/unlock',
              reprintWarning: 'This action will cancel all bracelets from this QR code, please collect all the bracelet that printed.'
            },
            selfServiceTotalBeforePayDialog: {
              title: "Here's a summary of your order",
              totalToPay: 'Total to pay',
              back: 'Back',
              pay: 'Continue to Payment',
              promotion: 'Promotion',
            },
            selfServicePluxeeSelectCompanyDialog: {
              title: 'בחירת חברה',
              selectCompany: 'בחר חברה',
            },
            selectDalpakFromListDialog: {
              title: 'Select Counter',
              dalpak: 'Counter',
              dalpakArea: 'Area',
              movingItems: 'Moving items, please wait...'
            },
            cashcow: {
              orderFee: 'Order Fee',
            },
            posCloseSaleDailog: {
              title: "Close Sale",
              totalQuantity: 'Total Quantity',
              totalSale: 'Total Sale',
              totalPaid: 'Total Paid',
              changeInUSD: 'Change in $',
              changeInILS: 'Change in ₪',
              changeInCash: 'Change in Cash',
              changeInCreditCard: 'Change in CreditCard',
              changeInCreditVoucher: 'Change in Credit Voucher',
            },
            upsaleDialog: {
              wouldYouLikeItems: 'Would you like to add one of the following items?',
              wouldYouLikeAItem: 'Maybe you want {item}?',
              cancel: 'Not this time',
              ok: 'Alright',
            },

            valuCardReport: {
              actions: {
                resetBalance: "Reset Balance",
              },
            },


            restaurant: {
              EnableEpandedView: 'Expand View',
              DisableEpandedView: 'Collapse View',
              EnableSelectMode: 'Enable Select Mode',
              DisableSelectMode: 'Disable Select Mode',
              noItems: 'No Items Selected',
              emptyTable: 'The table is emtpy, Order something',
              noResults: 'No Results found for this search',
              searchPlaceholder: 'Type Here...',
              back: "Back",
              bottomActions: {
                search: 'Item Search',
                for: 'For',
                numberOfDiners: 'Number of diners',
                findCustomer: 'Find Customer',
                noPrint: 'Exit without printing',
                discount: 'Discount',
                tip: 'Tip',
                calculator: 'Calculator',
              }
            },


            midragPaymets:{
              numOfPaymentsTitle: "Number of payments:",
              ok:"Ok",
              cancel:"Cancel"
            },

            valueCardSelfService: {
              'valueCard': 'Value Card Club',
              'activeBenefits': 'Active Benefits',
              'birthdayBenefit': 'Birthday Benefit',
              'pointsShop': 'Points Shop',
              'punchCards': 'Punch Cards',
              'cashbackBalance': 'Budget/Cashback',
              'doYouWantToRedeemPoints': 'Do you want to redeem points?',
              'youHave': 'You have',
              'hi': 'Hi',
              'points': 'Points',
              'redeemablePoints': 'Redeemable Points',
              'noBenefits': 'No Benefits',
              'noPointsStore': 'No Points Store',
              'noPunchCards': 'No Active Punch Cards',
              },
              osherAd: {
                saleScanReady: "Finished Scan",
                cannotAddItemsToNonEmptyCart: 'Cannot add items to a non-empty cart',
                itemName: 'Item Name',
                itemPrice: 'Price',
                itemQuantity: 'Quantity',
                goToPay: 'Proceed to Payment',
                totalToPay: 'Total to Pay',
                cannotConnectToServer: 'Please try again later',
                smsInvoice: "Would you like to receive an invoice via SMS?",
                smsInvoicePlaceholder: "Enter mobile number to receive SMS",
                preferPrinted: "I prefer a printed invoice",
                sendSMS: "Confirm",
                print: "Print",
                clickToScan: "Click to start",
              },
              vendingMachine:{
                success:"Vending Successful",
                closingSale: "Vending Items", 
                manageMachine: "Manage machine",
                resetMachine: "Reset machine",
                requestPort: "Select machine port",
                inventoryUpdate: "Inventory update",
                resetingMachine: "Machine reset command sent successfully",
                disableLogs: "Disable machine logs",
                enableLogs: "Enable machine logs",
                tempCheck: "Temperature check",
                queringMachine: "Querying machine..",
                tempIs: "The temperature is ",
                tempCheckError: "Error in temperature check",
                checkStatus: "Check machine status",
                codes: {
                  '00': 'POS ERROR',
                  '30': 'READY (NOTHING TO REPORT)',
                  '31': 'IN PROCESS - BUSY',
                  '32': 'INVALID TRAY REQUESTED',
                  '33': 'INVALID CHANNEL REQUESTED',
                  '34': 'Dear customer, the selected product is out of stock, therefore we are now crediting you for the transaction. You can choose an alternative item in a new order',
                  '35': 'FAULT IN ELEVATOR MOTOR (POSSIBLE JAM)',
                  '37': 'FAULT IN ANY OF THE CABINET\'S PHOTOTRANSISTORS',
                  '38': 'NO CHANNEL DETECTED',
                  '39': 'DETECTING PRODUCT',
                  '41': 'FAULT IN 485 BUS',
                  '42': 'PRODUCT UNDER THE ELEVATOR ALARM',
                  '43': 'ERROR WHEN ELEVATOR APROACHING TO A POSITION',
                  '44': 'FAULT IN KEYBOARD',
                  '45': 'EEPROM WRITING ERROR',
                  '46': 'FAULT COMMUNICATING WITH TEMPERATURE CONTROL',
                  '47': 'THERMOMETER DISCONNECTED',
                  '48': 'THERMOMETER PROGRAMMING LOST',
                  '49': 'THERMOMETER FAULTY',
                  '4a': 'CHANNELS POWER COMSUMPTION DETECTOR FAULTY',
                  '4b': 'ELEVATOR DOES NOT FIND CHANNEL/TRAY',
                  '4c': 'ELEVATOR DOES NOT FIND DELIVERY PRODUCT POSITION',
                  '4d': 'INTERIOR OF THE ELEVATOR BLOCKED',
                  '4e': 'ERROR IN TESTER OF PRODUCT DETECTOR',
                  '4f': 'WAITING FOR PRODUCT TO BE REMOVED',
                  '50': 'PRODUCT IS EXPIRED BECAUSE TEMPERATURE',
                  '59': 'PRODUCT IS EXPIRED (NSF ALARM 1)',
                  '5a': 'PRODUCT IS EXPIRED (NSF ALARM 2)',
                  '61': 'PRODUCT IS EXPIRED (NSF ALARM 3)',
                  '62': 'PRODUCT IS EXPIRED (NSF ALARM 4)',
                  '63': 'PRODUCT IS EXPIRED (NSF ALARM 5)',
                  '64': 'PRODUCT DETECTOR DID NOT CHANGE DURING ITS VERIFICATION TEST',
                },
                zReportInProgress: "Please wait, the system is processing a data update and will soon return to activity.",
                inventory: {
                  onCloseSaleOutOfStockError: 'The requested item is out of stock, canceling sale',                  
                  areYouSureYouWantToReload: "Refresh machine's inventory?",
                  loading: "Load items' inventory...",
                  errorOnLoad: 'An error occurred while updating the machine inventory. If this message recurs, contact support',
                  cantLoadWhenOfflineSales: "Can't update inventory when there are offline sales. If this message recurs, contact support",
                  success: "Successfully updated inventory",
                  catalogUpdatedBut: "Catalog updated but - ",
                }
              },
              multiCurrencyChange: {
                storeCurrencyChange: "Store Currency Change",
                storeCurrencyChangeLeft: "Remaining Store Currency Change",
                title: "Input Change for Customer",
                invalidChangeAmount: "Please enter the entire change amount",
                enterChange: "Please enter change",
                changeIncurrency: "Change in Currency",
                changeInput: "Input Change",
              },
              multiCurrencyCashDetails: {
                posTitle: "Pos Currencies Details",
                storeTitle: "Store Currencies Details",
              },
              currenciesRates: {
                title:'Currencies Rates',
                lastUpdate: "Currency rates are updated as of the date: ",
              },
              creditInOGCashCurrency: {
                title: 'Return cash according to the details',
                cols: {
                  currency: 'Currency',
                  howMuchToRefund: 'How much to refund in currency',
                },
              },
            
              languages: {
                title: 'Languages',
                en: 'English',
                he: 'Hebrew',
                ru: 'Russian',
                ar: 'Arabic',
            },
            components:{
              componentNotExist:'The dialog {componentName} does not exist',
            },
            transmitTaxInvoiceDialog: {
              title: "Transmit Invoice to Tax Authority",
              content: "Should the invoice number {invoiceSequence} be transmitted to the Tax Authority?",
              transmitSuccess: "The invoice was successfully transmitted",
              saleTransmitted: "The invoice has already been transmitted to the Tax Authority \n Allocation number: <strong>{confirmation_number}</strong>",
              transmitError: "Error in transmitting the invoice to the Tax Authority",
              saleNotFound: "The transaction was not synced to the server, please try again when there are no offline sales",
              saleSyncError: "The transaction was not synced to the server and therefore did not receive an allocation number for the Tax Authority. An allocation number can be requested through the previous invoices screen after synchronizing the transaction to the server",
              syncSaleToTransmit: "It is not possible to transmit a sale in disconnection",
              loginAgainBo: "The connection to the Tax Authority has expired \n You need to reconnect through the back office on the 'Connect to Tax Authority' screen found under 'Settings'",
              cantTransmitSaleWithoutVAT: "It is not possible to transmit a sale where the VAT amount equals 0",
              invoiceTypeNotSupported: "This type of document is not supported in transmission to the Tax Authority",
              unknownError: "Error in transmitting invoice to income tax, if this message repeats contact support.",
              errors: {
                Customer_VAT_Number: "Please enter a valid ID or Business ID",
                VAT_Number: "The business's VAT number is invalid, please contact support",
                406: "There is an error trying to create an allocation for the customer invoice in Invoices Israel, it seems there is a problem with the business registration.\nPlease try to re-register. If the problem persists, contact the Invoices Israel support team for assistance."
              },
              connect: "Connect to Tax Authority"
            },
            taxAuthorityConnect: {
              title: "Connecting to the Israeli Invoice Model",
              step1: "To use the Israeli invoice model, you must connect to the Tax Authority website via QR",
              step2: "If you are unable to scan the QR, you should copy the following link into an internet browser:",
              guide: "Setup Guide",
            }
          }