module PositiveTS {
  export module Components {
    export module CompleteOrderWithPaymentDialog {

      export function create() {
        let component = {
          template: JST.CompleteOrderWithPaymentDialog(),
          components: {
            'posit-table': PositTable.getComponent(),
          },
          setup() {
            const { ref } = Vue;
            const resolveFunc = ref(null); // In the open component, we re-assign this
            const el = ref(null);
            const meta = ref(null);
            const pageNum = ref(1);
            const freeSearch = ref("");
            const modelValue = ref([]);
            const resetFields = () => {
              freeSearch.value = "";
              modelValue.value = [];
            }
            let transPrefix = "completeOrderWithPaymentDialog.cols";
            const cols: PositiveTS.Types.PositTableHeader[] = [
              { field: 'order_time', title: i18next.t(`${transPrefix}.createdAt`), type: "string", class: 'text-center' },
              {
                field: 'customer_name', title: i18next.t(`${transPrefix}.customerName`), type: "string", class: 'text-center',
                computedField: (row) => row.customer_name || `${i18next.t('none')} ${i18next.t('name')}`
              },
              { field: 'order_num', title: i18next.t(`${transPrefix}.orderNumber`), type: "string", class: 'text-center' },
              {
                field: 'action', title: i18next.t('actions'), type: "action", action: 'loadOrderToSale', btnClass: 'py-2 px-4',
                computedField: (row) => i18next.t(`${transPrefix}.loadOrder`, { orderNum: row.order_num })
              },
            ];
            const colDefs = ref(cols)
            const searchOrders = async () => {
              try {
                app.showLoadingMessageDialog(i18next.t("loading"));
                let orders = await PositiveTS.Service.SelfServiceSaleOrders.getOrders(freeSearch.value, pageNum.value);
                modelValue.value = orders.items;
                meta.value = orders.meta;
                await app.hideLoadingMessageDialog();
              } catch (error) {
                await app.hideLoadingMessageDialog();
                app.showErrorAlertDialog(i18next.t("generalError"));
              }
            }
            const open = () => {
              return new Promise((resolve, reject) => {
                $(document).unbind("keypress");
                let dialog: HTMLDialogElement = el.value;
                dialog.showModal();
                searchOrders().then(() => {
                  resolveFunc.value = resolve;
                }).catch((err) => {
                  console.error(err);
                  app.showAlert({ title: i18next.t("error"), content: i18next.t("generalError"), hideCancelButton: true });
                  resolve();
                });

              });
            }
            const close = (continueToPayment = false) => {
              resetFields();
              el.value.close();
              $(document).keypress(posVC.onKeyPress);
              resolveFunc.value(continueToPayment);
            };
            const previousPage = async () => {
              pageNum.value = pageNum.value - 1;
              await searchOrders();
            };
            const nextPage = async () => {
              pageNum.value = pageNum.value + 1;
              await searchOrders();
            };
            const loadOrderToSale = async (order) => {
              try {
                app.showLoadingMessageDialog(i18next.t("loading"));
                await PositiveTS.Service.SelfServiceSaleOrders.loadOrderAsSale(order);
                await app.hideLoadingMessageDialog();
                close(true);
              } catch (error) {
                await app.hideLoadingMessageDialog();
                app.showErrorAlertDialog(i18next.t("generalError"));
              }
            }
            const newSearch = async () => {
              pageNum.value = 1;
              await searchOrders();
            }
            return {
              el,
              meta,
              colDefs,
              freeSearch,
              modelValue,
              open,
              close,
              cancel: close,
              searchOrders,
              loadOrderToSale,
              nextPage,
              previousPage,
              newSearch,
            };
          }
        };
        VueApp.component("complete-order-with-payment-dialog", component);
      }
    }
  }
}
