
module PositiveTS {
   export module Service {

      const HAKAFA_CUSTOMER_PTRFIX = "לקוח"
      const _PositiveServiceLogger ='positive orders'

      const DELIVERY_TYPES = {
         TA: 0,
         DELIVERY: 1,
      }

      const PAYMENT_TYPE = {
         CASH: 0,
         CREDIT_CARD: 1,
         CIBUS: 2,
         TENBIS: 3,
      }

      const CHARGE_TYPES = {
         DISCOUNT: 0,
         DELIVERY_PRICE: 1,
         TIP: 2,
      }

      const getInvoiceTypeConfig = () => {
         return {
            'tamash-only': {
               debitInvoicePaymentTypes: []
            },
            'debit-only': {
               debitInvoicePaymentTypes: [Storage.Entity.SalePayment.METHOD_CASH, Storage.Entity.SalePayment.METHOD_VOUCHER]
            },
            'debit-cash-only': {
               debitInvoicePaymentTypes: [Storage.Entity.SalePayment.METHOD_CASH]
            },
         }
      }

      const VOUCHER_START_TYPE_CODE = 15000;
      export class PositiveDeliveryConvertService extends BaseOrderConvertService {
         public apiData;

         public getInvoiceTypeConfiguration() {

            let allInvoiceTypeConfigs = getInvoiceTypeConfig();

            if (posUtils.isBlank(this.apiData.invoiceTypeConfiguration)) {
               throw new Error('Invoice type configurator is not defined');
            }

            let currentConfig = allInvoiceTypeConfigs[this.apiData.invoiceTypeConfiguration];


            if (posUtils.isBlank(currentConfig)) {
               throw new Error('Invoice type configurator is not found. configuration name: ' + this.apiData.invoiceTypeConfiguration);
            }

            return currentConfig;
         }

         // return if should create debit invoice for the payment
         getIsDebitInoviceForPayment(paymentType) {
            return this.getInvoiceTypeConfiguration().debitInvoicePaymentTypes.includes(paymentType);
         }

         getCustomerNumber() {
            return `${HAKAFA_CUSTOMER_PTRFIX} ${this.apiData.name}`
         }

         getOrderNumber(order: any) {
            return order.orderId;
         }

         getOrderTotal(order: any) {
            return session.fixedFloat(this.convertAgorotToShkalim(order.orderSubTotal));
         }

         getSaleInvoiceType(sale) {
            for (let payment of sale.payments) {
               let data = JSON.parse(payment.data)[0];

               if (data.isDebitInvoice) {
                  return Storage.Entity.Sequence.TYPE_DEBIT_INVOICE;
               }
            }

            return Storage.Entity.Sequence.TYPE_SHIPMENT_INV;
         }

         async getOrderJsonData(order, sale) {
            let deliveryTypeStr, deliveryStatus;

            let isPickup = false;

            if (order.deliveryType == DELIVERY_TYPES.DELIVERY) {
               deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.delivery,
                  deliveryStatus = PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY;
            } else if (order.deliveryType == DELIVERY_TYPES.TA) {
               if (sale.invoiceType == Storage.Entity.Sequence.TYPE_DEBIT_INVOICE) {
                  isPickup = order.payments.some(orderPayment => orderPayment.type == PAYMENT_TYPE.CASH && orderPayment.amount > 0);
               }


               deliveryTypeStr = PositiveTS.Service.Delivery.DeliveryType.ta;
               deliveryStatus = isPickup ? PositiveTS.Storage.Entity.Sale.OPEN_DELIVERY : PositiveTS.Storage.Entity.Sale.TA_PAID;
            } else {
               let error = "Unknown delivery type";
               PositiveTS.Service.Delivery.failedDeliveriesLogger(error,order.order_number,_PositiveServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing)
               throw new Error(error);
            }

            let hakafaCustomer = await Service.Hakafa.getHakafaCustomerByCustomerNumber(this.getCustomerNumber());
            if(!hakafaCustomer){
               let error = "Positive Hakafa customer missing";
               PositiveTS.Service.Delivery.failedDeliveriesLogger(error,order.order_number,_PositiveServiceLogger,Delivery.FailedDeliveryType.HakafaCustomer);
               throw new Error(error);
            }


            let jsondata = JSON.parse(sale.jsondata)
            jsondata["promotions"] = [];
            jsondata["delivery"] = {
               deliveryType: deliveryTypeStr,
               status: deliveryStatus,
               isPickup: isPickup,
               serviceData: {
                  name: this.apiData.name,
                  type: DELIVERY_API_TYPES.POSITIVE,
                  positiveApiId: this.apiData.positiveApiId
               },
               ordererName: `${order.customer.firstName} ${order.customer.lastName}`,
               ordererPhone: order.customer.phone,
               ordererCallerRemarks: order.comment,
               ordererDeliveryRemarks: order.address ? order.address.comment : null,
               ordererDeliveryCutlery: order.comment,
               orderHasBeenPaid: !isPickup,
               "deliveryAddress": {
                  "address": {
                     "name": order.address ? order.address.street : '',
                     "value": 0,
                     "cityID": 0
                  },
                  "apartment": order.address ? order.address.apartment : "",
                  "house_number": order.address ? order.address.number : "",
                  "house_entrance": order.address ? order.address.entrance : "",
                  "house_floor": order.address ? order.address.floor : "",
                  "city": {
                     "name": order.address ? order.address.city : "",
                     "value": 0,
                     "cityID": 0
                  },
               },
               "orderTime": new Date(order.orderTime),
               "address": "",
            };

            jsondata["customer"] = {
               clubName: "HAKAFA",
               s_id_number: hakafaCustomer.tz,
               s_first_name: hakafaCustomer.first_name,
               s_last_name: hakafaCustomer.last_name,
               dt_birth_date: null,
               s_phone_number_1: hakafaCustomer.phone,
               customer_group_id: null,
               amount: 0,
               merakez: "",
               db_id: hakafaCustomer.id,
               is_offline: false,
               is_price_alut: false,
               printer_type: 0,
               discount_percent: 0,
               customer_number: hakafaCustomer.customer_number,
               obligo: null,
               address: null,
               is_tamash_customer: false,
               is_cibus_tenbis: true
            }

            return jsondata;
         }

         getOrderSaleItems(order, sale) {
            let items = [];

            for (let orderItemIndex in order.items) {
               let orderItem = order.items[orderItemIndex];
               let saleItem = this.convertOrderItemToSaleItem(orderItem, sale, parseInt(orderItemIndex) + 1);

               for (let variationIndex in (orderItem.variations || [])) {
                  let variation = orderItem.variations[variationIndex];

                  let childSaleItem = this.convertOrderItemToSaleItem(variation, sale, 20001 + parseInt(variationIndex));

                  childSaleItem.level = 1;
                  childSaleItem.parentItemId = orderItem.id;
                  childSaleItem.parentRowNumber = 1;
                  childSaleItem.itemGroupId = -1;
                  childSaleItem.parent = saleItem;
                  childSaleItem.quantity = 1;

                  saleItem.unitPrice += childSaleItem.priceNetoAfterDiscounts
                  saleItem.priceNetoAfterDiscounts += childSaleItem.priceNetoAfterDiscounts * saleItem.quantity
                  saleItem.children.push(childSaleItem);

               }

               if (orderItem.variations && orderItem.variations.length > 0) {
                  saleItem.hasGroups = true;
               }

               items.push(saleItem);
            }

            let chargesItems = this.getOrderChargeItems(order, sale, items.length);

            let allItems = items.concat(chargesItems);

            if (order.deliveryType == DELIVERY_TYPES.TA) {
               allItems.push(this.getTaSaleItem(sale, allItems.length + 1));
            }

            return allItems;
         }

         getOrderChargeItems(order, sale, rowNumberStartIndex) {
            let items = [];

            let chargeConvertFunctions = {
               [CHARGE_TYPES.DISCOUNT]: this.convertDiscountChargeToItem.bind(this),
               [CHARGE_TYPES.DELIVERY_PRICE]: this.convertDeliveryPriceChargeToItem.bind(this),
               [CHARGE_TYPES.TIP]: this.convertTipChargeToItem.bind(this),
            }

            for (let chargeIndex in order.charges) {
               let charge = order.charges[chargeIndex];
               if (chargeConvertFunctions[charge.type]) {
                  items.push(chargeConvertFunctions[charge.type](charge, sale, rowNumberStartIndex + parseInt(chargeIndex) + 1));
               } else {
                  let error = 'Unknown charge type'
                  PositiveTS.Service.Delivery.failedDeliveriesLogger(error,order.order_number,_PositiveServiceLogger,Delivery.FailedDeliveryType.DeliveryTypeMissing)
                  throw new Error(error)
               }
            }

            return items;
         }

         convertDeliveryPriceChargeToItem(charge, sale, rowNumber) {
            let currentItem = null;

            if (jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.deliveryItemCode))
            }

            if (posUtils.isBlank(currentItem)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
               currentItem.description = charge.desc;
            }

            let deliverySaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });


            deliverySaleItem.unitPrice = this.convertAgorotToShkalim(charge.amount);
            deliverySaleItem.rowNumber = rowNumber;
            deliverySaleItem.saleID = sale.id;
            deliverySaleItem.priceNetoAfterDiscounts = deliverySaleItem.unitPrice;

            return deliverySaleItem;
         }

         getTaSaleItem(sale, rowNumber) {
            let currentItem = null;

            if (jsonConfig.getVal(jsonConfig.KEYS.taItemCode)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.taItemCode))
            }

            if (posUtils.isBlank(currentItem)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
               currentItem.description = 'TA';
            }

            let deliverySaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });


            deliverySaleItem.unitPrice = 0;
            deliverySaleItem.rowNumber = rowNumber;
            deliverySaleItem.saleID = sale.id;
            deliverySaleItem.priceNetoAfterDiscounts = 0;

            return deliverySaleItem;
         }


         convertDiscountChargeToItem(charge, sale, rowNumber) {
            let discountItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));

            let discountSaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(discountItem, { size: 'null', color: 'null', barcode: null });


            discountSaleItem.itemDescription = String(charge.desc).trim();
            discountSaleItem.unitPrice = -(this.convertAgorotToShkalim(charge.amount));
            discountSaleItem.rowNumber = rowNumber;
            discountSaleItem.saleID = sale.id;
            discountSaleItem.priceNetoAfterDiscounts = discountSaleItem.unitPrice;
            return discountSaleItem;
         }


         convertTipChargeToItem(charge, sale, rowNumber) {
            let tipItem;
            let changeDesc = false;

            if (Service.Tip.isHasTips()) {
               tipItem = Service.Tip.getTipsItem();
            } else {
               tipItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
               changeDesc = true;
            }


            let tipSaleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(tipItem, { size: 'null', color: 'null', barcode: null });

            if (changeDesc) {
               tipSaleItem.itemDescription = String(charge.desc).trim();
            }


            tipSaleItem.unitPrice = this.convertAgorotToShkalim(charge.amount);
            tipSaleItem.rowNumber = rowNumber;
            tipSaleItem.saleID = sale.id;
            tipSaleItem.priceNetoAfterDiscounts = tipSaleItem.unitPrice;

            return tipSaleItem;
         }


         convertOrderItemToSaleItem(orderItem, sale, rowNumber): Storage.Entity.SaleItem {
            let currentItem = session.allItems.get(String(orderItem.code))

            if (!Boolean(currentItem)) {
               currentItem = session.allItems.get(jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem));
            }

            let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(currentItem, { size: 'null', color: 'null', barcode: null });
            saleItem.quantity = orderItem.quantity;
            saleItem.barcode = saleItem.itemCode;
            saleItem.saleID = sale.id;
            saleItem.unitPrice = this.convertAgorotToShkalim(orderItem.price)
            saleItem.originalUnitPrice = this.convertAgorotToShkalim(orderItem.price);
            saleItem.hasPreparationInstructions = false;
            saleItem.rowNumber = rowNumber;
            saleItem.invoiceSequence = sale.invoiceSequence
            saleItem.children = [];
            saleItem.priceNetoAfterDiscounts = this.convertAgorotToShkalim(orderItem.price * orderItem.quantity);

            if (currentItem.code == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)) {
               saleItem.itemDescription = String(orderItem.desc).trim();
            }


            if (!posUtils.isNullOrUndefinedOrEmptyString(orderItem.comment)) {
               saleItem.hasPreparationInstructions = true;
               saleItem.selectedPreparationInstructions = JSON.stringify([orderItem.comment]);
            }

            return saleItem;
         }

         getSalePayments(sale, order): Storage.Entity.SalePayment[] {

            let paymentsByType = {};
            let methodsWithoutCash = Object.values(PAYMENT_TYPE).filter(p => p != PAYMENT_TYPE.CASH);

            for (let orderPayment of order.payments) {


               if (methodsWithoutCash.includes(parseInt(orderPayment.type))) {
                  if (!paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER]) {
                     paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER] = new Storage.Entity.SalePayment();
                     paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].method = Storage.Entity.SalePayment.METHOD_VOUCHER;
                     paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].saleID = sale.id;
                  }

                  paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].amount += this.convertAgorotToShkalim(orderPayment.amount);

                  let paymentData = JSON.parse(paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].data);
                  if (posUtils.isBlank(paymentData)) {
                     paymentData = [];
                  }

                  let voucherData =
                  {
                     amount: this.convertAgorotToShkalim(orderPayment.amount),
                     barCode: "",
                     creditType: this.apiData.name,
                     voucher_type_id: this.getVoucherTypeId(),
                     voucherType: PositiveTS.Storage.Entity.Voucher.DYNAMIC_DELIVERY_API,
                     smartVoucherType: null,
                     valuTypeId: null,
                     praxellManpik: null,
                     mutipassInit: null,
                     isTamashCustomer: !this.getIsDebitInoviceForPayment(Storage.Entity.SalePayment.METHOD_VOUCHER),
                     isDebitInvoice: this.getIsDebitInoviceForPayment(Storage.Entity.SalePayment.METHOD_VOUCHER)
                  }

                  paymentData.push(voucherData);
                  paymentsByType[Storage.Entity.SalePayment.METHOD_VOUCHER].data = JSON.stringify(paymentData);

               } else {
                  if (!this.getIsDebitInoviceForPayment(Storage.Entity.SalePayment.METHOD_CASH)) {
                     throw new Error("Configuration error, trying to create tamash on order with cash");
                  }

                  if (!paymentsByType[Storage.Entity.SalePayment.METHOD_CASH]) {
                     paymentsByType[Storage.Entity.SalePayment.METHOD_CASH] = new Storage.Entity.SalePayment();
                     paymentsByType[Storage.Entity.SalePayment.METHOD_CASH].method = Storage.Entity.SalePayment.METHOD_CASH;
                     paymentsByType[Storage.Entity.SalePayment.METHOD_CASH].saleID = sale.id;
                     paymentsByType[Storage.Entity.SalePayment.METHOD_CASH].data = JSON.stringify([{ isDebitInvoice: true }]);
                     paymentsByType[Storage.Entity.SalePayment.METHOD_CASH].amount = 0;
                  }

                  paymentsByType[Storage.Entity.SalePayment.METHOD_CASH].amount = session.fixedFloat(paymentsByType[Storage.Entity.SalePayment.METHOD_CASH].amount + this.convertAgorotToShkalim(orderPayment.amount), 1);
               }

            }

            return Object.values(paymentsByType);
         }

         convertAgorotToShkalim(amount) {
            return (amount / 100)
         }

         getVoucherTypeId() {
            return VOUCHER_START_TYPE_CODE + parseInt(this.apiData.positiveApiId);
         }
      }
   }
}