module PositiveTS {
export module Storage {
export module Entity {

export const enum CurrencieCodes {
  ILS = 'ILS',
  USD = 'USD',
  EUR = 'EUR',
}

export class Currencies extends IDBEntity {

  public name: string;
  public unit: number;
  public currencycode: string;
  public country: string;
  public rate: number;
  public last_run: string;
  public last_update: string;

  constructor() {
    let meta = {
        name: 'Currencies',
        internal: true,
        fields: {
          last_run: "TEXT",
          last_update: "TEXT",
          name: "TEXT",
          unit: "INT",
          currencycode: "TEXT",
          country: "TEXT",
          rate: "FLOAT",
          change: "TEXT"
        },
        unique: []
      };
    super(meta)
  }
}}}}
