module PositiveTS {
export module Storage {
export module Entity {
export class Item extends WasmEntity {
	code:string;
	isAbstract:boolean;
	hasGroups:boolean;
	isAddition:boolean;
	noVat:boolean;
	alwaysHasVat:boolean;
	priceZarhan:number;
	priceAlut:number;
	hasWeight:boolean;
	avoidFromAccumulatingClubPoints:boolean;
	description:string;
	priceEilat:number;
	priceLists:string;
	noDiscount:boolean;
	hasPreparationInstructions:boolean
	managerApprove:boolean
	autoOpenPreparationInstructions:boolean
	isPunchCard:boolean
	punchCardPriceByQuantity:boolean 
	punchQuantity:number
	punchCardItemId:number
	primaryCategoryId:number
	isSalePercentAddition:boolean
	allowZeroPrice:boolean
	checkQuestion:string
	upsaleMessage:string
	isAllowNameChange:boolean
	vouchers:string
	promoBuy:string
	promoGet:string
	logicalOrGroupPrinters:string
	printSufix:string
	nameOnBon:string
	discounts:string
	availableFrom:number
	availableUntil:number
	minUnitPrice:number
	disableItemGroupEditing:number
	wallets:string
	priceZarhan2:number
	salesLimitTimeType: number
	salesLimitTimeAmount: number
	hideChildrenPrices:boolean

	//roshemet fields
	allowPriceChange:boolean
	barcode:string
	currentInventory:number
	departmentId:number
	syncStatus:number
	serverDepartmentId:number
	invDelta:number
	locked:boolean
	promoSal3:string
	promoSal4:string
	isDeposit:boolean
	hideFromMenu:boolean
	showInMainMenu:boolean
	menuItemSortOrder: number
	//end roshemet fields
	isComplimentary:boolean
	hasPicture:boolean
	hasInventory:boolean
	isTimeItem:boolean
	message:string
	messageOnTimeEnd:string
	details: string
	shortDetails: string
	cannotBeSoldSeparately:boolean;
	translations: string;

	//non persisted fields
	departmentName:string
	//end non persisted fields
	
	constructor() {
		let meta = {
			name: 'Item',
			fields: {
				code: "TEXT",
					description: "TEXT",
					brandID: "TEXT",
					itemMainClassID: "TEXT",
					itemMainClassNumber: "INT",
					itemMainSubClassID: "TEXT",
					itemMainSubClassNumber: "INT",
					itemSecondaryClassID: "TEXT",
					itemSecondarySubClassID: "TEXT",
					ageGroupID: "TEXT",
					type: "TEXT",
					priceZarhan: "FLOAT",
					priceAlut: "FLOAT",
					priceEilat: "FLOAT",
					discounts: "TEXT",
					vouchers: "TEXT",
					promoBuy: "TEXT",
					promoGet: "TEXT",
					promoSal3: "TEXT",
					promoSal4: "TEXT",
					hasWeight: "BOOL",
					avoidFromAccumulatingClubPoints: "BOOL",
					priceLists: "TEXT",
					noVat: "BOOL",
					alwaysHasVat: "BOOL",
					menuItemSortOrder: "INT",
					isAllowNameChange: "BOOL",
					isSalePercentAddition: "BOOL",
					allowZeroPrice: "BOOL",
					isAbstract: "BOOL",
					isAddition: "BOOL",
					hasGroups: "BOOL",
					noDiscount: "BOOL",
					checkQuestion: "TEXT",
					upsaleMessage: "TEXT",
					printSufix: "TEXT",
					logicalOrGroupPrinters: "TEXT",
					hasPreparationInstructions: "BOOL",
					autoOpenPreparationInstructions: "BOOL",
					isPunchCard: "BOOL",
					punchCardPriceByQuantity: "BOOL", 
					punchQuantity:  "INT",
					punchCardItemId: "INT",
					supplierName: "TEXT",
					primaryCategoryId: "INT",
					nameOnBon: "TEXT",
					managerApprove: "BOOL",
					availableFrom: "INT",
					availableUntil: "INT",
					minUnitPrice: "FLOAT",
					disableItemGroupEditing: "BOOL",
					hideChildrenPrices: "BOOL",
					isDeposit: "BOOL",
					isComplimentary: "BOOL",
					hasPicture: "BOOL",
					hasInventory: "BOOL",
					wallets: "TEXT",
					priceZarhan2: "FLOAT",
					salesLimitTimeType: "INT",
					salesLimitTimeAmount: "INT",
					isTimeItem: "BOOL",
					message: "TEXT",
					messageOnTimeEnd: "TEXT",
					details: "TEXT",
					shortDetails: "TEXT",
					cannotBeSoldSeparately: "BOOL",
					translations: "TEXT",
			},
			unique: ['code'],
			money: ['priceZarhan', 'priceAlut', 'priceEilat']
		};

		if (session && session.pos && session.pos.isRoshemet) {
			meta.fields = Object.assign({
				allowPriceChange: "BOOL",
				barcode: "TEXT",
				currentInventory: "FLOAT",
				departmentId: "INT",
				serverDepartmentId: "INT",
				syncStatus: "INT",
				invDelta: "FLOAT",
				locked: "BOOL",
				hideFromMenu: "BOOL",
				showInMainMenu: "BOOL"
			},meta.fields)
		}

		super(meta)
	}



	defaultVal(field) {

		// console.warn(`assigning default value to a missing field in entity ${this.meta.name}. Field is ${field}`);
		switch (this.meta.fields[field]) {
			case "TEXT":
				return "";
			case "BOOL":
				return false;
			case "INT":
			case "FLOAT":
				return 0;
			case "JSON":
				return "{}";
			default:
				return undefined;
		}
	}


	importFromObject(importFrom) {
		if (session && session.pos && session.pos.isRoshemet) {
			for (let field in this.meta.fields) {
				if (!(field in importFrom)) {
					this[field] = this.defaultVal(field)
				}
				else {
					this[field] = importFrom[field];
				}
			}

			// If there is an ID, mark the entity as not new
			if ('id' in importFrom) {
				this.id = importFrom.id;
				this.timestamp = importFrom.timestamp;
				this._new = false;
			}
			return this;
		}
		else {
			return super.importFromObject(importFrom);
		}
	};


	static import(item) {
		let itemModel = new Item();
		return itemModel.importFromObject(item);
	}

	static convertToVueObject (item: Item): Item { 
		let itemVue: Item = Object.assign(item)
		itemVue = _.omit(itemVue, ['_data', '_new', 'meta'])
		itemVue.hasWeight = Boolean(itemVue.hasWeight);
		itemVue.noVat = Boolean(itemVue.noVat);
		itemVue.allowZeroPrice = Boolean(itemVue.allowZeroPrice);
		itemVue.locked = Boolean(itemVue.locked);
		itemVue.hasPreparationInstructions = Boolean(itemVue.hasPreparationInstructions);
		itemVue.hideFromMenu = Boolean(itemVue.hideFromMenu);
		itemVue.showInMainMenu = Boolean(itemVue.showInMainMenu);
		itemVue.isAllowNameChange = Boolean(itemVue.isAllowNameChange);
		return itemVue
	}

	private static convertVueObjectToItem (item: Item): Item {
		let itemEntity = PositiveTS.Storage.Entity.Item.import(item)
		itemEntity.id = item.id
		itemEntity.timestamp = moment().unix()
		itemEntity.syncStatus = Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT;
		return itemEntity;
	}


	static async saveToDB(item: Item,  updateItemOnSession = false) {
		let itemEntity = PositiveTS.Storage.Entity.Item.convertVueObjectToItem(item);
		let result = await appDB.localItems.put(itemEntity);	
		if (updateItemOnSession) {
			Storage.Entity.Item.updateItemOnSession(itemEntity);
		}
		return { id: result, itemEntity: itemEntity };
	}

	static updateItemOnSession(item: Item, convertVueObjectToItem = false) {
		if (convertVueObjectToItem) { 
			let itemEntity = PositiveTS.Storage.Entity.Item.convertVueObjectToItem(item);
			session.allItems.set(item.code, itemEntity);
		} else {
			session.allItems.set(item.code, item);
		}
	}

	search (query) {
		var aThis = this;

		// Perform case-insensitive search
		query = query.toUpperCase();

		let sql = `select * from itemSearch where code match "${query}*"
						or description match "*${query}*"
						order by code ASC limit 10`;

		let result = this.execAndConvertResult(sql);

		let uniqueItems = _.keyBy(result, 'id')

		let objectResult = Object.values(uniqueItems).map(entityItem => ({item: entityItem, itemBarcode: null}))

		return Promise.resolve(objectResult);
	};

	static async smartSearchRoshemet(query:string) {
		const lowerCaseQuery = query.toLowerCase();
		let items = await appDB.localItems.filter((item) => {
			let isItemDescriptionMatch = !posUtils.isNullOrUndefinedOrEmptyString(item.description) && item.description.toLowerCase().includes(lowerCaseQuery);
			let isBarcodeMatch = !posUtils.isNullOrUndefinedOrEmptyString(item.barcode) && (item.barcode.toLowerCase().startsWith(lowerCaseQuery) || item.barcode.toLowerCase().endsWith(lowerCaseQuery));
			return item.locked == false && (isItemDescriptionMatch || isBarcodeMatch)
		}).toArray();

		items = items.sort(item => item.description.length)
		items.splice(8)
		return items.map(item => Item.import(item)).map(item=> { return {item: item, itemBarcode:null} });
	}

	static async smartSearch(query) {
		if (session.pos.isRoshemet){
			return Item.smartSearchRoshemet(query)
		}
		console.time("smartSearch")

		// Perform case-insensitive search
		query = query.toUpperCase().trim();


		if (query == "") {
			return [];
		}

		let revQuery = query.split('').reverse().join('');
					
		let sqlQuery = `select * from itemSearch where barcoderev match "${revQuery}*" or barcode match "${query}*"
		or coderev match "${revQuery}*" or code match "${query}*"`
		if(!jsonConfig.getVal(jsonConfig.KEYS.barcodeSearchOnly)) {
			sqlQuery += `or description match "*${query}*"`
		}
		sqlQuery += `order by length(code) ASC limit 50`

		let result = Service.WasmDB.execAsObject(sqlQuery)

				
		let items = [];
		for (var i = 0; i < result.length; i++) {
			items.push({ item: result[i], itemBarcode: result[i] });
		}

		console.timeEnd("smartSearch")
		return items;
	
	}

	static async searchByCode (code):Promise<Array<any>> {

		console.time("searchByCode")
		if (code) {
			code = code.trim();
			if (code == "") {
				return [];
			}
		}
		
		let item = session.allItems.get(code);
		if (item == null) {
			
			let tempCode = "0" + code;
			item = session.allItems.get(tempCode);
			if (item == null){

  			console.timeEnd("searchByCode");
				return [];
			}
		}
		let itemModel = new Item();
		itemModel.importFromObject( item);

		console.timeEnd("searchByCode")
		return [{item: itemModel, itemBarcode: null}]

	};

	async searchByBarcode(barcode):Promise<Array<any>> {

		if (barcode == '') {
			return [];
		}

		// Perform case-insensitive search
		barcode = barcode.toUpperCase().trim();
		console.time('searchByBarcode')

		let itemBarcode;
		if (session.pos.isRoshemet) {
			itemBarcode = <ItemBarcodeWithItem>(Service.AllItems.getItemBarcodeByBarcode(barcode));
		}
		else {
			itemBarcode = Service.AllItems.getItemBarcodeByBarcode(barcode);
		}
		if (itemBarcode == null) {
			
			if(!session.pos.isCaveret) {
				let tempBarcode = "0" + barcode;
				itemBarcode = Service.AllItems.getItemBarcodeByBarcode(tempBarcode);
			}
			if (itemBarcode == null){

  			console.timeEnd('searchByBarcode');
				return [];
			}
		}
		let itemModel = new Item();
		let ibModel = new ItemBarcode();
		itemModel.importFromObject( itemBarcode);
		ibModel.importFromObject(itemBarcode)
		console.timeEnd('searchByBarcode')

		return [{item: itemModel, itemBarcode: ibModel}]
	
	};

	static fromItemsToMenuItem(item, level): PositiveTS.Types.MenuButton {
		return {
			level: level, isItem: true, itemId: item.id, sortOrder: 0, name: item.description,
			code: item.code, color: null, id: item.code, hasPicture: item.hasPicture, price: item.priceZarhan
		}
	}

	static getItemTranslations(itemCode: string): string {
		let item: Item = session.allItems.get(itemCode);
		return item.translations;
	}

}

export class ItemBarcodeWithItem extends Item {
	barcode:string
	color:string
	size:string		
}

}}}
