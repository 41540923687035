module PositiveTS {
    export module Components {
      export module PosMenuButton {
  
        export function getComponent() {
          return {
            template: JST.posMenuButton(),
            props: {
                button: {
                    type: Object
                },
                totalButtons: {
                    type: Number,
                    default: null,
                },
                primaryMenuButton: {
                    type: Boolean,
                    default: false,
                },
                selected: {
                    type: Boolean,
                    default: false
                }
            },
            methods: {
                buttonClicked() {
                    if (this.button.onClickedFunc) {
                        this.button.onClickedFunc();
                        return;
                    }

                    if (this.isShowDetails) {
                      this.toggleShowCloseDetails(this.button)
                      return;
                    }

                    this.$emit('menuItemClicked', this.button)
                },
                toggleShowCloseDetails() {
                    this.isShowDetails = !this.isShowDetails;
                }
            },
            computed: {
                useSelectedFontColorInSelfService(){
                    return jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService) && jsonConfig.getVal(jsonConfig.KEYS.selfServiceColorPickerForHeadlines);
                },
                useShortDetails() {
                    return jsonConfig.getVal(jsonConfig.KEYS.useShortDetails);
                },
                hasPicture() {
                    let isSimpleSelfService = jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService);

                    let hasSelfServiceDefaultImage = false;

                    if (this.button.isItem) {
                        hasSelfServiceDefaultImage = isSimpleSelfService && this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM];
                    } else {
                        hasSelfServiceDefaultImage = isSimpleSelfService && this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_MENU];
                    }

                    return this.usePictures && (this.button.hasPicture || this.button.pictureUrl || hasSelfServiceDefaultImage);
                },
                buttonStyle() {
                    let styleObj = {};
                    let backgroundStyle = posUtils.isNullOrUndefinedOrEmptyString(this.button.color) ? null :  this.button.color;
                    
                    if (backgroundStyle != null && !this.selected && !this.hasPicture) {
                      styleObj['backgroundColor'] = backgroundStyle;
                      styleObj['borderColor'] = PositiveShared.Utils.LightenDarkenColor(backgroundStyle,-50)
                    }
  
                    if(this.hasPicture){
                      styleObj['position'] = 'relative'
                    }
  
                    if (session.pos.hasFlights && posVC.isCurrentSaleComplimentaryDiscountSale() && 
                        session.allItems.get(this.button.code) && (!session.allItems.get(this.button.code).isComplimentary)) {
                          styleObj['backgroundColor'] = 'var(--disabled-color)';
                          styleObj['borderColor'] = 'var(--disabled-color)';
                    } 
  
                    if (!posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.numberOfMenuItemsPerRow))) {
                      let percentage = 100 / jsonConfig.getVal(jsonConfig.KEYS.numberOfMenuItemsPerRow);
                      styleObj['flex'] = `0 0 calc(${percentage}% - 4px)`;
                    }
                    
                    return styleObj;
                },
                mainClasses() {
                    let mainClasses =  {'menu': this.button.isPrimaryMenuItem || this.button.isSecondaryMenuItem || this.button.isThirdMenuItem, 
                                        'has-picture': this.hasPicture && !this.selfServiceNewItemButtonStyle,
                                        'has-picture-new': this.hasPicture && this.selfServiceNewItemButtonStyle,
                                        'squared': !this.hasPicture && this.selfServiceNewItemButtonStyle,
                                        'is-not-self-service': !(this.simpleSelfService && !this.selfServiceSuperMarket),
                                        'selected': this.selected,
                  };

                  if (this.classByTotalButtons) {
                    mainClasses[this.classByTotalButtons] = true;
                  }

                  return mainClasses;
                },
                classByTotalButtons() {
                    let result = null; // When it's more then 5 buttons
                    if (this.totalButtons && !this.primaryMenuButton) {
                        if (this.selfServiceQuickSale) {
                            return 'buttons-count-2-quick-sale'
                        }
                        if (this.totalButtons < 3) {
                            result = 'buttons-count-2';
                        }
                        if (this.totalButtons < 5) {
                            result = 'buttons-count-4';
                        }
                    }

                    return result;
                },
                buttonPicture() {
                    if (posUtils.isPresent(this.button?.pictureUrl)){
                      return this.button.pictureUrl
                    }

                    let item = session.allItems.get(this.button.code || '');

                     // is the old kombina that image come from item with code is name of menu item
                    if (item && item.hasPicture) {
                        return `/catalog-images/items/${session.pos.tenantID}/${session.pos.companyID}/${this.button.code}`
                    }

                    return this.button.isItem ? this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM]
                                              : this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_MENU];
                },
                currencySign() {
                    return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                },
                usePictures() {
                    return jsonConfig.getVal(jsonConfig.KEYS.usePictures);
                },
                sprateBtnImgText() {
                    return jsonConfig.getVal(jsonConfig.KEYS.sprateBtnImgText);
                },
                fullBtnImgText() {
                    return jsonConfig.getVal(jsonConfig.KEYS.fullBtnImgText);
                },
                displayPricesOnButtons() {
                    return jsonConfig.getVal(jsonConfig.KEYS.displayPricesOnButtons);
                },
                selfServiceNewItemButtonStyle() {
                    return jsonConfig.getVal(jsonConfig.KEYS.selfServiceNewItemButtonStyle);
                },
                showIButton() {
                    return this.simpleSelfService && jsonConfig.getVal(jsonConfig.KEYS.showItemDetails) && posUtils.isPresent(this.button.details)
                },

            },
            setup(){

                const globalStore = Pinia.useGlobalStore();
                const {
                    simpleSelfService,
                    selfServiceSuperMarket,
                    mobileLayout,
                    codeToQtyMap,
                    defaultImages,
                    outOfStockItemCodesByCode,
                    selfServiceQuickSale
                } = Pinia.storeToRefs(globalStore);

                const globalStoreProps = {
                    simpleSelfService,
                    selfServiceSuperMarket,
                    mobileLayout,
                    codeToQtyMap,
                    defaultImages,
                    outOfStockItemCodesByCode,
                    selfServiceQuickSale
                }
  
                return {...globalStoreProps}
            },
            data() {
                return {
                    isShowDetails: false,
                }
            }
          }
        }
      }
    }
  } 
  