module PositiveTS {
    export module Components {
        export module Mixins {
            export let saleItemMixin = {
                data() {
                    return {
                    }
                },
                computed: {
                    promoGroups(){
                        return Pinia.globalStore.promoGroups
                    },
                },
                methods: {
                    deleteDiscountFromSaleItem: (saleItem: Storage.Entity.SaleItem, disablePromotions) => posVC.deleteDiscountFromSaleItem(saleItem, disablePromotions),
                    removeCompensation: (saleItem: Storage.Entity.SaleItem) => posVC.removeCompensationOnItem(saleItem),
                    confirmSaleDiscountDelete: () => posVC.confirmSaleDiscountDelete(),
                    fixedFloat: session.fixedFloat,
                    getTotalAmountString(total: number): string {
                        return `${session.fixedNumber(Math.abs(total))}${total < 0 ? '-' : ''}`;
                    },
                    promotionsForRow(saleItem: Storage.Entity.SaleItem) {
                        let totalWithDiscounts = Number(saleItem.displayTotal);
                        let promoGroups = this.promoGroups.filter((pg: Promotions.PromoGroup) => {
                            return !pg.isSaleDiscount && pg.rowToShowOn == saleItem.rowNumber && pg.discountAmountForGroup != 0;
                        })

                        return promoGroups.map((pg: Promotions.PromoGroup, index) => {
                            totalWithDiscounts -= pg.discountAmountForGroup;
                            return {
                                ...pg,
                                totalToDisplay: this.getTotalAmountString(totalWithDiscounts),
                                isNotLast: index < (promoGroups.length - 1)
                            }
                        });
                    },
                    hasDiscounts(saleItem: Storage.Entity.SaleItem) {
                        let promoGroups = this.promoGroups.filter((pg: Promotions.PromoGroup) => {
                            return !pg.isSaleDiscount && pg.rowToShowOn == saleItem.rowNumber && pg.discountAmountForGroup != 0;
                        })
                        return saleItem.promotions != null && promoGroups.length != 0
                    },
                },
            }
        }
    }
}

