module PositiveTS {
export module Service {
export module CashierStatement {

  export function getLastEnterCashierStatement() {
    if ( localStorage.getItem('lastEnterCashierStatement') === null ) {
      return i18next.t("none");
    } else {
      return session.fixedNumber( localStorage.getItem('lastEnterCashierStatement') );
    }
  }

  export function getCashSumWithdrawnToSafe() {
    if ( localStorage.getItem('cashWithdrawnToSafe') === null ) {
      return 0;
    } else {
      return session.fixedFloat( localStorage.getItem('cashWithdrawnToSafe') );
    }
  }

  export function getCashierZStatement() {
    if ( localStorage.getItem('cashierZStatement') === null ) {
      return 0;
    } else {
      return Number(localStorage.getItem('cashierZStatement'));
    }
  }

  export function getLastEnterCashierStatementNumber() {
    if ( localStorage.getItem('lastEnterCashierStatement') === null ) {
      return 0;
    } else {
      return Number(localStorage.getItem('lastEnterCashierStatement'));
    }
  }

  export function getLastEnterCashierStatementNumberCurrencies(): { [key: string]: number } {
    return posUtils.jsonParse(localStorage.getItem('LastEnterCashierStatementCurrencies')) || {}
  }

  export function getCashCurrenciesSumWithdrawnToSafe(): { [key: string]: number } {
    return posUtils.jsonParse(localStorage.getItem('cashCurrenicesWithdrawnToSafe')) || {}
  }

  export function setLastEnterCashierStatement(str) {
    localStorage.setItem('lastEnterCashierStatement',str)
  }

  export function setLastEnterCashierStatementCurrencies(currencies: Types.CashierStatementMultiCurr[]) {
    let currencyAmounts = {};
    for (const curr of currencies) {
      currencyAmounts[curr.code] = curr.currencyAmount;
    }
    localStorage.setItem('LastEnterCashierStatementCurrencies',JSON.stringify(currencyAmounts));
  }

  export function resetLastEnterCashierStatementCurrencies() {
    let multiCurr = Service.MultiCurr.getInstance();
    let currArr = multiCurr.getCurrenciesEnabled();
    let currenciesEnabledReset: Types.CashierStatementMultiCurr[] = currArr.map(c => { return { currencyAmount: 0, code: c } });
    setLastEnterCashierStatementCurrencies(currenciesEnabledReset);
  }

  export function setCashierZStatement(str:string) {
    localStorage.setItem('cashierZStatement',str)
  }

  export function resetCashSumWithdrawnToSafe(sum) {
    localStorage.setItem('cashWithdrawnToSafe',String(sum));
  }

  export function deleteCashCurrencuesSumWithdrawnToSafe() {
    localStorage.removeItem('cashCurrenicesWithdrawnToSafe')
  }
  
  export function addCashSumWithdrawnToSafe(sum) {
    if( localStorage.getItem('cashWithdrawnToSafe') === null) {
      localStorage.setItem('cashWithdrawnToSafe', String(sum));
    } else {
      localStorage.setItem('cashWithdrawnToSafe', String(parseFloat(localStorage.getItem('cashWithdrawnToSafe')) + parseFloat(sum)));
    }
  }

  export function addCashCurrenciesSumWithdrawnToSafe(currencies: Types.CashierStatementMultiCurr[]) {
    let currencyAmounts = {};
    for (const curr of currencies) {
      currencyAmounts[curr.code] = curr.currencyAmount;
    }
    let previousCashCurrenciesInSafe = getCashCurrenciesSumWithdrawnToSafe();
    let currenciesEnabled = MultiCurr.getInstance().getCurrenciesEnabled();
    let currentCashCurrenciesInSafe = {};
    currenciesEnabled.forEach(c => { currentCashCurrenciesInSafe[c] = (previousCashCurrenciesInSafe[c] || 0) + (currencyAmounts[c] || 0) })
    localStorage.setItem('cashCurrenicesWithdrawnToSafe', JSON.stringify(currentCashCurrenciesInSafe));
  }

  export async function generateMultiCurrencyStatement(currencies: Types.CashierStatementMultiCurr[], totalAmount, type = Shared.Constants.CashierStatement.TYPE_START_DAY) {
    let showCoinsGap = jsonConfig.getVal(jsonConfig.KEYS.showMultiCurrCoinGap);
    totalAmount = Number(totalAmount);
    let newStatement = new Storage.Entity.CashStatementLog();
    newStatement.employee_id = session.pos.employeeID;

    let statementData:any = { currencies };

    if (session.pos.hasFlights) {
        statementData.flight_id = (Pinia.flightsStore.currentFlight || {}).id;
        statementData.leg_ids = (Pinia.flightsStore.currentTeamLegs || []).map(leg => leg.id);
        statementData.date = new Date();
    }

    newStatement.data = JSON.stringify(statementData);
    newStatement.type = type;
    newStatement.amount = totalAmount;
    newStatement.syncStatus = Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;

    if (type == Shared.Constants.CashierStatement.TYPE_START_DAY) {
      PositiveTS.Service.CashierStatement.setLastEnterCashierStatement(totalAmount);
      PositiveTS.Service.CashierStatement.setLastEnterCashierStatementCurrencies(currencies);      
    }

    if (type == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS) {
      let lastEnterCashierStatementNumber: any = PositiveTS.Service.CashierStatement.getLastEnterCashierStatementNumber();
      PositiveTS.Service.CashierStatement.setLastEnterCashierStatement(parseFloat(totalAmount) + parseFloat(lastEnterCashierStatementNumber));
      
      let LastEnterCashierStatementNumberCurrencies = PositiveTS.Service.CashierStatement.getLastEnterCashierStatementNumberCurrencies();
      currencies.forEach(c => c.currencyAmount += LastEnterCashierStatementNumberCurrencies[c.code]);
      PositiveTS.Service.CashierStatement.setLastEnterCashierStatementCurrencies(currencies);
      
    }

    if (type == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS) {
      PositiveTS.Service.CashierStatement.addCashSumWithdrawnToSafe(totalAmount);
      PositiveTS.Service.CashierStatement.addCashCurrenciesSumWithdrawnToSafe(currencies);
    }

    if (type == PositiveTS.Shared.Constants.CashierStatement.TYPE_END_DAY) {
        newStatement = await setCashierZStatementMultiCurrency(totalAmount, newStatement)
    }

    if (newStatement instanceof PositiveTS.Storage.Entity.CashStatementLog) {
        await appDB.cashStatementLogs.put(newStatement);
    }

    return newStatement;
  }

  export async function loadCashierStatementLogsIfNeed (cashierStatementLogs: Array<any>) {
    let result = {success: false, error: ''}

    try {
      const cashStatementLogsCount =  await appDB.cashStatementLogs.count()

      //load only one time and if not have any record
      if (cashStatementLogsCount === 0){
        cashierStatementLogs = _.orderBy(cashierStatementLogs, 'statement_time')

        for (let csl of cashierStatementLogs){
          let newStatement = new PositiveTS.Storage.Entity.CashStatementLog()
          newStatement.amount = csl.amount
          newStatement.cash_in_safe = csl.cash_in_safe
          newStatement.data = JSON.stringify(csl.data)
          newStatement.difference = csl.difference
          newStatement.employee_id = csl.employee_id
          newStatement.opening_amount = csl.opening_amount
          newStatement.serverId = csl.id
          newStatement.statement_time = moment(csl.statement_time).unix()
          newStatement.syncStatus = Shared.Constants.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY
          newStatement.timestamp = moment(csl.created_at).unix()
          newStatement.type = csl.statement_type
          newStatement.z_amount = csl.z_amount

          await appDB.cashStatementLogs.put(newStatement)

          if (newStatement.type == Shared.Constants.CashierStatement.TYPE_START_DAY) {
            PositiveTS.Service.CashierStatement.setLastEnterCashierStatement(newStatement.amount.toFixed(2))
          }
    
          if (newStatement.type == Shared.Constants.CashierStatement.TYPE_ADD_TO_POS) {
            let lastEnterCashierStatementNumber: any = PositiveTS.Service.CashierStatement.getLastEnterCashierStatementNumber();
            PositiveTS.Service.CashierStatement.setLastEnterCashierStatement((newStatement.amount + parseFloat(lastEnterCashierStatementNumber)).toFixed(2));
          }
    
          if (newStatement.type == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS) {
            PositiveTS.Service.CashierStatement.addCashSumWithdrawnToSafe(newStatement.amount.toFixed(2));
          }
        }
      }

      result.success = true      
    }catch(error){
      console.error(error)
      result.error = i18next.t('cashierStatement.errorLoadCashierStatementLogs')
    }

    return result 
  }

  async function setCashierZStatementMultiCurrency(totalAmount, newStatement) {
    let realZCash = await Service.Withdrawal.cashInPos();
    let difference = realZCash - totalAmount;
    let allowedDifference = jsonConfig.getVal(jsonConfig.KEYS.allowedDeclarationToXDifference);
    if (difference > allowedDifference || difference < allowedDifference * -1) {
        let zDifferenceDialog = await app.promiseShowAlert({
            header: i18next.t('error'),
            content: i18next.t('cashierStatement.zDifferenceProblem'),
            continueButtonText: i18next.t("cashierStatement.zDifferenceProblemOk"),
            cancelButtonText: i18next.t("cashierStatement.zDifferenceProblemCancel"),
            hideCancelButton: false
        });
        if (zDifferenceDialog == "cancel") {
            return false;
        }
    }
    PositiveTS.Service.CashierStatement.setCashierZStatement(totalAmount);
    if (difference < 0) {
        difference *= -1;
    }
    newStatement.difference = difference;
    newStatement.z_amount = realZCash;
    newStatement.opening_amount = PositiveTS.Service.CashierStatement.getLastEnterCashierStatementNumber();
    newStatement.cash_in_safe = Number(PositiveTS.Service.CashierStatement.getCashSumWithdrawnToSafe());
    newStatement.syncStatus = PositiveTS.Shared.Constants.CashierStatement.SYNC_STATUS_AWAITING_Z;
    pNavigator.pushPage('zreport', i18next.t('homepage.zreport'), '/homepage', null);
    zReportVC.createNewZReportMain();

    return newStatement;
  }

  export function forceCashDeclarationsAtBeginningOfDay() {
    let forceCashDeclarations = jsonConfig.getVal(jsonConfig.KEYS.forceCashDeclarations)

    return forceCashDeclarations === true || forceCashDeclarations == 1 || forceCashDeclarations == 2
  }

  export function forceCashDeclarationsAtEndOfDay() {
    let forceCashDeclarations = jsonConfig.getVal(jsonConfig.KEYS.forceCashDeclarations)

    return forceCashDeclarations === true || forceCashDeclarations == 1 || forceCashDeclarations == 3
  }


}
}
}