module PositiveTS {
export module Service {
export module ShipmentInvCredit {

  interface validate_object {
    valid: boolean, 
    errors: Array<string>
  }

  export function isShipmentInvCreditable (sale, itemsAndSalesToCredit): validate_object {
    let result = {valid: false, errors: []};

    if (_isShipmentInvDocument(sale)) {

      if (itemsAndSalesToCredit.itemsToCredit.length <= 0) {
        result.errors.push(i18next.t('isCreditable.canNotCreditSaleNoItemsAvailableToCredit'));
      }

      result.valid = result.errors.length === 0;
    }
    
    return result;
  }

  export function creditShipmentInvItem(aThis){
      aThis.creditSaleItem();
  }

  function _isShipmentInvDocument(sale):boolean{
    return sale.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_SHIPMENT_INV;
  }


}}}
