module PositiveTS {
export module Service {
export module CheckPosPropriety {
    export const LocalStorageCheckPosProprietyStatus = 'Positive.CheckPosProprietyStatus'
    export const STATUSES = {
        WAITING: 'waiting',
        FAILED: 'failed',
        PASSED: 'passed'
    }

    export function isCannotWorkOnPos () {
        return [STATUSES.WAITING, STATUSES.FAILED].includes(getStatus())
    }

    export function isCheckPosProprietyFailed () {
       return getStatus() == STATUSES.FAILED
    }

    export function getStatus () {
        return localStorage.getItem(LocalStorageCheckPosProprietyStatus)
    }

    export function setStatus (status: string) {
        return localStorage.setItem(LocalStorageCheckPosProprietyStatus, status)
    }

    export async function check () {
        const url = '/check_pos_propriety'
        const data = {
            physical_id: session.pos.physicalID,
            sequences: await appDB.sequences.toArray()
        }

        const res = await Service.FetchReq.jsonReq(url, 'post', data)

        if(!res.result?.success && res.result?.errors?.length > 0 ) {
            let errors = res.result.errors
            for (const error of errors) {
                console.error(error)
            }
        }

        

        return res.result
    }

    export async function syncLocalSalesWithServer(): Promise<{success: boolean}> {        
        let result = { success: true }

        let serverInvoiceSequencesHash = {}
        
		let localSales = await appDB.sales.toArray()
        localSales = localSales.filter(sale => sale.invoiceType != PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE); //Gili the cuck, built the punch card, its broken as fuck, so it's sales we discard
    
        let fromDate = null;
        if(localSales.length > 0) {
            let firstSaleDate =  localSales.sort(function(a,b){
                return <any>new Date(a.createdAt) - <any>new Date(b.createdAt);
            })[0].createdAt;
            if(new Date(firstSaleDate).getTime() <  new Date().getTime() - (30 * 24 * 60 * 60 * 1000)) {  // Check for 30 days back
                fromDate = firstSaleDate;
            }
        }

        let serverSales = await PositiveTS.Service.Sale.GetSalesWithoutZReport(session.pos.deviceID, fromDate) 
        serverSales = serverSales.filter(sale => sale.invoiceType != PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE); //Gili the cuck, built the punch card, its broken as fuck, so it's sales we discard

        for (const sale of serverSales) {
            serverInvoiceSequencesHash[sale.invoiceSequence] = sale
        }

		for (const localSale of localSales) {
			if(localSale.invoiceSequence < 0 ) {
				continue
            }
            
            if(posUtils.isBlank(serverInvoiceSequencesHash[localSale.invoiceSequence])) {
                result.success = false
                Service.Logger.error(`local sale was not found in server sales - saleID: ${localSale.id}, invoiceSequence: ${localSale.invoiceSequence}, amount: ${localSale.totalAmount}, date: ${moment(localSale.timestamp).toString()}`);
            }
		}

        if (!result.success) {
            return result;
        }

        //get server sales that are not in local
        let serverSalesNotInLocal = serverSales.filter(serverSale => 
            serverSale.invoiceSequence > 0 && !localSales.some(localSale => localSale.invoiceSequence == serverSale.invoiceSequence));
		// Sync missing server sales to local
        let salesToInsertAsync = [];
        for (let sale of serverSalesNotInLocal) {
            sale.posPhysicalID = session.pos.physicalID;
            sale.zNumber = Storage.Entity.Sale.NULL_Z_REPORT;
            salesToInsertAsync.push(Service.FullSale.persist(sale, sale.items, sale.payments, true));
        }
        await Promise.all(salesToInsertAsync);
        return result
	}

    export async function getItemNotSyncToServer (tableName) {
        let itemNotSyncToServer = await appDB[tableName].where('syncStatus')
            .anyOf([PositiveTS.Shared.Constants.SyncStatuses.SYNC_STATUS_WAITING_TO_BE_SENT, PositiveTS.Shared.Constants.SyncStatuses.SYNC_STATUS_FAILED])
            .toArray()
        
        return itemNotSyncToServer
    }
}
}
}