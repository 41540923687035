module PositiveTS {
    export module Promotions {
    export module Templates {
    export class Medorag extends TemplateAbstract {
      private promoGroups:Array<any>
   
    
      constructor(initParameters) {
        super(initParameters)
        this.promoGroups = [];
      }
    
      calculatePromotions() {
          throw Error("Whatcha talkin bout Willis")
      }
    
      run(promotion:Storage.Entity.Promotion) {
        this.promoGroups = [];
        let allItems:Array<any> = [];
        let sortedItems:Array<any>;
        let usedSalim = [false, false, false, false];
        let itemsForPromotion:Array<Array<any>> = []
        let allItemsFromPos = this.parameters.updatedSaleItems;
        let lastSal = 2;

        if(!posUtils.isNullOrUndefined(promotion.discountPercent4)) {
            lastSal = 4;
        } 
        else if(!posUtils.isNullOrUndefined(promotion.discountPercent3)) {
            lastSal = 3;
        }

        if(!promotion.allowWithOtherPromotions) {
            allItemsFromPos = allItemsFromPos.filter(item => !item.hasPromotion)
        }

        for (const item of this.parameters.updatedSaleItems) {
            if (promotion.isHappyHour && NewPromotionsEngine.happyHourIsActiveForSaleItem(promotion, item) == false) {
                continue;
            }
            let itemSalim = this.getItemSalim(item, promotion);
            if(itemSalim.indexOf(1) != -1) {
                usedSalim[0] = true;
            }
            if(itemSalim.indexOf(2) != -1) {
                usedSalim[1] = true;
            }

            if(itemSalim.length > 0) {
                allItems.push({
                    item: item,
                    salim: itemSalim,
                    price: item.unitPrice,
                })
            }
        }

        if(!usedSalim[0] || !usedSalim[1]) {
            return ;
        }

        sortedItems = allItems.sort(this.sortSaleItems);

        let runSuccess = true;
        let maxTimesToRun = 1;
        let promotionRepeatedTimes = 0;

        if(promotion.allowMultipleTimesSameSale) {
            if(posUtils.isNullOrUndefinedOrEmptyString(promotion.maxQuantityToGiveCustomer)) {
                maxTimesToRun = Number.POSITIVE_INFINITY;
            } else {
                maxTimesToRun = Number(promotion.maxQuantityToGiveCustomer);
            }
        }

        for(let runNum = 0; runSuccess && runNum < maxTimesToRun; runNum++) {
            runSuccess = false;
            for(let sal = 0; sal < lastSal; sal++) {
                let itemToUseIndex = sortedItems.findIndex(item => item.salim.indexOf(sal+1) !== -1);
                if(itemToUseIndex == -1) {
                    break ;
                }
                let itemToUse = sortedItems[itemToUseIndex];
                sortedItems.splice(itemToUseIndex, 1);

                if(posUtils.isNullOrUndefined(itemsForPromotion[runNum]) && sal == 0) {
                    itemsForPromotion[runNum] = [];
                    itemsForPromotion[runNum].push(itemToUse);
                }
                else if(!posUtils.isNullOrUndefined(itemsForPromotion[runNum][sal-1])) {
                    itemsForPromotion[runNum].push(itemToUse)
                    runSuccess = true
                } else {
                    break ;
                }
            }
            if(runSuccess) {
                promotionRepeatedTimes++;
            }
        }

        for(let i = 0; i<promotionRepeatedTimes; i++) {
            itemsForPromotion[i].forEach((item, index) => {
                this.promoGroups.push({
                    discountAmountForGroup: ((item.price/100)*promotion[`discountPercent${index+1}`]),
                    promotion: promotion,
                    totalPriceForItemsBeforeDiscount: item.price,
                    // item: {
                    //     buyGet: "buy",
                    //     discountAbsoluteValue: (item.price/promotion[`discountPercent${index+1}`]).toFixed(2),
                    //     discountPrecentValue: promotion[`discountPercent${index+1}`],
                    //     discountType: "1",
                    //     isPromotionGiven: true,
                    //     promotionCode: promotion.code,
                    //     promotionName: promotion.name,
                    //     saleItemID: item.item.id,
                    // },
                    rowValueCounter: this.addToRowValueCounter(item.item,{}),
                    itemsCounter: this.addToItemsCounter(item.item, {})

                })
            })
        }

        this.parameters.promoGroups = this.promoGroups;

        
      }

      sortSaleItems(a, b) {
        if(a.salim[0] != b.salim[0]) {
            return a.salim[0] - b.salim[0]
        }

        if(a.price != b.price) {
            return b.price - a.price
        }

        return 0;
      }

      getItemSalim(item:Storage.Entity.SaleItem, promotion:Storage.Entity.Promotion) {
        let salim = [];

        if(!posUtils.isNullOrUndefined(item.item.promoBuy)) {
            if(item.item.promoBuy.split("&").indexOf(promotion.code) != -1) {
                salim.push(1);
            }
        }
        if(!posUtils.isNullOrUndefined(item.item.promoGet)) {
            if(item.item.promoGet.split("&").indexOf(promotion.code) != -1) {
                salim.push(2);
            }
        }
        if(!posUtils.isNullOrUndefined(item.item.promoSal3)) {
            if( item.item.promoSal3.split("&").indexOf(promotion.code) != -1) {
                salim.push(3);
            }
        }
        if(!posUtils.isNullOrUndefined(item.item.promoSal4)) {
            if(item.item.promoSal4.split("&").indexOf(promotion.code) != -1) {
                salim.push(4);
            }
        }
        return salim;
      }
    }
    }}}
