module PositiveTS {
    export module Components {
        export module PositiveCheckbox {
            export function getComponent() {
                return {
                    inheritAttrs: false,
                    template: JST.positiveCheckbox(),
                    props: {
                        modelValue: {
                            type: Boolean,
                            default: false,
                        },
                        label: {
                            type: String,
                            default: null,
                        },
                    },
                    methods: {
                        toggle() {
                            this.$emit('update:modelValue', !this.modelValue); 
                        }
                    },
                    computed: {
                        iconClass() {
                            return this.modelValue ? 'fa-light fa-circle-check' : 'fa-light fa-circle';
                        }
                    }
                }
            }
        }
    }
}