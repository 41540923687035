module PositiveTS {
export module Storage {
export module Entity {


export const enum FlightsDiscountCodes {
	complimentary = '1000',
	crew = '2000'
}
		
export const enum FlightsDiscount {
	complimentary = 'D1000',
	crew = 'D2000'
}

export class Discount extends WasmEntity {
  discountID:string
  name:string
  approvedPrecent:number
  managerPrecent:number
  typeID:number
  isActive:boolean
  minimumAmount:string
  allowDuplicatePromotion:boolean
  isClubMemberOnly: boolean
  fromDate:string
  toDate:string

  
  constructor() {
    let meta = {
      name: 'Discount',
      fields: {
        discountID: "TEXT",
        name: "TEXT",
        approvedPrecent: "FLOAT",
        managerPrecent: "FLOAT",
        typeID: "INT",
        isActive: "BOOL",
        minimumAmount: "TEXT",
        allowDuplicatePromotion: "BOOL",
        isClubMemberOnly: "BOOL",
        fromDate: "TEXT",
        toDate: "TEXT"
      },
      money: ['minimumAmount']
    };
    super(meta)
  }

  static TYPE_ITEM = '1';
  static TYPE_SALE = '2';
  static TYPE_AMOUNT_BARCODE = '3';
  static TYPE_PCT_BARCODE = '4';
  static SPECIAL_DISCOUNT_TYPE = '-2';

  _get_type_condition_string(type) {

    var SQL_CONDITION_ITEM_OR_BARCODE = "typeID in (" +
                PositiveTS.Storage.Entity.Discount.TYPE_ITEM + "," +
                PositiveTS.Storage.Entity.Discount.TYPE_AMOUNT_BARCODE + "," +
                PositiveTS.Storage.Entity.Discount.TYPE_PCT_BARCODE +
                        ") ";
    var sql_condition_type = function(){
      return "typeID = " +
            type +
            " ";
    }

    if (type == PositiveTS.Storage.Entity.Discount.TYPE_ITEM &&
      PositiveTS.Reachability.isOnline) {
        return SQL_CONDITION_ITEM_OR_BARCODE;
      } else {
        return sql_condition_type();
      }

  }

  static async fetchByStoreAndType(storeID, type, item):Promise<Discount[]> {
    return (new Discount()).fetchByStoreAndType(storeID, type, item);
  }

  /**
   * @param {Integer} storeID The store ID to check stock.
   * @param {Integer} type The discount type, one from PositiveTS.Storage.Entity.Discount.TYPE_*.
   * @param {PositiveTS.Storage.Entity.Item} item An instance of PositiveTS.Storage.Entity.Item.
   */
  fetchByStoreAndType(storeID, type, item) {

    var aThis = this;

    // When fetching item discounts, there is an option to specify an item for which to fetch discounts
    var itemDiscountsString = '';

    if (type == PositiveTS.Storage.Entity.Discount.TYPE_ITEM && item != null) {
      var discountIDs = item.discounts.split('&');
      for (var loop = 0; loop < discountIDs.length; loop++) {
        itemDiscountsString = itemDiscountsString + ` OR d.discountID = ${discountIDs[loop]}`;
      }
      itemDiscountsString = itemDiscountsString.substr(3, itemDiscountsString.length);
      itemDiscountsString = ' AND (' + itemDiscountsString + ')';
    }

    // Get the DiscountStore table name
    var discountStoreEntityName = (new PositiveTS.Storage.Entity.DiscountStore()).meta.name;

    let query = `SELECT d.* FROM ${aThis.meta.name}  AS d
        WHERE 
          ( 
            EXISTS (SELECT 1 FROM  ${discountStoreEntityName} as ds WHERE ds.storeID = ${storeID} AND ds.discountID = d.discountID) 
            OR 
            NOT EXISTS (SELECT 1 FROM  ${discountStoreEntityName} as ds WHERE ds.discountID = d.discountID) 
          ) 
        AND   
        ${aThis._get_type_condition_string(type)}
        AND isActive = 1  ${itemDiscountsString}`;

    return Promise.resolve(this.execAndConvertResult(query));
  };

  static async fetchByDiscountId(discountId):Promise<Discount> {
    var aThis = new Discount();

    let query = `SELECT d.* FROM ${aThis.meta.name}  AS d
        WHERE 
        d.discountID == ${discountId}
        AND isActive = 1`;

    try {
      let result = await Promise.resolve(this.execAndConvertResult(query));
      if(result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    } catch(error) {
      console.error(error);
      return null;
    }
  }

  static isCrewDiscountSale(saleItems) {
		return Promotion.saleContainsDiscountOrPromotionCode(FlightsDiscount.crew, saleItems);
	}

	static isComplimentaryDiscountSale(saleItems) {
		return Promotion.saleContainsDiscountOrPromotionCode(FlightsDiscount.complimentary, saleItems);
  }
  
}}}}