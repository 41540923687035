module PositiveTS {
export module Service {
export module CheckCreditInvoice {

  export function isValid(){
    if (!serviceCheckErn.isErnSupported() || !_isAnyCheckPaymentsSelected()) {return Promise.resolve();}
    if (!_isAllPaymentOfChecksSelected()){
      return false;
    }
    return true;
  }

  export function promiseCreditChecks():Promise<any>{
    if (!serviceCheckErn.isErnSupported() || !_isAnyCheckPaymentsSelected()) {return Promise.resolve();}
    if (!isValid()){
      return Promise.reject(i18next.t("checkPaymentsMustSelectAllPayments"));
    }

    var allPromise = [];
    var selectedAuthorizationNumbers = Object.keys(_getDictCountAttrOfSelectedToCredit("ern"));
    for (var i=0;i<selectedAuthorizationNumbers.length;i++ ){
      allPromise.push( serviceCheckErn.actionCancelTransaction(selectedAuthorizationNumbers[i]) );
    }


    return Promise.all( allPromise );
}

  function _getChecksAllowedToCredit(){
    var _pvcObj = posCIVC;

    var _return = $.map(_pvcObj.paymentsAllowedToCredit, function (value, key) {
      if (value.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK) {
        return value;
      } else {
        return null;
      }
    });
    return _return;
  }


  function _getChecksSelectedToCredit(){
    var _pvcObj = posCIVC;
    return _pvcObj.paymentsToCredit[PositiveTS.Storage.Entity.SalePayment.METHOD_CHECK.toString()];
  };


  function _dictCounter(byEefDict, val:string) {
     if (!byEefDict[val] ){
          byEefDict[val] = 1;
        } else {
          byEefDict[val] ++;
        }
  };

  function _getDictCountTimeStampOfAllowedToCredit(){
    var getChecksAllowedToCredit = _getChecksAllowedToCredit();

    var currentRow;
    var _return = {};
    for (var i=0; i<getChecksAllowedToCredit.length; i++){
      currentRow = JSON.parse(getChecksAllowedToCredit[i].data);
      for (var j=0; j<currentRow.length; j++ ) {
        _dictCounter(_return, currentRow[j].timeStamp.toString());
      }
    }
    return _return;
  }


  function _getDictCountAttrOfSelectedToCredit(attr:string){

    var _return = {};
    var currentCheckPayment = _getChecksSelectedToCredit();
    if (!currentCheckPayment || !currentCheckPayment.data) {return _return;}

    var rows = JSON.parse( currentCheckPayment.data);


    for (var i=0;i<rows.length;i++){
      _dictCounter(_return, rows[i][attr].toString());
    }
    return _return;
  }

  function _isAllPaymentOfChecksSelected(){
    var getDictCountTimeStampOfAllowedToCredit = _getDictCountTimeStampOfAllowedToCredit();
    var selectedChecksToCredit = _getDictCountAttrOfSelectedToCredit("timeStamp");
    for (var key in selectedChecksToCredit){
      if (selectedChecksToCredit[key] !== getDictCountTimeStampOfAllowedToCredit[key]) {
        return false;
      }
    }
    return true;
  }

  function _isAnyCheckPaymentsSelected(){
    if ( _getChecksSelectedToCredit()) {
      return true;
    } else {
      return false;
    }
  };

}}}


