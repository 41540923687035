module PositiveTS {
  export module Components {
      export module vouchersReportDialog {
  
        const dialogSelector = "vouchers-report-dialog"
  
        function initData() {
          return {
            initialized: false,
            loading: false,
            rejectFunc: null,
            resolveFunc: null,
            dialogElem: (<HTMLDialogElement>document.getElementById(dialogSelector)),
            keyboardImg: `${(<any>window).images_path}/keyboard.jpg`,
            availableVouchers: [],
            selectedVoucher: null,
            dateFormat: "dd/MM/yyyy",
            startDate: moment(new Date()).startOf("month").toDate(),
            endDate : moment(new Date()).endOf("month").toDate(),
          }
        }
  
        export function create() {
  
          let component = {
            template: JST.vouchersReportDialog(),
            components: {positiveVSelect: PositiveVueSelect.getComponent()},
            methods: {
              cleanData() {
                let result = initData();
                for (let prop in result) {
                  this[prop] = result[prop];
                }
                this.initialized = true;
              },
              cancel() {                
                this.resolveFunc()
                this.close();
              },
              printReport(summeryOnly){
                if(moment(this.startDate).diff(moment(this.endDate),"years") != 0){
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('vouchersReport.periodTooLong'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true,
                  },null,null)

                  return;
                }
                Service.VouchersReport.printReport(this.selectedVoucher.value,this.selectedVoucher.name,this.startDate,this.endDate,summeryOnly);
              },
              close() {
                if (this.initialized) {
                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector));
                  }

                  this.resolveFunc();                  
                  this.dialogElem.close();
                }
                this.cleanData()
              },
              async open(mode) {
                return new Promise(async (resolve,reject) => {
                  
                  this.initialized = true;

                  if (this.dialogElem == null) {
                    this.dialogElem = (<HTMLDialogElement>document.getElementById(dialogSelector))
                  }
                  app.showLoadingMessage(i18next.t('vouchersReport.loadingVouchers'))
                  this.availableVouchers = await Service.VouchersReport.listAvailableVouchers();
                  app.hideLoadingMessage();
                  if(this.availableVouchers.length > 0){
                    this.selectedVoucher = this.availableVouchers[0];
                  }
                  this.dialogElem.showModal();
                  this.blurInputsOnElement(this.dialogElem)
                  this.resolveFunc = resolve;
                  this.rejectFunc = reject;
                })
              }
            },
            data: initData,
          }
  
          VueApp.component('vouchers-report-dialog',component)
        }
      }
    }
  }
