module PositiveTS {
export module Service {

    export module PriorityService {

        const prioritySimplyct = 1
        const priorityQama = 2

        interface ResponseRequestPriority{
            body: string,
            headers: any,
            status: number,
            success: boolean,
            data: Array<any>,
        }

        interface PriorityItemDiscount {
            selectedDiscount: Storage.Entity.Discount, 
            selectedDiscountPrecent: number, 
            discountType: number, 
            discountValue: number,
            saleItemRowNumber: number,
        }

        export function isPriorityCompany(){
            return session.pos.companyUsePriorityApi == prioritySimplyct || session.pos.companyUsePriorityApi == priorityQama
        }

        export function isManagingInventoryInBo() {
            if(session?.pos?.priorityUserData) {
                return JSON.parse(session.pos.priorityUserData)["manage_items_inventory_in_bo"] == true
            } else {
                return false
            }
        }

        export function payHakafaCustomerInvoicesFromPriority(): boolean {
            if(session?.pos?.priorityUserData) {
                return isPriorityCompany() && JSON.parse(session.pos.priorityUserData)["pay_hakafa_customer_invoices_from_priority"] == true
            } else {
                return false
            }
        }

        // need to know / before params query break priority sometimes
        // FOO/?$filter=ba - not good
        // FOO?$filter=ba - good
        export async function request (type: string, endPointUrl: string, payload: object = {}){
            let url = encodeURI(getFullUrl(endPointUrl))

            try{
                let response = await Service.HTTPService.makeHttpRequestWithFullResponseAndValidate(url, type, payload, getHeaders())
                Service.Filelog.log("priority", JSON.stringify({payload: payload, result: response.body}))
                let request: ResponseRequestPriority = {...response, data: []}
                let result = response.getJsonBody()
                if (result){
                    request.data = result.value
                }
                return request;
            }catch(e){
                console.error(e);
                throw new Error(e)
            }
        }

        export async function importOrderToSale (order: string): Promise<{success: boolean, error: string}> {
            let result = {success: false, error: null}
            const statusCanPay = 'מאושרת לבצוע'

            try {
                if (posVC.saleItems.length > 0){
                    result.error = i18next.t('priorityImportOrderDialog.errorHasItemsOnCurrentSale')
                    return result
                }
    
                const priorityOrder = await getOrder(order)
    
                if (_.isEmpty(priorityOrder)){
                    result.error = i18next.t('priorityImportOrderDialog.errorNotFoundItemsOnOrder', {order})
                    return result
                }
                
                if (priorityOrder.ORDSTATUSDES != statusCanPay) {
                    result.error = i18next.t('priorityImportOrderDialog.errorCannotPayOrderOnStatus', {status: priorityOrder.ORDSTATUSDES})
                    return result
                }
    
                const res = await addOrderItems(priorityOrder.ORDERITEMS_SUBFORM)
                if (res.success){
                    let jsondata = JSON.parse(posVC.sale.jsondata)
                    jsondata.priorityOrder = order
                    jsondata.priorityCustomer = priorityOrder.CUSTNAME
                    posVC.sale.jsondata = JSON.stringify(jsondata)
                }

                await checkIfNeedPutPositiveCustomerOnSale(priorityOrder)
    
                return res
            }catch(e){
                console.error(e)
                result.error = i18next.t('priorityImportOrderDialog.generalError')
                return result
            }
        }

        export async function checkIfNeedPutPositiveCustomerOnSale(priorityOrder) {
            const priorityUserData = getPriorityUserData()

            if (Boolean(priorityUserData?.put_customer_club_on_import_order)){
                let customer = await getPositiveCustomer(priorityOrder.CUSTNAME)
                if (customer){
                    if (customer.customer_group_id) {
                        let customerGroups = await (new PositiveTS.Storage.Entity.CustomerGroup()).all()
                        customer['customer_group_name'] = customerGroups.find(cg => cg.code == customer.customer_group_id).name
                    }
                }
                
                let customerClubService = new Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems)
                await customerClubService.setCurrentSelectedCustomer(Service.CustomerClub.convertToPosStructure(customer))
                await posVC.loadSale()
            }
        }

        export async function getPositiveCustomer(customerNumber): Promise<PositiveTS.Storage.Entity.PositiveCustomer> {
            let customer: PositiveTS.Storage.Entity.PositiveCustomer = undefined
            let customers = await PositiveTS.Storage.Entity.PositiveCustomer.search(customerNumber)
            if (Array.isArray(customers?.result?.customers)){
                customer = customers.result.customers.find(cust => cust.customer_number == customerNumber)
            }
            return customer
        }

        export async function searchCustomersPriority (search: string, hakafaOnly = false) {
            let result = {success: false, customers: [], error: ''}
            try {
                const str = search.trim()
                const priorityUserData = getPriorityUserData()
                let endPoint = `CUSTOMERS?$filter=STATDES eq 'פעיל' `
                if (hakafaOnly){
                    if (!priorityUserData || posUtils.isBlank(priorityUserData.customer_field_is_hakafa_customer)){
                        result.error = i18next.t('priorityHakafaReportDialog.cannotSearchPriorityHakafaCustomerFieldIsHakafaCustomerIsMiss')
                        return result
                    }
                    
                    endPoint += `and ${priorityUserData.customer_field_is_hakafa_customer} eq 'Y' `
                }

                endPoint += `and (CUSTDES eq '*${str}*' ` +
                    `or CUSTNAME eq '*${str}*' `
                
                if (priorityUserData && !posUtils.isBlank(priorityUserData.customer_field_identity_card)){
                    endPoint += `or ${priorityUserData.customer_field_identity_card} eq '*${str}*' `
                }
                
                endPoint += `or PHONE eq '*${str}*')`
                
                let res = await PositiveTS.Service.PriorityService.request('get', endPoint);

                if (res && Array.isArray(res.data)) {
                    result.customers = res.data
                }
                result.success = true
            }catch (error){
                console.error(error)
                result.error = i18next.t('generalError')
            }
            
            return result
        }

        export function getPriorityUserData () {
            let pud = null
            if (session.pos.priorityUserData){
                pud = JSON.parse(session.pos.priorityUserData)
            }

            return pud
        }

        export async function getCustomerInvoices (custname, limit = null, offset = null) {
            let result = {success: false, invoices: [], error: ''}
            try {
                let endPoint = `AINVOICES?$filter=CUSTNAME eq '${custname}'&$expand=AINVOICEITEMS_SUBFORM`
                if (limit){
                    endPoint += `&$top=${limit}`
                }

                if (offset){
                    endPoint += `&$skip=${offset}`
                }
                
                let res = await PositiveTS.Service.PriorityService.request('get', endPoint)

                if (res && Array.isArray(res.data)) {
                    result.invoices = res.data
                }
                result.success = true
            }catch (error){
                console.error(error)
                result.error = i18next.t('generalError')
            }

            return result
        }

        export async function getCustomerObligoInvoices(custname) {
            let result = {success: false, invoices: [], error: ''}
            try {
                let endPoint =`OBLIGO?$filter=CUSTNAME eq '${custname}' &$select=CUSTNAME,CUST&$expand=OBLIGO_FNCITEMS_SUBFORM($filter=FNCIREF1 eq 'IN*' ;$select=BALDATE,FNCNUM,IVNUM,FNCPATNAME,DETAILS,SUM1,FNCREF2,FNCIREF1)`
                
                let res = await PositiveTS.Service.PriorityService.request('get', endPoint)

                if (res && Array.isArray(res.data)) {
                    result.invoices = res.data
                }
                result.success = true
            }catch (error){
                console.error(error)
                result.error = i18next.t('generalError')
            }

            return result
        }

        export async function getTinvoices (params = {}) {
            let result = {success: false, tinvoices: [], error: ''}
            try {
                let endPoint = 'TINVOICES'
                if (posUtils.isPresent(params)){
                    endPoint += `?${posUtils.objectToUrlQueryParams(params)}`
                }
                
                let res = await PositiveTS.Service.PriorityService.request('get', endPoint)

                if (res && Array.isArray(res.data)) {
                    result.tinvoices = res.data
                }
                result.success = true
            }catch (error){
                console.error(error)
                result.error = i18next.t('generalError')
            }

            return result
        }

        export async function getEinvoices (params = {}) {
            let result = {success: false, einvoices: [], error: ''}
            try {
                let endPoint = 'EINVOICES'
                if (posUtils.isPresent(params)){
                    endPoint += `?${posUtils.objectToUrlQueryParams(params)}`
                }
                
                let res = await PositiveTS.Service.PriorityService.request('get', endPoint)

                if (res && Array.isArray(res.data)) {
                    result.einvoices = res.data
                }
                result.success = true
            }catch (error){
                console.error(error)
                result.error = i18next.t('generalError')
            }

            return result
        }

        async function addOrderItems (orderItems: Array<any>): Promise<{success: boolean, error: string}>{
            let result = {success: false, error: null}
            try{
                let generalItemDiscount = await PositiveTS.Storage.Entity.Discount.fetchByDiscountId(PositiveTS.Storage.Entity.Discount.TYPE_ITEM)
                let discounts: PriorityItemDiscount[] = []

                for (let line of orderItems){
                    let itemObj = PositiveTS.Service.AllItems.get(line.PARTNAME)

                    if (!itemObj){
                        result.error = i18next.t('priorityImportOrderDialog.errorNotFoundItemOnPos', {item: line.PARTNAME})  
                        await posVC.restartSale()
                        break
                    }
                    
                    let linePrice = session.fixedFloat(line.VATPRICE / line.TQUANT)
                    let rowNumber = posVC.getRowNumber()
                    if (itemObj.priceZarhan != linePrice){
                        let discountPrecent = 100 - ((linePrice / itemObj.priceZarhan) * 100)
                        let discount: PriorityItemDiscount = {
                            selectedDiscount: generalItemDiscount,
                            selectedDiscountPrecent: discountPrecent, 
                            saleItemRowNumber: rowNumber,
                            discountType: Number(PositiveTS.Storage.Entity.SaleItem.DiscountType.AMOUNT), 
                            discountValue: linePrice,
                        }

                        discounts.push(discount)
                    }
                    // itemObj.priceZarhan = linePrice // if has price before discount from priority put it here
                    let sizesColors = [{size:'null',color:'null',barcode: line.PARTNAME}]//priority not support matrix item
                    let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(itemObj, sizesColors[0]);
                    saleItem.quantity = line.TQUANT
                    saleItem.saleID = posVC.sale.id
                    saleItem.rowNumber = rowNumber
                    await posVC.persistNewSaleItem(saleItem)
                }

                if (!result.error && discounts.length > 0){
                    let res = await addOrderItemsDiscounts(discounts)
                    result.error = res.error
                }
            }catch(e){
                console.error(e)
                result.error = i18next.t('priorityImportOrderDialog.generalError')
            }

            if (!result.error){
                result.success = true
            }

            return result
        }

        async function addOrderItemsDiscounts (discounts: PriorityItemDiscount[]): Promise<{success: boolean, error: string}>{
            let result = {success: false, error: null}
            try {
                for (let discount of discounts) {
                    let saleItem = posVC.saleItems.find(si => si.rowNumber == discount.saleItemRowNumber)
                    posDiscountVC.addDiscountNewStructure(-1, discount.selectedDiscount, discount.selectedDiscountPrecent, [saleItem], discount.discountType, discount.discountValue)
                }
            }catch(e){
                console.error(e)
                result.error = i18next.t('priorityImportOrderDialog.errorCannotAddItemDiscount')
            }

            if (!result.error){
                result.success = true
            }

            return result
        }

        async function getOrder (order: string): Promise<any> {
            let orderObject = {}
            const endPoint = `ORDERS?$filter=ORDNAME eq '${order}'&$expand=ORDERITEMS_SUBFORM`
            let res =  await PositiveTS.Service.PriorityService.request('get', endPoint)
            if (res && !_.isEmpty(res.data)){
                orderObject = res.data[0]
            }
            
            return orderObject
        }

        function jsonParse (json: string) {
            try{
                return JSON.parse(json);
            }catch(e){
                return null;
            }
        }

        function getFullUrl (endPointUrl: string) {
            if (session.pos.priorityUserData){
                const pud = JSON.parse(session.pos.priorityUserData);
                let baseUrl = `https://${pud['url']}/odata/priority/${pud['application']}`
                if (session.pos.companyUsePriorityApi == prioritySimplyct){
                    baseUrl += `/${pud['environment']}`
                }
                
                if (endPointUrl  && endPointUrl [0] !== '/'){
                    endPointUrl  = `/${endPointUrl}`
                }
                return `${baseUrl}${endPointUrl}`;
            }

            throw Error('Not found Priority User Data in this pos');
        }

        function getHeaders () {
            if (session.pos.priorityUserData){
                const pud = JSON.parse(session.pos.priorityUserData);

                return {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Basic ' + btoa(`${pud['username']}:${pud['password']}`), 
                    'X-App-Id': `${pud['x_app_id']}`,
                    'X-App-Key': `${pud['x_app_key']}`,
                }
            }

            throw Error('Not found Priority User Data in this pos');
        }

    }

}}

