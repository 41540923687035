

module PositiveTS {
  export module UI {
    export function moduleSetUiDictionary(dictUi) {
      var internal:any = {};
      var external:any = {};
    
      external.dictUi = dictUi;
    
      internal.main = function() {
        for (let key in dictUi) {
          let dictControl = dictUi[key];
          switch (dictControl.type ) {
            case 'Input':
              dictControl.ctrl = new UiInput(key);
              if ("val" in dictControl) {
                dictControl.ctrl.setVal(dictControl.val);
              }
              break;
            default:
              throw new Error("No type " + dictControl.type);
          }
        }
      };
    
      external.setVal = function() {
        var dictUi = external.dictUi;
        for (var key in dictUi) {
          var dictControl = dictUi[key];
          if (dictControl.type == 'Input') {
            dictControl.val = dictControl.ctrl.getVal();
          } else if (dictControl.type == 'Date') {
            dictControl.val = dictControl.ctrl.getDate();
          } else if (dictControl.type == 'DateRange') {
            // Ignore for now
          } else if (dictControl.type == 'Radio') {
            //do nothing
          } else {
            // throw error
            throw ("No type " + dictControl.type);
          }
        }
      };
    
      internal.get_input_with_enterid = function(id) {
        var _return = "";
        var dictUi = external.dictUi;
        for (var key in dictUi) {
          if (dictUi[key].enter_id == id) {
            _return = key;
          }
        }
        return _return;
      };
    
      external.setDefaultIfEnterIdZero = function() {
        var dictUi = external.dictUi;
        for (var key in dictUi) {
          if (dictUi[key].enter_id == 0) {
            var _ikey = key;
            setTimeout(function() {
              $(_ikey).focus();
              $(_ikey).select();
            }, 500);
    
          }
        }
      };
    
      internal.jq_enter_binding = function() {
    
        var dictUi = external.dictUi;
    
        for (var key in dictUi) {
    
          if ("enter_function" in dictUi[key]) {
            (function(_key, _dictUi, _func) {
              $(key).unbind('keyup');
              $(key).keyup(function(e) {
                if (e.keyCode == 13) {
                  setTimeout(function() {
                    _func();
                  }, 100);
    
    
                }
              });
            })(key, dictUi, dictUi[key].enter_function);
          }
    
    
          if ("enter_next" in dictUi[key]) {
            (function(_key, _dictUi, _nextid) {
              $(key).unbind('keyup');
              $(key).keyup(function(e) {
                if (e.keyCode == 13) {
                  setTimeout(function() {
                    $(_nextid).focus();
                  }, 100);
    
    
                }
              });
            })(key, dictUi, internal.get_input_with_enterid(dictUi[key].enter_next));
    
          }
    
    
    
        }
      };
    
      external.enter_binding = function() {
        internal.jq_enter_binding();
      };
    
    
      internal.main();
      return external;
    }
  
    export class UiInput {
      private _strSelector:string;
      private _strSelectorContainer;
      constructor(strSelector) {
        this._strSelector = strSelector;
        var CONTAINER_TAIL = "-container";
        this._strSelectorContainer = strSelector + CONTAINER_TAIL;
        $(this._strSelectorContainer).inputField({
          keyboardOn: true,
          clearOn: true,
          disclosureOn: true
        });
      }
  
      getVal():string {
        return $(this._strSelector).val();
      };
  
      setVal(x:string) {
        return $(this._strSelector).val(x);
      };
    }
  }
  
}

