module PositiveTS {
export module Storage {
export module Entity {
export class CashStatementLog extends IDBEntity {

	constructor() {
		let meta = {
			name: 'CashStatementLog',
			internal: true,
			fields: {
				type: "TEXT",
				amount: "FLOAT",
				employee_id: "INT",
				statement_time: "INT",
				syncStatus: "INT",
				serverId: "INT",
				z_amount: "FLOAT",
				difference: "FLOAT",
				opening_amount: "FLOAT",
				cash_in_safe: "FLOAT",
				data: 'TEXT'
			},
			unique: []
		};
		super(meta)
		this.statement_time = Math.round(+new Date()/1000);
		this.syncStatus = Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
	}

	public type: string;
	public amount: number;
	public employee_id: string;
	public statement_time: number;
	public syncStatus: number;
	public serverId: number;
	public z_amount: number;
	public difference: number;
	public opening_amount: number;
	public cash_in_safe: number;
	public data: string;

	static async fetchLastOfType(type: string) {
		let items = await appDB.cashStatementLogs.where('type').equals(type).toArray();

		items.sort((a, b) => {
			if(a.statement_time > b.statement_time) return 1;
			if(a.statement_time < b.statement_time) return -1;
			return 0;
		});
		return items[items.length -1];
	}

	static async undoLastZ() {
		let lastZ = await this.fetchLastOfType(Shared.Constants.CashierStatement.TYPE_END_DAY);
		await appDB.cashStatementLogs.where('id').equals(lastZ.id).delete();
		return true;
	}

	static async setLastZToSend() {
		await appDB.cashStatementLogs.where('syncStatus').equals(Shared.Constants.CashierStatement.SYNC_STATUS_AWAITING_Z)
		.modify({syncStatus: Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT});
	}

	static async enterHasBeenDone() {
		let enter = await this.fetchLastOfType(Shared.Constants.CashierStatement.TYPE_START_DAY);
		let z = await this.fetchLastOfType(Shared.Constants.CashierStatement.TYPE_END_DAY);

		if(enter && z) {
			if(enter.statement_time > z.statement_time) {
				return true;
			}
		}
		else if(enter && !z) {
			return true;
		}

		return false;
	}

	static async getCashSumWithdrawnToSafe() {
		let lastEnter = await this.fetchLastOfType(Shared.Constants.CashierStatement.TYPE_START_DAY);
		let lastZ = await this.fetchLastOfType(Shared.Constants.CashierStatement.TYPE_END_DAY);

		if(!lastEnter || !lastZ || lastEnter.statement_time < lastZ.statement_time) {
			return ;
		}

		let items = await appDB.cashStatementLogs.where('statement_time').above(lastEnter.statement_time).toArray();
		let total = 0;
		items.forEach(item => {
			if(item.type == Shared.Constants.CashierStatement.TYPE_REMOVE_FROM_POS) {
				total += item.amount;
			}
		});

		return total;
	}
	
}}}}
