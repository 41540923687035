(function( $ ){
	var keyboardIconTemplate = '<li id="{id}-keyboard-icon" class="keyboard-icon fad fa-keyboard"></li>';
	var clearIconTemplate = '<li id="{id}-clear-icon" class="clear-icon fas fa-times-circle"></li>';
	var disclusreIconTemplate = '<img id="{id}-disclusre-icon" class="disclusre-icon" src="' + (<any>window).images_path + 'black_arrow.png" />';

	var methods = {
		init: function (options) {
			var defaultOptions = {
				keyboardOn: true,
				clearOn: true,
				disclosureOn: false,
				disabled: false,
				type: 'text',
				dateMask: '39/19/9999'
			};

			return this.each(function(){
				this.settings = $.extend(defaultOptions, options);

				var $this = $(this);
				var inputEl = $this.find('input');

				// Add style to input
				$this.addClass('input-field-container');
				inputEl.addClass('input-field');

				if (this.settings.disabled) {
					inputEl.prop('disabled', true);
				} else {
					inputEl.prop('disabled', false);
				}

				if (this.settings.clearOn && !this.settings.disabled) {
					// Add button for clearing field
					if ($this.find('#' + this.id + '-clear-icon').length === 0) {
						$this.append(clearIconTemplate.replace("{id}", this.id));
					}
					$this.find('#' + this.id + '-clear-icon').click(function () {
						inputEl.val('');
						inputEl.keyup();
					});
				} else {
					$this.find('#' + this.id + '-clear-icon').remove();
				}
				if (navigator.userAgent.toLowerCase().indexOf('android') > -1 && typeof(Android) !== "undefined") {
					return;
				}
				if (this.settings.keyboardOn && !this.settings.disabled) {
					// Add button for opening keyboard
					if ($this.find('#' + this.id + '-keyboard-icon').length === 0) {
						$this.append(keyboardIconTemplate.replace("{id}", this.id));
					}

					// Add keyboard support to input field
					if (inputEl.getkeyboard() === undefined) {
						var layout;
						if(inputEl.data("layout") != null){
							if(inputEl.data("layout") == "text"){
								layout = "hebrew-qwerty-custom"//"hebrew-qwerty-custom";
							} else if(inputEl.data("layout") == "numbers"){
								layout = "only-numbers";
							}
						} else {
							layout = "hebrew-qwerty-custom"//"hebrew-qwerty-custom";
						}

						var keyboardOptions = {
							layout      : layout,
							validate: function () {
								inputEl.keyup();
								return true;
							}
						}
						if (layout == 'only-numbers') {
          					keyboardOptions['css'] = { container: "ui-widget-content ui-widget ui-corner-all ui-helper-clearfix number-keys" }
        				}

						inputEl.keyboard(Object.assign(app.defaultKeyboardConfig,keyboardOptions))//.addTyping();
					}

					// Open the keyboard by clicking on the keyboard icon only
					$('#' + this.id + '-keyboard-icon').unbind('click');
					$('#' + this.id + '-keyboard-icon').click(function() {
						inputEl.getkeyboard().reveal();
						return false;
					});


				} else if (this.settings.disclosureOn && !this.settings.disabled) {
					// Add disclusre button
					if ($this.find('#' + this.id + '-disclusre-icon').length === 0) {
						$this.append(disclusreIconTemplate.replace("{id}", this.id));
					}
				} else {
					$this.find('#' + this.id + '-keyboard-icon').remove();
					$this.find('#' + this.id + '-disclusre-icon').remove();
				}

				if (this.settings.type === 'date') {
					var dateMask = this.settings.dateMask;
					inputEl.bind('change paste keyup', function (event) {
						// Ensure date format is preserved
						(<HTMLInputElement>event.target).value = $.mask.string((<HTMLInputElement>event.target).value, dateMask);
					});
				} else if (this.settings.type === 'int') {
					inputEl.bind('change paste keyup', function (event) {
						var intValue = parseInt((<any>event.target).value, 10);
						if (!isNaN(intValue)) {
							(<any>event.target).value = intValue;
						}
					});
				} else if (this.settings.type === 'float') {
					inputEl.bind('change paste keyup', function (event) {
						var floatValue = parseFloat((<any>event.target).value);
						if (!isNaN(floatValue)) {
							// If the last char is dot, don't omit it
							var suffix = '';
							if ((<any>event.target).value[(<any>event.target).value.length-1] === '.') {
								suffix = '.';
							}
							(<any>event.target).value = floatValue + suffix;
						}
					});
				}
			});
		},
		/**
		 * Focus on the input field
		 */
		focus: function () {
			$(this).find('input').focus();
		},
		/**
		 * Add onchange listener
		 */
		change: function (listener) {
			$(this).find('input').bind('change paste keyup', listener);
		},
		/**
		 * Add disclosure listener
		 */
		disclosureListener: function (listener) {
			$(this).find('input').bind('click', listener);
			$(this).find('.disclusre-icon').bind('click', listener);
		},
		/**
		 * Unbind all listener
		 */
		unbind: function () {
			$(this).find('input').unbind();
			$(this).find('.disclusre-icon').unbind();
		},
		/**
		 * Get the value of the input field
		 */
		getValue: function () {
			return $(this).find('input').val();
		},
		/**
		 * set the value of the input field
		 */
		setValue: function (value) {
			$(this).find('input').val(value);
			$(this).find('input').keyup();
		}
	};

	$.fn.inputField = function(method) {
		if (methods[method]) {
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof method === 'object' || ! method) {
			return methods.init.apply(this, arguments);
		} else {
			$.error('Method ' +  method + ' does not exist on inputField');
		}
	};
})( jQuery );
