module PositiveTS {
export module Components {
    export module HakafaInvoicesDialog {
      const dialogSelector = "hakafa-invoices-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          searchInput: "",
          loading: false,
          rejectFunc: null,
          resolveFunc: null,
          leftToPay: 0,
          invoicesPaid: 0,
          invoices: [],
          dialogElem: document.getElementById(dialogSelector),
          fields: initFields(),
        }
      }

      function initFields() {
        let fields = {
          'price': {

          }
        }
        return fields;
      }

      function checkInvoice(invoice){
        if(invoice.checked){
          invoice.checked = false;
          this.invoicesPaid--;
          invoice.current_tamash_amount_paid = 0
        }
        else{
          invoice.checked = true;
          this.invoicesPaid++;
          if (!invoice.current_tamash_amount_paid) {
            invoice.current_tamash_amount_paid = session.fixedFloat(invoice.amount - invoice.tamash_amount_paid, 2)
          }
        }
      }


      function close() {
        if (this.initialized) {
          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.close();
        }
        this.cleanData()
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      async function getInvoicesFromServer(fetchInvoicesFromPriority = false){
        let svcCustomer = new PositiveTS.Service.CustomerClub(posVC.sale, posVC.salePayments, posVC.saleItems);
        let customer = svcCustomer.getCurrentSelectedCustomer();
        let invoicesFromServer = null
        let invoices = [];

        if(fetchInvoicesFromPriority) {
          invoicesFromServer = await PositiveTS.Service.PriorityService.getCustomerObligoInvoices(customer.customer_number)
          let hasInvoices = invoicesFromServer.invoices[0]?.["OBLIGO_FNCITEMS_SUBFORM"]?.length > 0

          if(invoicesFromServer.success && hasInvoices) {

            let groupedInvoices = _.groupBy(invoicesFromServer.invoices[0]["OBLIGO_FNCITEMS_SUBFORM"], "FNCIREF1")

            for (const invoiceNumber in groupedInvoices) {
              let parentInvoiceIndex = groupedInvoices[invoiceNumber].findIndex(inv => inv["FNCIREF1"] == inv["IVNUM"])
              let parentInvoice =  groupedInvoices[invoiceNumber][parentInvoiceIndex]
              groupedInvoices[invoiceNumber].splice(parentInvoiceIndex, 1)

              let paidAmount = 0

              for (let index = 0; index < groupedInvoices[invoiceNumber].length; index++) {
              let paymentSum = groupedInvoices[invoiceNumber][index]["SUM1"]
                paidAmount += (paymentSum * -1)
              }
              
              let invoiceBalance = parentInvoice['SUM1'] - paidAmount
              let invoice = {
                id: null,
                checked: false,
                invoice_number: parentInvoice['IVNUM'],
                customer_name: customer.s_first_name,
                sold_at: parentInvoice['BALDATE'],
                amount: parentInvoice['SUM1'],
                tamash_bal: session.fixedFloat(invoiceBalance, 2),
                current_tamash_amount_paid: 0,
                tamash_amount_paid: paidAmount
              }

              invoices.push(invoice)
            }
          }
        } else {
          invoicesFromServer = await PositiveTS.Service.FetchReq.jsonReq(`/sales?hakafa_customer_id=${customer.db_id}&include_hakafa_children_sales=true`,"get");

          let result = invoicesFromServer.result;

          for(let invoice of result){
            if((invoice.tamash_amount_paid != invoice.amount) && (invoice.invoice_type == Storage.Entity.Sequence.TYPE_TAX_INV || invoice.invoice_type == Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV)){
              invoice.checked = false;
              invoice.current_tamash_amount_paid = 0;
              if(!invoice.tamash_amount_paid){
                invoice.tamash_amount_paid = 0;
              }
              invoices.push(invoice);
            }
          }
        }

        return invoices;
      }

      async function validateAmount(invoice, inputIndex){
        let {current_tamash_amount_paid, tamash_bal} = invoice
        let el = document.getElementById('invoice-total-input' + inputIndex)

        if(Number(current_tamash_amount_paid) > tamash_bal ){
          await this.showError(i18next.t('hakafaInvoicesDialog.amountIsHigherThanLeftToPay'))
          invoice.current_tamash_amount_paid = tamash_bal
          el.focus()
        } 
        
        if(Number(current_tamash_amount_paid) < 0 ){
          await this.showError(i18next.t('hakafaInvoicesDialog.amountCannotBeNegative'))
          invoice.current_tamash_amount_paid = tamash_bal
          el.focus()
        } 
      }

      function open() {
        return new Promise(async (resolve,reject) =>  {
          this.initialized = true;
          
          if(!PositiveTS.Reachability.isOnline){
            showError(i18next.t('hakafaInvoicesDialog.offlineMode'))
            return;
          }
          app.showLoadingMessage(i18next.t('hakafaInvoicesDialog.loadingInvoices'));

          let fetchInvoicesFromPriority = Service.PriorityService.payHakafaCustomerInvoicesFromPriority()
          this.invoices = await getInvoicesFromServer(fetchInvoicesFromPriority);

          app.hideLoadingMessage();

          $(document).unbind('keypress');

          if (this.dialogElem == null) {
            this.dialogElem = document.getElementById(dialogSelector)
          }
          this.dialogElem.showModal();
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
        })
      }

      function showError(errStr) {
        app.showAlertDialog({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        })
      }

      function confirmAndClose() {
        let invoices = _.cloneDeep(this.invoices)
        let selectedInvoices = []

        for (const invoice of invoices) {
          if(!invoice.checked){
                continue
          }
          invoice.tamash_amount_paid = Number(invoice.current_tamash_amount_paid)

          selectedInvoices.push({id: invoice.id, tamash_amount_paid: invoice.tamash_amount_paid, invoice_number:invoice.invoice_number})
        }

        if(selectedInvoices.length == 0){
          showError(i18next.t('hakafaInvoicesDialog.noInvoicesChecked'));
          return;
        }

        let jd = JSON.parse(posVC.sale.jsondata);
        jd.hakafaClosedInvoices = selectedInvoices;
        posVC.sale.jsondata = JSON.stringify(jd);
        posVC.sale.persist();
        this.resolveFunc(this.amount);
        this.close();
        return;
    }

    function soldAtStr(soldAt) {
      if (!soldAt) {
        return "";
      }

      let date = moment(soldAt)
      return `${date.format("DD/MM/YYYY")}`
    }


      export function create() {

        let component = {
          template: JST.hakafaInvoicesDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData: cleanData,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            showError: showError,
            soldAtStr:soldAtStr,
            checkInvoice: checkInvoice ,
            validateAmount: validateAmount
          },
          data: initData,

          computed: {
            amount() {
              let amount = 0
              for (const invoice of this.invoices) {
                if(invoice.checked){
                  amount += Number(invoice.current_tamash_amount_paid)
                }
              }
              return amount
            },
          },
        }

        VueApp.component('hakafa-invoices-dialog',component)

      }
    }
  }
}
