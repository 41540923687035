module PositiveTS {
	
	export module Service {

		export module Currencies {

			//1 hour = 3600000 || 10 min = 600000
			export const fetchCurrenciesInterval = _.debounce(async () => {
				await fetchCurrencies()
				fetchCurrenciesInterval()
			}, 3600000)

			async function fetchElalCurrencies() {
				let isUpdated = false;
				if (Pinia.globalStore.isOnline) {
					try {
						const result =  await getRatesFromElal();
									
						await saveCurrenciesToDB(result);

						isUpdated = true;
					} catch (err) {
						Service.Logger.error(err);
					}
				}
				await fetchExternalCurrenciesFromDB();
				return isUpdated;
			} 

			export async function fetchCurrencies() {
				if (session && session.pos && session.pos.hasFlights) {
					await fetchExternalCurrenciesFromDB();
					return;
				}
				let isUpdated = false;
				if (Pinia.elalStore.isOn) {
					return await fetchElalCurrencies();
				}

				if (Pinia.globalStore.isOnline) {
					try {
						const result = await getRatesFromBankOfIsrael()		
						await saveCurrenciesToDB(result);

						isUpdated = true;
					} catch (err) {
						Service.Logger.error(err);
					}
				}
				await fetchPosCurrenciesFromDb();

				return isUpdated;
			}

			export async function showCurrenciesNotUpdatedMessage() {
				let dbCurrenciesCount = await appDB.currencies.count();

				if (dbCurrenciesCount == 0) {
					do {
						app.hideLoadingMessage();
						await app.promiseShowAlert({
							header: i18next.t('currenciesMessages.notUpdatedTitle'),
							content: i18next.t(Pinia.elalStore.isOn?'currenciesMessages.noCurrenciesAtDbElal':'currenciesMessages.noCurrenciesAtDb'),
							continueButtonText: i18next.t("currenciesMessages.updateNow"),
							hideCancelButton: true})

							app.showLoadingMessage();
					} while(!await fetchCurrencies());
					app.hideLoadingMessage();
				} else {
					let keepTrying = true;

					while (keepTrying) {
						let res = await app.promiseShowAlert({
							header: i18next.t('currenciesMessages.notUpdatedTitle'),
							content: i18next.t('currenciesMessages.currenciesNotUpdated'),
							continueButtonText: i18next.t("currenciesMessages.updateNow"),
							cancelButtonText: i18next.t("currenciesMessages.useOldRates")});

						if (res == "cancel") {
							keepTrying = false;
						} else {
							app.showLoadingMessage();
							keepTrying = !await fetchCurrencies();
							app.hideLoadingMessage();
						}
					}
				}
			}



			// This method is for jr and elal only
			export async function fetchExternalCurrenciesFromDB() {
				posPaymentVC.Currencies = {}
				const baseCurrencyPos = Service.MultiCurr.getInstance().getPosCurrency()
				let currencies = await appDB.currencies.toArray();

				if (currencies){
					let baseRate = 1

					currencies.forEach(curr => {
						if (curr.currencycode == baseCurrencyPos){
							baseRate = curr.rate
						}
					})

					currencies.forEach(curr => {
							posPaymentVC.Currencies[curr.currencycode] = (curr.rate / curr.unit) / baseRate;
					})
				}
			}

			export async function getLastCurrenciesUpdatedAt(){
				try{
					let firstCurrency = await appDB.currencies.orderBy('currencycode').first();
					return firstCurrency?.last_update;
				}catch(err){
					console.log(err);
					return null;
				}
			}

			async function getRatesFromElal(){
				try{
					const res = await FetchReq.jsonReq(`${Shared.Constants.remoteRoot}elal_currencies`, 'GET') 
					return parseResultToTableObjects(res?.result?.result);
				} catch (err) {
					await showCurrenciesNotUpdatedMessage()
				}
			}

			async function getRatesFromBankOfIsrael() {
				const data = '{"body":"","type":"GET","url":"https://boi.org.il/PublicApi/GetExchangeRates", "soap": true}'

				if(session.isAndroid && (<any>window).Android != undefined) {
					var res = await PositiveTS.Service.AndroidAddon.sendNativeMessageToExtension({ data: data },
						"external_service")
				}else{
					try {
						var res = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data, action: "HTTP_ROUTER" },
						"http_router")

						if (res && res.request && res.request.result && res.request.result.includes('ERROR')){
							res = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data },
								"external_service")
						}
					} catch(err) {
						var res = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({ data: data },
							"external_service")
					}
				}
						
				console.log('getRatesFromBankOfIsrael res', res)
				return parseResultToTableObjects(res.request.result);
			}

			function parseResultToTableObjects (result) {
				const last_run = new Date().toJSON();

				result = JSON.parse(result);
				let tableObjects = result.exchangeRates.map((row) => {
					return {
						name: '',//not get this value from boi
						unit: 1,//not get this value from boi
						currencycode: row.key,
						country: '',//not get this value from boi
						rate: row.currentExchangeRate,
						change: row.currentChange,
						last_run: last_run,
						last_update: row.lastUpdate,
					}
				});

				return {table: tableObjects};
			}

			function addXmlRows (xml, _return) {

				const last_run = new Date().toJSON()
				const last_update =  $(xml).find("LAST_UPDATE").text()

				_return.last_update =  last_update
				_return.last_run = last_run

				$(xml).find("CURRENCY").each(function(obj, ref){
					let _row :any = {}
					_row.name = $(ref).find("name").text()
					_row.unit = Number($(ref).find("unit").text())
					_row.currencycode = $(ref).find("currencycode").text()
					_row.country = $(ref).find("country").text()
					_row.rate = Number($(ref).find("rate").text())
					_row.change = $(ref).find("change").text()
					_row.last_run = last_run
					_row.last_update = last_update
					_return.table.push(_row)
				})
			}

			function parseXml (xml) {
				var _return :any = {};
				_return.table = [];
				xml = removeHeadRow(xml);
				addXmlRows(xml, _return);
				return _return;
			}

			function removeHeadRow (str) {
				// break the textblock into an array of lines
				var lines = str.split('\n');
				// remove one line, starting at the first position
				lines.splice(0,1);
				// join the array back into a single string
				return ( lines.join('\n') );
			}

			async function saveCurrenciesToDB (data) {
				if (data && Array.isArray(data.table) && data.table.length){
					await appDB.currencies.bulkPut(data.table)
				}
				console.log('saveCurrenciesToDB data:', data)
			}


			async function fetchPosCurrenciesFromDb () {

				posPaymentVC.Currencies = {}
				const baseCurrencyPos = Service.MultiCurr.getInstance().getPosCurrency()
				let currencies = await appDB.currencies.toArray();

				if (currencies && currencies.length > 0){

					posPaymentVC.LastUpdateCurrencies = currencies[0].last_run ? currencies[0].last_run : ''
					const last_run = (new Date()).toJSON();
					let ILS = {
						name: 'Shekel',
						unit: 1,
						currencycode: 'ILS',
						country: 'Israel',
						rate: 1,
						last_run: last_run,
						last_update: last_run,
						change: ''
					}

					let ils_cur = new Storage.Entity.Currencies()
					ils_cur.importFromObject(ILS)
					
					currencies.push(ils_cur)
					let baseRate = 1

					//if not NIS need to set the base currency rate is 1 for division rates
					if (baseCurrencyPos != 'ILS'){
						currencies.forEach(curr => {
							if (curr.currencycode == baseCurrencyPos){
								baseRate = curr.rate
							}
						})
					}

					currencies.forEach(curr => {
						if (curr.currencycode != baseCurrencyPos){
							posPaymentVC.Currencies[curr.currencycode] = curr.currencycode != 'ILS' ? 
							baseRate / (curr.rate / curr.unit) : baseRate
						}
					})

					posPaymentVC.Currencies[baseCurrencyPos] = 1
					if (Service.CustomerDisplay.isSunmiScreen()){
						Service.CustomerDisplay.updateCurrenciesRate();
					}
				}
							
			}

			export async function updatePosPaymentCurrency(code){
				Pinia.globalStore.setPaymentCurrency(code);
				if (Service.CustomerDisplay.isSunmiScreen()){
					Service.CustomerDisplay.updatePosPaymentCurrency(code);
				}
			}

		}
		
	}

}


