module PositiveTS {
export module Storage {
export module Entity {
export class PaymentType extends WasmEntity {

	seq:string
	name:string
	isInXZ:boolean
	displayName:string
	isInSale:boolean

	static dbCache:Array<PaymentType> = null


	constructor() {
		let meta = {
			name: 'PaymentType',
			fields: {
				seq: "TEXT",
				name: "TEXT",
				displayName: "TEXT",
				isInXZ: "BOOL",
				isInSale: "BOOL"
			},
			unique: ['seq']
		};
		super(meta)
	}

	getCache() {
		return super.getCache<PaymentType>("dbCache");
	}

	getCalcuatedPaymentTypesObject () {
		
		return new Promise((resolve,reject) => {
			this.getCache()
			.then((payments) => {

				if (payments == null || payments.length < 1) {
					return reject();
				}

				let returnPayments = {};

				for (let i in payments) {
					returnPayments[payments[i].seq] = payments[i].exportToObject();
					returnPayments[payments[i].seq].displayName = i18next.t(`paymentTypes.${payments[i].seq}`)
				}

				resolve(returnPayments);

			}).catch((error) => {
				reject(error);
			});	
		})
	}
}}}}
