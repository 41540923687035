module PositiveTS {
export module Components {
export module PositiveRadio {

  export class Btn {
    txt:string;
    id: string;
    isSelected:boolean;

    constructor (txt:string, id: string, isSelected:boolean){
        this.txt = txt;
        this.id = id
        this.isSelected = isSelected;
    }
  }

  function _initData(){
    var btns:Btn[] = []
    return {
      btns: btns //btns: Btn[]
    }
  }


  function _setSelected(btn:Btn){
    for (var i=0;i<this.btns.length;i++){
      this.btns[i].isSelected = false;
    }
    btn.isSelected = true;
    this.$emit('clicked-item')

  }

  function _getSelected():Btn{
    var selected:Btn = undefined;
    for (var i=0;i<this.btns.length;i++){
      if(this.btns[i].isSelected){
        selected = this.btns[i];
      }
    }
    return selected;
  }
  export function create() {
    let obj = {
        template: JST.positiveRadio(),
        methods: {
          setSelected: _setSelected,
          getSelected: _getSelected,
        },
        data: _initData

    }

    VueApp.component('positive-radio',obj)

  }
}}}
