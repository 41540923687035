module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header class="header-component" :class="mobileLayout ? \'\' : \'desktop\'">\n	<div v-if="rightIcon" class="aside-icon right">\n		<div class="aside-icon-wrapper" @click="clickRight">\n			<font-awesome-icon :icon="rightIcon"></font-awesome-icon>\n		</div>\n		<template v-if="showDeliveries">\n			<div class="deliveries-icon" @click="openDeliveries">\n				<span v-if="deliveryOrdersCount > 0">{{ deliveryOrdersCount }}</span>\n				<positive-icon name="deliveryBadge"></positive-icon>\n			</div>\n		</template>\n		<template v-if="showTASitting">\n			<div class="sitting-ta-icon" @click="openTASittingDialog">\n				<positive-icon name="takeawayMobile"></positive-icon>\n			</div>\n		</template>\n	</div>\n\n	<template v-else>\n		' +
((__t = ( JST.HeaderRightInfo() )) == null ? '' : __t) +
'\n	</template>\n	<div class="middle">\n		<div v-if="isContentHtml" v-html="middleContent"></div>\n		<div v-else>{{ middleContent }}</div>\n	</div>\n	<div class="aside-icon left" @click="clickLeft">\n		<div class="aside-icon-wrapper" v-if="leftIcon">\n			<font-awesome-icon :icon="leftIcon"></font-awesome-icon>\n		</div>\n	</div>\n</header>\n';

}
return __p
}