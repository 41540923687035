module PositiveTS {
  export module Components {
    export module MultipassPoliceChoosePoints {

      const _dialogSelector = "positive-multipass-police-choose-points";
      const _vueComponentName = 'multipass-police-choose-points';

      export function create() {
        let loginDialog = {
          template: JST.multipassPoliceChoosePoints(),
          methods: {
            async initialLoad(customer) {
              if (PositiveTS.Service.MultipassPolice.disablePromos()) {
                await PositiveTS.Helper.SaleHelper.togglePromotions(true);
              }
              this.points = customer.points;
              this.cardNumber = customer.card;
              this.customerName = customer.s_first_name;
              if (!posUtils.isNullOrUndefinedOrEmptyString(customer.companyId)) {
                this.companyId = customer.companyId
              }
              if (!posUtils.isNullOrUndefinedOrEmptyString(customer.pinCode)) {
                this.pinCode = customer.pinCode;
              }
              this.resetPointsToOptimal();
              this.groupItemByWallets = PositiveTS.Service.MultipassPolice.groupItemsByWallets(posVC.saleItems, this.companyId);
            },
            open(givenCustomer) {
              app.showLoadingMessage(i18next.t('multipassPolice.searching'));
              let customer = null;
              if (!Service.MultipassPolice.saleHashMultipassPoliceCustomer(posVC.sale) && givenCustomer == null) {
                app.hideLoadingMessage();
                return;
              }

              if(givenCustomer){
                customer = givenCustomer
              }
              else{
                let saleJson = JSON.parse(posVC.sale.jsondata);
                customer = saleJson.customer
              }             
              
              app.hideLoadingMessage()
              this.element = <HTMLDialogElement>window.document.getElementById(_dialogSelector);
              this.element.show();
              return new Promise(async (resolve) => {
                await this.initialLoad(customer)
                this.resolveFunc = resolve;
                $(document).unbind('keypress');
                app.hideLoadingMessage();
              });
            },
            getRemainingPayment() {
              let totalLeftToPay: number = posVC.getTotalLeftToPay();
              let pointsToUse = this.pointsToUse
              if (posUtils.isPresent(pointsToUse)) {
                let currentDiscount = Number(this.currentDiscount());
                let virtualPointsType = jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceVirtualPointNumber);
                let total = 0;
                for (let type in pointsToUse) {
                  if (type == virtualPointsType) {
                    continue;
                  }
                  total += Number(pointsToUse[type]);
                }
                let totalWithDiscount = Number(total + currentDiscount).toFixed(2)           
                let remains = totalLeftToPay - Number(totalWithDiscount);
                return remains;
              } else if (posVC.saleItems.length > 0) {
                console.log("remaining: ",posVC.getTotalLeftToPay());
                return posVC.getTotalLeftToPay();
              } else {
                return 0;
              }
            },          
            addClassificationToPoints(points) {
              let totalPaidInSporshiyut = 0
              let totalItemsAmountS = 0
              let totalItemsAmountA = 0
              let pointsAfterClassification = []
              for (let point of points) {
                if (point.Type == Service.MultipassPolice.caveretPointsTypes.personal) {
                  point.Classification = "A"
                  pointsAfterClassification.push(point)
                }
                else if (point.Type == Service.MultipassPolice.caveretPointsTypes.sport) {
                  point.Classification = "S"
                  pointsAfterClassification.push(point)
                }
                else if (point.Type == Service.MultipassPolice.caveretPointsTypes.sportPersonal) {
                  totalPaidInSporshiyut += Number(point.Amount)
                  for (let item of posVC.saleItems) {
                    if(!posUtils.isNullOrUndefined(item.item.wallets)){
                      let walletAndClass = Service.MultipassPolice.getWalletsArrayAndClassification(item, this.companyId)
                      let hasRelevantWallt = false
                      for (let wallet of walletAndClass.wallets) {
                        if (wallet == Service.MultipassPolice.caveretPointsTypes.sportPersonal) {
                          hasRelevantWallt = true
                        }
                      }
                      if (hasRelevantWallt) {
                        if (walletAndClass.classification == "S") {
                          totalItemsAmountS += (item.unitPrice * item.quantity)
                        } else {
                          totalItemsAmountA += (item.unitPrice * item.quantity)
                        }
                      }
                    }
                  }
                  
                  const totalItemsAmountSAndA = totalItemsAmountA + totalItemsAmountS

                  if (totalItemsAmountSAndA == 0 || totalPaidInSporshiyut == 0) {
                    pointsAfterClassification.push({
                      Amount: String(totalPaidInSporshiyut),
                      Type: Service.MultipassPolice.caveretPointsTypes.sportPersonal,
                      Classification: "A"
                    });
                  }
                  else {
                    const aPercentage = totalItemsAmountA / totalItemsAmountSAndA
                    let aPointsAmount = Math.floor(Number(totalPaidInSporshiyut) * aPercentage)
                    let sPointsAmount = Number(totalPaidInSporshiyut) - aPointsAmount

                    pointsAfterClassification.push({
                      Amount: String(aPointsAmount),
                      Type: Service.MultipassPolice.caveretPointsTypes.sportPersonal,
                      Classification: "A"
                    });

                    pointsAfterClassification.push({
                      Amount: String(sPointsAmount),
                      Type: Service.MultipassPolice.caveretPointsTypes.sportPersonal,
                      Classification: "S"
                    });
                  }
                }
              }
              pointsAfterClassification = pointsAfterClassification.filter(point => point.Amount != "0");
              // Add kaspar points (i.e. Vritual points used for discounts) with the appropriate classification
              if (points.some(p => p.Type === Service.MultipassPolice.caveretPointsTypes.kaspar && Number(p.Amount) > 0)) {
                let point = points.find(p => p.Type === Service.MultipassPolice.caveretPointsTypes.kaspar);
                if (pointsAfterClassification.length > 0) {
                  point.Classification = pointsAfterClassification[0].Classification;
                } else if(posUtils.isPresent(posVC.saleItems) && posVC.saleItems.some(si => posUtils.isPresent(si.item.wallets))) {
                  for (let item of posVC.saleItems) {
                    if (posUtils.isBlank(item.item.wallets)) {
                      continue;
                    }
                    let walletAndClass = Service.MultipassPolice.getWalletsArrayAndClassification(item, this.companyId);
                    if (posUtils.isPresent(walletAndClass?.classification)) {
                      point.Classification = walletAndClass.classification;
                      break;
                    }
                  }
                } else { // מקרה קיצון שיכול לקראות רק אם העולם יקרוס
                  console.warn("No items and no payments have a classification")
                  point.Classification = "A";
                }
                pointsAfterClassification.push(point);
              }
              return pointsAfterClassification.filter(point => point.Amount != "0")
            },
            validateDiscountsDiffItemsAndTotalAndFix(diff){
              let saleItems = posVC.saleItems;
                for (let saleItem of saleItems) {
                  if(saleItem.multipassDiscount && saleItem.multipassDiscount > 0){
                    saleItem.multipassDiscount+=diff;
                    break;
                  }
                }
            },
            totalAmountUsedPointsInSale() {
              let saleItems = posVC.saleItems;
              let pointsToUse = this.pointsToUse;
              let totalAmount = 0;
              for (let saleItem of saleItems) {
                for (let pointType in pointsToUse) {
                  if(pointsToUse[pointType] && pointsToUse[pointType] > 0){
                      let usedSaleItemAmount = Service.MultipassPolice.totalPriceforAllMPItems(this.companyId,[saleItem],pointType)
                      if(usedSaleItemAmount >0){
                        totalAmount += usedSaleItemAmount;
                        break;
                      }
                  }
                }
              }
              return totalAmount;
            },
            async calculateSaleItemsUsedPoints() {
              let saleItems = posVC.saleItems;
              let pointsToUse = this.pointsToUse;
              let totalDiscounts = 0;
              let totalAmountUsedPointsInSale = this.totalAmountUsedPointsInSale();
              
              let discountAmount = this.currentDiscount();
              let discountPercent = Number(discountAmount / totalAmountUsedPointsInSale);

              for (let saleItem of saleItems) {
                let result = PositiveTS.Service.MultipassPolice.getWalletsArrayAndClassification(saleItem, this.companyId);
                let wallets = saleItem.item.wallets
                let nextSaleItem = false;
                if (posUtils.isBlank(wallets)) {
                  continue;
                }
                for (let wallet of result.wallets) {
                  if(nextSaleItem){
                    break;
                  }
                  for (let pointType in pointsToUse) {
                    if(wallet ==pointType && pointsToUse[pointType] > 0){
                      let totalFixedPriceDiscount = saleItem.priceNetoAfterDiscounts * discountPercent;
                      if(totalFixedPriceDiscount > 0){
                        if(!saleItem.multipassDiscount){
                          saleItem.multipassDiscount = 0
                        }
                        saleItem.multipassDiscount = Number(totalFixedPriceDiscount.toFixed(2));
                        totalDiscounts+=totalFixedPriceDiscount;
                        nextSaleItem = true;
                        break;
                      }
                    }
                  }
                }
              }

              let multipassComputedDiscount = this.currentDiscount();
              totalDiscounts = Number(totalDiscounts.toFixed(2));
              let remainingDiff = Number((multipassComputedDiscount - totalDiscounts).toFixed(2));
              if(remainingDiff != 0){
                this.validateDiscountsDiffItemsAndTotalAndFix(remainingDiff)
              }

            },           
            async acceptPoints() {
              if(PositiveTS.Service.MultipassPolice.isWorkingWithIDF()){
                posPaymentVC.init()
              }
              app.showLoadingMessage(i18next.t('multipassPolice.commitingTransaction'))
              let virtualPointsType = jsonConfig.getVal(jsonConfig.KEYS.multipassPoliceVirtualPointNumber);
              let totalUsedPoints = 0;
              let pointsToUse = this.pointsToUse
              let currentDiscount = Number(this.currentDiscount());
              let remainingPayment = this.getRemainingPayment();
              let formatRemainingPayment = Math.round(Math.abs(remainingPayment) * 100) / 100;
              let secondDecimalDigitOfRemainingPayment = posUtils.getSecondDecimal(formatRemainingPayment);
              for (let pointType in pointsToUse) {
                totalUsedPoints += Number(pointsToUse[pointType]);
                let maxPointsAllowed = Service.MultipassPolice.getMaxAllowedPointForWallet(pointType, this.companyId)
                if (pointsToUse[pointType] > maxPointsAllowed) {
                  app.hideLoadingMessage()
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('multipassPolice.tooManyPointsInWallet', {wallet: i18next.t(`multipassPolice.pointTypes.${pointType}`), max: maxPointsAllowed}),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                  return;
                }
              }

              if (Number(this.maxDiscount()) < Number(currentDiscount)) {
                app.hideLoadingMessage()
                app.showAlert({
                  header: i18next.t('error'),
                  content: i18next.t('multipassPolice.tooManyPointsError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
                return;
              }
              if (remainingPayment < 0 && posUtils.isNumberBetween(formatRemainingPayment, 0.01, 0.04) == false) {
                app.hideLoadingMessage()
                app.showAlert({
                  header: i18next.t('error'),
                  content: i18next.t('multipassPolice.tooManyPointsError'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
                return;
              }

              let points = new Array()
              for (let pointType in pointsToUse) {
                let amount = Math.floor(Number(pointsToUse[pointType]) * 100)
                if (amount > 0) {
                  points.push({
                    Amount: String(amount),
                    Type: pointType
                  });
                }
              }

              if (PositiveTS.Service.MultipassPolice.isWorkingWithIDF()) {
                points = this.addClassificationToPoints(points)
              }

              let usedPointsWithoutVirtual = totalUsedPoints;
              let virtualPoints = Number(pointsToUse[virtualPointsType] || 0);
              if (posUtils.isPresent(pointsToUse[virtualPointsType]) && virtualPoints > 0) {
                usedPointsWithoutVirtual = totalUsedPoints - virtualPoints;
              }
              if (totalUsedPoints == 0) {
                app.hideLoadingMessage();
                await this.close();
                return;
              }
              let paymentResult = await PositiveTS.Service.VoucherPayment.payBySmartVoucherTypeEx(PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_MULTIPASS_POLICE, usedPointsWithoutVirtual, this.cardNumber, this.pinCode, {points:points,cardCompanyId:this.companyId,pinCode: this.pinCode, customerName: this.customerName});
              if (paymentResult.success) {
                let multipassComputedDiscount = this.currentDiscount();
                if(multipassComputedDiscount > 0) {
                  if (secondDecimalDigitOfRemainingPayment > 0 && posUtils.isNumberBetween(formatRemainingPayment, 0.01, 0.04)) {
                    if (remainingPayment > 0) {
                      multipassComputedDiscount += formatRemainingPayment;  
                    } else {
                      multipassComputedDiscount -= formatRemainingPayment;  
                    }
                  } 
                  let args: PositiveTS.Types.CreateNewSaleItemFromGenernicItemOptionalArgs = { 
                    preparationInstructions: [Service.MultipassPolice.POS_DISCOUNT],
                    dontThrowErrorOnItemNotFound: session.pos.isCaveret
                  };
                  await this.calculateSaleItemsUsedPoints()
                  await saleItemHelper.createNewSaleItemFromGenernicItem(i18next.t("multipassPolice.discountName"), multipassComputedDiscount, true, -1, args);
                }
                posVC.saleUpdated();
                if (PositiveTS.Service.MultipassPolice.isWorkingWithIDF()){
                  let paymentMethods = PositiveTS.VueInstance.$refs.posPaymentDialog.paymentMethods
                  for(let paymentMethod in paymentMethods){
                    if(paymentMethods[paymentMethod].visible == true){
                      PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod(paymentMethod)
                    }
                  }
                }

                await this.close();
              } else{
                app.showAlert({
                  header: i18next.t('error'),
                  content: paymentResult.error,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
              }
              app.hideLoadingMessage();
            },
            resetPointsToOptimal() {
              let args: PositiveTS.Types.MutlipassCalcParams = { 
                companyId: this.companyId, 
                saleItems: posVC.saleItems, 
                currentUsedPoints: this.pointsToUse, 
                pointsAvailable: this.pointsAvailable };
              let points = PositiveTS.Service.MultipassPolice.getBestPointsUsage(args);
              let types = Object.keys(points)
              for (const type of types) {
                if(type == '14' && this.isPointsNotAllowed()){
                  this.enterPointInput(type, 0);  
                } else {
                  this.enterPointInput(type, points[type]);  
                }
              }              
            },
            getMaxPoints(type: string) {
              let args: PositiveTS.Types.MutlipassCalcParams = { 
                companyId: this.companyId, 
                saleItems: posVC.saleItems, 
                currentUsedPoints: this.pointsToUse, 
                pointsAvailable: this.points };
              let maxPoints = Service.MultipassPolice.addRestOfThePointsFromWallet(type, args)
              if(type == '14' && this.isPointsNotAllowed()){

                app.showAlert({ 
                  header: i18next.t('error'),
                  content:i18next.t('multipassPolice.canotUseCasparPoints') ,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                }, null, null);
                
                this.enterPointInput(type, 0);  
              } else {
                this.enterPointInput(type, maxPoints);
              }
              
            },
            maxDiscount() {
              return PositiveTS.Service.MultipassPolice.getMaxDicountAUserCanGet(this.companyId, posVC.saleItems);
            },
            currentDiscount() {
              let totalUsedPoints = 0;
              let pointsToUse = this.pointsToUse
              for (let pointType in pointsToUse) {
                totalUsedPoints += Number(pointsToUse[pointType]);
              }
              let discount = PositiveTS.Service.MultipassPolice.discountPercent() / 100;
              let cleanResult = (totalUsedPoints / (1 - discount)) * discount;

              let result = Math.ceil(cleanResult * 100) / 100;
              return result;
            },
            onInputPoints(pointType: string) {
              let value = this.pointsToUse[pointType];
              if(pointType == '14' && this.isPointsNotAllowed()){
                value = 0;
              }
              this.enterPointInput(pointType, value);
            },
            enterPointInput(pointType: string, value) {
              this.pointsToUse[pointType] = posUtils.ensureDecimalPointsOn2DigitsOnly(value);
            },
            toggleItemDetails(pointType: string) {
              if (this.displayItemsForWalletIds.includes(pointType)) {
                this.displayItemsForWalletIds = this.displayItemsForWalletIds.filter(p => p != pointType);
              } else {
                this.displayItemsForWalletIds.push(pointType);
              }
            },
            isPointsNotAllowed(){
              return session.pos.isCaveret && this.isBlockedCasperPoints();
            },
            isPointInputDisabled(point){
              return  this.isPointsNotAllowed() && point.type == '14' ;
            },
            isBlockedCasperPoints(){
              let salePayments = posVC.salePayments;
              let isBlocked = false;
              let allowedPaymentMethods = [PositShared.SalePaymentMethod.CREDIT, PositShared.SalePaymentMethod.CASH];
              for (let payment of salePayments) {
                if (!allowedPaymentMethods.includes(payment.method)) {
                  if(payment.amount>0){
                    isBlocked = true;
                    break;
                  }
                }
              }
              return isBlocked;
            },
            close: close,
          },
          computed: {
            pointsAvailable() {
                let points: PositiveTS.Types.WalletPoints[] = this.points || [];
                return points;                  
            },
            getRemainingPaymentStr() {
              return session.fixedNumber(this.getRemainingPayment().toFixed(2));
            },
            currentDiscountStr() {
              return session.fixedNumber(this.currentDiscount());
            },
            maxDiscountStr() {
              return session.fixedNumber(this.maxDiscount());
            }
          },
          data: _initData,
        }
        VueApp.component(_vueComponentName, loginDialog)
      }



      function _initData() {
        let multiCurr = Service.MultiCurr.getInstance();
        return {
          points: null,
          element: null,
          customerName: null,
          pointsToUse: {},
          cardNumber: null,
          pinCode: null,
          companyId: null,
          resolveFunc: null,
          displayItemsForWalletIds: [],
          groupItemByWallets: {},          
          currencySign: multiCurr.getPosCurrencySign(),
        };
      }

      async function close(resolveResult = 'continue') {
        if (PositiveTS.Service.MultipassPolice.disablePromos() && resolveResult !== 'continue') {
          await PositiveTS.Helper.SaleHelper.togglePromotions(false);
        }
        this.resolveFunc(resolveResult);
        this.element.close();
        this.resolveFunc = null;
        this.points = null;
        this.element = null;
        this.customerName = null;
        this.pointsToUse = {};
        this.groupItemByWallets = {};
        this.displayItemsForWalletIds = [];
        this.cardNumber = null;
        this.pinCode = null;
        this.companyId = null;
        $(document).keypress(posVC.onKeyPress);
      }
    }
  }
}