module WebSocketRails {
/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 HTTP Interface for the WebSocketRails client.
*/
  export class HttpConnection extends AbstractConnection {

    connection_type:string
    private _url:any
    last_pos:number

    _httpFactories() { return [
      () => new XMLHttpRequest(),
      () => new ActiveXObject("Msxml2.XMLHTTP"),
      () => new ActiveXObject("Msxml3.XMLHTTP"),
      () => new ActiveXObject("Microsoft.XMLHTTP")
    ]; }

    constructor(url, dispatcher) {
      {
        // Hack: trick Babel/TypeScript into allowing this before super.
        super();
        let thisFn = (() => { this; }).toString();
        let thisName = thisFn.slice(thisFn.indexOf('{') + 1, thisFn.indexOf(';')).trim();
        eval(`${thisName} = this;`);
      }
      this.dispatcher = dispatcher;
      super(...arguments);
      this.connection_type = 'http';
      this._url          = `http://${url}`;
      this._conn         = this._createXMLHttpObject();
      this.last_pos      = 0;
      try {
        this._conn.onreadystatechange = () => this._parse_stream();
        this._conn.addEventListener("load", this.on_close, false);
      } catch (e) {
        this._conn.onprogress = () => this._parse_stream();
        this._conn.onload = this.on_close;
        // set this as 3 always for parse_stream as the object does not have this property at all
        this._conn.readyState = 3;
      }
      this._conn.open("GET", this._url, true);
      this._conn.send();
    }

    close() {
      return this._conn.abort();
    }

    send_event(event) {
      super.send_event(...arguments);
      return this._post_data(event.serialize());
    }

    _post_data(payload) {
      return $.ajax(this._url, {
        type: 'POST',
        data: {
          client_id: this.connection_id,
          data: payload
        },
        success() {}
      }
      );
    }

    _createXMLHttpObject() {
      let xmlhttp   = false;
      const factories = this._httpFactories();
      for (let factory of Array.from(factories)) {
        try {
          xmlhttp = factory();
        } catch (e) {
          continue;
        }
        break;
      }
      return xmlhttp;
    }

    _parse_stream() {
      if (this._conn.readyState === 3) {
        let data         = this._conn.responseText.substring(this.last_pos);
        this.last_pos    = this._conn.responseText.length;
        data = data.replace( /\]\]\[\[/g, "],[" );
        try {
          const event_data = JSON.parse(data);
          return this.on_message(event_data);
        } catch (e) {}
      }
    }
  }

}