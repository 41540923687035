module PositiveTS {
	export module DeliveryQueue {

		export async function launchQueueExt() {
			try {
				await launchQueue();
			} catch(error) {
				let msg = "";
				if (error && error.message) {
					msg = error.message;
				}
				if (typeof(error) === "string") {
					msg = error;
				}
				console.error(`Did not send deliveries to server. Reason: ${msg}`);
			}

			// Schedule the next queue launch
			setTimeout(() => { DeliveryQueue.launchQueueExt(); }, 5000);			
		}

	 async function launchQueue() {

        Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT
            let items = await workerDB.sales.filter(sale => (sale.deliverySyncStatus == Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT
            ||  sale.deliverySyncStatus == Shared.Constants.Sale.SYNC_STATUS_FAILED)
            && sale.syncStatus == Shared.Constants.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY).toArray();

			if (items.length == 0) {
				return;
			}

			if (!PositiveTS.QueueWorker.Utils.isOnline) { 
				console.debug('Aborting (offline)');
				return;
			}
			
			 await sendItemsToServer(items);
			 return;
		}

		async function sendItemsToServer(items) {
	
			 console.debug('Sales delivery to sync: ');
			 console.debug(items);
	
			 // Send the sales to the remote server
			 let url = Shared.Constants.remoteRoot + Shared.Constants.DeliveriesRemote;
	
			let results = await PositiveTS.QueueWorker.FetchReq.jsonReq(url,"POST",PositiveTS.QueueWorker.Utils.token ,{ data: JSON.stringify(items) })
			console.debug(results);
			
			 let promises = [];
			 // --- If there was an error, the remote server will return an error object
			 if ('error' in results.result) {
			 	await saveFailures(items)
			 	console.error(results.result.error);
			 	throw new Error(results.result.error);
			 }
			 else {
			 	for (let itemResult of results.result) {

			 		if (itemResult.success) {
						
			 			promises.push(workerDB.sales.where('id').equals(itemResult.pos_sale_id)
			 			.modify({deliverySyncStatus: Shared.Constants.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY, serverID: itemResult.id}))
			 		}
			 		else {
			 			//error
			 			promises.push(workerDB.sales.where('id').equals(itemResult.pos_sale_id)
			 			.modify({deliverySyncStatus: Shared.Constants.Sale.SYNC_STATUS_FAILED}))
					}
			 	}

			 	await Promise.all(promises);
			 	return;
			}
		}

		async function saveFailures(items) {
			
			// Mark all sales sync status as failed
			await workerDB.sales.where('id').anyOf(items.map(item => item.id))
			.modify({ deliverySyncStatus: Shared.Constants.Sale.SYNC_STATUS_FAILED })
			 return;
		}

	}
}
