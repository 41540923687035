module PositiveTS {
export module Components {
    export module BonPrinterMapDialog {
      const dialogSelector = "#bon-printer-map-dialog"

      function cleanData() {
        let result = initData();
        for (let prop in result) {
          this[prop] = result[prop];
        }
      }


      function _initjQueryDialogIfNeeded() {
        let aThis = this;
        if (!this.initialized) {
          //TODO: find a more elegant way to open a dialog or create a vue modal...
            $(dialogSelector).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: 'positive-dialog',
              width: "80%",
              height: 380,
              resizable: false,
              closeOnEscape: false,
              draggable: false,
            });
          this.initialized = true
        }
      }

      function initData() {
        return {
          initialized: this.initialized,
          rejectFunc: null,
          resolveFunc: null,
          allLogicalPrinnters: [],
          isModifiedRow: false,
        }
      }


      function close() {
        if (this.initialized) {
          $(dialogSelector).dialog('close');
        }
        this.cleanData()
        $(document).unbind('keypress');
        $(document).keypress(posVC.onKeyPress);
      }

      function open() {
        this.cleanData();
        var obj = new PositiveTS.Storage.Entity.LogicalPrinter()
        obj.getCache<PositiveTS.Storage.Entity.LogicalPrinter>("dbCache")
        .then( (allLogicalPrinnters) => {
          this.allLogicalPrinnters = JSON.parse(JSON.stringify(allLogicalPrinnters));
        })

        return new Promise((resolve,reject) => {
          _initjQueryDialogIfNeeded.call(this)

          $(document).unbind('keypress');

          $(dialogSelector).dialog('open');
          this.resolveFunc = resolve;
          this.rejectFunc = reject;
        })
      }

      function showGenericError() {
        app.showAlert({
          header: i18next.t('error'),
          content: "שגיאה כללית",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        null,null)
      }

      function showError(errStr) {
        let aThis = this;
        app.showAlert({
          header: i18next.t('error'),
          content: errStr,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true,
        },
        () => {
          this.$refs.searchField.focus();
        },null)
      }

      function confirmAndClose() {
        let modifiedRows = this.allLogicalPrinnters.filter( (row)=>{return row.isModifiedRow === true;});
        this.resolveFunc({approved:true, modifiedRows: modifiedRows})
        this.close();
      }


      function changeLocalPrinter(row){
        this.isModifiedRow = true;
        PositiveTS.Dialogs.PrinterDefault.selectPrintrer()
        .then(function(printerSelected){
          if (!row.originalPhysicalName){
            row.originalPhysicalName = row.physicalName;
          }
          row.physicalName = printerSelected;
          row.isModifiedRow = true;
        });
      }

      function setToDefaultPrinter(row){
        row.physicalName = row.originalPhysicalName;
        row.originalPhysicalName = undefined;
        row.isModifiedRow = true;
        this.isModifiedRow = true;
      }

      export function create() {

        let component = {
          template: JST.bonPrinterMapDialog(),
          mixins: [keyPadMixin],
          methods: {
            cleanData: cleanData,
            _initjQueryDialogIfNeeded: _initjQueryDialogIfNeeded,
            open: open,
            close: close,
            confirmAndClose: confirmAndClose,
            showError: showError,
            showGenericError: showGenericError,
            changeLocalPrinter: changeLocalPrinter,
            setToDefaultPrinter: setToDefaultPrinter,
          },
          data: initData,
          computed: {}
        }

        VueApp.component('bon-printer-map-dialog',component)

      }
    }
  }
}
