module PositiveTS {
  export module Printing {
    
    export function getPrintObject():Print20{
      return new Print20();
    }

    // Private
    interface PrinterObject {
        print():Promise<void>;
        canSetDefaultPrinter: boolean;
    }

    const errPrefix = "ERROR:";

    const err = {
      "ADDON_NOT_INSTALLED": i18next.t('printingAddonNotInstalled'),
    }
    export const PRINTER_TYPE = { A4: "A4",
                          ibm:"ibm",
                          ibm2:"ibm2",
                          ibm4610:"ibm4610",
                          sunmi: "sunmi",
                          sunmit1: "sunmit1",
                          sunmiv1: "sunmiv1",
                          sunmik1: "sunmik1",
                          rongta: "rongta",
                          none: "none",                       
                        };
    const CODE_PAGES  = {
      DOS: "DOS-862",
      ISO: "iso-8859-8"
    }

    enum ADDON_PRINT_SERVICE {
      print,
      print20,
    }

    enum ADDON_PRINT20_ACTIONS {
      PRINT,
      LIST,
    }

    // base class inherited by Print10 or Print20
    class Print {
      codepage: string;
      protected  _skipRawPrinting: boolean;
      protected _data: string = "";
      protected _printBuffers = {};

      constructor (){
        this._setPrinterType();
        this._skipRawPrinting = (session.pos.printerType === PRINTER_TYPE.A4 || session.isAndroid);
      }

      print(addonMsgObj, addonService:ADDON_PRINT_SERVICE):Promise<any> {
        return new Promise((resolve,reject) => {
          if (this._skipRawPrinting) {
            return resolve();
          }

          PositiveTS.Service.Addon.isAddonInstalled()
        .then((isAddonInstalled) => {
          if (isAddonInstalled) {
             PositiveTS.Service.Addon.sendMessageToExtension(addonMsgObj ,
                 ADDON_PRINT_SERVICE[addonService])
               .then(() => {
                 this._data = "";
                 resolve()
               });
          }
          else {
            throw new Error(err.ADDON_NOT_INSTALLED);
          };
            
        })
        .catch((e:Error) => {
           this._uiErrorMsg(e.message);
           throw e;
        });
        })

      }

      resetPrintBuffer(printerBuffer){
          this._printBuffers[printerBuffer] = "";
      }

      append(s:string, printerBuffer?, ignorePicturePrinting = false):void{
        if(printer.isA4() || (printer.isPicturesPrinter(printerBuffer) && (!ignorePicturePrinting))) {
          if (s && s.length > 0) {
            if(Object.values(printer.allowComponents).includes(s)) {
              s = PositiveTS.Printing.HtmlPrinting.applyComponent(s);
            }
          }
        }

        if (printerBuffer){
          this._printBuffers[printerBuffer] += s;
        } else {
        this._data += s;
        }
      };

      protected _uiErrorMsg(msg:string){
    	  app.showAlert({
            header: i18next.t('error'),
            content: msg,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          }, function () {
            // Go back
            pNavigator.back();
          }, null);
    	};

      public  _setPrinterType():void {
            switch (session.pos.printerType) {
            case PRINTER_TYPE.ibm:
            case PRINTER_TYPE.rongta:
              this.codepage = CODE_PAGES.DOS;
              printer.printerType = printerTypes.ibm;
              printer.allowComponents.underline = '';
              printer.allowComponents.init = String.fromCharCode(27) + String.fromCharCode(64);
              printer.allowComponents.bold = String.fromCharCode(27) + String.fromCharCode(69) + String.fromCharCode(27);
              printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(32);
              printer.allowComponents.hugeFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(48);
              printer.allowComponents.smallLineSpace = String.fromCharCode(27) + String.fromCharCode(51) + String.fromCharCode(20);
              if(session.pos.printerType != PRINTER_TYPE.rongta){
                printer.lineLengthBigFont = 20;
                printer.lineLength = 42
                printer.itemDescriptionLength = 20
              } 
            break;
            case PRINTER_TYPE.ibm2:
              this.codepage = CODE_PAGES.ISO;
              printer.printerType = printerTypes.ibm;
              printer.allowComponents.underline = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(128);
              printer.allowComponents.init = String.fromCharCode(27) + String.fromCharCode(64);
              printer.allowComponents.bold = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(10);
              printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(33);
              printer.allowComponents.hugeFont = printer.allowComponents.bigFont;
              printer.allowComponents.smallLineSpace = '';
            break;
            case PRINTER_TYPE.ibm4610:
              this.codepage = CODE_PAGES.DOS;
              printer.printerType = printerTypes.ibm;
              printer.allowComponents.underline = '';
              printer.allowComponents.init = String.fromCharCode(27) + String.fromCharCode(64);
              printer.allowComponents.bold = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(8);
              printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(33);
              printer.allowComponents.hugeFont = printer.allowComponents.bigFont;
              printer.allowComponents.smallLineSpace = '';
              printer.lineLengthBigFont = 20;
              printer.lineLength = 44
              printer.itemDescriptionLength = 22;

            break;
            case PRINTER_TYPE.sunmi:
            case PRINTER_TYPE.sunmit1:
            case PRINTER_TYPE.sunmiv1:
              this.codepage = CODE_PAGES.DOS;
              printer.printerType = printerTypes.ibm;
              printer.allowComponents.underline = '';
              printer.allowComponents.bold = String.fromCharCode(27) + String.fromCharCode(69) + String.fromCharCode(15);
              let noBold = String.fromCharCode(27) + String.fromCharCode(69) + String.fromCharCode(0);

              // printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(30);
              // printer.allowComponents.hugeFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(35);
              printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(22) +
                String.fromCharCode(29) + String.fromCharCode(33) + String.fromCharCode(17)
              printer.allowComponents.hugeFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(38) +
              String.fromCharCode(29) + String.fromCharCode(33) + String.fromCharCode(18)
              printer.allowComponents.smallLineSpace = "" //String.fromCharCode(27) + String.fromCharCode(51) + String.fromCharCode(20);

              printer.allowComponents.cutLine = String.fromCharCode(29) + String.fromCharCode(86) + String.fromCharCode(1);
              printer.allowComponents.init = String.fromCharCode(28) + String.fromCharCode(67) + String.fromCharCode(34);
              if(navigator.userAgent.toLowerCase().indexOf('t2')){
                printer.allowComponents.init = String.fromCharCode(parseInt("200F",16)) + String.fromCharCode(28) + String.fromCharCode(67) + String.fromCharCode(34);                
              }
              let regularFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(14);
              
              printer.allowComponents.init = printer.allowComponents.init + regularFont + noBold + 
                String.fromCharCode(29) + String.fromCharCode(33) + String.fromCharCode(0) +
                String.fromCharCode(27) + String.fromCharCode(32) + String.fromCharCode(1) 

              printer.allowComponents.centerAlignment = String.fromCharCode(27) + String.fromCharCode(97) + String.fromCharCode(1);
              printer.allowComponents.rightAlignment = String.fromCharCode(27) + String.fromCharCode(97) + String.fromCharCode(2);


              printer.lineLength = 38
              printer.itemCodeLength = 12
              printer.itemDescriptionLength = 16
              printer.itemPriceLength = 6
              printer.itemDiscountLength = 28

              printer.firstDefaultColWidth = 23;
              printer.secondDefaultColWidth = 9;
              printer.thirdDefaultColWidth = 6;
              printer.lineLengthBigFont = 19;

              printer.firstDefaultColWidth4Columns = 16;
              printer.secondDefaultColWidth4Columns = 8;
              printer.thirdDefaultColWidth4Columns = 8;
              printer.fourthDefaultColWidth4Columns = 6;

              if(session.pos.printerType == PRINTER_TYPE.sunmit1)
              {
                printer.lineLength = 36
              }

              if(session.pos.printerType == PRINTER_TYPE.sunmiv1)
              {
                printer.lineLength = 29;
                printer.lineLengthBigFont = 14;
                printer.firstDefaultColWidth = 16;
                printer.secondDefaultColWidth = 8;
                printer.thirdDefaultColWidth = 5;
                printer.zReportTableWidths = [20,9];

                printer.firstDefaultColWidth4Columns = 12;
                printer.secondDefaultColWidth4Columns = 6;
                printer.thirdDefaultColWidth4Columns = 6;
                printer.fourthDefaultColWidth4Columns = 5;


                printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(22) +
                String.fromCharCode(29) + String.fromCharCode(33) + String.fromCharCode(16)
              }
          
                            
              // reinit font
              

            break;
            default: // epson is default
              this.codepage = CODE_PAGES.ISO;
              printer.printerType = printerTypes.epson;
              printer.allowComponents.underline = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(128);
              printer.allowComponents.bold = String.fromCharCode(27) + String.fromCharCode(69) + String.fromCharCode(27);
              printer.allowComponents.bigFont = String.fromCharCode(27) + String.fromCharCode(33) + String.fromCharCode(100);
              printer.allowComponents.hugeFont = printer.allowComponents.bigFont;
              printer.allowComponents.smallLineSpace = '';
          }

          
        if (session.isAndroid && !jsonConfig.getVal(jsonConfig.KEYS.printWithPrimaryPos)) {
          // Don't ask, you are not ready for it
          printer.allowComponents.logo = printer.androidLogoChars;
        }
      }

    }


    class Print20AddonAction {
      action:string;
      data:string = "";
      dataObj:any;

      constructor(action:ADDON_PRINT20_ACTIONS, content?, codepage?, printer?){
          this.action = ADDON_PRINT20_ACTIONS[action];
          if (action === ADDON_PRINT20_ACTIONS.PRINT) {
            this.data = JSON.stringify({
              content: content,
              codepage: codepage,
              printer: printer
            });
            this.dataObj = {
              content: content,
              codepage: codepage,
              printer: printer              
            }
          }
      }
    }

    var isPrint20Vailable = true;

    function ensureIsPrint20Vailable () {
      let timesTried = 0;
        return new Promise(function (resolve, reject) {
            (function waitForFoo(){
                if (isPrint20Vailable || timesTried > 33) {//if printer wasn't available for the last second (33 times * 30 milisecs), print anyway
                  return resolve();
                }
                timesTried++;
                setTimeout(waitForFoo, 30);
            })();
        });
    }

    export class Print20 extends Print implements PrinterObject  {
      canSetDefaultPrinter = true;
      ADDON_ACTIONS = {
        PRINT: "PRINT",
        LIST: "LIST",
      };
      LOCAL_STORAGE_PRINTER_NAME = "printer20Name";

      constructor (){
        super();
      }

      androidPrint(data,fontSize){
        if(Number(Android.getVersion()) > 1.41){  //Only 1.42 and above supports fontSize
          Android.print(data,PositiveTS.VueInstance.wifiPrinterIp,fontSize);    
        }
        else{
          Android.print(data,PositiveTS.VueInstance.wifiPrinterIp);   
        }
      }

      printForceMultiline():Promise<void>  {
        return this.print(this.getDefaultPrinter(), null, true, true);
      }

      printWithoutPicturesPrinting():Promise<void>  {
        return this.print(this.getDefaultPrinter(), null, true, false, true);
      }

      async printAndroidWifi(data, ipAddress) {
        let result;

        if (Android.printWifiAndInitConn !== undefined) {
          try {
            result = await Service.AndroidService.makeActionAsyncIfSupported('printWifiAndInitConn', data, ipAddress);
          } catch (err) {
            Service.Logger.error('Error running android function printWifiAndInitConn');
            Service.Logger.error(err);
            return;
          }
        } else {
          result = Android.printWifi(data,ipAddress);
        }
                  
        if (!result) {
          Service.Logger.error('Error printing wifi, android retured false');
        }
      }

      printAndroidRongta(data) {
        let printingResult = Android.RTPrint(data);
        if(printingResult == false){
          app.showAlertDialog({
            header: i18next.t('error'),
            content: i18next.t('printing.androidRongtaPrinterError'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
            imageHeight: 384,
            imageUrl: `${(<any>window).images_path}pos/usb-permission.gif`
          })
        }
      }

      printAndroidRegular(data, fontSize) {
        let printLogoIndex = this._data.indexOf("[PRINTLOGO]");

        while(printLogoIndex != -1){
          this.androidPrint(data.slice(0,printLogoIndex),fontSize);
          if(Android.printLogo !== undefined){
            Android.printLogo();
          }
          data = data.slice(printLogoIndex+11);
          printLogoIndex = data.indexOf("[PRINTLOGO]");
        }
        this.androidPrint(data,fontSize);
      }

      async printPicture(printingData, forceMultiLine, defaultPrinter, isShowUiError = false) {
        let classes = `image-printing ${session.pos.printerType} lang-${jsonConfig.getVal(jsonConfig.KEYS.lang) || 'he'}`;

        if (jsonConfig.getVal(jsonConfig.KEYS.printItemCode) || forceMultiLine) {
          classes = `${classes} multiline`;
        }

        let allHtmlDocuments = printingData.split(printer.allowComponents.picturePrinterCutLine).filter(doc => doc.length)


        for (let index in allHtmlDocuments) {
          let printLogo = false;
          let htmlDoc = allHtmlDocuments[index];
          let printLogoIndex = htmlDoc.indexOf("[PRINTLOGO]");

          if (printLogoIndex != -1) {
            htmlDoc = htmlDoc.replace(/\[PRINTLOGO\]/g, '')
            printLogo = true;
          }


          let documentToPrint = PositiveTS.Printing.HtmlPrinting.getPrintingHtml(htmlDoc, classes);
          let base64img = await PositiveShared.PicturesPrinting.convertHtmlToBase64Img(documentToPrint, session.pos.printerType, !session.isAndroid, session.isAndroid && session.pos.printerType != PRINTER_TYPE.rongta ? 1 : 1.33);

          if (!base64img) {
            continue;
          }

          // Fixed for sunmi
          let fixedBase64img = base64img.substr(base64img.indexOf(',') + 1);

          if (session.isAndroid && typeof(Android) !== "undefined" && Android.printImg) {
            if (parseInt(index) > 0) {
              if (jsonConfig.getVal(jsonConfig.KEYS.waitOnPrinterLineCut)) {
                await new Promise(resolve => setTimeout(resolve, 5 * 1000)); // wait 5 seconds
              }

              if (jsonConfig.getVal(jsonConfig.KEYS.approvalOnPrinterLineCut)) {
                window.alert(i18next.t('printing.continuePrintingMessage'));
              }
            }

            if (posUtils.isBlank(defaultPrinter)) {
              if (session.pos.printerType == PRINTER_TYPE.rongta) {

                if (printLogo) {
                  printer.printLogo(true);
                  await Service.AndroidService.makeActionAsync('RTPrint', this._data);
                  this._data = '';
                }

                Service.AndroidService.makeActionAsync('RTPrintImg', fixedBase64img);              
              } else {
                if (printLogo && Android.printLogo !== undefined) {
                  Android.printLogo();
                }

                Android.printImg(fixedBase64img);
              }
            } else {
              let logicalPrinter = Storage.Entity.LogicalPrinter.getPrinterByPhysicalName(defaultPrinter);

              if (logicalPrinter.isSunmiPrinter()) {
                if (session.pos.printerType == PRINTER_TYPE.rongta) {
                  Service.AndroidService.makeActionAsync('RTPrintImg', fixedBase64img);              
                } else {
                  Android.printImg(fixedBase64img);
                }
              } else if (logicalPrinter.isWifiPrinter()) {
                Service.AndroidService.makeActionAsync('RTPrintImgWifi', fixedBase64img, logicalPrinter.getPrinterIpAddress());
              }
            }
          } else {
            // uncomment for opening new window in order to watch printing (helps when dubigging/creating new print)
            // let imgWidnow = window.open("")
            // imgWidnow.document.write("<iframe width='100%' height='100%' src='" + base64img + "'></iframe>")
            
            if (printLogo) {
              printer.printLogo(true);
              await printer.jzebra.printAddonRegular(this._data, '', false);
              this._data = '';
            }

            let resp = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
              action: 'printPicture',
              base64image: fixedBase64img,
              printerName: defaultPrinter,
            }, 'picturesprinter');

            if (resp.request.result != "true" && isShowUiError) {
              this._uiErrorMsg(resp.request.result);
            }
          }
      }

        return;
      }

      async printAddonRegular(data, defaultPrinter, isShowUiError, printAction = ADDON_PRINT20_ACTIONS.PRINT) {
        var addonMsgObj = new Print20AddonAction(printAction, data, this.codepage, defaultPrinter);

        try {
          await ensureIsPrint20Vailable();

          isPrint20Vailable = false;

          let msg = await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(addonMsgObj, ADDON_PRINT_SERVICE[ADDON_PRINT_SERVICE.print20]);
          if (!msg) {
            throw new Error("Print20 executable disconnected fatal error")
          }

          isPrint20Vailable = true;

          if (msg.request.result.startsWith("ERROR:") )  {
            throw new Error(msg.request.result)
          }

        } catch(e) {
          console.error(e);
          if (e && e.message) {
            console.error(e.message);
          }
          console.error(addonMsgObj);
          isPrint20Vailable = true;
          var errorMsg = defaultPrinter + " " + e.message;
          if (isShowUiError) {this._uiErrorMsg(errorMsg);}
          if (errorMsg && !session.isAndroid) {
            throw new Error(errorMsg)
          }
        }
      }


      async printData(data) {
        return await this.print(this.getDefaultPrinter(), null, true, false, false, {_data: data, _printBuffers: [], _skipRawPrinting: false});
      }


      async print(defaultPrinter=this.getDefaultPrinter(), printerBuffer?, isShowUiError = true, forceMultiLine = false, ignorePicturesPrinting = false, printingDataHolder = null):Promise<void> {

        if (jsonConfig.getVal(jsonConfig.KEYS.printWithPrimaryPos) && posUtils.isBlank(printerBuffer)) {
          let result = await Service.PrimaryPosPrint.sendPrintJobToPrimaryPosPrinter(this._data);
          this._data = "";

          if (!result.success && isShowUiError) {
            this._uiErrorMsg(i18next.t('printing.printerError') + ': ' + result.errorMessage);
          }

          return;
        }

        let logicalPrinter = null;

        if (!printingDataHolder) {
          printingDataHolder = this;
        }

        if (session.isAndroid && jsonConfig.getVal(jsonConfig.KEYS.isPrimaryPosPrinter)) {
          printingDataHolder._data = printingDataHolder._data.replace(printer.windowsLogoChars, printer.allowComponents.logo);
        }

        if (posUtils.isDefined(printerBuffer)) {
          logicalPrinter = Storage.Entity.LogicalPrinter.getPrinterByPhysicalName(printerBuffer);

          if (session.pos.printerType == PRINTER_TYPE.none && logicalPrinter && logicalPrinter.isSunmiPrinter()) {
            printingDataHolder._data = "";
            return;
          }

        } else {
          if (session.pos.printerType == PRINTER_TYPE.none) {
            printingDataHolder._data = "";
            return;
          }
        }
        
        if(printer.isPicturesPrinter(printerBuffer) && (!ignorePicturesPrinting)) {
          let printingData;

          if (posUtils.isDefined(printerBuffer)) {
            printingData = printingDataHolder._printBuffers[printerBuffer];
            printingDataHolder._printBuffers[printerBuffer] = "";
          } else {
            printingData = printingDataHolder._data;
            printingDataHolder._data = "";
          }
  
          if (posUtils.isBlank(printingData)) {
            return;
          }

          try {
            await  this.printPicture(printingData, forceMultiLine, defaultPrinter, isShowUiError)
          } catch (e) {
            console.log('picture printing - failed');
          }
         
        }

        if(session.pos.printerType == PRINTER_TYPE.A4) {
          PositiveTS.Printing.HtmlPrinting.print(printingDataHolder._data);

          printingDataHolder._data = "";
          return;
        }
        try{
          if (session.isAndroid && typeof(Android) !== "undefined") {


            let data = printingDataHolder._data;
            let fontSize = printer.isNarrowPrinting() ? 24 : 27
            if (posUtils.isDefined(printerBuffer)) {
              // apk version 1.55+
              if (logicalPrinter.isWifiPrinter()) {
                let printingData = printingDataHolder._printBuffers[printerBuffer]
                printer.jzebra.resetPrintBuffer(printerBuffer)
               if (Android.printWifiAndInitConn !== undefined || logicalPrinter.isOldWifiPrinter()) {
                  if(printingData && printingData.length > 0){
                    this.printAndroidWifi(printingData, logicalPrinter.getPrinterIpAddress()); 
                  }
                } else {
                  Service.Logger.error('Minimum apk version to support this feature is 1.55, please upgrade the device');
                }
               printingDataHolder._data = "";  
                return;
              }
             if (logicalPrinter.isSunmiPrinter()) {
                fontSize = 24
                data = printingDataHolder._printBuffers["SUNMI"]
                printer.jzebra.resetPrintBuffer("SUNMI")
              }
            }

            if(session.pos.printerType == PRINTER_TYPE.rongta){
              this.printAndroidRongta(data);
            } else {
              this.printAndroidRegular(data, fontSize);
            }            

            printingDataHolder._data = "";
            return;
          }
        }
        catch(e){
          console.error(e);
          let errorMsg = ""
          if(navigator.userAgent.toLowerCase().indexOf('t2lite') && session.pos.printerType != PRINTER_TYPE.rongta){
            errorMsg = "\r\n" + i18next.t('printing.t2liteconfigerror')
          }
          app.showAlertDialog({
            header: i18next.t('error'),
            content: i18next.t('printing.androidPrintingError') + errorMsg,
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          })
        }

        if (printingDataHolder._skipRawPrinting) {
          return;
        }

        let data = printingDataHolder._data;

        if (printerBuffer) {
          data = printingDataHolder._printBuffers[printerBuffer]
        }

        // it is critical to clear before async operation to avoid race
        if (!printerBuffer){
          printingDataHolder._data = "";
        }

        try {
          await this.printAddonRegular(data, defaultPrinter, isShowUiError);
        } catch (e) {
          console.log('printing - failed');
        }
      }

      getQrZpl(data: string, x = 30, ys = [250, 1000, 1800], size = 7) {

        let qrZplCode = `
        ^XA
        ^MNM,0`

        for (let y of ys) {
          qrZplCode += `
          ^FO${x},${y}
          ^BQN,2,${size}
          ^FDMM,A${data}^FS
          `;
        }

        qrZplCode += `^XZ`
        
        return qrZplCode;
      }

      async printOnZebraPrinter(data: string, isPicturesPrinter = false) {
        // data - if pictures printer then base64 image, if not then zpl code
        // printerNameOrIp - printerName for windows, ip for android if wifi printer
        let printerNameOrIp = jsonConfig.getVal(jsonConfig.KEYS.zplLabelPrinterName);

        if (session.isAndroid) {
          let params = [data]
          
          if (!posUtils.isBlank(printerNameOrIp)) {
            params.push(printerNameOrIp);
          }

          if (isPicturesPrinter) {
            await Service.AndroidService.makeActionAsync('zebraPrintPicture', ...params);
          } else {
            await Service.AndroidService.makeActionAsync('zebraPrintZpl', ...params);
          }
        } else {
          if (isPicturesPrinter) {
            await PositiveTS.Service.GenericAddon.sendNativeMessageToExtension({
              action: 'printPicture',
              base64image: data,
              printerName: printerNameOrIp,
            }, 'picturesprinter');
          } else {
            await this.printAddonRegular(data, printerNameOrIp, false);
          }
        }
      }

      getPrinterList():Promise<string[]>{
        return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(
          new Print20AddonAction(ADDON_PRINT20_ACTIONS.LIST),"print20","טוען רשימת מדפסות")
          .then((response) => {
            this._throwIfError(response);
            return JSON.parse(response.request.result).printers;
         });
      }

      getDefaultPrinter():string{
        return localStorage.getItem(this.LOCAL_STORAGE_PRINTER_NAME)||"";
      }

      setDefaultPrinter(name: string):void {
        localStorage.setItem(this.LOCAL_STORAGE_PRINTER_NAME, name);
      }

      //also trim the ERROR:
      private _throwIfError(response):void{
        var err:string = response.request.result;
        if ( err.startsWith(errPrefix) ) {
          throw new Error( err.substr(errPrefix.length));
        }
      }
    }




  }
}
