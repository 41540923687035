module PositiveTS {
  export module Components {
    export module changeWaiterComponent {

      export const route = "/change-waiter";

      export function getComponent() {
        return {
          template: JST.changeWaiterComponent(),
          props: {
            asDialog: {
              type: Boolean,
              default: false,
            }
          },
          methods: {
            initData(selectedDalpakId = null, activeEmployee = null) {
              
              this.changeWaiterData = {
                dalpak: selectedDalpakId,
                waiter: activeEmployee,
              }

            },
            async open(selectedDalpak, activeEmployee) {
              if (this.asDialog) {
                this.initData(selectedDalpak, activeEmployee);
                return await this.$refs.dialog.open();
              }
            },
            async validateAndFinish() {
              let errors = [];

              if (posUtils.isBlank(this.changeWaiterData.dalpak) || posUtils.isBlank(this.changeWaiterData.waiter)) {
                errors.push(this.$t('changeWaiterComponent.waiterMandatory'));
              }

              if (errors.length > 0) {
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: errors.join('\n'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                });

                return false;
              }

              return await this.finish();
            }, 
            async finish() {
              let selectedEmployee = this.dalpakEmployees.filter(e => e._data.employeeID == this.changeWaiterData.waiter)[0];
              let result = await Service.Dalpak.updateDalpakEmployee(this.dalpak, selectedEmployee);
              if (result && !this.asDialog) {
                this.goBack();
              }

              return result;
            },
            goBack() {
              VueServices.Router.goto(SelectDalpakDialog.route);
            },
            async selectDalpak() {
              if (!posUtils.isBlank(this.changeWaiterData.waiter)) {
                try {
                  await Service.Dalpak.updateDalpakEmployee(this.dalpak, this.fromDalpak);
                } catch (err) {
                  console.error(err);
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: err.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }
              }

            },

          },
          setup(){
            const dalpaksStore = Pinia.useDalpaksStore();
            const {
              allDalpaksWithoutSpecialAreas,
              dalpaksByArea,
              openedOrdersCount,
              dalpakAreas,
              allDalpaks} = Pinia.storeToRefs(dalpaksStore);
            return {allDalpaksWithoutSpecialAreas,dalpaksByArea,openedOrdersCount,dalpakAreas,dalpaks:allDalpaks}
          },
          computed: {
            dalpak() {
              return this.allDalpaksWithoutSpecialAreas.filter(d => d.id == this.changeWaiterData.dalpak)[0];
            },
            employees(){
              if(!this.dalpakEmployees) return []
              return this.dalpakEmployees.map(e => {
                return {
                  id: e._data.employeeID,
                  name: e.name,
                }
              });
            },
            allWaiters() {
              return this.dalpakEmployees.filter(d => posUtils.isBlank(d.employee));
            },
            mobileLayout(){
              return Pinia.globalStore.mobileLayout
            },
            dialogButtons() {
              return [
                {
                  text: this.$t('saveAction'),
                  class: 'tables-green',
                  name: 'save',
                  value: true,
                  beforeCloseAction: async () => await this.validateAndFinish(),
                }
              ]
            },
            getRootItemBindings() {
              if (this.asDialog) {
                return {
                  faIcon: "fa-solid fa-arrow-right-to-arc",
                  animation: "open-from-left",
                  buttons: this.dialogButtons,
                  title: this.$t('changeWaiterComponent.dialogTitle'),
                };
              }

              return {};
            },
          },
          data() {
            return {
              isRestaurantNewPOS: Service.Dalpak.isRestaurantNewPOS(),
              changeWaiterData: {
                table: null,
                waiter: null,
              },
              dalpakEmployees: null,

            }
          },
          async mounted() {
            this.dalpakEmployees = await Storage.Entity.Employee.fetchByStoreOrByShift(session.pos.storeID)
            
            if (!this.asDialog) {
              this.initData(this.$router.currentRoute.value?.params?.dalpakId, this.$router.currentRoute.value?.params?.activeEmployee);
            }
          },
          beforeRouteEnter: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(i18next.t('changeWaiterComponent.dialogTitle'))
            Pinia.dalpaksStore.setHideHamburgerMenu(true)
            Pinia.dalpaksStore.setGoBackAction(() => VueServices.Router.goto(SelectDalpakDialog.route))
            next();
          },
          beforeRouteLeave: async function(to, from, next) {
            Pinia.dalpaksStore.setDalpaksPageTitle(null)
            Pinia.dalpaksStore.setHideHamburgerMenu(false)
            Pinia.dalpaksStore.setGoBackAction(null)
            next();
          }
        }
      }
    }
  }
}
