module PositiveTS {
export module Storage {
export module Entity {
export class ExternalOrder extends IDBEntity {

	orderNum: number;
	externalOrderId: string;
	deliveryType: Service.Delivery.DeliveryType;
	status: Service.Delivery.ExternalOrderStatus;
	date: string;
	totalAmount: number;
	customerName: string;
	phone: string;
	addressName: string;
	floor: number;
	apartmentNumber: string;
	entrance: string;
	houseNumber: number;
	street: string;
	city: string;
	orderType : Service.Delivery.ExternalOrderType;
	saleItems: Array<SaleItem>;
	orderOrigin: Service.Delivery.ExternalOrderOrigin;
	paymentToken: string;
	syncStatus:number;
	paid: boolean;
	isPhoneOrder: boolean;
	remarks: string;
	remoteDBId: number;
	saleInvoiceSequence:number;
	posDeviceId: string;
	storeId: number;
	paymentData: string;

	constructor() {
		let meta = {
			name: 'ExternalOrder',
			fields: {
				orderNum: "INT",
				externalOrderId: "TEXT",
				deliveryType: "TEXT",
				status: "INT",
				date: "TEXT",
				totalAmount: "FLOAT",
				customerName: "TEXT",
				phone: "TEXT",
				addressName: "TEXT",
				floor: "INT",
				apartmentNumber: "TEXT",
				entrance: "TEXT",
				houseNumber: "INT",
				street: "TEXT",
				city: "TEXT",
				notes: "TEXT",
				orderType : "INT",
				orderOrigin: "INT",
				paymentToken: "TEXT",
				syncStatus: "INT",
				remarks: "TEXT",
				paid: "BOOL",
				remoteDBId: "INT",
				saleInvoiceSequence: "INT",
				posDeviceId: "TEXT",
				storeId: "INT",
				paymentData: "TEXT",
				isPhoneOrder: "BOOL"
			},
		}
		super(meta)
	}

	get missingItems():Array<string> {
		return this.saleItems.filter((item) => item.itemCode == jsonConfig.getVal(jsonConfig.KEYS.tenbisApiGenericItem)).map((item) => item.itemDescription);
	}

	async persist() {
		if(this.saleItems && this.saleItems.length > 0) {
			this._data.saleItems = this.saleItems.map(item => item._data);
			appDB.externalOrders.put(this);
		}
	}
}}}}
