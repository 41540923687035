module PositiveTS {
    export module Application {
        export module Controllers {
            export class EmployeeBounsReportViewController extends AbstractViewControllerTS {
                // UGLY! Remove this when solving the resume issue in dialogs!!!
                fromEmployeeSelect = false
                // -- END of UGLY
                selectedEmployee = null
                choose_date_start = new Date()
                choose_date_ends = new Date()
                
                // --------------------------------------------------------------------
                // Controller Life-Cycle
                // --------------------------------------------------------------------
                init(options) {
                    
                    
                    $('#employee-bouns-report-from_date').datepicker({
                        dateFormat: 'dd/mm/yy',
                        changeMonth: true,
                        changeYear: true,
                        maxDate: "+0d",       
                    });                
                    
                    
                    $('#employee-bouns-report-to_date').datepicker({
                        dateFormat: 'dd/mm/yy',
                        changeMonth: true,
                        changeYear: true,
                        maxDate: "+0d",
                    });    
                    
                    
                    $('#employee-bouns-report-to_date').autoselected();            
                    $('#employee-bouns-report-from_date').autoselected();                    
                    
                    $('#employee-bouns-report-percent-container').inputField({
                        keyboardOn:   true,
                        disclosureOn: false
                    });       
                    
                    var aThis = employeeBounsReportVC;
                    $('#employee-bouns-report-month-container').positiveRadioSelect({
                        
                        changed: aThis.changeDate
                    });
                    $('#employee-bouns-report-month-container').positiveRadioSelect('select', 1);
                }
                changeDate(){
                    
                    
                    employeeBounsReportVC.choose_date_start = $( '#employee-bouns-report-from_date' ).datepicker( "getDate" );
                    employeeBounsReportVC.choose_date_ends = $( '#employee-bouns-report-to_date' ).datepicker( "getDate" );
                    
                    employeeBounsReportVC.choose_date_start.setHours(0,0,0,0);
                    employeeBounsReportVC.choose_date_ends.setHours(23,59,59,999);        
                    
                }
                resume(options) {
                    // Initialize employee card field
                    $('#employee-bouns-report-employee-card-field-container').inputField({
                        keyboardOn:   false,
                        disclosureOn: true
                    });
                    $('#employee-bouns-report-employee-card-field-container').inputField('disclosureListener', employeeBounsReportVC.openEmployeeSelectDialog);
                    $('#employee-bouns-report-employee-card-field-container').inputField('change', employeeBounsReportVC.updateEmployeeOnClear);
                    if (!employeeBounsReportVC.fromEmployeeSelect) {
                        $('#employee-bouns-report-employee-card-field-container').inputField('setValue', '');
                    }
                    
                    
                    $('#employee-bouns-report-continue').click(employeeBounsReportVC.continueClicked);
                    
                    
                }
                stop() {
                    // Unbind the listeners from the resume function
                    $('#employee-bouns-report-continue').unbind('click');
                    $('#employee-bouns-report-employee-card-field-container').inputField('unbind');
                }
                destroy() {
                }
                // --------------------------------------------------------------------
                // View Related Functions
                // --------------------------------------------------------------------
                updateEmployeeOnClear(event) {
                    if ($('#employee-bouns-report-employee-card-field-container').inputField('getValue') === '') {
                        employeeBounsReportVC.selectedEmployee = null;
                    }
                }
                openEmployeeSelectDialog(event) {
                    return employeeSelectVC.openRelativeTo('/homepage/employee-bouns-report')
                    .then(function (employee:any) {
                        employeeBounsReportVC.selectedEmployee = employee;
                        $('#employee-bouns-report-employee-card-field-container').inputField('setValue', employee.name);
                    });
                }
                displayErrorMessage(errorMessage) {
                    app.showAlert({
                        header: i18next.t('error'),
                        content: errorMessage,
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                    }, function () {
                    }, null);
                }
                continueClicked() {
                    
                    employeeBounsReportVC.changeDate();
                    let from_date   = employeeBounsReportVC.choose_date_start;
                    let to_date     = employeeBounsReportVC.choose_date_ends;
                    
                    
                    
                    if ((from_date == null)||(to_date == null)){
                        
                        employeeBounsReportVC.displayErrorMessage('לא נבחרו תאריכים');
                        return;
                    }
                    
                    app.showLoadingMessage(i18next.t('generatingReportWait'));
                    var bouns_percent = $('#employee-bouns-report-percent-container').inputField('getValue');
                    
                    // Generate report for the supplied parameters
                    employeeBounsReportVC.generateReport(employeeBounsReportVC.selectedEmployee,from_date, to_date,bouns_percent);
                }
                closeScreen() {
                    pNavigator.back();
                }
                // --------------------------------------------------------------------
                // Logic Related Functions
                // --------------------------------------------------------------------
                async generateReport(employee, from_date, to_date ,bouns_percent) {
                    
                    
                    var employeeID;
                    if (employee !== null) {
                        employeeID = employee.employeeID;
                    }
                    
                    try {
                        const htmlResponse = await Service.EmployeeBounsReport.getFromServer({
                            employee_id: employeeID,
                            bouns_percent: bouns_percent,
                            from_date: from_date,
                            to_date: to_date,
                        });
                        app.hideLoadingMessage();
                        let dialogElem = document.getElementById("employee-bouns-report-dialog");
                        let dialogElemContent = document.getElementById("employee-bouns-report-dialog-content");
                        dialogElemContent.innerHTML = htmlResponse;
                        let printBtnElement = <HTMLElement>document.getElementById("emp-bonus-print-btn")
                        if(printBtnElement && session.isAndroid){
                            printBtnElement.hidden = true;
                        }
                        (<HTMLDialogElement>dialogElem).showModal();
                    } catch (error) {
                        app.hideLoadingMessage();
                        console.error(error);
                        employeeBounsReportVC.displayErrorMessage(error.responseText);
                    }
                }
            }
        }}}
        
        
        declare var employeeBounsReportVC:PositiveTS.Application.Controllers.EmployeeBounsReportViewController;
        employeeBounsReportVC = new PositiveTS.Application.Controllers.EmployeeBounsReportViewController();
        PositiveTS.Application.Controllers.instances.EmployeeBounsReportViewController = employeeBounsReportVC;
