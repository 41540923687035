module PositiveTS {
export module Service {
export module WebDB {

	let db = null;


	export function promiseSql(strSql, db):Promise<SqlResultSet> {

		return new Promise((resolve,reject) => {
			db.transaction(
				(tx) => {
					tx.executeSql(strSql, null,
						(tx, result) => {
							resolve(result);
						},
						(tx, error) => {
						  console.error(strSql);
							reject(error);
						}
					);
				},
				(error) => {
					console.error(error);
					reject(error);
					return true; //rollback the transaction
				}
			);
		})
	};

	// table name is case sensative!
	export async function tableExists(tableName, db) {
		let res = await promiseSql(`select count(*) as cnt from sqlite_master where name='${tableName}' and type='table'`, db)
		return res.rows.item(0).cnt == 1
	}

	export function getDbIfSupported() {
		if ("openDatabase" in window) {
			if (!db) {
				db = (<any>window).openDatabase('positive', '1.0', 'Positive WebSQL Database', 50 * 1024 * 1024);
			}

			return db;
		} 

		return null;
	}
}}}
