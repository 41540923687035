module PositiveTS {

/*
Usage:
Pinia.componentsStore.openComponent( {componentName:"employeeInStore", arguments: ["10.50","123123"]})
*/
export module Components {
export module EmployeeInStore {
  
  const _vueComponentName = 'employee-in-store';

  export function create() {
    let vueObj = {
        template: JST.employeeInStore(),
        methods: {
          hoursStr: hoursStr,
          open: _open,
          close: _close,
        },
        computed:{
          isRoshemet(){
            return Pinia.globalStore.isRoshemet
          }
        }, 
        data: _initData

    }

    VueApp.component(_vueComponentName,vueObj)

  }

  function hoursStr(rowHours:number) {
    let strHours = String("00" + Math.floor(rowHours)).slice(-2)
    let strMinutes = String("00" + Math.round((rowHours%1)*60)).slice(-2)
    return `${strHours}:${strMinutes}`
  }

  function _open(rows){
    this.$el.showModal();
    this.rows=rows;

  }


  function _initData(){
    return {
      rows: [],
    };
  }

  function _close() {
    this.$el.close()
  }


}}}
