module PositiveTS { 
  export module Printing {

    export class PluxeeSlip extends AFactory {
        private _I18N = {
          FOR_DEBIT_DOCUMENT: "חיוב פלאקסי:",
          FOR_CREDIT_DOCUMENT: "זיכוי פלאקסי:",
          AMOUNT_SUM: "סכום העסקה",
          TRX_ID: "מזהה העסקה",
          COMPANY_ID: "חברה",
        };



        constructor(sale: any, company: any, salePayments: any) {
            super(sale, company, salePayments);
        }

        run(): void{
          var voucherPayments = PositiveTS.Helper.SaleHelper.findPaymentByMethod(this._salePayments, PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER);
          if (voucherPayments) {
            var cibusPayments = JSON.parse(voucherPayments.data).filter( (row)=>{return row.smartVoucherType === "Cibus"});
            if ( !(cibusPayments.length) ){return;}

            for (let i=0;i<cibusPayments.length;i++){
              this._printCibusPayment(cibusPayments[i])
            }
          }
          else {
            return;
          }

        }

        private _printCibusPayment(cibusPayment){
          var aThis = this._aThis;
          var zebra = this._zebra;
          var sale = this._sale;

          if (cibusPayment.amount > 0) {
              this.printHeaderForSlipType(this._I18N.FOR_DEBIT_DOCUMENT);
          } else {
              this.printHeaderForSlipType(this._I18N.FOR_CREDIT_DOCUMENT);
          }

          zebra.append(aThis.allowComponents.init);
          zebra.append(aThis.allowComponents.rightAlignment);
          this.lineFeed();

          aThis.printFloatingLine(this._I18N.AMOUNT_SUM, session.fixedNumber(cibusPayment.amount))
          aThis.printFloatingLine(this._I18N.TRX_ID, cibusPayment.orderId)
          aThis.printFloatingLine(this._I18N.COMPANY_ID, cibusPayment.companyId || "")

          zebra.append(aThis.allowComponents.init);

          this.lineFeed();
          printer.printLineCut();
        }
    }


  }
}
