module PositiveTS {
	export module Storage {
		export module Entity {
			export class HakafaCustomer extends WasmEntity {

				first_name: string 
				last_name: string
				tz: string
				store_id: number
				amount: number 
				birth_date: string
				phone: string
				name: string
				is_price_alut: boolean
				is_monthly_obligo: boolean
				address: string 
				customer_number: string 
				is_tamash_customer: boolean
				merakez: string
				obligo: number 
				printer_typed: number 
				discount_percent: number
				is_parent_hakafa_customer: boolean 
				parent_hakafa_customer_id: number 
				email: string
				customer_group_id: number
				is_locked: boolean 
				timestamp: number

				constructor() {
					let meta = {
						name: 'HakafaCustomer',
						fields: {
							first_name: "TEXT", 
							last_name: "TEXT", 
							tz: "TEXT", 
							store_id: "INT", 
							amount: "FLOAT", 
							birth_date: "TEXT", 
							phone: "TEXT", 
							name: "TEXT",
							is_price_alut: "BOOL", 
							is_monthly_obligo: "BOOL", 
							address: "TEXT", 
							customer_number: "TEXT", 
							is_tamash_customer: "BOOL", 
							merakez: "TEXT", 
							obligo: "FLOAT", 
							printer_typed: "INT", 
							discount_percent: "FLOAT",
							is_parent_hakafa_customer: "BOOL", 
							parent_hakafa_customer_id: "INT", 
							email: "TEXT", 
							customer_group_id: "INT", 
							is_locked: "BOOL", 
						}
					}
					super(meta)
				}

				private static get canUseOnline(): boolean {
					return Boolean(PositiveTS.Reachability.isOnline || jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos))
				}
				
				static async search(params: {search : string}): Promise<any> {
					let paramsString = ''

					for (const [key, value] of Object.entries(params)) {
						if(value || key == "search" || key == "is_locked"){
							paramsString += `${key}=${value}&`
						}
					}

					if(this.canUseOnline) {
						return await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers?new_mode=true&${paramsString}`, "get")
					}

					const notFuond = 4
					const success = 1;

					let searchInput = params.search

					let sqlExact = `select * from HakafaCustomer where id = '${searchInput}' or tz = '${searchInput}'`;
					let fuzzySearchInput = searchInput.trim().replace(/  +/g, ' ').replace(' ','%');
					let tzFuzzySearch = fuzzySearchInput && fuzzySearchInput[0] === '0' ? fuzzySearchInput.substr(1,fuzzySearchInput.length -1) : fuzzySearchInput;
					let sqlFreetext = `select * from HakafaCustomer where id like '%${fuzzySearchInput}%' 
					or tz like '%${tzFuzzySearch}%' 
					or name like '%${fuzzySearchInput}%'
					or phone like '%${fuzzySearchInput}%'`;


					let result = {status: notFuond, customers: []};

					let wasmResult = await Service.WasmDB.promiseSql(sqlExact)

					if (!wasmResult.length) {

						wasmResult = await Service.WasmDB.promiseSql(sqlFreetext)
					}

					if(wasmResult.length > 0 ){
						let custs = []

						for (let i=0; i < wasmResult.length; i++) {
							let cust = {...wasmResult[i], is_offline: true}
							custs.push(cust);
						}

						result.status = success
						result.customers = custs
					}
				
					return {result}
				}

				static async getCustomerByPhoneOrCustomerNumber(num: string) { 
					if(this.canUseOnline) {
						let serverRes = await PositiveTS.Service.FetchReq.jsonReq(`/hakafa_customers/${num}?by_customer_number_or_phone=true`, "get");
						return serverRes?.result?.hakafa_customer; // Note that this might not have some fields you expect from an actual instance
					}
					let wasmRes = await PositiveTS.Service.WasmDB.promiseSql(`select * from HakafaCustomer where phone = "${num}" OR (customer_number IS NOT NULL AND customer_number = "${num}") LIMIT(1)`);
					return wasmRes[0];
				}
			}
		}
	}
}