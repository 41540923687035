module PositiveTS {
  export module Components {
    export module SelfServiceAshrait {

      export function getComponent() {
        return {
          template: JST.selfServicePaymentScreenNoPinpad(),
          mixins: [Mixins.wizardItemMixin, Mixins.modalsMixin,Mixins.oneClickMixin, Mixins.selfServicePaymentNoPinpadMixin('selfService.creditCard', Mixins.IMAGES_TYPES.SWIPE_CARD)],
          setup(){
            const selfServicePaymentStore = Pinia.useSelfServicePaymentStore();
            const {creditCardPaymentsData} = Pinia.storeToRefs(selfServicePaymentStore);

            return {creditCardPaymentsData}
          },
          methods: { 
            getSalePayment() {
              let salePayment = posVC.salePayments.filter(sp => {
                return (sp.method == Storage.Entity.SalePayment.METHOD_CREDIT)
              })[0]
    
    
              if (!salePayment) {
                salePayment = new Storage.Entity.SalePayment();
                salePayment.saleID = posVC.sale.id;
                salePayment.amount = 0;
                salePayment.method = Storage.Entity.SalePayment.METHOD_CREDIT;
              }
    
              return salePayment;
            },
            async savePayment(result,amount,salePayment){
              //Create dataObject array
              var dataObj = [];
    
              //init the old dataObject
              if (salePayment.data != null && JSON.stringify(salePayment.data).indexOf('amount') != -1) {
                dataObj = JSON.parse(salePayment.data);
              }
    
              //workaround for a rare bug in gili's credit card infrastructure
              //this code can be removed once creditDal/shva/creditService have been re-written
              if (result.amount == null || Number.isNaN(result.amount)) {
                result.amount = amount;
              }
    
              dataObj.push(result);
              salePayment.data = JSON.stringify(dataObj);
              salePayment.amount = Number(amount);
  
              let inserted = false
              for (let i=0; i < posVC.salePayments.length; i++) {
                if (posVC.salePayments[i].method == salePayment.method) {
                  posVC.salePayments[i] = salePayment;
                  inserted = true;
                  break;
                }
              }
    
              if (!inserted) {
                posVC.salePayments.push(salePayment)
              }
              await Service.FullSale.saveCurrentSale();
            },
            getCardDetails(amount,cardNumber) {
    
              var creditCurrency = undefined;
              var creditLeadCurrencyAmount = undefined;
              // if MC supported get posPaymentVC.PaymentCreditSelectedCurrency.getVal()
              if ( PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() ) {
                creditCurrency = posPaymentVC.PaymentCreditSelectedCurrency.getVal();
                creditLeadCurrencyAmount = posPaymentVC.moduleMultiCurrencyCredit.getAmountTranslatedIfRequired(amount);
              }
    
              let cardDetails = {
                'amount'    : amount,
                'cardNumber'   :  cardNumber,
                'creditType' : session.creditCardPaymentTypes[this.paymentsData == null ? 1 : this.paymentsData.paymentType].ccTypeID,
                'experDate' :  "",
                'phoneNumber':  "",
                'ccv' : "",
                'cardPayments': this.paymentsData == null ? 1 : this.paymentsData.numberOfPayments,
                'confNumber' : "",
                'creditCurrency': creditCurrency,
                'creditLeadCurrencyAmount': creditLeadCurrencyAmount,
                'tz': ""
              }
    
              return cardDetails;
    
            },
            async cardChanged() {
             this.paymentsData = this.creditCardPaymentsData()
              if (this.cardNumber.indexOf('=') !== -1) { //swiped
                await this.pay();
              }
            },
            async pay(){
              this.paymentInProcess = true;
              let totals = Helper.SaleHelper.calcuateSaleTotals(posVC.sale, posVC.saleItems, posVC.salePayments);
              let amount = session.fixedFloat(totals.totalAmount - totals.totalPaid);
              let cardDetails = this.getCardDetails(amount,this.cardNumber)
              try{
                let creditCardResult = await Service.CreditCard.payWithCreditCard(cardDetails.amount,cardDetails.cardNumber,cardDetails.creditType,cardDetails.ccv,
                cardDetails.cardPayments,cardDetails.experDate,cardDetails.phoneNumber,cardDetails.creditCurrency,cardDetails.confNumber, cardDetails.tz)

                //TODO: Shay - do we need this crap?
                // if (creditCardResult && creditCardResult.return_code && (creditCardResult.return_code == "025" || creditCardResult.return_code == "032")) {
                //   await Service.CreditCard.updateSvaIfErr025AndNoTranExists(creditCardResult)

                //   creditCardResult = await Service.CreditCard.payWithCreditCard(cardDetails.amount,cardDetails.cardNumber,cardDetails.creditType,cardDetails.ccv,
                //     cardDetails.cardPayments,cardDetails.experDate,cardDetails.phoneNumber,cardDetails.creditCurrency,cardDetails.confNumber, cardDetails.tz)
                // }

                if(creditCardResult.return_code != "000"){
                  throw creditCardResult;
                }
                await this.savePayment(creditCardResult,amount,this.getSalePayment())
                this.goToNextStep();
              }
              catch(error){
                console.error(error.error_message || error)
                app.showAlertDialog({
                  header: i18next.t('error'),
                  content: error.error_message || error,
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
                this.setCurrentStep("SelfSelectPaymentMethod")
              }
            }
          },
          data:function(){
            return{
              paymentsData: null
            }
          },
        }
      }
    }
  }
}
