module PositiveTS {
  export module Pages {
      export module PosPastInvoices {
  
        function initData() {
          return {
            isLastSaleSelected: false,
            itemsAndSalesToCredit:null,
            posHasWithdrawal: Service.Withdrawal.posHasCashWithdrawals(),
            totalPastSales: 0,
            firstSaleNumOnPage: 0,
            lastSaleNumOnPage: 0,
            hideCredit: false,
            numberOfColumns: session.pos.isElal ? 9 : 8,
            spaces: session.pos.isElal ? "13%,12%,14%,11%,9%,9%,13%,10%,6%" : "14%, 13%, 14%,13%,10%,11%,17%,6%",
          };
        }

        async function rowSelected(theRow) {  
          this.hideCredit = false;
          
          posPastInvoicesVC.selectedRowId = posPastInvoicesVC.getSelectedRowId();
          let sale = posPastInvoicesVC.rowIDsSales[posPastInvoicesVC.selectedRowId];
    
          if (posUtils.isNullOrUndefined(sale)) {
            this.hideCredit = true;
            return;
          }
          

    
        }

        function transmitTaxInvoice() {
          //Get the selected sale
          var sale = posPastInvoicesVC.getSelectedSale();

          // If there are no selected rows, finish
          if (sale == null) {
            return;
          }
          Pinia.componentsStore.openComponent( { componentName: "transmitTaxInvoiceDialog", args: [sale] })

        }

        function printSaleItem() {
					//Get the selected sale
					var sale = posPastInvoicesVC.getSelectedSale();
					
					// If there are no selected rows, finish
					if (sale == null) {
						return;
					}
          
          if(!posUtils.isNullOrUndefinedOrEmptyString(sale.atmTransactionId)) { //print copy
            Service.Withdrawal.printInvoiceCopy(sale);
            return;
          }

					// Fetch all items and payments for the sale
          sale.fetchSaleItemAndPaymentsBySaleIDs([sale.id])
          .then(result => {
						let saleItemsAndPayments = result[0];
						Printing.Invoice.printInvoice(sale, saleItemHelper.unflattenSaleItems(saleItemsAndPayments.items), saleItemsAndPayments.payments, false);
					});
				}

        function creditWithdrawal(sale) {

          let saleValidation =  Service.Withdrawal.validateRefundSale(sale);
          app.hideLoadingMessage();
          if(saleValidation.isValid){
            Pinia.componentsStore.openComponent( {componentName:"withdrawalRefundDialog", args: [sale]});
          }else{
            app.showAlert({
              header: i18next.t('error'),
              content: saleValidation.message,
              hideCancelButton: true
            },null,null);
          }
        }
  
        function creditTaxInvItem():Promise<any>{
					var aThis = posPastInvoicesVC;
					aThis.selectedRowId = aThis.getSelectedRowId();
					var sale = aThis.rowIDsSales[aThis.selectedRowId];
					app.hideLoadingMessage();
					return app.promiseShowAlert({
						header: i18next.t('confirmReceiptCancelHeader'),
						content: i18next.t('confirmReceiptCencel'),
						continueButtonText: i18next.t('yes'),
						cancelButtonText: i18next.t('no')
					})
					.then((response)=>{
						if (response === "continue") {
							PositiveTS.Service.TaxInvCredit.creditTaxInvItem(this);
						}
					});
					
        }
        
        async function printBon(){
            app.showManagerApprovalDialog().then(async ()=>{
              let sale = posPastInvoicesVC.getSelectedSale();
              if(await Service.LogicalPrinterBonPrint.saleHasBonItems(sale)){
                Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(sale, saleItemHelper.unflattenSaleItems(sale.items),sale.payments,null,true)
              }
              else{
                app.showAlert({
                  header: i18next.t('error'),
                  content: i18next.t('posPastInvoices.noBonsInSale'),
                  hideCancelButton: true
                },null,null);
              }
            })
        }

				function creditShipmentInvItem():Promise<any>{
					var aThis = posPastInvoicesVC;
					app.hideLoadingMessage();
					return app.promiseShowAlert({
						header: i18next.t('confirmShipmentCancelHeader'),
						content: i18next.t('confirmShipmentCencel'),
						continueButtonText: i18next.t('yes'),
						cancelButtonText: i18next.t('no')
					})
					.then((response)=>{
						if (response === "continue") {
							PositiveTS.Service.ShipmentInvCredit.creditShipmentInvItem(this);
						}
					});
					
				}
				
				function creditReceiptItem() {
					var aThis = posPastInvoicesVC;
          app.hideLoadingMessage();
					app.showAlert({
						header: i18next.t('confirmReceiptCancelHeader'),
						content: i18next.t('confirmReceiptCencel'),
						continueButtonText: i18next.t('yes'),
						cancelButtonText: i18next.t('no')
					},  () => {
						creditSaleItem();
					}, null);
					
					
        }
        
        async function creditSaleItemExt() {
          let authNeeded = false;
          this.isLastSaleSelected = false;
          if(session.pos.parameterManagerApprovalCreditInvoice){
            authNeeded = true;
            if(jsonConfig.getVal(jsonConfig.KEYS.allowCreditLastInvoiceWithoutApproval)){
              let lastSaleTimestamp = await posPastInvoicesVC.getLastSaleTimestamp();
              app.hideLoadingMessage();
              if(lastSaleTimestamp== posPastInvoicesVC.getSelectedSale().timestamp){
                this.isLastSaleSelected = true;
                authNeeded = false;
              }              
            }
          }
          app.hideLoadingMessage();
          posVC.RedirectIfAuthorized(authNeeded,this.creditSaleItem,[Storage.Entity.Employee.CAN_CREDIT_PAYMENT]);
        }

        async function creditSelectedSale() {
          app.showLoadingMessage(i18next.t("posCreditInvoice.checkingSale"))
          const sale = posPastInvoicesVC.getSelectedSale();
          const validateCredit = await PositiveTS.Service.isCreditable.validateSale(sale);
          const allowToReplaceItemsInReplacementSale = jsonConfig.getVal(jsonConfig.KEYS.allowToReplaceItemsInReplacementSale)

          if (!validateCredit.valid){
            app.hideLoadingMessage();
            app.showAlert({
              header: i18next.t('isCreditable.titleErrorModal'),
              content: validateCredit.errors.join("\n"),
              continueButtonText: i18next.t('ok'),
              hideCancelButton: true
            }, null, null);
            return;
          }
           
          this.itemsAndSalesToCredit = PositiveTS.Helper.SaleHelper.getCreditedItemsAndPaymentsByFullSale(sale);

          if(Service.Withdrawal.saleCanBeRefunded(sale)) {
            return this.creditWithdrawal(sale);
          }

          if (PositiveTS.Service.isCreditable.isShowCredit(sale, this.itemsAndSalesToCredit).valid) {
            return this.creditSaleItemExt()
          }
            
          if (Service.isCreditable.isShowReceiptCredit(sale, this.itemsAndSalesToCredit).valid) {
            return this.creditReceiptItem()
          }
          
          if (PositiveTS.Service.TaxInvCredit.isTaxInvCreditable(sale, this.itemsAndSalesToCredit).valid) {
            return this.creditTaxInvItem();
          }
          
  
          if (PositiveTS.Service.ShipmentInvCredit.isShipmentInvCreditable(sale, this.itemsAndSalesToCredit).valid) {
            return this.creditShipmentInvItem();
          }

          if (allowToReplaceItemsInReplacementSale && sale.invoiceType == Storage.Entity.Sequence.TYPE_CREDIT_INVOICE) {
            return this.creditSaleItemExt()
          }

        }
				
				function creditSaleItem() {
					//Get the selected sale
					let sale = posPastInvoicesVC.getSelectedSale();
					
					// If there are no selected rows, finish
					if (sale == null) {
						return;
					}
					
          var saleProxyOptions = null;
					
					if (PositiveTS.Reachability.isOnline) {
						saleProxyOptions = {
							type: 		PositiveTS.Service.SaleProxy.REMOTE_DATA_TYPE,
							store_id: sale.storeID,
							pos_device_id: sale.posDeviceID,
							invoice_number: sale.invoiceSequence,
							invoice_type: sale.invoiceType
						};
					} else {
						saleProxyOptions = {
							type: 		PositiveTS.Service.SaleProxy.LOCAL_TYPE,
							saleID: 	sale.id
						};
					}
					
					// Go to the pos-cancel-invoice dialog
					pNavigator.replacePage('pos-credit-invoice', i18next.t('pageTitle.creditInvoice'), null , {initParams: {
						saleProxyOptions: saleProxyOptions,
            backTo: 'pos-past-invoices',
            lastSale: this.isLastSaleSelected
					}});
        }
        

        export function create() {
            var component = {
                template: JST.posPastInvoices(),
                methods: {
                  open() { // Mimic the hooklife cycle. consider this as the "mounted" ,more or less
                    Pinia.globalStore.setRouterState(PositiveTS.Enums.RouteState.PosPastInvoices);
                  },
                  rowSelected: rowSelected,
                  printSaleItem: printSaleItem,
                  transmitTaxInvoice: transmitTaxInvoice,
                  creditSaleItemExt: creditSaleItemExt,
                  creditSaleItem: creditSaleItem,
                  creditSelectedSale: creditSelectedSale,
                  creditReceiptItem: creditReceiptItem,
                  creditTaxInvItem: creditTaxInvItem,
                  creditShipmentInvItem: creditShipmentInvItem,
                  printBon: printBon,
                  creditWithdrawal: creditWithdrawal,
                  displaySaleItems: () => posPastInvoicesVC.displaySaleItems(),
                  openSendSmsInvoiceDialog: () => {
                    PositiveTS.Service.Sms.openPhoneDialogAndSendInvoiceSms(posPastInvoicesVC.getSelectedSale());
                  },
                  
                  async printOtotBracelet() {
                    if (posPastInvoicesVC.getSelectedSale()) {
                      await Service.Otot.printTagsFromSaleWithManangerApproval(posPastInvoicesVC.getSelectedSale());
                    }
                  },
                  posShowsPagination: () => jsonConfig.getVal(jsonConfig.KEYS.paginatePastInvoices),
                  nextPage() {
                    if(this.lastSaleNumOnPage >= this.totalPastSales) return ;
                    posPastInvoicesVC.currentPage++;
                    this.updatePaginationParams(posPastInvoicesVC.totalAmountOfSales, posPastInvoicesVC.currentPage, posPastInvoicesVC.invoicesPerPage);
                    posPastInvoicesVC.reload();
                  },
                  prevPage() {
                    if(posPastInvoicesVC.currentPage < 1) return ;
                    posPastInvoicesVC.currentPage--;
                    this.updatePaginationParams(posPastInvoicesVC.totalAmountOfSales, posPastInvoicesVC.currentPage, posPastInvoicesVC.invoicesPerPage);
                    posPastInvoicesVC.reload();
                  },
                  updatePaginationParams(totalPastSales:number, currentPage:number, invoicesPerPage:number) {
                    this.totalPastSales = totalPastSales
                    this.firstSaleNumOnPage = (currentPage*invoicesPerPage) + 1;
                    this.lastSaleNumOnPage = (currentPage+1)*invoicesPerPage
                    if(this.lastSaleNumOnPage > totalPastSales) this.lastSaleNumOnPage = totalPastSales;
                  }
                },
                data: initData,
                computed:  {                
                  mobileLayout(){
                    return Pinia.globalStore.mobileLayout
                  },
                  posUsesBonsPrinting() { return jsonConfig.getVal(jsonConfig.KEYS.allowBonPrinting) },
                  mobilePhoneRepairModule() { return  jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule); },
                  canManageHakafaCustomer() { return  jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos); },
                  restaurantNewPOS() { return jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS); },
                  canSendInvoiceSms() { return jsonConfig.getVal(jsonConfig.KEYS.isAllowedSendSmsInvoice); },
                  isOtot() { return Service.Otot.isOtotActive(); },
                  taxAuthorityTransmitEnabled() { 
                    return session.pos.taxAuthorityInvoiceAllocationAmount > 0
                  },
                  actions(): Array<PositiveTS.Types.PositiveButtonProps> {
                    let btns: Array<PositiveTS.Types.PositiveButtonProps> = [
                      {
                        onClick: this.displaySaleItems,
                        text: i18next.t("posPastInvoices.show"),
                        icon: 'fa-light fa-circle-check',
                        iconClass: 'success',
                        border: false,
                        textClass: 'big-txt',
                      },
                      {
                        onClick: this.printSaleItem,
                        text: i18next.t("posPastInvoices.printInvoice"),
                        icon: 'fa-light fa-print',
                        iconClass: 'primary-active',
                        border: false,
                        textClass: 'big-txt',
                      },
                      {
                        onClick: this.creditSelectedSale,
                        text: i18next.t("posPastInvoices.creditInvoice"),
                        icon: 'fal fa-undo',
                        iconClass: 'primary-active flip-horizontolly',
                        textClass: 'big-txt',
                        border: false,
                      },
                      {
                        if: this.posUsesBonsPrinting,
                        onClick: this.printBon,
                        icon: 'printBon',
                        iconClass: 'print-bon',
                        text: i18next.t("posPastInvoices.Bons"),
                        textClass: 'big-txt',
                        border: false,
                      },
                      {
                        if: this.canSendInvoiceSms,
                        onClick: this.openSendSmsInvoiceDialog,
                        text: i18next.t("posPastInvoices.send"),
                        icon: 'fa-light fa-comment-sms',
                        border: false,
                        textClass: 'big-txt',
                      },
                      {
                        if: this.isOtot,
                        onClick: this.printOtotBracelet,
                        text: i18next.t("posPastInvoices.printOtotBracelet"),
                        icon: 'fa-light fa-qrcode',
                        border: false,
                        textClass: 'big-txt',
                      },
                    ];
                    return btns.filter(btn => btn.if === undefined || btn.if);
                  },
                }

            }
  
          VueApp.component('pos-past-invoices',component)
        }
      }
    }
  }
  
