module PositiveTS {
export module Storage {
export module Entity {
export class SecondaryMenuItem extends WasmEntity {


	name:string
	button_index:number
	primary_menu_item_id:string
	disallowedStores:string
	translations: string

	constructor() {
		let meta = {
			name: 'SecondaryMenuItem',
			fields: {
				name: "TEXT",
			  	button_index: "INT",
	      		primary_menu_item_id: "TEXT",
				color: "TEXT",
				migvanId: "INT",
				disallowedStores: "TEXT",
				pictureUrl: "TEXT",
				translations: "TEXT",
			}
		}
		super(meta)
	}


	async getAll(primaryMenuItemId): Promise<Array<PositiveTS.Types.MenuButton>> {
		let cols = await PositiveTS.Service.WasmDB.getExistingTableColumns('SecondaryMenuItem', ['id', 'name', 'color', 'disallowedStores', 'pictureUrl', 'translations']);
		let result = await PositiveTS.Service.WasmDB.promiseSql(`SELECT ${cols.join(', ')} FROM SecondaryMenuItem WHERE primary_menu_item_id=${primaryMenuItemId} 
			ORDER BY button_index asc, name asc` )
		var resArr = []
		for (var i = 0; i < result.length; i++) {
			if (session.pos.hasFlights && (await SecondaryMenuItem.getNumOfItemsInMenuTree(result[i].id)) == 0) {
				continue;
			}
			if (result[i].disallowedStores &&
				JSON.parse(result[i].disallowedStores).indexOf(parseInt(session.store.storeID)) > -1) { continue; }
			resArr.push(Object.assign({ level: 1, isItem: false, isSecondaryMenuItem: true }, result[i]));
		}
		return resArr;
	}

	static getCategoryMenuItems(categoryId:number):ItemMenuItem[] {
		return session.allMenuItems.filter(menuItem => menuItem.secondaryMenuItemId == categoryId);
	}

	static async getNumOfItemsInMenuTree(categoryId: number): Promise<number> {
		let total = this.getCategoryMenuItems(categoryId).length;


		let subcategories = await (new ThirdMenuItem()).getAll(categoryId);

		for (let subcategory of subcategories) {
			total += ThirdMenuItem.getNumOfItemsInMenuTree(subcategory.id);
		}


		return total;
	}
}}}}
