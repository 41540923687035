module PositiveTS {
  export module Components {
    export module Mixins {
      export let wizardItemMixin = {
        props: {
          modelValue: {},
          wizardData: {
            type: Object,
            default: () => {}
          }
        },
        emits: ['next', 'back','update:modelValue'],
        methods: {
          goBack() {
            this.$emit('back');
          },
          goToNextStep(data: object = {}, updatePaymentInProcess = false) {            
            this.$emit('next', data, updatePaymentInProcess);
          },
          closePaymentPage() {
            this.$emit('close');
          }
        },
        computed: {
          stepData: {
            get (){
              return this.modelValue || {}
            },
            set(value) {
              this.$emit('update:modelValue', value)
            }
          }
        },
        mounted() {
          if (!this.modelValue) {
            this.$emit('update:modelValue', {})
          }
        },
      }
    }
  }
}
