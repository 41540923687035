module PositiveTS {
  export module Service {
    export class GPP extends SmartVoucher {
      hasCVV = true

      getBalance(cardNumber: string, cvv: string): Promise<GetBalanceResponse> {
        if (cardNumber.indexOf('=') < 0) {
          if (cvv === "") {
            cardNumber += "000"
          }
          else {
            cardNumber += cvv;
          }
        }
        return new Promise<GetBalanceResponse>((resolve, reject) =>
          fetch(`/gpp/${cardNumber}?device_id=${posVC.sale.posDeviceID}`, { //TODO: add relevant terminal id
            headers: { 'Authorization': `Token token=${session.pos.access_token}` }
          })
          .then((response) => {
            return response.json()
          })
          .then((json) => {
            resolve(json);
          })
          .catch((error) => {
            console.error('request failed', error);
            resolve({
              success: false,
              balance: 0,
              error: "שגיאת רשת - אנא פנה לתמיכה"
            })
          })
        )
      }

      pay(voucherData: any, amount: number, cvv?: string): Promise<any> {
        let cardNumber = voucherData.barCode;
        if (cardNumber.indexOf('=') < 0) {
          if (cvv === "") {
            cardNumber += "000"
          }
          else {
            cardNumber += cvv;
          }
        }

        return new Promise((resolve, reject) => {

          let voucherConfig = PositiveTS.Service.WasmDB.execAsObject(`select ${SmartVoucher.CARD_PREFIX_FIELD} as cardPrefix from Voucher WHERE typeID = '${voucherData.voucher_type_id}'`)[0]

          if (voucherConfig && !posUtils.isBlank(voucherConfig.cardPrefix)) {            
            let prefixes = voucherConfig.cardPrefix.split(',');
            if (prefixes.length == 1) {
              prefixes = voucherConfig.cardPrefix.split('&');
            }
            let appropriateCard = prefixes.some( prefix => cardNumber.startsWith(prefix));            
            if (!appropriateCard) {
              reject(i18next.t('smartVoucher.incompatableCard'));
              return;
            }
          }

          this.getBalance(voucherData.barCode,cvv)
          .then((result) => {
              if (result.success) {
                const balance = Number(result.balance)
                if (balance <= 0){
                  reject('אין יתרה זמינה לחיוב')
                }
                let amountToCharge = Math.min(balance,amount)
                fetch(`/gpp`, {
                  method: 'post',
                  headers: {
                    'Authorization': `Token token=${session.pos.access_token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    number: cardNumber,
                    device_id: posVC.sale.posDeviceID,
                    action_type: 'withdraw',
                    amount: amountToCharge,
                    total_amount: amount
                  })
                })
                .then((response) => {
                  return response.json()
                })
                .then((json) => {
                  if (json.success) {
                    voucherData.actionReference = json.action_reference
                    voucherData.barCode = cardNumber
                    voucherData.balance = json.balance
                    voucherData.actionType = SmartVoucherActionTypes.WITHDRAW
                    voucherData.allowPartialReturn = true
                    voucherData.amount = amountToCharge
                    voucherData.smartVoucherType = PositiveTS.Storage.Entity.Voucher.SMART_VOUCHER_GPP
                    resolve(voucherData)
                  }
                  else {
                    reject(json.error);
                  }
                })
              }
              else {
                reject(result.error)
              }
          })

          .catch((error) => {
            console.error(error)
            reject("שגיאה כללית - אנא נסה שנית מאוחר יותר או פנה לתמיכה");
          })
        });

      }

      cancelPayment(voucherToCancel: any): Promise<ActionResponse> {
        return new Promise<ActionResponse>((resolve, reject) => {
          fetch(`/gpp`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              number: voucherToCancel.barCode,
              action_type: SmartVoucherActionTypes.CANCEL_WITHDRAW,
              amount: Math.abs(voucherToCancel.amount),
              device_id: posVC.sale.posDeviceID,
              total_amount: Math.abs(voucherToCancel.amount),
              action_reference: voucherToCancel.actionReference
            })
          })
          .then((response) => {
            return response.json()
          })
          .then((json) => {
            if (json.success) {
              voucherToCancel.originalReference = voucherToCancel.actionReference;
              voucherToCancel.actionReference = json.action_reference
              voucherToCancel.actionType = SmartVoucherActionTypes.CANCEL_WITHDRAW
              voucherToCancel.balance = json.balance
              resolve({ success: true, error: "" })
            }
            else {
              resolve({ success: false, error: json.error });
            }
          })
          .catch((error) => {
            resolve({ success: false, error: "שגיאה כללית - אנא נסה שנית מאוחר יותר או פנה לתמיכה" });
          })
        });
      }

      cancelLoad(paymentToCancel): Promise<any> {
        var currentPayment;
        paymentToCancel = paymentToCancel[0];
        return new Promise((resolve, reject) => {
          fetch(`/gpp`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              number: paymentToCancel.barCode,
              action_type: 'cancel_deposit',
              amount: Math.abs(paymentToCancel.amount),
              device_id: posVC.sale.posDeviceID,
              total_amount: Math.abs(paymentToCancel.amount),
              action_reference: paymentToCancel.actionReference
            })
          })
            .then((response) => {
            return response.json()
          })
            .then((json) => {
            if (json.success) {
              currentPayment = new PositiveTS.Storage.Entity.SalePayment()
              currentPayment.amount = 0
              currentPayment.method = PositiveTS.Storage.Entity.SalePayment.METHOD_GPP
              paymentToCancel.amount = -Math.abs(paymentToCancel.amount)
              paymentToCancel.actionType = SmartVoucherActionTypes.CANCEL_DEPOSIT
              paymentToCancel.balance = json.balance
              paymentToCancel.actionReference = json.action_reference
              currentPayment.data = JSON.stringify([paymentToCancel])
              resolve(currentPayment)
            }
            else {
              reject(new Error(json.error));
            }
          })
            .catch((error) => {
            reject(new Error("שגיאה כללית - אנא נסה שנית מאוחר יותר או פנה לתמיכה"));
          })
        });
      }

      getCardNumber(): Promise<GetCardNumberResponse> {
        return new Promise<GetCardNumberResponse>((resolve, reject) => {

          this.getInputCardScan()
            .then(result => {
            resolve({
              cardNumber: result.cardNumber,
              options: { cvv: result.cvv }
            })
          })
        })
      }

      loadCard(cardNumber: string, amount: Number, options?: any): Promise<SmartVoucherPaymentData> {

        if (cardNumber.indexOf('=') < 0) {
          if (options.cvv === "") {
            cardNumber += "000"
          }
          else {
            cardNumber += options.cvv;
          }
        }
        return new Promise((resolve, reject) => {
          fetch(`/gpp`, {
            method: 'post',
            headers: {
              'Authorization': `Token token=${session.pos.access_token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              number: cardNumber,
              action_type: SmartVoucherActionTypes.DEPOSIT,
              device_id: posVC.sale.posDeviceID,
              amount: amount,
              total_amount: amount
            })
          })
          .then((response) => {
            return response.json()
          })
          .then((json) => {
            if (json.success) {
              json.actionType = SmartVoucherActionTypes.DEPOSIT;
              json.amount = amount;
              json.actionReference = json.action_reference;
              json.barCode = cardNumber
              resolve({
                amount: 0,
                method: PositiveTS.Storage.Entity.SalePayment.METHOD_GPP,
                data: JSON.stringify([json])
              })
            }
            else {
              reject(json.error);
            }
          })
            .catch((error) => {
            reject("שגיאה כללית - אנא נסה שנית מאוחר יותר או פנה לתמיכה");
          })
        });
      }

      private getInputCardScan(): Promise<PositiveTS.Dialogs.InputWithCVVResult> {
        let dg = new PositiveTS.Dialogs.InputDialogWithCVV();
        let options = new PositiveTS.Dialogs.InputDialogOptions()
        options.header = 'הזן כרטיס לטעינה'
        options.description = 'סרוק כרטיס או הזן קוד ידנית'
        options.inputPlaceHolder = 'מספר כרטיס'
        options.showCancelButton = false
        options.emptyErrorMessage = 'חובה להזין מספר כרטיס'
        return dg.open(options)
      }
    }
  }
}
