module PositiveTS {
export module Promotions {
export module Templates {
export class Kombina extends TemplateAbstract {
    private promoGroups:Array<any>
  
    constructor(initParameters) {
      super(initParameters)
      this.promoGroups = [];
    }
    
    calculatePromotions() {
      throw new Error("This should not happen")
    }

    run(promotion:Storage.Entity.Promotion) {
        this.promoGroups = [];
        let allItems:Array<any> = [];
        let sortedItems:Array<any>;
        let usedSalim = [false, false, false, false];
        let itemsForPromotion:Array<any> = []
        let rowValuesForPromotion:Array<any> = []
        let allItemsFromPos = this.parameters.updatedSaleItems;
        let lastSal = 2;

        //find out how many salim are in the promotion
        if(!posUtils.isNullOrUndefined(promotion.quantitySal4)) {
            lastSal = 4;
        } 
        else if(!posUtils.isNullOrUndefined(promotion.quantitySal3)) {
            lastSal = 3;
        }

        if(!promotion.allowWithOtherPromotions) {
            allItemsFromPos = allItemsFromPos.filter(item => !item.hasPromotion);
        }

        //for each item, get their salim and price after prev discounts
        for (const item of this.parameters.updatedSaleItems) {
            if (promotion.isHappyHour && NewPromotionsEngine.happyHourIsActiveForSaleItem(promotion, item) == false) {
                continue;
            }
            let itemSalim = this.GetItemSalim(item, promotion);
            if(itemSalim.indexOf(1) != -1) {
                usedSalim[0] = true;
            }
            if(itemSalim.indexOf(2) != -1) {
                usedSalim[1] = true;
            }
            if(itemSalim.length > 0) {
                allItems.push({
                    item: item,
                    salim: itemSalim,
                    price: item.unitPrice,
                })
            }
        }
        if(!usedSalim[0] || !usedSalim[1]) {
            return ;
        }

        //sort items by 1st: sal -low to high, 2nd: price - high to low
        sortedItems = allItems.sort(this.sortSaleItems);

        let runSuccess = true;
        let totalPriceOfItemsByRun = [];
        let mapOfSalimInPromotion = this.getSalimMapInPromotion(promotion, lastSal);
        let promotionRepeatedTimes = 0;

        
        //calculate the amount of times the promotion can be repeated
        let maxTimesToRun = sortedItems.length/mapOfSalimInPromotion.length;
        if(!promotion.allowMultipleTimesSameSale) {
            maxTimesToRun = 1
        }
        else if(!posUtils.isNullOrUndefinedOrEmptyString(promotion.maxQuantityToGiveCustomer)) {
            maxTimesToRun = promotion.maxQuantityToGiveCustomer < maxTimesToRun ? promotion.maxQuantityToGiveCustomer : maxTimesToRun;
        }
        
        //calculate the promotions to be commited
        for(let promotionRepeat = 0; promotionRepeat < maxTimesToRun && runSuccess; promotionRepeat++) {
            runSuccess = false
            for(let i = 0; i<mapOfSalimInPromotion.length; i++) {
                let itemIndex = sortedItems.findIndex(element => element.salim.includes(mapOfSalimInPromotion[i]));
                if(itemIndex != -1) {
                    if(i == 0) {
                        itemsForPromotion[promotionRepeat] = {};
                        rowValuesForPromotion[promotionRepeat] = {};
                        totalPriceOfItemsByRun[promotionRepeat] = 0;
                    }
                    else if(i == mapOfSalimInPromotion.length -1) {
                        runSuccess = true;
                        promotionRepeatedTimes++;
                    }
                    itemsForPromotion[promotionRepeat] = this.addToItemsCounter(sortedItems[itemIndex].item, itemsForPromotion[promotionRepeat])
                    rowValuesForPromotion[promotionRepeat] = this.addToRowValueCounter(sortedItems[itemIndex].item, rowValuesForPromotion[promotionRepeat])
                    totalPriceOfItemsByRun[promotionRepeat] += sortedItems[itemIndex].price;
                    sortedItems.splice(itemIndex, 1);
                            
                } else {
                    break ;
                }
            }
        }
        
        //create promotion promoGroups for engine
        for(let i = 0; i < promotionRepeatedTimes; i++) {
            let discountAmount;
            switch(promotion.discountType) {
                case Storage.Entity.Promotion.DISCOUNT_TYPE_PERCENT:
                    discountAmount = totalPriceOfItemsByRun[i] - (totalPriceOfItemsByRun[i]*(promotion.discountValue/100))
                    break;
                case Storage.Entity.Promotion.DISCOUNT_TYPE_FIX:
                    discountAmount = totalPriceOfItemsByRun[i] - promotion.discountValue;
                    break;
                case Storage.Entity.Promotion.DISCOUNT_TYPE_AMOUNT:
                    discountAmount = promotion.discountValue;
                    break;
            }
            this.promoGroups.push({
                discountAmountForGroup: discountAmount,
                item: {},
                itemsCounter: itemsForPromotion[i],
                rowValueCounter: rowValuesForPromotion[i],
                totalPriceForItemsBeforeDiscount: totalPriceOfItemsByRun[i],
                promotion: promotion
            });
            this.parameters.promoGroups = this.promoGroups;
        }
    }

    getSalimMapInPromotion(promotion, numberOfSalim) {
      let salimInPromotion = [];
      for(let i = 0; i<numberOfSalim; i++) {
        let itemsInSal = i == 0 ? promotion.minimumBuyQuantity : promotion[`quantitySal${i+1}`]
        for(let y = 0; y<itemsInSal; y++) {
          salimInPromotion.push(i+1)
        }
      }
      return salimInPromotion;
    }

    sortSaleItems(a, b) {
      if(a.salim[0] != b.salim[0]) {
          return a.salim[0] - b.salim[0]
      }

      if(a.price != b.price) {
          return b.price - a.price
      }

      return 0;
    }

    GetItemSalim(item:Storage.Entity.SaleItem, promotion:Storage.Entity.Promotion) {
      let salim = [];

      if(!posUtils.isNullOrUndefined(item.item.promoBuy)) {
          if(item.item.promoBuy.split("&").indexOf(promotion.code) != -1) {
              salim.push(1);
          }
      }
      if(!posUtils.isNullOrUndefined(item.item.promoGet)) {
          if(item.item.promoGet.split("&").indexOf(promotion.code) != -1) {
              salim.push(2);
          }
      }
      if(!posUtils.isNullOrUndefined(item.item.promoSal3)) {
          if( item.item.promoSal3.split("&").indexOf(promotion.code) != -1) {
              salim.push(3);
          }
      }
      if(!posUtils.isNullOrUndefined(item.item.promoSal4)) {
          if(item.item.promoSal4.split("&").indexOf(promotion.code) != -1) {
              salim.push(4);
          }
      }
      return salim;
    }
}}}}