module PositiveTS {
	export module CashStatementQueue {

		export async function launchQueueExt() {
			try {
				await launchQueue();
			} catch(error) {
				let msg = "";
				if (error && error.message) {
					msg = error.message;
				}
				if (typeof(error) === "string") {
					msg = error;
				}
				console.error(`Did not send statements to server. Reason: ${msg}`);
			}

			// Schedule the next queue launch
			setTimeout(() => { CashStatementQueue.launchQueueExt(); }, 5000 );
		}

	 	async function launchQueue() {

			let items = await workerDB.cashStatementLogs.filter(log => (log.syncStatus == Shared.Constants.Sale.SYNC_STATUS_WAITING_TO_BE_SENT
				|| log.syncStatus == Shared.Constants.Sale.SYNC_STATUS_FAILED)).toArray();


			if (items.length == 0) {
				return;
			}

			if (!PositiveTS.QueueWorker.Utils.isOnline) { 
				console.debug('Aborting (offline)');
				return;
			}
			
			 await sendItemsToServer(items);
			 await eraseOldItems();
			 return;
		}

		async function sendItemsToServer(items) {

			// Send the sales to the remote server
			let url = Shared.Constants.remoteRoot + 'cashier_statement_logs';

			let results = await PositiveTS.QueueWorker.FetchReq.jsonReq(url,"POST",PositiveTS.QueueWorker.Utils.token ,{ data: JSON.stringify(items) })
			console.debug(results);
			
				let promises = [];				
					for (let itemResult of results.result) {

						if (itemResult.success) {
							promises.push(workerDB.cashStatementLogs.where('id').equals(itemResult.log_id)
							.modify({syncStatus: Shared.Constants.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY, serverId: itemResult.id}))
						}
						else {
							//error
							promises.push(workerDB.cashStatementLogs.where('id').equals(itemResult.log_id)
							.modify({syncStatus: Shared.Constants.Sale.SYNC_STATUS_FAILED}))
						}
					}

				await Promise.all(promises);
				return;
			
		}

		async function eraseOldItems() {
			let eraseDate = new Date();
			eraseDate.setMonth(eraseDate.getMonth() - 1);

			await workerDB.cashStatementLogs.where("statement_time").below(Math.round(+eraseDate / 1000)).delete();
		}

	}
}
